import React from "react";
import type { ColumnDef } from "@jobber/components/DataTable";
import type { GetValueType } from "~/jobber/features/Reporting/components/Report/types";
import {
  AMOUNT_COLUMN_HEADER,
  NAME_COLUMN_FOOTER,
  NAME_COLUMN_HEADER,
  PAYMENT_COLUMN_HEADER,
  RATE_COLUMN_HEADER,
} from "jobber/franchise/features/Reporting/views/RoyaltyReport/constants";
import type {
  RoyaltyData,
  RoyaltyTotalsData,
} from "jobber/franchise/features/Reporting/views/RoyaltyReport/types";
import {
  ComparableHeader,
  CurrencyFooter,
  InnerColumnCellFactory,
} from "~/jobber/features/Reporting/components/Report/components/ReportCells/ReportCells";

export const getColumnConfig = (
  totalsData?: RoyaltyTotalsData,
): ColumnDef<RoyaltyData, GetValueType>[] => {
  return [
    {
      id: "FRANCHISE_NAME",
      accessorKey: "name",
      header: NAME_COLUMN_HEADER,
      footer: NAME_COLUMN_FOOTER,
      minSize: 538,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: "PAYMENTS_TOTAL",
      accessorKey: "payment",
      accessorFn: (row: RoyaltyData) => row?.total?.toLocaleString(),
      header: () => <ComparableHeader text={PAYMENT_COLUMN_HEADER} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.paymentsTotal} />
      ),
      cell: InnerColumnCellFactory("currency"),
      maxSize: 268,
    },
    {
      id: "ROYALTY_RATE",
      accessorKey: "royaltyRate",
      header: () => <ComparableHeader text={RATE_COLUMN_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      maxSize: 268,
    },
    {
      id: "ROYALTY_AMOUNT",
      accessorKey: "royaltyAmount",
      accessorFn: (row: RoyaltyData) => row?.royaltyAmount?.toLocaleString(),
      header: () => <ComparableHeader text={AMOUNT_COLUMN_HEADER} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.royaltyAmountsTotal} />
      ),
      cell: InnerColumnCellFactory("currency"),
      maxSize: 268,
    },
  ];
};
