import { gql } from "@apollo/client";

export const TWILIO_REG_INFO = gql`
  query TwilioRegistrationInfo {
    twilioCarrierRegistrationData {
      street
      region
      postalCode
      phoneNumber
      lastName
      firstName
      emailAddress
      businessName
      city
      websiteUrl
      businessType
      businessRegistrationNumber
      jobPosition
      businessTitle
      userErrors {
        message
        path
      }
    }
  }
`;

export const SUBMIT_TWILIO_REG = gql`
  mutation TwilioRegistrationMutation($input: CarrierRegistrationInput!) {
    createTwilio10dlcA2pRegistration(input: $input) {
      twilioCarrierRegistration {
        id
      }
      userErrors {
        message
        path
      }
    }
  }
`;
