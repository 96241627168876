import { Content } from "@jobber/components/Content";
import { Markdown } from "@jobber/components/Markdown";
import { Switch } from "@jobber/components/Switch";
import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { trackAutopayToggleClicked } from "jobber/workOrders/components/AutomaticPayments/utils/trackinteractions";
import styles from "./EnableOnCardAddSwitch.module.css";
import { messages } from "./messages";

interface EnableOnCardAddSwitchProps {
  shouldEnableWorkOrderOnPaymentMethodAdd: boolean;
  disableSwitch: boolean;
  newRecord?: boolean;
}

export function EnableOnCardAddSwitch({
  shouldEnableWorkOrderOnPaymentMethodAdd,
  disableSwitch,
  newRecord = false,
}: EnableOnCardAddSwitchProps) {
  const [enabled, setEnabled] = useState(
    shouldEnableWorkOrderOnPaymentMethodAdd,
  );

  return (
    <Content>
      <div className={styles.switchContainer}>
        <Heading level={4}>{messages.switchTitle.defaultMessage}</Heading>
        <Switch
          disabled={disableSwitch}
          value={enabled && !disableSwitch}
          name="should_enable_work_order_on_payment_method_add"
          onChange={handleChange}
        />
      </div>
      <Markdown
        content={messages.switchSubtitleMarkdown.defaultMessage}
        externalLink={true}
      />
    </Content>
  );

  function handleChange(value: boolean) {
    setEnabled(value);
    trackAutopayToggleClicked(newRecord, value);
  }
}
