import { useCallback, useContext, useEffect, useState } from "react";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { buildAttributes } from "utilities/split/buildSplitAttributes";
import type { SplitNames } from "./splitNames";
import { SplitOverridesContext } from "./SplitOverridesContext";

export interface SplitProps {
  readonly names: string[];
  readonly attributes?: SplitIO.Attributes;
}

export function useSplit({ names, attributes }: SplitProps): SplitTreatments {
  const overrides = useContext(SplitOverridesContext);
  const attributesInternal = buildAttributes(attributes);
  const { isReady, treatments } = useSplitTreatments({
    names,
    attributes: attributesInternal,
  });

  return {
    isReady,
    getTreatmentValue: useCallback(
      (splitName: SplitNames): boolean => {
        if (!isReady) {
          return false;
        }

        // If there is a provided override, we can use it. Otherwise, grab from Split SDK
        if (splitName in overrides) {
          return overrides[splitName] === "on";
        }

        return treatments[splitName]?.treatment === "on";
      },
      [isReady, treatments, overrides],
    ),
  };
}

export function useFeatureFlag(flag: SplitNames): boolean {
  const [flagValue, setFlagValue] = useState(false);
  const { getTreatmentValue, isReady } = useSplit({
    names: [flag],
  });

  useEffect(() => {
    if (isReady) {
      setFlagValue(getTreatmentValue(flag));
    }
  }, [isReady, getTreatmentValue, flag]);

  return flagValue;
}

interface SplitTreatments {
  isReady: boolean;
  getTreatmentValue(splitName: string): boolean;
}
