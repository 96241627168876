import type { InlineLabelColors } from "@jobber/components/InlineLabel";
import { InvoiceStatusTypeEnum } from "~/utilities/API/graphqlFranchiseManagement";

export const REPORT_ERROR_MESSAGE = "Something went wrong!";
export const REPORT_HEADER = "Invoices (Detailed)";
export const FRANCHISEE_NAME_HEADER = "Franchisee";
export const FRANCHISEE_NAME_FOOTER = "Totals";
export const INVOICE_NUMBER_HEADER = "Invoice #";
export const CLIENT_NAME_HEADER = "Client Name";
export const STATUS_HEADER = "Status";
export const SUBJECT_HEADER = "Subject";
export const DRAFTED_DATE_HEADER = "Drafted Date";
export const ISSUED_DATE_HEADER = "Issued Date";
export const PAID_DATE_HEADER = "Paid Date";
export const DUE_DATE_HEADER = "Due Date";
export const SUBTOTAL_HEADER = "Subtotal ($)";
export const DISCOUNT_AMOUNT_HEADER = "Discount ($)";
export const TAX_RATE_HEADER = "Tax (%)";
export const TAX_AMOUNT_HEADER = "Tax ($)";
export const TOTAL_HEADER = "Total ($)";
export const TIP_TOTAL_HEADER = "Tip ($)";
export const DEPOSIT_HEADER = "Deposit ($)";
export const BALANCE_HEADER = "Balance ($)";
export const ASSIGNED_USERS_HEADER = "Visit assigned to";

export const STATUS_FILTER_LABELS = {
  drafted: "Drafted within",
  issued: "Issued within",
  paid: "Paid within",
};

export const INVOICE_STATUS_KEYS: Record<string, string> = {
  [InvoiceStatusTypeEnum.AWAITING_PAYMENT]: "Awaiting payment",
  [InvoiceStatusTypeEnum.PAID]: "Paid",
  [InvoiceStatusTypeEnum.PAST_DUE]: "Past due",
  [InvoiceStatusTypeEnum.BAD_DEBT]: "Bad debt",
  [InvoiceStatusTypeEnum.DRAFT]: "Draft",
};

export const INVOICE_STATUS_COLORS: Record<string, InlineLabelColors> = {
  [InvoiceStatusTypeEnum.AWAITING_PAYMENT]: "orange",
  [InvoiceStatusTypeEnum.PAID]: "green",
  [InvoiceStatusTypeEnum.PAST_DUE]: "red",
  [InvoiceStatusTypeEnum.BAD_DEBT]: "red",
  [InvoiceStatusTypeEnum.DRAFT]: "greyBlue",
};
