import React from "react";
import { useIntl } from "react-intl";
import { FormatDate } from "@jobber/components/FormatDate";
import { FormatTime } from "@jobber/components/FormatTime";
import { Text } from "@jobber/components/Text";
import { messages } from "./messages";

export function DateTimeDisplay({ date }: { date: string }) {
  const { formatMessage } = useIntl();

  return (
    <Text>
      <FormatDate date={date} />
      {formatMessage(messages.dateTimeAt)}
      <FormatTime time={date} />
    </Text>
  );
}
