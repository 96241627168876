import { cloneDeep } from "lodash";
import { CivilTime } from "@std-proposal/temporal";
import type {
  InstantBookingAvailabilityWindow,
  InstantBookingAvailabilityWindowAttributes,
} from "~/utilities/API/graphql";
import type { ArrivalWindow } from "jobber/google_lsa/MerchantConfiguration";

function sortArrivalWindows(
  windowA: InstantBookingAvailabilityWindow,
  windowB: InstantBookingAvailabilityWindow,
) {
  const startTimeComparison = windowA.startSeconds - windowB.startSeconds;
  const endTimeComparison = windowA.endSeconds - windowB.endSeconds;

  if (startTimeComparison !== 0) {
    return startTimeComparison;
  } else {
    return endTimeComparison;
  }
}

export function convertArrivalWindows(
  availabilityWindows: InstantBookingAvailabilityWindow[],
) {
  const arrivalWindows: ArrivalWindow[] = [];
  let spotsTotal = 0;

  cloneDeep(availabilityWindows)
    .sort(sortArrivalWindows)
    .forEach(singleDayWindow => {
      const startCivilTime = CivilTimeFromSeconds(singleDayWindow.startSeconds);
      const endCivilTime = CivilTimeFromSeconds(singleDayWindow.endSeconds);

      let existingArrivalWindow = arrivalWindows.find(
        (arrivalWindow: ArrivalWindow) => {
          return (
            arrivalWindow.startAt.hour === startCivilTime.hour &&
            arrivalWindow.startAt.minute === startCivilTime.minute &&
            arrivalWindow.endAt.hour === endCivilTime.hour &&
            arrivalWindow.endAt.minute === endCivilTime.minute
          );
        },
      );

      if (!existingArrivalWindow) {
        existingArrivalWindow = {
          startAt: startCivilTime,
          endAt: endCivilTime,
          dayOfWeekMap: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
          },
        };
        arrivalWindows.push(existingArrivalWindow);
      }

      switch (singleDayWindow.dayOfWeek) {
        case 0:
          existingArrivalWindow.dayOfWeekMap.sunday = true;
          break;
        case 1:
          existingArrivalWindow.dayOfWeekMap.monday = true;
          break;
        case 2:
          existingArrivalWindow.dayOfWeekMap.tuesday = true;
          break;
        case 3:
          existingArrivalWindow.dayOfWeekMap.wednesday = true;
          break;
        case 4:
          existingArrivalWindow.dayOfWeekMap.thursday = true;
          break;
        case 5:
          existingArrivalWindow.dayOfWeekMap.friday = true;
          break;
        case 6:
          existingArrivalWindow.dayOfWeekMap.saturday = true;
          break;
        default:
          throw new Error();
      }

      spotsTotal = Math.max(singleDayWindow.spotsTotal, spotsTotal);
    });
  return {
    arrivalWindows: arrivalWindows,
    spotsTotal: spotsTotal,
  };
}

export function convertAvailabilityWindows(
  arrivalWindows: ArrivalWindow[],
  spotsTotal = 1,
) {
  const availabilityWindows: InstantBookingAvailabilityWindowAttributes[] = [];

  arrivalWindows.forEach(arrivalWindow => {
    const startSeconds = SecondsFromCivilTime(arrivalWindow.startAt);
    const endSeconds = SecondsFromCivilTime(arrivalWindow.endAt);

    Object.entries(arrivalWindow.dayOfWeekMap).forEach(([day, enabled]) => {
      if (enabled) {
        let dayOfWeek: number;

        switch (day) {
          case "sunday":
            dayOfWeek = 0;
            break;
          case "monday":
            dayOfWeek = 1;
            break;
          case "tuesday":
            dayOfWeek = 2;
            break;
          case "wednesday":
            dayOfWeek = 3;
            break;
          case "thursday":
            dayOfWeek = 4;
            break;
          case "friday":
            dayOfWeek = 5;
            break;
          case "saturday":
            dayOfWeek = 6;
            break;
          default:
            throw new Error();
        }

        availabilityWindows.push({
          startSeconds: startSeconds,
          endSeconds: endSeconds,
          spotsTotal: spotsTotal,
          dayOfWeek: dayOfWeek,
        });
      }
    });
  });

  return availabilityWindows;
}

function CivilTimeFromSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return new CivilTime(hours, minutes);
}

function SecondsFromCivilTime(time: CivilTime) {
  return time.hour * 3600 + time.minute * 60;
}
