import React from "react";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";
import styles from "./WorkflowCard.module.css";
import { WorkflowCardEmptyStateContent } from "./WorkflowCardEmptyStateContent";
import {
  InvoicesTrendlineImg,
  JobsTrendlineImg,
  QuotesTrendlineImg,
  RequestsTrendlineImg,
} from "./images";

interface WorkflowStatusData {
  amount: string;
  count: number;
  label: string;
  url?: string;
}

export interface WorkflowCardProps {
  title: string;
  status: string;
  accentColor: Parameters<typeof Card>[0]["accent"];
  primaryCount: number;
  primaryAmount?: string;
  primaryUrl?: string;
  primaryCaption: string;
  allStatusData: WorkflowStatusData[];
  showEmptyState: boolean;
}

export function WorkflowCard({
  title,
  status,
  accentColor,
  primaryCount,
  primaryAmount,
  primaryUrl,
  primaryCaption,
  allStatusData,
  showEmptyState,
}: WorkflowCardProps) {
  const { formatMessage } = useIntl();

  const onCTAButtonClick = () => {
    trackCTAEvent(title);
  };

  const [ref, { exactWidth = 320 }] = useResizeObserver<HTMLDivElement>();
  const small = 280;
  const fullWidthButton = exactWidth < small;

  const [newQuotePath, newInvoicePath, newJobPath, newRequestPath] = useUrls(
    "newQuotePath",
    "newInvoicePath",
    "newJobPath",
    "newRequestPath",
  );
  const cardInfo: {
    [key: string]: { [key: string]: string };
  } = {
    Quotes: {
      CTAButtonUrl: newQuotePath,
      ariaLabel: formatMessage(messages.quotesCTAAriaLabel),
      eventSource: "create_quote",
      emptyStateMessage: formatMessage(messages.quotesEmptyStateMessage),
      emptyStateButtonText: formatMessage(messages.quotesEmptyStateButtonText),
      trendlineImgSrc: QuotesTrendlineImg.src,
      trendlineImgAlt: formatMessage(QuotesTrendlineImg.alt),
    },
    Invoices: {
      CTAButtonUrl: newInvoicePath,
      ariaLabel: formatMessage(messages.invoicesCTAAriaLabel),
      eventSource: "create_invoice",
      emptyStateMessage: formatMessage(messages.invoicesEmptyStateMessage),
      emptyStateButtonText: formatMessage(
        messages.invoicesEmptyStateButtonText,
      ),
      trendlineImgSrc: InvoicesTrendlineImg.src,
      trendlineImgAlt: formatMessage(InvoicesTrendlineImg.alt),
    },
    Jobs: {
      CTAButtonUrl: newJobPath,
      ariaLabel: formatMessage(messages.jobsCTAAriaLabel),
      eventSource: "create_job",
      emptyStateMessage: formatMessage(messages.jobsEmptyStateMessage),
      emptyStateButtonText: formatMessage(messages.jobsEmptyStateButtonText),
      trendlineImgSrc: JobsTrendlineImg.src,
      trendlineImgAlt: formatMessage(JobsTrendlineImg.alt),
    },
    Requests: {
      CTAButtonUrl: newRequestPath,
      ariaLabel: formatMessage(messages.requestsCTAAriaLabel),
      eventSource: "create_request",
      emptyStateMessage: formatMessage(messages.requestsEmptyStateMessage),
      emptyStateButtonText: formatMessage(
        messages.requestsEmptyStateButtonText,
      ),
      trendlineImgSrc: RequestsTrendlineImg.src,
      trendlineImgAlt: formatMessage(RequestsTrendlineImg.alt),
    },
  };

  const showSecondaryButton = !showEmptyState;

  return (
    <div className={styles.wrapper} ref={ref}>
      <Card
        header={{
          title: title,
          ...(showSecondaryButton && {
            action: (
              <Button
                icon="plus2"
                ariaLabel={cardInfo[title].ariaLabel}
                type="secondary"
                url={cardInfo[title].CTAButtonUrl}
                size="small"
                onClick={onCTAButtonClick}
              />
            ),
          }),
        }}
        accent={accentColor}
      >
        {showEmptyState ? (
          <WorkflowCardEmptyStateContent
            onCTAButtonClick={onCTAButtonClick}
            cardInfo={cardInfo[title]}
          />
        ) : (
          <div className={styles.metrics}>
            <div className={styles.metric}>
              <Text variation="subdued">{status}</Text>
              <div className={styles.threeColumnRow}>
                <Heading level={2}>{primaryCount}</Heading>
                <div>
                  {primaryAmount && primaryCount > 0 && (
                    <Text>{primaryAmount}</Text>
                  )}
                </div>
                {primaryCount > 0 && (
                  <div className={styles.buttonColumn}>
                    <Button
                      icon="arrowRight"
                      ariaLabel={primaryCaption}
                      type="secondary"
                      url={primaryUrl}
                      onClick={() => trackEvent(title, "Primary")}
                      fullWidth={fullWidthButton}
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>

            {allStatusData.map((statusData, index) => {
              const onStatusClick = () => {
                trackEvent(title, "Secondary");
              };
              const ariaLabel = formatMessage(messages.viewButtonAriaLabel, {
                statusLabel: statusData.label?.toLowerCase(),
                title: title?.toLowerCase(),
              });
              return (
                <div className={styles.metric} key={index}>
                  <Text variation="subdued">{statusData.label}</Text>
                  <div className={styles.threeColumnRow}>
                    <Heading level={2}>{statusData.count}</Heading>
                    <div>
                      {statusData.amount && statusData.count > 0 && (
                        <Text>{statusData.amount}</Text>
                      )}
                    </div>
                    {statusData.count > 0 && (
                      <div className={styles.buttonColumn}>
                        <Button
                          icon="arrowRight"
                          ariaLabel={ariaLabel}
                          type="secondary"
                          url={statusData.url}
                          onClick={onStatusClick}
                          size="small"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Card>
    </div>
  );

  function trackEvent(cardType: string, buttonType: string) {
    const eventName = "Clicked Workflow Card";
    const eventProperty = {
      source: cardType,
      type: buttonType,
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
  }

  function trackCTAEvent(cardTitle: string) {
    const eventName = "CTA Clicked";
    let source = cardInfo[cardTitle].eventSource;
    if (showEmptyState) {
      source += "_empty";
    }
    const eventProperty = {
      source: source,
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
  }
}
