import React from "react";
import {
  type GenericObject,
  useReplaceTemplatedValuesWithSamples,
} from "./useReplaceTemplatedValuesWithSamples";

interface TemplateSectionPreviewerProps {
  readonly template: string;
  readonly values: GenericObject;
  readonly isBold?: boolean;
}

export function TemplateSectionPreviewer({
  template,
  values,
  isBold = false,
}: TemplateSectionPreviewerProps) {
  const templateWithReplacedValues = useReplaceTemplatedValuesWithSamples(
    template,
    values,
  );

  try {
    return (
      <span
        style={{
          whiteSpace: "pre-wrap",
          fontWeight: isBold ? "bold" : "normal",
        }}
      >
        {templateWithReplacedValues}
      </span>
    );
  } catch {
    return null;
  }
}
