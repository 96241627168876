import React, { useContext } from "react";
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  type IndividualElementChangeEvent,
} from "@recurly/react-recurly";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputValidation } from "@jobber/components/InputValidation";
import { useIntl } from "react-intl";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import type { RecurlyInputValidation } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsEdit/PaymentDetailsEdit.d";
import { CardBrand } from "components/CardBrand";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { GlimmerField } from "jobber/billing/components/EditBillingInfo/LoadingGlimmer";
import styles from "./RecurlyCardFields.module.css";
import { messages } from "./messages";

interface RecurlyCardFieldsProps {
  handleInputFocus(input: string): void;
  recurlyInputValidation: RecurlyInputValidation;
  handleCardValidation(
    inputName: keyof RecurlyInputValidation,
  ): (event: IndividualElementChangeEvent) => void;
}

const validationMessages = {
  cardNumber: messages.invalidCardNumber,
  cardExpiry: messages.invalidCardExpiry,
  cvc: messages.invalidCvc,
};

export function RecurlyCardFields(props: RecurlyCardFieldsProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalRecurlyCardFields {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalRecurlyCardFields({
  handleInputFocus,
  recurlyInputValidation,
  handleCardValidation,
}: RecurlyCardFieldsProps) {
  const context = useContext(PurchaseFormContext);
  const { formatMessage } = useIntl();
  const { tokens } = useAtlantisTheme();
  const fieldValueColor = tokens["color-heading"];
  const fieldPlaceholderColor = tokens["color-text--secondary"];

  return (
    <>
      <div hidden={context.submitting}>
        <InputGroup>
          <div className={styles.cardNumberContainer}>
            <CardNumberElement
              data-testid="cardNumber"
              className={`${styles.recurlyHostedField} ${styles.cardNumberInput}`}
              onFocus={() => {
                handleInputFocus("cardNumber");
              }}
              style={{
                fontSize: `${tokens["typography--fontSize-base"]}px`,
                fontFamily: "Inter",
                fontColor: fieldValueColor,
                placeholder: {
                  content: formatMessage(messages.cardNumberPlaceholder),
                  color: fieldPlaceholderColor,
                },
              }}
              onChange={handleCardValidation("cardNumber")}
            />
            <div className={styles.cardBrandsContainer}>
              <InputGroup flowDirection="horizontal">
                <CardBrand brand="visa" />
                <CardBrand brand="mastercard" />
                <CardBrand brand="amex" />
                <CardBrand brand="discover" />
              </InputGroup>
            </div>
          </div>
          <CardInputValidation
            messageKey={"cardNumber"}
            hasError={recurlyInputValidation.cardNumber === false}
          />
          <div className={styles.ccDetailsContainer}>
            <div className={styles.expirationContainer}>
              <div className={styles.expirationFields}>
                <CardMonthElement
                  data-testid="month"
                  className={`${styles.recurlyHostedField} ${styles.monthExpirationInput}`}
                  onFocus={() => {
                    handleInputFocus("month");
                  }}
                  style={{
                    fontSize: `${tokens["typography--fontSize-base"]}px`,
                    fontFamily: "Inter",
                    fontColor: fieldValueColor,
                    placeholder: {
                      content: "MM",
                      color: fieldPlaceholderColor,
                    },
                  }}
                  onChange={handleCardValidation("month")}
                />
                <CardYearElement
                  data-testid="year"
                  className={styles.recurlyHostedField}
                  onFocus={() => {
                    handleInputFocus("year");
                  }}
                  style={{
                    fontSize: `${tokens["typography--fontSize-base"]}px`,
                    fontFamily: "Inter",
                    fontColor: fieldValueColor,
                    placeholder: {
                      content: "YY",
                      color: fieldPlaceholderColor,
                    },
                  }}
                  onChange={handleCardValidation("year")}
                />
              </div>
              <CardInputValidation
                messageKey={"cardExpiry"}
                hasError={
                  recurlyInputValidation.month === false ||
                  recurlyInputValidation.year === false
                }
              />
            </div>
            <div className={styles.cvcDetailsContainer}>
              <div className={styles.cvcFields}>
                <CardCvvElement
                  data-testid="cvc"
                  className={`${styles.recurlyHostedField} ${styles.cvcInput}`}
                  onFocus={() => {
                    handleInputFocus("cvc");
                  }}
                  style={{
                    fontSize: `${tokens["typography--fontSize-base"]}px`,
                    fontFamily: "Inter",
                    fontColor: fieldValueColor,
                    placeholder: {
                      content: "CVC",
                      color: fieldPlaceholderColor,
                    },
                  }}
                  onChange={handleCardValidation("cvc")}
                />
                <div className={styles.cvcImage}>
                  {window.SG1_IMAGE_MANIFEST["svg/cvc.svg"] && (
                    <img
                      alt="cvc"
                      src={window.SG1_IMAGE_MANIFEST["svg/cvc.svg"]}
                    />
                  )}
                </div>
              </div>
              <CardInputValidation
                messageKey={"cvc"}
                hasError={recurlyInputValidation.cvc === false}
              />
            </div>
          </div>
        </InputGroup>
      </div>
      <div hidden={!context.submitting}>
        <CCFieldsGlimmer />
      </div>
    </>
  );
}

function CardInputValidation({
  messageKey,
  hasError,
}: {
  messageKey: keyof typeof validationMessages;
  hasError: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      {hasError && (
        <InputValidation
          message={formatMessage(validationMessages[messageKey])}
        />
      )}
    </>
  );
}

const CCFieldsGlimmer = () => {
  return (
    <div className={styles.glimmer}>
      <GlimmerField />
      <div className={styles.glimmerRow}>
        <GlimmerField short={true} />
        <GlimmerField short={true} />
        <GlimmerField short={true} />
      </div>
    </div>
  );
};
