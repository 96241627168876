import React from "react";
import { ContactDetails } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader/components/ContactDetails";
import {
  type MessageDataFragment,
  MessageDirectionEnum,
} from "~/utilities/API/graphql";

interface ContactDetailsHeaderProps {
  readonly message: MessageDataFragment;
  externalName?: string;
}

export function ContactDetailsHeader({
  message,
  externalName,
}: ContactDetailsHeaderProps) {
  const to =
    message.__typename === "EmailMessage" ? message.toEmails : undefined;
  const cc =
    message.__typename === "EmailMessage" ? message.ccEmails : undefined;

  return (
    <ContactDetails
      sentBy={getSender()}
      to={to}
      cc={cc}
      sentTo={getRecipient()}
      direction={message.direction}
      externalName={externalName}
    />
  );

  function getSender() {
    if (message.direction === MessageDirectionEnum.INBOUND) {
      return message.externalContactObject;
    }
    return message.internalContactObject;
  }

  function getRecipient() {
    if (message.direction === MessageDirectionEnum.OUTBOUND) {
      return message.externalContactObject;
    }
    return message.internalContactObject;
  }
}
