import { useQuery } from "@apollo/client";
import type { AccountBillingInformation } from "~/utilities/API/graphql";
import { GET_MOBILE_BILLING_INFO } from "./useMobileBillingInfo.graphql";

export function useMobileBillingInfo() {
  const { data, loading } = useQuery<AccountBillingInformationQuery>(
    GET_MOBILE_BILLING_INFO,
  );

  return {
    loading,
    isMobileBilled:
      !data?.account?.billingInformation?.allowInWebBillingChanges,
  };
}

interface AccountBillingInformationQuery {
  account: {
    billingInformation: AccountBillingInformation;
  };
}
