import { gql } from "@apollo/client";

export const ACCOUNT_EDIT_COMPANY_DETAILS = gql`
  mutation AccountEditCompanyDetails(
    $input: AccountCompanyDetailsUpdateAttributes!
  ) {
    accountEditCompanyDetails(input: $input) {
      account {
        id
        companyDetails {
          city
          country
          postalCode
          province
          street1
          street2
        }
      }
      userErrors {
        message
        path
      }
    }
  }
`;
