import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import paperRipSrc from "@images/signup/paper-rip.svg";
import type { ImageType } from "jobber/setupWizard/types";
import defaultBusinessImageSrc from "@images/signup/set-up-your-business.jpg";
import themeDark from "@images/signup/theme-dark.svg";
import themeLight from "@images/signup/theme-light.svg";
import styles from "./MediaColumn.module.css";

interface MediaColumnProps {
  backgroundImage?: ImageType;
  showRip?: boolean;
  children?: React.ReactNode;
  hasOverlay?: boolean;
}

export function MediaColumn({
  children,
  backgroundImage,
  showRip = true,
  hasOverlay,
}: MediaColumnProps) {
  const { formatMessage } = useIntl();

  const backgroundImageStyles = () => {
    return classNames(styles.mediaBackgroundImage, {
      [styles.mediaBackgroundImageWithOverlay]: hasOverlay,
      [styles.businessBackground]:
        backgroundImage?.src === defaultBusinessImageSrc,
      [styles.themeBackgroundImage]:
        backgroundImage?.src === themeDark ||
        backgroundImage?.src === themeLight,
    });
  };

  return (
    <div>
      {showRip && (
        <span
          className={styles.paperRip}
          style={{ maskImage: `url(${paperRipSrc})` }}
        ></span>
      )}
      {backgroundImage && (
        <div className={classNames({ [styles.imageOverlay]: hasOverlay })}>
          <img
            className={backgroundImageStyles()}
            src={backgroundImage.src}
            alt={formatMessage(backgroundImage.alt)}
          />
        </div>
      )}
      {children}
    </div>
  );
}
