import { defineMessages } from "react-intl";

export const messages = defineMessages({
  automatedCampaignRecipientSummary: {
    id: "campaigns.campaignReviewPage.automatedCampaignRecipientSummary",
    defaultMessage:
      "This campaign will automatically send to clients {timeFrame} after {trigger} -> {condition}",
    description: "Recipient summary",
  },
});
