import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { Text } from "@jobber/components/Text";
import React, { type ReactNode, useState } from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import type { MerchantConfiguration } from "jobber/google_lsa/MerchantConfigurationPage.graphql";
import styles from "./SetupNavigation.module.css";

export enum SetupStep {
  SetServices = 0,
  SetAvailability,
  SetCapacity,
  SetMessage,
  ActivateIntegration,
  ReviewSettings,
}

interface SetupNavigationProps {
  children?: ReactNode[];
  currentStep: number;
  data: MerchantConfiguration;
  onChangeStep(newStep: SetupStep): Promise<void>;
}
export function SetupNavigation({
  children,
  currentStep,
  data,
  onChangeStep,
}: SetupNavigationProps) {
  const [setupWizardMessage, setSetupWizardMessage] = useState(<></>);

  const [backLoading, setBackLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);

  const stepBackward = async () => {
    setBackLoading(true);
    await onChangeStep(currentStep - 1);
    setBackLoading(false);
  };

  const stepForward = async () => {
    const stepError = getSetupStepError(currentStep, data);
    if (stepError) {
      setSetupWizardMessage(stepError);
    } else {
      setNextLoading(true);
      setSetupWizardMessage(<></>);
      await onChangeStep(currentStep + 1);
      setNextLoading(false);
    }
  };

  const numberOfSteps = SetupStep.ReviewSettings;

  return (
    <Content spacing="large">
      {currentStep !== SetupStep.ReviewSettings && (
        <Content spacing="small">
          <ProgressBar
            currentStep={currentStep + 1}
            totalSteps={numberOfSteps + 1}
          />
          <Text size="large">
            Step: <Emphasis variation="bold">{currentStep + 1}</Emphasis> of{" "}
            <Emphasis variation="bold">{numberOfSteps}</Emphasis>{" "}
          </Text>
        </Content>
      )}

      {setupWizardMessage}

      {children}

      {currentStep !== SetupStep.ReviewSettings && (
        <div className={styles.wizardNavigation} key={currentStep}>
          {currentStep !== SetupStep.SetServices ? (
            <Button
              label="Back"
              variation="subtle"
              loading={backLoading}
              onClick={stepBackward}
            />
          ) : (
            // Intentionally empty element to make sure the Next button is always aligned in the second position
            <span />
          )}{" "}
          <Button
            label={
              currentStep === SetupStep.ActivateIntegration ? "Confirm" : "Next"
            }
            loading={nextLoading}
            onClick={stepForward}
            disabled={
              currentStep === SetupStep.ActivateIntegration &&
              !data.account.googleLsaConfiguration?.enabled
            }
          />
        </div>
      )}
    </Content>
  );
}

function getSetupStepError(step: SetupStep, data: MerchantConfiguration) {
  switch (step) {
    case SetupStep.SetServices:
      if (data.instantBookingSelectedServices.nodes.length === 0) {
        return (
          <Banner type="error" dismissible={false}>
            You must choose at least one job type before continuing
          </Banner>
        );
      }
      break;
    default:
  }
  return undefined;
}
