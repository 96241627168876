import React from "react";
import { Tooltip, Typography } from "@jobber/components";
import type { AiAssistantQuestionInterpretation } from "~/utilities/API/graphql";

interface QuestionInterpretationProps {
  readonly interpretation: AiAssistantQuestionInterpretation | undefined;
}

export function QuestionInterpretation({
  interpretation,
}: QuestionInterpretationProps) {
  if (!interpretation) {
    return null;
  }

  return (
    <Tooltip message={interpretation.boilerplate} preferredPlacement="top">
      <Typography underline="dashed color-border" fontWeight="bold">
        {interpretation.body}
      </Typography>
    </Tooltip>
  );
}
