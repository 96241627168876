import { defineMessages } from "react-intl";

export const messages = defineMessages({
  turnOnQuickBooksSync: {
    id: "dashboard.components.qboTurnOnSyncInsight.turnOnQuickBooksSync",
    defaultMessage: "Turn On QuickBooks Sync",
    description: "The turn on QuickBooks Sync insights card",
  },
  turnOnQuickBooksSyncDescription: {
    id: "dashboard.components.qboTurnOnSyncInsight.qboTurnOnSyncDescription",
    defaultMessage:
      "Our accounting sync will keep your customer, invoice, and payment information accurate between Jobber and QuickBooks.",
    description: "The description of turn on QuickBooks sync insights card",
  },
  learnMore: {
    id: "dashboard.components.qboTurnOnSyncInsight.learnMore",
    defaultMessage: "Learn More",
    description: "The action button to learn more about QuickBooks sync",
  },
});
