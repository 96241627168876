import { gql } from "@apollo/client";

export const JobPageInfo = gql`
  fragment jobPageInfo on PageInfo {
    hasPreviousPage
    hasNextPage
    endCursor
    startCursor
  }
`;

export const JobCostTimeSheetEntry = gql`
  fragment jobCostTimeSheetEntry on TimeSheetEntry {
    id
    approved
    duration
    finalDuration
    labourRate @include(if: $canViewJobCosts)
    note
    ticking
    startAt
    visitDurationTotal
    user {
      id
      name {
        first
        last
        full
      }
    }
  }
`;

export const JobCostTimeSheetEntries = gql`
  fragment jobCostTimeSheetEntries on TimeSheetEntryConnection {
    nodes {
      ...jobCostTimeSheetEntry
    }
    totalCount
    pageInfo {
      ...jobPageInfo
    }
  }
  ${JobCostTimeSheetEntry}
  ${JobPageInfo}
`;

export const LineItemInterface = gql`
  fragment lineItemInterface on LineItemInterface {
    id
    name
    description
    quantity
    unitPrice @include(if: $canViewPricing)
    totalPrice @include(if: $canViewPricing)
    taxable
    linkedProductOrService {
      id
    }
  }
`;

export const JobLineItem = gql`
  fragment jobLineItem on JobLineItem {
    ...lineItemInterface
    unitCost @include(if: $canViewPricing)
    totalCost @include(if: $canViewPricing)
    image {
      url
      thumbnailUrl
      fileName
      fileSize
      contentType
    }
  }
  ${LineItemInterface}
`;

export const JobCostLineItem = gql`
  fragment jobCostLineItem on JobViewLineItem {
    ...lineItemInterface
    unitCost @include(if: $canViewPricing)
    totalCost @include(if: $canViewPricing)
    image {
      url
      thumbnailUrl
      fileName
      fileSize
      contentType
    }
    overrideDates {
      nodes {
        startAt
      }
    }
  }
  ${LineItemInterface}
`;

export const JobCostExpense = gql`
  fragment jobCostExpense on Expense {
    id
    title
    total
    date
    accountingCode {
      id
      code
    }
    reimbursableTo {
      id
      name {
        full
      }
    }
    description
    receipt {
      fileName
      fileSize
      thumbnailUrl
      contentType
      url
    }
    createdAt
    updatedAt
  }
`;

export const JobCostExpenses = gql`
  fragment jobCostExpenses on ExpenseConnection {
    nodes {
      ...jobCostExpense
    }
    totalCount
    pageInfo {
      ...jobPageInfo
    }
  }
  ${JobPageInfo}
  ${JobCostExpense}
`;

export const JobCostLineItems = gql`
  fragment jobCostLineItems on JobViewLineItemConnection {
    nodes {
      ...jobCostLineItem
    }
    totalCount
    pageInfo {
      ...jobPageInfo
    }
  }
  ${JobCostLineItem}
  ${JobPageInfo}
`;

export const JobClientDetails = gql`
  fragment jobClient on Client {
    name
  }
`;

export const JobDetails = gql`
  fragment jobDetails on JobView {
    id
    jobNumber
    jobType
    billingType
    client {
      ...jobClient
    }
    property {
      id
    }
    createdAt
  }
  ${JobClientDetails}
`;

export const JobCostingData = gql`
  fragment jobCostingData on JobCosting {
    id
    labourDuration
    labourCost
    expenseCost
    lineItemCost
    totalCost
    totalRevenue
    profitAmount
    profitPercentage
  }
`;

export const JobCostProfitData = gql`
  fragment jobCostProfitData on JobViewProfit {
    id
    labourDuration
    labourCost
    expenseCost
    lineItemCost
    totalCost
    totalRevenue
    profitAmount
    profitPercentage
    invoiceRemindersCount
    visitsCount
  }
`;

export const JOB_COST_QUERY = gql`
  query jobCostQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
    $canViewExpenses: Boolean = false
    $canViewTimeSheetEntries: Boolean = false
    $canViewPricing: Boolean = false
  ) {
    account {
      id
      currentUser {
        id
        labourRate @include(if: $canViewJobCosts)
        name {
          first
          last
          full
        }
        isAccountAdmin
      }
    }
    jobView: viewWebJob(id: $jobId) {
      id
      labour @include(if: $canViewTimeSheetEntries) {
        id
        totalDuration
        totalCost @include(if: $canViewJobCosts)
        items(first: 10) {
          ...jobCostTimeSheetEntries
        }
      }
      expenses @include(if: $canViewExpenses) {
        id
        total
        items(first: 10) {
          ...jobCostExpenses
        }
      }
      lineItems {
        id
        totalPrice @include(if: $canViewPricing)
        totalCost @include(if: $canViewPricing)
        items(first: 50) {
          ...jobCostLineItems
        }
      }
      profit @include(if: $canViewJobCosts) {
        ...jobCostProfitData
      }
      ...jobDetails
    }
  }
  ${JobCostTimeSheetEntries}
  ${JobCostExpenses}
  ${JobCostLineItems}
  ${JobDetails}
  ${JobCostProfitData}
`;

export const JOB_COST_TIME_SHEET_ENTRIES_QUERY = gql`
  query jobCostTimeSheetEntriesQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
    $canViewTimeSheetEntries: Boolean = false
    $timeSheetsCursor: String
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      labour @include(if: $canViewTimeSheetEntries) {
        id
        totalDuration
        totalCost @include(if: $canViewJobCosts)
        items(first: 10, after: $timeSheetsCursor) {
          ...jobCostTimeSheetEntries
        }
      }
    }
  }
  ${JobCostTimeSheetEntries}
`;

export const JOB_COST_EXPENSES_QUERY = gql`
  query jobCostExpensesQuery(
    $jobId: EncodedId!
    $canViewExpenses: Boolean = false
    $expensesCursor: String
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      expenses @include(if: $canViewExpenses) {
        id
        total
        items(first: 10, after: $expensesCursor) {
          ...jobCostExpenses
        }
      }
    }
  }
  ${JobCostExpenses}
`;

export const JOB_COST_LINE_ITEMS_QUERY = gql`
  query jobCostLineItemsQuery(
    $jobId: EncodedId!
    $lineItemsCursor: String
    $canViewPricing: Boolean = false
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      lineItems {
        id
        totalCost @include(if: $canViewPricing)
        totalPrice @include(if: $canViewPricing)
        items(first: 50, after: $lineItemsCursor) {
          ...jobCostLineItems
        }
      }
    }
  }
  ${JobCostLineItems}
`;

export const JOB_COST_PROFIT_QUERY = gql`
  query jobCostProfitQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      profit @include(if: $canViewJobCosts) {
        ...jobCostProfitData
      }
    }
  }
  ${JobCostProfitData}
`;

export const JOB_COST_LINE_ITEM_TOTALS_QUERY = gql`
  query jobCostLineItemTotalsQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
    $canViewPricing: Boolean = false
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      lineItems {
        id
        totalCost @include(if: $canViewPricing)
        totalPrice @include(if: $canViewPricing)
      }
      profit @include(if: $canViewJobCosts) {
        ...jobCostProfitData
      }
    }
  }
  ${JobCostProfitData}
`;

export const JOB_COST_LABOUR_TOTALS_QUERY = gql`
  query jobCostLabourTotalsQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      labour {
        id
        totalCost @include(if: $canViewJobCosts)
        totalDuration
      }
      profit @include(if: $canViewJobCosts) {
        ...jobCostProfitData
      }
    }
  }
  ${JobCostProfitData}
`;

export const JOB_COST_EXPENSE_TOTALS_QUERY = gql`
  query jobCostExpenseTotalsQuery(
    $jobId: EncodedId!
    $canViewJobCosts: Boolean = false
  ) {
    jobView: viewWebJob(id: $jobId) {
      id
      expenses {
        id
        total
      }
      profit @include(if: $canViewJobCosts) {
        ...jobCostProfitData
      }
    }
  }
  ${JobCostProfitData}
`;

export const EXPENSE_CREATE = gql`
  mutation expenseCreate($input: ExpenseCreateInput!) {
    expenseCreate(input: $input) {
      expense {
        ...jobCostExpense
      }
      userErrors {
        message
      }
    }
  }
  ${JobCostExpense}
`;

export const EXPENSE_EDIT = gql`
  mutation expenseEdit($expenseId: EncodedId!, $input: ExpenseEditInput!) {
    expenseEdit(expenseId: $expenseId, input: $input) {
      expense {
        ...jobCostExpense
      }
      userErrors {
        message
      }
    }
  }
  ${JobCostExpense}
`;

export const EXPENSE_DELETE = gql`
  mutation expenseDelete($expenseId: EncodedId!) {
    expenseDelete(expenseId: $expenseId) {
      deletedExpense {
        id
      }
      userErrors {
        message
      }
    }
  }
`;

export const TIMESHEET_CREATE = gql`
  mutation timesheetCreate(
    $input: TimeSheetEntryCreateInput!
    $canViewJobCosts: Boolean = false
  ) {
    timeSheetEntryCreate(input: $input) {
      timeSheetEntry {
        ...jobCostTimeSheetEntry
      }
      userErrors {
        message
      }
    }
  }
  ${JobCostTimeSheetEntry}
`;

export const TIMESHEET_EDIT = gql`
  mutation timesheetEdit(
    $timeSheetEntryId: EncodedId!
    $input: TimeSheetEntryEditInput!
    $canViewJobCosts: Boolean = false
  ) {
    timeSheetEntryEdit(timeSheetEntryId: $timeSheetEntryId, input: $input) {
      timeSheetEntry {
        ...jobCostTimeSheetEntry
      }
      userErrors {
        message
      }
    }
  }
  ${JobCostTimeSheetEntry}
`;

export const TIMESHEET_DELETE = gql`
  mutation timesheetDelete($timeSheetEntryId: EncodedId!) {
    timeSheetEntryDelete(timeSheetEntryId: $timeSheetEntryId) {
      timeSheetEntry {
        id
      }
      userErrors {
        message
      }
    }
  }
`;

export const LABOUR_RATE_UPDATE = gql`
  mutation labourRateUpdate(
    $userId: EncodedId!
    $labourRate: Float!
    $canViewJobCosts: Boolean = false
  ) {
    upsertUser(id: $userId, attributes: { labourRate: $labourRate }) {
      user {
        id
        labourRate @include(if: $canViewJobCosts)
        name {
          full
        }
      }
      userErrors {
        message
      }
    }
  }
`;

export const JOB_EDIT_LINE_ITEM = gql`
  mutation JobEditLineItems(
    $jobId: EncodedId!
    $input: JobEditLineItemsInput!
    $canViewPricing: Boolean = false
  ) {
    jobEditLineItems(jobId: $jobId, input: $input) {
      modifiedLineItems {
        ...jobLineItem
      }
      userErrors {
        message
      }
    }
  }
  ${JobLineItem}
`;

export const ACCOUNTING_CODES_QUERY = gql`
  query accountingCodesQuery {
    accountingCodes {
      nodes {
        code
        id
      }
    }
  }
`;

export const JOB_DELETE_LINE_ITEMS = gql`
  mutation JobDeleteLineItems($jobId: EncodedId!, $lineItemId: EncodedId!) {
    jobDeleteLineItems(jobId: $jobId, input: { lineItemIds: [$lineItemId] }) {
      deletedLineItems {
        id
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const JOB_CREATE_LINE_ITEMS = gql`
  mutation JobCreateLineItems(
    $jobId: EncodedId!
    $input: JobCreateLineItemsInput!
    $canViewPricing: Boolean = false
  ) {
    jobCreateLineItems(jobId: $jobId, input: $input) {
      createdLineItems {
        ...jobLineItem
      }
      userErrors {
        path
        message
      }
    }
  }
  ${JobLineItem}
`;

export const EXPORT_JOB_COST_CSV = gql`
  mutation ExportJobCostCsv($jobId: EncodedId!) {
    jobExportCsv(jobId: $jobId) {
      userErrors {
        message
      }
    }
  }
`;

export const ACTIVE_USERS_QUERY = gql`
  query ActiveUsersQuery($canViewJobCosts: Boolean = false) {
    users(filter: { status: ACTIVATED }) {
      nodes {
        id
        name {
          first
          last
          full
        }
        labourRate @include(if: $canViewJobCosts)
      }
    }
  }
`;
