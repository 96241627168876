import { gql, useMutation } from "@apollo/client";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import React, { useState } from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import type { SetNotificationEnabledMutation } from "~/utilities/API/graphql";
import { notificationFieldsFragment } from "jobber/settings/notifications/notificationFieldsFragment";
import { ScheduleOverview } from "jobber/settings/notifications/components/ScheduleOverview/ScheduleOverview";
import type { BaseNotificationProps } from "jobber/settings/notifications/notificationInterfaces";
import { useUpdateNotificationModal } from "jobber/settings/notifications/hooks";
import { NotificationTemplateEditor } from "./NotificationTemplateEditor";
import { NotificationModal } from "./NotificationModal";

const TOGGLE_NOTIFICATION_MUTATION = gql`
  mutation SetNotificationEnabled(
    $id: ClientNotificationId!
    $enabled: Boolean!
  ) {
    updateNotification(id: $id, attributes: { enabled: $enabled }) {
      clientNotification {
        ...notificationFields
      }
      errors
    }
  }

  ${notificationFieldsFragment}
`;

export function NotificationSection({
  title,
  description,
  textRewriteVariableSet,
  scheduleTemplate,
  scheduleFootnote,
  allowDeliveryMethodChange = true,
  allowUnitChange = true,
  notification,
  showToggles = true,
  requiredFeaturePlan,
  featureAvailable = true,
}: BaseNotificationProps) {
  const [editing, setEditing] = useState(
    window.location.search.includes("grow_trial") &&
      title.includes("Quote follow-up"),
  );
  const [updateToggleNotification] =
    useMutation<SetNotificationEnabledMutation>(TOGGLE_NOTIFICATION_MUTATION);
  const { updateNotification } = useUpdateNotificationModal();

  const {
    id,
    enabled,
    schedules: { nodes: schedules },
  } = notification;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleEnabling = async (isEnabled: boolean) => {
    await updateToggleNotification({
      variables: {
        id,
        enabled: isEnabled,
      },
    });
  };

  const handleClose = () => {
    setEditing(false);
  };

  if (!notification.featureEnabled.toggleable || !showToggles) {
    return (
      <NotificationTemplateEditor
        title={title}
        description={description}
        editing={editing}
        notification={notification}
        handleEdit={handleEdit}
        handleClose={handleClose}
        requiredFeaturePlan={requiredFeaturePlan}
        featureAvailable={featureAvailable}
        textRewriteVariableSet={textRewriteVariableSet}
      />
    );
  }
  return (
    <>
      {requiredFeaturePlan && (
        <InlineLabel color="lightBlue">{requiredFeaturePlan}</InlineLabel>
      )}
      <FeatureSwitch
        enabled={enabled}
        disabled={!featureAvailable}
        title={title}
        description={description}
        hasSaveIndicator={true}
        onSwitch={handleEnabling}
        onEdit={featureAvailable ? handleEdit : undefined}
        externalLink={true}
      >
        <ScheduleOverview
          scheduleTemplate={scheduleTemplate}
          schedules={schedules}
          type={notification.id}
        />

        <NotificationModal
          title={title}
          open={editing}
          scheduleTemplate={scheduleTemplate}
          scheduleFootnote={scheduleFootnote}
          allowDeliveryMethodChange={allowDeliveryMethodChange}
          allowUnitChange={allowUnitChange}
          notification={notification}
          onRequestClose={handleClose}
          onSave={updateNotification}
          textRewriteVariableSet={textRewriteVariableSet}
        />
      </FeatureSwitch>
    </>
  );
}
