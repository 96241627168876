import { useActor } from "@xstate/react";
import { createContext, useContext } from "react";
import type { ActorRefFrom } from "xstate";
import type { ScheduleCardMachine } from "./scheduleCard.machine";

export const ScheduleCardMachineContext = createContext<
  ActorRefFrom<ScheduleCardMachine> | undefined
>(undefined);

export const useScheduleCardMachine = () => {
  const actor = useContext(ScheduleCardMachineContext);

  return useActor(actor!);
};
