import { screen } from "@testing-library/react";
import { messages } from "./messages";

// Selectors
export function queryModal() {
  return screen.queryByRole("dialog");
}

export function getModal() {
  return screen.getByRole("dialog");
}

export function getCloseButton() {
  return screen.getByLabelText("Close modal");
}

export function getReferralTitle() {
  return screen.getByText(messages.sentDelighterReferralTitle.defaultMessage);
}

export function getReferralButton() {
  return screen.getByText(
    messages.sentDelighterReferralButtonLabel.defaultMessage,
  );
}

export function getDefaultTitle() {
  return screen.getByText(messages.sentDelighterTitle.defaultMessage);
}

export function getDefaultButton() {
  return screen.getByText(messages.sentDelighterButtonLabel.defaultMessage);
}
