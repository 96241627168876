import React from "react";
import {
  Draggable,
  type DraggableProvided,
  type DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import styles from "./styles.module.css";

export interface DraggableWrapperProps {
  lineItemIndex: number;
  reactKey: string;
  children: (
    dragHandle: DraggableProvidedDragHandleProps | undefined,
  ) => React.ReactNode;
}

export function DraggableWrapper({
  lineItemIndex,
  reactKey,
  children,
}: DraggableWrapperProps) {
  return (
    <Draggable key={reactKey} draggableId={reactKey} index={lineItemIndex}>
      {(providedDraggable: DraggableProvided) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <div className={styles.draggableLineItemContainer}>
            {children(providedDraggable.dragHandleProps)}
          </div>
        </div>
      )}
    </Draggable>
  );
}
