/* eslint-disable import/no-internal-modules */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { InputNumber } from "@jobber/components/InputNumber";
import { InputValidation } from "@jobber/components/InputValidation";
import { showToast } from "@jobber/components/Toast";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";
import { useUpdateSchedule } from "../../hooks/useUpdateSchedule";

const ONE_DAY_IN_MINUTES = 1440;
const SAVE_DEBOUNCE_DELAY = 1000;
const MINIMUM_EARLIEST_AVAILABILITY = 0;
const MAXIMUM_EARLIEST_AVAILABILITY = 30;

interface EarliestAvailabilityConfigurationProps {
  earliestAvailabilityMinutes?: number;
}

export function EarliestAvailabilityConfiguration({
  earliestAvailabilityMinutes,
}: EarliestAvailabilityConfigurationProps) {
  const { formatMessage } = useIntl();
  const [earliestAvailability, setEarliestAvailability] = useState(
    (earliestAvailabilityMinutes ?? ONE_DAY_IN_MINUTES) / ONE_DAY_IN_MINUTES,
  );
  const [validationMessages, setValidationMessages] = useState<string>();
  const { editSelfServeScheduleSettings, error } = useUpdateSchedule();

  const debouncedEditSelfServeScheduleSettings = useRef(
    debounce(newScheduleSettings => {
      editSelfServeScheduleSettings(newScheduleSettings).then(
        handleSuccess,
        handleError,
      );
    }, SAVE_DEBOUNCE_DELAY),
  ).current;

  useEffect(() => {
    if (error) {
      setEarliestAvailability(
        (earliestAvailabilityMinutes ?? ONE_DAY_IN_MINUTES) /
          ONE_DAY_IN_MINUTES,
      );
    }
  }, [error, earliestAvailabilityMinutes, formatMessage]);

  const updateEarliestAvailability = useCallback(
    (input: number) => {
      setEarliestAvailability(input);
      if (
        input >= MINIMUM_EARLIEST_AVAILABILITY &&
        input <= MAXIMUM_EARLIEST_AVAILABILITY
      ) {
        setValidationMessages(undefined);
        debouncedEditSelfServeScheduleSettings({
          earliestAvailability: input * ONE_DAY_IN_MINUTES,
        });
      }
    },
    [debouncedEditSelfServeScheduleSettings],
  );

  const handleSuccess = useCallback(() => {
    showToast({ message: formatMessage(messages.saveMessage) });
  }, [formatMessage]);

  const handleError = useCallback(() => {
    setValidationMessages(formatMessage(messages.errorMessage));
  }, [formatMessage]);

  return (
    <ConfigurationSetting title={formatMessage(messages.configurationLabel)}>
      <label aria-label={formatMessage(messages.inputAriaLabel)}>
        {formatMessage(messages.inputPrompt, {
          input: (
            <span className={styles.inlineInputWrapper}>
              <InputNumber
                inline={true}
                keyboard={"numeric"}
                min={MINIMUM_EARLIEST_AVAILABILITY}
                max={MAXIMUM_EARLIEST_AVAILABILITY}
                maxLength={3}
                size="small"
                value={earliestAvailability}
                onChange={updateEarliestAvailability}
                onValidation={setValidationMessages}
              />
            </span>
          ),
        })}
      </label>
      {validationMessages && <InputValidation message={validationMessages} />}
    </ConfigurationSetting>
  );
}
