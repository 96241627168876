import { USER_PERMISSIONS_QUERY } from "~/utilities/contexts/authorization/graphql";
import type {
  Expense,
  JobCostLineItemFragment,
  JobCostTimeSheetEntryFragment,
  JobDetailsFragment,
  User,
} from "~/utilities/API/graphql";
import { mockJobViewNewLineItem } from "~/jobber/lineItems/mocks";
import { pageInfoDetails } from "jobber/workOrders/components/JobCost/hooks/mocks";
import { unroundedSecondsToHours } from "jobber/workOrders/components/JobCost/utils";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import * as LineItemCustomFieldChipsPOM from "~/jobber/customFields/components/LineItemCustomFieldChips/LineItemCustomFieldChips.pom";
import {
  JOB_COST_QUERY,
  JOB_CREATE_LINE_ITEMS,
  JOB_DELETE_LINE_ITEMS,
  JOB_EDIT_LINE_ITEM,
} from "./hooks";

export const mockCurrentUser = {
  __typename: "User",
  id: "NA==",
  labourRate: 0,
  name: {
    first: "John",
    full: "John Lennon",
    last: "Lennon",
  },
};

export interface SetupProps {
  canViewTimeSheetEntries?: boolean;
  canViewExpenses?: boolean;
  canViewJobCosts?: boolean;
  canViewPricing?: boolean;
  timesheets?: JobCostTimeSheetEntryFragment[];
  expenses?: Expense[];
  lineItems?: JobCostLineItemFragment[];
  job?: JobDetailsFragment;
  user?: Pick<User, "id" | "labourRate">;
  jobCreateHasError?: boolean;
  showJobCostDiscoveryCard?: boolean;
}

export function buildMocksForJobCostLoader({
  timesheets,
  canViewTimeSheetEntries,
  expenses,
  canViewExpenses,
  lineItems,
  canViewJobCosts,
  canViewPricing,
  user,
  job,
  jobCreateHasError,
  showJobCostDiscoveryCard,
}: Required<SetupProps>) {
  return [
    generateUserPermissionsQueryMock(),
    generateJobCostQueryMock({
      canViewExpenses,
      canViewJobCosts,
      canViewTimeSheetEntries,
      canViewPricing,
      job,
      expenses,
      lineItems,
      timesheets,
      user,
      jobCreateHasError,
      showJobCostDiscoveryCard,
    }),
    generateProductOrServicesQueryMock(),
    generateJobDeleteLineItemMutationMock({
      jobId: job.id,
      lineItemId: lineItems[0].id,
      hasError: false,
    }),
    generateJobDeleteLineItemMutationMock({
      jobId: job.id,
      lineItemId: lineItems[1].id,
      hasError: true,
    }),
    generateJobEditLineItemMutationMock({
      jobId: job.id,
      lineItem: lineItems[0],
      hasError: false,
      canViewPricing: canViewPricing,
    }),
    generateJobEditLineItemMutationMock({
      jobId: job.id,
      lineItem: lineItems[1],
      hasError: jobCreateHasError,
      canViewPricing: canViewPricing,
    }),
    generateJobCreateLineItemsMutationMock({
      jobId: job.id,
      lineItem: mockJobViewNewLineItem,
      hasError: jobCreateHasError,
      canViewPricing: (canViewPricing = true),
    }),
    LineItemCustomFieldChipsPOM.getProductOrServiceCustomFieldsQueryMock({
      productOrServiceId: "MQ==",
      customFields: [
        {
          __typename: "CustomFieldText",
          id: "1",
          label: "Field 1",
          valueText: "Text Value 1",
          customFieldConfiguration: {
            id: "1",
            app: {
              id: "AppId-1",
              name: "App A",
              logoUrl: "https://app-a-logo.com",
            },
            archived: false,
          },
        },
      ],
    }),
  ];
}

function generateJobEditLineItemMutationMock({
  jobId,
  lineItem,
  hasError,
  canViewPricing,
}: {
  jobId: string;
  lineItem: JobCostLineItemFragment;
  hasError: boolean;
  canViewPricing: boolean;
}) {
  return {
    request: {
      query: JOB_EDIT_LINE_ITEM,
      variables: {
        jobId,
        canViewPricing: canViewPricing,
        input: {
          lineItems: [
            {
              description: lineItem.description,
              name: lineItem.name,
              quantity: lineItem.quantity,
              unitCost: lineItem.unitCost,
              unitPrice: lineItem.unitPrice,
              lineItemId: lineItem.id,
            },
          ],
        },
      },
    },
    result: {
      data: {
        jobEditLineItems: {
          __typename: "JobEditLineItemsPayload",
          modifiedLineItems: hasError
            ? []
            : [
                {
                  __typename: "JobLineItem",
                  ...lineItem,
                  category: "COOL_CATEGORY",
                  image: {
                    __typename: "Image",
                    url: "asd",
                    thumbnailUrl: "asd",
                  },
                },
              ],
          userErrors: hasError
            ? [
                {
                  __typename: "MutationErrors",
                  message: "Couldn't delete line item",
                  path: "Some Path",
                },
              ]
            : [],
        },
      },
    },
  };
}

function generateJobCostQueryMock({
  canViewExpenses,
  canViewJobCosts,
  canViewTimeSheetEntries,
  canViewPricing,
  job,
  user,
  expenses,
  lineItems,
  timesheets,
}: Required<SetupProps>) {
  return {
    request: {
      query: JOB_COST_QUERY,
      variables: {
        jobId: "NQ==",
        canViewJobCosts,
        canViewExpenses,
        canViewTimeSheetEntries,
        canViewPricing,
      },
    },
    result: {
      data: {
        account: {
          __typename: "Account",
          id: "NA==",
          currentUser: user,
        },
        jobView: {
          __typename: "JobView",
          ...job,
          labour: {
            __typename: "JobViewLabour",
            id: job.id,
            totalDuration: timesheets.reduce(
              (prev, curr) => prev + curr.finalDuration,
              0,
            ),
            totalCost: timesheets.reduce(
              (prev, curr) =>
                prev +
                unroundedSecondsToHours(curr.finalDuration) *
                  (curr.labourRate || 0),
              0,
            ),
            items: {
              __typename: "TimeSheetEntryConnection",
              nodes: [...timesheets],
              ...pageInfoDetails,
            },
          },
          expenses: {
            __typename: "JobViewExpenses",
            id: job.id,
            total: expenses.reduce((prev, curr) => prev + (curr.total || 0), 0),
            items: {
              __typename: "ExpenseConnection",
              nodes: [...expenses],
              ...pageInfoDetails,
            },
          },
          lineItems: {
            __typename: "JobViewLineItems",
            id: job.id,
            totalPrice: lineItems.reduce(
              (prev, curr) => prev + (curr.totalPrice || 0),
              0,
            ),
            totalCost: lineItems.reduce(
              (prev, curr) => prev + (curr.totalCost || 0),
              0,
            ),
            items: {
              __typename: "JobViewLineItemConnection",
              nodes: [...lineItems],
              ...pageInfoDetails,
            },
          },
          profit: {
            __typename: "JobCosting",
            id: job.id,
            labourDuration: 0,
            labourCost: 0,
            expenseCost: 0,
            lineItemCost: 0,
            totalCost: 0,
            totalRevenue: 0,
            profitAmount: 0,
            profitPercentage: 0,
          },
        },
      },
    },
  };
}

function generateProductOrServicesQueryMock() {
  return {
    request: {
      query: PRODUCT_OR_SERVICES_QUERY,
      variables: {},
    },
    result: {
      data: {
        products: {
          nodes: [
            {
              __typename: "ProductOrService",
              id: "MQ==",
              name: "Product 1",
              description: "Product 1",
              defaultUnitCost: 0,
              internalUnitCost: 0,
              category: "PRODUCT",
              taxable: true,
            },
          ],
        },
      },
    },
  };
}

function generateJobDeleteLineItemMutationMock({
  jobId,
  lineItemId,
  hasError,
}: {
  jobId: string;
  lineItemId: string;
  hasError: boolean;
}) {
  return {
    request: {
      query: JOB_DELETE_LINE_ITEMS,
      variables: {
        jobId,
        lineItemId,
      },
    },
    result: {
      data: {
        jobDeleteLineItems: {
          __typename: "JobDeleteLineItemsPayload",
          deletedLineItems: hasError
            ? []
            : [
                {
                  __typename: "JobLineItem",
                  id: lineItemId,
                },
              ],
          userErrors: hasError
            ? [
                {
                  __typename: "MutationErrors",
                  message: "Couldn't delete line item",
                  path: "Some Path",
                },
              ]
            : [],
        },
      },
    },
  };
}

function generateJobCreateLineItemsMutationMock({
  jobId,
  lineItem,
  hasError,
  canViewPricing,
}: {
  jobId: string;
  lineItem: JobCostLineItemFragment;
  hasError: boolean;
  canViewPricing: boolean;
}) {
  return {
    request: {
      query: JOB_CREATE_LINE_ITEMS,
      variables: {
        jobId,
        canViewPricing: canViewPricing,
        input: {
          lineItems: [
            {
              description: lineItem.description,
              name: lineItem.name,
              quantity: lineItem.quantity,
              unitCost: lineItem.unitCost,
              unitPrice: lineItem.unitPrice,
              saveToProductsAndServices: false,
              taxable: true,
            },
          ],
        },
      },
    },
    result: {
      data: {
        jobCreateLineItems: {
          __typename: "JobCreateLineItemsPayload",
          createdLineItems: hasError
            ? []
            : [
                {
                  __typename: "JobLineItem",
                  ...lineItem,
                  category: "COOL_CATEGORY",
                  image: {
                    __typename: "Image",
                    url: "asd",
                    thumbnailUrl: "asd",
                  },
                },
              ],
          userErrors: hasError
            ? [
                {
                  __typename: "MutationErrors",
                  message: "Couldn't create line item",
                  path: "Some Path",
                },
              ]
            : [],
        },
      },
    },
  };
}

function generateUserPermissionsQueryMock() {
  return {
    request: {
      query: USER_PERMISSIONS_QUERY,
      variables: {},
    },
    result: {
      data: {
        user: {
          isAccountAdmin: true,
          permissions: {
            nodes: [],
          },
        },
      },
    },
  };
}
