import { gql } from "@apollo/client";

export const GROW_FEATURE_GUIDE_COMPLETED = gql`
  mutation GrowFeatureGuideCompleted {
    setupGuideStatus {
      setupGuide {
        completed
        steps {
          nodes {
            step
          }
        }
      }
    }
  }
`;

export interface GrowFeatureGuideCompletedData {
  setupGuideStatus: {
    setupGuide: {
      completed: boolean;
      steps: {
        nodes: {
          step: string;
        }[];
      };
    };
  };
}
