import { defineMessages } from "react-intl";

export const messages = defineMessages({
  editButton: {
    id: "billing.billingAddress.editButton",
    defaultMessage: "Edit",
    description: "Button text for edit button",
  },
  cardTitle: {
    id: "billing.billingAddress.cardTitle",
    defaultMessage: "Billing Address",
    description: "Title for form section Card",
  },
});
