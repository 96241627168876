import { gql } from "@apollo/client";

export const GLOBAL_MESSAGE_FRAGMENT = gql`
  fragment GlobalMessageFragment on GlobalMessage {
    dismissable
    id
    message
    type
    postMessageLink {
      urlLabel
      url
      message
      openInSameWindow
    }
    expiryDate
    action {
      label
      url
      external
      buttonVariation
      tracking {
        eventName
        properties {
          name
          source
          action
        }
      }
    }
    viewTracking {
      eventName
      properties {
        name
        source
      }
    }
    dismissTracking {
      eventName
      properties {
        name
        source
        action
      }
    }
  }
`;

export const GLOBAL_MESSAGES_QUERY = gql`
  query globalMessagesQuery {
    messages: globalMessages {
      nodes {
        ...GlobalMessageFragment
      }
    }
  }
  ${GLOBAL_MESSAGE_FRAGMENT}
`;

export const GLOBAL_MESSAGES_DISMISS_MUTATION = gql`
  mutation globalMessagesDismissMutation($id: String!) {
    globalMessageDismiss(id: $id) {
      errors {
        message
        path
      }
      messages {
        nodes {
          ...GlobalMessageFragment
        }
      }
    }
  }
  ${GLOBAL_MESSAGE_FRAGMENT}
`;
