import React from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { LARGE_SCREEN_WIDTH } from "jobber/dashboard/components/SetupGuide/constants";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./DonePanel.module.css";
import { messages } from "./messages";

export function DonePanelContent() {
  const isSmallScreen = window.innerWidth < LARGE_SCREEN_WIDTH;
  return (
    <div className={styles.rightContainer}>
      <Content>
        <Content spacing={isSmallScreen ? "base" : "large"}>
          <Heading level={isSmallScreen ? 4 : 3}>
            {messages.growTrialHeading.defaultMessage}
          </Heading>
          <div className={styles.description}>
            <Text>{messages.growTrialSubHeading.defaultMessage}</Text>
          </div>
          <Button
            label={messages.growTrialButton.defaultMessage}
            onClick={() => {
              Amplitude.TRACK_EVENT("CTA Clicked", {
                name: "book_a_demo",
                type: "done",
                source: "connect_to_grow_trial_onboarding_checklist",
              });
            }}
            url="https://calendly.com/checkinwithjobber/booking-a-demo-call-lt"
          />
        </Content>
      </Content>
    </div>
  );
}
