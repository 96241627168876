import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadSizeError: {
    id: "campaign.headerImageUpload.uploadSizeError",
    defaultMessage:
      "The size of the image is too large. Please choose a smaller image.",
    description:
      "Error message for when the user tries to upload an image that is too large",
  },
  uploadHeading: {
    id: "campaign.headerImageUpload.uploadHeading",
    defaultMessage: "Header Image (optional)",
    description: "Heading for the header image upload section",
  },
  uploadSubheading: {
    id: "campaign.headerImageUpload.uploadSubheading",
    defaultMessage:
      "Adds an image to show below your logo. Recommended aspect ratio: Landscape",
    description: "Subheading for the header image upload section",
  },
  uploadButton: {
    id: "campaign.headerImageUpload.uploadButton",
    defaultMessage: "Upload Image",
    description: "Button text displayed to the user",
  },
});
