import { gql, useQuery } from "@apollo/client";
import type { ReceivablesQuery } from "~/utilities/API/graphql";

export const RECEIVABLES_QUERY = gql`
  query Receivables {
    topClientsBalance {
      totalBalance
      numberOfClientsWithBalances
    }
    insightsAndAccountingReceivables {
      averageDaysToGettingPaid
      percentageOfUpcomingJobsOnAutopay
    }
    feature(key: "automatic_payments") {
      available
    }
  }
`;

export function useReceivables() {
  const { data, loading } = useQuery<ReceivablesQuery>(RECEIVABLES_QUERY, {
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
  };
}
