import type { SquareTokenData } from "jobber/payments/providers/TokenProvider";
import type TokenProvider from "jobber/payments/providers/TokenProvider";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { cardOptions } from "./config";

export class SquareWebPaymentProvider
  implements TokenProvider<SquareTokenData>
{
  private squareCard: SquareCard | undefined;

  // `resolve` and `reject` aren't initialized until `createToken` is called.
  private resolve!: (value: SquareTokenData) => void;
  private reject!: (reason: Error) => void;

  constructor({
    params,
  }: {
    form: HTMLFormElement;
    params: { payment_method_public_key: string };
  }) {
    this.build(params.payment_method_public_key)
      .then(card => {
        this.squareCard = card;
      })
      .catch((error: Error) => {
        Rollbar.EXECUTE("Failed to initialize Square SDK", error);
      });
  }

  public async createToken() {
    return new Promise<SquareTokenData>((resolve, reject) => {
      const timeout = setTimeout(() => {
        this.reject(new Error("Timed out creating Square token"));
      }, 60000);

      this.resolve = value => {
        clearTimeout(timeout);
        resolve(value);
      };

      this.reject = e => {
        clearTimeout(timeout);
        Rollbar.EXECUTE("Failed to create Square token", e);
        // The error message in this Error will get surfaced to the UI
        reject(new Error("Payment failed. Please try again later."));
      };

      if (this.squareCard === undefined) {
        this.reject(new Error("Initialization error"));
      } else {
        this.squareCard
          .tokenize()
          .then((tokenResult: TokenResult) => {
            const result: SquareTokenData = {
              square_token: tokenResult.token,
              last_four: tokenResult.details.card.last4,
              credit_card_number: tokenResult.details.card.last4,
              expiration_month: tokenResult.details.card.expMonth.toString(),
              expiration_year: tokenResult.details.card.expYear.toString(),
            };
            this.resolve(result);
          })
          .catch((e: Error) => this.reject(e));
      }
    });
  }

  private async build(publicKey: string) {
    const payments = Square.payments(publicKey);
    const card: SquareCard = await payments.card(cardOptions);

    try {
      await card.attach("#square-card-container");
    } catch (error) {
      const errorMessage: string = (error as Error).message;
      return Promise.reject(errorMessage);
    }

    return card;
  }
}
