import { defineMessages } from "react-intl";

export const pendingStateMessages = defineMessages({
  header: {
    id: "ukKycPendingState.header",
    defaultMessage: "Your application has been submitted!",
    description: "Your application has been submitted!",
  },
  body: {
    id: "ukKycPendingState.body",
    defaultMessage:
      "We will notify you when the approval process is complete, which typically takes up to three days.",
    description:
      "We will notify you when the approval process is complete, which typically takes up to three days.",
  },
});
