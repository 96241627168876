import { Checkbox } from "@jobber/components/Checkbox";
import { Text } from "@jobber/components/Text";
import { Option, Select } from "@jobber/components/Select";
import React, { useState } from "react";
import { useReplyToInformation } from "jobber/email/EmailReplyToInput/hooks/useReplyToInformation";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./EmailReplyToInput.module.css";

export interface EmailReplyToInputProps {
  replyToSettingsPath: string;
  isUserAdmin: boolean;
  clientId: string;
  workFlowType: string;
  workFlowObjectId: string;
  onChange?(newValue?: string, defaultEmail?: string): void;
}

export function EmailReplyToInputLoader(props: EmailReplyToInputProps) {
  return (
    <APIProvider>
      <EmailReplyToInput {...props} />
    </APIProvider>
  );
}
function EmailReplyToInput({
  replyToSettingsPath,
  isUserAdmin,
  clientId,
  workFlowType,
  workFlowObjectId,
  onChange,
}: EmailReplyToInputProps) {
  const [selected, setSelected] = useState(false);
  const [valueChange, setValueChange] = useState(false);
  const [replyToId, setReplyToId] = useState<string | undefined>();
  const { replyToInformation } = useReplyToInformation(
    btoa(clientId),
    getWorkFlowName(workFlowType),
    btoa(workFlowObjectId),
  );

  if (!replyToInformation) {
    return <></>;
  }

  function checkboxChanged() {
    setSelected(!selected);
  }

  const selectionChange = (newValue: string) => {
    setValueChange(true);

    if (onChange) {
      const selectedOption = replyToInformation.replyToOptions.find(
        option => option.id === newValue,
      );

      const newEmail = selectedOption?.email?.raw;

      const defaultReplyToEmail = replyToInformation.defaultReplyTo?.email?.raw;

      if (
        selectedOption &&
        selectedOption.id !== replyToInformation.defaultReplyTo?.id
      ) {
        if (newValue === btoa("company")) {
          setReplyToId("");
        } else {
          setReplyToId(selectedOption.id);
        }
      } else {
        setReplyToId(undefined);
      }
      onChange(newEmail, defaultReplyToEmail);
    }
  };

  const options = replyToInformation.replyToOptions.map(option => {
    return (
      <Option key={option.id} value={option.id}>
        {`${option.name} (${option?.email?.raw})`}
      </Option>
    );
  });

  return (
    <>
      {replyToId !== undefined && (
        <input
          type="hidden"
          name="reply_tos[]"
          data-testid="reply_tos"
          value={replyToId}
        />
      )}
      <div className={styles.dropdown}>
        <Select
          placeholder="Send replies to"
          defaultValue={replyToInformation?.defaultReplyTo?.id}
          onChange={selectionChange}
        >
          {options}
        </Select>
      </div>

      {valueChange && isUserAdmin && (
        <div className={styles.checkbox}>
          <Checkbox
            name="update_global_ownership"
            checked={selected}
            onChange={checkboxChanged}
            label={`Use this email for all future ${getWorkFlowName(
              workFlowType,
            ).toLowerCase()}s.`}
            value={selected.toString()}
          />
          <Text>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={replyToSettingsPath}
            >
              View Email reply settings
            </a>
          </Text>
        </div>
      )}
    </>
  );
}

function getWorkFlowName(workFlowObject: string) {
  let workFlowName = "";
  switch (workFlowObject) {
    case "WorkOrder":
      workFlowName = "JOB";
      break;
    case "WorkRequest":
      workFlowName = "REQUEST";
      break;
    default:
      workFlowName = workFlowObject.toUpperCase();
  }
  return workFlowName;
}
