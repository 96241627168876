import React from "react";
import { Heading } from "@jobber/components/Heading";
import { ElongatedCircle } from "./components/ElongatedCircle";

export const facts = {
  defaultFact: {
    copy: (
      <Heading level={1}>
        Business owners who use Jobber save{" "}
        <ElongatedCircle>7 hours</ElongatedCircle> a week
      </Heading>
    ),
  },
};
