import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import React from "react";
import styles from "./InputDuration.module.css";

interface InputDurationProps {
  duration?: {
    hours?: number;
    minutes?: number;
  };
  onChange: (newValues: { hours?: number; minutes?: number }) => void;
  description?: string;
  disabled?: boolean;
}

export function InputDuration({
  onChange,
  duration = { hours: 0, minutes: 0 },
  description,
  disabled,
}: InputDurationProps) {
  function handleChange(touchedField: string, incomingValue: string) {
    const parsedIncomingValue = parseInt(incomingValue || "0", 10);
    const minutesOverflow =
      touchedField === "minutes" && parsedIncomingValue > 59;

    if (Number.isNaN(parsedIncomingValue) || minutesOverflow) {
      return;
    }

    onChange({
      minutes:
        touchedField === "minutes" ? parsedIncomingValue : duration.minutes,
      hours: touchedField === "hours" ? parsedIncomingValue : duration.hours,
    });
  }

  return (
    <div>
      <div className={styles.fieldsWrapper} aria-hidden="true">
        <div className={styles.fieldWrapper} aria-hidden="true">
          <InputText
            name="hours"
            value={duration?.hours}
            onChange={(hours: string) => handleChange("hours", hours)}
            suffix={{ label: "hours" }}
            validations={{
              pattern: {
                value: /^[0-9]*$/,
                message: "Hours must be a whole number.",
              },
              max: {
                value: 99999,
                message: "Hours must be less than 100 thousand",
              },
            }}
            disabled={disabled}
          />
        </div>
        <div className={styles.fieldWrapper} aria-hidden="true">
          <InputText
            name="minutes"
            value={duration?.minutes}
            onChange={(minutes: string) => handleChange("minutes", minutes)}
            suffix={{ label: "minutes" }}
            disabled={disabled}
          />
        </div>
      </div>
      {description && (
        <div className={styles.description}>
          <Text size="small" variation="subdued">
            {description}
          </Text>
        </div>
      )}
    </div>
  );
}
