import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  InvoiceDetailFilterInput,
  InvoiceDetailTotalsQuery,
  InvoiceDetailTotalsQueryVariables,
} from "~/utilities/API/graphqlFranchiseManagement";
import { INVOICE_DETAIL_TOTALS_QUERY } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/InvoiceDetailReport.graphql";

interface ReturnedInvoiceDetailTotalsReportData {
  data?: InvoiceDetailTotalsQuery;
  loading: boolean;
  error?: ApolloError;
  fetchInvoiceDetailTotals(filter: InvoiceDetailFilterInput): void;
}

export function useInvoiceDetailTotalsData(): ReturnedInvoiceDetailTotalsReportData {
  const [fetchInvoiceDetailTotals, { data, loading, error }] = useLazyQuery<
    InvoiceDetailTotalsQuery,
    InvoiceDetailTotalsQueryVariables
  >(INVOICE_DETAIL_TOTALS_QUERY);

  const _fetchInvoiceDetailTotals = (filter: InvoiceDetailFilterInput) =>
    fetchInvoiceDetailTotals({
      variables: {
        filter,
      },
    });

  return {
    fetchInvoiceDetailTotals: _fetchInvoiceDetailTotals,
    data,
    loading,
    error,
  };
}
