import { strFormatDate } from "@jobber/components/FormatDate";
import { FormatTime } from "@jobber/components/FormatTime";
import {
  InlineLabel,
  type InlineLabelColors,
} from "@jobber/components/InlineLabel";
import React, { type ReactElement, type ReactNode } from "react";
import classnames from "classnames";
import {
  CLIENTS_PATH,
  INVOICES_PATH,
  QUOTES_PATH,
} from "jobber/features/PaymentDisputes/components/DisputeDetailsList/constants";
import {
  DISPUTE_CATEGORIES,
  DISPUTE_REASONS,
  DISPUTE_STATUSES,
} from "jobber/features/PaymentDisputes/utils/types";
import {
  type DisputeCategory,
  type DisputeReason,
  DisputeStatus,
  type GetDisputeQueryQuery,
  type Invoice,
  type JobberPaymentsCreditCardPaymentRecord,
  type Quote,
} from "~/utilities/API/graphql";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./disputeUtils.module.css";
import { SupportingFileLink } from "../components/SupportingFileLink";

export type DisputeType = GetDisputeQueryQuery["jobberPaymentsDispute"];
export const getDisputeStatusLabelColor = (
  disputeStatus: string,
): InlineLabelColors => {
  switch (disputeStatus) {
    case DisputeStatus.UNDER_REVIEW:
    case DisputeStatus.WARNING_UNDER_REVIEW:
      return "yellow";
    case DisputeStatus.LOST:
      return "lightBlue";
    case DisputeStatus.WON:
      return "green";
    case DisputeStatus.WARNING_CLOSED:
      return "greyBlue";
    case DisputeStatus.CHARGE_REFUNDED:
      return "orange";
    default:
      return "red";
  }
};

export const getDecodedId = (id: string | undefined): string => {
  if (id) {
    return decodeId(id);
  }
  return "";
};

export const getClientUrl = (clientId: string | undefined): string => {
  return `${CLIENTS_PATH}/${getDecodedId(clientId)}`;
};

export const getInvoiceUrl = (
  invoiceDetails: Partial<Invoice> | undefined,
): string => {
  return `${INVOICES_PATH}/${getDecodedId(invoiceDetails?.id)}`;
};

export const getPaymentUrl = (
  paymentRecordId: string | undefined,
  clientId: string | undefined,
) => {
  return `${CLIENTS_PATH}/${getDecodedId(
    clientId,
  )}/balance_adjustments/${getDecodedId(paymentRecordId)}`;
};

export const getQuoteUrl = (
  quoteDetails: Partial<Quote> | undefined,
): string => {
  return `${QUOTES_PATH}/${getDecodedId(quoteDetails?.id)}`;
};

export const getClaimUrl = () => {
  return "/";
};

export const getDisputeInvoiceOrQuoteNumber = (
  invoice: Invoice | undefined,
  quote: Quote | undefined,
) => {
  if (invoice) {
    return "Invoice #" + invoice.invoiceNumber;
  } else if (quote) {
    return "Quote #" + quote.quoteNumber;
  } else {
    return "—";
  }
};

export const disputeCategory = (category: DisputeCategory): string => {
  return DISPUTE_CATEGORIES[category];
};

export const disputeReason = (reason: DisputeReason): string => {
  return DISPUTE_REASONS[reason];
};

export const getDisputeStatusLabel = (
  disputeStatus: DisputeStatus,
  disputeEvidenceDueBy?: string,
): string | ReactElement => {
  if (
    [
      DisputeStatus.NEEDS_RESPONSE,
      DisputeStatus.WARNING_NEEDS_RESPONSE,
    ].includes(disputeStatus) &&
    disputeEvidenceDueBy
  ) {
    return (
      <>
        Respond by {strFormatDate(new Date(disputeEvidenceDueBy), false)},{" "}
        <FormatTime time={disputeEvidenceDueBy} />
      </>
    );
  } else {
    return DISPUTE_STATUSES[disputeStatus];
  }
};

export const renderDisputeStatus = (
  status: DisputeStatus,
  evidenceDate?: string,
): ReactNode => {
  return (
    <InlineLabel key="status" color={getDisputeStatusLabelColor(status)}>
      {getDisputeStatusLabel(status, evidenceDate)}
    </InlineLabel>
  );
};

export const disputeAmount = (amount: number, currencySymbol = "$") => {
  return formatCurrency(amount, currencySymbol);
};

const cardHolder = (dispute: DisputeType) => {
  const creditCardPaymentRecord =
    dispute?.paymentRecord as JobberPaymentsCreditCardPaymentRecord;
  const cardholderName = creditCardPaymentRecord?.nameOnCard;
  return cardholderName ? `${cardholderName}` : "—";
};

const getInvoiceQuoteDetails = (
  invoiceDetails: Partial<Invoice> | undefined,
  quoteDetails: Partial<Quote> | undefined,
): [string, string | ReactNode][] => {
  const invoiceQuoteDetails: [string, string | ReactNode][] = [];
  if (!quoteDetails && !invoiceDetails) {
    return [["Invoice", "—"]];
  }
  if (quoteDetails) {
    invoiceQuoteDetails.push([
      "Quote",
      <a
        key="quote"
        href={getQuoteUrl(quoteDetails)}
        target="_blank"
        rel="noreferrer"
      >
        #{quoteDetails.quoteNumber}
      </a>,
    ]);
  }
  if (invoiceDetails) {
    invoiceQuoteDetails.push([
      "Invoice",
      <a
        key="invoice"
        href={getInvoiceUrl(invoiceDetails)}
        target="_blank"
        rel="noreferrer"
      >
        #{invoiceDetails.invoiceNumber}
      </a>,
    ]);
  }
  return invoiceQuoteDetails;
};

const paymentDetails = (dispute: DisputeType): ReactElement => {
  return (
    <a
      key="payment"
      href={getPaymentUrl(
        dispute?.paymentRecord.id,
        dispute?.paymentRecord.client?.id,
      )}
      target="_blank"
      rel="noreferrer"
    >
      View payment details
    </a>
  );
};

const clientDetails = (dispute: DisputeType): React.ReactElement | string => {
  const client = dispute?.paymentRecord.client;
  return client ? (
    <a
      key="client"
      href={getClientUrl(client.id)}
      target="_blank"
      rel="noreferrer"
    >
      {`${client.firstName} ${client.lastName}`}
    </a>
  ) : (
    "—"
  );
};

const claimDetails = (onViewClaimDetailsClick: () => void): ReactElement => {
  return (
    <SupportingFileLink
      customClassName={classnames(styles.viewClaimDetailsContainer)}
      onViewSupportingFileClick={onViewClaimDetailsClick}
      supportingFileLinkText={"View claim details"}
      ariaLabel={"View claim details button chargeback details"}
    />
  );
};

const getResolutionStatusLabel = (status: DisputeStatus) => {
  switch (status) {
    case DisputeStatus.LOST:
      return "Lost on";
    case DisputeStatus.WON:
      return "Won on";
    case DisputeStatus.WARNING_CLOSED:
      return "Closed on";
  }
};

const getDateDetails = (dispute: DisputeType) => {
  const detailDates: [string, string | ReactNode][] = [];
  if (!dispute) {
    return detailDates;
  } else {
    const disputedAtDate = strFormatDate(new Date(dispute.disputedAt));

    detailDates.push(["Disputed on", disputedAtDate]);
  }
  const resolutionLabel = getResolutionStatusLabel(dispute?.status);
  if (resolutionLabel) {
    const resolutionReceivedAtDate = dispute?.resolutionReceivedAt
      ? strFormatDate(new Date(dispute?.resolutionReceivedAt))
      : "—";
    detailDates.push([resolutionLabel, resolutionReceivedAtDate]);
  }
  return detailDates;
};

export const disputeClientName = (dispute: DisputeType): string => {
  const client = dispute?.paymentRecord.client;
  const firstName = client?.firstName;
  const lastName = client?.lastName;
  const fullName = `${firstName} ${lastName}`;
  return fullName.trim();
};

export const disputeClientPrimaryEmail = (dispute: DisputeType): string => {
  const client = dispute?.paymentRecord.client;
  const emails = client?.emails;
  const primaryEmail = emails?.find(email => email.primary);
  return primaryEmail ? primaryEmail.address : "";
};

export const disputeClientBillingAddress = (dispute: DisputeType): string => {
  const billingAddress = dispute?.paymentRecord.client?.billingAddress;
  return billingAddress
    ? `${billingAddress.street}\n${billingAddress.city}, ${billingAddress.province} ${billingAddress.postalCode}`
    : "";
};

export const getDisputeDetailsListData = (
  dispute: NonNullable<DisputeType>,
  onViewClaimDetailsClick: () => void,
): [string, string | ReactNode][] => {
  return [
    ["Status", renderDisputeStatus(dispute.status, dispute.evidenceDueBy)],
    ...getDateDetails(dispute),
    ["Type", disputeCategory(dispute.category)],
    ["Reason", disputeReason(dispute.reason)],
    hasIssuerEvidence(dispute)
      ? ["Chargeback claim", claimDetails(onViewClaimDetailsClick)]
      : ["Chargeback claim", "—"],
    ["Disputed amount", disputeAmount(dispute.amount, "$")],
    ["Cardholder name", cardHolder(dispute)],
    ["Payment", paymentDetails(dispute)],
    ["Client", clientDetails(dispute)],
    ...getInvoiceQuoteDetails(
      dispute.paymentRecord?.invoice,
      dispute.paymentRecord?.quote,
    ),
  ];
};

export const hasIssuerEvidence = (
  dispute: NonNullable<DisputeType>,
): boolean => {
  return !!dispute?.issuerEvidences?.nodes?.length;
};
