import React from "react";
import { Text } from "@jobber/components/Text";
import styles from "./HelpItem.module.css";

export interface HelpItemProps {
  /** Label of the help article */
  label: string;
  /** URL of the help article */
  url: string;
  /** Open article in new tab? */
  external?: boolean;
}

export function HelpItem({ label, url, external }: HelpItemProps) {
  return (
    <li className={styles.helpListItem}>
      <Text>
        <a
          className={styles.helpItem}
          href={url}
          target={external ? "_blank" : "_self"}
          rel="noreferrer"
        >
          {label}
        </a>
      </Text>
    </li>
  );
}
