import React, { useEffect, useMemo } from "react";
import { InputText } from "@jobber/components/InputText";
import { Option, Select } from "@jobber/components/Select";
import type { AddressValues } from "./types";

type CommonInputProps = Pick<
  React.ComponentProps<typeof InputText> | React.ComponentProps<typeof Select>,
  Extract<
    keyof React.ComponentProps<typeof InputText>,
    keyof React.ComponentProps<typeof Select>
  >
>;

type InputProvinceProps = Omit<CommonInputProps, "value" | "onChange"> & {
  provinces: Array<AddressValues["zipOrPostalCode"]>;
  value?: AddressValues["zipOrPostalCode"];
  onChange(province: Required<AddressValues>["zipOrPostalCode"]): void;
};

export function InputProvince({
  provinces,
  value,
  onChange,
  ...commonInputProps
}: InputProvinceProps) {
  const valueInProvincesArray = useMemo(
    () => !value || provinces.includes(value),
    [value, provinces],
  );

  useEffect(() => {
    if (provinces[0] && !value) {
      onChange(provinces[0]);
    }
  }, [provinces, value, onChange]);

  return provinces.length > 0 ? (
    <Select
      value={value}
      onChange={newValue => onChange(newValue.toString())}
      {...commonInputProps}
    >
      {!valueInProvincesArray && <Option value={value}>{value}</Option>}
      {provinces.map(province => (
        <Option key={province} value={province}>
          {province}
        </Option>
      ))}
    </Select>
  ) : (
    <InputText
      value={value}
      onChange={newValue => onChange(newValue.toString())}
      {...commonInputProps}
    />
  );
}
