import React, { type ReactNode } from "react";

interface Values {
  duration: ReactNode | ReactNode[];
  method: ReactNode | ReactNode[];
  time: ReactNode | ReactNode[];
}

interface NotificationStringTemplateProps {
  template: string;
  values?: Values;
}

export function NotificationStringTemplate({
  template,
  values,
}: NotificationStringTemplateProps) {
  const textArray = template.split(" ");

  const transposeText = (text: string): ReactNode => {
    if (values) {
      switch (text) {
        case "{duration}":
          return values.duration;
        case "{method}":
          return values.method;
        case "{time}":
          return values.time;
        default:
          return text;
      }
    } else {
      return text;
    }
  };

  return (
    <>
      {textArray.map((t: string, key: number) => (
        <React.Fragment key={key}>{transposeText(t)} </React.Fragment>
      ))}
    </>
  );
}
