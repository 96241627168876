import type { ResourceLinkProps } from "./types";

export const defaultResources: ResourceLinkProps[] = [
  {
    title:
      "How to Attract More Customers on Google Using Reviews (And 10x Your Reviews)",
    link: "https://getjobber.com/academy/attract-customers-with-google-reviews/",
    type: "article",
  },
  {
    title: "How to Respond to a Negative Review: Response Examples & Templates",
    link: "https://getjobber.com/academy/negative-review-response-examples/",
    type: "article",
  },
];
