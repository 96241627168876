import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./messages";

interface JobberLogoProps {
  className: string;
}

export const JobberLogo: React.FC<JobberLogoProps> = ({ className }) => {
  const { formatMessage } = useIntl();

  return (
    <div aria-label={formatMessage(messages.jobberLogoAltText)}>
      <svg
        className={className}
        fill="currentColor"
        x="0px"
        y="0px"
        width="244px"
        height="40px"
        viewBox="0 0 244 40"
      >
        <g>
          <path
            d="M76,5.4h-7.5c-0.5,0-0.9,0.4-0.9,0.9v17.4c0,1.1-0.2,2-0.7,2.7c-0.5,0.7-1.3,1-2.4,1c-1.1,0-1.9-0.4-2.4-1.1
    c-0.4-0.6-0.7-1.4-0.8-2.4c-0.1-0.5-0.4-0.8-0.9-0.8H53c-0.5,0-1,0.5-0.9,1c0.3,3.5,1.3,6.2,3.2,8.1c2.1,2.1,5.1,3.2,9.2,3.2
    c3.8,0,6.9-1,9.1-3c2.2-2,3.3-5,3.3-9v-17C76.9,5.8,76.5,5.4,76,5.4z"
          />
          <path
            d="M110.6,8.7c-1.2-1.3-2.8-2.3-4.6-3c-1.8-0.7-3.9-1-6.3-1c-2.4,0-4.6,0.4-6.4,1.1c-1.9,0.7-3.4,1.8-4.7,3.1
    c-1.3,1.3-2.2,3-2.8,4.9c-0.6,1.9-0.9,4-0.9,6.4c0,2.4,0.3,4.5,0.9,6.4c0.6,1.9,1.6,3.5,2.8,4.8c1.2,1.3,2.8,2.3,4.6,3
    c1.8,0.7,3.9,1,6.3,1c2.4,0,4.6-0.4,6.4-1.1c1.9-0.7,3.4-1.8,4.6-3.1c1.2-1.3,2.2-3,2.8-4.9c0.6-1.9,1-4.1,1-6.4
    c0-2.4-0.3-4.5-0.9-6.4C112.7,11.6,111.8,10,110.6,8.7z M99.6,27.3c-3.4,0-5.1-2.5-5.1-7.4s1.7-7.4,5.1-7.4s5.1,2.5,5.1,7.4
    S103,27.3,99.6,27.3z"
          />
          <path
            d="M143.9,19.7c-0.5-0.3-0.6-1.1-0.1-1.5c0.7-0.5,1.2-1.2,1.6-1.8c0.6-1,0.9-2.2,0.9-3.6c0-1-0.2-2-0.5-2.9
    c-0.3-0.9-0.9-1.7-1.7-2.4c-0.8-0.7-1.8-1.2-3.1-1.6c-1.3-0.4-2.9-0.6-4.9-0.6h-12.7c-0.5,0-0.9,0.4-0.9,0.9v27.4
    c0,0.5,0.4,0.9,0.9,0.9h12.4c2.1,0,3.9-0.2,5.3-0.7c1.4-0.5,2.6-1.1,3.5-1.9c0.9-0.8,1.5-1.7,1.9-2.7c0.4-1,0.6-2.1,0.6-3.3
    C147.1,23.2,146.1,21.1,143.9,19.7z M131,12.9c0-0.5,0.4-0.9,0.9-0.9h2.7c1.1,0,1.9,0.2,2.3,0.6c0.4,0.4,0.6,0.9,0.6,1.6
    c0,0.7-0.2,1.3-0.6,1.6c-0.4,0.4-1.2,0.6-2.3,0.6h-2.7c-0.5,0-0.9-0.4-0.9-0.9V12.9z M137.6,27.2c-0.5,0.5-1.4,0.7-2.8,0.7h-2.9
    c-0.5,0-0.9-0.4-0.9-0.9v-3.5c0-0.5,0.4-0.9,0.9-0.9h2.9c1.4,0,2.3,0.2,2.8,0.7c0.5,0.5,0.7,1.1,0.7,2
    C138.4,26.1,138.1,26.7,137.6,27.2z"
          />
          <path
            d="M176.6,19.7c-0.5-0.3-0.6-1.1-0.1-1.5c0.7-0.5,1.2-1.2,1.6-1.8c0.6-1,0.9-2.2,0.9-3.6c0-1-0.2-2-0.5-2.9
    c-0.3-0.9-0.9-1.7-1.7-2.4c-0.8-0.7-1.8-1.2-3.1-1.6c-1.3-0.4-2.9-0.6-4.9-0.6h-12.7c-0.5,0-0.9,0.4-0.9,0.9v27.4
    c0,0.5,0.4,0.9,0.9,0.9h12.4c2.1,0,3.9-0.2,5.3-0.7c1.4-0.5,2.6-1.1,3.5-1.9c0.9-0.8,1.5-1.7,1.9-2.7c0.4-1,0.6-2.1,0.6-3.3
    C179.8,23.2,178.7,21.1,176.6,19.7z M163.6,12.9c0-0.5,0.4-0.9,0.9-0.9h2.7c1.1,0,1.9,0.2,2.3,0.6c0.4,0.4,0.6,0.9,0.6,1.6
    c0,0.7-0.2,1.3-0.6,1.6c-0.4,0.4-1.2,0.6-2.3,0.6h-2.7c-0.5,0-0.9-0.4-0.9-0.9V12.9z M170.3,27.2c-0.5,0.5-1.4,0.7-2.8,0.7h-2.9
    c-0.5,0-0.9-0.4-0.9-0.9v-3.5c0-0.5,0.4-0.9,0.9-0.9h2.9c1.4,0,2.3,0.2,2.8,0.7c0.5,0.5,0.7,1.1,0.7,2
    C171,26.1,170.8,26.7,170.3,27.2z"
          />
          <path
            d="M209.3,27.4h-11.5c-0.5,0-0.9-0.4-0.9-0.9v-2.2c0-0.5,0.4-0.9,0.9-0.9h9.9c0.5,0,0.9-0.4,0.9-0.9v-5.3
    c0-0.5-0.4-0.9-0.9-0.9h-9.9c-0.5,0-0.9-0.4-0.9-0.9v-1.9c0-0.5,0.4-0.9,0.9-0.9h10.8c0.5,0,0.9-0.4,0.9-0.9V6.3
    c0-0.5-0.4-0.9-0.9-0.9h-19.9c-0.5,0-0.9,0.4-0.9,0.9v27.4c0,0.5,0.4,0.9,0.9,0.9h20.1c0.5,0,0.9-0.4,0.9-0.8l0.5-5.3
    C210.3,27.9,209.9,27.4,209.3,27.4z"
          />
          <path
            d="M243.8,33.2l-4.9-9.1c-0.2-0.4-0.1-0.9,0.2-1.2c1.1-0.8,1.9-1.8,2.5-3c0.7-1.4,1.1-3.1,1.1-5
    c0-1.3-0.2-2.6-0.6-3.7c-0.4-1.2-1.1-2.2-2-3c-0.9-0.9-2.2-1.5-3.7-2c-1.5-0.5-3.4-0.8-5.6-0.8h-11.6c-0.5,0-0.9,0.4-0.9,0.9v27.4
    c0,0.5,0.4,0.9,0.9,0.9h6.9c0.5,0,0.9-0.4,0.9-0.9v-7.5c0-0.5,0.4-0.9,0.9-0.9h1.8c0.3,0,0.7,0.2,0.8,0.5l4.2,8.4
    c0.2,0.3,0.5,0.5,0.8,0.5h7.4C243.7,34.6,244.1,33.8,243.8,33.2z M233.1,17.7c-0.6,0.5-1.6,0.8-2.9,0.8H228c-0.5,0-0.9-0.4-0.9-0.9
    v-4.4c0-0.5,0.4-0.9,0.9-0.9h2.2c1.4,0,2.4,0.3,2.9,0.8c0.6,0.5,0.9,1.3,0.9,2.3C234,16.4,233.7,17.2,233.1,17.7z"
          />
          <path
            d="M34.3,0.3C34.1,0.1,33.8,0,33.5,0H14.8C6.7,0,0.1,6.6,0.1,14.7v18.7c0,0.3,0.1,0.6,0.3,0.8L6,39.7
    C6.2,39.9,6.5,40,6.8,40h18.7c8.1,0,14.7-6.6,14.7-14.7V6.7c0-0.3-0.1-0.6-0.3-0.8L34.3,0.3z M5.5,14.7v19.6c0,0.3-0.4,0.5-0.7,0.3
    l-1.8-1.8c-0.1-0.1-0.1-0.2-0.1-0.3V14.7c0-6.5,5.3-11.8,11.8-11.8h16.8c0.2,0,0.4,0.2,0.4,0.4v22.1c0,3.6-2.9,6.5-6.5,6.5H14.2
    c-0.3,0-0.5-0.3-0.4-0.5l0.7-1.8c0.1-0.2,0.2-0.3,0.4-0.3h10.1c2.5,0,4.5-2,4.5-4.5V5.7c0-0.2-0.2-0.4-0.4-0.4H14.8
    C9.6,5.4,5.5,9.5,5.5,14.7z M13.7,26.1V15.2c0-0.9,0.8-1.7,1.7-1.7h10.9c0.2,0,0.4,0.2,0.4,0.4v10.9c0,0.9-0.8,1.7-1.7,1.7H14
    C13.8,26.5,13.7,26.3,13.7,26.1z M37.3,25.3c0,6.5-5.3,11.8-11.8,11.8H8.7c-0.2,0-0.4-0.2-0.4-0.4V14.7c0-3.6,2.9-6.5,6.5-6.5H26
    c0.3,0,0.5,0.3,0.4,0.5l-0.7,1.8c-0.1,0.2-0.2,0.3-0.4,0.3H15.3c-2.5,0-4.5,2-4.5,4.5v19.1c0,0.2,0.2,0.4,0.4,0.4h14.2
    c5.1,0,9.3-4.2,9.3-9.3V5.7c0-0.3,0.4-0.5,0.7-0.3l1.8,1.8c0.1,0.1,0.1,0.2,0.1,0.3V25.3z"
          />
        </g>
      </svg>
    </div>
  );
};
