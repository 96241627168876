import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { RecommendationButtons } from ".";
import styles from "./styles.module.css";
import type { LocationCoordinates } from "./RecommendationsSchedulingContainer";
import {
  type SchedulingAvailabilityResponse,
  useScheduleCardState,
} from "../hooks";
import { SerializedFieldsForRails } from "../SerializeScheduleStateForRails";
import { RecurrentScheduling } from "../RecurrentScheduling";
import { SchedulingDateControls } from "../SchedulingDateControls";
import { SchedulingTimeControls } from "../SchedulingTimeControls";
import type { RepeatOption } from "../../OneOffScheduler";
import { ArrivalWindowPreview } from "../../ArrivalWindowPreview";
import { TeamAssignmentCard } from "../../TeamAssignmentCard";
import {
  AddUnscheduledVisitCheckbox,
  ScheduleLaterCheckbox,
} from "../DeferScheduling";

export interface RecommendationsSchedulingProps {
  repeatOptions: RepeatOption[];
  arrivalWindowParameters: { centered: boolean; duration: number | null };
  railsFormKey: string;
  initialNotifyTeam: boolean;
  recommendationsResponse: SchedulingAvailabilityResponse;
  locationCoordinates?: LocationCoordinates;
}

export function RecommendationsScheduling({
  arrivalWindowParameters,
  initialNotifyTeam,
  railsFormKey,
  repeatOptions,
  recommendationsResponse,
  locationCoordinates,
}: RecommendationsSchedulingProps) {
  const { stateMatches, schedulingState } = useScheduleCardState();
  const shouldShowRecurrentOptions =
    stateMatches("dates.enabled.multiDay") &&
    stateMatches("scheduling.isScheduled");

  return (
    <>
      <Card header={{ title: "Schedule" }}>
        <Content spacing="small">
          <div className={styles.recommendationsAndSchedulingContainer}>
            <div className={styles.recommendationsSchedulingControls}>
              <SchedulingDateControls
                heading={
                  <div className={styles.dateHeaderContainer}>
                    <Heading level={5}>Date and time</Heading>
                    <ScheduleLaterCheckbox />
                  </div>
                }
                showPlaceHolders
              />
              <SchedulingTimeControls renderHeading={false} />
              {shouldShowRecurrentOptions && (
                <RecurrentScheduling
                  repeatOptions={repeatOptions}
                  showPlaceholder
                />
              )}
              {schedulingState.arrivalWindowsEnabled && (
                <div className={styles.spanGridContainer}>
                  <ArrivalWindowPreview
                    jobScheduleType="oneOff"
                    existingJobArrivalWindow={arrivalWindowParameters.duration}
                    existingJobArrivalWindowCentered={
                      arrivalWindowParameters.centered
                    }
                  />
                </div>
              )}
              <AddUnscheduledVisitCheckbox />
              <div className={styles.spanGridContainer}>
                <Heading level={5}>Assign to</Heading>
                <TeamAssignmentCard
                  initialNotifyTeam={initialNotifyTeam}
                  railsFormKey={railsFormKey}
                  renderCardHeader={false}
                />
              </div>
            </div>
            <RecommendationButtons
              loading={!!recommendationsResponse.loading}
              recommendations={recommendationsResponse.recommendations}
              hasError={!!recommendationsResponse.errors.length}
              locationCoordinates={locationCoordinates}
            />
          </div>
        </Content>
      </Card>
      <SerializedFieldsForRails
        state={schedulingState}
        stateMatches={stateMatches}
        railsFormKey={railsFormKey}
        recommendationsResponse={recommendationsResponse}
      />
    </>
  );
}
