import React from "react";
import type { PropsWithChildren } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Flex } from "@jobber/components/Flex";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { messages } from "./messages";

type ConfigurationSettingProps = PropsWithChildren<{
  title: string;
  description?: string | JSX.Element | (string | JSX.Element)[];
  onEdit?: () => void;
}>;

export function ConfigurationSetting({
  title,
  description,
  children,
  onEdit,
}: ConfigurationSettingProps) {
  const { formatMessage } = useIntl();

  return (
    <Flex template={["grow", "shrink"]} align="start">
      <Content spacing="small">
        <Heading level={5}>{title}</Heading>
        {description && <Text>{description}</Text>}
        {children}
      </Content>
      {onEdit && (
        <Button
          onClick={onEdit}
          icon="edit"
          ariaLabel={formatMessage(messages.editButtonAriaLabel, { title })}
          type="tertiary"
          variation="subtle"
        />
      )}
    </Flex>
  );
}
