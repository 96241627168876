import React from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import type {
  CancellationFormAnswer,
  CancellationFormState,
  CancellationFormStepProps,
} from "./CancellationForm.d";
import { Question } from "./CancellationFormQuestion";
import {
  type CancellationFormQuestion,
  FINAL_QUESTION_IDS,
} from "./CancellationFormQuestion.d";
import styles from "./CancellationFormModal.module.css";
import { INITIAL_QUESTION_ID } from "./CancellationFormModal";

// Build the list of questions to display for the dynamic bit of the form,
// using the IDs of the questions that have already been answered, plus the
// `nextQuestion` IDs from those questions. Only the last `nextQuestion` value
// will be relevant (because the others will exist as IDs on answered
// questions), so we filter out duplicate values before returning the list.
const getQuestionIds = (answers: CancellationFormState) => {
  if (answers.length === 0) {
    return [INITIAL_QUESTION_ID];
  } else {
    return answers
      .flatMap(answer => [answer.id, answer.nextQuestion || ""])
      .filter(
        (item, index, array) => item !== "" && array.indexOf(item) === index,
      )
      .filter(item => !FINAL_QUESTION_IDS.includes(item));
  }
};

export function CancellationReasonDetailsStep({
  questions,
  answers,
  onNext,
  onPrevious,
  addAnswer,
  showPrevious,
}: CancellationFormStepProps & { showPrevious: boolean }) {
  function disableNext() {
    return answers.length < 2;
  }

  return (
    <>
      <Content>
        We&apos;re sad to see you go! To help us improve, we&apos;d appreciate
        feedback on why you&apos;d like to cancel your account.
      </Content>
      {getQuestionIds(answers).map((id: string) => {
        const question = questions.find(
          (formQuestion: CancellationFormQuestion) => formQuestion.id === id,
        );
        const existingValue =
          answers.find((answer: CancellationFormAnswer) => answer.id === id)
            ?.value || "";

        return (
          question && (
            <Question
              key={question.id}
              id={question.id}
              type={question.type}
              label={question.label}
              customerText={question.customerText}
              options={question.options}
              value={existingValue}
              nextQuestion={question.nextQuestion}
              addAnswer={addAnswer}
            />
          )
        );
      })}
      <div
        className={
          showPrevious
            ? styles.actionButtonsContainer
            : styles.singleActionButtonContainer
        }
      >
        {showPrevious && (
          <Button label="Previous" onClick={onPrevious} variation="subtle" />
        )}
        <Button label="Next" onClick={onNext} disabled={disableNext()} />
      </div>
    </>
  );
}
