import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import type { IntlFormatters } from "react-intl";
import { useIntl } from "react-intl";
import Lottie from "lottie-react";
import { useCampaignWizardContext } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignWizardProvider";
import { Template } from "~/utilities/API/graphql";
import { MARKETING_REFERRALS_PATH } from "~/jobber/features/Marketing/ClientReferrals/constants";
import { messages } from "./messages";
import sentAnimation from "./sentAnimation.json";
import styles from "./SentDelighterModal.module.css";

export function SentDelighterModal() {
  const { formatMessage } = useIntl();
  const {
    sentDelighter: {
      open,
      setOpen,
      clientCount,
      setClientCount,
      type,
      setType,
    },
  } = useCampaignWizardContext();

  const closeModal = () => {
    setOpen(false);
    setClientCount(null);
    setType(Template.RE_ENGAGE);
  };

  return (
    <Modal
      title={getTitle(formatMessage, type)}
      size="small"
      open={open}
      dismissible={true}
      onRequestClose={closeModal}
      primaryAction={getPrimaryAction(formatMessage, type, closeModal)}
      data-testid="sent-delighter-modal"
    >
      <Content>
        <Lottie animationData={sentAnimation} className={styles.lottie} loop />
        <Text>{getTextBody(formatMessage, type, clientCount)}</Text>
      </Content>
    </Modal>
  );
}

function getTitle(
  formatMessage: IntlFormatters["formatMessage"],
  type: Template,
) {
  switch (type) {
    case Template.REFERRAL:
      return formatMessage(messages.sentDelighterReferralTitle);
    default:
      return formatMessage(messages.sentDelighterTitle);
  }
}

function getTextBody(
  formatMessage: IntlFormatters["formatMessage"],
  type: Template,
  count: string | number | null,
) {
  switch (type) {
    case Template.REFERRAL:
      return formatMessage(messages.sentDelighterReferralText);
    default:
      return count === 0
        ? formatMessage(messages.sentDelighterTextWithoutCount)
        : formatMessage(messages.sentDelighterTextWithCount, {
            count,
          });
  }
}

function getPrimaryAction(
  formatMessage: IntlFormatters["formatMessage"],
  type: Template,
  closeModal: () => void,
) {
  switch (type) {
    case Template.REFERRAL:
      return {
        label: formatMessage(messages.sentDelighterReferralButtonLabel),
        onClick: () => {
          closeModal();
          window.location.href = MARKETING_REFERRALS_PATH;
        },
      };
    default:
      return {
        label: formatMessage(messages.sentDelighterButtonLabel),
        onClick: closeModal,
      };
  }
}
