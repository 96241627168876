import React, { type ProviderProps, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  JobberPaymentsContext,
  type JobberPaymentsContextInitType,
  type JobberPaymentsContextType,
} from "./JobberPaymentsContext";

export function JobberPaymentsContextProvider(
  props: ProviderProps<JobberPaymentsContextInitType>,
) {
  const { value, children } = props;

  const [enabled, setEnabled] = useState(value.enabled);
  const [context, setContext] = useState(initContext);
  const [stripePromise] = useState(initStripe);

  useEffect(() => setContext(initContext), [enabled]);

  return (
    <Elements stripe={stripePromise}>
      <JobberPaymentsContext.Provider value={context}>
        {children}
      </JobberPaymentsContext.Provider>
    </Elements>
  );

  function initContext(
    existingContext?: JobberPaymentsContextType,
  ): JobberPaymentsContextType {
    return {
      ...value,
      ...existingContext,
      enabled,
      setEnabled,
    };
  }

  async function initStripe() {
    return loadStripe(context.stripeKey);
  }
}
