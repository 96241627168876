import { CREATE_JOB_COSTING_MUTATION } from "./useCreateJobCostingMutation";
import { JOB_COST_PROFIT_QUERY } from "../../hooks";

export function generateMocksForJobCostHeader() {
  return [
    buildMockForJobCostProfitQuery("NA=="),
    buildMockForJobCostProfitQuery("MW=="),
    {
      request: {
        query: CREATE_JOB_COSTING_MUTATION,
        variables: {
          jobId: "NA==",
        },
      },
      result: {
        data: {
          jobCreateJobCosting: {
            __typename: "JobCreateJobCostingPayload",
            userErrors: [{ message: "Some error", path: "Some path" }],
            jobCosting: null,
          },
        },
      },
    },
    {
      request: {
        query: CREATE_JOB_COSTING_MUTATION,
        variables: {
          jobId: "MW==",
        },
      },
      result: {
        data: {
          jobCreateJobCosting: {
            __typename: "JobCreateJobCostingPayload",
            userErrors: [],
            jobCosting: {
              __typename: "JobCosting",
              id: "NA==",
              labourDuration: 20,
              labourCost: 200,
              expenseCost: 35,
              lineItemCost: 45,
              totalCost: 34,
              totalRevenue: 343,
              profitAmount: 34,
              profitPercentage: -20,
            },
          },
        },
      },
    },
  ];
}

function buildMockForJobCostProfitQuery(jobId: string) {
  return {
    request: {
      query: JOB_COST_PROFIT_QUERY,
      variables: {
        canViewJobCosts: true,
        jobId,
      },
    },
    result: {
      data: {
        job: {
          __typename: "Job",
          id: "NA==",
          jobCosting: {
            __typename: "JobCosting",
            id: "NA==",
            labourDuration: 20,
            labourCost: 200,
            expenseCost: 35,
            lineItemCost: 45,
            totalCost: 34,
            totalRevenue: 343,
            profitAmount: 34,
            profitPercentage: -20,
          },
        },
      },
    },
  };
}
