import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "billingDetails.addonBillingDetails.header",
    defaultMessage: "Add-ons",
    description:
      "Header for the addon billing details section of the pricing summary.",
  },
  discountHeader: {
    id: "billingDetails.addonBillingDetails.discountHeader",
    defaultMessage: "Discount",
    description:
      "Header for the discount for the addon billing details section of the pricing summary.",
  },
  discountAmount: {
    id: "billingDetails.addonBillingDetails.discountAmount",
    defaultMessage: "-{discountAmount}",
    description:
      "Discount amount for the addon billing details section of the pricing summary.",
  },
});
