/**
 * Jobber pack entrypoint.
 */
import "@babel/polyfill";
import cssVars from "css-vars-ponyfill";
import "formdata-polyfill";
import "mdn-polyfills/CustomEvent";
import "mdn-polyfills/Node.prototype.replaceWith";
import "utilities/bridges/mountReact";
import "whatwg-fetch";
import "jobber/bridges/Bridges";
import "intersection-observer";
import { setupCollectPaymentForm } from "jobber/payments/SetupCollectPaymentForm";
import { initSharedResources } from "./sharedResources";

cssVars({
  watch: true,
});

const sectionContext = require.context("jobber", true);
initSharedResources(sectionContext);

setupCollectPaymentForm("jobber");

if (
  process.env.NODE_ENV === "development" &&
  process.env.ENABLE_STATE_CHART_DEBUGGING === "true"
) {
  const { inspect } = require("@xstate/inspect");

  inspect({
    url: "https://statecharts.io/inspect",
    iframe: false,
  });
}
