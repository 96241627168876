import type { EventInput } from "@fullcalendar/core";
import React from "react";
import { Anytime } from "./Anytime";
import { Appointment } from "./Appointment";
import { AvailabilitySlot } from "./AvailabilitySlot";
import { ShortAppointment } from "./ShortAppointment";

interface EventProps {
  type: "appointment" | "availabilitySlot" | "anytime" | "short";
  eventId: string;
  event: EventInput["event"];
  title: string;
  description?: string;
}
export const Event = ({
  type,
  eventId,
  event,
  title,
  description,
}: EventProps) => {
  if (type === "anytime") {
    return <Anytime eventId={eventId}>{title}</Anytime>;
  } else if (type === "availabilitySlot") {
    return (
      <AvailabilitySlot
        eventId={eventId}
        driveTimeTo={event.extendedProps.driveTimeTo}
        driveTimeFrom={event.extendedProps.driveTimeFrom}
      />
    );
  } else if (type === "short") {
    return <ShortAppointment eventId={eventId}>{title}</ShortAppointment>;
  }

  return (
    <Appointment eventId={eventId} title={title}>
      {description}
    </Appointment>
  );
};
