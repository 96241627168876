import {
  type ApolloError,
  type FetchResult,
  useMutation,
} from "@apollo/client";
import type {
  ArrivalWindowSettingsEditAttributes,
  Maybe,
  NotificationUpdateAttributes,
  PersistArrivalWindowOnboardingSettingsMutation,
  PersistArrivalWindowOnboardingSettingsMutationVariables,
} from "~/utilities/API/graphql";
import {
  PERSIST_AND_BACKFILL_ARRIVAL_WINDOW_ONBOARDING_SETTINGS,
  PERSIST_ARRIVAL_WINDOW_ONBOARDING_SETTINGS,
} from "./PersistArrivalWindowOnboardingSettings.graphql";

export type UsePersistArrivalWindowOnboardingAttributes = Pick<
  NotificationUpdateAttributes,
  "schedules" | "templates"
> &
  ArrivalWindowSettingsEditAttributes & {
    applyToAllJobs: boolean;
  };

export interface UsePersistArrivalWindowOnboarding {
  data?: PersistArrivalWindowOnboardingSettingsMutation;
  loading: boolean;
  error?: ApolloError;
  update(
    params: UsePersistArrivalWindowOnboardingAttributes,
  ): Promise<FetchResult<PersistArrivalWindowOnboardingSettingsMutation>>;
}

// This hook updates the arrival window default, turns on the visit comms
// and updates the default templates
export function usePersistArrivalWindowOnboarding(
  backfill: boolean,
): UsePersistArrivalWindowOnboarding {
  const [_update, { error, loading, data }] = useMutation<
    PersistArrivalWindowOnboardingSettingsMutation,
    PersistArrivalWindowOnboardingSettingsMutationVariables
  >(
    backfill
      ? PERSIST_AND_BACKFILL_ARRIVAL_WINDOW_ONBOARDING_SETTINGS
      : PERSIST_ARRIVAL_WINDOW_ONBOARDING_SETTINGS,
  );

  const update = async ({
    durationInMinutes,
    centered,
    applyToAllJobs,
    ...communicationsSettings
  }: UsePersistArrivalWindowOnboardingAttributes) => {
    return _update({
      variables: {
        communicationsAttributes: {
          enabled: true,
          ...communicationsSettings,
        },
        arrivalWindowsSettingsAttributes: {
          durationInMinutes: (applyToAllJobs
            ? durationInMinutes
            : null) as Maybe<number>,
          centered,
        },
      },
    });
  };

  return {
    data: data ?? undefined,
    update,
    error,
    loading,
  };
}
