import type { MessageDescriptor } from "react-intl";
import StarRatingSrc from "@images/star_rating.png";
import { messages } from "./messages";

export interface ImageType {
  src: string;
  alt: MessageDescriptor;
}

const StarRatingImage: ImageType = {
  src: StarRatingSrc as string,
  alt: messages.starRatingImage,
};

export { StarRatingImage };
