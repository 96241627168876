import React, { type PropsWithChildren } from "react";
import { InputValidation } from "@jobber/components/InputValidation";
import { IntlProvider } from "@translations/IntlProvider";
import {
  City,
  type InputProps,
  PostalCode,
  Province,
  type ProvinceInputProps,
  Street1,
  Street2,
} from "~/components/PropertyAutocomplete/FormParts";
import { CountryPicker } from "components/CountryPicker";
// eslint-disable-next-line import/no-deprecated
import { ResultsPicker_DEPRECATED } from "components/ResultsPicker";
import type { ResultsPickerProps } from "components/ResultsPicker/ResultsPicker";
import type { CountryPickerProps } from "components/CountryPicker/CountryPicker";

interface AddressInputProps
  extends Omit<InputProps & ProvinceInputProps, "formPrefix"> {
  errorMessage?: string;
}

interface AddressFormProps {
  formPrefix?: string;
  street1Input: AddressInputProps;
  street1ResultsPicker?: ResultsPickerProps;
  street2Input: AddressInputProps;
  cityInput: AddressInputProps;
  provinceInput: AddressInputProps;
  postalCodeInput: AddressInputProps;
  countryPicker: CountryPickerProps;
  longitude?: number | string;
  latitude?: number | string;
  placeId?: string;
  geoStatus?: number;
  alreadyGeocoded?: string | number;
}

function AddressFormInternal({
  formPrefix,
  street1Input,
  street1ResultsPicker,
  street2Input,
  cityInput,
  provinceInput,
  postalCodeInput,
  countryPicker,
  longitude,
  latitude,
  placeId,
  geoStatus,
  alreadyGeocoded,
  children,
}: PropsWithChildren<AddressFormProps>) {
  // Should be removed once this is no longer being used in rails forms
  const railsFormPrefix = formPrefix ? formPrefix : "address";

  function showAddressErrors(error?: string) {
    return error && <InputValidation message={error} />;
  }

  return (
    <div className="property_form clearfix">
      <div className="fieldGroup">
        <div className="row collapse">
          <div
            className={`columns ${
              street1Input.errorMessage ? "is-invalid" : ""
            }`}
          >
            <Street1 {...street1Input} formPrefix={railsFormPrefix} />
            {showAddressErrors(street1Input.errorMessage)}
            {street1ResultsPicker && (
              <ResultsPicker_DEPRECATED {...street1ResultsPicker} />
            )}
          </div>
        </div>
        <div className="row collapse">
          <div className="columns">
            <Street2 {...street2Input} formPrefix={railsFormPrefix} />
          </div>
        </div>
        <div className="row collapse">
          <div
            className={`columns ${cityInput.errorMessage ? "is-invalid" : ""}`}
          >
            <City {...cityInput} formPrefix={railsFormPrefix} />
          </div>
          <div
            className={`columns ${
              provinceInput.errorMessage ? "is-invalid" : ""
            }`}
          >
            <Province {...provinceInput} formPrefix={railsFormPrefix} />
          </div>
        </div>
        {(cityInput.errorMessage || provinceInput.errorMessage) && (
          <div className="row collapse">
            <div className={"columns"}>
              {showAddressErrors(cityInput.errorMessage)}
            </div>
            <div className={"columns"}>
              {showAddressErrors(provinceInput.errorMessage)}
            </div>
          </div>
        )}

        <div className="row collapse">
          <div
            className={`columns ${
              postalCodeInput.errorMessage ? "is-invalid" : ""
            }`}
          >
            <PostalCode {...postalCodeInput} formPrefix={railsFormPrefix} />
          </div>
          <div className="columns">
            <CountryPicker
              ariaLabel="Country"
              priorityCountries={countryPicker.priorityCountries}
              allCountries={countryPicker.allCountries}
              value={countryPicker.value}
              name={`${railsFormPrefix}[country]`}
              onChange={countryPicker.onChange}
            />
          </div>
        </div>
        {postalCodeInput.errorMessage && (
          <div className="row collapse">
            <div className={"columns"}>
              {showAddressErrors(postalCodeInput.errorMessage)}
            </div>
            <div className={"columns"} />
          </div>
        )}
      </div>
      {longitude && (
        <input
          name={`${railsFormPrefix}[longitude]`}
          type="hidden"
          value={longitude}
          alt={"longitude"}
        />
      )}
      {latitude && (
        <input
          name={`${railsFormPrefix}[latitude]`}
          type="hidden"
          value={latitude}
          alt={"latitude"}
        />
      )}

      {placeId !== undefined && (
        <input
          name={`${railsFormPrefix}[place_id]`}
          type="hidden"
          value={placeId}
          alt={"google place Id"}
        />
      )}

      {geoStatus && (
        <input
          name={`${railsFormPrefix}[geo_status]`}
          type="hidden"
          value={geoStatus}
          alt={"geo status"}
        />
      )}

      {alreadyGeocoded !== undefined && (
        <input
          name={`${railsFormPrefix}[already_geocoded]`}
          type="hidden"
          value={alreadyGeocoded}
          alt={"already geocoded?"}
        />
      )}

      {children}
    </div>
  );
}

export function AddressForm(props: PropsWithChildren<AddressFormProps>) {
  return (
    <IntlProvider>
      <AddressFormInternal {...props} />
    </IntlProvider>
  );
}
