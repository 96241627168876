import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonLabel: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.buttonLabel",
    defaultMessage: "Get it now",
    description: "Button label for instant payout",
  },
  jobberPaymentsDebitCardAwaitingValidationToolTip: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.jobberPaymentsDebitCardAwaitingValidationToolTip",
    defaultMessage: `Hold tight, it will take 48 hours after you add your card before you can make your first instant payout.`,
    description: "Tooltip for debit card awaiting validation",
  },
  jobberPaymentsInstantPayoutMaximumToolTip: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.jobberPaymentsInstantPayoutMaximumToolTip",
    defaultMessage: `You have reached the maximum amount of $10,000 you can withdraw today.`,
    description: "Tooltip for maximum instant payout",
  },
  jobberPaymentsInstantPayoutMinimumToolTip: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.jobberPaymentsInstantPayoutMinimumToolTip",
    defaultMessage: `Minimum balance for instant payout is $50.`,
    description: "Tooltip for minimum instant payout",
  },
});
