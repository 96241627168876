import { useMutation } from "@apollo/client";
import { mapToStoredPaymentMethod } from "jobber/payments_react/utils/paymentMethodMappers";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import type {
  PaymentMethodDeleteMutation,
  PaymentMethodDeleteMutationVariables,
  PaymentMethodSetAsDefaultMutation,
  PaymentMethodSetAsDefaultMutationVariables,
} from "~/utilities/API/graphql";
import {
  DELETE_PAYMENT_METHOD_MUTATION,
  SET_DEFAULT_PAYMENT_METHOD_MUTATION,
} from "./PaymentMethodActions.graphql";

export interface UsePaymentMethodActionsInput {
  clientId: string;
  isClientIdEncoded?: boolean;
}

interface UsePaymentMethodActionsResponse {
  deletePaymentMethod: (
    paymentMethodUuid: string,
  ) => Promise<StoredPaymentMethodInterface>;
  setAsDefaultPaymentMethod: (
    paymentMethodUuid: string,
  ) => Promise<StoredPaymentMethodInterface>;
}

export function usePaymentMethodActionsJobberOnline({
  clientId,
  isClientIdEncoded = false,
}: UsePaymentMethodActionsInput): UsePaymentMethodActionsResponse {
  const [setDefaultPaymentMethodMutation] = useMutation<
    PaymentMethodSetAsDefaultMutation,
    PaymentMethodSetAsDefaultMutationVariables
  >(SET_DEFAULT_PAYMENT_METHOD_MUTATION);

  const [deletePaymentMethodMutation] = useMutation<
    PaymentMethodDeleteMutation,
    PaymentMethodDeleteMutationVariables
  >(DELETE_PAYMENT_METHOD_MUTATION);

  const encodedClientId = isClientIdEncoded ? clientId : btoa(clientId);

  const deletePaymentMethod = async (
    paymentMethodUuid: string,
  ): Promise<StoredPaymentMethodInterface> => {
    const variables = {
      clientId: encodedClientId,
      input: {
        uuid: paymentMethodUuid,
      },
    };

    const result = await deletePaymentMethodMutation({ variables });

    const paymentMethodResult = result.data?.paymentMethodDelete.paymentMethod;

    if (result.errors || !paymentMethodResult) {
      throw new Error("Failed to delete payment method");
    }

    const paymentMethod: StoredPaymentMethodInterface =
      mapToStoredPaymentMethod(paymentMethodResult);

    return paymentMethod;
  };

  const setAsDefaultPaymentMethod = async (paymentMethodUuid: string) => {
    const variables = {
      clientId: encodedClientId,
      input: {
        defaultMethod: true,
        uuid: paymentMethodUuid,
      },
    };

    const result = await setDefaultPaymentMethodMutation({ variables });

    const paymentMethodResult =
      result.data?.paymentMethodSetAsDefault.paymentMethod;

    if (result.errors || !paymentMethodResult) {
      throw new Error(
        "There was an error setting the payment method as default",
      );
    }

    const paymentMethod: StoredPaymentMethodInterface =
      mapToStoredPaymentMethod(paymentMethodResult);

    return paymentMethod;
  };

  return {
    deletePaymentMethod,
    setAsDefaultPaymentMethod,
  };
}
