import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { QuickbooksSyncIcon } from "./QuickbooksSyncIcon";

interface QboSyncIconProps {
  settingsUrl: string;
}

export function QuickbooksSyncIconWrapper(props: QboSyncIconProps) {
  return (
    <APIProvider>
      <QuickbooksSyncIcon settingsUrl={props.settingsUrl} />
    </APIProvider>
  );
}
