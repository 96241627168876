export const messages = {
  noServicesAdded: {
    title: "No services added",
    description: "Description when on Services Settings when no services added",
  },
  addServicePrompt: {
    title: "Add a service to have it available on your online booking form.",
    description: "Prompt to add a service for online booking",
  },
  addServiceButtonLabel: {
    title: "Add Service",
    description: "Label for Add Service Button when no services exist",
  },
  add: {
    title: "Add",
    description: "Label for Add Service Button in service list header",
  },
  services: {
    title: "Services",
    description: "Title of the Services settings page",
  },
};
