import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./OAuthAuthorizationPage.module.css";
import type { OAuthAuthorizationPageProps } from "./types";
import { messages } from "./messages";
import { OAuthAuthorizationScopes } from "./OAuthAuthorizationScopes";
import { OAuthAuthorizationLogos } from "./OAuthAuthorizationLogos";

export function OAuthAuthorizationPage({
  id,
  logo,
  name,
  accountName,
  scopes,
  termsOfServiceUrl,
  privacyPolicyUrl,
  appAvailable,
  isPublic,
  errorMessages,
  reauthorizeMessage,
  requireReauthorization,
  minimumRequiredPlan,
}: OAuthAuthorizationPageProps) {
  const { formatMessage } = useIntl();

  const headingMessage = () => {
    if (isPublic && !requireReauthorization && !minimumRequiredPlan) {
      return `${name} ${formatMessage(messages.connectToJobberAccount)}`;
    } else if (!isPublic && !requireReauthorization) {
      return `${name} ${formatMessage(messages.accessJobberAccount)}`;
    } else if (requireReauthorization) {
      return `${name} requires Re-Authorization`;
    } else if (minimumRequiredPlan && !requireReauthorization) {
      return `${name} ${formatMessage(messages.accessJobberAccount)}`;
    } else {
      return null;
    }
  };

  const onCancel = () => handleCancel(id, name);
  const onAllowAccess = () => handleAllowAccess(id, name);

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Card>
          <OAuthAuthorizationLogos name={name} logo={logo} />
          {reauthorizeMessage && requireReauthorization && (
            <div className={styles.developerMessageWrapper}>
              <Banner type="notice" dismissible={false}>
                <span className={styles.developerMessage}>
                  <b>Message from the app developer:</b> {reauthorizeMessage}
                </span>
              </Banner>
            </div>
          )}
          <Content>
            {headingMessage() && (
              <Heading level={2}>{headingMessage()}</Heading>
            )}
            <Content>
              <Text>
                Allowing access will give <strong>{name}</strong> the permission
                to view or edit <strong>{accountName}</strong>&apos;s account
                information:
              </Text>
              <OAuthAuthorizationScopes scopes={scopes} />
            </Content>
            {appAvailable && (
              <>
                <OAuthAuthorizationDetails
                  isPublic={isPublic}
                  name={name}
                  privacyPolicyUrl={privacyPolicyUrl}
                  termsOfServiceUrl={termsOfServiceUrl}
                />
                {(!errorMessages || errorMessages?.length <= 0) && (
                  <ActionButtons
                    onCancel={onCancel}
                    onAllowAccess={onAllowAccess}
                  />
                )}
              </>
            )}
          </Content>
        </Card>
      </div>
      {!appAvailable && (
        <UpgradeButtons
          id={id}
          name={name}
          minimumRequiredPlan={minimumRequiredPlan}
        />
      )}
      {errorMessages?.length > 0 && (
        <Banner
          type="warning"
          primaryAction={{
            label: "Ok",
            onClick: onCancel,
          }}
          dismissible={false}
        >
          <ul>
            {errorMessages.map((message, index) => {
              return (
                <li key={index} className={styles.errorMessage}>
                  {message}
                </li>
              );
            })}
          </ul>
        </Banner>
      )}
    </div>
  );
}

function OAuthAuthorizationDetails({
  isPublic,
  name,
  privacyPolicyUrl,
  termsOfServiceUrl,
}: {
  isPublic: boolean;
  name: string;
  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
}) {
  return (
    <Content>
      {!isPublic && (
        <>
          <Text variation="error">{`This app has not yet undergone Jobber’s app approval process.`}</Text>
          <Text>
            Make sure you trust <Emphasis variation="bold">{name}</Emphasis>.
            You may be sharing sensitive info with this app and allowing them to
            make changes in your Jobber account.
          </Text>
        </>
      )}
      <Text variation="subdued">
        {name}&apos;s use of your data will be subject to its{" "}
        <ConditionalLink
          text="privacy
        policy"
          url={privacyPolicyUrl}
        />{" "}
        and <ConditionalLink text="terms of service" url={termsOfServiceUrl} />.
      </Text>
      <Text variation="subdued">{`You can disconnect this app at anytime from Jobber's App Marketplace.`}</Text>
    </Content>
  );
}

function ActionButtons({
  onCancel,
  onAllowAccess,
}: {
  onCancel: () => void;
  onAllowAccess: () => void;
}) {
  return (
    <div className={styles.actions}>
      <Button
        label="Cancel"
        type="primary"
        variation="subtle"
        onClick={onCancel}
      />
      <Button label="Allow Access" onClick={onAllowAccess} />
    </div>
  );
}

function UpgradeButtons({
  id,
  name,
  minimumRequiredPlan,
}: {
  id: string;
  name: string;
  minimumRequiredPlan?: string;
}) {
  return (
    <Banner
      type="warning"
      primaryAction={{
        label: "Upgrade now",
        onClick: () => goToBilling(id, name),
      }}
      dismissible={false}
    >
      <Text>
        A Jobber{" "}
        <Emphasis variation={"bold"}>{minimumRequiredPlan} or higher</Emphasis>{" "}
        plan is required to connect this app. Upgrade your Jobber plan to
        connect {name}
      </Text>
    </Banner>
  );
}

function ConditionalLink({ url, text }: { url: string; text: string }) {
  return (
    <>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {text}
        </a>
      ) : (
        text
      )}
    </>
  );
}

function goToBilling(id: string, name: string) {
  Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickUpgradeNow, {
    appId: id,
    appName: name,
    source: ANALYTICS_PROPERTIES.authorizePageSource,
  });
  window.location.assign("/accounts/billing_info/pricing");
}

function handleCancel(id: string, name: string) {
  Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.interactedWithOAuth, {
    appId: id,
    appName: name,
    interaction: "deny",
    source: ANALYTICS_PROPERTIES.authorizePageSource,
  });
  window.close();
}

function triggerStorageEvent(name: string) {
  // Remove the item first to guarantee the value changes. Overwriting "appAccessChangeEvent"
  // with the same value does not trigger the "storage" event.
  window.localStorage.removeItem("appAccessChangeEvent");
  window.localStorage.setItem(
    "appAccessChangeEvent",
    JSON.stringify({ title: name, isConnected: true }),
  );
}

function handleAllowAccess(id: string, name: string) {
  Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.interactedWithOAuth, {
    appId: id,
    appName: name,
    interaction: "approve",
    source: ANALYTICS_PROPERTIES.authorizePageSource,
  });

  const form = document.querySelector("form");
  if (form) {
    triggerStorageEvent(name);
    form.submit();
  }
}
