import React, { useCallback } from "react";
import { AutocompleteAddress } from "components/InputAddress";
import type { AddressValues } from "components/InputAddress/types";
import type { GeoPoint } from "~/utilities/API/graphql";
import { messages } from "../messages";

interface ServiceAreaAddressInputProps {
  onChange: (
    address: string,
    location?: Pick<GeoPoint, "latitude" | "longitude">,
  ) => void;
  placeholder: string;
  value: string | undefined;
}

export function ServiceAreaAddressInput({
  onChange,
  placeholder,
  value,
}: ServiceAreaAddressInputProps) {
  const onAddressChange = useCallback(
    (address: AddressValues) => {
      onChange(address.formatted || "", address.location);
    },
    [onChange],
  );

  const clearInput = useCallback(() => {
    onAddressChange({ formatted: "" });
  }, [onAddressChange]);

  const suffix = value
    ? {
        icon: "cross" as const,
        onClick: clearInput,
        ariaLabel: messages.addressFieldClearButtonLabel.defaultMessage,
      }
    : undefined;

  return (
    <AutocompleteAddress
      allowFreeForm={false}
      prefix={{ icon: "address" }}
      suffix={suffix}
      onAddressChange={onAddressChange}
      placeholder={placeholder}
      value={value}
      name="address"
      validations={{
        required: {
          value: true,
          message: messages.addressFieldRequiredError.defaultMessage,
        },
      }}
    />
  );
}
