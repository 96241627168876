import { useQuery } from "@apollo/client";
import type {
  MessageTemplateEmailFragment,
  ReferralSettingsQuery,
} from "~/utilities/API/graphql";
import { INCENTIVE_SETTINGS_QUERY } from "../views/Dashboard/SettingsDrawer/SettingsDrawer.graphql";

export function useIncentiveSettingsQuery() {
  const { loading, data, error } = useQuery<ReferralSettingsQuery>(
    INCENTIVE_SETTINGS_QUERY,
  );

  return {
    loading,
    data,
    error,
    template: data?.clientNotification?.templates?.nodes.filter(
      isMessageTemplateEmailFragment,
    )[0],
  };
}

function isMessageTemplateEmailFragment(
  x: unknown,
): x is MessageTemplateEmailFragment {
  return (x as { __typename: string }).__typename === "MessageTemplateEmail";
}
