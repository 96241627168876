import { defineMessages } from "react-intl";

const prefix = "setupWizard.businessPageForm.";

export const messages = defineMessages({
  companySizePlaceholder: {
    id: `${prefix}companySizePlaceholder`,
    defaultMessage: "How many people work at your company (including you)?",
    description: "Company size placeholder for the setup wizard",
  },
  companySizeError: {
    id: `${prefix}companySizeError`,
    defaultMessage: "Please tell us your team size",
    description: "Company size error for the setup wizard",
  },
  companySizeJustMe: {
    id: `${prefix}companySizeJustMe`,
    defaultMessage: "Just me",
    description: "Company size 'just me' option for the setup wizard",
  },
  companySize2To3People: {
    id: `${prefix}companySize2To3People`,
    defaultMessage: "2-3 people",
    description: "Company size '2-3 people' option for the setup wizard",
  },
  companySize4To10People: {
    id: `${prefix}companySize4To10People`,
    defaultMessage: "4-10 people",
    description: "Company size '4-10 people' option for the setup wizard",
  },
  companySize10PlusPeople: {
    id: `${prefix}companySize10PlusPeople`,
    defaultMessage: "10+ people",
    description: "Company size '10+ people' option for the setup wizard",
  },
  companySize11To19People: {
    id: `${prefix}companySize11To19People`,
    defaultMessage: "11-19 people",
    description: "Company size '11-19 people' option for the setup wizard",
  },
  companySize20PlusPeople: {
    id: `${prefix}companySize20PlusPeople`,
    defaultMessage: "20+ people",
    description: "Company size '20+ people' option for the setup wizard",
  },
  companyAgePlaceholder: {
    id: `${prefix}companyAgePlaceholder`,
    defaultMessage: "How many years have you been in business?",
    description: "Company age placeholder for the setup wizard",
  },
  companyAgeError: {
    id: `${prefix}companyAgeError`,
    defaultMessage: "Please tell us how long your company has been in business",
    description: "Company age error for the setup wizard",
  },
  companyAgeLessThan1Year: {
    id: `${prefix}companyAgeLessThan1Year`,
    defaultMessage: "Less than 1 year",
    description: "Company age '<1 year' option for the setup wizard",
  },
  companyAge1To2Years: {
    id: `${prefix}companyAge1To2Years`,
    defaultMessage: "1-2 years",
    description: "Company age '1-2 years' option for the setup wizard",
  },
  companyAge3To5Years: {
    id: `${prefix}companyAge3To5Years`,
    defaultMessage: "3-5 years",
    description: "Company age '3-5 years' option for the setup wizard",
  },
  companyAge6To10Years: {
    id: `${prefix}companyAge6To10Years`,
    defaultMessage: "6-10 years",
    description: "Company age '6-10 years' option for the setup wizard",
  },
  companyAge10PlusYears: {
    id: `${prefix}companyAge10PlusYears`,
    defaultMessage: "10+ years",
    description: "Company age '10+ years' option for the setup wizard",
  },
});
