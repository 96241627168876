/* eslint-disable import/no-internal-modules */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import type { OnlineBookingConfigurationFragment } from "~/utilities/API/graphql";
import { ServiceAreaPreviewText } from "./components/ServiceAreaPreviewText";
import { ServiceAreaEditModal } from "./components/ServiceAreaEditModal";
import { messages } from "./messages";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";

interface ServiceAreaConfigurationProps {
  serviceAreasEnabled: boolean;
  serviceAreas: OnlineBookingConfigurationFragment["serviceAreas"];
}

export function ServiceAreaConfiguration({
  serviceAreasEnabled,
  serviceAreas,
}: ServiceAreaConfigurationProps) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const serviceArea = serviceAreas?.edges?.[0]?.node;

  function toggleModal() {
    setModalOpen(current => !current);
  }

  return (
    <>
      <ConfigurationSetting
        title={formatMessage(messages.configurationLabel)}
        onEdit={toggleModal}
      >
        {serviceAreasEnabled && serviceArea ? (
          <ServiceAreaPreviewText serviceArea={serviceArea} />
        ) : (
          formatMessage(messages.serviceAreasDisabled)
        )}
      </ConfigurationSetting>
      <ServiceAreaEditModal
        open={modalOpen}
        onClose={toggleModal}
        serviceArea={serviceArea}
        serviceAreasEnabled={serviceAreasEnabled}
      />
    </>
  );
}
