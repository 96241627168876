import { gql } from "@apollo/client";

export const AUTOMATIONS_ENABLED_QUERY = gql`
  query AutomationsEnabled {
    feature(key: "Automations") {
      available
      enabled
    }
  }
`;

export const QUOTE_FOLLOW_UP_CLIENT_NOTIFICATION_QUERY = gql`
  query QuoteFollowUpClientNotification {
    clientNotification(id: QUOTE_FOLLOWUP, includeUpgradeFeatures: true) {
      enabled
      schedules {
        nodes {
          offset {
            value
          }
        }
      }
    }
  }
`;

export const QUOTE_FOLLOW_UP_AUTOMATION_RULES_QUERY = gql`
  query QuoteFollowUpAutomationRules {
    automationRules {
      nodes {
        trigger
        active
      }
    }
  }
`;
