import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noContactProfileMessage: {
    id: "automaticPayments.sendCardOnFileRequest.noContactProfileMessage",
    defaultMessage:
      "Your client can save a payment method when they make their first payment.",
    description: "Message when no contact info is available",
  },
  headingTitle: {
    id: "automaticPayments.sendCardOnFileRequest.headingTitle",
    defaultMessage: "Request a payment method on file",
    description: "Title for the heading when no payment methods are on file",
  },
  noContactInfoMessage: {
    id: "automaticPayments.sendCardOnFileRequest.noContactInfoMessage",
    defaultMessage:
      "Sending a request for a payment method requires contact details. Add an email address or mobile number in their ",
    description: "Message when no contact info is available",
  },
  noContactInfoUrlMessage: {
    id: "automaticPayments.sendCardOnFileRequest.noContactInfoUrlMessage",
    defaultMessage: "client profile.",
    description: "Message when no contact info is available",
  },
  emailOnlyCheckboxTitleMessage: {
    id: "automaticPayments.sendCardOnFileRequest.emailOnlyCheckboxTitleMessage",
    defaultMessage: "Send the request via email when you save this job",
    description:
      "Checkbox title for requesting payment method on file with an email when in autopay smart defaults split",
  },
  smsOnlyCheckboxTitleMessage: {
    id: "automaticPayments.sendCardOnFileRequest.smsOnlyCheckboxTitleMessage",
    defaultMessage: "Send the request via SMS when you save this job",
    description:
      "Checkbox title for requesting payment method on file with an sms when in autopay smart defaults split",
  },
  allOptionsCheckboxTitleMessage: {
    id: "automaticPayments.sendCardOnFileRequest.allOptionsCheckboxTitleMessage",
    defaultMessage: "Send the request when you save this job with an",
    description:
      "Checkbox title for requesting payment method on file with an email and sms when in autopay smart defaults split",
  },
  tooltipMessage: {
    id: "automaticPayments.sendCardOnFileRequest.tooltipMessage",
    defaultMessage:
      "You can also send an email or SMS request directly from the client's profile page.",
    description: "Tooltip message when in autopay smart defaults split",
  },
  descriptionForNoAutoPayAccounts: {
    id: "automaticPayments.sendCardOnFileRequest.descriptionForCoreAccounts",
    defaultMessage:
      "Manually charge your client when they save a payment method. Eliminate waiting to get paid by charging your client with their saved payment method.",
    description: "Description for core accounts",
  },
});
