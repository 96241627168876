import React, { useState } from "react";
import { type FileUpload, InputFile } from "@jobber/components/InputFile";
import { FormatFile } from "@jobber/components/FormatFile";
import { useIntl } from "react-intl";
import { type File, UploadDestinationsEnum } from "~/utilities/API/graphql";
import { fetchInternalUploadParams } from "utilities/fetchUploadParams";
import {
  ExpenseActionTypes,
  type ExpenseModalAction,
} from "./ExpenseModalReducer";
import { messages } from "./messages";

export function Receipt({
  initialReceipt,
  dispatch,
}: {
  initialReceipt?: File;
  dispatch: React.Dispatch<ExpenseModalAction>;
}) {
  const initialReceiptFileUpload = createInitialReceipt(initialReceipt);
  const [receipt, setReceipt] = useState<FileUpload | undefined>(
    initialReceiptFileUpload,
  );
  const [uploading, setUploading] = useState(false);
  const { formatMessage } = useIntl();

  if (receipt) {
    return (
      <FormatFile
        file={receipt}
        onDelete={() => handleDelete(setReceipt, dispatch)}
      />
    );
  }

  return uploading ? (
    <div className={"row collapse align-center u-paddingNone"}>
      <div className={"columns spinner spinner--inline"} />
    </div>
  ) : (
    <InputFile
      allowMultiple={false}
      getUploadParams={fetchInternalUploadParams(
        UploadDestinationsEnum.UPLOADS,
      )}
      onUploadStart={() => setUploading(true)}
      onUploadComplete={file =>
        handleImageUpload(file, setReceipt, dispatch, setUploading)
      }
      buttonLabel={formatMessage(messages.addReceipt)}
    />
  );
}

function createInitialReceipt(initialReceipt?: File) {
  if (!initialReceipt) return undefined;

  return {
    key: initialReceipt.url ?? "",
    name: initialReceipt.fileName || "",
    type: initialReceipt.contentType || "",
    size: initialReceipt.fileSize,
    uploadUrl: "",
    progress: 1,
    src: () => Promise.resolve(`${initialReceipt.url}`),
  };
}

function handleDelete(
  setReceipt: React.Dispatch<React.SetStateAction<FileUpload | undefined>>,
  dispatch: React.Dispatch<ExpenseModalAction>,
) {
  dispatch({
    type: ExpenseActionTypes.DeleteReceipt,
    value: "",
  });
  setReceipt(undefined);
}

function handleImageUpload(
  file: FileUpload,
  setReceipt: React.Dispatch<React.SetStateAction<FileUpload | undefined>>,
  dispatch: React.Dispatch<ExpenseModalAction>,
  setUpload: React.Dispatch<React.SetStateAction<boolean>>,
) {
  setReceipt(file);
  setUpload(false);

  dispatch({
    type: ExpenseActionTypes.UpdateReceipt,
    value: `${file.uploadUrl}/${file.key}`,
  });
}
