import { gql } from "@apollo/client";

export const JOBBER_PAYMENTS_AUTOMATIC_PAYMENT_METHODS_QUERY = gql`
  query JobberPaymentsAutomaticPaymentMethods($clientId: EncodedId!) {
    jobberPaymentsPaymentMethods(filter: { clientId: $clientId }) {
      nodes {
        brand
        defaultMethod
        expired
        expiringSoon
        expiryMonth
        expiryYear
        fingerprint
        last4
        uuid
      }
    }
  }
`;

export const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethods($clientId: EncodedId!) {
    paymentMethods(filter: { clientId: $clientId }) {
      nodes {
        ... on BankAccountPaymentMethod {
          __typename
          bankName
          defaultMethod
          fingerprint
          last4
          uuid
        }
        ... on CreditCardPaymentMethod {
          __typename
          brand
          defaultMethod
          expired
          expiringSoon
          expiryYear
          expiryMonth
          fingerprint
          uuid
          last4
        }
      }
    }
  }
`;
