import React from "react";
import styles from "./styles.module.css";

export function LineItemsBulkEditEmptyState() {
  return (
    <div className={styles.lineItemsBulkEditEmpty}>
      Stay on top of what needs to be done by adding products & services
    </div>
  );
}
