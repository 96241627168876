import React from "react";
import { Icon } from "@jobber/components/Icon";
import type { AppAlert } from "jobber/appAlerts/AppAlerts.types";
import styles from "./AppAlertsList.module.css";

interface AppAlertsListProps {
  appAlerts: AppAlert[];
}

export function AppAlertsList({ appAlerts }: AppAlertsListProps) {
  return (
    <>
      {appAlerts.map(appAlert => {
        if (appAlert.count > 0) {
          return (
            <div key={appAlert.app.id}>
              <a
                href={appAlert.app.manageAppUrl}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className={styles.appAlertRowContent}>
                  <div className={styles.itemBottomDivider}>
                    <div className={styles.appAlertRowContainer}>
                      <div className={styles.appLogoContainer}>
                        <img
                          className={styles.appLogoIcon}
                          src={appAlert.app.logoUrl}
                          alt="App logo icon"
                        />
                      </div>
                      <div className={styles.appAlertCenterContent}>
                        <div className={styles.appAlertTitleContainer}>
                          {`${appAlert.app.name} has ${appAlert.count} alert${
                            appAlert.count > 1 ? "s" : ""
                          }`}
                        </div>
                        <div className={styles.appAlertLastUpdatedAt}>
                          {lastUpdatedAt(appAlert.updatedAt)}
                        </div>
                      </div>
                      <div className={styles.alertIconContainer}>
                        <Icon name="alert" color="red" />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        }
      })}
    </>
  );
}

function lastUpdatedAt(updatedAt?: string) {
  const updatedAtDate = new Date(updatedAt as string);
  const currentTime = new Date();
  const timeDiff = currentTime.getTime() - updatedAtDate.getTime();

  if (timeDiff < 60000) {
    return "less than a minute ago";
  } else if (timeDiff >= 60000 && timeDiff < 3600000) {
    return `${Math.floor(timeDiff / 60000)} minute${
      Math.floor(timeDiff / 60000) > 1 ? "s" : ""
    } ago`;
  } else if (timeDiff >= 3600000 && timeDiff < 86400000) {
    return `${Math.floor(timeDiff / 3600000)} hour${
      Math.floor(timeDiff / 3600000) > 1 ? "s" : ""
    } ago`;
  } else {
    return `${Math.floor(timeDiff / 86400000)} day${
      Math.floor(timeDiff / 86400000) > 1 ? "s" : ""
    } ago`;
  }
}
