/* eslint-disable import/no-internal-modules */
import { useMachine } from "@xstate/react";
import { type ApolloClient, useApolloClient } from "@apollo/client";
import { add, endOfMonth, endOfWeek, isBefore } from "date-fns";
import { useRef } from "react";
import type FullCalendar from "@fullcalendar/react";
import {
  type LoadAvailabilityEvent,
  assignSelectedSlotFromAvailability,
  reassignSelectedSlotFromAvailability,
  schedulingAssistantMachine,
} from "jobber/workOrders/components/SchedulingAssistant/machines/schedulingAssistant.machine";
import type {
  ScheduledAppointmentsQuery,
  ScheduledAppointmentsQueryVariables,
} from "~/utilities/API/graphql";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useScheduleCardChangeHandlers } from "jobber/workOrders/components/ScheduleCard/hooks/useScheduleCardChangeHandlers";
import { useAssignTeam } from "jobber/workOrders/components/AssignTeam/useAssignTeam";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useScheduleCardState } from "jobber/workOrders/components/ScheduleCard/hooks/useScheduleCardState";
import { SCHEDULED_APPOINTMENTS_QUERY } from "./ScheduledAppointments.graphql";
import { querySchedulingAvailability } from "./querySchedulingAvailability";
import {
  type Month,
  type Year,
  type YearMonthDay,
  toYearMonthDay,
  yearMonthDayToDate,
  yearMonthDayToString,
} from "../yearMonthDay";
import { mkCacheKey } from "../utils/availabilityCache";
import { getFirstCalendarDateOrToday } from "../utils/getFirstCalendarDateOrToday";
import { today } from "../utils/today";
import { scrollToTime } from "../machines/actions/scrollToTime";
import { queryCompanySchedule } from "../queries/companySchedule";

export const useSchedulingAssistantMachine = ({
  initialDurationInSeconds,
  initialSelectedDate,
  initialSelectedTeamMembers,
  initialSelectedTime,
  location,
}: {
  initialDurationInSeconds: number;
  initialSelectedDate: YearMonthDay;
  initialSelectedTime?: {
    start: Date;
    assigneeId: string;
  };
  initialSelectedTeamMembers: Map<string, boolean>;
  location?: { latitude: number; longitude: number };
}) => {
  const apolloClient = useApolloClient();
  const calendarRef = useRef<FullCalendar>(null);
  const { handleStartDateAndTimeChange, handleEndDateAndTimeChange } =
    useScheduleCardChangeHandlers();
  const { setSelectedAssignees } = useAssignTeam();

  const { calendarFirstDay, timezone } = useAccount();
  const weekStartsOnMonday = calendarFirstDay === 1;

  const { startTime: defaultStart, endTime: defaultEnd } =
    useScheduleCardState().schedulingState;

  const machine = useMachine(schedulingAssistantMachine, {
    context: {
      durationInSeconds: initialDurationInSeconds,
      selectedDate: initialSelectedDate,
      selectedTeamMembers: initialSelectedTeamMembers,
      selectedTime: initialSelectedTime,
    },
    actions: {
      sendOnSaveTrackingEvent: context => {
        Amplitude.TRACK_EVENT("Interacted with Scheduling Assistant", {
          interaction: "modal_saved",
          // eslint-disable-next-line @typescript-eslint/naming-convention
          default_start: defaultStart?.toISOString(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          default_end: defaultEnd?.toISOString(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          selected_start: context.selectedTime!.start.toISOString(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          selected_end:
            context.selectedTime!.start &&
            add(context.selectedTime!.start, {
              seconds: context.durationInSeconds,
            }).toISOString(),
        });
      },
      scrollToTime,

      assignSelectedSlotFromAvailability:
        assignSelectedSlotFromAvailability(timezone),

      reassignSelectedSlotFromAvailability:
        reassignSelectedSlotFromAvailability(timezone),

      updateFullCalendarWithSelectedDate: (_, event) => {
        calendarRef.current!.getApi().gotoDate(yearMonthDayToDate(event.date));
      },
      updateModalValues: context => {
        handleStartDateAndTimeChange(context.selectedTime!.start);
        handleEndDateAndTimeChange(
          add(context.selectedTime!.start, {
            seconds: context.durationInSeconds,
          }),
        );

        setSelectedAssignees([context.selectedTime!.assigneeId]);
      },
    },
    services: {
      queryCompanySchedule: queryCompanySchedule(apolloClient),
      queryScheduledAppointments: async ({ selectedDate }, event) =>
        requestScheduledAppointments(apolloClient)(
          event.type === "SELECT_DATE" ? event.date : selectedDate,
        ),
      queryMonthAvailability:
        ({ durationInSeconds, selectedDate, selectedMonth }, event) =>
        send => {
          const date = getDateFromEvent(event, selectedDate, selectedMonth);
          const [year, month] = toYearMonthDay(date);
          const startDate = getFirstCalendarDateOrToday(
            date,
            weekStartsOnMonday,
          );

          async function query() {
            try {
              const { data } = await querySchedulingAvailability(apolloClient)({
                startDate: yearMonthDayToString(startDate),
                endDate: yearMonthDayToString(
                  getEndOfCalendarDate(date, weekStartsOnMonday),
                ),
                duration: durationInSeconds,
                location: location || (null as unknown as undefined),
              });

              send({
                type: "SUBSCRIPTION_PAYLOAD",
                schedulingRecommendations:
                  data.schedulingAvailability.schedulingRecommendations,
                cacheKey: mkCacheKey(year, month),
              });
            } catch (error) {
              send({
                type: "SUBSCRIPTION_ERROR",
                error,
              });
            }
          }

          void query();
        },
      queryDayAvailability:
        ({ durationInSeconds, selectedDate }) =>
        send => {
          const startDate = selectedDate;
          const [year, month, day] = startDate;

          async function query() {
            try {
              const { data } = await querySchedulingAvailability(apolloClient)({
                startDate: yearMonthDayToString(startDate),
                endDate: yearMonthDayToString(startDate),
                duration: durationInSeconds,
                location: location || (null as unknown as undefined),
              });

              send({
                type: "DAY_SUBSCRIPTION_PAYLOAD",
                schedulingRecommendations:
                  data.schedulingAvailability.schedulingRecommendations,
                cacheKey: mkCacheKey(year, month, day as Day),
              });
            } catch (error) {
              send({
                type: "DAY_SUBSCRIPTION_ERROR",
                error,
              });
            }
          }

          void query();
        },
    },
    devTools: true,
  });
  return [calendarRef, ...machine] as const;
};

function getDateFromEvent(
  event: LoadAvailabilityEvent,
  selectedDate: YearMonthDay,
  selectedMonth: [Year, Month],
) {
  switch (event.type) {
    case "SELECT_MONTH": {
      return yearMonthDayToDate([event.year, event.month, 1]);
    }
    case "SELECT_DURATION": {
      const firstDayOfMonth = yearMonthDayToDate([
        selectedMonth[0],
        selectedMonth[1],
        1,
      ]);
      const day = isBefore(firstDayOfMonth, today()) ? today().getDate() : 1;

      return yearMonthDayToDate([selectedMonth[0], selectedMonth[1], day]);
    }
  }

  return yearMonthDayToDate(selectedDate);
}

function getEndOfCalendarDate(date: Date, weekStartsOnMonday: boolean) {
  return toYearMonthDay(
    endOfWeek(endOfMonth(date), {
      weekStartsOn: weekStartsOnMonday ? 1 : 0,
    }),
  );
}

function requestScheduledAppointments(apolloClient: ApolloClient<unknown>) {
  return (yearMonthDay: YearMonthDay) =>
    apolloClient.query<
      ScheduledAppointmentsQuery,
      ScheduledAppointmentsQueryVariables
    >({
      query: SCHEDULED_APPOINTMENTS_QUERY,
      variables: {
        occursWithin: {
          startDate: yearMonthDayToString(yearMonthDay),
        },
        selectedEmployees: [],
      },
    });
}
