import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "selfServeBookings.ShareLinkFooter.heading",
    defaultMessage: "Share booking link",
    description: "Heading for the ShareLinkFooter component",
  },
  previewButton: {
    id: "selfServeBookings.ShareLinkFooter.previewButton",
    defaultMessage: "Preview",
    description: "Label for the Preview button",
  },
});
