import { useIntl } from "react-intl";
import React from "react";
import { CampaignRecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/CampaignRecipientsSection/CampaignsRecipientSection";
import { messages } from "./messages";

export interface RecipientsSectionProps {
  loading: boolean;
  segmentName: string;
  consentingClientsCount: number;
  totalCount: number;
  timeoutError: boolean;
  redirectToEdit: () => void;
}

export function RecipientsSection({
  loading,
  segmentName,
  consentingClientsCount,
  totalCount,
  timeoutError,
  redirectToEdit,
}: RecipientsSectionProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <CampaignRecipientsSection
      loading={loading}
      summaryMessage={formatMessage(messages.recipientSummary, {
        segmentName,
        consentingClientsCount,
        totalCount,
      })}
      timeoutError={timeoutError}
      redirectToEdit={redirectToEdit}
    />
  );
}
