import { type Dispatch, useEffect } from "react";
import type { PaymentAction, PaymentState } from "../../paymentReducer";

type Dispatcher = Dispatch<PaymentAction>;

interface SubmitWithChargeParamOptions {
  submitWithFormData?: never;
  submitWithPaymentState(cancelAwareDispatcher: Dispatcher): Promise<unknown>;
}
interface SubmitWithFormDataOptions {
  submitWithPaymentState?: never;
  submitWithFormData(
    formData: FormData,
    cancelAwareDispatcher: Dispatcher,
  ): Promise<unknown>;
}

export function usePaymentSubmit(
  paymentState: PaymentState,
  paymentDispatcher: Dispatcher,
  options: SubmitWithChargeParamOptions | SubmitWithFormDataOptions,
) {
  useEffect(() => {
    if (paymentState.status !== "inProgress") return;

    let didEffectCancel = false;

    if (options.submitWithPaymentState) {
      options.submitWithPaymentState(cancelAwareDispatcher).catch(handleError);
    }

    if (options.submitWithFormData && paymentState.formData) {
      options
        .submitWithFormData(paymentState.formData, cancelAwareDispatcher)
        .catch(handleError);
    }

    return () => void (didEffectCancel = true);

    function cancelAwareDispatcher(action: PaymentAction) {
      if (didEffectCancel) return;

      paymentDispatcher(action);
    }

    function handleError(error: unknown) {
      cancelAwareDispatcher({
        type: "completingCharge",
        response: {
          success: false,
          errorMessage: (error && (error as Error).message) as string,
        },
      });
    }
  }, [paymentState.status]);
}
