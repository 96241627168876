import { useMutation } from "@apollo/client";
import type {
  OnlineBookingSettingsInitializeMutation,
  OnlineBookingSettingsInitializeMutationVariables,
} from "~/utilities/API/graphql";
import { INITIALIZE_SETTINGS_MUTATION } from "./graphql";

export function useInitializeSettings() {
  const [callMutation, { data, loading, error }] = useMutation<
    OnlineBookingSettingsInitializeMutation,
    OnlineBookingSettingsInitializeMutationVariables
  >(INITIALIZE_SETTINGS_MUTATION);

  return {
    initializeSettings: callMutation,
    data,
    loading,
    error,
  };
}
