import classNames from "classnames";
import React from "react";
import { Icon } from "@jobber/components/Icon";
import { TwilioRegistrationStatus } from "~/utilities/API/graphql";
import { messages } from "./messages";

interface RegistrationMessage {
  title: string;
  message: string;
  description: string;
}

export function getRegistrationMessageForStatus(
  status?: TwilioRegistrationStatus,
): RegistrationMessage {
  switch (status) {
    /** The account needs to register for Twilio 10DLC but has not yet done so. */
    case TwilioRegistrationStatus.NOT_REGISTERED: {
      return {
        title: messages.notRegistered.title,
        message: messages.notRegistered.message,
        description: messages.notRegistered.description,
      };
    }
    /** The account is currently being processed for Twilio 10DLC registration. */
    case TwilioRegistrationStatus.PROCESSING: {
      return {
        title: messages.processing.title,
        message: messages.processing.message,
        description: messages.processing.description,
      };
    }

    /** The account's registration has failed for Twilio 10DLC. */
    case TwilioRegistrationStatus.REGISTRATION_FAILED: {
      return {
        title: messages.registrationFailed.title,
        message: messages.registrationFailed.message,
        description: messages.registrationFailed.description,
      };
    }

    /** The registration requires verification from the account. */
    case TwilioRegistrationStatus.VERIFICATION_EXPIRED: {
      return {
        title: messages.verificationExpired.title,
        message: messages.verificationExpired.message,
        description: messages.verificationExpired.description,
      };
    }

    // DO NOT SHOW ANYTHING FOR THESE STATUSES
    case TwilioRegistrationStatus.REGISTERED:
    case TwilioRegistrationStatus.REGISTRATION_NOT_REQUIRED:
    case TwilioRegistrationStatus.UNKNOWN_REGISTRATION_STATUS:
    default: {
      return {
        title: "",
        message: "",
        description: "",
      };
    }
  }
}

export function TwilioRegistrationMessage({
  status,
}: {
  status?: TwilioRegistrationStatus;
}) {
  const registrationMessage = getRegistrationMessageForStatus(status);

  return (
    <div
      data-testid={"twilioRegistrationMessageTestId"}
      className={classNames(
        "align-top u-colorGreyBlue u-borderBottom u-paddingRightSmall",
      )}
    >
      <div className="row">
        <div className="columns shrink">
          <div className="row u-paddingSmall">
            <div className="icon--circle u-colorGreyBlue">
              <Icon name="loadingCheck" size="large" />
            </div>
          </div>
        </div>

        <div className="columns u-paddingLeftSmall u-paddingBottomSmall u-borderBottomSmall">
          <div className="row align-middle u-paddingTopSmall u-paddingSmallest">
            <span className="u-textBold u-textLarge">
              {registrationMessage.title}
            </span>
          </div>
          <br />
          {registrationMessage.message && (
            <>
              <div
                data-testid={"messageBlock"}
                className="row align-middle u-paddingLeftSmallest u-paddingRightSmaller"
              >
                <p className="u-marginBottomNone">
                  {registrationMessage.message}
                </p>
              </div>
              <br />
            </>
          )}

          <div className="row align-middle u-paddingLeftSmallest u-paddingRightSmaller">
            <p className="u-marginBottomNone">
              {registrationMessage.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
