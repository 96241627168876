import { Card } from "@jobber/components/Card";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import React from "react";
import styles from "./CheckboxCard.module.css";

interface CheckboxCardProps {
  checked: boolean;
  value?: string;
  title: string;
  name: string;
  onChange?(newValue: boolean): void;
}

export function CheckboxCard({
  checked,
  value,
  onChange,
  title,
  name,
}: CheckboxCardProps) {
  const toggleCheckbox = (e: React.SyntheticEvent<HTMLElement>) => {
    if (onChange) {
      e.preventDefault();
      onChange(!checked);
    }
  };

  const toggleCheckBoxOnSpace = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === " ") {
      if (onChange) {
        event.preventDefault();
        onChange(!checked);
      }
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code.
    <div
      role="checkbox"
      aria-checked={checked}
      onKeyPress={toggleCheckBoxOnSpace}
      className="flexBlock"
    >
      <Card onClick={toggleCheckbox}>
        <div className={styles.verticalCenter}>
          <div className="checkbox u-marginNone">
            <input
              id="c1"
              type="checkbox"
              name={name}
              value={value}
              checked={checked}
              aria-checked={checked}
              onChange={toggleCheckbox}
            />
            <label htmlFor="c1" className="align-middle">
              <span
                className={`checkbox-box icon icon--checkmark ${styles.checkboxMargin}`}
              />
              <Text>
                <Emphasis variation="bold">{title}</Emphasis>
              </Text>
            </label>
          </div>
        </div>
      </Card>
    </div>
  );
}
