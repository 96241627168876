import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expenses: {
    id: "workOrders.jobCost.expenses",
    defaultMessage: "Expenses",
    description: "Expenses label",
  },
  labour: {
    id: "workOrders.jobCost.labour",
    defaultMessage: "Labour",
    description: "Labour label (translated)",
  },
  lineItemCostLowercase: {
    id: "workOrders.jobCost.lineItemCostLowercase",
    defaultMessage: "Line item cost",
    description: "Line item cost label where only first letter capitalized",
  },
  profit: {
    id: "workOrders.jobcost.profit",
    defaultMessage: "Profit",
    description: "Profit label",
  },
  cancel: {
    id: "workOrders.jobCost.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label",
  },
  delete: {
    id: "workOrders.jobCost.delete",
    defaultMessage: "Delete",
    description: "Delete label",
  },
  errorLoadingData: {
    id: "workOrders.jobCost.errorLoadingData",
    defaultMessage: "Error loading data. Please refresh the page.",
    description: "Error when loading data label",
  },
});
