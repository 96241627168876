import { CivilTime } from "@std-proposal/temporal";
import { set, startOfDay } from "date-fns";

export function getDateFromCivilTime(civilTime?: CivilTime): Date | undefined {
  if (!civilTime) {
    return undefined;
  }

  return set(new Date(), {
    hours: civilTime.hour,
    minutes: civilTime.minute,
    seconds: 0,
    milliseconds: 0,
  });
}

export function getCivilTimeFromDate(date?: Date): CivilTime | undefined {
  if (!date) {
    return undefined;
  }

  return new CivilTime(date.getHours(), date.getMinutes());
}

export function setTimeToMidnightOnDate(date: Date): Date {
  return startOfDay(date);
}

export const combineDateTime = (
  date?: Date | undefined,
  time?: Date | undefined,
) => {
  return date && time
    ? set(date, {
        hours: time?.getHours(),
        minutes: time.getMinutes(),
        seconds: time.getSeconds(),
        milliseconds: time.getMilliseconds(),
      })
    : undefined;
};
