import { useMutation } from "@apollo/client";
import type {
  DeliveryMethod,
  GoogleBusinessAccountEditMutation,
} from "~/utilities/API/graphql";
import { GOOGLE_BUSINESS_ACCOUNT_EDIT_MUTATION } from "../reviews.graphql";

export interface EditGoogleBusinessAccountArgs {
  location?: string;
  businessName?: string;
  deliveryMethod?: keyof typeof DeliveryMethod;
}

export function useEditGoogleBusinessAccount() {
  const [editMutation] = useMutation<GoogleBusinessAccountEditMutation>(
    GOOGLE_BUSINESS_ACCOUNT_EDIT_MUTATION,
  );

  async function editGoogleBusinessAccount(
    args: EditGoogleBusinessAccountArgs,
  ) {
    const { location, businessName, deliveryMethod } = args;

    try {
      const result = await editMutation({
        variables: {
          input: {
            primaryLocationName: location,
            businessName,
            deliveryMethod: deliveryMethod?.toUpperCase(),
          },
        },
      });

      const userErrors =
        result.data?.googleBusinessAccountEdit.userErrors ?? [];
      return userErrors;
    } catch (e) {
      return [e];
    }
  }

  return {
    editGoogleBusinessAccount,
  };
}
