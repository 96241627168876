import React from "react";
import { Button } from "@jobber/components/Button";
import { Grid } from "@jobber/components/Grid";
import { Card } from "@jobber/components/Card";
import GrowTrialOnboardingChecklistDonePanel from "@images/GrowTrialOnboardingChecklistDonePanel.png";
import styles from "./DonePanel.module.css";
import { DonePanelContent } from "./DonePanelContent";
import { messages } from "./messages";
import { LARGE_SCREEN_WIDTH } from "../../constants";

interface DonePanelProps {
  onClose?: () => void;
}

export function DonePanel({ onClose }: DonePanelProps) {
  const isSmallScreen = window.innerWidth < LARGE_SCREEN_WIDTH;
  return (
    <Card>
      <div className={styles.donePanelContainer}>
        {!isSmallScreen ? <CloseButton onClose={onClose} /> : ""}

        <Grid alignItems={"stretch"} gap={isSmallScreen ? false : true}>
          <Grid.Cell size={{ xs: 10, lg: 6 }}>
            <GrowTrialImg />
          </Grid.Cell>

          <Grid.Cell size={{ xs: 2 }}>
            {isSmallScreen ? <CloseButton onClose={onClose} /> : ""}
          </Grid.Cell>

          <Grid.Cell size={{ xs: 12, lg: 4 }}>
            <DonePanelContent />
          </Grid.Cell>
        </Grid>
      </div>
    </Card>
  );
}

function CloseButton({ onClose }: DonePanelProps) {
  return (
    <div className={styles.close}>
      <Button
        icon="cross"
        ariaLabel="Dismiss"
        variation="subtle"
        type="tertiary"
        onClick={() => {
          onClose?.();
        }}
      />
    </div>
  );
}

function GrowTrialImg() {
  return (
    <div className={styles.title}>
      <img
        src={GrowTrialOnboardingChecklistDonePanel}
        alt={messages.growTrialImageAlt.defaultMessage}
      />
    </div>
  );
}
