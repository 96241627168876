import { useCallback } from "react";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import type { Operator } from "~/utilities/API/graphql";

export function useUpdateLineItemCriteria({
  updateAdditionalCriteria,
}: {
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}) {
  const updateLineItemCriteria = useCallback(
    ({
      operator,
      lineItemValue,
      criteriaIndex,
    }: {
      operator: Operator;
      criteriaIndex: number;
      lineItemValue: { id: string; name: string } | undefined;
    }) => {
      updateAdditionalCriteria({
        type: "updateLineItemCriteria",
        payload: {
          operator: operator,
          index: criteriaIndex,
          lineItemValue: lineItemValue,
          isDirty: true,
        },
      });
    },
    [updateAdditionalCriteria],
  );
  return updateLineItemCriteria;
}
