import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { LocationTimersCTA } from "./LocationTimersCTA";

export function LocationTimersCTALoader(): JSX.Element {
  return (
    <APIProvider>
      <IntlProvider>
        <LocationTimersCTA />
      </IntlProvider>
    </APIProvider>
  );
}
