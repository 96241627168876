import { Page } from "@jobber/components/Page";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Markdown } from "@jobber/components/Markdown";
import { getPaymentUrl } from "jobber/features/PaymentDisputes/utils/disputeUtils";
import { DisputeDetailsList } from "jobber/features/PaymentDisputes/components/DisputeDetailsList";
import { RespondToDispute } from "jobber/features/PaymentDisputes/components/RespondToDispute";
import { SubmitEvidence } from "jobber/features/PaymentDisputes/components/SubmitEvidence";
import { ViewDisputeStatus } from "jobber/features/PaymentDisputes/components/ViewDisputeStatus";
import { IntlProvider } from "@translations/IntlProvider";
import {
  DisputeCategory,
  type JobberPaymentsDispute,
  type StripeFileLink,
} from "~/utilities/API/graphql";
import { useDisputeUpdate } from "jobber/features/PaymentDisputes/hooks/useDisputeUpdate";
import { useDisputeClosed } from "jobber/features/PaymentDisputes/hooks/useDisputeClosed";
import type { SubmitEvidencePayload } from "jobber/features/PaymentDisputes/types";
import type { SubmitEvidenceValidationError } from "jobber/features/PaymentDisputes/components/SubmitEvidence/SubmitEvidence";
import { useStripeFileLink } from "jobber/features/PaymentDisputes/hooks/useStripeFileLink";
import styles from "./DisputeDetailsPage.module.css";

export interface DisputeDetailsProps {
  dispute: JobberPaymentsDispute;
}

enum DisputeDetailsPageSteps {
  StatusStep,
  SubmitEvidenceStep,
}

export function DisputeDetailsPage(props: DisputeDetailsProps) {
  return (
    <IntlProvider>
      <DisputeDetailsPageInternal {...props} />
    </IntlProvider>
  );
}

// eslint-disable-next-line max-statements
function DisputeDetailsPageInternal(props: DisputeDetailsProps) {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(
    DisputeDetailsPageSteps.StatusStep,
  );
  const [dispute, setDispute] = useState<JobberPaymentsDispute>(props.dispute);

  const [claimDetailsModalOpen, setClaimDetailsModalOpen] = useState(false);
  const [
    supportingEvidenceFileDownloadError,
    setSupportingEvidenceFileDownloadError,
  ] = useState<string>("");

  const onStripeFileLinkComplete = (stripeFileLink: StripeFileLink) => {
    if (stripeFileLink.publicUrl) {
      window.open(stripeFileLink.publicUrl, "_blank", "noopener noreferrer");

      showToast({
        message: "Claim details downloaded",
        variation: "success",
      });
    }
  };

  const stripeFileLink = useStripeFileLink(onStripeFileLinkComplete);

  const onViewClaimDetailsClick = () => {
    if (dispute.issuerEvidences?.nodes[0].fileEvidence) {
      stripeFileLink.retrieveFileLink(
        dispute.issuerEvidences?.nodes[0].fileEvidence[0],
      );
    } else if (dispute.issuerEvidences?.nodes[0].textEvidence) {
      toggleClaimDetailsModal();
    }
  };

  const toggleClaimDetailsModal = () => {
    setClaimDetailsModalOpen(!claimDetailsModalOpen);
  };

  const pendingResponseDisputeStatuses =
    dispute.status === "NEEDS_RESPONSE" ||
    dispute.status === "WARNING_NEEDS_RESPONSE";

  const updateDispute = useDisputeUpdate({
    disputeId: dispute.id,
    updateCompleted: () => {
      setErrorMessages([]);
      setCurrentStep(DisputeDetailsPageSteps.StatusStep);
      showToast({
        message: "Your response was submitted",
        variation: "success",
      });
    },
  });

  const closeDispute = useDisputeClosed(dispute.id, () => {
    setCurrentStep(DisputeDetailsPageSteps.StatusStep);
    showToast({
      message: "You accepted the dispute",
      variation: "success",
    });
  });

  useEffect(() => {
    if (updateDispute.error) {
      setErrorMessages([updateDispute.error]);
    }
    if (updateDispute.dispute) {
      setDispute(updateDispute.dispute);
    }
    if (closeDispute.error) {
      setErrorMessages([closeDispute.error]);
    }
    if (closeDispute.dispute) {
      setDispute(closeDispute.dispute);
    }
    if (stripeFileLink.error) {
      setErrorMessages([stripeFileLink.error]);
    }
    if (supportingEvidenceFileDownloadError) {
      setErrorMessages([supportingEvidenceFileDownloadError]);
    }
  }, [
    updateDispute.error,
    updateDispute.dispute,
    closeDispute.error,
    closeDispute.dispute,
    stripeFileLink.error,
    supportingEvidenceFileDownloadError,
  ]);

  async function handleSubmit(data: SubmitEvidencePayload) {
    updateDispute.updateDispute(data);
  }

  const submitEvidenceValidationErrors = (
    errors: SubmitEvidenceValidationError[],
  ) => {
    setErrorMessages(errors);
  };

  const handleAcceptDisputeClick = () => {
    if (dispute.category == DisputeCategory.INQUIRY) {
      return window.open(
        getPaymentUrl(
          dispute.paymentRecord.id,
          dispute.paymentRecord.client?.id,
        ),
        "_blank",
        "noopener noreferrer",
      );
    } else if (dispute.category == DisputeCategory.CHARGEBACK) {
      closeDispute.closeDispute();
    }
  };

  const renderCurrentStep = () => {
    if (currentStep === DisputeDetailsPageSteps.StatusStep) {
      return pendingResponseDisputeStatuses ? (
        <RespondToDispute
          dispute={dispute}
          onCounterDisputeClick={() =>
            setCurrentStep(DisputeDetailsPageSteps.SubmitEvidenceStep)
          }
          onAcceptDisputeClick={handleAcceptDisputeClick}
          setAcceptError={(message: string) => setErrorMessages([message])}
          isLoading={closeDispute.loading}
          onViewClaimDetailsClick={onViewClaimDetailsClick}
        />
      ) : (
        <ViewDisputeStatus
          dispute={dispute}
          onViewClaimDetailsClick={onViewClaimDetailsClick}
          setSupportingEvidenceFileDownloadError={
            setSupportingEvidenceFileDownloadError
          }
        />
      );
    } else {
      return (
        <SubmitEvidence
          dispute={dispute}
          onCancelClick={() => {
            setCurrentStep(DisputeDetailsPageSteps.StatusStep);
            setErrorMessages([]);
          }}
          onValidationErrors={submitEvidenceValidationErrors}
          onSubmitClick={handleSubmit}
          isLoading={updateDispute.loading}
        />
      );
    }
  };

  return (
    <div className={classnames(styles.pageWrapper)}>
      <div className={classnames(styles.linkContainer)}>
        Back to:
        <Link to="../"> Disputes</Link>
      </div>
      <Page title="Dispute" width="fill">
        <Modal
          title="Claim details"
          open={claimDetailsModalOpen}
          size="small"
          onRequestClose={toggleClaimDetailsModal}
        >
          <Content>
            <Text>{dispute?.issuerEvidences?.nodes[0]?.textEvidence}</Text>
          </Content>
        </Modal>
        <ErrorBanner
          errorMessages={errorMessages}
          onDismiss={() => setErrorMessages([])}
        />

        <div className={classnames(styles.container)}>
          <div className={classnames(styles.respondToDisputeContainer)}>
            {renderCurrentStep()}
          </div>
          {dispute.id && (
            <div className={classnames(styles.disputeDetailsContainer)}>
              <DisputeDetailsList
                dispute={dispute}
                onViewClaimDetailsClick={onViewClaimDetailsClick}
              />
            </div>
          )}
        </div>
      </Page>
    </div>
  );
}

const ErrorBanner = ({
  errorMessages,
  onDismiss,
}: {
  errorMessages: string[];
  onDismiss: () => void;
}) => {
  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.bannerContainer)}>
      <Banner type="error" dismissible={true} onDismiss={onDismiss}>
        <ul>
          {errorMessages.map((message, index) => (
            <li key={index}>
              <Markdown content={message} externalLink={true} />
            </li>
          ))}
        </ul>
      </Banner>
    </div>
  );
};
