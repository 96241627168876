import React, { useCallback, useState } from "react";
import { parseISO } from "date-fns";
import { Text } from "@jobber/components/Text";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import type { FetchResult } from "@apollo/client";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import type {
  Review,
  ReviewReplyEditInput,
  ReviewReplyEditMutation,
} from "~/utilities/API/graphql";
import styles from "./ReviewCard.module.css";
import { messages } from "./messages";
import { StarGroup } from "../StarGroup";
import { ReplyCard } from "../ReplyCard";

export const ReviewCard = React.memo(ReviewCardInternal);

function ReviewCardInternal({
  review,
  sendReviewReply,
}: {
  review: Review;
  sendReviewReply: (
    options: ReviewReplyEditInput,
  ) => Promise<FetchResult<ReviewReplyEditMutation>>;
}) {
  const { formatMessage } = useIntl();
  const [reviewReplyError, setReviewReplyError] = useState<string | undefined>(
    undefined,
  );

  const dismissErrorMessage = useCallback(() => {
    if (reviewReplyError !== undefined) {
      setReviewReplyError(undefined);
    }
  }, [reviewReplyError]);

  const setErrorMessage = useCallback(
    (message: string) => {
      if (reviewReplyError !== undefined) {
        setReviewReplyError(undefined);
      }
      setReviewReplyError(message);
    },
    [reviewReplyError],
  );

  if (review === undefined) {
    return <></>;
  }

  const showTextInput = !review.reviewReply?.comment?.length;

  return (
    <div
      className={
        showTextInput
          ? styles.listItemContainer
          : styles.listItemContainerLegacy
      }
    >
      <Content type={"section"} spacing={"smaller"}>
        {reviewReplyError !== undefined && (
          <Banner type={"error"}>{reviewReplyError}</Banner>
        )}
        <StarGroup rating={review.rating ?? 0} allowHalfStars={false} />
        <div className={styles.listItemRow}>
          <Heading level={5}>
            {review.reviewer.isAnonymous
              ? formatMessage(messages.anonymousReviewer)
              : review.reviewer.displayName}
          </Heading>
          <Text size={"small"} variation={"subdued"}>
            <FormatRelativeDateTime date={parseISO(review.updatedAt)} />
          </Text>
        </div>
        <Text>{review.comment}</Text>
        <ReplyCard
          reviewComment={review.comment}
          comment={review.reviewReply?.comment}
          date={review.reviewReply?.updatedAt}
          reviewId={review.reviewId}
          sendReviewReply={sendReviewReply}
          setErrorMessage={setErrorMessage}
          dismissErrorMessage={dismissErrorMessage}
        />
      </Content>
    </div>
  );
}
