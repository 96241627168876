import { useContext, useEffect } from "react";
import { usePreloadImages } from "jobber/setupWizard/hooks/usePreloadImages";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  extractBackgroundImageSrc,
  getIndustrySpecificContent,
} from "../../utils";
import type { SetupWizardStep } from "../../types";

interface PreloadStepImagesProps {
  currentSteps: SetupWizardStep[];
  currentStepIndex: number;
}

export function PreloadStepImages({
  currentSteps,
  currentStepIndex,
}: PreloadStepImagesProps) {
  const {
    wizardData: { industry },
  } = useContext(SetupWizardContext);
  const { preloadImages } = usePreloadImages();

  useEffect(() => {
    if (currentStepIndex < currentSteps.length - 1 && industry) {
      const nextStep = currentSteps[currentStepIndex + 1];
      const content = getIndustrySpecificContent(industry, nextStep.route);

      const imageSrc = extractBackgroundImageSrc(content, nextStep.route);

      if (imageSrc) {
        void preloadImages([imageSrc]);
      }
    }
  }, [currentStepIndex, currentSteps, industry, preloadImages]);

  return null;
}
