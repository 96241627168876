import React from "react";
import { Spinner } from "@jobber/components/Spinner";
import { Text } from "@jobber/components/Text";
import { Chip, Chips } from "@jobber/components/Chips";
import { Avatar as AvatarComponent } from "@jobber/components/Avatar";
import { Checkbox } from "@jobber/components/Checkbox";
import styles from "./TeamAssignment.module.css";
import type { TeamAssignmentProps } from "./TeamAssignment";
import { messages } from "./messages";

function LoadingIndicator() {
  return (
    <div className={styles.loadingContainer}>
      <Spinner size="small" />
    </div>
  );
}

type TeamAssignmentCardContentProps = Omit<
  TeamAssignmentProps,
  "renderCardHeader "
>;

export function TeamAssignmentCardContent(
  props: TeamAssignmentCardContentProps,
) {
  if (props.loading) {
    return <LoadingIndicator />;
  }

  if (props.disabled) {
    return <Text>{messages.disabledMessage.defaultMessage}</Text>;
  }

  return (
    <>
      {props.selectedAssignees.length === 0 && (
        <Text>{messages.emptyStateMessage.defaultMessage}</Text>
      )}
      <Chips
        type="dismissible"
        selected={props.selectedAssignees}
        onChange={props.setSelectedAssignees}
      >
        {props.assignableUsers.map((user, index) => (
          <Chip
            key={index}
            label={user.name.full}
            value={user.id}
            prefix={
              <AvatarComponent
                size={"base"}
                initials={user.avatar.initials}
                imageUrl={user.avatar.image?.photoUrl}
              />
            }
          />
        ))}
      </Chips>
      {props.selectedAssignees.length > 0 && (
        <Checkbox
          checked={props.emailAssignment}
          label={messages.emailTeam.defaultMessage}
          onChange={props.setEmailAssignment}
        />
      )}
    </>
  );
}
