import { Option, Select } from "@jobber/components/Select";
import { Text } from "@jobber/components/Text";
import React from "react";
import { Heading } from "@jobber/components/Heading";
import { RecipientPicker } from "jobber/recipientPicker/components/EmailRecipientPicker/RecipientPicker";
import type { WorkObject } from "~/utilities/API/graphql";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import { messages } from "./messages";
import type { Ownership, OwnershipRecord } from "./useGlobalOwnership";
import style from "./GlobalOwnershipModal.module.css";

export interface OwnershipSectionProps {
  ownership: OwnershipRecord;
  type: WorkObject;
  onChange(update: { type: WorkObject; values: Ownership[] }): void;
}

function OwnershipSection({
  ownership,
  type,
  onChange,
}: OwnershipSectionProps) {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.MultipleReplyTo],
  });
  const canUseMultipleReplyTo = getTreatmentValue(SplitNames.MultipleReplyTo);

  function handleChange(values: string[]) {
    onChange({
      type,
      values: values.map(value => {
        return {
          ownershipType: ownership.options.find(
            option => option.label === value,
          )?.ownershipType,
          name: value,
          userId: ownership.options.find(option => option.label === value)
            ?.userId,
        };
      }),
    });
  }

  function handleSingleChange(value: string) {
    handleChange([value]);
  }

  const displayNames = ownership.values.map(owner => owner.name);

  return (
    <div className={style.header}>
      <Heading level={4}>{messages[type].title}</Heading>
      <Text size="small" variation="default" align="start">
        {messages[type].description}
      </Text>
      {canUseMultipleReplyTo && (
        <RecipientPicker
          dropdownRecipients={ownership.options.map(option => {
            return option.label;
          })}
          preloadedRecipients={displayNames}
          inputName="Reply-to"
          placeholderSingular="Send email replies to"
          placeholderPlural="Send email replies to"
          restrictInput={true}
          showLabel={false}
          onChange={handleChange}
        />
      )}
      {!canUseMultipleReplyTo && (
        <Select
          placeholder="Send email replies to"
          value={displayNames[0]}
          onChange={handleSingleChange}
        >
          {ownership.options.map(option => {
            return (
              <Option
                value={option.label}
                key={`${option.ownershipType}${option?.userId || ""}`}
              >
                {option.label}
              </Option>
            );
          })}
        </Select>
      )}
    </div>
  );
}

const SplitWrappedComponent = withSplitClient(OwnershipSection);
export { SplitWrappedComponent as OwnershipSection };
