import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deletePaymentMethodConfirmationTitle: {
    id: "PaymentsSCA.PaymentMethodListItem.deletePaymentMethodConfirmationTitle",
    defaultMessage: "Delete Payment Method",
    description: "Modal title for deleting a payment method",
  },
  deleteDefaultPaymentMethodConfirmationTitle: {
    id: "PaymentsSCA.PaymentMethodListItem.deleteDefaultPaymentMethodConfirmationTitle",
    defaultMessage: "This is a default card",
    description: "Modal title for deleting a DEFAULT payment method",
  },
  deletePaymentMethodConfirmationMessage: {
    id: "PaymentsSCA.PaymentMethodListItem.deletePaymentMethodConfirmationMessage",
    defaultMessage: "Are you sure you want to delete this payment method?",
    description: "Message to confirm deleting a payment method",
  },
  deleteDefaultPaymentMethodConfirmationMessage: {
    id: "PaymentsSCA.PaymentMethodListItem.deleteDefaultPaymentMethodConfirmationMessage",
    defaultMessage:
      "Add a new default payment method before deleting this card to keep automatic payments enabled.",
    description: "Message to confirm deleting a payment method",
  },
  deleteDefaultPaymentMethodDisclaimerMessage: {
    id: "PaymentsSCA.PaymentMethodListItem.deleteDefaultPaymentMethodDisclaimerMessage",
    defaultMessage:
      "By deleting this card, you will disable automatic payments on all jobs associated with this client.",
    description:
      "Disclaimer message when deleting the last payment method and automatic payments are enabled",
  },
  defaultPaymentMethodAria: {
    id: "PaymentsSCA.PaymentMethodListItem.defaultPaymentMethodAria",
    defaultMessage: "Set as default payment method",
    description: "Aria label for default payment method",
  },
  deletePaymentMethodAria: {
    id: "PaymentsSCA.PaymentMethodListItem.deletePaymentMethodAria",
    defaultMessage: "Delete payment method",
    description: "Aria label for deleting payment method",
  },
});
