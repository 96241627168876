import React, { useMemo } from "react";
import { type Column, useTable } from "react-table";
import { Checkbox } from "@jobber/components/Checkbox";
import { TableHeader } from "jobber/workOrders/components/TableHeader";
import { TableRow } from "jobber/workOrders/components/TableRow";
import styles from "./ListTable.module.css";

const sampleData = [
  {
    name: "James Holden",
    status: "Lead",
    properties: "4 properties",
    contact: "555-555-5555",
    tags: "primary",
    lastActivity: "June 24, 2020",
  },
  {
    name: "Amos Burton",
    status: "Engineer",
    properties: "2 properties",
    contact: "555-555-5555",
    tags: "primary",
    lastActivity: "June 24, 2020",
  },
  {
    name: "Alex Kamal",
    status: "Pilot",
    properties: "2 properties",
    contact: "555-555-5555",
    tags: "primary",
    lastActivity: "June 24, 2020",
  },
  {
    name: "Naomi Nagata",
    status: "Officer",
    properties: "2 properties",
    contact: "555-555-5555",
    tags: "primary",
    lastActivity: "June 24, 2020",
  },
];

interface TableProps {
  readonly columns: Column[];
  readonly data: {
    name: string;
    status: string;
    properties: string;
    contact: string;
    tags: string;
    lastActivity: string;
  }[];
}

function Table({ columns, data }: TableProps) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              <th className={styles.headerCheckbox}>
                <Checkbox indeterminate={true} />
              </th>
              {headerGroup.headers.map(column => (
                <th
                  className={styles.header}
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                >
                  <TableHeader>{column.render("Header")}</TableHeader>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TableRow
                key={row.getRowProps().key}
                cells={row.cells.map(cell => cell.render("Cell"))}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export const ListTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Properties",
        accessor: "properties",
      },
      {
        Header: "Contact",
        accessor: "contact",
      },
      {
        Header: "Tags",
        accessor: "tags",
      },
      {
        Header: "Last activity",
        accessor: "lastActivity",
      },
    ],
    [],
  );

  const data = useMemo(() => sampleData, []);

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
};
