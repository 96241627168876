export const ANALYTICS_EVENTS = {
  selfServeBookingsSettings: {
    clickedPreviewButton: "Clicked Preview Online Booking",
    clickedCopyBookingUrl: "Clicked Copy to Clipboard",
    copyBookingUrlSources: {
      bookingSettingsPage: "Online Booking Settings",
    },
    configuredServiceAreas: "Configured Service Areas",
    toggledOLBFeature: "Toggled OLB feature",
  },
};
