import React, { useState } from "react";
import { Icon } from "@jobber/components/Icon";
// eslint-disable-next-line import/no-deprecated
import { FileUploader_DEPRECATED } from "~/jobber/fileUploader/FileUploader";
// eslint-disable-next-line import/no-deprecated
import { EditableThumbnail } from "jobber/editableThumbnail/EditableThumbnail";
import {
  removeLineItemImage,
  setLineItemImage,
} from "jobber/bridges/lineItemImageForm";

export interface LineItemImageSelectorChangedEvent {
  fileName: string | undefined;
  contentType: string | undefined;
  fileSize: number | undefined;
  s3Key: string | undefined;
  thumbnail: string | undefined;
}

export interface LineItemImageSelectorProps {
  signingUrl: string;
  s3ThumbnailUrl: string;
  s3Key: string;
  fileName: string;
  canUpload: boolean;
  // we need this prop right now as there are currently places in the codebase where
  // we are using this component in an erb. This can be removed once this is no longer
  // the case.
  parentIsReact: boolean;
  uniqueId?: string;
  railsFormKey?: string;
  onChanged?(event: LineItemImageSelectorChangedEvent): void;
  onDeleted?(): void;
}

export interface FileAttachmentData {
  fileName: string;
  contentType: string;
  fileSize: number;
  key: string;
  thumbnail?: string;
}

interface FileAttachmentCallbacks {
  [key: string]: (outsideS3Key: string, outsideFileName: string) => void;
}

declare global {
  interface Window {
    fileAttachmentCallbacks: FileAttachmentCallbacks;
  }
}
window.fileAttachmentCallbacks = window.fileAttachmentCallbacks || {};

// This file will be changing as we are replacing @jobber/react-s3-uploader with dropzone
// eslint-disable-next-line max-statements
export function LineItemImageSelector(props: LineItemImageSelectorProps) {
  const [lastProps, setLastProps] = useState(props);
  const [thumbnail, setThumbnail] = useState("");
  const [name, setName] = useState(props.fileName);
  const [s3Key, setS3Key] = useState(props.s3Key);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [uploading, setUploading] = useState(false);
  // This should be a useRef and use ref forwarding
  const [inputRef, setInputRef] = useState<HTMLInputElement | undefined>(
    undefined,
  );

  if (lastProps !== props) {
    setName(props.fileName);
    setS3Key(props.s3Key);
    setLastProps(props);
    if (lastProps.s3Key !== props.s3Key) {
      setThumbnail("");
    }
  }

  function onUploaded(
    fileName: string,
    contentType: string,
    fileSize: number,
    key: string,
    thumbnailURL: string,
  ) {
    // This can be removed when we are no longer using this component in erb files
    if (!props.parentIsReact && props.uniqueId) {
      setLineItemImage(
        props.uniqueId,
        fileName,
        contentType,
        String(fileSize),
        key,
      );
    } else if (props.onChanged) {
      props.onChanged({
        fileName: fileName,
        contentType: contentType,
        fileSize: fileSize,
        s3Key: key,
        thumbnail: thumbnailURL,
      });
    }
  }

  function onDeleted() {
    // This can be removed when we are no longer using this component in erb files
    if (!props.parentIsReact && props.uniqueId) {
      removeLineItemImage(props.uniqueId);
    } else if (props.onDeleted) {
      props.onDeleted();
    }
  }

  function handleUploadClick() {
    if (inputRef) {
      inputRef.click();
    }
  }

  if ((name && name !== "") || uploading) {
    return (
      <div>
        <EditableThumbnail
          thumbnail={thumbnail}
          name={name}
          percentCompleted={percentCompleted}
          uploading={uploading}
          canUpload={props.canUpload}
          signingUrl={props.signingUrl}
          s3ThumbnailUrl={props.s3ThumbnailUrl}
          s3Key={s3Key}
          setS3Key={setS3Key}
          setName={setName}
          setThumbnail={setThumbnail}
          setPercentCompleted={setPercentCompleted}
          setUploading={setUploading}
          onDeleted={onDeleted}
          onUploaded={onUploaded}
        />
      </div>
    );
  } else if (!props.canUpload) {
    return <></>;
  } else {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control -- Grandfathered error: Please fix if touching this code.
      <label id={props.uniqueId}>
        <button
          className="fileUpload u-border u-borderDashed u-paddingBottomSmall u-paddingTopSmall align-middle u-textCenter u-borderRadius"
          style={{
            cursor: "pointer",
            height: "70px",
            display: "block",
            width: "100%",
          }}
          type="button"
          onClick={handleUploadClick}
        >
          <Icon name="camera" color="interactive" />
          <FileUploader_DEPRECATED
            setInputRef={setInputRef}
            signingUrl={props.signingUrl}
            s3ThumbnailUrl={props.s3ThumbnailUrl}
            setName={setName}
            setS3Key={setS3Key}
            setThumbnail={setThumbnail}
            setPercentCompleted={setPercentCompleted}
            setUploading={setUploading}
            onUploaded={onUploaded}
          />
        </button>
      </label>
    );
  }
}
