import { defineMessages } from "react-intl";

export const messages = defineMessages({
  switchTitle: {
    id: "jobber.workOrders.components.automaticPayments.components.enabledOnCardAddSwitchMessage",
    defaultMessage: "Get paid automatically",
    description: "Title for the switch to enable automatic payments",
  },
  switchSubtitleMarkdown: {
    id: "jobber.workOrders.components.automaticPayments.components.enabledOnCardAddSwitchSubtitle",
    defaultMessage:
      "Sit back as the money rolls in. Clients are automatically invoiced and charged based on their billing frequency once they save a payment method on file. Learn more in [Help Center](https://help.getjobber.com/hc/en-us/articles/360036931633-Automatic-Payments).",
    description: "Subtitle for the switch to enable automatic payments",
  },
});
