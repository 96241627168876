import { Modal } from "@jobber/components/Modal";
import React from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import GrowTrialGlobalBanner from "@images/GrowTrialGlobalBanner.png";
import { IntlProvider } from "@translations/IntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./ConnectToGrowOnboardingPopUpModal.module.css";

export interface ConnectToGrowOnboardingPopUpModalProps {
  showModal: boolean;
  confirmationCallback: () => void;
  dismissalCallback: () => void;
}

function ConnectToGrowOnboardingPopUpModal({
  showModal,
  confirmationCallback,
  dismissalCallback,
}: ConnectToGrowOnboardingPopUpModalProps) {
  const { formatMessage } = useIntl();
  return (
    <Modal
      title={formatMessage(messages.modalTitle)}
      open={showModal}
      dismissible={true}
      onRequestClose={handleModalDismissal}
      primaryAction={{
        label: formatMessage(messages.closeGuideButton),
        onClick: handleCloseGuideButtonClick,
      }}
      secondaryAction={{
        label: formatMessage(messages.cancelButton),
        onClick: handleCancelButtonClick,
      }}
    >
      <Content>
        <div className={styles.popUpDescription}>
          <Text>{formatMessage(messages.modalDescription)}</Text>
        </div>
        <img
          data-testid="growTrialGlobalBannerImage"
          src={GrowTrialGlobalBanner}
          alt={formatMessage(messages.bannerImageAltText)}
        />
      </Content>
    </Modal>
  );

  function handleCancelButtonClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "connect_to_grow_onboarding_popup_modal",
      name: "cancel_button",
    });
    dismissalCallback();
  }

  function handleCloseGuideButtonClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "connect_to_grow_onboarding_popup_modal",
      name: "yes_close_guide_button",
    });
    dismissalCallback();
    confirmationCallback();
  }

  function handleModalDismissal() {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: "connect_to_grow_onboarding_popup_modal",
      name: "modal_dismissal_button",
    });
    dismissalCallback();
  }
}

function WrappedConnectToGrowOnboardingPopUpModal({
  showModal,
  confirmationCallback,
  dismissalCallback,
}: ConnectToGrowOnboardingPopUpModalProps) {
  return (
    <IntlProvider>
      <ConnectToGrowOnboardingPopUpModal
        showModal={showModal}
        confirmationCallback={confirmationCallback}
        dismissalCallback={dismissalCallback}
      />
    </IntlProvider>
  );
}

export { WrappedConnectToGrowOnboardingPopUpModal as ConnectToGrowOnboardingPopUpModal };
