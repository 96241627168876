import { useMemo } from "react";
import type { ProductOrServiceCustomFieldFragment } from "~/utilities/API/graphql";

interface useProductAndServiceCustomFieldsByAppAndStatusResult {
  customFieldsByAppAndStatus: {
    archived: Record<string, ProductOrServiceCustomFieldFragment[]>;
    unArchived: Record<string, ProductOrServiceCustomFieldFragment[]>;
  };
}

export function useProductAndServiceCustomFieldsByAppAndStatus(
  customFields: ProductOrServiceCustomFieldFragment[],
): useProductAndServiceCustomFieldsByAppAndStatusResult {
  const customFieldsByAppAndStatus = useMemo(() => {
    const groupedFields = {
      archived: {} as Record<string, ProductOrServiceCustomFieldFragment[]>,
      unArchived: {} as Record<string, ProductOrServiceCustomFieldFragment[]>,
    };

    customFields.forEach(field => {
      const isArchived = field.customFieldConfiguration.archived;
      const appName = field.customFieldConfiguration.app?.name || "Unknown App";

      const group = isArchived
        ? groupedFields.archived
        : groupedFields.unArchived;

      if (!group[appName]) {
        group[appName] = [];
      }

      group[appName].push(field);
    });

    return groupedFields;
  }, [customFields]);

  return { customFieldsByAppAndStatus };
}
