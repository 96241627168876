import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  tableNoItemsFound: {
    id: "reporting.report.tableNoItemsFound",
    defaultMessage: "No items found",
    description: "Description when there are no results in the table",
  },
  updateSearchOrFiltersMessage: {
    id: "reporting.report.updateSearchOrFiltersMessage",
    defaultMessage: "Update your search or filter selection",
    description:
      "Call to action to the user when there are no results in the table",
  },
});
