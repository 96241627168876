import React, { useCallback } from "react";
import classnames from "classnames";
import {
  type DropzoneOptions,
  type FileRejection,
  useDropzone,
} from "react-dropzone";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import styles from "./InputImage.module.css";
import { messages } from "./messages";

export interface UploadParams {
  readonly url: string;
  readonly key?: string;
  readonly fields?: { [field: string]: string };
  readonly httpMethod?: "POST" | "PUT";
}

interface InputImageProps {
  readonly buttonLabel?: string;
  readonly maxFileSize?: number; //in bytes
  readonly testId?: string;
  handleDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

export function InputImage({
  buttonLabel: providedButtonLabel,
  handleDrop,
  maxFileSize,
  testId,
}: InputImageProps) {
  const options: DropzoneOptions = {
    onDrop: useCallback(handleDrop, []),
    maxSize: maxFileSize,
    accept: "image/*",
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const { buttonLabel, hintText } = getLabels(providedButtonLabel);
  const dropZone = classnames(styles.dropZoneBase, styles.dropZone, {
    [styles.active]: isDragActive,
  });

  return (
    <div
      {...getRootProps({ className: dropZone })}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <input {...getInputProps()} data-testid={testId} />

      <Content spacing="small">
        <Button label={buttonLabel} size="small" type="secondary" />

        <Text size="small" variation="subdued">
          {hintText}
        </Text>
      </Content>
    </div>
  );
}

function getLabels(providedButtonLabel: string | undefined) {
  let buttonLabel = messages.buttonLabelImage.message;
  const hintText = messages.hintTextImage.message;

  if (providedButtonLabel) buttonLabel = providedButtonLabel;

  return { buttonLabel, hintText };
}
