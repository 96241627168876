import { gql } from "@apollo/client";

export const SETUP_WIZARD_MUTATION = gql`
  mutation SetupWizard($setupWizardInput: SetupWizardEditInput!) {
    setupWizardEdit(input: $setupWizardInput) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const SETUP_WIZARD_QUERY = gql`
  query SetupWizardData {
    account {
      industry
      name
      phone
      setupWizard {
        companySize
        heardAboutUs
        questionsAndAnswers {
          answer
          question
        }
        smsOptIn
      }
      accountOwner {
        fullName
        email {
          raw
        }
      }
    }
  }
`;
