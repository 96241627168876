import React from "react";
import { WorkflowButton } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/WorkflowButton";
import { BubbleWrapper } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/BubbleWrapper";
import type {
  MessageDataTextMessageFragment,
  SmsFile,
} from "~/utilities/API/graphql";
import { SmsFileAttachment } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/SmsFileAttachment";
import styles from "./Content.module.css";

interface TextMessageContentProps {
  readonly message: MessageDataTextMessageFragment;
}

export function TextMessageContent({ message }: TextMessageContentProps) {
  const isAssistant =
    message.internalContactObject?.__typename === "AiReceptionist";
  return (
    <BubbleWrapper
      direction={message.direction}
      status={message.status}
      isAssistant={isAssistant}
    >
      <div className={styles.flexColumn}>
        {message.content}

        <SmsFileAttachment files={message.files.nodes as SmsFile[]} />
        {message?.linkableWorkObject && <WorkflowButton message={message} />}
      </div>
    </BubbleWrapper>
  );
}
