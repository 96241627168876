import type { AllConditionTypes } from "./types";

export interface AddConditionAction {
  type: "addCondition" | "resetConditionType";
  payload?: AllConditionTypes;
  index?: number;
}

export interface UpdateConditionAction {
  type: "updateCondition";
  payload: {
    [key: string]: string | number | boolean;
  };
  index: number;
}

export interface LoadConditionsAction {
  type: "loadConditions";
  payload: AllConditionTypes[];
}

export interface RemoveConditionAction {
  type: "removeCondition";
  index: number;
}

export function reducer(
  state: AllConditionTypes[],
  action:
    | AddConditionAction
    | UpdateConditionAction
    | RemoveConditionAction
    | LoadConditionsAction,
) {
  switch (action.type) {
    case "addCondition":
      return [...state, action.payload];
    case "updateCondition":
      return state.map((condition, index) => {
        if (index === action.index) {
          return {
            ...condition,
            arguments: {
              ...condition.arguments,
              fields: {
                ...condition.arguments.fields,
                ...action.payload,
              },
            },
          };
        }
        return condition;
      });
    case "resetConditionType":
      return state.map((condition, index) => {
        if (index === action.index) {
          return action.payload;
        }
        return condition;
      });
    case "removeCondition":
      return state.filter((_, index) => index !== action.index);
    case "loadConditions":
      return action.payload;
    default:
      throw new Error("Invalid action type");
  }
}
