import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type {
  ActivateLiteToCoreTrialMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import styles from "./LiteToCoreTrialBeginModal.module.css";
import {
  BeginModalFirstSlideImg,
  BeginModalSecondSlideImg,
  BeginModalThirdSlideImg,
} from "./images";
import { ACTIVATE_LITE_TO_CORE_TRIAL } from "./ActivateLiteToCoreTrial.graphql";

export interface LiteToCoreTrialBeginModalProps {
  source: string;
  showModal: boolean;
  closeModal(): void;
}

export function LiteToCoreTrialBeginModal({
  source,
  showModal,
  closeModal,
}: LiteToCoreTrialBeginModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [activateLiteToCoreTrial] =
    useMutation<ActivateLiteToCoreTrialMutation>(ACTIVATE_LITE_TO_CORE_TRIAL);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselContent = [
    {
      title: "Performance tracking & insights",
      description:
        "One glance in Jobber shows revenue at each stage of your workflow, and recommended actions to keep your business running smoothly.",
      image: BeginModalFirstSlideImg,
      buttons: {
        primaryAction: { label: "Next", onClick: handleNext },
      },
    },
    {
      title: "Scheduling & job details",
      description:
        "Every client visit or in-house task is tracked in the Jobber calendar, with job details easily accessed from the field in the Jobber Mobile App.",
      image: BeginModalSecondSlideImg,
      buttons: {
        primaryAction: { label: "Next", onClick: handleNext },
        secondaryAction: { label: "Previous", onClick: handlePrevious },
      },
    },
    {
      title: "Custom invoicing & reminders",
      description:
        "Set a custom billing frequency for recurring jobs. Jobber will remind you when it's time to invoice the job, so you never fall behind on getting paid.",
      image: BeginModalThirdSlideImg,
      buttons: {
        primaryAction: {
          label: "Start free trial",
          onClick: handleStartFreeTrial,
          isLoading: isLoading,
        },
        secondaryAction: { label: "Previous", onClick: handlePrevious },
      },
    },
  ];

  useEffect(() => {
    if (showModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: "lite_to_core_trial_begin_modal",
        source,
      });
    }
  }, [showModal]);

  return (
    <Modal
      title="The Core plan experience"
      open={showModal}
      onRequestClose={handleModalDismissal}
    >
      <Content>
        <div className={styles.slideContainer}>
          {carouselContent.map((content, index) => {
            return (
              <div className={getSlideClass(index)} key={index}>
                <LiteToCoreBeginModalSlide
                  slide={index}
                  carouselContent={content}
                />
              </div>
            );
          })}
        </div>
        <LiteToCoreBeginModalButtons
          buttons={carouselContent[currentSlide].buttons}
        />
      </Content>
    </Modal>
  );

  function handleModalDismissal() {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      name: "lite_to_core_trial_begin_modal",
      source: currentSlide,
    });
    setCurrentSlide(0);
    closeModal();
  }

  async function handleStartFreeTrial() {
    setIsLoading(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "start_free_trial",
      source: "lite_to_core_trial_begin_modal",
    });
    await updateTrialState();
    setIsLoading(false);
  }

  function handleNext() {
    setCurrentSlide(currentSlide + 1);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "lite_to_core_trial_begin_modal_next",
      source: currentSlide,
    });
  }

  function handlePrevious() {
    setCurrentSlide(currentSlide - 1);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "lite_to_core_trial_begin_modal_previous",
      source: currentSlide,
    });
  }

  function getSlideClass(slide: number) {
    const classList = [styles.slide];
    if (currentSlide === slide) {
      classList.push(styles.present);
    }
    return classList.join(" ");
  }

  async function updateTrialState() {
    const defaultError = new Error(
      "Core trial could not be started. Please try again.",
    );
    try {
      const { data } = await activateLiteToCoreTrial();
      const errors = data?.activateLiteToCoreTrial
        .userErrors as MutationErrors[];
      if (errors.length === 0) {
        window.location.href = "/home";
      } else {
        showToast({
          message: defaultError.message,
          variation: "error",
        });
      }
    } catch (e) {
      showToast({
        message: defaultError.message,
        variation: "error",
      });
    }
  }
}

interface LiteToCoreBeginModalSlideProps {
  slide: number;
  carouselContent: {
    title: string;
    description: string;
    image: { src: string; alt: string };
  };
}

function LiteToCoreBeginModalSlide({
  slide,
  carouselContent,
}: LiteToCoreBeginModalSlideProps) {
  return (
    <Content>
      <Heading level={3}>{carouselContent.title}</Heading>
      <Text>{carouselContent.description}</Text>
      <div className={styles.imageContainer}>
        <img
          src={carouselContent.image.src}
          alt={carouselContent.image.alt}
          className={styles.image}
        />
      </div>
      <div className={styles.dots}>
        <div className={slide === 0 ? styles.greenDot : styles.greyDot} />
        <div className={slide === 1 ? styles.greenDot : styles.greyDot} />
        <div className={slide === 2 ? styles.greenDot : styles.greyDot} />
      </div>
    </Content>
  );
}

interface LiteToCoreBeginModalButtonsProps {
  buttons: {
    primaryAction: { label: string; isLoading?: boolean; onClick(): void };
    secondaryAction?: { label: string; onClick(): void };
  };
}

function LiteToCoreBeginModalButtons({
  buttons,
}: LiteToCoreBeginModalButtonsProps) {
  return (
    <div
      className={`${styles.buttons} ${
        buttons.secondaryAction ? styles.twoButtons : styles.singleButton
      }`}
    >
      {buttons.secondaryAction && (
        <Button
          label={buttons.secondaryAction?.label}
          onClick={buttons.secondaryAction?.onClick}
          type="primary"
          variation="subtle"
        />
      )}
      <Button
        label={buttons.primaryAction.label}
        onClick={buttons.primaryAction.onClick}
        loading={buttons.primaryAction.isLoading}
      />
    </div>
  );
}
