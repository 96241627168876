import React from "react";
import { Content, Glimmer } from "@jobber/components";
import styles from "../ReviewsSettings.module.css";

export function LoadingLayout({
  showCustomizations = false,
}: {
  showCustomizations?: boolean;
}) {
  return (
    <Content>
      <Glimmer size={"largest"} />
      <Glimmer size={"largest"} />
      {showCustomizations && (
        <Content>
          <div className={styles.preview}>
            <Glimmer size={"auto"} />
          </div>
          <Glimmer size={"largest"} />
        </Content>
      )}
      <Glimmer size={"large"} />
      <div className={styles.preview}>
        <Glimmer size={"auto"} />
      </div>
      <div className={styles.footer}>
        <Glimmer size={"largest"} />
      </div>
    </Content>
  );
}
