import { defineMessages } from "react-intl";

const homeIdPrefix = "homePage.home.";

export const messages = defineMessages({
  goodMorningGreeting: {
    id: `${homeIdPrefix}goodMorningGreeting`,
    defaultMessage: "Good morning{formattedName}",
    description: "Greeting message in the morning for the home page",
  },
  goodAfternoonGreeting: {
    id: `${homeIdPrefix}goodAfternoonGreeting`,
    defaultMessage: "Good afternoon{formattedName}",
    description: "Greeting message in the afternoon for the home page",
  },
  goodEveningGreeting: {
    id: `${homeIdPrefix}goodEveningGreetin`,
    defaultMessage: "Good evening{formattedName}",
    description: "Greeting message in the evening for the home page",
  },
  hideInsightsLabel: {
    id: `${homeIdPrefix}hideInsightsLabel`,
    defaultMessage: "Hide Insights",
    description: "Label for the hide insights button on the home page",
  },
  viewInsightsLabel: {
    id: `${homeIdPrefix}viewInsightsLabel`,
    defaultMessage: "View Insights",
    description: "Label for the view insights button on the home page",
  },
  insightsUnavailableBannerMessage: {
    id: `${homeIdPrefix}insightsUnavailableBannerMessage`,
    defaultMessage:
      "Due to server maintenance, insights are temporarily unavailable.",
    description:
      "Message to be shown when insights are unavailable on the home page",
  },
  learnMoreAboutInsightsLabel: {
    id: `${homeIdPrefix}learnMoreAboutInsightsLabel`,
    defaultMessage: "Learn more about insights",
    description:
      "Label for the 'learn more about insights' button on the home page",
  },
  todaysAppointmentsLabel: {
    id: `${homeIdPrefix}todaysAppointmentsLabel`,
    defaultMessage: "Today's Appointments",
    description: "Label for the today's appointments section of the home page",
  },
});
