import type { Option } from "@jobber/components/Autocomplete";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import {
  type InvoiceDetailFilterInput,
  type InvoiceDetailSortInput,
  InvoiceDetailSortKey,
  SortDirectionEnum,
} from "~/utilities/API/graphqlFranchiseManagement";
import type { InvoiceDetailSortState } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/types";

export function useFormatSortAndFilter() {
  return { formatSort, formatFilter };
}

function formatSort(
  sort: [InvoiceDetailSortState] | [],
): InvoiceDetailSortInput {
  return {
    key: sort[0]?.id || InvoiceDetailSortKey.FRANCHISEE_NAME,
    direction: sort[0]?.desc
      ? SortDirectionEnum.DESCENDING
      : SortDirectionEnum.ASCENDING,
  };
}

function formatFilter(
  status: string,
  filter?: FilterType,
  search?: Option,
): InvoiceDetailFilterInput {
  const beforeDate =
    filter?.value.endDate.toISOString() ||
    defaultDateRangeOption.end.toISOString();
  const afterDate =
    filter?.value.startDate.toISOString() ||
    defaultDateRangeOption.start.toISOString();

  return {
    [`${status}Before`]: beforeDate,
    [`${status}After`]: afterDate,
    franchiseeId: search?.value as string | undefined,
  };
}
