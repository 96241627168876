import React from "react";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { Combobox } from "@jobber/components/Combobox";
import { useIntl } from "react-intl";
import { useStatusOptions } from "jobber/reviews/views/ReviewsPage/hooks/useStatusOptions";
import { messages } from "./messages";

export interface StatusFilterProps {
  selected: ComboboxOption[];
  setSelected: (selected: ComboboxOption[]) => void;
}

export function ReviewFilter({
  selected,
  setSelected,
}: StatusFilterProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { statusOptions } = useStatusOptions();

  return (
    <Combobox
      label={formatMessage(messages.reviews)}
      selected={selected}
      onSelect={setSelected}
      subjectNoun="status"
    >
      {statusOptions.map(option => (
        <Combobox.Option label={option.label} key={option.id} id={option.id} />
      ))}
    </Combobox>
  );
}
