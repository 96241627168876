import type { AuthorizeNetData } from "legacy/jobber/payments/providers/TokenProvider";
import type TokenProvider from "legacy/jobber/payments/providers/TokenProvider";

export class AuthorizeNetProvider implements TokenProvider<AuthorizeNetData> {
  // eslint-disable-next-line max-statements
  public async createToken() {
    const authorizeNetDataDescriptor = document.querySelector<HTMLInputElement>(
      "#authorizeNetDataDescriptor",
    );
    const authorizeNetDataValue = document.querySelector<HTMLInputElement>(
      "#authorizeNetDataValue",
    );
    const creditCardLastFour = document.querySelector<HTMLInputElement>(
      "#authorizeNetLastFour",
    );
    const firstName = document.querySelector<HTMLInputElement>(
      "#authorizeNetFirstName",
    );
    const lastName = document.querySelector<HTMLInputElement>(
      "#authorizeNetLastName",
    );
    const street1 = document.querySelector<HTMLInputElement>(
      "input[name='street1']",
    );
    const street2 = document.querySelector<HTMLInputElement>(
      "input[name='street2']",
    );
    const city = document.querySelector<HTMLInputElement>("input[name='city']");
    const province = document.querySelector<HTMLInputElement>(
      "input[name='province']",
    );
    const postalCode =
      document.querySelector<HTMLInputElement>("input[name='pc']");
    const country = document.querySelector<HTMLElement>(
      "select[autocomplete='billing country'] option:checked",
    );

    if (
      authorizeNetDataDescriptor &&
      authorizeNetDataDescriptor.value &&
      authorizeNetDataValue &&
      authorizeNetDataValue.value &&
      creditCardLastFour &&
      creditCardLastFour.value
    ) {
      return {
        authorize_net_data_descriptor: authorizeNetDataDescriptor.value,
        authorize_net_data_value: authorizeNetDataValue.value,
        credit_card_number: creditCardLastFour.value,
        name_on_card: [firstName?.value, lastName?.value].join(" ").trim(),
        billing_address1: street1?.value,
        billing_address2: street2?.value,
        billing_city: city?.value,
        billing_state: province?.value,
        billing_country: country?.innerText,
        billing_zip: postalCode?.value,
      };
    }

    throw new Error("Please add card details.");
  }
}
