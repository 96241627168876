import React from "react";
import { Text } from "@jobber/components/Text";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import styles from "./ContactJobber.module.css";

export function ContactJobber() {
  const [t] = useTranslation();
  return (
    <>
      <Text>
        <strong>{t("contactInfoTitle")}</strong>
      </Text>
      <div className={styles.contactJobberContainer}>
        <Text size="small">{t("contactInfoJobberSuite")}</Text>
        <Text size="small">{t("contactInfoJobberAddress")}</Text>
        <Text size="small">{t("contactInfoJobberEmail")}</Text>
        <Text size="small">{t("contactInfoJobberPhoneNumber")}</Text>
      </div>
      <div className={styles.contactJobberContainer}>
        <Text size="small">
          <strong>{t("debitIdentificationTitle")}</strong>
        </Text>
        <Text size="small">{t("debitIdentificationSun")}</Text>
      </div>
    </>
  );
}
