import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { CompanyDetailsBanner } from "./CompanyDetailsBanner";

export interface CompanyDetails {
  name: string;
  address?: string;
  phone?: string;
  websiteUrl?: string;
}
export interface IntegrationEnabledCardProps {
  enabled: boolean;
  companyDetails: CompanyDetails;
  onChange(enabled: boolean): Promise<void>;
}

export function IntegrationEnabledCard({
  enabled,
  companyDetails,
  onChange,
}: IntegrationEnabledCardProps) {
  const onSwitch = async (newValue: boolean) => {
    return onChange(newValue);
  };

  return (
    <>
      <CompanyDetailsBanner {...companyDetails} />
      <Card title="Step 5: Enable Booking">
        <Content>
          <FeatureSwitch
            enabled={enabled}
            title={"Activate Google's Local Services Ads"}
            description="Allow customers to start booking directly from your Local Services Ads. You will not receive any bookings until you have completed your [Local Services Ads account setup](https://ads.google.com/local-services-ads/) with Google."
            externalLink={true}
            hasSaveIndicator={true}
            onSwitch={onSwitch}
          />
        </Content>
      </Card>
    </>
  );
}
