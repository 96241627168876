import React from "react";
import { RegistrationModalExistingNumber } from "jobber/settings/dedicatedPhoneNumber/RegistrationModalExistingNumber";
import { APIProvider } from "~/utilities/API/APIProvider";

export function StandaloneRegistrationModalExistingNumber() {
  return (
    <APIProvider>
      <RegistrationModalExistingNumber />
    </APIProvider>
  );
}
