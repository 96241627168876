import { defineMessages } from "react-intl";

/* CONTENT */
/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  moreInfoButtonDefaultMessage: {
    id: "reporting.infoPopover.moreInfoButtonDefaultMessage",
    defaultMessage: "More Information",
    description: "Default button message",
  },
  learnMoreButtonLabel: {
    id: "reporting.infoPopover.learnMoreButtonLabel",
    defaultMessage: "Learn More",
    description: "Learn more button label",
  },
});
