import { gql } from "@apollo/client";

const PLAID_ACH_PAYMENT_MUTATION = gql`
  mutation SubmitACHPayment(
    $tipAmount: String
    $attachedToId: EncodedId!
    $attachedToType: String!
    $plaidLinkPublicKeyToken: String!
    $plaidAccountId: String!
    $approvalSignatureData: String
  ) {
    achPayment(
      tipAmount: $tipAmount
      attachedToId: $attachedToId
      attachedToType: $attachedToType
      plaidLinkPublicKeyToken: $plaidLinkPublicKeyToken
      plaidAccountId: $plaidAccountId
      approvalSignatureData: $approvalSignatureData
    ) {
      success
      errors {
        message
      }
    }
  }
`;

export { PLAID_ACH_PAYMENT_MUTATION };
