import React, { type Dispatch, type SetStateAction, useContext } from "react";
import { Grid } from "@jobber/components/Grid";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { HiddenRecurlyFormInputs } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/HiddenRecurlyFormInputs";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import styles from "./BillingAddressPreview.module.css";
import { messages } from "./messages";

interface BillingAddressPreviewProps {
  billingAddress: BillingAddressType;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}

export function BillingAddressPreview(props: BillingAddressPreviewProps) {
  const { billingAddress, onClickAction } = props;
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  const { email } = billingAddress;

  return (
    <Content>
      <Grid>
        <Grid.Cell size={{ xs: 8, sm: 8, md: 9, lg: 9, xl: 9 }}>
          <div className={styles.emailRow}>
            <Text>{email}</Text>
          </div>
          <Address {...billingAddress} />
        </Grid.Cell>
        <Grid.Cell size={{ xs: 4, sm: 4, md: 3, lg: 3, xl: 3 }}>
          <div className={styles.buttonContainer}>
            <Button
              type="tertiary"
              label={formatMessage(messages.editBillingButton)}
              size="small"
              disabled={submitting}
              onClick={() => onClickAction(false)}
            />
          </div>
        </Grid.Cell>
      </Grid>
      <HiddenRecurlyFormInputs {...billingAddress} />
    </Content>
  );
}

function Address({
  address1,
  address2,
  city,
  state,
  zip,
  country,
}: Partial<BillingAddressType>) {
  const cityStateZip = [city, state, zip]
    .filter(component => !!component)
    .join(", ");

  return (
    <div className={styles.addressRow}>
      <Text>{address1}</Text>
      {address2 && <Text>{address2}</Text>}
      {/* eslint-disable formatjs/no-literal-string-in-jsx */}
      <Text>{`${cityStateZip} ${country}`}</Text>
    </div>
  );
}
