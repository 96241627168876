import { useEffect } from "react";

declare global {
  interface Window {
    bridgeMethods: Map<string, BridgeMethod>;
  }
}

type BridgeMethod = (...args: unknown[]) => void;

window.bridgeMethods ??= new Map<string, BridgeMethod>();
const { bridgeMethods } = window;

/**
 * Expose a method from react to our legacy code.
 *
 * **Setting up a bridge.**
 * ```tsx
 * export function Foo() {
 *   const [message, setMessage] = useState("");
 *
 *   useCreateBridgeMethod("updateFooMessage", (newFoo: string) => {
 *     setMessage(newFoo);
 *   });
 *
 *   return <>{ message }</>;
 * }
 * ```
 *
 * **Calling a bridge from TypeScript.**
 * ```ts
 * import { Bridges } from "jobber/bridges/Bridges";
 * Bridges.callBridgeMethod("updateFooMessage", "Hello World!");
 * ```
 *
 * **Calling a bridge from Legacy Code.**
 * ```ts
 * window.Bridges.callBridgeMethod("updateFooMessage", "Hello World!");
 * ```
 *
 * @param name - Name of the method to expose over the bridge.
 * @param method - Method to expose over the bridge.
 */
export function useCreateBridgeMethod(name: string, method: BridgeMethod) {
  useEffect(() => {
    bridgeMethods.set(name, method);

    return () => {
      bridgeMethods.delete(name);
    };
  }, [method, name]);
}

/**
 * Call a method that has been exposed with the `useCreateBridgeMethod` hook.
 *
 * eg: `Bridges.callBridgeMethod("updateFooMessage", "Hello World!");`
 * @param name - Name of the bridged method.
 * @param args - Args for the bridged method.
 */
export function callBridgeMethod(name: string, ...args: unknown[]) {
  const method = bridgeMethods.get(name);

  if (method != undefined) {
    method(...args);
  } else {
    throw new Error(`Method \`${name}\` could not be found.`);
  }
}
