import React from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import styles from "./IndustryLegitimacy.module.css";
import { industryStats } from "../../Stats";
import { AnimatedStep } from "../AnimatedStep";
import { industryName } from "../../utils";

interface IndustryLegitimacyProps {
  industry: string;
  companyName: string;
  jobberIconSrc: string;
  onFinishedAnimation(): void;
}

export function IndustryLegitimacy({
  industry,
  companyName,
  jobberIconSrc,
  onFinishedAnimation,
}: IndustryLegitimacyProps) {
  function getLegitimacyCopy() {
    const numInIndustry = industryStats[industry];
    if (industry === "Other" || numInIndustry <= 2000) {
      return (
        <>
          Jobber is trusted by over{" "}
          <Emphasis variation="bold">200,000</Emphasis> service professionals
        </>
      );
    } else if (numInIndustry > 2000 && numInIndustry <= 10000) {
      return (
        <>
          Jobber is trusted by <Emphasis variation="bold">thousands</Emphasis>{" "}
          of {industryName(industry)} service professionals
        </>
      );
    }

    return (
      <>
        Jobber is trusted by{" "}
        <Emphasis variation="bold">tens of thousands</Emphasis> of{" "}
        {industryName(industry)} service professionals
      </>
    );
  }

  const slideInContent = (
    <>
      <img
        data-testid="jobberIcon"
        className={`${styles.jobberIcon} slideInElement`}
        src={jobberIconSrc}
        alt="Jobber Icon"
      />
      <div className={"slideInElement"}>{getLegitimacyCopy()}</div>
    </>
  );

  const headerContent = (
    <Heading level={3}>
      <div className={styles.settingUp}>
        <span>Setting up&nbsp;</span>
        <Emphasis variation="highlight">
          {companyName.slice(0, 130)}...
        </Emphasis>
      </div>
    </Heading>
  );

  return (
    <AnimatedStep
      slideInContent={slideInContent}
      headerContent={headerContent}
      onFinishedAnimation={onFinishedAnimation}
      totalAnimationTime={2600}
    />
  );
}
