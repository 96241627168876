import { defineMessages } from "react-intl";

export const messages = defineMessages({
  saveMessage: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.saveMessage",
    defaultMessage: "Updated Google Analytics tracking code",
    description:
      "Toast message for successfully saving the Google Analytics tracking code",
  },
  bannerError: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.bannerError",
    defaultMessage: "Google Analytics tracking code is not valid",
    description:
      "Banner error message for failing to save the Google Analytics tracking code",
  },
  configurationLabel: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.configurationLabel",
    defaultMessage: "Google Analytics tracking",
    description: "Label for the configuration option",
  },
  configurationDescription: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.configurationDescription",
    defaultMessage:
      "Enable Google Analytics for online booking by adding your GA4 tracking code. {helpCentreLink}",
    description: "Description for the configuration option",
  },
  learnMore: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.learnMore",
    defaultMessage: "Learn more",
    description: "Link to learn more about Google Analytics",
  },
  configurationPresent: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.configurationPresent",
    defaultMessage: "Tracking code: {gaTag}",
    description: "Text shown when a Google Analytics tag is set",
  },
  configurationEmpty: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.configurationEmpty",
    defaultMessage: "Not added",
    description: "Text shown when no Google Analytics tag is set",
  },
  modalTitle: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.modalTitle",
    defaultMessage: "Add Google Analytics Tracking Code",
    description: "Title of the edit modal",
  },
  modalSave: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.modalSave",
    defaultMessage: "Save",
    description: "Save button text",
  },
  modalCancel: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.modalCancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  inputTextPlaceholder: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.inputTextPlaceholder",
    defaultMessage: "Tracking code",
    description:
      "Placeholder text for the input on the Terms and Conditions modal",
  },
  inputTextDescription: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.inputTextDescription",
    defaultMessage:
      "Google Analytics Tracking uses cookies. Ensure you have all required rights and consent for your region. Data takes up to 48 hours to appear in your GA account.",
    description: "Text shown below the input on the Terms and Conditions modal",
  },
  invalidGaTagMessage: {
    id: "selfServeBookings.GoogleAnalyticsConfiguration.invalidGaTagMessage",
    defaultMessage:
      "Invalid tracking code. Only Google Analytics 4 with a G-XXXXXXXXX format is supported.",
    description: "Message displayed when the tracking code is invalid",
  },
});
