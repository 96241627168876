/* eslint-disable import/no-internal-modules */
import { parseISO } from "date-fns";
import { useMemo } from "react";
import { AVAILABLE_SLOTS_STYLES } from "./useSchedulingAssistant";
// eslint-disable-next-line import/no-internal-modules
import {
  type YearMonthDay,
  toYearMonthDay,
  yearMonthDayToString,
  yearMonthDaysEqual,
} from "../yearMonthDay";
import type { AvailabilityCache } from "../utils/availabilityCache";

export type AvailabilityEvent = {
  title: string;
  start: string;
  end: string;
  resourceIds: string[];
  assignedEmployee: string;
  driveTimeTo: number | undefined;
  driveTimeFrom: number | undefined;
} & typeof AVAILABLE_SLOTS_STYLES;

export function useSchedulingAvailability(
  selectedDay: YearMonthDay,
  availabilityCache: AvailabilityCache,
  driveTimeLimitInSeconds: number,
  selectedTeamMembers: Map<string, boolean>,
) {
  const selectedDayAvailability = useMemo((): AvailabilityEvent[] => {
    const availability = Object.values(availabilityCache)
      .filter(availabilitySlots => availabilitySlots !== undefined)
      .flatMap(availabilitySlots => availabilitySlots!);

    return availability
      .filter(event => {
        const start = toYearMonthDay(parseISO(event.recommendedStartAt));
        const startsOnSelectedDay = yearMonthDaysEqual(start, selectedDay);
        return startsOnSelectedDay;
      })
      .map((slot, index) => ({
        title: `available-slot-${index}`,
        start: slot.recommendedStartAt,
        end: slot.recommendedEndAt,
        resourceIds: [slot.recommendedAssignee!.id],
        assignedEmployee: slot.recommendedAssignee!.id,
        isAvailabilitySlot: true,
        driveTimeTo: slot.driveTimeTo != null ? slot.driveTimeTo : undefined,
        driveTimeFrom:
          slot.driveTimeFrom != null ? slot.driveTimeFrom : undefined,
        ...AVAILABLE_SLOTS_STYLES,
      }))
      .filter(isWithinDriveTimeLimit(driveTimeLimitInSeconds));
  }, [availabilityCache, driveTimeLimitInSeconds, selectedDay]);

  const datesWithAvailability = useMemo(() => {
    return [
      ...new Set(
        Object.values(availabilityCache).flatMap(availabilitySlots =>
          availabilitySlots!
            .filter(
              recommendation =>
                recommendation.recommendedAssignee == null ||
                selectedTeamMembers.get(
                  recommendation.recommendedAssignee.id,
                ) != false,
            )
            .filter(isWithinDriveTimeLimit(driveTimeLimitInSeconds))
            .map(recommendation =>
              parseISO(
                yearMonthDayToString(
                  toYearMonthDay(parseISO(recommendation.recommendedStartAt)),
                ),
              ),
            ),
        ),
      ),
    ];
  }, [availabilityCache, driveTimeLimitInSeconds, selectedTeamMembers]);

  return { selectedDayAvailability, datesWithAvailability };
}

interface WithDriveTime {
  driveTimeTo?: number;
  driveTimeFrom?: number;
}

function isWithinDriveTimeLimit(driveTimeLimitInSeconds: number) {
  return <T extends WithDriveTime>(slot: T) =>
    (slot.driveTimeTo == null ||
      slot.driveTimeTo * 60 <= driveTimeLimitInSeconds) &&
    (slot.driveTimeFrom == null ||
      slot.driveTimeFrom * 60 <= driveTimeLimitInSeconds);
}
