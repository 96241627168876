import React from "react";
import { IntegrationCard } from "jobber/marketplace/components";
import type { CardType } from "../IntegrationList";
import type { Integration } from "../IntegrationListProps";

export function IntegrationCardList({
  apps,
  featured,
  highlightedApp,
  type,
  publicFacing,
}: {
  apps: Integration[];
  featured?: boolean;
  highlightedApp?: string;
  type: CardType;
  publicFacing: boolean;
}) {
  return (
    <>
      {apps.map(app => (
        <IntegrationCard
          key={app.id}
          featured={featured}
          id={app.id}
          displayName={app.displayName}
          title={app.title}
          description={app.description}
          logoUrl={
            featured ? app.featuredImage || app.logoIconUrl : app.logoIconUrl
          }
          detailsUrl={app.link}
          appType={type}
          logoBackgroundColor={featured ? app.featuredBgColor : undefined}
          appState={app.appState}
          highlighted={app.id === highlightedApp}
          isConnected={app.isConnected}
          recentlyPublished={app.recentlyPublished}
          publicFacing={publicFacing}
          hasOffer={!!app.offer}
        />
      ))}
    </>
  );
}
