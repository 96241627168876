import type { Dispatch, SetStateAction } from "react";
import type { SetupStepProps } from "jobber/dashboard/components/SetupGuide/SetupGuide";
import { buildListItem } from "jobber/dashboard/components/SetupGuide/utils/buildListItem";
import type { SetupGuideStep } from "~/utilities/API/graphql";
import { buildStartGrowTrialListItem } from "./buildStartGrowTrialListItem";
import type { SetupGuideEditResponseType } from "../types";

export function convertDataToSteps(
  steps: SetupGuideStep[],
  data: { userName: string },
  firstLoad: boolean,
  setCurrentStepIndex: Dispatch<SetStateAction<number>>,
  handleSetupGuideEdit: SetupGuideEditResponseType,
  setFirstLoad: Dispatch<SetStateAction<boolean>>,
): SetupStepProps[] {
  const transformedSteps: SetupStepProps[] = steps.map((step, index) => {
    const setupGuideStep: SetupStepProps =
      index === 0
        ? buildStartGrowTrialListItem(
            step,
            steps,
            index,
            data,
            firstLoad,
            setCurrentStepIndex,
            handleSetupGuideEdit,
            setFirstLoad,
          )
        : buildListItem(step, index);

    return setupGuideStep;
  });

  return transformedSteps;
}
