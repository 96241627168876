import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { BankAccount, type BankAccountProps } from "./BankAccount";
import type { SuccessBank } from "./bankAccountReducer";
import { messages } from "./messages";

export interface BankDetailsProps {
  bank?: SuccessBank;
  bankAccountProps: BankAccountProps;
  payoutsErrorMessage?: string;
  receivedFirstPayout: boolean;
  manualPayoutsIsEnabled: boolean;
  payoutDelayDaysType: "business" | "calendar";
}

enum payoutScheduleState {
  PAYOUTS_UNAVAILABLE = "PAYOUTS_UNAVAILABLE",
  TEMPORARY_DELAY = "TEMPORARY_DELAY",
  HOURLY_PAYOUTS = "HOURLY_PAYOUTS",
  STANDARD_PAYOUTS = "STANDARD_PAYOUTS",
}

export function BankDetails(props: BankDetailsProps) {
  const {
    bank,
    bankAccountProps,
    payoutsErrorMessage,
    receivedFirstPayout,
    manualPayoutsIsEnabled,
    payoutDelayDaysType,
  } = props;
  const { payoutScheduleInDays } = useJobberPayments();
  const [learnMoreUrl] = useUrls("docsPayoutsUrl");
  const { formatMessage } = useIntl();

  const headingCopy =
    bank?.payoutsToJobberMoney && !manualPayoutsIsEnabled
      ? formatMessage(messages.hourlyPayoutScheduleTitle)
      : formatMessage(messages.standardPayoutScheduleTitle);

  function getPayoutScheduleState() {
    if (manualPayoutsIsEnabled) {
      return payoutScheduleState.PAYOUTS_UNAVAILABLE;
    } else if (!receivedFirstPayout) {
      return payoutScheduleState.TEMPORARY_DELAY;
    } else if (bank?.payoutsToJobberMoney) {
      return payoutScheduleState.HOURLY_PAYOUTS;
    } else {
      return payoutScheduleState.STANDARD_PAYOUTS;
    }
  }

  function getPayoutCopyText() {
    const currentPayoutScheduleState = getPayoutScheduleState();
    switch (currentPayoutScheduleState) {
      case payoutScheduleState.PAYOUTS_UNAVAILABLE:
        return formatMessage(messages.payoutsUnavailableDescription);

      case payoutScheduleState.TEMPORARY_DELAY:
        return formatMessage(messages.temporaryPayoutsDelayDescription, {
          payoutScheduleDelayDays: payoutScheduleInDays,
          delayDayType: payoutDelayDaysType,
        });

      case payoutScheduleState.HOURLY_PAYOUTS:
        return formatMessage(messages.hourlyPayoutsDescription);

      case payoutScheduleState.STANDARD_PAYOUTS:
        return formatMessage(messages.standardPayoutsDescription, {
          payoutScheduleDelayDays: payoutScheduleInDays,
          delayDayType: payoutDelayDaysType,
        });
    }
  }

  return (
    <Content>
      <Heading level={3}>{headingCopy}</Heading>
      {payoutsErrorMessage && (
        <Banner type="warning" dismissible={false}>
          <Text>{payoutsErrorMessage}</Text>
        </Banner>
      )}
      <Content spacing="smaller">
        <Text variation="subdued">{getPayoutCopyText()}</Text>
        <Text variation="subdued">
          <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
            {formatMessage(messages.learnMore)}
          </a>
        </Text>
      </Content>
      <Content>
        <section>
          {bank && bank.bankName && <Text>{bank.bankName}</Text>}
          {bank && bank.bankLast4 && (
            <Text>
              {formatMessage(messages.accountEndingDigits, {
                bankLast4: bank.bankLast4,
              })}
            </Text>
          )}
        </section>
        <ChangeBankAccount
          bankAccountProps={bankAccountProps}
          payoutsToJobberMoney={!!bank?.payoutsToJobberMoney}
        />
      </Content>
    </Content>
  );
}

function ChangeBankAccount(props: {
  payoutsToJobberMoney: boolean;
  bankAccountProps: BankAccountProps;
}) {
  const { payoutsToJobberMoney, bankAccountProps } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { permissions, enabled: jobberPaymentsEnabled } = useJobberPayments();
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const shouldShow = permissions.canChangeBankAccount;
  const shouldDisable = !jobberPaymentsEnabled;

  useEffect(() => {
    if (
      window.location.pathname === "/jobber_payments/oauth" &&
      window.location.search.includes("oauth_state_id")
    ) {
      openDialog();
    }
  }, []);

  return (
    <>
      {shouldShow && (
        <Button
          label="CHANGE BANK ACCOUNT"
          disabled={shouldDisable}
          onClick={onChangeBankClick}
          type="tertiary"
          fullWidth={true}
        />
      )}
      <Modal
        open={isDialogOpen}
        onRequestClose={closeDialog}
        title="CHANGE BANK ACCOUNT"
      >
        <BankAccount
          {...bankAccountProps}
          payoutsToJobberMoney={payoutsToJobberMoney}
          embedded={true}
          completedCallback={onComplete}
        />
      </Modal>
    </>
  );

  function onChangeBankClick() {
    openDialog();
  }

  function onComplete(bank?: SuccessBank) {
    closeDialog();
    bankAccountProps.completedCallback(bank);
  }
}
