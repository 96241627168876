export const cardOptions: CardOptions = {
  style: {
    ".input-container": {
      borderRadius: "0px",
    },
    ".input-container.is-focus": {
      borderColor: "#dcc30f",
      borderWidth: "1px",
    },
  },
};
