import React from "react";
import type { AiAssistantInsightWidgetCategory } from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { InsightWidget, InsightWidgetLoading } from "./InsightWidget";
import styles from "./InsightWidget.module.css";
import { useRandomizedInsightWidget } from "./hooks/useRandomizedInsightWidget";

interface InsightWidgetsLoaderProps {
  categories: AiAssistantInsightWidgetCategory[];
  insideKPISection?: boolean;
  insideReviewsEngagement?: boolean;
  nextComponent?: React.ReactNode;
}

const InsightWidgetsLoaderInternal = (props: InsightWidgetsLoaderProps) => {
  const { insightWidget, loading } = useRandomizedInsightWidget(
    props.categories,
  );

  if (loading) {
    return <InsightWidgetLoading />;
  } else if (insightWidget) {
    return <InsightWidget {...insightWidget} />;
  } else {
    return <>{props.nextComponent}</>;
  }
};

export const InsightWidgetsLoader = (props: InsightWidgetsLoaderProps) => {
  const InsightWidgetWrapper = renderWrapper(props);

  return (
    <InsightWidgetWrapper>
      <InsightWidgetsLoaderInternal {...props} />
    </InsightWidgetWrapper>
  );
};

const WrapperForKPISection = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.insideKPISection}>{children}</div>
);

const WrapperForReviewsEngagement = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className={styles.insideReviewsEngagement}>{children}</div>;

const renderWrapper = (props: InsightWidgetsLoaderProps) => {
  if (props.insideReviewsEngagement) {
    return WrapperForReviewsEngagement;
  }
  if (props.insideKPISection) return WrapperForKPISection;
  return APIProvider;
};
