import React from "react";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import { messages } from "./messages";

interface RedirectToPricingPageButtonProps {
  addonSetName?: string;
  isMarketingSource?: boolean;
}

function InternalRedirectToPricingPageButton(
  props: RedirectToPricingPageButtonProps,
) {
  const { formatMessage } = useIntl();

  const { getTreatmentValue } = useSplit({
    names: [SplitNames.AutoAddMarketingTools],
  });
  const autoAddMarketingToolsEnabled = getTreatmentValue(
    SplitNames.AutoAddMarketingTools,
  );

  return (
    <Content>
      <Button
        label={formatMessage(messages.inTrialButton)}
        ariaLabel={formatMessage(messages.inTrialButton)}
        size={"base"}
        type={"primary"}
        onClick={() =>
          navigateToPricing(
            props.isMarketingSource && autoAddMarketingToolsEnabled,
          )
        }
      />
      {props.addonSetName && (
        <Text variation={"info"}>
          <Emphasis variation={"italic"}>
            {formatMessage(messages.inTrialFooter, {
              addonSetName: props.addonSetName,
            })}
          </Emphasis>
        </Text>
      )}
    </Content>
  );
}

function navigateToPricing(autoAddMarketingToolsEnabled?: boolean) {
  const pathname = generateRedirectUrl();
  window.location.assign(
    `/accounts/billing_info/pricing${autoAddMarketingToolsEnabled ? `?source=${pathname}` : ""}`,
  );
}

function generateRedirectUrl() {
  const path = window.location.pathname;
  let pathname;
  if (path?.includes("/reviews")) pathname = "reviews";
  if (path?.includes("/marketing/referrals")) pathname = "referrals";
  if (path?.includes("/campaigns_landing_page")) pathname = "campaigns";

  return pathname;
}

export const RedirectToPricingPageButton = withSplitClient(
  InternalRedirectToPricingPageButton,
);
