import { useMutation } from "@apollo/client";
import { useMemo, useReducer } from "react";
import { GLOBAL_OWNERSHIP_QUERY } from "jobber/features/Settings/views/CommsSettings/hooks/useOwnershipData";
import {
  WorkObject,
  type WorkObjectGlobalOwnershipFragment,
  WorkObjectOwnership,
} from "~/utilities/API/graphql";
import { COMMS_SETTINGS_PAGE_QUERY } from "jobber/features/Settings/views/CommsSettings/CommsSettings.graphql";
import { WORK_OBJECT_GLOBAL_OWNERSHIP_MUTATION } from "./WorkObjectGlobalOwnership.graphql";

interface OwnershipOption {
  userId?: string;
  label: string;
  ownershipType: WorkObjectOwnership;
}

export interface OwnershipRecord {
  values: Ownership[];
  options: OwnershipOption[];
}

type OwnershipState = {
  [k in WorkObject]: OwnershipRecord;
};

interface OwnershipAction {
  type: WorkObject;
  values: Ownership[];
}

export interface Ownership {
  ownershipType?: WorkObjectOwnership; // USER or SENDER or COMPANY
  name: string;
  userId?: string;
}

const senderOwnership: Ownership = {
  ownershipType: WorkObjectOwnership.SENDER,
  name: "Sender of email",
  userId: undefined,
};

const defaultState: OwnershipState = {
  INVOICE: { values: [senderOwnership], options: [] },
  JOB: { values: [senderOwnership], options: [] },
  QUOTE: { values: [senderOwnership], options: [] },
  REQUEST: { values: [senderOwnership], options: [] },
};

export function useGlobalOwnership(
  globalOwnershipRecords: WorkObjectGlobalOwnershipFragment[],
) {
  const initialValues = useMemo(() => {
    return globalOwnershipRecords.reduce<OwnershipState>((newState, record) => {
      newState[record.workObjectType] = {
        values: getWorkObjectOwnershipValues(record),
        options: getOptions(record.possibleReplyToOwners.nodes),
      };
      return newState;
    }, defaultState);
  }, [globalOwnershipRecords]);

  function ownershipReducer(
    prevState: OwnershipState,
    action: OwnershipAction,
  ) {
    return {
      ...prevState,
      [action.type]: {
        ...prevState[action.type],
        values: action.values,
        isUpdated: initialValues[action.type].values !== action.values,
      },
    };
  }

  const [updateOwnerships, { loading, error }] = useMutation(
    WORK_OBJECT_GLOBAL_OWNERSHIP_MUTATION,
    {
      refetchQueries: [
        {
          query: GLOBAL_OWNERSHIP_QUERY,
        },
        {
          query: COMMS_SETTINGS_PAGE_QUERY,
        },
      ],
    },
  );

  async function save() {
    return updateOwnerships({
      variables: {
        ownerships: getUpdatedOwnerships(),
      },
    });
  }

  const [state, dispatch] = useReducer(ownershipReducer, initialValues);

  return {
    request: state[WorkObject.REQUEST],
    quote: state[WorkObject.QUOTE],
    job: state[WorkObject.JOB],
    invoice: state[WorkObject.INVOICE],
    dispatch,
    save,
    loading,
    error,
  };

  function getOptions(
    users: WorkObjectGlobalOwnershipFragment["possibleReplyToOwners"]["nodes"],
  ): OwnershipOption[] {
    const possibleReplyToOwners = [
      ...users.map(user => ({
        userId: user.user?.id,
        label:
          user.ownershipType === WorkObjectOwnership.SENDER
            ? `${user.name}`
            : `${user.name} (${user.email?.raw})`,
        ownershipType: user.ownershipType,
      })),
    ];
    return [...possibleReplyToOwners] as OwnershipOption[];
  }

  function getUpdatedOwnerships() {
    return {
      requests: compareCurrentState(WorkObject.REQUEST)
        ? getOwnershipUpdate(state.REQUEST)
        : undefined,
      quotes: compareCurrentState(WorkObject.QUOTE)
        ? getOwnershipUpdate(state.QUOTE)
        : undefined,
      jobs: compareCurrentState(WorkObject.JOB)
        ? getOwnershipUpdate(state.JOB)
        : undefined,
      invoices: compareCurrentState(WorkObject.INVOICE)
        ? getOwnershipUpdate(state.INVOICE)
        : undefined,
    };

    function compareCurrentState(workObject: WorkObject) {
      return state[workObject].values !== initialValues[workObject].values;
    }

    function getOwnershipUpdate(records: OwnershipRecord) {
      return records.values.map(record => ({
        ownershipType: record.ownershipType,
        userId: record.userId,
      }));
    }
  }

  function getWorkObjectOwnershipValues(
    ownerships: WorkObjectGlobalOwnershipFragment,
  ): Ownership[] {
    return ownerships.replyToOwners.nodes.map(ownership => ({
      userId: ownership.user?.id,
      ownershipType: ownership.ownershipType,
      name:
        ownership.ownershipType === WorkObjectOwnership.SENDER
          ? ownership.name
          : `${ownership.name} (${ownership.email?.raw})`,
    })) as Ownership[];
  }
}
