import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recipientSummary: {
    id: "campaigns.campaignReviewPage.recipientSummary",
    defaultMessage:
      "This email will send to the <b>{segmentName}</b> segment, with <b>{consentingClientsCount}</b> of {totalCount} subscribed to email marketing.",
    description: "Recipient summary",
  },
});
