import { Content } from "@jobber/components/Content";
import React, { useReducer } from "react";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import { useIntl } from "react-intl";
import {
  useAccountingCodesQuery,
  useActiveUsersQuery,
  useExpenseMutation,
} from "jobber/workOrders/components/JobCost/hooks";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { getUTCFromAccountZonedTime } from "jobber/workOrders/components/JobCost/utils";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import type { User } from "~/utilities/API/graphql";
import styles from "./ExpenseModal.module.css";
import {
  ExpenseModalReducer,
  initialExpenseModalState,
} from "./ExpenseModalReducer";
import { ExpenseModalContent } from "./ExpenseModalContent";
import { messages } from "./messages";

interface CreateExpenseModalProps {
  jobId: string;
  closeModal(): void;
  currentUser: User;
}

export function CreateExpenseModal({
  jobId,
  closeModal,
  currentUser,
}: CreateExpenseModalProps) {
  const { formatMessage } = useIntl();
  const account = useAccount();

  const { canViewJobCosts, canViewExpenses, canViewTimeSheetEntries } =
    usePermissions();

  const [state, dispatch] = useReducer(
    ExpenseModalReducer,
    initialExpenseModalState,
  );

  const {
    itemName,
    accountingCode,
    description,
    expenseDate,
    total,
    receiptUrl,
    reimburseTo,
  } = state;

  const { handleSubmit, loading } = useExpenseMutation(
    { jobId },
    canViewJobCosts,
    canViewExpenses,
    canViewTimeSheetEntries,
    closeModal,
  );

  const { accountingCodes } = useAccountingCodesQuery();
  const { users } = useActiveUsersQuery(canViewJobCosts);

  return (
    <Content>
      <Form onSubmit={handleFormSubmit}>
        <ExpenseModalContent
          accountingCodes={accountingCodes}
          users={users}
          currencySymbol={account.currencySymbol}
          state={state}
          dispatch={dispatch}
          currentUser={currentUser}
        />
        <div className={styles.expenseModalButtonGroup}>
          <div className={styles.rightButtons}>
            <Button
              label={formatMessage(jobCostMessages.cancel)}
              variation="subtle"
              onClick={closeModal}
            />
            <Button
              label={formatMessage(messages.saveExpense)}
              submit
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </Content>
  );

  function handleFormSubmit() {
    const input = {
      title: itemName,
      accountingCodeId: !accountingCode ? undefined : accountingCode,
      reimbursableToId: !reimburseTo ? undefined : reimburseTo,
      description,
      date: (
        getUTCFromAccountZonedTime(expenseDate, account.timezone) ||
        new Date(expenseDate)
      ).toISOString(),
      total,
      receiptUrl,
    };

    handleSubmit(input);
  }
}

function usePermissions() {
  const { can } = useAuthorization();
  const canViewJobCosts = can("view", "JobCosts");
  const canViewExpenses = can("view", "Expenses");
  const canViewTimeSheetEntries = can("view", "Timesheets");

  return { canViewJobCosts, canViewExpenses, canViewTimeSheetEntries };
}
