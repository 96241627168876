import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
// DO NOT COPY: Allowing an exception here for a legacy use of Typography.
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import styles from "./HelpDrawerButton.module.css";

export function HelpDrawerButton({
  label,
  icon,
  termsOfService,
  href,
  target,
  rel,
  onClick,
}: {
  label: string;
  icon?: IconNames;
  termsOfService?: boolean;
  href: string;
  target?: string;
  rel?: string;
  onClick?(): void;
}) {
  return (
    <a
      href={href}
      onClick={onClick}
      target={target}
      rel={rel}
      className={styles.helpDrawerButton}
    >
      {termsOfService ? (
        <span className="u-marginRightSmall">{label}</span>
      ) : (
        <>
          {icon && <Icon name={icon} color="interactiveSubtle" />}
          <span className="u-marginLeftSmaller">
            <Typography
              element="span"
              fontWeight="bold"
              size="base"
              textColor="text"
            >
              {label}
            </Typography>
          </span>
        </>
      )}
    </a>
  );
}
