import { type ApolloError, useQuery } from "@apollo/client";
import {
  type DefaultEmailQueryWithOwnershipQuery,
  type DefaultEmailQueryWithOwnershipQueryVariables,
  type OwnerInfoFragmentFragment,
  WorkObject,
} from "~/utilities/API/graphql";
import { DEFAULT_EMAIL_QUERY_WITH_OWNERSHIP } from "jobber/email/EmailReplyToInput/component/EmailReplyToInformation.graphql";

export interface ReplyToInformation {
  replyToOptions: OwnerInfoFragmentFragment[];
  defaultReplyTo: OwnerInfoFragmentFragment | undefined;
}

interface DefaultEmailResult {
  defaultEmailData: DefaultEmailQueryWithOwnershipQuery | undefined;
  defaultEmailError: ApolloError | undefined;
  defaultEmailLoading: boolean;
  replyToInformation: ReplyToInformation | undefined;
}

export function useReplyToInformation(
  clientId: string,
  workflowType: string,
  workflowId: string,
): DefaultEmailResult {
  let fetchOwnerships = false;
  const workObjectOwnership = Object.keys(WorkObject).find(
    key => key === workflowType,
  );

  let workObjectType: WorkObject | undefined;
  if (workObjectOwnership) {
    workObjectType = WorkObject[workObjectOwnership as WorkObject];
    fetchOwnerships = true;
  }

  const {
    loading: defaultEmailLoading,
    error: defaultEmailError,
    data: defaultEmailData,
  } = useQuery<
    DefaultEmailQueryWithOwnershipQuery,
    DefaultEmailQueryWithOwnershipQueryVariables
  >(DEFAULT_EMAIL_QUERY_WITH_OWNERSHIP, {
    fetchPolicy: "network-only",
    variables: {
      workObjectType: workObjectType,
      workObjectId: workObjectType ? workflowId : undefined,
      fetchOwnerships,
      clientId: clientId,
    },
  });

  return {
    defaultEmailData,
    replyToInformation: buildReplyTo(defaultEmailData),
    defaultEmailError,
    defaultEmailLoading,
  };
}

function buildReplyTo(
  defaultEmailData?: DefaultEmailQueryWithOwnershipQuery,
): ReplyToInformation | undefined {
  const ownerships = defaultEmailData?.replyToOwnership?.nodes;
  if (ownerships && ownerships.length > 0) {
    const singleOwnership = ownerships[0];

    return {
      replyToOptions: singleOwnership.possibleOwners.nodes,
      defaultReplyTo: singleOwnership.owner,
    };
  }
}
