import React, { useCallback, useEffect, useRef, useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { parseISO } from "date-fns";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import { Button } from "@jobber/components/Button";
import type { FetchResult } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  ReviewReplyEditInput,
  ReviewReplyEditMutation,
} from "~/utilities/API/graphql";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { GenerateResponse } from "jobber/reviews/views/ReviewsPage/components/GenerateResponse/GenerateResponse";
import styles from "./ReplyCard.module.css";
import { messages } from "./messages";

interface ReplyCardProps {
  comment?: string;
  reviewId: string;
  date?: string;
  sendReviewReply: (
    options: ReviewReplyEditInput,
  ) => Promise<FetchResult<ReviewReplyEditMutation>>;
  setErrorMessage: (message?: string) => void;
  dismissErrorMessage: () => void;
  reviewComment?: string;
}

export const ReplyCard = React.memo(ReplyCardInternal);
function ReplyCardInternal({
  reviewId,
  comment,
  date,
  sendReviewReply,
  setErrorMessage,
  dismissErrorMessage,
  reviewComment,
}: ReplyCardProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    draftComment,
    setDraftComment,
    savedComment,
    setSavedComment,
    validComment,
    setValidComment,
  } = useCommentState(comment);
  const dateRef = useRef(date);
  const [generatedResponse, setGeneratedResponse] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (comment !== savedComment) {
      setSavedComment(comment);
    }
    // we only want to run this effect when the comment changes
    // (which can happen when the subscription returns with a new,
    // updated, or deleted comment
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  const onClickHandler = useCallback(() => {
    if (draftComment === undefined) {
      return;
    }
    setLoading(true);

    sendReviewReply({
      reviewId,
      comment: draftComment,
    })
      .then(result => {
        setLoading(false);

        if (result.data?.reviewReplyEdit.reviewReply?.comment !== undefined) {
          dateRef.current = result.data.reviewReplyEdit.reviewReply.updatedAt;
          setSavedComment(result.data.reviewReplyEdit.reviewReply.comment);
          showToast({ message: formatMessage(messages.replySent) });
        } else {
          setErrorMessage(formatMessage(messages.saveReplyError));
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(formatMessage(messages.saveReplyError));
      });

    sendEventForGeneratedSubmittedResponse(draftComment, generatedResponse);
  }, [
    draftComment,
    sendReviewReply,
    reviewId,
    generatedResponse,
    setSavedComment,
    formatMessage,
    setErrorMessage,
  ]);

  const onChangeHandler = useCallback(
    (inputText: string) => {
      dismissErrorMessage();
      setDraftComment(inputText);
    },
    [dismissErrorMessage, setDraftComment],
  );

  const onValidationHandler = useCallback(
    (message: string) => {
      setValidComment(message === "");
    },
    [setValidComment],
  );

  if (savedComment === undefined) {
    return (
      <div className={styles.inputTextContainer}>
        <MagicInputText
          multiline
          primaryButton={
            <div style={{ marginLeft: "auto" }}>
              <Button
                label={formatMessage(messages.send)}
                ariaLabel={"send"}
                onClick={onClickHandler}
                size={"small"}
                disabled={!validComment || loading}
                loading={loading}
              />
            </div>
          }
          renderEmptyStateButton={({
            setError,
            setText,
            setLoading: setGenerateLoading,
          }) => (
            <GenerateResponse
              reviewComment={reviewComment}
              setError={setError}
              setText={setText}
              setLoading={setGenerateLoading}
              onResponseGenerated={setGeneratedResponse}
            />
          )}
          name={reviewId}
          value={draftComment}
          placeholder={formatMessage(messages.replyTextInputPlaceholder)}
          onChange={onChangeHandler}
          rows={{ max: 5, min: 1 }}
          validations={{
            maxLength: {
              value: 1024,
              message: formatMessage(messages.replyMaxLength),
            },
          }}
          onValidation={onValidationHandler}
        />
      </div>
    );
  }

  return <ReviewReply comment={savedComment} date={dateRef.current} />;
}

function useCommentState(comment: string | undefined) {
  const [draftComment, setDraftComment] = useState<string | undefined>(
    undefined,
  );
  const [savedComment, setSavedComment] = useState<string | undefined>(comment);
  const [validComment, setValidComment] = useState<boolean>(true);

  return {
    draftComment,
    setDraftComment,
    savedComment,
    setSavedComment,
    validComment,
    setValidComment,
  };
}

function ReviewReply({
  comment,
  date,
}: {
  comment?: string;
  date?: string;
}): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      {comment !== undefined && (
        <div className={styles.container}>
          <div className={styles.row}>
            <Heading level={5}>{formatMessage(messages.title)}</Heading>
            <div className={styles.replyDate}>
              <Text variation="subdued" size="small">
                {date && <FormatRelativeDateTime date={parseISO(date)} />}
              </Text>
            </div>
          </div>
          <Text>{comment}</Text>
        </div>
      )}
    </>
  );
}

function sendEventForGeneratedSubmittedResponse(
  draftComment: string,
  generatedResponse: string | undefined,
) {
  if (generatedResponse === undefined) {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "review_response_submission",
    });
  } else if (generatedResponse === draftComment) {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "generated_response_submitted",
      result: "submitted_as_is",
    });
  } else {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "generated_response_submitted",
      result: "modified",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      generated_message: generatedResponse,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      submitted_message: draftComment,
    });
  }
}
