import React, { useState } from "react";
import { LightBox } from "@jobber/components/LightBox";
import {
  DefaultFullDimensions,
  DefaultThumbDimensions,
  fetchS3ThumbnailUrl,
} from "~/jobber/fileUploader/FileUploaderUtils";

export interface ThumbnailProps {
  name: string;
  s3Key: string;
  s3ThumbnailUrlPath?: string;
  thumbnail?: string;
  fullSizeImage?: string;
  setThumbnail(thumbnail: string): void;
}

export function Thumbnail(props: ThumbnailProps) {
  const [open, isOpen] = useState(false);
  const [fullSizeImage, setFullSizeImage] = useState(props.fullSizeImage || "");

  if (!props.thumbnail && props.s3ThumbnailUrlPath) {
    fetchS3ThumbnailUrl(
      props.s3Key,
      props.s3ThumbnailUrlPath,
      props.setThumbnail,
      DefaultThumbDimensions,
    );
  }

  // fetch full sized image for the lightbox
  if (!fullSizeImage && props.s3ThumbnailUrlPath) {
    fetchS3ThumbnailUrl(
      props.s3Key,
      props.s3ThumbnailUrlPath,
      setFullSizeImage,
      DefaultFullDimensions,
    );
  }

  let imgPlaceholder = <></>;
  if (props.thumbnail) {
    imgPlaceholder = (
      <button
        type="button"
        onClick={() => {
          isOpen(true);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <img
          className={"thumbnailPreview"}
          src={props.thumbnail}
          alt={props.name}
          style={{ maxHeight: "4.3755rem" }}
        />
      </button>
    );
  } else {
    imgPlaceholder = (
      <div
        className="spinner spinner--small"
        style={{
          minHeight: "4.375rem",
        }}
      />
    );
  }
  return (
    <div className="u-textCenter">
      <LightBox
        open={open}
        images={[
          {
            url: fullSizeImage,
          },
        ]}
        onRequestClose={() => isOpen(false)}
      />
      {imgPlaceholder}
    </div>
  );
}
