import { useContext } from "react";
import { TranslationContext, type Translations } from "./TranslationContext";

export function useTranslation() {
  const { translations } = useContext(TranslationContext);

  return [simpleTranslate];

  // todo replace with actual translation library
  function simpleTranslate(key: keyof Translations): string {
    const translated = translations[key];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      ensureExists(key, translated, translations);
    }

    return translated || key;
  }
}

function ensureExists(
  key: string,
  translated: string | undefined,
  translations: Record<string, string | undefined>,
) {
  if (!translated) {
    throw new Error(
      `missing translation key '${key}'; ensure TranslationContext.Provider or RailsPropsAsContexts exists in the parent tree, and the translation key exists in the 'translations' map. received keys: ${Object.keys(
        translations,
      )}`,
    );
  }
}
