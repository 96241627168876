export interface SettingsState {
  defaultDuration: number;
  centered: boolean;
}

type SettingsAction =
  | {
      type: "DEFAULT_DURATION";
      duration?: number;
    }
  | { type: "STYLE"; centered: boolean };

export function settingsReducer(
  state: SettingsState,
  action: SettingsAction,
): SettingsState {
  switch (action.type) {
    case "DEFAULT_DURATION":
      return {
        ...state,
        defaultDuration: action.duration ?? state.defaultDuration,
      };
    case "STYLE":
      return { ...state, centered: action.centered };
    default:
      return state;
  }
}
