import { render } from "mustache";
import React from "react";

interface MustachePreviewProps {
  readonly template: string;
  readonly values: object;
}

export function MustachePreview({ template, values }: MustachePreviewProps) {
  try {
    return (
      <span style={{ whiteSpace: "pre-wrap" }}>
        {render(template, values, undefined, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          escape: (text: any) => text,
        })}
      </span>
    );
  } catch {
    return <></>;
  }
}
