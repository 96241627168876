import React from "react";
import { Icon } from "@jobber/components/Icon";
import type { IconColorNames, IconNames } from "@jobber/design";
import { GlobalMessageTypeEnum } from "~/utilities/API/graphql";

interface GlobalBannerTypeIconProps {
  readonly type: GlobalMessageTypeEnum;
}

export function GlobalBannerTypeIcon({ type }: GlobalBannerTypeIconProps) {
  let color: IconColorNames;
  let iconName: IconNames = "alert";

  switch (type) {
    case GlobalMessageTypeEnum.ERROR:
      color = "critical";
      break;
    case GlobalMessageTypeEnum.WARNING:
      color = "warning";
      break;
    case GlobalMessageTypeEnum.NOTICE:
      color = "informative";
      iconName = "knot";
      break;
    default:
      color = "success";
      iconName = "checkmark";
  }

  return <Icon name={iconName} color={color} />;
}
