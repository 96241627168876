import { useEffect, useRef } from "react";
import type { LineItemsAction, LineItemsState } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import type { JobDetailsFragment } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";

const NO_CORRESPONDING_INDEX = -1;

export function useReconcileLineItems(
  state: LineItemsState,
  lineItems: LineItem[],
  dispatch: (action: LineItemsAction) => void,
  job?: JobDetailsFragment,
) {
  const paginatedLineItemCount = useRef(lineItems.length);

  useEffect(() => {
    if (lineItems.length !== paginatedLineItemCount.current) {
      const lineItemsWithPersistedChanges = reconcileChangedLineItems(
        state,
        lineItems,
      );
      dispatch({
        type: LineItemsBulkEditActionTypes.Reset,
        value: {
          lineItems: lineItemsWithPersistedChanges,
        },
      });

      paginatedLineItemCount.current = lineItems.length;
    }
  }, [paginatedLineItemCount, job?.id, lineItems, state, dispatch]);
}

export function reconcileChangedLineItems(
  state: LineItemsState,
  lineItems: LineItem[],
) {
  const reconciledLineItems = [...lineItems];

  state.lineItems.forEach(li => {
    const correspondingIndex = getCorrespondingIndex(lineItems, li);

    if (correspondingIndex === NO_CORRESPONDING_INDEX) {
      reconciledLineItems.push(li);
    }

    if (correspondingIndex !== NO_CORRESPONDING_INDEX) {
      reconciledLineItems[correspondingIndex] = li;
    }
  });

  return reconciledLineItems;
}

export function getCorrespondingIndex(
  lineItems: LineItem[],
  lineItem: LineItem,
) {
  return lineItems.findIndex(li => li.id === lineItem.id);
}
