import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { withSplitClient } from "utilities/split";
import styles from "./OnlinePaymentsToggle.module.css";
import { PaymentMethodToggle } from "./PaymentMethodToggle";
import { messages } from "./messages";
import { usePartialPaymentsSplit } from "./usePartialPaymentsSplit";

export interface OnlinePaymentsSettingsProps {
  showAchPayments: boolean;
  isMandatoryCardOnFileSettingEnabled: boolean;
  objectName: "quote" | "invoice";
  allowOnlinePayment: boolean;
  disableCreditCardPayments: boolean;
  disableAchPayments: boolean;
  jobberPaymentsEnabled?: boolean;
  partialPaymentsDisabled?: boolean;
  isProgressInvoicing?: boolean;
  showPaymentsToggle?: boolean;
}

const OnlinePaymentsSettings: React.FC<OnlinePaymentsSettingsProps> = ({
  showAchPayments,
  isMandatoryCardOnFileSettingEnabled,
  objectName,
  allowOnlinePayment,
  disableAchPayments,
  disableCreditCardPayments,
  jobberPaymentsEnabled,
  partialPaymentsDisabled,
  isProgressInvoicing,
  showPaymentsToggle,
}) => {
  const { hasPartialPayments } = usePartialPaymentsSplit();
  const { formatMessage } = useIntl();
  const [creditCardEnabled, setCreditCardEnabled] = useState<boolean>(
    !disableCreditCardPayments,
  );
  const [achEnabled, setAchEnabled] = useState<boolean>(!disableAchPayments);
  const [partialPaymentsEnabled, setPartialPaymentsEnabled] = useState<boolean>(
    !partialPaymentsDisabled,
  );

  const paymentName = {
    quote: "Quote Deposits",
    invoice: "Invoice Payments",
  };
  const shouldShowPartialPaymentsToggle =
    objectName == "invoice" &&
    isProgressInvoicing !== true &&
    hasPartialPayments &&
    jobberPaymentsEnabled;

  return (
    <div>
      <Content>
        <div>
          {(showPaymentsToggle !== false ||
            shouldShowPartialPaymentsToggle) && (
            <Heading level={4}>
              {formatMessage(messages.onlinePaymentSettingsTitle, {
                object: objectName === "quote" ? "Deposit" : "Invoice",
              })}
            </Heading>
          )}
          {showPaymentsToggle !== false && (
            <div className={styles.secondaryText}>
              <Text size="base" variation="subdued">
                {formatMessage(messages.onlinePaymentSettingsSecondaryText, {
                  object: objectName,
                  link: (
                    <a
                      href="/jobber_payments/setup"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.underlineText}
                    >
                      {formatMessage(messages.onlinePaymentSettingsLinkText)}
                    </a>
                  ),
                })}
              </Text>
            </div>
          )}
        </div>

        <Content>
          {showPaymentsToggle !== false && (
            <>
              <PaymentMethodToggle
                labelText={formatMessage(
                  messages.onlinePaymentSettingsCardPaymentToggleText,
                )}
                paymentMethodType={"credit_card"}
                objectName={objectName}
                enabled={creditCardEnabled}
                onChange={setCreditCardEnabled}
              />
              {showAchPayments && (
                <>
                  <PaymentMethodToggle
                    labelText={formatMessage(
                      messages.onlinePaymentSettingsAchPaymentToggleText,
                    )}
                    paymentMethodType={"ach"}
                    disableToggle={isMandatoryCardOnFileSettingEnabled}
                    objectName={objectName}
                    disabledToggleHelpText={formatMessage(
                      messages.onlinePaymentSettingsAchPaymentDisabledText,
                    )}
                    enabled={achEnabled}
                    onChange={setAchEnabled}
                  />

                  {!allowOnlinePayment && (
                    <Text size="small" variation="subdued">
                      {formatMessage(
                        messages.onlinePaymentSettingsNotEnabledText,
                        {
                          object: paymentName[objectName],
                          link: (
                            <a
                              href={"/jobber_payments/setup"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {formatMessage(
                                messages.onlinePaymentSettingsNotEnabledLinkText,
                              )}
                            </a>
                          ),
                        },
                      )}
                    </Text>
                  )}
                </>
              )}
            </>
          )}

          {shouldShowPartialPaymentsToggle && (
            <div className={styles.partialPaymentToggle}>
              <PaymentMethodToggle
                labelText={formatMessage(
                  messages.onlinePaymentSettingsPartialPaymentToggleText,
                )}
                paymentMethodType={"partial_payment"}
                objectName={objectName}
                enabled={partialPaymentsEnabled}
                onChange={setPartialPaymentsEnabled}
              />
            </div>
          )}
        </Content>
      </Content>
    </div>
  );
};

const SplitWrappedComponent = withSplitClient(OnlinePaymentsSettings);
export { SplitWrappedComponent as OnlinePaymentsSettings };
