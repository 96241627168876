import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { GlobalBannerLoader } from "./GlobalBanner.loader";

export function GlobalBanner() {
  return (
    <APIProvider>
      <GlobalBannerLoader />
    </APIProvider>
  );
}
