import { gql } from "@apollo/client";

export const MIGRATE_LEGACY_QUICKBOOKS_ONLINE_INTEGRATION = gql`
  mutation migrateLegacyQuickbooksOnlineIntegration {
    migrateLegacyQuickbooksOnlineIntegration {
      success
      userErrors {
        message
      }
    }
  }
`;
