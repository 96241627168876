import React from "react";
import { Icon } from "@jobber/components/Icon";

interface SupportingFileLinksProps {
  customClassName: string;
  onViewSupportingFileClick: () => void;
  supportingFileLinkText: string;
  ariaLabel: string;
  fileIcon?: boolean;
}

export function SupportingFileLink({
  customClassName,
  onViewSupportingFileClick,
  supportingFileLinkText,
  ariaLabel,
  fileIcon,
}: SupportingFileLinksProps) {
  const handlePress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onViewSupportingFileClick();
      event.preventDefault();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handlePress}
      aria-label={ariaLabel}
      onClick={onViewSupportingFileClick}
      className={customClassName}
    >
      {fileIcon && <Icon name="file" color="greyBlue" />}
      <span>{supportingFileLinkText}</span>
    </div>
  );
}
