import React, {
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useLazyQuery, useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { debounce } from "lodash";
import { InputText } from "@jobber/components/InputText";
import { InputValidation } from "@jobber/components/InputValidation";
import {
  SET_TEMPORARY_TWO_FACTOR_PHONE_MUTATION,
  VALID_SMS_NUMBER_QUERY,
} from "jobber/settings/users/components/VerifyOTPPhone/VerifyOTPPhone.graphiql";
import type {
  SetTemporaryPhoneMutation,
  ValidSmsNumberQuery,
  ValidSmsNumberQueryVariables,
} from "~/utilities/API/graphql";
import {
  VerificationProgress,
  init,
  verifyOTPPhoneReducer,
} from "./verifyOTPPhoneReducer";

export interface VerifyOTPPhoneInputRef {
  submit(): void;
}

export interface VerifyOTPInputProps {
  phone: string;
  children?: React.ReactNode;
  setParentPhone?: React.Dispatch<React.SetStateAction<string>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess(): void;
}

export const VerifyOTPPhoneInput = forwardRef(VerifyOTPPhoneInputInternal);

/*
  This component is intended to be used to verify a phone number for use with Twilio's 2FA service.
  We use this as part of the initial 2FA setup, which can be triggered from Rails (two_factor_authentication_controller)
  or as part of another react component. This is also used when a user who has already setup 2FA wishes to change their
  phone number to a different number.
*/

function VerifyOTPPhoneInputInternal(
  {
    phone,
    setParentPhone,
    setDisabled,
    onSuccess,
    children,
  }: VerifyOTPInputProps,
  ref: Ref<VerifyOTPPhoneInputRef>,
) {
  const [{ loading: showLoading, invalid }, dispatch] = useReducer(
    verifyOTPPhoneReducer,
    VerificationProgress.Default,
    init,
  );

  const [phoneNumber, setPhoneNumber] = useState(phone.replace(/\D/g, ""));
  const debouncedPhoneLookup = debounce(lookupSms, 1000);

  const [validSmsQuery, { loading, data }] = useLazyQuery<
    ValidSmsNumberQuery,
    ValidSmsNumberQueryVariables
  >(VALID_SMS_NUMBER_QUERY);

  const [setTemporaryPhone] = useMutation<SetTemporaryPhoneMutation>(
    SET_TEMPORARY_TWO_FACTOR_PHONE_MUTATION,
  );

  useEffect(() => {
    setDisabled(true);
    if (shouldLookupPhoneNumber(phoneNumber)) {
      dispatch(VerificationProgress.Loading);
      debouncedPhoneLookup(phoneNumber);
    } else {
      dispatch(VerificationProgress.Default);
    }
  }, [phoneNumber]);

  useEffect(() => {
    /*
      useLazyQuery does not return a promise,
      evaluate returned data in useEffect
    */

    if (data?.phoneLookup && !loading) {
      if (data.phoneLookup.smsAvailable) {
        dispatch(VerificationProgress.ValidPhone);
        setDisabled(false);
        if (setParentPhone) {
          setParentPhone(data.phoneLookup.phoneNumber.friendly);
        }
      } else {
        dispatch(VerificationProgress.InvalidPhone);
      }
    }
  }, [data]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit().catch((e: Error) => {
        throw e;
      });
    },
  }));

  return (
    <>
      <Content>
        {children}
        <Text>
          <InputText
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={handleChange}
            keyboard={"numeric"}
            autocomplete={false}
            loading={showLoading}
            invalid={invalid}
          />
          {invalid && (
            <InputValidation
              message={"This phone number does not support text messaging"}
            />
          )}
        </Text>
      </Content>
    </>
  );

  function handleChange(newPhone: string) {
    if (!newPhone.match(/^[0-9]*$/)) {
      return;
    }
    setPhoneNumber(newPhone);
  }

  async function handleSubmit() {
    try {
      const result = await setTemporaryPhone({
        variables: {
          phone: phoneNumber,
        },
      });

      const userErrors = result.data?.setTemporaryTwoFactorPhone?.userErrors;
      if (userErrors && userErrors.length > 0) {
        // model validation errors
        userErrors.forEach(error =>
          showToast({
            message: error.message,
            variation: "error",
          }),
        );
      } else {
        onSuccess();
      }
    } catch (error) {
      setDisabled(false);
      throw error;
    }
  }

  function lookupSms(number: string) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validSmsQuery({ variables: { phoneNumber: number } });
  }
}

function shouldLookupPhoneNumber(number: string) {
  // only lookup phone numbers that are 10, 11 or 12 digits long (12 for UK and Aus)
  const numberLength = number.length;
  return (
    (numberLength === 10 || numberLength === 11 || numberLength === 12) &&
    Number.isInteger(Number(number))
  );
}
