import { type DocumentNode, useMutation } from "@apollo/client";
import type { AlternateSchema } from "~/utilities/API/APIClient";
import type { ExportFunctionArguments } from "./types";

interface ExportVariables<F, C> {
  input: ExportFunctionArguments<F, C>;
}

export function useExportCsvMutation<M, F, C>(
  mutation: DocumentNode,
  alternateSchema?: AlternateSchema,
) {
  const [exportCsv, { data, loading, error }] = useMutation<
    M,
    ExportVariables<F, C>
  >(
    mutation,
    alternateSchema
      ? {
          context: { schema: alternateSchema },
        }
      : undefined,
  );

  async function fetchExport({
    filter,
    columnSelection,
    formId,
  }: ExportFunctionArguments<F, C>) {
    await exportCsv({
      variables: {
        input: {
          filter: filter,
          columnSelection: columnSelection,
          formId: formId,
        },
      },
    });
  }

  return {
    fetchExport,
    data,
    loading,
    error,
  };
}
