import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "automaticPayments.expansionCard.heading",
    defaultMessage: "Get paid automatically",
    description: "Heading for automatic payments expansion card",
  },
  description: {
    id: "automaticPayments.expansionCard.description",
    defaultMessage:
      "Upgrade to use automatic payments. Clients will be automatically invoiced and charged with their saved payment method based on billing frequency. Learn more in [Help Center](https://help.getjobber.com/hc/en-us/articles/360036931633-Automatic-Payments).",
    description: "Description for automatic payments expansion card",
  },
  primaryCTA: {
    id: "automaticPayments.expansionCard.primaryCTA",
    defaultMessage: "Upgrade Now",
    description: "Primary CTA for automatic payments expansion card",
  },
  secondaryCTA: {
    id: "automaticPayments.expansionCard.secondaryCTA",
    defaultMessage: "Help Center",
    description: "Secondary CTA for automatic payments expansion card",
  },
});
