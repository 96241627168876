import Quill from "quill";
import type { Parchment } from "quill";
import { QuillImageBlot } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks/useQuill/modules/imageResize";

// https://quilljs.com/docs/formats/ has the list of available formats.
export const QUILL_FORMATS = [
  "bold",
  "italic",
  "link",
  "strike",
  "underline",
  "header",
  "align",
  "font",
  "image",
];

/**
 * Helvetica is placed first as it is the primary font for Campaigns.
 * Quill renders these fonts in the order specified below. First font must
 * always be the one used as primary in the templates
 * `config/locales/views/marketing/en.yml`
 */
export const SUPPORTED_FONTS = [
  "Helvetica",
  "Arial",
  "Trebuchet MS",
  "Georgia",
  "Courier New",
];

// eslint-disable-next-line max-statements
export function setupAttributors() {
  // This forces Quill to use inline styles instead of classes.

  const AlignStyle = Quill.import("attributors/style/align");
  Quill.register("formats/align", AlignStyle, true);

  const BackgroundStyle = Quill.import("attributors/style/background");
  Quill.register("formats/background", BackgroundStyle, true);

  const ColorStyle = Quill.import("attributors/style/color");
  Quill.register("formats/color", ColorStyle, true);

  const DirectionStyle = Quill.import("attributors/style/direction");
  Quill.register("formats/direction", DirectionStyle, true);

  const FontStyle = Quill.import(
    "attributors/style/font",
  ) as unknown as Parchment.ClassAttributor;
  Quill.register("formats/font", FontStyle, true);

  const SizeStyle = Quill.import("attributors/style/size");
  Quill.register("formats/size", SizeStyle, true);

  FontStyle.whitelist = SUPPORTED_FONTS;

  const Font = Quill.import(
    "formats/font",
  ) as unknown as Parchment.ClassAttributor;
  Font.whitelist = SUPPORTED_FONTS;
  Quill.register(Font, true);

  // Custom image formatter
  Quill.register(QuillImageBlot, true);
}
