import type { FetchResult } from "@apollo/client";
import type { ComponentProps } from "react";
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { AiReceptionistToggle } from "jobber/chat/components/ChatDrawer/Chat/AiReceptionistToggle/AiReceptionistToggle";
import { reverseTabbing } from "jobber/chat/components/ChatDrawer/ChatList/reverseTabbing";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import type {
  AiReceptionistSmsStateEditMutation,
  ConversationMessagesQuery,
  MessageStatusEnum,
  Scalars,
} from "~/utilities/API/graphql";
import { ChatList } from "jobber/chat/components/ChatDrawer/ChatList";
import { ChatBubbleRenderer } from "jobber/chat/components/ChatDrawer/Chat/ChatBubble/ChatBubble";
import { ChatInput } from "jobber/chat/components/ChatDrawer/Chat/ChatInput";
import { ConversationStartInformation } from "jobber/features/MessageCenter/views/Conversation/components/ConversationStartInformation";
import type { useAiReceptionist } from "jobber/chat/components/ChatDrawer/Chat/AiReceptionistToggle/useAiReceptionist";
import styles from "./Chat.module.css";

interface ChatProps {
  messages: NonNullable<
    NonNullable<ConversationMessagesQuery["conversation"]>["messages"]["edges"]
  >[0]["node"][];
  hasNextPage: boolean;
  getMoreMessages(): void;
  markConversationAsRead(): void;
  afterSend?(): void;
  afterInput?(
    message: string,
    clientGeneratedId: Scalars["Uuid"]["input"],
    status: MessageStatusEnum,
  ): void;
  aiReceptionistSmsStateAvailable: boolean;
  aiReceptionistSmsStateStatus: boolean;
  toggleAiReceptionist: (
    value: boolean,
  ) => Promise<FetchResult<AiReceptionistSmsStateEditMutation>>;
}

export function Chat({
  messages,
  hasNextPage,
  getMoreMessages,
  markConversationAsRead,
  afterSend,
  afterInput,
  aiReceptionistSmsStateAvailable,
  aiReceptionistSmsStateStatus,
  toggleAiReceptionist,
}: ChatProps) {
  useEffect(() => {
    markConversationAsRead();
  }, []);
  const { chatBubbleRef, handleReverseTabbing } = reverseTabbing();
  const [state] = useContext(MessageCenterContext);
  const loadMore = hasNextPage ? getMoreMessages : undefined;

  const externalName = state.selectedRecipient?.id
    ? state.selectedRecipient.name
    : state.selectedRecipient?.contactInfo;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions -- Grandfathered error: Please fix if touching this code.
    <div
      data-testid={"chat"}
      className={classNames(styles.chat)}
      onKeyDown={handleReverseTabbing}
      tabIndex={0}
      role={"complementary"}
    >
      <ChatList ref={chatBubbleRef} loadMore={loadMore} reverseOrder={true}>
        <>
          <ChatBubbleRenderer items={messages} externalName={externalName} />
          {!hasNextPage && !!messages.length && (
            <ConversationStartInformation message={messages[0]} />
          )}
        </>
      </ChatList>
      {aiReceptionistSmsStateAvailable && (
        <AiReceptionistToggleWrapper
          handleValueChange={handleValueChange}
          initialValue={aiReceptionistSmsStateStatus}
          key={`ai.toggle.${aiReceptionistSmsStateStatus}`}
        />
      )}
      {state.selectedConversationId && (
        <ChatInput
          canSendMessage={state.canSendMessages}
          recipient={state.selectedRecipient?.contactInfo}
          afterSend={afterSend}
          afterInput={afterInput}
          conversationId={state.selectedConversationId.toString()}
        />
      )}
    </div>
  );

  function handleValueChange(value: boolean) {
    return toggleAiReceptionist(value);
  }
}

type ToggleValue = ComponentProps<typeof AiReceptionistToggle>["toggle"];
type HandleValueChange = ReturnType<
  typeof useAiReceptionist
>["toggleAiReceptionist"];

function AiReceptionistToggleWrapper({
  handleValueChange,
  initialValue = false,
}: {
  handleValueChange: HandleValueChange;
  initialValue?: ToggleValue;
}) {
  const [toggle, setToggle] = useState<ToggleValue>(initialValue);

  return (
    <AiReceptionistToggle
      handleValueChange={handleValueChangeInternal}
      toggle={toggle}
    />
  );

  async function handleValueChangeInternal(toggleVal: ToggleValue) {
    setToggle(toggleVal);

    try {
      const { data } = await handleValueChange(toggleVal);

      if (!data?.aiReceptionistSmsStateEdit?.success) setToggle(!toggleVal);
    } catch {
      setToggle(!toggleVal);
    }
  }
}
