import React from "react";
import type { IconNames } from "@jobber/design";
import { Icon } from "@jobber/components";
import styles from "./AutomationsIcon.module.css";

interface AutomationsIconProps {
  iconName: IconNames;
}

export function AutomationsIcon({ iconName }: AutomationsIconProps) {
  return (
    <div className={styles.iconContainer}>
      <Icon name={iconName} color="heading" />
    </div>
  );
}
