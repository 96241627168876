// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import type { dateRangeOptionIndexType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/types";

// These strings are related to the Intl keys in messages.ts
export enum DateRangeOptions {
  week = "dateRangeWeek",
  thirtyDays = "dateRangeThirtyDays",
  month = "dateRangeMonth",
  year = "dateRangeYear",
  twelveMonths = "dateRangeTwelveMonths",
  custom = "dateRangeCustom",
}

export const dateRangeOptionIndex: dateRangeOptionIndexType[] = [
  {
    option: DateRangeOptions.week,
    start: moment()
      .subtract(1, "week")
      .startOf("week")
      .hour(12)
      .minute(0)
      .toDate(),
    end: moment().subtract(1, "week").endOf("week").hour(12).minute(0).toDate(),
  },
  {
    option: DateRangeOptions.thirtyDays,
    start: moment()
      .subtract(30, "day")
      .startOf("hours")
      .hour(12)
      .minute(0)
      .toDate(),
    end: moment().startOf("hours").hour(12).minute(0).toDate(),
  },
  {
    option: DateRangeOptions.month,
    start: moment()
      .subtract(1, "month")
      .startOf("month")
      .startOf("hours")
      .hour(12)
      .minute(0)
      .toDate(),
    end: moment()
      .subtract(1, "month")
      .endOf("month")
      .startOf("hours")
      .hour(12)
      .minute(0)
      .toDate(),
  },
  {
    option: DateRangeOptions.year,
    start: new Date(new Date().getFullYear(), 0, 1),
    end: moment().startOf("hours").hour(12).minute(0).toDate(),
  },
  {
    option: DateRangeOptions.twelveMonths,
    start: moment()
      .subtract(12, "month")
      .startOf("hours")
      .hour(12)
      .minute(0)
      .toDate(),
    end: moment().startOf("hours").hour(12).minute(0).toDate(),
  },
];

// Should be last 30 days (DateRangeOptions.thirtyDays)
export const defaultDateRangeOption = dateRangeOptionIndex[1];

export const defaultDateRangeTimezone = "UTC";
