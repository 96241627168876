import {
  GenericError,
  InvoiceError,
  type PaymentError,
  QuoteError,
  UnexpectedError,
} from "jobber/payments/errors";
import type { JobberApiError, JobberApiResponse, PaymentResponse } from ".";

function normalizeErrorResponse(errors: JobberApiError): PaymentError {
  if (errors.generic) {
    return new GenericError(errors.generic.header, errors.generic.details);
  } else if (errors.invoice) {
    return new InvoiceError(errors.invoice);
  } else if (errors.quote) {
    return new QuoteError(errors.quote);
  } else if (errors.error) {
    return new GenericError(undefined, errors.error);
  } else if (errors.base) {
    return new GenericError(undefined, errors.base);
  }

  return new UnexpectedError(errors);
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function normalizeResponse(
  data: JobberApiResponse,
): PaymentResponse {
  if (data.success !== true && data.errors) {
    return {
      success: false,
      error: normalizeErrorResponse(data.errors),
    };
  }

  return {
    success: true,
  };
}
