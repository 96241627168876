import { gql } from "@apollo/client";

export const PLAID_LINK_ACCOUNT_TOKEN_QUERY = gql`
  query PlaidLinkAccountTokenQuery($platform: PlaidPlatform!) {
    plaidLinkAccountToken(platform: $platform) {
      linkToken
    }
  }
`;

export const PLAID_LINK_PAYMENT_TOKEN_QUERY = gql`
  query PlaidLinkPaymentTokenQuery {
    plaidLinkPaymentToken {
      linkToken
    }
  }
`;
