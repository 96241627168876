import { gql } from "@apollo/client";

export const SETUP_GUIDE_STATUS_MUTATION = gql`
  mutation SetupGuideStatus {
    setupGuideStatus {
      setupGuide {
        completed
        id
        firstLoad
        optOut
        steps {
          nodes {
            status
            step
          }
        }
        totalSteps
        doneSteps
        progress
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const SETUP_GUIDE_EDIT_MUTATION = gql`
  mutation SetupGuideEdit($input: SetupGuideEditInput!) {
    setupGuideEdit(input: $input) {
      setupGuide {
        id
        optOut
        firstLoad
        completed
        totalSteps
        steps {
          nodes {
            status
            step
          }
        }
      }
      userErrors {
        message
      }
    }
  }
`;

export const SEND_APP_DOWNLOAD_SMS_MUTATION = gql`
  mutation SendAppDownloadSmsInvite($input: String!) {
    sendAppDownloadSmsInvite(recipient: $input) {
      success
      userErrors {
        path
        message
      }
    }
  }
`;
