import { createContext } from "react";
import type { AddressConfiguration } from "./types";
import { provinces } from "./provinces";

export const AddressConfigurationContext = createContext<AddressConfiguration>({
  availableCountries: {
    CA: "Canada",
    US: "United States",
  },
  availableProvinces: provinces,
});
export const AddressConfigurationProvider =
  AddressConfigurationContext.Provider;
