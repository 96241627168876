import BigNumber from "bignumber.js";
import type { Margin, TotalModifier } from "~/jobber/quotes/types";
import type { TotalData } from "~/jobber/quotes/TotalData";
import { jobberOnline } from "components/JobberOnline/jobberOnline";

export function setFormTotals(
  calculatedTotals: TotalData,
  currencySymbol: string,
  quoteMarginsEnabled: boolean,
) {
  setTotalValue(
    "#quote_subtotal",
    formatValue(currencySymbol, new BigNumber(calculatedTotals.subtotal)),
  );
  setTotalValue(
    "#quote_discount",
    `- ${formatNumberToCurrency(
      currencySymbol,
      new BigNumber(calculatedTotals.discountAmount).toNumber(),
    )}`,
  );
  setTaxValues(calculatedTotals, currencySymbol);
  setTotalValue(
    ".js-formTotal",
    formatValue(currencySymbol, new BigNumber(calculatedTotals.total)),
  );
  setTotalValue(
    "#quote_deposit",
    formatValue(currencySymbol, new BigNumber(calculatedTotals.depositAmount)),
  );

  if (jobberOnline.featureFlags.quoteMargins && quoteMarginsEnabled) {
    toggleMarginsRows(calculatedTotals);
    setTotalValue(
      "#quote_costs",
      formatValue(currencySymbol, new BigNumber(calculatedTotals.costs)),
    );
    setMarginValue(currencySymbol, calculatedTotals.margin);
  }
}

function toggleMarginsRows(calculatedTotals: TotalData) {
  if (calculatedTotals.costs.isZero()) {
    $("#quoteCostsListItem").addClass("u-hidden");
    $("#quoteMarginListItem").addClass("u-hidden");
  } else {
    $("#quoteCostsListItem").removeClass("u-hidden");
    $("#quoteMarginListItem").removeClass("u-hidden");
  }
}

function setMarginValue(currencySymbol: string, margin: Margin) {
  setTotalValue(
    "#quote_margin",
    `${formatValue(currencySymbol, margin.amount)} (${margin.percentage}%)`,
  );
  if (margin.amount.isNegative()) {
    $("#quote_margin").removeClass("u-colorGreen");
    $("#quote_margin").addClass("u-colorRed");
  } else {
    $("#quote_margin").addClass("u-colorGreen");
    $("#quote_margin").removeClass("u-colorRed");
  }
}

function formatValue(currencySymbol: string, value: BigNumber) {
  const prefix = value.isNegative() ? "- " : "";
  return `${prefix}${formatNumberToCurrency(
    currencySymbol,
    value.absoluteValue().toNumber(),
  )}`;
}

function setTaxValues(calculatedTotals: TotalData, currencySymbol: string) {
  if (calculatedTotals.taxOptions) {
    if (calculatedTotals.taxOptions.length > 1) {
      const oldTaxRates = document.querySelectorAll(".js-taxRateComponent");
      if (oldTaxRates) {
        oldTaxRates.forEach((oldTaxRate: HTMLElement) => {
          oldTaxRate.remove();
        });
      }
      calculatedTotals.taxOptions.forEach((taxOption: TotalModifier) => {
        const taxRateRow = document.querySelector(
          ".js-taxRateRow",
        ) as HTMLElement;
        const row = $(".js-taxRateComponentTemplate")
          .clone()
          .removeClass("js-taxRateComponentTemplate")
          .addClass("js-taxRateComponent");
        row.find(".list-text").text(getTaxLabel(taxOption));
        row
          .find(".js-bindTaxComponent")
          .val(taxOption.rate)
          .prop("disabled", true);
        const taxRowAmount = new BigNumber(taxOption.rate)
          .dividedBy(new BigNumber(100))
          .multipliedBy(calculatedTotals.taxableSubtotal);
        row
          .find(".js-taxComponentAmount")
          .text(formatValue(currencySymbol, taxRowAmount));
        return row.insertAfter(taxRateRow).removeClass("u-hidden").show();
      });
    } else {
      setTotalValue(
        ".js-bindTaxAmountFormatted",
        formatValue(currencySymbol, new BigNumber(calculatedTotals.taxAmount)),
      );
    }
  }
}

function getTaxLabel(taxOption: TotalModifier): string {
  if (taxOption.name) {
    return taxOption.name;
  }
  return "";
}

function setTotalValue(totalSelector: string, value: string) {
  const control = document.querySelector(`${totalSelector}`) as HTMLElement;
  if (control) {
    control.innerText = value;
  } else {
    throw new Error(`No ${totalSelector} found`);
  }
}

function formatNumberToCurrency(currencySymbol: string, value: number): string {
  return `${currencySymbol}${value.toFixed(2)}`;
}
