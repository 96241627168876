const jobValues = {
  iconName: "job",
  label: "Job",
  urlPath: "work_orders",
};

const quoteValues = {
  iconName: "quote",
  label: "Quote",
  urlPath: "quotes",
};

const invoiceValues = {
  iconName: "invoice",
  label: "Invoice",
  urlPath: "invoices",
};

const requestValues = {
  iconName: "request",
  label: "Request",
  urlPath: "work_requests",
};

export const WORK_OBJECT_MAPPING = new Map([
  ["Job", jobValues],
  ["WorkRequest", requestValues],
  ["Quote", quoteValues],
  ["Invoice", invoiceValues],
]);
