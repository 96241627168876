import React, { type ComponentType } from "react";
import {
  RailsPropsAsContexts,
  type RailsPropsAsContextsProps,
} from "./RailsPropsAsContexts";

export function withRailsPropsAsContexts() {
  function hoc<Props>(Component: ComponentType<Props>) {
    function WithRailsPropsAsContexts(
      props: Props & RailsPropsAsContextsProps,
    ) {
      return (
        <RailsPropsAsContexts {...props}>
          <Component {...props} />
        </RailsPropsAsContexts>
      );
    }
    return WithRailsPropsAsContexts;
  }
  return hoc;
}
