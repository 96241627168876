import { Checkbox } from "@jobber/components/Checkbox";
import { Content } from "@jobber/components/Content";
import { CardElement } from "@stripe/react-stripe-js";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import FieldError from "jobber/payments_sca/components/FieldError";
import NameOnCardInput from "jobber/payments_sca/components/NameOnCardInput";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { getStripeFieldColorsFromTheme } from "~/utilities/getStripeFieldColorsFromTheme";
import styles from "./CreditCardForm.module.css";
import { JobberPaymentsBillingAddress } from "./JobberPaymentsBillingAddress";

interface CreditCardFormProps {
  inClientHub: boolean;
}

export function CreditCardForm(props: CreditCardFormProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalCreditCardForm {...props} />
    </AtlantisThemeContextProvider>
  );
}

export function InternalCreditCardForm({ inClientHub }: CreditCardFormProps) {
  const [paymentState, paymentDispatcher] = usePaymentReducer();
  const isPaymentInProgress = paymentState.status === "inProgress";
  const [errorMessage, setErrorMessage] = useState<string>();
  const { tokens } = useAtlantisTheme();
  const [t] = useTranslation();
  const { fieldValueColor, fieldPlaceholderColor, criticalColor } =
    getStripeFieldColorsFromTheme(tokens);

  const stripeElementsStyle = {
    style: {
      base: {
        color: fieldValueColor,
        ["::placeholder"]: {
          color: fieldPlaceholderColor,
        },
      },
      invalid: {
        color: criticalColor,
      },
    },
    classes: {
      focus: "is-focus",
      empty: "is-empty",
      invalid: "is-invalid",
    },
    disabled: isPaymentInProgress,
    hidePostalCode: true,
    iconStyle: "solid" as const,
  };

  return (
    <Content>
      <Heading level={5}>Card details</Heading>
      <NameOnCardInput
        name={paymentState.billingDetails?.name}
        onNameChange={onNameChange}
        disabled={isPaymentInProgress}
      />
      <FieldError errorMessage={errorMessage} />
      <CardElement
        className={`placeholderField-input ${styles.stripeInput}`}
        options={stripeElementsStyle}
        onChange={onCardElementChange}
      />
      {paymentState.showSavePaymentMethod && (
        <>
          {paymentState.mandatoryCardOnFile &&
          paymentState.attachedToType === "quote" ? (
            <p className="u-textSmaller u-lineHeightBase">
              <em>{t("mandatoryCardOnFileDisclaimer")}</em>
            </p>
          ) : (
            <Checkbox
              label="Keep this card on file"
              value="should_save_payment_method"
              checked={paymentState.shouldSavePaymentMethod}
              onChange={onShouldSavePaymentMethodChange}
              disabled={isPaymentInProgress}
            />
          )}
        </>
      )}
      <JobberPaymentsBillingAddress inClientHub={inClientHub} />
    </Content>
  );

  function onNameChange(name: string) {
    paymentDispatcher({
      type: "updatingJobberPaymentsBillingName",
      name,
    });
  }

  function onCardElementChange(event: StripeCardElementChangeEvent) {
    setErrorMessage(event.error?.message);
  }

  function onShouldSavePaymentMethodChange(shouldSavePaymentMethod: boolean) {
    paymentDispatcher({
      type: "updatingJobberPaymentsShouldSavePaymentMethod",
      shouldSavePaymentMethod,
    });
  }
}
