import { useRef } from "react";
import type { TwilioRegistrationFormReducerState } from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import { hasTaxIdRegistrationError } from "jobber/settings/dedicatedPhoneNumber/utilities/businessRegistrationNumberHelpers";

export function useTwilioRegistrationDataMemoization(
  state: TwilioRegistrationFormReducerState,
) {
  const memoizedBusinessRegistrationNumber = useRef(
    state.businessRegistrationNumber,
  );

  const memoizedBusinessName = useRef(state.businessName);

  function setMemoizedBusinessRegistrationNumber() {
    if (
      !memoizedBusinessRegistrationNumber.current &&
      hasTaxIdRegistrationError(state.userErrors)
    ) {
      memoizedBusinessRegistrationNumber.current =
        state.businessRegistrationNumber;
    }
  }

  function setMemoizedBusinessName() {
    if (
      !memoizedBusinessName.current &&
      hasTaxIdRegistrationError(state.userErrors)
    ) {
      memoizedBusinessName.current = state.businessName;
    }
  }

  setMemoizedBusinessRegistrationNumber();
  setMemoizedBusinessName();
  return {
    memoizedBusinessName,
    memoizedBusinessRegistrationNumber,
  };
}
