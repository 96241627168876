import { gql } from "@apollo/client";

export const INSIGHT_WIDGETS_QUERY = gql`
  query InsightWidgets($categories: [AiAssistantInsightWidgetCategory!]!) {
    aiAssistantInsightWidgets(categories: $categories) {
      nodes {
        widgetName: name
        categories
        prompt
        statement {
          arrow {
            direction
            impact
            value
          }
          text
        }
      }
    }
  }
`;
