import React, { useMemo } from "react";
import { ExpiryStatus } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";

export interface ExpiryProps {
  month: number | undefined;
  year: number | undefined;
  status?: ExpiryStatus;
}

export function Expiry({ month, year, status }: ExpiryProps) {
  const statusClass = useMemo(() => classFromStatus(status), [status]);

  return (
    <div className={`u-textRight`}>
      <div
        className={`list-subText u-textSmaller u-textUppercase ${statusClass}`}
      >
        Expiry
      </div>
      <span className={`list-text ${statusClass}`}>
        {formattedExpiry({ month, year })}
      </span>
    </div>
  );
}

function formattedExpiry({ month, year }: Pick<ExpiryProps, "month" | "year">) {
  if (!month || !year) {
    return "–";
  }

  const shortYear = `${year}`.trim().slice(-2);

  return `${month}/${shortYear}`;
}

function classFromStatus(status: ExpiryStatus | undefined) {
  switch (status) {
    case ExpiryStatus.EXPIRED:
      return "u-colorRed";
    case ExpiryStatus.EXPIRING_SOON:
      return "u-colorOrange";
    default:
      return "";
  }
}
