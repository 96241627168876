import { Button } from "@jobber/components/Button";
import React from "react";
import { useIntl } from "react-intl";
import { useStartChatWithSuccess } from "jobber/jobberAssistant/hooks";
import { messages } from "./messages";

interface ChatWithSuccessButtonProps {
  message: string;
  requestId: string;
}

export function ChatWithSuccessButton({
  message,
  requestId,
}: ChatWithSuccessButtonProps) {
  const { formatMessage } = useIntl();
  const { startChatWithSuccess, loading } = useStartChatWithSuccess();

  return (
    <Button
      onClick={async () => {
        await startChatWithSuccess(message, requestId);
      }}
      label={formatMessage(messages.chatWithSuccessButtonLabel)}
      fullWidth
      loading={loading}
    />
  );
}
