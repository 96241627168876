import React from "react";
import { SetupGuide } from "jobber/dashboard/components/SetupGuide";

export interface ConnectToGrowOnboardingChecklistProps {
  userName: string;
  drawerOpen?: boolean | undefined;
  optingBackIn?: boolean;
}

export function ConnectToGrowOnboardingChecklist({
  userName,
  drawerOpen,
  optingBackIn,
}: ConnectToGrowOnboardingChecklistProps) {
  return (
    <div>
      <SetupGuide
        userName={userName}
        drawerOpen={drawerOpen ? drawerOpen : undefined}
        optingBackIn={optingBackIn}
      />
    </div>
  );
}
