import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import styles from "./OneOffSchedulingLoader.module.css";
import type { RepeatOption } from "./types";
import { DefaultSchedulingContainer } from "./DefaultSchedulingContainer";
import { useOneOffSchedulingMachine } from "./hooks";
import {
  RecommendationMachineContext,
  RecommendationsSchedulingContainer,
  ScheduleCardMachineContext,
} from "../ScheduleCard";
import { AssignTeamMachineContext } from "../AssignTeam";

export interface OneOffSchedulingLoaderProps {
  initialAssignedToIds: string[];
  notifyTeam: boolean;
  railsFormKey: string;
  repeatOptions: RepeatOption[];
  arrivalWindowParameters: { centered: boolean; duration: number | null };
}

/**
 *
 * @returns Place holder for all the components within the Scheduling Card for
 * One-Off Job Create.
 */
const OneOffSchedulingLoaderInternal = (props: OneOffSchedulingLoaderProps) => {
  const { assignTeamMachine, scheduleCardMachine, recommendationMachine } =
    useOneOffSchedulingMachine(props.initialAssignedToIds, props.repeatOptions);

  const { getTreatmentValue } = useSplit({
    names: [SplitNames.SchedulingRecommendations],
  });
  const isInRecommendationsBeta = getTreatmentValue(
    SplitNames.SchedulingRecommendations,
  );
  const SchedulingComponent = isInRecommendationsBeta
    ? RecommendationsSchedulingContainer
    : DefaultSchedulingContainer;

  return (
    <div className={styles.container}>
      <div
        className={
          isInRecommendationsBeta
            ? styles.recommendationsScheduleAndCalendarContainer
            : styles.scheduleAndCalendarContainer
        }
      >
        <div
          className={[
            styles.scheduleContainer,
            isInRecommendationsBeta ? styles.wide : styles.narrow,
          ].join(" ")}
        >
          <RecommendationMachineContext.Provider value={recommendationMachine}>
            <ScheduleCardMachineContext.Provider value={scheduleCardMachine}>
              <AssignTeamMachineContext.Provider value={assignTeamMachine}>
                <SchedulingComponent {...props} />
              </AssignTeamMachineContext.Provider>
            </ScheduleCardMachineContext.Provider>
          </RecommendationMachineContext.Provider>
        </div>
        {!isInRecommendationsBeta && (
          <div className={styles.calendarContainer}>
            <div>Calendar</div>
          </div>
        )}
      </div>
    </div>
  );
};

const OneOffSchedulingLoaderApiWrapper = (
  props: OneOffSchedulingLoaderProps,
) => {
  return (
    <APIProvider>
      <OneOffSchedulingLoaderInternal {...props} />
    </APIProvider>
  );
};

export const OneOffSchedulingLoader = withSplitClient(
  withRailsPropsAsContexts()(OneOffSchedulingLoaderApiWrapper),
);
