import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useIntl } from "react-intl";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import { toPercentage } from "jobber/workOrders/components/JobCost/components/JobCostHeader/utils";
import { useViewport } from "jobber/hooks/useViewport";
import type { JobCostRecord } from "jobber/workOrders/components/JobCost/components/JobCostHeader/JobCostHeader";
import styles from "./JobProfitBarGraph.module.css";
import { messages } from "./messages";
import { JobProfitTooltip } from "../JobProfitTooltip";

interface JobCostGraphProps {
  jobCosting: JobCostRecord;
}

export function JobProfitBarGraph(props: JobCostGraphProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalJobProfitBarGraph {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalJobProfitBarGraph(props: JobCostGraphProps): JSX.Element {
  const { tokens } = useAtlantisTheme();
  const { innerWidth } = useViewport();
  const { formatMessage } = useIntl();

  const {
    lineItemCost,
    labourCost,
    expenseCost,
    profitPercentage,
    totalRevenue,
  } = props.jobCosting;

  if (
    totalRevenue === 0 ||
    // DO NOT COPY THIS! This is a custom breakpoint for Profit Bar only
    innerWidth > 1180
  ) {
    return <></>;
  }

  const barData = [
    {
      id: formatMessage(messages.totalCost),
      LineItemCost: toPercentage(lineItemCost, totalRevenue),
      Labour: toPercentage(labourCost, totalRevenue),
      Expenses: toPercentage(expenseCost, totalRevenue),
      // if profit is negative / undefined (< 0) we show as 0
      Profit: profitPercentage ? toPercentage(profitPercentage, 100) : 0,
    },
  ];

  return (
    <div className={styles.container} data-testid="JobProfitBarGraph">
      <ResponsiveBar
        data={barData}
        keys={["LineItemCost", "Labour", "Expenses", "Profit"]}
        layout="horizontal"
        padding={0.3}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        innerPadding={1}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={[
          tokens["color-lightBlue--dark"],
          tokens["color-lightBlue"],
          tokens["color-lightBlue--light"],
          tokens["color-green"],
        ]}
        axisLeft={null}
        enableLabel={false}
        enableGridY={false}
        axisBottom={null}
        role="application"
        ariaLabel={formatMessage(messages.barGraphAriaLabel)}
        tooltip={tooltipData => <JobProfitTooltip tooltipData={tooltipData} />}
      />
    </div>
  );
}
