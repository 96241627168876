import React from "react";
import { useMutation } from "@apollo/client";
import type { GoogleBusinessAccountDisconnectMutation } from "~/utilities/API/graphql";
import { messages } from "./messages";
import { GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION } from "./ConnectedStep.graphql";
import type { SetupGuideStepProps } from "../../types";
import { BuildStep } from "../BuildStep";
import { ConnectedImage } from "../../images";

export function ConnectedStep(stepProps: SetupGuideStepProps): JSX.Element {
  const [disconnectAccount] =
    useMutation<GoogleBusinessAccountDisconnectMutation>(
      GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION,
    );

  const stepDetails = {
    title: messages.title,
    subtitle: messages.subtitle,
    description: messages.description,
    image: {
      src: ConnectedImage.src,
      alt: ConnectedImage.alt,
    },
    primaryButton: {
      label: messages.primaryButton,
      action: () => {
        stepProps.navigation.onNext();
      },
    },
    secondaryButton: {
      label: messages.secondaryButton,
      action: () => {
        disconnectAccount()
          .then(() => {
            window.location.href = "/reviews";
          })
          .catch(() => {
            stepProps.setErrMsg(messages.generalError);
          });
      },
    },
  };

  return (
    <BuildStep navigation={stepProps.navigation} stepDetails={stepDetails} />
  );
}
