import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import React from "react";
import styles from "./LoadError.module.css";

export function LoadError() {
  return (
    <div className={styles.errorContainer}>
      <Icon name="alert" size="large" color="yellow" />
      <div>
        <Heading level={5}>Can&apos;t load Custom Fields</Heading>
        <Text>
          There was a problem loading your custom fields. Please try again
          later.
        </Text>
      </div>
    </div>
  );
}
