// If making changes to this file, please also update this code in jobber-frontend.
// https://github.com/GetJobber/jobber-frontend/blob/main/apps/jobber-online/app/features/requests/insightWidgets/InsightWidget.tsx
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import {
  AiAssistantInsightDirection,
  AiAssistantInsightImpact,
  type AiAssistantInsightWidgetStatement,
} from "~/utilities/API/graphql";
import { withIntlProvider } from "@translations/withIntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./InsightWidget.module.css";
import { messages } from "./messages";
import { OPEN_ASSISTANT_FROM_INSIGHT_WIDGET } from "./constants";

interface InsightWidgetProps {
  statement: AiAssistantInsightWidgetStatement;
  prompt?: string;
  widgetName: string;
}

export const InsightWidget = withIntlProvider(InsightWidgetInternal);

export function InsightWidgetLoading() {
  return (
    <div className={styles.insightCard}>
      <Content>
        <div className={styles.insightCardLabel}>
          <Glimmer.Header level={4} />
        </div>
        <div className={styles.copyContainer}>
          <span>
            <Glimmer.Text lines={3} />
          </span>
        </div>
      </Content>
    </div>
  );
}

function InsightWidgetInternal({
  statement,
  prompt,
  widgetName,
}: InsightWidgetProps) {
  const { formatMessage } = useIntl();

  return (
    <button
      onClick={sendPromptEventWithTracking}
      className={styles.insightCard}
      aria-label="Jobber Copilot insight"
    >
      <InsightStatements {...statement} />
      <div id={styles.insightCardLabel}>
        <Typography size="base" fontWeight="bold">
          {formatMessage(messages.insightLabel, {
            icon: <Icon name="sparkles" size="small" />,
          })}
        </Typography>
      </div>
    </button>
  );

  function sendPromptEventWithTracking() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "insight_widget",
      source: widgetName,
    });

    dispatchEvent(
      new CustomEvent(OPEN_ASSISTANT_FROM_INSIGHT_WIDGET, {
        detail: {
          message:
            prompt ??
            formatMessage(messages.insightPromptPreamble, {
              statements: statement.text.join(" "),
            }),
          widgetName,
        },
      }),
    );
  }
}

function InsightStatements({ arrow, text }: AiAssistantInsightWidgetStatement) {
  let partOne, partTwo;
  if (arrow?.value && arrow.direction && arrow.impact) {
    [partOne, partTwo] = text[0].split(arrow.value);
  }

  return (
    <div className={styles.copyContainer}>
      <Heading level={4}>
        <span className={styles.sparklesIcon}>
          <Icon name="sparkles" size="small" />
        </span>
        {partOne && partTwo && arrow?.value ? (
          <span>
            {partOne}
            <span className={styles.valueHighlight}>
              <span
                className={
                  arrow.impact === AiAssistantInsightImpact.POSITIVE
                    ? styles.greenArrow
                    : styles.redArrow
                }
              >
                {arrow.direction === AiAssistantInsightDirection.UP
                  ? "\u2191"
                  : "\u2193"}
              </span>
              {arrow.value}
            </span>
            {partTwo}
          </span>
        ) : (
          text[0]
        )}
      </Heading>
      <Typography size={"small"}>
        {text.length > 1 && <span>{text[1]} </span>}
      </Typography>
    </div>
  );
}
