import { gql } from "@apollo/client";

export const EMAIL_CAMPAIGNS_FEATURE_QUERY = gql`
  query EmailCampaignsFeatureQuery {
    feature(key: "email_campaigns") {
      enabled
      available
    }
  }
`;
