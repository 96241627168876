import { gql } from "@apollo/client";

export const SET_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
  mutation PaymentMethodSetAsDefault(
    $clientId: EncodedId!
    $input: PaymentMethodSetAsDefaultInput!
  ) {
    paymentMethodSetAsDefault(clientId: $clientId, input: $input) {
      paymentMethod {
        ... on BankAccountPaymentMethod {
          __typename
          bankName
          defaultMethod
          fingerprint
          last4
          uuid
        }
        ... on CreditCardPaymentMethod {
          __typename
          brand
          defaultMethod
          expired
          expiringSoon
          expiryYear
          expiryMonth
          fingerprint
          uuid
          last4
        }
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD_MUTATION = gql`
  mutation PaymentMethodDelete(
    $clientId: EncodedId!
    $input: PaymentMethodDeleteInput!
  ) {
    paymentMethodDelete(clientId: $clientId, input: $input) {
      paymentMethod {
        ... on BankAccountPaymentMethod {
          __typename
          bankName
          defaultMethod
          fingerprint
          last4
          uuid
        }
        ... on CreditCardPaymentMethod {
          __typename
          brand
          defaultMethod
          expired
          expiringSoon
          expiryYear
          expiryMonth
          fingerprint
          uuid
          last4
        }
      }
      userErrors {
        message
        path
      }
    }
  }
`;
