import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components/Banner";
import { Text } from "@jobber/components/Text";
import {
  WorkObject,
  type WorkObjectGlobalOwnershipFragment,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { OwnershipSection } from "./OwnershipSection";
import { useGlobalOwnership } from "./useGlobalOwnership";

interface GlobalOwnershipModalProps {
  ownerships: WorkObjectGlobalOwnershipFragment[];
}

export function GlobalOwnershipModal({
  ownerships,
}: GlobalOwnershipModalProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  function handleEdit() {
    setModalOpen(true);
  }
  function handleCloseModal() {
    setModalOpen(false);
  }

  const { request, quote, job, invoice, dispatch, save, loading, error } =
    useGlobalOwnership(ownerships);

  function handleSave() {
    save()
      .then(() => {
        handleCloseModal();
      })
      .catch((err: Error) => {
        Rollbar.EXECUTE("Failed saving global ownerships", err);
      });
  }
  return (
    <>
      <Button label="Edit" type="tertiary" onClick={handleEdit} />
      <Modal
        open={isModalOpen}
        title={"Email replies and notifications"}
        onRequestClose={handleCloseModal}
        primaryAction={{
          label: loading ? "Saving…" : "Save",
          onClick: handleSave,
          loading: loading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: handleCloseModal,
        }}
      >
        <Content>
          {error && (
            <Banner type="error">
              Could not save changes, please try again later
            </Banner>
          )}
          <Text>
            {`Direct all client replies and notifications for a workflow to the
              original sender, or assign team members to be responsible.`}
          </Text>
          <OwnershipSection
            ownership={request}
            type={WorkObject.REQUEST}
            onChange={dispatch}
          />
          <OwnershipSection
            ownership={quote}
            type={WorkObject.QUOTE}
            onChange={dispatch}
          />
          <OwnershipSection
            ownership={job}
            type={WorkObject.JOB}
            onChange={dispatch}
          />
          <OwnershipSection
            ownership={invoice}
            type={WorkObject.INVOICE}
            onChange={dispatch}
          />
        </Content>
      </Modal>
    </>
  );
}
