import type { ClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";
import {
  type AllClientsCriteriaInput,
  type PastClientsCriteriaInput,
  Segment,
  type UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";

export function isPastClientCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
  selectedSegmentType: Segment,
): criteria is PastClientsCriteriaInput {
  return (
    selectedSegmentType === Segment.PAST_CLIENTS &&
    !!criteria &&
    "interval" in criteria &&
    "unit" in criteria
  );
}

export function isAllClientCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
  selectedSegmentType: Segment,
): criteria is AllClientsCriteriaInput {
  return selectedSegmentType === Segment.ALL_CLIENTS;
}

export function isUpcomingClientCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
  selectedSegmentType: Segment,
): criteria is UpcomingClientsCriteriaInput {
  return (
    selectedSegmentType === Segment.UPCOMING_CLIENTS &&
    !!criteria &&
    "interval" in criteria &&
    "unit" in criteria
  );
}
