import moment from "moment";
// eslint-disable-next-line import/namespace, import/no-deprecated, import/no-internal-modules
import type { Payload } from "recharts/types/component/DefaultTooltipContent";

export function getDateLabel(time: string | number | undefined) {
  if (time && Date.parse(time as string)) {
    return moment(time).format("MMM DD, YYYY");
  } else {
    return time;
  }
}

export function formatTooltipContent(
  allData: Payload<React.ReactText, React.ReactText>[],
  index: number,
  currencySymbol: string,
  amountDatakey?: string,
) {
  const data = allData[index];

  if (!data) {
    return "";
  }

  if (amountDatakey) {
    return `${data.name} ${
      data.payload[data.dataKey as string]
    } worth ${currencySymbol}${data.payload[amountDatakey]}`;
  } else {
    return `${data.name} ${data.payload[data.dataKey as string]}`;
  }
}
