import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sendNowTitle: {
    id: "campaigns.sendNow.title",
    defaultMessage: "Send now?",
    description: "Send Now modal title label",
  },
  sendNowText: {
    id: "campaigns.sendNow.text",
    defaultMessage:
      "This campaign is scheduled for {date} at {time}. Are you sure you want to send it immediately instead?",
    description: "Send now message label",
  },
  sendNowButtonLabel: {
    id: "campaigns.sendNow.buttonLabel",
    defaultMessage: "Send Now",
    description: "Confirm button label",
  },
  sendNowError: {
    id: "campaigns.sendNow.error",
    defaultMessage: "An error occurred while sending the campaign",
    description: "Error message label",
  },
});
