import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { FilterDropdownProps } from "jobber/workOrders/types";
import styles from "./SortFilter.module.css";

const options = ["Status", "Job number", "First name", "Last name"];

export const SortFilter = ({ value, onChangeFilters }: FilterDropdownProps) => {
  const onChange = (term: string) => onChangeFilters({ sortBy: term });

  return (
    <div className={styles.dropdown}>
      <Select
        name="type_filter"
        placeholder="Type"
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};
