import * as React from "react";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { resolvePostalCodeLine } from "~/bunker/paymentMethodForm/components/BillingAddress/utils/resolvePostalCodeLine";

export interface BillingAddressDisplayProps extends BillingAddress {
  emptyMessage?: string;

  onChangeButtonClick?(): void;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function BillingAddressDisplay(
  props: BillingAddressDisplayProps,
) {
  const {
    street1,
    street2,
    city,
    pc,
    province,
    onChangeButtonClick,
    emptyMessage = "No address provided",
  } = props;
  const postalCodeLine = React.useMemo(
    () => resolvePostalCodeLine({ city, pc, province }),
    [city, pc, province],
  );
  const isEmpty = React.useMemo(
    () => [street1, street2, city, postalCodeLine].every(str => !str),
    [street1, street2, city, postalCodeLine],
  );

  return (
    <>
      <div className="u-colorGreyBlue">
        {isEmpty ? (
          <em>{emptyMessage}</em>
        ) : (
          <>
            <div>{street1}</div>
            <div>{street2}</div>
            <div>{postalCodeLine}</div>
          </>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code. */}
      <span
        aria-label="Edit Button"
        className="textAction"
        role="button"
        onClick={onChangeButtonClick}
      >
        Change
      </span>
    </>
  );
}
