import { defineMessages } from "react-intl";

export const messages = defineMessages({
  privacyPolicyTermsOfServiceLinksPrefix: {
    id: "checkout.checkoutSummary.privacyPolicyTermsOfServiceLinksPrefix",
    defaultMessage: "By continuing you are agreeing to our",
    description:
      "Prefix to the message informing user of the acceptance of Privacy Policy and Terms of Service",
  },
  privacyPolicy: {
    id: "checkout.checkoutSummary.privacyPolicy",
    defaultMessage: "Privacy Policy",
    description: "Privacy Policy link text",
  },
  and: {
    id: "checkout.checkoutSummary.and",
    defaultMessage: "and",
    description: "and a.k.a. &",
  },
  termsOfService: {
    id: "checkout.checkoutSummary.termsOfService",
    defaultMessage: "Terms of Service",
    description: "Terms of Service link text",
  },
});
