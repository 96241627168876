import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import React from "react";
import { Icon } from "@jobber/components/Icon";
import { messages } from "./messages";
import styles from "./BusinessPriority.module.css";
import { NumberRoller } from "./components";
import { AnimatedStep } from "../AnimatedStep";

interface BusinessPriorityProps {
  businessPriority?: string;
  onFinishedAnimation?(): void;
  onFadeOutStart?(): void;
}

export enum BusinessPriorityState {
  GetPaidFaster = "getPaidFaster",
  WinMoreWork = "winMoreWork",
  IncreaseEfficiency = "increaseEfficiency",
  NoGoalSelected = "noGoalSelected",
}

export function BusinessPriority({
  businessPriority,
  onFinishedAnimation,
  onFadeOutStart,
}: BusinessPriorityProps) {
  const businessPriorityState: BusinessPriorityState = (() => {
    switch (businessPriority) {
      case "Get paid faster":
        return BusinessPriorityState.GetPaidFaster;
      case "Win more work":
        return BusinessPriorityState.WinMoreWork;
      case "Increase efficiency":
        return BusinessPriorityState.IncreaseEfficiency;
      default:
        return BusinessPriorityState.NoGoalSelected;
    }
  })();

  const rollerContent = () => {
    switch (businessPriorityState) {
      case BusinessPriorityState.NoGoalSelected:
      case BusinessPriorityState.IncreaseEfficiency:
        return (
          <NumberRoller
            rollToNumber={messages[businessPriorityState].slideIn.rollToNumber}
          />
        );
      case BusinessPriorityState.GetPaidFaster:
        return (
          <>
            <NumberRoller
              rollToNumber={
                messages[businessPriorityState].slideIn.rollToNumber
              }
            />
            <span>x</span>
          </>
        );
      case BusinessPriorityState.WinMoreWork:
        return (
          <>
            <div className={styles.arrowIconWrapper}>
              <Icon name="longArrowUp" size="large" />
            </div>
            <NumberRoller
              rollToNumber={
                messages[businessPriorityState].slideIn.rollToNumber
              }
            />
            <span>%</span>
          </>
        );
    }
  };

  const slideInContent = (
    <Heading level={2}>
      <span className={`${styles.valueMetric} slideInElement`}>
        {rollerContent()}&nbsp;
        <span className={styles.valueMetricMessage}>
          {messages[businessPriorityState].slideIn.valueMetric}
        </span>
      </span>
    </Heading>
  );

  const headerContent = (
    <Heading level={3}>
      <div className={`${styles.header} slideOutElement`}>
        <Emphasis variation="highlight">
          {messages[businessPriorityState].header.emphasisText}
        </Emphasis>
        <span>
          &nbsp;{messages[businessPriorityState].header.secondaryText}
        </span>
      </div>
    </Heading>
  );

  return (
    <AnimatedStep
      slideInContent={slideInContent}
      headerContent={headerContent}
      onFinishedAnimation={onFinishedAnimation}
      onFadeOutStart={onFadeOutStart}
      totalAnimationTime={4250}
    />
  );
}
