import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recipientsLabel: {
    id: "campaigns.campaignsbreadcrumb.recipientsLabel",
    defaultMessage: "Recipients",
    description: "Recipients label",
  },
  contentLabel: {
    id: "campaigns.campaignsbreadcrumb.contentLabel",
    defaultMessage: "Content",
    description: "Content label",
  },
  reviewLabel: {
    id: "campaigns.campaignsbreadcrumb.reviewLabel",
    defaultMessage: "Review",
    description: "Review label",
  },
  sendATestLabel: {
    id: "campaigns.campaignsbreadcrumb.sendATestLabel",
    defaultMessage: "Send a Test",
    description: "Send a Test label",
  },
  templateLabel: {
    id: "campaigns.campaignsbreadcrumb.templatesLabel",
    defaultMessage: "Template",
    description: "Template label",
  },
});
