import { gql } from "@apollo/client";

export const INTERCOM_CONVERSATION_CREATE = gql`
  mutation IntercomConversationCreate(
    $input: IntercomConversationCreateInput!
  ) {
    intercomConversationCreate(input: $input) {
      conversationId
    }
  }
`;
