import React from "react";
import type { ManualPagination } from "@jobber/components/DataTable";
import type { ReportProps } from "~/jobber/features/Reporting/components/Report/types";
import { Report } from "~/jobber/features/Reporting/components/Report/Report";
import { REPORT_HEADER } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/constants";
import { useInvoiceDetailColumns } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/hooks/useInvoiceDetailColumns";
import type { InvoiceDetailData, InvoiceDetailTotalsData } from "./types";

export interface InvoiceDetailReportProps extends ReportProps {
  data: InvoiceDetailData[] | undefined;
  totalsData: InvoiceDetailTotalsData | undefined;
  paginationProps: ManualPagination;
}

export function InvoiceDetailReport({
  data,
  totalsData,
  loading,
  error,
  paginationProps,
  filterProps,
  sortingProps,
  exportProps,
}: InvoiceDetailReportProps) {
  const invoiceDetailColumns = useInvoiceDetailColumns(totalsData);

  return (
    <Report
      data={data}
      // @ts-ignore TODO: https://jobber.atlassian.net/browse/JOB-68173.
      columns={invoiceDetailColumns}
      title={REPORT_HEADER}
      loading={loading}
      error={error}
      pagination={paginationProps}
      filterProps={filterProps}
      exportProps={exportProps}
      sorting={sortingProps}
    />
  );
}
