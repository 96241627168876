import { gql } from "@apollo/client";

export const SCHEDULING_AVAILABILITY_QUERY = gql`
  query SchedulingAssistantAvailability($input: SchedulingAvailabilityInput!) {
    schedulingAvailability(input: $input) {
      schedulingRecommendations {
        recommendedStartAt
        recommendedEndAt
        driveTimeTo
        driveTimeFrom
        recommendedAssignee {
          id
        }
      }
      userErrors {
        message
        path
      }
    }
  }
`;
