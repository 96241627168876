/* eslint-disable import/no-internal-modules */
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { Button } from "@jobber/components/Button";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Banner } from "@jobber/components/Banner";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { Spinner } from "@jobber/components/Spinner";
import { Glimmer } from "@jobber/components/Glimmer";
import { Markdown } from "@jobber/components/Markdown";
import { Emphasis } from "@jobber/components/Emphasis";
import {
  e164PhoneFormat,
  friendlyPhoneFormat,
} from "legacy/utilities/format/phone";
import type { CompanyDetailsForGoogleQuery } from "~/utilities/API/graphql";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { COMPANY_DETAILS_FOR_GOOGLE } from "../../../../hooks/graphql";
import { useUpdateGoogleSharingSettings } from "../../../../hooks/useUpdateGoogleSharingSettings";

interface ShareToGoogleProfileButtonProps {
  type: React.ComponentProps<typeof Button>["type"];
  value: boolean;
  disabled: React.ComponentProps<typeof Button>["disabled"];
}

export function ShareToGoogleProfileButton({
  type,
  value,
  disabled,
}: ShareToGoogleProfileButtonProps) {
  const { formatMessage } = useIntl();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [unshareModalOpen, setUnshareModalOpen] = useState(false);
  const {
    editGoogleSharingSettings,
    loading: saving,
    error: savingError,
  } = useUpdateGoogleSharingSettings();

  const [loadCompanyDetails, { data, loading, error }] =
    useLazyQuery<CompanyDetailsForGoogleQuery>(COMPANY_DETAILS_FOR_GOOGLE, {
      fetchPolicy: "cache-and-network",
    });

  const validCompanyDetails = !!(
    data?.account?.companyDetails.name && data?.account?.companyDetails.address
  );

  const errorMessage =
    (error && formatMessage(messages.connectModalLoadError)) ||
    (savingError && formatMessage(messages.connectModalSaveError));

  const ButtonComponent = value ? (
    <Button
      label={formatMessage(messages.sharedToGoogleProfile)}
      type="secondary"
      icon="checkmark"
      loading={saving}
      disabled={saving || disabled}
      onClick={() => setUnshareModalOpen(true)}
    />
  ) : (
    <Button
      label={formatMessage(messages.shareToGoogleProfile)}
      type={type}
      loading={saving}
      disabled={saving || disabled}
      onClick={() => {
        void loadCompanyDetails();
        setShareModalOpen(true);
      }}
    />
  );

  const openCompanySettings = useCallback(() => {
    window.open("/accounts/edit", "_blank", "noopener, noreferrer");
    setShareModalOpen(false);
  }, []);

  const companyDataList = data?.account && [
    {
      key: formatMessage(messages.connectModalBusinessName),
      value: data.account.companyDetails.name,
      required: true,
    },
    {
      key: formatMessage(messages.connectModalLocation),
      value: data.account.companyDetails.address,
      required: true,
    },
    {
      key: formatMessage(messages.connectModalPhone),
      value: normalizedPhoneNumber(
        data.account.companyDetails.phone,
        data.account.settings?.localization.countryCode,
      ),
    },
    {
      key: formatMessage(messages.connectModalWebsite),
      value: data.account.companyDetails.websiteUrl,
    },
  ];

  return (
    <>
      {ButtonComponent}
      <Modal
        open={shareModalOpen}
        title={formatMessage(messages.connectModalTitle)}
        onRequestClose={() => setShareModalOpen(false)}
        primaryAction={{
          label: formatMessage(messages.connectModalPrimaryAction),
          loading: loading || saving,
          disabled: !validCompanyDetails || saving,
          onClick: async () => {
            await editGoogleSharingSettings({ enabled: true }).then(() => {
              setShareModalOpen(false);
            });
          },
        }}
        secondaryAction={{
          label: formatMessage(messages.connectModalSecondaryAction),
          onClick: openCompanySettings,
        }}
      >
        <Content>
          {errorMessage && (
            <Banner type="error" dismissible={false}>
              {errorMessage}
            </Banner>
          )}
          {!validCompanyDetails && !loading && (
            <Banner
              type="error"
              dismissible={false}
              primaryAction={{
                label: "Add",
                onClick: openCompanySettings,
                type: "secondary",
              }}
            >
              {formatMessage(messages.connectModalRequiredFieldsError)}
            </Banner>
          )}
          <Markdown
            content={formatMessage(messages.connectModalDescription)}
            externalLink
          />
          <DynamicDataList loading={loading} data={companyDataList} />
        </Content>
      </Modal>
      <ConfirmationModal
        open={unshareModalOpen}
        variation="destructive"
        title={formatMessage(messages.disconnectModalTitle)}
        confirmLabel={formatMessage(messages.disconnectModalPrimaryAction)}
        message={formatMessage(messages.disconnectModalDescription)}
        onConfirm={() => editGoogleSharingSettings({ enabled: false })}
        onRequestClose={() => setUnshareModalOpen(false)}
      />
    </>
  );
}

function normalizedPhoneNumber(
  unstructuredPhoneNumber?: string,
  countryCode?: string,
) {
  try {
    return friendlyPhoneFormat(
      e164PhoneFormat(unstructuredPhoneNumber ?? "", countryCode),
      countryCode,
    );
  } catch {
    // The phone number didn't survive the round-trip through the phone number formatter
    // Google won't accept it, so we'll just return undefined is if it's not set
    return undefined;
  }
}

interface DynamicDataListProps {
  loading: boolean;
  data?: Array<{
    key: string;
    value: string | undefined;
    required?: boolean;
  }>;
}

function DynamicDataList({ loading, data }: DynamicDataListProps) {
  const { formatMessage } = useIntl();
  const dataElements = data
    ?.filter(({ value, required }) => !!value || required)
    .map(({ key, value }) => (
      <React.Fragment key={key}>
        <dt>{key}</dt>
        <dd>
          {value ?? (
            <Emphasis variation="italic">
              {formatMessage(messages.connectModalRequiredFieldPlaceholder)}
            </Emphasis>
          )}
        </dd>
      </React.Fragment>
    ));

  return (
    <div className={styles.dynamicDataList}>
      {data ? <dl>{dataElements}</dl> : <Glimmer.Text lines={3} />}
      {loading && data && (
        <span className={styles.dynamicDataListLoadingSpinner}>
          <Spinner size="small" />
        </span>
      )}
    </div>
  );
}
