import React from "react";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import classes from "./EditSmsBody.module.css";
import { SmsPreviewBubble } from "./SmsPreviewBubble";

/**
 * Absolute maximum number of allowed characters for text messages
 */
// export const DEFAULT_MAX_LENGTH = 800;
export const PRE_FOOTER_SPACE = "\n\n";

/**
 * Component props for [[EditSmsBody]] and [[EditSmsBodyInternal]]
 */
export interface PropsEditSmsBody {
  /** Indicates whether to show an editable control. Defaults to true */
  isEditable: boolean;
  /** Initial value of the sms message provided by the webserver */
  defaultBodyMessage?: string;
  /** Footer message, optional, not editable, often contains link to client hub */
  footerMessage?: string;
  /** Template URL */
  templateUrl?: string;
  /** Optional help text */
  helpText?: React.ReactNode;
  allowMagicField?: boolean;
}

/**
 * Component props for [[EditSmsBodyInternal]]
 */
interface PropsEditSmsBodyInternal {
  /** Sms message state currently be edited */
  message: string;
  /** Callback for updating the state of the message being edited. */
  onChangeMessage(message: string): void;
}

export const EditSmsBodyInternal = (
  props: PropsEditSmsBody & PropsEditSmsBodyInternal,
) => {
  const templateLink = props.templateUrl ? (
    <a href={props.templateUrl}>Customize your default templates</a>
  ) : undefined;

  const footer =
    props.message && props.footerMessage
      ? `${PRE_FOOTER_SPACE}${props.footerMessage}`
      : props.footerMessage;

  const helpText = props.helpText ? (
    <p className={classes.helpText}>{props.helpText}</p>
  ) : undefined;

  const handleOnChange = (text: string) => props.onChangeMessage(text);

  const messageLength = props.message.length + (footer?.length || 0);

  return (
    <div className={classes.container} data-testid="edit_sms_body">
      {props.isEditable && (
        <div>
          <MagicInputText
            disableMagicRewrite={!props.allowMagicField}
            placeholder="Message"
            data-testid="body_message"
            name={"body_message"}
            value={props.message}
            rows={4}
            multiline
            onChange={handleOnChange}
          />
          <div className={classes.footer}>
            {templateLink}
            <div>{messageLength}</div>
          </div>
        </div>
      )}
      <div>
        <SmsPreviewBubble>
          {props.message}
          {footer}
        </SmsPreviewBubble>
        {helpText}
      </div>
    </div>
  );
};

EditSmsBodyInternal.displayName = "EditSmsBody(Internal)";

/**
 * Input for editing and previewing sms messages before sending them to clients.
 */
export const EditSmsBody = ({
  isEditable = true,
  ...props
}: PropsEditSmsBody) => {
  const [message, setMessage] = React.useState(props.defaultBodyMessage || "");

  return (
    <EditSmsBodyInternal
      {...props}
      isEditable={isEditable}
      message={message}
      onChangeMessage={setMessage}
    />
  );
};

EditSmsBody.displayName = "EditSmsBody";
