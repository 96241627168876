import { useIntl } from "react-intl";
import type { IconNames } from "@jobber/components/Icon";
import { Template } from "~/utilities/API/graphql";
import { messages as sharedCampaignMessages } from "jobber/campaigns/messages";
import type { SelectableCardSimplified } from "jobber/campaigns/components/SelectableCards/SelectableCard/SelectableCard";
import { useCampaignAutomationsSplit } from "jobber/campaigns/hooks/useCampaignAutomationsSplit/useCampaignAutomationsSplit";
import { SplitNames, useFeatureFlag } from "utilities/split";

export interface TemplateCard {
  type: Template;
  name: string;
  description: string;
}

export function useOneOffTemplateOptions(
  hasAccessToReferrals: boolean,
): SelectableCardSimplified[] {
  const { formatMessage } = useIntl();
  const campaignsPleEnabled = useFeatureFlag(SplitNames.CampaignsPLE);
  return [
    {
      heading: formatMessage(sharedCampaignMessages.startFromScratchTitle),
      description: formatMessage(
        sharedCampaignMessages.startFromScratchDescription,
      ),
      value: Template.START_FROM_SCRATCH,
      checked: false,
      img: {
        icon: "edit" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(sharedCampaignMessages.specialOfferTitle),
      description: formatMessage(
        sharedCampaignMessages.specialOfferDescription,
      ),
      value: "", // TODO: add this template
      checked: false,
      img: {
        icon: "gift" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
      comingSoon: true,
    },
    {
      heading: formatMessage(sharedCampaignMessages.reEngagePastClientsTitle),
      description: formatMessage(
        sharedCampaignMessages.reEngagePastClientsDescription,
      ),
      value: Template.RE_ENGAGE,
      checked: true,
      img: {
        icon: "sync" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.reEngagePastClientsImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(sharedCampaignMessages.holidayTitle),
      description: formatMessage(sharedCampaignMessages.holidayDescription),
      value: Template.HOLIDAYS,
      checked: false,
      img: {
        icon: "star" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(sharedCampaignMessages.announcementTitle),
      description: formatMessage(
        sharedCampaignMessages.announcementDescription,
      ),
      value: "", // TODO: add this template
      checked: false,
      img: {
        icon: "marketing" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
      comingSoon: true,
    },
    {
      heading: formatMessage(sharedCampaignMessages.seasonalPromotionTitle),
      description: formatMessage(
        sharedCampaignMessages.seasonalPromotionDescription,
      ),
      value: Template.SEASONAL_PROMOTION,
      checked: false,
      img: {
        icon: "sync" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(sharedCampaignMessages.upSellCustomersTitle),
      description: formatMessage(
        sharedCampaignMessages.upSellCustomersDescription,
      ),
      value: Template.UPSELL,
      checked: false,
      img: {
        icon: "longArrowUp" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.upSellCustomersImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(sharedCampaignMessages.referralTitle),
      description: formatMessage(sharedCampaignMessages.referralDescription),
      value: Template.REFERRAL,
      checked: false,
      img: {
        icon: "thumbsUp" as IconNames,
        alternativeText: formatMessage(sharedCampaignMessages.referralImgAlt),
      },
      referral: true,
    },
    {
      heading: formatMessage(sharedCampaignMessages.leadTitle),
      description: formatMessage(sharedCampaignMessages.leadDescription),
      value: Template.LEAD,
      checked: false,
      img: {
        icon: "redo" as IconNames,
        alternativeText: formatMessage(
          sharedCampaignMessages.startFromScratchImgAlt,
        ),
      },
    },
  ].filter(option => {
    // filter out the referral option if the user doesn't have access to referrals
    if (option.value !== Template.REFERRAL && option.value !== Template.LEAD) {
      return true;
    } else if (option.value == Template.LEAD) {
      return campaignsPleEnabled;
    } else {
      return hasAccessToReferrals;
    }
  });
}

export function useAutomatedTemplateOptions(): SelectableCardSimplified[] {
  const { formatMessage } = useIntl();
  const { isInCampaignAutomationsV2 } = useCampaignAutomationsSplit();
  return [
    {
      heading: formatMessage(
        sharedCampaignMessages.automatedReEngagePastClientsTitle,
      ),
      description: formatMessage(
        sharedCampaignMessages.automatedReEngagePastClientsDescription,
      ),
      value: Template.AUTOMATED_RE_ENGAGE,
      checked: false,
      img: {
        icon: "sync",
        alternativeText: formatMessage(
          sharedCampaignMessages.automatedReEngagePastClientsImgAlt,
        ),
      },
    },
    {
      heading: formatMessage(
        sharedCampaignMessages.automatedWinBackLostLeadsTitle,
      ),
      description: formatMessage(
        sharedCampaignMessages.automatedWinBackLostLeadsDescription,
      ),
      value: Template.AUTOMATED_WIN_BACK_LOST_LEADS,
      checked: false,
      img: {
        icon: "redo",
        alternativeText: formatMessage(
          sharedCampaignMessages.automatedWinBackLostLeadsImgAlt,
        ),
      },
      comingSoon: !isInCampaignAutomationsV2,
    },
    //TODO: Update coming soon to false as part of JOB-107190
    {
      heading: formatMessage(
        sharedCampaignMessages.automatedCloseOnPendingQuotesTitle,
      ),
      description: formatMessage(
        sharedCampaignMessages.automatedCloseOnPendingQuotesDescription,
      ),
      value: Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES,
      checked: false,
      img: {
        icon: "quote",
        alternativeText: formatMessage(
          sharedCampaignMessages.automatedCloseOnPendingQuotesImgAlt,
        ),
      },
    },
  ];
}
