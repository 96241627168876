import SetupGuideAddBranding from "@images/SetupGuideAddBranding.svg";
import SetupGuideDownloadAppQRCode from "@images/SetupGuideDownloadAppQRCode.svg";
import SetupGuideAddClient from "@images/SetupGuideAddClient.svg";
import SetupGuideAddTeamUser from "@images/SetupGuideAddTeamUser.svg";
import type { SetupGuidePanelProps } from "jobber/dashboard/components/SetupGuide/components/SetupGuidePanel";
import { messages } from "jobber/dashboard/components/SetupGuide/messages";
import { trackInteractedWithExperiment } from "~/utilities/analytics/trackInteractedWithExperiment";

export const LARGE_SCREEN_WIDTH = 1080;
export const MOBILE_SCREEN_WIDTH = 414;
export const SMALL_SCREEN_WIDTH = 768;

export const ADD_BRANDING_GUIDE: SetupGuidePanelProps = {
  img: {
    src: SetupGuideAddBranding as string,
    altText: messages.addBrandingGuideSubHeading.defaultMessage,
  },
  content: {
    heading: messages.addBrandingGuideHeading.defaultMessage,
    subheading: messages.addBrandingGuideSubHeading.defaultMessage,
    buttonLabel: messages.addBrandingGuideButtonLabel.defaultMessage,
    buttonOnPress: () => {
      trackInteractedWithExperiment({
        experiment: "Setup Guide",
        interaction: "Clicked customize your branding",
        source: "content button",
      });
      window.location.href = "/work_configuration/edit";
    },
  },
};

export const ADD_CLIENT_GUIDE: SetupGuidePanelProps = {
  img: {
    src: SetupGuideAddClient as string,
    altText: messages.addClientGuideSubHeading.defaultMessage,
  },
  content: {
    heading: messages.addClientGuideHeading.defaultMessage,
    subheading: messages.addClientGuideSubHeading.defaultMessage,
    buttonLabel: messages.addClientGuideButtonLabel.defaultMessage,
    buttonOnPress: () => {
      trackInteractedWithExperiment({
        experiment: "Setup Guide",
        interaction: "Clicked to add clients",
        source: "content button",
      });
      window.location.href = "/clients";
    },
  },
};

export const APP_DOWNLOAD_GUIDE: SetupGuidePanelProps = {
  img: {
    src: SetupGuideDownloadAppQRCode as string,
    altText: messages.appDownloadGuideSubHeading.defaultMessage,
  },
  content: {
    heading: messages.appDownloadGuideHeading.defaultMessage,
    subheading: messages.appDownloadGuideSubHeading.defaultMessage,
    buttonLabel: messages.appDownloadGuideButtonLabel.defaultMessage,
  },
};

export const ADD_TEAM_USER_GUIDE: SetupGuidePanelProps = {
  img: {
    src: SetupGuideAddTeamUser as string,
    altText: messages.addTeamUserGuideSubHeading.defaultMessage,
  },
  content: {
    heading: messages.addTeamUserGuideHeading.defaultMessage,
    subheading: messages.addTeamUserGuideSubHeading.defaultMessage,
    buttonLabel: messages.addTeamUserGuideButtonLabel.defaultMessage,
    buttonOnPress: () => {
      trackInteractedWithExperiment({
        experiment: "Setup Guide",
        interaction: "Clicked invite a team member",
        source: "content button",
      });
      window.location.href = "/manage_team";
    },
  },
};

export const JOBBER_GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.getjobber.jobber&hl=en_CA&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const GOOGLE_PLAY_BADGE_LINK =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";
export const JOBBER_APP_STORE_LINK =
  "https://apps.apple.com/us/app/jobber/id1014146758?itsct=apps_box_badge&amp;itscg=30200%22";
export const APP_STORE_BADGE_LINK =
  "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1440115200";
