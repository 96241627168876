const uuidV4 =
  "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$)";
const signedID = "([a-zA-Z0-9-_=]+--[a-zA-Z0-9-_=]+$)";
const integerID = "([0-9]+?$)";

export function decodeId(id: string): string {
  const decodedId = atob(id);

  const parseIDRegEx = new RegExp(`${signedID}|${uuidV4}|${integerID}`, "gm");
  const parsedId = decodedId.match(parseIDRegEx);

  if (parsedId === undefined || parsedId?.length !== 1) {
    throw new Error(`ID was not decoded properly. id: ${id}`);
  }
  return parsedId[0];
}
