import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Amplitude } from "~/utilities/analytics/Amplitude";

interface FreemiumErrorBannerProps {
  /** freemium Error message for email */
  freemiumMessage: string;
  /** Path to be navigated */
  redirectURL: string;
  /** Jobber Analytics source for error */
  jaSource: string;
}

/* Freemium Error Banner Component */
export function FreemiumErrorBanner({
  freemiumMessage,
  redirectURL,
  jaSource,
}: FreemiumErrorBannerProps) {
  function handleOnClick() {
    Amplitude.TRACK_EVENT("Clicked Upgrade Now", {
      source: jaSource,
    });
    window.location.href = redirectURL;
  }
  if (freemiumMessage) {
    return (
      <Banner
        type="error"
        primaryAction={{
          label: "Upgrade Now",
          onClick: () => {
            handleOnClick();
          },
        }}
        dismissible={false}
      >
        {freemiumMessage}
      </Banner>
    );
  } else {
    return <></>;
  }
}
