import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { strFormatDate } from "@jobber/components/FormatDate";
import { Markdown } from "@jobber/components/Markdown";
import { Text } from "@jobber/components/Text";
import classnames from "classnames";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import {
  type DisputeType,
  hasIssuerEvidence,
} from "jobber/features/PaymentDisputes/utils/disputeUtils";
import { formatCurrency } from "utilities/formatCurrency";
import { DisputeCategory } from "~/utilities/API/graphql";
import styles from "./RespondToDispute.module.css";
import { messages } from "./messages";
import { HELP_CENTER_URL } from "../../constants";
import { SupportingFileLink } from "../SupportingFileLink";

interface RespondToDisputeProps {
  dispute: NonNullable<DisputeType>;
  onCounterDisputeClick: () => void;
  onAcceptDisputeClick: () => void;
  onViewClaimDetailsClick: () => void;
  setAcceptError: (error: string) => void;
  isLoading: boolean;
}

// eslint-disable-next-line max-statements
export function RespondToDispute({
  dispute,
  onCounterDisputeClick,
  onAcceptDisputeClick,
  onViewClaimDetailsClick,
  isLoading,
}: RespondToDisputeProps) {
  const { formatMessage } = useIntl();

  const category = dispute.category;
  const [modalOpen, setModalOpen] = useState(false);

  const evidenceDueByDateText = dispute.evidenceDueBy
    ? strFormatDate(new Date(dispute.evidenceDueBy), true)
    : null;

  const amount = formatCurrency(dispute.amount, "$");

  const renderReviewClaimDetails = () => {
    return (
      <li key={1}>
        <div className={classnames(styles.reviewClaimDetailsStep)}>
          <SupportingFileLink
            customClassName={classnames(styles.reviewClaimDetailsContainer)}
            onViewSupportingFileClick={onViewClaimDetailsClick}
            supportingFileLinkText={formatMessage(messages.reviewClaimDetails)}
            ariaLabel={formatMessage(messages.reviewClaimDetailsA11y)}
          />
          <span>{formatMessage(messages.fromCardholderIssuingBank)}</span>
        </div>
      </li>
    );
  };

  const createListItem = (index: number, content: string): JSX.Element => {
    return (
      <li key={index}>
        <Markdown content={content} />
      </li>
    );
  };

  const renderListItems = (): JSX.Element[] => {
    const listItems: JSX.Element[] = [];

    if (hasIssuerEvidence(dispute)) {
      listItems.push(renderReviewClaimDetails());
    }

    if (evidenceDueByDateText) {
      if (category === DisputeCategory.CHARGEBACK) {
        listItems.push(
          createListItem(
            2,
            formatMessage(messages.chargebackParagraphTwoListTwo),
          ),
        );
        listItems.push(
          createListItem(
            3,
            formatMessage(messages.chargebackParagraphTwoListThree),
          ),
        );
        listItems.push(
          createListItem(
            4,
            formatMessage(messages.chargebackParagraphTwoListFour, {
              date: evidenceDueByDateText,
            }),
          ),
        );
      } else {
        listItems.push(
          createListItem(2, formatMessage(messages.inquiryParagraphTwoListTwo)),
        );
        listItems.push(
          createListItem(
            3,
            formatMessage(messages.inquiryParagraphTwoListThree),
          ),
        );
        listItems.push(
          createListItem(
            4,
            formatMessage(messages.inquiryParagraphTwoListFour, {
              date: evidenceDueByDateText,
            }),
          ),
        );
      }
    }

    return listItems;
  };

  const acceptDisputeClick = () => {
    if (dispute.category === DisputeCategory.INQUIRY) {
      onAcceptDisputeClick();
    } else {
      toggleModal();
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const learnMoreMessage =
    formatMessage(messages.learnMore) + `(${HELP_CENTER_URL}).`;

  return (
    <Card
      header={formatMessage(messages.header, {
        category: category.toLowerCase(),
      })}
    >
      <Modal
        title={formatMessage(messages.modalTitle)}
        open={modalOpen}
        size="small"
        primaryAction={{
          label: formatMessage(messages.chargebackResolveButton),
          onClick: onAcceptDisputeClick,
          loading: isLoading,
        }}
        secondaryAction={{
          label: formatMessage(messages.cancelButton),
          onClick: toggleModal,
        }}
        onRequestClose={toggleModal}
      >
        <Content>
          <Text>{formatMessage(messages.contentParagraphOne)}</Text>
          <Text>
            {formatMessage(messages.contentParagraphTwo, { amount: amount })}
          </Text>
        </Content>
      </Modal>
      <Content>
        <Text maxLines="large">
          {formatMessage(
            category === DisputeCategory.CHARGEBACK
              ? messages.chargebackParagraphOne
              : messages.inquiryParagraphOne,
            { amount: amount },
          )}
        </Text>
        <ol className={classnames(styles.stepsList)}>{renderListItems()}</ol>

        <div className={classnames(styles.learnMore)}>
          <Markdown content={learnMoreMessage} externalLink={true} />
        </div>

        <div className={styles.acceptDispute}>
          <Button
            label={formatMessage(
              category === DisputeCategory.CHARGEBACK
                ? messages.chargebackResolveButton
                : messages.inquiryResolveButton,
            )}
            type="secondary"
            onClick={acceptDisputeClick}
          />
          <Button
            label={formatMessage(
              category === DisputeCategory.CHARGEBACK
                ? messages.chargebackRespondButton
                : messages.inquiryRespondButton,
            )}
            onClick={onCounterDisputeClick}
          />
        </div>
      </Content>
    </Card>
  );
}
