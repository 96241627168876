import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { Timezone, TimezoneSelectorProps } from "./TimezoneSelector.d";

const EMPTY_TIMEZONE: Timezone = {
  key: "",
  offset: "",
  name: "",
};

export function TimezoneSelector({
  timezone,
  timezones,
  onChange,
}: TimezoneSelectorProps) {
  const defaultTimezone = getDefaultTimezone();
  return (
    <Select
      onChange={onChange}
      defaultValue={defaultTimezone.key}
      name="timezone"
    >
      {timezones &&
        timezones.map((tz, index) => {
          return (
            <Option value={tz.key} key={index}>
              (GMT{tz.offset}) {tz.name}
            </Option>
          );
        })}
    </Select>
  );

  function getDefaultTimezone(): Timezone {
    if (!timezone && timezones && timezones[0]) {
      const timezoneOffset = new Date().getTimezoneOffset() / 60;
      const nonDstOffset = isDstObserved()
        ? timezoneOffset + 1
        : timezoneOffset;

      return (
        timezones.find(tz => {
          const offsetSplit: string[] = tz.offset.split(":");
          const offsetHour: string = offsetSplit[0];
          const offsetMin: string = offsetSplit[1] || "";

          return (
            Number(offsetHour) === nonDstOffset * -1 &&
            Number(offsetMin) === nonDstOffset % 1
          );
        }) || timezones[0]
      );
    } else if (timezone) {
      const match = timezones.find(tz => {
        return tz.key === timezone;
      });
      if (match) {
        return match;
      }
    }

    return EMPTY_TIMEZONE;
  }

  function isDstObserved(): boolean {
    const currentTimezoneOffset: number = new Date().getTimezoneOffset();
    return currentTimezoneOffset < getStandardTimezoneOffset();
  }

  function getStandardTimezoneOffset(): number {
    const fullYear: number = new Date().getFullYear();
    const jan: Date = new Date(fullYear, 0, 1);
    const jul: Date = new Date(fullYear, 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }
}
