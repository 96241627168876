import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import { InputValidation } from "@jobber/components/InputValidation";
import { Option, Select } from "@jobber/components/Select";
import classNames from "classnames";
import React from "react";
import {
  SOLE_PROPRIETORSHIP,
  businessTypesList,
} from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationBusinessInformation/RegistrationBusinessInformationData";
import type { TwilioRegistrationFormReducerState } from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import { BusinessRegistrationNumberForm } from "jobber/settings/dedicatedPhoneNumber/components/businessRegistrationNumberForm/BusinessRegistrationNumberForm";
import {
  businessRegistrationNumberErrors,
  hasTaxIdRegistrationError,
} from "jobber/settings/dedicatedPhoneNumber/utilities/businessRegistrationNumberHelpers";
import styles from "../../TwilioRegistrationForm.module.css";

interface RegistrationBusinessInformationProps {
  state: TwilioRegistrationFormReducerState;
  formSubmitted?: boolean;
  handleUpdate(data: Partial<TwilioRegistrationFormReducerState>): void;
}

interface BusinessInformationKeys {
  businessName?: string;
  businessType?: string;
}

export const registrationBusinessInformationErrorMessages = {
  businessName: "Legal business name is required",
  businessType: "Business type is required",
};

export function RegistrationBusinessInformation({
  state,
  handleUpdate,
  formSubmitted,
}: RegistrationBusinessInformationProps) {
  const isTaxIdErrorPresent = hasTaxIdRegistrationError(state.userErrors);
  function isInvalid(field: keyof BusinessInformationKeys) {
    return formSubmitted && !state[field];
  }

  function getFormErrors(field: keyof BusinessInformationKeys) {
    return (
      isInvalid(field) && (
        <InputValidation
          message={registrationBusinessInformationErrorMessages[field]}
        />
      )
    );
  }

  function handleBusinessTypeOnChange(value: string) {
    if (value === SOLE_PROPRIETORSHIP) {
      handleUpdate({ businessRegistrationNumber: "" });
    }
    handleUpdate({ businessType: value });
  }

  return (
    <>
      <Heading level={5}>Business details</Heading>
      <div className={classNames(styles.gridContainer, styles.inputField)}>
        <div className={styles.flexBasis}>
          <InputText
            placeholder="Legal business name"
            name="businessName"
            value={state.businessName}
            invalid={isInvalid("businessName") || isTaxIdErrorPresent}
            onChange={(value: string) => handleUpdate({ businessName: value })}
          />
          {getFormErrors("businessName")}
        </div>

        <div className={styles.flexBasis}>
          <Select
            placeholder={"Business type"}
            onChange={handleBusinessTypeOnChange}
            invalid={isInvalid("businessType")}
            value={state.businessType}
          >
            {!state.businessType && <Option value={""} key={"empty"} />}
            {businessTypesList.map(businessType => {
              return (
                <Option value={businessType.value} key={businessType.label}>
                  {businessType.label}
                </Option>
              );
            })}
          </Select>
          {getFormErrors("businessType")}
        </div>
      </div>

      <BusinessRegistrationNumberForm
        state={state}
        handleUpdate={handleUpdate}
        errorMessages={businessRegistrationNumberErrors}
      />
    </>
  );
}
