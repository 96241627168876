import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";

interface JobStatusCriterionProps {
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

export function JobStatusCriterion({
  criteriaIndex,
  updateAdditionalCriteria,
}: JobStatusCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  useEffect(() => {
    updateAdditionalCriteria({
      type: "updateJobStatusCriteria",
      payload: {
        index: criteriaIndex,
        isDirty: true,
      },
    });
    // We only want this to fire when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid={"jobStatusCriterion"}>
      <Text>{formatMessage(messages.jobStatusCriterionText)}</Text>
    </div>
  );
}
