import { gql } from "@apollo/client";

export const CAMPAIGN_CHECK_FROM_EMAIL_VALIDATION_CODE = gql`
  mutation campaignCheckFromEmailValidationCode(
    $input: CampaignCheckFromEmailValidationCodeInput!
  ) {
    campaignCheckFromEmailValidationCode(input: $input) {
      status
      userErrors {
        message
        path
      }
    }
  }
`;
