import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { AddBankAccountForm } from "./AddBankAccountForm";
import type {
  BankAccountInformation,
  BankAccountInformationAction,
} from "./bankAccountInformationReducer";
import { BankContactForm } from "./BankContactForm";
import { DirectDebitInfoSheet } from "./DirectDebitInfoSheet";
import { ContactJobber } from "./ContactJobber";

export interface DebitAgreementFormProps {
  countryCode: string;
  bankAccountInformation: BankAccountInformation;
  errorMessage?: string;
  showIBAN: boolean;
  bankAccountInformationDispatch(action: BankAccountInformationAction): void;
}

export function DebitAgreementForm(props: DebitAgreementFormProps) {
  const {
    bankAccountInformation,
    errorMessage,
    showIBAN,
    bankAccountInformationDispatch,
  } = props;

  const [t] = useTranslation();

  return (
    <Content>
      <Text>
        <strong>{t("instructionTitle")}</strong>
      </Text>
      <AddBankAccountForm
        bankAccountInformation={bankAccountInformation}
        bankAccountInformationDispatch={bankAccountInformationDispatch}
        errorMessage={errorMessage}
        showIBAN={showIBAN}
      />
      <BankContactForm
        bankAccountInformation={bankAccountInformation}
        bankAccountInformationDispatch={bankAccountInformationDispatch}
      />
      <DirectDebitInfoSheet
        bankAccountInformation={bankAccountInformation}
        bankAccountInformationDispatch={bankAccountInformationDispatch}
      />
      <Divider />
      <ContactJobber />
    </Content>
  );
}
