import React from "react";
import { Carousel } from "components/Carousel";
import styles from "./HeaderCards.module.css";
import { HeaderCard } from "../HeaderCard";

export interface HeaderCardsProps {
  readonly cards: HeaderCard[];
  readonly size: "small" | "base";
}

export function HeaderCards({ cards, size }: HeaderCardsProps) {
  const renderCards = () => {
    return cards.map((card, index) => {
      return <HeaderCard card={card} key={index} />;
    });
  };

  return (
    <>
      {size === "small" ? (
        <div className={styles.cardsContainerSmall}>
          <div className={styles.carouselOuterWrapper}>
            <Carousel>{renderCards()}</Carousel>
          </div>
        </div>
      ) : (
        <div className={styles.cardsContainerBase}>{renderCards()}</div>
      )}
    </>
  );
}
