import React from "react";
import { ActiveFilters } from "components/FilterSearchInput/components/ActiveFilters";
import { InputSearch } from "components/InputSearch";
import type { InputSearchProps } from "components/InputSearch";
import styles from "./FilterSearchInput.module.css";

interface FilterSearchInputProps extends InputSearchProps {
  children?: JSX.Element;
}

export const NO_FILTER_SELECTED_VALUE = "";

export function FilterSearchInput({
  children,
  filter,
  ...inputSearchProps
}: FilterSearchInputProps) {
  function onClearFilter() {
    filter?.onFilterChange(NO_FILTER_SELECTED_VALUE);
  }

  return (
    <>
      <div className={styles.filterSearchContainer}>
        <InputSearch {...inputSearchProps} filter={filter} />
      </div>
      {children && (
        <div className={styles.filterSearchContainer}>{children}</div>
      )}
      {filter?.selectedFilter && (
        <ActiveFilters filter={filter} onClearButtonClick={onClearFilter} />
      )}
    </>
  );
}
