import { useEffect, useRef } from "react";

export interface MonitorProps {
  name: MonitoringName;
  isLoading: boolean;
}

export enum MonitoringName {
  SCHEDULING_RECOMMENDATIONS = "scheduling_recommendations",
  GOOGLE_REVIEWS = "google_reviews_subscription",
}

export function useSubscriptionMonitoring({ name, isLoading }: MonitorProps) {
  const loadStartTimeRef = useRef<number>();

  useEffect(() => {
    const now = performance.now();

    if (isLoading && !loadStartTimeRef.current) {
      loadStartTimeRef.current = now;
    } else if (!isLoading && !!loadStartTimeRef.current) {
      const timeDiff = (now - loadStartTimeRef.current) / 1000000;
      window.DD_RUM?.addTiming(name, timeDiff);
      loadStartTimeRef.current = undefined;
    }
  }, [name, isLoading]);
}
