import { usePaymentMethodActionsJobberOnline } from "jobber/payments_react/hooks/usePaymentMethodActions/usePaymentMethodActionsJobberOnline";
import { usePaymentMethodsBase } from "jobber/payments_react/hooks/usePaymentMethodsBase/usePaymentMethodsBase";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";

export interface UsePaymentMethodsInput {
  clientId: string;
  includeBankAccounts?: boolean;
  isClientIdEncoded?: boolean;
}

interface UsePaymentMethodsResponse {
  paymentMethods: StoredPaymentMethodInterface[];
  loading: boolean;
  error: Error | undefined;
  refetchPaymentMethods: () => Promise<void>;
  setDefaultPaymentMethod: (
    paymentMethod: StoredPaymentMethodInterface,
  ) => Promise<StoredPaymentMethodInterface | undefined>;
  deletePaymentMethod: (
    paymentMethod: StoredPaymentMethodInterface,
  ) => Promise<StoredPaymentMethodInterface | undefined>;
}

export function usePaymentMethodsJobberOnline({
  clientId,
  includeBankAccounts = false,
  isClientIdEncoded = false,
}: UsePaymentMethodsInput): UsePaymentMethodsResponse {
  const { deletePaymentMethod, setAsDefaultPaymentMethod } =
    usePaymentMethodActionsJobberOnline({
      clientId,
      isClientIdEncoded,
    });

  return usePaymentMethodsBase({
    clientId,
    deleteFunction: deletePaymentMethod,
    setAsDefaultFunction: setAsDefaultPaymentMethod,
    includeBankAccounts,
    isClientIdEncoded,
  });
}
