import { Amplitude } from "~/utilities/analytics/Amplitude";

const defaultProperties = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  conversation_type: "sms",
  source: "Message Center",
};

export function clickedViewClient(): void {
  Amplitude.TRACK_EVENT("Clicked View Client", { ...defaultProperties });
}

export function clickedMarkUnread(): void {
  Amplitude.TRACK_EVENT("Clicked Mark Unread", { ...defaultProperties });
}
