import {
  addDays,
  addHours,
  differenceInHours,
  differenceInSeconds,
} from "date-fns";
import {
  createDateWithoutSeconds,
  isTimeBefore,
  unroundedSecondsToMinutes,
} from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";

export enum TimesheetActionTypes {
  UpdateStartAt,
  UpdateEndAt,
  UpdateDuration,
  UpdateStartAtDate,
  UpdateNote,
  UpdateLabourRate,
  UpdateAssignedTo,
  UpdateShouldUpdateDefaultLabourRate,
}

export const initialCreateTimesheetModalState: TimesheetModalState = {
  startAt: createDateWithoutSeconds(),
  endAt: addHours(createDateWithoutSeconds(), 1),
  duration: 3600,
  note: "",
  labourRate: 0,
  assignedTo: "",
  shouldUpdateDefaultLabourRate: false,
  isLabourRateChanged: false,
};

export interface TimesheetModalState {
  startAt?: Date;
  endAt?: Date;
  duration: number;
  note?: string;
  labourRate?: number;
  assignedTo?: string;
  shouldUpdateDefaultLabourRate?: boolean;
  isLabourRateChanged?: boolean;
}

export interface UserConfig {
  userId: string;
  labourRate: number;
}

export type TimesheetModalStateType =
  | Date
  | number
  | string
  | boolean
  | UserConfig;

export interface TimesheetModalAction {
  type: TimesheetActionTypes;
  value: TimesheetModalStateType;
}

export function TimesheetModalReducer(
  prevState: TimesheetModalState,
  action: TimesheetModalAction,
): TimesheetModalState {
  let nextState;
  let startAt;
  let endAt;
  let duration;
  let userData;

  switch (action.type) {
    case TimesheetActionTypes.UpdateStartAt:
      startAt = action.value as Date;

      nextState = {
        startAt,
        ...updateDuration(prevState.endAt, startAt),
      };

      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateEndAt:
      endAt = action.value as Date;

      nextState = {
        ...updateDuration(endAt, prevState.startAt),
      };

      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateDuration:
      duration = Number(action.value);

      nextState = {
        duration,
        endAt: updateEndAtByDuration(duration, prevState.startAt),
      };

      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateStartAtDate:
      startAt = action.value as Date;

      nextState = {
        startAt,
        endAt: updateEndAtByDuration(prevState.duration, startAt),
      };

      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateNote:
      nextState = { note: action.value as string };
      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateLabourRate:
      nextState = {
        labourRate: action.value as number,
        isLabourRateChanged: true,
      };
      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateAssignedTo:
      userData = action.value as UserConfig;

      nextState = {
        assignedTo: userData.userId as string,
        labourRate: userData.labourRate as number,
        isLabourRateChanged: false,
        shouldUpdateDefaultLabourRate: false,
      };
      return { ...prevState, ...nextState };

    case TimesheetActionTypes.UpdateShouldUpdateDefaultLabourRate:
      nextState = { shouldUpdateDefaultLabourRate: action.value as boolean };
      return { ...prevState, ...nextState };

    default:
      return { ...prevState };
  }
}

function updateDuration(endAt?: Date, startAt?: Date) {
  if (!endAt || !startAt) return { duration: 0, endAt: undefined };
  const difference = differenceInSeconds(endAt, startAt);

  if (difference < 0) {
    endAt = addDays(endAt, 1);
  } else if (differenceInHours(endAt, startAt) >= 24) {
    endAt.setMonth(startAt.getMonth());

    if (isTimeBefore(startAt, endAt)) {
      endAt.setDate(startAt.getDate());
    } else {
      endAt.setDate(startAt.getDate() + 1);
    }
  }

  const newDurationSeconds = differenceInSeconds(endAt, startAt);

  return { duration: newDurationSeconds, endAt };
}

function updateEndAtByDuration(duration: number, startAt?: Date) {
  if (!startAt) return undefined;
  const newEndAt = new Date(startAt.toISOString());
  newEndAt.setMinutes(
    newEndAt.getMinutes() + unroundedSecondsToMinutes(duration),
  );

  return newEndAt;
}
