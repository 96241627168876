import { gql } from "@apollo/client";

export const GET_MOBILE_BILLING_INFO = gql`
  query mobileBillingInfo {
    account {
      billingInformation {
        allowInWebBillingChanges
      }
    }
  }
`;
