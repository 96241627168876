import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import styles from "./FeatureItem.module.css";

export interface FeatureItemProps {
  /**
   * The Icon to be displayed infront of this feature
   * @default "checkmark"
   */
  icon?: IconNames;
  /** The label of the feature */
  label: string;
}

export function FeatureItem({ icon = "checkmark", label }: FeatureItemProps) {
  return (
    <li className={styles.listItem}>
      <div className={styles.iconItem}>
        <Icon name={icon} color="green" />
      </div>
      <div>
        <Text>{label}</Text>
      </div>
    </li>
  );
}
