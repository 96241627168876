import { isBefore, startOfMonth, startOfWeek } from "date-fns";
import { today } from "./today";
import { type YearMonthDay, toYearMonthDay } from "../yearMonthDay";

export function getFirstCalendarDateOrToday(
  selectedDate: Date,
  weekStartsOnMonday: boolean,
): YearMonthDay {
  // Get the first day of the selected month
  const firstDayOfMonth = startOfMonth(selectedDate);

  // Get the first day of the week for the first day of the month
  const firstCalendarDate = startOfWeek(firstDayOfMonth, {
    weekStartsOn: weekStartsOnMonday ? 1 : 0,
  });

  if (isBefore(firstCalendarDate, today())) {
    return toYearMonthDay(today());
  }

  return toYearMonthDay(firstCalendarDate);
}
