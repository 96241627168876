import { WorkObjectOwnership } from "~/utilities/API/graphql";

const oneOwner = {
  id: "1",
  email: {
    raw: "sam_green@example.com",
  },
  name: "Sam Green",
  ownershipType: WorkObjectOwnership.USER,
  user: {
    id: "MTE4",
  },
};

const twoOwner = {
  id: "4",
  email: {
    raw: "big_cat_green@example.com",
  },
  name: "Big Cat Green",
  ownershipType: WorkObjectOwnership.USER,
  user: {
    id: "MTE5",
  },
};

const senderOwner = {
  id: "2",
  email: undefined,
  name: "Sender of email",
  ownershipType: WorkObjectOwnership.SENDER,
  user: undefined,
};

const companyOwner = {
  id: "3",
  email: {
    raw: "sam_green_f1_company@example.com",
  },
  name: "Company email",
  ownershipType: WorkObjectOwnership.COMPANY,
  user: undefined,
};

export const possibleReplyToOwners = [
  [oneOwner],
  [twoOwner],
  [senderOwner],
  [companyOwner],
];

// SINGLE OWNERS
export const fourDifferentReplyToOwners = [
  [oneOwner],
  [twoOwner],
  [senderOwner],
  [companyOwner],
];

export const threeDifferentReplyToOwners = [
  [oneOwner],
  [oneOwner],
  [senderOwner],
  [companyOwner],
];

export const twoDifferentReplyToOwners = [
  [oneOwner],
  [oneOwner],
  [oneOwner],
  [senderOwner],
];

export const twoDifferentReplyToOwnersTwoWorkObjects = [
  [oneOwner],
  [oneOwner],
  [senderOwner],
  [senderOwner],
];

export const oneReplyToSenderOwner = [
  [senderOwner],
  [senderOwner],
  [senderOwner],
  [senderOwner],
];

export const oneReplyToOneOwner = [
  [oneOwner],
  [oneOwner],
  [oneOwner],
  [oneOwner],
];

export const oneReplyToCompanyOwner = [
  [companyOwner],
  [companyOwner],
  [companyOwner],
  [companyOwner],
];

// MULTIPLE OWNERS MOCKS
export const oneMultipleReplyToSenderOwner = [
  [oneOwner, twoOwner, senderOwner, companyOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
];

export const fourDifferentMultipleReplyToOwners = [
  [oneOwner],
  [oneOwner, twoOwner],
  [oneOwner, twoOwner, senderOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
];

export const threeDifferentMultipleReplyToOwners = [
  [oneOwner, twoOwner],
  [oneOwner, twoOwner],
  [twoOwner, senderOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
];

export const twoDifferentMultipleReplyToOwners = [
  [twoOwner, senderOwner],
  [twoOwner, senderOwner],
  [twoOwner, senderOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
];

export const twoDifferentMultipleReplyToOwnersTwoWorkObjects = [
  [twoOwner, senderOwner],
  [twoOwner, senderOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
  [oneOwner, twoOwner, senderOwner, companyOwner],
];
