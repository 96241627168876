import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { IntegrationLogo } from "jobber/marketplace/components/IntegrationLogo";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/marketplace/ANALYTICS_EVENTS";
import { AppStateLabel } from "jobber/marketplace/components/AppStateLabel";
import type { ApplicationStateTypeEnum } from "~/utilities/API/graphqlAnchor";
import { decodeId } from "~/utilities/decodeId/decodeId";
import styles from "./IntegrationCard.module.css";

interface IntegrationCardProps {
  /** The title of the integration */
  title: string;
  /** The shortented display name of the integration, if it exists */
  displayName?: string;
  /** The description of the integration */
  description: string;
  /** The URL of the Integration Logo */
  logoUrl?: string;
  /** The URL to see more details about */
  detailsUrl: string;
  /**
   * Featured Integration
   * @default false
   */
  featured?: boolean;
  /** The background colour of the logo */
  logoBackgroundColor?: string;
  /** The ID of the application */
  id?: string;
  /** The section the app belongs to on the App Marketplace */
  appType: string;
  /** App state */
  appState: ApplicationStateTypeEnum;
  /** Whether or not the app is highlighted */
  highlighted?: boolean;
  /** Whether or not the app is connected */
  isConnected?: boolean;
  /** Whether or not the app is recently published */
  recentlyPublished?: boolean;
  /** Whether or not the app is public facing */
  publicFacing: boolean;
  /** Whether or not the app has an offer */
  hasOffer: boolean;
}

export function IntegrationCard({
  title,
  displayName,
  description,
  logoUrl,
  detailsUrl,
  featured = false,
  logoBackgroundColor,
  id,
  appType,
  appState,
  highlighted,
  isConnected = false,
  recentlyPublished = false,
  publicFacing,
  hasOffer = false,
}: IntegrationCardProps) {
  function handleCardClick() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedIntegrationCard, {
      appId: id,
      name: title,
      source: appType,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      public_marketplace: publicFacing,
    });
    window.location.href = detailsUrl;
  }
  return (
    <div
      className={`${featured ? styles.featuredCard : styles.card} ${
        highlighted ? styles.highlightedCard : ""
      }`}
      id={`${featured ? "featured" : ""}app-id-${decodeId(id || "")}`}
      data-testid={`${featured ? "featured" : ""}app-id-${decodeId(id || "")}`}
    >
      {!featured && (
        <AppStateLabel
          cardListing
          appState={hasOffer && !isConnected ? "OFFER" : appState}
          recentlyPublished={recentlyPublished}
        />
      )}
      <Card
        elevation="base"
        {...(id ? { onClick: handleCardClick } : { url: detailsUrl })}
      >
        {featured ? (
          <FeaturedCardContent
            description={description}
            title={title}
            displayName={displayName}
            featured={featured}
            logoBackgroundColor={logoBackgroundColor}
            logoUrl={logoUrl}
          />
        ) : (
          <NonFeaturedCardContent
            description={description}
            title={title}
            displayName={displayName}
            featured={featured}
            logoBackgroundColor={logoBackgroundColor}
            logoUrl={logoUrl}
          />
        )}
      </Card>
    </div>
  );
}

function NonFeaturedCardContent({
  description,
  title,
  displayName,
  featured,
  logoBackgroundColor,
  logoUrl,
}: Pick<
  IntegrationCardProps,
  | "title"
  | "featured"
  | "logoBackgroundColor"
  | "logoUrl"
  | "description"
  | "displayName"
>) {
  return (
    <Content>
      <div className={styles.row}>
        <IntegrationLogoCard
          title={title}
          featured={featured}
          logoBackgroundColor={logoBackgroundColor}
          logoUrl={logoUrl}
        />
        <IntegrationCardContent
          description={description}
          title={title}
          displayName={displayName}
          featured={featured}
        />
      </div>
    </Content>
  );
}

function FeaturedCardContent({
  description,
  title,
  displayName,
  featured,
  logoBackgroundColor,
  logoUrl,
}: Pick<
  IntegrationCardProps,
  | "description"
  | "displayName"
  | "featured"
  | "title"
  | "logoUrl"
  | "logoBackgroundColor"
>) {
  return (
    <>
      <IntegrationLogoCard
        title={title}
        featured={featured}
        logoBackgroundColor={logoBackgroundColor}
        logoUrl={logoUrl}
      />
      <Content>
        <IntegrationCardContent
          description={description}
          title={title}
          displayName={displayName}
          featured={featured}
        />
      </Content>
    </>
  );
}

function IntegrationCardContent({
  description,
  title,
  displayName,
  featured,
}: Pick<
  IntegrationCardProps,
  "title" | "displayName" | "description" | "featured"
>) {
  return (
    <div className={styles.integrationCardContent}>
      <Heading level={featured ? 3 : 4}>
        {displayName ? displayName : title}
      </Heading>
      <Text maxLines={featured ? "single" : "small"}>{description}</Text>
    </div>
  );
}

function IntegrationLogoCard({
  logoUrl,
  title,
  logoBackgroundColor,
  featured,
}: Pick<
  IntegrationCardProps,
  "logoUrl" | "title" | "logoBackgroundColor" | "featured"
>) {
  return logoUrl ? (
    <IntegrationLogo
      title={title}
      logoUrl={logoUrl}
      fullwidth={featured}
      backgroundColor={logoBackgroundColor}
    />
  ) : (
    <></>
  );
}
