import { defineMessages } from "react-intl";

export const messages = defineMessages({
  ariaLabel: {
    id: "starGroup.ariaLabel",
    defaultMessage: "Rated {fullRating} out of {MAX_RATING}",
    description:
      "Description of how many stars there are out of a maximum of {maxRating}",
  },
});
