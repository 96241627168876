import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import React from "react";
import styles from "./CustomFieldTypes.module.css";

export function BaseReadOnlyCustomField(
  isInAppGroup: boolean,
  name: string,
  value: string,
) {
  return (
    <div className={styles.container} title="container">
      <div
        className={classNames({
          [styles.label]: true,
          [styles.inAppGroup]: isInAppGroup,
        })}
        title="label"
      >
        <Text>{name}</Text>
      </div>
      <div className={styles.value} title="value">
        <Text>
          {/*
            Input and links are somewhat sanitized via CustomFieldsHelper,
            but this needs to be revisited on if we should allow html in the first place.
          */}
          {/* eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention */}
          <span dangerouslySetInnerHTML={{ __html: value }} />
        </Text>
      </div>
    </div>
  );
}
