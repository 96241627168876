import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { useQuery } from "@apollo/client";
import { Option, Select } from "@jobber/components/Select";
import { Form } from "@jobber/components/Form";
import { ErrorLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ErrorLayout/ErrorLayout";
import { LoadingLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/LoadingLayout/LoadingLayout";
import type {
  InitialMessageSettingsQuery,
  MessageTemplateEmail,
  MessageTemplateSms,
} from "~/utilities/API/graphql";
import { DeliveryMethod } from "~/utilities/API/graphql";
import { ReviewMessageCustomizer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessageCustomizer/ReviewMessageCustomizer";
import { useSettingsFormState } from "jobber/reviews/views/ReviewsPage/hooks/useSettingsFormState";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { messages as messageCTA } from "jobber/reviews/views/ReviewsPage/components/Resources/messages";
import { ReviewMessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";
import { INITIAL_MESSAGE_SETTINGS_QUERY } from "../ReviewsSettings.graphql";

interface InitialMessageSettingsProps {
  showCustomizations: boolean;
}

interface FormState {
  isDirty: boolean;
  isValid: boolean;
}

export function InitialMessageSettings({
  showCustomizations,
}: InitialMessageSettingsProps) {
  const { formatMessage } = useIntl();
  const { drawerActions, showInsights, form, unsavedChangesModalActions } =
    useContext(ReviewsSettingsDrawerContext);
  const { dynamicTemplates, staticTemplates, loading, fetchMethodError } =
    useInitialMessageQuery(showInsights);
  const templates = [dynamicTemplates?.email, dynamicTemplates?.sms];

  const {
    isSubmitting,
    formValues,
    errorState,
    onChange,
    onSubmit,
    setFormValues,
  } = useSettingsFormState({ initialValues: dynamicTemplates });

  const handleStateChange = (state: FormState) => {
    if (state.isDirty) {
      form?.handleFormIsDirty();
    }
  };

  const deliveryMethod =
    formValues.deliveryMethod ||
    dynamicTemplates?.deliveryMethod ||
    DeliveryMethod.EMAIL_AND_SMS;

  return (
    <>
      <div className={styles.backButton}>
        <Button
          type="tertiary"
          variation="subtle"
          icon="longArrowLeft"
          ariaLabel={formatMessage(messages.messageSettingsBackAriaLabel)}
          onClick={() => {
            if (form?.isDirty) {
              unsavedChangesModalActions?.open();
            } else {
              drawerActions?.goTo(DrawerView.ManageSettings);
            }
          }}
        />
      </div>

      <div className={styles.settingsContainer}>
        {errorState.shouldShow && (
          <ErrorLayout
            hasDeliveryMethodError={fetchMethodError !== undefined}
            hasGeneralError={errorState.hasGeneralError}
          />
        )}

        <Form onSubmit={onSubmit} onStateChange={handleStateChange}>
          {loading ? (
            <LoadingLayout showCustomizations={showCustomizations} />
          ) : (
            <>
              <Select
                name={"deliveryMethod"}
                onChange={onChange}
                placeholder={formatMessage(
                  messages.messageSettingsDeliveryPlaceholder,
                )}
                defaultValue={
                  dynamicTemplates?.deliveryMethod ||
                  DeliveryMethod.EMAIL_AND_SMS
                }
              >
                <Option value={DeliveryMethod.EMAIL_AND_SMS}>
                  {formatMessage(messages.bothOptionsLabel)}
                </Option>
                <Option value={DeliveryMethod.EMAIL}>
                  {formatMessage(messages.emailOptionLabel)}
                </Option>
                <Option value={DeliveryMethod.SMS}>
                  {formatMessage(messages.textOptionLabel)}
                </Option>
              </Select>

              <div className={styles.editContainer}>
                {showCustomizations ? (
                  (formValues.deliveryMethod !== undefined ||
                    dynamicTemplates?.deliveryMethod !== undefined) && (
                    <ReviewMessageCustomizer
                      deliveryMethod={deliveryMethod}
                      dynamicTemplates={templates}
                      staticTemplate={staticTemplates}
                      setFormValues={setFormValues}
                    />
                  )
                ) : (
                  <ReviewMessagePreviewer
                    deliveryMethod={deliveryMethod}
                    templates={templates}
                    hasDPN={showCustomizations}
                    staticTemplate={staticTemplates}
                  />
                )}
              </div>

              <div className={styles.footer}>
                <Button
                  type="primary"
                  size="large"
                  submit={true}
                  fullWidth={true}
                  ariaLabel={formatMessage(
                    messages.messageSettingsSaveAriaLabel,
                  )}
                  label={formatMessage(messages.messageSettingsSaveLabel)}
                  disabled={isSubmitting}
                />
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
}

function useInitialMessageQuery(showInsights: boolean) {
  const {
    data,
    loading,
    error: fetchMethodError,
  } = useQuery<InitialMessageSettingsQuery>(INITIAL_MESSAGE_SETTINGS_QUERY);
  const queryValues = {
    deliveryMethod:
      data?.googleBusinessAccount?.deliveryMethod ||
      DeliveryMethod.EMAIL_AND_SMS,
    email: data?.reviewRequest?.templates?.nodes[0] as MessageTemplateEmail,
    sms: data?.reviewV2Request?.templates?.nodes[0] as MessageTemplateSms,
  };

  const insightValues = {
    deliveryMethod:
      data?.googleBusinessAccount?.deliveryMethod ||
      DeliveryMethod.EMAIL_AND_SMS,
    email: {
      ...(data?.reviewRequest?.templates?.nodes[0] as MessageTemplateEmail),
      message: {
        current: messageCTA.templateText.defaultMessage,
        default: queryValues?.email?.message.default,
      },
    } as MessageTemplateEmail,
    sms: {
      ...(data?.reviewV2Request?.templates?.nodes[0] as MessageTemplateSms),
      message: {
        current: messageCTA.templateText.defaultMessage,
        default: queryValues?.sms?.message.default,
      },
    } as MessageTemplateSms,
  };

  const staticTemplates = data?.reviewV2Transactional?.templates
    ?.nodes[0] as MessageTemplateSms;

  return {
    dynamicTemplates: showInsights ? insightValues : queryValues,
    staticTemplates,
    loading,
    fetchMethodError,
  };
}
