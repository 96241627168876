import { useQuery } from "@apollo/client";
import React, {
  type MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import type { EditBillingInfoRef } from "jobber/billing/components/EditBillingInfo";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { LoadingError } from "jobber/billing/components/LoadingError";
import { trackViewedRecurlyForm } from "jobber/billing/utils/trackInteractions";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { AddonUpsellProps } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import type {
  AccountAddonInfoQuery,
  MutationErrors,
} from "~/utilities/API/graphql";
import { AddonPurchaseForm } from "./AddonPurchaseForm";
import { ACCOUNT_ADDON_INFO } from "./AddonPurchaseForm.graphql";
import { AddonPurchaseGlimmer } from "./components/AddonPurchaseGlimmer";

interface AddonPurchaseFormLoaderProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  addonUpsell?: AddonUpsellProps;
  trackingDetails: TrackingDetails;
  setSaveEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowParentActions?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAddonCode: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSelectedAddonName: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  onValidationError(error: FieldErrorState): void;
  onSubmitBillingInfoSuccess(): void;
  onSubmitBillingInfoError(errors: MutationErrors[]): void;
}

export const AddonPurchaseFormLoader = forwardRef(
  InternalAddonPurchaseFormLoader,
);

// eslint-disable-next-line max-statements
export function InternalAddonPurchaseFormLoader(
  props: AddonPurchaseFormLoaderProps,
  ref: MutableRefObject<EditBillingInfoRef>,
) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    addonUpsell,
    trackingDetails,
    setSaveEnabled,
    setShowParentActions,
    setSelectedAddonCode,
    setSelectedAddonName,
    onValidationError,
    onSubmitBillingInfoSuccess,
    onSubmitBillingInfoError,
  } = props;

  const { name } = trackingDetails;
  const { loading, error, data } = useQuery<AccountAddonInfoQuery>(
    ACCOUNT_ADDON_INFO,
    {
      variables: {
        addonSetIdentifier:
          addonUpsell && addonUpsell.addonsToUpsell.length > 0
            ? addonUpsell.addonsToUpsell[0]
            : addonSetIdentifier,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const { accountAddonInfo, addonPreviewGroup, addonDiscountGroup, account } =
    data || {};

  const addonCode = accountAddonInfo?.monthlyBillingCycle.addonCode;
  const addonName = accountAddonInfo?.name;
  const startBillingOnDate = account?.billingInformation?.startBillingOnDate;

  const updateAddonCode = useCallback(() => {
    if (addonCode) {
      setSelectedAddonCode(addonCode);
    }
  }, [addonCode, setSelectedAddonCode]);

  const updateAddonName = useCallback(() => {
    if (addonName) {
      setSelectedAddonName(addonName);
      trackViewedRecurlyForm({ name, addonName });
    }
  }, [name, addonName, setSelectedAddonName]);

  useEffect(() => {
    updateAddonCode();
    updateAddonName();
  }, [addonCode, addonName, updateAddonCode, updateAddonName]);

  if (loading) {
    return <AddonPurchaseGlimmer />;
  }

  if (error || !accountAddonInfo || !addonPreviewGroup || !account) {
    return (
      <LoadingError
        source="AddonPurchaseFormLoader"
        logMessage={`Could not load subscription addon: ${error}`}
      />
    );
  }

  return (
    <AddonPurchaseForm
      ref={ref}
      accountAddonInfo={accountAddonInfo}
      addonPreviewGroup={addonPreviewGroup}
      addonDiscountGroup={addonDiscountGroup}
      addonUpsell={addonUpsell}
      startBillingOnDate={startBillingOnDate}
      recurlyPublicKey={recurlyPublicKey}
      trackingDetails={trackingDetails}
      setSaveEnabled={setSaveEnabled}
      setShowParentActions={setShowParentActions}
      onValidationError={onValidationError}
      onSubmitBillingInfoSuccess={onSubmitBillingInfoSuccess}
      onSubmitBillingInfoError={onSubmitBillingInfoError}
    />
  );
}
