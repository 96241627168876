import { gql } from "@apollo/client";

export const OwnerInfoFragment = gql`
  fragment ownerInfoFragment on ReplyToOwner {
    id
    name
    email {
      raw
    }
  }
`;

export const DEFAULT_EMAIL_QUERY_WITH_OWNERSHIP = gql`
  query DefaultEmailQueryWithOwnership(
    $workObjectType: WorkObject
    $workObjectId: EncodedId
    $fetchOwnerships: Boolean!
    $clientId: EncodedId!
  ) {
    client(id: $clientId) {
      id
      defaultEmails
    }
    replyToOwnership(workObjectType: $workObjectType, id: $workObjectId)
      @include(if: $fetchOwnerships) {
      nodes {
        id
        owner {
          ...ownerInfoFragment
        }
        possibleOwners {
          nodes {
            ...ownerInfoFragment
          }
        }
        workObjectOwnershipType
      }
    }
  }
  ${OwnerInfoFragment}
`;
