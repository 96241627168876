import React from "react";
import { Heading } from "@jobber/components/Heading";
// DO NOT COPY: Allowing an exception here for a legacy use of Typography.
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import classNames from "classnames";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { ContainerHeaderProps } from "./OnboardingCarousel.d";
import styles from "./ContainerHeader.module.css";

export function ContainerHeader({
  title,
  createUrl,
  type,
  viewAllUrl,
}: ContainerHeaderProps) {
  const handleOnClick = (url: string, interaction: string) => () => {
    Amplitude.TRACK_EVENT("Interacted With Freemium Get Started Page", {
      interaction,
    });

    window.location.href = url;
  };

  const cta = `New ${type}`;

  return (
    <div className={styles.containerHeader}>
      <div className={styles.left}>
        <span className="u-marginRightSmall">
          <Heading level={2}>{title}</Heading>
        </span>
        <a href={viewAllUrl} className="js-spinOnClick">
          <Typography
            element="h6"
            textCase="uppercase"
            textColor="green"
            fontWeight="bold"
          >
            View All
          </Typography>
        </a>
      </div>
      <button
        className={classNames(
          "button button--green js-spinOnClick",
          styles.button,
        )}
        onClick={handleOnClick(createUrl, cta)}
      >
        {cta}
      </button>
    </div>
  );
}
