import { defineMessages } from "react-intl";

export const messages = defineMessages({
  genericError: {
    id: "errorBanner.genericError",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Simple error message",
  },
  refreshButton: {
    id: "errorBanner.refreshButton",
    defaultMessage: "Refresh",
    description: "Refresh button",
  },
});
