import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Cell, Header, Row, Table } from "@jobber/components/Table";
import classnames from "classnames";
import type {
  CategoryWithServices,
  Service,
} from "jobber/google_lsa/MerchantConfiguration";
import { ServiceSelector } from "jobber/google_lsa/components";
import styles from "./OnlineBookingCard.module.css";

interface BookingConfigurationEmptyProps {
  children: JSX.Element;
}

function Empty({ children }: BookingConfigurationEmptyProps) {
  return (
    <div className={classnames(styles.bookingConfigurationEmpty, "row")}>
      <div className="columns shrink">
        <span className="icon icon--invoice icon--circle u-colorGreyBlue" />
      </div>
      <div className="columns">
        <Content>
          <div>
            <Heading level={5}>No job types chosen</Heading>
            <Text>
              <Emphasis variation="italic">
                Choose your categories and related services for online booking
              </Emphasis>
            </Text>
          </div>
          {children}
        </Content>
      </div>
    </div>
  );
}

function displayPrice(price: number | undefined): string {
  // Should be replaced with a proper i18n
  if (!price) {
    return `FREE`;
  }
  return `$${price.toFixed(2)}`;
}

interface CategoryParams {
  editButton: JSX.Element;
  category: CategoryWithServices;
}

function Category({ editButton, category }: CategoryParams) {
  const { categoryDisplayName, services } = category;
  const rowHeader = (
    <th scope="rowgroup" rowSpan={services.length}>
      {categoryDisplayName}
    </th>
  );
  const editCategory = <td rowSpan={services.length}>{editButton}</td>;

  return (
    <tbody>
      {services
        .filter(service => service.id !== undefined)
        .map((service, rowIndex) => {
          return (
            <Row key={service.serviceName}>
              {rowIndex === 0 ? rowHeader : <></>}
              <Cell>{service.serviceDisplayName}</Cell>
              <Cell>{displayPrice(service.price)}</Cell>
              {rowIndex === 0 ? editCategory : <></>}
            </Row>
          );
        })}
    </tbody>
  );
}

interface BookingConfigurationNotEmptyProps {
  activeCategories: CategoryWithServices[];
  editButtonFactory(activeCategory: CategoryWithServices): JSX.Element;
}

function NotEmpty({
  activeCategories,
  editButtonFactory,
}: BookingConfigurationNotEmptyProps) {
  return (
    <div className={styles.bookingConfigurationTable}>
      <Table>
        <Header>
          <Cell> Job Category </Cell>
          <Cell> Service Types </Cell>
          <Cell> Listed Minimum Fee </Cell>
        </Header>
        <>
          {activeCategories.map(category => {
            return (
              <Category
                key={category.categoryName}
                editButton={editButtonFactory(category)}
                category={category}
              />
            );
          })}
        </>
      </Table>
    </div>
  );
}

export interface OnlineBookingCardProps {
  categories: CategoryWithServices[];
  onUpdate(services: Service[]): Promise<void>;
}

export function OnlineBookingCard({
  categories,
  onUpdate,
}: OnlineBookingCardProps) {
  const activeCategories = categories.filter(category => {
    return (
      category.services.filter(service => service.id !== undefined).length > 0
    );
  });
  const editButtonFactory = (activeCategory: CategoryWithServices) => {
    return (
      <ServiceSelector
        label="Edit"
        size="small"
        type="tertiary"
        categories={categories}
        activeCategory={activeCategory}
        onUpdate={onUpdate}
      />
    );
  };

  return (
    <Card title="Step 1: Match Your 'Job Types'">
      <Content>
        <Text>
          Identify the ‘job types’ from your{" "}
          <a
            href="https://adwords.google.com/localservices/myprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            Local Services Ads profile
          </a>{" "}
          that you want to enable for booking.
        </Text>
        {activeCategories.length > 0 ? (
          <>
            <NotEmpty
              activeCategories={activeCategories}
              editButtonFactory={editButtonFactory}
            />
            <ServiceSelector
              categories={categories}
              size="small"
              onUpdate={onUpdate}
            />
          </>
        ) : (
          <Empty>
            <ServiceSelector
              categories={categories}
              size="small"
              onUpdate={onUpdate}
            />
          </Empty>
        )}
      </Content>
    </Card>
  );
}
