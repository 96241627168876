import React, { type RefObject, useRef, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { Banner } from "@jobber/components/Banner";
import { useTwilioRegistrationDataMemoization } from "jobber/settings/dedicatedPhoneNumber/hooks/useTwilioRegistrationDataMemoization";
import {
  hasTaxIdRegistrationError,
  taxIdRegistrationErrorMessage,
} from "jobber/settings/dedicatedPhoneNumber/utilities/businessRegistrationNumberHelpers";
import { TwilioRegistrationForm } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/TwilioRegistrationForm";
import { useTwilioRegistrationData } from "jobber/settings/dedicatedPhoneNumber/hooks/useTwilioRegistrationData";
import { Rollbar } from "~/utilities/errors/Rollbar";
import type { MutationErrors } from "~/utilities/API/graphql";
import { StandardRegistrationInformation } from "jobber/settings/dedicatedPhoneNumber/components/standardRegistrationInformation/StandardRegistrationInformation";

interface RegistrationModalExistingNumberProps {
  onClose?(): void;
}

export function RegistrationModalExistingNumber({
  onClose,
}: RegistrationModalExistingNumberProps) {
  const [openModal, setOpenModal] = useState(true);
  const {
    loading,
    handleUpdate,
    state: formState,
    handleSubmit,
  } = useTwilioRegistrationData(true);

  const { memoizedBusinessName, memoizedBusinessRegistrationNumber } =
    useTwilioRegistrationDataMemoization(formState);
  const isTaxIdErrorPresent = hasTaxIdRegistrationError(formState.userErrors);
  const taxIdErrorMessage = taxIdRegistrationErrorMessage(formState.userErrors);
  const topLevelRef: RefObject<HTMLDivElement> = useRef(null);

  return (
    <Modal
      title="Register Your Number"
      open={openModal}
      onRequestClose={closeModal}
      primaryAction={{
        label: "Submit",
        onClick: handleSave,
        loading: loading,
      }}
      tertiaryAction={{
        label: "Cancel",
        onClick: closeModal,
        variation: "subtle",
        type: "primary",
      }}
    >
      <div ref={topLevelRef} />
      <Content>
        {formState.generalError && (
          <Banner type="error" onDismiss={hideGeneralError}>
            {formState.generalError}
          </Banner>
        )}
        {isTaxIdErrorPresent && (
          <Banner type="error" dismissible={false}>
            {taxIdErrorMessage}
          </Banner>
        )}

        <StandardRegistrationInformation />
        <TwilioRegistrationForm
          handleUpdate={handleUpdate}
          loading={loading}
          state={formState}
        />
      </Content>
    </Modal>
  );

  function scrollToTheTopOfModal() {
    // 0 means that strings are the same
    const hasBusinessNameChanged =
      memoizedBusinessName.current?.localeCompare(
        formState.businessName ?? "",
      ) === 0;

    const hasBusinessRegistrationNumberChanged =
      memoizedBusinessRegistrationNumber.current?.localeCompare(
        formState.businessRegistrationNumber ?? "",
      ) === 0;
    if (
      hasTaxIdRegistrationError(formState.userErrors) &&
      hasBusinessNameChanged &&
      hasBusinessRegistrationNumberChanged
    ) {
      topLevelRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }

  function handleSave() {
    scrollToTheTopOfModal();
    handleSubmit()
      .then(
        (result: {
          mutationErrors: MutationErrors[];
          responseNumber: string;
        }) => {
          if (!result.mutationErrors?.length) {
            closeModal();
            window.location.reload();
          }
        },
      )
      .catch((err: Error) => {
        if (
          err.message !== "Mutation Error Caught" &&
          err.toString() !== "Form submitted without valid state."
        ) {
          Rollbar.EXECUTE("Error submitting 10DLC Form", err);
        }
      });
  }

  function hideGeneralError() {
    handleUpdate({ generalError: undefined });
  }

  function closeModal() {
    setOpenModal(false);
    if (onClose) {
      onClose();
    }
  }
}
