import { gql } from "@apollo/client";

export const REPORT_EXPORT_MUTATION = gql`
  mutation ReportExport(
    $reportType: ReportTypeEnum!
    $filter: RoyaltyFilterAttributes
    $sort: RoyaltySortInput
  ) {
    exportCsv(reportType: $reportType, filter: $filter, sort: $sort) {
      response
    }
  }
`;
