import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { SerializedFieldsForRails } from "./SerializeScheduleStateForRails";
import { useScheduleCardState } from "./hooks";
import { SchedulingDateControls } from "./SchedulingDateControls";
import { SchedulingTimeControls } from "./SchedulingTimeControls";
import { DeferScheduling } from "./DeferScheduling";
import { RecurrentScheduling } from "./RecurrentScheduling";
import styles from "./styles.module.css";
import type { RepeatOption } from "../OneOffScheduler";
import { ArrivalWindowPreview } from "../ArrivalWindowPreview";

export interface ScheduleCardProps {
  repeatOptions: RepeatOption[];
  arrivalWindowParameters: { centered: boolean; duration: number | null };
  railsFormKey: string;
}

export function ScheduleCard({
  repeatOptions,
  arrivalWindowParameters,
  railsFormKey,
}: ScheduleCardProps) {
  const { stateMatches, schedulingState } = useScheduleCardState();
  const shouldShowRecurrentOptions =
    stateMatches("dates.enabled.multiDay") &&
    stateMatches("scheduling.isScheduled");

  return (
    <>
      <Card header={{ title: "Schedule" }}>
        <Content>
          <Content spacing="small">
            <SchedulingDateControls
              heading={<DatesHeading />}
              showPlaceHolders={false}
            />
            <SchedulingTimeControls renderHeading />
            {schedulingState.arrivalWindowsEnabled && (
              <ArrivalWindowPreview
                jobScheduleType="oneOff"
                existingJobArrivalWindow={arrivalWindowParameters.duration}
                existingJobArrivalWindowCentered={
                  arrivalWindowParameters.centered
                }
              />
            )}
            {shouldShowRecurrentOptions && (
              <RecurrentScheduling
                repeatOptions={repeatOptions}
                heading={<RecurrenceHeading />}
                showPlaceholder={false}
              />
            )}
            <DeferScheduling />
          </Content>
        </Content>
      </Card>
      <SerializedFieldsForRails
        state={schedulingState}
        stateMatches={stateMatches}
        railsFormKey={railsFormKey}
      />
    </>
  );
}

function RecurrenceHeading() {
  return (
    <div className={styles.recurrenceTopMargin}>
      <Heading level={5}>Repeats</Heading>
    </div>
  );
}

function DatesHeading() {
  return (
    <div className={styles.flexBox}>
      <div className={styles.flexChild}>
        <Heading level={5}>Start date</Heading>
      </div>
      <div className={styles.flexChild}>
        <Heading level={5}>End date</Heading>
      </div>
    </div>
  );
}
