export const messages = {
  buttonLabelImage: {
    message: "Upload Image",
    description: "label for upload image button",
  },
  hintTextImage: {
    message: "or drag an image here to upload",
    description: "hint text for upload image",
  },
};
