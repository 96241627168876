import type { RawVariableSection } from "../types";

interface VariableMapping {
  [key: string]: string;
}

export function useVariableSubstitution(
  rawVariableSections: RawVariableSection[],
) {
  const variableSubstitution = rawVariableSections.reduce(
    (map: VariableMapping, section) => {
      section.variables.nodes.forEach(
        variable => (map[variable.name] = variable.sample),
      );

      return map;
    },
    {},
  );

  return variableSubstitution;
}
