import React, { useState } from "react";
import { useSubscription } from "@apollo/client";
import type { IconColorNames } from "@jobber/components/Icon";
import request from "queries/live_sync_stats_subscription.graphql";
import type {
  LiveSyncStats,
  QboLiveSyncStatsSubscription,
} from "~/utilities/API/graphql";
import { SetIconStateVariables } from "jobber/quickbooks/components/QuickbooksSyncIconWrapper/SetIconStateVariables";
import { IconButton } from "jobber/quickbooks/components/QuickbooksSyncIconWrapper/IconButton";
import { QuickbooksIconDefaults } from "./QuickbooksIconDefaults";

export interface QboSubscriptionSyncIconProps {
  liveSyncQueryData: LiveSyncStats;
  handleClick(): void;
}

export function QuickbooksSubscriptionSyncIcon(
  props: QboSubscriptionSyncIconProps,
) {
  let icon = QuickbooksIconDefaults.default;
  let syncing = props.liveSyncQueryData.syncing;
  let errorCount = props.liveSyncQueryData.errorCount;
  const [qboIconState, setQboIconState] = useState("default");

  const { error, data } =
    useSubscription<QboLiveSyncStatsSubscription>(request);

  if (error) {
    return <></>;
  }

  if (data) {
    [syncing, errorCount] = SetIconStateVariables({
      subscriptionData: data.qboLiveSyncStats,
      currentIconProps: props,
      setQboState: setQboIconState,
    });
    [props.liveSyncQueryData.syncing, props.liveSyncQueryData.errorCount] = [
      syncing,
      errorCount,
    ];
  }

  icon = SetQboIconVariable(syncing, qboIconState);

  return (
    <IconButton
      icon={icon.icon}
      errorCount={errorCount}
      animation={icon.animation}
      color={icon.color as IconColorNames}
      onClick={props.handleClick}
    />
  );
}

function SetQboIconVariable(syncing: boolean, qboIconState: string) {
  if (syncing) {
    return QuickbooksIconDefaults.syncing;
  } else {
    if (qboIconState === "default") {
      return QuickbooksIconDefaults.default;
    } else {
      return QuickbooksIconDefaults.checkmark;
    }
  }
}
