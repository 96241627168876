import React from "react";
import { Divider, Grid } from "@jobber/components";
import { APIProvider } from "~/utilities/API/APIProvider";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { DepositedPayouts } from "./components/DepositedPayouts";
import styles from "./FinancialInsightsSection.module.css";
import { Receivables } from "./components/Receivables";
import { UpcomingPayouts } from "./components/UpcomingPayouts";

interface FinancialInsightsSectionProps {
  hasLoan: boolean;
  instantPayoutDebitCardInfo?: CreditCard;
  instantPayoutFeeMultiplier: string;
}

export function FinancialInsightsSection({
  hasLoan,
  instantPayoutDebitCardInfo,
  instantPayoutFeeMultiplier,
}: FinancialInsightsSectionProps) {
  return (
    <APIProvider>
      <section className={styles.financialInsights}>
        <Grid gap={true} alignItems={"stretch"}>
          <Grid.Cell size={{ md: 12, lg: 4 }}>
            <div className={styles.financialInsightsCard}>
              <DepositedPayouts hasLoan={hasLoan} />
            </div>
          </Grid.Cell>
          <Grid.Cell size={{ md: 12, lg: 4 }}>
            <div className={styles.financialInsightsCard}>
              <UpcomingPayouts
                instantPayoutDebitCardInfo={instantPayoutDebitCardInfo}
                instantPayoutFeeMultiplier={instantPayoutFeeMultiplier}
              />
            </div>
          </Grid.Cell>
          <Grid.Cell size={{ md: 12, lg: 4 }}>
            <div className={styles.financialInsightsCard}>
              <Receivables />
            </div>
          </Grid.Cell>
        </Grid>
        <div className={styles.divider}>
          <Divider direction={"horizontal"} />
        </div>
      </section>
    </APIProvider>
  );
}
