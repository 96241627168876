import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "automaticPayments.cardOnFileSwitch.title",
    defaultMessage: "Get paid automatically",
    description: "Title for the card on file switch",
  },
  paymentMethodOnFileTitle: {
    id: "automaticPayments.cardOnFileSwitch.paymentMethodOnFileTitle",
    defaultMessage: "Payment method on file",
    description: "Title for the payment method on file",
  },
  createdAndPaidAutomatically: {
    id: "automaticPayments.cardOnFileSwitch.createdAndPaidAutomatically",
    defaultMessage:
      "Sit back as the money rolls in. Clients are automatically invoiced and charged based on their billing frequency once they save a payment method on file. Learn more in [Help Center](https://help.getjobber.com/hc/en-us/articles/360036931633-Automatic-Payments).",
    description: "Message when invoices are created and paid automatically",
  },
  mustBeScheduled: {
    id: "automaticPayments.cardOnFileSwitch.mustBeScheduled",
    defaultMessage:
      "Invoices must be scheduled to be automatically paid. Learn more in [Help Center](https://help.getjobber.com/hc/en-us/articles/360036931633-Automatic-Payments).",
    description:
      "Message when invoices must be scheduled to be automatically paid",
  },
});
