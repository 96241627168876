import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { ExpiryStatus } from "utilities/jobberPayments/paymentMethods/paymentMethods";

const testCreditCards: CreditCard[] = [
  {
    id: "1",
    brand: "visa",
    expiryMonth: 8,
    expiryYear: 2028,
    expiryStatus: ExpiryStatus.DEFAULT,
    isDefault: false,
    last4: "4242",
  },
  {
    id: "2",
    brand: "mastercard",
    expiryMonth: 12,
    expiryYear: 2020,
    expiryStatus: ExpiryStatus.EXPIRING_SOON,
    isDefault: true,
    last4: "2451",
  },
  {
    id: "3",
    brand: "jcb",
    expiryMonth: 12,
    expiryYear: 2012,
    expiryStatus: ExpiryStatus.EXPIRED,
    isDefault: false,
    last4: "8925",
  },
];

export { testCreditCards };
