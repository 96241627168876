import React from "react";
import type { Dispatch, SetStateAction } from "react";
import { type QueryResult, useQuery } from "@apollo/client";
import { CheckoutSummaryGlimmer } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/CheckoutSummaryGlimmer/CheckoutSummaryGlimmer";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import type {
  BillingCycleName,
  PlanInfoQuery,
  SubscriptionDiscountGroupInfoQuery,
  SubscriptionPreview,
  SubscriptionPreviewGroupQuery,
} from "~/utilities/API/graphql";
import { LoadingError } from "jobber/billing/components/LoadingError";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import {
  PLAN_INFO,
  SUBSCRIPTION_DISCOUNT_GROUP_INFO,
  SUBSCRIPTION_PREVIEW_GROUP,
} from "./CheckoutSummary.graphql";
import { CheckoutSummary } from "./CheckoutSummary";
import type { CheckoutSummaryData } from "./types";

interface CheckoutSummaryLoaderProps {
  trackingDetails: TrackingDetails;
  planSetIdentifier: string;
  selectedBillingCycle: BillingCycleName;
  postalCode?: string;
  country?: string;
  startBillingDate?: Date;
  enablePurchaseButton: boolean;
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  displayedPurchaseTotal?: number;
  onSetSelectedBillingCycle: Dispatch<SetStateAction<BillingCycleName>>;
  onSetPurchaseTotal(purchaseTotal: number): void;
  onConfirmSubscription: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

// eslint-disable-next-line max-statements
export function CheckoutSummaryLoader(props: CheckoutSummaryLoaderProps) {
  const {
    trackingDetails,
    planSetIdentifier,
    selectedBillingCycle,
    postalCode,
    country,
    startBillingDate,
    enablePurchaseButton,
    subscriptionAddons,
    selectedAddonCodes,
    displayedPurchaseTotal,
    onSetSelectedBillingCycle,
    onSetPurchaseTotal,
    onConfirmSubscription,
  } = props;

  const plan = useQuery<PlanInfoQuery>(PLAN_INFO, {
    variables: { planSetIdentifier },
  });
  const previews = useQuery<SubscriptionPreviewGroupQuery>(
    SUBSCRIPTION_PREVIEW_GROUP,
    {
      variables: {
        planSetIdentifier,
        postalCode,
        country,
      },
    },
  );
  const discountInformation = useQuery<SubscriptionDiscountGroupInfoQuery>(
    SUBSCRIPTION_DISCOUNT_GROUP_INFO,
    {
      variables: {
        planSetIdentifier,
        startBillingDate,
      },
    },
  );

  if (plan.loading || previews.loading || discountInformation.loading) {
    return <CheckoutSummaryGlimmer />;
  }

  const checkoutSummaryData = validateQueryResults(
    plan,
    previews,
    discountInformation,
  );
  if (!checkoutSummaryData) {
    return (
      <LoadingError
        source={"CheckoutSummaryLoader"}
        logMessage={`Unable to fetch plan set preview for plan set identifier: ${planSetIdentifier}`}
      />
    );
  }

  return (
    <CheckoutSummary
      trackingDetails={trackingDetails}
      checkoutSummaryData={checkoutSummaryData}
      selectedBillingCycle={selectedBillingCycle}
      enablePurchaseButton={enablePurchaseButton}
      subscriptionAddons={subscriptionAddons}
      selectedAddonCodes={selectedAddonCodes}
      displayedPurchaseTotal={displayedPurchaseTotal}
      onSetSelectedBillingCycle={onSetSelectedBillingCycle}
      onSetPurchaseTotal={onSetPurchaseTotal}
      onConfirmSubscription={onConfirmSubscription}
    />
  );
}

function validateQueryResults(
  plan: QueryResult<PlanInfoQuery>,
  previews: QueryResult<SubscriptionPreviewGroupQuery>,
  discountInformation: QueryResult<SubscriptionDiscountGroupInfoQuery>,
): CheckoutSummaryData | undefined {
  if (
    plan.error ||
    previews.error ||
    discountInformation.error ||
    (!plan.data?.planInfo?.monthlyBillingCycle &&
      !plan.data?.planInfo?.annualBillingCycle) ||
    !plan.data?.accountPlanInfo?.currentBillingCycle?.planCode ||
    (!previews.data?.subscriptionPreviewGroup?.monthlyPreview &&
      !previews.data?.subscriptionPreviewGroup?.annualPreview) ||
    !discountInformation.data?.subscriptionDiscountGroup
  ) {
    return;
  }

  return {
    accountPlanCode: plan.data.accountPlanInfo.currentBillingCycle.planCode,
    planInfo: {
      planTier: plan.data.planInfo.planTier,
      planIdentifier: plan.data.planInfo.planIdentifier,
      monthlyBillingCycle: plan.data.planInfo.monthlyBillingCycle,
      annualBillingCycle: plan.data.planInfo.annualBillingCycle,
    },
    subscriptionPreviewGroup: {
      monthlyPreview: getPreviewData(
        previews.data.subscriptionPreviewGroup.monthlyPreview,
      ),
      annualPreview: getPreviewData(
        previews.data.subscriptionPreviewGroup.annualPreview,
      ),
    },
    discountInformation: {
      monthlyDiscount:
        discountInformation.data.subscriptionDiscountGroup
          .monthlySubscriptionDiscount,
      annualDiscount:
        discountInformation.data.subscriptionDiscountGroup
          .annualSubscriptionDiscount,
    },
  };
}

function getPreviewData(preview?: SubscriptionPreview) {
  if (!preview) {
    return;
  }

  return {
    proratedDiscount: preview.proratedDiscount || 0,
    proratedSubtotal: preview.proratedSubtotal,
    proratedTax: preview.proratedTax,
    taxRate: preview.taxRate,
    proratedTotal: preview.proratedTotal,
    totalCredit: preview.totalCredit || 0,
    startBillingDate: preview.startBillingDate,
    renewalDate: preview.renewalDate,
    isCurrentBillingCycle: preview.isCurrentBillingCycle,
    subscribeDisabled: preview.subscribeDisabled,
  };
}
