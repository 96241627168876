import { defineMessages } from "react-intl";

export const messages = defineMessages({
  additionalVerificationRequired: {
    id: "editBillingInfo.additionalVerificationRequired",
    defaultMessage:
      "Additional verification is required to complete this transaction.",
    description: "Banner text for 3D secure challenge",
  },
  recurlyDefaultError: {
    id: "editBillingInfo.recurlyDefaultError",
    defaultMessage:
      "Unable to verify payment details. Please contact our success team.",
    description:
      "A generic message when something went wrong with submitting updated Recurly billing info.",
  },
  recurly3dsAuthError: {
    id: "editBillingInfo.recurly3dsAuthError",
    defaultMessage:
      "Unable to authenticate your payment method. Please try again with a different payment method.",
    description:
      "3-D Secure authentication has failed because the customer could not be authenticated. We recommend prompting the user to try a different payment method.",
  },
  unableToVerifyPaymentDetails: {
    id: "editBillingInfo.unableToVerifyPaymentDetails",
    defaultMessage: "Unable to verify payment details.",
    description: "Error message when unable to complete the transaction.",
  },
  firstNameInvalid: {
    id: "editBillingInfo.firstNameInvalid",
    defaultMessage: "Please check your first name and try again.",
    description: "Error message when the first name is invalid.",
  },
  lastNameInvalid: {
    id: "editBillingInfo.lastNameInvalid",
    defaultMessage: "Please check your last name and try again.",
    description: "Error message when the last name is invalid.",
  },
  cardNumberInvalid: {
    id: "editBillingInfo.cardNumberInvalid",
    defaultMessage:
      "Could not process credit card. Please check your card and try again.",
    description: "Error message when the card number is invalid.",
  },
  cardExpiryInvalid: {
    id: "editBillingInfo.cardExpiryInvalid",
    defaultMessage: "Please check your card expiry and try again.",
    description: "Error message when the credit card expiry is invalid.",
  },
  cvcInvalid: {
    id: "editBillingInfo.cvcInvalid",
    defaultMessage: "Please check your card CVC and try again.",
    description: "Error message when the CVC is invalid.",
  },
  countryInvalid: {
    id: "editBillingInfo.countryInvalid",
    defaultMessage: "Please check your country and try again.",
    description: "Error message when the country is invalid.",
  },
  stateInvalid: {
    id: "editBillingInfo.stateInvalid",
    defaultMessage: "Please check your state/province and try again.",
    description: "Error message when the state/province is invalid.",
  },
  addressInvalid: {
    id: "editBillingInfo.addressInvalid",
    defaultMessage: "Please check your billing address and try again.",
    description: "Error message when the address is invalid.",
  },
  postalCodeInvalid: {
    id: "editBillingInfo.postalCodeInvalid",
    defaultMessage: "Please check your zip/postal code and try again.",
    description: "Error message when the zip/postal code is invalid.",
  },
});
