import { defineMessages } from "react-intl";

export const messages = defineMessages({
  confirmSubscription: {
    id: "checkout.checkoutSummary.confirmSubscription",
    defaultMessage: "Confirm Subscription",
    description: "Confirm Subscription",
  },
  yourCurrentPlan: {
    id: "checkout.checkoutSummary.yourCurrentPlan",
    defaultMessage: "Your Current Plan",
    description: "Your Current Plan button",
  },
  resubscribe: {
    id: "checkout.checkoutSummary.resubscribe",
    defaultMessage: "Resubscribe",
    description: "Resubscribe button",
  },
});
