import { Button } from "@jobber/components";
import React from "react";
import styles from "./RuleButton.module.css";

export interface RuleButtonProps {
  label: string;
  onClick?(): void;
}

export function RuleButton({ label, onClick }: RuleButtonProps) {
  return (
    <span className={styles.ruleButton}>
      <Button label={label} type="tertiary" size="small" onClick={onClick} />
    </span>
  );
}
