import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "@jobber/components/Tooltip";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import {
  EventTypes,
  trackEventWithAmplitude,
} from "jobber/managed_accounts/FinancialInsightsSection/utils";
import styles from "./GetItNowButton.module.css";
import { messages } from "./messages";
import { PromoPopover } from "./components/PromoPopover/PromoPopover";
import { useButtonState } from "./useButtonState";

export interface GetItNowButtonProps {
  debitCardLinked: boolean;
  instantAvailableAmount: string;
  instantPayoutRequestedLast24Hours: string;
  debitCardIsValid: boolean;
  onClick(): void;
}

export function GetItNowButton(props: GetItNowButtonProps) {
  const popoverDivRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const { disabled, tooltip, showPopover } = useButtonState(props);

  const onClickWithTracking = () => {
    trackEventWithAmplitude({
      eventType: EventTypes.Modal,
      eventSource: "Instant payout",
    });
    props.onClick();
  };

  return (
    <>
      <Tooltip preferredPlacement="bottom" message={tooltip ?? ""}>
        <div className={styles.container} ref={popoverDivRef}>
          <span
            className={styles.buttonContainer}
            onMouseEnter={() => {
              if (tooltip) {
                trackEventWithAmplitude({
                  eventType: EventTypes.Tooltip,
                  eventSource: "Instant payout",
                });
              }
            }}
          >
            <TextActionButton
              label={formatMessage(messages.buttonLabel)}
              onClick={onClickWithTracking}
              disabled={disabled}
            />
          </span>
        </div>
      </Tooltip>

      {showPopover && <PromoPopover targetRef={popoverDivRef} />}
    </>
  );
}
