import { Segment, Template } from "~/utilities/API/graphql";

export function getRecommendedClientSegment(template: Template): Segment {
  switch (template) {
    case Template.RE_ENGAGE:
      return Segment.PAST_CLIENTS;
    case Template.UPSELL:
      return Segment.UPCOMING_CLIENTS;
    case Template.START_FROM_SCRATCH:
      return Segment.ALL_CLIENTS;
    case Template.AUTOMATED_RE_ENGAGE:
    case Template.AUTOMATED_WIN_BACK_LOST_LEADS:
    case Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES:
    case Template.REFERRAL:
    default:
      return Segment.ALL_CLIENTS;
  }
}
