import React from "react";
import {
  InlineLabel,
  type InlineLabelColors,
} from "@jobber/components/InlineLabel";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { ApplicationStateTypeEnum } from "~/utilities/API/graphqlAnchor";
import styles from "./AppStateLabel.module.css";

interface AppStateLabelProps {
  cardListing?: boolean;
  appState: keyof typeof ApplicationStateTypeEnum | "OFFER";
  recentlyPublished?: boolean;
}

interface AppLabel {
  label: string;
  color: InlineLabelColors;
}

const AppLabels: { [key: string]: AppLabel } = {
  BETA: {
    label: "Beta",
    color: "lightBlue",
  },
  DEVELOPMENT: {
    label: "In Development",
    color: "greyBlue",
  },
  NEW: {
    label: "New",
    color: "green",
  },
};

export function AppStateLabel({
  cardListing = false,
  appState,
  recentlyPublished = false,
}: AppStateLabelProps) {
  let labelConfig;
  switch (appState) {
    case ApplicationStateTypeEnum.PUBLISHED:
      if (!recentlyPublished) return <></>;
      labelConfig = AppLabels.NEW;
      break;
    case ApplicationStateTypeEnum.APPROVED:
      return <></>;
    case ApplicationStateTypeEnum.PUBLISHED_BETA:
      labelConfig = AppLabels.BETA;
      break;
    default:
      labelConfig = AppLabels.DEVELOPMENT;
      break;
  }

  const isOffer = appState === "OFFER";

  return (
    <div className={cardListing ? styles.appListingLabel : ""}>
      {cardListing && <div className={styles.labelShadowBox} />}
      {isOffer ? (
        <BrandLabel label="Offer" />
      ) : (
        <InlineLabel
          size={cardListing ? "base" : "large"}
          color={labelConfig.color}
        >
          {labelConfig.label}
        </InlineLabel>
      )}
    </div>
  );
}

function BrandLabel({ label }: { label: string }) {
  return (
    <span className={styles.offerLabel}>
      <Typography
        align={"center"}
        element={"span"}
        fontFamily={"base"}
        fontWeight={"semiBold"}
        textColor={"blue"}
        size={"small"}
      >
        {label}
      </Typography>
    </span>
  );
}
