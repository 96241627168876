/**
 * Provides an entry point for interacting with Intercom
 */

type IntercomMethod = `on${string}` | string;

export const Intercom = {
  EXECUTE: <T extends IntercomMethod>(
    method: T,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    param?: T extends `on${infer _Rest}` ? () => void : string,
    metadata?: { [key: string]: string | number },
  ) => {
    if (window.Intercom == undefined) {
      // eslint-disable-next-line no-console
      console.warn("Intercom not defined");

      return;
    }

    window.Intercom(method, param, metadata);
  },
};
