import * as React from "react";
import {
  SignaturePad,
  type SignaturePadProps,
  SignaturePadType,
} from "components/SignaturePad";
import {
  SignatureSelector,
  type SignatureSelectorProps,
} from "./SignatureSelector";

export interface ComboSignaturePadProps {
  SignatureSelectorComponent?: React.ComponentType<SignatureSelectorProps>;
  SignaturePadComponent?: React.ComponentType<SignaturePadProps>;

  onSignatureDataUrlChange?(signatureDataUrl: string | undefined): void;
}

export function ComboSignaturePad(props: ComboSignaturePadProps) {
  const {
    onSignatureDataUrlChange: onChange,
    SignaturePadComponent = SignaturePad,
    SignatureSelectorComponent = SignatureSelector,
  } = props;
  const [inputType, setInputType] = React.useState(SignaturePadType.TYPE);
  const onSelectorChange = React.useCallback(
    (newInputType: SignaturePadType) => {
      setInputType(newInputType);
      if (onChange) {
        onChange(undefined);
      }
    },
    [onChange],
  );

  const handleSignatureDataUrlChange = (
    signatureDataUrl: string | undefined,
  ) => {
    if (onChange) {
      onChange(signatureDataUrl || undefined);
    }
  };

  return (
    <>
      <SignatureSelectorComponent
        selectedSignaturePadType={inputType}
        onChange={onSelectorChange}
      />
      <SignaturePadComponent
        inputType={inputType}
        onSignatureDataUrlChange={handleSignatureDataUrlChange}
      />
    </>
  );
}
