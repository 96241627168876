import type { MutableRefObject } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Heading, InlineLabel, SideDrawer } from "@jobber/components";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import { OPEN_ASSISTANT_FROM_INSIGHT_WIDGET } from "~/jobber/insightWidgets/constants";
import { IntlProvider } from "@translations/IntlProvider";
import { Intercom } from "utilities/chat";
import { OPEN_ASSISTANT_FROM_BUTTON } from "./constants";
import { JobberAssistantConversationLoader } from "./components/JobberAssistantConversationLoader";
import {
  AssistantPromptEventQueueProvider,
  useAssistantPromptEventQueue,
} from "./AssistantPromptEventQueueProvider";
import styles from "./JobberAssistantRoot.module.css";
import { messages } from "./messages";

export function JobberAssistantRoot() {
  return (
    <AssistantPromptEventQueueProvider>
      <APIProvider>
        <IntlProvider>
          <JobberAssistantButtonInternal />
        </IntlProvider>
      </APIProvider>
    </AssistantPromptEventQueueProvider>
  );
}

function JobberAssistantButtonInternal() {
  const { formatMessage } = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shouldLoadConversationHistory, setShouldLoadConversationHistory] =
    useState(true);

  const { enqueuePrompt } = useAssistantPromptEventQueue();
  const buttonContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const handleOpenFromInsightWidget = useCallback(
    (event: CustomEvent<{ message: string; widgetName?: string }>) => {
      enqueuePrompt({
        message: event.detail?.message,
        widgetName: event.detail?.widgetName,
      });
      // An insight widget represents a new conversation, so history is not needed
      setShouldLoadConversationHistory(false);
      trackOpenEvent("insight-widget");
      setIsDrawerOpen(true);
    },
    [enqueuePrompt],
  );
  const handleOpenFromButton = useCallback(() => {
    trackOpenEvent("top-nav");
    setIsDrawerOpen(true);
  }, []);

  useEffect(() => {
    window.addEventListener(
      OPEN_ASSISTANT_FROM_INSIGHT_WIDGET,
      handleOpenFromInsightWidget,
    );
    window.addEventListener(OPEN_ASSISTANT_FROM_BUTTON, handleOpenFromButton);

    return () => {
      window.removeEventListener(
        OPEN_ASSISTANT_FROM_INSIGHT_WIDGET,
        handleOpenFromInsightWidget,
      );
      window.removeEventListener(
        OPEN_ASSISTANT_FROM_BUTTON,
        handleOpenFromButton,
      );
    };
  }, [handleOpenFromInsightWidget, handleOpenFromButton]);

  const title = (
    <div className={styles.sidebarTitle}>
      <Heading level={2}>{formatMessage(messages.copilotName)}</Heading>
      <InlineLabel>{formatMessage(messages.betaFlag)}</InlineLabel>
    </div>
  );

  return (
    <div data-testid="jobber-assistant">
      <Button
        type="secondary"
        variation="subtle"
        icon={"sparkles"}
        ariaLabel="Open Jobber Copilot"
        onClick={() =>
          window.dispatchEvent(
            new CustomEvent(OPEN_ASSISTANT_FROM_BUTTON, {
              detail: { source: "top-nav" },
            }),
          )
        }
      />
      <SideDrawer
        onRequestClose={onCloseSideDrawer}
        open={isDrawerOpen}
        variation="base"
      >
        <SideDrawer.Title>{title}</SideDrawer.Title>
        <JobberAssistantConversationLoader
          closeChatSidebar={onCloseSideDrawer}
          shouldLoadConversationHistory={shouldLoadConversationHistory}
        />
      </SideDrawer>
    </div>
  );

  function setFocusOnButton() {
    const button = buttonContainerRef.current as HTMLDivElement | undefined;
    button?.focus();
  }

  function onCloseSideDrawer() {
    Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
      name: "chatbot_drawer_closed",
    });
    setIsDrawerOpen(false);
    // Reset to load the history by default next time the drawer is opened.
    setShouldLoadConversationHistory(true);
    // We need the tick to wait for the redraw after the drawer is removed.
    setTimeout(() => {
      setFocusOnButton();
    }, 0);
  }
}

function trackOpenEvent(source: "top-nav" | "insight-widget") {
  Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
    name: "chatbot_drawer_opened",
    source,
  });
  if (source === "top-nav") {
    Intercom.EXECUTE("trackEvent", "click_on_jobber_copilot");
  }
}
