import { generatePath, matchPath, useHistory } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_PATH,
  CAMPAIGNS_TEMPLATE_EDIT_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";

function handleBeforeUnload(event: BeforeUnloadEvent) {
  event.preventDefault();
  event.returnValue = true;
}

function addBeforeUnloadListener(isInSavedState: boolean) {
  if (isInSavedState) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }
}

export function removeBeforeUnloadListener() {
  window.removeEventListener("beforeunload", handleBeforeUnload);
}

export function useCampaignNavigation() {
  const {
    campaignContent: { isDirty },
  } = useCampaignWizardContext();
  const { block, listen, replace } = useHistory();

  const { formatMessage } = useIntl();
  const {
    campaignContent: { resetCampaignTemplateContent, campaignId },
  } = useCampaignWizardContext();

  const blockNavigationListener = useCallback(
    (_isDirty = false) => {
      removeBeforeUnloadListener();
      addBeforeUnloadListener(_isDirty);
      const blockNavigation = block(() => {
        if (_isDirty) {
          return formatMessage(messages.exitConfirmationModalText);
        }
      });

      return () => {
        removeBeforeUnloadListener();
        blockNavigation();
      };
    },
    [block, formatMessage],
  );

  useEffect(() => {
    const locationListener = listen(newLocation => {
      const matchesSegmentCreatePath = matchPath(
        newLocation.pathname,
        CAMPAIGNS_SEGMENT_PATH,
      );
      const resetSteps = matchPath(newLocation.pathname, {
        path: [
          CAMPAIGNS_SEGMENT_EDIT_PATH,
          CAMPAIGNS_SEGMENT_PATH,
          CAMPAIGNS_TEMPLATE_EDIT_PATH,
          CAMPAIGNS_TEMPLATE_PATH,
          CAMPAIGNS_LANDING_PAGE_PATH,
        ],
      });

      if (matchesSegmentCreatePath && campaignId) {
        removeBeforeUnloadListener();
        replace(
          generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
            campaignId: campaignId,
          }),
        );
      } else if (resetSteps) {
        resetCampaignTemplateContent();
      }
    });

    return locationListener;
  }, [listen, replace, resetCampaignTemplateContent, campaignId]);

  useEffect(() => {
    return blockNavigationListener(isDirty);
  }, [isDirty, blockNavigationListener]);

  return { removeBeforeUnloadListener: blockNavigationListener };
}
