/* eslint-disable no-restricted-imports */
import { Button } from "@jobber/components/Button";
import React from "react";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import type { PaymentButtonBarProps } from "./PaymentButtonBarProps.d";
import styles from "./PaymentButtonBar.module.css";
import { BankPaymentACHButtonProvider } from "../BankPaymentACH";
import { SuperSaveButton } from "../SuperSaveButton";
import { SecurityAssuranceWrapper } from "../SecurityAssuranceWrapper";

export function PaymentButtonBar(props: PaymentButtonBarProps) {
  const {
    showSecurityAssurance,
    onChargeButtonFormat,
    onPaymentSubmit,
    onSuperSaveSubmit,
    superSaveItems,
    onCancel,
    bankPaymentACHSettings,
    inTabsContext,
    showSelectBankButton,
    setBankPaymentErrorMessage,
    attachedToId,
    attachedToType,
    isQuoteDepositPayment,
    quoteSignatureData,
    setBankPaymentAuthorized,
    setBankPaymentInfoCallback,
    authorizedBankingInfo,
    setAuthorizedBankingInfo,
    oauthStateId,
    isJobberPaymentsAch,
    companyName,
  } = props;
  const { currencySymbol } = useAccount();
  const [paymentState] = usePaymentReducer();
  const isPaymentInProgress = paymentState.status === "inProgress";
  const buttonBarStyles = [
    styles.buttonBar,
    inTabsContext ? styles.reducedPaddingWhenWrappedInTab : "",
  ].join(" ");

  return (
    <>
      <SecurityAssuranceWrapper
        companyName={companyName}
        show={showSecurityAssurance}
        haveMargins={!inTabsContext}
      >
        <div className={buttonBarStyles}>
          <Button
            label="Cancel"
            onClick={onCancel}
            disabled={isPaymentInProgress}
            variation="subtle"
          />
          <SuperSaveButton
            items={superSaveItems}
            onSubmit={onSuperSaveSubmit}
          />
          {renderPaymentButton(showSelectBankButton)}
        </div>
      </SecurityAssuranceWrapper>
    </>
  );

  function renderPaymentButton(selectBankButton: boolean) {
    if (!selectBankButton) {
      const disabled =
        paymentState.status === "notInitialized" ||
        (isJobberPaymentsAch && paymentState?.paymentMethods?.length === 0);

      return (
        <Button
          onClick={onPaymentSubmit}
          disabled={disabled}
          loading={isPaymentInProgress}
          label={onChargeButtonFormat(
            currencyWithUnit(paymentState.amount, currencySymbol).format(),
          )}
        />
      );
    }

    return (
      <BankPaymentACHButtonProvider
        setBankPaymentErrorMessage={setBankPaymentErrorMessage}
        bankPaymentACHSettings={bankPaymentACHSettings}
        onPaymentSubmit={onPaymentSubmit}
        attachedToId={attachedToId}
        attachedToType={attachedToType}
        isQuoteDepositPayment={isQuoteDepositPayment}
        quoteSignatureData={quoteSignatureData}
        setBankPaymentAuthorized={setBankPaymentAuthorized}
        setBankPaymentInfoCallback={setBankPaymentInfoCallback}
        authorizedBankingInfo={authorizedBankingInfo}
        setAuthorizedBankingInfo={setAuthorizedBankingInfo}
        oauthStateId={oauthStateId}
      />
    );
  }
}
