import React from "react";
import type { ConversationsQuery } from "~/utilities/API/graphql";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import type { CONVERSATION_TYPE_FILTER } from "jobber/chat/components/ChatDrawer/NotificationList/ConversationTypeFilterInput";
import { ConversationsNotFoundEmptyState } from "./ConversationsNotFoundEmptyState";
import { NoDPNEmptyState } from "./NoDPNEmptyState";
import { FilteredConversationsEmptyState } from "./FilteredConversationsEmptyState";
import { NoMessagesEmptyState } from "./NoMessagesEmptyState";

export interface EmptyStateWrapperProps {
  hasPhoneNumber: boolean;
  searchConversationType: CONVERSATION_TYPE_FILTER;
  error: boolean;
  data?: ConversationsQuery;
  filterLabel?: string;
  searchTerm?: string;
  searchFilter?: string;
  clearFilter(): void;
}

export function EmptyStateWrapper({
  hasPhoneNumber,
  data,
  error,
  searchTerm,
  clearFilter,
  filterLabel,
  searchFilter,
  searchConversationType,
}: EmptyStateWrapperProps): JSX.Element {
  const { currentUser } = useCurrentUser(["isAdmin"]);

  if (!hasPhoneNumber) {
    return <NoDPNEmptyState canEditNumber={currentUser.isAdmin} />;
  }

  if (!data?.conversations || error) {
    return <ConversationsNotFoundEmptyState />;
  }

  if (
    data.conversations.edges == undefined ||
    data.conversations.edges.length === 0
  ) {
    if (searchFilter) {
      return (
        <FilteredConversationsEmptyState
          filterLabel={filterLabel ?? ""}
          onClearFilter={clearFilter}
        />
      );
    }

    return (
      <NoMessagesEmptyState
        searchTerm={searchTerm}
        conversationType={searchConversationType}
      />
    );
  }
  return <></>;
}
