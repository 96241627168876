import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React from "react";
import { useIntl } from "react-intl";
import { AddDebitCardDialog } from "jobber/payments_sca/components/AddDebitCardDialog/AddDebitCardDialog";
import CreditCardListItem from "jobber/payments_sca/components/CreditCardListItem";
import useDialogOpen from "jobber/payments_sca/hooks/useDialogOpen";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export interface InstantPayoutDetailsProps {
  debitCard?: CreditCard;
  onDebitCardChange?(debitCard: CreditCard): void;
  isJobberMoneyPayout: boolean;
}

export const InstantPayoutDetails =
  withStripeElementProvider<InstantPayoutDetailsProps>(
    InstantPayoutDetailsWithStripe,
  );

export function InstantPayoutDetailsWithStripe(
  props: InstantPayoutDetailsProps,
) {
  const { debitCard, isJobberMoneyPayout } = props;
  const [learnMoreUrl] = useUrls("docsInstantPayoutsUrl");
  const { formatMessage } = useIntl();

  return (
    <Content>
      <Heading level={3}>Instant Payouts</Heading>
      {isJobberMoneyPayout === true ? (
        <Text>{formatMessage(messages.instantPayoutUnavailable)}</Text>
      ) : (
        <>
          <Text variation="subdued">
            Payouts are made within minutes 24/7 for an additional 1% fee.{" "}
            <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </Text>
          <Content>
            {debitCard && (
              <section>
                <CreditCardListItem {...debitCard} />
              </section>
            )}
            <ChangeDebitCard {...props} />
          </Content>
        </>
      )}
    </Content>
  );
}

function ChangeDebitCard(props: InstantPayoutDetailsProps) {
  const { debitCard, onDebitCardChange } = props;
  const [isDialogOpen, openDialog, closeDialog] = useDialogOpen(false);
  const {
    permissions,
    enabled: jobberPaymentsEnabled,
    instantPayoutOverrideEnabled,
  } = useJobberPayments();
  const shouldShow = permissions.canChangeBankAccount;
  const shouldDisable = !jobberPaymentsEnabled || !instantPayoutOverrideEnabled;
  const buttonLabel = debitCard ? "Change debit card" : "Link a debit card";

  return (
    <>
      {shouldShow && (
        <>
          <div className="js-add-debit-card-button">
            <Button
              label={buttonLabel}
              disabled={shouldDisable}
              onClick={openDialog}
              type="tertiary"
              fullWidth={true}
            />
          </div>
          {jobberPaymentsEnabled && !instantPayoutOverrideEnabled && (
            <shared-tooltip
              bind="hover"
              target=".js-add-debit-card-button"
              direction="above"
              class="tooltip"
            >
              Changing debit cards is temporarily disabled
            </shared-tooltip>
          )}
        </>
      )}
      <AddDebitCardDialog
        isDialogOpen={isDialogOpen}
        onRequestCloseDialog={closeDialog}
        onDebitCardAdded={onDebitCardChange}
      />
    </>
  );
}
