import type { FetchResult } from "@apollo/client";
import type { CompanySchedulePageDataQuery } from "~/utilities/API/graphql";

export const defaultWorkingHours = {
  startTime: "09:00",
  endTime: "17:00",
};

export function makeFullCalendarBusinessHoursForDay(
  workingHours: FetchResult<CompanySchedulePageDataQuery>["data"],
  dayOfWeek: number,
): {
  startTime: string;
  endTime: string;
} {
  if (!workingHours) return defaultWorkingHours;
  switch (dayOfWeek) {
    case 1:
      return {
        startTime: workingHours.companySchedule.days.monday.times[0][0],
        endTime: workingHours.companySchedule.days.monday.times[0][1],
      };
    case 2:
      return {
        startTime: workingHours.companySchedule.days.tuesday.times[0][0],
        endTime: workingHours.companySchedule.days.tuesday.times[0][1],
      };
    case 3:
      return {
        startTime: workingHours.companySchedule.days.wednesday.times[0][0],
        endTime: workingHours.companySchedule.days.wednesday.times[0][1],
      };
    case 4:
      return {
        startTime: workingHours.companySchedule.days.thursday.times[0][0],
        endTime: workingHours.companySchedule.days.thursday.times[0][1],
      };
    case 5:
      return {
        startTime: workingHours.companySchedule.days.friday.times[0][0],
        endTime: workingHours.companySchedule.days.friday.times[0][1],
      };
    case 6:
      return {
        startTime: workingHours.companySchedule.days.saturday.times[0][0],
        endTime: workingHours.companySchedule.days.saturday.times[0][1],
      };
    case 0:
      return {
        startTime: workingHours.companySchedule.days.sunday.times[0][0],
        endTime: workingHours.companySchedule.days.sunday.times[0][1],
      };
  }
  return defaultWorkingHours;
}
