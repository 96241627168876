import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import React, {
  type Dispatch,
  type MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { Option, Select } from "@jobber/components/Select";
import { InputText } from "@jobber/components/InputText";
import { Checkbox } from "@jobber/components/Checkbox";
import { Form, type FormRef } from "@jobber/components/Form";
import { InputNumber } from "@jobber/components/InputNumber";
import { InputGroup } from "@jobber/components/InputGroup";
import { Button } from "@jobber/components/Button";
import { Tooltip } from "@jobber/components/Tooltip";
import { showToast } from "@jobber/components/Toast";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";
import {
  type ConfigurationActions,
  customFieldConfigurationReducer,
  initialCustomFieldConfiguration,
} from "./customFieldConfigurationReducer";
import styles from "./CustomFieldConfigurationModal.module.css";
import {
  type CustomFieldConfigurationReducerState,
  invalidTransferableObjects,
} from "./types";
import { useCustomFieldConfiguration } from "./hooks";

interface CustomFieldConfigurationModalProps {
  readonly existingCustomField: CustomFieldConfigurationNodeFragment;
  readonly open: boolean;
  /**
   * appliesToDisabled should be true when triggering modal
   * from an object screen
   **/
  readonly appliesToDisabled?: boolean;
  onClose(): void;
  onSuccess(newConfiguration: CustomFieldConfigurationNodeFragment): void;
  onArchive?(configuration: CustomFieldConfigurationNodeFragment): void;
}

export function CustomFieldConfigurationModal({
  open,
  existingCustomField,
  appliesToDisabled = false,
  onClose,
  onSuccess,
  onArchive,
}: CustomFieldConfigurationModalProps) {
  const formRef = useRef() as MutableRefObject<FormRef>;
  const [bannerError, setBannerError] = useState("");

  const [customFieldConfiguration, dispatch] = useReducer(
    customFieldConfigurationReducer,
    existingCustomFieldToReducerState(existingCustomField),
  );

  useEffect(() => {
    dispatch({
      type: "RESET",
      value: existingCustomFieldToReducerState(existingCustomField),
    });
    setBannerError("");
  }, [open, existingCustomField]);

  function onFormSubmitSuccess(
    newConfiguration: CustomFieldConfigurationNodeFragment,
  ) {
    const successMessage = existingCustomField?.id
      ? "Custom field updated"
      : "Custom field created";
    showToast({ message: successMessage });
    onSuccess(newConfiguration);
  }

  const { handleCreateOrEditConfiguration, loading } =
    useCustomFieldConfiguration(onFormSubmitSuccess, setBannerError);

  async function createCustomField() {
    handleCreateOrEditConfiguration(customFieldConfiguration);
  }

  function submitForm() {
    formRef.current.submit();
  }
  return (
    <Modal
      title={
        customFieldConfiguration.id
          ? customFieldConfiguration.name
          : "New Custom Field"
      }
      open={open}
      onRequestClose={onClose}
      tertiaryAction={
        existingCustomField.id
          ? {
              label: "Archive",
              onClick: () => onArchive && onArchive(existingCustomField),
            }
          : undefined
      }
      primaryAction={{
        label: customFieldConfiguration.id ? "Update" : "Create Custom Field",
        onClick: submitForm,
        loading: loading,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: onClose,
        variation: "subtle",
        type: "primary",
      }}
    >
      <Content>
        {bannerError && (
          <Banner type="error" dismissible={false}>
            {bannerError}
          </Banner>
        )}

        <Form ref={formRef} onSubmit={createCustomField}>
          <Content>
            {/* Disable when triggered from work object */}
            <AppliesToSelector
              appliesTo={customFieldConfiguration.appliesTo}
              onChange={newAppliesTo =>
                dispatch({ type: "UPDATE_APPLIES_TO", appliesTo: newAppliesTo })
              }
              disabled={appliesToDisabled}
            />
            {!invalidTransferableObjects.includes(
              customFieldConfiguration.appliesTo,
            ) && (
              <Tooltip message="Transferable custom fields allow your data to appear in multiple places and follow you through your workflow.">
                <Checkbox
                  label="Transferable field"
                  checked={customFieldConfiguration.transferable}
                  disabled={!!customFieldConfiguration.id}
                  onChange={value =>
                    dispatch({
                      type: "UPDATE_TRANSFERABLE",
                      transferable: value,
                    })
                  }
                />
              </Tooltip>
            )}

            <NameInput
              customFieldName={customFieldConfiguration.name}
              onChange={name => dispatch({ type: "UPDATE_NAME", name: name })}
            />
            <FieldTypeSelector
              customFieldFieldType={customFieldConfiguration.fieldType}
              isEdit={!!customFieldConfiguration.id}
              onChange={type =>
                dispatch({ type: "UPDATE_TYPE", fieldType: type })
              }
            />
            <DefaultValueInput
              customFieldConfiguration={customFieldConfiguration}
              fieldType={customFieldConfiguration.fieldType}
              dispatch={dispatch}
            />
          </Content>
        </Form>

        <Text>
          All custom fields can be edited and reordered in{" "}
          <a href="/custom_fields" target="blank" rel="noopener noreferrer">
            Settings &gt; Custom Fields.
          </a>
        </Text>
      </Content>
    </Modal>
  );
}

function NameInput({
  customFieldName,
  onChange,
}: {
  customFieldName: string;
  onChange(name: string): void;
}) {
  return (
    <div>
      <InputText
        name="name"
        placeholder="Custom field name"
        onChange={onChange}
        value={customFieldName}
        validations={{
          required: {
            value: true,
            message: "Custom field name is required",
          },
        }}
      />
    </div>
  );
}

function FieldTypeSelector({
  customFieldFieldType,
  isEdit,
  onChange,
}: {
  customFieldFieldType: CustomFieldConfigurationValueType;
  isEdit: boolean;
  onChange(fieldType: CustomFieldConfigurationValueType): void;
}) {
  return (
    <Select
      placeholder="Field type"
      defaultValue={CustomFieldConfigurationValueType.TEXT}
      onChange={onChange}
      disabled={isEdit}
      value={customFieldFieldType}
    >
      <Option value={CustomFieldConfigurationValueType.TEXT}>Text</Option>
      <Option value={CustomFieldConfigurationValueType.NUMERIC}>Numeric</Option>
      <Option value={CustomFieldConfigurationValueType.TRUE_FALSE}>
        True/False
      </Option>
      <Option value={CustomFieldConfigurationValueType.AREA}>
        Area (length x width)
      </Option>
      <Option value={CustomFieldConfigurationValueType.DROPDOWN}>
        Dropdown
      </Option>
    </Select>
  );
}

function AppliesToSelector({
  disabled = false,
  appliesTo,
  onChange,
}: {
  disabled: boolean;
  appliesTo: CustomFieldAppliesTo;
  onChange(appliesTo: CustomFieldAppliesTo): void;
}) {
  return (
    <Select
      placeholder="Applies to"
      value={appliesTo}
      disabled={disabled}
      onChange={onChange}
    >
      <Option value={CustomFieldAppliesTo.ALL_PROPERTIES}>
        All properties
      </Option>
      <Option value={CustomFieldAppliesTo.ALL_CLIENTS}>All clients</Option>
      <Option value={CustomFieldAppliesTo.ALL_QUOTES}>All quotes</Option>
      <Option value={CustomFieldAppliesTo.ALL_JOBS}>All jobs</Option>
      <Option value={CustomFieldAppliesTo.ALL_INVOICES}>All invoices</Option>
      <Option value={CustomFieldAppliesTo.TEAM}>Team</Option>
    </Select>
  );
}

function DefaultValueInput({
  customFieldConfiguration,
  fieldType,
  dispatch,
}: {
  customFieldConfiguration: CustomFieldConfigurationReducerState;
  fieldType: CustomFieldConfigurationValueType;
  dispatch: Dispatch<ConfigurationActions>;
}) {
  function updateDefaultValue(value: string) {
    dispatch({
      type: "UPDATE_DEFAULT_VALUE",
      defaultValue: value,
    });
  }

  function updateUnit(unit: string) {
    dispatch({
      type: "UPDATE_UNIT",
      unit: unit,
    });
  }

  function updateDropdownOptions(options: string[]) {
    dispatch({
      type: "UPDATE_DROPDOWN_OPTIONS",
      options: options,
    });
  }

  switch (fieldType) {
    case CustomFieldConfigurationValueType.TEXT:
      return (
        <>
          <Text variation={"subdued"}>
            Example: Serial Number{" "}
            <input
              disabled={true}
              className={styles.exampleTextInput}
              value="54A17-HEX"
            />
          </Text>

          <InputText
            name="defaultTextValue"
            placeholder="Default value"
            onChange={updateDefaultValue}
            value={customFieldConfiguration.defaultValue as string}
          />
        </>
      );
    case CustomFieldConfigurationValueType.TRUE_FALSE:
      return (
        <>
          <div>
            <div className={styles.block}>
              <Text variation={"subdued"}>Example: </Text>
            </div>{" "}
            <div className={styles.block}>
              <Checkbox label={"Dog?"} checked={true} disabled={true} />
            </div>
          </div>
          <Select
            name="defaultTrueFalseValue"
            placeholder="Default value"
            defaultValue="false"
            onChange={updateDefaultValue}
            value={customFieldConfiguration.defaultValue as string}
          >
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        </>
      );
    case CustomFieldConfigurationValueType.NUMERIC:
      return (
        <>
          <Text variation={"subdued"}>
            Example: Pool Depth{" "}
            <input
              disabled={true}
              className={styles.exampleNumberInput}
              value="11"
            />{" "}
            ft
          </Text>

          <InputGroup flowDirection="horizontal">
            <InputNumber
              name="defaultNumericValue"
              onChange={updateDefaultValue}
              placeholder="Default value"
              value={customFieldConfiguration.defaultValue as number}
            />
            <div>
              <InputText
                name="numericUnit"
                onChange={updateUnit}
                value={customFieldConfiguration.unit}
                placeholder="Unit"
                validations={{
                  required: {
                    value: true,
                    message: "Unit is required",
                  },
                }}
              />
            </div>
          </InputGroup>
        </>
      );
    case CustomFieldConfigurationValueType.AREA:
      return (
        <>
          <Text variation={"subdued"}>
            Example: Yard Size{" "}
            <input
              disabled={true}
              className={styles.exampleNumberInput}
              value="50"
            />{" "}
            ×{" "}
            <input
              disabled={true}
              className={styles.exampleNumberInput}
              value="75"
            />{" "}
            ft
          </Text>
          <Text>Default values</Text>
          <div className={styles.areaInputWrapper}>
            <InputGroup flowDirection="horizontal">
              <div className={styles.areaInputGroup}>
                <InputNumber
                  name="defaultValueLength"
                  placeholder="Length"
                  onChange={length =>
                    dispatch({
                      type: "UPDATE_AREA_LENGTH",
                      length: Number(length),
                    })
                  }
                  value={customFieldConfiguration.areaLength}
                  inline={true}
                />{" "}
                ×{" "}
                <InputNumber
                  name="defaultValueWidth"
                  placeholder="Width"
                  onChange={width =>
                    dispatch({
                      type: "UPDATE_AREA_WIDTH",
                      width: Number(width),
                    })
                  }
                  value={customFieldConfiguration.areaWidth}
                  inline={true}
                />
              </div>
              <div>
                <InputText
                  name="areaUnit"
                  onChange={updateUnit}
                  value={customFieldConfiguration.unit}
                  placeholder="Unit"
                  validations={{
                    required: {
                      value: true,
                      message: "Unit is required",
                    },
                  }}
                />
              </div>
            </InputGroup>
          </div>
        </>
      );
    case CustomFieldConfigurationValueType.DROPDOWN:
      return (
        <Content>
          <Text variation={"subdued"}>
            Example: Work Type{" "}
            <select name="example-dropdown" id="ex-dropdown" disabled={true}>
              <option value="Commercial">Commercial</option>
            </select>
          </Text>

          <DropdownDefaultValueInput
            dropdownOptions={customFieldConfiguration.dropdownOptions}
            onDefaultValueChange={updateDefaultValue}
            onOptionsChange={updateDropdownOptions}
          />
        </Content>
      );
    default:
      return <></>;
  }
}

function DropdownDefaultValueInput({
  dropdownOptions,
  onDefaultValueChange,
  onOptionsChange,
}: {
  dropdownOptions: string[];
  onDefaultValueChange(value: string): void;
  onOptionsChange(options: string[]): void;
}) {
  function updateOptionValue(index: number, optionValue: string) {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index] = optionValue;

    onOptionsChange(updatedOptions);
  }

  function addOption() {
    const newOptions = [...dropdownOptions, ""];

    onOptionsChange(newOptions);
  }

  function updateDefaultValue(newDefaultValue: string) {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[0] = newDefaultValue;

    onOptionsChange(updatedOptions);
    onDefaultValueChange(newDefaultValue);
  }

  return (
    <>
      <Text>Options for dropdown</Text>
      <div className={styles.dropdownOptionContainer}>
        <div className={styles.dropdownNumber}>
          <Text key={"defaultValueKey"}>1. </Text>
        </div>
        <div className={styles.dropdownOption}>
          <InputText
            name={"defaultDropdownValue"}
            placeholder={"Default option"}
            onChange={updateDefaultValue}
            value={dropdownOptions[0]}
          />
        </div>
      </div>

      {dropdownOptions
        .slice(1, dropdownOptions.length)
        .map((dropdownOption, index) => (
          <div key={`${index + 1}`} className={styles.dropdownOptionContainer}>
            <div className={styles.dropdownNumber}>
              <Text>{index + 2}. </Text>
            </div>
            <div className={styles.dropdownOption}>
              <InputText
                key={`${index + 1}`}
                name={`${index + 1}`}
                placeholder={"Option"}
                onChange={value =>
                  updateOptionValue(index + 1, value as string)
                }
                value={dropdownOption}
              />
            </div>
          </div>
        ))}

      <Button
        label="Add Another Option"
        onClick={addOption}
        variation="subtle"
        size="small"
      />
    </>
  );
}

function existingCustomFieldToReducerState(
  existingCustomField: CustomFieldConfigurationNodeFragment,
): CustomFieldConfigurationReducerState {
  const baseReturnValues = {
    ...initialCustomFieldConfiguration,
    appliesTo: existingCustomField.appliesTo,
    fieldType: existingCustomField.valueType,
    id: existingCustomField.id,
    name: existingCustomField.name,
    transferable: existingCustomField.transferable,
    defaultValue: "",
    areaLength: 0,
    areaWidth: 0,
    unit: "",
    dropdownOptions: ["", ""],
  };
  if (existingCustomField.__typename === undefined) {
    return baseReturnValues;
  }

  let customField;

  switch (existingCustomField.__typename) {
    case "CustomFieldConfigurationArea":
      customField = existingCustomField;
      return {
        ...baseReturnValues,
        fieldType: CustomFieldConfigurationValueType.AREA,
        areaLength: customField.areaDefaultValue.length,
        areaWidth: customField.areaDefaultValue.width,
        unit: customField.unit,
      };
    case "CustomFieldConfigurationDropdown":
      customField = existingCustomField;
      return {
        ...baseReturnValues,
        fieldType: CustomFieldConfigurationValueType.DROPDOWN,
        defaultValue: customField.dropdownDefaultValue,
        dropdownOptions: customField.dropdownOptions,
      };
    case "CustomFieldConfigurationText":
      customField = existingCustomField;
      return {
        ...baseReturnValues,
        fieldType: CustomFieldConfigurationValueType.TEXT,
        defaultValue: customField.textDefaultValue,
      };
    case "CustomFieldConfigurationTrueFalse":
      customField = existingCustomField;
      return {
        ...baseReturnValues,
        fieldType: CustomFieldConfigurationValueType.TRUE_FALSE,
        defaultValue: customField.booleanDefaultValue,
      };
    case "CustomFieldConfigurationNumeric":
      customField = existingCustomField;
      return {
        ...baseReturnValues,
        fieldType: CustomFieldConfigurationValueType.NUMERIC,
        defaultValue: customField.numericDefaultValue,
        unit: customField.unit,
      };
  }

  return baseReturnValues;
}

export const emptyCustomFieldConfiguration: CustomFieldConfigurationNodeFragment =
  {
    id: "",
    appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
    valueType: CustomFieldConfigurationValueType.TEXT,
    name: "",
    createdAt: "",
    updatedAt: "",
    readOnly: false,
    transferable: false,
    sortOrder: 0,
    booleanDefaultValue: false,
    archived: false,
  };
