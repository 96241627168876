import moment from "moment";
import { useMemo } from "react";

export const useUtcTimeToDeviceTime = (
  time: string | null,
  accountTimezoneOffsetInSeconds: number,
) => {
  return useMemo(() => {
    if (!time) {
      return undefined;
    }

    const deviceOffsetInSeconds = moment.parseZone(Date.now()).utcOffset() * 60;
    const accountOffsetInSeconds = accountTimezoneOffsetInSeconds;
    const totalOffset = accountOffsetInSeconds - deviceOffsetInSeconds;

    return moment(time).add(totalOffset, "seconds").toDate();
  }, [accountTimezoneOffsetInSeconds, time]);
};
