import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";

interface ArrivalWindowRemoveButtonProps {
  onRemove(): void;
}

export function ArrivalWindowRemoveButton({
  onRemove,
}: ArrivalWindowRemoveButtonProps) {
  const [removeButtonLabel, setRemoveButtonLabel] = useState(
    "Remove Arrival Window",
  );

  useEffect(() => {
    const setButtonLabel = () => {
      const mediaQuery = window.matchMedia("(min-width: 60rem)");
      if (mediaQuery.matches) {
        setRemoveButtonLabel("Remove");
      } else {
        setRemoveButtonLabel("Remove Arrival Window");
      }
    };

    setButtonLabel();
    window.addEventListener("resize", setButtonLabel);
    return () => {
      window.removeEventListener("resize", setButtonLabel);
    };
  });

  return (
    <>
      <Button
        label={removeButtonLabel}
        variation="destructive"
        type="secondary"
        size="small"
        onClick={onRemove}
      />
    </>
  );
}
