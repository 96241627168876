import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignContentPageTitle: {
    id: "campaigns.campaignReviewPage.campaignContentPageTitle",
    defaultMessage: "Edit campaign content",
    description: "Title of campaign content page",
  },
  campaignSubjectLine: {
    id: "campaigns.campaignContentPage.campaignSubjectLine",
    defaultMessage: "Subject line",
    description: "Label for the subject line input",
  },
  campaignHeading: {
    id: "campaigns.campaignContentPage.campaignHeading",
    defaultMessage: "Heading",
    description: "Label for the email heading input",
  },
  campaignBody: {
    id: "campaigns.campaignContentPage.campaignBody",
    defaultMessage: "Body",
    description: "Label for the email body input",
  },
  insertVariable: {
    id: "campaigns.campaignContentPage.insertVariable",
    defaultMessage: "Insert Variable",
    description:
      "Button that inserts variables into the last activated text input",
  },
  errorForEmptySubject: {
    id: "campaigns.error.emptySubjectLine",
    defaultMessage: "Enter a subject line",
    description: "Empty subject line error message",
  },
  sendTestEmailSuccess: {
    id: "campaigns.campaignContentPage.success.sendTestEmail",
    defaultMessage: "Sent test email to {userEmail}",
    description: "Message when sending a test email is successful",
  },
  exitConfirmationModalTitle: {
    id: "campaigns.exitConfirmation.title",
    defaultMessage: "Leave this form?",
    description: "Exit confirmation modal title",
  },
  exitConfirmationModalText: {
    id: "campaigns.exitConfirmation.text",
    defaultMessage: "Changes you have made will not be saved",
    description: "Exit confirmation modal text",
  },
  exitConfirmationModalPrimaryButton: {
    id: "campaigns.exitConfirmation.button.primary",
    defaultMessage: "Leave",
    description: "Exit confirmation modal primary button",
  },
  ctaButton: {
    id: "campaigns.campaignContentPage.ctaButton",
    defaultMessage: "Button",
    description: "Label for the call to action button input",
  },
  ctaButtonExternalLink: {
    id: "campaigns.campaignContentPage.ctaButtonExternalLink",
    defaultMessage: "External link",
    description: "External link option for the CTA",
  },
  ctaExternalLinkButtonText: {
    id: "campaigns.campaignContentPage.ctaExternalLinkButtonText",
    defaultMessage: "Click Here",
    description: "Button text for the external link CTA",
  },
  ctaButtonOnlineBooking: {
    id: "campaigns.campaignContentPage.ctaButtonOnlineBooking",
    defaultMessage: "Online booking page",
    description: "Online booking option for the CTA",
  },
  ctaOnlineBookingButtonText: {
    id: "campaigns.campaignContentPage.ctaOnlineBookingButtonText",
    defaultMessage: "Book Now",
    description: "Button text for the online booking CTA",
  },
  ctaRequestForm: {
    id: "campaigns.campaignContentPage.ctaRequestForm",
    defaultMessage: "Request form",
    description: "Request form option for the CTA",
  },
  ctaRequestFormButtonText: {
    id: "campaigns.campaignContentPage.ctaRequestFormButtonText",
    defaultMessage: "Request a Quote",
    description: "Button text for the request form CTA",
  },
  ctaQuotesInClientHub: {
    id: "campaigns.campaignContentPage.ctaQuotesInClientHub",
    defaultMessage: "Quotes in client hub",
    description: "Quotes in client hub option for the CTA",
  },
  ctaQuotesInClientHubButtonText: {
    id: "campaigns.campaignContentPage.ctaQuotesInClientHubButtonText",
    defaultMessage: "View Quote",
    description: "Button text for the quotes in client hub CTA",
  },
  ctaLinkToPlaceholder: {
    id: "campaigns.campaignContentPage.ctaLinkToPlaceholder",
    defaultMessage: "Link to",
    description: "Placeholder for the CTA link to input",
  },
  ctaButtonTextPlaceholder: {
    id: "campaigns.campaignContentPage.ctaButtonTextPlaceholder",
    defaultMessage: "Button text",
    description: "Placeholder for the CTA button text input",
  },
  ctaButtonLinkPlaceholder: {
    id: "campaigns.campaignContentPage.ctaButtonLinkPlaceholder",
    defaultMessage: "Link",
    description: "Placeholder for the CTA button link input",
  },
  ctaButtonColorPlaceholder: {
    id: "campaigns.campaignContentPage.ctaButtonColorPlaceholder",
    defaultMessage: "Button color",
    description: "Placeholder for the CTA button color input",
  },
  urlValidationError: {
    id: "campaigns.campaignContentPage.urlValidationError",
    defaultMessage:
      'Enter a valid link in the format "https://www.example.com"',
    description: "Validation error message displayed with url is invalid",
  },
  emailSubjectLineInput: {
    id: "campaigns.campaignContentPage.emailSubjectLineInput",
    defaultMessage: "Subject line",
    description: "Heading for the email subject line input",
  },
  emailHeadingInput: {
    id: "campaigns.campaignContentPage.emailHeadingInput",
    defaultMessage: "Heading (optional)",
    description: "Heading for the heading input",
  },
  emailRichTextEditorHeading: {
    id: "campaigns.emailRichTextEditorHeading.emailHeadingInput",
    defaultMessage: "Message body",
    description: "Heading for the rich text editor",
  },
  urlRequiredError: {
    id: "campaigns.campaignReviewPage.urlRequiredError",
    defaultMessage: "Enter a link",
    description: "Error message when URL is required",
  },
  ctaTextRequiredError: {
    id: "campaigns.campaignReviewPage.ctaTextRequiredError",
    defaultMessage: "Enter button text",
    description: "Error message when CTA text is required",
  },
  ctaTextMaxLengthError: {
    id: "campaigns.campaignReviewPage.ctaTextMaxLengthError",
    defaultMessage: "Button text must not be longer than 30 characters",
    description: "Error message when CTA text is too long",
  },
  subjectMaxLengthError: {
    id: "campaigns.campaignReviewPage.subjectMaxLengthError",
    defaultMessage: "Email subject must not be longer than 150 characters",
    description: "Error message when email subject is too long",
  },
  showLogoInput: {
    id: "campaigns.campaignContentPage.showLogoInput",
    defaultMessage: "Show logo",
    description: "Label for the show logo input",
  },
  logoDisabledTooltip: {
    id: "campaigns.campaignContentPage.logoDisabledTooltip",
    defaultMessage: "There is no logo uploaded in branding settings",
    description: "Tooltip message when logo is disabled",
  },
});
