import React, { useCallback, useMemo, useState } from "react";
import type { PropsWithChildren } from "react";

export interface PromptType {
  message: string;
  widgetName?: string;
}

interface AssistantPromptEventQueueContextType {
  isEmpty: boolean;
  enqueuePrompt: (prompt: PromptType) => void;
  dequeuePrompt: () => PromptType | undefined;
}

const AssistantPromptEventQueueContext =
  React.createContext<AssistantPromptEventQueueContextType>({
    isEmpty: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    enqueuePrompt: () => {},
    dequeuePrompt: () => undefined,
  });

export function useAssistantPromptEventQueue() {
  return React.useContext(AssistantPromptEventQueueContext);
}

export function AssistantPromptEventQueueProvider(props: PropsWithChildren) {
  const [queue, setQueue] = useState<PromptType[]>([]);
  const isEmpty = useMemo(() => queue.length === 0, [queue]);
  const enqueuePrompt = useCallback(
    (prompt: PromptType) => {
      setQueue([...queue, prompt]);
    },
    [queue],
  );
  const dequeuePrompt = useCallback(() => {
    if (!isEmpty) {
      const [firstPrompt, ...rest] = queue;
      setQueue(rest);

      return firstPrompt;
    }
  }, [queue, isEmpty]);

  return (
    <AssistantPromptEventQueueContext.Provider
      value={{ dequeuePrompt, enqueuePrompt, isEmpty }}
    >
      {props.children}
    </AssistantPromptEventQueueContext.Provider>
  );
}
