import { defineMessages } from "react-intl";

export const messages = defineMessages({
  wrapperTitle: {
    id: "wrapper.header",
    defaultMessage: "We’ve turned on features to save you 7 hours/week",
    description: "Title of the page",
  },
  wrapperDescription: {
    id: "wrapper.description",
    defaultMessage:
      "See them in action below, then schedule your first job to start saving time with Jobber!",
    description: "Description of the content",
  },
  invoicesTitle: {
    id: "accordion.item.invoices.title",
    defaultMessage: "Automated quote and invoice follow-ups",
    description: "Title for invoice follow-ups",
  },
  invoicesDescription: {
    id: "accordion.item.invoices.description",
    defaultMessage:
      "Waiting on a payment or quote approval? Jobber will follow up with customers so you don't have to.",
    description: "Description of invoice follow-ups",
  },
  remindersTitle: {
    id: "accordion.item.reminders.title",
    defaultMessage: "Automated visit reminders",
    description: "Title for automated reminders",
  },
  remindersDescription: {
    id: "accordion.item.reminders.description",
    defaultMessage:
      "Jobber will send reminders to customers before the scheduled visit.",
    description: "description of automated reminders",
  },
  jobsTitle: {
    id: "accordion.item.jobs.title",
    defaultMessage: "Automated job follow-ups",
    description: "Title for job follow-ups",
  },
  jobsDescription: {
    id: "accordion.item.jobs.description",
    defaultMessage:
      "Jobber will follow-up to thank customers for their business and request feedback.",
    description: "Description of job follow-ups",
  },
  clienthubTitle: {
    id: "accordion.item.clienthub.title",
    defaultMessage: "Online client portal",
    description: "Title for client hub",
  },
  clienthubDescription: {
    id: "accordion.item.clienthub.description",
    defaultMessage:
      "Jobber's client hub is where your customer can request work, approve quotes, review scheduled work, and make payments.",
    description: "Description of client hub",
  },
  calendarTitle: {
    id: "accordion.item.calendar.title",
    defaultMessage: "Jobber calendar",
    description: "Title for calendar feature",
  },
  calendarDescription: {
    id: "accordion.item.calendar.description",
    defaultMessage:
      "Schedule an upcoming job, or create a test job to see how it works. You can always delete it later.",
    description: "Description of calendar feature",
  },
  sendASampleLabel: {
    id: "happypath.send.message",
    defaultMessage: "Send me a sample",
    description: "Label of the button",
  },
  clientHubLabel: {
    id: "client.hub",
    defaultMessage: "Client hub",
    description: "Label of the button",
  },
  scheduleJobsLabel: {
    id: "happypath.schedule.jobs",
    defaultMessage: "Schedule a job",
    description: "Label of the button",
  },
  doItLaterLabel: {
    id: "happypath.do.it.later",
    defaultMessage: "Do it later",
    description: "Label of the link",
  },
  sendSampleToastSuccessMessageEmailAndSMS: {
    id: "happypath.sendSampleToastSuccessMessageEmailAndSMS",
    defaultMessage: "Text message & email notification sent",
    description:
      "Success toast message for happy path experiment 'Send me a sample' email/sms actions",
  },
  sendSampleToastSuccessMessageEmailOnly: {
    id: "happypath.sendSampleToastSuccessMessageEmailOnly",
    defaultMessage: "Email notification sent",
    description:
      "Success toast message for happy path experiment 'Send me a sample' email only action",
  },
  sendSampleToastErrorMessage: {
    id: "happypath.sendSampleToastErrorMessage",
    defaultMessage: "We couldn't send a sample, please try again",
    description:
      "Error toast message for happy path experiment 'Send me a sample' action",
  },
});
