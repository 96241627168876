import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { Banner } from "@jobber/components/Banner";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  ScReferralsCardDataQuery,
  ScReferralsToggleFeatureMutation,
} from "~/utilities/API/graphql";
import { SCReferralCard } from ".";
import {
  SC_REFERRALS_CARD,
  SC_REFERRALS_TOGGLE,
} from "./SCReferralCard.graphql";

export function SCReferralCardLoader() {
  // Turn loading/data/error values into a UI state enum?
  const { loading, data, error } = useQuery<ScReferralsCardDataQuery>(
    SC_REFERRALS_CARD,
    { fetchPolicy: "cache-and-network" },
  );
  const [toggleFeature] =
    useMutation<ScReferralsToggleFeatureMutation>(SC_REFERRALS_TOGGLE);

  const onSwitch = async (enabled: boolean) => {
    await toggleFeature({
      variables: {
        enabled,
      },
    });
  };

  if (loading && !data) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Banner type="error">
        An error occurred when loading the page. Please reload and try again
      </Banner>
    );
  }

  if (data && data.feature) {
    if (data.feature.available) {
      return (
        <SCReferralCard
          switchState={data.feature.enabled}
          messagingSettings={data.clientHubReferralSettings}
          onSwitch={onSwitch}
        />
      );
    } else {
      return <></>;
    }
  }

  return (
    <Banner type="error">
      An error occurred when loading the page. Please reload and try again
    </Banner>
  );
}

// Need a wrapper because the component doing the GraphQL queries needs to be
// inside <APIProvider>
export function SCReferralCardWrapper() {
  return (
    <APIProvider>
      <SCReferralCardLoader />
    </APIProvider>
  );
}
