import React from "react";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import classNames from "classnames";
import { useIntl } from "react-intl";
import {
  WelcomeCard,
  WorkflowCard,
  type WorkflowCardProps,
} from "jobber/dashboard/components/WorkflowCard";
import { DoubleSparklineDataSource } from "jobber/dashboard/components/DoubleSparkline";
import {
  formatIndustryName,
  getIndustryVideoData,
  getWelcomeVideoData,
} from "jobber/dashboard/components/WorkflowCard/utils";
import { useCta } from "~/utilities/contexts/internal/useCtas";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import style from "./WorkflowCardList.module.css";

export interface HomeWorkFlowCardsProps extends WorkflowCardProps {
  chartType: string;
  primaryChartColor: string;
  primaryChartLabel: string;
  primaryDataKey: string;
  secondaryChartColor: string;
  secondaryChartLabel: string;
  secondaryDataKey: string;
}

interface WorkflowCardsListProps {
  workflowCards: HomeWorkFlowCardsProps[];
  workRequestEnabled: boolean;
  metricsApiUrl: string;
  currencySymbol: string;
  workflowItemCounts: { [key: string]: number };
  accountSubscribedOrPaying: boolean;
  industry: string;
}

const customWidths = {
  medium: 580,
  large: 890,
};

export function WorkflowCardList({
  workflowCards,
  workRequestEnabled,
  metricsApiUrl,
  currencySymbol,
  workflowItemCounts,
  accountSubscribedOrPaying,
  industry,
}: WorkflowCardsListProps) {
  const { formatMessage } = useIntl();
  const [ref, { exactWidth = 880 }] = useResizeObserver<HTMLDivElement>({
    widths: customWidths,
  });

  const [welcomeCardNotDismissed, markWelcomeCardDismissed] = useCta(
    "new_home_welcome_card_dismissed",
  );

  const { id, text } = getWelcomeVideoData(industry);

  return (
    <div ref={ref} className={classNames(style.cardStyling, getWidthClass())}>
      {shouldShowWelcomeCard() && (
        <div key={"card"}>
          <div
            className={classNames(
              "u-fullWidth u-marginBottomSmall",
              style.fullHeight,
            )}
          >
            <div
              className={classNames("u-paddingBottomSmall", style.fullHeight)}
            >
              <WelcomeCard
                onDismiss={() => {
                  markWelcomeCardDismissed();
                  return trackWelcomeCardDismissed();
                }}
                welcomeVideoData={{
                  id,
                  text: formatMessage(text, {
                    industryName: formatIndustryName(industry),
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {workflowCards.map((card, key) => {
        const showEmptyState =
          shouldShowEmptyState(card) && !accountSubscribedOrPaying;
        return (
          shouldShowWorkFlowCard(card.title) && (
            <div key={key} className={style.flexCardStyling}>
              <div
                className={classNames(
                  "u-fullWidth",
                  "u-marginBottomSmall",
                  style.flexColumnCardStyling,
                  {
                    [style.emptyStateCard_container]: showEmptyState,
                  },
                )}
              >
                <WorkflowCard
                  title={card.title}
                  status={card.status}
                  accentColor={card.accentColor}
                  primaryCount={card.primaryCount}
                  primaryAmount={card.primaryAmount}
                  primaryUrl={card.primaryUrl}
                  primaryCaption={card.primaryCaption}
                  allStatusData={card.allStatusData}
                  showEmptyState={showEmptyState}
                />
                {card.chartType && !showEmptyState && (
                  <div
                    className={classNames(
                      "card u-borderTopNone",
                      style.chartStyling,
                    )}
                  >
                    <DoubleSparklineDataSource
                      apiUrl={metricsApiUrl}
                      type={card.chartType}
                      primaryColor={card.primaryChartColor}
                      primaryLabel={card.primaryChartLabel}
                      primaryDataKey={card.primaryDataKey}
                      secondaryColor={card.secondaryChartColor}
                      secondaryLabel={card.secondaryChartLabel}
                      secondaryDataKey={card.secondaryDataKey}
                      currencySymbol={currencySymbol}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );

  function shouldShowWelcomeCard() {
    return (
      welcomeCardNotDismissed &&
      getWorkflowItemCount("requests") === 0 &&
      !accountSubscribedOrPaying
    );
  }

  function shouldShowWorkFlowCard(cardTitle: string) {
    if (cardTitle !== "Requests") return true;
    if (!workRequestEnabled) return false;

    return !shouldShowWelcomeCard();
  }

  function shouldShowEmptyState(card: HomeWorkFlowCardsProps | undefined) {
    if (card === undefined) {
      return false;
    }

    return getWorkflowItemCount(card.title) === 0;
  }

  function getWorkflowItemCount(itemType: string): number | undefined {
    const itemKey = itemType.toLowerCase();
    if (itemKey in workflowItemCounts === false) {
      return undefined;
    }
    return workflowItemCounts[itemKey];
  }

  function getWidthClass() {
    if (exactWidth < customWidths.medium) return style.oneColumn;
    if (exactWidth < customWidths.large) return style.twoColumns;

    return workRequestEnabled || shouldShowWelcomeCard()
      ? style.fourColumns
      : style.threeColumns;
  }

  function trackWelcomeCardDismissed() {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: getIndustryVideoData(industry)?.industry ?? "Graham",
      interaction: "Dismissed Video Component",
    });
  }
}
