/* eslint-disable import/no-internal-modules */
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import { Spinner } from "@jobber/components/Spinner";
import clipboardCopy from "clipboard-copy";
import { showToast } from "@jobber/components/Toast";
import type { ActionProps } from "@jobber/components/Menu";
import { useSetPageTitle } from "jobber/settings/users/hooks/useSetPageTitle";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/settings/selfServeBookings/analytics/AnalyticsEvents";
import { withSplitClient } from "utilities/split";
import { useQueryConfiguration, useUpdateConfiguration } from "./hooks";
import { messages } from "./messages";
import { SettingsPage } from "./SettingsPage";
import { CompanyLocationContext } from "./components/CompanyLocationContext/CompanyLocationContext";
import { ShareLinkFooter } from "./components/ShareLinkFooter/ShareLinkFooter";
import styles from "./styles.module.css";
import type { Configuration } from "../../types";
import { ProductAndServiceSearchModal } from "../OfferingsSettingsPage/components/OfferingsCard/components/ProductAndServiceSearchModal";

export const SettingsPageLoader = withSplitClient(InternalSettingsPageLoader);

// eslint-disable-next-line max-statements
function InternalSettingsPageLoader() {
  const { formatMessage } = useIntl();

  const { configuration, loading, error } = useQueryConfiguration();
  const { editSelfServeSettings } = useUpdateConfiguration();
  const displayContent = !loading && !error;
  const canEnableBooking =
    configuration.servicesCount > 0 &&
    configuration.bookableUsers.totalCount > 0;
  useSetPageTitle(formatMessage(messages.header));

  const { trackClickPreview, trackClickCopyBookingUrl, trackToggleFeature } =
    useSettingsPageAmplitudeTracking();

  const onClickPreview = useCallback(() => {
    window.open(configuration.bookingUrl, "_blank", "noopener, noreferrer");

    trackClickPreview(configuration.acceptingOnlineBookings);
  }, [
    configuration.acceptingOnlineBookings,
    configuration.bookingUrl,
    trackClickPreview,
  ]);

  const onClickCopy = useCallback(async () => {
    await clipboardCopy(configuration.bookingUrl).then(() => {
      trackClickCopyBookingUrl();
    });
  }, [configuration.bookingUrl, trackClickCopyBookingUrl]);

  const onToggleBooking = useCallback(async () => {
    const newSetting = !configuration.acceptingOnlineBookings;
    if (!newSetting || canEnableBooking) {
      await editSelfServeSettings({ acceptingOnlineBookings: newSetting }).then(
        () => {
          trackToggleFeature(newSetting);
          showToast({
            message: formatMessage(
              newSetting ? messages.toggledOnLabel : messages.toggledOffLabel,
            ),
          });
        },
        () => {
          showToast({
            message: formatMessage(messages.errorToggling),
            variation: "error",
          });
        },
      );
    }
  }, [
    canEnableBooking,
    configuration.acceptingOnlineBookings,
    editSelfServeSettings,
    formatMessage,
    trackToggleFeature,
  ]);

  const moreActions = useMemo(() => {
    const actions: ActionProps[] = [
      {
        label: formatMessage(messages.copyLabel),
        icon: "copy",
        onClick: onClickCopy,
      },
      {
        label: formatMessage(messages.previewLabel),
        icon: "eye",
        onClick: onClickPreview,
      },
    ];

    if (configuration.acceptingOnlineBookings) {
      actions.push({
        label: formatMessage(messages.toggleOffLabel),
        onClick: onToggleBooking,
      });
    } else if (canEnableBooking) {
      actions.push({
        label: formatMessage(messages.toggleOnLabel),
        onClick: onToggleBooking,
      });
    }

    return actions;
  }, [
    formatMessage,
    onClickCopy,
    onClickPreview,
    configuration.acceptingOnlineBookings,
    canEnableBooking,
    onToggleBooking,
  ]);

  return (
    <div className={styles.pageWithFooterContainer}>
      <div className={styles.pageWithFooter}>
        <Page
          title={formatMessage(messages.header)}
          intro={formatMessage(messages.intro)}
          width="fill"
          moreActionsMenu={
            displayContent ? [{ actions: moreActions }] : undefined
          }
        >
          <Content spacing="large">
            {loading && <Spinner />}
            {error && <Banner type="error">{error}</Banner>}
            {displayContent && (
              <CompanyLocationContext.Provider
                value={configuration.companyLocation}
              >
                <SettingsPage configuration={configuration} />
              </CompanyLocationContext.Provider>
            )}
          </Content>
        </Page>
      </div>
      {displayContent && (
        <ShareLinkFooter
          canEnableBooking={canEnableBooking}
          banners={<ActivationWarning configuration={configuration} />}
          bookingUrl={configuration.bookingUrl}
          googleSharingSettings={configuration.googleSharingSettings}
          onCopyBookingUrl={trackClickCopyBookingUrl}
          onClickPreview={() =>
            trackClickPreview(configuration.acceptingOnlineBookings)
          }
        />
      )}
    </div>
  );
}

function useSettingsPageAmplitudeTracking() {
  const trackClickPreview = useCallback((enabled: boolean) => {
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.selfServeBookingsSettings.clickedPreviewButton,
      {
        app: "Online",
        enabled,
      },
    );
  }, []);

  const trackClickCopyBookingUrl = useCallback(() => {
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.selfServeBookingsSettings.clickedCopyBookingUrl,
      {
        source:
          ANALYTICS_EVENTS.selfServeBookingsSettings.copyBookingUrlSources
            .bookingSettingsPage,
      },
    );
  }, []);

  const trackToggleFeature = useCallback((currentState: boolean) => {
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.selfServeBookingsSettings.toggledOLBFeature,
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        accepting_bookings: currentState,
        source:
          ANALYTICS_EVENTS.selfServeBookingsSettings.copyBookingUrlSources
            .bookingSettingsPage,
      },
    );
  }, []);

  return { trackClickPreview, trackClickCopyBookingUrl, trackToggleFeature };
}

function ActivationWarning({
  configuration,
}: {
  configuration: Configuration;
}) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  if (configuration.servicesCount === 0) {
    if (configuration.bookableUsers.totalCount === 0) {
      return (
        <Banner type="warning" dismissible={false}>
          {formatMessage(messages.validationMinimumServicesAndUsers)}
        </Banner>
      );
    } else {
      return (
        <>
          <ProductAndServiceSearchModal
            open={modalOpen}
            closeSearchModal={() => setModalOpen(false)}
          />
          <Banner
            type="warning"
            dismissible={false}
            primaryAction={{
              label: formatMessage(messages.validationAddService),
              type: "secondary",
              onClick: () => setModalOpen(true),
            }}
          >
            {formatMessage(messages.validationMinimumServices)}
          </Banner>
        </>
      );
    }
  } else if (configuration.bookableUsers.totalCount === 0) {
    return (
      <Banner type="warning" dismissible={false}>
        {formatMessage(messages.validationMinimumUsers)}
      </Banner>
    );
  } else if (!configuration.acceptingOnlineBookings) {
    return (
      <Banner type="warning" dismissible={false}>
        {formatMessage(messages.validationOnlineBookingTurnedOff)}
      </Banner>
    );
  }
  return null;
}
