import React, { useEffect } from "react";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { Spinner } from "@jobber/components/Spinner";
import { useMutation, useQuery } from "@apollo/client";
import { CTA_DISMISS, CTA_QUERY, CTA_SHOWN } from "./useOnlinePaymentsQueries";

interface DismissMandatoryCardCTAProps {
  showDismissButton: boolean;
  loading?: boolean;
}

const ctaId = "show_mandatory_card_on_file_within_quotes_creation";

export const DismissMandatoryCardCTA: React.FC<
  DismissMandatoryCardCTAProps
> = ({ showDismissButton, loading }) => {
  const { loading: ctaLoading, error: ctaError } = useQuery(CTA_QUERY, {
    variables: { id: ctaId },
  });

  const [setHasIncrementedCTA] = useMutation(CTA_SHOWN);

  useEffect(() => {
    const incrementCTA = async () => {
      try {
        if (showDismissButton) {
          await setHasIncrementedCTA({
            variables: { id: ctaId },
          });
        }
      } catch (error) {
        new Error("Something went wrong");
      }
    };

    void incrementCTA();
  }, [showDismissButton, setHasIncrementedCTA]);

  const [dismissCTA, { loading: dismissLoading }] = useMutation(CTA_DISMISS);

  const handleDismiss = async () => {
    try {
      const response = await dismissCTA({ variables: { id: ctaId } });

      if (
        response.data?.ctaDismiss?.callToAction?.id === ctaId &&
        response.data?.ctaDismiss?.callToAction?.shouldShow === false
      ) {
        showToast({ message: "Notification dismissed" });
      } else {
        showToast({ message: "Something went wrong" });
      }
    } catch (_error) {
      showToast({ message: `Something went wrong` });
    }
  };

  if (loading || ctaLoading || dismissLoading) return <Spinner size={"base"} />;

  if (ctaError) return <p>Error fetching CTA data</p>;

  return (
    <>
      {showDismissButton && (
        <Button
          label={"Don't show this again"}
          onClick={handleDismiss}
          variation={"learning"}
          type={"primary"}
          size={"small"}
          fullWidth={false}
        />
      )}
    </>
  );
};
