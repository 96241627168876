import React from "react";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import {
  formatPrice,
  isAddonSelected,
  pricePerPeriod,
} from "~/shared/billing/pricePreview/utils";
import { messages } from "./messages";
import styles from "./AddonBillingDetails.module.css";
import { CreditInfo } from "../CreditInfo/CreditInfo";

interface AddonBillingDetailsProps {
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  totalAddonsCredit: number;
}

export function AddonBillingDetails({
  subscriptionAddons,
  selectedAddonCodes,
  totalAddonsCredit,
}: AddonBillingDetailsProps) {
  const { formatMessage } = useIntl();

  return (
    <Content>
      {selectedAddonCodes.length > 0 && (
        <Heading level={3}>{formatMessage(messages.header)}</Heading>
      )}
      {subscriptionAddons.map(
        (subscriptionAddon, index) =>
          isAddonSelected(selectedAddonCodes, subscriptionAddon) && (
            <div key={`addon-billing-details-${index}`}>
              <div
                className={styles.container}
                data-testid={`addon-billing-details-${subscriptionAddon.name.toLowerCase()}`}
              >
                <Text size="large">{subscriptionAddon.name}</Text>
                <Text size="large">
                  <Emphasis variation="bold">
                    {pricePerPeriod(
                      subscriptionAddon.monthlyBillingCycle.monthlyCost,
                      "month",
                    )}
                  </Emphasis>
                </Text>
              </div>
              {!!(
                subscriptionAddon.previewGroup.monthlyPreview
                  ?.proratedDiscount &&
                subscriptionAddon.previewGroup.monthlyPreview
                  ?.proratedDiscount > 0
              ) && (
                <div
                  className={styles.container}
                  data-testid={`${subscriptionAddon.name.toLowerCase()}-discount`}
                >
                  <Text size="large">
                    {formatMessage(messages.discountHeader)}
                  </Text>
                  <Text size="large">
                    <Emphasis variation="bold">
                      {formatMessage(messages.discountAmount, {
                        discountAmount: formatPrice(
                          subscriptionAddon.previewGroup.monthlyPreview
                            .proratedDiscount,
                        ),
                      })}
                    </Emphasis>
                  </Text>
                </div>
              )}
            </div>
          ),
      )}
      {!!totalAddonsCredit && (
        <div
          className={styles.container}
          data-testid="addon-billing-details-credit"
        >
          <CreditInfo totalCredit={totalAddonsCredit} />
        </div>
      )}
    </Content>
  );
}
