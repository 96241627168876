import React from "react";
import { type ActionProps, Menu } from "@jobber/components/Menu";
import { Button } from "@jobber/components/Button";
import type {
  InputSearchFilterOption,
  InputSearchFilterProps,
} from "components/InputSearch/InputSearch";

// The Menu SectionProps expects actions in the following format (see Menu.d.ts).
type MenuAction = Omit<ActionProps, "shouldFocus">;

export function FilterButton({
  filterOptions,
  onFilterChange,
}: InputSearchFilterProps) {
  function convertInputSearchFilterOptionToMenuAction(
    filterOption: InputSearchFilterOption,
  ): MenuAction {
    return {
      label: filterOption.label,
      icon: filterOption.icon,
      onClick: () => {
        onFilterChange(filterOption.value);
      },
    };
  }

  return (
    <Menu
      activator={
        <Button
          icon="filter"
          variation="subtle"
          type="tertiary"
          ariaLabel="Search Filter"
        />
      }
      items={[
        {
          actions: filterOptions.map(
            convertInputSearchFilterOptionToMenuAction,
          ),
        },
      ]}
    />
  );
}
