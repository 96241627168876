import React, { useState } from "react";
import { format } from "date-fns";
import { Text } from "@jobber/components/Text";
import { type MessageDescriptor, useIntl } from "react-intl";
import styles from "./OverrideDates.module.css";
import { messages } from "./messages";

interface OverrideDatesProps {
  overrideDates: string[];
}

export function OverrideDates({ overrideDates }: OverrideDatesProps) {
  const { formatMessage } = useIntl();
  const [showAll, setShowAll] = useState(false);

  const formattedDates = overrideDates.map(date =>
    format(new Date(date), "MMM d"),
  );

  const formatVariationDatesString = formatVariationDates(
    formattedDates,
    showAll,
    formatMessage,
  );

  return (
    <div className={styles.overrideDates}>
      <Text size="small">{formatVariationDatesString}</Text>
      <ShowMoreDatesButton
        formattedDates={formattedDates}
        showAll={showAll}
        onClick={() => setShowAll(!showAll)}
      />
    </div>
  );
}

interface ShowMoreDatesButtonProps {
  formattedDates: string[];
  showAll: boolean;
  onClick: () => void;
}

function ShowMoreDatesButton({
  formattedDates,
  showAll,
  onClick,
}: ShowMoreDatesButtonProps) {
  const { formatMessage } = useIntl();
  const preventClickPropagationAndClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  const remainingDates = formattedDates.slice(3);

  if (remainingDates.length === 0) {
    return <></>;
  }

  const viewLessCopy = ` ${formatMessage(messages.viewLessCopy)}`;

  const showMoreCopy = ` ${formatMessage(
    remainingDates.length == 1
      ? messages.showMoreCopySingleVisit
      : messages.showMoreCopyMultiVisit,
    {
      remainingVisitCount: remainingDates.length,
    },
  )}`;

  return (
    <div
      className={styles.showMoreDates}
      onClick={preventClickPropagationAndClick}
      aria-hidden="true"
    >
      <Text size="small">{showAll ? viewLessCopy : showMoreCopy}</Text>
    </div>
  );
}

function formatVariationDates(
  formattedDates: string[],
  showAll: boolean,
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string,
) {
  return showAll || formattedDates.length <= 3
    ? formatVariationDatesFull(formattedDates, formatMessage)
    : formatVariationDatesWithHiddenVisits(formattedDates, formatMessage);
}

function formatVariationDatesFull(
  formattedDates: string[],
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string,
) {
  const formattedVariationStringFull = formatMessage(
    messages.formattedVariationStringFull,
    { partialOverrideDatesList: formattedDates.join(", ") },
  );

  return formattedVariationStringFull;
}

function formatVariationDatesWithHiddenVisits(
  formattedDates: string[],
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string,
) {
  const visibleDates = formattedDates.slice(0, 3);

  const formattedVariationStringPartial = formatMessage(
    messages.formattedVariationStringPartial,
    { partialOverrideDatesList: visibleDates.join(", ") },
  );

  return formattedVariationStringPartial;
}
