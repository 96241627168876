import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import {
  type PaymentMethodCreateInput,
  type PaymentMethodCreateMutation,
  type PaymentMethodCreateMutationVariables,
  type PaymentMethodSetupIntentCreateMutation,
  type PaymentMethodSetupIntentCreateMutationVariables,
  type PaymentMethodSetupIntentType,
  PaymentMethodSource,
  PaymentMethodVaultOrigin,
} from "~/utilities/API/graphql";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import {
  PAYMENT_METHOD_CREATE,
  PAYMENT_METHOD_SETUP_INTENT_CREATE,
} from "./AddPaymentMethod.graphql";
import { messages } from "./messages";

export const useAddPaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [createSetupIntent] = useMutation<
    PaymentMethodSetupIntentCreateMutation,
    PaymentMethodSetupIntentCreateMutationVariables
  >(PAYMENT_METHOD_SETUP_INTENT_CREATE);
  const [createPaymentMethod] = useMutation<
    PaymentMethodCreateMutation,
    PaymentMethodCreateMutationVariables
  >(PAYMENT_METHOD_CREATE);
  const { formatMessage } = useIntl();

  const createIntentSetup = useCallback(
    async (type: PaymentMethodSetupIntentType): Promise<string | null> => {
      const response = await createSetupIntent({
        variables: {
          input: {
            setupIntentType: type,
          },
        },
      });

      if (
        response.errors ||
        !response.data?.paymentMethodSetupIntentCreate?.clientSecret
      ) {
        throw new Error(formatMessage(messages.errorAddingCreditCard));
      }

      const clientSecret =
        response.data.paymentMethodSetupIntentCreate.clientSecret;

      return clientSecret;
    },
    [createSetupIntent, formatMessage],
  );

  const getStripePaymentMethodId = useCallback(
    async (options: {
      clientSecret: string;
      name: string;
      address: BillingAddress;
    }): Promise<string> => {
      if (!stripe || !elements) {
        throw new Error(formatMessage(messages.stripeNotInitialized));
      }

      const card = elements.getElement(CardElement);

      if (card === null) {
        throw new Error(formatMessage(messages.errorAddingCreditCard));
      }

      const { clientSecret, name, address } = options;

      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          /* eslint-disable @typescript-eslint/naming-convention */
          payment_method: {
            billing_details: {
              name,
              address: {
                city: address.city,
                country: address.country_code,
                line1: address.street1,
                line2: address.street2,
                postal_code: address.pc,
                state: address.province,
              },
            },
            card: card,
          },
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      );

      if (error) throw new Error(error.message);
      if (!setupIntent?.payment_method) {
        throw new Error(formatMessage(messages.errorAddingCreditCard));
      }

      return setupIntent.payment_method as string;
    },
    [stripe, elements, formatMessage],
  );

  const storePaymentMethod = useCallback(
    async (
      paymentMethodId: string,
      clientId: string,
    ): Promise<string | null> => {
      const input: PaymentMethodCreateInput = {
        defaultMethod: true,
        paymentMethodSourceType: PaymentMethodSource.CREDIT_CARD,
        stripePaymentMethodId: paymentMethodId,
        vaultOrigin: PaymentMethodVaultOrigin.JOBBER_ONLINE_CLIENT_ORIGIN,
      };

      const response = await createPaymentMethod({
        variables: {
          input: input,
          clientId: clientId,
        },
      });

      if (
        response.errors ||
        !response.data?.paymentMethodCreate?.paymentMethod?.uuid
      ) {
        throw new Error(formatMessage(messages.errorAddingCreditCard));
      }

      return response.data?.paymentMethodCreate?.paymentMethod?.uuid;
    },
    [createPaymentMethod, formatMessage],
  );

  const handleCreatePaymentMethod = useCallback(
    async (
      type: PaymentMethodSetupIntentType,
      clientId: string,
      nameOnCard: string,
      billingAddress: BillingAddress,
    ): Promise<string | null> => {
      if (
        !nameOnCard ||
        !billingAddress?.street1 ||
        !billingAddress?.pc ||
        !billingAddress?.city ||
        !billingAddress?.country_code
      ) {
        throw new Error(
          formatMessage(messages.errorNameOnCardAndBillingAddress),
        );
      }

      const clientSecret = await createIntentSetup(type);
      if (!clientSecret) {
        throw new Error(formatMessage(messages.errorAddingCreditCard));
      }

      const paymentMethodId = await getStripePaymentMethodId({
        clientSecret: clientSecret,
        name: nameOnCard,
        address: billingAddress,
      });

      const uuid = await storePaymentMethod(paymentMethodId, clientId);

      return uuid;
    },
    [
      createIntentSetup,
      getStripePaymentMethodId,
      storePaymentMethod,
      formatMessage,
    ],
  );

  return {
    handleCreatePaymentMethod,
  };
};
