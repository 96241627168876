import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Checkbox } from "@jobber/components/Checkbox";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { SideDrawer } from "legacy/components/SideDrawer";
import styles from "./ReportColumnSelect.module.css";
import { messages } from "./messages";
import type { ReportColumnSelectProps } from "./types";

export const ReportColumnSelect = ({
  onButtonClick,
  onRequestDrawerClose,
  columnVisibilityConfig,
  onSave,
}: ReportColumnSelectProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityConfig);

  // validating custom fields and changes to configuration
  useEffect(() => {
    if (
      JSON.stringify(visibleColumns.map(i => i.id)) !=
      JSON.stringify(columnVisibilityConfig.map(i => i.id))
    ) {
      setVisibleColumns(columnVisibilityConfig);
    }
  }, [columnVisibilityConfig, setVisibleColumns, visibleColumns]);

  const handleButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
  ) => {
    setOpen(true);
    if (onButtonClick) {
      onButtonClick(event);
    }
  };

  const handleCancel = () => {
    setVisibleColumns(columnVisibilityConfig);
    handleDrawerClose();
  };

  const handleDrawerClose = () => {
    if (onRequestDrawerClose) {
      onRequestDrawerClose();
    }
    setOpen(false);
  };

  const changeColumnVisibility = (newValue: boolean, id: string) => {
    const newColumnVisibilityConfig = visibleColumns.map(column => {
      if (column.id === id) {
        return {
          ...column,
          checked: newValue,
        };
      }
      return column;
    });
    setVisibleColumns(newColumnVisibilityConfig);
  };

  const handleShowAll = () => {
    const showAllColumns = visibleColumns.map(column => ({
      ...column,
      checked: true,
    }));
    setVisibleColumns(showAllColumns);
  };

  const getHiddenCount = () => {
    return visibleColumns.filter(column => !column.checked).length;
  };

  const hiddenColumnsCount = getHiddenCount();

  return (
    <>
      <SideDrawer
        open={open}
        title={formatMessage(messages.columnSelectHeaderTitle)}
        requestDrawerClose={handleDrawerClose}
      >
        <div className={styles.reportSideDrawerContent}>
          <div className={styles.cardBody}>
            <div className={styles.sectionHeader}>
              <Text>
                <strong>
                  {formatMessage(messages.hiddenColumnsCount, {
                    hiddenColumnsCount,
                  })}
                </strong>
                {hiddenColumnsCount > 0 && (
                  <>
                    {" | "}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Grandfathered error: Please fix if touching this code. */}
                    <strong
                      className={styles.showAll}
                      onClick={() => handleShowAll()}
                    >
                      {formatMessage(messages.showAllColumns)}
                    </strong>
                  </>
                )}
              </Text>
            </div>
            <div className={styles.columnList}>
              {visibleColumns.map(column => (
                <div key={column.id} className={styles.listItem}>
                  <Checkbox
                    checked={column.checked}
                    label={column.label}
                    onChange={newValue =>
                      changeColumnVisibility(newValue, column.id)
                    }
                  />
                </div>
              ))}
            </div>
            <div className={styles.reportSideDrawerFooter}>
              <Button
                label={formatMessage(messages.columnSelectCancelLabel)}
                onClick={() => handleCancel()}
                variation="subtle"
              />
              <Button
                label={formatMessage(messages.columnSelectSaveLabel)}
                onClick={() => {
                  onSave(visibleColumns);
                  handleDrawerClose();
                }}
              />
            </div>
          </div>
        </div>
      </SideDrawer>
      <div>
        <Button
          type="primary"
          variation="subtle"
          label={formatMessage(messages.columnsLabel)}
          icon="tableColumns"
          onClick={handleButtonClick}
        />
      </div>
    </>
  );
};
