import { useState } from "react";

export function useSelectAndSavePhoneNumber(
  title: string,
  requiresRegistration: boolean,
) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [stepHasError, setStepHasError] = useState(false);
  const content = getStepContent(
    requiresRegistration,
    currentStep,
    stepHasError,
    title,
  );

  return {
    dynamicTitle: content.title,
    primaryLabel: content.primaryLabel,
    tertiaryLabel: content.tertiaryLabel,
    content: content.content,
    step: currentStep,
    totalSteps: requiresRegistration ? 3 : 2,
    moveToNextStep: moveToNextStep,
    moveToPreviousStep: moveToPreviousStep,
    goToErrorState,
  };

  function goToErrorState(hasError: boolean) {
    setStepHasError(hasError);
  }

  function moveToNextStep() {
    setStepHasError(false);
    const next = nextStep(requiresRegistration, currentStep);
    setCurrentStep(next);
  }

  function moveToPreviousStep() {
    setStepHasError(false);
    setCurrentStep(1);
  }
}

// 1 = dpn selection
// 2 = A2P registration
// 3 = Success
function nextStep(requiresRegistration: boolean, currentStep: number) {
  if (currentStep === 1 && requiresRegistration) {
    return 2;
  }

  if ((currentStep === 1 && !requiresRegistration) || currentStep === 2) {
    return 3;
  }

  return 1;
}

function getStepContent(
  requiresRegistration: boolean,
  currentStep: number,
  errorStep: boolean,
  title?: string,
) {
  const stepNumber = requiresRegistration ? `Step ${currentStep}: ` : "";

  if (errorStep) {
    return {
      title: "Choose Your Number",
      primaryLabel: "Choose Number",
      tertiaryLabel: "Cancel",
      content: "DpnIsTaken",
    };
  }

  if (currentStep === 2) {
    return {
      title: `${stepNumber}Register Your Number`,
      primaryLabel: "Submit",
      tertiaryLabel: "Back",
      content: "Registration",
    };
  }

  if (currentStep === 3) {
    return {
      title: "You're All Set!",
      primaryLabel: requiresRegistration ? "Learn More" : "Customize Settings",
      tertiaryLabel: "Maybe Later",
      content: "Success",
    };
  }

  return {
    title: title,
    primaryLabel: requiresRegistration ? "Next Step" : "Submit",
    tertiaryLabel: "Cancel",
    content: "ChooseNumber",
  };
}
