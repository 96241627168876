/* eslint-disable max-statements */
import React, {
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Content } from "@jobber/components/Content";
import { Banner } from "@jobber/components/Banner";
import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { useOnKeyDown } from "@jobber/hooks/useOnKeyDown";
import { CodeEntryInput } from "jobber/OTP/components/OTPInput/CodeEntryInput/CodeEntryInput";
import type {
  SendOtpMutation,
  SubmitOtpMutation,
} from "~/utilities/API/graphql";
import { SEND_OTP_MUTATION, SUBMIT_OTP_MUTATION } from "./OTPInput.graphql";

export interface OTPInputRef {
  submit(): void;
  sendCode(): void;
}

export interface OTPInputProps {
  children?: React.ReactNode;
  onSuccess(): void;
  setDisabled(disabled: boolean): void;
  setSubmitting(submitting: boolean): void;
}

export const OTPInput = forwardRef(OTPInputInternal);

function OTPInputInternal(
  { children, onSuccess, setDisabled, setSubmitting }: OTPInputProps,
  ref: Ref<OTPInputRef>,
) {
  const [code, setCode] = useState("");
  const debouncedSendCode = debounce(handleSendCode, 30000);
  const [bannerMessage, setBannerMessage] = useState<string>();
  const [submitable, setSubmitable] = useState(false);
  const [bannerVariation, setBannerVariation] = useState<"warning" | "error">(
    "error",
  );

  const [sendCodeMutation] = useMutation<SendOtpMutation>(SEND_OTP_MUTATION);
  const [submitCodeMutation] =
    useMutation<SubmitOtpMutation>(SUBMIT_OTP_MUTATION);

  useEffect(() => {
    if (/^\d{6}$/gm.test(code)) {
      setDisabled(false);
      setSubmitable(true);
      return;
    }
    setDisabled(true);
    setSubmitable(false);
  }, [code]);

  useEffect(() => {
    handleSendCode().catch((e: Error) => {
      throw e;
    });
  }, []);

  useImperativeHandle(ref, () => ({
    submit: () => {
      gracefullyHandleSubmit();
    },
    sendCode: () => {
      dismissBanner();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      debouncedSendCode();
    },
  }));

  useOnKeyDown(() => {
    if (submitable) {
      setSubmitting(true);
      gracefullyHandleSubmit();
    }
  }, "Enter");

  return (
    <Content>
      {bannerMessage && (
        <Banner type={bannerVariation} onDismiss={dismissBanner}>
          {bannerMessage}
        </Banner>
      )}
      {children}
      <CodeEntryInput value={code} onChange={setCode} />
    </Content>
  );

  function dismissBanner() {
    setBannerMessage(undefined);
  }

  async function handleSendCode() {
    const result = await sendCodeMutation();

    const error = result?.data?.sendVerificationCode.userErrors[0];

    if (error) {
      setBannerMessage(error.message);
      setBannerVariation("error");
    }
  }

  function gracefullyHandleSubmit() {
    handleSubmit().catch((e: Error) => {
      throw e;
    });
  }

  async function handleSubmit() {
    try {
      const result = await submitCodeMutation({
        variables: {
          code: code,
        },
      });

      const status = result?.data?.submitVerificationCode.status;
      const error = result?.data?.submitVerificationCode.userErrors[0];

      if (status === "approved") {
        onSuccess();
      } else if (error) {
        switch (error.path[1]) {
          case "warning":
            setBannerVariation("warning");
            break;
          default:
            setBannerVariation("error");
        }
        setBannerMessage(error.message);
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      setBannerVariation("error");
      setBannerMessage(error as string);
    }
  }
}
