import React from "react";
import styles from "./BigValue.module.css";

export interface BigValueProps {
  children?: React.ReactNode;
  link?: {
    url: string;
    external?: boolean;
  };
}

export function BigValue({ children, link }: BigValueProps) {
  return link ? (
    <a
      href={link.url}
      target={link.external ? "_blank" : ""}
      rel={link.external ? "noreferrer" : ""}
      className={styles.bigValue}
    >
      {children}
    </a>
  ) : (
    <p className={styles.bigValue}>{children}</p>
  );
}
