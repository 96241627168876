import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./CoreToConnectTrialOnboardingMobileCard.module.css";

export function CoreToConnectTrialOnboardingMobileCard() {
  const [showMobileCard, setShowMobileCard] = useState(true);

  return showMobileCard ? (
    <div className={styles.onboardingMobileCardContainer}>
      <Card header={{ title: "Discover Connect" }}>
        <Content>
          <Text size="large">
            Set up custom job fields & job forms, track expenses, and turn on
            automated reminders - all in Settings.
          </Text>
          <div className={styles.buttonContainer}>
            <Button
              label="Got It"
              variation="learning"
              onClick={handleButtonClick}
            />
          </div>
        </Content>
      </Card>
    </div>
  ) : (
    <></>
  );

  function handleButtonClick() {
    setShowMobileCard(false);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "core_to_connect_trial_home_onboarding_cta",
      type: "mobile_card",
    });
  }
}
