import { type MutableRefObject, useEffect, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import type {
  ActivateCoreToConnectTrialMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ACTIVATE_CORE_TO_CONNECT_TRIAL } from "./ActivateCoreToConnectTrial.graphql";
import styles from "./CoreToConnectTrialBeginModal.module.css";

const modalTimelineContent: {
  icon: IconNames;
  heading: string;
  content: string;
}[] = [
  {
    icon: "sparkles",
    heading: "Today",
    content: "Gain access to all Connect features",
  },
  {
    icon: "knot",
    heading: "Day 12",
    content: "We'll remind you 2 days before your trial ends",
  },
  {
    icon: "checkmark",
    heading: "Day 14",
    content: "Your account will revert to your Core plan",
  },
];

export enum CoreToConnectTrialStartSource {
  DEEPLINKED = "deeplinked",
  AUTOTRIGGERED = "auto-triggered",
  OLB_PLE = "online-booking-ple",
  UPGRADE_PAGE = "upgrade-page",
  EXPANSION_LANDING_PAGE = "expansion-landing-page",
  CUSTOM_FIELDS_TIP_CARD = "custom-fields-tip-card",
}

export interface CoreToConnectTrialBeginModalProps {
  source: CoreToConnectTrialStartSource;
  showModal: boolean;
  dismissModal: () => void;
}

export function CoreToConnectTrialBeginModal({
  source,
  showModal,
  dismissModal,
}: CoreToConnectTrialBeginModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activateCoreToConnectTrial] =
    useMutation<ActivateCoreToConnectTrialMutation>(
      ACTIVATE_CORE_TO_CONNECT_TRIAL,
    );
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const upgradePageUrl = "/accounts/billing_info/pricing",
    ctaName = "core_to_connect_trial_pre_trial_modal_cta";

  useEffect(() => {
    if (showModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
        source,
      });
    }
  }, [showModal, source]);

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          title="Try the Connect plan for free"
          open={showModal}
          onRequestClose={handleModalDismissal}
          dismissible
          primaryAction={{
            label: "Start Free Trial",
            onClick: handleStartFreeTrial,
            loading: isLoading,
          }}
          tertiaryAction={{
            label: "Not Now",
            type: "primary",
            variation: "subtle",
            onClick: handleModalDismissal,
          }}
        >
          {errorMessage && (
            <Banner type="error" dismissible={false}>
              {errorMessage}
            </Banner>
          )}
          <Content>
            <Text>
              Connect allows you to create a seamless customer experience for
              your clients and win more work with less effort.
            </Text>
            <div className={styles.timelineContainer}>
              {modalTimelineContent.map((lineItem, index) => {
                return (
                  <div className={styles.timelineContent} key={lineItem.icon}>
                    <div className={styles.timelineIconContainer}>
                      <Icon color="green" name={lineItem.icon} />
                      <div
                        className={classNames(styles.dashedLine, {
                          [styles.hideDashedLine]: index === 2,
                        })}
                      ></div>
                    </div>
                    <div>
                      <div className={styles.timelineHeading}>
                        <Heading level={5}>{lineItem.heading}</Heading>
                      </div>
                      <Text>{lineItem.content}</Text>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.connectCardSubHeader}>
              <Emphasis variation="bold">The Connect trial includes</Emphasis>
              <div className={styles.line} />
            </div>
            <div className={styles.connectCardFeatures}>
              <ul>
                <li>Time & expense tracking</li>
                <li>Automated payments</li>
                <li>Automated emails & text messaging</li>
                <li>Custom fields</li>
              </ul>
            </div>
            <div className={styles.connectCardBottomText}>
              <Text>
                You will not lose any existing work when the trial starts. If
                you wish to start the trial at a later time, you can revisit
                this in the <a href={upgradePageUrl}> plans page.</a>
              </Text>
            </div>
          </Content>
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleModalDismissal() {
    dismissCTA(ctaRef, { source })();
    dismissModal();
  }

  async function handleStartFreeTrial() {
    setIsLoading(true);
    await updateTrialState();
  }

  async function updateTrialState() {
    const defaultError = new Error(
      "Connect trial could not be started. Please try again.",
    );
    try {
      const { data } = await activateCoreToConnectTrial({
        variables: {
          input: {
            deeplinked: source === CoreToConnectTrialStartSource.DEEPLINKED,
          },
        },
      });
      const errors = data?.activateCoreToConnectTrial
        .userErrors as MutationErrors[];
      if (errors.length === 0) {
        convertCTA(ctaRef, { source })();
        window.location.href = "/home";
        dismissModal();
      } else {
        setErrorMessage(defaultError.message);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMessage(defaultError.message);
      setIsLoading(false);
    }
  }
}
