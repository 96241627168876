import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntegrationList } from "./IntegrationList";
import type { IntegrationListProps } from "./IntegrationListProps";

function IntegrationListWrapper({
  allApps,
  connectedApps,
  featuredApps,
  publicFacing,
  highlightedApp,
}: IntegrationListProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <IntegrationList
          allApps={allApps}
          connectedApps={connectedApps}
          featuredApps={featuredApps}
          publicFacing={publicFacing}
          highlightedApp={highlightedApp}
        />
      </IntlProvider>
    </APIProvider>
  );
}

export { IntegrationListWrapper };
