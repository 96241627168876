import { gql } from "@apollo/client";

const arrivalWindowSettingsEditPayloadFragment = gql`
  fragment arrivalWindowSettingsEdit on ArrivalWindowsSettingsEditPayload {
    arrivalWindowSettings {
      centered
      defaultDuration
      id
      showOnboarding
    }
    userErrors {
      message
      path
    }
  }
`;

const clientNotificationEditPayloadFragment = gql`
  fragment clientNotification on UpdateNotificationPayload {
    clientNotification {
      id
      enabled
      featureEnabled {
        enabled
        toggleable
      }
      templates {
        nodes {
          ...visitReminderMessageTemplate
        }
      }
      schedules(first: 2) {
        nodes {
          id
          enabled
          deliveryMethod
          before
          offset {
            value
            unit
          }
          at
        }
      }
    }
    errors
  }

  fragment visitReminderMessageTemplate on MessageTemplate {
    ... on MessageTemplateSMS {
      id
      name
      deliveryMethod
      message {
        current
        default
      }
      variables {
        nodes {
          label
          variables {
            nodes {
              name
              label
              sample
            }
          }
        }
      }
      footerText
    }
    ... on MessageTemplateEmail {
      id
      name
      deliveryMethod
      subject {
        current
        default
      }
      message {
        current
        default
      }
      variables {
        nodes {
          label
          variables {
            nodes {
              name
              label
              sample
            }
          }
        }
      }
    }
    ... on MessageTemplateSurvey {
      id
      name
      deliveryMethod
      surveyEnabled
      subject {
        current
        default
      }
      message {
        current
        default
      }
      survey {
        current
        default
      }
      variables {
        nodes {
          label
          variables {
            nodes {
              name
              label
              sample
            }
          }
        }
      }
    }
  }
`;

export const PERSIST_ARRIVAL_WINDOW_ONBOARDING_SETTINGS = gql`
  mutation PersistArrivalWindowOnboardingSettings(
    $communicationsAttributes: NotificationUpdateAttributes!
    $arrivalWindowsSettingsAttributes: ArrivalWindowSettingsEditAttributes!
  ) {
    updateNotification(
      id: VISIT_REMINDER
      attributes: $communicationsAttributes
    ) {
      ...clientNotification
    }

    arrivalWindowsSettingsEdit(attributes: $arrivalWindowsSettingsAttributes) {
      ...arrivalWindowSettingsEdit
    }
  }
  ${arrivalWindowSettingsEditPayloadFragment}
  ${clientNotificationEditPayloadFragment}
`;

export const PERSIST_AND_BACKFILL_ARRIVAL_WINDOW_ONBOARDING_SETTINGS = gql`
  mutation PersistAndBackfillArrivalWindowOnboardingSettings(
    $communicationsAttributes: NotificationUpdateAttributes!
    $arrivalWindowsSettingsAttributes: ArrivalWindowSettingsEditAttributes!
  ) {
    updateNotification(
      id: VISIT_REMINDER
      attributes: $communicationsAttributes
    ) {
      ...clientNotification
    }

    arrivalWindowsSettingsEdit(attributes: $arrivalWindowsSettingsAttributes) {
      ...arrivalWindowSettingsEdit
    }

    jobsBackfillArrivalWindows {
      userErrors {
        message
      }
    }
  }

  ${arrivalWindowSettingsEditPayloadFragment}
  ${clientNotificationEditPayloadFragment}
`;
