import { defineMessages } from "react-intl";

const prefix = "setupWizard.companyPageForm.";

export const messages = defineMessages({
  companyNamePlaceholder: {
    id: `${prefix}companyNamePlaceholder`,
    defaultMessage: "Enter company name",
    description: "Company name placeholder for the setup wizard",
  },
  manualCompanyNameLabel: {
    id: `${prefix}manualCompanyNameLabel`,
    defaultMessage: "Manually enter company name",
    description: "Label for the manual company name input",
  },
});
