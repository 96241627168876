import { useCallback, useState } from "react";
import { SortDirectionEnum } from "~/utilities/API/graphqlFranchiseManagement";
import type { PageState } from "../useClientSegmentTablePaginationState";

export interface SortState<T> {
  id: T;
  desc: boolean;
}

export interface SortInput<T> {
  key: T;
  direction: SortDirectionEnum;
}

function useFormatSort<T>(defaultSortKey: T) {
  const formatSort = useCallback(
    (sort: [SortState<T>] | []): SortInput<T> => {
      return {
        key: sort[0]?.id || defaultSortKey,
        direction: sort[0]?.desc
          ? SortDirectionEnum.DESCENDING
          : SortDirectionEnum.ASCENDING,
      };
    },
    [defaultSortKey],
  );
  return { formatSort };
}

export function useClientSegmentTableSorting<T>(
  defaultSortKey: T,
  setPagination: React.Dispatch<React.SetStateAction<PageState>>,
  desc = false,
) {
  const { formatSort } = useFormatSort<T>(defaultSortKey);
  const [clientSegmentSortingState, setClientSegmentSortingState] = useState<
    [SortState<T>]
  >([
    {
      id: defaultSortKey,
      desc: desc,
    },
  ]);

  const handleSortChange = (sortState: [SortState<T>]) => {
    setPagination((pageState: PageState) => ({
      pageIndex: 0,
      pageSize: pageState.pageSize,
    }));
    setClientSegmentSortingState(sortState);
  };

  return { formatSort, clientSegmentSortingState, handleSortChange };
}
