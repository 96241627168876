export const elementsConfig: stripe.elements.ElementsCreateOptions = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap",
    },
  ],
};

export const elementsOptions: stripe.elements.ElementsOptions = {
  classes: {
    focus: "is-focus",
    empty: "is-empty",
    invalid: "is-invalid",
  },
  style: {
    base: {
      fontFamily: "var(--typography--fontFamily-normal)",
      fontSize: "16px",
      "::placeholder": {
        color: "#93a1a9",
      },
    },
    invalid: {
      color: "inherit",
    },
  },
};
