import React from "react";
import { useQuery } from "@apollo/client";
import type { AvailableBillingCountriesQuery } from "~/utilities/API/graphql";
import { LoadingError } from "jobber/billing/components/LoadingError";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { AVAILABLE_BILLING_COUNTRIES_QUERY } from "./BillingAddressEdit.graphql";
import { BillingAddressEdit } from "./BillingAddressEdit";

interface BillingAddressEditLoaderProps {
  billingAddress: BillingAddressType;
  trackingDetails: TrackingDetails;
  setBillingAddress: React.Dispatch<React.SetStateAction<BillingAddressType>>;
  onValidationError(error: FieldErrorState): void;
}

export function BillingAddressEditLoader(props: BillingAddressEditLoaderProps) {
  const {
    billingAddress,
    trackingDetails,
    setBillingAddress,
    onValidationError,
  } = props;

  const { error, data } = useQuery<AvailableBillingCountriesQuery>(
    AVAILABLE_BILLING_COUNTRIES_QUERY,
  );

  const countries = data?.availableBillingCountries;

  if (error || !countries) {
    return (
      <LoadingError
        source="BillingAddressEditLoader"
        logMessage={`Could not load available billing countries: ${error?.message}`}
      />
    );
  }

  return (
    <BillingAddressEdit
      availableBillingCountries={countries}
      billingAddress={billingAddress}
      trackingDetails={trackingDetails}
      setBillingAddress={setBillingAddress}
      onValidationError={onValidationError}
    />
  );
}
