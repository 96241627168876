import { gql } from "@apollo/client";
import { CLIENT_SEGMENT } from "../../graphql";

export const UPDATE_UPCOMING_CLIENTS_SEGMENT_CAMPAIGN = gql`
  mutation campaignSegmentUpdateUpcomingClients(
    $input: UpcomingClientsUpdateSegmentInput!
  ) {
    campaignSegmentUpdateUpcomingClients(input: $input) {
      upcomingClientsSegment {
        ...clientSegment
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CLIENT_SEGMENT}
`;
