import React from "react";
import { Heading } from "@jobber/components";
import styles from "./ChartWithTitle.module.css";

interface ChartWithTitleProps {
  title: string;
}

export function ChartWithTitle({
  children,
  title,
}: React.PropsWithChildren<ChartWithTitleProps>) {
  return (
    <div className={styles.chartWithTitle}>
      <Heading level={4}>{title}</Heading>
      <div className={styles.plotContainer}>{children}</div>
    </div>
  );
}
