export function showTodoDialog(todoId: number) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  $.ajax({
    url: `/to_dos/${todoId}.dialog`,
    dataType: "script",
    type: "get",
  }).done(() => {
    $(window).on("dialog:opened", () => {
      checkIfTodoIsEdited();
    });
  });
}

export function newTaskDialog() {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  $.ajax({
    url: `/to_dos/new.dialog`,
    dataType: "script",
    type: "get",
  });
}

export function newEventDialog() {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  $.ajax({
    url: `/to_dos/new.dialog?to_do%5Bplugin_type%5D=event`,
    dataType: "script",
    type: "get",
  });
}

function checkIfTodoIsEdited() {
  $(`.dialog-box .js-markCompleteButton,
    .dialog-box .js-formSubmit,
    .dialog-box a[data-method='delete'][href*='?confirm=true']`).on(
    "click",
    reloadAfterDialogCloses,
  );
}

function reloadAfterDialogCloses() {
  $(window).on("dialog:closed", () => {
    setTimeout(() => {
      const openDialogs = $(".dialog-box").length;
      if (openDialogs === 0) {
        $(".js-today-card").addClass("spinner");
        location.reload();
      }
    }, 300);
  });
}
