import React from "react";
import { useQuery } from "@apollo/client";
import request from "queries/live_sync_stats.graphql";
import type { LiveSyncStatsQuery } from "~/utilities/API/graphql";
import { QuickbooksSubscriptionSyncIcon } from "./QuickbooksSubscriptionSyncIcon";

interface QboSyncIconComponentProps {
  settingsUrl: string;
}

export function QuickbooksSyncIcon(props: QboSyncIconComponentProps) {
  const buttonClicked = () => {
    window.location.href = props.settingsUrl;
  };

  const { loading, error, data } = useQuery<LiveSyncStatsQuery>(request, {
    variables: {
      integrationType: "QBO",
    },
  });

  if (loading) {
    return <></>;
  }

  // We do a nil check on the live sync stats attached to the data, to address a rendering
  // issue where sometimes the data isn't undefined, but the attributes attached to the data are,
  // which causes the rendering of the sub-component to fail.
  if (error || !data || !data.liveSyncStats) {
    return <></>;
  }

  return (
    <QuickbooksSubscriptionSyncIcon
      liveSyncQueryData={data.liveSyncStats}
      handleClick={buttonClicked}
    />
  );
}
