import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages } from "jobber/campaigns/views/CampaignsLandingPage/messages";

// Selectors

export function getCampaignTitle(title: string) {
  return screen.getByText(title);
}

export function getDataListLoadingSpinner() {
  return screen.getByTestId("ATL-DataList-loadingMoreSpinner");
}

export function getDeactivateMenuButton() {
  return screen.getAllByTestId("minus2");
}

export function getDeleteMenuButton() {
  return screen.getAllByTestId("destructive");
}

export function getEditMenuButton() {
  return screen.getAllByTestId("edit");
}

export function getLastUpdatedNewest() {
  return screen.getByText("Last Updated (Newest)");
}

export function getLastUpdatedOldest() {
  return screen.getByText("Last Updated (Oldest)");
}

export function getListTitle() {
  return screen.getByText(messages.automatedCampaignListTitle.defaultMessage);
}

export function getMoreActionsMenuButton() {
  return screen.queryAllByRole("menu");
}

export function getNewCampaignButton() {
  return screen.getByText(messages.newCampaignLabel.defaultMessage);
}

export function getNoNewCampaignsLabel() {
  return screen.getByText(messages.noCampaignsMessage.defaultMessage);
}

export function getReactivateMenuButton() {
  return screen.getAllByTestId("redo");
}

export function getCampaignDescription(description: string) {
  return screen.getByText(description);
}

export function getAllCampaignDescription(description: string) {
  return screen.getAllByText(description);
}

export function getAllEmailsDeliveredTotal(total: number) {
  return screen.getAllByText(total);
}

// Query

export function queryCampaignDescription(description: string) {
  return screen.queryByText(description);
}

export function queryCampaignTitle(title: string) {
  return screen.queryByText(title);
}

export function queryNoNewCampaignsLabel() {
  return screen.queryByText(messages.noCampaignsMessage.defaultMessage);
}

export function queryJobEndedText() {
  return screen.queryByText("Job ended");
}

export function getToolTip(text: string) {
  return screen.getByText(text);
}

export function getHeaderText(text: string) {
  return screen.getByText(text);
}

// Solo Actions

export function iClickTheLastUpdatedColumn() {
  userEvent.click(screen.getByText(messages.lastUpdated.defaultMessage));
}

export function iClickTheMenuDeactivateItem() {
  userEvent.click(getDeactivateMenuButton()[0]);
}

export function iClickTheMenuEditItem() {
  userEvent.click(getEditMenuButton()[0]);
}

export function iClickTheMenuReactivateItem() {
  userEvent.click(getReactivateMenuButton()[0]);
}

export function iClickTheNewCampaignButton() {
  userEvent.click(getNewCampaignButton());
}

export function iClickTheSortButton() {
  userEvent.click(screen.getByText("Sort"));
}

export function iHoverCampaignTitle(title: string) {
  userEvent.hover(getCampaignTitle(title));
}
