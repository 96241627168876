import { useIntl } from "react-intl";
import type { AutomationRule, ProductsQuery } from "~/utilities/API/graphql";
import {
  CRITERIA_MODEL_OPTIONS,
  JOB_TYPE,
  QUOTE_STATUS,
  TRIGGER_DROPDOWN_OPTIONS,
} from "jobber/campaigns/constants";
import {
  sExprParserContains,
  sExprParserValueLabel,
} from "jobber/campaigns/utils/automationRuleUtils";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { messages } from "./messages";
import {
  type FromServerCondition,
  ServerRuleConditionTypes,
} from "../../types";

export interface ConditionTags {
  clientTagsInclude: string[];
  clientTagsDoNotInclude: string[];
  lineItemsInclude: string[];
  lineItemsDoNotInclude: string[];
  jobType: string;
  quoteStatus: string;
}

export interface Props {
  allLoading: boolean;
  automationRule?: AutomationRule;
  lineItemData?: ProductsQuery;
  campaignIsAutomated?: boolean;
}
export const useTranslateAutomationRulesConditionToText = ({
  allLoading,
  automationRule,
  lineItemData,
  campaignIsAutomated = true,
}: Props) => {
  const { formatMessage } = useIntl();
  if (!campaignIsAutomated) {
    return { timeFrame: "", trigger: "", conditionText: "" };
  }

  const extractConditionTags = () => {
    const conditionTags: ConditionTags = {
      clientTagsInclude: [],
      clientTagsDoNotInclude: [],
      lineItemsInclude: [],
      lineItemsDoNotInclude: [],
      jobType: "",
      quoteStatus: "",
    };

    automationRule?.condition.conditions.map(
      (condition: FromServerCondition) => {
        switch (condition.name) {
          case ServerRuleConditionTypes.ClientHasTags:
          case ServerRuleConditionTypes.QuoteClientHasTags:
            if (sExprParserContains(condition.arguments.expression)) {
              conditionTags.clientTagsInclude.push(
                `${sExprParserValueLabel(condition.arguments.expression)}`,
              );
            } else {
              conditionTags.clientTagsDoNotInclude.push(
                `${sExprParserValueLabel(condition.arguments.expression)}`,
              );
            }
            break;
          case ServerRuleConditionTypes.ClientHasJobLineItems:
            // eslint-disable-next-line no-case-declarations
            const lineItem = lineItemData?.products.nodes.find(
              item => item.id === condition.arguments.work_item_id,
            );
            if (condition.arguments.include) {
              conditionTags.lineItemsInclude.push(`${lineItem?.name}`);
            } else {
              conditionTags.lineItemsDoNotInclude.push(`${lineItem?.name}`);
            }
            break;
          case ServerRuleConditionTypes.QuoteHasLineItems:
            // eslint-disable-next-line no-case-declarations
            const quoteLineItem = lineItemData?.products.nodes.find(
              item =>
                decodeId(item.id) ==
                sExprParserValueLabel(condition.arguments.expression),
            );
            if (sExprParserContains(condition.arguments.expression)) {
              conditionTags.lineItemsInclude.push(`${quoteLineItem?.name}`);
            } else {
              conditionTags.lineItemsDoNotInclude.push(
                `${quoteLineItem?.name}`,
              );
            }
            break;
          case ServerRuleConditionTypes.JobType:
            // eslint-disable-next-line no-case-declarations
            const jobType = JOB_TYPE.find(
              type => type.id === condition.arguments.job_type,
            )?.label;
            conditionTags.jobType = `job type ${jobType}`;
            break;
          case ServerRuleConditionTypes.QuoteStatus:
            {
              const quoteStatus = QUOTE_STATUS.find(
                status =>
                  status.id ===
                  sExprParserValueLabel(condition.arguments.expression),
              )?.label;
              conditionTags.quoteStatus = `quote is ${quoteStatus}`;
            }
            break;
          case "":
            break;
        }
      },
    );
    return conditionTags;
  };

  const generateConditionText = () => {
    if (allLoading) return;
    const conditionTags = extractConditionTags();
    const conditionTextHolder = [];
    switch (automationRule?.name) {
      case "CampaignPastClientsAutomation":
        conditionTextHolder.push(formatMessage(messages.clientHasNoJobs));
        break;
      case "CampaignLostLeadsAutomation":
        conditionTextHolder.push(
          formatMessage(messages.clientHasNotWorkedWithYou),
        );
        break;
    }
    conditionTags.quoteStatus &&
      conditionTextHolder.push(conditionTags.quoteStatus);
    conditionTags.clientTagsInclude.length > 0 &&
      conditionTextHolder.push(
        `${formatMessage(messages.clientTagsInclude)} '${conditionTags.clientTagsInclude.join(", ")}'`,
      );
    conditionTags.clientTagsDoNotInclude.length > 0 &&
      conditionTextHolder.push(
        `${formatMessage(messages.clientTagsDoNotInclude)} '${conditionTags.clientTagsDoNotInclude.join(", ")}'`,
      );
    conditionTags.lineItemsInclude.length > 0 &&
      conditionTextHolder.push(
        `${formatMessage(messages.lineItemsInclude)} '${conditionTags.lineItemsInclude.join(", ")}'`,
      );
    conditionTags.lineItemsDoNotInclude.length > 0 &&
      conditionTextHolder.push(
        `${formatMessage(messages.lineItemsDoNotInclude)} '${conditionTags.lineItemsDoNotInclude.join(", ")}'`,
      );
    conditionTags.jobType && conditionTextHolder.push(conditionTags.jobType);
    return conditionTextHolder.join(", ");
  };

  const conditionText = generateConditionText();
  const timeFrame = TRIGGER_DROPDOWN_OPTIONS.find(
    option => option.value === automationRule?.trigger.arguments.time,
  )?.label;
  const trigger = CRITERIA_MODEL_OPTIONS.find(
    option => option.id === automationRule?.trigger.arguments.model_type,
  )?.label;

  return {
    timeFrame,
    trigger,
    conditionText,
  };
};
