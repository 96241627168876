import React from "react";
import { useIntl } from "react-intl";
import { Body, Cell, Header, Row, Table } from "components/Table";
import { LabourTableTitle } from "jobber/workOrders/components/JobCost/components/LabourTable/components/LabourTableTitle";
import type { JobDetailsFragment, User } from "~/utilities/API/graphql";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/messages";

interface LabourTableEmptyStateProps {
  job: JobDetailsFragment;
  user?: User;
}

export function LabourTableEmptyState({
  job,
  user,
}: LabourTableEmptyStateProps) {
  const { formatMessage } = useIntl();

  return (
    <Table>
      <Header>
        <LabourTableTitle job={job} user={user} />
      </Header>
      <Body>
        <Row>
          <Cell>{formatMessage(messages.labourTableEmptyStateMessage)}</Cell>
        </Row>
      </Body>
    </Table>
  );
}
