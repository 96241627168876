import { gql } from "@apollo/client";

export const ReferralMessagingModalFields = gql`
  fragment ReferralMessagingModalFields on ClientHubReferralSettings {
    bannerMessage
    commMessage
  }
`;

export const ReferralMessagingUpdate = gql`
  mutation ReferralMessagingUpdate(
    $bannerMessage: String
    $commMessage: String
  ) {
    clientHubReferralSettingsUpsert(
      attributes: { bannerMessage: $bannerMessage, commMessage: $commMessage }
    ) {
      clientHubReferralSettings {
        id
        ...ReferralMessagingModalFields
      }
      errors
    }
  }

  ${ReferralMessagingModalFields}
`;
