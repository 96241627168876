export const messages = {
  getPaidFaster: {
    header: {
      emphasisText: "Get paid faster",
      secondaryText: "with instant payouts and more...",
    },
    slideIn: {
      valueMetric: "faster payouts",
      rollToNumber: "4.0",
    },
  },
  winMoreWork: {
    header: {
      emphasisText: "Win more work",
      secondaryText: "with online booking and more...",
    },
    slideIn: {
      valueMetric: "first year revenue",
      rollToNumber: "37",
    },
  },
  increaseEfficiency: {
    header: {
      emphasisText: "Increase efficiency",
      secondaryText: "with automated workflows and more...",
    },
    slideIn: {
      valueMetric: "hours saved per week",
      rollToNumber: "7",
    },
  },
  noGoalSelected: {
    header: {
      emphasisText: "Creating professional templates",
      secondaryText: "to get you up to speed...",
    },
    slideIn: {
      valueMetric: "hours saved per week",
      rollToNumber: "7",
    },
  },
};
