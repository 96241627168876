import React from "react";
import { Icon, type IconColorNames } from "@jobber/components/Icon";

export interface IconButtonProps {
  icon: "quickbooks" | "sync" | "checkmark";
  errorCount: number;
  color?: IconColorNames;
  animation?: string;
  onClick(): void;
}

export function IconButton({
  icon,
  errorCount,
  color,
  animation,
  onClick,
}: IconButtonProps) {
  const hideBadge = icon === "sync" || errorCount <= 0;
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={`button u-paddingNone ${animation}`}
      >
        <Icon size="base" name={icon} color={color} />
      </button>
      <div
        className={`u-paddingNone u-borderNone notifications-unread-icon ${
          hideBadge ? "hidden" : ""
        }`}
      >
        <div className="notifications-number">
          {errorCount > 99 ? 99 : errorCount}
        </div>
      </div>
    </>
  );
}
