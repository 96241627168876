import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  InvoiceSummaryFilterInput,
  InvoiceSummaryQuery,
  InvoiceSummaryQueryVariables,
  InvoiceSummarySortInput,
} from "~/utilities/API/graphqlFranchiseManagement";
import { INVOICE_SUMMARY_QUERY } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/InvoiceSummaryReport.graphql";

interface FetchInvoiceSummaryArguments {
  sort: InvoiceSummarySortInput;
  filter: InvoiceSummaryFilterInput;
  cursor?: string;
  first: number;
}

interface InvoiceSummaryReportDataProps {
  data?: InvoiceSummaryQuery;
  loading: boolean;
  error?: ApolloError;
  fetchInvoiceSummary(args: FetchInvoiceSummaryArguments): void;
}

export function useInvoiceSummaryReportData(): InvoiceSummaryReportDataProps {
  const [fetchInvoiceSummary, { data, loading, error }] = useLazyQuery<
    InvoiceSummaryQuery,
    InvoiceSummaryQueryVariables
  >(INVOICE_SUMMARY_QUERY);

  const _fetchInvoiceSummary = ({
    sort,
    filter,
    cursor,
    first,
  }: FetchInvoiceSummaryArguments) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInvoiceSummary({
      variables: {
        sort: sort,
        filter: filter,
        cursor: cursor,
        first: first,
      },
    });
  };

  return {
    fetchInvoiceSummary: _fetchInvoiceSummary,
    data,
    loading,
    error,
  };
}
