import type { Dispatch, SetStateAction } from "react";
import type { QboSubscriptionSyncIconProps } from "jobber/quickbooks/components/QuickbooksSyncIconWrapper/QuickbooksSubscriptionSyncIcon";
import type { QboLiveSyncStatsPayload } from "~/utilities/API/graphql";

export interface SetIconStateVariablesProps {
  subscriptionData: QboLiveSyncStatsPayload;
  currentIconProps: QboSubscriptionSyncIconProps;
  setQboState: Dispatch<SetStateAction<string>>;
}

export function SetIconStateVariables(
  props: SetIconStateVariablesProps,
): [boolean, number] {
  if (
    props.subscriptionData.liveSyncStats.syncing !==
      props.currentIconProps.liveSyncQueryData.syncing ||
    props.subscriptionData.liveSyncStats.errorCount !==
      props.currentIconProps.liveSyncQueryData.errorCount
  ) {
    if (
      props.currentIconProps.liveSyncQueryData.syncing &&
      !props.subscriptionData.liveSyncStats.syncing &&
      props.subscriptionData.liveSyncStats.errorCount <= 0
    ) {
      props.setQboState("checkmark");
      setTimeout(() => {
        props.setQboState("default");
      }, 2000);
    }
  }
  return [
    props.subscriptionData.liveSyncStats.syncing,
    props.subscriptionData.liveSyncStats.errorCount,
  ];
}
