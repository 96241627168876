import React, { type CSSProperties, Component } from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";

const wrapperStyles: CSSProperties = {
  pointerEvents: "none",
  position: "static",
  transform: "none",
  boxShadow: "none",
};

export interface MenuItemProps {
  label: string;
  isSelected: boolean;
  iconType: IconNames;
}

interface ClientHubMenuDisplayProps {
  workRequestsVisible: boolean;
  menuItems: MenuItemProps[];
}

function MenuItem(props: MenuItemProps) {
  const classes = `sidenav-item${props.isSelected ? " is-selected" : ""}`;
  const iconColor = props.isSelected ? "blue" : "greyBlue";

  return (
    <div className={classes}>
      <div style={{ marginRight: "var(--space-base)" }}>
        <Icon name={props.iconType} color={iconColor} />
      </div>
      {props.label}
    </div>
  );
}

/**
 * ClientHubMenuDisplay react component.
 */

export class ClientHubMenuDisplay extends Component<ClientHubMenuDisplayProps> {
  public render() {
    return (
      <div className="sidenav sidenav--clientHub is-open" style={wrapperStyles}>
        <div className="sidenav-menu">
          {this.props.workRequestsVisible && (
            <>
              <div className="sidenav-item u-paddingSmaller">
                <div
                  className="button js-spinOnClick button--green button--ghost button--fill u-paddingLeftSmaller"
                  style={{ justifyContent: "start" }}
                >
                  <div style={{ marginRight: "var(--space-base)" }}>
                    <Icon name="add" color="green" />
                  </div>
                  New Request
                </div>
              </div>
              <MenuItem label="Requests" isSelected={true} iconType="request" />
            </>
          )}

          {this.props.menuItems.map((object: MenuItemProps) => (
            <MenuItem
              key={object.label}
              label={object.label}
              isSelected={object.isSelected}
              iconType={object.iconType}
            />
          ))}

          <div className="sidenav-divider" />

          <div className="sidenav-menuGroup">
            <MenuItem label="Contact Us" isSelected={false} iconType="phone" />
          </div>
          <MenuItem label="Log Out" isSelected={false} iconType="logout" />
        </div>
      </div>
    );
  }
}
