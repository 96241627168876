export function hasPOBoxAddress(combinedStreet: string) {
  const regex = /[Pp]\.?\s?[Oo]\.?\s*[Bb][Oo][Xx]/;
  const value = combinedStreet.search(regex);

  return value > -1;
}

export function hasInvalidZipCode(zipCode: string) {
  const regex = /^\d{5}(?:-\d{4})?$/;
  return !regex.test(zipCode);
}
