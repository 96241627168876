import React from "react";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { Text } from "@jobber/components/Text";
import styles from "./SetupGuideProgressBar.module.css";
import { messages } from "../../messages";

export interface SetupGuideProgressBarProps {
  progress: number;
  doneSteps: number;
  totalSteps: number;
}

export function SetupGuideProgressBar({
  progress,
  doneSteps,
  totalSteps,
}: SetupGuideProgressBarProps) {
  const progressText = () => {
    const progressDone = `${progress}% ${messages.progressCompleteInfo.defaultMessage}`;

    if (doneSteps == 1) {
      return `${progressDone} - ${messages.progressStartInfo.defaultMessage}`;
    }

    if (doneSteps == totalSteps) {
      return progressDone;
    }

    if (doneSteps == totalSteps - 1) {
      return `${progressDone} - ${messages.progressLastStepInfo.defaultMessage}`;
    }

    if (doneSteps > 1 && progress <= 50) {
      return `${progressDone} - ${messages.progressKeepGoingInfo.defaultMessage}`;
    }

    if (progress > 50 && doneSteps > 1) {
      return `${progressDone} - ${messages.progressAlmostThereInfo.defaultMessage}`;
    }

    return "";
  };

  return (
    <div className={styles.progressBar}>
      <span className={styles.progressBarText}>
        <Text>{progressText()}</Text>
      </span>
      <ProgressBar
        currentStep={doneSteps}
        totalSteps={totalSteps}
        size={"smaller"}
      />
    </div>
  );
}
