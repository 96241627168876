import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cardNumberPlaceholder: {
    id: "paymentDetailsEdit.cardNumberPlaceholder",
    defaultMessage: "Card number",
    description: "Label text for card number field",
  },
  invalidCardNumber: {
    id: "paymentDetails.invalidCardNumber",
    defaultMessage: "Enter a valid card number",
    description: "Error message for invalid card number",
  },
  invalidCardExpiry: {
    id: "paymentDetails.invalidCardExpiry",
    defaultMessage: "Enter a valid expiry date",
    description: "Error message for invalid card expiry",
  },
  invalidCvc: {
    id: "paymentDetails.invalidCvc",
    defaultMessage: "Enter a valid CVC",
    description: "Error message for invalid CVC",
  },
});
