/* eslint-disable max-statements */
import React, { useMemo } from "react";
import { format } from "date-fns";
import type { StateFrom } from "xstate";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { SplitNames, useSplit } from "utilities/split";
import type { SchedulingState } from "./types";
import type { ScheduleCardMachine } from "./scheduleCard.machine";
import {
  ACTIVE_DISPATCH,
  ACTIVE_DISPATCH_CHECK_BOX_KEY,
  DISPATCH_RRULE_KEY,
  DISPATCH_TYPE_KEY,
  END_AT_DATE_KEY,
  END_AT_TIME_KEY,
  NEVER_DISPATCH,
  PERIODIC_DISPATCH,
  SCHEDULED_KEY,
  SCHEDULE_RECOMMENDATION_SELECTED_KEY,
  START_AT_DATE_KEY,
  START_AT_TIME_KEY,
} from "./constants";
import { useRecommendations } from "./Recommendations";
import type { SchedulingAvailabilityResponse } from "./hooks";
import { ScheduleDataForLogs } from "./ScheduleDataForLogs";

export interface SerializedFieldsForRails {
  state: SchedulingState;
  stateMatches: StateFrom<ScheduleCardMachine>["matches"];
  railsFormKey: string;
  recommendationsResponse?: SchedulingAvailabilityResponse;
}

export function SerializedFieldsForRails({
  state,
  stateMatches,
  railsFormKey,
  recommendationsResponse,
}: SerializedFieldsForRails) {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.SchedulingRecommendations],
  });
  const isInRecommendationsBeta = getTreatmentValue(
    SplitNames.SchedulingRecommendations,
  );
  const { dateFormat, timeFormat } = useAccount();
  const { recommendationTypeSelectedForJobForm } = useRecommendations();

  const startAtDate = formatDate(state.startDate, dateFormat);
  const endAtDate = formatDate(state.endDate, dateFormat);
  const startAtTime = formatTime(state.startTime, timeFormat);
  const endAtTime = formatTime(state.endTime, timeFormat);
  const addUnscheduledVisit = stateMatches("addUnscheduledVisits.true");
  const scheduleLater = stateMatches("scheduling.scheduleLater");
  const isMultiDay = stateMatches("dates.enabled.multiDay");

  const dispatchType = useMemo(() => {
    if (!scheduleLater && isMultiDay) {
      return PERIODIC_DISPATCH;
    } else if (!scheduleLater || (scheduleLater && addUnscheduledVisit)) {
      return ACTIVE_DISPATCH;
    }
    return NEVER_DISPATCH;
  }, [addUnscheduledVisit, isMultiDay, scheduleLater]);

  return (
    <>
      <input
        data-testid={SCHEDULED_KEY}
        type="hidden"
        id={`${railsFormKey}_scheduled`}
        name={`${railsFormKey}[${SCHEDULED_KEY}]`}
        value={scheduleLater ? "false" : "true"}
      />

      {!scheduleLater && (
        <>
          <input
            data-testid={START_AT_DATE_KEY}
            type="hidden"
            id={`${railsFormKey}_start_at_date`}
            name={`${railsFormKey}[${START_AT_DATE_KEY}]`}
            value={startAtDate}
          />
          <input
            data-testid={END_AT_DATE_KEY}
            type="hidden"
            id={`${railsFormKey}_end_at_date`}
            name={`${railsFormKey}[${END_AT_DATE_KEY}]`}
            value={endAtDate}
          />
          <input
            data-testid={START_AT_TIME_KEY}
            type="hidden"
            id={`${railsFormKey}_initial_start_time`}
            name={`${railsFormKey}[${START_AT_TIME_KEY}]`}
            value={startAtTime}
          />
          <input
            data-testid={END_AT_TIME_KEY}
            type="hidden"
            id={`${railsFormKey}_initial_end_time`}
            name={`${railsFormKey}[${END_AT_TIME_KEY}]`}
            value={endAtTime}
          />
          {isInRecommendationsBeta && (
            <input
              data-testid={SCHEDULE_RECOMMENDATION_SELECTED_KEY}
              type="hidden"
              id={`${railsFormKey}_schedule_recommendation_selected`}
              name={`${railsFormKey}[${SCHEDULE_RECOMMENDATION_SELECTED_KEY}]`}
              value={recommendationTypeSelectedForJobForm}
            />
          )}
        </>
      )}
      {isInRecommendationsBeta && (
        <ScheduleDataForLogs
          isInRecommendationsBeta={isInRecommendationsBeta}
          railsFormKey={railsFormKey}
          recommendationsResponse={recommendationsResponse}
        />
      )}
      {addUnscheduledVisit && (
        <input
          data-testid={ACTIVE_DISPATCH_CHECK_BOX_KEY}
          type="hidden"
          id={`${railsFormKey}_active_dispatch_check_box`}
          name={`${ACTIVE_DISPATCH_CHECK_BOX_KEY}`}
          value={addUnscheduledVisit.toString()}
        />
      )}
      <input
        data-testid={DISPATCH_TYPE_KEY}
        type="hidden"
        id={`${railsFormKey}_dispatch_type`}
        name={`${railsFormKey}[${DISPATCH_TYPE_KEY}]`}
        value={dispatchType}
      />
      <input
        data-testid={DISPATCH_RRULE_KEY}
        type="hidden"
        id={`${railsFormKey}_dispatch_rrule`}
        name={`${railsFormKey}[${DISPATCH_RRULE_KEY}]`}
        value={state.repeatOption}
      />
    </>
  );
}

function formatDate(date: Date | undefined, dateFormat: string) {
  return date ? format(date, dateFormat) : undefined;
}

function formatTime(time: Date | undefined, timeFormat: string) {
  if (time) {
    const baseFormattedTime = format(time, timeFormat);

    // The jQuery time library is called with the option to space-pad in the CoffeeScript
    // form, which date-fns `format` does not support, so we need to do it manually
    return timeFormat === "h:mma"
      ? baseFormattedTime.padStart(7, " ")
      : baseFormattedTime;
  }
}
