import { useCallback } from "react";
import type { AssignableUsersQuery } from "~/utilities/API/graphql";
import { useAssignTeamMachine } from "./AssignTeamMachineContext";

export interface UseAssignTeam {
  assignableUsers: AssignableUsersQuery["users"]["nodes"];
  selectedAssignees: Set<string>;
  selectAssignee: (userId: string) => void;
  removeAssignee: (userId: string) => void;
  setSelectedAssignees: (userIds: string[]) => void;
  error: string;
  loading: boolean;
  disabled: boolean;
  currentUserId: string;
}

export function useAssignTeam(): UseAssignTeam {
  const [current, send] = useAssignTeamMachine();

  const callbacks = useCallback(() => {
    return {
      removeAssignee: (userId: string) =>
        send({ type: "REMOVE_ASSIGNEE", userId }),
      selectAssignee: (userId: string) =>
        send({ type: "ADD_ASSIGNEE", userId }),
      setSelectedAssignees: (userIds: string[]) =>
        send({ type: "SET_SELECTED_ASSIGNEES", userIds }),
    };
  }, [send]);

  const isDisabled = current.matches("disabled");

  return {
    error: current.context.error as string,
    assignableUsers: current.context.assignableUsers,
    currentUserId: current.context.currentUserId,
    selectedAssignees: isDisabled
      ? new Set()
      : current.context.selectedAssignees,
    loading: current.matches("loading"),
    disabled: isDisabled,
    ...callbacks(),
  };
}
