import React, {
  type Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useIntl } from "react-intl";
// Importing to allow for the rich text editor to use the Atlantis form validation
// eslint-disable-next-line no-restricted-imports
import { FormField } from "@jobber/components/FormField";
import { sanitize } from "dompurify";
import { Glimmer } from "@jobber/components/Glimmer";
import classNames from "classnames";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Chip } from "@jobber/components/Chip";
import { Icon } from "@jobber/components/Icon";
import {
  useQuillEvents,
  useRows,
} from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks";
import { useQuill } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks/useQuill/useQuill";
import { usePreventSearchFocus } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks/usePreventSearchFocus";
import { ImageUploader } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/components/ImageUploader/ImageUploader";
import type { TextRewriteError } from "components/TextRewrite/errors";
import styles from "./RichTextContentEditor.module.css";
// imports the styles required by our chosen theme for Quill
// eslint-disable-next-line import/no-internal-modules
import "quill/dist/quill.snow.css";
import { messages } from "./messages";

export interface RichTextContentEditorRef {
  insert(text: string): void;
  blur(): void;
  focus(): void;
  setValue(text: string): void;
  insertImage(url: string, isHeaderImage: boolean): void;
}

interface RichTextContentEditorProps {
  placeholder: string;
  content: string;
  onChange: (x: string, isDirty: boolean) => void;
  loading: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  /**
   * Determines the min height used for the Glimmer and the Editor
   * @default 12
   */
  minRows?: number;
  textRewriteLoading: boolean;
  textRewriteError: TextRewriteError | undefined;
}

export const RichTextContentEditor = React.forwardRef<
  RichTextContentEditorRef,
  RichTextContentEditorProps
>(RichTextContentEditorInternal);

// eslint-disable-next-line max-statements
function RichTextContentEditorInternal(
  {
    placeholder,
    content,
    onChange,
    loading,
    onFocus,
    onBlur,
    minRows = 12,
    textRewriteLoading,
    textRewriteError,
  }: RichTextContentEditorProps,
  ref: Ref<RichTextContentEditorRef>,
) {
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [imageSize, setImageSize] = useState("Original");
  const {
    setupQuill,
    editorId,
    quillInstance,
    initialValueLoaded,
    lastKnownPosition,
  } = useQuill({ setIsImageUploadModalOpen, setImageSize });
  const { formatMessage } = useIntl();
  const {
    onSelectionChange,
    onTextChange,
    formFieldHiddenInputRef,
    formFieldActionsRef,
  } = useQuillEvents({
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    quillInstance: quillInstance,
    lastKnownPosition: lastKnownPosition,
    initialValueLoaded: initialValueLoaded,
  });

  usePreventSearchFocus({
    quillInstance,
    formFieldActionsRef,
  });

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        quillInstance.current?.focus();
      },
      blur: () => {
        quillInstance.current?.blur();
      },
      insert: (text: string) => {
        const selection = quillInstance.current?.getSelection();
        const index =
          selection?.index !== undefined
            ? selection.index
            : lastKnownPosition.current;
        quillInstance.current?.insertText(index, sanitize(text), "user");
        formFieldActionsRef.current?.setValue(
          quillInstance.current?.getText()?.trim() || "",
        );
      },
      setValue: (_newContent: string) => {
        if (quillInstance.current !== null) {
          const sanitizedContent = sanitize(_newContent);
          const newContent = quillInstance.current.clipboard.convert({
            html: sanitizedContent,
          });
          quillInstance.current.setContents(newContent, "user");
          formFieldActionsRef.current?.setValue(
            quillInstance.current?.getText()?.trim() || "",
          );
          onChange(sanitizedContent, true);
        }
      },
      insertImage: (url: string, isHeaderImage: boolean) => {
        const index = isHeaderImage
          ? 0
          : quillInstance.current?.getSelection()?.index || 0;

        quillInstance.current?.insertEmbed(index, "image", url);

        if (isHeaderImage) {
          quillInstance.current?.setSelection(index);
          quillInstance.current?.insertText(index + 1, "\n");
          quillInstance.current?.formatLine(index, 1, { align: "center" });
        }
      },
    }),
    [],
  );

  const { richTextContainerHeight, richTextContainer } = useRows(minRows);

  useEffect(() => {
    if (quillInstance.current === null) {
      setupQuill(placeholder, onTextChange, onSelectionChange);
    }
  });

  useEffect(() => {
    if (
      quillInstance.current !== null &&
      !initialValueLoaded.current &&
      content &&
      !loading
    ) {
      const initialContent = quillInstance.current.clipboard.convert({
        html: sanitize(content),
      });
      quillInstance.current.setContents(initialContent);
      initialValueLoaded.current = true;
    }
  }, [content, initialValueLoaded, quillInstance, loading]);

  return (
    <div ref={richTextContainer}>
      {loading && (
        <div style={{ height: richTextContainerHeight + "px" }}>
          <Glimmer size="auto" />
        </div>
      )}
      <div
        className={classNames(styles.richTextContainer, {
          [styles.loading]: loading,
        })}
      >
        <FormField
          defaultValue={content}
          name={"body"}
          invalid={textRewriteError?.type === "INLINE"}
          validations={{
            required: {
              value: true,
              message: formatMessage(messages.errorForEmptyBody),
            },
          }}
          inputRef={formFieldHiddenInputRef}
          actionsRef={formFieldActionsRef}
        >
          <div
            style={{ minHeight: richTextContainerHeight + "px" }}
            id={editorId.current || ""}
            data-testid="quill-editor"
            aria-busy={loading || textRewriteLoading}
          ></div>
        </FormField>
        <div
          id="image-resize-menu"
          data-testid="image-resize-menu"
          className={styles.imageResizeMenu}
        >
          <div className={styles.imageResizeMenuButtons}>
            <Chip label="Small">
              <Chip.Suffix>
                {imageSize === "Small" && (
                  <Icon name="checkmark" size="small" />
                )}
              </Chip.Suffix>
            </Chip>
            <Chip label="Best fit">
              <Chip.Suffix>
                {imageSize === "Best fit" && (
                  <Icon name="checkmark" size="small" />
                )}
              </Chip.Suffix>
            </Chip>
            <Chip label="Original">
              <Chip.Suffix>
                {imageSize === "Original" && (
                  <Icon name="checkmark" size="small" />
                )}
              </Chip.Suffix>
            </Chip>
          </div>
        </div>
        <Modal
          title={formatMessage(messages.uploadImageModalTitle)}
          open={isImageUploadModalOpen}
          onRequestClose={() => setIsImageUploadModalOpen(false)}
        >
          <Content>
            <ImageUploader
              setIsImageUploadModalOpen={setIsImageUploadModalOpen}
              isHeaderImage={false}
              ref={ref}
            />
          </Content>
        </Modal>
        {textRewriteLoading && (
          <div
            className={styles.rephrasingGlimmer}
            data-testid="text-rewrite-loading"
          >
            <Glimmer />
            <Glimmer />
            <Glimmer />
            <div>
              <Glimmer />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
