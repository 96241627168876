import type PaymentError from "./PaymentError";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class GenericError extends Error implements PaymentError {
  public readonly details: string[];

  constructor(message = "", details: string[] = []) {
    super(message);
    this.details = details;
  }
}
