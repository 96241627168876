import { defineMessages } from "react-intl";

export const messages = defineMessages({
  allClientsSubheader: {
    id: "campaigns.upcomingClientsSubHeader.",
    defaultMessage: "Client criteria",
    description: "Subheader for the past clients segment criteria",
  },
  allClientsDescription1: {
    id: "campaigns.clientSegmentPage.allClientsPrimaryDescription",
    defaultMessage: "Clients that have not been archived",
    description: "Text fragment explaining the time interval inputs",
  },
  upcomingClientsDescription1: {
    id: "campaigns.clientSegmentPage.upComingClientsPrimaryDescription",
    defaultMessage: "Clients with at least one upcoming visit within the next",
    description: "Text fragment explaining the time interval inputs",
  },
  clientSegmentEditSideDrawerTitle: {
    id: "campaigns.clientSegmentPage.clientSegmentEditSideDrawerTitle",
    defaultMessage: "Edit client segment",
    description: "Title of edit client segment side drawer",
  },
  clientSegmentEditSideDrawerUpdate: {
    id: "campaigns.clientSegmentPage.clientSegmentEditSideDrawerUpdate",
    defaultMessage: "Update Client Segment",
    description: "Button to update client segment criteria",
  },
  clients: {
    id: "campaigns.clientSegmentPage.upcomingClientsSegment.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}} ",
    description: "clients label",
  },
  allClientsDescription: {
    id: "campaigns.clientSegmentPage.meetCriteriaDescription",
    defaultMessage:
      "meet this criteria. Your clients will be added to this segment automatically when they meet this criteria.",
    description: "Text fragment explaining the time interval inputs",
  },
  refreshErrorMessage: {
    id: "campaigns.clientSegmentPage.refreshErrorMessage",
    defaultMessage: "There was an error refreshing the client segment.",
    description: "Error message when refreshing the client segment",
  },
  saveErrorMessage: {
    id: "campaigns.clientSegmentPage.saveErrorMessage",
    defaultMessage: "There was an error saving the client segment.",
    description: "Error message when saving the client segment fails",
  },
  yearsLabel: {
    id: "campaigns.clientSegmentPage.upcomingClientsYearsLabel",
    defaultMessage: "Year(s)",
    description: "Label for the Years input",
  },
  monthsLabel: {
    id: "campaigns.clientSegmentPage.upcomingClientsMonthsLabel",
    defaultMessage: "Month(s)",
    description: "Label for the Months input",
  },
  weeksLabel: {
    id: "campaigns.clientSegmentPage.upcomingClientsWeeksLabel",
    defaultMessage: "Week(s)",
    description: "Label for the Weeks input",
  },
  daysLabel: {
    id: "campaigns.clientSegmentPage.upcomingClientsDaysLabel",
    defaultMessage: "Day(s)",
    description: "Label for the Days input",
  },
});
