import React, { type PropsWithChildren, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Flex } from "@jobber/components/Flex";
import styles from "./InlinePrompt.module.css";

interface InlinePromptProps {
  readonly title?: string;
  readonly description?: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  url?: string;
  handlePrimaryAction?(): void;
  handleSecondaryAction?(): void;
  handleDismiss?(): void;
}

export function InlinePrompt({
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  handlePrimaryAction,
  handleSecondaryAction,
  handleDismiss,
  children,
  url,
}: PropsWithChildren<InlinePromptProps>) {
  const [showPrompt, setShowPrompt] = useState(true);
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      {showPrompt && (
        <div className={styles.box}>
          <Content>
            <Flex template={["grow", "shrink"]} gap="small" align="start">
              <Heading level={4}>{title}</Heading>
              <button
                className={styles.dismissButton}
                onClick={onDismiss}
                type="button"
                aria-label="Dismiss"
                data-testid="dismissButton"
              >
                <Icon name="cross" />
              </button>
            </Flex>
            <Text>{description}</Text>
            {children}
            <div className={styles.actionButtons}>
              {handlePrimaryAction && primaryActionLabel && (
                <Button
                  label={primaryActionLabel}
                  variation="learning"
                  onClick={onPrimaryButtonClick}
                  external={true}
                  loading={isLoading}
                  url={url}
                />
              )}
              {handleSecondaryAction && secondaryActionLabel && (
                <Button
                  label={secondaryActionLabel}
                  variation="learning"
                  type="tertiary"
                  onClick={onSecondaryButtonClick}
                  external={true}
                />
              )}
            </div>
          </Content>
        </div>
      )}
    </>
  );

  function onSecondaryButtonClick() {
    if (handleSecondaryAction != undefined) {
      handleSecondaryAction();
    }
  }

  function onPrimaryButtonClick() {
    setLoading(true);
    try {
      if (handlePrimaryAction != undefined) {
        handlePrimaryAction();
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  }

  function onDismiss() {
    setShowPrompt(false);
    if (handleDismiss != undefined) {
      handleDismiss();
    }
  }
}
