import { defineMessages } from "react-intl";

export const messages = defineMessages({
  availabilityCardHeader: {
    id: "selfServeBookings.SettingsPage.availabilityCardHeader",
    defaultMessage: "Availability",
    description: "Header for availability card",
  },
  advancedSettingsDisclosure: {
    id: "selfServeBookings.SettingsPage.advancedSettingsDisclosure",
    defaultMessage: "Advanced settings",
    description: "Label for the advanced settings disclosure",
  },
});
