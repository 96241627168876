import React, { useContext, useState } from "react";
import { Spinner } from "@jobber/components/Spinner";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton";
import { IntlProvider } from "@translations/IntlProvider";
import { UkKycLaggerState } from "jobber/chat/components/UkKycRegistration/UkKycLaggerState";
import { NotificationList } from "jobber/chat/components/ChatDrawer/NotificationList/NotificationList";
import { ConversationTypeEnum } from "~/utilities/API/graphql";
import type { ConversationsQueryVariables } from "~/utilities/API/graphql";
import { EmptyStateWrapper } from "jobber/chat/components/ChatDrawer/ChatList/EmptyState";
import {
  FilterSearchInput,
  type InputSearchFilterOption,
  NO_FILTER_SELECTED_VALUE,
} from "components/FilterSearchInput";
import { useStateWithLocalStorage } from "utilities/localStorage";
import {
  CONVERSATION_TYPE_FILTER,
  ConversationTypeFilterInput,
} from "jobber/chat/components/ChatDrawer/NotificationList/ConversationTypeFilterInput";
import { useConversationListQuery } from "jobber/chat/hooks";
import { UkKycRegistration } from "jobber/chat/components/UkKycRegistration/UkKycRegistration";
import {
  CHANNEL_PLACEHOLDER_DEFAULT,
  CHANNEL_PLACEHOLDER_EMAIL,
  CHANNEL_PLACEHOLDER_PHONE,
} from "./constants";
import styles from "./NotificationListWrapper.module.css";

const HAS_NOTIFICATION_FILTER = "has_notification";
interface NotificationListWrapperProps {
  hasPhoneNumber: boolean;
  showNotificationList: boolean;
  showConversationTypeFilter?: boolean;
}

const filterOptions: InputSearchFilterOption[] = [
  {
    label: "All messages",
    value: NO_FILTER_SELECTED_VALUE,
  },
  {
    label: "Unread",
    value: HAS_NOTIFICATION_FILTER,
  },
];

export function NotificationListWrapper({
  hasPhoneNumber,
  showNotificationList,
  showConversationTypeFilter = false,
}: NotificationListWrapperProps) {
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useStateWithLocalStorage(
    "NotificationListWrapper.searchFilter",
    NO_FILTER_SELECTED_VALUE,
    () => {
      return NO_FILTER_SELECTED_VALUE;
    },
  );
  const [searchConversationType, setSearchConversationType] =
    useStateWithLocalStorage(
      "NotificationListWrapper.searchConversationType",
      CONVERSATION_TYPE_FILTER.ALL,
      () => {
        return CONVERSATION_TYPE_FILTER.ALL;
      },
    );
  const [state] = useContext(MessageCenterContext);
  const clearFilter = () => {
    setSearchFilter(NO_FILTER_SELECTED_VALUE);
  };

  const variables: ConversationsQueryVariables = {
    searchTerm: searchTerm ? searchTerm : undefined,
    filter: {
      hasNotification:
        searchFilter === HAS_NOTIFICATION_FILTER ? true : undefined,
      conversationType: conversationTypeFilterForChannel(
        searchConversationType,
      ),
    },
  };

  const { data, error, nextPage, loadingInitialContent } =
    useConversationListQuery({ variables });

  function notificationListWithEmptyState() {
    // when the customer does not have a phone number
    // and has not acknowledged their compliance registration, we do not need to render any components
    // in this case the `UkKycRegistration` component will take care of states
    if (
      !hasPhoneNumber &&
      state.ukKycComplianceRegistrationData &&
      !state.ukKycComplianceRegistrationData.registration?.acknowledgedAt
    ) {
      return null;
    }
    // tried to store these in a variable shouldShowEmptyState
    // but our compiler wasn't smart enough to figure out that data wasn't null
    // if we got past this if statement
    if (!hasPhoneNumber || error || !data?.conversations?.edges?.length) {
      const filterLabel = filterOptions.find(
        (f: InputSearchFilterOption) => f.value === searchFilter,
      )?.label;
      return (
        <EmptyStateWrapper
          filterLabel={filterLabel}
          hasPhoneNumber={hasPhoneNumber}
          data={data}
          error={!!error}
          searchTerm={searchTerm}
          clearFilter={clearFilter}
          searchFilter={searchFilter}
          searchConversationType={
            showConversationTypeFilter
              ? searchConversationType
              : CONVERSATION_TYPE_FILTER.PHONE
          }
        />
      );
    }

    return (
      <NotificationList
        data={data}
        showNotificationList={showNotificationList}
        getMoreConversations={nextPage}
      />
    );
  }

  function onChannelFilterChange(value?: CONVERSATION_TYPE_FILTER): void {
    setSearchConversationType(value || CONVERSATION_TYPE_FILTER.ALL);
  }
  function displayNotificationList() {
    if (state?.isUkKycComplianceRegistrationHardBlocked && hasPhoneNumber) {
      return (
        <IntlProvider>
          <UkKycLaggerState />
        </IntlProvider>
      );
    }

    return (
      <>
        {showNotificationList && (
          <div className={"u-borderBottom"}>
            <div className={styles.searchWrapper}>
              <FilterSearchInput
                placeholder={searchFilterPlaceholderForChannel(
                  searchConversationType,
                  showConversationTypeFilter,
                )}
                value={searchValue}
                onChange={setSearchValue}
                onDebouncedChange={setSearchTerm}
                filter={{
                  onFilterChange: setSearchFilter,
                  selectedFilter: searchFilter,
                  filterOptions: filterOptions,
                }}
              >
                <>
                  {showConversationTypeFilter && (
                    <ConversationTypeFilterInput
                      conversationTypeFilter={searchConversationType}
                      onFilterChange={onChannelFilterChange}
                    />
                  )}
                </>
              </FilterSearchInput>
            </div>
            <UkKycRegistration hasPhoneNumber={hasPhoneNumber} />
          </div>
        )}

        {loadingInitialContent ? (
          <div className={"row collapse align-center u-paddingTopLarger"}>
            <Spinner inline={true} />
          </div>
        ) : (
          notificationListWithEmptyState()
        )}
      </>
    );
  }

  return displayNotificationList();
}

function searchFilterPlaceholderForChannel(
  searchConversationType: CONVERSATION_TYPE_FILTER,
  showConversationTypeFilter: boolean,
): string {
  if (!showConversationTypeFilter) {
    return CHANNEL_PLACEHOLDER_PHONE;
  }

  switch (searchConversationType) {
    case CONVERSATION_TYPE_FILTER.PHONE:
      return CHANNEL_PLACEHOLDER_PHONE;
    case CONVERSATION_TYPE_FILTER.EMAIL:
      return CHANNEL_PLACEHOLDER_EMAIL;
    default:
      return CHANNEL_PLACEHOLDER_DEFAULT;
  }
}

function conversationTypeFilterForChannel(
  searchConversationType: CONVERSATION_TYPE_FILTER,
): ConversationTypeEnum | undefined {
  switch (searchConversationType) {
    case CONVERSATION_TYPE_FILTER.PHONE:
      return ConversationTypeEnum.PHONE;
    case CONVERSATION_TYPE_FILTER.EMAIL:
      return ConversationTypeEnum.EMAIL;
    default:
      return undefined;
  }
}
