import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import React from "react";
import { NotificationStringTemplate } from "jobber/settings/notifications/components/NotificationStringTemplate/NotificationStringTemplate";
import { ScheduleRow } from "jobber/settings/notifications/components/Scheduler/components/ScheduleRow/ScheduleRow";
import type { SchedulerProps } from "jobber/settings/notifications/notificationInterfaces";

export function Scheduler({
  scheduleFootnote,
  schedules,
  scheduleTemplate,
  allowUnitChange,
  allowDeliveryMethodChange,
  onDispatch,
  type,
}: SchedulerProps) {
  return (
    <>
      <ul className="list list--dividers">
        {schedules.map((schedule, index: number) => {
          return (
            <li className="list-item" key={schedule.id}>
              <ScheduleRow
                scheduleTemplate={scheduleTemplate}
                schedule={schedule}
                allowUnitChange={allowUnitChange}
                allowDeliveryMethodChange={allowDeliveryMethodChange}
                onDispatch={onDispatch}
                canDelete={index >= 1}
              />
            </li>
          );
        })}

        {/* Workaround to get consistent schedule experience in Jobber.
            Basically, job follow up doesn't really have a schedule, but it
            should look like it has a schedule */}
        {scheduleTemplate && type === "JOB_FOLLOWUP" && (
          <li className="list-item">
            <Text>
              <NotificationStringTemplate template={scheduleTemplate} />
            </Text>
          </li>
        )}
      </ul>

      {scheduleFootnote && (
        <Text variation="subdued">
          <Emphasis variation="italic">{scheduleFootnote}</Emphasis>
        </Text>
      )}
    </>
  );
}
