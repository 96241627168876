import React, { useState } from "react";
import { v1 as uuidV1 } from "uuid";

interface ColorPickerProps {
  value: string;
  placeholder: string;
  dataList: string[];
  inputNameAttribute: string;
  onChange?(newValue: string): void;
}

export function ColorPicker({
  value,
  placeholder,
  dataList,
  inputNameAttribute,
  onChange,
}: ColorPickerProps) {
  const [currentColorValue, setColor] = useState(value);
  const dataListId = uuidV1();

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.currentTarget.value);
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <div className="fieldAffix">
      <span className="fieldAffix-item">
        <input
          type="color"
          value={currentColorValue}
          onChange={handleColorChange}
          list={dataListId}
          name={inputNameAttribute}
        />
        <datalist id={dataListId}>
          {dataList.map(color => {
            const selected = color === value;

            return (
              <option key={color} aria-selected={selected} value={color} />
            );
          })}
        </datalist>
      </span>
      <placeholder-field class="" label={placeholder} auto-size={false}>
        <input
          type="text"
          maxLength={7}
          value={currentColorValue}
          onChange={handleColorChange}
        />
      </placeholder-field>
    </div>
  );
}
