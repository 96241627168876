import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  JobEditLineItemAttributes,
  JobEditLineItemsMutation,
  JobEditLineItemsMutationVariables,
} from "~/utilities/API/graphql";
import { JOB_COST_LINE_ITEM_TOTALS_QUERY, JOB_EDIT_LINE_ITEM } from "./graphql";
import { messages } from "./messages";

export interface JobEditLineItemsInput {
  lineItems: JobEditLineItemAttributes[];
}

export function useJobEditLineItemMutation(
  jobId: string,
  canViewJobCosts: boolean,
  canViewPricing: boolean,
  onSuccess: () => void,
): {
  handleJobEditLineItem: (input: JobEditLineItemsInput) => Promise<void>;
  loading: boolean;
} {
  const [editJobLineItem, { loading }] = useMutation<
    JobEditLineItemsMutation,
    JobEditLineItemsMutationVariables
  >(JOB_EDIT_LINE_ITEM, {
    refetchQueries: [
      {
        query: JOB_COST_LINE_ITEM_TOTALS_QUERY,
        variables: {
          jobId: jobId,
          canViewJobCosts: canViewJobCosts,
          canViewPricing: canViewPricing,
        },
      },
    ],
  });

  async function handleJobEditLineItem(input: JobEditLineItemsInput) {
    try {
      const result = await editJobLineItem({
        variables: {
          jobId,
          input,
          canViewPricing: canViewPricing,
        },
      });

      const errors = result.data?.jobEditLineItems.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.lineItemSaved,
          variation: "success",
        });
        onSuccess();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleJobEditLineItem,
    loading,
  };
}
