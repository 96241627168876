import { defineMessages } from "react-intl";

export const messages = defineMessages({
  add: {
    id: "billing.subscriptionAddonCard.add",
    defaultMessage: "Add",
    description: "Add to plan button text",
  },
  addAriaLabel: {
    id: "billing.subscriptionAddonCard.addAriaLabel",
    defaultMessage: "Add {addonName}",
    description: "Aria label for add to plan button",
  },
});
