import { gql } from "@apollo/client";

export const AUTOMATION_RULE_EDIT_MUTATION = gql`
  mutation AutomationRuleEdit(
    $ruleId: EncodedId!
    $name: String!
    $trigger: JSON!
    $condition: JSON
    $action: JSON!
  ) {
    automationRuleEdit(
      input: {
        name: $name
        trigger: $trigger
        condition: $condition
        action: $action
      }
      ruleId: $ruleId
    ) {
      rule {
        action
        actionedCount
        active
        condition
        id
        name
        trigger
      }
      userErrors {
        message
        path
      }
    }
  }
`;
