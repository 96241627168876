import React from "react";
import { useIntl } from "react-intl";
import {
  Content,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
} from "@jobber/components";
import { messages } from "../messages";
import { EventTypes, trackEventWithAmplitude } from "../../../utils";

export interface AverageDaysToGettingPaidProps {
  averageDaysToGettingPaid?: number;
}

export const AverageDaysToGettingPaid = ({
  averageDaysToGettingPaid,
}: AverageDaysToGettingPaidProps) => {
  const { formatMessage } = useIntl();

  return (
    <Content type={"section"} spacing={"small"}>
      <Flex template={["shrink", "shrink"]} gap="smallest">
        <Text size="small">
          {formatMessage(messages.averageDaysToGettingPaidHeading)}
        </Text>
        <Tooltip
          preferredPlacement="bottom"
          message={formatMessage(messages.averageDaysToGettingPaidToolTip)}
        >
          <span
            onMouseEnter={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Tooltip,
                eventSource: formatMessage(
                  messages.averageDaysToGettingPaidToolTip,
                ),
              });
            }}
          >
            <Icon name={"help"} size={"small"} />
          </span>
        </Tooltip>
      </Flex>
      <Heading level={2}>
        {averageDaysToGettingPaid != null
          ? formatMessage(messages.averageDaysToGettingPaid, {
              averageDaysToGettingPaid,
            })
          : "-"}
      </Heading>
    </Content>
  );
};
