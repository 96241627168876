import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import { Form } from "@jobber/components/Form";
import { useFormState } from "@jobber/hooks/useFormState";

interface EditMessageDescriptionProps {
  description: string;
  onUpdate(descriptionmessage: string): Promise<void>;
}

export function EditMessageDescriptionCard({
  description,
  onUpdate,
}: EditMessageDescriptionProps) {
  const [currentMessageDescription, setCurrentMessageDescription] =
    useState(description);

  // Update the textbox contents if the description prop changes.
  useEffect(() => {
    setCurrentMessageDescription(description);
  }, [description]);

  const [editMessageDescriptionOpen, setMessageDescriptionOpen] =
    useState(false);

  const openMessageDescription = () => {
    setMessageDescriptionOpen(true);
  };

  const onMessageDescriptionUpdate = (newMessageDescription: string) => {
    setCurrentMessageDescription(newMessageDescription);
  };

  const cancelMessageDescriptionEdit = () => {
    setMessageDescriptionOpen(false);
    setCurrentMessageDescription(description);
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const saveMessageDescription = () => {
    setSaveLoading(true);
    onUpdate(currentMessageDescription).then(
      () => {
        showToast({
          message: "'Message from the Pro' description saved",
          variation: "success",
        });
        setSaveLoading(false);
        setMessageDescriptionOpen(false);
      },
      () => {
        showToast({
          message:
            "A problem occurred when saving your 'Message from the Pro' description",
          variation: "error",
        });
        setSaveLoading(false);
      },
    );
  };

  // Used for validation system
  const [formState, setFormState] = useFormState();

  return (
    <>
      <Button label="Edit" type="tertiary" onClick={openMessageDescription} />

      <Modal
        title="Edit 'message from the pro' description"
        open={editMessageDescriptionOpen}
        onRequestClose={cancelMessageDescriptionEdit}
        primaryAction={{
          label: "Save",
          onClick: saveMessageDescription,
          loading: saveLoading,
          disabled: !formState.isValid,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: cancelMessageDescriptionEdit,
        }}
      >
        <Content>
          <Form onStateChange={setFormState}>
            <InputText
              multiline={true}
              value={currentMessageDescription}
              onChange={onMessageDescriptionUpdate}
              placeholder="Message (maximum 300 characters)"
              validations={{
                maxLength: {
                  value: 300,
                  message: "Your message exceeds 300 characters",
                },
              }}
            />
          </Form>
        </Content>
      </Modal>
    </>
  );
}
