import { defineMessages } from "react-intl";

export const messages = defineMessages({
  shareToGoogleProfile: {
    id: "selfServeBookings.ShareToGoogleProfileButton.shareToGoogleProfile",
    defaultMessage: "Connect to Google",
    description: "Label for the share to Google Profile button",
  },
  sharedToGoogleProfile: {
    id: "selfServeBookings.ShareToGoogleProfileButton.sharedToGoogleProfile",
    defaultMessage: "Connected to Google",
    description: "Label for the shared to Google Profile button",
  },
  connectModalTitle: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalTitle",
    defaultMessage: "Connect to Google Business Profile",
    description: "Title for the connect modal",
  },
  connectModalPrimaryAction: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalPrimaryAction",
    defaultMessage: "Connect",
    description: "Label for the connect modal primary action button",
  },
  connectModalSecondaryAction: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalSecondaryAction",
    defaultMessage: "Update information",
    description: "Label for the connect modal secondary action button",
  },
  connectModalDescription: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalDescription",
    defaultMessage:
      "Google takes up to 1 week to add a booking link to your Google Business Profile. The following information will be sent to them and needs to exactly match your Profile. [Learn more in our Help Center](https://help.getjobber.com/hc/en-us/articles/22894204540951/)",
    description: "Description for the connect modal",
  },
  connectModalBusinessName: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalBusinessName",
    defaultMessage: "Company",
    description: "Label for the business name in the connect modal",
  },
  connectModalLocation: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalLocation",
    defaultMessage: "Address",
    description: "Label for the location in the connect modal",
  },
  connectModalPhone: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalPhone",
    defaultMessage: "Phone",
    description: "Label for the phone in the connect modal",
  },
  connectModalWebsite: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalWebsite",
    defaultMessage: "Website",
    description: "Label for the website in the connect modal",
  },
  connectModalLoadError: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalLoadError",
    defaultMessage: "An error occurred loading your company details",
    description: "Error message for when the company details fail to load",
  },
  connectModalRequiredFieldsError: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalRequiredFieldsError",
    defaultMessage:
      "Business name and address are required to connect to Google Business Profile",
    description: "Error message for when the required fields are missing",
  },
  connectModalRequiredFieldPlaceholder: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalRequiredFieldPlaceholder",
    defaultMessage: "Not added",
    description: "Placeholder for the required fields",
  },
  connectModalSaveError: {
    id: "selfServeBookings.ShareToGoogleProfileButton.connectModalSaveError",
    defaultMessage: "An error occurred saving your settings",
    description: "Error message for when the settings fail to save",
  },
  disconnectModalTitle: {
    id: "selfServeBookings.ShareToGoogleProfileButton.disconnectModalTitle",
    defaultMessage: "Remove connection to Google Business Profile",
    description: "Title for the disconnect modal",
  },
  disconnectModalPrimaryAction: {
    id: "selfServeBookings.ShareToGoogleProfileButton.disconnectModalPrimaryAction",
    defaultMessage: "Remove booking link",
    description: "Label for the disconnect modal primary action button",
  },
  disconnectModalDescription: {
    id: "selfServeBookings.ShareToGoogleProfileButton.disconnectModalDescription",
    defaultMessage:
      "Removing the booking link means that clients will no longer be able to book work directly from your Google Business Profile.",
    description: "Description for the disconnect modal",
  },
});
