import { defineMessages } from "react-intl";

export const messages = defineMessages({
  depositedPayoutsHeading: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.heading",
    defaultMessage: "Deposited payouts",
    description: "Heading for deposited payouts section",
  },
  depositedPayoutsThisWeekTitle: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.thisWeek",
    defaultMessage: "This week",
    description: "Title for this week deposited payouts",
  },
  depositedPayoutsThisMonthTitle: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.thisMonth",
    defaultMessage: "This month",
    description: "Title for this month deposited payouts",
  },
  viewBankPayoutsLabel: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.viewBankPayoutsLabel",
    defaultMessage: "View bank payouts",
    description: "Title for view bank payouts button",
  },
  viewTransactionsLabel: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.viewTransactionsLabel",
    defaultMessage: "View transactions",
    description: "Title for View transactions button",
  },
  viewLoanReportLabel: {
    id: "managedAccount.financialInsightsSection.depositedPayouts.viewLoanReportLabel",
    defaultMessage: "View loan report",
    description: "Title for View loan report button",
  },
});
