import React from "react";

export interface AuthContextType {
  authenticityToken: string;
}

export const AuthContext = React.createContext<AuthContextType>({
  authenticityToken: "",
});
AuthContext.displayName = "AuthContext";
