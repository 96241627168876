import React from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Cell, Footer } from "components/Table";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./ExpensesTable.module.css";
import { messages } from "./messages";

interface ExpensesTableFooterProps {
  amount: number;
}

export function ExpensesTableFooter({ amount }: ExpensesTableFooterProps) {
  const { currencySymbol } = useAccount();
  const { formatMessage } = useIntl();

  return (
    <Footer>
      <Cell size="base" />
      <Cell size="base" />
      <Cell size="base" />
      <Cell size="base" />
      <Cell className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          <Heading level={5}>
            {formatMessage(messages.expenseTableFooter)}
          </Heading>
        </span>
        <span className={styles.numericBold}>
          {formatCurrency(amount, currencySymbol)}
        </span>
      </Cell>
    </Footer>
  );
}
