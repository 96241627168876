import type {
  ProductOrServiceCustomFieldCustomFieldTextFragment,
  ProductOrServiceCustomFieldFragment,
} from "~/utilities/API/graphql";

export function isCustomFieldText(
  customField: ProductOrServiceCustomFieldFragment,
): customField is ProductOrServiceCustomFieldCustomFieldTextFragment {
  return (
    (customField as ProductOrServiceCustomFieldCustomFieldTextFragment)
      .valueText !== undefined
  );
}
