import React from "react";
import styles from "./LoadingBar.module.css";

export interface LoadingBarProps {
  steps: "two" | "three";
}

export function LoadingBar({ steps }: LoadingBarProps): JSX.Element {
  const animationClass =
    steps === "two"
      ? styles.loadingBarTwoStepAnimation
      : styles.loadingBarThreeStepAnimation;

  return (
    <div className={`${styles.loadingBarContainer} ${animationClass}`}>
      <div className={styles.loadingBar}></div>
    </div>
  );
}
