import { defineMessages } from "react-intl";

export const messages = defineMessages({
  // Labour table column headers
  labourTableTeamColumnHeader: {
    id: "jobCosting.LabourTable.labourTableTeamColumnHeader",
    defaultMessage: "Team",
    description: "Team column header of the labour table",
  },
  labourTableNotesColumnHeader: {
    id: "jobCosting.LabourTable.labourTableNotesColumnHeader",
    defaultMessage: "Notes",
    description: "Notes column header of the labour table",
  },
  labourTableDateColumnHeader: {
    id: "jobCosting.LabourTable.labourTableDateColumnHeader",
    defaultMessage: "Date",
    description: "Date column header of the labour table",
  },
  labourTableHoursColumnHeader: {
    id: "jobCosting.LabourTable.labourTableHoursColumnHeader",
    defaultMessage: "Hours",
    description: "Hours column header of the labour table",
  },
  labourTableCostColumnHeader: {
    id: "jobCosting.LabourTable.labourTableCostColumnHeader",
    defaultMessage: "Cost",
    description: "Cost column header of the labour table",
  },
  labourTableTotalHoursLabel: {
    id: "jobCosting.LabourTable.labourTableTotalHoursLabel",
    defaultMessage: "Total hours",
    description: "Total hours label of the labour table",
  },
  labourTableTotalCostLabel: {
    id: "jobCosting.LabourTable.labourTableTotalCostLabel",
    defaultMessage: "Total cost",
    description: "Total cost label of the labour table",
  },
  labourTableEmptyStateMessage: {
    id: "jobCosting.LabourTable.labourTableEmptyStateMessage",
    defaultMessage:
      "Time tracked to this job by you or your team will show here",
    description: "Message shown when there are no timesheet entries",
  },
});
