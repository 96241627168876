import React from "react";
import { useQuery } from "@apollo/client";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import type { DropResult, DroppableProvided } from "react-beautiful-dnd";
import { LineItemsBulkEditEmptyState } from "~/jobber/lineItems/components/LineItemsBulkEdit/LineItemsBulkEditEmptyState";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import type { ProductsQuery } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";
import { CustomFieldSideDrawer } from "~/jobber/customFields/components/CustomFieldSideDrawer";
import { LineItemCustomFieldChips } from "~/jobber/customFields/components/LineItemCustomFieldChips";
import { useCustomFieldSideDrawer } from "~/jobber/customFields/hooks/useCustomFieldSideDrawer";
import styles from "./styles.module.css";
import { PRODUCT_OR_SERVICES_QUERY } from "./components/graphql";
import { DraggableWrapper } from "./components/LineItemInput/components/DraggableWrapper/DraggableWrapper";
import type { LineItemInputProps } from "./components/LineItemInput/types";

export interface LineItemsBulkEditProps<T extends LineItem = LineItem> {
  lineItems: T[];
  dispatch: (action: LineItemsAction<T>) => void;
  lineItemRenderer: (props: LineItemInputProps<T>) => React.ReactNode;
}

export function onDragEnd(
  lineItems: LineItem[],
  dispatch: (action: LineItemsAction) => void,
) {
  return function (
    result: Pick<DropResult, "source" | "destination" | "draggableId">,
  ) {
    const { source, destination, draggableId } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newLineItems = Array.from(lineItems);
    const draggedLineItem = newLineItems.find(
      (element: LineItem) => String(element.reactKey) === draggableId,
    );
    newLineItems.splice(source.index, 1);
    if (!draggedLineItem) {
      return;
    }
    newLineItems.splice(destination.index, 0, draggedLineItem);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newLineItems.forEach((lineItem: any, index: number) => {
      lineItem.sort_order = index;
    });

    dispatch({
      type: LineItemsBulkEditActionTypes.ReorderLineItems,
      reorderedLineItems: newLineItems,
    });
  };
}

export function LineItemsBulkEdit(props: LineItemsBulkEditProps) {
  return (
    <div data-testid="lineItems">
      <DragDropContext onDragEnd={onDragEnd(props.lineItems, props.dispatch)}>
        <Droppable droppableId={"line-items-bulk-edit"}>
          {(providedDroppable: DroppableProvided) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              <LineItemsBulkEditContent {...props} />
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function LineItemsBulkEditContent({
  lineItems,
  dispatch,
  lineItemRenderer,
}: LineItemsBulkEditProps) {
  const { data } = useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const {
    handleCustomFieldChipClick,
    handleRequestClose,
    sideDrawerAttributes,
  } = useCustomFieldSideDrawer();

  if (lineItems.length <= 0) {
    return <LineItemsBulkEditEmptyState />;
  }

  return (
    <div className={styles.lineItemsBulkEditWrapper}>
      {lineItems.map((lineItem, index) => (
        <div
          className={styles.lineItemsInputContainer}
          key={lineItem.reactKey}
          data-testid="lineItem"
        >
          <DraggableWrapper lineItemIndex={index} reactKey={lineItem.reactKey}>
            {dragHandle =>
              lineItemRenderer({
                initialOptions: data?.products.nodes ?? [],
                lineItem,
                dispatch,
                dragHandle,
                customFieldsSection: lineItem.linkedProductOrService?.id && (
                  <LineItemCustomFieldChips
                    productOrServiceId={lineItem.linkedProductOrService.id}
                    onChipClick={handleCustomFieldChipClick(
                      lineItem.image?.thumbnailUrl || "",
                    )}
                  />
                ),
              })
            }
          </DraggableWrapper>
        </div>
      ))}
      <CustomFieldSideDrawer
        open={sideDrawerAttributes.open}
        onRequestClose={handleRequestClose}
        productOrServiceId={sideDrawerAttributes.productOrServiceId}
        lineItemThumbnailUrl={sideDrawerAttributes.lineItemThumbnailUrl}
        app={sideDrawerAttributes.app}
      />
    </div>
  );
}
