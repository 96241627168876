import { InputGroup } from "@jobber/components/InputGroup";
import { InputTime } from "@jobber/components/InputTime";
import type { CivilTime } from "@std-proposal/temporal";
import React, { useEffect, useState } from "react";
import { InputValidation } from "@jobber/components/InputValidation";
import type { TimeRange } from "jobber/google_lsa/MerchantConfiguration";
import { startTimeIsBeforeEndTime } from "./ArrivalWindowsValidation";

export interface ArrivalWindowRangeProps {
  startAt: CivilTime;
  endAt: CivilTime;
  onChange(newValue: TimeRange): void;
}

export function ArrivalWindowRange({
  startAt,
  endAt,
  onChange,
}: ArrivalWindowRangeProps) {
  const isStartTimeBeforeEndTime = startTimeIsBeforeEndTime(startAt, endAt);

  /*
    Specifically on Safari, there is an issue with props not being updated if
    read directly from an onChange function on InputTime. This results in the
    field not being modified "resetting" to the original value. The following
    useState/useEffect dance is specifically to make sure any onChange events
    fired upwards always use the most up to date version of the other field.
  */
  const [timeRange, setTimeRange] = useState({
    startAt: startAt,
    endAt: endAt,
  });

  useEffect(() => {
    onChange(timeRange);
  }, [timeRange]);

  return (
    <>
      <InputGroup flowDirection="horizontal">
        <InputTime
          value={timeRange.startAt}
          onChange={handleChange("startAt")}
          size="small"
          placeholder="Arrival window start time"
          invalid={!isStartTimeBeforeEndTime}
        />
        <InputTime
          value={timeRange.endAt}
          onChange={handleChange("endAt")}
          size="small"
          placeholder="Arrival window end time"
          invalid={!isStartTimeBeforeEndTime}
        />
      </InputGroup>
      {isStartTimeBeforeEndTime ? (
        <></>
      ) : (
        <InputValidation message="Start time must be before end time" />
      )}
    </>
  );

  function handleChange(which: "startAt" | "endAt") {
    return (newValue: CivilTime) => {
      setTimeRange(state => {
        return {
          ...state,
          [which]: newValue,
        };
      });
    };
  }
}
