import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AtlantisThemeContextProvider } from "@jobber/components";
import { IntlProvider } from "@translations/IntlProvider";
import { SettingsPage } from "jobber/settings/selfServeBookings/views";
import { NoMatch, join } from "jobber/settings/users/utilities/routing";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { AccountConfigurationContextProvider } from "./components";
import { GoogleMapsApiContext } from "./GoogleMapsApiContext";

export interface SelfServeBookingsRouteProps {
  readonly urlContext: {
    readonly companySettingsPath: string;
    readonly subWorkConfigurationWorkSettingsPath: string;
    readonly manageTeamPath: string;
  };
  readonly features: {
    readonly selfServeBookingsEnabled: boolean;
    readonly advancedQuotingEnabled: boolean;
    readonly quoteMarginsEnabled: boolean;
  };
  readonly currencySymbol: string;
  readonly googleMapsApiKey: string;
}

export function SelfServeBookingsRoute(props: SelfServeBookingsRouteProps) {
  const { path } = useRouteMatch();

  const renderSettingsPage = () => {
    return (
      <GoogleMapsApiContext.Provider value={props.googleMapsApiKey}>
        <SettingsPage />
      </GoogleMapsApiContext.Provider>
    );
  };

  return (
    <IntlProvider>
      <AtlantisThemeContextProvider>
        <AccountConfigurationContextProvider
          features={props.features}
          currencySymbol={props.currencySymbol}
        >
          <Switch>
            <Route exact path={join(path)} render={renderSettingsPage} />
            <Route exact path={join(path, "services")}>
              <Redirect to={join(path)} />
            </Route>
            <Route exact path={join(path, "schedule")}>
              <Redirect to={join(path)} />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </AccountConfigurationContextProvider>
      </AtlantisThemeContextProvider>
    </IntlProvider>
  );
}

export const SelfServeBookingSettingsRouteWrapped = withEntryPointProviders(
  SelfServeBookingsRoute,
);
