import { messages } from "./messages";

export enum IndustryName {
  ConstructionAndContracting = "Construction & Contracting",
  ElectricalContractor = "Electrical Contractor",
  Handyman = "Handyman",
  HVAC = "HVAC",
  LandscapingContractor = "Landscaping Contractor",
  LawnCareAndLawnMaintenance = "Lawn Care & Lawn Maintenance",
  Painting = "Painting",
  PestControl = "Pest Control",
  Plumbing = "Plumbing",
  PressureWashingService = "Pressure Washing Service",
  ResidentialCleaning = "Residential Cleaning",
  WindowWashing = "Window Washing",
}

export const INDUSTRY_VIDEOS = [
  { industry: IndustryName.ConstructionAndContracting, id: "n7t5o3y2uf" },
  { industry: IndustryName.ElectricalContractor, id: "m7tus3f15z" },
  { industry: IndustryName.Handyman, id: "t0y2w9eowf" },
  { industry: IndustryName.HVAC, id: "dtrmn0vu41" },
  { industry: IndustryName.LandscapingContractor, id: "nosunp1twi" },
  { industry: IndustryName.LawnCareAndLawnMaintenance, id: "l35abqpp0s" },
  { industry: IndustryName.Painting, id: "q7j19rmn8k" },
  { industry: IndustryName.PestControl, id: "heydi4qw4n" },
  { industry: IndustryName.Plumbing, id: "heelm56uvh" },
  { industry: IndustryName.PressureWashingService, id: "dfng5ltw64" },
  { industry: IndustryName.ResidentialCleaning, id: "m476k6mfq6" },
  { industry: IndustryName.WindowWashing, id: "923cve3crl" },
];

export const DEFAULT_VIDEO_DATA = {
  id: "dhry6w4bi1",
  text: messages.welcomeVideoDefaultText,
};
