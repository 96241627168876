import React, { createRef, useState } from "react";
import type { InputTextRef, SectionProps } from "@jobber/components";
import {
  Button,
  Content,
  Flex,
  InputText,
  Menu,
  Text,
} from "@jobber/components";
import { useIntl } from "react-intl";
import type { MessageTemplateMessageTemplateEmailFragment } from "~/utilities/API/graphql";
import { messages } from "../messages";
import type { MessageTemplate } from "../types";

enum AvailableEditors {
  subject,
  message,
}

interface TemplateEditorProps {
  template: MessageTemplate;
  setTemplate: (newTemplates: MessageTemplate) => void;
  inputRowMin?: number;
  inputRowMax?: number;
}

export function TemplateEditor({
  template,
  setTemplate,
  inputRowMin = 6,
  inputRowMax = 10,
}: TemplateEditorProps) {
  const { formatMessage } = useIntl();

  const [activeEditor, setActiveEditor] = useState<AvailableEditors>(
    AvailableEditors.message,
  );

  const refs = {
    [AvailableEditors.subject]: createRef<InputTextRef>(),
    [AvailableEditors.message]: createRef<InputTextRef>(),
  };

  const isEmail = template.deliveryMethod === "EMAIL";
  const isSMS = template.deliveryMethod === "SMS";

  return (
    <Content>
      {isEmail &&
        (template as MessageTemplateMessageTemplateEmailFragment).subject && (
          <InputText
            placeholder={formatMessage(messages.emailSubjectPlaceholderText)}
            value={
              (template as MessageTemplateMessageTemplateEmailFragment).subject
                .current
            }
            onChange={onEmailSubjectChange}
            ref={refs[AvailableEditors.subject]}
            onFocus={() => setActiveEditor(AvailableEditors.subject)}
          />
        )}
      {isCharLengthExceeded() && (
        <Text variation="warn">
          {formatMessage(messages.characterLimitExceeded)}
        </Text>
      )}
      <InputText
        multiline={true}
        placeholder={formatMessage(messages.inputFieldPlaceholderText)}
        ref={refs[AvailableEditors.message]}
        onFocus={() => setActiveEditor(AvailableEditors.message)}
        rows={{ min: inputRowMin, max: inputRowMax }}
        value={template.message.current}
        onChange={onTextInputChange}
      />
      <Flex template={["shrink", "grow", "shrink"]}>
        <Button
          type="secondary"
          variation="destructive"
          label={formatMessage(messages.resetButtonLabel)}
          size="small"
          onClick={onInputReset}
        />
        {isSMS ? (
          <Text
            align={"end"}
            variation={isCharLengthExceeded() ? "warn" : "default"}
          >
            {template.message.current.length}
          </Text>
        ) : (
          <div />
        )}
        <Menu
          activator={
            <Button
              size="small"
              type="secondary"
              label={formatMessage(messages.insertButtonLabel)}
            />
          }
          items={convertToMenu(template, insertVariable)}
        />
      </Flex>
    </Content>
  );

  function onEmailSubjectChange(newValue: string) {
    if (isEmail) {
      const emailTemplate =
        template as MessageTemplateMessageTemplateEmailFragment;
      setTemplate({
        ...emailTemplate,
        subject: {
          ...emailTemplate.subject,
          current: newValue,
        },
      });
    }
  }

  function onTextInputChange(newValue: string) {
    setTemplate({
      ...template,
      message: {
        ...template.message,
        current: newValue,
      },
    });
  }

  function onInputReset() {
    let resetTemplate = {
      ...template,
      message: {
        ...template.message,
        current: template.message.default,
      },
    };

    if (isEmail) {
      const emailTemplate = {
        ...(template as MessageTemplateMessageTemplateEmailFragment),
      };

      resetTemplate = {
        ...emailTemplate,
        message: {
          ...emailTemplate.message,
          current: emailTemplate.message.default,
        },
        subject: {
          ...emailTemplate.subject,
          current: emailTemplate.subject.default,
        },
      };
    }
    setTemplate(resetTemplate);
  }

  function isCharLengthExceeded(): boolean {
    return isSMS && template.message.current.length > 160 ? true : false;
  }

  function insertVariable(text: string) {
    const active = refs[activeEditor];
    if (active.current) {
      active.current.insert(`{{${text}}}`);
    }
  }
}

function convertToMenu(
  template: TemplateEditorProps["template"],
  insertVariable: (variable: string) => void,
): SectionProps[] {
  return template.variables.nodes
    .filter(variableSection => variableSection.label !== "HIDDEN")
    .map(
      variableSection =>
        ({
          header: variableSection.label,
          actions: variableSection.variables.nodes.map(variable => ({
            label: variable.label,
            onClick: () => {
              insertVariable(variable.name);
            },
          })),
        }) as SectionProps,
    );
}
