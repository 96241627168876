import { defineMessages } from "react-intl";

export const messages = defineMessages({
  startGrowTrialLeftHeading: {
    id: "startGrowTrialHeading",
    defaultMessage: "Here’s where you can start with {planName} ",
    description:
      "Onboarding checklist left side heading for Start Grow trial step",
  },
  startGrowTrialLeftPlanNameEmphasis: {
    id: "startGrowTrialPlanNameEmphasis",
    defaultMessage: "Grow.",
    description:
      "Onboarding checklist plan name for emphasis in left heading for Start Grow trial step",
  },
  startToGrowTrialLeftDescription: {
    id: "startToGrowTrialLeftDescription",
    defaultMessage:
      "From automated quote follow-ups to job costing, take advantage of Grow features throughout the trial and see how they can help you scale your business.",
    description: "Onboarding checklist description for Start Grow trial step",
  },
  startToGrowTrialProductExpertButton: {
    id: "startToGrowTrialProductExpertButton",
    defaultMessage: "Speak to a Product Expert",
    description: "Onboarding checklist Speak to a product expert button copy",
  },
});
