import React, { useContext } from "react";
// DO NOT COPY: Allowing an exception here for a legacy use of Typography.
// eslint-disable-next-line no-restricted-imports
import { Heading } from "@jobber/components/Heading";
import classNames from "classnames";
import { Button } from "@jobber/components/Button";
import {
  ChatView,
  MessageCenterContext,
} from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { ConversationActionsButton } from "jobber/chat/components/ChatDrawer/ListHeader/ConversationActionsButton";
import styles from "./ListHeader.module.css";

interface ListHeaderProps {
  showCompose: boolean;
  markUnread(): void;
  deleteConversation(): void;
  reassignConversation(): void;
  requestDrawerClose(): void;
}

export function ListHeader({
  showCompose,
  requestDrawerClose,
  markUnread,
  deleteConversation,
  reassignConversation,
}: ListHeaderProps) {
  const [state, dispatch] = useContext(MessageCenterContext);

  const goBack = () => {
    if (dispatch) {
      dispatch({
        type: "HIDE_CHAT",
      });
    }
  };

  const clickCompose = () => {
    if (dispatch) {
      dispatch({
        type: "COMPOSE",
        title: "New Message",
      });
    }
  };

  return (
    <div className={styles.header}>
      {state.activeView !== ChatView.Notifications &&
        state.activeView !== ChatView.RegistrationInProgress && (
          <Button
            type="tertiary"
            variation="subtle"
            icon={"arrowLeft"}
            onClick={goBack}
            ariaLabel="Go back"
          />
        )}
      <div
        className={classNames(
          styles.fullWidth,
          "u-paddingNone u-paddingLeftSmall u-paddingRightSmall",
        )}
      >
        <Heading level={2}>{state.chatDrawerTitle}</Heading>
      </div>
      <div style={{ display: "flex" }}>
        {state.activeView === ChatView.Notifications && showCompose && (
          <Button
            type="tertiary"
            variation="subtle"
            icon={"compose"}
            onClick={clickCompose}
            ariaLabel="Compose new message"
          />
        )}
        {state.activeView === ChatView.Conversation && (
          <ConversationActionsButton
            markUnread={markUnread}
            deleteConversation={deleteConversation}
            reassignConversation={reassignConversation}
          />
        )}
        <Button
          type="tertiary"
          variation="subtle"
          icon={"cross"}
          onClick={requestDrawerClose}
          ariaLabel="Close list"
        />
      </div>
    </div>
  );
}
