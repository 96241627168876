import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { InputText, type InputTextRef } from "@jobber/components/InputText";
import { Menu } from "@jobber/components/Menu";
import { Switch } from "@jobber/components/Switch";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import React, {
  type Ref,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { ActionTypes } from "jobber/settings/notifications/ActionTypes";
import { TemplatePreviewer } from "jobber/settings/notifications/components/TemplateEditor/components/TemplatePreviewer";
import type { TemplateEditorProps } from "jobber/settings/notifications/notificationInterfaces";
import { assertHasTypename } from "utilities/types/assertTypes";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import { NotificationDeliveryMethod } from "~/utilities/API/graphql";

const EditorsWithRef = forwardRef(Editors);

export function TemplateEditor({
  template,
  onDispatch,
  textRewriteVariableSet,
}: TemplateEditorProps) {
  const { id, name, message, deliveryMethod } = template;
  const isEditingSMS = deliveryMethod === "SMS";
  const shouldShowHelpText = name === "Visit" || name === "Assessment";
  const textRef = React.createRef<InputTextRef>();

  const handleDispatch = (actionType: ActionTypes) => {
    return (newValue: string | number | boolean) => {
      onDispatch({
        type: actionType,
        id: id,
        value: newValue,
      });
    };
  };

  const handleSubjectChange = handleDispatch(ActionTypes.ChangeTemplateSubject);
  const handleMessageChange = handleDispatch(ActionTypes.ChangeTemplateMessage);
  const handleSurveyChange = handleDispatch(ActionTypes.ChangeTemplateSurvey);

  return (
    <div className="row small-up-1 medium-up-2">
      <div className="columns">
        <Content>
          <EditorsWithRef
            template={template}
            onSubjectChange={handleSubjectChange}
            onMessageChange={handleMessageChange}
            onSurveyChange={handleSurveyChange}
            ref={textRef}
            textRewriteVariableSet={textRewriteVariableSet}
          />

          <div className="row align-justify">
            <div className="shrink columns">
              <Button
                label="Reset"
                type="secondary"
                variation="destructive"
                size="small"
                onClick={handleReset}
              />
            </div>
            <div className="shrink columns">
              {isEditingSMS && <CharCount text={message.current} />}
              <Menu
                activator={
                  <Button
                    label="Insert Variable"
                    type="secondary"
                    size="small"
                  />
                }
                items={convertToMenu(template, insertVariable)}
              />
            </div>
          </div>

          {template.__typename === "MessageTemplateSurvey" && (
            <SurveyToggler
              enabled={template.surveyEnabled}
              onChange={handleDispatch(ActionTypes.ToggleTemplateSurvey)}
            />
          )}
          {isEditingSMS && shouldShowHelpText && <SMSHelpText />}
        </Content>
      </div>
      <div className="columns">
        <TemplatePreviewer template={template} />
      </div>
    </div>
  );

  function insertVariable(variable: string) {
    return () => {
      if (textRef.current) {
        textRef.current.insert(`{{${variable}}}`);
      }
    };
  }

  function handleReset() {
    assertHasTypename(template);

    if (template.__typename !== "MessageTemplateSMS") {
      handleSubjectChange(template.subject.default);
    }
    if (template.__typename === "MessageTemplateSurvey") {
      handleSurveyChange(template.survey.default);
    }
    handleMessageChange(message.default);
  }
}

// --- Editors --- //

enum AvailableEditors {
  subject,
  message,
  survey,
}

interface TemplateEditorRef {
  insert(text: string): void;
}

interface EditorsProps {
  template: TemplateEditorProps["template"];
  textRewriteVariableSet: {
    SMS?: string;
    EMAIL: string;
  };
  onSubjectChange(newValue: string | number | boolean): void;
  onMessageChange(newValue: string | number | boolean): void;
  onSurveyChange(newValue: string | number | boolean): void;
}

function convertToMenu(
  template: TemplateEditorProps["template"],
  insertVariable: (variable: string) => () => void,
) {
  return template.variables.nodes
    .filter(variableSection => variableSection.label !== "HIDDEN")
    .map(variableSection => ({
      header: variableSection.label,
      actions: variableSection.variables.nodes.map(variable => ({
        label: variable.label,
        onClick: insertVariable(variable.name),
      })),
    }));
}

function Editors(
  {
    template,
    onSubjectChange,
    onMessageChange,
    onSurveyChange,
    textRewriteVariableSet,
  }: EditorsProps,
  ref: Ref<TemplateEditorRef>,
) {
  const [activeEditor, setActiveEditor] = useState<AvailableEditors>(
    AvailableEditors.message,
  );

  const refs = {
    [AvailableEditors.subject]: React.createRef<InputTextRef>(),
    [AvailableEditors.message]: React.createRef<InputTextRef>(),
    [AvailableEditors.survey]: React.createRef<InputTextRef>(),
  };

  useImperativeHandle(ref, () => ({
    insert: (text: string) => {
      insertVariable(text);
    },
  }));

  const { message, deliveryMethod } = template;

  assertHasTypename(template);

  const variableSet =
    template.deliveryMethod === NotificationDeliveryMethod.SMS &&
    textRewriteVariableSet.SMS
      ? textRewriteVariableSet.SMS
      : textRewriteVariableSet.EMAIL;

  return (
    <>
      {template.__typename !== "MessageTemplateSMS" && (
        <InputText
          value={template.subject.current}
          onChange={handleSubjectChange}
          placeholder="Subject"
          name={`${deliveryMethod}Subject`}
          ref={refs[AvailableEditors.subject]}
          onFocus={setActiveField(AvailableEditors.subject)}
          validations={{
            required: {
              value: true,
              message: "Subject is required",
            },
          }}
        />
      )}

      {template.__typename === "MessageTemplateSMS" &&
        message.current.length > 160 && (
          <Text variation="warn">
            Phone companies split text messages that are over 160 characters
            into two messages. This can impact deliverability.
          </Text>
        )}

      <MagicInputText
        plainTemplate={{ variableSet }}
        value={message.current}
        onChange={onMessageChange}
        placeholder="Message"
        multiline={true}
        rows={12}
        name={`${deliveryMethod}Message`}
        ref={refs[AvailableEditors.message]}
        onFocus={setActiveField(AvailableEditors.message)}
      />

      {template.__typename === "MessageTemplateSurvey" &&
        template.surveyEnabled && (
          <MagicInputText
            plainTemplate={{ variableSet }}
            value={template.survey.current}
            onChange={onSurveyChange}
            placeholder="Survey"
            multiline={true}
            rows={2}
            name={`${deliveryMethod}Survey`}
            ref={refs[AvailableEditors.survey]}
            onFocus={setActiveField(AvailableEditors.survey)}
          />
        )}
    </>
  );

  function setActiveField(field: AvailableEditors) {
    return () => {
      setActiveEditor(field);
    };
  }

  function insertVariable(text: string) {
    const active = refs[activeEditor];
    if (active.current) {
      active.current.insert(text);
    }
  }

  function handleSubjectChange(newValue: string) {
    onSubjectChange(newValue);
  }
}

// --- CharCount --- //

interface CharCountProps {
  readonly text: string;
}

function CharCount({ text }: CharCountProps) {
  const count = text.length;
  const classes = classNames("u-marginRightSmaller", "u-verticalAlignMiddle", {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "u-colorYellowDark": count > 160,
  });

  return <span className={classes}>{count}</span>;
}

// --- SMSHelpText --- //

function SMSHelpText() {
  return (
    <div className="u-marginTopSmall">
      <Text>
        For more information about text message visit reminders please visit our{" "}
        <a
          href="https://help.getjobber.com/hc/en-us/articles/360033608974"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>
      </Text>
    </div>
  );
}

// --- SurveyToggler --- //

interface SurveyTogglerProps {
  enabled: boolean;
  onChange(newValue: boolean): void;
}

function SurveyToggler({ enabled, onChange }: SurveyTogglerProps) {
  return (
    <div className="row align-justify u-marginTop">
      <div className="columns">
        <Heading level={5}>Feedback Survey</Heading>
        <Text variation="subdued">
          Attach a 0-10 rating to your follow-up email
        </Text>
      </div>
      <div className="shrink columns">
        <Switch
          ariaLabel="Survey Enabled"
          value={enabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
