/* eslint-disable max-statements */
import { ApolloLink } from "@apollo/client";

declare module "@apollo/client" {
  interface DefaultContext {
    measure?: {
      name?: string;
      debug?: boolean;
    };
  }
}

interface PerformanceResult {
  name: string;
  duration: number;
}

export function performanceLink() {
  return new ApolloLink((operation, forward) => {
    // Called before operation is sent to server
    operation.setContext({ start: performance.now() });

    return forward(operation).map(data => {
      const measure = operation.getContext().measure;
      const maybeMeasurementName = measure
        ? measure.name ?? operation.operationName
        : null;

      if (maybeMeasurementName === null) return data;

      const duration = performance.now() - operation.getContext().start;
      const debugMode = measure?.debug ?? false;

      if (debugMode) {
        console.log(
          `Performance: ${maybeMeasurementName} took ${duration}ms for operation ${operation.operationName}`,
        );
      } else {
        onRequestComplete({
          name: maybeMeasurementName,
          duration,
        });
      }

      return data;
    });
  });
}

export function onRequestComplete({ name, duration }: PerformanceResult) {
  window.DD_RUM?.addTiming(name, duration);
}
