/* eslint-disable max-statements */
import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { CoachingStep, IntroStep, SetupFormStep } from "./steps";
import type { EnterpriseOnboardingFormProps } from "./EnterpriseOnboardingForm.d";
import { saveAccountDetails } from "./saveAccountDetails";
import styles from "./EnterpriseOnboarding.module.css";

enum Steps {
  Intro,
  SetupForm,
  Coaching,
}

export function EnterpriseOnboardingForm({
  distributionChannelName,
  jobberImageSrc,
  plusImageSrc,
  imageAlt,
  imageSrc,
  providedEmailAddress,
  providedUserName,
  providedUserPhone,
  providedAccountName,
  timezones,
  calendyUrl,
  userId,
  aliasUsers,
  showSupplementalTerms,
  termsOfServiceLink,
  skipCoachingStep,
}: EnterpriseOnboardingFormProps) {
  /* States */
  const [userName, setUserName] = useState(providedUserName);
  const [userPhone, setUserPhone] = useState(providedUserPhone);
  const [email, setEmail] = useState(providedEmailAddress);
  const [companyName, setCompanyName] = useState(providedAccountName);
  const [timezone, setTimezone] = useState("America/Denver");
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(Steps.Intro);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const requireSupplementalTerms = showSupplementalTerms;
  const lastStepIdx = 2;

  /* Props */
  return (
    <div
      className={
        currentStep === Steps.Coaching
          ? styles.enterpriseCoaching
          : styles.enterpriseContainer
      }
    >
      <Content spacing="large">
        <Card>
          <Content spacing="large">
            <div className={styles.enterpriseHeading}>
              <Heading level={3}>{getHeading()}</Heading>
            </div>
          </Content>
          <Content spacing="large">
            <ProgressBar currentStep={currentStep} totalSteps={lastStepIdx} />
          </Content>
          {renderStep()}
        </Card>
      </Content>
    </div>
  );

  function nextStep(): void {
    if (currentStep === Steps.Intro) {
      setCurrentStep(Steps.SetupForm);
    } else if (currentStep === Steps.SetupForm) {
      setErrorMessage("");
      setCurrentStep(Steps.Coaching);
    } else {
      setCurrentStep(Steps.Coaching);
    }
  }

  function getHeading(): string {
    switch (currentStep) {
      case Steps.Coaching:
        return "Book Coaching";
      case Steps.SetupForm:
        return "Add Account Information";
      default:
        return "Set up your account";
    }
  }

  function submitSetup(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    saveAccountDetails(
      userName,
      email,
      password,
      companyName,
      timezone,
      userId,
      userPhone,
    )
      .then(function (response) {
        if (response.success) {
          setRedirectUrl(response.redirectUrl);
          if (skipCoachingStep) {
            window.location.href = response.redirectUrl;
          } else {
            nextStep();
          }
        } else {
          setErrorMessage(response.message);
        }
      })
      .catch();
  }

  function completeForm(): void {
    window.location.href = redirectUrl;
  }

  function renderStep() {
    switch (currentStep) {
      case Steps.SetupForm: {
        return (
          <SetupFormStep
            companyName={companyName}
            email={email}
            password={password}
            timezone={timezone}
            userName={userName}
            userPhone={userPhone}
            aliasUsers={aliasUsers}
            nameOnChange={setUserName}
            phoneOnChange={setUserPhone}
            emailOnChange={setEmail}
            passwordOnChange={setPassword}
            timezoneOnChange={setTimezone}
            companyNameOnChange={setCompanyName}
            buttonClick={submitSetup}
            timezones={timezones}
            submissionErrors={errorMessage}
            showSupplementalTerms={requireSupplementalTerms}
            termsOfServiceLink={termsOfServiceLink}
            distributionChannelName={distributionChannelName}
          />
        );
      }
      case Steps.Coaching: {
        return (
          <CoachingStep
            userId={userId}
            userName={userName}
            calendyUrl={calendyUrl}
            email={email}
            buttonClick={completeForm}
          />
        );
      }
      default: {
        return (
          <IntroStep
            distributionChannelName={distributionChannelName}
            jobberImageSrc={jobberImageSrc}
            plusImageSrc={plusImageSrc}
            imageSrc={imageSrc}
            imageAlt={imageAlt}
            onClick={nextStep}
          />
        );
      }
    }
  }
}
/* eslint-enable max-statements */
