import {
  Card,
  Content,
  Flex,
  Glimmer,
  Heading,
  Icon,
  Text,
} from "@jobber/components";
import { InsightWidgetsLoader } from "~/jobber/insightWidgets/InsightWidgetsLoader";
import {
  AiAssistantInsightWidgetCategory,
  Industry,
} from "~/utilities/API/graphql";
import { INDUSTRY_AVERAGES } from "~/jobber/marketingSuiteExpansion/ReviewsEngagement/IndustryAverages";
import { INDUSTRY_LEADERS } from "~/jobber/marketingSuiteExpansion/ReviewsEngagement/IndustryLeaders";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import styles from "./ReviewsEngagement.module.css";

interface ReviewsEngagementProps {
  accountNumReviews: number;
  industry: Industry;
  isLoading: boolean;
}
function ReviewsEngagement({
  accountNumReviews,
  industry,
  isLoading,
}: ReviewsEngagementProps) {
  const reviewsComparisonEnabled = useFeatureFlag(
    SplitNames.ReviewsIndustryComparison,
  );

  if (isLoading) return <Glimmer />;
  if (!reviewsComparisonEnabled) return <></>;

  const industryAvgNumReviews =
    INDUSTRY_AVERAGES[industry].numReviews ||
    INDUSTRY_AVERAGES.OTHER.numReviews;
  const industryLeaderNumReviews =
    INDUSTRY_LEADERS[industry].numReviews || INDUSTRY_LEADERS.OTHER.numReviews;

  return (
    <div className={styles.cardOverride}>
      <Card>
        <Content>
          <Flex direction="row" template={["grow", "shrink"]}>
            <Flex
              direction="column"
              template={["grow", "grow"]}
              gap="small"
              align="center"
            >
              <Heading level={4}>
                You have{" "}
                {renderComparison(accountNumReviews, industryAvgNumReviews)}{" "}
                reviews than the{" "}
                {industry === Industry.OTHER
                  ? "industry average"
                  : `${INDUSTRY_AVERAGES[industry].name} industry average`}
              </Heading>
              <Text size="small" variation="subdued">
                {renderComparison(accountNumReviews, industryLeaderNumReviews)}{" "}
                than industry leaders, Google shows businesses with a large
                number of reviews higher in search results
              </Text>
            </Flex>
            <div className={styles.widget}>
              <InsightWidgetsLoader
                categories={[AiAssistantInsightWidgetCategory.MARKETING]}
                insideReviewsEngagement
              ></InsightWidgetsLoader>
            </div>
          </Flex>
        </Content>
      </Card>
    </div>
  );
}

const SplitWrappedComponent = withSplitClient(ReviewsEngagement);
export { SplitWrappedComponent as ReviewsEngagement };

const renderComparison = (
  accountNumReviews: number,
  industryNumReviews: number,
) => {
  if (accountNumReviews === industryNumReviews) {
    return <>the same number of</>;
  } else if (accountNumReviews > industryNumReviews) {
    return (
      <>
        <Icon name="longArrowUp" color="green" size="small" />
        {accountNumReviews - industryNumReviews} more
      </>
    );
  } else {
    return (
      <>
        <Icon name="longArrowDown" color="red" size="small" />
        {industryNumReviews - accountNumReviews} less
      </>
    );
  }
};
