import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Modal } from "@jobber/components/Modal";
import { useIntl } from "react-intl";
import type { JobDetailsFragment, User } from "~/utilities/API/graphql";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/components/TimesheetModal/messages";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import { CreateTimesheetModal } from "./TimesheetModal";
import styles from "../LabourTable.module.css";

interface LabourTableTitleProps {
  job: JobDetailsFragment;
  user?: User;
}

export function LabourTableTitle({ job, user }: LabourTableTitleProps) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <tr>
      <th colSpan={100}>
        <div className={styles.title}>
          <Heading level={2}>{formatMessage(jobCostMessages.labour)}</Heading>
          <Button
            label={formatMessage(messages.createTimesheetModalButtonLabel)}
            ariaLabel={formatMessage(messages.createTimesheetModalButtonLabel)}
            type="secondary"
            size="small"
            onClick={() => setModalOpen(true)}
          />
        </div>
        <Modal
          open={modalOpen}
          onRequestClose={closeModal}
          title={formatMessage(messages.createTimesheetModalTitle)}
        >
          <CreateTimesheetModal
            jobId={job.id}
            user={user}
            closeModal={closeModal}
          />
        </Modal>
      </th>
    </tr>
  );

  function closeModal() {
    setModalOpen(false);
  }
}
