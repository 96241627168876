import {
  CampaignTemplateCtaLinkTo,
  type Template,
  type TemplateVariablesFragment,
} from "~/utilities/API/graphql";
import type { CampaignContent } from "./CampaignWizardProvider";
import { DEFAULT_CTA_BUTTON_COLOR } from "./constants";
import type { HeaderImageFile } from "../../types";

type CampaignContentReducerActions =
  | { type: "setSubject"; payload: { value: string; isDirty: boolean } }
  | { type: "setHeader"; payload: { value: string; isDirty: boolean } }
  | { type: "setBody"; payload: { value: string; isDirty: boolean } }
  | { type: "setShowLogo"; payload: { value: boolean; isDirty: boolean } }
  | { type: "setIsDirty"; payload: boolean }
  | { type: "setCampaignId"; payload: string }
  | {
      type: "setCtaButtonEnabled";
      payload: { value: boolean; isDirty: boolean };
    }
  | {
      type: "setLinkTo";
      payload: { value: CampaignTemplateCtaLinkTo; isDirty: boolean };
    }
  | { type: "setLinkValue"; payload: { value: string; isDirty: boolean } }
  | { type: "setButtonText"; payload: { value: string; isDirty: boolean } }
  | { type: "setButtonColor"; payload: { value: string; isDirty: boolean } }
  | { type: "resetCampaignTemplateContent" }
  | { type: "setTemplateType"; payload: Template }
  | {
      type: "setHeaderImage";
      payload: { value?: HeaderImageFile; isDirty: boolean };
    }
  | { type: "setImageIds"; payload: string[] }
  | { type: "setReferralLink"; payload: string }
  | { type: "setVariables"; payload: TemplateVariablesFragment[] };

export function campaignContentReducer(
  campaignContent: CampaignContent,
  action: CampaignContentReducerActions,
) {
  switch (action.type) {
    case "setSubject":
      return {
        ...campaignContent,
        subject: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setHeader":
      return {
        ...campaignContent,
        header: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setBody":
      return {
        ...campaignContent,
        body: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setShowLogo":
      return {
        ...campaignContent,
        showLogo: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setIsDirty": {
      return { ...campaignContent, isDirty: action.payload };
    }
    case "resetCampaignTemplateContent":
      return {
        subject: "",
        header: "",
        body: "",
        showLogo: true,
        isDirty: false,
        campaignId: "",
        ctaButtonEnabled: true,
        linkTo: CampaignTemplateCtaLinkTo.REQUEST_FORM,
        linkValue: undefined,
        buttonText: "",
        buttonColor: DEFAULT_CTA_BUTTON_COLOR,
        templateType: campaignContent.templateType,
        headerImage: undefined,
        imageIds: [],
        referralLink: undefined,
        variables: [],
      };
    case "setCampaignId":
      return {
        ...campaignContent,
        campaignId: action.payload,
      };
    case "setCtaButtonEnabled":
      return {
        ...campaignContent,
        ctaButtonEnabled: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setLinkTo":
      return {
        ...campaignContent,
        linkTo: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setLinkValue":
      return {
        ...campaignContent,
        linkValue: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setButtonText":
      return {
        ...campaignContent,
        buttonText: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setButtonColor":
      return {
        ...campaignContent,
        buttonColor: action.payload.value,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setTemplateType":
      return {
        ...campaignContent,
        templateType: action.payload,
      };
    case "setHeaderImage":
      return {
        ...campaignContent,
        headerImage: action.payload.value
          ? {
              contentType: action.payload.value.contentType,
              fileName: action.payload.value.fileName,
              fileSize: action.payload.value.fileSize,
              thumbnailUrl: action.payload.value.thumbnailUrl,
              url: action.payload.value.url,
              uploadUrl: action.payload.value.uploadUrl,
            }
          : undefined,
        isDirty: campaignContent.isDirty || action.payload.isDirty,
      };
    case "setImageIds":
      return {
        ...campaignContent,
        imageIds: action.payload,
      };
    case "setReferralLink":
      return {
        ...campaignContent,
        referralLink: action.payload,
      };
    case "setVariables":
      return {
        ...campaignContent,
        variables: action.payload,
      };
    default:
      return campaignContent;
  }
}
