import React from "react";
import { Button } from "@jobber/components/Button";
import { useGlobalMessageDismissMutation } from "jobber/features/GlobalBanner/hooks";

interface GlobalBannerDismissButtonProps {
  readonly id: string;
}

export function GlobalBannerDismissButton({
  id,
}: GlobalBannerDismissButtonProps) {
  const { loading, handleGlobalMessageDismiss } =
    useGlobalMessageDismissMutation();

  return (
    <Button
      label="Dismiss"
      variation="subtle"
      type="tertiary"
      size="small"
      loading={loading}
      onClick={handleClick}
    />
  );

  async function handleClick() {
    await handleGlobalMessageDismiss(id);
  }
}
