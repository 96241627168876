export enum VerificationProgress {
  Default = "reset",
  Loading = "loading",
  InvalidPhone = "invalid_phone",
  ValidPhone = "valid_phone",
}

export interface VerifyState {
  loading: boolean;
  invalid: boolean;
}

const INIT_STATE: VerifyState = {
  loading: false,
  invalid: false,
};

export function init(intialProgress: VerificationProgress): VerifyState {
  return verifyOTPPhoneReducer(INIT_STATE, intialProgress);
}

export function verifyOTPPhoneReducer(
  prevState: VerifyState,
  progress: VerificationProgress,
): VerifyState {
  switch (progress) {
    case VerificationProgress.Default:
      return {
        loading: false,
        invalid: false,
      };
    case VerificationProgress.Loading:
      return {
        loading: true,
        invalid: false,
      };
    case VerificationProgress.InvalidPhone:
      return {
        loading: false,
        invalid: true,
      };
    case VerificationProgress.ValidPhone:
      return {
        loading: false,
        invalid: false,
      };
    default:
      return prevState;
  }
}
