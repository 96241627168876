import { defineMessages } from "react-intl";

export const messages = defineMessages({
  referralLinkWithIncentiveTitle: {
    id: "campaigns.campaignReviewPage.referralLink.withIncentiveTitle",
    defaultMessage: "Share and save {incentiveAmount}!",
    description: "Referral Link Title",
  },
  referralLinkShareMessage: {
    id: "campaigns.campaignReviewPage.referralLink.shareMessage",
    defaultMessage: "Copy this link and send it to a friend!",
    description: "Referral Link Title",
  },
  referralLinkNoIncentiveTitle: {
    id: "campaigns.campaignReviewPage.referralLink.noIncentiveTitle",
    defaultMessage: "Enjoy our work?",
    description: "Referral Link Title",
  },
});
