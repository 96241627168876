import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createErrorMessage: {
    id: "campaigns.rulebuilder.createErrorMessage",
    defaultMessage: "There was an error creating the automation rule",
    description: "Error message for creating an automation rule",
  },
  editErrorMessage: {
    id: "campaigns.rulebuilder.editErrorMessage",
    defaultMessage: "There was an error editing the automation rule",
    description: "Error message for editing an automation rule",
  },
  tagErrorMessage: {
    id: "campaigns.rulebuilder.tagErrorMessage",
    defaultMessage: "Select a tag",
    description: "Error message for selecting a tag",
  },
  lineItemErrorMessage: {
    id: "campaigns.rulebuilder.lineItemErrorMessage",
    defaultMessage: "Select a line item",
    description: "Error message for selecting a line item",
  },
});
