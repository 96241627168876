import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientSegmentEditSideDrawerTitle: {
    id: "campaigns.clientSegmentPage.clientSegmentEditSideDrawerTitle",
    defaultMessage: "Edit client segment",
    description: "Title of edit client segment side drawer",
  },
  clientSegmentEditSideDrawerUpdate: {
    id: "campaigns.clientSegmentPage.clientSegmentEditSideDrawerUpdate",
    defaultMessage: "Update Client Segment",
    description: "Button to update client segment criteria",
  },
  pastClientsDescription3: {
    id: "campaigns.clientSegmentPage.pastClientsDescription3",
    defaultMessage:
      "meet this criteria. Your clients will be added to this segment automatically when they meet this criteria.",
    description: "Text fragment explaining the time interval inputs",
  },
  refreshErrorMessage: {
    id: "campaigns.clientSegmentPage.refreshErrorMessage",
    defaultMessage: "There was an error refreshing the client segment.",
    description: "Error message when refreshing the client segment",
  },
  saveErrorMessage: {
    id: "campaigns.clientSegmentPage.saveErrorMessage",
    defaultMessage: "There was an error saving the client segment.",
    description: "Error message when saving the client segment fails",
  },
  pastClientsDescription1: {
    id: "campaigns.clientSegmentPage.pastClientsDescription1",
    defaultMessage: "Last job was completed more than",
    description: "Text fragment explaining the time interval inputs",
  },
  pastClientsDescription2: {
    id: "campaigns.clientSegmentPage.pastClientsDescription2",
    defaultMessage: "and has no upcoming jobs scheduled",
    description: "Text fragment explaining the time interval inputs",
  },
  yearsAgoLabel: {
    id: "campaigns.clientSegmentPage.pastClientsYearsAgoLabel",
    defaultMessage: "Year(s) ago",
    description: "Label for the Years ago input",
  },
  monthsAgoLabel: {
    id: "campaigns.clientSegmentPage.monthsAgoLabel",
    defaultMessage: "Month(s) ago",
    description: "Label for the Months ago input",
  },
  weeksAgoLabel: {
    id: "campaigns.clientSegmentPage.weeksAgoLabel",
    defaultMessage: "Week(s) ago",
    description: "Label for the Weeks ago input",
  },
  daysAgoLabel: {
    id: "campaigns.clientSegmentPage.daysAgoLabel",
    defaultMessage: "Day(s) ago",
    description: "Label for the Days ago input",
  },
});
