import React from "react";
import classNames from "classnames";
import { Text } from "@jobber/components/Text";
import styles from "./StopState.module.css";

export function StopState() {
  return (
    <div className={classNames(styles.stopState)}>
      <Text>This number does not want to receive text messages.</Text>
    </div>
  );
}
