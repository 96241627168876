import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import React from "react";
import { Button } from "@jobber/components/Button";
import { Divider } from "@jobber/components/Divider";
import { useMutation } from "@apollo/client";
import QuickbooksIcon from "@images/integrations/icons/quickbooks.png";
import DocuSignIcon from "@images/integrations/icons/docusign.png";
import CompanyCamIcon from "@images/integrations/icons/companycam.png";
import MailchimpIcon from "@images/integrations/icons/mailchimp.png";
import ZapierIcon from "@images/integrations/icons/zapier.png";
import GoogleIcon from "@images/integrations/icons/google.png";
import { CTA_DISMISS } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/marketplace/ANALYTICS_EVENTS";
import { AppItem } from "./AppItem";
import styles from "./AppMarketplaceCard.module.css";

interface AppMarketplaceCardProps {
  country: string;
  experimentName: string;
  experimentCTA: string;
  onDismiss: () => void;
}

export function AppMarketplaceCard({
  country,
  experimentName,
  experimentCTA,
  onDismiss,
}: AppMarketplaceCardProps) {
  const [ctaDismiss] = useMutation(CTA_DISMISS);

  function dismissAppMarketplaceCard() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.ctaDismissed, {
      name: experimentName,
      source: "home",
    });

    onDismiss();
    ctaDismiss({
      variables: { id: experimentCTA },
    }).catch((ctaDismissError: string) => {
      throw new Error(ctaDismissError);
    });
  }

  function handleItemClick(appId: string, title: string) {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedIntegrationCard, {
      appId: appId,
      name: title,
      source: experimentName,
    });

    window.location.href = `/marketplace?nav_source=integrate_your_tools_card&app_id=${appId}#app-id-${appId}`;
  }

  function handleButtonClick() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedAppMarketplace, {
      source: experimentName,
    });

    window.location.href = `/marketplace?nav_source=integrate_your_tools_card`;
  }

  return (
    <div className={styles.cardContent} data-testid="AppMarketplaceCard">
      <Card
        header={{
          title: "Integrate your tools",
          action: (
            <Button
              ariaLabel="Close Integrate your tools"
              onClick={() => dismissAppMarketplaceCard()}
              icon="cross"
              type="tertiary"
              size="base"
              variation="subtle"
            />
          ),
        }}
      >
        <Content>
          <div className={styles.appItems}>
            <AppItem
              image={QuickbooksIcon}
              title="QuickBooks Online"
              description="Sync accounting data"
              onClick={() => handleItemClick("5585", "QuickBooks Online")}
            />
            <Divider direction={"horizontal"} />
            <AppItem
              image={DocuSignIcon}
              title="DocuSign"
              description="Collect signatures in minutes"
              onClick={() => handleItemClick("12581", "DocuSign")}
            />
            <Divider direction={"horizontal"} />
            <AppItem
              image={CompanyCamIcon}
              title="CompanyCam"
              description="Sync photos from your projects"
              onClick={() => handleItemClick("41", "CompanyCam")}
            />
            <Divider direction={"horizontal"} />
            <AppItem
              image={MailchimpIcon}
              title="Mailchimp"
              description="Email marketing campaigns"
              onClick={() => handleItemClick("2421", "Mailchimp")}
            />
            {country === "Canada" && (
              <>
                <Divider direction={"horizontal"} />
                <AppItem
                  image={ZapierIcon}
                  title="Zapier"
                  description="Connect with thousands of tools"
                  onClick={() => handleItemClick("2434", "Zapier")}
                />
              </>
            )}
            {country === "United States" && (
              <>
                <Divider direction={"horizontal"} />
                <AppItem
                  image={GoogleIcon}
                  title="Google's Local Services Ads"
                  description="Bookings right from Google search"
                  onClick={() =>
                    handleItemClick("2420", "Google's Local Services Ads")
                  }
                />
              </>
            )}
          </div>
          <div className={styles.footer}>
            <Button
              type="secondary"
              icon="apps"
              label="Explore All Apps"
              onClick={() => handleButtonClick()}
              ariaLabel="Explore All Apps"
            />
          </div>
        </Content>
      </Card>
    </div>
  );
}
