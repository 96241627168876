import { Menu } from "@jobber/components";
import React from "react";
import { RuleButton } from "./RuleButton";
import styles from "./RuleDropdown.module.css";

export interface RuleDropdownProps {
  value: string | number | boolean | undefined;
  setValue?: (val: string | number | boolean) => void;
  options: {
    label: string;
    value: string | number | boolean;
    icon?: string;
  }[];
  readOnly?: boolean;
}

function getButtonLabel(
  value: string | number | boolean | undefined,
  options: RuleDropdownProps["options"],
) {
  return options.find(option => option.value === value)?.label ?? "";
}

export function RuleDropdown({
  value,
  setValue,
  options,
  readOnly,
}: RuleDropdownProps) {
  if (readOnly || !setValue) {
    return <>{getButtonLabel(value, options)}</>;
  }

  return (
    <div className={styles.menuContainer}>
      <Menu
        items={[
          {
            actions: options.map(option => {
              return {
                label: option.label,
                onClick: () => setValue(option.value),
              };
            }),
          },
        ]}
        activator={<RuleButton label={getButtonLabel(value, options)} />}
      />
    </div>
  );
}
