import React from "react";
import { useIntl } from "react-intl";
import { parseISO } from "date-fns";
import { Emphasis, Text, strFormatDate } from "@jobber/components";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import styles from "../../UpcomingPayouts.module.css";
import { messages } from "../../messages";

interface UpcomingPayoutProps {
  expectedPayout: {
    arrivalDate: string;
    id: string;
    netAmount: number;
  };
}

export const UpcomingPayout = ({ expectedPayout }: UpcomingPayoutProps) => {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();

  return (
    <Text size="small" key={expectedPayout.id}>
      <Emphasis variation="bold">
        {currencyFormatter(expectedPayout.netAmount / 100)}
      </Emphasis>
      <span className={styles.expectedText}>
        {formatMessage(messages.expectedOn)}
        {strFormatDate(parseISO(expectedPayout.arrivalDate))}
      </span>
    </Text>
  );
};
