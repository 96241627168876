import { defineMessages } from "react-intl";

export const messages = defineMessages({
  scheduleSendModalTitle: {
    id: "campaigns.ScheduleSendModal.title",
    defaultMessage: "Schedule campaign",
    description: "Schedule Campaign Modal Title",
  },
  submitButton: {
    id: "campaigns.ScheduleSendModal.submitButton",
    defaultMessage: "Schedule Campaign",
    description: "Schedule Campaign Modal Submit Button",
  },
  sendAtDatePlaceholder: {
    id: "campaigns.ScheduleSendModal.sendAtDatePlaceholder",
    defaultMessage: "Date",
    description: "Schedule Campaign Modal Date Placeholder",
  },
  sendAtTimePlaceholder: {
    id: "campaigns.ScheduleSendModal.sendAtTimePlaceholder",
    defaultMessage: "Time",
    description: "Schedule Campaign Modal Time Placeholder",
  },
  timeZone: {
    id: "campaigns.ScheduleSendModal.timeZone",
    defaultMessage:
      "Time Zone: {offset} {IAnaTimeZoneName} ({timeZoneAbbreviation})",
    description: "Schedule Campaign Modal Time Zone Label",
  },
});
