import React from "react";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";

export function HiddenRecurlyFormInputs({
  address1,
  address2,
  city,
  state,
  zip,
  country,
}: Partial<BillingAddressType>) {
  return (
    <>
      <input
        data-recurly={"address1"}
        value={address1}
        hidden={true}
        readOnly={true}
        data-testid={"address1_recurly"}
      />
      <input
        data-recurly={"address2"}
        value={address2}
        hidden={true}
        readOnly={true}
        data-testid={"address2_recurly"}
      />
      <input
        data-recurly={"country"}
        value={country}
        hidden={true}
        readOnly={true}
        data-testid={"country_recurly"}
      />
      <input
        data-recurly={"city"}
        value={city}
        hidden={true}
        readOnly={true}
        data-testid={"city_recurly"}
      />
      <input
        data-recurly={"state"}
        value={state}
        hidden={true}
        readOnly={true}
        data-testid={"state_recurly"}
      />
      <input
        data-recurly={"postal_code"}
        value={zip}
        hidden={true}
        readOnly={true}
        data-testid={"postal_code_recurly"}
      />
    </>
  );
}
