import React from "react";
import type { ManualPagination } from "@jobber/components/DataTable";
import type { ReportProps } from "~/jobber/features/Reporting/components/Report/types";
import { Report } from "~/jobber/features/Reporting/components/Report/Report";
import { REPORT_LABELS } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/constants";
import { useQuoteDetailColumns } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/hooks/useQuoteDetailColumns";
import type { QuoteDetailData, QuoteDetailTotalsData } from "./types";

interface QuoteDetailReportProps extends ReportProps {
  data: QuoteDetailData[] | undefined;
  totalsData: QuoteDetailTotalsData | undefined;
  paginationProps: ManualPagination;
}

export function QuoteDetailReport({
  data,
  totalsData,
  loading,
  error,
  filterProps,
  sortingProps,
  exportProps,
  paginationProps,
}: QuoteDetailReportProps) {
  const quoteDetailColumns = useQuoteDetailColumns(totalsData);

  return (
    <Report
      data={data}
      // @ts-ignore TODO: https://jobber.atlassian.net/browse/JOB-68172.
      columns={quoteDetailColumns}
      title={REPORT_LABELS.reportTitle}
      loading={loading}
      error={error}
      pagination={paginationProps}
      filterProps={filterProps}
      exportProps={exportProps}
      sorting={sortingProps}
    />
  );
}
