import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  InvoiceSummaryFilterInput,
  InvoiceSummaryTotalsQuery,
  InvoiceSummaryTotalsQueryVariables,
} from "~/utilities/API/graphqlFranchiseManagement";
import { INVOICE_SUMMARY_TOTALS_QUERY } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/InvoiceSummaryReport.graphql";

interface InvoiceSummaryTotalsReportDataProps {
  data?: InvoiceSummaryTotalsQuery;
  loading: boolean;
  error?: ApolloError;
  fetchInvoiceSummaryTotals(filter: InvoiceSummaryFilterInput): void;
}

export function useInvoiceSummaryTotalsData(): InvoiceSummaryTotalsReportDataProps {
  const [fetchInvoiceSummaryTotals, { data, loading, error }] = useLazyQuery<
    InvoiceSummaryTotalsQuery,
    InvoiceSummaryTotalsQueryVariables
  >(INVOICE_SUMMARY_TOTALS_QUERY);

  const _fetchInvoiceSummaryTotals = (filter: InvoiceSummaryFilterInput) =>
    fetchInvoiceSummaryTotals({
      variables: {
        filter,
      },
    });

  return {
    fetchInvoiceSummaryTotals: _fetchInvoiceSummaryTotals,
    data,
    loading,
    error,
  };
}
