import { defineMessages } from "react-intl";

export const messages = defineMessages({
  mainHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.MainHeading",
    defaultMessage: "Scheduling software built for the field",
    description: "Main heading for schedule landing page",
  },
  subHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.SubHeading",
    defaultMessage:
      "Schedule field service jobs faster, avoid booking mistakes, and route visits efficiently - all while keeping you and your customers on the same page.",
    description: "Sub heading for schedule landing page",
  },
  firstImageAlt: {
    id: "ScheduleLandingPage.ScheduleLandingPage.FirstImageAlt",
    defaultMessage:
      "A calendar view of an example schedule on the Jobber app in desktop and mobile view",
    description: "First image alt text for schedule landing page",
  },
  secondImageAlt: {
    id: "ScheduleLandingPage.ScheduleLandingPage.SecondImageAlt",
    defaultMessage:
      "List of team members selected for a job and calendar view of schedule on mobile device",
    description: "Second image alt text for schedule landing page",
  },
  calendarSubFeatureHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.CalendarSubFeatureHeading",
    defaultMessage: "Make your calendar work for you",
    description: "Heading for calendar sub feature",
  },
  calendarSubFeatureDescription: {
    id: "ScheduleLandingPage.ScheduleLandingPage.CalendarSubFeatureDescription",
    defaultMessage:
      "Choose from five different views, personalize your calendar with color coding and filters, and easily reschedule jobs by clicking, dragging, and dropping.",
    description: "Description for calendar sub feature",
  },
  organizeSubFeatureHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.OrganizeSubFeatureHeading",
    defaultMessage: "Organize your day",
    description: "Heading for organize sub feature",
  },
  organizeSubFeatureDescription: {
    id: "ScheduleLandingPage.ScheduleLandingPage.OrganizeSubFeatureDescription",
    defaultMessage:
      "In addition to job visits, you can track in-house tasks, events, and reminders and keep your team organized.",
    description: "Description for organize sub feature",
  },
  commuteTimeSubFeatureHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.CommuteTimeSubFeatureHeading",
    defaultMessage: "Cut commute time to fit more work in the day",
    description: "Heading for commute time sub feature",
  },
  commuteTimeSubFeatureDescription: {
    id: "ScheduleLandingPage.ScheduleLandingPage.CommuteTimeSubFeatureDescription",
    defaultMessage:
      "See your visits on a map and automatically generate the fastest, most fuel-efficient route.",
    description: "Description for commute time sub feature",
  },
  featuresSectionHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.FeaturesSectionHeading",
    defaultMessage: "Learn about other {plan} features",
    description: "Heading for features section",
  },
  requestFormsFeatureImageAlt: {
    id: "ScheduleLandingPage.ScheduleLandingPage.RequestFormsFeatureImageAlt",
    defaultMessage: "Requests feature page on Jobber",
    description: "Image alt text for request forms feature",
  },
  jobsFeatureImageAlt: {
    id: "ScheduleLandingPage.ScheduleLandingPage.JobsFeatureImageAlt",
    defaultMessage:
      "Job Checklist feature shown on Jobber app in mobile web view beside an example job order.",
    description: "Image alt text for jobs feature",
  },
  requestFormsFeatureHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.RequestFormsFeatureHeading",
    defaultMessage: "Win more work with request forms",
    description: "Heading for request forms feature",
  },
  requestFormsFeatureDescription: {
    id: "ScheduleLandingPage.ScheduleLandingPage.RequestFormsFeatureDescription",
    defaultMessage:
      "When a client submits a new work request, Jobber sends you an email, activity feed notification and a push notification.",
    description: "Description for request forms feature",
  },
  jobsFeatureHeading: {
    id: "ScheduleLandingPage.ScheduleLandingPage.JobsFeatureHeading",
    defaultMessage: "Keep track of important job details in one place",
    description: "Heading for jobs feature",
  },
  jobsFeatureDescription: {
    id: "ScheduleLandingPage.ScheduleLandingPage.JobsFeatureDescription",
    defaultMessage:
      "Move work forward by quickly turning a work request or quote into a job, or book a new job on the fly.",
    description: "Description for jobs feature",
  },
});
