import React from "react";
import { showToast } from "@jobber/components/Toast";
import { APIProvider } from "~/utilities/API/APIProvider";
import { OTPInputModal } from "./components/OTPInputModal";

export interface OTPModalRailsProps {
  readonly phoneLastDigits: string;
  readonly backRoute: string;
  readonly forwardRoute: string;
  readonly channel: string;
  readonly verified: boolean;
}

export function OTPModalRails({
  phoneLastDigits,
  backRoute,
  forwardRoute,
  channel,
  verified,
}: OTPModalRailsProps) {
  return (
    <APIProvider>
      <OTPInputModal
        open={true}
        onRequestClose={goBack}
        phoneLastDigits={phoneLastDigits}
        onSuccess={goForwards}
        channel={channel}
      />
    </APIProvider>
  );

  function goForwards() {
    if (!verified) {
      // show toast if first time setup
      showToast({
        message: "Two-step verification is on.",
      });
    }
    window.location.assign(forwardRoute);
  }

  function goBack() {
    window.location.assign(backRoute);
  }
}
