/* eslint-disable import/no-internal-modules */
import React from "react";
import type { Configuration } from "jobber/settings/selfServeBookings/types";
import { AvailabilityCard } from "./components/AvailabilityCard/AvailabilityCard";
import { OfferingsSettingsPageLoader } from "../OfferingsSettingsPage/OfferingsSettingsPage.loader";

export interface SettingsPageProps {
  configuration: Configuration;
}

export function SettingsPage({ configuration }: SettingsPageProps) {
  return (
    <>
      <OfferingsSettingsPageLoader />
      <AvailabilityCard configuration={configuration} />
    </>
  );
}
