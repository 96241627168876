import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "campaigns.automated.pauseAndEdit.title",
    defaultMessage: "Pause and edit?",
    description: "Pause and Edit modal title label",
  },
  text: {
    id: "campaigns.automated.pauseAndEdit.text",
    defaultMessage: `Editing this campaign will deactivate the campaign temporarily until you choose to reactivate it.`,
    description: "Pause and Edit message label",
  },
  buttonLabel: {
    id: "campaigns.automated.pauseAndEdit.buttonLabel",
    defaultMessage: "Pause and Edit",
    description: "Confirm button label",
  },
  error: {
    id: "campaigns.automated.pauseAndEdit.error",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error message",
  },
});
