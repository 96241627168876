import React from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputNumber } from "@jobber/components/InputNumber";
import { InputGroup } from "@jobber/components/InputGroup";
import { useIntl } from "react-intl";
import type { QuantityRange } from "jobber/workItems/types";
import styles from "./styles.module.css";
import { messages } from "./messages";

export interface ServiceQuantitiesProps {
  quantityRange?: QuantityRange;
  onChange(newValue: QuantityRange | undefined): void;
}

const defaultRange: Omit<QuantityRange, "quantityEnabled"> = {
  minQuantity: 1,
  maxQuantity: 10,
};

export function ServiceQuantities({
  quantityRange,
  onChange,
}: ServiceQuantitiesProps) {
  const { formatMessage } = useIntl();
  const changeState = (newValue: boolean) => {
    onChange({
      minQuantity: quantityRange?.minQuantity ?? defaultRange.minQuantity,
      maxQuantity: quantityRange?.maxQuantity ?? defaultRange.maxQuantity,
      quantityEnabled: newValue,
    });
  };

  if (!quantityRange || !quantityRange.quantityEnabled) {
    return (
      <Checkbox
        checked={false}
        label={formatMessage(messages.allowSelectQuantity)}
        description={formatMessage(messages.quantityCheckboxDescription)}
        onChange={changeState}
      />
    );
  }

  const changeMinimum = (newValue: number) => {
    onChange({
      ...quantityRange,
      minQuantity: greaterThanZero(newValue) as number,
    });
  };

  const changeMaximum = (newValue: number) => {
    onChange({
      ...quantityRange,
      maxQuantity: greaterThanZero(newValue) as number,
    });
  };

  return (
    <>
      <Checkbox
        checked={true}
        label={formatMessage(messages.allowSelectQuantity)}
        description={formatMessage(messages.quantityCheckboxDescription)}
        onChange={changeState}
      />
      <InputGroup flowDirection="horizontal">
        <div className={styles.inputWrapper}>
          <InputNumber
            placeholder={formatMessage(messages.minimumQuantity)}
            value={quantityRange.minQuantity}
            validations={{
              required: formatMessage(messages.minimumQuantityRequired),
              validate: newMinQuantity => {
                if (
                  quantityRange.maxQuantity &&
                  newMinQuantity >= quantityRange.maxQuantity
                ) {
                  return formatMessage(
                    messages.minimumQuantityLessThanMaximumQuantity,
                  );
                }
                return true;
              },
            }}
            onChange={changeMinimum}
          />
        </div>

        <div className={styles.inputWrapper}>
          <InputNumber
            placeholder={formatMessage(messages.maximumQuantity)}
            value={quantityRange.maxQuantity}
            validations={{
              required: formatMessage(messages.maximumQuantityRequired),
              validate: newMaxQuantity => {
                if (
                  quantityRange.minQuantity &&
                  newMaxQuantity <= quantityRange.minQuantity
                ) {
                  return formatMessage(
                    messages.maximumQuantityLessThanMinimumQuantity,
                  );
                }
                return true;
              },
            }}
            onChange={changeMaximum}
          />
        </div>
      </InputGroup>
    </>
  );
}

function greaterThanZero(val: number | "") {
  return val === "" || val > 0 ? val : 1;
}
