import { defineMessages } from "react-intl";

export const messages = defineMessages({
  jobberMoneyPayoutsDescription: {
    id: "managedAccount.depositedPayouts.jobberMoneyPayoutsDescription",
    defaultMessage: "Payouts are being deposited in less than an hour",
    description: "Description for Jobber Money payouts",
  },
  jobberMoneyPayoutsLastPayout: {
    id: "managedAccount.depositedPayouts.jobberMoneyPayoutsLastPayout",
    defaultMessage: "Last payout",
    description: "title for last payout",
  },
  jobberMoneyPayoutsAvailableBalance: {
    id: "managedAccount.depositedPayouts.jobberMoneyPayoutsAvailableBalance",
    defaultMessage: "Jobber Money balance",
    description: "title for available balance",
  },
  jobberMoneyPayoutsLastPayoutTooltip: {
    id: "managedAccount.depositedPayouts.jobberMoneyPayoutsLastPayoutTooltip",
    defaultMessage:
      "You're receiving automatic hourly payouts to your Jobber Money account for free. You can spend your funds right away using your Jobber Money card.",
    description: "Tooltip for jobber money payouts",
  },
  jobberMoneyPayoutsReportLink: {
    id: "managedAccount.depositedPayouts.jobberMoneyPayoutsReportLink",
    defaultMessage: "View report",
    description: "Link to view jobber money report",
  },
});
