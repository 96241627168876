import { gql } from "@apollo/client";

export const ROYALTY_QUERY = gql`
  query Royalty(
    $sort: RoyaltySortInput
    $filter: RoyaltyFilterAttributes
    $first: Int
    $cursor: String
  ) {
    royalties(sort: $sort, filter: $filter, cursor: $cursor, first: $first) {
      reportPageInfo {
        startCursor
        nextCursor
      }
      totalCount
      records {
        total
        royaltyRate
        royaltyAmount
        name
      }
    }
  }
`;

export const ROYALTY_TOTALS_QUERY = gql`
  query RoyaltyTotals($filter: RoyaltyFilterAttributes) {
    royaltyTotals(filter: $filter) {
      paymentsTotal
      royaltyAmountsTotal
    }
  }
`;
