import React from "react";
import { Drawer } from "@jobber/components/Drawer";
import { type ApolloError, useQuery } from "@apollo/client";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { QBOTurnOnSyncInsight } from "jobber/dashboard/components/QBOTurnOnSyncInsight";
import { DoubleBookingInsight } from "jobber/dashboard/components/DoubleBookingInsight";
import type {
  ClientReengagement,
  DoubleBookings,
  ExpiringJobsDataFragment,
  OverdueItems,
  QboLastSync,
  RevenueCalculation,
  TopClientsBalance,
} from "~/utilities/API/graphql";
import { ProjectedRevenueInsight } from "jobber/dashboard/components/ProjectedRevenueInsight";
import { ScheduledItemsOverdueInsight } from "jobber/dashboard/components/ScheduledItemsOverdueInsight";
import { ExpiringJobsInsight } from "jobber/dashboard/components/ExpiringJobsInsight";
import { TopClientsBalanceInsight } from "jobber/dashboard/components/TopClientsBalanceInsight";
import { QBOLastSyncInsight } from "jobber/dashboard/components/QBOLastSyncInsight";
import { ClientReengagementInsight } from "jobber/dashboard/components/ClientReengagementInsight";
import { CustomizeBrandingInsight } from "jobber/dashboard/components/CustomizeBrandingInsight";
import { useCta } from "~/utilities/contexts/internal/useCtas";
import { INSIGHTS_CARDS_QUERY } from "./InsightsDrawer.graphql";
import style from "./InsightsDrawer.module.css";
import { messages } from "./messages";

interface InsightsDrawerProps {
  open: boolean;
  showCustomizeBrandingInsight?: boolean;
  trackEvents: boolean;
  onDrawerClose(): void;
}

interface InsightsDrawerType {
  expiringJobs: ExpiringJobsDataFragment;
  overdueItems: OverdueItems;
  revenue: RevenueCalculation;
  doubleBookings: DoubleBookings;
  topClientsBalance: TopClientsBalance;
  qboLastSync: QboLastSync;
  account: { shouldConnectQbo: boolean };
  clientReengagement: ClientReengagement;
  insightOrder: { order: string };
}

export function InsightsDrawer({
  open,
  onDrawerClose,
  showCustomizeBrandingInsight,
  trackEvents,
}: InsightsDrawerProps) {
  const { tomorrow, nextWeek } = getNextWeekRange();

  const { loading, data, error } = useQuery<InsightsDrawerType>(
    INSIGHTS_CARDS_QUERY,
    {
      variables: {
        startDate: tomorrow,
        endDate: nextWeek,
      },
      fetchPolicy: "network-only",
    },
  );

  const [, markCustomizeBrandingInsightClicked] = useCta(
    "customize_branding_insight_card_cta",
  );

  return (
    <Drawer title={"Insights"} open={open} onRequestClose={onDrawerClose}>
      <Content>
        <DrawerContent
          error={error}
          loading={loading}
          data={data}
          showCustomizeBrandingInsight={showCustomizeBrandingInsight}
          trackEvents={trackEvents}
          markCustomizeBrandingInsightClicked={
            markCustomizeBrandingInsightClicked
          }
        />
      </Content>
    </Drawer>
  );
}

interface DrawerContentInterface {
  error?: ApolloError;
  loading?: boolean;
  data?: InsightsDrawerType;
  showCustomizeBrandingInsight?: boolean;
  trackEvents: boolean;
  markCustomizeBrandingInsightClicked(): void;
}

function DrawerContent({
  error,
  loading,
  data,
  showCustomizeBrandingInsight,
  markCustomizeBrandingInsightClicked,
  trackEvents,
}: DrawerContentInterface) {
  const { formatMessage } = useIntl();

  if (error || isOrderDataEmpty(data?.insightOrder?.order)) {
    return (
      <div className={style.errorState}>
        <Content spacing={"small"}>
          <Icon name="alert" />
          <Text>{formatMessage(messages.insightsUnavailable)}</Text>
        </Content>
      </div>
    );
  } else if (loading) {
    return (
      <div className={style.loading}>
        <div data-testid="spinner" className="spinner spinner--inline" />
      </div>
    );
  } else {
    return (
      <>
        {getOrderedInsightCards({
          data,
          showCustomizeBrandingInsight,
          markCustomizeBrandingInsightClicked,
          trackEvents,
        })}
      </>
    );
  }
}

function isOrderDataEmpty(orderingDataString: string | undefined) {
  return orderingDataString === "{}";
}

export function getNextWeekRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
  );
  const nextWeek = new Date(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate() + 6,
  );
  return { tomorrow, nextWeek };
}

interface InsightCardOrderer {
  data?: InsightsDrawerType;
  showCustomizeBrandingInsight?: boolean;
  trackEvents: boolean;
  markCustomizeBrandingInsightClicked(): void;
}

interface InsightCardOrderingType {
  [key: number]: string;
}

export function getOrderedInsightCards({
  data,
  showCustomizeBrandingInsight,
  markCustomizeBrandingInsightClicked,
  trackEvents,
}: InsightCardOrderer) {
  /* eslint-disable @typescript-eslint/naming-convention */
  const insightCards: { [id: string]: JSX.Element | undefined | false } = {
    "Projected revenue": (
      <ProjectedRevenueInsight
        key={"ProjectedRevenueInsight"}
        revenue={data?.revenue}
        trackEvents={trackEvents}
      />
    ),
    "Overdue items": (
      <ScheduledItemsOverdueInsight
        key={"ScheduledItemsOverdueInsight"}
        overdueItems={data?.overdueItems}
        trackEvents={trackEvents}
      />
    ),
    "Jobs ending soon": (
      <ExpiringJobsInsight
        key={"ExpiringJobsInsight"}
        expiringJobs={data?.expiringJobs}
        trackEvents={trackEvents}
      />
    ),
    "Double bookings": (
      <DoubleBookingInsight
        key={"DoubleBookingInsight"}
        doubleBookings={data?.doubleBookings}
        trackEvents={trackEvents}
      />
    ),
    "Client balances": (
      <TopClientsBalanceInsight
        key={"TopClientsBalanceInsight"}
        topClientsBalance={data?.topClientsBalance}
        trackEvents={trackEvents}
      />
    ),
    "QuickBooks Sync": (
      <QBOLastSyncInsight
        key={"QBOLastSyncInsight"}
        qboLastSync={data?.qboLastSync}
        trackEvents={trackEvents}
      />
    ),
    "Turn On QuickBooks Sync": (
      <QBOTurnOnSyncInsight
        key={"QBOTurnOnSyncInsight"}
        shouldConnect={data?.account?.shouldConnectQbo}
        trackEvents={trackEvents}
      />
    ),
    "Re-engage clients": (
      <ClientReengagementInsight
        key={"ClientReengagementInsight"}
        clientReengagement={data?.clientReengagement}
        trackEvents={trackEvents}
      />
    ),
    "Customize your branding": showCustomizeBrandingInsight && (
      <CustomizeBrandingInsight
        key={"CustomizeBrandingInsight"}
        trackEvents={trackEvents}
        onButtonClick={markCustomizeBrandingInsightClicked}
      />
    ),
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const orderedInsightCards = [];
  let ordering: InsightCardOrderingType = {};
  const preferredOrdering = data?.insightOrder?.order;
  if (preferredOrdering) {
    ordering = JSON.parse(preferredOrdering) as InsightCardOrderingType;
    for (let i = 1; i <= Object.keys(ordering).length; i = i + 1) {
      orderedInsightCards.push(insightCards[ordering[i]]);
    }
  }

  return orderedInsightCards;
}
