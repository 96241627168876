import React, { Fragment } from "react";
import styles from "./NumberRoller.module.css";
import { generateDigitArrays } from "../../../utils";

interface NumberRollerProps {
  rollToNumber: string;
}

export function NumberRoller({ rollToNumber }: NumberRollerProps) {
  const numberRollerDigits = generateDigitArrays(rollToNumber);
  const hasDecimal = rollToNumber.includes(".");

  return (
    <div className={styles.numberRollerWrapper}>
      {numberRollerDigits.map((scrollValues, colIndex, { length }) => (
        <Fragment key={colIndex}>
          {hasDecimal && colIndex === length - 1 && <span>.</span>}
          <div className={styles.numberRoller} key={`col_${colIndex}`}>
            {scrollValues.map((value, rowIndex) => (
              <span role={"listitem"} key={`col_${colIndex}_row_${rowIndex}`}>
                {value}
              </span>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
