import React, { useEffect, useState } from "react";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./LandingPagePlanCard.module.css";
import { messages } from "./messages";
import { useLandingPagePlan } from "../../useLandingPagePlan";
import type { LandingPagePlanDataType } from "../../useLandingPagePlan.graphql";

interface PlanCardProps {
  cardHeading: string;
  cardDescription: string;
  featuresHeading: string;
  planUsersDescription: string;
  planFeatures: string[];
}

export function LandingPagePlanCard({
  cardHeading,
  cardDescription,
  featuresHeading,
  planUsersDescription,
  planFeatures,
}: PlanCardProps) {
  const { formatMessage } = useIntl();

  const [plan, setPlan] = useState<LandingPagePlanDataType>();
  const { landingPagePlan, loading } = useLandingPagePlan(cardHeading);

  useEffect(() => {
    if (landingPagePlan && !loading) {
      setPlan(landingPagePlan);
    }
  }, [landingPagePlan, loading]);

  return (
    <APIProvider>
      {!loading && plan && (
        <div data-testid="landingPagePlanCard" className={styles.cardContainer}>
          <Card>
            <div className={styles.cardPadding}>
              <Content>
                <div className={styles.topCardSection}>
                  <div className={styles.cardHeadingSection}>
                    <div className={styles.cardHeading}>
                      <Heading level={2}>{cardHeading}</Heading>
                    </div>
                    <div className={styles.cardDescription}>
                      <Text>{cardDescription}</Text>
                    </div>
                  </div>

                  {plan.planCostAnnual && plan.planCostMonthly && (
                    <div className={styles.cardCostSection}>
                      <div className={styles.monthlyPrice}>
                        <Text size="large" variation="subdued">
                          {formatMessage(messages.costFormatAmount, {
                            amount: plan.planCostMonthly,
                          })}
                        </Text>
                      </div>
                      <div className={styles.annualPrice}>
                        <Heading level={1}>
                          {formatMessage(messages.costFormatAmount, {
                            amount: plan.planCostAnnual,
                          })}
                        </Heading>
                      </div>
                      <div className={styles.costDescription}>
                        <Text>
                          <span className={styles.boldFont}>
                            {formatMessage(messages.costSavingsAmount, {
                              amount:
                                plan?.planCostMonthly - plan?.planCostAnnual,
                            })}
                          </span>
                          {formatMessage(messages.costSavingsAmountPartTwo)}
                        </Text>
                        <Text>
                          {formatMessage(messages.costSavingsBillingDate)}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.featuresHeading}>
                  <Text>{featuresHeading}</Text>
                  <div className={styles.line} />
                </div>
                <div className={styles.featuresMobileView}>
                  <div className={styles.featureItem}>
                    <Emphasis variation="bold">{planUsersDescription}</Emphasis>
                  </div>
                  {planFeatures.map((feature, index) => {
                    return (
                      <div key={index} className={styles.featureItem}>
                        <Text>{feature}</Text>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.featuresDesktopView}>
                  <ul>
                    <li>
                      <Emphasis variation="bold">
                        {planUsersDescription}
                      </Emphasis>
                    </li>
                    <li>{planFeatures[0]}</li>
                  </ul>
                  <ul>
                    <li>{planFeatures[1]}</li>
                    <li>{planFeatures[2]}</li>
                  </ul>
                  <ul>
                    <li>{planFeatures[3]}</li>
                  </ul>
                </div>
              </Content>
            </div>
          </Card>
        </div>
      )}
    </APIProvider>
  );
}

export function LandingPagePlanCardWithApollo({
  cardHeading,
  cardDescription,
  featuresHeading,
  planUsersDescription,
  planFeatures,
}: PlanCardProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <LandingPagePlanCard
          cardHeading={cardHeading}
          cardDescription={cardDescription}
          featuresHeading={featuresHeading}
          planUsersDescription={planUsersDescription}
          planFeatures={planFeatures}
        />
      </IntlProvider>
    </APIProvider>
  );
}
