import React from "react";
import { Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { strFormatDate } from "@jobber/components/FormatDate";
import { formatCurrency } from "legacy/utilities/formatCurrency";
import styles from "./PurchaseTotal.module.css";
import { messages } from "./messages";

export interface PurchaseTotalProps {
  startBillingDate: Date;
  purchaseTotal: number;
}

export function PurchaseTotal(props: PurchaseTotalProps) {
  const { startBillingDate, purchaseTotal } = props;
  const { formatMessage } = useIntl();

  const billedImmediately =
    startBillingDate &&
    new Date(startBillingDate).toISOString() <= new Date().toISOString();

  return (
    <div className={styles.previewFlex}>
      <Heading level={3}>{getBilledTodayLabel()}</Heading>
      <Heading level={3}>{getFormattedTotal(purchaseTotal)}</Heading>
    </div>
  );

  function getBilledTodayLabel() {
    return billedImmediately
      ? formatMessage(messages.billedTodayHeader)
      : formatMessage(messages.futureBilledHeader, {
          startBillingDate: getFormattedDate(startBillingDate),
        });
  }
}

function getFormattedTotal(total: number) {
  return formatCurrency(total, "$", 2);
}

function getFormattedDate(renewalDate: Date) {
  return strFormatDate(new Date(renewalDate));
}
