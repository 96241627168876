import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import styles from "./ResourceItem.module.css";

export interface ResourceItemProps {
  icon?: IconNames;
  label: string;
  url: string;
  external?: boolean;
}

export function ResourceItem({
  icon = "archive",
  label,
  url,
  external,
}: ResourceItemProps) {
  return (
    <li className={styles.listItem}>
      <div className={styles.iconItem}>
        <Icon name={icon} />
      </div>
      <Text>
        <a href={url} target={external ? "_blank" : "_self"} rel="noreferrer">
          {label}
        </a>
      </Text>
    </li>
  );
}
