import { SplitNames, useFeatureFlag } from "utilities/split";

export const useCampaignAutomationsSplit = () => {
  const isInCampaignAutomations = useFeatureFlag(
    SplitNames.CampaignAutomations,
  );

  const isInCampaignAutomationsV2 = useFeatureFlag(
    SplitNames.CampaignsAutomationsV2,
  );

  return {
    isInCampaignAutomations,
    isInCampaignAutomationsV2,
  };
};
