import React, { useEffect } from "react";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

interface SendTestEmailModalProps {
  isOpen: boolean;
  isInErrorState?: boolean;
  closeModal: () => void;
  onSendTestEmail: () => void;
  userEmail: string;
  formValidationErrors: boolean;
}

export function SendTestEmailModal(props: SendTestEmailModalProps) {
  return (
    <IntlProvider>
      <SendTestEmailModalInternal {...props} />
    </IntlProvider>
  );
}

function SendTestEmailModalInternal({
  isOpen,
  isInErrorState = false,
  closeModal,
  onSendTestEmail,
  userEmail,
  formValidationErrors,
}: SendTestEmailModalProps): JSX.Element {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isOpen && formValidationErrors) {
      closeModal();
    }
  }, [closeModal, formValidationErrors, isOpen]);

  if (formValidationErrors) {
    return <></>;
  }

  return (
    <ConfirmationModal
      title={formatMessage(messages.title)}
      size={"small"}
      open={isOpen}
      confirmLabel={formatMessage(messages.sendEmailButton)}
      onConfirm={onSendTestEmail}
      cancelLabel={formatMessage(messages.cancelButton)}
      onCancel={closeModal}
      onRequestClose={closeModal}
    >
      <Content>
        {isInErrorState && (
          <Banner type={"error"}>{formatMessage(messages.errorMessage)}</Banner>
        )}
        <Text>
          {formatMessage(messages.textLine1)}{" "}
          <Emphasis variation={"bold"}>{userEmail}</Emphasis>
        </Text>
        <Text>{formatMessage(messages.textLine2)}</Text>
      </Content>
    </ConfirmationModal>
  );
}
