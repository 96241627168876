import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.termsAndConditionsConfiguration.configurationLabel",
    defaultMessage: "Terms and Conditions",
    description: "Label for the configuration option",
  },
  errorMessage: {
    id: "selfServeBookings.termsAndConditionsConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Message displayed when an error occurs",
  },
  saveMessage: {
    id: "selfServeBookings.termsAndConditionsConfiguration.saveMessage",
    defaultMessage: "Updated terms and conditions",
    description:
      "Toast message for successfully saving the terms and conditions link",
  },
  modalTitle: {
    id: "selfServeBookings.termsAndConditionsConfiguration.modalTitle",
    defaultMessage: "Edit Terms and Conditions",
    description: "Title of the edit modal",
  },
  modalSave: {
    id: "selfServeBookings.termsAndConditionsConfiguration.modalSave",
    defaultMessage: "Save",
    description: "Save button text",
  },
  modalCancel: {
    id: "selfServeBookings.termsAndConditionsConfiguration.modalCancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  inputTextDescription: {
    id: "selfServeBookings.termsAndConditionsConfiguration.inputTextDescription",
    defaultMessage:
      "Share important information about your services and policies with your customers ahead of time",
    description: "Text shown below the input on the Terms and Conditions modal",
  },
  inputTextPlaceholder: {
    id: "selfServeBookings.termsAndConditionsConfiguration.inputTextPlaceholder",
    defaultMessage: "Link",
    description: "Placeholder text on for the terms and conditions link input",
  },
  urlEmpty: {
    id: "selfServeBookings.termsAndConditionsConfiguration.urlEmpty",
    defaultMessage: "No URL added",
    description: "Placeholder for url when not set",
  },
  urlValidationError: {
    id: "selfServeBookings.termsAndConditionsConfiguration.urlValidationError",
    defaultMessage: "Enter a valid link",
    description: "Validation error message displayed with url is invalid",
  },
});
