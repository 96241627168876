import { InputText } from "@jobber/components/InputText";
import React, { type Dispatch, type SetStateAction, useContext } from "react";
import { useIntl } from "react-intl";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { messages } from "./messages";

export interface RecurlyInputTextProps {
  className?: string;
  pattern?: RegExp;
  inputValue?: string;
  inputName: string;
  dataRecurlyTag: string;
  required: boolean;
  handleFocus: (input: string) => void;
  setSelected: Dispatch<SetStateAction<string>>;
  onValidationError(error: FieldErrorState): void;
}

export function RecurlyInputText(props: RecurlyInputTextProps) {
  const { formatMessage } = useIntl();
  const {
    className,
    pattern,
    inputValue,
    inputName,
    dataRecurlyTag,
    required,
    handleFocus,
    setSelected,
    onValidationError,
  } = props;
  const validations = {
    required: {
      value: required,
      message: `${inputName} ${formatMessage(messages.isRequired)}`,
    },
    pattern: pattern
      ? {
          value: pattern,
          message: `${formatMessage(messages.isRequired)} ${inputName}`,
        }
      : undefined,
  };

  const context = useContext(PurchaseFormContext);

  return (
    <div className={className}>
      <InputText
        validations={validations}
        placeholder={inputName}
        onFocus={() => {
          handleFocus(dataRecurlyTag);
        }}
        onChange={(value: string) => {
          setSelected(value);
        }}
        onValidation={(message: string) => {
          onValidationError && onValidationError({ field: inputName, message });
        }}
        value={inputValue}
        disabled={context.submitting}
      />
      <input
        data-recurly={dataRecurlyTag}
        value={inputValue}
        hidden={true}
        readOnly={true}
        name={formatRecurlyInputString(inputName)}
        data-testid={formatRecurlyInputString(inputName)}
      />
    </div>
  );
}

export function formatRecurlyInputString(inputName: string) {
  // Strips non-alpha characters and replaces with _
  return `${inputName.replace(/\W/g, "_").toLowerCase()}_recurly`;
}
