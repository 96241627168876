import { ComponentRenderOptions } from "jobber/dashboard/components/Home/constants";

export function useHomeHeaderCheck({
  growTrialActive,
}: {
  growTrialActive: boolean;
}): ComponentRenderOptions {
  if (growTrialActive) {
    return ComponentRenderOptions.GROW_TRIAL_ONBOARDING;
  } else {
    return ComponentRenderOptions.NONE;
  }
}
