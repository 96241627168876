import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import type { IconColorNames, IconNames } from "@jobber/design";
import React, { useEffect, useState } from "react";
import { SideDrawer, Typography } from "@jobber/components";
import { useIntl } from "react-intl";
import type {
  AiAssistantAction,
  AiAssistantFollowup,
  AiAssistantQuestionInterpretation,
  AiAssistantVisual,
} from "~/utilities/API/graphql";
import { Intercom } from "utilities/chat";
import { ConversationList } from "./ConversationList";
import { messageType } from "./ConversationListItem";
import type { ConversationListItemModel } from "./ConversationListItem";
import styles from "./JobberAssistant.module.css";
import { messages } from "./messages";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

export interface InteractionItem {
  id: string;
  requestId: string;
  createdAt: string;
  message: string;
  footnotes?: string[];
  visuals: AiAssistantVisual[];
  questionInterpretation: AiAssistantQuestionInterpretation | undefined;
  type: messageType;
  actions?: AiAssistantAction[];
  followups: AiAssistantFollowup[];
}

interface JobberAssistantProps {
  conversationList: InteractionItem[];
  onPrompt: (prompt: PromptType) => void;
  showInitialMessage: boolean;
}

// eslint-disable-next-line max-statements
export const JobberAssistant = ({
  conversationList,
  onPrompt,
  showInitialMessage,
}: JobberAssistantProps) => {
  const { formatMessage } = useIntl();

  const [promptInput, setPromptInput] = useState("");
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  const listItems = buildListItems(conversationList);

  // Clear the loading state if latest list item is a response.
  useEffect(() => {
    if (conversationList.length === 0) {
      return;
    }

    const lastMessageType = conversationList[conversationList.length - 1]?.type;

    setIsSendDisabled(
      [
        messageType.REQUEST,
        messageType.PARTIAL_RESPONSE,
        messageType.LOADING,
      ].includes(lastMessageType),
    );
  }, [conversationList]);

  return (
    <div className={styles.chatWindow} data-testid="jobber-assistant-chat">
      <div className={styles.listContainer}>
        <div className={styles.listContent}>
          <ConversationList items={listItems} />
        </div>
      </div>
      <SideDrawer.Footer>
        <div className={styles.footerContainer}>
          <div className={styles.promptContainer}>
            <InputText
              placeholder={formatMessage(
                messages.jobberCopilotInputPlaceholder,
              )}
              value={promptInput}
              onChange={handleInput}
              onEnter={handleSend}
              autofocus
            />
            <Button
              label=""
              ariaLabel="Send"
              icon={"backArrow"}
              onClick={handleSend}
              disabled={isSendDisabled}
            />
          </div>
          <Typography size="smaller" textColor="textSecondary">
            {formatMessage(messages.jobberCopilotDisclaimer)}
          </Typography>
        </div>
      </SideDrawer.Footer>
    </div>
  );

  function handleInput(newInput: string) {
    setPromptInput(newInput);
  }

  function handleSend() {
    const trimmedInput = promptInput.trim();

    if (trimmedInput == "" || isSendDisabled) return;

    setIsSendDisabled(true);
    onPrompt({ message: trimmedInput });
    Intercom.EXECUTE("trackEvent", "click_send_jobber_copilot_chat");
    setPromptInput("");
  }

  function buildListItems(list: InteractionItem[]) {
    if (!showInitialMessage) {
      return list.map(buildListItem).slice(1);
    }
    return list.map(buildListItem);
  }

  function buildListItem(
    interactionItem: InteractionItem,
    index: number,
  ): ConversationListItemModel {
    let icon: IconNames | undefined;
    let iconColor: IconColorNames | undefined;

    switch (interactionItem.type) {
      case messageType.REQUEST:
        icon = undefined;
        iconColor = undefined;
        break;
      case messageType.ERROR:
        icon = "alert";
        iconColor = "criticalOnSurface";
        break;
      case messageType.SYSTEM:
      case messageType.RESPONSE:
      case messageType.PARTIAL_RESPONSE:
        icon = "sparkles";
        iconColor = "success";
        break;
    }

    return {
      id: interactionItem.id,
      actions: interactionItem.actions ?? [],
      requestId: interactionItem.requestId,
      icon: icon,
      iconColor: iconColor,
      type: interactionItem.type,
      content: interactionItem.message,
      questionInterpretation: interactionItem.questionInterpretation,
      visuals: interactionItem.visuals,
      showFeedbackOptions: index !== 0 && !interactionItem.actions?.length,
      // Only add footnote if there is one.
      ...(interactionItem.footnotes && {
        footnotes: interactionItem.footnotes,
      }),
      // Actions add buttons below the message. Don't add further buttons (followup pills) when they exist.
      followups:
        !interactionItem.actions || interactionItem.actions.length === 0
          ? // We don't want to show a loading state on the followup buttons
            interactionItem.followups.map(f => ({ ...f, loading: false }))
          : [],
      ...(interactionItem.followups.length && {
        onClick: (prompt: string) => {
          if (isSendDisabled) return;
          setIsSendDisabled(true);
          onPrompt({ message: prompt });
        },
      }),
    };
  }
};
