import { Content } from "@jobber/components/Content";
import React from "react";

export function CancellationFinalizedStep({
  nextBillingDate,
}: {
  nextBillingDate: string;
}) {
  return (
    <>
      <Content>
        Your account cancellation is in progress. You will lose access to your
        account on {nextBillingDate}.
      </Content>
      <Content>
        We&apos;re sad to see you go, but wish you all the best. If you want to
        stay up-to-date on expert advice, visit{" "}
        <a href="https://getjobber.com/academy/">Jobber Academy</a> any time.
      </Content>
    </>
  );
}
