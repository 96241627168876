import React from "react";

export interface Translations {
  removeMoneyManagementAccount: string;
  addBankPrompt: string;
  addBankPromptNoPlaid: string;
  manualAddBankPrompt: string;
  routingNumber: string;
  routingNumberHelp: string;
  accountNumber: string;
  ibanNumber: string;
  accountNumberHelp: string;
  ibanNumberHelp: string;
  holderName: string;
  connectBankAccountHeading: string;
  jobberPaymentsTitle: string;
  demoJobberPaymentsTitle: string;
  jobberPaymentsSubtitle: string;
  centCurrency: string;
  verifyBusinessHeading: string;
  managedAccountRequirements: string;
  managedAccountSecurityAssurance: string;
  managedAccountTagline: string;
  payoutTimeInformation: string;
  chequeNumber: string;
  referenceNumber: string;
  transactionNumber: string;
  mandatoryCardOnFileDisclaimer: string;
  wisetackSignupDescription: string;
  wisetackSignupTitle1: string;
  wisetackSignupBenefits: string;
  wisetackSignupButton: string;
  wisetackSignupButtonIneligibilityDisclaimerTooltip: string;
  wisetackSignupButtonNonAccountOwnerTooltipMessage: string;
  wisetackEligibilityStatementForEligibleAccount: string;
  wisetackEligibilityStatementForIneligibleAccount: string;
  wisetackEligibilityDetailsListPointOne: string;
  wisetackEligibilityDetailsListPointTwo: string;
  wisetackEligibilityDetailsListPointThree: string;
  wisetackEligibilityContactStatement: string;
  wisetackIneligibleIndustriesStatement: string;
  wisetackAlmostThereTitle: string;
  wisetackAlmostThereText: string;
  wisetackAlmostThereButton: string;
  wisetackApplicationSentTitle: string;
  wisetackApplicationSentText: string;
  wisetackApplicationSentButton: string;
  wisetackSignupContactSuccess: string;
  wisetackPaymentCalculatorResourceTitle: string;
  wisetackPaymentCalculatorResourceDescription: string;
  wisetackMarketingToolsResourceTitle: string;
  wisetackMarketingToolsResourceDescription: string;
  wisetackTrainingWebinarResourceTitle: string;
  wisetackTrainingWebinarResourceDescription: string;
  wisetackAdminMessage: string;
  debitAgreementFormTitle: string;
  debitAgreementFormDetails: string;
  debitAgreementFormBoldedDetails: string;
  directDebitGuaranteeTitle: string;
  directDebitGuaranteeDetails: string;
  contactInfoTitle: string;
  contactInfoJobberSuite: string;
  contactInfoJobberAddress: string;
  contactInfoJobberEmail: string;
  contactInfoJobberPhoneNumber: string;
  debitIdentificationTitle: string;
  debitIdentificationSun: string;
  instructionTitle: string;
  whereIsYourAccountHeld: string;
  experimentalVerifyRequirements: string;
  labourCost: string;
  profitBarProfitMargin: string;
  profitBarPrice: string;
  profitBarLineItemCost: string;
  profitBarLabour: string;
  profitBarExpenses: string;
  profitBarProfit: string;
  profitBarCost: string;
  profitBarRevenue: string;
  recurringJobProfitBarProfitMargin: string;
  recurringJobProfitability: string;
}

export interface TranslationContextType {
  translations: Partial<Translations>;
}

export const TranslationContext = React.createContext<TranslationContextType>({
  translations: {},
});
TranslationContext.displayName = "TranslationContext";
