import React from "react";
import { Content, Glimmer } from "@jobber/components";

export function LoadingLayout() {
  return (
    <span data-testid="loadingLayout">
      <Content>
        <Content type="header">
          <Glimmer size={"large"} />
        </Content>
        <Content type={"section"} spacing={"small"}>
          <Glimmer size={"auto"} />
          <Glimmer size={"large"} />
        </Content>
        <Content type={"section"} spacing={"small"}>
          <Glimmer size={"auto"} />
          <Glimmer size={"large"} />
        </Content>
        <Content type={"section"} spacing={"small"}>
          <Glimmer size={"auto"} />
          <Glimmer size={"large"} />
        </Content>
      </Content>
    </span>
  );
}
