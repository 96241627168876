import { useEffect, useState } from "react";
import type { Option } from "@jobber/components/Autocomplete";
import type { AdditionalCriteriaOptions } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { ProductsQuery } from "~/utilities/API/graphql";

export function useLineItemsOptions(
  resultData: ProductsQuery | undefined,
): AdditionalCriteriaOptions {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (resultData?.products?.nodes) {
      const lineItemOptions = resultData.products.nodes.map(product => ({
        label: product.name,
        value: product.id,
      }));
      setOptions(lineItemOptions);
    }
  }, [resultData]);

  function getOptions(lineItemName: string) {
    if (lineItemName.length === 0) {
      return options;
    }
    const filterRegex = new RegExp(lineItemName, "i");
    return options.filter(option => option.label.match(filterRegex));
  }

  return {
    options,
    optionsLoaded: options.length > 0,
    getOptions,
  };
}
