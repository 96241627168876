import React, { useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputText } from "@jobber/components/InputText";
import { Icon } from "@jobber/components/Icon";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./JPayAutoenableTurnOffReasonModal.module.css";

const jobberPaymentsDisabledReasons = [
  "I'm already using a different payment processor",
  "I don't need to accept payment online",
  "I don't want to pay processing fees",
  "I don't know enough about Jobber Payments",
  "It doesn't work with my accounting software or process",
  "Other",
];
export const ctaName = "jobber_payments_autoenable_turn_off_reason_modal_cta";

export interface JPayAutoenableTurnOffReasonModalProps {
  dismissCTA(): void;
  convertCTA(): void;
}

export function JPayAutoenableTurnOffReasonModal({
  dismissCTA,
  convertCTA,
}: JPayAutoenableTurnOffReasonModalProps) {
  const [modalOpen, setModalOpen] = useState(true);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: ctaName,
    });
  }, []);

  return (
    <Modal
      open={modalOpen}
      title="Jobber Payments has been disabled"
      onRequestClose={dismissModal}
      primaryAction={{
        label: "Submit",
        ariaLabel: "Submit Jobber Payments turn off reason",
        onClick: submitTurnOffReason,
      }}
    >
      <Content>
        <Text>
          We&apos;re sorry to see Jobber Payments isn&apos;t what you were
          looking for. To help us improve, we&apos;d appreciate feedback.
        </Text>
        <Text>
          <Emphasis variation="bold">
            What is your primary reason for turning off Jobber Payments?
          </Emphasis>
        </Text>
        <div>
          {jobberPaymentsDisabledReasons.map(reason => (
            <div key={reason} className={styles.reason}>
              <Checkbox
                checked={selectedReasons.includes(reason)}
                label={reason}
                value={reason}
                onChange={selectReason(reason)}
              />
            </div>
          ))}
          {selectedReasons.includes("Other") && (
            <div className={styles.inputTextbox}>
              <InputText
                value={otherReason}
                onChange={changeOtherReason}
                multiline={true}
                placeholder="Please tell us why"
                name="otherReason"
              />
            </div>
          )}
          {showAlert && (
            <div className={styles.alert}>
              <Icon name="alert" color="red" size="small" />
              <div className={styles.alertText}>
                {isOtherReasonEmpty()
                  ? "Please provide a reason in the text field above."
                  : "Please select a reason(s) from the list above."}
              </div>
            </div>
          )}
        </div>
      </Content>
    </Modal>
  );

  function dismissModal() {
    setModalOpen(false);
    dismissCTA();
  }

  function submitTurnOffReason() {
    if (!selectedReasons.length || isOtherReasonEmpty()) {
      setShowAlert(true);
    } else {
      setModalOpen(false);

      const translatedReasons: number[] = [];
      selectedReasons.map(value => {
        if (value !== "Other") {
          translatedReasons.push(jobberPaymentsDisabledReasons.indexOf(value));
        }
      });

      Amplitude.TRACK_EVENT("Interacted with Experiment", {
        experiment: "jpay_autoenable_turnoff_reason",
        interaction:
          translatedReasons.sort().toString() +
          (translatedReasons.length > 0 && otherReason ? "," : "") +
          otherReason,
      });

      convertCTA();
    }
  }

  function isOtherReasonEmpty() {
    return selectedReasons.includes("Other") && !otherReason;
  }

  function selectReason(reason: string) {
    return (checkValue: boolean) => {
      let updatedSelectedReasons = Array.from(selectedReasons);
      if (checkValue) {
        updatedSelectedReasons.push(reason);
        setShowAlert(showAlert && isOtherReasonEmpty());
      } else {
        updatedSelectedReasons = updatedSelectedReasons.filter(
          r => r !== reason,
        );
        setShowAlert(showAlert && reason !== "Other");
      }
      setSelectedReasons(updatedSelectedReasons);
    };
  }

  function changeOtherReason(newText: string) {
    setShowAlert(false);
    setOtherReason(newText);
  }
}
