import { Button } from "@jobber/components/Button";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Content } from "@jobber/components/Content";
import { Context } from "jobber/setupWizard/context/SetupWizardContext";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import { generateLastSubmittedStepQandA } from "jobber/setupWizard/utils";
import { messages } from "../messages";
import styles from "../../SetupWizardForms.module.css";

export function HeardAboutUsPageForm({
  navigation,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } = useContext(Context);
  const [heardAboutUs, setHeardAboutUs] = useState<string>(
    wizardData?.heardAboutUs ?? "",
  );

  const handleSubmit = async () => {
    const dataToSend = {
      heardAboutUs,
      questionsAndAnswers: [generateLastSubmittedStepQandA(Routes.hdyhau)],
    };
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleInputChange(text: string) {
    setHeardAboutUs(text);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <InputText
          name="hdyhau"
          placeholder={formatMessage(messages.hdyhauPlaceholder)}
          value={heardAboutUs}
          onChange={handleInputChange}
          size="large"
        />
        <div className={styles.button}>
          <Button
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            icon="arrowRight"
            iconOnRight={true}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
