import React, { useContext } from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { messages } from "./messages";
import styles from "./PurchaseButton.module.css";

interface PurchaseButtonProps {
  viewingCurrentPlan: boolean;
  enablePurchaseButton: boolean;
  subscribeDisabled: boolean;
  onConfirmSubscription?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export function PurchaseButton({
  viewingCurrentPlan,
  enablePurchaseButton,
  subscribeDisabled,
  onConfirmSubscription,
}: PurchaseButtonProps) {
  const { formatMessage } = useIntl();

  const { submitting } = useContext(PurchaseFormContext);

  const buttonLabel = viewingCurrentPlan
    ? subscribeDisabled
      ? formatMessage(messages.yourCurrentPlan)
      : formatMessage(messages.resubscribe)
    : formatMessage(messages.confirmSubscription);
  const buttonDisabled = !enablePurchaseButton || subscribeDisabled;

  return (
    <div className={styles.buttonContainer}>
      <Button
        id={"checkout-subscribe-button"} // Note: This is used by Google Tag Manager. Do not change this ID without updating Google Tag Manager.
        loading={subscribeDisabled ? false : submitting}
        disabled={buttonDisabled}
        ariaLabel={buttonLabel}
        label={buttonLabel}
        onClick={onConfirmSubscription}
        fullWidth={true}
      />
    </div>
  );
}
