import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Text } from "@jobber/components/Text";

interface SchedulingSummaryProps {
  dateSummary: string;
  assigneeSummary: string | undefined;
}

export const SchedulingSummary = (props: SchedulingSummaryProps) => {
  return (
    <div data-testid="scheduling-summary">
      <Typography fontWeight={"bold"}>{props.dateSummary}</Typography>
      <Text align="end">{props.assigneeSummary}</Text>
    </div>
  );
};
