import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useMobileBillingInfo } from "jobber/campaigns/hooks/useMobileBillingInfo";
import type { CampaignPurchaseStateQueryQuery } from "~/utilities/API/graphql";
import { CAMPAIGN_PURCHASE_STATE_QUERY } from "./useEmailCampaignsPurchaseFlow.graphql";

/**
 * This hook is used to determine if the SP should be asked to purchase campaigns before sending or activating a campaign.
 * This hook should be used to to control the purchase flow, whereas `useEmailCampaignsPreview` should be used to display campaign upsell CTAs
 *
 * `validAdditionalCondition` should be used for additional validation or other one off conditions. Otherwise explicitly pass in `true` to ignore this check.
 */
export function useEmailCampaignsPurchaseFlow(
  campaignId: string,
  validAdditionalCondition: boolean,
) {
  const { data, loading: purchaseStateLoading } =
    useQuery<CampaignPurchaseStateQueryQuery>(CAMPAIGN_PURCHASE_STATE_QUERY, {
      variables: {
        id: campaignId,
      },
      fetchPolicy: "network-only",
    });

  const { isMobileBilled, loading: isMobileBilledLoading } =
    useMobileBillingInfo();
  const loading = isMobileBilledLoading || purchaseStateLoading;
  const requiresPurchasingOfCampaigns =
    data?.commsCampaignPurchaseState?.purchaseRequired;

  const campaignsAvailable =
    data?.commsCampaignPurchaseState?.availableToPurchase || isMobileBilled;

  const previewEnabled = useMemo(() => {
    return (
      !loading &&
      requiresPurchasingOfCampaigns &&
      campaignsAvailable &&
      validAdditionalCondition
    );
  }, [
    loading,
    requiresPurchasingOfCampaigns,
    campaignsAvailable,
    validAdditionalCondition,
  ]);

  return { previewEnabled, isMobileBilled, loading };
}
