import { Amplitude } from "~/utilities/analytics/Amplitude";

export const defaultProperties = {
  name: "Comms Compose",
  type: "Email",
};

export function trackMoreActionOptionClickEvent(moreActionValue: string) {
  let interaction;
  const possibleMoreActionValues = ["cc", "reply_to", "customizeTemplate"];
  switch (moreActionValue) {
    case "cc":
      interaction = "Select cc";
      break;
    case "reply_to":
      interaction = "Select reply-to";
      break;
    case "customizeTemplate":
      interaction = "Select customize template";
      break;
    default:
  }

  if (possibleMoreActionValues.includes(moreActionValue)) {
    Amplitude.TRACK_EVENT("Interacted with Dialog", {
      ...defaultProperties,
      interaction: interaction,
    });
  }
}

export function trackMoreActionButtonClickEvent() {
  Amplitude.TRACK_EVENT("Interacted with Dialog", {
    ...defaultProperties,
    interaction: "Select more actions",
  });
}
