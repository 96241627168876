import { defineMessages } from "react-intl";

export const messages = defineMessages({
  planTapeAltText: {
    id: "reviews.purchase.planTapeAltText",
    defaultMessage: " ",
    description:
      "A black tape strip with the words 'MARKETING TOOLS' at the top of the page",
  },
  heroAltText: {
    id: "reviews.purchase.heroAltText",
    defaultMessage: " ",
    description:
      "A dashboard showing all reviews on the left, with additional imagery showing a text being sent to a client, and a sample review.",
  },
  moreClientsAltText: {
    id: "reviews.purchase.moreClientsAltText",
    defaultMessage: " ",
    description:
      "Google search results displayed in the background with an arrow demonstrating that your business is ranking higher in the search.",
  },
  protectReputationAltText: {
    id: "reviews.purchase.protectReputationAltText",
    defaultMessage: " ",
    description:
      "A man (left) shows a woman (right) an iPad, and the woman appears satisfied with the outcome.",
  },
  trustedBrandAltText: {
    id: "reviews.purchase.trustedBrandAltText",
    defaultMessage: " ",
    description:
      "Demonstration of sending a Google review request through an invoice on the left with a five star review on the right.",
  },
});
