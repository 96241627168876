import { useEffect, useState } from "react";
import { useMutation, useSubscription } from "@apollo/client";
import type {
  FindPhoneNumbersPayload,
  PhoneNumbersFoundSubscription,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import {
  FIND_PHONE_NUMBERS_MUTATION,
  PHONE_NUMBERS_FOUND_SUBSCRIPTION,
} from "./graphql";

const retrievalError =
  "Phone number options not available at this time. Try again later or contact Support";

export function usePhoneNumbers() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [numbers, setNumbers] = useState<string[]>([]);

  const [findPhoneNumberMutation] = useMutation<FindPhoneNumbersPayload>(
    FIND_PHONE_NUMBERS_MUTATION,
  );

  const { data, error: subscriptionError } =
    useSubscription<PhoneNumbersFoundSubscription>(
      PHONE_NUMBERS_FOUND_SUBSCRIPTION,
    );

  useEffect(() => {
    handleSubscriptionData();
  });

  return [findPhoneNumbers, loading, numbers, error] as const;

  function findPhoneNumbers() {
    findPhoneNumberMutation().catch((err: Error) => {
      setLoading(false);
      setError(retrievalError);
      Rollbar.EXECUTE("Error getting new DPN list", err);
    });

    setLoading(true);
  }

  function handleSubscriptionData() {
    if (!data) {
      return;
    }

    setLoading(false);

    if (subscriptionError || data.phoneNumbersFound.errors.length > 0) {
      setError(retrievalError);

      return;
    }

    if (data) {
      setNumbers(data.phoneNumbersFound.phoneNumbers);
    }
  }
}
