import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Spinner } from "@jobber/components/Spinner";
import React, { createContext, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import type { OnlineBookingServicesQuery } from "~/utilities/API/graphql";
import { useQueryServices } from "./hooks";
import { ProductAndServiceSearchModal } from "./components/OfferingsCard/components/ProductAndServiceSearchModal";
import { messages } from "./messages";
import { EmptyOfferings } from "./components/OfferingsCard/components/EmptyOfferings";
import { OfferingsCard } from "./components/OfferingsCard";

interface ServicesFetchContextProps {
  fetchMoreServices?: () => Promise<
    ApolloQueryResult<OnlineBookingServicesQuery>
  >;
  hasMoreServices?: boolean;
}
export const ServicesFetchContext = createContext<
  ServicesFetchContextProps | undefined
>(undefined);
export function OfferingsSettingsPageLoader() {
  const {
    offerings,
    acceptingOnlineBookings,
    loading,
    error,
    fetchMore,
    hasMoreServices,
  } = useQueryServices();
  const displayContent = !loading && !error;
  const [modalOpen, setModalOpen] = useState(false);

  function toggleModal() {
    setModalOpen(current => !current);
  }

  return (
    <>
      <ProductAndServiceSearchModal
        open={modalOpen}
        closeSearchModal={toggleModal}
      />
      <Card
        header={{
          title: messages.services.defaultMessage,
          action: (
            <Button
              label={messages.add.defaultMessage}
              type="secondary"
              onClick={toggleModal}
            />
          ),
        }}
      >
        <Content>
          <ServicesFetchContext.Provider
            value={{
              fetchMoreServices: fetchMore,
              hasMoreServices: hasMoreServices,
            }}
          >
            <Content spacing="large">
              {loading && <Spinner />}
              {error && <Banner type="error">{error}</Banner>}
              {displayContent && offerings.length > 0 && (
                <OfferingsCard
                  offerings={offerings}
                  acceptingOnlineBookings={acceptingOnlineBookings}
                />
              )}
              {displayContent && offerings.length == 0 && (
                <EmptyOfferings onAddService={toggleModal} />
              )}
            </Content>
          </ServicesFetchContext.Provider>
        </Content>
      </Card>
    </>
  );
}
