import { Text } from "@jobber/components/Text";
import React from "react";
import { Heading } from "@jobber/components/Heading";
import styles from "./AppMarketplaceCard.module.css";

export interface AppItemProps {
  image: string;
  title: string;
  description: string;
  ariaLabel?: string;
  onClick: () => void;
}

export function AppItem({
  image,
  title,
  description,
  ariaLabel = title,
  onClick,
}: AppItemProps) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick();
    }
  };

  return (
    <div
      className={styles.app}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
    >
      <div className={styles.appImage}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.appDetails}>
        <Heading level={5}>{title}</Heading>
        <Text>{description}</Text>
      </div>
    </div>
  );
}
