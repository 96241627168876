import React from "react";
import { Gallery } from "@jobber/components/Gallery";
import type { GalleryImage } from "jobber/marketplace/IntegrationProps";

interface AppGalleryProps {
  galleryImages: GalleryImage[];
}

export function AppGallery({ galleryImages }: AppGalleryProps) {
  const files = galleryImages.map(galleryImage => {
    return {
      key: galleryImage.key,
      name: galleryImage.filename,
      type: galleryImage.type,
      size: galleryImage.size,
      progress: 1,
      src: galleryImage.url,
    };
  });

  return <Gallery size="large" files={files} />;
}
