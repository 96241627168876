import React, { type RefObject } from "react";
import { Popover } from "@jobber/components/Popover";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { useCta } from "~/utilities/contexts/internal/useCtas";
import styles from "./JobCostHeaderPopover.module.css";
import { messages } from "./messages";

export interface JobCostHeaderPopoverProps {
  elementRef: RefObject<HTMLDivElement>;
}

export function JobCostHeaderPopover(props: JobCostHeaderPopoverProps) {
  const [showCTA, dismissCTA] = useCta("job_costing_profit_bar_help");
  const { formatMessage } = useIntl();

  return (
    <Popover
      attachTo={props.elementRef}
      open={showCTA}
      preferredPlacement="top"
      onRequestClose={() => {
        dismissCTA();
      }}
    >
      <div className={styles.popoverContainer}>
        <div className={styles.content}>
          <Heading level={5}>{formatMessage(messages.popoverHeading)}</Heading>
          <Text>{formatMessage(messages.popoverHeadingDescription)}</Text>
        </div>
        <div className={styles.button}>
          <Button
            label={formatMessage(messages.popoverButton)}
            url={formatMessage(messages.popoverButtonLink)}
            type="tertiary"
            external={true}
            variation="learning"
          />
        </div>
      </div>
    </Popover>
  );
}
