import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import companyImgSrc from "@images/signup/company-step.jpg";
import type { StatLines } from "jobber/setupWizard/components/MaskingTapeStats/MaskingTapeStats";
import { profilePageDefaultStats } from "jobber/setupWizard/components/constants";
import { messages } from "./messages";
import { CompanyPageForm } from "./components/CompanyPageForm";
import { MaskingTapeStats } from "../MaskingTapeStats";

export const rightHandSideImage: ImageType = {
  alt: messages.serviceProviderAltText,
  src: companyImgSrc,
};

export function CompanyPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.company,
    });
  }, []);

  const stat: StatLines[] = [...profilePageDefaultStats];

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.companyPageDefaultHeading)}
          copy={formatMessage(messages.companyPageDefaultCopy)}
          form={<CompanyPageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={rightHandSideImage}>
          <MaskingTapeStats stat={stat} />
        </MediaColumn>
      }
    />
  );
}
