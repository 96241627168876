import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/messages";

export function getExpressionText() {
  return screen.getByText("6 months after a job ends");
}

export function getExpressionTextWithoutField(text: string) {
  return screen.getByText(text);
}

export function getDropdownButtonText(text: RegExp | string) {
  return screen.getByRole("button", { name: text });
}

export function getOptionLabel(text: string) {
  return screen.getByText(text);
}

export function getNoClientTagsMessage() {
  return screen.getByText(
    `You don't have any ${messages.clientTagSubjectNoun.defaultMessage} yet`,
  );
}

export function getNoLineItemMessage() {
  return screen.getByText(
    `You don't have any ${messages.lineItemSubjectNoun.defaultMessage} yet`,
  );
}

export function getButton() {
  return screen.queryByRole("button");
}

export function getComboboxButton() {
  return screen.queryByRole("combobox");
}

export function getAddConditionButton() {
  return screen.getByRole("button", {
    name: "Add Condition",
  });
}

export function getDeleteButton(index: number) {
  return screen.getAllByRole("button", {
    name: "remove condition rule",
  })[index];
}

export function getMenuItemButton(name: string) {
  return screen.getByRole("menuitem", { name });
}

// Actions

export function iClickOnDefaultClientTagLabel() {
  userEvent.click(
    screen.getByText(messages.clientTagDefaultValue.defaultMessage),
  );
}

export function iClickOnDefaultLineItemLabel() {
  userEvent.click(
    screen.getByText(messages.lineItemDefaultValue.defaultMessage),
  );
}
