import { useMutation, useSubscription } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import type {
  DisputeCloseMutation,
  DisputeCloseMutationVariables,
  DisputeClosedSubscription,
  JobberPaymentsDispute,
} from "~/utilities/API/graphql";
import { DISPUTE_CLOSED_SUBSCRIPTION, DISPUTE_CLOSE_MUTATION } from "./graphql";

interface ReturnedDisputeClosedData {
  closeDispute: () => void;
  dispute?: JobberPaymentsDispute;
  loading: boolean;
  error: string;
}

export function useDisputeClosed(
  disputeId: string,
  complete: () => void,
): ReturnedDisputeClosedData {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [dispute, setDispute] = useState<JobberPaymentsDispute>();

  const [disputeCloseMutation] = useMutation<
    DisputeCloseMutation,
    DisputeCloseMutationVariables
  >(DISPUTE_CLOSE_MUTATION);

  const { data: subscriptionData, error: subscriptionError } =
    useSubscription<DisputeClosedSubscription>(DISPUTE_CLOSED_SUBSCRIPTION, {
      variables: {
        disputeId: disputeId,
      },
    });

  const handleSubscriptionData = useCallback(
    (data: DisputeClosedSubscription | undefined) => {
      if (!data) {
        return;
      }

      setLoading(false);

      if (subscriptionError || data.disputeClosed.userErrors.length > 0) {
        setError(data.disputeClosed.userErrors[0].message);
        return;
      }

      if (data) {
        setDispute(data.disputeClosed.dispute as JobberPaymentsDispute);
        complete();
      }
    },
    [subscriptionError],
  );

  useEffect(() => {
    handleSubscriptionData(subscriptionData);
  }, [subscriptionData, handleSubscriptionData]);

  return {
    closeDispute,
    dispute,
    loading,
    error,
  };

  async function closeDispute() {
    setError("");
    setLoading(true);
    const errorMessage = "Failed to close the dispute";

    const options = {
      variables: {
        id: disputeId,
      },
    };

    await disputeCloseMutation(options)
      .then(result => {
        const userErrors = result.data?.disputeClose.userErrors;
        if (userErrors && userErrors?.length > 0) {
          setError(errorMessage);
        }
      })
      .catch(() => {
        setError(errorMessage);
      })
      .finally(() => setLoading(false));
  }
}
