import { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom";
import { useIntl } from "react-intl";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { Template } from "~/utilities/API/graphql";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { messages as breadCrumbMessages } from "jobber/campaigns/components/Breadcrumbs/messages";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_PAGE_TITLE_STEPS,
  CAMPAIGNS_SEGMENT_PATH,
  CAMPAIGNS_TEMPLATE_EDIT_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
  UPSELL_CAMPAIGNS_PAGE_TITLE_STEPS,
} from "jobber/campaigns/constants";

export function useCampaignUpsellSplit() {
  const upsellCampaignSplit = useFeatureFlag(
    SplitNames.CommsEmailMarketingUpsell,
  );
  const { formatMessage } = useIntl();
  const newCampaignNavigationURL = useCallback(
    (templateType: Template = Template.RE_ENGAGE, fromLanding = false) => {
      return fromLanding
        ? {
            pathname: generatePath(CAMPAIGNS_SEGMENT_PATH, {
              templateType,
            }),
            search: "?from_landing=1",
          }
        : generatePath(CAMPAIGNS_TEMPLATE_PATH);
    },
    [],
  );

  const templateSelectedNext = useCallback((templateType: Template) => {
    return generatePath(CAMPAIGNS_SEGMENT_PATH, {
      templateType,
    });
  }, []);

  const breadCrumbSteps = useMemo(() => {
    if (upsellCampaignSplit) {
      return [
        formatMessage(breadCrumbMessages.templateLabel),
        formatMessage(breadCrumbMessages.recipientsLabel),
        formatMessage(breadCrumbMessages.contentLabel),
        formatMessage(breadCrumbMessages.reviewLabel),
      ];
    } else {
      return [
        formatMessage(breadCrumbMessages.recipientsLabel),
        formatMessage(breadCrumbMessages.contentLabel),
        formatMessage(breadCrumbMessages.reviewLabel),
      ];
    }
  }, [upsellCampaignSplit, formatMessage]);

  const upsellBottomBarCta = useCallback(
    (step: number, loading?: boolean, isFromEmailValidated?: boolean) => {
      switch (step) {
        case 2: {
          return formatMessage(bottomBarMessages.continueToContent);
        }
        case 3: {
          return formatMessage(bottomBarMessages.reviewCampaign);
        }
        case 4: {
          if (isFromEmailValidated || loading) {
            return formatMessage(bottomBarMessages.sendCampaign);
          }

          return formatMessage(bottomBarMessages.verifyFromEmail);
        }
        default: {
          return formatMessage(bottomBarMessages.continue);
        }
      }
    },
    [formatMessage],
  );

  const getRegularBottomBarCTA = useCallback(
    (step: number, loading?: boolean, isFromEmailValidated?: boolean) => {
      switch (step) {
        case 1: {
          return formatMessage(bottomBarMessages.continueToContent);
        }
        case 2: {
          return formatMessage(bottomBarMessages.reviewCampaign);
        }
        case 3: {
          if (isFromEmailValidated || loading) {
            return formatMessage(bottomBarMessages.sendCampaign);
          }

          return formatMessage(bottomBarMessages.verifyFromEmail);
        }
        default: {
          return formatMessage(bottomBarMessages.continue);
        }
      }
    },
    [formatMessage],
  );

  const getPageStep = useCallback(
    (page: "segment" | "content" | "review") => {
      if (upsellCampaignSplit) {
        return UPSELL_CAMPAIGNS_PAGE_TITLE_STEPS[page];
      } else {
        return CAMPAIGNS_PAGE_TITLE_STEPS[page];
      }
    },
    [upsellCampaignSplit],
  );
  const getBottomBarCTA = useMemo(
    () => (upsellCampaignSplit ? upsellBottomBarCta : getRegularBottomBarCTA),
    [upsellCampaignSplit, upsellBottomBarCta, getRegularBottomBarCTA],
  );

  const segmentPageOnBackPath = useCallback(
    ({
      campaignId,
      fromLanding,
    }: {
      campaignId?: string;
      fromLanding?: boolean;
    }) => {
      if (upsellCampaignSplit) {
        if (fromLanding) {
          return generatePath(CAMPAIGNS_LANDING_PAGE_PATH);
        }
        if (campaignId) {
          return generatePath(CAMPAIGNS_TEMPLATE_EDIT_PATH, { campaignId });
        } else {
          return generatePath(CAMPAIGNS_TEMPLATE_PATH);
        }
      } else {
        return generatePath(CAMPAIGNS_LANDING_PAGE_PATH);
      }
    },
    [upsellCampaignSplit],
  );

  return {
    upsellCampaignSplit,
    newCampaignNavigationURL,
    breadCrumbSteps,
    templateSelectedNext,
    getBottomBarCTA,
    getPageStep,
    segmentPageOnBackPath,
  };
}
