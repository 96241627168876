import { gql } from "@apollo/client";
import {
  CUSTOM_FIELD_CONFIGURATION_AREA,
  CUSTOM_FIELD_CONFIGURATION_DROPDOWN,
  CUSTOM_FIELD_CONFIGURATION_INTERFACE,
  CUSTOM_FIELD_CONFIGURATION_NODE,
  CUSTOM_FIELD_CONFIGURATION_NUMERIC,
  CUSTOM_FIELD_CONFIGURATION_TEXT,
  CUSTOM_FIELD_CONFIGURATION_TRUE_FALSE,
} from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";

export const TEXT_CREATE = gql`
  mutation CustomFieldConfigurationCreateText(
    $input: CustomFieldConfigurationCreateTextInput!
  ) {
    customFieldConfigurationCreateText(input: $input) {
      customFieldConfiguration {
        ...baseConfiguration
        ...textDetails
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_TEXT}
`;

export const CUSTOM_FIELD_EDIT = gql`
  mutation CustomFieldConfigurationEdit(
    $input: CustomFieldConfigurationEditInput!
    $customFieldConfigurationId: EncodedId!
  ) {
    customFieldConfigurationEdit(
      customFieldConfigurationId: $customFieldConfigurationId
      input: $input
    ) {
      customFieldConfiguration {
        ...customFieldConfigurationNode
      }
      userErrors {
        message
        path
      }
    }
  }

  ${CUSTOM_FIELD_CONFIGURATION_NODE}
`;

export const TRUE_FALSE_CREATE = gql`
  mutation CustomFieldConfigurationCreateTrueFalse(
    $input: CustomFieldConfigurationCreateTrueFalseInput!
  ) {
    customFieldConfigurationCreateTrueFalse(input: $input) {
      customFieldConfiguration {
        ...baseConfiguration
        ...trueFalseDetails
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_TRUE_FALSE}
`;

export const NUMERIC_CREATE = gql`
  mutation CustomFieldConfigurationCreateNumeric(
    $input: CustomFieldConfigurationCreateNumericInput!
  ) {
    customFieldConfigurationCreateNumeric(input: $input) {
      customFieldConfiguration {
        ...baseConfiguration
        ...numericDetails
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_NUMERIC}
`;

export const AREA_CREATE = gql`
  mutation CustomFieldConfigurationCreateArea(
    $input: CustomFieldConfigurationCreateAreaInput!
  ) {
    customFieldConfigurationCreateArea(input: $input) {
      customFieldConfiguration {
        ...baseConfiguration
        ...areaDetails
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_AREA}
`;

export const DROPDOWN_CREATE = gql`
  mutation CustomFieldConfigurationCreateDropdown(
    $input: CustomFieldConfigurationCreateDropdownInput!
  ) {
    customFieldConfigurationCreateDropdown(input: $input) {
      customFieldConfiguration {
        ...baseConfiguration
        ...dropdownDetails
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_DROPDOWN}
`;
