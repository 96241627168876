import { Button } from "@jobber/components/Button";
import type { IconNames } from "@jobber/components/Icon";
import React from "react";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";

export interface SuperSaveItem {
  label: string;
  icon: IconNames;
  value: string;
}

interface SuperSaveButtonProps {
  items?: SuperSaveItem[];
  onSubmit(superSaveValue: string): void;
}

export function SuperSaveButton(props: SuperSaveButtonProps) {
  const { items, onSubmit } = props;
  const [state] = usePaymentReducer();
  const isLoading = state.status === "inProgress";

  if (!items || !items.length) return <></>;
  return (
    <>
      {items.map(item => {
        const onClick = () => onSubmit(item.value);

        return (
          <Button
            key={item.value}
            label={item.label}
            onClick={onClick}
            loading={isLoading}
            type="secondary"
          />
        );
      })}
    </>
  );
}
