export function readDataFromLocalStorage(keys: string[]) {
  return keys.map(key => getLocalStorageJSON(key));
}

export function writeDataToLocalStorage(data: { [key: string]: unknown }[]) {
  data.map(({ key, value }: { key: string; value: unknown }) => {
    setLocalStorageJSON(key, value);
  });
}

export function getLocalStorageJSON(key: string) {
  const localStorageValue = localStorage.getItem(key);

  if (localStorageValue) {
    return JSON.parse(localStorageValue);
  }
}

export function setLocalStorageJSON(key: string, value: unknown) {
  localStorage.setItem(key, JSON.stringify(value));
}
