import React, { type MutableRefObject, useRef } from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/upgradePaths/ANALYTICS_EVENTS";
import { InlinePrompt } from "components/InlinePrompt";
import { CallToAction } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import {
  type CallToActionRef,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";

interface QuoteFollowUpsUpgradePromptProps {
  ctaName: string;
  trackingLocation: string;
}

export function QuoteFollowUpsUpgradePrompt({
  ctaName,
  trackingLocation,
}: QuoteFollowUpsUpgradePromptProps) {
  const quoteFollowUpCtaRef = useRef() as MutableRefObject<CallToActionRef>;
  const trackingClass = "js-quoteFollowUpUpgradePrompt";
  const title = "Win work faster with automatic quote follow-ups!";
  const primaryActionLabel = "Let's do it!";

  return (
    <APIProvider>
      <CallToAction ctaName={ctaName} ref={quoteFollowUpCtaRef}>
        <div className={`${trackingClass}`}>
          <InlinePrompt
            title={title}
            handlePrimaryAction={handlePrimaryAction}
            primaryActionLabel={primaryActionLabel}
            handleDismiss={dismissCTA(quoteFollowUpCtaRef)}
          />
        </div>
      </CallToAction>
    </APIProvider>
  );

  function handlePrimaryAction() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.quoteFollowUpsUpgrade.learnMore, {
      location: trackingLocation,
    });

    window.open(
      `${encodeURI("/accounts/billing_info/quote_follow_ups_upgrade")}`,
      "_blank",
      "noopener noreferrer",
    );
  }
}
