import React from "react";

export function useViewport() {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const resizeWindow = () => setInnerWidth(window.innerWidth);
    window.addEventListener("resize", resizeWindow);

    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return { innerWidth };
}
