import { defineMessages } from "react-intl";

export const messages = defineMessages({
  quickBooksSync: {
    id: "dashboard.components.qboLastSyncInsight.quickBooksSync",
    defaultMessage: "QuickBooks Sync",
    description: "The QuickBooks sync insights card",
  },
  quickBooksSyncDescription: {
    id: "dashboard.components.qboLastSyncInsight.quickBooksSyncDescription",
    defaultMessage:
      "It's been {lastSyncedDays} {lastSyncedDays, plural, one {day} other {days}} since QuickBooks was last synced.",
    description: "The description of last QuickBooks sync on the insights card",
  },
  openSync: {
    id: "dashboard.components.qboLastSyncInsight.openSync",
    defaultMessage: "Open sync",
    description: "The action button to open the latest sync",
  },
});
