import React from "react";

interface HiddenFieldProps {
  name?: string;
  value?: string | string[] | number;
}

export function HiddenField(props: HiddenFieldProps) {
  return (
    <>
      <input
        hidden={true}
        readOnly={true}
        name={props.name}
        value={props.value}
      />
    </>
  );
}
