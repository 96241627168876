import React from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import styles from "./PaymentsCard.module.css";
import { messages } from "./messages";

export interface JobberMoneyBalanceProps {
  balance?: number;
  lastPayoutAmount?: number;
  currencyUnit: string;
}

export function JobberMoneyBalance({
  balance,
  lastPayoutAmount,
  currencyUnit,
}: JobberMoneyBalanceProps) {
  const [jobberMoneyAccountStatementReportPath] = useUrls(
    "jobberMoneyAccountStatementReportPath",
  );
  const { formatMessage } = useIntl();

  return (
    <Content>
      <h5 className={styles.headerText}>
        {formatMessage(messages.jobberMoneyBalanceHeader)}
      </h5>
      <div className={styles.container}>
        <div className={styles.content}>
          <Heading level={2}>
            {balance
              ? currencyWithUnit(balance.toString(), currencyUnit).format()
              : "-"}
          </Heading>
          <div className={styles.flexRow}>
            <Text variation="subdued">
              {formatMessage(messages.lastPayout)}
              {lastPayoutAmount
                ? currencyWithUnit(
                    lastPayoutAmount.toString(),
                    currencyUnit,
                  ).format()
                : "-"}
            </Text>
            <span className="u-marginLeftSmallest">
              <tooltip-icon>{formatMessage(messages.toolTipText)}</tooltip-icon>
            </span>
          </div>
        </div>
        <div className={styles.action}>
          <Button
            type="secondary"
            size="small"
            label="View Report"
            url={jobberMoneyAccountStatementReportPath}
          />
        </div>
      </div>
    </Content>
  );
}
