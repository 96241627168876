import React from "react";
import classNames from "classnames";
import styles from "./Banner.module.css";

export const rowBreakpointsMap = {
  none: "",
  small: styles.rowBreakpointSmall,
  medium: styles.rowBreakpointMedium,
  large: styles.rowBreakpointLarge,
  extraLarge: styles.rowBreakpointExtraLarge,
  any: styles.rowBreakpointAny,
};

interface BannerProps extends React.PropsWithChildren {
  /*
   * The breakpoint at which the banner switches to row layout
   * @default "medium"
   */
  rowBreakpoint?: keyof typeof rowBreakpointsMap;

  visible?: boolean;
}

export function Banner({
  children,
  rowBreakpoint = "medium",
  visible = true,
}: BannerProps) {
  if (!visible) return null;

  return (
    <div
      className={classNames(styles.banner, rowBreakpointsMap[rowBreakpoint])}
    >
      {children}
    </div>
  );
}
