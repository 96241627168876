import { defineMessages } from "react-intl";

export const messages = defineMessages({
  lineItemsTableEmptyState: {
    id: "workOrders.jobCost.lineItemsTable.lineItemsTableEmptyState.lineItemsTableEmptyState",
    defaultMessage:
      "Stay on top of what needs to be done by adding products & services",
    description: "The message displayed when the line items table is empty",
  },
});
