import { assign, createMachine } from "xstate";
import { ArrivalWindowDialogEvents, ArrivalWindowDialogState } from "./types";

export const arrivalWindowDialogMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOklwBcBBAJxtwDd0AbAdQIgHsB3WAZQoBPZmADEAYQAyAeT4BRAPoBZaQBEqkgNoAGALqJQAB06xKuTvgMgAHogCsAZhIAmABwB2Z3YCM77Q4AWdw93ABoQQUR3ADZvEgBOOwDXeJj47T9vAIBfbPC0LDxCUnJqOkYWdnwuXgFhMT4ACWlWBXEAVT4AFWklAEkALUUuuSUABUkqEb4dfSQQY1MKc0t52wQAuzsSbTdtX2dUgIDtO3DIhDtdhNdoh214+PuA7ztc-IwcAmIyCEpaehMNgcHj8IQiUQ9ADiUMkinEcgAciMAEpyVSzKyLMwWKzrAC0zjcJAc8QCj2cDl8uyJDnOiDuAR2rgcWxSnmOh3eIAKX2Kv3+5SBVRqYPqkOkMLhCioYwmAE0FD0FAAxdpddpozHzbHLXFrRD47zRZwkaLReKubws7y29IBOkRRC27QkdzucnRd3kuzOfzxbm8oo-UoAirA6qguoQ9pjdQjBSqTVTPrSRHaowmHGrUDraKu80OdzebSuZz5h7aAL0hCE13OF5PP1E8kpV6Bz7B0iYACusAonFQuAAXmAlHBYOgYF0wKhDMx0BQ4BIZPJlGoNBmFlm9TmbM6Mk5kt5KelonY-Mca+XoiRji9-A2HubXB3Ct9u32B0PR+PYJPp1nedF2XKFpAUAAhKhxAAaSVcD0T6LoZRRFE+gANQ0BRWD6RFVBaPgFG6eU4S3XUVjxRBNlcElKT9Bwy2CVkwidBALVNB1NgeZ4HCLaJcjyEB8E4CA4CsIMPyxHcKINWtnBiEkyQpKk-CJSkayNDI3Xk-M-EcE8izfPkfkwZgTEgKSlhk3NnW8S03VtTYHGcXxG1cGtjwSV5PGLBxolueJvCMrsBTKQFKhBWpwTASzs0otjTRNEsYlcB4iVeM5WPJRL5L9WJAiLZJgo-Ehe37QcRzHCcpzAGc5wXJd4B1aT9RshB3CLEgtktdwklcMsrWca9zTvXj5N414AkOdwBOyIA */
  initial: "editArrivalWindowsStyle",
  schema: {
    context: {} as ArrivalWindowDialogState,
    events: {} as ArrivalWindowDialogEvents,
  },
  tsTypes: {} as import("./arrivalWindow.machine.typegen").Typegen0,
  predictableActionArguments: true,
  states: {
    closed: {},
    editArrivalWindowsStyle: {
      on: {
        CLOSE_MODAL: "closed",
        SHOW_REMINDER_PREVIEWS: "showReminderPreviews",
        TOGGLE_CENTERED: {
          actions: assign({
            centered: context => !context.centered,
          }),
        },
        TOGGLE_APPLY_TO_FUTURE: {
          actions: assign({
            applyToFuture: context => !context.applyToFuture,
          }),
        },
        UPDATE_DURATION: {
          actions: assign({
            defaultDuration: (_, event) => event.newDuration,
          }),
        },
      },
    },
    showReminderPreviews: {
      on: {
        CLOSE_MODAL: "closed",
        GO_BACK_TO_EDIT_ARRIVAL_WINDOWS_STYLE: "editArrivalWindowsStyle",
      },
    },
  },
});
