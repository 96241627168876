import { useMutation } from "@apollo/client";
import type {
  OnlineBookingServiceDisableMutation,
  OnlineBookingServicesQuery,
} from "~/utilities/API/graphql";
import { useAccountConfigurationContext } from "jobber/settings/selfServeBookings/components/AccountConfigurationContext";
import {
  SELF_SERVE_BOOKINGS_SERVICES_QUERY,
  SELF_SERVE_BOOKINGS_SERVICE_DISABLE_MUTATION,
} from "./graphql";

export function useServiceDisableMutation() {
  const { features } = useAccountConfigurationContext();
  const servicesQueryDirectives = {
    advancedQuotingEnabled: features.advancedQuotingEnabled,
    quoteMarginsEnabled: features.quoteMarginsEnabled,
  };

  const [disableService, { loading, error }] =
    useMutation<OnlineBookingServiceDisableMutation>(
      SELF_SERVE_BOOKINGS_SERVICE_DISABLE_MUTATION,
      {
        update(cache, { data }) {
          const cachedQuery = cache.readQuery<OnlineBookingServicesQuery>({
            query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
            variables: servicesQueryDirectives,
          });

          const filteredServices =
            cachedQuery?.onlineBookingServices.nodes.filter(
              service => service.id !== data?.data.disabledService?.id,
            );
          const onlineBookingServices = {
            nodes: filteredServices,
            totalCount: filteredServices?.length || 0,
            pageInfo: cachedQuery?.onlineBookingServices.pageInfo,
          };
          cache.writeQuery({
            query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
            variables: servicesQueryDirectives,
            data: {
              onlineBookingServices,
              onlineBookingConfiguration:
                cachedQuery?.onlineBookingConfiguration,
            },
          });
        },
      },
    );

  return {
    disableService,
    loading,
    error,
  };
}
