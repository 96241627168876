import React from "react";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components";
import { messages } from "./messages";

export interface UpsellAddonProps {
  onAddonSelect: () => void;
}

export function UpsellAddon(props: UpsellAddonProps) {
  const { onAddonSelect } = props;
  const { formatMessage } = useIntl();

  return (
    <Banner
      type="success"
      icon={"knot"}
      primaryAction={{
        label: formatMessage(messages.addAddon),
        ariaLabel: formatMessage(messages.addAddonAriaLabel),
        onClick: () => onAddonSelect(),
      }}
      dismissible={false}
    >
      {formatMessage(messages.upgradeMessage)}
    </Banner>
  );
}
