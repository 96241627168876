import { useEffect, useState } from "react";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";

export interface UsePaymentMethodsConfig {
  loadPaymentMethods(): Promise<CreditCard[]>;

  deletePaymentMethod?(id: string): Promise<void>;

  setDefaultPaymentMethod?(id: string): Promise<void>;
}

type AsyncPaymentMethodAction = (card: CreditCard) => Promise<void>;
type DeletePaymentMethod = AsyncPaymentMethodAction;
type SetDefaultPaymentMethod = AsyncPaymentMethodAction;
type AddPaymentMethod = (card: CreditCard) => void;
type StillLoading = undefined;
type ReturnType = [
  CreditCard[] | StillLoading,
  DeletePaymentMethod,
  SetDefaultPaymentMethod,
  AddPaymentMethod,
];

export function usePaymentMethods(config: UsePaymentMethodsConfig): ReturnType {
  const [creditCards, setCreditCards] = useState<CreditCard[]>();

  async function deletePaymentMethod(cardToDelete: CreditCard) {
    if (!config.deletePaymentMethod) return;

    const cardToDeleteId = cardToDelete.id;
    await config.deletePaymentMethod(cardToDeleteId);

    setCreditCards(
      cards => cards && cards.filter(card => card.id !== cardToDeleteId),
    );
  }

  async function setDefaultPaymentMethod(cardToBeDefault: CreditCard) {
    if (!config.setDefaultPaymentMethod) return;

    const cardToBeDefaultId = cardToBeDefault.id;
    await config.setDefaultPaymentMethod(cardToBeDefaultId);

    setCreditCards(
      cards =>
        cards &&
        cards.map(card => ({
          ...card,
          isDefault: card.id === cardToBeDefaultId,
        })),
    );
  }

  function addPaymentMethod(newCard: CreditCard) {
    setCreditCards(existingCards => {
      if (!existingCards) return existingCards;

      const updatedList = [newCard];
      const isNewCardDefault = newCard.isDefault;

      existingCards.forEach(existingCard => {
        if (existingCard.fingerprint === newCard.fingerprint) return;

        updatedList.push({
          ...existingCard,
          isDefault: isNewCardDefault ? false : existingCard.isDefault,
        });
      });

      return updatedList;
    });
  }

  useEffect(() => {
    let isSubscribed = true;

    function setResult(result: CreditCard[]) {
      if (isSubscribed) {
        setCreditCards(result);
      }
    }

    async function load() {
      try {
        const result = await config.loadPaymentMethods();
        setResult(result);
      } catch (e) {
        setResult([]);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load();

    return () => void (isSubscribed = false);
  }, [setCreditCards, config]);

  return [
    creditCards,
    deletePaymentMethod,
    setDefaultPaymentMethod,
    addPaymentMethod,
  ];
}
