import React, { type RefObject, useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Popover } from "@jobber/components/Popover";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";
import { useCtas } from "~/utilities/contexts/internal/useCtas";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type { CtaName } from "~/utilities/contexts/internal/CallToActionContext";
import { messages } from "./messages";

interface PromoPopoverProps {
  targetRef: RefObject<HTMLDivElement>;
}

export function PromoPopover({ targetRef }: PromoPopoverProps) {
  const ctaName: CtaName = "instant_payouts_eligible_prompt";
  const [{ hasDismissed: hasCtaDismissed }] = useCtas(ctaName);
  const [showPopover, setShowPopover] = useState(!hasCtaDismissed);
  const [learnMoreUrl] = useUrls("docsInstantPayoutsUrl");
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (showPopover) {
      CTAMarkSeen(ctaName);
    }
  }, [showPopover]);

  const onDismiss = () => {
    CTAMarkConverted(ctaName);
    setShowPopover(false);
  };

  return (
    <Popover
      attachTo={targetRef}
      open={showPopover}
      onRequestClose={onDismiss}
      preferredPlacement="top"
    >
      <Content>
        <section className="u-marginBottomSmallest">
          <Text>{formatMessage(messages.promoMessage)}</Text>
        </section>
        <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
          {formatMessage(messages.learnMore)}
        </a>
        <section className="u-marginTopSmallest">
          <Button
            variation="learning"
            size="small"
            label={formatMessage(messages.buttonLabel)}
            onClick={onDismiss}
          />
        </section>
      </Content>
    </Popover>
  );
}
