import React, { type Dispatch, type SetStateAction } from "react";
import {
  SetupGuidePanel,
  type SetupGuidePanelProps,
} from "jobber/dashboard/components/SetupGuide/components/SetupGuidePanel";
import type { SetupStepProps } from "jobber/dashboard/components/SetupGuide/SetupGuide";
import {
  type SetupGuideStep,
  SetupGuideStepStatus,
} from "~/utilities/API/graphql";
import { messages } from "jobber/connectToGrowTrial/components/ConnectToGrowOnboardingChecklist/messages";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { getPendingIndex } from "./getPendingIndex";
import type { SetupGuideEditResponseType } from "../types";

export function buildStartGrowTrialListItem(
  step: SetupGuideStep,
  steps: SetupGuideStep[],
  index: number,
  { userName }: { userName: string },
  firstLoad: boolean,
  setCurrentStepIndex: Dispatch<SetStateAction<number>>,
  handleSetupGuideEdit: SetupGuideEditResponseType,
  setFirstLoad: Dispatch<SetStateAction<boolean>>,
): SetupStepProps {
  const pendingIndex = getPendingIndex(steps, firstLoad);

  const getStartGrowTrial = () => {
    return {
      img: {
        src: "",
        altText: "",
      },
      content: {
        heading: "",
        buttonLabel: messages.startGrowTrialButton.defaultMessage,
        buttonOnPress: () => {
          if (firstLoad && handleSetupGuideEdit) {
            handleSetupGuideEdit({
              firstLoad: false,
            })
              .then(result => {
                setFirstLoad(result?.firstLoad || false);
                setCurrentStepIndex(getPendingIndex(steps, false));
                return;
              })
              .catch(() => {
                // do nothing.
              });
          }
          Amplitude.TRACK_EVENT("CTA Clicked", {
            name: "get_started",
            source: "connect_to_grow_trial_onboarding_checklist",
          });
          setCurrentStepIndex(pendingIndex);
          return;
        },
      },
    };
  };

  const panelHeading = `Welcome to Grow, ${userName}!`;

  return {
    listItem: {
      completed: step.status === SetupGuideStepStatus.INCOMPLETE ? false : true,
      id: index,
      title: messages.startGrowTrial.defaultMessage,
    },
    panel: renderPanel({
      ...getStartGrowTrial(),
      content: {
        ...getStartGrowTrial().content,
        heading: panelHeading,
      },
    }),
  };
}

function renderPanel(props: SetupGuidePanelProps): JSX.Element {
  return (
    <SetupGuidePanel
      img={props.img}
      content={props.content}
      startGrowTrialStep={true}
    />
  );
}
