import { gql } from "@apollo/client";

export const SEND_REQUEST_CARD_ON_FILE_MESSAGE = gql`
  mutation SendRequestCardOnFileMessage(
    $recipients: [String!]!
    $clientId: EncodedId!
  ) {
    sendRequestCardOnFileMessage(
      input: { recipients: $recipients, clientId: $clientId }
    ) {
      message
      userErrors {
        message
        path
      }
    }
  }
`;
