import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import type {
  ConversationSubscription,
  ConversationsQuery,
  ConversationsQueryVariables,
} from "~/utilities/API/graphql";
import {
  CONVERSATIONS_QUERY,
  CONVERSATION_SUBSCRIPTION,
} from "jobber/chat/components/ChatDrawer/NotificationList/NotificationList.graphql";

interface UseConversationListQueryProps {
  variables: ConversationsQueryVariables;
}

export function useConversationListQuery({
  variables,
}: UseConversationListQueryProps) {
  const { data, error, nextPage, loadingInitialContent } = useCollectionQuery<
    ConversationsQuery,
    ConversationSubscription
  >({
    query: CONVERSATIONS_QUERY,
    queryOptions: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      variables: variables,
    },
    getCollectionByPath(conversationsData) {
      return conversationsData?.conversations;
    },
    subscription: {
      document: CONVERSATION_SUBSCRIPTION,
      getNodeByPath(conversationData) {
        return conversationData?.conversation?.conversation;
      },
    },
  });

  const filteredData = data
    ? {
        ...data,
        conversations: {
          ...data.conversations,
          edges: data.conversations?.edges?.filter(
            conversation => !conversation?.node?.deletedAt,
          ),
        },
      }
    : data;

  return {
    data: filteredData,
    loadingInitialContent,
    error,
    nextPage,
  };
}
