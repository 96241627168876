import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import type { Name, User } from "~/utilities/API/graphql";
import { messages } from "./messages";
import { TeamAssignmentCardContent } from "./TeamAssignmentCardContent";

export type TeamAssignmentUser = Pick<User, "id"> & {
  name: Pick<Name, "full">;
} & {
  avatar: {
    initials: string;
    image?: { photoUrl: string } | undefined;
  };
};

export interface TeamAssignmentProps {
  setEmailAssignment?: (value: boolean) => void;
  emailAssignment?: boolean;
  selectedAssignees: string[];
  assignableUsers: TeamAssignmentUser[];
  setSelectedAssignees: (value: string[]) => void;
  loading: boolean;
  disabled?: boolean;
}

export function TeamAssignment(props: TeamAssignmentProps) {
  return (
    <Card
      header={{
        title: messages.team.defaultMessage,
      }}
    >
      <Content>
        <TeamAssignmentCardContent {...props} />
      </Content>
    </Card>
  );
}
