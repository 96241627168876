import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  reportTabName: {
    id: "reporting.report.tabName",
    defaultMessage: "{reportName} Report",
    description: "The name of the tab of the report.",
  },
  reportErrorMessage: {
    id: "reporting.report.reportErrorMessage",
    defaultMessage: "Something went wrong!",
    description: "Message when there is an error with the report",
  },
  defaultReportInfoPopoverHeader: {
    id: "reporting.report.defaultReportInfoPopoverHeader",
    defaultMessage: "About this report",
    description: "Default popover header for report info",
  },
  moreInfoIconAriaLabel: {
    id: "reporting.report.moreInfoIconAriaLabel",
    defaultMessage: "About report",
    description: "Aria label for more info icon",
  },
  exportSuccessReportBanner: {
    id: "reporting.report.exportSuccessReportBanner",
    defaultMessage:
      "You'll receive the report in your inbox in up to 15 minutes",
    description: "Success banner message when exporting a report",
  },
  emptyCellValue: {
    id: "reporting.report.emptyCellValue",
    defaultMessage: "-",
    description: "Value for empty cells",
  },
  dismissBannerLabel: {
    id: "reporting.report.dismissBannerLabel",
    defaultMessage: "Dismiss",
    description: "Label for dismiss banner",
  },
});
