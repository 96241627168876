import React, { useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { useMutation } from "@apollo/client";
import { Button } from "@jobber/components/Button";
import { IntlProvider } from "@translations/IntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type {
  EnrolSelfServeDowngradeSavePromoMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Intercom } from "utilities/chat";
import { messages } from "./messages";
import { ENROL_DOWNGRADE_SAVE_PROMO } from "./AcceptOfferMutation.graphql";
import styles from "./DowngradePromoModal.module.css";

export interface DowngradePromoModalProps {
  shouldShowModal: boolean;
  hideModal: (offerAccepted: boolean) => void;
  isAnnualBillingCycle: boolean;
  currentPlanTier: string;
}

function DowngradePromoModal({
  shouldShowModal,
  hideModal,
  isAnnualBillingCycle,
  currentPlanTier,
}: DowngradePromoModalProps) {
  const { formatMessage } = useIntl();
  const [eligible, setEligible] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [enrolDowngradeSavePromo] =
    useMutation<EnrolSelfServeDowngradeSavePromoMutation>(
      ENROL_DOWNGRADE_SAVE_PROMO,
    );

  return (
    <APIProvider>
      <Modal
        title={formatMessage(messages.downgradePromoModalTitle)}
        open={shouldShowModal}
        onRequestClose={handleModalDismissal}
        primaryAction={{
          label: formatMessage(messages.downgradePromoModalPrimaryCtaLabel),
          onClick: handlePrimaryCTAClick,
          loading: isLoading,
        }}
        secondaryAction={{
          label: formatMessage(messages.downgradePromoModalSecondaryCtaLabel),
          onClick: handleModalDismissal,
        }}
      >
        <Content>
          {showError && (
            <div className={styles.promoOfferErrorBanner}>
              <Banner type="error" dismissible={false}>
                {formatErrorMessage()}
              </Banner>
            </div>
          )}
          <div className={styles.promoOfferDescription}>
            <Text>
              {formatMessage(messages.downgradePromoModalDescription, {
                planName: currentPlanTier,
              })}
            </Text>
          </div>
          <div className={styles.promoOfferContainer}>
            <div className={styles.promoOfferAmount}>
              <Text>
                {isAnnualBillingCycle
                  ? formatMessage(
                      messages.downgradePromoModalOfferAnnualBillingAmount,
                      {
                        saveAmount: (
                          <Emphasis variation={"highlight"}>
                            {formatMessage(
                              messages.downgradePromoModalAnnualOfferPercentage,
                            )}
                          </Emphasis>
                        ),
                      },
                    )
                  : formatMessage(
                      messages.downgradePromoModalOfferMonthlyBillingAmount,
                      {
                        saveAmount: (
                          <Emphasis variation={"highlight"}>
                            {formatMessage(
                              messages.downgradePromoModalMonthlyOfferPercentage,
                            )}
                          </Emphasis>
                        ),
                      },
                    )}
              </Text>
            </div>
            <div className={styles.promoOfferDuration}>
              <Text>
                {isAnnualBillingCycle
                  ? formatMessage(
                      messages.downgradePromoModalOfferAnnualDuration,
                    )
                  : formatMessage(
                      messages.downgradePromoModalOfferMonthlyDuration,
                    )}
              </Text>
            </div>
          </div>
        </Content>
      </Modal>
    </APIProvider>
  );

  function handleModalDismissal() {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: "downgrade_promo_modal",
      name: "no_thanks_button",
    });
    hideModal(false);
  }

  async function handlePrimaryCTAClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "downgrade_promo_modal",
      name: "accept_offer_button",
    });

    setIsLoading(true);
    await updateEnrolmentState();
  }

  async function updateEnrolmentState() {
    try {
      const promotionEnrolmentData = await enrolDowngradeSavePromo();
      const errors = promotionEnrolmentData.data
        ?.enrolSelfServeDowngradeSavePromo.userErrors as MutationErrors[];

      if (errors.length === 0) {
        window.location.assign(
          formatMessage(
            messages.downgradePromoModalOfferEnrolmentSuccessRedirectPath,
          ),
        );
      } else {
        setShowError(true);
        setIsLoading(false);
        setEligible(errors[0].message.search(/ineligible/i) < 0);
      }
    } catch (e) {
      setShowError(true);
      setIsLoading(false);
    }
  }

  function formatErrorMessage() {
    if (eligible) {
      return formatMessage(
        messages.downgradePromoModalOfferEnrolmentFailedError,
        {
          contact: (
            <Button
              label={formatMessage(
                messages.downgradePromoModalOfferEnrolmentErrorContactUs,
              )}
              type="tertiary"
              onClick={openChatWidget}
            />
          ),
        },
      );
    }

    return formatMessage(
      messages.downgradePromoModalOfferEnrolmentIneligibleError,
      {
        contact: (
          <Button
            label={formatMessage(
              messages.downgradePromoModalOfferEnrolmentErrorContactUs,
            )}
            type="tertiary"
            onClick={openChatWidget}
          />
        ),
      },
    );
  }

  function openChatWidget() {
    Intercom.EXECUTE(
      "showNewMessage",
      formatMessage(
        messages.downgradePromoModalOfferEnrolmentErrorIntercomPrompt,
      ),
    );
  }
}

function WrappedDowngradePromoModal({
  shouldShowModal,
  hideModal,
  isAnnualBillingCycle,
  currentPlanTier,
}: DowngradePromoModalProps) {
  return (
    <IntlProvider>
      <DowngradePromoModal
        shouldShowModal={shouldShowModal}
        hideModal={hideModal}
        isAnnualBillingCycle={isAnnualBillingCycle}
        currentPlanTier={currentPlanTier}
      />
    </IntlProvider>
  );
}

export { WrappedDowngradePromoModal as DowngradePromoModal };
