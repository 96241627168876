import { gql } from "@apollo/client";

export const LOCATION_TIMERS_TOGGLE_FEATURE_MUTATION = gql`
  mutation LocationTimersEnablement($enabled: Boolean!) {
    toggleFeature(enabled: $enabled, key: "location_timers") {
      feature {
        id
        available
        enabled
      }
    }
  }
`;

export const LOCATION_TIMERS_SETTINGS_EDIT_MUTATION = gql`
  mutation LocationTimersSettingsEdit($input: LocationTimersSettingsInput!) {
    locationTimersSettingsEdit(input: $input) {
      locationTimersSettings {
        id
        mode
      }
    }
  }
`;
