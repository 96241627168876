import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./AnimatedStep.module.css";

export interface AnimatedStepProps {
  slideInContent: React.ReactNode;
  headerContent: React.ReactNode;
  onFinishedAnimation?(): void;
  onFadeOutStart?(): void;
  totalAnimationTime: number;
}

export function AnimatedStep({
  slideInContent,
  headerContent,
  onFinishedAnimation,
  onFadeOutStart,
  totalAnimationTime,
}: AnimatedStepProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  if (totalAnimationTime < 300) {
    throw new Error("Animation duration must be 300ms or greater");
  }

  useEffect(() => {
    const unMountTimeout = setTimeout(() => {
      setIsVisible(false);
      if (onFinishedAnimation) {
        onFinishedAnimation();
      }
    }, totalAnimationTime);
    const fadeOutTimeout = setTimeout(() => {
      setIsFadingOut(true);
      onFadeOutStart?.();
    }, totalAnimationTime - 300);

    return () => {
      clearTimeout(unMountTimeout);
      clearTimeout(fadeOutTimeout);
    };
  }, [setIsVisible, onFinishedAnimation, totalAnimationTime, onFadeOutStart]);

  return (
    <div
      className={classNames(styles.itemsContainer, {
        [styles.visible]: isVisible,
        [styles.fadeOut]: isFadingOut,
      })}
    >
      <div className={styles.slideInContent}>{slideInContent}</div>
      <div className={styles.headerContent}>{headerContent}</div>
    </div>
  );
}
