import { useCallback, useEffect, useState } from "react";
import {
  type AdditionalCriteriaUnion,
  SupportedCriteriaTypes,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";

export function useAddCriteriaButtonVisibility(
  additionalCriteria: AdditionalCriteriaUnion[],
) {
  const [buttonVisibility, setButtonVisibility] = useState(true);

  const criteriaNotSupported = useCallback(
    (criterion: AdditionalCriteriaUnion): boolean => {
      if (criterion.__typename) {
        return !Object.values(SupportedCriteriaTypes).includes(
          criterion.__typename,
        );
      }

      return true;
    },
    [],
  );

  useEffect(() => {
    const unselectedCriteriaExists = additionalCriteria.find(criterion =>
      criteriaNotSupported(criterion),
    );

    if (unselectedCriteriaExists) {
      setButtonVisibility(false);
    } else {
      setButtonVisibility(true);
    }
  }, [additionalCriteria, criteriaNotSupported, setButtonVisibility]);

  return {
    buttonVisibility,
  };
}
