import React from "react";
import styles from "./BareColumn.module.css";

interface BareColumnProps {
  child: React.ReactNode;
}

export function BareColumn({ child }: BareColumnProps) {
  return <div className={styles.container}>{child}</div>;
}
