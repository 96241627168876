import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import type {
  LinkVerificationDocumentsMutation,
  VerificationDocumentsInput,
} from "~/utilities/API/graphql";

export const LINK_VERIFICATION_DOCUMENTS = gql`
  mutation LinkVerificationDocuments($input: VerificationDocumentsInput!) {
    moneyManagementLinkVerificationDocuments(input: $input) {
      cardHolder {
        id
      }
      userErrors {
        message
      }
    }
  }
`;

interface UseLinkVerificationDocumentsReturn {
  handleLinkVerificationDocuments: (
    input: VerificationDocumentsInput,
  ) => Promise<unknown>;
  loading: boolean;
}

export function useLinkVerificationDocuments(): UseLinkVerificationDocumentsReturn {
  const [linkVerificationDocumentsMutation, { loading }] =
    useMutation<LinkVerificationDocumentsMutation>(LINK_VERIFICATION_DOCUMENTS);

  async function handleLinkVerificationDocuments(
    input: VerificationDocumentsInput,
  ) {
    const mutationPromise = () =>
      linkVerificationDocumentsMutation({
        variables: { input },
      });

    return new Promise((resolve, reject) => {
      mutationPromise()
        .then(({ data: result }) => {
          const errors =
            result?.moneyManagementLinkVerificationDocuments?.userErrors;
          if (errors && errors.length > 0) {
            const userErrors = errors.reduce(
              (acc, error) => ({ ...acc, errorMessage: error.message }),
              {},
            );
            reject(userErrors);
          } else {
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  return {
    handleLinkVerificationDocuments,
    loading,
  };
}
