import { useState } from "react";
import type { FileUpload } from "@jobber/components/InputFile";

export function useHeaderImage() {
  const [headerImage, setHeaderImage] = useState<FileUpload>();

  const handleUpload = (file: FileUpload) => {
    setHeaderImage(file);
  };

  const handleOnDeleteUpload = () => {
    setHeaderImage(undefined);
  };

  return { headerImage, handleUpload, handleOnDeleteUpload };
}
