import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { getIndustrySpecificContent } from "jobber/setupWizard/utils";
import { HeardAboutUsPageForm } from "./components/HeardAboutUsPageForm";
import { FeatureList } from "./components/FeatureList";
import { messages } from "./messages";

export function HeardAboutUsPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.heardAboutUs,
    });
  }, []);

  const { wizardData } = useContext(SetupWizardContext);
  const backgroundImage = getIndustrySpecificContent(wizardData.industry)
    .heardAboutUsPage.backgroundImage;

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.heardAboutUsPageHeading)}
          copy={formatMessage(messages.heardAboutUsPageCopy)}
          form={<HeardAboutUsPageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={backgroundImage}>
          <FeatureList />
        </MediaColumn>
      }
    />
  );
}
