import { gql } from "@apollo/client";

export const DISPUTE_CLOSED_SUBSCRIPTION = gql`
  subscription disputeClosed($disputeId: EncodedId!) {
    disputeClosed(disputeId: $disputeId) {
      userErrors {
        message
        path
      }
      dispute {
        amount
        category
        disputedAt
        submittedEvidence {
          serviceOccurrence
          customerName
          billingAddress
          productDescription
          customerEmailAddress
          refundRefusalExplanation
          supportingEvidence {
            nodes {
              category
              fileId
            }
          }
        }
        counterDisputeReason
        refundedAt
        resolutionReceivedAt
        evidenceDueBy
        id
        reason
        status
        paymentRecord {
          id
          adjustmentType
          amount
          entryDate
          client {
            id
            firstName
            lastName
          }
          invoice {
            id
            invoiceNumber
          }
          quote {
            id
            quoteNumber
          }
          ... on JobberPaymentsCreditCardPaymentRecord {
            nameOnCard
          }
        }
      }
    }
  }
`;

export const DISPUTE_UPDATED_SUBSCRIPTION = gql`
  subscription disputeUpdated($disputeId: EncodedId!) {
    disputeUpdated(disputeId: $disputeId) {
      userErrors {
        message
        path
      }
      dispute {
        amount
        category
        disputedAt
        submittedEvidence {
          serviceOccurrence
          customerName
          billingAddress
          productDescription
          customerEmailAddress
          refundRefusalExplanation
          supportingEvidence {
            nodes {
              category
              fileId
            }
          }
        }
        counterDisputeReason
        refundedAt
        responseSubmittedAt
        evidenceDueBy
        id
        reason
        status
        paymentRecord {
          id
          adjustmentType
          amount
          entryDate
          client {
            id
            firstName
            lastName
          }
          invoice {
            id
            invoiceNumber
          }
          quote {
            id
            quoteNumber
          }
          ... on JobberPaymentsCreditCardPaymentRecord {
            nameOnCard
          }
        }
      }
    }
  }
`;

export const DISPUTE_CLOSE_MUTATION = gql`
  mutation DisputeClose($id: EncodedId!) {
    disputeClose(id: $id) {
      status
      userErrors {
        message
      }
    }
  }
`;

export const DISPUTE_EDIT_MUTATION = gql`
  mutation DisputeEdit($id: EncodedId!, $input: DisputeEditInput!) {
    disputeEdit(id: $id, input: $input) {
      status
      userErrors {
        message
      }
    }
  }
`;
