import { defineMessages } from "react-intl";

export const messages = defineMessages({
  lineItemsTitle: {
    id: "workOrders.jobCost.lineItemsTable.lineItemsTitle",
    defaultMessage: "Line Items",
    description: "The line items table",
  },
  lineItemProductService: {
    id: "workOrders.jobCost.lineItemsTable.lineItemProductService",
    defaultMessage: "Product / Service",
    description: "The column for the line item product or service",
  },
  lineItemQuantity: {
    id: "workOrders.jobCost.lineItemsTable.lineItemQuantity",
    defaultMessage: "Quantity",
    description: "The column for the line item quantity",
  },
  lineItemCost: {
    id: "workOrders.jobCost.lineItemsTable.lineItemCost",
    defaultMessage: "Cost",
    description: "The column for the line item cost",
  },
  lineItemPrice: {
    id: "workOrders.jobCost.lineItemsTable.lineItemPrice",
    defaultMessage: "Price",
    description: "The column for the line item price",
  },
  lineItemNonTaxableLabel: {
    id: "workOrders.jobCost.lineItemsTable.lineItemNonTaxableLabel",
    defaultMessage: "Non-taxable",
    description: "Non-taxable label for line items",
  },
  lineItemPriceTooltip: {
    id: "workOrders.jobCost.lineItemsTable.lineItemPriceTooltip",
    defaultMessage: "Amount charged to client for line item",
    description: "The tooltip for the line item price",
  },
  lineItemTotal: {
    id: "workOrders.jobCost.lineItemsTable.lineItemTotal",
    defaultMessage: "Total",
    description: "The column for the line item total",
  },
});
