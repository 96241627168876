import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "downgradePage.downgradePromoAcceptedModal.heading",
    defaultMessage: "Great to have you back!",
    description: "Downgrade promo offer accepted modal heading",
  },
  firstParagraph: {
    id: "downgradePage.downgradePromoAcceptedModal.firstParagraph",
    defaultMessage:
      "We are glad that you gave the {planName} plan another chance. Your promotion has been applied at {promoAmount} off for {offerCopy} and will be reflected on your next billing cycle on {nextBillingDate}.",
    description: "Explanatory text",
  },
  offerCopyAnnual: {
    id: "downgradePage.downgradePromoAcceptedModal.offerCopyAnnual",
    defaultMessage: "one year",
    description: "Offer copy for annual plan",
  },
  offerCopyMonthlySingular: {
    id: "downgradePage.downgradePromoAcceptedModal.offerCopyMonthlySingular",
    defaultMessage: "the next month",
    description: "Offer copy for monthly plan",
  },
  offerCopyMonthlyPlural: {
    id: "downgradePage.downgradePromoAcceptedModal.offerCopyMonthlyPlural",
    defaultMessage: "the next {offerDuration} months",
    description: "Offer copy for monthly plan",
  },
  secondParagraph: {
    id: "downgradePage.downgradePromoAcceptedModal.secondParagraph",
    defaultMessage:
      "Meanwhile, to get the best value out of your plan, talk to our product experts to learn how to get the most out of the {planName} plan.",
    description: "Explanatory text",
  },
  talkToAProductExpert: {
    id: "downgradePage.downgradePromoAcceptedModal.talkToAProductExpert",
    defaultMessage: "Talk to a Product Expert",
    description: "Talk to a product expert button label",
  },
  talkToAProductExpertCalendlyLink: {
    id: "downgradePage.downgradePromoAcceptedModal.talkToAProductExpertCalendlyLink",
    defaultMessage:
      "https://calendly.com/billing-support/jobber-training-account-review",
    description: "Talk to a product expert Calendly link",
  },
  gotIt: {
    id: "downgradePage.downgradePromoAcceptedModal.gotIt",
    defaultMessage: "Got It",
    description: "Got it button label",
  },
  imgAlt: {
    id: "downgradePage.downgradePromoAcceptedModal.imgAlt",
    defaultMessage:
      "Alternative text for an image of two hands executing a high five",
    description: "Image alt text",
  },
});
