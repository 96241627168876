import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage } = createIntl();

// Get By Selectors

export const getShareReferralMessage = () => {
  return screen.getByText(messages.referralLinkShareMessage.defaultMessage);
};

export const getNoIncentiveReferralTitle = () => {
  return screen.getByText(messages.referralLinkNoIncentiveTitle.defaultMessage);
};

export const getWithIncentiveReferralTitle = (incentiveAmount: string) => {
  return screen.getByText(
    formatMessage(messages.referralLinkWithIncentiveTitle, { incentiveAmount }),
  );
};

// Query By Selectors

export const queryShareReferralMessage = () => {
  return screen.queryByText(messages.referralLinkShareMessage.defaultMessage);
};

// Actions
