import { useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import React from "react";
import { usePaymentDisputesPagination } from "jobber/features/PaymentDisputes/hooks/usePaymentDisputesPagination";
import { usePaymentDisputesSorting } from "jobber/features/PaymentDisputes/hooks/usePaymentDisputesSorting";
import { DisputeListPage } from "jobber/features/PaymentDisputes/views/DisputeListPage";
import { DEFAULT_SORT_KEY } from "jobber/features/PaymentDisputes/views/DisputeListPage/constants";
import { PAYMENT_DISPUTES_QUERY } from "jobber/features/PaymentDisputes/views/DisputeListPage/PaymentDisputes.graphql";
import type {
  JobberPaymentsDisputesSortKey,
  PaymentDisputesQueryQuery,
} from "~/utilities/API/graphql";

export const PaymentDisputesLoader = () => {
  const {
    pageState,
    resetPagination,
    getPaginationProps,
    getLastCursorOnPage,
  } = usePaymentDisputesPagination({});

  const { formatSort, sortingState, handleSortChange } =
    usePaymentDisputesSorting<JobberPaymentsDisputesSortKey>(
      DEFAULT_SORT_KEY,
      resetPagination,
    );

  const { loading, error, data } = useQuery<PaymentDisputesQueryQuery>(
    PAYMENT_DISPUTES_QUERY,
    {
      variables: {
        key: formatSort(sortingState).key,
        direction: formatSort(sortingState).direction,
        first: pageState.pageSize,
        after: getLastCursorOnPage(pageState),
      },
    },
  );

  if (error) {
    return (
      <Banner type={"error"} dismissible={false}>
        An error occurred while retrieving the disputes. Please try again later.
      </Banner>
    );
  }

  const totalCount: number | undefined = data
    ? data.jobberPaymentsDisputes?.totalCount
    : 0;

  return (
    <DisputeListPage
      disputes={data?.jobberPaymentsDisputes}
      loading={loading}
      paginationProps={getPaginationProps(totalCount)}
      sortingProps={{
        manualSorting: true,
        state: sortingState,
        onSortingChange: handleSortChange,
      }}
    />
  );
};
