import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import type { StripeCardElementChangeEvent, Token } from "@stripe/stripe-js";
import { Emphasis } from "@jobber/components/Emphasis";
import { Divider } from "@jobber/components/Divider";
import { SetupOrConfirmTwoFactor } from "jobber/settings/users/components/SetupOrConfirmTwoFactor";
import { useCreditCardDetails } from "jobber/payments_sca/components/CollectPaymentForm/CreditCardDetails";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { emptyToUndefined } from "jobber/payments_sca/utils/mapJobberToStripeAddress";
import {
  type ServerCreditCard,
  mapServerCreditCard,
} from "jobber/payments_sca/utils/mapServerCreditCard";
import { APIProvider } from "~/utilities/API/APIProvider";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import { SimpleAddPaymentMethodForm } from "~/components/AddPaymentMethodForm/SimpleAddPaymentMethodForm";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import { useUrls } from "~/utilities/contexts/internal/useUrls";

export interface AddDebitCardDialogProps {
  isDialogOpen: boolean;
  saveButtonLabel?: string;
  onRequestCloseDialog(): void;
  onDebitCardAdded?(creditCard: CreditCard): void;
  nameOnCard?: string;
  isOtpAuthenticated?: boolean;
}

export const AddDebitCardDialog =
  withStripeElementProvider<AddDebitCardDialogProps>(
    AddDebitCardDialogWithStripe,
  );

// eslint-disable-next-line max-statements
function AddDebitCardDialogWithStripe(props: AddDebitCardDialogProps) {
  const {
    nameOnCard = "",
    saveButtonLabel = "Save",
    onDebitCardAdded,
    isDialogOpen,
    onRequestCloseDialog,
    isOtpAuthenticated = false,
  } = props;

  const handleCardElementChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage("");
    }
  };

  const [
    authenticityToken,
    connectInstantPayoutDebitCardPath,
    countryCode,
    currencyCode,
  ] = useContexts();
  const creditCardDetailsProps = useCreditCardDetails({
    initialAddress: {},
    initialName: nameOnCard,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(isOtpAuthenticated);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <>
      <APIProvider>
        <SetupOrConfirmTwoFactor
          onSuccess={twoFactorGranted}
          isOpen={!authenticated && isDialogOpen}
          onClose={onRequestClose}
        />
      </APIProvider>
      <Modal
        title="Link a debit card"
        onRequestClose={onRequestClose}
        open={authenticated && isDialogOpen}
        primaryAction={{
          label: saveButtonLabel,
          onClick: onSubmit,
          loading: isLoading,
        }}
        secondaryAction={{ label: "Cancel", onClick: onRequestClose }}
      >
        <Content>
          <Text variation="subdued">
            Instant payouts require adding a supported debit card so you can
            receive payouts 24/7—even on weekends and holidays.
          </Text>
          <Text variation="subdued">
            <Emphasis variation="bold">
              You can do your first instant payout 48 hours after adding a card.
            </Emphasis>
          </Text>
          <Divider />
          <SimpleAddPaymentMethodForm
            nameOnCard={creditCardDetailsProps.name}
            isLoading={isLoading}
            cardErrorMessage={errorMessage}
            onCardElementChange={handleCardElementChange}
            onNameOnCardChange={creditCardDetailsProps.setName}
          />
        </Content>
      </Modal>
    </>
  );

  function twoFactorGranted() {
    setAuthenticated(true);
  }

  function onRequestClose() {
    onRequestCloseDialog();
    setAuthenticated(false);
    setErrorMessage("");
  }

  async function onSubmit() {
    startLoading();

    try {
      if (stripe && elements) {
        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
          const result = await stripe.createToken(cardElement, {
            name: emptyToUndefined(creditCardDetailsProps.name),
            currency: currencyCode,
            address_country: countryCode,
          });

          if (result.error) {
            throw result.error;
          }

          if (result.token) {
            const card = await connectWithToken(result.token);

            stopLoadingWithResult(card);
          }
        }
      }
    } catch (e) {
      stopLoadingWithError(e as Error);
    }
  }

  async function connectWithToken(token: Token) {
    if (token.card?.cvc_check === "fail") {
      throw new Error(
        "The card's security code is invalid. Check the card's security code or use a different card",
      );
    }

    const connectWithTokenResult = await fetch(
      connectInstantPayoutDebitCardPath,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          authenticity_token: authenticityToken,
          cardToken: token.id,
        }),
      },
    );
    const json = await connectWithTokenResult.json();

    if (connectWithTokenResult.ok) {
      const card = json as ServerCreditCard;
      return mapServerCreditCard(card);
    } else {
      throw new Error((json as { message: string }).message);
    }
  }

  function startLoading() {
    setErrorMessage("");
    setLoading(true);
  }

  function stopLoadingWithError(error: { message: string }) {
    setLoading(false);
    setErrorMessage(error.message);
  }

  function stopLoadingWithResult(card: CreditCard) {
    setLoading(false);

    onRequestClose();

    if (onDebitCardAdded) {
      onDebitCardAdded(card);
    }
  }
}

function useContexts() {
  const [authenticityToken] = useAuth();
  const [connectInstantPayoutDebitCardPath] = useUrls(
    "jobberPaymentsConnectInstantPayoutDebitCardPath",
  );
  const { countryCode, currencyCode } = useAccount();

  return [
    authenticityToken,
    connectInstantPayoutDebitCardPath,
    countryCode,
    currencyCode,
  ] as const;
}
