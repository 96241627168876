import { useEffect, useState } from "react";
import type { Option } from "@jobber/components/Autocomplete";
import type { AdditionalCriteriaOptions } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { GetClientTagLabelsQuery } from "~/utilities/API/graphql";

export function useClientTagOptions(
  resultData: GetClientTagLabelsQuery | undefined,
): AdditionalCriteriaOptions {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (resultData?.clientTagUniqueLabels?.labels) {
      const labels = resultData.clientTagUniqueLabels.labels;
      setOptions(
        labels.map((label, index) => ({
          value: index + 1,
          label: label,
        })),
      );
    }
  }, [resultData]);

  function getOptions(text: string) {
    if (text === "") {
      return options;
    }
    const filterRegex = new RegExp(text, "i");
    return options.filter(option => option.label.match(filterRegex));
  }

  return {
    options,
    optionsLoaded: options.length > 0,
    getOptions,
  };
}
