import React from "react";
import { gql } from "@apollo/client";
import type {
  ConversationDataFragment,
  ConversationsQuery,
} from "~/utilities/API/graphql";
import { ChatList } from "jobber/chat/components/ChatDrawer/ChatList";
import { ChatNotificationListRenderer } from "jobber/chat/components/ChatDrawer/NotificationList/ChatNotificationListItem";
import { sortConversationByLastSentAt } from "jobber/chat/utilities/sortBySentAt";

export const conversationData = gql`
  fragment conversationData on Conversation {
    id
    with {
      name
      id
      state
      phoneNumber {
        raw
        friendly
      }
    }
    messages(first: 1) {
      nodes {
        __typename
        id
        channel
        content
        timestamp
        ... on EmailMessage {
          toEmails
        }
        ... on TextMessage {
          toPhones
        }
      }
    }
    readFlag
    updatedTimestamp
    stopped
    deletedAt
  }
`;

interface NotificationListProps {
  showNotificationList: boolean;
  data: ConversationsQuery;
  getMoreConversations(): void;
}

export function NotificationList({
  getMoreConversations,
  data,
  showNotificationList,
}: NotificationListProps) {
  const nodes = (data.conversations.edges || []).map(
    edge => edge.node as ConversationDataFragment,
  );

  const sortedConversations = sortConversationByLastSentAt(nodes);

  const loadMore = data.conversations.pageInfo.hasNextPage
    ? getMoreConversations
    : undefined;
  return (
    <ChatList loadMore={loadMore} hideList={!showNotificationList}>
      <ChatNotificationListRenderer conversations={sortedConversations} />
    </ChatList>
  );
}
