import React from "react";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import type { IconNamesType, TableHeaderProps } from "./types";
import styles from "./TableHeader.module.css";

const iconNames: IconNamesType = {
  ascending: "longArrowUp",
  descending: "longArrowDown",
};

export const TableHeader = ({ sort, children, onClick }: TableHeaderProps) => {
  const headerClasses = classNames(styles.cell, sort ? styles.active : "");
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code. */}
      <div role="columnheader" className={headerClasses} onClick={onClick}>
        <Text>{children}</Text>
        {sort && <Icon name={iconNames[sort]} />}
      </div>
    </>
  );
};
