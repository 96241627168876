export const messages = {
  errorMessage: "Something went wrong. Please try again.",

  lineItemSaved: "Line item saved",
  lineItemDeleted: "Line item deleted",

  timeEntrySaved: "Time entry saved",
  timeEntryDeleted: "Time entry deleted",

  expenseSaved: "Expense saved",
  expenseDeleted: "Expense deleted",
};
