import { defineMessages } from "react-intl";

export const laggerStateMessages = defineMessages({
  header: {
    id: "ukKycLaggerState.header",
    defaultMessage: "Verification is required",
    description:
      "Header message for the lagger state of the UK KYC registration widget",
  },
  body: {
    id: "ukKycLaggerState.body",
    defaultMessage:
      "Regain access to your conversations and sending messages once verification is complete. The approval process may take up to three days.",
    description:
      "Body message for the lagger state of the UK KYC registration widget",
  },
  button: {
    id: "ukKycLaggerState.button",
    defaultMessage: "Verify",
    description:
      "CTA text for the button in the lagger state of the UK KYC registration widget",
  },
});
