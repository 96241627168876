import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { UpgradePrompt } from "jobber/billing/components/UpgradePrompts";
import { LiteToCoreTrialBeginModal } from "jobber/liteToCoreTrial/LiteToCoreTrialBeginModal";
import { APIProvider } from "~/utilities/API/APIProvider";

export function LiteToCoreTrialUpgradePrompt() {
  const [modalOpen, setModalOpen] = useState(false);

  function openLiteToCoreBeginTrialModal() {
    setModalOpen(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "lite_to_core_trial_upgrade_prompt",
    });
  }

  function closeLiteToCoreBeginTrialModal() {
    setModalOpen(false);
  }

  return (
    <APIProvider>
      <UpgradePrompt
        title="Free 14-Day Core Trial"
        content={LiteToCoreTrialUpgradePromptDescription()}
        ctaText="Learn More"
        handleCTAClick={openLiteToCoreBeginTrialModal}
      />
      <LiteToCoreTrialBeginModal
        source="upgrade_prompt"
        showModal={modalOpen}
        closeModal={closeLiteToCoreBeginTrialModal}
      />
    </APIProvider>
  );
}

function LiteToCoreTrialUpgradePromptDescription(): JSX.Element {
  return (
    <Content>
      <Text>
        Get{" "}
        <Emphasis variation="bold">
          free 14-day access to our Core plan
        </Emphasis>{" "}
        and start entering work into our scheduling feature to organize your
        business. You will automatically return to your current plan after the
        trial.
      </Text>
      <Text variation="subdued">
        Restart the Jobber app to access the trial on your phone.
      </Text>
    </Content>
  );
}
