import React, { useReducer, useState } from "react";
import classnames from "classnames";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import { InputNumber } from "@jobber/components/InputNumber";
import type { ArrivalWindow } from "jobber/google_lsa/MerchantConfiguration";
import {
  type Action,
  arrivalWindowReducer,
  initReducer,
} from "./ArrivalWindowReducer";
import { ArrivalWindowRow } from "./ArrivalWindowRow";
import {
  ValidationBanner,
  type ValidationError,
  dayOfWeekValidation,
  overlappingWindowsValidation,
  startTimeBeforeEndTimeValidation,
} from "./ArrivalWindowsValidation";
import styles from "./EditArrivalWindows.module.css";

interface ArrivalWindowsProps {
  windows: ArrivalWindow[];
  dispatch: React.Dispatch<React.SetStateAction<Action>>;
}

function ArrivalWindows({ windows, dispatch }: ArrivalWindowsProps) {
  function onChange(index: number) {
    return (newValue: ArrivalWindow) => {
      dispatch({
        type: "updateRow",
        rowIndex: index,
        data: newValue,
      });
    };
  }

  const handleRemoveRow = (index: number) => {
    return () => {
      dispatch({
        type: "removeRow",
        rowIndex: index,
      });
    };
  };

  return (
    <>
      {windows.map((window, index) => {
        return (
          <ArrivalWindowRow
            key={index}
            window={window}
            onRemove={index === 0 ? undefined : handleRemoveRow(index)}
            onChange={onChange(index)}
          />
        );
      })}
    </>
  );
}

interface EditArrivalWindowsProps {
  initialWindows: ArrivalWindow[];
  initialBookingBufferHours: number;
  onSaveWindows(windows: ArrivalWindow[]): Promise<void>;
  onSaveBookingBuffer(bookingBufferHours: number): Promise<void>;
}

// eslint-disable-next-line max-statements
export function EditArrivalWindows({
  initialWindows,
  initialBookingBufferHours,
  onSaveWindows,
  onSaveBookingBuffer,
}: EditArrivalWindowsProps) {
  const [windows, dispatch] = useReducer(
    arrivalWindowReducer,
    initialWindows,
    initReducer,
  );

  const [editArrivalWindowsOpen, setEditArrivalWindowsOpen] = useState(false);
  const [bookingBufferHours, setBookingBufferHours] = useState(
    initialBookingBufferHours,
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [inputValidationErrors, setWindowValidation] = useState<
    ValidationError[]
  >([]);

  const openEditArrivalWindows = () => {
    dispatch({
      type: "reset",
      data: initialWindows,
    });
    setEditArrivalWindowsOpen(true);
  };

  const closeEditArrivalWindows = () => {
    setEditArrivalWindowsOpen(false);
    setWindowValidation([]);
  };

  const shouldSaveArrivalWindows = () => {
    const errors = [
      ...startTimeBeforeEndTimeValidation(windows),
      ...dayOfWeekValidation(windows),
      ...overlappingWindowsValidation(windows),
    ];
    setWindowValidation(errors);

    return errors.length === 0;
  };

  const saveArrivalWindows = () => {
    if (shouldSaveArrivalWindows()) {
      setSaveLoading(true);
      Promise.all([
        onSaveWindows(windows),
        onSaveBookingBuffer(bookingBufferHours),
      ]).then(
        () => {
          showToast({
            message: "Availability settings saved",
            variation: "success",
          });
          setSaveLoading(false);
          setEditArrivalWindowsOpen(false);
        },
        () => {
          showToast({
            message:
              "A problem occurred when saving your availability settings",
            variation: "error",
          });
          setSaveLoading(false);
        },
      );
    }
  };

  const addRowAction = () => {
    dispatch({
      type: "addRow",
    });
  };

  const cancelArrivalWindowsEdit = () => {
    dispatch({
      type: "reset",
      data: initialWindows,
    });
    closeEditArrivalWindows();
  };

  const onClose = () => {
    dispatch({
      type: "reset",
      data: initialWindows,
    });
    closeEditArrivalWindows();
  };

  const onChangeBookingBufferHours = (newValue: number) => {
    setBookingBufferHours(newValue);
  };

  return (
    <>
      <Button label="Edit" type="tertiary" onClick={openEditArrivalWindows} />

      <Modal
        title="Edit Your Availability"
        size="large"
        open={editArrivalWindowsOpen}
        onRequestClose={onClose}
        primaryAction={{
          label: "Save",
          onClick: saveArrivalWindows,
          loading: saveLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: cancelArrivalWindowsEdit,
        }}
      >
        <Content spacing="large">
          <Content spacing="small">
            <Heading level={4}>Minimum Lead Time</Heading>
            <Text>
              Accept online bookings as early as{" "}
              <InputNumber
                value={bookingBufferHours}
                size={"small"}
                min={0}
                maxLength={3}
                inline={true}
                onChange={onChangeBookingBufferHours}
              />{" "}
              hour(s) in advance of the appointment.
            </Text>
          </Content>
          <Content spacing="small">
            <Heading level={4}>Arrival Windows</Heading>
            <Text>
              A two hour arrival window is recommended to incorporate drive time
            </Text>
          </Content>
          <ValidationBanner errors={inputValidationErrors} />
          <Content spacing="small">
            <div className={styles.editArrivalWindows}>
              <EditArrivalWindowsHeader />
              <ArrivalWindows windows={windows} dispatch={dispatch} />
            </div>
            <Button
              label="Add Arrival Window"
              type="tertiary"
              onClick={addRowAction}
            />
          </Content>
        </Content>
      </Modal>
    </>
  );
}

function EditArrivalWindowsHeader() {
  return (
    <>
      <div
        className={classnames(
          styles["editArrivalWindows--header"],
          styles.editArrivalWindows__range,
        )}
      >
        <Heading level={5}>Arrival Windows</Heading>
      </div>
      <div
        className={classnames(
          styles["editArrivalWindows--header"],
          styles.editArrivalWindows__daysOfWeek,
        )}
      >
        <Heading level={5}>Sun</Heading>
        <Heading level={5}>Mon</Heading>
        <Heading level={5}>Tue</Heading>
        <Heading level={5}>Wed</Heading>
        <Heading level={5}>Thu</Heading>
        <Heading level={5}>Fri</Heading>
        <Heading level={5}>Sat</Heading>
      </div>
    </>
  );
}
