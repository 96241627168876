import { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Checkbox } from "@jobber/components/Checkbox";
import { Content } from "@jobber/components/Content";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import BillingAddressDisplay from "legacy/jobber/payments_sca/components/BillingAddress/BillingAddressDisplay";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { BillingAddressFields } from "legacy/jobber/payments_sca/components/BillingAddress/BillingAddressFields";
import { CardDetailsForm } from "./CardDetailsForm";
import { SecurityDisclosure } from "./SecurityDisclosure";

export interface AddPaymentMethodFormProps {
  nameOnCard: string;
  isLoading: boolean;
  cardErrorMessage: string | undefined;
  setAsDefaultChecked?: boolean;
  billingAddress: BillingAddress | undefined;
  countryCodesToNameMap: { [key: string]: string };
  hideSetDefaultCheckbox: boolean;
  showSecurityDisclosure?: boolean;
  onSetAsDefaultChange?(newValue: boolean): void;
  onCardElementChange(event: StripeCardElementChangeEvent): void;
  onNameOnCardChange(name: string): void;
  onBillingAddressChange(address: BillingAddress): void;
}

export function AddPaymentMethodForm(props: AddPaymentMethodFormProps) {
  const {
    nameOnCard,
    isLoading,
    cardErrorMessage,
    billingAddress,
    countryCodesToNameMap,
    setAsDefaultChecked,
    hideSetDefaultCheckbox,
    showSecurityDisclosure = true,
    onSetAsDefaultChange,
    onCardElementChange,
    onNameOnCardChange,
    onBillingAddressChange,
  } = props;

  const [isEditingBillingAddress, setIsEditingBillingAddress] = useState(
    isBillingAddressEmpty,
  );
  return (
    <Content>
      <Heading level={5}>Card details</Heading>
      <CardDetailsForm
        cardErrorMessage={cardErrorMessage}
        isLoading={isLoading}
        nameOnCard={nameOnCard}
        onCardElementChange={onCardElementChange}
        onNameOnCardChange={onNameOnCardChange}
      />

      {!hideSetDefaultCheckbox && (
        <Checkbox
          label="Set as default payment method"
          value="default_method"
          checked={setAsDefaultChecked}
          onChange={onSetAsDefaultChange}
          disabled={isLoading}
        />
      )}
      <Heading level={5}>Billing Address</Heading>
      {isEditingBillingAddress ? (
        <BillingAddressFields
          countryCodesToNameMap={countryCodesToNameMap}
          address={billingAddress}
          onAddressChange={onBillingAddressChange}
        />
      ) : (
        <BillingAddressDisplay
          {...billingAddress}
          onChangeButtonClick={onChangeBillingAddressButtonClick}
        />
      )}
      {showSecurityDisclosure && (
        <div>
          <SecurityDisclosure />
        </div>
      )}
    </Content>
  );

  function onChangeBillingAddressButtonClick() {
    Amplitude.TRACK_EVENT("Clicked Update Address", {
      app: "Client Hub",
      source: "Storing a card",
      type: "billing",
    });
    setIsEditingBillingAddress(true);
  }

  function isBillingAddressEmpty() {
    return (
      !billingAddress?.street1 ||
      !billingAddress?.city ||
      !billingAddress?.province ||
      !billingAddress?.pc ||
      !billingAddress?.country_code
    );
  }
}
