import React, { useEffect, useState } from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Form } from "@jobber/components/Form";
import { useFormState } from "@jobber/hooks/useFormState";
import { useIntl } from "react-intl";
import type {
  AccountType,
  BankAccountFormState,
} from "jobber/AddPaymentMethod/AddBankAccount/types";
import { messages } from "./messages";
import { ManualEntryBankAccountFields } from "../ManualEntryBankAccountFields";
import type { AddPaymentMethodBankAccountData } from "../AddPaymentMethod";

interface AddBankAccountProps {
  bankData: AddPaymentMethodBankAccountData;
  onBankAccountFormChange: (formState: BankAccountFormState) => void;
}

export function AddBankAccount({
  bankData,
  onBankAccountFormChange,
}: AddBankAccountProps) {
  const [formData, setFormData] = useState(bankData);
  const [{ isDirty, isValid }, setFormState] = useFormState();

  const { formatMessage } = useIntl();

  const updateForm = (newData: Partial<AddPaymentMethodBankAccountData>) => {
    const newFormData = { ...formData, ...newData };
    setFormData(newFormData);
  };

  useEffect(() => {
    onBankAccountFormChange({
      ...formData,
      isDirty,
      isValid,
    });
  }, [formData, isDirty, isValid, onBankAccountFormChange]);

  const handleInputChange =
    (name: string) => (newValue: string | number | boolean) => {
      updateForm({ [name]: newValue });
    };

  const handleSelectChange = (value: AccountType) => {
    updateForm({ accountType: value });
  };

  const handleCheckboxChange = () => {
    updateForm({ authorizedByCustomer: !formData.authorizedByCustomer });
  };

  return (
    <Form onStateChange={setFormState}>
      <Content>
        <ManualEntryBankAccountFields
          formData={formData}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
        />
        <Content>
          <Checkbox
            checked={formData.authorizedByCustomer}
            onChange={handleCheckboxChange}
          >
            <Text>{formatMessage(messages.achAuthorizationDisclaimer)}</Text>
          </Checkbox>
        </Content>
      </Content>
    </Form>
  );
}
