import { gql } from "@apollo/client";
import { notificationFieldsFragment } from "jobber/settings/notifications/notificationFieldsFragment";

export const COMMS_SETTINGS_PAGE_QUERY = gql`
  query CommsSettingsPageData {
    quoteFollowup: clientNotification(
      id: QUOTE_FOLLOWUP
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    visitReminder: clientNotification(
      id: VISIT_REMINDER
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    assessmentReminder: clientNotification(
      id: ASSESSMENT_REMINDER
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    invoiceFollowup: clientNotification(
      id: INVOICE_FOLLOWUP
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    jobFollowup: clientNotification(
      id: JOB_FOLLOWUP
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    quoteSent: clientNotification(
      id: QUOTE_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    invoiceSent: clientNotification(
      id: INVOICE_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    chemicalTreatmentSent: clientNotification(
      id: CHEMICAL_TREATMENT_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    requestSubmitted: clientNotification(
      id: REQUEST_SUBMITTED
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    signedDocSent: clientNotification(
      id: SIGNED_DOC_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    statementSent: clientNotification(
      id: STATEMENT_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    balanceAdjustmentReceiptSent: clientNotification(
      id: BALANCE_ADJUSTMENT_RECEIPT_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    jobFormSent: clientNotification(
      id: JOB_FORM_SENT
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    quoteApproved: clientNotification(
      id: QUOTE_APPROVED
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    requestCardOnFile: clientNotification(
      id: REQUEST_CARD_ON_FILE
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    jobBookingConfirmation: clientNotification(
      id: JOB_BOOKING_CONFIRMATION
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
    assessmentBooked: clientNotification(
      id: ASSESSMENT_BOOKED
      includeUpgradeFeatures: true
    ) {
      ...notificationFields
    }
  }

  ${notificationFieldsFragment}
`;
