import type { ColumnDef } from "@jobber/components/DataTable";
import { camelCase } from "lodash";
import type {
  ExportSelectionConfiguration,
  ReportColumn,
  ReportColumns,
  ReportCustomFields,
  ReportFormFields,
} from "~/jobber/features/Reporting/components/Report/types";
import type { VisibilityConfigType } from "~/jobber/features/Reporting/components/Report/components/ReportColumnSelect/types";
import { isReportColumn } from "./utils/isReportColumn";

export function useFormatColumns() {
  return {
    formatColumnsForDataTable,
    formatColumnsForColumnSelection,
    formatColumnsForExport,
  };
}

function formatColumnsForDataTable<T>(
  columns: ReportColumns<T>,
): ColumnDef<T>[] {
  // TODO JOB-70613: Remove the check for isReportColumn<T>(column)
  if (!isReportColumn<T>(columns[0])) return columns;

  // TODO JOB-70613: Remove type assertion
  // Type assertion only needed to handle the different types until we can migrate the franchise reports to the new data type
  const visibleColumns: ReportColumn<T>[] = (
    columns as ReportColumn<T>[]
  ).filter(column => column.visible);

  return visibleColumns.map(column => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { visible, label, available, ...dataTableAttributes } = column;

    return dataTableAttributes;
  });
}

function formatColumnsForColumnSelection<T>(
  columnDef: ReportColumn<T>[],
): VisibilityConfigType[] {
  const visibilityConfig = columnDef.map((column: ReportColumn<T>) => ({
    id: column.id ? column.id : "",
    label: column.label,
    checked: column.visible,
  }));

  return visibilityConfig;
}

function formatColumnsForExport(
  visibleColumnsConfiguration: VisibilityConfigType[],
  useSelectedColumns = false,
  customFields?: ReportCustomFields,
  formFields?: ReportFormFields,
): ExportSelectionConfiguration {
  const customFieldIds = customFields?.map(header => header.id) || [];
  const formFieldLabels = formFields?.map(field => field.label) || [];

  // column selection configuration to be passed to the mutation
  const generatedColumnSelectionConfig: ExportSelectionConfiguration = {};

  // generate column selection configuration using visibleColumnsConfiguration
  visibleColumnsConfiguration.map(column => {
    generatedColumnSelectionConfig[column.id] = useSelectedColumns
      ? column.checked
      : true;
  });
  const {
    customFieldsColumnVisibilityConfig,
    formFieldsColumnVisibilityConfig,
  } = parseCustomSelection(
    visibleColumnsConfiguration,
    useSelectedColumns,
    customFieldIds,
    formFieldLabels,
  );
  if (customFieldsColumnVisibilityConfig.length > 0) {
    generatedColumnSelectionConfig.customFields =
      customFieldsColumnVisibilityConfig;
  }
  if (formFieldsColumnVisibilityConfig.length > 0) {
    generatedColumnSelectionConfig.formFields =
      formFieldsColumnVisibilityConfig;
  }
  return Object.keys(generatedColumnSelectionConfig).reduce(
    (generatedConfiguration: ExportSelectionConfiguration, key: string) => {
      if (!customFieldIds.includes(key) && !formFieldLabels.includes(key)) {
        generatedConfiguration[camelCase(key)] =
          generatedColumnSelectionConfig[key];
      }
      return generatedConfiguration;
    },
    {},
  );
}

function parseCustomSelection(
  columnConfig: VisibilityConfigType[],
  useSelectedColumns = false,
  customFieldIds?: string[],
  formFieldLabels?: string[],
) {
  const customFieldsColumnVisibilityConfig = columnConfig
    .filter(column => customFieldIds?.includes(column.id))
    .map(column => ({
      id: column.id,
      selected: useSelectedColumns ? column.checked : true,
    }));

  const formFieldsColumnVisibilityConfig = columnConfig
    .filter(column => formFieldLabels?.includes(column.label))
    .map(column => ({
      label: column.label,
      selected: useSelectedColumns ? column.checked : true,
    }));

  return {
    customFieldsColumnVisibilityConfig,
    formFieldsColumnVisibilityConfig,
  };
}
