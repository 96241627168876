import { type ApolloError, useLazyQuery } from "@apollo/client";
import type { Option } from "@jobber/components/Autocomplete";
import { useEffect } from "react";
import type { FranchiseeNameAutoCompleteQuery } from "~/utilities/API/graphqlFranchiseManagement";
import { FRANCHISEE_NAME_AUTOCOMPLETE_QUERY } from "./franchiseeNameAutoCompleteData.graphql";

interface UseFranchiseeNameAutoCompleteDataReturn {
  data?: FranchiseeNameAutoCompleteQuery;
  options?: Option[];
  loading: boolean;
  error?: ApolloError;
  handleAutoCompleteChange(newInputText: string): {
    value: string;
    label: string;
  }[];
  fetchAutoCompleteData(): void;
}

const formatAutoCompleteOptions = (data?: FranchiseeNameAutoCompleteQuery) => {
  return data?.accounts.nodes.map(account => ({
    value: account.id,
    label: account.name,
  }));
};

export const useFranchiseeNameAutoCompleteData =
  (): UseFranchiseeNameAutoCompleteDataReturn => {
    const [fetchAutoCompleteData, { data, loading, error, fetchMore }] =
      useLazyQuery<FranchiseeNameAutoCompleteQuery>(
        FRANCHISEE_NAME_AUTOCOMPLETE_QUERY,
      );

    const fetchMoreData = () => {
      return fetchMore({
        variables: {
          after: data?.accounts.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const previousData = previousResult.accounts;
          const newData = fetchMoreResult.accounts;

          return newData.nodes
            ? {
                accounts: {
                  __typename: previousData.__typename,
                  nodes: [...previousData.nodes, ...newData.nodes],
                  totalCount: previousData.totalCount,
                  pageInfo: newData.pageInfo,
                },
              }
            : previousResult;
        },
      });
    };

    useEffect(() => {
      if (!loading && data?.accounts.pageInfo.hasNextPage) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchMoreData();
      }
    }, [loading, data]);

    const options = formatAutoCompleteOptions(data) || [];

    const handleAutoCompleteChange = (newInputText: string) => {
      const cleanPattern = /[^a-zA-Z0-9\s]/g;
      const searchTerm = newInputText.replace(cleanPattern, "").toLowerCase();
      return options?.filter((option: Option) =>
        option.label
          .replace(cleanPattern, "")
          .toLowerCase()
          .includes(searchTerm),
      );
    };

    return {
      fetchAutoCompleteData,
      data,
      loading,
      error,
      handleAutoCompleteChange,
      options,
    };
  };
