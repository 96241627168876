import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deleteWorkItemConfirmationTitle: {
    id: "workItemModal.deleteWorkItemConfirmation.title",
    defaultMessage: "Delete {workItemName}?",
    description: "Title for confirmation when deleting a particular work item",
  },
  deleteWorkItemConfirmationMessage: {
    id: "workItemModal.deleteWorkItemConfirmation.message",
    defaultMessage:
      "Deleting {workItemName} will permanently remove it from Jobber",
    description: "Message for the confirm modal ",
  },
  deleteWorkItemConfirmationLabel: {
    id: "workItemModal.deleteWorkItemConfirmation.confirmLabel",
    defaultMessage: "Delete",
    description: "Label for the confirm delete button",
  },
  deleteWorkItemConfirmationOnlineBookingCondition: {
    id: "workItemModal.deleteWorkItemConfirmation.onlineBookingCondition",
    defaultMessage: " and online booking",
    description:
      "Message suffix when onlineBookingsEnabled is true at time of delete",
  },
  errorFixValidationsBeforeSaving: {
    id: "workItemModal.errors.fixValidationsBeforeSaving",
    defaultMessage: "Please fix validation errors before saving.",
    description: "Error message when validations fail",
  },
  errorCannotDeleteUnsavedWorkItem: {
    id: "workItemModal.errors.cannotDeleteUnsavedWorkItem",
    defaultMessage: "Cannot delete unsaved Products or Services",
    description: "Error message when trying to delete unsaved work item",
  },
  errorCouldNotUpdateWorkItem: {
    id: "workItemModal.errors.couldNotUpdateWorkItem",
    defaultMessage: "Could not update item",
    description: "Error message when update fails",
  },
  errorCouldNotCreateWorkItem: {
    id: "workItemModal.errors.couldNotCreateWorkItem",
    defaultMessage: "Could not create work item",
    description: "Error message when create fails",
  },
  updateWorkItemLabel: {
    id: "workItemModal.actionLabel.update",
    defaultMessage: "Update",
    description: "Label for the update button",
  },
  updateWorkItemAriaLabel: {
    id: "workItemModal.ariaLabel.update",
    defaultMessage: "Update Product or Service",
    description: "Aria label for the update button",
  },
  workItemModalCancelLabel: {
    id: "workItemModal.actionLabel.cancelLabel",
    defaultMessage: "Cancel",
    description: "Label for the cancel button",
  },
  workItemDeleteLabel: {
    id: "workItemModal.actionLabel.deleteLabel",
    defaultMessage: "Delete",
    description: "Label for the delete button",
  },
  workItemDeleteAriaLabel: {
    id: "workItemModal.ariaLabel.delete",
    defaultMessage: "Delete Product or Service",
    description: "Aria label for the delete button",
  },
  workItemCreateLabel: {
    id: "workItemModal.actionLabel.create",
    defaultMessage: "Create",
    description: "Label for the create button",
  },
  workItemCreateAriaLabel: {
    id: "workItemModal.ariaLabel.create",
    defaultMessage: "Create Work Item Button",
    description: "Aria label for the create button",
  },
  workItemCancelAriaLabel: {
    id: "workItemModal.ariaLabel.cancel",
    defaultMessage: "Cancel Add to Products And Services",
    description: "Aria label for the cancel button",
  },
});
