import { Amplitude } from "~/utilities/analytics/Amplitude";

export enum EventTypes {
  Link = "link",
  Tooltip = "tooltip",
  Modal = "modal",
}

interface trackEventWithAmplitudeProps {
  eventType: EventTypes;
  eventSource: string;
}

export function trackEventWithAmplitude({
  eventType,
  eventSource,
}: trackEventWithAmplitudeProps) {
  const eventProperties = {
    app: "online",
    type: eventType,
    source: eventSource,
  };

  Amplitude.TRACK_EVENT("Interacted with Data Visualization", eventProperties);
}
