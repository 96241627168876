import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { jobberOnline } from "components/JobberOnline/jobberOnline";

/*
Although it is a violation of HOC concepts, but we are still "enhancing" the
wrapped component with Stripe Elements capabilities.
*/

export function withStripeElementProvider<WrappedProps extends object>(
  WrappedComponent: React.FC<WrappedProps>,
  stripeKeyOverride?: string,
) {
  const stripeKey = stripeKeyOverride ?? jobberOnline.constants.stripeKey;

  function WithStripeComponent({ ...rest }: WrappedProps) {
    const [stripePromise] = useState(async () => loadStripe(stripeKey));

    return (
      <Elements stripe={stripePromise}>
        <WrappedComponent {...(rest as WrappedProps)} />
      </Elements>
    );
  }
  return WithStripeComponent;
}
