import React from "react";
import { Button, Content } from "@jobber/components";
import { useIntl } from "react-intl";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";
import { ClientBalances } from "./components/ClientBalances";
import { AverageDaysToGettingPaid } from "./components/AverageDaysToGettingPaid";
import { PercentageOfUpcomingJobsOnAutopay } from "./components/PercentageOfUpcomingJobsOnAutopay";
import { EventTypes, trackEventWithAmplitude } from "../../utils";

export interface ReceivablesProps {
  totalBalance?: number;
  numberOfClientsWithBalances?: number;
  averageDaysToGettingPaid?: number;
  percentageOfUpcomingJobsOnAutopay?: number;
  automaticPaymentsEnabled?: boolean;
}

export function Receivables({
  totalBalance,
  numberOfClientsWithBalances,
  averageDaysToGettingPaid,
  percentageOfUpcomingJobsOnAutopay,
  automaticPaymentsEnabled,
}: ReceivablesProps) {
  const { formatMessage } = useIntl();
  const [agedReceivablesPath] = useUrls("agedReceivablesPath");

  return (
    <>
      <Content>
        <ClientBalances
          totalBalance={totalBalance}
          numberOfClientsWithBalances={numberOfClientsWithBalances}
        />
      </Content>

      {automaticPaymentsEnabled && (
        <Content>
          <PercentageOfUpcomingJobsOnAutopay
            percentageOfUpcomingJobsOnAutopay={
              percentageOfUpcomingJobsOnAutopay
            }
          />
        </Content>
      )}

      <Content>
        <AverageDaysToGettingPaid
          averageDaysToGettingPaid={averageDaysToGettingPaid}
        />
      </Content>

      <Content>
        <Button
          label={formatMessage(messages.ageOfReceivablesReport)}
          type="secondary"
          url={agedReceivablesPath}
          onClick={() => {
            trackEventWithAmplitude({
              eventType: EventTypes.Link,
              eventSource: formatMessage(messages.ageOfReceivablesReport),
            });
          }}
          fullWidth={true}
        />
      </Content>
    </>
  );
}
