import React, { type ReactElement } from "react";
import styles from "./Table.module.css";

interface RowProps {
  children: ReactElement | ReactElement[];
  onClick?: () => void;
  testId?: string;
  disabled?: boolean;
}

export function Row({ children, onClick, testId, disabled }: RowProps) {
  let className = styles.row;
  if (onClick && !disabled) {
    className = className.concat(` ${styles.interactive}`);
  }
  return (
    <tr
      className={className}
      onClick={disabled ? undefined : onClick}
      tabIndex={0}
      data-testid={testId}
    >
      {children}
    </tr>
  );
}
