/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
// DO NOT COPY: Allowing an exception here for a legacy use of FormField.
// eslint-disable-next-line no-restricted-imports
import { FormField } from "@jobber/components/FormField";
import { Icon } from "@jobber/components/Icon";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Heading } from "@jobber/components/Heading";
import { useDebounce } from "./useDebounce";
import { HelpDrawerButton } from "./HelpDrawerButton";
import type {
  HelpDrawerProps,
  PageInfo,
  ResponseData,
  Result,
} from "./HelpDrawer.d";
import { ArticleList } from "./ArticleList";
import { RECOMMENDED_ARTICLES } from "./recommendedArticles";
import { trackAmplitudeEvent } from "./amplitudeEvent";
import styles from "./HelpDrawer.module.css";

export function HelpDrawer({
  section,
  showWebinarLink,
  canDelegateAccessFlow,
  tierName,
}: HelpDrawerProps) {
  const MAX_PER_PAGE = 10;
  const HELP_CENTER_API_URL =
    "https://help.getjobber.com//api/v2/help_center/articles/search.json";
  const CURRENT_SECTION =
    section && section in RECOMMENDED_ARTICLES ? section : "default";

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<Result[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const resultsCount = pageInfo && pageInfo.count;
  const hasNextPage = pageInfo && Boolean(pageInfo.next_page);
  const isSearching = debouncedSearchTerm !== "";

  const handleChangeSearchTerm = (newValue: string) => {
    setSearchTerm(newValue);
  };

  const handleOnClickUrl = (title: string) => () => {
    trackAmplitudeEvent({
      eventName: "Clicked on Support Link in Help Drawer",
      eventProperties: {
        interaction: title,
      },
      searchTerm: debouncedSearchTerm,
      section: CURRENT_SECTION,
    });
  };

  const handleOnClickChatWithUs = () => {
    trackAmplitudeEvent({
      eventName: "Clicked Start a Chat",
      eventProperties: {
        source: "global_chat_trigger",
      },
      searchTerm: debouncedSearchTerm,
      section: CURRENT_SECTION,
    });
  };

  const handleOnClickMoreResults = () => {
    setCurrentPage(currentPage + 1);
    trackAmplitudeEvent({
      eventName: "Clicked on More Results in Help Drawer",
      searchTerm: debouncedSearchTerm,
      section: CURRENT_SECTION,
    });
  };

  useEffect(() => {
    setResults([]);
    setPageInfo(undefined);
    setCurrentPage(1);
    if (isSearching) {
      trackAmplitudeEvent({
        eventName: "Searched in Help Drawer",
        searchTerm: debouncedSearchTerm,
        section: CURRENT_SECTION,
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const searchResults = async (search: string, page: number) => {
      setIsFetching(true);
      setIsError(false);

      fetch(
        `${HELP_CENTER_API_URL}?per_page=${MAX_PER_PAGE}&page=${page}&query=${search}`,
      )
        .then(async (response: Response) => response.json())
        .then((responseData: ResponseData) => {
          setIsFetching(false);
          setResults(prev => prev.concat(responseData.results));
          setPageInfo({ ...responseData });
        })
        .catch(() => setIsError(true));
    };

    if (isSearching) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      searchResults(searchTerm, currentPage);
    }
  }, [currentPage, debouncedSearchTerm]);

  const getSubTitle = (search: string, count?: number) => {
    const searchLabel = (
      <span className="u-marginLeftSmall">
        <InlineLabel>{search}</InlineLabel>
      </span>
    );

    if (!isSearching) {
      return "Recommendations";
    } else if (count) {
      return (
        <>
          {count} results for {searchLabel}
        </>
      );
    } else if (!isFetching) {
      return <>No results for {searchLabel}</>;
    }
  };

  const getResults = () => {
    return isSearching ? results : RECOMMENDED_ARTICLES[CURRENT_SECTION];
  };

  return (
    <div className={styles.helpDrawerWrapper}>
      <Content>
        <FormField
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          placeholder="Search our Help Center..."
          // bypass the type check for "type"
          type={"search" as "text"}
        />
        <Heading level={6}>
          {getSubTitle(debouncedSearchTerm, resultsCount)}
        </Heading>
      </Content>
      <div className={styles.helpDrawerArticleWrapper}>
        <ArticleList
          results={getResults()}
          searchTerm={debouncedSearchTerm}
          section={CURRENT_SECTION}
        />

        <div className={styles.helpDrawerMoreResults}>
          {/* More Results cta */}
          {!isFetching && hasNextPage && (
            <Button
              label="More Results"
              icon="arrowDown"
              variation="subtle"
              onClick={handleOnClickMoreResults}
            />
          )}

          {/* Fetching */}
          {isFetching && <div className="spinner spinner--inline" />}

          {/* Error */}
          {isError && (
            <Text>
              Sorry, the search results could not be displayed. Please try
              again.
            </Text>
          )}

          {/* End of results */}
          {!isFetching && !hasNextPage && results.length > MAX_PER_PAGE && (
            <Text>End of results</Text>
          )}
        </div>
      </div>

      <div className={styles.helpDrawerFooter}>
        <div className={styles.helpDrawerMenu}>
          {showWebinarLink && (
            <HelpDrawerButton
              label="Attend a Training Webinar"
              icon="presentation"
              target="_blank"
              href={getTierUrl()}
              onClick={handleOnClickUrl("Webinar Training")}
            />
          )}
          <HelpDrawerButton
            label="Visit Help Center"
            icon="help"
            href="https://help.getjobber.com/hc/en-us"
            onClick={handleOnClickUrl("Visit Help Center")}
            target="jobber_docs"
          />
          <HelpDrawerButton
            label="Watch Jobber Videos"
            icon="video"
            href="https://jbbr.app/3sxRbl1"
            onClick={handleOnClickUrl("Watch Jobber Videos")}
            target="jobber_docsdropdown-item"
          />
          {canDelegateAccessFlow && (
            <HelpDrawerButton
              label="Support Access"
              icon="user"
              href="/accounts/success_access"
              onClick={handleOnClickUrl("Support Access")}
            />
          )}
          <div className="show-for-small-only">
            <HelpDrawerButton
              label="Terms of Service"
              href="https://getjobber.com/terms-of-service/"
              onClick={handleOnClickUrl("Terms of Service")}
              target="_blank"
              rel="noopener noreferrer"
              termsOfService={true}
            />
          </div>
        </div>
        <button
          className="button button--large button--green button--fill u-marginTopSmall js-intercom"
          onClick={handleOnClickChatWithUs}
          aria-label="chat"
        >
          <Icon name="chat" color="surface" />
          <span className="u-paddingLeftSmaller">Chat With Us</span>
        </button>

        <div className="show-for-medium-up u-marginTopSmall">
          <a
            href="https://getjobber.com/terms-of-service/"
            onClick={handleOnClickUrl("Terms of Service")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );

  function getTierUrl() {
    const webinarEndpoint = ["core", "lite"].includes(tierName)
      ? "-core-plan"
      : "";

    return `https://app.livestorm.co/jobber-1/getting-started-with-jobber${webinarEndpoint}?utm_source=jobber&utm_medium=in%20product&utm_campaign=in%20product&utm_content=helpdrawer`;
  }
}
