import { type ApolloError, useQuery } from "@apollo/client";
import type {
  CommsCampaignQuery,
  CommsCampaignQueryVariables,
} from "~/utilities/API/graphql";
import { GET_CAMPAIGN_BY_ID } from "jobber/campaigns/graphql";

interface useCommsCampaignQueryProps {
  campaignId?: string;
  onCampaignNotFound: () => void;
  skip?: boolean;
}

export interface UseCommsCampaignQuery {
  loading: boolean;
  data?: CommsCampaignQuery;
  error?: ApolloError;
}

export function useCommsCampaignQuery({
  campaignId,
  onCampaignNotFound,
  skip = !campaignId,
}: useCommsCampaignQueryProps): UseCommsCampaignQuery {
  const {
    loading,
    data: result,
    error,
  } = useQuery<CommsCampaignQuery, CommsCampaignQueryVariables>(
    GET_CAMPAIGN_BY_ID,
    {
      skip: skip,
      variables: { id: campaignId || "" },
      onCompleted: data => {
        if (
          !data.commsCampaign?.automationRule &&
          !data.commsCampaign?.clientSegment
        ) {
          onCampaignNotFound();
        }
      },
    },
  );

  return {
    loading,
    data: result,
    error,
  };
}
