import { useSetupGuideEdit } from "./useSetupGuideEdit";

export function useSetupGuideActions() {
  const { handleSetupGuideEdit } = useSetupGuideEdit();

  function handleSetupGuideOptOut(onSuccess?: () => void) {
    const input = {
      optOut: true,
    };

    void handleSetupGuideEdit(input).then(result => {
      if (result?.optOut) {
        onSuccess?.();
      }
    });
  }

  function handleSetupGuideOptBackIn(onSuccess?: () => void) {
    const input = {
      optOut: false,
    };

    void handleSetupGuideEdit(input).then(result => {
      if (!result?.optOut) {
        onSuccess?.();
      }
    });
  }

  function handleSetupGuideComplete(onSuccess?: () => void) {
    const input = {
      completed: true,
    };

    void handleSetupGuideEdit(input).then(result => {
      if (result?.completed) {
        onSuccess?.();
      }
      return result?.completed;
    });
  }

  return {
    handleSetupGuideOptBackIn,
    handleSetupGuideOptOut,
    handleSetupGuideComplete,
  };
}
