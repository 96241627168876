import React from "react";
import { CalendarDatePicker } from "components/CalendarDatePicker";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { type Month, type Year, toMonth } from "../../yearMonthDay";

export interface MiniCalendarProps {
  selectedDate: Date;
  onMonthChange: (month: Month, year: Year) => void;
  onSelectDate: (date: Date) => void;
  highlightedDates: Date[];
}

export const MiniCalendar = (props: MiniCalendarProps) => {
  const weekStartsOnMonday = useAccount().calendarFirstDay === 1;

  return (
    <CalendarDatePicker
      onChange={props.onSelectDate}
      onMonthChange={(date: Date) => {
        props.onMonthChange(toMonth(date), date.getFullYear());
      }}
      weekStartsOnMonday={weekStartsOnMonday}
      highlightedDates={props.highlightedDates}
      selected={props.selectedDate}
      translations={{
        highlightedLabelSuffix: " has availability",
      }}
    />
  );
};
