/* eslint-disable @typescript-eslint/naming-convention */
export const industryStats: Record<string, number> = {
  "Lawn Care & Lawn Maintenance": 24000,
  "Residential Cleaning": 23000,
  "Construction & Contracting": 22000,
  "Landscaping Contractor": 13000,
  HVAC: 11000,
  Plumbing: 9000,
  "Arborist / Tree Care": 8000,
  "Electrical Contractor": 8000,
  "Commercial Cleaning": 8000,
  Handyman: 5000,
  "Pool and Spa Service": 4000,
  "Pressure Washing Service": 4000,
  Painting: 4000,
  "Window Washing": 3000,
  "Pest Control": 2000,
  Renovations: 2000,
  "Security and Alarm": 2000,
  "Roofing Service": 2000,
  "Appliance Repair": 2000,
  "Flooring Service": 2000,
  "Mechanical Service": 2000,
  "Carpet Cleaning": 1000,
  "Computers & IT": 1000,
  "Junk Removal": 1000,
  "Snow Removal": 0,
  "Home Theater": 0,
  Locksmith: 0,
};

export const industryDisplayText: Record<string, string> = {
  HVAC: "HVAC",
  "Computers & IT": "computers & IT",
  Other: "",
};
