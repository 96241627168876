import { Heading } from "@jobber/components/Heading";
import React from "react";
import { Glimmer } from "@jobber/components/Glimmer";
import styles from "./ReviewSummaryCard.module.css";
import { StarGroup } from "../StarGroup";

export interface ReviewSummaryCardProps {
  header: string;
  summaryValue: number;
  showStarGroup: boolean;
  isLoading: boolean;
}

export function ReviewSummaryCard({
  header,
  summaryValue,
  showStarGroup,
  isLoading,
}: ReviewSummaryCardProps): JSX.Element {
  const summaryDisplayValue = Number.isInteger(summaryValue)
    ? summaryValue.toString()
    : summaryValue.toFixed(1);
  return (
    <>
      {isLoading ? (
        <OverviewLoading />
      ) : (
        <div className={styles.summaryContainer}>
          <Heading level={5}>{header}</Heading>
          <div className={styles.row}>
            <Heading level={1}>{summaryDisplayValue}</Heading>
            {showStarGroup && (
              <StarGroup rating={summaryValue} allowHalfStars={true} />
            )}
            {/* TODO JOB-81699: Add back TrendChip */}
          </div>
        </div>
      )}
    </>
  );
}

function OverviewLoading(): JSX.Element {
  return (
    <div className={styles.overviewCardLoading}>
      <div className={styles.glimmerRow}>
        <Glimmer size={"base"} width={150} />
      </div>
      <div className={styles.glimmerRow}>
        <Glimmer size={"larger"} width={100} />
      </div>
    </div>
  );
}
