import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dataSharingConsentModal.title",
    defaultMessage: "Data processing consent",
    description: "Data processing consent modal title",
  },
  consentLabel: {
    id: "dataSharingConsentModal.consentLabel",
    defaultMessage: "I consent",
    description: "Data processing consent label",
  },
  disableLabel: {
    id: "dataSharingConsentModal.disableLabel",
    defaultMessage: "Disable Jobber Payments",
    description: "Disable Jobber Payments label",
  },
  consentMessage1: {
    id: "dataSharingConsentModal.consentMessage1",
    defaultMessage: "**To use Jobber Payments, your consent is required:**",
    description: "Data processing consent message 1",
  },
  consentMessage2: {
    id: "dataSharingConsentModal.consentMessage2",
    defaultMessage:
      "To help detect and combat fraud, Jobber will engage third-party service providers to verify our customers that use Jobber Payments. For more information about Jobber's privacy practices, please read our [privacy policy](https://getjobber.com/privacy-policy/).",
    description: "Data processing consent message 2",
  },
  warningTitle: {
    id: "dataSharingConsentModal.warningTitle",
    defaultMessage: "Disable Jobber Payments?",
    description: "Disable Jobber Payments warning title",
  },
  consentWarningMessage: {
    id: "dataSharingConsentModal.consentWarningMessage",
    defaultMessage:
      "Your clients will no longer be able to pay and tip by credit card or bank payment and automatic payments will be unavailable.",
    description: "Data processing consent warning message",
  },
  secondaryButtonWarning: {
    id: "dataSharingConsentModal.secondaryButtonWarning",
    defaultMessage: "Cancel",
    description: "Data processing consent secondary button warning",
  },
  disableToastMessage: {
    id: "dataSharingConsentModal.disableToastMessage",
    defaultMessage: "Jobber Payments disabled",
    description: "Data processing consent disable toast message",
  },
});
