import { useState } from "react";

export function useCustomFieldSideDrawer() {
  const [sideDrawerAttributes, setSideDrawerAttributes] = useState({
    open: false,
    productOrServiceId: "",
    lineItemThumbnailUrl: "",
    app: { name: "", logoUrl: "" },
  });

  const handleRequestClose = () => {
    setSideDrawerAttributes({
      open: false,
      productOrServiceId: "",
      lineItemThumbnailUrl: "",
      app: { name: "", logoUrl: "" },
    });
  };

  const handleCustomFieldChipClick =
    (lineItemThumbnailUrl: string) =>
    ({
      app,
      productOrServiceId,
    }: {
      app: { name: string; logoUrl: string };
      productOrServiceId: string;
    }) =>
      setSideDrawerAttributes({
        open: true,
        productOrServiceId,
        lineItemThumbnailUrl,
        app,
      });

  return {
    sideDrawerAttributes,
    handleRequestClose,
    handleCustomFieldChipClick,
  };
}

export type handleCustomFieldChipClick = ReturnType<
  typeof useCustomFieldSideDrawer
>["handleCustomFieldChipClick"];
