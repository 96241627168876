import React from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Cell, Footer } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import {
  columnsRequiringJobCostingFeature,
  columnsRequiringPricingPermission,
} from "jobber/workOrders/components/JobCost/components/LineItemsTable/constants";
import { formatCurrency } from "utilities/formatCurrency";
import { messages } from "./messages";
import styles from "../../LineItemsTable.module.css";
import { AddNewLineItemButton } from "../AddNewLineItemButton";

interface LineItemsTableFooterProps {
  lineItemsTotalCost: number;
  lineItemsTotalPrice: number;
  dispatch: (action: LineItemsAction) => void;
}

export const LineItemsTableFooter = (props: LineItemsTableFooterProps) => {
  const { features, currencySymbol } = useAccount();
  const canViewCostColumn =
    features.jobCosting.enabled && features?.quoteMargins.enabled;
  const { can } = useAuthorization();
  const canViewPricing = can("view", "Pricing");
  const canEditJobs = can("edit", "Jobs");
  const { formatMessage } = useIntl();

  if (!canViewPricing) return <></>;

  const cellsToRender = [
    <Cell key={"description"}>
      {canViewPricing && canEditJobs && (
        <AddNewLineItemButton
          onPress={() =>
            props.dispatch({
              type: LineItemsBulkEditActionTypes.AddLineItem,
            })
          }
        />
      )}
    </Cell>,
    <Cell key={"quantity"} />,
    <Cell key={"cost"} className={styles.lineItemsTableFooterCell}>
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          <Heading level={5}>
            {formatMessage(messages.lineItemsTotalCost)}
          </Heading>
        </span>
        <span className={styles.numericBold} data-testid="footerTotalCost">
          {formatCurrency(props.lineItemsTotalCost, currencySymbol)}
        </span>
      </div>
    </Cell>,
    <Cell key={"price"} />,
    <Cell key={"total"} className={styles.lineItemsTableFooterCell}>
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          <Heading level={5}>
            {formatMessage(messages.lineItemsTotalPrice)}
          </Heading>
        </span>
        <span className={styles.numericBold} data-testid="footerTotalPrice">
          {formatCurrency(props.lineItemsTotalPrice, currencySymbol)}
        </span>
      </div>
    </Cell>,
  ];

  const permissionFilteredCellsToRender = cellsToRender
    .filter(
      cell =>
        !columnsRequiringPricingPermission.includes(
          `${cell.key?.toString()}`,
        ) || canViewPricing,
    )
    .filter(
      cell =>
        !columnsRequiringJobCostingFeature.includes(
          `${cell.key?.toString()}`,
        ) || canViewCostColumn,
    );

  return <Footer>{...permissionFilteredCellsToRender}</Footer>;
};
