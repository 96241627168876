import { defineMessages } from "react-intl";

export const approvedStateMessages = defineMessages({
  thankYouMessage: {
    id: "ukKycRegistration.approved.thankYouMessage",
    defaultMessage: "Thank you for completing verification!",
    description: "Thank you message for the body of the component",
  },
  approvedButtonText: {
    id: "ukKycRegistration.approved.buttonText",
    defaultMessage: "Got It",
    description: "CTA text for the button in the approved state",
  },
  youreFragment: {
    id: "ukKycRegistration.approved.youreFragment",
    defaultMessage: "You're",
    description:
      "Part of the larger sentence in the approved state for ''You're approved''",
  },
  approvedFragment: {
    id: "ukKycRegistration.approved.approvedFragment",
    defaultMessage: "approved",
    description:
      "Part of the larger sentence in the approved state for ''You're approved''",
  },
});
