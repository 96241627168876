import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import type { AliasUser, AliasUsersProps } from "./AliasUsers.d";

export function AliasUsers({ users }: AliasUsersProps) {
  /* Props */
  return (
    <Content>
      <Text variation="info" size="large">
        The following users will be automatically added to the account after you
        have set your account details.
      </Text>

      {users.map(renderUser)}
    </Content>
  );

  function renderUser(user: AliasUser, key: number): JSX.Element {
    return (
      <Text key={key} size="large">
        <Icon name="user" /> {user.name}
      </Text>
    );
  }
}
