import PlanTapeSrc from "@images/Marketing/marketingSuiteTape.png";
import MoreClientsSrc from "@images/more_clients.png";
import ProtectReputationSrc from "@images/protect_reputation.png";
import ReviewsHeroSrc from "@images/reviews_hero.png";
import TrustedBrandSrc from "@images/trusted_brand.png";
import { messages } from "./messages";
import type { ImageType } from "../../types";

const PlanTapeImg: ImageType = {
  src: PlanTapeSrc as string,
  alt: messages.planTapeAltText,
};

const MoreClientsImg: ImageType = {
  src: MoreClientsSrc as string,
  alt: messages.moreClientsAltText,
};

const ProtectReputationImg: ImageType = {
  src: ProtectReputationSrc as string,
  alt: messages.protectReputationAltText,
};

const ReviewsHeroImg: ImageType = {
  src: ReviewsHeroSrc as string,
  alt: messages.heroAltText,
};

const TrustedBrandImg: ImageType = {
  src: TrustedBrandSrc as string,
  alt: messages.trustedBrandAltText,
};

export {
  ReviewsHeroImg,
  TrustedBrandImg,
  ProtectReputationImg,
  MoreClientsImg,
  PlanTapeImg,
};
