import React, { useState } from "react";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputText } from "@jobber/components/InputText";
import { Checkbox } from "@jobber/components/Checkbox";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import styles from "./EmailMessage.module.css";

export interface EmailMessageProps {
  /** value of the email subject provided by the webserver */
  emailSubject: string;

  /** value of the email message provided by the webserver */
  emailMessage: string;

  /* Initial value of our survey checkbox */
  sendSurvey: boolean;

  /* Conditional for checkbox display */
  showSurvey: boolean;

  /** Initial value of checkbox */
  sendMeCopy: boolean;

  allowMagicField?: boolean;

  /** Callback for updating the state of the email subject being edited. */
  setEmailSubject(emailSubject: string): void;

  /** Callback for updating the state of the email message being edited. */
  setEmailMessage(emailMessage: string): void;
}

interface EmailMessageInternalProps {
  /** Email subject state currently being edited */
  emailSubject: string;

  /** Email message state currently being edited */
  emailMessage: string;

  allowMagicField?: boolean;

  /** Callback for updating the state of the email subject being edited. */
  onChangeSubject(emailSubject: string): void;

  /** Callback for updating the state of the email message being edited. */
  onChangeMessage(emailMessage: string): void;
}
interface SendMeCopyProps {
  /** Initial value of checkbox */
  sendMeCopy: boolean;
}
interface SendSurveyProps {
  /* Internal value of survey checkbox */
  sendSurvey: boolean;
}

/* Email Message Internal */
const EmailMessageInternal = ({
  emailMessage,
  emailSubject,
  onChangeSubject,
  onChangeMessage,
  allowMagicField,
}: EmailMessageInternalProps) => {
  return (
    <InputGroup>
      <InputText
        name="subject"
        placeholder="Subject"
        value={emailSubject}
        onChange={onChangeSubject}
      />
      <MagicInputText
        disableMagicRewrite={!allowMagicField}
        name="additional_message"
        placeholder="Message"
        value={emailMessage}
        onChange={onChangeMessage}
        multiline={true}
        rows={12}
        size="small"
      />
    </InputGroup>
  );
};

/* Include survey checkbox */
const SendSurvey = ({ sendSurvey }: SendSurveyProps) => {
  const [isChecked, setIsChecked] = useState(sendSurvey);
  return (
    <div className={styles.checkbox}>
      <Checkbox
        name="send_survey"
        checked={isChecked}
        label="Include feedback survey in email"
        onChange={setIsChecked}
        value={isChecked.toString()}
      />
    </div>
  );
};

/* Sned me a copy checkbox */
const SendMeCopy = ({ sendMeCopy }: SendMeCopyProps) => {
  const [isChecked, setIsChecked] = useState(sendMeCopy);
  return (
    <div className={styles.checkbox}>
      <Checkbox
        name="send_copy"
        checked={isChecked}
        label="Send me a copy"
        onChange={setIsChecked}
        value={isChecked.toString()}
      />
    </div>
  );
};

/* Email Message Component */
export function EmailMessage({
  emailSubject,
  emailMessage,
  setEmailSubject,
  setEmailMessage,
  sendSurvey,
  showSurvey,
  sendMeCopy,
  allowMagicField,
}: EmailMessageProps) {
  return (
    <div>
      <EmailMessageInternal
        emailSubject={emailSubject}
        emailMessage={emailMessage}
        onChangeSubject={setEmailSubject}
        onChangeMessage={setEmailMessage}
        allowMagicField={allowMagicField}
      />
      <SendMeCopy sendMeCopy={sendMeCopy} />
      {showSurvey && <SendSurvey sendSurvey={sendSurvey} />}
    </div>
  );
}
