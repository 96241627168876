import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { parseISO } from "date-fns";
import type {
  OnlineBookingConfigurationFragment,
  OnlineBookingConfigurationQuery,
  OnlineBookingScheduleFragment,
} from "~/utilities/API/graphql";
import type { Configuration } from "jobber/settings/selfServeBookings/types";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { SELF_SERVE_SETTINGS_QUERY } from "./graphql";
import { useInitializeSettings } from "./useInitializeSettings";

export function useQueryConfiguration(): {
  configuration: Configuration;
  loading: boolean;
  error: string | undefined;
} {
  const {
    data: settings,
    loading,
    error,
  } = useQuery<OnlineBookingConfigurationQuery>(SELF_SERVE_SETTINGS_QUERY);

  const {
    initializeSettings,
    data: newSettings,
    loading: initLoading,
    error: initError,
  } = useInitializeSettings();

  useEffect(() => {
    if (
      (!settings?.onlineBookingConfiguration ||
        !settings?.onlineBookingScheduleSettings) &&
      !loading
    ) {
      initializeSettings().catch(err => {
        Rollbar.EXECUTE(
          "Error Initializing Online Booking Settings",
          err as Error,
        );
      });
    }
  }, [settings, initializeSettings, loading]);

  const mapConfiguration = (
    config: OnlineBookingConfigurationFragment | undefined,
  ) => {
    return {
      acceptingOnlineBookings: config?.acceptingOnlineBookings ?? false,
      bookingUrl: config?.bookingUrl ?? "",
      confirmationMessage: config?.confirmationMessage,
      googleMeasurementId: config?.googleMeasurementId,
      serviceAreasEnabled: config?.serviceAreasEnabled ?? false,
      serviceAreas: config?.serviceAreas,
      spTermsAndConditionsUrl: config?.spTermsAndConditionsUrl,
    };
  };

  const mapSchedule = (
    schedule: OnlineBookingScheduleFragment | undefined,
  ) => ({
    earliestAvailabilityMinutes: schedule?.earliestAvailabilityMinutes,
    intervalDurationMinutes: schedule?.intervalDurationMinutes,
    useBufferTime: schedule?.useBufferTime ?? false,
    useDriveTime: schedule?.useDriveTime ?? false,
    maxDriveTimeMinutes: schedule?.maxDriveTimeMinutes ?? 30,
    bufferDurationMinutes: schedule?.bufferDurationMinutes ?? 30,
    bookableUsers: schedule?.bookableUsers || { nodes: [], totalCount: 0 },
  });

  const mapCompanyLocation = (
    account: OnlineBookingConfigurationQuery["account"] | undefined,
  ) => ({
    countryCode: account?.settings?.localization?.countryCode ?? "US",
    address: account?.companyDetails.address,
    coordinates: account?.companyDetails.coordinates,
  });

  const mapGoogleSettings = (
    googleSettings:
      | OnlineBookingConfigurationQuery["onlineBookingGoogleSettings"]
      | undefined,
    acceptingOnlineBookings: boolean | undefined,
  ) => ({
    enabled: (acceptingOnlineBookings && googleSettings?.enabled) ?? false,
    firstEnabledAt: googleSettings?.updatedAt
      ? parseISO(googleSettings.updatedAt)
      : undefined,
  });

  return {
    configuration: {
      ...mapConfiguration(
        settings?.onlineBookingConfiguration ||
          newSettings?.onlineBookingSettingsInitialize
            .onlineBookingConfiguration,
      ),
      ...mapSchedule(
        settings?.onlineBookingScheduleSettings ||
          newSettings?.onlineBookingSettingsInitialize
            .onlineBookingScheduleSettings,
      ),
      companyLocation: mapCompanyLocation(settings?.account),
      googleSharingSettings: mapGoogleSettings(
        settings?.onlineBookingGoogleSettings,
        settings?.onlineBookingConfiguration?.acceptingOnlineBookings,
      ),
      leadRoutingEnabled:
        settings?.account?.franchise?.leadRoutingEnabled || false,
      servicesCount: settings?.onlineBookingServices.totalCount || 0,
      driveTimeFeatureEnabled: settings?.driveTimeFeature?.enabled || false,
      users: settings?.users?.nodes || [],
    },
    loading: loading || initLoading,
    error: error?.message || initError?.message,
  };
}
