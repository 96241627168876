import React, { useCallback, useState } from "react";
import { LightBox } from "@jobber/components/LightBox";
import { Button } from "@jobber/components/Button";
import styles from "./AttachmentLink.module.css";

export interface AttachmentLinkProps {
  label: string;
  contentLocation: string;
  contentType: string;
}

export function AttachmentLink(props: AttachmentLinkProps) {
  if (attachmentShouldAppearInLightbox(props)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [open, isOpen] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onRequestClose = useCallback(() => isOpen(false), []);
    const onButtonClick = () => {
      isOpen(true);
    };
    return (
      <div className={styles.attachmentButton}>
        <Button
          label={props.label}
          ariaLabel={props.label}
          onClick={onButtonClick}
          external={false}
          type="tertiary"
          size="small"
          fullWidth={false}
        />
        <LightBox
          open={open}
          images={[{ url: props.contentLocation }]}
          onRequestClose={onRequestClose}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.attachmentButton}>
        <Button
          label={props.label}
          ariaLabel={props.label}
          url={props.contentLocation}
          external={true}
          type="tertiary"
          size="small"
          fullWidth={false}
        />
      </div>
    );
  }
}

function attachmentShouldAppearInLightbox(props: AttachmentLinkProps) {
  return props.contentType.toUpperCase().startsWith("IMAGE");
}
