import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ReviewStatus } from "~/utilities/API/graphql";
import type {
  MarketingReviewsSubscription,
  ReviewEdge,
  ReviewsQuery,
} from "~/utilities/API/graphql";
import { useAccountFeatures } from "jobber/hooks/useAccountFeatures";
import { REVIEWS_QUERY, REVIEWS_SUBSCRIPTION } from "../ReviewsPage.graphql";
import type { ReviewsError } from "../types";
import { ReviewStatuses } from "../types";

interface UseReviewsQueryProps {
  statusFilter?: string | number;
}

export function useReviewsQuery({ statusFilter }: UseReviewsQueryProps = {}) {
  const [errors, setErrors] = useState<ReviewsError | undefined>(undefined);

  const phoneNumberFeature = useAccountFeatures().find(
    feature => feature.name === "Unique Phone Number",
  );

  const {
    data,
    loading,
    error: queryError,
    subscribeToMore,
  } = useQuery<ReviewsQuery>(REVIEWS_QUERY, {
    variables: {
      filter: {
        status:
          statusFilter == ReviewStatuses.Unanswered
            ? ReviewStatus.UNANSWERED
            : undefined,
      },
      canViewCommsSettings: !!phoneNumberFeature?.enabled,
    },
    skip: phoneNumberFeature == undefined,
  });

  useEffect(() => {
    if (queryError) {
      setErrors(() => ({
        generalError: true,
      }));
      return;
    }
  }, [queryError]);

  useEffect(() => {
    if (queryError) {
      return;
    }

    const unsubscribe = subscribeToMore<MarketingReviewsSubscription>({
      document: REVIEWS_SUBSCRIPTION,
      onError: () => {
        setErrors(() => ({
          generalError: true,
        }));
      },
      updateQuery: (prev, { subscriptionData }) => {
        const subscriptionErrors = subscriptionData.data.reviews.errors;
        if (subscriptionErrors.length > 0) {
          setErrors(() => ({
            credentialError: subscriptionErrors.find(error =>
              error.message.includes("Missing Google account credentials"),
            )
              ? true
              : false,
            generalError: subscriptionErrors.find(error =>
              error.message.includes(
                "There was a problem loading your reviews",
              ),
            )
              ? true
              : false,
          }));
          return prev;
        }

        const reviewSummary = buildReviewSummary(prev, subscriptionData.data);
        const reviews = reviewSummary.reviews;

        switch (statusFilter) {
          case ReviewStatuses.All: {
            return Object.assign({}, prev, {
              reviews: reviewSummary,
            });
          }
          case ReviewStatuses.Unanswered: {
            return Object.assign({}, prev, {
              reviews: {
                ...reviewSummary,
                reviews: {
                  ...reviews,
                  edges: reviews.edges.filter(e => e.node.reviewReply === null),
                },
              },
            });
          }
          default: {
            return Object.assign({}, prev, {
              reviews: reviewSummary,
            });
          }
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [queryError, statusFilter, subscribeToMore]);

  return {
    data,
    // useAccountFeatures doesn't provide a loading state
    // so if it doesn't have a value yet, we want to consider it "loading"
    loading: loading || phoneNumberFeature == undefined,
    error: errors,
  };
}

function buildReviewSummary(
  prev: ReviewsQuery,
  subscriptionData: MarketingReviewsSubscription,
) {
  const edges = buildEdges(
    prev.reviews.reviews.edges as ReviewEdge[],
    subscriptionData.reviews.reviews.edges as ReviewEdge[],
  );
  const newEdges = findNewReviewEdges(
    prev.reviews.reviews.edges as ReviewEdge[],
    subscriptionData.reviews.reviews.edges as ReviewEdge[],
  );

  return {
    __typename: "ReviewsSummary",
    averageRating: subscriptionData.reviews.averageRating,
    totalReviewCount: subscriptionData.reviews.totalReviewCount,
    reviews: Object.assign({}, prev.reviews.reviews, {
      __typename: "ReviewConnection",
      pageInfo: subscriptionData.reviews.reviews.pageInfo,
      edges: [...newEdges, ...edges],
    }),
  };
}

function buildEdges(prev?: ReviewEdge[], next?: ReviewEdge[]) {
  // the edges in prev are the result of a merge, so it should
  // be safe to filter out the edges that are not in next
  return (
    prev?.filter(
      prevEdge =>
        undefined !==
        next?.find(nextEdge => nextEdge.node.id === prevEdge.node.id),
    ) || []
  );
}

function findNewReviewEdges(prev?: ReviewEdge[], next?: ReviewEdge[]) {
  return (
    next?.filter(
      nextEdge =>
        undefined ===
        prev?.find(
          prevEdge =>
            nextEdge.node.id === prevEdge.node.id &&
            nextEdge.node.updatedAt === prevEdge.node.updatedAt,
        ),
    ) || []
  );
}
