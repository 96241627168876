import type { ConversationDataFragment } from "~/utilities/API/graphql";

export function sortConversationByLastSentAt(
  nodes: ConversationDataFragment[],
) {
  return nodes.slice().sort((a, b) => {
    const aDate = a.messages.nodes[0].timestamp;
    const bDate = b.messages.nodes[0].timestamp;
    if (aDate != undefined && bDate != undefined) {
      return bDate < aDate ? -1 : bDate > aDate ? 1 : 0;
    }
    return 0;
  });
}
