import { gql } from "@apollo/client";
import { CLIENT_SEGMENT } from "../../graphql";

export const UPDATE_PAST_CLIENTS_SEGMENT_CAMPAIGN = gql`
  mutation updatePastClientsSegmentCampaign(
    $input: PastClientsUpdateSegmentInput!
  ) {
    campaignSegmentUpdatePastClients(input: $input) {
      pastClientsSegment {
        ...clientSegment
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CLIENT_SEGMENT}
`;
