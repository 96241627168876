import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Option, Select } from "@jobber/components/Select";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputDate } from "@jobber/components/InputDate";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import styles from "./DateRangePicker.module.css";
import { messages } from "./messages";
import type { DateRange, DateRangeOptions, PresetRange } from "../types";
import { DateRanges, presetDateRanges } from "../types";

interface DateRangePickerProps {
  onChange(newValue: { preset: PresetRange["preset"]; range: DateRange }): void;
  initialValue: {
    preset: PresetRange["preset"];
    range: DateRange;
  };
  dateRangeOptions: DateRangeOptions[];
  dateRangeDropdownLabel?: string;
}

export function DateRangePicker({
  onChange,
  initialValue,
  dateRangeOptions,
  dateRangeDropdownLabel,
}: DateRangePickerProps) {
  const { formatMessage } = useIntl();
  const [preset, setPreset] = useState<PresetRange["preset"]>(
    initialValue.preset,
  );
  const [after, setAfter] = useState<Date>(
    initialValue.range.after ? initialValue.range.after : defaultStartDate(),
  );
  const [before, setBefore] = useState<Date>(
    initialValue.range.before ? initialValue.range.before : defaultEndDate(),
  );

  // When selecting a custom date range, the date pickers should be visible
  if (preset === DateRanges.custom) {
    return (
      <ApplyCard
        onClick={() => {
          onChange({
            preset,
            range: { after, before },
          });
        }}
      >
        <SelectDateRangePreset
          dateRangeOptions={dateRangeOptions}
          onChange={setPreset}
          initialValue={DateRanges.custom}
          dateRangeDropdownLabel={dateRangeDropdownLabel}
        />

        <InputGroup flowDirection="horizontal">
          <InputDate
            placeholder={formatMessage(messages.filterStartDatePlaceholder)}
            value={after}
            onChange={setAfter}
            maxDate={before}
          />
          <InputDate
            placeholder={formatMessage(messages.filterEndDatePlaceholder)}
            value={before}
            onChange={setBefore}
            minDate={after}
          />
        </InputGroup>
      </ApplyCard>
    );
  }

  // When selecting a date range preset we only need the Select box
  return (
    <ApplyCard
      onClick={() => {
        onChange({
          preset,
          range: presetDateRanges[preset],
        });
      }}
    >
      <SelectDateRangePreset
        onChange={setPreset}
        dateRangeOptions={dateRangeOptions}
        initialValue={preset}
        dateRangeDropdownLabel={dateRangeDropdownLabel}
      />
    </ApplyCard>
  );
}

interface SelectDateRangePresetProps {
  onChange(newValue: PresetRange["preset"]): void;
  initialValue: PresetRange["preset"];
  dateRangeOptions: { [s: number]: string };
  dateRangeDropdownLabel?: string;
}

function SelectDateRangePreset({
  onChange,
  initialValue,
  dateRangeOptions,
  dateRangeDropdownLabel,
}: SelectDateRangePresetProps) {
  const { formatMessage } = useIntl();
  return (
    <Select
      onChange={onChange}
      defaultValue={initialValue}
      placeholder={
        dateRangeDropdownLabel ??
        formatMessage(messages.dateRangeSelectPlaceholder)
      }
    >
      {Object.values(dateRangeOptions).map((option, index) => {
        return (
          <Option key={index} value={option}>
            {formatMessage(messages[option as keyof typeof messages])}
          </Option>
        );
      })}
    </Select>
  );
}

function ApplyCard({
  onClick,
  children,
}: {
  onClick(): void;
  children: React.ReactNode;
}) {
  const { formatMessage } = useIntl();
  return (
    <Card>
      <div className={styles.dateSelectorCardBody}>
        {children}
        <Button
          label={formatMessage(messages.applySelectedDateRangeButtonLabel)}
          onClick={onClick}
          type="primary"
        />
      </div>
    </Card>
  );
}

function defaultStartDate() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function defaultEndDate() {
  const date = new Date();
  date.setHours(23, 59, 59, 999);
  return date;
}
