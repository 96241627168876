import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { FormatDate } from "@jobber/components/FormatDate";
import { FormatTime } from "@jobber/components/FormatTime";
import { Modal } from "@jobber/components/Modal";
import { Icon } from "@jobber/components/Icon";
import { type MessageDescriptor, useIntl } from "react-intl";
import { messages as labourTableMessages } from "jobber/workOrders/components/JobCost/components/LabourTable/messages";
import { messages as timesheetModalMessages } from "jobber/workOrders/components/JobCost/components/LabourTable/components/TimesheetModal/messages";
import type { TimeSheetDataEntry } from "jobber/workOrders/components/JobCost/components/LabourTable/types";
import { Body, Cell, Row } from "components/Table";
import { formatSecondsToHours } from "jobber/workOrders/components/JobCost/utils";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { JobDetailsFragment } from "~/utilities/API/graphql";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import { EditTimesheetModal } from "./TimesheetModal";
import { getCurrentEndAt } from "../utils";
import styles from "../LabourTable.module.css";

interface LabourTableBodyProps {
  timeSheetData: TimeSheetDataEntry[];
  job: JobDetailsFragment;
  currentDate: Date;
  children?: JSX.Element;
}

export function LabourTableBody({
  timeSheetData,
  job,
  currentDate,
  children,
}: LabourTableBodyProps) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<
    TimeSheetDataEntry | undefined
  >(undefined);
  const { can } = useAuthorization();
  const { currencySymbol, timezone } = useAccount();

  const canViewJobCosts = can("view", "JobCosts");

  function editTimeSheetEntry(entry: TimeSheetDataEntry) {
    setSelectedEntry(entry);
    setModalOpen(true);
  }

  return (
    <Body>
      <>
        {timeSheetData.map((labourRow, index) =>
          formatLabourRow(
            labourRow,
            index,
            editTimeSheetEntry,
            canViewJobCosts,
            currencySymbol,
            timezone,
            currentDate,
            formatMessage,
          ),
        )}
        {children}
        <Modal
          open={modalOpen}
          onRequestClose={closeModal}
          title={formatMessage(timesheetModalMessages.editTimesheetModalTitle)}
        >
          <EditTimesheetModal
            jobId={job.id}
            timeSheetEntryId={selectedEntry?.id || ""}
            initialState={{
              duration: selectedEntry?.durationSeconds || 0,
              startAt: selectedEntry?.date.startAt,
              endAt: selectedEntry?.date.endAt,
              note: selectedEntry?.note || "",
              labourRate: selectedEntry?.labourRate,
              assignedTo: selectedEntry?.assignedTo,
            }}
            closeModal={closeModal}
          />
        </Modal>
      </>
    </Body>
  );
  function closeModal() {
    setModalOpen(false);
  }
}

function formatLabourRow(
  labourRow: TimeSheetDataEntry,
  index: number,
  editTimeSheetEntry: (entry: TimeSheetDataEntry) => void,
  canViewCostColumn: boolean,
  currencySymbol: string,
  timezone: string,
  currentDate: Date,
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string,
) {
  const { team, note, date, durationSeconds, cost, ticking } = labourRow;
  const cells = [
    <Cell variation="bold" key={"team"}>
      {team}
    </Cell>,
    <Cell key={"note"}>{note}</Cell>,
    <Cell key={"date"}>
      <Text>
        <FormatDate date={date.startAt} />
      </Text>
      <Text>
        <>
          <FormatTime time={date.startAt} use24HourClock={false} />
          {" - "}
          {date.endAt ? (
            <FormatTime time={date.endAt} use24HourClock={false} />
          ) : (
            "..."
          )}
        </>
      </Text>
    </Cell>,
    <Cell key={"hours"}>
      <div className={styles.hoursCell}>
        <span>
          {formatMessage(labourTableMessages.labourTableHoursColumnHeader)}
        </span>
        <div className={styles.clockCell}>
          {ticking && <Icon name="runningTimer" />}
          <span>
            {formatSecondsToHours(
              (durationSeconds || durationSeconds === 0) && !ticking
                ? durationSeconds
                : getCurrentEndAt(date.startAt, currentDate, timezone),
            )}
          </span>
        </div>
      </div>
    </Cell>,
  ];

  if (canViewCostColumn) {
    const costValue = ticking ? "-" : formatCurrency(cost, currencySymbol);

    cells.push(
      <Cell className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(labourTableMessages.labourTableCostColumnHeader)}
        </span>
        <span className={styles.numericBold}>{costValue}</span>
      </Cell>,
    );
  }

  return (
    <Row
      key={index}
      disabled={labourRow.ticking}
      onClick={() => {
        editTimeSheetEntry(labourRow);
      }}
    >
      {...cells}
    </Row>
  );
}
