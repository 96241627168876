import { useLazyQuery } from "@apollo/client";
import type {
  WorkObjectGlobalOwnershipsQuery,
  WorkObjectGlobalOwnershipsQueryVariables,
} from "~/utilities/API/graphql";
import { GLOBAL_OWNERSHIP_QUERY } from "jobber/features/Settings/views/CommsSettings/hooks/useOwnershipData/useOwnershipData.graphql";

export function useOwnershipData() {
  const [globalOwnershipsQuery, { loading, data, error }] = useLazyQuery<
    WorkObjectGlobalOwnershipsQuery,
    WorkObjectGlobalOwnershipsQueryVariables
  >(GLOBAL_OWNERSHIP_QUERY);

  function fetchOwnerships(
    variables?: WorkObjectGlobalOwnershipsQueryVariables,
  ) {
    const options = variables
      ? {
          variables: variables,
        }
      : undefined;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    globalOwnershipsQuery(options);
  }

  const ownerships = data?.workObjectGlobalOwnerships?.nodes;

  return {
    fetchOwnerships,
    ownerships,
    loading,
    error,
  };
}
