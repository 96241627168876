import React from "react";
import type { Segment } from "~/utilities/API/graphql";
import type { UseClientSegmentOptionsType } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions/useClientSegmentOptions";
import { SelectableSegment } from "jobber/campaigns/components/SelectableSegments/SelectableSegment/SelectableSegment";

export interface SelectableSegmentsProps {
  segmentOptions: UseClientSegmentOptionsType[];
  value: Segment;
  onChange: (newValue: Segment) => void;
  loading: boolean;
}

export function SelectableSegments({
  segmentOptions,
  value,
  onChange,
  loading,
}: SelectableSegmentsProps) {
  return (
    <>
      {segmentOptions.map((segment, index) => {
        return (
          <SelectableSegment
            description={segment.heading}
            recommended={segment.recommended}
            value={value}
            name={segment.heading}
            segmentValue={segment.value}
            onChange={onChange}
            loading={loading}
            key={index}
          />
        );
      })}
    </>
  );
}
