import { Option, Select } from "@jobber/components/Select";
import { Heading } from "@jobber/components/Heading";
import { isInHandheldView } from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import styles from "./FilterSelect.module.css";
import type { FilterSelectProps } from "./types";

export const FilterSelect = ({
  filterOptions = [],
  onFilterSelectChange,
  heading,
  defaultValue,
  description,
  placeholder,
}: FilterSelectProps) => {
  return (
    <div className={styles.filterSelectContainer}>
      {heading && isInHandheldView(innerWidth) && (
        <div className={styles.headingContainer}>
          <Heading level={6}>{heading}</Heading>
        </div>
      )}

      <Select
        onChange={onFilterSelectChange}
        defaultValue={defaultValue}
        description={description}
        placeholder={placeholder}
      >
        {filterOptions.map((option, index) => {
          return <Option key={index}>{option}</Option>;
        })}
      </Select>
    </div>
  );
};
