/* eslint-disable import/no-internal-modules */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import { Content } from "@jobber/components/Content";
import { Banner } from "@jobber/components/Banner";
import { InputText } from "@jobber/components/InputText";
import { URL_REGEX } from "jobber/settings/selfServeBookings/utils";
import { BulletedItems } from "jobber/settings/selfServeBookings/components/BulletedItems/BulletedItems";
import { messages } from "./messages";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";
import { useUpdateConfiguration } from "../../hooks/useUpdateConfiguration";

interface TermsAndConditionsConfigurationProps {
  /**
   * Current confirmation page sp terms and conditions url
   */
  readonly url?: string;
}

export function TermsAndConditionsConfiguration({
  url,
}: TermsAndConditionsConfigurationProps) {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spTermsAndConditionsUrl, setSpTermsAndConditionsUrl] = useState(url);

  const {
    editSelfServeSettings,
    loading: saving,
    error,
  } = useUpdateConfiguration();

  function toggleModal() {
    handleReset();
    setIsModalOpen(current => !current);
  }

  function updateText(input: string) {
    setSpTermsAndConditionsUrl(input);
  }

  function handleReset() {
    setSpTermsAndConditionsUrl(url);
  }

  async function handleSubmit() {
    if (!spTermsAndConditionsUrl || URL_REGEX.test(spTermsAndConditionsUrl)) {
      await editSelfServeSettings({
        spTermsAndConditionsUrl,
      }).then(() => {
        toggleModal();
        showToast({ message: formatMessage(messages.saveMessage) });
      });
    }
  }

  return (
    <>
      <ConfigurationSetting
        title={formatMessage(messages.configurationLabel)}
        onEdit={toggleModal}
      >
        <BulletedItems items={[url || formatMessage(messages.urlEmpty)]} />
      </ConfigurationSetting>
      <Modal
        title={formatMessage(messages.modalTitle)}
        open={isModalOpen}
        onRequestClose={toggleModal}
        primaryAction={{
          label: formatMessage(messages.modalSave),
          onClick: handleSubmit,
          loading: saving,
          disabled: saving,
        }}
        secondaryAction={{
          label: formatMessage(messages.modalCancel),
          onClick: toggleModal,
          disabled: saving,
        }}
      >
        <Content>
          {error && (
            <Banner type={"error"}>
              {formatMessage(messages.errorMessage)}
            </Banner>
          )}
          <InputText
            value={spTermsAndConditionsUrl}
            onChange={updateText}
            description={formatMessage(messages.inputTextDescription)}
            prefix={{ icon: "link" }}
            placeholder={formatMessage(messages.inputTextPlaceholder)}
            validations={{
              pattern: {
                value: URL_REGEX,
                message: formatMessage(messages.urlValidationError),
              },
            }}
          />
        </Content>
      </Modal>
    </>
  );
}
