import React, { useEffect } from "react";
import { Button } from "@jobber/components/Button";
import { type PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import type { BankConnectionButtonProps } from "./BankConnectionButtonProps.d";

export const BankPaymentACHButton = (config: BankConnectionButtonProps) => {
  const { submitPlaidLinkToken, oauthStateId } = config;
  const {
    loading,
    token,
    accountLinked,
    errorMessage,
    achAuthorizedTokenReady,
    buttonLabel,
  } = config.buttonState;

  const onExit = () => {
    localStorage.removeItem("plaidLinkToken");
    window.location.href = window.location.origin + window.location.pathname;
  };

  const receivedRedirectUri: string | undefined = oauthStateId
    ? `${window.location.origin}/client_hubs/payments/oauth?oauth_state_id=${oauthStateId}`
    : undefined;

  const plaidLinkOptions: PlaidLinkOptions = {
    token,
    onSuccess: config.onSuccess,
    onExit,
    receivedRedirectUri,
  };

  const { open, ready } = usePlaidLink(plaidLinkOptions);

  useEffect(() => {
    if (ready && oauthStateId) {
      open();
    }
  }, [ready, open]);

  return <Button loading={loading} label={buttonLabel} onClick={onClick} />;

  function onClick() {
    if (!accountLinked && !errorMessage && !achAuthorizedTokenReady) {
      open();
    } else if (achAuthorizedTokenReady) {
      submitPlaidLinkToken();
    }
  }
};
