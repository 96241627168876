import React from "react";
import classNames from "classnames";
import { Content, InputText, InputValidation, Text } from "@jobber/components";
import { BaseReadOnlyCustomField } from "./BaseReadCustomField";
import styles from "./CustomFieldTypes.module.css";

interface CustomFieldEditProps {
  value: string;
  editing: boolean;
  onChange(newValue: string): void;
}

interface CustomFieldTextProps {
  isInAppGroup?: boolean;
  label: string;
  stringValue: string;
  editProps?: CustomFieldEditProps;
  error?: string;
}

export function CustomFieldText({
  isInAppGroup = false,
  label,
  stringValue,
  editProps,
  error,
}: CustomFieldTextProps) {
  if (editProps?.editing) {
    return (
      <div
        className={classNames([styles.container])}
        title="container"
        data-testid="customFieldText"
      >
        <div
          className={classNames({
            [styles.label]: true,
            [styles.labelEdit]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div
          className={classNames([styles.value, styles.valueEdit])}
          title="value"
        >
          <Content spacing={"minuscule"}>
            <InputText
              size="small"
              placeholder={label}
              value={editProps.value}
              onChange={editProps.onChange}
              invalid={!!error}
            />
            {error && <InputValidation message={error} />}
          </Content>
        </div>
      </div>
    );
  }

  return BaseReadOnlyCustomField(isInAppGroup, label, stringValue);
}
