/* eslint-disable import/no-internal-modules */
import React from "react";
import { createPortal } from "react-dom";
import { useRefocusOnActivator } from "@jobber/hooks/useRefocusOnActivator";
import { useOnKeyDown } from "@jobber/hooks/useOnKeyDown";
import { useFocusTrap } from "@jobber/hooks/dist";
import classNames from "./FullScreenModal.module.css";

export interface FullScreenModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const FullScreenModal = ({
  open,
  children,
  onClose,
}: FullScreenModalProps) => {
  useRefocusOnActivator(open);
  useOnKeyDown(onClose, "Escape");
  const modalRef = useFocusTrap<HTMLDivElement>(open);

  if (!open) return <></>;

  const content = (
    <div role="dialog" className={classNames.dialog} ref={modalRef}>
      {children}
    </div>
  );

  return createPortal(content, document.body);
};
