import QuotesTrendlineSrc from "@images/empty-state-trendline_quotes.png";
import JobsTrendlineSrc from "@images/empty-state-trendline_jobs.png";
import InvoicesTrendlineSrc from "@images/empty-state-trendline_invoices.png";
import RequestsTrendlineSrc from "@images/empty-state-trendline_requests.png";
import type { MessageFormat } from "../types";
import { messages } from "../messages";

interface ImageType {
  src: string;
  alt: MessageFormat;
}

const QuotesTrendlineImg: ImageType = {
  src: QuotesTrendlineSrc as string,
  alt: messages.quotesTrendlineImgAltText,
};

const JobsTrendlineImg: ImageType = {
  src: JobsTrendlineSrc as string,
  alt: messages.jobsTrendlineImgAltText,
};

const InvoicesTrendlineImg: ImageType = {
  src: InvoicesTrendlineSrc as string,
  alt: messages.invoicesTrendlineImgAltText,
};

const RequestsTrendlineImg: ImageType = {
  src: RequestsTrendlineSrc as string,
  alt: messages.requestsTrendlineImgAltText,
};

export {
  type ImageType,
  QuotesTrendlineImg,
  JobsTrendlineImg,
  InvoicesTrendlineImg,
  RequestsTrendlineImg,
};
