export type AccountType = "individual" | "company";

export interface BankAccountData {
  accountType: AccountType;
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  accountOwnerName: string;
}

// Unfortunately the bankAccountFormState and BankAccountData are
// getting used interchangeably in the forms. So it's better if the formState
// keys match BankAccountData keys. Future clean up Invoicing.
export interface BankAccountFormState {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  accountOwnerName: string;
  confirmAccountNumber: string;
  isDirty: boolean;
  isValid: boolean;
  setAsDefault?: boolean;
  saveBankDetails?: boolean;
  authorizedByCustomer?: boolean;
}

export const defaultBankState: BankAccountData = {
  accountType: "individual",
  routingNumber: "",
  accountNumber: "",
  accountOwnerName: "",
  confirmAccountNumber: "",
};
