import gql from "graphql-tag";

export const AI_RECEPTIONIST_SMS_STATE_QUERY = gql`
  query AiReceptionistSmsState($id: EncodedId!) {
    aiReceptionistSmsState(id: $id) {
      status
      available
    }
  }
`;

export const AI_RECEPTIONIST_SMS_STATE_MUTATION = gql`
  mutation AiReceptionistSmsStateEdit($id: EncodedId!, $status: Boolean!) {
    aiReceptionistSmsStateEdit(input: { id: $id, status: $status }) {
      success
      userErrors {
        message
        path
      }
    }
  }
`;
