import { gql } from "@apollo/client";

export const GOOGLE_BUSINESS_ACCOUNT_EDIT_MUTATION = gql`
  mutation googleBusinessAccountEdit($input: GoogleBusinessAccountInput!) {
    googleBusinessAccountEdit(input: $input) {
      googleBusinessAccount {
        id
        isConnected
        primaryLocationName
        reviewUrl
        deliveryMethod
      }
      userErrors {
        message
        path
      }
    }
  }
`;
