import { render } from "@testing-library/react";
import { MockedProvider as ApolloMockedProvider } from "@apollo/client/testing";
import React from "react";
import type { SetupWizardEditInput } from "~/utilities/API/graphql";
import {
  SETUP_WIZARD_MUTATION,
  SETUP_WIZARD_QUERY,
} from "../SetupWizard.graphql";
import type { SetupWizardLoaderProps } from "../SetupWizard.loader";
import { SetupWizardLoader } from "../SetupWizard.loader";
import type { SetupWizardRouteProps } from "../SetupWizardRoute";
import { SetupWizardRoute } from "../SetupWizardRoute";
import {
  mockMutationInput,
  mockQueryResult,
  mockSetupWizardLoaderProps,
  mockSetupWizardRouteProps,
} from "../testHelpers";
import type { QueryResultData } from "../types";

export interface renderConfig {
  mockQueryResultData?: QueryResultData;
  mockMutationInputData?: SetupWizardEditInput;
  loaderProps?: SetupWizardLoaderProps;
  routeProps?: SetupWizardRouteProps;
}

export const renderPage = ({
  mockQueryResultData,
  mockMutationInputData,
  loaderProps,
  routeProps,
}: renderConfig = {}) => {
  const querySuccessMock = {
    request: { query: SETUP_WIZARD_QUERY },
    result: { data: mockQueryResultData ?? mockQueryResult },
  };
  const mutationSuccessMock = {
    request: {
      query: SETUP_WIZARD_MUTATION,
      variables: {
        setupWizardInput: mockMutationInputData ?? mockMutationInput,
      },
    },
    result: { data: { setupWizardEdit: { userErrors: [] } } },
  };

  const mocksToUse = [];
  mocksToUse.push(querySuccessMock);
  if (mockMutationInputData) {
    mocksToUse.push(mutationSuccessMock);
  }
  const loaderPropsToUse = loaderProps ?? mockSetupWizardLoaderProps;
  const routePropsToUse = routeProps ?? mockSetupWizardRouteProps;
  return render(
    <ApolloMockedProvider mocks={mocksToUse}>
      <SetupWizardLoader routing={{ base: "/" }} {...loaderPropsToUse}>
        <SetupWizardRoute {...routePropsToUse} />
      </SetupWizardLoader>
    </ApolloMockedProvider>,
  );
};
