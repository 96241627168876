import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { defaultSteps } from "../stepsDefinition";

export function useSetupGuideSteps() {
  const history = useHistory();
  const routerLocation = useLocation();

  const totalSteps =
    defaultSteps.filter(step => step.includeInProgress).length - 1;
  const currentStepIndex = defaultSteps
    .map(step => step.route.toString())
    .indexOf(routerLocation.pathname);
  const completedSteps = defaultSteps.slice(0, currentStepIndex);
  const progressBarStep = completedSteps.reduce((greatestIndexSoFar, step) => {
    return step.includeInProgress ? greatestIndexSoFar + 1 : greatestIndexSoFar;
  }, 0);

  const onNext = useCallback(() => {
    if (currentStepIndex + 1 >= defaultSteps.length) {
      history.push("/reviews");
    } else {
      const nextStep = defaultSteps[currentStepIndex + 1].route;
      history.push(nextStep.toString());
    }
  }, [currentStepIndex, history]);

  function goBack() {
    if (currentStepIndex > 0) {
      const previousStep = defaultSteps[currentStepIndex - 1].route;
      history.replace(previousStep.toString());
    } else {
      window.location.href = "/reviews";
    }
  }

  return {
    progressBarStep,
    totalSteps,
    showBackButton: defaultSteps[currentStepIndex]?.showBackButton || false,
    goBack,
    onNext,
  };
}
