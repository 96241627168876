import React, { type ReactElement, useContext } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import type { Library } from "@googlemaps/js-api-loader";
import { Banner } from "@jobber/components/Banner";
import { Spinner } from "@jobber/components/Spinner";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { GoogleMapsApiContext } from "jobber/settings/selfServeBookings/GoogleMapsApiContext";

interface GoogleMapsWrapperProps {
  children: ReactElement;
  libraries?: Library[];
}

export function GoogleMapsWrapper({
  children,
  libraries,
}: GoogleMapsWrapperProps): ReactElement {
  const apiKey = useContext<string>(GoogleMapsApiContext);
  const render = (status: Status) => {
    if (status === Status.FAILURE) return <ErrorComponent />;
    if (status === Status.LOADING) return <Spinner />;
    return children;
  };

  return <Wrapper apiKey={apiKey} render={render} libraries={libraries} />;
}

function ErrorComponent() {
  Rollbar.EXECUTE(
    "Google Maps failed to load",
    new Error("Google Maps failed to load"),
  );
  return <Banner type={"error"}>Google Maps failed to load</Banner>;
}
