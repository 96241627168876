import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  filterDrawerTitle: {
    id: "reporting.report.filterDrawerTitle",
    defaultMessage: "Report Filters",
    description: "Title for filter drawer",
  },
  filterApply: {
    id: "reporting.report.filterApply",
    defaultMessage: "Apply",
    description: "Apply text for report filters",
  },
});
