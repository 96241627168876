import React, { type ReactNode } from "react";
import styles from "./TableCell.module.css";

interface TableCellProps {
  children: ReactNode;
}

export const TableCell = ({ children }: TableCellProps) => {
  return <div className={styles.cell}>{children}</div>;
};
