import { gql } from "@apollo/client";

export const QBO_ERROR_QUERY = gql`
  query syncObjectStats(
    $integrationType: String!
    $modelId: Int!
    $modelType: String!
  ) {
    syncObjectStats(
      integrationType: $integrationType
      modelId: $modelId
      modelType: $modelType
    ) {
      modelId
      modelType
      message
    }
  }
`;
