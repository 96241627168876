import { gql } from "@apollo/client";

export const notificationFieldsFragment = gql`
  fragment notificationFields on ClientNotification {
    id
    enabled
    featureEnabled {
      enabled
      toggleable
    }
    templates {
      nodes {
        ...messageTemplate
      }
    }
    schedules(first: 2) {
      nodes {
        id
        enabled
        deliveryMethod
        before
        offset {
          value
          unit
        }
        at
      }
    }
  }

  fragment variableSection on TemplateVariableSection {
    label
    variables {
      nodes {
        ...variableFields
      }
    }
  }

  fragment variableFields on TemplateVariable {
    label
    name
    sample
  }

  fragment stringWithDefaultFields on StringWithDefault {
    current
    default
  }

  fragment messageTemplate on MessageTemplate {
    ... on MessageTemplateSMS {
      id
      name
      deliveryMethod
      message {
        ...stringWithDefaultFields
      }
      variables {
        nodes {
          ...variableSection
        }
      }
      footerText
    }
    ... on MessageTemplateEmail {
      id
      name
      deliveryMethod
      subject {
        ...stringWithDefaultFields
      }
      message {
        ...stringWithDefaultFields
      }
      variables {
        nodes {
          ...variableSection
        }
      }
    }
    ... on MessageTemplateSurvey {
      id
      name
      deliveryMethod
      surveyEnabled
      subject {
        ...stringWithDefaultFields
      }
      message {
        ...stringWithDefaultFields
      }
      survey {
        ...stringWithDefaultFields
      }
      variables {
        nodes {
          ...variableSection
        }
      }
    }
  }
`;
