import React, { useEffect, useState } from "react";
import {
  Button,
  Content,
  Flex,
  Heading,
  Link,
  StatusLabel,
  Text,
  Typography,
} from "@jobber/components";
import { showToast } from "@jobber/components/Toast";
import { type IntlFormatters, useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import type {
  JobberPaymentsLimitsChangeRequest,
  JobberPaymentsLimitsChangeRequestStatus,
} from "~/utilities/API/graphql";
import { JobberPaymentsLimitsChangeRequestStatus as JobberPaymentsLimitsChangeRequestEnum } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import {
  useAchLimitsChangeRequest,
  useJobberPaymentsLimitsChangeRequestCreate,
} from "./hooks";

interface ManageACHLimitsProps {
  achTier?: string;
  canRequestAchLimitIncrease: boolean;
}

const getStatusDetails = (
  status: JobberPaymentsLimitsChangeRequestStatus | undefined,
  formatMessage: IntlFormatters["formatMessage"],
) => {
  const statusDetailsMap: Record<
    JobberPaymentsLimitsChangeRequestStatus,
    { statusLabel: JSX.Element | null; message: string | null }
  > = {
    [JobberPaymentsLimitsChangeRequestEnum.APPROVED]: {
      statusLabel: (
        <StatusLabel
          status="success"
          label={formatMessage(messages.requestApproved)}
        />
      ),
      message: formatMessage(messages.requestedLimitApproved),
    },
    [JobberPaymentsLimitsChangeRequestEnum.PENDING]: {
      statusLabel: (
        <StatusLabel
          status="warning"
          label={formatMessage(messages.requestPending)}
        />
      ),
      message: formatMessage(messages.requestedLimitProcessing),
    },
    [JobberPaymentsLimitsChangeRequestEnum.DECLINED]: {
      statusLabel: (
        <StatusLabel
          status="critical"
          label={formatMessage(messages.requestDeclined)}
        />
      ),
      message: formatMessage(messages.requestedLimitDeclined),
    },
    [JobberPaymentsLimitsChangeRequestEnum.CANCELLED]: {
      statusLabel: null,
      message: null,
    },
  };

  return status
    ? statusDetailsMap[status]
    : { statusLabel: null, message: null };
};

export function ManageACHLimits({
  canRequestAchLimitIncrease,
  achTier,
}: ManageACHLimitsProps) {
  const { formatMessage, formatDate } = useIntl();
  const { data: limitsChangeRequestResponse } = useAchLimitsChangeRequest();
  const { extraSmallOnly } = useBreakpoints();
  const [limitsChangeRequest, setLimitsChangeRequest] = useState<
    JobberPaymentsLimitsChangeRequest | undefined
  >(undefined);

  useEffect(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "Request Limit Increase",
      ach_tier: achTier,
      disabled: !canRequestAchLimitIncrease,
    });
  }, [achTier, canRequestAchLimitIncrease]);

  useEffect(() => {
    if (limitsChangeRequestResponse) {
      setLimitsChangeRequest(limitsChangeRequestResponse);
    }
  }, [limitsChangeRequestResponse]);

  const { createAchPaymentsLimitsRequest } =
    useJobberPaymentsLimitsChangeRequestCreate();

  const headingAndStatusLabel = extraSmallOnly ? "column" : "row";

  const statusDetails = getStatusDetails(
    limitsChangeRequest?.status,
    formatMessage,
  );

  return (
    <Content>
      <Content>
        {limitsChangeRequest?.status ? (
          <Content>
            <Flex
              template={["grow", "shrink"]}
              direction={headingAndStatusLabel}
            >
              <Heading level={4}>
                {formatMessage(messages.achLimitIncrease)}
              </Heading>
              {statusDetails.statusLabel}
            </Flex>
            {limitsChangeRequest?.requestedAt && (
              <Text variation="subdued">
                {formatMessage(messages.requestedOn, {
                  date: formatDate(limitsChangeRequest.requestedAt, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }),
                })}
              </Text>
            )}
            <Text>{statusDetails.message}</Text>
          </Content>
        ) : (
          <>
            <Heading level={4}>
              {formatMessage(messages.manageAchLimits)}
            </Heading>
            <Text>
              {canRequestAchLimitIncrease && !limitsChangeRequest?.status ? (
                <>
                  {formatMessage(messages.manageAchLimitsDescriptionAllowed)}
                  &nbsp;
                  <Typography element={"span"} fontWeight="semiBold">
                    <Link
                      url={
                        "https://help.getjobber.com/hc/en-us/articles/1500004781762-Bank-Payments-ACH"
                      }
                      external={true}
                      ariaLabel={formatMessage(
                        messages.learnMoreAboutACHButton,
                      )}
                    >
                      {formatMessage(messages.learnMoreAboutACHButton)}
                    </Link>
                  </Typography>
                </>
              ) : !canRequestAchLimitIncrease ? (
                <>
                  {formatMessage(messages.manageAchLimitsDescriptionNotAllowed)}
                  &nbsp;
                  <Typography element={"span"} fontWeight="semiBold">
                    <Link
                      url={
                        "https://help.getjobber.com/hc/en-us/articles/1500004781762-Bank-Payments-ACH"
                      }
                      external={true}
                      ariaLabel={formatMessage(
                        messages.learnMoreAboutACHButton,
                      )}
                    >
                      {formatMessage(messages.learnMoreAboutACHButton)}
                    </Link>
                  </Typography>
                </>
              ) : null}
            </Text>
            {canRequestAchLimitIncrease && !limitsChangeRequest?.status ? (
              <Button
                label={formatMessage(messages.requestLimitIncreaseButton)}
                variation="work"
                onClick={requestLimitIncrease}
                type="primary"
                size="small"
              />
            ) : null}
          </>
        )}
      </Content>
    </Content>
  );

  async function requestLimitIncrease() {
    const { jobberPaymentsLimitsChangeRequest, errorMessage } =
      await createAchPaymentsLimitsRequest();

    if (jobberPaymentsLimitsChangeRequest) {
      setLimitsChangeRequest(jobberPaymentsLimitsChangeRequest);
      showToast({
        message: formatMessage(messages.requestLimitIncreaseSubmitted),
        variation: "success",
      });
    } else {
      showToast({
        message:
          errorMessage ||
          formatMessage(messages.requestLimitIncreaseSubmittedError),
        variation: "error",
      });
    }
  }
}
