import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { TimesheetCreateMutation } from "~/utilities/API/graphql";
import { Intercom } from "utilities/chat";
import type { TimesheetEntryInput } from "./types";
import {
  JOB_COST_LABOUR_TOTALS_QUERY,
  JobCostTimeSheetEntry,
  TIMESHEET_CREATE,
} from "./graphql";
import { messages } from "./messages";

interface UseTimesheetMutationData {
  jobId: string;
}

export function useCreateTimesheetMutation(
  data: UseTimesheetMutationData,
  canViewJobCosts: boolean,
  closeModal: () => void,
): {
  handleSubmit(input: TimesheetEntryInput): void;
  loading: boolean;
} {
  const [create, { loading }] = useMutation<TimesheetCreateMutation>(
    TIMESHEET_CREATE,
    {
      variables: {
        canViewJobCosts,
      },
      refetchQueries: [
        {
          query: JOB_COST_LABOUR_TOTALS_QUERY,
          variables: {
            jobId: data.jobId,
            canViewJobCosts: canViewJobCosts,
          },
        },
      ],
      update(cache, result) {
        if (result.data?.timeSheetEntryCreate?.timeSheetEntry) {
          const newTimeSheetEntry =
            result.data.timeSheetEntryCreate.timeSheetEntry;
          cache.modify({
            id: `JobViewLabour:${data.jobId}`,
            fields: {
              items(existingTimeSheetRefs = []) {
                const newEntryRef = cache.writeFragment({
                  data: newTimeSheetEntry,
                  fragment: JobCostTimeSheetEntry,
                  variables: {
                    canViewJobCosts,
                  },
                });
                return {
                  ...existingTimeSheetRefs,
                  nodes: [newEntryRef, ...existingTimeSheetRefs.nodes],
                  totalCount: existingTimeSheetRefs.totalCount + 1,
                };
              },
            },
          });
        }
      },
    },
  );

  async function handleSubmit(input: TimesheetEntryInput) {
    try {
      const result = await create({
        variables: {
          input: {
            jobId: data.jobId,
            ...input,
          },
        },
      });

      const errors = result.data?.timeSheetEntryCreate.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.timeEntrySaved,
          variation: "success",
        });
        Intercom.EXECUTE("trackEvent", "user_timesheetwithinjob_entry");
        closeModal();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleSubmit,
    loading,
  };
}
