import React from "react";
import { SideDrawer } from "components/SideDrawer";
import { DrawerMode } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { PastClientsSegmentEdit } from "jobber/campaigns/views/SelectClientSegmentPage/components/PastClientsSegmentEdit/PastClientsSegmentEdit";
import type {
  ClientSegmentFragment,
  GetCommsClientsSegmentQueryVariables,
  PastClientsCriteriaInput,
  Segment,
} from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { ClientSegmentsData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { useOnEditClientSegmentCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useOnEditClientSegmentCriteria";
import { AllClientsSegmentEdit } from "jobber/campaigns/views/SelectClientSegmentPage/components/AllClientsSegmentEdit/AllClientsSegmentEdit";
import type { ClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";
import {
  isAllClientCriteriaInput,
  isPastClientCriteriaInput,
  isUpcomingClientCriteriaInput,
} from "./utils";
import { ClientSegmentView } from "./ClientSegmentView";
import { UpcomingClientsSegmentEdit } from "../UpcomingClientsSegmentEdit";

export interface CriteriaProps {
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  baseCriteria: ClientsCriteriaFromSegment;

  setBaseCriteria(criteria: PastClientsCriteriaInput, isDirty?: boolean): void;
}

export interface ClientSegmentData {
  rawData: ClientSegmentFragment | undefined;
  clientSegments: ClientSegmentsData;
}

export interface LoadingState {
  loadingMore: boolean;
  fullReload: boolean;
}

interface ClientSegmentDrawerProps {
  isOpen: boolean;

  closeSideDrawer(): void;

  drawerState: DrawerMode;
  segmentName: string;

  setDrawerState(drawerState: DrawerMode): void;

  clientSegmentData: ClientSegmentData;

  refetch(variables: GetCommsClientsSegmentQueryVariables): void;

  fetchData(variables: GetCommsClientsSegmentQueryVariables): void;

  criteria: CriteriaProps;

  setClientSegment(clientSegment: ClientSegmentFragment): void;

  loadingState: LoadingState;
  selectedSegment: Segment;
}

export function ClientSegmentDrawer({
  isOpen,
  closeSideDrawer,
  drawerState,
  segmentName,
  setDrawerState,
  clientSegmentData,
  criteria,
  setClientSegment,
  refetch,
  selectedSegment,
}: ClientSegmentDrawerProps) {
  const { onEditClientSegment, saveError, updateSegmentCriteriaLoading } =
    useOnEditClientSegmentCriteria({
      clientSegmentData,
      closeSideDrawer,
      setClientSegment,
      resetPagination: refetch,
      segmentType: selectedSegment,
    });

  return (
    <SideDrawer
      open={isOpen}
      requestDrawerClose={closeSideDrawer}
      mobileFullWidth={true}
    >
      {drawerState === DrawerMode.View && (
        <ClientSegmentView
          closeSideDrawer={closeSideDrawer}
          criteria={criteria}
          selectedSegment={selectedSegment}
          setDrawerState={setDrawerState}
          segmentName={segmentName}
        />
      )}

      {drawerState === DrawerMode.Edit &&
        isPastClientCriteriaInput(criteria.baseCriteria, selectedSegment) && (
          <PastClientsSegmentEdit
            segmentCriteria={criteria.baseCriteria}
            additionalCriteria={criteria.additionalCriteria}
            updateAdditionalCriteria={criteria.updateAdditionalCriteria}
            onSave={onEditClientSegment}
            setDrawerState={setDrawerState}
            closeSideDrawer={closeSideDrawer}
            saveError={saveError}
            loading={updateSegmentCriteriaLoading}
          />
        )}

      {drawerState === DrawerMode.Edit &&
        isUpcomingClientCriteriaInput(
          criteria.baseCriteria,
          selectedSegment,
        ) && (
          <UpcomingClientsSegmentEdit
            segmentCriteria={criteria.baseCriteria}
            additionalCriteria={criteria.additionalCriteria}
            updateAdditionalCriteria={criteria.updateAdditionalCriteria}
            onSave={onEditClientSegment}
            setDrawerState={setDrawerState}
            closeSideDrawer={closeSideDrawer}
            saveError={saveError}
            loading={updateSegmentCriteriaLoading}
          />
        )}

      {drawerState === DrawerMode.Edit &&
        isAllClientCriteriaInput(criteria.baseCriteria, selectedSegment) && (
          <AllClientsSegmentEdit
            segmentCriteria={criteria.baseCriteria}
            additionalCriteria={criteria.additionalCriteria}
            updateAdditionalCriteria={criteria.updateAdditionalCriteria}
            onSave={onEditClientSegment}
            setDrawerState={setDrawerState}
            closeSideDrawer={closeSideDrawer}
            saveError={saveError}
            loading={updateSegmentCriteriaLoading}
          />
        )}
    </SideDrawer>
  );
}
