import React, { useCallback, useMemo } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { RecommendationButton } from "./RecommendationButton";
import styles from "./styles.module.css";
import {
  LoadingAndErrorRecommendationButton,
  type RecommendationButtonErrorMessage,
} from "./LoadingAndErrorRecommendationButton";
import { useRecommendations } from "./useRecommendationMachine";
import type { LocationCoordinates } from "./RecommendationsSchedulingContainer";
import { TeamMemberSelection } from "./TeamMemberSelection";
import {
  type SchedulingAvailabilityPayload,
  useScheduleCardChangeHandlers,
  useScheduleCardState,
  useSchedulingRecommendationsTracking,
} from "../hooks";
import { useAssignTeam } from "../../AssignTeam";
import { VisitDuration } from "../../VisitDuration";

export interface RecommendationButtonsProps {
  loading: boolean;
  recommendations: SchedulingAvailabilityPayload;
  hasError: boolean;
  locationCoordinates?: LocationCoordinates;
}

export function RecommendationButtons({
  loading,
  recommendations,
  hasError,
  locationCoordinates,
}: RecommendationButtonsProps) {
  const { setSelectedAssignees } = useAssignTeam();
  const {
    sendClosestSchedulingSelectedEvent,
    sendSoonestSchedulingSelectedEvent,
  } = useSchedulingRecommendationsTracking();
  const { handleStartDateAndTimeChange, handleEndDateAndTimeChange } =
    useScheduleCardChangeHandlers();
  const { stateMatches: schedulingStateMatches } = useScheduleCardState();

  const {
    onScheduleRecommendationSelect,
    stateMatches: recommendationStateMatches,
  } = useRecommendations();
  const onSelectRecommendation = useCallback(
    (type: "soonest" | "closest") => {
      const sendAnalytics =
        type === "soonest"
          ? sendSoonestSchedulingSelectedEvent
          : sendClosestSchedulingSelectedEvent;

      const recommendation = recommendations[type];

      if (recommendation) {
        sendAnalytics();

        handleStartDateAndTimeChange(recommendation.recommendedStartAt);
        handleEndDateAndTimeChange(recommendation.recommendedEndAt);

        const assigneeId = recommendation.recommendedAssignee?.id;
        if (assigneeId) {
          setSelectedAssignees([assigneeId]);
        }

        onScheduleRecommendationSelect({
          action: type === "soonest" ? "scheduleSoonest" : "scheduleClosest",
        });
      }
    },
    [
      handleStartDateAndTimeChange,
      handleEndDateAndTimeChange,
      recommendations,
      setSelectedAssignees,
      sendClosestSchedulingSelectedEvent,
      sendSoonestSchedulingSelectedEvent,
      onScheduleRecommendationSelect,
    ],
  );

  const shortestDriveMessage: RecommendationButtonErrorMessage | undefined =
    useMemo(() => {
      if (hasError) {
        return {
          message: "Unable to generate recommendation",
          style: "error",
          icon: "alert",
        };
      } else if (!locationCoordinates) {
        return {
          message: "Drive time unavailable for this address",
          style: "subdued",
        };
      }
    }, [locationCoordinates, hasError]);

  const soonestAvailableMessage: RecommendationButtonErrorMessage | undefined =
    useMemo(() => {
      return hasError
        ? {
            message: "Unable to generate recommendation",
            style: "error",
            icon: "alert",
          }
        : undefined;
    }, [hasError]);

  return (
    <Content spacing="small">
      <div className={styles.recommendationGridContainer}>
        <RecommendationsHeading />
        <fieldset className={styles.recommendationsButtonContainer}>
          {!loading && !hasError ? (
            <RecommendationButton
              recommendation={recommendations?.soonest}
              heading="Soonest available"
              isSelected={!!recommendationStateMatches("scheduleSoonest")}
              icon="timer"
              onSelectRecommendation={() => onSelectRecommendation("soonest")}
              disabled={schedulingStateMatches("scheduling.scheduleLater")}
            />
          ) : (
            <LoadingAndErrorRecommendationButton
              heading="Soonest available"
              icon="timer"
              loading={loading}
              errorMessage={soonestAvailableMessage}
            />
          )}
          {!loading && !hasError && locationCoordinates ? (
            <RecommendationButton
              heading="Shortest drive"
              icon="visit"
              isSelected={!!recommendationStateMatches("scheduleClosest")}
              onSelectRecommendation={() => onSelectRecommendation("closest")}
              recommendation={recommendations?.closest}
              disabled={schedulingStateMatches("scheduling.scheduleLater")}
            />
          ) : (
            <LoadingAndErrorRecommendationButton
              heading="Shortest drive"
              icon="visit"
              loading={loading}
              errorMessage={shortestDriveMessage}
            />
          )}
        </fieldset>
      </div>
    </Content>
  );
}

function RecommendationsHeading() {
  const { stateMatches: schedulingStateMatches, schedulingState } =
    useScheduleCardState();
  const { handleVisitDurationChange } = useScheduleCardChangeHandlers();

  const visitDurationDisabled =
    schedulingStateMatches("dates.disabled") &&
    schedulingStateMatches("times.disabled");

  return (
    <div className={styles.recommendationsHeadingContainer}>
      <div className={styles.headingTitleContainer}>
        <Icon name="sparkles" color="blue" />
        <Heading level={5}>Suggestions</Heading>
        <div className="recommendations-intercom-cta" />
      </div>
      <div className={styles.schedulingParametersContainer}>
        <VisitDuration
          value={schedulingState.visitDuration}
          onChange={handleVisitDurationChange}
          disabled={visitDurationDisabled}
        />
        <TeamMemberSelection />
      </div>
    </div>
  );
}
