import React from "react";
import classnames from "classnames";
import {
  type MessageDataFragment,
  MessageDirectionEnum,
} from "~/utilities/API/graphql";
import { MessageFooter } from "jobber/features/MessageCenter/views/Conversation/components/MessageFooter";
import { MessageHeader } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader";
import { MessageBubble } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble";
import styles from "./MessageContainer.module.css";

interface MessageContainerProps {
  readonly status?: "sent" | "failed" | "queued";
  readonly fullMessage: MessageDataFragment;
  readonly externalName?: string;
  showTimeStamp: boolean;
  showInternalName: boolean;
}

export function MessageContainer({
  status = "sent",
  fullMessage,
  externalName,
  showTimeStamp,
  showInternalName,
}: MessageContainerProps) {
  const isInbound = fullMessage.direction === MessageDirectionEnum.INBOUND;
  const timeStampClass = getTimeStampClass(isInbound, status);

  return (
    <div className={classnames(styles.messageContainer)}>
      <MessageHeader
        message={fullMessage}
        externalName={externalName}
        showInternalName={showInternalName}
      />

      <MessageBubble message={fullMessage} />

      <div
        className={timeStampClass}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- Grandfathered error: Please fix if touching this code.
        tabIndex={0}
        data-testid="ChatBubbleTimestampContainer"
      >
        <MessageFooter showTimeStamp={showTimeStamp} message={fullMessage} />
      </div>
    </div>
  );
}

function getTimeStampClass(
  isInbound: boolean,
  status: Exclude<MessageContainerProps["status"], undefined>,
) {
  if (isInbound) {
    return classnames(styles.inboundTimeStamp);
  }
  switch (status) {
    case "failed":
      return classnames(styles.errorStateTimeStamp);
    case "queued":
    case "sent":
    default:
      return classnames(styles.outboundTimeStamp);
  }
}
