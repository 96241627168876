import React from "react";
import { FormattedMessage } from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { messages } from "./messages";

export const quotes = {
  arboristIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.arboristIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.arboristIndustryQuoteName,
    company: messages.arboristIndustryQuoteCompany,
    location: messages.arboristIndustryQuoteLocation,
  },
  cleaningIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.cleaningIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.cleaningIndustryQuoteName,
    company: messages.cleaningIndustryQuoteCompany,
    location: messages.cleaningIndustryQuoteLocation,
  },
  constructionIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.constructionIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.constructionIndustryQuoteName,
    company: messages.constructionIndustryQuoteCompany,
    location: messages.constructionIndustryQuoteLocation,
  },
  defaultQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.defaultQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.defaultQuoteName,
    company: messages.defaultQuoteCompany,
    location: messages.defaultQuoteLocation,
  },
  electricalIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.electricalIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.electricalIndustryQuoteName,
    company: messages.electricalIndustryQuoteCompany,
    location: messages.electricalIndustryQuoteLocation,
  },
  hvacIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.hvacIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.hvacIndustryQuoteName,
    company: messages.hvacIndustryQuoteCompany,
    location: messages.hvacIndustryQuoteLocation,
  },
  landscapingIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.landscapingIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.landscapingIndustryQuoteName,
    company: messages.landscapingIndustryQuoteCompany,
    location: messages.landscapingIndustryQuoteLocation,
  },
  lawnIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.lawnIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.lawnIndustryQuoteName,
    company: messages.lawnIndustryQuoteCompany,
    location: messages.lawnIndustryQuoteLocation,
  },
  paintingIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.paintingIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.paintingIndustryQuoteName,
    company: messages.paintingIndustryQuoteCompany,
    location: messages.paintingIndustryQuoteLocation,
  },
  plumbingIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.plumbingIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.plumbingIndustryQuoteName,
    company: messages.plumbingIndustryQuoteCompany,
    location: messages.plumbingIndustryQuoteLocation,
  },
  pressureWashingIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.pressureWashingIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.pressureWashingIndustryQuoteName,
    company: messages.pressureWashingIndustryQuoteCompany,
    location: messages.pressureWashingIndustryQuoteLocation,
  },
  windowWashingIndustryQuote: {
    copy: (
      <Heading data-testid="quote-copy" level={2}>
        <FormattedMessage
          {...messages.windowWashingIndustryQuoteCopy}
          values={{
            emphasis: chunks => (
              <Emphasis variation="highlight">{chunks}</Emphasis>
            ),
          }}
        />
      </Heading>
    ),
    name: messages.windowWashingIndustryQuoteName,
    company: messages.windowWashingIndustryQuoteCompany,
    location: messages.windowWashingIndustryQuoteLocation,
  },
};
