import { CampaignsFilterFields, Template } from "~/utilities/API/graphql";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_MAIN_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "./constants";

export function isAutomatedCampaignType(
  templateType: Template | undefined,
  isAutomated?: boolean | undefined,
) {
  return (
    isAutomated ||
    templateType === Template.AUTOMATED_RE_ENGAGE ||
    templateType === Template.AUTOMATED_WIN_BACK_LOST_LEADS ||
    templateType === Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES
  );
}

export function isEnumValue<T extends object>(
  enumObj: T,
  value: unknown,
): value is T[keyof T] {
  return Object.values(enumObj).includes(value as T[keyof T]);
}

export function mainPath(templateType: Template) {
  return isAutomatedCampaignType(templateType)
    ? `${CAMPAIGNS_MAIN_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${CampaignsFilterFields.AUTOMATED}`
    : CAMPAIGNS_MAIN_PATH;
}

export function landingPath(templateType: Template) {
  return isAutomatedCampaignType(templateType)
    ? `${CAMPAIGNS_LANDING_PAGE_PATH}/?${URL_PARAM_CAMPAIGNS_TYPE}=${CampaignsFilterFields.AUTOMATED}`
    : CAMPAIGNS_LANDING_PAGE_PATH;
}
