import { v4 as uuidV4 } from "uuid";
import React, { useEffect, useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Option, Select } from "@jobber/components/Select";
import { useMutation } from "@apollo/client";
import {
  AiAssistantTextReviseContentType,
  type AiAssistantTextReviseExchange,
  AiAssistantTextReviseIntent,
  type AiAssistantTextReviseMutation,
  type AiAssistantTextReviseMutationVariables,
  AiAssistantTextReviseOperation,
} from "~/utilities/API/graphql";
import { TextRewrite } from "components/TextRewrite";
import styles from "./InputTextLlmPoc.module.css";
import { AI_ASSISTANT_TEXT_REVISE } from "./InputTextLlmPoc.graphql";

const CONTENT_TYPE = AiAssistantTextReviseContentType.EMAIL_TEMPLATE;
const TONEBOT_TRACKING_SOURCE = "tonebot-poc";

export const textIntents = [
  { label: "", intentType: undefined },
  { label: "Re-engage", intentType: AiAssistantTextReviseIntent.REENGAGE },
  { label: "Upsell", intentType: AiAssistantTextReviseIntent.UPSELL },
  { label: "Win back", intentType: AiAssistantTextReviseIntent.WIN_BACK },
];

// Possible Text Operations
export const textOperations = [
  { label: "Casual", operationType: AiAssistantTextReviseOperation.CASUAL },
  { label: "Cheerful", operationType: AiAssistantTextReviseOperation.CHEERFUL },
  { label: "Lengthen", operationType: AiAssistantTextReviseOperation.LENGTHEN },
  {
    label: "Professional",
    operationType: AiAssistantTextReviseOperation.PROFESSIONAL,
  },
  { label: "Serious", operationType: AiAssistantTextReviseOperation.SERIOUS },
  { label: "Shorten", operationType: AiAssistantTextReviseOperation.SHORTEN },
];
type RevisionResult = AiAssistantTextReviseMutation | null | undefined;

interface ModifyOperationResult {
  content: string;
  operation?: AiAssistantTextReviseOperation;
  intent?: AiAssistantTextReviseIntent;
}

interface InputTextLlmPocProps {
  readonly defaultValue?: string;
  readonly setSampleContent?: (content: string) => void;
}

// eslint-disable-next-line max-statements
export function InputTextLlmPoc({ defaultValue = "" }: InputTextLlmPocProps) {
  const [conversationId] = useState(uuidV4());
  const [content, setContent] = useState(defaultValue);
  const [contentHistory, setContentHistory] = useState<ModifyOperationResult[]>(
    [],
  );
  const [intent, setIntent] = useState<AiAssistantTextReviseIntent>();
  const [operation, setOperation] = useState<AiAssistantTextReviseOperation>(
    textOperations[0].operationType,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [modifyText] = useMutation<
    AiAssistantTextReviseMutation,
    AiAssistantTextReviseMutationVariables
  >(AI_ASSISTANT_TEXT_REVISE);

  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  return (
    <Card>
      <Content>
        {errorMessage != "" && (
          <Banner type={"error"} dismissible={false}>
            {errorMessage}
          </Banner>
        )}
        <InputText
          multiline
          rows={14}
          value={content}
          onChange={(value: string) => setContent(value)}
        />
        <TextRewrite
          setError={err => err && setErrorMessage(err.message)}
          loading={isLoading}
          setLoading={setIsLoading}
          text={content}
          setText={setContent}
          trackingSource={TONEBOT_TRACKING_SOURCE}
          contentType={AiAssistantTextReviseContentType.PLAIN_TEXT}
        />
        <div className={styles.buttonRow}>
          <Button
            onClick={() => setContent(defaultValue)}
            type="secondary"
            variation="destructive"
            label="Reset"
            disabled={content === defaultValue}
          />
          <span className={styles.intentContainer}>Intent:</span>
          <div className={styles.intentSelect}>
            <Select
              value={intent}
              onChange={(newIntent: AiAssistantTextReviseIntent) =>
                setIntent(newIntent)
              }
              size="small"
            >
              {textIntents.map(textIntent => (
                <Option value={textIntent.intentType} key={textIntent.label}>
                  {textIntent.label}
                </Option>
              ))}
            </Select>
          </div>
          <span className={styles.operationContainer}>Make this more:</span>
          <div className={styles.operationSelect}>
            <Select
              value={operation}
              onChange={(op: AiAssistantTextReviseOperation) =>
                setOperation(op)
              }
              size="small"
            >
              {textOperations.map(op => (
                <Option value={op.operationType} key={op.label}>
                  {op.label}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            onClick={() => handleSubmit()}
            type="secondary"
            label="Generate"
            icon="sparkles"
            disabled={content === ""}
            loading={isLoading}
          />
        </div>
        {contentHistory.map((c, index) => (
          <div key={index} className={styles.contentPreview}>
            <h4>
              {contentHistory.length - index}: {c.operation}
              {c.intent && ` - ${c.intent}`}
            </h4>
            <p
              className={styles.contentPreviewText}
              // For Proof of Concept, we'll allow this exception. Content is coming from a trusted source.
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: c.content.toString() }}
            />
          </div>
        ))}
      </Content>
    </Card>
  );

  function handleSubmit() {
    if (content !== "") {
      setErrorMessage("");
      setIsLoading(true);

      // If contentHistory is empty, let's put the starting content in there
      if (contentHistory.length === 0) {
        setContentHistory([{ content }]);
      }

      modifyText({
        variables: {
          conversationId,
          input: {
            contentType: CONTENT_TYPE,
            submission: content,
            operation,
            ...(intent ? { intent } : {}),
          },
        },
      })
        .then(result => {
          const textRevision = processTextRevisionData(result?.data);
          setContent(textRevision);
          setErrorMessageIfPresent(result?.data);

          const revisedContent = {
            content: textRevision,
            operation,
            intent,
          };
          setContentHistory(prevContentHistory => [
            revisedContent,
            ...prevContentHistory,
          ]);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setErrorMessage(error.message);
        });
    }
  }

  function setErrorMessageIfPresent(data: RevisionResult) {
    const result = data?.aiAssistantTextRevise?.message
      ?.content as AiAssistantTextReviseExchange;
    const errors = result.errors;
    if (errors) {
      setErrorMessage(errors.join(" "));
    }
  }

  function processTextRevisionData(data: RevisionResult): string {
    const result = data?.aiAssistantTextRevise?.message
      ?.content as AiAssistantTextReviseExchange;
    const revisedContent = result.revision;

    if (!revisedContent) {
      return content;
    }
    return revisedContent;
  }
}
