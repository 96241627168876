import React, { useEffect, useReducer } from "react";
import {
  type ServerCreditCard,
  mapServerCreditCard,
} from "jobber/payments_sca/utils/mapServerCreditCard";
import { IntlProvider } from "@translations/IntlProvider";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import type {
  AchProcessingRate,
  ProcessingRates,
} from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import type { BankAccountProps } from "./BankAccount";
import { BankAccountProgress, type SuccessBank } from "./bankAccountReducer";
import { JobberPaymentsSettings } from "./JobberPaymentsSettings";
import {
  type Progress,
  init,
  managedAccountReducer,
} from "./managedAccountReducer";
import { Setup } from "./Setup";
import { HostedOnboardingProgress } from "./verifyReducer";
import type { CardReadersProps } from "./CardReaders";
import type { PaymentsCardProps } from "./PaymentsCard";

const POLL_MAX_WAIT_IN_MS = 5 * 60 * 1000;
const WAIT_TIME_BEFORE_NEXT_POLL_IN_MS = 1000;

interface ManagedAccountProps {
  supportsInstantPayouts: boolean;
  instantPayoutDebitCardInfo?: ServerCreditCard;
  checkSignupProgressUrl: string;
  chargesEnabled: boolean;
  isInPaymentsOneClickOnboarding: boolean;
  hostedOnboardingProgress: HostedOnboardingProgress;
  bankAccountProgress: BankAccountProgress;
  bankDetails: SuccessBank;
  splashImgSrc: string;
  processingRates: ProcessingRates;
  hostedOnboardingUrlCreationUrl: string;
  bankAccountProps: BankAccountProps;
  demoJobberPayments: boolean;
  fromInlineSignup: boolean;
  cardReaderProps: CardReadersProps;
  hasLoan: boolean;
  payoutsErrorMessage?: string;
  trackingSourceParam: string;
  achProcessingRate?: AchProcessingRate;
  previousRoute?: string;
  paymentCard: PaymentsCardProps;
  shouldShowJobberPaymentCard: boolean;
  shouldShowDisputesRow: boolean;
  receivedFirstPayout: boolean;
  manualPayoutsIsEnabled: boolean;
  payoutDelayDaysType: "business" | "calendar";
}

function ManagedAccount(props: ManagedAccountProps) {
  const {
    checkSignupProgressUrl,
    processingRates,
    achProcessingRate,
    paymentCard,
    shouldShowJobberPaymentCard,
    shouldShowDisputesRow,
    receivedFirstPayout,
    manualPayoutsIsEnabled,
    payoutDelayDaysType,
  } = props;
  const [
    {
      showSettings,
      chargesEnabled,
      isInPaymentsOneClickOnboarding,
      hostedOnboardingProgress,
      bankAccountProgress,
      bankDisplay,
      newSignUpSettingsEnabled,
    },
    dispatch,
  ] = useReducer(managedAccountReducer, props, init);
  const { setEnabled: setPaymentsEnabled, permissions } = useJobberPayments();

  const instantPayoutDebitCardInfo =
    props.instantPayoutDebitCardInfo &&
    mapServerCreditCard(props.instantPayoutDebitCardInfo);

  useEffect(() => {
    let mounted = true;
    if (permissions.canSetup) {
      if (
        props.hostedOnboardingProgress === HostedOnboardingProgress.Started ||
        props.hostedOnboardingProgress ===
          HostedOnboardingProgress.CompletedHostedOnboarding ||
        !props.hostedOnboardingProgress
      ) {
        dispatch({
          type: "updateProgress",
          hostedOnboardingProgress: props.hostedOnboardingProgress,
          bankAccountProgress,
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        pollSignupProgress(POLL_MAX_WAIT_IN_MS, Date.now()).then(progress => {
          if (!mounted) return;
          dispatch({ type: "updateProgress", ...progress });
        });
      } else {
        dispatch({ type: "updateProgress", ...props });
      }
    } else if (!showSettings) {
      dispatch({
        type: "updateProgress",
        hostedOnboardingProgress: HostedOnboardingProgress.Uninitiated,
        bankAccountProgress: BankAccountProgress.NotConnected,
      });
    }

    return () => void (mounted = false);
  }, [props]);

  return showSettings ? (
    <IntlProvider>
      <JobberPaymentsSettings
        bankDetailsProps={{
          bank: bankDisplay,
          bankAccountProps: {
            ...props.bankAccountProps,
            bankAccountProgress: BankAccountProgress.NotConnected,
            completedCallback: onSignupComplete,
          },
          payoutsErrorMessage: props.payoutsErrorMessage,
          receivedFirstPayout,
          manualPayoutsIsEnabled,
          payoutDelayDaysType,
        }}
        instantPayoutDebitCardInfo={instantPayoutDebitCardInfo}
        processingRates={processingRates}
        cardReaderProps={props.cardReaderProps}
        hasLoan={props.hasLoan}
        newSignUpSettingsEnabled={newSignUpSettingsEnabled}
        payoutsErrorMessage={props.payoutsErrorMessage}
        achProcessingRate={achProcessingRate}
        paymentCard={paymentCard}
        shouldShowJobberPaymentCard={shouldShowJobberPaymentCard}
        shouldShowDisputesRow={shouldShowDisputesRow}
      />
    </IntlProvider>
  ) : (
    <Setup
      {...props}
      bankAccountProps={{
        ...props.bankAccountProps,
        completedCallback: onSignupComplete,
        bankAccountProgress,
      }}
      hostedOnboardingUrlCreationUrl={props.hostedOnboardingUrlCreationUrl}
      hostedOnboardingProgress={hostedOnboardingProgress}
      chargesEnabled={chargesEnabled}
      isInPaymentsOneClickOnboarding={isInPaymentsOneClickOnboarding}
      achProcessingRate={achProcessingRate}
    />
  );

  async function pollSignupProgress(
    maxWaitInMS = 30000,
    startTime = Date.now(),
  ): Promise<Progress> {
    const currentState = await fetchAccountState();
    if (
      currentState.hostedOnboardingProgress !==
      HostedOnboardingProgress.CompletedHostedOnboarding
    ) {
      return currentState;
    } else if (Date.now() - startTime < maxWaitInMS) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          pollSignupProgress(maxWaitInMS, startTime)
            .then(resolve)
            .catch(reject);
        }, WAIT_TIME_BEFORE_NEXT_POLL_IN_MS);
      });
    }

    return currentState;
  }

  async function fetchAccountState(): Promise<Progress> {
    const response: Response = await fetch(checkSignupProgressUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      const responseObject = (await response.json()) as {
        /* eslint-disable @typescript-eslint/naming-convention */
        hosted_onboarding_progress: HostedOnboardingProgress;
        bank_account_progress: BankAccountProgress;
        /* eslint-enable @typescript-eslint/naming-convention */
      };

      return {
        hostedOnboardingProgress: responseObject.hosted_onboarding_progress,
        bankAccountProgress: responseObject.bank_account_progress,
      };
    }

    return {
      hostedOnboardingProgress: HostedOnboardingProgress.Uninitiated,
      bankAccountProgress: BankAccountProgress.NotConnected,
    };
  }

  function onSignupComplete(bank?: SuccessBank) {
    dispatch({
      type: "updateBank",
      bankAccountProgress: BankAccountProgress.Connected,
      bank,
    });
    setPaymentsEnabled(true);
  }
}

const Enhanced = withRailsPropsAsContexts()(ManagedAccount);
export { Enhanced as ManagedAccount };
