import React from "react";
import { Button } from "@jobber/components/Button";
import { Page } from "@jobber/components/Page";
import { useMachine } from "@xstate/react";
import { SchedulingAssistantModal } from "./components/SchedulingAssistant/SchedulingAssistantModal";
import {
  AssignTeamMachineContext,
  useAssignTeamMachineConfiguration,
} from "./components/AssignTeam";

export const ScheduleAssistantModalPage = () => {
  const [machine, context, config] = useAssignTeamMachineConfiguration();
  const [, , assignTeamMachine] = useMachine(machine, { ...config, context });
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <Page title="Schedule Assistant Modal">
        <Button label="Open Modal" onClick={() => setOpen(true)} />
        <AssignTeamMachineContext.Provider value={assignTeamMachine}>
          <SchedulingAssistantModal
            open={open}
            onClose={() => setOpen(false)}
          />
        </AssignTeamMachineContext.Provider>
      </Page>
    </div>
  );
};
