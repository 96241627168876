import { Checkbox } from "@jobber/components/Checkbox";
import React from "react";
import { useScheduleCardChangeHandlers, useScheduleCardState } from "./hooks";
import styles from "./styles.module.css";

export const DeferScheduling = () => {
  return (
    <div className={styles.checkboxContainer}>
      <ScheduleLaterCheckbox />
      <AddUnscheduledVisitCheckbox />
    </div>
  );
};

export function ScheduleLaterCheckbox() {
  const { stateMatches } = useScheduleCardState();
  const { handleScheduleLater } = useScheduleCardChangeHandlers();

  return (
    <Checkbox
      label="Schedule later"
      onChange={handleScheduleLater}
      value={stateMatches("scheduling.scheduleLater") ? "true" : "false"}
    />
  );
}

export function AddUnscheduledVisitCheckbox() {
  const { stateMatches } = useScheduleCardState();
  const { handleAddUnscheduledVisit } = useScheduleCardChangeHandlers();

  if (!stateMatches("scheduling.scheduleLater")) {
    return null;
  }

  return (
    <div className={styles.unscheduledCheckContainer}>
      <Checkbox
        label="Add an unscheduled visit to the calendar"
        onChange={handleAddUnscheduledVisit}
        checked={stateMatches("addUnscheduledVisits.true")}
        value={stateMatches("addUnscheduledVisits.true").toString()}
      />
    </div>
  );
}
