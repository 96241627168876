import { Banner } from "@jobber/components/Banner";
import { Emphasis } from "@jobber/components/Emphasis";
import React from "react";

interface ErrorListProps {
  errors: string[];
}

export function ErrorList({ errors }: ErrorListProps) {
  if (errors.length === 0) {
    return <></>;
  }

  return (
    <Banner type="error">
      {/* DO NOT COPY: Banners should simply indicate that there are errors on a
      page which need to be corrected. This is a workaround for our lack of
      proper server-side validation. They should not list or contain detailed
      information about each error since that's the role of the input component.
      */}
      <Emphasis variation="bold">Please correct the errors below</Emphasis>
      <br />
      {errors.map((error, index) => (
        <React.Fragment key={index}>
          <br /> {error}
        </React.Fragment>
      ))}
    </Banner>
  );
}
