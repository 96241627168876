import React, { type Dispatch, type SetStateAction } from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import type { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import type { AppsQuery } from "~/utilities/API/graphql";
import type { Integration } from "../IntegrationListProps";
import { IntegrationCardList } from "../IntegrationCardList";
import { CardType } from "../IntegrationList";
import { messages } from "../../../messages";
import styles from "../IntegrationList.module.css";

export function AllAppsSection({
  searchResult,
  highlightedApp,
  setSearchTerm,
  fetchSearchResults,
  publicFacing,
}: {
  searchResult: {
    filteredApps: Integration[];
    header: string;
    showOurPicks: boolean;
  };
  highlightedApp?: string | undefined;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  fetchSearchResults: LazyQueryExecFunction<AppsQuery, OperationVariables>;
  publicFacing: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading level={2}>{searchResult.header}</Heading>
      {searchResult.filteredApps.length > 0 ? (
        <div className={styles.appGrid}>
          <IntegrationCardList
            apps={searchResult.filteredApps}
            type={CardType.ALL_APPS}
            highlightedApp={highlightedApp}
            publicFacing={publicFacing}
          />
        </div>
      ) : (
        <div className={styles.noResultsContainer}>
          <p>{formatMessage(messages.sectionNoResultsTitle)}</p>
          <Button
            label="View All Apps"
            type="tertiary"
            size="small"
            onClick={() => {
              setSearchTerm("");
              void fetchSearchResults({ variables: { searchTerm: "" } });
            }}
          />
        </div>
      )}
    </>
  );
}
