import { gql } from "@apollo/client";

const ACH_PAYMENT_MUTATION = gql`
  mutation SubmitACHPaymentWithPaymentMethod(
    $attachedToId: EncodedId!
    $attachedToType: String!
    $paymentMethodUuid: String!
    $amount: String
  ) {
    achPayment(
      attachedToId: $attachedToId
      attachedToType: $attachedToType
      paymentMethodUuid: $paymentMethodUuid
      amount: $amount
    ) {
      success
      errors {
        message
      }
    }
  }
`;

export { ACH_PAYMENT_MUTATION };
