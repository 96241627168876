import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  UkKycRegistrationCreateMutation,
  UkKycRegistrationCreateMutationVariables,
} from "~/utilities/API/graphql";
import { REGISTRATION_CREATE } from "./useUkKycRegistrationCreateMutation.graphql";

export function useUkKycRegistrationCreateMutation() {
  const [createRegistrationMutation, { loading, error, data }] =
    useMutation<UkKycRegistrationCreateMutation>(REGISTRATION_CREATE);

  const ukKycRegistrationCreate = useCallback(
    async (input: UkKycRegistrationCreateMutationVariables) => {
      const mutationResult = await createRegistrationMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.commsUkKycRegistrationCreate.userErrors ?? [];
      return {
        ukKycRegistration:
          mutationResult.data?.commsUkKycRegistrationCreate.ukKycRegistration,
        ukKycRegistrationInquiry:
          mutationResult.data?.commsUkKycRegistrationCreate
            .ukKycRegistrationInquiry,
        userErrors,
      };
    },
    [createRegistrationMutation],
  );

  return {
    ukKycRegistrationCreate,
    loading,
    error,
    data,
  };
}
