import GenericError from "./GenericError";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class UnexpectedError extends GenericError {
  public readonly details: string[] = [];

  constructor(details: unknown) {
    super("An unexpected error ocurred");

    this.details = normalizeDetails(details);
  }
}

function normalizeDetails(details: unknown) {
  if (Array.isArray(details)) {
    return details;
  }

  if (details instanceof Object) {
    return Object.values(details).reduce(
      (agg: string[], value: string | string[]) => {
        if (value instanceof Array) {
          return [...agg, ...value];
        }

        return [...agg, value];
      },
      [],
    ) as string[];
  }

  if (typeof details === "string") {
    return [details];
  }

  return [];
}
