import { defineMessages } from "react-intl";

export const messages = defineMessages({
  onboardingChecklistTitle: {
    id: "onboardingChecklistTitle",
    defaultMessage: "Explore Grow Features",
    description: "Title for the onboarding checklist",
  },
  // Start Grow trial step
  startGrowTrial: {
    id: "startGrowTrial",
    defaultMessage: "Start the Grow trial",
    description: "Title for the start Grow trial checklist item",
  },
  startGrowTrialButton: {
    id: "startGrowTrialButton",
    defaultMessage: "Get Started",
    description: "Onboarding checklist button for Start Grow trial step",
  },
  startToGrowTrialLeftDescription: {
    id: "startToGrowTrialLeftDescription",
    defaultMessage:
      "From automated quote follow-ups to job costing, take advantage of Grow features throughout the trial and see how they can help you scale your business.",
    description: "Onboarding checklist description for Start Grow trial step",
  },
  // TWSMS step
  twsms: {
    id: "twsms",
    defaultMessage: "Set up two-way text messaging",
    description: "Title for the TWSMS checklist item",
  },
  twsmsHeading: {
    id: "twsmsHeading",
    defaultMessage: "Text customers on the go with two-way text messaging",
    description: "Onboarding checklist heading for TWSMS step",
  },
  twsmsSubHeading: {
    id: "twsmsSubHeading",
    defaultMessage:
      "With a dedicated phone number, you can text with your clients back and forth. They will always know it’s you, and you can keep all conversations in Jobber.",
    description: "Onboarding checklist subheading for TWSMS step",
  },
  twsmsButton: {
    id: "twsmsButton",
    defaultMessage: "Choose Your Number",
    description: "Onboarding checklist button for TWSMS step",
  },
  twsmsAltText: {
    id: "twsmsAltText",
    defaultMessage:
      "Image of mobile device displaying text messages between a SP and SC about an appointment reminder.",
    description: "Alt text for the TWSMS image",
  },
  // Customize quote follow-ups step
  customizeQuoteFollowUps: {
    id: "customizeQuoteFollowUps",
    defaultMessage: "Customize quote follow-ups",
    description: "Title for the customize quote follow-ups checklist item",
  },
  customizeQuoteFollowUpsHeading: {
    id: "customizeQuoteFollowUpsHeading",
    defaultMessage: "Win more work with automated quote follow-ups",
    description:
      "Onboarding checklist heading for customize quote follow-ups step",
  },
  customizeQuoteFollowUpsSubHeading: {
    id: "customizeQuoteFollowUpsSubHeading",
    defaultMessage:
      "Jobber automatically sends email and text message follow-ups so you can be sure you’re never leaving work on the table. Choose when the reminders are sent out, and customize your message.",
    description:
      "Onboarding checklist subheading for customize quote follow-ups step",
  },
  customizeQuoteFollowUpsButton: {
    id: "customizeQuoteFollowUpsButton",
    defaultMessage: "Customize Quote Follow-up",
    description:
      "Onboarding checklist button for customize quote follow-ups step",
  },
  customizeQuoteFollowUpsAltText: {
    id: "customizeQuoteFollowUpsAltText",
    defaultMessage:
      "Image of mobile device displaying a quote follow-up message.",
    description: "Alt text for the customize quote follow-ups image",
  },
  // Turn on location timers step
  turnOnLocationTimers: {
    id: "turnOnLocationTimers",
    defaultMessage: "Turn on location timers",
    description: "Title for the turn on location timers checklist item",
  },
  turnOnLocationTimersHeading: {
    id: "turnOnLocationTimersHeading",
    defaultMessage: "Track time automatically with location timers",
    description:
      "Onboarding checklist heading for turn on location timers step",
  },
  turnOnLocationTimersSubHeading: {
    id: "turnOnLocationTimersSubHeading",
    defaultMessage:
      "Maintain accurate, up-to-date timesheets with location timers. Team members can automatically track their visit time or receive time-tracking reminders when near a client’s property so you can save hours on admin work.",
    description:
      "Onboarding checklist subheading for turn on location timers step",
  },
  turnOnLocationTimersButton: {
    id: "turnOnLocationTimersButton",
    defaultMessage: "Try Location Timers",
    description: "Onboarding checklist button for turn on location timers step",
  },
  turnOnLocationTimersAltText: {
    id: "turnOnLocationTimersAltText",
    defaultMessage:
      "Image of mobile device displaying a location timer notification of arrival and departure for a job.",
    description: "Alt text for the turn on location timers image",
  },
  // Add optional line items step
  addOptionalLineItems: {
    id: "addOptionalLineItems",
    defaultMessage: "Add optional line items",
    description: "Title for the add optional line items checklist item",
  },
  addOptionalLineItemsHeading: {
    id: "addOptionalLineItemsHeading",
    defaultMessage: "Sell more with optional line items",
    description:
      "Onboarding checklist heading for add optional line items step",
  },
  addOptionalLineItemsSubHeading: {
    id: "addOptionalLineItemsSubHeading",
    defaultMessage:
      "Increase your revenue by offering premium packages or add-ons directly in the quote. Clients can check off which extras they'd like and approve the quote. No back and forth required.",
    description:
      "Onboarding checklist subheading for add optional line items step",
  },
  addOptionalLineItemsButton: {
    id: "addOptionalLineItemsButton",
    defaultMessage: "Create a Quote",
    description: "Onboarding checklist button for add optional line items step",
  },
  addOptionalLineItemsAltText: {
    id: "addOptionalLineItemsAltText",
    defaultMessage:
      "Image of mobile device displaying examples of optional line items for a form.",
    description: "Alt text for the add optional line items image",
  },
  // Start tracking job costs step
  startTrackingJobCosts: {
    id: "startTrackingJobCosts",
    defaultMessage: "Start tracking job costs",
    description: "Title for the start tracking job costs checklist item",
  },
  startTrackingJobCostsHeading: {
    id: "startTrackingJobCostsHeading",
    defaultMessage: "Track job costs all in one place",
    description:
      "Onboarding checklist heading for start tracking job costs step",
  },
  startTrackingJobCostsSubHeading: {
    id: "startTrackingJobCostsSubHeading",
    defaultMessage:
      "You can now see real-time profitability calculations on your jobs page. To automatically calculate labor costs, set a default rate for each employee.",
    description:
      "Onboarding checklist subheading for start tracking job costs step",
  },
  startTrackingJobCostsButton: {
    id: "startTrackingJobCostsButton",
    defaultMessage: "Setup Labor Rates",
    description:
      "Onboarding checklist button for start tracking job costs step",
  },
  startTrackingJobCostsAltText: {
    id: "startTrackingJobCostsAltText",
    defaultMessage:
      "Image of mobile device displaying a job cost summary for a job.",
    description: "Alt text for the start tracking job costs image",
  },
  choosePlan: {
    id: "choosePlan",
    defaultMessage: "Choose plan",
    description: "Title for the create account list item",
  },
  choosePlanGuideHeading: {
    id: "choosePlanGuideHeading",
    defaultMessage: "Welcome to",
    description: "Setup guide welcome heading",
  },
  choosePlanGuideButtonLabel: {
    id: "choosePlanGuideButtonLabel",
    defaultMessage: "Get started",
    description: "Setup guide welcome screen get started button",
  },
});
