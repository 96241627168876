import React from "react";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { accordionImageMap } from "./useAccordionItems";
import styles from "./AccordionImage.module.css";

interface AccordionImageProps {
  expandedItem: string;
}

export function AccordionImage({ expandedItem }: AccordionImageProps) {
  const { largeAndUp } = useBreakpoints();

  return (
    <>
      {largeAndUp && (
        <section className={styles.imageSection}>
          <img
            className={styles.accordionImage}
            src={accordionImageMap[expandedItem].src}
            alt={accordionImageMap[expandedItem].alt}
          />
        </section>
      )}
    </>
  );
}
