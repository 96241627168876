import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import styles from "./BookingCapacityCard.module.css";
import { EditBookingCapacity } from "./EditBookingCapacity";

interface BookingCapacityCardProps {
  spotsTotal: number;
  onSaveCapacity(spotsTotal: number): Promise<void>;
}

export function BookingCapacityCard({
  spotsTotal,
  onSaveCapacity,
}: BookingCapacityCardProps) {
  const appointments = spotsTotal === 1 ? "appointment" : "appointments";
  return (
    <Card title="Step 3: Set Your Appointment Capacity">
      <Content>
        <div className={styles.bookingCapacityLayout}>
          <Content spacing="small">
            <Heading level={4}>Appointment Capacity</Heading>
            <Text>
              Set the maximum number of appointments in your Jobber calendar
              before an arrival window shows as unavailable for new bookings.
              The higher the number, the more bookings you can receive.
              <br />
              &bull; Current capacity set to{" "}
              <Emphasis variation="bold">{spotsTotal}</Emphasis> {appointments}
            </Text>
          </Content>
          <div>
            <EditBookingCapacity
              spotsTotal={spotsTotal}
              onSaveCapacity={onSaveCapacity}
            />
          </div>
        </div>
      </Content>
    </Card>
  );
}
