import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  QuoteSummaryFilterInput,
  QuoteSummaryQuery,
  QuoteSummaryQueryVariables,
  QuoteSummarySortInput,
} from "~/utilities/API/graphqlFranchiseManagement";
import { QUOTE_SUMMARY_QUERY } from "../QuoteSummaryReport.graphql";

interface FetchQuoteSummaryArguments {
  sort: QuoteSummarySortInput;
  filter: QuoteSummaryFilterInput;
  cursor?: string;
  first: number;
}

interface QuoteSummaryReportDataProps {
  data?: QuoteSummaryQuery;
  loading: boolean;
  error?: ApolloError;
  fetchQuoteSummary(args: FetchQuoteSummaryArguments): void;
}

export function useQuoteSummaryReportData(): QuoteSummaryReportDataProps {
  const [fetchQuoteSummary, { data, loading, error }] = useLazyQuery<
    QuoteSummaryQuery,
    QuoteSummaryQueryVariables
  >(QUOTE_SUMMARY_QUERY);

  const _fetchQuoteSummary = ({
    sort,
    filter,
    cursor,
    first,
  }: FetchQuoteSummaryArguments) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchQuoteSummary({
      variables: {
        sort: sort,
        filter: filter,
        cursor: cursor,
        first: first,
      },
    });
  };

  return {
    fetchQuoteSummary: _fetchQuoteSummary,
    data,
    loading,
    error,
  };
}
