import { useCallback, useMemo, useState } from "react";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { ReviewStatuses } from "../types";

export interface FilterState {
  readonly status: ComboboxOption[];
}

export interface UseFilterStateValues {
  readonly filterState: FilterState;
  readonly filtered: boolean;
  readonly setStatus: (status: ComboboxOption[]) => void;
}

export function useFilterState({
  defaultStatus,
}: {
  defaultStatus: ComboboxOption;
}): UseFilterStateValues {
  const [filterState, setFilterState] = useState<FilterState>({
    status: [defaultStatus],
  });

  const callbacks = useCallback(
    () => ({
      setStatus: (status: ComboboxOption[]) =>
        setFilterState(prevState => ({ ...prevState, status })),
    }),
    [setFilterState],
  );
  const isFiltered = useMemo(
    () => filterState.status[0].id !== ReviewStatuses.All,
    [filterState.status],
  );

  return { filterState, filtered: isFiltered, ...callbacks() };
}
