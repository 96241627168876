import React from "react";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import styles from "./CodeEntryInput.module.css";

export interface CodeEntryProps {
  value: string;
  onChange(newCode: string): void;
}

export function CodeEntryInput({ value, onChange }: CodeEntryProps) {
  return (
    <>
      <div className={styles.twoFactorAuthInput} data-testid="otpStep">
        <InputText
          aria-label="One time code"
          onChange={onChange}
          keyboard={"numeric"}
          maxLength={6}
          autocomplete="one-time-code"
          value={value}
        />
      </div>
      <Content>
        Didn’t receive a code?{" "}
        <a
          href={"https://help.getjobber.com/hc/en-us/articles/1500001226241"}
          target="_blank"
          rel="noopener noreferrer"
        >
          Try another way
        </a>
      </Content>
    </>
  );
}
