import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "reviews.replyCard.title",
    defaultMessage: "Response from the owner",
    description: "Heading for the reply section",
  },
  replyTextInputPlaceholder: {
    id: "reviews.replyCard.replyTextInputPlaceholder",
    defaultMessage: "Type a response...",
    description: "Placeholder text for the reply text input",
  },
  replySent: {
    id: "reviews.replyCard.replySent",
    defaultMessage: "Reply sent",
    description: "Toast message for when a reply is sent",
  },
  saveReplyError: {
    id: "reviews.replyCard.saveReplyError",
    defaultMessage: "Unable to send response, try again later.",
    description: "Error message for when a reply fails to save",
  },
  send: {
    id: "reviews.replyCard.send",
    defaultMessage: "Send",
    description: "Button label for sending a reply",
  },
  replyMaxLength: {
    id: "reviews.replyCard.replyMaxLength",
    defaultMessage: "Character limit has been reached.",
    description: "Error message for when a reply is too long",
  },
});
