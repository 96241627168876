import classNames from "classnames";
import React, { type ReactNode } from "react";

export interface ListItemProps {
  isLoading?: boolean;

  leftNode?: ReactNode;

  title?: string;
  titleClassName?: string;

  titleLabel?: string;
  titleLabelClassName?: string;

  subtitle?: string;
  subtitleClassName?: string;

  rightNode?: ReactNode;
}

export function ListItem(props: ListItemProps) {
  const {
    isLoading = false,
    leftNode,
    title,
    titleClassName,
    titleLabel,
    titleLabelClassName,
    subtitle,
    subtitleClassName,
    rightNode,
  } = props;

  const titleNode = title && (
    <div
      className={`list-subText u-textSmaller u-textUppercase ${titleClassName}`}
    >
      {title}
      {titleLabel && (
        <span
          className={`inlineLabel u-textSmall u-paddingSmallest u-marginLeftSmallest ${titleLabelClassName}`}
        >
          {titleLabel}
        </span>
      )}
    </div>
  );

  const subtitleNode = subtitle && (
    <span className={`list-text ${subtitleClassName}`}>{subtitle}</span>
  );

  const rowClass = classNames("row row--tightColumns align-middle", {
    spinner: isLoading,
  });

  return (
    <div className={rowClass}>
      {leftNode}
      <div className="columns">
        {titleNode}
        {subtitleNode}
      </div>
      {rightNode}
    </div>
  );
}
