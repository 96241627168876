import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";
import styles from "./ConnectToGrowStartOfTrialModal.module.css";

enum FeaturesEnum {
  LocationTimer = "LOCATION_TIMER",
  AutoQuotes = "AUTO_QUOTES",
  LineItems = "LINE_ITEMS",
  JobCosting = "JOB_COSTING",
}

const AvailableFeaturesList: {
  [key in FeaturesEnum]: {
    main: keyof typeof messages;
    additional: (keyof typeof messages)[];
  };
} = {
  [FeaturesEnum.LocationTimer]: {
    main: "locationTimers",
    additional: ["autoQuote", "optionalLineItems", "jobCosting"],
  },
  [FeaturesEnum.AutoQuotes]: {
    main: "autoQuote",
    additional: ["locationTimers", "optionalLineItems", "jobCosting"],
  },
  [FeaturesEnum.LineItems]: {
    main: "optionalLineItems",
    additional: ["autoQuote", "locationTimers", "jobCosting"],
  },
  [FeaturesEnum.JobCosting]: {
    main: "jobCosting",
    additional: ["autoQuote", "locationTimers", "optionalLineItems"],
  },
};

interface ConnectToGrowStartOfTrialModalProps {
  feature: FeaturesEnum;
  showModal: boolean;
  onClose?: () => void;
}

function ConnectToGrowStartTrialModal({
  feature,
  showModal,
  onClose,
}: ConnectToGrowStartOfTrialModalProps) {
  const [modalOpen, setModalOpen] = useState(showModal);
  const { formatMessage } = useIntl();

  const mainFeature = AvailableFeaturesList[feature].main;
  const additionalFeatures = AvailableFeaturesList[feature].additional;

  return (
    <Modal
      title={formatMessage(messages.heading)}
      open={modalOpen}
      primaryAction={{
        label: formatMessage(messages.confirmation),
        onClick: () => handleModalDismissal(),
      }}
      onRequestClose={() => handleModalDismissal()}
    >
      <Content>
        <div className={styles.centered}>
          <div className={styles.spacing}>
            <Heading level={2}>
              {formatMessage(messages.subheading, {
                planName: (
                  <Emphasis variation="highlight">
                    {formatMessage(messages.subheadingEmphasis)}
                  </Emphasis>
                ),
              })}
            </Heading>
          </div>

          <div className={styles.description}>
            <Text align="center">{formatMessage(messages.descriptionL1)}</Text>
            <Text align="center">{formatMessage(messages.descriptionL2)}</Text>
            <Text align="center">{formatMessage(messages.descriptionL3)}</Text>
          </div>

          <Heading level={5}>
            <Emphasis variation="bold">
              {formatMessage(messages.availability, {
                feature: formatMessage(messages[mainFeature]),
              })}
            </Emphasis>
          </Heading>
        </div>

        <div className={styles.additionalFeatures}>
          {additionalFeatures.map(af => (
            <div className={styles.additionalFeature} key={af}>
              <Text align="center">{formatMessage(messages[af])}</Text>
            </div>
          ))}
        </div>
      </Content>
    </Modal>
  );

  function handleModalDismissal() {
    onClose?.();
    setModalOpen(false);
  }
}

function WrappedConnectToGrowStartOfTrialModal({
  feature,
  showModal,
  onClose,
}: ConnectToGrowStartOfTrialModalProps) {
  return (
    <IntlProvider>
      <ConnectToGrowStartTrialModal
        feature={feature}
        showModal={showModal}
        onClose={onClose}
      />
    </IntlProvider>
  );
}

export {
  WrappedConnectToGrowStartOfTrialModal as ConnectToGrowStartOfTrialModal,
  FeaturesEnum,
};
