const emailOwnerText = "Sender of the email";

// Builds owner comma separated list string
// if 1 owner -> owner
// if 2 owners -> owner and owner2
// if > 2 owners -> owner1, owner2, and owner3
function createOwnersString(owners: string[]) {
  const ownersCount = owners.length;

  if (ownersCount >= 2) {
    const lastItem = owners.slice(-1)[0];
    owners.splice(-1, 1);
    if (ownersCount === 2) {
      return `${owners.join()} and ${lastItem}`;
    }
    return `${owners.join(", ")}, and ${lastItem}`;
  }
  return owners.join();
}

// Builds string for ownerships that have different owners. E.g.
// Sender of the email, company email and Flavio Briatore for requests, and invoices
// Company email for quotes
// Flavio Briatore for jobs
export function buildReplyToMultipleOwnerships(owners: string[]) {
  const correctCaseOwners = owners.map((owner: string, index: number) => {
    if (owner === "Sender of email") {
      if (index === 0) {
        return emailOwnerText;
      } else {
        return `the ${emailOwnerText.toLowerCase()}`;
      }
    }

    if (owner === "Company email" && index !== 0) {
      return owner.toLowerCase();
    }

    return owner;
  });

  return createOwnersString(correctCaseOwners);
}
// Builds string when ownerships have the same owner for each work object type
// E.g. All email replies and notifications are sent to the sender of the email
export function buildReplyToOwnersStringWhenSameOwnership(owners: string[]) {
  const correctCaseOwners = owners.map((owner: string) => {
    if (owner === "Sender of email") {
      return `the ${emailOwnerText.toLowerCase()}`;
    }

    if (owner === "Company email") {
      return owner.toLowerCase();
    }

    return owner;
  });

  return createOwnersString(correctCaseOwners);
}
