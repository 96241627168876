/**
 * Provides an entry point for tracking events in amplitude
 */
export const Amplitude = {
  TRACK_EVENT: (eventName: string, eventProperties?: EventProperties) => {
    if (window.JobberAnalytics == undefined) {
      // eslint-disable-next-line no-console
      console.warn("JobberAnalytics not defined");

      return;
    }

    window.JobberAnalytics.track(eventName, eventProperties);
  },
};
