import React, { useEffect, useRef, useState } from "react";
import { Heading, Tab, Tabs } from "@jobber/components";
import type { MessageDescriptor } from "react-intl";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { TemplatePreviewer } from "~/components/NotificationTemplates/TemplatePreviewer/TemplatePreviewer";
import type { MessageTemplate } from "~/components/NotificationTemplates/types";
import type {
  DeliveryMethod,
  NotificationDeliveryMethod,
} from "~/utilities/API/graphql";
import { StarRatingImage } from "./images";
import { messages } from "./messages";
import styles from "./ReviewMessagePreviewer.module.css";

interface ReviewMessagePreviewerProps {
  deliveryMethod: DeliveryMethod | NotificationDeliveryMethod;
  hasDPN?: boolean;
  staticTemplate?: MessageTemplate;
  templates: MessageTemplate[];
}

interface MessagePreviewProps {
  hasDPN?: boolean;
  staticTemplate?: MessageTemplate;
  template: MessageTemplate;
}

export function ReviewMessagePreviewer({
  deliveryMethod,
  templates,
  staticTemplate,
  hasDPN = false,
}: ReviewMessagePreviewerProps): JSX.Element {
  const { formatMessage } = useIntl();
  const templateStoreRef = useRef(templates);
  const [templatesToDisplay, setTemplatesToDisplay] = useState<
    MessageTemplate[]
  >(
    templateStoreRef.current.filter(
      template => template.deliveryMethod === deliveryMethod,
    ),
  );

  useEffect(() => {
    const displayedTemplate =
      templateStoreRef.current.find(
        template => template.deliveryMethod === deliveryMethod,
      ) || undefined;

    setTemplatesToDisplay(
      displayedTemplate !== undefined
        ? [displayedTemplate]
        : templateStoreRef.current,
    );
  }, [deliveryMethod]);

  return (
    <Tabs defaultTab={templatesToDisplay.length > 1 ? 1 : 0}>
      {templatesToDisplay.map(template => (
        <Tab
          key={template.id}
          label={
            template.deliveryMethod === "EMAIL"
              ? formatMessage(messages.emailTabTitle)
              : formatMessage(messages.textMessageTabTitle)
          }
        >
          <MessagePreviewer
            template={template}
            staticTemplate={staticTemplate}
            hasDPN={hasDPN}
          />
        </Tab>
      ))}
    </Tabs>
  );
}

export function MessagePreviewer({
  template,
  staticTemplate,
  hasDPN = false,
}: MessagePreviewProps): JSX.Element {
  const { formatMessage } = useIntl();
  const isSMS = template.deliveryMethod === "SMS";
  const staticImage = !isSMS ? (
    <div className={styles.imageContainer}>
      <img src={StarRatingImage.src} alt={formatMessage(StarRatingImage.alt)} />
    </div>
  ) : undefined;

  return (
    <div className={styles.previewSection}>
      <Heading level={5}>{formatMessage(messages.previewTitle)}</Heading>
      {isSMS && staticTemplate && (
        <div className={styles.staticTemplate}>
          <TemplatePreviewer template={staticTemplate} showSmsArrow={false} />
        </div>
      )}
      <div className={styles.staticTemplate}>
        <TemplatePreviewer template={template} staticImage={staticImage} />
      </div>
      {helperText(hasDPN, isSMS, formatMessage)}
    </div>
  );
}

function helperText(
  hasDPN: boolean,
  isSMS: boolean,
  formatMessage: (messageDescriptor: MessageDescriptor) => string,
) {
  if (isSMS && !hasDPN) {
    return null;
  } else {
    return (
      <Text variation="subdued" size="small">
        {isSMS
          ? formatMessage(messages.previewTextHelper)
          : formatMessage(messages.previewEmailHelper)}
      </Text>
    );
  }
}
