import React, { useEffect } from "react";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import { InputValidation } from "@jobber/components/InputValidation";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { messages } from "jobber/campaigns/components/ValidateEmailModal/messages";

interface VerifyEmailModalProps {
  email: string | undefined;
  showModal: boolean;
  toggleModal: () => void;
  sendValidationCode: () => void;
  checkValidationCode: (code: string) => void;
  isCheckValidationCodeDataLoading: boolean;
  error: string;
}
export function ValidateEmailModal({
  showModal,
  email,
  toggleModal,
  sendValidationCode,
  checkValidationCode,
  isCheckValidationCodeDataLoading,
  error,
}: VerifyEmailModalProps) {
  const { formatMessage } = useIntl();
  const maxVerificationCodeLength = 6;

  useEffect(() => {
    sendValidationCode();
  }, []);

  function handleOnChange(value: string) {
    if (value.length === maxVerificationCodeLength) {
      checkValidationCode(value);
    }
  }

  return (
    <Modal
      title={formatMessage(messages.title)}
      open={showModal}
      dismissible={true}
      onRequestClose={toggleModal}
    >
      <Content>
        <Text>
          {formatMessage(messages.mainText, {
            email: email,
            b: () => <b>{email}</b>,
          })}
        </Text>
        <div className={"u-marginBottomSmallest"}>
          <InputText
            placeholder={formatMessage(messages.validationCodePlaceholder)}
            onChange={handleOnChange}
            loading={isCheckValidationCodeDataLoading}
            invalid={!!error}
          />
        </div>
        <div className={"u-marginNone"}>
          {error && <InputValidation message={error} />}
        </div>
        <div className={"u-marginTopSmall"}>
          <Text>
            {formatMessage(messages.resendValidationEmailFullText, {
              button: () => (
                <button
                  style={{ color: "#7DB00E" }}
                  onClick={() => sendValidationCode()}
                >
                  {formatMessage(messages.resendValidationEmailPartialText)}
                </button>
              ),
            })}
          </Text>
        </div>
      </Content>
    </Modal>
  );
}
