import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";

export interface BillingDetails {
  name?: string;
  address?: BillingAddress;
}

interface BasePaymentResult {
  success: boolean;
  bannerMessage?: string;
  errorMessage?: string;
  didIntercept?: boolean;
}

interface CollectPaymentSuccess extends BasePaymentResult {
  success: true;
  bannerMessage?: string;
  errorMessage?: undefined;
}

interface CollectPaymentIntercepted extends BasePaymentResult {
  success: true;
  didIntercept: true;
}

interface CollectPaymentFailed extends BasePaymentResult {
  success: false;
  errorMessage: string;
}

export type CollectPaymentResult =
  | CollectPaymentSuccess
  | CollectPaymentIntercepted
  | CollectPaymentFailed;

export interface BasePaymentState {
  amount: string;
  paymentType: string;
  attachedToId: string;
  attachedToType: "invoice" | "quote";
  jobberPaymentMethodId: string | undefined;
  paymentOrigin?: string | undefined;
  showSavePaymentMethod?: boolean;
  shouldSavePaymentMethod?: boolean;
  signature?: string;
  response?: undefined;
  billingDetails?: BillingDetails;
  paymentMethods?: StoredPaymentMethodInterface[];
  mandatoryCardOnFile?: boolean;
}
interface NotInitializedState extends BasePaymentState {
  status: "notInitialized";
}

interface IdleState extends BasePaymentState {
  status: "idle";
}

interface InProgressState extends BasePaymentState {
  status: "inProgress";
  formData?: FormData;
}

interface CompletedState extends Omit<BasePaymentState, "response"> {
  status: "completed";
  response: CollectPaymentResult;
}

export type PaymentState =
  | NotInitializedState
  | IdleState
  | InProgressState
  | CompletedState;

interface CompleteInitializationAction {
  type: "completingInitialization";
}

interface RequestJobberPaymentChargeAction {
  type: "requestingJobberPaymentCharge";
}

interface RecordChargeAction {
  type: "requestingRecordCharge";
  formData: FormData;
}

interface CompleteChargeAction {
  type: "completingCharge";
  response: CollectPaymentResult;
}

interface UpdateAmountAction {
  type: "updatingAmount";
  amount: string;
}

interface UpdatePaymentTypeAction {
  type: "updatingPaymentType";
  paymentType: string;
}

interface UpdateSignatureAction {
  type: "updatingSignature";
  signature: string | undefined;
}

interface OnJobberPaymentsBeingLoadedAction {
  type: "onJobberPaymentsBeingLoaded";
}

interface OnJobberPaymentsPaymentMethodsLoadedAction {
  type: "onJobberPaymentsPaymentMethodsLoaded";
  paymentMethods: StoredPaymentMethodInterface[];
}

interface UpdateJobberPaymentsSelectedPaymentMethodAction {
  type: "updatingJobberPaymentsSelectedPaymentMethod";
  paymentMethodId: string | undefined;
  paymentOrigin?: string;
}

interface UpdateJobberPaymentsShouldSavePaymentMethodAction {
  type: "updatingJobberPaymentsShouldSavePaymentMethod";
  shouldSavePaymentMethod: boolean;
}

interface UpdateJobberPaymentsBillingAddressAction {
  type: "updatingJobberPaymentsBillingAddress";
  billingAddressPartialUpdate?: Pick<BillingAddress, keyof BillingAddress>;
}

interface UpdateJobberPaymentsBillingNameAction {
  type: "updatingJobberPaymentsBillingName";
  name?: string;
}

export type PaymentAction =
  | CompleteInitializationAction
  | RequestJobberPaymentChargeAction
  | RecordChargeAction
  | CompleteChargeAction
  | UpdateAmountAction
  | UpdatePaymentTypeAction
  | UpdateSignatureAction
  | OnJobberPaymentsPaymentMethodsLoadedAction
  | OnJobberPaymentsBeingLoadedAction
  | UpdateJobberPaymentsSelectedPaymentMethodAction
  | UpdateJobberPaymentsShouldSavePaymentMethodAction
  | UpdateJobberPaymentsBillingAddressAction
  | UpdateJobberPaymentsBillingNameAction;

export function paymentReducer(
  prevState: PaymentState,
  action: PaymentAction,
): PaymentState {
  switch (action.type) {
    case "completingInitialization":
      if (prevState.status !== "notInitialized") {
        // eslint-disable-next-line no-console
        console.warn("already initialized");
        return prevState;
      }
      return {
        ...prevState,
        status: "idle",
      };
    case "requestingJobberPaymentCharge":
      ensureChargeReady();
      if (
        prevState.status === "inProgress" ||
        prevState.status === "notInitialized"
      ) {
        return prevState;
      }
      return {
        ...prevState,
        status: "inProgress",
        response: undefined,
      };
    case "requestingRecordCharge":
      ensureChargeReady();
      if (
        prevState.status === "inProgress" ||
        prevState.status === "notInitialized"
      ) {
        return prevState;
      }
      return {
        ...prevState,
        status: "inProgress",
        formData: action.formData,
        response: undefined,
      };
    case "completingCharge":
      return {
        ...prevState,
        status: "completed",
        response: action.response,
      };
    case "updatingAmount":
      ensureAmountMutable();
      return { ...prevState, amount: action.amount };
    case "updatingPaymentType":
      return {
        ...prevState,
        paymentType: action.paymentType,
      };
    case "updatingSignature":
      return { ...prevState, signature: action.signature };
    case "updatingJobberPaymentsSelectedPaymentMethod":
      return {
        ...prevState,
        jobberPaymentMethodId: action.paymentMethodId,
        paymentOrigin: action.paymentOrigin,
        shouldSavePaymentMethod: false,
      };
    case "onJobberPaymentsPaymentMethodsLoaded": {
      const paymentMethods = action.paymentMethods;
      const newDefaultMethod: StoredPaymentMethodInterface | undefined =
        paymentMethods.find(paymentMethod => paymentMethod.isDefault) ||
        paymentMethods[0];

      return {
        ...prevState,
        paymentMethods: action.paymentMethods,
        jobberPaymentMethodId: newDefaultMethod?.uuid,
      };
    }
    case "onJobberPaymentsBeingLoaded": {
      return {
        ...prevState,
        paymentMethods: undefined,
        jobberPaymentMethodId: undefined,
      };
    }
    case "updatingJobberPaymentsShouldSavePaymentMethod":
      return {
        ...prevState,
        shouldSavePaymentMethod: action.shouldSavePaymentMethod,
      };
    case "updatingJobberPaymentsBillingAddress":
      return {
        ...prevState,
        billingDetails: {
          ...prevState.billingDetails,
          address: {
            ...prevState.billingDetails?.address,
            ...action.billingAddressPartialUpdate,
          },
        },
      };
    case "updatingJobberPaymentsBillingName":
      return {
        ...prevState,
        billingDetails: {
          ...prevState.billingDetails,
          name: action.name,
        },
      };
    default:
      return prevState;
  }

  function ensureChargeReady() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (prevState.status === "inProgress") {
        throw new Error(
          "existing charge in progress. you should check state.status before dispatching a charge action",
        );
      }
      if (prevState.status === "notInitialized") {
        throw new Error(
          "payment attempt made before stripe or elements is ready, check state.status before submitting payment",
        );
      }
    }
  }

  function ensureAmountMutable() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (prevState.status === "inProgress") {
        throw new Error(
          "existing charge in progress. you should check state.status before attempting to change amount",
        );
      }
    }
  }
}
