import { Checkbox } from "@jobber/components/Checkbox";
import { Content } from "@jobber/components/Content";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Text } from "@jobber/components/Text";
import React, {
  Fragment,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useUrls } from "~/utilities/contexts/internal/useUrls";

interface Props {
  phoneNumberOptions: string[];
  requiresRegistration: boolean;
  requiresTermsOfService: boolean;
  selectedPhoneNumber: string | undefined;
  termsOfServiceChecked: boolean;
  onTermsOfServiceChanged(checked: boolean): void;
  onPhoneNumberChosen(number: string): void;
}

export function ChooseNumberContent({
  phoneNumberOptions,
  requiresRegistration,
  requiresTermsOfService,
  selectedPhoneNumber,
  termsOfServiceChecked,
  onTermsOfServiceChanged,
  onPhoneNumberChosen,
}: PropsWithChildren<Props>) {
  const [phoneNumber, setPhoneNumber] = useState<string | number>(
    selectedPhoneNumber || phoneNumberOptions[0],
  );
  const [termsOfServiceLink] = useUrls("termsOfService");

  useEffect(() => {
    if (phoneNumber) {
      onPhoneNumberChosen(phoneNumber.toString());
    }
  }, [phoneNumber]);
  return (
    <Content>
      <RadioGroup
        onChange={setPhoneNumber}
        value={phoneNumber}
        ariaLabel="Choose your number"
      >
        {phoneNumberOptions.map((numberOption: string) => (
          <RadioOption key={numberOption} value={numberOption}>
            {numberOption}
          </RadioOption>
        ))}
      </RadioGroup>

      {requiresTermsOfService && (
        <Fragment>
          <Text>
            Once you choose this number you won’t be be able to change it. By
            setting up a dedicated phone number, you acknowledge that you have
            read and agree to our{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={termsOfServiceLink}
            >
              Terms of Service.{" "}
            </a>
          </Text>
          <Checkbox
            checked={termsOfServiceChecked}
            label="I understand"
            onChange={onTermsOfServiceChanged}
          />
        </Fragment>
      )}

      {requiresRegistration && (
        <Text>
          In the next step, you will be required to submit basic company
          information to comply with U.S. carrier regulations.
        </Text>
      )}
    </Content>
  );
}
