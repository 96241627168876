import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "downgradePage.downgradeContactModal.modalTitle",
    defaultMessage: "Connect with us to downgrade",
    description: "Modal Title",
  },
  modalContent: {
    id: "downgradePage.downgradeContactModal.modalContent",
    defaultMessage: `Connect to a member on our support team to help process your downgrade. Call us at {phone} or chat with us.`,
    description: "Modal Content",
  },
  ctaLabel: {
    id: "downgradePage.downgradeContactModal.ctaLabel",
    defaultMessage: "Chat with us",
    description: "CTA Label",
  },
  cancelLabel: {
    id: "downgradePage.downgradeContactModal.cancelLabel",
    defaultMessage: "Cancel",
    description: "Cancel Label",
  },
  downgradePhoneNumber: {
    id: "downgradePage.downgradeContactModal.downgradePhoneNumber",
    defaultMessage: "1-888-424-5301",
    description: "Downgrade phone number",
  },
});
