import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { Cell, Footer } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { formatSecondsToHours } from "jobber/workOrders/components/JobCost/utils";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/messages";
import styles from "../LabourTable.module.css";

interface LabourTableFooterProps {
  hours: number;
  cost: number;
  tickingTimesheetsHours: number;
}

export function LabourTableFooter({
  hours,
  cost,
  tickingTimesheetsHours,
}: LabourTableFooterProps) {
  const { formatMessage } = useIntl();
  const { can } = useAuthorization();
  const { currencySymbol } = useAccount();

  const canViewJobCosts = can("view", "JobCosts");

  const totalHours = hours + tickingTimesheetsHours;

  const cells = [
    <Cell key={"blank1"} />,
    <Cell key={"blank2"} />,
    <Cell key={"blank3"} />,
    <Cell key="totalHours" className={styles.alignRight}>
      <span className={styles.mobileTitle}>
        <Heading level={5}>
          {formatMessage(messages.labourTableTotalHoursLabel)}
        </Heading>
      </span>
      <span className={styles.withIcon}>
        {tickingTimesheetsHours ? <Icon name="runningTimer" /> : <></>}
        <Heading level={5}>{formatSecondsToHours(totalHours)}</Heading>
      </span>
    </Cell>,
  ];

  if (canViewJobCosts) {
    cells.push(
      <Cell key={"cost"} className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          <Heading level={5}>
            {formatMessage(messages.labourTableTotalCostLabel)}
          </Heading>
        </span>
        <Heading level={5}>{formatCurrency(cost, currencySymbol)}</Heading>
      </Cell>,
    );
  }

  return <Footer>{...cells}</Footer>;
}
