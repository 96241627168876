import type { CancellationFormAnswer } from "./CancellationForm.d";

export interface CancellationFormOption {
  id: string;
  label: string;
  customerText: string;
  nextQuestion: string;
}

export type CancellationFormOptions = CancellationFormOption[];

export interface CancellationFormQuestion {
  type: string;
  id: string;
  label: string;
  customerText: string;
  nextQuestion?: string;
  options?: CancellationFormOptions;
  value: string;
  addAnswer(answer: CancellationFormAnswer): void;
}

export type CancellationFormQuestionTemplate = Pick<
  CancellationFormQuestion,
  "type" | "id" | "label" | "customerText" | "nextQuestion" | "options"
>;

export type CancellationFormQuestionTemplates =
  CancellationFormQuestionTemplate[];

export const FINAL_QUESTION_IDS = ["cfq2", "cfq3"];
