import { InputNumber } from "@jobber/components";
import { useIntl } from "react-intl";
import { messages } from "./messages";

interface LineItemQuantityProps {
  quantity: number;
  reactKey: string;
  onChange: (value: number) => void;
}

export function LineItemQuantity({
  quantity,
  reactKey,
  onChange,
}: LineItemQuantityProps) {
  const { formatMessage } = useIntl();

  return (
    <InputNumber
      name={`lineItems.${reactKey}.quantity`}
      value={quantity}
      placeholder={formatMessage(messages.quantity)}
      autocomplete={false}
      onChange={onChange}
      onBlur={() => {
        const formattedValue = Number(quantity.toFixed(2));
        onChange(formattedValue);
      }}
      validations={{
        min: {
          message: formatMessage(messages.quantityMinimumValueError),
          value: -9999999,
        },
        max: {
          message: formatMessage(messages.quantityMaximumValueError),
          value: 9999999,
        },
      }}
    />
  );
}
