import { useMutation } from "@apollo/client";
import type {
  OnlineBookingGoogleSettingsAttributes,
  OnlineBookingGoogleSettingsEditMutation,
} from "~/utilities/API/graphql";
import {
  EDIT_SELF_SERVE_GOOGLE_SETTINGS_MUTATION,
  SELF_SERVE_SETTINGS_QUERY,
} from "./graphql";
import { useUpdateConfiguration } from "./useUpdateConfiguration";

export function useUpdateGoogleSharingSettings() {
  const { editSelfServeSettings } = useUpdateConfiguration();
  const [callMutation, { loading, error }] =
    useMutation<OnlineBookingGoogleSettingsEditMutation>(
      EDIT_SELF_SERVE_GOOGLE_SETTINGS_MUTATION,
      {
        update(cache, { data: mutationData }) {
          if (mutationData) {
            cache.updateQuery(
              {
                query: SELF_SERVE_SETTINGS_QUERY,
              },
              queryData => {
                return {
                  ...queryData,
                  onlineBookingGoogleSettings: {
                    ...queryData?.onlineBookingGoogleSettings,
                    ...mutationData.onlineBookingGoogleSettingsEdit
                      .onlineBookingGoogleSettings,
                  },
                };
              },
            );
          }
        },
      },
    );

  async function editGoogleSharingSettings(
    newSettings: OnlineBookingGoogleSettingsAttributes,
  ) {
    return callMutation({
      variables: {
        input: newSettings,
      },
    }).then(async () => {
      if (newSettings.enabled) {
        await editSelfServeSettings({ acceptingOnlineBookings: true });
      }
    });
  }

  return {
    editGoogleSharingSettings,
    loading,
    error,
  };
}
