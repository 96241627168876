import { defineMessages } from "react-intl";

export const messages = defineMessages({
  jobberMoneyBalanceHeader: {
    id: "managedAccount.paymentsCard.jobberMoneyBalance.jobberMoneyBalanceHeader",
    defaultMessage: "JOBBER MONEY BALANCE",
    description: "Heading for Jobber Money Balance card",
  },
  lastPayout: {
    id: "managedAccount.paymentsCard.jobberMoneyBalance.lastPayout",
    defaultMessage: "Last payout: ",
    description: "Text for the last payout amount",
  },
  toolTipText: {
    id: "managedAccount.paymentsCard.jobberMoneyBalance.toolTipText",
    defaultMessage:
      "You're receiving automatic hourly payouts to your Jobber Money account for free. You can spend your funds right away using your Jobber Money card.",
    description:
      "Text to be displayed for the tooltip on the last payout amount",
  },
  payoutOnItsWay: {
    id: "managedAccount.paymentsCard.payoutDetails.payoutOnItsWay",
    defaultMessage: "On its way to your bank",
    description: "Text to be displayed when a payout is on its way to the bank",
  },
  noPayoutOnItsWay: {
    id: "managedAccount.paymentsCard.payoutDetails.noPayoutOnItsWay",
    defaultMessage: "There is no payout on its way",
    description: "Text to be displayed when there is no payout on its way",
  },
});
