import React from "react";
import { Banner, type BannerType } from "@jobber/components/Banner";
import styles from "./SyncStatusBanners.module.css";
import { messages } from "./messages";

interface SyncStatusBannersProps {
  syncInProgress: boolean;
  notSyncedCount: number;
  failedSyncCount: number;
}

function renderBanner(type: BannerType, message: string) {
  return (
    <div className={styles.banner}>
      <Banner type={type} dismissible={false}>
        {message}
      </Banner>
    </div>
  );
}
export function SyncStatusBanners({
  syncInProgress,
  notSyncedCount,
  failedSyncCount,
}: SyncStatusBannersProps) {
  const failedSyncMessage =
    failedSyncCount === 1 ? messages.failedSync : messages.failedSyncPlural;

  const notSyncedMessage =
    notSyncedCount === 1 ? messages.notSynced : messages.notSyncedPlural;

  return (
    <>
      {syncInProgress && renderBanner("notice", messages.syncInProgress)}
      {notSyncedCount !== 0 &&
        !syncInProgress &&
        renderBanner("warning", `${notSyncedCount} ${notSyncedMessage}`)}
      {failedSyncCount !== 0 &&
        renderBanner("error", `${failedSyncCount} ${failedSyncMessage}`)}
    </>
  );
}
