import React from "react";

export interface RouteConfig {
  name: string;
  path: string;
}
export type RailsRoutesContextType = RouteConfig[];

export const RailsRoutesContext = React.createContext<RailsRoutesContextType>(
  [],
);

RailsRoutesContext.displayName = "RailsRoutesContext";
