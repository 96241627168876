import currency from "currency.js";

export function displayCurrency(amount = 0) {
  return currency(amount, {
    symbol: "",
    separator: ",",
    precision: 2,
  }).format();
}

export const displayPercentage = (amount = 0, average?: boolean) =>
  average ? `${amount}% avg` : `${amount}%`;
