import React from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { formatPrice } from "~/shared/billing/pricePreview/utils";
import { messages } from "./messages";

interface CreditInfoProps {
  totalCredit: number | undefined;
}

export function CreditInfo({ totalCredit }: CreditInfoProps) {
  const { formatMessage } = useIntl();

  if (!totalCredit) {
    return null;
  }

  return (
    <>
      <Text size="large">{formatMessage(messages.creditLabelMessage)}</Text>
      <Text size="large">
        <Emphasis variation="bold" data-testid="total-credit-amount">
          {formatPrice(totalCredit)}
        </Emphasis>
      </Text>
    </>
  );
}
