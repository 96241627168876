import { defineMessages } from "react-intl";

export const messages = defineMessages({
  itemName: {
    id: "workOrders.jobCost.ExpenseModal.itemName",
    defaultMessage: "Item name",
    description: "Placeholder for expense name input",
  },
  itemNameValidationMessage: {
    id: "workOrders.jobCost.ExpenseModal.itemNameValidationMessage",
    defaultMessage: "Item name must contain 1 or more characters",
    description: "Validation message for expense name input",
  },
  accountCode: {
    id: "workOrders.jobCost.ExpenseModal.accountCode",
    defaultMessage: "Accounting code",
    description: "Placeholder for expense account code input",
  },
  none: {
    id: "workOrders.jobCost.ExpenseModal.none",
    defaultMessage: "None",
    description: "`None` option for expense account code input",
  },
  description: {
    id: "workOrders.jobCost.ExpenseModal.description",
    defaultMessage: "Description",
    description: "Placeholder for expense description input",
  },
  date: {
    id: "workOrders.jobCost.ExpenseModal.date",
    defaultMessage: "Date",
    description: "Placeholder for expense date input",
  },
  total: {
    id: "workOrders.jobCost.ExpenseModal.total",
    defaultMessage: "Total",
    description: "Placeholder for expense total input",
  },
  expenseMinValidationMessage: {
    id: "workOrders.jobCost.ExpenseModal.expenseMinValidationMessage",
    defaultMessage: "Expense total must be more than -1 million",
    description: "Validation message for expense total input",
  },
  expenseMaxValidationMessage: {
    id: "workOrders.jobCost.ExpenseModal.expenseMaxValidationMessage",
    defaultMessage: "Expense total must be less than 1 million",
    description: "Validation message for expense total input",
  },
  reimburseTo: {
    id: "workOrders.jobCost.ExpenseModal.reimburseTo",
    defaultMessage: "Reimburse to",
    description: "Message for reimbursement dropdown",
  },
  notReimbursable: {
    id: "workOrders.jobCost.ExpenseModal.notReimbursable",
    defaultMessage: "Not reimbursable",
    description: "Default option for reimbursement dropdown",
  },
  saveExpense: {
    id: "workOrders.jobCost.ExpenseModal.saveExpense",
    defaultMessage: "Save Expense",
    description: "Save expense button label",
  },
  deleteExpenseTitle: {
    id: "workOrders.jobCost.ExpenseModal.deleteExpense",
    defaultMessage: "Delete expense?",
    description: "Delete expense modal title",
  },
  deleteExpenseMessage: {
    id: "workOrders.jobCost.ExpenseModal.deleteExpenseMessage",
    defaultMessage:
      "Deleting {expenseName} will permanently remove it from Jobber",
    description: "Delete expense modal message",
  },
  addReceipt: {
    id: "workOrders.jobCost.ExpenseModal.addReceipt",
    defaultMessage: "Add receipt",
    description: "Add receipt button label",
  },
});
