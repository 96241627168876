import React, { useEffect, useRef } from "react";

export interface DatePickerProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "data-testid"?: string;
  name?: string;
  disabled?: boolean;
  date?: Date;
  placeholder?: string;
  onChange?(date?: Date): void;
}

declare global {
  interface JQuery {
    datepicker(options?: { dateFormat?: string; onSelect?(): void }): {
      datepicker(action: "setDate", date: Date): unknown;
    };
    datepicker(action: "destroy"): unknown;
    datepicker(action: "getDate"): Date | null;
    datepicker(action: "setDate", date: Date): unknown;
    datepicker(action: "onSelect", cb: () => void): unknown;
  }

  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_format: string;
  }
}

function DatePickerWithPlaceholder(
  props: DatePickerProps & { inputRef: React.RefObject<HTMLInputElement> },
) {
  const { name, disabled, inputRef, placeholder } = props;
  return (
    <placeholder-field label={placeholder}>
      <input
        data-testid={props["data-testid"] || "jquery-datepicker"}
        ref={inputRef}
        name={name}
        type="text"
        className="placeholderField-input"
        disabled={disabled}
        style={{ zIndex: 1001 }}
      />
    </placeholder-field>
  );
}

function DatePickerWithoutPlaceholder(
  props: DatePickerProps & { inputRef: React.RefObject<HTMLInputElement> },
) {
  const { name, disabled, inputRef } = props;
  return (
    <placeholder-field class="placeholderField placeholderField--noMiniLabel u-marginBottomNone">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Grandfathered error: Please fix if touching this code. */}
      <label
        htmlFor=""
        data-label="null"
        className="placeholderField-label is-hidden"
      />
      <input
        data-testid={props["data-testid"] || "jquery-datepicker"}
        ref={inputRef}
        name={name}
        type="text"
        placeholder="Date"
        className="placeholderField-input"
        disabled={disabled}
        style={{ zIndex: 1001 }}
      />
    </placeholder-field>
  );
}

export function Sg1DatePicker(props: DatePickerProps) {
  const { date, placeholder } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const onChangeRef = useRef(props.onChange);

  useEffect(() => {
    if (!inputRef.current) return;

    const input = inputRef.current;
    $(input)
      .datepicker({
        dateFormat: window.date_format,
        onSelect: () => {
          const next = $(input).datepicker("getDate");
          if (onChangeRef.current) onChangeRef.current(next || undefined);

          input.dispatchEvent(new Event("change"));
        },
      })
      .datepicker("setDate", date || new Date());

    return () => void $(input).datepicker("destroy");
  }, []);

  useEffect(() => {
    if (date && inputRef.current) {
      $(inputRef.current).datepicker("setDate", date);
    }
  }, [date]);

  useEffect(() => {
    onChangeRef.current = props.onChange;
  }, [props.onChange]);

  const datePicker = placeholder ? (
    <DatePickerWithPlaceholder {...props} inputRef={inputRef} />
  ) : (
    <DatePickerWithoutPlaceholder {...props} inputRef={inputRef} />
  );

  return (
    <>
      <div className="fieldAffix fieldAffix--inside flexContent u-marginBottomNone">
        {datePicker}
        <sg-icon icon="calendar" class="fieldAffix-item icon" />
      </div>
    </>
  );
}
