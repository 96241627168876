import React from "react";
import { DateHeading } from "jobber/workOrders/components/SchedulingAssistant/components/DateHeading/DateHeading";

export interface TopBarProps {
  date: Date;
  onClose: () => void;
}

export const TopBar = (props: TopBarProps) => {
  return <DateHeading date={props.date} onClose={props.onClose} />;
};
