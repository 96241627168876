import { type WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import type {
  CommsCampaignDefaultTemplateQuery,
  CommsCampaignDefaultTemplateQueryVariables,
  Template,
} from "~/utilities/API/graphql";
import { GET_CAMPAIGN_DEFAULT_TEMPLATE_BY_TYPE } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export function useCommsCampaignDefaultTemplateQuery({
  defaultTemplateType,
  skip,
  fetchPolicy,
}: {
  defaultTemplateType: Template;
  skip: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}) {
  const { loading, data, error, refetch } = useQuery<
    CommsCampaignDefaultTemplateQuery,
    CommsCampaignDefaultTemplateQueryVariables
  >(GET_CAMPAIGN_DEFAULT_TEMPLATE_BY_TYPE, {
    skip: skip,
    variables: { type: defaultTemplateType },
    fetchPolicy,
  });

  return { loading, data, error, refetch };
}
