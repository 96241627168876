export enum HostedOnboardingProgress {
  Uninitiated = "uninitiated",
  Started = "started_hosted_onboarding",
  Verifying = "verifying",
  CompletedHostedOnboarding = "completed_hosted_onboarding",
  Verified = "verified",
}

export interface VerifyState {
  verifyButtonLabel: string;
  verifying: boolean;
  verifyComplete: boolean;
}

const INIT_STATE: VerifyState = {
  verifyButtonLabel: "Get Started",
  verifying: false,
  verifyComplete: false,
};

export function init(
  intialSignupProgress: HostedOnboardingProgress,
): VerifyState {
  return verifyReducer(INIT_STATE, intialSignupProgress);
}

export function verifyReducer(
  prevState: VerifyState,
  newProgress: HostedOnboardingProgress,
): VerifyState {
  switch (newProgress) {
    case HostedOnboardingProgress.Uninitiated:
      return INIT_STATE;
    case HostedOnboardingProgress.Started:
      return {
        verifyButtonLabel: "Finish Verification",
        verifying: false,
        verifyComplete: false,
      };
    case HostedOnboardingProgress.Verifying:
    case HostedOnboardingProgress.CompletedHostedOnboarding:
      return {
        verifyButtonLabel: "Verifying",
        verifying: true,
        verifyComplete: false,
      };
    case HostedOnboardingProgress.Verified:
      return {
        verifyButtonLabel: "Verified",
        verifying: false,
        verifyComplete: true,
      };
    default:
      return prevState;
  }
}
