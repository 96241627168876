import { defineMessages } from "react-intl";

export const messages = defineMessages({
  loadingError: {
    id: "billingAddressEdit.loadingError",
    defaultMessage: "Could not load available billing countries:",
    description: "Loading error message",
  },
  paymentSummaryDisclaimer: {
    id: "billingAddressEdit.paymentSummaryDisclaimer",
    defaultMessage: "To receive payment summary",
    description: "Text to disclaim the purpose of entering email",
  },
  emailAddressError: {
    id: "billingAddressEdit.emailAddressError",
    defaultMessage: "Email address is required",
    description: "Error text for required email",
  },
  emailAddressPlaceholder: {
    id: "billingAddressEdit.emailAddressPlaceholder",
    defaultMessage: "Email address",
    description: "Placeholder text for email field",
  },
  countryPlaceholder: {
    id: "billingAddressEdit.countryPlaceholder",
    defaultMessage: "Country",
    description: "Country field placeholder",
  },
  provincePlaceholder: {
    id: "billingAddressEdit.provincePlaceholder",
    defaultMessage: "Province",
    description: "Province field placeholder",
  },
  statePlaceholder: {
    id: "billingAddressEdit.statePlaceholder",
    defaultMessage: "State",
    description: "alternate Province field placeholder",
  },
  streetAddressPlaceholder: {
    id: "billingAddressEdit.streetAddressPlaceholder",
    defaultMessage: "Street address",
    description: "Street address field placeholder",
  },
  unitPlaceholder: {
    id: "billingAddressEdit.unitPlaceholder",
    defaultMessage: "Unit/Suite",
    description: "Unit field placeholder",
  },
  cityPlaceholder: {
    id: "billingAddressEdit.cityPlaceholder",
    defaultMessage: "Town/City",
    description: "City field placeholder",
  },
  postalCodePlaceholder: {
    id: "billingAddressEdit.postalCodePlaceholder",
    defaultMessage: "Postal Code",
    description: "Postal code field placeholder",
  },
  zipCodePlaceholder: {
    id: "billingAddressEdit.zipCodePlaceholder",
    defaultMessage: "Zip Code",
    description: "Zip code field placeholder",
  },
});
