import React, { useMemo } from "react";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { Tooltip } from "@jobber/components/Tooltip";
import { DatadogNoRedactWrapper } from "~/utilities/errors/Datadog/DatadogNoRedactWrapper";
import {
  computeHtmlStringContentLength,
  trackRewriteMenuOpenClick,
} from "./utils";
import type { TextRewriteError } from "./errors";
import { useTextRewriteOptions } from "./hooks";
import type { PromptTypeProps } from "./types";

export type TextRewriteProps = {
  text: string;
  setText: (text: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setError: (error: TextRewriteError | undefined) => void;
  trackingSource: string;
  decodeHTMLEntities?: boolean;
} & PromptTypeProps;

const BUTTON_LABEL = "Rewrite";

export function TextRewrite(props: TextRewriteProps) {
  const { text } = props;
  const textLength = useMemo(
    () => computeHtmlStringContentLength(text),
    [text],
  );

  if (!textLength) {
    return (
      <DatadogNoRedactWrapper actionName={BUTTON_LABEL} revealOnReplay={true}>
        <Tooltip message="Write something first">
          <Button
            label={BUTTON_LABEL}
            icon="sparkles"
            type="primary"
            size="small"
            variation="subtle"
          />
        </Tooltip>
      </DatadogNoRedactWrapper>
    );
  }

  return <TextRewriteWithMenu {...props} />;
}

function TextRewriteWithMenu(props: TextRewriteProps) {
  const textRewriteOptions = useTextRewriteOptions(props);

  return (
    <DatadogNoRedactWrapper actionName={BUTTON_LABEL} revealOnReplay={true}>
      <Menu
        activator={
          <Button
            label={BUTTON_LABEL}
            icon="sparkles"
            type="primary"
            size="small"
            variation="subtle"
            loading={props.loading}
            onClick={() => {
              trackRewriteMenuOpenClick(props.trackingSource);
            }}
          />
        }
        items={textRewriteOptions}
      />
    </DatadogNoRedactWrapper>
  );
}
