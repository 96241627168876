import React from "react";
import { Text } from "@jobber/components/Text";
import type { Offering } from "jobber/settings/selfServeBookings/types";
import { messages } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/OfferingsList/components/messages";
import { OfferingsItemMenu } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/OfferingsList/components/OfferingsItemMenu";
import styles from "./styles.module.css";

interface OfferingsItemProps {
  offering: Offering;
  duration: string;
  currencySymbol: string;
  onClick(): void;
}

export function OfferingsItemLarge({
  offering,
  duration,
  currencySymbol,
  onClick,
}: OfferingsItemProps) {
  return (
    <>
      <div className={styles.titleText}>
        <Text maxLines="small">{offering.name}</Text>
      </div>

      <div className={styles.descriptionContainer}>
        <Text maxLines="small">
          {offering.description || messages.noDescription.title}
        </Text>
      </div>

      <div className={styles.endContainer}>
        <Text>
          {duration} • {currencySymbol}
          {offering.defaultUnitCost}
        </Text>

        <div className={styles.moreIconContainer}>
          <OfferingsItemMenu openModal={onClick} />
        </div>
      </div>
    </>
  );
}
