/* eslint-disable max-statements */
// Added for JOB-63313: do not copy and fix if you can
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { useCreateBridgeMethod } from "jobber/hooks/useCreateBridgeMethod";
import styles from "./ArrivalWindowPreview.module.css";
import {
  useArrivalWindowAccountSettings,
  useGetArrivalWindowRange,
} from "./hooks";
import type { JobScheduleType } from "./types";
import { ArrivalWindowDialogController } from "./ArrivalWindowDialogController";
import type { DialogConfirm } from "./ArrivalWindowDialog";

export interface ArrivalWindowPreviewProps {
  jobScheduleType: JobScheduleType;
  existingJobArrivalWindow: number | null;
  existingJobArrivalWindowCentered: boolean | null;
}

export const ArrivalWindowPreview = ({
  jobScheduleType,
  existingJobArrivalWindow,
  existingJobArrivalWindowCentered,
}: ArrivalWindowPreviewProps) => {
  // TODO: move these states to a reducer
  const [jobStartTime, setJobStartTime] = useState<Date>();
  const [selectedDuration, setSelectedDuration] = useState(
    existingJobArrivalWindow,
  );
  const [selectedCentered, setSelectedCentered] = useState(
    existingJobArrivalWindowCentered,
  );
  const [showArrivalWindowDialog, setShowArrivalWindowDialog] = useState(false);

  useCreateBridgeMethod(jobScheduleType, setJobStartTime);

  const ONBOARDING_INITIAL_DURATION = 60;

  const { defaultDuration, showOnboarding, loading } =
    useArrivalWindowAccountSettings();

  useEffect(() => {
    if (
      (!loading && selectedDuration === null) ||
      selectedDuration === undefined
    ) {
      setSelectedDuration(defaultDuration);
    }
  }, [defaultDuration, loading, selectedDuration]);

  const arrivalWindowButtonLabel = useMemo(() => {
    return selectedDuration === 0 ? "Add" : "Edit";
  }, [selectedDuration]);

  const duration = useMemo(() => {
    return showOnboarding ? ONBOARDING_INITIAL_DURATION : selectedDuration ?? 0;
  }, [showOnboarding, selectedDuration]);

  const arrivalWindowRange = useGetArrivalWindowRange({
    centered: selectedCentered ?? false,
    duration,
    jobStartTime,
  });

  const showEditDialog = useCallback(
    () => setShowArrivalWindowDialog(true),
    [],
  );

  const onDialogClose = useCallback((props: DialogConfirm) => {
    if (props.action === "confirm") {
      setSelectedDuration(props.duration);
      setSelectedCentered(props.centered);
    }

    setShowArrivalWindowDialog(false);
  }, []);

  if (!jobStartTime) {
    return <></>;
  }

  return (
    <>
      {showArrivalWindowDialog && (
        <ArrivalWindowDialogController
          onClose={onDialogClose}
          initialDuration={duration}
          jobStartTime={jobStartTime}
          centered={selectedCentered ?? false}
          showOnboarding={showOnboarding}
        />
      )}
      <div className={styles.container}>
        <Content spacing="small">
          <div className={styles.headerContainer}>
            <Heading level={5}>Arrival window</Heading>
            <Button
              size="small"
              variation="work"
              type="tertiary"
              label={arrivalWindowButtonLabel}
              onClick={showEditDialog}
            />
          </div>
          {!showOnboarding && (selectedDuration ?? 0) > 0 && (
            <>{arrivalWindowRange}</>
          )}
          <input
            type="hidden"
            data-testid="arrival_window_duration"
            name="work_order[arrival_window_duration]"
            value={selectedDuration ?? 0}
          />
        </Content>
      </div>
    </>
  );
};
