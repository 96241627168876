import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { OAuthAuthorizationPage } from "./OAuthAuthorizationPage";
import type { OAuthAuthorizationPageProps } from "./types";

export function OAuthAuthorizationPageLoader(
  props: OAuthAuthorizationPageProps,
) {
  return (
    <IntlProvider>
      <OAuthAuthorizationPage {...props} />
    </IntlProvider>
  );
}
