import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.EarliestAvailabilityConfiguration.configurationLabel",
    defaultMessage: "Earliest availability",
    description: "Label for the configuration option",
  },
  inputPrompt: {
    id: "selfServeBookings.EarliestAvailabilityConfiguration.inputPrompt",
    defaultMessage:
      "Accept appointments as early as {input} business day(s) away",
    description: "Prompt for the input",
  },
  inputAriaLabel: {
    id: "selfServeBookings.EarliestAvailabilityConfiguration.inputAriaLabel",
    defaultMessage:
      "Accepting appointments as early as this many business days away",
    description: "Aria label for the input",
  },
  saveMessage: {
    id: "selfServeBookings.EarliestAvailabilityConfiguration.saveMessage",
    defaultMessage: "Updated earliest availability",
    description: "Success message when saving succeeds",
  },
  errorMessage: {
    id: "selfServeBookings.EarliestAvailabilityConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Error message when saving fails",
  },
});
