import { useMutation } from "@apollo/client";
import type { LocationTimersEnablementMutation } from "~/utilities/API/graphql";
import { LOCATION_TIMERS_TOGGLE_FEATURE_MUTATION } from "./LocationTimersSettingsPage.graphql";

export function useLocationTimersFeatureSwitchMutation() {
  const [switchMutation, { loading, error }] =
    useMutation<LocationTimersEnablementMutation>(
      LOCATION_TIMERS_TOGGLE_FEATURE_MUTATION,
    );

  return {
    switchMutation,
    loading,
    error,
  };
}
