import { startOfDay } from "date-fns";
import { useMemo } from "react";

export function useHighlightedDatesGroupedByTimeStamp(
  highlightedDates: Date[],
) {
  return useMemo(
    () =>
      highlightedDates.reduce(
        (acc, date) => {
          acc[startOfDay(date).getTime()] = true;

          return acc;
        },
        {} as Record<string, boolean>,
      ),
    [highlightedDates],
  );
}
