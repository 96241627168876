import React from "react";
import { type IntlFormatters, useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { strFormatDate } from "@jobber/components/FormatDate";
import { isAfter } from "date-fns";
import { formatCurrency } from "utilities/formatCurrency";
import { messages } from "./messages";

interface PurchaseDisclaimerProps {
  startBillingOnDate: Date;
  baseUnitPrice: number;
  nextBillingDate: Date;
  subscriptionDisplayName: string;
  discountedUnitPrice?: number;
  discountEndDate?: Date;
}

export function PurchaseDisclaimer({
  startBillingOnDate,
  baseUnitPrice,
  nextBillingDate,
  subscriptionDisplayName,
  discountedUnitPrice,
  discountEndDate,
}: PurchaseDisclaimerProps) {
  const { formatMessage } = useIntl();

  const isDiscounted =
    discountedUnitPrice !== undefined && discountEndDate !== undefined;

  let message = "";
  if (isBilledImmediately(startBillingOnDate)) {
    message = getImmediateBilledMessage(
      formatMessage,
      isDiscounted,
      baseUnitPrice,
      nextBillingDate,
      subscriptionDisplayName,
      discountEndDate,
      discountedUnitPrice,
    );
  } else {
    message = getFutureBilledMessage(
      formatMessage,
      isDiscounted,
      baseUnitPrice,
      subscriptionDisplayName,
      discountEndDate,
      nextBillingDate,
    );
  }

  return (
    <div data-testid="purchase-disclaimer">
      <Text variation="subdued" size="small">
        {message}
      </Text>
    </div>
  );
}

function getImmediateBilledMessage(
  formatMessage: IntlFormatters["formatMessage"],
  isDiscounted: boolean,
  baseUnitPrice: number,
  nextBillingDate: Date,
  subscriptionDisplayName: string,
  discountEndDate?: Date,
  discountedUnitPrice?: number,
) {
  if (
    isDiscounted &&
    discountEndDate &&
    isAfter(discountEndDate, nextBillingDate)
  ) {
    return formatMessage(messages.immediateDiscountedChargeMessage, {
      subscriptionDisplayName: subscriptionDisplayName,
      nextPaymentAmount: getFormattedPrice(discountedUnitPrice),
      nextBillingDate: getFormattedDate(nextBillingDate),
      discountEndDate: getFormattedDate(discountEndDate),
      baseUnitPrice: getFormattedPrice(baseUnitPrice),
    });
  }

  return formatMessage(messages.immediateNonDiscountedChargeMessage, {
    subscriptionDisplayName: subscriptionDisplayName,
    nextPaymentAmount: getFormattedPrice(baseUnitPrice),
    nextBillingDate: getFormattedDate(nextBillingDate),
  });
}

function getFutureBilledMessage(
  formatMessage: IntlFormatters["formatMessage"],
  isDiscounted: boolean,
  baseUnitPrice: number,
  subscriptionDisplayName: string,
  discountEndDate?: Date,
  nextBillingDate?: Date,
) {
  if (
    isDiscounted &&
    discountEndDate &&
    nextBillingDate &&
    isAfter(discountEndDate, nextBillingDate)
  ) {
    return formatMessage(messages.futureBilledDiscountedChargeMessage, {
      subscriptionDisplayName: subscriptionDisplayName,
      discountEndDate: getFormattedDate(discountEndDate),
      baseUnitPrice: getFormattedPrice(baseUnitPrice),
    });
  }

  return "";
}

export function getFormattedPrice(total?: number) {
  if (total) {
    return formatCurrency(total, "$", 2);
  }
  return "";
}

export function getFormattedDate(date?: Date) {
  if (date) {
    return strFormatDate(date);
  }
  return "";
}

function isBilledImmediately(startBillingDate: Date) {
  const today = new Date();
  return !isAfter(startBillingDate, today);
}
