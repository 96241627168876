import React from "react";
import { Typography } from "@jobber/components";
import { formatCurrency } from "utilities/formatCurrency";
import { BillingCycleName } from "~/utilities/API/graphql";
import styles from "./PriceHighlightText.module.css";

export function PriceHighlightText({
  cost,
  discountedCost,
  hideDiscountedCost,
  billingCycleName = BillingCycleName.MONTHLY,
  size = "med",
}: {
  cost?: number;
  discountedCost?: number;
  hideDiscountedCost?: boolean;
  billingCycleName?: BillingCycleName.ANNUAL | BillingCycleName.MONTHLY;
  size?: "small" | "med" | "large";
}) {
  const sizeMap: { [key: string]: string } = {
    small: "small",
    med: "large",
    large: "jumbo",
  };

  const billingCycleSuffix =
    billingCycleName === BillingCycleName.ANNUAL ? "/yr" : "/mo";
  const showDiscountedCost = !!discountedCost && !hideDiscountedCost;

  if (!cost) {
    return null;
  }

  return (
    <div>
      <Typography
        size={sizeMap[size] as "small" | "large" | "jumbo"}
        fontWeight="extraBold"
        element="span"
      >
        <span
          className={showDiscountedCost ? styles.basePriceStrikeThrough : ""}
          data-testid="marketing-base-price"
        >
          {getHeaderPrice(cost)}
        </span>
        {showDiscountedCost && <span>{getHeaderPrice(discountedCost)}</span>}
      </Typography>
      <Typography size="small" element="span">
        {billingCycleSuffix}
      </Typography>
    </div>
  );
}

function getHeaderPrice(cost: number) {
  return formatCurrency(cost, "$", 0);
}
