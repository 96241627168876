import React from "react";
import { Spinner } from "@jobber/components/Spinner";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { APIProvider } from "~/utilities/API/APIProvider";
import { useUkKycRegistrationDetailsQuery } from "jobber/hooks/comms/useUkKycRegistrationDetailsQuery";
import { IntlProvider } from "@translations/IntlProvider";
import { UkKycComplianceRegistrationMain } from "./components/UkKycComplianceRegistrationMain";
import { messages } from "./messages";

export function UkKycComplianceRegistrationLoader() {
  return (
    <IntlProvider>
      <APIProvider>
        <InternalUkKycComplianceRegistrationLoader />
      </APIProvider>
    </IntlProvider>
  );
}

function InternalUkKycComplianceRegistrationLoader() {
  const { formatMessage } = useIntl();
  const { loading, error, data } = useUkKycRegistrationDetailsQuery();

  if (loading) {
    return <Spinner />;
  }

  if (error || !data) {
    <Content>
      <Banner type="error">
        {formatMessage(messages.registrationLoadingError)}
      </Banner>
    </Content>;
  }

  return (
    <UkKycComplianceRegistrationMain
      startingRegistration={data?.commsUkKycRegistrationDetails?.registration}
    />
  );
}
