import React from "react";
import { Content } from "@jobber/components/Content";
import { InputGroup } from "@jobber/components/InputGroup";
import { useIntl } from "react-intl";
import { RecurlyInputText } from "jobber/billing/components/EditBillingInfo/components/RecurlyInputText";
import type { UpdatedPaymentDetailsType } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import {
  type TrackingDetails,
  trackInteractedWithInput,
} from "jobber/billing/utils/trackInteractions";
import { useCardValidationHandler } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsEdit/hooks/useCardValidationHandler";
import styles from "./PaymentDetailsEdit.module.css";
import { messages } from "./messages";
import { RecurlyCardFields } from "./components/RecurlyCardFields";

interface PaymentDetailsEditProps {
  updatedPaymentDetails: UpdatedPaymentDetailsType;
  trackingDetails: TrackingDetails;
  setUpdatedPaymentDetails: React.Dispatch<
    React.SetStateAction<UpdatedPaymentDetailsType>
  >;
  setRecurlyFieldsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
}

interface RecurlyPaymentDetailsProps {
  updatedPaymentDetails: UpdatedPaymentDetailsType;
  handleInputFocus: (input: string) => void;
  setUpdatedPaymentDetails: React.Dispatch<
    React.SetStateAction<UpdatedPaymentDetailsType>
  >;
  setRecurlyFieldsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
}

export function PaymentDetailsEdit(props: PaymentDetailsEditProps) {
  const {
    updatedPaymentDetails,
    trackingDetails,
    setUpdatedPaymentDetails,
    setRecurlyFieldsInitialized,
    onValidationError,
  } = props;

  return (
    <RecurlyPaymentDetails
      updatedPaymentDetails={updatedPaymentDetails}
      handleInputFocus={handleInputFocus}
      setUpdatedPaymentDetails={setUpdatedPaymentDetails}
      setRecurlyFieldsInitialized={setRecurlyFieldsInitialized}
      onValidationError={onValidationError}
    />
  );

  function handleInputFocus(inputName: string) {
    const eventInputMapping: { [input: string]: string } = {
      /* eslint-disable @typescript-eslint/naming-convention */
      first_name: "firstName",
      last_name: "lastName",
    };

    trackInteractedWithInput({
      ...trackingDetails,
      input: eventInputMapping[inputName] || inputName,
    });
  }
}

function RecurlyPaymentDetails({
  updatedPaymentDetails,
  handleInputFocus,
  setUpdatedPaymentDetails,
  setRecurlyFieldsInitialized,
  onValidationError,
}: RecurlyPaymentDetailsProps) {
  const { formatMessage } = useIntl();

  const { firstName, lastName } = updatedPaymentDetails;

  const { handleCardValidation, recurlyInputValidation } =
    useCardValidationHandler(onValidationError, setRecurlyFieldsInitialized);

  return (
    <Content>
      <InputGroup flowDirection="horizontal">
        <RecurlyInputText
          className={styles.nameRowItem}
          inputValue={firstName}
          inputName={formatMessage(messages.firstNamePlaceholder)}
          dataRecurlyTag={"first_name"}
          required={true}
          handleFocus={handleInputFocus}
          setSelected={setPaymentDetailsNameField("firstName")}
          onValidationError={onValidationError}
        />
        <RecurlyInputText
          className={styles.nameRowItem}
          inputValue={lastName}
          inputName={formatMessage(messages.lastNamePlaceholder)}
          dataRecurlyTag={"last_name"}
          required={true}
          handleFocus={handleInputFocus}
          setSelected={setPaymentDetailsNameField("lastName")}
          onValidationError={onValidationError}
        />
      </InputGroup>
      <RecurlyCardFields
        handleInputFocus={handleInputFocus}
        recurlyInputValidation={recurlyInputValidation}
        handleCardValidation={handleCardValidation}
      />
    </Content>
  );

  function setPaymentDetailsNameField(
    field: keyof Partial<UpdatedPaymentDetailsType>,
  ) {
    return (value: string) => {
      setUpdatedPaymentDetails({
        ...updatedPaymentDetails,
        [field]: value,
      });
    };
  }
}
