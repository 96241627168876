import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React from "react";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import styles from "./PaymentsCard.module.css";

interface BasePayout {
  id: string | number;
  amount: string;
}

interface InstantPayout extends BasePayout {
  type: "instant";
  date?: string;
}

interface StandardPayout extends BasePayout {
  type: "standard";
  date: string;
}

export type Payout = InstantPayout | StandardPayout;

export interface NextPayoutProp {
  currencyUnit: string;
  payout: Payout;
}

export function NextPayout(props: NextPayoutProp) {
  const { payout, currencyUnit } = props;

  const [viewPayoutsUrl] = useUrls("jobberPaymentsViewPayoutsUrl");
  const amount = currencyWithUnit(payout.amount, currencyUnit).format();

  return (
    <Content spacing="small">
      <Text>
        {payout.type === "standard"
          ? `Expected on ${payout.date}`
          : "Instant payout expected in seconds"}
      </Text>
      <div className={styles.container}>
        <div className={styles.content}>
          <Heading level={2}>{amount}</Heading>
        </div>
        <div className={styles.action}>
          <Button
            type="secondary"
            size="small"
            label="View Report"
            url={viewPayoutsUrl}
          />
        </div>
      </div>
    </Content>
  );
}
