import type { DataListSorting } from "@jobber/components/DataList";
import { DataList } from "@jobber/components/DataList";
import type { ReactElement, ReactNode } from "react";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { generatePath } from "react-router-dom";
import { Combobox } from "@jobber/components/Combobox";
import { useCampaignEmailTransformer } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/hooks/useCampaignEmailTransformer/useCampaignEmailTransformer";
import type {
  CampaignEmailFilterFields,
  CampaignEmailFragment,
} from "~/utilities/API/graphql";
import { CLIENT_PAGE_PATH } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/constants";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { useCommsCampaignsExperienceQuery } from "jobber/campaigns/hooks/useCommsCampaignsExperienceQuery";
import type {
  EmailFilterOption,
  FilterActions,
} from "jobber/campaigns/views/CampaignReportingDetailsPage/hooks/useCampaignsReportFilters";
import {
  CAMPAIGN_EMAIL_SORT_DIRECTION,
  CAMPAIGN_EMAIL_SORT_KEYS,
} from "jobber/campaigns/hooks/useCampaignEmailsQuery/useCampaignEmailsQuery";
import {
  DateRangeButton,
  InputDateRange,
} from "~/shared/InputDateRange/InputDateRange";
import type { DateRange } from "~/shared/InputDateRange/types";
import { CampaignEmailsTableRow } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/components/CampaignEmailsTableRow/CampaignEmailsTableRow";
import { useShouldShowJobInformation } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/hooks/useShouldShowJobInformation/useShouldShowJobInformation";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import styles from "./CampaignEmailsTable.module.css";
import { messages } from "./messages";
import { campaignsEmailDateOptions } from "../../types";
import type { DateRangeOptions, SortAndFilterState } from "../../types";

interface DataListObject {
  readonly id: string | number;

  readonly label?: string | ReactElement;

  readonly [key: string]: ReactNode | Date;
}

export interface SingleEmail extends DataListObject {
  id: string;
  recipient: JSX.Element;
  statuses?: string | undefined;
  delivered?: string | undefined;
  opened?: string | undefined;
  clicked?: string | undefined;
  unsubscribed?: string | undefined;
  jobs: ReactNode;
  revenue: ReactNode;
  clientId: string | undefined;
}

export interface CampaignEmailsEdgeUsingFragment {
  __typename?: "CampaignsEmailEdge";
  node: CampaignEmailFragment;
}

export interface CampaignEmailsTableProps {
  emails: CampaignEmailsEdgeUsingFragment[];
  handleSearch: (newSearchTerm: string) => void;
  loadingInitial: boolean;
  loadingMore: boolean;
  totalCount: number;
  loadMoreEmails(): void;
  filter?: EmailFilterOption;
  filterActions: FilterActions;
  filterOptions: EmailFilterOption[];
  setVariablesForSorting: (
    sortKey: CAMPAIGN_EMAIL_SORT_KEYS,
    sortDirection: CAMPAIGN_EMAIL_SORT_DIRECTION,
  ) => void;
  dateRangeFilter: SortAndFilterState;
  campaignIsAutomated: boolean | undefined;
}

export function CampaignEmailsTable({
  emails,
  handleSearch,
  loadingInitial,
  loadingMore,
  totalCount,
  loadMoreEmails,
  filter,
  filterActions,
  filterOptions,
  setVariablesForSorting,
  dateRangeFilter,
  campaignIsAutomated,
}: CampaignEmailsTableProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { data: commsCampaignsExperience } = useCommsCampaignsExperienceQuery();

  const [sortingState, setSortingState] = useState<DataListSorting | undefined>(
    { order: "asc", key: CAMPAIGN_EMAIL_SORT_KEYS.RECIPIENT },
  );

  const {
    campaignContent: { templateType },
  } = useCampaignWizardContext();

  const hasDemoExperience =
    commsCampaignsExperience?.commsCampaignsExperience?.hasDemoExperience;
  const emailToDataListItem = useCampaignEmailTransformer({
    hasDemoExperience,
  });
  const dataListItems = emails.map(email => emailToDataListItem(email.node));
  const title =
    filter || dateRangeFilter.values.dateRangeFilter.before
      ? formatMessage(messages.filteredEmailsTableTitle)
      : formatMessage(messages.emailsTableTitle);

  const { showJobInformation, headers } =
    useShouldShowJobInformation(templateType);

  function navigateToClient(item: SingleEmail) {
    if (item.clientId) {
      const clientId = decodeId(item.clientId);
      window.location.href = generatePath(CLIENT_PAGE_PATH, { clientId });
    }
  }

  return (
    <PrivacyMask>
      <DataList
        data={dataListItems}
        headers={headers}
        headerVisibility={{ xs: false, lg: true }}
        title={title}
        loadingState={getLoadingState({ loadingMore, loadingInitial })}
        totalCount={totalCount}
        onLoadMore={loadMoreEmails}
        filtered={!!filter || !!dateRangeFilter.values.dateRangeFilter.before}
        sorting={{
          state: sortingState,
          onSort: sorting => {
            const sortDirection =
              sorting?.order === "desc"
                ? CAMPAIGN_EMAIL_SORT_DIRECTION.DESCENDING
                : CAMPAIGN_EMAIL_SORT_DIRECTION.ASCENDING;
            setVariablesForSorting(
              CAMPAIGN_EMAIL_SORT_KEYS.RECIPIENT,
              sortDirection,
            );
            setSortingState(sorting);
          },
          sortable: [
            {
              key: "recipient",
              sortType: "toggle",
              options: [
                {
                  id: "recipient",
                  label: formatMessage(messages.mobileSortRecipientAtoZ),
                  order: "asc",
                },
                {
                  id: "recipient",
                  label: formatMessage(messages.mobileSortRecipientZtoA),
                  order: "desc",
                },
              ],
            },
          ],
        }}
      >
        <DataList.Filters>
          <Combobox
            label={formatMessage(messages.emailFilter)}
            selected={filter ? [filter] : []}
            onSelect={type =>
              filterActions.setFilterType(
                type[0].id as CampaignEmailFilterFields,
              )
            }
          >
            {filterOptions.map(option => {
              return (
                <Combobox.Option
                  id={option.id}
                  label={option.label}
                  key={option.id}
                />
              );
            })}
          </Combobox>
          <>
            {campaignIsAutomated && (
              <InputDateRange
                dateRangeOptions={campaignsEmailDateOptions}
                onChange={(newPresetRange: {
                  preset: DateRangeOptions;
                  range: DateRange;
                }) => {
                  dateRangeFilter.setters.setPresetFilter(
                    newPresetRange.preset,
                  );
                  dateRangeFilter.setters.setDateRange(newPresetRange.range);
                }}
                initialValue={{
                  preset: dateRangeFilter.values.presetFilter,
                  range: {
                    after: dateRangeFilter.values.dateRangeFilter.after,
                    before: dateRangeFilter.values.dateRangeFilter.before,
                  },
                }}
                ref={dateRangeFilter.refs.inputDateRangeRef}
              >
                <DateRangeButton
                  range={dateRangeFilter.values.dateRangeFilter}
                />
              </InputDateRange>
            )}
          </>
        </DataList.Filters>
        <DataList.ItemActions onClick={navigateToClient} />
        <DataList.Search
          placeholder={formatMessage(messages.emailSearchRecipientPlaceholder)}
          onSearch={handleSearch}
        />
        <DataList.Layout size="lg">
          {(item: SingleEmail) => (
            <CampaignEmailsTableRow
              item={item}
              showJobInformation={showJobInformation}
            />
          )}
        </DataList.Layout>
        <DataList.Layout size="xs">
          {(item: SingleEmail) => (
            <Content>
              {item.recipient}
              <div className={styles.mobileStatusLine}>
                <div className={styles.mobileStatusLineItem}>
                  <Text>{item.statuses}</Text>
                </div>
              </div>
              {showJobInformation && (
                <div className={styles.mobileStatusLine}>
                  <div className={styles.mobileStatusLineItem}>{item.jobs}</div>
                </div>
              )}
            </Content>
          )}
        </DataList.Layout>
        <DataList.EmptyState
          type="filtered"
          message={formatMessage(messages.noResultsFound)}
          action={
            <Button
              label={formatMessage(messages.clearFilter)}
              onClick={() => filterActions.clearFilter()}
            />
          }
        />
      </DataList>
    </PrivacyMask>
  );
}

function getLoadingState({
  loadingMore,
  loadingInitial,
}: {
  loadingMore?: boolean;
  loadingInitial?: boolean;
}) {
  if (loadingInitial) {
    return "initial";
  }
  if (loadingMore) {
    return "loadingMore";
  }
  return undefined;
}
