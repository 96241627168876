import { v1 as uuidV1 } from "uuid";

// default localstorage hook format
interface JSONLeafProp {
  [key: string]: string | number | boolean | number[] | string[] | undefined;
}
export interface JSONClientData {
  [key: string]:
    | JSONClientData
    | JSONLeafProp
    | string
    | number
    | boolean
    | undefined;
}

export const STORE_PREFIX = "icd-";
const STORE_LOCK = "__lock-icd__";

export function setLocalStorageItems(newData: JSONClientData): boolean {
  try {
    window.localStorage.setItem(
      `${STORE_PREFIX}${uuidV1()}`,
      JSON.stringify(newData),
    );
    return true;
  } catch (error) {
    return false;
  }
}

export function restoreLocalStorageItems(savedData: string): boolean {
  try {
    const dataObject = JSON.parse(savedData) as JSONClientData;
    if (dataObject) {
      Object.keys(dataObject).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(dataObject, key)) {
          window.localStorage.setItem(key, JSON.stringify(dataObject[key]));
        }
      });
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function findLocalStorageItems(): string {
  const results: { [key: string]: string } = {};

  if (!isEventDataStoreAccessible()) return "";

  try {
    lockEventDataStore();
    Object.keys(window.localStorage).forEach(key => {
      if (key.startsWith(STORE_PREFIX)) {
        const icdRecord = window.localStorage.getItem(key);
        if (icdRecord) {
          const value = JSON.parse(icdRecord) as string;
          results[key] = value;
        }
      }
    });

    if (Object.keys(results).length > 0) {
      return JSON.stringify(results);
    }
    return "";
  } catch (error) {
    return "";
  } finally {
    unlockEventDataStore();
  }
}

export function deleteLocalStorageItems() {
  if (!isEventDataStoreAccessible()) return;

  try {
    lockEventDataStore();
    Object.keys(window.localStorage).forEach(key => {
      if (key.startsWith(STORE_PREFIX)) {
        window.localStorage.removeItem(key);
      }
    });
  } catch (error) {
    return;
  } finally {
    unlockEventDataStore();
  }
}

export function isEventDataStoreAccessible(): boolean {
  try {
    return window.localStorage.getItem(STORE_LOCK) ? false : true;
  } catch (error) {
    return false;
  }
}

export function lockEventDataStore(): boolean {
  try {
    window.localStorage.setItem(STORE_LOCK, Date.now().toString());
    return true;
  } catch (error) {
    return false;
  }
}

export function unlockEventDataStore(): boolean {
  try {
    window.localStorage.removeItem(STORE_LOCK);
    return true;
  } catch (error) {
    return false;
  }
}
