import classNames from "classnames";
import React, { useEffect } from "react";
import { Button } from "@jobber/components/Button";
import { useMutation } from "@apollo/client";
import type { InputTextRef } from "@jobber/components/InputText";
import { useInView } from "react-intersection-observer";
import { SEND_MESSAGE_MUTATION } from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";
import { StopState } from "jobber/chat/components/ChatDrawer/Chat/ChatInput/StopState";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { useStateWithLocalStorage } from "utilities/localStorage";
import {
  MessageStatusEnum,
  type Scalars,
  type SendTextMessageMutation,
  type SendTextMessageMutationVariables,
} from "~/utilities/API/graphql";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import styles from "./ChatInput.module.css";

export interface ChatInputProps {
  recipient: string | undefined;
  canSendMessage?: boolean;
  conversationId: string;
  afterSend?(): void;
  afterInput?(
    message: string,
    clientGeneratedId: Scalars["Uuid"]["input"],
    status: MessageStatusEnum,
  ): void;
}
const inputRef = React.createRef<InputTextRef>();
export function ChatInput({
  recipient,
  canSendMessage = true,
  conversationId,
  afterSend,
  afterInput,
}: ChatInputProps) {
  const conversationKey = `conversation_${conversationId}`;

  const [message, setMessage] = useStateWithLocalStorage(
    conversationKey,
    "",
    () => {
      return "";
    },
  );
  const [sendSMS] = useMutation<
    SendTextMessageMutation,
    SendTextMessageMutationVariables
  >(SEND_MESSAGE_MUTATION);
  const { ref: inViewRef, inView } = useInView();

  useEffect(() => {
    if (inView && inputRef.current) {
      const messageLength = message.length;
      inputRef.current.insert(message);
      setMessage(message.slice(0, messageLength));
      inputRef.current.focus();
    }
  }, [inView]);

  useEffect(() => {
    if (!message.length) {
      return () => {
        localStorage.removeItem(conversationKey);
      };
    }
  }, [message]);

  return (
    <div
      className={classNames(
        styles.container,
        "row collapse shrink bottom align-top u-borderTop u-colorGreyBlue",
      )}
    >
      {canSendMessage && (
        <>
          <div className={classNames(styles.input, "columns")} ref={inViewRef}>
            <MagicInputText
              onChange={messageOnChange}
              value={message}
              placeholder="Type a message..."
              size={"small"}
              multiline={true}
              rows={{ min: 2, max: 10 }}
              onEnter={hitEnter}
              ref={inputRef}
            />
          </div>
          <div
            className={classNames(
              styles.send,
              "columns shrink align-self-top small-2",
            )}
          >
            <Button
              icon={"sendMessage"}
              ariaLabel="send"
              onClick={sendMessage}
              disabled={message === ""}
            />
          </div>
        </>
      )}
      {!canSendMessage && (
        <div className={"columns"} ref={inViewRef}>
          <StopState />
        </div>
      )}
    </div>
  );

  function hitEnter(event: React.KeyboardEvent<HTMLElement>) {
    event.preventDefault();
    if (message !== "") {
      sendMessage();
    }
  }

  function messageOnChange(changedMessage: string) {
    setMessage(changedMessage);
  }

  function sendMessage() {
    const clientGeneratedId =
      crypto.randomUUID && (crypto.randomUUID() as Scalars["Uuid"]["input"]);
    afterInput &&
      afterInput(message, clientGeneratedId, MessageStatusEnum.QUEUED);
    sendSMS({
      variables: {
        message: message,
        recipient: recipient || "",
        source: "jobber online",
        clientGeneratedId: clientGeneratedId,
      },
    })
      .then(result => {
        if (afterSend) {
          afterSend();
        }
        if (
          result.data?.sendSms.userErrors &&
          result.data?.sendSms.userErrors.length > 0
        ) {
          afterInput &&
            afterInput(message, clientGeneratedId, MessageStatusEnum.FAILED);
        }
        localStorage.removeItem(conversationKey);
      })
      .catch((error: Error) => {
        afterInput &&
          afterInput(message, clientGeneratedId, MessageStatusEnum.FAILED);
        Rollbar.EXECUTE("Error Sending SMS Message", error);
      });

    setMessage("");
  }
}
