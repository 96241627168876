import React from "react";
import { useIntl } from "react-intl";
import { Content, Heading, Text } from "@jobber/components";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { messages } from "../messages";

export interface ClientBalancesProps {
  totalBalance?: number;
  numberOfClientsWithBalances?: number;
}

export const ClientBalances = ({
  totalBalance,
  numberOfClientsWithBalances,
}: ClientBalancesProps) => {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();

  if (numberOfClientsWithBalances === undefined) {
    return null;
  }

  return (
    <Content type={"section"} spacing={"small"}>
      <Text size="small">
        {formatMessage(messages.receivablesClientsOweYou, {
          numberOfClientsWithBalances,
        })}
      </Text>
      <Heading level={2}>
        {totalBalance != null ? currencyFormatter(totalBalance) : "-"}
      </Heading>
    </Content>
  );
};
