import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sentStatus: {
    id: "campaigns.landingPage.sentStatus",
    defaultMessage: "Sent",
    description: "Status Filter Label",
  },
  inProgressStatus: {
    id: "campaigns.landingPage.inProgressStatus",
    defaultMessage: "In progress",
    description: "In Progress Status Filter Label",
  },
  draftStatus: {
    id: "campaigns.landingPage.draftStatus",
    defaultMessage: "Draft",
    description: "Draft Status Filter Label",
  },
  scheduledStatus: {
    id: "campaigns.landingPage.scheduledStatus",
    defaultMessage: "Scheduled",
    description: "Scheduled Status Filter Label",
  },
  failedStatus: {
    id: "campaigns.landingPage.failedStatus",
    defaultMessage: "Failed",
    description: "Failed Status Filter Label",
  },
  failedStatusToolTip: {
    id: "campaigns.landingPage.failedStatusToolTip",
    defaultMessage:
      "There was an issue with sending this campaign. Please contact our success team for assistance.",
    description: "ToolTip for the Failed Status Label",
  },
  scheduledStatusToolTip: {
    id: "campaigns.landingPage.scheduledStatusToolTip",
    defaultMessage: "Scheduled to be sent {date} at {time}",
    description: "ToolTip for the Failed Status Label",
  },
  automationActiveStatus: {
    id: "campaigns.landingPage.automationActiveStatus",
    defaultMessage: "Active",
    description: "Automation Active Status Label",
  },
  automationInactiveStatus: {
    id: "campaigns.landingPage.automationInactiveStatus",
    defaultMessage: "Inactive",
    description: "Automation Inactive Status Label",
  },
});
