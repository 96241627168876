// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Icon } from "@jobber/components/Icon";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InputText } from "@jobber/components";
import type { MessageFormat } from "jobber/setupWizard/types";
import styles from "../../SetupWizardForms.module.css";
import { messages } from "../messages";

interface BusinessPriorityCardsProp {
  topPriorityArray: { value: string; label: MessageFormat }[];
  handleSelect(newValue: string): void;
  defaultValue: string;
  defaultText: string;
  handleChangeOtherText(input: string): void;
}

export function BusinessPriorityCards({
  topPriorityArray,
  handleSelect,
  defaultValue,
  defaultText,
  handleChangeOtherText,
}: BusinessPriorityCardsProp) {
  const { formatMessage } = useIntl();
  const [topPriority, setTopPriority] = useState<string>(defaultValue);
  const [otherText, setOtherText] = useState<string>(defaultText);

  useEffect(() => {
    if (topPriority !== "Other") {
      setOtherText("");
    }
  }, [topPriority]);

  return (
    <div>
      {topPriorityArray.map((option, index) => {
        const selected = topPriority === option.value;

        return (
          <div key={index}>
            <button
              className={
                selected
                  ? `${styles.goalCard} ${styles.goalCardSelected}`
                  : styles.goalCard
              }
              type="button"
              key={index}
              value={option.value}
              onClick={() => {
                if (topPriority === option.value) {
                  setTopPriority("");
                  handleSelect("");
                } else {
                  setTopPriority(option.value);
                  handleSelect(option.value);
                }
              }}
            >
              <Typography fontWeight={selected ? "bold" : "regular"}>
                {formatMessage(option.label)}
              </Typography>
              <div className={styles.icon}>
                {selected && <Icon name="checkmark" />}
              </div>
            </button>
          </div>
        );
      })}
      <div className={styles.otherText}>
        <InputText
          size="large"
          data-testid="otherPriorityText"
          name="otherText"
          placeholder={formatMessage(messages.topPriorityOtherTextPlaceholder)}
          value={otherText}
          validations={{
            maxLength: {
              value: 255,
              message: formatMessage(messages.topPriorityOtherTextValidation),
            },
          }}
          onChange={(input: string) => {
            setOtherText(input);
            handleChangeOtherText(input);
            setTopPriority("Other");
            handleSelect("Other");
          }}
          onFocus={() => {
            setTopPriority("Other");
            handleSelect("Other");
          }}
          onBlur={() => {
            if (otherText === "") {
              setTopPriority("");
              handleSelect("");
            }
          }}
        />
      </div>
    </div>
  );
}
