import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { InputNumber } from "@jobber/components/InputNumber";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import React, { useState } from "react";

interface EditBookingCapacityProps {
  spotsTotal: number;
  onSaveCapacity(spotsTotal: number): Promise<void>;
}

export function EditBookingCapacity({
  spotsTotal,
  onSaveCapacity,
}: EditBookingCapacityProps) {
  const [currentSpotsTotal, setCurrentSpotsTotal] = useState(spotsTotal);
  const onChangeCapacitySlots = (newValue: number) => {
    setCurrentSpotsTotal(newValue);
  };

  const [editBookingCapacityOpen, setEditBookingCapacityOpen] = useState(false);
  const openEditBookingCapacity = () => {
    setEditBookingCapacityOpen(true);
  };
  const closeEditBookingCapacity = () => {
    setCurrentSpotsTotal(spotsTotal);
    setEditBookingCapacityOpen(false);
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const saveEditBookingCapacity = () => {
    setSaveLoading(true);

    onSaveCapacity(currentSpotsTotal).then(
      () => {
        showToast({
          message: "Booking capacity saved",
          variation: "success",
        });
        setSaveLoading(false);
        setEditBookingCapacityOpen(false);
      },
      () => {
        showToast({
          message: "A problem occurred when saving your booking capacity",
          variation: "error",
        });
        setSaveLoading(false);
      },
    );
  };

  return (
    <>
      <Button label="Edit" type="tertiary" onClick={openEditBookingCapacity} />

      <Modal
        title="Edit Your Appointment Capacity"
        size="large"
        open={editBookingCapacityOpen}
        onRequestClose={closeEditBookingCapacity}
        primaryAction={{
          label: "Save",
          onClick: saveEditBookingCapacity,
          loading: saveLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: closeEditBookingCapacity,
        }}
      >
        <Content spacing="small">
          <Text>
            Show arrival window as unavailable for new bookings if there are
            more than{" "}
            <InputNumber
              value={currentSpotsTotal}
              size="small"
              min={1}
              maxLength={3}
              inline={true}
              onChange={onChangeCapacitySlots}
            />{" "}
            scheduled on the calendar.
          </Text>
          <Text>
            This includes appointments scheduled outside of Local Services Ads
            bookings. We recommend you match this to the number of crews you
            typically have in the field.
          </Text>
        </Content>
      </Modal>
    </>
  );
}
