import type { DataProxy } from "@apollo/client";
import type {
  CustomFieldConfigurationCreateAreaMutation as CreateAreaMutation,
  CustomFieldConfigurationCreateDropdownMutation as CreateDropdownMutation,
  CustomFieldConfigurationCreateNumericMutation as CreateNumericMutation,
  CustomFieldConfigurationCreateTextMutation as CreateTextMutation,
  CustomFieldConfigurationCreateTrueFalseMutation as CreateTrueFalseMutation,
  CustomFieldConfigurationNodeFragment,
  CustomFieldPageListQuery,
} from "~/utilities/API/graphql";
import { CUSTOM_FIELD_LIST } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";

export function updateCacheText(
  cache: DataProxy,
  { data }: { data: CreateTextMutation | null | undefined },
) {
  if (data?.customFieldConfigurationCreateText) {
    const newNode =
      data.customFieldConfigurationCreateText.customFieldConfiguration;
    if (newNode) {
      updateCache(cache, newNode);
    }
  }
}

export function updateCacheTrueFalse(
  cache: DataProxy,
  {
    data,
  }: {
    data: CreateTrueFalseMutation | null | undefined;
  },
) {
  if (data?.customFieldConfigurationCreateTrueFalse) {
    const newNode =
      data.customFieldConfigurationCreateTrueFalse.customFieldConfiguration;
    if (newNode) {
      updateCache(cache, newNode);
    }
  }
}

export function updateCacheNumeric(
  cache: DataProxy,
  {
    data,
  }: {
    data: CreateNumericMutation | null | undefined;
  },
) {
  if (data?.customFieldConfigurationCreateNumeric) {
    const newNode =
      data.customFieldConfigurationCreateNumeric.customFieldConfiguration;
    if (newNode) {
      updateCache(cache, newNode);
    }
  }
}

export function updateCacheArea(
  cache: DataProxy,
  { data }: { data: CreateAreaMutation | null | undefined },
) {
  if (data?.customFieldConfigurationCreateArea) {
    const newNode =
      data.customFieldConfigurationCreateArea.customFieldConfiguration;
    if (newNode) {
      updateCache(cache, newNode);
    }
  }
}

export function updateCacheDropdown(
  cache: DataProxy,
  {
    data,
  }: {
    data: CreateDropdownMutation | null | undefined;
  },
) {
  if (data?.customFieldConfigurationCreateDropdown) {
    const newNode =
      data.customFieldConfigurationCreateDropdown.customFieldConfiguration;
    if (newNode) {
      updateCache(cache, newNode);
    }
  }
}

export function updateCache(
  cache: DataProxy,
  newNode: CustomFieldConfigurationNodeFragment,
) {
  const cachedQuery = cache.readQuery<CustomFieldPageListQuery>({
    query: CUSTOM_FIELD_LIST,
  });

  if (cachedQuery) {
    const cachedData = cachedQuery.customFieldConfigurations.nodes;
    if (cachedData) {
      cache.writeQuery({
        query: CUSTOM_FIELD_LIST,
        data: {
          customFieldConfigurations: {
            __typename: cachedQuery.customFieldConfigurations.__typename,
            nodes: [...cachedData, newNode],
          },
        },
      });
    }
  }
}
