import { defineMessages } from "react-intl";

export const messages = defineMessages({
  servicesInfo: {
    id: "selfServeBookings.offeringsCard.servicesInfo",
    defaultMessage: "Choose which services clients can book you for.",
    description: "Description on Services Card",
  },
  servicesAdded: {
    id: "selfServeBookings.offeringsCard.servicesAdded",
    defaultMessage: "services added",
    description: "Description when on Services Card",
  },
  singularServiceAdded: {
    id: "selfServeBookings.offeringsCard.singularServiceAdded",
    defaultMessage: "service added",
    description: "Description when on Services Card",
  },
  manageServicesButtonLabel: {
    id: "selfServeBookings.offeringsCard.manageServicesButtonLabel",
    defaultMessage: "Manage Services",
    description: "Label for Manage Service Button",
  },
  services: {
    id: "selfServeBookings.offeringsCard.services",
    defaultMessage: "Bookable services",
    description: "Title of the Services Card",
  },
  add: {
    id: "selfServeBookings.offeringsCard.add",
    defaultMessage: "Add service",
    description: "Title of the Add Service Button",
  },
  header: {
    id: "selfServeBookings.offeringsCard.header",
    defaultMessage: "Online Booking",
    description: "Title of the Online Booking settings page",
  },
  intro: {
    id: "selfServeBookings.offeringsCard.intro",
    defaultMessage:
      "Offer your customers the ultimate convenience by letting them book a service with you, directly into your calendar. New jobs will keep rolling in, while you remain in total control of what services and availability you offer online.",
    description: "Intro to the Online Booking settings page",
  },
});
