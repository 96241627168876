import React from "react";
import type { IntlFormatters } from "react-intl";
import { Carousel } from "components/Carousel";
import { CarouselCard } from "./components";
import { messages } from "./messages";
import {
  type FeatureCarouselPlanEnum,
  FeatureCarouselPlans,
} from "./featureCarouselPlans";

export interface FeatureCarouselProps {
  showArrowIndicators?: boolean;
  formatMessage: IntlFormatters["formatMessage"];
  plan: FeatureCarouselPlanEnum;
}

export function FeatureCarousel({
  showArrowIndicators = true,
  formatMessage,
  plan,
}: FeatureCarouselProps) {
  const features = FeatureCarouselPlans[plan];

  return (
    <Carousel showArrowIndicators={showArrowIndicators} autoplaySeconds={6}>
      {features.map((feature, index) => (
        <CarouselCard
          key={index}
          img={{
            src: feature.imgSrc,
            alt: formatMessage(messages[feature.title]),
          }}
          title={formatMessage(messages[feature.title])}
          description={formatMessage(messages[feature.description])}
        />
      ))}
    </Carousel>
  );
}
