import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "home.googleReviewMilestoneCelebration.heading",
    defaultMessage: "Congratulations on {milestone} Google Reviews!",
    description: "Title for the Google Review Milestone Celebration",
  },
  celebrate25SubHeading: {
    id: "home.googleReviewMilestoneCelebration.subHeading25",
    defaultMessage:
      "You’ve hit a major milestone for your business. Each review is a reflection of the excellent service you provide your customers. Keep up the good work!",
    description: "Subheading for the 25 Google Review Milestone Celebration",
  },
  celebrate50SubHeading: {
    id: "home.googleReviewMilestoneCelebration.subHeading50",
    defaultMessage:
      "This milestone is a reflection of your hard work and dedication when providing outstanding services to your customers. Keep up the incredible work!",
    description: "Subheading for the 50 Google Review Milestone Celebration",
  },
  celebrate100SubHeading: {
    id: "home.googleReviewMilestoneCelebration.subHeading100",
    defaultMessage:
      "Surpassing this review mark is an incredible achievement! Your commitment to excellence has set you apart. Here’s to another 100 glowing reviews!",
    description: "Subheading for the 100 Google Review Milestone Celebration",
  },
  celebrate200SubHeading: {
    id: "home.googleReviewMilestoneCelebration.subHeading200",
    defaultMessage:
      "Wow! You’ve set the standard in your industry through hard work, dedication, and reviews that speak for themselves. Here’s to your business’s continued success!",
    description: "Subheading for the 200 Google Review Milestone Celebration",
  },
  button: {
    id: "home.googleReviewMilestoneCelebration.button",
    defaultMessage: "Check Out Your Reviews",
    description: "Button text for the Google Review Milestone Celebration",
  },
  imageAltText: {
    id: "home.googleReviewMilestoneCelebration.imageAltText",
    defaultMessage: "Image for {milestone} Google reviews celebration",
    description: "Image alt text for the Google Review Milestone Celebration",
  },
});
