import React, { useEffect, useState } from "react";
import { useBooleanToggle } from "jobber/payments_react/hooks/useBooleanToggle/useBooleanToggle";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import {
  BaseCollectPaymentDialog,
  type CollectPaymentDialogProps,
} from "./BaseCollectPaymentDialog";
import type { GroupedPaymentTypes } from "./PaymentMethodSelector";

type JobberOnlinePaymentDialogProps = CollectPaymentDialogProps;

function JobberOnlinePaymentDialog(props: JobberOnlinePaymentDialogProps) {
  const [isDialogOpen, openDialog, closeDialog] = useBooleanToggle(
    props.isDialogOpen || props.isSuperSave,
  );
  useWindowEventDialogOpen(openDialog);

  const [paymentTypes, setPaymentTypes] = useState<GroupedPaymentTypes>(
    props.groupedPaymentTypes,
  );

  // This is to optionally add the Bank Payment option to the Jobber Payments group without having to change the backend
  useEffect(() => {
    if (props.achAllowed) {
      const newPaymentTypes: GroupedPaymentTypes =
        props.groupedPaymentTypes.map(group => {
          const [groupName, types] = group;
          const isGroupJobberPayments = groupName === "Jobber Payments";
          const isBankPaymentIncluded = types.some(
            ([name]) => name === "Bank Payment",
          );

          return isGroupJobberPayments && !isBankPaymentIncluded
            ? [groupName, [...types, ["Bank Payment", 777]]]
            : group;
        });

      setPaymentTypes(newPaymentTypes);
    }
  }, [props.groupedPaymentTypes, props.achAllowed]);

  return (
    <>
      <BaseCollectPaymentDialog
        {...props}
        groupedPaymentTypes={paymentTypes}
        isDialogOpen={isDialogOpen}
        onDialogClose={closeDialog}
      />
    </>
  );
}

const DialogWithContexts = withRailsPropsAsContexts()(
  JobberOnlinePaymentDialog,
);
export { DialogWithContexts as JobberOnlinePaymentDialog };

function useWindowEventDialogOpen(openDialog: () => void) {
  const eventName = "jobber.payment_dialog.open";

  useEffect(() => {
    window.addEventListener(eventName, listener);

    return () => window.removeEventListener(eventName, listener);

    function listener(/* event: CustomEvent */) {
      // event.detail will have the value passed in from Rails via 'action[:eventOnClick][:detail]'
      openDialog();
    }
  }, [openDialog]);
}
