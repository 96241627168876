export const REPORT_HEADER = "Quote Summary";
export const REPORT_ERROR_MESSAGE = "Something went wrong!";
export const NAME_COLUMN_HEADER = "Franchisee";
export const NAME_COLUMN_FOOTER = "Calculations";
export const SENT_COUNT_HEADER = "Sent Quotes";
export const SENT_TOTAL_HEADER = "Sent Quotes Total ($)";
export const SENT_AVERAGE_HEADER = "Sent Quotes Average ($)";
export const CONVERTED_COUNT_HEADER = "Converted Quotes";
export const CONVERTED_TOTAL_HEADER = "Converted Quotes Total ($)";
export const CONVERTED_AVERAGE_HEADER = "Converted Quotes Average ($)";
export const CONVERSION_RATE_HEADER = "Conversion Rate (%)";
export const PAGE_NUMBER_OPTIONS = [10, 25, 50];
