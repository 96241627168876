import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "billing.subscriptionAddons.title",
    defaultMessage: "Add-ons",
    description: "Page title",
  },
  recommended: {
    id: "billing.subscriptionAddons.recommended",
    defaultMessage: "Recommended",
    description: "Recommended add-ons section title",
  },
  moreAddons: {
    id: "billing.subscriptionAddons.moreAddons",
    defaultMessage: "More add-ons",
    description: "More add-ons section title",
  },
  unableToPurchaseMessage: {
    id: "billing.subscriptionAddons.unableToPurchaseMessage",
    defaultMessage: "Add-on subscriptions are not available for this account",
    description: "Message shown when add-on subscriptions are not available",
  },
});
