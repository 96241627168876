import { defineMessages } from "react-intl";

export const messages = defineMessages({
  personalizeTitle: {
    id: "setupGuide.personalize.personalizeTitle",
    defaultMessage: "Automatic messages",
    description: "Title for the personalize step",
  },
  sendTest: {
    id: "setupGuide.personalize.sendTest",
    defaultMessage: "Send Test",
    description: "Description for the send test button",
  },
  plusButtonLabel: {
    id: "setupGuide.personalize.plusButtonLabel",
    defaultMessage: "plus-button",
    description: "aria-label for the plus button",
  },
  infoInputSubtext: {
    id: "setupGuide.personalize.infoInputSubtext",
    defaultMessage: "Some text in this message cannot be changed",
    description: "Subtext to inform users of text that cannot be changed",
  },
  smsLengthText: {
    id: "setupGuide.personalize.smsLengthText",
    defaultMessage: "133 / 160 • SMS Sent: 1",
    description: "Description of the SMS length",
  },
  personalizeDescription: {
    id: "setupGuide.personalize.personalizeDescription",
    defaultMessage:
      "After clients pay their invoice, they are automatically asked to leave a review. If the client does not use your review link, two follow-ups are then sent through your choice of text or email!",
    description: "Description for the personalize step",
  },
  personalizeDescriptionSubtle: {
    id: "setupGuide.personalize.personalizeDescriptionSubtle",
    defaultMessage:
      "To increase reviews, job follow-ups will be automatically turned off so that you only ask for your client's feedback once. You can turn this back on at any time.",
    description: "Subtle description for the personalize step",
  },
  jobfollowupsError: {
    id: "reviews.profile.jobfollowupsError",
    defaultMessage:
      "Failed to turn off job follow-ups. Please turn them off [in your settings](/communication_settings#jobs)",
    description:
      "A general warning that something has gone wrong while disabling job follow-ups",
  },
});
