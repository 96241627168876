import * as React from "react";
import { DrawSignaturePad } from "./DrawSignaturePad";
import type { CommonSignaturePadProps } from "./SignaturePadTypes";
import { TypeSignaturePad } from "./TypeSignaturePad";

export enum SignaturePadType {
  DRAW = "draw",
  TYPE = "type",
}

interface ResolveComponentParams {
  inputType: SignaturePadType;

  DrawSignatureComponent: React.ComponentType<CommonSignaturePadProps>;
  TypeSignatureComponent: React.ComponentType<CommonSignaturePadProps>;
}

export type SignaturePadProps = CommonSignaturePadProps &
  Partial<ResolveComponentParams>;

function resolveComponent(params: ResolveComponentParams) {
  const { inputType, DrawSignatureComponent, TypeSignatureComponent } = params;
  switch (inputType) {
    case SignaturePadType.DRAW:
      return DrawSignatureComponent;
    case SignaturePadType.TYPE:
      return TypeSignatureComponent;
    default:
      throw new Error(
        `expected 'draw' or 'type' as inputType, got ${inputType}`,
      );
  }
}

export function SignaturePad(props: SignaturePadProps) {
  const {
    inputType = SignaturePadType.DRAW,
    DrawSignatureComponent = DrawSignaturePad,
    TypeSignatureComponent = TypeSignaturePad,
    ...rest
  } = props;

  const Component = React.useMemo(
    () =>
      resolveComponent({
        inputType,
        DrawSignatureComponent,
        TypeSignatureComponent,
      }),
    [inputType, DrawSignatureComponent, TypeSignatureComponent],
  );

  return (
    <>
      <Component {...rest} />
    </>
  );
}
