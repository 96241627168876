import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components/Banner";
import { Link } from "@jobber/components/Link";
import { Markdown } from "@jobber/components/Markdown";
import { useIntl } from "react-intl";
import { LearnMoreButtons } from "jobber/marketplace/components/LearnMoreButtons";
import { ActionButtons } from "jobber/marketplace/components/ActionButtons";
import { useIntegrationStateContext } from "jobber/marketplace/views/IntegrationPage/hooks/useIntegrationState";
import { AppStateLabel } from "jobber/marketplace/components/AppStateLabel";
import { ApplicationStateTypeEnum } from "~/utilities/API/graphqlAnchor";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import styles from "./IntegrationPageHeader.module.css";
import { messages } from "../../../../messages";

export function IntegrationPageHeader() {
  const {
    id,
    title,
    description,
    developer,
    isConnected,
    oauthUrl,
    manageAppUrl,
    learnMoreUrl,
    logoIconUrl,
    requiredPlan,
    appState,
    publicFacing = false,
    restrictedCountries,
    recentlyPublished,
    offer,
  } = useIntegrationStateContext();
  const { formatMessage } = useIntl();
  const { currentUser } = useCurrentUser(["isAdmin"]);
  const [isAppConnected, setIsAppConnected] = useState(isConnected);

  const publishedBeta = appState === ApplicationStateTypeEnum.PUBLISHED_BETA;
  const marketplaceLink = publicFacing ? "/app_marketplace" : "/marketplace";

  const { changePlanModalLearnMore, changePlanModalRequiredPlan } = messages;

  const { learnMoreText, requiredPlanText } = {
    requiredPlanText: formatMessage(changePlanModalRequiredPlan, {
      requiredPlan,
    }),
    learnMoreText: formatMessage(changePlanModalLearnMore),
  };

  return (
    <div className={styles.header}>
      <div className={styles.innerHeader}>
        <Content spacing="large">
          <Text>
            <a href={marketplaceLink}>
              <Icon name="arrowLeft" color="green" /> App Marketplace
            </a>
          </Text>
          <div className={styles.twoThirdsGrid}>
            <div className={styles.twoColumnGrid}>
              <div className={styles.logoImage}>
                <img alt={`${title}-logo`} src={logoIconUrl} />
              </div>
              <Content spacing="small">
                <AppStateLabel
                  appState={offer && !isConnected ? "OFFER" : appState}
                  recentlyPublished={recentlyPublished}
                />
                <Heading level={1}>{title}</Heading>
                {description.split("\n").map((paragraph, index) => (
                  <Text size="large" key={index}>
                    {paragraph}
                  </Text>
                ))}
                {learnMoreUrl ? (
                  <LearnMoreButtons
                    learnMoreUrl={learnMoreUrl}
                    id={id}
                    title={title}
                  />
                ) : (
                  <ActionButtons
                    id={id}
                    title={title}
                    isConnected={isAppConnected}
                    setIsAppConnected={setIsAppConnected}
                    requiredPlan={requiredPlan}
                    oauthUrl={oauthUrl}
                    manageAppUrl={manageAppUrl}
                    publicFacing={publicFacing}
                  />
                )}
              </Content>
            </div>
            <Content spacing="large">
              {developer && (
                <div>
                  <Text variation="subdued">Developer</Text>
                  <Text>{developer}</Text>
                </div>
              )}
              {publicFacing && (
                <div>
                  <Text variation="subdued">Available in</Text>
                  {restrictedCountries && restrictedCountries.length > 0 ? (
                    <Text>{restrictedCountries.join(", ")}</Text>
                  ) : (
                    <Text>All countries</Text>
                  )}
                </div>
              )}
              {requiredPlan &&
                !learnMoreUrl &&
                (publicFacing || currentUser.isAdmin) && (
                  <div>
                    <Text variation="subdued">Plan requirements</Text>
                    <Text>
                      {requiredPlanText}{" "}
                      <Link url="https://getjobber.com/pricing/" external>
                        {learnMoreText}
                      </Link>
                    </Text>
                  </div>
                )}
              {publishedBeta && (
                <Banner dismissible={false} type="notice">
                  <div className={styles.disclaimerContent}>
                    <div>
                      <Text>
                        {
                          "Beta apps are available for early access to assist with Jobber's testing process. Some bugs or usability issues may occur."
                        }
                      </Text>
                    </div>
                    <div>
                      <Button
                        size="small"
                        type="tertiary"
                        variation="learning"
                        label="Learn More"
                        onClick={() => {
                          window.open(
                            "https://getjobber.com/terms-of-service/#beta-services",
                            "_blank",
                          );
                        }}
                      />
                    </div>
                  </div>
                </Banner>
              )}
              {/* Given the current implementation of these Banners, we probably should have a future task to make it more generic */}
              {offer && !isConnected && (
                <Banner dismissible={false} type="notice">
                  <div className={styles.disclaimerContent}>
                    <div>
                      <Markdown content={offer.details} />
                    </div>
                    <div>
                      <Button
                        size="small"
                        type="tertiary"
                        variation="learning"
                        label="Get Exclusive Offer"
                        onClick={() => {
                          window.open(offer.url, "_blank");
                        }}
                      />
                    </div>
                  </div>
                </Banner>
              )}
            </Content>
          </div>
        </Content>
      </div>
    </div>
  );
}
