import React from "react";
import { Banner } from "@jobber/components/Banner";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import { useCta } from "~/utilities/contexts/internal/useCtas";
import type { CtaName } from "~/utilities/contexts/internal/CallToActionContext";

const ctaName: CtaName = "email_reply_to_cta";

interface EmailReplyToBannerProps {
  showEmailReplyToBanner?: boolean;
}

/* Email Reply to notification Banner Component */
function EmailReplyToBanner({
  showEmailReplyToBanner,
}: EmailReplyToBannerProps) {
  const [showCTA, dismissCTA] = useCta(ctaName);
  return (
    <>
      {showEmailReplyToBanner && showCTA && (
        <Banner type="notice" dismissible={true} onDismiss={dismissCTA}>
          {
            "Direct email replies and updates from your client to where they need to go! Open the menu below and select “Change Reply-to Email”."
          }
        </Banner>
      )}
    </>
  );
}

const Enhanced = withRailsPropsAsContexts()(EmailReplyToBanner);
export { Enhanced as EmailReplyToBanner };
