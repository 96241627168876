import { SplitNames, useSplit } from "utilities/split";

export function useQuoteDepositShownOnJobSplit(): {
  hasQuoteDepositShownOnJob: boolean;
} {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.QuoteDepositShownOnJob],
  });

  return {
    hasQuoteDepositShownOnJob: getTreatmentValue(
      SplitNames.QuoteDepositShownOnJob,
    ),
  };
}
