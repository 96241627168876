import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { SetupGuideEditMutation } from "~/utilities/API/graphql";
import { SETUP_GUIDE_EDIT_MUTATION } from "../SetupGuide.graphql";

export interface SetupGuideEditInput {
  optOut?: boolean;
  completed?: boolean;
  firstLoad?: boolean;
}

export function useSetupGuideEdit() {
  const [setupGuideEdit, { loading, error }] =
    useMutation<SetupGuideEditMutation>(SETUP_GUIDE_EDIT_MUTATION);

  async function handleSetupGuideEdit(input: SetupGuideEditInput) {
    try {
      const result = await setupGuideEdit({
        variables: {
          input,
        },
      });

      const errors = result.data?.setupGuideEdit.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: "Error",
          variation: "error",
        });
      }

      return result.data?.setupGuideEdit.setupGuide;
    } catch (e) {
      showToast({
        message: "Error",
        variation: "error",
      });
    }
  }

  return {
    handleSetupGuideEdit,
    loading,
    error,
  };
}
