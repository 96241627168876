import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import React from "react";
import { Text } from "@jobber/components/Text";
import { InputValidation } from "@jobber/components/InputValidation";
import { SOLE_PROPRIETORSHIP } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationBusinessInformation/RegistrationBusinessInformationData";
import {
  hasTaxIdRegistrationError,
  isEinValid,
} from "jobber/settings/dedicatedPhoneNumber/utilities/businessRegistrationNumberHelpers";
import type { TwilioRegistrationFormReducerState } from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
// eslint-disable-next-line import/no-internal-modules
import styles from "../../twilioRegistrationForm/TwilioRegistrationForm.module.css";

interface EmployerIdentificationNumberFormProps {
  state: TwilioRegistrationFormReducerState;
  errorMessages: {
    required: string;
    correctLength: string;
  };
  handleUpdate(data: Partial<TwilioRegistrationFormReducerState>): void;
}

export function BusinessRegistrationNumberForm({
  state,
  handleUpdate,
  errorMessages,
}: EmployerIdentificationNumberFormProps) {
  const isTaxIdErrorPresent = hasTaxIdRegistrationError(state.userErrors);
  const isSoleProprietor = state.businessType === SOLE_PROPRIETORSHIP;

  const isBusinessRegistrationNumberEmpty = !(
    !state.formSubmitted ||
    isSoleProprietor ||
    state.businessRegistrationNumber
  );

  const isBusinessRegistrationNumberValid =
    !isSoleProprietor && isEinValid(state.businessRegistrationNumber);

  function handleTaxIdOnChange(value: string) {
    handleUpdate({ businessRegistrationNumber: value });
  }

  return (
    (state.businessType && !isSoleProprietor && (
      <>
        <div className={styles.formHeader}>
          <Heading level={5}>Tax ID requirement</Heading>
        </div>
        <div className={styles.inputField}>
          <Text>
            To ensure reliable deliverability, registered corporations must
            provide their Tax ID (EIN). You do not have to provide this if you
            are a sole proprietor.
          </Text>
          {!isSoleProprietor && (
            <div className={styles.inputField}>
              <InputText
                placeholder="Tax ID (EIN)"
                name="business_identification_number"
                value={state.businessRegistrationNumber}
                invalid={
                  isBusinessRegistrationNumberEmpty ||
                  isTaxIdErrorPresent ||
                  (state.formSubmitted &&
                    !isSoleProprietor &&
                    !isBusinessRegistrationNumberValid)
                }
                onChange={handleTaxIdOnChange}
                validations={{
                  required: true,
                }}
              />
              {isBusinessRegistrationNumberEmpty && (
                <InputValidation message={errorMessages.required} />
              )}
              {state.formSubmitted &&
                !isBusinessRegistrationNumberEmpty &&
                !isBusinessRegistrationNumberValid && (
                  <InputValidation message={errorMessages.correctLength} />
                )}
            </div>
          )}
        </div>
      </>
    )) ||
    null
  );
}
