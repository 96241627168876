import React, { useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { useMutation } from "@apollo/client";
import { CTA_DISMISS } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
} from "~/utilities/API/graphql";
import styles from "./AddCustomFieldButton.module.css";
import type { AttachedToClassNames } from "./railsBridgeHelpers";
import {
  CustomFieldConfigurationModal,
  emptyCustomFieldConfiguration,
} from "./customFieldConfigurationModal";

type GQLCustomFieldAppliesToMapping = {
  [index in AttachedToClassNames]: CustomFieldAppliesTo;
};

const gqlCustomFieldAppliesTo: GQLCustomFieldAppliesToMapping = {
  Client: CustomFieldAppliesTo.ALL_CLIENTS,
  Invoice: CustomFieldAppliesTo.ALL_INVOICES,
  Property: CustomFieldAppliesTo.ALL_PROPERTIES,
  Quote: CustomFieldAppliesTo.ALL_QUOTES,
  WorkOrder: CustomFieldAppliesTo.ALL_JOBS,
};

export interface AddCustomFieldButtonProps {
  attachedToClassName: AttachedToClassNames;
  showTipCard: boolean;
  setShowTipCard(value: boolean): void;
  onCreateCustomFieldConfiguration(
    configuration: CustomFieldConfigurationNodeFragment,
  ): void;
}

export function AddCustomFieldButton({
  showTipCard,
  setShowTipCard,
  attachedToClassName,
  onCreateCustomFieldConfiguration,
}: AddCustomFieldButtonProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [ctaDismiss] = useMutation(CTA_DISMISS);

  return (
    <div
      data-testid="addCustomFieldButton"
      className={styles.addCustomFieldButtonWrapper}
    >
      {showTipCard && (
        <Banner
          dismissible
          type="notice"
          icon="knot"
          primaryAction={{
            label: "Add Custom Field",
            onClick: () => setModalOpen(true),
          }}
          onDismiss={captureDismiss}
        >
          {GenerateTipText()}
        </Banner>
      )}
      {!showTipCard && (
        <Button
          onClick={() => setModalOpen(true)}
          label="Add Custom Field"
          type="secondary"
          size="small"
        />
      )}
      <CustomFieldConfigurationModal
        open={modalOpen}
        existingCustomField={{
          ...emptyCustomFieldConfiguration,
          appliesTo: gqlCustomFieldAppliesTo[attachedToClassName],
        }}
        appliesToDisabled={true}
        onClose={() => setModalOpen(false)}
        onSuccess={(
          customFieldConfiguration: CustomFieldConfigurationNodeFragment,
        ) => {
          onCreateCustomFieldConfiguration(customFieldConfiguration);
          setModalOpen(false);
          setShowTipCard(false);
        }}
      />
    </div>
  );

  function GenerateTipText(): string {
    switch (attachedToClassName) {
      case "Client": {
        return "Need to keep track of anything else about your clients?";
      }
      case "Property": {
        return "Need to keep track of anything else about properties?";
      }
      case "WorkOrder": {
        return "Need to track more details on jobs?";
      }
      case "Invoice": {
        return "Need to track more details on invoices?";
      }
      case "Quote": {
        return "Need to track more details on quotes?";
      }
      default: {
        return "Need to track more details?";
      }
    }
  }

  function captureDismiss(): void {
    const dismissId = grabDismissID();
    ctaDismiss({ variables: { id: dismissId } }).catch(
      (ctaDismissError: string) => {
        throw new Error(ctaDismissError);
      },
    );

    setShowTipCard(false);
  }

  function grabDismissID(): string {
    switch (attachedToClassName) {
      case "Client": {
        return "custom_fields_client";
      }
      case "Property": {
        return "custom_fields_property";
      }
      case "WorkOrder": {
        return "custom_fields_work_order";
      }
      case "Invoice": {
        return "custom_fields_invoice";
      }
      case "Quote": {
        return "custom_fields_quote";
      }
    }
  }
}
