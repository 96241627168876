import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pauseAndEditTitle: {
    id: "campaigns.pauseAndEdit.title",
    defaultMessage: "Pause and edit?",
    description: "Pause and Edit modal title label",
  },
  pauseAndEditText: {
    id: "campaigns.pauseAndEdit.text",
    defaultMessage:
      "Editing this campaign will pause the scheduled send and bring it back to draft mode.",
    description: "Pause and Edit message label",
  },
  pauseAndEditButtonLabel: {
    id: "campaigns.pauseAndEdit.buttonLabel",
    defaultMessage: "Pause and Edit",
    description: "Confirm button label",
  },
  pauseAndEditError: {
    id: "campaigns.pauseAndEdit.error",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error message",
  },
});
