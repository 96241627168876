import { Icon } from "@jobber/components/Icon";
import React, { type MutableRefObject, useRef } from "react";
import classNames from "classnames";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import styles from "./FranchiseButton.style.module.css";

export interface FranchiseButtonProps {
  label: string;
  fromSideNav: boolean;
  onClick?(): void;
}
export function FranchiseButton({
  label,
  fromSideNav,
  onClick,
}: FranchiseButtonProps): JSX.Element {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const handlePress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      ref.current.click();
      event.preventDefault();
    }
  };
  const customColor = fromSideNav ? "--color-surface" : "";
  const alternateStyling = fromSideNav ? styles.sideNav : "";

  return (
    <div
      ref={ref}
      className={classNames(styles.button, alternateStyling)}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handlePress}
      role={"button"}
    >
      <Typography element="span" fontWeight="extraBold" size={"small"}>
        {label}
      </Typography>
      <Icon name={"arrowDown"} customColor={customColor} />
    </div>
  );
}
