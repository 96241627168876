import React from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import { Spinner } from "@jobber/components/Spinner";
import { Heading } from "@jobber/components/Heading";
import { useAssignTeam } from "jobber/workOrders/components/AssignTeam";

export interface TeamMember {
  id: string;
  name: string;
}

interface SelectTeamMemberProps {
  onSelectTeamMembers: (assignees: Map<TeamMember["id"], boolean>) => void;
  selectedTeamMembers: Map<TeamMember["id"], boolean>;
}

export const SelectTeamMember = ({
  onSelectTeamMembers,
  selectedTeamMembers,
}: SelectTeamMemberProps) => {
  const { loading, assignableUsers } = useAssignTeam();

  if (!loading && assignableUsers.length === 1) {
    return <></>;
  }

  const content = loading ? (
    <Spinner size="small" inline />
  ) : (
    <SelectTeamMemberList
      onSelectTeamMembers={onSelectTeamMembers}
      selectedTeamMembers={selectedTeamMembers}
    />
  );

  return (
    <>
      <div>
        <Heading level={4}>Team members</Heading>
      </div>
      <div data-testid="select-team-members">{content}</div>
    </>
  );
};

interface SelectTeamMemberListProps {
  onSelectTeamMembers: (assignees: Map<TeamMember["id"], boolean>) => void;
  selectedTeamMembers: Map<TeamMember["id"], boolean>;
}

function SelectTeamMemberList({
  onSelectTeamMembers,
  selectedTeamMembers,
}: SelectTeamMemberListProps) {
  const { assignableUsers, currentUserId } = useAssignTeam();

  const allSelected = assignableUsers.every(
    user => selectedTeamMembers.get(user.id) !== false,
  );

  const onlyOneUserIsSelected =
    assignableUsers.filter(user => selectedTeamMembers.get(user.id) !== false)
      .length === 1;

  return (
    <>
      <div>
        <Checkbox
          label={"Select all"}
          checked={allSelected}
          onChange={onSelectAll}
        />
      </div>
      {assignableUsers.map(user => (
        <div key={user.id}>
          <Checkbox
            label={user.name.full}
            checked={selectedTeamMembers.get(user.id) !== false}
            onChange={makeOnToggleTeamMember(user.id)}
            disabled={
              onlyOneUserIsSelected &&
              selectedTeamMembers.get(user.id) !== false
            }
          />
        </div>
      ))}
    </>
  );

  function onSelectAll() {
    const result = new Map(
      assignableUsers.map(user => [user.id, !allSelected]),
    );
    if (allSelected) {
      result.set(currentUserId, true);
    }
    onSelectTeamMembers(result);
  }

  function makeOnToggleTeamMember(userId: TeamMember["id"]) {
    return function onToggleTeamMember() {
      const copyOf = new Map(selectedTeamMembers);
      // Explicitly compare to false because undefined means selected by default
      copyOf.set(userId, copyOf.get(userId) === false ? true : false);

      onSelectTeamMembers(copyOf);
    };
  }
}
