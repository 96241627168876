import React from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { InputNumber } from "@jobber/components/InputNumber";
import { InputValidation } from "@jobber/components/InputValidation";
import type {
  IntervalUnits,
  PastClientsCriteriaInput,
} from "~/utilities/API/graphql";
import type { IntlSelectProps } from "jobber/campaigns/views/SelectClientSegmentPage/components/IntlSelect/IntlSelect";
import { IntlSelect } from "jobber/campaigns/views/SelectClientSegmentPage/components/IntlSelect/IntlSelect";
import { messages } from "./messages";
import styles from "./BaseCriteria.module.css";

export interface BaseCriteriaProps {
  setInterval(x: number): void;
  setUnit(x: IntervalUnits): void;
  setValidationMessage(x: string): void;
  validateInterval(x: number): string | true;
  segmentCriteria: PastClientsCriteriaInput;
  validationMessage: string;
  preFieldsDescription?: string;
  postFieldsDescription?: string;
  intervalUnitOptions: IntlSelectProps["options"];
}
export function BaseCriteria({
  preFieldsDescription,
  postFieldsDescription,
  setInterval,
  setUnit,
  setValidationMessage,
  validateInterval,
  segmentCriteria,
  validationMessage,
  intervalUnitOptions,
}: BaseCriteriaProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Content spacing="small">
      <Heading level={4}>
        {formatMessage(messages.baseCriteriaSubheader)}
      </Heading>

      {preFieldsDescription && <Text>{preFieldsDescription}</Text>}
      <TimeIntervalInputs
        segmentCriteria={segmentCriteria}
        setValidationMessage={setValidationMessage}
        validateInterval={validateInterval}
        setInterval={setInterval}
        setUnit={setUnit}
        intervalUnitOptions={intervalUnitOptions}
      />
      {validationMessage && <InputValidation message={validationMessage} />}
      {postFieldsDescription && <Text>{postFieldsDescription}</Text>}
    </Content>
  );
}

type TimeIntervalInputsProps = Pick<
  BaseCriteriaProps,
  | "segmentCriteria"
  | "validateInterval"
  | "setValidationMessage"
  | "setInterval"
  | "setUnit"
  | "intervalUnitOptions"
>;
function TimeIntervalInputs({
  segmentCriteria,
  setValidationMessage,
  validateInterval,
  setInterval,
  setUnit,
  intervalUnitOptions,
}: TimeIntervalInputsProps) {
  return (
    <div className={styles.baseCriteriaWrapper}>
      <div className={styles.pastClientsInput}>
        <InputNumber
          name="interval"
          keyboard="numeric"
          inline={true}
          maxLength={3}
          defaultValue={`${segmentCriteria.interval}`}
          validations={{
            validate: validateInterval,
          }}
          onChange={setInterval}
          onValidation={setValidationMessage}
        />
      </div>

      <IntlSelect
        options={intervalUnitOptions}
        defaultValue={segmentCriteria.unit}
        onChange={setUnit}
      />
    </div>
  );
}
