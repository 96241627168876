import React from "react";
import classNames from "classnames";
import styles from "./JobProfitStatusLabel.module.css";

export interface JobProfitStatusLabelProps {
  status?: string;
}

export function JobProfitStatusLabel({ status }: JobProfitStatusLabelProps) {
  if (!status) return <></>;
  return (
    <div
      className={classNames([styles.label, styles[status.toLowerCase()]])}
      data-testid={status.toLowerCase()}
    />
  );
}
