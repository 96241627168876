import type React from "react";
import type { InputTextRef } from "@jobber/components/InputText";
import type { RichTextContentEditorRef } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor";

export enum AvailableEditors {
  subject = "subject",
  body = "body",
  header = "header",
  linkValue = "linkValue",
  buttonText = "buttonText",
}

export interface CampaignContentRefs {
  [AvailableEditors.subject]: React.RefObject<InputTextRef>;
  [AvailableEditors.header]: React.RefObject<InputTextRef>;
  [AvailableEditors.body]: React.RefObject<RichTextContentEditorRef>;
  [AvailableEditors.linkValue]: React.RefObject<InputTextRef>;
  [AvailableEditors.buttonText]: React.RefObject<InputTextRef>;
}
