import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bankAccount: {
    id: "addpaymentmethod.bankAccount",
    defaultMessage: "Bank account",
    description: "Bank account payment method type",
  },
  creditCard: {
    id: "addpaymentmethod.creditCard",
    defaultMessage: "Credit card",
    description: "Credit card payment method type",
  },
  errorBankAccount: {
    id: "addpaymentmethod.errorBankAccount",
    defaultMessage: "Error adding bank account. Please try again later.",
    description: "Error adding bank account message",
  },
  incompleteCardFields: {
    id: "addpaymentmethod.incompleteCardFields",
    defaultMessage: "Card fields are incomplete",
    description: "Incomplete card fields",
  },
  addPaymentMethodTitle: {
    id: "addpaymentmethod.addPaymentMethodTitle",
    defaultMessage: "Add payment method",
    description: "Add payment method title",
  },
  chipPaymentMethodName: {
    id: "addpaymentmethod.chipPaymentMethodName",
    defaultMessage: "Payment Method",
    description: "Chip Payment Method name",
  },
  saveButton: {
    id: "addpaymentmethod.saveButton",
    defaultMessage: "Save",
    description: "Save button",
  },
  cancelButton: {
    id: "addpaymentmethod.cancelButton",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  stripeNotInitialized: {
    id: "addpaymentmethod.stripeNotInitialized",
    defaultMessage: "Stripe is not initialized",
    description: "Stripe is not initialized",
  },
  errorAddingCreditCard: {
    id: "addpaymentmethod.errorAddingCreditCard",
    defaultMessage: "Error adding credit card. Please try again later.",
    description: "Error adding credit card",
  },
  errorAddingBankAccount: {
    id: "addpaymentmethod.errorAddingBankAccount",
    defaultMessage: "Error adding bank account. Please try again later.",
    description: "Error adding bank account",
  },
  errorNameOnCardAndBillingAddress: {
    id: "addpaymentmethod.errorNameOnCardAndBillingAddress",
    defaultMessage: "Name on card and billing address are required",
    description: "Error name on card and billing address",
  },
  successAddedCreditCard: {
    id: "addpaymentmethod.successAddedCreditCard",
    defaultMessage: "Successfully added credit card",
    description: "Success for added credit card",
  },
  successAddedBankAccount: {
    id: "addpaymentmethod.successAddedBankAccount",
    defaultMessage: "Successfully added bank account",
    description: "Success for added bank account",
  },
  incompleteBankFields: {
    id: "addpaymentmethod.incompleteBankFields",
    defaultMessage: "Bank fields are incomplete",
    description: "Incomplete bank fields",
  },
});
