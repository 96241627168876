import {
  CustomFieldAppliesTo,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";
import {
  type CustomFieldConfigurationReducerState,
  invalidTransferableObjects,
} from "./types";

export const initialCustomFieldConfiguration: CustomFieldConfigurationReducerState =
  {
    appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
    name: "",
    fieldType: CustomFieldConfigurationValueType.TEXT,
    defaultValue: "",
    areaLength: 0,
    areaWidth: 0,
    unit: "",
    dropdownOptions: ["", ""],
    transferable: false,
    readOnly: false,
  };

interface UpdateAppliesTo {
  type: "UPDATE_APPLIES_TO";
  appliesTo: CustomFieldAppliesTo;
}

interface UpdateName {
  type: "UPDATE_NAME";
  name: string;
}

interface UpdateType {
  type: "UPDATE_TYPE";
  fieldType: CustomFieldConfigurationValueType;
}

interface UpdateTransferable {
  type: "UPDATE_TRANSFERABLE";
  transferable: boolean;
}

interface UpdateDefaultValue {
  type: "UPDATE_DEFAULT_VALUE";
  defaultValue: string;
}

interface UpdateUnit {
  type: "UPDATE_UNIT";
  unit: string;
}

interface UpdateAreaLength {
  type: "UPDATE_AREA_LENGTH";
  length: number;
}

interface UpdateAreaWidth {
  type: "UPDATE_AREA_WIDTH";
  width: number;
}

interface UpdateDropDownOptions {
  type: "UPDATE_DROPDOWN_OPTIONS";
  options: string[];
}

interface Reset {
  type: "RESET";
  value: CustomFieldConfigurationReducerState;
}

export type ConfigurationActions =
  | UpdateAppliesTo
  | UpdateName
  | UpdateType
  | UpdateAreaLength
  | UpdateAreaWidth
  | UpdateDropDownOptions
  | UpdateTransferable
  | UpdateUnit
  | UpdateDefaultValue
  | Reset;

export function customFieldConfigurationReducer(
  state: CustomFieldConfigurationReducerState,
  action: ConfigurationActions,
) {
  switch (action.type) {
    case "UPDATE_APPLIES_TO":
      return {
        ...state,
        appliesTo: action.appliesTo,
        // transferable can't be true for invoices or team members
        ...(invalidTransferableObjects.includes(action.appliesTo) && {
          transferable: false,
        }),
      };
    case "UPDATE_TRANSFERABLE":
      return {
        ...state,
        transferable: action.transferable,
        // transferable can't be true for invoices or team members
        ...(invalidTransferableObjects.includes(state.appliesTo) && {
          transferable: false,
        }),
      };
    case "UPDATE_NAME":
      return { ...state, name: action.name };
    case "UPDATE_TYPE":
      return {
        ...state,
        fieldType: action.fieldType,
        defaultValue:
          action.fieldType === CustomFieldConfigurationValueType.TRUE_FALSE
            ? "true"
            : "",
        dropdownOptions: ["", ""],
        areaLength: 0,
        areaWidth: 0,
        unit: "",
      };
    case "UPDATE_DEFAULT_VALUE":
      return {
        ...state,
        defaultValue: action.defaultValue,
      };
    case "UPDATE_UNIT":
      return {
        ...state,
        unit: action.unit,
      };
    case "UPDATE_AREA_LENGTH":
      return {
        ...state,
        areaLength: action.length,
      };
    case "UPDATE_AREA_WIDTH":
      return {
        ...state,
        areaWidth: action.width,
      };
    case "UPDATE_DROPDOWN_OPTIONS":
      return {
        ...state,
        dropdownOptions: action.options,
      };
    case "RESET":
      return action.value;
  }
}
