/**
 * Joins two or more parts of a path or route:
 *
 * ```ts
 * const { path } = useRouteMatch();
 * join(path, "users", ":name"); // → '/users/:name'
 * join(path, "shut", "the", "front", "..", "door"); // → '/shut/the/door'
 * ```
 */
export function join(...path: string[]) {
  return path
    .reduce((pathArray, current) => {
      if (current === "..") {
        pathArray.pop();
        return pathArray;
      }

      return [...pathArray, current];
    }, [])
    .join("/")
    .replace(/\/+/g, "/");
}
