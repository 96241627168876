import { Button } from "@jobber/components/Button";
import React from "react";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./LearnMoreButtons.module.css";

interface LearnMoreButtonsProps {
  /** The url to go to */
  learnMoreUrl: string;
  id: string;
  title: string;
}

export function LearnMoreButtons({
  learnMoreUrl,
  id,
  title,
}: LearnMoreButtonsProps) {
  return (
    <div className={styles.learnMoreButtons}>
      <Button
        label="Learn More"
        type="primary"
        size="large"
        onClick={clickedLearnMoreButton}
        url={learnMoreUrl}
        external={true}
      />
    </div>
  );

  function clickedLearnMoreButton() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedLearnMore, {
      appId: id,
      name: title,
      source: ANALYTICS_PROPERTIES.marketplacePageSource,
    });
  }
}
