import React from "react";
import { Spinner } from "@jobber/components/Spinner";
import { useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { IntlProvider } from "@translations/IntlProvider";
import type {
  CustomFieldConfigurationNodeFragment,
  CustomFieldPageListQuery,
  CustomFieldPageListQueryVariables,
} from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { CUSTOM_FIELD_LIST } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";
import { CustomFieldsPageInternal } from "jobber/customFields/CustomFieldsPage/CustomFieldsPageInternal";

interface CustomFieldsPageProps {
  adminSuperpowers: boolean;
}

export function CustomFieldsPage({
  adminSuperpowers = false,
}: CustomFieldsPageProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <CustomFieldPageLoader adminSuperpowers={adminSuperpowers} />
      </IntlProvider>
    </APIProvider>
  );
}

export function CustomFieldPageLoader({
  adminSuperpowers,
}: CustomFieldsPageProps) {
  const { loading, error, data, refetch } = useQuery<
    CustomFieldPageListQuery,
    CustomFieldPageListQueryVariables
  >(CUSTOM_FIELD_LIST);

  if (error) {
    return (
      <Banner type="error" dismissible={false}>
        An error occurred when loading the page. Please reload and try again
      </Banner>
    );
  } else if (!data || loading) {
    return <Spinner />;
  }

  const customFieldConfigurations: CustomFieldConfigurationNodeFragment[] =
    data.customFieldConfigurations.nodes;

  return (
    <CustomFieldsPageInternal
      customFieldConfigurations={customFieldConfigurations}
      refetch={refetch}
      adminSuperpowers={adminSuperpowers}
    />
  );
}
