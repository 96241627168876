/* eslint-disable max-statements */
import { gql, useMutation } from "@apollo/client";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import React, { useEffect, useRef, useState } from "react";
import type {
  JobberPaymentsSettingsName,
  UpdateJobberPaymentsSettingsMutation,
} from "~/utilities/API/graphql";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";

const genericError = new Error("Could not update settings");
export const UPDATE_SETTING_MUTATION = gql`
  mutation UpdateJobberPaymentsSettings(
    $name: JobberPaymentsSettingsName!
    $enabled: Boolean!
  ) {
    updateJobberPaymentsSettings(name: $name, enabled: $enabled) {
      enabled
    }
  }
`;

interface SettingsSwitchProps {
  settingsName: JobberPaymentsSettingsName;
  title: string;
  description?: string;
  externalLink?: boolean;
  defaultEnabled?: boolean;
  onError?(error: Error): void;
  onSuccessCallback?(): void;
  onSuccessCallbackIsEnabled?(enabled: boolean): void;
  toggleSwitch?: boolean;
}

export function SettingsSwitch(props: SettingsSwitchProps) {
  const {
    title,
    description,
    externalLink,
    settingsName,
    onSuccessCallback,
    onSuccessCallbackIsEnabled,
    onError = () => undefined,
    defaultEnabled: defaultToggleEnabled = false,
    toggleSwitch,
  } = props;
  const { permissions, enabled: globalEnabled } = useJobberPayments();
  const [toggleEnabled, setToggleEnabled] = useState(defaultToggleEnabled);
  const isSavingRef = useRef(false);
  const [updateSettings] = useMutation<UpdateJobberPaymentsSettingsMutation>(
    UPDATE_SETTING_MUTATION,
  );
  const isFirstRender = useRef(true);

  const disabled = !permissions.canWriteSettings || !globalEnabled;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setToggleEnabled(currentToggle => !currentToggle);
  }, [toggleSwitch]);

  return (
    <FeatureSwitch
      enabled={toggleEnabled}
      onSwitch={onSwitch}
      hasSaveIndicator={true}
      title={title}
      externalLink={externalLink}
      description={description}
      disabled={disabled}
    />
  );

  async function onSwitch(targetEnabled: boolean) {
    if (isSavingRef.current || disabled) return;

    isSavingRef.current = true;

    try {
      const { data, errors } = await updateSettings({
        variables: {
          name: settingsName,
          enabled: targetEnabled,
        },
      });

      if (errors) {
        onError((errors[0] as Error) || genericError);
      }

      if (!data) {
        onError(genericError);
      } else {
        setToggleEnabled(data.updateJobberPaymentsSettings.enabled);
        onSuccessCallback?.();
        onSuccessCallbackIsEnabled?.(data.updateJobberPaymentsSettings.enabled);
      }
    } catch (error) {
      onError(error as Error);
    }

    // ref keeps the same reference across component updates
    // eslint-disable-next-line require-atomic-updates
    isSavingRef.current = false;
  }
}
