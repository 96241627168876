export const bannersWithCTADismissal = [
  "SUPPLEMENTAL_TERMS_OF_SERVICE",
  "LITE_TO_CORE_FEATURE_TRIAL_EXPIRED",
  "OTP_NUDGE",
  "COMMS_CARRIER_REGISTRATION_FAILED",
  "COMMS_CARRIER_REGISTRATION_LAGGING",
  "COMMS_CARRIER_REGISTRATION_WAITING",
];

export const bannersWithPOSTRequest: string[] = [];
