import React, { useMemo } from "react";
import {
  type ColumnDef,
  DataTable,
  type ManualSorting,
  type PaginationType,
  type Row,
} from "@jobber/components/DataTable";
import type {
  JobberPaymentsDisputeEdge,
  PaymentDisputesQueryQuery,
} from "~/utilities/API/graphql";
import styles from "./DisputeTable.module.css";

export interface DisputeTableProps {
  disputesData: PaymentDisputesQueryQuery["jobberPaymentsDisputes"];
  loading?: boolean;
  columns: ColumnDef<JobberPaymentsDisputeEdge>[];
  pagination: PaginationType;
  sorting?: ManualSorting;
  onRowClick?: (row: Row<JobberPaymentsDisputeEdge>) => void;
}

export function DisputeTable({
  disputesData,
  columns,
  pagination,
  sorting,
  onRowClick,
}: DisputeTableProps) {
  const totalCount = disputesData?.totalCount;
  const loadingColumns = useMemo(
    () =>
      columns.map(column => ({
        ...column,
        cell: () => (
          <span
            className={styles.loadingSkeletonAnimation}
            data-testid="loadingCell"
          />
        ),
      })),
    [columns],
  );

  const getTableColumns = () => {
    if (disputesData?.edges && disputesData.edges.length > 0) {
      return columns;
    }

    return loadingColumns;
  };

  const loadingRowsPerPage = pagination.manualPagination
    ? pagination.state.pageSize
    : 10;

  return (
    <div className={totalCount ? styles.emptyTableWrap : styles.tableWrap}>
      <DataTable
        stickyHeader
        pagination={pagination}
        sorting={sorting}
        data={disputesData?.edges || Array(loadingRowsPerPage).fill({})}
        columns={getTableColumns()}
        onRowClick={onRowClick}
      />
    </div>
  );
}
