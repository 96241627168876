export enum ActionTypes {
  Reset,
  ChangeDeliveryMethod,
  ChangeScheduleOffsetUnit,
  ChangeScheduleOffsetValue,
  ChangeScheduleTime,
  ChangeTemplateSubject,
  ChangeTemplateMessage,
  ChangeTemplateSurvey,
  ToggleTemplateSurvey,
  ToggleSchedule,
}
