import { defineMessages } from "react-intl";

export const messages = defineMessages({
  businessPageCopy: {
    id: "setupWizard.businessPage.businessPageCopy",
    defaultMessage:
      "We use this information to suggest the right tools and workflows for your team, helping you streamline tasks and improve efficiency.",
    description: "Business page copy for the setup wizard",
  },
  businessPageDefaultHeading: {
    id: "setupWizard.businessPage.businessPageHeading",
    defaultMessage: "Your business at a glance",
    description: "Default business page heading for the setup wizard",
  },
  businessPageArboristHeading: {
    id: "setupWizard.businessPage.businessPageArboristHeading",
    defaultMessage: "Your arborist business at a glance",
    description:
      "Business page heading for the setup wizard for arborist industry",
  },
  businessPageCleaningHeading: {
    id: "setupWizard.businessPage.businessPageCleaningHeading",
    defaultMessage: "Your cleaning business at a glance",
    description:
      "Default business page heading for the setup wizard for cleaning industry",
  },
  businessPageConstructionHeading: {
    id: "setupWizard.businessPage.businessPageConstructionHeading",
    defaultMessage: "Your construction business at a glance",
    description:
      "Default business page heading for the setup wizard for construction industry",
  },
  businessPageElectricalHeading: {
    id: "setupWizard.businessPage.businessPageElectricalHeading",
    defaultMessage: "Your electrical business at a glance",
    description:
      "Default business page heading for the setup wizard for electrical industry",
  },
  businessPageHandymanHeading: {
    id: "setupWizard.businessPage.businessPageHandymanHeading",
    defaultMessage: "Your handyman business at a glance",
    description:
      "Default business page heading for the setup wizard for handyman industry",
  },
  businessPageHVACHeading: {
    id: "setupWizard.businessPage.businessPageHVACHeading",
    defaultMessage: "Your HVAC business at a glance",
    description:
      "Default business page heading for the setup wizard for HVAC industry",
  },
  businessPageLandscapingHeading: {
    id: "setupWizard.businessPage.businessPageLandscapingHeading",
    defaultMessage: "Your landscaping business at a glance",
    description:
      "Default business page heading for the setup wizard for landscaping industry",
  },
  businessPageLawnCareHeading: {
    id: "setupWizard.businessPage.businessPageLawnCareHeading",
    defaultMessage: "Your lawn care business at a glance",
    description:
      "Default business page heading for the setup wizard for lawn care industry",
  },
  businessPagePaintingHeading: {
    id: "setupWizard.businessPage.businessPagePaintingHeading",
    defaultMessage: "Your painting business at a glance",
    description:
      "Default business page heading for the setup wizard for painting industry",
  },
  businessPagePlumbingHeading: {
    id: "setupWizard.businessPage.businessPagePlumbingHeading",
    defaultMessage: "Your plumbing business at a glance",
    description:
      "Default business page heading for the setup wizard for plumbing industry",
  },
  businessPagePressureWashingHeading: {
    id: "setupWizard.businessPage.businessPagePressureWashingHeading",
    defaultMessage: "Your pressure washing business at a glance",
    description:
      "Default business page heading for the setup wizard for pressure washing industry",
  },
  businessPageWindowWashingHeading: {
    id: "setupWizard.businessPage.businessPageWindowWashingHeading",
    defaultMessage: "Your window washing business at a glance",
    description:
      "Default business page heading for the setup wizard for window washing industry",
  },
  serviceProviderAltText: {
    id: "setupWizard.businessPage.serviceProviderAltText",
    defaultMessage: "service provider",
    description: "Service provider alt text for the setup wizard",
  },
  serviceProviderUsingMobileAppAltText: {
    id: "setupWizard.businessPage.serviceProviderUsingMobileAppAltText",
    defaultMessage: "service provider using mobile app",
    description:
      "Service provider using mobile app alt text for the setup wizard",
  },
  serviceProviderUsingDrillAltText: {
    id: "setupWizard.businessPage.serviceProviderUsingDrillAltText",
    defaultMessage: "service provider using a drill",
    description: "Service provider using drill alt text for the setup wizard",
  },
});
