import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { AccordionWrapper } from "./components/Accordion/AccordionWrapper";
import styles from "./LandingPage.module.css";

interface LandingPageProps {
  viewedCtas: string[];
  completedCtas: string[];
  clientHubLink: string;
}

export function LandingPage(props: LandingPageProps): JSX.Element {
  return (
    <APIProvider>
      <IntlProvider>
        <div className={styles.pageContainer}>
          <AccordionWrapper {...props} />
        </div>
      </IntlProvider>
    </APIProvider>
  );
}
