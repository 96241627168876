import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import clipboardCopy from "clipboard-copy";
import type { ButtonProps } from "@jobber/components/Button";
import { Button } from "@jobber/components/Button";
import { messages } from "./messages";

export const DEFAULT_ANIMATION_TIMEOUT = 2000;

type CopyButtonProps = Omit<
  ButtonProps,
  "icon" | "label" | "external" | "submit" | "to" | "url"
> & {
  /**
   * The initial label of the button.
   */
  initialLabel?: ButtonProps["label"];

  /**
   * The initial icon of the button.
   */
  initialIcon?: ButtonProps["icon"];

  /**
   * The activated label of the button.
   */
  activatedLabel?: ButtonProps["label"];

  /**
   * The activated icon of the button.
   */
  activatedIcon?: ButtonProps["icon"];

  /**
   * The value to copy to the clipboard.
   */
  value: string;

  /**
   * The timeout for the activated state animation.
   */
  animationTimeout?: number;
};

export function CopyButton({
  initialLabel,
  initialIcon = "link",
  activatedLabel,
  activatedIcon = "checkmark",
  value,
  animationTimeout = DEFAULT_ANIMATION_TIMEOUT,
  onClick,
  ...buttonProps
}: CopyButtonProps) {
  const { formatMessage } = useIntl();
  const timeoutRef = useRef<number | undefined>(undefined);
  const [buttonState, setButtonState] = useState({
    icon: initialIcon,
    label: initialLabel ?? formatMessage(messages.defaultInitialLabel),
  });

  useEffect(() => {
    // Clear active timeout when component unmounts to prevent updates to
    // unmounted components.
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  const copyToClipboard = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      window.clearTimeout(timeoutRef.current);

      void clipboardCopy(value).then(() => {
        setButtonState({
          icon: activatedIcon,
          label:
            activatedLabel ?? formatMessage(messages.defaultActivatedLabel),
        });

        timeoutRef.current = window.setTimeout(() => {
          setButtonState({
            icon: initialIcon,
            label: initialLabel ?? formatMessage(messages.defaultInitialLabel),
          });
        }, animationTimeout);

        onClick?.(event);
      });
    },
    [
      activatedIcon,
      activatedLabel,
      animationTimeout,
      formatMessage,
      initialIcon,
      initialLabel,
      onClick,
      value,
    ],
  );

  return (
    // @ts-expect-error
    <Button
      {...buttonProps}
      icon={buttonState.icon}
      label={buttonState.label}
      onClick={copyToClipboard}
    />
  );
}
