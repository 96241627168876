import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { Flex } from "@jobber/components/Flex";
import { Button } from "@jobber/components/Button";
import { subDays } from "date-fns";
import { useIntl } from "react-intl";
import { Intercom } from "utilities/chat";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useProfitBarQuery } from "jobber/workOrders/components/JobCost/components/JobCostHeader/useProfitBarQuery";
import type { JobCostRecord } from "jobber/workOrders/components/JobCost/components/JobCostHeader/JobCostHeader";
import {
  getLocalStorageJSON,
  writeDataToLocalStorage,
} from "utilities/localStorage";
import type { BillingStrategy } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";
import { messages as jobCostHeaderMessages } from "jobber/workOrders/components/JobCost/components/JobCostHeader/messages";
import styles from "./RecurringJobProfitHeader.module.css";
import { messages } from "./messages";
import { ProfitMarginBreakdown } from "../ProfitMarginBreakdown";
import { ComputeJobProfitabilityButton } from "../ComputeJobProfitabilityButton";

interface RecurringJobProfitHeaderProps {
  jobCosting: JobCostRecord;
  jobId: string;
  billingType: BillingStrategy;
  lineItems?: LineItem[];
}

export function RecurringJobProfitHeader({
  jobCosting,
  jobId,
  billingType,
  lineItems,
}: RecurringJobProfitHeaderProps) {
  const { profitMargin, profitMarginBreakdown, hasJobCostingData } =
    useProfitBarQuery(jobCosting, true);
  const { timezone } = useAccount();

  const { formatMessage } = useIntl();

  const localStorageValue = getLocalStorageJSON("jobProfitBarOpen");
  const [isOpen, setIsOpen] = useState(localStorageValue ?? true);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    writeDataToLocalStorage([{ key: "jobProfitBarOpen", value: !isOpen }]);
  };

  useEffect(() => {
    Intercom.EXECUTE("trackEvent", "job_costing_viewed");
  }, []);

  const dateRange = computeDateRange(timezone);

  return (
    <div
      data-testid="jobCostHeaderContainer"
      className={styles.jobCostHeaderContainer}
    >
      <div className={styles.jobCostToggleButton}>
        <Button
          label={
            isOpen
              ? formatMessage(jobCostHeaderMessages.hideProfitabilityLabel)
              : formatMessage(jobCostHeaderMessages.showProfitabilityLabel)
          }
          type="tertiary"
          variation="subtle"
          size="small"
          iconOnRight
          icon={isOpen ? "arrowUp" : "arrowDown"}
          onClick={handleToggle}
        />
      </div>
      {isOpen && (
        <div className={styles.jobHeaderContainer}>
          {!hasJobCostingData && (
            <ComputeJobProfitabilityButton jobId={jobId} />
          )}
          <div className={styles.profitabilityHeader}>
            <Flex align="center" template={["shrink", "shrink"]} gap="smaller">
              <Icon name="calendar" size="small"></Icon>
              <h4>{formatMessage(messages.profitability)}</h4>
            </Flex>
            <Text size="small" variation="subdued">
              {dateRange}
            </Text>
          </div>
          <div className={styles.profitAndGraph}>
            <div className={styles.jobCostHeader} data-testid="jobCostHeader">
              <div
                className={classNames([
                  styles.profitMarginContainer,
                  !hasJobCostingData && styles.blurredText,
                ])}
                aria-hidden={!hasJobCostingData}
              >
                <h2
                  data-testid="jobCostHeaderProfitMargin"
                  className={classNames([
                    styles.profitMarginText,
                    typeof profitMargin === "number" &&
                      profitMargin < 0 &&
                      styles.netLossAmount,
                  ])}
                >
                  {profitMargin}%
                </h2>
                <Text size="small" variation="subdued">
                  {formatMessage(messages.profitMargin)}
                </Text>
              </div>
              {profitMarginBreakdown.map((breakdown, index) => (
                <ProfitMarginBreakdown
                  {...breakdown}
                  key={index}
                  showLabelStatus={false}
                  isRecurring={true}
                  dateRange={dateRange}
                  billingType={billingType}
                  jobCosting={jobCosting}
                  lineItems={lineItems}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const computeDateRange = (timezone: string) => {
  const endDate = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    day: "numeric",
    month: "short",
  });

  const startDate = subDays(new Date(), 29).toLocaleString("en-US", {
    timeZone: timezone,
    day: "numeric",
    month: "short",
  });

  return `${startDate} - ${endDate}`;
};
