// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).mountReact = (
  target: HTMLElement,
  componentPath: string,
  props: object,
) => {
  const entrypoint = document.createElement("div");
  entrypoint.dataset.reactClass = componentPath;
  entrypoint.dataset.reactProps = JSON.stringify(props);
  target.append(entrypoint);
  window.ReactRailsUJS.mountComponents(target);
};
