import React from "react";
import { Banner } from "@jobber/components/Banner";
import styles from "./ApplicationRevisionBanner.module.css";

export interface ApplicationRevisionBannerProps {
  name: string;
  oauthUrl: string;
  marketplaceUrl: string;
}

export function ApplicationRevisionBanner({
  name,
  oauthUrl,
  marketplaceUrl,
}: ApplicationRevisionBannerProps) {
  return (
    <Banner
      type="notice"
      primaryAction={{
        label: "Re-Authorize",
        onClick: handleConnect,
      }}
    >
      <span className={styles.applicationRevisionBanner}>
        <a
          className={styles.appLink}
          href={marketplaceUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>{" "}
        has been updated and requires re-authorization.{" "}
      </span>
    </Banner>
  );

  function handleConnect() {
    window.open(oauthUrl, "_blank");
  }
}
