import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignSegmentUpdateUpcomingClientsMutation,
  CampaignSegmentUpdateUpcomingClientsMutationVariables,
} from "~/utilities/API/graphql";
import { UPDATE_UPCOMING_CLIENTS_SEGMENT_CAMPAIGN } from "./useUpdateUpcomingClientsSegmentCampaign.graphql";

export function useUpdateUpcomingClientsSegmentCampaign() {
  const [updateUpcomingClientsSegmentCampaignMutation, { loading }] =
    useMutation<
      CampaignSegmentUpdateUpcomingClientsMutation,
      CampaignSegmentUpdateUpcomingClientsMutationVariables
    >(UPDATE_UPCOMING_CLIENTS_SEGMENT_CAMPAIGN);
  const updateUpcomingClientsSegmentCampaign = useCallback(
    async (input: CampaignSegmentUpdateUpcomingClientsMutationVariables) => {
      const mutationResult = await updateUpcomingClientsSegmentCampaignMutation(
        {
          variables: input,
        },
      );
      const userErrors =
        mutationResult.data?.campaignSegmentUpdateUpcomingClients.userErrors ??
        [];
      return {
        clientSegment:
          mutationResult.data?.campaignSegmentUpdateUpcomingClients
            .upcomingClientsSegment,
        userErrors,
      };
    },
    [updateUpcomingClientsSegmentCampaignMutation],
  );
  return { updateUpcomingClientsSegmentCampaign, loading };
}
