export const EMPTY_STATE_HEADING = "No items found";
export const EMPTY_STATE_BODY = "There do not appear to be any disputes";
export const INNER_CELL_WIDTH_LARGE = 535;
export const INNER_CELL_WIDTH_BASE = 300;
export const INNER_CELL_WIDTH_SMALL = 60;
export const INNER_CELL_WIDTH_SMALLER = 35;
export const INNER_CELL_WIDTH_SMALLEST = 15;

export const paymentDisputeSortColumnKeys = {
  STATUS: "STATUS",
  DISPUTED_ON: "DISPUTED_ON",
  CLIENT: "CLIENT",
  INVOICE_QUOTE: "INVOICE_QUOTE",
  AMOUNT: "AMOUNT",
  REASON: "REASON",
  TYPE: "TYPE",
};

export const columnHeaderLabels = {
  status: "Status",
  disputedOn: "Disputed on",
  client: "Client",
  invoiceQuote: "Invoice/Quote",
  amount: "Amount",
  reason: "Reason",
  type: "Type",
};
