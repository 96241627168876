import gql from "graphql-tag";

export const LEAD_ROUTES_QUERY = gql`
  query leadRoutes($searchTerm: String!) {
    leadRoutes(searchTerm: $searchTerm) {
      nodes {
        id
        account {
          name
        }
        url
        routingCode
      }
    }
  }
`;
