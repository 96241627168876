import { assign, createMachine } from "xstate";
import { FetchResult } from "@apollo/client";
import { AssignableUsersQuery } from "~/utilities/API/graphql";

export interface AssignTeamContext {
  assignableUsers: AssignableUsersQuery["users"]["nodes"];
  selectedAssignees: Set<string>;
  currentUserId: string;
  error?: unknown;
}

export type AssignTeamEvents =
  | { type: "ENABLE" }
  | { type: "DISABLE" }
  | { type: "ADD_ASSIGNEE"; userId: string }
  | { type: "REMOVE_ASSIGNEE"; userId: string }
  | { type: "SET_SELECTED_ASSIGNEES"; userIds: string[] };

export const assignTeamMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QENawJZQHYBUzIFsA6dCAGzAGIBBAEVoH1qBlZgSQHEA5AUR4G0ADAF1EoAA4B7DABd0krGJAAPRAFoATABYiAdg0aAbAFZDG4wBoQAT0QBGYzoCcTxwA4thswGZdbjQC+AVaoGNh4hCTkVABKPACyAPIAajxMrJy8AiJKUrLyikgq6t6CRIJOvpY2iBr6RFp2+vqN3oZ2doa6QSFomLj4xKQUlMw8OAxjADI8AMI4PIws7Nx8zEKiRXnocgpKqgha3sZExrqV-mduxt52boZWtgh1Gg1NL63tnd3BIKH9EWIZEkyAg6CwUEoEAUYBIWAAbpIANawgCOAFcwAAnazUPrYZAAIwoAFVYNjYBtctIdgV9ohBI8GT0-viBpFgaDwZDsVjJFiiOIyMgZAAzfnEDHY3Fsomk8lYyk5LY03aFUAHEzeIhNY6CYzXJxHXTVJ5qQw6XSGJyGQQtW6uQRuFn-cKDKIjWhsZjUABCMypKvyeyKBw01x1pgd9yaTu8TIQUb0gn1dkEdwMul03hdbMBRC9Pv9i0oPC4foDyokqrpocQrXKbm8WhbTjsRrsbS0CaTuhTN0EWjOnmMQV+WEkEDgSld7II1OD6uKCDUfnKlRNCbUpTsOp3Q6bttuTdzYTnHrAC9pIY1tRcRDcug6xhTTitrnjNUOBofGn16d0TwWxMU8AXdZApDIYEeCxPksSvNV6QQJ9Xk8bwnD-DCNEqRwe21cNjBfAw2wHdtQLdDkQTBCEENrW8EG8AxykYuw6lMPs7CHLcNE7IhDB3NoDVY+4c1+Wd80LCtFlom9l3DXd9DqTo3G+NtBE-J4OL0c4n2uIitEEQIxyAA */
    predictableActionArguments: true,
    id: "assignTeam",

    states: {
      idle: {
        on: {
          ADD_ASSIGNEE: {
            target: "idle",
            internal: true,
            actions: "addAssignee",
            cond: "isNotAssigned",
          },

          REMOVE_ASSIGNEE: {
            target: "idle",
            internal: true,
            actions: "removeAssignee",
            cond: "isAssigned",
          },

          SET_SELECTED_ASSIGNEES: {
            target: "idle",
            internal: true,
            actions: "setSelectedAssignees",
          },

          DISABLE: "disabled",
        },
      },

      apolloError: {},

      loading: {
        invoke: {
          src: "queryAssignableUsers",
          id: "queryAssignableUsers",
          onDone: {
            target: "idle",
            actions: "setAssignableUsers",
          },
          onError: {
            target: "apolloError",
            actions: "setError",
          },
        },
      },

      disabled: {
        on: {
          ENABLE: "idle",
        },
      },
    },

    tsTypes: {} as import("./assignTeam.machine.typegen").Typegen0,

    schema: {
      context: {} as AssignTeamContext,
      services: {} as {
        queryAssignableUsers: {
          data: FetchResult<AssignableUsersQuery>;
        };
      },
      events: {} as AssignTeamEvents,
    },

    context: {
      assignableUsers: [],
      currentUserId: "",
      selectedAssignees: new Set<string>(),
    },
    initial: "loading",
  },
  {
    guards: {
      isAssigned: (context, event) => {
        return context.selectedAssignees.has(event.userId);
      },
      isNotAssigned: (context, event) => {
        return !context.selectedAssignees.has(event.userId);
      },
    },
    actions: {
      setError: assign({ error: (_, event) => event.data }),
      setAssignableUsers: assign((_, event) => {
        return {
          assignableUsers: event.data.data?.users.nodes ?? [],
          currentUserId: event.data.data?.account?.currentUser.id,
        };
      }),
      addAssignee: assign((context, event) => ({
        selectedAssignees: context.selectedAssignees.add(event.userId),
      })),
      removeAssignee: assign((context, event) => {
        context.selectedAssignees.delete(event.userId);
        return {
          selectedAssignees: context.selectedAssignees,
        };
      }),
      setSelectedAssignees: assign((_, event) => {
        return {
          selectedAssignees: new Set(event.userIds),
        };
      }),
    },
  },
);

export type AssignTeamMachine = typeof assignTeamMachine;
