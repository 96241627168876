import { useState } from "react";
import { messages } from "../messages";

interface UseRadiusUnits {
  minValue: number;
  maxValue: number;
  radius: number;
  setRadius: (value: number) => void;
  kmRadius: number;
  setKmRadius: (value: number) => void;
  unitLabel:
    | typeof messages.radiusFieldUnitMile
    | typeof messages.radiusFieldUnitKilometer;
}

export function useRadiusUnits(
  countryCode: string,
  initialRadiusInKms?: number,
): UseRadiusUnits {
  const [radius, setRadius] = useState<number>(
    initialRadiusInKms ??
      (() => {
        switch (countryCode) {
          case "US":
            return fromImperial(15);
          default:
            return 25;
        }
      })(),
  );

  switch (countryCode) {
    case "US":
      return {
        minValue: 1,
        maxValue: 300,
        radius: Math.round(toImperial(radius)),
        setRadius: (valueInMiles: number) =>
          setRadius(fromImperial(valueInMiles)),
        kmRadius: radius,
        setKmRadius: setRadius,
        unitLabel: messages.radiusFieldUnitMile,
      };
    default:
      return {
        minValue: 1,
        maxValue: 500,
        radius: Math.round(radius),
        setRadius,
        kmRadius: radius,
        setKmRadius: setRadius,
        unitLabel: messages.radiusFieldUnitKilometer,
      };
  }
}

function toImperial(valueInKms: number) {
  return valueInKms * 0.621371;
}

function fromImperial(valueInMiles: number) {
  return valueInMiles * 1.60934;
}
