// eslint-disable-next-line import/no-internal-modules
import type { AuthorizeNetProvider } from "~/shared/payments/providers/AuthorizeNetProvider/AuthorizeNetProvider";
import { SignatureValidationError } from "./errors";
import {
  type DirectProvider,
  type SquareWebPaymentProvider,
  type StripeProvider,
  type TokenData,
  mountProvider,
} from "./providers";
import { JobberOnline, parsePaymentType } from "./utils";

interface FormParams {
  provider: string;
  payment_method_public_key: string;
  charge_card_url: string;
  on_success_url?: string;
  currency?: string;
  locale?: string;
}

interface FormState {
  readonly selectedPaymentType: JobberOnline.constants.balanceAdjustments.paymentTypes;
  readonly billingAddress?: {
    billing_address1: string;
    billing_address2: string;
    billing_city: string;
    billing_state: string;
    billing_country: string;
    billing_zip: string;
  };
  readonly nameOnCard?: string | undefined;
  readonly card?: {
    name_on_card: string;
    credit_card_number: string;
    last_four: string;
    ccv: string;
    expiration_month: string;
    expiration_year: string;
  };
  readonly paymentMethod?: {
    isChargingNewPaymentMethod: boolean;
    selectedPaymentMethod?: number;
    savePaymentMethod: boolean;
  };
}

interface Context<Params, State> {
  readonly params: Readonly<Params>;
  readonly state: Readonly<State>;
}

interface Serializeable {
  serialize(): Promise<FormData>;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class FormContext
  implements Serializeable, Context<FormParams, FormState>
{
  public readonly form: HTMLFormElement;

  private readonly tokenProvider:
    | StripeProvider
    | DirectProvider
    | SquareWebPaymentProvider
    | AuthorizeNetProvider;

  constructor(form: HTMLFormElement) {
    this.form = form;
    this.tokenProvider = mountProvider(this);
  }

  public get params() {
    let formConfigWrapper = this.form.querySelector<HTMLElement>(
      ".js-jobberPaymentsForm",
    );

    if (formConfigWrapper && formConfigWrapper.dataset.extraJsParams) {
      return JSON.parse(formConfigWrapper.dataset.extraJsParams) as FormParams;
    }

    if (!formConfigWrapper) {
      formConfigWrapper =
        document.querySelector<HTMLElement>("#new_balance_adjustment") ||
        document.querySelector<HTMLElement>("#new_e_payment_direct_charger");
      if (formConfigWrapper && formConfigWrapper.dataset.extraJsParams) {
        return JSON.parse(
          formConfigWrapper.dataset.extraJsParams,
        ) as FormParams;
      }
    }

    return {
      provider: "",
      payment_method_public_key: "",
      charge_card_url: "",
    };
  }

  public get state() {
    return {
      selectedPaymentType: this.selectedPaymentType,
      billingAddress: this.billingAddress,
      nameOnCard: this.nameOnCard,
      card: this.card,
      paymentMethod: this.paymentMethod,
    };
  }

  public async serialize() {
    const payload = await this.buildPayload();

    return this.buildFormData(payload);
  }

  private get selectedPaymentType(): JobberOnline.constants.balanceAdjustments.paymentTypes {
    const select = this.form.querySelector(
      ".js-paymentType select",
    ) as HTMLSelectElement;

    if (select) {
      return parsePaymentType(select.value);
    }

    return JobberOnline.constants.balanceAdjustments.paymentTypes.epayment;
  }

  private get billingAddress() {
    const billingAddressForm = this.form.querySelector(
      "sgx-payments-billing-address-form",
    ) as SG1.SgxPaymentsBillingAddressForm;

    if (!billingAddressForm) {
      return undefined;
    }

    const {
      billingAddress: { city, country, pc, province, street1, street2 },
    } = billingAddressForm;

    return {
      billing_address1: street1,
      billing_address2: street2,
      billing_city: city,
      billing_state: province,
      billing_country: country,
      billing_zip: pc,
    };
  }

  private get nameOnCard() {
    const nameInput = this.form.querySelector(
      "[name='e_payment_direct_charger[name_on_card]']",
    ) as HTMLInputElement;

    return nameInput ? nameInput.value : undefined;
  }

  private get card() {
    const cardForm = this.form.querySelector(
      "sgx-payments-charge-payment-method-form",
    ) as SG1.SgxPaymentsChargePaymentMethodForm;

    if (!cardForm) {
      return undefined;
    }

    const {
      cardNumber,
      cvc,
      expirationMonth,
      expirationYear,
      last4,
      nameOnCard,
    } = cardForm.card;

    return {
      name_on_card: nameOnCard,
      credit_card_number: cardNumber,
      last_four: last4,
      ccv: cvc,
      expiration_month: `${expirationMonth}`,
      expiration_year: `${expirationYear}`,
    };
  }

  private get paymentMethod() {
    const cardForm = this.form.querySelector(
      "sgx-payments-charge-payment-method-form",
    ) as SG1.SgxPaymentsChargePaymentMethodForm;

    const {
      isChargingNewPaymentMethod = true,
      selectedPaymentMethod,
      state: { savePaymentMethod = false },
    } = cardForm || { state: {}, selectedPaymentMethod: undefined };

    return {
      isChargingNewPaymentMethod,
      selectedPaymentMethod,
      savePaymentMethod,
    };
  }

  private async buildPayload() {
    const token = await this.createToken();

    if (this.isSignatureRequired()) {
      const signature = this.exportSignature();

      if (signature.error) {
        throw signature.error;
      }

      return {
        e_payment_direct_charger: token,
        ...{ "approval-signature-data": signature.data },
      };
    }

    return {
      e_payment_direct_charger: token,
    };
  }

  private async createToken(): Promise<TokenData> {
    const {
      savePaymentMethod,
      isChargingNewPaymentMethod,
      selectedPaymentMethod,
    } = this.paymentMethod;

    const vaultingOptions = {
      vault_payment_info: savePaymentMethod || !isChargingNewPaymentMethod,
      charging_new_payment_method: isChargingNewPaymentMethod,
    };

    if (isChargingNewPaymentMethod) {
      const token = await this.tokenProvider.createToken();

      return {
        ...vaultingOptions,
        ...token,
      };
    }

    return {
      ...vaultingOptions,
      payment_method_id: selectedPaymentMethod,
    };
  }

  /**
   * Creates a new `FormData` object with all the data present on the form
   * and adds the fields from the given payload.
   */
  private buildFormData(payload: {
    e_payment_direct_charger: TokenData;
    "approval-signature-data"?: string;
  }) {
    const formData = new FormData(this.form);

    for (const [key, value] of Object.entries(payload)) {
      if (typeof value === "object") {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          if (nestedValue != undefined) {
            formData.append(`${key}[${nestedKey}]`, `${nestedValue}`);
          }
        }
      } else if (typeof value === "string") {
        formData.append(key, value);
      }
    }

    return formData;
  }

  private isSignatureRequired() {
    return this.signaturePad != undefined;
  }

  private exportSignature() {
    if (this.signaturePad == undefined) {
      throw new Error("Signature pad is not present");
    }

    const data = this.signaturePad.exportCanvas();

    if (!data) {
      return {
        error: new SignatureValidationError("Signature is required."),
      };
    }

    return { data };
  }

  private getSelectedSignatureType(): "draw" | "type" | undefined {
    const [selectedSignatureType] = Array.from(
      this.form.querySelectorAll("input[name='SignatureControlSelect']"),
    )
      .filter(({ checked }: HTMLInputElement) => checked)
      .map(({ value }: HTMLInputElement) => value);

    if (selectedSignatureType === "draw" || selectedSignatureType === "type") {
      return selectedSignatureType;
    }
  }

  private get signaturePad() {
    const selectedSignatureType = this.getSelectedSignatureType();
    let signaturePad;

    if (selectedSignatureType === "draw") {
      signaturePad = this.form.querySelector(
        "signature-pad",
      ) as SG1.SignaturePad;
    }

    if (selectedSignatureType === "type") {
      signaturePad = this.form.querySelector(
        "type-signature-pad",
      ) as SG1.TypeSignaturePad;
    }

    return signaturePad;
  }
}
