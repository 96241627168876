import { gql } from "@apollo/client";

const billingCycleData = gql`
  fragment billingCycleData on BillingCycle {
    displayName
    name
    monthlyPrice
    planCode
    annualPrice
  }
`;

export const PLAN_INFO = gql`
  query PlanInfo($planSetIdentifier: String!) {
    accountPlanInfo {
      planIdentifier
      currentBillingCycle {
        planCode
      }
    }
    planInfo(planSetIdentifier: $planSetIdentifier) {
      planTier
      planIdentifier
      annualBillingCycle {
        ...billingCycleData
      }
      monthlyBillingCycle {
        ...billingCycleData
      }
    }
  }

  ${billingCycleData}
`;

const subscriptionPreview = gql`
  fragment subscriptionPreview on SubscriptionPreview {
    proratedDiscount
    proratedSubtotal
    proratedTax
    taxRate
    proratedTotal
    totalCredit
    startBillingDate
    renewalDate
    isCurrentBillingCycle
    subscribeDisabled
  }
`;

export const SUBSCRIPTION_PREVIEW_GROUP = gql`
  query SubscriptionPreviewGroup(
    $planSetIdentifier: String!
    $postalCode: String
    $country: String
  ) {
    subscriptionPreviewGroup(
      planSetIdentifier: $planSetIdentifier
      postalCode: $postalCode
      country: $country
    ) {
      annualPreview {
        ...subscriptionPreview
      }
      monthlyPreview {
        ...subscriptionPreview
      }
    }
  }

  ${subscriptionPreview}
`;

const subscriptionDiscount = gql`
  fragment subscriptionDiscount on SubscriptionDiscount {
    discountAmount
    discountDurationUnit
    discountType
    remainingDiscountedFullMonths
    discountEndDate
    planCostMonthlyDiscounted
  }
`;

export const SUBSCRIPTION_DISCOUNT_GROUP_INFO = gql`
  query SubscriptionDiscountGroupInfo(
    $planSetIdentifier: String!
    $nextBillingDate: ISO8601DateTime
  ) {
    subscriptionDiscountGroup(
      planSetIdentifier: $planSetIdentifier
      nextBillingDate: $nextBillingDate
    ) {
      monthlySubscriptionDiscount {
        ...subscriptionDiscount
      }
      annualSubscriptionDiscount {
        ...subscriptionDiscount
      }
    }
  }

  ${subscriptionDiscount}
`;
