import React from "react";
import { FormattedMessage } from "react-intl";
import { Heading } from "@jobber/components";
import { AddNewLineItemButton } from "jobber/workOrders/components/JobCost/components/LineItemsTable/components/AddNewLineItemButton";
import styles from "./styles.module.css";
import { messages } from "./messages";

export function LineItemsBulkEditHeader({
  onClickAddLineItem,
}: {
  onClickAddLineItem: () => void;
}) {
  return (
    <div className={styles.lineItemsBulkEditHeader}>
      <Heading level={3}>
        <FormattedMessage {...messages.lineItemsHeader} />
      </Heading>
      <AddNewLineItemButton onPress={onClickAddLineItem} />
    </div>
  );
}
