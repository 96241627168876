import React, { type ReactText, useEffect, useState } from "react";
import { Option, Select } from "@jobber/components/Select";
import { InputText } from "@jobber/components/InputText";
import { Content } from "@jobber/components/Content";
import type {
  CancellationFormOption,
  CancellationFormQuestion,
} from "./CancellationFormQuestion.d";

export function Question({
  id,
  type,
  label,
  customerText,
  options,
  value,
  nextQuestion,
  addAnswer,
}: CancellationFormQuestion) {
  const [orderedOptions, setOrderedOptions] = useState([{}]);

  useEffect(() => {
    if (options) {
      setOrderedOptions(
        options
          ?.map(option => ({ option, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ option }) => option),
      );
    }
  }, []);

  function submitAnswer(newValue: string | Date | ReactText) {
    const selectedOption = options?.find(opt => opt.label === newValue);
    const questionToAdd = selectedOption?.nextQuestion || nextQuestion;
    const valueToAdd = selectedOption?.label || newValue;

    addAnswer({
      id: id,
      label: label,
      customerText: newValue as string,
      nextQuestion: questionToAdd,
      value: valueToAdd as string,
    });
  }

  switch (type) {
    case "text":
      return (
        <Content spacing={"large"}>
          <InputText
            placeholder={customerText}
            value={value}
            onChange={submitAnswer}
            size="large"
          />
        </Content>
      );
    case "bigText":
      return (
        <Content spacing={"large"}>
          <InputText
            multiline={true}
            placeholder={customerText}
            value={value}
            onChange={submitAnswer}
          />
        </Content>
      );
    case "select":
      return (
        <Content spacing={"large"}>
          <Select
            placeholder={customerText}
            value={value}
            onChange={submitAnswer}
            size="large"
          >
            <Option key="-1" value="" />
            {orderedOptions.map(
              (option: CancellationFormOption, index: number) => {
                return (
                  <Option key={index} value={option.label}>
                    {option.customerText}
                  </Option>
                );
              },
            )}
          </Select>
        </Content>
      );
    default:
      throw new Error(
        "Invalid question type passed to cancellation form input",
      );
  }
}
