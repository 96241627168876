import { useMutation } from "@apollo/client";
import type { AchPaymentMutation } from "~/utilities/API/graphql";
import { ACH_PAYMENT_MUTATION } from "./achPaymentMutation";

export interface SubmitAchPaymentInput {
  attachedToId: string;
  attachedToType: "invoice" | "quote";
  paymentMethodUuid: string;
  amount: string;
}

export const useSubmitAchPayment = () => {
  const [submitAchPayment] =
    useMutation<AchPaymentMutation>(ACH_PAYMENT_MUTATION);

  const submitPayment = async (
    input: SubmitAchPaymentInput,
  ): Promise<{
    success: boolean;
    errorMessage?: string;
  }> => {
    const { attachedToId, attachedToType, paymentMethodUuid, amount } = input;
    const variables = {
      attachedToId,
      attachedToType,
      paymentMethodUuid,
      amount,
    };

    try {
      const result = await submitAchPayment({ variables });

      if (!result.data?.achPayment.success) {
        throw new Error(result.data?.achPayment.errors[0]?.message);
      }

      return { success: true };
    } catch (error) {
      const errorMessage =
        error.message || "Something went wrong, could not collect payment.";

      return { success: false, errorMessage };
    }
  };

  return { submitPayment };
};
