import { useMemo, useState } from "react";
import { CampaignsFilterFields } from "~/utilities/API/graphql";
import type { CampaignsFilterInput } from "~/utilities/API/graphql";

export const DEFAULT_FILTER = CampaignsFilterFields.ONEOFF;

export interface CampaignsListFilterOption {
  tabIndex: number;
  filter: CampaignsFilterInput;
}

export interface FilterActions {
  setFilterType(newFilter: CampaignsListFilterOption): void;
}

export function useCampaignsQueryVariables(urlParam: CampaignsFilterFields) {
  const index = Math.abs(Object.keys(CampaignsFilterFields).indexOf(urlParam));
  const [filter, setFilter] = useState<CampaignsListFilterOption>({
    tabIndex: index === 1 ? 0 : 1,
    filter: { type: urlParam || DEFAULT_FILTER },
  });

  const filterOptions = useMemo(
    () => [
      {
        tabIndex: 0,
        filter: { type: CampaignsFilterFields.ONEOFF },
      },
      {
        tabIndex: 1,
        filter: { type: CampaignsFilterFields.AUTOMATED },
      },
    ],
    [],
  );

  function setFilterType(newFilter: CampaignsFilterFields): void {
    const filterParam = filterOptions.find(
      option => option.filter.type === newFilter,
    );
    if (!filterParam) {
      throw new Error(`Filter option with id ${newFilter} not found`);
    }
    setFilter(filterParam!);
  }

  return {
    filter,
    filterOptions,
    actions: {
      setFilterType,
    },
  };
}
