import React, { type MouseEvent } from "react";

export interface RecipientListProps {
  filteredRecipients: string[];
  shouldDisplay: boolean;
  highlightedItem: number;
  onAddRecipient(selectedItem: string): void;
  onUpdateHighlight?(hoveredItem: number): void;
}

export function RecipientList({
  filteredRecipients,
  onAddRecipient,
  shouldDisplay,
  highlightedItem,
  onUpdateHighlight,
}: RecipientListProps) {
  if (filteredRecipients.length === 0 || !shouldDisplay) {
    return null;
  }

  const selectRecipient = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Needed to return focus to <textarea>
    const selectedItem = (event.target as HTMLElement).innerText;
    onAddRecipient(selectedItem);
  };

  const handleHover = (param: number) => () => {
    if (onUpdateHighlight) {
      onUpdateHighlight(param);
    }
  };

  const recipientDropdownCSS: React.CSSProperties = {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    zIndex: 1,
  };

  // Using 'real' ListItem and ListItemLink classes trigger :hover, which duplicates highlighted items when using the arrow keys
  const fauxListItem: React.CSSProperties = {
    display: "flex",
    flex: "0 0 auto",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    minHeight: "1.5rem",
    padding: "0.25rem 0",
    fontSize: "0.875rem",
    lineHeight: "1.3",
    color: "#424e56",
  };

  const fauxListItemLink: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "-0.25rem",
    marginBottom: "-0.25rem",
    padding: "0.25rem 1rem",
    textDecoration: "none",
    cursor: "pointer",
  };

  return (
    <div
      className="card u-paddingSmaller u-boxShadow"
      style={recipientDropdownCSS}
    >
      <ul className="list u-marginNone u-maxHeight400">
        {filteredRecipients.map((recipient: string, listItemIndex: number) => {
          const shouldHighlight =
            highlightedItem === listItemIndex ? "u-bgColorSurfaceHover" : "";

          return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-redundant-roles -- Grandfathered error: Please fix if touching this code.
            <li
              key={`key${listItemIndex}-${recipient}`}
              // Don't apply 'list-item' class or multiple highlighted items will appear
              className={`${shouldHighlight} `}
              style={fauxListItem}
              onMouseOver={handleHover(listItemIndex)}
              role="listitem"
            >
              <button
                className="u-paddingSmaller"
                style={fauxListItemLink}
                // Use onMouseDown instead of onClick because onClick fires after onBlur and won't get triggered
                onMouseDown={selectRecipient}
                data-testid={`dropdown-${recipient}`}
              >
                <span className="list-label">{recipient}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
