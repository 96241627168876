import { defineMessages } from "react-intl";

export const messages = defineMessages({
  registrationLoadingError: {
    id: "ukKycComplianceRegistration.ukKycComplianceRegistrationLoader.registrationLoadingError",
    defaultMessage: "Error fetching KYC registration details",
    description: "Error message when fetching KYC registration details",
  },
  registrationCreateError: {
    id: "ukKycComplianceRegistration.UkKycComplianceRegistrationMain.registrationCreateError",
    defaultMessage: "Error creating KYC registration",
    description: "Error message when creating KYC registration",
  },
  fetchInquiryError: {
    id: "ukKycComplianceRegistration.UkKycComplianceRegistrationMain.fetchInquiryError",
    defaultMessage: "Error fetching inquiry details",
    description: "Error message when fetching inquiry",
  },
  registrationRejectedPromptMessage: {
    id: "ukKycComplianceRegistration.UkKycComplianceRegistrationMain.registrationRejectedPromptMessage",
    defaultMessage:
      "Confirm your registration type, incorrect registration may have been selected",
    description: "Prompt message when registration is rejected",
  },
});
