import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CONVERSATION_MUTATION,
  GET_CONVERSATION_ID_QUERY,
} from "jobber/chat/components/SmsComposerPage/SmsComposerPage.graphql";
import type {
  CreateConversationMutation,
  GetConversationIdQuery,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";

export function useLookupConversationId(phoneNumber: string) {
  const [
    createNewConversation,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation<CreateConversationMutation>(CREATE_CONVERSATION_MUTATION, {
    refetchQueries: [
      {
        query: GET_CONVERSATION_ID_QUERY,
        variables: {
          externalPhoneNumber: phoneNumber,
        },
      },
    ],
  });

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<GetConversationIdQuery>(GET_CONVERSATION_ID_QUERY, {
    variables: {
      externalPhoneNumber: phoneNumber,
    },
  });

  if (queryLoading || mutationLoading || queryError || mutationError) {
    return {
      conversationId: undefined,
      loading: queryLoading || mutationLoading,
      error: queryError || mutationError,
    };
  }

  const conversations =
    queryData?.conversations?.edges?.filter(edge => !edge.node.deletedAt) || [];

  if (conversations.length === 0 && !queryLoading) {
    if (mutationData === undefined && !mutationError && !mutationLoading) {
      createNewConversation({
        variables: {
          recipient: phoneNumber,
        },
      }).catch((error: Error) => {
        Rollbar.EXECUTE("Error creating new conversation", error);
      });

      return {
        conversationId: undefined,
        loading: true,
        error: undefined,
      };
    }
  }

  const conversationId =
    conversations[0]?.node?.id ||
    mutationData?.smsConversationCreate.conversation?.id;

  return {
    conversationId: conversationId,
    loading: false,
    error: conversationId === undefined,
  };
}
