/* eslint-disable import/no-internal-modules */
/**
 * Scheduling Assistant Modal Page Object Model
 *
 * - waitForLoadingToFinish
 * - displaysDate
 * - hasHighlightedAvailability
 * - displaysAvailabilitySlotsForDate
 *
 * Simulators:
 *  - cancel
 * - save
 * - nextMonth
 *
 * Dates used in tests:
 * - januaryOne2000
 * - februaryOne2000
 * - marchOne2000
 * - valentinesDay2000
 *
 * Mocks:
 * - onCancelCallback
 * - afterEach
 * - setup
 * - render
 * - executeSubscriptionObserver
 * - getMockedAvailabilityOfDate
 *
 */
import React from "react";
import {
  render as renderTest,
  screen,
  waitForElementToBeRemoved,
  within,
} from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { useInterpret, useMachine } from "@xstate/react";
import { MockedProvider, type MockedResponse } from "@apollo/client/testing";
import { format, isEqual } from "date-fns";
import { ScheduleCardMachineContext } from "jobber/workOrders/components/ScheduleCard/ScheduleCardMachineContext";
import { scheduleCardMachine } from "jobber/workOrders/components/ScheduleCard/scheduleCard.machine";
import type {
  RecommendNonRepeatingVisitScheduleSubscriptionSubscription,
  User,
} from "~/utilities/API/graphql";
import {
  AssignTeamMachineContext,
  useAssignTeamMachineConfiguration,
} from "jobber/workOrders/components/AssignTeam";
import {
  DefaultAccountContext,
  MockAccountContextProvider,
} from "~/utilities/contexts/internal/mocks";
import { makeSelectDurationPOM } from "./components/SelectDuration/SelectDuration.pom";
import { SchedulingAssistantModal } from "./SchedulingAssistantModal";
import * as MiniCalendarPOM from "./components/MiniCalendar/MiniCalendar.pom";
import * as DayViewPOM from "./components/DayView/DayView.pom";
import type { AvailabilityEvent } from "./hooks/useSchedulingAvailability";

export * as TeamMembersPOM from "./components/SelectTeamMember/SelectTeamMember.pom";
export * as DayViewPOM from "./components/DayView/DayView.pom";
export * as MiniCalendarPOM from "./components/MiniCalendar/MiniCalendar.pom";
export {
  hasHighlightedDates,
  selectDate,
  hasSelectedMonth,
} from "./components/MiniCalendar/MiniCalendar.pom";
export const DurationPOM = makeSelectDurationPOM("Duration");
export const DriveTimePOM = makeSelectDurationPOM("Drive time limit");
// POM

/**
 * Simulates clicking the cancel button
 */
export const cancel = () => {
  userEvent.click(screen.getByText("Cancel"));
};

/**
 * Simulates clicking the save button
 */
export const save = () => {
  userEvent.click(screen.getByText("Select"));
};

/**
 * Waits for all loading indicators to be removed from the DOM
 */
export async function waitForLoadingToFinish() {
  expect(screen.getAllByLabelText("loading")).toBeDefined();
  await waitForElementToBeRemoved(() => screen.queryAllByLabelText("loading"));
}

/**
 * @returns The selected date displayed in the header
 */
export function displaysDate(date: Date) {
  // Thu Oct 26, 2023
  return component().getByText(format(date, "EEE MMM d, yyyy"));
}

/**
 * @returns The date in the summary
 */
export function displaysDateSummary(date: string) {
  // Thu Oct 26, 2023
  return summaryComponent().getByText(date);
}

/**
 * @returns The assignee in the summary
 */
export function displaysAssigneeSummary(assignee: string) {
  return summaryComponent().getByText(assignee);
}

/**
 * @returns The assignee in the summary
 */
export function noAssigneeSummary(assignee: string) {
  return summaryComponent().queryAllByText(assignee);
}

/**
 * Simulates clicking the next month button and waits for availability to load
 */
export async function nextMonth({ clickCount = 1 } = {}) {
  clickCount = Math.abs(clickCount);
  clickCount = clickCount === 0 ? 1 : clickCount;

  for (let i = 0; i < clickCount; i++) {
    MiniCalendarPOM.nextMonth();
    await DurationPOM.waitForLoadingToFinish();
  }
}

const querySelectorSpy = jest.spyOn(document, "querySelector");

export const expectToScrollToHour = (hour: number) => {
  const anHourAgo = new Date();
  anHourAgo.setHours(hour - 1);

  expect(querySelectorSpy).toHaveBeenCalledWith(
    `[data-time="${format(anHourAgo, "HH")}:00:00"]`,
  );
};

interface GetAvailabilitySlotOnCalendarArgs {
  startAt: Date;
  endAt: Date;
  driveTimeTo?: number;
  driveTimeFrom?: number;
  assignee: Pick<User, "id" | "__typename">;
}

export function getAvailabilitySlotOnCalendar({
  startAt,
  endAt,
  driveTimeTo,
  driveTimeFrom,
  assignee,
}: GetAvailabilitySlotOnCalendarArgs): AvailabilityEvent {
  const availabilitySlot = DayViewPOM.events().find(event => {
    const isAvailabilitySlot = /available-slot-/.test(event.title as string);
    const sameStart = isEqual(startAt, event.start as Date);
    const sameEnd = isEqual(endAt, event.end as Date);
    const isForExpectedAssignee =
      event.resourceId === assignee.id ||
      event.resourceIds?.includes(assignee.id);
    const hasExpectedDriveTimeTo = event?.driveTimeTo === driveTimeTo;
    const hasExpectedDriveTimeFrom = event?.driveTimeFrom === driveTimeFrom;

    return (
      isAvailabilitySlot &&
      sameStart &&
      sameEnd &&
      isForExpectedAssignee &&
      hasExpectedDriveTimeTo &&
      hasExpectedDriveTimeFrom
    );
  });

  if (!availabilitySlot) {
    throw new Error(
      `No availability slot found for ${format(
        startAt,
        "MMM dd, yyyy HH:mm",
      )} - ${format(endAt, "HH:mm")}`,
    );
  }

  return availabilitySlot as unknown as AvailabilityEvent;
}

export const expectAvailabilitySlotOnCalendar = (
  args: GetAvailabilitySlotOnCalendarArgs,
) => {
  getAvailabilitySlotOnCalendar(args);
};

// Dates used in tests
export const JAN_15 = new Date(2000, 0, 15, 0, 0, 0, 0);
export const JAN_12 = new Date(2000, 0, 12, 0, 0, 0, 0);
export const JAN_30 = new Date(2000, 0, 30, 0, 0, 0, 0);
export const JAN_31 = new Date(2000, 0, 31, 0, 0, 0, 0);
export const REQUESTED_START_DATE_JAN_2000 = JAN_15;
export const REQUESTED_START_DATE_FEB_2000 = JAN_30;
export const REQUESTED_START_DATE_FEB_2000_WEEKSTART_MONDAY = JAN_31;

function root() {
  return screen.getByTestId("scheduling-assistant");
}

function component() {
  return within(root());
}

export function summaryComponent() {
  return within(screen.getByTestId("scheduling-summary"));
}

export function noSummaryComponent() {
  return screen.queryByTestId("scheduling-summary");
}

// Testing mocks & utilities

interface MockSetupProps {
  initialStartTime?: Date;
  initialEndTime?: Date;
  weekStartsOnMonday?: boolean;
  initialSelectedDate?: Date;
  initialSelectedEndDate?: Date;
  initialDurationInSeconds?: number;
  initialTeamSelection?: Set<string>;
  mocks: MockedResponse[];
  open?: boolean;
}

/**
 * Render the Scheduling Assistant Modal and wait for availability to load
 */
export async function setup(options: MockSetupProps) {
  render(options);

  await waitForLoadingToFinish();
}

/**
 * Render the Scheduling Assistant Modal
 */
export function render(options: MockSetupProps) {
  renderTest(
    <SchedulingAssistantModal
      open={options.open == null ? true : options.open}
      onClose={mockOnCancel}
    />,
    {
      wrapper: makeWrapper(options),
    },
  );
}

/**
 *
 * @returns The mock  used for the onCancel callback
 */
export function getOnCancelCallbackMock() {
  return mockOnCancel;
}

/**
 * Mock for the onCancel callback
 */
const mockOnCancel = jest.fn<void, []>();

export const afterEach = () => {
  mockOnCancel.mockReset();
};

export interface AvailabilityResponse {
  data: RecommendNonRepeatingVisitScheduleSubscriptionSubscription;
}

// Wrappers & Helpers
function AssignTeamMachineContextProvider({
  children,
  selectedAssignees,
}: {
  children: React.ReactElement;
  selectedAssignees?: Set<string>;
  currentUserId?: string;
}) {
  const [machine, context, config] =
    useAssignTeamMachineConfiguration(selectedAssignees);

  const [, , service] = useMachine(machine, {
    ...config,
    context,
  });

  return (
    <AssignTeamMachineContext.Provider value={service}>
      {children}
    </AssignTeamMachineContext.Provider>
  );
}

function makeWrapper({
  weekStartsOnMonday = false,
  mocks,
  initialSelectedDate,
  initialSelectedEndDate,
  initialStartTime,
  initialEndTime,
  initialTeamSelection = new Set<string>(),
  initialDurationInSeconds = 3600,
}: MockSetupProps) {
  return function Wrapper({ children }: { children: React.ReactElement }) {
    const scheduleCardActor = useInterpret(scheduleCardMachine, {
      context: {
        startDate: initialSelectedDate,
        endDate: initialSelectedEndDate,
        visitDuration: initialDurationInSeconds / 60,
        startTime: initialStartTime,
        endTime: initialEndTime,
      },
      actions: {
        sendToOneOffScheduler: jest.fn(),
      },
    });

    return (
      <MockedProvider mocks={mocks}>
        <MockAccountContextProvider
          account={{
            ...DefaultAccountContext,
            calendarFirstDay: weekStartsOnMonday ? 1 : 0,
          }}
        >
          <ScheduleCardMachineContext.Provider value={scheduleCardActor}>
            <AssignTeamMachineContextProvider
              selectedAssignees={initialTeamSelection}
            >
              {children}
            </AssignTeamMachineContextProvider>
          </ScheduleCardMachineContext.Provider>
        </MockAccountContextProvider>
      </MockedProvider>
    );
  };
}

export type AvailabilitySlot = Exclude<
  RecommendNonRepeatingVisitScheduleSubscriptionSubscription["recommendNonRepeatingVisitSchedule"]["schedulingRecommendations"][number],
  undefined
>;

export interface AvailabilityResponseOptions {
  recommendations: AvailabilitySlot[];
  error?: string;
}
