import { Heading } from "@jobber/components/Heading";
import React from "react";
import type { CustomFieldConfigurationNodeFragment } from "~/utilities/API/graphql";
import styles from "./AppCustomFieldItemContent.module.css";
import { CustomFieldItemContent } from "../CustomFieldItemContent";
import { CustomFieldItemRowActions } from "../CustomFieldItemRowActions";

export interface AppCustomFieldItemProps {
  customFields: CustomFieldConfigurationNodeFragment[];
  onDelete?: (customField: CustomFieldConfigurationNodeFragment) => void;
  onArchive?: (customField: CustomFieldConfigurationNodeFragment) => void;
  onUnarchive?: (customField: CustomFieldConfigurationNodeFragment) => void;
}

export function AppCustomFieldItemContent({
  customFields,
  onDelete,
  onArchive,
  onUnarchive,
}: AppCustomFieldItemProps) {
  const app = customFields[0].app;

  if (!app) return null;

  const archived = customFields[0].archived;
  const { name, logoUrl } = app;

  return (
    <div className={styles.appCustomFieldGroupContainer}>
      <AppCustomFieldGroupInfo
        app={{
          name,
          logoUrl,
        }}
        groupActions={
          archived ? undefined : (
            <CustomFieldItemRowActions
              actions={[
                {
                  label: "Archive",
                  icon: "archive",
                  onClick: () =>
                    onArchive &&
                    onArchive(
                      customFields[0] as CustomFieldConfigurationNodeFragment,
                    ),
                },
              ]}
            />
          )
        }
      />
      {customFields.map((customField, index) => (
        <CustomFieldItemContent
          key={`CustomFieldItemContent${customField.id}`}
          customField={customField}
          moreActions={
            archived ? (
              <CustomFieldItemRowActions
                actions={[
                  {
                    label: "Unarchive",
                    icon: "archive",
                    onClick: () =>
                      onUnarchive && onUnarchive(customFields[index]),
                  },
                  {
                    label: "Delete",
                    icon: "trash",
                    onClick: () => onDelete && onDelete(customFields[index]),
                  },
                ]}
              />
            ) : undefined
          }
        />
      ))}
    </div>
  );
}

interface AppCustomFieldGroupInfoProps {
  app: {
    name: string;
    logoUrl?: string;
  };
  groupActions?: React.ReactNode;
}

function AppCustomFieldGroupInfo({
  app,
  groupActions,
}: AppCustomFieldGroupInfoProps) {
  const { name, logoUrl } = app;
  return (
    <div className={styles.appCustomFieldGroupInfoContainer}>
      <div className={styles.appCustomFieldGroupInfoName}>
        {logoUrl && (
          <div>
            <img
              src={logoUrl}
              alt={`${name} logo`}
              className={styles.appCustomFieldGroupInfoLogo}
            />
          </div>
        )}
        <Heading level={6}>{name}</Heading>
      </div>
      {groupActions && (
        <div className={styles.appCustomFieldGroupInfoMoreActions}>
          <div className={styles.appCustomFieldGroupInfoMoreActionsButton}>
            {groupActions}
          </div>
        </div>
      )}
    </div>
  );
}
