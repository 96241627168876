import { useMutation } from "@apollo/client";
import type { LocationTimersSettingsEditMutation } from "~/utilities/API/graphql";
import { LOCATION_TIMERS_SETTINGS_EDIT_MUTATION } from "./LocationTimersSettingsPage.graphql";

export function useLocationTimersSettingsEditMutation() {
  const [editMutation, { loading, error }] =
    useMutation<LocationTimersSettingsEditMutation>(
      LOCATION_TIMERS_SETTINGS_EDIT_MUTATION,
    );

  return {
    editMutation,
    loading,
    error,
  };
}
