import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailsTableTitle: {
    id: "campaigns.reportingDetails.emailsTable.title",
    defaultMessage: "All recipients",
    description: "Title of the emails table",
  },
  filteredEmailsTableTitle: {
    id: "campaigns.reportingDetails.emailsTable.title.filtered",
    defaultMessage: "Filtered recipients",
    description: "Title of the emails table when the contents is filtered",
  },
  emailsTableRecipientHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.recipients",
    defaultMessage: "Recipient",
    description: "Recipient header in the emails table",
  },
  emailsTableDeliveredHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.delivered",
    defaultMessage: "Delivered",
    description: "Delivered header in the emails table",
  },
  emailsTableOpenedHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.opened",
    defaultMessage: "Opened",
    description: "Opened header in the emails table",
  },
  emailsTableClickedHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.clicked",
    defaultMessage: "Clicked",
    description: "Clicked header in the emails table",
  },
  emailsTableUnsubscribedHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.unsubscribed",
    defaultMessage: "Unsubscribed",
    description: "Unsubscribed header in the emails table",
  },
  emailsTableJobsHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.jobs",
    defaultMessage: "Jobs",
    description: "Jobs header in the emails table",
  },
  emailsTableJobsHeaderWithColon: {
    id: "campaigns.reportingDetails.emailsTable.header.emailsTableJobsHeaderWithColon",
    defaultMessage: "Jobs:",
    description: "Jobs header in the emails table on mobile",
  },
  emailsTableRevenueHeader: {
    id: "campaigns.reportingDetails.emailsTable.header.revenue",
    defaultMessage: "Job revenue",
    description: "Revenue header in the emails table",
  },
  emailsBooleanYes: {
    id: "campaigns.reportingDetails.emailsTable.boolean.yes",
    defaultMessage: "Yes",
    description: "A truthy value used within the email table",
  },
  emailsBooleanNo: {
    id: "campaigns.reportingDetails.emailsTable.boolean.no",
    defaultMessage: "No",
    description: "A falsey value used within the email table",
  },
  emailDeliveredStatus: {
    id: "campaigns.reportingDetails.emailsTable.deliveredStatus",
    defaultMessage: "Delivered",
    description:
      "A status shown in the mobile emails table if the email is delivered",
  },
  emailOpenedStatus: {
    id: "campaigns.reportingDetails.emailsTable.openedStatus",
    defaultMessage: "Opened",
    description:
      "A status shown in the mobile emails table if the email has been opened",
  },
  emailClickedStatus: {
    id: "campaigns.reportingDetails.emailsTable.clickedStatus",
    defaultMessage: "Clicked",
    description:
      "A status shown in the mobile emails table if the email has been actioned",
  },
  emailUnsubscribedStatus: {
    id: "campaigns.reportingDetails.emailsTable.unsubscribedStatus",
    defaultMessage: "Unsubscribed",
    description:
      "A status shown in the mobile emails table if the SC unsubscribed from the email",
  },
  emailSearchRecipientPlaceholder: {
    id: "campaigns.reportingDetails.emailsTable.search.recipient.placeholder",
    defaultMessage: "Search recipients...",
    description: "Placeholder text for the recipient search input",
  },
  emailFilterPlaceholder: {
    id: "campaigns.reportingDetails.emailsTable.filter.placeholder",
    defaultMessage: "Filter",
    description: "Placeholder text for the filter input",
  },
  noResultsFound: {
    id: "campaigns.reportingDetails.emailsTable.noResults",
    defaultMessage: "No results for selected filters",
    description:
      "A message displayed in the emails table when there are no results found for the filter",
  },
  emailFilter: {
    id: "campaigns.reportingDetails.emailsTable.filter",
    defaultMessage: "Filter",
    description: "The options to filter an email list",
  },
  clearFilter: {
    id: "campaigns.reportingDetails.emailsTable.filter.clear",
    defaultMessage: "Clear filter",
    description: "Clear the filter if there are no results in the list",
  },
  jobNumber: {
    id: "campaigns.reportingDetails.jobNumber",
    defaultMessage: "#{jobNumber}{hasComma, select, true {,} other {}}",
    description: "The job number for work booked related to the campaign email",
  },
  dollarAmount: {
    id: "campaigns.reportingDetails.dollar",
    defaultMessage: `$\{amount, number, ::.00}`,
    description: "Dollar sign for money amount",
  },
  emptyJobs: {
    id: "campaigns.reportingDetails.jobs.empty",
    defaultMessage: `-`,
    description:
      "Value shown if there are no jobs scheduled associated to the client email",
  },
  noRevenue: {
    id: "campaigns.reportingDetails.revenue.zero",
    defaultMessage: `$0.00`,
    description:
      "Value shown if there is no revenue associated to the client email",
  },
  mobileSortRecipientAtoZ: {
    id: "campaigns.reportingDetails.sort.atoz",
    defaultMessage: `Recipients (A-Z)`,
    description: "Sort recipients form A-Z to mobile viewport",
  },
  mobileSortRecipientZtoA: {
    id: "campaigns.reportingDetails.sort.ztoa",
    defaultMessage: `Recipients (Z-A)`,
    description: "Sort recipients form Z-A to mobile viewport",
  },
});
