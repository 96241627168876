import { Bridges } from "jobber/bridges/Bridges";

const types = {
  notice: "flash--notice",
  warning: "flash--warning",
  error: "flash--error",
};

// DO NOT COPY ANY OF THIS

declare global {
  interface Window {
    updateGlobalBanner(
      newVisible: boolean,
      newMessage?: string,
      newUrl?: string,
      newType?: string,
    ): void;
  }
}

export function showGlobalUserLimitBanner(message: string, url: string) {
  let type: keyof typeof types = "notice";
  const maxWarningDays = 4;
  const daysLeftMatch = message.match(/(\d+) days/);

  if (daysLeftMatch) {
    if (parseInt(daysLeftMatch[1], 10) >= maxWarningDays) {
      type = "warning";
    } else {
      type = "error";
    }
  }

  if (message.match(/tomorrow/) || message.match(/today/)) {
    type = "error";
  }

  try {
    Bridges.callBridgeMethod(
      "updateGlobalBanner",
      true,
      message,
      url,
      types[type],
    );
  } catch (error) {
    /**
     * Banner isn't always defined. This banner is situationally rendered by
     * rails depending on the particular user that is logged in.
     *
     * If it is absent we can safely ignore this.
     */
  }
}

export function hideGlobalUserLimitBanner() {
  try {
    Bridges.callBridgeMethod("updateGlobalBanner", false);
  } catch (error) {
    /**
     * Banner isn't always defined. This banner is situationally rendered by
     * rails depending on the particular user that is logged in.
     *
     * If it is absent we can safely ignore this.
     */
  }
}
