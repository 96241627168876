import { differenceInSeconds } from "date-fns";
import type { JobCostTimeSheetEntryFragment } from "~/utilities/API/graphql";
import type { TimeSheetDataEntry } from "jobber/workOrders/components/JobCost/components/LabourTable/types";
import {
  getAccountZonedTime,
  unroundedSecondsToHours,
} from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";

// Convert time sheet data into easier to manage form (TimeSheetDataEntry)
export function processTimeSheetEntries(
  timeSheetEntries: JobCostTimeSheetEntryFragment[],
  accountTimezone: string,
): TimeSheetDataEntry[] {
  return timeSheetEntries.map(timeSheetEntry => {
    const { note, startAt, finalDuration, labourRate, user, id, ticking } =
      timeSheetEntry;

    const finalDurationInHours = unroundedSecondsToHours(finalDuration);

    return {
      id,
      team: user?.name.full,
      note,
      startAt,
      date: getTimeSheetDate(startAt, finalDuration, accountTimezone),
      durationSeconds: finalDuration,
      cost: (labourRate && finalDurationInHours * labourRate) || 0,
      labourRate,
      ticking,
      assignedTo: user?.id,
    };
  });
}

function getTimeSheetDate(
  startAt: string,
  finalDuration: number,
  accountTimezone: string,
) {
  const startDate = new Date(startAt);
  const endDate = new Date(startAt);

  endDate.setSeconds(endDate.getSeconds() + finalDuration);

  return {
    startAt: getAccountZonedTime(startDate, accountTimezone),
    endAt:
      finalDuration > 0
        ? getAccountZonedTime(endDate, accountTimezone)
        : undefined,
  };
}

export function getCurrentEndAt(
  startAt: Date,
  currentDate: Date,
  accountTimezone: string,
) {
  const startDate = new Date(startAt);
  const newDate = new Date(
    currentDate.toLocaleString("en-US", { timeZone: accountTimezone }),
  );

  return differenceInSeconds(
    getAccountZonedTime(newDate, accountTimezone),
    getAccountZonedTime(startDate, accountTimezone),
  );
}
