import { defineMessages } from "react-intl";

export const messages = defineMessages({
  description: {
    id: "lineItemInput.LineItemDescription.description",
    defaultMessage: "Description",
    description: "label on description input for line item",
  },
  save: {
    id: "lineItemInput.LineItemSave.save",
    defaultMessage: "Save",
    description: "label on save button for line item",
  },
  delete: {
    id: "lineItemInput.LineItemDelete.delete",
    defaultMessage: "Delete",
    description: "label on delete button for line item",
  },
  totalPrice: {
    id: "lineItemInput.LineItemTotalPrice.totalPrice",
    defaultMessage: "Total",
    description: "label on total price input for line item",
  },
  quantity: {
    id: "lineItemInput.LineItemQuantity.quantity",
    defaultMessage: "Quantity",
    description: "label on quantity input for line item",
  },
  quantityMinimumValueError: {
    id: "lineItemInput.LineItemQuantity.quantityMinimumValueError",
    defaultMessage: "Quantity must be more than -10 million",
    description: "Error message for minimum quantity value",
  },
  quantityMaximumValueError: {
    id: "lineItemInput.LineItemQuantity.quantityMaximumValueError",
    defaultMessage: "Quantity must be less than 10 million",
    description: "Error message for maximum quantity value",
  },
  unitPrice: {
    id: "lineItemInput.LineItemUnitPrice.unitPrice",
    defaultMessage: "Unit price",
    description: "label on unitPrice input for line item",
  },
  unitPriceMinimumValueError: {
    id: "lineItemInput.LineItemUnitPrice.unitPriceMinimumValueError",
    defaultMessage: "Unit price must be more than -10 billion",
    description: "Error message for minimum unitPrice value",
  },
  unitPriceMaximumValueError: {
    id: "lineItemInput.LineItemUnitPrice.unitPriceMaximumValueError",
    defaultMessage: "Unit price must be less than 10 billion",
    description: "Error message for maximum unitPrice value",
  },
  unitCost: {
    id: "lineItemInput.LineItemUnitPrice.unitCost",
    defaultMessage: "Unit cost",
    description: "label on unitCost input for line item",
  },
  unitCostMinimumValueError: {
    id: "lineItemInput.LineItemUnitPrice.unitCostMinimumValueError",
    defaultMessage: "Unit cost must be more than -10 billion",
    description: "Error message for minimum unitCost value",
  },
  unitCostMaximumValueError: {
    id: "lineItemInput.LineItemUnitPrice.unitCostMaximumValueError",
    defaultMessage: "Unit cost must be less than 10 billion",
    description: "Error message for maximum unitCost value",
  },
});
