import React, { useEffect } from "react";
import { useQuoteDetailReportFilters } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/hooks/useQuoteDetailReportFilters";
import { useReportData } from "~/jobber/features/Reporting/components/Report/hooks/useReportData";
import { QuoteDetailReport } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/QuoteDetailReport";
import { useReportPagination } from "~/jobber/features/Reporting/components/Report/hooks/useReportPagination";
import type {
  QuoteDetailExportMutation,
  QuoteDetailFilterInput,
  QuoteDetailQuery,
  QuoteDetailQueryVariables,
  QuoteDetailSortKey,
} from "~/utilities/API/graphqlFranchiseManagement";
import { useExportCsvMutation } from "~/jobber/features/Reporting/components/Report/hooks/useExportCsvMutation";
import {
  QUOTE_DETAIL_EXPORT_MUTATION,
  QUOTE_DETAIL_QUERY,
} from "jobber/franchise/features/Reporting/views/QuoteDetailReport/QuoteDetailReport.graphql";
import { useReportSorting } from "~/jobber/features/Reporting/components/Report/hooks/useReportSorting";
import type { QuoteDetailData, QuoteDetailTotalsData } from "./types";
import { DEFAULT_SORT_KEY } from "./constants";

export function QuoteDetailReportLoader() {
  const {
    pageState,
    resetPagination,
    getPaginationProps,
    getLastCursorOnPage,
  } = useReportPagination({});

  const { formatSort, reportSortingState, handleSortChange } =
    useReportSorting<QuoteDetailSortKey>(DEFAULT_SORT_KEY, resetPagination);

  const { fetchData, data, loading, error } = useReportData<
    QuoteDetailQuery,
    QuoteDetailQueryVariables
  >(QUOTE_DETAIL_QUERY);

  const {
    fetchExport,
    data: exportData,
    error: exportError,
  } = useExportCsvMutation<
    QuoteDetailExportMutation,
    QuoteDetailFilterInput,
    object
  >(QUOTE_DETAIL_EXPORT_MUTATION);

  const { reportFilterState, canSearch, formatFilter, getFilterProps } =
    useQuoteDetailReportFilters(resetPagination);

  const formatAndFetchData = () => {
    fetchData({
      sort: formatSort(reportSortingState),
      filter: formatFilter(reportFilterState),
      first: pageState.pageSize,
      after: getLastCursorOnPage(pageState),
    });
  };

  useEffect(() => {
    if (canSearch) {
      formatAndFetchData();
    }
  }, [canSearch, reportFilterState.franchiseeNameSearchFilter]);

  useEffect(() => {
    formatAndFetchData();
  }, [
    reportSortingState,
    reportFilterState.dateFilterType,
    reportFilterState.dateFilterValues,
    pageState,
  ]);

  return (
    <QuoteDetailReport
      data={data?.quoteDetail.nodes as QuoteDetailData[]}
      loading={loading}
      error={error?.message || exportError?.message}
      totalsData={data?.quoteDetailTotals as QuoteDetailTotalsData}
      filterProps={getFilterProps()}
      sortingProps={{
        manualSorting: true,
        state: reportSortingState,
        onSortingChange: handleSortChange,
      }}
      paginationProps={getPaginationProps(data?.quoteDetail?.totalCount)}
      exportProps={{
        exportFunction: async () =>
          fetchExport({
            filter: formatFilter(reportFilterState),
          }),
        reportIsExported:
          exportData?.exportQuoteDetailCsv?.response != undefined,
      }}
    />
  );
}
