import React, { type MutableRefObject, useRef, useState } from "react";
import { Icon } from "@jobber/components/Icon";
import { Content } from "@jobber/components/Content";
import { Popover } from "@jobber/components/Popover";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import type { TooltipData } from "jobber/dataVisualizations/types";
import { BillingStrategy } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import styles from "./RecurringJobTooltip.module.css";
import { messages } from "./messages";
import type { JobCostRecord } from "../../JobCostHeader";

export interface RecurringJobTooltipProps {
  tooltipData: TooltipData;
  titleKey: string;
  dateRange?: string;
  billingType: BillingStrategy;
  jobCosting?: JobCostRecord;
  lineItems: LineItem[];
}

export function RecurringJobTooltip({
  tooltipData,
  titleKey,
  dateRange,
  billingType,
  jobCosting,
  lineItems,
}: RecurringJobTooltipProps) {
  const { currencySymbol } = useAccount();
  const popoverDivRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [showPopover, setShowPopover] = useState(false);

  const isOverrideDatePresent = lineItems.some(
    item => Array.isArray(item.overrideDates) && item.overrideDates.length > 0,
  );

  function togglePopover() {
    setShowPopover(value => !value);
  }

  if (titleKey !== "profitBarRevenue" && titleKey !== "profitBarLineItemCost") {
    return <></>;
  }
  return (
    <div ref={popoverDivRef} className={styles.popoverWrapper}>
      <div
        role="button"
        tabIndex={0}
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      >
        <Icon name="help" size="small" />
        <Popover
          attachTo={popoverDivRef}
          open={showPopover}
          onRequestClose={togglePopover}
          preferredPlacement="top"
        >
          <Content>
            <TooltipComponent
              tooltipData={tooltipData}
              currencySymbol={currencySymbol}
              titleKey={titleKey}
              dateRange={dateRange}
              billingType={billingType}
              jobCosting={jobCosting}
              isOverrideDatePresent={isOverrideDatePresent}
            />
          </Content>
        </Popover>
      </div>
    </div>
  );
}

function TooltipComponent({
  tooltipData,
  currencySymbol,
  titleKey,
  dateRange,
  billingType,
  jobCosting,
  isOverrideDatePresent,
}: {
  tooltipData: TooltipData;
  currencySymbol: string;
  titleKey: string;
  dateRange?: string;
  billingType: BillingStrategy;
  jobCosting?: JobCostRecord;
  isOverrideDatePresent: boolean;
}): JSX.Element {
  const { timezone } = useAccount();
  const { formatMessage } = useIntl();

  const popoverTitle =
    titleKey === "profitBarRevenue"
      ? formatMessage(messages.lineItemPriceTitle)
      : formatMessage(jobCostMessages.lineItemCostLowercase);

  const year = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    year: "numeric",
  });

  const visitOrInvoiceTitle =
    billingType === BillingStrategy.VISIT_BASED
      ? formatMessage(messages.visitsTitle)
      : formatMessage(messages.invoiceReminders);

  const visitOrInvoiceNumber =
    (billingType === BillingStrategy.VISIT_BASED
      ? jobCosting?.visitsCount
      : jobCosting?.invoiceRemindersCount) || 0;

  const lineItemNumber =
    typeof tooltipData?.data.amount === "number" && visitOrInvoiceNumber !== 0
      ? tooltipData?.data.amount / visitOrInvoiceNumber || 0
      : 0;

  const footerTitle =
    titleKey === "profitBarRevenue"
      ? formatMessage(messages.revenueTitle)
      : formatMessage(jobCostMessages.lineItemCostLowercase);

  const footerAmount = visitOrInvoiceNumber * lineItemNumber;

  const getLineItemNumberOrVaries = () => {
    if (isOverrideDatePresent && lineItemNumber !== 0) {
      return formatMessage(messages.variesKeyword);
    } else {
      return getCurrencyFormat(lineItemNumber, currencySymbol);
    }
  };

  return (
    <table className={styles.recurringTooltipTable}>
      <tbody>
        <tr>
          <td>
            {dateRange}, {year}
          </td>
        </tr>
        <tr>
          <th>{popoverTitle}</th>
          <th>{getLineItemNumberOrVaries()}</th>
        </tr>
        <tr>
          <th>{visitOrInvoiceTitle}</th>
          <th>x {visitOrInvoiceNumber?.toString()}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>{footerTitle}</th>
          <th>{formatCurrency(footerAmount, currencySymbol, 0)}</th>
        </tr>
      </tfoot>
    </table>
  );
}

function getCurrencyFormat(number: number, currencySymbol: string) {
  return formatCurrency(Math.ceil(number), currencySymbol, 0);
}
