import type {
  AllClientsCriteriaInput,
  PastClientsCriteriaInput,
  Segment,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";

export type SegmentCriteriaReducerAction =
  | {
      type: "setSelectedSegmentType";
      payload: { segmentType: Segment; dirty?: boolean };
    }
  | {
      type: "setPastClientsSegmentCriteria";
      payload: {
        segmentCriteria: PastClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "setAllClientsSegmentCriteria";
      payload: {
        segmentCriteria: AllClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "setUpcomingClientsSegmentCriteria";
      payload: {
        segmentCriteria: UpcomingClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "resetSegmentCriteria";
      payload: {
        segmentType:
          | Segment.ALL_CLIENTS
          | Segment.PAST_CLIENTS
          | Segment.UPCOMING_CLIENTS;
        dirty?: boolean;
      };
    }
  | {
      type: "setDirty";
    }
  | { type: "setNotDirty" };

export interface SegmentCriteriaReducerState {
  selectedSegmentType: Segment;
  allClientsSegmentCriteria?: AllClientsCriteriaInput;
  pastClientsSegmentCriteria: PastClientsCriteriaInput;
  upcomingClientsSegmentCriteria: UpcomingClientsCriteriaInput;
  isDirty: boolean;
}

export function segmentCriteriaReducer(
  value: SegmentCriteriaReducerState,
  action: SegmentCriteriaReducerAction,
): SegmentCriteriaReducerState {
  switch (action.type) {
    case "setSelectedSegmentType":
      return {
        ...value,
        selectedSegmentType: action.payload.segmentType,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setAllClientsSegmentCriteria":
      return {
        ...value,
        allClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setPastClientsSegmentCriteria":
      return {
        ...value,
        pastClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setUpcomingClientsSegmentCriteria":
      return {
        ...value,
        upcomingClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setDirty":
      return {
        ...value,
        isDirty: true,
      };
    case "setNotDirty":
      return { ...value, isDirty: false };
    default:
      return value;
  }
}
