import { defineMessages } from "react-intl";

export const messages = defineMessages({
  achAuthorizationDisclaimer: {
    id: "clientHubJobberPayments.addBankAccount.authorizationDisclaimer",
    defaultMessage:
      "I have signed authorization from my customer to use their payment details. I accept full responsibility for the accuracy of the information entered.",
    description: "Authorization disclaimer for ACH bank account",
  },
});
