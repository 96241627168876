declare namespace JobberOnline {
  let constants: {
    googleCloudPlatformKey: string;
    graphqlEndpoint: string;
    graphqlAnchorEndpoint: string;
    graphqlBunkerEndpoint: string;
    graphqlFranchiseManagementEndpoint: string;
    graphqlFranchiseEndpoint: string;
    graphqlExternalEndpoint: string;
    actionCableUrl: string;
    paymentIntegration: string;
    stripeKey: string;
    vehicleTrackersPresent: boolean;
  };
  interface Feature {
    available: boolean;
    enabled: boolean;
  }
  let features: {
    clientReminders: Feature;
    assessmentReminders: Feature;
    assessmentBookingConfirmation: Feature;
    visitReminders: Feature;
    followUps: Feature;
    invoiceFollowUps: Feature;
    quoteFollowUps: Feature;
    smsMessaging: Feature;
    bookingNotification: Feature;
    chemicalTracking: Feature;
    workRequests: Feature;
    jobForms: Feature;
  };

  let featureFlags: {
    quoteMargins: boolean;
    advancedQuotingFeatureEnabled: boolean;
  };

  // available in quotes controller
  let analytics: {
    accountIsNew: boolean;
  };

  let canUseAutomations: boolean;
}

export const jobberOnline = JobberOnline;
