import React, { useEffect } from "react";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type {
  TrackFeatureUpgradeMutation,
  TrackFeatureUpgradeMutationVariables,
  UpgradeFeature,
} from "~/utilities/API/graphql";
import styles from "./FeatureLandingPage.module.css";
import { messages } from "./messages";
import { GrowPlanCard } from "./components/GrowPlanCard/GrowPlanCard";
import { CorePlanCard } from "./components/CorePlanCard/CorePlanCard";
import { FeaturesHighlightsSection } from "./components/FeaturesHighlightsSection";
import { TRACK_FEATURE_UPGRADE } from "./TrackFeatureUpgrade.graphql";
import { readableUpgradeFeature } from "./readableUpgradeFeature";
import { useLandingPagePlan } from "./useLandingPagePlan";

export type Plan = "Grow" | "Connect" | "Core";
interface FeatureLandingPageProps {
  feature: UpgradeFeature;
  featurePlan: Plan;
  mainHeading: string;
  subHeading: string;
  firstImage: string;
  secondImage: string;
  firstImageAlt: string;
  secondImageAlt: string;
  featuresAndDescriptions: {
    heading: string;
    description: string;
  }[];
  trialAvailable?: boolean;
  onStartTrial?: () => void;
  learnAboutFeaturesSection?: {
    mainHeading: string;
    firstImage: string;
    secondImage: string;
    firstImageAlt: string;
    secondImageAlt: string;
    featuresAndDescriptions: {
      heading: string;
      description: string;
      link: string;
    }[];
  };
}

export function FeatureLandingPage({
  feature,
  featurePlan,
  mainHeading,
  subHeading,
  firstImage,
  secondImage,
  firstImageAlt,
  secondImageAlt,
  featuresAndDescriptions,
  learnAboutFeaturesSection,
  trialAvailable,
  onStartTrial,
}: FeatureLandingPageProps) {
  useEffect(() => {
    Amplitude.TRACK_EVENT("Viewed Page", {
      source: "feature_landing_page",
      type: readableUpgradeFeature[feature],
    });
  }, [feature]);

  const { formatMessage } = useIntl();
  const { landingPagePlan: plan } = useLandingPagePlan(featurePlan);

  const [trackFeatureUpgrade] = useMutation<
    TrackFeatureUpgradeMutation,
    TrackFeatureUpgradeMutationVariables
  >(TRACK_FEATURE_UPGRADE);

  return (
    <div className={styles.landingPageContainer}>
      <div className={styles.landingPage}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>
            <Heading level={3}>
              <Emphasis variation="highlight">
                {formatMessage(messages.featureLabel, { featurePlan })}
              </Emphasis>
            </Heading>
            <Heading level={1}>{mainHeading}</Heading>
            <Text size="large" align="center">
              {subHeading}
            </Text>
          </div>
        </div>
        <div className={styles.firstImageGridContainer}>
          <div className={styles.firstImageContainer}>
            <img src={firstImage} alt={firstImageAlt} />
          </div>
        </div>
        <div
          className={classNames(styles.gridContainer, {
            [styles.gridContainerTwoFeatures]:
              featuresAndDescriptions.length < 3,
          })}
        >
          <div
            className={classNames(styles.secondImageContainer, {
              [styles.secondImageContainerTwoFeatures]:
                featuresAndDescriptions.length < 3,
            })}
          >
            <img src={secondImage} alt={secondImageAlt} />
          </div>
          <div
            className={classNames(styles.firstFeature, {
              [styles.firstFeatureTwoFeatures]:
                featuresAndDescriptions.length < 3,
            })}
          >
            <Heading level={2}>{featuresAndDescriptions[0].heading}</Heading>
            <Text size="large">{featuresAndDescriptions[0].description}</Text>
          </div>
          <div
            className={classNames(styles.secondFeature, {
              [styles.secondFeatureTwoFeatures]:
                featuresAndDescriptions.length < 3,
            })}
          >
            <Heading level={2}>{featuresAndDescriptions[1].heading}</Heading>
            <Text size="large">{featuresAndDescriptions[1].description}</Text>
          </div>
          {featuresAndDescriptions.length > 2 && (
            <div className={styles.thirdFeature}>
              <Heading level={2}>{featuresAndDescriptions[2].heading}</Heading>
              <Text size="large">{featuresAndDescriptions[2].description}</Text>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.planSectionContainer, {
          [styles.planSectionContainerGradientBackground]: !trialAvailable,
          [styles.planSectionContainerTrialAvailable]: trialAvailable,
        })}
      >
        <div className={styles.planSectionGridContainer}>
          <div className={styles.discoverHeading}>
            <Heading level={1}>
              {formatMessage(messages.buttonSectionHeading, {
                trialAvailable: !!trialAvailable,
                emphasis: (
                  <Emphasis variation="highlight">{featurePlan}</Emphasis>
                ),
              })}
            </Heading>
          </div>
          <div className={styles.buttonsContainer}>
            {trialAvailable ? (
              <div className={styles.startTrialButton}>
                <Button
                  label={formatMessage(messages.startTrialLabel, {
                    featurePlan,
                  })}
                  onClick={handleStartTrialClick}
                  size="large"
                />
              </div>
            ) : (
              <div className={styles.upgradeNowButton}>
                <Button
                  size="large"
                  label={formatMessage(messages.upgradeNowLabel)}
                  onClick={trackUpgradeNowClick}
                />
              </div>
            )}

            <div className={styles.chatWithUsButton}>
              <Button
                size="large"
                type="secondary"
                label={formatMessage(messages.chatWithUsLabel)}
                onClick={openIntercom}
              />
            </div>
          </div>
          {!trialAvailable && (
            <div className={styles.planCard}>
              {featurePlan === "Grow" && <GrowPlanCard />}
              {featurePlan === "Core" && <CorePlanCard />}
            </div>
          )}
        </div>
      </div>
      {learnAboutFeaturesSection && (
        <div className={styles.featuresHighlightsContainer}>
          <div className={styles.featuresHighlightsGridContainer}>
            <FeaturesHighlightsSection
              mainHeading={learnAboutFeaturesSection.mainHeading}
              firstImage={learnAboutFeaturesSection.firstImage}
              secondImage={learnAboutFeaturesSection.secondImage}
              firstImageAlt={learnAboutFeaturesSection.firstImageAlt}
              secondImageAlt={learnAboutFeaturesSection.secondImageAlt}
              featuresAndDescriptions={[
                learnAboutFeaturesSection.featuresAndDescriptions[0],
                learnAboutFeaturesSection.featuresAndDescriptions[1],
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );

  function openIntercom() {
    Intercom.EXECUTE("showNewMessage");
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "chat_with_us",
      source: "feature_landing_page",
      type: readableUpgradeFeature[feature],
    });
  }

  async function trackUpgradeNowClick() {
    await $.getScript(
      formatMessage(messages.upgradeNowUrl, {
        featurePlan: featurePlan.toLowerCase(),
        planIdentifier: plan?.planIdentifier,
      }),
    );
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "upgrade_now",
      source: "feature_landing_page",
      type: readableUpgradeFeature[feature],
    });

    await handleFeatureUpgradeMutation();
  }

  function handleStartTrialClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "start_free_trial",
      source: "feature_landing_page",
      type: readableUpgradeFeature[feature],
    });
    onStartTrial && onStartTrial();
  }

  async function handleFeatureUpgradeMutation() {
    const payload = {
      variables: {
        feature: feature,
      },
    };

    await trackFeatureUpgrade(payload);
  }
}
