import React from "react";
import { Redirect } from "react-router-dom";
import { messages } from "./messages";
import { Routes, type SetupGuideStepProps } from "../../types";
import { BuildStep } from "../BuildStep";
import { ConnectImage } from "../../images";

export function ConnectStep(stepProps: SetupGuideStepProps): JSX.Element {
  const stepDetails = {
    title: messages.title,
    subtitle: messages.subtitle,
    description: messages.description,
    subtext: messages.subtext,
    image: {
      src: ConnectImage.src,
      alt: ConnectImage.alt,
    },
    primaryButton: {
      label: messages.primaryButton,
      action: () => {
        window.location.href =
          "/automated_reviews/google_business/connect?post_oauth_redirect=%2Freviews%2Fprofile";
      },
    },
  };

  if (stepProps.isConnected) {
    return <Redirect to={Routes.connected} />;
  }

  return (
    <BuildStep navigation={stepProps.navigation} stepDetails={stepDetails} />
  );
}
