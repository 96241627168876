import { defineMessages } from "react-intl";

export const messages = defineMessages({
  settingsTitle: {
    id: "WorkItems.SelfServeBookingsSettings.title",
    defaultMessage: "Online Booking",
    description: "Title displayed for switch feature",
  },
  settingsDescription: {
    id: "WorkItems.SelfServeBookingsSettings.description",
    defaultMessage: "These settings are only available for online booking.",
    description: "Description displayed for switch feature",
  },
  linkToSelfServeBookingsServicesSettings: {
    id: "WorkItems.SelfServeBookingsSettings.linkToSelfServeBookingsServicesSettings",
    defaultMessage:
      "[Manage in online booking services](/online_booking_settings/services)",
    description: "Description displayed for switch feature",
  },
  duration: {
    id: "WorkItems.SelfServeBookingsSettings.duration",
    defaultMessage: "Service Duration",
    description: "Title for field selection of service duration",
  },
  serviceType: {
    id: "WorkItems.SelfServeBookingsSettings.serviceType",
    defaultMessage: "Book service as",
    description:
      "Title to indicate which type of work object you want to book the service as",
  },
});
