import { gql } from "@apollo/client";

export const PAYMENT_METHOD_SETUP_INTENT_CREATE = gql`
  mutation PaymentMethodSetupIntentCreate(
    $input: PaymentMethodSetupIntentCreateInput!
  ) {
    paymentMethodSetupIntentCreate(input: $input) {
      clientSecret
      userErrors {
        message
        path
      }
    }
  }
`;

export const PAYMENT_METHOD_CREATE = gql`
  mutation PaymentMethodCreate(
    $input: PaymentMethodCreateInput!
    $clientId: EncodedId!
  ) {
    paymentMethodCreate(input: $input, clientId: $clientId) {
      paymentMethod {
        uuid
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD_QUERY = gql`
  query AddPaymentMethod($clientId: EncodedId!) {
    client(id: $clientId) {
      billingAddress {
        city
        country
        postalCode
        province
        street2
        longitude
        latitude
        street
        street1
      }
      firstName
      lastName
    }
  }
`;
