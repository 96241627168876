import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customizeBranding: {
    id: "dashboard.components.customizeBrandingInsight.customizeBranding",
    defaultMessage: "Customize your branding",
    description: "The customize branding insights card",
  },
  customizeBrandingDescription: {
    id: "dashboard.components.customizeBrandingInsight.customizeBrandingDescription  ",
    defaultMessage:
      "Jobber should look and feel like your business. Manage what your customers see when you send them quotes, invoices or other communications from Jobber.",
    description: "The description of customize branding insights card",
  },
  customizeBrandingAction: {
    id: "dashboard.components.doubleBookingInsight.customizeBrandingAction",
    defaultMessage: "Customize Branding",
    description: "The action button to customize your branding",
  },
});
