import { defineMessages } from "react-intl";

export const messages = defineMessages({
  barGraphAriaLabel: {
    id: "workOrders.jobCost.jobProfitBarGraph.responsiveBarAriaLabel",
    defaultMessage: "Responsive Profit Bar Graph",
    description: "Aria label for @nivo ResponsiveBar component",
  },
  totalCost: {
    id: "workOrders.jobCost.jobProfitBarGraph.profitBarCost",
    defaultMessage: "Total cost",
    description: "Cost label for Profit Bar Graph",
  },
});
