import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./LiteToCoreTrialInTrialCard.module.css";

export function LiteToCoreTrialInTrialCard() {
  const ctaName = "lite_to_core_trial_highlights_cta";
  const [showMobileCard, setShowMobileCard] = useState(true);

  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: ctaName,
      type: "in_trial_card",
      source: "home",
    });
  }, []);

  return showMobileCard ? (
    <Card title="What to explore in core">
      <Content>
        <Text>
          Track your workflow here on the home page. When you&apos;re ready, hit
          Got It to start scheduling jobs in your calendar!
        </Text>
        <div className={styles.buttonsContainer}>
          <div className={styles.dismissButton}>
            <Button
              label="Dismiss"
              type="secondary"
              variation="subtle"
              onClick={handleDismissButtonClick}
            />
          </div>
          <Button
            label="Got it"
            variation="learning"
            onClick={handleGotItButtonClick}
          />
        </div>
      </Content>
    </Card>
  ) : (
    <></>
  );

  function handleDismissButtonClick() {
    setShowMobileCard(false);
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      name: ctaName,
      type: "in_trial_card",
    });
  }

  function handleGotItButtonClick() {
    setShowMobileCard(false);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: ctaName,
      type: "in_trial_card",
    });
  }
}
