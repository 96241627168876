import React from "react";
import type { CivilTime } from "@std-proposal/temporal";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Cell, Header, Row, Table } from "@jobber/components/Table";
import { Heading } from "@jobber/components/Heading";
import type { ArrivalWindow } from "jobber/google_lsa/MerchantConfiguration";
import styles from "./ArrivalWindowCard.module.css";
import { EditArrivalWindows } from "./EditArrivalWindows";

export interface ArrivalWindowCardProps {
  loading: boolean;
  windows: ArrivalWindow[];
  bookingBufferHours: number;
  onSaveWindows(windows: ArrivalWindow[]): Promise<void>;
  onSaveBookingBuffer(bookingBufferHours: number): Promise<void>;
}

export function ArrivalWindowCard({
  loading,
  windows,
  bookingBufferHours,
  onSaveWindows,
  onSaveBookingBuffer,
}: ArrivalWindowCardProps) {
  return (
    <Card title="Step 2: Set Your Availability">
      <Content>
        <div className={styles.arrivalWindowLayout}>
          <div className={styles.arrivalWindowLayout__editColumn}>
            {loading ? (
              <></>
            ) : (
              <EditArrivalWindows
                initialWindows={windows}
                initialBookingBufferHours={bookingBufferHours}
                onSaveWindows={onSaveWindows}
                onSaveBookingBuffer={onSaveBookingBuffer}
              />
            )}
          </div>
          <div className={styles.arrivalWindowLayout__introColumn}>
            <Content spacing="large">
              <Content spacing="small">
                <Heading level={4}>Minimum Lead Time</Heading>
                <Text>
                  Specify how many hours in advance an arrival window becomes
                  unavailable.
                </Text>
              </Content>
              <Content spacing="small">
                <Heading level={4}>Arrival Windows</Heading>
                <Text>
                  Choose the days and times customers will see as available for
                  bookings.
                </Text>
              </Content>
            </Content>
          </div>
          <div className={styles.arrivalWindowLayout__tableColumn}>
            {loading ? <></> : <ArrivalWindowCardTable windows={windows} />}
          </div>
        </div>
      </Content>
    </Card>
  );
}

interface ArrivalWindowCardTableProps {
  windows: ArrivalWindow[];
}

function ArrivalWindowCardTable({ windows }: ArrivalWindowCardTableProps) {
  return (
    <Table>
      <Header>
        <Cell>Arrival Window</Cell>
        <Cell>Days of the Week</Cell>
      </Header>
      <tbody>
        {windows.map((window, index) => {
          return (
            <Row key={index}>
              <Cell>{windowTimeString(window)}</Cell>
              <Cell>{windowDayString(window)}</Cell>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
}

function windowTimeString(window: ArrivalWindow): string {
  function toFriendlyString(time: CivilTime) {
    const period: string = time.hour > 11 ? "PM" : "AM";
    const friendlyHour = time.hour > 12 ? time.hour % 12 : time.hour;
    const friendlyMinute = time.minute.toString().padStart(2, "0");
    return `${friendlyHour}:${friendlyMinute} ${period}`;
  }
  return `${toFriendlyString(window.startAt)} -
    ${toFriendlyString(window.endAt)}`;
}

function windowDayString(window: ArrivalWindow): string {
  function toFriendlyDate(dayName: string) {
    return dayName.substr(0, 1).toUpperCase() + dayName.substr(1, 2);
  }

  const activeDays = Object.entries(window.dayOfWeekMap)
    .filter(day => {
      return day[1];
    })
    .map(day => {
      return day[0];
    });

  return activeDays.slice(1).reduce((previousValue, currentDay) => {
    return `${previousValue}, ${toFriendlyDate(currentDay)}`;
  }, toFriendlyDate(activeDays[0]));
}
