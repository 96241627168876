import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CAMPAIGN_CREATE_ALL_CLIENTS = gql`
  mutation createAllClientsSegmentCampaign(
    $input: AllClientsCampaignInput = { criteria: {} }
  ) {
    campaignCreateAllClients(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
