import { Icon } from "@jobber/components/Icon";
import React, { type PropsWithChildren } from "react";
// eslint-disable-next-line import/no-internal-modules
import { translations } from "./translations";
import styles from "./SecurityAssuranceWrapper.module.css";

export function SecurityAssuranceWrapper(
  props: PropsWithChildren<{
    companyName: string;
    show?: boolean;
    haveMargins: boolean;
  }>,
) {
  const { show, children } = props;
  if (!show) return <>{children}</>;

  return (
    <SecurityAssurance
      companyName={props.companyName}
      haveMargins={props.haveMargins}
    >
      {children}
    </SecurityAssurance>
  );
}

const replaceCompanyName = (text: string, name: string) => {
  return text.replace(/\{companyName\}/g, name);
};

function SecurityAssurance(
  props: PropsWithChildren<{ companyName: string; haveMargins: boolean }>,
) {
  const { children, companyName } = props;
  const securityAssuranceTextsStyles = [
    styles.securityAssuranceTexts,
    props.haveMargins ? "" : styles.noSideMargin,
  ].join(" ");

  const t = translations();

  return (
    <>
      <div className={styles.securityAssuranceContainer}>
        <div className={securityAssuranceTextsStyles}>
          <h5 className="headingFive u-textRegular">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon name="lock" />
              <span className="u-marginLeftSmallest">
                {t.securityAssuranceTitle}
              </span>
            </div>
          </h5>
          <p className="paragraph u-textSmaller u-lineHeightBase">
            <em>
              {replaceCompanyName(t.securityAssuranceMessage, companyName)}
            </em>
          </p>
        </div>
        {children}
      </div>
    </>
  );
}
