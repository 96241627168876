import { gql } from "@apollo/client";
import { ReferralMessagingModalFields } from "jobber/bunker/settings/components/ReferralMessagingModal/ReferralMessagingModal.graphql";

export const SC_REFERRALS_CARD = gql`
  query SCReferralsCardData {
    feature(key: "sc_referrals") {
      id
      enabled
      available
    }
    clientHubReferralSettings {
      id
      ...ReferralMessagingModalFields
    }
  }
  ${ReferralMessagingModalFields}
`;

export const SC_REFERRALS_TOGGLE = gql`
  mutation SCReferralsToggleFeature($enabled: Boolean!) {
    toggleFeature(enabled: $enabled, key: "sc_referrals") {
      feature {
        id
        enabled
        available
      }
    }
  }
`;
