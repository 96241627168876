export const ANALYTICS_EVENTS = {
  clickedIntegrationCard: "Clicked Integration Card in Jobber App Marketplace",
  clickConnect: "Clicked Connect App",
  clickDisconnect: "Clicked Disconnect App",
  clickDisconnectConfirm: "Confirmed App Disconnect",
  clickDisconnectCancel: "Canceled App Disconnect",
  clickManageApp: "Clicked Manage App",
  interactedWithOAuth: "Interacted with OAuth",
  dialogInteraction: "Interacted with Dialog",
  clickedLearnMore: "Clicked to Learn More",
  viewedMarketplace: "Viewed Jobber App Marketplace",
  clickChoosePlan: "Clicked Choose Plan",
  clickUpgradeNow: "Clicked Upgrade Now",
  clickedAppMarketplace: "Clicked App Marketplace",
  ctaDismissed: "CTA Dismissed",
  clickedFreeTrialCTA: "Clicked Free Trial CTA",
};

export const ANALYTICS_PROPERTIES = {
  requestAnAppSource: "Submitted Request an App",
  requestAnAppModalSource: "Request an App",
  requestAnAppCancelledSource: "Cancelled Request an App",
  marketplacePageSource: "app_marketplace_page",
  appDetailsSource: "App Details",
  authorizePageSource: "authorize_page",
  marketplaceHomePageSource: "App Marketplace Home",
  appListingPageSource: "App Listing Details",
};
