// eslint-disable-next-line no-restricted-imports
import type { FieldActionsRef } from "@jobber/components/FormField";
import type Quill from "quill";
import { useEffect } from "react";

export function usePreventSearchFocus({
  quillInstance,
  formFieldActionsRef,
}: {
  quillInstance: React.MutableRefObject<Quill | null>;
  formFieldActionsRef: React.MutableRefObject<FieldActionsRef | null>;
}) {
  const quillRoot = quillInstance.current?.root;

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "/" && event.target === quillRoot) {
        const selection = quillInstance.current?.getSelection();
        const index = selection?.index || 0;
        quillInstance.current?.insertText(index, "/", "user");
        quillInstance.current?.setSelection(index + 1, 0, "user");
        formFieldActionsRef.current?.setValue(
          quillInstance.current?.getText()?.trim() || "",
        );
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [quillRoot, quillInstance, formFieldActionsRef]);
}
