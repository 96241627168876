import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import React from "react";
import type { ScheduleEnd } from "jobber/workOrders/components/Scheduler/types";

export const EndTypeSelection = (props: {
  selected: ScheduleEnd["type"];
  disabled: boolean;
  onChange(selected: ScheduleEnd["type"]): void;
}) => {
  const onChangeSelectedChip = (selected: ScheduleEnd["type"]) =>
    props.onChange(selected);

  return (
    <>
      <RadioGroup
        ariaLabel="Schedule End Type Selection"
        value={props.selected}
        onChange={onChangeSelectedChip}
      >
        <RadioOption disabled={props.disabled} label="End date" value="date" />
        <RadioOption
          disabled={props.disabled}
          label="Duration"
          value="duration"
        />
      </RadioGroup>
    </>
  );
};
