import React from "react";
import { messages } from "./messages";
import type { SetupGuideStepProps } from "../../types";
import { BuildStep } from "../BuildStep";
import { RequestsImage } from "../../images";

export function CompleteSetup(stepProps: SetupGuideStepProps): JSX.Element {
  const stepDetails = {
    title: messages.title,
    description: messages.description,
    primaryButton: {
      label: messages.primaryButton,
    },
    image: {
      src: RequestsImage.src,
      alt: RequestsImage.alt,
    },
  };

  return (
    <BuildStep navigation={stepProps.navigation} stepDetails={stepDetails} />
  );
}
