import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { useInvoiceSummaryReportData } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/hooks/useInvoiceSummaryReportData";
import { useInvoiceSummaryTotalsData } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/hooks/useInvoiceSummaryTotalsData";
import {
  type InvoiceSummaryReportExportMutation,
  type InvoiceSummaryReportExportMutationVariables,
  InvoiceSummarySortKey,
} from "~/utilities/API/graphqlFranchiseManagement";
import { useFormatSortAndFilter } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/hooks/useFormatSortAndFilter";
import { INVOICE_SUMMARY_EXPORT_MUTATION } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/InvoiceSummaryReport.graphql";
import type {
  InvoiceSummaryData,
  InvoiceSummarySortState,
  InvoiceSummaryTotalsData,
} from "./types";
import { PAGE_NUMBER_OPTIONS } from "./constants";
import { InvoiceSummaryReport } from "./InvoiceSummaryReport";

// eslint-disable-next-line max-statements
export function InvoiceSummaryReportLoader() {
  const { formatSort, formatFilter } = useFormatSortAndFilter();
  const [reportFilter, setReportFilter] = useState<FilterType>({
    key: "date_range",
    value: {
      startDate: defaultDateRangeOption.start,
      endDate: defaultDateRangeOption.end,
    },
  });

  const [sortingState, setSortingState] = useState<[InvoiceSummarySortState]>([
    {
      id: InvoiceSummarySortKey.FRANCHISE_NAME,
      desc: false,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_NUMBER_OPTIONS[0],
  });

  const [indexedCursors, setIndexedCursors] = useState<[string | undefined]>([
    undefined,
  ]);

  const { fetchInvoiceSummary, data, loading, error } =
    useInvoiceSummaryReportData();

  useEffect(() => {
    const sort = formatSort(sortingState);
    const filter = formatFilter(reportFilter);
    const cursor = indexedCursors[pageIndex];
    const first = pageSize;

    fetchInvoiceSummary({ sort, filter, cursor, first });
  }, [sortingState, reportFilter, pageIndex, pageSize]);

  const {
    fetchInvoiceSummaryTotals,
    data: totalsData,
    loading: totalsLoading,
    error: totalsError,
  } = useInvoiceSummaryTotalsData();

  useEffect(() => {
    const filter = formatFilter(reportFilter);

    fetchInvoiceSummaryTotals(filter);
  }, [reportFilter]);

  const [reportIsExported, setReportIsExported] = useState(false);

  const [exportCsv] = useMutation<
    InvoiceSummaryReportExportMutation,
    InvoiceSummaryReportExportMutationVariables
  >(INVOICE_SUMMARY_EXPORT_MUTATION, {
    variables: {
      input: {
        sort: formatSort(sortingState),
        filter: formatFilter(reportFilter),
      },
    },
  });

  async function exportFunction() {
    const { errors } = await exportCsv();
    setReportIsExported(!errors);
  }

  const totalItems = data?.invoiceSummary?.totalCount || 0;
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useMemo(() => {
    if (data?.invoiceSummary.reportPageInfo) {
      const newCursorIndex = indexedCursors;

      if (data.invoiceSummary.reportPageInfo.startCursor) {
        newCursorIndex[pageIndex] =
          data.invoiceSummary.reportPageInfo.startCursor;
      }

      if (data.invoiceSummary.reportPageInfo.nextCursor) {
        newCursorIndex[pageIndex + 1] =
          data.invoiceSummary.reportPageInfo.nextCursor;
      }

      setIndexedCursors(newCursorIndex);
    }
  }, [data]);

  const handleDateFilterChange = (range: FilterType) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setReportFilter(range);
  };

  const handleSortChange = (sortState: [InvoiceSummarySortState]) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setSortingState(sortState);
  };

  return (
    <InvoiceSummaryReport
      data={data?.invoiceSummary.records as InvoiceSummaryData[]}
      totalsData={totalsData?.invoiceSummaryTotals as InvoiceSummaryTotalsData}
      loading={loading || totalsLoading}
      error={error?.message || totalsError?.message}
      filterProps={{
        dateRangeFilter: reportFilter,
        onFilterChange: handleDateFilterChange,
      }}
      sortingProps={{
        manualSorting: true,
        state: sortingState,
        onSortingChange: handleSortChange,
      }}
      paginationProps={{
        manualPagination: true,
        onPaginationChange: setPagination,
        itemsPerPage: PAGE_NUMBER_OPTIONS,
        totalItems,
        state: pagination,
        pageCount: Math.ceil(totalItems / pageSize),
      }}
      exportProps={{
        exportFunction: exportFunction,
        setReportIsExported: setReportIsExported,
        reportIsExported: reportIsExported,
      }}
    />
  );
}
