import React, { type ReactNode, useState } from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import classNames from "classnames";
import { TableCell } from "jobber/workOrders/components/TableCell";
import styles from "./TableRow.module.css";

export const TableRow = ({ cells }: { cells: ReactNode[] }) => {
  const [selected, setSelected] = useState(false);
  const rowClasses = classNames(
    styles.cellRow,
    selected ? styles.selected : "",
  );
  return (
    <>
      {cells && (
        <tr role="row" className={rowClasses} data-selected={selected}>
          <td className={styles.cellCheckBox}>
            <Checkbox checked={selected} onChange={setSelected} />
          </td>
          {cells.map((cell, index) => {
            return (
              <td key={index}>
                <TableCell>{cell}</TableCell>
              </td>
            );
          })}
        </tr>
      )}
    </>
  );
};
