import { Routes, type SetupWizardStep } from "./types";

export const ANALYTICS_EVENTS = {
  viewedSetupWizard: {
    eventName: "Viewed Setup Wizard",
    validSteps: {
      business: "Business",
      company: "Company",
      heardAboutUs: "Heard About Us",
      profile: "Profile",
      promotion: "Promotion",
      revenue: "Revenue",
      theme: "Theme",
      topPriority: "Top Priority",
    },
  },
  interactedWithPromoMessage: {
    eventName: "Interacted with Promo Message",
    interaction: {
      clickedModalCta: "Clicked modal CTA",
      dismissedModal: "Dismissed Modal",
    },
  },
  interactedWithThemeStep: {
    eventName: "Interacted with Experiment",
    experiment: "signup_flow_theme",
  },
  interactedWithGoogleCompanyAutocomplete: {
    eventName: "Interacted with Experiment",
    experiment: "google_company_autocomplete",
    interaction: {
      manualCompanyInteraction: "clicked_manual_company_name",
      googleCompanyInteraction: "clicked_google_autocomplete_result",
      submittedGoogleCompanyData: "submitted_google_autocomplete_company_data",
    },
  },
};

// TODO: move this to testing util?
export const getAmplitudeStepByRoute = (
  wantedStep: SetupWizardStep,
): string => {
  switch (wantedStep.route) {
    case Routes.business:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.business;
    case Routes.company:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.company;
    case Routes.hdyhau:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.heardAboutUs;
    case Routes.profile:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.profile;
    case Routes.revenue:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.revenue;
    case Routes.theme:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.theme;
    case Routes.topPriority:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.topPriority;
    default:
      return "";
  }
};
