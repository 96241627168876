import { act, screen, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { callBridgeMethod } from "jobber/hooks/useCreateBridgeMethod";

// Selectors

export function addButton() {
  return screen.getByText("Add");
}

export function arrivalWindowStyles() {
  return screen.queryByText("Arrival window style");
}

export function arrivalWindows() {
  return screen.queryByText("Arrival Window");
}

export function arrivalWindowStyle(style: string) {
  return screen.getByDisplayValue(style);
}

export function arrivalWindowStyleCheckboxValue(style: string) {
  return (screen.getByDisplayValue(style) as HTMLInputElement).checked;
}

export function arrivalWindowWithLabelNamed(arrivalWindow: string) {
  return screen.getByLabelText(arrivalWindow);
}

export function arrivalWindowWithText(arrivalWindow: string) {
  return screen.getByText(arrivalWindow);
}

export function backButton() {
  return screen.getByText("Back");
}

export function cancelButton() {
  return screen.getByText("Cancel");
}

export function closePopup(index: number) {
  return screen.getAllByLabelText("Close modal")[index];
}

export function currentAndFutureJob() {
  return screen.getByLabelText("Apply to all current and future jobs");
}

export function currentAndFutureJobCheckboxValue() {
  return (currentAndFutureJob() as HTMLInputElement).checked;
}

export function currentAndFutureJobs() {
  return screen.queryByLabelText("Apply to all current and future jobs");
}

export function editButton() {
  return screen.getByText("Edit");
}

export function hiddenArrivalWindowInput() {
  return (screen.queryByTestId("arrival_window_duration") as HTMLInputElement)
    .value;
}

export function nextButton() {
  return screen.getByText(nextButtonValue());
}

export function nextButtonValue() {
  return "Next";
}

export function onboardingDuration(duration: string) {
  return screen.getByLabelText(duration) as HTMLInputElement;
}

export function popupHeader() {
  return screen.queryByTestId("modal-header");
}

export function saveButton() {
  return screen.getByText("Save");
}

export function setArrivalWindow() {
  return screen.getByText("Set Arrival Window");
}

export function whatYourClientsWillSee() {
  return screen.queryByText("What your clients will see");
}

export function visitRemindersSet() {
  return screen.queryByText("Visit reminders set");
}

// Finds

export function findNextButton() {
  return screen.findByText("Next");
}

// Solo Actions

export function iCancel() {
  userEvent.click(cancelButton());
}

export function iClickTheNextButton() {
  userEvent.click(nextButton());
}

export function iClickThePopupCloseIcon() {
  userEvent.click(closePopup(1));
}

export function iClickTheSaveButton() {
  userEvent.click(saveButton());
}

export function iConfirmMyEdits() {
  userEvent.click(setArrivalWindow());
}

export function iNavigateBackToTheFirstPopup() {
  userEvent.click(backButton());
}

export function iNavigateToNextPopup() {
  userEvent.click(nextButton());
}

/**
 * This difference between this function and iSetTheJobStartTimeTo is semantic.
 *
 * This one indicates that a user has previously set the job start time
 * and is now editing it.
 *
 * Whereas iSetTheJobStartTimeTo indicates that a user is setting the job
 * start time for the first time. i.e. is 'adding' an arrival window.
 */
export function iPreviouslySetTheJobStartTimeTo(date: Date) {
  iSetTheJobStartTimeTo(date);
}

export function iSetTheArrivalWindowToBe(arrivalWindow: string) {
  userEvent.click(arrivalWindowWithLabelNamed(arrivalWindow));
}

export function iSetTheArrivalWindowStyleToBe(style: string) {
  userEvent.click(arrivalWindowStyle(style));
}

export function iSetTheJobStartTimeTo(date: Date) {
  act(() => callBridgeMethod("oneOff", date));
}

export function iStartAddingAnArrivalWindow() {
  userEvent.click(addButton());
}

export function iStartEditingTheArrivalWindow() {
  userEvent.click(editButton());
}

export function iToggleApplyingSettingsToAllFutureJobs() {
  userEvent.click(currentAndFutureJob());
}

// Multi-step actions

export function iEditTheArrivalWindowToBe(arrivalWindow: string) {
  userEvent.click(arrivalWindowWithLabelNamed(arrivalWindow));
  userEvent.click(setArrivalWindow());
}

export async function iSetTheOnboardingDurationTo(duration: string) {
  iSetTheJobStartTimeTo(new Date(2022, 2, 3));

  await waitFor(() => {
    expect(addButton()).toBeDefined();
  });

  iStartAddingAnArrivalWindow();
  await waitForTheAddPopupToAppear();

  const onBoardingDuration = onboardingDuration(duration);
  expect(onBoardingDuration.checked).toBeTruthy();

  iClickTheNextButton();
  iClickTheSaveButton();
}

export async function iOpenTheArrivalWindow(startTime: Date) {
  iSetTheJobStartTimeTo(startTime);
  iStartAddingAnArrivalWindow();
  await waitForTheAddPopupToAppear();
}

export async function iOpenTheArrivalWindowAndSave(startTime: Date) {
  await iOpenTheArrivalWindow(startTime);
  iSetTheArrivalWindowToBe("1 hr");
  await iNavigateToNextPopup();
  iClickTheSaveButton();
}

export async function iNavigateToTheSecondPopup(startTime: Date) {
  await iOpenTheArrivalWindow(startTime);
  iNavigateToNextPopup();
}

export async function iNavigateToTheSecondPopupBackAndForth(startTime: Date) {
  await iNavigateToTheSecondPopup(startTime);
  iNavigateBackToTheFirstPopup();
  iNavigateToNextPopup();
}

export function iUpdateTheTime(originalDate: Date, newTime: string) {
  iPreviouslySetTheJobStartTimeTo(originalDate);

  iStartEditingTheArrivalWindow();
  iEditTheArrivalWindowToBe(newTime);
}

// Common WaitFors

export async function waitForTheAddPopupToAppear() {
  await screen.findByText(nextButtonValue());
}

export async function expectThePopupToDisappear() {
  await waitFor(() => {
    expect(popupHeader()).toBeNull();
  });
}
