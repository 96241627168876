import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { ConversationPills } from "jobber/jobberAssistant/components/ConversationPills";
import { AiAssistantFeedbackSentiment } from "~/utilities/API/graphql";
import { messages } from "./messages";
import { useFeedbackCreateMutation, useStartChatWithSuccess } from "../hooks";

interface SupportQuestionFollowupProps {
  message: string;
  requestId: string;
}

const THAT_HELPED_PROMPT = "yes";
const THAT_DID_NOT_HELP_PROMPT = "no";

export const SupportQuestionFollowup = ({
  message,
  requestId,
}: SupportQuestionFollowupProps) => {
  const { formatMessage } = useIntl();
  const {
    createFeedback,
    createFeedbackErrorMessage,
    createFeedbackSubmitted,
    createFeedbackLoading,
  } = useFeedbackCreateMutation({ requestId });
  const { startChatWithSuccess, loading: startChatLoading } =
    useStartChatWithSuccess();

  return (
    <>
      <Text>{formatMessage(messages.supportQuestionFollowupPrompt)}</Text>
      {createFeedbackErrorMessage && (
        <Banner type={"error"} dismissible={false}>
          {formatMessage(messages.supportQuestionFollowupSavingError)}
        </Banner>
      )}
      {!createFeedbackSubmitted ? (
        <ConversationPills
          onClick={async prompt => {
            if (prompt === THAT_HELPED_PROMPT) {
              await createFeedback(AiAssistantFeedbackSentiment.HELPFUL, "");
            } else {
              await startChatWithSuccess(message, requestId);
            }
          }}
          prompts={[
            {
              displayText: formatMessage(
                messages.supportQuestionFollowupPositive,
              ),
              prompt: THAT_HELPED_PROMPT,
              loading: createFeedbackLoading,
            },
            {
              displayText: formatMessage(
                messages.supportQuestionFollowupSupport,
              ),
              prompt: THAT_DID_NOT_HELP_PROMPT,
              loading: startChatLoading,
            },
          ]}
        />
      ) : (
        <Text align="end" variation="subdued" size="small">
          <Icon name="checkmark" size="small" />
          &nbsp;
          <Emphasis variation="italic">
            {formatMessage(messages.supportQuestionFollowupAcknowledgement)}
          </Emphasis>
        </Text>
      )}
    </>
  );
};
