import React from "react";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import styles from "./WorkflowCardEmptyStateContent.module.css";

export interface WorkflowCardEmptyStateContentProps {
  cardInfo: { [key: string]: string };
  onCTAButtonClick(): void;
}

export function WorkflowCardEmptyStateContent({
  cardInfo,
  onCTAButtonClick,
}: WorkflowCardEmptyStateContentProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.cta_wrapper}>
        <div className={styles.message}>
          <Text variation="subdued" size="large">
            {cardInfo.emptyStateMessage}
          </Text>
        </div>
        <Button
          label={cardInfo.emptyStateButtonText}
          url={cardInfo.CTAButtonUrl}
          onClick={onCTAButtonClick}
        />
      </div>
      <div className={styles.trendlineImage}>
        <img src={cardInfo.trendlineImgSrc} alt={cardInfo.trendlineImgAlt} />
      </div>
    </div>
  );
}
