import type { EventInput } from "@fullcalendar/core";

let eventGuid = 0;

function clientName() {
  return `Client ${randomInt(10) + 1}`;
}

function createEventId() {
  return String(eventGuid++);
}

function endTime(start: Date, durationMinutes = 60) {
  return new Date(start.getTime() + durationMinutes * 60_000);
}

function randomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function randomDuration() {
  const minBlockSize = 15; // 15 minute blocks
  return minBlockSize * (randomInt(16) + 1);
}

function startTime() {
  const start = new Date();
  const startOfDay = 8;
  const startHour = randomInt(12) + startOfDay;
  start.setHours(startHour, 0, 0);
  return start;
}

function randomBoolean() {
  return Math.random() <= 0.1; // 90% chance of false
}

function getAppointment(userCount: number) {
  const userId = randomInt(userCount) + 1;
  const duration = randomDuration();
  const start = startTime();

  return {
    id: createEventId(),
    title: clientName(),
    start: start.toISOString(),
    allDay: randomBoolean(),
    end: endTime(start, duration).toISOString(),
    resourceId: `user${userId}`,
  };
}

export function getAppointments(
  appointmentCount: number,
  userCount: number,
): EventInput[] {
  return Array(appointmentCount)
    .fill(undefined)
    .map(() => getAppointment(userCount));
}

const SAMPLE_USERS = [
  "Gordon Ramsay",
  "Uncle Roger",
  "Jamie Oliver",
  "Bobby Flay",
  "Alton Brown",
  "Wolfgang Puck",
  "Guy Fieri",
  "Rachel Ray",
  "Anthony Bourdain",
  "Anthony Bourdain",
  "Julia Child",
  "Emeril Lagasse",
  "Paula Deen",
  "Jacques Pépin",
  "Mario Batali",
  "Ina Garten",
  "Masaharu Morimoto",
];

export function getUsers(count: number) {
  return SAMPLE_USERS.slice(0, count).reduce(
    (u: { id: string; title: string }[], x) => {
      u.push({ id: `user${u.length + 1}`, title: x });
      return u;
    },
    [],
  );
}
