export namespace JobberOnline {
  export namespace constants {
    export namespace balanceAdjustments {
      export enum paymentTypes {
        cash = 1,
        cheque = 2,
        creditCard = 3,
        bankTransfer = 4,
        moneyOrder = 5,
        other = 6,
        zelle = 7,
        cashApp = 8,
        paypal = 9,
        venmo = 10,
        eTransfer = 11,
        achBankPayment = 12,
        jobberPayments = 77,
        jobberPaymentsAch = 777,
        epayment = 88,
        unknown,
      }
    }
  }
}

export interface JobberOnline {
  constants: {
    accountId: number;
  };
}
