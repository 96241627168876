import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CAMPAIGN_ACTIVATE = gql`
  mutation campaignActivate($campaignId: EncodedId!) {
    campaignActivate(campaignId: $campaignId) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
