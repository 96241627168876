import {
  DisputeCategory,
  DisputeReason,
  DisputeStatus,
} from "~/utilities/API/graphql";

export const sampleDataTableData = {
  totalCount: 12,
  pageInfo: {
    endCursor: "",
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: "",
  },
  edges: [
    {
      node: {
        id: "1",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "2",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "3",
        status: DisputeStatus.WON,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "4",
        status: DisputeStatus.UNDER_REVIEW,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "5",
        status: DisputeStatus.UNDER_REVIEW,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "6",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "7",
        status: DisputeStatus.WON,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "8",
        status: DisputeStatus.LOST,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "9",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "10",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "11",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "12",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
    {
      node: {
        id: "13",
        status: DisputeStatus.NEEDS_RESPONSE,
        disputedAt: "2023-12-22T00:45",
        evidenceDueBy: "2024-01-31T00:45",
        paymentRecord: {
          client: {
            name: "John Doe",
          },
          invoice: {
            invoiceNumber: "123456",
          },
        },
        amount: 34.99,
        reason: DisputeReason.PRODUCT_NOT_RECEIVED,
        category: DisputeCategory.CHARGEBACK,
      },
      cursor: "test",
    },
  ],
};
