import React from "react";
import { useIntl } from "react-intl";
import type { TooltipData } from "jobber/dataVisualizations/types";
import { capitalizeFirstLetter } from "utilities/capitalizeFirstLetter";
import { JobProfitStatusLabel } from "jobber/workOrders/components/JobCost/components/JobProfitStatusLabel";
import { ChartTooltip } from "jobber/dataVisualizations/ChartTooltip";
import { messages as jobCostHeaderMessages } from "jobber/workOrders/components/JobCost/components/JobCostHeader/messages";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import styles from "./JobProfitTooltip.module.css";
import { messages } from "./messages";

export function JobProfitTooltip({
  tooltipData,
}: {
  tooltipData: TooltipData;
}) {
  const { formatMessage } = useIntl();

  function handleLabel(label: string): string {
    if (label.toLowerCase() === "lineitemcost") {
      return formatMessage(jobCostHeaderMessages.lineItemCost);
    }
    if (label.toLowerCase() === "labour") {
      return formatMessage(jobCostMessages.labour);
    }
    return capitalizeFirstLetter(label);
  }

  const label = handleLabel(tooltipData.id.toString());

  return (
    <ChartTooltip
      statusLabel={
        <div className={styles.label}>
          <JobProfitStatusLabel status={tooltipData?.id.toString()} />
        </div>
      }
      label={label}
      content={formatMessage(messages.toolTipContent, {
        tooltipDataValue: tooltipData.value,
      })}
    />
  );
}
