import { useMemo } from "react";
import { type ApolloError, useQuery } from "@apollo/client";
import type {
  FollowUpMessageSettingsQuery,
  MessageTemplateEmail,
  MessageTemplateSms,
  NotificationSchedule,
  NotificationScheduleEdge,
} from "~/utilities/API/graphql";
import { FOLLOW_UP_MESSAGE_SETTINGS_QUERY } from "../components/ReviewsSettings/ReviewsSettings.graphql";

interface UseFollowUpMessageSettingsQueryResult {
  loading: boolean;
  error: ApolloError | undefined;
  schedules: NotificationSchedule[];
  templates: { email: MessageTemplateEmail; sms: MessageTemplateSms };
}

export function useFollowUpMessageSettingsQuery(): UseFollowUpMessageSettingsQueryResult {
  const { loading, error, data } = useQuery<FollowUpMessageSettingsQuery>(
    FOLLOW_UP_MESSAGE_SETTINGS_QUERY,
  );

  return useMemo(() => {
    const definedEdges =
      data?.clientNotification?.schedules.edges?.filter(
        edge => edge !== undefined,
      ) || ([] as NotificationScheduleEdge[]);
    const schedules = definedEdges.map(
      edge => edge.node,
    ) as NotificationSchedule[];

    const emailTemplate = data?.clientNotification?.templates?.nodes.find(
      node => node.__typename == "MessageTemplateEmail",
    ) as MessageTemplateEmail;
    const smsTemplate = data?.clientNotification?.templates?.nodes.find(
      node => node.__typename == "MessageTemplateSMS",
    ) as MessageTemplateSms;

    const templates = { email: emailTemplate, sms: smsTemplate };
    return {
      loading,
      error,
      schedules,
      templates,
    };
  }, [data, error, loading]);
}
