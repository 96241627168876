import { type WatchQueryFetchPolicy, gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import type {
  StripeFileLink,
  StripeFileLinkQuery,
  StripeFileLinkQueryVariables,
} from "~/utilities/API/graphql";

export const STRIPE_FILE_LINK_QUERY = gql`
  query StripeFileLink($stripeFileId: String!) {
    stripeFileLink(stripeFileId: $stripeFileId, fileAccessDurationMinutes: 10) {
      expiresAt
      publicUrl
    }
  }
`;

export function useStripeFileLink(
  onStripeFileLinkComplete: (stripeFileLink: StripeFileLink) => void,
) {
  const [error, setError] = useState<string>("");

  const [retrieveFileLink] = useLazyQuery<
    StripeFileLinkQuery,
    StripeFileLinkQueryVariables
  >(STRIPE_FILE_LINK_QUERY);

  function _retrieveFileLink(stripeFileId: string) {
    const options = {
      variables: { stripeFileId: stripeFileId },
      fetchPolicy: "no-cache" as WatchQueryFetchPolicy,
      onCompleted: (data: { stripeFileLink: StripeFileLink }) => {
        onStripeFileLinkComplete(data.stripeFileLink);
      },
      onError: () => {
        setError("Something went wrong, please try again later.");
      },
    };

    void retrieveFileLink(options);
  }

  return {
    retrieveFileLink: _retrieveFileLink,
    onComplete: onStripeFileLinkComplete,
    error,
  };
}
