import { useState } from "react";

type ValidationRules = Record<string, () => string | undefined>;

interface UseCustomClientSideValidationProps<T extends ValidationRules> {
  validationRules: T;
}

interface UseCustomClientSideValidationReturnType<T extends ValidationRules> {
  validationErrors: Record<keyof T, string | undefined> | undefined;
  checkIsFormValid: () => boolean;
}

export function useCustomClientSideValidation<T extends ValidationRules>({
  validationRules,
}: UseCustomClientSideValidationProps<T>): UseCustomClientSideValidationReturnType<T> {
  const validationErrors = Object.keys(validationRules).reduce(
    (validationErrorsRecordInternal, curr: keyof T) => {
      validationErrorsRecordInternal[curr] = validationRules[curr]();
      return validationErrorsRecordInternal;
    },
    {} as Record<keyof T, string | undefined>,
  );

  const hasValidationErrors = Object.values(validationErrors).some(
    errorValue => errorValue,
  );

  const [showValidationErrors, setShowValidationErrors] = useState(false);

  return {
    validationErrors: showValidationErrors ? validationErrors : undefined,
    checkIsFormValid: () => {
      if (hasValidationErrors) {
        setShowValidationErrors(true);
        return false;
      }
      return true;
    },
  };
}
