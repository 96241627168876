import type { HeaderImageFile } from "jobber/campaigns/types";
import {
  CampaignTemplateCtaLinkTo,
  Template,
  type TemplateVariablesFragment,
  type TemplatesFragment,
} from "~/utilities/API/graphql";

export function transformCampaignTemplate(
  template: TemplatesFragment | undefined,
): CommsCampaignTemplate {
  return {
    subject: template?.subject || "",
    header: template?.header || "",
    body: template?.body || "",
    showLogo: template?.showLogo === false ? false : true,
    ctaButtonEnabled: template?.ctaVisible || false,
    linkTo: template?.ctaLinkTo || CampaignTemplateCtaLinkTo.EXTERNAL_LINK,
    linkValue: template?.ctaUrl || "",
    buttonText: template?.ctaText || "",
    buttonColor: template?.ctaColor || "",
    variables: template?.variables?.nodes || [],
    type: template?.type || Template.RE_ENGAGE,
    headerImage: template?.headerImage || undefined,
    id: template?.id || "",
    referralLink: template?.referralLink || undefined,
  };
}
export interface CommsCampaignTemplate {
  subject: string;
  header: string;
  body: string;
  showLogo: boolean;
  ctaButtonEnabled: boolean;
  linkTo: CampaignTemplateCtaLinkTo;
  linkValue: string;
  buttonText: string;
  buttonColor: string;
  variables: TemplateVariablesFragment[];
  type: Template;
  headerImage?: HeaderImageFile;
  id: string;
  referralLink?: string;
}
