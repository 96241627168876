import { useQuery } from "@apollo/client";
import { GET_ACCOUNT_FEATURES_STATUS_FOR_CTA_LINK_TO } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";
import type { AccountFeatureStatusForCtaLinkToQuery } from "~/utilities/API/graphql";

export function useAccountFeatureStatusForCtaLinkTo() {
  const { loading, data } = useQuery<AccountFeatureStatusForCtaLinkToQuery>(
    GET_ACCOUNT_FEATURES_STATUS_FOR_CTA_LINK_TO,
  );

  return {
    loadingAccountFeatureStatus: loading,
    onlineBookingEnabled:
      data?.onlineBookingConfiguration?.acceptingOnlineBookings || false,
    requestsEnabled:
      data?.requestsConfiguration?.acceptingRequestsInClientHub || false,
  };
}
