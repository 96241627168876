import React from "react";
import { Divider } from "@jobber/components/Divider";
import type {
  ArrivalWindow,
  ArrivalWindowDayOfWeekMap,
  TimeRange,
} from "jobber/google_lsa/MerchantConfiguration";
import { ArrivalWindowDaysOfWeek } from "./ArrivalWindowDaysOfWeek";
import { ArrivalWindowRange } from "./ArrivalWindowRange";
import { ArrivalWindowRemoveButton } from "./ArrivalWindowRemoveButton";
import styles from "./EditArrivalWindows.module.css";

interface Props {
  window: ArrivalWindow;
  onChange(newValue: ArrivalWindow): void;
  onRemove?(): void;
}

export function ArrivalWindowRow({ window, onChange, onRemove }: Props) {
  const onChangeRange = (newValue: TimeRange) => {
    onChange({
      startAt: newValue.startAt,
      endAt: newValue.endAt,
      dayOfWeekMap: window.dayOfWeekMap,
    });
  };
  const onChangeDays = (newValue: ArrivalWindowDayOfWeekMap) => {
    onChange({
      startAt: window.startAt,
      endAt: window.endAt,
      dayOfWeekMap: newValue,
    });
  };

  return (
    <>
      <div className={styles.editArrivalWindows__range}>
        <ArrivalWindowRange
          startAt={window.startAt}
          endAt={window.endAt}
          onChange={onChangeRange}
        />
      </div>
      <div className={styles.editArrivalWindows__daysOfWeek}>
        <ArrivalWindowDaysOfWeek
          days={window.dayOfWeekMap}
          onChange={onChangeDays}
        />
      </div>
      {onRemove && (
        <div className={styles.editArrivalWindows__removeAction}>
          <ArrivalWindowRemoveButton onRemove={onRemove} />
        </div>
      )}
      <Divider />
    </>
  );
}
