import type { SectionProps } from "@jobber/components/Menu";
import { useCallback, useMemo } from "react";
import { ApolloError, type FetchResult } from "@apollo/client";
import {
  type AiAssistantTextReviseExchange,
  type AiAssistantTextReviseMutation,
  AiAssistantTextReviseOperation,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { useTextRewrite } from "./useTextRewrite";
import { TextRewriteError } from "../errors";
import { trackTextRewriteClicked } from "../utils";
import type { PromptTypeProps } from "../types";

export const SUGGEST_NEW_CONVERSATION_ERROR =
  "Try creating a new conversation.";

export const GENERIC_BANNER_ERROR =
  "Something went wrong with rewriting this message. Please try again later.";
export const MESSAGE_TOO_LONG_ERROR =
  "Message is too long to be rewritten. Please try again with a shorter message.";

type UseTextRewriteOptions = {
  text: string;
  setText: (text: string) => void;
  setError: (error: TextRewriteError | undefined) => void;
  setLoading: (loading: boolean) => void;
  trackingSource: string;
  decodeHTMLEntities?: boolean;
} & PromptTypeProps;

export function useTextRewriteOptions({
  setError,
  text,
  setText,
  setLoading,
  trackingSource,
  contentType,
  intent,
  additionalInstructions,
  decodeHTMLEntities = false,
}: UseTextRewriteOptions): SectionProps[] {
  const rewriteText = useTextRewrite(
    contentType,
    intent,
    additionalInstructions,
  );
  const onRewriteText = useCallback(
    async (tone: AiAssistantTextReviseOperation) => {
      try {
        trackTextRewriteClicked(tone, trackingSource);
        setLoading(true);
        setError(undefined);
        const response = await rewriteText(text, tone);
        const updatedText = parseRewrittenTextFromResponse(
          response,
          trackingSource,
          decodeHTMLEntities,
        );
        setText(updatedText);
      } catch (caughtError) {
        // This could happen for network errors
        const error =
          caughtError instanceof ApolloError
            ? new TextRewriteError(GENERIC_BANNER_ERROR, "BANNER")
            : caughtError;

        Rollbar.EXCEPTION("TextRewrite Error", caughtError, {
          trackingSource,
        });
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [
      trackingSource,
      setLoading,
      setError,
      rewriteText,
      text,
      decodeHTMLEntities,
      setText,
    ],
  );

  return useMemo(
    (): SectionProps[] => [
      {
        header: "Make this more",
        actions: [
          {
            icon: "happyFace",
            label: "Cheerful",
            onClick: () =>
              onRewriteText(AiAssistantTextReviseOperation.CHEERFUL),
          },
          {
            icon: "sneaker",
            label: "Casual",
            onClick: () => onRewriteText(AiAssistantTextReviseOperation.CASUAL),
          },
          {
            icon: "work",
            label: "Professional",
            onClick: () =>
              onRewriteText(AiAssistantTextReviseOperation.PROFESSIONAL),
          },
          {
            icon: "textField",
            label: "Shorter",
            onClick: () =>
              onRewriteText(AiAssistantTextReviseOperation.SHORTEN),
          },
        ],
      },
    ],
    [onRewriteText],
  );
}

// eslint-disable-next-line max-statements
function parseRewrittenTextFromResponse(
  { data, errors }: FetchResult<AiAssistantTextReviseMutation>,
  trackingSource: string,
  decodeHTMLEntities: boolean,
) {
  // Handle network errors or user errors (eg. `Cannot find or create conversation`)
  if (errors || data?.aiAssistantTextRevise.userErrors.length) {
    Rollbar.EXCEPTION("TextRewrite Error", new Error("GraphQL Error"), {
      trackingSource,
      errors,
      userErrors: data?.aiAssistantTextRevise?.userErrors,
    });
    throw new TextRewriteError(GENERIC_BANNER_ERROR, "BANNER");
  }

  const content = data?.aiAssistantTextRevise.message
    ?.content as AiAssistantTextReviseExchange;

  const messageIsTooLong = content.errors.some(e =>
    e.includes(SUGGEST_NEW_CONVERSATION_ERROR),
  );

  if (messageIsTooLong) {
    throw new TextRewriteError(MESSAGE_TOO_LONG_ERROR, "INLINE");
  }

  const revisedText = content.revision;

  if (content.errors.length || !revisedText) {
    throw new TextRewriteError(GENERIC_BANNER_ERROR, "BANNER");
  }

  if (decodeHTMLEntities) {
    try {
      const decodedRevisedText =
        new DOMParser().parseFromString(revisedText, "text/html").body
          .textContent || "";
      return decodedRevisedText;
    } catch (e) {
      throw new TextRewriteError(GENERIC_BANNER_ERROR, "BANNER");
    }
  } else {
    return revisedText;
  }
}
