import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import { Card } from "@jobber/components/Card";
import { APIProvider } from "~/utilities/API/APIProvider";
import { InputTextLlmPoc } from "./components/InputTextLlmPoc";

const SAMPLE_CONTENT: Record<string, string> = {
  MarkupWithVariables: `<h1>Hi {{CLIENT_NAME}}</h1>
<p>It’s <em>been a while</em> since we’ve had the pleasure of serving you.
  <strong>We’re here to provide you with top-notch service</strong> for all your project and maintenance needs. </p>
<h2><strong>We have recently added some services you might be interested in:</strong></h2>
<ul>
  <li>Hedge trimming.</li>
  <li>Fence repair.</li>
  <li>Weed whacking.</li>
  <li>Full 🌲 tree removal!! 💥</li>
</ul>
<p>
  <span style='color: rgb(230, 0, 0);background: rgb(33, 33, 33);'>Let us make your home the best it can be!</span>
</p>`,
  ReEngage: `It’s been a while since we’ve had the pleasure of serving you. We’re here to provide you with top-notch service for all your project and maintenance needs. Let us make your home the best it can be!`,
  RequestSubmitted: `Hello {{CLIENT_NAME}},\nYour request has been received! Thank you for considering us–-we'll be in touch soon.\nPlease find a copy of your request below.\nIf you have any questions regarding this request, please don't hesitate to get in touch with us at {{DEFAULT_EMAIL}}.\nSincerely,\n{{COMPANY_NAME}}`,
};

export function ToneBotPage() {
  const [defaultValue, setDefaultValue] = useState("");

  return (
    <Page title="InputTextLLM Proof of Concept">
      <Content>
        <Card header="Insert Starting Content">
          <Content>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: "s",
                gap: "10px",
              }}
            >
              {Object.keys(SAMPLE_CONTENT).map(key => (
                <Button
                  key={key}
                  label={key}
                  onClick={() => {
                    setDefaultValue(SAMPLE_CONTENT[key]);
                  }}
                />
              ))}
              <Button
                label="Clear"
                onClick={() => {
                  setDefaultValue("");
                }}
              />
            </div>
          </Content>
        </Card>
        <APIProvider>
          <InputTextLlmPoc defaultValue={defaultValue} />
        </APIProvider>
      </Content>
    </Page>
  );
}
