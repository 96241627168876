import { defineMessages } from "react-intl";

const todayCardIdPrefix = "homePage.todayCard.";

export const messages = defineMessages({
  todayHeading: {
    id: `${todayCardIdPrefix}todayHeading`,
    defaultMessage: "Total",
    description: "Heading for the today section of the today card",
  },
  toGoHeading: {
    id: `${todayCardIdPrefix}toGoHeading`,
    defaultMessage: "To Go",
    description: "Heading for the to go section of the today card",
  },
  activeHeading: {
    id: `${todayCardIdPrefix}activeHeading`,
    defaultMessage: "Active",
    description: "Heading for the active section of the today card",
  },
  completeHeading: {
    id: `${todayCardIdPrefix}completedHeading`,
    defaultMessage: "Complete",
    description: "Heading for the complete section of the today card",
  },
});
