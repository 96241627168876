import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { messages } from "./messages";

interface OfferingsItemMenuProps {
  openModal: () => void;
}

export function OfferingsItemMenu({ openModal }: OfferingsItemMenuProps) {
  const { formatMessage } = useIntl();

  return (
    <Button
      icon={"edit"}
      variation="subtle"
      type="tertiary"
      ariaLabel={formatMessage(messages.openModalButtonAriaLabel)}
      onClick={openModal}
    />
  );
}
