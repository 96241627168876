import React, { type PropsWithChildren } from "react";

interface InputLabelProps {
  label?: string;
}

export function InputLabel(props: PropsWithChildren<InputLabelProps>) {
  const { label, children } = props;
  if (!label) return <>children</>;

  return (
    <label className="row collapse align-middle u-marginBottomSmall">
      <div className="small-4 columns u-paddingRightSmaller">
        <span className="list-label">{label}</span>
      </div>
      <div className="columns fieldAffix u-marginBottomNone">{children}</div>
    </label>
  );
}
