/**
 * SelectDurationPOM
 *
 * - hasSelectedDuration
 * - selectedDuration
 * - selectDuration
 * - isLoading
 * - waitForLoadingToFinish
 *
 */
import {
  screen,
  waitForElementToBeRemoved,
  within,
} from "@testing-library/react";
import userEvent from "@testing-library/user-event";

/**
 *
 * @returns Create a SelectDurationPOM given a label
 */
export function makeSelectDurationPOM(label: string) {
  return {
    hasSelectedDuration,
    selectedDuration,
    selectDuration,
    isLoading,
    waitForLoadingToFinish,
    expectToBeLoading,
    expectNotToBeLoading,
  };

  // Public

  /**
   * @returns The selected duration
   */
  function selectedDuration() {
    const seconds = +element().value;
    const hours = Math.floor(seconds / 60 / 60);
    const minutes = Math.floor((seconds - hours * 60 * 60) / 60);
    return { hours, minutes };
  }

  /**
   *
   * @returns True if the duration selector has the selected duration
   */
  function hasSelectedDuration({
    hours,
    minutes,
  }: {
    hours: number;
    minutes: number;
  }) {
    return element().value === `${hours * 60 + minutes}`;
  }

  /**
   * Simulates selecting a duration
   */
  function selectDuration({
    hours,
    minutes,
  }: {
    hours: number;
    minutes: number;
  }) {
    userEvent.selectOptions(
      screen.getByLabelText(label),
      computeDurationOption(hours, minutes),
    );
  }

  /**
   * @returns True if the duration selector is loading
   */
  function isLoading() {
    return component().queryByLabelText("loading") != null;
  }

  /**
   * Waits for the duration selector to finish loading
   */
  function waitForLoadingToFinish() {
    component().getByLabelText("loading");
    return waitForElementToBeRemoved(() =>
      component().queryByLabelText("loading"),
    );
  }

  // Expects

  function expectToBeLoading() {
    expect(isLoading()).toBe(true);
  }

  function expectNotToBeLoading() {
    expect(isLoading()).toBe(false);
  }

  // Private

  function computeDurationOption(hours: number, minutes: number) {
    if (hours >= 2) {
      return `${hours} hr`;
    }
    if (minutes + hours * 60 >= 60) {
      return `${hours} hr ${minutes} min`;
    }

    return `${minutes} min`;
  }

  function element() {
    return screen.getByLabelText(label) as unknown as HTMLSelectElement;
  }

  function root() {
    const grandparent = element().parentElement?.parentElement;

    if (!grandparent) {
      throw new Error("Could not find root element of duration selector");
    }

    return grandparent;
  }

  function component() {
    return within(root());
  }
}
