import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadSuccess.uploadDocumentsHeading",
    defaultMessage: "Documents have been submitted",
    description: "Success page heading",
  },
  descriptionFirst: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadSuccess.descriptionFirst",
    defaultMessage:
      "We’ll let you know via email when the documents have been reviewed.",
    description: "First part of the description",
  },
  descriptionSecond: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadSuccess.descriptionSecond",
    defaultMessage: "Didn’t upload any documents?",
    description: "Second part of the description",
  },
  descriptionThird: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadSuccess.descriptionThird",
    defaultMessage:
      "Someone else on your team might have already gotten to it!",
    description: "Third part of the description",
  },
  successButtonLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadSuccess.successButtonLabel",
    defaultMessage: "Got it",
    description: "Success aknowledgement button label",
  },
});
