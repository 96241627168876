import React, { useState } from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  type Client,
  useDynamicClient,
} from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicClient";
import {
  type InvoiceFrequencySettings,
  useDynamicSelectedFrequency,
} from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicSelectedFrequency";
import { IntlProvider } from "@translations/IntlProvider";
import { AutomaticPayments } from "./AutomaticPayments";

export interface AutomaticPaymentsLoaderProps {
  shouldEnableWorkOrderOnPaymentMethodAdd: boolean;
  automaticallyChargeInvoice: boolean;
  disableSwitch: boolean;
  availableOnPlan: boolean;
  jobberPaymentsEnabled: boolean;
  client: Client | undefined;
  selectedFrequency: InvoiceFrequencySettings | undefined;
  newRecord: boolean;
}

export function AutomaticPaymentsLoader(props: AutomaticPaymentsLoaderProps) {
  const [client, setClient] = useState<Client | undefined>(undefined);
  useDynamicClient(setClient);

  const [selectedFrequency, setSelectedFrequency] = useState<
    InvoiceFrequencySettings | undefined
  >(props.selectedFrequency);

  useDynamicSelectedFrequency(setSelectedFrequency);

  return (
    <APIProvider>
      <IntlProvider>
        <AutomaticPayments
          {...props}
          selectedFrequency={selectedFrequency}
          client={client}
        />
      </IntlProvider>
    </APIProvider>
  );
}
