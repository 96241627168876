import React from "react";
import { useIntl } from "react-intl";
import { Icon } from "@jobber/components/Icon";
import { Tooltip } from "@jobber/components/Tooltip";
import { Text } from "@jobber/components/Text";
import { messages } from "./messages";

export function RecipientsDisplay({
  totalSent,
  totalDelivered,
}: {
  totalSent: number;
  totalDelivered: number;
}) {
  const { formatMessage } = useIntl();

  const deliveredPercentage =
    totalSent > 0 ? Math.round((totalDelivered / totalSent) * 100) : 0;

  return (
    <>
      <Text>{formatMessage(messages.sent, { totalSent: totalSent })}</Text>
      <Text>
        {formatMessage(messages.delivered, {
          totalDelivered: totalDelivered,
          deliveredPercentage: deliveredPercentage,
        })}
        <Tooltip message={formatMessage(messages.tooltip)}>
          <Icon name="help" size="small" />
        </Tooltip>
      </Text>
    </>
  );
}
