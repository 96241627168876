import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import type { datadogRum } from "@datadog/browser-rum";
import React, { type FunctionComponent, type Ref, forwardRef } from "react";
import {
  type SplitOverrides,
  SplitOverridesContext,
} from "./SplitOverridesContext";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _splitConfig: {
      authorizationKey: string;
      key: string;
    };
    splitOverrides: SplitOverrides;
    DD_RUM: typeof datadogRum;
  }
}

function splitSDKConfig(): SplitIO.IBrowserSettings {
  return {
    core: {
      authorizationKey: window._splitConfig?.authorizationKey,
      key: window._splitConfig?.key,
    },
    impressionListener: {
      logImpression: impressionData => {
        if (window.DD_RUM) {
          window.DD_RUM.addFeatureFlagEvaluation(
            impressionData.impression.feature,
            impressionData.impression.treatment,
          );
        }
      },
    },
  };
}

export function withSplitClient<WrappedComponentProps extends object>(
  WrappedComponent: FunctionComponent<WrappedComponentProps>,
) {
  const sdkConfig = splitSDKConfig();
  function SplitWrapped(props: WrappedComponentProps) {
    return (
      <SplitFactoryProvider config={sdkConfig}>
        <SplitOverridesContext.Provider value={window.splitOverrides ?? {}}>
          <WrappedComponent {...props} />
        </SplitOverridesContext.Provider>
      </SplitFactoryProvider>
    );
  }
  return SplitWrapped;
}

export function withSplitClientForwardedRef<
  WrappedComponentProps extends object,
  WrappedComponentRef,
>(WrappedComponent: FunctionComponent<WrappedComponentProps>) {
  const sdkConfig = splitSDKConfig();
  function SplitWrapped(
    props: WrappedComponentProps,
    ref: Ref<WrappedComponentRef>,
  ) {
    return (
      <SplitFactoryProvider config={sdkConfig}>
        <SplitOverridesContext.Provider value={window.splitOverrides ?? {}}>
          <WrappedComponent {...props} ref={ref} />
        </SplitOverridesContext.Provider>
      </SplitFactoryProvider>
    );
  }
  return forwardRef(SplitWrapped);
}
