import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { CardBrand } from "components/CardBrand/CardBrand";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import styles from "./ProcessingRateBadgesInternal.module.css";
import type { AchProcessingRate, ProcessingRate, RateGroup } from "./types";

interface ProcessingRatesProps {
  rateGroups: RateGroup[];
  hideRateLabel?: boolean;
  achProcessingRate?: AchProcessingRate;

  imgManifest?: { [key: string]: string };
}

export function formatRate(rate: ProcessingRate) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useTranslation();
  const centCurrency = t("centCurrency");
  return `${rate.percent}% + ${rate.cents_per_charge}${centCurrency}`;
}

export function ProcessingRatesBadgesInternal(props: ProcessingRatesProps) {
  const { rateGroups, imgManifest, hideRateLabel, achProcessingRate } = props;
  return (
    <div className={styles.ratesRow}>
      {rateGroups.map(({ cardBrands, processingRate }) => {
        const formattedRate = formatRate(processingRate);
        return (
          <div className={styles.rate} key={formattedRate}>
            {cardBrands.map(cardBrand => (
              <CardBrand
                key={cardBrand}
                brand={cardBrand}
                size="small"
                imgManifest={imgManifest}
              />
            ))}
            {!hideRateLabel && (
              <div className={styles.rateLabel}>
                <InlineLabel color="green">
                  <span style={{ textTransform: "lowercase" }}>
                    {formattedRate}
                  </span>{" "}
                  / TRANSACTION
                </InlineLabel>
              </div>
            )}
          </div>
        );
      })}
      {achProcessingRate && (
        <>
          {!hideRateLabel && (
            <div className={styles.rateLabel}>
              <InlineLabel color="green">
                {achProcessingRate.percent}% / bank transfer (ACH)
                {achProcessingRate.fee_cap_cents &&
                  ` up to $${achProcessingRate.fee_cap_cents / 100.0}`}
              </InlineLabel>
            </div>
          )}
        </>
      )}
    </div>
  );
}
