import { Chip, Chips } from "@jobber/components/Chips";
import { Content } from "@jobber/components/Content";
import React, { useReducer } from "react";
import { Heading } from "@jobber/components/Heading";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { timeSelectionOptions } from "jobber/workOrders/components/ArrivalWindowPreview/utils";
import { messages } from "./messages";
import styles from "./ArrivalWindowSettings.module.css";
import { type SettingsState, settingsReducer } from "./settingsReducer";
import { StateSerializerForController } from "./StateSerializerForController";

interface ArrivalWindowSettingsProps {
  defaultDuration: number;
  centered: boolean;
}

export function ArrivalWindowSettings({
  defaultDuration,
  centered,
}: ArrivalWindowSettingsProps) {
  const [formState, dispatch] = useReducer(settingsReducer, {
    defaultDuration,
    centered,
  });

  const onCenteredChange = (val: boolean) =>
    dispatch({ type: "STYLE", centered: val });
  const onDurationChange = (updatedValue?: string) =>
    dispatch({
      type: "DEFAULT_DURATION",
      duration: updatedValue ? +updatedValue : undefined,
    });

  return (
    <div className={styles.container}>
      <Content spacing="small">
        <Heading level={5}>{messages.header.title}</Heading>
        <Chips
          selected={formState.defaultDuration.toString()}
          onChange={onDurationChange}
        >
          {timeSelectionOptions.map(option => (
            <Chip
              key={option.value}
              value={option.value.toString()}
              label={option.label}
            />
          ))}
        </Chips>
      </Content>
      <Content spacing="small">
        <Heading level={5}>Arrival window style</Heading>
        <StyleOptions
          centered={formState.centered}
          onCenteredChange={onCenteredChange}
        />
      </Content>
      <StateSerializerForController
        controllerName="work_configuration"
        state={serializeStateForRailsController(formState)}
      />
    </div>
  );
}

function serializeStateForRailsController(state: SettingsState) {
  return [
    {
      name: "arrival_window_default_duration",
      value: state.defaultDuration.toString(),
    },
    {
      name: "arrival_window_centered",
      value: state.centered ? "1" : "0",
    },
  ];
}

function StyleOptions(props: {
  centered: boolean;
  onCenteredChange(val: boolean): void;
}) {
  return (
    <div className={styles.radioContainer}>
      <RadioGroup
        value={props.centered ? "centered" : "flush"}
        ariaLabel="Add window after start time"
        onChange={(value: "centered" | "flush") =>
          props.onCenteredChange(value === "centered")
        }
      >
        <RadioOption value="flush">
          <div className={styles["icon-container"]}>
            <Icon name="sliderStart" />
            <Text>Add window after start time</Text>
            <span className={styles["description-text"]}>
              <Text variation="subdued">ex. 9:00 AM – 10:00 AM</Text>
            </span>
          </div>
        </RadioOption>
        <RadioOption value="centered">
          <div className={styles["icon-container"]}>
            <Icon name="sliderCenter" />
            <Text>Center window on start time</Text>
            <span className={styles["description-text"]}>
              <Text variation="subdued">ex. 8:30 AM – 9:30 AM</Text>
            </span>
          </div>
        </RadioOption>
      </RadioGroup>
    </div>
  );
}
