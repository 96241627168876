enum DispatchType {
  ACTIVE_DISPATCH = 0,
  PERIODIC_DISPATCH = 1,
  NEVER_DISPATCH = 2,
}

export const START_AT_DATE_KEY = "start_at_date";
export const END_AT_DATE_KEY = "end_at_date";
export const START_AT_TIME_KEY = "initial_start_time";
export const END_AT_TIME_KEY = "initial_end_time";
export const SCHEDULED_KEY = "scheduled";
export const ACTIVE_DISPATCH_CHECK_BOX_KEY = "active_dispatch_check_box";
export const DISPATCH_TYPE_KEY = "dispatch_type";
export const DISPATCH_RRULE_KEY = "dispatch_rrule";
export const ACTIVE_DISPATCH = DispatchType.ACTIVE_DISPATCH;
export const PERIODIC_DISPATCH = DispatchType.PERIODIC_DISPATCH;
export const NEVER_DISPATCH = DispatchType.NEVER_DISPATCH;
export const SCHEDULE_RECOMMENDATION_SELECTED_KEY =
  "schedule_recommendation_selected";
export const SCHEDULE_DATA_FOR_LOGS = "schedule_data_for_logs";
