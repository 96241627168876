import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  MarkDemoCampaignAutomationActiveMutation,
  MarkDemoCampaignAutomationActiveMutationVariables,
} from "~/utilities/API/graphql";
import { MARK_DEMO_CAMPAIGN_AUTOMATION_ACTIVE } from "./useMarkDemoCampaignAutomationActiveMutation.graphql";

export interface MarkDemoCampaignAutomationActiveMutationProps {
  input: MarkDemoCampaignAutomationActiveMutationVariables;
  onError: () => void;
  onCompleted: (data: MarkDemoCampaignAutomationActiveMutation) => void;
}

export function useMarkDemoCampaignAutomationActiveMutation() {
  const [markDemoCampaignAutomationActiveMutation, { loading, data }] =
    useMutation<
      MarkDemoCampaignAutomationActiveMutation,
      MarkDemoCampaignAutomationActiveMutationVariables
    >(MARK_DEMO_CAMPAIGN_AUTOMATION_ACTIVE);

  const markDemoCampaignAutomationActive = useCallback(
    async ({
      input,
      onError,
      onCompleted,
    }: MarkDemoCampaignAutomationActiveMutationProps) => {
      const mutationResult = await markDemoCampaignAutomationActiveMutation({
        variables: input,
        onError: onError,
        onCompleted: onCompleted,
      });

      return {
        campaign:
          mutationResult.data?.markDemoCampaignAutomationActive.campaign,
        userErrors:
          mutationResult.data?.markDemoCampaignAutomationActive.userErrors ??
          [],
      };
    },
    [markDemoCampaignAutomationActiveMutation],
  );
  return { markDemoCampaignAutomationActive, loading, data };
}
