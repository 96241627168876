import { Checkbox } from "@jobber/components/Checkbox";
import React, { useEffect } from "react";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Heading } from "@jobber/components/Heading";
import { Spinner } from "@jobber/components/Spinner";
import { InputValidation } from "@jobber/components/InputValidation";
import { RegistrationBusinessInformation } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationBusinessInformation/RegistrationBusinessInformation";
import type { TwilioRegistrationFormReducerState } from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import { AddressForm } from "jobber/addressForm/components";
import {
  hasInvalidZipCode,
  hasPOBoxAddress,
} from "jobber/settings/dedicatedPhoneNumber/utilities/addressHelpers";
import { RegistrationPointOfContact } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationPointOfContact/RegistrationPointOfContact";
import {
  isUrlValid,
  websiteUrlErrors,
} from "jobber/settings/dedicatedPhoneNumber/utilities/websiteUrlHelpers";
import { SOLE_PROPRIETORSHIP } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationBusinessInformation/RegistrationBusinessInformationData";
import { USStateList } from "./USStateList";
import styles from "./TwilioRegistrationForm.module.css";

interface Props {
  loading: boolean;
  state: TwilioRegistrationFormReducerState;
  handleUpdate(data: Partial<TwilioRegistrationFormReducerState>): void;
}

export function TwilioRegistrationForm({
  loading,
  state,
  handleUpdate,
}: Props) {
  const errorMessages = {
    street1: "Street is required",
    city: "City is required",
    province: "State is required",
    pc: "ZIP code is required",
    websiteUrl: "Business website or social media url is required",
    checkbox: "Confirmation required to complete registration",
  };

  useEffect(() => {
    // set
    if (state.street1 && hasPOBoxAddress(state.street1)) {
      handleUpdate({
        formErrors: {
          ...state.formErrors,
          address: "Address must not be a PO box",
        },
      });
    } else if (state.formErrors?.address) {
      // reset
      handleUpdate({
        formErrors: { ...state.formErrors, address: undefined },
      });
    }
  }, [state.street1]);

  useEffect(() => {
    // set
    if (state.pc && hasInvalidZipCode(state.pc)) {
      handleUpdate({
        formErrors: { ...state.formErrors, pc: "ZIP code is invalid" },
      });
    } else if (state.formErrors?.pc) {
      // reset
      handleUpdate({
        formErrors: { ...state.formErrors, pc: undefined },
      });
    }
  }, [state.pc]);

  function isWebsiteUrlInvalid() {
    return state.formSubmitted && !isUrlValid(state.websiteUrl);
  }

  const pointOfContactFields = {
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    phone: state.phone,
    jobPosition: state.jobPosition,
    businessTitle: state.businessTitle,
  };

  return (
    <Form>
      {loading && (
        <div className="columns centerContainer">
          <Spinner />
        </div>
      )}

      {!loading && (
        <>
          <RegistrationBusinessInformation
            state={state}
            handleUpdate={handleUpdate}
            formSubmitted={state.formSubmitted}
          />

          <div className={styles.formHeader}>
            <Heading level={5}>
              Business address (non-PO box address required)
            </Heading>
          </div>
          <div className={styles.inputField}>
            <Address
              state={state}
              handleUpdate={handleUpdate}
              errorMessages={errorMessages}
            />
          </div>

          <RegistrationPointOfContact
            fields={pointOfContactFields}
            formErrors={state.formErrors}
            formSubmitted={state.formSubmitted}
            handleUpdate={handleUpdate}
            smsablePhoneError={state.smsablePhoneError}
            solePropSelected={state.businessType === SOLE_PROPRIETORSHIP}
          />

          <div className={styles.formHeader}>
            <Heading level={5}>Website or social media link</Heading>
          </div>
          <div className={styles.inputField}>
            <InputText
              placeholder="Business website or social media url"
              name="websiteUrl"
              value={state.websiteUrl}
              invalid={isWebsiteUrlInvalid()}
              onChange={onWebsiteUrlChange}
            />
            {state.formSubmitted && !state.websiteUrl && (
              <InputValidation message={websiteUrlErrors.required} />
            )}
            {state.formSubmitted &&
              state.websiteUrl &&
              isWebsiteUrlInvalid() && (
                <InputValidation message={websiteUrlErrors.formatError} />
              )}
          </div>

          <div className={styles.formHeader}>
            <Checkbox
              name="poc_checkbox"
              checked={state.pocCheckbox}
              label="I understand the point of contact identified will be used to complete messaging service registration and may also be contacted for validation of business details"
              onChange={onCheckboxChange}
              value={"poc_checkbox"}
            />
          </div>
          {state.formSubmitted && !state.pocCheckbox && (
            <InputValidation
              message={"Confirmation required to complete registration"}
            />
          )}
        </>
      )}
    </Form>
  );

  function onWebsiteUrlChange(value: string) {
    handleUpdate({ websiteUrl: value });
  }

  function onCheckboxChange() {
    handleUpdate({ pocCheckbox: !state.pocCheckbox });
  }
}

interface ErrorMessages {
  street1: string;
  city: string;
  province: string;
  pc: string;
}

interface AddressProps {
  state: TwilioRegistrationFormReducerState;
  errorMessages: ErrorMessages;
  handleUpdate(data: Partial<TwilioRegistrationFormReducerState>): void;
}

function Address({ state, handleUpdate, errorMessages }: AddressProps) {
  return (
    <AddressForm
      street1Input={{
        value: state.street1 || "",
        onChange: onStreet1Change,
        errorMessage: areErrorsInAddressForm("street1"),
      }}
      street2Input={{
        value: state.street2 || "",
        placeholder: "Street 2 (Optional)",
        onChange: onStreet2Change,
      }}
      cityInput={{
        value: state.city || "",
        onChange: onCityChange,
        errorMessage: areErrorsInAddressForm("city"),
      }}
      provinceInput={{
        value: state.province || "",
        provinceList: USStateList,
        onChange: onProvinceChange,
        errorMessage: areErrorsInAddressForm("province"),
      }}
      postalCodeInput={{
        value: state.pc || "",
        onChange: onPcChange,
        errorMessage: areErrorsInAddressForm("pc"),
      }}
      countryPicker={{
        ariaLabel: "Country",
        priorityCountries: ["United States"],
        allCountries: ["United States"],
        value: "United States",
        name: "country",
        onChange: onCountryChange,
      }}
    />
  );

  function areErrorsInAddressForm(
    field: keyof ErrorMessages,
  ): string | undefined {
    if (
      state.formSubmitted &&
      field === "street1" &&
      state.formErrors?.address
    ) {
      return state.formErrors?.address;
    }

    if (state.formSubmitted && field === "pc" && state.formErrors?.pc) {
      return state.formErrors?.pc;
    }

    if (state.formSubmitted || state[field]) {
      return state[field] ? undefined : errorMessages[field];
    }
  }

  function onStreet1Change(value: string) {
    handleUpdate({ street1: value });
  }

  function onStreet2Change(value: string) {
    handleUpdate({ street2: value });
  }

  function onCityChange(value: string) {
    handleUpdate({ city: value });
  }

  function onProvinceChange(value: string) {
    handleUpdate({ province: value });
  }

  function onPcChange(value: string) {
    handleUpdate({ pc: value });
  }

  function onCountryChange(value: string) {
    handleUpdate({ country: value });
  }
}
