import { Amplitude } from "~/utilities/analytics/Amplitude";

export const trackExternalLinkOpen = (
  source: string,
  type: "link_preview" | "inline",
) => {
  Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
    name: "link_click",
    type,
    source,
  });
};
