/* eslint-disable max-statements */
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import type {
  JobCostExpenseFragment,
  JobCostLineItemFragment,
  JobCostQueryQuery,
  JobCostTimeSheetEntryFragment,
  JobDetailsFragment,
  JobViewProfit,
  User,
} from "~/utilities/API/graphql";
import {
  JOB_COST_EXPENSES_QUERY,
  JOB_COST_LINE_ITEMS_QUERY,
  JOB_COST_QUERY,
  JOB_COST_TIME_SHEET_ENTRIES_QUERY,
} from "./graphql";
import type { JobQueryVariablesType } from "./types";

interface UseJobCostQueryProps {
  jobId: string;
  canViewJobCosts: boolean;
  canViewExpenses: boolean;
  canViewTimeSheetEntries: boolean;
  canViewPricing: boolean;
  authorizationLoading: boolean;
}

interface UseJobCostQuery {
  timesheets?: JobCostTimeSheetEntryFragment[];
  timesheetsTotalCost: number;
  timesheetsTotalDuration: number;
  expenses?: JobCostExpenseFragment[];
  expensesTotal: number;
  lineItems?: JobCostLineItemFragment[];
  lineItemsTotalCost: number;
  lineItemsTotalPrice: number;
  jobCosting?: JobViewProfit;
  job?: JobDetailsFragment;
  user?: User;
  loading: boolean;
  error?: string;
  hasMoreExpenses: boolean;
  hasMoreTimesheets: boolean;
  hasMoreLineItems: boolean;
  fetchMoreExpenses: () => Promise<void>;
  fetchMoreTimesheets: () => Promise<void>;
  fetchMoreLineItems: () => Promise<void>;
}

export function useJobCostQuery({
  jobId,
  canViewExpenses,
  canViewJobCosts,
  canViewTimeSheetEntries,
  canViewPricing,
  authorizationLoading,
}: UseJobCostQueryProps): UseJobCostQuery {
  const [expensesCursor, setExpensesCursor] = useState<string | undefined>(
    undefined,
  );
  const [labourRatesCursor, setLabourRatesCursor] = useState<
    string | undefined
  >(undefined);
  const [lineItemsCursor, setLineItemsCursor] = useState<string | undefined>(
    undefined,
  );

  const variables: JobQueryVariablesType = {
    jobId: btoa(jobId),
    canViewJobCosts,
    canViewExpenses,
    canViewTimeSheetEntries,
    canViewPricing,
  };

  const { data, loading, error, fetchMore } = useQuery<JobCostQueryQuery>(
    JOB_COST_QUERY,
    {
      variables,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      skip: authorizationLoading,
    },
  );

  const cursors = {
    expenses: data?.jobView?.expenses?.items.pageInfo?.endCursor,
    timeSheetEntries: data?.jobView?.labour?.items?.pageInfo?.endCursor,
    lineItems: data?.jobView?.lineItems?.items.pageInfo?.endCursor,
  };

  useEffect(() => {
    // set initial cursors on the first fetch
    setExpensesCursor(cursors.expenses);
    setLabourRatesCursor(cursors.timeSheetEntries);
    setLineItemsCursor(cursors.lineItems);
  }, [cursors.expenses, cursors.lineItems, cursors.timeSheetEntries]);

  const fetchMoreData = async (
    type: "expenses" | "lineItems" | "timeSheetEntries",
  ) => {
    let query;

    if (type === "expenses") {
      query = JOB_COST_EXPENSES_QUERY;
      variables.expensesCursor = expensesCursor;
    } else if (type === "timeSheetEntries") {
      query = JOB_COST_TIME_SHEET_ENTRIES_QUERY;
      variables.timeSheetsCursor = labourRatesCursor;
    } else {
      query = JOB_COST_LINE_ITEMS_QUERY;
      variables.lineItemsCursor = lineItemsCursor;
    }

    const { data: newData } = await fetchMore<JobCostQueryQuery>({
      query,
      variables,
    });

    if (type === "expenses") {
      setExpensesCursor(newData.jobView?.expenses?.items.pageInfo?.endCursor);
    } else if (type === "timeSheetEntries") {
      setLabourRatesCursor(newData.jobView?.labour?.items?.pageInfo?.endCursor);
    } else {
      setLineItemsCursor(newData.jobView?.lineItems?.items.pageInfo?.endCursor);
    }
    return;
  };

  return {
    timesheets: data?.jobView?.labour?.items.nodes,
    expenses: data?.jobView?.expenses?.items.nodes,
    lineItems: data?.jobView?.lineItems.items.nodes,
    jobCosting: data?.jobView?.profit,
    timesheetsTotalDuration: data?.jobView?.labour?.totalDuration || 0,
    timesheetsTotalCost: data?.jobView?.labour?.totalCost || 0,
    expensesTotal: data?.jobView?.expenses?.total || 0,
    lineItemsTotalPrice: data?.jobView?.lineItems?.totalPrice || 0,
    lineItemsTotalCost: data?.jobView?.lineItems?.totalCost || 0,
    job: data?.jobView,
    user: data?.account?.currentUser as User,
    loading,
    error: error?.message,
    hasMoreExpenses:
      data?.jobView?.expenses?.items.pageInfo?.hasNextPage || false,
    hasMoreTimesheets:
      data?.jobView?.labour?.items.pageInfo?.hasNextPage || false,
    hasMoreLineItems:
      data?.jobView?.lineItems?.items.pageInfo?.hasNextPage || false,
    fetchMoreExpenses: () => fetchMoreData("expenses"),
    fetchMoreTimesheets: () => fetchMoreData("timeSheetEntries"),
    fetchMoreLineItems: () => fetchMoreData("lineItems"),
  };
}
