import { defineMessages } from "react-intl";

export const messages = defineMessages({
  connectImage: {
    id: "automed_reviews.images.connect",
    defaultMessage:
      "A dashed line connecting three elements; Jobber, Google and a satisfied client leaving a five star Google review",
    description: "Alt text for image on connect step",
  },
  messagingImage: {
    id: "automated_reviews.images.messaging",
    defaultMessage:
      "A client leaving a five star Google review that states: 'Their people are always there when we need them and they do an amazing job with anything we throw at them!'",
    description: "Alt text for image on the personalize step",
  },
  requestsImage: {
    id: "automated_reviews.images.requestsImage",
    defaultMessage:
      "A client with 'Ask for review text displayed underneath their profile with a decision between answering yes or no. Yes is selected.",
    description: "Alt text for image on the complete setup step",
  },
  connectedImage: {
    id: "automated_reviews.images.connectedImage",
    defaultMessage:
      "A dashed line connecting three elements; Jobber, Google and a satisfied client leaving a five star Google review",
    description: "Alt text for image on the complete setup step",
  },
});
