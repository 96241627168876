import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";

export interface BottomBarDefaultFlowProps {
  enableAutomation: boolean | undefined;
  onNext: () => void;
  setShowScheduleSendModal: (value: boolean) => void;
  validateCampaignForSending: () => boolean;
  loadingAll: boolean;
  submitButtonLabel: string;
}

export function BottomBarDefaultFlow({
  enableAutomation,
  onNext,
  setShowScheduleSendModal,
  validateCampaignForSending,
  loadingAll,
  submitButtonLabel,
}: BottomBarDefaultFlowProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      {!enableAutomation && !loadingAll && (
        <Button
          label={formatMessage(messages.sendCampaignLaterButton)}
          onClick={() => {
            if (validateCampaignForSending()) {
              setShowScheduleSendModal(true);
            }
          }}
          variation={"work"}
          type={"tertiary"}
          fullWidth={true}
          loading={loadingAll}
        />
      )}
      <Button
        label={submitButtonLabel}
        onClick={() => onNext()}
        variation={"work"}
        fullWidth={true}
        loading={loadingAll}
      />
    </>
  );
}
