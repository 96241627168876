import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import React from "react";
import styles from "./MessageStatusDescription.module.css";

interface MessageStatusProps {
  variation: "error" | "subdued";
  icon?: "alert" | "checkmark";
  description: string;
}

export function MessageStatusDescription({
  variation,
  icon,
  description,
}: MessageStatusProps) {
  const iconColor = variation === "error" ? "criticalOnSurface" : "greyBlue";

  return (
    <div className={styles.statusDescription}>
      {icon && <Icon name={icon} color={iconColor} size="small" />}
      <Text variation={variation} size="small">
        {description}
      </Text>
    </div>
  );
}
