import { useMemo } from "react";
import type {
  DateClickArg,
  EventDragStopArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import type { InterpreterFrom } from "xstate";
import { add } from "date-fns";
import { makeFullCalendarBusinessHours } from "jobber/workOrders/components/SchedulingAssistant/utils/makeFullCalendarBusinessHours";
import type { DayViewProps } from "jobber/workOrders/components/SchedulingAssistant/components/DayView/DayView";
import { useAssignTeam } from "jobber/workOrders/components/AssignTeam";
import type { SchedulingAssistantMachine } from "jobber/workOrders/components/SchedulingAssistant/machines/schedulingAssistant.machine";
import { useSchedulingAssistantMachine } from "./useSchedulingAssistantMachine";
import { useSchedulingAvailability } from "./useSchedulingAvailability";
import { useAssistantCalendarEvents } from "./useAssistantCalendarEvents";
import {
  type Month,
  type Year,
  type YearMonthDay,
  toYearMonthDay,
  yearMonthDayToDate,
} from "../yearMonthDay";

export const useSchedulingAssistant = ({
  initialDurationInSeconds,
  initialSelectedDate,
  initialSelectedTeamMembers,
  initialSelectedTime,
  location,
}: {
  initialDurationInSeconds: number;
  initialSelectedDate: YearMonthDay;
  initialSelectedTeamMembers: Map<string, boolean>;
  initialSelectedTime?: { start: Date; assigneeId: string };
  location?: { latitude: number; longitude: number };
}) => {
  const { assignableUsers } = useAssignTeam();
  const [calendarRef, state, send] = useSchedulingAssistantMachine({
    initialDurationInSeconds,
    initialSelectedDate,
    initialSelectedTeamMembers,
    initialSelectedTime,
    location,
  });

  const employees: DayViewProps["employees"] = assignableUsers.flatMap(user => {
    if (state.context.selectedTeamMembers.get(user.id) === false) {
      return [];
    }

    return [
      {
        id: user.id,
        title: user.name.full,
        businessHours: makeFullCalendarBusinessHours(
          state.context.businessHours,
        ),
      },
    ];
  });

  const selectedEndTime = useMemo(() => {
    if (state.context.selectedTime?.start) {
      return add(state.context.selectedTime.start, {
        seconds: state.context.durationInSeconds,
      });
    }
  }, [state.context.durationInSeconds, state.context.selectedTime]);

  const { datesWithAvailability, selectedDayAvailability } =
    useSchedulingAvailability(
      state.context.selectedDate,
      state.context.availability,
      state.context.driveTimeLimitInSeconds,
      state.context.selectedTeamMembers,
    );

  const events = useAssistantCalendarEvents({
    availability: selectedDayAvailability,
    appointments: state.context.appointments,
    selectedSlot: state.context.selectedTime,
    durationInSeconds: state.context.durationInSeconds,
  });

  const selectedDate = useMemo(
    () => yearMonthDayToDate(state.context.selectedDate),
    [state.context.selectedDate],
  );

  return {
    filters: {
      driveTime: undefined,
      employees,
    },
    selectedDate,
    selectedTime: state.context.selectedTime,
    selectedEndTime: selectedEndTime,
    selectedTeamMembers: state.context.selectedTeamMembers,
    events,
    durationInSeconds: state.context.durationInSeconds,
    driveTimeLimitInSeconds: state.context.driveTimeLimitInSeconds,
    datesWithAvailability,
    availabilityLoading: state.hasTag("loadingAvailability"),
    calendarRef: calendarRef,
    ...useCallbacks(send, assignableUsers.length),
  };
};

export type SchedulingAssistantAPI = ReturnType<typeof useSchedulingAssistant>;

const AVAILABLE_SLOT_CLASSNAME = "available-slot";

export const AVAILABLE_SLOTS_STYLES = {
  classNames: [AVAILABLE_SLOT_CLASSNAME],
  borderColor: `var(--color-interactive)`,
  backgroundColor: `var(--color-surface)`,
  isRecommendedSlot: true,
  allDay: false,
};

function useCallbacks(
  send: InterpreterFrom<SchedulingAssistantMachine>["send"],
  assignableUsersCount: number,
) {
  return useMemo(() => {
    return {
      onSelectDuration: (durationInSeconds: number) =>
        send({ type: "SELECT_DURATION", durationInSeconds }),
      onSelectDriveTimeLimit: (driveTimeLimitInSeconds: number) =>
        send({ type: "SELECT_DRIVE_TIME_LIMIT", driveTimeLimitInSeconds }),
      onSelectDate: (date: Date) =>
        send({ type: "SELECT_DATE", date: toYearMonthDay(date) }),
      onDragSelection: (event: EventDragStopArg["event"]) =>
        event.start instanceof Date &&
        send({
          type: "DRAG_AND_DROP",
          date: event.start,
          assigneeId: event.getResources()[0].id,
        }),
      onResizeSelection: (event: EventResizeDoneArg["event"]) => {
        if (event.end instanceof Date && event.start instanceof Date) {
          send({
            type: "RESIZE",
            startAt: event.start,
            durationInSeconds:
              (event.end.getTime() - event.start.getTime()) / 1000,
          });
        }
      },
      onSelectSlot: (event: DateClickArg) =>
        event.resource &&
        send({
          type: "SELECT_SLOT",
          date: event.date,
          assigneeId: event.resource.id,
        }),
      onMonthChange: (month: Month, year: Year) =>
        send({ type: "SELECT_MONTH", month, year }),
      onSelectTeamMembers: (
        selectedTeamMembers: Map<string, boolean>,
        // assignableUsersCount: number,
      ) =>
        send({
          type: "SELECT_TEAM_MEMBERS",
          selectedTeamMembers,
          assignableUsersCount,
        }),
      onSave: () => send({ type: "SAVE_MODAL" }),
    };
  }, [assignableUsersCount, send]);
}
