import React from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./WistiaPlayer.module.css";

export interface WistiaPlayerProps {
  videoId: string;
  popover: boolean;
  trackEmail?: string;
  onPlay?(): void;
  onPause?(): void;
  onSeek?(): void;
  onEnded?(): void;
}

export function WistiaPlayer({
  videoId,
  popover,
  trackEmail,
  onPlay,
  onPause,
  onSeek,
  onEnded,
}: WistiaPlayerProps) {
  const wistiaConfig = {
    options: {
      popover: popover,
      popoverAnimateThumbnail: popover,
      videoFoam: true,
      trackEmail: trackEmail,
    },
  };

  return (
    <div
      className={classNames("wistiaVideoWrapperGlobal", styles.playerWrapper)}
    >
      <ReactPlayer
        className={classNames("wistiaVideoPlayerGlobal", styles.player)}
        onPlay={handlePlay}
        onPause={handlePause}
        onSeek={handleSeek}
        onEnded={handleEnded}
        width="100%"
        height="100%"
        url={`https://getjobber-1.wistia.com/medias/${videoId}`}
        config={{ wistia: wistiaConfig }}
      />
    </div>
  );

  function handlePlay() {
    track("play");
    if (onPlay !== undefined) {
      onPlay();
    }
  }

  function handlePause() {
    track("pause");
    if (onPause !== undefined) {
      onPause();
    }
  }

  function handleSeek() {
    track("seek");
    if (onSeek !== undefined) {
      onSeek();
    }
  }

  function handleEnded() {
    track("ended");
    if (onEnded !== undefined) {
      onEnded();
    }
  }

  function track(eventType: string) {
    Amplitude.TRACK_EVENT("Interacted with Video", {
      value: videoId,
      type: eventType,
    });
  }
}
