import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  MarkDemoCampaignSentMutation,
  MarkDemoCampaignSentMutationVariables,
} from "~/utilities/API/graphql";
import { MARK_DEMO_CAMPAIGN_SENT } from "./useMarkDemoCampaignSentMutation.graphql";

export interface MarkDemoCampaignSentMutationProps {
  input: MarkDemoCampaignSentMutationVariables;
  onError: () => void;
  onCompleted: (data: MarkDemoCampaignSentMutation) => void;
}

export function useMarkDemoCampaignSentMutation() {
  const [sendDemoOneOffCampaignMutation, { loading, data }] = useMutation<
    MarkDemoCampaignSentMutation,
    MarkDemoCampaignSentMutationVariables
  >(MARK_DEMO_CAMPAIGN_SENT);

  const markDemoCampaignSent = useCallback(
    async ({
      input,
      onError,
      onCompleted,
    }: MarkDemoCampaignSentMutationProps) => {
      const mutationResult = await sendDemoOneOffCampaignMutation({
        variables: input,
        onError: onError,
        onCompleted: onCompleted,
      });
      return {
        campaign: mutationResult.data?.markDemoCampaignSent.campaign,
        userErrors: mutationResult.data?.markDemoCampaignSent.userErrors ?? [],
      };
    },
    [sendDemoOneOffCampaignMutation],
  );
  return { markDemoCampaignSent, loading, data };
}
