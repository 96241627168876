import { Button, type ButtonProps } from "@jobber/components/Button";
import React from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Text } from "@jobber/components";
import classNames from "classnames";
import styles from "./HeaderCard.module.css";

export interface HeaderCardImage {
  src: string;
  alt: string;
}

type InlineLabelProps = Parameters<typeof InlineLabel>[0];

export interface HeaderCard {
  img: HeaderCardImage;
  title: string;
  description: string;
  action?: ButtonProps | InlineLabelProps;
  inlineLabel?: string;
}

export interface HeaderCardProps {
  readonly card: HeaderCard;
}
export function HeaderCard({ card }: HeaderCardProps) {
  const { title, description, action, img, inlineLabel } = card;

  return (
    <div className={styles.container}>
      <img alt={img.alt} src={img.src} className={styles.cardImage} />
      <div className={styles.contentContainer}>
        <div className={styles.contentText}>
          <Content spacing="base">
            {inlineLabel && <InlineLabel>{inlineLabel}</InlineLabel>}
            <Heading level={5}>{title}</Heading>
            <Text>{description}</Text>
          </Content>
        </div>
        <Action action={action} />
      </div>
    </div>
  );
}

function Action({ action }: { action: HeaderCardProps["card"]["action"] }) {
  if (!action) {
    return null;
  } else if ("children" in action) {
    return (
      <div className={styles.actionContainer}>
        <InlineLabel {...action} />
      </div>
    );
  } else {
    return (
      <div
        className={classNames(styles.actionContainer, {
          [styles.actionContainerPadding]: addPadding(action),
        })}
      >
        {action?.loading ? (
          <Glimmer size={"large"} width={95} shape={"rectangleShort"} />
        ) : (
          <Button {...action} />
        )}
      </div>
    );
  }
}

function addPadding(action: ButtonProps) {
  return (
    !action.disabled &&
    (action.type === "tertiary" ||
      (action.type === "secondary" && action.variation === "subtle"))
  );
}
