import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type {
  CreateJobCostingMutation,
  CreateJobCostingMutationVariables,
} from "~/utilities/API/graphql";
import { JOB_COST_PROFIT_QUERY, JobCostingData } from "../../hooks";

export const CREATE_JOB_COSTING_MUTATION = gql`
  mutation CreateJobCosting($jobId: EncodedId!) {
    jobCreateJobCosting(jobId: $jobId) {
      userErrors {
        message
        path
      }
      jobCosting {
        ...jobCostingData
      }
    }
  }
  ${JobCostingData}
`;

export const useCreateJobCostingMutation = (jobId: string) => {
  const { can } = useAuthorization();
  const canViewJobCosts = can("view", "JobCosts");
  const [_createJobCost, { loading }] = useMutation<
    CreateJobCostingMutation,
    CreateJobCostingMutationVariables
  >(CREATE_JOB_COSTING_MUTATION, {
    refetchQueries: [
      {
        query: JOB_COST_PROFIT_QUERY,
        variables: {
          jobId,
          canViewJobCosts,
        },
      },
    ],
  });

  const createJobCost = useCallback(async () => {
    const data = await _createJobCost({ variables: { jobId } });
    if (data.data?.jobCreateJobCosting.userErrors.length) {
      throw new Error();
    }

    return data;
  }, [_createJobCost, jobId]);

  return {
    loading,
    createJobCost,
  };
};
