import type { ColumnDef } from "@jobber/components/DataTable";
import type { ReportColumn } from "~/jobber/features/Reporting/components/Report/types";

// TODO JOB-70613: Remove this function
// The franchise reports are using the ColumnDef < T > type, which doesn't have the available or visible fields
// We need this function in order to handle the different types until we can migrate the franchise reports to the new data type
export function isReportColumn<T>(
  column: ColumnDef<T> | ReportColumn<T>,
): column is ReportColumn<T> {
  return (
    column &&
    Object.keys(column).includes("available") &&
    Object.keys(column).includes("visible")
  );
}
