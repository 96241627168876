/**
 * Use this component only when you want to prevent Datadog from redacting certain information.
 * This component is a wrapper that uses a specific action name to bypass redacting it on Datadog.
 * It should be used sparingly and only when necessary, as it may expose PII data.
 *
 * The purpose of adding this component is give a provision to wrap Atlantis component with a
 * parent that accepts data-custom-action-name attribute as Atlantis components do not accept
 * data attributes at the time of writing this.
 *
 * https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/#declare-a-name-for-click-actions
 */
import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { DATADOG_RUM_REDACT_PATTERN } from "./constants";
import styles from "./styles.module.css";

interface DatadogNoRedactWrapperProps {
  /**
   * Session log action name to be used for the action.
   */
  actionName: string;

  /**
   * Reveal the labels on replay. This reveals the UI labels and not the
   * `actionName` prop label in DataDog.
   */
  revealOnReplay?: boolean;
}

export function DatadogNoRedactWrapper({
  actionName,
  revealOnReplay = false,
  children,
}: PropsWithChildren<DatadogNoRedactWrapperProps>) {
  return (
    <div
      className={classNames(styles.noRedact)}
      data-dd-privacy={revealOnReplay ? "allow" : undefined}
      data-custom-action-name={`${DATADOG_RUM_REDACT_PATTERN}${actionName}`}
    >
      {children}
    </div>
  );
}
