type cardInputType = "cardNumber" | "cardExpiry" | "cardCvc" | "postalCode";

function getCardBrandImage(brand: string) {
  switch (brand) {
    case "American Express":
      return "cc-american-express";
    case "Discover":
      return "cc-discover";
    case "JCB":
      return "cc-jcb";
    case "MasterCard":
      return "cc-mastercard";
    case "Visa":
      return "cc-visa";
    default:
      return "card";
  }
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class FormInputHandler {
  private readonly form: HTMLElement;

  constructor(form: HTMLElement) {
    this.form = form;
  }

  public handleCardBrandChanged = (card: string) => {
    const element = this.form.querySelector<HTMLImageElement>(".js-cardImage");
    if (element) {
      const cardImg = getCardBrandImage(card);

      element.src = window.SG1_IMAGE_MANIFEST[`svg/${cardImg}.svg`];
    }
  };

  public handleInput = (
    inputType: cardInputType,
    empty: boolean,
    error?: string,
  ) => {
    const input = this.form.querySelector(`.js-${inputType}`) as HTMLElement;
    const label = this.form.querySelector(
      `label[for="${inputType}"]`,
    ) as HTMLLabelElement;
    const errorLabel = this.form.querySelector(
      `.js-${inputType}Error`,
    ) as HTMLElement;

    if (empty !== true) {
      label.classList.add("is-hidden");
    } else {
      label.classList.remove("is-hidden");
    }

    if (error) {
      input.classList.add("is-invalid");
    } else {
      input.classList.remove("is-invalid");
    }

    if (errorLabel) {
      errorLabel.textContent = error || "";
    }
  };

  public handleBlur = (inputType: cardInputType) => {
    const input = this.form.querySelector(`.js-${inputType}`) as HTMLElement;
    input.classList.remove("is-focus");
  };

  public handleFocus = (inputType: cardInputType) => {
    const input = this.form.querySelector(`.js-${inputType}`) as HTMLElement;
    input.classList.add("is-focus");
  };
}
