import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { VerifyOTPPhoneModal } from "./components/VerifyOTPPhoneModal";

export interface VerifyOTPPhoneRailsProps {
  phone: string;
  cancelPath: string;
  sendCodePath: string;
}

export function VerifyOTPPhoneRails(props: VerifyOTPPhoneRailsProps) {
  return (
    <APIProvider>
      <VerifyOTPPhoneModal
        {...props}
        open={true}
        onRequestSuccess={goForwards}
        onRequestClose={goBack}
      />
    </APIProvider>
  );

  function goForwards() {
    window.location.assign(props.sendCodePath);
  }

  function goBack() {
    window.location.assign(props.cancelPath);
  }
}
