import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage } = createIntl();

export function addOptionalLineItemCtaLabel() {
  return screen.getByText(formatMessage(messages.addOptionalLineItemCtaLabel));
}

export function addOptionalLineItemLockIcon() {
  return screen.getByTestId("lock");
}

export function addOptionalLineItemCtaTooltip() {
  return screen.getByText(formatMessage(messages.tooltipMessage));
}

export function addOptionalLineItemCtaButton() {
  return screen.getByRole("button", {
    name: formatMessage(messages.addOptionalLineItemCtaLabel),
  });
}

/***************** Simulators ********************/

export function clickAddOptionalLineItemButton() {
  addOptionalLineItemCtaButton().click();
}

export function hoverOverAddOptionalLineItemButton() {
  userEvent.hover(addOptionalLineItemCtaButton());
}
