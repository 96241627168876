import React, { useEffect } from "react";
import { isAfter, parseISO, sub } from "date-fns";
import {
  getLocalStorageJSON,
  writeDataToLocalStorage,
} from "utilities/localStorage";

interface TooltipEventTrackerProps extends React.PropsWithChildren {
  eventCallback: () => void;
  localStorageKey?: string;
  localStorageDelayInHours?: number;
  timeout?: number;
}

export const TooltipEventTracker = ({
  children,
  eventCallback,
  localStorageKey,
  localStorageDelayInHours = 1,
  timeout = 1000,
}: TooltipEventTrackerProps) => {
  useEffect(() => {
    // Wrap the event callback in a function that checks local storage for the last time the event was triggered
    // and does not call the callback if it was within the last hour.
    const wrappedEventCallback = () => {
      const localStorageValue =
        localStorageKey && getLocalStorageJSON(localStorageKey);

      if (
        localStorageValue &&
        isAfter(
          parseISO(localStorageValue),
          sub(new Date(), { hours: localStorageDelayInHours }),
        )
      ) {
        return;
      }

      writeDataToLocalStorage([{ key: localStorageKey, value: new Date() }]);
      eventCallback();
    };

    const timer = wrappedEventCallback
      ? setTimeout(wrappedEventCallback, timeout)
      : undefined;

    return () => {
      timer && clearTimeout(timer);
    };
  }, [eventCallback, localStorageKey, localStorageDelayInHours, timeout]);

  return <>{children}</>;
};
