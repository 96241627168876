import GrowTrialOnboardingChecklistQuoteFollowups from "@images/GrowTrialOnboardingChecklistQuoteFollowups.png";
import GrowTrialOnboardingChecklistTWSMS from "@images/GrowTrialOnboardingChecklistTWSMS.png";
import GrowTrialOnboardingChecklistLocationTimers from "@images/GrowTrialOnboardingChecklistLocationTimers.png";
import GrowTrialOnboardingChecklistOptionalLineItems from "@images/GrowTrialOnboardingChecklistOptionalLineItems.png";
import type { SetupGuidePanelProps } from "jobber/dashboard/components/SetupGuide/components/SetupGuidePanel";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

export const LARGE_SCREEN_WIDTH = 1080;
export const MOBILE_SCREEN_WIDTH = 414;
export const SMALL_SCREEN_WIDTH = 768;

export const SET_UP_TWSMS_GUIDE: SetupGuidePanelProps = {
  img: {
    src: GrowTrialOnboardingChecklistTWSMS as string,
    altText: messages.twsmsAltText.defaultMessage,
  },
  content: {
    heading: messages.twsmsHeading.defaultMessage,
    subheading: messages.twsmsSubHeading.defaultMessage,
    buttonLabel: messages.twsmsButton.defaultMessage,
    buttonOnPress: () => {
      Amplitude.TRACK_EVENT("CTA Clicked", {
        name: "setup_phone_number",
        type: "two_way_sms",
        source: "connect_to_grow_trial_onboarding_checklist",
      });
      window.location.href = "/text_messaging_settings?grow_trial";
    },
    timeEstimate: "2 min",
    setupType: "two_way_sms",
  },
};

export const SET_UP_QUOTE_FOLLOWUPS_GUIDE: SetupGuidePanelProps = {
  img: {
    src: GrowTrialOnboardingChecklistQuoteFollowups as string,
    altText: messages.customizeQuoteFollowUpsAltText.defaultMessage,
  },
  content: {
    heading: messages.customizeQuoteFollowUpsHeading.defaultMessage,
    subheading: messages.customizeQuoteFollowUpsSubHeading.defaultMessage,
    buttonLabel: messages.customizeQuoteFollowUpsButton.defaultMessage,
    buttonOnPress: () => {
      Amplitude.TRACK_EVENT("CTA Clicked", {
        name: "customize_followups",
        type: "quote_followups",
        source: "connect_to_grow_trial_onboarding_checklist",
      });
      window.location.href =
        "/automations?fallback=communication_settings&grow_trial=true";
    },
    timeEstimate: "2 min",
    setupType: "quote_followups",
  },
};

export const SET_UP_LOCATION_TIMERS_GUIDE: SetupGuidePanelProps = {
  img: {
    src: GrowTrialOnboardingChecklistLocationTimers as string,
    altText: messages.turnOnLocationTimersAltText.defaultMessage,
  },
  content: {
    heading: messages.turnOnLocationTimersHeading.defaultMessage,
    subheading: messages.turnOnLocationTimersSubHeading.defaultMessage,
    buttonLabel: messages.turnOnLocationTimersButton.defaultMessage,
    buttonOnPress: () => {
      Amplitude.TRACK_EVENT("CTA Clicked", {
        name: "turn_on_location_timers",
        type: "location_timers",
        source: "connect_to_grow_trial_onboarding_checklist",
      });
      window.location.href = "/location_timers_settings";
    },
    timeEstimate: "1 min",
    setupType: "location_timers",
  },
};

export const SET_UP_OPTIONAL_LINE_ITEMS_GUIDE: SetupGuidePanelProps = {
  img: {
    src: GrowTrialOnboardingChecklistOptionalLineItems as string,
    altText: messages.addOptionalLineItemsAltText.defaultMessage,
  },
  content: {
    heading: messages.addOptionalLineItemsHeading.defaultMessage,
    subheading: messages.addOptionalLineItemsSubHeading.defaultMessage,
    buttonLabel: messages.addOptionalLineItemsButton.defaultMessage,
    buttonOnPress: () => {
      Amplitude.TRACK_EVENT("CTA Clicked", {
        name: "create_quote",
        type: "quote",
        source: "connect_to_grow_trial_onboarding_checklist",
      });
      window.location.href = "/quotes/new";
    },
    timeEstimate: "3 min",
    setupType: "quote",
  },
};

export const SET_UP_JOB_COSTING_GUIDE: SetupGuidePanelProps = {
  img: {
    src: "JobCostingVideo",
    altText: "",
  },
  content: {
    heading: messages.startTrackingJobCostsHeading.defaultMessage,
    subheading: messages.startTrackingJobCostsSubHeading.defaultMessage,
    buttonLabel: messages.startTrackingJobCostsButton.defaultMessage,
    buttonOnPress: () => {
      Amplitude.TRACK_EVENT("CTA Clicked", {
        name: "setup_labor_rates",
        type: "job_costing",
        source: "connect_to_grow_trial_onboarding_checklist",
      });
      window.location.href = "/manage_team";
    },
    timeEstimate: "3 min",
    setupType: "job_costing",
  },
};
