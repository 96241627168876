import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { messages } from "./messages";

export function LoadingError({
  source,
  logMessage,
}: {
  source: string;
  logMessage: string;
}) {
  Rollbar.EXECUTE(logMessage, new Error(source));

  const { formatMessage } = useIntl();

  return (
    <Content>
      <Text>{formatMessage(messages.errorMessage)}</Text>
    </Content>
  );
}
