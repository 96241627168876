import React, { useCallback } from "react";
import { Option, Select } from "@jobber/components/Select";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import { useSchedulingRecommendationsTracking } from "../ScheduleCard";

const MINIMUM_DURATION = 60;
const MAXIMUM_DURATION = 480;
const OTHER_DURATION_VALUES = [30, 90];

const durationValues = computeDurationValuesForDropdown();

export interface VisitDurationProps {
  value: number;
  onChange: (updatedDuration: number) => void;
  disabled: boolean;
}

export const VisitDuration = ({
  onChange,
  value,
  disabled,
}: VisitDurationProps) => {
  const { sendVisitDurationChangedEvent } =
    useSchedulingRecommendationsTracking();
  const handleDurationChange = useCallback(
    (updatedDuration: string) => {
      const duration = parseInt(updatedDuration.toString(), 10);

      sendVisitDurationChangedEvent(duration);
      onChange(duration);
    },
    [onChange, sendVisitDurationChangedEvent],
  );

  return (
    <Select
      placeholder="Visit duration"
      name="visitDuration"
      value={value.toString()}
      onChange={handleDurationChange}
      disabled={disabled}
    >
      {durationValues.map(duration => {
        return (
          <Option key={duration} value={duration.toString()}>
            {minutesToDuration(duration)}
          </Option>
        );
      })}
    </Select>
  );
};

function computeDurationValuesForDropdown() {
  const values = new Set(OTHER_DURATION_VALUES);
  for (let i = MINIMUM_DURATION; i <= MAXIMUM_DURATION; i += 60) {
    values.add(i);
  }

  return Array.from(values).sort((a, b) => a - b);
}
