import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { AiAssistantTextReviseOperation } from "~/utilities/API/graphql";

export const trackTextRewriteClicked = (
  action: AiAssistantTextReviseOperation,
  source: string,
) => {
  Amplitude.TRACK_EVENT("Clicked Button", {
    name: "text_rewrite_clicked",
    action,
    source,
  });
};

export const trackRewriteMenuOpenClick = (source: string) => {
  Amplitude.TRACK_EVENT("Clicked Button", {
    name: "text_rewrite_menu_opened",
    source,
  });
};
