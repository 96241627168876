import { gql } from "@apollo/client";

export const SET_CAPTURE_USER_EVENT_MUTATION = gql`
  mutation SetCaptureUserEvent($event: [UserEvent!]!) {
    setCaptureUserEvent(event: $event) {
      userErrors {
        message
        path
      }
    }
  }
`;
