import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignCreatePastClientsMutation,
  CampaignCreatePastClientsMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_CREATE_PAST_CLIENTS } from "jobber/campaigns/hooks";

export function useCampaignCreatePastClientsMutation() {
  const [campaignCreatePastClientsMutation, { loading, error, data }] =
    useMutation<
      CampaignCreatePastClientsMutation,
      CampaignCreatePastClientsMutationVariables
    >(CAMPAIGN_CREATE_PAST_CLIENTS);

  const campaignCreatePastClients = useCallback(
    async (input: CampaignCreatePastClientsMutationVariables) => {
      const mutationResult = await campaignCreatePastClientsMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignCreatePastClients.userErrors ?? [];
      return {
        campaign: mutationResult.data?.campaignCreatePastClients.campaign,
        clientSegment:
          mutationResult.data?.campaignCreatePastClients.campaign
            ?.clientSegment,
        userErrors,
      };
    },
    [campaignCreatePastClientsMutation],
  );

  return {
    campaignCreatePastClients,
    loading,
    error,
    data,
  };
}
