import { useState } from "react";
import { Template } from "~/utilities/API/graphql";

export function useSentDelighter() {
  const [open, setOpen] = useState<boolean>(false);
  const [clientCount, setClientCount] = useState<number | string | null>(null);
  const [type, setType] = useState<Template>(Template.RE_ENGAGE);

  return {
    open,
    clientCount,
    type,
    setOpen,
    setClientCount,
    setType,
  };
}
