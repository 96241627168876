export const messages = {
  emailTeam: {
    id: "emailTeam",
    defaultMessage: "Email team about assignment",
    description: "Checkbox to send email to team about assignment",
  },
  emptyStateMessage: {
    id: "emptyStateMessage",
    defaultMessage: "No users are currently assigned",
    description: "When no users are currently selected",
  },
  disabledMessage: {
    id: "disabledMessage",
    defaultMessage: "Add an unscheduled visit to assign a user",
    description: "When the team assignment card is disabled",
  },
  team: {
    id: "team",
    defaultMessage: "Team",
    description: "Team title",
  },
};
