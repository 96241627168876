import { type ApolloError, useQuery } from "@apollo/client";
import { useState } from "react";
import type {
  CommsCampaignClientSegmentReviewQuery,
  CommsCampaignClientSegmentReviewQueryVariables,
} from "~/utilities/API/graphql";
import { GET_SEGMENT_INFORMATION } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/useCommsCampaignReviewQuery.graphql";

interface UseClientSegmentInformationQueryProps {
  campaignId: string;
}

interface UseClientSegmentInformationQueryReturn {
  loadingClientSegment: boolean;
  clientSegmentData?: CommsCampaignClientSegmentReviewQuery;
  clientSegmentError?: ApolloError;
  timeoutError: boolean;
}

export function useClientSegmentInformationQuery({
  campaignId,
}: UseClientSegmentInformationQueryProps): UseClientSegmentInformationQueryReturn {
  const [timeoutError, setTimeoutError] = useState(false);

  const {
    loading: loadingClientSegment,
    data: clientSegmentData,
    error: clientSegmentError,
  } = useQuery<
    CommsCampaignClientSegmentReviewQuery,
    CommsCampaignClientSegmentReviewQueryVariables
  >(GET_SEGMENT_INFORMATION, {
    skip: !campaignId,
    variables: { id: campaignId },
    onError: error => {
      const networkError = error.networkError;

      if (
        networkError &&
        (!("statusCode" in networkError) || networkError.statusCode === 504)
      ) {
        setTimeoutError(true);
      }
    },
  });

  return {
    loadingClientSegment,
    clientSegmentData,
    clientSegmentError,
    timeoutError,
  };
}
