import moment from "moment";

export function computeGranularity(expiryDate: string) {
  if (dateExpiringOver(expiryDate, 1, "days")) return "dhms";
  if (dateExpiringOver(expiryDate, 1, "hours")) return "hms";
  return "ms";
}

function dateExpiringOver(
  expiryDate: string,
  time: number,
  unitOfTime: "days" | "hours",
) {
  const momentExpiredDate = moment(Date.parse(expiryDate));
  const momentCurrentDate = moment();
  return momentExpiredDate.diff(momentCurrentDate, unitOfTime) >= time;
}
