import React from "react";
import { EmptyState } from "./EmptyState";

interface FilteredConversationsEmptyStateProps {
  filterLabel: string;
  onClearFilter(): void;
}

export function FilteredConversationsEmptyState({
  filterLabel,
  onClearFilter,
}: FilteredConversationsEmptyStateProps) {
  return (
    <EmptyState
      title={`No ${filterLabel.toLocaleLowerCase()} conversations`}
      description={`You currently don't have any ${filterLabel.toLocaleLowerCase()} conversations`}
      primaryActionLabel="View all messages"
      iconName={"sms2"}
      handlePrimaryAction={onClearFilter}
    />
  );
}
