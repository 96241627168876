import { defineMessages } from "react-intl";

export const messages = defineMessages({
  totalCostLabel: {
    id: "workOrders.lineItems.lineItemFooterTotalCost",
    defaultMessage: "Total cost",
    description: "Line items footer total section total cost label",
  },
  totalPriceLabel: {
    id: "workOrders.lineItems.lineItemFooterTotalPrice",
    defaultMessage: "Total price",
    description: "Line items footer total section total price label",
  },
});
