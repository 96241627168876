import React from "react";
import { XeroDatePicker } from "./XeroDatePicker";

export function XeroCsv() {
  const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));

  return (
    <div>
      <div>
        <h5 className={"u-marginBottomSmallest"}>Start date</h5>
        <XeroDatePicker
          name="xero_csv[start_date]"
          disabled={false}
          date={thirtyDaysAgo}
        />
      </div>
      <div className={"u-paddingTopSmaller"}>
        <h5 className={"u-marginBottomSmallest"}>End date</h5>
        <XeroDatePicker name="xero_csv[end_date]" disabled={false} />
      </div>
    </div>
  );
}
