/* eslint-disable import/no-internal-modules */
import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { showToast } from "@jobber/components/Toast";
import { Modal } from "@jobber/components/Modal";
import { InputText } from "@jobber/components/InputText";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { useUpdateConfiguration } from "jobber/settings/selfServeBookings/views/SettingsPage/hooks";
import { BulletedItems } from "jobber/settings/selfServeBookings/components/BulletedItems/BulletedItems";
import { messages } from "./messages";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";

interface ConfirmationMessageConfigurationProps {
  /**
   * Current confirmation page message
   */
  readonly message?: string;
}

export function ConfirmationMessageConfiguration({
  message,
}: ConfirmationMessageConfigurationProps) {
  const { formatMessage } = useIntl();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(message);
  const {
    editSelfServeSettings,
    loading: saving,
    error,
  } = useUpdateConfiguration();

  function toggleModal() {
    handleReset();
    setIsModelOpen(current => !current);
  }

  function updateText(input: string) {
    setConfirmationMessage(input);
  }

  function handleReset() {
    setConfirmationMessage(message);
  }

  async function handleSubmit() {
    await editSelfServeSettings({
      confirmationMessage,
    }).then(() => {
      toggleModal();
      showToast({ message: formatMessage(messages.saveMessage) });
    });
  }

  return (
    <>
      <ConfigurationSetting
        title={formatMessage(messages.configurationLabel)}
        onEdit={toggleModal}
      >
        <BulletedItems
          items={[message || formatMessage(messages.messageEmpty)]}
        />
      </ConfigurationSetting>
      <Modal
        title={formatMessage(messages.modalTitle)}
        open={isModelOpen}
        onRequestClose={toggleModal}
        primaryAction={{
          label: formatMessage(messages.modalSave),
          onClick: handleSubmit,
          loading: saving,
          disabled: saving,
        }}
        secondaryAction={{
          label: formatMessage(messages.modalCancel),
          onClick: toggleModal,
          disabled: saving,
        }}
      >
        <Content>
          {error && (
            <Banner type={"error"}>
              {formatMessage(messages.errorMessage)}
            </Banner>
          )}
          <InputText
            multiline={true}
            placeholder={formatMessage(messages.inputTextPlaceholder)}
            value={confirmationMessage}
            onChange={updateText}
            description={formatMessage(messages.inputTextDescription)}
          />
        </Content>
      </Modal>
    </>
  );
}
