import { GenericError, UnexpectedError } from "jobber/payments/errors";
import type FormContext from "jobber/payments/FormContext";
import FormInputHandler from "jobber/payments/FormInputHandler";
import type { StripeTokenData } from "jobber/payments/providers/TokenProvider";
import type TokenProvider from "jobber/payments/providers/TokenProvider";
import { elementsConfig, elementsOptions } from "./config";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class StripeProvider implements TokenProvider<StripeTokenData> {
  private readonly formInputHandler: FormInputHandler;
  private readonly formContext: FormContext;

  private readonly stripe: stripe.Stripe;
  private readonly elements: {
    cardNumber: stripe.elements.Element;
    cardCvc: stripe.elements.Element;
    cardExpiry: stripe.elements.Element;
  };

  constructor(formContext: FormContext) {
    this.formInputHandler = new FormInputHandler(formContext.form);
    this.formContext = formContext;

    this.stripe = Stripe(formContext.params.payment_method_public_key);
    this.elements = this.build();
  }

  public async createToken() {
    const { nameOnCard, billingAddress } = this.formContext.state;

    if (!billingAddress) {
      throw new Error("Billing address form is missing from page.");
    }

    const { token, error } = await this.stripe.createToken(
      this.elements.cardNumber,
      {
        name: nameOnCard,
        address_line1: billingAddress.billing_address1,
        address_line2: billingAddress.billing_address2,
        address_city: billingAddress.billing_city,
        address_country: billingAddress.billing_country,
        address_state: billingAddress.billing_country,
        address_zip: billingAddress.billing_zip,
        currency: this.formContext.params.currency,
      },
    );

    if (error) {
      throw new GenericError(error.message);
    } else if (!token || !token.card) {
      throw new UnexpectedError("An unexpected error ocurred");
    }

    return {
      stripe_token: token.id,
      last_four: token.card.last4,
      credit_card_number: token.card.last4,
      expiration_month: token.card.exp_month.toString(),
      expiration_year: token.card.exp_year.toString(),
    };
  }

  private build() {
    const elements = this.stripe.elements({
      ...elementsConfig,
      locale: this.formContext.params.locale,
    });

    const cardNumber = this.createCardNumber(elements);
    const cardCvc = this.createCardCvc(elements);
    const cardExpiry = this.createCardExpiry(elements);

    return { cardNumber, cardCvc, cardExpiry };
  }

  private readonly onCardBrandChanged = (
    inputEvent: stripe.elements.ElementChangeResponse,
  ) => {
    this.formInputHandler.handleCardBrandChanged(
      translateCardBrand(inputEvent.brand),
    );
  };

  private readonly onInputChanged = (
    inputEvent: stripe.elements.ElementChangeResponse,
  ) => {
    const inputType = translateFieldType(
      inputEvent.elementType as stripe.elements.elementsType,
    );

    this.formInputHandler.handleInput(
      inputType,
      inputEvent.empty,
      inputEvent.error && inputEvent.error.message,
    );
  };

  private readonly onBlur = (
    inputEvent: stripe.elements.ElementChangeResponse,
  ) => {
    const inputType = translateFieldType(
      inputEvent.elementType as stripe.elements.elementsType,
    );

    this.formInputHandler.handleBlur(inputType);
  };

  private readonly onFocus = (
    inputEvent: stripe.elements.ElementChangeResponse,
  ) => {
    const inputType = translateFieldType(
      inputEvent.elementType as stripe.elements.elementsType,
    );

    this.formInputHandler.handleFocus(inputType);
  };

  private createCardExpiry(elements: stripe.elements.Elements) {
    const cardExpiry = elements.create("cardExpiry", elementsOptions);
    cardExpiry.mount("#cardExpiry");
    cardExpiry.on("change", this.onInputChanged);
    cardExpiry.on("blur", this.onBlur);
    cardExpiry.on("focus", this.onFocus);
    return cardExpiry;
  }

  private createCardCvc(elements: stripe.elements.Elements) {
    const cardCvc = elements.create("cardCvc", elementsOptions);
    cardCvc.mount("#cardCvc");
    cardCvc.on("change", this.onInputChanged);
    cardCvc.on("blur", this.onBlur);
    cardCvc.on("focus", this.onFocus);
    return cardCvc;
  }

  private createCardNumber(elements: stripe.elements.Elements) {
    const cardNumber = elements.create("cardNumber", elementsOptions);
    cardNumber.mount("#cardNumber");
    cardNumber.on("change", this.onCardBrandChanged);
    cardNumber.on("change", this.onInputChanged);
    cardNumber.on("blur", this.onBlur);
    cardNumber.on("focus", this.onFocus);
    return cardNumber;
  }
}

function translateFieldType(inputType: stripe.elements.elementsType) {
  switch (inputType) {
    case "cardNumber":
      return "cardNumber";
    case "cardCvc":
      return "cardCvc";
    case "cardExpiry":
      return "cardExpiry";
    default:
      throw new Error(`Input type not implemented: ${inputType}`);
  }
}

function translateCardBrand(brand: string) {
  switch (brand) {
    case "amex":
      return "American Express";
    case "discover":
      return "Discover";
    case "jcb":
      return "JCB";
    case "mastercard":
      return "MasterCard";
    case "visa":
      return "Visa";
    default:
      return "Unknown";
  }
}
