import { gql } from "@apollo/client";

export const AUTOMATION_RULE = gql`
  fragment automationRule on AutomationRule {
    trigger
    name
    id
    condition
    active
    actionedCount
    action
  }
`;

export const GET_CAMPAIGN_INFORMATION = gql`
  query commsCampaignReview($id: EncodedId!) {
    commsCampaign(id: $id) {
      id
      updatedBy {
        email {
          raw
        }
        account {
          name
        }
      }
      fromEmail
      fromEmailAddress {
        isValidatedEmail
        type
        value
        rawEmail
      }
      status
      replyToEmail
      scheduledAt
      isAutomated
      automationRule {
        ...automationRule
      }
    }
  }
  ${AUTOMATION_RULE}
`;

export const GET_SEGMENT_INFORMATION = gql`
  query commsCampaignClientSegmentReview($id: EncodedId!) {
    commsCampaign(id: $id) {
      id
      clientSegment {
        clients {
          totalCount
        }
        friendlyName
        hasClientWithoutConsentEntries
        consentingClients {
          totalCount
        }
      }
    }
  }
`;
