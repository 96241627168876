import { useCallback } from "react";
import {
  useCampaignCreatePastClientsMutation,
  useUpdatePastClientsSegmentCampaign,
} from "jobber/campaigns/hooks";
import { isPastClientCriteriaInput } from "jobber/campaigns/utils/segmentCriteriaUtils";
import type {
  UpdateClientSegmentArgs,
  UseUpdateClientSegmentHelperArgs,
} from "../../types";

export function useUpdatePastClientSegment({
  clientSegment,
  defaultTemplate,
}: UseUpdateClientSegmentHelperArgs) {
  const { campaignCreatePastClients } = useCampaignCreatePastClientsMutation();
  const { updatePastClientsSegmentCampaign } =
    useUpdatePastClientsSegmentCampaign();
  const updatePastClientsSegment = useCallback(
    async ({
      newSegmentCriteria,
      onSuccess,
      onError,
    }: UpdateClientSegmentArgs) => {
      if (clientSegment && isPastClientCriteriaInput(newSegmentCriteria)) {
        const result = await updatePastClientsSegmentCampaign({
          input: {
            id: clientSegment.id,
            criteria: newSegmentCriteria,
          },
        });
        if (result.userErrors.length) {
          onError(result.userErrors[0].message);
        } else if (result.clientSegment) {
          onSuccess({ clientSegment: result.clientSegment });
        }
      } else {
        if (
          !defaultTemplate.body ||
          !defaultTemplate.subject ||
          !defaultTemplate.header
        ) {
          onError("Template is missing required fields");
        }
        if (isPastClientCriteriaInput(newSegmentCriteria)) {
          const result = await campaignCreatePastClients({
            input: {
              criteria: newSegmentCriteria,
              template: defaultTemplate,
            },
          });
          if (result.userErrors.length) {
            onError(result.userErrors[0].message);
          } else if (result.clientSegment) {
            onSuccess({
              clientSegment: result.clientSegment,
              campaignId: result.campaign?.id,
            });
          }
        }
      }
    },
    [
      clientSegment,
      campaignCreatePastClients,
      defaultTemplate,
      updatePastClientsSegmentCampaign,
    ],
  );
  return updatePastClientsSegment;
}
