import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Breakpoints } from "@jobber/hooks/useResizeObserver";
import SendToAppSrc from "@images/SendToApp.png";
import { ContentOverlay } from "components/ContentOverlay";
import { useViewport } from "jobber/hooks/useViewport";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./SendToAppOverlay.module.css";
import { messages } from "./messages";

interface SendToAppOverlayProps {
  appLink: string;
  persistentState: boolean;
}

export function SendToAppOverlay({
  persistentState,
  appLink,
}: SendToAppOverlayProps) {
  const { innerWidth } = useViewport();
  const onMobileWeb = innerWidth < Breakpoints.small;
  const [showOverlay, setShowOverlay] = useState(!persistentState);
  const [isPersistent, setisPersistent] = useState(persistentState);
  const trackingID = isPersistent
    ? "persistent_send_to_app_overlay"
    : "first_login_send_to_app_overlay";

  function handleButtonClick(
    buttonText: string,
    buttonType: "primary" | "secondary",
  ) {
    trackButtonClick(buttonText, buttonType);

    if (buttonType === "primary") {
      sendToApp();
    } else {
      dismiss();
    }
  }

  function trackButtonClick(
    buttonText: string,
    buttonType: "primary" | "secondary",
  ) {
    Amplitude.TRACK_EVENT(
      buttonType === "primary" ? "CTA Clicked" : "CTA Dismissed",
      {
        name: buttonText.replaceAll(" ", "_").toLowerCase(),
        source: trackingID,
      },
    );
  }

  function sendToApp() {
    window.location.href = appLink;
    setShowOverlay(false);
    setisPersistent(true);
  }

  function dismiss() {
    setShowOverlay(false);
    setisPersistent(true);
  }

  useEffect(() => {
    if ((showOverlay || isPersistent) && onMobileWeb) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: trackingID,
      });
    }
  }, [showOverlay, onMobileWeb, trackingID, isPersistent]);

  if (!onMobileWeb) return null;

  return (
    <>
      {isPersistent ? (
        <div className={styles.sticky} data-elevation={"elevated"}>
          <Content>
            <div className={styles.persistentStateContainer}>
              <div className={styles.persistentStateHeader}>
                <Heading level={3}>{messages.persistentState.header}</Heading>
              </div>
              <Button
                label={messages.persistentState.sendToAppLabel}
                onClick={() =>
                  handleButtonClick(
                    messages.persistentState.sendToAppLabel,
                    "primary",
                  )
                }
              />
            </div>
          </Content>
        </div>
      ) : (
        <ContentOverlay showOverlay={showOverlay} handleClose={dismiss}>
          <img
            src={SendToAppSrc}
            alt={messages.firstLogin.imageAlt}
            className={styles.overlayImage}
          />
          <div className={styles.overlayHeader}>
            <Heading level={3}>
              {messages.firstLogin.headerPartOne}
              <br />
              {messages.firstLogin.headerPartTwo}
            </Heading>
          </div>
          <Content spacing="small">
            <Button
              label={messages.firstLogin.sendToAppLabel}
              size="large"
              fullWidth={true}
              onClick={() =>
                handleButtonClick(messages.firstLogin.sendToAppLabel, "primary")
              }
            />
            <Button
              label={messages.firstLogin.dismissLabel}
              type="tertiary"
              variation="subtle"
              size="large"
              fullWidth={true}
              onClick={() =>
                handleButtonClick(messages.firstLogin.dismissLabel, "secondary")
              }
            />
          </Content>
        </ContentOverlay>
      )}
    </>
  );
}
