import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonLabel: {
    id: "reviewsPage.generateResponse.buttonLabel",
    defaultMessage: "Generate Response",
    description: "label for the primary button",
  },
  placeholder: {
    id: "reviewsPage.generateResponse.placeholder",
    defaultMessage: "Thanks for leaving us a review!",
    description: "placeholder text for the text area",
  },
  error: {
    id: "reviewsPage.generateResponse.error",
    defaultMessage:
      "Failed to generate a review response. Please try again later.",
    description: "error message when generating a reply fails",
  },
});
