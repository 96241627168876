import React from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { messages } from "jobber/workOrders/components/JobCost/components/LineItemsTable/messages";
import { AddNewLineItemButton } from "./AddNewLineItemButton";
import styles from "../LineItemsTable.module.css";

interface LineItemsTableTitleProps {
  dispatch: (action: LineItemsAction) => void;
}

export function LineItemsTableTitle({ dispatch }: LineItemsTableTitleProps) {
  const { can } = useAuthorization();
  const canViewPricing = can("view", "Pricing");
  const canEditJobs = can("edit", "Jobs");
  const { formatMessage } = useIntl();

  return (
    <tr>
      <th colSpan={100}>
        <div className={styles.title}>
          <Heading level={2}>{formatMessage(messages.lineItemsTitle)}</Heading>
          {canViewPricing && canEditJobs && (
            <AddNewLineItemButton
              onPress={() =>
                dispatch({
                  type: LineItemsBulkEditActionTypes.AddLineItem,
                })
              }
            />
          )}
        </div>
      </th>
    </tr>
  );
}
