import { useState } from "react";
import { useMutation } from "@apollo/client";
import type { GraphQLError } from "graphql";
import { useIntl } from "react-intl";
import type {
  AiAssistantFeedbackCreateMutation,
  AiAssistantFeedbackCreateMutationVariables,
  AiAssistantFeedbackSentiment,
} from "~/utilities/API/graphql";
import { AI_ASSISTANT_CREATE_FEEDBACK } from "jobber/jobberAssistant/components/JobberAssistant.graphql";
import { messages } from "./messages";

interface UseFeedbackCreateMutation {
  requestId: string;
}

export function useFeedbackCreateMutation({
  requestId,
}: UseFeedbackCreateMutation) {
  const { formatMessage } = useIntl();
  const [createFeedbackErrorMessage, setCreateFeedbackErrorMessage] =
    useState<string>();
  const [createFeedbackSubmitted, setCreateFeedbackSubmitted] = useState(false);
  const [mutate, { loading: createFeedbackLoading }] = useMutation<
    AiAssistantFeedbackCreateMutation,
    AiAssistantFeedbackCreateMutationVariables
  >(AI_ASSISTANT_CREATE_FEEDBACK);

  const createFeedback = async (
    sentiment: AiAssistantFeedbackSentiment,
    comment: string,
  ) => {
    try {
      setCreateFeedbackSubmitted(false);
      const result = await mutate({
        variables: {
          requestId,
          input: {
            sentiment,
            ...(comment !== undefined ? { comment } : { comment: "" }),
          },
        },
      });

      const errors = result?.data?.aiAssistantFeedbackCreate?.userErrors;
      if (errors && errors?.length > 0) {
        setCreateFeedbackErrorMessage(errors[0].message);
      } else {
        setCreateFeedbackErrorMessage(undefined);
        setCreateFeedbackSubmitted(true);
      }
    } catch (error) {
      const errorMessages =
        error.graphQLErrors?.map((err: GraphQLError) => err?.message) || [];
      const errorMessage =
        errorMessages.length > 0
          ? errorMessages.join(", ")
          : formatMessage(messages.useFeedbackCreateError);

      setCreateFeedbackErrorMessage(errorMessage);
    }
  };

  return {
    createFeedback,
    createFeedbackErrorMessage,
    createFeedbackLoading,
    createFeedbackSubmitted,
  };
}
