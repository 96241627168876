import currency from "currency.js";
import React, { type ChangeEvent, type FocusEvent } from "react";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { InputLabel } from "./InputLabel";

interface AmountInputProps {
  amount?: string;
  disabled?: boolean;
  name?: string;

  onAmountChange?(amount: string): void;
}

export function AmountInput(props: AmountInputProps) {
  const { amount, disabled, name, onAmountChange } = props;
  const { currencySymbol } = useAccount();

  return (
    <InputLabel label="Amount">
      {currencySymbol && (
        <span className="fieldAffix-item">{currencySymbol}</span>
      )}

      <input
        className="input"
        type="text"
        inputMode="decimal"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={amount}
        disabled={disabled}
        onFocus={onFocus}
        // eslint-disable-next-line jsx-a11y/no-autofocus -- Grandfathered error: Please fix if touching this code.
        autoFocus={true}
      />
    </InputLabel>
  );

  function onBlur() {
    onAmountChange && onAmountChange(formatAmount(amount || ""));
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!onAmountChange) return;

    onAmountChange(event.currentTarget.value);
  }

  function onFocus(event: FocusEvent<HTMLInputElement>) {
    event.currentTarget.select();
  }
}

function formatAmount(amount: string) {
  return currency(amount, {
    symbol: "",
    separator: "",
  }).format();
}
