import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.ServiceAreaConfiguration.configurationLabel",
    defaultMessage: "Service area",
    description: "Title of the Service Area configuration",
  },
  serviceAreasDisabled: {
    id: "selfServeBookings.ServiceAreaConfiguration.serviceAreasDisabled",
    defaultMessage: "Bookings aren't limited by a service area",
    description: "Text displayed when service areas are disabled",
  },
  serviceAreaRadiusPreview: {
    id: "selfServeBookings.ServiceAreaConfiguration.serviceAreaRadiusPreview",
    defaultMessage: "{radius}{unit} radius from {address}",
    description: "Format of the service area preview text",
  },
  radiusFieldUnitKilometer: {
    id: "selfServeBookings.ServiceAreaConfiguration.radiusFieldUnitKilometer",
    defaultMessage: "km",
    description: "Metric Unit for the radius field",
  },
  radiusFieldUnitMile: {
    id: "selfServeBookings.ServiceAreaConfiguration.radiusFieldUnitMile",
    defaultMessage: "mi",
    description: "Imperial Unit for the radius field",
  },
});
