import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignStatusUpdateMutation,
  CampaignStatusUpdateMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_STATUS_UPDATE } from "./useCampaignStatusUpdateMutation.graphql";

export interface CampaignStatusUpdateQueryProps {
  input: CampaignStatusUpdateMutationVariables;
  onError: () => void;
  onCompleted: (data: CampaignStatusUpdateMutation) => void;
}

export function useCampaignStatusUpdateMutation() {
  const [campaignStatusUpdateMutation, { loading, data }] = useMutation<
    CampaignStatusUpdateMutation,
    CampaignStatusUpdateMutationVariables
  >(CAMPAIGN_STATUS_UPDATE);

  const campaignStatusUpdate = useCallback(
    async ({ input, onError, onCompleted }: CampaignStatusUpdateQueryProps) => {
      const mutationResult = await campaignStatusUpdateMutation({
        variables: input,
        onError: onError,
        onCompleted: onCompleted,
      });

      return {
        campaign: mutationResult.data?.campaignStatusUpdate.campaign,
        userErrors: mutationResult.data?.campaignStatusUpdate.userErrors ?? [],
      };
    },
    [campaignStatusUpdateMutation],
  );
  return { campaignStatusUpdate, loading, data };
}
