import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { Header, Table } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { LineItem } from "~/jobber/lineItems/types";
import { useCustomFieldSideDrawer } from "~/jobber/customFields/hooks/useCustomFieldSideDrawer";
import { CustomFieldSideDrawer } from "~/jobber/customFields/components/CustomFieldSideDrawer";
import styles from "./LineItemsTable.module.css";
import {
  LineItemsTableBody,
  LineItemsTableEmptyState,
  LineItemsTableHeaderColumns,
  LineItemsTableTitle,
} from "./components";
import { LineItemsTableFooter } from "./components/LineItemsTableFooter";
import { LoadPastEntriesButton } from "../LoadPastEntriesButton";

interface LineItemsTableProps {
  lineItems: LineItem[];
  lineItemsTotalCost: number;
  lineItemsTotalPrice: number;
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  hasMoreLineItems: boolean;
  fetchMoreLineItems?: () => Promise<void>;
}

export function LineItemsTable({
  lineItems,
  lineItemsTotalCost,
  lineItemsTotalPrice,
  dispatch,
  jobId,
  hasMoreLineItems,
  fetchMoreLineItems,
}: LineItemsTableProps) {
  const { can } = useAuthorization();
  const canViewPricing = can("view", "Pricing");

  const {
    handleCustomFieldChipClick,
    handleRequestClose,
    sideDrawerAttributes,
  } = useCustomFieldSideDrawer();

  if (!lineItems?.length) {
    return (
      <IntlProvider>
        <LineItemsTableEmptyState dispatch={dispatch} />
      </IntlProvider>
    );
  }

  return (
    <div className={styles.lineItemsTable}>
      <IntlProvider>
        <Table>
          <LineItemsTableColumnGroup />
          <Header>
            <LineItemsTableTitle dispatch={dispatch} />
            <LineItemsTableHeaderColumns canViewPricing={canViewPricing} />
          </Header>
          <LineItemsTableBody
            lineItems={lineItems}
            dispatch={dispatch}
            jobId={jobId}
            onCustomFieldsChipsClick={handleCustomFieldChipClick}
          >
            <LoadPastEntriesButton
              showButton={hasMoreLineItems}
              onClick={() => fetchMoreLineItems?.()}
            ></LoadPastEntriesButton>
          </LineItemsTableBody>
          <LineItemsTableFooter
            lineItemsTotalCost={lineItemsTotalCost}
            lineItemsTotalPrice={lineItemsTotalPrice}
            dispatch={dispatch}
          />
        </Table>
        <CustomFieldSideDrawer
          open={sideDrawerAttributes.open}
          onRequestClose={handleRequestClose}
          productOrServiceId={sideDrawerAttributes.productOrServiceId}
          lineItemThumbnailUrl={sideDrawerAttributes.lineItemThumbnailUrl}
          app={sideDrawerAttributes.app}
        />
      </IntlProvider>
    </div>
  );
}

function LineItemsTableColumnGroup() {
  const { can } = useAuthorization();
  const { features } = useAccount();

  const canViewPricing = can("view", "Pricing");
  const canViewCostColumn =
    features.jobCosting.enabled && features?.quoteMargins.enabled;

  if (canViewPricing && canViewCostColumn) {
    return (
      <colgroup>
        <col style={{ width: `51%` }} />
        <col style={{ width: `10%` }} />
        <col style={{ width: `12%` }} />
        <col style={{ width: `12%` }} />
        <col style={{ width: `15%` }} />
      </colgroup>
    );
  }

  if (canViewPricing && !canViewCostColumn) {
    return (
      <colgroup>
        <col style={{ width: `63%` }} />
        <col style={{ width: `10%` }} />
        <col style={{ width: `12%` }} />
        <col style={{ width: `15%` }} />
      </colgroup>
    );
  }

  return (
    <colgroup>
      <col style={{ width: `90%` }} />
      <col style={{ width: `10%` }} />
    </colgroup>
  );
}
