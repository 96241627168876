import React from "react";
import { Button } from "@jobber/components/Button";
import { WORK_OBJECT_MAPPING } from "jobber/chat/components/ChatDrawer/Chat/WorkObjectMapping";
import type {
  LinkableWorkObjectUnion,
  MessageDataEmailMessageFragment,
  MessageDataFragment,
  MessageDataTextMessageFragment,
} from "~/utilities/API/graphql";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { Rollbar } from "~/utilities/errors/Rollbar";

interface WorkflowButtonProps {
  readonly message:
    | MessageDataTextMessageFragment
    | MessageDataEmailMessageFragment;
}

interface WorkflowButton {
  url: string;
  label: string;
}

export function WorkflowButton({ message }: WorkflowButtonProps) {
  const link = getWorkObjectLinkForMessage(message);

  return link?.label ? (
    <div className="u-paddingTopSmall u-paddingBottomSmaller">
      <Button
        label={link.label}
        type="secondary"
        size="small"
        url={link.url}
        external={true}
        fullWidth={true}
      />
    </div>
  ) : (
    <></>
  );
}

function getWorkObjectLinkForMessage(
  item: MessageDataFragment,
): WorkflowButton | undefined {
  if (item.linkableWorkObject) {
    const linkData = WORK_OBJECT_MAPPING.get(
      item.linkableWorkObject.__typename || "",
    );

    const encodedId = (item.linkableWorkObject as LinkableWorkObjectUnion).id;

    if (!encodedId || !linkData) {
      return undefined;
    }

    try {
      const decodedId = decodeId(encodedId);

      return {
        url: `/${linkData.urlPath}/${decodedId}`,
        label: `View ${linkData.label}`,
      };
    } catch (error) {
      Rollbar.EXECUTE("Failed to decode legacyID", error as Error);
      return undefined;
    }
  }

  return undefined;
}
