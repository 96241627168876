import { type ApolloError, useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";
import type { SetupGuideStatusMutation } from "~/utilities/API/graphql";
import { SETUP_GUIDE_STATUS_MUTATION } from "../SetupGuide.graphql";

export function useSetupGuideStatus(): {
  data?: SetupGuideStatusMutation | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const [setupGuideStatus, { data, loading, error }] =
    useMutation<SetupGuideStatusMutation>(SETUP_GUIDE_STATUS_MUTATION);

  const fetchSetupGuideStatus = useCallback(async () => {
    return setupGuideStatus();
  }, [setupGuideStatus]);

  useEffect(() => {
    void fetchSetupGuideStatus();
  }, [fetchSetupGuideStatus]);

  return { data, loading, error };
}
