import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "SendTestEmailForDemoModal.modal.title",
    defaultMessage: "Send a test email",
    description: "Send a test email modal title",
  },
  sendEmailButton: {
    id: "SendTestEmailForDemoModal.button.sendEmailButton",
    defaultMessage: "Send Test Email",
    description: "Send Test Email button",
  },
  cancelButton: {
    id: "SendTestEmailForDemoModal.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  textLine1: {
    id: "SendTestEmailForDemoModal.text.textline1",
    defaultMessage: "Send a test email to:",
    description: "text line one",
  },
  emailAddressInputPlaceholder: {
    id: "SendTestEmailForDemoModal.input.placeholder",
    defaultMessage: "Email address",
    description: "Email address input placeholder",
  },
  emailRequiredMessage: {
    id: "SendTestEmailForDemoModal.invalidEmail.message",
    defaultMessage: "Enter an email address",
    description: "Email required validation error message",
  },
  invalidEmailMessage: {
    id: "SendTestEmailForDemoModal.requiredEmail.message",
    defaultMessage: "Enter a valid email address",
    description: "Email validation error message",
  },
  errorMessage: {
    id: "SendTestEmailForDemoModal.error.message",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error banner message",
  },
});
