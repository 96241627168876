import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { TimesheetEditMutation } from "~/utilities/API/graphql";
import type { TimesheetEntryInput } from "./types";
import { JOB_COST_LABOUR_TOTALS_QUERY, TIMESHEET_EDIT } from "./graphql";
import { messages } from "./messages";

export function useTimesheetEditMutation(
  jobId: string,
  timeSheetEntryId: string,
  canViewJobCosts: boolean,
  onSuccess: () => void,
): {
  handleSubmit: (input: TimesheetEntryInput) => Promise<void>;
  loading: boolean;
} {
  const [editTimeSheet, { loading }] = useMutation<TimesheetEditMutation>(
    TIMESHEET_EDIT,
    {
      variables: {
        canViewJobCosts,
      },
      refetchQueries: [
        {
          query: JOB_COST_LABOUR_TOTALS_QUERY,
          variables: {
            jobId: jobId,
            canViewJobCosts: canViewJobCosts,
          },
        },
      ],
    },
  );

  async function handleSubmit(input: TimesheetEntryInput) {
    try {
      const result = await editTimeSheet({
        variables: {
          timeSheetEntryId,
          input,
        },
      });

      const errors = result.data?.timeSheetEntryEdit.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.timeEntrySaved,
          variation: "success",
        });
        onSuccess();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleSubmit,
    loading,
  };
}
