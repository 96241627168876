import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import type {
  InputSearchFilterOption,
  InputSearchFilterProps,
} from "components/InputSearch/InputSearch";
import styles from "./ActiveFilters.module.css";

interface ActiveFiltersProps {
  filter: InputSearchFilterProps;
  onClearButtonClick(): void;
}

export function ActiveFilters({
  filter,
  onClearButtonClick,
}: ActiveFiltersProps) {
  const label = filter.filterOptions.find(
    (f: InputSearchFilterOption) => f.value === filter.selectedFilter,
  )?.label;

  return (
    <div className={styles.filterRow}>
      <Heading level={4}>{label}</Heading>
      <Button
        label="Clear Filter"
        type="tertiary"
        onClick={onClearButtonClick}
      />
    </div>
  );
}
