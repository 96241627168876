import React from "react";
import { EmptyState } from "./EmptyState";

interface NoDPNEmptyStateProps {
  canEditNumber: boolean | undefined;
}

export function NoDPNEmptyState({ canEditNumber }: NoDPNEmptyStateProps) {
  const title = canEditNumber
    ? "You need a dedicated phone number!"
    : "Your team needs a dedicated phone number";
  const description = canEditNumber
    ? "Text messages with your clients will show here, once you pick a dedicated phone number. After you’ve picked your number, you may need to refresh your browser window to start seeing messages."
    : "A user with administrator permission needs to choose a dedicated phone number in order to access two-way text messaging.";
  const primaryActionLabel = canEditNumber ? "Choose Number" : undefined;

  return (
    <EmptyState
      title={title}
      description={description}
      primaryActionLabel={primaryActionLabel}
      iconName={"sms2"}
      handlePrimaryAction={canEditNumber ? handlePrimaryAction : undefined}
    />
  );

  function handlePrimaryAction() {
    window.open(
      `${encodeURI("/text_messaging_settings#show")}`,
      "_blank",
      "noopener noreferrer",
    );
  }
}
