/**
 * Provides an entry point for interacting with Rollbar
 * Available methods https://docs.rollbar.com/docs/rollbarjs-configuration-reference#method-reference
 */
export const Rollbar = {
  EXECUTE: (message: string, error: Error) => {
    if (window.Rollbar == undefined) {
      // eslint-disable-next-line no-console
      console.warn("Rollbar not defined");

      return;
    }

    window.Rollbar.configure({ logLevel: "error" });
    window.Rollbar.log(message, error);
  },
  EXCEPTION: (message: string, caughtError: Error, metadata?: object) => {
    if (window.Rollbar == undefined) {
      // eslint-disable-next-line no-console
      console.warn("Rollbar not defined");
      return;
    }

    window.Rollbar.configure({ logLevel: "error" });
    window.Rollbar.error(message, caughtError, metadata);
  },
};
