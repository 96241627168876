import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Markdown } from "@jobber/components/Markdown";
import React, { useEffect, useReducer } from "react";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { ManualConnect } from "jobber/managed_accounts/ManualConnect/ManualConnect";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import {
  type BankAccountProgress,
  type SuccessBank,
  bankAccountInitialState,
  bankAccountReducer,
} from "./bankAccountReducer";
import { PlaidConnect } from "./PlaidConnect";

export interface BankAccountProps {
  payoutsToJobberMoney: boolean;
  embedded?: boolean;
  countryCode: string;
  currencyCode: string;
  plaidEnabled: boolean;
  hostedOnboardingConnectBankAccountURL: string;
  disableAddBankAccount: boolean;
  bankAccountProgress: BankAccountProgress;
  requiresDebitAuthorizationForm: boolean;
  completedCallback(bank?: SuccessBank): void;
}

export function BankAccount(props: BankAccountProps) {
  const { payoutsToJobberMoney, completedCallback, embedded, plaidEnabled } =
    props;
  const { payoutScheduleInDays } = useJobberPayments();
  const [state, dispatch] = useReducer(
    bankAccountReducer,
    bankAccountInitialState(!!payoutsToJobberMoney, payoutScheduleInDays),
  );
  const { actionType } = state;
  const [t] = useTranslation();

  useEffect(() => {
    if (actionType !== "onSuccess") return;

    completedCallback(state.successBank);
  }, [actionType]);

  const bankAccountConnected = props.bankAccountProgress === "connected";
  const addBankAccountPrompt = plaidEnabled
    ? t("addBankPrompt")
    : t("addBankPromptNoPlaid");

  const removingMoneyManagementAccount = state.successBank?.payoutsToJobberMoney
    ? t("removeMoneyManagementAccount")
    : undefined;

  return (
    <Content spacing="small">
      {bankAccountConnected ? (
        <>
          <h2 className="u-colorGreen">
            <Icon color="green" name="checkmark" /> Your bank account is
            connected!
          </h2>
          <Markdown content={t("payoutTimeInformation")} />
        </>
      ) : (
        <>
          {!embedded && (
            <Heading level={3}>{t("connectBankAccountHeading")}</Heading>
          )}
          {removingMoneyManagementAccount && (
            <Markdown content={removingMoneyManagementAccount} />
          )}
          <Markdown content={addBankAccountPrompt} />
          <ManualConnect {...props} {...state} dispatch={dispatch} />
          {plaidEnabled && (
            <PlaidConnect {...props} {...state} dispatch={dispatch} />
          )}
        </>
      )}
    </Content>
  );
}
