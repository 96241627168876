import { useEffect, useMemo, useState } from "react";
import type { Option } from "@jobber/components/Autocomplete";
import type {
  FilterType,
  StatusFilterOption,
} from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import type { QuoteDetailFilterInput } from "~/utilities/API/graphqlFranchiseManagement";
import type {
  QuoteDetailDateFilterTypes,
  QuoteDetailReportFilterState,
} from "jobber/franchise/features/Reporting/views/QuoteDetailReport/types";
import {
  formatAfterDate,
  formatBeforeDate,
} from "~/jobber/features/Reporting/components/Report/utils/formatReportDateFilters";
import {
  SEARCH_FILTER_HEADING,
  SEARCH_PLACEHOLDER,
  useFranchiseeNameAutoCompleteData,
} from "jobber/franchise/features/Reporting/views/hooks/useFranchiseeNameAutoCompleteData";
import type {
  FilterAutocompleteProps,
  OptionCollection,
} from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/FilterAutocomplete/types";
import { DATE_FILTER_LABELS } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/constants";
import {
  isInDesktopView,
  isInHandheldView,
} from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import { useViewport } from "jobber/hooks/useViewport";
import { findStatusFilterType } from "~/jobber/features/Reporting/components/Report/utils/findStatusFilterType";
import { useDateFilterControls } from "./useDateFilterControls";

export function useQuoteDetailReportFilters(resetPagination: () => void) {
  const {
    dateFilterValues,
    setDateFilterValues,
    dateFilterType,
    setDateFilterType,
  } = useDateFilterControls();

  const [franchiseeNameSearchFilter, setFranchiseeNameSearchFilter] = useState<
    Option | undefined
  >();

  const [canSearch, setCanSearch] = useState<boolean>(false);
  const {
    fetchAutoCompleteData,
    handleAutoCompleteChange,
    options: franchiseeOptions,
  } = useFranchiseeNameAutoCompleteData();

  const { innerWidth } = useViewport();

  const dateFilterTypeOptions: StatusFilterOption[] = Object.keys(
    DATE_FILTER_LABELS,
  ).map((type: QuoteDetailDateFilterTypes) => {
    return {
      label: DATE_FILTER_LABELS[type],
      onClick: () => setDateFilterType(type),
    };
  });

  useEffect(() => {
    fetchAutoCompleteData();
  }, []);

  const handleDateFilterChange = (range: FilterType, statusType?: string) => {
    if (statusType) {
      const foundStatusFilterType = findStatusFilterType(
        DATE_FILTER_LABELS,
        statusType,
      );
      setDateFilterType(foundStatusFilterType as QuoteDetailDateFilterTypes);
    }

    resetPagination();
    setDateFilterValues(range);

    // if we're in mobile view, we allow the search only when the filters are applied
    if (isInHandheldView(innerWidth)) {
      setCanSearch(true);
    }
  };

  const searchFilterConfig: FilterAutocompleteProps = {
    heading: SEARCH_FILTER_HEADING,
    placeholder: SEARCH_PLACEHOLDER,
    initialOptions: franchiseeOptions || [],
    getOptions: newInput =>
      handleAutoCompleteChange(newInput) as OptionCollection,
    onChange: setFranchiseeNameSearchFilter,
    value: franchiseeNameSearchFilter,
    onBlur: () => {
      // if we're in desktop view, we allow the search as soon as the search is blurred
      if (isInDesktopView(innerWidth)) {
        setCanSearch(true);
      }
    },
    onFocus: () => setCanSearch(false),
  };

  const getFilterProps = () => {
    return {
      dateRangeFilter: dateFilterValues,
      onFilterChange: handleDateFilterChange,
      statusFilter: {
        heading: "STATUS",
        options: dateFilterTypeOptions,
        value: DATE_FILTER_LABELS[dateFilterType],
      },
      searchFilter: searchFilterConfig,
    };
  };

  const reportFilterState = useMemo(
    () => ({
      dateFilterType,
      dateFilterValues,
      franchiseeNameSearchFilter,
    }),
    [dateFilterValues, dateFilterType, franchiseeNameSearchFilter],
  );

  return {
    reportFilterState,
    canSearch,
    formatFilter,
    getFilterProps,
  };
}

function formatFilter({
  dateFilterType,
  dateFilterValues,
  franchiseeNameSearchFilter,
}: QuoteDetailReportFilterState): QuoteDetailFilterInput {
  return {
    [`${dateFilterType}Before`]: formatBeforeDate(
      dateFilterValues?.value.endDate,
    ),
    [`${dateFilterType}After`]: formatAfterDate(
      dateFilterValues?.value.startDate,
    ),
    franchiseeIds: franchiseeNameSearchFilter?.value as string | undefined,
  };
}
