import { Heading } from "@jobber/components/Heading";
import React from "react";
import { Icon } from "@jobber/components/Icon";
import classNames from "./DateHeading.module.css";

export interface DateHeadingProps {
  date: Date;
  onClose: () => void;
}

const dayOfWeek = (date: Date) => {
  return date.toLocaleDateString(navigator.language, { weekday: "short" });
};

const monthDayYear = (date: Date) => {
  const dateStringOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return date.toLocaleString(navigator.language, dateStringOptions);
};

export const DateHeading = (props: DateHeadingProps) => {
  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <Heading level={3}>
          {dayOfWeek(props.date)} {monthDayYear(props.date)}
        </Heading>
      </div>
      <div className={classNames.iconContainer}>
        <button onClick={props.onClose} style={{ cursor: "pointer" }}>
          <Icon name="remove" />
        </button>
      </div>
    </div>
  );
};
