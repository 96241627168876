import { defineMessages } from "react-intl";

export const messages = defineMessages({
  profitBarProfitMargin: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.profitMargin",
    defaultMessage: "Profit Margin",
    description: "Profit Margin label for Profit Bar",
  },
  profitBarLineItemCost: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.lineItemCost",
    defaultMessage: "Line Item Cost",
    description: "Line Item Cost label for Profit Bar",
  },
  profitBarLabour: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.labour",
    defaultMessage: "Labour",
    description: "Labour label for Profit Bar",
  },
  profitBarExpenses: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.expenses",
    defaultMessage: "Expenses",
    description: "Expenses label for Profit Bar",
  },
  profitBarProfit: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.profit",
    defaultMessage: "Profit",
    description: "Profit label for Profit Bar",
  },
  profitBarPrice: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.price",
    defaultMessage: "Total price",
    description: "Price label for Profit Bar",
  },
  profitBarCost: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.cost",
    defaultMessage: "Cost",
    description: "Cost label for Profit Bar",
  },
  profitBarRevenue: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.revenue",
    defaultMessage: "Revenue",
    description: "Revenue label for Profit Bar",
  },
  recurringJobProfitBarProfitMargin: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.recurringJobProfitMargin",
    defaultMessage: "Recurring Job Profit Margin",
    description: "Recurring Job Profit Margin label for Profit Bar",
  },
  recurringJobProfitability: {
    id: "workOrders.jobCost.jobCostHeader.profitBar.recurringJobProfitability",
    defaultMessage: "Recurring Job Profitability",
    description: "Recurring Job Profitability label for Profit Bar",
  },
});
