import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { CTA_SHOWN } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./AccordionWrapper.module.css";
import { AccordionItem, type AccordionItemContentProps } from "./AccordionItem";
import { messages } from "./messages";
import { useAccordionItems } from "./useAccordionItems";
import { trackUserCompletedSection } from "./utils";
import { AccordionImage } from "./AccordionImage";

interface AccordionWrapperProps {
  viewedCtas: string[];
  completedCtas: string[];
  clientHubLink: string;
}

export function AccordionWrapper({
  viewedCtas,
  completedCtas,
  clientHubLink,
}: AccordionWrapperProps) {
  const [completedItems, setCompletedItems] = useState<string[]>(completedCtas);
  const accordionItems = useAccordionItems(clientHubLink);
  const { formatMessage } = useIntl();
  const [ctaShown] = useMutation(CTA_SHOWN);

  const markItemAsShown = (ctaName: string) => {
    if (
      !completedItems.includes(ctaName) &&
      ctaName !== "happy_path_p1_v2_jobber_calendar_cta"
    ) {
      setCompletedItems(previousItems => [...previousItems, ctaName]);
      trackUserCompletedSection(ctaName);
    }

    ctaShown({
      variables: { id: ctaName },
    }).catch((ctaShownError: string) => {
      throw new Error(ctaShownError);
    });
  };

  const expandedItemOnRender = (): string => {
    let ctaToExpand: string;
    const lastViewedItem: string = viewedCtas.slice(-1)[0];

    if (completedItems.length === 0) {
      ctaToExpand = "happy_path_p1_v2_quote_invoice_followup_cta";
      markItemAsShown(ctaToExpand);
    } else {
      ctaToExpand = lastViewedItem;
    }

    return ctaToExpand;
  };
  const [expandedItem, setExpandedItem] =
    useState<string>(expandedItemOnRender);

  const expandItem = (ctaName: string) => {
    if (ctaName !== expandedItem) {
      setExpandedItem(ctaName);
      markItemAsShown(ctaName);
    }
  };

  const expandNextIncompleteItem = () => {
    const itemToExpand = accordionItems.find(
      ({ ctaName }) =>
        ctaName !== expandedItem && !completedItems.includes(ctaName),
    );

    if (itemToExpand) {
      expandItem(itemToExpand.ctaName);
    }
  };

  const trackUserLeavingPage = useCallback(() => {
    Amplitude.TRACK_EVENT("Interacted with Experiment", {
      experiment: "happy_path_p1_v2",
      interaction: "leaving_page",
      completedSections: completedItems.length,
    });
  }, [completedItems]);

  useEffect(() => {
    window.addEventListener("beforeunload", trackUserLeavingPage);

    return () => {
      window.removeEventListener("beforeunload", trackUserLeavingPage);
    };
  }, [trackUserLeavingPage]);

  return (
    <>
      <section className={styles.accordionSection}>
        <div className={styles.accordionContainer}>
          <div className={styles.progressBar}>
            <ProgressBar
              currentStep={completedItems.length}
              totalSteps={5}
              size="smaller"
            ></ProgressBar>
          </div>
          <div className={styles.headerContent}>
            <Content spacing={"large"}>
              <Heading level={1}>
                {formatMessage(messages.wrapperTitle)}
              </Heading>
              <Text>{formatMessage(messages.wrapperDescription)}</Text>
            </Content>
          </div>
          <div>
            {accordionItems.map(
              (accordionItem: AccordionItemContentProps, index: number) => {
                return (
                  <AccordionItem
                    key={index}
                    isExpanded={expandedItem === accordionItem.ctaName}
                    isCompleted={completedItems.includes(accordionItem.ctaName)}
                    onClick={() => expandItem(accordionItem.ctaName)}
                    onCompleted={expandNextIncompleteItem}
                    accordionItemContent={accordionItem}
                  />
                );
              },
            )}
          </div>
        </div>
      </section>
      <AccordionImage expandedItem={expandedItem} />
    </>
  );
}
