import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import themeDark from "@images/signup/theme-dark.svg";
import themeLight from "@images/signup/theme-light.svg";
import { Context } from "jobber/setupWizard/context/SetupWizardContext";
import { ThemePageForm } from "./components/ThemePageForm";
import { messages } from "./messages";
import { determineThemeToDisplay } from "./utils";

export const darkImage: ImageType = {
  alt: messages.darkButtonAltText,
  src: themeDark,
};

export const lightImage: ImageType = {
  alt: messages.lightButtonAltText,
  src: themeLight,
};

export function ThemePage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData } = useContext(Context);

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.theme,
    });
  }, []);

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.themePageDefaultHeading)}
          copy={formatMessage(messages.themePageCopy)}
          form={<ThemePageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn
          backgroundImage={
            determineThemeToDisplay(wizardData) === "dark"
              ? darkImage
              : lightImage
          }
        />
      }
    />
  );
}
