export function setLineItemImage(
  controlId: string,
  fileName: string,
  contentType: string,
  fileSize: string,
  s3Key: string,
) {
  setControlValue(controlId, ".js-hiddenDestroy", "false");
  setControlValue(controlId, ".js-fileAttachmentFileName", fileName);
  setControlValue(controlId, ".js-fileAttachmentContentType", contentType);
  setControlValue(controlId, ".js-fileAttachmentFileSize", fileSize);
  setControlValue(controlId, ".js-fileAttachmentS3Key", s3Key);
}

export function removeLineItemImage(controlId: string) {
  setControlValue(controlId, ".js-hiddenDestroy", "true");
}

export function setControlValue(
  controlId: string,
  controlSelector: string,
  value: string,
) {
  const control = document.querySelector(
    `#${controlId} ${controlSelector}`,
  ) as HTMLInputElement;
  if (control) {
    control.value = value;
  } else {
    throw new Error(`No ${controlSelector} found`);
  }
}
