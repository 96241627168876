import { useEffect } from "react";

interface BillingRule {
  validations: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    day_of_month: number[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rule_type: string;
  interval: number;
}

export type SelectedFrequency = BillingRule | number | string | undefined;

interface SelectedFrequencyEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  selected_frequency: SelectedFrequency;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  selected_frequency_text?: string;
}

export enum FrequencyType {
  CUSTOM = "custom",
  AFTER_VISIT = "after_visit",
  AS_NEEDED = "as_needed",
  ON_JOB_CLOSE = "on_job_close",
}

function isBillingRuleFrequencyResponse(
  eventSelectedFrequency: BillingRule | number | unknown,
): eventSelectedFrequency is BillingRule {
  return (
    typeof eventSelectedFrequency === "object" &&
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (eventSelectedFrequency as BillingRule).rule_type !== undefined
  );
}

type ValidSelectedFrequencyResponseNumbers = 0 | 2 | 3;

const selectedFrequencyEventNumberToTypeMap: Record<
  ValidSelectedFrequencyResponseNumbers,
  FrequencyType
> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  0: FrequencyType.ON_JOB_CLOSE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  2: FrequencyType.AFTER_VISIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  3: FrequencyType.AS_NEEDED,
};

function isValidNumberFrequency(
  eventSelectedFrequency: BillingRule | number | unknown,
): eventSelectedFrequency is ValidSelectedFrequencyResponseNumbers {
  return (
    typeof eventSelectedFrequency === "number" &&
    !isNaN(eventSelectedFrequency) &&
    eventSelectedFrequency in selectedFrequencyEventNumberToTypeMap
  );
}

export interface InvoiceFrequencySettings {
  frequencyType: FrequencyType;
  frequencyText?: string;
}

// Custom function to parse selected_frequency into BillingRule, number, or leave as string
export function parseSelectedFrequency(
  eventSelectedFrequency: SelectedFrequency,
): SelectedFrequency {
  if (typeof eventSelectedFrequency === "string") {
    try {
      return JSON.parse(eventSelectedFrequency) as BillingRule;
    } catch {
      const parsedNumber = parseInt(eventSelectedFrequency, 10);
      return isNaN(parsedNumber) ? eventSelectedFrequency : parsedNumber;
    }
  }
  return eventSelectedFrequency;
}

export function useDynamicSelectedFrequency(
  setSelectedFrequency: (selectedFrequency: InvoiceFrequencySettings) => void,
) {
  useEffect(() => {
    const listener = (event: CustomEvent<SelectedFrequencyEvent>) => {
      if (event?.detail?.selected_frequency === undefined) {
        return;
      }

      const eventSelectedFrequency = parseSelectedFrequency(
        event.detail.selected_frequency,
      );

      if (typeof eventSelectedFrequency === "string") {
        return;
      }

      if (
        !isValidNumberFrequency(eventSelectedFrequency) &&
        !isBillingRuleFrequencyResponse(eventSelectedFrequency)
      ) {
        return;
      }

      const frequencyType = isBillingRuleFrequencyResponse(
        eventSelectedFrequency,
      )
        ? FrequencyType.CUSTOM
        : selectedFrequencyEventNumberToTypeMap[eventSelectedFrequency];

      const selectedFrequency: InvoiceFrequencySettings = {
        frequencyText: event.detail.selected_frequency_text,
        frequencyType,
      };

      setSelectedFrequency(selectedFrequency);
    };

    window.addEventListener("jobber.selected_frequency.updated", listener);

    return () => {
      window.removeEventListener("jobber.selected_frequency.updated", listener);
    };
  }, [setSelectedFrequency]);
}
