import type {
  BankAccountPaymentMethod,
  CreditCardPaymentMethod,
} from "~/utilities/API/graphql";
import {
  ExpiryStatus,
  type StoredPaymentMethodInterface,
  StoredPaymentMethodType,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import { isBankAccountPaymentMethod } from "utilities/jobberPayments/paymentMethods/paymentMethods";

function getExpiryStatus({
  expired,
  expiringSoon,
}: {
  expired: boolean;
  expiringSoon: boolean;
}) {
  let expiryStatus = ExpiryStatus.DEFAULT;
  if (expired) {
    expiryStatus = ExpiryStatus.EXPIRED;
  } else if (expiringSoon) {
    expiryStatus = ExpiryStatus.EXPIRING_SOON;
  }

  return expiryStatus;
}

export function mapToStoredPaymentMethod(
  paymentMethod: BankAccountPaymentMethod | CreditCardPaymentMethod,
): StoredPaymentMethodInterface {
  return isBankAccountPaymentMethod(paymentMethod)
    ? {
        bankName: paymentMethod.bankName,
        isDefault: paymentMethod.defaultMethod,
        fingerprint: paymentMethod.fingerprint,
        last4: paymentMethod.last4,
        uuid: paymentMethod.uuid,
        type: StoredPaymentMethodType.BANK_ACCOUNT,
      }
    : {
        brand: mapBrand(paymentMethod.brand),
        expiryMonth: paymentMethod.expiryMonth,
        expiryYear: paymentMethod.expiryYear,
        expiryStatus: getExpiryStatus({
          expired: paymentMethod.expired,
          expiringSoon: paymentMethod.expiringSoon,
        }),
        isDefault: paymentMethod.defaultMethod,
        last4: paymentMethod.last4,
        uuid: paymentMethod.uuid,
        type: StoredPaymentMethodType.CREDIT_CARD,
        fingerprint: paymentMethod.fingerprint,
      };
}

export function mapBrand(paymentMethodBrand: string): string {
  switch (paymentMethodBrand) {
    case "American Express":
      return "amex";
    case "Discover":
      return "discover";
    case "JCB":
      return "jcb";
    case "MasterCard":
      return "mastercard";
    case "Visa":
      return "visa";
    default:
      return "unknown";
  }
}
