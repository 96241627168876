import { Button } from "@jobber/components";
import { useState } from "react";
import { useIntl } from "react-intl";
import type { LineItem } from "~/jobber/lineItems/types";
import { messages } from "./messages";

interface LineItemDeleteButtonProps {
  lineItem: LineItem;
  onDelete(lineItem: LineItem): Promise<void>;
}

export function LineItemDeleteButton({
  onDelete,
  lineItem,
}: LineItemDeleteButtonProps) {
  const { formatMessage } = useIntl();
  const [deleting, setDeleting] = useState(false);
  const handleDeleteClick = async () => {
    setDeleting(true);
    await onDelete(lineItem);
    setDeleting(false);
  };

  return (
    <Button
      label={formatMessage(messages.delete)}
      variation={"destructive"}
      type={"tertiary"}
      ariaLabel={formatMessage(messages.delete)}
      size="small"
      onClick={handleDeleteClick}
      loading={deleting}
    />
  );
}
