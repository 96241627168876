import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reviews: {
    id: "reviews.reviewsFilter.reviews",
    defaultMessage: "Reviews",
    description: "Label for the combobox",
  },
  all: {
    id: "reviews.reviewsFilter.all",
    defaultMessage: "All",
    description: "Label for the filter all option",
  },
  unanswered: {
    id: "reviews.reviewsFilter.unanswered",
    defaultMessage: "Unanswered",
    description: "Label for the filter unanswered option",
  },
});
