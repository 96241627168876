import { defineMessages } from "react-intl";

const prefix = "setupWizard.revenuePage.";

export const messages = defineMessages({
  revenuePageDefaultHeading: {
    id: `${prefix}revenuePageDefaultHeading`,
    defaultMessage: "Let's fine-tune your Jobber experience",
    description: "Default revenue page heading for the setup wizard",
  },
  revenuePageDefaultCopy: {
    id: `${prefix}revenuePageDefaultCopy`,
    defaultMessage:
      "We use this to ensure the tools within scheduling, invoicing, and client management fit your business needs.",
    description: "Default revenue page copy for the setup wizard",
  },
});
