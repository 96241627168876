import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { NotificationSettingsWrapper } from "./NotificationSettingsWrapper";

export function NotificationSettings() {
  return (
    <APIProvider>
      <NotificationSettingsWrapper />
    </APIProvider>
  );
}
