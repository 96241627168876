export function getNameWithContactDetailTitle(
  name: string | undefined,
  contactDetail: string | undefined,
) {
  return `${name || "Unknown"} • ${contactDetail}`;
}

export function getNameOrContactDetailTitle(
  name: string | undefined,
  contactDetail: string,
): string {
  return name && name !== "Unknown" ? name : contactDetail;
}
