import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import React from "react";
import styles from "./TrendChip.module.css";

interface TrendChipProps {
  trend?: {
    isPositive: boolean;
    value: number;
    showPercentage: boolean;
  };
}

export function TrendChip({ trend }: TrendChipProps): JSX.Element {
  const isTrendEmpty = trend === undefined;
  const trendText = isTrendEmpty
    ? "–"
    : (trend.isPositive ? "↑  " : "↓  ") +
      trend.value +
      (trend.showPercentage ? "%" : "");

  return (
    <div
      className={classNames(
        isTrendEmpty || trend.isPositive ? styles.positive : styles.negative,
        styles.ratingChip,
      )}
      data-testid="trendText"
    >
      <Text>{trendText}</Text>
    </div>
  );
}
