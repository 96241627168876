import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Tooltip } from "@jobber/components/Tooltip";
import React from "react";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  BankAccountInformation,
  BankAccountInformationAction,
} from "./bankAccountInformationReducer";

interface AddBankAccountFormProps {
  bankAccountInformation: BankAccountInformation;
  errorMessage?: string;
  showIBAN: boolean;
  bankAccountInformationDispatch(action: BankAccountInformationAction): void;
}

export function AddBankAccountForm(props: AddBankAccountFormProps) {
  const {
    bankAccountInformation,
    bankAccountInformationDispatch,
    errorMessage,
    showIBAN,
  } = props;

  const [t] = useTranslation();

  const accountInputPlaceholder = showIBAN
    ? t("ibanNumber")
    : t("accountNumber");

  const accountHelpText = showIBAN
    ? t("ibanNumberHelp")
    : t("accountNumberHelp");

  return (
    <Content>
      {errorMessage && <Banner type="error">{errorMessage}</Banner>}
      <Text>{t("manualAddBankPrompt")}</Text>
      <InputText
        value={bankAccountInformation.accountHolder}
        onChange={handleAccountHolderChange}
        placeholder={t("holderName")}
      />
      {!showIBAN && (
        <div className="row collapse align-middle">
          <div className="columns">
            <InputText
              value={bankAccountInformation.routingNumber}
              onChange={handleRoutingNumberChange}
              placeholder={t("routingNumber")}
            />
          </div>
          <div className="u-marginLeftSmall">
            <Tooltip message={t("routingNumberHelp")}>
              <Icon name="help" />
            </Tooltip>
          </div>
        </div>
      )}
      <div className="row collapse align-middle">
        <div className="columns">
          <InputText
            value={bankAccountInformation.accountNumber}
            onChange={handleAccountNumberChange}
            placeholder={accountInputPlaceholder}
          />
        </div>
        <div className="u-marginLeftSmall">
          <Tooltip message={accountHelpText}>
            <Icon name="help" />
          </Tooltip>
        </div>
      </div>
    </Content>
  );

  function handleAccountHolderChange(newValue: string) {
    bankAccountInformationDispatch({ type: "updateAccountHolder", newValue });
  }

  function handleRoutingNumberChange(newValue: string) {
    bankAccountInformationDispatch({ type: "updateRoutingNumber", newValue });
  }

  function handleAccountNumberChange(newValue: string) {
    bankAccountInformationDispatch({ type: "updateAccountNumber", newValue });
  }
}
