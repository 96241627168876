import * as React from "react";

export interface NameOnCardInputProps {
  name?: string;
  disabled?: boolean;

  onNameChange?(name: string): void;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function NameOnCardInput(props: NameOnCardInputProps) {
  const { name, disabled, onNameChange } = props;
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onNameChange && onNameChange(e.target.value),
    [onNameChange],
  );

  return (
    <placeholder-field label="Name on card">
      <input
        className="input"
        autoComplete="billing cc-name"
        onChange={onChange}
        value={name}
        disabled={disabled}
        data-testid="nameOnCardInput"
      />
    </placeholder-field>
  );
}
