import { cloneDeep } from "lodash";
import {
  type ClientNotification,
  NotificationDeliveryMethod,
  NotificationReminderUnit,
  type VisitCommsTemplateDataQuery,
} from "~/utilities/API/graphql";
import type { SettingsRowProps } from "jobber/settings/notifications/components/SettingsSection";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import { content } from "jobber/features/Settings/views/CommsSettings/content";

export interface SectionType {
  title: string;
  sectionData: SettingsRowProps[];
}

export function injectDefaultSchedule(data: VisitCommsTemplateDataQuery) {
  if (data.visitReminder && !data.visitReminder.schedules.nodes.length) {
    const dataWithNewSchedules = cloneDeep(data);
    (dataWithNewSchedules.visitReminder as ClientNotification).schedules.nodes =
      [
        {
          id: "1",
          enabled: true,
          deliveryMethod: NotificationDeliveryMethod.EMAIL,
          before: true,
          offset: {
            value: 1,
            unit: NotificationReminderUnit.DAY,
            __typename: "Offset",
          },
          __typename: "NotificationSchedule",
        },
        {
          id: "2",
          enabled: true,
          deliveryMethod: NotificationDeliveryMethod.SMS,
          before: true,
          offset: {
            value: 1,
            unit: NotificationReminderUnit.HOUR,
            __typename: "Offset",
          },
          __typename: "NotificationSchedule",
        },
      ];

    return dataWithNewSchedules;
  }

  return data;
}

export function getSection(
  data: VisitCommsTemplateDataQuery,
): SettingsRowProps | undefined {
  if (data.visitReminder) {
    return {
      descriptions: {
        ...content.visitReminders,
        scheduleTemplate: getCorrectString(content.visitReminders.templates),
      },
      notification: setCurrentToDefault(data.visitReminder),
    };
  }
}
//TemplatePreviewer only sends current to MustachePreview, whereas we want to use the same component to show the default
function setCurrentToDefault(
  data: NonNullable<VisitCommsTemplateDataQuery["visitReminder"]>,
) {
  const templateDefaultToCurrent = cloneDeep(data);
  templateDefaultToCurrent?.templates.nodes.forEach(template => {
    template.message.current = template.message.default;
    if (template.__typename === "MessageTemplateEmail") {
      template.subject.current = template.subject.default;
    }
  });
  return templateDefaultToCurrent;
}

export function getCorrectString(stringDictionary: {
  withSms: string;
  withoutSms: string;
}) {
  if (jobberOnline.features.smsMessaging.enabled) {
    return stringDictionary.withSms;
  }

  return stringDictionary.withoutSms;
}

export const formatTime = (date: Date): string => {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hourCycle: window.is_24_hour_format ? "h23" : "h12",
  });
};
