import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages } from "./messages";

// Selectors

export function queryPauseAndEditCampaignModalTitle() {
  return screen.queryByText(messages.title.defaultMessage);
}

export function getCancelButton() {
  return screen.getByText("Cancel");
}

export function getDialog() {
  return screen.getByRole("dialog");
}

// Query

export function queryDialog() {
  return screen.queryByRole("dialog");
}

// Solo Actions

export function iClickCancelButton() {
  userEvent.click(getCancelButton());
}

export function iClickPauseAndEditCampaignButton() {
  userEvent.click(screen.getByText(messages.buttonLabel.defaultMessage));
}
