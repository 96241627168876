import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useMemo, useReducer } from "react";
import _ from "lodash";
import { useTwilioRegistrationDataMemoization } from "jobber/settings/dedicatedPhoneNumber/hooks/useTwilioRegistrationDataMemoization";
import {
  SOLE_PROPRIETORSHIP,
  businessTypesList,
} from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationBusinessInformation/RegistrationBusinessInformationData";
import { jobPositionsList } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationPointOfContact/JobPositionsList";
import {
  hasTaxIdRegistrationError,
  isEinValid,
} from "jobber/settings/dedicatedPhoneNumber/utilities/businessRegistrationNumberHelpers";
import { USStateList } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/USStateList";
import { usePurchaseDedicatedPhoneNumber } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/hooks/usePurchaseDedicatedPhoneNumber";
import {
  SUBMIT_TWILIO_REG,
  TWILIO_REG_INFO,
} from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationForm.graphql";
import {
  type TwilioRegistrationFormReducerState,
  twilioRegistrationFormReducer,
} from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import type {
  MutationErrors,
  TwilioRegistrationInfoQuery,
  TwilioRegistrationMutationMutation,
} from "~/utilities/API/graphql";
import { isUrlValid } from "../utilities/websiteUrlHelpers";
import {
  hasInvalidZipCode,
  hasPOBoxAddress,
} from "../utilities/addressHelpers";

const defaultState = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  street1: undefined,
  street2: undefined,
  city: undefined,
  province: undefined,
  pc: undefined,
  businessRegistrationNumber: undefined,
  businessType: undefined,
  businessName: undefined,
  pocCheckbox: false,
  websiteUrl: undefined,
  country: "United States",
  jobPosition: undefined,
  businessTitle: undefined,
  userErrors: undefined,
  formErrors: {
    address: undefined,
    pc: undefined,
  },
};

const unexpectedErrorBuyingPhoneNumber =
  "There was an issue saving your phone number. Please try again later.";
const unexpectedErrorRegisteringNumber =
  "There was an issue registering your phone number. Please try again later.";

export function useTwilioRegistrationData(requiresRegistration: boolean) {
  const { loading, data } =
    useQuery<TwilioRegistrationInfoQuery>(TWILIO_REG_INFO);
  const [state, dispatch] = useReducer(
    twilioRegistrationFormReducer,
    defaultState,
  );

  const formData = {
    emailAddress: state.email,
    firstName: state.firstName,
    lastName: state.lastName,
    businessName: state.businessName,
    phoneNumber: state.phone,
    street: `${state.street1} ${state.street2}`.trim(),
    city: state.city,
    region: state.province,
    postalCode: state.pc,
    websiteUrl: state.websiteUrl,
    businessRegistrationNumber: state.businessRegistrationNumber,
    jobPosition: state.jobPosition,
    businessTitle: state.businessTitle,
    businessType: state.businessType,
  };

  const { savedNumber, mutationLoading, handleSaveNumber } =
    usePurchaseDedicatedPhoneNumber(requiresRegistration);
  const { memoizedBusinessName, memoizedBusinessRegistrationNumber } =
    useTwilioRegistrationDataMemoization(state);

  const [submitForm] =
    useMutation<TwilioRegistrationMutationMutation>(SUBMIT_TWILIO_REG);

  const handleUpdate = (
    dataUpdates: Partial<TwilioRegistrationFormReducerState>,
  ) => {
    dispatch({
      type: "Update",
      data: dataUpdates,
    });
  };

  const canSubmitForm = useMemo(() => {
    let isFormCompleted =
      state.firstName &&
      state.lastName &&
      state.email &&
      state.phone &&
      state.street1 &&
      !hasPOBoxAddress(state.street1) &&
      state.city &&
      state.province &&
      state.pc &&
      !hasInvalidZipCode(state.pc) &&
      state.jobPosition &&
      state.businessType &&
      state.businessName &&
      state.pocCheckbox &&
      isBusinessTitleRequired(state.jobPosition, state.businessTitle) &&
      isUrlValid(state.websiteUrl);

    if (state.businessType !== SOLE_PROPRIETORSHIP) {
      isFormCompleted =
        isFormCompleted && isEinValid(state.businessRegistrationNumber);
    }

    if (hasTaxIdRegistrationError(state.userErrors)) {
      const hasBusinessNameChanged =
        memoizedBusinessName.current?.localeCompare(
          state.businessName ?? "",
        ) !== 0;
      const hasBusinessRegistrationNumberChanged =
        memoizedBusinessRegistrationNumber.current?.localeCompare(
          state.businessRegistrationNumber ?? "",
        ) !== 0;

      isFormCompleted =
        isFormCompleted &&
        (hasBusinessNameChanged || hasBusinessRegistrationNumberChanged);
    }

    const isFormErrorsFalsy =
      state.formErrors &&
      Object.values(state.formErrors).some((v: string | undefined) => v);

    return !!isFormCompleted && !isFormErrorsFalsy;
  }, [state]);

  const handleSubmit = async (phoneNumber?: string) => {
    handleUpdate({ formSubmitted: true });
    if (phoneNumber) {
      if (!requiresRegistration || canSubmitForm) {
        return handleSaveNumber(phoneNumber, formData)
          .then(
            (response: {
              mutationErrors: MutationErrors[];
              responseNumber: string;
            }) => {
              handleMutationErrors(response.mutationErrors);
              return response;
            },
          )
          .catch(updateStateRethrow);
      }
      return Promise.reject("Form submitted without valid state.");
    } else {
      if (canSubmitForm) {
        const variables = {
          input: formData,
        };
        return submitForm({ variables })
          .then(submitResult => {
            if (submitResult) {
              handleMutationErrors(
                submitResult.data?.createTwilio10dlcA2pRegistration
                  .userErrors || [],
              );
              return {
                responseNumber: undefined,
                mutationErrors:
                  submitResult.data?.createTwilio10dlcA2pRegistration
                    .userErrors,
              };
            }
          })
          .catch(updateStateRethrow);
      }

      return Promise.reject("Form submitted without valid state.");
    }
    function updateStateRethrow(error: Error) {
      handleUpdate({
        generalError: phoneNumber
          ? unexpectedErrorBuyingPhoneNumber
          : unexpectedErrorRegisteringNumber,
      });
      throw error;
    }

    function handleMutationErrors(mutationErrors: MutationErrors[]) {
      const phoneNumberError = mutationErrors?.find(error =>
        _.isEqual(error.path, ["phoneNumber"]),
      );
      const otherErrors = mutationErrors.filter(
        error => !_.isEqual(error.path, ["phoneNumber"]),
      );
      let generalError: string | undefined;
      if (otherErrors.length === 1) {
        generalError = otherErrors[0].message;
      } else if (otherErrors.length > 1) {
        generalError = phoneNumber
          ? unexpectedErrorBuyingPhoneNumber
          : unexpectedErrorRegisteringNumber;
      }

      handleUpdate({
        smsablePhoneError: phoneNumberError?.message,
        generalError,
      });
    }
  };

  useEffect(() => {
    if (data && data.twilioCarrierRegistrationData) {
      handleUpdate({
        firstName: data.twilioCarrierRegistrationData.firstName,
        lastName: data.twilioCarrierRegistrationData.lastName,
        email: data.twilioCarrierRegistrationData.emailAddress,
        phone: data.twilioCarrierRegistrationData.phoneNumber,
        street1: data.twilioCarrierRegistrationData.street,
        street2: "",
        city: data.twilioCarrierRegistrationData.city,
        province:
          USStateList.find(
            province =>
              province.label === data.twilioCarrierRegistrationData?.region,
          )?.value ||
          USStateList.find(
            province =>
              province.value === data.twilioCarrierRegistrationData?.region,
          )?.value ||
          "",
        pc: data.twilioCarrierRegistrationData.postalCode,
        country: state.country,
        websiteUrl: data.twilioCarrierRegistrationData.websiteUrl,
        pocCheckbox: state.pocCheckbox,
        businessName: data.twilioCarrierRegistrationData.businessName,
        businessType: getBusinessType(
          data.twilioCarrierRegistrationData.businessType,
        ),
        businessRegistrationNumber:
          data.twilioCarrierRegistrationData.businessRegistrationNumber,
        jobPosition: getJobPosition(
          data.twilioCarrierRegistrationData.jobPosition,
        ),
        businessTitle: data.twilioCarrierRegistrationData.businessTitle,
        userErrors: data.twilioCarrierRegistrationData.userErrors,
      });
    }
  }, [data]);

  return {
    state,
    loading: loading || mutationLoading,
    handleUpdate,
    handleSubmit,
    savedNumber,
  };
}

function getBusinessType(businessType: string | undefined) {
  return businessTypesList.find(bt => bt.label === businessType)?.value;
}

function getJobPosition(jobPosition: string | undefined) {
  return jobPositionsList.find(jp => jp.label === jobPosition)?.value;
}

function isBusinessTitleRequired(
  jobPosition: string | undefined,
  businessTitle: string | undefined,
) {
  const OTHER_JOB_TITLE = jobPositionsList.find(
    position => position?.value === "OTHER",
  )?.value;

  return !!(
    (jobPosition === OTHER_JOB_TITLE && businessTitle) ||
    jobPosition !== OTHER_JOB_TITLE
  );
}
