import React from "react";
import { useIntl } from "react-intl";
import MaskingTapeSrc from "@images/signup/large-masking-tape.svg";
import DidYouKnowSrc from "@images/signup/did-you-know.svg";
import { messages } from "jobber/setupWizard/messages";
import styles from "./MaskingTapeFact.module.css";

interface MaskingTapeFactProps {
  fact: React.ReactNode;
}

export function MaskingTapeFact({ fact }: MaskingTapeFactProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.factWrapper}>
      <div className={styles.maskingTapeWrapper}>
        <img
          src={MaskingTapeSrc}
          alt={formatMessage(messages.maskingTapeBgAltText)}
          className={styles.backgroundSvg}
        />
        <img
          src={DidYouKnowSrc}
          alt={formatMessage(messages.didYouKnowAltText)}
          className={styles.beforeSvg}
        />
        <div className={styles.content}>{fact}</div>
      </div>
    </div>
  );
}
