import type { TypePolicies } from "@apollo/client";

export const typePolicies: TypePolicies = {
  // Prevent Recipient from being cached as a separate object
  // Recipient will be cached on parent object.
  // We will remove this in - Refactor Recipient type to have meaningful ID JOB-40961
  Recipient: {
    keyFields: false,
    merge: true,
  },
  QuantityRange: {
    keyFields: false,
    merge: true,
  },
  JobViewExpenses: {
    merge: true,
    fields: {
      items: {
        keyArgs: false,
        merge: mergePaginatedConnections,
      },
    },
  },
  JobViewLineItems: {
    merge: true,
    fields: {
      items: {
        keyArgs: false,
        merge: mergePaginatedConnections,
      },
    },
  },
  JobViewLabour: {
    merge: true,
    fields: {
      items: {
        keyArgs: false,
        merge: mergePaginatedConnections,
      },
    },
  },
  Query: {
    fields: {
      job: {
        merge: true,
      },
      jobView: {
        merge: true,
      },
      onlineBookingServices: {
        keyArgs: false,
        merge: mergeServicesWithOrder,
      },
    },
  },
  User: {
    fields: {
      name: {
        merge: true,
      },
    },
  },
  Account: {
    fields: {
      companyDetails: {
        merge: true,
      },
      settings: {
        merge: true,
      },
    },
  },
  AboutSection: {
    fields: {
      image: {
        merge: true,
      },
    },
  },
  HeroSection: {
    fields: {
      image: {
        merge: true,
      },
    },
  },
  FeaturedContentSection: {
    fields: {
      image: {
        merge: true,
      },
    },
  },
};

function mergePaginatedConnections(
  existing: ConnectionType | undefined,
  incoming: ConnectionType | undefined,
): ConnectionType {
  const existingLineItems = existing?.nodes || [];
  const incomingNodes = incoming?.nodes || [];
  const nodes = [...existingLineItems, ...incomingNodes];

  return {
    ...incoming,
    nodes: uniqueNodes(nodes),
  };
}

function mergeServicesWithOrder(
  existing: ConnectionType | undefined,
  incoming: ConnectionType | undefined,
): ConnectionType {
  const existingNodes = existing?.nodes || [];
  const incomingNodes = incoming?.nodes || [];
  const uniqueCombinedNodes = uniqueNodes([...existingNodes, ...incomingNodes]);
  // we want to use the combined set if there are new nodes (fetchMore/add new nodes)
  if (uniqueCombinedNodes.length > existingNodes.length) {
    return {
      ...incoming,
      nodes: uniqueCombinedNodes,
    };
  } else {
    // otherwise we just want to replace the existing nodes with the incoming nodes (reorder/delete nodes)
    return {
      ...incoming,
      nodes: [...incomingNodes],
    };
  }
}

export function uniqueNodes<T extends Node>(nodes: T[]): T[] {
  const result = new Map<string, T>();
  nodes.forEach(node => {
    result.set(node.__ref, node);
  });
  return Array.from(result.values());
}

interface Node {
  __ref: string;
}

interface ConnectionType {
  nodes: Node[];
}
