import React from "react";
import { sanitize } from "dompurify";
import styles from "./EmailBody.module.css";

interface EmailBodyProps {
  body: string;
}

export function EmailBody({ body }: EmailBodyProps): JSX.Element {
  return (
    <div className={styles.emailBody}>
      <div
        className={styles.htmlEmailBody}
        data-testid={"EmailBodyHtml"}
        /* eslint-disable-next-line react/no-danger*/
        dangerouslySetInnerHTML={{ __html: sanitize(body) }}
      ></div>
    </div>
  );
}
