import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { messages as emptyOfferingMessages } from "./messages";
import styles from "./styles.module.css";

interface EmptyOfferingsProps {
  onAddService(): void;
}

export function EmptyOfferings({ onAddService }: EmptyOfferingsProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Icon name="alert" size="large" />
      </div>
      <Heading level={4}>
        {formatMessage(emptyOfferingMessages.heading)}
      </Heading>
      <div className={styles.row}>
        <Text size="base">
          {formatMessage(emptyOfferingMessages.subHeading)}
        </Text>
        <button className={styles.link} onClick={onAddService}>
          {formatMessage(emptyOfferingMessages.subHeadingLink)}
        </button>
      </div>
    </div>
  );
}
