import React, { type ReactNode } from "react";
import { Icon } from "@jobber/components/Icon";
import styles from "./Disclosure.module.css";

interface DisclosureProps {
  children: ReactNode | ReactNode[];
  summary: ReactNode | ReactNode[];
  isOpen: boolean;
  onToggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Disclosure({
  children,
  summary,
  isOpen,
  onToggleOpen,
}: DisclosureProps) {
  return (
    <div className={styles.container}>
      <details open={isOpen} className={styles.details}>
        <summary onClick={onToggle} className={styles.summary}>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryWrap}>{summary}</div>
            <Icon color={"green"} name={isOpen ? "arrowDown" : "arrowUp"} />
          </div>
        </summary>
        <span className={styles.content}>{children}</span>
      </details>
    </div>
  );

  function onToggle(event: React.MouseEvent<HTMLDetailsElement>) {
    event.preventDefault();

    onToggleOpen(!isOpen);
  }
}
