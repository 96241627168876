import { DisputeEvidenceReason } from "~/utilities/API/graphql";

export const SupportingEvidencePoints = {
  [DisputeEvidenceReason.WITHDREW]: [
    "Documents showing that the client withdrew the dispute, such as a confirmation letter from their card issuer that the dispute has been withdrawn",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.REFUNDED]: [
    "Proof that you’ve already compensated the client fully or partially (e.g., receipt or bank statement)",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.NON_REFUNDABLE]: [
    "Your refund policy, as shown to the client",
    "Communication with the client that shows the client was made aware of your refund policy",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.LATE_REQUEST]: [
    "Your refund policy, as shown to the client",
    "Communication with the client that shows the client was made aware of your refund or cancellation policy",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.SATISFIED]: [
    "Documents showing proof that a service was provided to the client (e.g., signed contract, work order, or other form of written agreement) or that they are still using the service",
    "Photos and screenshots of the product or service in progress and/or completed, GPS trackers, timers, or job forms",
    "Documents showing the advertised product or service",
    "Documents or receipts showing your expenses related to this product or service",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Evidence that the client who signed for the service was authorized to sign for, or is known by the cardholder",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.CREDIT]: [
    "Proof that you’ve already compensated the client",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.GOVT_DELAY]: [
    "Documents or communication showing the service was delayed due to a government order or other reason (e.g., inclement weather)",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.UNIQUE_PURCHASE]: [
    "Proof that you’ve already compensated the client",
    "Documents showing each payment was for a separate product or service",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Other communication with the client that you feel is relevant to your case",
  ],
  [DisputeEvidenceReason.OTHER]: [
    "Photos and screenshots of the product or service in progress and/or completed, GPS trackers, timers, or job forms",
    "Documents or receipts showing your expenses related to this product or service",
    "Documents showing the client’s signature",
    "Invoice or message sent to the client notifying them of the charge",
    "Evidence that the client who signed for the service was authorized to sign for, or is known by the cardholder",
    "Other communication with the client that you feel is relevant to your case",
  ],
};
