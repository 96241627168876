import { strFormatDate } from "@jobber/components/FormatDate";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";

export function compareAddons(
  addon1: SubscriptionAddon,
  addon2: SubscriptionAddon,
) {
  if (addon1.isActive && !addon2.isActive) {
    return -1;
  } else if (!addon1.isActive && addon2.isActive) {
    return 1;
  }
  return 0;
}

export function formatRenewalDate(renewalDate: string | undefined) {
  if (renewalDate) {
    return strFormatDate(new Date(renewalDate));
  }
  return "";
}
