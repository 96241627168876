import React from "react";
import { Text } from "@jobber/components/Text";
import { Checkbox } from "@jobber/components/Checkbox";
import { Divider } from "@jobber/components/Divider";
import { createBulletSection } from "jobber/managed_accounts/translationHelper";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type {
  BankAccountInformation,
  BankAccountInformationAction,
} from "./bankAccountInformationReducer";
import styles from "./DirectDebitInfoSheet.module.css";

export interface DirectDebitInfoSheetProps {
  bankAccountInformation: BankAccountInformation;
  bankAccountInformationDispatch(action: BankAccountInformationAction): void;
}

export function DirectDebitInfoSheet(props: DirectDebitInfoSheetProps) {
  const { bankAccountInformation, bankAccountInformationDispatch } = props;

  const [directDebitLogo] = useUrls("directDebitLogoImgUrl");
  const [t] = useTranslation();
  const [debitAgreementFormTitle, debitAgreementFormDetails] =
    createBulletSection("debitAgreementFormTitle", "debitAgreementFormDetails");
  const [directDebitGuaranteeTitle, directDebitGuaranteeDetails] =
    createBulletSection(
      "directDebitGuaranteeTitle",
      "directDebitGuaranteeDetails",
    );
  return (
    <>
      <Text>
        <strong>{debitAgreementFormTitle}</strong>
      </Text>
      <ul className={styles.bulletPoints}>
        {debitAgreementFormDetails}
        <li>
          <strong>{t("debitAgreementFormBoldedDetails")}</strong>
        </li>
      </ul>
      <Checkbox
        label="I confirm that I read and agree with the statements above"
        checked={
          bankAccountInformation.debitAgreementForm
            .debitAgreementShownAndAccepted
        }
        onChange={handleConfirmForm}
      />
      <Divider />
      <img width="20%" alt="direct debit logo" src={directDebitLogo} />
      <Text>
        <strong>{directDebitGuaranteeTitle}</strong>
      </Text>
      <ul className={styles.bulletPoints}>{directDebitGuaranteeDetails}</ul>
    </>
  );

  function handleConfirmForm(newValue: boolean) {
    bankAccountInformationDispatch({ type: "confirmForm", newValue });
  }
}
