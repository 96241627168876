import React, { useEffect, useState } from "react";
import { Option, Select } from "@jobber/components/Select";
import {
  Autocomplete,
  type Option as OptionAutoComplete,
} from "@jobber/components/Autocomplete";
import { useQuery } from "@apollo/client";
import { Glimmer } from "@jobber/components/Glimmer";
import { useIntl } from "react-intl";
import { GET_CLIENT_TAG_LABELS } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/ClientTagCriterion/ClientTagCriterion.graphql";
import {
  type GetClientTagLabelsQuery,
  Operator,
} from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";
import { useUpdateClientTagCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/hooks/useUpdateClientTagCriteria";
import { useClientTagOptions } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/ClientTagCriterion/hooks/useClientTagOptions";
import styles from "./ClientTagCriterion.module.css";

interface ClientTagCriterionProps {
  clientTagValue: string;
  operator: Operator;
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

interface Option {
  value: number;
  label: string;
}

export function ClientTagCriterion({
  clientTagValue,
  operator,
  criteriaIndex,
  updateAdditionalCriteria,
}: ClientTagCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  const { loading, data } = useQuery<GetClientTagLabelsQuery>(
    GET_CLIENT_TAG_LABELS,
  );

  const {
    options: basicOptions,
    optionsLoaded,
    getOptions,
  } = useClientTagOptions(data);

  const selectValue = operator || Operator.NOT_EQUAL;

  const [autocompleteValue, setAutocompleteValue] = useState<
    OptionAutoComplete | undefined
  >(undefined);

  useEffect(() => {
    const updatedValue = clientTagValue
      ? {
          label: clientTagValue,
          value:
            basicOptions.findIndex(option => option.label === clientTagValue) +
            1,
        }
      : undefined;
    if (updatedValue?.label !== autocompleteValue?.label) {
      setAutocompleteValue(updatedValue);
    }
  }, [clientTagValue, basicOptions, autocompleteValue]);

  const onAdditionalCriteriaChange = useUpdateClientTagCriteria({
    updateAdditionalCriteria,
  });

  function onSelectionChange(value: Operator) {
    onAdditionalCriteriaChange({
      selectValue: value,
      clientTagValue: autocompleteValue?.label || "",
      criteriaIndex,
    });
  }

  function onAutocompleteChange(value?: OptionAutoComplete) {
    setAutocompleteValue(value);
    onAdditionalCriteriaChange({
      selectValue,
      clientTagValue: value?.label || "",
      criteriaIndex,
    });
  }

  return (
    <div data-testid={"clientTagCriterion"}>
      {loading && !optionsLoaded && <Glimmer size="larger" />}
      {!loading && optionsLoaded && (
        <div className={styles.clientTagCriteriaContainer}>
          <Select
            defaultValue={selectValue}
            onChange={onSelectionChange}
            validations={{
              required: {
                value: true,
                message: formatMessage(messages.pleaseSelectCriterion),
              },
            }}
          >
            <Option value={Operator.NOT_EQUAL}>
              {formatMessage(messages.clientTagOptionNotContain)}
            </Option>
            <Option value={Operator.EQUAL}>
              {formatMessage(messages.clientTagOptionContain)}
            </Option>
          </Select>
          <Autocomplete
            placeholder={formatMessage(
              messages.clientTagAutoCompletePlaceholder,
            )}
            initialOptions={basicOptions}
            value={autocompleteValue}
            onChange={onAutocompleteChange}
            getOptions={getOptions}
            allowFreeForm={false}
            validations={{
              required: {
                value: true,
                message: formatMessage(messages.clientTagFieldRequired),
              },
            }}
            suffix={
              autocompleteValue && {
                icon: "cross",
                ariaLabel: "clear search",
                onClick: () => onAutocompleteChange(undefined),
              }
            }
          />
        </div>
      )}
    </div>
  );
}
