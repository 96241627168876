import React, { type Dispatch, type SetStateAction } from "react";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import styles from "./Accordion.module.css";
import { AccordionItem } from "./components/AccordionItem/AccordionItem";
import type { AccordionItemSummary } from "./components/AccordionItem/types";

interface AccordionProps {
  separateWithDivider?: boolean | undefined;
  removeBackgroundColour?: boolean | undefined;
  items: AccordionItemSummary[];
  currentItemIndex: number;
  setCurrentItemIndex: Dispatch<SetStateAction<number>>;
  includeTitlePadding?: boolean;
}

export function Accordion({
  items,
  currentItemIndex,
  separateWithDivider,
  removeBackgroundColour,
  includeTitlePadding = true,
  setCurrentItemIndex,
}: AccordionProps) {
  const CLOSED = -1000;
  const handleItemChange = (index: number) => {
    if (currentItemIndex === index) {
      setCurrentItemIndex(CLOSED);
    } else {
      setCurrentItemIndex(index);
    }

    const currentItem: AccordionItemSummary = items[index];
    currentItem?.onClick?.();
  };

  return (
    <Content>
      <ul className={styles.accordionContainer}>
        {items.map((item, index) => {
          return (
            <li key={index}>
              <AccordionItem
                removeBackgroundColour={removeBackgroundColour}
                id={index}
                leftIcon={item.leftIcon}
                title={item.title}
                content={item.content}
                textDisabled={item.textDisabled}
                selected={index == currentItemIndex}
                handleItemChange={handleItemChange}
                includeTitlePadding={includeTitlePadding}
                secondaryTitle={item.secondaryTitle}
              />
              {separateWithDivider && <Divider />}
            </li>
          );
        })}
      </ul>
    </Content>
  );
}
