import { Button } from "@jobber/components/Button";
import type { ActionProps } from "@jobber/components/Menu";
import { Menu } from "@jobber/components/Menu";
import React, { useContext, useState } from "react";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton";
import { clickedViewClient } from "jobber/chat/utilities/AmplitudeTrackingEvents";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { DeleteConversationModal } from "../DeleteConversationModal";

interface ConversationActionsProps {
  markUnread(): void;
  deleteConversation(): void;
  reassignConversation(): void;
}

function ConversationActionsButton({
  markUnread,
  deleteConversation,
  reassignConversation,
}: ConversationActionsProps) {
  const [state] = useContext(MessageCenterContext);
  const [showDeleteConversationModal, setShowDeleteConversationModal] =
    useState(false);
  const isDeleteSmsConversationEnabled = useFeatureFlag(
    SplitNames.CommsDeleteSmsConversationOnline,
  );
  const isReassignSmsConversationEnabled = useFeatureFlag(
    SplitNames.CommsReassignSmsConversationOnline,
  );

  function clickViewClient() {
    if (!state.selectedRecipient?.id) return;

    const id = decodeId(state.selectedRecipient.id);
    clickedViewClient();
    window.open(`/clients/${id}`);
  }

  function handleDeleteConversation() {
    deleteConversation();
    setShowDeleteConversationModal(false);
  }

  const actions: ActionProps[] = [
    { label: "Mark as unread", onClick: markUnread, destructive: false },
    state.selectedRecipient?.id && {
      label: "View client",
      onClick: clickViewClient,
      destructive: false,
    },
    isReassignSmsConversationEnabled && {
      label: "Reassign conversation",
      onClick: reassignConversation,
      destructive: false,
    },
    isDeleteSmsConversationEnabled && {
      label: "Delete conversation",
      onClick: () => setShowDeleteConversationModal(true),
      destructive: true,
    },
  ].filter(Boolean) as ActionProps[];

  return (
    <>
      <Menu
        activator={
          <Button
            type="tertiary"
            variation="subtle"
            icon={"more"}
            ariaLabel="Conversation actions"
          />
        }
        items={[
          {
            actions: actions,
          },
        ]}
      />
      <DeleteConversationModal
        isOpen={showDeleteConversationModal}
        deleteConversation={handleDeleteConversation}
        cancel={() => setShowDeleteConversationModal(false)}
      />
    </>
  );
}

const SplitWrappedComponent = withSplitClient(ConversationActionsButton);
export { SplitWrappedComponent as ConversationActionsButton };
