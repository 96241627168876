import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import type { ProductOrServiceCustomFieldsQuery } from "~/utilities/API/graphql";
import { PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY } from "./LineItemCustomFieldChips.graphql";

export async function iWaitForLineItemCustomFieldsToFinishLoading({
  productOrServiceId,
}: {
  productOrServiceId: string;
}) {
  expect(
    await screen.findByTestId(
      `line-item-custom-field-chips-${productOrServiceId}`,
    ),
  ).toBeInTheDocument();
}

export async function iClickOnACustomFieldChip(label: string) {
  userEvent.click(await screen.findByRole("button", { name: label }));
}

export function getProductOrServiceCustomFieldsQueryMock(
  options: {
    customFields?: ProductOrServiceCustomFieldsQuery["productOrService"]["customFields"];
    productOrServiceId?: string;
    fileAttachment?: ProductOrServiceCustomFieldsQuery["productOrService"]["fileAttachment"];
  } = {},
) {
  const defaultProductOrServiceId = "MQ==";
  const productOrServiceId =
    options.productOrServiceId ?? defaultProductOrServiceId;
  return {
    request: {
      query: PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY,
      variables: { productOrServiceId },
    },
    result: {
      data: {
        productOrService: {
          id: productOrServiceId,
          name: "Product",
          fileAttachment: options.fileAttachment ?? null,
          customFields: options.customFields ?? [],
        },
      },
    },
  };
}
