import { type KeyboardEvent, type RefObject, useRef } from "react";

export function reverseTabbing() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const chatBubbleRef = useRef() as RefObject<HTMLDivElement>;
  function handleReverseTabbing(event: KeyboardEvent) {
    if (
      document.activeElement === event.currentTarget ||
      !chatBubbleRef.current
    ) {
      return;
    }

    if (event.key === "Tab") {
      const target = getNextElement(
        event,
        chatBubbleRef.current,
        event.currentTarget as HTMLDivElement,
      );

      if (target instanceof HTMLElement) {
        event.preventDefault();
        target.focus();
      }
    }
  }

  return { chatBubbleRef, handleReverseTabbing };
}

function getNextElement(
  event: KeyboardEvent,
  element: HTMLDivElement,
  parent: HTMLDivElement,
) {
  const { newestChatBubble, oldestChatBubble, chatInput } =
    getElements(element);

  if (event.shiftKey) {
    if (document.activeElement === oldestChatBubble) {
      return parent;
    } else if (chatInput) {
      return newestChatBubble;
    } else {
      return document.activeElement?.nextElementSibling;
    }
  } else {
    if (document.activeElement === newestChatBubble) {
      return parent.querySelector("textarea");
    } else if (!chatInput) {
      return document.activeElement?.previousElementSibling;
    }
  }
}

function getElements(element: HTMLDivElement) {
  const newestChatBubble = element.firstElementChild;
  const oldestChatBubble = element.lastElementChild;
  const chatInput = document.activeElement instanceof HTMLTextAreaElement;

  return {
    newestChatBubble,
    oldestChatBubble,
    chatInput,
  };
}
