import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components/Banner";
import { Markdown } from "@jobber/components/Markdown";
import { messages } from "./messages";
import style from "./QuickbooksLegacyMigrationModal.module.css";
import { QuickbooksLegacyMigrationFeedbackModal } from "../QuickbooksLegacyMigrationFeedbackModal";

interface QuickbooksLegacyMigrationModalProps {
  open: boolean;
  onClose: () => void;
  handleContinue: () => void;
  migrationLoading: boolean;
  error: boolean;
}
export function QuickbooksLegacyMigrationModal({
  open,
  onClose,
  handleContinue,
  migrationLoading,
  error,
}: QuickbooksLegacyMigrationModalProps) {
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  return (
    <>
      <QuickbooksLegacyMigrationFeedbackModal
        open={feedbackOpen}
        onRequestClose={() => setFeedbackOpen(false)}
      />
      <Modal
        title={messages.legacyMigration.modalTitle}
        open={open}
        onRequestClose={handleClose}
      >
        <Content spacing="base">
          {error && (
            <Banner dismissible={false} type="error">
              {messages.legacyMigration.migrationError}
            </Banner>
          )}
          <div className={style.firstHeader}>
            <Heading level={4}>
              {messages.legacyMigration.modalFirstHeader}
            </Heading>
          </div>
          <Text>{messages.legacyMigration.modalFirstDescription}</Text>
          <Heading level={4}>
            {messages.legacyMigration.modalSecondHeader}
          </Heading>
          <Markdown content={messages.legacyMigration.modalSecondDescription} />
          <div className={style.bulletPoints}>
            <li>{messages.legacyMigration.point1}</li>
            <li>{messages.legacyMigration.point2}</li>
          </div>
          <Markdown
            content={messages.legacyMigration.upgradeIrreversibleNotice}
          />
        </Content>
        <div className={style.outerButtonContainer}>
          <div className={style.leftButtonContainer}>
            <Button
              label={messages.legacyMigration.cancelButton}
              type="primary"
              variation="subtle"
              onClick={handleClose}
              disabled={migrationLoading}
            />
          </div>
          <div className={style.rightButtonContainer}>
            <div className={style.rightButtonContainerChild}>
              <Button
                label={messages.legacyMigration.learnMoreButton}
                type="secondary"
                onClick={() => {
                  window.open(
                    "https://help.getjobber.com/hc/en-us/articles/15868227566487",
                    "_blank",
                  );
                }}
              />
            </div>
            <Button
              label={messages.legacyMigration.connectButton}
              type="primary"
              loading={migrationLoading}
              onClick={handleContinue}
            />
          </div>
        </div>
      </Modal>
    </>
  );

  function handleClose() {
    onClose();
    setFeedbackOpen(true);
  }
}
