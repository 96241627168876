import clipboardCopy from "clipboard-copy";
import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Button } from "@jobber/components/Button";
import { ColorPicker } from "components/ColorPicker";

interface EmbeddedSnippetFormProps {
  color: string;
  buttonText: string;
  textColor: string;
  embedInlineScript: string;
  isEmbeddedDialog?: boolean;
}

interface CopyCodeProps {
  copy: string;
}

function CopyCode(props: CopyCodeProps) {
  const copyProps = {
    label: "Copy Code",
  };

  const copiedProps = {
    icon: "checkmark",
    label: "Copied",
  };

  const [buttonProps, setButtonProps] = useState(copyProps);
  const [timeoutIdentifier, setTimeoutIdentifier] = useState<number>();

  const copyURL = async () => {
    setButtonProps(copiedProps);
    window.clearTimeout(timeoutIdentifier);

    await clipboardCopy(props.copy);

    setTimeoutIdentifier(
      window.setTimeout(() => {
        setButtonProps(copyProps);
      }, 2000),
    );
  };

  return <Button {...buttonProps} onClick={copyURL} type="secondary" />;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function EmbeddedSnippetForm({
  color,
  buttonText,
  textColor,
  embedInlineScript,
  isEmbeddedDialog,
}: EmbeddedSnippetFormProps) {
  const [buttonColor, setColor] = useState(color);
  const [buttonTextColor, setColorOfText] = useState(textColor);
  const [newButtonText, setButtonText] = useState(buttonText);

  const handleColorChange = (newValue: string) => {
    setColor(newValue);
  };

  const handleColorOfTextChange = (newValue: string) => {
    setColorOfText(newValue);
  };

  const handleTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setButtonText(evt.currentTarget.value);
  };

  let buttonField;
  let buttonColorAttribute;
  let buttonTextColorAttribute;

  if (isEmbeddedDialog) {
    buttonField = (
      <div className="row">
        <div className="columns">
          <placeholder-field class="" label={"Button text"} auto-size={false}>
            <input
              type="text"
              maxLength={30}
              value={newButtonText}
              onChange={handleTextChange}
              name="work_request_settings[dialog_button_text]"
            />
          </placeholder-field>
        </div>
      </div>
    );
    buttonColorAttribute = "work_request_settings[dialog_button_color]";
    buttonTextColorAttribute =
      "work_request_settings[dialog_button_text_color]";
  } else {
    buttonColorAttribute = "work_request_settings[inline_button_color]";
    buttonTextColorAttribute =
      "work_request_settings[inline_button_text_color]";
  }

  return (
    <>
      <div className="row row--equalHeightColumns u-marginBottomSmall">
        <div className="small-12 large-8 columns">
          <div className="flexContent">
            <h5>Customize button appearance</h5>
            <ColorPicker
              value={buttonColor}
              placeholder={"Button color"}
              dataList={[
                "#7db00e",
                "#bbc520",
                "#ddc30f",
                "#f39624",
                "#b36096",
                "#8870c4",
                "#4b6a96",
                "#3ca2e0",
                "#3ead92",
              ]}
              inputNameAttribute={buttonColorAttribute}
              onChange={handleColorChange}
            />
            <ColorPicker
              value={buttonTextColor}
              placeholder={"Text color"}
              dataList={["#ffffff", "#000000"]}
              onChange={handleColorOfTextChange}
              inputNameAttribute={buttonTextColorAttribute}
            />
            {buttonField}
          </div>
        </div>
        <div className="small-12 large-4 columns">
          <div className="card u-bgColorWhite u-borderNone u-marginBottomSmall u-textCenter">
            <h5 className="u-marginBottomSmall">Button preview</h5>
            <button
              type="button"
              className="button"
              style={{ backgroundColor: buttonColor, color: buttonTextColor }}
            >
              {newButtonText}
            </button>
          </div>
        </div>
      </div>
      <div className="row collapse u-borderTop u-paddingTopSmall">
        <div className="columns">
          <p>Copy and paste the following code on your website:</p>
        </div>
      </div>
      <div className="row row--tightColumns">
        <div className="expand columns">
          <InputText
            value={embedInlineScript
              .replace(color, buttonColor)
              .replace(buttonText, newButtonText)
              .replace(textColor, buttonTextColor)}
            readonly={true}
            size="small"
          />
        </div>
        <div className="shrink columns u-marginBottomSmall">
          <CopyCode
            copy={embedInlineScript
              .replace(color, buttonColor)
              .replace(buttonText, newButtonText)
              .replace(textColor, buttonTextColor)}
          />
        </div>
      </div>
    </>
  );
}
