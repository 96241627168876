import React, { type Ref, forwardRef, useEffect } from "react";
import { debounce } from "lodash";
import { Icon } from "@jobber/components/Icon";
import { InputText, type InputTextRef } from "@jobber/components/InputText";
import styles from "./SearchBar.module.css";

export const SearchBar = forwardRef(SearchBarInternal);

export interface SearchBarProps {
  /**
   * Placeholder text
   */
  readonly placeholder: string;

  /**
   * The value input to the component
   */
  readonly value: string;

  /**
   * Prop to hide or display a magnifying glass icon
   */
  readonly showSearchIcon?: boolean;

  /**
   * A time delay (in ms) for the search callback
   */
  readonly delay?: number;

  /**
   * Handle the update of the component
   */
  onChange(newValue: string): void;

  /**
   * Callback function to handle the API call to the search function passed from parent.
   */
  searchCallback(searchValue: string): void;
}

export function SearchBarInternal(
  {
    placeholder,
    value,
    showSearchIcon = true,
    delay = 300,
    onChange,
    searchCallback,
    ...inputTextProps
  }: SearchBarProps,
  ref: Ref<InputTextRef>,
) {
  const delayedSearchCallback = debounce(searchCallback, delay);

  useEffect(() => {
    delayedSearchCallback(value);
    return delayedSearchCallback.cancel;
  }, [value, delayedSearchCallback]);

  return (
    <div className={styles.container}>
      <div
        className={
          showSearchIcon
            ? styles.inputTextContainer
            : styles.noIconTextContainer
        }
      >
        <InputText
          {...inputTextProps}
          placeholder={value ? "" : placeholder}
          onChange={onChange}
          ref={ref}
          value={value}
        />
      </div>
      {showSearchIcon && (
        <div
          className={
            showSearchIcon
              ? styles.searchIconContainer
              : styles.noIconTextContainer
          }
        >
          <Icon name={"search"} color={"greyBlue"} />
        </div>
      )}
      <ClearButton hideButton={!value} onChange={onChange} />
    </div>
  );
}

interface ClearButtonProps {
  readonly hideButton: boolean;
  onChange(newValue: string): void;
}

function ClearButton({ hideButton, onChange }: ClearButtonProps) {
  const clearInput = () => onChange("");
  if (hideButton) {
    return <></>;
  }

  return (
    <button className={styles.dismissContainer} onClick={clearInput}>
      <Icon name={"cross"} color={"greyBlueDark"} size={"small"} />
    </button>
  );
}
