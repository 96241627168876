import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  InvoiceDetailDataQuery,
  InvoiceDetailDataQueryVariables,
} from "~/utilities/API/graphqlFranchiseManagement";
import { INVOICE_DETAIL_QUERY } from "../InvoiceDetailReport.graphql";

interface ReturnedInvoiceDetailReportData {
  data?: InvoiceDetailDataQuery;
  loading: boolean;
  error?: ApolloError;
  fetchInvoiceDetail(args: InvoiceDetailDataQueryVariables): void;
}

export function useInvoiceDetailReportData(): ReturnedInvoiceDetailReportData {
  const [fetchInvoiceDetail, { data, loading, error }] = useLazyQuery<
    InvoiceDetailDataQuery,
    InvoiceDetailDataQueryVariables
  >(INVOICE_DETAIL_QUERY);

  const _fetchInvoiceDetail = ({
    sort,
    filter,
    after,
    first,
  }: InvoiceDetailDataQueryVariables) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInvoiceDetail({
      variables: {
        sort,
        filter,
        after,
        first,
      },
    });
  };

  return {
    fetchInvoiceDetail: _fetchInvoiceDetail,
    data,
    loading,
    error,
  };
}
