import { createContext, useContext } from "react";

export interface CountryCodesToNameMapContextType {
  countryCodesToNameMap?: Record<string, string>;
}

export const CountryCodesToNameMapContext =
  createContext<CountryCodesToNameMapContextType>({});
CountryCodesToNameMapContext.displayName = "CountryCodesToNameMapContext";

export function useCountryCodesToNameMap(): Record<string, string> {
  const { countryCodesToNameMap } = useContext(CountryCodesToNameMapContext);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return ensureExists(countryCodesToNameMap);
  }

  return countryCodesToNameMap as Record<string, string>;
}

function ensureExists(
  countryCodesToNameMap: Record<string, string> | undefined,
) {
  if (!countryCodesToNameMap) {
    throw new Error(
      `'countryCodesToNameMap' is not initialized.
      Ensure CountryCodesToNameMapContext.Provider or RailsPropsAsContexts exists in the parent tree and 'countryCodesToNameMap' is defined;
      `,
    );
  }

  return countryCodesToNameMap;
}
