import React from "react";
import { useLazyQuery } from "@apollo/client";
import { SmsComposerPage } from "jobber/chat/components/SmsComposerPage/SmsComposerPage";
import { CLIENT_PHONES_QUERY } from "jobber/chat/components/SmsComposerPage/SmsComposerPage.graphql";
import type {
  ClientPhonesQuery,
  ClientPhonesQueryVariables,
} from "~/utilities/API/graphql";

export function SmsComposerPageLoader() {
  const queryTuple = useLazyQuery<
    ClientPhonesQuery,
    ClientPhonesQueryVariables
  >(CLIENT_PHONES_QUERY);
  return <SmsComposerPage fetchRecipients={queryTuple} />;
}
