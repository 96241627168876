import { gql } from "@apollo/client";

export const ONLINE_BOOKING_SERVICE_AREA_FRAGMENT = gql`
  fragment OnlineBookingServiceArea on RegionInterface {
    __typename
    id
    name
    ... on GeoCircle {
      radius
      center {
        latitude
        longitude
      }
    }
  }
`;

export const ONLINE_BOOKING_DEFAULT_SERVICE_AREA_FRAGMENT = gql`
  fragment OnlineBookingDefaultServiceArea on Account {
    id
    companyDetails {
      address
      coordinates {
        latitude
        longitude
      }
    }
    settings {
      localization {
        countryCode
      }
    }
  }
`;

export const CREATE_SERVICE_AREA = gql`
  mutation CreateServiceArea($name: String, $shape: RegionShapeAttributes!) {
    onlineBookingServiceAreaCreate(input: { name: $name, shape: $shape }) {
      serviceArea {
        ...OnlineBookingServiceArea
      }
      userErrors {
        message
        path
      }
    }
    onlineBookingConfigurationEdit(input: { serviceAreasEnabled: true }) {
      onlineBookingConfiguration {
        id
        serviceAreasEnabled
      }
    }
  }

  ${ONLINE_BOOKING_SERVICE_AREA_FRAGMENT}
`;

export const EDIT_SERVICE_AREA = gql`
  mutation EditServiceArea(
    $serviceAreaId: EncodedId!
    $name: String
    $shape: RegionShapeAttributes
  ) {
    onlineBookingServiceAreaEdit(
      serviceAreaId: $serviceAreaId
      input: { name: $name, shape: $shape }
    ) {
      serviceArea {
        ...OnlineBookingServiceArea
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ONLINE_BOOKING_SERVICE_AREA_FRAGMENT}
`;
