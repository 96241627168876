import React, { useRef } from "react";
import { Heading } from "@jobber/components/Heading";
import classNames from "classnames";
import { Divider } from "@jobber/components/Divider";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { Translations } from "~/utilities/contexts/internal/TranslationContext";
import { formatCurrency } from "utilities/formatCurrency";
import { JobProfitStatusLabel } from "jobber/workOrders/components/JobCost/components/JobProfitStatusLabel";
import { JobCostHeaderPopover } from "jobber/workOrders/components/JobCost/components/JobCostHeaderPopover";
import type { BillingStrategy } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";
import styles from "./ProfitMarginBreakdown.module.css";
import { messages } from "./messages";
import { RecurringJobTooltip } from "../RecurringJobTooltip";
import type { JobCostRecord } from "../../JobCostHeader";

interface ProfitMarginBreakdownProps {
  titleKey: keyof Translations;
  amount: number | "—";
  followingSeparator?: "hyphen" | "equals";
  highlightNegativeValues: boolean;
  displayPopoverHelp?: boolean;
  isBlurred: boolean;
  showLabelStatus?: boolean;
  isRecurring?: boolean;
  dateRange?: string;
  billingType?: BillingStrategy;
  jobCosting?: JobCostRecord;
  lineItems?: LineItem[];
}

export function ProfitMarginBreakdown({
  showLabelStatus = true,
  isRecurring = false,
  ...props
}: ProfitMarginBreakdownProps) {
  const { currencySymbol } = useAccount();
  const { formatMessage } = useIntl();

  const currencyAmount =
    typeof props.amount === "number"
      ? formatCurrency(props.amount, currencySymbol)
      : "—";

  const valueTextHeading = classNames([
    styles.valueTextHeading,
    typeof props.amount === "number" &&
      props.amount < 0 &&
      styles.netLossAmount,
  ]);

  const jobCostProfitBarRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {/* For small screens only */}
      {props.titleKey === "profitBarProfit" && (
        <div className={styles.divider}>
          <Divider size="larger"></Divider>
        </div>
      )}
      <div
        ref={jobCostProfitBarRef}
        className={classNames([
          styles.profitMarginBreakdownContainer,
          props.isBlurred && styles.blurredText,
        ])}
        aria-hidden={props.isBlurred}
      >
        <div className={styles.titleWithLabel}>
          {showLabelStatus && props.titleKey !== "profitBarPrice" && (
            <JobProfitStatusLabel
              status={props.titleKey.split("profitBar")?.[1]}
            />
          )}
          <h5 className={styles.profitBreakdownTitle}>
            {formatMessage(messages[props.titleKey as keyof typeof messages])}
          </h5>
          {isRecurring && props.billingType && (
            <RecurringJobTooltip
              tooltipData={{
                id: props.titleKey,
                data: { amount: props.amount },
              }}
              titleKey={props.titleKey}
              dateRange={props.dateRange}
              billingType={props.billingType}
              jobCosting={props.jobCosting}
              lineItems={props.lineItems || []}
            />
          )}
        </div>
        <h4 className={valueTextHeading}>
          {/* For small screens only */}
          <span>-</span>
          {currencyAmount}
        </h4>
        {!!props.followingSeparator && (
          <div className={styles.separator}>
            <Heading level={4}>
              {props.followingSeparator === "equals" ? "=" : "-"}
            </Heading>
          </div>
        )}
      </div>
      {props.displayPopoverHelp && (
        <JobCostHeaderPopover elementRef={jobCostProfitBarRef} />
      )}
    </>
  );
}
