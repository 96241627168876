import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cardReadersTitle: {
    id: "managedAccount.cardReaders.title",
    defaultMessage: "Card Reader",
    description: "Title for the card readers component",
  },
  heading: {
    id: "managedAccount.cardReaders.heading",
    defaultMessage: "Get paid in person with a Jobber Card Reader",
    description: "Heading for the card readers component",
  },
  transactionText: {
    id: "managedAccount.cardReaders.transactionText",
    defaultMessage: " / transaction",
    description: "Transaction text for the card readers component",
  },
  description: {
    id: "managedAccount.cardReaders.description",
    defaultMessage:
      "Save on fees, look professional, and stay organized when you accept in-person debit and credit card payments by swipe, chip, tap, or mobile wallets like Apple and Google Pay.",
    description: "Description for the card readers component",
  },
  loyaltyTierText: {
    id: "managedAccount.cardReaders.loyaltyTierText",
    defaultMessage:
      "American Express and International cards rate is 3.0% + 30¢ / transaction",
    description: "Loyalty rates available text for the card readers component",
  },
  termsOfService: {
    id: "managedAccount.cardReaders.termsOfService",
    defaultMessage:
      "By purchasing and using a card reader terminal, you agree to comply with Jobber's [Payment Terms of Service](https://getjobber.com/payment-terms-of-service/), including the Stripe Terminal Purchase Terms and Stripe Terminal Terms as set out in our Payment Terms of Service as they apply to your purchase and use of the card reader terminal.",
    description: "Terms of service link text for the card readers component",
  },
});
