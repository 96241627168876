/* eslint-disable import/no-internal-modules */
import type { EventInput } from "@fullcalendar/core";
import { useMemo } from "react";
import { parseISO } from "date-fns";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { getAccountZonedTime } from "jobber/workOrders/components/JobCost/utils";
import type { AvailabilityEvent } from "./useSchedulingAvailability";
import type {
  AppointmentEvent,
  SchedulingAssistantContext,
} from "../machines/schedulingAssistant.machine";
import { makeSelectedSlotEvent } from "../utils/makeSelectedSlotEvent";

export function useAssistantCalendarEvents({
  availability,
  appointments,
  selectedSlot,
  durationInSeconds,
}: {
  availability: AvailabilityEvent[];
  appointments: AppointmentEvent[];
  durationInSeconds: number;
  selectedSlot?: SchedulingAssistantContext["selectedTime"];
}) {
  const { timezone } = useAccount();

  return useMemo(() => {
    const events: EventInput[] = [...appointments, ...availability].map(
      event => {
        return {
          ...event,
          start: getAccountZonedTime(parseISO(event.start), timezone),
          end: getAccountZonedTime(parseISO(event.end), timezone),
          display: event.allDay ? "auto" : "background",
          editable: false,
          droppable: false,
        };
      },
    );

    if (selectedSlot) {
      events.push(
        makeSelectedSlotEvent({
          ...selectedSlot,
          durationInSeconds,
        }),
      );
    }

    return events;
  }, [appointments, availability, selectedSlot, timezone, durationInSeconds]);
}
