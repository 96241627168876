import { Button, Combobox } from "@jobber/components";
import cx from "classnames";
import React from "react";
import styles from "./RuleDropdown.module.css";

export interface RuleComboboxProps {
  defaultValue: string;
  value: string | number | boolean | undefined;
  setValue?: (val: string | number) => void;
  options: {
    label: string;
    value: string | number | boolean;
    icon?: string;
  }[];
  readOnly?: boolean;
  hasSubmitted?: boolean;
  subjectNoun: string;
}

function getButtonLabel(
  value: string | number | boolean | undefined,
  options: RuleComboboxProps["options"],
) {
  return options.find(option => option.value === value)?.label ?? "";
}

export function RuleCombobox({
  defaultValue,
  value,
  setValue,
  options,
  readOnly,
  hasSubmitted,
  subjectNoun,
}: RuleComboboxProps) {
  if (typeof value === "boolean") {
    throw new Error("RuleCombobox does not support boolean values");
  }

  if (readOnly || !setValue) {
    return <>{getButtonLabel(value, options)}</>;
  }

  const comboboxOptions = options.map(opt => {
    if (typeof opt.value === "boolean") {
      throw new Error("RuleCombobox does not support boolean values");
    }
    return <Combobox.Option key={opt.value} id={opt.value} label={opt.label} />;
  });

  const selectedLabel =
    options.find(option => option.value === value)?.label ?? "";
  const selected = {
    id: value as string | number,
    label: selectedLabel,
  };

  const optionColor = () => {
    if (value === "") {
      if (hasSubmitted) {
        return styles.errorButtonTextColor;
      }
      return styles.defaultButtonTextColor;
    }
    return "";
  };

  return (
    <div className={cx(styles.menuContainer, optionColor())}>
      <Combobox
        onSelect={([{ id }]) => {
          setValue(id);
        }}
        selected={[selected]}
        subjectNoun={subjectNoun}
      >
        <Combobox.Activator>
          <Button
            label={value === "" ? defaultValue : getButtonLabel(value, options)}
            type="tertiary"
            size="small"
          />
        </Combobox.Activator>
        {comboboxOptions}
      </Combobox>
    </div>
  );
}
