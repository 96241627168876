import React from "react";
import { Banner } from "@jobber/components/Banner";

interface MerchantStatus {
  readyToBook: boolean;
}

export function MerchantStatusBanner({ readyToBook }: MerchantStatus) {
  if (!readyToBook) {
    return (
      <Banner type="notice" dismissible={false}>
        Your integration setup is complete! It will take up to 72 hours for the
        booking feature to appear on your Local Services Ads. If you have
        ongoing issues, visit our{" "}
        <a
          href="https://help.getjobber.com/hc/en-us/articles/360062129213"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>{" "}
        for more information.
      </Banner>
    );
  } else {
    return <></>;
  }
}
