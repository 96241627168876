import { gql } from "@apollo/client";

const PRODUCT_OR_SERVICE_FRAGMENT = gql`
  fragment productOrServiceSave on ProductOrService {
    id
    name
  }
`;

export const CREATE_PRODUCT_OR_SERVICE = gql`
  mutation createProductOrService($product: ProductsAndServicesInput!) {
    productsAndServicesCreate(input: $product) {
      productOrService {
        ...productOrServiceSave
      }
      userErrors {
        message
        path
      }
    }
  }

  ${PRODUCT_OR_SERVICE_FRAGMENT}
`;

export const EDIT_PRODUCT_OR_SERVICE = gql`
  mutation productsAndServicesEdit(
    $id: EncodedId!
    $product: ProductsAndServicesEditInput!
  ) {
    productsAndServicesEdit(productOrServiceId: $id, input: $product) {
      productOrService {
        ...productOrServiceSave
      }
      userErrors {
        message
        path
      }
    }
  }
  ${PRODUCT_OR_SERVICE_FRAGMENT}
`;

export const DELETE_PRODUCTS_AND_SERVICES = gql`
  mutation productsAndServicesDelete($productsAndServicesIds: [EncodedId!]!) {
    productsAndServicesDelete(
      input: { productsAndServicesIds: $productsAndServicesIds }
    ) {
      deletedProductsAndServices {
        id
        name
      }
      userErrors {
        message
      }
    }
  }
`;
