import { type DateRange, DateRanges } from "~/shared/InputDateRange/types";
import type { InputDateRangeRef } from "~/shared/InputDateRange/InputDateRange";

export type DateRangeOptions = DateRanges.all | DateRanges.thirtyDays;

export const campaignsEmailDateOptions: DateRangeOptions[] = [
  DateRanges.all,
  DateRanges.thirtyDays,
];

export interface SortAndFilterState {
  setters: {
    setDateRange: (value: DateRange) => void;
    setPresetFilter: (value: DateRangeOptions) => void;
  };
  values: {
    dateRangeFilter: DateRange;
    presetFilter: DateRangeOptions;
  };
  refs: {
    inputDateRangeRef: React.MutableRefObject<InputDateRangeRef | undefined>;
  };
}
