import React from "react";
import { Button } from "@jobber/components/Button";
import classNames from "classnames";

interface EmptyStateProps {
  readonly title: string;
  readonly description: string;
  primaryActionLabel?: string;
  iconName?: string;
  handlePrimaryAction?(): void;
}

export function EmptyState({
  title,
  description,
  primaryActionLabel,
  iconName,
  handlePrimaryAction,
}: EmptyStateProps) {
  return (
    <div className={classNames("align-top u-colorGreyBlue")}>
      <div className="row">
        <div className="columns shrink">
          <div className="row u-paddingSmall">
            {iconName && (
              <sg-icon icon={iconName} class="icon--circle u-colorGreyBlue" />
            )}
          </div>
        </div>

        <div className="columns u-paddingLeftSmall">
          <div className="row align-middle u-paddingTopSmall u-paddingSmallest">
            <span className="u-textBold u-textLarge">{title}</span>
          </div>
          <div className="row align-middle u-paddingLeftSmallest u-paddingRightSmaller">
            <p className="u-marginBottomNone">{description}</p>
          </div>
          {primaryActionLabel && (
            <div className="row align-middle u-paddingLeftqSmallest u-paddingTopSmaller">
              <Button
                label={primaryActionLabel}
                type="primary"
                onClick={handlePrimaryAction}
                external={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
