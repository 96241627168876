/* eslint-disable @typescript-eslint/naming-convention */
type IndustryTopFeatures = Record<string, string[]>;

export const industryTopFeatures: IndustryTopFeatures = {
  "Appliance Repair": ["Dishwasher Repair", "Refrigerator Repair", "+4 others"],
  "Arborist / Tree Care": ["Tree Pruning", "Stump Removal", "+6 others"],
  "Carpet Cleaning": ["Carpet Cleaning", "Area Rug Cleaning", "+4 others"],
  "Commercial Cleaning": ["Office Cleaning", "Event Cleanup", "+8 others"],
  "Computers & IT": ["Computer Maintenance", "Backup Data", "+5 others"],
  "Construction & Contracting": [],
  "Electrical Contractor": [
    "Lighting Design",
    "Lighting Installation",
    "+7 others",
  ],
  "Flooring Service": [
    "Carpet Repairs",
    "Hardwood Floor Installation",
    "+13 others",
  ],
  Handyman: ["Carpentry", "Window Washing", "+14 others"],
  "Home Theater": [
    "Sound System Design and Installation",
    "Home Theatre",
    "+5 others",
  ],
  HVAC: ["Design and Installation", "Industrial Ventilation", "+7 others"],
  "Junk Removal": ["Junk Clean Out", "Furniture Removal", "+5 others"],
  "Landscaping Contractor": ["Prune Tree", "Landscape Design", "+9 others"],
  "Lawn Care & Lawn Maintenance": ["Aeration", "Fertilizing", "+15 others"],
  Locksmith: [],
  "Mechanical Service": [
    "High Pressure Flushing",
    "Air Filtration Servicing",
    "+5 others",
  ],
  Other: [],
  Painting: ["Baseboards", "Ceiling", "+9 others"],
  "Pest Control": ["Rodent Control", "Roaches", "+7 others"],
  Plumbing: ["Drain Cleaning", "Bathroom Install", "+5 others"],
  "Pool and Spa Service": [
    "Regular Maintenance",
    "Drain and Refill",
    "+7 others",
  ],
  "Pressure Washing Service": ["Storefront", "Pool Deck", "+13 others"],
  Renovations: ["Bathroom Renovation", "Kitchen Renovation", "+8 others"],
  "Residential Cleaning": ["Bathrooms", "Kitchen", "+8 others"],
  "Roofing Service": ["Re-Roofing", "Repair", "+6 others"],
  "Security and Alarm": ["Installation", "Equipment Cost", "+3 others"],
  "Snow Removal": ["Driveway", "Sidewalk and Entrance", "+3 others"],
  "Window Washing": ["High-Level Windows", "Pressure Washing", "+6 others"],
};

export const industryDisplayText: Record<string, string> = {
  HVAC: "HVAC",
  "Computers & IT": "computers & IT",
  Other: "",
};
