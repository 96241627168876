import type { ExpensesDataEntry } from "jobber/workOrders/components/JobCost/components/ExpensesTable/types";
import type { JobCostExpenseFragment } from "~/utilities/API/graphql";
import { getAccountZonedTime } from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";

export function processExpensesEntries(
  expensesEntries: JobCostExpenseFragment[],
  accountTimezone: string,
): ExpensesDataEntry[] {
  return expensesEntries.map(expensesEntry => {
    const {
      id,
      title,
      accountingCode,
      reimbursableTo,
      description,
      receipt,
      date,
      total,
    } = expensesEntry;

    const timezoneDate = getAccountZonedTime(new Date(date), accountTimezone);
    const reimbursableUser = reimbursableTo
      ? {
          id: reimbursableTo.id,
          name: reimbursableTo.name.full,
        }
      : undefined;

    return {
      id: id,
      item: title,
      accountingCode,
      reimbursableTo: reimbursableUser,
      description,
      receipt,
      date: timezoneDate.toString(),
      amount: total || 0,
    };
  });
}
