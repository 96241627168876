import React from "react";
import { useIntl } from "react-intl";
import { Switch } from "@jobber/components/Switch";
import { Text } from "@jobber/components/Text";
import { messages } from "./messages";
import styles from "./AiReceptionist.module.css";

interface AiReceptionistToggleProps {
  toggle: boolean;
  handleValueChange: (value: boolean) => void;
}

export function AiReceptionistToggle({
  toggle,
  handleValueChange,
}: AiReceptionistToggleProps) {
  const { formatMessage } = useIntl();
  const toggleLabel = toggle
    ? formatMessage(messages.receptionistToggleEnabled)
    : formatMessage(messages.receptionistToggleDisabled);

  return (
    <div
      data-testid="aiReceptionistToggle"
      className={styles.receptionistToggleFullWidth}
    >
      <Switch
        ariaLabel={"AI Receptionist Toggle"}
        value={toggle}
        onChange={handleValueChange}
      />
      <span className={styles.receptionistToggleLabel}>
        <Text variation={"subdued"}>{toggleLabel}</Text>
      </span>
    </div>
  );
}
