import React from "react";
import type { IntlFormatters } from "react-intl";
import CalendarViews from "@images/CalendarViews.png";
import CalendarTeamMembers from "@images/CalendarTeamMembers.png";
import { FeatureLandingPage } from "jobber/landingPages/FeatureLandingPage";
import RequestForms from "@images/RequestForms.png";
import JobsChecklistWithBackground from "@images/JobsChecklistWithBackground.png";
import { CoreTrialWrapper } from "jobber/landingPages/components/CoreTrialWrapper/CoreTrialWrapper";
import { UpgradeFeature } from "~/utilities/API/graphql";
import { messages } from "./messages";

export interface FeatureTrialEligibility {
  accountFeatureTrialEligibility: {
    eligibleForLiteToCoreTrial: boolean | null;
  };
}

function renderScheduleLandingPage(
  trialAvailable: boolean,
  showStartTrialModal: () => void,
  formatMessage: IntlFormatters["formatMessage"],
) {
  const learnAboutFeaturesSection = {
    mainHeading: formatMessage(messages.featuresSectionHeading, {
      plan: "Core",
    }),
    firstImage: RequestForms,
    secondImage: JobsChecklistWithBackground,
    firstImageAlt: formatMessage(messages.requestFormsFeatureImageAlt),
    secondImageAlt: formatMessage(messages.jobsFeatureImageAlt),
    featuresAndDescriptions: [
      {
        heading: formatMessage(messages.requestFormsFeatureHeading),
        description: formatMessage(messages.requestFormsFeatureDescription),
        link: "/work_requests",
      },
      {
        heading: formatMessage(messages.jobsFeatureHeading),
        description: formatMessage(messages.jobsFeatureDescription),
        link: "/work_orders",
      },
    ],
  };
  return (
    <FeatureLandingPage
      feature={UpgradeFeature.SCHEDULE}
      featurePlan="Core"
      mainHeading={formatMessage(messages.mainHeading)}
      subHeading={formatMessage(messages.subHeading)}
      firstImage={CalendarViews}
      secondImage={CalendarTeamMembers}
      firstImageAlt={formatMessage(messages.firstImageAlt)}
      secondImageAlt={formatMessage(messages.secondImageAlt)}
      featuresAndDescriptions={[
        {
          heading: formatMessage(messages.calendarSubFeatureHeading),
          description: formatMessage(messages.calendarSubFeatureDescription),
        },
        {
          heading: formatMessage(messages.organizeSubFeatureHeading),
          description: formatMessage(messages.organizeSubFeatureDescription),
        },
        {
          heading: formatMessage(messages.commuteTimeSubFeatureHeading),
          description: formatMessage(messages.commuteTimeSubFeatureDescription),
        },
      ]}
      learnAboutFeaturesSection={learnAboutFeaturesSection}
      trialAvailable={trialAvailable}
      onStartTrial={showStartTrialModal}
    />
  );
}

function ScheduleLandingPageWrapper() {
  return (
    <CoreTrialWrapper
      renderCoreFeatureLandingPage={renderScheduleLandingPage}
      source="schedule_landing_page"
    />
  );
}

export { ScheduleLandingPageWrapper as ScheduleLandingPage };
