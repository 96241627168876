import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import type { InputTextRef } from "@jobber/components/InputText";
import { InputSearch } from "components/InputSearch";
import styles from "./RecipientSearch.module.css";

interface RecipientSearchProps {
  onSearchTermChange(searchTerm: string): void;
  searchTermOverride?: string;
}

export function RecipientSearch({
  onSearchTermChange,
  searchTermOverride,
}: RecipientSearchProps) {
  const [searchValue, setSearchValue] = useState(searchTermOverride ?? "");
  const label = "To:";

  const inputRef = useRef<InputTextRef>() as MutableRefObject<InputTextRef>;

  useEffect(() => {
    if (!searchTermOverride) {
      inputRef.current.focus();
    }
  }, []);

  function setSearchValueWithTracking(searchInput: string): void {
    setSearchValue(searchInput);
  }

  function handleOnDebouncedChange(searchInput: string) {
    onSearchTermChange(searchInput);
  }

  return (
    <div className={styles.recipientSearchContainer}>
      <span className={styles.toLabelContainer}>{label}</span>
      <InputSearch
        placeholder={"Search or enter a phone number"}
        value={searchTermOverride ?? searchValue}
        onChange={setSearchValueWithTracking}
        onDebouncedChange={handleOnDebouncedChange}
        showIcon={false}
        ref={inputRef}
        showClearButton={false}
      />
    </div>
  );
}
