import { LineItemTotalPrice } from "./components/IndividualInputs/LineItemTotalPrice";
import { LineItemUnitPrice } from "./components/IndividualInputs/LineItemPrice";
import { LineItemUnitCost } from "./components/IndividualInputs/LineItemCost";
import { LineItemQuantity } from "./components/IndividualInputs/LineItemQuantity";
import {
  LineItemActionContainer,
  LineItemContainer,
  LineItemInputContainer,
  LineItemInputRow,
} from "./components/StylingComponents/LineItemInputContainer";
import { LineItemDeleteButton } from "./components/IndividualInputs/LineItemDeleteButton";
import { LineItemSaveButton } from "./components/IndividualInputs/LineItemSaveButton";
import { LineItemDescription } from "./components/IndividualInputs/LineItemDescription";
import { LineItemName } from "./components/LineItemName/LineItemName";

export const LineItem = {
  Name: LineItemName,
  Quantity: LineItemQuantity,
  UnitCost: LineItemUnitCost,
  UnitPrice: LineItemUnitPrice,
  TotalPrice: LineItemTotalPrice,
  Description: LineItemDescription,
  DeleteButton: LineItemDeleteButton,
  SaveButton: LineItemSaveButton,
  Container: LineItemContainer,
  InputContainer: LineItemInputContainer,
  ActionContainer: LineItemActionContainer,
  InputRow: LineItemInputRow,
};
