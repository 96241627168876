import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { DescriptionList } from "@jobber/components/DescriptionList";
import { Icon } from "@jobber/components/Icon";
import classnames from "classnames";
import React from "react";
import {
  type DisputeType,
  getDisputeDetailsListData,
} from "jobber/features/PaymentDisputes/utils/disputeUtils";
import styles from "./DisputeDetailsList.module.css";
import { HELP_CENTER_URL } from "../../constants";

interface DisputeDetailsProps {
  dispute: NonNullable<DisputeType>;
  onViewClaimDetailsClick: () => void;
}
export function DisputeDetailsList({
  dispute,
  onViewClaimDetailsClick,
}: DisputeDetailsProps) {
  return (
    <>
      <Card header="Details">
        <Content>
          <DescriptionList
            data={getDisputeDetailsListData(dispute, onViewClaimDetailsClick)}
          />
        </Content>
      </Card>
      <div className={classnames(styles.infoContainer)}>
        <Icon name="help" size="large" />
        <div className={classnames(styles.infoTextContainer)}>
          Learn about chargebacks, inquiries, and how to respond to them in the{" "}
          <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
            help center
          </a>
          .
        </div>
      </div>
    </>
  );
}
