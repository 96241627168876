import { useMutation } from "@apollo/client";
import {
  type CustomFieldConfigurationCreateAreaMutation as CreateAreaMutation,
  type CustomFieldConfigurationCreateAreaMutationVariables as CreateAreaMutationVariables,
  type CustomFieldConfigurationCreateDropdownMutation as CreateDropdownMutation,
  type CustomFieldConfigurationCreateDropdownMutationVariables as CreateDropdownMutationVariables,
  type CustomFieldConfigurationCreateNumericMutation as CreateNumericMutation,
  type CustomFieldConfigurationCreateNumericMutationVariables as CreateNumericMutationVariables,
  type CustomFieldConfigurationCreateTextMutation as CreateTextMutation,
  type CustomFieldConfigurationCreateTextMutationVariables as CreateTextMutationVariables,
  type CustomFieldConfigurationCreateTrueFalseMutation as CreateTrueFalseMutation,
  type CustomFieldConfigurationCreateTrueFalseMutationVariables as CreateTrueFalseMutationVariables,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
  type CustomFieldConfigurationEditMutation as EditMutation,
  type CustomFieldConfigurationEditMutationVariables as EditMutationVariables,
  type MutationErrors,
} from "~/utilities/API/graphql";
import { CUSTOM_FIELD_LIST } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";
import {
  AREA_CREATE,
  CUSTOM_FIELD_EDIT,
  DROPDOWN_CREATE,
  NUMERIC_CREATE,
  TEXT_CREATE,
  TRUE_FALSE_CREATE,
} from "../graphql";
import type { CustomFieldConfigurationReducerState } from "../types";
import {
  updateCacheArea,
  updateCacheDropdown,
  updateCacheNumeric,
  updateCacheText,
  updateCacheTrueFalse,
} from "../../CustomFieldCacheUtils";

export function useCustomFieldConfiguration(
  onSuccess: (configuration: CustomFieldConfigurationNodeFragment) => void,
  onError: (message: string) => void,
): {
  handleCreateOrEditConfiguration: (
    configuration: CustomFieldConfigurationReducerState,
  ) => void;
  loading: boolean;
} {
  const [createText, { loading: createTextLoading }] = useMutation<
    CreateTextMutation,
    CreateTextMutationVariables
  >(TEXT_CREATE);

  const [editConfiguration, { loading: editConfigurationLoading }] =
    useMutation<EditMutation, EditMutationVariables>(CUSTOM_FIELD_EDIT);

  const [createTrueFalse, { loading: createTrueFalseLoading }] = useMutation<
    CreateTrueFalseMutation,
    CreateTrueFalseMutationVariables
  >(TRUE_FALSE_CREATE);

  const [createNumeric, { loading: createNumericLoading }] = useMutation<
    CreateNumericMutation,
    CreateNumericMutationVariables
  >(NUMERIC_CREATE);

  const [createArea, { loading: createAreaLoading }] = useMutation<
    CreateAreaMutation,
    CreateAreaMutationVariables
  >(AREA_CREATE);

  const [createDropdown, { loading: createDropdownLoading }] = useMutation<
    CreateDropdownMutation,
    CreateDropdownMutationVariables
  >(DROPDOWN_CREATE);

  function mutationAndKey(
    configuration: CustomFieldConfigurationReducerState,
  ): {
    responseKey: string;
    payload: object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: (variables: object) => any;
  } {
    const baseCreateInputPayload = {
      name: configuration.name,
      appliesTo: configuration.appliesTo,
      transferable: configuration.transferable,
      readOnly: configuration.readOnly,
    };

    const basePayload = {
      refetchQueries: configuration.transferable ? [CUSTOM_FIELD_LIST] : [],
    };

    if (configuration.id) {
      return {
        responseKey: "customFieldConfigurationEdit",
        payload: {
          ...basePayload,
          variables: {
            customFieldConfigurationId:
              encodedGlobalIdFromCustomFieldConfiguration(configuration),
            input: {
              name: configuration.name,
              ...defaultValueInputFromFieldType(configuration),
            },
          },
        },
        mutation: editConfiguration,
      };
    }

    switch (configuration.fieldType) {
      case CustomFieldConfigurationValueType.TEXT:
        return {
          responseKey: "customFieldConfigurationCreateText",
          payload: {
            ...basePayload,
            variables: {
              input: {
                ...baseCreateInputPayload,
                defaultValue: configuration.defaultValue as string,
              },
            },
            update: updateCacheText,
          },
          mutation: createText,
        };
      case CustomFieldConfigurationValueType.TRUE_FALSE:
        return {
          responseKey: "customFieldConfigurationCreateTrueFalse",
          payload: {
            ...basePayload,
            variables: {
              input: {
                ...baseCreateInputPayload,
                defaultValue: (configuration.defaultValue ===
                  "true") as boolean,
              },
            },
            update: updateCacheTrueFalse,
          },
          mutation: createTrueFalse,
        };
      case CustomFieldConfigurationValueType.NUMERIC:
        return {
          responseKey: "customFieldConfigurationCreateNumeric",
          payload: {
            ...basePayload,
            variables: {
              input: {
                ...baseCreateInputPayload,
                defaultValue: Number(configuration.defaultValue),
                unit: configuration.unit,
              },
            },
            update: updateCacheNumeric,
          },
          mutation: createNumeric,
        };
      case CustomFieldConfigurationValueType.AREA:
        return {
          responseKey: "customFieldConfigurationCreateArea",
          payload: {
            ...basePayload,
            variables: {
              input: {
                ...baseCreateInputPayload,
                defaultValue: {
                  length: configuration.areaLength,
                  width: configuration.areaWidth,
                },
                unit: configuration.unit,
              },
            },
            update: updateCacheArea,
          },
          mutation: createArea,
        };
      case CustomFieldConfigurationValueType.DROPDOWN:
        return {
          responseKey: "customFieldConfigurationCreateDropdown",
          payload: {
            ...basePayload,
            variables: {
              input: {
                ...baseCreateInputPayload,
                defaultValue: configuration.defaultValue as string,
                dropdownOptions: [...configuration.dropdownOptions],
              },
            },
            update: updateCacheDropdown,
          },
          mutation: createDropdown,
        };
    }

    throw new Error(
      `Custom Field Configuration Type not handled: ${configuration.fieldType}`,
    );
  }

  async function handleCreateOrEditConfiguration(
    configuration: CustomFieldConfigurationReducerState,
  ) {
    try {
      const { responseKey, payload, mutation } = mutationAndKey(configuration);

      const result = await mutation(payload);

      const data = result?.data?.[responseKey];
      const errors = data?.userErrors as MutationErrors[];
      const createdOrEditedConfiguration = data?.customFieldConfiguration;

      if (errors && errors.length > 0) {
        onError(errors[0].message);
      } else {
        onSuccess(createdOrEditedConfiguration);
      }
    } catch (e) {
      onError((e as Error).toString());
    }
  }

  return {
    handleCreateOrEditConfiguration,
    loading:
      createTextLoading ||
      editConfigurationLoading ||
      createTrueFalseLoading ||
      createNumericLoading ||
      createAreaLoading ||
      createDropdownLoading,
  };
}

const FIELD_TYPE_TO_CUSTOM_FIELD_CONFIGURATION_TYPE = {
  [CustomFieldConfigurationValueType.TEXT]: "CustomFieldConfigurationText",
  [CustomFieldConfigurationValueType.NUMERIC]:
    "CustomFieldConfigurationNumeric",
  [CustomFieldConfigurationValueType.TRUE_FALSE]:
    "CustomFieldConfigurationTrueFalse",
  [CustomFieldConfigurationValueType.DROPDOWN]:
    "CustomFieldConfigurationDropdown",
  [CustomFieldConfigurationValueType.AREA]: "CustomFieldConfigurationArea",
  [CustomFieldConfigurationValueType.LINK]: "CustomFieldConfigurationLink",
};

function encodedGlobalIdFromCustomFieldConfiguration(
  customFieldConfiguration: CustomFieldConfigurationReducerState,
) {
  return btoa(
    `gid://Jobber/${
      FIELD_TYPE_TO_CUSTOM_FIELD_CONFIGURATION_TYPE[
        customFieldConfiguration.fieldType
      ]
    }/${atob(customFieldConfiguration.id as string)}`,
  );
}

function yesNoStringToBoolean(yesNoDefaultValue: string) {
  switch (yesNoDefaultValue) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return false;
  }
}

function defaultValueInputFromFieldType(
  configuration: CustomFieldConfigurationReducerState,
) {
  switch (configuration.fieldType) {
    case CustomFieldConfigurationValueType.TEXT:
      return {
        valueText: {
          defaultValue: configuration.defaultValue as string,
        },
      };
    case CustomFieldConfigurationValueType.TRUE_FALSE:
      return {
        valueTrueFalse: {
          defaultValue: yesNoStringToBoolean(
            configuration.defaultValue as string,
          ),
        },
      };
    case CustomFieldConfigurationValueType.NUMERIC:
      return {
        valueNumeric: {
          defaultValue: configuration.defaultValue as number,
          unit: configuration.unit,
        },
      };
    case CustomFieldConfigurationValueType.AREA:
      return {
        valueArea: {
          defaultValue: {
            length: configuration.areaLength,
            width: configuration.areaWidth,
          },
          unit: configuration.unit,
        },
      };
    case CustomFieldConfigurationValueType.DROPDOWN:
      return {
        valueDropdown: {
          defaultValue: configuration.defaultValue as string,
          dropdownOptions: configuration.dropdownOptions,
        },
      };
    default:
      return {
        valueText: {
          defaultValue: "",
        },
      };
  }
}
