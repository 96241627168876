import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.modalTitle",
    defaultMessage: "Your Connect trial has ended",
    description: "Modal Title",
  },
  modalHeader: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.modalHeader",
    defaultMessage: "Your plan has been reverted back to Core",
    description: "Modal Header",
  },
  modalDescription: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.modalDescription",
    defaultMessage:
      "If you wish to access your progress again or would like to discover more features in Connect, upgrade through the [plans page](/accounts/billing_info/pricing) or reach out for support.",
    description: "Modal Description",
  },
  connectCardHeader: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardHeader",
    defaultMessage: "CONNECT",
    description: "Connect Card Header",
  },
  connectCardMonthlyCost: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardMonthlyCost",
    defaultMessage: `\${monthlyDiscountedCost}/mo`,
    description: "Connect Card Monthly Cost",
  },
  connectCardAnnualCost: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardAnnualCost",
    defaultMessage: `\${annualDiscountedCost}/mo`,
    description: "Connect Card Annual Cost",
  },
  connectCardSavings: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardSavings",
    defaultMessage: `Save \${savings}/mo`,
    description: "Connect Card Savings",
  },
  connectCardWhenCopy: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardWhenCopy",
    defaultMessage: "when",
    description: "Connect Card When Copy",
  },
  connectCardBilledAnnuallyCopy: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardBilledAnnuallyCopy",
    defaultMessage: "billed annually",
    description: "Connect Card Billed Annually Copy",
  },
  connectCardIncludesCopy: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardIncludesCopy",
    defaultMessage: "Includes everything in Core, plus",
    description: "Connect Card Includes Copy",
  },
  connectCardFeatureOne: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardFeatureOne",
    defaultMessage: "Time & expense tracking",
    description: "Connect Card Feature One",
  },
  connectCardFeatureTwo: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardFeatureTwo",
    defaultMessage: "Custom fields",
    description: "Connect Card Feature Two",
  },
  connectCardFeatureThree: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardFeatureThree",
    defaultMessage: "Automated payments",
    description: "Connect Card Feature Three",
  },
  connectCardFeatureFour: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.connectCardFeatureFour",
    defaultMessage: "Automated email & text messages",
    description: "Connect Card Feature Four",
  },
  dismissCtaLabel: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.dismissCtaLabel",
    defaultMessage: "Dismiss",
    description: "Dismiss CTA Label",
  },
  chatWithUsCtaLabel: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.chatWithUsCtaLabel",
    defaultMessage: "Chat with us",
    description: "Chat with us CTA Label",
  },
  upgradeNowCtaLabel: {
    id: "coreToConnectTrial.coreToConnectTrialPostTrialModal.upgradeNowCtaLabel",
    defaultMessage: "Upgrade Now",
    description: "Upgrade Now CTA Label",
  },
});
