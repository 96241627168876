import React from "react";
import { useIntl } from "react-intl";
import { Body, Cell, Header, Row, Table } from "components/Table";
import { ExpensesTableTitle } from "jobber/workOrders/components/JobCost/components/ExpensesTable/components";
import type { JobDetailsFragment, User } from "~/utilities/API/graphql";
import styles from "./ExpensesTable.module.css";
import { messages } from "./messages";

interface ExpenseEmptyStateProps {
  job: JobDetailsFragment;
  currentUser: User;
}

export function ExpensesTableEmptyState({
  job,
  currentUser,
}: ExpenseEmptyStateProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.expensesTable}>
      <Table>
        <Header>
          <ExpensesTableTitle job={job} currentUser={currentUser} />
        </Header>
        <Body>
          <Row>
            <Cell>{formatMessage(messages.expenseTableEmptyState)}</Cell>
          </Row>
        </Body>
      </Table>
    </div>
  );
}
