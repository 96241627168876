import { defineMessages } from "react-intl";

export const messages = defineMessages({
  topPriorityHeading: {
    id: "setupWizard.topPriorityPage.topPriorityHeading",
    defaultMessage: "What is your top priority right now?",
    description: "Top priority heading for the setup wizard",
  },
  topPriorityCopy: {
    id: "setupWizard.topPriorityPage.topPriorityCopy",
    defaultMessage:
      "Tell us what matters most to your business, and we’ll recommend features that help you reach your goals faster!",
    description: "Top priority copy for the setup wizard",
  },
  topPriorityPlaceholder: {
    id: "setupWizard.topPriorityPage.topPriorityPlaceholder",
    defaultMessage: "What is your top priority right now?",
    description: "Top priority placeholder for the setup wizard",
  },
  topPriorityError: {
    id: "setupWizard.topPriorityPage.topPriorityError",
    defaultMessage: "Please tell us your top priority",
    description: "Top priority error for the setup wizard",
  },
  topPriorityGetMoreCustomers: {
    id: "setupWizard.topPriorityPage.topPriorityGetMoreCustomers",
    defaultMessage: "Get more customers",
    description:
      "Top priority 'get more customers' option for the setup wizard",
  },
  topPriorityIncreaseEfficiency: {
    id: "setupWizard.topPriorityPage.topPriorityIncreaseEfficiency",
    defaultMessage: "Run more efficiently",
    description:
      "Top priority 'run more efficiently' option for the setup wizard",
  },
  topPriorityOtherTextPlaceholder: {
    id: "setupWizard.topPriorityPage.topPriorityOtherTextPlaceholder",
    defaultMessage: "Other",
    description: "Top priority 'other' text placeholder for the setup wizard",
  },
  topPriorityOtherTextValidation: {
    id: "setupWizard.topPriorityPage.topPriorityOtherTextValidation",
    defaultMessage: "Text must be less than 255 characters",
    description: "Top priority 'other' text validation for the setup wizard",
  },
});
