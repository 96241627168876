import { gql } from "@apollo/client";
import { conversationData } from "jobber/chat/components/ChatDrawer/NotificationList/NotificationList";

export const CONVERSATIONS_QUERY = gql`
  query Conversations(
    $searchTerm: String
    $filter: ConversationFilterAttributes
    $cursor: String
  ) {
    conversations(
      searchTerm: $searchTerm
      filter: $filter
      first: 20
      after: $cursor
    ) {
      edges {
        node {
          ...conversationData
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }

  ${conversationData}
`;

export const CONVERSATION_SUBSCRIPTION = gql`
  subscription Conversation {
    conversation {
      conversation {
        ...conversationData
      }
    }
  }

  ${conversationData}
`;
