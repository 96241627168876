export const messages = {
  choosePlan: {
    id: "choosePlan",
    defaultMessage: "Choose plan",
    description: "Title for the create account list item",
  },
  choosePlanGuideHeading: {
    id: "choosePlanGuideHeading",
    defaultMessage: "Welcome to",
    description: "Setup guide welcome heading",
  },
  choosePlanGuideButtonLabel: {
    id: "choosePlanGuideButtonLabel",
    defaultMessage: "Get started",
    description: "Setup guide welcome screen get started button",
  },

  // step: Bring in your brand
  addedBranding: {
    id: "addedBranding",
    defaultMessage: "Bring in your brand",
    description: "Title for the add branding list item",
  },
  addBrandingGuideHeading: {
    id: "addBrandingGuideHeading",
    defaultMessage: "Create a lasting impression",
    description: "Setup guide heading for customize branding",
  },
  addBrandingGuideSubHeading: {
    id: "addBrandingGuideSubHeading",
    defaultMessage:
      "Add a professional touch and keep your brand consistent throughout the client experience.",
    description: "Setup guide subheading for customize branding",
  },
  addBrandingGuideButtonLabel: {
    id: "addBrandingGuideButtonLabel",
    defaultMessage: "Customize branding",
    description: "Setup guide button for customize branding",
  },

  // step: Add clients
  addedClient: {
    id: "addedClient",
    defaultMessage: "Add your clients",
    description: "Title for the add client list item",
  },
  addClientGuideHeading: {
    id: "addClientGuideHeading",
    defaultMessage: "Everything starts with your clients",
    description: "Setup guide heading for add clients",
  },
  addClientGuideSubHeading: {
    id: "addClientGuideSubHeading",
    defaultMessage:
      "Deliver personalized service by tracking all their information in one place.",
    description: "Setup guide subheading for add clients",
  },
  addClientGuideButtonLabel: {
    id: "addClientGuideButtonLabel",
    defaultMessage: "Add your clients",
    description: "Setup guide button for add clients",
  },

  // step: Log into the app
  appDownloaded: {
    id: "appDownloaded",
    defaultMessage: "Log into the app",
    description: "Title for the download app list item",
  },
  appDownloadGuideHeading: {
    id: "appDownloadGuideHeading",
    defaultMessage: "Run your business from anywhere",
    description: "Setup guide heading for app download",
  },
  appDownloadGuideSubHeading: {
    id: "appDownloadGuideSubHeading",
    defaultMessage:
      "Download and log into the app to get real time notifications for key client interactions.",
    description: "Setup guide subheading for app download",
  },
  appDownloadGuideButtonLabel: {
    id: "appDownloadGuideButtonLabel",
    defaultMessage: "Send yourself the link",
    description: "Setup guide button for app download",
  },
  appDownloadGuideGooglePlay: {
    id: "appDownloadGuideGooglePlay",
    defaultMessage: "Download the app from Google PLay",
    description: "Google Play app download",
  },
  appDownloadGuideAppStore: {
    id: "appDownloadGuideAppStore",
    defaultMessage: "Download the app from App Store",
    description: "App Store app download",
  },

  // step: Invite your team
  addedTeamUser: {
    id: "addedTeamUser",
    defaultMessage: "Invite your team",
    description: "Title for the add team user list item",
  },
  addTeamUserGuideHeading: {
    id: "addTeamUserGuideHeading",
    defaultMessage: "Collaborate together",
    description: "Setup guide heading for invite team",
  },
  addTeamUserGuideSubHeading: {
    id: "addTeamUserGuideSubHeading",
    defaultMessage:
      "Add your team to streamline communications, stay on top of progress, and keep all the work in one place.",
    description: "Setup guide subheading for invite team",
  },
  addTeamUserGuideButtonLabel: {
    id: "addTeamUserGuideButtonLabel",
    defaultMessage: "Invite team",
    description: "Setup guide button for invite team",
  },

  // status bar progress texts
  progressStartInfo: {
    id: "progressStartInfo",
    defaultMessage: "off to a great start!",
    description: "Text for 1st step done in Progress bar",
  },
  progressKeepGoingInfo: {
    id: "progressKeepGoingInfo",
    defaultMessage: "keep going!",
    description: "Text for 2nd step done in Progress bar",
  },
  progressAlmostThereInfo: {
    id: "progressAlmostThereInfo",
    defaultMessage: "almost there!",
    description: "Text for 3rd step done in Progress bar",
  },
  progressLastStepInfo: {
    id: "progressLastStepInfo",
    defaultMessage: "last step!",
    description: "Text for 4th step done in Progress bar",
  },
  progressCompleteInfo: {
    id: "progressCompleteInfo",
    defaultMessage: "complete",
    description: "Complete text",
  },
};
