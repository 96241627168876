import React from "react";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import styles from "./AppFeatureRow.module.css";
import type { AppFeatureRowProps } from "./AppFeatureRow.types";

export function AppFeatureRow({ name, logoIconUrl }: AppFeatureRowProps) {
  return (
    <li className={classNames([styles.featureRow, styles.paddingLeft])}>
      <div className={styles.row}>
        <div className={classNames([styles.column, styles.shrink])}>
          <img
            alt={`${name} logo icon`}
            className={styles.logoIcon}
            src={logoIconUrl}
          />
        </div>
        <div className={classNames([styles.column, styles.textContainer])}>
          <Text>{name}</Text>
        </div>
      </div>
    </li>
  );
}
