export function dateRangeLabel(
  startDate: Date,
  endDate: Date,
  prefix = "",
): string {
  return `${prefix}${formatDate(startDate)} - ${formatDate(endDate)}`;
}

function formatDate(date: Date): string {
  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}
