import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage: t } = createIntl();

export function stayOnGrowPlanButton() {
  return screen.getByRole("button", {
    name: t(messages.fiveDayUsageReminderModalStayOnTheGrowPlanCTA),
  });
}

export function tryGrowFeaturesButton() {
  return screen.getByRole("link", {
    name: t(messages.fiveDayUsageReminderModalTryGrowFeaturesCTA),
  });
}

export function bookACallButton() {
  return screen.getByRole("link", {
    name: t(messages.fiveDayUsageReminderModalBookACallCTA),
  });
}

export function removeButton() {
  return screen.getByTestId("remove");
}
