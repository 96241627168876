import type { LineItem } from "~/jobber/lineItems/types";
import { randomLineItemId } from "~/jobber/lineItems/utils";

// The shape of a line item from the rails model
export interface WorkOrderLineItem {
  id: string;
  name: string;
  description: string;
  qty: number;
  cost: string;
  taxable?: boolean;
  /* eslint-disable @typescript-eslint/naming-convention */
  unit_cost: string;
  internal_unit_cost: string;
  quote_line_item_id: number;
  work_item_id: number;
  image?: {
    url?: string;
    thumbnailUrl?: string;
    fileName?: string;
    fileSize?: number;
    contentType?: string;
    s3Key?: string;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
}

export function workOrderLineItemToLineItem(
  workOrderLineItem: WorkOrderLineItem,
): LineItem {
  const productOrServiceId = workItemIdToProductOrServiceId(
    workOrderLineItem.work_item_id,
  );
  return {
    reactKey: randomLineItemId(),
    id: workOrderLineItem?.id ? workOrderLineItem.id : "",
    name: workOrderLineItem.name,
    description: workOrderLineItem.description,
    quantity: workOrderLineItem.qty,
    unitCost: parseFloat(workOrderLineItem.internal_unit_cost) || 0,
    unitPrice: parseFloat(workOrderLineItem.unit_cost) || 0,
    totalPrice: parseFloat(workOrderLineItem.cost) || 0,
    totalCost:
      (parseFloat(workOrderLineItem.internal_unit_cost) || 0) *
      workOrderLineItem.qty,
    quoteLineItemId: workOrderLineItem.quote_line_item_id,
    isDeleted: false,
    taxable: workOrderLineItem.taxable,
    image: workOrderLineItem.image,
    linkedProductOrService: productOrServiceId
      ? {
          id: productOrServiceId,
        }
      : undefined,
  };
}

function workItemIdToProductOrServiceId(workItemId?: number | null) {
  if (workItemId === null || workItemId === undefined) {
    return undefined;
  }

  return btoa(workItemId.toString());
}
