import { gql } from "@apollo/client";
import { messageData } from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";

export const READ_CONVERSATION_MUTATION = gql`
  mutation ConversationRead($id: EncodedId!) {
    conversationRead(id: $id) {
      conversation {
        id
        readFlag
      }
      userErrors {
        message
      }
    }
  }
`;

export const CONVERSATION_MESSAGE_SUBSCRIPTION = gql`
  subscription ConversationMessage($conversationId: EncodedId!) {
    conversationMessage(conversationId: $conversationId) {
      message {
        ...messageData
      }
    }
  }

  ${messageData}
`;
