interface JobPositionsListStructure {
  value: string;
  label: string;
}

export const jobPositionsList: JobPositionsListStructure[] = [
  { value: "DIRECTOR", label: "Director" },
  { value: "GM", label: "GM" },
  { value: "VP", label: "VP" },
  { value: "CEO", label: "CEO" },
  { value: "CFO", label: "CFO" },
  { value: "GENERAL_COUNSEL", label: "General Counsel" },
  { value: "OTHER", label: "Other" },
];
