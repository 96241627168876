import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import { Divider } from "@jobber/components/Divider";
import { Card } from "@jobber/components/Card";
import { List } from "@jobber/components/List";
import { debounce } from "lodash";
import { Spinner } from "@jobber/components/Spinner";
import { Banner } from "@jobber/components/Banner";
import { submitDomForm } from "utilities/submitDomForm";
import type { LeadRoute } from "~/utilities/API/graphqlFranchiseManagement";
import { LEAD_ROUTES_QUERY } from "./LeadRouting.graphql";
import styles from "./LeadRouting.module.css";

export interface LeadRoutingProps {
  modalOpen: boolean;
  setModalOpen(visibility: boolean): void;
}

function EmptyResults({ input }: { input: string }) {
  return (
    <div style={{ padding: "1rem" }}>
      <Text align="center">{`No results for "${input}" found.`}</Text>
    </div>
  );
}

function Loading() {
  return (
    <div
      style={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      <Spinner />
    </div>
  );
}

function Error() {
  return (
    <Banner type={"error"}>{"Something went wrong. Please try again"}</Banner>
  );
}

interface ResultsProps {
  franchises: LeadRoute[];
}

function Results({ franchises }: ResultsProps) {
  return (
    <>
      <Content>
        <List
          items={franchises.map(f => {
            return {
              title: f?.account?.name,
              id: f.id,
              content: "",
              section: "Create request in...",
              onClick: () => submitDomForm({ action: f.url, method: "post" }),
            };
          })}
        />
      </Content>
    </>
  );
}

export function LeadRouting({ modalOpen, setModalOpen }: LeadRoutingProps) {
  const [input, setInput] = useState<string>("");

  const { loading, error, data } = useQuery(LEAD_ROUTES_QUERY, {
    variables: { searchTerm: input },
  });

  const franchises: LeadRoute[] = data?.leadRoutes?.nodes || [];

  const onChange = debounce((value: string) => {
    setInput(value);
  }, 200);

  return (
    <Modal
      title="Route a lead"
      open={modalOpen}
      onRequestClose={() => {
        setModalOpen(false);
      }}
    >
      <Content spacing={"base"}>
        <Text>Route leads to franchise locations based on ZIP code.</Text>
        <InputText placeholder="Enter ZIP code" onChange={onChange} />
        <Card>
          <Divider />
          <div className={styles.listContainer}>
            {loading && <Loading />}
            {error && <Error />}
            {!loading && !error && franchises.length === 0 && (
              <EmptyResults input={input} />
            )}
            {!loading && !error && franchises.length > 0 && (
              <Results franchises={franchises} />
            )}
          </div>
        </Card>
      </Content>
    </Modal>
  );
}
