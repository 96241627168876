import { screen } from "@testing-library/react";

export const companyImage = () => screen.findByAltText("Company image");
export const companyImageAlt = async () => {
  const image = await companyImage();
  return image.getAttribute("alt");
};
export const companyImageSrc = async () => {
  const image = await companyImage();
  return image.getAttribute("src");
};
