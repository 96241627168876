import { gql } from "@apollo/client";

export const APP_ALERTS_QUERY = gql`
  query AppAlerts {
    appAlerts {
      nodes {
        count
        updatedAt
        app {
          id
          manageAppUrl
          logoUrl
          name
        }
      }
    }
  }
`;

export const APP_ALERTS_SUBSCRIPTION = gql`
  subscription AppAlertsSubscription {
    appAlerts {
      appAlerts {
        nodes {
          app {
            id
            manageAppUrl
            logoUrl
            name
          }
          count
          updatedAt
        }
        totalCount
      }
    }
  }
`;
