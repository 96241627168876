import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerInReview: {
    id: "viewDisputeStatus.headerInReview",
    defaultMessage: "Evidence in review",
    description: "Dispute status in review header",
  },
  headerLost: {
    id: "viewDisputeStatus.headerLost",
    defaultMessage: "Chargeback lost",
    description: "Dispute status chargeback lost header",
  },
  headerWon: {
    id: "viewDisputeStatus.headerWon",
    defaultMessage: "Chargeback won",
    description: "Dispute status chargeback won header",
  },
  headerClosed: {
    id: "viewDisputeStatus.headerClosed",
    defaultMessage: "Inquiry is closed",
    description: "Dispute status inquiry closed header",
  },
  paragraphOneInReview: {
    id: "viewDisputeStatus.paragraphOneInReview",
    defaultMessage:
      "Your response submitted on {date} is being reviewed by the cardholder's issuing bank. We will notify you when we receive the issuing bank's decision, which can take up to three months.",
    description: "Dispute status in review paragraph one",
  },
  paragraphOneLost: {
    id: "viewDisputeStatus.paragraphOneLost",
    defaultMessage:
      "The chargeback was lost. The disputed amount of {amount} will not be returned to you.",
    description: "Dispute status chargeback lost paragraph one",
  },
  paragraphOneWon: {
    id: "viewDisputeStatus.paragraphOneWon",
    defaultMessage:
      "The issuing bank decided in your favor on {date}. The disputed amount of {amount} will be returned to you. Funds will be credited on the next payout cycle.",
    description: "Dispute status chargeback won paragraph one",
  },
  paragraphOneRefundedClosed: {
    id: "viewDisputeStatus.paragraphOneRefundedClosed",
    defaultMessage:
      "You refunded your client on {date}. This inquiry is closed.",
    description: "Dispute status inquiry refunded closed paragraph one",
  },
  paragraphOneClosed: {
    id: "viewDisputeStatus.paragraphOneClosed",
    defaultMessage:
      "The issuing bank has decided to not pursue this dispute. No funds or fees were withdrawn from your account.",
    description: "Dispute status inquiry closed paragraph one",
  },
  learnMore: {
    id: "viewDisputeStatus.learnMore",
    defaultMessage: "Learn more about the dispute process in the [help center]",
    description: "Learn more link",
  },
  viewClaimDetails: {
    id: "viewDisputeStatus.viewClaimDetails",
    defaultMessage: "View claim details",
    description: "View claim details button",
  },
  viewClaimDetailsA11y: {
    id: "viewDisputeStatus.viewClaimDetailsA11y",
    defaultMessage: "View claim details button dispute status",
    description: "View claim details button dispute status aria label",
  },
});
