import { gql } from "@apollo/client";

export const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethods($clientId: EncodedId!) {
    paymentMethods(filter: { clientId: $clientId }) {
      nodes {
        ... on BankAccountPaymentMethod {
          __typename
          bankName
          defaultMethod
          fingerprint
          last4
          uuid
        }
        ... on CreditCardPaymentMethod {
          __typename
          brand
          defaultMethod
          expired
          expiringSoon
          expiryYear
          expiryMonth
          fingerprint
          uuid
          last4
        }
      }
    }
  }
`;
