import React from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";

interface OTPTextProps {
  readonly channel: string;
  readonly phoneLastDigits: string;
}

export function OTPText({ channel, phoneLastDigits }: OTPTextProps) {
  switch (channel) {
    case "call":
      return (
        <Text>
          You&rsquo;ll receive a call from Jobber with your verification code to
          your number ending in{" "}
          <Emphasis variation="bold">{phoneLastDigits}, </Emphasis>
          enter the code to continue.
        </Text>
      );
    default:
      return (
        <Text>
          We&rsquo;ve sent a verification code to your current phone ending in{" "}
          <Emphasis variation="bold">{phoneLastDigits}, </Emphasis>
          enter the code to continue.
        </Text>
      );
  }
}
