import React, { useEffect } from "react";
import { Content } from "@jobber/components/Content";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { Option, Select } from "@jobber/components/Select";
import { useIntl } from "react-intl";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import {
  DEFAULT_DURATION,
  DEFAULT_WORK_ITEM_TYPE,
} from "jobber/workItems/components/constants";
import type { BookableType, QuantityRange } from "jobber/workItems/types";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { durationConstants } from "./constants";
import { ServiceQuantities } from "./components/ServiceQuantities";

const durationValues = createDurationValues(
  durationConstants.minimumMinutes,
  durationConstants.maximumMinutes,
  durationConstants.incrementMinutes,
  durationConstants.additionalValues,
);

const bookableTypes = [
  { value: "WORK_ORDER", label: "Job" },
  { value: "WORK_REQUEST", label: "Assessment" },
];

export interface WorkItemSelfServeBookingsSettingsProps {
  bookingEnabled: boolean;
  durationMinutes?: number;
  onSelfServeBookingsPage?: boolean;
  quantityRange?: QuantityRange;
  bookableType: BookableType | undefined;
  handleDurationChange(newValue: number): void;
  handleBookingEnabledChange(newValue: boolean): void;
  handleServiceQuantityChange(newValue: QuantityRange): void;
  handleBookableTypeChange(newValue: BookableType): void;
}

export function WorkItemSelfServeBookingsSettings({
  bookingEnabled,
  durationMinutes,
  onSelfServeBookingsPage,
  quantityRange,
  bookableType,
  handleBookingEnabledChange,
  handleDurationChange,
  handleServiceQuantityChange,
  handleBookableTypeChange,
}: WorkItemSelfServeBookingsSettingsProps) {
  const { formatMessage } = useIntl();

  const handleSelectionChange = (newValue: string | boolean | Date) => {
    const selectedDuration = Number(newValue);
    if (!isNaN(selectedDuration)) {
      handleDurationChange(selectedDuration);
    }
  };
  useEffect(() => {
    // When the workItemModal is used within Self Serve Bookings we will automatically update the productOrService to be online bookable.
    // This is because the switch is hidden and the modal assumes the value is true
    if (onSelfServeBookingsPage && !bookingEnabled) {
      handleBookingEnabledChange(true);
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      <Content>
        <FeatureSwitch
          title={formatMessage(messages.settingsTitle)}
          description={getSwitchDescription()}
          // Switch is hidden for Self Serve Bookings page, done by setting function to undefined
          onSwitch={
            onSelfServeBookingsPage ? undefined : handleBookingEnabledChange
          }
          externalLink={true}
          enabled={bookingEnabled}
        />
        {bookingEnabled && (
          <>
            <Select
              placeholder={formatMessage(messages.serviceType)}
              onChange={handleBookableTypeChange}
              value={bookableType}
              defaultValue={DEFAULT_WORK_ITEM_TYPE}
            >
              {bookableTypes.map(type => {
                return (
                  <Option key={type.value} value={type.value}>
                    {type.label}
                  </Option>
                );
              })}
            </Select>
            <Select
              placeholder={formatMessage(messages.duration)}
              onChange={handleSelectionChange}
              value={durationMinutes}
              defaultValue={DEFAULT_DURATION.toString()}
            >
              {durationValues.map(duration => {
                return (
                  <Option key={duration} value={duration.toString()}>
                    {minutesToDuration(duration)}
                  </Option>
                );
              })}
            </Select>
            <ServiceQuantities
              quantityRange={quantityRange}
              onChange={handleServiceQuantityChange}
            />
          </>
        )}
      </Content>
    </div>
  );

  function getSwitchDescription() {
    if (onSelfServeBookingsPage) {
      return formatMessage(messages.settingsDescription);
    }

    return `${formatMessage(messages.settingsDescription)} ${formatMessage(
      messages.linkToSelfServeBookingsServicesSettings,
    )}`;
  }
}

function createDurationValues(
  minimumMinutes: number,
  maximumMinutes: number,
  incrementMinutes: number,
  additionalValues: number[],
) {
  const values: Set<number> = new Set(additionalValues);
  for (let i = minimumMinutes; i <= maximumMinutes; i += incrementMinutes) {
    values.add(i);
  }
  return Array.from(values).sort((a, b) => a - b);
}
