import { jobberOnline } from "components/JobberOnline/jobberOnline";
import {
  buildFlashErrorHTML,
  instanceOfPaymentError,
} from "jobber/payments/errors";
import performCharge from "jobber/payments/requests/performCharge";
import {
  isPaymentIntegration,
  paymentTypes,
  paymentTypesToString,
} from "jobber/payments/utils";
import FormContext from "./FormContext";

/**
 * Wraps the collect payment form dialog.
 *
 * @export
 * CollectPaymentForm
 */
export class CollectPaymentForm {
  private readonly form: HTMLFormElement;
  private readonly formContext: FormContext;
  private paymentType = paymentTypes.unknown;

  constructor(form: HTMLFormElement) {
    this.form = form;
    this.formContext = new FormContext(form);

    this.paymentType = this.formContext.state.selectedPaymentType;
    form.classList.add(paymentTypesToString(this.paymentType));
    this.attachListeners();
  }

  private attachListeners() {
    const selectPaymentTypeInput = this.form.querySelector(
      ".js-paymentType select",
    ) as HTMLSelectElement;

    if (selectPaymentTypeInput) {
      selectPaymentTypeInput.addEventListener(
        "change",
        this.handlePaymentTypeChanged,
      );
    }

    // Attach to rails remote form submit
    // https://github.com/rails/jquery-ujs/wiki/ajax#stoppable-events
    $(this.form).on("ajax:before", this.handleSubmit);
  }

  /**
   * Adds the current payment type to the form element classlist so that
   * the correct payment form fields can be displayed via CSS.
   */
  private readonly handlePaymentTypeChanged = () => {
    this.form.classList.remove(paymentTypesToString(this.paymentType));
    this.form.classList.add(
      paymentTypesToString(this.formContext.state.selectedPaymentType),
    );

    this.paymentType = this.formContext.state.selectedPaymentType;

    this.updateReactPaymentForm();
  };

  /**
   * Mount or unmount the React payment form based on payment type.
   *
   * Since we we instruct the React payment form to intercept form submit events,
   * we cannot rely on hide/show with CSS `display: none` like other fields
   *
   */
  private readonly updateReactPaymentForm = () => {
    const jobberPayments = paymentTypes.jobberPayments;
    if (this.paymentType === jobberPayments) {
      window.ReactRailsUJS.mountComponents(".js-scaReactJobberPaymentsForm");
    } else {
      window.ReactRailsUJS.unmountComponents(".js-scaReactJobberPaymentsForm");
    }

    window.dispatchEvent(
      new CustomEvent("collectPaymentForm.method.updated", {
        detail: {
          authorizeFormSelected:
            this.paymentType === paymentTypes.epayment &&
            jobberOnline.constants.paymentIntegration === "authorize_net",
        },
      }),
    );
  };

  /**
   * Handles the `jquery_ujs` `ajax::before` event.
   *
   * This handler calls `event.preventDefault()` so that it will work with
   * `@rails/ujs` when we eventually drop `jquery_ujs`,
   *
   * If the processing a payment this handler will prevent the default
   * form submit and trigger the process payment request flow.
   *
   * If recording a payment this method will return true and allow
   * the default form submit.
   */
  private readonly handleSubmit = (event: Event) => {
    this.clearErrors();
    this.disableSubmit(true);

    if (isPaymentIntegration(this.formContext.state.selectedPaymentType)) {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.processPayment();

      return false;
    }

    return true;
  };

  /**
   * Sends the charge request to the server and handles the response.
   */
  private async processPayment() {
    if (!this.formContext.params.charge_card_url) {
      throw new Error("Missing charge_card_url");
    }

    try {
      const response = await performCharge(
        this.formContext.params.charge_card_url,
        await this.formContext.serialize(),
      );

      if (response.success) {
        this.handleProcessPaymentSuccess();

        return;
      }

      if (response.error) {
        this.handleProcessPaymentError(response.error);
      }
    } catch (error) {
      this.handleProcessPaymentError(error);
    }

    this.clearLoadingState();
    this.disableSubmit(false);
  }

  /**
   * Redirects to the `on_success_url` if present, otherwise just reloads the page.
   */
  private handleProcessPaymentSuccess() {
    if (this.formContext.params.on_success_url) {
      window.location.href = this.formContext.params.on_success_url;
    } else {
      window.location.reload();
    }
  }

  /**
   * Parses an error-like object and inserts the message into the form.
   * If the shape of the error isn't recognized, it will get re-thrown.
   *
   * @param error an error object
   */
  private handleProcessPaymentError(error: unknown) {
    let normalizedArgs: [string | undefined, string[]?];

    if (instanceOfPaymentError(error)) {
      normalizedArgs = [error.message, error.details];
    } else if (typeof error === "string") {
      normalizedArgs = [error];
    } else if (Array.isArray(error)) {
      normalizedArgs = [undefined, error];
    } else if (error instanceof Error) {
      normalizedArgs = [error.message];
    } else {
      throw error;
    }

    this.insertError(...normalizedArgs);
  }

  /**
   * Sets the disabled state of the form submit button.
   *
   * @param disabled the disabled state to set on the button
   */
  private disableSubmit(disabled: boolean) {
    Array.from(this.form.querySelectorAll("[type=submit]")).forEach(
      (element: HTMLButtonElement) => {
        element.disabled = disabled;
      },
    );
  }

  /**
   * Removes all the errors from the form.
   */
  private clearErrors() {
    Array.from(this.form.querySelectorAll(".js-errorMessage")).forEach(
      element => {
        element.remove();
      },
    );
  }

  /**
   * Renders the given errors as a flash message at the top of the form.
   *
   * @param [header=""] A clear summarization of the errors
   * @param [details=[]] A list of errors to display as a bulleted list under the header
   */
  private insertError(header = "", details: string[] = []) {
    this.form.insertAdjacentHTML(
      "afterbegin",
      buildFlashErrorHTML(details, header),
    );
  }

  /**
   * Removes any active spinners from the form.
   */
  private clearLoadingState() {
    Array.from(this.form.querySelectorAll(".spinner, .spinning")).forEach(
      element => {
        element.classList.remove("spinner", "spinner--small", "spinning");
      },
    );
  }
}
