import { gql } from "@apollo/client";

export const INVOICE_DETAIL_QUERY = gql`
  query InvoiceDetailData(
    $filter: InvoiceDetailFilterInput
    $sort: InvoiceDetailSortInput
    $after: String
    $first: Int
  ) {
    invoiceDetail(sort: $sort, filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        assignedUsers
        balance
        clientName
        depositAmount
        discountAmount
        draftedOn
        dueOn
        issuedOn
        paidOn
        franchiseeName
        invoiceNumber
        status
        subject
        subtotal
        taxAmount
        taxRate
        tipsTotal
        total
      }
    }
  }
`;

export const INVOICE_DETAIL_TOTALS_QUERY = gql`
  query InvoiceDetailTotals($filter: InvoiceDetailFilterInput) {
    invoiceDetailTotals(filter: $filter) {
      balanceTotal
      depositAmountTotal
      discountAmountTotal
      subtotalAmountTotal
      taxAmountTotal
      tipAmountTotal
      totalAmountTotal
    }
  }
`;

export const INVOICE_DETAIL_EXPORT_MUTATION = gql`
  mutation InvoiceDetailReportExport($input: ExportInvoiceDetailCsvInput) {
    exportInvoiceDetailCsv(input: $input) {
      response
    }
  }
`;
