import React, { useCallback, useEffect, useMemo } from "react";
import { SelectableCards } from "jobber/campaigns/components/SelectableCards";
import { ClientSegmentDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentDrawer";
import { useClientSegmentSideDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { useClientSegmentOptions } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import type { ClientSegmentFragment } from "~/utilities/API/graphql";
import { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { getSegmentCriteriasFromSegments } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { useUxImprovementSplit } from "jobber/campaigns/hooks/useUxImprovementSplit/useUxImprovementSplit";
import { SelectableSegments } from "jobber/campaigns/components/SelectableSegments/SelectableSegments";

interface ClientSegmentInternalsProps {
  initialClientSegment: ClientSegmentFragment | undefined;

  setClientSegment(clientSegment: ClientSegmentFragment | undefined): void;

  loading: boolean;
}

export function ClientSegmentInternals({
  initialClientSegment: clientSegment,
  setClientSegment,
  loading,
}: ClientSegmentInternalsProps) {
  const {
    campaignSegment: {
      selectedSegmentType,
      setSelectedSegmentType,
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    },
    campaignContent: { templateType },
  } = useCampaignWizardContext();
  const {
    openSideDrawer,
    closeSideDrawer,
    drawerState,
    isOpen,
    segmentName,
    setDrawerState,
    additionalCriteria,
    segmentCriteria,
    setSegmentCriteria,
    updateAdditionalCriteria,
  } = useClientSegmentSideDrawer({ clientSegment });

  const clientSegmentInternalsConditionalVariables = useMemo(
    () => ({
      isUpcomingClientDrawer: selectedSegmentType === Segment.UPCOMING_CLIENTS,
      isPastClientDrawer: selectedSegmentType === Segment.PAST_CLIENTS,
      isAllClientDrawer: selectedSegmentType === Segment.ALL_CLIENTS,
    }),
    [selectedSegmentType],
  );
  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    };
  }, [
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  const { fetchData, fullReload, loadingMore, refetch, clientSegmentsData } =
    useClientSegmentData({
      conditionalVariables: clientSegmentInternalsConditionalVariables,
      segmentCriterias,
      segmentType: selectedSegmentType,
    });

  const segmentOptions = useClientSegmentOptions({
    clientsCriteriaInput: segmentCriterias,
    clientSegments: clientSegmentsData,
    selectedSegmentType,
    templateType,
  });

  const updateSelectedOption = useCallback(
    (value: Segment) => {
      setSelectedSegmentType(value, true);
    },
    [setSelectedSegmentType],
  );

  const { isInUxImprovementSplit } = useUxImprovementSplit();

  useEffect(() => {
    const variables = {
      ...clientSegmentInternalsConditionalVariables,
      ...getSegmentCriteriasFromSegments({
        segmentCriterias: segmentCriterias,
        segmentType: selectedSegmentType,
      }),
      after: btoa("0"),
    };

    refetch(variables);
  }, [segmentCriterias, selectedSegmentType, refetch]);

  return (
    <>
      {isInUxImprovementSplit && (
        <SelectableSegments
          segmentOptions={segmentOptions}
          value={selectedSegmentType}
          loading={loading || fullReload}
          onChange={updateSelectedOption}
        />
      )}

      {!isInUxImprovementSplit && (
        <SelectableCards
          name="clientSegments"
          fullWidth={true}
          loading={loading || fullReload}
          cards={segmentOptions}
          value={selectedSegmentType}
          onChange={updateSelectedOption}
          additionalButtonClick={openSideDrawerFn}
        />
      )}
      <ClientSegmentDrawer
        isOpen={isOpen}
        closeSideDrawer={closeSideDrawer}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        selectedSegment={selectedSegmentType}
        segmentName={segmentName}
        refetch={refetch}
        fetchData={fetchData}
        clientSegmentData={{
          rawData: clientSegment,
          clientSegments: clientSegmentsData,
        }}
        criteria={{
          additionalCriteria: additionalCriteria,
          updateAdditionalCriteria: updateAdditionalCriteria,
          baseCriteria: segmentCriteria,
          setBaseCriteria: setSegmentCriteria,
        }}
        loadingState={{
          loadingMore: loadingMore,
          fullReload: fullReload,
        }}
        setClientSegment={setClientSegment}
      />
    </>
  );

  function openSideDrawerFn() {
    const heading =
      segmentOptions.find(card => card.type === selectedSegmentType)?.heading ||
      "";
    openSideDrawer(heading);
  }
}
