import { gql } from "@apollo/client";

const PLAID_CONNECT_ACCOUNT_MUTATION = gql`
  mutation ConnectPlaidBankAccount(
    $plaidLinkPublicKeyToken: String!
    $plaidAccountId: String!
  ) {
    connectPlaidBankAccount(
      plaidLinkPublicKeyToken: $plaidLinkPublicKeyToken
      plaidAccountId: $plaidAccountId
    ) {
      success
      bankMetadata {
        bankName
        last4
        payoutScheduleInDays
      }
      errors {
        message
      }
    }
  }
`;

export { PLAID_CONNECT_ACCOUNT_MUTATION };
