import { InputGroup } from "@jobber/components/InputGroup";
import { InputTime } from "@jobber/components/InputTime";
import type { CivilTime } from "@std-proposal/temporal";
import React from "react";

interface InputTimeProps {
  start: CivilTime | undefined;
  end: CivilTime | undefined;
  isValid: boolean;
  disabled: boolean;
  validationMessage?: React.ReactNode;
  startInputRef?: React.RefObject<HTMLInputElement>;
  endInputRef?: React.RefObject<HTMLInputElement>;
  onChange(next: {
    start: CivilTime | undefined;
    end: CivilTime | undefined;
  }): void;
}

export const ScheduleTimeInput = (props: InputTimeProps) => {
  const onChangeStartTime = (time: CivilTime | undefined) =>
    props.onChange({ start: time, end: props.end });

  const onChangeEndTime = (time: CivilTime | undefined) =>
    props.onChange({ start: props.start, end: time });

  return (
    <>
      <InputGroup flowDirection="horizontal">
        <InputTime
          value={props.start}
          onChange={onChangeStartTime}
          placeholder="Start time"
          inputRef={props.startInputRef}
          disabled={props.disabled}
        />
        <InputTime
          value={props.end}
          onChange={onChangeEndTime}
          placeholder="End time"
          inputRef={props.endInputRef}
          disabled={props.disabled}
        />
      </InputGroup>
    </>
  );
};
