import React from "react";
import type { ColumnDef } from "@jobber/components/DataTable";
import type { GetValueType } from "~/jobber/features/Reporting/components/Report/types";
import {
  ComparableHeader,
  CurrencyFooter,
  InnerColumnCellFactory,
  NumberFooter,
  StandardFooter,
} from "~/jobber/features/Reporting/components/Report/components/ReportCells/ReportCells";
import { InvoiceSummarySortKey } from "~/utilities/API/graphqlFranchiseManagement";
import { INNER_CELL_WIDTH_LARGE } from "~/jobber/features/Reporting/components/Report/constants";
import {
  AVERAGE_PER_INVOICE_COLUMN_HEADER,
  ISSUED_COUNT_COLUMN_HEADER,
  NAME_COLUMN_FOOTER,
  NAME_COLUMN_HEADER,
  TOTAL_ISSUED_COLUMN_HEADER,
  TOTAL_PAID_COLUMN_HEADER,
} from "./constants";
import type { InvoiceSummaryData, InvoiceSummaryTotalsData } from "./types";

export const getColumnConfig = (
  totalsData?: InvoiceSummaryTotalsData,
): ColumnDef<InvoiceSummaryData, GetValueType>[] => {
  return [
    {
      id: InvoiceSummarySortKey.FRANCHISE_NAME,
      accessorKey: "name",
      header: NAME_COLUMN_HEADER,
      footer: () => <StandardFooter text={NAME_COLUMN_FOOTER} />,
      minSize: INNER_CELL_WIDTH_LARGE,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: InvoiceSummarySortKey.ISSUED_COUNT,
      accessorKey: "issuedCount",
      accessorFn: (row: InvoiceSummaryData) => row.issuedCount.toLocaleString(),
      header: () => <ComparableHeader text={ISSUED_COUNT_COLUMN_HEADER} />,
      cell: InnerColumnCellFactory("number"),
      footer: () => <NumberFooter number={totalsData?.issuedCountTotal} />,
    },
    {
      id: InvoiceSummarySortKey.TOTAL_ISSUED,
      accessorKey: "totalIssued",
      accessorFn: (row: InvoiceSummaryData) => row.totalIssued.toLocaleString(),
      header: () => <ComparableHeader text={TOTAL_ISSUED_COLUMN_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => <CurrencyFooter currencyAmount={totalsData?.issuedTotal} />,
    },
    {
      id: InvoiceSummarySortKey.TOTAL_PAID,
      accessorKey: "totalPaid",
      accessorFn: (row: InvoiceSummaryData) => row.totalPaid.toLocaleString(),
      header: () => <ComparableHeader text={TOTAL_PAID_COLUMN_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => <CurrencyFooter currencyAmount={totalsData?.paidTotal} />,
    },
    {
      id: InvoiceSummarySortKey.AVERAGE_PER_INVOICE,
      accessorKey: "averagePerInvoice",
      accessorFn: (row: InvoiceSummaryData) =>
        row.averagePerInvoice.toLocaleString(),
      header: () => (
        <ComparableHeader text={AVERAGE_PER_INVOICE_COLUMN_HEADER} />
      ),
      cell: InnerColumnCellFactory("currency"),
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.issuedAverageTotal} />
      ),
    },
  ];
};
