import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CreateAllClientsSegmentCampaignMutation,
  CreateAllClientsSegmentCampaignMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_CREATE_ALL_CLIENTS } from "./useCampaignCreateAllClientsMutation.graphql";

export function useCampaignCreateAllClientsMutation() {
  const [createAllClientsCampaignMutation, { loading, data, error }] =
    useMutation<
      CreateAllClientsSegmentCampaignMutation,
      CreateAllClientsSegmentCampaignMutationVariables
    >(CAMPAIGN_CREATE_ALL_CLIENTS);

  const createAllClientsCampaign = useCallback(
    async (input: CreateAllClientsSegmentCampaignMutationVariables) => {
      const mutationResult = await createAllClientsCampaignMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignCreateAllClients.userErrors ?? [];
      return {
        campaign: mutationResult.data?.campaignCreateAllClients.campaign,
        clientSegment:
          mutationResult.data?.campaignCreateAllClients.campaign?.clientSegment,
        userErrors,
      };
    },
    [createAllClientsCampaignMutation],
  );
  return { createAllClientsCampaign, loading, data, error };
}
