import { defineMessages } from "react-intl";

export const messages = defineMessages({
  requiredDepositLabel: {
    id: "workOrders.depositDetails.requiredDeposit",
    defaultMessage: "Required Deposit",
    description: "Required Deposit text",
  },
  depositCollectedLabel: {
    id: "workOrders.depositDetails.depositCollected",
    defaultMessage: "Deposit Collected",
    description: "Deposit Collected text",
  },
  outstandingDepositLabel: {
    id: "workOrders.depositDetails.outstandingDeposit",
    defaultMessage: "Outstanding Deposit",
    description: "Outstanding Deposit text",
  },
  depositRefundLabel: {
    id: "workOrders.depositDetails.depositRefund",
    defaultMessage: "Refund",
    description: "Refund text",
  },
});
