import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import React from "react";

interface ReassignConversationModalProps {
  isOpen: boolean;
  reassignConversation(): void;
  cancel(): void;
}

const reassignModalBody =
  "Future conversations with this number will appear under a diferent client record";

export function ReassignConversationModal({
  isOpen,
  reassignConversation,
  cancel,
}: ReassignConversationModalProps) {
  return (
    <Modal
      title="Reassign conversation?"
      open={isOpen}
      secondaryAction={{
        label: "Cancel",
        variation: "subtle",
        onClick: cancel,
      }}
      onRequestClose={() => cancel()}
      primaryAction={{
        label: "Reassign",
        onClick: reassignConversation,
      }}
    >
      <Content>
        <p>{reassignModalBody}</p>
      </Content>
    </Modal>
  );
}
