import type {
  ClientNotificationId,
  DeliveryMethod,
  MessageTemplateEmail,
  MessageTemplateSms,
  NotificationDeliveryMethod,
} from "~/utilities/API/graphql";

export enum ReviewStatuses {
  All = "All",
  Unanswered = "Unanswered",
}

export interface UpdateNotificationArgs {
  deliveryMethod?: keyof typeof NotificationDeliveryMethod;
  clientNotificationId: keyof typeof ClientNotificationId;
  scheduleId?: string;
  templateId?: string;
  message?: string;
  subject?: string;
  scheduleEnabled?: boolean;
  scheduleOffsetValue?: number;
}

export interface ErrorState {
  dismissed: boolean;
  shouldShow: boolean;
  hasGeneralError?: boolean;
}

interface FormValueProps {
  deliveryMethod: DeliveryMethod | undefined;
  email: MessageTemplateEmail | undefined;
  sms: MessageTemplateSms | undefined;
}

export interface SubmitProps {
  initialValues: FormValueProps;
}

export interface ReviewsError {
  credentialError?: boolean;
  generalError?: boolean;
}
