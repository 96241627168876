import { defineMessages } from "react-intl";

export const messages = defineMessages({
  // ChatWithSuccessButton component
  chatWithSuccessButtonLabel: {
    id: "jobberCopilot.chatWithSuccessButton.label",
    defaultMessage: "Chat with Success",
    description: "Chat with success button label",
  },
  // Feedback component
  feedbackModalTitle: {
    id: "jobberCopilot.feedback.modalTitle",
    defaultMessage: "Send feedback",
    description: "Feedback modal title",
  },
  feedbackModalPrimaryAction: {
    id: "jobberCopilot.feedback.modalPrimaryActionLabel",
    defaultMessage: "Send Feedback",
    description: "Feedback modal submit button",
  },
  feedbackModalSecondaryAction: {
    id: "jobberCopilot.feedback.feedbackModalSecondaryAction",
    defaultMessage: "Cancel",
    description: "Feedback modal cancel button",
  },
  feedbackSavingError: {
    id: "jobberCopilot.feedback.savingError",
    defaultMessage:
      "There was an error saving your feedback. Please try again.",
    description: "Feedback saving error message",
  },
  feedbackModalPrompt: {
    id: "jobberCopilot.feedback.modalPrompt",
    defaultMessage: "Was this response from Jobber Copilot helpful?",
    description: "Feedback label",
  },
  feedbackInputPlaceholder: {
    id: "jobberCopilot.feedback.inputPlaceholder",
    defaultMessage: "Briefly provide us some feedback around the response",
    description: "Feedback input placeholder",
  },
  feedbackAcknowledgement: {
    id: "jobberCopilot.feedback.acknowledgement.",
    defaultMessage: "Thanks for your feedback",
    description: "Thanks for feedback message",
  },
  // JobberAssistant component
  jobberCopilotInputPlaceholder: {
    id: "jobberCopilot.jobberCopilot.inputPlaceholder",
    defaultMessage: "Ask a question...",
    description: "Copilot input placeholder",
  },
  jobberCopilotDisclaimer: {
    id: "jobberCopilot.jobberCopilot.disclaimer",
    defaultMessage: "Copilot can make mistakes. Double check important info.",
    description: "Copilot disclaimer",
  },
  // SupportQuestionFollowup component
  supportQuestionFollowupPrompt: {
    id: "jobberCopilot.supportQuestionFollowup.prompt",
    defaultMessage: "Did that answer your question?",
    description: "Support question feedback prompt",
  },
  supportQuestionFollowupSavingError: {
    id: "jobberCopilot.supportQuestionFollowup.savingError",
    defaultMessage:
      "There was an error saving your feedback. Please try again.",
    description: "Support question feedback saving error message",
  },
  supportQuestionFollowupAcknowledgement: {
    id: "jobberCopilot.supportQuestionFollowup.acknowledgement.",
    defaultMessage: "Thanks for your feedback",
    description: "Thanks for feedback message",
  },
  supportQuestionFollowupPositive: {
    id: "jobberCopilot.supportQuestionFollowup.positive.",
    defaultMessage: "That helped. 👍",
    description: "Positive feedback for the support question response",
  },
  supportQuestionFollowupSupport: {
    id: "jobberCopilot.supportQuestionFollowup.support",
    defaultMessage: "Connect with support. 👤",
    description:
      "Option to connect with support after the support question response",
  },
});
