import { Modal } from "@jobber/components/Modal";
import React, { type Dispatch, type PropsWithChildren, useState } from "react";
import { Content } from "@jobber/components/Content";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { Banner } from "@jobber/components/Banner";
import { SuccessContent } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/SuccessContent";
import { useSelectAndSavePhoneNumber } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/hooks/useSelectAndSavePhoneNumber";
import { RegistrationContent } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/RegistrationContent";
import { ChooseNumberContent } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/ChooseNumberContent";
import { TwilioRegistrationForm } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/TwilioRegistrationForm";
import { useTwilioRegistrationData } from "jobber/settings/dedicatedPhoneNumber/hooks/useTwilioRegistrationData";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { dpnIsNoLongerAvailableError } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/errorConstants";
import type { MutationErrors } from "~/utilities/API/graphql";

interface SelectAndSaveProps {
  title: string;
  open: boolean;
  phoneNumberOptions: string[];
  requiresRegistration: boolean;
  onRequestClose(): void;
  handleAfterSave?(newValue?: string | undefined): void;
  getPhoneNumberOptions(): void;
}

export function SelectAndSaveModal({
  title,
  open,
  phoneNumberOptions,
  requiresRegistration,
  onRequestClose,
  handleAfterSave,
  getPhoneNumberOptions,
  children,
}: PropsWithChildren<SelectAndSaveProps>) {
  const [complianceTermsOfService, setComplianceTermsOfService] =
    useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(phoneNumberOptions[0]);
  const [showToSBanner, setShowToSBanner, hideBanner] = useShowBanner();

  const {
    loading,
    handleSubmit,
    handleUpdate,
    state: formState,
    savedNumber,
  } = useTwilioRegistrationData(requiresRegistration);

  const {
    dynamicTitle,
    primaryLabel,
    tertiaryLabel,
    content,
    step,
    totalSteps,
    moveToNextStep,
    moveToPreviousStep,
    goToErrorState,
  } = useSelectAndSavePhoneNumber(title, requiresRegistration);

  const {
    showConfirmationModal,
    handleCancelConfirmationModal,
    handleLeaveConfirmationModal,
    handleAttemptClose,
  } = useConfirmationModalState(onRequestClose, content);

  const primaryActions = [
    handleDpnSelection,
    savePhoneNumberWithCarrierRegistration,
    () => successRedirect(requiresRegistration),
  ];

  return (
    <>
      {!showConfirmationModal && (
        <Modal
          title={dynamicTitle}
          open={open && phoneNumberOptions.length > 0}
          onRequestClose={handleAttemptClose}
          primaryAction={{
            label: primaryLabel,
            onClick: primaryActions[step - 1],
            loading: loading,
          }}
          tertiaryAction={{
            label: tertiaryLabel,
            onClick: handleTertiaryClick,
            variation: "subtle",
            type: "primary",
          }}
        >
          <Content>
            {formState.generalError && (
              <Banner type="error" onDismiss={hideGeneralError}>
                {formState.generalError}
              </Banner>
            )}
            {showToSBanner &&
              content === "ChooseNumber" &&
              !formState.generalError && (
                <Banner type="error" onDismiss={hideBanner}>
                  In order to continue, you must agree to the Terms of Service
                </Banner>
              )}

            <ProgressBar currentStep={step} totalSteps={totalSteps} />
            {content === "ChooseNumber" && (
              <>
                {children}
                <ChooseNumberContent
                  phoneNumberOptions={phoneNumberOptions}
                  selectedPhoneNumber={phoneNumber}
                  termsOfServiceChecked={complianceTermsOfService}
                  requiresTermsOfService={true}
                  requiresRegistration={requiresRegistration}
                  onTermsOfServiceChanged={setComplianceTermsOfService}
                  onPhoneNumberChosen={setPhoneNumber}
                />
              </>
            )}
            {content === "Registration" && (
              <RegistrationContent savedPhoneNumber={phoneNumber}>
                <TwilioRegistrationForm
                  loading={false}
                  state={formState}
                  handleUpdate={handleUpdate}
                />
              </RegistrationContent>
            )}
            {content === "DpnIsTaken" && (
              <ChooseNumberContent
                phoneNumberOptions={phoneNumberOptions}
                selectedPhoneNumber={phoneNumber}
                termsOfServiceChecked={complianceTermsOfService}
                requiresRegistration={false}
                requiresTermsOfService={false}
                onTermsOfServiceChanged={setComplianceTermsOfService}
                onPhoneNumberChosen={setPhoneNumber}
              />
            )}
            {content === "Success" && (
              <SuccessContent
                phoneNumber={savedNumber}
                requiresRegistration={requiresRegistration}
              />
            )}
          </Content>
        </Modal>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Leave Registration?"
          message={`By closing this form, the phone number selected will not be assigned to your business.`}
          confirmLabel="LEAVE"
          open={showConfirmationModal}
          onConfirm={handleLeaveConfirmationModal}
          onCancel={handleCancelConfirmationModal}
        />
      )}
    </>
  );

  function handleDpnSelection() {
    if (complianceTermsOfService) {
      hideBanner();
      if (requiresRegistration) {
        // DPN is saved into state.
        moveToNextStep();
      } else {
        savePhoneNumberWithCarrierRegistration();
      }
    } else {
      setShowToSBanner(true);
    }
  }

  function handleTertiaryClick() {
    if (content === "Registration") {
      handleUpdate({ generalError: undefined, smsablePhoneError: undefined });
      moveToPreviousStep();
    } else {
      handleAttemptClose();
    }
  }

  function hideGeneralError() {
    handleUpdate({ generalError: undefined });
  }

  function savePhoneNumberWithCarrierRegistration() {
    handleSubmit(phoneNumber)
      .then(
        (response: {
          mutationErrors: MutationErrors[];
          responseNumber: string;
        }) => {
          const isDpnNoLongerAvailableError =
            response.mutationErrors?.length &&
            response.mutationErrors.some(
              error => error.message === dpnIsNoLongerAvailableError.message,
            );
          if (isDpnNoLongerAvailableError) {
            getPhoneNumberOptions();
            goToErrorState(true);
          } else if (!response.mutationErrors?.length) {
            if (handleAfterSave) handleAfterSave(response.responseNumber);
            setPhoneNumber(response.responseNumber);
            moveToNextStep();
          }
        },
      )
      .catch((err: Error) => {
        if (
          err.toString() !== "Invalid address provided" &&
          err.toString() !== "Form submitted without valid state."
        ) {
          Rollbar.EXECUTE("Error submitting 10 DPN registration", err);
        }
      });
  }
}

function useConfirmationModalState(
  onRequestClose: () => void,
  content: string,
) {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  function handleCancelConfirmationModal() {
    setShowConfirmationModal(false);
  }

  function handleLeaveConfirmationModal() {
    setShowConfirmationModal(false);
    onRequestClose();
  }
  function handleAttemptClose() {
    if (content === "Registration") {
      setShowConfirmationModal(true);
    } else {
      onRequestClose();
      if (content === "Success") {
        window.location.reload();
      }
    }
  }
  return {
    showConfirmationModal,
    handleCancelConfirmationModal,
    handleLeaveConfirmationModal,
    handleAttemptClose,
  };
}

function useShowBanner(): [boolean, Dispatch<boolean>, () => void] {
  const [showToSBanner, setShowToSBanner] = useState<boolean>(false);
  function hideBanner() {
    setShowToSBanner(false);
  }
  return [showToSBanner, setShowToSBanner, hideBanner];
}

function successRedirect(requiresRegistration: boolean) {
  if (requiresRegistration) {
    window.open(
      "https://help.getjobber.com/hc/en-us/articles/1500008684501-Register-Your-Number",
      "_blank",
    );
  } else {
    Amplitude.TRACK_EVENT("Clicked Navigation Button", {
      source: "DedicatedPhoneNumberSavedModal",
      destination: "/client_template_settings",
    });

    window.location.href = "/client_template_settings";
  }
}
