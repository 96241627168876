import { isDevelopment, isTest } from "utilities/environment";

const shouldAssertTypename = isDevelopment() || isTest();

/**
 * Typenames are added by the graphql-codegen plugin, but they are always
 * optional. This function asserts that the typename is present. Using this
 * function is preferable to using the non-null assertion operator (!) because
 * our linter prevents us from using the non-null assertion operator, and we
 * don't want to change that.
 *
 * An alternate approach would be to explicitly add the __typename property to
 * your graphql fragments, which causes those generated types to not be
 * optional. But that causes problems when generated fragments have a required
 * __typename property, but the generated schema keeps them optional.
 *
 * We tried setting the codegen to use non-optional typenames everywhere, but
 * that caused problems with interfaces generated by the schema to not match the
 * interfaces generated by the fragments/operations.
 */
export function assertHasTypename(
  value: unknown,
): asserts value is { __typename: string } {
  if (!shouldAssertTypename) {
    return;
  }

  if (!value || typeof value !== "object" || !("__typename" in value)) {
    throw new Error("Expected value to have a __typename property");
  }
}
