export interface TooltipData {
  id: string | number;
  value?: number | null;
  indexValue?: string | number;
  data: Record<string, string | number | Date | undefined>;
}

export interface Iso8601DateRange {
  after: string;
  before: string;
}

export interface DateRange {
  startAt: Date;
  endAt: Date;
}

export interface LineChartTooltipNode {
  timePeriod: string;
  xAxisTick?: string;
}

export enum ChartDataAggregationInterval {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}
