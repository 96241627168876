import { defineMessages } from "react-intl";

const profilePageFormIdPrefix = "setupWizard.profilePageForm.";

export const messages = defineMessages({
  fullNamePlaceholder: {
    id: `${profilePageFormIdPrefix}fullNamePlaceholder`,
    defaultMessage: "First and last name",
    description: "Account owner full name for the setup wizard",
  },
  fullNameError: {
    id: `${profilePageFormIdPrefix}fullNameError`,
    defaultMessage: "Please fill in your first and last name",
    description: "Full name error for the setup wizard",
  },
  phoneNumberPlaceholder: {
    id: `${profilePageFormIdPrefix}phoneNumberPlaceholder`,
    defaultMessage: "Phone number",
    description: "Phone number for the setup wizard",
  },
  smsOptIn: {
    id: `${profilePageFormIdPrefix}smsOptIn`,
    defaultMessage:
      "I would like to receive SMS marketing notifications from Jobber. You can unsubscribe at any time. For more details, review our [Privacy Policy](https://getjobber.com/privacy-policy/).",
    description: "SMS opt in for the setup wizard",
  },
  smsOptInUSVerbiage: {
    id: `${profilePageFormIdPrefix}smsOptInUSVerbiage`,
    defaultMessage:
      "I understand that Jobber may contact my business by SMS and phone using the information I have provided. For SMS: message & data rates may vary; for help, reply HELP; and to opt-out, reply STOP. [Privacy Policy](https://getjobber.com/privacy-policy/).",
    description: "SMS opt in for the setup wizard",
  },
});
