export const messages = {
  selectProductOrService: {
    title: "Select a Product / Service",
    description: "Product or service search modal header",
  },
  updateAndAdd: {
    title: "Update & Add",
    description: "Save button label for existing service becoming bookable",
  },
  createAndAdd: {
    title: "Create & Add",
    description: "Save button label when creating a new bookable service",
  },
  addNewProductService: {
    title: "Add New Product / Service",
    description: "Modal title when creating a new bookable service",
  },
  add: {
    title: "Add",
    description: "Modal title prefix when updating an existing service",
  },
};
