import { Button, Content, Text } from "@jobber/components";
import React from "react";
import type { MessageDescriptor } from "react-intl";
import { useIntl } from "react-intl";
import styles from "./CustomizationCTA.module.css";
import { messages } from "./messages";

interface CustomizationCTAProps {
  message: MessageDescriptor;
  onClick: () => void;
}

export function CustomizationCTA({
  message,
  onClick,
}: CustomizationCTAProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <Content spacing="small">
        <Text align={"start"} size={"small"}>
          {formatMessage(message)}
        </Text>
        <Button
          icon={"longArrowRight"}
          iconOnRight={true}
          variation={"subtle"}
          label={formatMessage(messages.useItNowButtonLabel)}
          onClick={onClick}
        ></Button>
      </Content>
    </div>
  );
}
