import { useMutation } from "@apollo/client";
import { UPDATE_UK_KYC_REGISTRATION } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/hooks/useUkKycRegistrationUpdate/useUkKycRegistrationUpdate.graphql";
import type {
  MutationCommsUkKycRegistrationUpdateArgs,
  UpdateUkKycRegistrationMutation,
} from "~/utilities/API/graphql";

export function useUkKycRegistrationUpdate() {
  const [updateUkKycRegistrationMutation, { loading, error, data }] =
    useMutation<UpdateUkKycRegistrationMutation>(UPDATE_UK_KYC_REGISTRATION);

  const ukKycRegistrationUpdate = async (
    input: MutationCommsUkKycRegistrationUpdateArgs,
  ) => {
    const result = await updateUkKycRegistrationMutation({
      variables: input,
    });
    const userErrors =
      result.data?.commsUkKycRegistrationUpdate?.userErrors ?? [];

    return {
      ukKycRegistration:
        result.data?.commsUkKycRegistrationUpdate?.ukKycRegistration,
      userErrors,
    };
  };

  return {
    ukKycRegistrationUpdate,
    loading,
    error,
    data,
  };
}
