import { defineMessages } from "react-intl";

export const messages = defineMessages({
  upcomingPayoutsHeading: {
    id: "managedAccount.upcomingPayouts.heading",
    defaultMessage: "Upcoming payouts",
    description: "Heading for upcoming payouts section",
  },
  onitsWay: {
    id: "managedAccount.depositedPayouts.onitsWay",
    defaultMessage: "On its way to your bank",
    description: "Title for this week deposited payouts",
  },
  onitsWayToolTipText: {
    id: "managedAccount.depositedPayouts.onitsWayToolTipText",
    defaultMessage: "Payouts that are on their way to your bank",
    description: "Tooltip text for on its way to your bank",
  },
  expectedOn: {
    id: "managedAccount.depositedPayouts.expectedOn",
    defaultMessage: " Expected on ",
    description: "Expected date for deposited payouts",
  },
  allUpcomingPayouts: {
    id: "managedAccount.depositedPayouts.allUpcomingPayouts",
    defaultMessage: "View all upcoming payouts",
    description: "Label for all upcoming payouts button",
  },
  upcomingPayoutsModalHeading: {
    id: "managedAccount.depositedPayouts.upcomingPayoutsModalHeading",
    defaultMessage: "Upcoming payouts",
    description: "Heading for upcoming payouts modal",
  },
  processingPayout: {
    id: "managedAccount.depositedPayouts.processingPayout",
    defaultMessage: "Processing payout",
    description: "Label for processing payouts",
  },
  processingPayoutToolTipText: {
    id: "managedAccount.depositedPayouts.processingPayoutToolTipText",
    defaultMessage:
      "Payouts that are still being processed before they make their way to your bank",
    description: "Tooltip text for processing payout",
  },
  dailyLimitReachedToolTipText: {
    id: "managedAccount.depositedPayouts.dailyLimitReachedToolTipText",
    defaultMessage:
      "You have reached the maximum amount of $10,000 you can withdraw today.",
    description:
      "Tooltip text for processing payout when daily limit is reached",
  },
  minimumBalanceToolTipText: {
    id: "managedAccount.depositedPayouts.minimumBalanceToolTipText",
    defaultMessage: "The minimum balance for instant payout is $50.",
    description:
      "Tooltip text for processing payout when balance is below minimum",
  },
  getItNow: {
    id: "managedAccount.depositedPayouts.getItNow",
    defaultMessage: "Get it now",
    description: "Label for get it now button",
  },
});
