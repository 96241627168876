import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalHeader: {
    id: "customFieldArchiveModal.modalHeader",
    defaultMessage: "Archive {appName} custom field",
    description: "Text to display in the modal title",
  },
  modalInitialContent: {
    id: "customFieldArchiveModal.modalInitialContent",
    defaultMessage:
      "Archiving will hide this custom field from any new {workObjectName} created, but it will stay visible on current and past {workObjectName}.",
    description: "Initial content within the modal",
  },
  modalEndingContent: {
    id: "customFieldArchiveModal.modalEndingContent",
    defaultMessage:
      "You can restore this archived custom field later or permanently delete it.",
    description: "Ending content within the modal",
  },
  appModalArchivedHeader: {
    id: "customFieldArchiveModal.appModalArchivedHeader",
    defaultMessage: "Disconnect {appName} to archive custom fields",
    description: "Text to display in the modal title",
  },
  appModalUnarchivedHeader: {
    id: "customFieldArchiveModal.appModalUnarchivedHeader",
    defaultMessage: "Reconnect {appName} to unarchive custom fields",
    description: "Text to display in the modal title",
  },
  appModalArchivedIntitialContent: {
    id: "customFieldArchiveModal.appModalArchivedInitialContent",
    defaultMessage:
      "To archive {appName} custom fields, disconnect the app from your Jobber account.",
    description: "Initial content within the modal",
  },
  appModalArchivedEndingContent: {
    id: "customFieldArchiveModal.appModalArchivedEndingContent",
    defaultMessage:
      "The custom fields will be archived automatically once the app is disconnected.",
    description: "Ending content within the modal",
  },
  appModalUnarchivedContent: {
    id: "customFieldArchiveModal.appModalUnarchivedContent",
    defaultMessage:
      "To unarchive {appName} custom fields, reconnect the app first.",
    description: "Content within the modal",
  },
});
