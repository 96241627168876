import { defineMessages } from "react-intl";

export const messages = defineMessages({
  onHoverMarkerHint: {
    id: "selfServeBookings.GoogleMaps.onHoverMarkerHint",
    defaultMessage: "Drag to change location",
    description:
      "The hint that appears when hovering over the marker on the map",
  },
});
