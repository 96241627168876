import { useIntl } from "react-intl";
import { useMemo } from "react";
import { Segment } from "~/utilities/API/graphql";
import type {
  AllClientsCriteriaInput,
  PastClientsCriteriaInput,
  Template,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import type { ClientSegmentsData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/messages";
import {
  PAST_CLIENT_DEFAULT_INTERVAL,
  PAST_CLIENT_DEFAULT_UNIT,
  UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL,
  UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT,
} from "jobber/campaigns/constants";
import {
  type ClientsCriteriaFromSegment,
  isPastClientCriteriaInput,
  isUpcomingClientCriteriaInput,
} from "jobber/campaigns/utils/segmentCriteriaUtils";
import type { SelectableCardSimplified } from "jobber/campaigns/components/SelectableCards/SelectableCard/SelectableCard";
import { getRecommendedClientSegment } from "./utils";

interface useClientSegmentOptionsProps {
  clientsCriteriaInput: {
    allClientsSegmentCriteria: ClientsCriteriaFromSegment;
    pastClientsSegmentCriteria: ClientsCriteriaFromSegment;
    upcomingClientsSegmentCriteria: ClientsCriteriaFromSegment;
  };
  clientSegments: ClientSegmentsData;
  selectedSegmentType: Segment;
  templateType: Template;
}

export interface UseClientSegmentOptionsType extends SelectableCardSimplified {
  type: Segment;
}

export function useClientSegmentOptions({
  clientsCriteriaInput,
  clientSegments,
  selectedSegmentType,
  templateType,
}: useClientSegmentOptionsProps): UseClientSegmentOptionsType[] {
  const { formatMessage } = useIntl();
  const { upsellCampaignSplit } = useCampaignUpsellSplit();

  // eslint-disable-next-line max-statements
  const pastClientsDescription = useMemo(() => {
    const pastClientsCriteriaInput =
      clientsCriteriaInput.pastClientsSegmentCriteria;

    const isPastClientCriteria = isPastClientCriteriaInput(
      pastClientsCriteriaInput,
    );

    const interval = isPastClientCriteria
      ? pastClientsCriteriaInput?.interval.toString()
      : PAST_CLIENT_DEFAULT_INTERVAL;

    const _unit = isPastClientCriteria
      ? pastClientsCriteriaInput?.unit
      : PAST_CLIENT_DEFAULT_UNIT;
    const unit = _unit.toLocaleLowerCase().replace(/s$/, "");

    let additionalCriteriaCount = 0;
    if (pastClientsCriteriaInput && isPastClientCriteria) {
      const pastClientCriteria =
        pastClientsCriteriaInput as PastClientsCriteriaInput;
      const clientTagCount = pastClientCriteria.clientTagCriteria?.length || 0;
      const jobTypeCount = pastClientCriteria.jobTypeCriteria?.length || 0;
      const lineItemCriteriaCount =
        pastClientCriteria.lineItemCriteria?.length || 0;
      additionalCriteriaCount =
        clientTagCount + jobTypeCount + lineItemCriteriaCount;
    }

    return formatMessage(messages.pastClientsDescription, {
      unit,
      interval,
      additionalCriteriaCount,
    });
  }, [clientsCriteriaInput, formatMessage]);

  // eslint-disable-next-line max-statements
  const upcomingClientDescription = useMemo(() => {
    const upcomingClientsCriteriaInput =
      clientsCriteriaInput.upcomingClientsSegmentCriteria;

    const isUpcomingClientCriteria = isUpcomingClientCriteriaInput(
      upcomingClientsCriteriaInput,
    );

    const interval = isUpcomingClientCriteria
      ? upcomingClientsCriteriaInput?.interval.toString()
      : UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL;

    const _unit = isUpcomingClientCriteria
      ? upcomingClientsCriteriaInput?.unit
      : UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT;
    const unit = _unit.toLocaleLowerCase().replace(/s$/, "");

    let additionalCriteriaCount = 0;
    if (clientsCriteriaInput && isUpcomingClientCriteria) {
      const upcomingClientCriteria =
        upcomingClientsCriteriaInput as UpcomingClientsCriteriaInput;
      const clientTagCount =
        upcomingClientCriteria.clientTagCriteria?.length || 0;
      const jobTypeCount = upcomingClientCriteria.jobTypeCriteria?.length || 0;
      const lineItemCriteriaCount =
        upcomingClientCriteria.lineItemCriteria?.length || 0;
      const jobStatusCount =
        upcomingClientCriteria.jobStatusCriteria?.length || 0;
      additionalCriteriaCount =
        clientTagCount + jobTypeCount + lineItemCriteriaCount + jobStatusCount;
    }

    return formatMessage(messages.upcomingVisitClientsDescription, {
      interval,
      unit,
      additionalCriteriaCount,
    });
  }, [clientsCriteriaInput, formatMessage]);

  const allClientsCriteriaInput =
    clientsCriteriaInput.allClientsSegmentCriteria;

  const allClientDescription = useMemo(() => {
    let additionalCriteriaCount = 0;
    const allClientCriteria =
      allClientsCriteriaInput as AllClientsCriteriaInput;
    const clientTagCount: number =
      allClientCriteria?.clientTagCriteria?.length || 0;
    const clientTypeCount: number =
      allClientCriteria?.clientTypeCriteria?.length || 0;
    additionalCriteriaCount = clientTagCount + clientTypeCount;

    return formatMessage(messages.allClientsDescription, {
      additionalCriteriaCount,
    });
  }, [allClientsCriteriaInput, formatMessage]);

  const segmentOptions = useMemo<UseClientSegmentOptionsType[]>(() => {
    const recommendedClientSegment = getRecommendedClientSegment(templateType);
    return [
      {
        type: Segment.ALL_CLIENTS,
        heading: formatMessage(messages.allClientsTitle),
        description: allClientDescription,
        subDescription:
          !upsellCampaignSplit || selectedSegmentType !== Segment.ALL_CLIENTS
            ? ""
            : formatMessage(messages.clients, {
                totalCount: clientSegments.allClients.total,
              }),
        value: Segment.ALL_CLIENTS,
        checked: false,
        additionalButtonText: formatMessage(messages.edit),
      },
      {
        type: Segment.UPCOMING_CLIENTS,
        heading: formatMessage(messages.upcomingVisitClientsTitle),
        description: upcomingClientDescription,
        subDescription:
          selectedSegmentType === Segment.UPCOMING_CLIENTS
            ? formatMessage(messages.clients, {
                totalCount: clientSegments.upcomingClients.total,
              })
            : "",
        value: Segment.UPCOMING_CLIENTS,
        checked: false,
        additionalButtonText: formatMessage(messages.edit),
        recommended: recommendedClientSegment === Segment.UPCOMING_CLIENTS,
      },
      {
        type: Segment.PAST_CLIENTS,
        heading: formatMessage(messages.pastClientsTitle),
        description: pastClientsDescription,
        subDescription:
          selectedSegmentType === Segment.PAST_CLIENTS
            ? formatMessage(messages.clients, {
                totalCount: clientSegments.pastClients.total,
              })
            : "",
        value: Segment.PAST_CLIENTS,
        checked: true,
        additionalButtonText: formatMessage(messages.edit),
        recommended: recommendedClientSegment === Segment.PAST_CLIENTS,
      },
    ];
  }, [
    allClientDescription,
    clientSegments.allClients.total,
    clientSegments.pastClients.total,
    clientSegments.upcomingClients.total,
    selectedSegmentType,
    formatMessage,
    pastClientsDescription,
    upcomingClientDescription,
    upsellCampaignSplit,
    templateType,
  ]);

  return segmentOptions;
}
