import { csrfToken } from "utilities/csrfToken";
import type { SubmissionFieldsInterface } from "./SubmissionFieldsInterface.d";
import type { ResponseDataInterface } from "./ResponseDataInterface.d";

export async function saveUserCredentials(
  formSubmitPath: string,
  submissionFields: SubmissionFieldsInterface,
  hiddenFields?: object,
): Promise<ResponseDataInterface> {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken,
  };

  const payload = {
    user: { ...submissionFields },
    ...hiddenFields,
  };

  const response = await fetch(formSubmitPath, {
    headers,
    body: JSON.stringify(payload),
    method: "PUT",
  });

  return response.json();
}
