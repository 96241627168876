/* eslint-disable import/no-internal-modules */
import { gql } from "@apollo/client";
import {
  ONLINE_BOOKING_DEFAULT_SERVICE_AREA_FRAGMENT,
  ONLINE_BOOKING_SERVICE_AREA_FRAGMENT,
} from "../components/ServiceAreaConfiguration/hooks/graphql";

export const ONLINE_BOOKING_CONFIGURATION_FRAGMENT = gql`
  fragment OnlineBookingConfiguration on OnlineBookingConfiguration {
    acceptingOnlineBookings
    bookingUrl
    confirmationMessage
    googleMeasurementId
    id
    serviceAreasEnabled
    serviceAreas {
      edges {
        node {
          id
          ...OnlineBookingServiceArea
        }
      }
      totalCount
    }
    spTermsAndConditionsUrl
  }
  ${ONLINE_BOOKING_SERVICE_AREA_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    avatar {
      initials
      image {
        photoUrl
      }
    }
    name {
      full
    }
  }
`;

export const ACTIVE_USERS_FRAGMENT = gql`
  fragment ActiveUsers on Query {
    users(filter: { status: ACTIVATED }) {
      nodes {
        ...User
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const ONLINE_BOOKING_SCHEDULE_FRAGMENT = gql`
  fragment OnlineBookingSchedule on OnlineBookingScheduleSettings {
    id
    bookableUsers {
      nodes {
        ...User
      }
      totalCount
    }
    bufferDurationMinutes
    earliestAvailabilityMinutes
    intervalDurationMinutes
    maxDriveTimeMinutes
    useBufferTime
    useDriveTime
  }

  ${USER_FRAGMENT}
`;

export const ONLINE_BOOKING_GOOGLE_SETTINGS_FRAGMENT = gql`
  fragment OnlineBookingGoogleSharingSettings on OnlineBookingGoogleSettings {
    id
    enabled
    updatedAt
  }
`;

export const EDIT_SELF_SERVE_SETTINGS_MUTATION = gql`
  mutation onlineBookingConfigurationEdit(
    $input: OnlineBookingConfigurationAttributes!
  ) {
    onlineBookingConfigurationEdit(input: $input) {
      onlineBookingConfiguration {
        ...OnlineBookingConfiguration
      }
      userErrors {
        message
      }
    }
  }
  ${ONLINE_BOOKING_CONFIGURATION_FRAGMENT}
`;

export const EDIT_SELF_SERVE_SCHEDULE_MUTATION = gql`
  mutation onlineBookingScheduleSettingsEditV2(
    $input: OnlineBookingScheduleSettingsEditInput!
  ) {
    onlineBookingScheduleSettingsEdit(input: $input) {
      onlineBookingScheduleSettings {
        ...OnlineBookingSchedule
      }
      userErrors {
        message
      }
    }
  }
  ${ONLINE_BOOKING_SCHEDULE_FRAGMENT}
`;

export const EDIT_SELF_SERVE_GOOGLE_SETTINGS_MUTATION = gql`
  mutation onlineBookingGoogleSettingsEdit(
    $input: OnlineBookingGoogleSettingsAttributes!
  ) {
    onlineBookingGoogleSettingsEdit(input: $input) {
      onlineBookingGoogleSettings {
        ...OnlineBookingGoogleSharingSettings
      }
      userErrors {
        message
      }
    }
  }
  ${ONLINE_BOOKING_GOOGLE_SETTINGS_FRAGMENT}
`;

export const SELF_SERVE_SETTINGS_QUERY = gql`
  query onlineBookingConfiguration {
    onlineBookingConfiguration {
      ...OnlineBookingConfiguration
    }
    onlineBookingScheduleSettings {
      ...OnlineBookingSchedule
    }
    onlineBookingGoogleSettings {
      ...OnlineBookingGoogleSharingSettings
    }
    onlineBookingServices {
      totalCount
    }
    account {
      ...OnlineBookingDefaultServiceArea
      franchise {
        leadRoutingEnabled
      }
    }
    driveTimeFeature: feature(key: "drive_time") {
      id
      enabled
    }
    ...ActiveUsers
  }
  ${ACTIVE_USERS_FRAGMENT}
  ${ONLINE_BOOKING_CONFIGURATION_FRAGMENT}
  ${ONLINE_BOOKING_SCHEDULE_FRAGMENT}
  ${ONLINE_BOOKING_DEFAULT_SERVICE_AREA_FRAGMENT}
  ${ONLINE_BOOKING_GOOGLE_SETTINGS_FRAGMENT}
`;

export const INITIALIZE_SETTINGS_MUTATION = gql`
  mutation OnlineBookingSettingsInitialize {
    onlineBookingSettingsInitialize {
      onlineBookingConfiguration {
        ...OnlineBookingConfiguration
      }
      onlineBookingScheduleSettings {
        ...OnlineBookingSchedule
      }
      userErrors {
        message
      }
    }
  }
  ${ONLINE_BOOKING_CONFIGURATION_FRAGMENT}
  ${ONLINE_BOOKING_SCHEDULE_FRAGMENT}
`;

export const COMPANY_DETAILS_FOR_GOOGLE = gql`
  query CompanyDetailsForGoogle {
    account {
      id
      companyDetails {
        name
        address
        phone
        websiteUrl
      }
      settings {
        localization {
          countryCode
        }
      }
    }
  }
`;
