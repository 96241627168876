import type { CreditCardInfo } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/types";
import type { Recurrence } from "jobber/workOrders/components/Scheduler/types";

export enum InvoiceCreationPreference {
  RemindMe,
  AutomaticallyCreateAndCharge,
}

export enum IssuingPreference {
  EachVisit,
  OnePrice,
}

export enum InvoicePeriod {
  AfterEachVisit,
  LastDayOfMonth,
  OnJobClose,
  AsNeeded,
  Custom,
}

export interface InvoiceSchedulerState {
  jobberPaymentsEnabled: boolean;
  creditCardInfo?: CreditCardInfo;
  canUseAutomaticPayments: boolean;
  schedule: InvoicePeriod;
  invoiceCreationPreference: InvoiceCreationPreference;
  issuingPreference?: IssuingPreference;
  startDate: Date;
  recurrence: Recurrence;
  invoiceOnJobComplete: boolean;
}
