import { csrfToken } from "utilities/csrfToken";

export async function sendCoachingReminderEmail(email: string) {
  const url =
    "/accounts/billing_info/training_session_email_reminder_account_claim";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ email: email }),
  });

  return (await response.json()) as {
    message: string;
    success: boolean;
  };
}
