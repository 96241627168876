export const messages = {
  REQUEST: {
    title: "Requests",
    description: "Includes reminders",
  },
  QUOTE: {
    title: "Quotes",
    description: "Includes reminders, change requests, and approvals",
  },
  JOB: {
    title: "Jobs",
    description:
      "Includes reminders, booking confirmations, job forms, and job follow-ups",
  },
  INVOICE: {
    title: "Invoices",
    description: "Includes reminders, and receipts",
  },
};
