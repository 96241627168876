import { useQuery } from "@apollo/client";
import type { CampaignCompanyInformationQuery } from "~/utilities/API/graphql";
import { GET_CAMPAIGN_COMPANY_INFORMATION } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export function useCampaignCompanyInformation() {
  const { loading, data } = useQuery<CampaignCompanyInformationQuery>(
    GET_CAMPAIGN_COMPANY_INFORMATION,
  );

  return {
    loadingCompanyInformation: loading,
    name: data?.account?.name || "",
    logoUrl: data?.account?.companyDetails?.logoUrl || "",
    address: data?.account?.companyDetails?.address || "",
    street1: data?.account?.companyDetails?.street1 || "",
    street2: data?.account?.companyDetails?.street2 || "",
    city: data?.account?.companyDetails?.city || "",
    province: data?.account?.companyDetails?.province || "",
    postalCode: data?.account?.companyDetails?.postalCode || "",
    country: data?.account?.companyDetails?.country || "",
  };
}
