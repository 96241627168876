import React from "react";
import { Icon } from "@jobber/components/Icon";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Text } from "@jobber/components/Text";
import styles from "./ArrivalWindowStyleOptions.module.css";

interface ArrivalWindowStyleOptionsProps {
  centered: boolean;
  onCenteredChange(val: boolean): void;
}

export function ArrivalWindowStyleOptions({
  centered,
  onCenteredChange,
}: ArrivalWindowStyleOptionsProps): JSX.Element {
  const onChange = (value: "centered" | "flush") => {
    onCenteredChange(value === "centered");
  };
  return (
    <>
      <RadioGroup
        value={centered ? "centered" : "flush"}
        ariaLabel="Add window after start time"
        onChange={onChange}
      >
        <RadioOption value="flush">
          <div className={styles["icon-container"]}>
            <Icon name="sliderStart" />
            <Text>Add window after start time</Text>
            <span className={styles["description-text"]}>
              <Text variation="subdued">ex. 9:00 AM – 10:00 AM</Text>
            </span>
          </div>
        </RadioOption>
        <RadioOption value="centered">
          <div className={styles["icon-container"]}>
            <Icon name="sliderCenter" />
            <Text>Center window on start time</Text>
            <span className={styles["description-text"]}>
              <Text variation="subdued">ex. 8:30 AM – 9:30 AM</Text>
            </span>
          </div>
        </RadioOption>
      </RadioGroup>
    </>
  );
}
