import { Modal } from "@jobber/components/Modal";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import React from "react";
import { useMutation } from "@apollo/client";
import { JobberPaymentsSettingsName } from "~/utilities/API/graphql";
import type {
  JobberPaymentsDefaultPaymentPreference,
  UpdateJobberPaymentsSettingsMutation,
} from "~/utilities/API/graphql";
import { messages } from "./messages";
import { UPDATE_SETTING_MUTATION } from "../SettingsSwitch";

const genericError = new Error("Could not update settings");
export interface ConfirmACHModalProps {
  isOpen: boolean;
  loading?: boolean;
  onRequestClose: () => void;
  onError(error: Error): void;
  updateDefaultPreference(
    value: JobberPaymentsDefaultPaymentPreference,
  ): Promise<void>;
  radioOptionChosen: JobberPaymentsDefaultPaymentPreference;
  setIsAchEnabled?: (value: boolean) => void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ConfirmACHModal(props: ConfirmACHModalProps) {
  const {
    isOpen,
    onRequestClose,
    loading,
    onError,
    updateDefaultPreference,
    radioOptionChosen,
    setIsAchEnabled,
    setToggleAchFlag,
  } = props;
  const { formatMessage } = useIntl();
  const [updateSettings, { loading: achLoading }] =
    useMutation<UpdateJobberPaymentsSettingsMutation>(UPDATE_SETTING_MUTATION);

  return (
    <Modal
      size="small"
      title={formatMessage(messages.confirmACHModalTitle)}
      open={isOpen}
      onRequestClose={onConfirmCancel}
      dismissible={false}
      primaryAction={{
        label: formatMessage(messages.turnOnACHButtonTitle),
        onClick: onConfirmTurnOnAch,
        loading: achLoading || loading,
      }}
      secondaryAction={{
        label: formatMessage(messages.cancel),
        onClick: onConfirmCancel,
        loading: achLoading || loading,
      }}
    >
      <Content>
        <Text>{formatMessage(messages.turnOnACHBody)}</Text>
      </Content>
    </Modal>
  );

  async function onConfirmTurnOnAch() {
    try {
      const { data, errors } = await updateSettings({
        variables: {
          name: JobberPaymentsSettingsName.ALLOW_ACH_PAYMENTS,
          enabled: true,
        },
      });

      if (errors) {
        onError((errors[0] as Error) || genericError);
      }

      if (!data) {
        onError(genericError);
      } else {
        setToggleAchFlag?.(currentFlag => !currentFlag);
        await updateDefaultPreference(radioOptionChosen);
        setIsAchEnabled?.(true);
        onRequestClose();
      }
    } catch (error) {
      onError(error as Error);
    }
  }

  function onConfirmCancel() {
    onRequestClose();
  }
}
