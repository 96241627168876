import { gql } from "@apollo/client";
import { ExpiringJobsData } from "jobber/dashboard/components/ExpiringJobsInsight";
import { ProjectedRevenueData } from "jobber/dashboard/components/ProjectedRevenueInsight";
import { OverdueItemsTotalData } from "jobber/dashboard/components/ScheduledItemsOverdueInsight";
import { DoubleBookingData } from "jobber/dashboard/components/DoubleBookingInsight";
import { TopClientsBalanceData } from "jobber/dashboard/components/TopClientsBalanceInsight/TopClientsBalanceInsight";
import { QBOLastSyncData } from "jobber/dashboard/components/QBOLastSyncInsight/QBOLastSyncInsight";
import { ClientReengagementData } from "jobber/dashboard/components/ClientReengagementInsight";

export const INSIGHTS_CARDS_QUERY = gql`
  query InsightCards($startDate: String!, $endDate: String!) {
    overdueItems: overdueItems {
      ...OverdueItemsTotalData
    }

    expiringJobs: expiringJobs {
      ...ExpiringJobsData
    }

    revenue: revenueCalculation(
      revenueStartDate: $startDate
      revenueEndDate: $endDate
      state: "insights"
    ) {
      ...ProjectedRevenueData
    }
    doubleBookings: doubleBookings {
      ...DoubleBookingData
    }

    topClientsBalance: topClientsBalance {
      ...TopClientsBalanceData
    }

    qboLastSync: qboDaysSinceLastSync {
      ...QBOLastSyncData
    }

    account: account {
      id
      shouldConnectQbo
    }

    clientReengagement: clientReengagement {
      ...ClientReengagementData
    }

    insightOrder: insightOrder {
      order
    }
  }

  ${OverdueItemsTotalData}
  ${ExpiringJobsData}
  ${ProjectedRevenueData}
  ${DoubleBookingData}
  ${TopClientsBalanceData}
  ${QBOLastSyncData}
  ${ClientReengagementData}
`;
