import React, { useState } from "react";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { TeamAssignment, TeamAssignmentCardContent } from "../TeamAssignment";
import { useAssignTeam } from "../AssignTeam";

interface TeamAssignmentCardProps {
  initialNotifyTeam: boolean;
  railsFormKey: string;
  renderCardHeader: boolean;
}

const FORM_TEAM_ASSIGNMENT_KEY = "initial_assigned_to_ids";
const FORM_NOTIFY_TEAM_KEY = "notify_team";

export function TeamAssignmentCard(
  props: TeamAssignmentCardProps,
): JSX.Element {
  const api = useAssignTeam();
  const {
    assignableUsers,
    selectedAssignees,
    setSelectedAssignees,
    loading,
    disabled,
  } = api;
  const [notifyTeam, setNotifyTeam] = useState<boolean>(
    props.initialNotifyTeam,
  );
  const formAssignmentValue = Array.from(selectedAssignees)
    .map(id => decodeId(id))
    .join(" ");

  const TeamAssignmentComponent = props.renderCardHeader
    ? TeamAssignment
    : TeamAssignmentCardContent;

  return (
    <>
      <TeamAssignmentComponent
        emailAssignment={notifyTeam}
        setEmailAssignment={setNotifyTeam}
        selectedAssignees={Array.from(selectedAssignees)}
        setSelectedAssignees={setSelectedAssignees}
        assignableUsers={assignableUsers}
        loading={loading}
        disabled={disabled}
      />
      <input
        type="hidden"
        value={formAssignmentValue}
        name={`${props.railsFormKey}[${FORM_TEAM_ASSIGNMENT_KEY}]`}
        autoComplete="off"
        id={`${props.railsFormKey}_initial_assigned_to_ids`}
      />
      <input
        type="hidden"
        value={notifyTeam ? "1" : "0"}
        name={`${props.railsFormKey}[${FORM_NOTIFY_TEAM_KEY}]`}
        autoComplete="off"
        id={`${props.railsFormKey}_notify_team`}
      />
    </>
  );
}
