type SyncListenerWithElement = (config: {
  eventName: string;
}) => (params: {
  previousElement: Pick<Element, "removeEventListener"> | undefined | null;
  previousListener: ((event: Event) => void) | undefined;
  newElement: Pick<Element, "addEventListener"> | undefined | null;
  newListener: ((event: Event) => void) | undefined;
}) => void;

const syncListenerWithElement: SyncListenerWithElement =
  ({ eventName }) =>
  ({ previousElement, previousListener, newElement, newListener }) => {
    if (previousElement && previousListener) {
      previousElement.removeEventListener(eventName, previousListener, false);
    }

    if (newElement && newListener) {
      newElement.addEventListener(eventName, newListener, false);
    }
  };

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default syncListenerWithElement;
