import React from "react";
import { Page } from "@jobber/components/Page";
import { Icon } from "@jobber/components/Icon";
import type {
  ManualPagination,
  ManualSorting,
  Row,
} from "@jobber/components/DataTable";
import { useHistory } from "react-router-dom";
import { messages } from "jobber/features/PaymentDisputes/views/DisputeListPage/messages";
import {
  DisputeTable,
  getColumnConfig,
} from "jobber/features/PaymentDisputes/components/DisputeTable";
import { HELP_CENTER_URL } from "jobber/features/PaymentDisputes/constants";
import type {
  JobberPaymentsDisputeEdge,
  PaymentDisputesQueryQuery,
} from "~/utilities/API/graphql";
import styles from "./DisputeListPage.module.css";

export interface DisputeListProps {
  disputes: PaymentDisputesQueryQuery["jobberPaymentsDisputes"];
  paginationProps: ManualPagination;
  sortingProps: ManualSorting;
  loading?: boolean;
}

export const DisputeListPage = ({
  disputes,
  paginationProps,
  sortingProps,
  loading,
}: DisputeListProps) => {
  const history = useHistory();
  const onRowClicked = (row: Row<JobberPaymentsDisputeEdge>) => {
    history.push(`${row.original.node.id}`);
  };
  return (
    <Page title={messages.disputeListPageHeader} width="fill">
      <div>
        <Icon name="help" /> {messages.helpLinkDescription}
        <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
          {messages.helpLinkPlaceholder}
        </a>
        .
      </div>
      <div className={styles.dataTableContainer}>
        <DisputeTable
          disputesData={disputes}
          loading={loading}
          pagination={paginationProps}
          sorting={sortingProps}
          columns={getColumnConfig()}
          onRowClick={onRowClicked}
        />
      </div>
    </Page>
  );
};
