// Get the csrfToken out of the DOM.
// CSRF is Cross-Site Request Forgery. It prevents making calls to Jobber
// servers from non-Jobber web pages.
function getCSRFToken() {
  const element = document.head.querySelector(`meta[name="csrf-token"]`);
  if (element) {
    const content = element.getAttribute("content");
    if (content != undefined) {
      return content;
    }
  }
  throw new Error("Did not find CSRF token in the document.");
}

export const csrfToken = getCSRFToken();
