import { showToast } from "@jobber/components/Toast";
import { screen, waitFor, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

/*
  selectors
*/
export const findTheEmailTab = async () => {
  return screen.findByRole("tab", {
    name: "Email",
  });
};

export const emailTab = () => {
  return screen.getByRole("tab", { name: "Email" });
};

export const queryTheEmailTab = () => {
  return screen.queryByRole("tab", { name: "Email" });
};

export const findThePreviewSection = async () => {
  return screen.findByText("Preview");
};

export const findTheCustomizationsSection = async () => {
  return screen.findByText("Insert variable");
};

export const queryByTheCustomizationsSection = () => {
  return screen.queryByText("Insert variable");
};

export const queryTheTextTab = () => {
  return screen.queryByRole("tab", { name: "Text" });
};

export const findTheTextTab = async () => {
  return screen.findByRole("tab", {
    name: "Text",
  });
};

export const textTab = () => {
  return screen.getByRole("tab", { name: "Text" });
};

export const messageBody = () => {
  return screen.getByRole("textbox", { name: "Message" });
};

export const findTheMessageBody = async () => {
  return screen.findByRole("textbox", { name: "Message" });
};

export const findTheSelectBox = async () => {
  return screen.findByRole("combobox");
};

export const findTheInputNumber = async () => {
  return screen.findByRole("spinbutton");
};

export const queryTheSelectBox = async () => {
  return screen.queryByRole("combobox");
};

export const somethingWentWrongBanner = async () => {
  return screen.findByText("Something went wrong. Please try again later.");
};

export const findFeatureToggle = () => {
  return screen.findByRole("switch");
};

/*
  actions
*/
export const iClickTheBackButton = async () => {
  userEvent.click(
    await screen.findByRole("button", { name: "Back to main settings" }),
  );
};

export const iClickTheSaveButton = () => {
  userEvent.click(
    screen.getByRole("button", { name: "Save message settings" }),
  );
};

export const iSelectADeliveryMethod = async (deliveryMethod: string) => {
  const options = await findTheSelectBox();
  userEvent.selectOptions(options, deliveryMethod);
};

export const iChangeInputNumber = async (newValue: string) => {
  const inputNumber = await findTheInputNumber();
  userEvent.clear(inputNumber);
  userEvent.type(inputNumber, newValue);
};

export const iCheckForTheEmailTab = () => {
  return screen.queryByText("Email Subject");
};

export const iCheckForTheTextTab = () => {
  return screen.queryByText("static message");
};

export const iClickTheEmailTab = async () => {
  userEvent.click(await findTheEmailTab());
};

export const iClickTheTextTab = async () => {
  userEvent.click(await findTheTextTab());
};

export const iClickTheSwitch = async () => {
  userEvent.click(await findFeatureToggle());
};

export const iTypeInTheEmailMessageBody = async (message: string) => {
  await iClickTheEmailTab();
  const textbox = await findTheMessageBody();
  userEvent.clear(textbox);
  userEvent.type(textbox, message);
};

export const iTypeInTheTextMessageBody = async (message: string) => {
  await iClickTheTextTab();
  const textbox = await findTheMessageBody();
  userEvent.clear(textbox);
  userEvent.type(textbox, message);
};

/*
  wait for assertions
*/
export const waitForTheEmailMessage = async (message: string) => {
  await iClickTheEmailTab();
  expect(within(messageBody()).getByText(message)).toBeVisible();
};

export const waitForTheToastToShow = async () => {
  await waitFor(() => {
    expect(showToast).toHaveBeenCalledWith({
      message: "Saved changes",
    });
  });
};

export const waitForTheDropdownToShow = async () => {
  expect(await findTheSelectBox()).toBeInTheDocument();
};
