import React from "react";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import classNames from "classnames";
import { CardType } from "../IntegrationList";
import styles from "../IntegrationList.module.css";
import type { Integration } from "../IntegrationListProps";
import { IntegrationCardList } from "../IntegrationCardList";
import { messages } from "../../../messages";

export function OurPicksSection({
  featuredApps,
  publicFacing,
}: {
  featuredApps: Integration[];
  publicFacing: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.appSection}>
      <Heading level={2}>
        {formatMessage(messages.sectionOurPicksTitle)}
      </Heading>
      <div className={classNames(styles.appGrid, styles.ourPicksSection)}>
        <IntegrationCardList
          apps={featuredApps}
          featured
          type={CardType.OUR_PICKS}
          publicFacing={publicFacing}
        />
      </div>
    </div>
  );
}
