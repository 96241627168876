import { industryDisplayText } from "./Features";

/* Accepts a string number like "4.0" or "37" (only one decimal place allowed)
 * Returns array of integers to scroll through for each number roller column
 * Furthest left digit should scroll straight to value, other digits should scroll 0-9 first
 *
 * Examples:
 * generateDigitArrays("6") = [[0,1,2,3,4,5,6]]
 * generateDigitArrays("4.2") => [[0,1,2,3,4], [0,1,2,3,4,5,6,7,8,9,0,1,2]]
 * generateDigitArrays("37") => [[0,1,2,3], [0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7]]
 */
export function generateDigitArrays(inputNumber: string) {
  const digits = Array.from(inputNumber.replace(".", ""), Number);
  const output = [];

  for (let i = 0; i < digits.length; i++) {
    const digit = digits[i];
    const digitArray = [];

    // when digit is 0, push 0..9, 0
    // otherwise push 0..digit
    if (digit === 0) {
      digitArray.push(...Array(10).keys(), 0);
    } else {
      digitArray.push(...Array(digit + 1).keys());
    }

    output.push(digitArray);
  }

  return output;
}

export function industryName(industry: string) {
  return industryDisplayText[industry] ?? industry.toLowerCase();
}
