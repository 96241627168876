import classNames from "classnames";
import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import styles from "./ResultsPicker.module.css";

export interface Result_DEPRECATED {
  description: string;
  uniqueId: string;
  icon?: IconNames;
  prompt?: string;
}

export interface ResultsPickerProps {
  filteredResults: Result_DEPRECATED[];
  shouldDisplay: boolean;
  selectedResult: number;
  onAddResult(selectedItem: Result_DEPRECATED): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

interface ResultItemProps {
  result: Result_DEPRECATED;
  selected: boolean;
  onClick(result: Result_DEPRECATED): void;
}

/**
 * ResultsPicker react component
 * @deprecated Use [Atlantis Autocomplete](https://atlantis.getjobber.com/components/autocomplete) instead.
 */
export function ResultsPicker_DEPRECATED({
  filteredResults,
  selectedResult,
  onAddResult,
  shouldDisplay,
  onMouseEnter,
  onMouseLeave,
}: ResultsPickerProps) {
  if (filteredResults.length === 0 || !shouldDisplay) {
    return null;
  }

  const className = classNames(
    "card",
    "u-paddingSmaller",
    "u-boxShadow",
    styles.resultsPickerOverlay,
  );

  return (
    <div className={className}>
      <ul
        className="list u-marginNone u-maxHeight400"
        aria-label="Property Results"
        onTouchStart={onMouseEnter}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {filteredResults.map((result: Result_DEPRECATED, index: number) => {
          return (
            <ResultItem
              key={`${result.uniqueId}`}
              result={result}
              onClick={onAddResult}
              selected={selectedResult === index}
            />
          );
        })}
      </ul>
    </div>
  );
}

function ResultItem({ onClick, result, selected }: ResultItemProps) {
  const click = () => {
    onClick(result);
  };

  const className = classNames("list-item", {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "is-selected": selected,
  });

  let icon;
  if (result.icon) {
    icon = (
      <div className="columns shrink">
        <Icon name={result.icon} />
      </div>
    );
  } else {
    icon = <></>;
  }

  return (
    <li className={className}>
      <button
        type="button"
        className="list-itemLink u-paddingSmaller"
        onClick={click}
      >
        <div className="row row--tightColumns align-middle">
          {icon}
          <div className="columns">{result.description}</div>
        </div>
      </button>
    </li>
  );
}
