import classnames from "classnames";
import React from "react";
import { Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { pendingStateMessages } from "jobber/chat/components/UkKycRegistration/UkKycPendingState/pendingStateMessages";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";

export function UkKycPendingState() {
  const { formatMessage } = useIntl();

  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderTop",
  );

  return (
    <div className={cssClasses}>
      <Heading level={4}>{formatMessage(pendingStateMessages.header)}</Heading>
      <p>{formatMessage(pendingStateMessages.body)}</p>
    </div>
  );
}
