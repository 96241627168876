import React from "react";
import { Content } from "@jobber/components/Content";
import { Glimmer } from "@jobber/components/Glimmer";

export const AddonPurchaseGlimmer = () => {
  return (
    <Content spacing="larger">
      <Glimmer.Header level={1} />
      <Glimmer />
      <Glimmer />
      <Glimmer.Header />
      <Glimmer.Text />
    </Content>
  );
};
