import React from "react";
import ReactS3Uploader, { type S3Response } from "@jobber/react-s3-uploader";
import {
  DefaultThumbDimensions,
  type ResponseData,
  fetchS3ThumbnailUrl,
  fetchSignedUrl,
} from "./FileUploaderUtils";

const MAX_FILE_SIZE = 1e8; // in bytes

export interface FileUploaderProps_DEPRECATED {
  signingUrl: string;
  s3ThumbnailUrl: string;
  setS3Key(s3Key: string): void;
  setName(url: string): void;
  setThumbnail(thumbnail: string): void;
  setPercentCompleted(percentCompleted: number): void;
  setUploading(uploading: boolean): void;
  setInputRef(element: HTMLInputElement): void;
  onUploaded?(
    fileName: string,
    contentType: string,
    fileSize: number,
    s3key: string,
    thumbnail: string,
  ): void;
}
/**
 * FileUploader component
 * @deprecated Use [Atlantis InputFile](https://atlantis.getjobber.com/?path=/docs/components-inputfile--input-file) instead.
 */
// This file will be changing as we are replacing @jobber/react-s3-uploader with dropzone
export function FileUploader_DEPRECATED(props: FileUploaderProps_DEPRECATED) {
  function getSignedUrl(file: File, callback: (data: ResponseData) => void) {
    fetchSignedUrl(file, callback, props.signingUrl, props.setS3Key);
  }

  function onUploadProgress(percent: number) {
    props.setPercentCompleted(percent);
    props.setUploading(true);
    props.setThumbnail("");
  }

  function onUploadFinish(signResult: S3Response, file: File) {
    fetchS3ThumbnailUrl(
      signResult.fileKey,
      props.s3ThumbnailUrl,
      (thumbnailURL: string) => {
        props.setThumbnail(thumbnailURL);
        props.setS3Key(signResult.fileKey);
        props.setName(signResult.filename);
        props.setUploading(false);
        if (props.onUploaded) {
          props.onUploaded(
            signResult.filename,
            file.type,
            file.size,
            signResult.fileKey,
            thumbnailURL,
          );
        }
      },
      DefaultThumbDimensions,
    );
  }

  function onUploadError() {
    alert(`Something went wrong. Image could not be uploaded.`);
    props.setUploading(false);
    return;
  }

  function onPreprocess(file: File, next: (file: File) => void) {
    if (file.size > MAX_FILE_SIZE) {
      alert(
        `Couldn't upload ${file.name}. Please select a file that's less than ${
          MAX_FILE_SIZE / 1e6
        }MB`,
      );
    } else {
      next(file);
    }
  }

  return (
    <ReactS3Uploader
      inputRef={props.setInputRef}
      aria-label={"File Uploader"}
      style={{ display: "none" }}
      getSignedUrl={getSignedUrl}
      signingUrlMethod="GET"
      accept="image/*"
      // eslint-disable-next-line @typescript-eslint/naming-convention
      uploadRequestHeaders={{ "X-Amz-Acl": "private" }}
      preprocess={onPreprocess}
      onProgress={onUploadProgress}
      onError={onUploadError}
      onFinish={onUploadFinish}
      contentDisposition="auto"
      autoUpload={true}
    />
  );
}
