import { defineMessages } from "react-intl";

export const messages = defineMessages({
  invoiceTotal: {
    id: "clientHubJobberPayments.checkoutHeader.invoiceTotal",
    defaultMessage: "Total",
    description: "Invoice total message",
  },
  invoiceBalance: {
    id: "clientHubJobberPayments.checkoutHeader.invoiceBalance",
    defaultMessage: "Balance",
    description: "Invoice balance message",
  },
  quoteDepositRequired: {
    id: "clientHubJobberPayments.checkoutHeader.quoteTotal",
    defaultMessage: "Deposit Required",
    description: "Quote total message",
  },
  quoteOutstandingDeposit: {
    id: "clientHubJobberPayments.checkoutHeader.quoteOutstandingDeposit",
    defaultMessage: "Outstanding Deposit",
    description: "Quote outstanding deposit message",
  },
  bankAccountFormContainerTitle: {
    id: "clientHubJobberPayments.bankAccountFormContainer.title",
    defaultMessage: "Bank Account Selected",
    description: "Bank account form container title",
  },
  choosePaymentMethodTitle: {
    id: "clientHubJobberPayments.choosePaymentMethod.title",
    defaultMessage: "Choose payment method",
    description: "Choose payment method title",
  },
  bankAccount: {
    id: "clientHubJobberPayments.paymentMethodChips.bankAccount",
    defaultMessage: "Bank account",
    description: "Bank account message",
  },
  creditCard: {
    id: "clientHubJobberPayments.paymentMethodChips.creditCard",
    defaultMessage: "Credit card",
    description: "Credit card message",
  },
  applePay: {
    id: "clientHubJobberPayments.paymentMethodChips.applePay",
    defaultMessage: "Apple Pay",
    description: "Apple Pay payment method type",
  },
  googlePay: {
    id: "clientHubJobberPayments.paymentMethodChips.googlePay",
    defaultMessage: "Google Pay",
    description: "Google Pay payment method type",
  },
  tipSettingsDisclaimer: {
    id: "clientHubJobberPayments.tipSettings.disclaimer",
    defaultMessage: "Tip is on full invoice amount before taxes",
    description: "Disclaimer for tip settings",
  },
  tipSettingsTitle: {
    id: "clientHubJobberPayments.tipSettings.title",
    defaultMessage: "Allow clients to leave a tip",
    description: "Title for tip settings",
  },
  tipSettingsDescription: {
    id: "clientHubJobberPayments.tipSettings.description",
    defaultMessage:
      "Clients can leave a tip when paying invoices through client hub.",
    description: "Description for tip settings",
  },
  tipsAddATipLabel: {
    id: "clientHubJobberPayments.tips.addATipLabel",
    defaultMessage: "Add a tip:",
    description: "Add a tip label",
  },
  invoiceLabel: {
    id: "clientHubJobberPayments.invoice.label",
    defaultMessage: "Pay Invoice",
    description: "Invoice label",
  },
  invoiceLabelSentenceCase: {
    id: "clientHubJobberPayments.invoice.invoiceLabelSentenceCase",
    defaultMessage: "Pay invoice",
    description: "Invoice label",
  },
  quoteLabel: {
    id: "clientHubJobberPayments.quote.label",
    defaultMessage: "Approve & Pay Deposit",
    description: "Quote label",
  },
  quoteLabelWithoutApproval: {
    id: "clientHubJobberPayments.quote.labelWithoutApproval",
    defaultMessage: "Pay Deposit",
    description: "Quote label without approval",
  },
  errorMessage: {
    id: "clientHubJobberPayments.general.errorMessage",
    defaultMessage: "Sorry, something went wrong. Please try again",
    description: "Error message",
  },
  errorCannotUseThisCreditCard: {
    id: "clientHubJobberPayments.general.errorCannotUseThisCreditCard",
    defaultMessage: "Cannot use this credit card",
    description: "Cannot use this credit card message",
  },
  errorCannotRetrieveDataMessage: {
    id: "clientHubJobberPayments.general.errorDataMessage",
    defaultMessage: "Cannot retrieve Jobber Payments settings",
    description: "Cannot retrieve Jobber Payments settings error message",
  },
  errorPaymentFailed: {
    id: "clientHubJobberPayments.general.paymentFailed",
    defaultMessage: "Payment failed:",
    description: "Payment failed error with colon after name",
  },
  errorQuoteRequiresSignature: {
    id: "clientHubJobberPayments.general.errorQuoteRequiresSignature",
    defaultMessage: "Quote requires signature",
    description: "Error message for Quote Requires Signature",
  },
  errorYouMustSaveYourBankAccountDetailsToContinue: {
    id: "clientHubJobberPayments.general.errorYouMustSaveYourBankAccountDetailsToContinue",
    defaultMessage: "You must save your bank account details to continue",
    description: "Error message for user must save bank details to continue",
  },
  successMessage: {
    id: "clientHubJobberPayments.general.successMessage",
    defaultMessage: "Payment completed - thank you!",
    description: "Success message",
  },
  learnMoreInHelpCenter: {
    id: "clientHubJobberPayments.general.learnMoreInHelpCenter",
    defaultMessage: "Learn More in the Help Center",
    description: "Learn more in the Help Center message",
  },
  securityAssurance: {
    id: "clientHubJobberPayments.general.securityAssurance",
    defaultMessage:
      "We do not store your financial information. It is transferred over an encrypted connection to a certified payment processing server.",
    description: "Security assurance message",
  },
  nachaMandateTitle: {
    id: "clientHubJobberPayments.invoice.nachaMandateTitle",
    defaultMessage: "By clicking Pay, you agree to terms and conditions",
    description: "Nacha mandate title",
  },
  nachaMandateLegalPrivacyPolicyLink: {
    id: "clientHubJobberPayments.invoice.nachaMandatePrivacyPolicyLink",
    defaultMessage: "privacy policy",
    description: "Nacha ACH privacy policy link",
  },
  nachaMandateLegalTermsAndConditionsLink: {
    id: "clientHubJobberPayments.invoice.nachaMandateTermsAndConditionsLink",
    defaultMessage: "terms and conditions",
    description: "Nacha ACH terms and conditions link",
  },
  nachaMandateLegalText: {
    id: "clientHubJobberPayments.invoice.nachaMandateLegalText",
    defaultMessage:
      "Payments are secure and encrypted as per our {privacy_policy_link}. By clicking Pay, you agree to our {terms_and_conditions_link} and allow {companyName} to charge {amount} to your {paymentMethodType} on {currentDate}.",
    description: "Nacha ACH mandate message",
  },
  nachaMandateLegalWalletText: {
    id: "clientHubJobberPayments.invoice.nachaMandateLegalWalletText",
    defaultMessage:
      "By clicking Save Payment Method, you agree to our {terms_and_conditions_link}. Our {privacy_policy_link} will apply to personal information you provide us. You also allow {companyName} to charge agreed amount to your ACH bank account.",
    description: "Nacha ACH mandate message",
  },
  payWithCardTitle: {
    id: "clientHubJobberPayments.StoredPaymentMethodList.payWithCardTitle",
    defaultMessage: "Pay with card",
    description: "Pay with card message",
  },
  choosePaymentMethod: {
    id: "clientHubJobberPayments.CheckoutContainer.choosePaymentMethod",
    defaultMessage: "Choose payment method",
    description: "Choose payment method message",
  },
  creditCardFormContainerTitle: {
    id: "clientHubJobberPayments.creditCardFormContainer.containerTitle",
    defaultMessage: "Card Details",
    description: "Credit card container title",
  },
  creditCardMandatoryText: {
    id: "clientHubJobberPayments.creditCard.mandatoryText",
    defaultMessage:
      "By paying this deposit you agree to have your card securely saved on file for future use by {companyName}.",
    description: "Credit card mandatory message",
  },
  creditCardKeepCardOnFile: {
    id: "clientHubJobberPayments.creditCard.keepCardOnFile",
    defaultMessage: "Remember this card for future payments",
    description: "Credit card keep card on file message",
  },
  creditCardNameOnCard: {
    id: "clientHubJobberPayments.creditCard.nameOnCard",
    defaultMessage: "Name on card",
    description: "Credit card name on card message",
  },
  creditCardCardHolderNameRequired: {
    id: "clientHubJobberPayments.creditCard.cardHolderNameRequired",
    defaultMessage: "Card holder name is required",
    description: "Credit card card holder name required message",
  },
  bankAccountUseDifferentAccount: {
    id: "clientHubJobberPayments.bankAccount.bankAccountUseDifferentAccount",
    defaultMessage: "Use another account",
    description: "Bank account use different card than saved card message",
  },
  creditCardUseDifferentCard: {
    id: "clientHubJobberPayments.creditCard.useDifferentCard",
    defaultMessage: "Use another card",
    description: "Credit card use different card than saved cards message",
  },
  creditCardUseSavedCard: {
    id: "clientHubJobberPayments.creditCard.useSavedCard",
    defaultMessage: "Use saved card",
    description: "Credit card use a saved card instead of a new card message",
  },
  bankAccountUseSavedBankAccount: {
    id: "clientHubJobberPayments.bankAccount.bankAccountUseSavedBankAccount",
    defaultMessage: "Use saved bank account",
    description: "Bank account use a saved account message",
  },
  creditCardErrorMessagesIncompleteElements: {
    id: "clientHubJobberPayments.creditCard.errorMessagesIncompleteElements",
    defaultMessage: "payment attempt made before stripe or elements is ready",
    description: "Credit card incomplete elements error message",
  },
  payWithACHBankPayment: {
    id: "clientHubJobberPayments.bankPayment.payWithACHBankPayment",
    defaultMessage: "Pay with ACH bank payment",
    description: "Pay with ACH Bank Payment message",
  },
  buttonsPayWithCreditCard: {
    id: "clientHubJobberPayments.buttons.payWithCreditCard",
    defaultMessage: "Pay with Credit Card",
    description: "Pay with credit card button label",
  },
  buttonsApproveAndPayWithCreditCard: {
    id: "clientHubJobberPayments.buttons.approveAndPayWithCreditCard",
    defaultMessage: "Approve & Pay with Credit Card",
    description: "Approve and pay with credit card button label",
  },
  buttonsPayWithACH: {
    id: "clientHubJobberPayments.buttons.payWithACH",
    defaultMessage: "Pay with Bank Payment",
    description: "Pay with ACH button label",
  },
  buttonsApproveAndPayWithACH: {
    id: "clientHubJobberPayments.buttons.approveAndPayWithACH",
    defaultMessage: "Approve & Pay with Bank Payment",
    description: "Approve and pay with ACH button label",
  },
  buttonsBack: {
    id: "clientHubJobberPayments.buttons.back",
    defaultMessage: "Back",
    description: "Back button message",
  },
  buttonsContinueLabel: {
    id: "clientHubJobberPayments.buttons.continue",
    defaultMessage: "Continue",
    description: "Continue button label",
  },
  useAnotherPaymentMethod: {
    id: "clientHubJobberPayments.storedCardList.useAnother",
    defaultMessage: "Use another",
    description: "Use another message",
  },
  securityAssuranceTitle: {
    id: "clientHubJobberPayments.invoice.securityAssuranceTitle",
    defaultMessage: "Bank Payment",
    description: "Security Assurance title",
  },
  securityAssuranceMessage: {
    id: "clientHubJobberPayments.invoice.securityAssuranceMessage",
    defaultMessage:
      "Your information is not stored by {companyName}, and is transferred over an encrypted connection to a certified payment processing server.",
    description: "Security assurance message",
  },
  signaturePad: {
    id: "clientHubJobberPayments.signaturePad.label",
    defaultMessage: "Approval signature",
    description: "Signature pad label",
  },
  signaturePadDisclaimer: {
    id: "clientHubJobberPayments.signaturePad.disclaimer",
    defaultMessage:
      "By writing your signature, you accept this quote, associated costs, and any terms and conditions that may apply.",
    description: "Signature pad disclaimer",
  },
  rememberTheseBankDetailsForFuturePayments: {
    id: "clientHubJobberPayments.payInvoiceModal.rememberTheseBankDetailsForFuturePayments",
    defaultMessage: "Remember these bank details for future payments",
    description: "Remember these bank details for future payments message",
  },
  errorBankAccountHasAlreadyBeenConnected: {
    id: "clientHubJobberPayments.errors.bankAccountConnected",
    defaultMessage: "This bank account has already been connected.",
    description:
      "Error message when a bank account is already connected with this account number and routing number",
  },
  confirmationTitle: {
    id: "clientHubJobberPayments.confirmation.title",
    defaultMessage: "Are you sure?",
    description: "Confirmation dialog title",
  },
  confirmationMessage: {
    id: "clientHubJobberPayments.confirmation.message",
    defaultMessage:
      "If you close this dialog you will lose the bank details that you have entered. Are you sure?",
    description: "Confirmation dialog message",
  },
  confirmationButtonLabel: {
    id: "clientHubJobberPayments.confirmation.buttonLabel",
    defaultMessage: "Yes",
    description: "Confirmation dialog button label",
  },
  connectWithPlaid: {
    id: "clientHubJobberPayments.buttons.connectWithPlaid",
    defaultMessage: "Connect with Plaid",
    description: "Connect with Plaid message",
  },
  useAccount: {
    id: "clientHubJobberPayments.storedCardList.useAccount",
    defaultMessage: "Use account",
    description: "Use account message for stored card list",
  },
  useAnother: {
    id: "clientHubJobberPayments.storedCardList.useAnother",
    defaultMessage: "Use another",
    description: "Use another message for stored card list",
  },
  selectSavedCreditCard: {
    id: "invoices.storedCardList.selectSavedCreditCard",
    defaultMessage: "Select a saved credit card",
    description: "Aria label for selecting a saved credit card",
  },
  selectSavedBankCard: {
    id: "invoices.storedCardList.selectSavedBankCard",
    defaultMessage: "Select a saved bank account",
    description: "Aria label for selecting a saved bank card",
  },
  altBankIcon: {
    id: "clientHubJobberPayments.bankPaymentSecurityText.altBankIcon",
    defaultMessage: "Bank",
    description: "Bank icon alt text",
  },
  bankACHSecurityText: {
    id: "clientHubJobberPayments.bankPaymentSecurityText.bankACHSecurityText",
    defaultMessage:
      "Connect to your bank account and make a secure one-time payment",
    description: "Bank ACH security text",
  },
  genericACHPaymentError: {
    id: "clientHubJobberPayments.ACHPayment.genericPaymentError",
    defaultMessage: "Something went wrong, could not collect payment.",
    description: "Generic error message for ACH payment failure",
  },
  genericCreateManualAchBankError: {
    id: "clientHubJobberPayments.Wallet.genericCreateManualAchBankError",
    defaultMessage: "Something went wrong, could not save the payment method.",
    description: "Generic error message for creation of manual ach bank",
  },
  connectToYourBankAccountWithPlaid: {
    id: "clientHubJobberPayments.ACHPayment.connectToYourBankAccountWithPlaid",
    defaultMessage:
      "Connect securely to your bank without entering your banking details.",
    description: "Connect to your bank account with Plaid message",
  },
  bankAccountFormRadioOptionManual: {
    id: "clientHubJobberPayments.bankAccountForm.manual",
    defaultMessage: "Enter banking details",
    description: "Bank account form radio option manual",
  },
  bankAccountFormRadioOptionLogin: {
    id: "clientHubJobberPayments.bankAccountForm.login",
    defaultMessage: "Use bank login",
    description: "Bank account form radio option login",
  },
  bankAccountFormRadioOptionAriaLabel: {
    id: "clientHubJobberPayments.bankAccountForm.ariaLabel",
    defaultMessage: "Choose enter details method",
    description: "Aria label for radio group",
  },
  payButtonText: {
    id: "clientHubJobberPayments.buttons.pay",
    defaultMessage: "Pay",
    description: "Pay button label",
  },
  connectWithPlaidButtonText: {
    id: "clientHubJobberPayments.buttons.connectWithPlaid",
    defaultMessage: "Connect with Plaid",
    description: "Connect with Plaid button label",
  },
  payButtonAriaLabel: {
    id: "clientHubJobberPayments.buttons.payAriaLabel",
    defaultMessage: "Pay",
    description: "Pay button aria label",
  },
  missingBankDetails: {
    id: "clientHubJobberPayments.ACHPayment.missingBankDetails",
    defaultMessage: "Missing required bank account details",
    description: "Error message for missing bank details",
  },
  billingAddressIncomplete: {
    id: "clientHubJobberPayments.creditCard.billingAddressRequired",
    defaultMessage: "Billing address is incomplete",
    description: "Error message for incomplete billing address",
  },
  selectPaymentMethodHeadingText: {
    id: "clientHubJobberPayments.general.selectPaymentHeadingText",
    defaultMessage: "Select payment method",
    description: "Select payment method heading text",
  },
  signButtonLabel: {
    id: "clientHubJobberPayments.signaturePad.signButtonLabel",
    defaultMessage: "Sign",
    description: "Sign button label",
  },
  poweredByStripeImageLabel: {
    id: "clientHubJobberPayments.poweredByStripe.imageLabel",
    defaultMessage: "Powered by Stripe",
    description: "Powered by Stripe image label",
  },
});
