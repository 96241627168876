import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { IntlProvider } from "@translations/IntlProvider";
import DowngradePromoAccepted from "@images/downgradePromoAccepted.png";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./DowngradePromoAcceptedModal.module.css";

interface DowngradePromoAcceptedModalProps {
  planName: string | null;
  promoAmount: number | null;
  offerDuration: number | null;
  isAnnual: boolean | null;
  showModal: boolean;
  nextBillingDate: string | null;
}

function DowngradePromoAcceptedModal({
  planName,
  promoAmount,
  offerDuration,
  isAnnual,
  showModal,
  nextBillingDate,
}: DowngradePromoAcceptedModalProps) {
  const [modalOpen, setModalOpen] = useState(showModal);
  const { formatMessage } = useIntl();

  const monthlyCopy = () => {
    return offerDuration === 1
      ? formatMessage(messages.offerCopyMonthlySingular)
      : formatMessage(messages.offerCopyMonthlyPlural, {
          offerDuration,
        });
  };

  const offerCopy = isAnnual
    ? formatMessage(messages.offerCopyAnnual)
    : monthlyCopy();

  return (
    <div>
      {planName !== null && isAnnual !== null && nextBillingDate !== null && (
        <Modal
          title={formatMessage(messages.heading)}
          open={modalOpen}
          dismissible={true}
          onRequestClose={handleModalDismissal}
          primaryAction={{
            label: formatMessage(messages.gotIt),
            onClick: handleModalDismissal,
          }}
          secondaryAction={{
            label: formatMessage(messages.talkToAProductExpert),
            onClick: talkToAProductExpertClick,
            type: "secondary",
            variation: "work",
            url: formatMessage(messages.talkToAProductExpertCalendlyLink),
          }}
        >
          <div className={styles.downgradePromoAcceptedModalContainer}>
            <Content spacing="large">
              <div className={styles.downgradePromoAcceptedImgContainer}>
                <div className={styles.downgradePromoAcceptedImg}>
                  <img
                    src={DowngradePromoAccepted}
                    alt={formatMessage(messages.imgAlt)}
                  />
                </div>
              </div>
              <Content spacing="large">
                <Text>
                  {formatMessage(messages.firstParagraph, {
                    planName,
                    promoAmount,
                    offerCopy,
                    nextBillingDate,
                  })}
                </Text>
                <Text>
                  {formatMessage(messages.secondParagraph, {
                    planName,
                  })}
                </Text>
              </Content>
            </Content>
          </div>
        </Modal>
      )}
    </div>
  );

  function handleModalDismissal() {
    setModalOpen(false);
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: "downgrade_promo_accepted_modal",
      name: "dismiss",
    });
  }

  function talkToAProductExpertClick() {
    setModalOpen(false);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "downgrade_promo_accepted_modal",
      name: "talk_to_a_product_expert",
    });
  }
}

function WrappedDowngradePromoAcceptedModal({
  planName,
  promoAmount,
  offerDuration,
  isAnnual,
  showModal,
  nextBillingDate,
}: DowngradePromoAcceptedModalProps): JSX.Element {
  return (
    <IntlProvider>
      <DowngradePromoAcceptedModal
        planName={planName}
        promoAmount={promoAmount}
        offerDuration={offerDuration}
        isAnnual={isAnnual}
        showModal={showModal}
        nextBillingDate={nextBillingDate}
      />
    </IntlProvider>
  );
}

export { WrappedDowngradePromoAcceptedModal as DowngradePromoAcceptedModal };
