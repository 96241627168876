import { Content } from "@jobber/components/Content";
import React from "react";
import { InputText } from "@jobber/components/InputText";
import { InputDate } from "@jobber/components/InputDate";
import { Option, Select } from "@jobber/components/Select";
import { useIntl } from "react-intl";
import type { AccountingCode, File, User } from "~/utilities/API/graphql";
import { InputCurrency } from "components/InputCurrency";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { UsersQueryType } from "jobber/workOrders/components/JobCost/hooks/types";
import {
  ExpenseActionTypes,
  type ExpenseModalAction,
  type ExpenseModalState,
  type ExpenseModalStateType,
} from "./ExpenseModalReducer";
import { Receipt } from "./Receipt";
import { messages } from "./messages";

interface ExpenseModalContentProps {
  accountingCodes?: AccountingCode[];
  users?: UsersQueryType[];
  currentUser: User;
  currencySymbol: string;
  state: ExpenseModalState;
  initialReceipt?: File;
  dispatch: React.Dispatch<ExpenseModalAction>;
}

export function ExpenseModalContent({
  accountingCodes,
  users,
  currentUser,
  currencySymbol,
  state,
  initialReceipt,
  dispatch,
}: ExpenseModalContentProps) {
  const {
    itemName,
    accountingCode,
    description,
    expenseDate,
    total,
    reimburseTo,
  } = state;

  const { can } = useAuthorization();
  const canEditExpenses = can("edit", "Expenses");

  const { formatMessage } = useIntl();

  const handleDispatch = (actionType: ExpenseActionTypes) => {
    return (newValue: ExpenseModalStateType) => {
      dispatch({ type: actionType, value: newValue });
    };
  };

  return (
    <Content spacing="small">
      <InputText
        name="itemName"
        placeholder={formatMessage(messages.itemName)}
        onChange={handleDispatch(ExpenseActionTypes.UpdateItemName)}
        value={itemName}
        validations={{
          required: {
            message: formatMessage(messages.itemNameValidationMessage),
            value: true,
          },
        }}
      />
      <Select
        name="accountingCode"
        placeholder={formatMessage(messages.accountCode)}
        onChange={handleDispatch(ExpenseActionTypes.UpdateAccountingCode)}
        value={accountingCode}
      >
        <Option value={""}>{formatMessage(messages.none)}</Option>
        {accountingCodes?.map(({ id, code }) => (
          <Option key={id} value={id}>
            {code}
          </Option>
        ))}
      </Select>
      <InputText
        autocomplete={false}
        name="description"
        placeholder={formatMessage(messages.description)}
        onChange={handleDispatch(ExpenseActionTypes.UpdateDescription)}
        multiline
        value={description}
        rows={{
          min: 3,
          max: 9,
        }}
      />
      <InputDate
        name="date"
        data-testid="expenseDate"
        placeholder={formatMessage(messages.date)}
        onChange={handleDispatch(ExpenseActionTypes.UpdateExpenseDate)}
        value={expenseDate ? expenseDate : new Date()}
      />
      <InputCurrency
        autocomplete={false}
        name="total"
        placeholder={formatMessage(messages.total)}
        prefix={{ label: currencySymbol }}
        maximumDecimalPlaces={2}
        onChange={handleDispatch(ExpenseActionTypes.UpdateTotal)}
        value={total}
        validations={{
          min: {
            message: formatMessage(messages.expenseMinValidationMessage),
            value: -999999,
          },
          max: {
            message: formatMessage(messages.expenseMaxValidationMessage),
            value: 999999,
          },
        }}
      />
      {canEditExpenses && (
        <Select
          name="reimburseTo"
          placeholder={formatMessage(messages.reimburseTo)}
          onChange={handleDispatch(ExpenseActionTypes.UpdateReimburseTo)}
          value={reimburseTo}
        >
          <Option value={""}>{formatMessage(messages.notReimbursable)}</Option>
          {currentUser.isAccountAdmin ? (
            users?.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name.full}
              </Option>
            ))
          ) : (
            <Option key={currentUser.id} value={currentUser.id}>
              {currentUser.name.full}
            </Option>
          )}
        </Select>
      )}
      <Receipt dispatch={dispatch} initialReceipt={initialReceipt} />
    </Content>
  );
}
