import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import React from "react";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { generatePath } from "react-router-dom";
import { Link } from "@jobber/components/Link";
import type { CampaignEmailFragment } from "~/utilities/API/graphql";
import type { SingleEmail } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/CampaignEmailsTable";
import { messages } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/messages";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { JOB_PAGE_PATH } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/constants";

interface JobNode {
  id: string;
  jobNumber: number;
}

interface useCampaignEmailTransformerArgs {
  hasDemoExperience?: boolean;
}

export function useCampaignEmailTransformer({
  hasDemoExperience = false,
}: useCampaignEmailTransformerArgs) {
  const { formatMessage } = useIntl();
  const { largeAndUp } = useBreakpoints();

  function renderJobNumbers(jobs: JobNode[]) {
    if (hasDemoExperience && jobs.length === 0) {
      const randomJobNumber = Math.floor(Math.random() * 1000);
      return formatMessage(messages.jobNumber, {
        jobNumber: randomJobNumber,
        hasComma: false,
      });
    }

    if (jobs.length === 0) {
      return formatMessage(messages.emptyJobs);
    }

    return jobs.map((job, index) => {
      const jobUrl = generatePath(JOB_PAGE_PATH, { jobId: decodeId(job.id) });
      return (
        <Link key={job.id} url={jobUrl}>
          {formatMessage(messages.jobNumber, {
            jobNumber: job.jobNumber,
            hasComma: jobs.length - 1 !== index,
          })}
        </Link>
      );
    });
  }

  function formatRevenue(revenue: number | undefined) {
    return revenue
      ? formatMessage(messages.dollarAmount, { amount: revenue.toFixed(2) })
      : formatMessage(messages.noRevenue);
  }

  function largeAndUpFormattedDataListItem(
    email: CampaignEmailFragment,
  ): SingleEmail {
    return {
      id: email.id,
      clientId: email.client?.id,
      recipient: (
        <>
          <Emphasis variation="bold">{email.recipientName}</Emphasis>
          <Text>{email.recipientEmail}</Text>
        </>
      ),
      delivered: formatMessage(generateYesOrNoStatus(email.deliveredAt)),
      opened: formatMessage(generateYesOrNoStatus(email.openedAt)),
      clicked: formatMessage(generateYesOrNoStatus(email.clickedAt)),
      unsubscribed: formatMessage(generateYesOrNoStatus(email.unsubscribedAt)),
      jobs: renderJobNumbers(email.jobs?.nodes || []),
      revenue: formatRevenue(email.revenue?.value),
    };
  }

  const generateStatuses = (email: CampaignEmailFragment) => {
    const delivered = email.deliveredAt
      ? formatMessage(messages.emailDeliveredStatus)
      : "";
    const opened = email.openedAt
      ? formatMessage(messages.emailOpenedStatus)
      : "";
    const clicked = email.clickedAt
      ? formatMessage(messages.emailClickedStatus)
      : "";
    const unsubscribed = email.unsubscribedAt
      ? formatMessage(messages.emailUnsubscribedStatus)
      : "";

    return [delivered, opened, clicked, unsubscribed]
      .filter(element => element !== "")
      .join(", ");
  };

  function smallerScreensFormattedDataListItem(
    email: CampaignEmailFragment,
  ): SingleEmail {
    return {
      id: email.id,
      clientId: email.client?.id,
      recipient: (
        <Text>
          <Emphasis variation={"bold"}>{email.recipientName}</Emphasis>
          {email.recipientName && ", "}
          {email.recipientEmail}
        </Text>
      ),
      statuses: generateStatuses(email),
      jobs: email.jobs?.nodes.length && (
        <>
          <Text>{formatMessage(messages.emailsTableJobsHeaderWithColon)}</Text>
          {renderJobNumbers(email.jobs?.nodes || [])}
        </>
      ),
      revenue: formatRevenue(email.revenue?.value),
    };
  }

  return function emailToDataListItem(
    email: CampaignEmailFragment,
  ): SingleEmail {
    return largeAndUp
      ? largeAndUpFormattedDataListItem(email)
      : smallerScreensFormattedDataListItem(email);
  };
}

function generateYesOrNoStatus(value: unknown) {
  return value ? messages.emailsBooleanYes : messages.emailsBooleanNo;
}
