import { useCallback, useState } from "react";
import { SortDirectionEnum } from "~/utilities/API/graphqlFranchiseManagement";
import type {
  SortInput,
  SortState,
} from "~/jobber/features/Reporting/components/Report/hooks/types";

function useFormatSort<T>(defaultSortKey: T) {
  const formatSort = useCallback(
    (sort: [SortState<T>] | []): SortInput<T> => {
      return {
        key: sort[0]?.id || defaultSortKey,
        direction: sort[0]?.desc
          ? SortDirectionEnum.DESCENDING
          : SortDirectionEnum.ASCENDING,
      };
    },
    [defaultSortKey],
  );
  return { formatSort };
}

export function useReportSorting<T>(
  defaultSortKey: T,
  resetPagination: () => void,
  desc = true,
) {
  const { formatSort } = useFormatSort<T>(defaultSortKey);
  const [reportSortingState, setSortingState] = useState<[] | [SortState<T>]>([
    {
      id: defaultSortKey,
      desc: desc,
    },
  ]);
  const handleSortChange = (sortState: [] | [SortState<T>]) => {
    resetPagination();
    setSortingState(sortState);
  };

  return { formatSort, reportSortingState, handleSortChange };
}
