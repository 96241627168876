import React, { useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Modal } from "@jobber/components/Modal";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";

const ctaName = "ftue-request-modal";

interface FirstTimeRequestModalProps {
  imageSrc: string;
}

export function FirstTimeRequestModal({
  imageSrc,
}: FirstTimeRequestModalProps) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    CTAMarkSeen(ctaName);
  }, []);

  const handleClose = () => {
    CTAMarkConverted(ctaName);
    setOpen(false);
  };

  return (
    <Modal
      title={"Requests in Jobber"}
      open={open}
      onRequestClose={handleClose}
      primaryAction={{
        label: "Got It!",
        onClick: handleClose,
      }}
    >
      <Content spacing="large">
        <img src={imageSrc} alt="" role="presentation" className="u-center" />
        <Text>
          Your clients can submit requests through your website, social media,
          channels, or client hub.
        </Text>

        <Text>
          You and your team can also create requests to keep track of new work
          that comes up in-person, on the phone, or over email.
        </Text>
      </Content>
    </Modal>
  );
}
