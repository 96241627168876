import React from "react";
import { Text } from "@jobber/components/Text";
import type { GlobalMessageLink } from "~/utilities/API/graphql";

interface GlobalBannerMessageProps {
  readonly message: string;
  readonly postMessageLink?: GlobalMessageLink;
}

export function GlobalBannerMessage({
  message,
  postMessageLink,
}: GlobalBannerMessageProps) {
  return (
    <Text>
      {`${message} `}
      {postMessageLink && (
        <>
          {`${postMessageLink.message} `}
          <a
            target={postMessageLink.openInSameWindow ? "_self" : "blank"}
            rel="noopener noreferrer"
            href={postMessageLink.url}
          >
            {postMessageLink.urlLabel}
          </a>
        </>
      )}
    </Text>
  );
}
