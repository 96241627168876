import { Children, type ReactNode, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import { Drawer } from "@jobber/components/Drawer";
import type { IconNames } from "@jobber/design";
import { useViewport } from "legacy/jobber/hooks/useViewport";
import { messages } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/messages";
import { isInHandheldView } from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import styles from "./FilterDisplay.module.css";

interface ConfigurableDatePickerProps {
  filterLabel: string;
  drawerLabel: string;
  children: ReactNode;
  onFilterApply(): void;
  onFilterClose(): void;
}
export function FilterDisplay({
  filterLabel,
  drawerLabel,
  children,
  onFilterApply,
  onFilterClose,
}: ConfigurableDatePickerProps) {
  const { formatMessage } = useIntl();
  const [openDateSelect, setOpenDateSelect] = useState<boolean>(false);
  const { innerWidth } = useViewport();

  const onClose = () => {
    onFilterClose();
    setOpenDateSelect(!openDateSelect);
  };

  const applyChanges = () => {
    onFilterApply();
    setOpenDateSelect(!openDateSelect);
  };

  const getAvailableFilters = () => {
    const nonUndefinedChildren = Children.toArray(children).filter(
      child => child !== undefined,
    );
    return nonUndefinedChildren.length;
  };

  const getButtonProps = (): {
    label: string;
    icon: IconNames | undefined;
  } => {
    const filtersCount = getAvailableFilters();
    return filtersCount > 1 && isInHandheldView(innerWidth)
      ? {
          label: "Filters",
          icon: undefined,
        }
      : {
          label: filterLabel,
          icon: "calendar",
        };
  };

  const dateSelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleFilterClose(event: MouseEvent) {
      if (
        dateSelectorRef.current &&
        !dateSelectorRef.current.contains(event.target as Node)
      ) {
        setOpenDateSelect(false);
      }
    }
    document.addEventListener("mousedown", handleFilterClose);
    return () => {
      document.removeEventListener("mousedown", handleFilterClose);
    };
  }, [dateSelectorRef]);

  return (
    <div className={styles.datePickerContainer} ref={dateSelectorRef}>
      <div className={styles.dateSelectorButton}>
        <Button
          type="primary"
          variation="subtle"
          onClick={onClose}
          {...getButtonProps()}
        />
      </div>
      {openDateSelect && (
        <>
          {/* Using this specific value here due to Atlantis Drawer strict rendering rule under 640px */}
          {/* This is temporary until we move to the new Report template elements */}
          {innerWidth < 640 ? (
            <div className={styles.mobileDateFilterDrawerWrapper}>
              <Drawer
                id="drawer-element"
                title={drawerLabel}
                open={openDateSelect}
                onRequestClose={onClose}
              >
                <div className={styles.dateSelectorDrawer}>{children}</div>
                <div className={styles.applyButton}>
                  <Button
                    label={formatMessage(messages.filterApply)}
                    onClick={applyChanges}
                    type="primary"
                    variation="subtle"
                  />
                </div>
              </Drawer>
            </div>
          ) : (
            <div className={styles.dateSelectorCardContainer}>
              <Card>
                <div className={styles.dateSelectorCardBody}>
                  {children}
                  <div className={styles.applyButton}>
                    <Button
                      label={formatMessage(messages.filterApply)}
                      onClick={applyChanges}
                      type="primary"
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
}
