import React, { useMemo } from "react";
import { RecommendationsScheduling } from "./RecommendationsScheduling";
import type { SchedulingParametersProps } from "../../OneOffScheduler";
import {
  useScheduleCardState,
  useSchedulingRecommendationsQuery,
  useSelectedPropertyLocation,
} from "../hooks";

export interface LocationCoordinates {
  latitude: number;
  longitude: number;
}

export const RecommendationsSchedulingContainer = ({
  repeatOptions,
  railsFormKey,
  notifyTeam,
  arrivalWindowParameters,
}: SchedulingParametersProps) => {
  const { schedulingState } = useScheduleCardState();
  const { latitude, longitude } = useSelectedPropertyLocation();

  const locationCoordinates: LocationCoordinates | undefined = useMemo(() => {
    if (latitude !== undefined && longitude !== undefined) {
      return { latitude, longitude };
    }
    return undefined;
  }, [latitude, longitude]);

  const recommendationsResponse = useSchedulingRecommendationsQuery({
    scheduledDate: schedulingState.startDate,
    duration: schedulingState.visitDuration * 60,
    location: locationCoordinates,
    assignees: schedulingState.schedulingInputTeamMemberIds.length
      ? schedulingState.schedulingInputTeamMemberIds
      : undefined,
  });

  return (
    <RecommendationsScheduling
      recommendationsResponse={recommendationsResponse}
      repeatOptions={repeatOptions}
      arrivalWindowParameters={arrivalWindowParameters}
      railsFormKey={railsFormKey}
      initialNotifyTeam={notifyTeam}
      locationCoordinates={locationCoordinates}
    />
  );
};
