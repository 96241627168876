import React from "react";
import * as squatch from "@saasquatch/squatch-js";
import { SquatchWidget } from "./components/SquatchWidget";

interface ReferralSaasquatchProps {
  tenantAlias: string;
  user: {
    id: number;
    accountId: number;
    firstName: string;
    lastName: string;
    email: string;
    segments: string[];
    locale?: string[];
  };
  jwt: string;
  engagementMedium?: string;
  widgetType?: string;
}

export function ReferralSaasquatchForm({
  tenantAlias,
  user,
  jwt,
  engagementMedium,
  widgetType,
}: ReferralSaasquatchProps) {
  window.squatchToken = jwt;
  window.squatchTenant = tenantAlias;
  window.squatchOnReady = () => {
    squatch.widgets().upsertUser({ user, engagementMedium, widgetType });
  };

  return <SquatchWidget widgetType={widgetType} />;
}
