import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.completeSetup.title",
    defaultMessage: "Managing reviews",
    description: "Title for the complete setup step",
  },
  description: {
    id: "setupGuide.completeSetup.description",
    defaultMessage:
      "Control which clients are asked to leave reviews before sending an invoice—clients with multiple invoices **will not receive more than one request within 6 months.**",
    description: "Description for the complete setup step",
  },
  primaryButton: {
    id: "setupGuide.completeSetup.primaryButton",
    defaultMessage: "Complete Setup",
    description: "Button that navigates to the reviews page",
  },
});
