import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@jobber/components";
import { useIntl } from "react-intl";
import type { OnDataOptions } from "@apollo/client";
import { useMutation, useSubscription } from "@apollo/client";
import { v4 as uuidV4 } from "uuid";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type {
  AutomatedReviewReplySubscription,
  AutomatedReviewReplySubscriptionVariables,
  ReviewReplyGenerateMutation,
  ReviewReplyGenerateMutationVariables,
} from "~/utilities/API/graphql";
import {
  AUTOMATED_REVIEW_REPLY_SUBSCRIPTION,
  REVIEW_REPLY,
} from "./GenerateResponse.graphql";
import { messages } from "./messages";

interface GenerateResponseProps {
  reviewComment?: string;
  setError?: (error: string | undefined) => void;
  setText?: (text: string) => void;
  setLoading?: (loading: boolean) => void;
  onResponseGenerated?: (response: string) => void;
}

export function GenerateResponse({
  reviewComment,
  setError,
  setText,
  setLoading,
  onResponseGenerated,
}: GenerateResponseProps) {
  const { formatMessage } = useIntl();
  const [conversationId] = useState(uuidV4());
  const [generateError, setGenerateError] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const { error: subscriptionError } = useSubscription<
    AutomatedReviewReplySubscription,
    AutomatedReviewReplySubscriptionVariables
  >(AUTOMATED_REVIEW_REPLY_SUBSCRIPTION, {
    variables: {
      conversationId,
    },
    onData: handleSubscriptionData,
  });
  const [sendReviewComment] = useMutation<
    ReviewReplyGenerateMutation,
    ReviewReplyGenerateMutationVariables
  >(REVIEW_REPLY);

  const onClick = useCallback(() => {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "generate_review_response",
    });

    setLoadingSubscription(true);
    sendReviewComment({
      variables: {
        conversationId,
        requestId: uuidV4(),
        input: {
          submission: reviewComment || "",
        },
      },
    }).catch(() => {
      setGenerateError(true);
    });
  }, [
    conversationId,
    reviewComment,
    sendReviewComment,
    setGenerateError,
    setLoadingSubscription,
  ]);

  useEffect(() => {
    if (generateError || subscriptionError) {
      setError?.(formatMessage(messages.error));
      setLoadingSubscription(false);
      setLoading?.(false);
    }

    if (loadingSubscription) {
      setLoading?.(true);
    }
  }, [
    setLoading,
    setError,
    generateError,
    formatMessage,
    subscriptionError,
    loadingSubscription,
    setLoadingSubscription,
  ]);

  return (
    <Button
      label={formatMessage(messages.buttonLabel)}
      icon="sparkles"
      type="primary"
      size="small"
      variation="subtle"
      loading={loadingSubscription}
      onClick={onClick}
    />
  );

  function handleSubscriptionData(
    response: OnDataOptions<AutomatedReviewReplySubscription>,
  ) {
    const { data, error } = response.data;

    if (error) {
      return setGenerateError(true);
    }
    if (data) {
      const reviewReply = data.reviewReply.content;

      if (
        reviewReply &&
        reviewReply.__typename == "AiAssistantReviewReplyResponse" &&
        reviewReply.response
      ) {
        if (onResponseGenerated) {
          onResponseGenerated(reviewReply.response);
        }

        setText?.(reviewReply.response || formatMessage(messages.placeholder));
        setLoadingSubscription(false);
        setLoading?.(false);
      }
    }
  }
}
