import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { messages } from "./messages";
import styles from "./ReportExport.module.css";
import type { ReportExportProps } from "./types";

export function ReportExport({
  exportFunction,
  getMenuActions,
  columnVisibilityConfig,
}: ReportExportProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.exportMenu}>
      {columnVisibilityConfig ? (
        <Menu
          activator={
            <Button
              label={formatMessage(messages.csvExportLabel)}
              iconOnRight={true}
              icon={"arrowDown"}
            />
          }
          items={[
            {
              actions: getMenuActions
                ? getMenuActions(columnVisibilityConfig)
                : [],
            },
          ]}
        />
      ) : (
        <Button
          label={formatMessage(messages.csvExportLabel)}
          onClick={() => exportFunction()}
        />
      )}
    </div>
  );
}
