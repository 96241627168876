/* eslint-disable import/no-internal-modules */
import React, { useEffect, useMemo } from "react";
import { format, set } from "date-fns";
import { createPortal } from "react-dom";
import { Button } from "@jobber/components/Button";
import { useSchedulingAssistant } from "jobber/workOrders/components/SchedulingAssistant/hooks/useSchedulingAssistant";
import { useSelectedPropertyLocation } from "jobber/workOrders/components/ScheduleCard/hooks/useSelectedPropertyLocation";
import { useScheduleCardState } from "jobber/workOrders/components/ScheduleCard/hooks/useScheduleCardState";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { SchedulingAssistant } from "./SchedulingAssistant";
import { type YearMonthDay, toYearMonthDay } from "./yearMonthDay";
import { today } from "./utils/today";
import { SchedulingSummary } from "./SchedulingSummary";
import { FullScreenModal } from "./FullScreenModal";
import classNames from "./SchedulingAssistantModal.module.css";
import { usePreloadCompanySchedule } from "./hooks/usePreloadCompanySchedule";
import { useAssignTeam } from "../AssignTeam";
import { getAccountZonedTime } from "../JobCost/utils";

export interface SchedulingAssistantModalProps {
  onClose: () => void;
  onCancel: () => void;
  initialDurationInSeconds: number;
  initialSelectedDate: YearMonthDay;
  initialSelectedTeamMembers: Map<string, boolean>;
  initialSelectedTime?: { start: Date; assigneeId: string };
  location?: { latitude: number; longitude: number };
}

export const SchedulingAssistantModalComponent = ({
  onClose,
  onCancel,
  initialDurationInSeconds,
  initialSelectedDate,
  initialSelectedTeamMembers,
  initialSelectedTime,
  location,
}: SchedulingAssistantModalProps) => {
  usePreloadCompanySchedule();

  const { ...props } = useSchedulingAssistant({
    location,
    initialDurationInSeconds,
    initialSelectedDate,
    initialSelectedTeamMembers,
    initialSelectedTime,
  });

  const selectedEmployeeTitle = useMemo(() => {
    if (props.selectedTime) {
      const selectedTime = props.selectedTime;
      const selectedTitle = props.filters.employees.find(
        x => x.id === selectedTime.assigneeId,
      )?.title;
      return selectedTitle;
    }
    return "";
  }, [props.filters.employees, props.selectedTime]);

  const formattedSelectedDate = useMemo(() => {
    if (props.selectedTime) {
      return format(props.selectedTime.start, "iii MMM d");
    }
    return "";
  }, [props.selectedTime]);

  const formattedSelectedTime = useMemo(() => {
    if (props.selectedTime && props.selectedEndTime) {
      return `${format(props.selectedTime.start, "p")} - ${format(
        props.selectedEndTime,
        "p",
      )}`;
    }
    return "";
  }, [props.selectedEndTime, props.selectedTime]);

  return (
    <div className={classNames.container}>
      <SchedulingAssistant onClose={onCancel} {...props} />
      <div className={classNames.actionBar}>
        <Button
          label="Cancel"
          onClick={onCancel}
          type="primary"
          variation="subtle"
        />
        <div />
        {props.selectedTime && props.selectedEndTime && (
          <SchedulingSummary
            dateSummary={`${formattedSelectedDate}, ${formattedSelectedTime}`}
            assigneeSummary={selectedEmployeeTitle}
          />
        )}
        <Button
          label="Select"
          onClick={() => {
            props.onSave();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export const SchedulingAssistantModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    initialDurationInSeconds,
    initialSelectedTime,
    location,
    loadingAssignableUsers,
    usersPreassigned,
    initialSelectedDate,
    initialSelectedTeamMembers,
  } = useInput();

  useEffect(() => {
    if (open && !loadingAssignableUsers) {
      Amplitude.TRACK_EVENT("Interacted with Scheduling Assistant", {
        interaction: "modal_opened",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        users_preassigned: usersPreassigned,
      });
    }
  }, [open, loadingAssignableUsers, usersPreassigned]);

  const onCancel = () => {
    onClose();
    Amplitude.TRACK_EVENT("Interacted with Scheduling Assistant", {
      interaction: "modal_cancelled",
    });
  };

  const content = (
    <FullScreenModal open={open} onClose={onCancel}>
      <SchedulingAssistantModalComponent
        onClose={onClose}
        onCancel={onCancel}
        initialDurationInSeconds={initialDurationInSeconds}
        initialSelectedDate={initialSelectedDate}
        initialSelectedTeamMembers={initialSelectedTeamMembers}
        initialSelectedTime={initialSelectedTime}
        location={location}
      />
    </FullScreenModal>
  );

  return createPortal(content, document.body);
};

function useInput() {
  const { latitude, longitude } = useSelectedPropertyLocation();
  const {
    assignableUsers,
    loading: loadingAssignableUsers,
    selectedAssignees,
  } = useAssignTeam();

  const { visitDuration, startDate, startTime, endTime, endDate } =
    useScheduleCardState().schedulingState;

  const initialSelectedTeamMembers =
    selectedAssignees.size > 0
      ? new Map(
          assignableUsers.map(user => [
            user.id,
            selectedAssignees.has(user.id),
          ]),
        )
      : new Map(assignableUsers.map(user => [user.id, true]));

  const usersPreassigned = selectedAssignees.size > 0;

  const { initialDurationInSeconds, initialSelectedTime } =
    useInitialSelectedTimeAndDuration({
      startDate,
      startTime,
      endDate,
      endTime,
      visitDuration,
      initialSelectedTeamMembers,
    });

  return {
    initialSelectedDate: toYearMonthDay(startDate || today()),
    initialDurationInSeconds,
    initialSelectedTime,
    location: latitude && longitude ? { latitude, longitude } : undefined,
    loadingAssignableUsers,
    usersPreassigned,
    startDate,
    initialSelectedTeamMembers,
  };
}

/**
 * This function is used to calculate the initial selected time and duration for the calendar to render the
 * starting selected time slot. The default duration is used if:
 * 1. The start time is not defined
 * 2. The end time is not defined
 * 3. The end time is before the start time
 * 4. The end time is the same as the start time
 *
 * A selected time is defined only if the start time was selected.
 */
// eslint-disable-next-line max-statements
const useInitialSelectedTimeAndDuration = ({
  startDate,
  startTime,
  endDate,
  endTime,
  visitDuration,
  initialSelectedTeamMembers,
}: {
  initialSelectedTeamMembers: Map<string, boolean>;
  startDate?: Date;
  startTime?: Date;
  endDate?: Date;
  endTime?: Date;
  visitDuration: number;
}) => {
  const { timezone } = useAccount();
  let initialDurationInSeconds = visitDuration * 60;
  let initialSelectedTime = undefined;

  // if we don't have the start time, we cannot calculate the initial selected time for the calendar to
  // render the starting selected time slot.  The modal will default to the first available time slot
  // returned from the backend.
  if (startTime === undefined) {
    return { initialDurationInSeconds, initialSelectedTime };
  }

  const initialSelectedStartDateAndTime = set(startDate || today(), {
    hours: startTime.getHours(),
    minutes: startTime.getMinutes(),
  });

  const initialSelectedUser = [...initialSelectedTeamMembers.entries()].find(
    ([, selected]) => selected,
  )?.[0];

  initialSelectedTime = {
    start: getAccountZonedTime(initialSelectedStartDateAndTime, timezone),
    assigneeId: initialSelectedUser || "",
  };

  if (endTime === undefined) {
    return { initialDurationInSeconds, initialSelectedTime };
  }

  const initialSelectedEndDateAndTime = set(endDate || startDate || today(), {
    hours: endTime.getHours(),
    minutes: endTime.getMinutes(),
  });

  // if the end time is before the start time, then use the default duration to determine the height of the
  // selected time slot
  if (
    initialSelectedEndDateAndTime.getDay() !==
      initialSelectedStartDateAndTime.getDay() ||
    initialSelectedStartDateAndTime.getTime() >=
      initialSelectedEndDateAndTime.getTime()
  ) {
    return { initialDurationInSeconds, initialSelectedTime };
  }

  initialDurationInSeconds = Math.floor(
    (initialSelectedEndDateAndTime.getTime() -
      initialSelectedStartDateAndTime.getTime()) /
      1000,
  );

  return {
    initialDurationInSeconds,
    initialSelectedTime,
  };
};
