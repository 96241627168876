export function isUrlValid(url: string | undefined) {
  return (
    url &&
    /^((http|https):\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g.test(
      url,
    )
  );
}

export const websiteUrlErrors = {
  required: "Business website or social media url is required",
  formatError: "Business website or social media url must be a valid url",
};
