import React, { type PropsWithChildren, useMemo, useState } from "react";
import { Chip, Chips } from "@jobber/components/Chips";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Modal } from "@jobber/components/Modal";
import { Checkbox } from "@jobber/components/Checkbox";
import { timeSelectionOptions } from "jobber/workOrders/components/ArrivalWindowPreview/utils";
import type { SettingsRowProps } from "jobber/settings/notifications/components/SettingsSection";
import type {
  NotificationFieldsFragment,
  TemplateAttributes,
} from "~/utilities/API/graphql";
import { assertHasTypename } from "utilities/types/assertTypes";
import styles from "./ArrivalWindowDialog.module.css";
import { isNumber } from "./utils";
import { ArrivalWindowStyleOptions } from "../ArrivalWindowStyleOptions";
import type {
  ArrivalWindowDialogState,
  OnChange,
} from "../ArrivalWindowDialogController";
import { useGetArrivalWindowRange } from "../hooks";

export interface ArrivalWindowDialogProps {
  initialDuration?: number;
  centered?: boolean;
  jobStartTime?: Date;
  loading: boolean;
  showOnboarding: boolean;
  formState: ArrivalWindowDialogState;
  section: SettingsRowProps | undefined;
  onChange: (
    props: OnChange,
  ) => Promise<{ errors: string[]; data: unknown } | undefined>;
  open: boolean;
}

export function ArrivalWindowDialog({
  showOnboarding,
  jobStartTime,
  formState,
  onChange,
  section,
  loading,
  open,
  children,
}: PropsWithChildren<ArrivalWindowDialogProps>): JSX.Element {
  const [saveLoading, setSaveLoading] = useState(false);
  const templates = section && section.notification.templates;

  const arrivalWindowRange = useGetArrivalWindowRange({
    jobStartTime,
    centered: formState.centered,
    duration: formState.defaultDuration,
  });

  const handleOnDurationChange = (updatedValue?: string) => {
    return onChange({
      action: "changeDuration",
      duration: isNumber(updatedValue) ? +updatedValue : undefined,
    });
  };

  const handleCancel = async () => {
    return onChange({ action: "dismissAllModals" });
  };

  const handleNext = async () => {
    await onChange({ action: "showReminderPreviews" });
  };

  const handleAccept = async () => {
    const templatesToUpdate = convertTemplatesForSave(templates);

    try {
      setSaveLoading(true);
      await onChange({
        action: "saveArrivalWindowOnboarding",
        updatedTemplates: { templates: templatesToUpdate },
      });
    } catch {
      // eslint-disable-next-line no-console
      console.error("Error saving arrival window onboarding");
    } finally {
      setSaveLoading(false);
    }
  };

  const buttonLabel = useMemo(() => {
    if (showOnboarding) {
      return "Next";
    }

    return "Set Arrival Window";
  }, [showOnboarding]);

  if (loading) {
    return (
      <Modal
        title="Arrival Window"
        open={open}
        primaryAction={{ label: "Loading..." }}
        tertiaryAction={{
          label: "Cancel",
          onClick: handleCancel,
          type: "primary",
          variation: "subtle",
        }}
      >
        <div className={styles.loadingContainer}>
          <div className="row collapse align-center u-paddingNone">
            <div className="columns spinner spinner--inline" />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        title="Arrival Window"
        open={open}
        onRequestClose={handleCancel}
        primaryAction={{
          label: buttonLabel,
          onClick: showOnboarding ? handleNext : handleAccept,
          loading: saveLoading,
        }}
        tertiaryAction={{
          label: "Cancel",
          onClick: handleCancel,
          type: "primary",
          variation: "subtle",
        }}
      >
        <div
          className={
            showOnboarding ? styles.experimentContainer : styles.blockContainer
          }
        >
          <Content spacing="large">
            <Content spacing="base">
              <Heading level={4}>{arrivalWindowRange}</Heading>
              <Chips
                selected={formState.defaultDuration.toString()}
                onChange={handleOnDurationChange}
              >
                {timeSelectionOptions.map(option => (
                  <Chip
                    key={option.value}
                    value={option.value.toString()}
                    label={option.label}
                  />
                ))}
              </Chips>
            </Content>
            {showOnboarding && (
              <>
                <Content spacing="small">
                  <Heading level={5}>Arrival window style</Heading>
                  <ArrivalWindowStyleOptions
                    centered={formState.centered}
                    onCenteredChange={() =>
                      onChange({ action: "toggleCentered" })
                    }
                  />
                </Content>
                <Content spacing="large">
                  <Checkbox
                    checked={formState.applyToFuture}
                    onChange={() => onChange({ action: "toggleApplyToFuture" })}
                    label="Apply to all current and future jobs"
                  />
                </Content>
              </>
            )}
          </Content>
          {children}
        </div>
      </Modal>
    </>
  );
}

function convertTemplatesForSave(
  templates?: NotificationFieldsFragment["templates"],
) {
  return templates?.nodes.map(template => {
    const convertedTemplate: TemplateAttributes = {
      id: template.id,
      message: template.message.current,
    };

    assertHasTypename(template);

    if (template.__typename !== "MessageTemplateSMS") {
      convertedTemplate.subject = template.subject
        ? template.subject.current
        : "";
    }

    return convertedTemplate;
  });
}
