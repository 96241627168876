import { gql } from "@apollo/client";

export const SEND_SAMPLE_DATA_MESSAGES = gql`
  mutation SendSampleDataMessages($input: SendSampleDataMessagesInput!) {
    happyPathExpSendSampleDataMessages(input: $input) {
      smsIncluded
      userErrors {
        message
      }
    }
  }
`;
