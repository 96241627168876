import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { ButtonDismiss } from "@jobber/components/ButtonDismiss";
import { Emphasis } from "@jobber/components/Emphasis";
import { useWindowWidth } from "./hooks";
import styles from "./TopHeaderCards.module.css";
import { HeaderCards } from "./HeaderCards";
import type { HeaderCard } from "./HeaderCard";

export interface TopHeaderCardsProps {
  readonly title: string;
  /**
   * The cards to render in the Header
   */
  readonly cards: HeaderCard[];

  readonly onDismiss?: () => void;

  readonly visible?: boolean;
}

export function TopHeaderCards({
  title,
  cards,
  onDismiss,
  visible,
}: TopHeaderCardsProps) {
  const windowWidth = useWindowWidth();

  if (!visible) return null;
  return (
    <Content spacing="base">
      <div className={styles.card}>
        <div className={styles.headerSection}>
          <div className={styles.heading}>
            <Heading level={3}>
              <Emphasis variation={"highlight"}>{title}</Emphasis>
            </Heading>
          </div>
          {onDismiss && (
            <div className={styles.dismissButtonContainer}>
              <ButtonDismiss ariaLabel="Close" onClick={onDismiss} />
            </div>
          )}
        </div>
        <HeaderCards
          cards={cards}
          size={windowWidth >= 768 ? "base" : "small"}
        />
      </div>
    </Content>
  );
}
