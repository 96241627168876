import React from "react";
import { EmptyState } from "jobber/chat/components/ChatDrawer/ChatList/EmptyState";

interface SearchAndSelectEmptyStateProps {
  searchTerm: string;
}

export function SearchAndSelectEmptyState({
  searchTerm,
}: SearchAndSelectEmptyStateProps) {
  return (
    <>
      <EmptyState
        title={getTitle(searchTerm)}
        description={getDescription()}
        iconName={"search"}
      />
    </>
  );
}

function getTitle(searchTerm: string) {
  return `No results for "${searchTerm}" found`;
}

function getDescription() {
  return `Try searching by client name or phone number`;
}
