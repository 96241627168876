export interface RegistrationBusinessInformationFieldsTypes {
  businessName: string | undefined;
  businessType: string | undefined;
  businessRegistrationNumber: string | undefined;
}

interface BusinessTypeStructure {
  label: string;
  value: string;
}

export const businessTypesList: BusinessTypeStructure[] = [
  { value: "SOLE_PROPRIETORSHIP", label: "Sole Proprietorship" },
  { value: "PARTNERSHIP", label: "Partnership" },
  {
    value: "LIMITED_LIABILITY_CORPORATION",
    label: "Limited Liability Corporation",
  },
  { value: "CO_OPERATIVE", label: "Co-operative" },
  { value: "NON_PROFIT_CORPORATION", label: "Non-profit Corporation" },
  { value: "CORPORATION", label: "Corporation" },
];

// Coverage for this file fails because of optional check (?)
// that must be used on ).value because TS think that it can retrun UNDEFINED.
// find function indeed returns undefined if it can't find the match.
// But in this case we guarantee that it will find the value.
// @ts-ignore
export const SOLE_PROPRIETORSHIP: string | undefined = businessTypesList.find(
  businessType => businessType.value === "SOLE_PROPRIETORSHIP",
).value;
