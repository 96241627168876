import React, { useMemo } from "react";
import type { AiAssistantSimplePlot } from "~/utilities/API/graphql";
import { ChartWithTitle } from "./ChartWithTitle";
import { CopilotLineChart } from "./CopilotLineChart";
import { CopilotBarChart } from "./CopilotBarChart";

interface DataVisualizationProps {
  parameters: AiAssistantSimplePlot;
}

export function DataVisualization({ parameters }: DataVisualizationProps) {
  const chartComponent = useMemo(() => {
    switch (parameters.chartType) {
      case "BAR":
        return <CopilotBarChart parameters={parameters} />;
      case "LINE":
        return <CopilotLineChart parameters={parameters} />;
      default:
        return null;
    }
  }, [parameters]);

  if (!chartComponent) {
    return null;
  }

  return (
    <ChartWithTitle title={parameters.title}>{chartComponent}</ChartWithTitle>
  );
}
