import type { MessageCenterActionType } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { useMarkConversationHook } from "jobber/chat/hooks";
import { clickedMarkUnread } from "jobber/chat/utilities/AmplitudeTrackingEvents";
import { Rollbar } from "~/utilities/errors/Rollbar";

export function useConversationActionFunctions(
  selectedConversationId: string | number | undefined,
  dispatch: React.Dispatch<MessageCenterActionType> | undefined,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
) {
  const [markConversationUnread, markConversationDeleted] =
    useMarkConversationHook();
  async function markUnread() {
    try {
      await markConversationUnread({
        variables: {
          id: selectedConversationId,
        },
      });

      clickedMarkUnread();

      if (dispatch) {
        dispatch({
          type: "HIDE_CHAT",
        });
      }
    } catch (err) {
      Rollbar.EXECUTE("Error Marking Conversation as Unread", err as Error);
      setErrorMessage("Unable to mark as unread. Please try again.");
    }
  }

  async function deleteConversation() {
    try {
      await markConversationDeleted({
        variables: {
          id: selectedConversationId,
        },
      });

      if (dispatch) {
        dispatch({
          type: "HIDE_CHAT",
        });
      }
    } catch (err) {
      Rollbar.EXECUTE("Error Deleting Conversation", err as Error);
      setErrorMessage("Unable to delete conversation. Please try again.");
    }
  }
  async function showReassignConversationScreen() {
    try {
      if (dispatch) {
        dispatch({
          type: "SHOW_REASSIGN_CONVERSATION",
          title: "Reassign",
        });
      }
    } catch (err) {
      Rollbar.EXECUTE("Error Deleting Conversation", err as Error);
      setErrorMessage("Unable to delete conversation. Please try again.");
    }
  }

  return {
    markUnread,
    deleteConversation,
    showReassignConversationScreen,
  };
}
