import React, { type Ref, forwardRef, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { InputText, type InputTextRef } from "@jobber/components/InputText";
import styles from "./InputSearch.module.css";
import { FilterButton } from "./components/FilterButton";

export const InputSearch = forwardRef(SearchInputInternal);

export interface InputSearchFilterOption {
  /**
   * Filter label
   */
  label: string;
  /**
   * Filter value
   */
  value: string;
  /**
   * Visual cue for the action label
   */
  icon?: IconNames;
}

export interface InputSearchFilterProps {
  /**
   * List of filter options
   */
  readonly filterOptions: InputSearchFilterOption[];

  readonly selectedFilter?: string;

  /**
   * Method that gets called when a filter is toggled
   *
   * @param filterValue - the value from the filterOptions for toggled filter
   */
  onFilterChange(filterValue: string): void;
}

export interface InputSearchProps {
  /**
   * ...
   */
  readonly placeholder: string;

  /**
   * Set the component to a given value
   */
  readonly value: string;

  /**
   * A numeric value to represents the milliseconds in delaying the function to populate
   * the data source when the 'value' changed.
   */
  readonly wait?: number;

  /**
   * A boolean value that sets visibility of magnitude lens icon. Defaults to true.
   */
  readonly showIcon?: boolean;

  /**
   * Optional filters to use in tandem with the search
   */
  readonly filter?: InputSearchFilterProps;

  /**
   * A callback function that handles the update of the new value of the property value.
   */
  onChange(newValue: string): void;

  /**
   * A callback function that handles the API call to search the value. This is where the
   * wait value is applied to the debounce function to give a delay in each input and API request.
   */
  onDebouncedChange(searchValue: string): void;

  /**
   * A boolean value that forces the visibility of the clear button. Defaults to true.
   */
  readonly showClearButton?: boolean;
}

function SearchInputInternal(
  {
    onChange,
    onDebouncedChange,
    wait = 300,
    value,
    showIcon = true,
    filter,
    showClearButton = true,
    ...inputTextProps
  }: InputSearchProps,
  ref: Ref<InputTextRef>,
) {
  const delayedSearch = debounce(onDebouncedChange, wait);
  const handleChange = onChange;
  const shouldShowClearButton = showClearButton && !!value;
  const showFilterButton = !shouldShowClearButton && !!filter;

  useEffect(() => {
    delayedSearch(value);
    return delayedSearch.cancel;
  }, [value, delayedSearch]);

  return (
    <div className={styles.container}>
      <div className={`${showIcon ? styles.inputTextContainer : ""}`}>
        <InputText
          {...inputTextProps}
          placeholder={value ? "" : inputTextProps.placeholder}
          onChange={handleChange}
          ref={ref}
          value={value}
        />
      </div>
      {showIcon && (
        <div className={styles.searchIconContainer}>
          <Icon name={"search"} color={"greyBlue"} />
        </div>
      )}

      <ClearButton hideButton={!shouldShowClearButton} onChange={onChange} />
      {showFilterButton && filter && (
        <div className={styles.actionIconContainer}>
          <FilterButton
            filterOptions={filter.filterOptions}
            onFilterChange={filter.onFilterChange}
          />
        </div>
      )}
    </div>
  );
}

interface ClearButtonProps {
  readonly hideButton: boolean;
  onChange(newValue: string): void;
}

function ClearButton({ hideButton, onChange }: ClearButtonProps) {
  const clearInput = useCallback(() => onChange(""), [onChange]);
  if (hideButton) {
    return <></>;
  }

  return (
    <button className={styles.actionIconContainer} onClick={clearInput}>
      <Icon name="cross" color="interactiveSubtle" />
    </button>
  );
}
