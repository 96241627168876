import React, { createContext, useEffect, useState } from "react";
import type { AddonDiscountGroup } from "~/utilities/API/graphql";

interface AddonInfo {
  addonIdentifier: string;
  name: string;
  price: number;
}

export interface AddonUpsellProps {
  addonsToUpsell: string[];
  originalAddonForPurchase?: AddonInfo;
  onAddonUpsellRejected: () => void;
  reset: () => void;
}

export const defaultAddonUpsellPropValue = {
  addonsToUpsell: [],
  onAddonUpsellRejected: () => {
    // Do nothing
  },
  reset: () => {
    // Do nothing
  },
  canUpsellAddon: false,
};

export const AddonUpsellContext = createContext<AddonUpsellProps>(
  defaultAddonUpsellPropValue,
);

interface AccountAddonInfo {
  identifier: string;
  name: string;
  includedWithAddonSetIdentifiers: string[];
  monthlyBillingCycle?: {
    monthlyCost: number;
  };
}

export interface AddonUpsellContextProviderProps {
  accountAddonInfo?: AccountAddonInfo;
  addonDiscountGroup?: AddonDiscountGroup;
  children: React.ReactNode;
}

export function AddonUpsellContextProvider({
  accountAddonInfo,
  addonDiscountGroup,
  children,
}: AddonUpsellContextProviderProps) {
  const addonsToUpsell = accountAddonInfo?.includedWithAddonSetIdentifiers;
  const addonInfo = toAddonInfo(accountAddonInfo, addonDiscountGroup);

  const [addonsToUpsellInState, setAddonsToUpsell] = useState<
    string[] | undefined
  >(addonsToUpsell);

  useEffect(() => {
    //only use effect for handling initial data loading
    if (addonsToUpsellInState === undefined) {
      setAddonsToUpsell(addonsToUpsell);
    }
  }, [addonsToUpsellInState, addonsToUpsell]);

  return (
    <AddonUpsellContext.Provider
      value={{
        addonsToUpsell: addonsToUpsellInState || [],
        originalAddonForPurchase: addonInfo,
        onAddonUpsellRejected: () => {
          const newVar = addonsToUpsellInState?.toSpliced(0, 1) || [];
          setAddonsToUpsell(newVar);
        },
        reset: () => {
          setAddonsToUpsell(addonsToUpsell);
        },
      }}
    >
      {children}
    </AddonUpsellContext.Provider>
  );
}

function toAddonInfo(
  accountAddonInfo?: AccountAddonInfo,
  addonDiscountGroup?: AddonDiscountGroup,
): AddonInfo | undefined {
  const addPrice =
    addonDiscountGroup?.monthlyAddonDiscount?.addonCostMonthlyDiscounted ||
    accountAddonInfo?.monthlyBillingCycle?.monthlyCost;
  const addonName = accountAddonInfo?.name;
  const identifier = accountAddonInfo?.identifier;

  if (addPrice !== undefined && addonName !== undefined && identifier) {
    return {
      addonIdentifier: identifier,
      name: addonName,
      price: addPrice,
    };
  }
  return undefined;
}
