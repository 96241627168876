import type { BillingAddressDisplayProps } from "~/bunker/paymentMethodForm/components/BillingAddress/BillingAddressDisplay";

export function resolvePostalCodeLine(
  address: Pick<BillingAddressDisplayProps, "city" | "province" | "pc">,
) {
  const { province = "", city = "", pc = "" } = address;
  let building = "";
  if (city && province) {
    building += `${city}, ${province}`;
  } else {
    building += city;
    building += province;
  }

  if (pc) {
    building = [building, pc].join(building ? " " : "");
  }

  return building;
}
