import { useMemo } from "react";

export function useTimeZone() {
  const IAnaTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeZoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const offset = useMemo(() => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const sign = offsetMinutes < 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offsetMinutes) / 60).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      },
    );
    const minutes = (Math.abs(offsetMinutes) % 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `(GMT${sign}${hours}:${minutes})`;
  }, []);

  return { IAnaTimeZoneName, timeZoneAbbreviation, offset };
}
