import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const util = PhoneNumberUtil.getInstance();

export function e164PhoneFormat(number: string, countryCode = "US"): string {
  const normalized = util.parse(number, countryCode);
  return util.format(normalized, PhoneNumberFormat.E164);
}

export function friendlyPhoneFormat(
  number: string,
  countryCode = "US",
): string {
  const normalized = util.parse(number, countryCode);
  return util.formatInOriginalFormat(normalized, countryCode);
}
