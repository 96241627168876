import {
  type DocumentNode,
  type OperationVariables,
  useLazyQuery,
} from "@apollo/client";
import { useCallback } from "react";

export function useReportData<T, V extends OperationVariables>(
  query: DocumentNode,
  queryVariables?: V,
) {
  const [fetchData, { data, loading, error }] = useLazyQuery<T, V>(query, {
    variables: queryVariables,
  });

  const _fetchData = useCallback(
    (variables: V) => {
      void fetchData({
        variables,
      });
    },
    [fetchData],
  );

  return {
    fetchData: _fetchData,
    data,
    loading,
    error,
  };
}
