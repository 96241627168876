import type { ArrivalWindowDialogState } from ".";

export function serializeStateForRailsController(
  state: ArrivalWindowDialogState,
) {
  return [
    {
      name: "arrival_window_default_duration",
      value: state.defaultDuration.toString(),
    },
    {
      name: "arrival_window_centered",
      value: state.centered ? "1" : "0",
    },
  ];
}
