import { defineMessages } from "react-intl";

export const messages = defineMessages({
  profitMarginTitle: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.profitMarginTitle",
    defaultMessage: "Profit margin",
    description: "Content for the profit margin header",
  },
  sampleProfitMarginValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleProfitMarginValue",
    defaultMessage: "20.14%",
    description: "Example value for the profit margin",
  },
  totalPriceTitle: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.totalPriceTitle",
    defaultMessage: "Total price",
    description: "Content for the total price header",
  },
  sampleTotalPriceValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleTotalPriceValue",
    defaultMessage: "$4,370.00",
    description: "Example value for the total price",
  },
  lineItemCostTitle: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.lineItemCostTitle",
    defaultMessage: "Line item cost",
    description: "Content for the line item cost header",
  },
  sampleLineItemCostValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleLineItemCostValue",
    defaultMessage: "$2,689.80",
    description: "Example value for the line item cost",
  },
  sampleLaborValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleLaborValue",
    defaultMessage: "$720.00",
    description: "Example value for the labor",
  },
  sampleExpensesValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleExpensesValue",
    defaultMessage: "$80.00",
    description: "Example value for the expenses",
  },
  sampleProfitValue: {
    id: "workOrders.jobCost.jobCostProfitMarginDemoContent.sampleProfitValue",
    defaultMessage: "$880.20",
    description: "Example value for the profit",
  },
});
