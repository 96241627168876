import React, { useEffect, useRef } from "react";
import { Text } from "@jobber/components/Text";
import { Spinner } from "@jobber/components/Spinner";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import styles from "./BuildingDashboard.module.css";
import type { SetupGuideStepProps } from "../../types";

export function BuildingDashboard(props: SetupGuideStepProps): JSX.Element {
  const { formatMessage } = useIntl();
  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      window.location.href = "/reviews";
    }, 2000);

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [props.navigation]);

  return (
    <div className={styles.stepContainer}>
      <div className={styles.spinner}>
        <Spinner />
      </div>
      <h1>{formatMessage(messages.header)}</h1>
      <Text size={"large"}>{formatMessage(messages.body)}</Text>
    </div>
  );
}
