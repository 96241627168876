import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { CoachingImage } from "./images";
import type { CoachingStepProps } from "./CoachingStep.d";
import { sendCoachingReminderEmail } from "./sendCoachingReminderEmail";
import styles from "./CoachingStep.module.css";

export function CoachingStep(props: CoachingStepProps) {
  const [reminderSuccess, setReminderSuccess] = useState(true);
  const [reminderButtonEnabled, setSendReminderButtonEnabled] = useState(true);
  const [reminderMessage, setReminderMessage] = useState("");
  const [reminderLoading, setReminderLoading] = useState(false);
  const [bookTimeButtonEnabled, setBookTimeButtonEnabled] = useState(true);
  const [bookTimeLoading, setBookTimeLoading] = useState(false);

  return (
    <Content spacing="large">
      <div className={styles.imageWrapper}>
        <img
          src={CoachingImage.src}
          alt={CoachingImage.alt}
          className={styles.imageClass}
        />
      </div>
      <Text>{contentText()}</Text>
      <div className="u-textCenter">
        <div className={styles.buttonWrapper}>
          <Button
            label={"Book a Time"}
            type="primary"
            external={true}
            loading={bookTimeLoading}
            onClick={handleBookSessionClick}
            disabled={!bookTimeButtonEnabled}
            size="base"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            label={"Remind Me Later"}
            type="secondary"
            loading={reminderLoading}
            onClick={handleSendEmailClick}
            disabled={!reminderButtonEnabled}
            size="base"
          />
        </div>
      </div>
      {renderEmailReminderStatus()}
    </Content>
  );

  function contentText() {
    return "Book an initial 25-minute call to meet your Product Coach! Your Product Coach will work with you to create a free customized training plan so you can get the most out of running your business on Jobber.";
  }

  function renderEmailReminderStatus() {
    if (!reminderSuccess) {
      return (
        <Content spacing="large">
          <Text variation="error">{reminderMessage}</Text>
        </Content>
      );
    }
  }

  function handleBookSessionClick() {
    setBookTimeLoading(true);
    setSendReminderButtonEnabled(false);

    const newWindow = window.open(
      props.calendyUrl,
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = undefined;

    props.buttonClick();
  }

  function handleSendEmailClick() {
    setReminderLoading(true);
    setBookTimeButtonEnabled(false);

    sendCoachingReminderEmail(props.email)
      .then(function (response) {
        if (response.success) {
          props.buttonClick();
        } else {
          reminderButtonFailedState();
          setReminderMessage(response.message);
        }
      })
      .catch(function () {
        reminderButtonFailedState();
        setReminderMessage(
          "Something went wrong. Please book a session with the direct link.",
        );
      });
  }

  function reminderButtonFailedState() {
    setReminderLoading(false);
    setBookTimeButtonEnabled(true);
    setReminderSuccess(false);
  }
}
