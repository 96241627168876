import type { CustomFieldValue } from "./CustomField";
import type { AreaValue, LinkValue, NumberValue, SelectValue } from "./types";

export type CustomFieldsValueType =
  | "area"
  | "bool"
  | "int"
  | "link"
  | "select"
  | "text";

export interface ConfigurationState {
  configurationId: number | string;
  label: string;
  readOnly: boolean;
  stringValue: string;
  type: CustomFieldsValueType;
  value: CustomFieldValue;
  transferedFromId?: string;
  error?: string;
}

export interface GroupState {
  appIconUrl?: string;
  appTitle?: string;
  byConfiguration: {
    [index: string]: ConfigurationState;
  };
  allConfigurations: string[];
}

export interface State {
  byGroup: {
    [index: string]: GroupState;
  };
  allGroups: string[];
  configurationIdToGroupKey: {
    [index: number]: string;
  };
  configurationIdToConfigurationKey: {
    [index: number]: string;
  };

  showLoadError: boolean;
  showTipCard: boolean;
}

interface RailsAreaValue {
  length: string;
  width: string;
  unit: string;
}

interface RailsNumberValue {
  number: string;
  unit: string;
}

interface RailsLinkValue {
  text: string;
  url?: string;
}

interface RailsSelectValue {
  options: string[];
  selection: string;
}

// RailsCustomFieldValue (data from Rails) and CustomFieldValue (used for React/TypeScript)
// don't line up exactly. The function initReducerFromRailsCustomFieldGroups transforms one
// to the other.
type RailsCustomFieldValue =
  | RailsAreaValue /* type: "area" */
  | boolean /* type: "bool" */
  | RailsNumberValue /* type: "int" */
  | RailsLinkValue /* type: "link" */
  | RailsSelectValue /* type: "select" */
  | string; /* type: "text" */

export interface RailsCustomFieldGroup {
  app?: {
    iconUrl: string;
    title: string;
    id: number;
  };
  customFields: {
    configurationId: number;
    isBlank: boolean;
    name: string;
    readOnly: boolean;
    stringifiedValue: string;
    type: string;
    value: RailsCustomFieldValue;
    valueId: number;
    error?: string;
  }[];
}

export type Action =
  | {
      type: "Replace Value";
      configurationId: number;
      value: CustomFieldValue;
    }
  | {
      type: "Update Area";
      configurationId: number;
      length: number;
      width: number;
    }
  | {
      type: "Update Link";
      configurationId: number;
      text: string;
      url: string;
    }
  | {
      type: "Update Number";
      configurationId: number;
      value: number;
    }
  | {
      type: "Update Selection";
      configurationId: number;
      value: string;
    }
  | {
      type: "Set Show Load Error";
      configurationId: number;
    }
  | {
      type: "Set Show Tip Card";
      configurationId: number;
      value: boolean;
    }
  | {
      type: "Add New Custom Field";
      configurationId: number;
      configurationState: ConfigurationState;
    };

export function initReducerFromRailsCustomFieldGroups(
  railsCustomFieldGroups: RailsCustomFieldGroup[],
) {
  const state: State = {
    byGroup: {},
    allGroups: [],
    configurationIdToConfigurationKey: {},
    configurationIdToGroupKey: {},

    showLoadError: false,
    showTipCard: false,
  };

  railsCustomFieldGroups.forEach(function (
    railsCustomFieldGroup: RailsCustomFieldGroup,
    groupIndex: number,
  ) {
    const groupKey = `group${groupIndex}`;

    state.byGroup[groupKey] = {
      appIconUrl: railsCustomFieldGroup?.app?.iconUrl,
      appTitle: railsCustomFieldGroup?.app?.title,
      byConfiguration: {},
      allConfigurations: [],
    };

    state.allGroups.push(groupKey);

    // Build the React state from the initial Rails state.
    railsCustomFieldGroup.customFields.forEach(function (
      customField,
      customFieldIndex: number,
    ) {
      const configurationKey = `configuration${customFieldIndex}`;

      let valueInState: CustomFieldValue;
      let selectOptions: string[];
      let selectValue: string;

      switch (customField.type) {
        case "area":
          valueInState = {
            length: Number((customField.value as RailsAreaValue).length),
            width: Number((customField.value as RailsAreaValue).width),
            unit: (customField.value as RailsAreaValue).unit,
          };
          break;
        case "int":
          valueInState = {
            number: Number((customField.value as RailsNumberValue).number),
            unit: (customField.value as RailsNumberValue).unit,
          };
          break;
        case "select":
          selectOptions = (customField.value as RailsSelectValue).options;
          selectValue = (customField.value as RailsSelectValue).selection;

          if (!selectOptions.some(option => option === selectValue)) {
            selectValue = selectOptions[0];
          }

          valueInState = {
            options: selectOptions,
            selection: selectValue,
          };
          break;
        default:
          // All the other states can be copied straight across.
          valueInState = customField.value as CustomFieldValue;
      }

      state.byGroup[groupKey].byConfiguration[configurationKey] = {
        configurationId: customField.configurationId,
        label: customField.name,
        readOnly: customField.readOnly,
        stringValue: customField.stringifiedValue,
        type: customField.type as CustomFieldsValueType,
        value: valueInState,
        error: customField?.error,
      };

      state.byGroup[groupKey].allConfigurations.push(configurationKey);
      state.configurationIdToGroupKey[customField.configurationId] = groupKey;
      state.configurationIdToConfigurationKey[customField.configurationId] =
        configurationKey;
    });
  });

  return state;
}

function extractFirstInteger(key: string): number {
  return parseInt(key.replace(/[^0-9]*/, ""), 10);
}

export function reducer(state: State, action: Action): State {
  const groupKey = state.configurationIdToGroupKey[action.configurationId];
  const configurationKey =
    state.configurationIdToConfigurationKey[action.configurationId];

  let newState: State;
  let newGroupKey: string;
  const newConfigurationKey = "configuration0";

  switch (action.type) {
    case "Replace Value":
      newState = {
        ...state,
        byGroup: {
          ...state.byGroup,
          [groupKey]: {
            ...state.byGroup[groupKey],
            byConfiguration: {
              ...state.byGroup[groupKey].byConfiguration,
              [configurationKey]: {
                ...state.byGroup[groupKey].byConfiguration[configurationKey],
                value: action.value,
              },
            },
          },
        },
      };
      break;

    case "Update Area":
      newState = { ...state };
      (
        newState.byGroup[groupKey].byConfiguration[configurationKey]
          .value as AreaValue
      ).length = action.length;
      (
        newState.byGroup[groupKey].byConfiguration[configurationKey]
          .value as AreaValue
      ).width = action.width;
      break;

    case "Update Link":
      newState = { ...state };
      (
        newState.byGroup[groupKey].byConfiguration[configurationKey]
          .value as LinkValue
      ).text = action.text;
      (
        newState.byGroup[groupKey].byConfiguration[configurationKey]
          .value as LinkValue
      ).url = action.url;
      break;

    case "Update Number":
      newState = { ...state };
      (
        newState.byGroup[groupKey].byConfiguration[configurationKey]
          .value as NumberValue
      ).number = action.value;
      break;

    case "Update Selection":
      newState = { ...state };

      if (
        (
          newState.byGroup[groupKey].byConfiguration[configurationKey]
            .value as SelectValue
        ).options.some(x => x === action.value)
      ) {
        (
          newState.byGroup[groupKey].byConfiguration[configurationKey]
            .value as SelectValue
        ).selection = action.value;
      }
      break;

    case "Set Show Load Error":
      newState = { ...state };
      newState.showLoadError = true;
      break;

    case "Set Show Tip Card":
      newState = { ...state };
      newState.showTipCard = action.value;
      break;

    case "Add New Custom Field":
      newState = { ...state };

      if (state.allGroups.length === 0) {
        newGroupKey = "group0";
      } else {
        newGroupKey = `group${
          extractFirstInteger(state.allGroups[state.allGroups.length - 1]) + 1
        }`;
      }

      newState.byGroup[newGroupKey] = {
        byConfiguration: {
          configuration0: action.configurationState,
        },
        allConfigurations: [newConfigurationKey],
      };
      newState.allGroups.push(newGroupKey);

      newState.configurationIdToGroupKey[
        action.configurationState.configurationId as number
      ] = newGroupKey;
      newState.configurationIdToConfigurationKey[
        action.configurationState.configurationId as number
      ] = newConfigurationKey;

      break;

    default:
      return state;
  }

  return newState;
}
