import React from "react";
import { Button, Emphasis, Heading } from "@jobber/components";
import classnames from "classnames";
import { useIntl } from "react-intl";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";
import { newSpApprovedStateMessages } from "./newSpApprovedStateMessages";

export function UkKycNewSpApprovedState() {
  const { formatMessage } = useIntl();
  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderTop",
  );

  return (
    <div className={cssClasses}>
      <Heading level={4}>
        {formatMessage(newSpApprovedStateMessages.chooseYoureFragment)}{" "}
        <Emphasis variation={"highlight"}>
          {formatMessage(newSpApprovedStateMessages.numberFragment)}
        </Emphasis>
      </Heading>
      <p>{formatMessage(newSpApprovedStateMessages.thankYouMessage)}</p>
      <Button
        label={formatMessage(newSpApprovedStateMessages.approvedButtonText)}
        fullWidth={true}
        size={"base"}
        variation={"work"}
        onClick={() => (window.location.href = "/text_messaging_settings")}
      />
    </div>
  );
}
