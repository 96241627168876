import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import {
  StoredCardListItem,
  type StoredPaymentMethodInterface,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/StoredCardListItem";
import { StoredPaymentMethodType } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import { messages } from "~/bunker/payments_react/clientHubJobberPayments/utilities/messages";
import styles from "./BankPaymentSecurityText.module.css";

export interface BankInfo {
  institutionName: string;
  accountName: string;
  accountMask: string;
}

export interface BankPaymentSecurityTextProps {
  bankPaymentInfo: BankInfo | undefined;
}

export function BankPaymentSecurityText(props: BankPaymentSecurityTextProps) {
  const { formatMessage } = useIntl();
  const { bankPaymentInfo } = props;

  if (bankPaymentInfo === undefined) {
    return (
      <div className={styles.bankACHSecurityText}>
        <div className={styles.bankIconLargeSpace}>
          <img
            alt={formatMessage(messages.altBankIcon)}
            src={window.SG1_IMAGE_MANIFEST["svg/bank.svg"]}
            className={styles.cardBrandClassNames}
          />
        </div>
        <div>
          <Text variation="subdued">
            {formatMessage(messages.bankACHSecurityText)}
          </Text>
        </div>
      </div>
    );
  } else {
    const paymentMethod: StoredPaymentMethodInterface = {
      bankName: bankPaymentInfo?.institutionName,
      isDefault: false,
      last4: bankPaymentInfo?.accountMask,
      type: StoredPaymentMethodType.BANK_ACCOUNT,
    };
    return (
      <Content>
        <StoredCardListItem {...paymentMethod} />
      </Content>
    );
  }
}
