/* eslint-disable no-restricted-imports */
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import React from "react";
import { HiddenFields } from "./HiddenFields";
import type { JobberPaymentsPaymentContentProps } from "./JobberPaymentsPaymentContentProps.d";
import { AmountInput } from "../AmountInput";
import { ClientBalanceDisplay } from "../ClientBalanceDisplay";
import { HiddenField } from "../HiddenField";
import { JobberPaymentsCreditCardForm } from "../JobberPaymentsCreditCardForm";
import { PaymentRecordForm } from "../PaymentRecordForm";
import { PaymentTypeSelector } from "../PaymentMethodSelector";
import { JobberPaymentsAchForm } from "../JobberPaymentsAchForm";

const CLIENT_HUB_ORIGIN = 3;

export function JobberPaymentsPaymentContent(
  props: JobberPaymentsPaymentContentProps,
) {
  const { paymentState, disablePaymentTypeEdit } = props;

  // Used for <form>
  const { onSubmit, formElementRef } = props;

  // Used for <PaymentTypeSelector>
  const { groupedPaymentTypes, onPaymentTypeChange, isPaymentInProgress } =
    props;

  // Used as condition to show <HiddenField> or <PaymentTypeSelector>
  const { disableAmountEdit } = props;

  // Used for <AmountInput>
  const { onNewAmount } = props;

  // Used as condition and props to render <PaymentRecordForm>
  const { isJobberPaymentsCreditCard, defaultNoteValue, isJobberPaymentsAch } =
    props;

  // Used for <ClientBalanceDisplay>
  const { clientDetails } = props;

  // Used for <HiddenFileds>
  const { parentProps } = props;

  const renderPaymentForm = () => {
    if (isJobberPaymentsAch) {
      return <JobberPaymentsAchForm clientId={props.clientId} />;
    } else if (isJobberPaymentsCreditCard) {
      return (
        <JobberPaymentsCreditCardForm
          clientId={props.clientId}
          paymentRequest={props.paymentRequest}
          setPaymentRequest={props.setPaymentRequest}
          inClientHub={parentProps.paymentOrigin === CLIENT_HUB_ORIGIN}
        />
      );
    } else {
      return <PaymentRecordForm defaultNoteValue={defaultNoteValue} />;
    }
  };

  return (
    <Content>
      {paymentState.response && paymentState.response.errorMessage && (
        <Banner type="error">{paymentState.response.errorMessage}</Banner>
      )}

      <form onSubmit={onSubmit} ref={formElementRef}>
        {disablePaymentTypeEdit ? (
          <HiddenField
            name="balance_adjustment[payment_type]"
            value={paymentState.paymentType}
          />
        ) : (
          <PaymentTypeSelector
            name="balance_adjustment[payment_type]"
            groupedPaymentTypes={groupedPaymentTypes}
            selectedType={paymentState.paymentType}
            onPaymentTypeChange={onPaymentTypeChange}
            disabled={isPaymentInProgress}
          />
        )}
        {disableAmountEdit ? (
          <HiddenField
            name="balance_adjustment[clean_amount]"
            value={paymentState.amount}
          />
        ) : (
          <AmountInput
            name="balance_adjustment[clean_amount]"
            amount={paymentState.amount}
            onAmountChange={onNewAmount}
            disabled={isPaymentInProgress}
          />
        )}
        {renderPaymentForm()}
        <HiddenFields {...parentProps} />
        <button hidden={true} type="submit" disabled={isPaymentInProgress} />
      </form>
      <ClientBalanceDisplay clientDetails={clientDetails} />
    </Content>
  );
}
