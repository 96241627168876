/* eslint-disable @typescript-eslint/naming-convention */
import {
  closeOnPendingQuotesOptions,
  dateFieldOptions,
  dayOptions,
  modelOptions,
  monthOptions,
} from "./dropdownOptions";
import { FieldType } from "../RuleExpression";
import type { AutomationTrigger } from "../types";

// All options for automationRule.trigger.arguments.time must be in hours
export const defaultTriggerFields = (trigger: AutomationTrigger) => {
  return {
    days: {
      argument: trigger.arguments.time,
      fieldType: FieldType.DROPDOWN,
      options: dayOptions,
      readOnly: true,
    },
    months: {
      argument: trigger.arguments.time,
      fieldType: FieldType.DROPDOWN,
      options: monthOptions,
      readOnly: true,
    },
    closeOnPendingQuotesOptions: {
      argument: trigger.arguments.time,
      fieldType: FieldType.DROPDOWN,
      options: closeOnPendingQuotesOptions,
      readOnly: true,
    },
    model: {
      argument: trigger.arguments.model_type,
      fieldType: FieldType.DROPDOWN,
      options: modelOptions,
      readOnly: true,
    },
    date_field: {
      argument: trigger.arguments.date_field,
      fieldType: FieldType.DROPDOWN,
      options: dateFieldOptions,
      readOnly: true,
    },
  };
};
