import { useState } from "react";
import { useCampaignCheckFromEmailValidationCodeMutation } from "jobber/campaigns/hooks/useCampaignCheckFromEmailValidationCode";
import { useCampaignRequestFromEmailValidationCodeMutation } from "jobber/campaigns/hooks/useCampaignRequestFromEmailValidationCodeMutation";
import type {
  CommsCampaignReviewQuery,
  CommsCampaignReviewQueryVariables,
} from "~/utilities/API/graphql";

interface UseCampaignCheckFromEmail {
  campaignData: CommsCampaignReviewQuery | undefined;
  refetchCampaign(variables: CommsCampaignReviewQueryVariables): void;
}

export function useCampaignCheckFromEmail({
  campaignData,
  refetchCampaign,
}: UseCampaignCheckFromEmail) {
  const [showValidateEmailModal, setShowValidateEmailModal] =
    useState<boolean>(false);

  const isFromEmailValidated =
    campaignData?.commsCampaign?.fromEmailAddress?.isValidatedEmail || false;
  const toggleModal = () => setShowValidateEmailModal(!showValidateEmailModal);

  const {
    sendCampaignRequestFromEmailValidationCode,
    data: requestEmailValidationCodeData,
  } = useCampaignRequestFromEmailValidationCodeMutation();

  const {
    checkCampaignFromEmailValidationCode,
    data: fromEmailValidationCodeData,
    loading: isCheckValidationCodeDataLoading,
  } = useCampaignCheckFromEmailValidationCodeMutation();

  async function sendValidationCode() {
    resetAllErrors();
    await sendCampaignRequestFromEmailValidationCode();
  }

  async function checkValidationCode(code: string) {
    resetAllErrors();
    if (code) {
      const response = await checkCampaignFromEmailValidationCode({
        code: code,
      });

      if (response.status === "approved" && campaignData?.commsCampaign?.id) {
        setShowValidateEmailModal(false);
        refetchCampaign({
          id: campaignData.commsCampaign.id,
        });
      }
    }
  }

  function resetAllErrors() {
    if (fromEmailValidationCodeData) {
      fromEmailValidationCodeData.campaignCheckFromEmailValidationCode.userErrors =
        [];
    }
    if (requestEmailValidationCodeData) {
      requestEmailValidationCodeData.campaignRequestFromEmailValidationCode.userErrors =
        [];
    }
  }

  const getLatestError = () => {
    const checkValidationCodeError =
      fromEmailValidationCodeData?.campaignCheckFromEmailValidationCode
        ?.userErrors[0]?.message || "";
    const requestValidationCodeError =
      requestEmailValidationCodeData?.campaignRequestFromEmailValidationCode
        ?.userErrors[0]?.message || "";

    if (checkValidationCodeError) {
      return checkValidationCodeError;
    }

    if (requestValidationCodeError) {
      return requestValidationCodeError;
    }

    return "";
  };

  return {
    toggleModal,
    isFromEmailValidated,
    showValidateEmailModal,
    sendValidationCode,
    checkValidationCode,
    isCheckValidationCodeDataLoading,
    error: getLatestError(),
  };
}
