import { type MutableRefObject, type ReactNode, useRef, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Popover } from "@jobber/components/Popover";
import { useIntl } from "react-intl";
import styles from "./InfoPopover.module.css";
import { messages } from "./messages";

export interface InfoPopoverProps {
  header: string;
  children: ReactNode;
  iconAriaLabel?: string;
  learnMoreLink?: string;
  externalLink?: boolean;
}

export function InfoPopover({
  header,
  children,
  iconAriaLabel,
  learnMoreLink,
  externalLink = true,
}: InfoPopoverProps) {
  const divRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { formatMessage } = useIntl();
  const [showPopover, setShowPopover] = useState(false);
  const handleMouseEvent = () => setShowPopover(!showPopover);
  const closePopover = () => setShowPopover(false);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code. */}
      <div
        ref={divRef}
        className={styles.infoIcon}
        onClick={handleMouseEvent}
        aria-label={
          iconAriaLabel || formatMessage(messages.moreInfoButtonDefaultMessage)
        }
        role="button"
      >
        <Icon name="help" color="interactiveSubtle" size="large" />
      </div>
      <Popover
        attachTo={divRef}
        open={showPopover}
        preferredPlacement="bottom"
        onRequestClose={closePopover}
      >
        <div className={styles.popoverContainer}>
          <Content>
            <Heading level={4}>{header}</Heading>
            {children}
            {Boolean(learnMoreLink) && (
              <Button
                label={formatMessage(messages.learnMoreButtonLabel)}
                variation="learning"
                type="secondary"
                size="small"
                url={learnMoreLink}
                external={externalLink}
              />
            )}
          </Content>
        </div>
      </Popover>
    </>
  );
}
