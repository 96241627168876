import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import type { Feature } from "~/utilities/API/graphql";
import { useMobileBillingInfo } from "jobber/campaigns/hooks/useMobileBillingInfo";
import { EMAIL_CAMPAIGNS_FEATURE_QUERY } from "./useEmailCampaignsPreview.graphql";

/**
 * This hook is used to determine if the SP preview is enabled for email campaigns feature.
 * This hook should be used to to display campaign upsell CTAs, whereas `useEmailCampaignsPurchaseFlow` should be used to show the purchase flow
 *
 * `validAdditionalCondition` should be used for additional validation or other one off conditions. Otherwise explicitly pass in `true` to ignore this check.
 */
export function useEmailCampaignsPreview(validAdditionalCondition: boolean) {
  const { data, loading: featureCheckLoading } = useQuery<FeatureQuery>(
    EMAIL_CAMPAIGNS_FEATURE_QUERY,
    {
      fetchPolicy: "network-only",
    },
  );
  const { isMobileBilled, loading: isMobileBilledLoading } =
    useMobileBillingInfo();
  const loading = isMobileBilledLoading || featureCheckLoading;
  const emailCampaignsEnabled = data?.feature?.enabled;
  const emailCampaignsAvailable = data?.feature?.available || isMobileBilled;

  const previewEnabled = useMemo(() => {
    return (
      !loading &&
      !emailCampaignsEnabled &&
      emailCampaignsAvailable &&
      validAdditionalCondition
    );
  }, [
    loading,
    emailCampaignsEnabled,
    emailCampaignsAvailable,
    validAdditionalCondition,
  ]);
  return { previewEnabled, isMobileBilled, loading };
}

export interface FeatureQuery {
  feature: Feature;
}
