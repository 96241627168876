import React from "react";
import classNames from "classnames";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import styles from "./CustomFieldTypes.module.css";
import type { LinkValue } from "../types";

interface CustomFieldEditProps {
  editing: boolean;
  onChange(newValue: LinkValue): void;
}

interface CustomFieldLinkProps {
  isInAppGroup?: boolean;
  label: string;
  value: LinkValue;
  editProps?: CustomFieldEditProps;
}

export function CustomFieldLink({
  isInAppGroup = false,
  label,
  value,
  editProps,
}: CustomFieldLinkProps) {
  if (editProps?.editing) {
    return (
      <div className={classNames([styles.container])} title="container">
        <div
          className={classNames({
            [styles.label]: true,
            [styles.labelEdit]: true,
            [styles.linkLabel]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div
          className={classNames([
            styles.value,
            styles.valueEdit,
            styles.linkValue,
          ])}
          title="value"
        >
          <div className={styles.linkName}>
            <Text>{value.text}</Text>
          </div>
          <InputText
            size="small"
            placeholder={`URL`}
            value={value.url}
            onChange={onURLChange}
          />
        </div>
      </div>
    );
  } else {
    const linkText = value.text ? value.text : value.url;

    return (
      <div className={styles.container} title="container">
        <div
          className={classNames({
            [styles.label]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div className={styles.value} title="value">
          <a target="_blank" href={value.url} rel="noreferrer">
            {linkText}
          </a>
        </div>
      </div>
    );
  }

  function onURLChange(newURL: string) {
    editProps?.onChange({
      text: value.text,
      url: newURL,
    });
  }
}
