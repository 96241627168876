import { useState } from "react";
import type { SubmissionResponse } from "../../types";

export function useSubmission(
  hasExistingSubmission: boolean,
): [
  SubmissionResponse | undefined,
  React.Dispatch<React.SetStateAction<SubmissionResponse | undefined>>,
] {
  return useState<SubmissionResponse | undefined>(() =>
    hasExistingSubmission ? { type: "success" } : undefined,
  );
}
