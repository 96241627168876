import { gql } from "@apollo/client";

export const NEW_SYNC_STATUS_SUBSCRIPTION = gql`
  subscription QBOObjectSyncStats {
    qboObjectSyncStats {
      qboSyncStats {
        modelId
        modelType
        message
      }
    }
  }
`;
