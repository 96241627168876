import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { InputCurrency } from "components/InputCurrency";
import { messages } from "./messages";

interface LineItemTotalPriceProps {
  totalPrice: number | undefined;
  reactKey: string;
  onChange: (value: number) => void;
}

export function LineItemTotalPrice({
  reactKey,
  totalPrice,
  onChange,
}: LineItemTotalPriceProps) {
  const { formatMessage } = useIntl();
  const { currencySymbol } = useAccount();

  return (
    <InputCurrency
      name={`lineItems.${reactKey}.total`}
      value={totalPrice}
      prefix={{ label: currencySymbol }}
      placeholder={formatMessage(messages.totalPrice)}
      autocomplete={false}
      maximumDecimalPlaces={2}
      onChange={onChange}
    />
  );
}
