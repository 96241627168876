import React from "react";
import { Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { Receivables } from "./Receivables";
import { useReceivables } from "./useReceivables";
import { LoadingLayout } from "../LoadingLayout";
import { FinancialInsightCardWithButtonWrapper } from "../FinancialInsightCardWithButtonWrapper";

export const ReceivablesLoader = () => {
  const { formatMessage } = useIntl();
  const { data, loading } = useReceivables();
  let cardContent;

  if (loading) {
    cardContent = <LoadingLayout />;
  } else {
    cardContent = (
      <Receivables
        totalBalance={data?.topClientsBalance.totalBalance}
        numberOfClientsWithBalances={
          data?.topClientsBalance.numberOfClientsWithBalances
        }
        averageDaysToGettingPaid={
          data?.insightsAndAccountingReceivables?.averageDaysToGettingPaid
        }
        percentageOfUpcomingJobsOnAutopay={
          data?.insightsAndAccountingReceivables
            ?.percentageOfUpcomingJobsOnAutopay
        }
        automaticPaymentsEnabled={data?.feature?.available}
      />
    );
  }

  return (
    <FinancialInsightCardWithButtonWrapper>
      <Heading level={3}>{formatMessage(messages.receivablesHeading)}</Heading>
      {cardContent}
    </FinancialInsightCardWithButtonWrapper>
  );
};
