import { gql } from "@apollo/client";

export const PRODUCT_OR_SERVICE_CUSTOM_FIELD_FRAGMENT = gql`
  fragment ProductOrServiceCustomField on CustomFieldUnion {
    ... on CustomFieldLink {
      id
      label
      valueLink {
        text
        url
      }
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
    ... on CustomFieldNumeric {
      id
      label
      valueNumeric
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
    ... on CustomFieldText {
      id
      label
      valueText
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
    ... on CustomFieldTrueFalse {
      id
      label
      valueTrueFalse
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
    ... on CustomFieldArea {
      id
      label
      valueArea {
        length
        width
      }
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
    ... on CustomFieldDropdown {
      id
      label
      valueDropdown
      customFieldConfiguration {
        id
        app {
          id
          name
          logoUrl
        }
        archived
      }
    }
  }
`;
