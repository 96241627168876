import React, {
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { BillingAddressDisplay } from "./components/BillingAddressDisplay";
import { BillingAddressEdit } from "./components/BillingAddressEdit";
import styles from "./BillingAddress.module.css";
import type { BillingAddressType } from "./BillingAddress.d";
import { messages } from "./messages";
import { BillingAddressPreview } from "./components/BillingAddressPreview/BillingAddressPreview";

export interface BillingAddressProps {
  shouldShowDisplay: boolean;
  showPreview: boolean;
  billingAddress: BillingAddressType;
  trackingDetails: TrackingDetails;
  setBillingAddress: React.Dispatch<React.SetStateAction<BillingAddressType>>;
  onValidationError(error: FieldErrorState): void;
}

export function BillingAddress(props: BillingAddressProps) {
  const {
    shouldShowDisplay,
    showPreview,
    billingAddress,
    trackingDetails,
    setBillingAddress,
    onValidationError,
  } = props;

  const [showDisplay, setShowDisplay] = useState(shouldShowDisplay);

  if (showPreview && showDisplay) {
    return (
      <BillingAddressPreview
        billingAddress={billingAddress}
        onClickAction={setShowDisplay}
      />
    );
  }

  return (
    <Content>
      <Card
        header={
          <BillingAddressHeader
            showButton={showDisplay}
            onClickAction={setShowDisplay}
          />
        }
      >
        {showDisplay ? (
          <BillingAddressDisplay billingAddress={billingAddress} />
        ) : (
          <BillingAddressEdit
            billingAddress={billingAddress}
            trackingDetails={trackingDetails}
            setBillingAddress={setBillingAddress}
            onValidationError={onValidationError}
          />
        )}
      </Card>
    </Content>
  );
}

function BillingAddressHeader({
  showButton,
  onClickAction,
}: {
  showButton: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  return (
    <div className={styles.billingAddressHeaderContainer}>
      <Heading level={4}>{formatMessage(messages.cardTitle)}</Heading>
      {showButton && (
        <Button
          ariaLabel={"Edit billing address"}
          type="tertiary"
          label={formatMessage(messages.editButton)}
          icon="arrowRight"
          iconOnRight={true}
          size="small"
          disabled={submitting}
          onClick={() => onClickAction(false)}
        />
      )}
    </div>
  );
}
