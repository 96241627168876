import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import type { Option } from "@jobber/components/Autocomplete";
import { useInvoiceDetailTotalsData } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/hooks/useInvoiceDetailTotalsData";
import { useInvoiceDetailReportData } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/hooks/useInvoiceDetailReportData";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import {
  type InvoiceDetailReportExportMutation,
  type InvoiceDetailReportExportMutationVariables,
  InvoiceDetailSortKey,
} from "~/utilities/API/graphqlFranchiseManagement";
import { useFormatSortAndFilter } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/hooks/useFormatSortAndFilter";
import { DEFAULT_PAGE_NUMBER_OPTIONS } from "~/jobber/features/Reporting/components/Report/constants";
import { STATUS_FILTER_LABELS } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/constants";
import type {
  FilterAutocompleteProps,
  OptionCollection,
} from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/FilterAutocomplete/types";
import {
  isInDesktopView,
  isInHandheldView,
} from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import { useFranchiseeNameAutoCompleteData } from "jobber/franchise/features/Reporting/views/hooks/useFranchiseeNameAutoCompleteData";
import { useViewport } from "jobber/hooks/useViewport";
import { findStatusFilterType } from "~/jobber/features/Reporting/components/Report/utils/findStatusFilterType";
import { InvoiceDetailReport } from "./InvoiceDetailReport";
import type {
  InvoiceDetailData,
  InvoiceDetailSortState,
  InvoiceDetailTotalsData,
  StatusFilters,
} from "./types";
import { INVOICE_DETAIL_EXPORT_MUTATION } from "./InvoiceDetailReport.graphql";

// eslint-disable-next-line max-statements
export function InvoiceDetailReportLoader() {
  const { formatSort, formatFilter } = useFormatSortAndFilter();
  const [reportFilter, setReportFilter] = useState<FilterType>({
    heading: "DATE RANGE",
    key: "date_range",
    value: {
      startDate: defaultDateRangeOption.start,
      endDate: defaultDateRangeOption.end,
    },
  });
  const [currentStatusFilter, setCurrentStatusFilter] =
    useState<StatusFilters>("drafted");

  const [currentNameSearchFilter, setCurrentNameSearchFilter] = useState<
    Option | undefined
  >();

  const [canSearch, setCanSearch] = useState<boolean>(false);

  const [sortingState, setSortingState] = useState<[InvoiceDetailSortState]>([
    {
      id: InvoiceDetailSortKey.FRANCHISEE_NAME,
      desc: false,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_NUMBER_OPTIONS[0],
  });

  const { innerWidth } = useViewport();

  const statusOptions = [
    {
      label: STATUS_FILTER_LABELS.drafted,
      onClick: () => setCurrentStatusFilter("drafted"),
    },
    {
      label: STATUS_FILTER_LABELS.issued,
      onClick: () => setCurrentStatusFilter("issued"),
    },
    {
      label: STATUS_FILTER_LABELS.paid,
      onClick: () => setCurrentStatusFilter("paid"),
    },
  ];

  const { fetchInvoiceDetail, data, loading, error } =
    useInvoiceDetailReportData();

  const {
    fetchInvoiceDetailTotals,
    data: totalsData,
    loading: totalsLoading,
    error: totalsError,
  } = useInvoiceDetailTotalsData();

  const {
    fetchAutoCompleteData,
    handleAutoCompleteChange,
    options: franchiseeOptions,
  } = useFranchiseeNameAutoCompleteData();

  useEffect(() => {
    fetchAutoCompleteData();
  }, []);

  const fetchDetails = () => {
    const sort = formatSort(sortingState);
    const filter = formatFilter(
      currentStatusFilter,
      reportFilter,
      currentNameSearchFilter,
    );
    fetchInvoiceDetail({
      sort,
      filter,
      after: btoa((pageIndex * pageSize).toString()),
      first: pageSize,
    });
    fetchInvoiceDetailTotals(filter);
  };

  useEffect(() => {
    fetchDetails();
  }, [sortingState, reportFilter, pageIndex, pageSize, currentStatusFilter]);

  useEffect(() => {
    if (canSearch && isInDesktopView(innerWidth)) {
      fetchDetails();
    }
  }, [canSearch, currentNameSearchFilter]);

  const [reportIsExported, setReportIsExported] = useState(false);

  const [exportCsv] = useMutation<
    InvoiceDetailReportExportMutation,
    InvoiceDetailReportExportMutationVariables
  >(INVOICE_DETAIL_EXPORT_MUTATION, {
    variables: {
      input: {
        sort: formatSort(sortingState),
        filter: formatFilter(
          currentStatusFilter,
          reportFilter,
          currentNameSearchFilter,
        ),
      },
    },
  });

  async function exportFunction() {
    const { errors } = await exportCsv();
    setReportIsExported(!errors);
  }

  const totalItems = data?.invoiceDetail.totalCount || 0;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleDateFilterChange = (range: FilterType, statusType?: string) => {
    if (statusType) {
      const foundStatusFilterType = findStatusFilterType(
        STATUS_FILTER_LABELS,
        statusType,
      );
      setCurrentStatusFilter(foundStatusFilterType as StatusFilters);
    }

    setPagination({ pageIndex: 0, pageSize: pageSize });
    setReportFilter(range);

    if (isInHandheldView(innerWidth)) {
      fetchDetails();
    }
  };

  const handleSortChange = (sortState: [InvoiceDetailSortState]) => {
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setSortingState(sortState);
  };

  const searchFilterConfig: FilterAutocompleteProps = {
    heading: "SEARCH",
    placeholder: "Search by a franchisee name",
    initialOptions: franchiseeOptions || [],
    getOptions: newInput =>
      handleAutoCompleteChange(newInput) as OptionCollection,
    onChange: setCurrentNameSearchFilter,
    value: currentNameSearchFilter,
    onBlur: () => setCanSearch(true),
    onFocus: () => setCanSearch(false),
  };

  return (
    <InvoiceDetailReport
      data={data?.invoiceDetail.nodes as InvoiceDetailData[]}
      totalsData={totalsData?.invoiceDetailTotals as InvoiceDetailTotalsData}
      loading={loading || totalsLoading}
      error={error?.message || totalsError?.message}
      filterProps={{
        dateRangeFilter: reportFilter,
        onFilterChange: handleDateFilterChange,
        statusFilter: {
          heading: "STATUS",
          options: statusOptions,
          value: STATUS_FILTER_LABELS[currentStatusFilter],
        },
        searchFilter: searchFilterConfig,
      }}
      sortingProps={{
        manualSorting: true,
        state: sortingState,
        onSortingChange: handleSortChange,
      }}
      exportProps={{
        exportFunction: exportFunction,
        setReportIsExported: setReportIsExported,
        reportIsExported: reportIsExported,
      }}
      paginationProps={{
        manualPagination: true,
        onPaginationChange: setPagination,
        itemsPerPage: DEFAULT_PAGE_NUMBER_OPTIONS,
        totalItems,
        state: pagination,
        pageCount: Math.ceil(totalItems / pageSize),
      }}
    />
  );
}
