import { Button } from "@jobber/components/Button";
import React, { useState } from "react";
import { Spinner } from "@jobber/components/Spinner";
import { useIntl } from "react-intl";
import styles from "./LoadPastEntriesButton.module.css";
import { messages } from "./messages";

export function LoadPastEntriesButton({
  onClick,
  showButton,
}: {
  onClick: () => void;
  showButton: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const handleMoreData = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return showButton ? (
    <tr key="load-past-entries">
      <td colSpan={100} className={styles.parentCell}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Spinner size="small" />
          </div>
        ) : (
          <div className={styles.container}>
            <Button
              label={formatMessage(messages.loadEntriesButton)}
              type="tertiary"
              onClick={handleMoreData}
            />
          </div>
        )}
      </td>
    </tr>
  ) : (
    <></>
  );
}
