import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import styles from "./LinkButton.module.css";

export interface LinkButtonProps {
  label: string;
  url: string;
  icon: IconNames;
}

export function LinkButton(props: LinkButtonProps) {
  return (
    <a
      href={props.url}
      className={styles.linkDescription}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name={props.icon} />
      {props.label}
    </a>
  );
}
