import {
  type ApolloCache,
  type DataProxy,
  useMutation,
  useQuery,
} from "@apollo/client";
import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Page } from "@jobber/components/Page";
import { Spinner } from "@jobber/components/Spinner";
import deepmerge from "deepmerge";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  AccountUpdateAttributes,
  AvailabilityWindowConfigurationMutationMutation,
  InstantBookingAvailabilityWindow,
  InstantBookingAvailabilityWindowAttributes,
  InstantBookingSelectedService,
  MerchantConfigurationMutationMutation,
  ServiceConfigurationMutationMutation,
} from "~/utilities/API/graphql";
import { InlinePrompt } from "components/InlinePrompt";
import {
  ArrivalWindowCard,
  IntegrationEnabledCard,
  MerchantStatusBanner,
  MessageDescriptionCard,
  OnlineBookingCard,
  SetupNavigation,
  SetupNavigationStep,
} from "./components";
import { SetupStep } from "./components/SetupNavigation/SetupNavigation";
import {
  AVAILABILITY_WINDOW_CONFIGURATION_MUTATION,
  MERCHANT_CONFIGURATION_MUTATION,
  MERCHANT_CONFIGURATION_QUERY,
  type MerchantConfiguration,
  SERVICE_CONFIGURATION_MUTATION,
} from "./MerchantConfigurationPage.graphql";
import type { ArrivalWindow, Offering, Service } from "./MerchantConfiguration";
import {
  convertArrivalWindows,
  convertAvailabilityWindows,
} from "./utilities/ConvertArrivalWindows";
import { convertServices } from "./utilities/ConvertServices";
import styles from "./MerchantConfigurationPage.module.css";
import { BookingCapacityCard } from "./components/BookingCapacityCard/BookingCapacityCard";

interface MerchantConfigurationPageProps {
  allServices: Offering[];
}

export function MerchantConfigurationPage({
  allServices,
}: MerchantConfigurationPageProps) {
  return (
    <Page
      title="Google's Local Services Ads"
      intro="Book new leads, with less effort. When you connect Jobber to your Local Services Ads account, customers searching for your services on Google will be able to instantly book into your calendar, while you remain in control of your availability. To learn more about this feature, visit our [Help Center](https://help.getjobber.com/hc/en-us/articles/360062129213)."
      width="narrow"
    >
      <APIProvider>
        <MerchantConfigurationData allServices={allServices} />
      </APIProvider>
    </Page>
  );
}

interface UpdatedMerchantConfiguration {
  enabled?: boolean;
  bookingBufferHours?: number;
  serviceDescription?: string;
  setupStep?: number;
  dismissedGoogleGuaranteedPrompt?: boolean;
}

// eslint-disable-next-line max-statements
function MerchantConfigurationData({
  allServices,
}: MerchantConfigurationPageProps) {
  const { loading, error, data } = useQuery<MerchantConfiguration>(
    MERCHANT_CONFIGURATION_QUERY,
    { fetchPolicy: "cache-and-network" },
  );

  const [upsertAccount] = useMutation<MerchantConfigurationMutationMutation>(
    MERCHANT_CONFIGURATION_MUTATION,
  );

  const [updateServicesMutation] =
    useMutation<ServiceConfigurationMutationMutation>(
      SERVICE_CONFIGURATION_MUTATION,
    );

  const [updateAvailabilityWindowsMutation] =
    useMutation<AvailabilityWindowConfigurationMutationMutation>(
      AVAILABILITY_WINDOW_CONFIGURATION_MUTATION,
    );

  const updateMerchantConfiguration = async (
    id: string,
    updatedConfig: UpdatedMerchantConfiguration,
  ) => {
    const attrs: AccountUpdateAttributes = {
      googleLsaConfiguration: {
        enabled: updatedConfig.enabled ?? false,
        bookingBufferHours: updatedConfig.bookingBufferHours,
        serviceDescription: updatedConfig.serviceDescription,
        setupStep: updatedConfig.setupStep,
        dismissedGoogleGuaranteedPrompt:
          updatedConfig.dismissedGoogleGuaranteedPrompt,
      },
    };

    await upsertAccount({
      variables: {
        id,
        attrs,
      },
    });
  };

  if (loading && !data) {
    return <Spinner inline={true} />;
  }

  if (error || !data) {
    return (
      <Banner type="error" dismissible={false}>
        A problem has occurred and this page could not be loaded
      </Banner>
    );
  }

  const { id: accountId, googleLsaConfiguration: config } = data.account;

  const currentSetupStep =
    data.account.googleLsaConfiguration?.setupStep ?? SetupStep.SetServices;

  const changeSetupStep = async (newStep: SetupStep) => {
    await updateMerchantConfiguration(accountId, {
      enabled: config?.enabled ?? false,
      setupStep: newStep,
    });
  };

  const companyDetails = {
    name: data.account.name,
    address: data.account.companyDetails.address,
    phone: data.account.companyDetails.phone ?? undefined,
    websiteUrl: data.account.companyDetails.websiteUrl ?? undefined,
  };

  const categoriesWithServices = convertServices(
    allServices,
    data.instantBookingSelectedServices.nodes,
  );

  const { arrivalWindows, spotsTotal } = convertArrivalWindows(
    data.instantBookingAvailabilityWindows.nodes,
  );

  const updateServices = async (services: Service[]) => {
    const servicesIdsToDelete = services
      .filter((service: Service) => {
        // Services that will be deleted are those where enabled is false
        // (they're disabled), but they have an instance Id.
        return !!service.id && !service.enabled;
      })
      .map(service => service.id);

    await updateServicesMutation({
      variables: {
        services: services
          .filter((service: Service) => {
            // We only want to update the backend with services that are
            // either enabled, or are disabled but currently have an
            // instance Id (i.e. those that need deleting)
            return !!service.id || service.enabled;
          })
          .map((service: Service) => {
            return {
              serviceId: service.id,
              enabled: service.enabled,
              price: service.price ? service.price : undefined,
              serviceTypeId: service.serviceTypeId,
            };
          }),
      },
      update(cache, { data: updatedServicesData }) {
        if (updatedServicesData) {
          updateServicesCache(
            cache,
            updatedServicesData.updateInstantBookingServices
              .instantBookingSelectedServices,
            servicesIdsToDelete,
          );
        }
      },
    });
  };

  const updateArrivalWindows = async (windows: ArrivalWindow[]) => {
    const availabilityWindows: InstantBookingAvailabilityWindowAttributes[] =
      convertAvailabilityWindows(windows, spotsTotal);

    await updateAvailabilityWindowsMutation({
      variables: {
        availabilityWindows,
      },
      update(cache, { data: availabilityWindowData }) {
        if (
          availabilityWindowData?.updateInstantBookingAvailabilities
            .availabilityWindows
        ) {
          updateAvailabilityWindowsCache(
            cache,
            availabilityWindowData.updateInstantBookingAvailabilities
              .availabilityWindows,
          );
        }
      },
    });
  };

  const updateWindowCapacity = async (newSpotsTotal: number) => {
    const availabilityWindows: InstantBookingAvailabilityWindowAttributes[] =
      convertAvailabilityWindows(arrivalWindows, newSpotsTotal);

    await updateAvailabilityWindowsMutation({
      variables: {
        availabilityWindows,
      },
      update(cache, { data: availabilityWindowData }) {
        if (
          availabilityWindowData?.updateInstantBookingAvailabilities
            .availabilityWindows
        ) {
          updateAvailabilityWindowsCache(
            cache,
            availabilityWindowData.updateInstantBookingAvailabilities
              .availabilityWindows,
          );
        }
      },
    });
  };

  const onToggleIntegration = async (enabled: boolean) => {
    return updateMerchantConfiguration(accountId, {
      enabled: enabled,
    });
  };

  const onMessageUpdate = async (description: string) => {
    return updateMerchantConfiguration(accountId, {
      enabled: config?.enabled ?? false,
      serviceDescription: description,
    });
  };

  const updateBookingBuffer = async (bookingBufferHours: number) => {
    return updateMerchantConfiguration(accountId, {
      enabled: config?.enabled ?? false,
      bookingBufferHours: bookingBufferHours,
    });
  };

  const guaranteedPromptPrimaryAction = async () => {
    const glsaSignUpURL =
      "https://ads.google.com/localservices/signup/eligibility";
    window.open(glsaSignUpURL, "_blank");

    // Amplitude tracking
    const eventProperty = {
      name: "Sign up for GLSA (get Google Guaranteed)",
      source: "GLSA Settings Page",
      url: window.location.href,
      target: glsaSignUpURL,
    };
    Amplitude.TRACK_EVENT("CTA Clicked", eventProperty);

    return updateMerchantConfiguration(accountId, {
      enabled: config?.enabled ?? false,
      dismissedGoogleGuaranteedPrompt: true,
    });
  };

  const guaranteedPromptSecondaryAction = async () => {
    // Amplitude tracking
    const eventProperty = {
      name: "Dismiss prompt to sign up for GLSA (get Google Guaranteed)",
      source: "GLSA Settings Page",
      url: window.location.href,
    };
    Amplitude.TRACK_EVENT("CTA Dismissed", eventProperty);

    return updateMerchantConfiguration(accountId, {
      enabled: config?.enabled ?? false,
      dismissedGoogleGuaranteedPrompt: true,
    });
  };

  return (
    <div className={styles.MerchantConfigurationData}>
      <SetupNavigation
        currentStep={currentSetupStep}
        data={data}
        onChangeStep={changeSetupStep}
      >
        {!config?.dismissedGoogleGuaranteedPrompt && (
          <InlinePrompt
            title="You must have a Local Services Ads account with Google"
            description="It can take some time for the Google screening and qualification process, so we suggest starting now."
            primaryActionLabel="Sign Up with Google"
            secondaryActionLabel="I Already have an Account"
            handlePrimaryAction={guaranteedPromptPrimaryAction}
            handleSecondaryAction={guaranteedPromptSecondaryAction}
          />
        )}

        <SetupNavigationStep
          step={SetupStep.ReviewSettings}
          currentStep={currentSetupStep}
        >
          <MerchantStatusBanner
            readyToBook={
              data.account.googleLsaConfiguration?.merchantStatus
                ?.readyToBook ?? false
            }
          />
        </SetupNavigationStep>

        <SetupNavigationStep
          step={SetupStep.SetServices}
          currentStep={currentSetupStep}
        >
          <OnlineBookingCard
            categories={categoriesWithServices}
            onUpdate={updateServices}
          />
        </SetupNavigationStep>

        <SetupNavigationStep
          step={SetupStep.SetAvailability}
          currentStep={currentSetupStep}
        >
          <ArrivalWindowCard
            loading={loading}
            windows={arrivalWindows}
            bookingBufferHours={config?.bookingBufferHours ?? 24}
            onSaveWindows={updateArrivalWindows}
            onSaveBookingBuffer={updateBookingBuffer}
          />
        </SetupNavigationStep>

        <SetupNavigationStep
          step={SetupStep.SetCapacity}
          currentStep={currentSetupStep}
        >
          <BookingCapacityCard
            spotsTotal={spotsTotal}
            onSaveCapacity={updateWindowCapacity}
          />
        </SetupNavigationStep>

        <SetupNavigationStep
          step={SetupStep.SetMessage}
          currentStep={currentSetupStep}
        >
          <MessageDescriptionCard
            loading={loading}
            description={config?.serviceDescription ?? ""}
            onUpdate={onMessageUpdate}
          />
        </SetupNavigationStep>

        <SetupNavigationStep
          step={SetupStep.ActivateIntegration}
          currentStep={currentSetupStep}
        >
          <IntegrationEnabledCard
            enabled={config?.enabled ?? false}
            onChange={onToggleIntegration}
            companyDetails={companyDetails}
          />
        </SetupNavigationStep>
      </SetupNavigation>
    </div>
  );
}

// Future consideration: Move these functions to their own utility file(s) and
//   write more thorough tests for them

function updateServicesCache(
  cache: DataProxy,
  updatedServices: InstantBookingSelectedService[],
  servicesIdsToDelete: (number | undefined)[],
) {
  const cachedData: MerchantConfiguration | null = cache.readQuery({
    query: MERCHANT_CONFIGURATION_QUERY,
  });
  if (!cachedData) {
    // We're not capable of building up the whole cache entry here.
    // This should only occur if the mutation is somehow run prior
    // to the page query finishing.
    throw new Error("GraphQL cache is null during manual mutation");
  }

  let newCachedNodes = cachedData.instantBookingSelectedServices.nodes
    .slice()
    .reduce((nodes, cachedService) => {
      if (servicesIdsToDelete.includes(cachedService.id)) {
        // Skip deleted cached entries
        return nodes;
      }

      const updatedIndex = updatedServices.findIndex(
        updatedService => updatedService.id === cachedService.id,
      );
      if (updatedIndex >= 0) {
        const updatedService = updatedServices.splice(updatedIndex, 1)[0];
        // We have an updated version of this service, use it (and remove it from our working list)
        nodes.push(updatedService);
      } else {
        // No updates, keep the cached service
        nodes.push(cachedService);
      }

      return nodes;
    }, [] as InstantBookingSelectedService[]);

  // Any remaining services are new, add them to the list
  newCachedNodes = newCachedNodes.concat(updatedServices);

  cache.writeQuery({
    query: MERCHANT_CONFIGURATION_QUERY,
    data: deepmerge(cachedData, {
      instantBookingSelectedServices: { nodes: newCachedNodes },
    }),
  });
}

function updateAvailabilityWindowsCache(
  cache: ApolloCache<AvailabilityWindowConfigurationMutationMutation>,
  availabilityWindows: InstantBookingAvailabilityWindow[],
) {
  const cachedData: MerchantConfiguration | null = cache.readQuery({
    query: MERCHANT_CONFIGURATION_QUERY,
  });
  if (!cachedData) {
    // We're not capable of building up the whole cache entry here.
    // This should only occur if the mutation is somehow run prior
    // to the page query finishing.
    throw new Error("GraphQL cache is null during manual mutation");
  }

  cache.modify({
    fields: {
      instantBookingAvailabilityWindows() {
        return { nodes: availabilityWindows };
      },
    },
  });
}
