import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignCreateUpcomingClientsMutation,
  CampaignCreateUpcomingClientsMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_CREATE_UPCOMING_CLIENTS } from "./useCampaignCreateUpcomingClientsMutation.graphql";

export function useCampaignCreateUpcomingClientsMutation() {
  const [campaignCreateUpcomingClientsMutation, { loading, error, data }] =
    useMutation<
      CampaignCreateUpcomingClientsMutation,
      CampaignCreateUpcomingClientsMutationVariables
    >(CAMPAIGN_CREATE_UPCOMING_CLIENTS);
  const campaignCreateUpcomingClients = useCallback(
    async (input: CampaignCreateUpcomingClientsMutationVariables) => {
      const mutationResult = await campaignCreateUpcomingClientsMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignCreateUpcomingClients.userErrors ?? [];
      return {
        campaign: mutationResult.data?.campaignCreateUpcomingClients.campaign,
        clientSegment:
          mutationResult.data?.campaignCreateUpcomingClients.campaign
            ?.clientSegment,
        userErrors,
      };
    },
    [campaignCreateUpcomingClientsMutation],
  );

  return {
    campaignCreateUpcomingClients,
    loading,
    error,
    data,
  };
}
