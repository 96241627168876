import { Rollbar } from "~/utilities/errors/Rollbar";
import type { EvidenceFileInput } from "~/utilities/API/graphql";
import { useStripePublishableKey } from "./useStripePublishableKey";
import type { DisputeFile } from "../types";

export interface useUploadEvidenceFilesProps {
  files: DisputeFile[];
}
export function useUploadEvidenceFiles() {
  const publishableKey = useStripePublishableKey(); // This is a custom hook that fetches the stripe publishable key and stores it in the cache

  return { uploadEvidenceFiles };

  async function uploadEvidenceFiles(
    files: DisputeFile[],
  ): Promise<EvidenceFileInput[]> {
    const uploadFile = async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("purpose", "dispute_evidence");

      const response = await fetch("https://files.stripe.com/v1/files", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${publishableKey.key}`,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        const err = new Error(errorResponse);
        Rollbar.EXECUTE("Failed to upload dispute evidence file", err);
        throw err;
      }

      const uploadData = await response.json();
      return uploadData.id;
    };

    const fileIds = await Promise.all(files.map(file => uploadFile(file.file)));
    return files.map((file, index) => {
      return {
        category: file.category,
        name: file.file.name,
        stripeFileId: fileIds[index],
      } as EvidenceFileInput;
    });
  }
}
