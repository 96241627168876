import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { Glimmer } from "@jobber/components/Glimmer";
import { Form } from "@jobber/components/Form";
import { debounce } from "lodash";
import { useFormState } from "@jobber/hooks/useFormState";
import { useInView } from "@jobber/hooks/useInView";
import classnames from "classnames";
import type { AllClientsCriteriaInput } from "~/utilities/API/graphql";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { DrawerMode } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { AdditionalCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import styles from "./AllClientsSegmentEdit.module.css";
import { messages } from "./messages";

export interface AllClientsSegmentEditProps {
  segmentCriteria: AllClientsCriteriaInput;
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<DrawerMode>>;
  onSave: (segmentCriteria: AllClientsCriteriaInput) => void;
  closeSideDrawer: () => void;
  saveError?: string;
  loading?: boolean;
  pageSize?: number;
}

const clientSegmentDataConditionalVariables = {
  isAllClientDrawer: true,
};

export function AllClientsSegmentEdit({
  additionalCriteria,
  updateAdditionalCriteria,
  setDrawerState,
  closeSideDrawer,
  onSave,
  saveError,
  loading,
  pageSize = 20,
}: AllClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();

  const [{ isValid, isDirty }, setFormState] = useFormState();

  const {
    refetch,
    fullReload,
    error: refreshError,
    clientSegmentsData,
  } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const errorMessage = useMemo(() => {
    const refreshErrorMessage = refreshError
      ? formatMessage(messages.refreshErrorMessage)
      : "";
    const saveErrorMessage = saveError
      ? formatMessage(messages.saveErrorMessage)
      : "";
    return saveErrorMessage || refreshErrorMessage;
  }, [formatMessage, refreshError, saveError]);

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: AllClientsCriteriaInput) => {
        const { clientTagCriteria, clientTypeCriteria, ...newCriteria } =
          newSegmentCriteria;
        refetch({
          first: pageSize,
          after: btoa("0"),
          ...{
            ...newCriteria,
            allClientTagCriteria: clientTagCriteria,
            allClientTypeCriteria: clientTypeCriteria,
          },
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [refetch, pageSize],
  );

  useEffect(() => {
    if (isValid || !isDirty) {
      debounceFetchData({
        clientTagCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
        clientTypeCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTypeCriteria,
      });
    }
  }, [debounceFetchData, isValid, additionalCriteria, isDirty]);
  const [ref, bodyHasBeenScrolled] = useInView<HTMLDivElement>();

  return (
    <div className={styles.clientSegmentDrawer}>
      <div
        className={classnames(styles.drawerHeaderNoLeftPad, {
          [styles.scrollable]: !bodyHasBeenScrolled,
        })}
      >
        <Heading level={3}>
          <Button
            variation="subtle"
            type="tertiary"
            icon="arrowLeft"
            ariaLabel="arrowLeft"
            onClick={() => setDrawerState(DrawerMode.View)}
          />
          {formatMessage(messages.clientSegmentEditSideDrawerTitle)}
        </Heading>
        <Button
          variation="subtle"
          type="tertiary"
          icon="remove"
          ariaLabel="close"
          onClick={closeSideDrawer}
        />
      </div>
      <div className={styles.drawerFormContent}>
        <div ref={ref}></div>
        <Form
          onStateChange={setFormState}
          onSubmit={() =>
            onSave({
              clientTagCriteria:
                parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
              clientTypeCriteria:
                parseAdditionalCriteria(additionalCriteria).clientTypeCriteria,
            })
          }
        >
          <Content spacing="large">
            {errorMessage && <Banner type="error">{errorMessage}</Banner>}
            <Content>
              <Content spacing="small">
                <Heading level={4}>
                  {formatMessage(messages.allClientsSubheader)}
                </Heading>
                <Text>{formatMessage(messages.allClientsDescription1)}</Text>
              </Content>
              <AdditionalCriteria
                criteria={additionalCriteria}
                updateAdditionalCriteria={(
                  value: AdditionalCriteriaReducerActions,
                ) => updateAdditionalCriteria(value, true)}
              />
            </Content>
            <Text>
              {fullReload ? (
                <Glimmer.Text />
              ) : (
                <>
                  <Emphasis variation="bold">
                    {formatMessage(messages.clients, {
                      totalCount: clientSegmentsData.allClients?.total,
                    })}
                  </Emphasis>
                  {formatMessage(messages.allClientsDescription)}
                </>
              )}
            </Text>
            <Button
              type="primary"
              fullWidth={true}
              label={formatMessage(messages.clientSegmentEditSideDrawerUpdate)}
              loading={loading}
              submit={true}
            />
          </Content>
        </Form>
      </div>
    </div>
  );
}
