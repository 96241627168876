import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { debounce } from "lodash";
import styles from "./styles.module.css";
import { messages } from "./messages";

const debounceDelay = 300;
interface SearchOrCreateHeaderProps {
  searching: boolean;
  onSearchInput(input: string): void;
  onClickCreate(): void;
}

export function SearchOrCreateHeader({
  searching,
  onSearchInput,
  onClickCreate,
}: SearchOrCreateHeaderProps) {
  const [searchString, setSearchString] = useState("");

  const delayedSearch = useCallback(debounce(onSearchInput, debounceDelay), [
    onSearchInput,
  ]);

  useEffect(() => {
    delayedSearch(searchString);
    return delayedSearch.cancel;
  }, [searchString, delayedSearch]);

  function handleSearch(value: string) {
    setSearchString(value);
  }

  return (
    <div className={styles.header}>
      <InputText
        placeholder={messages.searchProductOrService.title}
        onChange={handleSearch}
        loading={searching}
        inline
      />
      <div className={styles.centerVertically}>
        <Text align="center">{messages.or.title}</Text>
      </div>
      <div className={styles.centerVertically}>
        <Button label={messages.createNew.title} onClick={onClickCreate} />
      </div>
    </div>
  );
}
