import { Option, Select } from "@jobber/components/Select";
import React, { type Dispatch, type SetStateAction, useContext } from "react";
import { useIntl } from "react-intl";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import styles from "./RegionSelect.module.css";
import { messages } from "./messages";

export interface CountrySelectProps {
  countries: string[];
  placeholder: string;
  isSelected: boolean;
  setSelected: Dispatch<SetStateAction<string>>;
  updateRegionList: (value: string) => void;
  handleFocus: (input: string) => void;
  onValidationError(error: FieldErrorState): void;
  value?: string;
}

export function CountrySelect(props: CountrySelectProps) {
  const {
    countries,
    placeholder,
    isSelected,
    setSelected,
    updateRegionList,
    handleFocus,
    onValidationError,
    value,
  } = props;

  const context = useContext(PurchaseFormContext);

  const { formatMessage } = useIntl();

  return (
    <div className={isSelected ? undefined : styles.regionSelect}>
      <Select
        onChange={async (newValue: string) => {
          setSelected(newValue);
          updateRegionList(newValue);
        }}
        onFocus={() => {
          handleFocus("country");
        }}
        placeholder={isSelected ? placeholder : undefined}
        value={value}
        validations={{
          required: {
            value: true,
            message: `${placeholder} ${formatMessage(messages.isRequired)}`,
          },
        }}
        onValidation={message => {
          onValidationError({ field: "country", message: message });
        }}
        disabled={context.submitting}
      >
        <Option value={""} key={-1} disabled>
          {placeholder}
        </Option>
        {countries.map(country => {
          return (
            <Option key={country} value={country}>
              {country}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
