import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import arrowCurvedLarge from "@images/arrowCurvedLarge.png";
import styles from "./FeaturesHighlightsSection.module.css";

export interface FeaturesHighlightsSectionProps {
  mainHeading: string;
  firstImage: string;
  secondImage: string;
  firstImageAlt: string;
  secondImageAlt: string;
  featuresAndDescriptions: {
    heading: string;
    description: string;
    link: string;
  }[];
}

export function FeaturesHighlightsSection({
  mainHeading,
  firstImage,
  secondImage,
  firstImageAlt,
  secondImageAlt,
  featuresAndDescriptions,
}: FeaturesHighlightsSectionProps) {
  return (
    <>
      <div className={styles.headingSection}>
        <Heading level={2}>{mainHeading}</Heading>
        <img
          src={arrowCurvedLarge}
          alt="horizontalCurvedArrow"
          className={styles.arrowImg}
        />
      </div>
      <Grid>
        <Grid.Cell size={{ xs: 12, md: 6 }}>
          <a href={featuresAndDescriptions[0].link}>
            <img src={firstImage} alt={firstImageAlt} />
            <div className={styles.featureCopyOne}>
              <Heading level={3}>{featuresAndDescriptions[0].heading}</Heading>
              <Text size="large">{featuresAndDescriptions[0].description}</Text>
            </div>
          </a>
        </Grid.Cell>
        <Grid.Cell size={{ xs: 12, md: 6 }}>
          <a href={featuresAndDescriptions[1].link}>
            <img src={secondImage} alt={secondImageAlt} />
            <div className={styles.featureCopyTwo}>
              <Heading level={3}>{featuresAndDescriptions[1].heading}</Heading>
              <Text size="large">{featuresAndDescriptions[1].description}</Text>
            </div>
          </a>
        </Grid.Cell>
      </Grid>
    </>
  );
}
