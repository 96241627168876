import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./OtherFeatureSection.module.css";
import { FeatureCard } from "./components/FeatureCard/FeatureCard";

interface DotsProps {
  slide: number;
  numberOfSlides: number;
  onDotClick: (index: number) => void;
}

function Dots({ slide, numberOfSlides, onDotClick }: DotsProps): JSX.Element {
  return (
    <div className={styles.dots}>
      {Array.from({ length: numberOfSlides }).map((_, dotIndex) => (
        <button
          data-testid="dot"
          key={`dot-${dotIndex}`}
          className={classNames(styles.dot, {
            [styles.activeDot]: dotIndex === slide,
          })}
          onClick={() => onDotClick(dotIndex)}
        />
      ))}
    </div>
  );
}

export interface Feature {
  featureToTrack: string;
  cardHeading: string;
  cardDescription: string;
  imageSrc: string;
  imageAltText: string;
  linkToRedirect: string;
}

interface OtherFeatureSectionProps {
  featureInfo: Feature[];
}

export function OtherFeatureSection({ featureInfo }: OtherFeatureSectionProps) {
  const [features, setFeatures] = useState<Feature[]>([]);
  const [slide, setSlide] = useState<number>(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const minSwipeDistance = 50;

  useEffect(() => {
    setFeatures(featureInfo);
  }, [featureInfo]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart && touchEnd) {
      const diff = touchStart - touchEnd;
      const isLeftSwipe = diff > minSwipeDistance;
      const isRightSwipe = diff < -minSwipeDistance;
      if (isLeftSwipe) {
        slideRight();
      } else if (isRightSwipe) {
        slideLeft();
      }
    }
  };

  return (
    <div
      className={styles.otherFeatureSection}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.featureCards}>
        {features.map((feature, featureIndex) => (
          <div
            data-testid={`feature-card-${featureIndex}`}
            key={`feature-card${featureIndex}`}
            className={classNames(styles.featureCard, {
              [styles.slide]: featureIndex === slide,
              [styles.leftSlide]: featureIndex === slide - 1,
              [styles.rightSlide]: featureIndex === slide + 1,
              [styles.fourFeatureCard]: features.length === 4,
            })}
          >
            <FeatureCard {...feature} />
          </div>
        ))}
      </div>
      {features.length > 1 && (
        <Dots
          slide={slide}
          numberOfSlides={features.length}
          onDotClick={setSlide}
        />
      )}
    </div>
  );

  function slideRight() {
    if (slide < features.length - 1) {
      setSlide(slide + 1);
    }
  }
  function slideLeft() {
    if (slide > 0) {
      setSlide(slide - 1);
    }
  }
}
