import { defineMessages } from "react-intl";

export const messages = defineMessages({
  invalid: {
    id: "recurlyInputText.invalid",
    defaultMessage: "Invalid",
    description: "Text to display when field contents are invalid",
  },
  isRequired: {
    id: "recurlyInputText.isRequired",
    defaultMessage: "is required",
    description: "Text to display when field is required",
  },
});
