/* eslint-disable react/jsx-key */
import React from "react";
import { Content } from "@jobber/components/Content";
import { Divider, Flex, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { StatusLabel } from "@jobber/components/StatusLabel";
import { Grid } from "@jobber/components/Grid";
import { messages } from "./messages";
import styles from "./DepositDetails.module.css";

export interface DepositDetailsType {
  depositAmount?: string;
  depositAmountOutstanding?: string;
  depositPaymentsRecords?: depositPaymentsObject[];
}
interface refundObject {
  entry_date: string;
  amount: string;
  strike_through: boolean;
  inline_label?: string;
  inline_color: string;
  deposit_link: { onclick_get_script: string };
}
interface depositPaymentsObject extends refundObject {
  refunds?: refundObject[];
}

export function DepositDetails({
  depositAmount,
  depositAmountOutstanding,
  depositPaymentsRecords,
}: DepositDetailsType) {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Content>
        <Divider size="large" />
        <Flex template={["grow", "shrink"]}>
          <Text>{formatMessage(messages.requiredDepositLabel)}</Text>
          <Text>{depositAmount}</Text>
        </Flex>
        {depositPaymentsRecords &&
          depositPaymentsRecords.map(
            (payment: depositPaymentsObject, index: number) => {
              return (
                <>
                  <DepositLine payment={payment} index={index} />
                  {payment.refunds &&
                    payment.refunds.map((refund: refundObject, key: number) => {
                      return (
                        <DepositLine
                          payment={refund}
                          index={key}
                          isRefund={true}
                        />
                      );
                    })}
                </>
              );
            },
          )}
        <Divider size="large" />
        <Flex template={["grow", "shrink"]}>
          <Text>{formatMessage(messages.outstandingDepositLabel)}</Text>
          <Text>{depositAmountOutstanding}</Text>
        </Flex>
      </Content>
    </div>
  );
}

function DepositLine({
  payment,
  index,
  isRefund = false,
}: {
  payment: depositPaymentsObject;
  index: number;
  isRefund?: boolean;
}) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Divider />
      <div key={index}>
        <Grid alignItems="center" gap={false}>
          <Grid.Cell size={{ xs: 12, md: 5 }}>
            <div>
              <span
                className="js-spinOnClick textAction"
                data-onclick-get-script={
                  payment.deposit_link.onclick_get_script
                }
              >
                {isRefund
                  ? formatMessage(messages.depositRefundLabel)
                  : formatMessage(messages.depositCollectedLabel)}
              </span>
              {payment.inline_label && (
                <StatusLabel
                  label={payment.inline_label}
                  status={
                    payment.inline_color === "red" ? "critical" : "informative"
                  }
                  alignment="start"
                />
              )}
            </div>
          </Grid.Cell>
          <Grid.Cell size={{ xs: 12, md: 4 }}>
            <Text>{payment.entry_date} </Text>
          </Grid.Cell>
          <Grid.Cell size={{ xs: 12, md: 3 }}>
            <div
              className={`${styles.amountContainer} ${payment.strike_through ? styles.strikeThrough : ""}`}
            >
              <Text>{payment.amount}</Text>
            </div>
          </Grid.Cell>
        </Grid>
      </div>
    </>
  );
}
