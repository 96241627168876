import React, { useCallback, useMemo } from "react";
import { Button } from "@jobber/components/Button";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { add } from "date-fns";
import classNames from "./CalendarDatePickerHeader.module.css";

interface CaleanderDatePickerHeaderProps {
  readonly month: number;
  readonly year: number;
  readonly onChange?: (date: Date) => void;
  readonly translations?: {
    readonly previousMonth?: string;
    readonly nextMonth?: string;
  };
}

export const CalendarDatePickerHeader = ({
  onChange,
  month,
  year,
  translations,
}: CaleanderDatePickerHeaderProps) => {
  const date = useMemo(() => new Date(year, month, 1), [year, month]);

  const formatter = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "long",
  });

  const onPreviousMonth = useCallback(() => {
    onChange?.(add(date, { months: -1 }));
  }, [onChange, date]);

  const onNextMonth = useCallback(() => {
    onChange?.(add(date, { months: 1 }));
  }, [onChange, date]);

  return (
    <div className={classNames.container}>
      <div className={classNames.label} aria-live="polite">
        <Typography fontWeight="semiBold">{formatter.format(date)}</Typography>
      </div>
      <Button
        type="tertiary"
        variation="subtle"
        icon="arrowLeft"
        ariaLabel={translations?.previousMonth || "Previous month"}
        onClick={onPreviousMonth}
      />
      <Button
        type="tertiary"
        variation="subtle"
        icon="arrowRight"
        ariaLabel={translations?.nextMonth || "Next month"}
        onClick={onNextMonth}
      />
    </div>
  );
};
