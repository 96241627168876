import React from "react";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { type ApolloError, useQuery } from "@apollo/client";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { GET_ALL_REVENUE_TOTALS } from "jobber/dashboard/components/TodayCard/Revenue.graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { RevenueCalculation } from "~/utilities/API/graphql";
import { RailsPropsAsContexts } from "~/utilities/contexts/internal/RailsPropsAsContexts";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { messages } from "./messages";
import { TeamRow, type TeamRowProps, type VisitData } from "./TeamRow";
import styles from "./TodayCard.module.css";

const customWidths = {
  medium: 510,
  large: 645,
};

interface RevenueCalculationType {
  all: RevenueCalculation;
  incomplete: RevenueCalculation;
  active: RevenueCalculation;
  completed: RevenueCalculation;
}

/* eslint-disable @typescript-eslint/naming-convention */
interface TeamDBData {
  name: string;
  initials: string;
  avatar_url?: string;
  id: number;
  total_item_count: number;
  ordered_visit_data: VisitData[];
  ticking: boolean;
  total_time: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface TodayCardProps {
  title: string;
  activeAppointmentCount: number;
  completedAppointmentCount: number;
  incompleteAppointmentCount: number;
  scheduledAppointmentCount: number;
  teamData: TeamDBData[];
  date: string;
  showCalculateRevenue?: boolean;
}

export function TodayCard(props: TodayCardProps) {
  return (
    <RailsPropsAsContexts {...props}>
      <APIProvider>
        <TodayCardWrapper {...props} />
      </APIProvider>
    </RailsPropsAsContexts>
  );
}

export function TodayCardWrapper({
  title,
  activeAppointmentCount,
  completedAppointmentCount,
  incompleteAppointmentCount,
  scheduledAppointmentCount,
  teamData,
  date,
  showCalculateRevenue,
}: TodayCardProps) {
  const { formatMessage } = useIntl();
  const [ref, { exactWidth = customWidths.large }] =
    useResizeObserver<HTMLDivElement>({
      widths: customWidths,
    });
  const teamRowData: TeamRowProps[] = convertToTeamRowProps(teamData);
  const { loading, error, data } = useQuery<RevenueCalculationType>(
    GET_ALL_REVENUE_TOTALS,
    {
      variables: {
        startDate: date,
        endDate: date,
      },
      fetchPolicy: "network-only",
      skip: !showCalculateRevenue,
    },
  );

  return (
    <>
      <Card title={title}>
        <div className={`${styles.cardBody} js-today-card`}>
          <div className={styles.section}>
            <div
              ref={ref}
              className={classNames(styles.summaries, getWidthClass())}
            >
              <div className={`${styles.scheduled} ${styles.summary}`}>
                <Heading level={3}>{scheduledAppointmentCount}</Heading>
                <div>
                  <Text variation="subdued">
                    {formatMessage(messages.todayHeading)}
                  </Text>
                  <Revenue
                    loading={loading}
                    error={error}
                    data={data}
                    state="all"
                  />
                </div>
              </div>

              <div className={`${styles.toGo} ${styles.summary}`}>
                <Heading level={3}>{incompleteAppointmentCount}</Heading>
                <div>
                  <Text variation="subdued">
                    {formatMessage(messages.toGoHeading)}
                  </Text>
                  <Revenue
                    loading={loading}
                    error={error}
                    data={data}
                    state="incomplete"
                  />
                </div>
              </div>

              <div className={`${styles.active} ${styles.summary}`}>
                <Heading level={3}>{activeAppointmentCount}</Heading>
                <div>
                  <Text variation="subdued">
                    {formatMessage(messages.activeHeading)}
                  </Text>
                  <Revenue
                    loading={loading}
                    error={error}
                    data={data}
                    state="active"
                  />
                </div>
              </div>

              <div className={`${styles.complete} ${styles.summary}`}>
                <Heading level={3}>{completedAppointmentCount}</Heading>
                <div>
                  <Text variation="subdued">
                    {formatMessage(messages.completeHeading)}
                  </Text>
                  <Revenue
                    loading={loading}
                    error={error}
                    data={data}
                    state="completed"
                  />
                </div>
              </div>
            </div>
          </div>
          {teamRowData.map(row => (
            <TeamRow key={row.name} {...row} />
          ))}
        </div>
      </Card>
    </>
  );

  function getWidthClass() {
    if (exactWidth < customWidths.medium) return styles.oneColumn;
    if (exactWidth < customWidths.large) return styles.twoColumns;
    return styles.fourColumns;
  }
}

function convertToTeamRowProps(teamData: TeamDBData[]): TeamRowProps[] {
  return teamData.map(item => ({
    name: item.name,
    initials: item.initials,
    avatarUrl: item.avatar_url,
    userId: item.id,
    totalItemCount: item.total_item_count,
    orderedVisitData: item.ordered_visit_data,
    ticking: item.ticking,
    totalTime: item.total_time,
  }));
}

interface RevenueProps {
  data: RevenueCalculationType | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  state: string;
}

export function Revenue({ data, loading, error, state }: RevenueProps) {
  if (loading) {
    return (
      <div className={"row collapse align-center u-paddingNone"}>
        <div className={"columns spinner spinner--small spinner--inline"} />
      </div>
    );
  }

  if (error || data === undefined) {
    return <Text>Revenue is unavailable.</Text>;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currencyFormatter = useFormatCurrency();
  return (
    <div>
      <Text>{currencyFormatter(getData(state, data).revenueTotal)}</Text>
    </div>
  );

  function getData(dataState: string, allData: RevenueCalculationType) {
    switch (dataState) {
      case "active":
        return allData.active;
      case "completed":
        return allData.completed;
      case "incomplete":
        return allData.incomplete;
      default:
        return allData.all;
    }
  }
}
