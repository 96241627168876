import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage: t } = createIntl();

export function primaryCTAButton() {
  return screen.getByRole("button", {
    name: t(messages.primaryCTA),
  });
}

export function helpCenterLink() {
  return screen.getByRole("link", {
    name: "Help Center",
  });
}

export function heading() {
  return screen.getByText(t(messages.heading));
}

export function description() {
  return screen.getByText(
    "Upgrade to use automatic payments. Clients will be automatically invoiced and charged with their saved payment method based on billing frequency. Learn more in",
    { exact: false },
  );
}

/***************** Simulators ********************/

export function iClickPrimaryCTAButton() {
  primaryCTAButton().click();
}

export function iClickHelpCenterLink() {
  helpCenterLink().click();
}
