import type {
  AccountBillingInformation,
  BillingInfoEditInput,
} from "~/utilities/API/graphql";
import type {
  StoredPaymentDetails,
  UpdatedPaymentDetailsType,
} from "./components/PaymentDetails";
import type { BillingAddressType } from "./components/BillingAddress";

export function getStoredPaymentDetails(
  billingInfo: Partial<AccountBillingInformation>,
): StoredPaymentDetails {
  const { firstName, lastName, creditCardDetails, hasPaymentError } =
    billingInfo;

  return {
    firstName: firstName,
    lastName: lastName,
    cardType: creditCardDetails?.cardType,
    lastFour: creditCardDetails?.lastFour,
    expMonth: creditCardDetails?.expMonth,
    expYear: creditCardDetails?.expYear,
    hasPaymentError: hasPaymentError,
  };
}

export function getStoredBillingAddress(
  billingInfo: Partial<AccountBillingInformation>,
): BillingAddressType {
  const { email, address } = billingInfo;

  return {
    email: email,
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city,
    state: address?.state,
    country: address?.country,
    zip: address?.zip,
  };
}

export function hasStoredPaymentDetails(
  storedPaymentDetails?: StoredPaymentDetails,
): boolean {
  if (!storedPaymentDetails) {
    return false;
  }

  const { cardType, lastFour, expMonth, expYear } = storedPaymentDetails;

  return !!(cardType && lastFour && expMonth && expYear);
}

export function hasStoredBillingAddress(
  billingAddress?: BillingAddressType,
): boolean {
  if (!billingAddress) {
    return false;
  }

  const { address1, city, state, zip } = billingAddress;

  return !!(address1 && city && state && zip);
}

export function getInputValues(
  updatedPaymentDetails: UpdatedPaymentDetailsType,
  billingAddress: BillingAddressType,
  recurlyTokenId?: string,
  threeDSecureActionResultTokenId?: string,
) {
  const input: BillingInfoEditInput = {
    address1: billingAddress.address1!,
    address2: billingAddress.address2,
    city: billingAddress.city!,
    country: billingAddress.country!,
    email: billingAddress.email!,
    state: billingAddress.state!,
    zip: billingAddress.zip!,
    firstName: updatedPaymentDetails.firstName!,
    lastName: updatedPaymentDetails.lastName!,
    recurlyToken: recurlyTokenId,
    threeDSecureActionResultToken: threeDSecureActionResultTokenId,
  };

  return input;
}
