import type { IconNames } from "@jobber/components/Icon";
import { ActionTask, TriggerTask } from "../types";

export function actionToIcon(task: string): IconNames {
  let actionIcon: IconNames = "add";

  switch (task) {
    case ActionTask.CAMPAIGN_DELIVERY:
      actionIcon = "sendMessage";
      break;
  }

  return actionIcon;
}

export function triggerToIcon(task: string): IconNames {
  let triggerIcon: IconNames = "add";

  switch (task) {
    case TriggerTask.TIME_SINCE_LAST_JOB:
      triggerIcon = "calendar";
      break;
  }

  return triggerIcon;
}
