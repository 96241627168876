import { gql } from "@apollo/client";
import { notificationFieldsFragment } from "jobber/settings/notifications/notificationFieldsFragment";

export const VISIT_COMMS_TEMPLATE_QUERY = gql`
  query VisitCommsTemplateData {
    visitReminder: clientNotification(id: VISIT_REMINDER) {
      ...notificationFields
    }
  }
  ${notificationFieldsFragment}
`;
