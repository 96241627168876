import { gql } from "@apollo/client";

export const REGISTRATION_CREATE = gql`
  mutation UkKycRegistrationCreate($input: UkKycRegistrationCreateInput!) {
    commsUkKycRegistrationCreate(input: $input) {
      ukKycRegistration {
        id
        registrationId
        endUserType
        status
        acknowledgedAt
        failureReason
        createdAt
        updatedAt
      }
      ukKycRegistrationInquiry {
        inquirySessionToken
        inquiryId
      }
      userErrors {
        message
      }
    }
  }
`;
