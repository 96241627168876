import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CAMPAIGN_CHECK_FROM_EMAIL_VALIDATION_CODE } from "jobber/campaigns/hooks/useCampaignCheckFromEmailValidationCode/useCampaignCheckFromEmailValidationCode.graphql";
import type {
  CampaignCheckFromEmailValidationCodeInput,
  CampaignCheckFromEmailValidationCodeMutation,
} from "~/utilities/API/graphql";

export function useCampaignCheckFromEmailValidationCodeMutation() {
  const [
    campaignCheckFromEmailValidationCodeMutation,
    { loading, error, data },
  ] = useMutation<CampaignCheckFromEmailValidationCodeMutation>(
    CAMPAIGN_CHECK_FROM_EMAIL_VALIDATION_CODE,
  );

  const checkCampaignFromEmailValidationCode = useCallback(
    async (input: CampaignCheckFromEmailValidationCodeInput) => {
      const mutationResult = await campaignCheckFromEmailValidationCodeMutation(
        {
          variables: {
            input: input,
          },
        },
      );

      const userErrors =
        mutationResult.data?.campaignCheckFromEmailValidationCode.userErrors ??
        [];

      return {
        status:
          mutationResult.data?.campaignCheckFromEmailValidationCode.status,
        userErrors,
      };
    },
    [campaignCheckFromEmailValidationCodeMutation],
  );

  return {
    checkCampaignFromEmailValidationCode,
    loading,
    error,
    data,
  };
}
