import React from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import styles from "./CarouselCard.module.css";

export interface CarouselCardProps {
  readonly title: string;
  readonly description: string;
  readonly img: { src: string; alt: string };
}

export function CarouselCard({ title, description, img }: CarouselCardProps) {
  return (
    <div className={styles.container}>
      <img alt={img.alt} src={img.src} className={styles.cardImage} />
      <div className={styles.contentContainer}>
        <Content spacing="base">
          <Heading level={4}>{title}</Heading>
          <div className={styles.contentText}>
            <Text>{description}</Text>
          </div>
        </Content>
      </div>
    </div>
  );
}
