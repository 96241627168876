import { useContext, useEffect, useState } from "react";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";
import {
  CallToActionContext,
  type CallToActionContextType,
  type CtaName,
  type CtaStatus,
} from "./CallToActionContext";

export function useCtas(...ctaNames: CtaName[]): CtaStatus[] {
  const { ctas } = useContext(CallToActionContext);

  return ctaNames.map(ctaName => getCtaStatus(ctaName, ctas));
}

function getCtaStatus(
  ctaName: CtaName,
  ctas: CallToActionContextType["ctas"],
): CtaStatus {
  const ctaStatus = ctas[ctaName];

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return ensureExists(ctaStatus, ctaName, ctas);
  }

  return ctaStatus as CtaStatus;
}

type ShouldShow = boolean;
type MarkDismissedFunction = () => void;
type MarkSeenFunction = () => void;
type UseSingleCta = [ShouldShow, MarkDismissedFunction, MarkSeenFunction];
export function useCta(ctaName: CtaName | undefined): UseSingleCta {
  const { ctas } = useContext(CallToActionContext);
  const [hasDismissed, setDismissed] = useState(initHasDismissed);

  useEffect(() => {
    if (!hasDismissed) markSeen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctaName]);

  return [!hasDismissed, dismiss, markSeen];

  function dismiss() {
    if (!ctaName) return;

    setDismissed(true);
    CTAMarkConverted(ctaName);
  }

  function markSeen() {
    if (!ctaName) return;

    CTAMarkSeen(ctaName);
  }

  function initHasDismissed() {
    return ctaName ? getCtaStatus(ctaName, ctas).hasDismissed : false;
  }
}

function ensureExists(
  ctaStatus: CtaStatus | undefined,
  ctaName: string,
  ctas: CallToActionContextType["ctas"],
) {
  if (!ctaStatus) {
    throw new Error(
      `'${ctaName}' is not initialized.
      Ensure CallToActionContext.Provider or RailsPropsAsContexts exists in the parent tree and '${ctaName}' is defined;
      all provided CTA names: ${JSON.stringify(Object.keys(ctas))}
      `,
    );
  }

  return ctaStatus;
}
