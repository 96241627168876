import { type Dispatch, createContext, useContext } from "react";
import type {
  PaymentAction,
  PaymentState,
} from "legacy/jobber/payments_react/paymentReducer";

export interface PaymentReducerContextType {
  paymentState: PaymentState;
  paymentDispatcher: Dispatch<PaymentAction>;
}

const unusedDefaultContextValue: PaymentReducerContextType =
  {} as PaymentReducerContextType;
export const PaymentReducerContext = createContext<PaymentReducerContextType>(
  unusedDefaultContextValue,
);
PaymentReducerContext.displayName = "PaymentReducerContext";

export function usePaymentReducer() {
  const { paymentState, paymentDispatcher } = useContext(PaymentReducerContext);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    ensureExists(paymentState);
  }

  return [paymentState, paymentDispatcher] as const;
}

function ensureExists(state: PaymentState | undefined) {
  if (!state) {
    throw new Error(
      "'PaymentReducerContext' is not initialized. Ensure PaymentReducerContext.Provider exists in the parent tree",
    );
  }
}
