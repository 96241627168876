import { defineMessages } from "react-intl";

export const rejectedStateMessages = defineMessages({
  moreInfoNeeded: {
    id: "ukKycRegistration.rejected.moreInfoNeeded",
    defaultMessage: "We need more information",
    description: "Header for the rejected state",
  },
  verificationNotComplete: {
    id: "ukKycRegistration.rejected.verificationNotComplete",
    defaultMessage:
      "Verification was not completed and needs your attention. Take a moment to fill in required fields and resubmit.",
    description: "body for the rejected state",
  },
  rejectedButtonText: {
    id: "ukKycRegistration.rejected.rejectedButtonText",
    defaultMessage: "Continue",
    description: "CTA text for the rejected state",
  },
});
