import type { StateFrom } from "xstate";
import { useMemo } from "react";
import type { recommendationMachine } from "./recommendation.machine";
import { useRecommendationMachine } from "./RecommendationMachineContext";
import type { SchedulingRecommendationOptions } from "../types";

export interface UseRecommendationMachine {
  onScheduleRecommendationSelect: (p: SchedulingRecommendationOptions) => void;
  stateMatches: StateFrom<typeof recommendationMachine>["matches"];
  recommendationTypeSelectedForJobForm?:
    | "soonest"
    | "closest"
    | "self_schedule";
}

export function useRecommendations(): UseRecommendationMachine {
  const [current, send] = useRecommendationMachine();

  const onScheduleRecommendationSelect = (
    param: SchedulingRecommendationOptions,
  ) => {
    switch (param.action) {
      case "scheduleSelf":
        return send({
          type: "SCHEDULE_SELF",
        });
      case "scheduleSoonest":
        return send({
          type: "SCHEDULE_SOONEST",
        });
      case "scheduleClosest":
        return send({
          type: "SCHEDULE_CLOSEST",
        });
    }
  };

  const recommendationTypeSelectedForJobForm = useMemo(() => {
    if (current.matches("scheduleSelf")) {
      return "self_schedule";
    } else if (current.matches("scheduleSoonest")) {
      return "soonest";
    } else if (current.matches("scheduleClosest")) {
      return "closest";
    } else {
      return undefined;
    }
  }, [current]);

  return {
    onScheduleRecommendationSelect,
    stateMatches: current.matches,
    recommendationTypeSelectedForJobForm,
  };
}
