import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Text } from "@jobber/components/Text";
import styles from "./PurchaseFormErrors.module.css";

export function PurchaseFormErrors({ errors }: { errors: string[] }) {
  if (!errors.length) {
    return <></>;
  }
  return (
    <div className={styles.errorMessageContainer}>
      <Banner dismissible={false} type={"error"}>
        {errors.map((errorText: string, index: number) => {
          return (
            <div key={index}>
              <Text>{errorText}</Text>
              {index + 1 < errors.length ? <br></br> : <></>}
            </div>
          );
        })}
      </Banner>
    </div>
  );
}
