import React, { type Dispatch, type SetStateAction } from "react";
import { Grid } from "@jobber/components/Grid";
import { Card } from "@jobber/components/Card";
import { SetupGuideHeader } from "./components/SetupGuideHeader";
import {
  ProgressList,
  type ProgressListItemProps,
} from "./components/ProgressList";
import styles from "./SetupGuide.module.css";

export interface SetupStepProps {
  listItem: ProgressListItemProps;
  panel: JSX.Element;
}

export interface SetupGuideProps {
  steps: SetupStepProps[];
  currentStepIndex: number;
  onDismiss?: () => void;
  hideSetupGuide?: boolean;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
}

export function SetupGuide({
  steps,
  currentStepIndex,
  onDismiss,
  setCurrentStepIndex,
}: SetupGuideProps) {
  const activePanel = steps[currentStepIndex];
  const stepItems = steps.map((step, index) => {
    const customClick = step.listItem.onClick;
    step.listItem.onClick = () => {
      customClick?.();
      setCurrentStepIndex(index);
    };
    return {
      ...step.listItem,
      timeEstimate: step.listItem.timeEstimate,
    };
  }) as ProgressListItemProps[];

  return (
    <Card>
      <Grid alignItems={"stretch"} gap={false}>
        <Grid.Cell size={{ xs: 12, md: 4 }}>
          <div className={styles.title}>
            <SetupGuideHeader onClose={onDismiss} />
          </div>

          <ProgressList
            currentStepIndex={currentStepIndex}
            items={stepItems}
            setCurrentStepIndex={setCurrentStepIndex}
          />
        </Grid.Cell>

        <Grid.Cell size={{ xs: 12, md: 8 }}>
          <div className={styles.activePanel}>
            {activePanel && activePanel.panel}
          </div>
        </Grid.Cell>
      </Grid>
    </Card>
  );
}
