import React, { useEffect } from "react";
import { Option, Select } from "@jobber/components/Select";
import { useIntl } from "react-intl";
import { JobTypeTypeEnum } from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";

interface JobTypeCriterionProps {
  jobTypeValue: JobTypeTypeEnum;
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

export function JobTypeCriterion({
  jobTypeValue,
  criteriaIndex,
  updateAdditionalCriteria,
}: JobTypeCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  const selectValue = jobTypeValue;

  const onSelectionChange = (value: JobTypeTypeEnum) => {
    updateAdditionalCriteria({
      type: "updateJobTypeCriteria",
      payload: {
        index: criteriaIndex,
        jobTypeValue: value,
        isDirty: true,
      },
    });
  };

  //This is to make sure the jobTypeValue is updated in the store when the component is mounted
  useEffect(() => {
    updateAdditionalCriteria({
      type: "updateJobTypeCriteria",
      payload: {
        index: criteriaIndex,
        jobTypeValue: selectValue,
        isDirty: true,
      },
    });
  }, []);

  return (
    <div data-testid={"jobTypeCriterion"}>
      <div>
        <Select defaultValue={selectValue} onChange={onSelectionChange}>
          <Option value={JobTypeTypeEnum.RECURRING}>
            {formatMessage(messages.jobTypeIsRecurring)}
          </Option>
          <Option value={JobTypeTypeEnum.ONE_OFF}>
            {formatMessage(messages.jobTypeIsOneOff)}
          </Option>
        </Select>
      </div>
    </div>
  );
}
