import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import type { ClientAddress } from "~/utilities/API/graphql";

export function mapClientAddressToBillingAddress(
  clientAddress: ClientAddress | undefined,
  countryCode: string | undefined,
): BillingAddress | undefined {
  if (!clientAddress || !countryCode) return undefined;

  return {
    city: clientAddress.city,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    country_code: countryCode,
    pc: clientAddress.postalCode,
    province: clientAddress.province,
    street1: clientAddress.street1,
    street2: clientAddress.street2,
  };
}

export function getCountryCodeFromMap(
  countryCodesToNameMap: Record<string, string>,
  countryName: string | undefined,
): string | undefined {
  if (!countryName) return undefined;

  return Object.keys(countryCodesToNameMap).find(
    key => countryCodesToNameMap[key] === countryName,
  );
}
