import { useState } from "react";
import { SortDirectionEnum } from "~/utilities/API/graphql";

export interface SortState<T> {
  id: T;
  desc: boolean;
}

export interface SortInput<T> {
  key: T;
  direction: SortDirectionEnum;
}

function useFormatSort<T>(defaultSortKey: T) {
  function formatSort(sort: [SortState<T>] | []): SortInput<T> {
    return {
      key: sort[0]?.id || defaultSortKey,
      direction: sort[0]?.desc
        ? SortDirectionEnum.DESCENDING
        : SortDirectionEnum.ASCENDING,
    };
  }
  return { formatSort };
}

export function usePaymentDisputesSorting<T>(
  defaultSortKey: T,
  resetPagination: () => void,
) {
  const { formatSort } = useFormatSort<T>(defaultSortKey);
  const [sortingState, setSortingState] = useState<[] | [SortState<T>]>([
    {
      id: defaultSortKey,
      desc: false,
    },
  ]);
  const handleSortChange = (sortState: [] | [SortState<T>]) => {
    resetPagination();
    setSortingState(sortState);
  };

  return { formatSort, sortingState, handleSortChange };
}
