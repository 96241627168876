import React, { type VoidFunctionComponent } from "react";
import { BrowserRouter as RoutingProvider } from "react-router-dom";
import { APIProvider } from "~/utilities/API/APIProvider";
import { DatadogErrorBoundaryProvider } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";

interface EntryPointProps {
  /**
   * Any configuration necessary for the RoutingProvider.
   */
  routing: {
    /**
     * The `basepath` for react router to build off of.
     */
    base: string;
  };
}

/**
 * Prepare an entry point with required providers such as GraphQL and Routing.
 *
 * As we transition out of rails we will have many smaller react apps rendered
 * through controllers or erb. This allows a common set of providers for each
 * of these since we can't yet have a common react app entry point.
 *
 * ```tsx
 * export const SectionRoute = withEntryPointProviders(() => {
 *   const { path } = useRouteMatch();
 *
 *   return (
 *     <Switch>
 *       <Route exact path={join(path)}>
 *         Index View
 *       </Route>
 *       <Route exact path={join(path, "create")}>
 *         Create View
 *       </Route>
 *       <Route path="*">
 *         <NoMatch />
 *       </Route>
 *     </Switch>
 *   );
 * });
 * ```
 *
 * @param WrappedComponent - Component to be provided.
 */
export function withEntryPointProviders<WrappedProps extends object>(
  WrappedComponent: VoidFunctionComponent<WrappedProps>,
  datadogLoggerName?: string,
) {
  type CombinedProps = EntryPointProps & WrappedProps;

  function RoutedComponent({ routing, ...rest }: CombinedProps) {
    return (
      <APIProvider>
        <DatadogErrorBoundaryProvider loggerName={datadogLoggerName}>
          <RoutingProvider basename={routing.base}>
            <WrappedComponent {...(rest as WrappedProps)} />
          </RoutingProvider>
        </DatadogErrorBoundaryProvider>
      </APIProvider>
    );
  }
  return RoutedComponent;
}
