import GenericError from "jobber/payments/errors/GenericError";
import type {
  JobberApiResponse,
  PaymentResponse,
} from "jobber/payments/responses";
import normalizeResponse from "jobber/payments/responses/normalizeResponse";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default async function performCharge(
  url: string,
  body: FormData,
): Promise<PaymentResponse> {
  const response = await fetch(url, {
    method: "POST",
    body,
    credentials: "include",
  });

  // Specifying these specific status codes instead of
  // `!response.ok` because the API doesn't return json
  if (response.status === 500 || response.status === 404) {
    return {
      success: false,
      error: new GenericError(response.statusText),
    };
  }
  const data = await response.json();

  return normalizeResponse(data as JobberApiResponse);
}
