import { defineMessages } from "react-intl";

export const messages = defineMessages({
  costFormatAmount: {
    id: "landingPageCard.planCard.costFormatAmount",
    defaultMessage: `$\{amount}/mo`,
    description: "Monthly cost of the plan",
  },
  costSavingsAmount: {
    id: "landingPageCard.planCard.costSavings",
    defaultMessage: `Save $\{amount}/mo`,
    description: "Cost savings of the plan",
  },
  costSavingsAmountPartTwo: {
    id: "landingPageCard.planCard.costSavingsPartTwo",
    defaultMessage: " when",
    description: "Cost savings of the plan",
  },
  costSavingsBillingDate: {
    id: "landingPageCard.planCard.costSavingsBillingDate",
    defaultMessage: "billed annually",
    description: "Cost savings of the plan",
  },
});
