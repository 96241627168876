/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type {
  PaymentRequest,
  Stripe,
  StripeCardElement,
  StripeElements,
} from "@stripe/stripe-js";
import classNames from "classnames";
import React, { type Dispatch, useEffect } from "react";
import {
  emptyToUndefined,
  mapJobberToStripeAddress,
} from "jobber/payments_sca/utils/mapJobberToStripeAddress";
import { usePaymentSubmit } from "jobber/payments_react/hooks/usePaymentSubmit/usePaymentSubmit";
import { PaymentMethodListType } from "jobber/payments_react/BaseCollectPaymentDialog/CollectPaymentDialogProps.d";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { CreditCardForm } from "./CreditCardForm";
import type { PaymentAction, PaymentState } from "./paymentReducer";
import type { PaymentResponse } from "./PaymentResponse";
import { VaultedPaymentMethodsSelector } from "./VaultedPaymentMethodsSelector";
import { getAmountInCents } from "./utils";

export function JobberPaymentsCreditCardForm(props: {
  paymentRequest: PaymentRequest | undefined;
  inClientHub: boolean;
  setPaymentRequest(pr: PaymentRequest): void;
  clientId: string;
}) {
  const [paymentState, paymentDispatcher] = usePaymentReducer();
  const { paymentRequest, setPaymentRequest, inClientHub, clientId } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [authenticityToken] = useAuth();
  const [createPaymentUrl, confirmPaymentUrl] = useUrls(
    "jobberPaymentsCreatePaymentUrl",
    "jobberPaymentsConfirmPaymentUrl",
  );
  const { countryCode, currencyCode } = useAccount();

  useEffect(() => {
    if (!stripe || !elements || paymentState.status !== "notInitialized") {
      return;
    }

    paymentDispatcher({ type: "completingInitialization" });
    if (stripe && inClientHub) {
      const label =
        paymentState.attachedToType === "quote"
          ? "Deposit total"
          : "Invoice total";

      const amountInCents = getAmountInCents(paymentState.amount);

      const pr = stripe.paymentRequest({
        country: countryCode,
        currency: currencyCode.toLowerCase(),
        total: {
          label,
          amount: amountInCents, // Amount needs to be supplied in cents
        },
        requestPayerName: true,
      });

      pr.on("paymentmethod", async ev => {
        paymentDispatcher({
          type: "updatingJobberPaymentsSelectedPaymentMethod",
          paymentMethodId: ev.paymentMethod.id,
          paymentOrigin: "ewallet_origin",
        });
        paymentDispatcher({ type: "requestingJobberPaymentCharge" });
        ev.complete("success");
      });

      // Check the availability of the Payment Request API.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      pr.canMakePayment()
        .then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        })
        .catch();
    }
  }, [paymentState.status, stripe, elements]);

  usePaymentSubmit(paymentState, paymentDispatcher, {
    submitWithPaymentState: submit,
  });

  const vaultedCardsLoaded = paymentState.paymentMethods;
  const noVaultedCardSelected = !paymentState.jobberPaymentMethodId;
  const showCreditCardForm =
    (noVaultedCardSelected && vaultedCardsLoaded) ||
    paymentState.paymentMethods?.length === 0;
  const showPaymentRequestButton = !(
    paymentState.mandatoryCardOnFile && paymentState.attachedToType === "quote"
  );

  return (
    <>
      {paymentRequest && showPaymentRequestButton && (
        <>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <div className="u-borderTop u-marginTop u-paddingBottomSmall">
            <div className="u-textCenter" style={{ marginTop: "-0.75rem" }}>
              <span className="u-bgColorWhite inlineLabel--medium">
                Or pay with card
              </span>
            </div>
          </div>
        </>
      )}
      <VaultedPaymentMethodsSelector
        clientId={clientId}
        paymentMethodListType={PaymentMethodListType.CREDIT_CARD}
      />
      {showCreditCardForm && (
        <div
          className={classNames(
            "u-paddingSmall",
            paymentState.paymentMethods?.length
              ? "u-bgColorGreenLightest"
              : "u-paddingTopNone",
          )}
          style={{
            marginLeft: "calc(-1 * var(--modal--padding))",
            marginRight: "calc(-1 * var(--modal--padding))",
          }}
        >
          <CreditCardForm inClientHub={inClientHub} />
        </div>
      )}
    </>
  );

  async function submit(cancelAwareDispatcher: Dispatch<PaymentAction>) {
    if (!stripe || !elements) {
      throw new Error(
        "payment attempt made before stripe or elements is ready, check paymentState.status before submitting payment",
      );
    }

    try {
      const paymentMethod = paymentState.jobberPaymentMethodId
        ? undefined
        : await createPaymentMethod(paymentState, stripe, elements);

      const createPaymentResp = await createPayment(
        paymentMethod?.id,
        authenticityToken,
        paymentState,
        createPaymentUrl,
      );

      await handleResponse(createPaymentResp); // do not omit this `await`, errors won't bubble if missing
    } catch (e) {
      onError((e as Error).message);
    }

    async function handleResponse(response: PaymentResponse.GenericResponse) {
      if (!stripe) return;

      if (response.success) {
        onSuccess();
      } else if (response.error) {
        onError(response.error_message);
        return;
      } else if (response.status === "requires_confirmation") {
        const confirmRes = await confirmPayment(response.payment_intent_id);
        await handleResponse(confirmRes); // do not omit this `await`, errors won't bubble if missing
      } else if (response.status === "requires_action") {
        const paymentIntent = await handleCardAction(
          response.payment_intent_client_secret,
          stripe,
        );
        const confirmResponse = await confirmPayment(paymentIntent.id);
        await handleResponse(confirmResponse); // do not omit this `await`, errors won't bubble if missing
      }

      async function confirmPayment(paymentIntentId: string) {
        const url = `${confirmPaymentUrl}/${paymentIntentId}/confirm`;
        const confirmResponse = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(
            mapPaymentState(paymentState, authenticityToken),
          ),
        });

        return (await confirmResponse.json()) as PaymentResponse.GenericResponse;
      }
    }

    function onSuccess() {
      cancelAwareDispatcher({
        type: "completingCharge",
        response: { success: true },
      });
    }

    function onError(errorMessage?: string) {
      cancelAwareDispatcher({
        type: "completingCharge",
        response: {
          success: false,
          errorMessage: errorMessage || "Payment failed",
        },
      });
    }
  }
}

async function createPaymentMethod(
  paymentState: PaymentState,
  stripe: Stripe,
  elements: StripeElements,
) {
  const cardElement = elements.getElement(CardElement) as StripeCardElement;

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: paymentState.billingDetails && {
      name: emptyToUndefined(paymentState.billingDetails.name),
      address:
        paymentState.billingDetails.address &&
        mapJobberToStripeAddress(paymentState.billingDetails.address),
    },
  });

  if (paymentMethod) return paymentMethod;

  const errorMessage =
    (error && error.message) || "Cannot use this credit card";
  throw new Error(errorMessage);
}

async function createPayment(
  paymentMethodId: string | undefined,
  authenticityToken: string,
  paymentState: PaymentState,
  createPaymentUrl: string,
) {
  const response = await fetch(createPaymentUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      ...mapPaymentState(paymentState, authenticityToken),
      payment_method_id: paymentMethodId,
    }),
  });

  return (await response.json()) as PaymentResponse.GenericResponse;
}

async function handleCardAction(paymentIntentSecret: string, stripe: Stripe) {
  const { paymentIntent, error } =
    await stripe.handleCardAction(paymentIntentSecret);

  if (paymentIntent) return paymentIntent;

  const errorMessage = (error && error.message) || "Card action failed";
  throw new Error(errorMessage);
}

function mapPaymentState(
  paymentState: PaymentState,
  authenticityToken: string,
) {
  return {
    amount: paymentState.amount,
    authenticity_token: authenticityToken,
    attached_to_id: paymentState.attachedToId,
    attached_to_type: paymentState.attachedToType,
    vault_payment_method:
      paymentState.shouldSavePaymentMethod ||
      (paymentState.mandatoryCardOnFile &&
        paymentState.attachedToType === "quote"),
    jobber_payments_payment_method_id: paymentState.jobberPaymentMethodId,
    approval_signature_data: paymentState.signature,
    payment_origin: paymentState.paymentOrigin,
  };
}
