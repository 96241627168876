import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  dateRangeButtonDefaultLabel: {
    id: "shared.inputDateRange.dateRangeButtonDefaultLabel",
    defaultMessage: "All",
    description:
      "Label for the date range button when no date range is selected. This should represent all time.",
  },
});
