import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  columnsLabel: {
    id: "reporting.report.columnsLabel",
    defaultMessage: "Columns",
    description: "Label for columns select button",
  },
  columnSelectCancelLabel: {
    id: "reporting.report.columnSelectCancelLabel",
    defaultMessage: "Cancel",
    description: "Cancel button for column select modal",
  },
  columnSelectSaveLabel: {
    id: "reporting.report.columnSelectSaveLabel",
    defaultMessage: "Save",
    description: "Save button for column select modal",
  },
  columnSelectHeaderTitle: {
    id: "reporting.report.columnSelectHeaderTitle",
    defaultMessage: "Configure columns",
    description: "The header title for the column select modal",
  },
  showAllColumns: {
    id: "reporting.report.showAllColumns",
    defaultMessage: "Show all",
    description: "Message to show all columns",
  },
  hiddenColumnsCount: {
    id: "reporting.report.hiddenColumnsCount",
    defaultMessage: "Hidden ({hiddenColumnsCount})",
    description: "Message to show the count of hidden columns",
  },
});
