import type { QueryErrors } from "~/utilities/API/graphql";

export function isEinValid(ein: string | undefined) {
  return ein && ein.match(/\d/g)?.length === 9 && /^[0-9-]+$/g.test(ein);
}

export const businessRegistrationNumberErrors = {
  required: "Tax ID (EIN) is required",
  correctLength: "Tax ID (EIN) must be 9 digits",
};

export function hasTaxIdRegistrationError(
  userErrors: QueryErrors[] | undefined,
) {
  return userErrors?.some(
    error =>
      error.path.includes("businessName") &&
      error.path.includes("businessRegistrationNumber"),
  );
}

export function taxIdRegistrationErrorMessage(
  userErrors: QueryErrors[] | undefined,
) {
  return (
    userErrors?.find(
      error =>
        error.path.includes("businessName") &&
        error.path.includes("businessRegistrationNumber"),
    )?.message || ""
  );
}
