/* eslint-disable max-statements */
import pluralize from "pluralize";
import {
  NotificationReminderUnit,
  type NotificationSchedule,
} from "~/utilities/API/graphql";

export const getDurationInWords = (
  schedule: NotificationSchedule,
): string | undefined => {
  const offset = schedule.offset;
  if (offset) {
    const duration = offset.value.toString();
    const unit = offset.unit.toLowerCase();
    if (offset.value.toString() === "0") {
      return `the same ${unit} as`;
    }

    const durationString = pluralize(unit, parseInt(duration, 10), true);
    return `${durationString} before`;
  }
};

export const getMethodInWords = (
  deliveryMethod: NotificationSchedule["deliveryMethod"],
) => {
  switch (deliveryMethod) {
    case "EMAIL":
      return "email";
    case "SMS":
      return "text message";
    default:
      return "text message or email";
  }
};

export const getTimeInWords = (schedule: NotificationSchedule) => {
  if (
    schedule.at &&
    schedule.offset &&
    schedule.offset.unit === NotificationReminderUnit.DAY
  ) {
    return schedule.at;
  } else {
    return "";
  }
};
