import { useQuery } from "@apollo/client";
import type { ActiveUsersQueryQuery } from "~/utilities/API/graphql";
import { ACTIVE_USERS_QUERY } from "./graphql";
import type { UsersQueryType } from "./types";

export function useActiveUsersQuery(canViewJobCosts: boolean): {
  users?: UsersQueryType[];
  loading: boolean;
  error?: string;
} {
  const { data, loading, error } = useQuery<ActiveUsersQueryQuery>(
    ACTIVE_USERS_QUERY,
    {
      variables: {
        canViewJobCosts: canViewJobCosts,
      },
    },
  );

  return {
    users: data?.users.nodes,
    loading,
    error: error?.message,
  };
}
