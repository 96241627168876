import React from "react";
import { Grid } from "@jobber/components/Grid";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components";
import { SubscriptionAddonCard } from "~/jobber/billing/components/SubscriptionAddonCard/SubscriptionAddonCard";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { messages } from "./messages";

interface SubscriptionAddonsProps {
  recurlyPublicKey: string;
  subscriptionAddons: SubscriptionAddon[];
}

export function SubscriptionAddons(props: SubscriptionAddonsProps) {
  const { recurlyPublicKey, subscriptionAddons } = props;
  const { formatMessage } = useIntl();

  if (!subscriptionAddons.length) {
    return <Text>{formatMessage(messages.unableToPurchaseMessage)}</Text>;
  }

  let recommendedAddons = subscriptionAddons.filter(addon => addon.recommended);
  let moreAddons = subscriptionAddons.filter(addon => !addon.recommended);

  // If nothing is a recommended addon then everything is a recommended addon
  if (recommendedAddons.length === 0) {
    recommendedAddons = subscriptionAddons;
    moreAddons = [];
  }

  return (
    <div data-testid="addon-cards">
      <Content>
        <Text variation="subdued">{formatMessage(messages.recommended)}</Text>
        <Grid alignItems="stretch">
          {recommendedAddons.map((subscriptionAddon, index) => (
            <Grid.Cell
              key={`addon-card-${index}`}
              size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            >
              <SubscriptionAddonCard
                recurlyPublicKey={recurlyPublicKey}
                subscriptionAddon={subscriptionAddon}
              />
            </Grid.Cell>
          ))}
        </Grid>

        {moreAddons.length > 0 && (
          <>
            <Text variation="subdued">
              {formatMessage(messages.moreAddons)}
            </Text>
            <Grid alignItems="stretch">
              {moreAddons.map((subscriptionAddon, index) => (
                <Grid.Cell
                  key={`addon-card-${index}`}
                  size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }}
                >
                  <SubscriptionAddonCard
                    recurlyPublicKey={recurlyPublicKey}
                    subscriptionAddon={subscriptionAddon}
                  />
                </Grid.Cell>
              ))}
            </Grid>
          </>
        )}
      </Content>
    </div>
  );
}
