import { gql } from "@apollo/client";

export const GET_ALL_REVENUE_TOTALS = gql`
  query revenueTotalsAll($startDate: String!, $endDate: String!) {
    all: revenueCalculation(
      revenueStartDate: $startDate
      revenueEndDate: $endDate
      state: "all"
    ) {
      revenueTotal
    }
    incomplete: revenueCalculation(
      revenueStartDate: $startDate
      revenueEndDate: $endDate
      state: "incomplete"
    ) {
      revenueTotal
    }
    active: revenueCalculation(
      revenueStartDate: $startDate
      revenueEndDate: $endDate
      state: "active"
    ) {
      revenueTotal
    }
    completed: revenueCalculation(
      revenueStartDate: $startDate
      revenueEndDate: $endDate
      state: "completed"
    ) {
      revenueTotal
    }
  }
`;
