import { defineMessages } from "react-intl";

const prefix = "setupWizard.companyPage.";

export const messages = defineMessages({
  companyPageDefaultHeading: {
    id: `${prefix}companyPageDefaultHeading`,
    defaultMessage: "Tell us about your business",
    description: "Default page heading for the company page",
  },
  companyPageDefaultCopy: {
    id: `${prefix}companyPageCopy`,
    defaultMessage:
      "Your company name and industry help us personalize Jobber with features that are specifically designed for businesses just like yours.",
    description: "Default page copy for the company page",
  },
  serviceProviderAltText: {
    id: `${prefix}serviceProviderAltText`,
    defaultMessage: "service provider",
    description: "Service provider alt text for the setup wizard",
  },
});
