import { defineMessages } from "react-intl";

export const messages = defineMessages({
  copilotName: {
    id: "jobbeCopilot.jobberAssistantRoot.name",
    defaultMessage: "Copilot",
    description: "Jobber Copilot name",
  },
  betaFlag: {
    id: "jobbeCopilot.jobberAssistantRoot.beta",
    defaultMessage: "Beta",
    description: "Jobber Copilot beta flag",
  },
});
