import { screen } from "@testing-library/react";

export const reviewText = () => screen.findByRole("heading", { level: 2 });
export const tooltip = () => screen.findByRole("tooltip");
export const googleLogo = () => screen.findByAltText("Google logo");
export const ratingLabel = (rating: number) =>
  screen.findByLabelText(`Rated ${rating} out of 5`);
export const companyCustomer = (name: string) =>
  screen.findByText(`${name}, Customer`);
export const authorName = (name: string) => screen.findByText(name);
