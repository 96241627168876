import { gql } from "@apollo/client";

export const UNREAD_CONVERSATIONS = gql`
  query UnreadConversations {
    unreadConversations {
      totalCount
    }
  }
`;

export const UNREAD_CONVERSATIONS_SUBSCRIPTION = gql`
  subscription UpdateUnreadConversations {
    unreadConversations {
      totalCount
    }
  }
`;
