import React from "react";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import styles from "./ErrorBanner.module.css";
import { messages } from "./messages";

export interface ErrorBannerProps {
  isVisible: boolean;
}

export function ErrorBanner({ isVisible }: ErrorBannerProps): JSX.Element {
  const { formatMessage } = useIntl();

  if (!isVisible) {
    return <></>;
  }
  return (
    <div className={styles.bannerContainer}>
      <Banner
        type={"error"}
        primaryAction={{
          label: formatMessage(messages.refreshButton),
          onClick: () => window.location.reload(),
        }}
      >
        {formatMessage(messages.genericError)}
      </Banner>
    </div>
  );
}
