import { Checkbox } from "@jobber/components/Checkbox";
import React, { useState } from "react";
import { Tooltip } from "@jobber/components/Tooltip";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { trackCofCheckboxClicked } from "jobber/workOrders/components/AutomaticPayments/utils/trackinteractions";
import styles from "./SendCardOnFileRequestOptions.module.css";
import { messages } from "./messages";

export function SendCardOnFileRequestEmailOnlyOption() {
  const EVENT_SOURCE = "email";
  const { formatMessage: t } = useIntl();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.checkboxContainer}>
      <Checkbox
        name="should_send_email_payment_request_on_file"
        label={t(messages.emailOnlyCheckboxTitleMessage)}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <Tooltip message={t(messages.tooltipMessage)}>
        <div className={styles.infoIcon}>
          <Icon name="alert" size="small" />
        </div>
      </Tooltip>
    </div>
  );

  function handleCheckboxChange() {
    setIsChecked(prevChecked => !prevChecked);
    trackCofCheckboxClicked(isChecked, EVENT_SOURCE);
  }
}
