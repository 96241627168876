import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import type { PaymentMethodsQuery } from "~/utilities/API/graphql";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import { isBankAccountPaymentMethod } from "utilities/jobberPayments/paymentMethods/paymentMethods";
import { mapToStoredPaymentMethod } from "jobber/payments_react/utils/paymentMethodMappers";
import { PAYMENT_METHODS_QUERY } from "./JobberPaymentsMethods.graphql";

export interface UseFetchPaymentMethodsInput {
  clientId: string;
  includeBankAccounts?: boolean;
  isClientIdEncoded?: boolean;
}

interface UsePaymentMethodsResponse {
  paymentMethods: StoredPaymentMethodInterface[];
  loading: boolean;
  error: Error | undefined;
  refetchPaymentMethods: () => Promise<void>;
}

export function useFetchPaymentMethods({
  clientId,
  isClientIdEncoded = false,
  includeBankAccounts = false,
}: UseFetchPaymentMethodsInput): UsePaymentMethodsResponse {
  const variables = {
    clientId: isClientIdEncoded ? clientId : btoa(clientId),
  };

  const { loading, error, data, refetch } = useQuery<PaymentMethodsQuery>(
    PAYMENT_METHODS_QUERY,
    {
      fetchPolicy: "no-cache",
      variables,
    },
  );

  const paymentMethods = useMemo(() => {
    const paymentMethodsQueryResult = data?.paymentMethods?.nodes;
    return (paymentMethodsQueryResult ?? [])
      .filter(
        paymentMethod =>
          includeBankAccounts || !isBankAccountPaymentMethod(paymentMethod),
      )
      .map(mapToStoredPaymentMethod)
      .filter(notEmpty);
  }, [data, includeBankAccounts]);

  return {
    error,
    loading,
    paymentMethods,
    refetchPaymentMethods: async () => {
      await refetch();
    },
  };
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
