import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import { Icon } from "@jobber/components/Icon";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/upgradePaths/ANALYTICS_EVENTS";
import { TwoWayTextMessageMarketingImg } from "./images";
import styles from "./TwoWayTextMessagingModal.module.css";

interface TwoWayTextMessagingModalProps {
  redirectLink: string;
  ctaName: string;
  topNavButtonCtaName: string;
  showTopNavButton: boolean;
  hasSeenTopNavButton: boolean;
  modalOpen: boolean;
  source: string | undefined;
}

export function TwoWayTextMessagingModal(props: TwoWayTextMessagingModalProps) {
  const [modalOpen, setmodalOpen] = useState(props.modalOpen);
  const [source, setSource] = useState(props.source);

  const handleOnClick = () => {
    setmodalOpen(true);
    setSource("top_nav_button");
    if (props.topNavButtonCtaName) {
      CTAMarkConverted(props.topNavButtonCtaName);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      CTAMarkSeen(props.ctaName);
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: "two_way_sms_modal",
        source,
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    if (props.showTopNavButton) {
      if (props.topNavButtonCtaName) {
        CTAMarkSeen(props.topNavButtonCtaName);
      }
      if (!props.hasSeenTopNavButton) {
        Amplitude.TRACK_EVENT("CTA Viewed", {
          name: "two_way_sms_top_nav_button",
        });
      }
    }
  }, [props.showTopNavButton]);

  return (
    <>
      {props.showTopNavButton && (
        <button
          className={styles.topNavButton}
          onClick={handleOnClick}
          aria-label="SMS Messenger"
        >
          <Icon name="sms2" color="interactiveSubtle" />
          <div className={styles.lockBadge}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 12C6 12 9.5 12 10 12C10 6 9.5 5 12 5C14.5 5 14 6 14 12C15.5 12 18 12 18 12V19H6V12Z"
                fill="none"
              />
              <path
                d="M12 14C11.4477 14 11 14.4477 11 15V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V15C13 14.4477 12.5523 14 12 14Z"
                fill="#3CA2E0"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3C9.79086 3 8 4.79086 8 7V10H6C4.89543 10 4 10.8954 4 12V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V12C20 10.8954 19.1046 10 18 10H16V7C16 4.79086 14.2091 3 12 3ZM14 7V10H10V7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7ZM6 12V19H18V12H6Z"
                fill="#3CA2E0"
              />
            </svg>
          </div>
        </button>
      )}

      <Modal
        title="Have you heard?"
        open={modalOpen}
        onRequestClose={handleCloseButtonClick}
        primaryAction={{
          label: "Learn More",
          onClick: handlePrimaryAction,
          variation: "learning",
          url: props.redirectLink,
        }}
      >
        <Content spacing="large">
          <div>
            <img
              src={TwoWayTextMessageMarketingImg.src}
              alt={TwoWayTextMessageMarketingImg.alt}
              className={styles.img}
            />
          </div>
          <Content spacing="small">
            <Heading level={3}>Text messaging just got better!</Heading>
            <Text>
              Communicate with customers anytime, anywhere, with two-way text
              messaging on the Jobber Grow plan.
            </Text>
          </Content>
        </Content>
      </Modal>
    </>
  );

  function handleCloseButtonClick(): void {
    CTAMarkConverted(props.ctaName);
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.twoWayTextMessagingUpgrade.dialogInteraction,
      {
        interaction:
          ANALYTICS_EVENTS.twoWayTextMessagingUpgrade.interactions.dismissed,
        value: ANALYTICS_EVENTS.twoWayTextMessagingUpgrade.locations.prompt,
      },
    );
    setmodalOpen(false);
  }

  function handlePrimaryAction() {
    CTAMarkConverted(props.ctaName);
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.twoWayTextMessagingUpgrade.learnMore,
      {
        location: ANALYTICS_EVENTS.twoWayTextMessagingUpgrade.locations.prompt,
      },
    );
  }
}
