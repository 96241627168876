import { InlineLabel } from "@jobber/components/InlineLabel";
import React from "react";
import { Tooltip } from "@jobber/components/Tooltip";
import type { CampaignStatus } from "~/utilities/API/graphql";
import { useCampaignStatusInformation } from "jobber/campaigns/views/CampaignsLandingPage/components/CampaignStatusLabel/hooks/useCampaignStatusInformation";

export type StatusNode = CampaignStatus;
interface CampaignStatusLabelProps {
  status: StatusNode;
  statusMessage: string | undefined;
  scheduledAt?: string | undefined;
}

export function CampaignStatusLabel({
  status,
  statusMessage,
  scheduledAt,
}: CampaignStatusLabelProps) {
  const { statusText, statusColor, statusTooltip } =
    useCampaignStatusInformation(status, statusMessage, scheduledAt);

  return statusTooltip ? (
    <Tooltip message={statusTooltip}>
      <InlineLabel color={statusColor}>{statusText}</InlineLabel>
    </Tooltip>
  ) : (
    <InlineLabel color={statusColor}>{statusText}</InlineLabel>
  );
}
