import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import styles from "./CancellationFormModal.module.css";

interface CancellationSaveOfferStepProps {
  onPrevious(): void;
  onNext(): void;
  skipStep?(): boolean;
}

export function CancellationSaveOfferStep({
  onPrevious,
  onNext,
  onAcceptSaveOffer,
}: CancellationSaveOfferStepProps & {
  onAcceptSaveOffer(
    setButtonLoadingState: (loadingState: boolean) => void,
  ): Promise<void>;
}) {
  const [buttonLoadingState, setButtonLoadingState] = useState(false);

  async function handleClickAcceptDiscount() {
    await onAcceptSaveOffer(setButtonLoadingState);
  }

  return (
    <>
      <div className={styles.discountOfferContainer}>
        <Content>
          <Text align="center">Consider sticking around with Jobber and</Text>
          <Heading level={2}>
            <Emphasis variation="highlight">Save 50%</Emphasis> for your next 2
            months
          </Heading>
        </Content>
      </div>
      <div className={styles.actionButtonsContainer}>
        <Button label="Previous" onClick={onPrevious} variation="subtle" />
        <div className={styles.twoRightActionButtonsContainer}>
          <Button label="No thanks" onClick={onNext} type="secondary" />
          <Button
            label="Yes, I want 50% off"
            loading={buttonLoadingState}
            onClick={handleClickAcceptDiscount}
          />
        </div>
      </div>
    </>
  );
}
