import React, { Fragment } from "react";
import { Banner } from "@jobber/components/Banner";
import { Text } from "@jobber/components/Text";
import styles from "./PreviouslyConnectedAppsBanner.module.css";

export interface AppNameAndLink {
  name: string;
  reconnectUrl: string;
}

export interface PreviouslyConnectedAppsBannerProps {
  apps: AppNameAndLink[];
  marketplaceUrl: string;
}

export function PreviouslyConnectedAppsBanner({
  apps,
  marketplaceUrl,
}: PreviouslyConnectedAppsBannerProps) {
  const multipleApps = apps.length > 1;

  if (apps.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.previouslyConnectedApps}>
      <Banner type="notice">
        <Text>
          <>
            Welcome back! Your account had previously connected to apps from the
            <a
              href={marketplaceUrl}
              className={styles.hyperlinkText}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              App Marketplace
            </a>
            {". "}
            Reconnect the{" "}
          </>
          {renderApps()}
          <>
            {" "}
            {multipleApps ? "apps" : "app"} to resume using{" "}
            {multipleApps ? "these" : "this"}.
          </>
        </Text>
      </Banner>
    </div>
  );

  function renderApps() {
    return (
      <>
        {apps.map((app, index) => {
          if (index === 0) {
            return (
              <Fragment key={app.name}>
                <a
                  href={app.reconnectUrl}
                  className={styles.hyperlinkText}
                  target="_blank"
                  rel="noreferrer"
                >
                  {app.name}
                </a>
              </Fragment>
            );
          } else if (index === apps.length - 1) {
            return (
              <Fragment key={app.name}>
                , and{" "}
                <a
                  href={app.reconnectUrl}
                  className={styles.hyperlinkText}
                  target="_blank"
                  rel="noreferrer"
                >
                  {app.name}
                </a>
              </Fragment>
            );
          }
          return (
            <Fragment key={app.name}>
              ,{" "}
              <a
                href={app.reconnectUrl}
                className={styles.hyperlinkText}
                target="_blank"
                rel="noreferrer"
              >
                {app.name}
              </a>
            </Fragment>
          );
        })}
      </>
    );
  }
}
