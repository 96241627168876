import { gql } from "@apollo/client";

export const GET_DISPUTE_QUERY = gql`
  query GetDisputeQuery($id: EncodedId!) {
    jobberPaymentsDispute(id: $id) {
      amount
      category
      disputedAt
      evidenceDueBy
      id
      reason
      status
      issuerEvidences(last: 1) {
        nodes {
          fileEvidence
          textEvidence
        }
      }
      refundedAt
      submittedEvidence {
        serviceOccurrence
        customerName
        billingAddress
        productDescription
        customerEmailAddress
        refundRefusalExplanation
        supportingEvidence {
          nodes {
            category
            fileId
          }
        }
      }
      counterDisputeReason
      responseSubmittedAt
      resolutionReceivedAt
      paymentRecord {
        id
        adjustmentType
        amount
        entryDate
        client {
          id
          firstName
          lastName
          emails {
            address
            primary
          }
          billingAddress {
            street
            city
            province
            postalCode
          }
        }
        invoice {
          id
          invoiceNumber
        }
        quote {
          id
          quoteNumber
        }
        ... on JobberPaymentsCreditCardPaymentRecord {
          nameOnCard
        }
      }
    }
  }
`;
