import { useHistory } from "react-router-dom";
import { CAMPAIGNS_LANDING_PAGE_PATH } from "jobber/campaigns/constants";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { CampaignReviewErrorState } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions";
import { useSendCampaign } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useSendCampaign/useSendCampaign";

export interface useHandleSendArgs {
  campaignId: string;
  totalCount: number;
}

export function useHandleSend({ campaignId, totalCount }: useHandleSendArgs) {
  const history = useHistory();
  const {
    sentDelighter: { setOpen, setClientCount, setType },
    campaignContent: { templateType },
  } = useCampaignWizardContext();
  const { campaignSend } = useSendCampaign({ templateType });

  const handleSend = async (scheduledAt?: Date) => {
    const { userErrors, mutationErrors } = await campaignSend({
      campaignId: campaignId,
      input: { scheduledAt: scheduledAt?.toISOString() },
    });

    if (mutationErrors) {
      throw new Error(CampaignReviewErrorState.AUTHORIZATION_ERROR);
    }

    if (userErrors.length > 0) {
      throw parseError(userErrors[0].message);
    }

    history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
    if (!scheduledAt) {
      // trigger the sent delighter modal to open
      setType(templateType);
      setClientCount(totalCount);
      setOpen(true);
    }
  };

  const parseError = (firstError: string) => {
    switch (firstError) {
      case "scheduledAt must be in the future":
        return new Error(CampaignReviewErrorState.SCHEDULE_INVALID);
      default:
        return new Error(CampaignReviewErrorState.GENERIC_ERROR);
    }
  };

  return { handleSend };
}
