/* eslint-disable no-restricted-imports */
import { type Dispatch, useEffect, useState } from "react";
import { JobberOnline } from "jobber/payments/utils";
import type { PaymentReducerContextType } from "~/utilities/contexts/internal/PaymentReducerContext";
import {
  type PaymentAction,
  type PaymentState,
  paymentReducer,
} from "../paymentReducer";

const JOBBER_PAYMENTS_TYPE =
  JobberOnline.constants.balanceAdjustments.paymentTypes.jobberPayments;
const JOBBER_PAYMENTS_ACH_TYPE =
  JobberOnline.constants.balanceAdjustments.paymentTypes.jobberPaymentsAch;

export function useDerivedStates(
  paymentState: PaymentState,
  paymentDispatcher: Dispatch<PaymentAction>,
) {
  const [reducerContext, setReducerContext] =
    useState<PaymentReducerContextType>({
      paymentState,
      paymentDispatcher,
    });
  useEffect(
    () => setReducerContext({ paymentState, paymentDispatcher }),
    [paymentState, paymentReducer],
  );

  return {
    reducerContext,
    isPaymentInProgress: paymentState.status === "inProgress",
    isJobberPaymentsCreditCard:
      +paymentState.paymentType === JOBBER_PAYMENTS_TYPE,
    isJobberPaymentsAch: +paymentState.paymentType === JOBBER_PAYMENTS_ACH_TYPE,
    onNewAmount(amount: string) {
      if (paymentState.status === "inProgress") return;

      paymentDispatcher({ type: "updatingAmount", amount });
    },
    onPaymentTypeChange(paymentType: string) {
      paymentDispatcher({ type: "updatingPaymentType", paymentType });
    },
  };
}
