import { Checkbox } from "@jobber/components/Checkbox";
import { Chip, Chips } from "@jobber/components/Chips";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { trackCofCheckboxClicked } from "jobber/workOrders/components/AutomaticPayments/utils/trackinteractions";
import styles from "./SendCardOnFileRequestOptions.module.css";
import { messages } from "./messages";

export function SendCardOnFileRequestAllOptions() {
  const EVENT_SOURCE = "email and sms";
  const { formatMessage: t } = useIntl();
  const [isChecked, setIsChecked] = useState(false);

  const [selectedChip, setSelectedChip] = useState(
    "should_send_email_payment_request_on_file",
  );

  const handleChipChange = (value: string) => {
    setSelectedChip(value);
  };

  return (
    <div className={styles.checkboxContainer}>
      <Checkbox
        label={t(messages.allOptionsCheckboxTitleMessage)}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <div className={styles.optionChipsContainer}>
        <Chips
          type="singleselect"
          selected={selectedChip}
          name={selectedChip}
          onChange={handleChipChange}
        >
          <Chip
            disabled={!isChecked}
            label={"Email"}
            key={"should_send_email_payment_request_on_file_chip"}
            value={"should_send_email_payment_request_on_file"}
          />
          <Chip
            disabled={!isChecked}
            label={"SMS"}
            key={"should_send_sms_payment_request_on_file_chip"}
            value={"should_send_sms_payment_request_on_file"}
          />
        </Chips>
      </div>
    </div>
  );

  function handleCheckboxChange() {
    setIsChecked(prevChecked => !prevChecked);
    trackCofCheckboxClicked(isChecked, EVENT_SOURCE);
  }
}
