import { defineMessages } from "react-intl";

export const messages = defineMessages({
  receptionistToggleEnabled: {
    id: "chat.components.chatDrawer.chat.aiReceptionistToggle.enabled",
    defaultMessage: "AI Receptionist is on for this conversation",
    description: "The toggle label when AI Receptionist is enabled",
  },
  receptionistToggleDisabled: {
    id: "chat.components.chatDrawer.chat.aiReceptionistToggle.disabled",
    defaultMessage: "AI Receptionist is off for this conversation",
    description: "The toggle label when AI Receptionist is disabled",
  },
});
