import type {
  NotificationDeliveryMethod,
  NotificationReminderUnit,
} from "~/utilities/API/graphql";
import { ActionTypes } from "jobber/settings/notifications/ActionTypes";
import type { ActionFields } from "jobber/settings/notifications/notificationInterfaces";
import type { NotificationModalState } from "./NotificationModal";

export function scheduleReducer(
  schedules: NotificationModalState["schedules"],
  action: ActionFields,
) {
  switch (action.type) {
    case ActionTypes.ChangeDeliveryMethod:
      return update(
        action.id,
        schedules,
        schedule =>
          (schedule.deliveryMethod =
            action.value as NotificationDeliveryMethod),
      );

    case ActionTypes.ChangeScheduleOffsetUnit:
      return update(
        action.id,
        schedules,
        schedule =>
          schedule.offset &&
          (schedule.offset.unit = action.value as NotificationReminderUnit),
      );

    case ActionTypes.ChangeScheduleOffsetValue:
      return update(
        action.id,
        schedules,
        schedule =>
          schedule.offset && (schedule.offset.value = action.value as number),
      );

    case ActionTypes.ChangeScheduleTime:
      return update(
        action.id,
        schedules,
        schedule => action.value && (schedule.at = action.value.toString()),
      );

    case ActionTypes.ToggleSchedule:
      return update(
        action.id,
        schedules,
        schedule => (schedule.enabled = action.value as boolean),
      );

    default:
      return schedules;
  }
}

function update(
  id: string,
  collection: NotificationModalState["schedules"],
  action: (item: NotificationModalState["schedules"]["nodes"][0]) => void,
) {
  const item = collection.nodes.find(node => node.id === id);

  if (item) {
    action(item);
  }

  return collection;
}
