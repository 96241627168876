import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { QuoteSummaryReport } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport/QuoteSummaryReport";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import {
  type QuoteSummaryExportMutation,
  type QuoteSummaryExportMutationVariables,
  QuoteSummarySortKey,
} from "~/utilities/API/graphqlFranchiseManagement";
import { useFormatSortAndFilter } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport/hooks/useFormatSortAndFilter";
import { useQuoteSummaryReportData } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport/hooks/useQuoteSummaryReportData";
import { useQuoteSummaryTotalsData } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport/hooks/useQuoteSummaryTotalsData";
import type {
  QuoteSummaryData,
  QuoteSummarySortState,
  QuoteSummaryTotalsData,
} from "./types";
import { PAGE_NUMBER_OPTIONS } from "./constants";
import { QUOTE_SUMMARY_EXPORT_MUTATION } from "./QuoteSummaryReport.graphql";

// eslint-disable-next-line max-statements
export function QuoteSummaryReportLoader() {
  const { formatSort, formatFilter } = useFormatSortAndFilter();
  const [reportFilter, setReportFilter] = useState<FilterType>({
    key: "date_range",
    value: {
      startDate: defaultDateRangeOption.start,
      endDate: defaultDateRangeOption.end,
    },
  });

  const [sortingState, setSortingState] = useState<[QuoteSummarySortState]>([
    {
      id: QuoteSummarySortKey.FRANCHISE_NAME,
      desc: false,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_NUMBER_OPTIONS[0],
  });

  const [indexedCursors, setIndexedCursors] = useState<[string | undefined]>([
    undefined,
  ]);

  const { fetchQuoteSummary, data, loading, error } =
    useQuoteSummaryReportData();

  useEffect(() => {
    const sort = formatSort(sortingState);
    const filter = formatFilter(reportFilter);
    const cursor = indexedCursors[pageIndex];
    const first = pageSize;

    fetchQuoteSummary({ sort, filter, cursor, first });
  }, [sortingState, reportFilter, pageIndex, pageSize]);

  const {
    fetchQuoteSummaryTotals,
    data: totalsData,
    loading: totalsLoading,
    error: totalsError,
  } = useQuoteSummaryTotalsData();

  useEffect(() => {
    const filter = formatFilter(reportFilter);

    fetchQuoteSummaryTotals(filter);
  }, [reportFilter]);

  const [reportIsExported, setReportIsExported] = useState(false);

  const [exportCsv] = useMutation<
    QuoteSummaryExportMutation,
    QuoteSummaryExportMutationVariables
  >(QUOTE_SUMMARY_EXPORT_MUTATION, {
    variables: {
      input: {
        sort: formatSort(sortingState),
        filter: formatFilter(reportFilter),
      },
    },
  });

  async function exportFunction() {
    const { errors } = await exportCsv();
    setReportIsExported(!errors);
  }

  const totalItems = data?.quotesSummary?.totalCount || 0;
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useMemo(() => {
    if (data?.quotesSummary.reportPageInfo) {
      const newCursorIndex = indexedCursors;

      if (data.quotesSummary.reportPageInfo.startCursor) {
        newCursorIndex[pageIndex] =
          data.quotesSummary.reportPageInfo.startCursor;
      }

      if (data.quotesSummary.reportPageInfo.nextCursor) {
        newCursorIndex[pageIndex + 1] =
          data.quotesSummary.reportPageInfo.nextCursor;
      }

      setIndexedCursors(newCursorIndex);
    }
  }, [data]);

  const handleDateFilterChange = (range: FilterType) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setReportFilter(range);
  };

  const handleSortChange = (sortState: [QuoteSummarySortState]) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setSortingState(sortState);
  };

  return (
    <QuoteSummaryReport
      data={data?.quotesSummary.records as QuoteSummaryData[]}
      loading={loading || totalsLoading}
      error={error?.message || totalsError?.message}
      totalsData={totalsData?.quotesSummaryTotals as QuoteSummaryTotalsData}
      filterProps={{
        dateRangeFilter: reportFilter,
        onFilterChange: handleDateFilterChange,
      }}
      sortingProps={{
        manualSorting: true,
        state: sortingState,
        onSortingChange: handleSortChange,
      }}
      paginationProps={{
        manualPagination: true,
        onPaginationChange: setPagination,
        itemsPerPage: PAGE_NUMBER_OPTIONS,
        totalItems,
        state: pagination,
        pageCount: Math.ceil(totalItems / pageSize),
      }}
      exportProps={{
        exportFunction: exportFunction,
        setReportIsExported: setReportIsExported,
        reportIsExported: reportIsExported,
      }}
    />
  );
}
