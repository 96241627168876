import { gql } from "@apollo/client";

export const ACTIVATE_LITE_TO_CORE_TRIAL = gql`
  mutation ActivateLiteToCoreTrial {
    activateLiteToCoreTrial {
      membership {
        id
        experimentId
        variationId
        abTestableId
        abTestableType
      }
      userErrors {
        message
      }
    }
  }
`;
