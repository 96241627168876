import bind from "bind-decorator";
import classNames from "classnames";
import React, { type ChangeEvent, Component, type ReactNode } from "react";

export const INPUT_ARIA_LABEL_DEPRECATED = "Input";

export enum TextFieldType_DEPRECATED {
  textarea = "textarea",
  textinput = "textinput",
}

interface TextFieldProps {
  stacked?: boolean;
  autoSize: boolean;
  inputType: TextFieldType_DEPRECATED;
  name?: string;
  placeholder?: string;
  value: string;
  autoComplete?: string;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  readonly?: boolean;
  onFocus?(): void;
  onBlur?(): void;
  onChange?(newValue: string): void;
  onKeyDown?(
    newValue: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void;
}

/**
 * TextField react component
 */
export class TextField_DEPRECATED extends Component<TextFieldProps> {
  public static defaultProps = {
    autoSize: false,
    inputType: TextFieldType_DEPRECATED.textarea,
    value: "",
    stacked: false,
  };

  public render(): ReactNode {
    const input = this.getInput();
    if (input === undefined) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const className = classNames({ "fieldGroup-field": this.props.stacked });

    if (this.props.placeholder != undefined) {
      return (
        <placeholder-field
          class={className}
          label={this.props.placeholder}
          auto-size={this.props.autoSize}
        >
          {input}
        </placeholder-field>
      );
    }

    return input;
  }

  public getInput() {
    switch (this.props.inputType) {
      case TextFieldType_DEPRECATED.textarea:
        return (
          <textarea
            aria-label={INPUT_ARIA_LABEL_DEPRECATED}
            name={this.props.name}
            value={this.props.value}
            onChange={this.handleChange}
            readOnly={this.props.readonly}
          />
        );
      case TextFieldType_DEPRECATED.textinput:
        return (
          <input
            ref={this.props.forwardedRef}
            autoComplete={this.props.autoComplete}
            aria-label={INPUT_ARIA_LABEL_DEPRECATED}
            className="placeholderField-input"
            name={this.props.name}
            value={this.props.value}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
        );
      default:
        return;
    }
  }

  @bind
  private handleOnFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  @bind
  private handleOnBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  @bind
  private handleChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const newValue = event.currentTarget.value;
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  @bind
  private handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const newValue = event.currentTarget.value;
    if (this.props.onKeyDown) {
      this.props.onKeyDown(newValue, event);
    }
  }
}
