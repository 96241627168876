import React from "react";
import {
  CustomFieldArea,
  CustomFieldBoolean,
  CustomFieldLink,
  CustomFieldNumber,
  CustomFieldSelect,
  CustomFieldText,
} from "./customFieldTypes";
import type { ConfigurationState } from "./customFieldReducer";
import type { AreaValue, LinkValue, NumberValue, SelectValue } from "./types";

export type CustomFieldValue =
  | AreaValue
  | NumberValue
  | SelectValue
  | LinkValue
  | string
  | boolean;

export interface CustomFieldProps {
  configurationState: ConfigurationState;
  isInAppGroup?: boolean;
  showReadOnlyForm: boolean;
  valueUpdateHandler(configurationId: number, update: CustomFieldValue): void;
}

export function CustomField({
  configurationState,
  showReadOnlyForm,
  isInAppGroup = false,
  valueUpdateHandler,
}: CustomFieldProps) {
  const isEditable = showReadOnlyForm ? false : !configurationState.readOnly;

  switch (configurationState.type) {
    case "area":
      return (
        <CustomFieldArea
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          stringValue={configurationState.stringValue}
          editProps={{
            // "Yes" initially
            value: configurationState.value as AreaValue,
            editing: isEditable,
            onChange: (newValue: AreaValue) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                newValue,
              );
            },
          }}
        />
      );

    case "bool":
      return (
        <CustomFieldBoolean
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          stringValue={configurationState.stringValue}
          editProps={{
            value: configurationState.value as boolean,
            editing: isEditable,
            onChange: (value: boolean) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                value,
              );
            },
          }}
        />
      );

    case "int":
      return (
        <CustomFieldNumber
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          stringValue={configurationState.stringValue}
          editProps={{
            value: configurationState.value as NumberValue,
            editing: isEditable,
            onChange: (value: NumberValue) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                value,
              );
            },
          }}
          error={configurationState.error}
        />
      );

    case "link":
      return (
        <CustomFieldLink
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          value={configurationState.value as LinkValue}
          editProps={{
            editing: isEditable,
            onChange: (value: LinkValue) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                value,
              );
            },
          }}
        />
      );

    case "select":
      return (
        <CustomFieldSelect
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          stringValue={configurationState.stringValue}
          editProps={{
            value: configurationState.value as SelectValue,
            editing: isEditable,
            onChange: (value: SelectValue) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                value,
              );
            },
          }}
          error={configurationState.error}
        />
      );

    case "text":
      return (
        <CustomFieldText
          isInAppGroup={isInAppGroup}
          label={configurationState.label}
          stringValue={configurationState.stringValue}
          editProps={{
            value: configurationState.value as string,
            editing: isEditable,
            onChange: (value: string) => {
              valueUpdateHandler(
                configurationState.configurationId as number,
                value,
              );
            },
          }}
          error={configurationState.error}
        />
      );
  }
}
