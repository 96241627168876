import type { Translations } from "~/utilities/contexts/internal/TranslationContext";
import type { JobCostRecord } from "./JobCostHeader";

interface ProfitMarginBreakdown {
  titleKey: keyof Translations;
  amount: number | "—";
  followingSeparator?: "hyphen" | "equals";
  highlightNegativeValues: boolean;
  displayPopoverHelp?: boolean;
  isBlurred: boolean;
}

interface UseProfitBarQuery {
  profitMargin: number | "—";
  profitMarginBreakdown: ProfitMarginBreakdown[];
  loading: boolean;
  hasJobCostingData: boolean;
}

export const useProfitBarQuery = (
  jobCosting: JobCostRecord,
  isRecurring?: boolean,
): UseProfitBarQuery => {
  const recordExists = !jobCosting.recordDoesNotExist;

  return {
    hasJobCostingData: recordExists,
    loading: false,
    profitMargin: getProfitMargin(jobCosting, recordExists),
    profitMarginBreakdown: [
      {
        titleKey: isRecurring ? "profitBarRevenue" : "profitBarPrice",
        amount: recordExists ? jobCosting.totalRevenue : "—",
        followingSeparator: "hyphen",
        highlightNegativeValues: false,
        isBlurred: !recordExists,
      },
      {
        titleKey: "profitBarLineItemCost",
        amount: recordExists ? jobCosting.lineItemCost : "—",
        followingSeparator: "hyphen",
        highlightNegativeValues: false,
        isBlurred: !recordExists,
      },
      {
        titleKey: "profitBarLabour",
        amount: recordExists ? jobCosting.labourCost : "—",
        followingSeparator: "hyphen",
        highlightNegativeValues: false,
        isBlurred: !recordExists,
      },
      {
        titleKey: "profitBarExpenses",
        amount: recordExists ? jobCosting.expenseCost : "—",
        followingSeparator: "equals",
        highlightNegativeValues: false,
        isBlurred: !recordExists,
      },
      {
        titleKey: "profitBarProfit",
        amount: recordExists ? jobCosting.profitAmount : "—",
        highlightNegativeValues: true,
        displayPopoverHelp: recordExists,
        isBlurred: !recordExists,
      },
    ],
  };
};

function getProfitMargin(jobCosting: JobCostRecord, recordExists: boolean) {
  const profitMargin = jobCosting.profitPercentage
    ? +jobCosting.profitPercentage?.toFixed(2)
    : 0;

  return recordExists ? profitMargin : "—";
}
