import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "campaigns.deactivate.title",
    defaultMessage: "Deactivate campaign?",
    description: "Pause and Edit modal title label",
  },
  text: {
    id: "campaigns.deactivate.text",
    defaultMessage: `Emails will no longer be sent out once the trigger and conditions are met. You can always turn the automation back on in the future.`,
    description: "Pause and Edit message label",
  },
  buttonLabel: {
    id: "campaigns.deactivate.buttonLabel",
    defaultMessage: "Deactivate Campaign",
    description: "Confirm button label",
  },
  error: {
    id: "campaigns.deactivate.error",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error message",
  },
  toast: {
    id: "campaigns.deactivate.toast",
    defaultMessage: "Deactivated campaign",
    description: "Toast message",
  },
});
