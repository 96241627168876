import { Content } from "@jobber/components/Content";
import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import type { Client } from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicClient";
import { messages } from "./messages";
import styles from "./SendCardOnFileRequestOptions.module.css";
import { SendCardOnFileRequestAllOptions } from "./SendCardOnFileRequestAllOptions";
import { SendCardOnFileRequestEmailOnlyOption } from "./SendCardOnFileRequestEmailOnlyOption";
import { SendCardOnFileRequestSMSOnlyOption } from "./SendCardOnFileRequestSMSOnlyOption";

interface SendCardOnFileRequestOptionsProps {
  client?: Client;
  availableOnPlan: boolean;
}

function hasValidEmail(client?: Client): boolean {
  return !!client?.email;
}

function hasValidPhone(client?: Client): boolean {
  return !!client && client.hasSmsAllowedPhone;
}

export function SendCardOnFileRequestOptions({
  client,
  availableOnPlan,
}: SendCardOnFileRequestOptionsProps) {
  const { formatMessage: t } = useIntl();
  const clientHasValidEmail = hasValidEmail(client);
  const clientHasValidPhone = hasValidPhone(client);

  const renderOptionsComponent = () => {
    if (clientHasValidEmail && clientHasValidPhone) {
      return <SendCardOnFileRequestAllOptions />;
    } else if (clientHasValidEmail && !clientHasValidPhone) {
      return <SendCardOnFileRequestEmailOnlyOption />;
    } else if (!clientHasValidEmail && clientHasValidPhone) {
      return <SendCardOnFileRequestSMSOnlyOption />;
    } else {
      return client?.id ? (
        <Text>
          {t(messages.noContactInfoMessage)}
          <a href={`/clients/${client?.id}`} target="_blank" rel="noreferrer">
            {t(messages.noContactInfoUrlMessage)}
          </a>
        </Text>
      ) : (
        <Text variation="subdued">{t(messages.noContactProfileMessage)}</Text>
      );
    }
  };

  return (
    <Content>
      <div className={styles.contentContainer}>
        <Heading level={4}>{t(messages.headingTitle)}</Heading>
        {!availableOnPlan && (
          <div className={styles.descriptionForNoAutoPayAccounts}>
            <Text>{t(messages.descriptionForNoAutoPayAccounts)}</Text>
          </div>
        )}
        <div className={styles.optionsContainer}>
          {renderOptionsComponent()}
        </div>
      </div>
    </Content>
  );
}
