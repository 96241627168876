import React from "react";
import { Text } from "@jobber/components/Text";
import { decodeId } from "~/utilities/decodeId/decodeId";
import type { MessageDataFragment } from "~/utilities/API/graphql";
import {
  CLIENT_COMMS_REPORT_MESSAGE_END,
  CLIENT_COMMS_REPORT_MESSAGE_START,
} from "./constants";
import styles from "./ConversationStartInformation.module.css";

interface ConversationStartInformationProps {
  message: MessageDataFragment;
}

export function ConversationStartInformation({
  message,
}: ConversationStartInformationProps) {
  if (
    message.externalContactObject?.id &&
    message.__typename === "EmailMessage"
  ) {
    const decodedId = decodeId(message.externalContactObject.id);
    const reportPath = `/reports/client_communications?report%5Bclient_id%5D=${decodedId}&report%5Brange%5D=12_months`;
    return (
      <div className={styles.conversationStartInformation}>
        <Text variation="subdued" align="center" size="small">
          {CLIENT_COMMS_REPORT_MESSAGE_START}{" "}
          <a href={reportPath} target="_blank" rel="noopener noreferrer">
            {CLIENT_COMMS_REPORT_MESSAGE_END}
          </a>
        </Text>
      </div>
    );
  }

  return <></>;
}
