/* eslint-disable import/no-internal-modules */
import { add } from "date-fns";
import type { SchedulingAssistantContext } from "../machines/schedulingAssistant.machine";

interface SelectedSlotDetails {
  title?: string;
  primaryDescription?: string;
}

export function makeSelectedSlotEvent({
  start,
  assigneeId,
  durationInSeconds,
  primaryDescription,
  title,
}: {
  durationInSeconds: number;
} & SelectedSlotDetails &
  Exclude<SchedulingAssistantContext["selectedTime"], undefined>) {
  return {
    id: "draggable-slot",
    start: start,
    title: title || "",
    primaryDescription: primaryDescription,
    end: add(start, { seconds: durationInSeconds }),
    allDay: false,
    classNames: ["selected"],
    display: "auto",
    editable: true,
    resourceId: assigneeId,
    resourceEditable: true,
    droppable: true,
  };
}
