import React, { type Dispatch, type SetStateAction } from "react";
import { Card } from "@jobber/components/Card";
import { Accordion } from "components/Accordion";
import type { AccordionItemSummary } from "components/Accordion/components/AccordionItem/types";
import styles from "./SetupGuideMini.module.css";
import type { SetupStepProps } from "../../SetupGuide";
import { SetupGuideHeader } from "../SetupGuideHeader";

export interface SetupGuideMiniProps {
  steps: SetupStepProps[];
  currentStepIndex: number;
  onDismiss?: () => void;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
}

export function SetupGuideMini({
  steps,
  currentStepIndex,
  onDismiss,
  setCurrentStepIndex,
}: SetupGuideMiniProps) {
  const setupGuideMiniSteps = () => {
    const setupGuideMiniItems: AccordionItemSummary[] = [];

    steps.map(item => {
      const title = item.listItem.title;
      const secondaryTitle = item.panel.props.content.timeEstimate;
      let completed = undefined;
      let itemContent = {
        title: title,
        content: item.panel,
        secondaryTitle: secondaryTitle,
      };

      if (item.listItem.completed) {
        completed = {
          leftIcon: "checkmark",
          textDisabled: item.listItem.completed,
        };
        itemContent = { ...completed, ...itemContent };
      }

      setupGuideMiniItems.push(itemContent);
    });
    return setupGuideMiniItems;
  };

  const items = setupGuideMiniSteps();

  return (
    <Card>
      <div className={styles.handheldHeader}>
        <div className={styles.title}>
          <SetupGuideHeader onClose={onDismiss} />
        </div>
      </div>
      <div className={styles.list}>
        {steps.length > 0 && (
          <Accordion
            items={items}
            currentItemIndex={currentStepIndex}
            setCurrentItemIndex={setCurrentStepIndex}
          />
        )}
      </div>
    </Card>
  );
}
