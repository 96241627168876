import React, { type Dispatch, type SetStateAction } from "react";
import classnames from "classnames";
import styles from "./ProgressList.module.css";
import {
  ProgressListItem,
  type ProgressListItemProps,
} from "./ProgressListItem";

interface ProgressListProps {
  /**
   * Highlights the list item with green on the first load indicating that
   * it is the current step in the list.
   */
  readonly currentStepIndex: number;

  /**
   * Array of the steps displayed in the progress list.
   */
  readonly items: ProgressListItemProps[];

  readonly setCurrentStepIndex: Dispatch<SetStateAction<number>>;
}

export function ProgressList({
  currentStepIndex,
  items,
  setCurrentStepIndex,
}: ProgressListProps) {
  return (
    <ul className={styles.list}>
      {items.map((item: ProgressListItemProps, index: number) => {
        const isActive = currentStepIndex === index;
        const progressListStyle = classnames({
          [styles.listItem]: true,
          [styles.active]: isActive,
        });

        return (
          <li key={index.toString()} className={progressListStyle}>
            <ProgressListItem
              completed={item.completed}
              isActive={isActive}
              id={index}
              title={item.title}
              timeEstimate={item.timeEstimate}
              onClick={() => {
                setCurrentStepIndex(index);
                item?.onClick && item.onClick();
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}
