import React, { useState } from "react";
import { Switch } from "@jobber/components/Switch";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Markdown } from "@jobber/components/Markdown";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import {
  FrequencyType,
  type InvoiceFrequencySettings,
  useDynamicSelectedFrequency,
} from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicSelectedFrequency";
import type { StoredPaymentMethodInterface } from "~/bunker/vaultedCards/StoredCardListItem";
import { StoredCardListItem } from "~/bunker/vaultedCards/StoredCardListItem";
import { trackAutopayToggleClicked } from "jobber/workOrders/components/AutomaticPayments/utils/trackinteractions";
import styles from "./CardOnFileSwitch.module.css";
import { messages } from "./messages";

interface CardOnFileSwitchProps {
  paymentMethod: StoredPaymentMethodInterface;
  errorMessage: string | undefined;
  selectedFrequency: InvoiceFrequencySettings | undefined;
  disableSwitch: boolean;
  automaticallyChargeInvoice?: boolean;
  newRecord?: boolean;
  availableOnPlan: boolean;
}

export function CardOnFileSwitch({
  automaticallyChargeInvoice: initialAutomaticallyChargeInvoice = false,
  paymentMethod,
  errorMessage,
  disableSwitch,
  newRecord = false,
  availableOnPlan,
}: CardOnFileSwitchProps) {
  const { formatMessage: t } = useIntl();
  const [automaticallyChargeInvoice, setAutomaticallyChargeInvoice] = useState(
    initialAutomaticallyChargeInvoice,
  );

  const [selectedFrequency, setSelectedFrequency] =
    useState<InvoiceFrequencySettings>();
  useDynamicSelectedFrequency(setSelectedFrequency);

  const renderFrequency = () => {
    switch (selectedFrequency?.frequencyType) {
      case FrequencyType.AS_NEEDED:
        return (
          <Markdown content={t(messages.mustBeScheduled)} externalLink={true} />
        );
      default:
        return (
          <Markdown
            content={t(messages.createdAndPaidAutomatically)}
            externalLink={true}
          />
        );
    }
  };

  return (
    <Content>
      {availableOnPlan && (
        <div className={styles.switchContainer}>
          <Heading level={4}>{t(messages.title)}</Heading>
          <Switch
            disabled={disableSwitch}
            name="work_order[automatically_charge_invoice]"
            value={automaticallyChargeInvoice && !disableSwitch}
            onChange={handleChange}
          />
        </div>
      )}
      {availableOnPlan && renderFrequency()}
      {errorMessage ? (
        <Text variation="error">{errorMessage}</Text>
      ) : (
        <Content>
          <div className={styles.contentContainer}>
            <Heading level={4}>{t(messages.paymentMethodOnFileTitle)}</Heading>
            <div className={styles.optionsContainer}>
              <StoredCardListItem {...paymentMethod} />
            </div>
          </div>
        </Content>
      )}
    </Content>
  );

  function handleChange(value: boolean) {
    setAutomaticallyChargeInvoice(value);
    trackAutopayToggleClicked(newRecord, value);
  }
}
