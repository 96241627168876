import { Button } from "@jobber/components/Button";
import React, { useCallback, useContext, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { generateLastSubmittedStepQandA } from "jobber/setupWizard/utils";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import styles from "../../SetupWizardForms.module.css";

export interface PromotionPageDetailsProps extends SetupWizardStepProps {
  welcomeText: string;
  title: string;
  information: string;
  subtext: string;
  buttonText: string;
  cancelButtonText: string;
}

export function PromotionPageForm({
  navigation,
  welcomeText,
  title,
  information,
  subtext,
  buttonText,
  cancelButtonText,
  promoTrackingTag,
  showPromoStatusQuestionKey,
  showPromoStatusInterestedValue,
}: PromotionPageDetailsProps) {
  const { updateWizardData } = useContext(SetupWizardContext);
  const [loadingButton, setLoadingButton] = useState<string>("");

  const handleSubmit = async () => {
    setLoadingButton("primary");
    trackCtaClick(
      ANALYTICS_EVENTS.interactedWithPromoMessage.interaction.clickedModalCta,
    );
    updateWizardData(
      {
        questionsAndAnswers: [
          generateLastSubmittedStepQandA(Routes.promotion),
          {
            question: showPromoStatusQuestionKey,
            answer: showPromoStatusInterestedValue,
          },
        ],
      },
      navigation.isFinalStep,
      navigation.onNext,
    );
  };

  const handleCancel = async () => {
    setLoadingButton("secondary");
    trackCtaClick(
      ANALYTICS_EVENTS.interactedWithPromoMessage.interaction.dismissedModal,
    );
    updateWizardData(
      {
        questionsAndAnswers: [
          generateLastSubmittedStepQandA(Routes.promotion),
          { question: showPromoStatusQuestionKey, answer: "Not Interested" },
        ],
      },
      navigation.isFinalStep,
      navigation.onNext,
    );
  };

  const trackCtaClick = useCallback(
    (interaction: string) => {
      Amplitude.TRACK_EVENT(
        ANALYTICS_EVENTS.interactedWithPromoMessage.eventName,
        {
          interaction: interaction,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          promo_name: promoTrackingTag,
        },
      );
    },
    [promoTrackingTag],
  );

  return (
    <>
      <Content>
        {welcomeText && <Heading level={4}>{welcomeText}</Heading>}
        <Heading level={1}>{title}</Heading>
        {information && <Heading level={2}>{information}</Heading>}
        {subtext && (
          <Typography data-testid="promo-subtext">{subtext}</Typography>
        )}
      </Content>
      <div className={styles.promotionButtonGroup}>
        <div className={styles.promotionButton}>
          <Button
            type="primary"
            fullWidth={true}
            label={buttonText}
            loading={loadingButton === "primary"}
            size="large"
            onClick={handleSubmit}
          />
        </div>
        <div className={styles.promotionButton}>
          <Button
            type="secondary"
            fullWidth={true}
            label={cancelButtonText}
            loading={loadingButton === "secondary"}
            size="large"
            onClick={handleCancel}
          />
        </div>
      </div>
    </>
  );
}
