import React, { createContext, useState } from "react";

export interface PurchaseFormContextType {
  submitting?: boolean;
  billingAddressChanged: boolean;
  setBillingAddressChanged: (changed: boolean) => void;
}

export const PurchaseFormContext = createContext<PurchaseFormContextType>({
  submitting: false,
  billingAddressChanged: false,
  setBillingAddressChanged: () => {
    // Do nothing
  },
});

interface PurchaseFormContextProviderProps {
  submitting: boolean;
  children: React.ReactNode;
}

export function PurchaseFormContextProvider({
  submitting,
  children,
}: PurchaseFormContextProviderProps) {
  const [billingAddressChanged, setBillingAddressChanged] =
    useState<boolean>(false);

  return (
    <PurchaseFormContext.Provider
      value={{ submitting, billingAddressChanged, setBillingAddressChanged }}
    >
      {children}
    </PurchaseFormContext.Provider>
  );
}
