import { gql, useQuery } from "@apollo/client";
import type {
  AccountFeature,
  AccountFeaturesQuery,
  AccountFeaturesQueryVariables,
} from "~/utilities/API/graphql";

export const ACCOUNT_FEATURES_QUERY = gql`
  query AccountFeatures {
    account {
      id
      features {
        available
        enabled
        name
      }
    }
  }
`;

export function useAccountFeatures(): AccountFeature[] {
  const { data } = useQuery<
    AccountFeaturesQuery,
    AccountFeaturesQueryVariables
  >(ACCOUNT_FEATURES_QUERY, { fetchPolicy: "cache-first" });

  return data?.account?.features ?? [];
}
