import { Autocomplete } from "@jobber/components/Autocomplete";
import { Heading } from "@jobber/components/Heading";
import styles from "./FilterAutocomplete.module.css";
import type { FilterAutocompleteProps } from "./types";

export const FilterAutocomplete = ({
  heading,
  getOptions,
  initialOptions,
  onChange,
  placeholder,
  value,
  onBlur,
  onFocus,
}: FilterAutocompleteProps) => (
  <div className={styles.filterAutocompleteContainer}>
    {heading && <Heading level={6}>{heading}</Heading>}
    <Autocomplete
      getOptions={getOptions}
      initialOptions={initialOptions}
      onChange={onChange}
      placeholder={placeholder}
      value={value || { label: "", value: "" }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  </div>
);
