import { useState } from "react";
import { DurationPeriod, type SchedulerState } from "./types";

export const useScheduler = (initialState: Partial<SchedulerState> = {}) => {
  const [state, setState] = useState<SchedulerState>({
    scheduleLater: false,
    startDate: new Date(),
    selectedRecurringOption: 0,
    anyTimeOfDay:
      initialState.anyTimeOfDay !== undefined
        ? initialState.anyTimeOfDay
        : !(initialState.startTime || initialState.endTime),
    startTime: undefined,
    endTime: undefined,
    recurrence: {
      rule: { interval: 1, type: DurationPeriod.Day },
      ends: {
        type: "date",
        date: new Date(),
        numOfPeriods: 1,
        durationPeriod: DurationPeriod.Day,
      },
    },
    ...initialState,
  });

  return [state, setState] as const;
};
