import React from "react";
import combineClassNames from "classnames";
import classNames from "./Appointment.module.css";

interface AppointmentProps {
  eventId: string;
  title: React.ReactNode;
  children: React.ReactNode;
}

export const Appointment = ({ eventId, title, children }: AppointmentProps) => {
  return (
    <div
      className={combineClassNames(classNames.container)}
      data-eventid={eventId}
    >
      <div className={classNames.content}>
        <span>{title}</span>
      </div>
      <div className={classNames.details}>
        <span>{children}</span>
      </div>
    </div>
  );
};
