import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Link } from "@jobber/components/Link";
import { Modal } from "@jobber/components/Modal";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./ChangePlanModal.module.css";
import { messages } from "../../messages";

export interface ChangePlanModalProps {
  appId: string;
  appName: string;
  requiredPlan: string;
  openChangePlanModal: boolean;
  onCancelChangePlan(): void;
}

export function ChangePlanModal({
  appId,
  appName,
  requiredPlan,
  openChangePlanModal,
  onCancelChangePlan,
}: ChangePlanModalProps) {
  const { formatMessage } = useIntl();

  const {
    changePlanModalConfirmLabel,
    changePlanModalUpgradeMessage,
    changePlanModalTitle,
    changePlanModalLearnMore,
    changePlanModalRequiredPlan,
  } = messages;

  const {
    confirmLabel,
    confirmMessage,
    confirmTitle,
    learnMoreText,
    requiredPlanText,
  } = {
    confirmLabel: formatMessage(changePlanModalConfirmLabel),
    confirmMessage: formatMessage(changePlanModalUpgradeMessage, { appName }),
    requiredPlanText: formatMessage(changePlanModalRequiredPlan, {
      requiredPlan,
    }),
    confirmTitle: formatMessage(changePlanModalTitle, { appName }),
    learnMoreText: formatMessage(changePlanModalLearnMore),
  };

  function onConfirmChangePlan() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickChoosePlan, {
      appId: appId,
      appName: appName,
      source: ANALYTICS_PROPERTIES.marketplacePageSource,
    });
    window.open("/accounts/billing_info/pricing", "_blank");
  }

  function upgradePlanContent() {
    return (
      <div className={styles.ChangePlanModalContent}>
        <Text>
          {requiredPlanText}
          <br />
          <Link url="https://getjobber.com/pricing/" external>
            {learnMoreText}
          </Link>
        </Text>
        <Text>{confirmMessage}</Text>
      </div>
    );
  }

  return (
    <Modal
      title={confirmTitle}
      open={openChangePlanModal}
      primaryAction={{
        label: confirmLabel,
        onClick: onConfirmChangePlan,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: onCancelChangePlan,
      }}
      onRequestClose={onCancelChangePlan}
    >
      {upgradePlanContent()}
    </Modal>
  );
}
