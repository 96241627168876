import { callBridgeMethod as bridgeMethodCaller } from "jobber/hooks/useCreateBridgeMethod";

/**
 * Namespace for Bridges into and out of legacy code.
 */
export namespace Bridges {
  export const callBridgeMethod = bridgeMethodCaller;
}

interface WindowWithBridges {
  Bridges: typeof Bridges;
}

(window as unknown as WindowWithBridges).Bridges = Bridges ?? {};
