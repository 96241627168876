import React from "react";
import styles from "./styles.module.css";

interface BulletedItemsProps {
  items: string[] | JSX.Element[];
}

export function BulletedItems({ items }: BulletedItemsProps) {
  return (
    <ul className={styles.bulletedItems}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}
