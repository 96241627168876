import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { differenceInCalendarDays } from "date-fns";
import { Markdown } from "@jobber/components/Markdown";
import { Icon } from "@jobber/components/Icon";
import { Modal } from "@jobber/components/Modal";
import { Tooltip } from "@jobber/components/Tooltip";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import googleOrganicBookingsPreview from "@images/googleOrganicBookingsPreview.png";
import styles from "./styles.module.css";
import { messages } from "./messages";

const DAYS_TO_SHOW_INFO_TIP = 7;

interface GoogleInfoTipProps {
  enabled: boolean;
  firstEnabledAt?: Date;
}

export function GoogleInfoTip({ enabled, firstEnabledAt }: GoogleInfoTipProps) {
  const { formatMessage } = useIntl();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    // Close the modal if one of the props changed
    setInfoModalOpen(false);
  }, [enabled, firstEnabledAt]);

  const isNewActivation =
    enabled &&
    firstEnabledAt &&
    differenceInCalendarDays(new Date(), firstEnabledAt) <
      DAYS_TO_SHOW_INFO_TIP;

  if (!enabled) {
    return (
      <>
        <div
          role="button"
          tabIndex={0}
          aria-expanded={infoModalOpen}
          className={styles.infoTipWithIcon}
          onClick={() => setInfoModalOpen(true)}
          onKeyDown={() => setInfoModalOpen(true)}
        >
          <Text>{formatMessage(messages.deactivatedTip)}</Text>
          <Icon name="help" size="small" />
        </div>
        <Modal
          open={infoModalOpen}
          onRequestClose={() => setInfoModalOpen(false)}
          title={formatMessage(messages.infoModalTitle)}
        >
          <Content>
            <div className={styles.infoModalContentWrapper}>
              <div className={styles.infoModelContent}>
                <Markdown content={formatMessage(messages.infoModalContent)} />
                <Markdown
                  content={formatMessage(messages.infoModalContent2)}
                  externalLink
                />
              </div>
              <div className={styles.infoModelImage}>
                <img
                  src={googleOrganicBookingsPreview}
                  width={290}
                  height={360}
                  alt={formatMessage(messages.infoModalPreviewAltText)}
                />
              </div>
            </div>
          </Content>
        </Modal>
      </>
    );
  } else if (isNewActivation) {
    return (
      <Markdown
        externalLink
        content={formatMessage(messages.newActivationTip)}
      />
    );
  } else {
    return (
      <Tooltip message={formatMessage(messages.activatedTooltip)}>
        <div className={styles.infoTipWithIcon}>
          <Text>{formatMessage(messages.activatedTip)}</Text>
          <Icon name="help" size="small" />
        </div>
      </Tooltip>
    );
  }
}
