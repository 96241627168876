import React, {
  type MouseEvent,
  type UIEvent,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { Icon } from "@jobber/components/Icon";
import { Tooltip } from "@jobber/components/Tooltip";
import { Avatar } from "@jobber/components/Avatar";
import { showTodoDialog } from "jobber/bridges/todoDialog";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./TeamRow.module.css";

export interface VisitData {
  visitId: number;
  jobId?: number;
  title: string;
  startTime?: string;
  active: boolean;
  completed: boolean;
}

export interface TeamRowProps {
  name: string;
  initials: string;
  avatarUrl?: string;
  userId?: number;
  totalItemCount: number;
  orderedVisitData: VisitData[];
  ticking: boolean;
  totalTime: string;
}

export function TeamRow({
  name,
  initials,
  avatarUrl,
  userId,
  totalItemCount,
  orderedVisitData,
  ticking,
  totalTime,
}: TeamRowProps) {
  const onTimeClick = () => {
    trackTimeEvent(ticking, totalTime);
  };
  const scrollElement = React.createRef<HTMLDivElement>();
  const [prevScroll, setPrevScroll] = useState(false);
  const [nextScroll, setNextScroll] = useState(false);

  useEffect(() => {
    const element = scrollElement.current as HTMLDivElement;
    if (element !== null) {
      updateScroll(element);
      window.addEventListener("resize", () => {
        updateScroll(element);
      });
    }
  });

  return (
    <>
      {totalItemCount > 0 && (
        <div className={styles.row}>
          <div className={styles.name}>
            <Tooltip message={name}>
              {name === "Unassigned" ? (
                <Icon name="userUnassigned" color="red" size="large" />
              ) : (
                <Avatar
                  size="base"
                  name={name}
                  initials={initials}
                  imageUrl={avatarUrl}
                />
              )}
            </Tooltip>
          </div>
          <div className={styles.time}>
            {name.toLowerCase() === "unassigned" ? (
              <div className="row collapse align-middle" />
            ) : (
              <a
                className={styles.timerLink}
                href={`/time_sheets?user_id=${userId}`}
                onClick={onTimeClick}
              >
                <div>
                  <div
                    className={`timer-icon  timer-icon--large ${
                      ticking ? "" : `is-stopped ${styles.timerStopped}`
                    }`}
                  />
                </div>
                <div>{totalTime}</div>
              </a>
            )}
          </div>
          <div className={styles.scheduleWrap}>
            {totalItemCount === 0 ? (
              <span>None</span>
            ) : (
              <>
                <button
                  className={classNames({
                    [styles.prevSchedule]: true,
                    [styles.canScroll]: prevScroll,
                  })}
                  aria-label="Previous appointments"
                  onClick={doScrollPrev}
                >
                  <Icon name="arrowLeft" color="white" />
                </button>
                <button
                  className={classNames({
                    [styles.nextSchedule]: true,
                    [styles.canScroll]: nextScroll,
                  })}
                  aria-label="Next appointments"
                  onClick={doScroll}
                >
                  <Icon name="arrowRight" color="white" />
                </button>
                <div
                  className={styles.scheduleScroll}
                  role="feed"
                  onScroll={handleScroll}
                  ref={scrollElement}
                >
                  <div className={styles.schedule}>
                    {orderedVisitData.map((visit, index) => {
                      const visitClass = classNames({
                        ["js-spinOnClick"]: true,
                        [styles.first]: index === 0,
                        [styles.last]: orderedVisitData.length === index + 1,
                        [styles.appointment]: true,
                        [styles.completeAppointment]: visit.completed,
                        [styles.activeAppointment]: visit.active,
                        [styles.incompleteAppointment]:
                          !visit.completed && !visit.active,
                      });

                      const handleVisitClick = () => {
                        trackAppointmentEvent(
                          visit.completed,
                          visit.active,
                          name,
                        );
                        showTodoDialog(visit.visitId);
                      };
                      return (
                        <button
                          key={visit.visitId}
                          className={visitClass}
                          onClick={handleVisitClick}
                        >
                          <div className={styles.appointmentDetails}>
                            <div className={styles.appointmentTime}>
                              {visit.startTime}
                            </div>
                            <div className={styles.appointmentTitle}>
                              {visit.title}
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );

  function handleScroll(event: UIEvent<HTMLDivElement>) {
    const element = event.target as HTMLDivElement;
    updateScroll(element);
  }

  function updateScroll(element: HTMLDivElement) {
    const child = element.firstChild as HTMLDivElement;
    const hasScrolling = child.scrollWidth > child.offsetWidth;
    const canScrollMore =
      element.scrollLeft + child.offsetWidth < child.scrollWidth - 16;

    if (element.scrollLeft > 16) {
      setPrevScroll(true);
    } else {
      setPrevScroll(false);
    }

    if (hasScrolling && canScrollMore) {
      setNextScroll(true);
    } else {
      setNextScroll(false);
    }
  }

  function doScrollPrev(event: MouseEvent<HTMLButtonElement>) {
    doScroll(event, "prev");
  }

  function doScroll(event: MouseEvent<HTMLButtonElement>, direction = "next") {
    const element = scrollElement.current;
    if (element !== null) {
      let distance =
        element.offsetWidth > 200 ? element.offsetWidth - 100 : 100;

      if (direction === "prev") {
        distance = distance * -1;
      }

      if (element.scrollBy !== undefined) {
        element.scrollBy({ left: distance, behavior: "smooth" });
      } else {
        element.scrollLeft = element.scrollLeft + distance;
      }
    }
  }

  function trackTimeEvent(running: boolean, time: string) {
    const eventName = "Clicked Time";
    const eventProperty = {
      source: "Home",
      timerRunning: running ? true : false,
      empty: time === "0:00" ? true : false,
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
  }

  function trackAppointmentEvent(
    completed: boolean,
    active: boolean,
    assignedName: string,
  ) {
    const eventName = "Clicked Appointment";
    const type = completed ? "Completed" : active ? "Active" : "Incomplete";
    const eventProperty = {
      source: "Home",
      visitType: type,
      assigned: assignedName === "Unassigned" ? false : true,
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
  }
}
