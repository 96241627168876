import { useCallback, useEffect, useState } from "react";
import type {
  UkKycRegistration,
  UkKycRegistrationEndUserTypes,
  UkKycRegistrationInquiry,
} from "~/utilities/API/graphql";
import { UkKycRegistrationStatuses } from "~/utilities/API/graphql";
import { useUkKycRegistrationCreateMutation } from "jobber/hooks/comms/useUkKycRegistrationCreateMutation";

export function useUkKycRegistrationCreateOrContinue(
  endUserType?: UkKycRegistrationEndUserTypes,
  currentRegistration?: UkKycRegistration,
) {
  const [inquiry, setInquiry] = useState<
    UkKycRegistrationInquiry | undefined
  >();
  const [registration, setRegistration] = useState<
    UkKycRegistration | undefined
  >();
  const [error, setError] = useState(false);

  const { ukKycRegistrationCreate, loading } =
    useUkKycRegistrationCreateMutation();

  const createOrcontinue = useCallback(async () => {
    if (
      currentRegistration &&
      currentRegistration.status === UkKycRegistrationStatuses.TWILIO_APPROVED
    ) {
      setRegistration(currentRegistration);
      return;
    }

    if (!endUserType) {
      return;
    }

    const results = await ukKycRegistrationCreate({
      input: {
        endUserType,
      },
    }).catch(() => {
      setError(true);
    });

    if (results?.userErrors && results?.userErrors?.length > 0) {
      setError(true);
    } else {
      setRegistration(results?.ukKycRegistration);
      setInquiry(results?.ukKycRegistrationInquiry);
    }
  }, [
    endUserType,
    currentRegistration,
    setError,
    setRegistration,
    setInquiry,
    ukKycRegistrationCreate,
  ]);

  useEffect(() => {
    if (endUserType) {
      createOrcontinue().catch(() => {
        setError(true);
      });
    }
  }, [
    setError,
    setRegistration,
    setInquiry,
    endUserType,
    currentRegistration,
    createOrcontinue,
  ]);

  return { inquiry, registration, loading, error };
}
