import { type ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import type { GraphQLError } from "graphql";
import type {
  CampaignSendTestEmailForDemoMutation,
  CampaignSendTestEmailForDemoMutationVariables,
  MutationErrors,
} from "~/utilities/API/graphql";
import { CAMPAIGN_SEND_TEST_FOR_DEMO } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export interface UserErrors {
  userErrors: MutationErrors[];
  mutationErrors?: readonly GraphQLError[];
}

interface UseSendTestCampaignForDemoReturn {
  loading: boolean;
  campaignSendTestEmailForDemo(
    input?: CampaignSendTestEmailForDemoMutationVariables,
  ): Promise<UserErrors>;
  data?: CampaignSendTestEmailForDemoMutation | null;
  error?: ApolloError;
}

export function useSendTestCampaignForDemo(): UseSendTestCampaignForDemoReturn {
  const [sendTestEmailForDemo, { loading, error, data }] = useMutation<
    CampaignSendTestEmailForDemoMutation,
    CampaignSendTestEmailForDemoMutationVariables
  >(CAMPAIGN_SEND_TEST_FOR_DEMO);

  const campaignSendTestEmailForDemo = useCallback(
    async (input: CampaignSendTestEmailForDemoMutationVariables) => {
      const mutationResult = await sendTestEmailForDemo({
        variables: input,
      }).catch(e => ({
        data: undefined,
        errors: e,
      }));

      const userErrors =
        mutationResult.data?.campaignSendTestEmailForDemo.userErrors ?? [];
      return {
        userErrors: userErrors,
        mutationErrors: mutationResult.errors,
      };
    },
    [sendTestEmailForDemo],
  );

  return {
    campaignSendTestEmailForDemo,
    data,
    loading,
    error,
  };
}
