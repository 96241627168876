import { useQuery } from "@apollo/client";
import type { VisitCommsTemplateDataQuery } from "~/utilities/API/graphql";
import { VISIT_COMMS_TEMPLATE_QUERY } from "./VisitCommsTemplate.graphql";
import { getSection, injectDefaultSchedule } from "./utils";

export function useVisitReminderTemplates(showOnboarding: boolean) {
  const { data, loading } = useQuery<VisitCommsTemplateDataQuery>(
    VISIT_COMMS_TEMPLATE_QUERY,
    { skip: !showOnboarding },
  );
  const section = data && getSection(injectDefaultSchedule(data));

  const templates = section && section.notification.templates;
  return {
    section,
    templates,
    loading,
  };
}
