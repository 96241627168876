import React, { useMemo } from "react";
import {
  // eslint-disable-next-line no-restricted-imports
  IntlProvider as BaseProvider,
  type MessageFormatElement,
  ReactIntlErrorCode,
} from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { messages as intlMessages } from "./all";
import { IgnoreIntlWarnings, SupportedLocale } from "./types";

export interface IntlProviderProps extends React.PropsWithChildren {
  readonly messages?: Record<string, MessageFormatElement[]>;
}

function fetchMessages(locale: SupportedLocale): Record<string, string> {
  switch (locale) {
    case "en-AU":
    case "en-CA":
    case "en-GB":
    case "en-US":
      return intlMessages[locale];
    default: {
      return intlMessages.en;
    }
  }
}

export const findLocale = (code?: string): SupportedLocale => {
  switch (code) {
    case "en-AU": {
      return SupportedLocale.EN_AU;
    }
    case "en-CA": {
      return SupportedLocale.EN_CA;
    }
    case "en-GB": {
      return SupportedLocale.EN_GB;
    }
    case "en-US": {
      return SupportedLocale.EN_US;
    }
    default: {
      return SupportedLocale.EN;
    }
  }
};

export const IntlProvider = ({ messages, children }: IntlProviderProps) => {
  const locale = findLocale(document.documentElement.lang);
  const messagesToUseForInternationalization = useMemo(
    () => messages || fetchMessages(locale),
    [locale, messages],
  );

  return (
    <BaseProvider
      key={locale}
      defaultLocale={SupportedLocale.EN}
      messages={messagesToUseForInternationalization}
      locale={locale}
      onError={onIntlError}
      onWarn={onIntlWarn}
      defaultRichTextElements={{
        highlight: chunks => (
          <Emphasis variation="highlight">{chunks}</Emphasis>
        ),
        b: chunks => <b>{chunks}</b>,
        em: chunks => <em>{chunks}</em>,
      }}
    >
      {children}
    </BaseProvider>
  );
};

function onIntlWarn(warn: string) {
  if (warn === IgnoreIntlWarnings.DEFAULT_RICH_TEXT_ELEMENTS) {
    return;
  }
}

function onIntlError(error: { code: string }) {
  if (error.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    return;
  }

  throw error;
}
