import React from "react";
import { Icon } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Text } from "@jobber/components/Text";
import { IntlProvider } from "@translations/IntlProvider";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import styles from "./CampaignsBreadCrumb.module.css";
import { messages } from "./messages";

interface CampaignsBreadCrumbProps {
  /**
   * Step currently being viewed. Must be in steps array.
   */
  currentStep: string;
  /**
   * @default ["Template", "Recipients", "Content", "Review"]`
   */
  onBack: () => void;
  steps?: string[];
  /**
   * Whether to display the Send button in the bread crumb
   */
  displaySendButton?: boolean;
  /**
   * The function to invoke for the send test button
   */
  onSendTestButton?: () => void;
  loading?: boolean;
}

export function CampaignsBreadCrumbWrapper(props: CampaignsBreadCrumbProps) {
  return (
    <IntlProvider>
      <CampaignsBreadCrumbInternal {...props} />
    </IntlProvider>
  );
}

function CampaignsBreadCrumbInternal({
  steps: _steps,
  currentStep,
  onBack,
  displaySendButton = false,
  onSendTestButton = () => {
    return false;
  },
  loading = false,
}: CampaignsBreadCrumbProps): JSX.Element {
  const { mediumAndUp } = useBreakpoints();
  const { breadCrumbSteps } = useCampaignUpsellSplit();

  const steps = _steps ?? breadCrumbSteps;

  return (
    <>
      {!mediumAndUp ? (
        <CampaignsMobileBreadCrumbWrapper
          {...{
            steps,
            currentStep,
            displaySendButton,
            onBack,
            onSendTestButton,
            loading,
          }}
        />
      ) : (
        <DesktopCampaignsBreadCrumbWrapper
          {...{
            steps,
            currentStep,
            displaySendButton,
            onBack,
            onSendTestButton,
            loading,
          }}
        />
      )}
    </>
  );
}

function CampaignsMobileBreadCrumbWrapper({
  currentStep,
  steps,
  displaySendButton,
  onBack,
  onSendTestButton,
  loading,
}: Required<CampaignsBreadCrumbProps>): JSX.Element {
  const { formatMessage } = useIntl();
  const numberOfSteps = steps.length;
  const stepIndex = steps.findIndex(step => step === currentStep) + 1;

  return (
    <div className={styles.breadcrumbMobileContainer}>
      <div className={styles.headingBox}>
        <div className={styles.mobileContent}>
          <div className={styles.backButton}>
            <Button
              variation="subtle"
              type="tertiary"
              icon="arrowLeft"
              ariaLabel="arrowLeft"
              onClick={onBack}
            />
          </div>
          <div className={styles.mobileContentText}>
            <Text size={"large"}>
              Step {stepIndex} of {numberOfSteps}
            </Text>
            <Emphasis variation={"bold"}>
              <Heading level={3}>{currentStep}</Heading>
            </Emphasis>
          </div>
          <div className={styles.mobileContentButton}>
            {displaySendButton && (
              <Button
                label={formatMessage(messages.sendATestLabel)}
                onClick={onSendTestButton}
                type={"secondary"}
                variation={"work"}
                size={"base"}
                loading={loading}
              />
            )}
          </div>
        </div>
        <div className={styles.mobileProgressBar}>
          <ProgressBar
            currentStep={stepIndex}
            totalSteps={numberOfSteps}
            size={"smaller"}
          />
        </div>
      </div>
    </div>
  );
}

function DesktopCampaignsBreadCrumbWrapper({
  currentStep,
  steps,
  displaySendButton,
  onSendTestButton,
  loading,
}: Required<CampaignsBreadCrumbProps>): JSX.Element {
  const { formatMessage } = useIntl();
  const renderedSteps = steps.map((step, index) => {
    const isCurrentStep = step === currentStep;
    const addIcon = index !== steps.length - 1;
    return (
      <div key={index} className={styles.parentItem}>
        <div className={styles.breadCrumb}>
          <Heading level={4}>
            {isCurrentStep ? (
              step
            ) : (
              <span className={styles.inactiveBreadCrumb}>{step}</span>
            )}
          </Heading>
        </div>
        {addIcon && <Icon name={"arrowRight"} />}
      </div>
    );
  });

  return (
    <div className={styles.breadcrumbContainer}>
      <div className={styles.navigationContainer}>{renderedSteps}</div>
      <div className={styles.buttonPlaceholder}>
        {displaySendButton && (
          <Button
            label={formatMessage(messages.sendATestLabel)}
            onClick={onSendTestButton}
            type={"secondary"}
            variation={"work"}
            size={"base"}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}
