import { defineMessages } from "react-intl";

export const messages = defineMessages({
  receivablesHeading: {
    id: "managedAccount.financialInsightsSection.receivables.heading",
    defaultMessage: "Receivables",
    description: "Heading for receivables section",
  },
  receivablesClientsOweYou: {
    id: "managedAccount.financialInsightsSection.receivables.clientsOweYou",
    defaultMessage:
      "{numberOfClientsWithBalances, plural, one {# client owes you} other {# clients owe you}}",
    description: "Number of clients owe you",
  },
  averageDaysToGettingPaidHeading: {
    id: "managedAccount.financialInsightsSection.receivables.averageDaysToGettingPaidHeading",
    defaultMessage: "Average time to getting paid",
    description: "The average days it takes the user to get paid heading",
  },
  averageDaysToGettingPaid: {
    id: "managedAccount.financialInsightsSection.receivables.averageDaysToGettingPaid",
    defaultMessage:
      "{averageDaysToGettingPaid, plural, one {# day} other {# days}}",
    description: "The average days it takes the user to get paid",
  },
  averageDaysToGettingPaidToolTip: {
    id: "managedAccount.financialInsightsSection.receivables.averageDaysToGettingPaidToolTip",
    defaultMessage:
      "Average time it took to get an invoice from issued to paid in the last 7 days.",
    description:
      "The tooltip for the average days it takes the user to get paid",
  },
  percentageOfUpcomingJobsOnAutopayHeading: {
    id: "managedAccount.financialInsightsSection.receivables.percentageOfUpcomingJobsOnAutopayHeading",
    defaultMessage: "Upcoming jobs on auto pay",
    description: "The percentage of upcoming jobs on autopay heading",
  },
  percentageOfUpcomingJobsOnAutopay: {
    id: "managedAccount.financialInsightsSection.receivables.percentageOfUpcomingJobsOnAutopay",
    defaultMessage: "{percentageOfUpcomingJobsOnAutopay}%",
    description: "The percentage of upcoming jobs on autopay",
  },
  percentageOfUpcomingJobsOnAutopayToolTip: {
    id: "managedAccount.financialInsightsSection.receivables.percentageOfUpcomingJobsOnAutopayToolTip",
    defaultMessage:
      "Jobs in the next 7 days that will be automatically paid with a card or bank on file.",
    description: "The tooltip for the percentage of upcoming jobs on autopay",
  },
  percentageOfUpcomingJobsOnAutopayUrlLabel: {
    id: "managedAccount.financialInsightsSection.receivables.percentageOfUpcomingJobsOnAutopayUrlLabel",
    defaultMessage: "Learn how to turn on auto pay",
    description: "The link to FAQ article for turning on auto pay",
  },
  ageOfReceivablesReport: {
    id: "managedAccount.financialInsightsSection.receivables.ageOfReceivablesReport",
    defaultMessage: "View Age of Receivables",
    description: "The button to redirect to the age of receivables report",
  },
});
