import { defineMessages } from "react-intl";

export const messages = defineMessages({
  mainHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.MainHeading",
    defaultMessage: "Win more work with request forms",
    description: "Main heading for requests landing page",
  },
  subHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.SubHeading",
    defaultMessage:
      "When a client submits a new work request, Jobber sends you an email, activity feed notification and a push notification. You can review any new requests in Jobber, and in just two clicks turn them into a quote or job.",
    description: "Sub heading for requests landing page",
  },
  firstImageAlt: {
    id: "RequestsLandingPage.RequestsLandingPage.FirstImageAlt",
    defaultMessage:
      "A job checklist displayed on the Jobber app and on the client's job order",
    description: "Alt text for first image on requests landing page",
  },
  secondImageAlt: {
    id: "RequestsLandingPage.RequestsLandingPage.SecondImageAlt",
    defaultMessage:
      "A visit displaying notes and attachments on the Jobber app",
    description: "Alt text for second image on requests landing page",
  },
  impressYourCustomersSubFeatureHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.ImpressYourCustomersSubFeatureHeading",
    defaultMessage: "Impress your customers",
    description: "Heading for impress your customers sub feature",
  },
  impressYourCustomersSubFeatureDescription: {
    id: "RequestsLandingPage.RequestsLandingPage.ImpressYourCustomersSubFeatureDescription",
    defaultMessage:
      "Reduce time spent on back and forth communications and meet customer expectations of professional, convenient experiences.",
    description: "Description for impress your customers sub feature",
  },
  getJobsQuotedFasterSubFeatureHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.GetJobsQuotedFasterSubFeatureHeading",
    defaultMessage: "Get jobs quoted faster",
    description: "Heading for get jobs quoted faster sub feature",
  },
  getJobsQuotedFasterSubFeatureDescription: {
    id: "RequestsLandingPage.RequestsLandingPage.GetJobsQuotedFasterSubFeatureDescription",
    defaultMessage:
      "Create quotes 70% faster when customers submit their request online.",
    description: "Description for get jobs quoted faster sub feature",
  },
  featuresSectionHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.FeaturesSectionHeading",
    defaultMessage: "Learn about other {plan} features",
    description: "Heading for features section",
  },
  jobsFeatureImageAlt: {
    id: "RequestsLandingPage.RequestsLandingPage.JobsFeatureImageAlt",
    defaultMessage: "Jobs feature page on Jobber",
    description: "Alt text for jobs feature image",
  },
  schedulingFeatureImageAlt: {
    id: "RequestsLandingPage.RequestsLandingPage.SchedulingFeatureImageAlt",
    defaultMessage: "Scheduling feature page on Jobber",
    description: "Alt text for scheduling feature image",
  },
  jobsFeatureHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.JobsFeatureHeading",
    defaultMessage: "Keep track of important job details in one place",
    description: "Heading for jobs feature",
  },
  jobsFeatureDescription: {
    id: "RequestsLandingPage.RequestsLandingPage.JobsFeatureDescription",
    defaultMessage:
      "Move work forward by quickly turning a work request or quote into a job, or book a new job on the fly.",
    description: "Description for jobs feature",
  },
  schedulingFeatureHeading: {
    id: "RequestsLandingPage.RequestsLandingPage.SchedulingFeatureHeading",
    defaultMessage: "Scheduling software built for the field",
    description: "Heading for scheduling feature",
  },
  schedulingFeatureDescription: {
    id: "RequestsLandingPage.RequestsLandingPage.SchedulingFeatureDescription",
    defaultMessage:
      "Schedule field service jobs faster, avoid booking mistakes, and route visits efficiently - all while keeping your crew and customers on the same page.",
    description: "Description for scheduling feature",
  },
});
