import type { QueryTuple } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import classNames from "classnames";
import { ConversationsNotFoundEmptyState } from "jobber/chat/components/ChatDrawer/ChatList/EmptyState/ConversationsNotFoundEmptyState";
import { RecipientList } from "jobber/chat/components/MessageComposer/components/SearchAndSelect/components/RecipientList";
import { RecipientSearch } from "jobber/chat/components/MessageComposer/components/SearchAndSelect/components/RecipientSearch";
import { SearchAndSelectEmptyState } from "jobber/chat/components/MessageComposer/components/SearchAndSelect/components/SearchAndSelectEmptyState";
import type {
  ClientPhoneNumberEdge,
  ClientPhonesQuery,
  ClientPhonesQueryVariables,
} from "~/utilities/API/graphql";
import type { SelectedRecipientType } from "jobber/chat/types";
import styles from "./SearchAndSelect.module.css";

interface SearchAndSelectProps {
  fetchRecipients: QueryTuple<ClientPhonesQuery, ClientPhonesQueryVariables>;
  selectRecipient: Dispatch<SetStateAction<SelectedRecipientType>>;
  canCreateConversation(recipient: string): boolean;
  searchTermOverride?: string;
}

export function SearchAndSelect({
  fetchRecipients,
  selectRecipient,
  canCreateConversation,
  searchTermOverride,
}: SearchAndSelectProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [recipients, setRecipients] = useState<
    Partial<ClientPhoneNumberEdge>[]
  >([]);
  const [getClientSearchResults, { data, loading, error }] = fetchRecipients;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getClientSearchResults({
      variables: { searchTerm: searchTermOverride ?? searchTerm },
    });
  }, [searchTerm]);

  useEffect(() => {
    setRecipients(
      (data?.clientPhones.edges as Partial<ClientPhoneNumberEdge>[]) || [],
    );
  }, [data]);

  function onSearchTermChange(value: string): void {
    setSearchTerm(value);
  }

  function handleSendToClick() {
    selectRecipient({ name: undefined, contactInfo: searchTerm });
  }
  const showSelectButton = useMemo(
    () => canCreateConversation(searchTerm),
    [searchTerm],
  );

  const hasNoSearchResults = !recipients?.length && !loading && searchTerm;
  return (
    <>
      <RecipientSearch
        onSearchTermChange={onSearchTermChange}
        searchTermOverride={searchTermOverride}
      />
      {!error && !loading && showSelectButton && (
        <div className={classNames(styles.createButtonContainer)}>
          <Button
            label={`Send To '${searchTerm}'`}
            type="primary"
            fullWidth={true}
            onClick={handleSendToClick}
          />
        </div>
      )}
      <RecipientList
        recipients={recipients}
        onSelectRecipient={selectRecipient}
      >
        {error && <ConversationsNotFoundEmptyState />}
        {!error && hasNoSearchResults && (
          <SearchAndSelectEmptyState searchTerm={searchTerm} />
        )}
        {loading && (
          <div className={"row collapse align-center u-paddingTopLarger"}>
            <Spinner inline={true} />
          </div>
        )}
      </RecipientList>
    </>
  );
}
