interface FindImgBackgroundConfig {
  container: HTMLElement;
  selector: string;
}

const findImgSrc = ({ container, selector }: FindImgBackgroundConfig) => {
  const img = container.querySelector(selector) as HTMLImageElement;
  if (!img) {
    throw new Error(`${selector} not found in rendered DOM tree`);
  }

  return img.src || "";
};

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default findImgSrc;
