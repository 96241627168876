import { useEffect, useState } from "react";
import type { UsersQueryType } from "./types";

export function useSelectedUserName(
  users: UsersQueryType[] | undefined,
  assignedTo: string | undefined,
) {
  const [selectedUserName, setSelectedUserName] = useState<string>("");

  useEffect(() => {
    const userData = users?.filter(user => user.id === assignedTo)[0];
    setSelectedUserName(userData?.name.full || "");
  }, [users, assignedTo]);

  return { selectedUserName };
}
