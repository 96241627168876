import React from "react";
import classnames from "classnames";
import { Text } from "@jobber/components/Text";
import { FormatTime } from "@jobber/components/FormatTime";
import { EmailContent } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/EmailContent";
import { TextMessageContent } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/TextMessageContent";
import {
  type MessageDataFragment,
  MessageDirectionEnum,
} from "~/utilities/API/graphql";
import styles from "./MessageBubble.module.css";
import { SystemMessageContent } from "./components/SystemMessageContent";

interface MessageBubbleProps {
  readonly message: MessageDataFragment;
}

export function MessageBubble({ message }: MessageBubbleProps) {
  const wrapperClassNames = classnames(styles.bubbleWrapper, {
    [styles.inbound]: message.direction === MessageDirectionEnum.INBOUND,
    [styles.outbound]:
      message.direction === MessageDirectionEnum.OUTBOUND &&
      message.commType !== "CONVERSATION_REASSIGNED",
  });
  return (
    <div className={wrapperClassNames}>
      {renderMessageContent()}
      {renderMetaData()}
    </div>
  );

  function renderMessageContent() {
    if (message.__typename === "TextMessage") {
      if (message.commType === "CONVERSATION_REASSIGNED") {
        return <SystemMessageContent message={message} />;
      }

      return <TextMessageContent message={message} />;
    }

    if (message.__typename === "EmailMessage") {
      return <EmailContent message={message} />;
    }
  }

  function renderMetaData() {
    if (message.commType === "CONVERSATION_REASSIGNED") {
      return;
    }

    return (
      <span data-testid="hovertime" className={styles.hoverTime}>
        <Text variation={"subdued"} size="small">
          <FormatTime time={message.timestamp} />
        </Text>
      </span>
    );
  }
}
