import type { BankAccountInformation } from "jobber/managed_accounts/ManualConnect/bankAccountInformationReducer";
import type { SuccessMetadata } from "./PlaidConnect.d";

interface PlaidSuccessResponse {
  token: string;
  metadata: SuccessMetadata;
}

export enum BankAccountProgress {
  NotConnected = "not_connected",
  Connected = "connected",
}

export interface SuccessBank {
  bankName: string;
  bankLast4: string;
  payoutsToJobberMoney: boolean;
  payoutScheduleInDays: number;
}

interface OnSuccessAction {
  type: "onSuccess";
  data: {
    successMessage: string;
    bank: SuccessBank;
  };
}

interface OnHandlePlaidResponseAction {
  type: "onHandlePlaidResponse";
  data: PlaidSuccessResponse;
}

interface OnPlaidErrorAction {
  type: "onPlaidError";
  data: { plaidErrorMessage: string };
}

interface OnManualConnectErrorAction {
  type: "onManualConnectError";
  data: { manualConnectErrorMessage?: string };
}

interface OnManualConnectStartAction {
  type: "onManualConnectStart";
  data: { bankAccountInformation: BankAccountInformation };
}

interface OnBankAccountInformationChangedAction {
  type: "onBankAccountInformationChanged";
  data: {
    bankAccountInformation: BankAccountInformation;
    usingIBAN: boolean;
    requiresAuthForm: boolean;
  };
}

export type BankAccountReducerAction =
  | OnSuccessAction
  | OnHandlePlaidResponseAction
  | OnPlaidErrorAction
  | OnManualConnectErrorAction
  | OnManualConnectStartAction
  | OnBankAccountInformationChangedAction;

interface BankAccountState {
  actionType: BankAccountReducerAction["type"] | "INIT";
  plaidErrorMessage?: string;
  successMessage?: string;
  successBank?: SuccessBank;
  plaidSuccessResponse?: PlaidSuccessResponse;
  buttonText: "Connect" | "Connecting" | "Connected";
  manualConnectErrorMessage?: string;
  isManualConnectLoading: boolean;
  accountDetailsComplete: boolean;
}

export type BaseBankAccountState = BankAccountState;

export const bankAccountInitialState = (
  payoutsToJobberMoney: boolean,
  payoutScheduleInDays: number,
): BankAccountState => {
  return {
    actionType: "INIT",
    plaidErrorMessage: undefined,
    plaidSuccessResponse: undefined,
    successMessage: undefined,
    successBank: {
      payoutsToJobberMoney,
      bankName: "",
      bankLast4: "",
      payoutScheduleInDays,
    },
    buttonText: "Connect",
    manualConnectErrorMessage: undefined,
    isManualConnectLoading: false,
    accountDetailsComplete: false,
  };
};

export function bankAccountReducer(
  prevState: BankAccountState,
  action: BankAccountReducerAction,
): BankAccountState {
  switch (action.type) {
    case "onSuccess":
      return {
        ...prevState,
        actionType: action.type,
        plaidErrorMessage: undefined,
        isManualConnectLoading: false,
        successMessage: action.data.successMessage,
        successBank: action.data.bank,
        buttonText: "Connected",
      };
    case "onHandlePlaidResponse":
      return {
        ...prevState,
        actionType: action.type,
        plaidSuccessResponse: action.data,
        plaidErrorMessage: undefined,
        successMessage: undefined,
        successBank: undefined,
        buttonText: "Connecting",
      };
    case "onPlaidError":
      return {
        ...prevState,
        actionType: action.type,
        buttonText: "Connect",
        plaidErrorMessage: action.data.plaidErrorMessage,
      };
    case "onManualConnectError":
      return {
        ...prevState,
        actionType: action.type,
        isManualConnectLoading: false,
        buttonText: "Connect",
        manualConnectErrorMessage: action.data.manualConnectErrorMessage,
      };
    case "onManualConnectStart":
      return {
        ...prevState,
        actionType: action.type,
        manualConnectErrorMessage: undefined,
        isManualConnectLoading: true,
        buttonText: "Connecting",
      };
    case "onBankAccountInformationChanged":
      /* eslint-disable no-case-declarations */
      let completed: boolean =
        action.data.bankAccountInformation.accountHolder.length > 0 &&
        action.data.bankAccountInformation.accountNumber.length > 0;

      if (!action.data.usingIBAN) {
        const routingNumberCompleted =
          action.data.bankAccountInformation.routingNumber &&
          action.data.bankAccountInformation.routingNumber.length > 0;

        completed = (completed && routingNumberCompleted) as boolean;
      }

      if (action.data.requiresAuthForm) {
        const debitAgreementForm =
          action.data.bankAccountInformation.debitAgreementForm;
        const bankAddress = debitAgreementForm.bankAddress;

        const debitAgreementFormCompleted =
          fieldIsComplete(debitAgreementForm.bankName) &&
          fieldIsComplete(bankAddress.street1) &&
          fieldIsComplete(bankAddress.city) &&
          fieldIsComplete(bankAddress.pc) &&
          debitAgreementForm.debitAgreementShownAndAccepted;

        completed = (completed && debitAgreementFormCompleted) as boolean;
      }

      return {
        ...prevState,
        actionType: action.type,
        accountDetailsComplete: !!completed,
      };
    default:
      return prevState;
  }

  function fieldIsComplete(field: string | undefined) {
    return field && field?.length > 0;
  }
}
