import React, { useContext } from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { AddonUpsellContext } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import {
  trackCtaClicked,
  trackCtaDismissed,
} from "jobber/billing/utils/trackInteractions";
import { AddonPurchaseModal } from "legacy/jobber/billing/features/AddonPurchaseModal";
import type { PurchaseModalButtonProps } from "legacy/jobber/billing/features/AddonPurchaseModal/AddonPurchaseModal";
import { ChatAboutAddonsButton } from "./components/ChatAboutAddonsButton/ChatAboutAddonsButton";
import { RedirectToPricingPageButton } from "./components/RedirectToPricingButton/RedirectToPricingPageButton";
import { messages } from "./messages";

interface LandingPageCtaButtonProps {
  loading: boolean;
  addonSetIdentifier?: string;
  addonSetName?: string;
  recurlyPublicKey: string;
  inTrial: boolean;
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  onPurchaseSuccess?: () => void;
  purchaseButtonProps?: PurchaseModalButtonProps;
  isMarketingSource?: boolean;
}

export function LandingPageCtaButton(props: LandingPageCtaButtonProps) {
  const {
    loading,
    addonSetIdentifier,
    addonSetName,
    recurlyPublicKey,
    inTrial,
    isMobileBilled,
    salesforceTrackingId,
    onPurchaseSuccess,
    purchaseButtonProps,
    isMarketingSource,
  } = props;
  const addonUpSell = useContext(AddonUpsellContext);
  const { formatMessage } = useIntl();

  if (inTrial) {
    return (
      <RedirectToPricingPageButton
        addonSetName={addonSetName}
        isMarketingSource={isMarketingSource}
      />
    );
  }

  if (addonSetIdentifier === undefined) {
    return (
      <Button
        label={formatMessage(messages.loading)}
        disabled
        loading={true}
        ariaLabel={formatMessage(messages.loading)}
      />
    );
  }

  if (isMobileBilled) {
    return (
      <ChatAboutAddonsButton
        addonSetIdentifier={addonSetIdentifier}
        loading={loading}
      />
    );
  }

  return (
    <AddonPurchaseModal
      recurlyPublicKey={recurlyPublicKey}
      addonSetIdentifier={addonSetIdentifier}
      addonUpsell={addonUpSell}
      buttonProps={{
        buttonSize: "base",
        ...purchaseButtonProps,
      }}
      onOpen={() => {
        trackCtaClicked({
          name: `clicked_add_to_plan_for_${addonSetIdentifier}`,
          salesforceId: salesforceTrackingId,
        });
      }}
      onDismiss={() => {
        trackCtaDismissed({
          name: `${addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: salesforceTrackingId,
        });
      }}
      successAction={() => {
        trackCtaClicked({
          name: `${addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: salesforceTrackingId,
        });

        if (onPurchaseSuccess) {
          onPurchaseSuccess();
        }
      }}
    />
  );
}
