import React from "react";
import { HelpItem, type HelpItemProps } from "./HelpItem";

interface HelpListProps {
  items: HelpItemProps[];
}
export function HelpList({ items }: HelpListProps) {
  return (
    <ul>
      {items.map(item => (
        <HelpItem
          key={item.label}
          label={item.label}
          url={item.url}
          external={item.external}
        />
      ))}
    </ul>
  );
}
