import React from "react";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import type { DepositResult } from "jobber/managed_accounts/WithdrawFundsDialog";
import { FlashBanner } from "./FlashBanner";

export function DepositResultBanner(props: {
  depositResult: DepositResult;
  currencyUnit: string;
}) {
  const { depositResult, currencyUnit } = props;
  const formattedAmount = currencyWithUnit(
    depositResult.amount,
    currencyUnit,
  ).format();

  return (
    <FlashBanner type="success">
      Your money is on its way! Instantly deposited {formattedAmount} to card
      ending in •••• {depositResult.cardLast4}
    </FlashBanner>
  );
}
