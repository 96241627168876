import { defineMessages } from "react-intl";

export const messages = defineMessages({
  openRateTitle: {
    id: "campaigns.metricsCard.openRateTitle",
    defaultMessage: "Open rate",
    description: "Open rate title",
  },
  clickRateTitle: {
    id: "campaigns.metricsCard.clickRateTitle",
    defaultMessage: "Click rate",
    description: "Click rate title",
  },
  revenueTitle: {
    id: "campaigns.metricsCard.revenueTitle",
    defaultMessage: "Job revenue",
    description: "Revenue title",
  },
  unsubscribeTitle: {
    id: "campaigns.metricsCard.unsubscribeTitle",
    defaultMessage: "Unsubscribe rate",
    description: "Unsubscribe title",
  },
  openRateToolTip: {
    id: "campaigns.metricsCard.openRateToolTip",
    defaultMessage: "The percentage of recipients who opened your email",
    description: "Open rate tool tip",
  },
  clickRateToolTip: {
    id: "campaigns.metricsCard.clickRateToolTip",
    defaultMessage:
      "The percentage of recipients who clicked on a link in your email",
    description: "Click rate tool tip",
  },
  revenueToolTip: {
    id: "campaigns.metricsCard.revenueToolTip",
    defaultMessage:
      "The total value of jobs created for recipients within 30 days of receiving this email",
    description: "Revenue tool tip",
  },
  unsubscribeToolTip: {
    id: "campaigns.metricsCard.unsubscribeToolTip",
    defaultMessage:
      "The percentage of recipients who choose to stop receiving marketing emails by clicking on the unsubscribe link from this campaign",
    description: "Unsubscribe tool tip",
  },
});
