import React, { createRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { OTPInput, type OTPInputRef } from "jobber/OTP/components/OTPInput";
import { OTPText } from "jobber/OTP/components/OTPText";

export interface OTPInputModalProps {
  readonly open: boolean;
  readonly phoneLastDigits: string;
  readonly channel: string;
  onSuccess(): void;
  onRequestClose(): void;
}

export function OTPInputModal({
  open,
  onRequestClose,
  phoneLastDigits,
  channel,
  ...inputProps
}: OTPInputModalProps) {
  const inputRef = createRef<OTPInputRef>();
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      title="Check your phone"
      open={open}
      primaryAction={{
        label: "Enter Code",
        loading: submitting,
        disabled: disabled || submitting,
        onClick: handleSubmit,
      }}
      tertiaryAction={{
        label: "Resend Code",
        variation: "subtle",
        type: "primary",
        disabled: submitting,
        onClick: handleResend,
      }}
      onRequestClose={onRequestClose}
    >
      <OTPInput
        {...inputProps}
        ref={inputRef}
        setDisabled={setDisabled}
        setSubmitting={setSubmitting}
      >
        <OTPText channel={channel} phoneLastDigits={phoneLastDigits} />
      </OTPInput>
    </Modal>
  );

  function handleSubmit() {
    setSubmitting(true);
    if (inputRef.current) inputRef.current.submit();
  }

  function handleResend() {
    if (inputRef?.current?.sendCode) {
      inputRef.current.sendCode();
    }
  }
}
