import type { Theme } from "@jobber/components";
import { COOKIE_NAME } from "~/jobber/settings/darkModeToggle/constants";
import type { SetupWizardData } from "../../types";
import { safelyExtractAnswer } from "../../utils";

export const determineThemeToDisplay = (wizardData: SetupWizardData): Theme => {
  const selectedTheme = safelyExtractAnswer(
    "theme",
    wizardData.questionsAndAnswers,
  );
  if (selectedTheme) {
    return selectedTheme === "dark" ? "dark" : "light";
  }

  const themeCookieValue = retrieveThemeFromCookie();
  if (themeCookieValue) {
    return themeCookieValue === "dark" ? "dark" : "light";
  }

  return "light";
};

const retrieveThemeFromCookie = (): Theme | null => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        COOKIE_NAME.replace(/([.$?*|{}()[]\\\/+^])/g, "\\$1") +
        "=([^;]*)",
    ),
  );
  if (!matches) {
    return null;
  }
  return matches[1] === "dark" ? "dark" : "light";
};
