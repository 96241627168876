import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recommended: {
    id: "campaigns.selectableCard.recommended",
    defaultMessage: "Recommended",
    description: "recommended text",
  },
  comingSoon: {
    id: "campaigns.selectableCard.comingSoon",
    defaultMessage: "Coming soon",
    description: "coming soon text",
  },
  referral: {
    id: "campaigns.selectableCard.referral",
    defaultMessage: "Free with Referrals",
    description: "referral text",
  },
});
