import type { SchedulingAssistantAvailabilityQuery } from "~/utilities/API/graphql";
import type { NonEmptyArray } from "utilities/nonEmptyArray";
import type { Month } from "../yearMonthDay";

declare const brand: unique symbol;

export type CacheKey = string & {
  [brand]: "CacheKey";
};

export type AvailabilitySlot =
  SchedulingAssistantAvailabilityQuery["schedulingAvailability"]["schedulingRecommendations"][number];

export type AvailabilityCache = Readonly<
  Record<CacheKey, NonEmptyArray<AvailabilitySlot> | undefined>
>;

export function mkCacheKey(year: number, month: Month, day?: Day) {
  return `${year}:${month}:${day}` as CacheKey;
}
