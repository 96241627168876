import { Modal } from "@jobber/components/Modal";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import React from "react";
import { showToast } from "@jobber/components/Toast";
import type { MutationErrors } from "~/utilities/API/graphql";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
} from "~/utilities/API/graphql";
import { messages } from "./messages";
import { useUpdateDefaultPaymentPreference } from "../useUpdateDefaultPaymentPreference";
import { messages as ToastMessage } from "../messages";

export interface DefaultACHModalProps {
  isOpen: boolean;
  loading?: boolean;
  onRequestClose: () => void;
  onError(error: Error): void;
  setUpdateDefaultPreference: React.Dispatch<
    React.SetStateAction<{
      preference: string;
      achEnabled: boolean;
    }>
  >;
}

const genericError = new Error("Could not update settings");

export function DefaultACHModal(props: DefaultACHModalProps) {
  const { isOpen, onRequestClose, onError } = props;
  const { formatMessage } = useIntl();
  const { handleUpdateSettings, loading } = useUpdateDefaultPaymentPreference();

  return (
    <Modal
      size="small"
      title={formatMessage(messages.acceptACHModalTitle)}
      open={isOpen}
      dismissible={false}
      primaryAction={{
        label: formatMessage(messages.setNewDefaultPaymentOptions),
        onClick: onConfirmNewDefault,
        loading: loading,
      }}
      secondaryAction={{
        label: formatMessage(messages.keepExistingPaymentOptions),
        onClick: onConfirmKeepExisting,
        loading: loading,
      }}
    >
      <Content>
        <>
          <Text>{formatMessage(messages.acceptACHModalBody)}</Text>
          <Text>{formatMessage(messages.acceptACHModalRecommendation)}</Text>
        </>
      </Content>
    </Modal>
  );

  async function onConfirmNewDefault() {
    await updateDefaultPreference(
      JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
    );
    onRequestClose();
  }

  async function onConfirmKeepExisting() {
    await updateDefaultPreference(
      JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
    );
    onRequestClose();
  }

  async function updateDefaultPreference(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    try {
      const { data } = await handleUpdateSettings({
        objectType: InvoiceOrQuote.INVOICE_AND_QUOTE,
        preference: value,
      });
      const errors = data?.jobberPaymentsUpdateDefaultPaymentPreference
        ?.userErrors as MutationErrors[];

      if (!data) {
        onError?.(genericError);
      }
      if (
        errors.length > 0 &&
        data?.jobberPaymentsUpdateDefaultPaymentPreference.success === false
      ) {
        props.onError?.(new Error(errors[0].message) || genericError);
      } else {
        props.setUpdateDefaultPreference({
          preference: value,
          achEnabled: true,
        });
        if (
          value === JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD
        ) {
          showToast({
            message: formatMessage(
              ToastMessage.paymentOptionChangeToastMessage,
            ),
          });
        }
      }
    } catch (error) {
      props.onError?.(error as Error);
    }
  }
}
