import React, { createContext, useState } from "react";
import { Source } from "./types";

export enum DrawerView {
  ManageSettings = "manageSettings",
  MessageSettings = "messageSettings",
  FollowUpMessageSettings1 = "followUpMessageSettings1",
  FollowUpMessageSettings2 = "followUpMessageSettings2",
}

export interface ReviewsSettingsDrawer {
  currentView: DrawerView | null;
  showInsights: boolean;
  drawerActions?: {
    goTo: (drawer: DrawerView, source?: Source) => void;
    closeSideDrawer: () => void;
  };
  unsavedChangesModalActions?: {
    open: () => void;
    close: () => void;
    isOpen: boolean;
  };
  form?: {
    isDirty: boolean;
    handleFormIsDirty: () => void;
  };
}

export const ReviewsSettingsDrawerContext =
  createContext<ReviewsSettingsDrawer>({
    currentView: null,
    showInsights: false,
  });

export function ReviewsSettingsDrawerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentView, setInitialView] = useState<DrawerView | null>(null);
  const [showInsights, setShowInsights] = useState<boolean>(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const drawerActions = {
    goTo: (drawer: DrawerView, source?: Source) => {
      setInitialView(drawer);
      if (source === Source.insights) {
        setShowInsights(true);
      } else {
        setShowInsights(false);
      }
    },
    closeSideDrawer: () => {
      setInitialView(null);
      setShowInsights(false);
    },
  };

  const unsavedChangesModalActions = {
    open: () => {
      setIsUnsavedChangesModalOpen(true);
    },
    close: () => {
      setIsUnsavedChangesModalOpen(false);
      setIsDirty(false);
    },
    isOpen: isUnsavedChangesModalOpen,
  };

  const form = {
    isDirty,
    handleFormIsDirty: () => {
      setIsDirty(true);
    },
  };

  return (
    <ReviewsSettingsDrawerContext.Provider
      value={{
        currentView,
        showInsights,
        drawerActions,
        unsavedChangesModalActions,
        form,
      }}
    >
      {children}
    </ReviewsSettingsDrawerContext.Provider>
  );
}
