import { fireEvent, screen, within } from "@testing-library/react";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { messages as errorBannerMessages } from "jobber/campaigns/components/ErrorBanner/messages";
import { messages as breadcrumbMessages } from "jobber/campaigns/components/Breadcrumbs/messages";
import { messages as sendATestModalMessages } from "jobber/campaigns/views/CampaignsContentPage/components/SendTestEmailModal/messages";
import { messages as headerImageUploadMessages } from "jobber/campaigns/views/CampaignsContentPage/components/HeaderImageUpload/messages";
import { messages } from "./messages";
// selectors
export function getCampaignContentPageTitle() {
  return screen.getByText(messages.campaignContentPageTitle.defaultMessage);
}

export function getContentDivByTestId(testId: string): HTMLElement {
  return screen.getByTestId(testId);
}

export function getEmailBody(testId: string, text: string) {
  return within(getContentDivByTestId(testId)).getByText(text);
}

export function getBackButton() {
  return screen.getByText(bottomBarMessages.backButton.defaultMessage);
}

export function getSubjectLine() {
  return screen.getByDisplayValue("Subject");
}

export function getSendTestEmailModal() {
  return screen.getByText(sendATestModalMessages.title.defaultMessage);
}

export function getSendTestEmailConfirmationButton() {
  return screen.getByText(
    sendATestModalMessages.sendEmailButton.defaultMessage,
  );
}

export function getSaveDraftButton() {
  return screen.getByText(bottomBarMessages.saveDraft.defaultMessage);
}

export function getSendATestModalErrorMessage() {
  return screen.getByText(sendATestModalMessages.errorMessage.defaultMessage);
}

export function getErrorBanner() {
  return screen.getByText(errorBannerMessages.genericError.defaultMessage);
}

export function getReviewCampaignButton() {
  return screen.getByText(bottomBarMessages.reviewCampaign.defaultMessage);
}

export function getHeaderImageLabel() {
  return screen.getByText(
    headerImageUploadMessages.uploadHeading.defaultMessage,
  );
}

export function getSubjectLabel() {
  return screen.getByLabelText(messages.campaignSubjectLine.defaultMessage);
}

export function getExitConfirmationModalTitle() {
  return screen.getByText(messages.exitConfirmationModalTitle.defaultMessage);
}

export function getExitConfirmationModalText() {
  return screen.getByText(messages.exitConfirmationModalText.defaultMessage);
}

export function getExitConfirmationModalLeaveButton() {
  return screen.getByText(
    messages.exitConfirmationModalPrimaryButton.defaultMessage,
  );
}

export function getExitModalCancelButton() {
  return screen.getByText("Cancel");
}

export function getInputByDisplayValue(displayValue: string) {
  return screen.getByDisplayValue(displayValue);
}

export function querySendTestEmailModal() {
  return screen.queryByText(sendATestModalMessages.title.defaultMessage);
}

export function querySendTestEmailButton() {
  return screen.queryByText(
    sendATestModalMessages.sendEmailButton.defaultMessage,
  );
}

export function queryExitConfirmationModalTitle() {
  return screen.queryByText(messages.exitConfirmationModalTitle.defaultMessage);
}

export function queryExitConfirmationModalText() {
  return screen.queryByText(messages.exitConfirmationModalText.defaultMessage);
}

export function queryExitConfirmationModalLeaveButton() {
  return screen.queryByText(
    messages.exitConfirmationModalPrimaryButton.defaultMessage,
  );
}

export function getYourCompanyAddressLabel() {
  return screen.getByText("We need your company address");
}

export async function findEmailBody(emailBody: string) {
  return screen.findByDisplayValue(emailBody);
}

export function findSendATestButton() {
  return screen.findByText(breadcrumbMessages.sendATestLabel.defaultMessage);
}

export function findSendTestEmailButton() {
  return screen.findByText(
    sendATestModalMessages.sendEmailButton.defaultMessage,
  );
}

export function getBottomBar() {
  return screen.getByTestId("bottom-bar-container");
}

// actions

export function iClickBackButton() {
  fireEvent.click(getBackButton());
}

export function iClickSendTestEmailConfirmationButton() {
  fireEvent.click(getSendTestEmailConfirmationButton());
}

export function iClickSaveDraftButton() {
  fireEvent.click(getSaveDraftButton());
}

export function iClickReviewCampaignButton() {
  fireEvent.click(getReviewCampaignButton());
}

export function iUpdateSubject(subject: string) {
  const subjectLabel = getSubjectLabel();

  fireEvent.change(subjectLabel, { target: { value: subject } });
  fireEvent.blur(subjectLabel);
}

export function iClickExitConfirmationModalCancel() {
  fireEvent.click(getExitModalCancelButton());
}

export function iClickExitConfirmationModalLeaveButton() {
  fireEvent.click(getExitConfirmationModalLeaveButton());
}

export function iChangeInputValue(displayValue: string, value: string) {
  const input = getInputByDisplayValue(displayValue);
  fireEvent.change(input, { target: { value: value } });
  fireEvent.blur(input);
}

export async function iClickSendATestButton() {
  const sendATestButton = await findSendATestButton();
  fireEvent.click(sendATestButton);
}

export async function iClickSendTestEmailButton() {
  const sendTestEmailButton = await findSendTestEmailButton();
  fireEvent.click(sendTestEmailButton);
}
