import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import React from "react";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Markdown } from "@jobber/components/Markdown";
import { formatRate } from "~/jobber/managed_accounts/ProcessingRateBadges/ProcessingRateBadgesInternal";
import type { ProcessingRate } from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import { messages } from "./messages";
import styles from "./styles.module.css";

export interface CardReadersProps {
  show: boolean;
  rate: ProcessingRate;
  showLoyaltyRateStatement: boolean;
  cardReaderStoreLink: string;
}

export function CardReaders(props: CardReadersProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      {props.show && (
        <Card header={formatMessage(messages.cardReadersTitle)}>
          <Content>
            <Heading level={4}>{formatMessage(messages.heading)}</Heading>
            <Text size="small">
              {formatRate(props.rate)}
              {formatMessage(messages.transactionText)}
            </Text>
            <Text>{formatMessage(messages.description)}</Text>
            {props.showLoyaltyRateStatement && (
              <Text>{formatMessage(messages.loyaltyTierText)}</Text>
            )}
            <div className={styles.termsOfService}>
              <Markdown content={formatMessage(messages.termsOfService)} />
            </div>
            <Button
              label="Buy Card Reader"
              type="secondary"
              url={props.cardReaderStoreLink}
              fullWidth={false}
              external={true}
            />
          </Content>
        </Card>
      )}
    </>
  );
}
