import React from "react";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { format } from "date-fns";
import type { Recommendation } from "./types";
import styles from "./styles.module.css";

interface RecommendationDetailsProps {
  recommendation?: Recommendation;
}

export function RecommendationDetails({
  recommendation,
}: RecommendationDetailsProps) {
  if (!recommendation) {
    return (
      <div className={styles.recommendationDetails}>
        <div className={styles.noAvailableOptionsMessageContainer}>
          <Text variation="warn">
            <Icon name="alert" color="warningOnSurface" size="small" />
            {"No available options were found"}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.recommendationDetails}>
      <div className={styles.dateContainer}>
        <Text>{format(recommendation.recommendedStartAt, "EEE MMM d")}</Text>
        <div className={styles.timeContainer}>
          <Text>{format(recommendation.recommendedStartAt, "hh:mm aa")}</Text>
          <Text>→</Text>
          <Text>{format(recommendation.recommendedEndAt, "hh:mm aa")}</Text>
        </div>
      </div>

      <Text variation="subdued" size="small">
        {recommendation.driveTime != null
          ? `${recommendation.driveTime} min drive • `
          : undefined}
        {recommendation.recommendedAssignee?.name}
      </Text>
    </div>
  );
}
