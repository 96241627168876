import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";

export interface CustomFieldConfigurationReducerState {
  id?: string;
  appliesTo: CustomFieldAppliesTo;
  name: string;
  fieldType: CustomFieldConfigurationValueType;
  defaultValue: string | boolean | number;
  areaLength: number;
  areaWidth: number;
  unit: string;
  dropdownOptions: string[];
  transferable: boolean;
  readOnly: boolean;
}

export const invalidTransferableObjects = [
  CustomFieldAppliesTo.ALL_INVOICES,
  CustomFieldAppliesTo.TEAM,
];
