import React from "react";
import styles from "./AppAlertsNotificationBadge.module.css";

export interface AppAlertsNotificationBadgeProps {
  count: number;
}

export function AppAlertsNotificationBadge({
  count,
}: AppAlertsNotificationBadgeProps) {
  const unreadLabel = count > 99 ? "99+" : count.toString();

  return (
    <div
      className={styles.unreadBadge}
      style={{ width: calculateWidth(count) }}
    >
      <div className="notifications-number">{unreadLabel}</div>
    </div>
  );
}

function calculateWidth(count: number) {
  if (count < 10) {
    return "0.9rem";
  }
  if (count < 100) {
    return "1.3rem";
  }
  return "1.5rem";
}
