import * as React from "react";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function FieldError(props: { errorMessage?: string }) {
  const { errorMessage } = props;

  return errorMessage ? (
    <div className="fieldError" data-testid="card-error-message">
      {errorMessage}
    </div>
  ) : null;
}
