import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { AnimatedSwitcher } from "@jobber/components/AnimatedSwitcher";
import { Divider } from "@jobber/components/Divider";
import { Link } from "@jobber/components/Link";
import styles from "./MarketplaceNavDrawer.module.css";

interface MarketplaceNavDrawerProps {
  privateMarketplaceURL: string;
  onFreeTrialClick: () => void;
}

export function MarketplaceNavDrawer({
  privateMarketplaceURL,
  onFreeTrialClick,
}: MarketplaceNavDrawerProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarClassNames = [styles.sidebar, menuOpen && styles.visible].join(
    " ",
  );

  return (
    <div className={styles.topNavGroup}>
      <div className={styles.toggleButton}>
        <AnimatedSwitcher
          switched={menuOpen}
          initialChild={
            <Button
              variation={"subtle"}
              type={"tertiary"}
              icon={"list"}
              onClick={() => setMenuOpen(true)}
              ariaLabel={"toggle sidebar"}
            />
          }
          switchTo={
            <Button
              variation={"subtle"}
              type={"tertiary"}
              icon={"remove"}
              onClick={() => setMenuOpen(false)}
              ariaLabel={"toggle sidebar"}
            />
          }
          type={"fade"}
        />
      </div>
      <aside
        className={sidebarClassNames}
        aria-label="marketplace navigation drawer"
      >
        <div className={styles.sidebarContent}>
          <div className={styles.sidebarNavigationControls} />
          <div className={styles.sidebarNavigationInterior}>
            <div className={styles.sidebarNavigationInteriorCellMain}>
              <Link external url="/">
                Visit Jobber.com
              </Link>
              <Link
                external
                url="https://help.getjobber.com/hc/en-us/articles/360062128653-App-Marketplace"
              >
                About App Marketplace
              </Link>
            </div>
            <div className={styles.sidebarNavigationCellBottom}>
              <p>
                Call us toll free: <a href="tel:+18887211115">1-888-721-1115</a>
              </p>
              <div className={styles.devCenterLink}>
                <Link external url="https://developer.getjobber.com/">
                  Developer Center
                </Link>
              </div>
            </div>
            <Divider />
            <div className={styles.sidebarNavigationCellFooter}>
              <Button label={"Free Trial"} onClick={onFreeTrialClick} />
              <Button
                label={"Log In"}
                type={"secondary"}
                url={privateMarketplaceURL}
              />
              <div className={styles.sidebarNavigationFooterDivider} />
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}
