import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { RecurrenceOptions } from "jobber/workOrders/components/Scheduler/types";
import styles from "./RecurringScheduleDropdown.module.css";

export interface RecurringScheduleDropdownProps {
  recurringOption: RecurrenceOptions;
  startDate: Date;
  disabled: boolean;
  disableCustomSchedule?: boolean;
  onChange(nextState: RecurrenceOptions): void;
}

const dropdownValues = {
  daily: {
    label: "Every day",
    value: RecurrenceOptions.Daily,
  },
  weekly: {
    label: "Every week on",
    value: RecurrenceOptions.Weekly,
  },
  biweekly: {
    label: "Every 2 weeks on",
    value: RecurrenceOptions.BiWeekly,
  },
  asNeeded: {
    label: "As needed - I will manually add visits",
    value: RecurrenceOptions.AsNeeded,
  },
  custom: {
    label: "Custom schedule...",
    value: RecurrenceOptions.Custom,
  },
};

export const RecurringScheduleDropdown = ({
  recurringOption,
  onChange,
  startDate,
  disabled,
  disableCustomSchedule,
}: RecurringScheduleDropdownProps) => {
  const day = startDate.toLocaleString("default", { weekday: "long" });

  const onChangeRecurringOption = (value: string) => onChange(+value);

  return (
    <div className={styles.container}>
      <Select
        placeholder="Repeats"
        name="recurring_schedule_dropdown"
        value={recurringOption}
        onChange={onChangeRecurringOption}
        disabled={disabled}
        size="small"
        prefix={{
          icon: "sync",
        }}
      >
        <Option value={`${RecurrenceOptions.DoesNotRepeat}`}>
          Does not repeat
        </Option>
        <Option value={`${dropdownValues.daily.value}`}>
          {dropdownValues.daily.label}
        </Option>
        <Option value={`${dropdownValues.weekly.value}`}>
          {`${dropdownValues.weekly.label} ${day}`}
        </Option>
        <Option value={`${dropdownValues.biweekly.value}`}>
          {`${dropdownValues.biweekly.label} ${day}`}
        </Option>
        <Option value={`${dropdownValues.asNeeded.value}`}>
          {dropdownValues.asNeeded.label}
        </Option>
        {!disableCustomSchedule && (
          <Option value={`${dropdownValues.custom.value}`}>
            {dropdownValues.custom.label}
          </Option>
        )}
      </Select>
    </div>
  );
};
