export enum SupportedLocale {
  EN = "en",
  EN_AU = "en-AU",
  EN_CA = "en-CA",
  EN_GB = "en-GB",
  EN_US = "en-US",
}

export enum IgnoreIntlWarnings {
  DEFAULT_RICH_TEXT_ELEMENTS = "[@formatjs/intl] 'defaultRichTextElements' was specified, but 'message' was not pre-compiled. Please consider using '@formatjs/cli' to pre-compile your messages for performance. For more details, see https://formatjs.io/docs/getting-started/message-distribution",
}
