import { defineMessages } from "react-intl";

export const messages = defineMessages({
  formattedVariationStringPartial: {
    id: "workOrders.jobCost.lineItemsTable.formattedVariationStringPartial",
    defaultMessage: "Quantity varies on {partialOverrideDatesList} and",
    description: "The override dates string",
  },
  formattedVariationStringFull: {
    id: "workOrders.jobCost.lineItemsTable.formattedVariationStringFull",
    defaultMessage: "Quantity varies on {partialOverrideDatesList}",
    description: "The override dates string",
  },
  viewLessCopy: {
    id: "workOrders.jobCost.lineItemsTable.viewLessCopy",
    defaultMessage: "View less",
    description: "The view less copy",
  },
  showMoreCopySingleVisit: {
    id: "workOrders.jobCost.lineItemsTable.showMoreCopySingleVisit",
    defaultMessage: "{remainingVisitCount} other visit",
    description: "The show more copy when there is a single visit",
  },
  showMoreCopyMultiVisit: {
    id: "workOrders.jobCost.lineItemsTable.showMoreCopyMultiVisit",
    defaultMessage: "{remainingVisitCount} other visits",
    description: "The show more copy when there are multiple visits",
  },
});
