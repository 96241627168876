import { fireEvent, screen } from "@testing-library/react";
import { GLIMMER_TEST_ID } from "@jobber/components/Glimmer";
import userEvent from "@testing-library/user-event";
import { messages as validationEmailModalMessages } from "jobber/campaigns/components/ValidateEmailModal/messages";
import { mockReplyToEmail } from "jobber/campaigns/mocks/campaignReviewMocks";
import { messages as scheduleModalMessages } from "jobber/campaigns/components/ScheduleSendModal/messages";
import { messages } from "./messages";

// getAll selectors

export const getAllSubjectTitle = () => {
  return screen.getAllByText(messages.campaignsSubjectTitle.defaultMessage);
};

export const getAllFromEmailTitle = () => {
  return screen.getAllByText(messages.campaignsFromEmail.defaultMessage);
};

export const getAllReplyToEmailTitle = () => {
  return screen.getAllByText(
    messages.campaignsReplyToEmailTitle.defaultMessage,
  );
};

// get selectors

export const getRecipientSummaryTestId = () => {
  return screen.getByTestId("recipientSummary");
};

export const getAutomatedPastClientsCampaignRecipientSummary = async () => {
  return (
    (await getRecipientSummaryTestId().textContent) ===
    "This campaign will automatically send to clients 6 months after a job ends -> and only if they have no upcoming visits, client tags include 'Administrator', line items include 'product 1', job type is recurringEdit"
  );
};

export const getAutomatedWinBackLostLeadsCampaignRecipientSummary =
  async () => {
    return (
      (await getRecipientSummaryTestId().textContent) ===
      "This campaign will automatically send to clients 6 months after a a quote is sent -> and only if they have no upcoming visits, quote is awaiting response, client tags include 'Administrator', line items include 'product 1'"
    );
  };

export const getOneOffCampaignRecipientSummary = async () => {
  return (
    (await getRecipientSummaryTestId().textContent) ===
    "This email will send to the Test Segment segment, with 10 of 12 subscribed to email marketing.Edit"
  );
};

export const getFromEmail = () => {
  return screen.getByText("Test User <hello_world=gmail.com@jobberemail.com>");
};

export const getFromEmailVerifiedLabel = () => {
  return screen.getByText(messages.fromEmailVerifiedLabel.defaultMessage);
};

export const getFromEmailNotVerifiedLabel = () => {
  return screen.getByText(messages.fromEmailNotVerifiedLabel.defaultMessage);
};

export const getValidatonEmailModalMessage = () => {
  return screen.getByText(validationEmailModalMessages.title.defaultMessage);
};

export const getConsentEntriesErrorMessage = () => {
  return screen.getByText(
    messages.shouldShowConsentEntriesErrorMessage.defaultMessage,
  );
};

export const getMockReplyToEmail = () => {
  return screen.getByText(mockReplyToEmail);
};

export const getScheduledCampaignErrorToast = () => {
  return screen.getByText(messages.scheduledCampaignErrorToast.defaultMessage);
};

export const getScheduleCampaignDateTimeErrorToast = () => {
  return screen.getByText(
    messages.scheduledCampaignDateTimeErrorToast.defaultMessage,
  );
};

// query selectors

export const queryGlimmer = () => {
  return screen.queryByTestId(GLIMMER_TEST_ID);
};

export const queryFromEmailNotVerifiedMessage = () => {
  return screen.queryByText(
    /In order to send this campaign, we need to make sure this email address belongs to you./,
  );
};

export const queryScheduledCampaignErrorToast = () => {
  return screen.queryByText(
    messages.scheduledCampaignErrorToast.defaultMessage,
  );
};

export const queryValidatonEmailModalMessage = () => {
  return screen.queryByText(validationEmailModalMessages.title.defaultMessage);
};

export const queryEmptyClientSegmentError = () => {
  return screen.queryByText(
    messages.emptyClientSegmentErrorBannerText.defaultMessage,
  );
};

export const queryCampaignSendAuthorizationErrorBanner = () => {
  return screen.queryByText(
    messages.campaignSendAuthorizationErrorBannerText.defaultMessage,
  );
};

// Input fields

export const getVerificationCodeInput = () => {
  return screen.getByLabelText(
    validationEmailModalMessages.validationCodePlaceholder.defaultMessage,
  );
};

export const getSendAtDateInputLabel = () => {
  return screen.getByLabelText(
    scheduleModalMessages.sendAtDatePlaceholder.defaultMessage,
  );
};

export const getSendAtTimeInputLabel = () => {
  return screen.getByLabelText(
    scheduleModalMessages.sendAtTimePlaceholder.defaultMessage,
  );
};

export const getEmptyClientSegmentError = () => {
  return screen.getByText(
    messages.emptyClientSegmentErrorBannerText.defaultMessage,
  );
};

export const getCampaignSendErrorBanner = () => {
  return screen.getByText(messages.campaignSendErrorBannerText.defaultMessage);
};

export const getCampaignSendAuthorizationErrorBanner = () => {
  return screen.getByText(
    messages.campaignSendAuthorizationErrorBannerText.defaultMessage,
  );
};

// Modals & Dialogs

export const getModalLabel = () => {
  return screen.getByLabelText("Close modal");
};

export const queryDialog = () => {
  return screen.queryByRole("dialog");
};

// Buttons
export const getPayAndSendButton = () => {
  return screen.getByText(messages.payAndSendButton.defaultMessage);
};

export const queryPayAndSendButton = () => {
  return screen.queryByText(messages.payAndSendButton.defaultMessage);
};

export const getVerifyEmailButton = async () => {
  return screen.findByText("Verify Email");
};

export const getSendCampaignButton = () => {
  return screen.getByText(messages.sendCampaignButton.defaultMessage);
};

export const getSendCampaignLaterButton = () => {
  return screen.getByText(messages.sendCampaignLaterButton.defaultMessage);
};

export const getPayAndScheduleButton = () => {
  return screen.getByText(messages.payAndScheduleButton.defaultMessage);
};

export const getCampaignEmailConsentCheckbox = () => {
  return screen.getByText(
    messages.campaignsConfirmImpliedConsentPermission.defaultMessage,
  );
};

export const getAutomatedCampaignActivateButton = () => {
  return screen.getByText(
    messages.automatedCampaignActivateButton.defaultMessage,
  );
};

export const getPayAndActivateButton = () => {
  return screen.getByText(messages.payAndActivateButton.defaultMessage);
};

export const getDismissButton = () => {
  return screen.getByTestId("remove");
};

export const getBackButton = () => {
  return screen.getByText("Back");
};

export const getSubmitButton = () => {
  return screen.getByRole("button", {
    name: scheduleModalMessages.submitButton.defaultMessage,
  });
};

export const getDismissNotification = () => {
  return screen.getByLabelText("Dismiss notification");
};

export const getTestCampaignButton = () => {
  return screen.getByText("Test Campaign");
};

export const getBackToSegmentButton = () => {
  return screen.getByText(
    messages.emptyClientSegmentErrorBannerCtaText.defaultMessage,
  );
};

// Solo actions

export const iClickSendCampaignButton = () => {
  userEvent.click(getSendCampaignButton());
};

export const iClickSendCampaignLaterButton = () => {
  userEvent.click(getSendCampaignLaterButton());
};

export const iCheckCampaignEmailConsentCheckbox = () => {
  userEvent.click(getCampaignEmailConsentCheckbox());
};

export const iClickAutomatedCampaignActivateButton = () => {
  userEvent.click(getAutomatedCampaignActivateButton());
};

export const iClickDismissButton = () => {
  userEvent.click(getDismissButton());
};

export const iClickVerifyEmailButton = async () => {
  userEvent.click(await getVerifyEmailButton());
};

export const iClickSubmitButton = () => {
  userEvent.click(getSubmitButton());
};

export const iClickDismissNotificaton = () => {
  userEvent.click(getDismissNotification());
};

export const iCloseTheModal = () => {
  userEvent.click(getModalLabel());
};

export const iClickBackToClientSegmentButton = () => {
  userEvent.click(getBackToSegmentButton());
};

// Input actions
export const iEnterVerificationCode = (code: string) => {
  fireEvent.change(getVerificationCodeInput(), {
    target: { value: code },
  });
};

export const iClickBackButton = () => {
  userEvent.click(getBackButton());
};

export const iChangeSendAtDateInput = (date: string) => {
  fireEvent.change(getSendAtDateInputLabel(), {
    target: { value: date },
  });
};

export const iChangeSendAtTimeInput = (time: string) => {
  fireEvent.change(getSendAtTimeInputLabel(), {
    target: { value: time },
  });
};
