import React from "react";
import classnames from "classnames";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { InlineLabel } from "@jobber/components/InlineLabel";
import {
  ExpiryStatus,
  StoredPaymentMethodType,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/utils/CreditCardUIHelpers";
import { CardBrand } from "components/CardBrand/CardBrand";
import { IntlProvider } from "@translations/IntlProvider";
import type { StoredPaymentMethodInterface } from "./StoredPaymentMethodInterface.d";
import styles from "./StoredCardListItem.module.css";
import { messages } from "./messages";

function StoredCardListItem(props: StoredPaymentMethodInterface) {
  const { formatMessage } = useIntl();
  const isBankAccount = props.type === StoredPaymentMethodType.BANK_ACCOUNT;

  return (
    <div className={styles.container}>
      {renderIcon({
        bankName: props.bankName,
        brand: props.brand,
      })}
      {renderCardNumberAndExpiry(
        props.last4,
        props.bankName,
        props.expiryMonth,
        props.expiryYear,
        props.expiryStatus,
      )}
      {renderDefaultSection(props.isDefault)}
    </div>
  );

  function renderIcon({
    brand,
    bankName,
  }: {
    bankName?: string;
    brand?: string;
  }) {
    return (
      <>
        {isBankAccount ? (
          <img
            alt={bankName || "Bank Account"}
            src={window.SG1_IMAGE_MANIFEST["svg/bank.svg"]}
          />
        ) : (
          <CardBrand brand={brand} />
        )}
      </>
    );
  }

  function renderCardNumberAndExpiry(
    last4Digits = "",
    bankName = "",
    month?: number,
    year?: number,
    expiryStatus: ExpiryStatus = ExpiryStatus.DEFAULT,
  ) {
    const bankNameWithDefault =
      bankName?.toUpperCase() || formatMessage(messages.bankAccountTitle);
    let expiryStyle: string;

    if (expiryStatus === ExpiryStatus.EXPIRED) {
      expiryStyle = classnames(styles.expiryContainer, styles.isExpired);
    } else if (expiryStatus === ExpiryStatus.EXPIRING_SOON) {
      expiryStyle = classnames(styles.expiryContainer, styles.expiringSoon);
    } else {
      expiryStyle = classnames(styles.expiryContainer);
    }

    return (
      <div className={styles.cardNumberContainer}>
        <div className={styles.cardNumberInnerContainer}>
          <Text size="base">
            <span className={styles.cardNumber}>{`•••• ${last4Digits}`}</span>
          </Text>
        </div>
        {isBankAccount && <Text size="base">{bankNameWithDefault}</Text>}
        {month && year && (
          <div className={expiryStyle}>
            <Text size="base">
              {formatMessage(messages.expiryDate, {
                expiryDate: `${month}/${year}`,
              })}
            </Text>
          </div>
        )}
      </div>
    );
  }

  function renderDefaultSection(isDefault = false) {
    return (
      <div className={styles.defaultCardContainer}>
        {isDefault && (
          <InlineLabel>{formatMessage(messages.defaultLabel)}</InlineLabel>
        )}
      </div>
    );
  }
}

export function StoredCardListItemWrapper(props: StoredPaymentMethodInterface) {
  return (
    <IntlProvider>
      <StoredCardListItem {...props} />
    </IntlProvider>
  );
}
