import React, { type Dispatch } from "react";
import type { SelectedRecipientType } from "jobber/chat/types";
import {
  TwilioRegistrationStatus,
  type UkKycRegistrationDetails,
} from "~/utilities/API/graphql";

const primaryTitle = "Messages";

export enum ChatView {
  Notifications,
  Compose,
  ReassignConversation,
  Conversation,
  RegistrationInProgress,
  UkKycRegistrationRequired,
}

export interface MessageCenterStateType {
  selectedConversationId?: string | number;
  selectedRecipient?: SelectedRecipientType;
  chatDrawerTitle: string;
  // This is used exclusively by ChatInput in Chat to determine
  // if the user can send. It is set from OpenChat based on whether stop has been sent
  canSendMessages: boolean;
  activeView: ChatView;
  registrationStatus?: TwilioRegistrationStatus;
  ukKycComplianceRegistrationData?: UkKycRegistrationDetails;
  isUkKycComplianceRegistrationHardBlocked?: boolean;
}

interface ShowChatAction {
  type: "SHOW_CHAT";
  conversationId: string | number;
  recipient: SelectedRecipientType;
  title: string;
  canSendMessages?: boolean;
}

interface HideChatAction {
  type: "HIDE_CHAT";
}

interface ComposeAction {
  type: "COMPOSE";
  title: string;
}

interface ReassignConversationAction {
  type: "SHOW_REASSIGN_CONVERSATION";
  title: string;
}

interface SetRecipientAction {
  type: "SET_RECIPIENT";
  recipient: SelectedRecipientType;
}
interface SetConversationIDAction {
  type: "SET_CONVERSATION_ID";
  conversationId: string | number;
}

interface SetRegistrationStatusAction {
  type: "SET_REGISTRATION_STATUS";
  registrationStatus: TwilioRegistrationStatus | undefined;
}

interface SetUkKycRegistrationDataAction {
  type: "SET_UK_KYC_REGISTRATION_DATA";
  ukKycComplianceRegistrationData: UkKycRegistrationDetails | undefined;
  isUkKycComplianceRegistrationHardBlocked: boolean;
}

export type MessageCenterActionType =
  | ShowChatAction
  | HideChatAction
  | ComposeAction
  | ReassignConversationAction
  | SetRecipientAction
  | SetConversationIDAction
  | SetRegistrationStatusAction
  | SetUkKycRegistrationDataAction;

export const InitialMessageCenterState = {
  selectedConversationId: undefined,
  selectedRecipient: undefined,
  chatDrawerTitle: primaryTitle,
  canSendMessages: true,
  activeView: ChatView.Notifications,
  registrationStatus: undefined,
  ukKycComplianceRegistrationData: undefined,
  isUkKycComplianceRegistrationHardBlocked: false,
};

export const MessageCenterContext = React.createContext<
  [MessageCenterStateType, Dispatch<MessageCenterActionType>?]
>([InitialMessageCenterState, undefined]);

export const MessageCenterReducer = (
  state: MessageCenterStateType,
  action: MessageCenterActionType,
) => {
  switch (action.type) {
    case "SHOW_CHAT":
      return {
        ...state,
        selectedConversationId: action.conversationId,
        selectedRecipient: action.recipient,
        chatDrawerTitle: action.title || primaryTitle,
        activeView: ChatView.Conversation,
        canSendMessages:
          action.canSendMessages == undefined ? true : action.canSendMessages,
      };
    case "HIDE_CHAT":
      return {
        ...state,
        selectedConversationId: undefined,
        selectedRecipient: undefined,
        chatDrawerTitle: primaryTitle,
        activeView: ChatView.Notifications,
        canSendMessages: true,
      };
    case "COMPOSE":
      return {
        ...state,
        selectedConversationId: undefined,
        selectedRecipient: undefined,
        chatDrawerTitle: action.title || primaryTitle,
        activeView: ChatView.Compose,
        canSendMessages: true,
      };
    case "SHOW_REASSIGN_CONVERSATION":
      return {
        ...state,
        chatDrawerTitle: action.title || primaryTitle,
        activeView: ChatView.ReassignConversation,
      };
    case "SET_RECIPIENT":
      return {
        ...state,
        selectedRecipient: action.recipient,
      };
    case "SET_CONVERSATION_ID":
      return {
        ...state,
        selectedConversationId: action.conversationId,
      };
    case "SET_REGISTRATION_STATUS":
      if (
        action.registrationStatus == TwilioRegistrationStatus.PROCESSING ||
        action.registrationStatus == TwilioRegistrationStatus.NOT_REGISTERED ||
        action.registrationStatus ==
          TwilioRegistrationStatus.REGISTRATION_FAILED ||
        action.registrationStatus ==
          TwilioRegistrationStatus.VERIFICATION_EXPIRED
      ) {
        return {
          ...state,
          activeView: ChatView.RegistrationInProgress,
          canSendMessages: false,
          registrationStatus: action.registrationStatus,
        };
      } else {
        return {
          ...state,
          registrationStatus: action.registrationStatus,
        };
      }
    case "SET_UK_KYC_REGISTRATION_DATA":
      return {
        ...state,
        ukKycComplianceRegistrationData: action.ukKycComplianceRegistrationData,
        isUkKycComplianceRegistrationHardBlocked:
          action.isUkKycComplianceRegistrationHardBlocked,
      };
    default:
      throw new Error();
  }
};
