export const messages = {
  legacyMigration: {
    modalTitle: "Upgrade your QuickBooks integration for free",
    modalFirstHeader: "Our improved QuickBooks integration is here!",
    modalFirstDescription:
      "Automatic one-way syncing to QuickBooks Online means no more manual syncing or reconciling double entries. Plus, a new dashboard overviews your sync activity and guides you through anything that needs your attention.",
    modalSecondHeader: "Get started with a few clicks",
    modalSecondDescription:
      "To get started, click 'Connect Now' below. Once done, you'll need to reconnect Jobber to your QuickBooks online account, and we'll take care of syncing anything created or updated in Jobber since your last manual sync.\n\nHere are some important differences between the old sync and our new and improved one:",
    connectButton: "Connect Now",
    learnMoreButton: "Learn More",
    cancelButton: "Cancel",
    point1:
      "Syncing is now one-way from Jobber to QuickBooks in real-time — clients, and products and services no longer sync from QuickBooks to Jobber",
    point2:
      "Updates to clients, products and services, and invoices in Jobber automatically sync to QuickBooks",
    upgradeIrreversibleNotice:
      "Keep in mind, **once you move to the new integration, you will be unable to return to the old manual sync**.",
    migrationError:
      "Something went wrong. Please wait 30 seconds, then select Connect Now to try again.",
  },
};
