import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { AddressValues } from "./types";

type InputCountryProps = Omit<
  React.ComponentProps<typeof Select>,
  "value" | "onChange"
> & {
  countries: Array<AddressValues["country"]>;
  value?: AddressValues["country"];
  onChange(province: Required<AddressValues>["country"]): void;
};

export function InputCountry({
  countries,
  value,
  onChange,
  ...selectProps
}: InputCountryProps) {
  return (
    <Select value={value} onChange={onChange} {...selectProps}>
      {countries.map(country => (
        <Option key={country} value={country}>
          {country}
        </Option>
      ))}
    </Select>
  );
}
