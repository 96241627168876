import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { MessageDescriptor } from "react-intl";
import { useIntl } from "react-intl";

export interface IntlSelectOption {
  value: string;
  label: MessageDescriptor;
}
export interface IntlSelectProps extends React.ComponentProps<typeof Select> {
  options: Array<IntlSelectOption>;
}
export function IntlSelect({ options, ...selectProps }: IntlSelectProps) {
  const { formatMessage } = useIntl();
  return (
    <Select {...selectProps}>
      {options.map((option, index) => (
        <Option
          key={`${option.label.defaultMessage}.${index}`}
          value={option.value}
        >
          {formatMessage(option.label)}
        </Option>
      ))}
    </Select>
  );
}
