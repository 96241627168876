import { Button } from "@jobber/components/Button";
import { InputTime } from "@jobber/components/InputTime";
import { InputValidation } from "@jobber/components/InputValidation";
import { CivilTime } from "@std-proposal/temporal";
import React, { useState } from "react";
import { NotificationReminderUnit } from "~/utilities/API/graphql";
import { ActionTypes } from "jobber/settings/notifications/ActionTypes";
import { NotificationStringTemplate } from "jobber/settings/notifications/components/NotificationStringTemplate/NotificationStringTemplate";
import { DeliveryMethodPicker } from "jobber/settings/notifications/components/Scheduler/components/DeliveryMethodPicker/DeliveryMethodPicker";
import { DurationPicker } from "jobber/settings/notifications/components/Scheduler/components/DurationPicker/DurationPicker";
import type { ScheduleRowProps } from "jobber/settings/notifications/notificationInterfaces";

export function ScheduleRow({
  scheduleTemplate,
  schedule,
  allowUnitChange,
  allowDeliveryMethodChange,
  onDispatch,
  canDelete,
}: ScheduleRowProps) {
  const handleScheduleToggle = () => {
    onDispatch({
      type: ActionTypes.ToggleSchedule,
      id: schedule.id,
      value: !schedule.enabled,
    });
  };

  return (
    <>
      {schedule.enabled && (
        <div className="row">
          <div className="columns">
            <ScheduleForm
              scheduleTemplate={scheduleTemplate}
              schedule={schedule}
              allowUnitChange={allowUnitChange}
              allowDeliveryMethodChange={allowDeliveryMethodChange}
              onDispatch={onDispatch}
            />
          </div>

          {canDelete && (
            <div className="small-12 medium-shrink columns">
              <Button
                label="Delete"
                type="secondary"
                variation="destructive"
                onClick={handleScheduleToggle}
              />
            </div>
          )}
        </div>
      )}
      {!schedule.enabled && (
        <Button label="Add" type="secondary" onClick={handleScheduleToggle} />
      )}
    </>
  );
}

function ScheduleForm({
  scheduleTemplate,
  schedule,
  allowUnitChange,
  allowDeliveryMethodChange,
  onDispatch,
  onValidation,
}: ScheduleRowProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const handleDispatch = (actionType: ActionTypes) => {
    return (newValue: string | number | CivilTime | undefined) => {
      onDispatch({
        type: actionType,
        id: schedule.id,
        value: newValue,
      });
    };
  };

  const handleValidation = (message: string) => {
    setErrorMessage(message);

    if (onValidation) {
      onValidation(message);
    }
  };

  const durationPicker = (
    <DurationPicker
      offset={schedule.offset}
      before={schedule.before}
      allowHours={schedule.before}
      allowUnitChange={allowUnitChange}
      onDurationChange={handleDispatch(ActionTypes.ChangeScheduleOffsetValue)}
      onUnitChange={handleDispatch(ActionTypes.ChangeScheduleOffsetUnit)}
      onValidation={handleValidation}
    />
  );

  const deliveryMethodPicker = (
    <DeliveryMethodPicker
      value={schedule.deliveryMethod}
      allowMethodChange={allowDeliveryMethodChange}
      onChange={handleDispatch(ActionTypes.ChangeDeliveryMethod)}
    />
  );

  const timeValue = schedule.at ? CivilTime.fromString(schedule.at) : undefined;

  // We only want to show time picker for day selection.
  // No time is needed for hour selections.
  const shouldShowTimePicker = !(
    schedule.offset && schedule.offset.unit === NotificationReminderUnit.HOUR
  );

  let timePicker = <></>;
  if (shouldShowTimePicker) {
    timePicker = (
      <>
        <>at </>
        <InputTime
          value={timeValue}
          onChange={handleDispatch(ActionTypes.ChangeScheduleTime)}
          size="small"
          inline={true}
        />
      </>
    );
  }

  return (
    <>
      {scheduleTemplate && (
        <>
          <NotificationStringTemplate
            template={scheduleTemplate}
            values={{
              duration: durationPicker,
              method: deliveryMethodPicker,
              time: timePicker,
            }}
          />
          {errorMessage && <InputValidation message={errorMessage} />}
        </>
      )}
    </>
  );
}
