import React from "react";
import { Text } from "@jobber/components/Text";
import { FormattedMessage } from "react-intl";
import { Emphasis } from "@jobber/components";
import { AddNewLineItemButton } from "jobber/workOrders/components/JobCost/components/LineItemsTable/components/AddNewLineItemButton";
import type { LineItem } from "~/jobber/lineItems/types";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useLineItemPermissions } from "~/jobber/lineItems/hooks/useLineItemPermissions";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./LineItemTotals.module.css";
import { messages } from "./messages";

export interface LineItemTotalsProps {
  onClickAddLineItem: () => void;
  lineItems: LineItem[];
  children?: React.ReactNode;
}

export function LineItemTotals({
  onClickAddLineItem,
  lineItems,
  children,
}: LineItemTotalsProps) {
  const { showCosting } = useLineItemPermissions();
  const { currencySymbol } = useAccount();

  const lineItemsTotalCost = lineItems.reduce(
    (total, lineItem) => total + (lineItem.totalCost || 0),
    0.0,
  );

  const lineItemsTotalPrice = lineItems.reduce(
    (total, lineItem) => total + (lineItem.totalPrice || 0),
    0.0,
  );

  return (
    <div className={styles.container} data-testid="lineItemsFooter">
      <div className={styles.lineItemsFooter}>
        <div className={styles.lineItemsTotalButton}>
          <AddNewLineItemButton onPress={onClickAddLineItem} />
        </div>
        <div className={styles.lineItemsTotals}>
          {showCosting && (
            <div className={styles.lineItemsTotalCost}>
              <Text>
                <FormattedMessage {...messages.totalCostLabel} />
              </Text>
              <Text>{formatCurrency(lineItemsTotalCost, currencySymbol)}</Text>
            </div>
          )}
          <div className={styles.lineItemsTotalPrice}>
            <Emphasis variation="bold">
              <FormattedMessage {...messages.totalPriceLabel} />
            </Emphasis>
            <Emphasis variation="bold">
              {formatCurrency(lineItemsTotalPrice, currencySymbol)}
            </Emphasis>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
