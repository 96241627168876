import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { InternalClientHubSettings } from "./InternalClientHubSettings";

interface ClientHubSettingsProps {
  dismissCta?(): void;
  setToggleAchFlag?(flag: boolean): void;
  updateDefaultPreference?: {
    preference: string;
    achEnabled: boolean;
  };
}

export function ClientHubSettings(props: ClientHubSettingsProps) {
  return (
    <APIProvider>
      <InternalClientHubSettings
        dismissCta={props.dismissCta}
        setToggleAchFlag={props.setToggleAchFlag}
        updateDefaultPreference={props.updateDefaultPreference}
      />
    </APIProvider>
  );
}
