import React from "react";
import { useIntl } from "react-intl";
import { messages } from "jobber/landingPages/messages";
import { LandingPagePlanCard } from "../LandingPagePlanCard";

export function CorePlanCard() {
  const { formatMessage } = useIntl();
  const planFeatures = [
    formatMessage(messages.corePlanCardPlanFeatures1),
    formatMessage(messages.corePlanCardPlanFeatures2),
    formatMessage(messages.corePlanCardPlanFeatures3),
    formatMessage(messages.corePlanCardPlanFeatures4),
  ];

  return (
    <div
      data-testid="corePlanCard"
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <LandingPagePlanCard
        cardHeading={formatMessage(messages.corePlanCardHeading)}
        cardDescription={formatMessage(messages.corePlanCardDescription)}
        featuresHeading={formatMessage(messages.corePlanCardFeaturesHeading)}
        planUsersDescription={formatMessage(
          messages.corePlanCardPlanUsersDescription,
        )}
        planFeatures={planFeatures}
      />
    </div>
  );
}
