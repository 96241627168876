import React from "react";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import styles from "./RuleConditionLine.module.css";

interface RuleConditionAddButtonProps {
  itemActions: { label: string; onClick: () => void }[];
}

export function RuleConditionAddButton({
  itemActions,
}: RuleConditionAddButtonProps) {
  return (
    <div className={styles.container}>
      <div className={styles.addButton}>
        <Menu
          items={[
            {
              header: "And only if...",
              actions: itemActions,
            },
          ]}
          activator={
            <Button
              variation="work"
              type="secondary"
              size="small"
              ariaLabel="Add Condition"
              icon="plus2"
            />
          }
        />
      </div>
    </div>
  );
}
