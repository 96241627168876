import React from "react";
import deepmerge from "deepmerge";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputValidation } from "@jobber/components/InputValidation";
import type { ArrivalWindowDayOfWeekMap } from "jobber/google_lsa/MerchantConfiguration";
import styles from "./EditArrivalWindows.module.css";
import { atLeastOneDayOfWeekIsSelected } from "./ArrivalWindowsValidation";

interface DaysOfWeekProps {
  days: ArrivalWindowDayOfWeekMap;
  onChange(newValue: ArrivalWindowDayOfWeekMap): void;
}

export function ArrivalWindowDaysOfWeek({ days, onChange }: DaysOfWeekProps) {
  const isAnyDaySelected = atLeastOneDayOfWeekIsSelected(days);

  function onDaysChange(dayIndex: string) {
    return (newValue: boolean) => {
      const newDays: ArrivalWindowDayOfWeekMap = deepmerge({}, days);
      newDays[dayIndex as keyof ArrivalWindowDayOfWeekMap] = newValue;
      onChange(newDays);
    };
  }
  return (
    <>
      <Checkbox
        label="Sunday"
        checked={days.sunday}
        onChange={onDaysChange("sunday")}
      />
      <Checkbox
        label="Monday"
        checked={days.monday}
        onChange={onDaysChange("monday")}
      />
      <Checkbox
        label="Tuesday"
        checked={days.tuesday}
        onChange={onDaysChange("tuesday")}
      />
      <Checkbox
        label="Wednesday"
        checked={days.wednesday}
        onChange={onDaysChange("wednesday")}
      />
      <Checkbox
        label="Thursday"
        checked={days.thursday}
        onChange={onDaysChange("thursday")}
      />
      <Checkbox
        label="Friday"
        checked={days.friday}
        onChange={onDaysChange("friday")}
      />
      <Checkbox
        label="Saturday"
        checked={days.saturday}
        onChange={onDaysChange("saturday")}
      />
      {isAnyDaySelected ? (
        <></>
      ) : (
        <div className={styles.editArrivalWindows__daysOfWeek__validation}>
          <InputValidation message="Choose day(s) of the week for this arrival window" />
        </div>
      )}
    </>
  );
}
