/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Controller as RHFController,
  FormProvider as RHFFormProvider,
  useController as useRHFController,
  useFieldArray as useRHFFieldArray,
  useFormContext as useRHFFormContext,
  useWatch as useRHFWatch,
  useForm as useRHForm,
} from "react-hook-form";
import type {
  FieldValues,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  UseFormSetError,
} from "react-hook-form";

export type {
  FieldValues,
  RegisterOptions,
  SubmitHandler,
  SubmitErrorHandler,
  UseFormProps,
  UseFormReturn,
};

/**
 * Submit handler that allows for setting errors on the form.
 */
interface FormMethods<TFieldValues extends FieldValues> {
  setError: UseFormSetError<TFieldValues>;
}

export interface FormSubmitHandler<TFieldValues extends FieldValues> {
  (data: TFieldValues, methods: FormMethods<TFieldValues>): void;
}

/**
 * Types to be removed from the `useForm` props to avoid devs from changing them which would cause differences between
 * implementations.
 */
type ExcludedUseFormProps = "mode" | "reValidateMode";

/**
 * Light abstraction over React Hook Form to provide a consistent experience for all forms in the app when leveraging
 * the `react-hook-form` library directly. This was meant as a way to keep the settings consistent to Atlantis's form component (https://github.com/GetJobber/atlantis/blob/master/packages/components/src/Form/Form.tsx)
 */
export function useFormWithDefaults<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(props?: Omit<UseFormProps<TFieldValues, TContext>, ExcludedUseFormProps>) {
  return useRHForm<TFieldValues, TContext, TTransformedValues>({
    mode: "onTouched",
    ...props,
  });
}

export const useFieldArray = useRHFFieldArray;
export const useController = useRHFController;
export const useFormContext = useRHFFormContext;
export const useWatch = useRHFWatch;

export const Controller = RHFController;
export const FormProvider = RHFFormProvider;
