import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addToPlan: {
    id: "billing.addonPurchaseModal.addToPlan",
    defaultMessage: "Add to Plan",
    description: "Add addon button label",
  },
  modalTitle: {
    id: "billing.addonPurchaseModal.modalTitle",
    defaultMessage: "Add to Plan",
    description: "Modal title",
  },
  submitButtonLabel: {
    id: "billing.addonPurchaseModal.submitButtonLabel",
    defaultMessage: "Subscribe",
    description: "Confirmation button label text",
  },
  successfulUpdate: {
    id: "billing.addonPurchaseModal.successfulUpdate",
    defaultMessage: "Added to subscription",
    description: "Successful update message",
  },
  defaultSubmitError: {
    id: "billing.addonPurchaseModal.defaultSubmitError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Default error message",
  },
});
