import React, { type FunctionComponent } from "react";
import { IntlProvider } from "./IntlProvider";

export function withIntlProvider<WrappedComponentProps extends object>(
  WrappedComponent: FunctionComponent<WrappedComponentProps>,
) {
  function IntlWrapped(props: WrappedComponentProps) {
    return (
      <IntlProvider>
        <WrappedComponent {...props} />
      </IntlProvider>
    );
  }

  return IntlWrapped;
}
