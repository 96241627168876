import React from "react";
import classNames from "classnames";
import { BannerOverlay } from "./BannerOverlay";
import styles from "./Banner.module.css";

interface BannerSlotProps extends React.PropsWithChildren {
  size?: "small" | "large" | "fill";
  padded?: boolean;
  variant?: "image";
}

function BannerSlot({ size, padded, variant, children }: BannerSlotProps) {
  const sizeClasses = {
    large: styles.large,
    small: styles.small,
    fill: styles.fill,
  };

  const variants = {
    image: styles.bannerImage,
  };

  return (
    <div
      className={classNames([
        variant && variants[variant],
        padded && styles.padded,
        size && sizeClasses[size],
        styles.bannerSlot,
      ])}
    >
      {children}
    </div>
  );
}

interface ImageSlotProps extends React.PropsWithChildren {
  hasTornEffect?: boolean;
}

function ImageSlot({ children, hasTornEffect = true }: ImageSlotProps) {
  return (
    <BannerSlot variant="image">
      {children}
      {hasTornEffect && <BannerOverlay.Torn />}
    </BannerSlot>
  );
}

function ContentSlot({ children }: React.PropsWithChildren) {
  return (
    <BannerSlot size="fill" padded>
      {children}
    </BannerSlot>
  );
}

BannerSlot.Image = ImageSlot;
BannerSlot.Content = ContentSlot;
export { BannerSlot };
