import React from "react";
import classnames from "classnames";
import styles from "./IntegrationLogo.module.css";

interface IntegrationLogoProps {
  /** The title of the Integration */
  title: string;
  /** The URL of the Integration Logo */
  logoUrl: string;

  /**
   * Take up the available width of the parent container
   * @default false
   */
  fullwidth?: boolean;

  /** The color of the background */
  backgroundColor?: string;
}

export function IntegrationLogo({
  title,
  logoUrl,
  /* istanbul ignore next */
  fullwidth = false,
  backgroundColor,
}: IntegrationLogoProps) {
  const logoClassNames = classnames(styles.logo, {
    [styles.fullwidth]: fullwidth,
  });

  return (
    <div
      style={{ backgroundColor: backgroundColor ? backgroundColor : "inherit" }}
      className={logoClassNames}
    >
      <img alt={`${title} Logo`} src={logoUrl} />
    </div>
  );
}
