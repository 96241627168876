import React, { type ReactElement } from "react";
import styles from "./Table.module.css";

interface HeaderProps {
  children: ReactElement | ReactElement[];
}

export function Header({ children }: HeaderProps) {
  return <thead className={styles.header}>{children}</thead>;
}
