import React, { type Dispatch, useEffect, useState } from "react";
import currency from "currency.js";
import { type FetchResult, useApolloClient, useMutation } from "@apollo/client";
import type { PaymentAction } from "jobber/payments_react/paymentReducer";
import { fetchPlaidLinkPaymentToken } from "jobber/plaidLink/PlaidLinkUtils";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { PLAID_ACH_PAYMENT_MUTATION } from "./plaidACHPaymentMutation";
import {
  type BankConnectionButtonProps,
  BankPaymentACHButton,
} from "./BankPaymentACHButton";
import type { BankPaymentACHButtonProviderProps } from "./BankPaymentACHButtonProvider";

interface PlaidPaymentResponseType {
  achPayment: {
    success: boolean;
    errors: [
      {
        message: string;
      },
    ];
  };
}

interface PlaidMetadataObjectType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const currencyFormatOptions = {
  symbol: "",
  separator: ",",
};

/* eslint-disable max-statements */
export function PlaidLinkWrapper(props: BankPaymentACHButtonProviderProps) {
  const {
    bankPaymentACHSettings,
    setBankPaymentErrorMessage,
    attachedToId,
    attachedToType,
    onPaymentSubmit,
    isQuoteDepositPayment,
    quoteSignatureData,
    setBankPaymentAuthorized,
    setBankPaymentInfoCallback,
    authorizedBankingInfo,
    setAuthorizedBankingInfo,
    oauthStateId,
  } = props;

  const [paymentState, paymentDispatcher] = usePaymentReducer();
  const storedToken = localStorage.getItem("plaidLinkToken");

  useEffect(() => {
    if (paymentState.status !== "notInitialized") {
      return;
    }

    paymentDispatcher({ type: "completingInitialization" });
  }, [paymentState.status]);

  let initialButtonState = {
    token: "",
    buttonLabel: "Please Wait",
    loading: true,
    accountLinked: false,
    errorMessage: "",
    achAuthorizedTokenReady: false,
  };

  if (authorizedBankingInfo.publicToken) {
    initialButtonState = {
      loading: false,
      accountLinked: true,
      // Bank account was already initialized so we can skip the plaid link token step
      token: "n/a",
      buttonLabel: getButtonLabel(paymentState.amount, isQuoteDepositPayment),
      errorMessage: "",
      achAuthorizedTokenReady: true,
    };
  }

  if (oauthStateId && storedToken) {
    initialButtonState = {
      loading: false,
      accountLinked: true,
      // Use the previously stored token since we are in the oauth flow
      token: storedToken,
      buttonLabel: getButtonLabel(paymentState.amount, isQuoteDepositPayment),
      errorMessage: "",
      achAuthorizedTokenReady: true,
    };
    localStorage.removeItem("plaidLinkToken");
  }

  const [buttonState, setbuttonState] = useState(initialButtonState);
  const [submitPlaidLinkToken] = useMutation(PLAID_ACH_PAYMENT_MUTATION);
  const apolloClient = useApolloClient();

  const config: BankConnectionButtonProps = {
    amount: paymentState.amount,
    clientName: "Jobber",
    env: bankPaymentACHSettings.plaidEnvironment,
    product: ["auth"],
    countryCodes: ["US"],
    buttonState,
    oauthStateId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (publicToken: string, metadata: any) => {
      const bankPaymentInfoCallback = {
        institutionName: metadata.institution.name,
        accountName: metadata.account.name,
        accountMask: metadata.account.mask,
      };

      setBankPaymentInfoCallback(bankPaymentInfoCallback);

      setbuttonState({
        loading: false,
        accountLinked: true,
        token: buttonState.token,
        buttonLabel: getButtonLabel(config.amount, isQuoteDepositPayment),
        errorMessage: "",
        achAuthorizedTokenReady: true,
      });
      setAuthorizedBankingInfo({
        publicToken,
        metadata,
      });
    },
    submitPlaidLinkToken: async () => {
      const loadingState = { ...buttonState, loading: true };
      const notLoadingState = { ...buttonState, loading: false };
      const bankingPaymentInfo = {
        isQuoteDepositPayment,
        quoteSignatureData,
        attachedToId,
        attachedToType,
        authorizedBankingInfo,
      };

      // Prevent user switching tabs on the payment dialog
      setBankPaymentAuthorized(true);

      // Block button when token is being exchanged
      setbuttonState(loadingState);

      await sendPlaidToken(
        submitPlaidLinkToken,
        setBankPaymentErrorMessage,
        onPaymentSubmit,
        bankingPaymentInfo,
        paymentDispatcher,
      );

      setbuttonState(notLoadingState);
    },
  };

  if (!buttonState.token && buttonState.buttonLabel !== "Try Again") {
    void fetchPlaidLinkPaymentToken({
      apolloClient,
      onSuccess: linkToken => {
        setbuttonState({
          loading: false,
          accountLinked: false,
          token: linkToken,
          buttonLabel: "Select Bank",
          errorMessage: "",
          achAuthorizedTokenReady: false,
        });
      },
      onError: errorMessage => {
        setbuttonState({
          loading: false,
          accountLinked: false,
          token: "",
          buttonLabel: "Try Again",
          errorMessage,
          achAuthorizedTokenReady: false,
        });
      },
    });
  }
  return <BankPaymentACHButton {...config} />;
}

function getButtonLabel(amount: string, isQuoteDepositPayment: boolean) {
  const buttonText = `Pay $${currency(amount, currencyFormatOptions).format()}`;
  if (isQuoteDepositPayment) {
    return `Approve & ${buttonText}`;
  }
  return buttonText;
}

/* eslint-disable max-statements */
async function sendPlaidToken(
  submitPlaidLinkToken: (
    params: PlaidMetadataObjectType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<FetchResult<any, Record<string, any>>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setBankPaymentErrorMessage: (err: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPaymentSubmit: (err: any) => void,
  achInfo: PlaidMetadataObjectType,
  paymentDispatcher: Dispatch<PaymentAction>,
) {
  const {
    attachedToId,
    attachedToType,
    authorizedBankingInfo,
    isQuoteDepositPayment,
    quoteSignatureData,
  } = achInfo;
  const { metadata, publicToken: plaidLinkPublicKeyToken } =
    authorizedBankingInfo;
  const { account_id: plaidAccountId } = metadata;

  try {
    if (isQuoteDepositPayment && !quoteSignatureData) {
      throw new Error("Please provide a signature to approve this quote.");
    }
    const submissionData = {
      attachedToId: btoa(attachedToId),
      attachedToType: attachedToType,
      plaidLinkPublicKeyToken,
      plaidAccountId,
      approvalSignatureData: quoteSignatureData,
    };

    paymentDispatcher({ type: "requestingJobberPaymentCharge" });

    const result = await submitPlaidLinkToken({ variables: submissionData });
    const data: PlaidPaymentResponseType = result.data;

    if (!data.achPayment.success) {
      throw new Error(data.achPayment.errors[0].message);
    } else {
      onPaymentSubmit({ type: "achPaymentAccepted" });
    }
  } catch (e) {
    const errorMessage =
      e.message ||
      "There's a problem retrieving your banking information. Please try again.";
    setBankPaymentErrorMessage(errorMessage);
    paymentDispatcher({
      type: "completingCharge",
      response: {
        success: false,
        errorMessage: errorMessage,
      },
    });
  }
}
/* eslint-enable max-statements */
