import type { FetchResult } from "@apollo/client";
import type { CompanySchedulePageDataQuery } from "~/utilities/API/graphql";

enum DayOfWeek {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
}

export const days: DayOfWeek[] = Object.values(DayOfWeek);

export const makeFullCalendarBusinessHours = (
  workingHours: FetchResult<CompanySchedulePageDataQuery>["data"],
) => {
  if (!workingHours) return [];

  const result = Array.from({ length: 7 })
    .map((_, i) => {
      const day = days[i];
      const [startTime, endTime] =
        workingHours.companySchedule.days[day].times[0];

      if (!workingHours.companySchedule.days[day].enabled) {
        return; // Skip this entry
      }

      return {
        daysOfWeek: [i],
        startTime,
        endTime,
      };
    })
    .filter(item => item !== undefined); // remove undefined entries to prevent fullcalendar from crashing

  return result;
};
