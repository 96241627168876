import React from "react";
import { Typography } from "@jobber/components";
import type { DatumValue } from "@nivo/line";
import { ChartTooltip } from "jobber/dataVisualizations/ChartTooltip";

interface CopilotChartTooltipProps {
  xAxisLabel: string;
  yAxisLabel: string;
  xValue: DatumValue;
  yValue: DatumValue;
}

export function CopilotChartTooltip(props: CopilotChartTooltipProps) {
  const content = (
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <Typography fontWeight="bold">{`${props.xAxisLabel}: `}</Typography>
        <Typography>{props.xValue.toString()}</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <Typography fontWeight="bold">{`${props.yAxisLabel}: `}</Typography>
        <Typography>{props.yValue.toString()}</Typography>
      </div>
    </div>
  );

  return <ChartTooltip label={""} content={content} />;
}
