import { useState } from "react";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import {
  DEFAULT_DATE_FILTER_TYPE,
  DEFAULT_DATE_FILTER_VALUES,
} from "jobber/franchise/features/Reporting/views/QuoteDetailReport/constants";
import type { QuoteDetailDateFilterTypes } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/types";

export const useDateFilterControls = () => {
  const [dateFilterValues, setDateFilterValues] = useState<FilterType>(
    DEFAULT_DATE_FILTER_VALUES,
  );

  const [dateFilterType, setDateFilterType] =
    useState<QuoteDetailDateFilterTypes>(DEFAULT_DATE_FILTER_TYPE);

  return {
    dateFilterValues,
    setDateFilterValues,
    dateFilterType,
    setDateFilterType,
  };
};
