const testImageManifest = {
  "button/quickbooks/C2QB_white_btn_lg_default.png":
    "//localhost.test:3000/assets/components/button/quickbooks/C2QB_white_btn_lg_default.png",
  "button/quickbooks/C2QB_white_btn_lg_hover.png":
    "//localhost.test:3000/assets/components/button/quickbooks/C2QB_white_btn_lg_hover.png",
  "button/quickbooks/Sign_in_white_btn_lg_default.png":
    "//localhost.test:3000/assets/components/button/quickbooks/Sign_in_white_btn_lg_default.png",
  "button/quickbooks/Sign_in_white_btn_lg_hover.png":
    "//localhost.test:3000/assets/components/button/quickbooks/Sign_in_white_btn_lg_hover.png",
  "svg/timesheet-blank-icon.svg":
    "//localhost.test:3000/assets/components/svg/timesheet-blank-icon.svg",
  "svg/emptyState-invoices-graphic.svg":
    "//localhost.test:3000/assets/components/svg/emptyState-invoices-graphic.svg",
  "svg/emptyState-jobs-graphic.svg":
    "//localhost.test:3000/assets/components/svg/emptyState-jobs-graphic.svg",
  "svg/nothing-scheduled-graphic.svg":
    "//localhost.test:3000/assets/components/svg/nothing-scheduled-graphic.svg",
  "svg/emptyState-clients-graphic.svg":
    "//localhost.test:3000/assets/components/svg/emptyState-clients-graphic.svg",
  "svg/bgSlice.svg": "//localhost.test:3000/assets/components/svg/bgSlice.svg",
  "svg/emptyState-quotes-graphic.svg":
    "//localhost.test:3000/assets/components/svg/emptyState-quotes-graphic.svg",
  "svg/cvc.svg": "//localhost.test:3000/assets/components/svg/cvc.svg",
  "svg/notes-blank-icon.svg":
    "//localhost.test:3000/assets/components/svg/notes-blank-icon.svg",
  "svg/cc-american-express.svg":
    "//localhost.test:3000/assets/components/svg/cc-american-express.svg",
  "svg/cc-discover.svg":
    "//localhost.test:3000/assets/components/svg/cc-discover.svg",
  "svg/card.svg": "//localhost.test:3000/assets/components/svg/card.svg",
  "svg/cc-jcb.svg": "//localhost.test:3000/assets/components/svg/cc-jcb.svg",
  "svg/cc-visa.svg": "//localhost.test:3000/assets/components/svg/cc-visa.svg",
  "svg/cc-international.svg":
    "//localhost.test:3000/assets/components/svg/cc-international.svg",
  "svg/bug-white.svg":
    "//localhost.test:3000/assets/components/svg/bug-white.svg",
  "svg/cvc-american-express.svg":
    "//localhost.test:3000/assets/components/svg/cvc-american-express.svg",
  "svg/cc-visa-debit.svg":
    "//localhost.test:3000/assets/components/svg/cc-visa-debit.svg",
  "svg/cc-diners-club.svg":
    "//localhost.test:3000/assets/components/svg/cc-diners-club.svg",
  "svg/clients-blank-icon.svg":
    "//localhost.test:3000/assets/components/svg/clients-blank-icon.svg",
  "svg/bank.svg": "//localhost.test:3000/assets/components/svg/bank.svg",
  "svg/bgSlice-large.svg":
    "//localhost.test:3000/assets/components/svg/bgSlice-large.svg",
  "svg/powered-by-jobber-navy.svg":
    "//localhost.test:3000/assets/components/svg/powered-by-jobber-navy.svg",
  "svg/cc-mastercard.svg":
    "//localhost.test:3000/assets/components/svg/cc-mastercard.svg",
  "svg/bgAngled.svg":
    "//localhost.test:3000/assets/components/svg/bgAngled.svg",
  "flash/flash-errorFace.svg":
    "//localhost.test:3000/assets/components/flash/flash-errorFace.svg",
  "flash/flash-successFace.svg":
    "//localhost.test:3000/assets/components/flash/flash-successFace.svg",
  "flash/flash-warningFace.svg":
    "//localhost.test:3000/assets/components/flash/flash-warningFace.svg",
  "button/google/google_icon_optimized.svg":
    "//localhost.test:3000/assets/components/button/google/google_icon_optimized.svg",
  "button/social/intuit-icon-optimized.svg":
    "//localhost.test:3000/assets/components/button/social/intuit-icon-optimized.svg",
  "button/social/google_icon_optimized.svg":
    "//localhost.test:3000/assets/components/button/social/google_icon_optimized.svg",
  "button/social/intuit_icon_optimized.svg":
    "//localhost.test:3000/assets/components/button/social/intuit_icon_optimized.svg",
  "button/social/google-icon-optimized.svg":
    "//localhost.test:3000/assets/components/button/social/google-icon-optimized.svg",
  "logo/jobber-logo-white.svg":
    "//localhost.test:3000/assets/components/logo/jobber-logo-white.svg",
  "icons/jobbericons.svg":
    "//localhost.test:3000/assets/components/icons/jobbericons.svg",
  "bg-image/bgSlice-large.svg":
    "//localhost.test:3000/assets/components/bg-image/bgSlice-large.svg",
  "bg-image/slice.svg":
    "//localhost.test:3000/assets/components/bg-image/slice.svg",
  "bg-image/neighbourhood-with-pink-trees.jpg":
    "//localhost.test:3000/assets/components/bg-image/neighbourhood-with-pink-trees.jpg",
};

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default testImageManifest;
