import type { UsePaymentMethodsConfig } from "jobber/payments_sca/hooks/usePaymentMethods";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import {
  type ServerCreditCard,
  mapServerCreditCard,
} from "jobber/payments_sca/utils/mapServerCreditCard";

export function makeUsePaymentMethodsConfig({
  endPointUrl,
  deleteEndPointPathPattern,
  patchEndPointPathPattern,
  staticServerFields,
}: {
  endPointUrl: string;
  deleteEndPointPathPattern?: string;
  patchEndPointPathPattern?: string;

  // `unknown` cuz this object's entries are never read. They are passed in by Rails and passed onto server
  staticServerFields?: { [key: string]: unknown };
}): UsePaymentMethodsConfig {
  return {
    async loadPaymentMethods(): Promise<CreditCard[]> {
      const response = await fetch(endPointUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      const rawServerResponse = (await response.json()) as ServerCreditCard[];

      return rawServerResponse.map(mapServerCreditCard);
    },
    async deletePaymentMethod(id: string) {
      if (!deleteEndPointPathPattern) {
        throw new Error(`missing deleteEndPointPathPattern`);
      }

      const url = deleteEndPointPathPattern.replace(":id", id);
      const response = await fetch(url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(staticServerFields),
      });

      if (!response.ok) throw new Error(`${response.status}`);

      return undefined;
    },
    async setDefaultPaymentMethod(id: string) {
      if (!patchEndPointPathPattern) {
        throw new Error(`missing patchEndPointPathPattern`);
      }

      const url = patchEndPointPathPattern.replace(":id", id);
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          default_method: true,
          ...staticServerFields,
        }),
      });

      if (!response.ok) throw new Error(`${response.status}`);

      return undefined;
    },
  };
}
