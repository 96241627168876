import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadError.heading",
    defaultMessage: "We had an issue submitting your documents",
    description: "Error page heading",
  },
  descriptionFirst: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadError.descriptionFirst",
    defaultMessage:
      "We can try to submit them to Stripe again or you can upload new files. For assistance, please contact support at ",
    description: "First part of the description",
  },
  tryAgainButtonLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadError.tryAgainButtonLabel",
    defaultMessage: "Try again",
    description: "Try again button label",
  },
  goBackButtonLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadError.goBackButtonLabel",
    defaultMessage: "Go back",
    description: "Go back button label",
  },
});
