import { gql } from "@apollo/client";

export const ACTIVATE_CORE_TO_CONNECT_TRIAL = gql`
  mutation ActivateCoreToConnectTrial($input: ActivateCoreToConnectTrialInput) {
    activateCoreToConnectTrial(input: $input) {
      membership {
        id
        experimentId
        variationId
        abTestableId
        abTestableType
      }
      userErrors {
        message
      }
    }
  }
`;
