import { gql } from "@apollo/client";

export const CAMPAIGN_PURCHASE_STATE_QUERY = gql`
  query CampaignPurchaseStateQuery($id: EncodedId!) {
    commsCampaignPurchaseState(id: $id) {
      purchaseRequired
      availableToPurchase
    }
  }
`;
