import React from "react";
import {
  SetupGuidePanel,
  type SetupGuidePanelProps,
} from "jobber/dashboard/components/SetupGuide/components/SetupGuidePanel";
import type { SetupStepProps } from "jobber/dashboard/components/SetupGuide/SetupGuide";
import {
  type SetupGuideStep,
  SetupGuideStepStatus,
} from "~/utilities/API/graphql";
import { messages as messagesForGrowTrial } from "jobber/connectToGrowTrial/components/ConnectToGrowOnboardingChecklist/messages";
import {
  SET_UP_JOB_COSTING_GUIDE,
  SET_UP_LOCATION_TIMERS_GUIDE,
  SET_UP_OPTIONAL_LINE_ITEMS_GUIDE,
  SET_UP_QUOTE_FOLLOWUPS_GUIDE,
  SET_UP_TWSMS_GUIDE,
} from "jobber/connectToGrowTrial/components/ConnectToGrowOnboardingChecklist/constants";
import { DownloadAppPanel } from "../components/DownloadAppPanel";
import {
  ADD_BRANDING_GUIDE,
  ADD_CLIENT_GUIDE,
  ADD_TEAM_USER_GUIDE,
} from "../constants";
import { messages } from "../messages";

export function buildListItem(
  step: SetupGuideStep,
  index: number,
): SetupStepProps {
  const commonItemValues = {
    completed: step.status === SetupGuideStepStatus.INCOMPLETE ? false : true,
    id: index,
  };

  switch (step.step) {
    case "added_branding":
      return {
        listItem: {
          ...commonItemValues,
          title: messages.addedBranding.defaultMessage,
        },
        panel: renderPanel(ADD_BRANDING_GUIDE),
      };
    case "added_client":
      return {
        listItem: {
          ...commonItemValues,
          title: messages.addedClient.defaultMessage,
        },
        panel: renderPanel(ADD_CLIENT_GUIDE),
      };
    case "app_downloaded":
      return {
        listItem: {
          ...commonItemValues,
          title: messages.appDownloaded.defaultMessage,
        },
        panel: <DownloadAppPanel />,
      };
    case "added_team_user":
      return {
        listItem: {
          ...commonItemValues,
          title: messages.addedTeamUser.defaultMessage,
        },
        panel: renderPanel(ADD_TEAM_USER_GUIDE),
      };
    case "sms_setup":
      return {
        listItem: {
          ...commonItemValues,
          title: messagesForGrowTrial.twsms.defaultMessage,
          timeEstimate: SET_UP_TWSMS_GUIDE.content.timeEstimate,
        },
        panel: renderPanel(SET_UP_TWSMS_GUIDE),
      };
    case "quote_follow_up_setup":
      return {
        listItem: {
          ...commonItemValues,
          title: messagesForGrowTrial.customizeQuoteFollowUps.defaultMessage,
          timeEstimate: SET_UP_QUOTE_FOLLOWUPS_GUIDE.content.timeEstimate,
        },
        panel: renderPanel(SET_UP_QUOTE_FOLLOWUPS_GUIDE),
      };
    case "location_timers_on":
      return {
        listItem: {
          ...commonItemValues,
          title: messagesForGrowTrial.turnOnLocationTimers.defaultMessage,
          timeEstimate: SET_UP_LOCATION_TIMERS_GUIDE.content.timeEstimate,
        },
        panel: renderPanel(SET_UP_LOCATION_TIMERS_GUIDE),
      };
    case "added_advanced_line_items":
      return {
        listItem: {
          ...commonItemValues,
          title: messagesForGrowTrial.addOptionalLineItems.defaultMessage,
          timeEstimate: SET_UP_OPTIONAL_LINE_ITEMS_GUIDE.content.timeEstimate,
        },
        panel: renderPanel(SET_UP_OPTIONAL_LINE_ITEMS_GUIDE),
      };
    case "added_labour_rate":
      return {
        listItem: {
          ...commonItemValues,
          title: messagesForGrowTrial.startTrackingJobCosts.defaultMessage,
          timeEstimate: SET_UP_JOB_COSTING_GUIDE.content.timeEstimate,
        },
        panel: renderPanel(SET_UP_JOB_COSTING_GUIDE),
      };
    default:
      return {
        listItem: {
          ...commonItemValues,
          title: "Default",
          timeEstimate: "",
          completed: false,
        },
        panel: renderPanel(undefined),
      };
  }
}

function renderPanel(props: SetupGuidePanelProps | undefined): JSX.Element {
  if (!props) {
    return <></>;
  }

  return <SetupGuidePanel img={props.img} content={props.content} />;
}
