import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.BookingIntervalConfiguration.configurationLabel",
    defaultMessage: "Booking interval",
    description: "Label for the configuration option",
  },
  configurationDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.configurationDescription",
    defaultMessage:
      "Set the increments your appointment times will be offered at, beginning at the start of your business hours",
    description: "Description for the configuration option",
  },
  settingSaved: {
    id: "selfServeBookings.BookingIntervalConfiguration.settingSaved",
    defaultMessage: "Updated booking interval",
    description: "Toast message displayed when the setting is saved",
  },
  errorMessage: {
    id: "selfServeBookings.BookingIntervalConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Message displayed when an error occurs",
  },
  explanationDescriptionPartial: {
    id: "selfServeBookings.BookingIntervalConfiguration.explanationDescriptionPartial",
    defaultMessage: "Appointments will be offered ",
    description: "Description for the explanation on how intervals work",
  },
  halfHour: {
    id: "selfServeBookings.BookingIntervalConfiguration.halfHour",
    defaultMessage: "30 minutes",
    description: "30 minute interval option",
  },
  oneHour: {
    id: "selfServeBookings.BookingIntervalConfiguration.oneHour",
    defaultMessage: "1 hour",
    description: "1 hour interval option",
  },
  twoHours: {
    id: "selfServeBookings.BookingIntervalConfiguration.twoHours",
    defaultMessage: "2 hours",
    description: "2 hour interval option",
  },
  threeHours: {
    id: "selfServeBookings.BookingIntervalConfiguration.threeHours",
    defaultMessage: "3 hours",
    description: "3 hour interval option",
  },
  fourHours: {
    id: "selfServeBookings.BookingIntervalConfiguration.fourHours",
    defaultMessage: "4 hours",
    description: "4 hour interval option",
  },
  halfHourDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.halfHourDescription",
    defaultMessage: "every 30 minute.",
    description: "30 minute interval description",
  },
  oneHourDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.oneHourDescription",
    defaultMessage: "every 1 hour.",
    description: "1 hour interval description",
  },
  twoHoursDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.twoHoursDescription",
    defaultMessage: "every 2 hours.",
    description: "2 hour interval description",
  },
  threeHoursDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.threeHoursDescription",
    defaultMessage: "every 3 hours.",
    description: "3 hour interval description",
  },
  fourHoursDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.fourHoursDescription",
    defaultMessage: "every 4 hours.",
    description: "4 hour interval description",
  },
  visualizerDescription: {
    id: "selfServeBookings.BookingIntervalConfiguration.visualizerDescription",
    defaultMessage: " For example at ",
    description: "Description for the visualizer",
  },
  halfHoursVisualized: {
    id: "selfServeBookings.BookingIntervalConfiguration.halfHoursVisualized",
    defaultMessage: "9:00 AM, 9:30 AM, 10:00 AM, etc.",
    description: "30 minute interval visualized",
  },
  oneHourVisualized: {
    id: "selfServeBookings.BookingIntervalConfiguration.oneHourVisualized",
    defaultMessage: "9:00 AM, 10:00 AM, 11:00 AM, etc.",
    description: "1 hour interval visualized",
  },
  twoHoursVisualized: {
    id: "selfServeBookings.BookingIntervalConfiguration.twoHoursVisualized",
    defaultMessage: "9:00 AM, 11:00 AM, 1:00 PM, etc.",
    description: "2 hour interval visualized",
  },
  threeHoursVisualized: {
    id: "selfServeBookings.BookingIntervalConfiguration.threeHoursVisualized",
    defaultMessage: "9:00 AM, 12:00 PM, 3:00 PM, etc.",
    description: "3 hour interval visualized",
  },
  fourHoursVisualized: {
    id: "selfServeBookings.BookingIntervalConfiguration.fourHoursVisualized",
    defaultMessage: "9:00 AM, 1:00 PM, etc.",
    description: "4 hour interval visualized",
  },
});
