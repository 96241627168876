import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Content } from "@jobber/components/Content";
import { APIProvider } from "~/utilities/API/APIProvider";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./CardHolderWatchListReviewUpload.module.css";
import { CardHolderWatchListReviewUploadForm } from "./components/CardHolderWatchListReviewUploadForm/CardHolderWatchListReviewUploadForm";
import { CardHolderWatchListReviewUploadSuccess } from "./components/CardHolderWatchListReviewUploadSuccess/CardHolderWatchListReviewUploadSuccess";
import { CardHolderWatchListReviewUploadError } from "./components/CardHolderWatchListReviewUploadError/CardHolderWatchListReviewUploadError";
import { uploadFileToStripe } from "./utils";
import { useLinkVerificationDocuments, useSubmission } from "./hooks";
import type { CardHolderWatchListReviewUploadFormState } from "./types";
import { CardHolderWatchListReviewAccessError } from "./components/CardHolderWatchListReviewAccessError/CardHolderWatchListReviewAccessError";

export interface CardHolderWatchListReviewUploadProps {
  isValidCardHolder: boolean;
  cardHolderId: string;
  cardHolderName?: string;
  stripeAccountId: string;
  hasExistingSubmission: boolean;
}
const CardHolderWatchListReviewUploadInternal = ({
  cardHolderId,
  cardHolderName,
  isValidCardHolder,
  stripeAccountId,
  hasExistingSubmission,
}: CardHolderWatchListReviewUploadProps) => {
  const [submissionResponseType, setSubmissionResponseType] = useSubmission(
    hasExistingSubmission,
  );

  const formMethods = useForm<CardHolderWatchListReviewUploadFormState>({});
  const { getValues, control, handleSubmit, clearErrors } = formMethods;
  const [loading, setLoading] = useState<boolean>(false);
  const { handleLinkVerificationDocuments } = useLinkVerificationDocuments();

  return (
    <APIProvider>
      <IntlProvider>
        <Content>
          <div className={styles.pageContainer}>
            {!isValidCardHolder ? (
              <CardHolderWatchListReviewAccessError />
            ) : (
              <>
                {submissionResponseType === undefined && (
                  <CardHolderWatchListReviewUploadForm
                    cardHolderName={cardHolderName}
                    onFormSubmit={handleSubmit(onFormSubmit)}
                    loading={loading}
                    control={control}
                    clearErrors={clearErrors}
                  />
                )}

                {submissionResponseType?.type === "success" && (
                  <CardHolderWatchListReviewUploadSuccess />
                )}

                {submissionResponseType?.type === "error" && (
                  <CardHolderWatchListReviewUploadError
                    loading={loading}
                    onTryAgain={onFormSubmit}
                    onGoBack={() => setSubmissionResponseType(undefined)}
                  />
                )}
              </>
            )}
          </div>
        </Content>
      </IntlProvider>
    </APIProvider>
  );

  async function onFormSubmit() {
    setLoading(true);

    const { frontSide, backSide } = getValues();
    try {
      const frontFileUploadResponse = await uploadFileToStripe(
        stripeAccountId,
        frontSide,
      );
      let backFileUploadResponse;
      if (backSide) {
        backFileUploadResponse = await uploadFileToStripe(
          stripeAccountId,
          backSide,
        );
      }

      const mutationParams = {
        cardHolderId: cardHolderId,
        documentFrontId: frontFileUploadResponse.id,
        documentBackId: backFileUploadResponse?.id,
      };

      await handleLinkVerificationDocuments(mutationParams);

      setSubmissionResponseType({ type: "success", data: undefined });
    } catch {
      setSubmissionResponseType({ type: "error" });
    } finally {
      setLoading(false);
    }
  }
};

export const CardHolderWatchListReviewUpload = withEntryPointProviders(
  CardHolderWatchListReviewUploadInternal,
);
