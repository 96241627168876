// eslint-disable-next-line import/no-extraneous-dependencies
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";

export interface DateRange {
  after: Date | undefined;
  before: Date | undefined;
}

export interface PresetRange {
  preset: DateRanges;
  range: DateRange;
}

// These strings are related to the Intl keys in messages.ts
export enum DateRanges {
  all = "dateRangeAll",
  week = "dateRangeWeek",
  thirtyDays = "dateRangeThirtyDays",
  month = "dateRangeMonth",
  year = "dateRangeYear",
  twelveMonths = "dateRangeTwelveMonths",
  thisMonth = "dateRangeThisMonth",
  custom = "dateRangeCustom",
}

export const defaultDateRangeOptions: DateRangeOptions[] = [
  DateRanges.all,
  DateRanges.week,
  DateRanges.thirtyDays,
  DateRanges.month,
  DateRanges.year,
  DateRanges.twelveMonths,
  DateRanges.custom,
];

export type DateRangeOptions =
  | DateRanges.all
  | DateRanges.week
  | DateRanges.thirtyDays
  | DateRanges.month
  | DateRanges.year
  | DateRanges.twelveMonths
  | DateRanges.custom
  | DateRanges.thisMonth;

// Any new DateRangeOptions need to be added to this list
export const presetDateRanges = {
  [DateRanges.all]: {
    after: undefined,
    before: undefined,
  },
  [DateRanges.week]: {
    after: startOfWeek(subWeeks(new Date(), 1)),
    before: endOfDay(endOfWeek(subWeeks(new Date(), 1))),
  },
  [DateRanges.thirtyDays]: {
    after: startOfDay(subDays(new Date(), 30)),
    before: endOfDay(new Date()),
  },
  [DateRanges.month]: {
    after: startOfDay(startOfMonth(subMonths(new Date(), 1))),
    before: endOfDay(endOfMonth(subMonths(new Date(), 1))),
  },
  [DateRanges.year]: {
    after: new Date(new Date().getFullYear(), 0, 1),
    before: endOfDay(new Date()),
  },
  [DateRanges.twelveMonths]: {
    after: startOfDay(subMonths(new Date(), 12)),
    before: endOfDay(new Date()),
  },
  [DateRanges.custom]: {
    after: customStartDate(),
    before: customEndDate(),
  },
  [DateRanges.thisMonth]: {
    after: startOfDay(startOfMonth(new Date())),
    before: endOfDay(endOfMonth(new Date())),
  },
};

function customStartDate() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function customEndDate() {
  const date = new Date();
  date.setHours(23, 59, 59, 999);
  return date;
}
