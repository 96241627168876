import { Heading, StatusLabel, Text } from "@jobber/components";
import React from "react";
import { useIntl } from "react-intl";
import { uKKycComplianceRegistrationStatusMessages } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/components/UkKycComplianceRegistrationStatus/ukKycComplianceRegistrationStatusMessages";
import { UkKycRegistrationStatuses } from "~/utilities/API/graphql";
import styles from "./UkKycComplianceRegistrationStatus.module.css";

export interface UkKycComplianceRegistrationStatusProps {
  status: string;
}

export function UkKycComplianceRegistrationStatus({
  status,
}: UkKycComplianceRegistrationStatusProps) {
  const { formatMessage } = useIntl();
  const { label, message } = mapRegistrationStatus(status);

  return (
    <>
      {label && message ? (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.inline}>
              <Heading level={2}>
                {formatMessage(
                  uKKycComplianceRegistrationStatusMessages.statusHeader,
                )}
              </Heading>
              <StatusLabel
                label={formatMessage(label)}
                alignment={"start"}
                status={"warning"}
              />
            </div>
            <Text>{formatMessage(message)}</Text>
          </div>
        </div>
      ) : null}
    </>
  );
}
function mapRegistrationStatus(status: string) {
  switch (status) {
    case UkKycRegistrationStatuses.IN_REVIEW:
    case UkKycRegistrationStatuses.PENDING_REVIEW:
      return {
        label: uKKycComplianceRegistrationStatusMessages.inProgressStatusLabel,
        message:
          uKKycComplianceRegistrationStatusMessages.inProgressStatusMessage,
      };
    default:
      return {
        label: null,
        message: null,
      };
  }
}
