import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { AppAlertsButtonLoader as AppAlertsButton } from "./AppAlertsButton.loader";

export function AppAlerts() {
  return (
    <APIProvider>
      <AppAlertsButton />
    </APIProvider>
  );
}
