import React, { type ReactNode, useEffect } from "react";
import classnames from "classnames";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { DescriptionList } from "@jobber/components/DescriptionList";
import { strFormatDate } from "@jobber/components/FormatDate";
import { showToast } from "@jobber/components/Toast";
import type { DisputeType } from "jobber/features/PaymentDisputes/utils/disputeUtils";
import type { StripeFileLink } from "~/utilities/API/graphql";
import { useStripeFileLink } from "jobber/features/PaymentDisputes/hooks/useStripeFileLink";
import {
  ADDITIONAL_DETAILS_HEADING,
  CLIENT_INFORMATION_HEADING,
  COUNTER_DISPUTE_REASON_HEADING,
  CounterDisputeReasonText,
  PRODUCT_OR_SERVICE_DETAILS_HEADING,
  SUPPORTING_EVIDENCE_HEADING,
  SupportingEvidenceFileCategories,
} from "./constants";
import styles from "./SubmittedEvidence.module.css";
import { SupportingFileLink } from "../SupportingFileLink";

interface SubmittedEvidenceProps {
  dispute: NonNullable<DisputeType>;
  setSupportingEvidenceFileDownloadError: React.Dispatch<
    React.SetStateAction<string>
  >;
}

export function SubmittedEvidence({
  dispute,
  setSupportingEvidenceFileDownloadError,
}: SubmittedEvidenceProps) {
  const { submittedEvidence } = dispute;

  const getClientDetails = (): [string, string | ReactNode][] => {
    return [
      ["Client", submittedEvidence?.customerName || "—"],
      ["Client email", submittedEvidence?.customerEmailAddress || "—"],
      ["Client billing address", submittedEvidence?.billingAddress || "—"],
    ];
  };

  const onStripeFileLinkComplete = (stripeFileLink: StripeFileLink) => {
    if (stripeFileLink.publicUrl) {
      window.open(stripeFileLink.publicUrl, "_blank", "noopener noreferrer");

      showToast({
        message: "Supporting evidence downloaded",
        variation: "success",
      });
    }
  };

  const stripeFileLink = useStripeFileLink(onStripeFileLinkComplete);

  useEffect(() => {
    if (stripeFileLink.error) {
      setSupportingEvidenceFileDownloadError(stripeFileLink.error);
    }
  }, [stripeFileLink.error]);

  const serviceDate =
    submittedEvidence?.serviceOccurrence &&
    strFormatDate(new Date(submittedEvidence.serviceOccurrence), true);

  const hasSupportingFiles = submittedEvidence?.supportingEvidence?.nodes.some(
    node => node.fileId,
  );

  const haveFilesExpired = () => {
    if (!dispute.responseSubmittedAt) return false;
    const fileExpirationMonths = 9;
    const currentDate = new Date();
    const expiryDate = new Date(dispute.responseSubmittedAt);
    expiryDate.setUTCMonth(expiryDate.getUTCMonth() + fileExpirationMonths);
    return currentDate >= expiryDate;
  };

  const renderSupportingEvidence = () =>
    submittedEvidence?.supportingEvidence?.nodes
      .filter(uploadedFile => uploadedFile.fileId !== null)
      .map((uploadedFile, index) => {
        const category = uploadedFile.category;
        const key = `${category}-${index}`;

        const onViewSupportingEvidenceClick = () => {
          if (uploadedFile.fileId) {
            stripeFileLink.retrieveFileLink(uploadedFile.fileId);
          }
        };

        return (
          <div
            key={key}
            className={classnames(styles.supportingEvidenceFileLinkContainer)}
          >
            <Content>
              <SupportingFileLink
                customClassName={classnames(styles.supportingEvidenceFileLink)}
                onViewSupportingFileClick={onViewSupportingEvidenceClick}
                supportingFileLinkText={
                  SupportingEvidenceFileCategories[category]
                }
                ariaLabel={`Uploaded file for ${SupportingEvidenceFileCategories[category]}`}
              />
            </Content>
          </div>
        );
      });

  return (
    <div className={classnames(styles.submittedEvidenceContainer)}>
      <Card header="Submitted evidence">
        <Content>
          <Heading level={4}>{COUNTER_DISPUTE_REASON_HEADING}</Heading>
          <div className={classnames(styles.submittedEvidenceSection)}>
            {dispute.counterDisputeReason &&
              CounterDisputeReasonText[dispute.counterDisputeReason]}
          </div>
          <Heading level={4}>{PRODUCT_OR_SERVICE_DETAILS_HEADING}</Heading>
          <div className={classnames(styles.submittedEvidenceSection)}>
            <div className={classnames(styles.serviceDate)}>{serviceDate}</div>
            {submittedEvidence?.productDescription}
          </div>
          <Heading level={4}>{ADDITIONAL_DETAILS_HEADING}</Heading>
          <div className={classnames(styles.submittedEvidenceSection)}>
            {submittedEvidence?.refundRefusalExplanation}
          </div>
          {hasSupportingFiles && (
            <>
              <Heading level={4}>{SUPPORTING_EVIDENCE_HEADING}</Heading>
              <div
                className={classnames(
                  haveFilesExpired()
                    ? styles.submittedEvidenceSection
                    : styles.supportingEvidenceSection,
                )}
              >
                {haveFilesExpired()
                  ? "Uploaded files are only available for 9 months"
                  : renderSupportingEvidence()}
              </div>
            </>
          )}
          <Heading level={4}>{CLIENT_INFORMATION_HEADING}</Heading>
          <div className={classnames(styles.submittedEvidenceSection)}>
            <DescriptionList data={getClientDetails()} />
          </div>
        </Content>
      </Card>
    </div>
  );
}
