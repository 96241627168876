import type PaymentError from "jobber/payments/errors/PaymentError";

export interface JobberApiError {
  generic?: { header: string; details: string[] };
  invoice?: string;
  quote?: string[];
  error?: string[];
  base?: string[];
}

export interface JobberApiResponse {
  success?: boolean;
  errors?: JobberApiError;
}

export interface PaymentSuccessResponse {
  success: true;
}

export interface PaymentErrorResponse {
  success: false;
  error: PaymentError;
}

export type PaymentResponse = PaymentSuccessResponse | PaymentErrorResponse;

export function isPaymentSuccessResponse(
  response: unknown,
): response is PaymentSuccessResponse {
  return (response as PaymentSuccessResponse).success === true;
}

export function isPaymentErrorResponse(
  response: unknown,
): response is PaymentErrorResponse {
  return (response as PaymentErrorResponse).success === false;
}
