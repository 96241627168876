import { gql, useQuery } from "@apollo/client";
import type { DeepPartial } from "utility-types";
import type {
  GetCurrentUserQueryDataQuery,
  GetCurrentUserQueryDataQueryVariables,
} from "~/utilities/API/graphql";

export const CURRENT_USER_QUERY = gql`
  query getCurrentUserQueryData(
    $fullName: Boolean!
    $isOwner: Boolean!
    $isAdmin: Boolean!
    $email: Boolean!
  ) {
    user {
      id
      fullName @include(if: $fullName)
      isOwner @include(if: $isOwner)
      isAdmin @include(if: $isAdmin)
      email @include(if: $email) {
        raw
        isValid
      }
    }
  }
`;

export function useCurrentUser(
  fields: (keyof GetCurrentUserQueryDataQueryVariables)[],
) {
  const variables: GetCurrentUserQueryDataQueryVariables = {
    fullName: false,
    isOwner: false,
    isAdmin: false,
    email: false,
  };

  fields.forEach(field => (variables[field] = true));

  const { loading, error, data } = useQuery<GetCurrentUserQueryDataQuery>(
    CURRENT_USER_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables,
    },
  );

  const partialData = data as DeepPartial<typeof data>;
  const currentUser = partialData?.user ?? {};
  return { loading, error, currentUser };
}
