import React from "react";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { showToast } from "@jobber/components/Toast";
import type {
  CampaignDeleteMutation,
  CampaignDeleteMutationVariables,
} from "~/utilities/API/graphql";
import { messages as campaignsLandingPageMessages } from "jobber/campaigns/views/CampaignsLandingPage/messages";
import { messages } from "./messages";
import { CAMPAIGN_DELETE } from "./DeleteCampaign.graphql";

export interface DeleteCampaignModalProps {
  isOpen: boolean;
  campaignId: string;
  setSelectedCampaignId: (id: string) => void;
  setCampaignActionsError: (error: string | undefined) => void;
  refreshCampaignList: () => void;
}

export function DeleteCampaignModal({
  isOpen,
  campaignId,
  setSelectedCampaignId,
  setCampaignActionsError,
  refreshCampaignList,
}: DeleteCampaignModalProps) {
  const { formatMessage } = useIntl();

  const [deleteCampaignMutation] = useMutation<
    CampaignDeleteMutation,
    CampaignDeleteMutationVariables
  >(CAMPAIGN_DELETE);

  const onConfirm = async () => {
    setSelectedCampaignId("");

    await deleteCampaignMutation({
      variables: { campaignId },
      onCompleted: data => {
        const userErrors = data?.campaignDelete.userErrors ?? [];

        if (userErrors.length > 0) {
          setCampaignActionsError(userErrors[0].message);
        } else {
          setCampaignActionsError(undefined);
          showToast({
            message: formatMessage(messages.deleteCampaignToast),
            variation: "success",
          });
        }
      },
      onError: () => {
        setCampaignActionsError(
          formatMessage(campaignsLandingPageMessages.errorMessage),
        );
      },
    });

    refreshCampaignList();
  };

  return (
    <ConfirmationModal
      size="small"
      title={formatMessage(messages.deleteCampaignModalTitle)}
      message={formatMessage(messages.deleteCampaignModalMessage)}
      variation={"destructive"}
      open={isOpen}
      confirmLabel={formatMessage(messages.deleteCampaignModalButtonText)}
      onConfirm={onConfirm}
      onCancel={() => setSelectedCampaignId("")}
    />
  );
}
