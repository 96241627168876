import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  getYear,
  isAfter,
  isBefore,
  isMatch,
  isSameDay,
  isWithinInterval,
  parse,
  parseISO,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { ChartDataAggregationInterval } from "jobber/dataVisualizations/types";
import {
  timePeriodMapping,
  xAxisLabelMapping,
} from "jobber/dataVisualizations/utils/dataVisualizationsUtils";
import { getEndOfWeekDay } from "jobber/dataVisualizations/utils/dateRangeUtils";
import type {
  Iso8601DateRange,
  LineChartTooltipNode,
  TooltipData,
} from "jobber/dataVisualizations/types";

const PARTIAL_DATE_FORMAT = "MMM d";
const FULL_DATE_FORMAT = "MMM d, yyyy";

function getDailyTimeRange(date: string) {
  const dayDate = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.DAILY],
    new Date(),
  );
  return {
    startAt: dayDate,
    endAt: dayDate,
  };
}

// Returns the date range for a weekly grouping, handling the logic for if the beginning or end of the entire date range has a partial week.
function getWeeklyTimeRange({
  date,
  dateRange,
  weekStartsOnMonday,
}: {
  date: string;
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}) {
  const startOfInterval = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.WEEKLY],
    new Date(),
  );
  const daysToAdd = Math.min(
    6,
    differenceInDays(parseISO(dateRange.before), startOfInterval),
  );
  const endOfInterval = isSameDay(startOfInterval, parseISO(dateRange.after))
    ? getEndOfWeekDay(startOfInterval, weekStartsOnMonday)
    : addDays(startOfInterval, daysToAdd);

  return {
    startAt: startOfInterval,
    endAt: endOfInterval,
  };
}

function getMonthlyTimeRange({
  date,
  dateRange,
}: {
  date: string;
  dateRange: Iso8601DateRange;
}) {
  // line charts pass dates as MMM yyyy and bar charts pass dates as yyyy-MM
  const dateFormat = isMatch(
    date,
    xAxisLabelMapping[ChartDataAggregationInterval.MONTHLY],
  )
    ? xAxisLabelMapping[ChartDataAggregationInterval.MONTHLY]
    : timePeriodMapping[ChartDataAggregationInterval.MONTHLY];

  const parsedDate = parse(date, dateFormat, new Date());

  const firstDayOfMonth = startOfMonth(parsedDate);
  const startOfInterval = isAfter(parseISO(dateRange.after), firstDayOfMonth)
    ? parseISO(dateRange.after)
    : firstDayOfMonth;

  const lastDayOfMonth = endOfMonth(startOfInterval);
  const endOfInterval = isBefore(
    endOfDay(parseISO(dateRange.before)),
    lastDayOfMonth,
  )
    ? endOfDay(parseISO(dateRange.before))
    : lastDayOfMonth;

  return {
    startAt: startOfInterval,
    endAt: endOfInterval,
  };
}

function getQuarterlyTimeRange({
  date,
  dateRange,
}: {
  date: string;
  dateRange: Iso8601DateRange;
}) {
  const parsedDate = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.QUARTERLY],
    new Date(),
  );

  const startOfInterval = isWithinInterval(parseISO(dateRange.after), {
    start: parsedDate,
    end: addMonths(parsedDate, 2),
  })
    ? parseISO(dateRange.after)
    : startOfMonth(parsedDate);

  const endOfInterval = isWithinInterval(parseISO(dateRange.before), {
    start: startOfInterval,
    end: endOfMonth(addMonths(startOfInterval, 2)),
  })
    ? parseISO(dateRange.before)
    : endOfMonth(addMonths(startOfInterval, 2));

  return {
    startAt: startOfInterval,
    endAt: endOfInterval,
  };
}

function getYearlyTimeRange(date: string) {
  const parsedDate = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.YEARLY],
    new Date(),
  );

  return {
    startAt: startOfYear(parsedDate),
    endAt: endOfYear(parsedDate),
  };
}

function formatDailyTooltipDate(date: string) {
  return format(
    parse(
      date,
      timePeriodMapping[ChartDataAggregationInterval.DAILY],
      new Date(),
    ),
    FULL_DATE_FORMAT,
  );
}

function formatWeeklyTooltipDate({
  date,
  dateRange,
  weekStartsOnMonday,
}: {
  date: string;
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}) {
  const { startAt, endAt } = getWeeklyTimeRange({
    date,
    dateRange,
    weekStartsOnMonday,
  });
  return `${format(startAt, getYear(startAt) === getYear(endAt) ? PARTIAL_DATE_FORMAT : FULL_DATE_FORMAT)} - ${format(endAt, FULL_DATE_FORMAT)}`;
}

function formatMonthlyTooltipDate({
  date,
  dateRange,
}: {
  date: string;
  dateRange: Iso8601DateRange;
}) {
  const { startAt, endAt } = getMonthlyTimeRange({
    date,
    dateRange,
  });
  return `${format(startAt, PARTIAL_DATE_FORMAT)} - ${format(endAt, FULL_DATE_FORMAT)}`;
}

// Returns the formatted date range for each quarter grouping, handling the logic for if the beginning/end of the entire date range has a partial quarter
function formatQuarterlyTooltipDate(date: string, dateRange: Iso8601DateRange) {
  const { startAt, endAt } = getQuarterlyTimeRange({
    date,
    dateRange,
  });

  return `${format(startAt, getYear(startAt) === getYear(endAt) ? PARTIAL_DATE_FORMAT : FULL_DATE_FORMAT)} - ${format(endAt, FULL_DATE_FORMAT)}`;
}

export function getAdditionalDateInformationForTooltipLabel({
  tooltipData,
  totalsData,
}: {
  tooltipData: TooltipData;
  totalsData: LineChartTooltipNode[];
}): string {
  const { timePeriod } =
    totalsData.find(data => data.xAxisTick === tooltipData.indexValue) || {};
  return timePeriod || "";
}

export function getTimeRange({
  chartDataAggregationInterval,
  date,
  dateRange,
  weekStartsOnMonday,
}: {
  chartDataAggregationInterval: string;
  date: string;
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}): { startAt: Date; endAt: Date } {
  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY: {
      return getDailyTimeRange(date);
    }
    case ChartDataAggregationInterval.WEEKLY: {
      return getWeeklyTimeRange({
        date,
        dateRange,
        weekStartsOnMonday,
      });
    }
    case ChartDataAggregationInterval.MONTHLY: {
      return getMonthlyTimeRange({
        date,
        dateRange,
      });
    }
    case ChartDataAggregationInterval.QUARTERLY: {
      return getQuarterlyTimeRange({
        date,
        dateRange,
      });
    }
    case ChartDataAggregationInterval.YEARLY: {
      return getYearlyTimeRange(date);
    }
  }
  return { startAt: new Date(), endAt: new Date() };
}

export function formatResponsiveChartTooltipDate({
  chartDataAggregationInterval,
  dateRange,
  fullDate,
  tooltipData,
  weekStartsOnMonday,
}: {
  chartDataAggregationInterval: string;
  dateRange: Iso8601DateRange;
  fullDate?: string;
  tooltipData: TooltipData;
  weekStartsOnMonday: boolean;
}): string {
  const {
    data: { timePeriod, xFormatted },
  } = tooltipData;
  const date = xFormatted ? xFormatted : timePeriod;

  if (typeof date !== "string") {
    return "";
  }

  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY: {
      return formatDailyTooltipDate(fullDate || date);
    }
    case ChartDataAggregationInterval.WEEKLY: {
      return formatWeeklyTooltipDate({
        date: fullDate || date,
        dateRange,
        weekStartsOnMonday,
      });
    }
    case ChartDataAggregationInterval.MONTHLY: {
      return formatMonthlyTooltipDate({ date, dateRange });
    }
    case ChartDataAggregationInterval.QUARTERLY: {
      return formatQuarterlyTooltipDate(fullDate || date, dateRange);
    }
    case ChartDataAggregationInterval.YEARLY: {
      return date;
    }
    default:
      return "";
  }
}
