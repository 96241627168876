import { gql } from "@apollo/client";

const schedulingRecommendationFragment = gql`
  fragment schedulingRecommendation on SchedulingRecommendation {
    recommendedStartAt
    recommendedEndAt
    driveTime
    recommendedAssignee {
      id
      name {
        full
      }
    }
  }
`;

export const SCHEDULING_AVAILABILITY_QUERY = gql`
  query schedulingAvailability($input: SchedulingAvailabilityInput!) {
    schedulingAvailability(input: $input) {
      soonest {
        ...schedulingRecommendation
      }
      closest {
        ...schedulingRecommendation
      }
      schedulingRecommendations {
        ...schedulingRecommendation
      }
      userErrors {
        message
        path
      }
    }
  }
  ${schedulingRecommendationFragment}
`;
