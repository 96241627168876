import React from "react";
import type { SchedulingAssistantAPI } from "jobber/workOrders/components/SchedulingAssistant/hooks/useSchedulingAssistant";
import { MiniCalendar } from "./components/MiniCalendar/MiniCalendar";
import { SchedulingDetailsForm } from "./components/SchedulingDetailsForm/SchedulingDetailsForm";
import { TopBar } from "./components/TopBar/TopBar";
import classNames from "./SchedulingAssistant.module.css";
import { DayView } from "./components/DayView/DayView";

export type SchedulingAssistantProps = Omit<
  SchedulingAssistantAPI,
  "onSave" | "onCancel"
> & { onClose: () => void };

export const SchedulingAssistant = (props: SchedulingAssistantProps) => {
  return (
    <div className={classNames.container} data-testid="scheduling-assistant">
      {/* Left side */}
      <div
        className={classNames.leftColumn}
        data-testid="scheduling-minicalendar"
      >
        <div className={classNames.minical}>
          <MiniCalendar
            selectedDate={props.selectedDate}
            onMonthChange={props.onMonthChange}
            onSelectDate={props.onSelectDate}
            highlightedDates={props.datesWithAvailability}
          />
        </div>
        <SchedulingDetailsForm
          durationInSeconds={props.durationInSeconds}
          onChangeDuration={props.onSelectDuration}
          driveTimeLimitInSeconds={props.driveTimeLimitInSeconds}
          onChangeDriveTimeLimit={props.onSelectDriveTimeLimit}
          availabilityLoading={props.availabilityLoading}
          selectedTeamMembers={props.selectedTeamMembers}
          onSelectTeamMembers={props.onSelectTeamMembers}
        />
      </div>
      {/* Right side */}
      <div className={classNames.rightColumn}>
        <TopBar date={props.selectedDate} onClose={props.onClose} />
        <DayView
          events={props.events}
          employees={props.filters.employees}
          onSelectSlot={props.onSelectSlot}
          selectedDate={props.selectedDate}
          onDragSelection={props.onDragSelection}
          onResizeSelection={props.onResizeSelection}
          calendarRef={props.calendarRef}
        />
      </div>
    </div>
  );
};
