import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { InputText } from "@jobber/components/InputText";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import style from "./QuickbooksLegacyMigrationFeedbackModal.module.css";

interface QuickbooksLegacyMigrationFeedbackModalProps {
  open: boolean;
  onRequestClose: () => void;
}
export function QuickbooksLegacyMigrationFeedbackModal({
  open,
  onRequestClose,
}: QuickbooksLegacyMigrationFeedbackModalProps) {
  const [radioTypeOptions, setRadioTypeOptions] = useState<string>("");
  const [optionalMessage, setOptionalMessage] = useState<string>("");
  return (
    <>
      <Modal
        title={messages.legacyMigration.modalTitle}
        open={open}
        onRequestClose={handleClose}
      >
        <Content spacing="base">
          <div className={style.firstHeader}>
            <Heading level={5}>
              {messages.legacyMigration.modalFirstHeader}
            </Heading>
          </div>
          <div className={style.radioGroup}>
            <RadioGroup
              onChange={onRadioOptionChange}
              value={radioTypeOptions}
              ariaLabel="Reason for not migrating"
            >
              <RadioOption
                value={messages.legacyMigration.point1}
                label={messages.legacyMigration.point1}
              />
              <RadioOption
                value={messages.legacyMigration.point2}
                label={messages.legacyMigration.point2}
              />
              <RadioOption
                value={messages.legacyMigration.point3}
                label={messages.legacyMigration.point3}
              />
            </RadioGroup>
          </div>
          <Heading level={5}>
            {messages.legacyMigration.modalSecondHeader}
          </Heading>
          <InputText
            value={optionalMessage}
            onChange={onChangeText}
            placeholder={messages.legacyMigration.inputTextPlaceholder}
          />
        </Content>
        <div className={style.outerButtonContainer}>
          <div className={style.leftButtonContainer}>
            <Button
              label={messages.legacyMigration.cancelButton}
              type="primary"
              variation="subtle"
              onClick={handleClose}
            />
          </div>
          <div className={style.rightButtonContainer}>
            <Button
              label={messages.legacyMigration.submitButton}
              type="primary"
              onClick={handleSubmit}
              disabled={radioTypeOptions == ""}
            />
          </div>
        </div>
      </Modal>
    </>
  );
  function handleSubmit() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "legacy_migration_feedback_modal",
      value: radioTypeOptions,
      text: optionalMessage || "No feedback given",
    });
    handleClose();
  }

  function onChangeText(newText: string) {
    setOptionalMessage(newText);
  }

  function onRadioOptionChange(selectedRadioOption: string) {
    setRadioTypeOptions(selectedRadioOption);
  }

  function resetInputs() {
    setOptionalMessage("");
    setRadioTypeOptions("");
  }

  function handleClose() {
    resetInputs();
    onRequestClose();
  }
}
