export const content = {
  connectAndGrowPlan: "Connect & Grow plan feature",
  growPlan: "Grow plan feature",
  requestSubmitted: {
    title: "Submitted request",
    description:
      "Automatically notifies a client that a request has been received",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "WorkRequest",
    },
  },
  quoteSent: {
    title: "New quote",
    description: "Notifies a client about a new quote when sent",
    showToggles: false,
    textRewriteVariableSet: {
      SMS: "QuoteSms",
      EMAIL: "Quote",
    },
  },
  quoteApproved: {
    title: "Quote approval",
    description:
      "Automatically notifies a client that a quote approval has been received",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "Quote",
    },
  },
  jobFormSent: {
    title: "Job form",
    description: "A copy of a job form used during a visit when sent",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "ToDoFormServiceSubmissionMembership",
    },
  },
  invoiceSent: {
    title: "New invoice",
    description: "Notifies a client about a new invoice when sent",
    showToggles: false,
    textRewriteVariableSet: {
      SMS: "InvoiceSms",
      EMAIL: "Invoice",
    },
  },
  balanceAdjustmentReceiptSent: {
    title: "Payment & deposit receipts",
    description:
      "Automatic or manual receipts when a payment is made by a client",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "BalanceAdjustment",
    },
  },
  statementSent: {
    title: "Statements",
    description: "A copy of a client’s billing history when sent",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "Statement",
    },
  },
  signedDocumentSent: {
    title: "Signed documents",
    description: "Provide a copy of signed documents to a client when sent",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "Note",
    },
  },
  chemicalTreatmentSent: {
    title: "Chemical treatment",
    description: "A record of chemicals used during a job when sent",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "ChemicalTreatment",
    },
  },
  requestPaymentMethodOnFile: {
    title: "Request a payment method on file",
    description:
      "Request to save a client’s payment method for future billing when sent ",
    showToggles: false,
    textRewriteVariableSet: {
      EMAIL: "RequestCardOnFileMessage",
    },
  },
  jobBookingConfirmation: {
    title: "Booking confirmation",
    description:
      "Notifies a client that a job has been scheduled when sent and when a client has submitted an online booking",
    showToggles: false,
    textRewriteVariableSet: {
      SMS: "WorkOrder",
      EMAIL: "WorkOrder",
    },
  },
  assessmentBooked: {
    title: "Booking confirmation",
    description:
      "Notifies a client that an assessment has been scheduled when sent",
    showToggles: false,
    textRewriteVariableSet: {
      SMS: "AssessmentSms",
      EMAIL: "Assessment",
    },
  },
  quoteFollowUps: {
    title: "Quote follow-up",
    description: "Automatically notifies a client to respond to a quote",
    allowUnitChange: false,
    templates: {
      withSms:
        "Send a follow-up {duration} the quote was sent by {method} {time}",
      withoutSms:
        "Send a follow-up {duration} the quote was sent by email {time}",
    },
    footnotes: {
      withSms:
        "*Follow ups are sent in the same method as the client’s most recent quote was sent",
      withoutSms: "",
    },
    textRewriteVariableSet: {
      SMS: "QuoteSms",
      EMAIL: "Quote",
    },
  },
  assessmentReminders: {
    title: "Assessment reminder",
    description:
      "Automatically notifies a client about an upcoming assessment for a request",
    templates: {
      withSms: "Send a reminder {duration} the appointment by {method} {time}",
      withoutSms: "Send a reminder {duration} the appointment by email {time}",
    },
    footnotes: {
      withSms:
        "Email reminders for anytime visits are sent at 12:00AM unless a time is specified. Text message reminders on an anytime visit will not send unless a time is specified.",
      withoutSms:
        "Email reminders for anytime visits are sent at 12:00AM unless a time is specified.",
    },
    textRewriteVariableSet: {
      SMS: "AssessmentSms",
      EMAIL: "Assessment",
    },
  },
  visitReminders: {
    title: "Visit reminder",
    description: "Automatically notifies a client about an upcoming visit",
    templates: {
      withSms: "Send a reminder {duration} the appointment by {method} {time}",
      withoutSms: "Send a reminder {duration} the appointment by email {time}",
    },
    footnotes: {
      withSms:
        "Email reminders for anytime visits are sent at 12:00AM unless a time is specified. Text message reminders on an anytime visit will not send unless a time is specified.",
      withoutSms:
        "Email reminders for anytime visits are sent at 12:00AM unless a time is specified.",
    },
    textRewriteVariableSet: {
      SMS: "VisitSms",
      EMAIL: "Visit",
    },
  },
  invoiceFollowUps: {
    title: "Invoice follow-up",
    description: "Notifies a client to follow up on an overdue invoice",
    templates: {
      withSms:
        "Send a follow-up {duration} the invoice is due by {method} {time}",
      withoutSms:
        "Send a follow-up {duration} the invoice is due by email {time}",
    },
    footnotes: {
      withSms:
        "*Follow ups are sent in the same method as the client’s most recent invoice was sent",
      withoutSms: "",
    },
    textRewriteVariableSet: {
      SMS: "InvoiceSms",
      EMAIL: "Invoice",
    },
  },
  jobFollowUps: {
    title: "Job follow-up",
    description:
      "Automatically request feedback after a job is completed with an optional survey after closing a job",
    template: "Send a follow-up after closing a job",
    textRewriteVariableSet: {
      EMAIL: "FeedbackResult",
    },
  },
};
