import { UpgradeFeature } from "~/utilities/API/graphql";

export const readableUpgradeFeature = {
  [UpgradeFeature.JOB_COSTING]: "Job Costing",
  [UpgradeFeature.JOBS]: "Jobs",
  [UpgradeFeature.SCHEDULE]: "Schedule",
  [UpgradeFeature.REQUESTS]: "Requests",
  [UpgradeFeature.ONLINE_BOOKING]: "online booking",
  [UpgradeFeature.JOB_FORMS]: "job forms",
  [UpgradeFeature.EXPENSE_TRACKING]: "expense tracking",
  [UpgradeFeature.ROUTE_OPTIMIZATION]: "route optimization",
  [UpgradeFeature.TIME_TRACKING]: "time tracking",
};
