import { defineMessages } from "react-intl";

const spQuoteIdPrefix = "setupWizard.spQuote.";

export const messages = defineMessages({
  arboristIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}arboristIndustryQuoteCopy`,
    defaultMessage:
      "We've been more <emphasis>profitable</emphasis> using Jobber - we get paid faster from our clients, and we save more time and money on administration.",
    description: "Arborist industry quote copy for the setup wizard",
  },
  arboristIndustryQuoteName: {
    id: `${spQuoteIdPrefix}arboristIndustryQuoteName`,
    defaultMessage: "Matt Davis",
    description: "Arborist industry quote author name for the setup wizard",
  },
  arboristIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}arboristIndustryQuoteCompany`,
    defaultMessage: "Adair Tree Care",
    description: "Arborist industry quote company for the setup wizard",
  },
  arboristIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}arboristIndustryQuoteLocation`,
    defaultMessage: "Calgary, AB",
    description: "Arborist industry quote location for the setup wizard",
  },
  cleaningIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}cleaningIndustryQuoteCopy`,
    defaultMessage:
      "Once I started using Jobber, my life became <emphasis>so much</emphasis> simpler. I wasn't stuck scheduling all evenings and weekends anymore.",
    description: "Cleaning industry quote copy for the setup wizard",
  },
  cleaningIndustryQuoteName: {
    id: `${spQuoteIdPrefix}cleaningIndustryQuoteName`,
    defaultMessage: "Irene Zibin",
    description: "Cleaning industry quote author name for the setup wizard",
  },
  cleaningIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}cleaningIndustryQuoteCompany`,
    defaultMessage: "eSunshine Cleaning",
    description: "Cleaning industry quote company for the setup wizard",
  },
  cleaningIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}cleaningIndustryQuoteLocation`,
    defaultMessage: "Abbotsford, BC",
    description: "Cleaning industry quote location for the setup wizard",
  },
  constructionIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}constructionIndustryQuoteCopy`,
    defaultMessage:
      "With Jobber, we are able to communicate <emphasis>way better</emphasis> with clients and they really, truly appreciate it.",
    description: "Construction industry quote copy for the setup wizard",
  },
  constructionIndustryQuoteName: {
    id: `${spQuoteIdPrefix}constructionIndustryQuoteName`,
    defaultMessage: "Jesse + Zack Jurkowski",
    description: "Construction industry quote author name for the setup wizard",
  },
  constructionIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}constructionIndustryQuoteCompany`,
    defaultMessage: "Montreal Contractors",
    description: "Construction industry quote company for the setup wizard",
  },
  constructionIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}constructionIndustryQuoteLocation`,
    defaultMessage: "Montreal, QC",
    description: "Montreal, QC",
  },
  defaultQuoteCopy: {
    id: `${spQuoteIdPrefix}defaultQuoteCopy`,
    defaultMessage:
      "Jobber has changed the game for us. Now that we have Jobber, we are actually starting to <emphasis>scale up</emphasis> our business.",
    description: "Default quote copy for the setup wizard",
  },
  defaultQuoteName: {
    id: `${spQuoteIdPrefix}defaultQuoteName`,
    defaultMessage: "Ryaan Tuttle",
    description: "Default quote author name for the setup wizard",
  },
  defaultQuoteCompany: {
    id: `${spQuoteIdPrefix}defaultQuoteCompany`,
    defaultMessage: "Best Boston Handyman",
    description: "Default quote company for the setup wizard",
  },
  defaultQuoteLocation: {
    id: `${spQuoteIdPrefix}defaultQuoteLocation`,
    defaultMessage: "Boston, MA",
    description: "Default quote location for the setup wizard",
  },
  electricalIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}electricalIndustryQuoteCopy`,
    defaultMessage:
      "We wanted to have a professional look that would match the <emphasis>caliber</emphasis> of our electrical work. With Jobber, our customers could see the difference.",
    description: "Electrician industry quote copy for the setup wizard",
  },
  electricalIndustryQuoteName: {
    id: `${spQuoteIdPrefix}electricalIndustryQuoteName`,
    defaultMessage: "Stephanie and Ryan Godfrey",
    description: "Electrician industry quote author name for the setup wizard",
  },
  electricalIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}electricalIndustryQuoteCompany`,
    defaultMessage: "Florida Electrical Solutions",
    description: "Electrician industry quote company for the setup wizard",
  },
  electricalIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}electricalIndustryQuoteLocation`,
    defaultMessage: "Orange City, FL",
    description: "Electrician industry quote location for the setup wizard",
  },
  hvacIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}hvacIndustryQuoteCopy`,
    defaultMessage:
      "I can do everything on my phone in the field, which means less admin time at the office and <emphasis>more time</emphasis> with my family.",
    description: "HVAC industry quote copy for the setup wizard",
  },
  hvacIndustryQuoteName: {
    id: `${spQuoteIdPrefix}hvacIndustryQuoteName`,
    defaultMessage: "Adrian Garcia",
    description: "HVAC industry quote author name for the setup wizard",
  },
  hvacIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}hvacIndustryQuoteCompany`,
    defaultMessage: "Reliable Kitchen Services",
    description: "HVAC industry quote company for the setup wizard",
  },
  hvacIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}hvacIndustryQuoteLocation`,
    defaultMessage: "Mission, TX",
    description: "HVAC industry quote location for the setup wizard",
  },
  landscapingIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}landscapingIndustryQuoteCopy`,
    defaultMessage:
      "Our clients can tell that we make sure our systems are smooth, not only for us, but <emphasis>for them</emphasis> as well.",
    description: "Landscaping industry quote copy for the setup wizard",
  },
  landscapingIndustryQuoteName: {
    id: `${spQuoteIdPrefix}landscapingIndustryQuoteName`,
    defaultMessage: "Tyler Dixon",
    description: "Landscaping industry quote author name for the setup wizard",
  },
  landscapingIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}landscapingIndustryQuoteCompany`,
    defaultMessage: "Dixon & Company",
    description: "Landscaping industry quote company for the setup wizard",
  },
  landscapingIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}landscapingIndustryQuoteLocation`,
    defaultMessage: "High Point, NC",
    description: "Landscaping industry quote location for the setup wizard",
  },
  lawnIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}lawnIndustryQuoteCopy`,
    defaultMessage:
      "Jobber is <emphasis>priceless</emphasis>. It helps organize yourself so you can spend more time with your family, friends, and loved ones.",
    description: "Lawn industry quote copy for the setup wizard",
  },
  lawnIndustryQuoteName: {
    id: `${spQuoteIdPrefix}lawnIndustryQuoteName`,
    defaultMessage: "Ramona and Wesley Mullins",
    description: "Lawn industry quote author name for the setup wizard",
  },
  lawnIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}lawnIndustryQuoteCompany`,
    defaultMessage: "Mullins Lawn Enforcement",
    description: "Lawn industry quote company for the setup wizard",
  },
  lawnIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}lawnIndustryQuoteLocation`,
    defaultMessage: "Cumberland City, TN",
    description: "Lawn industry quote location for the setup wizard",
  },
  paintingIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}paintingIndustryQuoteCopy`,
    defaultMessage:
      "Jobber payments and automated follow-ups put my business on autopilot. We would literally land jobs in our <emphasis>sleep</emphasis>.",
    description: "Painting industry quote copy for the setup wizard",
  },
  paintingIndustryQuoteName: {
    id: `${spQuoteIdPrefix}paintingIndustryQuoteName`,
    defaultMessage: "Branden Sewell",
    description: "Painting industry quote author name for the setup wizard",
  },
  paintingIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}paintingIndustryQuoteCompany`,
    defaultMessage: "Seal Pro Painting Services",
    description: "Painting industry quote company for the setup wizard",
  },
  paintingIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}paintingIndustryQuoteLocation`,
    defaultMessage: "Titusville, FL",
    description: "Painting industry quote location for the setup wizard",
  },
  plumbingIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}plumbingIndustryQuoteCopy`,
    defaultMessage:
      "Jobber helps make our recurring customers feel like <emphasis>VIPs</emphasis> because we are able to quickly find customer information when we need it.",
    description: "Plumbing industry quote copy for the setup wizard",
  },
  plumbingIndustryQuoteName: {
    id: `${spQuoteIdPrefix}plumbingIndustryQuoteName`,
    defaultMessage: "Terence Chan",
    description: "Plumbing industry quote author name for the setup wizard",
  },
  plumbingIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}plumbingIndustryQuoteCompany`,
    defaultMessage: "Impetus Plumbing & Heating",
    description: "Plumbing industry quote company for the setup wizard",
  },
  plumbingIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}plumbingIndustryQuoteLocation`,
    defaultMessage: "Vancouver, BC",
    description: "Plumbing industry quote location for the setup wizard",
  },
  pressureWashingIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}pressureWashingIndustryQuoteCopy`,
    defaultMessage:
      "Before Jobber, I was running my business like a caveman. Now, our customers give us <emphasis>five-star</emphasis> reviews for excellent communication.",
    description: "Pressure washing industry quote copy for the setup wizard",
  },
  pressureWashingIndustryQuoteName: {
    id: `${spQuoteIdPrefix}pressureWashingIndustryQuoteName`,
    defaultMessage: "Adam Chapman",
    description:
      "Pressure washing industry quote author name for the setup wizard",
  },
  pressureWashingIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}pressureWashingIndustryQuoteCompany`,
    defaultMessage: "Pad Pal",
    description: "Pressure washing industry quote company for the setup wizard",
  },
  pressureWashingIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}pressureWashingIndustryQuoteLocation`,
    defaultMessage: "Ottawa, ON",
    description:
      "Pressure washing industry quote location for the setup wizard",
  },
  windowWashingIndustryQuoteCopy: {
    id: `${spQuoteIdPrefix}windowWashingIndustryQuoteCopy`,
    defaultMessage:
      "It's such a moment of <emphasis>pride</emphasis> as a business owner when you can watch the business run itself — Jobber is dynamite.",
    description: "Window washing industry quote copy for the setup wizard",
  },
  windowWashingIndustryQuoteName: {
    id: `${spQuoteIdPrefix}windowWashingIndustryQuoteName`,
    defaultMessage: "David Moerman",
    description:
      "Window washing industry quote author name for the setup wizard",
  },
  windowWashingIndustryQuoteCompany: {
    id: `${spQuoteIdPrefix}windowWashingIndustryQuoteCompany`,
    defaultMessage: "Revive Washing",
    description: "Window washing industry quote company for the setup wizard",
  },
  windowWashingIndustryQuoteLocation: {
    id: `${spQuoteIdPrefix}windowWashingIndustryQuoteLocation`,
    defaultMessage: "Vancouver, BC",
    description: "Window washing industry quote location for the setup wizard",
  },
});
