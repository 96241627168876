export async function makeHostedOnboardingUrl(
  hostedOnboardingUrlCreationUrl: string,
  authenticityToken: string,
) {
  const response = await fetch(hostedOnboardingUrlCreationUrl, {
    method: "POST",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    body: JSON.stringify({ authenticity_token: authenticityToken }),
  });

  if (!response.ok) {
    throw new Error("Failed to process verification.");
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const json = (await response.json()) as { redirect_url: string };
  return json.redirect_url;
}
