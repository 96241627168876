import { gql } from "@apollo/client";

export const ACCOUNT_ADDONS_ADD = gql`
  mutation SubscriptionAddonsAdd($input: AddonsAddInput!) {
    subscriptionAddonsAdd(input: $input) {
      success
      userErrors {
        message
        path
      }
    }
  }
`;
