import { type ApolloError, useQuery } from "@apollo/client";
import type {
  CommsCampaignReviewQuery,
  CommsCampaignReviewQueryVariables,
} from "~/utilities/API/graphql";
import { GET_CAMPAIGN_INFORMATION } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/useCommsCampaignReviewQuery.graphql";

interface UseCampaignInformationQueryProps {
  campaignId: string;
  onCampaignNotFound: () => void;
}

interface UseCampaignInformationQueryReturn {
  loadingCampaign: boolean;
  campaignData?: CommsCampaignReviewQuery;
  campaignError?: ApolloError;
  refetchCampaign(variables: CommsCampaignReviewQueryVariables): void;
}

export function useCampaignInformationQuery({
  campaignId,
  onCampaignNotFound,
}: UseCampaignInformationQueryProps): UseCampaignInformationQueryReturn {
  const {
    loading: loadingCampaign,
    data: campaignData,
    error: campaignError,
    refetch: refetchCampaign,
  } = useQuery<CommsCampaignReviewQuery, CommsCampaignReviewQueryVariables>(
    GET_CAMPAIGN_INFORMATION,
    {
      skip: !campaignId,
      variables: { id: campaignId },
      onCompleted: _data => {
        if (_data.commsCampaign === null) {
          onCampaignNotFound();
        }
      },
    },
  );

  return {
    loadingCampaign,
    campaignData,
    campaignError,
    refetchCampaign,
  };
}
