/* eslint-disable max-statements */
import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Card } from "@jobber/components/Card";
import { Emphasis } from "@jobber/components/Emphasis";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import { useIntl } from "react-intl";
import { Markdown } from "@jobber/components/Markdown";
import { useQuery } from "@apollo/client";
import { IntlProvider } from "@translations/IntlProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Intercom } from "utilities/chat";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { PlansAvailableType } from "~/jobber/plans/types";
import { PLANS_AVAILABLE } from "~/jobber/plans/Plans.graphql";
import styles from "./CoreToConnectTrialPostTrialModal.module.css";
import { messages } from "./messages";

function CoreToConnectTrialPostTrialModal() {
  const plansAvailableData = useQuery<PlansAvailableType>(PLANS_AVAILABLE);

  const monthlyDiscountedCost = plansAvailableData?.data?.plansAvailable?.find(
    plan => plan.planTier === "CONNECT",
  )?.planCostMonthlyDiscounted;

  const annualDiscountedCost = plansAvailableData?.data?.plansAvailable?.find(
    plan => plan.planTier === "CONNECT",
  )?.planCostAnnualDiscounted;

  const savings =
    annualDiscountedCost && monthlyDiscountedCost
      ? monthlyDiscountedCost - annualDiscountedCost
      : null;

  const [modalOpen, setModalOpen] = useState(true);
  const ctaName = "core_to_connect_trial_post_trial_modal_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();
  const upgradePageUrl = "/accounts/billing_info/pricing";
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    if (modalOpen) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
      });
    }
  }, [modalOpen]);

  return (
    <div ref={ref}>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          title={t(messages.modalTitle)}
          open={modalOpen}
          onRequestClose={dismissModal}
          primaryAction={{
            label: t(messages.upgradeNowCtaLabel),
            url: upgradePageUrl,
            onClick: handleUpgradeClick,
          }}
          secondaryAction={{
            label: t(messages.chatWithUsCtaLabel),
            type: "secondary",
            variation: "work",
            onClick: openIntercom,
          }}
          {...(width >= 640 && {
            tertiaryAction: {
              label: t(messages.dismissCtaLabel),
              type: "primary",
              variation: "subtle",
              onClick: dismissModal,
            },
          })}
        >
          <Content>
            <Heading level={4}>{t(messages.modalHeader)}</Heading>
            <Markdown content={t(messages.modalDescription)} externalLink />

            <div className={styles.cardContainer}>
              <Card>
                <div className={styles.connectCard}>
                  <Content>
                    <div className={styles.connectCardHeader}>
                      <div>{t(messages.connectCardHeader)}</div>
                      {monthlyDiscountedCost && annualDiscountedCost && (
                        <div className={styles.connectCardPricing}>
                          <div className={styles.connectCardPrices}>
                            <div className={styles.connectCardMonthlyPrice}>
                              {t(messages.connectCardMonthlyCost, {
                                monthlyDiscountedCost: monthlyDiscountedCost,
                              })}
                            </div>
                            <div className={styles.connectCardAnnualPrice}>
                              {t(messages.connectCardAnnualCost, {
                                annualDiscountedCost: annualDiscountedCost,
                              })}
                            </div>
                          </div>
                          <Text size="small">
                            <Emphasis variation="bold">
                              {t(messages.connectCardSavings, {
                                savings: savings,
                              })}
                            </Emphasis>{" "}
                            {t(messages.connectCardWhenCopy)}{" "}
                            {width < 640 && (
                              <span>
                                {t(messages.connectCardBilledAnnuallyCopy)}
                              </span>
                            )}
                          </Text>
                          {width >= 640 && (
                            <Text size="small">
                              {t(messages.connectCardBilledAnnuallyCopy)}
                            </Text>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.connectCardSubHeader}>
                      <Emphasis variation="bold">
                        {t(messages.connectCardIncludesCopy)}
                        {width < 640 && ":"}
                      </Emphasis>
                      {width >= 640 && <div className={styles.line} />}
                    </div>
                    <div className={styles.connectCardFeatures}>
                      <ul>
                        <li>{t(messages.connectCardFeatureOne)}</li>
                        <li>{t(messages.connectCardFeatureTwo)}</li>
                      </ul>
                      <ul>
                        <li>{t(messages.connectCardFeatureThree)}</li>
                        <li>{t(messages.connectCardFeatureFour)}</li>
                      </ul>
                    </div>
                  </Content>
                </div>
              </Card>
            </div>
          </Content>
        </Modal>
      </CallToAction>
    </div>
  );

  function handleUpgradeClick() {
    convertCTA(ctaRef)();
  }

  function openIntercom() {
    Intercom.EXECUTE("showNewMessage");
    convertCTA(ctaRef, { type: "chat_with_us" })();
  }

  function dismissModal() {
    dismissCTA(ctaRef)();
    setModalOpen(false);
  }
}

function WrappedCoreToConnectTrialPostTrialModal() {
  return (
    <IntlProvider>
      <APIProvider>
        <CoreToConnectTrialPostTrialModal />
      </APIProvider>
    </IntlProvider>
  );
}

export { WrappedCoreToConnectTrialPostTrialModal as CoreToConnectTrialPostTrialModal };
