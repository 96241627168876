import React, { type ComponentType } from "react";
import type { RouteComponentProps } from "react-router-dom";

interface ParamProps {
  [param: string]: string;
}

/**
 * Render and provide a component params.
 *
 * ```tsx
 * <Route
 *   path={join(path, ":id")}
 *   render={withParams<{ id: string }>(EditUser)}
 * />
 * ```
 *
 * @param Component - Component to provide with `params`.
 */
export function withParams<T extends ParamProps>(Component: ComponentType<T>) {
  function ViewWithID(props: RouteComponentProps<T>) {
    return <Component {...props.match.params} />;
  }
  return ViewWithID;
}
