import { defineMessages } from "react-intl";

export const messages = defineMessages({
  upsellHeader: {
    id: "billing.upsellAddon.upsellHeader",
    defaultMessage: "Unlock your most powerful tool yet with the {addonName}",
    description: "Upsell header",
  },
  marketingSuiteUpsellSummary: {
    id: "billing.upsellAddon.marketingSuiteUpsellSummary",
    defaultMessage:
      "Turn every customer into a growth engine with automated referrals, targeted email campaigns, and effortless reviews",
    description: "Upsell summary for the marketing suite",
  },
  upsellDeclineButtonLabel: {
    id: "billing.upsellAddon.upsellDeclineButtonLabel",
    defaultMessage: "No thanks, only add {addonName} for {price}",
    description: "Upsell decline button label header",
  },
});
