import type { ApolloError, FetchResult } from "@apollo/client";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import type {
  AiReceptionistSmsStateEditMutation,
  AiReceptionistSmsStateQuery,
} from "~/utilities/API/graphql";
import {
  AI_RECEPTIONIST_SMS_STATE_MUTATION,
  AI_RECEPTIONIST_SMS_STATE_QUERY,
} from "./AiReceptionist.graphql";
import { AI_RECEPTIONIST_SMS_STATE_UPDATE_SUBSCRIPTION } from "../Chat.graphql";

export interface UseAiReceptionist {
  aiReceptionistError?: ApolloError;
  aiReceptionistSmsStateAvailable: boolean;
  aiReceptionistSmsStateStatus: boolean;
  aiReceptionistLoading: boolean;
  toggleAiReceptionist(
    value: boolean,
  ): Promise<FetchResult<AiReceptionistSmsStateEditMutation>>;
}

export function useAiReceptionist(conversationId: string): UseAiReceptionist {
  const { data, error, loading, refetch } =
    useQuery<AiReceptionistSmsStateQuery>(AI_RECEPTIONIST_SMS_STATE_QUERY, {
      variables: {
        id: conversationId,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  useSubscription(AI_RECEPTIONIST_SMS_STATE_UPDATE_SUBSCRIPTION, {
    onData: () => refetch(),
  });

  const [aiReceptionistToggle] =
    useMutation<AiReceptionistSmsStateEditMutation>(
      AI_RECEPTIONIST_SMS_STATE_MUTATION,
    );
  const toggleAiReceptionist = async (value: boolean) => {
    return aiReceptionistToggle({
      variables: {
        id: conversationId,
        status: value,
      },
    });
  };

  return {
    aiReceptionistError: error,
    aiReceptionistSmsStateAvailable:
      (data?.aiReceptionistSmsState?.available && !loading) || false,
    aiReceptionistSmsStateStatus: data?.aiReceptionistSmsState?.status || false,
    aiReceptionistLoading: loading,
    toggleAiReceptionist,
  };
}
