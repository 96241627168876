import { gql } from "@apollo/client";

export const CAMPAIGN_REQUEST_FROM_EMAIL_VALIDATION_CODE = gql`
  mutation campaignRequestFromEmailValidationCode {
    campaignRequestFromEmailValidationCode {
      userErrors {
        message
        path
      }
    }
  }
`;
