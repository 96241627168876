import { gql, useMutation } from "@apollo/client";
import { notificationFieldsFragment } from "jobber/settings/notifications/notificationFieldsFragment";
import type {
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables,
} from "~/utilities/API/graphql";

export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation UpdateNotification(
    $id: ClientNotificationId!
    $data: NotificationUpdateAttributes!
  ) {
    updateNotification(id: $id, attributes: $data) {
      clientNotification {
        ...notificationFields
      }
      errors
    }
  }

  ${notificationFieldsFragment}
`;

interface UseUpdateNotificationModal {
  mutationLoading: boolean;
  updateNotification(variables: UpdateNotificationMutationVariables): Promise<{
    data?: UpdateNotificationMutation["updateNotification"];
    errors: string[];
  }>;
}

export const useUpdateNotificationModal = (): UseUpdateNotificationModal => {
  const [_updateNotification, { loading: mutationLoading }] = useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UPDATE_NOTIFICATION_MUTATION);

  const updateNotification = async (
    variables: UpdateNotificationMutationVariables,
  ) => {
    try {
      const response = await _updateNotification({ variables });
      const errors = response.data?.updateNotification.errors || [];

      return {
        data: response.data?.updateNotification,
        errors,
      };
    } catch (error) {
      return {
        data: undefined,
        errors: [(error as Error).message],
      };
    }
  };

  return {
    mutationLoading,
    updateNotification,
  };
};
