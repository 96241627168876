import React from "react";
import { Button } from "@jobber/components/Button";
import styles from "./ConversationPills.module.css";
import type { Followup } from "./ConversationListItem";

interface ConversationPillsProps {
  readonly prompts: Followup[];
  readonly onClick: (prompt: string) => void;
}

export function ConversationPills({
  prompts,
  onClick,
}: ConversationPillsProps) {
  return (
    <div className={styles.wrapper} data-testid="conversation-pills">
      {prompts.map(({ displayText, loading, prompt }, index) => (
        <div
          className={loading ? styles.loading : styles.notLoading}
          key={index}
        >
          <Button
            size="small"
            type="secondary"
            onClick={() => onClick(prompt)}
            label={displayText}
            loading={loading}
          />
        </div>
      ))}
    </div>
  );
}
