import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { InlineLabel } from "@jobber/components/InlineLabel";
import type { ObjectContentProps } from "jobber/freemium/onboarding/OnboardingCarousel/OnboardingCarousel.d";

export function CarouselCardContent({
  labelName,
  labelColour,
  objectTitle,
  clientName,
  total,
}: ObjectContentProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
      className="u-paddingSmaller js-spinOnClick"
    >
      <div>
        <div className="u-paddingBottomSmaller">
          <InlineLabel color={labelColour}>
            <span className="u-textTruncate">{labelName}</span>
          </InlineLabel>
        </div>
        <div className="u-paddingBottomSmallest">
          <Typography
            element="h4"
            textCase="capitalize"
            fontWeight="regular"
            size="small"
            textColor="textSecondary"
          >
            {objectTitle}
          </Typography>
        </div>
        <Typography
          element="h1"
          textCase="capitalize"
          fontWeight="bold"
          size="large"
          textColor="text"
        >
          <span className="u-textTruncate">{clientName}</span>
        </Typography>
      </div>
      <div style={{ display: "flex", alignSelf: "flex-end" }}>
        <Typography
          element="h4"
          textCase="capitalize"
          fontWeight="regular"
          size="small"
          textColor="textSecondary"
        >
          ${total}
        </Typography>
      </div>
    </div>
  );
}
