import React from "react";
import type { SchedulingParametersProps } from "./types";
import { ScheduleCard } from "../ScheduleCard";
import { TeamAssignmentCard } from "../TeamAssignmentCard";

export function DefaultSchedulingContainer({
  repeatOptions,
  railsFormKey,
  notifyTeam,
  arrivalWindowParameters,
}: SchedulingParametersProps) {
  return (
    <>
      <ScheduleCard
        repeatOptions={repeatOptions}
        arrivalWindowParameters={arrivalWindowParameters}
        railsFormKey={railsFormKey}
      />
      <TeamAssignmentCard
        initialNotifyTeam={notifyTeam}
        railsFormKey={railsFormKey}
        renderCardHeader
      />
    </>
  );
}
