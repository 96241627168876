import React from "react";
import { EmailHeader } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader/components/EmailHeader";
import { TextMessageHeader } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader/components/TextMessageHeader";
import type { MessageDataFragment } from "~/utilities/API/graphql";
import styles from "./MessageHeader.module.css";

interface MessageHeaderProps {
  message: MessageDataFragment;
  externalName?: string;
  showInternalName: boolean;
}

export function MessageHeader({
  message,
  externalName,
  showInternalName,
}: MessageHeaderProps) {
  return (
    <div className={styles.messageHeader}>{renderTypeSpecificHeader()}</div>
  );

  function renderTypeSpecificHeader() {
    if (message.__typename === "EmailMessage") {
      return <EmailHeader message={message} externalName={externalName} />;
    }

    if (message.__typename === "TextMessage") {
      return (
        <TextMessageHeader
          message={message}
          externalName={externalName}
          showInternalName={showInternalName}
        />
      );
    }
  }
}
