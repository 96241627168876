import { type IntlFormatters, useIntl } from "react-intl";
import type { RecurlyError } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { messages } from "jobber/billing/components/EditBillingInfo/messages";
import { Rollbar } from "~/utilities/errors/Rollbar";

export function useRecurlyErrorHandler() {
  const { formatMessage } = useIntl();

  const getErrorMessage = (recurlyError: RecurlyError) => {
    const { code } = recurlyError;

    switch (code) {
      case "3ds-auth-error":
      case "3ds-result-tokenization-error":
      case "3ds-vendor-load-error":
      case "3ds-auth-determination-error":
        return handle3DSErrors(recurlyError, formatMessage);
      case "validation":
      case "api-error":
      case "invalid-parameter":
        return handleValidationErrors(recurlyError, formatMessage);
      default:
        logError(recurlyError);
        return formatMessage(messages.recurlyDefaultError);
    }
  };

  return { getErrorMessage };
}

function logError(recurlyError: RecurlyError) {
  const { code, fields, message } = recurlyError;

  const logMessage = `Recurly form submission error. Code: ${code} Message: ${message} Fields: ${
    fields ? fields.join(", ") : ""
  }`;
  Rollbar.EXECUTE(logMessage, new Error(logMessage));
}

function handle3DSErrors(
  recurlyError: RecurlyError,
  formatMessage: IntlFormatters["formatMessage"],
) {
  if (recurlyError.code !== "3ds-auth-error") {
    logError(recurlyError);
  }

  return formatMessage(messages.recurly3dsAuthError);
}

function handleValidationErrors(
  recurlyError: RecurlyError,
  formatMessage: IntlFormatters["formatMessage"],
) {
  const { code, fields } = recurlyError;
  const defaultMessage = formatMessage(messages.unableToVerifyPaymentDetails);

  if (code === "api-error") {
    logError(recurlyError);
  }

  if (!fields?.length) {
    return defaultMessage;
  }

  switch (fields[0]) {
    case "first_name":
      return `${defaultMessage} ${formatMessage(messages.firstNameInvalid)}`;
    case "last_name":
      return `${defaultMessage} ${formatMessage(messages.lastNameInvalid)}`;
    case "number":
      return `${defaultMessage} ${formatMessage(messages.cardNumberInvalid)}`;
    case "month":
    case "year":
      return `${defaultMessage} ${formatMessage(messages.cardExpiryInvalid)}`;
    case "cvv":
      return `${defaultMessage} ${formatMessage(messages.cvcInvalid)}`;
    case "country":
      return `${defaultMessage} ${formatMessage(messages.countryInvalid)}`;
    case "state":
      return `${defaultMessage} ${formatMessage(messages.stateInvalid)}`;
    case "address1":
    case "address2":
    case "city":
      return `${defaultMessage} ${formatMessage(messages.addressInvalid)}`;
    case "postal_code":
      return `${defaultMessage} ${formatMessage(messages.postalCodeInvalid)}`;
    default:
      if (code !== "api-error") {
        logError(recurlyError);
      }
      return formatMessage(messages.recurlyDefaultError);
  }
}
