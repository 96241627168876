import React from "react";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { trackMoreActionButtonClickEvent } from "jobber/email/EmailMoreActions/EmailMoreActionAmplitudeEvents";

export interface ActionItem {
  readonly label: string;
  readonly value: string;
  onClick(): void;
}
interface EmailMoreActionsProps {
  moreActions: ActionItem[];
}

export function EmailMoreActions({ moreActions }: EmailMoreActionsProps) {
  return moreActions.length ? (
    <Menu
      activator={
        <Button
          icon={"more"}
          type="tertiary"
          ariaLabel="More actions button"
          onClick={trackMoreActionButtonClickEvent}
        />
      }
      items={[
        {
          actions: moreActions,
        },
      ]}
    />
  ) : (
    <></>
  );
}
