import React, { type PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { MockedProvider, type MockedResponse } from "@apollo/client/testing";
import { InMemoryCache } from "@apollo/client";
import { possibleTypes } from "~/utilities/API/possibleTypes.json";

/**
 * MockRouted is a component that helps to mock the `routed` component
 * in your Jest tests. Wrap any component that uses routing or GQL in
 * this to be able to mock your queries/mutations/routes.
 */

interface MockRoutedProps {
  /**
   * Mocks provided to Apollo MockProvider.
   * See https://www.apollographql.com/docs/react/development-testing/testing/
   * for more documentation.
   */
  mocks?: MockedResponse[];
  /**
   * Base url to be used if you are testing routing.
   */
  basename?: string;

  addTypename?: boolean;
}

export function MockRouted({
  mocks,
  children,
  basename = "",
  addTypename = false,
}: PropsWithChildren<MockRoutedProps>) {
  const cache = new InMemoryCache({ possibleTypes, addTypename: addTypename });
  return (
    <>
      <MockedProvider
        mocks={mocks}
        addTypename={addTypename}
        cache={cache}
        defaultOptions={{
          watchQuery: { fetchPolicy: "no-cache" },
          query: { fetchPolicy: "no-cache" },
        }}
      >
        <BrowserRouter basename={basename}>{children}</BrowserRouter>
      </MockedProvider>
    </>
  );
}
