import { Button, Heading } from "@jobber/components";
import classnames from "classnames";
import React from "react";
import { useIntl } from "react-intl";
import { laggerStateMessages } from "jobber/chat/components/UkKycRegistration/UkKycLaggerState/laggerStateMessages";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";

export function UkKycLaggerState() {
  const { formatMessage } = useIntl();
  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderBottom",
    "u-borderTop",
  );

  return (
    <div className={cssClasses}>
      <Heading level={4}>{formatMessage(laggerStateMessages.header)}</Heading>
      <p>{formatMessage(laggerStateMessages.body)}</p>
      <Button
        fullWidth={true}
        size={"base"}
        variation={"work"}
        label={formatMessage(laggerStateMessages.button)}
        onClick={() => (window.location.href = "/text_messaging_settings")}
      />
    </div>
  );
}
