import { fireEvent, screen } from "@testing-library/react";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";
import type { CampaignTemplateCtaLinkTo } from "~/utilities/API/graphql";

//selectors
export function getCtaButton() {
  return screen.getByText(messages.ctaButton.defaultMessage);
}

export function getCtaLinkToPlaceholder() {
  return screen.getByLabelText(
    messages.ctaButtonLinkPlaceholder.defaultMessage,
  );
}

export function getCtaButtonTextPlaceholder() {
  return screen.getByLabelText(
    messages.ctaButtonTextPlaceholder.defaultMessage,
  );
}

export function getCtaButtonOnlineBooking() {
  return screen.queryByText(messages.ctaButtonOnlineBooking.defaultMessage);
}

export function getCtaButtonRequestForm() {
  return screen.queryByText(messages.ctaRequestForm.defaultMessage);
}

export function getCtaButtonQuotesInClientHub() {
  return screen.queryByText(messages.ctaQuotesInClientHub.defaultMessage);
}

export function getCtaButtonExternalLink() {
  return screen.queryByText(messages.ctaButtonExternalLink.defaultMessage);
}

//Input Actions
export function iClickCtaLinkTo(value: CampaignTemplateCtaLinkTo) {
  fireEvent.change(
    screen.getByLabelText(messages.ctaLinkToPlaceholder.defaultMessage),
    {
      target: { value: value },
    },
  );
}

export function iClickTrashButton() {
  fireEvent.click(screen.getByTestId("trash"));
}

export function iChangeCtaLinkValue(value: string) {
  fireEvent.change(
    screen.getByLabelText(messages.ctaButtonLinkPlaceholder.defaultMessage),
    {
      target: { value: value },
    },
  );
}

export function iChangeCtaButtonText(value: string) {
  fireEvent.change(
    screen.getByLabelText(messages.ctaButtonTextPlaceholder.defaultMessage),
    {
      target: { value: value },
    },
  );
}

export function iChangeButtonColor(
  colorPickerPlaceholder: string,
  value: string,
) {
  fireEvent.change(screen.getByPlaceholderText(colorPickerPlaceholder), {
    target: { value: value },
  });
}
