import React, { useEffect, useState } from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Glimmer } from "@jobber/components/Glimmer";
import { Flex } from "@jobber/components";
import { Heading } from "@jobber/components/Heading";
import type { Segment } from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./SelectableSegment.module.css";

export interface SelectableSegmentProps {
  description: string;
  value: Segment;
  segmentValue: string | number;
  name?: string;
  onChange?: (newValue: Segment) => void;
  recommended?: boolean;
  loading?: boolean;
}
export function SelectableSegment({
  description,
  value,
  name,
  segmentValue,
  onChange,
  recommended,
  loading,
}: SelectableSegmentProps) {
  const { formatMessage } = useIntl();

  const [openSegment, setOpenSegment] = useState<Segment | null>(null);

  useEffect(() => {
    setOpenSegment(value);
  }, [value]);

  const getSegmentDescription = (segmentName: string, isOpen: boolean) => {
    switch (segmentName) {
      case "All clients":
        return formatMessage(messages.allClientsSegmentCardDescription);
      case "Past clients":
        return isOpen
          ? formatMessage(messages.pastClientsSegmentCardOpenDescription)
          : formatMessage(messages.pastClientsSegmentCardClosedDescription);
      case "Clients with upcoming visits":
        return isOpen
          ? formatMessage(messages.upcomingClientsSegmentCardOpenDescription)
          : formatMessage(messages.upcomingClientsSegmentCardClosedDescription);
      default:
        return "";
    }
  };

  function handleChange(segment: Segment) {
    if (openSegment !== segment) {
      setOpenSegment(segment);
      onChange?.(segment);
    }
  }

  const cardRecommended = () => {
    return (
      recommended && (
        <InlineLabel color="lightBlue">
          {formatMessage(messages.recommended)}
        </InlineLabel>
      )
    );
  };

  const cardDescription = () => {
    return description ? (
      <Heading level={4}>{description}</Heading>
    ) : (
      <Glimmer.Text lines={3} />
    );
  };

  const cardSubDescription = () => {
    if (description) {
      return (
        <Text variation={"subdued"}>
          {getSegmentDescription(description, value === segmentValue)}
        </Text>
      );
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContent}>
        <Glimmer.Text />
      </div>
    );
  }

  return (
    <label className={styles.container}>
      <input
        id={value.toString()}
        type="radio"
        name={name}
        className={styles.radio}
        checked={openSegment === segmentValue}
        onChange={() => handleChange(segmentValue as Segment)}
        value={value}
      />

      <div className={styles.segmentContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.innerContentContainer}>
            <div className={styles.iconAndStatusContainer}>
              <Flex align="center" template={["grow", "shrink"]}>
                <Flex
                  align="center"
                  gap="smaller"
                  template={["shrink", "shrink", "shrink"]}
                >
                  {cardRecommended()}
                </Flex>
              </Flex>
            </div>
            <div>
              <div className={styles.descriptionContainer}>
                {cardDescription()}
              </div>
              <div className={styles.descriptionContainer}>
                {cardSubDescription()}
              </div>
              {openSegment === segmentValue && (
                <div className={styles.descriptionContainer}>
                  <Text>{`${description} Criteria`}</Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </label>
  );
}
