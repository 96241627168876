import { Text } from "@jobber/components/Text";
import React from "react";
import { GlobalOwnershipModal } from "jobber/features/WorkObjectOwnerships/views/WorkObjectGlobalOwnershipsCard/components/GlobalOwnershipModal/";
import { OwnershipRulesText } from "jobber/features/WorkObjectOwnerships/views/WorkObjectGlobalOwnershipsCard/components/OwnershipRulesText/OwnershipRulesText";
import type { WorkObjectGlobalOwnershipFragment } from "~/utilities/API/graphql";
import styles from "./WorkObjectGlobalOwnershipsContent.module.css";

interface WorkObjectGlobalOwnershipsContentProps {
  /**
   * array of work object global ownership records
   */
  ownerships: WorkObjectGlobalOwnershipFragment[];
}

export function WorkObjectGlobalOwnershipsContent({
  ownerships,
}: WorkObjectGlobalOwnershipsContentProps) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text>
            Direct all email replies and notifications for emails sent via
            Jobber to the original sender, or assign individual team members to
            be responsible.
          </Text>
        </div>
        <div className={styles.action}>
          <GlobalOwnershipModal ownerships={ownerships} />
        </div>
      </div>
      {ownerships.length > 0 && <OwnershipRulesText ownerships={ownerships} />}
    </>
  );
}
