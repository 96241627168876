import React, {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import { List } from "@jobber/components/List";
import type { IconNames } from "@jobber/design";
import type { ClientPhoneNumber } from "~/utilities/API/graphql";
import type { SelectedRecipientType } from "jobber/chat/types";
import styles from "./RecipientList.module.css";

interface RecipientListProps {
  children: ReactNode;
  recipients: object[];
  onSelectRecipient: Dispatch<SetStateAction<SelectedRecipientType>>;
}
export function RecipientList({
  children,
  recipients,
  onSelectRecipient,
}: RecipientListProps) {
  const personIcon: IconNames = "person";
  function formatData(data: object[]) {
    return data.map((edge: { node: ClientPhoneNumber }) => {
      return {
        id: edge.node?.id || "",
        icon: personIcon,
        title: edge.node?.client?.name || "",
        content: edge.node?.friendly || edge.node?.number,
        onClick: () =>
          onSelectRecipient({
            name: edge.node?.client?.name,
            id: edge.node?.client?.id,
            contactInfo: edge.node?.friendly || edge.node?.number,
          }),
      };
    });
  }
  return (
    <div className={styles.scrollContainer}>
      <List items={formatData(recipients)} />
      {children}
    </div>
  );
}
