import type { ApolloClient } from "@apollo/client";
import type {
  SchedulingAssistantAvailabilityQuery,
  SchedulingAssistantAvailabilityQueryVariables,
} from "~/utilities/API/graphql";
import { SCHEDULING_AVAILABILITY_QUERY } from "./SchedulingAvailability.graphql";

export const querySchedulingAvailability =
  (apolloClient: ApolloClient<unknown>) =>
  (variables: SchedulingAssistantAvailabilityQueryVariables["input"]) =>
    apolloClient.query<
      SchedulingAssistantAvailabilityQuery,
      SchedulingAssistantAvailabilityQueryVariables
    >({
      query: SCHEDULING_AVAILABILITY_QUERY,
      fetchPolicy: "network-only",
      variables: {
        input: variables,
      },
    });
