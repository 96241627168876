import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { EditMessageDescriptionCard } from "./EditMessageDescriptionCard";
import styles from "./MessageDescriptionCard.module.css";

interface MessageDescriptionProp {
  loading: boolean;
  description: string;
  onUpdate(descriptionmessage: string): Promise<void>;
}

export function MessageDescriptionCard({
  loading,
  description,
  onUpdate,
}: MessageDescriptionProp) {
  return (
    <Card title="Step 4: Customize Your 'Message from the Pro'">
      <Content>
        <div className={styles.messageDescriptionLayout}>
          <Content>
            <Text>
              Google displays a ‘message from the pro’ to customers before they
              finalize their booking. You can customize this message to set
              expectations around pricing policies or next steps to confirm the
              appointment.
            </Text>
            {loading ? (
              <></>
            ) : (
              description.length > 0 && (
                <ul className={styles.messageDescriptionPreview}>
                  <li>{description}</li>
                </ul>
              )
            )}
          </Content>
          <div className={styles.messageDescriptionPreview__editColumn}>
            <EditMessageDescriptionCard
              description={description}
              onUpdate={onUpdate}
            />
          </div>
        </div>
      </Content>
    </Card>
  );
}
