import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadDocumentsHeading: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadDocumentsHeading",
    defaultMessage: "Upload documents",
    description: "Heading for Upload documents form",
  },
  uploadDocumentsSubHeading1: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadDocumentsSubHeading1",
    defaultMessage:
      "We need a little more information to verify the card application for ",
    description: "Subheading part 1 for Upload documents form",
  },
  uploadDocumentsSubHeading2: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadDocumentsSubHeading2",
    defaultMessage:
      ". The documents uploaded will be shared with Stripe for review.",
    description: "Subheading part 2 for Upload documents form",
  },
  uploadFrontDocumentLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadFrontDocumentLabel",
    defaultMessage:
      "Please upload an image of this cardholder’s government issued ID document.",
    description: "Label for front of document upload",
  },
  uploadFrontDocumentHeader: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadFrontDocumentHeader",
    defaultMessage: "Front side",
    description: "header for front of document upload",
  },
  uploadFrontDocumentRequired: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadFrontDocumentRequired",
    defaultMessage:
      "An image of the front side of the government issued ID document is required.",
    description: "required error defaultMessage for front of document upload",
  },
  uploadBackDocumentLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadBackDocumentLabel",
    defaultMessage:
      "If you are submitting a card ID, please upload an image of the back of the card as well.",
    description: "Label for back of document upload",
  },
  uploadBackDocumentHeader: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.uploadBackDocumentHeader",
    defaultMessage: "Back side (optional)",
    description: "header for back of document upload",
  },
  submitButtonLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewUploadForm.submitButtonLabel",
    defaultMessage: "Submit Documents",
    description: "Label for submit button",
  },
});
