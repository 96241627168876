import React, { type SyntheticEvent, useEffect } from "react";
import { showToast } from "@jobber/components/Toast";
import { RadioListItem } from "jobber/payments_sca/components/RadioListItem";
import { PaymentMethodListType } from "jobber/payments_react/BaseCollectPaymentDialog/CollectPaymentDialogProps.d";
import { usePaymentMethodsJobberOnline } from "jobber/payments_react/hooks/usePaymentMethods/usePaymentMethodsJobberOnline";
import { StoredPaymentMethodType } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import { StoredCardListItem } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/StoredCardListItem";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";

const radioName = "VaultedPaymentMethodSelector";
const enterNewCreditCardId = "enter-new-credit-card";

const paymentMethodTypeFilters = {
  [PaymentMethodListType.CREDIT_CARD]: [StoredPaymentMethodType.CREDIT_CARD],
  [PaymentMethodListType.BANK_ACCOUNT]: [StoredPaymentMethodType.BANK_ACCOUNT],
  [PaymentMethodListType.CREDIT_CARD_AND_BANK_ACCOUNT]: [
    StoredPaymentMethodType.CREDIT_CARD,
    StoredPaymentMethodType.BANK_ACCOUNT,
  ],
};

export interface VaultedPaymentMethodsSelectorProps {
  clientId: string;
  paymentMethodListType: PaymentMethodListType;
  includeNewPaymentMethodOption?: boolean;
}

export function VaultedPaymentMethodsSelector(
  props: VaultedPaymentMethodsSelectorProps,
) {
  const {
    clientId,
    paymentMethodListType,
    includeNewPaymentMethodOption = true,
  } = props;
  const [state, dispatcher] = usePaymentReducer();
  const isPaymentInProgress = state.status === "inProgress";
  const { paymentMethods, jobberPaymentMethodId: selectedPaymentMethodId } =
    state;

  const {
    paymentMethods: fetchedPaymentMethods,
    error: fetchPaymentMethodsError,
    loading: isFetchingPaymentMethods,
  } = usePaymentMethodsJobberOnline({
    clientId,
    isClientIdEncoded: false,
    includeBankAccounts: true,
  });

  useEffect(() => {
    dispatcher({
      type: "onJobberPaymentsBeingLoaded",
    });
  }, [dispatcher]);

  useEffect(() => {
    if (isFetchingPaymentMethods) return;
    if (fetchPaymentMethodsError) {
      showToast({
        message: "Something went wrong. Please try again",
        variation: "error",
      });
    } else if (fetchedPaymentMethods) {
      const filteredPaymentMethods = fetchedPaymentMethods.filter(
        paymentMethod =>
          paymentMethodTypeFilters[paymentMethodListType].includes(
            paymentMethod.type,
          ),
      );

      dispatcher({
        type: "onJobberPaymentsPaymentMethodsLoaded",
        paymentMethods: filteredPaymentMethods,
      });
    }
  }, [
    fetchedPaymentMethods,
    fetchPaymentMethodsError,
    dispatcher,
    paymentMethodListType,
    isFetchingPaymentMethods,
  ]);

  if (isFetchingPaymentMethods || !paymentMethods) return <Loading />;

  return (
    <ul className="list list--dividers u-marginBottomSmaller">
      {paymentMethods.map(paymentMethod => {
        if (!paymentMethod.uuid) return null;
        const checked = selectedPaymentMethodId === paymentMethod.uuid;

        return (
          <RadioListItem
            key={paymentMethod.uuid}
            id={paymentMethod.uuid}
            value={paymentMethod.uuid}
            name={radioName}
            checked={checked}
            onChange={onChange}
            disabled={isPaymentInProgress}
          >
            <StoredCardListItem {...paymentMethod} />
          </RadioListItem>
        );
      })}
      {paymentMethods.length > 0 && includeNewPaymentMethodOption && (
        <RadioListItem
          id={enterNewCreditCardId}
          value={enterNewCreditCardId}
          name={radioName}
          checked={!selectedPaymentMethodId}
          onChange={onChange}
        >
          Use another payment method
        </RadioListItem>
      )}
    </ul>
  );

  function onChange(e: SyntheticEvent) {
    const id = (e.target as HTMLElement).id;
    const isUsingNewCreditCard = id === enterNewCreditCardId;

    dispatcher({
      type: "updatingJobberPaymentsSelectedPaymentMethod",
      paymentMethodId: isUsingNewCreditCard ? undefined : id,
    });
  }
}

function Loading() {
  return (
    <div className="centerContainer u-positionRelative u-marginSmaller">
      <span className="spinner spinner--inline centerContainer-content" />
    </div>
  );
}
