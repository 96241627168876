import {
  type InternalRefetchQueriesInclude,
  useMutation,
} from "@apollo/client";
import { useIntl } from "react-intl";
import type { ProductsAndServicesDeleteMutation } from "~/utilities/API/graphql";
import { DELETE_PRODUCTS_AND_SERVICES } from "./graphql";
import type { SaveResult } from "./useProductOrServiceSave";
import { messages } from "./messages";

export function useProductsAndServicesDelete(
  refetchQueries?: InternalRefetchQueriesInclude,
) {
  const { formatMessage } = useIntl();
  const [deleteMutation, { loading: deleting }] =
    useMutation<ProductsAndServicesDeleteMutation>(
      DELETE_PRODUCTS_AND_SERVICES,
      {
        refetchQueries: refetchQueries,
      },
    );

  async function productsAndServicesDelete(
    productsAndServicesIds: string[],
  ): Promise<SaveResult> {
    const result = await deleteMutation({
      variables: { productsAndServicesIds },
    });

    if (!result.data) {
      return {
        errors: [
          formatMessage(messages.useProductsAndServicesDeleteFailedToDelete),
        ],
      };
    }

    return {
      errors: result.data.productsAndServicesDelete.userErrors.map(
        x => x.message,
      ),
    };
  }

  return { productsAndServicesDelete, deleting };
}
