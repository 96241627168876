import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  csvExportLabel: {
    id: "reporting.report.csvExportLabel",
    defaultMessage: "Email CSV",
    description: "Label for csv export button",
  },
  exportAllText: {
    id: "reporting.report.exportAllText",
    defaultMessage: "All Columns",
    description: "Label for exporting all columns",
  },
  exportSelectedText: {
    id: "reporting.report.exportSelectedText",
    defaultMessage: "Selected Columns",
    description: "Label for exporting only selected columns",
  },
  exportAllFranchiseText: {
    id: "reporting.report.exportAllFranchiseText",
    defaultMessage: "All columns (Franchise)",
    description: "Label for exporting all franchise columns",
  },
  exportSelectedFranchiseText: {
    id: "reporting.report.exportSelectedFranchiseText",
    defaultMessage: "Selected columns (Franchise)",
    description: "Label for exporting franchise only selected columns",
  },
});
