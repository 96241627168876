import * as React from "react";
import type { SignaturePadWebComponent } from "./SignaturePadTypes";

interface UseSignaturePadCallbackParams {
  onChange?(dataUrl: string | undefined): void;
}

type Update = () => void;
type Reset = () => void;

type ReturnType = [
  React.MutableRefObject<SignaturePadWebComponent | undefined | null>,
  Update,
  Reset,
];

export function useSignaturePadCallback({
  onChange,
}: UseSignaturePadCallbackParams): ReturnType {
  const ref = React.useRef<SignaturePadWebComponent | undefined | null>(null);
  const update = React.useCallback(() => {
    if (!onChange) {
      return;
    }
    const dataUrl = exportCanvasAndShowErrorIfEmpty(ref.current);
    onChange(dataUrl);
  }, [ref]);
  const reset = React.useCallback(
    () => onChange && onChange(undefined),
    [onChange],
  );

  return [ref, update, reset];
}

/*
 calling `exportCanvas()` while empty would trigger error state
 reference: vendor/assets/sg-1/components/signature-pad/signature-pad.js
 reference: vendor/assets/sg-1/components/type-signature-pad/type-signature-pad.js
 */
function exportCanvasAndShowErrorIfEmpty(
  component: SignaturePadWebComponent | undefined | null,
) {
  return (component && component.exportCanvas()) || undefined;
}
