import React from "react";
import classNames from "classnames";
// DO NOT COPY: Allowing an exception here for a legacy use of Typography.
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { CarouselCard } from "jobber/freemium/onboarding/OnboardingCarousel/CarouselCard";
import { ContainerHeader } from "./ContainerHeader";
import type {
  CarouselObjectType,
  ObjectInfoProps,
  OnboardingCarouselProps,
} from "./OnboardingCarousel.d";
import styles from "./OnboardingCarousel.module.css";

export function OnboardingCarousel({
  objectInfo,
  title,
  viewAllUrl,
  createUrl,
  type,
}: OnboardingCarouselProps) {
  const shouldShowEmptyState = objectInfo.length === 0;
  const renderCarouselContent = (objects: ObjectInfoProps[]) => {
    if (shouldShowEmptyState) {
      return <CarouselEmptyState type={type} />;
    }
    return objects.map((object, index) => (
      <CarouselCard key={index} type={type} {...object} />
    ));
  };

  return (
    <div
      className={classNames({
        [styles.bottomPadding]: objectInfo.length >= 2,
      })}
    >
      <div className="u-paddingBottomSmall">
        <ContainerHeader
          title={title}
          createUrl={createUrl}
          type={type}
          viewAllUrl={viewAllUrl}
        />
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <div className={styles.shadow} />
        <div
          className={classNames(styles.scrollContainer, {
            [styles.greyBackground]: shouldShowEmptyState,
          })}
          style={{
            display: "flex",
            overflowX: "auto",
            margin: "-1rem",
            padding: "1rem",
          }}
        >
          {renderCarouselContent(objectInfo)}
        </div>
      </div>
    </div>
  );
}

interface CarouselEmptyStateProps {
  type: CarouselObjectType;
}
function CarouselEmptyState({ type }: CarouselEmptyStateProps) {
  return (
    <div className={styles.carouselEmptyState}>
      <Typography element="h6" fontWeight="bold" textColor="grey">
        No {type}s Created
      </Typography>
    </div>
  );
}
