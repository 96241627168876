import GrowTrialAutomatedQuote from "@images/GrowTrialAutomatedQuote.png";
import GrowTrialOLI from "@images/GrowTrialOLI.png";
import GrowTrialLocationTimers from "@images/GrowTrialLocationTimers.png";
import GrowTrialJobCostingProfitMargin from "@images/GrowTrialJobCostingProfitMargin.png";
import ConnectAutomatedMessaging from "@images/selfServePerUserPricingExpansion/ConnectAutomatedMessaging.png";
import ConnectAutopay from "@images/selfServePerUserPricingExpansion/ConnectAutopay.png";
import ConnectCustomField from "@images/selfServePerUserPricingExpansion/ConnectCustomField.png";
import ConnectTimeTracking from "@images/selfServePerUserPricingExpansion/ConnectTimeTracking.png";
import type { messages } from "./messages";

export enum FeatureCarouselPlanEnum {
  Connect = "CONNECT",
  Grow = "GROW",
}

export interface FeatureCarouselType {
  title: keyof typeof messages;
  description: keyof typeof messages;
  imgSrc: string;
}

const growFeatures: FeatureCarouselType[] = [
  {
    title: "jobCostingTitle",
    description: "jobCostingDescription",
    imgSrc: GrowTrialJobCostingProfitMargin as string,
  },
  {
    title: "locationTimersTitle",
    description: "locationTimersDescription",
    imgSrc: GrowTrialLocationTimers as string,
  },
  {
    title: "oliTitle",
    description: "oliDescription",
    imgSrc: GrowTrialOLI as string,
  },
  {
    title: "automatedQuoteFollowUpsTitle",
    description: "automatedQuoteFollowUpsDescription",
    imgSrc: GrowTrialAutomatedQuote as string,
  },
];

const connectFeatures: FeatureCarouselType[] = [
  {
    title: "automatedMessagingTitle",
    description: "automatedMessagingDescription",
    imgSrc: ConnectAutomatedMessaging as string,
  },
  {
    title: "autopayTitle",
    description: "autopayDescription",
    imgSrc: ConnectAutopay as string,
  },
  {
    title: "timeTrackingTitle",
    description: "timeTrackingDescription",
    imgSrc: ConnectTimeTracking as string,
  },
  {
    title: "customFieldTitle",
    description: "customFieldDescription",
    imgSrc: ConnectCustomField as string,
  },
];

export const FeatureCarouselPlans: {
  [key in FeatureCarouselPlanEnum]: FeatureCarouselType[];
} = {
  [FeatureCarouselPlanEnum.Connect]: connectFeatures,
  [FeatureCarouselPlanEnum.Grow]: growFeatures,
};
