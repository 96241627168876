import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React from "react";
import { Intercom } from "utilities/chat";
import styles from "./SuccessContent.module.css";

interface Props {
  phoneNumber: string | undefined;
  requiresRegistration: boolean;
}
export function SuccessContent({ phoneNumber, requiresRegistration }: Props) {
  function openIntercom() {
    Intercom.EXECUTE("showNewMessage");
  }

  function getPhoneNumberText() {
    if (requiresRegistration) {
      return "Your new phone number will be ";
    }
    return "Your new phone number is";
  }

  function getContent() {
    if (requiresRegistration) {
      return (
        <Content>
          <Text>
            You have successfully signed up for a dedicated phone number! The
            registration approval process can take up to 3 weeks, but is usually
            faster. You can still send invoices, quotes, reminders and
            follow-ups by text message on Jobber&apos;s shared phone numbers.
            You will receive a text message to your dedicated phone number and a
            message in your Jobber account once your registration is complete!
          </Text>
          <Text>
            If your registration hasn&apos;t been approved after a few weeks,
            please contact our{" "}
            <button onClick={openIntercom}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">Customer Success team</a>
            </button>
          </Text>
        </Content>
      );
    }
    return (
      <Content>
        <Text>
          Did you know that you can also customize your text message templates
          for a more personal touch? Head to the notifications settings page to
          get started!
        </Text>
      </Content>
    );
  }
  return (
    <>
      <div className={`${styles.headerWithoutBackground}`}>
        <Content>
          <Heading level={2}>{getPhoneNumberText()}</Heading>
          <Heading level={1}>
            <Emphasis variation="highlight">{phoneNumber}</Emphasis>
          </Heading>
        </Content>
      </div>
      {getContent()}
    </>
  );
}
