import { defineMessages } from "react-intl";

export const uKKycComplianceRegistrationStatusMessages = defineMessages({
  statusHeader: {
    id: "ukKycRegistration.status.statusHeader",
    defaultMessage: "Verification",
    description: "Status header for UK KYC registration",
  },
  inProgressStatusLabel: {
    id: "ukKycRegistration.status.inProgressStatusLabel",
    defaultMessage: "In progress",
    description: "Verification in progress label",
  },
  inProgressStatusMessage: {
    id: "ukKycRegistration.status.inProgressStatusMessage",
    defaultMessage:
      "Your application has been submitted! We will notify you when the approval process is complete, which typically takes up to three days.",
    description: "Verification in progress",
  },
});
