import { useEffect, useReducer } from "react";
import { useFetchPaymentMethods } from "jobber/payments_react/hooks/useFetchPaymentMethods/useFetchPaymentMethods";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/StoredCardListItem";

export interface OnLoadingAction {
  type: "LOADING";
}

export interface OnSetDefaultPaymentMethodAction {
  type: "SET_DEFAULT";
  payload: StoredPaymentMethodInterface;
}

export interface OnDeletePaymentMethodAction {
  type: "DELETE";
  payload: StoredPaymentMethodInterface;
}

export interface OnPaymentMethodsLoadedAction {
  type: "ON_PAYMENT_METHODS_LOADED";
  payload: StoredPaymentMethodInterface[];
}

export interface OnPaymentMethodsErrorAction {
  type: "ON_PAYMENT_METHODS_ERROR";
  payload: Error;
}

export type PaymentMethodsAction =
  | OnLoadingAction
  | OnDeletePaymentMethodAction
  | OnSetDefaultPaymentMethodAction
  | OnPaymentMethodsLoadedAction
  | OnPaymentMethodsErrorAction;

interface PaymentMethodsState {
  loading: boolean;
  error: Error | undefined;
  paymentMethods: StoredPaymentMethodInterface[];
}

const initialState: PaymentMethodsState = {
  loading: true,
  error: undefined,
  paymentMethods: [],
};

export function paymentMethodsReducer(
  state: PaymentMethodsState,
  action: PaymentMethodsAction,
): PaymentMethodsState {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case "SET_DEFAULT": {
      const newCard = { ...action.payload, isDefault: true };
      const updatedList = [newCard];

      state.paymentMethods.forEach(existingCard => {
        if (existingCard.uuid === newCard.uuid) return;

        updatedList.push({
          ...existingCard,
          isDefault: false,
        });
      });

      return {
        ...state,
        paymentMethods: updatedList,
      };
    }
    case "DELETE": {
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          method => method.uuid !== action.payload.uuid,
        ),
      };
    }
    case "ON_PAYMENT_METHODS_LOADED": {
      return {
        ...state,
        paymentMethods: action.payload,
        loading: false,
        error: undefined,
      };
    }
    case "ON_PAYMENT_METHODS_ERROR": {
      return { ...state, error: action.payload, loading: false };
    }
    default: {
      return state;
    }
  }
}

export interface UsePaymentMethodsInput {
  clientId: string;
  deleteFunction: (uuid: string) => Promise<StoredPaymentMethodInterface>;
  setAsDefaultFunction: (
    uuid: string,
  ) => Promise<StoredPaymentMethodInterface | undefined>;
  includeBankAccounts?: boolean;
  isClientIdEncoded?: boolean;
}

interface UsePaymentMethodsResponse {
  paymentMethods: StoredPaymentMethodInterface[];
  loading: boolean;
  error: Error | undefined;
  refetchPaymentMethods: () => Promise<void>;
  setDefaultPaymentMethod: (
    paymentMethod: StoredPaymentMethodInterface,
  ) => Promise<StoredPaymentMethodInterface | undefined>;
  deletePaymentMethod: (
    paymentMethod: StoredPaymentMethodInterface,
  ) => Promise<StoredPaymentMethodInterface | undefined>;
}

export function usePaymentMethodsBase({
  clientId,
  includeBankAccounts = false,
  isClientIdEncoded = false,
  deleteFunction,
  setAsDefaultFunction,
}: UsePaymentMethodsInput): UsePaymentMethodsResponse {
  const [state, dispatch] = useReducer(paymentMethodsReducer, initialState);

  const {
    error: fetchPaymentMethodsError,
    loading: fetchPaymentMethodsLoading,
    paymentMethods: fetchedPaymentMethods,
    refetchPaymentMethods,
  } = useFetchPaymentMethods({
    clientId,
    isClientIdEncoded,
    includeBankAccounts,
  });

  useEffect(() => {
    if (fetchPaymentMethodsLoading) {
      dispatch({ type: "LOADING" });

      return;
    }

    if (fetchPaymentMethodsError) {
      dispatch({
        type: "ON_PAYMENT_METHODS_ERROR",
        payload: fetchPaymentMethodsError,
      });

      return;
    }

    const paymentMethods = fetchedPaymentMethods ?? [];

    dispatch({
      type: "ON_PAYMENT_METHODS_LOADED",
      payload: paymentMethods,
    });
  }, [
    fetchPaymentMethodsError,
    fetchPaymentMethodsLoading,
    fetchedPaymentMethods,
    includeBankAccounts,
  ]);

  const setDefaultPaymentMethod = async (
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined> => {
    if (!paymentMethod.uuid) return;
    try {
      const setAsDefaultPaymentMethod = await setAsDefaultFunction(
        paymentMethod.uuid,
      );

      dispatch({
        type: "SET_DEFAULT",
        payload: paymentMethod,
      });

      return setAsDefaultPaymentMethod;
    } catch (err) {
      dispatch({
        type: "ON_PAYMENT_METHODS_ERROR",
        payload: err,
      });
    }
  };

  const deletePaymentMethod = async (
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined> => {
    if (!paymentMethod.uuid) return;
    try {
      const deletedPaymentMethod = await deleteFunction(paymentMethod.uuid);
      dispatch({
        type: "DELETE",
        payload: paymentMethod,
      });

      return deletedPaymentMethod;
    } catch (err) {
      dispatch({
        type: "ON_PAYMENT_METHODS_ERROR",
        payload: err,
      });
    }
  };

  return {
    error: state.error,
    loading: state.loading,
    paymentMethods: state.paymentMethods,
    refetchPaymentMethods,
    deletePaymentMethod,
    setDefaultPaymentMethod,
  };
}
