import { SplitNames, useFeatureFlag } from "utilities/split";

export const useUxImprovementSplit = () => {
  const isInUxImprovementSplit = useFeatureFlag(
    SplitNames.CampaignsUxImprovements,
  );

  return {
    isInUxImprovementSplit,
  };
};
