import { defineMessages } from "react-intl";

export const messages = defineMessages({
  andAdditionalCriteria: {
    id: "campaigns.editClientSegment.additionalCriteria.and.",
    defaultMessage: "and",
    description: "Text between each additional criteria and the base criteria",
  },
  addAdditionalCriteria: {
    id: "campaigns.editClientSegment.additionalCriteria.add.",
    defaultMessage: "Add",
    description: "Add additional criteria button",
  },
  clientTagsCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTagsCriterion.",
    defaultMessage: "Client tags",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  lineItemsCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.lineItemsCriterion.",
    defaultMessage: "Line Items",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  jobTypeCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.jobTypeCriterion.",
    defaultMessage: "Job type",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  jobStatusCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.jobStatusCriterion.",
    defaultMessage: "Job status",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  chooseCriteria: {
    id: "campaigns.editClientSegment.additionalCriteria.chooseCriteria.",
    defaultMessage: "Choose criteria",
    description: "Select placeholder for choosing the criteria type",
  },
  pleaseSelectCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.pleaseSelect.",
    defaultMessage: "Please select",
    description: "Option displayed when no criterion has been selected",
  },
  clientTagAutoCompletePlaceholder: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTagSelectPlaceholder.",
    defaultMessage: "Search for a tag",
    description: "Select placeholder for choosing the client tag",
  },
  clientTagFieldRequired: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTagFieldRequired.",
    defaultMessage: "Select a tag",
    description: "Error message for when the client tag field is empty",
  },
  clientTagOptionContain: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTagOptionContain.",
    defaultMessage: "contain",
    description: "Option for the select to contain the client tag",
  },
  clientTagOptionNotContain: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTagOptionNotContain.",
    defaultMessage: "do not contain",
    description: "Option for the select to not contain the client tag",
  },
  lineItemAutoCompletePlaceholder: {
    id: "campaigns.editClientSegment.additionalCriteria.lineItemSelectPlaceholder.",
    defaultMessage: "Search for a product or service",
    description: "Select placeholder for choosing the line item",
  },
  lineItemFieldRequired: {
    id: "campaigns.editClientSegment.additionalCriteria.lineItemFieldRequired.",
    defaultMessage: "Select a product or service",
    description: "Error message for when the client tag field is empty",
  },
  lineItemOptionInclude: {
    id: "campaigns.editClientSegment.additionalCriteria.lineItemOptionInclude.",
    defaultMessage: "include",
    description: "Option for the select to include the line item",
  },
  lineItemOptionDoNotInclude: {
    id: "campaigns.editClientSegment.additionalCriteria.lineItemOptionExclude.",
    defaultMessage: "do not include",
    description: "Option for the select to exclude the line item",
  },
  jobTypeIsRecurring: {
    id: "campaigns.editClientSegment.additionalCriteria.jobTypeRecurring.",
    defaultMessage: "is recurring",
    description: "Option for the select to choose recurring job type",
  },
  jobTypeIsOneOff: {
    id: "campaigns.editClientSegment.additionalCriteria.jobTypeOneOff.",
    defaultMessage: "is one-off",
    description: "Option for the select to choose one-off job type",
  },
  removeCriteria: {
    id: "campaigns.editClientSegment.additionalCriteria.removeCriteria.",
    defaultMessage: "Remove criteria",
    description: "Aria label for the remove criterion button",
  },
  jobStatusCriterionText: {
    id: "campaigns.editClientSegment.additionalCriteria.jobStatusCriterionText.",
    defaultMessage: "is ending within 30 days",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  clientTypeCriterion: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTypeCriterion.",
    defaultMessage: "Clients who never booked a job",
    description:
      "Type of criteria chosen in the select to narrow down the client segment",
  },
  clientTypeIsLead: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTypeIsLead.",
    defaultMessage: "leads",
    description: "Option for the select to choose lead client type",
  },
  clientTypeIsAlExistingClient: {
    id: "campaigns.editClientSegment.additionalCriteria.clientTypeIsAlExistingClient.",
    defaultMessage: "all clients",
    description: "Option for the select to choose client type",
  },
});
