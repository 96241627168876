import { Emphasis } from "@jobber/components/Emphasis";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import { Icon } from "@jobber/components/Icon";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import React, { type KeyboardEvent, useContext } from "react";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import {
  getNameOrContactDetailTitle,
  getNameWithContactDetailTitle,
} from "jobber/chat/utilities/formatConversationTitle";
import {
  type ConversationDataFragment,
  type EmailMessage,
  MessageChannelEnum,
  type TextMessage,
} from "~/utilities/API/graphql";
import styles from "./ChatNotificationListItem.module.css";

interface ChatNotificationListRendererProps {
  conversations: ConversationDataFragment[];
}

interface ConversationItem {
  item: ConversationDataFragment;
}

export function ChatNotificationListRenderer({
  conversations,
}: ChatNotificationListRendererProps) {
  return (
    <>
      {conversations.map(item => (
        <ChatNotificationListItem key={item.id} item={item} />
      ))}
    </>
  );
}

export function ChatNotificationListItem({
  item: conversation,
}: ConversationItem) {
  const [, dispatch] = useContext(MessageCenterContext);

  const messagePreview = conversation.messages.nodes[0];
  const contactDetail = getContactDetails(conversation.messages.nodes[0]);

  const title = getNameWithContactDetailTitle(
    conversation.with?.name,
    contactDetail,
  );

  const goToChat = (selectedId: string | number) => {
    if (dispatch) {
      dispatch({
        type: "SHOW_CHAT",
        conversationId: selectedId,
        title: getNameOrContactDetailTitle(
          conversation.with?.name,
          contactDetail,
        ),
        canSendMessages: !conversation.stopped,
        recipient: {
          ...conversation.with,
          contactInfo: contactDetail,
        },
      });
    }
  };

  return (
    <div
      role={"button"}
      className={classNames(
        "flexBlock flexBlock--noGrow flexBlock--noShrink collapse u-paddingSmall u-borderBottom u-colorGreyBlue notificationRow",
        styles.chatNotification,
      )}
      tabIndex={0}
      onClick={clickChatListItem}
      onKeyUp={enterChatListItem}
    >
      <div className="columns align-middle u-paddingNone">
        <div className="row">
          <div className="columns shrink u-paddingNone">
            <Icon name={iconNameForChannel(messagePreview.channel)} />
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns u-paddingRightNone">
                <Text>
                  <Emphasis variation="bold">
                    <span className="u-textTruncate">{title}</span>
                  </Emphasis>
                </Text>
              </div>
              {conversation.with?.state === "new_lead" && (
                <div className="columns u-paddingRightNone shrink">
                  <InlineLabel color="lightBlue">Lead</InlineLabel>
                </div>
              )}
            </div>
            <div className="row">
              <div className="columns">
                <Text variation="subdued">
                  <Emphasis variation="italic">
                    <span className="u-textTruncate">
                      {messagePreview.content}
                    </span>
                  </Emphasis>
                </Text>
                <Text variation="subdued">
                  <span className="u-textSmaller">
                    <FormatRelativeDateTime date={messagePreview.timestamp} />
                  </span>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns shrink u-paddingSmall u-paddingBottomNone">
        {!conversation.readFlag && (
          <span className={classNames(styles.unread)} aria-label="Unread" />
        )}
      </div>
    </div>
  );

  function clickChatListItem() {
    goToChat(conversation.id);
  }

  function enterChatListItem(e: KeyboardEvent) {
    if (e.key === "Enter") {
      clickChatListItem();
    }
  }
}

function iconNameForChannel(channel: MessageChannelEnum) {
  switch (channel) {
    case MessageChannelEnum.EMAIL:
      return "email";
    case MessageChannelEnum.SMS:
      return "sms2";
    // An unhandled channel type has come through. Add a new case statement
    // for a new MessageChannelEnum.
    default:
      return "alert";
  }
}

function getContactDetails(node: Partial<TextMessage | EmailMessage>) {
  let res: string | undefined;
  if (node.__typename === "EmailMessage") {
    res = node.toEmails?.join(", ");
  } else if (node.__typename === "TextMessage") {
    res = node.toPhones?.join(", ");
  }
  return res || "";
}
