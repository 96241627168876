import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignCreateTemplatesMutation,
  CampaignCreateTemplatesMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_CREATE_TEMPLATES } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export function useCampaignCreateTemplatesMutation() {
  const [campaignCreateTemplatesMutation, { loading, error, data }] =
    useMutation<
      CampaignCreateTemplatesMutation,
      CampaignCreateTemplatesMutationVariables
    >(CAMPAIGN_CREATE_TEMPLATES);

  const campaignCreateTemplates = useCallback(
    async (input: CampaignCreateTemplatesMutationVariables) => {
      const mutationResult = await campaignCreateTemplatesMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignCreateTemplates.userErrors ?? [];
      return {
        campaign: mutationResult.data?.campaignCreateTemplates.campaign,
        userErrors,
      };
    },
    [campaignCreateTemplatesMutation],
  );

  return {
    campaignCreateTemplates,
    loading,
    error,
    data,
  };
}
