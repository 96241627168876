import { Amplitude } from "~/utilities/analytics/Amplitude";

export const trackAmplitudeEvent = ({
  eventName,
  eventProperties = {},
  section,
  searchTerm,
}: {
  eventName: string;
  eventProperties?: EventProperties;
  section: string;
  searchTerm?: string;
}) => {
  const mergedEventProperties = {
    ...eventProperties,
    section,
    searchTerm,
  };
  Amplitude.TRACK_EVENT(eventName, mergedEventProperties);
};
