import React from "react";
import { Page } from "@jobber/components/Page";

/**
 * React Routed 404 View
 */
export function NoMatch() {
  return (
    <Page title="404: Page Not Found">
      Check the address for errors and try again
    </Page>
  );
}
