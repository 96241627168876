import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import {
  ExpiryStatus,
  type StoredPaymentMethodInterface,
  StoredPaymentMethodType,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";

export const defaultCard: CreditCard = {
  id: "2",
  brand: "mastercard",
  expiryMonth: 12,
  expiryYear: 2020,
  expiryStatus: ExpiryStatus.EXPIRING_SOON,
  isDefault: true,
  last4: "2451",
};

export const testCreditCards: CreditCard[] = [
  {
    id: "1",
    brand: "visa",
    expiryMonth: 8,
    expiryYear: 2028,
    expiryStatus: ExpiryStatus.DEFAULT,
    isDefault: false,
    last4: "4242",
  },
  defaultCard,
  {
    id: "3",
    brand: "jcb",
    expiryMonth: 12,
    expiryYear: 2012,
    expiryStatus: ExpiryStatus.EXPIRED,
    isDefault: false,
    last4: "8925",
  },
];

export const testPaymentMethods: StoredPaymentMethodInterface[] = [
  {
    uuid: "1",
    type: StoredPaymentMethodType.CREDIT_CARD,
    brand: "visa",
    expiryMonth: 8,
    expiryYear: 2028,
    expiryStatus: ExpiryStatus.DEFAULT,
    isDefault: false,
    last4: "4242",
  },
  {
    uuid: "2",
    type: StoredPaymentMethodType.CREDIT_CARD,
    brand: "mastercard",
    expiryMonth: 12,
    expiryYear: 2020,
    expiryStatus: ExpiryStatus.EXPIRING_SOON,
    isDefault: true,
    last4: "2451",
  },
  {
    uuid: "3",
    type: StoredPaymentMethodType.CREDIT_CARD,
    brand: "jcb",
    expiryMonth: 12,
    expiryYear: 2012,
    expiryStatus: ExpiryStatus.EXPIRED,
    isDefault: false,
    last4: "8925",
  },
];
