import React from "react";
import { useIntl } from "react-intl";
import { Autocomplete } from "@jobber/components/Autocomplete";
import {
  TextFieldType_DEPRECATED,
  TextField_DEPRECATED,
} from "legacy/components/TextField";
import { messages } from "./messages";

export interface InputProps {
  value: string;
  formPrefix: string;
  autocomplete?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  errorMessage?: string;
  onChange?(newValue: string): void;
  onFocus?(): void;
  onBlur?(): void;
  onKeyDown?(
    newValue: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void;
}
export interface ProvinceListValueProps {
  value: string;
  label: string;
}

export interface ProvinceInputProps extends InputProps {
  provinceList?: ProvinceListValueProps[];
}

export function Street1({
  autocomplete,
  value,
  forwardedRef,
  formPrefix,
  placeholder,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}: InputProps) {
  return (
    <TextField_DEPRECATED
      autoComplete={autoCompleteString(autocomplete)}
      stacked={true}
      forwardedRef={forwardedRef}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder || "Street 1"}
      inputType={TextFieldType_DEPRECATED.textinput}
      name={`${formPrefix}[street1]`}
      value={value}
    />
  );
}

export function Street2({
  autocomplete,
  value,
  formPrefix,
  placeholder,
  onChange,
}: InputProps) {
  return (
    <TextField_DEPRECATED
      autoComplete={autoCompleteString(autocomplete)}
      stacked={true}
      onChange={onChange}
      placeholder={placeholder || "Street 2"}
      inputType={TextFieldType_DEPRECATED.textinput}
      name={`${formPrefix}[street2]`}
      value={value}
    />
  );
}

export function City({
  autocomplete,
  value,
  formPrefix,
  placeholder,
  onChange,
}: InputProps) {
  return (
    <TextField_DEPRECATED
      autoComplete={autoCompleteString(autocomplete)}
      stacked={true}
      onChange={onChange}
      placeholder={placeholder || "City"}
      inputType={TextFieldType_DEPRECATED.textinput}
      name={`${formPrefix}[city]`}
      value={value}
    />
  );
}

export function Province({
  autocomplete,
  value,
  formPrefix,
  placeholder,
  onChange,
  provinceList,
  errorMessage,
}: ProvinceInputProps) {
  const { formatMessage } = useIntl();

  if (!provinceList) {
    return (
      <TextField_DEPRECATED
        autoComplete={autoCompleteString(autocomplete)}
        stacked={true}
        onChange={onChange}
        placeholder={placeholder || formatMessage(messages.province)}
        inputType={TextFieldType_DEPRECATED.textinput}
        name={`${formPrefix}[province]`}
        value={value}
      />
    );
  }

  /**
   * Restrict users from entering invalid data in State.
   * This will show a list of predefined States/Provinces if provided.
   */
  const defaultProvince = { label: "", value: "" };

  const province =
    provinceList.find(item => item.label === value || item.value === value) ??
    defaultProvince;

  const options = provinceList;

  return (
    <Autocomplete
      value={province}
      invalid={!!errorMessage}
      initialOptions={provinceList}
      onChange={handleChange}
      getOptions={getOptions}
      placeholder="State"
      debounce={0}
      allowFreeForm={false}
    />
  );

  function handleChange(values: ProvinceListValueProps) {
    if (onChange) {
      onChange(values?.value || "");
    }
  }

  function getOptions(text: string) {
    if (text === "") {
      return options;
    }
    const filterRegex = new RegExp(text, "i");
    return options.filter(provinces => provinces.label.match(filterRegex));
  }
}

export function PostalCode({
  autocomplete,
  value,
  formPrefix,
  placeholder,
  onChange,
}: InputProps) {
  const { formatMessage } = useIntl();

  return (
    <TextField_DEPRECATED
      autoComplete={autoCompleteString(autocomplete)}
      stacked={true}
      onChange={onChange}
      placeholder={placeholder || formatMessage(messages.postalCode)}
      inputType={TextFieldType_DEPRECATED.textinput}
      name={`${formPrefix}[pc]`}
      value={value}
    />
  );
}

function autoCompleteString(autocomplete: boolean | undefined) {
  return autocomplete ? "randomString" : "off";
}
