import { defineMessages } from "react-intl";

export const messages = defineMessages({
  connectToJobberAccount: {
    id: "marketplace.oAuthAuthorizationPage.connectToJobberAccount",
    defaultMessage: "wants to connect to your Jobber account",
    description: "connect to Jobber account",
  },
  accessJobberAccount: {
    id: "marketplace.oAuthAuthorizationPage.accessJobberAccount",
    defaultMessage: "wants access to your Jobber account",
    description: "access to Jobber account",
  },
  readCustomFieldConfigurations: {
    id: "marketplace.oAuthAuthorizationPage.readCustomFieldConfigurations",
    defaultMessage: "custom field configurations",
    description: "read custom field configurations",
  },
  writeCustomFieldConfigurations: {
    id: "marketplace.oAuthAuthorizationPage.writeCustomFieldConfigurations",
    defaultMessage: "custom field configurations",
    description: "write custom field configurations",
  },
  writeTaxRates: {
    id: "marketplace.oAuthAuthorizationPage.writeTaxRates",
    defaultMessage: "tax rates",
    description: "write tax rates",
  },
  readUsers: {
    id: "marketplace.oAuthAuthorizationPage.readUsers",
    defaultMessage: "contact information, addresses, labour rates",
    description: "read users",
  },
  readTimeSheets: {
    id: "marketplace.oAuthAuthorizationPage.readTimeSheets",
    defaultMessage: "timesheets",
    description: "read timesheets",
  },
  writeUsers: {
    id: "marketplace.oAuthAuthorizationPage.writeUsers",
    defaultMessage: "contact information, addresses, labour rates",
    description: "write users",
  },
  writeTimeSheets: {
    id: "marketplace.oAuthAuthorizationPage.writeTimeSheets",
    defaultMessage: "timesheets",
    description: "read timesheets",
  },
  readClients: {
    id: "marketplace.oAuthAuthorizationPage.readClients",
    defaultMessage: "clients",
    description: "read clients",
  },
  readRequests: {
    id: "marketplace.oAuthAuthorizationPage.readRequests",
    defaultMessage: "requests",
    description: "read requests",
  },
  readQuotes: {
    id: "marketplace.oAuthAuthorizationPage.readQuotes",
    defaultMessage: "quotes",
    description: "read quotes",
  },
  readJobs: {
    id: "marketplace.oAuthAuthorizationPage.readJobs",
    defaultMessage: "jobs",
    description: "read jobs",
  },
  readScheduledItems: {
    id: "marketplace.oAuthAuthorizationPage.readScheduledItems",
    defaultMessage: "scheduled items",
    description: "read scheduled items",
  },
  writeClients: {
    id: "marketplace.oAuthAuthorizationPage.writeClients",
    defaultMessage: "clients",
    description: "write clients",
  },
  writeRequests: {
    id: "marketplace.oAuthAuthorizationPage.writeRequests",
    defaultMessage: "requests",
    description: "write requests",
  },
  writeQuotes: {
    id: "marketplace.oAuthAuthorizationPage.writeQuotes",
    defaultMessage: "quotes",
    description: "write quotes",
  },
  writeJobs: {
    id: "marketplace.oAuthAuthorizationPage.writeJobs",
    defaultMessage: "jobs",
    description: "write jobs",
  },
  writeScheduledItems: {
    id: "marketplace.oAuthAuthorizationPage.writeScheduledItems",
    defaultMessage: "scheduled items",
    description: "write scheduled items",
  },
  readInvoices: {
    id: "marketplace.oAuthAuthorizationPage.readInvoices",
    defaultMessage: "invoices, payments",
    description: "read invoices and payments",
  },
  readExpenses: {
    id: "marketplace.oAuthAuthorizationPage.readExpenses",
    defaultMessage: "expenses",
    description: "read expenses",
  },
  readJobberPayments: {
    id: "marketplace.oAuthAuthorizationPage.readJobberPayments",
    defaultMessage: "Jobber Payments information",
    description: "read Jobber Payments information",
  },
  writeInvoices: {
    id: "marketplace.oAuthAuthorizationPage.writeInvoices",
    defaultMessage: "invoices, payments",
    description: "write invoices and payments",
  },
  writeExpenses: {
    id: "marketplace.oAuthAuthorizationPage.writeExpenses",
    defaultMessage: "expenses",
    description: "write expenses",
  },
  writeJobberPayments: {
    id: "marketplace.oAuthAuthorizationPage.writeJobberPayments",
    defaultMessage: "Jobber Payments information",
    description: "write Jobber Payments information",
  },
});
