import { defineMessages } from "react-intl";

export const messages = defineMessages({
  fiveDayUsageReminderModalTitle: {
    id: "fiveDayUsageReminderModalTitle",
    defaultMessage: "Don’t forget, your trial ends soon",
    description: "Title for the five day usage reminder modal",
  },
  fiveDayUsageReminderModalFeatureListHeader: {
    id: "fiveDayUsageReminderModalFeatureListHeader",
    defaultMessage:
      "Stay on the Grow plan for permanent access to these features, and more:",
    description:
      "Header for the features list section of the five day usage reminder modal",
  },
  fiveDayUsageReminderModalFeatureOne: {
    id: "fiveDayUsageReminderModalFeatureOne",
    defaultMessage: "Automated quote follow-ups",
    description:
      "First feature of the features list section of the five day usage reminder modal",
  },
  fiveDayUsageReminderModalFeatureTwo: {
    id: "fiveDayUsageReminderModalFeatureTwo",
    defaultMessage: "Optional line items",
    description:
      "Second feature of the features list section of the five day usage reminder modal",
  },
  fiveDayUsageReminderModalFeatureThree: {
    id: "fiveDayUsageReminderModalFeatureThree",
    defaultMessage: "Location timers",
    description:
      "Third feature of the features list section of the five day usage reminder modal",
  },
  fiveDayUsageReminderModalFeatureFour: {
    id: "fiveDayUsageReminderModalFeatureFour",
    defaultMessage: "Job costing",
    description:
      "Fourth feature of the features list section of the five day usage reminder modal",
  },
  fiveDayUsageReminderModalSMSWarning: {
    id: "fiveDayUsageReminderModalSMSWarning",
    defaultMessage:
      "Upon returning to your original plan, two-way text messages sent or received during the trial will no longer be accessible.",
    description: "SMS warning for the five day usage reminder modal",
  },
  fiveDayUsageReminderModalCompletedGuideCopyParagraphOne: {
    id: "fiveDayUsageReminderModalCompletedGuideCopyParagraphOne",
    defaultMessage:
      "Congratulations! You’ve explored every feature available on your Grow trial. Once the trial ends, your original subscription plan will automatically resume. None of your existing work will be lost.",
    description:
      "Copy for first paragraph of the five day usage reminder modal for accounts that have completed the home feature guide",
  },
  fiveDayUsageReminderModalNotCompletedGuideCopyParagraphOne: {
    id: "fiveDayUsageReminderModalNotCompletedGuideCopyParagraphOne",
    defaultMessage:
      "You haven’t explored all of your available Grow features. Take full advantage by using these features before your trial ends.",
    description:
      "Copy for first paragraph of the five day usage reminder modal for accounts that have not completed the home feature guide",
  },
  fiveDayUsageReminderModalCompletedGuideCopyParagraphTwo: {
    id: "fiveDayUsageReminderModalCompletedGuideCopyParagraphTwo",
    defaultMessage:
      "Still undecided? Speak with a product expert to learn how you can make the most out of these features to grow your business.",
    description:
      "Copy for second paragraph of the five day usage reminder modal for accounts that have completed the home feature guide",
  },
  fiveDayUsageReminderModalNotCompletedGuideCopyParagraphTwo: {
    id: "fiveDayUsageReminderModalNotCompletedGuideCopyParagraphTwo",
    defaultMessage:
      "Not sure where to start? Speak with a product expert to learn how you can make the most out of of these features to grow your business.",
    description:
      "Copy for second paragraph of the five day usage reminder modal for accounts that have not completed the home feature guide",
  },
  fiveDayUsageReminderModalStayOnTheGrowPlanCTA: {
    id: "fiveDayUsageReminderModalStayOnTheGrowPlanCTA",
    defaultMessage: "Stay on the Grow Plan",
    description: "CTA Label for the five day usage reminder modal",
  },
  fiveDayUsageReminderModalBookACallCTA: {
    id: "fiveDayUsageReminderModalBookACallCTA",
    defaultMessage: "Book a Call",
    description: "CTA Label for the five day usage reminder modal",
  },
  fiveDayUsageReminderModalTryGrowFeaturesCTA: {
    id: "fiveDayUsageReminderModalTryGrowFeaturesCTA",
    defaultMessage: "Try Grow Features",
    description: "CTA Label for the five day usage reminder modal",
  },
  fiveDayUsageReminderModalBookACallUrl: {
    id: "fiveDayUsageReminderModalBookADemoUrl",
    defaultMessage:
      "https://calendly.com/checkinwithjobber/booking-a-demo-call-lt",
    description: "Calendly url to book a call",
  },
  fiveDayUsageReminderModalStayOnGrowUrl: {
    id: "fiveDayUsageReminderModalStayOnGrowUrl",
    defaultMessage:
      "/accounts/billing_info/edit.dialog?plan_set_identifier={planIdentifier}&tier_name=grow&is_annual=true&is_choosing_plan=true",
    description: "Url to open the billing modal",
  },
  fiveDayUsageReminderModalTryGrowFeaturesUrl: {
    id: "fiveDayUsageReminderModalTryGrowFeaturesUrl",
    defaultMessage: "/home?show_grow_trial_onboarding",
    description: "Url to open the feature guide",
  },
});
