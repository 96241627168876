import React from "react";
import { useParams } from "react-router-dom";

interface RedirectParams {
  [key: string]: string;
}

interface ExternalRedirectProps {
  url(params: RedirectParams): string;
}

export function ExternalRedirect({ url }: ExternalRedirectProps) {
  const params = useParams();
  window.location.replace(url(params));
  return <></>;
}
