import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./FeatureCard.module.css";

interface FeatureCardProps {
  featureToTrack: string;
  cardHeading: string;
  cardDescription: string;
  imageSrc: string;
  imageAltText: string;
  linkToRedirect: string;
}

export function FeatureCard({
  featureToTrack,
  cardHeading,
  cardDescription,
  imageSrc,
  imageAltText,
  linkToRedirect,
}: FeatureCardProps) {
  return (
    <a href={linkToRedirect} onClick={handleTracking}>
      <div className={styles.featureCardContainer} data-testid="featureCard">
        <div className={styles.featureCardCopy}>
          <div className={styles.featureCardHeading}>
            <Heading level={3}>{cardHeading}</Heading>
          </div>
          <Text variation="subdued">{cardDescription}</Text>
        </div>
        <div className={styles.featureImage}>
          <img src={imageSrc} alt={imageAltText} />
        </div>
      </div>
    </a>
  );

  function handleTracking() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "expansion_landing_page_feature_card",
      type: `${featureToTrack}`,
    });
  }
}
