import React from "react";
import { ResourceItem, type ResourceItemProps } from "./ResourceItem";

interface ResourceListProps {
  items: ResourceItemProps[];
}

export function ResourceList({ items }: ResourceListProps) {
  return (
    <ul>
      {items.map(resource => {
        const { icon, label, url, external } = resource;
        return (
          <ResourceItem
            key={label}
            icon={icon}
            label={label}
            url={url}
            external={external}
          />
        );
      })}
    </ul>
  );
}
