import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { profilePageDefaultStats } from "jobber/setupWizard/components/constants";
import type { StatLines } from "jobber/setupWizard/components/MaskingTapeStats/MaskingTapeStats";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import profileImgSrc from "@images/signup/collage.jpg";
import { ProfilePageForm } from "./components/ProfilePageForm";
import { messages } from "./messages";
import { MaskingTapeStats } from "../MaskingTapeStats";

export const profileImage: ImageType = {
  alt: messages.serviceProviderCollageAltText,
  src: profileImgSrc,
};

export function ProfilePage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.profile,
    });
  }, []);

  const profilePageHeading = (
    <div data-testid="profile-heading">
      <Heading level={1}>
        <FormattedMessage
          {...messages.profilePageDefaultHeader}
          values={{
            span: chunks => <span className="highlighter">{chunks}</span>,
          }}
        />
      </Heading>
    </div>
  );

  const stat: StatLines[] = [...profilePageDefaultStats];

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={profilePageHeading}
          copy={formatMessage(messages.profilePageCopy)}
          form={<ProfilePageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={profileImage}>
          <MaskingTapeStats stat={stat} />
        </MediaColumn>
      }
    />
  );
}
