import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Intercom } from "legacy/utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

interface ChatAboutAddonsButtonProps {
  loading: boolean;
  addonSetIdentifier: string;
  buttonText?: string;
}

export function ChatAboutAddonsButton(props: ChatAboutAddonsButtonProps) {
  const { formatMessage } = useIntl();

  function triggerIntercom() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "chat_with_us",
      source: `${props.addonSetIdentifier}_landing_page`,
    });

    Intercom.EXECUTE("trackEvent", "account_mobilebilled_marketingtools");
  }

  return (
    <div className="bot_launcher_button">
      <Button
        label={props.buttonText || formatMessage(messages.intercomButton)}
        onClick={triggerIntercom}
        variation={"work"}
        type={"primary"}
        size={"base"}
        loading={props.loading}
      />
    </div>
  );
}
