import React, { useState } from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import type { Service } from "jobber/google_lsa/MerchantConfiguration";
import styles from "./CategoryRemovalModal.module.css";

interface CategoryRemovalModalProps {
  open: boolean;
  activeServices: Service[];
  onConfirmed(): void;
  onCancelled(): void;
}

export function CategoryRemovalModal(props: CategoryRemovalModalProps) {
  const [removeConfirmed, setRemoveConfirmed] = useState(false);

  const confirm = () => {
    setRemoveConfirmed(false);
    props.onConfirmed();
  };

  const cancel = () => {
    setRemoveConfirmed(false);
    props.onCancelled();
  };

  return (
    <Modal
      title="Remove Category?"
      open={props.open}
      dismissible={false}
      primaryAction={{
        label: "Remove Category",
        onClick: confirm,
        disabled: !removeConfirmed,
        variation: "destructive",
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: cancel,
      }}
    >
      <Content>
        <Text>
          By removing this job category, you will also be removing the following
          service types and the minimum prices associated to them from your
          instant booking.
        </Text>
        <ul className={styles.categoryServiceList}>
          {props.activeServices.map(
            (service: Service) =>
              service.enabled && (
                <li key={service.serviceName}>{service.serviceDisplayName}</li>
              ),
          )}
        </ul>
        <Checkbox
          checked={removeConfirmed}
          onChange={setRemoveConfirmed}
          label="I understand this will remove these service offerings from my instant booking"
        />
      </Content>
    </Modal>
  );
}
