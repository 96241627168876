import type { CampaignContentRefs } from "jobber/campaigns/views/CampaignsContentPage/types";

export function triggerValidation(refs: CampaignContentRefs): void {
  Object.keys(refs).forEach((key: keyof typeof refs) => {
    const ref = refs[key].current;
    if (ref) {
      ref.focus();
      ref.blur();
    }
  });
}
