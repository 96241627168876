import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

// Selectors

export function findRuleConditionText(text: string) {
  return screen.getByText(text);
}

export function getTooltipIcon() {
  return screen.getByTestId("help");
}

export function getTooltipText(text: string) {
  return screen.getByText(text);
}

// Solo actions

export function iHoverOnHelpIcon() {
  return userEvent.hover(getTooltipIcon());
}
