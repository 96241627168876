import { gql } from "@apollo/client";

export const SCHEDULED_APPOINTMENTS_QUERY = gql`
  query ScheduledAppointments(
    $occursWithin: DateOnlyRange!
    $selectedEmployees: [EncodedId!]!
  ) {
    viewSchedulingRecommendations(
      occursWithin: $occursWithin
      selectedEmployees: $selectedEmployees
    ) {
      appointments {
        title
        primaryDescription
        secondaryDescription
        assignedEmployeeIds
        endAt
        startAt
        allDay
        isShortAppointment
      }
    }
  }
`;
