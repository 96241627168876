import { Emphasis } from "@jobber/components/Emphasis";
import { FormatTime } from "@jobber/components/FormatTime";
import { Text } from "@jobber/components/Text";
import { CivilTime } from "@std-proposal/temporal";
import pluralize from "pluralize";
import React from "react";
import { NotificationReminderUnit } from "~/utilities/API/graphql";
import { NotificationStringTemplate } from "jobber/settings/notifications/components/NotificationStringTemplate/NotificationStringTemplate";
import type {
  NotificationSchedule,
  ScheduleOverviewProps,
} from "jobber/settings/notifications/notificationInterfaces";

export function ScheduleOverview({
  scheduleTemplate,
  schedules,
  type,
}: ScheduleOverviewProps) {
  const getDurationInWords = (schedule: NotificationSchedule) => {
    let message;
    const offset = schedule.offset;
    if (offset) {
      const duration = offset.value.toString();
      const unit = offset.unit.toLowerCase();
      if (offset.value.toString() === "0") {
        message = `the same ${unit} as`;
      } else {
        const durationString = pluralize(unit, parseInt(duration, 10), true);
        const beforeOrAfter = schedule.before ? "before" : "after";

        message = `${durationString} ${beforeOrAfter}`;
      }
    }

    return <Emphasis variation="bold">{message}</Emphasis>;
  };

  const getMethodInWords = (
    deliveryMethod: NotificationSchedule["deliveryMethod"],
  ) => {
    let message;
    switch (deliveryMethod) {
      case "EMAIL":
        message = "email";
        break;
      case "SMS":
        message = "text message";
        break;
      default:
        message = "text message or email";
    }

    return <Emphasis variation="bold">{message}</Emphasis>;
  };

  const enabledSchedules = schedules.filter(
    schedule => schedule.enabled === true,
  );

  return (
    <ul className="list list--bulleted u-paddingLeft u-marginBottomNone">
      {scheduleTemplate &&
        enabledSchedules.map(schedule => (
          <li key={schedule.id} className="list-item">
            <Text>
              <NotificationStringTemplate
                template={scheduleTemplate}
                values={{
                  duration: getDurationInWords(schedule),
                  method: getMethodInWords(schedule.deliveryMethod),
                  time: getTimeInWords(schedule),
                }}
              />
            </Text>
          </li>
        ))}

      {/* Workaround to get consistent schedule experience in Jobber. Basically,
          job follow up doesn't really have a schedule, but it should look like
          it has a schedule */}
      {scheduleTemplate && type === "JOB_FOLLOWUP" && (
        <li className="list-item">
          <Text>
            <NotificationStringTemplate template={scheduleTemplate} />
          </Text>
        </li>
      )}
    </ul>
  );
}

function getTimeInWords(schedule: NotificationSchedule) {
  if (
    schedule.at &&
    schedule.offset &&
    schedule.offset.unit === NotificationReminderUnit.DAY
  ) {
    return (
      <>
        at <FormatTime time={CivilTime.fromString(schedule.at)} />
      </>
    );
  } else {
    return <></>;
  }
}
