import type { MutableRefObject } from "react";
import React, { useRef } from "react";
import { withSplitClient } from "utilities/split";
import { ConnectToGrowOnboardingChecklist } from "jobber/connectToGrowTrial/components/ConnectToGrowOnboardingChecklist";
import { useHomeHeaderCheck } from "jobber/dashboard/components/Home/hooks/useHomeHeaderCheck";
import type { CallToActionRef } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { CallToAction } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { GoogleReviewMilestoneCelebration } from "./components/GoogleReviewMilestoneCelebration/GoogleReviewMilestoneCelebration";
import style from "./HomeHeaderContainer.module.css";
import { MilestoneCTA } from "./components/GoogleReviewMilestoneCelebration/utils";
import { ComponentRenderOptions } from "./constants";

export interface HomeHeaderContainerProps {
  drawerOpen: boolean;
  growTrial: {
    active: boolean;
    firstName: string;
    optingBackIn?: boolean;
  };
}

function HomeHeaderContainer({
  drawerOpen,
  growTrial,
}: HomeHeaderContainerProps) {
  const experience = useHomeHeaderCheck({ growTrialActive: growTrial.active });
  const twentyFiveReviewsCelebrationRef =
    useRef() as MutableRefObject<CallToActionRef>;
  const fiftyReviewsCelebrationRef =
    useRef() as MutableRefObject<CallToActionRef>;
  const hundredReviewsCelebrationRef =
    useRef() as MutableRefObject<CallToActionRef>;
  const twoHundredPlusReviewsCelebrationRef =
    useRef() as MutableRefObject<CallToActionRef>;

  if (experience === ComponentRenderOptions.GROW_TRIAL_ONBOARDING) {
    return (
      <div className={style.headerContainer}>
        <ConnectToGrowOnboardingChecklist
          userName={growTrial.firstName}
          drawerOpen={drawerOpen}
          optingBackIn={growTrial.optingBackIn}
        />
      </div>
    );
  }

  return (
    <div className={style.headerContainer}>
      <CallToAction
        ref={twentyFiveReviewsCelebrationRef}
        ctaName={MilestoneCTA.TwentyFive}
      >
        <GoogleReviewMilestoneCelebration
          milestone={MilestoneCTA.TwentyFive}
          ctaRef={twentyFiveReviewsCelebrationRef}
        />
      </CallToAction>
      <CallToAction
        ref={fiftyReviewsCelebrationRef}
        ctaName={MilestoneCTA.Fifty}
      >
        <GoogleReviewMilestoneCelebration
          milestone={MilestoneCTA.Fifty}
          ctaRef={fiftyReviewsCelebrationRef}
        />
      </CallToAction>
      <CallToAction
        ref={hundredReviewsCelebrationRef}
        ctaName={MilestoneCTA.Hundred}
      >
        <GoogleReviewMilestoneCelebration
          milestone={MilestoneCTA.Hundred}
          ctaRef={hundredReviewsCelebrationRef}
        />
      </CallToAction>
      <CallToAction
        ref={twoHundredPlusReviewsCelebrationRef}
        ctaName={MilestoneCTA.TwoHundredPlus}
      >
        <GoogleReviewMilestoneCelebration
          milestone={MilestoneCTA.TwoHundredPlus}
          ctaRef={twoHundredPlusReviewsCelebrationRef}
        />
      </CallToAction>
    </div>
  );
}

const SplitWrappedComponent = withSplitClient(HomeHeaderContainer);
export { SplitWrappedComponent as HomeHeaderContainer };
