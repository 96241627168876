export const messages = {
  setupGuideHeaderText: {
    id: "setupGuideHeaderText",
    defaultMessage: "Let’s get you set up",
    description: "Title of the setup guide",
  },
  growTrialOnboardingChecklistHeaderText: {
    id: "growTrialOnboardingChecklistHeaderText",
    defaultMessage: "Explore Grow Features",
    description: "Title for the onboarding checklist",
  },
};
