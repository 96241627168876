import React, { useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { CTAMarkSeen } from "jobber/bridges/callToActionBridge";
import { APIProvider } from "~/utilities/API/APIProvider";
import { SelectAndSaveModal } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/SelectAndSaveModal";
import { usePhoneNumbers } from "jobber/settings/dedicatedPhoneNumber/usePhoneNumbers";
import {
  UrlsContext,
  type UrlsContextType,
} from "~/utilities/contexts/internal/UrlsContext";
import styles from "./DedicatedPhoneNumber.module.css";

interface DedicatedPhoneNumberCallToActionProps {
  imgSrc: string;
  ctaName: string;
  requiresRegistration: boolean;
}

interface DedicatedPhoneNumberCallToActionWrapperProps
  extends DedicatedPhoneNumberCallToActionProps {
  urlsContext: UrlsContextType;
}

export function DedicatedPhoneNumberCallToActionWrapper({
  imgSrc,
  ctaName,
  requiresRegistration,
  urlsContext,
}: DedicatedPhoneNumberCallToActionWrapperProps) {
  return (
    <UrlsContext.Provider value={urlsContext}>
      <APIProvider>
        <DedicatedPhoneNumberCallToAction
          imgSrc={imgSrc}
          ctaName={ctaName}
          requiresRegistration={requiresRegistration}
        />
      </APIProvider>
    </UrlsContext.Provider>
  );
}

function DedicatedPhoneNumberCallToAction({
  imgSrc,
  ctaName,
  requiresRegistration,
}: DedicatedPhoneNumberCallToActionProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [findPhoneNumbers, loading, numbers] = usePhoneNumbers();

  useEffect(() => {
    if (ctaName) {
      CTAMarkSeen(ctaName);
    }
  }, []);

  useEffect(() => {
    findPhoneNumbers();
  }, []);

  return (
    <>
      {!loading && numbers.length > 0 && (
        <SelectAndSaveModal
          title={"Two-way text messaging 🙌"}
          open={modalOpen}
          onRequestClose={handleClose}
          phoneNumberOptions={numbers}
          requiresRegistration={requiresRegistration}
          getPhoneNumberOptions={findPhoneNumbers}
        >
          <div
            className={`${styles.headerStyle}`}
            style={{ backgroundImage: `url(${imgSrc})` }}
          >
            <h1 className={"u-colorWhite u-marginBottomNone"}>
              Start today <br />
              with a local <br />
              <span className="highlighter">Phone Number!</span>
            </h1>
          </div>
          <Content>
            <p>
              Pick from the available numbers below, for one dedicated to your
              business. Your clients will always know it’s you and you’ll be
              able to text back-and-forth!
            </p>
            <p className={"u-marginBottomNone"}>
              Don’t see a number below that you like? Check back, in your
              Company settings, in a few days!
            </p>
          </Content>
        </SelectAndSaveModal>
      )}
    </>
  );

  function handleClose() {
    setModalOpen(false);
  }
}
