import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React, { type CSSProperties } from "react";
import { MustachePreview } from "jobber/settings/notifications/components/MustachePreview";
import type { TemplatePreviewerProps } from "jobber/settings/notifications/notificationInterfaces";
import { assertHasTypename } from "utilities/types/assertTypes";

interface VariableMapping {
  [key: string]: string;
}

const SURVEY_PREVIEW_STYLE: CSSProperties = {
  display: "inline-block",
  width: "40px",
  height: "40px",
  margin: "2px",
  padding: "0",
  textAlign: "center",
  textDecoration: "none",
  color: "#657884",
  background: "transparent",
  border: "1px solid #E0E0E0",
  borderBottomWidth: "3px",
  borderRadius: "4px",
  fontSize: "16px",
  cursor: "pointer",
  backgroundColor: "transparent",
  pointerEvents: "none",
};

export function TemplatePreviewer({ template }: TemplatePreviewerProps) {
  const { deliveryMethod, message, variables } = template;

  const variableSubstitution = variables.nodes.reduce(
    (map: VariableMapping, section) => {
      section.variables.nodes.forEach(
        variable => (map[variable.name] = variable.sample),
      );

      return map;
    },
    {},
  );

  const previewClassesMap = {
    EMAIL: "u-bgColorWhite u-paddingSmall",
    SMS: "chatBubble",
    SAME_AS_LATEST: "",
  };

  assertHasTypename(template);

  return (
    <>
      <div className="show-for-small-only u-marginTopSmall u-marginBottomSmaller">
        <Heading level={5}>Preview</Heading>
      </div>
      <div className={previewClassesMap[deliveryMethod]}>
        {template.__typename !== "MessageTemplateSMS" && (
          <div className="u-borderBottom u-paddingBottomSmall u-marginBottomSmall">
            <Text>
              <MustachePreview
                template={template.subject.current}
                values={variableSubstitution}
              />
            </Text>
          </div>
        )}
        <Text>
          <MustachePreview
            template={message.current}
            values={variableSubstitution}
          />
        </Text>
        {template.__typename === "MessageTemplateSMS" && (
          <div className="u-marginTopSmall">
            <Text>
              <MustachePreview
                template={template.footerText}
                values={variableSubstitution}
              />
            </Text>
          </div>
        )}
        {template.__typename === "MessageTemplateSurvey" &&
          template.surveyEnabled && (
            <div className="u-marginTopSmall">
              <Text>
                <MustachePreview
                  template={template.survey.current}
                  values={variableSubstitution}
                />
              </Text>
              <div className="u-marginTopSmall u-textCenter">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rating => (
                  <button
                    type="button"
                    style={SURVEY_PREVIEW_STYLE}
                    key={rating}
                  >
                    {rating}
                  </button>
                ))}
              </div>
            </div>
          )}
      </div>
    </>
  );
}
