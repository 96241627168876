import classnames from "classnames";
import React from "react";
import { Button, Emphasis, Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";
import { initialStateMessages } from "./initialStateMessages";

export function UkKycInitialState() {
  const { formatMessage } = useIntl();
  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderTop",
  );

  return (
    <div className={cssClasses}>
      <Heading level={4}>
        <Emphasis variation={"highlight"}>
          {formatMessage(initialStateMessages.verifyFragment)}
        </Emphasis>{" "}
        {formatMessage(initialStateMessages.inSevenMinsFragment)}
      </Heading>
      <p>{formatMessage(initialStateMessages.initialRegistrationBody)}</p>
      <Button
        label={formatMessage(initialStateMessages.initialButtonText)}
        fullWidth={true}
        size={"base"}
        variation={"work"}
        onClick={() => (window.location.href = "/text_messaging_settings")}
      />
    </div>
  );
}
