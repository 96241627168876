import React, { useMemo } from "react";
import { Heading } from "@jobber/components/Heading";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Cell, HeaderColumns } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/messages";

interface ColumnType {
  name: MessageDescriptor;
  align?: "left" | "center" | "right";
}

const columns: ColumnType[] = [
  {
    name: messages.labourTableTeamColumnHeader,
  },
  {
    name: messages.labourTableNotesColumnHeader,
  },
  {
    name: messages.labourTableDateColumnHeader,
  },
  {
    name: messages.labourTableHoursColumnHeader,
    align: "right",
  },
] as ColumnType[];

export function LabourTableHeaderColumns() {
  const { formatMessage } = useIntl();
  const { can } = useAuthorization();

  const canViewJobCosts = can("view", "JobCosts");

  const headerColumns: ColumnType[] = useMemo(() => {
    if (!canViewJobCosts) {
      return [...columns];
    }

    return [
      ...columns,
      { name: messages.labourTableCostColumnHeader, align: "right" },
    ];
  }, [canViewJobCosts]);

  return (
    <HeaderColumns testId={"labourTableHeaderColumns"}>
      {headerColumns.map((column, index) =>
        formatHeaderColumn(column, index, formatMessage),
      )}
    </HeaderColumns>
  );
}

function formatHeaderColumn(
  { name, align = "left" }: ColumnType,
  index: number,
  formatMessage: (messageDescriptor: MessageDescriptor) => string,
) {
  return (
    <Cell key={index} size="small" align={align}>
      <Heading level={6}>{formatMessage(name)}</Heading>
    </Cell>
  );
}
