import { gql } from "@apollo/client";

export const EDIT_BILLING_INFO_DATA = gql`
  query EditBillingInfoData {
    account {
      billingInformation {
        email
        lastName
        firstName
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        creditCardDetails {
          cardType
          expMonth
          expYear
          lastFour
        }
        hasPaymentError
        startBillingOnDate
      }
    }
  }
`;

export const EDIT_BILLING_INFO_MUTATION = gql`
  mutation BillingInfoEdit($input: BillingInfoEditInput!) {
    billingInfoEdit(input: $input) {
      billingInformation {
        email
        lastName
        firstName
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        creditCardDetails {
          cardType
          expMonth
          expYear
          lastFour
        }
      }
      threeDSecureActionToken
      userErrors {
        message
        path
      }
    }
  }
`;
