import React from "react";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  BankAccountInformation,
  BankAccountInformationAction,
} from "./bankAccountInformationReducer";
import { BankAddressForm } from "./BankAddressForm";

export interface BankContactFormProps {
  bankAccountInformation: BankAccountInformation;
  bankAccountInformationDispatch(action: BankAccountInformationAction): void;
}

export function BankContactForm(props: BankContactFormProps) {
  const { bankAccountInformation, bankAccountInformationDispatch } = props;

  const [t] = useTranslation();
  const address = bankAccountInformation.debitAgreementForm.bankAddress;

  return (
    <>
      <Text>{t("whereIsYourAccountHeld")}</Text>
      <InputText
        name="bank_name"
        value={bankAccountInformation.debitAgreementForm.bankName}
        placeholder="Bank or building society name"
        onChange={handleBankNameChange}
      />
      <BankAddressForm
        address={address}
        bankAccountInformationDispatch={bankAccountInformationDispatch}
      />
    </>
  );

  function handleBankNameChange(newValue: string) {
    bankAccountInformationDispatch({
      type: "changeBankName",
      newValue,
    });
  }
}
