import { type ApolloError, useQuery } from "@apollo/client";
import type { ArrivalWindowAccountSettingsQuery } from "~/utilities/API/graphql";
import { ARRIVAL_WINDOW_ACCOUNT_SETTINGS } from "./ArrivalWindowAccountSettings.graphql";

interface UseArrivalWindowAccountSettings {
  defaultDuration: number;
  showOnboarding: boolean;
  loading: boolean;
  error?: ApolloError;
}

export function useArrivalWindowAccountSettings(): UseArrivalWindowAccountSettings {
  const { data, loading, error } = useQuery<ArrivalWindowAccountSettingsQuery>(
    ARRIVAL_WINDOW_ACCOUNT_SETTINGS,
    {
      // `showOnboarding` cannot be cached as its value changes when arrival windows are made
      fetchPolicy: "network-only",
    },
  );

  const defaultDuration =
    (data?.account?.arrivalWindowSettings.defaultDuration as number) ?? 0;
  const showOnboarding =
    data?.account?.arrivalWindowSettings.showOnboarding ?? false;

  return {
    defaultDuration,
    showOnboarding,
    loading,
    error,
  };
}
