import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

export function getDropdownButtonText(text: RegExp | string) {
  return screen.getByRole("button", { name: text });
}

export function getLabelTextForTrigger(text: string) {
  return screen.getByText(text);
}

export function getLabelTextForThen() {
  return screen.getByText("send the email campaign");
}

export function findLabelTextForDefaultCondition(text: string) {
  return screen.findByText(text);
}

export function getTooltipText(text: string) {
  return screen.getByText(text);
}

// Solo Actions
export function iHoverOnHelpIcon() {
  return userEvent.hover(screen.getByTestId("help"));
}
