import { Option, Select } from "@jobber/components/Select";
import React from "react";
import { NotificationDeliveryMethod } from "~/utilities/API/graphql";

// The option to pick the delivery method picking only seems to show on the
// Appointment & Assessment Reminders.
interface DeliveryMethodPickerProps {
  readonly value: string;
  readonly allowMethodChange?: boolean;
  onChange(value: string): void;
}

export function DeliveryMethodPicker({
  value,
  allowMethodChange = true,
  onChange,
}: DeliveryMethodPickerProps) {
  const methodPicker = (
    <Select value={value} onChange={onChange} size="small" inline={true}>
      <Option value={NotificationDeliveryMethod.SMS}>Text Message</Option>
      <Option value={NotificationDeliveryMethod.EMAIL}>Email</Option>
    </Select>
  );

  const methodMap: { [key: string]: string } = {
    EMAIL: "email",
    SMS: "text message",
    SAME_AS_LATEST: "text message or email*",
  };

  return <>{allowMethodChange ? methodPicker : methodMap[value]}</>;
}
