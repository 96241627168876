import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import type {
  ActivateConnectToGrowTrialMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ACTIVATE_GROW_TRIAL } from "jobber/connectToGrowTrial/ConnectToGrowTrial.graphql";
import { FeatureCarouselPlanEnum } from "~/components/Expansion/FeatureCarousel/featureCarouselPlans";
import { FeatureCarousel } from "~/components/Expansion/FeatureCarousel/FeatureCarousel";
import { messages } from "./messages";
import styles from "./ConnectToGrowTrialBeginModal.module.css";

export enum ConnectToGrowTrialBeginSource {
  AUTOTRIGGERED = "auto-triggered",
  DEEPLINKED = "deeplinked",
}

export interface ConnectToGrowTrialBeginModalProps {
  source: ConnectToGrowTrialBeginSource;
  showModal: boolean;
  dismissModal: () => void;
}

function ConnectToGrowTrialBeginModal({
  source,
  showModal,
  dismissModal,
}: ConnectToGrowTrialBeginModalProps) {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activateGrowTrial] =
    useMutation<ActivateConnectToGrowTrialMutation>(ACTIVATE_GROW_TRIAL);
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const ctaName = "connect_to_grow_trial_pre_trial_home_modal";

  useEffect(() => {
    if (showModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
        source,
      });
    }
  }, [showModal, source]);

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          title={formatMessage(messages.modalTitle)}
          open={showModal}
          onRequestClose={handleModalDismissal}
          dismissible
          primaryAction={{
            variation: "learning",
            label: formatMessage(messages.ctaLabel),
            onClick: handleStartFreeTrial,
            loading: isLoading,
          }}
        >
          {errorMessage && (
            <Banner type="error" dismissible={false}>
              {errorMessage}
            </Banner>
          )}
          <Content>
            <div className={styles.header}>
              <Text align="center">{formatMessage(messages.modalHeader)}</Text>
            </div>
            <FeatureCarousel
              formatMessage={formatMessage}
              plan={FeatureCarouselPlanEnum.Grow}
            />
          </Content>
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleModalDismissal() {
    dismissCTA(ctaRef, { source })();
    dismissModal();
  }

  async function handleStartFreeTrial() {
    setIsLoading(true);
    await updateTrialState();
  }

  async function updateTrialState() {
    const defaultError = new Error(formatMessage(messages.modalDefaultError));
    try {
      const { data } = await activateGrowTrial({
        variables: {
          input: {
            deeplinked: source === ConnectToGrowTrialBeginSource.DEEPLINKED,
          },
        },
      });
      const errors = data?.activateConnectToGrowTrial
        .userErrors as MutationErrors[];
      if (errors.length === 0) {
        convertCTA(ctaRef, { source })();
        window.location.href = "/home";
        dismissModal();
      } else {
        setErrorMessage(defaultError.message);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMessage(defaultError.message);
      setIsLoading(false);
    }
  }
}

function WrappedConnectToGrowTrialBeginModal({
  source,
  showModal,
  dismissModal,
}: ConnectToGrowTrialBeginModalProps) {
  return (
    <IntlProvider>
      <ConnectToGrowTrialBeginModal
        source={source}
        showModal={showModal}
        dismissModal={dismissModal}
      />
    </IntlProvider>
  );
}

export { WrappedConnectToGrowTrialBeginModal as ConnectToGrowTrialBeginModal };
