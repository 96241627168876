export const messages = {
  removeLastServicePrompt: {
    message: "Removing this service will turn off online booking",
    description:
      "Prompt when removing the last service for online booking in confirmation modal",
  },
  edit: {
    message: "Edit",
    description: "Title for Edit Service Modal",
  },
  save: {
    message: "Save",
    description: "Label for Save button in Edit Service Modal",
  },
  remove: {
    message: "Remove",
    description: "Label for Remove Service Button and Action",
  },
  orderSaved: {
    message: "Order has been saved!",
    description: "Toast message when offerings is re-ordered and saved",
  },
  genericError: {
    message: "Something went wrong. Please try again later.",
    description: "Generic error message",
  },
  onlineBookingDisabled: {
    message: "Online booking has been disabled",
    description: "Toast text when online booking is disabled via subscription",
  },
};
