/* eslint-disable max-statements */
import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { FormatTime } from "@jobber/components/FormatTime";
import { CivilTime } from "@std-proposal/temporal";
import { cloneDeep } from "lodash";
import { Heading } from "@jobber/components/Heading";
import { Modal } from "@jobber/components/Modal";
import { NotificationStringTemplate } from "jobber/settings/notifications/components/NotificationStringTemplate";
import { TemplatePreviewer } from "jobber/settings/notifications/components/TemplateEditor/components/TemplatePreviewer";
import type {
  MessageTemplateFragment,
  NotificationFieldsFragment,
  TemplateAttributes,
} from "~/utilities/API/graphql";
import { Accordion } from "components/Accordion";
import type { SettingsRowProps } from "jobber/settings/notifications/components/SettingsSection";
import { assertHasTypename } from "utilities/types/assertTypes";
import type { OnChange } from "../ArrivalWindowDialogController";
import {
  getDurationInWords,
  getMethodInWords,
  getTimeInWords,
} from "../PreviewCommsTemplate";

export interface ArrivalWindowExperimentProps {
  notification: NotificationFieldsFragment;
  scheduleTemplate: string | undefined;
  showOnboarding: boolean;
  onChange: (
    props: OnChange,
  ) => Promise<{ errors: string[]; data: unknown } | undefined>;
  section: SettingsRowProps | undefined;
  open: boolean;
}

// eslint-disable-next-line max-statements
export function ArrivalWindowExperiment({
  scheduleTemplate,
  notification,
  open,
  section,
  onChange,
}: ArrivalWindowExperimentProps) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const schedules = [...notification.schedules.nodes];
  const previewTemplates = notification.templates.nodes;
  const templates = section && section.notification.templates;
  const [saveLoading, setSaveLoading] = useState(false);

  const enabledSchedules = schedules.filter(schedule => {
    return schedule.enabled === true;
  });

  const emailPreviewerTemplate = cloneDeep(
    previewTemplates.find(
      previewTemplate => previewTemplate.__typename === "MessageTemplateEmail",
    ) as MessageTemplateFragment,
  );
  const smsPreviewerTemplate = cloneDeep(
    previewTemplates.find(
      previewTemplate => previewTemplate.__typename === "MessageTemplateSMS",
    ) as MessageTemplateFragment,
  );

  const handleBack = async () => {
    await onChange({ action: "goBackToEditArrivalWindowsStyle" });
  };

  const handleCancel = async () => {
    await onChange({ action: "dismissAllModals" });
  };

  const handleAccept = async () => {
    const templatesToUpdate = convertTemplatesForSave(templates);

    try {
      setSaveLoading(true);
      await onChange({
        action: "saveArrivalWindowOnboarding",
        updatedTemplates: { templates: templatesToUpdate },
      });
    } catch {
      // eslint-disable-next-line no-console
      console.error("Error saving arrival window onboarding");
    } finally {
      setSaveLoading(false);
    }
  };

  const previewTypes = [
    {
      title: "Text message preview",
      content: (
        <Content spacing="base">
          <TemplatePreviewer template={smsPreviewerTemplate} />
        </Content>
      ),
    },
    {
      title: "Email preview",
      content: <TemplatePreviewer template={emailPreviewerTemplate} />,
    },
  ];

  return (
    <Modal
      title="What your clients will see"
      open={open}
      onRequestClose={handleCancel}
      primaryAction={{
        label: "Save",
        onClick: () => handleAccept(),
        loading: saveLoading,
      }}
      tertiaryAction={{
        label: "Back",
        onClick: handleBack,
        type: "primary",
        variation: "subtle",
      }}
    >
      <Content spacing="base">
        <div style={{ marginBottom: "var(--space-small)" }}>
          <Heading level={5}>Visit reminders sent</Heading>
        </div>
        <ul
          data-testid="schedules"
          className="list list--bulleted u-paddingLeft u-marginBottomNone"
        >
          {scheduleTemplate &&
            enabledSchedules.map(schedule => (
              <li key={schedule.id} className="list-item">
                <Text>
                  <NotificationStringTemplate
                    template={"{duration} the appointment {time} by {method}"}
                    values={{
                      duration: (
                        <Emphasis variation="bold">
                          {getDurationInWords(schedule)}
                        </Emphasis>
                      ),
                      method: (
                        <Emphasis variation="bold">
                          {getMethodInWords(schedule.deliveryMethod)}
                        </Emphasis>
                      ),
                      time: getTimeInWords(schedule) ? (
                        <>
                          by at{" "}
                          <FormatTime
                            time={CivilTime.fromString(
                              getTimeInWords(schedule),
                            )}
                          />
                        </>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Text>
              </li>
            ))}
        </ul>
        <Accordion
          items={previewTypes}
          currentItemIndex={currentItemIndex}
          separateWithDivider={true}
          includeTitlePadding={false}
          removeBackgroundColour={true}
          setCurrentItemIndex={setCurrentItemIndex}
        />
        <Content spacing="large">
          Adjust your reminder schedule and templates at any time in your
          settings.
        </Content>
      </Content>
    </Modal>
  );
}

function convertTemplatesForSave(
  templates?: NotificationFieldsFragment["templates"],
) {
  return templates?.nodes.map(template => {
    const convertedTemplate: TemplateAttributes = {
      id: template.id,
      message: template.message.current,
    };

    assertHasTypename(template);

    if (template.__typename !== "MessageTemplateSMS") {
      convertedTemplate.subject = template.subject
        ? template.subject.current
        : "";
    }

    return convertedTemplate;
  });
}
