import { type ApolloError, useQuery } from "@apollo/client";
import type { CommsCampaignsExperienceQuery } from "~/utilities/API/graphql";
import { GET_CAMPAIGNS_EXPERIENCE_QUERY } from "jobber/campaigns/graphql";

export interface UseCommsCampaignsExperienceQueryReturn {
  loading: boolean;
  data?: CommsCampaignsExperienceQuery;
  error?: ApolloError;
}

export function useCommsCampaignsExperienceQuery(): UseCommsCampaignsExperienceQueryReturn {
  const { loading, data, error } = useQuery<CommsCampaignsExperienceQuery>(
    GET_CAMPAIGNS_EXPERIENCE_QUERY,
  );

  return {
    loading,
    data,
    error,
  };
}
