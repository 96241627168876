import { Banner } from "@jobber/components/Banner";
import React from "react";

export function ConversationsNotFoundEmptyState() {
  const message = "Unable to load messages";
  const primaryAction = {
    label: "Refresh",
    onClick: () => window.location.reload(),
  };

  return (
    <Banner type="error" primaryAction={primaryAction}>
      {message}
    </Banner>
  );
}
