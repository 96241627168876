import moment from "moment";

interface GetArrivalWindowStartTime {
  jobStartTime: Date | undefined;
  centered: boolean;
  duration: number;
}

export function getArrivalWindowStartTime({
  centered,
  duration,
  jobStartTime,
}: GetArrivalWindowStartTime): Date | undefined {
  if (!jobStartTime) {
    return;
  }

  if (!centered) {
    return jobStartTime;
  }

  return moment(jobStartTime)
    .add(-duration / 2, "m")
    .toDate();
}
