import { useQuery } from "@apollo/client";
import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { AddonUpsellContextProvider } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { AccountAddonInfoReviewsPurchasePageQuery } from "~/utilities/API/graphql";
import { PurchasePage } from "./PurchasePage";
import { ACCOUNT_ADDON_INFO_REVIEWS_PURCHASE_PAGE } from "./PurchasePage.graphql";

interface PurchasePageLoaderProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  inTrial: boolean;
}

export function PurchasePageLoader(props: PurchasePageLoaderProps) {
  return (
    <IntlProvider>
      <APIProvider>
        <InternalPurchasePageLoader {...props} />
      </APIProvider>
    </IntlProvider>
  );
}

function InternalPurchasePageLoader(props: PurchasePageLoaderProps) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    isMobileBilled,
    salesforceTrackingId,
    inTrial,
  } = props;

  const { data, loading } = useQuery<AccountAddonInfoReviewsPurchasePageQuery>(
    ACCOUNT_ADDON_INFO_REVIEWS_PURCHASE_PAGE,
    {
      variables: { addonSetIdentifier },
    },
  );

  return (
    <AddonUpsellContextProvider
      accountAddonInfo={data?.accountAddonInfo}
      addonDiscountGroup={data?.addonDiscountGroup}
    >
      <PurchasePage
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        monthlyCost={data?.accountAddonInfo?.monthlyBillingCycle?.monthlyCost}
        monthlyDiscountedCost={
          data?.addonDiscountGroup?.monthlyAddonDiscount
            ?.addonCostMonthlyDiscounted
        }
        isMobileBilled={isMobileBilled}
        discountCallToAction={
          data?.addonDiscountGroup?.monthlyAddonDiscount?.discountCallToAction
        }
        salesforceTrackingId={salesforceTrackingId}
        inTrial={inTrial}
        loading={loading}
      />
    </AddonUpsellContextProvider>
  );
}
