import React from "react";
import { useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { Spinner } from "@jobber/components/Spinner";
import { IntlProvider } from "@translations/IntlProvider";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import type { AddPaymentMethodQuery } from "~/utilities/API/graphql";
import { AddPaymentMethodComponent } from "./AddPaymentMethod";
import { ADD_PAYMENT_METHOD_QUERY } from "./AddPaymentMethod.graphql";
import {
  getCountryCodeFromMap,
  mapClientAddressToBillingAddress,
} from "./utils";
import { withSplitClient } from "../../utilities/split";

export const AddPaymentMethodLoader = withSplitClient(
  withStripeElementProvider<AddPaymentMethodLoaderProps>(
    AddPaymentMethodWithStripe,
  ),
);

export interface AddPaymentMethodLoaderProps {
  clientId: string;
  countryCodesToNameMap: Record<string, string>;
  testCompleteOverride?: boolean;
  modalOpen: boolean;
  toggleModal: () => void;
  refetchPaymentMethods: () => Promise<void>;
}

function AddPaymentMethodWithStripe({
  clientId,
  countryCodesToNameMap,
  testCompleteOverride = false,
  modalOpen,
  toggleModal,
  refetchPaymentMethods,
}: AddPaymentMethodLoaderProps) {
  const { data, loading, error } = useQuery<AddPaymentMethodQuery>(
    ADD_PAYMENT_METHOD_QUERY,
    {
      variables: { clientId: clientId },
      fetchPolicy: "no-cache",
    },
  );

  const billingAddress = mapClientAddressToBillingAddress(
    data?.client?.billingAddress,
    getCountryCodeFromMap(
      countryCodesToNameMap,
      data?.client?.billingAddress?.country,
    ),
  );

  if (loading && !data) {
    return <Spinner />;
  }

  if (error || !data) {
    return (
      <Banner type={"error"}>
        {"Something went wrong. Please try again or contact Support."}
      </Banner>
    );
  }

  return (
    <IntlProvider>
      <AddPaymentMethodComponent
        onAddPaymentMethodSuccess={refetchPaymentMethods}
        clientId={clientId}
        countryCodesToNameMap={countryCodesToNameMap}
        billingAddress={billingAddress}
        clientName={`${data.client?.firstName} ${data.client?.lastName}`.trim()}
        testCompleteOverride={testCompleteOverride}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      />
    </IntlProvider>
  );
}
