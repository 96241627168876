import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { AuthorizedBankingInfo } from ".";
import type { BankPaymentACHSettings } from "./bankPaymentACHSettings.d";
import { PlaidLinkWrapper } from "./PlaidLinkWrapper";
import type { BankInfo } from "../BankPaymentSecurityText";

interface BankPaymentACHButtonProviderProps {
  bankPaymentACHSettings: BankPaymentACHSettings;
  attachedToId: string;
  attachedToType: string;
  isQuoteDepositPayment: boolean;
  quoteSignatureData: string;
  authorizedBankingInfo: AuthorizedBankingInfo;
  oauthStateId?: string | null;
  onPaymentSubmit(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setBankPaymentErrorMessage(err: any): void;
  setBankPaymentAuthorized(auth: boolean): void;
  setBankPaymentInfoCallback(info: BankInfo): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAuthorizedBankingInfo(p: { publicToken: string; metadata: any }): void;
}

const BankPaymentACHButtonProvider = (
  props: BankPaymentACHButtonProviderProps,
) => (
  <APIProvider>
    <PlaidLinkWrapper {...props} />
  </APIProvider>
);

export { BankPaymentACHButtonProvider, type BankPaymentACHButtonProviderProps };
