import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { QuoteDetailSortKey } from "~/utilities/API/graphqlFranchiseManagement";
import {
  ComparableHeader,
  CurrencyFooter,
  InnerColumnCellFactory,
  StandardFooter,
} from "~/jobber/features/Reporting/components/Report/components/ReportCells/ReportCells";
import { createAccessorKey } from "~/jobber/features/Reporting/components/Report/utils/createAccessorKey";
import {
  INNER_CELL_WIDTH_LARGE,
  INNER_CELL_WIDTH_SMALL,
} from "~/jobber/features/Reporting/components/Report/constants";
import {
  QUOTE_STATUS_COLORS,
  QUOTE_STATUS_KEYS,
  REPORT_LABELS,
} from "jobber/franchise/features/Reporting/views/QuoteDetailReport/constants";
import type {
  ColumnInfo,
  ReportColumns,
} from "~/jobber/features/Reporting/components/Report/types";
import type {
  QuoteDetailData,
  QuoteDetailTotalsData,
} from "jobber/franchise/features/Reporting/views/QuoteDetailReport/types";

export const useQuoteDetailColumns = (
  totalsData: QuoteDetailTotalsData | undefined,
): ReportColumns<QuoteDetailData> => {
  const quoteDetailColumns = [
    {
      id: QuoteDetailSortKey.FRANCHISEE_NAME,
      accessorKey: createAccessorKey(QuoteDetailSortKey.FRANCHISEE_NAME),
      header: REPORT_LABELS.franchisee,
      footer: () => <StandardFooter text={REPORT_LABELS.totalFooter} />,
      minSize: INNER_CELL_WIDTH_LARGE,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.QUOTE_NUMBER,
      accessorKey: createAccessorKey(QuoteDetailSortKey.QUOTE_NUMBER),
      header: REPORT_LABELS.quoteNumber,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.CLIENT_NAME,
      accessorKey: createAccessorKey(QuoteDetailSortKey.CLIENT_NAME),
      header: REPORT_LABELS.clientName,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.PROPERTY_ADDRESS,
      accessorKey: createAccessorKey(QuoteDetailSortKey.PROPERTY_ADDRESS),
      header: REPORT_LABELS.propertyAddress,
      minSize: INNER_CELL_WIDTH_LARGE,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.STATUS,
      accessorKey: createAccessorKey(QuoteDetailSortKey.STATUS),
      header: REPORT_LABELS.quoteStatus,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => (
        <InlineLabel
          size="base"
          color={QUOTE_STATUS_COLORS[info.getValue() as string]}
        >
          {QUOTE_STATUS_KEYS[info.getValue() as string]}
        </InlineLabel>
      ),
    },
    {
      id: QuoteDetailSortKey.DRAFTED_ON,
      accessorKey: createAccessorKey(QuoteDetailSortKey.DRAFTED_ON),
      header: REPORT_LABELS.draftedOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.SENT_ON,
      accessorKey: createAccessorKey(QuoteDetailSortKey.SENT_ON),
      header: REPORT_LABELS.sentOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.LAST_CHANGES_REQUESTED_ON,
      accessorKey: createAccessorKey(
        QuoteDetailSortKey.LAST_CHANGES_REQUESTED_ON,
      ),
      header: REPORT_LABELS.lastChangesOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.APPROVED_ON,
      accessorKey: createAccessorKey(QuoteDetailSortKey.APPROVED_ON),
      header: REPORT_LABELS.approvedOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.CONVERTED_ON,
      accessorKey: createAccessorKey(QuoteDetailSortKey.CONVERTED_ON),
      header: REPORT_LABELS.convertedOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.ARCHIVED_ON,
      accessorKey: createAccessorKey(QuoteDetailSortKey.ARCHIVED_ON),
      header: REPORT_LABELS.archivedOn,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteDetailSortKey.TOTAL,
      accessorKey: createAccessorKey(QuoteDetailSortKey.TOTAL),
      header: () => <ComparableHeader text={REPORT_LABELS.total} />,
      footer: () => <CurrencyFooter currencyAmount={totalsData?.total} />,
      cell: InnerColumnCellFactory("currency"),
    },
  ];

  return quoteDetailColumns as ReportColumns<QuoteDetailData>;
};
