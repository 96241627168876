import type {
  MessageTemplateEmail,
  MessageTemplateSurvey,
} from "~/utilities/API/graphql";
import { ActionTypes } from "jobber/settings/notifications/ActionTypes";
import type { ActionFields } from "jobber/settings/notifications/notificationInterfaces";
import type { NotificationModalState } from "./NotificationModal";

export function templateReducer(
  templates: NotificationModalState["templates"],
  action: ActionFields,
) {
  switch (action.type) {
    case ActionTypes.ChangeTemplateSubject:
      return update(
        action.id,
        templates,
        template =>
          ((template as MessageTemplateEmail).subject.current =
            action.value as string),
      );

    case ActionTypes.ChangeTemplateMessage:
      return update(
        action.id,
        templates,
        template => (template.message.current = action.value as string),
      );

    case ActionTypes.ChangeTemplateSurvey:
      return update(
        action.id,
        templates,
        template =>
          ((template as MessageTemplateSurvey).survey.current =
            action.value as string),
      );

    case ActionTypes.ToggleTemplateSurvey:
      return update(
        action.id,
        templates,
        template =>
          ((template as MessageTemplateSurvey).surveyEnabled =
            action.value as boolean),
      );

    default:
      return templates;
  }
}

function update(
  id: string,
  collection: NotificationModalState["templates"],
  action: (item: NotificationModalState["templates"]["nodes"][0]) => void,
) {
  const item = collection.nodes.find(node => node.id === id);

  if (item) {
    action(item);
  }

  return collection;
}
