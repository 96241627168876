/* eslint-disable import/no-internal-modules */
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  NoMatch,
  join,
  withParams,
} from "jobber/settings/users/utilities/routing";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import { withSplitClient } from "utilities/split";
import { PaymentDisputesLoader } from "jobber/features/PaymentDisputes/views/DisputeListPage/PaymentDisputes.loader";
import { DisputeDetailsPage } from "./views/DisputeDetailsPage";

export const ContextWrappedPaymentDisputes = withEntryPointProviders(() => {
  const { path } = useRouteMatch();
  const renderDisputeList = () => {
    return <PaymentDisputesLoader />;
  };

  const renderDisputeDetails = ({ id }: { id: string }) => {
    const disputeDetailsProps = {
      id: id,
    };

    return <DisputeDetailsPage {...disputeDetailsProps} />;
  };

  return (
    <Switch>
      <Route
        path={join(path, ":id")}
        render={withParams<{ id: string }>(renderDisputeDetails)}
      />
      <Route exact path={join(path)} render={renderDisputeList} />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
});
const Enhanced = withSplitClient(
  withRailsPropsAsContexts()(ContextWrappedPaymentDisputes),
);
export { Enhanced as PaymentDisputesRoute };
