import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import communityImgSrc from "@images/signup/collage-community.jpg";
import { messages as CommunityPageMessages } from "./messages";
import { messages as CommunityPageFormMessages } from "./components/messages";
import { CommunityPageForm } from "./components/CommunityPageForm";
import { CommunityPageInfo } from "./components/CommunityPageInfo";

export const communityPageImage: ImageType = {
  alt: CommunityPageMessages.serviceProviderCollageAltText,
  src: communityImgSrc,
};

export function CommunityPage(stepProps: SetupWizardStepProps) {
  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.profile,
    });
  }, []);

  const pageHeading = (
    <div data-testid="community-heading">
      <Heading level={2}>
        <FormattedMessage
          {...CommunityPageFormMessages.communityHeading}
          values={{
            span: chunks => <span className="highlighter">{chunks}</span>,
          }}
        />
      </Heading>
    </div>
  );

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={pageHeading}
          copy={""}
          form={<CommunityPageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={communityPageImage}>
          <CommunityPageInfo />
        </MediaColumn>
      }
    />
  );
}
