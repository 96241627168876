import React from "react";
import { CardBrand as DefaultCardBrand } from "components/CardBrand";
import type { CardBrandProps } from "components/CardBrand";
import {
  ListItem as DefaultListItem,
  type ListItemProps,
} from "components/ListItem/ListItem";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { ExpiryStatus } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import { Expiry as DefaultExpiry, type ExpiryProps } from "./Expiry";

export interface CreditCardListItemProps extends CreditCard {
  size?: "base" | "compact";
  showDropDownMenu?: boolean;
  isLoading?: boolean;

  CardBrand?: React.ComponentType<CardBrandProps>;
  ListItem?: React.ComponentType<ListItemProps>;
  Expiry?: React.ComponentType<ExpiryProps>;

  onDeleteButtonClick?(): void;

  onSetDefaultButtonClick?(): void;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function CreditCardListItem(props: CreditCardListItemProps) {
  const {
    brand,
    last4,
    isDefault,
    expiryMonth,
    expiryYear,
    expiryStatus = ExpiryStatus.DEFAULT,
    isLoading,
    size = "base",
    ListItem = DefaultListItem,
    CardBrand = DefaultCardBrand,
    Expiry = DefaultExpiry,
  } = props;

  const titleLabel = isDefault ? "DEFAULT" : "";

  if (size === "compact") {
    return (
      <div className="row row--tightColumns align-middle">
        <CardBrand brand={brand} size="small" />
        <span className="columns">•••• {last4}</span>
      </div>
    );
  }

  return (
    <ListItem
      isLoading={isLoading}
      leftNode={<CardBrand brand={brand} />}
      title="CARD NUMBER"
      titleLabel={titleLabel}
      subtitle={`●●●● ${last4}`}
      rightNode={
        <Expiry month={expiryMonth} year={expiryYear} status={expiryStatus} />
      }
    />
  );
}
