import { useCallback, useLayoutEffect, useRef, useState } from "react";

export function useRows(rows: number) {
  const [height, setHeight] = useState(20);
  const richTextContainer = useRef<HTMLDivElement | null>(null);
  const resize = useCallback(
    (element: HTMLDivElement) => {
      const { lineHeight } = window.getComputedStyle(element);
      const newHeight = parseFloat(lineHeight) * rows;
      setHeight(newHeight);
    },
    [rows],
  );

  useLayoutEffect(() => {
    if (
      richTextContainer &&
      richTextContainer.current instanceof HTMLDivElement
    ) {
      resize(richTextContainer.current);
    }
  }, [richTextContainer.current, resize]);

  return { richTextContainer, richTextContainerHeight: height };
}
