import { useIntl } from "react-intl";
import { MagicInputText } from "legacy/components/MagicInputText/MagicInputText";
import { messages } from "./messages";

interface LineItemDescriptionProps {
  description: string;
  reactKey: string;
  onChange: (value: string) => void;
}

export function LineItemDescription({
  description,
  reactKey,
  onChange,
}: LineItemDescriptionProps) {
  const { formatMessage } = useIntl();

  return (
    <MagicInputText
      name={`lineItems.${reactKey}.description`}
      placeholder={formatMessage(messages.description)}
      multiline
      rows={{
        min: 3,
        max: 50,
      }}
      autocomplete={false}
      onChange={onChange}
      value={description}
    />
  );
}
