import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { AUTOMATION_RULE_EDIT_MUTATION } from "~/jobber/automations/graphql/AutomationRuleEdit.graphql";
import type {
  AutomationRuleEditMutation,
  AutomationRuleEditMutationVariables,
} from "~/utilities/API/graphql";

export interface UseEditAutomationRuleMutationProps {
  variables: AutomationRuleEditMutationVariables;
  onError: () => void;
  onCompleted: (data: AutomationRuleEditMutation) => void;
}

export function useEditAutomationRuleMutation() {
  const [updateAutomation, { data }] = useMutation<
    AutomationRuleEditMutation,
    AutomationRuleEditMutationVariables
  >(AUTOMATION_RULE_EDIT_MUTATION);

  const editAutomationRule = useCallback(
    async ({
      variables,
      onError,
      onCompleted,
    }: UseEditAutomationRuleMutationProps) => {
      const mutationResult = await updateAutomation({
        variables,
        onCompleted: onCompleted,
        onError: onError,
      });
      return {
        rule: mutationResult?.data?.automationRuleEdit.rule,
        userErrors: mutationResult?.data?.automationRuleEdit.userErrors ?? [],
      };
    },
    [updateAutomation],
  );

  return { editAutomationRule, data };
}
