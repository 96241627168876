import React, { useEffect, useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { createPortal } from "react-dom";

type BannerProps = Parameters<typeof Banner>[0];
type FlashBannerProps = BannerProps;

const flashRoot = document.querySelector(".js-reactFlashPortal") as Element;

export function FlashBanner(props: FlashBannerProps) {
  const [portalElement] = useState(() => document.createElement("div"));

  useEffect(() => {
    flashRoot.appendChild(portalElement);

    return () => void flashRoot.removeChild(portalElement);
  }, []);

  return createPortal(<Banner {...props} />, portalElement);
}
