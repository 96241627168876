import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "setupGuide.buildingDashboard.header",
    defaultMessage: "Building your dashboard",
    description: "Title for the building dashboard step",
  },
  body: {
    id: "setupGuide.buildingDashboard.body",
    defaultMessage:
      "Your review automation and dashboard tools are being set up!",
    description: "Body for the building dashboard step",
  },
});
