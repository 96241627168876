import { gql } from "@apollo/client";

export const PHONE_NUMBERS_FOUND_SUBSCRIPTION = gql`
  subscription PhoneNumbersFound {
    phoneNumbersFound {
      phoneNumbers
      errors
    }
  }
`;

export const FIND_PHONE_NUMBERS_MUTATION = gql`
  mutation FindPhoneNumbers {
    findPhoneNumbers {
      status
    }
  }
`;

export const PURCHASE_DEDICATED_PHONE_NUMBER = gql`
  mutation PurchaseDedicatedPhoneNumber(
    $input: PurchaseDedicatedPhoneNumberInput!
  ) {
    purchaseDedicatedPhoneNumber(input: $input) {
      dedicatedPhoneNumber {
        friendlyPhoneNumber
        phoneNumber
      }
      twilioCarrierRegistration {
        id
      }
      userErrors {
        message
        path
      }
    }
  }
`;
