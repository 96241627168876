import React, { useCallback, useContext } from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import type { BillingCycleNameType } from "~/shared/billing/pricePreview/types";
import { pricePerPeriod } from "~/shared/billing/pricePreview/utils";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import styles from "./BillingCycleRadioInput.module.css";

interface BillingCycleRadioInputProps {
  selected: boolean;
  option: BillingCycleNameType;
  label: string;
  price?: number;
  onSetSelectedCycle(cycle: string): void;
  savingsMessage?: string;
}

export function BillingCycleRadioInput({
  selected,
  option,
  label,
  price,
  onSetSelectedCycle,
  savingsMessage,
}: BillingCycleRadioInputProps) {
  const setAsSelected = useCallback(() => {
    onSetSelectedCycle(option);
  }, [option, onSetSelectedCycle]);

  const { submitting } = useContext(PurchaseFormContext);

  if (!price) {
    return null;
  }

  return (
    <div className={styles.radioGroupContainer}>
      <div className={styles.columnContainer}>
        <div className={styles.radioOption}>
          <input
            name={"billingCycle"}
            id={option}
            value={option}
            type="radio"
            onChange={setAsSelected}
            checked={selected}
            aria-checked={selected}
            disabled={submitting}
          />
          <label htmlFor={option}>{label}</label>
          {savingsMessage && (
            <InlineLabel color="green">{savingsMessage}</InlineLabel>
          )}
        </div>
        <Text size="large">
          <Emphasis variation="bold">{pricePerPeriod(price, "month")}</Emphasis>
        </Text>
      </div>
    </div>
  );
}
