import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { CancellationFormModal } from "./CancellationFormModal";
import type { CancellationFormProps } from "./CancellationForm.d";

export function CancellationForm({
  nextBillingDate,
  additionalSubscription,
  offerSaveCoupon,
}: CancellationFormProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button
        variation="destructive"
        label="Cancel Your Account"
        onClick={showModal}
      />
      <CancellationFormModal
        nextBillingDate={nextBillingDate}
        additionalSubscription={additionalSubscription}
        open={modalOpen}
        offerSaveCoupon={offerSaveCoupon}
        closeModal={closeModal}
      />
    </>
  );
}
