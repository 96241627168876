import { gql } from "@apollo/client";

export const QUOTE_DETAIL_QUERY = gql`
  query QuoteDetail(
    $sort: QuoteDetailSortInput
    $filter: QuoteDetailFilterInput
    $first: Int
    $after: String
  ) {
    quoteDetailTotals(filter: $filter) {
      total
    }
    quoteDetail(sort: $sort, filter: $filter, after: $after, first: $first) {
      nodes {
        total
        status
        sentOn
        quoteNumber
        propertyAddress
        lastChangesRequestedOn
        franchiseeName
        franchiseeId
        clientName
        convertedOn
        draftedOn
        archivedOn
        approvedOn
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const QUOTE_DETAIL_EXPORT_MUTATION = gql`
  mutation QuoteDetailExport($input: ExportQuoteDetailCsvInput) {
    exportQuoteDetailCsv(input: $input) {
      response
    }
  }
`;
