import React, { Component } from "react";
import {
  ClientHubMenuDisplay,
  type MenuItemProps,
} from "./ClientHubMenuDisplay";

interface ClientHubMenuPreviewState {
  workRequestsVisible: boolean;
  quoteInvoicesVisible: boolean;
}

/**
 * ClientHubMenuPreview react component.
 */

export class ClientHubMenuPreview extends Component<
  Record<string, never>,
  ClientHubMenuPreviewState
> {
  public constructor(props: Record<string, never>) {
    super(props);
    this.state = this.getVisibilityFromSettings();
    this.handlePreviewCheckboxToggled =
      this.handlePreviewCheckboxToggled.bind(this);
  }

  public componentDidMount() {
    const checkboxes = Array.from(
      document.getElementsByClassName("js-previewCheckboxReact"),
    );

    for (const checkbox of checkboxes) {
      checkbox.addEventListener(
        "click",
        this.handlePreviewCheckboxToggled,
        false,
      );
    }
  }

  public handlePreviewCheckboxToggled() {
    const newState = this.getVisibilityFromSettings();
    this.setState(newState);
  }

  public render() {
    const menuItems = this.generateMenuItems();

    return (
      <ClientHubMenuDisplay
        workRequestsVisible={this.state.workRequestsVisible}
        menuItems={menuItems}
      />
    );
  }

  private generateMenuItems(): MenuItemProps[] {
    const menuItems: MenuItemProps[] = [];

    if (this.state.quoteInvoicesVisible) {
      menuItems.push({
        label: "Quotes",
        isSelected: !this.state.workRequestsVisible,
        iconType: "quote",
      });
    }

    menuItems.push({
      label: "Appointments",
      isSelected:
        !this.state.workRequestsVisible && !this.state.quoteInvoicesVisible,
      iconType: "calendar",
    });

    if (this.state.quoteInvoicesVisible) {
      menuItems.push({
        label: "Invoices",
        isSelected: false,
        iconType: "invoice",
      });
    }

    return menuItems;
  }

  private getVisibilityFromSettings(): ClientHubMenuPreviewState {
    const workRequestInputElement =
      document.getElementById("work_requests_read");
    const quoteInvoiceCheckboxElement = document.getElementById(
      "quotes_and_invoices_read",
    );

    return {
      workRequestsVisible: checkInputValue(workRequestInputElement),
      quoteInvoicesVisible: checkCheckboxValue(quoteInvoiceCheckboxElement),
    };
  }
}

function checkCheckboxValue(element: HTMLElement | null): boolean {
  if (element === null) {
    return false;
  }
  const inputElement = element as HTMLInputElement;

  return inputElement.checked;
}

function checkInputValue(element: HTMLElement | null): boolean {
  if (element === null) {
    return false;
  }
  const inputElement = element as HTMLInputElement;

  return inputElement.value === "true";
}
