import { defineMessages } from "react-intl";

export const messages = defineMessages({
  nameLabel: {
    id: "paymentDetailsDisplay.nameLabel",
    defaultMessage: "Name",
    description: "Label text for name field",
  },
  cardLabel: {
    id: "paymentDetailsDisplay.cardLabel",
    defaultMessage: "Card",
    description: "Label text for card field",
  },
  expirationLabel: {
    id: "paymentDetailsDisplay.expirationLabel",
    defaultMessage: "Expiration",
    description: "Label text for expiration field",
  },
});
