import React from "react";
import { Banner } from "@jobber/components/Banner";

interface EmailErrorBannerProps {
  /** Email flashError from the component */
  flashError: string;
}

/* Email Error Banner Component */
export function EmailErrorBanner({ flashError }: EmailErrorBannerProps) {
  if (flashError) {
    return (
      <Banner type="error" dismissible={false}>
        {flashError}
      </Banner>
    );
  } else {
    return <></>;
  }
}
