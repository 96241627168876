import { SupportedLocale } from "./types";
import EnglishMessages from "./en.json";
import EnglishAustralia from "./en-AU.json";
import EnglishCanada from "./en-CA.json";
import EnglishGreatBritain from "./en-GB.json";
import EnglishUnitedStates from "./en-US.json";

export const messages = {
  [SupportedLocale.EN]: EnglishMessages,
  [SupportedLocale.EN_AU]: EnglishAustralia,
  [SupportedLocale.EN_CA]: EnglishCanada,
  [SupportedLocale.EN_GB]: EnglishGreatBritain,
  [SupportedLocale.EN_US]: EnglishUnitedStates,
};
