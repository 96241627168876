import React, { useState } from "react";
import { type ActionProps, Menu } from "@jobber/components/Menu";
import { APIProvider } from "~/utilities/API/APIProvider";
import { LeadRouting } from "jobber/leadRouting/components/LeadRouting/LeadRouting";
import { submitDomForm } from "utilities/submitDomForm";
import { FranchiseButton } from "jobber/leadRouting/components/LeadRouting/FranchiseButton";

interface Account {
  id: number;
  name: string;
}

export interface AccountPickerProps {
  accounts: Account[];
  currentAccount: Account;
  fromSideNav: boolean;
  readonly leadRoutingEnabled?: boolean;
}

export function AccountPicker({
  accounts,
  currentAccount,
  fromSideNav,
  leadRoutingEnabled,
}: AccountPickerProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigateToFranchise = (accountId: number) => {
    submitDomForm({ action: `/accounts/${accountId}/switch`, method: "post" });
  };

  const formattedAccounts = accounts.map(el => {
    return {
      label: el.name,
      shouldFocus: leadRoutingEnabled ? false : el.id === currentAccount.id,
      onClick: () => {
        navigateToFranchise(el.id);
      },
    };
  });
  const items: { actions: ActionProps[] }[] = [
    {
      actions: formattedAccounts,
    },
  ];
  if (leadRoutingEnabled) {
    items.unshift({
      actions: [
        {
          label: "Route a lead",
          icon: "sync",
          onClick: () => {
            setModalOpen(true);
          },
        },
      ],
    });
  }
  const activator = (
    <FranchiseButton label={currentAccount.name} fromSideNav={fromSideNav} />
  );
  const dropdownMenu = <Menu activator={activator} items={items} />;

  return (
    <APIProvider>
      {modalOpen && (
        <LeadRouting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
      {dropdownMenu}
    </APIProvider>
  );
}
