import React, { useState } from "react";
import { Icon } from "@jobber/components/Icon";
import classes from "./SecondaryLogo.module.css";

interface SecondaryLogoProps {
  imgSrc: string;
}

export function SecondaryLogo({ imgSrc }: SecondaryLogoProps) {
  const [containerClassStyle, setContainerStyles] = useState(
    classes.containerExpanded,
  );
  const sidebar = document.querySelectorAll(".sidenav.js-sidenav")[0];

  const callback = (mutationList: [MutationRecord]) => {
    for (const mutation of mutationList) {
      if (mutation.attributeName === "class") {
        const sidebarCollapsed =
          document.querySelectorAll(".sidenav.js-sidenav.is-collapsed").length >
          0;
        const style = sidebarCollapsed
          ? classes.containerCollapsed
          : classes.containerExpanded;
        setContainerStyles(style);
      }
    }
  };

  if (sidebar) {
    const observer = new MutationObserver(callback);
    observer.observe(sidebar, { attributes: true });
  }

  return (
    <div className={`${classes.container} ${containerClassStyle}`}>
      <Icon name="add" size="small" color="greyBlue" />
      <div
        data-testid={`secondaryLogo`}
        className={`${classes.icon} ${classes.secondaryLogo}`}
        style={{
          backgroundImage: `url(${imgSrc})`,
        }}
      />
    </div>
  );
}
