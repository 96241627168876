interface ConversionRateInput {
  unconverted: number;
  converted: number;
}

export function calculateAndFormatQuoteConversionRate({
  unconverted,
  converted,
}: ConversionRateInput) {
  let conversionRate = 0;

  if (unconverted > 0 && converted > 0) {
    conversionRate = Math.round(Math.abs((converted / unconverted) * 100));
  }

  return conversionRate;
}
