import React from "react";
import { useIntl } from "react-intl";
import { Body, Cell, Header, Row, Table } from "components/Table";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { messages } from "./messages";
import styles from "./LineItemsTableEmptyState.module.css";
import { LineItemsTableTitle } from "../LineItemsTableTitle";

interface LineItemsTableEmptyStateProps {
  dispatch: (action: LineItemsAction) => void;
}

export function LineItemsTableEmptyState({
  dispatch,
}: LineItemsTableEmptyStateProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.lineItemsTableEmptyState}>
      <Table>
        <Header>
          <LineItemsTableTitle dispatch={dispatch} />
        </Header>
        <Body>
          <Row>
            <Cell>{formatMessage(messages.lineItemsTableEmptyState)}</Cell>
          </Row>
        </Body>
      </Table>
    </div>
  );
}
