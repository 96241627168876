import { gql } from "@apollo/client";

export const GET_CONVERSATION_ID_QUERY = gql`
  query GetConversationId($externalPhoneNumber: String) {
    conversations(
      filter: {
        externalPhoneNumber: $externalPhoneNumber
        includeEmptyConversations: true
      }
    ) {
      edges {
        node {
          deletedAt
          id
        }
        cursor
      }
    }
  }
`;

export const CREATE_CONVERSATION_MUTATION = gql`
  mutation CreateConversation($recipient: String) {
    smsConversationCreate(recipient: $recipient) {
      conversation {
        id
      }
    }
  }
`;

export const clientPhoneData = gql`
  fragment clientPhoneData on ClientPhoneNumber {
    id
    description
    friendly
    number
    normalizedPhoneNumber
    client {
      id
      name
    }
  }
`;

export const CLIENT_PHONES_QUERY = gql`
  query ClientPhones($searchTerm: String, $cursor: String) {
    clientPhones(
      searchTerm: $searchTerm
      filter: {
        smsAllowed: true
        smsStopped: false
        isValidSmsPhoneNumber: true
      }
      first: 50
      after: $cursor
    ) {
      edges {
        node {
          ...clientPhoneData
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
  ${clientPhoneData}
`;
