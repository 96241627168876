import { Emphasis } from "@jobber/components/Emphasis";
import React, { type PropsWithChildren } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { StandardRegistrationInformation } from "jobber/settings/dedicatedPhoneNumber/components/standardRegistrationInformation/StandardRegistrationInformation";

interface RegistrationContentProps {
  savedPhoneNumber: string | undefined;
}

export function RegistrationContent({
  savedPhoneNumber,
  children,
}: PropsWithChildren<RegistrationContentProps>) {
  return (
    <Content>
      <Text size="large">
        <Emphasis variation="bold">
          Your dedicated phone number: {savedPhoneNumber}
        </Emphasis>
      </Text>
      <StandardRegistrationInformation />
      {children}
    </Content>
  );
}
