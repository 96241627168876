import React, { useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { APIProvider } from "~/utilities/API/APIProvider";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { QuickbooksLegacyMigrationModal } from "../QuickbooksLegacyMigrationModal";

export interface QuickbooksLegacyMigrationBannerProps {
  redirectUrl: string;
}

export function QuickbooksLegacyMigrationBanner(
  props: QuickbooksLegacyMigrationBannerProps,
) {
  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState<boolean>(false);
  return (
    <>
      <APIProvider>
        <QuickbooksLegacyMigrationModal
          open={isMigrationModalOpen}
          onRequestClose={() => setIsMigrationModalOpen(!isMigrationModalOpen)}
          redirectUrl={props.redirectUrl}
        />
      </APIProvider>
      <div className={styles.bannerContainer}>
        <Banner
          type={"notice"}
          dismissible={false}
          primaryAction={{
            label: messages.banner.action,
            onClick: () => setIsMigrationModalOpen(!isMigrationModalOpen),
          }}
        >
          {messages.banner.message}
        </Banner>
      </div>
    </>
  );
}
