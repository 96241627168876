import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import { Modal } from "@jobber/components/Modal";
import { InternalGlobalBannerCountdown } from "jobber/features/GlobalBanner/components/InternalGlobalBannerCountdown/";
import { GlobalBannerMessage } from "jobber/features/GlobalBanner/components/GlobalBannerMessage";
import type { GlobalMessage } from "~/utilities/API/graphql";
import { GlobalBannerDismissButton } from "jobber/features/GlobalBanner/components/GlobalBannerDismissButton";
import { GlobalBannerTypeIcon } from "jobber/features/GlobalBanner/components/GlobalBannerTypeIcon";
import {
  GlobalBannerModal,
  bannersWithModals,
} from "jobber/features/GlobalBanner/components/GlobalBannerModal";
import { bannersWithCTADismissal } from "jobber/features/GlobalBanner/components/InternalGlobalBanner";
import { useGlobalMessageDismissMutation } from "jobber/features/GlobalBanner/hooks";
import styles from "./GlobalBannerMessagesModal.module.css";

interface GlobalBannerMessagesModalProps {
  readonly messages: GlobalMessage[];
  readonly open: boolean;
  onRequestClose(): void;
  handleActionViewed(message: GlobalMessage): void;
  actionCallback: (
    message: GlobalMessage,
    toggleModalCallback: () => void,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function GlobalBannerMessagesModal({
  messages,
  open,
  onRequestClose,
  handleActionViewed,
  actionCallback,
}: GlobalBannerMessagesModalProps) {
  const [actionModalOpen, setActionModalOpen] = useState<string | undefined>();
  const { handleGlobalMessageDismiss } = useGlobalMessageDismissMutation();

  useEffect(() => {
    if (open) {
      handleActionViewedAll();
    }
  }, [open]);

  const globalMessages = () => {
    return (
      <Content>
        {messages.map((content, index) => (
          <Content key={content.id}>
            <div className={styles.message} data-testid="message">
              <div className={styles.content}>
                <GlobalBannerTypeIcon type={content.type} />
                <div className={styles.contentMessage}>
                  <Text>
                    <GlobalBannerMessage
                      message={content.message}
                      postMessageLink={content.postMessageLink}
                    />
                  </Text>
                  {content.expiryDate && (
                    <Text>
                      <InternalGlobalBannerCountdown
                        onComplete={onRequestClose}
                        expiryDate={content.expiryDate}
                      />
                    </Text>
                  )}
                </div>
              </div>
              <div className={styles.actions}>
                {content.action && (
                  <Button
                    label={content.action.label}
                    url={content.action.url}
                    size="small"
                    type="tertiary"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      actionCallback(content, () =>
                        toggleActionModal(content.id),
                      )(event);
                      if (shouldDismissOnCTA(content.id)) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        handleDismiss(content.id).catch();
                      }
                    }}
                  />
                )}
                {shouldOpenModal(content.id) &&
                  actionModalOpen === content.id && (
                    <GlobalBannerModal
                      globalMessageId={content.id}
                      onClose={() => {
                        toggleActionModal(content.id);
                      }}
                    />
                  )}
                {content.dismissable && (
                  <GlobalBannerDismissButton id={content.id} />
                )}
              </div>
            </div>
            {index !== messages.length - 1 && <Divider />}
          </Content>
        ))}
      </Content>
    );
  };

  return (
    <Modal
      open={open}
      title="Messages"
      onRequestClose={onRequestClose}
      primaryAction={{ label: "Got it", onClick: onRequestClose }}
    >
      {globalMessages()}
    </Modal>
  );

  function toggleActionModal(messageId: string) {
    if (actionModalOpen === undefined && shouldOpenModal(messageId)) {
      setActionModalOpen(messageId);
    } else {
      setActionModalOpen(undefined);
    }
  }

  function shouldOpenModal(messageId: string): boolean {
    return bannersWithModals.includes(messageId);
  }

  function handleActionViewedAll() {
    messages.forEach(message => {
      if (message?.action?.tracking) {
        handleActionViewed(message);
      }
    });
  }

  function shouldDismissOnCTA(messageId: string): boolean {
    return bannersWithCTADismissal.includes(messageId);
  }

  async function handleDismiss(messageId: string) {
    await handleGlobalMessageDismiss(messageId);
  }
}
