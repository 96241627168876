import React from "react";
import type { BillingStrategy, JobViewProfit } from "~/utilities/API/graphql";
import type { LineItem } from "~/jobber/lineItems/types";
import { OneOffJobProfitHeader } from "./components/OneOffJobProfitHeader";
import { RecurringJobProfitHeader } from "./components/RecurringJobProfitHeader";

export interface JobCostRecord extends JobViewProfit {
  recordDoesNotExist?: boolean;
}

interface JobCostHeaderProps {
  jobCosting: JobCostRecord;
  jobId: string;
  isRecurring: boolean;
  billingType: BillingStrategy;
  lineItems?: LineItem[];
}

export function JobCostHeader({
  jobCosting,
  jobId,
  isRecurring,
  billingType,
  lineItems,
}: JobCostHeaderProps) {
  return (
    <>
      {isRecurring ? (
        <RecurringJobProfitHeader
          jobId={jobId}
          jobCosting={jobCosting}
          billingType={billingType}
          lineItems={lineItems}
        />
      ) : (
        <OneOffJobProfitHeader jobId={jobId} jobCosting={jobCosting} />
      )}
    </>
  );
}
