import React from "react";
import { useQuery } from "@apollo/client";
import type { GlobalMessagesQueryQuery } from "~/utilities/API/graphql";
import { useGlobalMessageDismissMutation } from "jobber/features/GlobalBanner/hooks";
import { GLOBAL_MESSAGES_QUERY } from "jobber/features/GlobalBanner/components/GlobalBanner";
import { InternalGlobalBanner } from "jobber/features/GlobalBanner/components/InternalGlobalBanner";

export function GlobalBannerLoader() {
  const { data, error, loading } = useQuery<GlobalMessagesQueryQuery>(
    GLOBAL_MESSAGES_QUERY,
  );

  const { handleGlobalMessageDismiss } = useGlobalMessageDismissMutation();

  const messages = data?.messages?.nodes;

  if (error || loading || !messages?.length) {
    return <></>;
  }

  return (
    <InternalGlobalBanner
      messages={messages}
      onRequestDismiss={handleGlobalMessageDismiss}
    />
  );
}
