import React from "react";
import type { QueryTuple } from "@apollo/client";
import { MessageComposer } from "jobber/chat/components/MessageComposer";
import { optimisticCouldBePhoneNumber } from "jobber/chat/utilities/phoneNumberCheck";
import type {
  ClientPhonesQuery,
  ClientPhonesQueryVariables,
} from "~/utilities/API/graphql";
import { SMSComposeMessageRenderer } from "jobber/chat/components/SmsComposerPage/components/SMSComposeMessageRenderer";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { SelectedRecipientType } from "jobber/chat/types";

interface SmsComposerPageProps {
  fetchRecipients: QueryTuple<ClientPhonesQuery, ClientPhonesQueryVariables>;
}

export function SmsComposerPage({ fetchRecipients }: SmsComposerPageProps) {
  function trackOpenTexting() {
    Amplitude.TRACK_EVENT("SMS Sent", {
      app: "Online",
      source: "Message Center",
      interaction: "Sent an Open Text Message",
    });
  }

  const composerRenderer = (recipient: SelectedRecipientType) => {
    return (
      <SMSComposeMessageRenderer
        phoneNumber={recipient.contactInfo}
        afterSend={trackOpenTexting}
      />
    );
  };

  return (
    <MessageComposer
      fetchRecipients={fetchRecipients}
      canCreateConversation={optimisticCouldBePhoneNumber}
      composeRenderer={composerRenderer}
    />
  );
}
