import React, { useState } from "react";
import { render, screen, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { SearchBox, type SearchBoxProps } from "./SearchBox";

/**
 * Sets up the SearchBox component with the provided options.
 *
 * @param options - Optional configuration for the SearchBox component.
 * @returns void
 */
export function setup(options?: Partial<Pick<SearchBoxProps, "onSearch">>) {
  render(<SearchBoxMock onSearch={options?.onSearch ?? emptyFn} />);
}

/**
 * Clears the search input by clicking on the clear search button.
 */
export function clearSearch() {
  const clearSearchBtn = getClearSearchBtn();

  userEvent.click(clearSearchBtn);
}

/**
 * Retrieves the clear search button element.
 *
 * @returns The clear search button element.
 */
export function getClearSearchBtn() {
  return component().getByRole("button", { name: "Clear input" });
}

/**
 * Clicks the search button to initiate the search.
 */
export function searchOnClick() {
  const searchBtn = getSearchBtn();

  userEvent.click(searchBtn);
}

/**
 * Retrieves the search button icon element within the search box component.
 * @returns The search button icon element.
 */
export function getSearchBtnIcon() {
  return within(getSearchBtn()).queryByTestId("search");
}

/**
 * Retrieves the search button element.
 *
 * @returns The search button element.
 */
export function getSearchBtn() {
  return component().getByRole("button", { name: "Search Apps" });
}

/**
 * Performs a search by pressing the enter key.
 */
export function searchOnEnter() {
  const searchInput = getSearchInput();

  userEvent.type(searchInput, "{enter}");
}

/**
 * Types the search term into the search input.
 *
 * @param searchTerm - The search term to type.
 */
export function typeOnSearchInput(searchTerm: string) {
  const searchInput = getSearchInput();

  userEvent.type(searchInput, searchTerm);
}

/**
 * Retrieves the search input element.
 * @returns The search input element.
 */
export function getSearchInput() {
  return component().getByRole("textbox", {
    name: "Search app names or features",
  });
}

/**
 * Retrieves the root element of the marketplace search box.
 * @returns The root element of the marketplace search box.
 */
function root() {
  return screen.getByTestId("marketplace-search-box");
}

/**
 * Returns the component within the root element.
 * Allows for querying within the root element.
 * @returns {Component} The component within the root element.
 */
function component() {
  return within(root());
}

function emptyFn() {
  // empty
}

/**
 * Mock component for the SearchBox.
 *
 * @param onClearSearch - Callback function to clear the search.
 * @param onSearch - Callback function to perform the search.
 * @returns JSX.Element - The rendered SearchBox component.
 */
function SearchBoxMock({ onSearch }: Pick<SearchBoxProps, "onSearch">) {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <SearchBox
      value={searchTerm}
      onChange={newValue => setSearchTerm(newValue.toString())}
      onSearch={onSearch}
    />
  );
}
