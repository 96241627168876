import { defineMessages } from "react-intl";

export const messages = defineMessages({
  goBack: {
    id: "reviews.setupGuide.goBack",
    defaultMessage: "Go back",
    description: "Button that navigates to the previous step",
  },
  continue: {
    id: "reviews.setupGuide.continue",
    defaultMessage: "Continue",
    description: "Button that navigates to the continue step",
  },
  generalError: {
    id: "reviews.setupGuide.generalErrorBanner",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "A general warning that something has gone wrong while loading Google reviews",
  },
});
