import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.configurationLabel",
    defaultMessage: "Message",
    description: "Label for the configuration option",
  },
  errorMessage: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Message displayed when an error occurs",
  },
  saveMessage: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.saveMessage",
    defaultMessage: "Updated confirmation message",
    description:
      "Toast message for successfully saving the confirmation message",
  },
  modalTitle: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.modalTitle",
    defaultMessage: "Edit Message",
    description: "Title of the edit modal",
  },
  modalSave: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.modalSave",
    defaultMessage: "Save",
    description: "Save button text",
  },
  modalCancel: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.modalCancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  inputTextPlaceholder: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.inputTextPlaceholder",
    defaultMessage: "Custom Message",
    description:
      "Placeholder text for the input on the Terms and Conditions modal",
  },
  inputTextDescription: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.inputTextDescription",
    defaultMessage:
      "Enter in any cancellation policies, terms, or information about when payment will be collected.",
    description: "Text shown below the input on the Terms and Conditions modal",
  },
  messageEmpty: {
    id: "selfServeBookings.ConfirmationMessageConfiguration.messageEmpty",
    defaultMessage: "No custom message added",
    description: "Text shown when no message is set",
  },
});
