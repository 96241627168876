import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Chip,
  Chips,
  Content,
  Form,
  Heading,
  InputValidation,
} from "@jobber/components";
import { companyRevenueArray } from "jobber/setupWizard/components/constants";
import { Routes } from "jobber/setupWizard/types";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  generateLastSubmittedStepQandA,
  safelyExtractAnswer,
} from "jobber/setupWizard/utils";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

export function RevenuePageForm({
  navigation,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const [companyRevenue, setCompanyRevenue] = useState<string>(() => {
    return safelyExtractAnswer(
      "business_revenue",
      wizardData.questionsAndAnswers,
    );
  });

  const handleSubmit = async () => {
    if (!companyRevenue) {
      if (!showValidationErrors) {
        setShowValidationErrors(true);
      }
      return;
    }

    const dataToSend = {
      questionsAndAnswers: [
        {
          question: "business_revenue",
          answer: companyRevenue,
        },
        generateLastSubmittedStepQandA(Routes.revenue),
      ],
    };

    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  const handleCompanyRevenueChange = (revenue: string) => {
    setCompanyRevenue(revenue);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content spacing="large">
        <Content>
          <Heading level={5}>
            {formatMessage(messages.companyRevenuePlaceholder)}
          </Heading>
          <div className={styles.chipsContainer}>
            <Content spacing="minuscule">
              <Chips
                onChange={handleCompanyRevenueChange}
                selected={companyRevenue}
                type={"singleselect"}
                showSelectedSuffix={false}
              >
                {companyRevenueArray.map(option => {
                  return (
                    <Chip
                      key={option.value}
                      label={formatMessage(option.label)}
                      value={option.value}
                    />
                  );
                })}
              </Chips>
              {showValidationErrors && !companyRevenue && (
                <InputValidation
                  message={formatMessage(messages.companyRevenueError)}
                />
              )}
            </Content>
          </div>
        </Content>

        <div className={styles.button}>
          <Button
            id={"revenuePageSubmit"}
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
