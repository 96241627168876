import { defineMessages } from "react-intl";

export const messages = defineMessages({
  openModalButtonAriaLabel: {
    id: "OfferingSettingsPage.OfferingsItemMenu.openModalButtonAriaLabel",
    defaultMessage: "Open edit menu",
    description:
      "Aria label for the button that opens the edit menu for an offering",
  },
});
