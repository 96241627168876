import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { InputCurrency } from "components/InputCurrency";
import { messages } from "./messages";

interface LineItemUnitPriceProps {
  unitPrice: number | undefined;
  reactKey: string;
  onChange: (value: number) => void;
}

export function LineItemUnitPrice({
  unitPrice,
  reactKey,
  onChange,
}: LineItemUnitPriceProps) {
  const { currencySymbol } = useAccount();
  const { formatMessage } = useIntl();

  return (
    <InputCurrency
      name={`lineItems.${reactKey}.unit_price`}
      value={unitPrice}
      prefix={{ label: currencySymbol }}
      placeholder={formatMessage(messages.unitPrice)}
      autocomplete={false}
      onChange={onChange}
      validations={{
        min: {
          message: formatMessage(messages.unitPriceMinimumValueError),
          value: -9999999999,
        },
        max: {
          message: formatMessage(messages.unitPriceMaximumValueError),
          value: 9999999999,
        },
      }}
    />
  );
}
