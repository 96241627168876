import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import { Markdown } from "@jobber/components/Markdown";
import { useIntl } from "react-intl";
import styles from "./CardHolderWatchListReviewUploadSuccess.module.css";
import { messages } from "./messages";
import { goHome } from "../../utils";

const SUCCESS_ICON_SVG_PATH = "svg/successState-money-management.svg";

export function CardHolderWatchListReviewUploadSuccess() {
  const { formatMessage } = useIntl();
  const [ref, { width }] = useResizeObserver<HTMLDivElement>();
  const isMobileWidth = !width || width < Breakpoints.small;

  const descriptionSecondLine = `**${formatMessage(
    messages.descriptionSecond,
  )}** ${formatMessage(messages.descriptionThird)}`;
  return (
    <div ref={ref} className={styles.pageContainer}>
      <Page title="" width="narrow">
        <div className={styles.innerPageContainer}>
          <div className={styles.iconContainer}>
            <SuccessIcon />
          </div>

          <Content>
            <Heading level={1}>{formatMessage(messages.heading)}</Heading>
          </Content>

          <Content spacing="base">
            <Text>{formatMessage(messages.descriptionFirst)}</Text>
            <Markdown content={descriptionSecondLine} />
          </Content>

          <div className={styles.buttonContainer}>
            <Button
              variation="work"
              type="primary"
              label={formatMessage(messages.successButtonLabel)}
              size={isMobileWidth ? "base" : "large"}
              fullWidth={isMobileWidth}
              onClick={goHome}
            />
          </div>
        </div>
      </Page>
    </div>
  );
}

function SuccessIcon() {
  const src = window.SG1_IMAGE_MANIFEST[SUCCESS_ICON_SVG_PATH];
  return <img className="" alt="success" src={src} />;
}
