import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addOptionalLineItemCtaLabel: {
    id: "upgradePaths.optionalLineItemsPrompt.addOptionalLineItemCtaLabel",
    defaultMessage: "Add Optional Line Item",
    description: "CTA label for adding optional line items",
  },
  tooltipMessage: {
    id: "upgradePaths.optionalLineItemsPrompt.tooltipMessage",
    defaultMessage: "Try optional line items free for 14 days.",
    description: "Tooltip message for optional line items",
  },
});
