import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import type { CustomFieldConfigurationNodeFragment } from "~/utilities/API/graphql";
import { messages } from "./messages";

interface CustomFieldArchiveModalProps {
  customFieldConfiguration: CustomFieldConfigurationNodeFragment | undefined;
  open: boolean;
  closeModal(): void;
  handleArchiveCustomField(): void;
}

export function CustomFieldArchiveModal({
  customFieldConfiguration,
  open,
  closeModal,
  handleArchiveCustomField,
}: CustomFieldArchiveModalProps) {
  return (
    <>
      {customFieldConfiguration?.app ? (
        <AppCustomFieldModal
          customFieldConfiguration={customFieldConfiguration}
          open={open}
          closeModal={closeModal}
        />
      ) : (
        <CustomFieldModal
          customFieldConfiguration={customFieldConfiguration}
          open={open}
          closeModal={closeModal}
          handleArchiveCustomField={handleArchiveCustomField}
        />
      )}
    </>
  );
}

function CustomFieldModal({
  customFieldConfiguration,
  open,
  closeModal,
  handleArchiveCustomField,
}: CustomFieldArchiveModalProps) {
  const workObjectName =
    APPLIES_TO_LIST[
      customFieldConfiguration?.appliesTo as keyof typeof APPLIES_TO_LIST
    ];

  const { formatMessage } = useIntl();
  const { modalHeader, modalInitialContent, modalEndingContent } = messages;
  const { title, initialContent, endingContent } = {
    title: formatMessage(modalHeader, {
      appName: customFieldConfiguration?.name,
    }),
    initialContent: formatMessage(modalInitialContent, {
      workObjectName: workObjectName,
    }),
    endingContent: formatMessage(modalEndingContent),
  };

  return (
    <Modal
      title={title}
      open={open}
      onRequestClose={closeModal}
      primaryAction={{
        label: "Archive",
        onClick: handleArchiveCustomField,
        loading: false,
        variation: "destructive",
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: closeModal,
        variation: "subtle",
        type: "primary",
      }}
    >
      <Content>
        <Text>{initialContent}</Text>
        <Text>{endingContent}</Text>
      </Content>
    </Modal>
  );
}

const APPLIES_TO_LIST = {
  ALL_PROPERTIES: "properties",
  ALL_QUOTES: "quotes",
  ALL_JOBS: "jobs",
  ALL_CLIENTS: "clients",
  ALL_INVOICES: "invoices",
  TEAM: "users",
};

interface AppCustomFieldModalProps {
  customFieldConfiguration: CustomFieldConfigurationNodeFragment | undefined;
  open: boolean;
  closeModal(): void;
}

function AppCustomFieldModal({
  customFieldConfiguration,
  open,
  closeModal,
}: AppCustomFieldModalProps) {
  const { formatMessage } = useIntl();
  const {
    appModalArchivedHeader,
    appModalUnarchivedHeader,
    appModalArchivedIntitialContent,
    appModalArchivedEndingContent,
    appModalUnarchivedContent,
  } = messages;
  const {
    archivedTitle,
    unarchivedTitle,
    archivedInitialContent,
    archivedEndingContent,
    unarchivedEndingContent,
  } = {
    archivedTitle: formatMessage(appModalArchivedHeader, {
      appName: customFieldConfiguration?.app?.name,
    }),
    unarchivedTitle: formatMessage(appModalUnarchivedHeader, {
      appName: customFieldConfiguration?.app?.name,
    }),
    archivedInitialContent: formatMessage(appModalArchivedIntitialContent, {
      appName: customFieldConfiguration?.app?.name,
    }),
    archivedEndingContent: formatMessage(appModalArchivedEndingContent),
    unarchivedEndingContent: formatMessage(appModalUnarchivedContent, {
      appName: customFieldConfiguration?.app?.name,
    }),
  };

  return (
    <Modal
      title={
        customFieldConfiguration?.archived ? unarchivedTitle : archivedTitle
      }
      open={open}
      onRequestClose={closeModal}
      primaryAction={{
        label: "View app",
        url: customFieldConfiguration?.app?.marketplaceUrl,
        variation: "subtle",
        type: "primary",
      }}
    >
      <Content>
        {customFieldConfiguration?.archived === false ? (
          <>
            <Text>{archivedInitialContent}</Text>
            <Text>
              <b>{archivedEndingContent}</b>
            </Text>
          </>
        ) : (
          <Text>{unarchivedEndingContent}</Text>
        )}
      </Content>
    </Modal>
  );
}
