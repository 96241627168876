import classNames from "classnames";
import React, { useState } from "react";
import { trackMoreActionOptionClickEvent } from "jobber/email/EmailMoreActions/EmailMoreActionAmplitudeEvents";
import { EmailReplyToInput } from "jobber/email/EmailReplyToInput";
import { EmailMoreActions } from "jobber/email/EmailMoreActions";
import { RecipientPicker } from "./RecipientPicker";
import styles from "./EmailRecipientPicker.module.css";

export interface EmailPickerProps {
  emailAddresses: string[];
  preloadedTo: string[];
  preloadedCc: string[];
  isUserAdmin: boolean;
  clientTemplateSettings: {
    hideCustomizeTemplateLink: boolean;
    settingsPath: string;
  };
  replyToSettingsPath: string;
  clientId: string;
  workFlowObject: string;
  workFlowObjectId: string;
  onReplyToChange?(newValue?: string, defaultEmail?: string): void;
}

export function EmailRecipientPicker({
  emailAddresses,
  preloadedCc,
  preloadedTo,
  isUserAdmin,
  clientTemplateSettings,
  replyToSettingsPath,
  clientId,
  workFlowObject,
  workFlowObjectId,
  onReplyToChange,
}: EmailPickerProps) {
  const [showCc, setShowCc] = useState(() => {
    return preloadedCc.length > 0 || false;
  });

  const [showReplyTo, setShowReplyTo] = useState(false);

  const [moreActions, setMoreActions] = useState(() => {
    let actions = [
      {
        label: "Add CC Email",
        value: "cc",
        onClick: () => removeMainAction("cc"),
      },
    ];

    //workFlowObject will be nil for Client Emails which does not have reply_to feature
    if (workFlowObject !== "NilClass") {
      actions = [
        ...actions,
        {
          label: "Change Reply-to Email",
          value: "reply_to",
          onClick: () => removeMainAction("reply_to"),
        },
      ];
    }

    if (!clientTemplateSettings.hideCustomizeTemplateLink) {
      actions = [
        ...actions,
        {
          label: "Customize Template",
          value: "customizeTemplate",
          onClick: () => {
            trackMoreActionOptionClickEvent("customizeTemplate");
            window.open(`${clientTemplateSettings.settingsPath}`);
          },
        },
      ];
    }
    return actions;
  });

  function removeMainAction(value: string) {
    if (value === "cc") {
      setShowCc(true);
    } else if (value === "reply_to") {
      setShowReplyTo(true);
    }

    setMoreActions(prevState =>
      prevState.filter(action => action.value !== value),
    );
    trackMoreActionOptionClickEvent(value);
  }

  return (
    <>
      <div className={classNames(styles.flex, styles.spaceBetween)}>
        <div className={styles.affixedInputWrapper}>
          <RecipientPicker
            dropdownRecipients={emailAddresses}
            preloadedRecipients={preloadedTo}
            inputName="recipients[]"
            placeholderSingular="Type an email address"
            placeholderPlural="Type or select an email address"
          />
        </div>
        <div
          className={classNames(
            styles.flex,
            styles.alignItemsCenter,
            moreActions.length ? "u-paddingLeftSmaller" : "",
            "u-paddingBottomSmall",
          )}
        >
          <EmailMoreActions moreActions={moreActions} />
        </div>
      </div>

      {showCc && (
        <RecipientPicker
          dropdownRecipients={emailAddresses}
          preloadedRecipients={preloadedCc}
          inputName="cc_emails[]"
          label="Cc"
          placeholderSingular="Type an email address"
          placeholderPlural="Type or select an email address"
        />
      )}

      {showReplyTo && (
        <EmailReplyToInput
          replyToSettingsPath={replyToSettingsPath}
          isUserAdmin={isUserAdmin}
          clientId={clientId}
          workFlowType={workFlowObject}
          workFlowObjectId={workFlowObjectId}
          onChange={onReplyToChange}
        />
      )}
    </>
  );
}
