import React from "react";
import classNames from "./Anytime.module.css";

interface AnytimeProps {
  eventId: string;
  children: React.ReactNode;
}
export const Anytime = ({ eventId, children }: AnytimeProps) => {
  return (
    <div className={classNames.container} data-eventid={eventId}>
      <div className={classNames.content}>
        <span>{children}</span>
      </div>
    </div>
  );
};
