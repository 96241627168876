import React from "react";
import { useIntl } from "react-intl";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { messages } from "./messages";

interface TermsOfServiceAndPrivacyPolicyLinksProps {
  isMobile?: boolean;
}

export function TermsOfServiceAndPrivacyPolicyLinks(
  props: TermsOfServiceAndPrivacyPolicyLinksProps,
) {
  const { isMobile } = props;
  const { formatMessage } = useIntl();

  const legalText = (
    <span data-testid="termsOfServiceAndPrivacyPolicyLinks">
      {formatMessage(messages.privacyPolicyTermsOfServiceLinksPrefix)}
      <a
        href="https://getjobber.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;{formatMessage(messages.privacyPolicy)}
      </a>
      &nbsp;{formatMessage(messages.and)}
      <a
        href="https://getjobber.com/terms-of-service/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;{formatMessage(messages.termsOfService)}
      </a>
    </span>
  );

  return isMobile ? (
    <Emphasis variation="italic">
      <Text variation="subdued" size="small">
        {legalText}
      </Text>
    </Emphasis>
  ) : (
    <Text variation="subdued" size="small">
      {legalText}
    </Text>
  );
}
