import { gql } from "@apollo/client";

export const VALID_SMS_NUMBER_QUERY = gql`
  query ValidSmsNumber($phoneNumber: String!) {
    phoneLookup(number: $phoneNumber) {
      phoneNumber {
        friendly
      }
      smsAvailable
      userErrors {
        message
      }
    }
  }
`;

export const SET_TEMPORARY_TWO_FACTOR_PHONE_MUTATION = gql`
  mutation SetTemporaryPhone($phone: String!) {
    setTemporaryTwoFactorPhone(phone: $phone) {
      phone
      userErrors {
        message
      }
    }
  }
`;
