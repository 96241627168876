import React from "react";

export interface PropsSmsPreviewBubble {
  children: React.ReactNode;
}

export const SmsPreviewBubble = (props: PropsSmsPreviewBubble) => {
  return (
    <div className="chatBubble u-wordBreak" aria-label="Text message preview">
      {props.children}
    </div>
  );
};

SmsPreviewBubble.displayName = "SmsPreviewBubble";
