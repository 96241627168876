import { defineMessages } from "react-intl";

export const messages = defineMessages({
  insightLabel: {
    id: "jobberAssistant.insightWidget.insightLabel",
    defaultMessage: "Learn more with {icon}Copilot",
    description: "Label for the business insight widget",
  },
  insightPromptPreamble: {
    id: "jobberCopilot.insightWidget.insightPromptPreamble",
    defaultMessage: `I have received the following insight from Jobber:
      "{statements}"
      How should I use this to improve my business?`,
    description: "Preamble for the insight widget prompt",
  },
});
