import { useEffect, useState } from "react";

interface UseSelectedPropertyLocation {
  latitude: number | undefined;
  longitude: number | undefined;
}

interface PropertySelectedEvent {
  property: {
    latitude?: string;
    longitude?: string;
  };
}

export const useSelectedPropertyLocation = () => {
  const [selectedPropertyLocation, setSelectedPropertyLocation] =
    useState<UseSelectedPropertyLocation>({
      latitude: undefined,
      longitude: undefined,
    });

  useEffect(() => {
    const eventListener = (event: CustomEvent<PropertySelectedEvent>) => {
      const { latitude, longitude } = event.detail.property;
      setSelectedPropertyLocation({
        latitude: latitude ? parseFloat(latitude) : undefined,
        longitude: longitude ? parseFloat(longitude) : undefined,
      });
    };

    addEventListener("jobber.property.updated", eventListener);

    return () => removeEventListener("jobber.property.updated", eventListener);
  }, []);

  return selectedPropertyLocation;
};
