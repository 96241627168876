import { useState } from "react";
import type { ConfirmationModalState } from "jobber/campaigns/contexts";

export function usePauseAndEdit(): ConfirmationModalState {
  const [open, setOpen] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<string | null>(null);

  return {
    open,
    campaignId,
    setOpen,
    setCampaignId,
  };
}
