import CalendarSrc from "@images/calendar.png";
import BeginModalFirstSlideSrc from "@images/beginModalFirstSlide.png";
import BeginModalSecondSlideSrc from "@images/beginModalSecondSlide.png";
import BeginModalThirdSlideSrc from "@images/beginModalThirdSlide.png";

const CalendarImg = {
  src: CalendarSrc as string,
  alt: "Jobber's calendar scheduling feature",
};

const BeginModalFirstSlideImg = {
  src: BeginModalFirstSlideSrc as string,
  alt: "Cards with insights on the accounts' quotes and jobs",
};

const BeginModalSecondSlideImg = {
  src: BeginModalSecondSlideSrc as string,
  alt: "Jobber's jobs feature",
};

const BeginModalThirdSlideImg = {
  src: BeginModalThirdSlideSrc as string,
  alt: "Reminders about draft, awaiting payment, and past due invoices",
};

export {
  CalendarImg,
  BeginModalFirstSlideImg,
  BeginModalSecondSlideImg,
  BeginModalThirdSlideImg,
};
