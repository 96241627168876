import React from "react";
import combineClassNames from "classnames";
import classNames from "./ShortAppointment.module.css";

interface AppointmentProps {
  eventId: string;
  children: React.ReactNode;
}

export const ShortAppointment = ({ eventId, children }: AppointmentProps) => {
  return (
    <div
      className={combineClassNames(classNames.container)}
      data-eventid={eventId}
    >
      <div className={classNames.content}>
        <span>{children}</span>
      </div>
    </div>
  );
};
