import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { TimesheetDeleteMutation } from "~/utilities/API/graphql";
import { JOB_COST_LABOUR_TOTALS_QUERY, TIMESHEET_DELETE } from "./graphql";
import { messages } from "./messages";

export function useTimesheetDeleteMutation(
  jobId: string,
  timeSheetEntryId: string,
  canViewJobCosts: boolean,
  onSuccess: () => void,
): {
  deleteTimeSheetEntry(): Promise<void>;
  loading: boolean;
} {
  const [deleteTimesheet, { loading }] = useMutation<TimesheetDeleteMutation>(
    TIMESHEET_DELETE,
    {
      refetchQueries: [
        {
          query: JOB_COST_LABOUR_TOTALS_QUERY,
          variables: {
            jobId: jobId,
            canViewJobCosts: canViewJobCosts,
          },
        },
      ],
      update(cache, result) {
        if (result?.data?.timeSheetEntryDelete.timeSheetEntry) {
          cache.evict({
            id: cache.identify(result.data.timeSheetEntryDelete.timeSheetEntry),
          });

          cache.gc();
        }
      },
    },
  );

  async function deleteTimeSheetEntry() {
    try {
      const result = await deleteTimesheet({
        variables: {
          timeSheetEntryId,
        },
      });

      const errors = result.data?.timeSheetEntryDelete.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.timeEntryDeleted,
          variation: "success",
        });
        onSuccess();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    deleteTimeSheetEntry,
    loading,
  };
}
