import React, { type Ref, forwardRef } from "react";
import classNames from "classnames";
import { LoadMoreSpinner } from "jobber/chat/components/ChatDrawer/ChatList/LoadMoreSpinner";
import styles from "./ChatList.module.css";

interface ListProps {
  children: JSX.Element;
  reverseOrder?: boolean;
  hideList?: boolean;
  loadMore?(): void;
}

export const ChatList = forwardRef(ChatListInternal);

function ChatListInternal(props: ListProps, ref: Ref<HTMLDivElement>) {
  return (
    <>
      <div
        ref={ref}
        className={classNames(
          styles.scrollContainer,
          props.reverseOrder ? styles.scrollReverse : styles.scrollRegular,
          props.hideList ? styles.listHidden : "",
        )}
      >
        {props.children}
        {props.loadMore && <LoadMoreSpinner loadMore={props.loadMore} />}
      </div>
    </>
  );
}
