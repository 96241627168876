import { defineMessages } from "react-intl";

export const messages = defineMessages({
  conversionRateTextPercent: {
    id: "campaigns.metricsCard.conversionRateTextPercent",
    defaultMessage: "{rate, number, ::.#}%",
    description: "Conversion rate text",
  },
  conversionRateTextDollar: {
    id: "campaigns.metricsCard.conversionRateTextDollar",
    defaultMessage: `$\{amount, number, ::.00}`,
    description: "Dollar amount text",
  },
  recipients: {
    id: "campaigns.metricsCard.recipients",
    defaultMessage: "{recipients} recipients",
    description: "Conversion rate title",
  },
  jobs: {
    id: "campaigns.metricsCard.jobs",
    defaultMessage: "{count} jobs",
    description: "jobs label",
  },
});
