import { useEffect, useState } from "react";
import { useOnlinePaymentsQueries } from "./useOnlinePaymentsQueries";

interface OnlinePaymentSettings {
  collapsed: boolean;
  mandatoryCardEnabled: boolean | undefined;
}

export function useOnlinePaymentsSettings(
  initialSettings: OnlinePaymentSettings,
) {
  const [collapsed, setCollapsed] = useState(initialSettings.collapsed);

  const handleCollapseChange = () => setCollapsed(prevState => !prevState);

  const { data } = useOnlinePaymentsQueries("MANDATORY_CARD_ON_FILE");
  const [mandatoryCardEnabled, setMandatoryCardEnabled] = useState<
    boolean | undefined
  >(initialSettings.mandatoryCardEnabled);

  useEffect(() => {
    if (data && data.jobberPaymentsSetting) {
      setMandatoryCardEnabled(data.jobberPaymentsSetting.enabled);
    }
  }, [data]);

  return {
    collapsed,
    mandatoryCardEnabled,
    setMandatoryCardEnabled,
    handleCollapseChange,
  };
}
