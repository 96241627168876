import React, { useState } from "react";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { JobberPaymentsBillingAddressDisplay } from "./JobberPaymentsBillingAddressDisplay";
import { JobberPaymentsBillingAddressEdit } from "./JobberPaymentsBillingAddressEdit";

interface JobberPaymentsBillingAddressProps {
  inClientHub: boolean;
}

export function JobberPaymentsBillingAddress({
  inClientHub,
}: JobberPaymentsBillingAddressProps) {
  const [isEditing, setEditing] = useState(false);
  const [state] = usePaymentReducer();
  const isPaymentInProgress = state.status === "inProgress";

  return (
    <div>
      <h5>Billing address</h5>
      {isEditing ? (
        <JobberPaymentsBillingAddressEdit />
      ) : (
        <JobberPaymentsBillingAddressDisplay
          onChangeButtonClick={onEditAddressClick}
        />
      )}
    </div>
  );

  function onEditAddressClick() {
    if (isPaymentInProgress) return;

    const jobberEnv = inClientHub ? "Client Hub" : "Online";
    Amplitude.TRACK_EVENT("Clicked Update Address", {
      app: jobberEnv,
      source: "Making a payment",
      type: "billing",
    });

    setEditing(true);
  }
}
