import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { Template } from "~/utilities/API/graphql";

interface BaseCampaignsAmplitudeEventProps {
  interaction: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  segment_type?: string;
  is_automated?: boolean;
  template_type?: Template;
  /* eslint-enable @typescript-eslint/naming-convention */
}
interface UpdatedCampaignAmplitudeEventProps
  extends BaseCampaignsAmplitudeEventProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  entry_point?: string;
}

interface ViewedPageAmplitudeEventProps {
  name: string;
}

export function updatedCampaignAmplitudeEvent(
  properties: UpdatedCampaignAmplitudeEventProps,
) {
  Amplitude.TRACK_EVENT("Updated Campaign", properties);
}

export function interactedWithClientSegmentAmplitudeEvent(
  properties: BaseCampaignsAmplitudeEventProps,
) {
  Amplitude.TRACK_EVENT("Interacted with Client Segment", properties);
}

export function activatedCampaignsAmplitudeEvent(
  properties: BaseCampaignsAmplitudeEventProps,
) {
  Amplitude.TRACK_EVENT("Activated Campaigns", properties);
}

export function viewedPageAmplitudeEvent(
  properties: ViewedPageAmplitudeEventProps,
) {
  Amplitude.TRACK_EVENT("Viewed Page", properties);
}

export function interactedWithCampaignSummaryAmplitudeEvent(
  properties: BaseCampaignsAmplitudeEventProps,
) {
  Amplitude.TRACK_EVENT("Interacted with Campaign Summary", properties);
}
