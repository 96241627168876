import React from "react";
import { Page } from "@jobber/components/Page";
import { useSubscription } from "@apollo/client";
import type { RecommendNonRepeatingVisitScheduleSubscriptionSubscription } from "~/utilities/API/graphql";
import { AVAILABILITY_SUBSCRIPTION } from "./components/SchedulingAssistant/hooks/ScheduleAvailability.graphql";
import {
  toYearMonthDay,
  yearMonthDayToString,
} from "./components/SchedulingAssistant/yearMonthDay";

export function ScheduleRecommendationsPage() {
  const today = toYearMonthDay(new Date());
  const { data } =
    useSubscription<RecommendNonRepeatingVisitScheduleSubscriptionSubscription>(
      AVAILABILITY_SUBSCRIPTION,
      {
        variables: {
          recommendationRequestId: "foo",
          startDate: yearMonthDayToString(today),
          endDate: null,
          location: null,
        },
      },
    );

  return (
    <Page title="Recommend Non-Repeating Visit Schedule" width="fill">
      <h5>Ensure that your bin/jobber procfile is running</h5>
      <div>
        recommendation_id:
        {` ${data?.recommendNonRepeatingVisitSchedule.recommendationRequestId}`}
      </div>

      {data?.recommendNonRepeatingVisitSchedule.schedulingRecommendations && (
        <table>
          <thead>
            <tr>
              <th>Available Times</th>
            </tr>
            <tr>
              <th>Assignee</th>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {data.recommendNonRepeatingVisitSchedule.schedulingRecommendations.map(
              recommendation => {
                return (
                  <tr
                    key={
                      recommendation.__typename +
                      recommendation.recommendedStartAt
                    }
                  >
                    <td>{`${recommendation.recommendedAssignee?.id}`}</td>
                    <td>{`${recommendation.recommendedStartAt}`}</td>
                    <td>{`${recommendation.recommendedEndAt}`}</td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      )}

      <div>
        user errors: {`${data?.recommendNonRepeatingVisitSchedule.userErrors}`}
      </div>
    </Page>
  );
}
