import React from "react";
import { CONVERSATION_TYPE_FILTER } from "jobber/chat/components/ChatDrawer/NotificationList/ConversationTypeFilterInput";
import { EmptyState } from "./EmptyState";

interface NoMessageEmptyStateProps {
  conversationType: CONVERSATION_TYPE_FILTER;
  searchTerm?: string;
}

// Defining this type means we will get a compile time error if a new conversation filter is added without updating these arrays
type ConversationTypeStrings = Record<CONVERSATION_TYPE_FILTER, string>;

const title: ConversationTypeStrings = {
  [CONVERSATION_TYPE_FILTER.ALL]: "No messages, yet!",
  [CONVERSATION_TYPE_FILTER.PHONE]: "No text messages, yet!",
  [CONVERSATION_TYPE_FILTER.EMAIL]: "No emails, yet!",
};

const description: ConversationTypeStrings = {
  [CONVERSATION_TYPE_FILTER.ALL]:
    "You'll see them here after sending a quote, invoice, automated notification, or when your client contacts you at your dedicated Jobber phone number or email.",
  [CONVERSATION_TYPE_FILTER.PHONE]:
    "You'll see them here after sending a quote, invoice, automated notification by text message, or when your client texts you at your dedicated phone number.",
  [CONVERSATION_TYPE_FILTER.EMAIL]:
    "You'll see them here after sending a quote, invoice, automated email notification, or when your client emails you at your Jobber email.",
};

const searchDescription: ConversationTypeStrings = {
  [CONVERSATION_TYPE_FILTER.ALL]:
    "Try searching by client name, phone number, or email address",
  [CONVERSATION_TYPE_FILTER.PHONE]:
    "Try searching by client name or phone number",
  [CONVERSATION_TYPE_FILTER.EMAIL]:
    "Try searching by client name or email address",
};

export function NoMessagesEmptyState({
  searchTerm,
  conversationType,
}: NoMessageEmptyStateProps) {
  return (
    <EmptyState
      title={getTitle()}
      description={getDescription()}
      iconName={
        CONVERSATION_TYPE_FILTER.EMAIL === conversationType ? "email" : "sms2"
      }
    />
  );

  function getTitle() {
    if (searchTerm) {
      return `No results for "${searchTerm}" found`;
    } else {
      return title[conversationType];
    }
  }

  function getDescription(): string {
    if (searchTerm) {
      return searchDescription[conversationType];
    } else {
      return description[conversationType];
    }
  }
}
