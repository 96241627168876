import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages } from "./messages";

// Selectors

export function getDialog() {
  return screen.getByRole("dialog");
}

// Query

export function queryDeleteCampaignModalTitle() {
  return screen.queryByText(messages.deleteCampaignModalTitle.defaultMessage);
}

export function queryOneOffCampaignModalMessage() {
  return screen.queryByText(messages.deleteCampaignModalMessage.defaultMessage);
}

export function queryDialog() {
  return screen.queryByRole("dialog");
}

// Solo Actions

export function iClickCancelButton() {
  userEvent.click(screen.getByText("Cancel"));
}

export function iClickConfirmButton() {
  userEvent.click(
    screen.getByText(messages.deleteCampaignModalButtonText.defaultMessage),
  );
}
