import { useActor } from "@xstate/react";
import { createContext, useContext } from "react";
import type { ActorRefFrom } from "xstate";
import type { RecommendationMachine } from "./recommendation.machine";

export const RecommendationMachineContext = createContext<
  ActorRefFrom<RecommendationMachine> | undefined
>(undefined);

export const useRecommendationMachine = () => {
  const actor = useContext(RecommendationMachineContext);

  return useActor(actor!);
};
