import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import React from "react";
import { useIntl } from "react-intl";
import { formatCurrency } from "utilities/formatCurrency";
import { messages } from "./messages";

export interface AddonUpsellProps {
  addonName: string;
  onRejected: () => void;
  originalAddonName: string;
  originalAddonPrice: number;
}

export function AddonUpsell(props: AddonUpsellProps) {
  const { formatMessage } = useIntl();

  if (props.originalAddonName === props.addonName) {
    return null;
  }

  return (
    <Content>
      <Banner type="success" dismissible={false} icon="knot">
        <Emphasis variation="bold">
          {formatMessage(messages.upsellHeader, { addonName: props.addonName })}
        </Emphasis>
        <Text>{formatMessage(messages.marketingSuiteUpsellSummary)}</Text>
        <Button
          type="tertiary"
          variation="work"
          label={formatMessage(messages.upsellDeclineButtonLabel, {
            addonName: props.originalAddonName,
            price: formatCurrency(props.originalAddonPrice, "$", 0),
          })}
          size="small"
          disabled={false}
          onClick={props.onRejected}
        />
      </Banner>
    </Content>
  );
}
