import React from "react";
import { Text } from "@jobber/components/Text";
import type {
  MessageTemplateMessageTemplateEmailFragment,
  MessageTemplateMessageTemplateSmsFragment,
} from "~/utilities/API/graphql";
import styles from "./TemplatePreviewer.module.css";
import { useVariableSubstitution } from "./useVariableSubstitution";
import { TemplateSectionPreviewer } from "./TemplateSectionPreviewer";
import type { MessageTemplate } from "../types";

interface TemplatePreviewerProps {
  template: MessageTemplate;
  staticImage?: JSX.Element | null;
  showSmsArrow?: boolean;
}

export function TemplatePreviewer({
  template,
  staticImage,
  showSmsArrow = true,
}: TemplatePreviewerProps) {
  const { deliveryMethod, message, variables } = template;

  const variableSubstitution = useVariableSubstitution(variables.nodes);

  const previewClassesMap = {
    EMAIL: "emailPreview",
    SMS: showSmsArrow ? "smsPreview" : "smsPreviewNoArrow",
    SAME_AS_LATEST: "",
  };

  const isEmailTemplate = template.deliveryMethod === "EMAIL";
  const hasFooterText =
    !isEmailTemplate &&
    (template as MessageTemplateMessageTemplateSmsFragment).footerText;

  return (
    <div>
      <div className={styles[previewClassesMap[deliveryMethod]]}>
        {isEmailTemplate && (
          <div className={styles.messageTemplateSubject}>
            <Text>
              <TemplateSectionPreviewer
                template={
                  (template as MessageTemplateMessageTemplateEmailFragment)
                    .subject.current
                }
                values={variableSubstitution}
                isBold={true}
              />
            </Text>
          </div>
        )}
        <Text>
          <TemplateSectionPreviewer
            template={message.current}
            values={variableSubstitution}
          />
        </Text>
        {isEmailTemplate && staticImage !== undefined && staticImage}
        {hasFooterText && (
          <div className={styles.messageTemplateFooterText}>
            <Text>
              <TemplateSectionPreviewer
                template={
                  (template as MessageTemplateMessageTemplateSmsFragment)
                    .footerText
                }
                values={variableSubstitution}
              />
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
