import { defineMessages } from "react-intl";

export const messages = defineMessages({
  defaultInitialLabel: {
    id: "selfServeBookings.CopyButton.defaultInitialLabel",
    defaultMessage: "Copy link",
    description: "Default initial label for the CopyButton component",
  },
  defaultActivatedLabel: {
    id: "selfServeBookings.CopyButton.defaultActivatedLabel",
    defaultMessage: "Copied!",
    description: "Default activated label for the CopyButton component",
  },
});
