import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Checkbox } from "@jobber/components/Checkbox";
// import { InvoicePeriodDropdown } from "./InvoicePeriodDropdown";
import { InvoiceSummary } from "./InvoiceSummary";
// import { InvoiceScheduleOptions } from "./InvoiceScheduleOptions";
import { InvoicePreference } from "./InvoicePreference";
import { InvoicePeriod, type InvoiceSchedulerState } from "./types";
import { InvoicePeriodDropdown } from "./InvoicePeriodDropdown";

export type InvoiceSchedulerProps = InvoiceSchedulerState & {
  onChange(state: Partial<InvoiceSchedulerState>): void;
};

export const InvoiceScheduler = (props: InvoiceSchedulerProps) => {
  const toggleInvoiceOnJobComplete = () => {
    props.onChange({ invoiceOnJobComplete: !props.invoiceOnJobComplete });
  };

  const onChangeInvoiceScheduleDropdown = (newValue: InvoicePeriod) => {
    props.onChange({ schedule: Number(newValue) });
  };

  return (
    <Card title="Invoicing">
      <Content spacing="small">
        <InvoicePeriodDropdown
          disabled={false}
          onChange={onChangeInvoiceScheduleDropdown}
          invoicePeriodOption={props.schedule}
        />
        {/* <InvoiceScheduleOptions /> */}
        <Checkbox
          label={"Remind me to invoice when I close the job"}
          checked={props.invoiceOnJobComplete}
          onChange={toggleInvoiceOnJobComplete}
        />
        {props.schedule !== InvoicePeriod.AsNeeded && <InvoicePreference />}
        {props.schedule !== InvoicePeriod.AsNeeded && <InvoiceSummary />}
      </Content>
    </Card>
  );
};
