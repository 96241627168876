import { Button, Emphasis, Heading } from "@jobber/components";
import classnames from "classnames";
import React from "react";
import { useIntl } from "react-intl";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";
import { approvedStateMessages } from "./approvedStateMessages";

export interface UkKycApprovedStateProps {
  onClick: () => void;
}

export function UkKycApprovedState(props: UkKycApprovedStateProps) {
  const { formatMessage } = useIntl();
  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderTop",
  );

  return (
    <div className={cssClasses}>
      <Heading level={4}>
        {formatMessage(approvedStateMessages.youreFragment)}{" "}
        <Emphasis variation={"highlight"}>
          {formatMessage(approvedStateMessages.approvedFragment)}
        </Emphasis>
      </Heading>
      <p>{formatMessage(approvedStateMessages.thankYouMessage)}</p>
      <Button
        label={formatMessage(approvedStateMessages.approvedButtonText)}
        fullWidth={true}
        size={"base"}
        variation={"work"}
        onClick={props.onClick}
      />
    </div>
  );
}
