import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useState } from "react";
import { BroadcastChannel } from "broadcast-channel";
import styles from "./ExpiredSessionModal.module.css";

interface ExpiredSessionModalProps {
  broadcastExpiryEvent: boolean;
  onClick?(): void;
}

function onClickDefault() {
  window.location.reload();
}

export function ExpiredSessionModal({
  broadcastExpiryEvent,
  onClick = onClickDefault,
}: ExpiredSessionModalProps) {
  const [showModal, setShowModal] = useState(false);
  const broadcastedChannel = "sessionExpiry";
  const broadcastedMessage = "showExpiredSessionModal";

  // Broadcast a message to all other tabs to show the modal
  // Won't show the modal on the tab that posts the message
  useEffect(() => {
    const channel: BroadcastChannel = new BroadcastChannel(broadcastedChannel);
    let mounted = true;

    if (broadcastExpiryEvent) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      channel.postMessage(broadcastedMessage).catch();
    }

    const handler = (msg: string) =>
      mounted && msg === broadcastedMessage && setShowModal(true);

    // Listen for messages from other tabs to trigger showing the modal
    channel.addEventListener("message", handler);

    return () => {
      channel.removeEventListener("message", handler);
      mounted = false;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      channel.close().catch();
    };
  }, [broadcastExpiryEvent]);

  return (
    <Modal
      title="Your session has ended"
      open={showModal}
      size="small"
      dismissible={false}
      primaryAction={{
        label: "Refresh",
        onClick: onClick,
        variation: "work",
        ariaLabel: "Refresh",
      }}
    >
      <div className={styles.content}>
        <Text>
          For your security, we&apos;ve automatically logged you out. Refresh to
          log back in.
        </Text>
      </div>
    </Modal>
  );
}
