import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import clipboardCopy from "clipboard-copy";
import React, { useState } from "react";
import { Button } from "@jobber/components/Button";

interface ShareLoginPageViewProps {
  loginURL: string;
}

const copyProps = {
  label: "Copy URL",
};

const copiedProps = {
  icon: "checkmark",
  label: "Copied",
};

export function ShareLoginPageView({ loginURL }: ShareLoginPageViewProps) {
  const [buttonProps, setButtonProps] = useState(copyProps);
  const [timeoutIdentifier, setTimeoutIdentifier] = useState<number>();

  const copyURL = async () => {
    setButtonProps(copiedProps);
    window.clearTimeout(timeoutIdentifier);

    await clipboardCopy(loginURL);

    setTimeoutIdentifier(
      window.setTimeout(() => {
        setButtonProps(copyProps);
      }, 2000),
    );
  };

  return (
    <Card title="Share login page">
      <Content>
        <Text>
          Allow existing clients to log into your client hub by adding the
          following URL to your website:
        </Text>
        <div className="row collapse">
          <div className="columns shrink u-marginRightSmall">
            <Button {...buttonProps} onClick={copyURL} type="secondary" />
          </div>
          <div className="columns">
            <InputText value={loginURL} readonly={true} size="small" />
          </div>
        </div>
      </Content>
    </Card>
  );
}
