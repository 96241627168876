import React from "react";
import { useSubscription } from "@apollo/client";
import type { GoogleProfileSubscription } from "~/utilities/API/graphql";
import { ProfileStep } from "./ProfileStep";
import { GOOGLE_PROFILE } from "./ProfileStep.graphql";
import type { SetupGuideStepProps } from "../../types";

export function ProfileStepLoader({
  navigation,
  setErrMsg,
}: SetupGuideStepProps) {
  const { data, loading } =
    useSubscription<GoogleProfileSubscription>(GOOGLE_PROFILE);

  const err = data?.googleProfiles?.errors;
  const errors = typeof err !== "undefined" && err.length > 0;

  return (
    <ProfileStep
      data={data}
      loading={loading}
      errors={errors}
      navigation={navigation}
      setErrMsg={setErrMsg}
    />
  );
}
