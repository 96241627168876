import * as React from "react";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { Amplitude } from "~/utilities/analytics/Amplitude";

export function useCreditCardDetails({
  initialAddress,
  initialName,
}: {
  initialAddress: BillingAddress;
  initialName: string;
}) {
  const [isEditingAddress, setIsEditingAddress] = React.useState(false);
  const onChangeAddressButtonClick = React.useCallback(() => {
    Amplitude.TRACK_EVENT("Clicked Update Address", {
      app: "Online",
      source: "Storing a card",
      type: "billing",
    });
    setIsEditingAddress(true);
  }, [setIsEditingAddress]);
  const [address, setAddress] = React.useState(initialAddress);
  const [name, setName] = React.useState(initialName);
  const [savePaymentMethod, setSavePaymentMethod] = React.useState(false);
  const [signatureDataUrl, setSignatureDataUrl] = React.useState<
    string | undefined
  >();

  return {
    name,
    setName,
    address,
    setAddress,
    savePaymentMethod,
    setSavePaymentMethod,
    isEditingAddress,
    signatureDataUrl,
    setSignatureDataUrl,
    onChangeAddressButtonClick,
  };
}
