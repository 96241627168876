import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Markdown } from "@jobber/components/Markdown";
import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import {
  type JobberPaymentsDataSharingConsent,
  JobberPaymentsDataSharingConsentActions,
} from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";
import { upsertDecision } from "./eventHandlers";
import { DATA_SHARING_CONSENT_UPSERT } from "./DataSharingConsentUpsertQuery";

export interface DataSharingConsentModalProps {
  /**
   * Controls whether this modal is open.
   * @default false
   */
  readonly open?: boolean;
}

export function DataSharingConsentModal(props: DataSharingConsentModalProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <DataSharingConsentModalWrapper {...props} />
      </IntlProvider>
    </APIProvider>
  );
}

function DataSharingConsentModalWrapper({
  open = false,
}: DataSharingConsentModalProps) {
  const { formatMessage } = useIntl();

  const [isOpen, setOpen] = useState(open);
  const [isWarningOpen, setWarningOpen] = useState(false);

  const [dataSharingConsentUpsert] =
    useMutation<JobberPaymentsDataSharingConsent>(DATA_SHARING_CONSENT_UPSERT, {
      fetchPolicy: "no-cache",
    });

  const onDisableFunction = async () => {
    try {
      await upsertDecision(
        JobberPaymentsDataSharingConsentActions.DISABLE,
        dataSharingConsentUpsert,
      );
    } finally {
      showToast({
        message: formatMessage(messages.disableToastMessage),
      });
      setWarningOpen(false);
      setOpen(false);
    }
  };

  const onAgreeFunction = async () => {
    try {
      await upsertDecision(
        JobberPaymentsDataSharingConsentActions.CONSENT,
        dataSharingConsentUpsert,
      );
    } finally {
      setOpen(false);
    }
  };

  return (
    <APIProvider>
      <Modal
        dismissible={false}
        open={isOpen}
        title={formatMessage(messages.title)}
        primaryAction={{
          label: formatMessage(messages.consentLabel),
          onClick: onAgreeFunction,
        }}
        secondaryAction={{
          label: formatMessage(messages.disableLabel),
          onClick: () => setWarningOpen(true),
        }}
      >
        <Content>
          <Markdown content={formatMessage(messages.consentMessage1)} />
          <Markdown
            content={formatMessage(messages.consentMessage2)}
            externalLink={true}
          />
        </Content>
      </Modal>
      <Modal
        dismissible={false}
        open={isWarningOpen}
        title={formatMessage(messages.warningTitle)}
        primaryAction={{
          label: formatMessage(messages.disableLabel),
          onClick: onDisableFunction,
          variation: "destructive",
        }}
        secondaryAction={{
          label: formatMessage(messages.secondaryButtonWarning),
          onClick: () => setWarningOpen(false),
        }}
      >
        <Content>
          <Markdown content={formatMessage(messages.consentWarningMessage)} />
        </Content>
      </Modal>
    </APIProvider>
  );
}
