import { defineMessages } from "react-intl";

export const messages = defineMessages({
  previewTitle: {
    id: "campaigns.templatePreview.title",
    defaultMessage: "View campaign",
    description: "Title for the campaign preview modal",
  },
  clientSegment: {
    id: "campaigns.templatePreview.clientSegment",
    defaultMessage: "Client segment",
    description: "Label for the client segment",
  },
  automationRule: {
    id: "campaigns.templatePreview.automationRules",
    defaultMessage: "Automation rules",
    description: "Label for the automation rules",
  },
  automatedCampaignRecipientSummary: {
    id: "campaigns.templatePreview.automatedCampaignRecipientSummary",
    defaultMessage:
      "This campaign will automatically send to clients {timeFrame} after {trigger} -> {condition}",
    description: "Recipient summary",
  },
});
