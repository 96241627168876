export type Year = number;
export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type Day = number;
export type YearMonthDay = [Year, Month, Day];

export const toMonth = (date: Date): Month =>
  (date.getMonth() + 1) as unknown as Month;

export const toYearMonthDay = (date: Date): [Year, Month, Day] => [
  date.getFullYear(),
  toMonth(date),
  date.getDate(),
];

export const yearMonthDayToString = ([
  year,
  month,
  day,
]: YearMonthDay): string => `${year}-${leadingZero(month)}-${leadingZero(day)}`;

export const yearMonthDayToDate = ([year, month, day]: YearMonthDay): Date => {
  const date = new Date(year, month - 1, day);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const yearMonthDaysEqual = (
  [year, month, day]: YearMonthDay,
  [otherYear, otherMonth, otherDay]: YearMonthDay,
): boolean => year == otherYear && month == otherMonth && day == otherDay;

function leadingZero(number: number) {
  return number.toString().padStart(2, "0");
}
