import { defineMessages } from "react-intl";

export const messages = defineMessages({
  instantPayoutUnavailable: {
    id: "managedAccount.paymentsCard.instantPayoutDetails.instantPayoutUnavailable",
    defaultMessage:
      "Instant payouts are not available because you're receiving automatic hourly payouts to your Jobber Money account for free.",
    description:
      "Message when instant payouts are not available because the users payout accout is a Jobber Money account",
  },
  standardPayoutScheduleTitle: {
    id: "managedAccount.payoutSchedule.standardPayoutScheduleTitle",
    defaultMessage: "Standard Payouts",
    description: "Title for the standard payout schedule section",
  },
  hourlyPayoutScheduleTitle: {
    id: "managedAccount.payoutSchedule.hourlyPayoutScheduleTitle",
    defaultMessage: "Hourly Payouts",
    description: "Title for the hourly payout schedule section",
  },
  temporaryPayoutsDelayDescription: {
    id: "managedAccount.payoutSchedule.temporaryPayoutsDelayDescription",
    defaultMessage:
      "Payouts are temporarily being made on a {payoutScheduleDelayDays} {delayDayType} day schedule",
    description:
      "Payouts description when SP in initial auth temporary delay state",
  },
  payoutsUnavailableDescription: {
    id: "managedAccount.payoutSchedule.payoutsUnavailableDescription",
    defaultMessage: "Automatic payouts are currently unavailable",
    description: "Payouts description when SP is in manual payout state",
  },
  standardPayoutsDescription: {
    id: "managedAccount.payoutSchedule.standardPayoutsDescription",
    defaultMessage:
      "Payouts are made on a {payoutScheduleDelayDays} {delayDayType} day schedule",
    description: "Payouts description for standard payouts",
  },
  hourlyPayoutsDescription: {
    id: "managedAccount.payoutSchedule.hourlyPayoutsDescription",
    defaultMessage:
      "Payouts arrive in your Jobber Money account in as little as one hour",
    description: "Payouts description for hourly payouts",
  },
  delayDaysBusiness: {
    id: "managedAccount.payoutSchedule.delayDaysBusiness",
    defaultMessage: "business",
    description: "Payouts schedule based on business days",
  },
  delayDaysCalendar: {
    id: "managedAccount.payoutSchedule.delayDaysCalendar",
    defaultMessage: "calendar",
    description: "Payouts schedule based on calendar days",
  },
  learnMore: {
    id: "managedAccount.payoutSchedule.learnMore",
    defaultMessage: "Learn about payout schedules",
    description: "Text to learn more about payout schedules link",
  },
  accountEndingDigits: {
    id: "managedAccount.payoutSchedule.accountEndingDigits",
    defaultMessage: "Account Ending in {bankLast4}",
    description: "Bank account ending digits",
  },
  depositedPayoutsHeading: {
    id: "managedAccount.depositedPayouts.heading",
    defaultMessage: "Deposited payouts",
    description: "Heading for deposited payouts section",
  },
  depositedPayoutsThisWeekTitle: {
    id: "managedAccount.depositedPayouts.thisWeek",
    defaultMessage: "This week",
    description: "Title for this week deposited payouts",
  },
  depositedPayoutsThisMonthTitle: {
    id: "managedAccount.depositedPayouts.thisMonth",
    defaultMessage: "This month",
    description: "Title for this month deposited payouts",
  },
});
