import { defineMessages } from "react-intl";

export const messages = defineMessages({
  immediateNonDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.immediateNonDiscountedChargeMessage",
    defaultMessage:
      "Your <b>{subscriptionDisplayName}</b> will renew at <b>{nextPaymentAmount}</b> on {nextBillingDate}.",
    description: "Immediate prorated charge message",
  },
  immediateDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.immediateDiscountedChargeMessage",
    defaultMessage:
      "Your <b>{subscriptionDisplayName}</b> will renew at <b>{nextPaymentAmount}</b> on {nextBillingDate}. After the discount period ends on {discountEndDate}, your subscription will renew at <b>{baseUnitPrice}</b>.",
    description: "Immediate prorated charge message",
  },
  futureBilledDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.futureBilledDiscountedChargeMessage",
    defaultMessage:
      "After the discount period ends on {discountEndDate}, your <b>{subscriptionDisplayName}</b> will renew at <b>{baseUnitPrice}</b>.",
    description: "Immediate prorated charge message",
  },
});
