import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import type { FormRef } from "@jobber/components/Form";
import { Form } from "@jobber/components/Form";
import { Text } from "@jobber/components/Text";
import { InputDate } from "@jobber/components/InputDate";
import { InputTime } from "@jobber/components/InputTime";
import { useFormState } from "@jobber/hooks/useFormState";
import { CivilTime } from "@std-proposal/temporal";
import { messages } from "jobber/campaigns/components/ScheduleSendModal/messages";
import { useTimeZone } from "./useTimeZone";

export interface ScheduleSendModalProps {
  showModal: boolean;
  toggleModal: () => void;
  onSubmit: (date: Date) => void;
  loading: boolean;
  error?: string;
  onErrorDismiss: () => void;
  scheduleDayOffset?: number;
}
export function ScheduleSendModal({
  showModal,
  toggleModal,
  onSubmit,
  loading,
  error,
  onErrorDismiss,
  scheduleDayOffset = 7,
}: ScheduleSendModalProps) {
  const { formatMessage } = useIntl();
  // form ref is needed to trigger form validation on change
  const formRef = useRef<FormRef>(null);
  const [{ isValid }, setFormState] = useFormState();
  const [scheduledDateTime, setScheduledDateTime] = useState<Date>(() => {
    const weekFromNow = new Date();
    weekFromNow.setDate(weekFromNow.getDate() + scheduleDayOffset);
    return weekFromNow;
  });
  const { IAnaTimeZoneName, timeZoneAbbreviation, offset } = useTimeZone();

  const onDateChange = (date: Date) => {
    setScheduledDateTime(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        scheduledDateTime.getHours(),
        scheduledDateTime.getMinutes(),
      ),
    );
    formRef.current?.submit();
  };

  const onTimeChange = (time: CivilTime) => {
    setScheduledDateTime(
      new Date(
        scheduledDateTime.getFullYear(),
        scheduledDateTime.getMonth(),
        scheduledDateTime.getDate(),
        time.hour,
        time.minute,
      ),
    );
    formRef.current?.submit();
  };

  const dateValidator = () => {
    const scheduledDay = new Date(
      scheduledDateTime.getFullYear(),
      scheduledDateTime.getMonth(),
      scheduledDateTime.getDate(),
    );
    const now = new Date();
    const currentDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    );

    return scheduledDay < currentDay ? "Enter a day in the future" : undefined;
  };

  return (
    <Modal
      title={formatMessage(messages.scheduleSendModalTitle)}
      open={showModal}
      dismissible={true}
      onRequestClose={toggleModal}
      primaryAction={{
        disabled: !isValid,
        label: formatMessage(messages.submitButton),
        onClick: () => onSubmit(scheduledDateTime),
      }}
    >
      <Content spacing="small">
        {!!error && (
          <Banner type="error" dismissible onDismiss={onErrorDismiss}>
            {error}
          </Banner>
        )}
        <Form ref={formRef} onStateChange={setFormState}>
          <Content spacing="small">
            <InputDate
              placeholder={formatMessage(messages.sendAtDatePlaceholder)}
              onChange={onDateChange}
              value={scheduledDateTime}
              loading={loading}
              minDate={new Date()}
              validations={{
                validate: dateValidator,
              }}
            />
            <InputTime
              placeholder={formatMessage(messages.sendAtTimePlaceholder)}
              onChange={onTimeChange}
              value={
                new CivilTime(
                  scheduledDateTime.getHours(),
                  scheduledDateTime.getMinutes(),
                )
              }
              loading={loading}
              validations={{
                validate: () => {
                  return scheduledDateTime < new Date()
                    ? "Enter a time in the future"
                    : undefined;
                },
              }}
            />
          </Content>
        </Form>
        <Text variation="subdued">
          {formatMessage(messages.timeZone, {
            IAnaTimeZoneName,
            offset,
            timeZoneAbbreviation,
          })}
        </Text>
      </Content>
    </Modal>
  );
}
