import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailTabTitle: {
    id: "templates.notificationTemplates.emailTab",
    defaultMessage: "Email",
    description: "The email tab that users can select",
  },
  textMessageTabTitle: {
    id: "templates.notificationTemplates.textMessageTab",
    defaultMessage: "Text Message",
    description: "The text message tab that users can select",
  },
  emailSubjectPlaceholderText: {
    id: "templates.templateEditor.emailSubjectPlaceholderText",
    defaultMessage: "Email subject",
    description: "The email subject placeholder text",
  },
  inputFieldPlaceholderText: {
    id: "templates.templateEditor.inputFieldPlaceholderText",
    defaultMessage: "Message",
    description: "The message placeholder text for the input text",
  },
  characterLimitExceeded: {
    id: "templates.templateEditor.characterLimitExceeded",
    defaultMessage:
      "Phone companies split text messages that are over 160 characters into two messages. This can impact deliverability.",
    description:
      "text informing that character limit is exceeded on the input field",
  },
  resetButtonLabel: {
    id: "templates.templateEditor.resetButtonLabel",
    defaultMessage: "Reset",
    description: "Reset label for button",
  },
  insertButtonLabel: {
    id: "templates.templateEditor.insertButtonLabel",
    defaultMessage: "Insert variable",
    description: "Insert variable label text",
  },
});
