import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Form, type FormRef } from "@jobber/components/Form";
import { Banner } from "@jobber/components/Banner";
import { showToast } from "@jobber/components/Toast";
import type { ApolloError } from "@apollo/client";
import { Switch } from "@jobber/components/Switch";
import { Text } from "@jobber/components/Text";
import type { OnlineBookingServiceAreaFragment } from "~/utilities/API/graphql";
import { CompanyLocationContext } from "jobber/settings/selfServeBookings/views/SettingsPage/components/CompanyLocationContext/CompanyLocationContext";
import { useUpdateConfiguration } from "jobber/settings/selfServeBookings/views/SettingsPage/hooks";
import {
  GoogleMapsWrapper,
  GoogleServiceAreaMap,
  InputRange,
  ServiceAreaAddressInput,
} from "./components";
import { messages } from "./messages";
import styles from "./styles.module.css";
import {
  useLinkedAddressLocation,
  useRadiusUnits,
  useSaveServiceArea,
} from "../../hooks";

interface ServiceAreaEditModalProps {
  open: boolean;
  onClose: () => void;
  serviceArea?: OnlineBookingServiceAreaFragment;
  serviceAreasEnabled: boolean;
}

// eslint-disable-next-line max-statements
export function ServiceAreaEditModal({
  open,
  onClose,
  serviceArea,
  serviceAreasEnabled,
}: ServiceAreaEditModalProps) {
  const formRef = useRef<FormRef>(null);
  const { editSelfServeSettings } = useUpdateConfiguration();
  const [saveServiceArea, { loading, error }] = useSaveServiceArea(
    serviceArea?.id,
  );
  const { showErrorBanner, setShowErrorBanner, userErrors, setUserErrors } =
    useErrorBanner(error);

  async function enableServiceAreas() {
    await editSelfServeSettings({ serviceAreasEnabled: true });
  }

  async function disableServiceAreas() {
    await editSelfServeSettings({ serviceAreasEnabled: false });
  }
  const handleOnSwitch = serviceAreasEnabled
    ? disableServiceAreas
    : enableServiceAreas;

  const {
    countryCode,
    address: companyDefaultAddress,
    coordinates: companyDefaultCoordinates,
  } = useContext(CompanyLocationContext);

  const { address, location, setAddressLocation } = useLinkedAddressLocation({
    address: serviceArea?.name ?? crlfToSpace(companyDefaultAddress ?? ""),
    location: serviceArea?.center ?? companyDefaultCoordinates,
  });

  const {
    radius,
    setRadius,
    minValue,
    maxValue,
    unitLabel,
    kmRadius,
    setKmRadius,
  } = useRadiusUnits(countryCode, serviceArea?.radius);

  const addressFieldLabel = (() => {
    switch (countryCode) {
      case "US":
        return messages.addressFieldLabelUS;
      default:
        return messages.addressFieldLabel;
    }
  })();

  const modalTitle = (() => {
    if (!serviceArea) {
      return messages.modalTitle;
    } else {
      return messages.modalTitleEdit;
    }
  })();

  return (
    <Modal
      size="large"
      title={modalTitle.defaultMessage}
      open={open}
      primaryAction={{
        label: messages.primaryActionLabel.defaultMessage,
        onClick: handlePrimaryAction,
        loading,
      }}
      secondaryAction={{
        label: messages.secondaryActionLabel.defaultMessage,
        onClick: handleSecondaryAction,
      }}
      onRequestClose={handleSecondaryAction}
    >
      <Content>
        {showErrorBanner && (
          <Banner onDismiss={() => setShowErrorBanner(false)} type="error">
            {messages.errorBanner.defaultMessage}
            {userErrors.length > 0 && (
              <ul>
                {userErrors.map((userError, index) => (
                  <li key={index}>{userError}</li>
                ))}
              </ul>
            )}
          </Banner>
        )}
        <div className={styles.serviceArea}>
          <Switch
            ariaLabel={messages.serviceAreaSwitchAriaLabel.defaultMessage}
            onChange={handleOnSwitch}
            value={serviceAreasEnabled}
          />
          <Text>{messages.serviceAreaSwitchDescription.defaultMessage}</Text>
        </div>
        <GoogleMapsWrapper
          libraries={["core", "geocoding", "marker", "maps", "places"]}
        >
          <Form ref={formRef}>
            <ServiceAreaAddressInput
              placeholder={addressFieldLabel.defaultMessage}
              value={address}
              onChange={(newAddress, newLocation) => {
                setAddressLocation({
                  address: newAddress,
                  location: newLocation,
                });
              }}
            />
            <GoogleServiceAreaMap
              center={location}
              onAddressChanged={(newAddress, center) =>
                setAddressLocation({ address: newAddress, location: center })
              }
              kmRadius={kmRadius}
              onRadiusChanged={setKmRadius}
            />
            <InputRange
              placeholder={messages.radiusFieldLabel.defaultMessage}
              value={radius}
              min={minValue}
              max={maxValue}
              onChange={setRadius}
              suffix={{ label: unitLabel.defaultMessage }}
            />
          </Form>
        </GoogleMapsWrapper>
      </Content>
    </Modal>
  );

  function handlePrimaryAction() {
    if (
      location?.latitude &&
      location?.longitude &&
      kmRadius &&
      radius <= maxValue &&
      radius >= minValue
    ) {
      saveServiceArea({
        name: address,
        shape: {
          circle: {
            radius: kmRadius,
            latitude: location.latitude,
            longitude: location.longitude,
          },
        },
      })
        .then(response => {
          const responseUserErrors =
            response.data?.onlineBookingServiceAreaCreate?.userErrors ||
            response.data?.onlineBookingServiceAreaEdit?.userErrors ||
            [];
          setUserErrors(responseUserErrors.map(({ message }) => message));

          if (responseUserErrors.length > 0) {
            setShowErrorBanner(true);
          } else {
            showToast({
              message: messages.savedMessage.defaultMessage,
            });
            setShowErrorBanner(false);
            onClose();
          }
        })
        .catch(() => {
          setUserErrors([]);
          setShowErrorBanner(true);
        });
    } else {
      // Trigger validation focus
      formRef.current?.submit();
    }
  }

  function handleSecondaryAction() {
    if (serviceArea) {
      setAddressLocation({
        address: serviceArea.name,
        location: serviceArea.center,
      });
      setKmRadius(serviceArea.radius);
    }
    setUserErrors([]);
    setShowErrorBanner(false);
    onClose();
  }
}

function useErrorBanner(error?: ApolloError) {
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [userErrors, setUserErrors] = useState<string[]>([]);

  useEffect(() => setShowErrorBanner(!!error), [error]);

  return {
    showErrorBanner,
    setShowErrorBanner,
    userErrors,
    setUserErrors,
  };
}

function crlfToSpace(str: string) {
  return str.replace(/\r\n/g, " ");
}
