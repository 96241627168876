import React from "react";
import { ContactDetailsHeader } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader/components/ContactDetailsHeader";
import {
  type MessageDataTextMessageFragment,
  MessageDirectionEnum,
} from "~/utilities/API/graphql";

interface TextMessageHeaderProps {
  readonly message: MessageDataTextMessageFragment;
  externalName?: string;
  showInternalName: boolean;
}

export function TextMessageHeader({
  message,
  externalName,
  showInternalName,
}: TextMessageHeaderProps) {
  const showContactDetails =
    message.direction === MessageDirectionEnum.OUTBOUND;

  if (showContactDetails && showInternalName) {
    return (
      <ContactDetailsHeader message={message} externalName={externalName} />
    );
  }

  return <></>;
}
