import React, { type MutableRefObject, useRef } from "react";
import { InlinePrompt } from "components/InlinePrompt";
import {
  CallToAction,
  type CallToActionRef,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";

export function SetUpJobberPaymentsCTA() {
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  return (
    <CallToAction
      ctaName={"automatic_payments_invoice_reminder_set_up_prompt"}
      ref={ctaRef}
    >
      <InlinePrompt
        title={"Automate how you get paid when you turn on Jobber Payments"}
        primaryActionLabel={"Set Up Jobber Payments"}
        handlePrimaryAction={handlePrimaryAction}
        handleDismiss={dismissCTA(ctaRef)}
        url={`${encodeURI("/jobber_payments/setup")}`}
      />
    </CallToAction>
  );

  function handlePrimaryAction() {
    Amplitude.TRACK_EVENT("Clicked to Learn More", {
      source: "invoice_reminder_dialog",
      name: "CTA Dismissed",
      app: "jobber_online",
    });
  }
}
