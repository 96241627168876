import { useQuery } from "@apollo/client";
import type { ProductOrServiceCustomFieldFragment } from "~/utilities/API/graphql";
import { useProductAndServiceCustomFieldsByAppAndStatus } from "~/jobber/customFields/hooks/useProductAndServiceCustomFieldsByAppAndStatus";
import { PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY } from "./LineItemCustomFieldChips.graphql";
import styles from "./LineItemCustomFieldChips.module.css";
import {
  isCustomFieldLink,
  isCustomFieldNumeric,
  isCustomFieldText,
} from "../../util";
import { CustomFieldChip } from "../CustomFieldChip";
import type { App } from "../CustomFieldChip/CustomFieldChip";

export function LineItemCustomFieldChips({
  productOrServiceId,
  onChipClick,
}: {
  productOrServiceId: string;
  onChipClick: (attributes: { productOrServiceId: string; app: App }) => void;
}) {
  const { data, loading } = useQuery(PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY, {
    variables: { productOrServiceId: productOrServiceId },
  });

  const {
    customFieldsByAppAndStatus: { unArchived: customFieldsByApp },
  } = useProductAndServiceCustomFieldsByAppAndStatus(
    data?.productOrService?.customFields || [],
  );

  if (!data || loading) {
    return null;
  }

  return (
    <div
      className={styles.chipsContainer}
      data-testid={`line-item-custom-field-chips-${productOrServiceId}`}
    >
      {Object.values(customFieldsByApp)
        .map(customFields => customFields.at(0))
        .filter(isValidProductOrServiceCustomField)
        .map(firstCustomFieldByApp => (
          <CustomFieldChip
            key={firstCustomFieldByApp.id}
            app={{
              name: firstCustomFieldByApp.customFieldConfiguration.app.name,
              logoUrl:
                firstCustomFieldByApp.customFieldConfiguration.app.logoUrl,
            }}
            name={firstCustomFieldByApp.label}
            value={getCustomFieldValue(firstCustomFieldByApp)}
            onClick={() => {
              onChipClick({
                app: {
                  name: firstCustomFieldByApp.customFieldConfiguration.app.name,
                  logoUrl:
                    firstCustomFieldByApp.customFieldConfiguration.app.logoUrl,
                },
                productOrServiceId,
              });
            }}
          />
        ))}
    </div>
  );
}

function isValidProductOrServiceCustomField(
  customField: ProductOrServiceCustomFieldFragment,
): customField is ProductOrServiceCustomFieldFragment & {
  id: string;
  customFieldConfiguration: { app: { name: string; logoUrl: string } };
} {
  return (
    isSupportedCustomFieldType(customField) &&
    customField.id !== undefined &&
    customField.customFieldConfiguration !== undefined &&
    customField.customFieldConfiguration.app !== undefined &&
    customField.customFieldConfiguration.app.name !== undefined &&
    customField.customFieldConfiguration.app.logoUrl !== undefined
  );
}

function isSupportedCustomFieldType(
  customField: ProductOrServiceCustomFieldFragment,
) {
  return (
    isCustomFieldText(customField) ||
    isCustomFieldLink(customField) ||
    isCustomFieldNumeric(customField)
  );
}

function getCustomFieldValue(customField: ProductOrServiceCustomFieldFragment) {
  if (isCustomFieldText(customField)) {
    return customField.valueText;
  }

  if (isCustomFieldLink(customField)) {
    return customField.valueLink.text;
  }

  if (isCustomFieldNumeric(customField)) {
    return customField.valueNumeric.toString();
  }

  return "";
}
