import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import type { StripeProviderContextQuery } from "~/utilities/API/graphql";

export const STRIPE_PROVIDER_CONTEXT = gql`
  query StripeProviderContext {
    stripeProviderContext {
      publishableKey
    }
  }
`;

export const useStripePublishableKey = () => {
  const [key, setKey] = useState<string>("");

  const { data } = useQuery<StripeProviderContextQuery>(
    STRIPE_PROVIDER_CONTEXT,
    {
      fetchPolicy: "cache-first",
    },
  );

  useEffect(() => {
    setKey(data?.stripeProviderContext?.publishableKey || "");
  });

  return { key };
};
