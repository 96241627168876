import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import {
  AppGallery,
  BeforeStarting,
  FeatureList,
  InstallationSteps,
} from "jobber/marketplace/views/IntegrationPage/components";
import { useIntegrationStateContext } from "jobber/marketplace/views/IntegrationPage/hooks/useIntegrationState";
import styles from "./IntegrationPageMainContent.module.css";

export function IntegrationPageMainContent() {
  const {
    features,
    galleryImages,
    beforeStartingContent,
    installationStepsContent,
  } = useIntegrationStateContext();
  return (
    <Content spacing="large">
      <Content spacing="small">
        <Content spacing="small">
          <div className={styles.featureHeading}>
            <Heading level={3}>Features & benefits</Heading>
          </div>
          <FeatureList items={features} />
        </Content>
      </Content>
      <Divider />
      {beforeStartingContent && (
        <Content spacing="small">
          <Heading level={3}>Before you start</Heading>
          <BeforeStarting content={beforeStartingContent} />
        </Content>
      )}
      {installationStepsContent && (
        <Content spacing="small">
          <Heading level={3}>Installation steps</Heading>
          <InstallationSteps content={installationStepsContent} />
        </Content>
      )}
      {galleryImages && galleryImages.length > 0 && (
        <Content spacing="small">
          <Heading level={3}>Gallery</Heading>
          <AppGallery galleryImages={galleryImages} />
        </Content>
      )}
    </Content>
  );
}
