import type { ManualPagination } from "@jobber/components/DataTable";
import { useMemo, useState } from "react";
import { DEFAULT_PAGE_NUMBER_OPTIONS } from "jobber/features/PaymentDisputes/constants";

export interface UsePaymentDisputesPaginationArgs {
  startingIndex?: number;
  startingSize?: number;
  pageNumberOptions?: number[];
}

export const usePaymentDisputesPagination = ({
  startingIndex = 0,
  startingSize,
  pageNumberOptions = DEFAULT_PAGE_NUMBER_OPTIONS,
}: UsePaymentDisputesPaginationArgs) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: startingIndex,
    pageSize: startingSize || pageNumberOptions[0],
  });

  const resetPagination = () => {
    setPagination({ pageIndex: 0, pageSize: pageSize });
  };

  const pageState = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const getPaginationProps = (totalItems = 0): ManualPagination => ({
    manualPagination: true,
    onPaginationChange: setPagination,
    itemsPerPage: pageNumberOptions,
    totalItems,
    state: pageState,
    pageCount: Math.ceil(totalItems / pageState.pageSize),
  });

  const getLastCursorOnPage = (state: {
    pageIndex: number;
    pageSize: number;
  }): string => btoa((state.pageIndex * state.pageSize).toString());

  return {
    getLastCursorOnPage,
    pageState,
    resetPagination,
    getPaginationProps,
  };
};
