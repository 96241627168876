import React, { type ReactNode } from "react";
import { SetupStep } from "./SetupNavigation";

interface SetupNavigationStepProps {
  children?: ReactNode | ReactNode[];
  step: SetupStep;
  currentStep: SetupStep;
}
export function SetupNavigationStep({
  children,
  step,
  currentStep,
}: SetupNavigationStepProps) {
  if (step === currentStep || currentStep === SetupStep.ReviewSettings) {
    return <>{children}</>;
  }
  return <></>;
}
