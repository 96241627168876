import React from "react";
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components/Banner";
import { handleCloseRailsDialog } from "utilities/bridges/handleCloseRailsDialog";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  RecipientPicker,
  type RecipientPickerProps,
} from "jobber/recipientPicker/components/EmailRecipientPicker/RecipientPicker";
import type { MutationErrors } from "~/utilities/API/graphql";
import { EditSmsBody, type PropsEditSmsBody } from "../EditSmsBody";
import { useSendRequestCardOnFileMessage } from "../useSendRequestCardOnFileMessage";

export interface EditSmsBodyProps extends PropsEditSmsBody {
  /** Client ID */
  clientId: string;
}

export interface SendSmsCommProps {
  editSmsBody: EditSmsBodyProps;
  recipientPicker: RecipientPickerProps;
}
export function SendSmsComm(props: SendSmsCommProps) {
  return (
    <APIProvider>
      <InnerSendSmsComm {...props} />
    </APIProvider>
  );
}

function InnerSendSmsComm({ editSmsBody, recipientPicker }: SendSmsCommProps) {
  const [errors, setErrors] = React.useState<MutationErrors[]>([]);
  const [recipients, setRecipients] = React.useState<string[]>(
    recipientPicker.preloadedRecipients,
  );

  const { sendRequestCardOnFileMessage, loading } =
    useSendRequestCardOnFileMessage();

  async function handleSendComm() {
    const result = await sendRequestCardOnFileMessage({
      recipients: recipients,
      clientId: editSmsBody.clientId,
    });
    const mutationErrors: MutationErrors[] =
      result?.data?.sendRequestCardOnFileMessage.userErrors || [];

    if (mutationErrors.length > 0) {
      setErrors(mutationErrors);
      return;
    }

    if (mutationErrors.length === 0 && !loading) {
      handleCloseRailsDialog();
    }
  }

  function handleOnChange(value: string[]) {
    setRecipients([...value]);
  }
  function buildErrorString(err: MutationErrors[]) {
    return err.map((e: MutationErrors, index: number) => {
      return (
        <p key={index} className="u-marginBottomNone">
          {e.message}
        </p>
      );
    });
  }

  return (
    <>
      {errors.length > 0 && (
        <div className="u-marginBottomSmall">
          <Banner type="error" dismissible={false}>
            {buildErrorString(errors)}
          </Banner>
        </div>
      )}
      <RecipientPicker
        dropdownRecipients={recipientPicker.dropdownRecipients}
        preloadedRecipients={recipients}
        inputName="recipients[]"
        placeholderSingular="Type a mobile number"
        placeholderPlural="Type or select mobile number"
        restrictInput={false}
        showLabel={true}
        onChange={handleOnChange}
      />
      <EditSmsBody
        defaultBodyMessage={editSmsBody.defaultBodyMessage}
        footerMessage={editSmsBody.footerMessage}
        templateUrl={editSmsBody.templateUrl}
        helpText={editSmsBody.helpText}
        isEditable={editSmsBody.isEditable}
      />
      <div className="dialog-actions u-paddingSmall u-paddingRightNone u-paddingBottomNone">
        {/* We can't use Atlantis component here because modal is triggered from the erb file.
        This should be refactored when we use React Modals
        */}
        <button className="button button--greyBlue button--ghost js-closeDialog">
          Cancel
        </button>
        <Button
          label="Send"
          onClick={handleSendComm}
          loading={loading}
        ></Button>
      </div>
    </>
  );
}
