import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Glimmer } from "@jobber/components/Glimmer";
import React from "react";
import styles from "./EditBillingInfo.module.css";

export function LoadingGlimmer({ showPreview }: { showPreview?: boolean }) {
  if (showPreview) {
    return (
      <Content spacing="larger">
        <Glimmer.Header level={1} />
        <Glimmer />
        <Glimmer />
      </Content>
    );
  }

  return (
    <Content>
      <Card>
        <Content spacing="large">
          <Glimmer.Header />
          <div className={styles.glimmerCard}>
            <div className={`${styles.glimmerCol} ${styles.glimmerColSmall}`}>
              <GlimmerField />
              <GlimmerField />
            </div>
            <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
              <GlimmerField />
              <GlimmerField short={true} />
            </div>
            <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`} />
          </div>
        </Content>
      </Card>
      <Card>
        <Content spacing="large">
          <Glimmer.Header />
          <div className={styles.glimmerCard}>
            <div className={`${styles.glimmerCol} ${styles.glimmerColSmall}`}>
              <GlimmerField />
              <GlimmerField />
              <GlimmerField />
            </div>
            <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
              <GlimmerField />
              <GlimmerField short={true} />
              <div>
                <GlimmerField short={true} />
                <GlimmerField short={true} />
                <GlimmerField short={true} />
              </div>
            </div>
            <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`} />
          </div>
        </Content>
      </Card>
    </Content>
  );
}

export interface GlimmerFieldProps {
  short?: boolean;
}
export const GlimmerField = ({ short }: GlimmerFieldProps) => {
  const classes = `${styles.glimmerField} ${
    short ? styles.glimmerFieldShort : ""
  }`;
  return (
    <div className={classes}>
      <Glimmer size={"base"} />
    </div>
  );
};
