import { useQuery } from "@apollo/client";
import type {
  AccountingCode,
  AccountingCodesQueryQuery,
} from "~/utilities/API/graphql";
import { ACCOUNTING_CODES_QUERY } from "./graphql";

export function useAccountingCodesQuery(): {
  accountingCodes?: AccountingCode[];
  loading: boolean;
  error?: string;
} {
  const { data, loading, error } = useQuery<AccountingCodesQueryQuery>(
    ACCOUNTING_CODES_QUERY,
  );

  return {
    accountingCodes: data?.accountingCodes.nodes,
    loading,
    error: error?.message,
  };
}
