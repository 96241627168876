import type { ManualPagination } from "@jobber/components/DataTable";
import { useCallback, useEffect } from "react";
import type { ClientSegmentsData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import type { ClientSegmentTableClientData } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentTable/ClientSegmentTable";
import {
  ClientSegmentSortableFields,
  type GetCommsClientsSegmentQueryVariables,
  type Segment,
} from "~/utilities/API/graphql";
import {
  type SortState,
  useClientSegmentTableSorting,
} from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentTableSorting/useClientSegmentTableSorting";
import { useClientSegmentDataTablePaginationState } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentTablePaginationState/useClientSegmentTablePaginationState";
import type { LoadingState } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentDrawer/ClientSegmentDrawer";
import { useClientDataForSelectedSegment } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientDataForSelectedSegment";
import type { ClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";

export interface UseClientSegmentTableArgs {
  segmentCriteria: ClientsCriteriaFromSegment;
  selectedSegment: Segment;
  fetchData(variables: GetCommsClientsSegmentQueryVariables): void;
  refetch(variables: GetCommsClientsSegmentQueryVariables): void;
  loadingState: LoadingState;
  clientSegments: ClientSegmentsData;
}

export interface ClientSegmentTableData {
  clientData: ClientSegmentTableClientData[];
  total?: number;
}

export interface UseClientSegmentTable {
  pageState: PageState;
  getPaginationProps: (totalItems: number) => ManualPagination;
  resetPagination: (variables: ClientsCriteriaFromSegment) => void;
  clientSegment: ClientSegmentTableData;
  clientSegmentSortingState: [SortState<ClientSegmentSortableFields>];
  handleSortingChange: (
    sortState: [SortState<ClientSegmentSortableFields>],
  ) => void;
}

export interface PageState {
  pageIndex: number;
  pageSize: number;
}

export function useClientSegmentTable({
  segmentCriteria,
  selectedSegment,
  refetch,
  fetchData,
  loadingState,
  clientSegments,
}: UseClientSegmentTableArgs): UseClientSegmentTable {
  const { endCursor, pageSize, pageState, setPagination, getPaginationProps } =
    useClientSegmentDataTablePaginationState();

  const resetPagination = useCallback(
    (criteriaVariables?: ClientsCriteriaFromSegment) => {
      setPagination({ pageIndex: 0, pageSize: pageSize });
      const after = btoa((0).toString());
      refetch({
        first: pageSize,
        after,
        ...criteriaVariables,
      });
    },
    [pageSize, refetch, setPagination],
  );

  const { formatSort, clientSegmentSortingState, handleSortChange } =
    useClientSegmentTableSorting(
      ClientSegmentSortableFields.NAME,
      setPagination,
      false,
    );

  const handleSortingChange = useCallback(
    (value: [SortState<ClientSegmentSortableFields>]) => {
      if (!loadingState.fullReload && !loadingState.loadingMore) {
        handleSortChange(value);
      }
    },
    [loadingState, handleSortChange],
  );

  useEffect(() => {
    resetPagination(segmentCriteria);
  }, [segmentCriteria, resetPagination]);

  useEffect(() => {
    fetchData({
      first: pageSize,
      after: endCursor,
      ...segmentCriteria,
      sort: [
        {
          key: formatSort(clientSegmentSortingState).key,
          direction: formatSort(clientSegmentSortingState).direction,
        },
      ],
    });
  }, [
    pageSize,
    fetchData,
    endCursor,
    clientSegmentSortingState,
    formatSort,
    segmentCriteria,
  ]);

  const { clientSegmentData, total } = useClientDataForSelectedSegment({
    clientSegments,
    selectedSegment,
  });

  return {
    pageState,
    getPaginationProps,
    resetPagination,
    clientSegment: {
      clientData: clientSegmentData,
      total: total,
    },
    clientSegmentSortingState,
    handleSortingChange,
  };
}
