import { SplitNames, useSplit } from "utilities/split";

export function usePartialPaymentsSplit(): {
  hasPartialPayments: boolean;
} {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.PartialPayments],
  });

  return {
    hasPartialPayments: getTreatmentValue(SplitNames.PartialPayments),
  };
}
