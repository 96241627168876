import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import type {
  AiAssistantInsightWidgetCategory,
  InsightWidgetsQuery,
  InsightWidgetsQueryVariables,
} from "~/utilities/API/graphql";
import { INSIGHT_WIDGETS_QUERY } from "~/jobber/insightWidgets/InsightWidgetsQuery.graphql";

export const useRandomizedInsightWidget = (
  categories: AiAssistantInsightWidgetCategory[],
) => {
  const { data, loading } = useQuery<
    InsightWidgetsQuery,
    InsightWidgetsQueryVariables
  >(INSIGHT_WIDGETS_QUERY, {
    variables: { categories },
  });
  const insightWidget = useMemo(() => {
    const nodes = data?.aiAssistantInsightWidgets.nodes;

    if (nodes?.length) {
      return nodes[Math.floor(Math.random() * nodes.length)];
    }
  }, [data?.aiAssistantInsightWidgets.nodes]);

  return { insightWidget, loading };
};
