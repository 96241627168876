import React from "react";
import type { ReactNode } from "react";
import { Card, Content } from "@jobber/components";
import styles from "./FinancialInsightCardWithButtonWrapper.module.css";

interface FinancialInsightCardWithButtonWrapperProps {
  children: ReactNode;
}

export const FinancialInsightCardWithButtonWrapper = ({
  children,
}: FinancialInsightCardWithButtonWrapperProps) => (
  <div
    className={styles.cardContainer}
    data-testid="financial-insight-card-wrapper"
  >
    <Card>
      <Content>{children}</Content>
    </Card>
  </div>
);
