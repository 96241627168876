import React from "react";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./ComputeJobProfitabilityButton.module.css";
import { messages } from "./messages";
import { useCreateJobCostingMutation } from "../../useCreateJobCostingMutation";

export const ComputeJobProfitabilityButton = ({ jobId }: { jobId: string }) => {
  const { createJobCost, loading } = useCreateJobCostingMutation(jobId);
  const { formatMessage } = useIntl();

  const handleCalculateProfitability = async () => {
    try {
      Amplitude.TRACK_EVENT("Clicked Button", {
        name: formatMessage(messages.calculateProfitability),
      });

      await createJobCost();
    } catch {
      showToast({
        message: formatMessage(messages.error),
        variation: "error",
      });
    }
  };

  return (
    <div className={styles.computeJobProfitability}>
      <Button
        loading={loading}
        variation="learning"
        type="tertiary"
        size="large"
        label={formatMessage(messages.calculateProfitability)}
        onClick={handleCalculateProfitability}
      />
    </div>
  );
};
