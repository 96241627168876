import { gql, useMutation } from "@apollo/client";
import type {
  JobberPaymentsUpdateDefaultPaymentPreferenceInput,
  JobberPaymentsUpdateDefaultPaymentPreferenceMutation,
} from "~/utilities/API/graphql";

export const UPDATE_DEFAULT_PREFERENCE_MUTATION = gql`
  mutation JobberPaymentsUpdateDefaultPaymentPreference(
    $input: JobberPaymentsUpdateDefaultPaymentPreferenceInput!
  ) {
    jobberPaymentsUpdateDefaultPaymentPreference(input: $input) {
      success
      userErrors {
        message
        path
      }
    }
  }
`;

export function useUpdateDefaultPaymentPreference() {
  const [updateSettings, { error, loading, data }] =
    useMutation<JobberPaymentsUpdateDefaultPaymentPreferenceMutation>(
      UPDATE_DEFAULT_PREFERENCE_MUTATION,
    );

  async function handleUpdateSettings(
    input: JobberPaymentsUpdateDefaultPaymentPreferenceInput,
  ) {
    return updateSettings({
      variables: {
        input: input,
      },
    });
  }

  return {
    handleUpdateSettings,
    error,
    loading,
    data,
  };
}
