import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { LocationTimersMode } from "~/utilities/API/graphql";
import { LocationTimersSettingsPage } from "./LocationTimersSettingsPage";

export interface LocationTimerSettingsPageProps {
  isAvailable?: boolean;
  isEnabled?: boolean;
  mode: LocationTimersMode;
  enableOnLoad?: boolean;
}

export function LocationTimersSettingsPageLoader(
  props: LocationTimerSettingsPageProps,
) {
  return (
    <APIProvider>
      <IntlProvider>
        <LocationTimersSettingsPage {...props}></LocationTimersSettingsPage>
      </IntlProvider>
    </APIProvider>
  );
}
