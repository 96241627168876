import React, { useEffect } from "react";
import { Icon } from "@jobber/components";
import GooglePayCard from "@images/GPay.svg";
import type { PaymentMethodType } from "~/bunker/payments_react/clientHubJobberPayments/components/CheckoutContainer/types";
import styles from "./PaymentMethodChips.module.css";
import { PaymentMethodChip } from "./PaymentMethodChip";

export interface PaymentMethodChipType {
  icon: React.ReactNode;
  type: PaymentMethodType;
}

const paymentMethodIconMapper = (
  type: PaymentMethodType,
): PaymentMethodChipType => {
  switch (type) {
    case "creditCard":
      return { icon: <Icon name="payment" />, type };
    case "bankAccount":
      return { icon: <Icon name="bank" />, type };
    case "applePay":
      return { icon: <Icon name="apple" />, type };
    case "googlePay":
      return { icon: <img src={GooglePayCard} alt="Google Pay" />, type };
  }
};

interface PaymentMethodChipsProps {
  options: PaymentMethodType[];
  onChange: (selectedPayment: string) => void;
  defaultOption?: PaymentMethodType;
}

const PaymentMethodChips: React.FC<PaymentMethodChipsProps> = ({
  options,
  onChange,
  defaultOption,
}) => {
  const [selected, setSelected] = React.useState<string | null>(null);

  useEffect(() => {
    if (defaultOption && !selected) {
      setSelected(defaultOption);
      onChange(defaultOption);
    }
  }, [defaultOption, onChange, selected]);

  const handleClick = (name: string) => {
    setSelected(prevSelected => (prevSelected === name ? null : name));
    onChange(name);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    name: string,
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      handleClick(name);
    }
  };

  return (
    <div className={styles.paymentMethodsContainer}>
      {options.map(option => (
        <PaymentMethodChip
          key={option}
          method={paymentMethodIconMapper(option)}
          onClick={() => handleClick(option)}
          isSelected={selected === option}
          onKeyDown={e => handleKeyDown(e, option)}
        />
      ))}
    </div>
  );
};

export { PaymentMethodChips };
