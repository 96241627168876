import { gql } from "@apollo/client";

export const APP_REQUEST_CREATE_MUTATION = gql`
  mutation AppRequestCreate($input: AppRequestCreateInput!) {
    appRequestCreate(input: $input) {
      appRequest {
        appName
        description
      }
      userErrors {
        message
      }
    }
  }
`;
