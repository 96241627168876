import React from "react";
import classnames from "classnames";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Markdown } from "@jobber/components/Markdown";
import { strFormatDate } from "@jobber/components/FormatDate";
import { useIntl } from "react-intl";
import { SubmittedEvidence } from "jobber/features/PaymentDisputes/components/SubmittedEvidence/SubmittedEvidence";
import {
  type DisputeType,
  hasIssuerEvidence,
} from "jobber/features/PaymentDisputes/utils/disputeUtils";
import { DisputeCategory, DisputeStatus } from "~/utilities/API/graphql";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./ViewDisputeStatus.module.css";
import { messages } from "./messages";
import { HELP_CENTER_URL } from "../../constants";
import { SupportingFileLink } from "../SupportingFileLink";

interface ViewDisputeStatusProps {
  dispute: NonNullable<DisputeType>;
  onViewClaimDetailsClick: () => void;
  setSupportingEvidenceFileDownloadError: React.Dispatch<
    React.SetStateAction<string>
  >;
}

// eslint-disable-next-line max-statements
export function ViewDisputeStatus({
  dispute,
  onViewClaimDetailsClick,
  setSupportingEvidenceFileDownloadError,
}: ViewDisputeStatusProps) {
  const { formatMessage } = useIntl();

  const category = dispute.category;
  const status = dispute.status;
  const refunded = dispute.refundedAt;
  const responseSubmittedAt = dispute.responseSubmittedAt;
  const resolutionReceivedAt = dispute.resolutionReceivedAt;
  const formattedAmount = formatCurrency(dispute.amount, "$");

  const disputeDateDecidedInFavourText =
    resolutionReceivedAt && strFormatDate(new Date(resolutionReceivedAt), true);

  const disputeResponseSubmittedOnDateText =
    responseSubmittedAt && strFormatDate(new Date(responseSubmittedAt), true);

  const refundedAtDateText =
    refunded && strFormatDate(new Date(refunded), true);

  const getHeader = () => {
    let header = "";
    switch (category) {
      case DisputeCategory.CHARGEBACK:
        switch (status) {
          case DisputeStatus.UNDER_REVIEW:
            header = formatMessage(messages.headerInReview);
            break;
          case DisputeStatus.LOST:
            header = formatMessage(messages.headerLost);
            break;
          case DisputeStatus.WON:
            header = formatMessage(messages.headerWon);
            break;
        }
        break;
      case DisputeCategory.INQUIRY:
        switch (status) {
          case DisputeStatus.WARNING_UNDER_REVIEW:
            header = formatMessage(messages.headerInReview);
            break;
          case DisputeStatus.WARNING_CLOSED:
            header = formatMessage(messages.headerClosed);
            break;
        }
        break;
    }
    return header;
  };

  const showSubmittedEvidence = () => {
    if (!responseSubmittedAt) return false;

    const targetDate = new Date("2023-08-22T00:00:00.000Z");
    const responseDate = new Date(responseSubmittedAt);

    return responseDate >= targetDate;
  };

  const getParagraphOne = () => {
    let paragraphOne = "";
    switch (category) {
      case DisputeCategory.CHARGEBACK:
        switch (status) {
          case DisputeStatus.UNDER_REVIEW:
            paragraphOne = formatMessage(messages.paragraphOneInReview, {
              date: disputeResponseSubmittedOnDateText,
            });
            break;
          case DisputeStatus.LOST:
            paragraphOne = formatMessage(messages.paragraphOneLost, {
              amount: formattedAmount,
            });
            break;
          case DisputeStatus.WON:
            paragraphOne = formatMessage(messages.paragraphOneWon, {
              date: disputeDateDecidedInFavourText,
              amount: formattedAmount,
            });
            break;
        }
        break;
      case DisputeCategory.INQUIRY:
        switch (status) {
          case DisputeStatus.WARNING_UNDER_REVIEW:
            paragraphOne = formatMessage(messages.paragraphOneInReview, {
              date: disputeResponseSubmittedOnDateText,
            });
            break;
          case DisputeStatus.WARNING_CLOSED:
            if (refunded) {
              paragraphOne = formatMessage(
                messages.paragraphOneRefundedClosed,
                { date: refundedAtDateText },
              );
            } else {
              paragraphOne = formatMessage(messages.paragraphOneClosed);
            }
            break;
        }
        break;
    }
    return paragraphOne;
  };

  const learnMoreValue =
    formatMessage(messages.learnMore) + `(${HELP_CENTER_URL}).`;

  return (
    <>
      <Card header={getHeader()}>
        <Content>
          <Text maxLines="large">{getParagraphOne()}</Text>
          {learnMoreValue && (
            <Markdown content={learnMoreValue} externalLink={true} />
          )}
          {hasIssuerEvidence(dispute) && (
            <SupportingFileLink
              customClassName={classnames(styles.viewClaimDetailsContainer)}
              onViewSupportingFileClick={onViewClaimDetailsClick}
              supportingFileLinkText={formatMessage(messages.viewClaimDetails)}
              ariaLabel={formatMessage(messages.viewClaimDetailsA11y)}
              fileIcon={true}
            />
          )}
        </Content>
      </Card>
      {showSubmittedEvidence() && (
        <SubmittedEvidence
          dispute={dispute}
          setSupportingEvidenceFileDownloadError={
            setSupportingEvidenceFileDownloadError
          }
        />
      )}
    </>
  );
}
