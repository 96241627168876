import React, { type ComponentProps } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Flex } from "@jobber/components/Flex";
import { InputText } from "@jobber/components/InputText";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";

export interface SearchBoxProps
  extends Required<
    Pick<ComponentProps<typeof InputText>, "value" | "onChange">
  > {
  onSearch: (searchValue: string) => void;
}

export function SearchBox({ value, onChange, onSearch }: SearchBoxProps) {
  const { mediumAndUp } = useBreakpoints();

  const handleSearch = (searchValue: string) => () => onSearch(searchValue);

  return (
    <div data-testid="marketplace-search-box">
      <Content>
        <Flex template={["grow", "shrink"]} gap="small">
          <InputText
            placeholder="Search app names or features"
            value={value}
            onChange={newValue => onChange(newValue)}
            prefix={
              mediumAndUp
                ? {
                    icon: "search",
                  }
                : undefined
            }
            clearable="always"
            onEnter={handleSearch(value.toString())}
          />
          <Button
            label={mediumAndUp ? "Search Apps" : ""}
            icon={mediumAndUp ? undefined : "search"}
            size="large"
            ariaLabel="Search Apps"
            onClick={handleSearch(value.toString())}
          ></Button>
        </Flex>
      </Content>
    </div>
  );
}
