import React from "react";
import { useIntl } from "react-intl";
import { Icon } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import blueBackground from "@images/signup/blue-bg-w-effect.png";
import { messages } from "jobber/setupWizard/messages";
import styles from "./SPQuote.module.css";
import type { MessageFormat } from "../../types";

export interface SPQuoteDetails {
  copy: React.ReactNode;
  name: MessageFormat;
  location: MessageFormat;
  company: MessageFormat;
}

interface SPQuoteProps {
  quote: SPQuoteDetails;
}

export function SPQuote({ quote }: SPQuoteProps) {
  const { formatMessage } = useIntl();
  const { copy, name, location, company } = quote;
  return (
    <div className={styles.quoteWrapper}>
      <div className={styles.quote}>
        <img
          src={blueBackground}
          alt={formatMessage(messages.backgroundImageAltText)}
          className={styles.backgroundImg}
        />
        <div className={[styles.quotationWrapper, "quotation"].join(" ")}>
          <span>“</span>
        </div>
        {copy && <div data-testid="quote-copy">{copy}</div>}
      </div>
      <div className={styles.details}>
        <Heading level={3}>{formatMessage(name)}</Heading>
        <div className={styles.detailsRow}>
          <p>{formatMessage(company)}</p>
          <Icon name="address" color="brandHighlight" />
          <p>{formatMessage(location)}</p>
        </div>
      </div>
    </div>
  );
}
