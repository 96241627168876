import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addons: {
    id: "checkout.subscriptionAddonCards.addons",
    defaultMessage: "Add-ons",
    description: "Add-ons heading",
  },
  addToPlan: {
    id: "checkout.subscriptionAddonCards.addToPlan",
    defaultMessage: "Add",
    description: "Add to plan button label",
  },
  remove: {
    id: "checkout.subscriptionAddonCards.remove",
    defaultMessage: "Remove",
    description: "Remove button label",
  },
  addToPlanAriaLabel: {
    id: "checkout.subscriptionAddonCards.addToPlanAriaLabel",
    defaultMessage: "Add {addonName}",
    description: "Aria label for add to plan button",
  },
  removeAriaLabel: {
    id: "checkout.subscriptionAddonCards.removeAriaLabel",
    defaultMessage: "Remove {addonName}",
    description: "Aria label for remove button",
  },
  recommended: {
    id: "checkout.subscriptionAddonCards.recommended",
    defaultMessage: "Recommended",
    description: "Recommended label",
  },
  moreAddOns: {
    id: "checkout.subscriptionAddonCards.moreAddOns",
    defaultMessage: "More add-ons",
    description: "More add-ons heading",
  },
});
