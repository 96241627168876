import { gql } from "@apollo/client";
import { CAMPAIGN, TEMPLATES } from "jobber/campaigns/graphql";

export const GET_CAMPAIGN_DEFAULT_TEMPLATE_BY_TYPE = gql`
  query commsCampaignDefaultTemplate($type: Template!) {
    commsCampaignDefaultTemplate(type: $type) {
      ...templates
    }
  }
  ${TEMPLATES}
`;

export const CAMPAIGN_CREATE_TEMPLATES = gql`
  mutation campaignCreateTemplates($input: CampaignCreateTemplatesInput!) {
    campaignCreateTemplates(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;

export const CAMPAIGN_UPDATE_TEMPLATES = gql`
  mutation campaignUpdateTemplates($input: CampaignUpdateTemplatesInput!) {
    campaignUpdateTemplates(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;

export const CAMPAIGN_SEND_TEST = gql`
  mutation campaignSendTestEmail($input: CampaignSendTestEmailInput!) {
    campaignSendTestEmail(input: $input) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const CAMPAIGN_SEND_TEST_FOR_DEMO = gql`
  mutation campaignSendTestEmailForDemo(
    $input: CampaignSendTestEmailDemoInput!
  ) {
    campaignSendTestEmailForDemo(input: $input) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const CAMPAIGN_SEND = gql`
  mutation campaignSend($campaignId: EncodedId!, $input: CampaignSendInput) {
    campaignSend(campaignId: $campaignId, input: $input) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const GET_CAMPAIGN_COMPANY_INFORMATION = gql`
  query CampaignCompanyInformation {
    account {
      id
      name
      companyDetails {
        logoUrl
        address
        street1
        street2
        city
        province
        country
        postalCode
      }
    }
  }
`;

export const GET_ACCOUNT_FEATURES_STATUS_FOR_CTA_LINK_TO = gql`
  query AccountFeatureStatusForCtaLinkTo {
    onlineBookingConfiguration {
      acceptingOnlineBookings
    }
    requestsConfiguration {
      acceptingRequestsInClientHub
    }
  }
`;
