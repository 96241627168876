import type { ApolloError } from "@apollo/client";
import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import { useState } from "react";
import type {
  CampaignEmailFilterAttributes,
  CommsCampaignEmailsQuery,
} from "~/utilities/API/graphql";
import { GET_CAMPAIGN_EMAILS } from "jobber/campaigns/graphql";

export enum CAMPAIGN_EMAIL_SORT_KEYS {
  RECIPIENT = "RECIPIENT",
}

export enum CAMPAIGN_EMAIL_SORT_DIRECTION {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export interface UseCommsCampaignEmailsQuery {
  data?: CommsCampaignEmailsQuery;
  error?: ApolloError;
  loadingInitialContent: boolean;
  loadingNextPage: boolean;
  nextPage: () => void;
  refresh: () => void;
  setSortVariable: (
    sortKey: CAMPAIGN_EMAIL_SORT_KEYS,
    sortDirection: CAMPAIGN_EMAIL_SORT_DIRECTION,
  ) => void;
}

interface UseCommsCampaignEmailsQueryProps {
  campaignId: string;
  searchTerm?: string;
  filter?: CampaignEmailFilterAttributes;
}

export function useCommsCampaignEmailsQuery({
  campaignId,
  searchTerm = "",
  filter = undefined,
}: UseCommsCampaignEmailsQueryProps): UseCommsCampaignEmailsQuery {
  const [variables, setVariables] = useState({
    key: CAMPAIGN_EMAIL_SORT_KEYS.RECIPIENT,
    direction: CAMPAIGN_EMAIL_SORT_DIRECTION.ASCENDING,
  });

  const {
    data,
    loadingInitialContent,
    loadingNextPage,
    error,
    loadingRefresh,
    nextPage,
    refresh,
  } = useCollectionQuery<CommsCampaignEmailsQuery>({
    query: GET_CAMPAIGN_EMAILS,
    queryOptions: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        campaignId: campaignId || "",
        searchTerm: searchTerm || "",
        filter: filter,
        sort: variables,
      },
      skip: !campaignId,
    },
    getCollectionByPath: campaign => campaign?.commsCampaign?.emails,
  });

  const setSortVariable = (
    sortKey: CAMPAIGN_EMAIL_SORT_KEYS,
    sortDirection: CAMPAIGN_EMAIL_SORT_DIRECTION,
  ) => {
    const sortOrder = sortDirection
      ? sortDirection
      : CAMPAIGN_EMAIL_SORT_DIRECTION.ASCENDING;
    setVariables({ key: sortKey, direction: sortOrder });
  };

  return {
    data,
    error,
    loadingInitialContent: loadingRefresh || loadingInitialContent,
    loadingNextPage,
    nextPage,
    refresh,
    setSortVariable,
  };
}
