import { Button } from "@jobber/components";
import { useState } from "react";
import { useIntl } from "react-intl";
import type { LineItem } from "~/jobber/lineItems/types";
import { messages } from "./messages";

interface LineItemSaveButtonProps {
  lineItem: LineItem;
  onSaveLineItem(lineItem: LineItem): Promise<void>;
}

export function LineItemSaveButton({
  lineItem,
  onSaveLineItem,
}: LineItemSaveButtonProps) {
  const { formatMessage } = useIntl();
  const [saving, setSaving] = useState(false);
  const handleSaveClick = async () => {
    setSaving(true);
    await onSaveLineItem(lineItem);
    setSaving(false);
  };

  return (
    <Button
      ariaLabel={formatMessage(messages.save)}
      label={formatMessage(messages.save)}
      icon="checkmark"
      size="small"
      onClick={handleSaveClick}
      loading={saving}
    />
  );
}
