import React from "react";
import { Button } from "@jobber/components/Button";
import { Tooltip } from "@jobber/components/Tooltip";
import { useIntl } from "react-intl";
import { APIProvider } from "~/utilities/API/APIProvider";
import { messages } from "./messages";

export interface OptionalLineItemsPromptProps {
  onLockedAddOptionalLineItemButtonClick(): void;
}

export function OptionalLineItemsPrompt({
  onLockedAddOptionalLineItemButtonClick,
}: OptionalLineItemsPromptProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <APIProvider>
      <Tooltip message={formatMessage(messages.tooltipMessage)}>
        <Button
          label={formatMessage(messages.addOptionalLineItemCtaLabel)}
          ariaLabel={formatMessage(messages.addOptionalLineItemCtaLabel)}
          variation="learning"
          type="secondary"
          icon="lock"
          fullWidth={true}
          onClick={onLockedAddOptionalLineItemButtonClick}
        />
      </Tooltip>
    </APIProvider>
  );
}
