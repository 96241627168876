import React from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
} from "recharts";
import { formatTooltipContent, getDateLabel } from "./utility";

interface DoubleSparklineProps {
  primarySeries: readonly object[];
  secondarySeries: readonly object[];
  primaryColor: string;
  secondaryColor: string;
  primaryLabel: string;
  secondaryLabel: string;
  primaryDataKey: string;
  secondaryDataKey: string;
  amountDataKey?: string;
  currencySymbol: string;
}

function customTooltip(
  props: TooltipProps<React.ReactText, React.ReactText>,
  currencySymbol: string,
  amountDatakey?: string,
) {
  if (props.active && props.payload) {
    return (
      <>
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "var(--color-lightBlue--lightest)",
            color: "var(--color-greyBlue--dark)",
            padding: "var(--space-small)",
            border: "var(--border-base) solid var(--tooltip--border-color)",
          }}
        >
          <p
            className="label"
            style={{
              color: "var(--color-blue--dark))",
              marginBottom: "var(--space-small)",
              fontWeight: "bold",
            }}
          >
            {`${getDateLabel(props.label)}`}
          </p>
          <span className="intro">
            {formatTooltipContent(
              props.payload,
              0,
              currencySymbol,
              amountDatakey,
            )}
          </span>
          <br />
          <span className="intro">
            {formatTooltipContent(
              props.payload,
              1,
              currencySymbol,
              amountDatakey,
            )}
          </span>
        </div>
      </>
    );
  }

  return null;
}

export function DoubleSparkline({
  primarySeries,
  secondarySeries,
  primaryColor,
  secondaryColor,
  primaryLabel,
  secondaryLabel,
  primaryDataKey,
  secondaryDataKey,
  amountDataKey,
  currencySymbol,
}: DoubleSparklineProps) {
  return (
    <>
      <ResponsiveContainer width="100%" height={80}>
        <LineChart>
          <Line
            type="monotone"
            data={primarySeries}
            dataKey={primaryDataKey}
            name={primaryLabel}
            stroke={primaryColor}
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            data={secondarySeries}
            dataKey={secondaryDataKey}
            name={secondaryLabel}
            stroke={secondaryColor}
            strokeWidth={2}
            dot={false}
          />
          <XAxis dataKey="x" hide={true} allowDuplicatedCategory={false} />
          <Legend
            align="left"
            verticalAlign="bottom"
            iconSize={16}
            iconType="circle"
            wrapperStyle={{ paddingTop: "var(--space-small)" }}
          />
          <Tooltip content={formatTooltip} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );

  function formatTooltip(args: TooltipProps<React.ReactText, React.ReactText>) {
    return customTooltip(args, currencySymbol, amountDataKey);
  }
}
