import React from "react";
import { DriveTimeLabel } from "./DriveTimeLabel";

interface AvailabilitySlotProps {
  eventId: string;
  driveTimeTo?: number;
  driveTimeFrom?: number;
}

export const AvailabilitySlot = ({
  eventId,
  driveTimeTo,
  driveTimeFrom,
}: AvailabilitySlotProps) => {
  return (
    <div data-eventid={eventId}>
      {driveTimeTo != null && <DriveTimeLabel mins={driveTimeTo} />}
      {driveTimeFrom != null && (
        <DriveTimeLabel mins={driveTimeFrom} isDriveTimeFrom />
      )}
    </div>
  );
};
