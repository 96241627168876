import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Modal } from "@jobber/components/Modal";
import { useIntl } from "react-intl";
import type { JobDetailsFragment, User } from "~/utilities/API/graphql";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import { CreateExpenseModal } from "./ExpenseModal";
import styles from "./ExpensesTable.module.css";
import { messages } from "./messages";

interface ExpenseTitleProps {
  job: JobDetailsFragment;
  currentUser: User;
}

export function ExpensesTableTitle({ job, currentUser }: ExpenseTitleProps) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <tr>
      <th colSpan={100}>
        <div className={styles.title}>
          <Heading level={2}>{formatMessage(jobCostMessages.expenses)}</Heading>
          <Button
            label={formatMessage(messages.newExpense)}
            ariaLabel={formatMessage(messages.newExpense)}
            type="secondary"
            size="small"
            onClick={() => setModalOpen(true)}
          />
        </div>
        <Modal
          open={modalOpen}
          onRequestClose={closeModal}
          title={formatMessage(messages.newExpense)}
        >
          <CreateExpenseModal
            jobId={job.id}
            closeModal={closeModal}
            currentUser={currentUser}
          />
        </Modal>
      </th>
    </tr>
  );

  function closeModal() {
    setModalOpen(false);
  }
}
