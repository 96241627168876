import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import React, { useEffect, useState } from "react";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/upgradePaths/ANALYTICS_EVENTS";
import styles from "./InvoicesModal.module.css";

interface Props {
  unpaidInvoicesTotal: number;
  modalOpen: boolean;
  redirectLink: string;
}

const ctaName = "invoice-upgrade-modal";

export function InvoiceUpgradeModal(props: Props) {
  const [modalOpen, setModalOpen] = useState(props.modalOpen);
  const trackingClass = "js-invoiceFollowUpUpgradePrompt";

  useEffect(() => {
    CTAMarkSeen(ctaName);
  }, []);

  return (
    <>
      <Modal
        title="DID YOU KNOW?"
        open={modalOpen}
        size="small"
        onRequestClose={handleCloseButtonClick}
        primaryAction={{
          label: "Learn More",
          onClick: handlePrimaryAction,
          variation: "learning",
        }}
        tertiaryAction={{
          label: "Remind Me Later",
          onClick: handleTertiaryAction,
          variation: "subtle",
          type: "primary",
        }}
      >
        <div className={`${styles.center} ${trackingClass}`}>
          <Content>
            <p className={styles.emoji}>💸</p>
            <Text>
              <Heading level={2}>
                You have ${props.unpaidInvoicesTotal} in overdue invoices.
              </Heading>
            </Text>
            <br />
            <Text>
              Schedule{" "}
              <Emphasis variation="bold">automated invoice follow-ups</Emphasis>{" "}
              to remind clients their invoices are due and give them an easy,
              one-click option to pay.
            </Text>
          </Content>
        </div>
      </Modal>
    </>
  );

  function handleCloseButtonClick(): void {
    CTAMarkConverted(ctaName);
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.dialogInteraction,
      {
        interaction:
          ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.interactions.dismissed,
        value: ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.locations.prompt,
      },
    );
    setModalOpen(false);
  }

  function handlePrimaryAction() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.learnMore, {
      location: ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.locations.prompt,
    });

    window.location.href = props.redirectLink;
  }

  function handleTertiaryAction(): void {
    CTAMarkConverted(ctaName);
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.dialogInteraction,
      {
        interaction:
          ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.interactions.remindMeLater,
        value: ANALYTICS_EVENTS.invoiceFollowUpsUpgrade.locations.prompt,
      },
    );
    setModalOpen(false);
  }
}
