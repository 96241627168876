import { showToast } from "@jobber/components/Toast";
import { FORM_VERSION } from "./CancellationFormModal";
import type {
  AdditionalSubscription,
  CancellationFormDetails,
  CancellationFormState,
  HttpResponse,
  ResponseBody,
} from "./CancellationForm.d";

export async function submitForm(
  additionalSubscription: AdditionalSubscription | undefined,
  answers: CancellationFormState,
  offeredSave: boolean,
  setButtonLoadingState: (loadingState: boolean) => void,
  onSuccess: () => void,
  csrfToken: string,
) {
  setButtonLoadingState(true);

  const request: Request = buildSubmissionRequest(
    additionalSubscription,
    answers,
    offeredSave,
    csrfToken,
  );
  const response: HttpResponse = await fetch(request);

  if (response.status === 202) {
    onSuccess();
  } else {
    setButtonLoadingState(false);
    response.parsedBody = (await response.json()) as ResponseBody;
    if (response.parsedBody && response.parsedBody.errors) {
      showToast({
        message: `Error cancelling account: ${response.parsedBody.errors}`,
        variation: "error",
      });
    }
  }
}

function buildCancellationDetails(answers: CancellationFormState) {
  const cancellationDetails = answers.reduce(
    (details: CancellationFormDetails, answer) => {
      details[answer.label] = answer.value;
      return details;
    },
    {},
  );

  cancellationDetails.version = FORM_VERSION;
  return cancellationDetails;
}

function buildSubmissionRequest(
  additionalSubscription: AdditionalSubscription | undefined,
  answers: CancellationFormState,
  offeredSave: boolean,
  csrfToken: string,
) {
  const details = buildCancellationDetails(answers);
  const headers = new Headers([
    ["X-CSRF-Token", csrfToken],
    ["Content-type", "application/json"],
  ]);

  return new Request("/accounts/cancel_account_subscription", {
    method: "DELETE",
    credentials: "include",
    headers,
    body: JSON.stringify({
      /* eslint-disable @typescript-eslint/naming-convention */
      cancellation_details: details,
      additional_subscription: additionalSubscription,
      save_offered: offeredSave,
      /* eslint-enable @typescript-eslint/naming-convention */
    }),
  });
}
