import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { InputCurrency } from "components/InputCurrency";
import { messages } from "./messages";

interface LineItemUnitCostProps {
  reactKey: string;
  unitCost: number | undefined;
  onChange: (value: number) => void;
}

export function LineItemUnitCost({
  reactKey,
  unitCost,
  onChange,
}: LineItemUnitCostProps) {
  const { formatMessage } = useIntl();
  const { currencySymbol } = useAccount();

  return (
    <InputCurrency
      name={`lineItems.${reactKey}.unit_cost`}
      placeholder={formatMessage(messages.unitCost)}
      value={unitCost}
      prefix={{ label: currencySymbol }}
      autocomplete={false}
      onChange={onChange}
      validations={{
        min: {
          message: formatMessage(messages.unitCostMinimumValueError),
          value: -9999999999,
        },
        max: {
          message: formatMessage(messages.unitCostMaximumValueError),
          value: 9999999999,
        },
      }}
    />
  );
}
