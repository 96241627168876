import React, { type ReactNode, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormState } from "@jobber/hooks/useFormState";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Form } from "@jobber/components/Form";
import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import type {
  ReferralMessagingModalFieldsFragment,
  ReferralMessagingUpdateMutation,
} from "~/utilities/API/graphql";
import { ReferralMessagingUpdate } from "./ReferralMessagingModal.graphql";

interface ReferralMessagingModalProps {
  messagingSettings: ReferralMessagingModalFieldsFragment;
  isOpen: boolean;
  setModalOpenState(state: boolean): void;
}

export function ReferralMessagingModal({
  isOpen,
  messagingSettings,
  setModalOpenState,
}: ReferralMessagingModalProps) {
  const [formState, setFormState] = useFormState();
  const [newMessagingSettings, setMessagingSettings] =
    useState(messagingSettings);
  const [isSaving, setIsSaving] = useState(false);
  const [notificationBanner, setNotificationBanner] = useState<ReactNode>();
  const [updateMessaging] = useMutation<ReferralMessagingUpdateMutation>(
    ReferralMessagingUpdate,
  );

  useEffect(() => {
    setMessagingSettings(messagingSettings);
  }, [messagingSettings]);

  return (
    <Modal
      title="Edit Referrals Messaging"
      open={isOpen}
      onRequestClose={closeAndResetModal}
      primaryAction={{
        label: "Save",
        ariaLabel: "Save messaging changes",
        onClick: saveMessaging,
        loading: isSaving,
      }}
      secondaryAction={{
        label: "Cancel",
        ariaLabel: "Cancel messaging changes",
        onClick: closeAndResetModal,
        disabled: isSaving,
      }}
    >
      <Content>
        {notificationBanner}
        <Form onStateChange={setFormState}>
          <Content>
            <Content spacing="small">
              <Heading level={4}>‘Refer a friend’ prompt</Heading>
              <Text>
                Use your own messaging and incentives to encourage referrals.
                Appears on the invoice page in client hub.
              </Text>
            </Content>

            <InputText
              multiline={true}
              value={newMessagingSettings.bannerMessage}
              onChange={onChangeMessage("bannerMessage")}
              placeholder="Banner Message (maximum 200 characters)"
              validations={{
                required: {
                  value: true,
                  message: "You must include a banner message",
                },
                maxLength: {
                  value: 200,
                  message: "Your message exceeds 200 characters",
                },
              }}
            />

            <Content spacing="small">
              <Heading level={4}>Shareable Message</Heading>
              <Text>
                Default message that your client can edit before sharing with
                friends.
              </Text>
            </Content>

            <InputText
              multiline={true}
              value={newMessagingSettings.commMessage}
              onChange={onChangeMessage("commMessage")}
              placeholder="Shareable Message (maximum 160 characters)"
              validations={{
                required: {
                  value: true,
                  message: "You must include a shareable message",
                },
                maxLength: {
                  value: 160,
                  message: "Your message exceeds 160 characters",
                },
              }}
            />
          </Content>
        </Form>
      </Content>
    </Modal>
  );

  function saveMessaging() {
    if (!formState.isDirty) {
      // There are no changes, just close without saving
      closeAndResetModal();
      return;
    }

    if (formState.isValid) {
      setIsSaving(true);

      updateMessaging({
        variables: {
          bannerMessage: newMessagingSettings.bannerMessage,
          commMessage: newMessagingSettings.commMessage,
        },
      }).then(
        ({ data }) => {
          setIsSaving(false);

          // Mutation was successful
          if (
            data &&
            data.clientHubReferralSettingsUpsert.errors.length === 0
          ) {
            // No errors returned from the mutation
            showToast({
              message: "Your messaging has been saved",
              variation: "success",
            });
            closeModal();
          } else {
            // Errors were returned from the mutation
            handleErrors(
              data?.clientHubReferralSettingsUpsert.errors ?? [
                "An unknown error occurred",
              ],
            );
          }
        },
        () => {
          setIsSaving(false);

          // Network error
          handleErrors([
            "An error occurred when saving your messaging. Please try again later",
          ]);
        },
      );
    } else {
      // The form isn't valid
      handleErrors(["Correct all errors before saving"]);
    }
  }

  function closeAndResetModal() {
    setMessagingSettings(messagingSettings);
    closeModal();
  }

  function closeModal() {
    setIsSaving(false);
    setNotificationBanner(<></>);
    setModalOpenState(false);
  }

  function onChangeMessage(field: "bannerMessage" | "commMessage") {
    return (newMessage: string | number | boolean) => {
      setMessagingSettings(prevSettings => {
        return {
          ...prevSettings,
          [field]: newMessage.toString(),
        };
      });
    };
  }

  function handleErrors(errors: string[]) {
    setNotificationBanner(
      <>
        {errors.map(error => (
          <Banner key={error} type="error" dismissible={false}>
            {error}
          </Banner>
        ))}
      </>,
    );
  }
}
