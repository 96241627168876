import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { GlobalMessagesDismissMutationMutation } from "~/utilities/API/graphql";
import { GLOBAL_MESSAGES_DISMISS_MUTATION } from "jobber/features/GlobalBanner/components/GlobalBanner";

export function useGlobalMessageDismissMutation() {
  const [globalMessageDismiss, { loading }] =
    useMutation<GlobalMessagesDismissMutationMutation>(
      GLOBAL_MESSAGES_DISMISS_MUTATION,
      {
        update(cache, { data: mutationData }) {
          if (mutationData?.globalMessageDismiss.messages) {
            cache.modify({
              fields: {
                globalMessages() {
                  return {
                    nodes: mutationData?.globalMessageDismiss.messages?.nodes,
                  };
                },
              },
            });
          }
        },
      },
    );

  async function handleGlobalMessageDismiss(id: string) {
    try {
      const result = await globalMessageDismiss({ variables: { id } });
      const errors = result.data?.globalMessageDismiss.errors;

      if (errors && errors.length > 0) {
        showToast({ message: errors[0].message, variation: "error" });
      }
    } catch {
      showToast({
        message: "Something went wrong. Please try again or contact Support.",
        variation: "error",
      });
    }
  }

  return { loading, handleGlobalMessageDismiss };
}
