import { gql } from "@apollo/client";

export const AI_ASSISTANT_TEXT_REVISE = gql`
  mutation AiAssistantTextRevise(
    $conversationId: Uuid!
    $input: AiAssistantTextReviseInput!
  ) {
    aiAssistantTextRevise(conversationId: $conversationId, input: $input) {
      message {
        content {
          ... on AiAssistantTextReviseExchange {
            errors
            operation
            submission
            revision
            contentType
          }
        }
        requestId
        createdAt
        conversationId
      }
      userErrors {
        message
        path
      }
    }
  }
`;
