import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { Icon } from "@jobber/components/Icon";
import { Tooltip } from "@jobber/components/Tooltip";
import styles from "./MetricsCard.module.css";
import { messages } from "./messages";

export interface MetricsCard {
  heading: string;
  value: number;
  subValue: number;
  toolTipText: string;
  loading?: boolean;
  isPercent?: boolean;
  onCardClick?(): void;
}

export function MetricsCard({
  heading,
  value,
  subValue,
  toolTipText,
  loading,
  isPercent = true,
  onCardClick,
}: MetricsCard) {
  const { formatMessage } = useIntl();

  const cardClicked = () => {
    onCardClick && onCardClick();
  };

  return (
    <label className={styles.inputLabelContainer}>
      <input onClick={cardClicked} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentContainer}>
            <div className={styles.title}>
              <Heading level={4}>{heading}</Heading>
              <Tooltip message={toolTipText}>
                <Icon name="help" size="small" />
              </Tooltip>
            </div>

            <div className={styles.contentDescription}>
              {loading && <Glimmer width={200} />}
              <Heading level={2}>
                {!loading &&
                  isPercent &&
                  formatMessage(messages.conversionRateTextPercent, {
                    rate: value,
                  })}
                {!loading &&
                  !isPercent &&
                  formatMessage(messages.conversionRateTextDollar, {
                    amount: value,
                  })}
              </Heading>
            </div>

            <div>
              {loading && <Glimmer width={200} />}
              <Text size={"base"} variation={"subdued"}>
                {!loading &&
                  isPercent &&
                  formatMessage(messages.recipients, { recipients: subValue })}
                {!loading &&
                  !isPercent &&
                  formatMessage(messages.jobs, { count: subValue })}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </label>
  );
}
