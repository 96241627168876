import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import React from "react";

interface DeleteConversationModalProps {
  isOpen: boolean;
  deleteConversation(): void;
  cancel(): void;
}

const deleteModalBody =
  "Conversation history with this number will be removed from messages";

export function DeleteConversationModal({
  isOpen,
  deleteConversation,
  cancel,
}: DeleteConversationModalProps) {
  return (
    <Modal
      title="Delete conversation?"
      open={isOpen}
      secondaryAction={{
        label: "Cancel",
        variation: "subtle",
        onClick: cancel,
      }}
      onRequestClose={() => cancel()}
      primaryAction={{
        label: "Delete",
        variation: "destructive",
        onClick: deleteConversation,
      }}
    >
      <Content>
        <p>{deleteModalBody}</p>
      </Content>
    </Modal>
  );
}
