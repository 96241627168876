import { ApolloError, useQuery } from "@apollo/client";
import { addDays, parseISO } from "date-fns";
import { useMemo } from "react";
import type {
  LocationInput,
  SchedulingAvailabilityQuery,
  SchedulingAvailabilityQueryVariables,
} from "~/utilities/API/graphql";
import {
  MonitoringName,
  useSubscriptionMonitoring,
} from "utilities/useSubscriptionMonitoring";
import { SCHEDULING_AVAILABILITY_QUERY } from "./SchedulingAvailability.graphql";
import type { Recommendation } from "../Recommendations";

export interface SchedulingAvailabilityPayload {
  closest?: Recommendation;
  soonest?: Recommendation;
  schedulingRecommendations?: Recommendation[] | undefined;
  recommendationRequestId?: string;
}

export interface SchedulingAvailabilityResponse {
  recommendations: SchedulingAvailabilityPayload;
  loading: boolean;
  errors: ApolloError[];
}

export interface UseSchedulingAvailabilitySubscriptionArguments {
  scheduledDate: Date | undefined;
  duration: number;
  location?: LocationInput;
  assignees?: string[];
}

export function useSchedulingAvailabilityQuery(
  variables: UseSchedulingAvailabilitySubscriptionArguments,
): SchedulingAvailabilityResponse {
  const {
    data,
    loading,
    error: networkError,
  } = useQuery<
    SchedulingAvailabilityQuery,
    SchedulingAvailabilityQueryVariables
  >(SCHEDULING_AVAILABILITY_QUERY, {
    variables: {
      input: {
        startDate: variables.scheduledDate?.toISOString() ?? "",
        endDate: variables.scheduledDate
          ? addDays(variables.scheduledDate, 14).toISOString()
          : undefined,
        duration: variables.duration,
        location: (variables.location || null) as LocationInput,
        assignees: variables.assignees,
      },
    },
    skip: !variables.scheduledDate,
    fetchPolicy: "cache-first",
  });

  const recommendations = useMemo(() => parseRecommendations(data), [data]);

  useSubscriptionMonitoring({
    name: MonitoringName.SCHEDULING_RECOMMENDATIONS,
    isLoading: loading,
  });

  return {
    recommendations,
    loading,
    errors: networkError
      ? [networkError]
      : recommendations.userErrors?.map(
          e => new ApolloError({ errorMessage: e.message }),
        ) || [],
  };
}

function parseRecommendations(data?: SchedulingAvailabilityQuery) {
  const closest = data?.schedulingAvailability.closest;
  const soonest = data?.schedulingAvailability.soonest;

  return {
    closest: closest ? parseRecommendation(closest) : undefined,
    soonest: soonest ? parseRecommendation(soonest) : undefined,
    schedulingRecommendations:
      data?.schedulingAvailability.schedulingRecommendations.map(
        parseRecommendation,
      ) ?? [],
    userErrors: data?.schedulingAvailability.userErrors,
  };
}

interface SubscriptionRecommendation {
  recommendedStartAt: string;
  recommendedEndAt: string;
  recommendedAssignee?: { id: string; name: { full: string } };
  driveTime?: number;
}

function parseRecommendation(
  recommendation: SubscriptionRecommendation,
): Recommendation {
  const recommendedAssignee = recommendation.recommendedAssignee
    ? {
        id: recommendation.recommendedAssignee.id,
        name: recommendation.recommendedAssignee.name.full,
      }
    : undefined;

  return {
    driveTime: recommendation.driveTime,
    recommendedEndAt: parseISO(recommendation.recommendedEndAt),
    recommendedStartAt: parseISO(recommendation.recommendedStartAt),
    recommendedAssignee,
  };
}
