import React from "react";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import type { CivilDateTime } from "@std-proposal/temporal";

interface TimeStampProps {
  readonly status?: "sent" | "failed";
  readonly sentDateTime: CivilDateTime | string;
}

export function TimeStamp({ sentDateTime, status }: TimeStampProps) {
  const displayName = status === "failed" ? "Failed to deliver" : "";
  const senderLabel = displayName !== "" ? `${displayName}・` : "";

  return (
    <>
      {senderLabel}
      <FormatRelativeDateTime date={sentDateTime} />
    </>
  );
}
