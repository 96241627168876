import { defineMessages } from "react-intl";

export const messages = defineMessages({
  featureLabel: {
    id: "FeatureLandingPage.FeatureLandingPage.FeatureLabel",
    defaultMessage: "{featurePlan} plan feature",
    description: "Label for plan feature",
  },
  buttonSectionHeading: {
    id: "FeatureLandingPage.FeatureLandingPage.ButtonSectionHeading",
    defaultMessage:
      "{trialAvailable, select, true {Find out if } false {Discover how} other {}} {emphasis} {trialAvailable, select, true {is the right plan for you} false {helps streamline your business} other{}}",
    description: "Heading for button section",
  },
  upgradeNowLabel: {
    id: "FeatureLandingPage.FeatureLandingPage.UpgradeNowLabel",
    defaultMessage: "Upgrade Now",
    description: "Label for upgrade now button",
  },
  upgradeNowUrl: {
    id: "FeatureLandingPage.FeatureLandingPage.UpgradeNowUrl",
    defaultMessage:
      "/accounts/billing_info/edit.dialog?plan_set_identifier={planIdentifier}&tier_name={featurePlan}&is_annual=true&is_choosing_plan=true",
    description: "URL for upgrade now button",
  },
  startTrialLabel: {
    id: "FeatureLandingPage.FeatureLandingPage.StartTrialLabel",
    defaultMessage: "Start Free {featurePlan} Trial",
    description: "Label for start trial button",
  },
  chatWithUsLabel: {
    id: "FeatureLandingPage.FeatureLandingPage.ChatWithUsLabel",
    defaultMessage: "Chat with us",
    description: "Label for chat with us button",
  },
  growPlanCardHeading: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardHeading",
    defaultMessage: "Grow",
    description: "Heading for grow plan card",
  },
  growPlanCardDescription: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardDescription",
    defaultMessage:
      "Scale your business with powerful sales and marketing automation tools.",
    description: "Description for grow plan card",
  },
  growPlanCardFeaturesHeading: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardFeaturesHeading",
    defaultMessage: "Includes everything you have today, plus",
    description: "Heading for features section of grow plan card",
  },
  growPlanCardPlanUsersDescription: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardPlanUsersDescription",
    defaultMessage: "Up to 15 users",
    description: "Description for number of users on grow plan card",
  },
  growPlanCardPlanFeatures1: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardPlanFeatures1",
    defaultMessage: "Quote add-ons and images",
    description: "First feature for grow plan card",
  },
  growPlanCardPlanFeatures2: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardPlanFeatures2",
    defaultMessage: "Automated quote follow-ups",
    description: "Second feature for grow plan card",
  },
  growPlanCardPlanFeatures3: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardPlanFeatures3",
    defaultMessage: "Tag and manage leads",
    description: "Third feature for grow plan card",
  },
  growPlanCardPlanFeatures4: {
    id: "FeatureLandingPage.FeatureLandingPage.GrowPlanCardPlanFeatures4",
    defaultMessage: "Job costing",
    description: "Fourth feature for grow plan card",
  },
  corePlanCardHeading: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardHeading",
    defaultMessage: "Core",
    description: "Heading for core plan card",
  },
  corePlanCardDescription: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardDescription",
    defaultMessage:
      "Organize your operations—from quoting to scheduling to invoicing—all in one place.",
    description: "Description for core plan card",
  },
  corePlanCardFeaturesHeading: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardFeaturesHeading",
    defaultMessage: "Includes everything you have today, plus",
    description: "Heading for features section of core plan card",
  },
  corePlanCardPlanUsersDescription: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardPlanUsersDescription",
    defaultMessage: "Up to 1 user",
    description: "Description for number of users on core plan card",
  },
  corePlanCardPlanFeatures1: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardPlanFeatures1",
    defaultMessage: "Schedule and manage jobs",
    description: "First feature for core plan card",
  },
  corePlanCardPlanFeatures2: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardPlanFeatures2",
    defaultMessage: "Online requests",
    description: "Second feature for core plan card",
  },
  corePlanCardPlanFeatures3: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardPlanFeatures3",
    defaultMessage: "1-on-1 product coaching",
    description: "Third feature for core plan card",
  },
  corePlanCardPlanFeatures4: {
    id: "FeatureLandingPage.FeatureLandingPage.CorePlanCardPlanFeatures4",
    defaultMessage: "Across over 20 built-in reports",
    description: "Fourth feature for core plan card",
  },
});
