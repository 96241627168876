import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expiringJobs: {
    id: "dashboard.components.expiringJobsInsight.expiringJobs",
    defaultMessage: "Jobs ending soon",
    description: "The expiring jobs insights card",
  },
  expiringJobsDescription: {
    id: "dashboard.components.expiringJobsInsight.expiringJobsDescription  ",
    defaultMessage:
      "You have {expiringJobsTotal} recurring {expiringJobsTotal, plural, one {job} other {jobs}} that {expiringJobsTotal, plural, one {is} other {are}} ending in the next 30 days.",
    description: "The description of expiring jobs on the insights card",
  },
  viewJobs: {
    id: "dashboard.components.expiringJobsInsight.viewJobs",
    defaultMessage: "View Jobs",
    description: "The action button to view the jobs",
  },
});
