import React from "react";

interface SerializableState {
  name: string;
  value: string;
}

interface StateSerializerForControllerProps {
  controllerName: string;
  state: SerializableState[];
}

/**
 * This component serializes React state into hidden inputs for the Rails controller.
 * Once the whole page is a SPA, we can deprecate this as all calls will be thru GraphQL.
 */
export function StateSerializerForController(
  props: StateSerializerForControllerProps,
) {
  return (
    <>
      {props.state.map((state, index) => {
        return (
          <input
            key={index}
            type="hidden"
            data-testid={state.name}
            name={`${props.controllerName}[${state.name}]`}
            value={state.value}
          />
        );
      })}
    </>
  );
}
