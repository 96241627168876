import { gql } from "@apollo/client";

const ServiceFragment = gql`
  fragment Service on OnlineBookingService {
    id
    name
    description
    defaultUnitCost
    category
    internalUnitCost @include(if: $quoteMarginsEnabled)
    markup @include(if: $quoteMarginsEnabled)
    taxable
    durationMinutes
    onlineBookingsEnabled
    visible
    bookableType
    fileAttachment @include(if: $advancedQuotingEnabled) {
      id
      fileName
      fileSize
      contentType
      s3Key
      thumbnailUrl
    }
    quantityRange {
      quantityEnabled
      maxQuantity
      minQuantity
    }
    accountingSync {
      warnAboutSyncStatus
      disableSyncedFields
      accountingSyncName
    }
  }
`;

export const SELF_SERVE_BOOKINGS_SERVICES_QUERY = gql`
  query onlineBookingServices(
    $advancedQuotingEnabled: Boolean!
    $quoteMarginsEnabled: Boolean!
    $first: Int
    $after: String
  ) {
    onlineBookingServices(first: $first, after: $after) {
      nodes {
        ...Service
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    onlineBookingConfiguration {
      id
      acceptingOnlineBookings
    }
  }
  ${ServiceFragment}
`;

export const SELF_SERVE_BOOKINGS_SERVICE_SORT_ORDER_MUTATION = gql`
  mutation OnlineBookingServiceSortOrderEdit(
    $input: OnlineBookingServiceSortOrderEditInput!
    $advancedQuotingEnabled: Boolean!
    $quoteMarginsEnabled: Boolean!
  ) {
    data: onlineBookingServiceSortOrder(input: $input) {
      reorderedServices {
        ...Service
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ServiceFragment}
`;

export const SELF_SERVE_BOOKINGS_SERVICE_DISABLE_MUTATION = gql`
  mutation OnlineBookingServiceDisable($id: EncodedId!) {
    data: onlineBookingServiceDisable(serviceId: $id) {
      disabledService {
        id
        name
        onlineBookingSortOrder
        onlineBookingsEnabled
      }
      onlineBookingConfiguration {
        id
        acceptingOnlineBookings
      }
      userErrors {
        message
        path
      }
    }
  }
`;
