/**
 * SelectTeamMember Page Object Model
 *
 * Selectors:
 * - teamMember
 * - selectAll
 *
 * Simulators:
 * - toggleAll
 * - toggleTeamMember
 *
 * Other:
 * - isSelected
 * - allSelected
 * - isLoading
 * - waitForLoadingToFinish
 *
 */
import {
  screen,
  waitForElementToBeRemoved,
  within,
} from "@testing-library/react";
import userEvent from "@testing-library/user-event";

/**
 * @returns The checkbox element for the given team member
 */
const teamMember = (name: string): HTMLInputElement =>
  component().getByLabelText(name);

/**
 * @returns The checkbox element for the "Select all" checkbox
 */
const selectAll = (): HTMLInputElement =>
  component().getByLabelText("Select all");

/**
 * Simulates clicking the "Select all" checkbox
 */
export const toggleAll = () => {
  userEvent.click(component().getByText("Select all"));
};

/**
 * Simulates clicking the checkbox for the given team member
 */
export const toggleTeamMember = (name: string) => {
  userEvent.click(teamMember(name));
};

/**
 * @returns True if the checkbox for the given team member is checked
 */
export const isSelected = (name: string) => teamMember(name).checked;

/**
 * @returns True if the "Select all" checkbox is checked
 */
export const allSelected = () => selectAll().checked;

/**
 * @returns True if the checkbox for the given team member is disabled
 */
export const isDisabled = (name: string) => teamMember(name).disabled;

/**
 * @returns True if it is loading the list of available team members
 */
export const isLoading = () => component().queryByLabelText("loading") != null;

/**
 * Wait for the list of available team members to finish loading
 */
export const waitForLoadingToFinish = () =>
  waitForElementToBeRemoved(() => component().queryByLabelText("loading"));

function root() {
  return screen.getByTestId("select-team-members");
}

function component() {
  return within(root());
}
