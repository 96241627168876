import { gql } from "@apollo/client";

export const ARRIVAL_WINDOW_ACCOUNT_SETTINGS = gql`
  query ArrivalWindowAccountSettings {
    account {
      id
      arrivalWindowSettings {
        id
        defaultDuration
        showOnboarding
      }
    }
  }
`;

export const ARRIVAL_WINDOW_ACCOUNT_SETTINGS_EDIT_MUTATION = gql`
  mutation ArrivalWindowAccountSettingsEdit(
    $attributes: ArrivalWindowSettingsEditAttributes!
  ) {
    arrivalWindowsSettingsEdit(attributes: $attributes) {
      arrivalWindowSettings {
        id
        defaultDuration
        showOnboarding
      }
      userErrors {
        message
        path
      }
    }
  }
`;
