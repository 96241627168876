import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "billing.checkout.title",
    defaultMessage: "Checkout",
    description: "Checkout",
  },
  confirmSubscription: {
    id: "billing.checkout.confirmSubscription",
    defaultMessage: "Confirm Subscription",
    description: "Confirm Subscription",
  },
  defaultSubmitError: {
    id: "billing.checkout.defaultSubmitError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Generic submission error",
  },
  successfulUpdate: {
    id: "billing.checkout.successfulUpdate",
    defaultMessage: "Updated subscription",
    description: "Successful update message",
  },
  monthlySubscriptionSavingsMessage: {
    id: "checkout.checkout.monthlySubscriptionSavingsMessage",
    defaultMessage:
      "Save {discountAmount} for {discountDuration} {discountDurationUnit}",
    description: "Main subscription monthly savings message",
  },
  annualSubscriptionSavingsMessage: {
    id: "checkout.checkout.annualSubscriptionSavingsMessage",
    defaultMessage: "Save {discountAmount} for 1 year",
    description: "Main subscription annual savings message",
  },
});
