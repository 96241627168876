import type React from "react";
import { APIClient } from "~/utilities/API/APIClient";
import type {
  JobDefaultCustomFieldValuesQuery,
  QuoteDefaultCustomFieldValuesQuery,
} from "~/utilities/API/graphql";
import type { CustomFieldValue } from "./CustomField";
import {
  JOB_CUSTOM_FIELD_DEFAULTS_QUERY,
  QUOTE_CUSTOM_FIELD_DEFAULTS_QUERY,
} from "./CustomFieldsDefaults.graphql";
import type { Action, State } from "./customFieldReducer";
import type { AreaValue, LinkValue, NumberValue, SelectValue } from "./types";

export type AttachedToClassNames =
  | "Client"
  | "Invoice"
  | "Property"
  | "Quote"
  | "WorkOrder";

export function syncReducerStateToHiddenInputs(
  state: State,
  railsHiddenInputContainerId: string,
) {
  const rootElementForForm = document.querySelector(
    `#${railsHiddenInputContainerId}`,
  );
  if (rootElementForForm === null) {
    return;
  }

  state.allGroups.forEach(function (groupName) {
    const group = state.byGroup[groupName];
    group.allConfigurations.forEach(function (configurationName) {
      const configuration =
        state.byGroup[groupName].byConfiguration[configurationName];

      // The third argument below (dimension1, dimension2, value, text, ...) matches up with the form
      // generated in Rails land.

      let randomIDForNewCustomField: string | undefined;

      switch (configuration.type) {
        case "area":
          randomIDForNewCustomField = setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "dimension1",
            `${(configuration.value as AreaValue).length}`,
          );
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "dimension2",
            `${(configuration.value as AreaValue).width}`,
            randomIDForNewCustomField,
          );
          break;

        case "bool":
          // Although the bridge is liberal in what it accepts, strictly set back a "0" or "1" for
          // false or true, respectively.
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "value",
            `${configuration.value ? "1" : "0"}`,
          );
          break;

        case "int":
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "value",
            `${(configuration.value as NumberValue).number}`,
          );
          break;

        case "link":
          randomIDForNewCustomField = setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "text",
            `${(configuration.value as LinkValue).text}`,
          );
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "url",
            `${(configuration.value as LinkValue).url}`,
            randomIDForNewCustomField,
          );
          break;

        case "select":
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "value",
            `${(configuration.value as SelectValue).selection}`,
          );
          break;

        case "text":
          setHiddenFormInput(
            rootElementForForm,
            configuration.configurationId as number,
            "value",
            `${configuration.value}`,
          );
          break;
      }
    });
  });
}

// eslint-disable-next-line max-statements
function setHiddenFormInput(
  rootElementForForm: Element,
  configurationId: number,
  name: string,
  value: string,
  randomIDToReuse?: string,
): string | undefined {
  let randomIDForNewCustomField: string | undefined;

  let inputElement = rootElementForForm.querySelector(
    `[data-bridge="${configurationId}-${name}"]`,
  ) as HTMLInputElement;

  // When the hidden input doesn't already exist, the user has created a new custom field using the
  // pop-up dialog. Create new hidden inputs for it here. The created hidden inputs match more-or-less
  // what's normally created in Rails for the bridge.
  if (!inputElement) {
    // The prefix for new elements must match the existing names. We have (at least) two options for matching these
    // prefixes: (a) pass additional parameters into this method or (b) store the prefix in the DOM. Storing the
    // prefix in the DOM should be the simplest approach, so that's the one taken here.
    const dataTag = rootElementForForm.querySelector(
      ".prefix-for-new-hidden-input-names",
    ) as HTMLInputElement;
    const prefixForNewHiddenInputNames = dataTag?.value || "";

    if (randomIDToReuse !== undefined) {
      randomIDForNewCustomField = randomIDToReuse;
    } else {
      // Create a 39-digit random integer for the ID. In Rails, we use `SecureRandom.uuid.gsub("-", "").hex`,
      // but the approach below should be sufficient.
      randomIDForNewCustomField = "";
      while (randomIDForNewCustomField.length < 39) {
        // tslint:disable-next-line:insecure-random
        randomIDForNewCustomField += String(
          Math.round(Math.random() * Number.MAX_SAFE_INTEGER),
        );
      }
      randomIDForNewCustomField = randomIDForNewCustomField.substring(0, 39);
    }

    // Only generate value ID and configuration ID elements for the first name/value pair on the value.
    if (randomIDToReuse === undefined) {
      // Example from Rails:
      // <input type="hidden" value="1399" name="client[custom_field_values_attributes][114555979902496396979896998339462103251][id]"
      //  id="client_custom_field_values_attributes_114555979902496396979896998339462103251_id">
      const customFieldValueIDElement = document.createElement("input");
      customFieldValueIDElement.setAttribute("type", "hidden");
      // The "value" attribute is omitted because a new custom field doesn't yet have a value.
      customFieldValueIDElement.setAttribute(
        "name",
        `${prefixForNewHiddenInputNames}[custom_field_values_attributes][${randomIDForNewCustomField}][id]`,
      );
      // The "id" attribute is omitted because it's not needed.
      rootElementForForm.appendChild(customFieldValueIDElement);

      // <input type="hidden" value="300" name="client[custom_field_values_attributes][114555979902496396979896998339462103251][custom_field_id]"
      //  id="client_custom_field_values_attributes_114555979902496396979896998339462103251_custom_field_id">
      const customFieldConfigurationIDElement = document.createElement("input");
      customFieldConfigurationIDElement.setAttribute("type", "hidden");
      customFieldConfigurationIDElement.setAttribute(
        "value",
        String(configurationId),
      );
      customFieldConfigurationIDElement.setAttribute(
        "name",
        `${prefixForNewHiddenInputNames}[custom_field_values_attributes][${randomIDForNewCustomField}][custom_field_id]`,
      );
      rootElementForForm.appendChild(customFieldConfigurationIDElement);
    }

    // <input data-bridge="300-value" type="hidden" value="value" name="client[custom_field_values_attributes][114555979902496396979896998339462103251][value]"
    //  id="client_custom_field_values_attributes_114555979902496396979896998339462103251_value">
    const customFieldValueElement = document.createElement("input");
    customFieldValueElement.setAttribute(
      "data-bridge",
      `${configurationId}-${name}`,
    );
    customFieldValueElement.setAttribute("type", "hidden");
    // The "value" attribute is set below on the happy path.
    customFieldValueElement.setAttribute(
      "name",
      `${prefixForNewHiddenInputNames}[custom_field_values_attributes][${randomIDForNewCustomField}][${name}]`,
    );
    rootElementForForm.appendChild(customFieldValueElement);

    inputElement = customFieldValueElement;
  }

  inputElement.value = value;

  return randomIDForNewCustomField;
}

interface ConfigurationToSync {
  type: string;
  configurationId: number;
}

// When a user submits a form and the submission fails, the page will reload. The hidden inputs
// generated by Rails will hold the submitted values from the user. We need to sync them back
// to the components so the user can use the previous values.
//
// We could have gone about this process in two very different ways:
//
// 1. Walk the hidden inputs in the container div. Collect the relevant parameters for a single
//    hidden input (configuration ID / name of attribute), and then do a dispatch.
//
// 2. Walk the existing state, and then a targeted search to
//    (a) get the value for the hidden input and
//    (b) update the state with the value.
//
// Approach #2 seemed simplest, and it was adopted below.
export function syncHiddenInputsToReducerState(
  railsHiddenInputContainerId: string,
  state: State,
  dispatch: React.Dispatch<Action>,
) {
  // Build an array of all the custom field configurations (type/configuration IDs) that need to
  // be synced.
  const configurationsToSync: { type: string; configurationId: number }[] = [];
  state.allGroups.forEach(function (groupName) {
    const group = state.byGroup[groupName];
    group.allConfigurations.forEach(function (configurationName) {
      const configuration =
        state.byGroup[groupName].byConfiguration[configurationName];

      configurationsToSync.push({
        type: configuration.type,
        configurationId: configuration.configurationId as number,
      });
    });
  });

  // Find the root element for the hidden input elements so we can search relative to it.
  const rootElementForForm = document.querySelector(
    `#${railsHiddenInputContainerId}`,
  );
  if (rootElementForForm === null) {
    throw new Error(
      "Cannot perform necessary setup to support editing custom fields.",
    );
  }

  // Synchronize each of the custom field configurations that needs to be synced.
  // eslint-disable-next-line max-statements
  configurationsToSync.forEach(function (configuration: ConfigurationToSync) {
    let b: boolean;
    let length: number;
    let width: number;
    let n: number;
    let s: string;
    let text: string;
    let url: string;

    switch (configuration.type) {
      case "area":
        length = Number(
          getHiddenFormInput(
            rootElementForForm,
            configuration.configurationId,
            "dimension1",
          ),
        );
        width = Number(
          getHiddenFormInput(
            rootElementForForm,
            configuration.configurationId,
            "dimension2",
          ),
        );
        dispatch({
          type: "Update Area",
          configurationId: configuration.configurationId,
          length: length,
          width: width,
        });
        break;

      case "bool":
        // In custom_field_values, `str_value` is an absolute mess for "bool" custom fields. The most common values are
        // "--- '0'\n" and "--- '1'\n" in the str_value column, but a wide range of strings exist.
        b = ["true", "1"].includes(
          getHiddenFormInput(
            rootElementForForm,
            configuration.configurationId,
            "value",
          ),
        );
        dispatch({
          type: "Replace Value",
          configurationId: configuration.configurationId,
          value: b,
        });
        break;

      case "int":
        n = Number(
          getHiddenFormInput(
            rootElementForForm,
            configuration.configurationId,
            "value",
          ),
        );
        dispatch({
          type: "Update Number",
          configurationId: configuration.configurationId,
          value: n,
        });
        break;

      case "link":
        text = getHiddenFormInput(
          rootElementForForm,
          configuration.configurationId,
          "text",
        );
        url = getHiddenFormInput(
          rootElementForForm,
          configuration.configurationId,
          "url",
        );
        dispatch({
          type: "Update Link",
          configurationId: configuration.configurationId,
          text: text,
          url: url,
        });
        break;

      case "select":
        s = getHiddenFormInput(
          rootElementForForm,
          configuration.configurationId,
          "value",
        );
        dispatch({
          type: "Update Selection",
          configurationId: configuration.configurationId,
          value: s,
        });
        break;

      case "text":
        s = getHiddenFormInput(
          rootElementForForm,
          configuration.configurationId,
          "value",
        );
        dispatch({
          type: "Replace Value",
          configurationId: configuration.configurationId,
          value: s,
        });
        break;
    }
  });
}

// getHiddenFormInput searches the DOM, rooted at `rootElementForForm`, for the hidden
// input that exists for bridge Rails with React.
function getHiddenFormInput(
  rootElementForForm: Element,
  configurationId: number,
  name: string,
): string {
  const inputElement = rootElementForForm.querySelector(
    `[data-bridge="${configurationId}-${name}"]`,
  ) as HTMLInputElement;
  if (!inputElement) {
    return "";
  }

  return inputElement.value;
}

// handleMutationObserver is a helper that handles the retrieving of transferable
// custom fields when a mutation in the DOM triggers it.
// eslint-disable-next-line max-statements
export async function handleMutationObserver(
  dispatch: React.Dispatch<Action>,
  attachedToClassName: AttachedToClassNames,
) {
  const idsFromDOM = getIDsFromDOM();
  if (
    idsFromDOM.clientId === undefined ||
    idsFromDOM.propertyId === undefined
  ) {
    return;
  }

  const query =
    attachedToClassName === "WorkOrder"
      ? JOB_CUSTOM_FIELD_DEFAULTS_QUERY
      : QUOTE_CUSTOM_FIELD_DEFAULTS_QUERY;
  const variables =
    idsFromDOM.quoteId === undefined
      ? {
          clientId: btoa(String(idsFromDOM.clientId)),
          propertyId: btoa(String(idsFromDOM.propertyId)),
        }
      : {
          clientId: btoa(String(idsFromDOM.clientId)),
          propertyId: btoa(String(idsFromDOM.propertyId)),
          quoteId: btoa(String(idsFromDOM.quoteId)),
        };

  const result = await APIClient.query<
    QuoteDefaultCustomFieldValuesQuery | JobDefaultCustomFieldValuesQuery
  >({
    query: query,
    variables: variables,
  });

  const customFields = result?.data?.defaultCustomFieldValues?.nodes;
  customFields?.forEach(customField => {
    const updateDetails = getValueForGraphQLElement(customField);
    if (updateDetails === undefined) {
      return;
    }

    dispatch({
      type: "Replace Value",
      configurationId: updateDetails?.customFieldConfigurationId,
      value: updateDetails?.value,
    });
  });
}

/* eslint-disable-next-line @typescript-eslint/naming-convention */
interface IDsFromDOM {
  clientId?: number;
  propertyId?: number;
  quoteId?: number;
}

// getIDsFromDOM returns all of the client, property, quote, and job IDs
// that it can find in the DOM. After a mutation observer gets things started,
// this method finds the IDs that'll be used in the GraphQL query to get the
// transferable custom fields.
function getIDsFromDOM(): IDsFromDOM {
  const clientId = (document.querySelector(".js-clientId") as HTMLInputElement)
    ?.value;

  const propertyId = (
    document.querySelector(".js-propertyId") as HTMLInputElement
  )?.value;

  const quoteId = (document.querySelector(".js-quoteId") as HTMLInputElement)
    ?.value;

  return {
    clientId: clientId ? Number(clientId) : undefined,
    propertyId: propertyId ? Number(propertyId) : undefined,
    quoteId: quoteId ? Number(quoteId) : undefined,
  };
}

type GraphQLElementType =
  | QuoteDefaultCustomFieldValuesQuery["defaultCustomFieldValues"]["nodes"][0]
  | JobDefaultCustomFieldValuesQuery["defaultCustomFieldValues"]["nodes"][0];

// getValueForGraphQLElement is a helper for extracting both a numeric `customFieldConfigurationId` and a
// CustomFieldValue from a single custom field in a GraphQL query response.
function getValueForGraphQLElement(
  customField: GraphQLElementType,
): { customFieldConfigurationId: number; value: CustomFieldValue } | undefined {
  const customFieldConfigurationId = Number(
    atob(customField.customFieldConfiguration.id),
  );

  // The `quoteDefaultCustomFieldValues` and `jobDefaultCustomFieldValues` include the default
  // value in the custom field value -- there's no need to dig into customFieldConfiguration
  // for the default values.
  let customFieldValue;
  switch (customField.__typename) {
    case "CustomFieldArea":
      customFieldValue = {
        width: customField.valueArea.width,
        length: customField.valueArea.length,
        unit: customField.unit,
      };
      break;

    case "CustomFieldTrueFalse":
      customFieldValue = customField.valueTrueFalse;
      break;

    case "CustomFieldLink":
      customFieldValue = {
        text: customField.valueLink.text,
        url: customField.valueLink.url,
      };
      break;

    case "CustomFieldNumeric":
      customFieldValue = {
        number: customField.valueNumeric,
        unit: customField.unit,
      };
      break;

    case "CustomFieldDropdown":
      customFieldValue = {
        selection: customField.valueDropdown,
        options: customField.dropdownOptions,
      };
      break;

    case "CustomFieldText":
      customFieldValue = customField.valueText;
      break;
  }

  if (customFieldValue === undefined) {
    return;
  }

  return {
    customFieldConfigurationId: customFieldConfigurationId,
    value: customFieldValue,
  };
}
