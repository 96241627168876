import type {
  RecipientPickerProps,
  RecipientPickerState,
} from "jobber/recipientPicker/components/EmailRecipientPicker/RecipientPicker/RecipientPicker";

export function filterRecipients(
  incompleteRecipient: string,
  dropdownRecipients: string[],
  addedRecipients: string[],
): string[] {
  return dropdownRecipients
    .filter(
      (recipient: string) =>
        recipient.toLowerCase().indexOf(incompleteRecipient.toLowerCase()) !==
        -1,
    ) // matches textArea in case insensitive way
    .filter((recipient: string) => addedRecipients.indexOf(recipient) === -1); // Not already added
}

export const getPlaceholderValues = (
  dropdownRecipients: string[],
  placeholderSingular: string,
  placeholderPlural: string,
  recipients: string[],
) => {
  const placeholderText =
    dropdownRecipients.length > 0 ? placeholderPlural : placeholderSingular;

  const placeholderValue = recipients.length === 0 ? placeholderText : "";
  const ariaLabelValue = recipients.length === 0 ? "" : placeholderText;

  return { placeholderValue: placeholderValue, ariaLabelValue: ariaLabelValue };
};

export const constrainNumber = (
  index: number,
  upperBound: number,
  lowerBound: number,
): number => {
  if (index > upperBound) {
    return upperBound;
  } else if (index < lowerBound) {
    return lowerBound;
  }

  return index;
};

export const refreshDropdown = (
  prevState: RecipientPickerState,
  props: RecipientPickerProps,
) => {
  const filteredRecipients = filterRecipients(
    prevState.inputValue,
    props.dropdownRecipients,
    prevState.recipients,
  );

  return {
    dropdownPosition: 0,
    dropdownItems: filteredRecipients,
  };
};
