import type { ApolloClient } from "@apollo/client";
import type { CompanySchedulePageDataQuery } from "~/utilities/API/graphql";
import { COMPANY_SCHEDULE_QUERY } from "~/jobber/settings/companySchedule/CompanyScheduleSettingsData/CompanyScheduleSettingsData.graphql";

export function queryCompanySchedule(apolloClient: ApolloClient<unknown>) {
  return () =>
    apolloClient.query<CompanySchedulePageDataQuery>({
      query: COMPANY_SCHEDULE_QUERY,
    });
}
