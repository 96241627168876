import React from "react";
import { Chip, Chips } from "@jobber/components/Chips";
import { SignaturePadType } from "components/SignaturePad";
import styles from "./SignatureSelector.module.css";

export interface SignatureSelectorProps {
  selectedSignaturePadType: SignaturePadType;

  onChange?(signaturePadType: SignaturePadType): void;
}

export const SignatureSelector = ({
  selectedSignaturePadType,
  onChange,
}: SignatureSelectorProps) => {
  return (
    <div className={styles.container}>
      <Chips
        selected={selectedSignaturePadType}
        onChange={newValue => {
          if (!newValue) return;
          onChange?.(newValue as SignaturePadType);
        }}
      >
        <Chip label="Draw" value={SignaturePadType.DRAW} />
        <Chip label="Type" value={SignaturePadType.TYPE} />
      </Chips>
    </div>
  );
};
