import { gql } from "@apollo/client";
import type {
  Account,
  InstantBookingAvailabilityWindow,
  InstantBookingSelectedService,
} from "~/utilities/API/graphql";
import {
  instantBookingAvailabilityWindowFieldFragments,
  instantBookingSelectedServiceFieldFragments,
  merchantConfigurationFieldFragments,
} from "./MerchantConfigurationFieldFragments";

export const MERCHANT_CONFIGURATION_QUERY = gql`
  query MerchantConfigurationQuery {
    account {
      ...accountFields
    }
    instantBookingSelectedServices {
      nodes {
        ...instantBookingSelectedServiceFields
      }
    }
    instantBookingAvailabilityWindows {
      nodes {
        ...instantBookingAvailabilityWindowFields
      }
    }
  }

  ${merchantConfigurationFieldFragments}
  ${instantBookingSelectedServiceFieldFragments}
  ${instantBookingAvailabilityWindowFieldFragments}
`;

export interface MerchantConfiguration {
  account: Account;
  instantBookingSelectedServices: { nodes: InstantBookingSelectedService[] };
  instantBookingAvailabilityWindows: {
    nodes: InstantBookingAvailabilityWindow[];
  };
}

export const MERCHANT_CONFIGURATION_MUTATION = gql`
  mutation MerchantConfigurationMutation(
    $id: EncodedId
    $attrs: AccountUpdateAttributes!
  ) {
    upsertAccount(id: $id, attributes: $attrs) {
      account {
        ...accountFields
      }
    }
  }

  ${merchantConfigurationFieldFragments}
`;

export const SERVICE_CONFIGURATION_MUTATION = gql`
  mutation ServiceConfigurationMutation(
    $services: [InstantBookingSelectedServiceAttributes!]!
  ) {
    updateInstantBookingServices(updatedServices: $services) {
      instantBookingSelectedServices {
        ...instantBookingSelectedServiceFields
      }
      userErrors {
        path
        message
      }
    }
  }
  ${instantBookingSelectedServiceFieldFragments}
`;

export const AVAILABILITY_WINDOW_CONFIGURATION_MUTATION = gql`
  mutation AvailabilityWindowConfigurationMutation(
    $availabilityWindows: [InstantBookingAvailabilityWindowAttributes!]!
  ) {
    updateInstantBookingAvailabilities(
      availabilityWindows: $availabilityWindows
    ) {
      availabilityWindows {
        ...instantBookingAvailabilityWindowFields
      }
    }
  }
  ${instantBookingAvailabilityWindowFieldFragments}
`;
