import React from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import styles from "./JobCostProfitMarginDemoContent.module.css";
import { messages } from "./messages";

export function JobCostProfitMarginDemoContent() {
  const { formatMessage } = useIntl();
  return (
    <div
      data-testid="jobCostProfitMarginDemoContent"
      className={styles.jobCostDiscoveryDemo}
    >
      <div className={styles.jobCostDiscoveryDemoContentLeft}>
        <div className={styles.jobCostDiscoveryDemoBlur}>
          <Heading level={2}>
            {formatMessage(messages.sampleProfitMarginValue)}
          </Heading>
        </div>
        <div className={styles.jobCostDiscoveryDemoTitle}>
          <Heading level={5}>
            {formatMessage(messages.profitMarginTitle)}
          </Heading>
        </div>
      </div>
      <div className={styles.jobCostDiscoveryDemoContentRight}>
        <div
          className={`${styles.jobCostDiscoveryDemoContentMobile} ${styles.jobCostDiscoveryDemoContentMobileSpacer}`}
        >
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>
              {formatMessage(messages.profitMarginTitle)}
            </Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleProfitMarginValue)}
            </Heading>
          </div>
        </div>
        <div
          className={`${styles.jobCostDiscoveryDemoContent} ${styles.jobCostDiscoveryDemoContentMobileSpacer}`}
        >
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>
              {formatMessage(messages.totalPriceTitle)}
            </Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleTotalPriceValue)}
            </Heading>
          </div>
        </div>
        <div className={styles.jobCostDiscoveryDemoContent}>
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>
              {formatMessage(jobCostMessages.lineItemCostLowercase)}
            </Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleLineItemCostValue)}
            </Heading>
          </div>
        </div>
        <div className={styles.jobCostDiscoveryDemoContent}>
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>{formatMessage(jobCostMessages.labour)}</Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleLaborValue)}
            </Heading>
          </div>
        </div>
        <div className={styles.jobCostDiscoveryDemoContent}>
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>
              {formatMessage(jobCostMessages.expenses)}
            </Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleExpensesValue)}
            </Heading>
          </div>
        </div>
        <div className={styles.jobCostDiscoveryDemoContentLast}>
          <div className={styles.jobCostDiscoveryDemoTitle}>
            <Heading level={5}>{formatMessage(jobCostMessages.profit)}</Heading>
          </div>
          <div className={styles.jobCostDiscoveryDemoBlur}>
            <Heading level={4}>
              {formatMessage(messages.sampleProfitValue)}
            </Heading>
          </div>
        </div>
      </div>
    </div>
  );
}
