import React from "react";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { UkKycComplianceRegistrationMain } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";

function UkKycComplianceRegistrationWrapper() {
  const { countryCode } = useAccount();
  const isUkKycRegistrationEnabled = useFeatureFlag(
    SplitNames.CommsUkKycDpnRegistration,
  );

  const ukKycEligible = isUkKycRegistrationEnabled && countryCode === "GB";

  return <>{ukKycEligible && <UkKycComplianceRegistrationMain />}</>;
}

const SplitWrappedComponent = withSplitClient(
  UkKycComplianceRegistrationWrapper,
);
export { SplitWrappedComponent as UkKycComplianceRegistrationWrapper };
