import { defineMessages } from "react-intl";

export const messages = defineMessages({
  billedTodayHeader: {
    id: "billing.purchaseTotal.billedTodayHeader",
    defaultMessage: "Billed today",
    description: "Purchase header for a billed today subscription",
  },
  futureBilledHeader: {
    id: "billing.purchaseTotal.futureBilledHeader",
    defaultMessage: "Billed {startBillingDate}",
    description: "Purchase header for a future billed subscription",
  },
});
