import { gql } from "@apollo/client";

export const JOB_FOLLOW_UPS = gql`
  mutation updateJobFollowUp($enabled: Boolean) {
    updateNotification(id: JOB_FOLLOWUP, attributes: { enabled: $enabled }) {
      clientNotification {
        id
        enabled
      }
      errors
    }
  }
`;
