import React from "react";

interface DataDebuggerProps {
  label?: string;

  /**
   * Data to display.
   */
  readonly data: unknown;
}

export function DataDump({ label = "Raw Data", data }: DataDebuggerProps) {
  return (
    <details>
      <summary>{label}</summary>
      <pre>{JSON.stringify(data, undefined, 2)}</pre>
    </details>
  );
}
