import { useEffect, useState } from "react";

interface DeviceLocation {
  latitude: number;
  longitude: number;
}

interface Parameters {
  skip?: boolean;
  navigator?: Navigator;
}

export const useDeviceLocation = (params = {} as Parameters) => {
  const skip = params.skip || false;
  const geolocator =
    params.navigator?.geolocation || window.navigator?.geolocation;
  const [deviceLocation, setDeviceLocation] = useState<
    DeviceLocation | undefined
  >(undefined);

  useEffect(() => {
    if (skip || !geolocator) {
      return;
    }

    geolocator.getCurrentPosition(position => {
      if (position) {
        setDeviceLocation(position.coords);
      }
    });
  }, [skip, geolocator]);

  return {
    deviceLocation,
  };
};
