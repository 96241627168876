import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  JobCreateLineItemsInput,
  JobCreateLineItemsMutation,
  JobCreateLineItemsMutationVariables,
} from "~/utilities/API/graphql";
import {
  JOB_COST_LINE_ITEM_TOTALS_QUERY,
  JOB_CREATE_LINE_ITEMS,
  JobCostLineItem,
} from "./graphql";
import { messages } from "./messages";

interface UseJobCreateLineItemsMutation {
  jobId: string;
  canViewJobCosts: boolean;
  canViewPricing: boolean;
  onSuccess: () => void;
}

export function useJobCreateLineItemsMutation({
  jobId,
  canViewJobCosts,
  canViewPricing,
  onSuccess,
}: UseJobCreateLineItemsMutation): {
  handleJobCreateLineItems: (
    input: JobCreateLineItemsInput,
  ) => Promise<string[] | undefined>;
  loading: boolean;
} {
  const [createLineItems, { loading }] = useMutation<
    JobCreateLineItemsMutation,
    JobCreateLineItemsMutationVariables
  >(JOB_CREATE_LINE_ITEMS, {
    refetchQueries: [
      {
        query: JOB_COST_LINE_ITEM_TOTALS_QUERY,
        variables: {
          jobId: jobId,
          canViewJobCosts: canViewJobCosts,
          canViewPricing: canViewPricing,
        },
      },
    ],
    update(cache, result) {
      if (result.data?.jobCreateLineItems?.createdLineItems) {
        result.data.jobCreateLineItems.createdLineItems.forEach(
          createdLineItem => {
            // Insert the returned line item as the expected type (JobViewLineItem)
            cache.modify({
              id: `JobViewLineItems:${jobId}`,
              fields: {
                items(existingLineItemRefs = []) {
                  const newLineItemRef = cache.writeFragment({
                    data: {
                      ...createdLineItem,
                      __typename: "JobViewLineItem",
                      overrideDates: null,
                    },
                    fragment: JobCostLineItem,
                    fragmentName: "jobCostLineItem",
                    variables: {
                      canViewJobCosts,
                      canViewPricing,
                    },
                  });
                  return {
                    ...existingLineItemRefs,
                    nodes: [...existingLineItemRefs.nodes, newLineItemRef],
                    totalCount: existingLineItemRefs.totalCount + 1,
                  };
                },
              },
            });
          },
        );
      }
    },
  });

  async function handleJobCreateLineItems(input: JobCreateLineItemsInput) {
    try {
      const result = await createLineItems({
        variables: {
          jobId,
          input,
          canViewPricing: canViewPricing,
        },
      });

      const errors = result.data?.jobCreateLineItems.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.lineItemSaved,
          variation: "success",
        });
        onSuccess();

        return result.data?.jobCreateLineItems.createdLineItems.map(
          lineItem => lineItem.id,
        );
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleJobCreateLineItems,
    loading,
  };
}
