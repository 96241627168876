import React, { useContext } from "react";
import type { AccountConfigurationType } from "./types";

export const AccountConfigurationContext =
  React.createContext<AccountConfigurationType>({
    settings: { currencySymbol: "" },
    features: {
      advancedQuotingEnabled: false,
      quoteMarginsEnabled: false,
      selfServeBookingsEnabled: false,
    },
  });

interface AccountConfigurationContextProviderProps {
  children: React.ReactNode;
  readonly features: {
    readonly selfServeBookingsEnabled: boolean;
    readonly advancedQuotingEnabled: boolean;
    readonly quoteMarginsEnabled: boolean;
  };
  readonly currencySymbol: string;
}

export function AccountConfigurationContextProvider({
  children,
  features,
  currencySymbol,
}: AccountConfigurationContextProviderProps) {
  const configuration = {
    features,
    settings: { currencySymbol },
  };
  return (
    <AccountConfigurationContext.Provider value={configuration}>
      {children}
    </AccountConfigurationContext.Provider>
  );
}

export function useAccountConfigurationContext() {
  return useContext(AccountConfigurationContext);
}
