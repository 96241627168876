import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Markdown } from "@jobber/components/Markdown";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Text } from "@jobber/components/Text";
import { useMutation } from "@apollo/client";
import { Intercom } from "utilities/chat";
import type { UpdateJobFollowUpMutation } from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { JOB_FOLLOW_UPS } from "./PersonalizeStep.graphql";
import { messages as messagesShared } from "../../messages";
import type { SetupGuideStepProps } from "../../types";
import { MessagingImage } from "../../images";

export function PersonalizeStep({
  navigation,
  setErrMsg,
}: SetupGuideStepProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [editJFUMutation] =
    useMutation<UpdateJobFollowUpMutation>(JOB_FOLLOW_UPS);

  useEffect(() => {
    Intercom.EXECUTE("trackEvent", "connected_google_business_account");
  }, []);

  function editJobFollowUps() {
    editJFUMutation({
      variables: {
        enabled: false,
      },
    })
      .then(result => {
        const userErrors = result.data?.updateNotification.errors ?? [];
        if (userErrors.length > 0) {
          setErrMsg(messages.jobfollowupsError);
        }
      })
      .catch(() => {
        setErrMsg(messages.jobfollowupsError);
      });
    navigation.onNext();
  }

  return (
    <div className={styles.setup}>
      <div className={styles.img}>
        <img src={MessagingImage.src} alt={formatMessage(MessagingImage.alt)} />
      </div>
      <div className={styles.stepDirections}>
        <h1 className={styles.title}>
          {formatMessage(messages.personalizeTitle)}
        </h1>
        {DescriptionText()}
        <ButtonGroup
          onNext={() => {
            editJobFollowUps();
          }}
        />
      </div>
    </div>
  );
}

function DescriptionText(): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <>
      <Markdown
        content={formatMessage(messages.personalizeDescription)}
      ></Markdown>
      <Text variation="subdued" size="small">
        {formatMessage(messages.personalizeDescriptionSubtle)}
      </Text>
    </>
  );
}

interface ButtonGroupProps {
  onNext: () => void;
}

function ButtonGroup({ onNext }: ButtonGroupProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { largeAndUp } = useBreakpoints();
  const fullWidthValue = largeAndUp ? false : true;
  return (
    <div className={styles.buttons}>
      <Button
        fullWidth={fullWidthValue}
        type={"primary"}
        ariaLabel={formatMessage(messagesShared.continue)}
        onClick={onNext}
        label={formatMessage(messagesShared.continue)}
      />
    </div>
  );
}
