import { BuildingDashboard } from "./components/BuildingDashboard";
import { Routes, type SetupGuideStep } from "./types";
import { ConnectStep } from "./components/ConnectStep/ConnectStep";
import { PersonalizeStep } from "./components/PersonalizeStep/PersonalizeStep";
import { CompleteSetup } from "./components/CompleteSetup";
import { ConnectedStep } from "./components/ConnectedStep/ConnectedStep";
import { ProfileStep } from "./components/ProfileStep";

export const defaultSteps: SetupGuideStep[] = [
  {
    route: Routes.connect,
    showBackButton: false,
    includeInProgress: false,
    component: ConnectStep,
  },
  {
    route: Routes.connected,
    showBackButton: false,
    includeInProgress: false,
    component: ConnectedStep,
  },
  {
    route: Routes.profile,
    showBackButton: true,
    includeInProgress: true,
    component: ProfileStep,
  },
  {
    route: Routes.personalize,
    showBackButton: true,
    includeInProgress: true,
    component: PersonalizeStep,
  },
  {
    route: Routes.completeSetup,
    showBackButton: true,
    includeInProgress: true,
    component: CompleteSetup,
  },
  {
    route: Routes.buildingDashboard,
    showBackButton: true,
    includeInProgress: true,
    component: BuildingDashboard,
  },
];
