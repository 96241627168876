import CoachingImageSrc from "@images/CoachingImage.png";

interface ImageType {
  src: string;
  alt: string;
}

const CoachingImage: ImageType = {
  src: CoachingImageSrc as string,
  alt: "Coaching Image",
};

export { CoachingImage, type ImageType };
