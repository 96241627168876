import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.profile.title",
    defaultMessage: "Select a profile",
    description: "Title text for the profile selection page",
  },
  generalError: {
    id: "reviews.profile.generalErrorBanner",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "A general warning that something has gone wrong while loading Google reviews",
  },
  unverifiedError: {
    id: "reviews.profile.unverifiedError",
    defaultMessage:
      "Unable to connect. Try selecting a verified Google Business Profile, if issue persists please contact our support team.",
    description:
      "A general warning that something has gone wrong while loading a verified Google business",
  },
  loadingProfileError: {
    id: "reviews.profile.loadingProfileError",
    defaultMessage: "There are no profiles associated with this account.",
    description:
      "A general warning that something has gone wrong while loading a google business profiles",
  },
  chooseAnotherAccount: {
    id: "reviews.profile.chooseAnotherAccount",
    defaultMessage: "Choose another account",
    description: "Button text to choose another account",
  },
  createProfileLink: {
    id: "reviews.profile.createProfileLink",
    defaultMessage: "[or create a profile](https://business.google.com/create)",
    description: "Text for link to create a profile",
  },
});
