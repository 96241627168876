/* eslint-disable import/no-internal-modules */
import React from "react";
import type { GeoPoint } from "~/utilities/API/graphql";
import { GoogleMaps } from "jobber/settings/selfServeBookings/views/SettingsPage/components/ServiceAreaConfiguration/components/ServiceAreaEditModal/GoogleMaps/GoogleMaps";

interface GoogleServiceAreaMapProps {
  center: Pick<GeoPoint, "latitude" | "longitude"> | undefined;
  kmRadius: number;
  onRadiusChanged?: (radius: number) => void;
  onAddressChanged: (
    address: string,
    location?: Pick<GeoPoint, "latitude" | "longitude"> | undefined,
  ) => void;
}

/* istanbul ignore next */
export function GoogleServiceAreaMap({
  center,
  kmRadius,
  onAddressChanged,
}: GoogleServiceAreaMapProps) {
  const mRadius = Math.round(kmRadius * 1000);

  // default to the US centroid point
  return (
    <div
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GoogleMaps
        center={{
          lat: center?.latitude || 37.09024,
          lng: center?.longitude || -95.712891,
        }}
        mRadius={mRadius}
        onAddressChanged={onAddressChanged}
      />
    </div>
  );
}
