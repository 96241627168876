import { cloneElement, createElement, isValidElement } from "react";
import type {
  PropsWithChildren,
  ReactElement,
  ReactHTML,
  ReactNode,
} from "react";

/**
 * Builds our standard privacy attributes for observability tooling.
 *
 * If you have HTML elements, not react components to mask, use `PrivacyMask` instead.
 *
 * If you have multiple elements in the same tree, use `PrivacyMask` instead.
 *
 * @param allow If true, will set data-dd-privacy=allow, otherwise data-dd-privacy=mask
 * @returns Object with the privacy attributes that can be spread onto a component
 */
export function buildPrivacyAttributes(allow = false) {
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "data-dd-privacy": allow ? "allow" : "mask",
  };
}

const TEST_ID = "PrivacyMask-Wrapper-Only";

/**
 * Privacy component to mask or allow children based on the `disabled` prop.
 *
 * This component is nestable! You can wholesale disable masking for an entire tree and selectively mask individual elements.
 * Will by default clone the child element if possible, otherwise it will wrap the children in a div with the appropriate privacy attributes.
 *
 * @param disabled (false) - If true, the children will be unmasked in our observability tooling, otherwise children will be masked.
 * @param asWrapper - If true, the children will be wrapped in a div with the privacy attributes.
 * @param wrapperElement - The element to use as the wrapper
 */
export function PrivacyMask({
  children,
  disabled = false,
  asWrapper = false,
  wrapperElement = "div",
}: PropsWithChildren<{
  disabled?: boolean;
  asWrapper?: boolean;
  wrapperElement?: keyof ReactHTML;
}>): ReactElement {
  const privacyProps = buildPrivacyAttributes(disabled);

  if (!isClonable(children) || asWrapper) {
    return createElement(
      wrapperElement,
      {
        ...privacyProps,
        style: { display: "contents" },
        ...buildTestIdAttribute(TEST_ID),
      },
      children,
    );
  }

  const child = children as ReactElement;
  return cloneElement(child, { ...child.props, ...privacyProps });
}

function isClonable(node: ReactNode): boolean {
  return isValidElement(node) && typeof node?.type === "string";
}

export const forTestingOnly = {
  TEST_ID,
};

const buildTestIdAttribute = (id: string) => {
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "data-testid": process.env.NODE_ENV === "test" ? id : undefined,
  };
};
