import { useQuery, useSubscription } from "@apollo/client";
import React from "react";
import type {
  AppAlertsQuery,
  AppAlertsSubscriptionSubscription,
} from "~/utilities/API/graphql";
import { AppAlertsButton } from "./AppAlertsButton";
import { APP_ALERTS_QUERY, APP_ALERTS_SUBSCRIPTION } from "./AppAlerts.graphql";

export function AppAlertsButtonLoader() {
  const { data } = useQuery<AppAlertsQuery>(APP_ALERTS_QUERY);
  const { data: subscriptionData } =
    useSubscription<AppAlertsSubscriptionSubscription>(APP_ALERTS_SUBSCRIPTION);

  if (data === undefined) {
    return <></>;
  }

  const appAlertsData =
    subscriptionData?.appAlerts?.appAlerts?.nodes || data.appAlerts.nodes;

  return <AppAlertsButton appAlerts={appAlertsData} />;
}
