import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/settings/selfServeBookings/analytics/AnalyticsEvents";
import { EDIT_SELF_SERVE_SETTINGS_MUTATION } from "./graphql";

interface SelfServeSettings {
  acceptingOnlineBookings?: boolean;
  confirmationMessage?: string;
  googleMeasurementId?: string;
  serviceAreasEnabled?: boolean;
  spTermsAndConditionsUrl?: string;
}

export function useUpdateConfiguration(tracker = Amplitude) {
  const [callMutation, { loading, error }] = useMutation(
    EDIT_SELF_SERVE_SETTINGS_MUTATION,
  );

  const track = useCallback(
    (enabled: boolean) => {
      tracker.TRACK_EVENT(
        ANALYTICS_EVENTS.selfServeBookingsSettings.configuredServiceAreas,
        {
          app: "Online",
          enabled,
        },
      );
    },
    [tracker],
  );

  async function editSelfServeSettings(newConfig: SelfServeSettings) {
    if (typeof newConfig.serviceAreasEnabled === "boolean") {
      track(newConfig.serviceAreasEnabled);
    }

    return callMutation({
      variables: {
        input: newConfig,
      },
    });
  }

  return {
    editSelfServeSettings,
    loading,
    error,
  };
}
