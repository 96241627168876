import { CardElement } from "@stripe/react-stripe-js";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import NameOnCardInput from "legacy/jobber/payments_sca/components/NameOnCardInput";
import FieldError from "legacy/jobber/payments_sca/components/FieldError";
import { getStripeFieldColorsFromTheme } from "~/utilities/getStripeFieldColorsFromTheme";
import styles from "./CardDetailsForm.module.css";

export interface CardDetailsFormProps {
  nameOnCard: string;
  isLoading: boolean;
  cardErrorMessage: string | undefined;
  onCardElementChange(event: StripeCardElementChangeEvent): void;
  onNameOnCardChange(name: string): void;
}
export function CardDetailsForm(props: CardDetailsFormProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalCardDetailsForm {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalCardDetailsForm(props: CardDetailsFormProps) {
  const {
    nameOnCard,
    isLoading,
    cardErrorMessage,
    onCardElementChange,
    onNameOnCardChange,
  } = props;
  const { tokens } = useAtlantisTheme();
  const { fieldValueColor, fieldPlaceholderColor, criticalColor } =
    getStripeFieldColorsFromTheme(tokens);

  const stripeElementsStyle = {
    style: {
      base: {
        color: fieldValueColor,
        ["::placeholder"]: {
          color: fieldPlaceholderColor,
        },
      },
      invalid: {
        color: criticalColor,
      },
    },
    classes: {
      focus: "is-focus",
      empty: "is-empty",
      invalid: "is-invalid",
    },
    disabled: isLoading,
    hidePostalCode: true,
    iconStyle: "solid" as const,
  };

  return (
    <>
      <NameOnCardInput
        name={nameOnCard}
        onNameChange={onNameOnCardChange}
        disabled={isLoading}
        data-testid="name-on-card-input"
      />
      <FieldError
        errorMessage={cardErrorMessage}
        data-testid="card-error-message"
      />
      <CardElement
        className={`placeholderField-input ${styles.stripeInput}`}
        options={stripeElementsStyle}
        onChange={onCardElementChange}
        data-testid="card-element-input"
      />
    </>
  );
}
