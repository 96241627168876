import React from "react";
import type { IntlFormatters } from "react-intl";
import { FeatureLandingPage } from "jobber/landingPages/FeatureLandingPage";
import ManageRequests from "@images/ManageRequests.png";
import CreateRequestsManually from "@images/CreateRequestsManually.png";
import JobsChecklistWithBackground from "@images/JobsChecklistWithBackground.png";
import Scheduling from "@images/Scheduling.png";
import { CoreTrialWrapper } from "jobber/landingPages/components/CoreTrialWrapper/CoreTrialWrapper";
import { UpgradeFeature } from "~/utilities/API/graphql";
import { messages } from "./messages";

function renderRequestsLandingPage(
  trialAvailable: boolean,
  showStartTrialModal: () => void,
  formatMessage: IntlFormatters["formatMessage"],
) {
  const learnAboutFeaturesSection = {
    mainHeading: formatMessage(messages.featuresSectionHeading, {
      plan: "Core",
    }),
    firstImage: JobsChecklistWithBackground,
    secondImage: Scheduling,
    firstImageAlt: formatMessage(messages.jobsFeatureImageAlt),
    secondImageAlt: formatMessage(messages.schedulingFeatureImageAlt),
    featuresAndDescriptions: [
      {
        heading: formatMessage(messages.jobsFeatureHeading),
        description: formatMessage(messages.jobsFeatureDescription),
        link: "/work_orders",
      },
      {
        heading: formatMessage(messages.schedulingFeatureHeading),
        description: formatMessage(messages.schedulingFeatureDescription),
        link: "/calendar",
      },
    ],
  };
  return (
    <FeatureLandingPage
      feature={UpgradeFeature.REQUESTS}
      featurePlan="Core"
      mainHeading={formatMessage(messages.mainHeading)}
      subHeading={formatMessage(messages.subHeading)}
      firstImage={ManageRequests}
      secondImage={CreateRequestsManually}
      firstImageAlt={formatMessage(messages.firstImageAlt)}
      secondImageAlt={formatMessage(messages.secondImageAlt)}
      featuresAndDescriptions={[
        {
          heading: formatMessage(
            messages.impressYourCustomersSubFeatureHeading,
          ),
          description: formatMessage(
            messages.impressYourCustomersSubFeatureDescription,
          ),
        },
        {
          heading: formatMessage(messages.getJobsQuotedFasterSubFeatureHeading),
          description: formatMessage(
            messages.getJobsQuotedFasterSubFeatureDescription,
          ),
        },
      ]}
      learnAboutFeaturesSection={learnAboutFeaturesSection}
      trialAvailable={trialAvailable}
      onStartTrial={showStartTrialModal}
    />
  );
}

function RequestsLandingPageWrapper() {
  return (
    <CoreTrialWrapper
      renderCoreFeatureLandingPage={renderRequestsLandingPage}
      source="requests_landing_page"
    />
  );
}

export { RequestsLandingPageWrapper as RequestsLandingPage };
