import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import { Select } from "@jobber/components/Select";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";
import { ControlledColorPicker } from "components/ColorPicker";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { CampaignTemplateCtaLinkTo } from "~/utilities/API/graphql";
import { useAccountFeatureStatusForCtaLinkTo } from "jobber/campaigns/views/CampaignsContentPage/hooks/useAccountFeatureStatusForCtaLinkTo/useAccountFeatureStatusForCtaLinkTo";
import {
  AvailableEditors,
  type CampaignContentRefs,
} from "jobber/campaigns/views/CampaignsContentPage/types";
import styles from "./CtaButtonInput.module.css";
import { triggerValidation, validUrlRegex } from "../../hooks";

export const CAMPAIGN_TEMPLATE_CTA_TEST_ID =
  "campaign-template-cta-button-input";

interface CtaButtonInputProps {
  refs: CampaignContentRefs;
  loading?: boolean;
}

export function CtaButtonInput(props: CtaButtonInputProps) {
  return (
    <IntlProvider>
      <CtaButtonInputInternal {...props} />
    </IntlProvider>
  );
}

function CtaButtonInputInternal({
  refs,
  loading,
}: CtaButtonInputProps): JSX.Element {
  const {
    campaignContent: {
      ctaButtonEnabled,
      setCtaButtonEnabled,
      linkTo,
      setLinkTo,
      linkValue,
      setLinkValue,
      buttonText,
      setButtonText,
      buttonColor,
      setButtonColor,
    },
  } = useCampaignWizardContext();

  const { formatMessage } = useIntl();

  const { onlineBookingEnabled, requestsEnabled } =
    useAccountFeatureStatusForCtaLinkTo();

  function toggleCtaEnabled() {
    setCtaButtonEnabled(!ctaButtonEnabled, true);
  }

  useEffect(() => {
    if (!ctaButtonEnabled) {
      triggerValidation(refs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctaButtonEnabled]);

  function getButtonText(value: CampaignTemplateCtaLinkTo) {
    switch (value) {
      case CampaignTemplateCtaLinkTo.QUOTES_IN_CLIENT_HUB:
        return formatMessage(messages.ctaQuotesInClientHubButtonText);
      case CampaignTemplateCtaLinkTo.ONLINE_BOOKING:
        return formatMessage(messages.ctaOnlineBookingButtonText);
      case CampaignTemplateCtaLinkTo.REQUEST_FORM:
        return formatMessage(messages.ctaRequestFormButtonText);
      case CampaignTemplateCtaLinkTo.EXTERNAL_LINK:
        return formatMessage(messages.ctaExternalLinkButtonText);
      default:
        return "";
    }
  }

  function onChangeLinkValue(value: string | number | boolean | Date) {
    const link = value.toString().trim();
    setLinkValue(link, true);
  }

  function onChangeLinkTo(value: CampaignTemplateCtaLinkTo) {
    setLinkTo(value, true);
    setButtonText(getButtonText(value), true);
  }

  function onChangeButtonText(value: string | number | boolean | Date) {
    setButtonText(value.toString(), true);
  }

  function onChangeColor(value: string) {
    setButtonColor(value, true);
  }

  return (
    <div
      className={styles.container}
      data-testid={CAMPAIGN_TEMPLATE_CTA_TEST_ID}
    >
      {!ctaButtonEnabled && (
        <Button
          icon="add"
          label="Add Button"
          ariaLabel="Add call to action button"
          type="secondary"
          onClick={toggleCtaEnabled}
          loading={loading}
        />
      )}
      {ctaButtonEnabled && (
        <Content spacing={"small"}>
          <div className={styles.headerRowContainer}>
            <Heading level={5}>{formatMessage(messages.ctaButton)}</Heading>
            <div className={styles.deleteIconContainer}>
              <Button
                icon="trash"
                loading={loading}
                ariaLabel="delete"
                onClick={toggleCtaEnabled}
                variation={"destructive"}
                type={"tertiary"}
              />
            </div>
          </div>
          <Select
            onChange={onChangeLinkTo}
            defaultValue={CampaignTemplateCtaLinkTo.EXTERNAL_LINK}
            value={linkTo}
            placeholder={formatMessage(messages.ctaLinkToPlaceholder)}
          >
            <option value={CampaignTemplateCtaLinkTo.EXTERNAL_LINK}>
              {formatMessage(messages.ctaButtonExternalLink)}
            </option>
            {onlineBookingEnabled && (
              <option value={CampaignTemplateCtaLinkTo.ONLINE_BOOKING}>
                {formatMessage(messages.ctaButtonOnlineBooking)}
              </option>
            )}
            {requestsEnabled && (
              <option value={CampaignTemplateCtaLinkTo.REQUEST_FORM}>
                {formatMessage(messages.ctaRequestForm)}
              </option>
            )}
            <option value={CampaignTemplateCtaLinkTo.QUOTES_IN_CLIENT_HUB}>
              {formatMessage(messages.ctaQuotesInClientHub)}
            </option>
          </Select>
          {linkTo === CampaignTemplateCtaLinkTo.EXTERNAL_LINK && (
            <InputText
              placeholder={formatMessage(messages.ctaButtonLinkPlaceholder)}
              prefix={{ icon: "link" }}
              validations={{
                pattern: {
                  value: validUrlRegex,
                  message: formatMessage(messages.urlValidationError),
                },
                required: {
                  value: true,
                  message: formatMessage(messages.urlRequiredError),
                },
              }}
              value={linkValue}
              ref={refs[AvailableEditors.linkValue]}
              onChange={onChangeLinkValue}
            />
          )}
          <div className={styles.rowContainer}>
            <div className={styles.rowElement}>
              <InputText
                placeholder={formatMessage(messages.ctaButtonTextPlaceholder)}
                value={buttonText}
                onChange={onChangeButtonText}
                validations={{
                  required: {
                    value: true,
                    message: formatMessage(messages.ctaTextRequiredError),
                  },
                  maxLength: {
                    value: 30,
                    message: formatMessage(messages.ctaTextMaxLengthError),
                  },
                }}
                ref={refs[AvailableEditors.buttonText]}
              />
            </div>
            <div className={styles.rowElement}>
              <ControlledColorPicker
                value={buttonColor}
                onChange={onChangeColor}
                placeholder={formatMessage(messages.ctaButtonColorPlaceholder)}
                dataList={[
                  "#7db00e",
                  "#bbc520",
                  "#ddc30f",
                  "#f39624",
                  "#b36096",
                  "#8870c4",
                  "#4b6a96",
                  "#3ca2e0",
                  "#3ead92",
                ]}
              />
            </div>
          </div>
        </Content>
      )}
    </div>
  );
}
