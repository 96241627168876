import bind from "bind-decorator";
import React, {
  type CSSProperties,
  Component,
  type ComponentType,
  type ReactNode,
} from "react";
import { InputBar as DefaultInputBar, type InputBarProps } from "./InputBar";
import {
  InputList as DefaultInputList,
  type InputListProps,
} from "./InputList";
import {
  constrainNumber,
  filterRecipients,
  getPlaceholderValues,
  refreshDropdown,
} from "./pickerHelper";
import {
  RecipientList as DefaultRecipientList,
  type RecipientListProps,
} from "./RecipientList";

export interface RecipientPickerProps {
  dropdownRecipients: string[];
  preloadedRecipients: string[];
  inputName: string;
  label?: string;
  placeholderSingular: string;
  placeholderPlural: string;
  initDropdownPosition: number; // for mocking in unit tests
  initCursorPosition?: number;
  InputBar: ComponentType<InputBarProps>;
  RecipientList: ComponentType<RecipientListProps>;
  InputList: ComponentType<InputListProps>;
  autoFocus?: boolean;
  restrictInput?: boolean;
  showLabel?: boolean;
  onChange?(value: string[]): void;
}

export interface RecipientPickerState {
  recipients: string[];
  inputValue: string;
  isFocused: boolean;
  showDropdown: boolean;
  dropdownItems: string[];
  dropdownPosition: number;
  cursorPosition: number;
}

export class RecipientPicker extends Component<
  RecipientPickerProps,
  RecipientPickerState
> {
  public static defaultProps: Partial<RecipientPickerProps> = {
    InputBar: DefaultInputBar,
    InputList: DefaultInputList,
    RecipientList: DefaultRecipientList,
    initDropdownPosition: 0,
  };

  public constructor(props: RecipientPickerProps) {
    super(props);
    const filteredRecipients = filterRecipients(
      "",
      this.props.dropdownRecipients,
      this.props.preloadedRecipients,
    );
    this.state = {
      recipients: [...this.props.preloadedRecipients],
      inputValue: "",
      isFocused: false,
      showDropdown: false,
      dropdownItems: filteredRecipients,
      dropdownPosition: this.props.initDropdownPosition,
      cursorPosition:
        this.props.initCursorPosition || this.props.preloadedRecipients.length,
    };
  }

  public render(): ReactNode {
    const {
      recipients,
      inputValue,
      isFocused,
      dropdownPosition,
      cursorPosition,
    } = this.state;

    const {
      inputName,
      dropdownRecipients,
      placeholderPlural,
      placeholderSingular,
      InputBar,
      InputList,
      RecipientList,
      label = "To",
      autoFocus,
      showLabel = true,
    } = this.props;

    const filteredRecipients = filterRecipients(
      inputValue,
      dropdownRecipients,
      recipients,
    );

    const { placeholderValue, ariaLabelValue } = getPlaceholderValues(
      dropdownRecipients,
      placeholderSingular,
      placeholderPlural,
      recipients,
    );

    const inputFlexibleHeight: CSSProperties = {
      height: "auto",
      minHeight: "3rem",
      zIndex: isFocused ? 3 : "auto",
    };

    let { showDropdown } = this.state;
    if (isLabelSelected()) {
      showDropdown = false;
    }

    return (
      <>
        <div className="fieldAffix">
          {showLabel && <div className={`fieldAffix-item`}>{label}</div>}
          <div className="input u-paddingSmaller" style={inputFlexibleHeight}>
            <div className="row collapse align-middle">
              <InputList
                recipients={recipients}
                inputName={inputName}
                onRemove={this.handleRemoveSelectedRecipient}
                highlightedIndex={cursorPosition}
              />
              <InputBar
                inputValue={inputValue}
                placeholderValue={placeholderValue}
                ariaValue={ariaLabelValue}
                onBackspace={this.handleBackspace}
                onInput={this.handleCapturedInput}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onVerticalArrowKey={this.handleVerticalArrow}
                onHorizontalArrowKey={this.handleHorizontalArrow}
                onEscape={this.handleEscape}
                readOnly={isLabelSelected()}
                onClick={this.returnToReadable}
                // eslint-disable-next-line jsx-a11y/no-autofocus -- Grandfathered error: Please fix if touching this code.
                autoFocus={autoFocus}
              />
            </div>
            <RecipientList
              filteredRecipients={filteredRecipients}
              onAddRecipient={this.handleAddRecipient}
              shouldDisplay={showDropdown}
              highlightedItem={dropdownPosition}
              onUpdateHighlight={this.updateHighlight}
            />
          </div>
        </div>
      </>
    );

    function isLabelSelected() {
      return inputValue === "" && cursorPosition !== recipients.length;
    }
  }

  @bind
  public returnToReadable() {
    this.setState({ cursorPosition: this.state.recipients.length });
  }

  @bind
  public updateHighlight(index: number) {
    this.setState({ dropdownPosition: index });
  }

  @bind
  public handleVerticalArrow(changeVal: number) {
    const getNewPosition = (updatedVal: number, upperBound: number) =>
      constrainNumber(updatedVal, upperBound, 0);
    this.setState(prevState => ({
      dropdownPosition: getNewPosition(
        prevState.dropdownPosition + changeVal,
        prevState.dropdownItems.length - 1,
      ),
    }));
  }

  @bind
  public handleHorizontalArrow(changeVal: number) {
    if (this.state.inputValue !== "") {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getNewPosition = (updatedVal: number, upperBound: number) =>
      //When the cursorPosition is the same as the length of the recipient list, nothing is highlighted
      constrainNumber(updatedVal, this.state.recipients.length, 0);
    this.setState(prevState => ({
      cursorPosition: getNewPosition(
        prevState.cursorPosition + changeVal,
        this.state.recipients.length - 1,
      ),
    }));
  }

  @bind
  public handleAddRecipient(selectedItem: string) {
    const inList = this.props.dropdownRecipients.includes(selectedItem);
    this.setState(prevState => ({
      recipients:
        this.props.restrictInput && !inList
          ? [...prevState.recipients]
          : [...prevState.recipients, selectedItem],
      inputValue: "",
      cursorPosition: this.state.recipients.length + 1,
    }));

    this.setState(refreshDropdown);

    if (this.props.onChange) {
      const newRecipients =
        this.props.restrictInput && !inList
          ? [...this.state.recipients]
          : [...this.state.recipients, selectedItem];
      this.props.onChange(newRecipients);
    }
  }

  @bind
  public handleCapturedInput(capturedInput: string) {
    const { showDropdown, dropdownItems, dropdownPosition } = this.state;
    if (showDropdown && dropdownItems.length > 0) {
      const dropdownItem = dropdownItems[dropdownPosition];
      this.handleAddRecipient(dropdownItem);
    } else if (capturedInput !== "") {
      this.handleAddRecipient(capturedInput);
    } else {
      return;
    }
  }

  @bind
  public handleRemoveSelectedRecipient(selectedRecipient: string) {
    const updatedRecipients = (arr: string[]) =>
      arr.filter((recipientString: string) => {
        return recipientString !== selectedRecipient;
      });
    this.setState(prevState => ({
      recipients: updatedRecipients(prevState.recipients),
    }));

    this.setState(refreshDropdown);

    if (this.props.onChange) {
      this.props.onChange(
        this.state.recipients.filter(item => item !== selectedRecipient),
      );
    }
  }

  @bind
  public handleBackspace() {
    if (this.state.cursorPosition !== this.state.recipients.length) {
      const newRecipients = [...this.state.recipients];
      newRecipients.splice(this.state.cursorPosition, 1);
      this.setState({ recipients: newRecipients });
    } else {
      const shouldDelete = (inputValue: string, recipients: string[]) => {
        if (inputValue.length === 0 && recipients.length > 0) {
          return [...recipients.slice(0, -1)];
        }

        return recipients;
      };

      this.setState(prevState => ({
        recipients: shouldDelete(prevState.inputValue, prevState.recipients),
      }));
    }
    this.setState(refreshDropdown);
    //Behaviour is the same as pressing the horizontal arrow in this case
    this.handleHorizontalArrow(-1);
  }

  @bind
  public handleChange(textAreaString: string) {
    this.setState({ inputValue: textAreaString });
    this.setState(refreshDropdown);
  }

  @bind
  public handleFocus() {
    this.setState({ showDropdown: true, isFocused: true });
    this.setState(refreshDropdown);
  }

  @bind
  public handleBlur() {
    if (this.state.inputValue) {
      this.setState(prevState => {
        if (this.props.onChange) {
          const newRecipients = this.props.restrictInput
            ? [...prevState.recipients]
            : [...prevState.recipients, prevState.inputValue];
          this.props.onChange(newRecipients);
        }
        return {
          showDropdown: false,
          isFocused: false,
          inputValue: "",
          recipients: this.props.restrictInput
            ? [...prevState.recipients]
            : [...prevState.recipients, prevState.inputValue],
        };
      });
    } else {
      this.setState({ showDropdown: false, isFocused: false });
    }
  }

  @bind
  public handleEscape(event?: React.KeyboardEvent<HTMLInputElement>) {
    if (this.state.showDropdown) {
      // Have to use nativeEvent or dialog-box.js.coffee will still capture the event and close the dialog
      // See https://reactjs.org/docs/events.html#overview
      if (event) {
        event.nativeEvent.stopImmediatePropagation();
      }
      this.setState({ showDropdown: false });
    }
  }
}
