import React, { type KeyboardEvent } from "react";
import styles from "./InputBar.module.css";

export interface InputBarProps {
  autoFocus?: boolean;
  inputValue: string;
  placeholderValue: string;
  ariaValue: string;
  readOnly?: boolean;
  onBackspace(): void;
  onInput(addedRecipient: string): void;
  onChange(textAreaString: string): void;
  onFocus(): void;
  onBlur(): void;
  onVerticalArrowKey(changeVal: number): void;
  onHorizontalArrowKey(changeVal: number): void;
  onEscape(event?: React.KeyboardEvent<HTMLInputElement>): void;
  onClick(): void;
}

export function InputBar({
  onBackspace,
  onInput,
  onChange,
  onBlur,
  onFocus,
  onVerticalArrowKey,
  onHorizontalArrowKey,
  onEscape,
  inputValue,
  placeholderValue,
  ariaValue,
  readOnly,
  onClick,
  autoFocus,
}: InputBarProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textareaValue = (event.currentTarget as HTMLInputElement).value;
    onChange(textareaValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Note: Using else requires redefining the behaviour of every key event
    // Just use if statements and define behaviour in the unit tests

    if (event.key === "Enter" && inputValue.length === 0 && readOnly) {
      event.preventDefault();
      return;
    }
    // Let the user tab normally if input is empty
    if (
      (event.key === "Tab" && inputValue.length > 0) ||
      [",", "Enter", "Space"].includes(event.key)
    ) {
      event.preventDefault();
      onInput((event.currentTarget as HTMLInputElement).value);
      return;
    }
    if (event.key === "Backspace") {
      onBackspace();
      return;
    }
    handleNavigation(event.key, event);
  };

  return (
    <div className="columns" style={{ minWidth: "5.625rem" }}>
      {/* eslint-disable-next-line jsx-a11y/autocomplete-valid -- Grandfathered error: Please fix if touching this code. */}
      <input
        type="text"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        className={styles.recipientInput}
        placeholder={placeholderValue}
        aria-label={ariaValue}
        value={inputValue}
        data-testid="InputBar-input"
        autoComplete="new-email" // needed to prevent chrome autocomplete; chrome ignores '=off'
        readOnly={readOnly}
        // eslint-disable-next-line jsx-a11y/no-autofocus -- Grandfathered error: Please fix if touching this code.
        autoFocus={!!autoFocus}
      />
    </div>
  );

  function handleNavigation(
    inputChar: string,
    event: KeyboardEvent<HTMLInputElement>,
  ) {
    if (inputChar === "ArrowUp") {
      onVerticalArrowKey(-1);
    }
    if (inputChar === "ArrowDown") {
      onVerticalArrowKey(1);
    }
    if (inputChar === "ArrowLeft") {
      onHorizontalArrowKey(-1);
    }
    if (inputChar === "ArrowRight") {
      onHorizontalArrowKey(1);
    }
    if (inputChar === "Escape") {
      onEscape(event);
    }
  }
}
