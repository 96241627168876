import { useState } from "react";
import { computeOccurrence } from "jobber/workOrders/components/Scheduler/computeOccurrence";
import { DurationPeriod } from "jobber/workOrders/components/Scheduler/types";
import {
  InvoiceCreationPreference,
  InvoicePeriod,
  type InvoiceSchedulerState,
} from "./types";

export const useInvoiceScheduler = (
  initialState: Partial<InvoiceSchedulerState> = {},
) => {
  const [state, setState] = useState<InvoiceSchedulerState>({
    jobberPaymentsEnabled: false,
    canUseAutomaticPayments: false,
    schedule: InvoicePeriod.AsNeeded,
    invoiceCreationPreference: InvoiceCreationPreference.RemindMe,
    startDate: new Date(),
    recurrence: {
      rule: { interval: 1, type: DurationPeriod.Day },
      ends: {
        type: "date",
        date: new Date(),
        numOfPeriods: 1,
        durationPeriod: DurationPeriod.DayOfMonth,
      },
    },
    invoiceOnJobComplete: true,
    ...initialState,
  });

  return [
    {
      ...state,
      ...computeOccurrence(state.startDate, state.recurrence),
    },
    setState,
  ] as const;
};
