import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Option, Select } from "@jobber/components/Select";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import type {
  CategoryWithServices,
  Service,
} from "jobber/google_lsa/MerchantConfiguration";
import { CategoryRemovalModal } from "./CategoryRemovalModal";
import { ServiceItem } from "./ServiceItem";
import styles from "./ServiceSelector.module.css";

interface ServiceSelectorProps {
  type?: "primary" | "secondary" | "tertiary";
  size?: "small" | "large" | "base";
  label?: string;
  categories: CategoryWithServices[];
  activeCategory?: CategoryWithServices;
  onUpdate(services: Service[]): Promise<void>;
}

// eslint-disable-next-line max-statements
export function ServiceSelector({
  type,
  size,
  label,
  categories,
  activeCategory,
  onUpdate,
}: ServiceSelectorProps) {
  // Keep track if we're mounted or not in case an update causes this to be unmounted
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const serviceListClassName = classnames(styles.serviceList);

  const [serviceIndustry, setServiceIndustry] = useState(
    activeCategory?.categoryName ?? "",
  );

  const [serviceList, setServiceList] = useState([] as Service[]);

  const toggleService = (serviceName: string) => {
    return (newValue: boolean) => {
      const index = serviceList.findIndex(service => {
        return service.serviceName === serviceName;
      });

      const modifiedServiceList = Array.from(serviceList);
      modifiedServiceList[index] = {
        ...serviceList[index],
        enabled: newValue,
      };

      setServiceList(modifiedServiceList);
    };
  };

  const changePrice = (serviceName: string) => {
    return (newValue: number) => {
      const index = serviceList.findIndex(service => {
        return service.serviceName === serviceName;
      });

      const modifiedServiceList = Array.from(serviceList);
      modifiedServiceList[index] = {
        ...serviceList[index],
        price: newValue,
      };

      setServiceList(modifiedServiceList);
    };
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const [serviceSelectorOpen, setServiceSelectorOpen] = useState(false);
  const openServiceSelector = () => {
    setServiceList(activeCategory?.services ?? []);
    setServiceSelectorOpen(true);
  };
  const closeServiceSelector = () => {
    setServiceIndustry(activeCategory?.categoryName ?? "");
    setServiceList([]);
    setServiceSelectorOpen(false);
  };

  const saveServiceSelector = () => {
    setSaveLoading(true);
    onUpdate(serviceList).then(
      () => {
        showToast({
          message: "Job types saved",
          variation: "success",
        });
        if (isMounted.current) {
          setSaveLoading(false);
          closeServiceSelector();
        }
      },
      () => {
        showToast({
          message: "A problem occurred when saving your job types",
          variation: "error",
        });

        setSaveLoading(false);
      },
    );
  };

  const [removeAllLoading, setRemoveAllLoading] = useState(false);
  const [showRemovalModal, setShowRemovalModal] = useState(false);

  const confirmRemoveAll = () => {
    onUpdate(
      serviceList.map((service: Service) => {
        service.enabled = false;
        return service;
      }),
    ).then(
      () => {
        showToast({
          message: "Job types removed",
          variation: "success",
        });

        if (isMounted.current) {
          setShowRemovalModal(false);
          setRemoveAllLoading(false);
        }
      },
      () => {
        showToast({
          message: "A problem occurred when removing your job types",
          variation: "error",
        });

        setShowRemovalModal(false);
        setRemoveAllLoading(false);
      },
    );
  };
  const cancelRemoveAll = () => {
    setRemoveAllLoading(false);
    setShowRemovalModal(false);
  };

  const removeAllServices = () => {
    setRemoveAllLoading(true);
    setShowRemovalModal(true);
  };

  return (
    <>
      <Button
        label={label ?? "Add Job Category"}
        size={size}
        type={type}
        onClick={openServiceSelector}
      />
      <Modal
        title="Match Your Job Types"
        open={serviceSelectorOpen}
        onRequestClose={closeServiceSelector}
        primaryAction={{
          label: "Save",
          onClick: saveServiceSelector,
          loading: saveLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: closeServiceSelector,
        }}
        tertiaryAction={
          activeCategory
            ? {
                label: "Remove Category",
                onClick: removeAllServices,
                loading: removeAllLoading,
              }
            : undefined
        }
      >
        <Content>
          <Text>
            Select the job category and service types from your Google Local
            Service Ads profile that you would like to offer for online booking.
          </Text>
          <Select
            value={serviceIndustry}
            onChange={updateServiceList}
            disabled={activeCategory !== undefined}
          >
            <Option value="" disabled={true}>
              - Select a job category -
            </Option>
            {categories.map((category: CategoryWithServices) => (
              <Option
                key={category.categoryName}
                value={category.categoryName}
                disabled={
                  category.services.filter(service => service.id !== undefined)
                    .length > 0
                }
              >
                {category.categoryDisplayName}
              </Option>
            ))}
          </Select>

          {serviceList.length > 0 && (
            <>
              <Text>
                Enter the minimum price you would like to charge to do an
                on-site assessment for a service. A price of $0.00 will be
                considered a free on-site assessment.
              </Text>
              <table className={serviceListClassName}>
                <thead>
                  <tr>
                    <th>Service Types</th>
                    <th>Listed Minimum Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceList.map((service: Service) => {
                    return (
                      <ServiceItem
                        key={service.serviceName}
                        service={service}
                        onSwitch={toggleService}
                        onEdit={changePrice}
                      />
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </Content>
      </Modal>
      {activeCategory && (
        <CategoryRemovalModal
          open={showRemovalModal}
          activeServices={serviceList}
          onConfirmed={confirmRemoveAll}
          onCancelled={cancelRemoveAll}
        />
      )}
    </>
  );

  function findCategory(selectedCategoryName: string) {
    return categories.find(
      category => category.categoryName === selectedCategoryName,
    );
  }

  function updateServiceList(selectedCategoryName: string) {
    const selectedCategory = findCategory(selectedCategoryName);

    if (selectedCategory) {
      setServiceList(selectedCategory.services);
      setServiceIndustry(selectedCategoryName);
    }

    return;
  }
}
