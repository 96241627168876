import React from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import { BaseReadOnlyCustomField } from "./BaseReadCustomField";

interface CustomFieldEditProps {
  value: boolean;
  editing: boolean;
  onChange(newValue: boolean): void;
}

interface CustomFieldBooleanProps {
  isInAppGroup?: boolean;
  label: string;
  stringValue: string;
  editProps?: CustomFieldEditProps;
}

export function CustomFieldBoolean({
  isInAppGroup = false,
  label,
  stringValue,
  editProps,
}: CustomFieldBooleanProps) {
  if (editProps?.editing) {
    const definedValue = editProps.value;
    return (
      <div>
        <Checkbox
          label={label}
          checked={definedValue}
          onChange={editProps.onChange}
        />
      </div>
    );
  } else {
    return BaseReadOnlyCustomField(isInAppGroup, label, stringValue);
  }
}
