import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import styles from "./DriveTimeLabel.module.css";

interface DriveTimeLabelProps {
  mins: number;
  isDriveTimeFrom?: boolean;
}

export function DriveTimeLabel({ mins, isDriveTimeFrom }: DriveTimeLabelProps) {
  return (
    <div
      data-testid="drive-time-label"
      data-direction={isDriveTimeFrom ? "from" : "to"}
      className={styles.container}
    >
      <div className={styles.content}>
        <Text variation="subdued" size="small">{`${mins} min`}</Text>
        <div className={styles.icon}>
          <Icon size="small" name="visit" color="textSecondary" />
        </div>
      </div>
    </div>
  );
}
