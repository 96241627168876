import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Popover } from "@jobber/components/Popover";
import { Text } from "@jobber/components/Text";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
  trackAnalytics,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import styles from "./LiteToCoreTrialPopovers.module.css";
import { findElement } from "./liteToCoreTrialUtils";

export interface LiteToCoreTrialPopoversProps {
  isInsightsDrawerOpen: boolean;
}

export function LiteToCoreTrialPopovers({
  isInsightsDrawerOpen,
}: LiteToCoreTrialPopoversProps) {
  const [popover, setPopover] = useState(0);

  const ctaName = "lite_to_core_trial_highlights_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  const popoverContent = [
    {
      elementSelector: "h1",
      heading: "Track your progress",
      content: "Start your day with a visual of your entire workflow.",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: isInsightsDrawerOpen
        ? `[data-testid="drawer-header"] h3`
        : `span:contains("View Insights")`,
      heading: "Reduce blind spots",
      content: "Insights will surface items that need your attention.",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `[aria-label="Schedule"]`,
      heading: "Start scheduling work",
      content: "Open your calendar and create a new job to get started.",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `[aria-label="Jobs"]`,
      heading: "Manage jobs",
      content: "Access a list of all jobs and their current status.",
      buttonLabel: "Got it",
      primaryAction: convertCTA(ctaRef, {
        type: `popover_step_4`,
      }),
    },
  ];

  return (
    <APIProvider>
      <CallToAction ctaName={ctaName} ref={ctaRef}>
        <LiteToCoreTrialPopover
          ctaName={ctaName}
          elementSelector={popoverContent[popover].elementSelector}
          heading={popoverContent[popover].heading}
          content={popoverContent[popover].content}
          popoverIndex={popover + 1}
          buttonLabel={popoverContent[popover].buttonLabel}
          primaryAction={popoverContent[popover].primaryAction}
          dismissPopover={dismissCTA(ctaRef, {
            type: `popover_step_${popover + 1}`,
          })}
        />
      </CallToAction>
    </APIProvider>
  );

  function handleNextPopover() {
    setPopover(popover + 1);
  }
}

interface LiteToCoreTrialPopoverProps {
  ctaName: string;
  elementSelector: string;
  heading: string;
  content: string;
  popoverIndex: number;
  buttonLabel?: string;
  primaryAction(): void;
  dismissPopover(): void;
}

function LiteToCoreTrialPopover({
  ctaName,
  elementSelector,
  heading,
  content,
  popoverIndex,
  buttonLabel = "Next",
  primaryAction,
  dismissPopover,
}: LiteToCoreTrialPopoverProps) {
  const element = findElement(elementSelector);

  useEffect(() => {
    trackAnalytics("CTA Viewed", ctaName, {
      type: `popover_step_${popoverIndex}`,
    });
  }, [popoverIndex]);

  return element ? (
    <Popover
      attachTo={element}
      open={true}
      preferredPlacement="right"
      onRequestClose={dismissPopover}
    >
      <Content spacing="base">
        <Heading level={4}>{heading}</Heading>
        <Text>{content}</Text>
        <div className={styles.row}>
          <Text variation="subdued">{popoverIndex} of 4</Text>
          <Button
            variation="learning"
            label={buttonLabel}
            onClick={primaryAction}
          />
        </div>
      </Content>
    </Popover>
  ) : (
    <></>
  );
}
