import { defineMessages } from "react-intl";

export const messages = defineMessages({
  discountLabel: {
    id: "billing.addonPurchasePreview.discountLabel",
    defaultMessage: "Discount",
    description: "Discount label",
  },
  discountLabelWithPercent: {
    id: "billing.addonPurchasePreview.discountLabelWithPercent",
    defaultMessage: "Discount ({discountAmount}% off)",
    description: "Discount label with percent",
  },
  billedToday: {
    id: "billing.addonPurchasePreview.billedToday",
    defaultMessage: "Billed today",
    description: "Purchase preview header",
  },
  futureBilledHeader: {
    id: "billing.addonPurchasePreview.futureBilledHeader",
    defaultMessage: "Billed {startBillingOnDate}",
    description: "Purchase preview header for a future billed subscription",
  },
  multipleDiscountedPreviewDetails: {
    id: "billing.addonPurchasePreview.multipleDiscountedPreviewDetails",
    defaultMessage:
      "You will receive an immediate prorated charge of <b>{proratedTotal}</b>. After the discount period your subscription will renew at <b>{monthlyCost}</b>.",
    description: "Purchase preview details with multiple discounts",
  },
  taxDisclaimer: {
    id: "billing.addonPurchasePreview.taxDisclaimer",
    defaultMessage: "Plus taxes where applicable.",
    description: "Purchase preview tax disclaimer",
  },
  creditLabel: {
    id: "billing.addonPurchasePreview.creditLabel",
    defaultMessage: "Credit",
    description: "Credit label",
  },
  addonSubscription: {
    id: "billing.addonPurchasePreview.addonSubscription",
    defaultMessage: "{addonName} subscription",
    description:
      "Display name for the add-on subscription in the purchase disclaimer",
  },
  renewalDisclaimer: {
    id: "billing.addonPurchasePreview.renewalDisclaimer",
    defaultMessage:
      "Charges are non-refundable and will automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description: "Purchase renewal disclaimer",
  },
  allPricesUSD: {
    id: "billing.addonPurchasePreview.allPricesUSD",
    defaultMessage: "All prices are in $USD",
    description: "All prices are in USD",
  },
});
