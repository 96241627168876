import { Icon, type IconNames } from "@jobber/components/Icon";
import React, { type ReactNode } from "react";
import { Text } from "@jobber/components/Text";
import styles from "./AccordionItem.module.css";

interface AccordionItemProps {
  removeBackgroundColour?: boolean;
  includeTitlePadding?: boolean;
  leftIcon?: IconNames;
  title: string;
  content: ReactNode;
  textDisabled?: boolean;
  id: number;
  selected?: boolean;
  handleItemChange: (index: number) => void;
  secondaryTitle?: string;
}

export function AccordionItem({
  removeBackgroundColour,
  includeTitlePadding = true,
  id,
  leftIcon,
  title,
  textDisabled,
  content,
  selected,
  handleItemChange,
  secondaryTitle,
}: AccordionItemProps) {
  const renderText = () => {
    if (textDisabled && !selected) {
      return (
        <span className={styles.accordionItemTextNotDone}>
          <Text variation="subdued">{title}</Text>
        </span>
      );
    } else {
      return (
        <Text>
          <span className={styles.accordionItemTextRegular}>{title}</span>
        </Text>
      );
    }
  };

  const Wrapper = "button";
  const buttonProps = {
    ...{ role: "button", type: "button" as const },
  };

  return (
    <details open={selected} className={styles.accordionDetails}>
      <summary className={styles.accordionSummary}>
        <Wrapper
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            handleItemChange(id);
          }}
          className={styles.accordionContainerButton}
          {...buttonProps}
        >
          <div
            className={`${
              selected ? styles.accordionItemSelected : styles.accordionItem
            } ${removeBackgroundColour && styles.removeBackgroundColour}`}
          >
            <div
              className={`${includeTitlePadding && styles.accordionItemTitle}
              ${removeBackgroundColour && styles.removeBackgroundColour}`}
            >
              <div className={styles.accordionItemTitleIcon}>
                {leftIcon && (
                  <span className={styles.accordionItemDisplay}>
                    <Icon size="base" name={leftIcon} color="green" />
                  </span>
                )}
              </div>
              <div className={styles.accordionItemTitleText}>
                {renderText()}
              </div>
            </div>

            <div className={styles.secondaryTitleContainer}>
              {secondaryTitle && (
                <div className={styles.secondaryTitle}>
                  <Text variation="subdued">{secondaryTitle}</Text>
                </div>
              )}
              <Icon
                size="base"
                name={selected ? "arrowUp" : "arrowDown"}
                color="greyBlue"
              />
            </div>
          </div>
        </Wrapper>
      </summary>
      <div>
        <div className={styles.accordionItemDetails}>{content}</div>
      </div>
    </details>
  );
}
