export enum ExpenseActionTypes {
  UpdateItemName,
  UpdateAccountingCode,
  UpdateDescription,
  UpdateExpenseDate,
  UpdateTotal,
  UpdateReceipt,
  DeleteReceipt,
  UpdateReimburseTo,
}

export const initialExpenseModalState: ExpenseModalState = {
  itemName: "",
  accountingCode: "",
  description: "",
  expenseDate: new Date(),
  total: undefined,
  receiptUrl: undefined,
  reimburseTo: "",
};

export interface ExpenseModalState {
  itemName: string;
  accountingCode: string;
  description?: string;
  expenseDate: Date;
  receiptUrl?: string;
  total?: number;
  reimburseTo?: string;
}

export type ExpenseModalStateType = Date | number | string;

export interface ExpenseModalAction {
  type: ExpenseActionTypes;
  value: ExpenseModalStateType;
}

export function ExpenseModalReducer(
  prevState: ExpenseModalState,
  action: ExpenseModalAction,
): ExpenseModalState {
  let nextState;
  switch (action.type) {
    case ExpenseActionTypes.UpdateItemName:
      nextState = { itemName: action.value as string };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateAccountingCode:
      nextState = { accountingCode: action.value as string };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateDescription:
      nextState = { description: action.value as string };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateExpenseDate:
      nextState = { expenseDate: action.value as Date };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateTotal:
      nextState = { total: action.value as number };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateReceipt:
      nextState = { receiptUrl: action.value as string };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.DeleteReceipt:
      nextState = { receiptUrl: undefined };
      return { ...prevState, ...nextState };

    case ExpenseActionTypes.UpdateReimburseTo:
      nextState = { reimburseTo: action.value as string };
      return { ...prevState, ...nextState };

    default:
      throw new Error("Invalid action type");
  }
}
