import React from "react";
import jobberLogo from "@images/integrations/jobber-logo.png";
import dottedLine from "@images/integrations/dotted-line.png";
import styles from "./OAuthAuthorizationPage.module.css";

interface OAuthAuthorizationLogosProps {
  name: string;
  logo?: string;
}

export function OAuthAuthorizationLogos({
  name,
  logo,
}: OAuthAuthorizationLogosProps) {
  return (
    <>
      {logo && (
        <div className={styles.logo_container}>
          <div className={styles.logo}>
            <img src={logo} alt={`${name} Logo`} />
          </div>
          <div className={styles.dotted_line}>
            <img src={dottedLine} alt={"dotted line connecting apps"} />
          </div>
          <div className={styles.logo}>
            <img src={jobberLogo} alt={"Jobber logo"} />
          </div>
        </div>
      )}
    </>
  );
}
