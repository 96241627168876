import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "selfServeBookings.SettingsPage.header",
    defaultMessage: "Online booking",
    description: "Title of the Online Booking settings page",
  },
  services: {
    id: "selfServeBookings.SettingsPage.services",
    defaultMessage: "Services",
    description: "Title of the Services settings page",
  },
  intro: {
    id: "selfServeBookings.SettingsPage.intro",
    defaultMessage:
      "Streamline your scheduling by letting leads and customers book an appointment with you online. New jobs will keep rolling in, while you remain in total control of what services and availability you offer online.",
    description: "Intro to the Online Booking settings page",
  },
  copyLabel: {
    id: "selfServeBookings.SettingsPage.copyLabel",
    defaultMessage: "Copy link",
    description: "Copy action for the Online Booking settings page",
  },
  previewLabel: {
    id: "selfServeBookings.SettingsPage.previewLabel",
    defaultMessage: "Preview link",
    description: "Preview action for the Online Booking settings page",
  },
  toggleOffLabel: {
    id: "selfServeBookings.SettingsPage.toggleOffLabel",
    defaultMessage: "Turn off bookings",
    description: "Turn off action for the Online Booking settings page",
  },
  toggledOffLabel: {
    id: "selfServeBookings.SettingsPage.toggledOffLabel",
    defaultMessage: "Accept bookings turned off",
    description: "Turn off toast for the Online Booking settings page",
  },
  toggleOnLabel: {
    id: "selfServeBookings.SettingsPage.toggleOnLabel",
    defaultMessage: "Turn on bookings",
    description: "Turn on action for the Online Booking settings page",
  },
  toggledOnLabel: {
    id: "selfServeBookings.SettingsPage.toggledOnLabel",
    defaultMessage: "Accept bookings turned on",
    description: "Turn on toast for the Online Booking settings page",
  },
  errorToggling: {
    id: "selfServeBookings.SettingsPage.errorToggling",
    defaultMessage: "Somehting went wrong. Please try again.",
    description: "Error toast for the Online Booking settings page",
  },
  validationMinimumServices: {
    id: "selfServeBookings.SettingsPage.validationMinimumServices",
    defaultMessage:
      "At least 1 bookable service must be added to accept appointments",
    description: "Validation message for minimum services",
  },
  validationAddService: {
    id: "selfServeBookings.SettingsPage.validationAddService",
    defaultMessage: "Add a service",
    description: "Message for the action to add a service",
  },
  validationMinimumUsers: {
    id: "selfServeBookings.SettingsPage.validationMinimumUsers",
    defaultMessage:
      "At least 1 team member must be added to accept appointments",
    description: "Validation message for minimum users",
  },
  validationMinimumServicesAndUsers: {
    id: "selfServeBookings.SettingsPage.validationMinimumServicesAndUsers",
    defaultMessage:
      "At least 1 bookable service and 1 bookable team member must be added to accept appointments",
    description: "Validation message for minimum services and users",
  },
  validationOnlineBookingTurnedOff: {
    id: "selfServeBookings.SettingsPage.validationOnlineBookingTurnedOff",
    defaultMessage:
      "You've turned off the booking link and are not currently accepting bookings",
    description:
      "Validation message for when online booking has been manually turned off",
  },
});
