export const cancellationQuestions = [
  {
    id: "cfq1",
    label: "What is the reason they're cancelling their account?",
    customerText: "What is the reason you're cancelling your account?",
    type: "select",
    options: [
      {
        id: "0",
        label: "Non-adoption",
        customerText: "Unable to set up and use",
        nextQuestion: "cfq1a",
      },
      {
        id: "1",
        label: "Product issue",
        customerText: "Product issue",
        nextQuestion: "cfq1b",
      },
      {
        id: "2",
        label: "Business reason",
        customerText: "Selling business, closing business, seasonal business",
        nextQuestion: "cfq1c",
      },
      {
        id: "3",
        label: "Value/price",
        customerText: "Value or price",
        nextQuestion: "cfq1d",
      },
      {
        id: "4",
        label: "Customer service",
        customerText: "Customer service",
        nextQuestion: "cfq1e",
      },
    ],
  },
  {
    id: "cfq1a",
    label: "Non-adoption",
    customerText: "Unable to set up and use",
    type: "select",
    options: [
      {
        id: "0",
        label: "Owner",
        customerText: "Company owner was not able to use",
        nextQuestion: "cfq1aa",
      },
      {
        id: "1",
        label: "Employees/field workers",
        customerText: "Employees or field workers were not able to use",
        nextQuestion: "cfq1ab",
      },
    ],
  },
  {
    id: "cfq1aa",
    label: "Non-adoption: owner",
    customerText: "Unable to set up and use: company owner",
    type: "select",
    options: [
      {
        id: "0",
        label: "Liked Jobber, but was too busy to actually set it up",
        customerText: "Liked Jobber, but was too busy to actually set it up",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Tried to use Jobber but found the product too difficult to use",
        customerText:
          "Tried to use Jobber but found the product too difficult to use",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Product was missing functionality",
        customerText: "Product was missing functionality",
        nextQuestion: "cfqfr",
      },
      {
        id: "3",
        label:
          "Person who signed up for Jobber was not the owner and couldn't get the owner to use it",
        customerText:
          "Person who signed up for Jobber was not the owner, and the owner was not interested in using Jobber",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfq1ab",
    label: "Non-adoption: employees/field workers",
    customerText: "Unable to set up and use: employees or field workers",
    type: "select",
    options: [
      {
        id: "0",
        label: "Staff wouldn't take the time to use the product",
        customerText: "Staff wouldn't take the time to use the product",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Staff found the product too difficult to use",
        customerText: "Staff found the product too difficult to use",
        nextQuestion: "cfq1abns1",
      },
      {
        id: "2",
        label: "Product was missing must-have features",
        customerText: "Product was missing must-have features",
        nextQuestion: "cfqfr",
      },
    ],
  },
  {
    id: "cfq1abns1",
    label: "Could you provide more detail?",
    customerText: "Could you provide more detail?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfq1b",
    label: "Product issue",
    customerText: "Product issue",
    type: "select",
    options: [
      {
        id: "0",
        label: "Too hard to learn/use",
        customerText: "Too hard to learn or use",
        nextQuestion: "cfq1ba",
      },
      {
        id: "1",
        label: "Missing key functionality",
        customerText: "Missing key functionality",
        nextQuestion: "cfqfr",
      },
      {
        id: "2",
        label: "Performance, bugs, stability issues",
        customerText: "Performance, bugs, stability issues",
        nextQuestion: "cfq1bc",
      },
      {
        id: "3",
        label: "Bad fit, unsupported industry",
        customerText: "Bad fit for our business or workflow",
        nextQuestion: "cfqfr",
      },
    ],
  },
  {
    id: "cfq1ba",
    label: "Too hard to learn/use",
    customerText: "Too hard to learn or use",
    type: "select",
    options: [
      {
        id: "0",
        label: "Online too hard to learn/use",
        customerText: "Jobber online is too hard to learn or use",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "App is too hard to learn/use",
        customerText: "The mobile app is too hard to learn or use",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Both online and app are too hard to learn/use",
        customerText: "Both online and the app are too hard to learn or use",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfq1bc",
    label: "Performance, bugs, stability issues",
    customerText: "Performance, bugs, or stability issues",
    type: "select",
    options: [
      {
        id: "0",
        label: "Desktop/Web interface",
        customerText: "Desktop or web interface",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Mobile browser",
        customerText: "Mobile browser",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Mobile app",
        customerText: "Mobile app",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "More than one/all",
        customerText: "More than one or all",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfq1c",
    label: "Business reason",
    customerText: "Selling business, closing business, seasonal business",
    type: "select",
    options: [
      {
        id: "0",
        label: "Seasonal business (will start up again)",
        customerText: "Seasonal business (will start up again)",
        nextQuestion: "cfqrd",
      },
      {
        id: "1",
        label: "Sold business",
        customerText: "Sold business",
        nextQuestion: "cfq1cns1",
      },
      {
        id: "2",
        label: "Merging two businesses into one",
        customerText: "Merging two businesses into one",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Closed business",
        customerText: "Closed business",
        nextQuestion: "cfqcr",
      },
      {
        id: "4",
        label: "Reopened account for temporary access/accidental subscription",
        customerText:
          "Reopened account for temporary access, or accidental subscription",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfqrd",
    label: "Expected restart date",
    customerText: "Expected restart date",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqcr",
    label: "Is this a temporary closure? Can we ask why?",
    customerText: "Is this a temporary closure? Can we ask why?",
    type: "text",
    nextQuestion: "cfq3",
  },
  {
    id: "cfq1cns1",
    label: "Can we get contact info for new owner & reach out?",
    customerText:
      "Can we get contact info for the new owner and reach out to them?",
    type: "text",
    nextQuestion: "cfq2",
  },

  {
    id: "cfq1d",
    label: "Value/price",
    customerText: "Value or price",
    type: "select",
    options: [
      {
        id: "0",
        label: "Only used for invoicing/estimates",
        customerText: "Only used for invoicing or estimates",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Price too high for my income/revenue",
        customerText: "Price too high for my income or revenue",
        nextQuestion: "cfqpq",
      },
      {
        id: "2",
        label: "Price too high compared to other options",
        customerText: "Price too high compared to other options",
        nextQuestion: "cfqpc",
      },
    ],
  },
  {
    id: "cfqpq",
    label: "What price range would they be comfortable with?",
    customerText: "What price range would you be comfortable with?",
    type: "select",
    options: [
      {
        id: "0",
        label: "$0 - $10",
        customerText: "$0 - $10",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "$10 - $30",
        customerText: "$10 - $30",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "$30 - $60",
        customerText: "$30 - $60",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "$60 - $100",
        customerText: "$60 - $100",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "$100 - $200",
        customerText: "$100 - $200",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfqpc",
    label: "What are they comparing us to?",
    customerText: "What software are you comparing us to?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfq1e",
    label: "Customer service",
    customerText: "Customer service",
    type: "select",
    options: [
      {
        id: "0",
        label: "Product Coach missed meeting",
        customerText: "Product Coach missed meeting",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Response time too slow",
        customerText: "Response time too slow",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Poor customer interaction",
        customerText: "Poor customer interaction",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Billing issue",
        customerText: "Billing issue",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfq2",
    label: "What are they going to use to run their business after Jobber?",
    customerText: "What will you be using to run your business after Jobber?",
    type: "text",
    nextQuestion: "cfq3",
  },
  {
    id: "cfq3",
    label: "Is there any other feedback they would like to give to Jobber?",
    customerText: "Is there any other feedback you have for us?",
    type: "bigText",
  },
  {
    id: "cfqfr",
    label: "Functionality",
    customerText: "Functionality",
    type: "select",
    options: [
      {
        id: "0",
        label: "Calendar & scheduling",
        customerText: "Calendar and scheduling",
        nextQuestion: "cfqfr1a",
      },
      {
        id: "1",
        label: "Client management & customer communication",
        customerText: "Client management and customer communication",
        nextQuestion: "cfqfr2a",
      },
      {
        id: "2",
        label: "Invoicing & quoting",
        customerText: "Invoicing and quoting",
        nextQuestion: "cfqfr3a",
      },
      {
        id: "3",
        label: "Payments",
        customerText: "Payments",
        nextQuestion: "cfqfr4a",
      },
      {
        id: "4",
        label: "Mobile app",
        customerText: "Mobile app",
        nextQuestion: "cfqfr5a",
      },
      {
        id: "5",
        label: "Integrations",
        customerText: "Integrations",
        nextQuestion: "cfqfr6a",
      },
      {
        id: "6",
        label: "Team management, time tracking, & routing",
        customerText: "Team management, time tracking, and routing",
        nextQuestion: "cfqfr7a",
      },
      {
        id: "7",
        label: "Work & job tracking",
        customerText: "Work and job tracking",
        nextQuestion: "cfqfr8a",
      },
      {
        id: "8",
        label: "Reports",
        customerText: "Reports",
        nextQuestion: "cfqfr9a",
      },
      {
        id: "9",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr10a",
      },
    ],
  },
  {
    id: "cfqfr10a",
    label: "Other",
    customerText: "Other",
    type: "select",
    options: [
      {
        id: "0",
        label: "Language options",
        customerText: "Language options",
        nextQuestion: "cfqfr10a0",
      },
      {
        id: "1",
        label: "Payroll",
        customerText: "Payroll",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Benefits",
        customerText: "Benefits",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Franchise",
        customerText: "Franchise",
        nextQuestion: "cfqfr10a3",
      },
    ],
  },
  {
    id: "cfqfr10a3",
    label: "Franchise",
    customerText: "Franchise",
    type: "select",
    options: [
      {
        id: "0",
        label: "Franchise setup",
        customerText: "Franchise setup",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Reporting",
        customerText: "Reporting",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
      {
        id: "3",
        label: "Need more information to move between accounts",
        customerText: "Need more information to move between accounts",
        nextQuestion: "cfqfr10a3a",
      },
    ],
  },
  {
    id: "cfqfr10a3a",
    label: "What specific information do they need?",
    customerText:
      "What specific information do you need to share between accounts?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr10a0",
    label: "Language options",
    customerText: "Language options",
    type: "select",
    options: [
      {
        id: "0",
        label: "French",
        customerText: "French",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Spanish",
        customerText: "Spanish",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr10a0a",
      },
    ],
  },
  {
    id: "cfqfr10a0a",
    label: "Which language?",
    customerText: "Which language are you looking for?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr9a",
    label: "Reports",
    customerText: "Reports",
    type: "select",
    options: [
      {
        id: "0",
        label: "Job costs & profit",
        customerText: "Job costs and profit",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Custom reporting",
        customerText: "Custom reporting",
        nextQuestion: "cfqfr9a1",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr9a1",
    label: "What do they want to report on?",
    customerText: "What do you want to report on?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr8a",
    label: "Work & job tracking",
    customerText: "Work and job tracking",
    type: "select",
    options: [
      {
        id: "0",
        label: "Notes & attachments",
        customerText: "Notes and attachments",
        nextQuestion: "cfqfr8a0",
      },
      {
        id: "1",
        label: "Requests & assessments",
        customerText: "Requests and assessments",
        nextQuestion: "cfqfr8a1",
      },
      {
        id: "2",
        label: "Expense tracking",
        customerText: "Expense tracking",
        nextQuestion: "cfqfr8a2",
      },
      {
        id: "3",
        label: "Home dashboards",
        customerText: "Home dashboards",
        nextQuestion: "cfqfr8a3",
      },
      {
        id: "4",
        label: "Online booking",
        customerText: "Online booking",
        nextQuestion: "cfqfr8a4",
      },
      {
        id: "5",
        label: "Contracts & service agreements",
        customerText: "Contracts and service agreements",
        nextQuestion: "cfqfr8a5",
      },
      {
        id: "6",
        label: "Inventory & assets",
        customerText: "Inventory and assets",
        nextQuestion: "cfqfr8a6",
      },
      {
        id: "7",
        label: "Chemical tracking",
        customerText: "Chemical tracking",
        nextQuestion: "cfqfr8a7",
      },
    ],
  },
  {
    id: "cfqfr8a7",
    label: "Chemical tracking",
    customerText: "Chemical tracking",
    type: "select",
    options: [
      {
        id: "0",
        label: "Customization",
        customerText: "Customization",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Reporting",
        customerText: "Reporting",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a6",
    label: "Inventory & assets",
    customerText: "Inventory and assets",
    type: "select",
    options: [
      {
        id: "0",
        label: "Inventory",
        customerText: "Inventory",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Assets",
        customerText: "Assets",
        nextQuestion: "cfqfr8a6",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a6",
    label: "What kind of assets do they want to track?",
    customerText: "What kind of assets do you want to track?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr8a5",
    label: "Contracts & service agreements",
    customerText: "Contracts and service agreements",
    type: "select",
    options: [
      {
        id: "0",
        label: "Contracts",
        customerText: "Contracts",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Service agreements",
        customerText: "Service agreements",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a4",
    label: "Online booking",
    customerText: "Online booking",
    type: "select",
    options: [
      {
        id: "0",
        label: "Instant booking",
        customerText: "Instant booking",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Couldn't set up",
        customerText: "Couldn't set up",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Customization",
        customerText: "Customization",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a3",
    label: "Home dashboards",
    customerText: "Home dashboards",
    type: "select",
    options: [
      {
        id: "0",
        label: "Insights",
        customerText: "Insights",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Missing information",
        customerText: "Missing information",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Activity feed",
        customerText: "Activity feed",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a2",
    label: "Expense tracking",
    customerText: "Expense tracking",
    type: "select",
    options: [
      {
        id: "0",
        label: "Making expenses",
        customerText: "Making expenses",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Reporting",
        customerText: "Reporting",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a0",
    label: "Notes & attachments",
    customerText: "Notes and attachments",
    type: "select",
    options: [
      {
        id: "0",
        label: "Notes",
        customerText: "Notes",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Attachments",
        customerText: "Attachments",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a1",
    label: "Requests & assessments",
    customerText: "Requests and assessments",
    type: "select",
    options: [
      {
        id: "0",
        label: "Requests",
        customerText: "Requests",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Assessments",
        customerText: "Assessments",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Images on requests",
        customerText: "Images on requests",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr8a2",
    label: "Expense tracking",
    customerText: "Expense tracking",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr8a3",
    label: "Home dashboards",
    customerText: "Home dashboards",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr8a4",
    label: "Online booking",
    customerText: "Online booking",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr8a5",
    label: "Contracts & service agreements",
    customerText: "Contracts and service agreements",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr8a6",
    label: "Inventory & assets",
    customerText: "Inventory and assets",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr8a7",
    label: "Chemical tracking",
    customerText: "Chemical tracking",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr7a",
    label: "Team management, time tracking, & routing",
    customerText: "Team management, time tracking, and routing",
    type: "select",
    options: [
      {
        id: "0",
        label: "Team management & permissions",
        customerText: "Team management and permissions",
        nextQuestion: "cfqfr7a0",
      },
      {
        id: "1",
        label: "Maps, routing, & GPS",
        customerText: "Maps, routing, and GPS",
        nextQuestion: "cfqfr7a1",
      },
      {
        id: "2",
        label: "Subcontractors & contractors",
        customerText: "Subcontractors and contractors",
        nextQuestion: "cfqfr7a2",
      },
      {
        id: "3",
        label: "Timers & timesheets",
        customerText: "Timers and timesheets",
        nextQuestion: "cfqfr7a3",
      },
      {
        id: "4",
        label: "Job forms",
        customerText: "Job forms",
        nextQuestion: "cfqfr7a4",
      },
    ],
  },
  {
    id: "cfqfr7a4",
    label: "Job forms",
    customerText: "Job forms",
    type: "select",
    options: [
      {
        id: "0",
        label: "Doesn't match workflow",
        customerText: "Doesn't match our current workflow",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Reporting",
        customerText: "Reporting",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr7a3",
    label: "Timers & timesheets",
    customerText: "Timers and timesheets",
    type: "select",
    options: [
      {
        id: "0",
        label: "Timesheets",
        customerText: "Timesheets",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Timers",
        customerText: "Timers",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Billable hours",
        customerText: "Billable hours",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Bugs",
        customerText: "Bugs",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr7a2",
    label: "Subcontractors & contractors",
    customerText: "Subcontractors and contractors",
    type: "select",
    options: [
      {
        id: "1",
        label: "Doesn't match workflow",
        customerText: "Doesn't match our current workflow",
        nextQuestion: "cfq2",
      },
      {
        id: "0",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr7a1",
    label: "Maps, routing, & GPS",
    customerText: "Maps, routing, and GPS",
    type: "select",
    options: [
      {
        id: "0",
        label: "Route optimization and map",
        customerText: "Route optimization and map",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "GPS waypoints",
        customerText: "GPS waypoints",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Geofencing",
        customerText: "Geofencing",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Fleet tracking",
        customerText: "Fleet tracking",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a1a",
      },
    ],
  },
  {
    id: "cfqfr7a1a",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr7a0",
    label: "Team management & permissions",
    customerText: "Team management and permissions",
    type: "select",
    options: [
      {
        id: "0",
        label: "Employees see too much info",
        customerText: "Employees see too much info",
        nextQuestion: "cfqfr7a0a",
      },
      {
        id: "1",
        label: "Too confusing",
        customerText: "Too confusing",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr7a0b",
      },
    ],
  },
  {
    id: "cfqfr7a0a",
    label: "What info were they unable to hide?",
    customerText: "What info were you unable to hide?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr7a0b",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr7a1",
    label: "Maps, routing, & GPS",
    customerText: "Maps, routing, and GPS",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr7a2",
    label: "Subcontractors & contractors",
    customerText: "Subcontractors and contractors",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr7a3",
    label: "Timers & timesheets",
    customerText: "Timers and timesheets",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr7a4",
    label: "Job forms",
    customerText: "Job forms",
    type: "select",
    options: [],
  },
  {
    id: "cfqfr6a",
    label: "Integrations",
    customerText: "Integrations",
    type: "select",
    options: [
      {
        id: "0",
        label: "API",
        customerText: "API",
        nextQuestion: "cfqfr6a0",
      },
      {
        id: "1",
        label: "Missing integration",
        customerText: "Missing integration",
        nextQuestion: "cfqfr6a1",
      },
      {
        id: "2",
        label: "Marketplace apps: Thumbtack",
        customerText: "Thumbtack",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Google local services ads",
        customerText: "Google local services ads",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Marketplace apps: Companycam",
        customerText: "Companycam",
        nextQuestion: "cfq2",
      },
      {
        id: "5",
        label: "QuickBooks sync",
        customerText: "QuickBooks sync",
        nextQuestion: "cfqfr6a1",
      },
      {
        id: "6",
        label: "Xero sync",
        customerText: "Xero sync",
        nextQuestion: "cfqfr6a2",
      },
    ],
  },
  {
    id: "cfqfr6a0",
    label: "What would they use it for?",
    customerText: "What would you use it for?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a1",
    label: "What are they looking for?",
    customerText: "What are you looking for?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a2",
    label: "Xero sync",
    customerText: "Xero sync",
    type: "select",
    options: [
      {
        id: "0",
        label: "No Xero sync",
        customerText: "No Xero sync",
        nextQuestion: "cfqfr6a2a",
      },
      {
        id: "1",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr6a2b",
      },
    ],
  },
  {
    id: "cfqfr6a2a",
    type: "text",
    label: "Would they come back if we had it?",
    customerText: "Would you come back if we had it?",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a2b",
    type: "text",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a1",
    label: "QuickBooks sync",
    customerText: "QuickBooks sync",
    type: "select",
    options: [
      {
        id: "0",
        label: "No QBD sync",
        customerText: "No sync with QuickBooks Desktop",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "No two-way sync",
        customerText: "No two-way sync",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Needs to connect to multiple accounts",
        customerText: "We need to connect to multiple accounts",
        nextQuestion: "cfqfr6a1a",
      },
      {
        id: "3",
        label: "Sync issues",
        customerText: "Sync issues",
        nextQuestion: "cfqfr6a1b",
      },
      {
        id: "4",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr6a1c",
      },
    ],
  },
  {
    id: "cfqfr6a1a",
    label: "What are the multiple accounts?",
    customerText: "What are the multiple accounts?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a1b",
    label: "What kind of issues?",
    customerText: "What kind of issues?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr6a1c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr5a",
    label: "Mobile App",
    customerText: "Mobile App",
    type: "select",
    options: [
      {
        id: "0",
        label: "Push notifications",
        customerText: "Push notifications",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Offline functionality",
        customerText: "Offline functionality",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Mobile scheduling",
        customerText: "Mobile scheduling",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Performance bugs",
        customerText: "Performance bugs",
        nextQuestion: "cfqfr5a4a",
      },
      {
        id: "4",
        label: "Can't run business through the app",
        customerText: "Can't run business through the app",
        nextQuestion: "cfqfr5a4a4",
      },
      {
        id: "5",
        label:
          "Features missing compared to online version (branding, user management, reporting, etc.)",
        customerText:
          "Features missing compared to online version (branding, user management, reporting, etc.)",
        nextQuestion: "cfq2",
      },
      {
        id: "6",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr5a7c",
      },
    ],
  },
  {
    id: "cfqfr5a4a4",
    label: "Can they be more specific?",
    customerText: "Can you provide us with more details?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr5a7c",
    label: "How would you categorize the issue?",
    customerText: "How would you categorize the issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr5a4a",
    label: "Performance bugs",
    customerText: "Performance bugs",
    type: "select",
    options: [
      {
        id: "0",
        label: "Outage",
        customerText: "Outage",
        nextQuestion: "cfq1bc",
      },
      {
        id: "1",
        label: "Bugs",
        customerText: "Bugs",
        nextQuestion: "cfq1bc",
      },
      {
        id: "2",
        label: "General performance",
        customerText: "General performance",
        nextQuestion: "cfq1bc",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr5a4a4c",
      },
    ],
  },
  {
    id: "cfqfr5a4a4c",
    label: "How would you categorize the issue?",
    customerText: "How would you categorize the issue?",
    type: "text",
    nextQuestion: "cfq1bc",
  },
  {
    id: "cfqfr4a",
    label: "Payments",
    customerText: "Payments",
    type: "select",
    options: [
      {
        id: "0",
        label: "Automatic payments",
        customerText: "Automatic payments",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Card reader",
        customerText: "Card reader",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Jobber payments",
        customerText: "Jobber payments",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Lump sum payments",
        customerText: "Lump sum payments",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Partial payments",
        customerText: "Partial payments",
        nextQuestion: "cfq2",
      },
      {
        id: "5",
        label: "Pre-payments",
        customerText: "Pre-payments",
        nextQuestion: "cfq2",
      },
      {
        id: "6",
        label: "Refunds and credits",
        customerText: "Refunds and credits",
        nextQuestion: "cfq2",
      },
      {
        id: "7",
        label: "Tips",
        customerText: "Tips",
        nextQuestion: "cfq2",
      },
      {
        id: "8",
        label: "ACH",
        customerText: "ACH",
        nextQuestion: "cfq2",
      },
      {
        id: "9",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr4a10c",
      },
    ],
  },
  {
    id: "cfqfr4a10",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr3a",
    label: "Invoicing & quoting",
    customerText: "Invoicing and quoting",
    type: "select",
    options: [
      {
        id: "0",
        label: "Invoicing",
        customerText: "Invoicing",
        nextQuestion: "cfqfr3a1a",
      },
      {
        id: "1",
        label: "Line items",
        customerText: "Line items",
        nextQuestion: "cfqfr3a2a",
      },
      {
        id: "2",
        label: "Quoting",
        customerText: "Quoting",
        nextQuestion: "cfqfr3a3a",
      },
    ],
  },
  {
    id: "cfqfr3a1a",
    label: "Invoicing",
    customerText: "Invoicing",
    type: "select",
    options: [
      {
        id: "0",
        label: "Automatic invoicing",
        customerText: "Automatic invoicing",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Batch invoicing",
        customerText: "Batch invoicing",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Overdue invoice reminders",
        customerText: "Overdue invoice reminders",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "PDF customization",
        customerText: "PDF customization",
        nextQuestion: "cfqfr3a1a4c",
      },
      {
        id: "4",
        label: "Taxes",
        customerText: "Taxes",
        nextQuestion: "cfq2",
      },
      {
        id: "5",
        label: "Progress billing",
        customerText: "Progress billing",
        nextQuestion: "cfq2",
      },
      {
        id: "6",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr3a1a7c",
      },
    ],
  },
  {
    id: "cfqfr3a1a4c",
    type: "text",
    label: "What do they want to be able to customize?",
    customerText: "What do you want to be able to customize?",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr3a1a7c",
    type: "text",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr3a2a",
    label: "Line items",
    customerText: "Line items",
    type: "select",
    options: [
      {
        id: "0",
        label: "Products & services import",
        customerText: "Products and services import",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Pricing & cost",
        customerText: "Pricing and cost",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Inconsistent functionality",
        customerText: "Inconsistent functionality",
        nextQuestion: "cfqfr2a2a3c",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr2a2a4c",
      },
    ],
  },
  {
    id: "cfqfr2a2a3c",
    label: "What was inconsistent?",
    customerText: "What was inconsistent?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a2a4c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr3a3a",
    label: "Quoting",
    customerText: "Quoting",
    type: "select",
    options: [
      {
        id: "0",
        label: "Advanced quoting",
        customerText: "Advanced quoting",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Quote markups",
        customerText: "Quote markups",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "PDF customization",
        customerText: "PDF customization",
        nextQuestion: "cfqfr3a3a3c",
      },
      {
        id: "3",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr3a3a4c",
      },
    ],
  },
  {
    id: "cfqfr3a3a3c",
    label: "What do they want to be able to customize?",
    customerText: "What do you want to be able to customize?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr3a3a4c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a",
    label: "Client management & customer communication",
    customerText: "Client management and customer communication",
    type: "select",
    options: [
      {
        id: "0",
        label: "Client management",
        customerText: "Client management",
        nextQuestion: "cfqfr2a1a",
      },
      {
        id: "1",
        label: "Customer communications",
        customerText: "Customer communications",
        nextQuestion: "cfqfr2a2a",
      },
      {
        id: "2",
        label: "Client hub",
        customerText: "Client hub",
        nextQuestion: "cfqfr2a3a",
      },
      {
        id: "3",
        label: "Marketing tools",
        customerText: "Marketing tools",
        nextQuestion: "cfqfr2a4a",
      },
    ],
  },
  {
    id: "cfqfr2a1a",
    label: "Client management",
    customerText: "Client management",
    type: "select",
    options: [
      {
        id: "0",
        label: "Client import",
        customerText: "Client import",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Lead management",
        customerText: "Lead management",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Client-property relationship doesn't make sense",
        customerText:
          "Client-property relationship doesn't work for our business",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Confusing contact info",
        customerText: "Confusing contact info",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr2a1a5c",
      },
    ],
  },
  {
    id: "cfqfr2a1a5c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a2a",
    label: "Customer communications",
    customerText: "Customer communications",
    type: "select",
    options: [
      {
        id: "0",
        label: "Client reminders",
        customerText: "Client reminders",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Delivery issues",
        customerText: "Delivery issues",
        nextQuestion: "cfqfr2a2a2c",
      },
      {
        id: "2",
        label: "Communications-voice",
        customerText: "Voice communication",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Follow-ups",
        customerText: "Follow-ups",
        nextQuestion: "cfq2",
      },
      {
        id: "4",
        label: "Open texting",
        customerText: "Open texting",
        nextQuestion: "cfq2",
      },
      {
        id: "5",
        label: "SMS",
        customerText: "SMS",
        nextQuestion: "cfq2",
      },
      {
        id: "6",
        label: "Visit confirmations",
        customerText: "Visit confirmations",
        nextQuestion: "cfq2",
      },
      {
        id: "7",
        label: "Branding",
        customerText: "Branding",
        nextQuestion: "cfq2",
      },
      {
        id: "8",
        label: "Non-SMS serviced country",
        customerText: "SMS messages are not supported in our country",
        nextQuestion: "cfq2",
      },
    ],
  },
  {
    id: "cfqfr2a2a2c",
    label: "What wasn't delivered?",
    customerText: "What wasn't delivered?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a3a",
    label: "Client hub",
    customerText: "Client hub",
    type: "select",
    options: [
      {
        id: "0",
        label: "Branding",
        customerText: "Branding",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr2a3a2c",
      },
    ],
  },
  {
    id: "cfqfr2a4a",
    label: "Marketing tools",
    customerText: "Marketing tools",
    type: "select",
    options: [
      {
        id: "0",
        label: "SC Referrals",
        customerText: "Client referrals",
        nextQuestion: "cfq2",
      },
      {
        id: "1",
        label: "SC Reviews",
        customerText: "Client reviews",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Marketing automation",
        customerText: "Marketing automation",
        nextQuestion: "cfq2",
      },
      {
        id: "3",
        label: "Mass communication",
        customerText: "Mass communication",
        nextQuestion: "cfqfr2a4a4c",
      },
      {
        id: "4",
        label: "Other",
        customerText: "Other",
        nextQuestion: "cfqfr2a4a5c",
      },
    ],
  },
  {
    id: "cfqfr2a4a4c",
    label: "What do they want this for?",
    customerText: "What do you want to be able to do with this?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a4a5c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr2a3a2c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a",
    label: "Calendar & scheduling",
    customerText: "Calendar and scheduling",
    type: "select",
    options: [
      {
        id: "0",
        label: "Calendar sync",
        customerText: "Calendar sync",
        nextQuestion: "cfqfr1a1c",
      },
      {
        id: "1",
        label: "Outgrew us",
        customerText: "We have more visits than the calendar can handle",
        nextQuestion: "cfq2",
      },
      {
        id: "2",
        label: "Project management",
        customerText: "Project management",
        nextQuestion: "cfqfr1a3c",
      },
      {
        id: "3",
        label: "Initial setup",
        customerText: "Initial setup",
        nextQuestion: "cfqfr1a4c",
      },
      {
        id: "4",
        label: "Calendar missing important info",
        customerText: "Calendar missing important info",
        nextQuestion: "cfqfr1a5c",
      },
      {
        id: "5",
        label: "Availability & finding open spots",
        customerText: "Availability and finding open spots",
        nextQuestion: "cfq2",
      },
      {
        id: "6",
        label: "Doesn't match SP workflow",
        customerText: "Doesn't match our current workflow",
        nextQuestion: "cfqfr1a7c",
      },
      {
        id: "7",
        label: "Job creation",
        customerText: "Job creation",
        nextQuestion: "cfq2",
      },
      {
        id: "8",
        label: "Limitations in scheduling, viewing, and grouping team members",
        customerText:
          "Limitations in scheduling, viewing, and grouping team members",
        nextQuestion: "cfqfr1a9c",
      },
      {
        id: "9",
        label: "Jobs & visits",
        customerText: "Jobs and visits",
        nextQuestion: "cfq2",
      },
      {
        id: "10",
        label: "Other",
        customerText: "Other",
        nextQuestion: "ccfqfr1a11c",
      },
    ],
  },
  {
    id: "cfqfr1a1c",
    label: "What was the specific issue with the sync?",
    customerText: "What was the specific issue with the sync?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a3c",
    label: "What functionality was missing?",
    customerText: "What functionality was missing?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a4c",
    label: "What was hard about setting it up?",
    customerText: "What was hard about setting it up?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a5c",
    label: "What info/context are they missing on the calendar?",
    customerText: "What info or context are you missing on the calendar?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a7c",
    label: "What specifically didn't match?",
    customerText: "What specifically didn't match?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a9c",
    label: "What did they need?",
    customerText: "What do you need to be able to do or see?",
    type: "text",
    nextQuestion: "cfq2",
  },
  {
    id: "cfqfr1a11c",
    label: "How would you categorize this issue?",
    customerText: "How would you categorize this issue?",
    type: "text",
    nextQuestion: "cfq2",
  },
];
