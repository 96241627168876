import React from "react";
import { Page } from "@jobber/components/Page";
import styles from "./styles.module.css";
import { ListTable } from "./components/ListTable";

export function IndexPage() {
  return (
    <Page
      title="Jobs"
      width="fill"
      primaryAction={{
        label: "New Job",
      }}
      moreActionsMenu={[
        {
          actions: [
            {
              label: "Manage Job Forms",
              icon: "jobForms",
            },
          ],
        },
      ]}
    >
      <div className={styles.gridContainer}>
        <div>
          <ListTable />
        </div>
        <div>Sidebar</div>
      </div>
    </Page>
  );
}
