import { useMutation } from "@apollo/client";
import {
  DELETE_CONVERSATION_MUTATION,
  UNREAD_CONVERSATION_MUTATION,
} from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";

export const useMarkConversationHook = () => {
  const [markConversationUnread] = useMutation(UNREAD_CONVERSATION_MUTATION);
  const [markConversationDeleted] = useMutation(DELETE_CONVERSATION_MUTATION);

  return [markConversationUnread, markConversationDeleted];
};
