import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

type UseState<S> = [S, Dispatch<SetStateAction<S>>];
type UseStateWithLocalStorage<S> = [S, Dispatch<SetStateAction<S>>, boolean];

export function useStateWithLocalStorage<S>(
  localStorageKey: string,
  defaultValue: S,
  errorHandler: (e: Error) => S,
  storage?: Storage, // if no value supplied, localStorage is used
): UseStateWithLocalStorage<S> {
  function isAvailable() {
    try {
      const x = "__storage_test__";
      const thisStorage = storage || localStorage;
      thisStorage.setItem(x, x);
      thisStorage.removeItem(x);
      return true;
    } catch {
      return false;
    }
  }

  function storageUnavailable(): UseStateWithLocalStorage<S> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [v, s]: UseState<S> = useState(defaultValue);
    return [v, s, false];
  }

  function storageAvailable(): UseStateWithLocalStorage<S> {
    const thisStorage = storage || localStorage;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue]: UseState<S> = useState(() => {
      const initialValue = thisStorage.getItem(localStorageKey);
      // localStorage returns null (not undefined) if localStorageKey is unknown
      if (initialValue !== null) {
        try {
          return JSON.parse(initialValue);
        } catch (e) {
          return errorHandler(e as Error);
        }
      }
      return defaultValue;
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      thisStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value]);
    return [value, setValue, true];
  }

  return isAvailable() ? storageAvailable() : storageUnavailable();
}
