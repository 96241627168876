// eslint-disable-next-line import/no-named-as-default
import type Quill from "quill";
import { useCallback, useRef } from "react";
import { sanitize } from "dompurify";
// eslint-disable-next-line no-restricted-imports
import type { FieldActionsRef } from "@jobber/components/FormField";
import type {
  QuillSelectionChangeHandler,
  QuillTextChangeHandler,
} from "../../types";

interface QuillEventProps {
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (x: string, initialLoad: boolean) => void;
  quillInstance: React.MutableRefObject<Quill | null>;
  lastKnownPosition: React.MutableRefObject<number | null>;
  initialValueLoaded: React.MutableRefObject<boolean | null>;
}

export function useQuillEvents({
  onFocus,
  onBlur,
  onChange,
  quillInstance,
  lastKnownPosition,
  initialValueLoaded,
}: QuillEventProps) {
  const formFieldHiddenInputRef = useRef<HTMLInputElement>(null);
  const formFieldActionsRef = useRef<FieldActionsRef>(null);

  const onSelectionChange: QuillSelectionChangeHandler = useCallback(
    (range: unknown, oldRange: { index: number | null | undefined }) => {
      if (!range) {
        if (oldRange?.index !== undefined) {
          lastKnownPosition.current = oldRange.index;
        }
        onBlur?.();
        formFieldHiddenInputRef.current?.blur();
      } else if (!oldRange) {
        onFocus?.();
      }
    },
    [onBlur, lastKnownPosition, onFocus],
  );

  const onTextChange: QuillTextChangeHandler = () => {
    const unsanitizedContent = quillInstance.current?.root.innerHTML || "";
    const actualText = quillInstance.current?.getText()?.trim();
    formFieldActionsRef.current?.setValue(actualText || "");
    onChange(sanitize(unsanitizedContent), !!initialValueLoaded.current);
  };

  return {
    onSelectionChange,
    onTextChange,
    formFieldActionsRef,
    formFieldHiddenInputRef,
  };
}
