interface S3ThumbnailUrlResult {
  thumbnail: string;
}
export interface ResponseData {
  signedUrl: string;
  s3Key: string;
}

export interface Dimensions {
  height?: number;
  width?: number;
  fit: string;
}

export const DefaultThumbDimensions: Dimensions = {
  height: 85,
  width: 135,
  fit: "inside",
};

export const DefaultFullDimensions: Dimensions = {
  fit: "inside",
};

export function fetchS3ThumbnailUrl(
  s3Key: string,
  s3ThumbnailUrl: string,
  setThumbnail: (thumbnail: string) => void,
  dimensions?: Dimensions,
  setUploading?: (uploading: boolean) => void,
) {
  const fetchUrl = `${s3ThumbnailUrl}?filepath=${s3Key}&dimensions=${JSON.stringify(
    dimensions,
  )}`;

  fetch(fetchUrl)
    .then((response: Response) => {
      response
        .json()
        .then((thumbnailResult: S3ThumbnailUrlResult) => {
          setThumbnail(thumbnailResult.thumbnail);
          if (setUploading) {
            setUploading(false);
          }
        })
        .catch(() => {
          throw new Error("Failed to process thumbnail result");
        });
    })
    .catch(() => {
      throw new Error("Failed to fetch the URL");
    });
}

export function fetchSignedUrl(
  file: File,
  callback: (data: ResponseData) => void,
  signingUrl: string,
  setS3Key: (key: string) => void,
) {
  const params = new URLSearchParams({
    objectName: scrubFilename(file.name),
    contentType: file.type,
  });

  const url = `${signingUrl}?${params}`;

  fetch(url)
    .then((response: Response) => {
      response
        .json()
        .then((data: ResponseData) => {
          setS3Key(data.s3Key);
          callback(data);
        })
        .catch(() => {
          throw new Error("Failed to process file attachment upload");
        });
    })
    .catch(() => {
      throw new Error("Failed to get signedURL");
    });
}

export function scrubFilename(filename: string) {
  return filename.replace(/[^\w\d_\-.]+/gi, "");
}
