import type FormContext from "jobber/payments/FormContext";
// eslint-disable-next-line import/no-internal-modules
import { AuthorizeNetProvider } from "~/shared/payments/providers/AuthorizeNetProvider/AuthorizeNetProvider";
import { DirectProvider, SquareWebPaymentProvider, StripeProvider } from ".";

/**
 * Creates a `TokenProvider` based on the `provider`
 * value in the form config.
 *
 * @remarks
 * `StripeProvider` have side effects
 * because they initialize hosted fields from their constructors.
 *
 * @export
 * @param formContext a reference to the form for fetching values
 * @returns a token provider for the configured provider
 */
export function mountProvider(formContext: FormContext) {
  const params = formContext.params;

  switch (params.provider) {
    case "stripe":
      return new StripeProvider(formContext);
    case "square":
      return new SquareWebPaymentProvider(formContext);
    case "authorize_net":
      return new AuthorizeNetProvider();
    default:
      return new DirectProvider(formContext);
  }
}
