import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expenseModalEdit: {
    id: "workOrders.jobCost.expensesTableTitle.expenseModalEdit",
    defaultMessage: "Edit Expense",
    description: "Title for the edit expense modal",
  },
  expenseTableEmptyState: {
    id: "workOrders.jobCost.expensesTableEmptyState.emptyState",
    defaultMessage:
      "Get an accurate picture of various job costs by recording expenses",
    description: "Default message for empty state of the expenses table",
  },
  expenseTableFooter: {
    id: "workOrders.jobCost.expensesTableFooter.footer",
    defaultMessage: "Total Amount",
    description: "Footer for the expenses table",
  },
  newExpense: {
    id: "workOrders.jobCost.expensesTableTitle.newExpense",
    defaultMessage: "New Expense",
    description: "Title for the new expense modal",
  },

  // Expense table column headers
  expensesTableItemColumnHeader: {
    id: "workOrders.jobCost.expensesTableItemColumnHeader",
    defaultMessage: "Item",
    description: "Item column header for the expenses table",
  },
  expensesTableDescriptionColumnHeader: {
    id: "workOrders.jobCost.expensesTableDescriptionColumnHeader",
    defaultMessage: "Description",
    description: "Description column header for the expenses table",
  },
  expensesTableDateColumnHeader: {
    id: "workOrders.jobCost.expensesTableDateColumnHeader",
    defaultMessage: "Date",
    description: "Date column header for the expenses table",
  },
  expensesTableAmountColumnHeader: {
    id: "workOrders.jobCost.expensesTableAmountColumnHeader",
    defaultMessage: "Amount",
    description: "Amount column header for the expenses table",
  },
});
