import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bankAccount: {
    id: "clientHubJobberPayments.paymentMethodChips.bankAccount",
    defaultMessage: "Bank (ACH)",
    description: "Bank account message",
  },
  creditCard: {
    id: "clientHubJobberPayments.paymentMethodChips.creditCard",
    defaultMessage: "Credit/Debit",
    description: "Credit/Card card message",
  },
  applePay: {
    id: "clientHubJobberPayments.paymentMethodChips.applePay",
    defaultMessage: "Apple Pay",
    description: "Apple Pay payment method type",
  },
  googlePay: {
    id: "clientHubJobberPayments.paymentMethodChips.googlePay",
    defaultMessage: "Google Pay",
    description: "Google Pay payment method type",
  },
});
