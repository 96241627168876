import moment from "moment";
import { useMemo } from "react";
import { formatTime } from "./utils";
import { getArrivalWindowStartTime } from "../../ArrivalWindowRange";

export function useGetArrivalWindowRange(params: {
  duration: number;
  centered: boolean;
  jobStartTime: Date | undefined;
}) {
  const arrivalWindowStartTime = useMemo(
    () =>
      getArrivalWindowStartTime({
        centered: params.centered,
        duration: +params.duration,
        jobStartTime: params.jobStartTime || new Date(),
      }) || new Date(),
    [params.centered, params.duration, params.jobStartTime],
  );

  const arrivalWindowEndTime = useMemo(
    () => moment(arrivalWindowStartTime).add(+params.duration, "m").toDate(),
    [arrivalWindowStartTime, params.duration],
  );

  return useMemo(() => {
    if (
      formatTime(arrivalWindowStartTime) === formatTime(arrivalWindowEndTime)
    ) {
      return formatTime(arrivalWindowStartTime);
    }

    return `${formatTime(arrivalWindowStartTime)} – ${formatTime(
      arrivalWindowEndTime,
    )}`;
  }, [arrivalWindowStartTime, arrivalWindowEndTime]);
}
