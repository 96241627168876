import { Option, Select } from "@jobber/components/Select";
import React from "react";
import type { JobberOnline } from "jobber/payments/utils";
import { InputLabel } from "./InputLabel";

type PaymentTypeId = JobberOnline.constants.balanceAdjustments.paymentTypes;

type GroupName = string;
type PaymentTypeName = string;
export type GroupedPaymentTypes = [
  GroupName,
  [PaymentTypeName, PaymentTypeId][],
][];

interface PaymentTypeSelectorProps {
  groupedPaymentTypes: GroupedPaymentTypes;

  name?: string;
  selectedType?: PaymentTypeName;
  disabled?: boolean;

  onPaymentTypeChange?(paymentType: string): void;
}

export function PaymentTypeSelector(props: PaymentTypeSelectorProps) {
  const {
    name,
    groupedPaymentTypes,
    selectedType,
    disabled,
    onPaymentTypeChange,
  } = props;

  return (
    <InputLabel label="Method">
      <Select
        name={name}
        value={selectedType}
        onChange={onPaymentTypeChange}
        disabled={disabled}
      >
        {groupedPaymentTypes.map(([groupName, types]) => (
          <optgroup key={groupName} label={groupName}>
            {types.map(([displayName, id]) => (
              <Option key={displayName} value={id.toString()}>
                {displayName}
              </Option>
            ))}
          </optgroup>
        ))}
      </Select>
    </InputLabel>
  );
}
