import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { ArrivalWindowPreview } from "./ArrivalWindowPreview";
import type { JobScheduleType } from "./types";

interface ArrivalWindowPreviewProviderProps {
  jobScheduleType: JobScheduleType;
  existingJobArrivalWindow: number | null;
  existingJobArrivalWindowCentered: boolean | null;
}

export function ArrivalWindowPreviewProvider({
  jobScheduleType,
  existingJobArrivalWindow,
  existingJobArrivalWindowCentered,
}: ArrivalWindowPreviewProviderProps) {
  return (
    <APIProvider>
      <ArrivalWindowPreview
        existingJobArrivalWindow={existingJobArrivalWindow}
        jobScheduleType={jobScheduleType}
        existingJobArrivalWindowCentered={existingJobArrivalWindowCentered}
      />
    </APIProvider>
  );
}
