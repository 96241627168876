import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Checkbox } from "@jobber/components/Checkbox";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import type {
  CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueCountsFragment,
  CustomFieldsValueCountQuery,
  CustomFieldsValueCountQueryVariables,
} from "~/utilities/API/graphql";
import { BulletedItems } from "jobber/settings/selfServeBookings/components";
import { CUSTOM_FIELD_CONFIGURATION_VALUE_COUNTS } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";
import styles from "./CustomFieldDeleteConfirmationModal.module.css";
import { messages } from "./messages";

function formatValueCount(
  configurationDetails: CustomFieldConfigurationValueCountsFragment,
) {
  const valueCountData = configurationDetails?.valueCount;

  const entries = Object.entries(valueCountData).filter(([, value]) => {
    const v = value as number;
    return v > 0;
  });

  return entries.map(([title, value]) => {
    const singularWorkObjects: Record<string, string> = {
      clients: "Client",
      invoices: "Invoice",
      jobs: "Job",
      productsAndServices: "Product or Service",
      properties: "Property",
      quotes: "Quote",
      users: "User",
    };

    const pluralWorkObjects: Record<string, string> = {
      clients: "Clients",
      invoices: "Invoices",
      jobs: "Jobs",
      productsAndServices: "Products and Services",
      properties: "Properties",
      quotes: "Quotes",
      users: "Users",
    };

    const formattedTitle =
      value === 1 ? singularWorkObjects[title] : pluralWorkObjects[title];

    return `${value} ${formattedTitle}`;
  });
}

function hasAssociatedWorkObjects(
  configurationDetails: CustomFieldConfigurationValueCountsFragment | undefined,
) {
  const valueCountData = configurationDetails?.valueCount;

  if (valueCountData === undefined) {
    return false;
  }

  return Object.values(valueCountData).some(value => {
    const v = value as number;
    return v > 0;
  });
}

interface CustomFieldDeleteConfirmationModalProps {
  customFieldConfiguration: CustomFieldConfigurationNodeFragment | undefined;
  confirmDeleteModalOpen: boolean;
  userConfirmDelete: boolean;
  closeConfirmDeleteModal(): void;
  handleDeleteCustomField(): void;
  setUserConfirmDelete: Dispatch<SetStateAction<boolean>>;
}

export function CustomFieldDeleteConfirmationModal({
  customFieldConfiguration,
  confirmDeleteModalOpen,
  userConfirmDelete,
  closeConfirmDeleteModal,
  handleDeleteCustomField,
  setUserConfirmDelete,
}: CustomFieldDeleteConfirmationModalProps) {
  const { formatMessage } = useIntl();
  const [configurationDetails, setConfugurationDetails] = useState<
    CustomFieldConfigurationValueCountsFragment | undefined
  >(undefined);

  const { loading, refetch } = useQuery<
    CustomFieldsValueCountQuery,
    CustomFieldsValueCountQueryVariables
  >(CUSTOM_FIELD_CONFIGURATION_VALUE_COUNTS, {
    variables: {
      valueType: customFieldConfiguration?.valueType,
      appliesTo: customFieldConfiguration?.appliesTo,
    },
    skip: customFieldConfiguration === undefined,
    onCompleted: data => {
      const customFieldConfigurationEdges =
        data?.customFieldConfigurations?.nodes || [];
      const configuration = customFieldConfigurationEdges.find(
        node => node?.id === customFieldConfiguration?.id,
      );
      setConfugurationDetails(configuration);
    },
  });

  useEffect(() => {
    async function fetch() {
      if (!loading) {
        await refetch();
      }
    }

    void fetch();
  }, [customFieldConfiguration, loading, refetch]);

  useEffect(() => {
    if (
      configurationDetails &&
      !hasAssociatedWorkObjects(configurationDetails)
    ) {
      setUserConfirmDelete(true);
    }
  }, [configurationDetails, setUserConfirmDelete]);

  return (
    <Modal
      title={formatMessage(messages.title, {
        customFieldName: customFieldConfiguration?.name,
      })}
      open={confirmDeleteModalOpen}
      onRequestClose={closeConfirmDeleteModal}
      primaryAction={{
        label: "Delete",
        onClick: handleDeleteCustomField,
        loading: false,
        variation: "destructive",
        disabled: !userConfirmDelete,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: closeConfirmDeleteModal,
        variation: "subtle",
        type: "primary",
      }}
    >
      <Content>
        <Content>
          {formatMessage(messages.description, {
            customFieldName: customFieldConfiguration?.name,
          })}
        </Content>
        {!loading &&
          configurationDetails !== undefined &&
          hasAssociatedWorkObjects(configurationDetails) && (
            <>
              <Text>{formatMessage(messages.disclaimer)}</Text>
              <div className={styles.confirmationChecklist}>
                {customFieldConfiguration && (
                  <BulletedItems
                    items={formatValueCount(configurationDetails)}
                  />
                )}
              </div>
              <Checkbox
                label={formatMessage(messages.confirmationCheck)}
                checked={userConfirmDelete}
                onChange={() => setUserConfirmDelete(!userConfirmDelete)}
              />
            </>
          )}
      </Content>
    </Modal>
  );
}
