import { useIntl } from "react-intl";
import { messages } from "./messages";

const MAX_DAILY_INSTANT_PAYOUT_AMOUNT = 10000;
const MIN_INSTANT_PAYOUT_AMOUNT = 50;

export function useButtonState(props: {
  debitCardLinked: boolean;
  instantAvailableAmount: string;
  instantPayoutRequestedLast24Hours: string;
  debitCardIsValid: boolean;
}) {
  const { formatMessage } = useIntl();
  const instantAvailableAmount = parseFloat(props.instantAvailableAmount);
  const instantPayoutRequestedLast24Hours = parseFloat(
    props.instantPayoutRequestedLast24Hours,
  );

  if (!props.debitCardLinked) {
    return {
      disabled: false,
      tooltip: undefined,
      showPopover: false,
    };
  }
  if (!props.debitCardIsValid) {
    return {
      disabled: true,
      tooltip: formatMessage(
        messages.jobberPaymentsDebitCardAwaitingValidationToolTip,
      ),
    };
  }
  if (instantPayoutRequestedLast24Hours >= MAX_DAILY_INSTANT_PAYOUT_AMOUNT) {
    return {
      disabled: true,
      tooltip: formatMessage(
        messages.jobberPaymentsInstantPayoutMaximumToolTip,
      ),
    };
  }
  if (instantAvailableAmount < MIN_INSTANT_PAYOUT_AMOUNT) {
    return {
      disabled: true,
      tooltip: formatMessage(
        messages.jobberPaymentsInstantPayoutMinimumToolTip,
      ),
    };
  }

  return {
    disabled: false,
    tooltip: undefined,
    showPopover: true,
  };
}
