import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { CardBrand } from "components/CardBrand";
import type { StoredPaymentDetails } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails";
import styles from "./PaymentDetailsDisplay.module.css";
import { messages } from "./messages";

interface PaymentDetailsDisplayProps {
  storedPaymentDetails: StoredPaymentDetails;
}

export function PaymentDetailsDisplay(props: PaymentDetailsDisplayProps) {
  const { formatMessage } = useIntl();

  const { storedPaymentDetails } = props;
  const { firstName, lastName, cardType, lastFour, expMonth, expYear } =
    storedPaymentDetails;

  return (
    <Content>
      <Grid>
        <Grid.Cell size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Label text={formatMessage(messages.nameLabel)} />
          <Label text={formatMessage(messages.cardLabel)} />
          <Label text={formatMessage(messages.expirationLabel)} />
        </Grid.Cell>
        <Grid.Cell size={{ xs: 6, sm: 6, md: 9, lg: 9, xl: 9 }}>
          <div className={styles.addressRow}>
            <Text>{`${firstName} ${lastName}`}</Text>
          </div>
          <div className={styles.addressRow}>
            <CardBrand brand={cardType?.toLowerCase()} size="small" />
            &nbsp;
            <Text>{`•••• ${lastFour}`}</Text>
          </div>
          <div className={styles.addressRow}>
            <Text>{`${expMonth}/${expYear}`}</Text>
          </div>
        </Grid.Cell>
      </Grid>
    </Content>
  );
}

function Label({ text }: { text: string }) {
  return (
    <div className={styles.addressRow}>
      <Text variation="subdued">{text}</Text>
    </div>
  );
}
