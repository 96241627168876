import { defineMessages } from "react-intl";

export const messages = defineMessages({
  comingSoon: {
    id: "campaigns.landingPage.comingSoon",
    defaultMessage: "Coming soon",
    description: "Label for Coming Soon Button",
  },
  chooseATemplate: {
    id: "campaigns.landingPage.chooseATemplate",
    defaultMessage: "Choose a template",
    description: "Label for Choose a template Button",
  },
  campaigns: {
    id: "campaigns.landingPage.campaigns",
    defaultMessage: "Campaigns",
    description: "Label for Campaigns Header",
  },
  campaignListTitle: {
    id: "campaigns.landingPage.campaignListTitle",
    defaultMessage: "All Campaigns",
    description: "Title for Campaigns DataList",
  },
  oneoffCampaignListTitle: {
    id: "campaigns.landingPage.oneoffCampaignListTitle",
    defaultMessage: "All one-off campaigns",
    description: "Title for Automated Campaigns DataList",
  },
  automatedCampaignListTitle: {
    id: "campaigns.landingPage.automatedCampaignListTitle",
    defaultMessage: "All automated campaigns",
    description: "Title for Automated Campaigns DataList",
  },
  newCampaignLabel: {
    id: "campaigns.landingPage.newCampaignLabel",
    defaultMessage: "New Campaign",
    description: "New Campaign Label",
  },
  noCampaignsMessage: {
    id: "campaigns.landingPage.noCampaignsMessage",
    defaultMessage: "No campaigns started yet",
    description: "No campaigns created message",
  },
  noFilteredCampaignsMessage: {
    id: "campaigns.landingPage.noFilteredCampaignsMessage",
    defaultMessage: "No campaigns for selected filters",
    description: "No campaigns found message",
  },
  clearFiltersMessage: {
    id: "campaigns.landingPage.clearFiltersMessage",
    defaultMessage: "Clear filters",
    description: "Clear DataList filters message",
  },
  campaign: {
    id: "campaigns.landingPage.campaign",
    defaultMessage: "Campaign",
    description: "Label for Campaign",
  },
  emailsDelivered: {
    id: "campaigns.landingPage.emailsDelivered",
    defaultMessage: "Emails delivered",
    description: "Label for Emails delivered",
  },
  openRate: {
    id: "campaigns.landingPage.openRate",
    defaultMessage: "Open Rate",
    description: "Label for Open Rate",
  },
  openRateTooltip: {
    id: "campaigns.landingPage.openRateTooltip",
    defaultMessage: "The percentage of recipients who opened your email",
    description: "Tooltip for Open Rate",
  },
  emailsDeliveredTooltip: {
    id: "campaigns.landingPage.emailsDeliveredTooltip",
    defaultMessage:
      "The number of emails that successfully reached recipients’ inboxes without bouncing or being marked as spam",
    description: "Tooltip for Open Rate",
  },
  clickRate: {
    id: "campaigns.landingPage.clickRate",
    defaultMessage: "Click Rate",
    description: "Label for Click Rate",
  },
  clickRateTooltip: {
    id: "campaigns.landingPage.clickRateTooltip",
    defaultMessage:
      "The percentage of recipients who clicked on a link in your email",
    description: "Tooltip for Click Rate",
  },
  revenue: {
    id: "campaigns.landingPage.revenue",
    defaultMessage: "Job Revenue",
    description: "Label for Revenue",
  },
  revenueTooltip: {
    id: "campaigns.landingPage.revenueTooltip",
    defaultMessage:
      "The total value of jobs created for recipients within 30 days of receiving this email",
    description: "Tooltip for Revenue",
  },
  status: {
    id: "campaigns.landingPage.status",
    defaultMessage: "Status",
    description: "Label for Status",
  },
  lastUpdated: {
    id: "campaigns.landingPage.lastUpdated",
    defaultMessage: "Last Updated",
    description: "Label for Last Updated",
  },
  searchCampaignsPlaceholder: {
    id: "campaigns.landingPage.searchCampaignsPlaceholder",
    defaultMessage: "Search campaigns...",
    description: "Placeholder for Campaigns Search Input",
  },
  filterByStatus: {
    id: "campaigns.landingPage.filterByStatus",
    defaultMessage: "Filter Status",
    description: "Status Filter Label",
  },
  percentOpen: {
    id: "campaigns.landingPage.percentOpen",
    defaultMessage: "% Opens",
    description: "Percent of campaigns opened on mobile",
  },
  percentClick: {
    id: "campaigns.landingPage.percentClick",
    defaultMessage: "% Clicks",
    description: "Percent of campaigns clicked on mobile",
  },
  desktopPercent: {
    id: "campaigns.landingPage.mobilePercent",
    defaultMessage: "%",
    description: "Percent of campaigns clicked or opened",
  },
  desktopDollar: {
    id: "campaigns.landingPage.desktopDollar",
    defaultMessage: `$\{amount, number, ::.00}`,
    description: "Dollar sign for money amount",
  },
  mobileRevenue: {
    id: "campaigns.landingPage.mobileRevenue",
    defaultMessage: " revenue",
    description: "Revenue for the campaigns on mobile",
  },
  revenueCountText: {
    id: "campaigns.landingPage.revenueCountText",
    defaultMessage: " jobs",
    description:
      "Jobs created for recipients within 30 days of receiving this email",
  },
  recipientsCountText: {
    id: "campaigns.landingPage.recipientsCountText",
    defaultMessage:
      "{recipientsCount, number, ::,###} {recipientsCount, plural, one {recipient} other {recipients}}",
    description: "Recipients count for open and click rate",
  },
  recipientsCountAndSegmentNameText: {
    id: "campaigns.landingPage.recipientsCountAndSegmentNameText",
    defaultMessage:
      "{recipientsCount, plural, =0 {} one {{recipientsCount, number, ::,###} recipient in } other {{recipientsCount, number, ::,###} recipients in }}{friendlyName}",
    description: "Recipients count for open and click rate",
  },
  listItemSent: {
    id: "campaigns.landingPage.listItemSent",
    defaultMessage: "Sent {date} at {time}",
    description: "Sent timestamp on mobile",
  },
  listItemEdit: {
    id: "campaigns.landingPage.listItemEdit",
    defaultMessage: "Edited {date} at {time}",
    description: "edit timestamp on mobile",
  },
  mobileSent: {
    id: "campaigns.landingPage.mobileSent",
    defaultMessage: "Sent {date}",
    description: "Sent timestamp on mobile",
  },
  mobileEdit: {
    id: "campaigns.landingPage.mobileEdit",
    defaultMessage: "Edited {date}",
    description: "edit timestamp on mobile",
  },
  delete: {
    id: "campaigns.landingPage.delete",
    defaultMessage: "Delete",
    description: "Delete Action Label",
  },
  edit: {
    id: "campaigns.landingPage.edit",
    defaultMessage: "Edit",
    description: "Edit Action Label",
  },
  reactivate: {
    id: "campaigns.landingPage.reactivate",
    defaultMessage: "Reactivate",
    description: "Reactivate Action Label",
  },
  deactivate: {
    id: "campaigns.landingPage.deactivate",
    defaultMessage: "Deactivate",
    description: "Deactivate Action Label",
  },
  pauseAndEdit: {
    id: "campaigns.landingPage.pauseAndEdit",
    defaultMessage: "Pause and edit",
    description: "Pause and Edit Action Label",
  },
  sendNow: {
    id: "campaigns.landingPage.sendNow",
    defaultMessage: "Send now",
    description: "Send Now Action Label",
  },
  actions: {
    id: "campaigns.landingPage.actions",
    defaultMessage: "Actions",
    description: "Action Button Aria Label",
  },
  errorMessage: {
    id: "campaigns.landingPage.errorMessage",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error message",
  },
  oneOffCampaignsTabTitle: {
    id: "campaigns.landingPage.oneOff",
    defaultMessage: "One-off",
    description: "One-off campaign type",
  },
  automatedCampaignsTabTitle: {
    id: "campaigns.landingPage.automated",
    defaultMessage: "Automated",
    description: "Automated campaign type",
  },
});
