import React from "react";
import { Text } from "@jobber/components/Text";
import { CardBrand } from "components/CardBrand";
import type { StoredPaymentDetails } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails";
import styles from "./PaymentPreviewDisplay.module.css";

interface PaymentPreviewDisplayProps {
  storedPaymentDetails: StoredPaymentDetails;
}

export function PaymentPreviewDisplay(props: PaymentPreviewDisplayProps) {
  const { cardType, lastFour } = props.storedPaymentDetails;

  return (
    <div className={styles.ccRow}>
      <CardBrand brand={cardType?.toLowerCase()} size="small" />
      &nbsp;
      {/* eslint-disable formatjs/no-literal-string-in-jsx */}
      <Text>{`•••• ${lastFour}`}</Text>
    </div>
  );
}
