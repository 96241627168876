import { defineMessages } from "react-intl";

export const messages = defineMessages({
  profitability: {
    id: "workOrders.jobCost.recurringJobProfitHeader.profitability",
    defaultMessage: "Past 30 days profitability",
    description: "Past 30 days profitability label",
  },
  profitMargin: {
    id: "workOrders.jobCost.recurringJobProfitHeader.profitMargin",
    defaultMessage: "30 day average profit margin",
    description: "Profit Margin label",
  },
});
