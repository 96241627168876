export const serviceAreasMock = {
  serviceAreas: {
    edges: [
      {
        node: {
          __typename: "GeoCircle" as const,
          id: "YmMzNWZmOWMtZWQwMy00Yjk2LThlNTUtN2IyZDkwMWZhNDcy",
          radius: 25,
          center: {
            latitude: 53.5419,
            longitude: -113.498,
          },
        },
      },
    ],
    totalCount: 1,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  },
};

export const serviceAreasMockNoServiceAreas = {
  serviceAreas: {
    edges: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  },
};

export const serviceAreasMockCompanyLocation = {
  countryCode: "CA",
  address: "123 Main St NW Smalltown Alberta T0T 0T0",
  coordinates: {
    latitude: 53.5419,
    longitude: -113.498,
  },
};
