import type {
  WorkObjectGlobalOwnerFragment,
  WorkObjectGlobalOwnershipFragment,
} from "~/utilities/API/graphql";

export interface MappingPermissionsStructure {
  request: string[];
  quote: string[];
  job: string[];
  invoice: string[];
}

export const emailReplySettings = `${window.location.origin}/communication_settings`;
// index is permission to see WO in the sidebar
// we don't need to take care of them at this moment
export const workObjects = [
  "work_requests",
  // "work_requests_index",
  "quotes",
  // "quotes_index",
  "work_orders",
  // "work_orders_index",
  "invoices",
  // "invoices_index",
];
// these permission codes come from app/graphql/graphql_schema/main/types/permission_level_enum.rb
export const downgradedPermissionsCodes = [
  "L00_NONE",
  "L05_LIMITED_READ",
  "L10_READ",
];

export const mappingPermissions: MappingPermissionsStructure = {
  request: ["work_requests", "work_requests_index"],
  quote: ["quotes", "quotes_index"],
  job: ["work_orders", "work_orders_index"],
  invoice: ["invoices", "invoices_index"],
};

// Gets ownerships that belong to the user
export function getUserOwnerships(
  globalOwnerships: WorkObjectGlobalOwnerFragment[],
  userId: number | string | undefined,
) {
  return (
    globalOwnerships
      .map((ownership: WorkObjectGlobalOwnerFragment) => {
        if (
          ownership.owner?.id !== undefined &&
          ownership.owner?.id === userId
        ) {
          return ownership.workObjectType.toLowerCase();
        }
      })
      // Have to cast here because map function will return undefined for
      // some elements of array. TS doesn't like that. At the end this
      // function will always return [];
      .filter((ownership: string) => ownership) as string[]
  );
}

// builds ownerships string in pluralize form
// if 1 ownerships -> requests
// if 2 ownerships -> requests and quotes
// if > 2 ownerships -> requests, quotes, and jobs
export function buildOwnershipsString(globalOwnerships: string[]): string {
  const pluralizedOwnerships = globalOwnerships.map(
    (ownership: string) => `${ownership}s`,
  );
  const ownershipsCount = pluralizedOwnerships.length;

  if (ownershipsCount >= 2) {
    const lastItem = pluralizedOwnerships.slice(-1)[0];
    pluralizedOwnerships.splice(-1, 1);

    if (ownershipsCount === 2) {
      return `${pluralizedOwnerships.join()} and ${lastItem}`;
    }
    return `${pluralizedOwnerships.join(", ")}, and ${lastItem}`;
  }

  return pluralizedOwnerships.join();
}

// Sets global ownerships in desc order
// Request, Quotes, Jobs, Invoices
export function sortGlobalOwnershipDesc(
  ownerships:
    | WorkObjectGlobalOwnerFragment[]
    | WorkObjectGlobalOwnershipFragment[]
    | undefined,
) {
  return (
    (ownerships &&
      ownerships
        .slice()
        .sort(
          (
            a:
              | WorkObjectGlobalOwnerFragment
              | WorkObjectGlobalOwnershipFragment,
            b:
              | WorkObjectGlobalOwnerFragment
              | WorkObjectGlobalOwnershipFragment,
          ) => (a.workObjectType > b.workObjectType ? -1 : 1),
        )) ||
    []
  );
}
