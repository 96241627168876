import type { ApolloQueryResult } from "@apollo/client";
import { useQuery } from "@apollo/client";
import type { OnlineBookingServicesQuery } from "~/utilities/API/graphql";
import type { Offering } from "jobber/settings/selfServeBookings/types";
import { useAccountConfigurationContext } from "jobber/settings/selfServeBookings/components";
import { SELF_SERVE_BOOKINGS_SERVICES_QUERY } from "./graphql";

export const DEFAULT_SERVICES_PAGE_SIZE = 10;

export function useQueryServices(): {
  acceptingOnlineBookings: boolean;
  fetchMore?: () => Promise<ApolloQueryResult<OnlineBookingServicesQuery>>;
  loading: boolean;
  error: string | undefined;
  endCursor: string | undefined;
  offerings: Offering[];
  hasMoreServices: boolean;
} {
  const accountConfiguration = useAccountConfigurationContext();
  const { data, loading, error, fetchMore } =
    useQuery<OnlineBookingServicesQuery>(SELF_SERVE_BOOKINGS_SERVICES_QUERY, {
      variables: {
        advancedQuotingEnabled:
          accountConfiguration.features.advancedQuotingEnabled,
        quoteMarginsEnabled: accountConfiguration.features.quoteMarginsEnabled,
        first: DEFAULT_SERVICES_PAGE_SIZE,
        after: null,
      },
    });

  function mapOfferings(
    services: OnlineBookingServicesQuery["onlineBookingServices"] | undefined,
  ): Offering[] {
    if (services) {
      return services.nodes;
    }
    return [];
  }

  return {
    offerings: mapOfferings(data?.onlineBookingServices),
    acceptingOnlineBookings:
      !!data?.onlineBookingConfiguration?.acceptingOnlineBookings,
    loading,
    error: error?.message,
    endCursor: data?.onlineBookingServices.pageInfo.endCursor,
    fetchMore: () =>
      fetchMore({
        query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
        variables: {
          advancedQuotingEnabled:
            accountConfiguration.features.advancedQuotingEnabled,
          quoteMarginsEnabled:
            accountConfiguration.features.quoteMarginsEnabled,
          first: DEFAULT_SERVICES_PAGE_SIZE,
          after: data?.onlineBookingServices.pageInfo.endCursor,
        },
      }),
    hasMoreServices: !!data?.onlineBookingServices.pageInfo.hasNextPage,
  };
}
