import { gql } from "@apollo/client";

export const BOOKING_CONFIRMATION_EMAIL_MESSAGE_QUERY = gql`
  query BookingConfirmationEmailMessage($attachmentId: EncodedId!) {
    commsEmailTemplates(
      attachmentId: $attachmentId
      messageType: JOB_BOOKING_CONFIRMATION
    ) {
      message
      subject
    }
  }
`;

export const BOOKING_CONFIRMATION_SMS_MESSAGE_QUERY = gql`
  query BookingConfirmationSmsMessage($attachmentId: EncodedId!) {
    commsSmsTemplates(
      attachmentId: $attachmentId
      messageType: JOB_BOOKING_CONFIRMATION
    ) {
      message
    }
  }
`;
