import Quill from "quill";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BaseImageFormat = Quill.import("formats/image") as any;
const ATTRIBUTES = ["alt", "height", "width", "style"];

// Customer image formatter for Quill
export class QuillImageBlot extends BaseImageFormat {
  static create(value: string) {
    // prevent base64 images from being used in quill
    if (this.isBase64(value)) {
      return;
    }
    return super.create(value);
  }

  static isBase64(value: string): boolean {
    return /^data:image\/.+;base64/.test(value);
  }

  static formats(domNode: HTMLElement) {
    return ATTRIBUTES.reduce(
      (formats: Record<string, string | null>, attribute) => {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute);
        }
        return formats;
      },
      {},
    );
  }

  format(name: string, value: string) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

const defaultOptions = ["small", "best-fit", "original"];
interface ImageResizerOptions {
  sizes?: string[];
  menuId: string;
  onResize: (btn: string) => void;
}

export class ImageResizer {
  quill: Quill;
  options: ImageResizerOptions;
  img: HTMLImageElement | null;

  constructor(quill: Quill, options: ImageResizerOptions) {
    this.quill = quill;
    this.options = {
      sizes: options.sizes || defaultOptions,
      menuId: options.menuId,
      onResize: options.onResize,
    };
    this.img = null;

    // listen for clicks inside the editor for all images
    this.quill.root.addEventListener("click", this.handleClick.bind(this));

    // add listeners to buttons on the menu
    const menuButtons = document.querySelectorAll(
      `#${this.options.menuId} div`,
    );
    menuButtons.forEach(buttonElement => {
      buttonElement.addEventListener("click", this.handleMenuClick.bind(this));
    });

    // hide the menu when the text changes (on button click, on user input)
    this.quill.on("text-change", () => {
      this.hide();
    });
  }

  handleMenuClick(event: MouseEvent) {
    const button = event.target as HTMLElement;
    if (!button) return;
    switch (button.textContent) {
      case "Small":
        this.resizeImage(186);
        break;
      case "Best fit":
        this.resizeImage(467);
        break;
      case "Original":
        this.resizeImage(null);
        break;
    }
  }

  resizeImage(size: number | null) {
    if (!this.img) return;

    const blot = Quill.find(this.img) as QuillImageBlot;
    if (!blot) return;

    if (!!size && this.img.naturalWidth < size) {
      // remove style attribute because the image is smaller than the requested size
      blot.format("style", "");
      blot.format("width", "");
      return;
    }

    if (!size) {
      blot.format("style", "");
      blot.format("width", "");
    } else {
      blot.format("style", `width: ${size}px`);
      blot.format("width", size?.toString() || "");
    }
  }

  // eslint-disable-next-line max-statements
  handleClick(event: MouseEvent) {
    const target = event.target as HTMLImageElement;

    if (target && target.tagName && target.tagName.toUpperCase() === "IMG") {
      // if there is no style on image, set original size
      const styleWidth = target.style.width;
      if (styleWidth === "186px") {
        this.options.onResize("Small");
      } else if (styleWidth === "467px") {
        this.options.onResize("Best fit");
      } else {
        this.options.onResize("Original");
      }

      if (this.img === target) {
        // we are already focused on this image
        return;
      }
      if (this.img) {
        // we were just focused on another image
        this.hide();
      }
      // show the UI on the target image
      this.show(target);
    } else if (this.img) {
      // clicked on a non image
      this.hide();
    }
  }

  hide() {
    this.img = null;

    const div = document.getElementById(this.options.menuId);
    if (!div) return;
    div.style.setProperty("display", "none");
  }

  show(img: HTMLImageElement) {
    this.img = img;
    this.styleMenu();
  }

  styleMenu() {
    const div = document.getElementById(this.options.menuId);
    if (!div || !this.img) return;

    // show the menu and position it over the image
    div.style.setProperty("display", "block");
    div.style.setProperty("left", this.img.offsetLeft + 1 + "px");
    div.style.setProperty("top", this.img.offsetTop - 16 + "px");
  }
}
