import React, { useCallback, useEffect, useState } from "react";
import {
  type PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from "react-plaid-link";
import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { useApolloClient, useMutation } from "@apollo/client";
import { PLAID_CONNECT_ACCOUNT_MUTATION } from "jobber/plaidLink/PlaidConnectAccountMutation";
import { SetupOrConfirmTwoFactor } from "jobber/settings/users/components/SetupOrConfirmTwoFactor";
import { fetchPlaidLinkAccountToken } from "jobber/plaidLink/PlaidLinkUtils";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import type {
  ConnectPayloadType,
  LinkConfigType,
  PlaidConnectProps,
  PlaidDataInterface,
} from "./PlaidConnect.d";

export function PlaidConnect(props: PlaidConnectProps) {
  return (
    <APIProvider>
      <BasePlaidConnect {...props} />
    </APIProvider>
  );
}

function clearRedirectUrl(): void {
  window.history.pushState(
    { page: 1 },
    "Jobber Payments - Jobber",
    "/jobber_payments/setup",
  );
}

// eslint-disable-next-line max-statements
function BasePlaidConnect(props: PlaidConnectProps) {
  const {
    countryCode,
    plaidEnabled,
    plaidErrorMessage,
    successMessage,
    disableAddBankAccount,
    buttonText,
    inRevampedSettingsExperiment,
    dispatch,
  } = props;
  const { permissions } = useJobberPayments();
  const apolloClient = useApolloClient();
  const buttonDisabled = disableAddBankAccount || !permissions.canSetup;
  const [isModalOpen, setModalOpen] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [linkConfig, setLinkConfig] = useState<LinkConfigType>({
    clientName: "Jobber",
    token: "",
    product: ["auth", "identity"],
    countryCodes: [countryCode],
  });
  const [connectPlaidBankAccount, { error: queryError, data }] = useMutation(
    PLAID_CONNECT_ACCOUNT_MUTATION,
  );

  const onEvent = useCallback((eventName: string) => {
    if (eventName === "OPEN") {
      Amplitude.TRACK_EVENT("Interacted with Jobber Payments Setup", {
        interaction: "Clicked Connect Plaid",
      });
    }
  }, []);

  const onSuccess = async (
    token: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    const accountSubtype = metadata.accounts[0].subtype;
    if (accountSubtype !== "checking") {
      setQueryLoading(false);
      dispatch({
        type: "onPlaidError",
        data: { plaidErrorMessage: "Please select your checking account" },
      });
    } else {
      await connectPlaidBankAccount({
        variables: {
          plaidLinkPublicKeyToken: token,
          plaidAccountId: metadata.accounts[0].id,
        },
      });
    }
    setModalOpen(false);
  };

  const onExit = () => {
    clearRedirectUrl();
    fetchPlaidLinkAccountToken({
      apolloClient,
      onSuccess: token => {
        // using destructuring to remove the receivedRedirectUri element
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { receivedRedirectUri, ...rest } = linkConfig;
        setLinkConfig({
          ...rest,
          token,
        });
      },
    }).catch(() => {
      return;
    });
    setModalOpen(false);
    setQueryLoading(false);
  };

  const onClick = () => {
    setModalOpen(true);
    setQueryLoading(true);
  };

  const onCloseTwoFa = () => {
    onExit();
    setModalOpen(false);
  };

  useEffect(() => {
    if (!plaidEnabled) {
      return;
    }
    if (
      window.location.pathname === "/jobber_payments/oauth" &&
      window.location.search.includes("oauth_state_id")
    ) {
      const token = localStorage.getItem("plaidLinkToken");
      if (token && token.length > 0) {
        const receivedRedirectUri = window.location.href;
        setLinkConfig({
          ...linkConfig,
          token: token,
          receivedRedirectUri: receivedRedirectUri,
        });
        onClick();
        localStorage.removeItem("plaidLinkToken");
        return;
      }
    }
    fetchPlaidLinkAccountToken({
      apolloClient,
      onSuccess: token => {
        setLinkConfig({ ...linkConfig, token });
      },
    }).catch(() => {
      return;
    });
  }, []);

  useEffect(() => {
    if (!(data && "connectPlaidBankAccount" in (data as PlaidDataInterface))) {
      return;
    }
    const connectPayload: ConnectPayloadType = (data as PlaidDataInterface)
      .connectPlaidBankAccount;

    if (!connectPayload?.success && connectPayload.errors) {
      setQueryLoading(false);
      setLinkConfig({ ...linkConfig, token: "" });
      clearRedirectUrl();
      dispatch({
        type: "onPlaidError",
        data: {
          plaidErrorMessage: connectPayload.errors[0].message,
        },
      });
    } else if (
      connectPayload?.success &&
      connectPayload?.bankMetadata?.bankName &&
      connectPayload?.bankMetadata?.last4 &&
      connectPayload?.bankMetadata?.payoutScheduleInDays
    ) {
      clearRedirectUrl();
      setQueryLoading(false);
      dispatch({
        type: "onSuccess",
        data: {
          successMessage: "Account Connected",
          bank: {
            bankName: connectPayload.bankMetadata.bankName,
            bankLast4: connectPayload.bankMetadata.last4,
            payoutsToJobberMoney: false,
            payoutScheduleInDays:
              connectPayload.bankMetadata.payoutScheduleInDays,
          },
        },
      });
    }
  }, [data, queryError]);

  const { open, ready, error } = usePlaidLink({
    ...linkConfig,
    onSuccess,
    onEvent,
    onExit,
  });

  async function authenticationSuccess() {
    setModalOpen(false);
    open();
  }

  const modifiedButtonText = () => {
    if (inRevampedSettingsExperiment && buttonText === "Connect") {
      return "Connect Bank";
    }

    return buttonText;
  };

  return (
    <>
      {plaidErrorMessage && <Banner type="error">{plaidErrorMessage}</Banner>}
      {successMessage && <Banner type="success">{successMessage}</Banner>}
      {plaidEnabled && (
        <>
          <SetupOrConfirmTwoFactor
            onSuccess={authenticationSuccess}
            isOpen={isModalOpen}
            onClose={onCloseTwoFa}
            requireReAuth={false}
          />
          <Button
            label={modifiedButtonText()}
            loading={queryLoading}
            onClick={onClick}
            disabled={buttonDisabled || !ready || error != undefined}
          />
        </>
      )}
    </>
  );
}
