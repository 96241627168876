import React, { useState } from "react";
import { RegistrationModalExistingNumber } from "jobber/settings/dedicatedPhoneNumber/RegistrationModalExistingNumber";
import { APIProvider } from "~/utilities/API/APIProvider";
import { SetupOrConfirmTwoFactor } from "jobber/settings/users/components/SetupOrConfirmTwoFactor";

interface GlobalBannerModalProps {
  /**
   * Unique Id of the global message
   */
  readonly globalMessageId: string;

  /**
   * Click handler for modal close.
   */
  onClose(): void;
}

const carrierRegistrationBanners = [
  "COMMS_CARRIER_REGISTRATION_FAILED",
  "COMMS_CARRIER_REGISTRATION_LAGGING",
  "COMMS_CARRIER_REGISTRATION_WAITING",
];

const phoneVerificationModal = "VERIFY_PHONE_NUMBER";

export const bannersWithModals = carrierRegistrationBanners.concat(
  phoneVerificationModal,
);

export function GlobalBannerModal({
  globalMessageId,
  onClose,
}: GlobalBannerModalProps) {
  const [isModalOpen, setModalOpen] = useState(true);

  return (
    <APIProvider>
      {carrierRegistrationBanners.includes(globalMessageId) && (
        <RegistrationModalExistingNumber onClose={onClose} />
      )}
      {phoneVerificationModal === globalMessageId && (
        <SetupOrConfirmTwoFactor
          onSuccess={authenticationSuccess}
          isOpen={isModalOpen}
          onClose={onClose}
          isForCommsVerification={true}
          requireReAuth={true}
        />
      )}
    </APIProvider>
  );

  function authenticationSuccess() {
    setModalOpen(false);
  }
}
