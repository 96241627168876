import React, { type ReactNode } from "react";
import classnames from "classnames";
import styles from "./Cell.module.css";

interface CellProps {
  children?: ReactNode | ReactNode[];
  align?: "left" | "center" | "right";
  size?: "small" | "base" | "large";
  variation?: "normal" | "bold";
  testId?: string;
  className?: string;
}

export function Cell({
  children,
  align = "left",
  size = "base",
  variation = "normal",
  testId,
  className,
}: CellProps) {
  const internalClassName = classnames(
    styles.cell,
    styles[align],
    styles[size],
    styles[variation],
    className || "",
  );

  return (
    <td data-testid={testId} className={internalClassName}>
      {children}
    </td>
  );
}
