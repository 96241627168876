import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Form } from "@jobber/components/Form";
import { Text } from "@jobber/components/Text";
import { useFormState } from "@jobber/hooks/useFormState";
import { InputPassword } from "@jobber/components/InputPassword";
import { Button } from "@jobber/components/Button";
import type { SubmissionFieldsInterface } from "utilities/formSubmission/userCredentials/SubmissionFieldsInterface.d";
import type { ResponseDataInterface } from "utilities/formSubmission/userCredentials/ResponseDataInterface.d";
import { saveUserCredentials } from "utilities/formSubmission/userCredentials/saveUserCredentials";
import type { PasswordResetProps } from "./passwordReset.d";
import {
  CONFIRM_PASSWORD_PLACEHOLDER,
  CONFIRM_PASSWORD_WARNING,
  MIN_PASSWORD_LENGTH_WARNING,
  MIN_PASSWORD_PLACEHOLDER,
  PASSWORD_CONFIRMATION_NOT_MATCHED_WARNING,
  PASSWORD_REQUIRED_WARNING,
  PLEASE_WAIT_LABEL,
} from "./stringConstants";

const processMinPasswordSentence = (string: string, num: number) =>
  string.replace("{n}", num.toString());

export function PasswordReset({
  minPasswordLength,
  submitButtonLabel,
  formSubmitPath,
}: PasswordResetProps) {
  const [newPassword, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useFormState();
  const [formStatusMessage, setFormStatusMessage] = useState("");
  const [formSubmissionSuccess, setFormSubmissionStatus] = useState(false);

  function pwConfirmationValidation(val: string) {
    if (val !== newPassword) return PASSWORD_CONFIRMATION_NOT_MATCHED_WARNING;
    else return true;
  }

  const saveUserInfo = async () => {
    setIsLoading(true);

    const inputFields: SubmissionFieldsInterface = {
      password: newPassword,
      password_confirmation: confirmedPassword, // eslint-disable-line @typescript-eslint/naming-convention
    };

    const response = await saveUserCredentials(formSubmitPath, inputFields);

    const { success, message, redirectUrl }: ResponseDataInterface = response;

    setFormStatusMessage(message);
    setFormSubmissionStatus(success);

    if (success) {
      window.history.replaceState("", "", redirectUrl || "/login");
      window.history.go();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Content>
      <Text variation={formSubmissionSuccess ? "success" : "error"}>
        {formStatusMessage}
      </Text>
      <Form onStateChange={setFormState}>
        <Content>
          <InputPassword
            defaultValue={newPassword}
            name="password"
            placeholder={processMinPasswordSentence(
              MIN_PASSWORD_PLACEHOLDER,
              minPasswordLength,
            )}
            onChange={setPassword}
            validations={{
              required: {
                value: true,
                message: PASSWORD_REQUIRED_WARNING,
              },
              minLength: {
                value: minPasswordLength,
                message: processMinPasswordSentence(
                  MIN_PASSWORD_LENGTH_WARNING,
                  minPasswordLength,
                ),
              },
            }}
          />
          <InputPassword
            defaultValue={confirmedPassword}
            name="confirmPassword"
            placeholder={CONFIRM_PASSWORD_PLACEHOLDER}
            onChange={setConfirmedPassword}
            validations={{
              required: {
                value: true,
                message: CONFIRM_PASSWORD_WARNING,
              },
              validate: pwConfirmationValidation,
            }}
          />
        </Content>
      </Form>
      <Button
        label={isLoading ? PLEASE_WAIT_LABEL : submitButtonLabel}
        onClick={saveUserInfo}
        disabled={!formState.isValid || isLoading}
      />
    </Content>
  );
}
