import { defineMessages } from "react-intl";

export const messages = defineMessages({
  upgradeMessage: {
    id: "billing.upsellAddon.upgradeMessage",
    defaultMessage:
      "Maximize your growth—get Referrals, Campaigns, and Reviews in one powerful Marketing Suite",
    description: "Marketing message to upsell the bundle",
  },
  addAddon: {
    id: "billing.upsellAddon.addAddon",
    defaultMessage: "Add",
    description: "Add button label",
  },
  addAddonAriaLabel: {
    id: "billing.upsellAddon.addAddonAriaLabel",
    defaultMessage: "Add",
    description: "Aria label for Add button",
  },
});
