import React from "react";
import { useIntl } from "react-intl";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

interface QBOTurnOnSyncInsightProps {
  shouldConnect?: boolean;
  trackEvents: boolean;
}

export function QBOTurnOnSyncInsight({
  shouldConnect = false,
  trackEvents,
}: QBOTurnOnSyncInsightProps) {
  const [qboSyncSettingsPath] = useUrls("qboSyncSettingsPath");
  const hideConnectCard = !shouldConnect;
  const { formatMessage } = useIntl();

  return (
    <InsightCard
      title={formatMessage(messages.turnOnQuickBooksSync)}
      description={formatMessage(messages.turnOnQuickBooksSyncDescription)}
      actionLabel={formatMessage(messages.learnMore)}
      actionUrl={qboSyncSettingsPath}
      hideEmptyCard={hideConnectCard}
      trackEvents={trackEvents}
    />
  );
}
