import classNames from "classnames";
import React from "react";
import { Button } from "@jobber/components/Button";
import { Spinner } from "@jobber/components/Spinner";
import { List } from "@jobber/components/List";
import type { ProductOrServiceFragment } from "~/utilities/API/graphql";
import type { WorkItem } from "jobber/workItems/types";
import styles from "./styles.module.css";
import { messages } from "./messages";

export interface SearchListProps {
  isLoadingMore: boolean;
  currencySymbol: string;
  productsAndServices: WorkItem[];
  selectItem(item: WorkItem): () => void;
  loadMore?(): void;
}

export function SearchList({
  isLoadingMore,
  currencySymbol,
  productsAndServices,
  selectItem,
  loadMore,
}: SearchListProps) {
  return (
    <>
      <List items={productsAndServices.map(productsToList)} />
      {loadMore ? (
        <div
          className={classNames(styles.centeredBox, {
            [styles.buttonLineItem]: !isLoadingMore,
            [styles.spinnerLineItem]: isLoadingMore,
          })}
        >
          {isLoadingMore ? (
            <Spinner />
          ) : (
            <Button
              onClick={loadMore}
              fullWidth={true}
              type="tertiary"
              label={messages.loadMore.title}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );

  function productsToList(
    product: ProductOrServiceFragment,
  ): Parameters<typeof List>[0]["items"][0] {
    return {
      section: "Services",
      title: product.name,
      value: `${currencySymbol}${product.defaultUnitCost}`,
      content: product.description || "",
      id: product.id,
      onClick: selectItem(product),
    };
  }
}
