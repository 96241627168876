import { useApolloClient } from "@apollo/client";
import { ASSIGNABLE_USERS_QUERY, assignTeamMachine } from ".";

export function useAssignTeamMachineConfiguration(
  selectedAssignees: Set<string> = new Set(),
) {
  const apolloClient = useApolloClient();
  return [
    assignTeamMachine,
    {
      ...assignTeamMachine.context,
      selectedAssignees: selectedAssignees,
    },
    {
      services: {
        queryAssignableUsers: async () => {
          return apolloClient.query({
            query: ASSIGNABLE_USERS_QUERY,
          });
        },
      },
    },
  ] as const;
}
