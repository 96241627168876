import { useCallback } from "react";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import type { Operator } from "~/utilities/API/graphql";

export function useUpdateClientTagCriteria({
  updateAdditionalCriteria,
}: {
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}) {
  const updateClientTagCriteria = useCallback(
    ({
      selectValue,
      clientTagValue,
      criteriaIndex,
    }: {
      selectValue: Operator;
      criteriaIndex: number;
      clientTagValue: string;
    }) => {
      updateAdditionalCriteria({
        type: "updateClientTagCriteria",
        payload: {
          operator: selectValue,
          index: criteriaIndex,
          clientTagValue: clientTagValue,
          isDirty: true,
        },
      });
    },
    [updateAdditionalCriteria],
  );
  return updateClientTagCriteria;
}
