import type { InlineLabelColors } from "@jobber/components/InlineLabel";
import { useIntl } from "react-intl";
import { CampaignStatus } from "~/utilities/API/graphql";
import type { StatusNode } from "jobber/campaigns/views/CampaignsLandingPage/components/CampaignStatusLabel/CampaignStatusLabel";
import { messages } from "jobber/campaigns/views/CampaignsLandingPage/components/CampaignStatusLabel/messages";
import { useFormatDateTime } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useFormatDateTime";

interface StatusInformation {
  statusText: string;
  statusColor: InlineLabelColors | undefined;
  statusTooltip?: string;
}

export function useCampaignStatusInformation(
  status: StatusNode,
  statusMessage?: string,
  scheduledAt?: string,
): StatusInformation {
  const { formatMessage } = useIntl();

  const { formattedDate, formattedTime } = useFormatDateTime({
    scheduledAt,
  });

  switch (status) {
    case CampaignStatus.DRAFT:
      return {
        statusText: formatMessage(messages.draftStatus),
        statusColor: "greyBlue",
      };
    case CampaignStatus.SENT:
      return {
        statusText: formatMessage(messages.sentStatus),
        statusColor: "green",
      };
    case CampaignStatus.INPROGRESS:
      return {
        statusText: formatMessage(messages.inProgressStatus),
        statusColor: "yellow",
      };
    case CampaignStatus.SCHEDULED:
      return {
        statusText: formatMessage(messages.scheduledStatus),
        statusColor: "lightBlue",
        statusTooltip: scheduledAt
          ? formatMessage(messages.scheduledStatusToolTip, {
              date: formattedDate,
              time: formattedTime,
            })
          : undefined,
      };
    case CampaignStatus.FAILED:
      return {
        statusText: formatMessage(messages.failedStatus),
        statusColor: "red",
        statusTooltip:
          statusMessage || formatMessage(messages.failedStatusToolTip),
      };
    case CampaignStatus.AUTOMATIONACTIVE:
      return {
        statusText: formatMessage(messages.automationActiveStatus),
        statusColor: "green",
      };
    case CampaignStatus.AUTOMATIONINACTIVE:
      return {
        statusText: formatMessage(messages.automationInactiveStatus),
        statusColor: "greyBlue",
      };
  }
}
