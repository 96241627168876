import type FormContext from "jobber/payments/FormContext";
import type { DirectTokenData } from "./TokenProvider";
import type TokenProvider from "./TokenProvider";

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default class DirectProvider implements TokenProvider<DirectTokenData> {
  private readonly formContext: FormContext;

  constructor(formContext: FormContext) {
    this.formContext = formContext;
  }

  public async createToken() {
    const { card, billingAddress } = this.formContext.state;

    if (!billingAddress) {
      throw new Error("Billing address form is missing from page.");
    }

    if (!card) {
      throw new Error("Credit card form is missing from page.");
    }

    return {
      ...card,
      ...billingAddress,
    };
  }
}
