/**
 * DayView Page Object Model
 *
 * This file contains the Page Object Model for the DayView component.
 * It also provides a mock for the FullCalendar component. The mocked component
 * prevents rendering the actual FullCalendar component as it has been known
 * to cause timeouts in CI.
 *
 * DayViewPOM exposes the props passed to FullCalendar by using `getFullCalendarProps()` instead.
 * This allows us to test the props passed to FullCalendar without actually rendering it.
 *
 */
import React, { forwardRef, useImperativeHandle, useLayoutEffect } from "react";
import type {
  CalendarOptions,
  EventDropArg,
  EventInput,
} from "@fullcalendar/core";
import { act, waitFor } from "@testing-library/react";
import type {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import { add } from "date-fns";
import type { User } from "~/utilities/API/graphql";
import {
  DEFAULT_EVENT_BACKGROUND_COLOUR,
  DEFAULT_EVENT_TEXT_COLOUR,
} from "./DayView";

/**
 * @returns The events passed to FullCalendar as props.
 */
export function events() {
  return (getFullCalendarProps().events as EventInput[]) || [];
}

/**
 *
 * @returns The event representing the selected slot
 */
const selectedSlot = () =>
  events().find(event => event.id === "draggable-slot");

/**
 * @returns Whether the event with the given title is on the calendar.
 */
export function hasEvent(title: string | RegExp) {
  return events().some(event => {
    if (!event || !event.title) return false;

    if (typeof title === "string") {
      return event.title === title;
    }

    return title.test(event.title);
  });
}

export function getEvent(title: string | RegExp) {
  const has = hasEvent(title);

  if (!has) {
    throw new Error(
      `DayView was unable to find an event with title "${title}"`,
    );
  }
}

export function waitForEvent(title: string | RegExp) {
  return waitFor(() => getEvent(title));
}
/**
 * @returns Whether an event representing the selected slot is on the calendar.
 */
export function hasSelectedSlot() {
  return !!selectedSlot();
}

/**
 * @returns The date of the selected slot
 */
export function selectedSlotDate(): Date | undefined {
  return selectedSlot()?.start as Date;
}

/**
 * @returns The end date of the selected slot
 */
export function selectedSlotEndDate(): Date | undefined {
  return selectedSlot()?.end as Date;
}

/**
 * @returns Whether the selected slot is on the given date
 */
export function hasSelectedSlotDate(date: Date) {
  return selectedSlotDate()?.getTime() === date.getTime() || false;
}

export function selectedSlotTeamMemberId() {
  return selectedSlot()?.resourceId;
}

/**
 * @returns The props passed to FullCalendar
 */
export function getFullCalendarProps() {
  return instanceProps as Omit<CalendarOptions, "events"> & {
    events: EventInput[];
  };
}

/**
 * @returns The duration of the selected slot in hours and minutes
 */
export function selectedSlotDuration() {
  const slot = selectedSlot();
  if (slot?.start instanceof Date && slot?.end instanceof Date) {
    const durationInSeconds = Math.floor(
      (slot.end.getTime() - slot.start.getTime()) / 1000,
    );

    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);

    return { hours, minutes };
  }
}

/**
 * Simulates selecting a slot on the calendar.
 */
export function selectSlot(
  date: Date,
  assignee: Pick<User, "id" | "__typename">,
) {
  act(() => {
    getFullCalendarProps().dateClick?.({
      date,
      resource: {
        id: assignee.id,
      },
    } as unknown as DateClickArg);
  });
}

/**
 * Simulates resizing the selected slot.
 */
export function resizeSelectedSlot({
  interval = 2,
  edge = "end",
}: {
  interval?: number;
  edge?: "start" | "end";
} = {}) {
  const start = selectedSlotDate();
  const end = selectedSlotEndDate();
  if (!start || !end) return;

  const date = add(edge === "start" ? start : end, { minutes: interval * 15 });

  act(() => {
    getFullCalendarProps().eventResize?.({
      event: {
        start: edge === "start" ? date : start,
        end: edge === "end" ? date : end,
      },
    } as unknown as EventResizeDoneArg);
  });
}

/**
 * Simulates dragging the selected slot.
 */
export function dragSelectedSlot({
  hours,
  minutes,
  assignee,
}: {
  hours: number;
  minutes: number;
  assignee: Pick<User, "id" | "__typename">;
}) {
  const start = selectedSlotDate();
  if (!start) return;

  act(() => {
    getFullCalendarProps().eventDrop?.({
      event: {
        start: new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          hours,
          minutes,
          0,
        ),
        getResources: () => [assignee],
      },
    } as unknown as EventDropArg);
  });
}

// expect helpers
export const expectSelectedSlotDateToBeEqual = (
  expectedStartTime?: Date,
  expectedEndTime?: Date,
) => {
  const start = selectedSlotDate();
  const end = selectedSlotEndDate();

  expect(start?.getTime()).toEqual(expectedStartTime?.getTime());
  expect(end?.getTime()).toEqual(expectedEndTime?.getTime());
};

export const expectEventColoursToUseDefault = () => {
  const { eventBackgroundColor, eventTextColor } = getFullCalendarProps();
  expect(eventBackgroundColor).toEqual(DEFAULT_EVENT_BACKGROUND_COLOUR);
  expect(eventTextColor).toEqual(DEFAULT_EVENT_TEXT_COLOUR);
};

export const expectEventColoursToNotUseDefault = () => {
  const { eventBackgroundColor, eventTextColor } = getFullCalendarProps();
  expect(eventBackgroundColor).toEqual(undefined);
  expect(eventTextColor).toEqual(undefined);
};

// Mocks

// Mutable module level variable to store the props passed to FullCalendar
let instanceProps: CalendarOptions | undefined;

export const gotoDate = jest.fn();

/**
 * Mocked FullCalendar component.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FullCalendar = forwardRef(function FullCalendar(
  props: CalendarOptions,
  ref: React.Ref<unknown>,
) {
  useImperativeHandle(ref, () => ({
    getApi: () => {
      return {
        gotoDate,
      };
    },
  }));

  // Store the props passed to FullCalendar
  useLayoutEffect(() => {
    instanceProps = props;
    return () => {
      // Reset the props when the component is unmounted
      instanceProps = undefined;
    };
  });

  return <></>;
});
