import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recommended: {
    id: "campaigns.selectableSegment.recommended",
    defaultMessage: "Recommended",
    description: "recommended text",
  },
  allClientsSegmentCardDescription: {
    id: "campaigns.selectableSegment.allClientsSegmentCardDescription",
    defaultMessage: "Clients that have not been archived",
    description: "Description for the all clients segment card",
  },
  pastClientsSegmentCardClosedDescription: {
    id: "campaigns.selectableSegment.pastClientsSegmentCardClosedDescription",
    defaultMessage:
      "Clients who haven’t worked with you in over 12 months, with no upcoming work scheduled",
    description: "Description for the upcoming visit clients segment card",
  },
  upcomingClientsSegmentCardClosedDescription: {
    id: "campaigns.selectableSegment.upcomingClientsSegmentCardClosedDescription",
    defaultMessage: "Clients with at least one visit within the next 30 days",
    description: "Description for the upcoming visit clients segment card",
  },
  pastClientsSegmentCardOpenDescription: {
    id: "campaigns.selectableSegment.pastClientsSegmentCardOpenDescription",
    defaultMessage: "Last job was completed more than",
    description: "Description for the past clients segment card",
  },
  upcomingClientsSegmentCardOpenDescription: {
    id: "campaigns.selectableSegment.upcomingClientsSegmentCardOpenDescription",
    defaultMessage: "Clients with at least one visit within the next",
    description: "Description for the upcoming visit clients segment card",
  },
});
