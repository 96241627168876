import { useMutation, useQuery } from "@apollo/client";
import {
  CTA_DISMISS,
  CTA_QUERY,
  CTA_SHOWN,
} from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import type {
  CtaLookupQuery,
  JobberPaymentsSettingQuery,
} from "~/utilities/API/graphql";
import { MANDATORY_CARD_ON_FILE_QUERY } from "./OnlinePaymentsToggle.graphql";

export function useOnlinePaymentsQueries(ctaId: string) {
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<JobberPaymentsSettingQuery>(MANDATORY_CARD_ON_FILE_QUERY, {
    variables: { id: "MANDATORY_CARD_ON_FILE" },
  });

  const { data: ctaData, loading: ctaLoading } = useQuery<CtaLookupQuery>(
    CTA_QUERY,
    {
      variables: { id: ctaId },
    },
  );

  const [dismissCTA, { loading: ctaDismissLoading }] = useMutation(
    CTA_DISMISS,
    {
      variables: { id: ctaId },
      refetchQueries: [{ query: CTA_QUERY, variables: { id: ctaId } }],
    },
  );

  const [incrementCTAShown, { loading: ctaShowLoading }] = useMutation(
    CTA_SHOWN,
    {
      variables: { id: ctaId },
      refetchQueries: [{ query: CTA_QUERY, variables: { id: ctaId } }],
    },
  );

  return {
    data,
    loading: queryLoading || ctaLoading || ctaDismissLoading || ctaShowLoading,
    queryError,
    ctaData,
    dismissCTA,
    incrementCTAShown,
  };
}
export { CTA_DISMISS, CTA_QUERY, CTA_SHOWN };
