import { gql } from "@apollo/client";

export const QUOTE_SUMMARY_QUERY = gql`
  query QuoteSummary(
    $sort: QuoteSummarySortInput
    $filter: QuoteSummaryFilterInput
    $first: Int
    $cursor: String
  ) {
    quotesSummary(
      sort: $sort
      filter: $filter
      cursor: $cursor
      first: $first
    ) {
      reportPageInfo {
        startCursor
        nextCursor
      }
      totalCount
      records {
        name
        quoteConversionRate
        quoteConvertedAverage
        quotesConvertedCount
        quoteSentAverage
        quotesConvertedTotal
        quotesSentCount
        quotesSentTotal
      }
    }
  }
`;

export const QUOTE_SUMMARY_TOTALS_QUERY = gql`
  query QuoteSummaryTotals($filter: QuoteSummaryFilterInput) {
    quotesSummaryTotals(filter: $filter) {
      quoteConversionRateAverage
      quoteConvertedAverage
      quoteSentAverage
      quotesConvertedCount
      quotesSentCount
      quotesSentTotal
      quotesConvertedTotal
    }
  }
`;

export const QUOTE_SUMMARY_EXPORT_MUTATION = gql`
  mutation QuoteSummaryExport($input: ExportQuotesSummaryCsvInput) {
    exportQuotesSummaryCsv(input: $input) {
      response
    }
  }
`;
