import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createTimesheetModalTitle: {
    id: "jobCosting.LabourTable.createTimesheetModalTitle",
    defaultMessage: "New time entry",
    description: "Title of the create new timesheet modal",
  },
  createTimesheetModalButtonLabel: {
    id: "jobCosting.LabourTable.createTimesheetModalButtonLabel",
    defaultMessage: "New Time Entry",
    description: "Title of the create new timesheet modal",
  },
  editTimesheetModalTitle: {
    id: "jobCosting.LabourTable.editTimesheetModalTitle",
    defaultMessage: "Edit time entry",
    description: "Title of the edit existing timesheet modal",
  },

  // TimesheetEntry confirmation modal messages
  deleteTimesheetEntryConfirmationModalTitle: {
    id: "jobCosting.LabourTable.deleteTimesheetEntryConfirmationModalTitle",
    defaultMessage: "Delete tracked time?",
    description: "Title of the delete existing timesheet confirmation modal",
  },
  deleteTimesheetEntryConfirmationModalMessage: {
    id: "jobCosting.LabourTable.deleteTimesheetEntryConfirmationModalMessage",
    defaultMessage:
      "Deleting tracked time will permanently remove it from Jobber",
    description: "Delete existing timesheet confirmation modal message",
  },
  saveTimesheetEntryButtonLabel: {
    id: "jobCosting.LabourTable.saveTimesheetEntryButtonLabel",
    defaultMessage: "Save Time Entry",
    description: "Save button label of the timesheet entry modal",
  },

  // TimesheetModal content Input field messages
  timesheetModalContentStartTimeInputPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.StartTimeInputPlaceholder",
    defaultMessage: "Start time",
    description: "Placeholder for start time input",
  },
  timesheetModalContentStartTimeInputRequiredValidationMessage: {
    id: "jobCosting.LabourTable.timesheetModalContent.StartTimeInputRequiredValidationMessage",
    defaultMessage: "Start time required",
    description:
      "Validation message for start time input - start time required",
  },
  timesheetModalContentEndTimeInputPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.EndTimeInputPlaceholder",
    defaultMessage: "End time",
    description: "Placeholder for end time input",
  },
  timesheetModalContentNotesInputLabel: {
    id: "jobCosting.LabourTable.timesheetModalContent.NotesInputLabel",
    defaultMessage: "notes",
    description: "Label for notes input",
  },
  timesheetModalContentNotesInputPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.NotesInputPlaceholder",
    defaultMessage: "Notes",
    description: "Placeholder for notes input",
  },
  timesheetModalContentDateInputPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.DateInputPlaceholder",
    defaultMessage: "Date",
    description: "Placeholder for date input",
  },
  timesheetModalContentDateInputRequiredValidationMessage: {
    id: "jobCosting.LabourTable.timesheetModalContent.DateInputRequiredValidationMessage",
    defaultMessage: "Date required",
    description: "Validation message for Date input - date required",
  },
  timesheetModalContentAssignToSelectLabel: {
    id: "jobCosting.LabourTable.timesheetModalContent.AssignToSelectLabel",
    defaultMessage: "assignedTo",
    description: "Label for AssignTo Select",
  },
  timesheetModalContentAssignToSelectPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.AssignToSelectPlaceholder",
    defaultMessage: "Employee",
    description: "Placeholder for AssignTo Select",
  },
  timesheetModalContentEmployeeCostInputPlaceholder: {
    id: "jobCosting.LabourTable.timesheetModalContent.EmployeeCostInputPlaceholder",
    defaultMessage: "Employee cost",
    description: "Placeholder for Employee cost input",
  },
  timesheetModalContentEmployeeCostInputSuffix: {
    id: "jobCosting.LabourTable.timesheetModalContent.EmployeeCostInputSuffix",
    defaultMessage: "per hour",
    description: "Suffix for Employee cost input",
  },
  timesheetModalContentEmployeeCostInputPositiveValidationMessage: {
    id: "jobCosting.LabourTable.timesheetModalContent.EmployeeCostInputPositiveValidationMessage",
    defaultMessage: "Enter a positive employee cost",
    description:
      "Validation message for Employee cost input - employee cost must be positive",
  },
  timesheetModalContentEmployeeCostInputMaxValidationMessage: {
    id: "jobCosting.LabourTable.timesheetModalContent.EmployeeCostInputMaxValidationMessage",
    defaultMessage: "Employee cost must be less than 10 billion",
    description:
      "Validation message for Employee cost input - employee cost max 10 billion",
  },
  timesheetModalContentLabourRateAppliedInputCheckboxLabel: {
    id: "jobCosting.LabourTable.timesheetModalContent.LabourRateAppliedInputCheckboxLabel",
    defaultMessage:
      "Apply labor rate to all of {selectedUserName}'s future timesheet entries",
    description: "Label for Labour rate applied input checkbox",
  },
  timesheetModalContentTotalCostString: {
    id: "jobCosting.LabourTable.timesheetModalContent.TotalCostString",
    defaultMessage: "Total cost: {currencySymbol}{totalCost}",
    description: "Total cost string for timesheet modal",
  },
});
