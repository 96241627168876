import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignActivateMutation,
  CampaignActivateMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_ACTIVATE } from "./useCampaignActivateMutation.graphql";

export interface CampaignActivationProps {
  input: CampaignActivateMutationVariables;
  onError: () => void;
  onCompleted: (data: CampaignActivateMutation) => void;
}

export function useCampaignActivateMutation() {
  const [campaignActivateMutation, { loading, data }] = useMutation<
    CampaignActivateMutation,
    CampaignActivateMutationVariables
  >(CAMPAIGN_ACTIVATE);

  const activateCampaign = useCallback(
    async ({ input, onError, onCompleted }: CampaignActivationProps) => {
      const mutationResult = await campaignActivateMutation({
        variables: input,
        onError: onError,
        onCompleted: onCompleted,
      });

      return {
        campaign: mutationResult.data?.campaignActivate.campaign,
        userErrors: mutationResult.data?.campaignActivate.userErrors ?? [],
      };
    },
    [campaignActivateMutation],
  );
  return { activateCampaign, loading, data };
}
