export const ANALYTICS_EVENTS = {
  quoteFollowUpsUpgrade: {
    learnMore: "Clicked Upgrade Path Button",
    dialogInteraction: "Interacted with Dialog",
    locations: {
      prompt: "Upgrade for Quote Follow-ups Prompt",
      notificationsSettings:
        "Upgrade for Quote Follow-ups on Notifications Settings page",
    },
    interactions: {
      dismissed: "Dismissed Dialog",
      remindMeLater: "Remind Me Later",
    },
  },
  invoiceFollowUpsUpgrade: {
    learnMore: "Clicked Upgrade Path Button",
    dialogInteraction: "Interacted with Dialog",
    locations: {
      prompt: "Upgrade for Invoice Follow-ups Prompt",
    },
    interactions: {
      dismissed: "Dismissed Dialog",
      remindMeLater: "Remind Me Later",
    },
  },
  twoWayTextMessagingUpgrade: {
    learnMore: "Clicked Upgrade Path Button",
    dialogInteraction: "Interacted with Dialog",
    locations: {
      prompt: "Upgrade for Two-way Text Messaging Prompt",
    },
    interactions: {
      dismissed: "Dismissed Dialog",
      remindMeLater: "Remind Me Later",
      dontShowMeAgain: "Don't Show Me Again",
    },
  },
};
