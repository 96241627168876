import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Spinner } from "@jobber/components/Spinner";
import classNames from "classnames";
import styles from "./styles.module.css";

interface ListLoadingWrapperProps {
  isLoading: boolean;
  error: { message: string } | undefined;
  children: React.ReactNode;
}
export function ListLoadingWrapper({
  isLoading,
  error,
  children,
}: ListLoadingWrapperProps) {
  if (isLoading) {
    return (
      <div className={classNames(styles.centeredBox, styles.largePadding)}>
        <Spinner />
      </div>
    );
  } else if (error) {
    return <Banner type="error">{error.message}</Banner>;
  }
  return <>{children}</>;
}
