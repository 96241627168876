import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { scheduleCardMachine } from "./scheduleCard.machine";
import type { RepeatOption } from "../OneOffScheduler";

export function useScheduleCardMachineConfiguration(
  repeatOptions: RepeatOption[] = [],
) {
  const { features } = useAccount();

  const dispatchRruleDaily = repeatOptions.filter(option =>
    option.label.toLowerCase().includes("daily"),
  );

  return scheduleCardMachine.withContext({
    ...scheduleCardMachine.context,
    arrivalWindowsEnabled: features.arrivalWindows.enabled,
    repeatOption: dispatchRruleDaily[0]?.value,
  });
}
