import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailTabTitle: {
    id: "reviews.commsCustomizer.emailTab",
    defaultMessage: "Email",
    description: "The email tab that users can select",
  },
  textMessageTabTitle: {
    id: "reviews.commsCustomizer.textMessageTab",
    defaultMessage: "Text",
    description: "The text message tab that users can select",
  },
  previewTitle: {
    id: "reviews.messagePreviewer.previewTitle",
    defaultMessage: "Preview",
    description: "Preview label text",
  },
  previewEmailHelper: {
    id: "reviews.messagePreviewer.previewEmailHelper",
    defaultMessage: "Replies sent to company email",
    description: "Preview description text",
  },
  previewTextHelper: {
    id: "reviews.messagePreviewer.previewTextHelper",
    defaultMessage: "Replies sent to dedicated phone number",
    description: "Preview description text",
  },
});
