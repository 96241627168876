import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "respondToDispute.header",
    defaultMessage: "Respond to {category}",
    description: "Respond to dispute header",
  },
  modalTitle: {
    id: "respondToDispute.modalTitle",
    defaultMessage: "Accept dispute?",
    description: "Respond to dispute modal title",
  },
  cancelButton: {
    id: "respondToDispute.cancelButton",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  contentParagraphOne: {
    id: "respondToDispute.contentParagraphOne",
    defaultMessage:
      "By accepting this dispute, you will acknowledge it as lost.\nThis is irreversible and permanent.",
    description: "Respond to dispute content paragraph one",
  },
  contentParagraphTwo: {
    id: "respondToDispute.contentParagraphTwo",
    defaultMessage: "The disputed amount of {amount} will also be forfeited.",
    description: "Respond to dispute content paragraph two",
  },
  reviewClaimDetails: {
    id: "respondToDispute.reviewClaimDetails",
    defaultMessage: "Review the claim details",
    description: "Review claim details button",
  },
  reviewClaimDetailsA11y: {
    id: "respondToDispute.reviewClaimDetailsA11y",
    defaultMessage: "Review the claim details button",
    description: "Review claim details button aria label",
  },
  fromCardholderIssuingBank: {
    id: "respondToDispute.fromCardholderIssuingBank",
    defaultMessage: "\u00A0from cardholder's issuing bank",
    description: "from cardholder's issuing bank",
  },
  chargebackParagraphOne: {
    id: "respondToDispute.chargebackParagraphOne",
    defaultMessage:
      "A payment dispute has been initiated by the cardholder. The cardholder's bank has reclaimed the disputed amount of {amount}. You may be able to recover the disputed amount by responding to this chargeback before the deadline. Here are the steps you can take:",
    description: "Respond to chargeback paragraph one",
  },
  chargebackParagraphTwoListTwo: {
    id: "respondToDispute.chargebackParagraphTwoListTwo",
    defaultMessage:
      "Contact your client to understand why they filed this dispute",
    description: "Respond to chargeback paragraph two list item two",
  },
  chargebackParagraphTwoListThree: {
    id: "respondToDispute.chargebackParagraphTwoListThree",
    defaultMessage:
      "If your client agrees to withdraw the dispute, we strongly recommend that you still counter the dispute and submit evidence",
    description: "Respond to chargeback paragraph two list item three",
  },
  chargebackParagraphTwoListFour: {
    id: "respondToDispute.chargebackParagraphTwoListFour",
    defaultMessage: "Counter or accept the dispute by {date}",
    description: "Respond to chargeback paragraph two list item four",
  },
  inquiryParagraphTwoListTwo: {
    id: "respondToDispute.inquiryParagraphTwoListTwo",
    defaultMessage:
      "Contact your client to understand why the dispute was filed",
    description: "Respond to inquiry paragraph two list item two",
  },
  inquiryParagraphTwoListThree: {
    id: "respondToDispute.inquiryParagraphTwoListThree",
    defaultMessage:
      "If you agree with your client's claim, you can refund the payment",
    description: "Respond to inquiry paragraph two list item three",
  },
  inquiryParagraphTwoListFour: {
    id: "respondToDispute.inquiryParagraphTwoListFour",
    defaultMessage: "Respond to this inquiry by {date}",
    description: "Respond to inquiry paragraph two list item four",
  },
  chargebackResolveButton: {
    id: "respondToDispute.chargebackResolveButton",
    defaultMessage: "Accept Dispute",
    description: "Resolve chargeback button",
  },
  chargebackRespondButton: {
    id: "respondToDispute.chargebackRespondButton",
    defaultMessage: "Counter Dispute",
    description: "Respond to chargeback button",
  },
  inquiryParagraphOne: {
    id: "respondToDispute.inquiryParagraphOne",
    defaultMessage:
      "An inquiry has been initiated by the cardholder. No funds have been withdrawn from your account yet, but the cardholder's bank is requesting more information to decide whether or not they should return these funds to the cardholder. You may be able to prevent a formal dispute by responding to this inquiry. Here are the steps you can take:",
    description: "Respond to inquiry paragraph one",
  },

  inquiryResolveButton: {
    id: "respondToDispute.inquiryResolveButton",
    defaultMessage: "Refund Payment",
    description: "Resolve inquiry button",
  },
  inquiryRespondButton: {
    id: "respondToDispute.inquiryRespondButton",
    defaultMessage: "Respond to Inquiry",
    description: "Respond to inquiry button",
  },
  learnMore: {
    id: "respondToDispute.learnMore",
    defaultMessage: "Learn more about the dispute process in the [help center]",
    description: "Learn more link",
  },
});
