import type { ApolloError } from "@apollo/client";
import type {
  CommsCampaignClientSegmentReviewQuery,
  CommsCampaignReviewQuery,
  CommsCampaignReviewQueryVariables,
} from "~/utilities/API/graphql";
import { useClientSegmentInformationQuery } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/hooks/useClientSegmentInformationQuery/useClientSegmentInformationQuery";
import { useCampaignInformationQuery } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/hooks/useCampaignInformationQuery/useCampaignInformationQuery";

interface useCommsCampaignReviewQueryProps {
  campaignId: string;
  onCampaignNotFound: () => void;
}

interface useCommsCampaignReviewQuery {
  loadingCampaign: boolean;
  campaignData?: CommsCampaignReviewQuery;
  campaignError?: ApolloError;
  loadingClientSegment: boolean;
  clientSegmentData?: CommsCampaignClientSegmentReviewQuery;
  clientSegmentError?: ApolloError;
  refetchCampaign(variables: CommsCampaignReviewQueryVariables): void;
  timeoutError: boolean;
}

export function useCommsCampaignReviewQuery({
  campaignId,
  onCampaignNotFound,
}: useCommsCampaignReviewQueryProps): useCommsCampaignReviewQuery {
  const { loadingCampaign, campaignData, campaignError, refetchCampaign } =
    useCampaignInformationQuery({ campaignId, onCampaignNotFound });

  const {
    loadingClientSegment,
    clientSegmentData,
    clientSegmentError,
    timeoutError,
  } = useClientSegmentInformationQuery({ campaignId });

  return {
    loadingCampaign,
    campaignData,
    campaignError,
    loadingClientSegment,
    clientSegmentData,
    clientSegmentError,
    refetchCampaign,
    timeoutError,
  };
}
