import { defineMessages } from "react-intl";

const prefix = "setupWizard.companyPageForm.";

export const messages = defineMessages({
  companyNamePlaceholder: {
    id: `${prefix}companyNamePlaceholder`,
    defaultMessage: "Company name",
    description: "Company name placeholder for the setup wizard",
  },
  companyNameError: {
    id: `${prefix}companyNameError`,
    defaultMessage: "Please fill in the name of your company",
    description: "Company name error for the setup wizard",
  },
  industryPlaceholder: {
    id: `${prefix}industryPlaceholder`,
    defaultMessage: "Search for your industry",
    description: "Industry placeholder for the setup wizard",
  },
  industryError: {
    id: `${prefix}industryError`,
    defaultMessage: "Please select an industry from the list",
    description: "Industry error for the setup wizard",
  },
});
