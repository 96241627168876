import type { QueryErrors } from "~/utilities/API/graphql";

export interface FormErrors {
  address?: string | undefined;
  phone?: string | undefined;
  pc?: string | undefined;
  email?: string | undefined;
}

export interface TwilioRegistrationFormReducerState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  street1?: string;
  street2?: string;
  city?: string;
  province?: string;
  pc?: string;
  country?: string;
  businessRegistrationNumber?: string;
  businessType?: string;
  businessName?: string;
  websiteUrl?: string;
  jobPosition?: string;
  businessTitle?: string;
  smsablePhoneError?: string;
  pocCheckbox?: boolean;
  generalError?: string;
  formSubmitted?: boolean;
  formErrors?: FormErrors;
  userErrors?: QueryErrors[];
}

export type TwilioRegistrationFormMutationFields = Omit<
  TwilioRegistrationFormReducerState,
  "pocCheckbox"
>;

interface Action {
  type: "Update";
  data: Partial<TwilioRegistrationFormReducerState>;
}

// Can be updated to use switch statement if more actions
// types are added later.
export function twilioRegistrationFormReducer(
  prevState: TwilioRegistrationFormReducerState,
  action: Action,
): TwilioRegistrationFormReducerState {
  return { ...prevState, ...action.data };
}
