import { useActor } from "@xstate/react";
import { createContext, useContext } from "react";
import type { ActorRefFrom } from "xstate";
import type { AssignTeamMachine } from "./assignTeam.machine";

export const AssignTeamMachineContext = createContext<
  ActorRefFrom<AssignTeamMachine> | undefined
>(undefined);

export const useAssignTeamMachine = () => {
  const actor = useContext(AssignTeamMachineContext);

  return useActor(actor!);
};
