import { createContext } from "react";
import type { UserPermission } from "~/utilities/API/graphql";

export interface AuthorizationContextType {
  loading: boolean;
  isAdmin: boolean;
  permissions: UserPermission[];
}

export const AuthorizationContext = createContext<AuthorizationContextType>({
  loading: true,
  isAdmin: false,
  permissions: [],
});

AuthorizationContext.displayName = "AuthorizationContext";
