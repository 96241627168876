import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import type { DepositDetailsType } from "./DepositDetails";
import { DepositDetails } from "./DepositDetails";
import styles from "./DepositDetails.module.css";

export function DepositDetailsWrapper({
  depositAmount,
  depositAmountOutstanding,
  depositPaymentsRecords,
}: DepositDetailsType) {
  return (
    <div className={styles.depositDetailsWrapper}>
      <IntlProvider>
        <DepositDetails
          depositAmount={depositAmount}
          depositAmountOutstanding={depositAmountOutstanding}
          depositPaymentsRecords={depositPaymentsRecords}
        />
      </IntlProvider>
    </div>
  );
}
