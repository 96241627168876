import { defineMessages } from "react-intl";

export const messages = defineMessages({
  renewalDisclaimer: {
    id: "billing.purchaseDisclaimer.renewalDisclaimer",
    defaultMessage:
      "Charges are non-refundable and will automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description: "Purchase renewal disclaimer",
  },
  taxDisclaimer: {
    id: "billing.purchaseDisclaimer.taxDisclaimer",
    defaultMessage: "Plus taxes where applicable.",
    description: "Purchase preview tax disclaimer",
  },
  mainSubscriptionDisplayName: {
    id: "billing.purchaseDisclaimer.mainSubscriptionDisplayName",
    defaultMessage: "{subscriptionDisplayName} subscription",
    description:
      "Display name for the main subscription in the purchase disclaimer",
  },
  addonSubscriptionDisplayName: {
    id: "billing.purchaseDisclaimer.addonSubscriptionDisplayName",
    defaultMessage: "subscription add-ons",
    description:
      "Display name for the add-on subscription in the purchase disclaimer",
  },
});
