import { useCallback, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { useHandleUpdatedClientSegment } from "jobber/campaigns/hooks/useHandleUpdatedClientSegment";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { type ClientSegmentFragment, Segment } from "~/utilities/API/graphql";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/messages";

export interface UseSaveOnDirtyArgs {
  campaignId?: string;
  initialClientSegment: ClientSegmentFragment | undefined;
  onNextNavigation: () => void;
}
export function useSaveOnDirty({
  initialClientSegment,
  onNextNavigation,
}: UseSaveOnDirtyArgs) {
  const {
    campaignSegment: {
      selectedSegmentType,
      pastClientsAdditionalCriteria,
      allClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
      isDirty,
    },
  } = useCampaignWizardContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { formatMessage } = useIntl();

  const { updateClientSegment, loadingDefaultTemplate } =
    useHandleUpdatedClientSegment({
      clientSegment: initialClientSegment,
      segmentType: selectedSegmentType,
    });

  const [updateSegmentCriteriaLoading, setUpdateSegmentCriteriaLoading] =
    useState(false);
  const saveOnNext = useCallback(async () => {
    if (!isDirty) {
      onNextNavigation();
      return;
    }
    if (updateSegmentCriteriaLoading || loadingDefaultTemplate) {
      return;
    }
    setUpdateSegmentCriteriaLoading(true);
    const newSegmentCriteria =
      selectedSegmentType === Segment.ALL_CLIENTS
        ? {
            clientTagCriteria: parseAdditionalCriteria(
              allClientsAdditionalCriteria,
            ).clientTagCriteria,
          }
        : {
            interval: pastClientsSegmentCriteria.interval,
            unit: pastClientsSegmentCriteria.unit,
            clientTagCriteria: parseAdditionalCriteria(
              pastClientsAdditionalCriteria,
            ).clientTagCriteria,
          };

    await updateClientSegment({
      newSegmentCriteria,
      onSuccess: ({ campaignId }) => {
        if (path !== "edit" && campaignId) {
          history.replace(
            generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
              campaignId: campaignId,
            }),
          );
        }
        showToast({ message: formatMessage(messages.savedClientSegment) });
        setUpdateSegmentCriteriaLoading(false);
        if (campaignId) {
          const newPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
            campaignId: campaignId,
          });
          history.push(newPath);
        } else {
          // Because react navigations aren't synchronous we have to manually do
          // the navigation when creating the campaign on the SelectClientSegmentPage.
          // If the campaign exists we can use the default navigation behaviour
          onNextNavigation();
        }
      },
      onError: () => {
        setUpdateSegmentCriteriaLoading(false);
      },
    });
  }, [
    allClientsAdditionalCriteria,
    loadingDefaultTemplate,
    formatMessage,
    history,
    path,
    isDirty,
    pastClientsAdditionalCriteria,
    pastClientsSegmentCriteria,
    selectedSegmentType,
    updateClientSegment,
    onNextNavigation,
    updateSegmentCriteriaLoading,
  ]);
  return {
    saveOnNext,
    updateSegmentCriteriaLoading,
  };
}
