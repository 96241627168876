import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "invoices.cardOnFileClientModal.modalTitle",
    defaultMessage: "Clients Missing a Payment Method",
    description:
      "Title that is displayed at the top of the card on file client modal",
  },
  modalDescriptionPartOne: {
    id: "invoices.cardOnFileClientModal.modalDescription",
    defaultMessage:
      "Request a payment method on file from your recurring clients to guarantee you'll get paid as soon as the job is done.",
    description: "Description of the card on file client modal",
  },
  modalDescriptionPartTwo: {
    id: "invoices.cardOnFileClientModal.modalDescription",
    defaultMessage:
      "The following list of clients will be sent a card on file request.",
    description: "Description of the card on file client modal",
  },
  nextButton: {
    id: "invoices.cardOnFileClientModal.nextButton",
    defaultMessage: "Next",
    description: "Button to navigate to the next modal",
  },
  cancelButton: {
    id: "invoices.cardOnFileClientModal.cancelButton",
    defaultMessage: "Cancel",
    description: "Button to cancel process of requesting card on file clients",
  },
  defaultLabel: {
    id: "invoices.cardOnFileClientModal.defaultLabel",
    defaultMessage: "Default",
    description: "Label for default payment method",
  },
  clientNameColumn: {
    id: "invoices.cardOnFileClientModal.clientNameColumn",
    defaultMessage: "Name",
    description: "Column header for the client name",
  },
  clientEmailColumn: {
    id: "invoices.cardOnFileClientModal.clientEmailColumn",
    defaultMessage: "Email Address",
    description: "Column header for the client email address",
  },
});
