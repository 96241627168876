import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

export function upgradeButton() {
  return screen.getByRole("link", { name: "Upgrade" });
}

export function itemLinkWithProgress(
  name: string,
  progress: number,
  as: "query" | "get" = "get",
) {
  const params = [
    "link",
    { name: `${progress} ${name} ${progress}/5` },
  ] as const;

  if (as === "query") {
    return screen.queryByRole(...params);
  }

  return screen.getByRole(...params);
}

export function hoverOnTooltip() {
  return userEvent.hover(screen.getByTestId("help"));
}

export function unhHoverOnTooltip() {
  return userEvent.unhover(screen.getByTestId("help"));
}

export function tooltipMessage(time: string) {
  return screen.findByText(`Your monthly limit will reset in ${time}`);
}
