import React from "react";
import {
  Box,
  Content,
  Emphasis,
  Heading,
  SideDrawer,
} from "@jobber/components";
import { useQuery } from "@apollo/client";
import { CustomFieldRenderer } from "~/jobber/workItems/views/WorkItemsPage/components/ProductsAndServicesList/components/CustomFields/components/CustomFieldRenderer";
import type {
  ProductOrServiceCustomFieldsQuery,
  ProductOrServiceCustomFieldsQueryVariables,
} from "~/utilities/API/graphql";
import styles from "./CustomFieldSideDrawer.module.css";
import { PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY } from "../LineItemCustomFieldChips/LineItemCustomFieldChips.graphql";
import { useProductAndServiceCustomFieldsByAppAndStatus } from "../../hooks/useProductAndServiceCustomFieldsByAppAndStatus";
import type { App } from "../CustomFieldChip/CustomFieldChip";

export function CustomFieldSideDrawer({
  open,
  onRequestClose,
  productOrServiceId,
  lineItemThumbnailUrl,
  app,
}: CustomFieldSideDrawerProps) {
  const { data, loading } = useQuery<
    ProductOrServiceCustomFieldsQuery,
    ProductOrServiceCustomFieldsQueryVariables
  >(PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY, {
    variables: { productOrServiceId },
    skip: !productOrServiceId,
  });

  const productOrService = data?.productOrService;

  const thumbnailUrl =
    lineItemThumbnailUrl || productOrService?.fileAttachment?.thumbnailUrl;

  const shouldDisplayContent =
    !loading && productOrService && productOrService.customFields.length > 0;

  return (
    <SideDrawer open={open} onRequestClose={onRequestClose}>
      <SideDrawer.Title>{productOrService?.name || ""}</SideDrawer.Title>
      {thumbnailUrl && (
        <CustomFieldSideDrawerThumbnail
          src={thumbnailUrl}
          alt="product-or-service-img"
        />
      )}
      {shouldDisplayContent && (
        <CustomFieldSideDrawerContent
          app={app}
          productOrService={productOrService}
        />
      )}
    </SideDrawer>
  );
}

function CustomFieldSideDrawerThumbnail({
  src,
  alt,
}: {
  src: string;
  alt: string;
}) {
  return (
    <Box padding="large" background="surface--background--subtle">
      <img
        src={src}
        alt={alt}
        className={styles.customFieldSideDrawerThumbnail}
      />
    </Box>
  );
}

function CustomFieldSideDrawerContent({
  app,
  productOrService,
}: Pick<CustomFieldSideDrawerProps, "app"> & {
  productOrService: ProductOrServiceCustomFieldsQuery["productOrService"];
}) {
  const {
    customFieldsByAppAndStatus: { unArchived: customFieldsByApp },
  } = useProductAndServiceCustomFieldsByAppAndStatus(
    productOrService.customFields || [],
  );

  const appCustomFields = customFieldsByApp[app.name];
  return (
    <Content>
      <Box gap="base" direction="row" alignItems="center">
        <Box radius="small" width={24} height={24}>
          <img src={app.logoUrl} alt={`${app.name} logo`} />
        </Box>
        <Heading level={4}>{app.name}</Heading>
      </Box>

      <Content>
        {appCustomFields.map(field => (
          <Box gap="large" direction="row" alignItems="center" key={field.id}>
            <Box width={150}>
              <Emphasis variation="bold">{field.label}</Emphasis>
            </Box>
            <Box width="grow">
              <CustomFieldRenderer field={field} />
            </Box>
          </Box>
        ))}
      </Content>
    </Content>
  );
}

interface CustomFieldSideDrawerProps {
  open: boolean;
  onRequestClose: () => void;
  productOrServiceId: string;
  lineItemThumbnailUrl: string;
  app: App;
}
