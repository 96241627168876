import type { JobberPaymentsDataSharingConsentActions } from "~/utilities/API/graphql";

export interface DataSharingConsentUpsertInterface {
  variables: {
    input:
      | JobberPaymentsDataSharingConsentActions.CONSENT
      | JobberPaymentsDataSharingConsentActions.DISABLE;
  };
}

const generatePayload = (
  input:
    | JobberPaymentsDataSharingConsentActions.CONSENT
    | JobberPaymentsDataSharingConsentActions.DISABLE,
): DataSharingConsentUpsertInterface => ({
  variables: {
    input,
  },
});

export async function upsertDecision(
  decision:
    | JobberPaymentsDataSharingConsentActions.CONSENT
    | JobberPaymentsDataSharingConsentActions.DISABLE,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSharingConsentUpsert: (args: DataSharingConsentUpsertInterface) => any,
): Promise<void> {
  const payload = generatePayload(decision);

  const { data } = await dataSharingConsentUpsert(payload);

  if (data?.jobberPaymentsDataSharingConsentUpsert?.userErrors) {
    throw new Error("Cannot create consent");
  }
}
