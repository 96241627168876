import { gql } from "@apollo/client";

export const FRANCHISEE_NAME_AUTOCOMPLETE_QUERY = gql`
  query FranchiseeNameAutoComplete($after: String) {
    accounts(after: $after, filter: { ignoreInvalidFranchiseeAccounts: true }) {
      nodes {
        id
        name
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
