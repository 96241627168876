/* eslint-disable @typescript-eslint/naming-convention */
export interface AutomationRule {
  action: AutomationAction;
  actionedCount: number;
  active: boolean;
  condition: AutomationCondition;
  id: string;
  name: string;
  trigger: AutomationTrigger;
}

export interface AllConditionTypes {
  task: "condition";
  arguments: {
    type: CreateRuleConditionTypes;
    fields: {
      contains?: boolean;
      tag?: string;
      includes?: boolean;
      item?: string;
      type?: string;
      status?: string;
      isLead?: boolean;
      text?: string;
      tooltip?: string;
    };
  };
  isNonDeletable?: boolean;
}

export type AutomationRuleWithParsedConditions = Omit<
  AutomationRule,
  "condition"
> & { condition: { task: string; conditions: AllConditionTypes[] } };

export interface CampaignCondition {
  task: "CampaignConditions";
  conditions: [
    | {
        type: "JobCondition";
        arguments: {
          job_type: string; // "recurring" | "one_off"
          include: boolean;
        };
      }
    | {
        type: "LineItemCondition";
        arguments: {
          work_item_id: number;
          include: boolean;
        };
      }
    | {
        type: "QuoteLineItemCondition";
        arguments: {
          work_item_id: number;
          include: boolean;
        };
      }
    | {
        type: "TagCondition";
        arguments: {
          expression: string;
        };
      }
    | {
        type: "QuoteStatusCondition";
        arguments: {
          status: string;
        };
      },
  ];
}

export interface AutomationTrigger {
  task: TriggerTask;
  arguments: {
    date_field?: string; // This is required depending on the TriggerTask
    model_type: string;
    time: number;
  };
}

export interface AutomationCondition {
  task: ConditionTask;
  arguments: {
    expression: string;
    type?: CreateRuleConditionTypes;
  };
}

export interface AutomationAction {
  task: ActionTask;
  arguments: {
    method?: string;
    message?: string;
  };
}

export interface RuleConditionText {
  text: string;
  tooltip?: string;
}

export enum ActionTask {
  NOTIFY = "notify",
  CAMPAIGN_DELIVERY = "campaign_delivery",
}

export enum ConditionTask {
  CONDITION = "condition",
}

export enum TriggerTask {
  TIME_SINCE_LAST_JOB = "time_since_last_job",
  AFTER_DATE = "after_date",
}

export enum TriggerTime {
  ONE_MONTH_IN_HOURS = 730,
  TWO_MONTHS_IN_HOURS = 1460,
  FOUR_MONTHS_IN_HOURS = 2920,
  SIX_MONTHS_IN_HOURS = 4380,
  TWELVE_MONTHS_IN_HOURS = 8760,
}

export enum TriggerTimeForQuotes {
  TWO_DAYS_IN_HOURS = 48,
  THREE_DAYS_IN_HOURS = 72,
  FIVE_DAYS_IN_HOURS = 120,
  SEVEN_DAYS_IN_HOURS = 168,
  NINE_DAYS_IN_HOURS = 216,
  TWO_WEEKS_IN_HOURS = 336,
  THREE_WEEKS_IN_HOURS = 504,
  FOUR_WEEKS_IN_HOURS = 672,
}

export enum ModelType {
  PAST_CLIENTS_CAMPAIGN = "CampaignPastClients",
  QUOTE = "Quote",
}

export enum DateField {
  ENDS_AT = "ends_at",
  SENT_AT = "sent_at",
}

export enum CriteriaModel {
  JOB = "Job",
}

export enum CreateRuleConditionTypes {
  TextOnly = "TextOnly",
  ClientTags = "ClientTags",
  LineItems = "LineItems",
  JobType = "JobType",
  QuoteClientTags = "QuoteClientTags",
  QuoteLineItems = "QuoteLineItems",
  ClientLeadStatus = "ClientLeadStatus",

  // We have decided that different automation templates will have different options for the same condition types
  // Quote status is evaluated the same way on the server with the same predicate expressions but we identify the different
  // conditions so that we can present unique option sets in the UI.
  QuoteStatus = "QuoteStatus", // Used in Lost Leads Automation
  CloseOnPendingQuotesQuoteStatus = "CloseOnPendingQuotesQuoteStatus",
}
