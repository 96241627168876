import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { formatPrice } from "~/shared/billing/pricePreview/utils";
import styles from "./TaxInfo.module.css";
import { messages } from "./messages";

interface TaxInfoProps {
  taxAmount?: number;
  taxRate?: number;
}

export function TaxInfo({ taxAmount, taxRate }: TaxInfoProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.columnContainer} data-testid="tax-summary">
      <Text size="large">
        {formatTaxRate(formatMessage(messages.tax), taxRate)}
      </Text>
      <Text size="large">
        <Emphasis variation="bold">
          {taxRate ? formatPrice(taxAmount) : "--"}
        </Emphasis>
      </Text>
    </div>
  );
}

function formatTaxRate(title: string, taxRate?: number) {
  if (!taxRate) {
    return title;
  }

  const percentageTaxRate = taxRate * 100;
  const taxRateString = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(percentageTaxRate);
  return `${title} (${taxRateString}%)`;
}
