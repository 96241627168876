import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import type {
  MigrateLegacyQuickbooksOnlineIntegrationMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { QuickbooksLegacyMigrationModal } from "./QuickbooksLegacyMigrationModal";
import { MIGRATE_LEGACY_QUICKBOOKS_ONLINE_INTEGRATION } from "./graphql";

interface QuickbooksLegacyMigrationModalLoaderProps {
  open: boolean;
  onRequestClose: () => void;
  redirectUrl: string;
}

export function QuickbooksLegacyMigrationModalLoader(
  props: QuickbooksLegacyMigrationModalLoaderProps,
) {
  const { open, onRequestClose, redirectUrl } = props;
  const [error, setError] = useState<boolean>(false);
  const [
    migrateLegacyQuickbooksOnlineIntegration,
    { loading: migrationLoading },
  ] = useMutation<MigrateLegacyQuickbooksOnlineIntegrationMutation>(
    MIGRATE_LEGACY_QUICKBOOKS_ONLINE_INTEGRATION,
    {
      onCompleted: data => {
        setError(false);
        const mutationErrors = data?.migrateLegacyQuickbooksOnlineIntegration
          ?.userErrors as MutationErrors[];
        if (
          mutationErrors?.length == 0 &&
          data?.migrateLegacyQuickbooksOnlineIntegration?.success
        ) {
          window.open(redirectUrl, "_blank");
          window.location.replace("/home");
        } else {
          setError(true);
        }
      },
      onError: () => setError(true),
    },
  );

  const onClose = () => {
    setError(false);
    onRequestClose();
  };

  return (
    <QuickbooksLegacyMigrationModal
      open={open}
      onClose={onClose}
      handleContinue={migrateLegacyQuickbooksOnlineIntegration}
      migrationLoading={migrationLoading}
      error={error}
    />
  );
}
