/* eslint-disable import/no-internal-modules */
import React, { ComponentProps, useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { CopyButton } from "./components/CopyButton/CopyButton";
import { ShareToGoogleProfileButton } from "./components/ShareToGoogleProfileButton/ShareToGoogleProfileButton";
import { GoogleInfoTip } from "./components/GoogleInfoTip/GoogleInfoTip";

interface ShareLinkFooterProps {
  canEnableBooking: boolean;
  bookingUrl: string;
  banners?: React.ReactNode;
  googleSharingSettings: {
    enabled: boolean;
    firstEnabledAt?: Date;
  };
  onCopyBookingUrl?: () => void;
  onClickPreview?: () => void;
  showShareToGoogleProfile?: boolean;
}
export function ShareLinkFooter({
  canEnableBooking,
  bookingUrl,
  banners,
  googleSharingSettings,
  onCopyBookingUrl,
  onClickPreview,
  showShareToGoogleProfile = true,
}: ShareLinkFooterProps) {
  const { formatMessage } = useIntl();
  const handlePreview = useCallback(() => {
    window.open(bookingUrl, "_blank", "noopener, noreferrer");
    onClickPreview?.();
  }, [bookingUrl, onClickPreview]);

  const actions: Action[] = [
    {
      render: ({ type }) => (
        <CopyButton
          type={type}
          key="action-copy"
          value={bookingUrl}
          onClick={onCopyBookingUrl}
          initialIcon="copy"
          disabled={!canEnableBooking}
        />
      ),
    },
    {
      render: ({ type }) => (
        <Button
          type={type}
          key="action-preview"
          label={formatMessage(messages.previewButton)}
          icon="eye"
          onClick={handlePreview}
        />
      ),
    },
  ];

  if (showShareToGoogleProfile) {
    actions.unshift({
      render: ({ type }) => (
        <ShareToGoogleProfileButton
          type={type}
          key="action-google"
          value={googleSharingSettings.enabled}
          disabled={!canEnableBooking}
        />
      ),
    });
  }

  return (
    <div className={styles.shareLinkFooter}>
      <div className={styles.shareLinkContainer}>
        {banners && <div className={styles.shareError}>{banners}</div>}
        <div className={styles.shareLinkHeader}>
          <Heading level={2}>{formatMessage(messages.heading)}</Heading>
        </div>
        <div className={styles.shareToolbar}>
          {actions.map(({ render }, index) => {
            const type = index === 0 ? "primary" : "secondary";
            return render({ type });
          })}
        </div>
        {showShareToGoogleProfile && (
          <div className={styles.shareHelp}>
            <GoogleInfoTip {...googleSharingSettings} />
          </div>
        )}
      </div>
    </div>
  );
}

interface Action {
  render: (props: {
    type: React.ComponentProps<typeof Button>["type"];
  }) => JSX.Element;
}
