import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useReducer } from "react";
import CreditCardListItem from "jobber/payments_sca/components/CreditCardListItem/CreditCardListItem";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { AmountInput } from "./AmountInput";
import { SimpleListItem } from "./SimpleListItem";
import { useWithdrawFundsRequests } from "./useWithdrawFundsRequests";
import {
  type DepositResult,
  initWithdrawFundsDialogReducer,
  withdrawFundsDialogReducer,
} from "./withdrawFundsDialogReducer";

export interface WithdrawFundsDialogProps {
  isOpen: boolean;
  instantAvailableAmount: string;
  currencyUnit: string;
  feeMultiplier: string;

  debitCard: CreditCard;

  onRequestClose(): void;
  onDepositSuccess(deposited: DepositResult): void;
}

export function WithdrawFundsDialog(props: WithdrawFundsDialogProps) {
  const { isOpen, onRequestClose, onDepositSuccess, currencyUnit, debitCard } =
    props;

  const [state, dispatch] = useReducer(
    withdrawFundsDialogReducer,
    props,
    initWithdrawFundsDialogReducer,
  );

  const {
    formattedPayoutLimit,
    formattedPayoutFeeTotal,
    formattedPayoutFeePercent,
    formattedWithdrawalAmount,
    requestedAmount,
    isLoading,
    inputErrorMessage,
    withdrawRequestErrorMessage,
    depositResult,
  } = state;

  useWithdrawFundsRequests(state, dispatch);

  useEffect(() => {
    if (!depositResult) return;

    onDepositSuccess(depositResult);
    onRequestClose();
    dispatch({ type: "init", payload: props });
  }, [depositResult]);

  useEffect(
    () => dispatch({ type: "init", payload: props }),
    [props.instantAvailableAmount],
  );

  return (
    <Modal
      open={isOpen}
      onRequestClose={onRequestClose}
      title="Withdraw Funds"
      size="small"
      secondaryAction={{ label: "Cancel", onClick: onRequestClose }}
      primaryAction={{
        label: `Withdraw ${formattedWithdrawalAmount}`,
        disabled: !!inputErrorMessage,
        onClick: () => dispatch({ type: "withdrawFunds" }),
        loading: isLoading,
      }}
    >
      <Content>
        {withdrawRequestErrorMessage && (
          <Banner type="error">{withdrawRequestErrorMessage}</Banner>
        )}
        <Text>
          You can withdraw up to{" "}
          <Emphasis variation="bold">{formattedPayoutLimit}</Emphasis>
        </Text>

        <AmountInput
          amount={requestedAmount}
          currencyUnit={currencyUnit}
          onChange={setAmount}
          onBlur={onInputBlur}
          errorMessage={inputErrorMessage}
          disabled={isLoading}
        />
        <Content spacing="small">
          <SimpleListItem
            leftText={`Instant payouts fee (${formattedPayoutFeePercent})`}
            rightText={formattedPayoutFeeTotal}
          />
          <SimpleListItem
            leftText="Deposit to"
            rightText={<CreditCardListItem size="compact" {...debitCard} />}
          />
          <Text variation="subdued">
            Payouts may take a few minutes and vary by bank
          </Text>
        </Content>
      </Content>
    </Modal>
  );

  function setAmount(amount: string) {
    dispatch({
      type: "requestAmountChange",
      payload: { requestingAmount: amount },
    });
  }

  function onInputBlur() {
    dispatch({ type: "formatAmount" });
  }
}
