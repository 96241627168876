import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addressFieldLabelUS: {
    id: "selfServeBookings.ServiceAreaEditModal.addressFieldLabelUS",
    defaultMessage: "Enter an address or ZIP code",
    description: "Label for the address field in the US",
  },
  addressFieldLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.addressFieldLabel",
    defaultMessage: "Enter an address or postal code",
    description: "Label for the address field",
  },
  modalTitle: {
    id: "selfServeBookings.ServiceAreaEditModal.modalTitle",
    defaultMessage: "Add service area",
    description: "Title of the Add Service Area Modal",
  },
  modalTitleEdit: {
    id: "selfServeBookings.ServiceAreaEditModal.modalTitleEdit",
    defaultMessage: "Edit service area",
    description: "Title of the Edit Service Area Modal",
  },
  primaryActionLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.primaryActionLabel",
    defaultMessage: "Save",
    description: "Label for the primary action button",
  },
  secondaryActionLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.secondaryActionLabel",
    defaultMessage: "Cancel",
    description: "Label for the secondary action button",
  },
  errorBanner: {
    id: "selfServeBookings.ServiceAreaEditModal.errorBanner",
    defaultMessage: "Something went wrong. Please try again.",
    description: "Generic error message for the error banner",
  },
  serviceAreaSwitchAriaLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.serviceAreaSwitchAriaLabel",
    defaultMessage: "Service area enablement switch",
    description:
      "Aria Label for the service area enablement/disablement switch",
  },
  serviceAreaSwitchDescription: {
    id: "selfServeBookings.ServiceAreaEditModal.serviceAreaSwitchDescription",
    defaultMessage: "Limit bookings to service area",
    description: "Description for the service area switch",
  },
  addressFieldClearButtonLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.addressFieldClearButtonLabel",
    defaultMessage: "Clear Address",
    description: "Aria Label for the address field clear button",
  },
  addressFieldRequiredError: {
    id: "selfServeBookings.ServiceAreaEditModal.addressFieldRequiredError",
    defaultMessage: "Location is required",
    description: "Error message for the address field",
  },
  radiusFieldLabel: {
    id: "selfServeBookings.ServiceAreaEditModal.radiusFieldLabel",
    defaultMessage: "Radius",
    description: "Label for the radius field",
  },
  savedMessage: {
    id: "selfServeBookings.ServiceAreaEditModal.savedMessage",
    defaultMessage: "Service area updated",
    description: "Toast message when the save completes",
  },
});
