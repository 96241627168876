import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { usePaymentMethodsJobberOnline } from "jobber/payments_react/hooks/usePaymentMethods/usePaymentMethodsJobberOnline";
import { ClientPaymentMethodsList } from "./ClientPaymentMethodsList";
import { AddPaymentMethod } from "../AddPaymentMethod";

export interface ClientPaymentMethodsProps {
  clientId: string;
  canAddPaymentMethod: boolean;
  countryCodesToNameMap: Record<string, string>;
  requestCardOnFileEmailUrl: string;
  requestCardOnFileSmsUrl: string;
  hasAutoPayJob: boolean;
}

export function ClientPaymentMethods(props: ClientPaymentMethodsProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    error: fetchPaymentMethodsError,
    loading: fetchPaymentMethodsLoading,
    paymentMethods,
    refetchPaymentMethods,
    deletePaymentMethod,
    setDefaultPaymentMethod,
  } = usePaymentMethodsJobberOnline({
    clientId: props.clientId,
    includeBankAccounts: true,
    isClientIdEncoded: true,
  });

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  return (
    <>
      <Content>
        <AddPaymentMethod
          clientId={props.clientId}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          countryCodesToNameMap={props.countryCodesToNameMap}
          refetchPaymentMethods={refetchPaymentMethods}
        />
      </Content>

      <Card
        header={{
          title: "Payment Methods",
          action: props.canAddPaymentMethod ? (
            <Button
              label="Add"
              icon="add"
              onClick={toggleModal}
              data-testid="add-button"
            />
          ) : undefined,
        }}
      >
        <ClientPaymentMethodsList
          error={fetchPaymentMethodsError}
          isLoading={fetchPaymentMethodsLoading}
          paymentMethods={paymentMethods}
          requestCardOnFileEmailUrl={props.requestCardOnFileEmailUrl}
          requestCardOnFileSmsUrl={props.requestCardOnFileSmsUrl}
          onDeletePaymentMethod={deletePaymentMethod}
          onSetDefaultPaymentMethod={setDefaultPaymentMethod}
          hasAutoPayJob={props.hasAutoPayJob}
        />
      </Card>
    </>
  );
}
