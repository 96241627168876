import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.connected.title",
    defaultMessage: "Your profile is connected",
    description: "Title for the connected step",
  },
  subtitle: {
    id: "setupGuide.connected.subtitle",
    defaultMessage: "Setup time 3 min",
    description: "Subtitle for the connected step",
  },
  description: {
    id: "setupGuide.connected.description",
    defaultMessage:
      "Your clients will be linked directly to your Google Business profile to leave reviews. Continue your setup to preview messages, see insights and manage reviews.",
    description: "Description for the connected step",
  },
  primaryButton: {
    id: "setupGuide.connected.primaryButton",
    defaultMessage: "Continue",
    description: "Button that navigates to the profile selection step",
  },
  secondaryButton: {
    id: "setupGuide.connected.secondaryButton",
    defaultMessage: "Disconnect",
    description: "Button that disconnects the Google Business Account",
  },
  generalError: {
    id: "reviews.reviewsPage.generalErrorBanner",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "A general warning that something has gone wrong while loading Google reviews",
  },
});
