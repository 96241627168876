import React from "react";
import { Banner } from "@jobber/components/Banner";
import { useQuery, useSubscription } from "@apollo/client";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  QboObjectSyncStatsSubscription,
  SyncObjectStats,
  SyncObjectStatsQuery,
} from "~/utilities/API/graphql";
import { QBO_ERROR_QUERY } from "./queries/error_query";
import { NEW_SYNC_STATUS_SUBSCRIPTION } from "./queries/error_subscription";

interface QuickbooksErrorBannerProps {
  modelId: number;
  modelType: string;
}

interface SubscriptionSyncStatusProps {
  queryData: SyncObjectStats;
}

export function QuickbooksErrorBanner(props: QuickbooksErrorBannerProps) {
  return (
    <APIProvider>
      <QuickbooksErrorBannerWrapper
        modelId={props.modelId}
        modelType={props.modelType}
      />
    </APIProvider>
  );
}

function QuickbooksErrorBannerWrapper(props: QuickbooksErrorBannerProps) {
  const { loading, error, data } = useQuery<SyncObjectStatsQuery>(
    QBO_ERROR_QUERY,
    {
      variables: {
        integrationType: "QBO",
        modelId: props.modelId,
        modelType: props.modelType,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <></>;
  }

  if (error) {
    throw Error(
      `Graphql/Apollo error in QuickbooksErrorBanner component ${error}`,
    );
  }

  return <SubscriptionSyncStatusData queryData={data.syncObjectStats} />;
}

function SubscriptionSyncStatusData(props: SubscriptionSyncStatusProps) {
  const syncStats = props.queryData;

  const { error, data } = useSubscription<QboObjectSyncStatsSubscription>(
    NEW_SYNC_STATUS_SUBSCRIPTION,
  );

  if (error) {
    throw Error(
      `Graphql/Apollo error in QuickbooksErrorBanner component ${error}`,
    );
  }

  if (data) {
    const newStats = data.qboObjectSyncStats.qboSyncStats;
    if (
      newStats.modelId === syncStats.modelId &&
      newStats.modelType === syncStats.modelType
    ) {
      syncStats.message = newStats.message;
    }
  }

  if (syncStats.message) {
    return (
      <Banner type="error">{`QuickBooks sync error — ${syncStats.message}`}</Banner>
    );
  } else {
    return <></>;
  }
}
