/* eslint-disable max-statements */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Icon } from "@jobber/components/Icon";
import styles from "./Carousel.module.css";

export interface CarouselProps {
  children: React.ReactNode;
  onIndexChange?: (index: number) => void;
  slideIndex?: number;
  showIndicators?: boolean;
  autoplaySeconds?: number;
  showArrowIndicators?: boolean;
}

export function Carousel({
  children,
  onIndexChange,
  slideIndex = 0,
  showIndicators = true,
  autoplaySeconds,
  showArrowIndicators = false,
}: CarouselProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(slideIndex);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1));
    } else if (event.key === "ArrowRight") {
      setCurrentIndex(prevIndex =>
        Math.min(React.Children.count(children) - 1, prevIndex + 1),
      );
    }
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setStartX(event.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentX = event.touches[0].clientX;
    const diff = currentX - startX;
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const swipeThreshold = containerWidth / 5; // 20% of slide width

    if (diff > swipeThreshold) {
      setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1));
      setIsDragging(false);
    } else if (diff < -swipeThreshold) {
      setCurrentIndex(prevIndex =>
        Math.min(React.Children.count(children) - 1, prevIndex + 1),
      );
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(currentIndex);
    }
  }, [currentIndex, onIndexChange]);

  useEffect(() => {
    goToSlide(slideIndex);
  }, [slideIndex]);

  useEffect(() => {
    if (autoplaySeconds) {
      const interval = setInterval(() => {
        if (currentIndex === React.Children.count(children) - 1) {
          goToSlide(0);
        } else {
          goToSlide(currentIndex + 1);
        }
      }, autoplaySeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [autoplaySeconds, children, currentIndex]);

  return (
    <div
      className={`${styles.carouselContainer} ${
        showIndicators ? styles.carouselIndicatorPadding : ""
      }`}
    >
      <div
        ref={containerRef}
        className={styles.carouselWrapper}
        style={{
          transform: `translateX(calc(-${currentIndex} * 100%))`,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        role="region"
        aria-live="polite"
      >
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={classNames(styles.carouselSlide, {
              [styles.hideCarouselSlideWidth]: index !== currentIndex,
            })}
            aria-hidden={index !== currentIndex}
            tabIndex={index === currentIndex ? 0 : -1}
            onKeyDown={handleKeyDown}
          >
            {child}
          </div>
        ))}
      </div>
      {showIndicators && (
        <div className={styles.carouselIndicators}>
          {showArrowIndicators && (
            <button
              onClick={() => handleArrowIndicatorClick(currentIndex - 1)}
              className={styles.carouselArrowPrev}
              aria-label="Previous slide arrow indicator"
            >
              <Icon size="base" name="arrowLeft" />
            </button>
          )}
          {React.Children.map(children, (_, index) => (
            <button
              key={index}
              className={`${styles.carouselIndicator} ${
                currentIndex === index ? styles.active : ""
              }`}
              onClick={() => goToSlide(index)}
              aria-label={`Slide ${index + 1} indicator`}
              tabIndex={currentIndex === index ? 0 : -1}
            />
          ))}
          {showArrowIndicators && (
            <button
              onClick={() => handleArrowIndicatorClick(currentIndex + 1)}
              className={styles.carouselArrowNext}
              aria-label="Next slide arrow indicator"
            >
              <Icon size="base" name="arrowRight" />
            </button>
          )}
        </div>
      )}
    </div>
  );

  function handleArrowIndicatorClick(nextIndex: number) {
    if (nextIndex >= 0 && nextIndex <= React.Children.count(children) - 1) {
      goToSlide(nextIndex);
    } else if (nextIndex === React.Children.count(children)) {
      goToSlide(0);
    }
  }
}
