import { useIntl } from "react-intl";
import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { messages } from "./messages";
import styles from "./CampaignRecipientSection.module.css";

export interface CampaignRecipientsSectionProps {
  loading: boolean;
  summaryMessage: string | JSX.Element | (string | JSX.Element)[];
  timeoutError: boolean;
  redirectToEdit: () => void;
}

export function CampaignRecipientsSection({
  loading,
  summaryMessage,
  timeoutError,
  redirectToEdit,
}: CampaignRecipientsSectionProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading level={4}>
        {formatMessage(messages.campaignRecipientsTitle)}
      </Heading>
      {loading && <Glimmer width={500} />}
      {!loading && (
        <div
          className={styles.recipientContainer}
          data-testid="recipientSummary"
        >
          <div className={styles.summaryMessage}>{summaryMessage}</div>
          <div className={styles.editContainer}>
            <Button
              label={formatMessage(messages.campaignRecipientEditButton)}
              onClick={redirectToEdit}
              type="tertiary"
              size="base"
              variation="work"
            />
          </div>
        </div>
      )}
      {timeoutError && (
        <Text size="small" variation="warn">
          {formatMessage(messages.campaignRecipientTimeoutError)}
        </Text>
      )}
    </>
  );
}
