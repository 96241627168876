import { useMemo } from "react";
import type { ClientSegmentTableClientData } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentTable/ClientSegmentTable";
import type {
  ClientSegmentPayload,
  ClientSegmentsData,
} from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import type { Segment } from "~/utilities/API/graphql";

interface ClientFragment {
  id: string;
  defaultEmails: Array<string>;
  name: string;
  emails: Array<{
    __typename?: "Email";
    primary: boolean;
    id: string;
    description: string;
    address: string;
  }>;
}

export function transformClientFragment(client: ClientFragment) {
  return {
    email: client.emails.find(email => email.primary)?.address,
    name: client.name,
  };
}
function transformClients(payload: ClientSegmentPayload | undefined) {
  return (
    payload?.data?.clients?.edges
      ?.map((edge: { node: ClientFragment }) =>
        transformClientFragment(edge.node),
      )
      ?.filter<ClientSegmentTableClientData>(
        (
          _client: ClientSegmentTableClientData,
        ): _client is ClientSegmentTableClientData =>
          _client.email !== undefined,
      ) || []
  );
}

interface UseClientDataForSelectedSegmentParams {
  clientSegments: ClientSegmentsData;
  selectedSegment: Segment;
}

export function useClientDataForSelectedSegment({
  clientSegments,
  selectedSegment,
}: UseClientDataForSelectedSegmentParams) {
  const dataToTransform = useMemo(
    () =>
      Object.values(clientSegments).find(
        clientSegment => clientSegment.type === selectedSegment,
      ) as ClientSegmentPayload,
    [clientSegments, selectedSegment],
  );
  const chosenClientSegment = useMemo(
    () => transformClients(dataToTransform),
    [dataToTransform],
  );

  return {
    clientSegmentData: chosenClientSegment,
    total: dataToTransform?.total,
  };
}
