import React from "react";
import type {
  ManualPagination,
  ManualSorting,
} from "@jobber/components/DataTable";
import { Report } from "~/jobber/features/Reporting/components/Report/Report";
import type { ReportFiltersProps } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import type { ReportExportProps } from "~/jobber/features/Reporting/components/Report/components/ReportExport/types";
import type { ReportProps } from "~/jobber/features/Reporting/components/Report/types";
import { getColumnConfig } from "jobber/franchise/features/Reporting/views/RoyaltyReport/helpers/columns";
import { REPORT_HEADER } from "./constants";
import type { RoyaltyData, RoyaltyTotalsData } from "./types";
import { AboutRoyaltyReport } from "./components/AboutRoyaltyReport";

export interface RoyaltyReportProps extends ReportProps {
  data: RoyaltyData[] | undefined;
  totalsData: RoyaltyTotalsData | undefined;
  loading: boolean;
  error: string | undefined;
  filterProps: ReportFiltersProps;
  sortingProps: ManualSorting;
  exportProps: ReportExportProps;
  paginationProps: ManualPagination;
}

export function RoyaltyReport({
  data,
  totalsData,
  loading,
  error,
  filterProps,
  sortingProps,
  exportProps,
  paginationProps,
}: RoyaltyReportProps) {
  const royaltyReportColumns = getColumnConfig(totalsData);

  return (
    <Report
      data={data}
      columns={royaltyReportColumns}
      title={REPORT_HEADER}
      loading={loading}
      error={error}
      pagination={paginationProps}
      sorting={sortingProps}
      filterProps={filterProps}
      exportProps={exportProps}
      infoPopoverContent={<AboutRoyaltyReport />}
    />
  );
}
