import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type {
  AuthorizationDomainActionType,
  AuthorizationKeyType,
  GetDomainSubjectBelongingToDomainAction,
} from "./authorizationTypes";
import { authorizationRules } from "./authorizationRules";
import { useAuthorizationContext } from "./AuthorizationContextProvider";

interface UseAuthorization {
  can<TActionType extends AuthorizationDomainActionType>(
    action: TActionType,
    subject: GetDomainSubjectBelongingToDomainAction<TActionType>,
  ): boolean;
  authorizationLoading: boolean;
}

export function useAuthorization(): UseAuthorization {
  const { features } = useAccount();
  const { permissions, isAdmin, loading } = useAuthorizationContext();
  const userRules = authorizationRules(isAdmin, permissions, features);

  const can = <TActionType extends AuthorizationDomainActionType>(
    action: TActionType,
    subject: GetDomainSubjectBelongingToDomainAction<TActionType>,
  ) => {
    const index = `${action}${subject}` as AuthorizationKeyType;
    const permissionRule = userRules[index];

    if (!permissionRule) {
      throw Error(
        `Action "${action}" or subject "${subject}" does not exist in permission rules.
        Add a new rule in utilities/contexts/authorization/authorizationRules.ts`,
      );
    }

    return permissionRule.condition();
  };

  return { can, authorizationLoading: loading };
}
