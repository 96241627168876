import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { InvoicePeriod } from "jobber/workOrders/components/InvoiceScheduler/types";

export interface InvoicePeriodDropdownProps {
  invoicePeriodOption: InvoicePeriod;
  disabled: boolean;
  onChange(nextState: InvoicePeriod): void;
}

const dropdownValues = {
  afterEachVisit: {
    label: "After each visit is completed",
    value: InvoicePeriod.AfterEachVisit,
  },
  lastDayOfMonth: {
    label: "Monthly on the last day of month",
    value: InvoicePeriod.LastDayOfMonth,
  },
  onJobClose: {
    label: "Once when job is closed",
    value: InvoicePeriod.OnJobClose,
  },
  asNeeded: {
    label: "As needed, don't send me invoice reminders",
    value: InvoicePeriod.AsNeeded,
  },
  custom: {
    label: "Custom schedule",
    value: InvoicePeriod.Custom,
  },
};

export const InvoicePeriodDropdown = ({
  invoicePeriodOption,
  disabled,
  onChange,
}: InvoicePeriodDropdownProps) => {
  return (
    <Select
      name="invoice_period_dropdown"
      value={invoicePeriodOption}
      onChange={onChange}
      disabled={disabled}
    >
      <Option value={`${dropdownValues.afterEachVisit.value}`}>
        {dropdownValues.afterEachVisit.label}
      </Option>
      <Option value={`${dropdownValues.lastDayOfMonth.value}`}>
        {dropdownValues.lastDayOfMonth.label}
      </Option>
      <Option value={`${dropdownValues.onJobClose.value}`}>
        {dropdownValues.onJobClose.label}
      </Option>
      <Option value={`${dropdownValues.asNeeded.value}`}>
        {dropdownValues.asNeeded.label}
      </Option>
      <Option value={`${dropdownValues.custom.value}`}>
        {dropdownValues.custom.label}
      </Option>
    </Select>
  );
};
