import { gql } from "@apollo/client";

export const PUBLIC_APPS_QUERY = gql`
  query apps($searchTerm: String) {
    apps(searchTerm: $searchTerm) {
      nodes {
        id
        name
        description
      }
    }
  }
`;
