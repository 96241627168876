/* eslint-disable import/no-internal-modules */
import React from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import classNames from "./SchedulingDetailsForm.module.css";
import {
  SelectDuration,
  SelectionType,
} from "../SelectDuration/SelectDuration";
import { SelectTeamMember } from "../SelectTeamMember/SelectTeamMember";

export interface Assignee {
  id: string;
  name: string;
}

export const SchedulingDetailsForm = ({
  durationInSeconds,
  driveTimeLimitInSeconds,
  availabilityLoading,
  selectedTeamMembers,
  onChangeDuration,
  onChangeDriveTimeLimit,
  onSelectTeamMembers,
}: {
  availabilityLoading: boolean;
  durationInSeconds: number;
  driveTimeLimitInSeconds: number;
  onChangeDuration: (seconds: number) => void;
  onChangeDriveTimeLimit: (seconds: number) => void;
  onSelectTeamMembers: (assignees: Map<string, boolean>) => void;
  selectedTeamMembers: Map<string, boolean>;
}) => {
  return (
    <section className={classNames.container} data-testid="scheduling-details">
      <div>
        <Content>
          <Heading level={4}>Filter availability</Heading>
          <SelectDuration
            selectionType={SelectionType.duration}
            duration={durationInSeconds}
            onChange={onChangeDuration}
            loading={availabilityLoading}
          />
          <SelectDuration
            selectionType={SelectionType.driveTime}
            duration={driveTimeLimitInSeconds}
            onChange={onChangeDriveTimeLimit}
          />
        </Content>
      </div>
      <SelectTeamMember
        onSelectTeamMembers={onSelectTeamMembers}
        selectedTeamMembers={selectedTeamMembers}
      />
    </section>
  );
};
