import { useEffect } from "react";

export interface Client {
  id: number;
  encodedId: string;
  email?: string;
  phone?: string;
  hasSmsAllowedPhone: boolean;
}

export type SelectedClient = Client | undefined;

interface ClientEvent {
  client: {
    id: number;
    encodedId: string;
    email?: string;
    phone?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    has_sms_allowed_phone: string | boolean;
  };
}

export function useDynamicClient(
  setClient: (selectedClient: SelectedClient) => void,
) {
  useEffect(() => {
    const listener = (event: CustomEvent<ClientEvent>) => {
      if (event?.detail?.client !== undefined) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { has_sms_allowed_phone, ...eventClient } = event.detail.client;
        setClient({
          ...eventClient,
          hasSmsAllowedPhone:
            has_sms_allowed_phone === true || has_sms_allowed_phone === "true",
        });
      }
    };

    window.addEventListener("jobber.client.updated", listener);

    return () => {
      window.removeEventListener("jobber.client.updated", listener);
    };
  }, [setClient]);
}
