import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import {
  HelpList,
  ResourceList,
} from "jobber/marketplace/views/IntegrationPage/components";
import { useIntegrationStateContext } from "jobber/marketplace/views/IntegrationPage/hooks/useIntegrationState";
import styles from "./IntegrationPageSecondaryContent.module.css";

export function IntegrationPageSecondaryContent() {
  let { helpArticles, resources } = useIntegrationStateContext();
  helpArticles = helpArticles.map(helpArticle => {
    return { ...helpArticle, external: true };
  });
  resources = resources.map(resource => {
    return { ...resource, external: true };
  });
  return (
    <Content spacing="large">
      {helpArticles.length > 0 && (
        <Content spacing="small">
          <Heading level={3}>Need help?</Heading>
          <HelpList items={helpArticles} />
        </Content>
      )}
      {resources.length > 0 && (
        <Content spacing="small">
          <div className={styles.resourcesHeading}>
            <Heading level={3}>Other resources</Heading>
          </div>
          <ResourceList items={resources} />
        </Content>
      )}
    </Content>
  );
}
