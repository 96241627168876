/* eslint-disable import/no-internal-modules */
import React, { useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { Markdown } from "@jobber/components/Markdown";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { showToast } from "@jobber/components/Toast";
import { Banner } from "@jobber/components/Banner";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { InputNumber } from "@jobber/components/InputNumber";
import { Chip, Chips } from "@jobber/components/Chips";
import { InputValidation } from "@jobber/components/InputValidation";
import { Text } from "@jobber/components/Text";
import { BufferDurationMinutes } from "~/utilities/API/graphql";
import { messages } from "./messages";
import {
  EfficientSchedulingInitialize,
  EfficientSchedulingReducer,
  EfficientSchedulingType,
} from "./EfficientSchedulingReducer";
import styles from "./styles.module.css";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";
import { useUpdateSchedule } from "../../hooks/useUpdateSchedule";

const MINIMUM_DRIVE_TIME = 1;
const MAXIMUM_DRIVE_TIME = 180;

interface EfficientSchedulingConfigurationProps {
  useBufferTime: boolean;
  bufferDurationMinutes: number;
  showDriveTime: boolean;
  useDriveTime: boolean;
  driveTimeMinutes: number;
}

export function EfficientSchedulingConfiguration({
  useBufferTime,
  bufferDurationMinutes,
  showDriveTime,
  useDriveTime,
  driveTimeMinutes,
}: EfficientSchedulingConfigurationProps) {
  const { formatMessage } = useIntl();
  const [modelOpen, setModalOpen] = useState(false);
  const [bufferDuration, setBufferDuration] = useState(bufferDurationMinutes);
  const [driveTime, setDriveTime] = useState(driveTimeMinutes);
  const [state, dispatch] = useReducer(
    EfficientSchedulingReducer,
    {
      useBufferTime: useBufferTime,
      useDriveTime: showDriveTime && useDriveTime,
    },
    EfficientSchedulingInitialize,
  );

  const {
    editSelfServeScheduleSettings,
    loading: saving,
    error,
  } = useUpdateSchedule();

  function handleReset() {
    toggleModal();
    setBufferDuration(bufferDurationMinutes);
    setDriveTime(driveTimeMinutes);
    dispatch({
      newSetting: EfficientSchedulingInitialize({
        useBufferTime,
        useDriveTime,
      }).EfficientSchedulingSetting,
    });
  }

  function toggleModal() {
    setModalOpen(current => !current);
  }

  async function handleSubmit() {
    await editSelfServeScheduleSettings({
      useBufferTime: state.useBufferTime,
      bufferDuration: state.useBufferTime
        ? toBufferDurationMinutes(bufferDuration)
        : undefined,
      useDriveTime: state.useDriveTime,
      maxDriveTime: state.useDriveTime ? driveTime : undefined,
    }).then(() => {
      toggleModal();
      showToast({ message: formatMessage(messages.saveMessage) });
    });
  }

  return (
    <>
      <ConfigurationSetting
        title={formatMessage(messages.configurationLabel)}
        onEdit={toggleModal}
      >
        <EfficientSchedulingConfigurationTitle
          useBufferTime={useBufferTime}
          bufferDurationMinutes={bufferDurationMinutes}
          useDriveTime={useDriveTime}
          showDriveTime={showDriveTime}
          driveTimeMinutes={driveTimeMinutes}
        />
      </ConfigurationSetting>
      <Modal
        title={formatMessage(messages.configurationLabel)}
        open={modelOpen}
        onRequestClose={toggleModal}
        size="large"
        primaryAction={{
          label: formatMessage(messages.modalSave),
          onClick: handleSubmit,
          loading: saving,
          disabled: saving,
        }}
        secondaryAction={{
          label: formatMessage(messages.modalCancel),
          onClick: handleReset,
          disabled: saving,
        }}
      >
        <Content>
          {error && (
            <Banner type={"error"}>
              {formatMessage(messages.errorMessage)}
            </Banner>
          )}
          <RadioGroup
            ariaLabel={formatMessage(messages.modalGroupLabel)}
            value={state.EfficientSchedulingSetting}
            onChange={value =>
              dispatch({ newSetting: value as EfficientSchedulingType })
            }
          >
            {EfficientSchedulingOptions({
              useBufferTime: state.useBufferTime,
              bufferDuration,
              setBufferDuration,
              showDriveTime,
              useDriveTime: state.useDriveTime,
              driveTime,
              setDriveTime,
            })}
          </RadioGroup>
        </Content>
      </Modal>
    </>
  );
}

function EfficientSchedulingConfigurationTitle({
  useBufferTime,
  bufferDurationMinutes,
  showDriveTime,
  useDriveTime,
  driveTimeMinutes,
}: EfficientSchedulingConfigurationProps) {
  const { formatMessage } = useIntl();
  const scheduleSetting = EfficientSchedulingInitialize({
    useBufferTime,
    useDriveTime: showDriveTime && useDriveTime,
  }).EfficientSchedulingSetting;

  const title = useMemo(() => {
    switch (scheduleSetting) {
      case EfficientSchedulingType.None:
        return formatMessage(messages.noRestrictionsTitle);
      case EfficientSchedulingType.BufferTime:
        return formatMessage(messages.bufferTimeTitle, {
          duration: bufferDurationMinutes,
        });
      case EfficientSchedulingType.DriveTime:
        return formatMessage(messages.driveTimeTitle, {
          duration: driveTimeMinutes,
        });
    }
  }, [formatMessage, bufferDurationMinutes, driveTimeMinutes, scheduleSetting]);

  return <Markdown basicUsage content={title} />;
}

function EfficientSchedulingOptions({
  useBufferTime,
  bufferDuration,
  showDriveTime,
  useDriveTime,
  driveTime,
  setDriveTime,
  setBufferDuration,
}: {
  showDriveTime: boolean;
  useBufferTime: boolean;
  bufferDuration: number;
  useDriveTime: boolean;
  driveTime: number;
  setDriveTime: (driveTime: number) => void;
  setBufferDuration: (bufferTime: number) => void;
}) {
  const { formatMessage } = useIntl();
  const [driveTimeValidationMessage, setDriveTimeValidationMessage] = useState<
    string | undefined
  >();
  const options = [];

  if (showDriveTime) {
    options.push(
      <RadioOption
        key={EfficientSchedulingType.DriveTime}
        value={EfficientSchedulingType.DriveTime}
        label={formatMessage(messages.driveTimeOption)}
        description={formatMessage(messages.driveTimeDescription)}
      >
        {useDriveTime && (
          <>
            <label>
              {formatMessage(messages.driveTimeFieldLabel, {
                input: (
                  <span className={styles.inlineInputWrapper}>
                    <InputNumber
                      inline={true}
                      keyboard={"numeric"}
                      min={MINIMUM_DRIVE_TIME}
                      max={MAXIMUM_DRIVE_TIME}
                      maxLength={5}
                      size="small"
                      suffix={{
                        label: formatMessage(messages.driveTimeUnitLabel),
                      }}
                      value={driveTime}
                      onChange={setDriveTime}
                      onValidation={setDriveTimeValidationMessage}
                    />
                  </span>
                ),
              })}
            </label>
            {driveTimeValidationMessage && (
              <InputValidation message={driveTimeValidationMessage} />
            )}
            <Text size="small">
              <a
                href="https://help.getjobber.com/hc/en-us/articles/13808363916951-Online-Booking#Time_between_appointments"
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatMessage(messages.driveTimeLearnMore)}
              </a>
            </Text>
          </>
        )}
      </RadioOption>,
    );
  }

  options.push(
    <RadioOption
      key={EfficientSchedulingType.BufferTime}
      value={EfficientSchedulingType.BufferTime}
      label={formatMessage(messages.bufferTimeOption)}
      description={formatMessage(messages.bufferTimeDescription)}
    >
      {useBufferTime && (
        <Chips
          onChange={newBufferDuration => {
            setBufferDuration(
              newBufferDuration ? parseInt(newBufferDuration, 10) : 30,
            );
          }}
          selected={bufferDuration.toString()}
        >
          <Chip
            label={formatMessage(messages.driveTimeChipLabel, { duration: 30 })}
            value="30"
          />
          <Chip
            label={formatMessage(messages.driveTimeChipLabel, { duration: 60 })}
            value="60"
          />
        </Chips>
      )}
    </RadioOption>,
  );

  options.push(
    <RadioOption
      key={EfficientSchedulingType.None}
      value={EfficientSchedulingType.None}
      label={formatMessage(messages.noRestrictionsOption)}
    />,
  );
  return options;
}

function toBufferDurationMinutes(bufferDuration: number) {
  switch (bufferDuration) {
    case 60:
      return BufferDurationMinutes.MINUTES_60;
    case 30:
    default:
      return BufferDurationMinutes.MINUTES_30;
  }
}
