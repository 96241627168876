import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  UpdateAllClientsSegmentCampaignMutation,
  UpdateAllClientsSegmentCampaignMutationVariables,
} from "~/utilities/API/graphql";
import { UPDATE_ALL_CLIENTS_SEGMENT_CAMPAIGN } from "./useUpdateAllClientsSegmentCampaign.graphql";

export function useUpdateAllClientsSegmentCampaign() {
  const [updateAllClientsSegmentCampaignMutation, { loading, data, error }] =
    useMutation<
      UpdateAllClientsSegmentCampaignMutation,
      UpdateAllClientsSegmentCampaignMutationVariables
    >(UPDATE_ALL_CLIENTS_SEGMENT_CAMPAIGN);

  const updateAllClientsSegmentCampaign = useCallback(
    async (input: UpdateAllClientsSegmentCampaignMutationVariables) => {
      const mutationResult = await updateAllClientsSegmentCampaignMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignSegmentUpdateAllClients.userErrors ?? [];
      return {
        clientSegment:
          mutationResult.data?.campaignSegmentUpdateAllClients
            .allClientsSegment,
        userErrors,
      };
    },
    [updateAllClientsSegmentCampaignMutation],
  );
  return { updateAllClientsSegmentCampaign, loading, data, error };
}
