import { Amplitude } from "./Amplitude";
import { ANALYTICS_EVENTS } from "./ANALYTICS_EVENTS";

export function trackInteractedWithExperiment({
  experiment,
  interaction,
  source,
}: {
  experiment: string;
  interaction: string;
  source: string;
}) {
  Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.interactedWithExperiments, {
    experiment: experiment,
    source: source,
    interaction: interaction,
  });
}
