import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import jobberIconSrc from "@images/jobber-icon.svg";
import { IndustryLegitimacy } from "./components/IndustryLegitimacy";
import styles from "./FirstLoginLoadingOverlay.module.css";
import { ProductsAndServices } from "./components/ProductsAndServices";
import { LoadingBar } from "./components/LoadingBar/LoadingBar";
import { BusinessPriority } from "./components/BusinessPriority";
import { industryTopFeatures } from "./Features";

export interface FirstLoginLoadingOverlayProps {
  companyName: string;
  industry: string;
  businessPriority: string;
}

enum LoadingSteps {
  LEGITIMACY = 1,
  PRODUCTS_AND_SERVICES = 2,
  BUSINESS_PRIORITY = 3,
}

enum FadeOutStates {
  FADE_OUT_OVERLAY = 1,
  REMOVE_OVERLAY = 2,
}

export function FirstLoginLoadingOverlay({
  companyName,
  industry,
  businessPriority,
}: FirstLoginLoadingOverlayProps) {
  const [loadingStep, setLoadingStep] = useState(LoadingSteps.LEGITIMACY);
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [fadeOutState, setFadeOutState] = useState(0);

  useEffect(() => {
    initializeResources();
  }, []);

  function initializeResources() {
    const jobberIcon = new Image();
    jobberIcon.onload = () => {
      setResourcesLoaded(true);
    };
    jobberIcon.src = jobberIconSrc;
  }

  const shouldSkipStep = !industryTopFeatures[industry]?.length;

  function getComponentForCurrentStep(): JSX.Element {
    switch (loadingStep) {
      case LoadingSteps.LEGITIMACY: {
        return (
          <IndustryLegitimacy
            industry={industry}
            onFinishedAnimation={goToNextStep}
            companyName={companyName}
            jobberIconSrc={jobberIconSrc}
          />
        );
      }
      case LoadingSteps.PRODUCTS_AND_SERVICES: {
        if (shouldSkipStep) {
          goToNextStep();
          return <></>;
        } else {
          return (
            <ProductsAndServices
              industry={industry}
              onFinishedAnimation={goToNextStep}
              jobberIconSrc={jobberIconSrc}
            />
          );
        }
      }
      case LoadingSteps.BUSINESS_PRIORITY: {
        return (
          <BusinessPriority
            onFadeOutStart={onFadeOutStart}
            businessPriority={businessPriority}
          />
        );
      }
      default:
        return <></>;
    }
  }

  function goToNextStep() {
    setLoadingStep(loadingStep + 1);
  }

  const onFadeOutStart = useCallback(() => {
    setFadeOutState(FadeOutStates.FADE_OUT_OVERLAY);
  }, []);

  useEffect(() => {
    if (fadeOutState === FadeOutStates.FADE_OUT_OVERLAY) {
      setTimeout(() => {
        setFadeOutState(FadeOutStates.REMOVE_OVERLAY);
      }, 750);
    }
  }, [setFadeOutState, fadeOutState]);

  const loadingOverlay = (
    <>
      {fadeOutState < FadeOutStates.REMOVE_OVERLAY && (
        <div
          className={classNames(styles.loadingOverlayContainer, {
            ["loadingOverlayfadeOut"]:
              fadeOutState >= FadeOutStates.FADE_OUT_OVERLAY,
          })}
        >
          {resourcesLoaded && (
            <>
              {getComponentForCurrentStep()}
              <LoadingBar steps={shouldSkipStep ? "two" : "three"} />
            </>
          )}
        </div>
      )}
    </>
  );

  return createPortal(loadingOverlay, document.body);
}
