import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CREATE_AUTOMATED_CAMPAIGN = gql`
  mutation createAutomatedCampaign($input: AutomatedCampaignInput!) {
    createAutomatedCampaign(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
