type Environment = "development" | "production" | "test";

function environment(): Environment {
  if (
    typeof process === "undefined" ||
    !process.env.NODE_ENV ||
    !isEnvironment(process.env.NODE_ENV)
  ) {
    return "production";
  }

  return process.env.NODE_ENV;
}

function isEnvironment(value: string): value is Environment {
  return value === "development" || value === "production" || value === "test";
}

export function isDevelopment() {
  return environment() === "development";
}

export function isProduction() {
  return environment() === "production";
}

export function isTest() {
  return environment() === "test";
}
