import { Amplitude } from "~/utilities/analytics/Amplitude";

export const DISMISS_INTERACTION = "Dismiss";
export const CANCEL_INTERACTION = "Cancel";
export const SAVE_PAYMENT_INTERACTION = "Save Payment Details";
export const PAY_NOW_INTERACTION = "Pay Now";
export const INPUT_DATA_INTERACTION = "input_data";
export const CONFIRM_SUBSCRIPTION_INTERACTION = "Confirm Subscription";
export const VIEWED_RECURLY_FORM_EVENT = "Viewed Recurly Credit Card Form";
export const INTERACTED_WITH_RECURLY_FORM_EVENT =
  "Interacted with Recurly Credit Card Form";
export const FAILED_SUBMIT_RECURLY_FORM_EVENT =
  "Failed to Submit Recurly Credit Card Form";
export const CLICKED_CHOOSE_PLAN_EVENT = "Clicked Choose Plan";
export const CTA_CLICKED_EVENT = "CTA Clicked";
export const CTA_DISMISSED_EVENT = "CTA Dismissed";

export interface TrackingDetails {
  name: string;
  plan?: string;
  input?: string;
  interaction?: string;
  promotion?: string;
  addonName?: string;
  addonCodes?: string[];
  action?: string;
  salesforceId?: string | null;
  source?: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export function trackViewedRecurlyForm(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(VIEWED_RECURLY_FORM_EVENT, {
    name: props.name,
    plan: props.plan?.toLocaleLowerCase(),
    addon_name: props.addonName,
  });
}

export function trackInteractedWithButton(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(INTERACTED_WITH_RECURLY_FORM_EVENT, {
    name: props.name,
    plan: props.plan?.toLocaleLowerCase(),
    interaction: props.interaction,
    addon_name: props.addonName,
    addon_codes: props.addonCodes || [],
  });
}

export function trackInteractedWithInput(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(INTERACTED_WITH_RECURLY_FORM_EVENT, {
    name: props.name,
    plan: props.plan?.toLocaleLowerCase(),
    interaction: props.interaction || INPUT_DATA_INTERACTION,
    input_name: props.input,
    addon_name: props.addonName,
    action: props.action,
  });
}

export function trackFailedSubmitRecurlyForm(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(FAILED_SUBMIT_RECURLY_FORM_EVENT, {
    name: props.name,
    plan: props.plan?.toLocaleLowerCase(),
    addon_name: props.addonName,
  });
}

export function trackCtaClicked(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(CTA_CLICKED_EVENT, {
    name: props.name,
    source: props.source,
    salesforce_id: props.salesforceId,
  });
}

export function trackCtaDismissed(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(CTA_DISMISSED_EVENT, {
    name: props.name,
    source: props.source,
    salesforce_id: props.salesforceId,
  });
}

export function trackAutoAddedAddon(props: TrackingDetails) {
  Amplitude.TRACK_EVENT(CLICKED_CHOOSE_PLAN_EVENT, {
    name: props.name,
    plan: props.plan?.toLocaleLowerCase(),
    interaction: props.interaction || INPUT_DATA_INTERACTION,
    input_name: props.input,
    addon_name: props.addonName,
    action: props.action,
  });
}
