import { gql } from "@apollo/client";

export const GOOGLE_PROFILE = gql`
  subscription GoogleProfile {
    googleProfiles {
      googleProfiles {
        nodes {
          profileName
          businessName
          locationName
        }
        totalCount
      }
      errors {
        path
        message
      }
    }
  }
`;
