export interface BankAddress {
  street1?: string;
  street2?: string;
  city?: string;
  province?: string;
  pc?: string;
  countryCode?: string;
}

export interface BankAccountInformation {
  accountHolder: string;
  routingNumber?: string;
  accountNumber: string;
  debitAgreementForm: {
    bankName: string;
    debitAgreementShownAndAccepted: boolean;
    bankAddress: BankAddress;
  };
}

interface ResetBankAccountInformationAction {
  type: "reset";
  newState: BankAccountInformation;
}

interface BaseBankAccountInformationAction {
  type:
    | "updateAccountHolder"
    | "updateRoutingNumber"
    | "updateAccountNumber"
    | "changeBankName";
  newValue: string;
}

interface ConfirmFormAction {
  type: "confirmForm";
  newValue: boolean;
}

interface UpdateBankAddressAction {
  type: "updateBankAddress";
  bankAddressPartialUpdate: Pick<BankAddress, keyof BankAddress>;
}

export type BankAccountInformationAction =
  | ResetBankAccountInformationAction
  | BaseBankAccountInformationAction
  | ConfirmFormAction
  | UpdateBankAddressAction;

export const initialBankAccountInformation = (countryCode: string) => {
  return {
    accountHolder: "",
    routingNumber: "",
    accountNumber: "",
    debitAgreementForm: {
      bankName: "",
      bankAddress: {
        street1: "",
        street2: "",
        city: "",
        province: "",
        pc: "",
        countryCode,
      },
      debitAgreementShownAndAccepted: false,
    },
  };
};

function init(bankAccountInformation: BankAccountInformation) {
  return bankAccountInformation;
}

export function bankAccountInformationReducer(
  prevState: BankAccountInformation,
  action: BankAccountInformationAction,
) {
  switch (action.type) {
    case "updateAccountHolder":
      return { ...prevState, accountHolder: action.newValue };
    case "updateRoutingNumber":
      return { ...prevState, routingNumber: action.newValue };
    case "updateAccountNumber":
      return { ...prevState, accountNumber: action.newValue };
    case "changeBankName":
      return {
        ...prevState,
        debitAgreementForm: {
          ...prevState.debitAgreementForm,
          bankName: action.newValue,
        },
      };
    case "confirmForm":
      return {
        ...prevState,
        debitAgreementForm: {
          ...prevState.debitAgreementForm,
          debitAgreementShownAndAccepted: action.newValue,
        },
      };
    case "updateBankAddress":
      return {
        ...prevState,
        debitAgreementForm: {
          ...prevState.debitAgreementForm,
          bankAddress: {
            ...prevState.debitAgreementForm.bankAddress,
            ...action.bankAddressPartialUpdate,
          },
        },
      };
    case "reset":
      return init(action.newState);
    default:
      return prevState;
  }
}
