import { defineMessages } from "react-intl";

export const messages = defineMessages({
  errorMessage: {
    id: "jobber.clientPaymentMethods.errorMessage",
    defaultMessage: "An error has occurred.",
    description:
      "Error message displayed in the component when an error occurs while fetching payment methods.",
  },
  toastErrorMessage: {
    id: "jobber.clientPaymentMethods.toastErrorMessage",
    defaultMessage: "Something went wrong, please refresh and try again.",
    description: "Error message displayed in a toast when an error occurs.",
  },
});
