import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import type { InsightListData } from "jobber/dashboard/components/InsightCard/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type { TopClientsBalanceDataFragment } from "~/utilities/API/graphql";
import { messages } from "./messages";

export const TopClientsBalanceData = gql`
  fragment TopClientsBalanceData on TopClientsBalance {
    totalBalance
    numberOfClientsWithBalances
    balanceByClient {
      clientBalance
      clientUrl
      client {
        id
        name
      }
    }
  }
`;
export interface TopClientsBalanceProps {
  readonly topClientsBalance?: TopClientsBalanceDataFragment;
  trackEvents: boolean;
}

export function TopClientsBalanceInsight({
  topClientsBalance,
  trackEvents,
}: TopClientsBalanceProps) {
  const [outstandingBalanceReportPath] = useUrls(
    "outstandingBalanceReportPath",
  );

  let formattedData: InsightListData[] | undefined = [];
  const currencyFormatter = useFormatCurrency();
  formattedData = topClientsBalance?.balanceByClient?.map(clientEntry => {
    return {
      id: clientEntry.client.id,
      icon: "person",
      content: clientEntry.client.name,
      value: `${currencyFormatter(clientEntry.clientBalance, false)}`,
      url: clientEntry.clientUrl,
    };
  });

  const { formatMessage } = useIntl();
  const totalOwing = currencyFormatter(topClientsBalance?.totalBalance, false);

  return (
    <>
      <InsightCard
        title={formatMessage(messages.clientBalances)}
        description={formatMessage(messages.clientBalancesDescription, {
          numberOfClients: topClientsBalance?.numberOfClientsWithBalances,
          totalOwing,
        })}
        actionLabel={formatMessage(messages.viewReport)}
        actionUrl={outstandingBalanceReportPath}
        listItemData={formattedData}
        hideEmptyCard={
          topClientsBalance?.totalBalance === 0 ||
          topClientsBalance === undefined
        }
        trackEvents={trackEvents}
      />
    </>
  );
}
