import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonLabel: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.promoPopover.buttonLabel",
    defaultMessage: "Got it",
    description: "Button label for promo popover",
  },
  learnMore: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.promoPopover.learnMore",
    defaultMessage: "Learn more",
    description: "Link to learn more about instant payouts",
  },
  promoMessage: {
    id: "managedAccount.financialInsightsSection.upcomingPayouts.instantPayouts.getItNowButton.promoPopover.promoMessage",
    defaultMessage:
      "Congrats! You're eligible to do instant payouts! You can transfer to your bank account 24 hours a day, 7 days a week.",
    description: "Promo message for instant payouts",
  },
});
