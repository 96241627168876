import React, { type PropsWithChildren } from "react";
import classnames from "classnames";
import { FAILED_STATUSES } from "jobber/features/MessageCenter/views/Conversation/constants";
import {
  MessageDirectionEnum,
  type MessageStatusEnum,
} from "~/utilities/API/graphql";
import styles from "./Content.module.css";

interface BubbleWrapperProps {
  direction?: MessageDirectionEnum;
  status?: MessageStatusEnum;
  isAssistant?: boolean;
}

export function BubbleWrapper({
  direction,
  status,
  children,
  isAssistant = false,
}: PropsWithChildren<BubbleWrapperProps>) {
  const bubbleClass = classnames(styles.messageBubble, {
    [styles.errorState]: status && FAILED_STATUSES.includes(status),
    [styles.inbound]: direction === MessageDirectionEnum.INBOUND,
    [styles.assistant]:
      direction !== MessageDirectionEnum.INBOUND && isAssistant,
    [styles.outbound]: direction !== MessageDirectionEnum.INBOUND,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- Grandfathered error: Please fix if touching this code.
    <div className={bubbleClass} tabIndex={0} aria-live="assertive">
      {children}
    </div>
  );
}
