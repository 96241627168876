import React from "react";
import { FormatTime } from "@jobber/components/FormatTime";
import { Text } from "@jobber/components/Text";
import { FAILED_STATUSES } from "jobber/features/MessageCenter/views/Conversation/constants";
import {
  type MessageDataFragment,
  MessageStatusEnum,
} from "~/utilities/API/graphql";
import {
  BOUNCED_STATUS_LABEL,
  FAILED_STATUS_LABEL,
  OPENED_STATUS_LABEL,
  QUEUED_STATUS_LABEL,
} from "jobber/features/MessageCenter/views/Conversation/components/MessageFooter/constants";
import { MessageStatusDescription } from "jobber/features/MessageCenter/views/Conversation/components/MessageStatusDescription";
import styles from "./MessageFooter.module.css";

interface MessageFooterProps {
  message: MessageDataFragment;
  showTimeStamp: boolean;
}

export function MessageFooter({ message, showTimeStamp }: MessageFooterProps) {
  if (FAILED_STATUSES.includes(message.status)) {
    const description =
      message.status === MessageStatusEnum.FAILED
        ? FAILED_STATUS_LABEL
        : BOUNCED_STATUS_LABEL;
    return (
      <MessageStatusDescription
        icon={"alert"}
        variation={"error"}
        description={description}
      />
    );
  }

  if (message.status === MessageStatusEnum.OPENED) {
    return (
      <MessageStatusDescription
        icon={"checkmark"}
        variation={"subdued"}
        description={OPENED_STATUS_LABEL}
      />
    );
  }

  if (message.status === MessageStatusEnum.QUEUED) {
    return (
      <MessageStatusDescription
        variation={"subdued"}
        description={QUEUED_STATUS_LABEL}
      />
    );
  }

  if (showTimeStamp) {
    return (
      <div className={styles.timestampFooter}>
        <Text variation={"subdued"} size="small">
          <FormatTime time={message.timestamp} />
        </Text>
      </div>
    );
  }

  return <></>;
}
