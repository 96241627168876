import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import type { ReactElement } from "react";
import React, { useEffect, useRef } from "react";
import { Heading } from "@jobber/components/Heading";
import { InlineLabel } from "@jobber/components/InlineLabel";
import type { NotificationTypes } from "jobber/settings/notifications/notificationInterfaces";
import { NotificationSection } from "jobber/settings/notifications/components/NotificationSection";
import styles from "./SettingsSection.module.css";

export interface SettingsDescriptionProps {
  title: string;
  description: string;
  scheduleTemplate?: string;
  scheduleFootnote?: string;
  allowDeliveryMethodChange?: boolean;
  allowUnitChange?: boolean;
  showToggles?: boolean;
  requiredFeaturePlan?: string;
  featureAvailable?: boolean;
  textRewriteVariableSet: {
    SMS?: string;
    EMAIL: string;
  };
}

export interface SettingsRowProps {
  descriptions: SettingsDescriptionProps;
  notification: NotificationTypes;
}
//We can refactor this type once templates and notification views are gone
export interface SettingsSectionProps {
  sectionTitle: string;
  sectionRows: SettingsRowProps[];
  showToggles: boolean;
  targetId: string;
}

function TargettableDiv({
  children,
  id,
}: {
  children: ReactElement;
  id: string;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const isTargetted = window.location.hash === `#${id}`;

  useEffect(() => {
    if (divRef.current && isTargetted) {
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [isTargetted]);

  return (
    <div
      id={id}
      ref={divRef}
      className={isTargetted ? styles.targettedCard : undefined}
    >
      {children}
    </div>
  );
}

export const SettingsSection = (props: SettingsSectionProps) => (
  <TargettableDiv id={props.targetId}>
    <SettingsSectionInternal {...props} />
  </TargettableDiv>
);

export function SettingsSectionInternal({
  sectionTitle,
  sectionRows,
  showToggles,
}: SettingsSectionProps) {
  const maxIndex = sectionRows.length - 1;

  const allRowsDisabled = sectionRows.every(
    x => x.descriptions.requiredFeaturePlan,
  );

  return (
    <Card title={allRowsDisabled ? undefined : sectionTitle}>
      {allRowsDisabled && (
        <div className={styles.header}>
          <Heading level={3}>{sectionTitle}</Heading>
          <InlineLabel color="lightBlue">
            {sectionRows[0].descriptions.requiredFeaturePlan}
          </InlineLabel>
        </div>
      )}
      <Content>
        {sectionRows.map((row, index: number) => {
          return (
            <div key={index}>
              <NotificationSection
                showToggles={showToggles}
                key={row.notification.id}
                notification={row.notification}
                {...{
                  ...row.descriptions,
                  requiredFeaturePlan: allRowsDisabled
                    ? undefined
                    : row.descriptions.requiredFeaturePlan,
                }}
              />
              {index < maxIndex && <div className="u-borderBottom" />}
            </div>
          );
        })}
      </Content>
    </Card>
  );
}
