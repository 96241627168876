import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

interface LoadMoreSpinnerProps {
  loadMore(): void;
}

export function LoadMoreSpinner({ loadMore }: LoadMoreSpinnerProps) {
  const { ref, inView } = useInView();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (inView) {
      setShowSpinner(true);
      loadMore();
    }
  }, [inView]);

  return (
    <>
      {showSpinner && (
        <div className={"row collapse align-center u-paddingNone"}>
          <div className={"columns spinner spinner--inline"} />
        </div>
      )}
      <div ref={ref} className={"u-padding"} />
    </>
  );
}
