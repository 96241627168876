import { defineMessages } from "react-intl";

export const messages = defineMessages({
  province: {
    id: "propertyAutocomplete.province",
    defaultMessage: "State",
    description: "Label to use for the province or state",
  },
  postalCode: {
    id: "propertyAutocomplete.postalCode",
    defaultMessage: "Zip code",
    description: "Label to use for the postal code",
  },
});
