import type { KeysToLabelOverride } from "./useAddressComponents";

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// https://www.postoffice.co.uk/mail/how-to-address-mail
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html

interface Map {
  [key: string]: KeysToLabelOverride;
}
const countryCodeToLabelOverrides: Map = {
  AU: {
    pc: "Post code",
    province: "State",
  },
  GB: {
    city: "Post town",
    province: "", // falsey = hide field
  },
  US: {
    pc: "ZIP code",
    province: "State",
  },
  IN: {
    province: "State",
  },
};

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default countryCodeToLabelOverrides;
