export const messages = {
  notRegistered: {
    title: "IMMEDIATE ACTION REQUIRED",
    message: "You must COMPLETE your registration",
    description:
      "The registration approval process can take up to 3 weeks. You can still send invoices, quotes, reminders and follow-ups by text message on Jobber's shared phone numbers. You will receive a text message to your dedicated phone number and a message in your Jobber account once your registration is complete!",
  },
  processing: {
    title: "Your registration is PENDING.",
    message: "",
    description:
      "The registration approval process can take up to 3 weeks. You can still send invoices, quotes, reminders and follow-ups by text message on Jobber's shared phone numbers. You will receive a text message to your dedicated phone number and a message in your Jobber account once your registration is complete!",
  },
  registrationFailed: {
    title: "IMMEDIATE ACTION REQUIRED",
    message:
      "You have submitted incomplete or inaccurate information in your registration and must RESUBMIT with correct information.",
    description:
      "The registration approval process can take up to 3 weeks. You can still send invoices, quotes, reminders and follow-ups by text message on Jobber's shared phone numbers. You will receive a text message to your dedicated phone number and a message in your Jobber account once your registration is complete!",
  },
  verificationExpired: {
    title: "IMMEDIATE ACTION REQUIRED",
    message:
      "You must VERIFY your registration by clicking the banner to receive a new verification message.",
    description:
      "The registration approval process can take up to 3 weeks. You can still send invoices, quotes, reminders and follow-ups by text message on Jobber's shared phone numbers. You will receive a text message to your dedicated phone number and a message in your Jobber account once your registration is complete!",
  },
};
