import { defineMessages } from "react-intl";

export const messages = defineMessages({
  acceptACHModalTitle: {
    id: "managedAccount.paymentsCard.acceptACHModalTitle",
    defaultMessage: "Accept ACH bank payments",
    description: "Title for the modal to accept ACH bank payments",
  },
  setNewDefaultPaymentOptions: {
    id: "managedAccount.paymentsCard.setNewDefaultPaymentOptions",
    defaultMessage: "Set new default",
    description: "Button text to set new default payment options",
  },
  keepExistingPaymentOptions: {
    id: "managedAccount.paymentsCard.keepExistingPaymentOptions",
    defaultMessage: "Keep existing",
    description: "Button text to keep existing payment options",
  },
  acceptACHModalBody: {
    id: "managedAccount.paymentsCard.acceptACHModalBody",
    defaultMessage: "You can now accept ACH bank payments.",
    description: "Body text for the modal to accept ACH bank payments",
  },
  acceptACHModalRecommendation: {
    id: "managedAccount.paymentsCard.acceptACHModalRecommendation",
    defaultMessage:
      "We recommend setting your default payment options on invoices and quotes to accept both card and bank payments.",
    description:
      "Recommendation text for the modal to accept ACH bank payments",
  },
  confirmACHModalTitle: {
    id: "managedAccount.paymentsCard.confirmACHModalTitle",
    defaultMessage: "Turn on ACH bank payments",
    description: "Title for the modal to confirm turning on ACH bank payments",
  },
  turnOnACHButtonTitle: {
    id: "managedAccount.paymentsCard.turnOnACHButtonTitle",
    defaultMessage: "Turn on ACH bank payments",
    description: "Button text to turn on ACH bank payments",
  },
  turnOnACHBody: {
    id: "managedAccount.paymentsCard.turnOnACHBody",
    defaultMessage:
      "In order to set this default, you must have ACH bank payments turned on.",
    description:
      "Body text for the modal to confirm turning on ACH bank payments",
  },
  cancel: {
    id: "managedAccount.paymentsCard.cancel",
    defaultMessage: "Cancel",
    description: "Button text to cancel an action",
  },
});
