import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "connectToGrowOnboardingPopUpModal.title",
    defaultMessage: "Are you sure?",
    description: "Onboarding pop up modal title",
  },
  modalDescription: {
    id: "connectToGrowOnboardingPopUpModal.description",
    defaultMessage:
      "This guide will be removed from your dashboard. You can re-access this in the countdown banner.",
    description: "Onboarding pop up modal description",
  },
  cancelButton: {
    id: "connectToGrowOnboardingPopUpModal.cancelButton",
    defaultMessage: "Cancel",
    description: "Text for secondary action button in onboarding pop up modal",
  },
  closeGuideButton: {
    id: "connectToGrowOnboardingPopUpModal.closeGuideButton",
    defaultMessage: "Yes, Close Guide",
    description: "Text for primary action button in onboarding pop up modal",
  },
  bannerImageAltText: {
    id: "connectToGrowOnboardingPopUpModal.bannerImageAltText",
    defaultMessage:
      "Connect to Grow Trial Banner on Home page with link to Explore Grow Features displaying.",
    description: "Alt text for banner image in onboarding pop up modal",
  },
});
