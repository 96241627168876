import React from "react";
import { Header, Table } from "components/Table";
import type {
  JobCostExpenseFragment,
  JobDetailsFragment,
  User,
} from "~/utilities/API/graphql";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { processExpensesEntries } from "./utils";
import { ExpensesTableEmptyState } from "./components/ExpensesTableEmptyState";
import { ExpensesTableTitle } from "./components/ExpensesTableTitle";
import { ExpensesTableFooter } from "./components/ExpensesTableFooter";
import { ExpensesTableBody } from "./components/ExpensesTableBody";
import { ExpensesTableHeaderColumns } from "./components/ExpensesTableHeaderColumns";
import styles from "./components/ExpensesTable.module.css";
import { LoadPastEntriesButton } from "../LoadPastEntriesButton";

interface ExpensesTableProps {
  expensesEntries?: JobCostExpenseFragment[];
  expensesTotal: number;
  job: JobDetailsFragment;
  hasMoreExpenses: boolean;
  fetchMoreExpenses?: () => Promise<void>;
  currentUser: User;
}

export function ExpensesTable({
  expensesEntries,
  expensesTotal,
  job,
  hasMoreExpenses,
  fetchMoreExpenses,
  currentUser,
}: ExpensesTableProps) {
  const { timezone } = useAccount();

  if (!expensesEntries || expensesEntries?.length <= 0) {
    return <ExpensesTableEmptyState job={job} currentUser={currentUser} />;
  }

  const expensesData = processExpensesEntries(expensesEntries, timezone);

  return (
    <div className={styles.expensesTable}>
      <Table>
        <ExpensesTableColumnGroup />
        <Header>
          <ExpensesTableTitle job={job} currentUser={currentUser} />
          <ExpensesTableHeaderColumns />
        </Header>
        <ExpensesTableBody
          expensesData={expensesData}
          job={job}
          currentUser={currentUser}
        >
          <LoadPastEntriesButton
            showButton={hasMoreExpenses}
            onClick={() => fetchMoreExpenses?.()}
          />
        </ExpensesTableBody>
        <ExpensesTableFooter amount={expensesTotal} />
      </Table>
    </div>
  );
}

function ExpensesTableColumnGroup() {
  return (
    <colgroup>
      <col style={{ width: `20%` }} />
      <col style={{ width: `50%` }} />
      <col style={{ width: `15%` }} />
      <col style={{ width: `15%` }} />
    </colgroup>
  );
}
