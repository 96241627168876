import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { DurationPeriod } from "jobber/workOrders/components/Scheduler/types";
import styles from "./ScheduleEndPeriodDropdown.module.css";

export interface ScheduleEndPeriodDropdownType {
  endPeriodOption: DurationPeriod;
  disabled: boolean;
  onChange(nextState: DurationPeriod): void;
}

const dropdownValues = {
  day: {
    label: "Day(s)",
    value: DurationPeriod.Day,
  },
  week: {
    label: "Week(s)",
    value: DurationPeriod.Week,
  },
  month: {
    label: "Month(s)",
    value: DurationPeriod.DayOfMonth,
  },
  year: {
    label: "Year(s)",
    value: DurationPeriod.Year,
  },
  visit: {
    label: "Visit(s)",
    value: DurationPeriod.Visit,
  },
};

export const ScheduleEndPeriodDropdown = ({
  disabled,
  endPeriodOption,
  onChange,
}: ScheduleEndPeriodDropdownType) => {
  return (
    <div className={styles.container}>
      <Select
        disabled={disabled}
        name="end_period_dropdown"
        value={endPeriodOption}
        onChange={onChange}
      >
        <Option value={dropdownValues.day.value.toString()}>
          {dropdownValues.day.label}
        </Option>
        <Option value={dropdownValues.week.value.toString()}>
          {dropdownValues.week.label}
        </Option>
        <Option value={dropdownValues.month.value.toString()}>
          {dropdownValues.month.label}
        </Option>
        <Option value={dropdownValues.year.value.toString()}>
          {dropdownValues.year.label}
        </Option>
        <Option value={dropdownValues.visit.value.toString()}>
          {dropdownValues.visit.label}
        </Option>
      </Select>
    </div>
  );
};
