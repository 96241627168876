import {
  type InternalRefetchQueriesInclude,
  useMutation,
} from "@apollo/client";
import lodash from "lodash";
import { useIntl } from "react-intl";
import type {
  CreateProductOrServiceMutation,
  ProductsAndServicesCategory,
  ProductsAndServicesEditMutation,
} from "~/utilities/API/graphql";
import type { BookableType, QuantityRange } from "jobber/workItems/types";
import { CREATE_PRODUCT_OR_SERVICE, EDIT_PRODUCT_OR_SERVICE } from "./graphql";
import { messages } from "./messages";

export interface SaveResult {
  errors: string[];
}

export interface FileAttachmentSaveAttributes {
  fileName: string;
  contentType: string;
  fileSize: number;
  fileableType: string;
  s3Key: string;
}

export interface ProductOrServiceSaveAttributes {
  id?: string | undefined;
  name: string;
  description: string;
  category: ProductsAndServicesCategory;
  defaultUnitCost: number;
  taxable: boolean;
  visible: boolean | undefined;
  internalUnitCost?: number;
  markup?: number;
  image?: FileAttachmentSaveAttributes;
  onlineBookingsEnabled?: boolean;
  durationMinutes?: number;
  quantityRange?: QuantityRange | null;
  bookableType?: BookableType;
}

export function useProductOrServiceSave(
  refetchQueries?: InternalRefetchQueriesInclude,
  createRefetchDelay = 0,
) {
  const { formatMessage } = useIntl();
  const [createMutation, { loading: creating }] =
    useMutation<CreateProductOrServiceMutation>(CREATE_PRODUCT_OR_SERVICE, {
      refetchQueries: refetchQueries,
      onQueryUpdated: query => {
        setTimeout(() => query.refetch(), createRefetchDelay);
      },
    });
  const [editMutation, { loading: editing }] =
    useMutation<ProductsAndServicesEditMutation>(EDIT_PRODUCT_OR_SERVICE, {
      refetchQueries: refetchQueries,
    });

  async function saveProductOrService(
    input: ProductOrServiceSaveAttributes,
  ): Promise<SaveResult> {
    const workItem = getWorkItem(input);
    if (workItem.id) {
      const result = await editMutation({
        variables: {
          id: workItem.id,
          product: {
            ...lodash.omit(workItem, "id"),
          },
        },
      });
      // I don't believe it is possible that data isn't defined but typescript does.
      if (!result.data) {
        return {
          errors: [formatMessage(messages.useProductOrServiceSaveFailedToEdit)],
        };
      }

      return {
        errors: result.data.productsAndServicesEdit.userErrors.map(
          x => x.message,
        ),
      };
    } else {
      const result = await createMutation({ variables: { product: workItem } });
      // I don't believe it is possible that data isn't defined but typescript does.
      if (!result.data) {
        return {
          errors: [
            formatMessage(messages.useProductOrServiceSaveFailedToCreate),
          ],
        };
      }

      return {
        errors: result.data.productsAndServicesCreate.userErrors.map(
          x => x.message,
        ),
      };
    }
  }
  return { saveProductOrService, saving: creating || editing };
}

function getWorkItem(item: ProductOrServiceSaveAttributes) {
  return {
    id: item.id,
    name: item.name,
    defaultUnitCost: item.defaultUnitCost,
    description: item.description,
    internalUnitCost: item.internalUnitCost,
    markup: item.markup,
    onlineBookingsEnabled: item.onlineBookingsEnabled,
    taxable: item.taxable,
    visible: item.visible,
    category: item.category,
    durationMinutes: item.durationMinutes,
    image: item.image,
    quantityRange: item.quantityRange,
    bookableType: item.bookableType,
  };
}
