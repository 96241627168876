import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Icon } from "@jobber/components/Icon";
import { Menu, type SectionProps } from "@jobber/components/Menu";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
} from "~/utilities/API/graphql";
import styles from "./CustomFieldItem.module.css";
import { CustomFieldItemContent } from "../CustomFieldItemContent";
import { CustomFieldMakeTransferableModal } from "../CustomFieldMakeTransferableModal";

export interface CustomFieldItemProps {
  customField: CustomFieldConfigurationNodeFragment;
  onEditCustomField: (
    customField: CustomFieldConfigurationNodeFragment,
  ) => void;
  adminSuperpowers: boolean;
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void;
}
export function CustomFieldItem({
  customField,
  onEditCustomField,
  adminSuperpowers,
  onArchive,
}: CustomFieldItemProps) {
  const [makeTransferableModalOpen, setMakeTransferableModalOpen] =
    useState<boolean>(false);

  const moreActionsAvailable = !customField.transferedFrom;
  const menuActions: SectionProps[] = [
    {
      actions: [
        {
          label: "Archive",
          icon: "archive",
          onClick: () => onArchive(customField),
        },
      ],
    },
  ];

  if (
    !customField.transferable &&
    !customField.transferedFrom &&
    ![CustomFieldAppliesTo.ALL_INVOICES, CustomFieldAppliesTo.TEAM].includes(
      customField.appliesTo,
    ) &&
    adminSuperpowers
  ) {
    menuActions.push({
      header: "Super Success Actions...",
      actions: [
        {
          label: "Make Transferable",
          icon: "transfer",
          onClick: () => setMakeTransferableModalOpen(true),
        },
      ],
    });
  }

  return (
    <div
      key={`CustomFieldItem${customField.id}`}
      className={styles.customFieldItemContainer}
    >
      <div className={styles.customFieldItemDragAnchor}>
        <Icon name="drag" />
      </div>
      <CustomFieldItemContent
        customField={customField}
        onEditCustomField={onEditCustomField}
        moreActions={
          moreActionsAvailable && (
            <div className={styles.customFieldMoreActionsButton}>
              <Menu
                items={menuActions}
                activator={
                  <Button
                    icon="more"
                    variation="subtle"
                    type="tertiary"
                    ariaLabel="More actions"
                  />
                }
              />
              <CustomFieldMakeTransferableModal
                open={makeTransferableModalOpen}
                customField={customField}
                setOpen={setMakeTransferableModalOpen}
              />
            </div>
          )
        }
      />
    </div>
  );
}
