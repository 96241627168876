import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Chip } from "@jobber/components/Chip";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { strFormatDate } from "@jobber/components/FormatDate";
import { createPortal } from "react-dom";
import type { DateRange, DateRangeOptions, PresetRange } from "./types";
import { DateRangePicker } from "./components/DateRangePicker";
import { usePositioning } from "./hooks/usePositioning";
import { messages } from "./messages";

// Terminology:
//  - "Preset" is a predefined date range. e.g. "Last Week", "Last Month"
//  - "Range" is the start and end date of a date range
//  - "PresetRange" is the combination of a preset and a range

export interface InputDateRangeRef {
  updatePresetRange(newPresetRange: PresetRange): void;
}

interface InputDateRangeProps {
  onChange(newPresetRange: PresetRange): void;
  initialValue: PresetRange;
  // Use children to render the button that will open the date range picker
  children: React.ReactNode;
  dateRangeOptions: DateRangeOptions[];
  dateRangeDropdownLabel?: string;
}
export const InputDateRange = forwardRef(function InputDateRange(
  {
    onChange,
    initialValue,
    children,
    dateRangeOptions,
    dateRangeDropdownLabel,
  }: InputDateRangeProps,
  ref: React.Ref<InputDateRangeRef | undefined>,
) {
  // We're exposing the ability to update the internal state of the component
  // to deal with the case where setting the initial value isn't enough. The case
  // that prompted this was when the component was initialized with default
  // values but the "real" initial value was fetched async.
  useImperativeHandle(ref, () => {
    return {
      updatePresetRange(newPresetRange: PresetRange) {
        onApplyChange(newPresetRange);
      },
    };
  });

  const {
    isOpen,
    setIsOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
  } = usePositioning();

  const [presetRange, setPresetRange] = useState<PresetRange>(initialValue);

  const onApplyChange = (newValue: PresetRange) => {
    onChange(newValue); // Update the outside world
    setPresetRange(newValue); // Update the internal state
    setIsOpen(false);
  };

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            data-testid="date-range-picker"
          >
            <DateRangePicker
              dateRangeOptions={dateRangeOptions}
              onChange={onApplyChange}
              initialValue={presetRange}
              dateRangeDropdownLabel={dateRangeDropdownLabel}
            />
          </div>,
          document.body,
        )}
    </>
  );
});

interface DateRangeButtonProps {
  range: DateRange;
}

export function DateRangeButton({ range }: DateRangeButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <Chip
      label={dateRangeLabel(
        range,
        formatMessage(messages.dateRangeButtonDefaultLabel),
      )}
      ariaLabel="Select Date Range"
    >
      <Chip.Prefix>
        <Icon name="calendar" />
      </Chip.Prefix>
    </Chip>
  );
}

export function dateRangeLabel(
  { after, before }: DateRange,
  defaultLabel = "All",
): string {
  if (!after || !before || isNaN(after.getTime()) || isNaN(before.getTime())) {
    return defaultLabel;
  }
  return `${strFormatDate(after)} - ${strFormatDate(before)}`;
}
