import { gql } from "@apollo/client";

export const USER_PERMISSIONS_QUERY = gql`
  query userPermissions {
    user {
      isAccountAdmin
      permissions {
        nodes {
          full
          limitedRead
          manage
          create
          delete
          id
          list
          name
          read
          write
        }
      }
    }
  }
`;
