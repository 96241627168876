import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewAccessError.heading",
    defaultMessage: "You are not able to upload documents for this cardholder",
    description: "Access error page heading",
  },
  descriptionFirst: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewAccessError.descriptionFirst",
    defaultMessage:
      "The document upload link you used doesn’t match the account you are logged into. For assistance, please contact support at ",
    description: "First part of the description",
  },
  buttonLabel: {
    id: "moneyManagement.CardHolderWatchListReviewUpload.components.CardHolderWatchListReviewAccessError.buttonLabel",
    defaultMessage: "Back to Jobber",
    description: "Back to Jobber button label",
  },
});
