import { gql } from "@apollo/client";

export const GET_ACH_LIMITS_CHANGE_REQUEST = gql`
  query GetAchLimitsChangeRequest {
    jobberPaymentsSettings {
      achLimitsChangeRequest {
        id
        limitsType
        status
        requestedAt
      }
    }
  }
`;
