import React, { useMemo, useState } from "react";
import { Content } from "@jobber/components/Content";
import { showToast } from "@jobber/components/Toast";
import { Modal } from "@jobber/components/Modal";
import { InputText } from "@jobber/components/InputText";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { useUpdateConfiguration } from "jobber/settings/selfServeBookings/views/SettingsPage/hooks";
import { BulletedItems } from "jobber/settings/selfServeBookings/components/BulletedItems/BulletedItems";
import { messages } from "./messages";
// eslint-disable-next-line import/no-internal-modules
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";

interface GoogleAnalyticsConfigurationProps {
  gaTag?: string;
}

export function GoogleAnalyticsConfiguration({
  gaTag,
}: GoogleAnalyticsConfigurationProps) {
  const { formatMessage } = useIntl();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [googleMeasurementId, setGoogleMeasurementId] = useState(gaTag);
  const { editSelfServeSettings, loading: saving } = useUpdateConfiguration();
  const [bannerError, setBannerError] = useState("");

  const helpCentreLink = useMemo(() => {
    return (
      <a
        href="https://help.getjobber.com/hc/en-us/articles/24239754165527"
        rel="noopener noreferrer"
        target="_blank"
      >
        {formatMessage(messages.learnMore)}
      </a>
    );
  }, [formatMessage]);

  function toggleModal() {
    handleReset();
    setIsModelOpen(current => !current);
  }

  function updateGaTag(input: string) {
    const upperCasedInput = input.toUpperCase();
    setGoogleMeasurementId(upperCasedInput);
  }

  function handleReset() {
    setGoogleMeasurementId(gaTag);
  }

  async function handleSubmit() {
    await editSelfServeSettings({
      googleMeasurementId,
    }).then(data => {
      if (data.data?.onlineBookingConfigurationEdit.userErrors.length == 0) {
        setBannerError("");
        toggleModal();
        showToast({ message: formatMessage(messages.saveMessage) });
      } else {
        setBannerError(formatMessage(messages.bannerError));
      }
    });
  }

  return (
    <>
      <ConfigurationSetting
        title={formatMessage(messages.configurationLabel)}
        description={formatMessage(messages.configurationDescription, {
          helpCentreLink,
        })}
        onEdit={toggleModal}
      >
        <BulletedItems
          items={[
            gaTag
              ? formatMessage(messages.configurationPresent, { gaTag })
              : formatMessage(messages.configurationEmpty),
          ]}
        />
      </ConfigurationSetting>
      <Modal
        title={formatMessage(messages.modalTitle)}
        open={isModelOpen}
        onRequestClose={toggleModal}
        primaryAction={{
          label: formatMessage(messages.modalSave),
          onClick: handleSubmit,
          loading: saving,
          disabled: saving,
        }}
        secondaryAction={{
          label: formatMessage(messages.modalCancel),
          onClick: toggleModal,
          disabled: saving,
        }}
      >
        <Content>
          {bannerError && <Banner type={"error"}>{bannerError}</Banner>}
          <InputText
            description={formatMessage(messages.inputTextDescription)}
            multiline={false}
            onChange={updateGaTag}
            placeholder={formatMessage(messages.inputTextPlaceholder)}
            validations={{
              pattern: {
                value: /^G-[A-Z0-9]+$/,
                message: formatMessage(messages.invalidGaTagMessage),
              },
            }}
            value={googleMeasurementId}
          />
        </Content>
      </Modal>
    </>
  );
}
