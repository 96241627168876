import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "locationTimers.cta.header",
    defaultMessage: "Track time ",
    description: "Text to display in the header",
  },
  headerEmphasis: {
    id: "locationTimers.cta.headerEmphasis",
    defaultMessage: "automatically",
    description: "Text to display in the header",
  },
  body: {
    id: "locationTimers.cta.body",
    defaultMessage:
      "Location timers help you and your team maintain accurate, up-to-date timesheets by tracking visit time when near a client's property",
    description: "Text to display in the body",
  },
  buttonTry: {
    id: "locationTimers.cta.buttonTry",
    defaultMessage: "Try It",
    description: "Label to display on the Try It button",
  },
  buttonLearn: {
    id: "locationTimers.cta.buttonLearn",
    defaultMessage: "Learn More",
    description: "Label to display on the Learn More button",
  },
  buttonTrial: {
    id: "locationTimers.cta.buttonTrial",
    defaultMessage: "Try This Feature",
    description: "Label to display on the Try This Feature button",
  },
  bodyTrial: {
    id: "locationTimers.cta.bodyTrial",
    defaultMessage: "Try it for free with a 14-day Grow trial.",
    description:
      "Text to display in the body when account is eligible for grow trial",
  },
});
