import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import React from "react";
import { Markdown } from "@jobber/components/Markdown";
import { showToast } from "@jobber/components/Toast";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
} from "~/utilities/API/graphql";
import { useCustomFieldConfigurations } from "../hooks";

interface CustomFieldMakeTransferableModalProps {
  readonly customField: CustomFieldConfigurationNodeFragment;
  readonly open: boolean;
  setOpen: (open: boolean) => void;
}

export function CustomFieldMakeTransferableModal({
  open,
  customField,
  setOpen,
}: CustomFieldMakeTransferableModalProps) {
  const { makeTransferable } = useCustomFieldConfigurations();

  async function handleConfirm() {
    const { success, errorMessage } = await makeTransferable(customField);
    if (success) {
      showToast({ message: "Custom field created as transferable" });
      closeModal();
    } else {
      showToast({
        message:
          "Could not make the custom field transferable.\n" + errorMessage,
        variation: "error",
      });
    }
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <>
      <ConfirmationModal
        title={`Make "${customField.name}" transferable?`}
        open={open}
        confirmLabel="Do it"
        onConfirm={handleConfirm}
        onCancel={closeModal}
        onRequestClose={closeModal}
      >
        <Message customField={customField} />
      </ConfirmationModal>
    </>
  );
}

interface MessageProps {
  readonly customField: CustomFieldConfigurationNodeFragment;
}

export function Message({ customField }: MessageProps) {
  const customFieldsToCreate = [];

  switch (customField.appliesTo) {
    case CustomFieldAppliesTo.ALL_CLIENTS:
    case CustomFieldAppliesTo.ALL_PROPERTIES:
      customFieldsToCreate.push(`- "${customField.name}" on **Quotes**`);
      customFieldsToCreate.push(`- "${customField.name}" on **Jobs**`);
      customFieldsToCreate.push(`- "${customField.name}" on **Invoices**`);
      break;
    case CustomFieldAppliesTo.ALL_QUOTES:
      customFieldsToCreate.push(`- "${customField.name}" on **Jobs**`);
      customFieldsToCreate.push(`- "${customField.name}" on **Invoices**`);
      break;
    case CustomFieldAppliesTo.ALL_JOBS:
      customFieldsToCreate.push(`- "${customField.name}" on **Invoices**`);
      break;
    default:
      break;
  }

  const message = `**The following new fields will be created** \n${customFieldsToCreate.join(
    "\n",
  )}`;

  return <Markdown content={message} />;
}
