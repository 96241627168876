import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientTagDefaultValue: {
    id: "campaigns.clientSegmentPage.automations.clientTagDefaultValue",
    defaultMessage: "this tag",
    description: "default value for client tag combobox",
  },
  lineItemDefaultValue: {
    id: "campaigns.clientSegmentPage.automations.lineItemDefaultValue",
    defaultMessage: "this line item",
    description: "default value for line item combobox",
  },
  clientTagSubjectNoun: {
    id: "campaigns.clientSegmentPage.automations.clientTagSubjectNoun",
    defaultMessage: "tags",
    description: "default value for client tag subject noun",
  },
  lineItemSubjectNoun: {
    id: "campaigns.clientSegmentPage.automations.lineItemSubjectNoun",
    defaultMessage: "line items",
    description: "default value for line item subject noun",
  },
});
