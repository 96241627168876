import { type OnDataOptions, useSubscription } from "@apollo/client";
import type { OnlineBookingDisabledSubscription } from "~/utilities/API/graphql";
import { SUBSCRIBE_ONLINE_BOOKING_DISABLE } from "./graphql";

export function useSelfServeBookingsDisabledSubscription(
  onUpdate: (data: OnDataOptions<OnlineBookingDisabledSubscription>) => void,
) {
  useSubscription<OnlineBookingDisabledSubscription>(
    SUBSCRIBE_ONLINE_BOOKING_DISABLE,
    {
      onData: onUpdate,
    },
  );
}
