import moment from "moment";

export type defaultDatasetKey = "requests" | "quotes" | "jobs" | "invoices";

function getDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  date.setHours(0, 0, 0, 0);
  const dateFormat = "YYYY-MM-DDTHH:mm:SS.SSS";
  return moment(date).format(dateFormat);
}

/* eslint-disable @typescript-eslint/naming-convention */
const primaryWorkRequest = {
  "WorkRequests.count": "0",
  "WorkRequests.createdAt": getDate(),
};

const secondaryWorkRequest = {
  "WorkRequests.count": "0",
  "WorkRequests.updatedAt": getDate(),
};

const primaryQuote = {
  "Quotes.count": "0",
  "Quotes.statusDate": getDate(),
};

const secondaryQuote = {
  "Quotes.count": "0",
  "Quotes.statusDate": getDate(),
};

const primaryWorkOrders = {
  "WorkOrders.count": "0",
  "WorkOrders.startAt": getDate(),
};

const secondaryWorkOrders = {
  "WorkOrders.count": "0",
  "WorkOrders.completedAt": getDate(),
};

const primaryInvoice = {
  "Invoices.count": "0",
  "Invoices.issuedDate": getDate(),
};

const secondaryInvoice = {
  "Invoices.count": "0",
  "Invoices.receivedDate": getDate(),
};
/* eslint-enable @typescript-eslint/naming-convention */

export const primaryDefaultDataset = {
  requests: primaryWorkRequest,
  quotes: primaryQuote,
  jobs: primaryWorkOrders,
  invoices: primaryInvoice,
};

export const secondaryDefaultDataset = {
  requests: secondaryWorkRequest,
  quotes: secondaryQuote,
  jobs: secondaryWorkOrders,
  invoices: secondaryInvoice,
};
