import classNames from "classnames";
import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Button } from "@jobber/components/Button";
import type { EventOnClick } from "~/jobber/actionBar/types";
import { Amplitude } from "~/utilities/analytics/Amplitude";

export interface ItemProps {
  label: string;
  icon?: IconNames;
  url?: string;
  selected?: boolean;
  hasUpdates?: boolean;
  dialog?: boolean;
  external?: boolean;
  freemiumLocked?: boolean;
  freemiumLockedTooltip?: string;
  freemiumLockedTooltipCta?: string;
  method?: "POST" | "PUT";
  confirm?: string;
  showLoadingOnClick?: boolean;
  jaTrackingLink?: string;
  jaSourceLink?: string;
  jaEmailType?: string;
  jaType?: string;
  jaRefundAvailableLink?: string;
  jaNameLink?: string;
  jaInteractionLink?: string;
  printButton?: string;
  clearIconColor?: boolean;
  eventOnClick?: EventOnClick;
  onClick?(): void;
  customActionName?: string;
  objectId?: string;
}

/**
 * React wrapper for Item
 */

export function Item(props: ItemProps) {
  const labelSnakeCased = props.label.split(" ").join("_").toLowerCase();

  const className = classNames("dropdown-item", {
    [`tooltip-${labelSnakeCased}`]:
      props.freemiumLocked && props.freemiumLockedTooltip,
    /* eslint-disable @typescript-eslint/naming-convention */
    "dropdown-itemFreemium": props.freemiumLocked,
    "is-selected": props.selected,
    "has-updates": props.hasUpdates,
    "js-spinOnClick": !props.external && props.showLoadingOnClick,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  const attrs = {
    className: className,
    href: props.url,
    onClick: props.onClick,
    target: props.external ? "_blank" : "_self",
    /* eslint-disable @typescript-eslint/naming-convention */
    "data-confirm": props.confirm,
    "data-ja-track-link": props.jaTrackingLink,
    "data-ja-source": props.jaSourceLink,
    "data-ja-email-type": props.jaEmailType,
    "data-ja-type": props.jaType,
    "data-ja-name": props.jaNameLink,
    "data-ja-interaction": props.jaInteractionLink,
    "data-ja-refund-available": props.jaRefundAvailableLink,
    "data-print-button": props.printButton,

    /**
     * We should find a solution to get away from using rails magic. For now,
     * it looks like we can't get away with this because of how some controllers
     * are written to mix and match "remote" and "method".
     */
    "data-method": props.method,
    "data-remote": props.dialog,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const MenuItem = props.url === undefined ? "button" : "a";

  const freemiumContent = () => {
    const ctaLabel = props.freemiumLockedTooltipCta || "Learn More";

    return (
      <>
        <span className="flexBlock flexBlock--grow">{props.label}</span>
        <sg-icon
          icon="lock"
          class={`u-block freemiumLockIcon u-marginRightNone u-marginLeftSmall`}
        />
        {props.freemiumLockedTooltip && (
          <shared-tooltip
            bind="hover"
            target={`.tooltip-${labelSnakeCased}`}
            direction="left"
            class="freemiumTooltip"
          >
            <div className="u-marginBottomSmallest">
              {props.freemiumLockedTooltip}
            </div>
            <Button
              url={props.url}
              label={ctaLabel}
              size="small"
              variation="learning"
              onClick={handleToolTipClickButton(
                `Clicked ${ctaLabel}`,
                `freemium_tooltip_actionbar_${labelSnakeCased}`,
              )}
            />
          </shared-tooltip>
        )}
      </>
    );
  };

  return (
    <MenuItem {...attrs}>
      {props.icon && (
        <div style={{ marginRight: "var(--space-base)" }}>
          <Icon name={props.icon} />
        </div>
      )}
      {props.freemiumLocked ? freemiumContent() : props.label}
    </MenuItem>
  );
}

function handleToolTipClickButton(event: string, source: string) {
  return () => Amplitude.TRACK_EVENT(event, { source });
}
