import React from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import styles from "./ProductsAndServices.module.css";
import { industryTopFeatures } from "../../Features";
import { AnimatedStep } from "../AnimatedStep";
import { industryName } from "../../utils";

interface ProductsAndServicesProps {
  industry: string;
  jobberIconSrc: string;
  onFinishedAnimation(): void;
}

export function ProductsAndServices({
  industry,
  jobberIconSrc,
  onFinishedAnimation,
}: ProductsAndServicesProps) {
  function getTopFeaturesCopy(features: string[]) {
    return (
      <>
        {features.map((feature, index) => (
          <div
            key={index}
            className={`${styles.featureContainer} slideInElement`}
          >
            {feature}
          </div>
        ))}
      </>
    );
  }

  const slideInContent = (
    <>
      <img
        data-testid="jobberIcon"
        className={`${styles.jobberIcon} slideInElement`}
        src={jobberIconSrc}
        alt="Jobber Icon"
      />
      <div className={styles.topFeaturesWrapper}>
        {getTopFeaturesCopy(industryTopFeatures[industry])}
      </div>
    </>
  );

  const headerContent = (
    <Heading level={3}>
      <div className={styles.header}>
        <span>Adding&nbsp;</span>
        <div className={styles.industryName}>
          <Emphasis variation="highlight">
            <span data-testid="industry-name">{industryName(industry)}</span>
          </Emphasis>
        </div>
        <Emphasis variation="bold">&nbsp;products and services</Emphasis>
        <span>&nbsp;to get you started...</span>
      </div>
    </Heading>
  );

  return (
    <AnimatedStep
      slideInContent={slideInContent}
      headerContent={headerContent}
      onFinishedAnimation={onFinishedAnimation}
      totalAnimationTime={3500}
    />
  );
}
