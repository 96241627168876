import * as React from "react";

type OpenDialog = () => void;
type CloseDialog = () => void;
type ReturnType = [boolean, OpenDialog, CloseDialog];

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function useDialogOpen(defaultOpen = false): ReturnType {
  const [open, setOpen] = React.useState(defaultOpen);
  const openDialog = React.useCallback(() => setOpen(true), [setOpen]);
  const closeDialog = React.useCallback(() => setOpen(false), [setOpen]);

  return [open, openDialog, closeDialog];
}
