import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { EditBillingInfoModal } from "./EditBillingInfoModal";

interface EditBillingInfoModalLoaderProps {
  recurlyPublicKey: string;
  tier: string;
}

export function EditBillingInfoModalLoader(
  props: EditBillingInfoModalLoaderProps,
) {
  return (
    <IntlProvider>
      <APIProvider>
        <InternalEditBillingInfoModalLoader {...props} />
      </APIProvider>
    </IntlProvider>
  );
}

function InternalEditBillingInfoModalLoader(
  props: EditBillingInfoModalLoaderProps,
) {
  return <EditBillingInfoModal {...props} />;
}
