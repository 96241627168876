import { defineMessages } from "react-intl";

//Placeholder messages
export const messages = defineMessages({
  title: {
    id: "setupGuide.connect.title",
    defaultMessage: "Connect your profile to start setup",
    description: "Title for the connect step",
  },
  subtitle: {
    id: "setupGuide.connect.subtitle",
    defaultMessage: "Setup time 3 min",
    description: "Subtitle for the connect step",
  },
  description: {
    id: "setupGuide.connect.description",
    defaultMessage:
      "Connect to Google Business to see insights and manage reviews. Once connected, your clients will be linked directly to your profile to leave reviews.",
    description: "Description for the connect step",
  },
  subtext: {
    id: "setupGuide.connect.subtext",
    defaultMessage:
      "Don't have a profile? [Set one up for free](https://business.google.com/create) before getting started",
    description: "Subtext for the connect step",
  },
  primaryButton: {
    id: "setupGuide.connect.primaryButton",
    defaultMessage: "Connect",
    description: "Button that navigates to the connect step",
  },
  secondaryButton: {
    id: "setupGuide.connected.secondaryButton",
    defaultMessage: "Disconnect",
    description: "Button that disconnects the Google Business Account",
  },
});
