import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { queryCompanySchedule } from "../queries/companySchedule";

export function usePreloadCompanySchedule() {
  const client = useApolloClient();

  useEffect(() => {
    queryCompanySchedule(client)().catch(() => undefined);
  }, [client]);
}
