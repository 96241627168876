import { useCallback, useReducer } from "react";
import { Segment } from "~/utilities/API/graphql";
import type {
  AllClientsCriteriaInput,
  PastClientsCriteriaInput,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import {
  DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
} from "jobber/campaigns/constants";
import {
  type AdditionalCriteriaReducerActions,
  additionalCriteriaReducer,
} from "./CampaignAdditionalSegmentCriteriaReducer";
import {
  type SegmentCriteriaReducerState,
  segmentCriteriaReducer,
} from "./SegmentCriteriaReducer";

const criteriaDefaults: SegmentCriteriaReducerState = {
  selectedSegmentType: Segment.PAST_CLIENTS,
  allClientsSegmentCriteria: {},
  pastClientsSegmentCriteria: DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  upcomingClientsSegmentCriteria: DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
  isDirty: false,
};
// eslint-disable-next-line max-statements
export function useCampaignSegmentCriteria({
  selectedSegmentType,
}: Partial<Pick<SegmentCriteriaReducerState, "selectedSegmentType">>) {
  const [pastClientsAdditionalCriteria, _updatePastClientsAdditionalCriteria] =
    useReducer(additionalCriteriaReducer, []);

  const [
    upcomingClientsAdditionalCriteria,
    _updateUpcomingClientsAdditionalCriteria,
  ] = useReducer(additionalCriteriaReducer, []);
  const [allClientsAdditionalCriteria, _updateAllClientsAdditionalCriteria] =
    useReducer(additionalCriteriaReducer, []);
  const [segmentCriteriaValue, updateSegmentCriteria] = useReducer(
    segmentCriteriaReducer,
    Object.assign(criteriaDefaults, {
      selectedSegmentType: selectedSegmentType || Segment.PAST_CLIENTS,
    }),
  );
  const updatePastClientsAdditionalCriteria = useCallback(
    (dispatch: AdditionalCriteriaReducerActions, isDirty?: boolean) => {
      _updatePastClientsAdditionalCriteria(dispatch);
      if (isDirty) {
        updateSegmentCriteria({ type: "setDirty" });
      }
    },
    [],
  );
  const updateAllClientsAdditionalCriteria = useCallback(
    (dispatch: AdditionalCriteriaReducerActions, isDirty?: boolean) => {
      _updateAllClientsAdditionalCriteria(dispatch);
      if (isDirty) {
        updateSegmentCriteria({ type: "setDirty" });
      }
    },
    [],
  );

  const updateUpcomingClientsAdditionalCriteria = useCallback(
    (dispatch: AdditionalCriteriaReducerActions, isDirty?: boolean) => {
      _updateUpcomingClientsAdditionalCriteria(dispatch);
      if (isDirty) {
        updateSegmentCriteria({ type: "setDirty" });
      }
    },
    [],
  );

  const setSelectedSegmentType = useCallback(
    (segment: Segment, setDirty?: boolean) => {
      updateSegmentCriteria({
        type: "setSelectedSegmentType",
        payload: { segmentType: segment, dirty: setDirty },
      });
    },
    [],
  );

  const setPastClientsSegmentCriteria = useCallback(
    (criteria: PastClientsCriteriaInput, setDirty?: boolean) => {
      updateSegmentCriteria({
        type: "setPastClientsSegmentCriteria",
        payload: {
          segmentCriteria: criteria,
          dirty: setDirty,
        },
      });
    },
    [],
  );

  const setAllClientsSegmentCriteria = useCallback(
    (criteria: AllClientsCriteriaInput, setDirty?: boolean) => {
      updateSegmentCriteria({
        type: "setAllClientsSegmentCriteria",
        payload: {
          segmentCriteria: criteria,
          dirty: setDirty,
        },
      });
    },
    [],
  );

  const setUpcomingClientsSegmentCriteria = useCallback(
    (criteria: UpcomingClientsCriteriaInput, setDirty?: boolean) => {
      updateSegmentCriteria({
        type: "setUpcomingClientsSegmentCriteria",
        payload: {
          segmentCriteria: criteria,
          dirty: setDirty,
        },
      });
    },
    [],
  );
  const setNotDirty = useCallback(() => {
    updateSegmentCriteria({ type: "setNotDirty" });
  }, []);

  return {
    pastClientsAdditionalCriteria,
    allClientsAdditionalCriteria,
    upcomingClientsAdditionalCriteria,
    updatePastClientsAdditionalCriteria,
    updateAllClientsAdditionalCriteria,
    updateUpcomingClientsAdditionalCriteria,
    allClientsSegmentCriteria: segmentCriteriaValue.allClientsSegmentCriteria,
    pastClientsSegmentCriteria: segmentCriteriaValue.pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria:
      segmentCriteriaValue.upcomingClientsSegmentCriteria,
    setAllClientsSegmentCriteria,
    setPastClientsSegmentCriteria,
    setUpcomingClientsSegmentCriteria,
    selectedSegmentType: segmentCriteriaValue.selectedSegmentType,
    setSelectedSegmentType,
    isDirty: segmentCriteriaValue.isDirty,
    setNotDirty,
  };
}
