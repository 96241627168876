import { screen } from "@testing-library/react";
import { messages } from "jobber/campaigns/components/SelectableSegments/SelectableSegment/messages";

export function getSelectableSegmentTitle(title: string) {
  return screen.getByText(title);
}

export function getRecommendedLabel() {
  return screen.getAllByText(messages.recommended.defaultMessage);
}
