import { gql } from "@apollo/client";

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod(
    $clientId: EncodedId!
    $input: PaymentMethodCreateInput!
  ) {
    paymentMethodCreate(clientId: $clientId, input: $input) {
      __typename
      paymentMethod {
        ...BankAccountFields
        ...CreditCardFields
      }
      userErrors {
        message
      }
    }
  }

  fragment BankAccountFields on BankAccountPaymentMethod {
    __typename
    bankName
    defaultMethod
    fingerprint
    last4
    uuid
  }

  fragment CreditCardFields on CreditCardPaymentMethod {
    __typename
    brand
    defaultMethod
    expired
    expiringSoon
    expiryYear
    expiryMonth
    fingerprint
    uuid
    last4
  }
`;
