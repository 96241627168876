import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { useScheduleCardChangeHandlers } from "./hooks";
import type { RepeatOption } from "../OneOffScheduler";

interface RecurrentSchedulingProps {
  showPlaceholder: boolean;
  repeatOptions: RepeatOption[];
  heading?: React.ReactNode;
}

export const RecurrentScheduling = ({
  showPlaceholder,
  repeatOptions,
  heading,
}: RecurrentSchedulingProps) => {
  const { handleRecurrenceSelection } = useScheduleCardChangeHandlers();

  return (
    <>
      {heading}
      <Select
        onChange={handleRecurrenceSelection}
        placeholder={showPlaceholder ? "Repeats" : undefined}
      >
        {repeatOptions.map(repeatOption => (
          <Option key={repeatOption.label} value={repeatOption.value}>
            {repeatOption.label}
          </Option>
        ))}
      </Select>
    </>
  );
};
