import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientBalances: {
    id: "dashboard.components.topClientsBalanceInsight.clientBalances",
    defaultMessage: "Client balances",
    description: "The client balances insights card",
  },
  clientBalancesDescription: {
    id: "dashboard.components.topClientsBalanceInsight.clientBalancesDescription  ",
    defaultMessage:
      "You have {numberOfClients} {numberOfClients, plural, one {client} other {clients}} that {numberOfClients, plural, one {owes} other {owe}} you a total of {totalOwing}.",
    description: "The description of client balances on the insights card",
  },
  viewReport: {
    id: "dashboard.components.topClientsBalanceInsight.viewReport",
    defaultMessage: "View Report",
    description: "The action button to view the client balances report",
  },
});
