import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { Button } from "@jobber/components/Button";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Markdown } from "@jobber/components/Markdown";
import { join } from "jobber/settings/users/utilities/routing";
import { useSetupGuideSteps } from "jobber/reviews/views/SetupGuide/hooks/useSetupGuideSteps";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import styles from "./styles.module.css";
import { defaultSteps } from "./stepsDefinition";
import type { SetupGuideProps, SetupGuideStep } from "./types";
import { messages } from "./messages";

export const SetupGuide = (props: SetupGuideProps) => {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();
  const { progressBarStep, showBackButton, totalSteps, onNext, goBack } =
    useSetupGuideSteps();

  const [errMsg, setErrMsg] = useState<MessageDescriptor>();

  const renderHeader = () => {
    return (
      <>
        {errMsg && (
          <div className={styles.errorBanner}>
            <Banner type={"error"} dismissible={true}>
              <Markdown externalLink={true} content={formatMessage(errMsg)} />
            </Banner>
          </div>
        )}
        <div
          data-testid="setupGuideControls"
          className={styles.headerContainer}
        >
          {showBackButton && (
            <Button
              icon={"backArrow"}
              type={"tertiary"}
              variation={"subtle"}
              onClick={goBack}
              ariaLabel={formatMessage(messages.goBack)}
            />
          )}
          <div className={styles.progressBar}>
            <ProgressBar
              currentStep={progressBarStep}
              totalSteps={totalSteps}
              variation={"stepped"}
              size={"small"}
            />
          </div>
        </div>
      </>
    );
  };

  const renderRoutes = () => {
    return (
      <Switch>
        {defaultSteps.map((step: SetupGuideStep, index: number) => {
          const CurrentStep = step.component;
          return (
            <Route
              key={index}
              exact
              path={join(path, step.route)}
              render={() => (
                <CurrentStep
                  navigation={{ onNext, goBack }}
                  companyName={props.companyName}
                  setErrMsg={setErrMsg}
                  isConnected={props.isConnected}
                />
              )}
            />
          );
        })}
        <Route path={"*"}>
          <Redirect to={defaultSteps[0].route} />
        </Route>
      </Switch>
    );
  };

  return (
    <div data-testid="setup-guide" className={styles.container}>
      {renderHeader()}
      {renderRoutes()}
    </div>
  );
};

export const SetupGuideWithProviders = withEntryPointProviders(SetupGuide);
