import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CAMPAIGN_CREATE_UPCOMING_CLIENTS = gql`
  mutation campaignCreateUpcomingClients(
    $input: UpcomingClientsCampaignInput = {
      criteria: { interval: 30, unit: DAYS }
    }
  ) {
    campaignCreateUpcomingClients(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
