import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { FilterDropdownProps } from "jobber/workOrders/types";
import styles from "./StatusFilter.module.css";

const options = [
  "Requires invoicing",
  "Active",
  "Action required",
  "Late",
  "Today",
  "Upcoming",
  "Unscheduled",
  "Ending",
  "Archived",
];

export const StatusFilter = ({
  value,
  onChangeFilters,
}: FilterDropdownProps) => {
  const onChange = (status: string) =>
    onChangeFilters({ filterByStatus: status });

  return (
    <div className={styles.dropdown}>
      <Select
        name="type_filter"
        placeholder="Type"
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};
