/**
 * Compare two booleans returning a number indicating whether `first` comes before,
 * or after, or is the same as `second` in sort order. A boolean is considered to
 * be before another if it is true and the other is false.
 *
 * @param first boolean
 * @param second boolean
 *
 * @return number - Negative if `first` occurs before `second`; positive if the `first` occurs after `second`; 0 if they are equivalent.
 */
export function booleanCompare(first: boolean, second: boolean) {
  // JS allows you to subtract booleans to do a comparison such as what we need.
  return (second as unknown as number) - (first as unknown as number);
}
