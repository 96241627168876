import { Content } from "@jobber/components/Content";
import React, { useState } from "react";
import { Banner } from "@jobber/components/Banner";
import type { Offering } from "jobber/settings/selfServeBookings/types";
import { OfferingsList } from "./components/OfferingsList";

export interface OfferingsSettingsProps {
  offerings: Offering[];
  acceptingOnlineBookings: boolean;
}

export function OfferingsCard({
  offerings,
  acceptingOnlineBookings,
}: OfferingsSettingsProps) {
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");

  function handleMutationError(errorMessage: Error) {
    setMutationErrorMessage(errorMessage.message);
  }

  function handleMutationSuccess() {
    setMutationErrorMessage("");
  }

  return (
    <Content>
      {mutationErrorMessage && (
        <Banner type="error" dismissible={false}>
          {mutationErrorMessage}
        </Banner>
      )}
      <OfferingsList
        offerings={offerings}
        acceptingOnlineBookings={acceptingOnlineBookings}
        onError={handleMutationError}
        onSuccess={handleMutationSuccess}
      />
    </Content>
  );
}
