import React from "react";
import classnames from "classnames";
import { Button } from "@jobber/components/Button";
import { Grid } from "@jobber/components/Grid";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import {
  MOBILE_SCREEN_WIDTH,
  SMALL_SCREEN_WIDTH,
} from "jobber/dashboard/components/SetupGuide/constants";
import { WistiaPlayer } from "jobber/wistiaPlayer/components";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./SetupGuidePanel.module.css";
import { messages } from "./messages";

export interface SetupGuidePanelProps {
  content: PanelContentProps;
  img: PanelImageProps;
  startGrowTrialStep?: boolean;
}

export function SetupGuidePanel({
  content,
  img,
  startGrowTrialStep,
}: SetupGuidePanelProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.panelContainer}>
      <Grid alignItems="center" gap={false}>
        <Grid.Cell size={{ xs: 12, md: 6, xl: 5 }}>
          {img &&
            !startGrowTrialStep &&
            img.src !== "JobCostingVideo" &&
            renderPanelImage(img)}
          {startGrowTrialStep && (
            <div className={styles.startGrowTrialLeftContainer}>
              <Heading level={1}>
                {formatMessage(messages.startGrowTrialLeftHeading, {
                  planName: (
                    <Emphasis variation="highlight">
                      {formatMessage(
                        messages.startGrowTrialLeftPlanNameEmphasis,
                      )}
                    </Emphasis>
                  ),
                })}
              </Heading>
              <div className={styles.growTrialDescription}>
                <Text>
                  {formatMessage(messages.startToGrowTrialLeftDescription)}
                </Text>
              </div>
            </div>
          )}
          {img.src === "JobCostingVideo" && renderPanelVideoForJobCosting()}
        </Grid.Cell>
        <Grid.Cell size={{ xs: 12, md: 4 }}>
          {renderPanelContent({ ...content, startGrowTrialStep })}
        </Grid.Cell>
      </Grid>
    </div>
  );
}

interface PanelImageProps {
  src: string;
  altText: string;
}

function renderPanelImage({ altText, src }: PanelImageProps): JSX.Element {
  return (
    <div className={styles.leftContainer}>
      <Content spacing={"base"}>
        <MainImage altText={altText} src={src} />
      </Content>
    </div>
  );
}

function renderPanelVideoForJobCosting(): JSX.Element {
  return (
    <div
      data-testid="videoCTA"
      role="button"
      aria-hidden="true"
      className={styles.jobCostingVideoContainer}
    >
      <Content spacing={"base"}>
        <WistiaPlayer videoId={"qy4ojivmye"} popover={true} />
      </Content>
    </div>
  );
}

function MainImage({ altText, src }: PanelImageProps): JSX.Element {
  return (
    <div className={styles.imageContainer}>
      <img alt={altText} src={src} />
    </div>
  );
}

interface PanelContentProps {
  heading: string;
  subheading?: string;
  buttonLabel: string;
  buttonOnPress?: () => void;
  timeEstimate?: string;
  startGrowTrialStep?: boolean;
  setupType?: string;
}

function renderPanelContent({
  heading,
  subheading,
  buttonLabel,
  buttonOnPress,
  startGrowTrialStep,
  setupType,
}: PanelContentProps): JSX.Element {
  const isSmallScreen = window.innerWidth <= SMALL_SCREEN_WIDTH;
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;
  const rightContainerClassName = classnames({
    [styles.rightContainer]: !isMobileScreen,
    [styles.rightContainerSmallScreen]: isMobileScreen,
  });

  return (
    <div className={rightContainerClassName}>
      <Content spacing={"base"}>
        {heading && <Heading level={3}>{heading}</Heading>}
        {subheading && (
          <Text>
            <span className={styles.description}>{subheading}</span>
          </Text>
        )}

        {buttonLabel && (
          <Button
            label={buttonLabel}
            onClick={buttonOnPress}
            fullWidth={isSmallScreen}
          />
        )}
        {!startGrowTrialStep && (
          <div className={styles.productExpertButton}>
            <Button
              onClick={() => {
                Amplitude.TRACK_EVENT("CTA Clicked", {
                  name: "book_a_demo",
                  type: setupType,
                  source: "connect_to_grow_trial_onboarding_checklist",
                });
                window.location.href =
                  "https://calendly.com/checkinwithjobber/booking-a-demo-call-lt";
              }}
              label={
                messages.startToGrowTrialProductExpertButton.defaultMessage
              }
              type="tertiary"
              fullWidth={isSmallScreen}
            />
          </div>
        )}
      </Content>
    </div>
  );
}
