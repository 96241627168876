import { useState } from "react";
import type { IndividualElementChangeEvent } from "@recurly/react-recurly";
import type { RecurlyInputValidation } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsEdit/PaymentDetailsEdit.d";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";

export function useCardValidationHandler(
  onValidationError: (error: FieldErrorState) => void,
  setRecurlyFieldsInitialized: (initialized: boolean) => void,
) {
  const [recurlyInputValidation, setRecurlyInputValidation] =
    useState<RecurlyInputValidation>({
      cardNumber: undefined,
      month: undefined,
      year: undefined,
      cvc: undefined,
    });

  const handleCardValidation = (inputName: keyof RecurlyInputValidation) => {
    return (event: IndividualElementChangeEvent) => {
      setRecurlyFieldsInitialized(true);

      if (!event.focus) {
        setRecurlyInputValidation({
          ...recurlyInputValidation,
          [inputName]: event.empty ? undefined : event.valid,
        });
      }

      if (event.focus && event.valid) {
        setRecurlyInputValidation({
          ...recurlyInputValidation,
          [inputName]: event.valid,
        });
      }

      onValidationError({
        field: inputName,
        message: !event.valid ? "Invalid card details" : "",
      });
    };
  };

  return { recurlyInputValidation, handleCardValidation };
}
