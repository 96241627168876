import React, { useEffect, useState } from "react";
import cubejs, {
  type CubeJSApiOptions,
  type ResultSet,
} from "@cubejs-client/core";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import {
  type defaultDatasetKey,
  primaryDefaultDataset,
  secondaryDefaultDataset,
} from "./defaultDataset";
import { DoubleSparkline } from "./DoubleSparkline";
import { queries } from "./queries";
import styles from "./DoubleSparklineDataSource.module.css";

interface DoubleSparklineDataSourceProps {
  apiUrl: string;
  type: string;
  primaryColor: string;
  secondaryColor: string;
  primaryLabel: string;
  secondaryLabel: string;
  primaryDataKey: string;
  secondaryDataKey: string;
  amountDataKey?: string;
  currencySymbol: string;
}

interface SparklineData {
  // The data returned from the metrics API could have any shape, depending on what the chart displays.
  allResults: readonly object[];
}

export function DoubleSparklineDataSource({
  apiUrl,
  type,
  primaryColor,
  secondaryColor,
  primaryLabel,
  secondaryLabel,
  primaryDataKey,
  secondaryDataKey,
  amountDataKey,
  currencySymbol,
}: DoubleSparklineDataSourceProps) {
  const [error, setError] = useState(false);
  const emptyResults: SparklineData = { allResults: [] };
  const [sparklineData, setSparklineData] =
    useState<SparklineData>(emptyResults);
  const chartHeading: string =
    type === "invoices" ? "Last 30 Days" : "Last 7 Days";

  function fetchMetricsAPIData(): void {
    const options: CubeJSApiOptions = { apiUrl, credentials: "include" };
    const cubejsApi = cubejs("", options);
    const theQueries = [queries[type].primary, queries[type].secondary];

    Promise.all(theQueries.map(async q => cubejsApi.load(q)))
      .then(([resultSet1, resultSet2]) => {
        handleChartData(resultSet1, resultSet2);
      })
      .catch(() => {
        setError(true);
        throw new Error(
          "Metrics API request failed when requesting dashboard chart data.",
        );
      });
  }

  function handleChartData(
    primaryData: ResultSet,
    secondaryData: ResultSet,
  ): void {
    if (primaryData.rawData().length === 0) {
      primaryData
        .rawData()
        .push(primaryDefaultDataset[type as defaultDatasetKey]);
    }

    if (secondaryData.rawData().length === 0) {
      secondaryData
        .rawData()
        .push(secondaryDefaultDataset[type as defaultDatasetKey]);
    }

    const allResults: SparklineData = {
      allResults: [primaryData.chartPivot(), secondaryData.chartPivot()],
    };

    setSparklineData(allResults);
  }

  useEffect(() => {
    fetchMetricsAPIData();
  }, []);

  if (sparklineData.allResults.length > 0) {
    return (
      <div className={`${styles.sparkLineBox} ${styles.sparkLine}`}>
        <Text variation="subdued">{chartHeading}</Text>
        <DoubleSparkline
          primarySeries={sparklineData.allResults[0] as readonly object[]}
          secondarySeries={sparklineData.allResults[1] as readonly object[]}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          primaryLabel={primaryLabel}
          secondaryLabel={secondaryLabel}
          primaryDataKey={primaryDataKey}
          secondaryDataKey={secondaryDataKey}
          amountDataKey={amountDataKey}
          currencySymbol={currencySymbol}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles.sparkLineBox} ${styles.errorState}`}>
        <Content spacing="small">
          <Icon name="alert" />
          <Text>Chart is currently unavailable</Text>
        </Content>
      </div>
    );
  }

  return (
    <div
      className={`${styles.sparkLineBox} ${styles.loadingState} spinner u-padding`}
    />
  );
}
