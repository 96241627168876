export class TextRewriteError extends Error {
  public message: string;
  public type: "BANNER" | "INLINE";

  constructor(message: string, type: "BANNER" | "INLINE") {
    super(message);

    this.message = message;
    this.type = type;
  }
}
