import React, { useContext, useEffect, useMemo, useState } from "react";
import { ChipDismissible } from "@jobber/components/Chips";
import type { QueryTuple } from "@apollo/client";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { SearchAndSelect } from "jobber/chat/components/MessageComposer/components/SearchAndSelect";
import type {
  ClientPhonesQuery,
  ClientPhonesQueryVariables,
} from "~/utilities/API/graphql";
import type { SelectedRecipientType } from "jobber/chat/types";
import styles from "./MessageComposer.module.css";

interface MessageComposerProps {
  fetchRecipients: QueryTuple<ClientPhonesQuery, ClientPhonesQueryVariables>;
  composeRenderer(recipient: SelectedRecipientType): JSX.Element;
  canCreateConversation(recipient: string): boolean;
}

export function MessageComposer({
  fetchRecipients,
  composeRenderer,
  canCreateConversation,
}: MessageComposerProps) {
  const [selectedRecipient, setSelectedRecipient] =
    useState<SelectedRecipientType>({
      name: "",
      contactInfo: "",
    });
  const label = "To:";
  const [state, dispatch] = useContext(MessageCenterContext);

  const chipName = useMemo(
    () => selectedRecipient.name || selectedRecipient.contactInfo,
    [selectedRecipient],
  );

  useEffect(() => {
    setSelectedRecipient({
      name: selectedRecipient.name,
      contactInfo: state.selectedRecipient?.contactInfo || "",
    });
  }, [state]);

  function setRecipient(recipient: SelectedRecipientType) {
    setSelectedRecipient({
      name: recipient.name,
      contactInfo: recipient.contactInfo,
    });
  }

  function goToComposePage() {
    setRecipient({
      name: "",
      contactInfo: "",
    });

    if (dispatch) {
      dispatch({
        type: "COMPOSE",
        title: "New Message",
      });
    }
  }

  if (!selectedRecipient.contactInfo) {
    return (
      <SearchAndSelect
        fetchRecipients={fetchRecipients}
        selectRecipient={setRecipient}
        canCreateConversation={canCreateConversation}
      />
    );
  } else {
    return (
      <>
        <div className={styles.recipientChipContainer}>
          <span className={styles.toLabelContainer}>{label}</span>
          <ChipDismissible label={chipName} onRequestRemove={goToComposePage} />
        </div>
        {composeRenderer(selectedRecipient)}
      </>
    );
  }
}
