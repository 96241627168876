import { ActorRefFrom, createMachine, sendTo } from "xstate";
import { AssignTeamMachine } from "../AssignTeam";
import { RecommendationMachine, ScheduleCardMachine } from "../ScheduleCard";

export const oneOffSchedulingMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QHsB2YDyAzLBlAxgBaQCuANgJapQCyAhkVWAMQByGA+gKqu4DCACQCiAES4AZURwBqASVyyAKgG0ADAF1EoAA7JYFAC4U0WkAE9EATgB0AVgAsARgBsAdkevbAJks-nADn8AGhAAD0RbVQBma0tLb1V-V3tVLyj7ewBfTJC0TBwCYghyKloGQiZmAEEREW5eQVEJKTkFFQ1TXX0jEyQwxHs461VnS1VxrwzHFOcQiwRHW0drf3tbVdU1p1VF52yckFRkCDhTPOw8IlJKanpGdE69Q2NUU3CEVTmrOydne0C-slnKpLFkDucCldijcyvcwNYjshtI9ui83gNguZENMwdkgA */
    id: "oneOffSchedulingMachine",
    entry: "spawnTeamSelectionAndScheduleMachine",
    predictableActionArguments: true,
    initial: "noop",
    tsTypes: {} as import("./oneOffScheduling.machine.typegen").Typegen0,
    schema: {
      context: {} as {
        assignTeamMachine: ActorRefFrom<AssignTeamMachine>;
        scheduleCardMachine: ActorRefFrom<ScheduleCardMachine>;
        recommendationMachine: ActorRefFrom<RecommendationMachine>;
      },
    },
    on: {
      NO_UNSCHEDULED_VISIT: {
        actions: ["disableTeamSelection"],
      },
      ADD_UNSCHEDULED_VISIT: {
        actions: ["enableTeamSelection"],
      },
      SCHEDULING_PARAMETERS_CHANGED: {
        actions: ["sendSchedulingChanged"],
      },
    },
    states: {
      noop: {},
    },
  },
  {
    actions: {
      disableTeamSelection: sendTo(ctx => ctx.assignTeamMachine, {
        type: "DISABLE",
      }),
      enableTeamSelection: sendTo(ctx => ctx.assignTeamMachine, {
        type: "ENABLE",
      }),
      sendSchedulingChanged: sendTo(ctx => ctx.recommendationMachine, {
        type: "SCHEDULE_SELF",
      }),
    },
  },
);
