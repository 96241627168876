import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deactivatedTip: {
    id: "selfServeBookings.GoogleInfoTip.deactivatedTip",
    defaultMessage: "What's this?",
    description: "Message displayed when Google sharing is deactivated.",
  },
  infoModalTitle: {
    id: "selfServeBookings.GoogleInfoTip.infoModalTitle",
    defaultMessage: "Turn searches into appointments",
    description: "Title for the Google info modal.",
  },
  infoModalContent: {
    id: "selfServeBookings.GoogleInfoTip.infoModalContent",
    defaultMessage:
      "Connect your booking link to your Google Business Profile and get bookings from the 77% of consumers who expect to book online.",
    description: "Content for the Google info modal.",
  },
  infoModalContent2: {
    id: "selfServeBookings.GoogleInfoTip.infoModalContent2",
    defaultMessage:
      "If you don't already have a Google Business Profile (just Google your business name to find out), [claim yours or request one](https://help.getjobber.com/hc/en-us/articles/22894204540951-Connect-Online-Booking-to-your-Google-Business-Profile#h_01HW5N30B817B6F7143TKJ2K5P). Then come back to online booking settings to connect them and start turning searches into clients.",
    description: "Content for the Google info modal.",
  },
  infoModalPreviewAltText: {
    id: "selfServeBookings.GoogleInfoTip.infoModalPreviewAltText",
    defaultMessage:
      "An example Google Business Profile with a Book online button",
    description: "Alt text for the Google info modal preview image.",
  },
  newActivationTip: {
    id: "selfServeBookings.GoogleInfoTip.newActivationTip",
    defaultMessage:
      "Google takes up to 1 week to add a booking link to your Google Business Profile. [Learn more](https://help.getjobber.com/hc/en-us/articles/22894204540951-Connect-Online-Booking-to-your-Google-Business-Profile#h_01HW5N30B817B6F7143TKJ2K5P)",
    description:
      "Message displayed when Google sharing is activated for the first time.",
  },
  activatedTip: {
    id: "selfServeBookings.GoogleInfoTip.activatedTip",
    defaultMessage: "Google Business Profile",
    description: "Message displayed when Google sharing is activated.",
  },
  activatedTooltip: {
    id: "selfServeBookings.GoogleInfoTip.activatedTooltip",
    defaultMessage:
      "If your online booking link hasn’t yet been connected to your Google Business Profile, contact our support team.",
    description: "Tooltip displayed when Google sharing is activated.",
  },
});
