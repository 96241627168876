import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import type {
  InvoiceDetailData,
  InvoiceDetailTotalsData,
} from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/types";
import { InvoiceDetailSortKey } from "~/utilities/API/graphqlFranchiseManagement";
import {
  ASSIGNED_USERS_HEADER,
  BALANCE_HEADER,
  CLIENT_NAME_HEADER,
  DEPOSIT_HEADER,
  DISCOUNT_AMOUNT_HEADER,
  DRAFTED_DATE_HEADER,
  DUE_DATE_HEADER,
  FRANCHISEE_NAME_FOOTER,
  FRANCHISEE_NAME_HEADER,
  INVOICE_NUMBER_HEADER,
  INVOICE_STATUS_COLORS,
  INVOICE_STATUS_KEYS,
  ISSUED_DATE_HEADER,
  PAID_DATE_HEADER,
  STATUS_HEADER,
  SUBJECT_HEADER,
  SUBTOTAL_HEADER,
  TAX_AMOUNT_HEADER,
  TAX_RATE_HEADER,
  TIP_TOTAL_HEADER,
  TOTAL_HEADER,
} from "jobber/franchise/features/Reporting/views/InvoiceDetailReport/constants";
import {
  INNER_CELL_WIDTH_BASE,
  INNER_CELL_WIDTH_LARGE,
  INNER_CELL_WIDTH_SMALL,
} from "~/jobber/features/Reporting/components/Report/constants";
import type {
  ColumnInfo,
  ReportColumns,
} from "~/jobber/features/Reporting/components/Report/types";
import {
  ComparableHeader,
  CurrencyFooter,
  InnerColumnCurrencyCell,
  InnerColumnTextCell,
  NonSortableHeader,
  StandardFooter,
} from "~/jobber/features/Reporting/components/Report/components/ReportCells/ReportCells";
import { createAccessorKey } from "~/jobber/features/Reporting/components/Report/utils/createAccessorKey";

export const useInvoiceDetailColumns = (
  totalsData: InvoiceDetailTotalsData | undefined,
): ReportColumns<InvoiceDetailData> => {
  const invoiceDetailColumns = [
    {
      id: InvoiceDetailSortKey.FRANCHISEE_NAME,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.FRANCHISEE_NAME),
      header: FRANCHISEE_NAME_HEADER,
      footer: () => <StandardFooter text={FRANCHISEE_NAME_FOOTER} />,
      minSize: INNER_CELL_WIDTH_LARGE,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.INVOICE_NUMBER,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.INVOICE_NUMBER),
      header: INVOICE_NUMBER_HEADER,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.CLIENT_NAME,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.CLIENT_NAME),
      header: CLIENT_NAME_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.STATUS,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.STATUS),
      header: STATUS_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => (
        <InlineLabel
          size="base"
          color={INVOICE_STATUS_COLORS[info.getValue() as string]}
        >
          {INVOICE_STATUS_KEYS[info.getValue() as string]}
        </InlineLabel>
      ),
    },
    {
      id: InvoiceDetailSortKey.SUBJECT,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.SUBJECT),
      header: SUBJECT_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.DRAFTED_ON,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.DRAFTED_ON),
      header: DRAFTED_DATE_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.ISSUED_ON,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.ISSUED_ON),
      header: ISSUED_DATE_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.PAID_ON,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.PAID_ON),
      header: PAID_DATE_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.DUE_ON,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.DUE_ON),
      header: DUE_DATE_HEADER,
      minSize: INNER_CELL_WIDTH_SMALL,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
    {
      id: "SUBTOTAL",
      accessorKey: "subtotal",
      enableSorting: false,
      header: () => <NonSortableHeader text={SUBTOTAL_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.subtotalAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.DISCOUNT_AMOUNT,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.DISCOUNT_AMOUNT),
      header: () => <ComparableHeader text={DISCOUNT_AMOUNT_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.discountAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.TAX_RATE,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.TAX_RATE),
      header: () => <ComparableHeader text={TAX_RATE_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
    },
    {
      id: InvoiceDetailSortKey.TAX_AMOUNT,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.TAX_AMOUNT),
      header: () => <ComparableHeader text={TAX_AMOUNT_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.taxAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.TOTAL,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.TOTAL),
      header: () => <ComparableHeader text={TOTAL_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.totalAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.TIPS_TOTAL,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.TIPS_TOTAL),
      header: () => <ComparableHeader text={TIP_TOTAL_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.tipAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.DEPOSIT_AMOUNT,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.DEPOSIT_AMOUNT),
      header: () => <ComparableHeader text={DEPOSIT_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.depositAmountTotal} />
      ),
    },
    {
      id: InvoiceDetailSortKey.BALANCE,
      accessorKey: createAccessorKey(InvoiceDetailSortKey.BALANCE),
      header: () => <ComparableHeader text={BALANCE_HEADER} />,
      cell: (info: ColumnInfo) => <InnerColumnCurrencyCell columnInfo={info} />,
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.balanceTotal} />
      ),
    },
    {
      id: "ASSIGNED_USERS",
      accessorKey: "assignedUsers",
      enableSorting: false,
      header: () => <NonSortableHeader text={ASSIGNED_USERS_HEADER} />,
      minSize: INNER_CELL_WIDTH_BASE,
      cell: (info: ColumnInfo) => <InnerColumnTextCell columnInfo={info} />,
    },
  ];

  return invoiceDetailColumns as ReportColumns<InvoiceDetailData>;
};
