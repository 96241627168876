import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { showToast } from "@jobber/components/Toast";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  type HappyPathExperimentSendSampleDataMessagesPayload,
  SendSampleDataMessagesInput,
  type SendSampleDataMessagesMutation,
  type SendSampleDataMessagesMutationVariables,
} from "~/utilities/API/graphql";
import step_1 from "@images/happyPathP1V2/step_1.png";
import step_2 from "@images/happyPathP1V2/step_2.png";
import step_3 from "@images/happyPathP1V2/step_3.png";
import step_4 from "@images/happyPathP1V2/step_4.png";
import step_5 from "@images/happyPathP1V2/step_5.png";
import { SEND_SAMPLE_DATA_MESSAGES } from "./SendSampleDataMessages.graphql";
import { messages } from "./messages";
import type { AccordionItemContentProps } from "./AccordionItem";

function trackCtaClickedEvent(
  ctaId: string,
  source: string,
  extraProps: Record<string, string> = {},
) {
  const baseProps = {
    name: ctaId,
    source: source,
  };
  const allProps = { ...baseProps, ...extraProps };

  Amplitude.TRACK_EVENT("CTA Clicked", allProps);
}

function trackSendSampleCTAEvents(
  responseData: HappyPathExperimentSendSampleDataMessagesPayload,
  source: string,
) {
  if (responseData) {
    const smsIncluded = responseData.smsIncluded;
    const userErrors = responseData.userErrors;
    const extraProps = {
      type: smsIncluded ? "email+sms" : "email_only",
      status: userErrors.length > 0 ? "failed" : "success",
    };

    trackCtaClickedEvent("send_sample", source, extraProps);
  }
}
export const ctaNames = [
  "happy_path_p1_v2_quote_invoice_followup_cta",
  "happy_path_p1_v2_visit_reminder_cta",
  "happy_path_p1_v2_job_followup_cta",
  "happy_path_p1_v2_client_portal_cta",
  "happy_path_p1_v2_jobber_calendar_cta",
];

export const accordionImageMap = {
  [ctaNames[0]]: { src: step_1, alt: "quotes invoices follow up" },
  [ctaNames[1]]: { src: step_2, alt: "visit reminder" },
  [ctaNames[2]]: { src: step_3, alt: "jobs follow up" },
  [ctaNames[3]]: { src: step_4, alt: "client portal" },
  [ctaNames[4]]: { src: step_5, alt: "jobber calendar" },
};

export const useAccordionItems = (
  clientHubLink: string,
): AccordionItemContentProps[] => {
  const { formatMessage } = useIntl();
  const [sendSampleDataMessages, { loading: sampleDataMessagesLoading }] =
    useMutation<
      SendSampleDataMessagesMutation,
      SendSampleDataMessagesMutationVariables
    >(SEND_SAMPLE_DATA_MESSAGES);

  return [
    {
      title: formatMessage(messages.invoicesTitle),
      description: formatMessage(messages.invoicesDescription),
      ctaName: ctaNames[0],
      buttonProps: {
        icon: "sendMessage",
        label: formatMessage(messages.sendASampleLabel),
        loading: sampleDataMessagesLoading,
        onClick: async () => {
          const response = await sendSampleDataMessages({
            variables: {
              input: SendSampleDataMessagesInput.QUOTE_AND_INVOICE_FOLLOW_UPS,
            },
          });

          handleSendMessagesResponse(
            "happy_path_p1_v2_step1_cta",
            response.data
              ?.happyPathExpSendSampleDataMessages as HappyPathExperimentSendSampleDataMessagesPayload,
          );
        },
      },
    },
    {
      title: formatMessage(messages.remindersTitle),
      description: formatMessage(messages.remindersDescription),
      ctaName: ctaNames[1],
      buttonProps: {
        icon: "sendMessage",
        label: formatMessage(messages.sendASampleLabel),
        loading: sampleDataMessagesLoading,
        onClick: async () => {
          const response = await sendSampleDataMessages({
            variables: {
              input: SendSampleDataMessagesInput.VISIT_REMINDERS,
            },
          });

          handleSendMessagesResponse(
            "happy_path_p1_v2_step2_cta",
            response.data
              ?.happyPathExpSendSampleDataMessages as HappyPathExperimentSendSampleDataMessagesPayload,
          );
        },
      },
    },
    {
      title: formatMessage(messages.jobsTitle),
      description: formatMessage(messages.jobsDescription),
      ctaName: ctaNames[2],
      buttonProps: {
        icon: "sendMessage",
        label: formatMessage(messages.sendASampleLabel),
        loading: sampleDataMessagesLoading,
        onClick: async () => {
          const response = await sendSampleDataMessages({
            variables: {
              input: SendSampleDataMessagesInput.FOLLOW_UP_SURVEY,
            },
          });

          handleSendMessagesResponse(
            "happy_path_p1_v2_step3_cta",
            response.data
              ?.happyPathExpSendSampleDataMessages as HappyPathExperimentSendSampleDataMessagesPayload,
          );
        },
      },
    },
    {
      title: formatMessage(messages.clienthubTitle),
      description: formatMessage(messages.clienthubDescription),
      ctaName: ctaNames[3],
      buttonProps: {
        icon: "export",
        label: formatMessage(messages.clientHubLabel),
        onClick: () => {
          window.open(clientHubLink, "_blank", "noopener, noreferrer");
          trackCtaClickedEvent("client_hub", "happy_path_p1_v2_step4_cta");
        },
      },
    },
    {
      title: formatMessage(messages.calendarTitle),
      description: formatMessage(messages.calendarDescription),
      ctaName: ctaNames[4],
      buttonProps: {
        label: formatMessage(messages.scheduleJobsLabel),
        onClick: () => {
          trackCtaClickedEvent("schedule_a_job", ctaNames[4]);
          window.location.href = "/work_orders/new";
        },
      },
      urlProps: {
        href: "/home",
        label: formatMessage(messages.doItLaterLabel),
        onClick: () => {
          trackCtaClickedEvent("do_it_later", ctaNames[4]);
        },
      },
    },
  ];

  function handleSendMessagesResponse(
    ctaName: string,
    responseData: HappyPathExperimentSendSampleDataMessagesPayload,
  ) {
    trackSendSampleCTAEvents(responseData, ctaName);
    if (responseData.userErrors.length > 0) {
      // handled in AccordionItem.tsx since that's where we handle moving or not moving to the next step
      throw new Error(responseData.userErrors[0].message);
    } else {
      const successMessage = responseData.smsIncluded
        ? messages.sendSampleToastSuccessMessageEmailAndSMS
        : messages.sendSampleToastSuccessMessageEmailOnly;

      showToast({
        message: formatMessage(successMessage),
      });
    }
  }
};
