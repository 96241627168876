import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Glimmer } from "@jobber/components/Glimmer";
import React from "react";
import { Divider } from "@jobber/components/Divider";
import styles from "./CheckoutSummary.module.css";

export const CheckoutSummaryGlimmer = () => {
  return (
    <div className={styles.SummaryCard}>
      <Content>
        <Card>
          <Content spacing="large">
            <Glimmer.Header />
            <div className={styles.glimmerCard}>
              <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
                <GlimmerField />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <GlimmerField />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <GlimmerField />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <div className={`${styles.glimmerTray}`}>
                  <Glimmer size={"base"} />
                </div>
                <div className={`${styles.glimmerTray}`}>
                  <Glimmer size={"large"} />
                </div>
              </div>
              <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
                <GlimmerField short={true} />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <GlimmerField short={true} />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <GlimmerField short={true} />
                <GlimmerField short={true} />
                <GlimmerDivider />
                <div
                  className={`${styles.glimmerFieldShort} + ${styles.glimmerTray}`}
                >
                  <Glimmer size={"base"} />
                </div>
                <div
                  className={`${styles.glimmerFieldShort} + ${styles.glimmerTray}`}
                >
                  <Glimmer size={"large"} />
                </div>
              </div>
            </div>
          </Content>
        </Card>
      </Content>
    </div>
  );
};

export interface GlimmerFieldProps {
  short?: boolean;
}
export const GlimmerField = ({ short }: GlimmerFieldProps) => {
  const classes = `${styles.glimmerField} ${
    short ? styles.glimmerFieldShort : ""
  }`;
  return (
    <div className={classes}>
      <Glimmer size={"base"} />
    </div>
  );
};

const GlimmerDivider = () => {
  return <Divider size={"large"} direction={"horizontal"} />;
};
