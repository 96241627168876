import { useMutation } from "@apollo/client";
import { useState } from "react";
import type { TwilioRegistrationFormMutationFields } from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import type {
  MutationErrors,
  PurchaseDedicatedPhoneNumberMutation,
} from "~/utilities/API/graphql";
import { PURCHASE_DEDICATED_PHONE_NUMBER } from "jobber/settings/dedicatedPhoneNumber/graphql";

export function usePurchaseDedicatedPhoneNumber(requiresRegistration: boolean) {
  const [purchaseDedicatedPhoneNumber, { loading: mutationLoading }] =
    useMutation<PurchaseDedicatedPhoneNumberMutation>(
      PURCHASE_DEDICATED_PHONE_NUMBER,
    );
  const [savedNumber, setSavedNumber] = useState<string>();

  return {
    savedNumber,
    mutationLoading,
    handleSaveNumber,
  };

  async function handleSaveNumber(
    phoneNumber: string,
    carrierRegistrationData?: TwilioRegistrationFormMutationFields,
  ): Promise<{
    responseNumber: string | undefined;
    mutationErrors: MutationErrors[];
  }> {
    const { data } = await triggerMutation();
    const responseNumber =
      data?.purchaseDedicatedPhoneNumber?.dedicatedPhoneNumber
        ?.friendlyPhoneNumber;
    setSavedNumber(responseNumber);

    return {
      responseNumber,
      mutationErrors: data?.purchaseDedicatedPhoneNumber?.userErrors || [],
    };

    async function triggerMutation() {
      return purchaseDedicatedPhoneNumber({
        variables: {
          input: {
            phoneNumber,
            carrierRegistrationInformation: requiresRegistration
              ? carrierRegistrationData
              : undefined,
          },
        },
      });
    }
  }
}
