import Celebrate25ReviewsVisualDesktop from "@images/googleReviewCelebrations/Celebrate25ReviewsVisualDesktop.png";
import Celebrate200ReviewsVisualDesktop from "@images/googleReviewCelebrations/Celebrate200ReviewsVisualDesktop.png";
import Celebrate50ReviewsVisualDesktop from "@images/googleReviewCelebrations/Celebrate50ReviewsVisualDesktop.png";
import Celebrate100ReviewsVisualDesktop from "@images/googleReviewCelebrations/Celebrate100ReviewsVisualDesktop.png";
import Celebrate25ReviewsVisualTablet from "@images/googleReviewCelebrations/Celebrate25ReviewsVisualTablet.png";
import Celebrate200ReviewsVisualTablet from "@images/googleReviewCelebrations/Celebrate200ReviewsVisualTablet.png";
import Celebrate50ReviewsVisualTablet from "@images/googleReviewCelebrations/Celebrate50ReviewsVisualTablet.png";
import Celebrate100ReviewsVisualTablet from "@images/googleReviewCelebrations/Celebrate100ReviewsVisualTablet.png";
import Celebrate25ReviewsVisualMobile from "@images/googleReviewCelebrations/Celebrate25ReviewsVisualMobile.png";
import Celebrate200ReviewsVisualMobile from "@images/googleReviewCelebrations/Celebrate200ReviewsVisualMobile.png";
import Celebrate50ReviewsVisualMobile from "@images/googleReviewCelebrations/Celebrate50ReviewsVisualMobile.png";
import Celebrate100ReviewsVisualMobile from "@images/googleReviewCelebrations/Celebrate100ReviewsVisualMobile.png";

export const Celebrate25ReviewsAssets = {
  desktopImage: Celebrate25ReviewsVisualDesktop,
  tabletImage: Celebrate25ReviewsVisualTablet,
  mobileImage: Celebrate25ReviewsVisualMobile,
};

export const Celebrate50ReviewsAssets = {
  desktopImage: Celebrate50ReviewsVisualDesktop,
  tabletImage: Celebrate50ReviewsVisualTablet,
  mobileImage: Celebrate50ReviewsVisualMobile,
};

export const Celebrate100ReviewsAssets = {
  desktopImage: Celebrate100ReviewsVisualDesktop,
  tabletImage: Celebrate100ReviewsVisualTablet,
  mobileImage: Celebrate100ReviewsVisualMobile,
};

export const Celebrate200ReviewsAssets = {
  desktopImage: Celebrate200ReviewsVisualDesktop,
  tabletImage: Celebrate200ReviewsVisualTablet,
  mobileImage: Celebrate200ReviewsVisualMobile,
};
