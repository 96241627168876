import { gql } from "@apollo/client";

export const REVIEW_REPLY = gql`
  mutation ReviewReplyGenerate(
    $conversationId: Uuid!
    $requestId: Uuid!
    $input: ReviewReplyGenerateInput!
  ) {
    reviewReplyGenerate(
      conversationId: $conversationId
      requestId: $requestId
      input: $input
    ) {
      userErrors {
        message
        path
      }
    }
  }
`;

const AI_ASSISTANT_MESSAGE_DATA = gql`
  fragment AiAssistantReviewReplyResponseData on AiAssistantMessage {
    conversationId
    requestId
    createdAt
    content {
      ... on AiAssistantReviewReplyResponse {
        response
      }
    }
  }
`;

export const AUTOMATED_REVIEW_REPLY_SUBSCRIPTION = gql`
  subscription AutomatedReviewReply($conversationId: Uuid!) {
    reviewReply(conversationId: $conversationId) {
      ...AiAssistantReviewReplyResponseData
    }
  }
  ${AI_ASSISTANT_MESSAGE_DATA}
`;
