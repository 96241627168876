export interface RemoteBooleanIdleState {
  type: "idle";
  targetEnable: undefined;
  currentState: boolean;
}

interface RemoteBooleanFetchingState {
  type: "fetching";
  targetEnable: boolean;
  currentState: boolean;
}

interface RemoteBooleanSuccessState {
  type: "success";
  targetEnable: undefined;
  currentState: boolean;
}

type RemoteBooleanState =
  | RemoteBooleanIdleState
  | RemoteBooleanFetchingState
  | RemoteBooleanSuccessState;

export interface RemoteBooleanStartAction {
  type: "start";
  targetEnable: boolean;
}

export interface RemoteBooleanResetAction {
  type: "reset";
}

export interface RemoteBooleanSuccessAction {
  type: "success";
  updatedEnable: boolean;
}

type RemoteBooleanAction =
  | RemoteBooleanStartAction
  | RemoteBooleanResetAction
  | RemoteBooleanSuccessAction;

export function remoteBooleanReducer(
  prevState: RemoteBooleanState,
  action: RemoteBooleanAction,
): RemoteBooleanState {
  switch (action.type) {
    case "start":
      return {
        ...prevState,
        type: "fetching",
        targetEnable: action.targetEnable,
      };
    case "reset":
      return {
        ...prevState,
        type: "idle",
        targetEnable: undefined,
      };
    case "success":
      return {
        ...prevState,
        type: "success",
        targetEnable: undefined,
        currentState: action.updatedEnable,
      };
    default:
      return prevState;
  }
}
