import { useLazyQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import type {
  ProductOrServiceFragment,
  ProductOrServiceListQuery,
  ProductOrServiceSearchQuery,
  ProductOrServiceSearchQueryVariables,
} from "~/utilities/API/graphql";
import { PRODUCT_OR_SERVICE_LIST, PRODUCT_OR_SERVICE_SEARCH } from "./graphql";

export function useProductAndServiceSearch(
  advancedQuotingEnabled: boolean,
  quoteMarginsEnabled: boolean,
) {
  const [searchString, setSearchString] = useState("");
  const {
    data: defaultList,
    loadingInitialContent: loading,
    loadingNextPage: loadingNextPage,
    nextPage,
    error,
  } = useCollectionQuery<ProductOrServiceListQuery>({
    query: PRODUCT_OR_SERVICE_LIST,
    queryOptions: {
      variables: {
        advancedQuotingEnabled,
        quoteMarginsEnabled,
      },
    },
    getCollectionByPath: data => data?.products,
  });

  const [
    searchProducts,
    { data: searchList, loading: searching, error: searchingError },
  ] = useLazyQuery<
    ProductOrServiceSearchQuery,
    ProductOrServiceSearchQueryVariables
  >(PRODUCT_OR_SERVICE_SEARCH);

  return {
    search: useCallback(handleSearch, [
      searchProducts,
      advancedQuotingEnabled,
      quoteMarginsEnabled,
    ]),
    productsAndServices: serviceList(
      searchString
        ? searchList?.productsSearch.nodes
        : defaultList?.products.edges?.map(x => x.node),
    ),
    loading,
    searching,
    loadingNextPage,
    error: searchString ? searchingError : error,
    nextPage:
      searchString || !defaultList?.products.pageInfo.hasNextPage
        ? undefined
        : nextPage,
  };

  function handleSearch(input: string) {
    setSearchString(input);
    if (input) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      searchProducts({
        variables: {
          searchTerm: input,
          advancedQuotingEnabled,
          quoteMarginsEnabled,
        },
      });
    }
  }
}

function serviceList(fullList: ProductOrServiceFragment[] | undefined) {
  if (!fullList) return [];

  return fullList.filter(x => !x.onlineBookingsEnabled);
}
