import { useMachine } from "@xstate/react";
import { assign, spawn } from "xstate";
import type { RepeatOption } from "../types";
import {
  recommendationMachine as recommendationMachineConfiguration,
  useScheduleCardMachineConfiguration,
} from "../../ScheduleCard";
import { useAssignTeamMachineConfiguration } from "../../AssignTeam";
import { oneOffSchedulingMachine } from "../oneOffScheduling.machine";

export const useOneOffSchedulingMachine = (
  initialAssignedToIds: string[] = [],
  repeatOptions: RepeatOption[] = [],
) => {
  const [
    childAssignTeamMachine,
    childAssignTeamMachineContext,
    childAssignTeamMachineConfig,
  ] = useAssignTeamMachineConfiguration(new Set(initialAssignedToIds));
  const childScheduleCardMachine =
    useScheduleCardMachineConfiguration(repeatOptions);

  const [current] = useMachine(oneOffSchedulingMachine, {
    devTools: true,
    actions: {
      spawnTeamSelectionAndScheduleMachine: assign({
        assignTeamMachine: () =>
          spawn(
            childAssignTeamMachine
              .withConfig(childAssignTeamMachineConfig)
              .withContext(childAssignTeamMachineContext),
          ),
        scheduleCardMachine: () => spawn(childScheduleCardMachine),
        recommendationMachine: () => spawn(recommendationMachineConfiguration),
      }),
    },
  });

  return {
    assignTeamMachine: current.context.assignTeamMachine,
    scheduleCardMachine: current.context.scheduleCardMachine,
    recommendationMachine: current.context.recommendationMachine,
  };
};
