import React, { memo, useMemo } from "react";
import type { ApolloError } from "@apollo/client";
import { WorkObjectGlobalOwnershipsCard } from "jobber/features/WorkObjectOwnerships/views/WorkObjectGlobalOwnershipsCard/WorkObjectGlobalOwnershipsCard";
import {
  type CommsSettingsPageDataQuery,
  type Maybe,
  NotificationReminderUnit,
  type WorkObjectGlobalOwnershipFragment,
} from "~/utilities/API/graphql";
import type { NotificationTypes } from "jobber/settings/notifications/notificationInterfaces";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import {
  type SettingsDescriptionProps,
  type SettingsRowProps,
  SettingsSection,
} from "jobber/settings/notifications/components/SettingsSection";
import { content } from "./content";

export interface GlobalOwnershipsDataProps {
  ownerships: WorkObjectGlobalOwnershipFragment[];
  error: ApolloError | undefined;
}

export interface CommsSettingsProps {
  data: CommsSettingsPageDataQuery | undefined;
  globalOwnershipsData: GlobalOwnershipsDataProps;
}

interface SectionType {
  title: string;
  sectionData: SettingsRowProps[];
  targetId: string;
}

export function CommsSettings({
  data,
  globalOwnershipsData,
}: CommsSettingsProps) {
  const sections = useMemo(() => getSections(data), [data]);

  return (
    <>
      <WorkObjectGlobalOwnershipsCard
        ownerships={globalOwnershipsData.ownerships}
        error={globalOwnershipsData.error?.message}
      />
      {data &&
        sections.map((section: SectionType, index: number) => (
          <SettingsSection
            showToggles={true}
            key={`${section.title}_${index}`}
            sectionTitle={section.title}
            sectionRows={section.sectionData}
            targetId={section.targetId}
          />
        ))}
      <GeneralSection data={data} />
    </>
  );
}

function getSections(data: CommsSettingsPageDataQuery | undefined) {
  return [
    {
      title: "Requests",
      sectionData: data ? requestSection(data) : [],
      targetId: "requests",
    },
    {
      title: "Quotes",
      sectionData: data ? quoteSection(data) : [],
      targetId: "quotes",
    },
    {
      title: "Jobs",
      sectionData: data ? jobSection(data) : [],
      targetId: "jobs",
    },
    {
      title: "Invoices",
      sectionData: data ? invoiceSection(data) : [],
      targetId: "invoices",
    },
  ];
}

function requestSection(data: CommsSettingsPageDataQuery) {
  const sectionData: SettingsRowProps[] = [];

  addSectionData(sectionData, data.requestSubmitted, {
    ...content.requestSubmitted,
    featureAvailable: jobberOnline.features.workRequests.available,
    requiredFeaturePlan: jobberOnline.features.workRequests.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  addSectionData(sectionData, data.assessmentBooked, {
    ...content.assessmentBooked,
    featureAvailable:
      jobberOnline.features.assessmentBookingConfirmation.available,
    requiredFeaturePlan: jobberOnline.features.assessmentBookingConfirmation
      .available
      ? undefined
      : content.connectAndGrowPlan,
  });

  addSectionData(sectionData, data.assessmentReminder, {
    ...content.assessmentReminders,
    scheduleTemplate: getCorrectString(content.assessmentReminders.templates),
    scheduleFootnote: getAppointmentFootNote(
      data.assessmentReminder,
      content.assessmentReminders.footnotes,
    ),
    featureAvailable: jobberOnline.features.assessmentReminders.available,
    requiredFeaturePlan: jobberOnline.features.assessmentReminders.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  return sectionData;
}
function quoteSection(data: CommsSettingsPageDataQuery) {
  const sectionData: SettingsRowProps[] = [];

  addSectionData(sectionData, data.quoteSent, content.quoteSent);
  addSectionData(sectionData, data.quoteApproved, content.quoteApproved);
  !jobberOnline.canUseAutomations &&
    addSectionData(sectionData, data.quoteFollowup, {
      ...content.quoteFollowUps,
      scheduleTemplate: getCorrectString(content.quoteFollowUps.templates),
      scheduleFootnote: getCorrectString(content.quoteFollowUps.footnotes),
      featureAvailable: jobberOnline.features.quoteFollowUps.available,
      requiredFeaturePlan: jobberOnline.features.quoteFollowUps.available
        ? undefined
        : content.growPlan,
      allowUnitChange: false,
      allowDeliveryMethodChange: false,
    });

  return sectionData;
}
function jobSection(data: CommsSettingsPageDataQuery) {
  const sectionData: SettingsRowProps[] = [];

  addSectionData(sectionData, data.jobBookingConfirmation, {
    ...content.jobBookingConfirmation,
    featureAvailable: jobberOnline.features.bookingNotification.available,
    requiredFeaturePlan: jobberOnline.features.bookingNotification.available
      ? undefined
      : content.connectAndGrowPlan,
  });
  addSectionData(sectionData, data.visitReminder, {
    ...content.visitReminders,
    scheduleTemplate: getCorrectString(content.visitReminders.templates),
    scheduleFootnote: getAppointmentFootNote(
      data.visitReminder,
      content.visitReminders.footnotes,
    ),
    featureAvailable: jobberOnline.features.visitReminders.available,
    requiredFeaturePlan: jobberOnline.features.visitReminders.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  addSectionData(sectionData, data.jobFormSent, {
    ...content.jobFormSent,
    featureAvailable: jobberOnline.features.jobForms.available,
    requiredFeaturePlan: jobberOnline.features.jobForms.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  addSectionData(sectionData, data.chemicalTreatmentSent, {
    ...content.chemicalTreatmentSent,
    featureAvailable: jobberOnline.features.chemicalTracking.available,
    requiredFeaturePlan: jobberOnline.features.chemicalTracking.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  addSectionData(sectionData, data.jobFollowup, {
    ...content.jobFollowUps,
    scheduleTemplate: content.jobFollowUps.template,
    featureAvailable: jobberOnline.features.followUps.available,
    requiredFeaturePlan: jobberOnline.features.followUps.available
      ? undefined
      : content.connectAndGrowPlan,
  });

  return sectionData;
}
function invoiceSection(data: CommsSettingsPageDataQuery) {
  const sectionData: SettingsRowProps[] = [];

  addSectionData(sectionData, data.invoiceSent, content.invoiceSent);

  !jobberOnline.canUseAutomations &&
    addSectionData(sectionData, data.invoiceFollowup, {
      ...content.invoiceFollowUps,
      scheduleTemplate: getCorrectString(content.invoiceFollowUps.templates),
      scheduleFootnote: getCorrectString(content.invoiceFollowUps.footnotes),
      featureAvailable: jobberOnline.features.invoiceFollowUps.available,
      requiredFeaturePlan: jobberOnline.features.invoiceFollowUps.available
        ? undefined
        : content.connectAndGrowPlan,
      allowDeliveryMethodChange: false,
    });
  addSectionData(
    sectionData,
    data.balanceAdjustmentReceiptSent,
    content.balanceAdjustmentReceiptSent,
  );

  return sectionData;
}

interface GeneralSectionProps {
  data: CommsSettingsPageDataQuery | undefined;
}

const GeneralSection = memo(function GeneralSection({
  data,
}: GeneralSectionProps) {
  if (!data) {
    return <></>;
  }

  const sectionData = generalSection(data);

  return (
    <SettingsSection
      showToggles={true}
      sectionTitle={"General"}
      sectionRows={sectionData}
      targetId="general"
    />
  );
});

function generalSection(data: CommsSettingsPageDataQuery) {
  const sectionData: SettingsRowProps[] = [];

  addSectionData(sectionData, data.statementSent, content.statementSent);

  addSectionData(
    sectionData,
    data.requestCardOnFile,
    content.requestPaymentMethodOnFile,
  );
  addSectionData(sectionData, data.signedDocSent, content.signedDocumentSent);

  return sectionData;
}

function addSectionData(
  section: SettingsRowProps[],
  data: Maybe<NotificationTypes>,
  description: SettingsDescriptionProps,
) {
  if (data != undefined) {
    section.push({
      descriptions: description,
      notification: data,
    });
  }
}

function getCorrectString(stringDictionary: {
  withSms: string;
  withoutSms: string;
}) {
  if (jobberOnline.features.smsMessaging.enabled) {
    return stringDictionary.withSms;
  }

  return stringDictionary.withoutSms;
}

function getAppointmentFootNote(
  notification: NotificationTypes | undefined,
  stringDictionary: {
    withSms: string;
    withoutSms: string;
  },
): string {
  if (!notification) {
    return "";
  }

  const showFootNote = notification.schedules.nodes.some(schedule => {
    return (
      schedule.at === null &&
      schedule.offset &&
      schedule.offset.unit === NotificationReminderUnit.DAY
    );
  });

  if (showFootNote) {
    return getCorrectString(stringDictionary);
  }

  return "";
}
