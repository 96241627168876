import { gql, useQuery } from "@apollo/client";
import type { DepositedPayoutsQuery } from "~/utilities/API/graphql";

export const DEPOSITED_PAYOUTS_QUERY = gql`
  query DepositedPayouts(
    $weekStart: ISO8601DateTime!
    $monthStart: ISO8601DateTime!
  ) {
    monthly: jobberPaymentsPayouts {
      paidAmountSinceDate(startDate: $monthStart)
    }
    weekly: jobberPaymentsPayouts {
      paidAmountSinceDate(startDate: $weekStart)
    }
  }
`;

export function useDepositedPayouts() {
  const currentMonthRange = getStartAndEndOfCurrentMonth();
  const currentWeekRange = getStartAndEndOfCurrentWeek();

  const { loading, data } = useQuery<DepositedPayoutsQuery>(
    DEPOSITED_PAYOUTS_QUERY,
    {
      variables: {
        weekStart: currentWeekRange.weekStart.toISOString(),
        monthStart: currentMonthRange.monthStart.toISOString(),
      },
      fetchPolicy: "no-cache",
    },
  );

  return {
    loading,
    monthAmount: data?.monthly?.paidAmountSinceDate,
    monthStart: currentMonthRange.monthStart,
    monthEnd: currentMonthRange.monthEnd,
    weekAmount: data?.weekly?.paidAmountSinceDate,
    weekStart: currentWeekRange.weekStart,
    weekEnd: currentWeekRange.weekEnd,
  };
}

function getStartAndEndOfCurrentMonth(): { monthStart: Date; monthEnd: Date } {
  const today = new Date();

  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return { monthStart, monthEnd };
}

function getStartAndEndOfCurrentWeek(): { weekStart: Date; weekEnd: Date } {
  const today = new Date();
  const day = today.getDay();

  // Calculate difference from Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffToMonday = day === 0 ? 6 : day - 1;

  // Start of the week (Monday)
  const weekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - diffToMonday,
  );
  weekStart.setDate(today.getDate() - diffToMonday);

  // End of the week (Sunday)
  const weekEnd = new Date(
    weekStart.getFullYear(),
    weekStart.getMonth(),
    weekStart.getDate(),
  );
  weekEnd.setDate(weekStart.getDate() + 6);

  return { weekStart, weekEnd };
}
