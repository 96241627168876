import currency from "currency.js";

const THOUSAND = 1_000.0;
const MILLION = 1_000_000.0;

export function formatCurrency(
  currencyUnit: string,
  amount?: number,
  abbreviate = true,
) {
  const STANDARD_FORMAT = {
    symbol: currencyUnit,
    separator: ",",
    precision: abbreviate ? 1 : 0,
  };

  if (amount === undefined) {
    return "";
  }

  if (amount < THOUSAND) {
    return currency(amount, { ...STANDARD_FORMAT, precision: 2 }).format();
  }
  if (!abbreviate) {
    return currency(amount, STANDARD_FORMAT).format();
  }
  if (amount < MILLION) {
    return currency(amount, { ...STANDARD_FORMAT, pattern: `!#K` })
      .divide(THOUSAND)
      .format();
  }
  // The amount is over a MILLION !
  return currency(amount, { ...STANDARD_FORMAT, pattern: `!#M` })
    .divide(MILLION)
    .format();
}
