import React from "react";
import { useIntl } from "react-intl";
import { PurchaseFlowButton } from "~/jobber/marketingCampaignsExpansion/components/PurchaseFlowButton";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";
import { Amplitude } from "~/utilities/analytics/Amplitude";

export interface BottomBarPurchaseFlowProps {
  enableAutomation: boolean | undefined;
  isMobileBilled: boolean;
  recurlyPublicKey: string | null;
  salesforceTrackingId: string | null;
  onNext: () => void;
  checkIsFormValid: () => boolean;
  setShowScheduleSendModal: (value: boolean) => void;
  loadingAll: boolean;
}

export function BottomBarPurchaseFlow({
  enableAutomation,
  isMobileBilled,
  recurlyPublicKey,
  salesforceTrackingId,
  onNext,
  checkIsFormValid,
  setShowScheduleSendModal,
  loadingAll,
}: BottomBarPurchaseFlowProps): JSX.Element {
  const { formatMessage } = useIntl();

  const onDismissPurchase = () => {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      name: "dismissed_email_campaign_purchase_modal",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      salesforce_id: `${salesforceTrackingId}`,
    });
  };

  const onSubmitPurchase = () => {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "clicked_email_campaign_purchase_modal",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      salesforce_id: `${salesforceTrackingId}`,
    });
  };

  return (
    <>
      {!enableAutomation && (
        <PurchaseFlowButton
          isMobileBilled={isMobileBilled}
          recurlyPublicKey={recurlyPublicKey ?? ""}
          addonSetIdentifier={"campaigns"}
          fullWidth={true}
          buttonText={formatMessage(messages.sendCampaignLaterButton)}
          buttonType="tertiary"
          modalActionProps={{
            buttonText: formatMessage(messages.payAndScheduleButton),
            onClick: onSubmitPurchase,
          }}
          successAction={() => setShowScheduleSendModal(true)}
          onClick={checkIsFormValid}
          onDismiss={onDismissPurchase}
          loading={loadingAll}
        />
      )}
      {!enableAutomation && (
        <PurchaseFlowButton
          isMobileBilled={isMobileBilled}
          recurlyPublicKey={recurlyPublicKey ?? ""}
          addonSetIdentifier={"campaigns"}
          fullWidth={true}
          buttonText={formatMessage(messages.sendCampaignButton)}
          modalActionProps={{
            buttonText: formatMessage(messages.payAndSendButton),
            onClick: onSubmitPurchase,
          }}
          successAction={onNext}
          onClick={checkIsFormValid}
          onDismiss={onDismissPurchase}
          loading={loadingAll}
        />
      )}
      {enableAutomation && (
        <PurchaseFlowButton
          isMobileBilled={isMobileBilled}
          recurlyPublicKey={recurlyPublicKey ?? ""}
          addonSetIdentifier={"campaigns"}
          fullWidth={true}
          buttonText={formatMessage(messages.automatedCampaignActivateButton)}
          modalActionProps={{
            buttonText: formatMessage(messages.payAndActivateButton),
            onClick: onSubmitPurchase,
          }}
          successAction={onNext}
          onClick={checkIsFormValid}
          onDismiss={onDismissPurchase}
          loading={loadingAll}
        />
      )}
    </>
  );
}
