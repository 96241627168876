import type { IconNames } from "@jobber/components";
import { Button, Content, Glimmer, Heading } from "@jobber/components";
import styles from "./SideDrawerMenuOption.module.css";

interface SideDrawerMenuOptionProps {
  ariaLabel?: string;
  heading?: string;
  icon?: IconNames;
  iconAlignment?: "left" | "right";
  label: string;
  loading?: boolean;
  onClick: () => void;
}

export function SideDrawerMenuOption({
  ariaLabel,
  heading,
  icon = "arrowRight",
  iconAlignment = "right",
  label,
  loading = false,
  onClick,
}: SideDrawerMenuOptionProps) {
  if (loading) {
    return <Glimmer size="largest" />;
  }

  return (
    <div className={styles.container}>
      <Content spacing="smallest">
        {heading && <Heading level={4}>{heading}</Heading>}
        <Button
          type="secondary"
          fullWidth={true}
          variation="subtle"
          ariaLabel={ariaLabel || heading || label}
          label={label}
          icon={icon}
          iconOnRight={iconAlignment == "right" ? true : false}
          onClick={onClick}
        />
      </Content>
    </div>
  );
}
