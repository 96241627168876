import React, { useCallback, useState } from "react";
import { v1 } from "uuid";
import { InputNumber } from "@jobber/components/InputNumber";
import { InputValidation } from "@jobber/components/InputValidation";
import styles from "./styles.module.css";

interface InputRangeBaseProps {
  max: number;
  min: number;
  onChange: (value: number) => void;
  placeholder?: string;
  value: number;
}
type InputNumberProps = Parameters<typeof InputNumber>[0];
type InputRangeProps = Omit<
  InputNumberProps,
  keyof InputRangeBaseProps | "inline" | "size" | "maxLength"
> &
  InputRangeBaseProps;

export function InputRange({
  max,
  min,
  onChange,
  placeholder,
  value,
  ...inputNumberProps
}: InputRangeProps) {
  const [rangeIdentifier] = useState(v1);
  const [validationMessages, setValidationMessages] = useState<string>();
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.valueAsNumber);
    },
    [onChange],
  );

  const cssValue = `${((value - min) / (max - min)) * 100}%`;

  return (
    <div>
      <label htmlFor={`range-${rangeIdentifier}`}>{placeholder}</label>
      <div className={styles.inputRangeControls}>
        <input
          id={`range-${rangeIdentifier}`}
          type="range"
          max={max}
          min={min}
          value={value}
          onChange={handleInputChange}
          // Need to set a CSS variable via the style property, this doesn't fit with normal naming conventions or known properties
          // eslint-disable-next-line @typescript-eslint/naming-convention
          style={{ "--percent-range-value": cssValue } as React.CSSProperties}
        />
        <InputNumber
          data-testid="input-range-text-input"
          value={value}
          max={max}
          maxLength={max.toString().length + 1}
          min={min}
          onChange={onChange}
          onValidation={setValidationMessages}
          size="small"
          inline
          {...inputNumberProps}
        />
      </div>
      {validationMessages && <InputValidation message={validationMessages} />}
    </div>
  );
}
