import React, { useEffect, useReducer, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import {
  RailsPropsAsContexts,
  type RailsPropsAsContextsProps,
} from "~/utilities/contexts/internal/RailsPropsAsContexts";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { csrfToken } from "utilities/csrfToken";
import { submitForm } from "./CancellationFormSubmit";
import { cancellationQuestions } from "./CancellationFormQuestionMap";
import { CancellationFormReducer } from "./CancellationFormReducer";
import type {
  AdditionalSubscription,
  CancellationFormAnswer,
  CancellationFormStep,
  HttpResponse,
  ResponseBody,
} from "./CancellationForm.d";
import type {
  CancellationFormQuestion,
  CancellationFormQuestionTemplates,
} from "./CancellationFormQuestion.d";
import { AdditionalSubscriptionStep } from "./AdditionalSubscriptionStep";
import { CancellationReasonDetailsStep } from "./CancellationReasonDetailsStep";
import { CancellationSaveOfferStep } from "./CancellationSaveOfferStep";
import { ProcessCancellationStep } from "./ProcessCancellationStep";
import { CancellationFinalizedStep } from "./CancellationFinalizedStep";

export const FORM_VERSION = "8";
export const INITIAL_QUESTION_ID = "cfq1";
export const WHAT_WILL_YOU_USE_NEXT_QUESTION_ID = "cfq2";

export interface CancellationFormModalProps {
  nextBillingDate: string;
  additionalSubscription: AdditionalSubscription | undefined;
  offerSaveCoupon: boolean;
  open: boolean;
  closeModal(): void;
}

function trackEvent(interaction: string, step: string) {
  Amplitude.TRACK_EVENT("Interacted with Dialog", {
    name: "Cancellation Form Modal",
    interaction: interaction,
    step: step,
  });
}

function redirect(route: string) {
  window.location.href = route;
}

// eslint-disable-next-line max-statements
export function CancellationFormModalImpl({
  nextBillingDate,
  additionalSubscription,
  offerSaveCoupon,
  open,
  closeModal,
}: CancellationFormModalProps) {
  const FIRST_STEP = additionalSubscription ? 0 : 1;
  const LAST_STEP = 4;

  const [cancellationProcessed, setCancellationProcessed] = useState(false);
  const [step, setStep] = useState(FIRST_STEP);
  const [offerSave, setOfferSave] = useState(offerSaveCoupon);
  const [askAboutNextSolution, setAskAboutNextSolution] = useState(true);
  const [questions] = useState<CancellationFormQuestionTemplates>(
    cancellationQuestions,
  );
  const [state, dispatch] = useReducer(CancellationFormReducer, []);

  useEffect(() => {
    const stepName =
      step === 0
        ? "Additional subscription notice"
        : "Cancellation details step";
    trackEvent("Opened dialog", stepName);
  }, []);

  useEffect(() => {
    const firstAnswer = state?.find(
      (existingAnswer: CancellationFormAnswer) =>
        existingAnswer.id === INITIAL_QUESTION_ID,
    );

    const shouldAskAboutNextSolution = firstAnswer?.value !== "Business reason";
    const shouldOfferSave = !offerSaveCoupon
      ? false
      : firstAnswer?.value !== "Business reason";

    setAskAboutNextSolution(shouldAskAboutNextSolution);
    setOfferSave(shouldOfferSave);
  }, [state]);

  const answers = state;

  const modalFormSteps: CancellationFormStep[] = [
    {
      name: "Additional subscription notice",
      title: "Notice",
      shouldSkip: !additionalSubscription,
      step: (
        <AdditionalSubscriptionStep
          key={0}
          nextBillingDate={nextBillingDate}
          additionalSubscription={additionalSubscription}
          onNext={onNext}
        />
      ),
    },
    {
      name: "Cancellation details step",
      step: (
        <CancellationReasonDetailsStep
          key={3}
          questions={questions}
          answers={answers}
          onNext={onNext}
          onPrevious={onPrevious}
          addAnswer={addAnswer}
          showPrevious={FIRST_STEP !== 1}
        />
      ),
    },
    {
      name: "Cancellation save offer step",
      title: "Special Final Offer",
      shouldSkip: !offerSave,
      step: (
        <CancellationSaveOfferStep
          key={4}
          onNext={onNext}
          onPrevious={onPrevious}
          onAcceptSaveOffer={onAcceptSaveOffer}
        />
      ),
    },
    {
      name: "Process cancellation step",
      step: (
        <ProcessCancellationStep
          key={5}
          questions={questions}
          answers={answers}
          onFinalizeCancellation={onFinalizeCancellation}
          askAboutNextSolution={askAboutNextSolution}
          onPrevious={onPrevious}
          addAnswer={addAnswer}
        />
      ),
    },
  ];

  function addAnswer(answer: CancellationFormAnswer) {
    const indexOfAnswer = answers.findIndex(
      (existingAnswer: CancellationFormAnswer) =>
        existingAnswer.id === answer.id,
    );

    if (indexOfAnswer > -1) {
      const answeredQuestion = questions.find(
        question => question.id === answer.id,
      ) as CancellationFormQuestion;
      const actionType = ["select", "radio"].includes(answeredQuestion.type)
        ? "replace"
        : "update";

      dispatch({ type: actionType, answer: answer, index: indexOfAnswer });
    } else {
      dispatch({ type: "add", answer: answer });
    }
  }

  return (
    <>
      {!cancellationProcessed ? (
        <Modal
          title={modalFormSteps[step]?.title || "Account Cancellation"}
          open={open}
          onRequestClose={handleClose}
        >
          <Content>{modalFormSteps[step].step}</Content>
        </Modal>
      ) : (
        <Modal
          title="Account Cancellation"
          open={open}
          onRequestClose={handleClose}
        >
          <Content>
            <CancellationFinalizedStep nextBillingDate={nextBillingDate} />
          </Content>
        </Modal>
      )}
    </>
  );

  async function onFinalizeCancellation(
    setButtonLoadingState: (loadingState: boolean) => void,
  ) {
    trackEvent("Clicked to cancel subscription", "Process cancellation step");

    await submitForm(
      additionalSubscription,
      answers,
      offerSave,
      setButtonLoadingState,
      onSubmitSuccess,
      csrfToken,
    );
  }

  async function onAcceptSaveOffer(
    setButtonLoadingState: (loadingState: boolean) => void,
  ) {
    trackEvent("Clicked to accept save offer", "Cancellation save offer step");

    setButtonLoadingState(true);

    const headers = new Headers([
      ["X-CSRF-Token", csrfToken],
      ["Content-type", "application/json"],
    ]);

    const request = new Request("/accounts/accept_cancellation_save_offer", {
      method: "POST",
      credentials: "include",
      headers,
    });

    const response: HttpResponse = await fetch(request);

    if (response.ok) {
      redirect("/promo");
    } else {
      setButtonLoadingState(false);
      response.parsedBody = (await response.json()) as ResponseBody;
      if (response.parsedBody && response.parsedBody.errors) {
        showToast({
          message: `Error submitting cancellation. Please contact support.`,
          variation: "error",
        });
      }
    }
  }

  function onSubmitSuccess() {
    setCancellationProcessed(true);
  }

  function onPrevious() {
    trackEvent("Clicked previous", modalFormSteps[step].name);

    let previousIndex = Math.max(FIRST_STEP, step - 1);

    while (
      modalFormSteps[previousIndex].shouldSkip &&
      previousIndex > FIRST_STEP
    ) {
      previousIndex = previousIndex - 1;
    }

    setStep(previousIndex);
  }

  function onNext() {
    trackEvent("Clicked next", modalFormSteps[step].name);

    let nextIndex = Math.min(LAST_STEP, step + 1);

    while (modalFormSteps[nextIndex].shouldSkip && nextIndex <= LAST_STEP) {
      nextIndex = nextIndex + 1;
    }
    setStep(nextIndex);
  }

  function handleClose() {
    if (cancellationProcessed) {
      redirect("/accounts/billing_info/account_and_billing");
      return;
    }

    const currentStep = modalFormSteps[step];

    trackEvent("Clicked to close dialog", currentStep.name);
    closeModal();
    setStep(FIRST_STEP);
    dispatch({ type: "clear" });

    redirect("/accounts/cancel_subscription?cancellation=abandoned");
  }
}

export function CancellationFormModal(
  props: CancellationFormModalProps & RailsPropsAsContextsProps,
) {
  return (
    <RailsPropsAsContexts {...props}>
      <CancellationFormModalImpl {...props} />
    </RailsPropsAsContexts>
  );
}
