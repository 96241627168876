import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_REVIEW_PATH,
} from "jobber/campaigns/constants";
import { useHandleSend } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useHandleSend";
import { useFormatDateTime } from "jobber/campaigns/views/CampaignsLandingPage/hooks";
import { CampaignReviewErrorState } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions";
import { messages as errorMessages } from "jobber/campaigns/views/CampaignsReviewPage/messages";
import { transformCommsCampaign } from "jobber/campaigns/views/CampaignsReviewPage/hooks/utils";
import { useCommsCampaignReviewQuery } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/useCommsCampaignReviewQuery";
import { useCampaignStatusUpdateMutation } from "jobber/campaigns/hooks/useCampaignStatusUpdateMutation/useCampaignStatusUpdateMutation";
import { CampaignTransitionalStatus } from "~/utilities/API/graphql";
import { useCommsCampaignsExperienceQuery } from "jobber/campaigns/hooks/useCommsCampaignsExperienceQuery";
import { useHandleMarkDemoCampaignSent } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useHandleMarkDemoCampaignSent/useHandleMarkDemoCampaignSent";
import { messages } from "./messages";

export interface SendNowModalProps {
  setCampaignActionsError: (message: string | undefined) => void;
  refreshCampaignList: () => void;
}

// eslint-disable-next-line max-statements
export function SendNowModal({
  setCampaignActionsError,
  refreshCampaignList,
}: SendNowModalProps) {
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  const {
    sendNow: { open, setOpen, campaignId, setCampaignId },
  } = useCampaignWizardContext();

  const {
    campaignData,
    loadingCampaign,
    clientSegmentData,
    loadingClientSegment,
    timeoutError,
  } = useCommsCampaignReviewQuery({
    campaignId: campaignId || "",
    onCampaignNotFound: () => {
      push(CAMPAIGNS_LANDING_PAGE_PATH);
    },
  });
  const { data: commsCampaignsExperience } = useCommsCampaignsExperienceQuery();
  const hasDemoExperience =
    commsCampaignsExperience?.commsCampaignsExperience?.hasDemoExperience;

  const { consentingClientsCount } = transformCommsCampaign({
    campaignData,
    clientSegmentData,
  });

  const { formattedDate, formattedTime } = useFormatDateTime({
    scheduledAt: campaignData?.commsCampaign?.scheduledAt,
  });

  const { campaignStatusUpdate } = useCampaignStatusUpdateMutation();

  const { handleSend } = useHandleSend({
    campaignId: campaignId || "",
    totalCount: consentingClientsCount,
  });
  const { handleMarkDemoCampaignSent } = useHandleMarkDemoCampaignSent(
    campaignId || "",
    campaignData?.commsCampaign?.status,
  );

  const handleSendError = (error: Error) => {
    if (error.message === CampaignReviewErrorState.EMPTY_CLIENT_SEGMENT) {
      setCampaignActionsError(
        formatMessage(errorMessages.emptyClientSegmentErrorBannerText),
      );
    } else {
      setCampaignActionsError(
        formatMessage(errorMessages.campaignSendAuthorizationErrorBannerText),
      );
    }
  };

  // eslint-disable-next-line max-statements
  const onConfirm = async () => {
    if (!campaignId) return;

    if (
      !loadingClientSegment &&
      !timeoutError &&
      consentingClientsCount === 0
    ) {
      setCampaignActionsError(
        formatMessage(errorMessages.emptyClientSegmentErrorBannerText),
      );
      return;
    }

    const isFromEmailAddressValidated =
      campaignData?.commsCampaign?.fromEmailAddress?.isValidatedEmail;

    if (!isFromEmailAddressValidated) {
      await campaignStatusUpdate({
        input: {
          campaignId: campaignId,
          input: {
            status: CampaignTransitionalStatus.DRAFT,
          },
        },
        onCompleted: mutationResult => {
          const userErrors =
            mutationResult?.campaignStatusUpdate.userErrors ?? [];
          if (userErrors.length > 0) {
            setCampaignActionsError(userErrors[0].message);
            return;
          }
        },
        onError: () => {
          setCampaignActionsError(formatMessage(messages.sendNowError));
          return;
        },
      });
      push(generatePath(CAMPAIGNS_REVIEW_PATH, { campaignId }));
      return;
    }

    try {
      if (hasDemoExperience) {
        await handleMarkDemoCampaignSent();
      } else {
        await handleSend();
      }
    } catch (error) {
      handleSendError(error);
    }

    refreshCampaignList();
  };

  const closeModal = () => {
    setOpen(false);
    setCampaignId(null);
  };

  return (
    <div>
      {!loadingCampaign && (
        <ConfirmationModal
          size="small"
          title={formatMessage(messages.sendNowTitle)}
          message={formatMessage(messages.sendNowText, {
            date: formattedDate,
            time: formattedTime,
          })}
          confirmLabel={formatMessage(messages.sendNowButtonLabel)}
          open={open}
          onConfirm={onConfirm}
          onCancel={closeModal}
          onRequestClose={closeModal}
        />
      )}
    </div>
  );
}
