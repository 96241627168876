import React from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { messages } from "jobber/campaigns/views/CampaignsTemplatePage/messages";

export interface ConfirmTemplateSwitchModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  close: () => void;
}
export function ConfirmTemplateSwitchModal({
  isOpen,
  onConfirm,
  onCancel,
  close,
}: ConfirmTemplateSwitchModalProps) {
  const { formatMessage } = useIntl();
  return (
    <Modal
      size={"small"}
      open={isOpen}
      secondaryAction={{ onClick: onCancel, label: "Cancel" }}
      primaryAction={{
        onClick: onConfirm,
        label: formatMessage(messages.templateChangeConfirmationButtonLabel),
      }}
      onRequestClose={close}
      title={formatMessage(messages.templateChangeConfirmationTitle)}
    >
      <Content>
        <Text>{formatMessage(messages.templateChangeConfirmationMessage)}</Text>
      </Content>
    </Modal>
  );
}
