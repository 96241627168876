import { useContext } from "react";
import { UrlsContext, type UrlsContextType } from "./UrlsContext";

type UrlKey = keyof UrlsContextType;

export function useUrls(...urlKeys: UrlKey[]): string[] {
  const urls = useContext(UrlsContext);

  return urlKeys.map(key => {
    const url = urls[key];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return ensureExists(url, key, urls);
    }

    return url as string;
  });
}

function ensureExists(
  url: string | undefined,
  urlKey: string,
  urls: UrlsContextType,
) {
  if (!url) {
    throw new Error(
      `'${urlKey}' is not initialized.
      Ensure UrlsContext.Provider or RailsPropsAsContexts exists in the parent tree and '${urlKey}' is defined;
      all urls: ${JSON.stringify(urls)}
      `,
    );
  }

  return url;
}
