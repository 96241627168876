import React from "react";
import { ContactDetailsHeader } from "jobber/features/MessageCenter/views/Conversation/components/MessageHeader/components/ContactDetailsHeader";
import type { MessageDataEmailMessageFragment } from "~/utilities/API/graphql";

interface EmailContentProps {
  readonly message: MessageDataEmailMessageFragment;
  externalName?: string;
}

export function EmailHeader({ message, externalName }: EmailContentProps) {
  return <ContactDetailsHeader message={message} externalName={externalName} />;
}
