interface IconType {
  icon: "quickbooks" | "sync" | "checkmark";
  animation: string;
  color: string;
}

interface QuickbooksIconDefaultsType {
  default: IconType;
  syncing: IconType;
  checkmark: IconType;
}

export const QuickbooksIconDefaults: QuickbooksIconDefaultsType = {
  default: { icon: "quickbooks", animation: "", color: "" },
  syncing: { icon: "sync", animation: "animate-spin", color: "" },
  checkmark: {
    icon: "checkmark",
    animation: "checkmarkAnimation",
    color: "green",
  },
};
