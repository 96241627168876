import type { useIntl } from "react-intl";
import { messages } from "../messages";

const EMAIL_VALIDATION_PATTERN =
  /^((?:(?:(?:[a-zA-Z0-9\-+_][.\-+_'&]?)*)[a-zA-Z0-9\-+_]))@((?:(?:(?:[a-zA-Z0-9][.\-_]?){0,62})[a-zA-Z0-9])+)\.([a-zA-Z0-9]{2,})$/;

type FormatMessage = ReturnType<typeof useIntl>["formatMessage"];

export function getValidations({
  formatMessage,
}: {
  formatMessage: FormatMessage;
}) {
  return {
    testEmail: {
      validate: (val: string) => {
        if (!val) return formatMessage(messages.emailRequiredMessage);
        if (!EMAIL_VALIDATION_PATTERN.test(val)) {
          return formatMessage(messages.invalidEmailMessage);
        }
      },
    },
  };
}
