import React, { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./ConversationList.module.css";
import type { ConversationListItemModel } from "./ConversationListItem";
import { ConversationListItem, messageType } from "./ConversationListItem";

interface ConversationListProps {
  readonly items: ConversationListItemModel[];
}

export function ConversationList({ items }: ConversationListProps) {
  const lastRequestRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lastRequestRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [items.length]);

  return (
    <AnimatePresence>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <motion.li
            key={keyMap(item)}
            initial={
              index === items.length - 1 || index === 0
                ? { opacity: 1 }
                : { opacity: 0 }
            }
            whileInView={{ opacity: 1 }}
          >
            {isLastRequest(item, index) ? (
              <div ref={lastRequestRef}>
                <ConversationListItem item={item} />
              </div>
            ) : (
              <ConversationListItem item={item} />
            )}
          </motion.li>
        ))}
      </ul>
    </AnimatePresence>
  );

  function isLastRequest(item: ConversationListItemModel, index: number) {
    return (
      item.type === messageType.REQUEST &&
      (index === items.length - 1 || index === items.length - 2)
    );
  }
}

function keyMap(item: ConversationListItemModel) {
  const conversationKeyMap: Record<messageType, string> = {
    [messageType.REQUEST]: "request",
    [messageType.ERROR]: "response",
    [messageType.RESPONSE]: "response",
    [messageType.PARTIAL_RESPONSE]: "response",
    [messageType.LOADING]: "response",
    [messageType.SYSTEM]: "response",
  };

  return `${conversationKeyMap[item.type]}-${item.requestId}`;
}
