import React, { useMemo } from "react";
import { Heading } from "@jobber/components/Heading";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { format } from "date-fns";
import classNames from "classnames";
import styles from "./styles.module.css";
import type { Recommendation } from "./types";
import { RecommendationDetails } from "./RecommendationDetails";

interface RecommendationButtonProps {
  heading: string;
  icon: IconNames;
  recommendation?: Recommendation;
  isSelected: boolean;
  onSelectRecommendation: () => void;
  disabled: boolean;
}

export function RecommendationButton({
  heading,
  disabled,
  icon,
  isSelected,
  recommendation,
  onSelectRecommendation,
}: RecommendationButtonProps) {
  const ariaLabel = useMemo(
    () =>
      recommendation?.recommendedStartAt && recommendation?.recommendedEndAt
        ? `${heading} for ${format(
            recommendation.recommendedStartAt,
            "EEE MMM d",
          )} from ${format(
            recommendation.recommendedStartAt,
            "hh:mm aa",
          )} to ${format(
            recommendation.recommendedEndAt,
            "hh:mm aa",
          )}. This is a ${
            recommendation.driveTime
          } minute drive and is assigned to ${
            recommendation.recommendedAssignee?.name
          }`
        : "No available options were found",
    [recommendation, heading],
  );

  return (
    <label
      className={classNames([
        styles.recommendationButtonContainer,
        isSelected && styles.recommendationButtonSelected,
      ])}
      aria-label={ariaLabel}
    >
      <input
        type="radio"
        name="recommendation"
        onClick={onSelectRecommendation}
        className={styles.recommendationInput}
        disabled={disabled}
      />
      <div className={styles.cardContent}>
        <div className={styles.title}>
          <Heading level={4}>{heading}</Heading>
          <Icon name={icon} color="lightBlue" size="base" />
        </div>
        <RecommendationDetails recommendation={recommendation} />
      </div>
    </label>
  );
}
