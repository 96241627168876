import { defineMessages } from "react-intl";

export const messages = defineMessages({
  companyAddressModalDescription: {
    id: "campaigns.companyAddressModal.description",
    defaultMessage:
      "You are required by law to include your company address in the footer of all marketing emails. This can be a physical address or a P.O. box.",
    description: "Reasoning for why we need a company address",
  },

  companyAddresModalTitle: {
    id: "campaigns.companyAddressModal.title",
    defaultMessage: "We need your company address",
    description: "Title for the company address modal",
  },
});
