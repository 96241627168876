import React from "react";
import type { ColumnDef } from "@jobber/components/DataTable";
import type { GetValueType } from "~/jobber/features/Reporting/components/Report/types";
import {
  ComparableHeader,
  CurrencyFooter,
  InnerColumnCellFactory,
  NumberFooter,
  StandardFooter,
} from "~/jobber/features/Reporting/components/Report/components/ReportCells/ReportCells";
import { QuoteSummarySortKey } from "~/utilities/API/graphqlFranchiseManagement";
import { INNER_CELL_WIDTH_LARGE } from "~/jobber/features/Reporting/components/Report/constants";
import {
  CONVERSION_RATE_HEADER,
  CONVERTED_AVERAGE_HEADER,
  CONVERTED_COUNT_HEADER,
  CONVERTED_TOTAL_HEADER,
  NAME_COLUMN_FOOTER,
  NAME_COLUMN_HEADER,
  SENT_AVERAGE_HEADER,
  SENT_COUNT_HEADER,
  SENT_TOTAL_HEADER,
} from "./constants";
import type { QuoteSummaryData, QuoteSummaryTotalsData } from "./types";

export const getColumnConfig = (
  totalsData?: QuoteSummaryTotalsData,
): ColumnDef<QuoteSummaryData, GetValueType>[] => {
  return [
    {
      id: QuoteSummarySortKey.FRANCHISE_NAME,
      accessorKey: "name",
      header: NAME_COLUMN_HEADER,
      footer: () => <StandardFooter text={NAME_COLUMN_FOOTER} />,
      minSize: INNER_CELL_WIDTH_LARGE,
      cell: InnerColumnCellFactory("text"),
    },
    {
      id: QuoteSummarySortKey.SENT_COUNT,
      accessorKey: "quotesSentCount",
      header: () => <ComparableHeader text={SENT_COUNT_HEADER} />,
      cell: InnerColumnCellFactory("number"),
      footer: () => <NumberFooter number={totalsData?.quotesSentCount} />,
    },
    {
      id: QuoteSummarySortKey.SENT_TOTAL,
      accessorKey: "quotesSentTotal",
      header: () => <ComparableHeader text={SENT_TOTAL_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.quotesSentTotal} />
      ),
    },
    {
      id: QuoteSummarySortKey.SENT_AVERAGE,
      accessorKey: "quoteSentAverage",
      header: () => <ComparableHeader text={SENT_AVERAGE_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.quoteSentAverage} />
      ),
    },
    {
      id: QuoteSummarySortKey.CONVERTED_COUNT,
      accessorKey: "quotesConvertedCount",
      header: () => <ComparableHeader text={CONVERTED_COUNT_HEADER} />,
      cell: InnerColumnCellFactory("number"),
      footer: () => <NumberFooter number={totalsData?.quotesConvertedCount} />,
    },
    {
      id: QuoteSummarySortKey.CONVERTED_TOTAL,
      accessorKey: "quotesConvertedTotal",
      header: () => <ComparableHeader text={CONVERTED_TOTAL_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.quotesConvertedTotal} />
      ),
    },
    {
      id: QuoteSummarySortKey.CONVERTED_AVERAGE,
      accessorKey: "quoteConvertedAverage",
      header: () => <ComparableHeader text={CONVERTED_AVERAGE_HEADER} />,
      cell: InnerColumnCellFactory("currency"),
      footer: () => (
        <CurrencyFooter currencyAmount={totalsData?.quoteConvertedAverage} />
      ),
    },
    {
      id: QuoteSummarySortKey.CONVERSION_RATE,
      accessorKey: "quoteConversionRate",
      header: () => <ComparableHeader text={CONVERSION_RATE_HEADER} />,
      cell: InnerColumnCellFactory("number"),
      footer: () => (
        <NumberFooter number={totalsData?.quoteConversionRateAverage} />
      ),
    },
  ];
};
