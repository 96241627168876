import { gql } from "@apollo/client";
import { CLIENT_SEGMENT } from "../../graphql";

export const UPDATE_ALL_CLIENTS_SEGMENT_CAMPAIGN = gql`
  mutation updateAllClientsSegmentCampaign(
    $input: AllClientsUpdateSegmentInput!
  ) {
    campaignSegmentUpdateAllClients(input: $input) {
      allClientsSegment {
        ...clientSegment
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CLIENT_SEGMENT}
`;
