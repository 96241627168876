import type { ButtonProps } from "@jobber/components";
import { Button } from "@jobber/components";
import classNames from "classnames";
import styles from "./TextActionButton.module.css";

export function TextActionButton(props: ButtonProps) {
  return (
    <div
      className={classNames(styles.textAction, {
        [styles.disabled]: props.disabled,
      })}
    >
      <Button type="tertiary" {...props} />
    </div>
  );
}
