import React from "react";
import classnames from "classnames";
import styles from "./FilterSearchConversations.module.css";

interface FilterSearchConversationsProps {
  /**
   * Styles the text bold and uppercased
   * @default false
   */
  readonly loud?: boolean;

  /**
   * Text to display.
   */
  readonly text: string;

  /**
   * Click handler.
   */
  onClick?(): void;
}

export function FilterSearchConversations({
  loud = false,
  text,
  onClick,
}: FilterSearchConversationsProps) {
  const className = classnames(styles.filterSearchConversations, {
    [styles.bold]: loud,
  });

  return (
    <div className={className} onClick={onClick} role={"none"}>
      {text}
    </div>
  );
}
