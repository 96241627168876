import { BankAccountProgress, type SuccessBank } from "./bankAccountReducer";
import { HostedOnboardingProgress } from "./verifyReducer";

export interface Progress {
  hostedOnboardingProgress: HostedOnboardingProgress;
  bankAccountProgress: BankAccountProgress;
}

interface SignupReducerStartPollingAction {
  type: "startPolling";
}

interface SignupReducerUpdateProgressAction {
  type: "updateProgress";
  hostedOnboardingProgress: HostedOnboardingProgress;
  bankAccountProgress: BankAccountProgress;
}

interface SignupReduceUpdateBankAction {
  type: "updateBank";
  bankAccountProgress: BankAccountProgress;
  bank?: SuccessBank;
}

type SignupReducerAction =
  | SignupReducerStartPollingAction
  | SignupReducerUpdateProgressAction
  | SignupReduceUpdateBankAction;

interface ManagedAccountState {
  showSettings: boolean;
  chargesEnabled: boolean;
  isInPaymentsOneClickOnboarding: boolean;
  hostedOnboardingProgress: HostedOnboardingProgress;
  bankAccountProgress: BankAccountProgress;
  bankDisplay?: SuccessBank;
  newSignUpSettingsEnabled: boolean;
}

const INIT_STATE: ManagedAccountState = {
  showSettings: false,
  chargesEnabled: false,
  isInPaymentsOneClickOnboarding: false,
  hostedOnboardingProgress: HostedOnboardingProgress.Uninitiated,
  bankAccountProgress: BankAccountProgress.NotConnected,
  bankDisplay: undefined,
  newSignUpSettingsEnabled: false,
};

export function init(initParams: {
  bankDetails: SuccessBank;
  hostedOnboardingProgress: HostedOnboardingProgress;
  bankAccountProgress: BankAccountProgress;
}): ManagedAccountState {
  const state = managedAccountReducer(INIT_STATE, {
    type: "updateProgress",
    ...initParams,
  });

  return managedAccountReducer(state, {
    ...state,
    type: "updateBank",
    bank: initParams.bankDetails,
  });
}

export function managedAccountReducer(
  prevState: ManagedAccountState,
  action: SignupReducerAction,
): ManagedAccountState {
  switch (action.type) {
    case "updateProgress":
      return derivedStates({
        ...prevState,
        ...action,
      });
    case "updateBank":
      return derivedStates({
        ...prevState,
        ...action,
        bankDisplay: action.bank,
      });
    default:
      return prevState;
  }
}

function derivedStates(state: ManagedAccountState): ManagedAccountState {
  const { hostedOnboardingProgress, bankAccountProgress } = state;

  const showSettings =
    hostedOnboardingProgress === HostedOnboardingProgress.Verified &&
    bankAccountProgress === BankAccountProgress.Connected;

  let newSignUpSettingsEnabled = false;

  if (state.showSettings === false && showSettings === true) {
    newSignUpSettingsEnabled = true;
  }

  return {
    ...state,
    showSettings,
    newSignUpSettingsEnabled,
  };
}
