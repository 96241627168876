import React from "react";
import { groupBy } from "lodash";
import { strFormatDate } from "@jobber/components/FormatDate";
import { Heading } from "@jobber/components/Heading";
import {
  type MessageDataFragment,
  MessageStatusEnum,
  MessageTypeEnum,
} from "~/utilities/API/graphql";
import { MessageContainer } from "jobber/features/MessageCenter/views/Conversation/components/MessageContainer";
import styles from "./ChatBubble.module.css";

export interface ChatBubbleRendererProps {
  readonly items: MessageDataFragment[];
  readonly externalName?: string;
}

const MESSAGE_GROUP_DURATION_MINUTES = 5;

interface DateGroup {
  [key: string]: MessageDataFragment[];
}
interface DateAndTimeGroup {
  [key: string]: MessageDataFragment[][];
}

export function ChatBubbleRenderer({
  items,
  externalName,
}: ChatBubbleRendererProps) {
  return (
    <>
      {items === undefined || items === null || items.length === 0 ? (
        <></>
      ) : (
        renderItems(items, externalName)
      )}
    </>
  );
}

function renderItems(items: MessageDataFragment[], externalName?: string) {
  const dateGroups = groupByDate(items);
  const timeGroups: DateAndTimeGroup = groupByTime(dateGroups);
  return Object.keys(timeGroups).map((date, index) => {
    return (
      <>
        {renderDateGroup(timeGroups[date], externalName)}
        <div key={index} className={styles.timestampHeaderContainer}>
          <Heading level={6}>{date}</Heading>
        </div>
      </>
    );
  });
}

function renderDateGroup(
  items: MessageDataFragment[][],
  externalName?: string,
) {
  return items.map(timeGrouping => {
    return timeGrouping.map((message, index) => {
      const nextMessage = timeGrouping[index + 1];
      const showInternalName =
        nextMessage?.internalContactObject?.id !==
        message.internalContactObject?.id;
      return (
        <MessageContainer
          key={index}
          status={getStatusLabel(message)}
          fullMessage={message}
          externalName={externalName}
          showTimeStamp={index === 0 && !isSystemMessage(message)}
          showInternalName={showInternalName}
        />
      );
    });
  });
}

function groupByDate(items: MessageDataFragment[]) {
  return groupBy(items, item => {
    const date = new Date(item.timestamp);
    return strFormatDate(date);
  });
}

function groupByTime(dateGroups: DateGroup) {
  const timeGroupObject: DateAndTimeGroup = {};
  Object.keys(dateGroups).forEach(date => {
    const messagesOfDay = dateGroups[date];
    const timeGroup = createTimeGroups(messagesOfDay);
    timeGroupObject[date] = timeGroup;
  });
  return timeGroupObject;
}

function createTimeGroups(messages: MessageDataFragment[]) {
  const timeGroups = [];
  let previousMessageTime = new Date(messages[0].timestamp);
  for (const [index, message] of messages.entries()) {
    if (index === 0) {
      timeGroups.push([message]);
      continue;
    }

    const expiryTime = subtractMinutes(previousMessageTime);
    if (new Date(message.timestamp) <= expiryTime) {
      timeGroups.push([message]);
    } else {
      timeGroups[timeGroups.length - 1].push(message);
    }
    previousMessageTime = new Date(message.timestamp);
  }
  return timeGroups;
}

function getStatusLabel(message: MessageDataFragment) {
  switch (message.status) {
    case MessageStatusEnum.FAILED:
      return "failed";
    case MessageStatusEnum.QUEUED:
      return "queued";
    default:
      return "sent";
  }
}

function subtractMinutes(date: Date) {
  const messageGroupDurationMilliseconds =
    MESSAGE_GROUP_DURATION_MINUTES * 60000;
  return new Date(date.getTime() - messageGroupDurationMilliseconds);
}

function isSystemMessage(message: MessageDataFragment) {
  return message.commType === MessageTypeEnum.CONVERSATION_REASSIGNED;
}
