export function handleCloseRailsDialog() {
  // Rails/React shim. This modal is being loaded by a Rails
  // so we need to close both the Rails modal
  // Must be removed when we use React Modals
  const railsClose = document.querySelector(
    ".js-closeDialog",
  ) as HTMLImageElement;
  if (railsClose) {
    railsClose.click();
  }
}
