import type { Theme } from "@jobber/components";
import { messages } from "./messages";

interface Location {
  latitude: number;
  longitude: number;
}

export const darkMapStyles = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [{ color: "#b2b2b2" }],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
];

export class MapView {
  circle: google.maps.Circle;
  instance: google.maps.Map;
  geocoder: google.maps.Geocoder;
  marker: google.maps.Marker;
  mounted: boolean;
  onChange: (address: string, location: Location) => void;
  theme: Theme;

  constructor(
    container: HTMLDivElement,
    center: google.maps.LatLngLiteral,
    radius: number,
    onChange: (address: string, location: Location) => void,
    theme: Theme = "light",
    geocoder = new google.maps.Geocoder(),
    zoom = 10,
  ) {
    this.mounted = false;
    this.onChange = onChange;
    this.geocoder = geocoder;
    this.theme = theme;
    this.instance = new google.maps.Map(container, {
      center,
      zoom,
      streetViewControl: false,
      ...(theme === "dark" ? { styles: darkMapStyles } : {}),
    });

    this.marker = new google.maps.Marker({
      position: center,
      map: this.instance,
      draggable: true,
      title: messages.onHoverMarkerHint.defaultMessage,
    });

    this.circle = new google.maps.Circle({
      strokeColor: "#F13337",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#F13337",
      fillOpacity: 0.3,
      map: this.instance,
      draggable: true,
      center,
      radius,
    });
  }

  mount = () => {
    if (!this.mounted) {
      this.marker.bindTo("position", this.circle, "center");
      this.marker.addListener("dragend", this.onDragEnd);
      this.circle.addListener("dragend", this.onDragEnd);
      this.zoomToFit();
      this.mounted = true;
    }

    return this;
  };

  onDragEnd = async () => {
    const position = this.marker.getPosition();

    if (position) {
      const address = await this.reverseGeocode(position);

      this.onChange(address.formatted, {
        latitude: address.location.lat(),
        longitude: address.location.lng(),
      });

      return position;
    }
  };

  reverseGeocode = async (location: google.maps.LatLng) => {
    const { results } = await this.geocoder.geocode({ location });

    if (results[0]) {
      return {
        formatted: results[0].formatted_address,
        location: results[0].geometry.location,
      };
    }

    return {
      formatted: location.toString(),
      location,
    };
  };

  setCenter = (center: google.maps.LatLngLiteral) => {
    this.circle.setCenter(center);
    this.instance.panTo(center);

    return center;
  };

  setCircleRadius = (radius: number) => {
    this.circle.setRadius(radius);
    this.zoomToFit();

    return radius;
  };

  zoomToFit = () => {
    const bounds = this.circle.getBounds();
    if (bounds) {
      this.instance.fitBounds(bounds, 2);
    }

    return bounds;
  };
}
