import React from "react";
import { useQuery } from "@apollo/client";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { PaymentOptionsCardQuery } from "~/utilities/API/graphql";
import { PAYMENT_OPTIONS_CARD_QUERY } from "./PaymentSettingsQuery.graphql";
import { PaymentOptionsCard } from "./PaymentOptionsCard";

interface PaymentOptionsCardLoaderProps {
  toggleAchSwitchFlag: boolean;
  setUpdateDefaultPreference: React.Dispatch<
    React.SetStateAction<{
      preference: string;
      achEnabled: boolean;
    }>
  >;
}

function InternalPaymentOptionsCardLoader({
  toggleAchSwitchFlag,
  setUpdateDefaultPreference,
}: PaymentOptionsCardLoaderProps) {
  const { loading, data } = useQuery<PaymentOptionsCardQuery>(
    PAYMENT_OPTIONS_CARD_QUERY,
  );

  if (loading || !data) {
    return <></>;
  }

  return (
    <PaymentOptionsCard
      data={data}
      toggleSwitch={toggleAchSwitchFlag}
      setUpdateDefaultPreference={setUpdateDefaultPreference}
    />
  );
}

export function PaymentOptionsCardLoader({
  toggleAchSwitchFlag,
  setUpdateDefaultPreference,
}: PaymentOptionsCardLoaderProps) {
  return (
    <APIProvider>
      <InternalPaymentOptionsCardLoader
        toggleAchSwitchFlag={toggleAchSwitchFlag}
        setUpdateDefaultPreference={setUpdateDefaultPreference}
      />
    </APIProvider>
  );
}
