import userEvent from "@testing-library/user-event";
import { screen, within } from "@testing-library/react";
import type { MessageDescriptor } from "react-intl";
import { createIntl } from "utilities/testHelpers/createIntl";
import type { BillingCycleName } from "~/utilities/API/graphql";
import { messages as checkoutPurchaseDisclaimerMessages } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/CheckoutPurchaseDisclaimer/messages";
import { messages as billingMessages } from "~/shared/billing/pricePreview/components/BillingDetails/messages";
import { messages as addonBillingDetailsMessages } from "~/shared/billing/pricePreview/components/AddonBillingDetails/messages";
import { formatPrice } from "~/shared/billing/pricePreview/utils";
import { messages as creditMessages } from "~/shared/billing/pricePreview/components/CreditInfo/messages";
import { messages as upsellAddonMessages } from "~/jobber/billing/components/UpsellAddon/messages";
import { messages as purchaseButtonMessages } from "./components/CheckoutSummary/components/PurchaseButton/messages";
import { messages as subscriptionAddonCardsMessages } from "./components/SubscriptionAddonCards/messages";
import { messages as checkoutMessages } from "./messages";
import { getDiscountHeader } from "./utils";

const { formatMessage } = createIntl();

function getButton(messageDescriptor: MessageDescriptor) {
  return screen.getByText(formatMessage(messageDescriptor))
    .parentElement as HTMLButtonElement;
}

export function creditLabel() {
  return screen.getByText(formatMessage(creditMessages.creditLabelMessage));
}

export function addonCards() {
  return screen.queryByTestId("addon-cards");
}

export function addonsHeader() {
  return screen.getAllByText(
    formatMessage(subscriptionAddonCardsMessages.addons),
  );
}

export function addonSummary(addonName: string) {
  return screen.queryByTestId(
    `addon-billing-details-${addonName.toLowerCase()}`,
  );
}

export function addAddonButton(addonName: string) {
  return screen.getByLabelText(
    formatMessage(subscriptionAddonCardsMessages.addToPlanAriaLabel, {
      addonName,
    }),
  );
}

export function removeAddonButton(addonName: string) {
  return screen.getByLabelText(
    formatMessage(subscriptionAddonCardsMessages.removeAriaLabel, {
      addonName,
    }),
  );
}

export function addonDiscountAmount(discountAmount: number) {
  return screen.queryByText(
    formatMessage(addonBillingDetailsMessages.discountAmount, {
      discountAmount: formatPrice(discountAmount),
    }),
  );
}

export function addonDiscountHeader() {
  return screen.queryAllByText(
    formatMessage(addonBillingDetailsMessages.discountHeader),
  );
}

export function addonPurchaseDisclaimer() {
  return screen.queryByTestId("addon-purchase-disclaimer");
}

export function addonCredit() {
  return screen.queryByTestId("addon-billing-details-credit");
}

export function addonRecommendedLabel() {
  return screen.queryByText(
    formatMessage(subscriptionAddonCardsMessages.recommended),
  );
}

export function addonMoreAddonsLabel() {
  return screen.queryByText(
    formatMessage(subscriptionAddonCardsMessages.moreAddOns),
  );
}

export function upsellAddonMesssage() {
  return screen.queryByText(formatMessage(upsellAddonMessages.upgradeMessage));
}

export function upsellAddonButton() {
  return screen.getByLabelText(
    formatMessage(upsellAddonMessages.addAddonAriaLabel),
  );
}

export function taxDisclaimer() {
  return screen.getByText(
    formatMessage(checkoutPurchaseDisclaimerMessages.taxDisclaimer),
  );
}

export function renewalDisclaimer() {
  return screen.getByText(
    formatMessage(checkoutPurchaseDisclaimerMessages.renewalDisclaimer),
  );
}

export function collapsedMobilePurchaseTotal(purchaseTotal: string) {
  return within(
    screen.getByTestId("collapsed-mobile-purchase-total"),
  ).getByText(purchaseTotal);
}

export function discountDurationHeader(
  selectedBillingCycle: BillingCycleName,
  discountType: string,
  discountAmount: number,
  remainingDiscountedFullMonths: number,
) {
  const discountMessage = getDiscountHeader(
    formatMessage,
    selectedBillingCycle,
    discountType,
    discountAmount,
    remainingDiscountedFullMonths,
  );
  return screen.getByText(discountMessage!);
}

export function monthlyRadio() {
  return screen.getByRole("radio", {
    name: formatMessage(billingMessages.billedMonthly),
  });
}

export function confirmSubscriptionButton() {
  return getButton(checkoutMessages.confirmSubscription);
}

export function yourCurrentPlanButton() {
  return getButton(purchaseButtonMessages.yourCurrentPlan);
}

export function resubscribeButton() {
  return getButton(purchaseButtonMessages.resubscribe);
}

export function expandMobileSummaryButton() {
  return screen.getByText("View details");
}

// Interactions
export function iSelectAddon(addonName: string) {
  userEvent.click(addAddonButton(addonName));
}

export function iDeselectAddon(addonName: string) {
  userEvent.click(removeAddonButton(addonName));
}

export function iSelectMonthly() {
  userEvent.click(monthlyRadio());
}

export function iClickConfirmSubscription() {
  userEvent.click(confirmSubscriptionButton());
}

export function iExpandMobileSummary() {
  userEvent.click(expandMobileSummaryButton());
}

export function iSelectUpsoldAddon() {
  userEvent.click(upsellAddonButton());
}
