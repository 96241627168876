import React from "react";
import {
  ClientNotificationId,
  type NotificationUpdateAttributes,
} from "~/utilities/API/graphql";
import type { OnChange } from "./types";
import { useArrivalWindowDialogStateMachine } from "./useArrivalWindowDialogStateMachine";
import {
  ArrivalWindowDialog,
  type DialogConfirm,
} from "../ArrivalWindowDialog";
import {
  usePersistArrivalWindowOnboarding,
  useVisitReminderTemplates,
} from "../hooks";
import { ArrivalWindowExperiment } from "../ArrivalWindowExperiment";

export interface ArrivalWindowDialogControllerProps {
  jobStartTime?: Date;
  initialDuration: number;
  showOnboarding: boolean;
  centered: boolean;
  onClose(props: DialogConfirm): void;
}

export function ArrivalWindowDialogController({
  onClose,
  initialDuration,
  showOnboarding,
  jobStartTime,
  centered,
}: ArrivalWindowDialogControllerProps): JSX.Element {
  const { section, loading } = useVisitReminderTemplates(showOnboarding);
  const {
    currentOnboardingStep,
    goBackToEditArrivalWindowsStyle,
    dismissAllModals,
    toggleCentered,
    toggleApplyToFuture,
    updateDuration,
    showReminderPreviews,
    state,
  } = useArrivalWindowDialogStateMachine({
    defaultDuration: initialDuration,
    centered,
    applyToFuture: true,
  });
  const { update } = usePersistArrivalWindowOnboarding(state.applyToFuture);

  const onSaveNotifications = async (params: {
    id: ClientNotificationId;
    data: NotificationUpdateAttributes;
  }): Promise<{ errors: string[]; data: unknown }> => {
    if (!showOnboarding) {
      onClose({
        action: "confirm",
        duration: state.defaultDuration,
        centered: state.centered,
      });

      return {
        errors: [],
        data: undefined,
      };
    }

    try {
      const result = await update({
        centered: state.centered,
        durationInMinutes: state.applyToFuture
          ? state.defaultDuration
          : (null as unknown as undefined),
        templates: params.data.templates,
        applyToAllJobs: state.applyToFuture,
      });

      const errors = (result.errors?.map(d => d.message) || [])
        .concat(
          result.data?.arrivalWindowsSettingsEdit.userErrors.map(
            d => d.message,
          ) || [],
        )
        .concat(result.data?.updateNotification.errors || []);

      if (!errors.length) {
        onClose({
          action: "confirm",
          duration: state.defaultDuration,
          centered: state.centered,
        });
      }

      return {
        errors,
        data: result.data,
      };
    } catch (err) {
      // Network errors would throw and need to be caught here
      return {
        errors: ["Unexpected error occurred"],
        data: undefined,
      };
    }
  };

  const onHandleChange = async (action: OnChange) => {
    switch (action.action) {
      case "showReminderPreviews":
        showReminderPreviews();
        break;
      case "changeDuration":
        updateDuration(action.duration);
        break;
      case "toggleCentered":
        toggleCentered();
        break;
      case "toggleApplyToFuture":
        toggleApplyToFuture();
        break;
      case "goBackToEditArrivalWindowsStyle":
        goBackToEditArrivalWindowsStyle();
        break;
      case "dismissAllModals": {
        onClose({ action: "cancel" });
        dismissAllModals();
        break;
      }
      case "saveArrivalWindowOnboarding":
        // TODO: handle error state
        return onSaveNotifications({
          id: ClientNotificationId.VISIT_REMINDER,
          data: action.updatedTemplates,
        });
    }
  };

  return (
    <>
      <ArrivalWindowDialog
        loading={loading}
        open={currentOnboardingStep === "editArrivalWindowsStyle"}
        jobStartTime={jobStartTime}
        showOnboarding={showOnboarding}
        formState={state}
        section={section}
        onChange={onHandleChange}
      ></ArrivalWindowDialog>
      {showOnboarding && section && (
        <ArrivalWindowExperiment
          open={currentOnboardingStep === "showReminderPreviews"}
          notification={section.notification}
          scheduleTemplate={section.descriptions.scheduleTemplate}
          showOnboarding={showOnboarding}
          onChange={onHandleChange}
          section={section}
        />
      )}
    </>
  );
}
