import { defineMessages } from "react-intl";

export const messages = defineMessages({
  year: {
    id: "billingDetails.summary.year",
    defaultMessage: "year",
    description: "Year",
  },
  month: {
    id: "billingDetails.summary.month",
    defaultMessage: "month",
    description: "Month",
  },
  billedYearly: {
    id: "billingDetails.summary.billedYearly",
    defaultMessage: "Billed yearly",
    description: "Billed yearly",
  },
  billedMonthly: {
    id: "billingDetails.summary.billedMonthly",
    defaultMessage: "Billed monthly",
    description: "Billed monthly",
  },
  allPricesUSD: {
    id: "billingDetails.summary.allPricesUSD",
    defaultMessage: "All prices are in $USD",
    description: "All prices are in USD",
  },
  savingsMessage: {
    id: "billingDetails.summary.savingsMessage",
    defaultMessage: "Save {amount}/year",
    description: "Savings message",
  },
});
