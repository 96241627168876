import React from "react";
import { Banner } from "@jobber/components/Banner";
import styles from "./TextRewriteErrorBanner.module.css";

interface TextRewriteErrorBannerProps {
  onDismiss: () => void;
  errorMessage: string;
}

export const TextRewriteErrorBanner = ({
  errorMessage,
  onDismiss,
}: TextRewriteErrorBannerProps) => {
  return (
    <div className={styles.errorBannerContainer}>
      <Banner dismissible type="error" onDismiss={onDismiss}>
        {errorMessage}
      </Banner>
    </div>
  );
};
