export const messages = {
  firstLogin: {
    headerPartOne: "Run your business anywhere",
    headerPartTwo: "with the Jobber app",
    imageAlt: "Jobber app on a phone",
    sendToAppLabel: "Get the app",
    dismissLabel: "Continue in the browser",
  },
  persistentState: {
    header: "Get more done in the app",
    sendToAppLabel: "Use app",
  },
};
