import { InputText } from "@jobber/components/InputText";
import { Option, Select } from "@jobber/components/Select";
import React, { useRef, useState } from "react";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { Popover, Text } from "@jobber/components";
import {
  trackAccountNumberHelpClick,
  trackAccountOwnerNameHelpClick,
  trackRoutingNumberHelpClick,
} from "~/bunker/payments_react/clientHubJobberPayments/components/CheckoutContainer/amplitude/amplitudeHelper";
import type {
  AccountType,
  BankAccountData,
} from "jobber/AddPaymentMethod/AddBankAccount/types";
import bankingNumbersHelp from "@images/bankingNumbersHelp.png";
import { messages } from "./messages";

interface ManualEntryBankAccountFieldsProps {
  formData: BankAccountData;
  handleInputChange: (
    name: string,
  ) => (newValue: string | number | boolean) => void;
  handleSelectChange: (value: AccountType) => void;
  attachedToType?: "invoice" | "quote";
  handleOnFocus?: () => void;
}

// eslint-disable-next-line max-statements
export function ManualEntryBankAccountFields({
  formData,
  handleInputChange,
  handleSelectChange,
  attachedToType,
  handleOnFocus,
}: ManualEntryBankAccountFieldsProps) {
  const { formatMessage } = useIntl();
  const routingNumberRef = useRef<HTMLDivElement>(null);
  const accountNumberRef = useRef<HTMLDivElement>(null);
  const accountOwnerNameRef = useRef<HTMLDivElement>(null);
  const [showRoutingNumberPopover, setShowRoutingNumberPopover] =
    useState(false);
  const [showAccountNumberPopover, setShowAccountNumberPopover] =
    useState(false);
  const [showAccountOwnerNamePopover, setShowAccountOwnerNamePopover] =
    useState(false);

  const confirmAccountNumbers = (value: string) => {
    if (value === formData.accountNumber) {
      return true;
    }
    return formatMessage(messages.accountNumbersDoNotMatch);
  };

  return (
    <>
      <Popover
        attachTo={routingNumberRef}
        open={showRoutingNumberPopover}
        onRequestClose={() => setShowRoutingNumberPopover(false)}
        preferredPlacement={"left"}
      >
        <AccountAndRoutingNumberPopoverContent />
      </Popover>
      <Popover
        attachTo={accountNumberRef}
        open={showAccountNumberPopover}
        onRequestClose={() => setShowAccountNumberPopover(false)}
        preferredPlacement={"left"}
      >
        <AccountAndRoutingNumberPopoverContent />
      </Popover>
      <Popover
        attachTo={accountOwnerNameRef}
        open={showAccountOwnerNamePopover}
        onRequestClose={() => setShowAccountOwnerNamePopover(false)}
        preferredPlacement={"left"}
      >
        <Content>
          <Text>{formatMessage(messages.accountOwnerNameHelpText)}</Text>
        </Content>
      </Popover>
      <Select
        placeholder={formatMessage(messages.placeholderAccountType)}
        name={"accountType"}
        value={formData.accountType}
        onChange={handleSelectChange}
      >
        <Option value="individual">
          {formatMessage(messages.achAccountTypePersonal)}
        </Option>
        <Option value="company">
          {formatMessage(messages.achAccountTypeBusiness)}
        </Option>
      </Select>

      <div ref={routingNumberRef}>
        <InputText
          keyboard="numeric"
          name="routingNumber"
          placeholder={formatMessage(messages.placeholderRoutingNumber)}
          value={formData.routingNumber}
          onChange={handleInputChange("routingNumber")}
          validations={{
            required: {
              value: true,
              message: formatMessage(messages.validationRoutingNumberRequired),
            },
            pattern: {
              value: /^[0-9]+$/,
              message: formatMessage(messages.validationPatternNumbersOnly),
            },
            maxLength: {
              value: 9,
              message: formatMessage(messages.routingNumberMaxLengthError),
            },
            validate: {
              checkRoutingNumber: validateRoutingNumber,
            },
          }}
          suffix={{
            icon: "help",
            ariaLabel: formatMessage(messages.routingNumberAriaLabel),
            onClick: onRoutingNumberHelpClick,
          }}
          onFocus={handleOnFocus}
        />
      </div>

      <div ref={accountNumberRef}>
        <InputText
          keyboard="numeric"
          name="accountNumber"
          placeholder={formatMessage(messages.placeholderAccountNumber)}
          value={formData.accountNumber}
          onChange={handleInputChange("accountNumber")}
          validations={{
            required: {
              value: true,
              message: formatMessage(messages.validationAccountNumberRequired),
            },
            pattern: {
              value: /^[0-9]+$/,
              message: formatMessage(messages.validationPatternNumbersOnly),
            },
            minLength: {
              value: 5,
              message: formatMessage(messages.validationAccountNumberMinLength),
            },
            maxLength: {
              value: 17,
              message: formatMessage(messages.validationAccountNumberMaxLength),
            },
          }}
          suffix={{
            icon: "help",
            ariaLabel: formatMessage(messages.accountNumberAriaLabel),
            onClick: onAccountNumberHelpClick,
          }}
          onFocus={handleOnFocus}
        />
      </div>

      <InputText
        keyboard="numeric"
        name="confirmAccountNumber"
        placeholder={formatMessage(messages.placeholderConfirmAccountNumber)}
        value={formData.confirmAccountNumber}
        onChange={handleInputChange("confirmAccountNumber")}
        validations={{
          required: {
            value: true,
            message: formatMessage(
              messages.validationConfirmAccountNumberRequired,
            ),
          },
          validate: {
            confirmAccountNumbers: confirmAccountNumbers,
          },
        }}
        onFocus={handleOnFocus}
      />

      <div ref={accountOwnerNameRef}>
        <InputText
          name="accountOwnerName"
          placeholder={formatMessage(messages.placeholderAccountOwnerName)}
          value={formData.accountOwnerName}
          onChange={handleInputChange("accountOwnerName")}
          validations={{
            required: {
              value: true,
              message: formatMessage(messages.accountOwnerNameRequiredError),
            },
            minLength: {
              value: 2,
              message: formatMessage(messages.accountOwnerNameMinLengthError),
            },
            maxLength: {
              value: 50,
              message: formatMessage(messages.accountOwnerNameMaxLengthError),
            },
          }}
          suffix={{
            icon: "help",
            ariaLabel: formatMessage(messages.accountOwnerNameAriaLabel),
            onClick: onAccountOwnerNameHelpClick,
          }}
          onFocus={handleOnFocus}
        />
      </div>
    </>
  );

  function validateRoutingNumber(value: string) {
    const weights = [3, 7, 1, 3, 7, 1, 3, 7];
    const total = Array.from(value.substring(0, 8)).reduce(
      (acc, digit, index) => acc + parseInt(digit, 10) * weights[index],
      0,
    );

    const checkDigit = (10 - (total % 10)) % 10;

    if (checkDigit !== parseInt(value[8], 10)) {
      return formatMessage(messages.validationRoutingNumberPattern);
    }

    return true;
  }

  function togglePopover(
    currentPopover: boolean,
    setPopover: (value: boolean) => void,
  ) {
    if (currentPopover) {
      setPopover(false);
      return;
    }

    closeAllPopovers();
    setPopover(true);
  }

  function onRoutingNumberHelpClick() {
    togglePopover(showRoutingNumberPopover, setShowRoutingNumberPopover);
    void trackRoutingNumberHelpClick(attachedToType ?? "invoice");
  }

  function onAccountNumberHelpClick() {
    togglePopover(showAccountNumberPopover, setShowAccountNumberPopover);
    void trackAccountNumberHelpClick(attachedToType ?? "invoice");
  }

  function onAccountOwnerNameHelpClick() {
    togglePopover(showAccountOwnerNamePopover, setShowAccountOwnerNamePopover);
    void trackAccountOwnerNameHelpClick(attachedToType ?? "invoice");
  }

  function closeAllPopovers() {
    setShowAccountNumberPopover(false);
    setShowRoutingNumberPopover(false);
    setShowAccountOwnerNamePopover(false);
  }
}

function AccountAndRoutingNumberPopoverContent() {
  const { formatMessage } = useIntl();
  return (
    <Content>
      <Text>{formatMessage(messages.accountHelpText)}</Text>
      <img
        alt={formatMessage(messages.altTextBankingNumbers)}
        src={bankingNumbersHelp}
        width="80%"
      />
    </Content>
  );
}
