import { useContext, useMemo } from "react";
import { AddressConfigurationContext } from "./AddressConfigurationContext";
import type {
  AddressValues,
  CountryRecords,
  StateOrProvinceRecords,
} from "./types";
import { provinces } from "./provinces";
import { messages } from "./messages";

export function useCountriesAndProvinces(
  currentCountry?: AddressValues["country"],
) {
  const { availableCountries, availableProvinces } = useContext(
    AddressConfigurationContext,
  );
  const selectedCountry =
    currentCountry ?? Object.values(availableCountries)[0];

  const selectedCountryCode = useMemo(() => {
    for (const [countryCode, countryName] of Object.entries(
      availableCountries,
    )) {
      if (selectedCountry === countryName) {
        return countryCode;
      }
    }
  }, [availableCountries, selectedCountry]);

  return {
    country: selectedCountry,
    countries: selectedCountryCode
      ? Object.values(availableCountries)
      : [selectedCountry, ...Object.values(availableCountries)],
    provinces: getProvinces(
      availableProvinces ?? provinces,
      selectedCountryCode,
    ),
    provincesPlaceholder: getProvincesLabel(selectedCountryCode),
    postalCodePlaceholder: getPostalCodeLabel(selectedCountryCode),
    postalCodeValidationPattern: getPostalCodeValidation(selectedCountryCode),
  };
}

function getProvinces(
  availableProvinces: StateOrProvinceRecords,
  countryCode?: keyof CountryRecords,
) {
  if (!countryCode) return [];
  return availableProvinces[countryCode] ?? [];
}

function getProvincesLabel(countryCode?: keyof CountryRecords) {
  switch (countryCode) {
    case "CA":
      return messages.provinceLabelProvince;
    case "US":
    case "AU":
      return messages.provinceLabelState;
    case "UK":
    case "IE":
      return messages.provinceLabelCounty;
    case "NZ":
      return messages.provinceLabelRegion;
    default:
      return messages.provinceLabelDefault;
  }
}

function getPostalCodeLabel(countryCode?: keyof CountryRecords) {
  switch (countryCode) {
    case "CA":
      return messages.postalCodeLabelPostalCode;
    case "US":
      return messages.postalCodeLabelZipCode;
    case "AU":
    case "UK":
    case "NZ":
      return messages.postalCodeLabelPostCode;
    case "IE":
      return messages.postalCodeLabelEirCode;
    default:
      return messages.postalCodeLabelDefault;
  }
}

function getPostalCodeValidation(countryCode?: keyof CountryRecords) {
  switch (countryCode) {
    case "CA":
      return {
        value: /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/i,
        message: messages.postalCodeInvalidCA.message,
      };
    case "US":
      return {
        value: /^\d{5}(?:-\d{4})?$/,
        message: messages.postalCodeInvalidUS.message,
      };
    default:
      return undefined;
  }
}
