import classNames from "classnames";
import React, { type ChangeEvent } from "react";

export function AmountInput(props: {
  amount: string;
  currencyUnit: string;
  disabled?: boolean;
  errorMessage?: string;
  onChange(amount: string): void;
  onBlur(amount: string): void;
}) {
  const { amount, currencyUnit, errorMessage, onChange, onBlur, disabled } =
    props;

  const className = classNames({
    /* eslint-disable @typescript-eslint/naming-convention */
    "is-disabled": disabled,
    "is-invalid": errorMessage,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  return (
    <div>
      {errorMessage && <div className="fieldError">{errorMessage}</div>}

      <div className="fieldAffix">
        <span className="fieldAffix-item">{currencyUnit}</span>
        <placeholder-field label="Amount">
          <input
            className={className}
            type="text"
            value={amount}
            onChange={onInputChange}
            onBlur={onInputBlur}
            disabled={disabled}
            inputMode="decimal"
          />
        </placeholder-field>
      </div>
    </div>
  );

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  function onInputBlur() {
    onBlur(amount);
  }
}
