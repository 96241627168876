export enum EfficientSchedulingType {
  None = "none",
  BufferTime = "bufferTime",
  DriveTime = "driveTime",
}

interface EfficientSchedulingInitialState {
  useBufferTime: boolean;
  useDriveTime: boolean;
}

interface EfficientSchedulingState extends EfficientSchedulingInitialState {
  EfficientSchedulingSetting: EfficientSchedulingType;
}

interface EfficientSchedulingAction {
  newSetting: EfficientSchedulingType;
}

export function EfficientSchedulingReducer(
  state: EfficientSchedulingState,
  action: EfficientSchedulingAction,
): EfficientSchedulingState {
  const newState = {
    ...state,
    EfficientSchedulingSetting: action.newSetting,
  };

  switch (action.newSetting) {
    case EfficientSchedulingType.None:
      newState.useBufferTime = false;
      newState.useDriveTime = false;
      break;
    case EfficientSchedulingType.BufferTime:
      newState.useBufferTime = true;
      newState.useDriveTime = false;
      break;
    case EfficientSchedulingType.DriveTime:
      newState.useBufferTime = false;
      newState.useDriveTime = true;
      break;
  }

  return newState;
}

export function EfficientSchedulingInitialize(
  initialState: EfficientSchedulingInitialState,
): EfficientSchedulingState {
  const EfficientSchedulingSetting = ((useBufferTime, useDriveTime) => {
    if (useBufferTime) {
      return EfficientSchedulingType.BufferTime;
    } else if (useDriveTime) {
      return EfficientSchedulingType.DriveTime;
    } else {
      return EfficientSchedulingType.None;
    }
  })(initialState.useBufferTime, initialState.useDriveTime);

  return {
    EfficientSchedulingSetting,
    ...initialState,
  };
}
