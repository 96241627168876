import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React, { useContext } from "react";
import { Banner } from "@jobber/components/Banner";
import { messages } from "jobber/setupWizard/messages";
import { SetupWizardContext } from "jobber/setupWizard/context";
import styles from "./FormColumn.module.css";

interface FormColumnProps {
  heading: string | React.ReactNode;
  copy?: string;
  form: React.ReactNode;
}

export function FormColumn({ heading, copy, form }: FormColumnProps) {
  const { formatMessage } = useIntl();
  const { error } = useContext(SetupWizardContext);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Heading level={1}>{heading}</Heading>
      </div>
      {copy && (
        <div className={styles.copy}>
          <Text>{copy}</Text>
        </div>
      )}
      {error && (
        <Banner type="error" dismissible={false}>
          {formatMessage(messages.serverErrorCopy)}
        </Banner>
      )}
      <div className={styles.form}>{form}</div>
    </div>
  );
}
