import { Box, Icon, useAtlantisTheme } from "@jobber/components";
import React from "react";
import type { ProductOrServiceCustomFieldFragment } from "~/utilities/API/graphql";
import {
  isCustomFieldLink,
  isCustomFieldNumeric,
  isCustomFieldText,
} from "~/jobber/customFields/util";
import styles from "../CustomFields.module.css";

export const CustomFieldRenderer: React.FC<{
  field: ProductOrServiceCustomFieldFragment;
}> = ({ field }) => {
  if (isCustomFieldText(field)) {
    return <CustomFieldBox>{field.valueText}</CustomFieldBox>;
  }

  if (isCustomFieldLink(field)) {
    return (
      <div className={styles.linkContainer}>
        <a
          href={field.valueLink.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.plainLink}
        >
          {field.valueLink.text || "Visit Link"}
          {"  "}
          <Icon name="export" size="small" color="green" />
        </a>
      </div>
    );
  }

  if (isCustomFieldNumeric(field)) {
    return <CustomFieldBox>{field.valueNumeric}</CustomFieldBox>;
  }

  return <span>Unsupported field type</span>;
};

function CustomFieldBox({ children }: { children: React.ReactNode }) {
  const { theme } = useAtlantisTheme();

  const lightModeColors = {
    background: "base-grey--200",
    borderColor: "border",
  } as const;

  const darkModeColors = {
    background: "disabled--secondary",
    borderColor: "disabled",
  } as const;

  const boxColors = theme === "light" ? lightModeColors : darkModeColors;
  return (
    <Box as="span" padding="small" border="base" radius="base" {...boxColors}>
      {children}
    </Box>
  );
}
