import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationLabel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.configurationLabel",
    defaultMessage: "Efficient scheduling",
    description: "Label and Modal title for the configuration option",
  },
  noRestrictionsTitle: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.noRestrictionsTitle",
    defaultMessage: "No time restrictions between appointments",
    description: "Title for the no restrictions option",
  },
  noRestrictionsOption: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.noRestrictionsOption",
    defaultMessage: "No time restrictions",
    description: "Option for no restrictions",
  },
  bufferTimeTitle: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.bufferTimeTitle",
    defaultMessage:
      "{duration} minutes **fixed buffer time** between appointments",
    description: "Title for the buffer time option",
  },
  bufferTimeOption: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.bufferTimeOption",
    defaultMessage: "Set a fixed buffer time",
    description: "Option for buffer time",
  },
  bufferTimeDescription: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.bufferTimeDescription",
    defaultMessage:
      "Prevent back-to-back appointments by adding a buffer time between appointments. This does not take into account the client's location.",
    description: "Description for the buffer time option",
  },
  driveTimeTitle: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeTitle",
    defaultMessage:
      "{duration} minutes **maximum drive time** between appointments",
    description: "Title for the drive time option",
  },
  driveTimeOption: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeOption",
    defaultMessage: "Set a maximum drive time",
    description: "Option for drive time",
  },
  driveTimeDescription: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeDescription",
    defaultMessage:
      "Only accept appointments that are within a set drive time of any pre-existing bookings that day.",
    description: "Description for the drive time option",
  },
  driveTimeLearnMore: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeLearnMore",
    defaultMessage: "Learn how drive times are calculated",
    description: "Learn more link for the drive time option",
  },
  driveTimeFieldLabel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeFieldLabel",
    defaultMessage:
      "Only show appointments that are within a {input} drive from other appointments.",
    description: "Label for the drive time field",
  },
  driveTimeUnitLabel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeUnitLabel",
    defaultMessage: "min",
    description: "Label for the drive time unit",
  },
  driveTimeChipLabel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.driveTimeChipLabel",
    defaultMessage: "{duration} min",
    description: "Label for the drive time chips",
  },
  modalSave: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.modalSave",
    defaultMessage: "Save",
    description: "Save button text",
  },
  modalCancel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.modalCancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  saveMessage: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.saveMessage",
    defaultMessage: "Updated efficient scheduling",
    description: "Message displayed when settings are saved",
  },
  errorMessage: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Message displayed when an error occurs",
  },
  modalGroupLabel: {
    id: "selfServeBookings.EfficientSchedulingConfiguration.modalGroupLabel",
    defaultMessage:
      "Select how you would like to set your time between appointments",
    description: "Aria label for the radio group",
  },
});
