import { defineMessages } from "react-intl";

const prefix = "setupWizard.profilePage.";

export const messages = defineMessages({
  profilePageDefaultHeader: {
    id: `${prefix}profilePageDefaultHeader`,
    defaultMessage: "Your <span>free trial</span> is now active",
    description: "Default page header for the profile page",
  },
  profilePageCopy: {
    id: `${prefix}profilePageCopy`,
    defaultMessage:
      "We’ll use your name and number to set up your account and ensure you get support when you need it.",
    description: "Default page copy for the profile page",
  },
  profilePageDefaultStatsCopy1: {
    id: `${prefix}profilePageDefaultStatsCopy1`,
    defaultMessage: "Powering",
    description: "Default stats copy one for the setup wizard",
  },
  profilePageDefaultStatsCopy2: {
    id: `${prefix}profilePageDefaultStatsCopy2`,
    defaultMessage: "200,000+",
    description: "Default stats copy two for the setup wizard",
  },
  profilePageDefaultStatsCopy3: {
    id: `${prefix}profilePageDefaultStatsCopy3`,
    defaultMessage: "Home Service Pros",
    description: "Default stats copy three for the setup wizard",
  },
  serviceProviderCollageAltText: {
    id: `${prefix}serviceProviderCollageAltText`,
    defaultMessage: "service provider collage",
    description: "Service provider collage alt text for the setup wizard",
  },
});
