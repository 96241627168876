import React from "react";
import { InputNumber } from "@jobber/components/InputNumber";
import { Content } from "@jobber/components/Content";
import { Sg1DatePicker } from "jobber/payments_react/Sg1DatePicker";
import type {
  DurationPeriod,
  ScheduleEnd,
} from "jobber/workOrders/components/Scheduler/types";
import styles from "./ScheduleEndInput.module.css";
import { ScheduleEndPeriodDropdown } from "./ScheduleEndPeriodDropdown";
import { EndTypeSelection } from "./EndTypeSelection";

type ScheduleEndType = ScheduleEnd["type"];

export const ScheduleEndInput = (props: {
  ends: ScheduleEnd;
  disabled: boolean;
  onChange(ends: ScheduleEnd): void;
}) => {
  const onChangeType = (type: ScheduleEndType) =>
    props.onChange({
      type,
      date: props.ends.date,
      durationPeriod: props.ends.durationPeriod,
      numOfPeriods: props.ends.numOfPeriods,
    });

  const onChangeEndDate = (date: Date) =>
    props.onChange({
      type: "date",
      date,
      durationPeriod: props.ends.durationPeriod,
      numOfPeriods: props.ends.numOfPeriods,
    });

  const onChangePeriodOptions = (durationPeriod: DurationPeriod) =>
    props.ends.type === "duration" &&
    props.onChange({
      ...props.ends,
      durationPeriod,
    });

  const onChangePeriodNumber = (numOfPeriods: number) =>
    props.ends.type === "duration" &&
    props.onChange({
      ...props.ends,
      numOfPeriods,
    });

  return (
    <>
      <Content spacing="small">
        <EndTypeSelection
          selected={props.ends.type}
          onChange={onChangeType}
          disabled={props.disabled}
        />
      </Content>
      <Content>
        {props.ends.type === "date" ? (
          <div className={styles.datePicker}>
            <Sg1DatePicker
              disabled={props.disabled}
              date={props.ends.date}
              placeholder="End date"
              onChange={onChangeEndDate}
              data-testid="job-scheduler-ends-on-date"
            />
          </div>
        ) : (
          <div className={styles.inputWrapper}>
            <InputNumber
              name="schedule-end-interval"
              disabled={props.disabled}
              value={props.ends.numOfPeriods}
              onChange={onChangePeriodNumber}
            />
            <ScheduleEndPeriodDropdown
              disabled={props.disabled}
              endPeriodOption={props.ends.durationPeriod}
              onChange={onChangePeriodOptions}
            />
          </div>
        )}
      </Content>
    </>
  );
};
