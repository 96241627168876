/* eslint-disable import/no-internal-modules */
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import type { OnlineBookingServiceAreaFragment } from "~/utilities/API/graphql";
import { useLinkedAddressLocation } from "../../hooks/useLinkedAddressLocation";
import { useRadiusUnits } from "../../hooks/useRadiusUnits";
import { messages } from "../../messages";
import { CompanyLocationContext } from "../../../CompanyLocationContext/CompanyLocationContext";

interface ServiceAreaPreviewTextProps {
  serviceArea: OnlineBookingServiceAreaFragment;
}
export function ServiceAreaPreviewText({
  serviceArea,
}: ServiceAreaPreviewTextProps) {
  return (
    <PreviewTextInternal
      key={`${serviceArea.radius}-${serviceArea.name}-${serviceArea.center.latitude}-${serviceArea.center.longitude}`}
      kmRadius={serviceArea.radius}
      name={serviceArea.name}
      center={serviceArea.center}
    />
  );
}

function PreviewTextInternal({
  kmRadius,
  name,
  center,
}: {
  kmRadius: number;
  name?: string;
  center?: OnlineBookingServiceAreaFragment["center"];
}) {
  const { formatMessage } = useIntl();
  const { countryCode } = useContext(CompanyLocationContext);
  const { address } = useLinkedAddressLocation({
    address: name,
    location: center,
  });
  const { radius, unitLabel } = useRadiusUnits(countryCode, kmRadius);

  return (
    <Text>
      {formatMessage(messages.serviceAreaRadiusPreview, {
        radius,
        unit: formatMessage(unitLabel),
        address,
      })}
    </Text>
  );
}
