import type {
  ProductOrServiceCustomFieldCustomFieldNumericFragment,
  ProductOrServiceCustomFieldFragment,
} from "~/utilities/API/graphql";

export function isCustomFieldNumeric(
  customField: ProductOrServiceCustomFieldFragment,
): customField is ProductOrServiceCustomFieldCustomFieldNumericFragment {
  return (
    (customField as ProductOrServiceCustomFieldCustomFieldNumericFragment)
      .valueNumeric !== undefined
  );
}
