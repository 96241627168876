import React from "react";
import { Typography } from "@jobber/components";
import { useIntl } from "react-intl";
import type { RuleDropdownProps } from "./RuleDropdown";
import { RuleDropdown } from "./RuleDropdown";
import { RuleCombobox } from "./RuleCombobox";
import { messages } from "./messages";

export enum FieldType {
  DROPDOWN = 1,
  MODAL = 2,
  COMBOBOX = 3,
}

export interface RuleExpressionProps {
  text: string;
  fields: {
    [key: string]: {
      fieldType: FieldType;
      argument: string | number | boolean | undefined;
      options?: RuleDropdownProps["options"];
      readOnly?: boolean;
      setArgument?: (val: string | number | boolean) => void;
    };
  };
  hasSubmitted?: boolean;
}

export function RuleExpression({
  text,
  fields,
  hasSubmitted,
}: RuleExpressionProps) {
  const { formatMessage } = useIntl();
  // Split the given text into parts based on variables wrapped in curly braces
  const expression = text.split(/({.*?})/).map((part, index) => {
    // Grab the field from the given variable name
    const fieldName = part.slice(1, -1);
    const field = fields[fieldName];
    // If the part is a variable, render the appropriate field component in its place
    if (part.startsWith("{") && part.endsWith("}") && !!field) {
      switch (field.fieldType) {
        case FieldType.DROPDOWN:
          return (
            <RuleDropdown
              key={index}
              value={field.argument}
              setValue={field.setArgument}
              options={field.options ?? []}
              readOnly={field.readOnly}
            />
          );
        case FieldType.COMBOBOX:
          return (
            <RuleCombobox
              defaultValue={
                fieldName === "tag"
                  ? formatMessage(messages.clientTagDefaultValue)
                  : formatMessage(messages.lineItemDefaultValue)
              }
              key={index}
              value={field.argument}
              setValue={field.setArgument}
              options={field.options ?? []}
              readOnly={field.readOnly}
              hasSubmitted={hasSubmitted}
              subjectNoun={
                fieldName === "tag"
                  ? formatMessage(messages.clientTagSubjectNoun)
                  : formatMessage(messages.lineItemSubjectNoun)
              }
            />
          );
      }
    } else {
      // Otherwise, just render the part as-is
      return part;
    }
  });

  return (
    <Typography element="span" size="base">
      {expression}
    </Typography>
  );
}
