import type {
  ReportColumn,
  ReportColumns,
} from "~/jobber/features/Reporting/components/Report/types";
import type { VisibilityConfigType } from "~/jobber/features/Reporting/components/Report/components/ReportColumnSelect/types";
import { useColumnSelection } from "./useColumnSelection";
import { useFormatColumns } from "./useFormatColumns";
import { isReportColumn } from "./utils/isReportColumn";

interface useReportColumnsArgs<T> {
  columns: ReportColumns<T>;
  uniqueReportKey?: string;
}
export function useReportColumns<T>({
  columns,
  uniqueReportKey,
}: useReportColumnsArgs<T>) {
  const { formatColumnsForDataTable } = useFormatColumns();

  const availableColumns = columns.filter(column => {
    // TODO JOB-70613: Remove the check for isReportColumn<T>(column)
    return isReportColumn<T>(column) ? column.available : true;
  });

  // TODO JOB-70613: Remove the conditional calling for the useColumnSelect
  // We can only enabled selected columns if the types are ReportColumnDef<T>
  // so we have to call this hook conditionally to stop columns from being saved to local storage
  const { columnSelectProps } = isReportColumn<T>(availableColumns[0])
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumnSelection<T>({
        columns: availableColumns as ReportColumn<T>[],
        key: uniqueReportKey,
        updateVisibleColumns,
      })
    : { columnSelectProps: undefined };

  const dataTableColumns = formatColumnsForDataTable<T>(availableColumns);

  return { dataTableColumns, columnSelectProps };
}

function updateVisibleColumns<T>(
  columns: ReportColumns<T>,
  selectedColumns: VisibilityConfigType[],
) {
  // TODO JOB-70613: Remove the check for isReportColumn<T>(column)
  if (!isReportColumn<T>(columns[0])) return columns;

  // Updates the visible attribute for the column in main column list, according to the visibility in the selectedColumns
  columns.forEach((column: ReportColumn<T>) => {
    // find the column
    const storedColumn = selectedColumns.find(
      selectedColumn => column.id === selectedColumn.id,
    );

    // If we can't find the column that we had stored the selection data for, ignore it
    if (!storedColumn) return;
    column.visible = storedColumn.checked;
  });
}
