import { useCallback } from "react";
import {
  useCampaignCreateUpcomingClientsMutation,
  useUpdateUpcomingClientsSegmentCampaign,
} from "jobber/campaigns/hooks";
import { isUpcomingClientCriteriaInput } from "jobber/campaigns/utils/segmentCriteriaUtils";
import type {
  UpdateClientSegmentArgs,
  UseUpdateClientSegmentHelperArgs,
} from "../../types";

export function useUpdateUpcomingClientSegment({
  clientSegment,
  defaultTemplate,
}: UseUpdateClientSegmentHelperArgs) {
  const { campaignCreateUpcomingClients } =
    useCampaignCreateUpcomingClientsMutation();
  const { updateUpcomingClientsSegmentCampaign } =
    useUpdateUpcomingClientsSegmentCampaign();

  const updateUpcomingClientsSegment = useCallback(
    async ({
      newSegmentCriteria,
      onSuccess,
      onError,
    }: UpdateClientSegmentArgs) => {
      if (clientSegment && isUpcomingClientCriteriaInput(newSegmentCriteria)) {
        const result = await updateUpcomingClientsSegmentCampaign({
          input: {
            id: clientSegment.id,
            criteria: newSegmentCriteria,
          },
        });
        if (result.userErrors.length) {
          onError(result.userErrors[0].message);
        } else if (result.clientSegment) {
          onSuccess({ clientSegment: result.clientSegment });
        }
      } else {
        if (
          !defaultTemplate.body ||
          !defaultTemplate.subject ||
          !defaultTemplate.header
        ) {
          onError("Template is missing required fields");
        }
        if (isUpcomingClientCriteriaInput(newSegmentCriteria)) {
          const result = await campaignCreateUpcomingClients({
            input: {
              criteria: newSegmentCriteria,
              template: defaultTemplate,
            },
          });
          if (result.userErrors.length) {
            onError(result.userErrors[0].message);
          } else if (result.clientSegment) {
            onSuccess({
              clientSegment: result.clientSegment,
              campaignId: result.campaign?.id,
            });
          }
        }
      }
    },
    [
      clientSegment,
      campaignCreateUpcomingClients,
      defaultTemplate,
      updateUpcomingClientsSegmentCampaign,
    ],
  );
  return updateUpcomingClientsSegment;
}
