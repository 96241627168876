import React from "react";
import classNames from "classnames";
import {
  Content,
  InputNumber,
  InputValidation,
  Text,
} from "@jobber/components";
import { BaseReadOnlyCustomField } from "./BaseReadCustomField";
import styles from "./CustomFieldTypes.module.css";
import type { NumberValue } from "../types";

interface CustomFieldEditProps {
  value: NumberValue;
  editing: boolean;
  onChange(newValue: NumberValue): void;
}

interface CustomFieldNumberProps {
  isInAppGroup?: boolean;
  label: string;
  stringValue: string;
  editProps?: CustomFieldEditProps;
  error?: string;
}

export function CustomFieldNumber({
  isInAppGroup = false,
  label,
  stringValue,
  editProps,
  error,
}: CustomFieldNumberProps) {
  if (editProps?.editing) {
    return (
      <div className={classNames([styles.container])} title="container">
        <div
          className={classNames({
            [styles.label]: true,
            [styles.labelEdit]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div
          className={classNames([styles.value, styles.valueEdit])}
          title="value"
        >
          <Content spacing={"minuscule"}>
            <InputNumber
              size="small"
              value={editProps.value.number}
              onChange={onNumberChange}
              inline={true}
              invalid={!!error}
            />
            <span className={styles.unit}>{editProps.value.unit}</span>
            {error && <InputValidation message={error} />}
          </Content>
        </div>
      </div>
    );
  } else {
    return BaseReadOnlyCustomField(isInAppGroup, label, stringValue);
  }

  function onNumberChange(newNumber: number) {
    editProps?.onChange({
      number: newNumber,
      unit: editProps.value.unit,
    });
  }
}
