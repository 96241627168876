import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { FormatFile } from "@jobber/components/FormatFile";
import { Heading } from "@jobber/components/Heading";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { Option, Select } from "@jobber/components/Select";
import { Text } from "@jobber/components/Text";
import React, { type ChangeEvent, useRef, useState } from "react";
import { partial } from "filesize";
import { EvidenceFile } from "~/utilities/API/graphql";
import type { DisputeFile } from "jobber/features/PaymentDisputes/types";
import styles from "./DisputeFileUpload.module.css";

export const MaxCombinedFileSize = 4718592;
const filesize = partial({ base: 2, standard: "jedec", exponent: 2 });

const categories = new Map([
  [EvidenceFile.CUSTOMER_COMMUNICATION, "Client communication"],
  [EvidenceFile.CUSTOMER_SIGNATURE, "Client signature"],
  [EvidenceFile.RECEIPT, "Receipt"],
  [EvidenceFile.REFUND_POLICY, "Refund policy"],
  [EvidenceFile.SERVICE_DOCUMENTATION, "Proof of service"],
  [EvidenceFile.UNCATEGORIZED, "Other evidence"],
]);

export interface DisputeFileUploadProps {
  onDisputeFilesChange: (
    disputeFiles: DisputeFile[],
    fileLimitReached: boolean,
  ) => void;
  isCategoriesSetAsInvalid: boolean;
}

export function DisputeFileUpload({
  onDisputeFilesChange,
  isCategoriesSetAsInvalid,
}: DisputeFileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<DisputeFile[]>([]);
  const [totalFileSize, setTotalFileSize] = useState<number>(0);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filesInput = e.target.files;
    let newInputFilesTotalSize = 0;
    if (filesInput) {
      const files: DisputeFile[] = Array.from(filesInput).map(file => {
        newInputFilesTotalSize += file.size;
        return {
          file,
        };
      });
      const newSelectedFiles = [...selectedFiles, ...files];
      setSelectedFiles(newSelectedFiles);
      setTotalFileSize(totalFileSize + newInputFilesTotalSize);
      onDisputeFilesChange(
        newSelectedFiles,
        totalFileSize + newInputFilesTotalSize > MaxCombinedFileSize,
      );
    }
  };

  const removeFileHandler = (file: File, index: number) => {
    const filteredSelectedFiles = [...selectedFiles];
    filteredSelectedFiles.splice(index, 1);
    setSelectedFiles(filteredSelectedFiles);
    setTotalFileSize(totalFileSize - file.size);
    onDisputeFilesChange(
      filteredSelectedFiles,
      totalFileSize - file.size > MaxCombinedFileSize,
    );
  };

  const updateCategory = (newCategory: EvidenceFile, fileIndex: number) => {
    const updatedSelectedFiles = selectedFiles?.map((file, index) => {
      if (fileIndex === index) {
        file.category = newCategory;
      }
      return file;
    });
    setSelectedFiles(updatedSelectedFiles);
    onDisputeFilesChange(selectedFiles, totalFileSize > MaxCombinedFileSize);
  };

  const getProgressBarLabel = (): string => {
    const label = `You've attached ${filesize(
      totalFileSize,
    )} out of the ${filesize(MaxCombinedFileSize)} limit`;
    return totalFileSize > MaxCombinedFileSize
      ? `${label}. Please remove files to meet the combined size limit.`
      : label;
  };

  const renderFiles = () => {
    return selectedFiles?.map((selectedFile, index) => (
      <Content key={`${selectedFile.file.name}-${index}`} spacing="small">
        <FormatFile
          key={selectedFile.file.name}
          file={{
            key: selectedFile.file.name,
            type: "file",
            name: selectedFile.file.name,
            size: selectedFile.file.size,
            progress: 1,
            src(): Promise<string> {
              return Promise.resolve("");
            },
          }}
          onDelete={() => removeFileHandler(selectedFile.file, index)}
        />
        <Select
          key={selectedFiles.length}
          placeholder="Category"
          defaultValue={
            selectedFile.category ? selectedFile.category : "none_selected"
          }
          onChange={(newCategory: EvidenceFile) =>
            updateCategory(newCategory, index)
          }
          data-testid="categorySelect"
          invalid={isCategoriesSetAsInvalid}
        >
          <Option value="none_selected" disabled>
            None selected
          </Option>
          {Array.from(categories.keys()).map(category => (
            <Option key={category} value={category}>
              {categories.get(category)}
            </Option>
          ))}
        </Select>
      </Content>
    ));
  };

  return (
    <Content>
      <div className={styles.buttonContainer}>
        <input
          key={selectedFiles.length}
          className={styles.inputButton}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          accept=".pdf,.png,.jpg,.jpeg"
          multiple
          data-testid="fileInput"
        />
        <Button
          label="Select Files"
          onClick={() => inputRef.current?.click()}
          type="secondary"
          size="large"
        />
      </div>
      <div>
        <Text
          size="small"
          variation={totalFileSize > MaxCombinedFileSize ? "error" : "default"}
        >
          {getProgressBarLabel()}
        </Text>
        <ProgressBar
          currentStep={totalFileSize}
          totalSteps={MaxCombinedFileSize}
          size="smaller"
        />
      </div>
      {selectedFiles?.length ? (
        <Heading level={4}>Assign category for each uploaded file</Heading>
      ) : (
        ""
      )}
      {renderFiles()}
    </Content>
  );
}
