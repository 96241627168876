import React from "react";
import { Chip, Emphasis, InlineLabel } from "@jobber/components";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import styles from "./CustomFieldChip.module.css";

export function CustomFieldChip({
  app,
  name,
  onClick,
  value,
}: CustomFieldChipProps) {
  const { formatMessage } = useIntl();
  return (
    <Chip
      ariaLabel={`${name}: ${value}`}
      role={"button"}
      tabIndex={0}
      variation={"subtle"}
      onClick={(_: undefined, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <Chip.Prefix>
        <img
          alt={formatMessage(messages.appLogoAltText, {
            appLogoAltText: app.name,
          })}
          className={styles.customFieldChipAppLogo}
          src={app.logoUrl}
        />
        <span className={styles.customFieldChipLabel}>
          <InlineLabel>
            <Emphasis variation="bold">{name}</Emphasis>: {value}
          </InlineLabel>
        </span>
      </Chip.Prefix>
    </Chip>
  );
}

/**
 * Represents an app object with a name and logo URL.
 */
export interface App {
  /**
   * The name of the application.
   * @type {string}
   */
  name: string;

  /**
   * The URL of the application's logo.
   * @type {string}
   */
  logoUrl: string;
}

/**
 * Represents the props for the CustomFieldChip component.
 */
interface CustomFieldChipProps {
  /**
   * App object containing the name and logoUrl.
   * @type {App}
   */
  readonly app: App;

  /**
   * The custom field name to be displayed on the chip.
   * @type {string}
   */
  name: string;

  /**
   * The custom field value to be displayed on the chip.
   * @type {string}
   */
  value: string;

  /**
   * Callback function to be called when the chip is clicked.
   * @type {() => void}
   */
  onClick: () => void;
}
