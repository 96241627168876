import { gql } from "@apollo/client";

export const CompanyScheduleFragment = gql`
  fragment companyScheduleWeekFields on CompanySchedule {
    id
    enabled
    days {
      friday {
        ...companyScheduleFields
      }
      monday {
        ...companyScheduleFields
      }
      saturday {
        ...companyScheduleFields
      }
      sunday {
        ...companyScheduleFields
      }
      thursday {
        ...companyScheduleFields
      }
      tuesday {
        ...companyScheduleFields
      }
      wednesday {
        ...companyScheduleFields
      }
    }
  }

  fragment companyScheduleFields on CompanyScheduleDay {
    enabled
    times
  }
`;
