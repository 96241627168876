import { defineMessages } from "react-intl";

export const messages = defineMessages({
  firstNamePlaceholder: {
    id: "paymentDetailsEdit.firstNamePlaceholder",
    defaultMessage: "First name",
    description: "Label text for first name field",
  },
  lastNamePlaceholder: {
    id: "paymentDetailsEdit.lastNamePlaceholder",
    defaultMessage: "Last name",
    description: "Label text for last name field",
  },
  cardNumberPlaceholder: {
    id: "paymentDetailsEdit.cardNumberPlaceholder",
    defaultMessage: "Card number",
    description: "Label text for card number field",
  },
});
