import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import styles from "./MarketplaceLayout.module.css";

export interface MarketplaceLayoutProps {
  children: React.ReactNode;
  publicFacing: boolean;
}

export function MarketplaceLayout({
  children,
  publicFacing,
}: MarketplaceLayoutProps) {
  return (
    <div className={styles.marketplaceLayout}>
      <Content>
        {publicFacing && <div className={styles.headerPadding} />}
        <Heading level={1}>App Marketplace</Heading>
        <div className={styles.layoutContent}>
          <Text size="large">
            <Emphasis variation="bold">
              {
                "Integrate Jobber with your favorite tools and take your business "
              }
              <Emphasis variation="highlight">{"to the next level!"}</Emphasis>
            </Emphasis>
          </Text>
        </div>
        <Content>{children}</Content>
      </Content>
    </div>
  );
}
