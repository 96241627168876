import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "connectToGrowStartOfTrialModal.heading",
    defaultMessage: "Your 14-day Grow plan trial starts now",
    description: "Start trial modal heading",
  },
  subheading: {
    id: "connectToGrowStartOfTrialModal.subheading",
    defaultMessage: "Explore {planName} features",
    description: "Subheading text",
  },
  subheadingEmphasis: {
    id: "connectToGrowStartOfTrialModal.subheadingEmphasis",
    defaultMessage: "Grow plan",
    description: "Subheading emphasis text",
  },
  descriptionL1: {
    id: "connectToGrowStartOfTrialModal.descriptionL1",
    defaultMessage: "You now have access to the Grow plan for 14 days.",
    description: "Start trial modal description line 1",
  },
  descriptionL2: {
    id: "connectToGrowStartOfTrialModal.descriptionL2",
    defaultMessage:
      "Once the trial ends, your original subscription plan will automatically resume.",
    description: "Start trial modal description line 2",
  },
  descriptionL3: {
    id: "connectToGrowStartOfTrialModal.descriptionL3",
    defaultMessage: "None of your existing work will be lost.",
    description: "Start trial modal description line 3",
  },
  availability: {
    id: "connectToGrowStartOfTrialModal.availability",
    defaultMessage: "{feature} is now available, as well as:",
    description: "Availability text",
  },
  optionalLineItems: {
    id: "connectToGrowStartOfTrialModal.optionalLineItems",
    defaultMessage: "Optional line items",
    description: "Feature: Optional line items",
  },
  locationTimers: {
    id: "connectToGrowStartOfTrialModal.locationTimers",
    defaultMessage: "Location timers",
    description: "Feature: Location timers",
  },
  jobCosting: {
    id: "connectToGrowStartOfTrialModal.jobCosting",
    defaultMessage: "Job costing",
    description: "Feature: Job costing",
  },
  autoQuote: {
    id: "connectToGrowStartOfTrialModal.autoQuote",
    defaultMessage: "Automated quote follow-ups",
    description: "Feature: Automated quote follow-ups",
  },
  confirmation: {
    id: "connectToGrowStartOfTrialModal.confirmation",
    defaultMessage: "Got it",
    description: "Confirmation button text",
  },
});
