import { gql } from "@apollo/client";

export const INVOICE_SUMMARY_QUERY = gql`
  query InvoiceSummary(
    $sort: InvoiceSummarySortInput
    $filter: InvoiceSummaryFilterInput
    $first: Int
    $cursor: String
  ) {
    invoiceSummary(
      sort: $sort
      filter: $filter
      cursor: $cursor
      first: $first
    ) {
      reportPageInfo {
        startCursor
        nextCursor
      }
      totalCount
      records {
        averagePerInvoice
        issuedCount
        name
        totalIssued
        totalPaid
      }
    }
  }
`;

export const INVOICE_SUMMARY_TOTALS_QUERY = gql`
  query InvoiceSummaryTotals($filter: InvoiceSummaryFilterInput) {
    invoiceSummaryTotals(filter: $filter) {
      issuedAverageTotal
      issuedCountTotal
      issuedTotal
      paidTotal
    }
  }
`;

export const INVOICE_SUMMARY_EXPORT_MUTATION = gql`
  mutation InvoiceSummaryReportExport($input: ExportInvoicesSummaryCsvInput) {
    exportInvoicesSummaryCsv(input: $input) {
      response
    }
  }
`;
