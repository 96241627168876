import { Gallery } from "@jobber/components/Gallery";
import React from "react";

interface CostingGalleryProps {
  name: string;
  type: string;
  size: number;
  src: string;
  progress: number;
}

// This component was only required because the Atlantis
// Gallery Component doesn't prevent click event propagation
// https://jobber.atlassian.net/browse/JOB-67601
export function CostingGallery({
  name,
  type,
  size,
  src,
  progress,
}: CostingGalleryProps) {
  const preventClickPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Grandfathered error: Please fix if touching this code.
    <div onClick={preventClickPropagation}>
      <Gallery
        files={[
          {
            name: name,
            type: type,
            size: size,
            src: src,
            progress: progress,
            key: `${name}_${size}`,
          },
        ]}
      />
    </div>
  );
}
