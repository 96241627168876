import React from "react";
import type { IconNames } from "@jobber/components";
import { Flex } from "@jobber/components";
import { AutomationsIcon } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/automationsIcon/AutomationsIcon";
import type { FieldType } from "./RuleExpression";
import { RuleExpression } from "./RuleExpression";
import type { RuleDropdownProps } from "./RuleDropdown";
import styles from "./RuleBlock.module.css";

interface RuleBlockProps {
  iconName: IconNames;
  text: string;
  fields: {
    [key: string]: {
      fieldType: FieldType;
      argument: string | number | undefined;
      options?: RuleDropdownProps["options"];
      readOnly?: boolean;
      setArguments?: (val: string | number) => void;
    };
  };
}

export function RuleBlock({ iconName, text, fields }: RuleBlockProps) {
  return (
    <div className={styles.inputContainer}>
      <Flex template={["shrink", "grow"]}>
        <AutomationsIcon iconName={iconName} />
        <RuleExpression text={text} fields={fields} />
      </Flex>
    </div>
  );
}
