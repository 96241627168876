import type { ManualPagination } from "@jobber/components/DataTable";
import { useMemo, useState } from "react";

export const DEFAULT_PAGE_NUMBER_OPTIONS = [20, 50];
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_NUMBER_OPTIONS[0];

export interface PageState {
  pageIndex: number;
  pageSize: number;
}

export function useClientSegmentDataTablePaginationState() {
  const [{ pageIndex, pageSize }, setPagination] = useState<PageState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_NUMBER_OPTIONS[0],
  });
  const pageState = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const endCursor = useMemo(() => {
    return btoa((pageIndex * pageSize).toString());
  }, [pageIndex, pageSize]);

  const getPaginationProps = (totalItems: number): ManualPagination => ({
    manualPagination: true,
    onPaginationChange: setPagination,
    itemsPerPage: DEFAULT_PAGE_NUMBER_OPTIONS,
    totalItems,
    state: pageState,
    pageCount: Math.ceil(totalItems / pageState.pageSize),
  });

  return { pageState, endCursor, setPagination, pageSize, getPaginationProps };
}
