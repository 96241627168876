import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-deprecated
import { type FileUpload, InputFile } from "@jobber/components/InputFile";
import { FormatFile } from "@jobber/components/FormatFile";
import {
  removeLineItemImage,
  setLineItemImage,
} from "jobber/bridges/lineItemImageForm";
import { fetchInternalUploadParams } from "utilities/fetchUploadParams";
import { UploadDestinationsEnum } from "~/utilities/API/graphql";

export interface WorkItemImageSelectorEvent {
  fileName: string | undefined;
  contentType: string | undefined;
  fileSize: number | undefined;
  s3Key: string | undefined;
  thumbnail: string | undefined;
}

export interface WorkItemImageSelectorProps {
  thumbnailUrl: string;
  s3Key: string;
  fileName: string;
  canUpload: boolean;
  contentType: string;
  fileSize: number;
  uploadUrl: string;
  // we need this prop right now as there are currently places in the codebase where
  // We are using this in the work_items/_form.html.erb -> line_items/file_attachment.html.erb
  // This is only used when opening the work_item modal from invoice line items
  parentIsReact: boolean;
  uniqueId?: string;
  onChanged?(event: WorkItemImageSelectorEvent): void;
  onDeleted?(): void;
}

export interface FileAttachmentData {
  fileName: string;
  contentType: string;
  fileSize: number;
  key: string;
}

// This file will be changing as we are replacing @jobber/react-s3-uploader with dropzone
// eslint-disable-next-line max-statements
export function WorkItemImageSelector(props: WorkItemImageSelectorProps) {
  const [fileUpload, setFileUpload] = useState<FileUpload | undefined>(
    buildFileFromProps(props),
  );

  useEffect(() => {
    setFileUpload(buildFileFromProps(props));
  }, [props.uniqueId]);

  function onDeleted() {
    setFileUpload(undefined);
    // This can be removed when we are no longer using this component in erb files
    if (!props.parentIsReact && props.uniqueId) {
      removeLineItemImage(props.uniqueId);
    } else if (props.onDeleted) {
      props.onDeleted();
    }
  }

  function handleUploadStart(file: FileUpload) {
    setFileUpload(file);
  }
  function handleUploadProgress(file: FileUpload) {
    setFileUpload(file);
  }

  async function handleUploadComplete(file: FileUpload) {
    setFileUpload(file);
    if (!props.parentIsReact && props.uniqueId) {
      setLineItemImage(
        props.uniqueId,
        file.name,
        file.type,
        String(file.size),
        file.key,
      );
    } else if (props.onChanged) {
      props.onChanged({
        fileName: file.name,
        contentType: file.type,
        fileSize: file.size,
        s3Key: file.key,
        thumbnail: await file.src(),
      });
    }
  }
  if (fileUpload) {
    return <FormatFile file={fileUpload} onDelete={onDeleted} />;
  }
  if (!props.canUpload) {
    return <></>;
  }
  return (
    <InputFile
      allowedTypes="images"
      allowMultiple={false}
      getUploadParams={fetchInternalUploadParams(
        UploadDestinationsEnum.FILE_ATTACHMENTS,
      )}
      onUploadStart={handleUploadStart}
      onUploadProgress={handleUploadProgress}
      onUploadComplete={handleUploadComplete}
    />
  );
}

function buildFileFromProps(props: WorkItemImageSelectorProps) {
  return props.fileName
    ? {
        key: props.s3Key,
        name: props.fileName,
        type: props.contentType,
        size: props.fileSize,
        progress: 1,
        uploadUrl: props.uploadUrl,
        src: () => Promise.resolve(props.thumbnailUrl),
      }
    : undefined;
}
