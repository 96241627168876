import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { forEach } from "lodash";
import type {
  JobDeleteLineItemsMutation,
  JobDeleteLineItemsMutationVariables,
} from "~/utilities/API/graphql";
import {
  JOB_COST_LINE_ITEM_TOTALS_QUERY,
  JOB_DELETE_LINE_ITEMS,
} from "./graphql";
import { messages } from "./messages";

interface UseJobDeleteLineItemsMutation {
  lineItemId: string;
  jobId: string;
  canViewJobCosts: boolean;
  canViewPricing: boolean;
  onSuccess: () => void;
}

export function useJobDeleteLineItemsMutation({
  lineItemId,
  jobId,
  canViewJobCosts,
  canViewPricing,
  onSuccess,
}: UseJobDeleteLineItemsMutation): {
  handleDeleteLineItem(): Promise<void>;
  loading: boolean;
} {
  const [deleteLineItem, { loading }] = useMutation<
    JobDeleteLineItemsMutation,
    JobDeleteLineItemsMutationVariables
  >(JOB_DELETE_LINE_ITEMS, {
    refetchQueries: [
      {
        query: JOB_COST_LINE_ITEM_TOTALS_QUERY,
        variables: {
          jobId: jobId,
          canViewJobCosts: canViewJobCosts,
          canViewPricing: canViewPricing,
        },
      },
    ],
    update(cache, result) {
      if (result?.data?.jobDeleteLineItems) {
        forEach(result.data.jobDeleteLineItems.deletedLineItems, lineItem => {
          cache.evict({
            id: cache.identify(lineItem),
          });
        });
        cache.gc();
      }
    },
  });

  async function handleDeleteLineItem() {
    try {
      const result = await deleteLineItem({
        variables: {
          jobId,
          lineItemId,
        },
      });

      const errors = result.data?.jobDeleteLineItems.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.lineItemDeleted,
          variation: "success",
        });
        onSuccess();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleDeleteLineItem,
    loading,
  };
}
