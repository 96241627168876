import { jobberOnline } from "components/JobberOnline/jobberOnline";

const STRIPE_UPLOADS_FILES_ENDPOINT = "https://files.stripe.com/v1/files";

export async function uploadFileToStripe(stripeAccountID: string, file: File) {
  const data = new FormData();
  data.append("file", file);
  data.append("purpose", "identity_document");

  const headers: Record<string, string> = {
    Authorization: `Bearer ${jobberOnline.constants.stripeKey}`,
  };
  // by pass the error "Object Literal Property name `Stripe-Account` must match one of the following formats: camelCase, PascalCase, UPPER_CASE"
  headers["Stripe-Account"] = stripeAccountID;

  const fileResult = await fetch(STRIPE_UPLOADS_FILES_ENDPOINT, {
    method: "POST",
    headers: headers,
    body: data,
  });

  if (!fileResult.ok) {
    throw new Error("Failed to upload file");
  }

  return fileResult.json();
}
