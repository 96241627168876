import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { ExpiryStatus } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";

export interface ServerCreditCard {
  uuid: string;
  last4: string;
  brand: string;
  default_method: boolean;
  expiry_year: number;
  expiry_month: number;
  expired: boolean;
  expiring_soon: boolean;
  fingerprint: string;
}

export function mapServerCreditCard(card: ServerCreditCard): CreditCard {
  return {
    id: card.uuid,
    uuid: card.uuid,
    brand: mapBrand(card.brand),
    last4: card.last4,
    isDefault: card.default_method,
    expiryMonth: card.expiry_month,
    expiryYear: card.expiry_year,
    fingerprint: card.fingerprint,
    expiryStatus: mapExpiry(card),
  };
}

function mapBrand(paymentMethodBrand: string): CreditCard["brand"] {
  switch (paymentMethodBrand) {
    case "American Express":
      return "amex";
    case "Discover":
      return "discover";
    case "JCB":
      return "jcb";
    case "MasterCard":
      return "mastercard";
    case "Visa":
      return "visa";
    case "Unknown":
      return "unknown";
    default:
      // `paymentMethodBrand` can be both `American Express` and `amex`
      // needs to support both variants
      return paymentMethodBrand as CreditCard["brand"];
  }
}

function mapExpiry(card: ServerCreditCard): ExpiryStatus {
  const { expired, expiring_soon } = card;
  if (expired) {
    return ExpiryStatus.EXPIRED;
  }
  if (expiring_soon) {
    return ExpiryStatus.EXPIRING_SOON;
  }

  return ExpiryStatus.DEFAULT;
}
