import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sent: {
    id: "campaigns.recipientsDisplay.sent",
    defaultMessage: "{totalSent} sent",
    description: "Sent",
  },
  delivered: {
    id: "campaigns.recipientsDisplay.delivered",
    defaultMessage: "{totalDelivered} delivered ({deliveredPercentage}%)",
    description: "Delivered",
  },
  tooltip: {
    id: "campaigns.recipientsDisplay.tooltip",
    defaultMessage:
      "The percentage of emails that successfully reached recipients' inboxes without bouncing or being marked as spam",
    description: "Tooltip",
  },
});
