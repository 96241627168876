import React, { useCallback } from "react";
import { InputText } from "@jobber/components/InputText";
import { Grid } from "@jobber/components/Grid";
import classNames from "classnames";
import { InputProvince } from "./InputProvince";
import { InputCountry } from "./InputCountry";
import type { AddressValues } from "./types";
import { useCountriesAndProvinces } from "./useCountriesAndProvinces";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { InputStreet } from "./InputStreet";

interface InputAddressProps {
  name: string;
  address: AddressValues;
  onChange(address: AddressValues): void;
  requireProvince?: boolean;
  specificValidationMessages?: boolean;
}

export function InputAddress({
  name,
  address,
  onChange,
  requireProvince,
  specificValidationMessages,
}: InputAddressProps) {
  const fieldNames = {
    street1: name + "street1",
    street2: name + "street2",
    city: name + "city",
    province: name + "province",
    postalCode: name + "postalCode",
    country: name + "country",
  };

  const {
    country,
    countries,
    provinces,
    provincesPlaceholder,
    postalCodePlaceholder,
    postalCodeValidationPattern,
  } = useCountriesAndProvinces(address.country);

  const onFieldChanged = useCallback(
    (fieldName: keyof AddressValues) => (newValue: string) =>
      onChange({ ...address, country, [fieldName]: newValue }),
    [address, country, onChange],
  );

  const onCountryChanged = useCallback(
    (newCountry: AddressValues["country"]) =>
      onChange({
        ...address,
        country: newCountry,
        stateOrProvince: undefined,
      }),
    [address, onChange],
  );

  const shareRow: { readonly [key: string]: number } = {
    xs: 12,
    md: 6,
  };

  return (
    <Grid gap={false}>
      <Grid.Cell size={{}}>
        <InputStreet
          placeholder={messages.street1Label.message}
          name={fieldNames.street1}
          address={{
            ...address,
            country,
          }}
          onAddressChange={onChange}
          validations={{
            maxLength: 255,
            required: {
              value: true,
              message: specificValidationMessages
                ? messages.validationRequiredAddress.message
                : messages.validationRequired.message,
            },
          }}
        />
      </Grid.Cell>
      <Grid.Cell size={{}}>
        <div className={styles.removeTopBorder}>
          <InputText
            placeholder={messages.street2Label.message}
            name={fieldNames.street2}
            value={address.street2}
            onChange={onFieldChanged("street2")}
            validations={{
              maxLength: 255,
            }}
          />
        </div>
      </Grid.Cell>
      <Grid.Cell size={shareRow}>
        <div
          className={classNames(
            styles.inputGroupWithValidationsFix,
            styles.removeTopBorder,
          )}
        >
          <InputText
            placeholder={messages.city.message}
            name={fieldNames.city}
            value={address.city}
            onChange={onFieldChanged("city")}
            validations={{
              maxLength: 255,
              required: {
                value: true,
                message: specificValidationMessages
                  ? messages.validationRequiredCity.message
                  : messages.validationRequired.message,
              },
            }}
          />
        </div>
      </Grid.Cell>
      <Grid.Cell size={shareRow}>
        <div
          className={classNames(
            styles.inputGroupWithValidationsFix,
            styles.secondColumn,
          )}
        >
          <InputProvince
            placeholder={provincesPlaceholder.message}
            name={fieldNames.province}
            provinces={provinces}
            value={address.stateOrProvince}
            onChange={onFieldChanged("stateOrProvince")}
            validations={
              requireProvince
                ? {
                    maxLength: 255,
                    required: {
                      value: true,
                      message: specificValidationMessages
                        ? messages.validationRequiredGeneric.message +
                          provincesPlaceholder.message.toLowerCase()
                        : messages.validationRequired.message,
                    },
                  }
                : {
                    maxLength: 255,
                  }
            }
          />
        </div>
      </Grid.Cell>
      <Grid.Cell size={shareRow}>
        <div
          className={classNames(
            styles.inputGroupWithValidationsFix,
            styles.removeTopBorder,
          )}
        >
          <InputText
            placeholder={postalCodePlaceholder.message}
            name={fieldNames.postalCode}
            value={address.zipOrPostalCode}
            onChange={onFieldChanged("zipOrPostalCode")}
            validations={{
              pattern: postalCodeValidationPattern,
              maxLength: 255,
              required: {
                value: true,
                message: specificValidationMessages
                  ? messages.validationRequiredGeneric.message +
                    postalCodePlaceholder.message.toLowerCase()
                  : messages.validationRequired.message,
              },
            }}
          />
        </div>
      </Grid.Cell>
      <Grid.Cell size={shareRow}>
        <div
          className={classNames(
            styles.inputGroupWithValidationsFix,
            styles.secondColumn,
          )}
        >
          <InputCountry
            placeholder={messages.country.message}
            name={fieldNames.country}
            countries={countries}
            value={country}
            onChange={onCountryChanged}
            validations={{
              maxLength: 255,
              required: {
                value: true,
                message: specificValidationMessages
                  ? messages.validationRequiredCountry.message
                  : messages.validationRequired.message,
              },
            }}
          />
        </div>
      </Grid.Cell>
    </Grid>
  );
}
