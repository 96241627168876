import React from "react";
import { Text } from "@jobber/components/Text";
import { InputNumber } from "@jobber/components/InputNumber";
import classNames from "classnames";
import { BaseReadOnlyCustomField } from "./BaseReadCustomField";
import styles from "./CustomFieldTypes.module.css";
import type { AreaValue } from "../types";

interface CustomFieldEditProps {
  value: AreaValue;
  editing: boolean;
  onChange(newValue: AreaValue): void;
}

interface CustomFieldAreaProps {
  isInAppGroup?: boolean;
  label: string;
  stringValue: string;
  editProps?: CustomFieldEditProps;
}

export function CustomFieldArea({
  isInAppGroup = false,
  label,
  stringValue,
  editProps,
}: CustomFieldAreaProps) {
  if (editProps?.editing) {
    return (
      <div className={classNames([styles.container])} title="container">
        <div
          className={classNames({
            [styles.label]: true,
            [styles.labelEdit]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div
          className={classNames([styles.value, styles.valueEdit])}
          title="value"
        >
          <InputNumber
            size="small"
            placeholder={`Length (${editProps.value.unit})`}
            value={editProps.value.length}
            onChange={onLengthChange}
          />
          <span className={styles.areaSymbol}>&times;</span>
          <InputNumber
            size="small"
            placeholder={`Width (${editProps.value.unit})`}
            value={editProps.value.width}
            onChange={onWidthChange}
          />
          <span className={styles.unit}>{editProps.value.unit}</span>
        </div>
      </div>
    );
  } else {
    return BaseReadOnlyCustomField(isInAppGroup, label, stringValue);
  }

  function onLengthChange(newLength: number) {
    editProps?.onChange({
      length: newLength,
      width: editProps.value.width,
      unit: editProps.value.unit,
    });
  }

  function onWidthChange(newWidth: number) {
    editProps?.onChange({
      length: editProps.value.length,
      width: newWidth,
      unit: editProps.value.unit,
    });
  }
}
