import { gql } from "@apollo/client";

const ADDITIONAL_CRITERIA = gql`
  fragment additionalCriteria on AdditionalCriteria {
    __typename
    ... on ClientTagCriteria {
      clientTagValue: value
      operator
      index
    }
    ... on ClientTypeCriteria {
      clientTypeValue: value
      index
    }
    ... on JobStatusCriteria {
      index
    }
    ... on JobTypeCriteria {
      jobTypeValue: value
      index
    }
    ... on LineItemCriteria {
      lineItemValue: value {
        id
        name
      }
      operator
      index
    }
  }
`;

export const CLIENT_SEGMENT = gql`
  fragment clientSegment on ClientSegment {
    id
    criteria {
      nodes {
        value
        name
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      totalCount
    }
    additionalCriteria(sort: { key: INDEX, direction: ASCENDING }) {
      nodes {
        ...additionalCriteria
      }
      totalCount
    }
    type
    friendlyName
  }
  ${ADDITIONAL_CRITERIA}
`;

const TEMPLATE_VARIABLES = gql`
  fragment templateVariables on CampaignTemplateVariableSection {
    label
    variables {
      nodes {
        name
        label
        sample
      }
    }
  }
`;

export const TEMPLATES = gql`
  fragment templates on CampaignTemplate {
    id
    subject
    header
    body
    type
    showLogo
    ctaVisible
    ctaLinkTo
    ctaUrl
    ctaText
    ctaColor
    headerImage {
      fileName
      fileSize
      contentType
      thumbnailUrl
      url
    }
    variables {
      nodes {
        ...templateVariables
      }
    }
    referralLink
  }
  ${TEMPLATE_VARIABLES}
`;

export const AUTOMATION_RULE = gql`
  fragment automationRule on AutomationRule {
    trigger
    name
    id
    condition
    active
    actionedCount
    action
  }
`;

export const CAMPAIGN = gql`
  fragment campaign on Campaigns {
    clientSegment {
      ...clientSegment
    }
    templates {
      nodes {
        ...templates
      }
    }
    automationRule {
      ...automationRule
    }
    isAutomated
    id
    openRate
    sentAt
    status
    updatedAt
    clickRate
    totalClicked
    totalOpened
    totalDelivered
    totalUnsubscribed
    totalSent
    revenue {
      value
      count
    }
    updatedBy {
      email {
        raw
      }
      account {
        name
      }
    }
    fromEmail
    replyToEmail
    scheduledAt
    isAutomated
  }
  ${CLIENT_SEGMENT}
  ${TEMPLATES}
  ${AUTOMATION_RULE}
`;

export const CAMPAIGN_LIST = gql`
  fragment campaignList on Campaigns {
    clientSegment {
      id
      type
      friendlyName
    }
    templates {
      nodes {
        id
        subject
      }
    }
    id
    sentAt
    status
    statusMessage
    updatedAt
    openRate
    clickRate
    totalClicked
    totalOpened
    totalDelivered
    totalUnsubscribed
    scheduledAt
    revenue {
      value
      count
    }
    automationRule {
      ...automationRule
    }
  }
  ${AUTOMATION_RULE}
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query commsCampaign($id: EncodedId!) {
    commsCampaign(id: $id) {
      ...campaign
    }
  }
  ${CAMPAIGN}
`;

export const GET_CAMPAIGNS_EXPERIENCE_QUERY = gql`
  query commsCampaignsExperience {
    commsCampaignsExperience {
      hasDemoExperience
    }
  }
`;

export const GET_CAMPAIGNS_QUERY = gql`
  query getCampaigns($sort: [CampaignSortInput!], $cursor: String) {
    commsCampaigns(sort: $sort, first: 10, after: $cursor) {
      totalCount
      edges {
        cursor
        node {
          ...campaign
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${CAMPAIGN}
`;

export const GET_CAMPAIGNS_LIST_QUERY = gql`
  query getCampaignsListQuery(
    $sort: [CampaignSortInput!]
    $cursor: String
    $filter: CampaignsFilterInput
  ) {
    commsCampaigns(sort: $sort, first: 10, after: $cursor, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          ...campaignList
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${CAMPAIGN_LIST}
`;

const CAMPAIGN_EMAIL = gql`
  fragment campaignEmail on CampaignsEmail {
    id
    client {
      id
    }
    clickedAt
    deliveredAt
    openedAt
    recipientEmail
    recipientName
    unsubscribedAt
    revenue {
      count
      value
    }
    jobs {
      totalCount
      nodes {
        id
        jobNumber
      }
    }
  }
`;

export const GET_CAMPAIGN_EMAILS = gql`
  query commsCampaignEmails(
    $campaignId: EncodedId!
    $cursor: String
    $filter: CampaignEmailFilterAttributes
    $searchTerm: String
    $sort: [CampaignEmailSortInput!]
  ) {
    commsCampaign(id: $campaignId) {
      id
      totalDelivered
      totalClicked
      totalOpened
      totalSent
      totalUnsubscribed
      updatedAt
      emails(
        after: $cursor
        first: 15
        filter: $filter
        searchTerm: $searchTerm
        sort: $sort
      ) {
        totalCount
        pageInfo {
          startCursor
          hasPreviousPage
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...campaignEmail
          }
        }
      }
    }
  }
  ${CAMPAIGN_EMAIL}
`;
