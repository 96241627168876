import { Content } from "@jobber/components/Content";
import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import { AdditionalCriterion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/AdditionalCriterion";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { useAddCriteriaButtonVisibility } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/hooks/useAddCriteriaButtonVisibility";
import { messages } from "./messages";

interface AdditionalCriteriaProps {
  criteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

function generateKey(_criterion: AdditionalCriteriaUnion) {
  return btoa(JSON.stringify(_criterion));
}

export function AdditionalCriteria({
  criteria,
  updateAdditionalCriteria,
}: AdditionalCriteriaProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { upsellCampaignSplit } = useCampaignUpsellSplit();
  const { buttonVisibility } = useAddCriteriaButtonVisibility(criteria);

  if (!upsellCampaignSplit) {
    return <></>;
  }

  const additionalCriteria = criteria.map(criterion => {
    return (
      <AdditionalCriterion
        criterion={criterion}
        updateAdditionalCriteria={updateAdditionalCriteria}
        index={criterion.index}
        key={generateKey(criterion)}
      />
    );
  });

  const addAnotherCriteria = () => {
    updateAdditionalCriteria({
      type: "addNewCriteria",
    });
  };

  return (
    <Content>
      {additionalCriteria}
      {buttonVisibility && (
        <Button
          label={formatMessage(messages.addAdditionalCriteria)}
          type="secondary"
          icon={"plus2"}
          size={"small"}
          onClick={addAnotherCriteria}
        />
      )}
    </Content>
  );
}
