import { defineMessages } from "react-intl";

export const messages = defineMessages({
  popoverHeading: {
    id: "workOrders.jobCost.jobCostHeaderPopover.popoverHeading",
    defaultMessage: "Calculate job profit",
    description: "Job Cost Popover Heading",
  },
  popoverHeadingDescription: {
    id: "workOrders.jobCost.jobCostHeaderPopover.popoverHeadingDescription",
    defaultMessage:
      "Include cost on line items, labor, and expenses to get an accurate reading on job profitability.",
    description: "Job Cost Popover Heading Description",
  },
  popoverButton: {
    id: "workOrders.jobCost.jobCostHeaderPopover.popoverButton",
    defaultMessage: "Learn about job costing",
    description: "Job Cost Popover Button Heading",
  },
  popoverButtonLink: {
    id: "workOrders.jobCost.jobCostHeaderPopover.popoverButtonLink",
    defaultMessage:
      "https://help.getjobber.com/hc/en-us/articles/14343244961175",
    description: "Job Cost Popover Button Link",
  },
});
