import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CampaignUpdateTemplatesMutation,
  CampaignUpdateTemplatesMutationVariables,
} from "~/utilities/API/graphql";
import { CAMPAIGN_UPDATE_TEMPLATES } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export function useCampaignUpdateTemplatesMutation() {
  const [campaignUpdateTemplatesMutation, { loading, error, data }] =
    useMutation<
      CampaignUpdateTemplatesMutation,
      CampaignUpdateTemplatesMutationVariables
    >(CAMPAIGN_UPDATE_TEMPLATES);

  const campaignUpdateTemplates = useCallback(
    async (input: CampaignUpdateTemplatesMutationVariables) => {
      const mutationResult = await campaignUpdateTemplatesMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignUpdateTemplates.userErrors ?? [];
      return {
        campaign: mutationResult.data?.campaignUpdateTemplates.campaign,
        userErrors,
      };
    },
    [campaignUpdateTemplatesMutation],
  );

  return {
    campaignUpdateTemplates,
    loading,
    error,
    data,
  };
}
