import React from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import type { AdditionalSubscription } from "./CancellationForm.d";
import styles from "./CancellationFormModal.module.css";

interface AdditionalSubscriptionStepProps {
  nextBillingDate: string;
  additionalSubscription: AdditionalSubscription | undefined;
  onNext(): void;
}

export function AdditionalSubscriptionStep({
  nextBillingDate,
  additionalSubscription,
  onNext,
}: AdditionalSubscriptionStepProps) {
  if (additionalSubscription) {
    const costInDollars = `$${additionalSubscription.additionalSeatCost / 100}`;

    return additionalSubscription.usingAdditionalSeats ? (
      <Content>
        <Content>
          You&apos;re paying for {additionalSubscription.additionalSeats}{" "}
          additional user seats at {costInDollars}/month each. Your next bill is
          due on {additionalSubscription.nextBillingDate}.
        </Content>
        <Content>
          Your additional user seats will continue to be billed until the end of
          your Jobber subscription, which will expire on {nextBillingDate}.
        </Content>
        <Content>
          <strong>
            In order to cancel your subscription, your additional users must be
            deactivated.
          </strong>{" "}
          If you would like to stop your additional user seat billing, head to{" "}
          <a href="/manage_team">Manage Team</a> and deactivate your additional
          users.
        </Content>
      </Content>
    ) : (
      <>
        <Content>
          <Content>
            You&apos;re paying for {additionalSubscription.additionalSeats}{" "}
            additional user seats at {costInDollars}/month each. Your next bill
            is due on {additionalSubscription.nextBillingDate}.
          </Content>
          <Content>
            Your additional user seats subscription will be terminated
            immediately when this form is submitted.
          </Content>
        </Content>
        <div className={styles.singleActionButtonContainer}>
          <Button label="Next" onClick={onNext} />
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
