export function minutesToDuration(minutes: number) {
  const hours = Math.floor(minutes / 60);
  if (minutes === 0) return "0m";
  const minutesLeftOver = minutes % 60;
  if (!minutesLeftOver) {
    return `${hours}h`;
  } else if (!hours) {
    return `${minutesLeftOver}m`;
  } else {
    return `${hours}h ${minutesLeftOver}m`;
  }
}
