import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Grid } from "@jobber/components/Grid";
import { ButtonDismiss } from "@jobber/components/ButtonDismiss";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import locationTimersDiscovery from "@images/locationTimersDiscovery.png";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import type {
  ActivateConnectToGrowTrialMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  ConnectToGrowStartOfTrialModal,
  ConnectToGrowStartOfTrialModalFeaturesEnum,
} from "jobber/connectToGrowTrial/components/ConnectToGrowStartOfTrialModal";
import {
  ACTIVATE_GROW_TRIAL,
  GROW_TRIAL_ELIGIBILITY,
  type GrowTrialEligibilityData,
} from "jobber/connectToGrowTrial/ConnectToGrowTrial.graphql";
import { messages } from "./messages";
import styles from "./LocationTimersCTA.module.css";

function WrappedLocationTimersCTA() {
  const ctaName = "location_timers_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [
    showConnectToGrowStartOfTrialModal,
    setShowConnectToGrowStartOfTrialModal,
  ] = useState(false);

  return (
    <>
      {showConnectToGrowStartOfTrialModal && (
        <ConnectToGrowStartOfTrialModal
          feature={ConnectToGrowStartOfTrialModalFeaturesEnum.LocationTimer}
          showModal={showConnectToGrowStartOfTrialModal}
        />
      )}
      <CallToAction ctaName={ctaName} ref={ctaRef}>
        <LocationTimersCTA
          ctaRef={ctaRef}
          showConnectToGrowStartOfTrialModal={() =>
            setShowConnectToGrowStartOfTrialModal(true)
          }
        />
      </CallToAction>
    </>
  );
}

interface LocationTimersCTAProps {
  ctaRef: MutableRefObject<CallToActionRef>;
  showConnectToGrowStartOfTrialModal(): void;
}

function LocationTimersCTA({
  ctaRef,
  showConnectToGrowStartOfTrialModal,
}: LocationTimersCTAProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { formatMessage } = useIntl();

  const { data, loading } = useQuery<GrowTrialEligibilityData>(
    GROW_TRIAL_ELIGIBILITY,
  );

  const [activateGrowTrial] =
    useMutation<ActivateConnectToGrowTrialMutation>(ACTIVATE_GROW_TRIAL);

  useEffect(() => {
    if (
      !loading &&
      data?.accountFeatureTrialEligibility.eligibleForConnectToGrowTrial
    ) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        source: "location_timers_cta",
      });
    }
  }, [
    loading,
    data?.accountFeatureTrialEligibility.eligibleForConnectToGrowTrial,
  ]);

  return (
    <div className={styles.container}>
      {!loading && (
        <Grid gap={false} alignItems={"center"}>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }}>
            <img
              data-testid="discoveryGraphic"
              src={locationTimersDiscovery}
              alt="Location timers discovery graphic"
            />
          </Grid.Cell>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 8 }}>
            <div className={styles.textContainer}>
              {errorMessage && (
                <Banner type="error" dismissible={false}>
                  {errorMessage}
                </Banner>
              )}
              <div className={styles.titleRow}>
                <Heading level={3}>
                  {formatMessage(messages.header)}
                  <Emphasis variation={"highlight"}>
                    {formatMessage(messages.headerEmphasis)}
                  </Emphasis>
                </Heading>
                <ButtonDismiss
                  ariaLabel="Dismiss"
                  onClick={dismissCTA(ctaRef, {
                    type: data?.accountFeatureTrialEligibility
                      .eligibleForConnectToGrowTrial
                      ? "eligible_for_connect_to_grow_trial"
                      : "not_eligible_for_connect_to_grow_trial",
                  })}
                />
              </div>
              <Text>
                {formatMessage(messages.body)}
                {data?.accountFeatureTrialEligibility
                  .eligibleForConnectToGrowTrial
                  ? `. ${formatMessage(messages.bodyTrial)}`
                  : ""}
              </Text>
              <div className={styles.buttonRow}>
                {data?.accountFeatureTrialEligibility
                  .eligibleForConnectToGrowTrial ? (
                  <Button
                    label={formatMessage(messages.buttonTrial)}
                    type="secondary"
                    variation="learning"
                    onClick={handleGrowTrialStart}
                    loading={isLoading}
                  />
                ) : (
                  <>
                    <Button
                      label={formatMessage(messages.buttonTry)}
                      type="secondary"
                      variation="learning"
                      size="small"
                      onClick={() => {
                        convertCTA(ctaRef)();
                        window.open(
                          "/location_timers_settings?enable_on_load=true",
                          "_blank",
                        );
                      }}
                    />
                    <Button
                      label={formatMessage(messages.buttonLearn)}
                      type="tertiary"
                      variation="subtle"
                      size="small"
                      onClick={() => {
                        convertCTA(ctaRef)();
                        window.open("/location_timers_settings", "_blank");
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </Grid.Cell>
        </Grid>
      )}
    </div>
  );

  async function handleGrowTrialStart() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "location_timers_cta",
      name: "try_this_feature",
    });

    setIsLoading(true);
    await updateTrialState();
  }

  async function updateTrialState() {
    const defaultError = new Error(
      "Grow trial could not be started. Please try again.",
    );

    try {
      const growTrialData = await activateGrowTrial();
      const errors = growTrialData.data?.activateConnectToGrowTrial
        .userErrors as MutationErrors[];

      if (errors.length === 0) {
        setIsLoading(false);
        showConnectToGrowStartOfTrialModal();
        convertCTA(ctaRef)();
      } else {
        setErrorMessage(defaultError.message);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMessage(defaultError.message);
      setIsLoading(false);
    }
  }
}

export { WrappedLocationTimersCTA as LocationTimersCTA };
