/*
 * Perform a browser-based form submission that includes the CSRF token.
 *
 * This implementation is based heavily on a similar feature in rails-ujs:
 *
 * https://github.com/rails/rails/blob/ad3a47759e67a411f3534309cdd704f12f6930a7/actionview/app/assets/javascripts/features/method.coffee
 */

import { csrfToken } from "./csrfToken";

type HttpMethod = "post" | "get";

export interface FormParameters {
  action: string;
  method: HttpMethod;
  params?: Record<string, string>;
}

const buildInput = (name: string, value: string) => {
  const input = document.createElement("input");

  input.name = name;
  input.value = value;
  input.type = "hidden";

  return input;
};

const buildSubmit = () => {
  const input = document.createElement("input");

  input.type = "submit";

  return input;
};

export const buildForm = ({
  action,
  method,
  params,
}: FormParameters): HTMLFormElement => {
  const form = document.createElement("form");
  form.method = method;
  form.action = action;
  form.style.display = "none";

  form.insertAdjacentElement(
    "beforeend",
    buildInput("authenticity_token", csrfToken),
  );

  for (const [name, value] of Object.entries(params || {})) {
    form.insertAdjacentElement("beforeend", buildInput(name, value));
  }

  form.insertAdjacentElement("beforeend", buildSubmit());

  return form;
};

export const submitDomForm = ({ action, method, params }: FormParameters) => {
  const form = buildForm({ action, method, params });
  document.body.appendChild(form);
  const button = form.querySelector('[type="submit"]') as HTMLInputElement;
  button.click();
  return form;
};
