import React from "react";
import { FeatureItem, type FeatureItemProps } from "./FeatureItem";

interface FeatureListProps {
  items: FeatureItemProps[];
}

export function FeatureList({ items }: FeatureListProps) {
  return (
    <ul>
      {items.map(feature => {
        const { label, icon } = feature;
        return <FeatureItem key={label} icon={icon} label={label} />;
      })}
    </ul>
  );
}
