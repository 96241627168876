import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailLabel: {
    id: "billingAddressDisplay.emailLabel",
    defaultMessage: "Email",
    description: "Label text for email",
  },
  billingAddressLabel: {
    id: "billingAddressDisplay.billingAddressLabel",
    defaultMessage: "Billing address",
    description: "Label text for billing address",
  },
});
