import { defineMessages } from "react-intl";

export const messages = defineMessages({
  useProductsAndServicesDeleteFailedToDelete: {
    id: "workItemModal.useProductsAndServicesDelete.failedToDelete",
    defaultMessage: "Failed to delete the Product or Service",
    description: "Error message when delete fails",
  },
  useProductOrServiceSaveFailedToEdit: {
    id: "workItemModal.useProductOrServiceSave.failedToEdit",
    defaultMessage: "Failed to edit the Product or Service",
    description: "Error message when edit fails",
  },
  useProductOrServiceSaveFailedToCreate: {
    id: "workItemModal.useProductOrServiceSave.failedToCreate",
    defaultMessage: "Failed to create the Product or Service",
    description: "Error message when create fails",
  },
});
