import React from "react";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputText } from "@jobber/components/InputText";
import countryCodeToLabelOverrides from "jobber/payments_sca/components/BillingAddress/BillingAddressFields/countryCodeToLabelOverrides";
import { CountrySelect } from "jobber/payments_sca/components/BillingAddress/CountrySelect";
import { useCountryCodesToNameMap } from "~/utilities/contexts/internal/CountryCodesToNameMapContext";
import type {
  BankAccountInformationAction,
  BankAddress,
} from "./bankAccountInformationReducer";

export interface BankAddressFormProps {
  address: BankAddress;
  bankAccountInformationDispatch(action: BankAccountInformationAction): void;
}

export function BankAddressForm(props: BankAddressFormProps) {
  const { address, bankAccountInformationDispatch } = props;
  const labelOverrides =
    countryCodeToLabelOverrides[address.countryCode as string];
  const provinceInputText =
    labelOverrides?.province !== "" ? (
      <InputText
        placeholder={labelOverrides?.province || "Province"}
        value={address.province}
        onChange={handleProvinceChange}
      />
    ) : (
      <></>
    );

  return (
    <InputGroup>
      <InputText
        placeholder="Street 1"
        value={address.street1}
        onChange={handleStreet1Change}
      />
      <InputText
        placeholder="Street 2"
        value={address.street2}
        onChange={handleStreet2Change}
      />
      <InputGroup flowDirection="horizontal">
        <InputText
          placeholder={labelOverrides?.city || "City"}
          value={address.city}
          onChange={handleCityChange}
        />
        {provinceInputText}
      </InputGroup>
      <InputGroup flowDirection="horizontal">
        <InputText
          placeholder={labelOverrides?.pc || "Postal Code"}
          value={address.pc}
          onChange={handlePCChange}
        />
        <CountrySelect
          className="u-marginNone"
          countryCodesToNameMap={useCountryCodesToNameMap()}
          countryCode={address.countryCode || ""}
          onCountryCodeSelect={handleCountryCodeChange}
        />
      </InputGroup>
    </InputGroup>
  );

  function handleBankAddressUpdate(key: keyof BankAddress, value: string) {
    bankAccountInformationDispatch({
      type: "updateBankAddress",
      bankAddressPartialUpdate: {
        [key]: value,
      },
    });
  }

  function handleStreet1Change(newStreet: string) {
    handleBankAddressUpdate("street1", newStreet);
  }

  function handleStreet2Change(newStreet: string) {
    handleBankAddressUpdate("street2", newStreet);
  }

  function handleCityChange(newCity: string) {
    handleBankAddressUpdate("city", newCity);
  }

  function handleProvinceChange(newProvince: string) {
    handleBankAddressUpdate("province", newProvince);
  }

  function handlePCChange(newPc: string) {
    handleBankAddressUpdate("pc", newPc);
  }

  function handleCountryCodeChange(newCountryCode: string) {
    handleBankAddressUpdate("countryCode", newCountryCode);
  }
}
