import React from "react";
import { Grid } from "@jobber/components/Grid";
import classNames from "classnames";
import type { SingleEmail } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/CampaignEmailsTable";
import styles from "./CampaignEmailsTableRow.module.css";

interface EmailRowWithJobAndRevenueProps {
  item: SingleEmail;
  showJobInformation: boolean;
}

interface EmailRowProps {
  item: SingleEmail;
}

export function CampaignEmailsTableRow({
  item,
  showJobInformation,
}: EmailRowWithJobAndRevenueProps): JSX.Element {
  return (
    <>
      {showJobInformation ? (
        <EmailRowWithJobInformation item={item} />
      ) : (
        <EmailRowWithoutJobInformation item={item} />
      )}
    </>
  );
}

function EmailRowWithJobInformation({ item }: EmailRowProps): JSX.Element {
  return (
    <Grid alignItems={"center"} key={item.id}>
      <Grid.Cell size={{ xs: 4 }}>
        <div className={styles.preventWrap}>{item.recipient}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 1 }}>
        <div className={styles.preventWrap}>{item.delivered}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 1 }}>
        <div className={styles.preventWrap}>{item.opened}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 1 }}>
        <div className={styles.preventWrap}>{item.clicked}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={styles.preventWrap}>{item.unsubscribed}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={classNames(styles.preventWrap, styles.jobNumberLink)}>
          {item.jobs}
        </div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 1 }}>
        <div className={styles.justifyContentEnd}>{item.revenue}</div>
      </Grid.Cell>
    </Grid>
  );
}

function EmailRowWithoutJobInformation({ item }: EmailRowProps): JSX.Element {
  return (
    <Grid alignItems={"center"} key={item.id}>
      <Grid.Cell size={{ xs: 4 }}>
        <div className={styles.preventWrap}>{item.recipient}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={styles.preventWrap}>{item.delivered}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={styles.preventWrap}>{item.opened}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={styles.preventWrap}>{item.clicked}</div>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 2 }}>
        <div className={styles.preventWrap}>{item.unsubscribed}</div>
      </Grid.Cell>
    </Grid>
  );
}
