/**
 * MiniCalendar Page Object Model
 *
 * Selectors:
 * - getDateCell
 *
 * Simulators:
 * - selectDate
 * - nextMonth
 * - previousMonth
 *
 * Other:
 * - hasSelectedMonth
 * - hasHighlightedDates
 *
 */
import { screen, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

/**
 * @returns The date cell element
 */
export const getDateCell = (
  date: Date,
  options: { highlighted?: boolean } = {},
) => component().getByText(new RegExp(`${accessibleLabel(date, options)}`));

/**
 * Selects a date in the calendar
 */
export const selectDate = (date: Date) => {
  userEvent.click(getDateCell(date));
};

/**
 * Clicks the next month button
 */
export const nextMonth = ({ clickCount = 1 } = {}) => {
  clickCount = Math.abs(clickCount);
  clickCount = clickCount === 0 ? 1 : clickCount;

  userEvent.click(component().getByLabelText("Next month"), undefined, {
    clickCount,
  });
};

/**
 * Clicks the previous month button
 */
export const previousMonth = ({ clickCount = 1 } = {}) => {
  userEvent.click(component().getByLabelText("Previous month"), undefined, {
    clickCount,
  });
};

/**
 * @returns True if the calendar has the selected month
 */
export const hasSelectedMonth = (month: Month, year: number) => {
  component().getAllByText(`${month} ${year}`);
  return true;
};

/**
 * @returns True if the calendar has all the provided dates highlighted
 */
export const hasHighlightedDates = (...dates: Date[]) => {
  return dates.every(date => {
    return !!getDateCell(date, { highlighted: true });
  });
};

export const expectSelectedMonthToBe = (month: Month, year: number) => {
  expect(hasSelectedMonth(month, year)).toBe(true);
};

export const expectDatesToBeHighlighted = (...dates: Date[]) => {
  return dates.forEach(date => getDateCell(date, { highlighted: true }));
};

export const expectDatesNotToBeHighlighted = (...dates: Date[]) => {
  return dates.every(
    date =>
      component().queryByText(
        new RegExp(`${accessibleLabel(date, { highlighted: true })}`),
      ) === null,
  );
};

export const expectToHaveHighlightedDates = () => {
  expect(component().getAllByText(/, has availability/)).not.toHaveLength(0);
};

export const expectNotToHaveHighlightedDates = () => {
  expect(component().queryAllByText(/, has availability/)).toHaveLength(0);
};

function root() {
  return screen.getByTestId("scheduling-minicalendar");
}

function component() {
  return within(root());
}

type Month =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";

function accessibleLabel(
  date: Date,
  { highlighted }: { highlighted?: boolean } = {},
) {
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: "medium",
  });

  return `Choose ${formatter.format(date)}${
    highlighted ? ", has availability" : ""
  }`;
}
