import React, { type ChangeEvent, type ComponentType } from "react";

export interface RadioListItemProps {
  id: string;
  checked?: boolean;
  disabled?: boolean;

  name?: string;
  value?: string;

  onChange?(e: ChangeEvent<HTMLInputElement>): void;

  children?: React.ReactNode;
}

export const RadioListItem: ComponentType<RadioListItemProps> = props => {
  const { id, checked, disabled, name, value, onChange, children } = props;

  return (
    <li className="list-item">
      <div className="radio u-marginNone flexContent radio--circle">
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles -- Grandfathered error: Please fix if touching this code. */}
        <input
          id={id}
          name={name}
          value={value}
          type="radio"
          role="radio"
          onChange={onChange}
          checked={checked}
          aria-checked={checked}
          disabled={disabled}
        />
        <label htmlFor={id} className="radio-label u-block">
          <div className="row collapse align-middle">
            <div className="columns">{children}</div>
          </div>
        </label>
      </div>
    </li>
  );
};
