import type { ColumnDef, Row } from "@jobber/components/DataTable";
import { strFormatDate } from "@jobber/components/FormatDate";
import {
  disputeAmount,
  disputeCategory,
  disputeReason,
  getDisputeInvoiceOrQuoteNumber,
  renderDisputeStatus,
} from "jobber/features/PaymentDisputes/utils/disputeUtils";
import type {
  JobberPaymentsDispute,
  JobberPaymentsDisputeEdge,
} from "~/utilities/API/graphql";
import {
  INNER_CELL_WIDTH_SMALL,
  INNER_CELL_WIDTH_SMALLER,
  INNER_CELL_WIDTH_SMALLEST,
  columnHeaderLabels,
  paymentDisputeSortColumnKeys,
} from "./constants";

export const getColumnConfig = (): ColumnDef<JobberPaymentsDisputeEdge>[] => {
  return [
    {
      id: paymentDisputeSortColumnKeys.STATUS,
      header: columnHeaderLabels.status,
      accessorFn: row => row.node.status,
      cell: ({ row }: { row: Row<JobberPaymentsDisputeEdge> }) => {
        const dispute: JobberPaymentsDispute = row.original.node;
        return renderDisputeStatus(dispute.status, dispute.evidenceDueBy);
      },
      minSize: INNER_CELL_WIDTH_SMALL,
      maxSize: INNER_CELL_WIDTH_SMALL,
    },
    {
      id: paymentDisputeSortColumnKeys.DISPUTED_ON,
      accessorFn: row => strFormatDate(new Date(row.node.disputedAt)),
      header: columnHeaderLabels.disputedOn,
      minSize: INNER_CELL_WIDTH_SMALLER,
      maxSize: INNER_CELL_WIDTH_SMALLER,
    },
    {
      id: paymentDisputeSortColumnKeys.CLIENT,
      accessorFn: row => {
        const client = row.node.paymentRecord.client;
        if (!client) {
          return "—";
        }
        return client.name;
      },
      header: columnHeaderLabels.client,
      minSize: INNER_CELL_WIDTH_SMALLER,
      maxSize: INNER_CELL_WIDTH_SMALLER,
      enableSorting: false,
    },
    {
      id: paymentDisputeSortColumnKeys.INVOICE_QUOTE,
      accessorFn: row => {
        const invoice = row.node.paymentRecord.invoice;
        const quote = row.node.paymentRecord.quote;
        return getDisputeInvoiceOrQuoteNumber(invoice, quote);
      },
      header: columnHeaderLabels.invoiceQuote,
      minSize: INNER_CELL_WIDTH_SMALLER,
      maxSize: INNER_CELL_WIDTH_SMALLER,
      enableSorting: false,
    },
    {
      id: paymentDisputeSortColumnKeys.AMOUNT,
      accessorFn: row => disputeAmount(row.node.amount),
      header: columnHeaderLabels.amount,
      minSize: 0,
      maxSize: 5,
      enableSorting: false,
    },
    {
      id: paymentDisputeSortColumnKeys.REASON,
      accessorFn: row => disputeReason(row.node.reason),
      header: columnHeaderLabels.reason,
      minSize: INNER_CELL_WIDTH_SMALLER,
      maxSize: INNER_CELL_WIDTH_SMALL,
      enableSorting: false,
    },
    {
      id: paymentDisputeSortColumnKeys.TYPE,
      accessorFn: row => disputeCategory(row.node.category),
      header: columnHeaderLabels.type,
      minSize: INNER_CELL_WIDTH_SMALLEST,
      maxSize: INNER_CELL_WIDTH_SMALLER,
      enableSorting: false,
    },
  ];
};
