import { gql } from "@apollo/client";

const OWNERSHIP_FRAGMENT = gql`
  fragment WorkObjectGlobalOwnership on WorkObjectGlobalOwnership {
    owner {
      id
      name {
        full
      }
      email {
        raw
      }
    }
    ownershipType
    workObjectType
    possibleUsers {
      nodes {
        name {
          full
        }
        email {
          raw
        }
        id
      }
    }
    replyToOwners {
      nodes {
        ownershipType
        name
        user {
          id
        }
        email {
          raw
        }
      }
    }
    possibleReplyToOwners {
      nodes {
        ownershipType
        name
        user {
          id
        }
        email {
          raw
        }
      }
    }
  }
`;

export const WORK_OBJECT_GLOBAL_OWNER_FRAGMENT = gql`
  fragment workObjectGlobalOwner on WorkObjectGlobalOwnership {
    workObjectType
    owner {
      id
      fullName
    }
  }
`;

export const GLOBAL_OWNERSHIP_QUERY = gql`
  query workObjectGlobalOwnerships(
    $filter: WorkObjectGlobalOwnershipFilterAttributes
  ) {
    workObjectGlobalOwnerships(filter: $filter) {
      nodes {
        ...WorkObjectGlobalOwnership
      }
    }
    account {
      companyDetails {
        email {
          raw
        }
      }
    }
  }
  ${OWNERSHIP_FRAGMENT}
`;
