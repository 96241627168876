import React from "react";
import { Spinner } from "@jobber/components/Spinner";

interface SquatchWidgetProps {
  widgetType?: string;
}

export function SquatchWidget({ widgetType }: SquatchWidgetProps) {
  return (
    <squatch-embed widget={widgetType} data-testid="widget">
      <Spinner />
    </squatch-embed>
  );
}
