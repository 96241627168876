import React from "react";
import { Button } from "@jobber/components/Button";
import { type ActionProps, Menu } from "@jobber/components/Menu";

interface CustomFieldItemRowActionsProps {
  actions: Omit<ActionProps, "shouldFocus">[];
}

export function CustomFieldItemRowActions({
  actions,
}: CustomFieldItemRowActionsProps) {
  return (
    <Menu
      items={[
        {
          actions: actions,
        },
      ]}
      activator={
        <Button
          icon="more"
          variation="subtle"
          type="tertiary"
          ariaLabel="More actions"
        />
      }
    />
  );
}
