import React from "react";
import { gql } from "@apollo/client";
import moment from "moment";
import { useIntl } from "react-intl";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type { InsightListData } from "jobber/dashboard/components/InsightCard/InsightCard";
import type { DoubleBookingDataFragment } from "~/utilities/API/graphql";
import { messages } from "./messages";

export const DoubleBookingData = gql`
  fragment DoubleBookingData on DoubleBookings {
    doubleBookingEntry {
      dateTime
      user {
        id
        fullName
      }
    }
    count
  }
`;

export interface DoubleBookingProps {
  readonly doubleBookings?: DoubleBookingDataFragment;
  trackEvents: boolean;
}

export function DoubleBookingInsight({
  doubleBookings,
  trackEvents,
}: DoubleBookingProps) {
  const [calendarGridPath] = useUrls("calendarGridPath");
  let formattedData: InsightListData[] | undefined = [];
  formattedData = doubleBookings?.doubleBookingEntry.map(entry => {
    const doubleBookingDate = new Date(entry.dateTime);
    const dateFormat = "MMM DD, YYYY HH:mmA";
    // Refactor this code as part of https://jobber.atlassian.net/browse/JOB-27662
    const formattedDate = moment(doubleBookingDate).format(dateFormat);
    return {
      id: entry.user.id + formattedDate,
      icon: "user",
      content: entry.user.fullName,
      caption: formattedDate,
      url: `${calendarGridPath}${urlFormattedDate(entry.dateTime)}`,
    };
  });

  const { formatMessage } = useIntl();

  return (
    <>
      <InsightCard
        title={formatMessage(messages.doubleBookings)}
        description={formatMessage(messages.doubleBookingsDescription)}
        actionLabel={formatMessage(messages.viewSchedule)}
        actionUrl={`${calendarGridPath}${urlFormattedDate(
          doubleBookings?.doubleBookingEntry[0]?.dateTime,
        )}`}
        listItemData={formattedData}
        hideEmptyCard={doubleBookings?.count === 0}
        trackEvents={trackEvents}
      />
    </>
  );
}

function urlFormattedDate(dateString: string | undefined) {
  if (dateString) {
    const date = new Date(dateString);
    return `/${date.getUTCFullYear()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}`;
  }
}
