import { differenceInMinutes } from "date-fns";
import type { SchedulingAvailabilityResponse } from "./hooks";
import type { ScheduleDataLog } from "./types";

export const formatScheduleData = ({
  endDateTime,
  isMultiDay,
  recommendationsResponse,
  scheduleLater,
  selectedAssignees,
  startDateTime,
  visitDuration,
}: {
  endDateTime: Date | undefined;
  isMultiDay: boolean;
  recommendationsResponse: SchedulingAvailabilityResponse;
  scheduleLater: boolean;
  selectedAssignees: string[] | [];
  startDateTime: Date | undefined;
  visitDuration: number;
}) => {
  if (!recommendationsResponse.recommendations) return undefined;

  const assignees = getAssignees({
    recommendationsResponse,
    scheduleLater,
    selectedAssignees,
  });

  const dateTime = getDateTime({
    endDateTime,
    isMultiDay,
    recommendationsResponse,
    scheduleLater,
    selectedAssignees,
    startDateTime,
  });

  const jobDuration = getJobDuration({
    visitDuration,
    startDateTime,
    endDateTime,
    scheduleLater,
    selectedAssignees,
  });

  return {
    assignees,
    dateTime,
    jobDuration,
    multiDay: isMultiDay,
    unScheduled: scheduleLater,
  };
};

const getAssignees = ({
  recommendationsResponse,
  scheduleLater,
  selectedAssignees,
}: {
  recommendationsResponse: SchedulingAvailabilityResponse;
  scheduleLater: boolean;
  selectedAssignees: string[] | [];
}) => {
  const recommendations = recommendationsResponse?.recommendations;

  return {
    selectedAssignees: selectedAssignees,
    recommendedAssignee: {
      soonest: scheduleLater
        ? undefined
        : recommendations?.soonest?.recommendedAssignee?.id,
      closest: scheduleLater
        ? undefined
        : recommendations?.closest?.recommendedAssignee?.id,
      others: scheduleLater
        ? undefined
        : recommendations?.schedulingRecommendations?.map(
            recommended => recommended.recommendedAssignee?.id,
          ),
    },
  };
};

const getDateTime = ({
  endDateTime,
  isMultiDay,
  recommendationsResponse,
  scheduleLater,
  selectedAssignees,
  startDateTime,
}: {
  endDateTime: Date | undefined;
  isMultiDay: boolean;
  recommendationsResponse: SchedulingAvailabilityResponse;
  scheduleLater: boolean;
  selectedAssignees: string[] | [];
  startDateTime: Date | undefined;
}) => {
  const dateTime: Pick<
    ScheduleDataLog["dateTime"],
    "selectedDateTime" | "deltas"
  > = {
    selectedDateTime: {
      startDateTime: undefined,
      endDateTime: undefined,
    },
    deltas: {
      soonest: undefined,
      closest: undefined,
      nearestToUserSelected: {
        diff: undefined,
        index: undefined,
      },
    },
  };

  if (isMultiDay || scheduleLater || selectedAssignees.length > 1) {
    return dateTime;
  }

  const deltas = calculateDeltas({
    recommendationsResponse,
    startDateTime,
    selectedAssignees,
    deltas: dateTime.deltas,
  }) as DeltasType;

  dateTime.selectedDateTime.startDateTime = startDateTime;
  dateTime.selectedDateTime.endDateTime = endDateTime;
  dateTime.deltas = deltas;

  return dateTime;
};

interface DeltasType {
  soonest: number | undefined;
  closest: number | undefined;
  nearestToUserSelected: {
    diff: number | undefined;
    index: number | undefined;
  };
}

const calculateDeltas = ({
  recommendationsResponse,
  startDateTime,
  selectedAssignees,
  deltas,
}: {
  recommendationsResponse: SchedulingAvailabilityResponse;
  selectedAssignees: string[] | [];
  startDateTime: Date | undefined;
  deltas: DeltasType;
}) => {
  if (selectedAssignees.length > 1) {
    return { deltas };
  }

  const recommendations = recommendationsResponse?.recommendations;

  if (recommendations.soonest && startDateTime) {
    deltas.soonest = differenceInMinutes(
      recommendations.soonest.recommendedStartAt,
      startDateTime,
    );
  }

  if (recommendations.closest && startDateTime) {
    deltas.closest = differenceInMinutes(
      recommendations.closest.recommendedStartAt,
      startDateTime,
    );
  }

  const selectedAssignee = selectedAssignees
    ? Array.from(selectedAssignees!)
    : [];

  if (
    recommendations.schedulingRecommendations &&
    startDateTime &&
    selectedAssignee.length
  ) {
    recommendations.schedulingRecommendations
      .filter(recommendation => {
        if (
          recommendation.recommendedAssignee?.id === selectedAssignee[0] &&
          recommendation.recommendedStartAt
        ) {
          return recommendation;
        }
      })
      .map((recommendation, index) => {
        if (startDateTime > recommendation.recommendedStartAt) {
          deltas.nearestToUserSelected.diff = differenceInMinutes(
            recommendation.recommendedStartAt,
            startDateTime,
          );
          deltas.nearestToUserSelected.index = index;
        }
        return;
      });
  }

  return deltas;
};

const getJobDuration = ({
  endDateTime,
  scheduleLater,
  selectedAssignees,
  startDateTime,
  visitDuration,
}: {
  endDateTime: Date | undefined;
  scheduleLater: boolean;
  selectedAssignees: string[] | [];
  startDateTime: Date | undefined;
  visitDuration: number;
}) => {
  const jobDuration: Pick<
    ScheduleDataLog["jobDuration"],
    "selected" | "created"
  > = {
    selected: 0,
    created: 0,
  };
  const createdJobDuration = () => {
    if (
      !startDateTime ||
      !endDateTime ||
      scheduleLater ||
      selectedAssignees.length > 1
    ) {
      return 0;
    }
    return differenceInMinutes(endDateTime, startDateTime);
  };

  jobDuration.selected = visitDuration;
  jobDuration.created = createdJobDuration();

  return jobDuration;
};
