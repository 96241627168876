import { type ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import type { GraphQLError } from "graphql";
import type {
  CampaignSendTestEmailMutation,
  CampaignSendTestEmailMutationVariables,
  MutationErrors,
} from "~/utilities/API/graphql";
import { CAMPAIGN_SEND_TEST } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export interface UserErrors {
  userErrors: MutationErrors[];
  mutationErrors: readonly GraphQLError[] | undefined;
}

export interface useSendTestCampaignReturn {
  loading: boolean;
  campaignSendTestEmail(
    input?: CampaignSendTestEmailMutationVariables,
  ): Promise<UserErrors>;
  data?: CampaignSendTestEmailMutation | null;
  error?: ApolloError;
}

export function useSendTestCampaign(): useSendTestCampaignReturn {
  const [sendTestEmail, { loading, error, data }] = useMutation<
    CampaignSendTestEmailMutation,
    CampaignSendTestEmailMutationVariables
  >(CAMPAIGN_SEND_TEST);

  const campaignSendTestEmail = useCallback(
    async (input: CampaignSendTestEmailMutationVariables) => {
      const mutationResult = await sendTestEmail({
        variables: input,
      }).catch(e => {
        return {
          data: undefined,
          errors: e,
        };
      });

      const userErrors =
        mutationResult.data?.campaignSendTestEmail.userErrors ?? [];
      return {
        userErrors: userErrors,
        mutationErrors: mutationResult.errors,
      };
    },
    [sendTestEmail],
  );

  return {
    campaignSendTestEmail,
    data,
    loading,
    error,
  };
}
