import { Content } from "@jobber/components/Content";
import React, { useReducer, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import {
  useAccountingCodesQuery,
  useActiveUsersQuery,
  useExpenseDeleteMutation,
  useExpenseEditMutation,
} from "jobber/workOrders/components/JobCost/hooks";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { File, User } from "~/utilities/API/graphql";
import { getUTCFromAccountZonedTime } from "jobber/workOrders/components/JobCost/utils";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import styles from "./ExpenseModal.module.css";
import {
  ExpenseModalReducer,
  type ExpenseModalState,
} from "./ExpenseModalReducer";
import { ExpenseModalContent } from "./ExpenseModalContent";
import { messages } from "./messages";

interface EditExpenseModalProps {
  jobId: string;
  expenseEntryId: string;
  initialState: ExpenseModalState;
  initialReceipt?: File;
  closeModal(): void;
  currentUser: User;
}

export function EditExpenseModal({
  initialState,
  initialReceipt,
  jobId,
  expenseEntryId,
  closeModal,
  currentUser,
}: EditExpenseModalProps) {
  const account = useAccount();
  const { accountingCodes } = useAccountingCodesQuery();
  const { canViewJobCosts, canViewExpenses } = useEditExpenseAuthorizations();
  const { users } = useActiveUsersQuery(canViewJobCosts);
  const { formatMessage } = useIntl();

  const [deleteExpenseModalIsOpen, setDeleteExpenseModalIsOpen] =
    useState(false);

  const { handleSubmit } = useExpenseEditMutation(
    jobId,
    expenseEntryId,
    canViewJobCosts,
    canViewExpenses,
    closeModal,
  );

  const { handleDeleteExpense } = useExpenseDeleteMutation(
    jobId,
    expenseEntryId,
    canViewJobCosts,
    closeModal,
  );

  const [state, dispatch] = useReducer(ExpenseModalReducer, {
    ...initialState,
    receiptUrl: initialReceipt?.thumbnailUrl,
  });

  return (
    <Content spacing="small">
      <Form onSubmit={handleFormSubmit}>
        <Content>
          <ExpenseModalContent
            accountingCodes={accountingCodes}
            users={users}
            currencySymbol={account.currencySymbol}
            state={state}
            initialReceipt={initialReceipt}
            dispatch={dispatch}
            currentUser={currentUser}
          />
          <div className={styles.expenseModalButtonGroup}>
            <Button
              label={formatMessage(jobCostMessages.delete)}
              type="secondary"
              variation="destructive"
              onClick={() => {
                setDeleteExpenseModalIsOpen(true);
              }}
            />
            <div className={styles.rightButtons}>
              <Button
                label={formatMessage(jobCostMessages.cancel)}
                variation="subtle"
                onClick={closeModal}
              />
              <Button label={formatMessage(messages.saveExpense)} submit />
            </div>
          </div>
        </Content>
      </Form>
      <ConfirmationModal
        title={formatMessage(messages.deleteExpenseTitle)}
        message={formatMessage(messages.deleteExpenseMessage, {
          expenseName: state.itemName,
        })}
        confirmLabel={formatMessage(jobCostMessages.delete)}
        variation="destructive"
        open={deleteExpenseModalIsOpen}
        onConfirm={handleDeleteExpense}
        onRequestClose={() => setDeleteExpenseModalIsOpen(false)}
      />
    </Content>
  );

  function handleFormSubmit() {
    const {
      itemName,
      accountingCode,
      reimburseTo,
      description,
      expenseDate,
      total,
      receiptUrl,
    } = state;

    const input = {
      title: itemName,
      accountingCodeId: accountingCode,
      reimbursableToId: reimburseTo,
      description: description,
      date: (
        getUTCFromAccountZonedTime(expenseDate, account.timezone) || new Date()
      ).toISOString(),
      receiptUrl,
      total: total,
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit(input);
    closeModal();
  }
}

function useEditExpenseAuthorizations() {
  const { can } = useAuthorization();
  const canViewJobCosts = can("view", "JobCosts");
  const canViewExpenses = can("view", "Expenses");
  return {
    canViewJobCosts,
    canViewExpenses,
  };
}
