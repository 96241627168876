/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Card, Content } from "@jobber/components";
import { useQuery } from "@apollo/client";
import type {
  GetClientTagLabelsQuery,
  ProductsQuery,
} from "~/utilities/API/graphql";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { defaultTriggerFields } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/utils/defaultTriggerFields";
import {
  actionToIcon,
  triggerToIcon,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/utils/utilities";
import { GET_CLIENT_TAG_LABELS } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/ClientTagCriterion/ClientTagCriterion.graphql";
import {
  containsOptions,
  includesOptions,
  quoteStatusOptions,
  recurringOptions,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/utils/dropdownOptions";
import { useRuleBuilder } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/hooks/useRuleBuilder";
import type {
  AllConditionTypes,
  AutomationRuleWithParsedConditions,
  CreateRuleConditionTypes,
} from "./types";
import { RuleBlock } from "./RuleBlock";
import { RuleConditionBlock } from "./RuleConditionBlock";
import { FieldType } from "./RuleExpression";
import { RuleConditionAddButton } from "./RuleConditionLine";
import type {
  AddConditionAction,
  RemoveConditionAction,
  UpdateConditionAction,
} from "./RuleBuilderReducer";

interface RuleBuilderProps {
  selectedAutomation: AutomationRuleWithParsedConditions;
  triggerArguments: {
    argument: number;
    setArgument: (val: number) => void;
  };
  actionArguments: {
    argument: string;
    setArgument: (val: string) => void;
  };
  conditions: AllConditionTypes[];
  dispatch: React.Dispatch<
    AddConditionAction | UpdateConditionAction | RemoveConditionAction
  >;
  hasSubmitted?: boolean;
}

export function RuleBuilder({
  selectedAutomation,
  triggerArguments,
  actionArguments,
  conditions,
  dispatch,
  hasSubmitted,
}: RuleBuilderProps) {
  const {
    conditionOptions,
    conditionTypesOptions,
    removeRuleCondition,
    resetCondition,
    getExpressionTextForTrigger,
    getExpressionTextForCampaignCondition,
    getExpressionTextForAction,
  } = useRuleBuilder(dispatch);
  const { data: tagData } = useQuery<GetClientTagLabelsQuery>(
    GET_CLIENT_TAG_LABELS,
  );

  const { data: lineItemData } = useQuery<ProductsQuery>(
    PRODUCT_OR_SERVICES_QUERY,
  );

  const clientTagOptions = tagData?.clientTagUniqueLabels?.labels?.map(
    label => ({
      value: label,
      label,
    }),
  ) ?? [{ value: "", label: "" }];

  const lineItemOptions = lineItemData?.products.nodes.map(label => ({
    value: label.id,
    label: label.name,
  })) ?? [{ value: "", label: "" }];

  const triggerFields = {
    ...defaultTriggerFields(selectedAutomation.trigger),
    months: {
      ...defaultTriggerFields(selectedAutomation.trigger).months,
      ...triggerArguments,
      readOnly: false,
    },
    closeOnPendingQuotesOptions: {
      ...defaultTriggerFields(selectedAutomation.trigger)
        .closeOnPendingQuotesOptions,
      ...triggerArguments,
      readOnly: false,
    },
  };

  return (
    <div>
      <Card>
        <RuleBlock
          iconName={triggerToIcon(selectedAutomation.trigger.task)}
          text={getExpressionTextForTrigger(
            selectedAutomation.trigger,
            selectedAutomation.name,
          )}
          fields={triggerFields}
        />
      </Card>
      <Content spacing="base">
        <div>
          {conditions.map((condition, index) => {
            return (
              <RuleConditionBlock
                key={index}
                rowKey={index}
                lastBlock={index === conditions.length - 1}
                removeRuleCondition={() => removeRuleCondition(index)}
                text={getExpressionTextForCampaignCondition(condition)}
                hasSubmitted={hasSubmitted}
                isNonDeletable={condition?.isNonDeletable}
                fields={{
                  client_tag: {
                    argument: condition.arguments.type,
                    fieldType: FieldType.DROPDOWN,
                    options: conditionTypesOptions(
                      selectedAutomation.trigger.task,
                    ),
                    readOnly: false,
                    setArgument: (value: CreateRuleConditionTypes) =>
                      resetCondition(value, index),
                  },
                  contains: {
                    argument: condition.arguments.fields.contains as boolean,
                    fieldType: FieldType.DROPDOWN,
                    options: containsOptions,
                    readOnly: false,
                    setArgument: (value: boolean) =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { contains: value },
                      }),
                  },
                  tag: {
                    argument: condition.arguments.fields.tag as string,
                    fieldType: FieldType.COMBOBOX,
                    options: clientTagOptions,
                    readOnly: false,
                    setArgument: value =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { tag: value },
                      }),
                  },
                  line_item: {
                    argument: condition.arguments.type,
                    fieldType: FieldType.DROPDOWN,
                    options: conditionTypesOptions(
                      selectedAutomation.trigger.task,
                    ),
                    readOnly: false,
                    setArgument: (value: CreateRuleConditionTypes) =>
                      resetCondition(value, index),
                  },
                  includes: {
                    argument: condition.arguments.fields.includes as boolean,
                    fieldType: FieldType.DROPDOWN,
                    options: includesOptions,
                    readOnly: false,
                    setArgument: value =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { includes: value },
                      }),
                  },
                  item: {
                    argument: condition.arguments.fields.item as string,
                    fieldType: FieldType.COMBOBOX,
                    options: lineItemOptions,
                    readOnly: false,
                    setArgument: value =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { item: value },
                      }),
                  },
                  job_type: {
                    argument: condition.arguments.type,
                    fieldType: FieldType.DROPDOWN,
                    options: conditionTypesOptions(
                      selectedAutomation.trigger.task,
                    ),
                    readOnly: false,
                    setArgument: (value: CreateRuleConditionTypes) =>
                      resetCondition(value, index),
                  },
                  type: {
                    argument: condition.arguments.fields.type as string,
                    fieldType: FieldType.DROPDOWN,
                    options: recurringOptions,
                    readOnly: false,
                    setArgument: value =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { type: value },
                      }),
                  },
                  status: {
                    argument: condition.arguments.fields.status as string,
                    fieldType: FieldType.DROPDOWN,
                    options: quoteStatusOptions,
                    readOnly: false,
                    setArgument: value =>
                      dispatch({
                        type: "updateCondition",
                        index,
                        payload: { status: value },
                      }),
                  },
                }}
              />
            );
          })}
          <RuleConditionAddButton
            itemActions={conditionOptions(selectedAutomation.trigger.task)}
          />
        </div>
        <Card>
          <RuleBlock
            iconName={actionToIcon(selectedAutomation.action.task)}
            text={getExpressionTextForAction(selectedAutomation.action)}
            fields={{
              notification: {
                ...actionArguments,
                fieldType: FieldType.MODAL,
              },
            }}
          />
        </Card>
      </Content>
    </div>
  );
}
