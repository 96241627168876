import React, { useCallback, useEffect, useState } from "react";
import styles from "./XStateInspector.module.css";

const DEFAULT_IFRAME_HEIGHT = 600;
const MINIMUM_IFRAME_HEIGHT = 150;

const xstateIcon = (
  <svg
    viewBox="101.3919 28.5661 167.8159 167.0002"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.xstateIcon}
  >
    <path
      d="M 106.363 27 L 139.827 27.272 C 143.078 27.298 146.137 28.812 148.131 31.38 L 267.691 185.436 C 270.401 188.924 267.911 194 263.499 194 L 228.464 194 C 225.154 194 222.032 192.457 220.024 189.828 L 186.679 144.193 L 149.774 189.97 C 147.759 192.516 144.691 194 141.444 194 L 106.32 194 C 101.883 194 99.403 188.878 102.155 185.397 L 160.698 111.253 L 102.103 35.535 C 99.423 32.025 101.949 26.966 106.366 27.003 L 106.363 27 Z M 263.454 27 C 267.914 26.966 270.427 32.108 267.661 35.603 L 215.422 101.609 C 211.696 98.677 182.778 74.469 210.677 42.053 C 210.677 42.053 212.86 39.499 215.112 36.858 L 215.548 36.347 L 215.983 35.837 C 217.27 34.332 218.551 32.821 219.825 31.304 C 222.584 27.965 224.855 27.299 228.072 27.274 L 263.454 27 Z"
      transform="matrix(1, 0, 0, 1, 0.3909670114517212, 1.566282033920288)"
    />
  </svg>
);

const inspectorUrl = "https://statecharts.io/inspect";
const inspectorContainer = "xstateInspectorContainer";

export const getXStateIFrame = (): HTMLIFrameElement | null => {
  return document.querySelector<HTMLIFrameElement>("iframe[data-xstate]");
};

export const XStateInspector = (): JSX.Element => {
  useEffect(() => {
    const { inspect } = require("@xstate/inspect");

    inspect({
      url: inspectorUrl,
      iframe: getXStateIFrame,
    });
  });

  return <></>;
};

export const XStateInspectorLauncher = () => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);

  const toggleIframeVisibility = useCallback(() => {
    const inspector = document.getElementById(inspectorContainer);
    if (inspector) {
      inspector.style.display = isIframeVisible ? "none" : "flex";
      setIsIframeVisible(!isIframeVisible);
      if (isIframeVisible) {
        inspector.style.height = DEFAULT_IFRAME_HEIGHT + "px";
      }
    }
  }, [isIframeVisible]);

  useResizeListener();

  useEffect(() => {
    const handleToolbarToggled = () => {
      const inspector = document.getElementById(inspectorContainer);
      if (inspector) {
        inspector.style.display = "none";
        setIsIframeVisible(false);
      }
    };
    const devToolToggle = document.querySelector(
      ".js-developmentToolbar-toggle",
    );
    if (devToolToggle) {
      devToolToggle.addEventListener("click", handleToolbarToggled);
    }

    return () => {
      if (devToolToggle) {
        devToolToggle.removeEventListener("click", handleToolbarToggled);
      }
    };
  }, []);

  return (
    <div>
      <button
        className="button"
        onClick={toggleIframeVisibility}
        title="XState Inspector"
      >
        {xstateIcon}
      </button>
    </div>
  );
};

const useResizeListener = () => {
  const inspector = document.getElementById(inspectorContainer);

  useEffect(() => {
    let mPosition = 0;
    const overlay = document.createElement("div");
    overlay.id = "xstateOverlay";

    function resize(e: MouseEvent) {
      if (inspector === null) {
        return;
      }
      const dx = mPosition - e.y;

      const delta = parseInt(getComputedStyle(inspector, "").height, 10) + dx;
      if (delta < MINIMUM_IFRAME_HEIGHT) {
        return;
      }

      mPosition = e.y;
      inspector.style.height =
        parseInt(getComputedStyle(inspector, "").height, 10) + dx + "px";
    }

    const handleMouseDown = (e: MouseEvent) => {
      mPosition = e.y;
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", resize, false);
      if (inspector) {
        inspector.appendChild(overlay);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", resize);
      // Remove the overlay when the mouse button is released
      if (inspector) {
        inspector.removeChild(overlay);
      }
    };

    if (inspector) {
      inspector.addEventListener("mousedown", handleMouseDown);
      inspector.addEventListener("contextmenu", handleMouseUp);
      inspector.addEventListener("drag", handleMouseUp);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mouseup", handleMouseUp, false);
      if (inspector) {
        inspector.removeEventListener("mousedown", handleMouseDown);
        inspector.removeEventListener("contextmenu", handleMouseUp);
        inspector.removeEventListener("drag", handleMouseUp);
      }
    };
  }, [inspector]);
};
