import React, { useEffect, useState } from "react";
import { FormatFile } from "@jobber/components/FormatFile";
import type { FileRejection } from "react-dropzone";
import { InputImage } from "./components/InputImage/InputImage";
import { messages } from "./messages";

interface InputImageWithDisplayProps {
  value: File;
  onChange: (file?: File) => void;
  setError: (message: string) => void;
  testId?: string;
}
export function InputImageWithDisplay({
  value,
  onChange,
  setError,
  testId,
}: InputImageWithDisplayProps): JSX.Element {
  const frontImage = useImageFileDataURL(value);
  const displayMode = !!value && !!frontImage;

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    let fileTooLarge = false;
    onChange(acceptedFiles?.[0]);
    fileRejections.forEach(file => {
      file.errors.forEach(err => {
        if (err.code === "file-too-large") {
          setError(messages.fileTooLarge.message);
          //in the event of having both a file too large and an invalid type, we want to just show file too large
          fileTooLarge = true;
          return;
        } else if (err.code === "file-invalid-type" && !fileTooLarge) {
          setError(messages.fileInvalidType.message);
        }
      });
    });
  };

  return (
    <>
      {!displayMode && (
        <InputImage
          handleDrop={handleDrop}
          maxFileSize={16000000}
          testId={testId}
        />
      )}

      {displayMode && (
        <>
          <FormatFile
            display="expanded"
            displaySize="base"
            key={value.name}
            file={{
              key: value.name,
              type: value.type,
              name: value.name,
              size: value.size,
              progress: 1,
              src: async () => frontImage,
            }}
            onDelete={() => onChange(undefined)}
          />
        </>
      )}
    </>
  );
}

function useImageFileDataURL(file?: File) {
  const [dataUrl, setDataUrl] = useState<string>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setDataUrl(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setDataUrl(undefined);
    }
  }, [file]);

  return dataUrl;
}
