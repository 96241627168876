import { screen, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

export function checkCalendarButton() {
  return component().getByText("Find a time");
}

export function closeSchedulingAssistantButton() {
  return component().getByText("Close");
}

export function showSchedulingAssistant() {
  userEvent.click(checkCalendarButton());
}

export function hideSchedulingAssistant() {
  userEvent.click(closeSchedulingAssistantButton());
}

export function schedulingAssistant() {
  return screen.getByTestId("scheduling-assistant");
}

function component() {
  return within(root());
}

function root() {
  return screen.getByTestId("scheduling-date-inputs");
}
