import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectATemplate: {
    defaultMessage: "Select a template",
    description: "Select a template",
    id: "campaigns.templates.selectATemplate",
  },
  templateChangeConfirmationTitle: {
    defaultMessage: "Change template?",
    description: "Change Template?",
    id: "campaigns.templates.templateChangeConfirmationTitle",
  },
  templateChangeConfirmationButtonLabel: {
    defaultMessage: "Confirm",
    description: "Change Template",
    id: "campaigns.templates.templateChangeConfirmationButtonLabel",
  },
  templateChangeConfirmationMessage: {
    defaultMessage:
      "Selecting a new template will clear the existing content for this campaign.",
    id: "campaigns.templates.templateChangeConfirmationMessage",
    description: "Confirmation message for changing template",
  },
  oneOffTemplateSubTitle: {
    id: "campaigns.templates.oneOffTemplateSubTitle",
    defaultMessage: "One-off campaigns",
    description: "One-off campaigns",
  },
  automatedTemplateSubtitle: {
    id: "campaigns.templates.automatedTemplateSubtitle",
    defaultMessage: "Automated campaigns",
    description: "Automated campaigns",
  },
  unableToSwitch: {
    id: "campaigns.templates.unableToSwitch",
    defaultMessage:
      "Unable to switch between automated and one-off campaigns. To switch campaign type, start a new campaign.",
    description: "Unable to switch tool tip",
  },
  unableToSwitchAutomated: {
    id: "campaigns.templates.unableToSwitchAutomated",
    defaultMessage:
      "Unable to switch template once a campaign has been activated. To switch template, start a new campaign.",
    description: "Unable to switch automated campaign template tool tip",
  },
});
