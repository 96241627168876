import React from "react";
import type { Translations } from "~/utilities/contexts/internal/TranslationContext";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";

export function createBulletSection(
  bulletTitle: keyof Translations,
  bulletDetails: keyof Translations,
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useTranslation();
  const title = t(bulletTitle);
  const details = [];
  const indentMargin = { marginLeft: "var(--space-large)" };
  let bullet;

  let i = 0;
  for (i = 0; i < t(bulletDetails).length; i = i + 1) {
    if (Array.isArray(t(bulletDetails)[i])) {
      bullet = (
        <li style={indentMargin} key={i}>
          {t(bulletDetails)[i]}
        </li>
      );
    } else {
      bullet = <li key={i}>{t(bulletDetails)[i]}</li>;
    }
    details.push(bullet);
  }
  return [title, details];
}
