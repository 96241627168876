import { useContext } from "react";
import { useQuery } from "@apollo/client";
import type { UserPermissionsQuery } from "~/utilities/API/graphql";
import { AuthorizationContext } from "./AuthorizationContext";
import { USER_PERMISSIONS_QUERY } from "./graphql";

export function AuthorizationContextProvider(props: React.PropsWithChildren) {
  const { children } = props;
  /* TODO: Ideally we would store these permissions so we don't need to query for them each time a React component
   *   is instantiated */
  const { data, loading } = useQuery<UserPermissionsQuery>(
    USER_PERMISSIONS_QUERY,
  );

  return (
    <AuthorizationContext.Provider
      value={{
        loading,
        isAdmin: data?.user?.isAccountAdmin || false,
        permissions: data?.user?.permissions.nodes || [],
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}

/*
 * We need to refactor and recheck all instances of useAuthorization(), before
 * applying a check gate to throw an error if the hook is used without
 * being wrapped by <AuthorizationContextProvider>.
 *
 * See implementation at useIntegrationStateContext() hook for more details.
 */
export const useAuthorizationContext = () => useContext(AuthorizationContext);
