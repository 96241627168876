// Growth Feature: Reviews Engagement
// This has been hardcoded on the frontend so that we can move quickly and ship value fast. If we decide to
// keep this feature or grow it we can pull this data from an up to date table in the back end.
export const INDUSTRY_AVERAGES = {
  // Cleaning Industry
  BIN_CLEANING: {
    name: "Bin Cleaning",
    rating: 4.4,
    numReviews: 140,
  },
  CARPET_CLEANING: {
    name: "Carpet Cleaning",
    rating: 4.4,
    numReviews: 140,
  },
  COMMERCIAL_CLEANING: {
    name: "Commercial Cleaning",
    rating: 4.4,
    numReviews: 140,
  },
  PRESSURE_WASHING_SERVICE: {
    name: "Pressure Washing Service",
    rating: 4.4,
    numReviews: 140,
  },
  RESIDENTIAL_CLEANING: {
    name: "Residential Cleaning",
    rating: 4.4,
    numReviews: 140,
  },
  WINDOW_WASHING: {
    name: "Window Washing",
    rating: 4.4,
    numReviews: 140,
  },
  // Green Industry
  ARBORIST_TREE_CARE: {
    name: "Arborist / Tree Care",
    rating: 4.6,
    numReviews: 110,
  },
  LANDSCAPING_CONTRACTOR: {
    name: "Landscaping Contractor",
    rating: 4.6,
    numReviews: 110,
  },
  LAWN_CARE_LAWN_MAINTENANCE: {
    name: "Lawn Care & Lawn Maintenance",
    rating: 4.6,
    numReviews: 110,
  },
  // Hi-Tech
  COMPUTERS_IT: {
    name: "Computers & IT",
    rating: 4.5,
    numReviews: 220,
  },
  HOME_THEATER: {
    name: "Home Theater",
    rating: 4.5,
    numReviews: 220,
  },
  SECURITY_AND_ALARM: {
    name: "Security and Alarm",
    rating: 4.5,
    numReviews: 220,
  },
  // Trade
  CONSTRUCTION_CONTRACTING: {
    name: "Construction & Contracting",
    rating: 4.8,
    numReviews: 270,
  },
  ELECTRICAL_CONTRACTOR: {
    name: "Electrical Contractor",
    rating: 4.8,
    numReviews: 270,
  },
  HVAC: {
    name: "HVAC",
    rating: 4.8,
    numReviews: 270,
  },
  LOCKSMITH: {
    name: "Locksmith",
    rating: 4.8,
    numReviews: 270,
  },
  MECHANICAL_SERVICE: {
    name: "Mechanical Service",
    rating: 4.8,
    numReviews: 270,
  },
  PLUMBING: {
    name: "Plumbing",
    rating: 4.8,
    numReviews: 270,
  },
  // Other
  APPLIANCE_REPAIR: {
    name: "Appliance Repair",
    rating: 4.5,
    numReviews: 190,
  },
  FLOORING_SERVICE: {
    name: "Flooring Service",
    rating: 4.5,
    numReviews: 190,
  },
  HANDYMAN: {
    name: "Handyman",
    rating: 4.5,
    numReviews: 190,
  },
  JUNK_REMOVAL: {
    name: "Junk Removal",
    rating: 4.5,
    numReviews: 190,
  },
  PAINTING: {
    name: "Painting",
    rating: 4.5,
    numReviews: 190,
  },
  PEST_CONTROL: {
    name: "Pest Control",
    rating: 4.5,
    numReviews: 190,
  },
  POOL_AND_SPA_SERVICE: {
    name: "Pool and Spa Service",
    rating: 4.5,
    numReviews: 190,
  },
  RENOVATIONS: {
    name: "Renovations",
    rating: 4.5,
    numReviews: 190,
  },
  ROOFING_SERVICE: {
    name: "Roofing Service",
    rating: 4.5,
    numReviews: 190,
  },
  SNOW_REMOVAL: {
    name: "Snow Removal",
    rating: 4.5,
    numReviews: 190,
  },
  OTHER: {
    name: "Other",
    rating: 4.5,
    numReviews: 190,
  },
};
