import {
  type FetchResult,
  type MutationResult,
  useMutation,
} from "@apollo/client";
import type { XOR } from "ts-xor";
import { SELF_SERVE_SETTINGS_QUERY } from "jobber/settings/selfServeBookings/views/SettingsPage/hooks/graphql";
import type {
  CreateServiceAreaMutation,
  CreateServiceAreaMutationVariables,
  EditServiceAreaMutation,
  EditServiceAreaMutationVariables,
} from "~/utilities/API/graphql";
import { CREATE_SERVICE_AREA, EDIT_SERVICE_AREA } from "./graphql";

interface SaveCircularServiceAreaProps {
  radius: number;
  latitude: number;
  longitude: number;
}

interface SaveServiceAreaProps {
  name?: string;
  shape: { circle: SaveCircularServiceAreaProps };
}

type SaveMutation = XOR<CreateServiceAreaMutation, EditServiceAreaMutation>;
type SaveMutationVariables = XOR<
  CreateServiceAreaMutationVariables,
  EditServiceAreaMutationVariables
>;

export function useSaveServiceArea(
  existingServiceAreaId?: string,
): [
  (serviceArea: SaveServiceAreaProps) => Promise<FetchResult<SaveMutation>>,
  MutationResult<SaveMutation>,
] {
  const isNewServiceArea = !existingServiceAreaId;
  const [mutateServiceArea, mutationResult] = useMutation<
    SaveMutation,
    SaveMutationVariables
  >(isNewServiceArea ? CREATE_SERVICE_AREA : EDIT_SERVICE_AREA, {
    refetchQueries: isNewServiceArea ? [SELF_SERVE_SETTINGS_QUERY] : [],
    awaitRefetchQueries: true,
  });

  function saveServiceArea(serviceArea: SaveServiceAreaProps) {
    return mutateServiceArea({
      variables: {
        serviceAreaId: existingServiceAreaId,
        name: serviceArea.name,
        shape: serviceArea.shape,
      },
    });
  }

  return [saveServiceArea, mutationResult];
}
