import { gql } from "@apollo/client";
import { notificationFieldsFragment } from "jobber/settings/notifications/notificationFieldsFragment";

export const BOOKING_CONFIRMATION_NOTIFICATION_QUERY = gql`
  query BookingConfirmationNotification {
    clientNotification(id: JOB_BOOKING_CONFIRMATION) {
      ...notificationFields
    }
  }
  ${notificationFieldsFragment}
`;
