import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Markdown } from "@jobber/components/Markdown";
import { Button } from "@jobber/components/Button";
import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { NotificationModal } from "jobber/settings/notifications/components/NotificationSection/NotificationModal";
import styles from "jobber/settings/notifications/components/NotificationSection/NotificationSection.module.css";
import type { NotificationTypes } from "jobber/settings/notifications/notificationInterfaces";
import { useUpdateNotificationModal } from "jobber/settings/notifications/hooks";

interface NotificationTemplateEditorProps {
  title: string;
  description: string;
  editing: boolean;
  requiredFeaturePlan?: string;
  featureAvailable?: boolean;
  notification: NotificationTypes;
  textRewriteVariableSet: {
    SMS?: string;
    EMAIL: string;
  };
  handleEdit(): void;
  handleClose(): void;
}

export function NotificationTemplateEditor({
  title,
  description,
  editing,
  notification,
  handleEdit,
  handleClose,
  requiredFeaturePlan,
  textRewriteVariableSet,
  featureAvailable = true,
}: NotificationTemplateEditorProps) {
  const { updateNotification } = useUpdateNotificationModal();

  return (
    <>
      {requiredFeaturePlan && (
        <InlineLabel color="lightBlue">{requiredFeaturePlan}</InlineLabel>
      )}
      <Content>
        {title && <Heading level={4}>{title}</Heading>}
        <div className={styles.container}>
          <div className={styles.content}>
            <Text>
              <Markdown
                content={description}
                basicUsage={true}
                externalLink={true}
              />
            </Text>
          </div>
          {featureAvailable && (
            <div className={styles.action}>
              <Button label="Edit" type="tertiary" onClick={handleEdit} />
            </div>
          )}
        </div>
      </Content>
      <NotificationModal
        title={`${title} Template`}
        open={editing}
        allowDeliveryMethodChange={false}
        allowUnitChange={false}
        notification={notification}
        onRequestClose={handleClose}
        onSave={updateNotification}
        textRewriteVariableSet={textRewriteVariableSet}
      />
    </>
  );
}
