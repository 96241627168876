import React from "react";
import { Icon } from "@jobber/components";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styles from "./LineItemInput.module.css";

export function LineItemContainer({ children }: React.PropsWithChildren) {
  return <div className={styles.lineItemContainer}>{children}</div>;
}

export function LineItemInputContainer({
  children,
  dragHandle,
}: React.PropsWithChildren<{ dragHandle?: DraggableProvidedDragHandleProps }>) {
  return (
    <div style={{ display: "flex" }}>
      {dragHandle && (
        <div className={styles.dragIconContainer} {...dragHandle}>
          <Icon name="drag" />
        </div>
      )}
      <div className={styles.lineItemInputContainer}>{children}</div>
    </div>
  );
}

export function LineItemActionContainer({ children }: React.PropsWithChildren) {
  return <div className={styles.actionContainer}>{children}</div>;
}

export function LineItemInputRow({ children }: React.PropsWithChildren) {
  return <div className={styles.inputRow}>{children}</div>;
}
