import type {
  CommsCampaignClientSegmentReviewQuery,
  CommsCampaignReviewQuery,
} from "~/utilities/API/graphql";

export interface TransformedCampaignData {
  fromEmail: string;
  segmentName: string;
  totalCount: number;
  consentingClientsCount: number;
  replyToEmail: string;
  hasClientWithoutConsentEntries: boolean;
}

interface TransformCommsCampaignProps {
  campaignData?: CommsCampaignReviewQuery;
  clientSegmentData?: CommsCampaignClientSegmentReviewQuery;
}
export function transformCommsCampaign({
  campaignData,
  clientSegmentData,
}: TransformCommsCampaignProps): TransformedCampaignData {
  function buildFromEmail(campaign?: CommsCampaignReviewQuery) {
    const companyName = campaign?.commsCampaign?.updatedBy?.account?.name || "";
    const fromEmail = campaign?.commsCampaign?.fromEmailAddress?.value || "";

    if (!companyName || !fromEmail) {
      return "";
    }

    return `${companyName} <${fromEmail}>`;
  }

  const clientSegment = clientSegmentData?.commsCampaign?.clientSegment;

  return {
    totalCount: clientSegment?.clients?.totalCount || 0,
    consentingClientsCount: clientSegment?.consentingClients?.totalCount || 0,
    segmentName: clientSegment?.friendlyName || "",
    replyToEmail: campaignData?.commsCampaign?.replyToEmail || "",
    fromEmail: buildFromEmail(campaignData),
    hasClientWithoutConsentEntries:
      clientSegment?.hasClientWithoutConsentEntries ?? true,
  };
}
