import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
// eslint-disable-next-line no-restricted-imports
import { FormField } from "@jobber/components/FormField";
import { Segment } from "~/utilities/API/graphql";
import type {
  AdditionalCriteriaClientTagCriteriaFragment,
  AdditionalCriteriaClientTypeCriteriaFragment,
  AdditionalCriteriaJobStatusCriteriaFragment,
  AdditionalCriteriaJobTypeCriteriaFragment,
  AdditionalCriteriaLineItemCriteriaFragment,
} from "~/utilities/API/graphql";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";
import { ClientTagCriterion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/ClientTagCriterion";
import {
  type AdditionalCriteriaUnion,
  SupportedCriteriaTypes,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import { LineItemCriterion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/LineItemCriterion";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { IntlSelect } from "jobber/campaigns/views/SelectClientSegmentPage/components/IntlSelect/IntlSelect";
import { JobTypeCriterion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/JobTypeCriterion/JobTypeCriterion";
import { JobStatusCriterion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/JobStatusCriterion/JobStatusCriterion";
import { SplitNames, useSplit } from "utilities/split";
import styles from "./AdditionalCriterion.module.css";
import {
  CLIENT_TAGS_OPTION,
  CLIENT_TYPE_OPTION,
  DEFAULT_OPTION,
  JOB_STATUS_OPTION,
  JOB_TYPE_OPTION,
  LINE_ITEMS_OPTION,
} from "./constants";
import { ClientTypeCriterion } from "../ClientTypeCriterion";

export const ADDITIONAL_CRITERIA_OPTIONS_BY_SEGMENT = {
  [Segment.ALL_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    CLIENT_TYPE_OPTION,
  ],
  [Segment.PAST_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    LINE_ITEMS_OPTION,
    JOB_TYPE_OPTION,
  ],
  [Segment.UPCOMING_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    LINE_ITEMS_OPTION,
    JOB_TYPE_OPTION,
    JOB_STATUS_OPTION,
  ],
};

interface AdditionalCriterionProps {
  criterion: AdditionalCriteriaUnion;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
  index: number;
}

export function isLineItemCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaLineItemCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.LINE_ITEMS;
}

function isClientTypeCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaClientTypeCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.CLIENT_TYPE;
}

function isClientTagCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaClientTagCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.CLIENT_TAGS;
}

function isJobTypeCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaJobTypeCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.JOB_TYPE;
}

function isJobStatusCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaJobStatusCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.JOB_STATUS;
}

export function AdditionalCriterion({
  criterion,
  updateAdditionalCriteria,
  index,
}: AdditionalCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.CampaignsPLE],
  });
  const canSeeClientsWithoutBookedJobs = getTreatmentValue(
    SplitNames.CampaignsPLE,
  );

  const {
    campaignSegment: { selectedSegmentType },
  } = useCampaignWizardContext();

  const criteriaOptions = ADDITIONAL_CRITERIA_OPTIONS_BY_SEGMENT[
    selectedSegmentType
    // ADDING THIS FILTER TO HIDE CLIENTS WITHOUT BOOKED JOBS FILTER UNTIL WE HAVE ALL EXPERIMENT ON
  ].filter(i => {
    if (
      !canSeeClientsWithoutBookedJobs &&
      i.value === CLIENT_TYPE_OPTION.value
    ) {
      return false;
    }
    return true;
  });
  const forceValidations = useRef<HTMLInputElement>(null);

  return (
    <>
      <Heading level={6}>
        {formatMessage(messages.andAdditionalCriteria)}
      </Heading>
      <div className={styles.criteriaSelection}>
        <IntlSelect
          options={criteriaOptions}
          placeholder={formatMessage(messages.chooseCriteria)}
          value={criterion.__typename}
          onChange={handleCriteriaChange}
        />
        <div className={styles.validationWrapper}>
          <FormField
            name={`forceValidations${index}`}
            inputRef={forceValidations}
          >
            <input name={`forceValidations${index}`} />
          </FormField>
        </div>

        <Button
          icon={"cross"}
          ariaLabel={formatMessage(messages.removeCriteria)}
          type="tertiary"
          variation="subtle"
          onClick={handleRemoveCriteria}
        />
      </div>
      {isLineItemCriterion(criterion) && (
        <LineItemCriterion
          lineItemValue={criterion.lineItemValue}
          operator={criterion.operator}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isClientTagCriterion(criterion) && (
        <ClientTagCriterion
          clientTagValue={criterion.clientTagValue}
          operator={criterion.operator}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isJobTypeCriterion(criterion) && (
        <JobTypeCriterion
          jobTypeValue={criterion.jobTypeValue}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isJobStatusCriterion(criterion) && (
        <JobStatusCriterion
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isClientTypeCriterion(criterion) && (
        <ClientTypeCriterion
          clientTypeValue={criterion.clientTypeValue}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
    </>
  );

  function handleCriteriaChange(selectedCriteria: string) {
    updateAdditionalCriteria({
      type: "updateCriteriaType",
      payload: { criteriaType: selectedCriteria, index: index },
    });
  }

  function handleRemoveCriteria() {
    updateAdditionalCriteria({
      type: "removeCriteria",
      payload: { index: index },
    });
    forceValidations.current?.focus();
    forceValidations.current?.blur();
  }
}
