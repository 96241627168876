import { useMemo } from "react";

const defaultPriorityCountryCodes = ["US", "CA", "GB"];

interface CountryCodesToNameMap {
  [key: string]: string;
}

interface UseCountriesParams {
  countryCodesToNameMap: CountryCodesToNameMap;
  priorityCountryCodes?: string[];
}

type CountryCode = string;
type CountryName = string;
export type CountryTuple = [CountryCode, CountryName];
type PriorityCountries = CountryTuple[];
type AllCountries = CountryTuple[];
type ReturnType = [PriorityCountries, AllCountries];

function selectPriorityCountries(
  priorityCodes: string[],
  allCountries: CountryCodesToNameMap,
): CountryCodesToNameMap {
  return priorityCodes.reduce<CountryCodesToNameMap>((accumulated, code) => {
    if (allCountries[code]) {
      accumulated[code] = allCountries[code];
    }

    return accumulated;
  }, {});
}

export function useCountries(config: UseCountriesParams): ReturnType {
  const {
    countryCodesToNameMap: map,
    priorityCountryCodes: priorities = defaultPriorityCountryCodes,
  } = config;

  const prioritiesMap = useMemo(
    () => selectPriorityCountries(priorities, map),
    [priorities, map],
  );
  const priorityCountryTuples = useMemo(
    () => Object.entries(prioritiesMap),
    [prioritiesMap],
  );
  const allCountryTuples = useMemo(() => Object.entries(map), [map]);

  return [priorityCountryTuples, allCountryTuples];
}
