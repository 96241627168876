import { DEFAULT_VIDEO_DATA, INDUSTRY_VIDEOS, IndustryName } from "./constants";
import type { MessageFormat } from "./types";
import { messages } from "./messages";

export const getIndustryVideoData = (
  industry: string,
): { industry: string; id: string } | undefined =>
  INDUSTRY_VIDEOS.find(item => item.industry === industry);

export const getWelcomeVideoData = (
  industry: string,
): { id: string; text: MessageFormat } => {
  const video = getIndustryVideoData(industry);

  if (video) {
    return {
      id: video.id,
      text: messages.welcomeVideoIndustryText,
    };
  }

  return DEFAULT_VIDEO_DATA;
};

export const formatIndustryName = (industry: string): string => {
  return industry === IndustryName.HVAC
    ? industry
    : industry.toLocaleLowerCase();
};
