import React, { useEffect, useState } from "react";
import { Option, Select } from "@jobber/components/Select";
import {
  Autocomplete,
  type Option as OptionAutoComplete,
} from "@jobber/components/Autocomplete";
import { useQuery } from "@apollo/client";
import { Glimmer } from "@jobber/components/Glimmer";
import { useIntl } from "react-intl";
import { useUpdateLineItemCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/hooks/useUpdateLineItemCriteria";
import { Operator, type ProductsQuery } from "~/utilities/API/graphql";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { useLineItemsOptions } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/components/LineItemCriterion/hooks/useLineItemsOptions";
import styles from "./LineItemCriterion.module.css";

interface LineItemCriterionProps {
  lineItemValue: { id: string; name: string } | undefined;
  operator: Operator;
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

export function LineItemCriterion({
  lineItemValue,
  operator,
  criteriaIndex,
  updateAdditionalCriteria,
}: LineItemCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  const { loading, data } = useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const {
    options: basicOptions,
    optionsLoaded,
    getOptions,
  } = useLineItemsOptions(data);

  const selectValue = operator || Operator.EQUAL;

  const [autocompleteValue, setAutocompleteValue] = useState<
    OptionAutoComplete | undefined
  >(undefined);

  useEffect(() => {
    const updatedValue = lineItemValue
      ? {
          label: lineItemValue.name,
          value: lineItemValue.id,
        }
      : undefined;
    if (updatedValue?.label !== autocompleteValue?.label) {
      setAutocompleteValue(updatedValue);
    }
  }, [lineItemValue, basicOptions, autocompleteValue]);

  const onAdditionalCriteriaChange = useUpdateLineItemCriteria({
    updateAdditionalCriteria,
  });

  function onSelectionChange(value: Operator) {
    onAdditionalCriteriaChange({
      operator: value,
      lineItemValue: {
        id: autocompleteValue?.value?.toString() || "",
        name: autocompleteValue?.label || "",
      },
      criteriaIndex,
    });
  }

  function onAutocompleteChange(value?: OptionAutoComplete) {
    setAutocompleteValue(value);
    onAdditionalCriteriaChange({
      operator: selectValue,
      lineItemValue: {
        id: value?.value?.toString() || "",
        name: value?.label || "",
      },
      criteriaIndex,
    });
  }

  return (
    <div data-testid={"lineItemCriterion"}>
      {loading && !optionsLoaded && <Glimmer size="larger" />}
      {!loading && optionsLoaded && (
        <div>
          <div className={styles.lineItemAutoComplete}>
            <Select
              defaultValue={selectValue}
              onChange={onSelectionChange}
              validations={{
                required: {
                  value: true,
                  message: formatMessage(messages.pleaseSelectCriterion),
                },
              }}
            >
              <Option value={Operator.EQUAL}>
                {formatMessage(messages.lineItemOptionInclude)}
              </Option>
              <Option value={Operator.NOT_EQUAL}>
                {formatMessage(messages.lineItemOptionDoNotInclude)}
              </Option>
            </Select>
          </div>
          <Autocomplete
            placeholder={formatMessage(
              messages.lineItemAutoCompletePlaceholder,
            )}
            initialOptions={basicOptions}
            value={autocompleteValue}
            onChange={onAutocompleteChange}
            getOptions={getOptions}
            allowFreeForm={false}
            validations={{
              required: {
                value: true,
                message: formatMessage(messages.lineItemFieldRequired),
              },
            }}
            suffix={
              autocompleteValue && {
                icon: "cross",
                ariaLabel: "clear search",
                onClick: () => onAutocompleteChange(undefined),
              }
            }
          />
        </div>
      )}
    </div>
  );
}
