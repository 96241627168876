import { Page } from "@jobber/components/Page";
import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { InvoiceScheduleOptions } from "jobber/workOrders/components/InvoiceScheduler/InvoiceScheduleOptions";
import {
  InvoiceCreationPreference,
  InvoicePeriod,
  type InvoiceSchedulerState,
} from "jobber/workOrders/components/InvoiceScheduler/types";
import { useInvoiceScheduler } from "jobber/workOrders/components/InvoiceScheduler/useInvoiceScheduler";
import {
  ExpiryStatus,
  type StoredPaymentMethodInterface,
  StoredPaymentMethodType,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import {
  InvoiceScheduler,
  type InvoiceSchedulerProps,
} from "./components/InvoiceScheduler/InvoiceScheduler";
import { DurationPeriod } from "./components/Scheduler/types";

export const InvoiceSchedulerPage = () => {
  const [invoiceSchedulerState, setInvoiceSchedulerState] =
    useInvoiceScheduler();

  const onChangeInvoiceSchedule = (
    partialNextInvoiceSchedule: Partial<InvoiceSchedulerState>,
  ) =>
    setInvoiceSchedulerState({
      ...invoiceSchedulerState,
      ...partialNextInvoiceSchedule,
    });

  const onInvoiceScheduleOptionsChange = (
    newValue: InvoiceCreationPreference,
  ) =>
    onChangeInvoiceSchedule({
      invoiceCreationPreference: newValue,
    });

  const sampleProps: StoredPaymentMethodInterface = {
    uuid: "pm_1234567890xyzabc",
    brand: "visa",
    isDefault: true,
    last4: "4242",
    expiryMonth: 12,
    expiryYear: 2033,
    expiryStatus: ExpiryStatus.DEFAULT,
    type: StoredPaymentMethodType.CREDIT_CARD,
  };

  const schedulerPageTestProps: InvoiceSchedulerProps = {
    invoiceOnJobComplete: true,
    jobberPaymentsEnabled: false,
    canUseAutomaticPayments: false,
    schedule: InvoicePeriod.AsNeeded,
    invoiceCreationPreference: InvoiceCreationPreference.RemindMe,
    recurrence: {
      rule: { interval: 1, type: DurationPeriod.Day },
      ends: {
        type: "date",
        date: new Date(),
        numOfPeriods: 1,
        durationPeriod: DurationPeriod.Day,
      },
    },
    startDate: new Date("July 20, 69 00:20:18"),
    onChange: onChangeInvoiceSchedule,
  };

  return (
    <Page title="Invoice Scheduler" width="fill">
      <div style={{ maxWidth: 450 }}>
        <InvoiceScheduler
          {...schedulerPageTestProps}
          invoiceOnJobComplete={invoiceSchedulerState.invoiceOnJobComplete}
          schedule={invoiceSchedulerState.schedule}
        />
      </div>
      <div style={{ maxWidth: 450 }}>
        <Card title="Invoice Schedule Options">
          <Content>
            <Heading level={5}>
              Disabled Jobber Payments and showing CTA
            </Heading>

            <InvoiceScheduleOptions
              jobberPaymentsEnabled={false}
              automaticPaymentsEnabled={true}
              invoiceCreationPreference={InvoiceCreationPreference.RemindMe}
              onChange={onInvoiceScheduleOptionsChange}
            />
          </Content>

          <Content>
            <Heading level={5}>
              Jobber Payments enabled but automatic payments are disabled
            </Heading>
            <InvoiceScheduleOptions
              jobberPaymentsEnabled={true}
              automaticPaymentsEnabled={false}
              onChange={onInvoiceScheduleOptionsChange}
              invoiceCreationPreference={InvoiceCreationPreference.RemindMe}
            />
          </Content>

          <Content>
            <Heading level={5}>
              Jobber Payments enabled, automatic payments are enabled but no
              Credit Card Info
            </Heading>
            <InvoiceScheduleOptions
              jobberPaymentsEnabled={true}
              onChange={onInvoiceScheduleOptionsChange}
              automaticPaymentsEnabled={true}
              invoiceCreationPreference={
                invoiceSchedulerState.invoiceCreationPreference
              }
            />
          </Content>

          <Content>
            <Heading level={5}>
              Jobber Payments enabled and automatic payments are enabled with
              Credit Card Info
            </Heading>
            <InvoiceScheduleOptions
              jobberPaymentsEnabled={true}
              onChange={onInvoiceScheduleOptionsChange}
              automaticPaymentsEnabled={true}
              invoiceCreationPreference={
                invoiceSchedulerState.invoiceCreationPreference
              }
              creditCardInfo={sampleProps}
            />
          </Content>
        </Card>
      </div>
    </Page>
  );
};
