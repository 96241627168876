import { gql } from "@apollo/client";

export const UPDATE_UK_KYC_REGISTRATION = gql`
  mutation UpdateUkKycRegistration($input: UkKycRegistrationUpdateInput!) {
    commsUkKycRegistrationUpdate(input: $input) {
      ukKycRegistration {
        id
        acknowledgedAt
        status
        createdAt
        endUserType
        failureReason
        registrationId
        updatedAt
      }
      userErrors {
        path
        message
      }
    }
  }
`;
