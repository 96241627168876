import { useMemo } from "react";
import { useMachine } from "@xstate/react";
import type { ArrivalWindowDialogState, OnboardingSteps } from "./types";
import { arrivalWindowDialogMachine } from "./arrivalWindow.machine";

interface UseArrivalWindowDialogStateMachine {
  state: ArrivalWindowDialogState;
  currentOnboardingStep: OnboardingSteps;
  showReminderPreviews(): void;
  goBackToEditArrivalWindowsStyle(): void;
  dismissAllModals(): void;
  toggleCentered(): void;
  toggleApplyToFuture(): void;
  updateDuration(updatedDuration?: number): void;
}

export const useArrivalWindowDialogStateMachine = (
  initialState: ArrivalWindowDialogState,
): UseArrivalWindowDialogStateMachine => {
  const [state, send] = useMachine(arrivalWindowDialogMachine, {
    context: initialState,
  });

  const callbacks = useMemo(
    () => ({
      showReminderPreviews: () => send("SHOW_REMINDER_PREVIEWS"),
      goBackToEditArrivalWindowsStyle: () =>
        send("GO_BACK_TO_EDIT_ARRIVAL_WINDOWS_STYLE"),
      dismissAllModals: () => send("CLOSE_MODAL"),
      toggleCentered: () => send("TOGGLE_CENTERED"),
      toggleApplyToFuture: () => send("TOGGLE_APPLY_TO_FUTURE"),
      updateDuration: (newDuration?: number) => {
        if (newDuration !== undefined) {
          send({ type: "UPDATE_DURATION", newDuration });
        }
      },
    }),
    [send],
  );

  return {
    currentOnboardingStep: state.value as OnboardingSteps,
    state: state.context,
    ...callbacks,
  };
};
