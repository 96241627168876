import { useHistory } from "react-router-dom";
import { CampaignStatus } from "~/utilities/API/graphql";
import { CAMPAIGNS_LANDING_PAGE_PATH } from "jobber/campaigns/constants";

export function usePreventEditCampaign({
  campaignStatus,
}: {
  campaignStatus?: CampaignStatus;
}) {
  const { replace } = useHistory();
  const campaignIsDraftOrInactive =
    campaignStatus == CampaignStatus.DRAFT ||
    campaignStatus == CampaignStatus.AUTOMATIONINACTIVE;

  const shouldRedirect = campaignStatus && !campaignIsDraftOrInactive;
  if (shouldRedirect) {
    replace(CAMPAIGNS_LANDING_PAGE_PATH);
  }
}
