import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  LabourRateUpdateMutation,
  LabourRateUpdateMutationVariables,
} from "~/utilities/API/graphql";
import { LABOUR_RATE_UPDATE } from "./graphql";
import { messages } from "./messages";

interface updateLabourRateData {
  userId: string;
  labourRate: number;
}

export function useSaveNewLabourRateMutation(jobCostingEnabled: boolean): {
  handleSaveNewLabourRate(input: updateLabourRateData): void;
  loading: boolean;
} {
  const [create, { loading }] = useMutation<
    LabourRateUpdateMutation,
    LabourRateUpdateMutationVariables
  >(LABOUR_RATE_UPDATE);

  async function handleSaveNewLabourRate(data: updateLabourRateData) {
    try {
      const result = await create({
        variables: {
          userId: data.userId,
          labourRate: data.labourRate,
          canViewJobCosts: jobCostingEnabled,
        },
      });

      const errors = result.data?.upsertUser.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        const name = result.data?.upsertUser.user?.name.full;
        const successMessage = `Labor rate set for ${name}`;

        showToast({
          message: successMessage,
          variation: "success",
        });
      }
    } catch (error) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return { handleSaveNewLabourRate, loading };
}
