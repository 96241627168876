import { defineMessages } from "react-intl";

export const messages = defineMessages({
  ariaModalContent: {
    id: "workItems.form.ariaModalContent",
    defaultMessage: "Add New Product and Service Modal Content",
    description:
      "Description of the modal content for adding a new product and service",
  },
  ariaCategorySelect: {
    id: "workItems.form.ariaCategorySelect",
    defaultMessage: "Work Item Category Drop Down",
    description: "Description of the category select",
  },
  ariaItemNameInput: {
    id: "workItems.form.ariaItemNameInput",
    defaultMessage: "Work Item Name Field",
    description: "Description of the item name input",
  },
  ariaItemDescriptionInput: {
    id: "workItems.form.ariaItemDescriptionInput",
    defaultMessage: "Work Item Description Field",
    description: "Description of the item description input",
  },
  ariaItemCostInput: {
    id: "workItems.form.ariaItemCostInput",
    defaultMessage: "Work Item Cost Field",
    description: "Description of the item cost input",
  },
  ariaItemMarkupInput: {
    id: "workItems.form.ariaItemMarkupInput",
    defaultMessage: "Work Item Markup Field",
    description: "Description of the item markup input",
  },
  ariaItemUnitPriceInput: {
    id: "workItems.form.ariaItemUnitPriceInput",
    defaultMessage: "Work Item Unit Price Field",
    description: "Description of the item unit price input",
  },
  ariaItemTaxExemptInput: {
    id: "workItems.form.ariaItemTaxExemptInput",
    defaultMessage: "Exempt From Tax Checkbox",
    description: "Description of the item tax exempt input",
  },
  itemNamePlaceholder: {
    id: "workItems.form.itemNamePlaceholder",
    defaultMessage: "Name",
    description: "Placeholder for the item name input",
  },
  itemDescriptionPlaceholder: {
    id: "workItems.form.itemDescriptionPlaceholder",
    defaultMessage: "Description",
    description: "Placeholder for the item description input",
  },
  itemCostPlaceholder: {
    id: "workItems.form.itemCostPlaceholder",
    defaultMessage: "Cost",
    description: "Placeholder for the item cost input",
  },
  itemMarkupPlaceholder: {
    id: "workItems.form.itemMarkupPlaceholder",
    defaultMessage: "Markup (%)",
    description: "Placeholder for the item markup input",
  },
  itemUnitPricePlaceholder: {
    id: "workItems.form.itemUnitPricePlaceholder",
    defaultMessage: "Unit Price",
    description: "Placeholder for the item unit price input",
  },
  serviceCategoryLabel: {
    id: "workItems.form.serviceCategoryLabel",
    defaultMessage: "Service",
    description: "Label for the service category select",
  },
  productCategoryLabel: {
    id: "workItems.form.productCategoryLabel",
    defaultMessage: "Product",
    description: "Label for the product category select",
  },
  exemptFromTaxLabel: {
    id: "workItems.form.exemptFromTaxLabel",
    defaultMessage: "Exempt from Tax",
    description: "Label for the tax exempt checkbox",
  },
  itemAvailableLabel: {
    id: "workItems.form.availableLabel",
    defaultMessage: "Available on quotes, jobs, invoices & online booking",
    description: "Label for the availablility on QJI and SSB checkbox",
  },
  bannerDisabledFieldsSyncError: {
    id: "workItems.form.bannerDisabledFieldsSyncError",
    defaultMessage:
      "Edit disabled fields in {accountingSyncName} to avoid sync errors.",
    description: "Error message for disabled fields out of sync",
  },
  bannerOverwriteOnSyncError: {
    id: "workItems.form.bannerOverwriteOnSyncError",
    defaultMessage:
      "Changes to the name, description and/or price may be overwritten during the next sync. Edit these settings in {accountingSyncName} to avoid lost information.",
    description: "Error message for overwrite on sync",
  },
});
