import { JobberOnline } from "./JobberOnline";

export function isPaymentIntegration(
  selection: JobberOnline.constants.balanceAdjustments.paymentTypes,
): selection is
  | JobberOnline.constants.balanceAdjustments.paymentTypes.jobberPayments
  | JobberOnline.constants.balanceAdjustments.paymentTypes.epayment {
  return [
    JobberOnline.constants.balanceAdjustments.paymentTypes.jobberPayments,
    JobberOnline.constants.balanceAdjustments.paymentTypes.epayment,
  ].includes(selection);
}
