import React from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import type { IntroStepProps } from "./IntroStep.d";
import styles from "./IntroStep.module.css";

export function IntroStep({
  distributionChannelName,
  jobberImageSrc,
  plusImageSrc,
  imageAlt,
  imageSrc,
  onClick,
}: IntroStepProps) {
  /* Props */
  return (
    <Content spacing="large">
      <div className={styles.logoContainer}>
        <img src={jobberImageSrc} alt="Jobber Logo" />
        {imageSrc && (
          <>
            <img src={plusImageSrc} alt="Plus Operator" />
            <img className={styles.channelLogo} src={imageSrc} alt={imageAlt} />
          </>
        )}
      </div>
      <div className="u-textCenter">
        <Text size="large">
          Jobber has partnered with {distributionChannelName} to provide you
          with a tool to help organize and manage your business. Before we get
          started, we have a few details for you to fill out!
        </Text>
      </div>
      <div className="u-textRight">
        <Button label="Let's Get Started" onClick={onClick} />
      </div>
    </Content>
  );
}
