import React from "react";
import classnames from "classnames";
import { Icon, type IconProps } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import styles from "./ProgressListItem.module.css";

export interface ProgressListItemProps {
  /**
   * Adds a check mark if the item is completed.
   */
  completed: boolean;

  /**
   * Highlights the list item with green indicating that the item is the
   * current selection.
   */
  readonly isActive?: boolean;

  /**
   * The ID of the list item.
   */
  readonly id: number;

  /**
   * Adds a heading.
   */
  readonly title: string;

  /**
   * Callback when a list item gets clicked.
   */
  onClick?(): void;

  /**
   * Adds a time estimate to the list item.
   */
  readonly timeEstimate?: string;
}

export function ProgressListItem({
  completed,
  id,
  isActive = false,
  onClick,
  title,
  timeEstimate,
}: ProgressListItemProps) {
  const actionClasses = classnames({
    [styles.action]: true,
    [styles.isActive]: !!isActive,
    [styles.hoverable]: !!onClick,
  });
  const Wrapper = "button";
  const buttonProps = {
    ...(Wrapper === "button" && { role: "button", type: "button" as const }),
  };

  return (
    <Wrapper
      key={id.toString()}
      className={actionClasses}
      onClick={onClick}
      {...buttonProps}
    >
      <ShowIcon show={completed} name={"checkmark"} />
      <ItemContent
        completed={completed}
        active={isActive}
        content={title}
        timeEstimate={timeEstimate}
      />
    </Wrapper>
  );
}

interface ShowIconProps extends Pick<IconProps, "name"> {
  show: boolean;
}

export function ShowIcon({ show = false, name }: ShowIconProps) {
  if (show) {
    return (
      <div data-testid="icon" className={styles.icon}>
        <Icon name={name} />
      </div>
    );
  }
  return null;
}

export interface ItemContentProps {
  content: string;
  active: boolean;
  completed: boolean;
  timeEstimate?: string;
}

export function ItemContent({
  completed,
  content,
  active,
  timeEstimate,
}: ItemContentProps) {
  const itemContentStyle = classnames(styles.itemContentContainer, {
    [styles.active]: active,
    [styles.activeCompletedFont]: completed && active,
    [styles.completedFont]: completed && !active,
    [styles.activeRegularFont]: !completed && active,
    [styles.regularFont]: !completed && !active,
  });

  return (
    <div className={itemContentStyle} data-testid={`item-content-${content}`}>
      <Text size={"large"}>{content}</Text>

      {timeEstimate && (
        <div className={styles.itemContentTime}>
          <Text variation="subdued">{timeEstimate}</Text>
        </div>
      )}
    </div>
  );
}
