import { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import type { AutocompleteCompany } from "jobber/setupWizard/types";
import { messages } from "./messages";
import { getCountryCode, mapPlaceToCompany } from "./helpers";

type GoogleApi = typeof google;

interface Parameters {
  google: GoogleApi;
  autocompleteService?: google.maps.places.AutocompleteService;
  placesService?: google.maps.places.PlacesService;
  locationBias?: google.maps.places.LocationBias;
  types?: string[];
  signupConsentCountry?: string;
}

export function useCompanyAutocompleteResults({
  google,
  locationBias = "IP_BIAS",
  types = ["establishment"],
  autocompleteService,
  placesService,
  signupConsentCountry,
}: Parameters) {
  const { formatMessage } = useIntl();

  const autocomplete = useRef<google.maps.places.AutocompleteService>(
    autocompleteService || new google.maps.places.AutocompleteService(),
  );

  const places = useRef<google.maps.places.PlacesService>(
    placesService ||
      new google.maps.places.PlacesService(document.createElement("div")),
  );

  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >();

  const predictions = useCallback(
    async (input: string) => {
      try {
        if (input === "") {
          return [];
        }

        const generateSessionToken = () => {
          const token = new google.maps.places.AutocompleteSessionToken();
          setSessionToken(token);

          return token;
        };

        const countryCode = getCountryCode(signupConsentCountry ?? "");

        const { predictions: results } =
          await autocomplete.current.getPlacePredictions({
            input,
            types,
            locationBias,
            sessionToken: sessionToken || generateSessionToken(),
            ...(countryCode && {
              componentRestrictions: { country: countryCode },
            }),
          });

        if (!results.length) {
          return [];
        }

        const resultsMap = results.map(result => ({
          label: result.structured_formatting.main_text,
          value: result.place_id,
          description: result.structured_formatting.secondary_text,
        }));

        const manualOption = {
          value: "manual",
          label: `+ ${formatMessage(messages.manualCompanyNameLabel)}`,
        };

        return [...resultsMap, manualOption];
      } catch (error) {
        return [];
      }
    },
    [
      google.maps.places.AutocompleteSessionToken,
      locationBias,
      types,
      sessionToken,
      formatMessage,
      signupConsentCountry,
    ],
  );

  const companyFromPlace = useCallback(
    (placeId: string, fields = ["name", "photos", "website", "reviews"]) =>
      new Promise<Partial<AutocompleteCompany>>((resolve, reject) => {
        places.current.getDetails(
          {
            placeId,
            fields,
            sessionToken,
          },
          (result, status) => {
            if (status == "OK" && result) {
              setSessionToken(undefined);
              resolve(mapPlaceToCompany(result));
            } else {
              reject(status);
            }
          },
        );
      }),
    [sessionToken],
  );

  return {
    predictions,
    companyFromPlace,
  };
}
