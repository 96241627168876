import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import styles from "./SecurityDisclosure.module.css";

export function SecurityDisclosure() {
  return (
    <div data-testid="security-disclosure">
      <div className={styles.securityDisclaimer}>
        <Icon name="lock" />
        <Text>Security Assurance</Text>
      </div>
      <div>
        <em className="u-textLeft u-textSmall u-colorGreyBlue">
          Your information is not stored by us, and is transferred over an
          encrypted connection to a certified payment processing server.
        </em>
      </div>
    </div>
  );
}
