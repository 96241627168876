import type { IntlFormatters } from "react-intl";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import type {
  BillingCycleNameType,
  PlanInfo,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
  SubscriptionPreviewGroup,
} from "~/shared/billing/pricePreview/types";
import { messages } from "~/shared/billing/pricePreview/components/BillingDetails/messages";
import { BillingCycleName } from "~/shared/billing/pricePreview/types";

export function isAddonSelected(
  selectedAddonCodes: string[],
  subscriptionAddon: SubscriptionAddonPreview,
) {
  return selectedAddonCodes.includes(
    subscriptionAddon.monthlyBillingCycle.addonCode,
  );
}
export function formatPrice(price?: number) {
  const symbol = "$";

  if (price === undefined) {
    return "";
  }

  return currencyWithUnit(price.toString(), symbol).format();
}

export function pricePerPeriod(price?: number, period?: string | undefined) {
  const formattedPrice = formatPrice(price);
  return period ? `${formattedPrice}/${period}` : formattedPrice;
}

export function currentPreview(
  previews: SubscriptionPreviewGroup,
  billingCycle: BillingCycleNameType,
): SubscriptionPreview {
  if (billingCycle === BillingCycleName.ANNUAL) {
    return handleAnnualPreviewSelected(previews);
  }

  return handleMonthlyPreviewSelected(previews);
}

function handleAnnualPreviewSelected(previews: SubscriptionPreviewGroup) {
  if (previews.annualPreview) {
    return previews.annualPreview;
  }

  if (previews.monthlyPreview) {
    return previews.monthlyPreview;
  }

  throw new Error("No subscription preview available");
}

function handleMonthlyPreviewSelected(previews: SubscriptionPreviewGroup) {
  if (previews.monthlyPreview) {
    return previews.monthlyPreview;
  }

  if (previews.annualPreview) {
    return previews.annualPreview;
  }

  throw new Error("No subscription preview available");
}

export function savingsMessage(
  formatMessage: IntlFormatters["formatMessage"],
  monthlyCycleAnnualPrice?: number,
  annualCycleAnnualPrice?: number,
) {
  if (!monthlyCycleAnnualPrice || !annualCycleAnnualPrice) {
    return "";
  }

  const savings = monthlyCycleAnnualPrice - annualCycleAnnualPrice;

  return formatMessage(messages.savingsMessage, {
    amount: formatPrice(savings),
  });
}

export function getPurchaseDisclaimerInformation(
  selectedBillingCycle: BillingCycleNameType,
  planInfo: PlanInfo,
  discountInformation: SubscriptionDiscountGroup,
) {
  if (selectedBillingCycle.valueOf() === BillingCycleName.ANNUAL) {
    const discountUnitPrice = discountInformation.annualDiscount
      ?.planCostMonthlyDiscounted
      ? discountInformation.annualDiscount.planCostMonthlyDiscounted * 12
      : undefined;

    return {
      baseUnitPrice: planInfo?.annualBillingCycle?.annualPrice,
      discountUnitPrice: discountUnitPrice,
      discountEndDate: discountInformation.annualDiscount?.discountEndDate,
    };
  }

  return {
    baseUnitPrice: planInfo?.monthlyBillingCycle?.monthlyPrice,
    discountUnitPrice:
      discountInformation.monthlyDiscount?.planCostMonthlyDiscounted,
    discountEndDate: discountInformation.monthlyDiscount?.discountEndDate,
  };
}

export function getDiscountInformationByBillingCycle(
  selectedBillingCycle: BillingCycleNameType,
  discountInformation: SubscriptionDiscountGroup,
) {
  return selectedBillingCycle === BillingCycleName.MONTHLY
    ? discountInformation.monthlyDiscount
    : discountInformation.annualDiscount;
}

export function getAddonPurchaseDiscountInformation(
  selectedAddonCodes: string[],
  subscriptionAddons: SubscriptionAddonPreview[],
) {
  const selectedAddonsBaseUnitPrice = subscriptionAddons?.reduce(
    (total, subscriptionAddon) => {
      if (
        selectedAddonCodes.includes(
          subscriptionAddon.monthlyBillingCycle.addonCode,
        )
      ) {
        return total + subscriptionAddon.monthlyBillingCycle.monthlyCost;
      }
      return total;
    },
    0,
  );

  const selectedAddonsDiscountedUnitPrice = subscriptionAddons?.reduce(
    (total, subscriptionAddon) => {
      if (
        selectedAddonCodes.includes(
          subscriptionAddon.monthlyBillingCycle.addonCode,
        )
      ) {
        return (
          total +
          (subscriptionAddon.discountGroup?.monthlyAddonDiscount
            ?.addonCostMonthlyDiscounted || 0)
        );
      }
      return total;
    },
    0,
  );

  const addonsNextBillingDates = selectedAddonCodes.map(selectedAddonCode => {
    const addon = subscriptionAddons.find(
      subscriptionAddon =>
        subscriptionAddon.monthlyBillingCycle.addonCode === selectedAddonCode,
    );

    return addon?.previewGroup?.monthlyPreview?.nextBillingDate;
  });

  const addonsNextBillingDate = getEarliestDate(addonsNextBillingDates);

  const addonsDiscountEndDates = selectedAddonCodes.map(selectedAddonCode => {
    const addon = subscriptionAddons.find(
      subscriptionAddon =>
        subscriptionAddon.monthlyBillingCycle.addonCode === selectedAddonCode,
    );

    return addon?.discountGroup?.monthlyAddonDiscount?.discountEndDate;
  });

  const addonsDiscountEndDate = getEarliestDate(addonsDiscountEndDates);

  return {
    selectedAddonsBaseUnitPrice,
    addonsNextBillingDate,
    selectedAddonsDiscountedUnitPrice,
    addonsDiscountEndDate,
  };
}

function getEarliestDate(dates: (string | undefined)[]) {
  if (dates.length !== 0) {
    return dates.reduce((earliest, current) => {
      if (earliest && current) {
        return new Date(earliest) < new Date(current) ? earliest : current;
      }
    });
  }
}

export function calculateTaxTotal(
  preview: SubscriptionPreview,
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  const selectedAddonsTax = calculateSelectedAddonsTax(
    subscriptionAddons,
    selectedAddonCodes,
  );
  return (preview.proratedTax || 0) + selectedAddonsTax;
}

function calculateSelectedAddonsTax(
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  return subscriptionAddons.reduce((total, addon) => {
    if (selectedAddonCodes.includes(addon.monthlyBillingCycle.addonCode)) {
      return total + (addon.previewGroup.monthlyPreview.proratedTax || 0);
    }
    return total;
  }, 0);
}

function calculateSelectedAddonsCostAndCredit(
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  return subscriptionAddons.reduce(
    (total, addon) => {
      if (selectedAddonCodes.includes(addon.monthlyBillingCycle.addonCode)) {
        return {
          addonsCost: (total.addonsCost +=
            addon.previewGroup.monthlyPreview.proratedTotal),
          addonsCredit: (total.addonsCredit +=
            addon.previewGroup.monthlyPreview.totalCredit || 0),
        };
      }
      return total;
    },
    { addonsCost: 0, addonsCredit: 0 },
  );
}

export function calculatePurchaseTotal(
  shouldShowTax: boolean,
  subscriptionPreview: SubscriptionPreview,
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  const { addonsCost, addonsCredit } = calculateSelectedAddonsCostAndCredit(
    subscriptionAddons,
    selectedAddonCodes,
  );

  const taxTotal = calculateTaxTotal(
    subscriptionPreview,
    subscriptionAddons,
    selectedAddonCodes,
  );

  // When checking out the main subscription and selected addons together, there will be two different transactions. We do
  // not want the total charge of the main subscription to appear less than it should, as the credit from the add-ons transaction
  // does not apply to the main subscription transaction.
  const addonsTotal = addonsCost + addonsCredit; // addonsCredit is a negative value

  const totalWithTax =
    subscriptionPreview.proratedTotal +
    subscriptionPreview.totalCredit + // totalCredit is a negative value
    Math.max(addonsTotal, 0);

  const totalWithoutTax = totalWithTax - taxTotal;

  const calculatedTotal = shouldShowTax ? totalWithTax : totalWithoutTax;

  // Ensure the displayed total is not negative
  return Math.max(calculatedTotal, 0);
}

export function adjustedSelectedAddonsCredit(
  subscriptionPreview: SubscriptionPreview,
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  const { addonsCost, addonsCredit } = calculateSelectedAddonsCostAndCredit(
    subscriptionAddons,
    selectedAddonCodes,
  );

  // Check if the main subscription has a credit balance. If totalCredit exceeds
  // proratedTotal (the sum is negative), use it. If not, use 0
  const remainingSubscriptionCredit = Math.min(
    subscriptionPreview.proratedTotal + subscriptionPreview.totalCredit, // totalCredit is a negative value
    0,
  );

  // If the sum of all credits covers the entire addons cost, just return at most the negative value of the addons cost
  return Math.max(addonsCredit + remainingSubscriptionCredit, -1 * addonsCost);
}
