import classNames from "classnames";
import React from "react";
import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputValidation } from "@jobber/components/InputValidation";
import { Option, Select } from "@jobber/components/Select";
import { InputEmail } from "@jobber/components/InputEmail";
import { jobPositionsList } from "jobber/settings/dedicatedPhoneNumber/twilioRegistrationForm/components/registrationPointOfContact/JobPositionsList";
import type {
  FormErrors,
  TwilioRegistrationFormReducerState,
} from "jobber/settings/dedicatedPhoneNumber/hooks/TwilioRegistrationFormReducer";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import { messages } from "./messages";
import styles from "../../TwilioRegistrationForm.module.css";

interface FormObjectKeysTypes {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  jobPosition?: string;
  businessTitle?: string;
}

interface RegistrationPointOfContactProps {
  solePropSelected: boolean;
  fields: FormObjectKeysTypes;
  smsablePhoneError?: string;
  formSubmitted?: boolean;
  formErrors?: FormErrors;
  handleUpdate(data: Partial<TwilioRegistrationFormReducerState>): void;
}

// eslint-disable-next-line max-statements
function RegistrationPointOfContact({
  fields,
  smsablePhoneError,
  handleUpdate,
  formSubmitted,
  formErrors,
  solePropSelected,
}: RegistrationPointOfContactProps) {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.SolePropUpgrade, SplitNames.SolePropNew],
  });
  const solePropUpgradeSplit = getTreatmentValue(SplitNames.SolePropUpgrade);
  const solePropNewSplit = getTreatmentValue(SplitNames.SolePropNew);

  const OTPFlowEnabled =
    (solePropUpgradeSplit || solePropNewSplit) && solePropSelected;

  const OTHER_JOB_TITLE = jobPositionsList.find(
    position => position.value === "OTHER",
  )?.value;

  const errorMessages = {
    firstName: "First name is required",
    lastName: "Last name is required",
    email: "Email address is required",
    phone: "Must be an SMS-enabled phone number",
    jobPosition: "Job position is required",
    businessTitle: "Business title is required",
  };

  return (
    <>
      <Heading level={5}>Point of contact</Heading>
      <div className={styles.inputField}>
        <InputGroup flowDirection={"horizontal"}>
          <InputText
            placeholder="First name"
            name="firstName"
            value={fields.firstName}
            invalid={isInvalid("firstName")}
            onChange={onFirstNameChange}
            validations={{
              required: true,
            }}
          />
          <InputText
            placeholder="Last name"
            name="lastName"
            value={fields.lastName}
            invalid={isInvalid("lastName")}
            onChange={onLastNameChange}
            validations={{
              required: true,
            }}
          />
        </InputGroup>
      </div>
      <div className={styles.flexRow}>
        <div className={styles.flexBasis}>{getFormErrors("firstName")}</div>
        <div className={styles.flexBasis}>{getFormErrors("lastName")}</div>
      </div>

      <div className={classNames(styles.gridContainer, styles.inputField)}>
        <div className={styles.flexBasis}>
          <Select
            placeholder={"Position"}
            onChange={onJobTitleChange}
            invalid={isInvalid("jobPosition")}
            value={fields.jobPosition}
          >
            {!fields.jobPosition && (
              <Option value={""} key={"emptyJobPosition"} />
            )}
            {jobPositionsList.map(position => {
              return (
                <Option value={position.value} key={position.label}>
                  {position.label}
                </Option>
              );
            })}
          </Select>
          {getFormErrors("jobPosition")}
        </div>

        {fields.jobPosition === OTHER_JOB_TITLE && (
          <div className={styles.flexBasis}>
            <InputText
              placeholder="Title"
              name="businessTitle"
              value={fields.businessTitle}
              invalid={isInvalid("businessTitle")}
              onChange={onBusinessTitleChange}
              validations={{
                required: true,
              }}
            />
            {getFormErrors("businessTitle")}
          </div>
        )}
      </div>

      <div className={styles.inputField}>
        <InputEmail
          placeholder="Email address"
          name="email"
          value={fields.email}
          onChange={onEmailChange}
          onValidation={onEmailValidation}
          validations={{
            required: {
              value: true,
              message: errorMessages.email,
            },
          }}
        />
      </div>
      <div className={styles.inputField}>
        <InputText
          placeholder="Mobile phone number"
          name="phoneNumber"
          value={fields.phone}
          onChange={onPhoneChange}
          invalid={isInvalid("phone") || !!smsablePhoneError}
          validations={{
            required: true,
          }}
          description={OTPFlowEnabled ? messages.OTPInformation.message : ""}
        />
        {formSubmitted && getFormErrors("phone")}
        {/*for server side validation*/}
        {smsablePhoneError && fields.phone && (
          <InputValidation message={smsablePhoneError} />
        )}
      </div>
    </>
  );

  function isInvalid(field: keyof FormObjectKeysTypes) {
    return formSubmitted && !fields[field];
  }

  function getFormErrors(field: keyof FormObjectKeysTypes) {
    return (
      isInvalid(field) && <InputValidation message={errorMessages[field]} />
    );
  }

  function onFirstNameChange(value: string) {
    handleUpdate({ firstName: value });
  }

  function onJobTitleChange(value: string) {
    if (value !== OTHER_JOB_TITLE && fields.businessTitle) {
      onBusinessTitleChange("");
    }
    handleUpdate({ jobPosition: value });
  }

  function onBusinessTitleChange(value: string) {
    handleUpdate({ businessTitle: value });
  }

  function onLastNameChange(value: string) {
    handleUpdate({ lastName: value });
  }

  function onEmailChange(value: string) {
    handleUpdate({ email: value });
  }

  function onEmailValidation(value: string) {
    handleUpdate({
      formErrors: { ...formErrors, email: value || undefined },
    });
  }

  function onPhoneChange(value: string) {
    handleUpdate({ phone: value, smsablePhoneError: undefined });
    if (value === undefined) {
      handleUpdate({
        formErrors: { ...formErrors, phone: undefined },
      });
    }
  }
}

const SplitWrappedComponent = withSplitClient(RegistrationPointOfContact);
export { SplitWrappedComponent as RegistrationPointOfContact };
