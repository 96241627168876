import { defineMessages } from "react-intl";

export const messages = defineMessages({
  automatedReEngagePastClientsTitle: {
    id: "campaigns.useHeaderOptions.automatedReEngagePastClientsTitle",
    defaultMessage: "Re-engage past clients",
    description: "Title of the Automated Re-engage past clients template",
  },
  automatedReEngagePastClientsDescription: {
    id: "campaigns.useHeaderOptions.automatedReEngagePastClientsDescription",
    defaultMessage:
      "Automatically send an email to clients who haven’t booked a job with you in a while",
    description: "Description of the Automated Re-engage past clients template",
  },
  automatedReEngagePastClientsImgAlt: {
    id: "campaigns.useHeaderOptions.automatedReEngagePastClientsImgAlt",
    defaultMessage: "Automatically re-engage past clients",
    description:
      "Alt message of the Automatically Re-engage past clients template",
  },
  automatedWinBackLostLeadsTitle: {
    id: "campaigns.useHeaderOptions.automatedWinBackLostLeadsTitle",
    defaultMessage: "Win back lost leads",
    description: "Title of the Win back lost leads template",
  },
  automatedWinBackLostLeadsDescription: {
    id: "campaigns.useHeaderOptions.automatedWinBackLostLeadsDescription",
    defaultMessage:
      "Automatically recapture leads with archived quotes that were previously considered lost",
    description: "Description of the Win back lost leads template",
  },
  automatedWinBackLostLeadsImgAlt: {
    id: "campaigns.useHeaderOptions.automatedWinBackLostLeadsImgAlt",
    defaultMessage: "Automatically Win back lost leads",
    description:
      "Alt message of the Automatically Win back lost leads template",
  },
  oneOffStartFromScratchTitle: {
    id: "campaigns.useHeaderOptions.oneOffStartFromScratchTitle",
    defaultMessage: "Start from scratch",
    description: "Title of the Start from scratch template",
  },
  oneOffStartFromScratchDescription: {
    id: "campaigns.useHeaderOptions.oneOffStartFromScratchDescription",
    defaultMessage:
      "Already have an idea for a campaign in mind? Get creative and build your own email",
    description: "Description of the Start from scratch template",
  },
  oneOffStartFromScratchImgAlt: {
    id: "campaigns.useHeaderOptions.oneOffStartFromScratchImgAlt",
    defaultMessage: "Start from scratch",
    description: "Alt message of the Start from scratch template",
  },
  useTemplate: {
    id: "campaigns.useHeaderOptions.useTemplate",
    defaultMessage: "Use Template",
    description: "Label for Use Template Button",
  },
  automatedCampaignType: {
    id: "campaigns.useHeaderOptions.automatedCampaignTypeLabel",
    defaultMessage: "Automated",
    description: "Label for Automated Templates",
  },
  oneOffCampaignType: {
    id: "campaigns.useHeaderOptions.oneOffCampaignTypeLabel",
    defaultMessage: "One-off",
    description: "Label for One-off Templates",
  },
});
