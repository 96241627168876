import { useEffect, useRef } from "react";
import { showToast } from "@jobber/components/Toast";
import { useStateWithLocalStorage } from "utilities/localStorage";
import { Rollbar } from "~/utilities/errors/Rollbar";

export function useStoredUpdateResult() {
  const firstRender = useRef(true);

  const [storedUpdateResult, setStoredUpdateResult] =
    useStateWithLocalStorage<string>("billingUpdateResult", "", handleError);

  const [storedUpdateBannerMessage, setStoredUpdateBannerMessage] =
    useStateWithLocalStorage<string>(
      "billingMessageUsingBanner",
      "",
      handleError,
    );

  useEffect(() => {
    if (firstRender.current && storedUpdateResult?.length > 0) {
      showToast({ message: storedUpdateResult, variation: "success" });
      setStoredUpdateResult("");
    }
    firstRender.current = false;
  }, [storedUpdateResult, setStoredUpdateResult]);

  return {
    setStoredUpdateResult,
    storedUpdateBannerMessage,
    setStoredUpdateBannerMessage,
  };
}

export function handleError(error: Error) {
  Rollbar.EXECUTE(
    "useStoredUpdateResult failed to store update message",
    error,
  );

  return "";
}
