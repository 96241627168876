import { useMutation } from "@apollo/client";
import { useStripe } from "@stripe/react-stripe-js";
import { useCallback } from "react";
import {
  PaymentMethodSource,
  PaymentMethodVaultOrigin,
} from "~/utilities/API/graphql";
import type { AccountType } from "jobber/AddPaymentMethod/AddBankAccount/types";
import { CREATE_PAYMENT_METHOD } from "./CreatePaymentMethod.graphql";

export interface createAndStoreBankAccountInput {
  accountType: AccountType;
  routingNumber: string;
  accountNumber: string;
  accountOwnerName: string;
  consentGiven: boolean;
}

export const useAddBankAccount = (clientId: string) => {
  const stripe = useStripe();
  const [createPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD);

  const createAndStoreBankAccount = useCallback(
    async (
      bankAccountInfo: createAndStoreBankAccountInput,
    ): Promise<{ error?: string; success?: string }> => {
      if (!stripe || !bankAccountInfo) {
        return { error: "Stripe or bank account information missing." };
      }

      const stripeResponse = await stripe.createToken("bank_account", {
        country: "US",
        currency: "usd",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        routing_number: bankAccountInfo.routingNumber,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        account_number: bankAccountInfo.accountNumber,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        account_holder_name: bankAccountInfo.accountOwnerName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        account_holder_type: bankAccountInfo.accountType,
      });

      if (stripeResponse.error) {
        return { error: "Error adding bank account." };
      }

      const paymentResponse = await createPaymentMethod({
        variables: {
          clientId,
          input: {
            stripePaymentMethodId: stripeResponse.token.id,
            vaultOrigin: PaymentMethodVaultOrigin.JOBBER_ONLINE_CLIENT_ORIGIN,
            defaultMethod: true,
            paymentMethodSourceType: PaymentMethodSource.BANK_ACCOUNT,
            consentGiven: bankAccountInfo.consentGiven,
          },
        },
      });

      if (paymentResponse.errors) {
        return { error: "Error adding bank account." };
      }

      const userErrors = paymentResponse.data?.paymentMethodCreate?.userErrors;

      if (userErrors && userErrors.length > 0) {
        return { error: userErrors[0].message };
      }

      return { success: "Bank account added successfully." };
    },
    [clientId, createPaymentMethod, stripe],
  );

  const handleCreateBankAccount = useCallback(
    async (
      bankAccountInfo?: createAndStoreBankAccountInput,
    ): Promise<{ error?: string; success?: string }> => {
      if (!bankAccountInfo) {
        return { error: "Bank account information is missing." };
      }

      const result = await createAndStoreBankAccount(bankAccountInfo);

      if (result.error) {
        return { error: result.error, success: undefined };
      }

      return { success: result.success, error: undefined };
    },
    [createAndStoreBankAccount],
  );

  return {
    handleCreateBankAccount,
  };
};
