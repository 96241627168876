import React from "react";
import { Intercom } from "utilities/chat";

// Production Survey ID: 34086930
// Development Survey ID: 34249565
const DEFAULT_SURVEY_ID = "34086930";

interface CoreToConnectTrialPostTrialSurveyProps {
  surveyID?: string;
}

export function CoreToConnectTrialPostTrialSurvey({
  surveyID,
}: CoreToConnectTrialPostTrialSurveyProps) {
  if (!surveyID) {
    // Apply default survey key if none is provided
    surveyID = DEFAULT_SURVEY_ID;
  }
  Intercom.EXECUTE("startSurvey", surveyID);
  return <></>;
}
