import { Icon } from "@jobber/components/Icon";
import React from "react";
import type { CustomFieldConfigurationNodeFragment } from "~/utilities/API/graphql";
import styles from "./AppCustomFieldItem.module.css";
import { AppCustomFieldItemContent } from "../AppCustomFieldItemContent";

export interface AppCustomFieldItemProps {
  customFields: CustomFieldConfigurationNodeFragment[];
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void;
}

export function AppCustomFieldItem({
  customFields,
  onArchive,
}: AppCustomFieldItemProps) {
  return (
    <div
      key={`CustomFieldItem${customFields[0].id}`}
      className={styles.appCustomFieldItemContainer}
    >
      <div className={styles.appCustomFieldItemDragAnchor}>
        <Icon name="drag" />
      </div>
      <AppCustomFieldItemContent
        customFields={customFields}
        onArchive={onArchive}
      />
    </div>
  );
}
