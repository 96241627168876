import { useEffect } from "react";
import type { EventMap, TypedEventEmitter } from "./types";

interface UseSubscribeToEventEmitterProps<
  T extends EventMap,
  TKey extends keyof T,
> {
  eventEmitter: TypedEventEmitter<T>;
  listenerKey: TKey;
  listener: (...args: Parameters<T[TKey]>) => void;
}
export function useSubscribeToEmitterEvent<
  TEvents extends EventMap,
  TListenerKey extends keyof TEvents,
>({
  eventEmitter,
  listenerKey,
  listener,
}: UseSubscribeToEventEmitterProps<TEvents, TListenerKey>): void {
  useEffect(() => {
    const handleSaveEmittedState = (
      ...args: Parameters<TEvents[TListenerKey]>
    ) => {
      listener(...args);
    };
    eventEmitter.addListener(listenerKey, handleSaveEmittedState);
    return () => {
      eventEmitter?.removeListener(listenerKey, handleSaveEmittedState);
    };
  }, [eventEmitter, listener, listenerKey]);
}
