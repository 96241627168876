import { format, setDay, setMonth } from "date-fns";
// The logic here is unit-tested well so should be safe to reach into the module
// eslint-disable-next-line import/no-internal-modules
import { enUS } from "date-fns/locale";

export const computeAbbreviatedTimePeriod = (monthOrDay: string) => {
  try {
    const monthIndex = enUS.match?.month(monthOrDay)?.value;
    if (monthIndex != null) {
      return format(setMonth(new Date(), monthIndex), "LLL");
    }

    const dayIndex = enUS.match?.day(monthOrDay)?.value;
    if (dayIndex != null) {
      return format(setDay(new Date(), dayIndex), "EEE");
    }

    return monthOrDay;
  } catch {
    return monthOrDay;
  }
};
