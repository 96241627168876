import React from "react";
import { Chip, Chips } from "@jobber/components/Chips";
import { Icon } from "@jobber/components/Icon";

export enum CONVERSATION_TYPE_FILTER {
  ALL = "",
  PHONE = "phone",
  EMAIL = "email",
}

export interface ConversationTypeFilterInputProps {
  conversationTypeFilter: CONVERSATION_TYPE_FILTER;
  onFilterChange(value: CONVERSATION_TYPE_FILTER): void;
}

export function ConversationTypeFilterInput({
  conversationTypeFilter,
  onFilterChange,
}: ConversationTypeFilterInputProps) {
  return (
    <div className="u-paddingTopSmall u-paddingLeftNone u-paddingBottomNone">
      <Chips selected={conversationTypeFilter} onChange={onFilterChange}>
        <Chip label="All" value={CONVERSATION_TYPE_FILTER.ALL} />
        <Chip
          label="Text Message"
          value={CONVERSATION_TYPE_FILTER.PHONE}
          prefix={<Icon name="sms2" />}
        />
        <Chip
          label="Email"
          value={CONVERSATION_TYPE_FILTER.EMAIL}
          prefix={<Icon name="email" />}
        />
      </Chips>
    </div>
  );
}
