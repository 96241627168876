import React from "react";
import { Text } from "@jobber/components/Text";

export function StandardRegistrationInformation() {
  return (
    <>
      <Text>
        For better delivery quality and lower filtering risks by U.S. carriers,
        all businesses that send messages via a standard 10-digit long code must
        complete this registration.
      </Text>
      <Text>
        Check out{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://help.getjobber.com/hc/en-us/articles/1500008684501"
        >
          Register Your Number{" "}
        </a>
        in the Help Centre for more information.
      </Text>
    </>
  );
}
