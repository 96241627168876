import React, { createRef } from "react";
import type { EventInput } from "@fullcalendar/core";
import { InputNumber } from "@jobber/components/InputNumber";
import { Page } from "@jobber/components/Page";
import type FullCalendar from "@fullcalendar/react";
import { DayView } from "jobber/workOrders/components/SchedulingAssistant/components/DayView/DayView";
import {
  getAppointments,
  getUsers,
} from "./components/SampleScheduleData/sampleData";
import styles from "./DayViewPage.module.css";

export const DayViewPage = () => {
  const [appointmentCount, setAppointmentCount] = React.useState(16);
  const [userCount, setUserCount] = React.useState(10);
  const [appointments, setAppointments] = React.useState<EventInput[]>(
    getAppointments(appointmentCount, userCount),
  );

  function onUserCountChange(newValue: number) {
    setUserCount(newValue);
    setAppointments(getAppointments(appointmentCount, newValue));
  }

  function onAppointmentCountChange(newValue: number) {
    setAppointmentCount(newValue);
    setAppointments(getAppointments(newValue, userCount));
  }
  const ref = createRef<FullCalendar>();

  return (
    <Page title="Schedule Availability">
      <div className={styles.container}>
        <div className={styles.spacing}>
          <span>User Count</span>
          <InputNumber
            value={userCount}
            onChange={(newValue: number) => onUserCountChange(newValue)}
          />
        </div>

        <div className={styles.spacing}>
          <span>Appointment Count</span>
          <InputNumber
            value={appointmentCount}
            onChange={(newValue: number) => onAppointmentCountChange(newValue)}
          />
        </div>
      </div>
      <DayView
        onSelectSlot={() => undefined}
        selectedDate={new Date()}
        events={appointments}
        employees={getUsers(userCount)}
        onDragSelection={() => false}
        onResizeSelection={() => false}
        calendarRef={ref}
      />
    </Page>
  );
};
