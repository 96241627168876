import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "customFields.CustomFieldDeleteConfirmationModal.title",
    defaultMessage: "Delete {customFieldName} custom field",
    description: "Title for the delete custom field modal",
  },
  description: {
    id: "customFields.CustomFieldDeleteConfirmationModal.description",
    defaultMessage:
      "Deleting this custom field will remove all associated values from your Jobber account permanently. This action can't be undone.",
    description:
      "Explains to the user what will happen if they confirm the deletion of the custom field",
  },
  disclaimer: {
    id: "customFields.CustomFieldDeleteConfirmationModal.disclaimer",
    defaultMessage: "This change will affect:",
    description: "Text for the disclaimer in the delete custom field modal",
  },
  confirmationCheck: {
    id: "customFields.CustomFieldDeleteConfirmationModal.confirmationCheck",
    defaultMessage:
      "I understand that deleting this custom field is permanent and can't be undone",
    description:
      "Text for the confirmation checkbox in the delete custom field modal",
  },
});
