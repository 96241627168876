import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { useRefocusOnActivator } from "@jobber/hooks/useRefocusOnActivator";
import styles from "./SG1PopDown.module.css";

interface SG1PopDownProps {
  readonly title: string;
  readonly attachTo: Element | React.RefObject<Element | null>;
  readonly children: React.ReactNode;
  readonly open: boolean;
  closePopDown(): void;
}

export function SG1PopDown({
  closePopDown,
  title,
  children,
  attachTo,
  open,
}: SG1PopDownProps) {
  const popperElement = useRef() as MutableRefObject<HTMLDivElement>;
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(
    attachTo instanceof Element ? attachTo : attachTo.current,
    popperElement.current,
    {
      modifiers: buildModifiers(arrowElement),
      placement: "bottom",
    },
  );
  useRefocusOnActivator(open);

  const closeOpenMenu = (e: MouseEvent) => {
    const attachedToElement =
      attachTo instanceof Element ? attachTo : attachTo.current;
    if (
      popperElement.current &&
      !popperElement.current.contains(e.target as Node) &&
      !attachedToElement?.contains(e.target as Node)
    ) {
      closePopDown();
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", closeOpenMenu);
  }, []);

  return (
    <>
      {open && (
        <div
          role="dialog"
          ref={popperElement}
          style={popperStyles.popper}
          className={styles.popover}
          {...attributes.popper}
        >
          <div className={styles.contentTitle}>{title}</div>
          {children}
          <div
            ref={setArrowElement}
            className={styles.arrow}
            style={popperStyles.arrow}
          />
        </div>
      )}
    </>
  );
}

function buildModifiers(arrowElement: HTMLElement | undefined | null) {
  // modifiers
  return [
    {
      name: "arrow",
      options: { element: arrowElement, padding: 16 },
    },
    {
      name: "offset",
      options: {
        offset: [-159, 9],
      },
    },
    {
      name: "flip",
      options: {
        fallbackPlacements: ["auto"],
      },
    },
  ];
}
