import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expiryDate: {
    id: "vaultedCards.storedCardListItem.expiryDate",
    defaultMessage: "Expiry {expiryDate}",
    description: "Expiry date of the card",
  },
  defaultLabel: {
    id: "vaultedCards.storedCardListItem.defaultLabel",
    defaultMessage: "Default",
    description: "Default label for the default payment method",
  },
  bankAccountTitle: {
    id: "vaultedCards.storedCardListItem.bankAccountTitle",
    defaultMessage: "Bank Account",
    description: "Bank Account title used if no bank name is provided",
  },
});
