import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import type { WorkObjectGlobalOwnershipFragment } from "~/utilities/API/graphql";
import { WorkObjectGlobalOwnershipsContent } from "jobber/features/WorkObjectOwnerships/views/WorkObjectGlobalOwnershipsCard/components/WorkObjectGlobalOwnershipsContent";

export interface WorkObjectGlobalOwnershipsCardProps {
  ownerships: WorkObjectGlobalOwnershipFragment[];
  error: string | undefined;
  canSeeButton?: boolean;
}

export function WorkObjectGlobalOwnershipsCard({
  ownerships,
  error,
}: WorkObjectGlobalOwnershipsCardProps) {
  return (
    <Card title={"Email replies and notifications"}>
      <Content>
        <Error {...{ error }} />
        <WorkObjectGlobalOwnershipsContent ownerships={ownerships} />
      </Content>
    </Card>
  );
}

function Error({ error }: Pick<WorkObjectGlobalOwnershipsCardProps, "error">) {
  return <>{error && <Banner type={"error"}>{error}</Banner>}</>;
}
