import { gql } from "@apollo/client";

export const CAMPAIGN_DELETE = gql`
  mutation campaignDelete($campaignId: EncodedId!) {
    campaignDelete(campaignId: $campaignId) {
      userErrors {
        message
        path
      }
      campaign {
        id
      }
    }
  }
`;
