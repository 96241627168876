import type { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { type CommsCampaignQuery, Template } from "~/utilities/API/graphql";
import {
  type CommsCampaignTemplate,
  transformCampaignTemplate,
} from "jobber/campaigns/views/CampaignsContentPage/hooks/utils/transformCampaignTemplate";
import { CAMPAIGNS_LANDING_PAGE_PATH } from "jobber/campaigns/constants";
import { useCommsCampaignQuery } from "jobber/campaigns/hooks/useCommsCampaignQuery";
import { useCommsCampaignDefaultTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignDefaultTemplateQuery";
import { useCommsContentState } from "jobber/campaigns/hooks/useCommsContentState";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";

interface useCommsCampaignTemplateQueryProps {
  campaignId?: string;
  defaultTemplateType?: Template;
}

interface useCommsCampaignTemplateQuery {
  loading: boolean;
  template: CommsCampaignTemplate;
  campaign: CommsCampaignQuery["commsCampaign"] | undefined;
  userEmail: string;
  error?: ApolloError;
}

export function useCommsCampaignTemplateQuery({
  campaignId,
  defaultTemplateType = Template.RE_ENGAGE,
}: useCommsCampaignTemplateQueryProps = {}): useCommsCampaignTemplateQuery {
  const [campaignNotFound, setCampaignNotFound] = useState(false);
  const [shouldSkipDefaultTemplateQuery, setShouldSkipDefaultTemplateQuery] =
    useState(!!campaignId);

  const history = useHistory();

  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(["email"]);

  const {
    loading: campaignLoading,
    data: campaignData,
    error: campaignError,
  } = useCommsCampaignQuery({
    campaignId: campaignId,
    skip: !campaignId,
    onCampaignNotFound: () => {
      setCampaignNotFound(true);
      history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
    },
  });

  useEffect(() => {
    const templatesLength =
      campaignData?.commsCampaign?.templates?.nodes?.length || 0;
    if (!campaignLoading && templatesLength == 0 && !campaignNotFound) {
      setShouldSkipDefaultTemplateQuery(false);
    }
  }, [campaignData, campaignNotFound, campaignLoading]);

  const {
    loading: defaultLoading,
    data: defaultData,
    error: defaultError,
  } = useCommsCampaignDefaultTemplateQuery({
    defaultTemplateType,
    skip: shouldSkipDefaultTemplateQuery,
    fetchPolicy: "no-cache",
  });

  const data =
    campaignData?.commsCampaign?.templates?.nodes?.[0] ||
    defaultData?.commsCampaignDefaultTemplate;

  const template = useMemo(() => {
    return transformCampaignTemplate(data);
  }, [data]);

  useCommsContentState(template);

  return {
    loading: campaignLoading || defaultLoading || userLoading,
    template,
    userEmail: currentUser.email?.raw || "",
    campaign: campaignData?.commsCampaign,
    error: campaignError || defaultError || userError,
  };
}
