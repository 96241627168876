import React, { useState } from "react";
import { Page } from "@jobber/components/Page";
import styles from "./styles.module.css";
import { Scheduler } from "./components/Scheduler";
import { useScheduler } from "./components/Scheduler/useScheduler";
import type { SchedulerState } from "./components/Scheduler/types";

export function SchedulerPage() {
  const [schedulerState, setSchedulerState] = useScheduler();
  const [calendarIsHidden, setCalendarIsHidden] = useState(false);

  const onHideCalendar = () => setCalendarIsHidden(!calendarIsHidden);

  const onChangeSchedule = (partialNextSchedule: Partial<SchedulerState>) =>
    setSchedulerState({ ...schedulerState, ...partialNextSchedule });

  return (
    <Page title="Scheduler" width="fill">
      <div className={styles.gridContainer}>
        <div>
          <div style={{ maxWidth: 450 }}>
            <Scheduler
              onHideCalendar={onHideCalendar}
              onChange={onChangeSchedule}
              calendarIsHidden={calendarIsHidden}
              {...schedulerState}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}
