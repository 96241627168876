import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import styles from "./SetupGuideHeader.module.css";
import { messages } from "./messages";

export interface SetupGuideHeaderProps {
  onClose?(): void;
}

export function SetupGuideHeader({ onClose }: SetupGuideHeaderProps) {
  return (
    <>
      <div className={styles.header}>
        <Heading level={3}>
          {messages.growTrialOnboardingChecklistHeaderText.defaultMessage}
        </Heading>
        <Button
          icon="cross"
          ariaLabel="Dismiss"
          variation="subtle"
          type="tertiary"
          onClick={handleClick}
        />
      </div>
    </>
  );

  function handleClick() {
    onClose?.();
  }
}
