import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { Source } from "jobber/reviews/views/ReviewsPage/context/types";
import { messages } from "./messages";
import { defaultResources } from "./defaultResources";
import { CustomizationCTA } from "./CustomizationCTA";
import type { ResourceLinkProps, ResourcesProps } from "./types";

export function Resources({ showCTA, resources }: ResourcesProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { drawerActions } = useContext(ReviewsSettingsDrawerContext);

  return (
    <Content>
      <Heading level={3}>{formatMessage(messages.heading)}</Heading>
      {showCTA && (
        <Content spacing={"small"}>
          <Heading level={4}>
            {formatMessage(messages.customizationCTAHeading)}
          </Heading>
          <CustomizationCTA
            onClick={() => {
              drawerActions?.goTo(DrawerView.MessageSettings, Source.insights);
            }}
            message={messages.templateText}
          />
        </Content>
      )}
      {(resources ?? defaultResources).map(resource => (
        <ResourceLink key={resource.link} {...resource} />
      ))}
    </Content>
  );
}

function ResourceLink({ title, link, type }: ResourceLinkProps): JSX.Element {
  const { formatMessage } = useIntl();
  const linkText = (() => {
    switch (type) {
      case "video":
        return formatMessage(messages.videoLinkText);
      case "article":
        return formatMessage(messages.articleLinkText);
    }
  })();

  return (
    <div>
      <Text>{title}</Text>
      <a href={link} target="_blank" rel="noreferrer">
        {linkText}
      </a>
    </div>
  );
}
