import React from "react";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Page } from "@jobber/components/Page";
import { Link } from "@jobber/components/Link";
import { useIntl } from "react-intl";
import styles from "./CardHolderWatchListReviewAccessError.module.css";
import { messages } from "./messages";
import { goHome } from "../../utils";

export function CardHolderWatchListReviewAccessError() {
  const { formatMessage } = useIntl();
  const [ref, { width }] = useResizeObserver<HTMLDivElement>();
  const isMobileWidth = !width || width < Breakpoints.small;

  return (
    <div ref={ref} className={styles.pageContainer}>
      <Page title="" width="narrow">
        <div className={styles.innerPageContainer}>
          <div className={styles.iconContainer}>
            <ErrorIcon />
          </div>

          <Content>
            <Heading level={1}>{formatMessage(messages.heading)}</Heading>
          </Content>

          <Content>
            {formatMessage(messages.descriptionFirst)}
            <Link url={"mailto:support@getjobber.com"}>
              support@getjobber.com
            </Link>
          </Content>

          <div className={styles.buttonContainer}>
            <Button
              variation="work"
              type="primary"
              label={formatMessage(messages.buttonLabel)}
              size={isMobileWidth ? "base" : "large"}
              fullWidth={isMobileWidth}
              onClick={goHome}
            />
          </div>
        </div>
      </Page>
    </div>
  );
}

function ErrorIcon() {
  const src = window.SG1_IMAGE_MANIFEST["svg/errorState-money-management.svg"];
  return <img alt={"success"} src={src} />;
}
