import React, { type ReactNode } from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import { ListItem } from "components/ListItem/ListItem";

export function SimpleListItem(props: {
  leftText: ReactNode;
  rightText: ReactNode;
}) {
  const { leftText, rightText } = props;
  return (
    <ListItem
      leftNode={leftText}
      rightNode={<Emphasis variation="bold">{rightText}</Emphasis>}
    />
  );
}
