/**
 * Builds a flash message html string.
 *
 * {@link http://sg-1.getjobber.com/components/shared/flash | SG-1 Flash Messages}
 *
 * @export
 * @param details a list of errors
 * @param header the error summary
 * @returns Flash error message as an HTML string
 */
export function buildFlashErrorHTML(details: string[], header: string) {
  const errorList = details.map(message => `<li>${message}</li>`).join("");

  return `
      <div class="flash flash--error js-errorMessage">
        <div class="flash-content">
          ${header}
          <ul class="flash-list u-textSmall">
            ${errorList}
          </ul>
        </div>
      </div>
    `;
}
