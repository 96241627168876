import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Content } from "@jobber/components/Content";
import { Spinner } from "@jobber/components/Spinner";
import type {
  BankAccountPaymentMethod,
  CreditCardPaymentMethod,
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
} from "~/utilities/API/graphql";
import { FrequencyType } from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicSelectedFrequency";
import { mapToStoredPaymentMethod } from "jobber/payments_react/utils/paymentMethodMappers";
import { SetUpJobberPaymentsCTA } from "jobber/SetUpJobberPaymentsCTA/SetUpJobberPaymentsCTA";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import type { InvoiceFrequencySettings } from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicSelectedFrequency";
import type { Client } from "jobber/workOrders/components/AutomaticPayments/hooks/useDynamicClient";
import { EnableOnCardAddSwitch } from "./components/EnableOnCardAddSwitch";
import { CardOnFileSwitch } from "./components/CardOnFileSwitch";
import { PAYMENT_METHODS_QUERY } from "./AutomaticPayments.graphql";
import styles from "./AutomaticPayments.module.css";
import { SendCardOnFileRequestOptions } from "./components/SendCardOnFileRequest/SendCardOnFileRequestOptions";
import { AutomaticPaymentsExpansionCard } from "./components/AutomaticPaymentsExpansionCard/AutomaticPaymentsExpansionCard";

export interface AutomaticPaymentsProps {
  shouldEnableWorkOrderOnPaymentMethodAdd: boolean;
  automaticallyChargeInvoice: boolean;
  disableSwitch: boolean;
  availableOnPlan: boolean;
  jobberPaymentsEnabled: boolean;
  client: Client | undefined;
  selectedFrequency: InvoiceFrequencySettings | undefined;
  newRecord: boolean;
}

export function AutomaticPayments({
  shouldEnableWorkOrderOnPaymentMethodAdd,
  automaticallyChargeInvoice,
  disableSwitch,
  availableOnPlan,
  jobberPaymentsEnabled,
  client,
  selectedFrequency,
  newRecord,
}: AutomaticPaymentsProps) {
  const [paymentMethod, setPaymentMethod] = useState<
    StoredPaymentMethodInterface | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const [getPaymentMethods] = useLazyQuery<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >(PAYMENT_METHODS_QUERY);

  useEffect(() => {
    if (client?.encodedId) {
      setPaymentMethod(undefined);

      const fetchData = async () => {
        setLoading(true);

        const { data } = await getPaymentMethods({
          variables: { clientId: client.encodedId },
        });

        setLoading(false);

        const paymentMethods:
          | (BankAccountPaymentMethod | CreditCardPaymentMethod)[]
          | undefined = data?.paymentMethods?.nodes;

        if (paymentMethods && paymentMethods.length > 0) {
          const defaultPaymentMethod =
            paymentMethods.find(pm => pm.defaultMethod === true) ||
            paymentMethods[0];

          const usablePaymentMethod =
            mapToStoredPaymentMethod(defaultPaymentMethod);

          setPaymentMethod(usablePaymentMethod);

          return;
        }
      };

      fetchData().catch(() => {
        setErrorMessage(
          "Something went wrong. Could not retrieve default payment method.",
        );
      });
    }
  }, [client?.encodedId, getPaymentMethods]);

  const notAvailableOnPlanOrEnabled =
    !availableOnPlan || !jobberPaymentsEnabled;

  const [shouldDisableSwitch, setShouldDisableSwitch] = useState(
    disableSwitch ||
      selectedFrequency?.frequencyType === FrequencyType.AS_NEEDED ||
      notAvailableOnPlanOrEnabled,
  );

  useEffect(() => {
    if (selectedFrequency?.frequencyType === FrequencyType.AS_NEEDED) {
      setShouldDisableSwitch(true);
    } else {
      setShouldDisableSwitch(notAvailableOnPlanOrEnabled);
    }
  }, [selectedFrequency, client?.encodedId, notAvailableOnPlanOrEnabled]);

  function renderContent() {
    const loadedContent = paymentMethod ? (
      <CardOnFileSwitch
        paymentMethod={paymentMethod}
        errorMessage={errorMessage}
        selectedFrequency={selectedFrequency}
        automaticallyChargeInvoice={automaticallyChargeInvoice}
        disableSwitch={shouldDisableSwitch}
        newRecord={newRecord}
        availableOnPlan={availableOnPlan}
      />
    ) : (
      <Content>
        <EnableOnCardAddSwitch
          shouldEnableWorkOrderOnPaymentMethodAdd={
            shouldEnableWorkOrderOnPaymentMethodAdd
          }
          disableSwitch={shouldDisableSwitch}
          newRecord={newRecord}
        />
        {!jobberPaymentsEnabled && <SetUpJobberPaymentsCTA />}
        {jobberPaymentsEnabled && (
          <SendCardOnFileRequestOptions
            client={client}
            availableOnPlan={availableOnPlan}
          />
        )}
      </Content>
    );

    return (
      <div className={styles.automaticPaymentsContainer}>
        {loading ? <Spinner /> : loadedContent}
      </div>
    );
  }

  return availableOnPlan ? (
    renderContent()
  ) : (
    <div className={styles.automaticPaymentsContainer}>
      <AutomaticPaymentsExpansionCard />
      {paymentMethod && (
        <CardOnFileSwitch
          paymentMethod={paymentMethod}
          errorMessage={errorMessage}
          selectedFrequency={selectedFrequency}
          automaticallyChargeInvoice={automaticallyChargeInvoice}
          disableSwitch={shouldDisableSwitch}
          newRecord={newRecord}
          availableOnPlan={availableOnPlan}
        />
      )}
      {jobberPaymentsEnabled && !paymentMethod && (
        <SendCardOnFileRequestOptions
          client={client}
          availableOnPlan={availableOnPlan}
        />
      )}
    </div>
  );
}
