export const messages = {
  growTrialHeading: {
    id: "donePanelHeading",
    defaultMessage: "Nice job!",
    description: "Onboarding checklist heading for done panel",
  },
  growTrialSubHeading: {
    id: "donePanelSubHeading",
    defaultMessage:
      "Have questions about how you can use them to help your business grow? Book time with your account manager to get more business insights.",
    description: "Onboarding checklist subheading for done panel",
  },
  growTrialButton: {
    id: "donePanelButton",
    defaultMessage: "Book a Call",
    description: "Onboarding checklist button for done panel",
  },
  growTrialImageAlt: {
    id: "donePanelImageAlt",
    defaultMessage: "Done title on grow trial onboarding guide",
    description: "Onboarding checklist image alt for done panel",
  },
};
