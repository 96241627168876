import React, { useEffect } from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { List } from "@jobber/components/List";
import type { IconNames } from "@jobber/components/Icon";
import { Divider } from "@jobber/components/Divider";
import moment from "moment";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { setLocalStorageItems } from "jobber/dashboard/components/Home/HomeClientDataStore";
import styles from "./InsightCard.module.css";

export interface InsightListData {
  id: string;
  icon: IconNames;
  content: string;
  value?: string;
  url: string;
  caption?: string;
  onClick?(): void;
}

interface InsightCardProps {
  title: string;
  description: string;
  actionLabel: string;
  actionUrl: string;
  listItemData?: InsightListData[];
  rawNumber?: number;
  hideEmptyCard: boolean;
  trackEvents: boolean;
  renderWith?(value: number): string;
  onButtonClick?(): void;
}

export function InsightCard({
  title,
  description,
  actionLabel,
  actionUrl,
  listItemData,
  rawNumber,
  hideEmptyCard,
  renderWith,
  trackEvents,
  onButtonClick,
}: InsightCardProps) {
  const onActionButtonClick = async (e: React.MouseEvent) => {
    const mouseBtn = e.button || 0;
    if (onButtonClick) {
      onButtonClick();
    }
    trackActionButtonEvent(title, mouseBtn);
  };

  if (hideEmptyCard) {
    return <></>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (trackEvents) {
      setLocalStorageItems({
        event: {
          event: "insight card render",
          source: title,
          timestamp: moment(Date.now()).format(),
        },
      });
    }
  }, []);

  return (
    <div className={styles.insight}>
      <Heading level={4}>{title}</Heading>
      {rawNumber != undefined && (
        <Heading level={2}>
          {renderWith ? renderWith(rawNumber) : rawNumber}
        </Heading>
      )}
      <Text>{description}</Text>
      {listItemData && (
        <InsightList
          listItemData={listItemData}
          insightType={title}
          trackEvents={trackEvents}
        />
      )}
      <div className={styles.container}>
        <Button
          label={actionLabel}
          url={actionUrl}
          type="secondary"
          onMouseDown={onActionButtonClick}
          size="small"
        />
      </div>
      <div className={styles.divider}>
        <Divider />
      </div>
    </div>
  );

  function trackActionButtonEvent(insightType: string, mouseButton: number) {
    const eventName = "Clicked Insight Card";
    const eventProperty = {
      source: insightType,
      type: "Primary Action",
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
    if (trackEvents) {
      setLocalStorageItems({
        event: {
          event: "insight action button click",
          source: title,
          timestamp: moment(Date.now()).format(),
          data: {
            unit: "mouse_button",
            value: [mouseButton],
          },
        },
      });
    }
  }
}

interface InsightListProp {
  listItemData: InsightListData[];
  insightType: string;
  trackEvents: boolean;
}

export function InsightList({
  listItemData,
  insightType,
  trackEvents,
}: InsightListProp) {
  listItemData.map(listItem => {
    listItem.onClick = trackListClickEvent;
  });
  return <List items={listItemData} />;

  function trackListClickEvent() {
    const eventName = "Clicked Insight Card";
    const eventProperty = {
      source: insightType,
      type: "List Item",
    };

    Amplitude.TRACK_EVENT(eventName, eventProperty);
    if (trackEvents) {
      setLocalStorageItems({
        event: {
          event: "insight list item click",
          source: insightType,
          timestamp: moment(Date.now()).format(),
        },
      });
    }
  }
}
