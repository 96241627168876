import {
  Celebrate100ReviewsAssets,
  Celebrate200ReviewsAssets,
  Celebrate25ReviewsAssets,
  Celebrate50ReviewsAssets,
} from "./assets";
import { messages } from "./messages";

export enum MilestoneCTA {
  TwentyFive = "jobber_google_reviews_25_milestone_cta",
  Fifty = "jobber_google_reviews_50_milestone_cta",
  Hundred = "jobber_google_reviews_100_milestone_cta",
  TwoHundredPlus = "jobber_google_reviews_200_plus_milestone_cta",
}

export function getMilestoneValues(milestone: MilestoneCTA) {
  switch (milestone) {
    case MilestoneCTA.TwentyFive:
      return {
        subHeading: messages.celebrate25SubHeading,
        amount: 25,
        ...Celebrate25ReviewsAssets,
      };
    case MilestoneCTA.Fifty:
      return {
        subHeading: messages.celebrate50SubHeading,
        amount: 50,
        ...Celebrate50ReviewsAssets,
      };
    case MilestoneCTA.Hundred:
      return {
        subHeading: messages.celebrate100SubHeading,
        amount: 100,
        ...Celebrate100ReviewsAssets,
      };
    case MilestoneCTA.TwoHundredPlus:
      return {
        subHeading: messages.celebrate200SubHeading,
        amount: 200,
        ...Celebrate200ReviewsAssets,
      };
    default:
      return {
        subHeading: messages.celebrate25SubHeading,
        amount: 25,
        ...Celebrate25ReviewsAssets,
      };
  }
}
