import React from "react";
import { Heading } from "@jobber/components/Heading";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Cell, HeaderColumns } from "components/Table";
import { messages } from "./messages";

interface ColumnType {
  name: MessageDescriptor;
  align?: "left" | "center" | "right";
}

const headerColumns = [
  {
    name: messages.expensesTableItemColumnHeader,
  },
  {
    name: messages.expensesTableDescriptionColumnHeader,
  },
  {
    name: undefined,
  },
  {
    name: messages.expensesTableDateColumnHeader,
  },
  {
    name: messages.expensesTableAmountColumnHeader,
    align: "right",
  },
] as ColumnType[];

export function ExpensesTableHeaderColumns() {
  const { formatMessage } = useIntl();

  return (
    <HeaderColumns>
      {headerColumns.map(column => {
        const columnName = column.name ? formatMessage(column.name) : "";

        return (
          <Cell key={columnName} size="small" align={column.align}>
            <Heading level={6}>{columnName}</Heading>
          </Cell>
        );
      })}
    </HeaderColumns>
  );
}
