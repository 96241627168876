import { gql } from "@apollo/client";

export const SEND_OTP_MUTATION = gql`
  mutation SendOTP {
    sendVerificationCode {
      userErrors {
        message
      }
    }
  }
`;

export const SUBMIT_OTP_MUTATION = gql`
  mutation SubmitOTP($code: String!) {
    submitVerificationCode(code: $code) {
      status
      userErrors {
        message
        path
      }
    }
  }
`;
