import React from "react";
import { QuickbooksLegacyMigrationFeedbackModal } from "./QuickbooksLegacyMigrationFeedbackModal";

interface QuickbooksLegacyMigrationFeedbackModalLoaderProps {
  open: boolean;
  onRequestClose: () => void;
}

export function QuickbooksLegacyMigrationFeedbackModalLoader(
  props: QuickbooksLegacyMigrationFeedbackModalLoaderProps,
) {
  const { open, onRequestClose } = props;

  return (
    <QuickbooksLegacyMigrationFeedbackModal
      open={open}
      onRequestClose={onRequestClose}
    />
  );
}
