import { useQuery } from "@apollo/client";
import type React from "react";
import { useEffect } from "react";
import type { TwilioRegistrationStatusQuery } from "~/utilities/API/graphql";
import { TWILIO_REGISTRATION_STATUS } from "./TwilioRegistrationMessage.graphql";
import type { MessageCenterActionType } from "../MessageCenterContext";

export function useRegistrationStatus(
  dispatch: React.Dispatch<MessageCenterActionType>,
) {
  const { data, loading } = useQuery<TwilioRegistrationStatusQuery>(
    TWILIO_REGISTRATION_STATUS,
  );

  useEffect(() => {
    dispatch({
      type: "SET_REGISTRATION_STATUS",
      registrationStatus: data?.communicationSettings?.registrationStatus,
    });
  }, [dispatch, data]);

  return loading;
}
