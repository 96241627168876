import type { File } from "~/utilities/API/graphql";

export interface HeaderImageFile extends File {
  uploadUrl?: string;
  src?: Promise<string>;
}

export interface FromServerCondition {
  name: string;
  arguments: {
    expression: string;
    include: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    work_item_id: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    job_type: string;
  };
}

export enum ServerRuleConditionTypes {
  ClientHasTags = "client_has_tags",
  QuoteClientHasTags = "quote_client_has_tags",
  ClientHasJobLineItems = "client_has_job_line_items",
  QuoteHasLineItems = "quote_has_line_items",
  LineItems = "line_items",
  JobType = "job_type_check",
  ClientLeadStatus = "client_lead_status_check",

  // We have decided that different automation templates will have different options for the same condition types
  // Quote status is evaluated the same way on the server with the same predicate expressions but we identify the different
  // conditions so that we can present unique option sets in the UI.
  QuoteStatus = "quote_status_check", // Used in Lost Leads Automation
  CloseOnPendingQuotesQuoteStatus = "close_on_pending_quotes_quote_status_check",
}
