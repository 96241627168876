import { InputText } from "@jobber/components/InputText";
import React, { type Dispatch } from "react";
import { JobberOnline } from "jobber/payments/utils";
import { usePaymentSubmit } from "jobber/payments_react/hooks/usePaymentSubmit/usePaymentSubmit";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { InputLabel } from "./InputLabel";
import type { PaymentAction } from "./paymentReducer";
import { Sg1DatePicker } from "./Sg1DatePicker";

const CHEQUE = JobberOnline.constants.balanceAdjustments.paymentTypes.cheque;
const BANK_TRANSFER =
  JobberOnline.constants.balanceAdjustments.paymentTypes.bankTransfer;
const ACH_BANK_PAYMENT =
  JobberOnline.constants.balanceAdjustments.paymentTypes.achBankPayment;
const CREDIT_CARD =
  JobberOnline.constants.balanceAdjustments.paymentTypes.creditCard;
const E_TRANSFER =
  JobberOnline.constants.balanceAdjustments.paymentTypes.eTransfer;
const PAYPAL = JobberOnline.constants.balanceAdjustments.paymentTypes.paypal;
const OTHER = JobberOnline.constants.balanceAdjustments.paymentTypes.other;
const CASH_APP = JobberOnline.constants.balanceAdjustments.paymentTypes.cashApp;
const VENMO = JobberOnline.constants.balanceAdjustments.paymentTypes.venmo;
const ZELLE = JobberOnline.constants.balanceAdjustments.paymentTypes.zelle;

interface PaymentRecordFormProps {
  defaultNoteValue?: string;
}

interface SuccessResponse {
  success: true;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  new_balance: string;
}

interface FailResponse {
  success: false;
  message: string;
}

type Response = SuccessResponse | FailResponse;

// eslint-disable-next-line max-statements
export function PaymentRecordForm(props: PaymentRecordFormProps) {
  const { defaultNoteValue } = props;
  const [paymentState, paymentDispatcher] = usePaymentReducer();
  paymentDispatcher({ type: "completingInitialization" });
  const paymentType = +paymentState.paymentType;
  const isPaymentInProgress = paymentState.status === "inProgress";
  const [recordPaymentUrl] = useUrls("recordPaymentUrl");
  const { currencySymbol } = useAccount();
  const [t] = useTranslation();

  usePaymentSubmit(paymentState, paymentDispatcher, {
    submitWithFormData: submit,
  });

  const paymentMethodsWithReferenceNumber = [
    BANK_TRANSFER,
    OTHER,
    ZELLE,
    CASH_APP,
    PAYPAL,
    VENMO,
    E_TRANSFER,
    ACH_BANK_PAYMENT,
  ];

  return (
    <>
      <InputLabel label="Transaction date">
        <Sg1DatePicker
          name="balance_adjustment[clean_entry_date]"
          disabled={isPaymentInProgress}
        />
      </InputLabel>
      {paymentType === CHEQUE && (
        <InputLabel label={t("chequeNumber")}>
          <InputText
            name="balance_adjustment[cheque_number]"
            disabled={isPaymentInProgress}
          />
        </InputLabel>
      )}
      {paymentType === CREDIT_CARD && (
        <InputLabel label={t("transactionNumber")}>
          <InputText
            name="balance_adjustment[cc_transaction_number]"
            disabled={isPaymentInProgress}
          />
        </InputLabel>
      )}
      {paymentMethodsWithReferenceNumber.includes(paymentType) && (
        <InputLabel label={t("referenceNumber")}>
          <InputText
            name="balance_adjustment[confirmation_number]"
            disabled={isPaymentInProgress}
          />
        </InputLabel>
      )}
      <InputLabel label="Details">
        <InputText
          name="balance_adjustment[note]"
          disabled={isPaymentInProgress}
          multiline={true}
          defaultValue={defaultNoteValue}
        />
      </InputLabel>
    </>
  );

  async function submit(
    formData: FormData,
    cancelAwareDispatcher: Dispatch<PaymentAction>,
  ) {
    const response = await fetch(recordPaymentUrl, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        Accept: "application/json",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    if (response.redirected) {
      const js = await response.text();
      // eslint-disable-next-line no-eval
      eval(js);
      cancelAwareDispatcher({
        type: "completingCharge",
        response: {
          success: true,
          didIntercept: true,
        },
      });
      return;
    }

    const json = (await response.json()) as Response;

    if (json.success) {
      const formattedBalance = currencyWithUnit(
        json.new_balance,
        currencySymbol,
      ).format();
      cancelAwareDispatcher({
        type: "completingCharge",
        response: {
          success: true,
          bannerMessage: `Client balance updated to ${formattedBalance}`,
        },
      });
    } else {
      cancelAwareDispatcher({
        type: "completingCharge",
        response: { success: false, errorMessage: json.message },
      });
    }
  }
}
