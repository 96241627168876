import React from "react";

export interface UrlsContextType {
  jobberPaymentsSetupUrl?: string;
  jobberPaymentsPayoutsPath?: string;
  jobberPaymentsTransactionsPath?: string;
  jobberPaymentsConnectInstantPayoutDebitCardPath?: string;
  jobberPaymentsCreateInstantPayoutsPath?: string;
  jobberPaymentsViewPayoutsUrl?: string;
  jobberPaymentsDisputesPath?: string;

  stripeCapitalReportLinkPath?: string;

  jobberPaymentsCreatePaymentUrl?: string;
  jobberPaymentsConfirmPaymentUrl?: string;
  hostedOnboardingUrlCreationUrl?: string;

  recordPaymentUrl?: string;
  wisetackSignupUrl?: string;

  directDebitLogoImgUrl?: string;

  docsPayoutsUrl?: string;
  docsInstantPayoutsUrl?: string;
  docsJobberPaymentsBasicsUrl?: string;

  calendarImportIndexPath?: string;
  calendarImportAuthPath?: string;

  calendarPath?: string;
  calendarListPath?: string;
  calendarGridPath?: string;

  expiringJobsPath?: string;

  outstandingBalanceReportPath?: string;
  clientReengagementReportPath?: string;

  qboSyncSettingsPath?: string;

  clientHubContactUsUrl?: string;

  consumerFinancingReportUrl?: string;

  newQuotePath?: string;
  newInvoicePath?: string;
  newJobPath?: string;
  newRequestPath?: string;

  editWorkConfigurationPath?: string;

  pricingAccountsBillingInfoUrl?: string;
  editAccountsBillingInfoDialogUrl?: string;

  termsOfService?: string;

  companySettingsPath?: string;

  subWorkConfigurationWorkSettingsPath?: string;

  manageTeamPath?: string;

  accountsBillingInfoPath?: string;

  jobberMoneyAccountStatementReportPath?: string;

  subcontractorFirstLoginPath?: string;

  clientHubRequestProvidersPath?: string;

  clientHubSavePropertyQuizPath?: string;

  agedReceivablesPath?: string;
}

export const UrlsContext = React.createContext<UrlsContextType>({});
UrlsContext.displayName = "UrlsContext";
