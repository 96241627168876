import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Page } from "@jobber/components/Page";
import { Banner } from "@jobber/components/Banner";
import { Spinner } from "@jobber/components/Spinner";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useOwnershipData } from "jobber/features/Settings/views/CommsSettings/hooks/useOwnershipData";
import type { CommsSettingsPageDataQuery } from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import { COMMS_SETTINGS_PAGE_QUERY } from "./CommsSettings.graphql";
import { CommsSettings } from "./CommsSettings";
import {
  COMMS_SETTINGS_ERROR_MESSAGE,
  EMAILS_AND_TEXT_MESSAGES_HEADER,
  EMAILS_HEADER,
} from "./constants";

export function CommsSettingsWrapper() {
  return (
    <APIProvider>
      <CommsSettingsLoader />
    </APIProvider>
  );
}

function CommsSettingsLoader() {
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<CommsSettingsPageDataQuery>(COMMS_SETTINGS_PAGE_QUERY);

  const {
    fetchOwnerships,
    ownerships,
    loading: isGlobalOwnershipsLoading,
    error,
  } = useOwnershipData();

  useEffect(() => {
    fetchOwnerships();
  }, []);

  const smsAvailable = jobberOnline.features.smsMessaging.available;
  const header = jobberOnline.features.smsMessaging.available
    ? EMAILS_AND_TEXT_MESSAGES_HEADER
    : EMAILS_HEADER;

  return (
    <Page title={header}>
      {(queryLoading || isGlobalOwnershipsLoading) && <Spinner />}
      {!queryLoading && !isGlobalOwnershipsLoading && (
        <>
          <Content spacing="large">
            <Text size="large">
              Customize emails {smsAvailable && "and text messages "} sent to
              your clients.
            </Text>
          </Content>
          {queryError && (
            <Banner type={"error"}>{COMMS_SETTINGS_ERROR_MESSAGE}</Banner>
          )}
          <CommsSettings
            data={data}
            globalOwnershipsData={{
              ownerships: ownerships || [],
              error: error,
            }}
          />
        </>
      )}
    </Page>
  );
}
