import React from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { CampaignRecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/CampaignRecipientsSection/CampaignsRecipientSection";
import type { AutomationRule, ProductsQuery } from "~/utilities/API/graphql";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { useTranslateAutomationRulesConditionToText } from "jobber/campaigns/hooks/useTranslateAutomationRulesConditionToText/useTranslateAutomationRulesConditionToText";
import { messages } from "./messages";

export interface AutomatedCampaignRecipientsSectionProps {
  loading: boolean;
  timeoutError: boolean;
  redirectToEdit: () => void;
  automationRule?: AutomationRule;
}

export function AutomatedCampaignRecipientsSection({
  loading,
  timeoutError,
  redirectToEdit,
  automationRule,
}: AutomatedCampaignRecipientsSectionProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { data: lineItemData, loading: productsLoading } =
    useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const allLoading = loading || productsLoading;

  const { trigger, timeFrame, conditionText } =
    useTranslateAutomationRulesConditionToText({
      allLoading,
      automationRule,
      lineItemData,
    });

  return (
    <CampaignRecipientsSection
      loading={allLoading}
      summaryMessage={formatMessage(
        messages.automatedCampaignRecipientSummary,
        {
          timeFrame: timeFrame,
          trigger: trigger,
          condition: conditionText,
        },
      )}
      timeoutError={timeoutError}
      redirectToEdit={redirectToEdit}
    />
  );
}
