import { defineMessages } from "react-intl";

export const messages = defineMessages({
  calculateProfitability: {
    id: "workOrders.jobCost.ComputeJobProfitabilityButton.calculateProfitability",
    defaultMessage: "Calculate Profitability",
    description: "Calculate profitability string",
  },
  error: {
    id: "workOrders.jobCost.ComputeJobProfitabilityButton.calculateProfitabilityError",
    defaultMessage:
      "Error occurred in calculating profitability. Please try again",
    description:
      "Error message for the Amplitude `calculate profitability` event handler",
  },
});
