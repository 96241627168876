import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reEngageClients: {
    id: "dashboard.components.clientReEngagementInsight.reEngageClients",
    defaultMessage: "Re-engage clients",
    description: "The re-engage clients insights card",
  },
  reEngageClientsDescription: {
    id: "dashboard.components.clientReEngagementInsight.reEngageClientsDescription  ",
    defaultMessage:
      "You have {clientCount} {clientCount, plural, one {client} other {clients}} that you haven't completed work for in over a year.",
    description: "The description of re-engage clients insights card",
  },
  viewReEngagementWork: {
    id: "dashboard.components.clientReEngagementInsight.viewReEngagementWork",
    defaultMessage: "View Schedule",
    description: "The action button to view re-engagement work",
  },
});
