import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailTabTitle: {
    id: "reviews.commsCustomizer.emailTab",
    defaultMessage: "Email",
    description: "The email tab that users can select",
  },
  textMessageTabTitle: {
    id: "reviews.commsCustomizer.textMessageTab",
    defaultMessage: "Text",
    description: "The text message tab that users can select",
  },
  emailSubjectPlaceholderText: {
    id: "reviews.commsCustomizer.emailSubjectPlaceholderText",
    defaultMessage: "Email subject",
    description: "The email subject placeholder text",
  },
  inputFieldPlaceholderText: {
    id: "reviews.commsCustomizer.inputFieldPlaceholderText",
    defaultMessage: "Message",
    description: "The message placeholder text for the input text",
  },
  characterLimitExceeded: {
    id: "reviews.commsCustomizer.characterLimitExceeded",
    defaultMessage:
      "Phone companies split text messages that are over 160 characters into two messages. This can impact deliverability.",
    description:
      "text informing that character limit is exceeded on the input field",
  },
  resetButtonLabel: {
    id: "reviews.commsCustomizer.resetButtonLabel",
    defaultMessage: "Reset",
    description: "Reset label for button",
  },
  insertButtonLabel: {
    id: "reviews.commsCustomizer.insertButtonLabel",
    defaultMessage: "Insert variable",
    description: "Insert variable label text",
  },
});
