import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  filterStartDatePlaceholder: {
    id: "reporting.report.filterStartDatePlaceholder",
    defaultMessage: "Start date",
    description: "Placeholder text for the date selector start date input",
  },
  filterEndDatePlaceholder: {
    id: "reporting.report.filterEndDatePlaceholder",
    defaultMessage: "End date",
    description: "Placeholder text for the date selector end date input",
  },
  dateRangeSelectPlaceholder: {
    id: "reporting.report.dateRangeSelectPlaceholder",
    defaultMessage: "Date range",
    description: "Placeholder text for the date range select input",
  },
  dateRangeWeek: {
    id: "reporting.report.dateRangeWeek",
    defaultMessage: "Last week",
    description: "Label for the last week date range",
  },
  dateRangeThirtyDays: {
    id: "reporting.report.dateRangeThirtyDays",
    defaultMessage: "Last 30 days",
    description: "Label for the last 30 days date range",
  },
  dateRangeMonth: {
    id: "reporting.report.dateRangeMonth",
    defaultMessage: "Last month",
    description: "Label for the last month date range",
  },
  dateRangeYear: {
    id: "reporting.report.dateRangeYear",
    defaultMessage: "This year",
    description: "Label for the this year date range",
  },
  dateRangeTwelveMonths: {
    id: "reporting.report.dateRangeTwelveMonths",
    defaultMessage: "Last 12 months",
    description: "Label for the last 12 months date range",
  },
  dateRangeCustom: {
    id: "reporting.report.dateRangeCustom",
    defaultMessage: "Custom range",
    description: "Label for the custom date range",
  },
});
