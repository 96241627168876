import React, { useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button, type ButtonProps } from "@jobber/components/Button";
import { Icon } from "@jobber/components/Icon";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { showToast } from "@jobber/components/Toast";
import styles from "./AccordionItem.module.css";
import { messages } from "./messages";

export interface AccordionItemContentProps {
  title: string;
  description: string;
  ctaName: string;
  buttonProps: ButtonProps;
  urlProps?: {
    href: string;
    label: string;
    onClick?(
      event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    ): void;
  };
}
export interface AccordionItemProps {
  isExpanded: boolean;
  isCompleted: boolean;
  accordionItemContent: AccordionItemContentProps;
  onClick(): void;
  onCompleted(): void;
}

export function AccordionItem({
  isExpanded,
  isCompleted,
  onClick,
  onCompleted,
  accordionItemContent,
}: AccordionItemProps) {
  const { formatMessage } = useIntl();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleMousedown = () => {
    if (!isExpanded) setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const itemCTAClick = accordionItemContent.buttonProps.onClick;
  const handlePrimaryButtonClick = async (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    try {
      await itemCTAClick?.(event);
      onCompleted();
    } catch (error) {
      showToast({
        message: formatMessage(messages.sendSampleToastErrorMessage),
        variation: "error",
      });
    }
  };

  return (
    <div
      className={classNames(styles.baseContainer, {
        [styles.completedContainer]: isCompleted && !isExpanded,
        [styles.clickableContainer]: !isExpanded,
        [styles.focus]: isFocused,
      })}
      onMouseDown={handleMousedown}
      onClick={onClick}
      onBlur={handleBlur}
      onKeyDown={onClick}
      role={isExpanded ? "none" : "button"}
      tabIndex={0}
      aria-expanded={isExpanded}
    >
      <div className={styles.headerContent}>
        <Heading level={4}>{accordionItemContent.title}</Heading>
        {isCompleted && !isExpanded && <Icon name="checkmark" />}
      </div>
      <div
        className={classNames(styles.expandedContentWrapper, {
          [styles.expanded]: isExpanded,
        })}
      >
        <div className={styles.expandedContent}>
          <Text>{accordionItemContent.description}</Text>
          <div className={styles.actionsContainer}>
            {/* @ts-expect-error */}
            <Button
              {...accordionItemContent.buttonProps}
              onClick={handlePrimaryButtonClick}
              submit={undefined}
            />
            {accordionItemContent.urlProps && (
              <a
                href={accordionItemContent.urlProps.href}
                className={styles.linkStyles}
                onClick={accordionItemContent.urlProps.onClick}
              >
                {accordionItemContent.urlProps.label}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
