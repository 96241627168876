import { gql } from "@apollo/client";

export const GROW_TRIAL_ELIGIBILITY = gql`
  query GrowTrialEligibility {
    accountFeatureTrialEligibility {
      eligibleForConnectToGrowTrial
    }
  }
`;

export interface GrowTrialEligibilityData {
  accountFeatureTrialEligibility: {
    eligibleForConnectToGrowTrial: boolean;
  };
}

export const ACTIVATE_GROW_TRIAL = gql`
  mutation ActivateConnectToGrowTrial($input: ActivateConnectToGrowTrialInput) {
    activateConnectToGrowTrial(input: $input) {
      membership {
        id
        experimentId
        variationId
        abTestableId
        abTestableType
      }
      userErrors {
        message
      }
    }
  }
`;
