import { gql } from "@apollo/client";

const PRODUCTS_OR_SERVICES_FRAGMENT = gql`
  fragment ProductOrServiceOptions on ProductOrService {
    id
    name
    description
    defaultUnitCost
    internalUnitCost
    category
    taxable
    fileAttachment {
      contentType
      fileName
      fileSize
      s3Key
      thumbnailUrl
      url
    }
  }
`;

export const PRODUCT_OR_SERVICES_QUERY = gql`
  query products {
    products {
      nodes {
        ...ProductOrServiceOptions
      }
    }
  }
  ${PRODUCTS_OR_SERVICES_FRAGMENT}
`;

export const PRODUCTS_SEARCH_QUERY = gql`
  query lineItemsOptionsQuery($searchTerm: String!) {
    productsSearch(searchTerm: $searchTerm) {
      nodes {
        ...ProductOrServiceOptions
      }
    }
  }
  ${PRODUCTS_OR_SERVICES_FRAGMENT}
`;

export const LAST_JOB_LINE_ITEM_QUERY = gql`
  query lastJobLineItem($id: EncodedId!, $propertyId: EncodedId) {
    productOrService(id: $id) {
      id
      lastJobLineItem(propertyId: $propertyId) {
        id
        name
        description
        taxable
        quantity
        unitPrice
        unitCost
      }
    }
  }
`;
