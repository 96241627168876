import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { FormatDate } from "@jobber/components/FormatDate";
import classNames from "classnames";
import React from "react";
import { Button } from "@jobber/components/Button";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import styles from "./PaymentsCard.module.css";

export interface DisputesWaitingResponseRowProps {
  disputesWaitingResponsesTotal: number;
  nearestDisputeExpireDate?: string;
}

export function DisputesWaitingResponseRow({
  disputesWaitingResponsesTotal,
  nearestDisputeExpireDate,
}: DisputesWaitingResponseRowProps) {
  const [jobberPaymentsDisputesPath] = useUrls("jobberPaymentsDisputesPath");
  const hasDisputes = disputesWaitingResponsesTotal !== 0;
  function disputeIndicatorColor() {
    if (hasDisputes) {
      return styles.disputeAmountIndicatorRed;
    } else {
      return styles.disputeAmountIndicatorNeutral;
    }
  }

  function nearestDisputeDate() {
    if (nearestDisputeExpireDate) {
      return <FormatDate date={nearestDisputeExpireDate}></FormatDate>;
    } else {
      return <Text>—</Text>;
    }
  }

  return (
    <Content>
      <h5 className={styles.headerText}>DISPUTES</h5>
      <div className={styles.container}>
        <div
          className={classNames(
            disputeIndicatorColor(),
            styles.disputeIndicatorNumber,
          )}
        >
          {disputesWaitingResponsesTotal}
        </div>
        <div className={styles.content}>
          <Text variation="subdued">Needs response</Text>
          {nearestDisputeDate()}
        </div>
        <div className={styles.action}>
          <Button
            type="secondary"
            size="small"
            label="View Disputes"
            url={jobberPaymentsDisputesPath}
          />
        </div>
      </div>
    </Content>
  );
}
