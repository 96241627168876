import { gql } from "@apollo/client";

export const merchantConfigurationFieldFragments = gql`
  fragment accountFields on Account {
    id
    name
    googleLsaConfiguration {
      ...googleLsaConfigurationFields
    }
    companyDetails {
      address
      phone
      websiteUrl
    }
  }

  fragment googleLsaConfigurationFields on GoogleLsaConfiguration {
    enabled
    bookingBufferHours
    serviceDescription
    setupStep
    dismissedGoogleGuaranteedPrompt
    merchantStatus {
      ...googleLsaMerchantStatusFields
    }
  }

  fragment googleLsaMerchantStatusFields on GoogleLsaMerchantStatus {
    readyToBook
    directUrl
  }
`;

export const instantBookingSelectedServiceFieldFragments = gql`
  fragment instantBookingSelectedServiceFields on InstantBookingSelectedService {
    id
    price
    serviceTypeId
  }
`;

export const instantBookingAvailabilityWindowFieldFragments = gql`
  fragment instantBookingAvailabilityWindowFields on InstantBookingAvailabilityWindow {
    dayOfWeek
    startSeconds
    endSeconds
    spotsTotal
  }
`;
