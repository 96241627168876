import type { MockedResponse } from "@apollo/client/testing";
import { PRODUCT_OR_SERVICE_LIST, PRODUCT_OR_SERVICE_SEARCH } from "./graphql";

function getProductOrService(
  id: string,
  name: string,
  onlineBookingsEnabled: boolean,
) {
  return {
    __typename: "ProductOrService",
    id,
    name,
    description: null,
    defaultUnitCost: 12,
    category: "SERVICE",
    internalUnitCost: 12,
    markup: 0,
    taxable: true,
    visible: true,
    durationMinutes: 90,
    onlineBookingsEnabled,
    fileAttachment: null,
    quantityRange: null,
  };
}

export function getQueryMock(hasNextPage: boolean, pageCount = 0) {
  const firstCursor = pageCount * 2 + 1;
  const secondCursor = firstCursor + 1;
  return {
    request: {
      query: PRODUCT_OR_SERVICE_LIST,
      variables: {
        cursor: pageCount ? btoa(String(firstCursor - 1)) : undefined,
        advancedQuotingEnabled: true,
        quoteMarginsEnabled: true,
      },
    },
    result: {
      data: {
        products: {
          edges: [
            {
              __typename: "ProductOrServiceEdge",
              node: getProductOrService(
                String(firstCursor),
                "Already Online Bookable",
                true,
              ),
              cursor: btoa(String(firstCursor)),
            },
            {
              __typename: "ProductOrServiceEdge",
              node: getProductOrService(
                String(secondCursor),
                "Bathroom Cleaning",
                false,
              ),
              cursor: btoa(String(secondCursor)),
            },
          ],
          pageInfo: {
            endCursor: btoa(String(secondCursor)),
            startCursor: btoa(String(firstCursor)),
            hasNextPage,
          },
        },
      },
    },
  };
}

export const defaultQueryMock: MockedResponse = getQueryMock(false);

export const searchQueryMock: MockedResponse = {
  request: {
    query: PRODUCT_OR_SERVICE_SEARCH,
    variables: {
      searchTerm: "search name",
      advancedQuotingEnabled: true,
      quoteMarginsEnabled: true,
    },
  },
  result: {
    data: {
      productsSearch: {
        nodes: [getProductOrService("GH==", "Search Name", false)],
      },
    },
  },
};
