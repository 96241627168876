import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

export interface DowngradeContactModalProps {
  showModal: boolean;
  hideModal: () => void;
}

function DowngradeContactModal({
  showModal,
  hideModal,
}: DowngradeContactModalProps) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage(messages.modalTitle)}
      open={showModal}
      onRequestClose={handleModalDismissal}
      dismissible
      primaryAction={{
        label: formatMessage(messages.ctaLabel),
        onClick: handleModalPrimaryCTA,
      }}
      secondaryAction={{
        label: formatMessage(messages.cancelLabel),
        onClick: handleModalDismissal,
        type: "tertiary",
      }}
      size={"small"}
    >
      <Content>
        <Text>
          {formatMessage(messages.modalContent, {
            phone: (
              <a href="tel:+18884245301">
                {formatMessage(messages.downgradePhoneNumber)}
              </a>
            ),
          })}
        </Text>
      </Content>
    </Modal>
  );

  function handleModalDismissal() {
    hideModal();
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: "downgrade_contact_modal",
      name: "cancel_button",
    });
  }

  function handleModalPrimaryCTA() {
    hideModal();
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "downgrade_contact_modal",
      name: "chat_with_us_button",
    });
    Intercom.EXECUTE("showNewMessage");
  }
}

function WrappedDowngradeContactModal({
  showModal,
  hideModal,
}: DowngradeContactModalProps) {
  return (
    <IntlProvider>
      <DowngradeContactModal showModal={showModal} hideModal={hideModal} />
    </IntlProvider>
  );
}

export { WrappedDowngradeContactModal as DowngradeContactModal };
