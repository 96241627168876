import React from "react";
import { Checkbox } from "@jobber/components/Checkbox";
import type { Service } from "jobber/google_lsa/MerchantConfiguration";
import { InputCurrency } from "components/InputCurrency";

export interface ServiceItemProps {
  service: Service;
  currencySymbol?: string;
  onSwitch(serviceName: string): (newValue: boolean) => void;
  onEdit(serviceName: string): (newValue: number) => void;
}

export function ServiceItem(props: ServiceItemProps) {
  const currencySymbol = props.currencySymbol ?? "$";

  return (
    <tr>
      <td>
        <Checkbox
          label={props.service.serviceDisplayName}
          checked={props.service.enabled}
          onChange={props.onSwitch(props.service.serviceName)}
        />
      </td>
      <td>
        <div className="fieldAffix u-marginBottomNone">
          <span className="fieldAffix-item">{currencySymbol}</span>
          <InputCurrency
            disabled={!props.service.enabled}
            value={props.service.price}
            placeholder={`${props.service.serviceDisplayName} Price`}
            onChange={props.onEdit(props.service.serviceName)}
            size="small"
            decimalPlaces={0}
            maximumDecimalPlaces={0}
          />
        </div>
      </td>
    </tr>
  );
}
