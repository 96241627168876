/* eslint-disable max-statements */
import { useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import type { ClientHubSettingsQuery } from "~/utilities/API/graphql";
import { CLIENT_HUB_SETTING_QUERY } from "./ClientHubSettingQuery";
import { InvoicePreferencesSettings } from "./InvoicePreferencesSettings";
import { QuotePreferencesSettings } from "./QuotePreferencesSettings";
import { messages } from "./messages";

interface InternalClientHubSettingsProps {
  dismissCta?(): void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDefaultPreference?: {
    preference: string;
    achEnabled: boolean;
  };
}

export function InternalClientHubSettings(
  props: InternalClientHubSettingsProps,
) {
  const { formatMessage } = useIntl();
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const [payQuoteDeposits, setPayQuoteDeposits] = useState<boolean>();
  const [payInvoices, setPayInvoices] = useState<boolean>();
  const [tip, setTip] = useState<boolean>();
  const { loading, error, data } = useQuery<ClientHubSettingsQuery>(
    CLIENT_HUB_SETTING_QUERY,
  );
  const [isAchEnabled, setIsAchEnabled] = useState<boolean>();

  useEffect(() => {
    if (data) {
      setPayQuoteDeposits(data.payQuoteDeposits.enabled);
      setPayInvoices(data.payInvoices.enabled);
      setIsAchEnabled(data.achPayments.enabled);
    }
  }, [data]);

  if (loading) {
    return <div className="spinner" />;
  }

  if (error) {
    return <>{error?.message}</>;
  }

  if (!data) {
    return <>{formatMessage(messages.defaultMutationErrorMessage)}</>;
  }

  return (
    <>
      {mutationErrorMessage && (
        <Banner type="error" onDismiss={onErrorBannerDismiss}>
          {mutationErrorMessage}
        </Banner>
      )}
      <QuotePreferencesSettings
        setMutationErrorMessage={setMutationErrorMessage}
        quoteData={data}
        payQuoteDeposits={payQuoteDeposits}
        setPayQuoteDeposits={setPayQuoteDeposits}
        setToggleAchFlag={props?.setToggleAchFlag}
        updateDefaultPreference={props.updateDefaultPreference}
        setIsAchEnabled={setIsAchEnabled}
        isAchEnabled={isAchEnabled}
      />

      <InvoicePreferencesSettings
        setMutationErrorMessage={setMutationErrorMessage}
        invoiceData={data}
        payInvoices={payInvoices}
        setPayInvoices={setPayInvoices}
        tip={tip}
        setTip={setTip}
        dismissCta={props.dismissCta}
        setToggleAchFlag={props?.setToggleAchFlag}
        updateDefaultPreference={props.updateDefaultPreference}
        setIsAchEnabled={setIsAchEnabled}
        isAchEnabled={isAchEnabled}
      />
    </>
  );

  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }
}
