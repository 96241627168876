import { useEffect, useState } from "react";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
} from "~/utilities/API/graphql";

export interface CustomFieldConfigurationList {
  client: Array<CustomFieldConfigurationNodeFragment>;
  property: Array<CustomFieldConfigurationNodeFragment>;
  job: Array<CustomFieldConfigurationNodeFragment>;
  invoice: Array<CustomFieldConfigurationNodeFragment>;
  quote: Array<CustomFieldConfigurationNodeFragment>;
  team: Array<CustomFieldConfigurationNodeFragment>;
  workItem: Array<CustomFieldConfigurationNodeFragment>;
}

export interface UseCustomFieldConfigurationsListType {
  customFields: CustomFieldConfigurationList;
}

export function useCustomFieldConfigurationsList(
  data: CustomFieldConfigurationNodeFragment[],
): UseCustomFieldConfigurationsListType {
  const [fieldList, setFieldList] = useState<CustomFieldConfigurationList>(
    emptyCustomFieldConfigurationGroupState(),
  );

  useEffect(() => {
    setFieldList(groupByType(data));
  }, [data]);

  return {
    customFields: fieldList,
  };
}

function fixSortOrderForGrouping(
  grouping: CustomFieldConfigurationNodeFragment[],
) {
  grouping.forEach((cfc, index) => {
    cfc.sortOrder = index;
  });
}

function getGrouping(
  customFieldConfigurationList: CustomFieldConfigurationList,
  appliesTo: CustomFieldAppliesTo,
) {
  switch (appliesTo) {
    case CustomFieldAppliesTo.ALL_CLIENTS:
      return customFieldConfigurationList.client;
    case CustomFieldAppliesTo.ALL_PROPERTIES:
      return customFieldConfigurationList.property;
    case CustomFieldAppliesTo.ALL_JOBS:
      return customFieldConfigurationList.job;
    case CustomFieldAppliesTo.ALL_INVOICES:
      return customFieldConfigurationList.invoice;
    case CustomFieldAppliesTo.ALL_QUOTES:
      return customFieldConfigurationList.quote;
    case CustomFieldAppliesTo.ALL_PRODUCTS_AND_SERVICES:
      return customFieldConfigurationList.workItem;
    case CustomFieldAppliesTo.TEAM:
      return customFieldConfigurationList.team;
    default:
      return [];
  }
}

function groupByType(
  customFieldArray: CustomFieldConfigurationNodeFragment[],
): CustomFieldConfigurationList {
  const groups: CustomFieldConfigurationList =
    emptyCustomFieldConfigurationGroupState();

  customFieldArray.forEach(customField => {
    getGrouping(groups, customField.appliesTo).push({ ...customField });
  });

  // In case the server has gotten bad data, let's update these to ensure that they have the correct sort orders (no missing values)
  // draggable library cannot handle orders like, 1, 3, 4, 5, 6 because it needs to be consecutive. On the next save, these changes/the new order will be persisted
  Object.values(groups).forEach(grouping => {
    fixSortOrderForGrouping(grouping);
  });

  return groups;
}

export function emptyCustomFieldConfigurationGroupState() {
  return {
    client: [],
    property: [],
    job: [],
    invoice: [],
    quote: [],
    team: [],
    workItem: [],
  };
}
