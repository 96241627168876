import { useIntl } from "react-intl";
import { Template } from "~/utilities/API/graphql";
import { messages } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/messages";
import type { SingleEmail } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/CampaignEmailsTable";

type DataListHeaderObject<T> = {
  readonly [K in keyof T]?: string;
};

interface ShouldShowJobInformationValues {
  showJobInformation: boolean;
  headers: DataListHeaderObject<SingleEmail>;
}

export function useShouldShowJobInformation(
  templateType: Template,
): ShouldShowJobInformationValues {
  const { formatMessage } = useIntl();

  const showJobInformation = templateType !== Template.REFERRAL;

  const baseHeaders = {
    recipient: formatMessage(messages.emailsTableRecipientHeader),
    delivered: formatMessage(messages.emailsTableDeliveredHeader),
    opened: formatMessage(messages.emailsTableOpenedHeader),
    clicked: formatMessage(messages.emailsTableClickedHeader),
    unsubscribed: formatMessage(messages.emailsTableUnsubscribedHeader),
  };

  let headers: DataListHeaderObject<SingleEmail> = baseHeaders;

  if (showJobInformation) {
    headers = {
      ...baseHeaders,
      jobs: formatMessage(messages.emailsTableJobsHeader),
      revenue: formatMessage(messages.emailsTableRevenueHeader),
    };
  }

  return {
    showJobInformation,
    headers,
  };
}
