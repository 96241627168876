import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { CardDetailsForm } from "./CardDetailsForm";
import { SecurityDisclosure } from "./SecurityDisclosure";

export interface SimpleAddPaymentMethodFormProps {
  nameOnCard: string;
  isLoading: boolean;
  cardErrorMessage: string | undefined;
  onCardElementChange(event: StripeCardElementChangeEvent): void;
  onNameOnCardChange(name: string): void;
  showSecurityDisclosure?: boolean;
}

export function SimpleAddPaymentMethodForm(
  props: SimpleAddPaymentMethodFormProps,
) {
  const {
    nameOnCard,
    isLoading,
    cardErrorMessage,
    onCardElementChange,
    onNameOnCardChange,
    showSecurityDisclosure,
  } = props;

  return (
    <Content>
      <Heading level={5}>Card details</Heading>
      <CardDetailsForm
        cardErrorMessage={cardErrorMessage}
        isLoading={isLoading}
        nameOnCard={nameOnCard}
        onCardElementChange={onCardElementChange}
        onNameOnCardChange={onNameOnCardChange}
      />
      {showSecurityDisclosure && (
        <div>
          <SecurityDisclosure />
        </div>
      )}
    </Content>
  );
}
