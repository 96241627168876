import React from "react";
import type { WorkOrderFilters } from "jobber/workOrders/types";
import { SearchBar } from "jobber/workOrders/components/SearchBar";
import { StatusFilter } from "jobber/workOrders/components/StatusFilter";
import { SortFilter } from "jobber/workOrders/components/SortFilter";
import { TypeFilter } from "jobber/workOrders/components/TypeFilter";
import styles from "./ListControls.module.css";

type ListControlsProps = WorkOrderFilters & {
  onChangeFilters(payload: Partial<WorkOrderFilters>): void;
  searchCallback(searchTerm: string): void;
  onChangeSearch(searchTerm: string): void;
};

export const ListControls = ({
  searchTerm,
  onChangeFilters,
  onChangeSearch,
  searchCallback,
}: ListControlsProps) => {
  return (
    <div className={styles.container}>
      <SearchBar
        placeholder={"Search jobs ..."}
        value={searchTerm}
        onChange={onChangeSearch}
        searchCallback={searchCallback}
      />
      <StatusFilter onChangeFilters={onChangeFilters} />
      <SortFilter onChangeFilters={onChangeFilters} />
      <TypeFilter onChangeFilters={onChangeFilters} />
    </div>
  );
};
