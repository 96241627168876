import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Form, type FormRef } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Heading } from "@jobber/components/Heading";
import { InputDate } from "@jobber/components/InputDate";
import { Option, Select } from "@jobber/components/Select";
import { Checkbox } from "@jobber/components/Checkbox";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Text } from "@jobber/components/Text";
import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CLIENT_INFORMATION_HEADING,
  COUNTER_DISPUTE_REASON_HEADING,
  CounterDisputeReasonText,
  PRODUCT_OR_SERVICE_DETAILS_HEADING,
  UPLOAD_SUPPORTING_EVIDENCE_HEADING,
} from "jobber/features/PaymentDisputes/components/SubmittedEvidence/constants";
import { SupportingEvidencePoints } from "jobber/features/PaymentDisputes/components/SubmitEvidence/constants";
import {
  type DisputeType,
  disputeClientBillingAddress,
  disputeClientName,
  disputeClientPrimaryEmail,
} from "jobber/features/PaymentDisputes/utils/disputeUtils";
import { DisputeEvidenceReason } from "~/utilities/API/graphql";
import { DisputeFileUpload } from "jobber/features/PaymentDisputes/components/DisputeFileUpload";
import type {
  DisputeFile,
  SubmitEvidencePayload,
} from "jobber/features/PaymentDisputes/types";
import styles from "./SubmitEvidence.module.css";
import { HELP_CENTER_URL } from "../../constants";

interface SubmitEvidenceProps {
  onValidationErrors: (errors: SubmitEvidenceValidationError[]) => void;
  onCancelClick: () => void;
  onSubmitClick: (args: SubmitEvidencePayload) => void;
  dispute: NonNullable<DisputeType>;
  isLoading: boolean;
}

const orderedDisputeEvidenceReason = [
  DisputeEvidenceReason.WITHDREW,
  DisputeEvidenceReason.REFUNDED,
  DisputeEvidenceReason.NON_REFUNDABLE,
  DisputeEvidenceReason.LATE_REQUEST,
  DisputeEvidenceReason.SATISFIED,
  DisputeEvidenceReason.CREDIT,
  DisputeEvidenceReason.GOVT_DELAY,
  DisputeEvidenceReason.UNIQUE_PURCHASE,
  DisputeEvidenceReason.OTHER,
];

const disputeReasons = new Map(
  Object.values(orderedDisputeEvidenceReason).map(reason => [
    reason,
    CounterDisputeReasonText[reason],
  ]),
);

export enum SubmitEvidenceValidationError {
  CONFIRM_TERMS = "Confirm you understand that you can only submit evidence for this dispute once by checking the checkbox",
  PRODUCT_SERVICE_DETAILS_MISSING = "Description of product or service is required",
  ADDITIONAL_INFORMATION_MISSING = "Additional information is required",
  CLIENT_NAME_MISSING = "Client name is required",
  REASON_MISSING = "Reason is required",
  FILE_EVIDENCE_CATEGORY_MISSING = "Uploaded files must be categorized",
  FILE_EVIDENCE_CATEGORIES_NOT_UNIQUE = "Only one file can be provided for each file category",
  FILE_SIZE_LIMIT_EXCEEDED = "Total evidence file size limit exceeded",
}

// eslint-disable-next-line max-statements
export function SubmitEvidence({
  onValidationErrors,
  onCancelClick,
  onSubmitClick,
  dispute,
  isLoading,
}: SubmitEvidenceProps) {
  const [showAgreeToTermsErrorMessage, setShowAgreeToTermsErrorMessage] =
    useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const formRef = useRef() as MutableRefObject<FormRef>;
  const [selectedReason, setSelectedReason] = useState<DisputeEvidenceReason>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [description, setDescription] = useState<string>();
  const [additionalInfo, setAdditionalInfo] = useState<string>();
  const [clientName, setClientName] = useState<string>(
    disputeClientName(dispute),
  );
  const [clientEmail, setClientEmail] = useState<string>(
    disputeClientPrimaryEmail(dispute),
  );
  const [clientBillingAddress, setClientBillingAddress] = useState<string>(
    disputeClientBillingAddress(dispute),
  );
  const [evidenceFiles, setEvidenceFiles] = useState<DisputeFile[]>([]);
  const [isEvidenceFileLimitReached, setIsEvidenceFileLimitReached] =
    useState<boolean>(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false);
  const [formModified, setFormModified] = useState(false);
  const [categoriesUnique, setCategoriesUnique] = useState(true);
  const [categoriesMissing, setCategoriesMissing] = useState(false);
  // eslint-disable-next-line max-statements
  const onSubmit = () => {
    const errorMessages = [];

    if (!selectedReason) {
      errorMessages.push(SubmitEvidenceValidationError.REASON_MISSING);
    }

    if (!description) {
      errorMessages.push(
        SubmitEvidenceValidationError.PRODUCT_SERVICE_DETAILS_MISSING,
      );
    }

    if (!additionalInfo) {
      errorMessages.push(
        SubmitEvidenceValidationError.ADDITIONAL_INFORMATION_MISSING,
      );
    }

    if (isEvidenceFileLimitReached) {
      errorMessages.push(
        SubmitEvidenceValidationError.FILE_SIZE_LIMIT_EXCEEDED,
      );
    }

    if (categoriesMissing) {
      errorMessages.push(
        SubmitEvidenceValidationError.FILE_EVIDENCE_CATEGORY_MISSING,
      );
    }

    if (!categoriesUnique) {
      errorMessages.push(
        SubmitEvidenceValidationError.FILE_EVIDENCE_CATEGORIES_NOT_UNIQUE,
      );
    }

    if (!clientName) {
      errorMessages.push(SubmitEvidenceValidationError.CLIENT_NAME_MISSING);
    }

    if (!agreedToTerms) {
      errorMessages.push(SubmitEvidenceValidationError.CONFIRM_TERMS);
      setShowAgreeToTermsErrorMessage(true);
    }

    if (errorMessages.length > 0) {
      // Form is invalid. Trigger submit to show the error messages
      formRef.current.submit();
      onValidationErrors(errorMessages);
      setIsSubmitPressed(true);
    } else {
      onSubmitClick({
        evidenceReason: selectedReason as DisputeEvidenceReason,
        serviceDate: new Date(selectedDate.setHours(0, 0, 0, 0)).toISOString(),
        productDescription: description,
        refundRefusalExplanation: additionalInfo,
        clientInformation: {
          customerName: clientName,
          customerEmailAddress: clientEmail,
          billingAddress: clientBillingAddress,
        },
        evidenceFiles: evidenceFiles.map(file => {
          return {
            category: file.category,
            file: file.file,
          };
        }),
      });
    }
  };

  const fileCategoriesUnique = (uploadEvidenceFiles: DisputeFile[]) => {
    if (uploadEvidenceFiles.length == 0) {
      return true;
    }

    const categories = uploadEvidenceFiles.map(file => file.category);
    const uniqueCategoryLength = new Set(categories).size;
    return uniqueCategoryLength == categories.length;
  };

  const fileCategoriesMissing = (uploadEvidenceFiles: DisputeFile[]) => {
    return uploadEvidenceFiles.filter(file => !file.category).length > 0;
  };

  const handleFileChange = (
    updateEvidenceFiles: DisputeFile[],
    isFileLimitReached: boolean,
  ) => {
    setEvidenceFiles(updateEvidenceFiles);
    setIsEvidenceFileLimitReached(isFileLimitReached);
    if (updateEvidenceFiles.length > 0) {
      handleFormModification();
    }
    setCategoriesUnique(fileCategoriesUnique(updateEvidenceFiles));
    setCategoriesMissing(fileCategoriesMissing(updateEvidenceFiles));
    setIsSubmitPressed(false);
  };

  const renderEvidencePoints = () => {
    return (
      <div className={styles.listContainer}>
        <ol className={styles.listStyle}>
          {selectedReason &&
            SupportingEvidencePoints[selectedReason].map(bulletPoint => (
              <li key={bulletPoint}>{bulletPoint}</li>
            ))}
        </ol>
      </div>
    );
  };

  const handleFormModification = () => {
    if (!formModified) {
      setFormModified(true);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (formModified) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formModified]);

  return (
    <Card header="Submit evidence">
      <Content>
        <Form ref={formRef}>
          <Content>
            <Heading level={4}>{COUNTER_DISPUTE_REASON_HEADING}</Heading>
            <Select
              placeholder="Reason"
              value={selectedReason}
              validations={{
                required: {
                  value: true,
                  message: SubmitEvidenceValidationError.REASON_MISSING,
                },
              }}
              onChange={(reason: DisputeEvidenceReason) => {
                handleFormModification();
                setSelectedReason(reason);
              }}
            >
              <Option value={""}>None selected</Option>
              {Array.from(disputeReasons.keys()).map(k => (
                <Option value={k} key={k}>
                  {disputeReasons.get(k)}
                </Option>
              ))}
            </Select>
            <Heading level={4}>{PRODUCT_OR_SERVICE_DETAILS_HEADING}</Heading>
            <div className={styles.dateWrapper}>
              <InputDate
                onChange={newDate => {
                  handleFormModification();
                  setSelectedDate(newDate);
                }}
                value={selectedDate}
                placeholder="Service date"
              />
            </div>
            <InputText
              multiline
              value={description}
              onChange={(newDescription: string) => {
                handleFormModification();
                setDescription(newDescription);
              }}
              placeholder="Description of product or service"
              rows={10}
              validations={{
                maxLength: {
                  value: 20000,
                  message:
                    "Product or service details must be 20,000 characters or less",
                },
                required: {
                  value: true,
                  message:
                    SubmitEvidenceValidationError.PRODUCT_SERVICE_DETAILS_MISSING,
                },
              }}
            />
            <InputText
              multiline
              value={additionalInfo}
              onChange={(newAdditionalInfo: string) => {
                handleFormModification();
                setAdditionalInfo(newAdditionalInfo);
              }}
              placeholder="Additional information on why you should win this dispute"
              rows={6}
              validations={{
                maxLength: {
                  value: 20000,
                  message:
                    "Additional information must be 20,000 characters or less",
                },
                required: {
                  value: true,
                  message:
                    SubmitEvidenceValidationError.ADDITIONAL_INFORMATION_MISSING,
                },
              }}
            />
            <Heading level={4}>{UPLOAD_SUPPORTING_EVIDENCE_HEADING}</Heading>
            {selectedReason && (
              <Text>
                The following evidence is most relevant to this dispute. If you
                would like to share other types of evidence not listed below,
                upload it and label it as “other”.
              </Text>
            )}
            {renderEvidencePoints()}
            <Text>
              File type must be PDF, JPEG, or PNG with a combined file size less
              than 4.5 MB. Learn more in the{" "}
              <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
                help center
              </a>
              .
            </Text>
            <DisputeFileUpload
              onDisputeFilesChange={handleFileChange}
              isCategoriesSetAsInvalid={
                isSubmitPressed && (!categoriesUnique || categoriesMissing)
              }
            />
            <Heading level={4}>{CLIENT_INFORMATION_HEADING}</Heading>
            <InputText
              placeholder="Client name"
              value={clientName}
              onChange={(newClientName: string) => {
                handleFormModification();
                setClientName(newClientName);
              }}
              validations={{
                required: {
                  value: true,
                  message: SubmitEvidenceValidationError.CLIENT_NAME_MISSING,
                },
              }}
            />
            <InputText
              placeholder="Client email"
              value={clientEmail}
              onChange={(newClientEmail: string) => {
                handleFormModification();
                setClientEmail(newClientEmail);
              }}
            />
            <InputText
              multiline
              placeholder="Client billing address"
              value={clientBillingAddress}
              onChange={(newClientBillingAddress: string) => {
                handleFormModification();
                setClientBillingAddress(newClientBillingAddress);
              }}
            />
            <Checkbox
              label="I understand I can only submit this evidence to the cardholder's card issuer once."
              onChange={() => {
                if (!agreedToTerms && showAgreeToTermsErrorMessage) {
                  setShowAgreeToTermsErrorMessage(false);
                }
                handleFormModification();
                setAgreedToTerms(!agreedToTerms);
              }}
            />
            {showAgreeToTermsErrorMessage && (
              <Typography textColor="red">
                {SubmitEvidenceValidationError.CONFIRM_TERMS}
              </Typography>
            )}
            <div className={styles.actionsContainer}>
              <Button type="secondary" label="Cancel" onClick={onCancelClick} />
              <Button
                label="Submit Evidence"
                loading={isLoading}
                onClick={onSubmit}
              />
            </div>
          </Content>
        </Form>
      </Content>
    </Card>
  );
}
