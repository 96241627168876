import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  UpdatePastClientsSegmentCampaignMutation,
  UpdatePastClientsSegmentCampaignMutationVariables,
} from "~/utilities/API/graphql";
import { UPDATE_PAST_CLIENTS_SEGMENT_CAMPAIGN } from "./useUpdatePastClientsSegmentCampaign.graphql";

export function useUpdatePastClientsSegmentCampaign() {
  const [updatePastClientsSegmentCampaignMutation, { loading, error, data }] =
    useMutation<
      UpdatePastClientsSegmentCampaignMutation,
      UpdatePastClientsSegmentCampaignMutationVariables
    >(UPDATE_PAST_CLIENTS_SEGMENT_CAMPAIGN);
  const updatePastClientsSegmentCampaign = useCallback(
    async (input: UpdatePastClientsSegmentCampaignMutationVariables) => {
      const mutationResult = await updatePastClientsSegmentCampaignMutation({
        variables: input,
      });
      const userErrors =
        mutationResult.data?.campaignSegmentUpdatePastClients.userErrors ?? [];
      return {
        clientSegment:
          mutationResult.data?.campaignSegmentUpdatePastClients
            .pastClientsSegment,
        userErrors,
      };
    },
    [updatePastClientsSegmentCampaignMutation],
  );
  return {
    updatePastClientsSegmentCampaign,
    loading,
    error,
    data,
  };
}
