import { defineMessages } from "react-intl";

export const messages = defineMessages({
  doubleBookings: {
    id: "dashboard.components.doubleBookingInsight.doubleBookings",
    defaultMessage: "Double bookings",
    description: "The double bookings insights card",
  },
  doubleBookingsDescription: {
    id: "dashboard.components.doubleBookingInsight.doubleBookingsDescription  ",
    defaultMessage: "You have double bookings in the next 7 days",
    description: "The description of double bookings on the insights card",
  },
  viewSchedule: {
    id: "dashboard.components.doubleBookingInsight.viewSchedule",
    defaultMessage: "View Schedule",
    description: "The action button to view the schedule",
  },
});
