import { csrfToken } from "utilities/csrfToken";

export async function saveAccountDetails(
  userName: string,
  email: string,
  password: string,
  companyName: string,
  timezone: string,
  userId: string,
  userPhone: string,
) {
  const url = `/enterprise_onboarding/update/${userId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    /* eslint-disable @typescript-eslint/naming-convention */
    body: JSON.stringify({
      user_name: userName,
      email: email,
      password: password,
      company_name: companyName,
      timezone: timezone,
      user_phone: userPhone,
    }),
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  return (await response.json()) as {
    redirectUrl: string;
    id: number;
    success: boolean;
    message: string;
  };
}
