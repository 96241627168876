import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CAMPAIGN_REQUEST_FROM_EMAIL_VALIDATION_CODE } from "jobber/campaigns/hooks/useCampaignRequestFromEmailValidationCodeMutation/useCampaignRequestFromEmailValidationCodeMutation.graphql";
import type { CampaignRequestFromEmailValidationCodeMutation } from "~/utilities/API/graphql";

export function useCampaignRequestFromEmailValidationCodeMutation() {
  const [
    campaignRequestFromEmailValidationCodeMutation,
    { loading, error, data },
  ] = useMutation<CampaignRequestFromEmailValidationCodeMutation>(
    CAMPAIGN_REQUEST_FROM_EMAIL_VALIDATION_CODE,
  );

  const sendCampaignRequestFromEmailValidationCode = useCallback(async () => {
    const mutationResult =
      await campaignRequestFromEmailValidationCodeMutation();
    const userErrors =
      mutationResult.data?.campaignRequestFromEmailValidationCode.userErrors ??
      [];
    return {
      userErrors,
    };
  }, [campaignRequestFromEmailValidationCodeMutation]);

  return {
    sendCampaignRequestFromEmailValidationCode,
    loading,
    error,
    data,
  };
}
