import { gql } from "@apollo/client";
import { PRODUCT_OR_SERVICE_CUSTOM_FIELD_FRAGMENT } from "~/jobber/customFields/ProductOrServiceCustomFieldFragment.graphql";

export const PRODUCT_OR_SERVICE_CUSTOM_FIELDS_QUERY = gql`
  query ProductOrServiceCustomFields($productOrServiceId: EncodedId!) {
    productOrService(id: $productOrServiceId) {
      id
      name
      fileAttachment {
        id
        thumbnailUrl
      }
      customFields {
        ...ProductOrServiceCustomField
      }
    }
  }
  ${PRODUCT_OR_SERVICE_CUSTOM_FIELD_FRAGMENT}
`;
