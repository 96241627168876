import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages as useHeaderOptionsMessage } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useHeaderCardsOptions/messages";
import { messages as sentDelighterMessages } from "jobber/campaigns/components/SentDelighterModal/messages";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage } = createIntl();

// getByText selectors

export const getCampaignsText = () => {
  return screen.getByText(messages.campaigns.defaultMessage);
};

export const getNewCampaignButton = () => {
  return screen.getByText(messages.newCampaignLabel.defaultMessage);
};

export const getChooseTemplateText = () => {
  return screen.getByText(messages.chooseATemplate.defaultMessage);
};

export const getAutomatedReEngageHeaderCardTitle = () => {
  return screen.getByText(
    useHeaderOptionsMessage.automatedReEngagePastClientsTitle.defaultMessage,
  );
};

export const getAutomatedWinBackLostLeadsHeaderCardTitle = () => {
  return screen.getByText(
    useHeaderOptionsMessage.automatedWinBackLostLeadsTitle.defaultMessage,
  );
};

export const getOneOffStartFromScratchHeaderCardTitle = () => {
  return screen.getByText(
    useHeaderOptionsMessage.oneOffStartFromScratchTitle.defaultMessage,
  );
};

export const getCampaignListTitle = () => {
  return screen.getByText(messages.campaigns.defaultMessage);
};

export const getLottieMockText = () => {
  return screen.getByText("lottie mock");
};

export const getDelighterWithoutCount = () => {
  return screen.getByText(
    sentDelighterMessages.sentDelighterTextWithoutCount.defaultMessage,
  );
};

export const getDelighterWithCount = (count: number) => {
  return screen.getByText(
    formatMessage(sentDelighterMessages.sentDelighterTextWithCount, {
      count: count,
    }),
  );
};

export const getErrorMessage = () => {
  return screen.getByText(messages.errorMessage.defaultMessage);
};

export const getCustomErrorMessage = (message: string) => {
  return screen.getByText(message);
};

export const getCampaignName = (name: string) => {
  return screen.getByText(name);
};

export const getDelete = () => {
  return screen.getByText("Delete");
};

// getByRole selectors
export const getDialog = () => {
  return screen.getByRole("dialog");
};

export const getAlert = () => {
  return screen.getByRole("alert");
};

// getAllByText selectors
export const getUseTemplateHeaderCardButton = () => {
  return screen.getAllByText(
    useHeaderOptionsMessage.useTemplate.defaultMessage,
  );
};

export const getAllNewCampaignButton = () => {
  return screen.getAllByText(messages.newCampaignLabel.defaultMessage);
};

// queryByText selectors
export const getSentDelighter = () => {
  return screen.queryByText(
    sentDelighterMessages.sentDelighterTitle.defaultMessage,
  );
};

export const queryCustomErrorMessage = (message: string) => {
  return screen.queryByText(message);
};

export const queryAutomatedWinBackLostLeadsHeaderCardTitle = () => {
  return screen.queryByText(
    useHeaderOptionsMessage.automatedWinBackLostLeadsTitle.defaultMessage,
  );
};
// getByLabelText selectors
export const getMoreActionsLabel = () => {
  return screen.getByLabelText("More actions");
};

export const getDismissBannerButton = () => {
  return screen.getByLabelText("Close");
};

// queryByLabelText selectors
export const queryDismissBannerButton = () => {
  return screen.queryByLabelText("Close");
};

// Actions
export const iClickNewCampaignsButton = () => {
  return userEvent.click(getNewCampaignButton());
};

export const iClickTemplateHeaderCardButton = (index: number) => {
  return userEvent.click(getUseTemplateHeaderCardButton()[index]);
};

export const iDismissBanner = () => {
  return userEvent.click(getDismissBannerButton());
};
