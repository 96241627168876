import { defineMessages } from "react-intl";

export const messages = defineMessages({
  allowSelectQuantity: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.allowSelectQuantity",
    defaultMessage: "Allow customers to select quantity",
    description:
      "Let the SP decide if they want to allow customers to select quantity",
  },
  minimumQuantity: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.minimumQuantity",
    defaultMessage: "Minimum quantity",
    description: "Title for field for selecting minimum quantity",
  },
  minimumQuantityRequired: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.minimumQuantityRequired",
    defaultMessage: "Minimum quantity is required",
    description: "Error message to SP if they do not set a minimum quantity",
  },
  greaterThanZero: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.greaterThanZero",
    defaultMessage: "Enter a number greater than 0",
    description: "Error message to SP if they set a quantity less than 1",
  },
  minimumQuantityLessThanMaximumQuantity: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.minimumQuantityLessThanMaximumQuantity",
    defaultMessage: "Minimum quantity must be less than maximum quantity",
    description:
      "Error message to SP if they set a minimum quantity greater than the maximum quantity",
  },
  maximumQuantity: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.maximumQuantity",
    defaultMessage: "Maximum quantity",
    description: "Title for field for selecting maximum quantity",
  },
  maximumQuantityRequired: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.maximumQuantityRequired",
    defaultMessage: "Maximum quantity is required",
    description: "Error message to SP if they do not set a maximum quantity",
  },
  maximumQuantityLessThanMinimumQuantity: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.maximumQuantityLessThanMinimumQuantity",
    defaultMessage: "Maximum quantity must be greater than minimum quantity",
    description:
      "Error message to SP if they set a maximum quantity less than the minimum quantity",
  },
  quantityCheckboxDescription: {
    id: "WorkItemSelfServeBookingsSettings.serviceQuantities.quantityCheckboxDescription",
    defaultMessage:
      "Duration and unit price will scale based on quantity. (e.g. 15min x 4 = 1h)",
    description:
      "Description displayed beneath quantities enabled to let SPs know about scaling",
  },
});
