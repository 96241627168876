import {
  type SetupGuideStep,
  SetupGuideStepStatus,
} from "~/utilities/API/graphql";

export function getPendingIndex(steps: SetupGuideStep[], firstLoad: boolean) {
  if (firstLoad) {
    return 0;
  }
  for (let index = 0; index < steps.length; ++index) {
    if (steps[index].status === SetupGuideStepStatus.INCOMPLETE) {
      return index;
    }
  }
  return -1;
}
