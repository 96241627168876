/* eslint-disable max-statements */
/* eslint-disable import/no-internal-modules */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { Glimmer } from "@jobber/components/Glimmer";
import { Form } from "@jobber/components/Form";
import { debounce } from "lodash";
import { useFormState } from "@jobber/hooks/useFormState";
import { useInView } from "@jobber/hooks/useInView";
import classnames from "classnames";
import type {
  IntervalUnits,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import type { ClientSegmentConditionalVariables } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { DrawerMode } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { AdditionalCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { BaseCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/BaseCriteria";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import styles from "./UpcomingClientsSegmentEdit.module.css";
import { messages } from "./messages";
import { INTERVAL_UNIT_OPTIONS } from "./constants";
import { useIntervalValidation } from "../../hooks/useIntervalValidation/useIntervalValidation";

export interface UpcomingClientsSegmentEditProps {
  segmentCriteria: UpcomingClientsCriteriaInput;
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<DrawerMode>>;
  onSave: (segmentCriteria: UpcomingClientsCriteriaInput) => void;
  closeSideDrawer: () => void;
  saveError?: string;
  loading?: boolean;
  pageSize?: number;
}

const clientSegmentDataConditionalVariables: Partial<ClientSegmentConditionalVariables> =
  {
    isUpcomingClientDrawer: true,
  };

export function UpcomingClientsSegmentEdit({
  additionalCriteria,
  updateAdditionalCriteria,
  setDrawerState,
  closeSideDrawer,
  onSave,
  saveError,
  loading,
  pageSize = 20,
  segmentCriteria,
}: UpcomingClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [ref, bodyHasBeenScrolled] = useInView<HTMLDivElement>();

  const [interval, setInterval] = useState<number>(segmentCriteria.interval);
  const [unit, setUnit] = useState<IntervalUnits>(segmentCriteria.unit);

  const { validationMessage, setValidationMessage, validateInterval } =
    useIntervalValidation();

  const [{ isValid }, setFormState] = useFormState();

  const isFormValid = useCallback(() => {
    return validateInterval(interval) === true && isValid;
  }, [interval, isValid, validateInterval]);

  const {
    refetch: fetchData,
    fullReload,
    error: refreshError,
    clientSegmentsData,
  } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const errorMessage = useMemo(() => {
    const refreshErrorMessage = refreshError
      ? formatMessage(messages.refreshErrorMessage)
      : "";
    const saveErrorMessage = saveError
      ? formatMessage(messages.saveErrorMessage)
      : "";
    return saveErrorMessage || refreshErrorMessage;
  }, [formatMessage, refreshError, saveError]);

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: UpcomingClientsCriteriaInput) => {
        const { clientTagCriteria, lineItemCriteria, jobTypeCriteria } =
          newSegmentCriteria;
        fetchData({
          first: pageSize,
          after: btoa("0"),
          upcomingClientInterval: newSegmentCriteria.interval,
          upcomingClientUnit: newSegmentCriteria.unit,
          upcomingClientLineItemCriteria: lineItemCriteria,
          upcomingClientTagCriteria: clientTagCriteria,
          upcomingClientJobTypeCriteria: jobTypeCriteria,
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [fetchData, pageSize],
  );

  useEffect(() => {
    if (unit && interval && isFormValid()) {
      const { clientTagCriteria, jobTypeCriteria, lineItemCriteria } =
        parseAdditionalCriteria(additionalCriteria);
      debounceFetchData({
        unit,
        interval,
        clientTagCriteria,
        lineItemCriteria,
        jobTypeCriteria,
      });
    }
  }, [
    unit,
    interval,
    validationMessage,
    debounceFetchData,
    isFormValid,
    additionalCriteria,
  ]);

  return (
    <div className={styles.clientSegmentDrawer}>
      <div
        className={classnames(styles.drawerHeaderNoLeftPad, {
          [styles.scrollable]: !bodyHasBeenScrolled,
        })}
      >
        <Heading level={3}>
          <Button
            variation="subtle"
            type="tertiary"
            icon="arrowLeft"
            ariaLabel="arrowLeft"
            onClick={() => setDrawerState(DrawerMode.View)}
          />
          {formatMessage(messages.clientSegmentEditSideDrawerTitle)}
        </Heading>
        <Button
          variation="subtle"
          type="tertiary"
          icon="remove"
          ariaLabel="close"
          onClick={closeSideDrawer}
        />
      </div>
      <div className={styles.drawerFormContent}>
        <div ref={ref}></div>
        <Form onStateChange={setFormState} onSubmit={onFormSubmit}>
          <Content spacing="large">
            {errorMessage && <Banner type="error">{errorMessage}</Banner>}
            <Content>
              <BaseCriteria
                segmentCriteria={segmentCriteria}
                setInterval={setInterval}
                validationMessage={validationMessage}
                setValidationMessage={setValidationMessage}
                validateInterval={validateInterval}
                setUnit={setUnit}
                preFieldsDescription={formatMessage(
                  messages.upcomingClientsDescription1,
                )}
                intervalUnitOptions={INTERVAL_UNIT_OPTIONS}
              />
              <AdditionalCriteria
                criteria={additionalCriteria}
                updateAdditionalCriteria={handleUpdateAdditionalCriteria}
              />
            </Content>
            <Text>
              {fullReload ? (
                <Glimmer.Text />
              ) : (
                <>
                  <Emphasis variation="bold">
                    {clientSegmentsData.upcomingClients?.total} clients{" "}
                  </Emphasis>
                  {formatMessage(messages.allClientsDescription1)}
                </>
              )}
            </Text>
            <Button
              type="primary"
              fullWidth={true}
              label={formatMessage(messages.clientSegmentEditSideDrawerUpdate)}
              loading={loading}
              submit={true}
            />
          </Content>
        </Form>
      </div>
    </div>
  );
  // Last job was completed more

  function onFormSubmit() {
    const {
      clientTagCriteria,
      jobTypeCriteria,
      lineItemCriteria,
      jobStatusCriteria,
    } = parseAdditionalCriteria(additionalCriteria);
    onSave({
      unit: unit,
      interval: interval,
      clientTagCriteria,
      jobTypeCriteria,
      lineItemCriteria,
      jobStatusCriteria,
    });
  }

  function handleUpdateAdditionalCriteria(
    value: AdditionalCriteriaReducerActions,
  ) {
    return updateAdditionalCriteria(value, true);
  }
}
