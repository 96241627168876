import { gql } from "@apollo/client";

const NOTIFICATION_SETTING_QUERY = gql`
  query NotificationSettings {
    paymentNotification: jobberPaymentsSetting(id: PAYMENT_NOTIFICATION) {
      enabled
    }
    autoSendEmailReceipt: jobberPaymentsSetting(id: AUTO_SEND_EMAIL_RECEIPT) {
      enabled
    }
  }
`;

export { NOTIFICATION_SETTING_QUERY };
