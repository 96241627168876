import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { useMutation } from "@apollo/client";
import {
  ACCOUNT_ADDONS_INFO,
  ACCOUNT_ADDON_REMOVE,
} from "jobber/billing/features/SubscriptionAddons/SubscriptionAddons.graphql";
import type {
  MutationErrors,
  SubscriptionAddonRemoveMutation,
  SubscriptionAddonRemoveMutationVariables,
} from "~/utilities/API/graphql";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Intercom } from "utilities/chat";
import { messages } from "./messages";
import styles from "./RemoveAddonModal.module.css";

interface RemoveAddonModalProps {
  addonName: string;
  addonIdentifier: string;
  addonCode: string;
  includedAddonSetIdentifiers?: string[];
}

const addonFeaturesMap: { [key: string]: MessageDescriptor[] } = {
  campaigns: [messages.campaignsFeature1, messages.campaignsFeature2],
  reviews: [messages.reviewsFeature1, messages.reviewsFeature2],
  referrals: [messages.referralsFeature1, messages.referralsFeature2],
  marketing_suite: [
    messages.marketingSuiteFeature1,
    messages.marketingSuiteFeature2,
    messages.marketingSuiteFeature3,
  ],
};

const addonRemovalDetailsMap: { [key: string]: MessageDescriptor } = {
  campaigns: messages.campaigns,
  reviews: messages.reviews,
  referrals: messages.referrals,
  marketing_suite: messages.marketingSuiteDetails,
};

// eslint-disable-next-line max-statements
export function RemoveAddonModal(props: RemoveAddonModalProps) {
  const { addonIdentifier, addonCode, addonName, includedAddonSetIdentifiers } =
    props;

  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const { formatMessage } = useIntl();

  const [addonRemove] = useMutation<
    SubscriptionAddonRemoveMutation,
    SubscriptionAddonRemoveMutationVariables
  >(ACCOUNT_ADDON_REMOVE, {
    refetchQueries: [ACCOUNT_ADDONS_INFO],
  });

  const addonRemovalDetails = formatMessage(
    addonRemovalDetailsMap[addonIdentifier],
  );

  return (
    <>
      <Button
        size={"small"}
        label={formatMessage(messages.remove)}
        ariaLabel={formatMessage(messages.removeAriaLabel, { addonName })}
        type={"tertiary"}
        variation={"subtle"}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        title={formatMessage(messages.modalTitle, { addonName })}
        open={modalOpen}
        onRequestClose={handleCancel}
        primaryAction={{
          label: formatMessage(messages.remove),
          onClick: submitForm,
          loading: isSubmitting,
          variation: "destructive",
        }}
        secondaryAction={{
          label: formatMessage(messages.cancel),
          disabled: isSubmitting,
          onClick: handleCancel,
        }}
      >
        <Content>
          <PurchaseFormErrors errors={submissionErrors} />
          <Text>
            {formatMessage(messages.removalInformation, {
              addonRemovalDetails,
            })}
          </Text>
          <ul className={styles.featureList}>
            {addonFeaturesMap[addonIdentifier].map((feature, index) => {
              return (
                <li key={`addonFeature-${index}`}>
                  <Text>{formatMessage(feature)}</Text>
                </li>
              );
            })}
          </ul>
          {!!includedAddonSetIdentifiers?.length && (
            <Text>{formatMessage(messages.addonPurchasedIndividually)}</Text>
          )}
          <Text>{formatMessage(messages.removalCreditMessage)}</Text>
        </Content>
      </Modal>
    </>
  );

  function handleCancel() {
    setModalOpen(!modalOpen);
    setSubmissionErrors([]);
  }

  async function submitForm() {
    setIsSubmitting(true);
    await handleRemoveAddon();
    sendAmplitudeRemoveEvent(addonName);
    sendIntercomRemoveEvent(addonName);
    setIsSubmitting(false);
  }

  async function handleRemoveAddon() {
    const payload = {
      variables: {
        input: {
          addonCode: addonCode,
        },
      },
    };

    try {
      const result = await addonRemove(payload);
      if (result?.data) {
        const userErrors = result.data.subscriptionAddonRemove
          .userErrors as MutationErrors[];

        if (userErrors.length > 0) {
          handleError(userErrors[0].message);
        }

        if (result.data.subscriptionAddonRemove.success) {
          handleSuccess(formatMessage(messages.addonRemoved, { addonName }));
        }
      }
    } catch (addonRemoveError) {
      handleError(formatMessage(messages.genericError));
    }
  }

  function handleSuccess(message: string) {
    showToast({
      message: message,
      variation: "success",
    });
    setModalOpen(!modalOpen);
  }

  function handleError(error: string) {
    setSubmissionErrors([error]);
  }
}

function sendIntercomRemoveEvent(addonName: string) {
  Intercom.EXECUTE("trackEvent", `Unsubscribed ${addonName} Add-on`);
}

function sendAmplitudeRemoveEvent(addonName: string) {
  Amplitude.TRACK_EVENT("CTA Clicked", {
    name: `Unsubscribed ${addonName} Add-on`,
  });
}
