import currency from "currency.js";

/**
 * Formats a numeric currency value with two digits of precision given a currency symbol.
 *
 * @param amount - numeric value that needs to be formatted
 * @param currencySymbol - string representing the symbol the symbol (e.g. `$`) of the currency
 * @param precision - OPTIONAL: numeric value representing number of decimals (e.g. 2) of the currency
 * @returns formatted currency string prepended with the currency symbol and decimals
 */
export function formatCurrency(
  amount: number,
  currencySymbol: string,
  precision?: number,
): string {
  return currency(amount, {
    symbol: currencySymbol,
    precision: precision ?? 2,
  }).format();
}
