import React, { useState } from "react";
import type { IntlFormatters } from "react-intl";
import { useIntl } from "react-intl";
import { Icon } from "@jobber/components/Icon";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import { Text } from "@jobber/components/Text";
import { StoredCardListItem } from "~/bunker/vaultedCards/StoredCardListItem/";
import type { StoredPaymentMethodInterface } from "~/bunker/vaultedCards/StoredCardListItem";
import styles from "./PaymentMethodListItem.module.css";
import { messages } from "./messages";

export interface PaymentMethodListItemProps {
  disableButtons: boolean;
  showTrashButton: boolean;
  paymentMethod: StoredPaymentMethodInterface;
  onSetAsDefault(
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined>;
  onDeleteConfirm(
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined>;
  useCompactLayout?: boolean;
  shouldShowDeleteDisclaimer?: boolean;
}

function DeleteDefaultPaymentMessaging({
  t,
}: {
  t: IntlFormatters["formatMessage"];
}) {
  return (
    <>
      <Text>{t(messages.deleteDefaultPaymentMethodDisclaimerMessage)}</Text>
      <Text>{t(messages.deleteDefaultPaymentMethodConfirmationMessage)}</Text>
    </>
  );
}

function PaymentMethodListItem(props: PaymentMethodListItemProps) {
  const {
    showTrashButton,
    disableButtons,
    paymentMethod,
    onSetAsDefault,
    onDeleteConfirm,
    useCompactLayout = false,
    shouldShowDeleteDisclaimer = false,
  } = props;

  const { formatMessage: t } = useIntl();

  const trashButtonColor = disableButtons ? "grey" : "red";
  const makeDefaultButtonColor = disableButtons ? "grey" : "yellow";

  const title = shouldShowDeleteDisclaimer
    ? t(messages.deleteDefaultPaymentMethodConfirmationTitle)
    : t(messages.deletePaymentMethodConfirmationTitle);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [
    isDeleteConfirmationModalLoading,
    setIsDeleteConfirmationModalLoading,
  ] = useState(false);

  const onDeleteConfirmAction = async () => {
    try {
      setIsDeleteConfirmationModalLoading(true);
      await onDeleteConfirm(paymentMethod);
    } catch (error) {
      showToast({
        message: `Something went wrong. ${error && (error as Error).message}`,
        variation: "error",
      });
    } finally {
      setIsDeleteConfirmationModalLoading(false);
      setIsDeleteConfirmationModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        title={title}
        size="small"
        onRequestClose={() => setIsDeleteConfirmationModalOpen(false)}
        open={isDeleteConfirmationModalOpen}
        primaryAction={{
          label: "Delete",
          variation: "destructive",
          onClick: () => onDeleteConfirmAction(),
          loading: isDeleteConfirmationModalLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: () => setIsDeleteConfirmationModalOpen(false),
          disabled: isDeleteConfirmationModalLoading,
        }}
      >
        <Content>
          {shouldShowDeleteDisclaimer ? (
            <DeleteDefaultPaymentMessaging t={t} />
          ) : (
            <Text>{t(messages.deletePaymentMethodConfirmationMessage)}</Text>
          )}
        </Content>
      </Modal>
      <li
        key={paymentMethod.uuid}
        className={`list-item ${styles.cardListItem} ${
          useCompactLayout ? styles.compactCardListItem : ""
        }`}
        data-testid="payment-method-list-item"
      >
        <button
          type="button"
          aria-label={t(messages.defaultPaymentMethodAria)}
          className={styles.inlineAction}
          disabled={disableButtons}
          onClick={() => onSetAsDefault(paymentMethod)}
        >
          {paymentMethod.isDefault ? (
            <Icon name="starFill" color={makeDefaultButtonColor} />
          ) : (
            <Icon name="star" />
          )}
        </button>
        <StoredCardListItem
          uuid={paymentMethod.uuid}
          last4={paymentMethod.last4}
          expiryMonth={paymentMethod.expiryMonth}
          expiryYear={paymentMethod.expiryYear}
          brand={paymentMethod.brand}
          isDefault={paymentMethod.isDefault}
          expiryStatus={paymentMethod.expiryStatus}
          type={paymentMethod.type}
        />

        {showTrashButton && (
          <button
            type="button"
            aria-label={t(messages.deletePaymentMethodAria)}
            className={styles.inlineAction}
            disabled={disableButtons}
            onClick={() => setIsDeleteConfirmationModalOpen(true)}
          >
            <Icon name="trash" color={trashButtonColor} />
          </button>
        )}
      </li>
    </>
  );
}

export { PaymentMethodListItem };
