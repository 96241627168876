import type { InlineLabelColors } from "@jobber/components/InlineLabel";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import {
  QuoteDetailSortKey,
  QuoteStatusTypeEnum,
} from "~/utilities/API/graphqlFranchiseManagement";
import type { QuoteDetailDateFilterTypes } from "jobber/franchise/features/Reporting/views/QuoteDetailReport/types";

export const REPORT_LABELS = {
  reportTitle: "Quotes (Detailed)",
  franchisee: "Franchisee",
  quoteNumber: "Quote #",
  clientName: "Client Name",
  propertyAddress: "Property Address",
  quoteStatus: "Status",
  draftedOn: "Drafted Date",
  sentOn: "Sent Date",
  lastChangesOn: "Last Changes Requested Date",
  approvedOn: "Approved Date",
  convertedOn: "Converted Date",
  archivedOn: "Archived Date",
  total: "Total ($)",
  totalFooter: "Totals",
};

export const DEFAULT_SORT_KEY = QuoteDetailSortKey.FRANCHISEE_NAME;

export const QUOTE_STATUS_KEYS: Record<string, string> = {
  [QuoteStatusTypeEnum.APPROVED]: "Approved",
  [QuoteStatusTypeEnum.ARCHIVED]: "Archived",
  [QuoteStatusTypeEnum.AWAITING_RESPONSE]: "Awaiting Response",
  [QuoteStatusTypeEnum.CHANGES_REQUESTED]: "Changes Requested",
  [QuoteStatusTypeEnum.CONVERTED]: "Converted",
  [QuoteStatusTypeEnum.DRAFT]: "Draft",
};

export const QUOTE_STATUS_COLORS: Record<string, InlineLabelColors> = {
  [QuoteStatusTypeEnum.APPROVED]: "green",
  [QuoteStatusTypeEnum.ARCHIVED]: "greyBlue",
  [QuoteStatusTypeEnum.AWAITING_RESPONSE]: "orange",
  [QuoteStatusTypeEnum.CHANGES_REQUESTED]: "red",
  [QuoteStatusTypeEnum.CONVERTED]: "teal",
  [QuoteStatusTypeEnum.DRAFT]: "greyBlue",
};

export const DEFAULT_DATE_FILTER_VALUES: FilterType = {
  key: "date_range",
  value: {
    startDate: defaultDateRangeOption.start,
    endDate: defaultDateRangeOption.end,
  },
};

export const DATE_FILTER_LABELS = {
  drafted: "Drafted within",
  sent: "Sent within",
  converted: "Converted within",
};

export const DEFAULT_DATE_FILTER_TYPE: QuoteDetailDateFilterTypes = "drafted";
