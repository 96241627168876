import { gql } from "@apollo/client";

export const ASSIGNABLE_USERS_QUERY = gql`
  query AssignableUsers {
    account {
      currentUser {
        id
      }
    }
    users(filter: { status: ACTIVATED }) {
      nodes {
        id
        name {
          full
        }
        avatar {
          image {
            photoUrl
          }
          initials
        }
      }
    }
  }
`;
