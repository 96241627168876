import { defineMessages } from "react-intl";

export const newSpApprovedStateMessages = defineMessages({
  thankYouMessage: {
    id: "ukKycRegistration.newSPApproved.thankYouMessage",
    defaultMessage:
      "Thank you for completing verification! Enable text messaging by selecting a dedicated phone number (DPN) from a list of local phone numbers.",
    description:
      "Thank you for completing verification! Enable text messaging by selecting a dedicated phone number (DPN) from a list of local phone numbers.",
  },
  approvedButtonText: {
    id: "ukKycRegistration.newSPApproved.buttonText",
    defaultMessage: "Choose Number",
    description: "CTA text for the button in the approved state",
  },
  chooseYoureFragment: {
    id: "ukKycRegistration.newSPApproved.youreFragment",
    defaultMessage: "Choose your",
    description:
      "Part of the larger sentence in the approved state for ''Choose your number''",
  },
  numberFragment: {
    id: "ukKycRegistration.newSPApproved.approvedFragment",
    defaultMessage: "number",
    description:
      "Part of the larger sentence in the approved state for ''Choose your number''",
  },
});
