import React from "react";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { WistiaPlayer } from "jobber/wistiaPlayer/components";
import { messages } from "./messages";
import styles from "./WelcomeCard.module.css";

export interface WelcomeCardProps {
  welcomeVideoData: { id: string; text: string };
  onDismiss(): void;
}

export function WelcomeCard({ welcomeVideoData, onDismiss }: WelcomeCardProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.outer_wrapper}>
      <Card>
        <div className={styles.header}>
          <Heading level={2}>
            {formatMessage(messages.welcomeToJobberHeading)}
          </Heading>
          <button
            className={`${styles.dismissButton} shrink`}
            onClick={onDismiss}
            type="button"
            aria-label={formatMessage(messages.dismissButtonAriaLabel)}
            data-testid="welcomeCardDismissButton"
          >
            <Icon name="cross" />
          </button>
        </div>
        <div data-testid="welcomeVideo">
          <div className={styles.message}>
            <Text>{welcomeVideoData.text}</Text>
          </div>
          <WistiaPlayer videoId={welcomeVideoData.id} popover={true} />
        </div>
      </Card>
    </div>
  );
}
