import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignRecipientsTitle: {
    id: "campaigns.campaignReviewPage.campaignRecipientsTitle",
    defaultMessage: "Recipients",
    description: "Title of campaign recipients section",
  },
  campaignRecipientTimeoutError: {
    id: "campaigns.campaignReviewPage.campaignRecipientTimeoutError",
    defaultMessage:
      "Recipient count couldn't be retrieved due to a system timeout. You can proceed with sending your campaign without it.",
    description:
      "The error message to show when the client segment query returns a timeout error",
  },
  campaignRecipientEditButton: {
    id: "campaigns.campaignReviewPage.automatedCampaignRecipientEditButton",
    defaultMessage: "Edit",
    description: "Edit recipients button",
  },
});
