import { defineMessages } from "react-intl";

export const messages = defineMessages({
  lineItemCost: {
    id: "workOrders.jobCost.jobCostHeader.lineItemCost",
    defaultMessage: "Line Item Cost",
    description: "Shared label for Line Item Cost in the JobCost component",
  },
  hideProfitabilityLabel: {
    id: "workOrders.jobCost.jobCostHeader.hideProfitability",
    defaultMessage: "Hide Profitability",
    description: "Label for hide profitability toggle",
  },
  showProfitabilityLabel: {
    id: "workOrders.jobCost.jobCostHeader.showProfitability",
    defaultMessage: "Show Profitability",
    description: "Label for show profitability toggle",
  },
  labour: {
    id: "workOrders.jobCost.jobCostHeader.labour",
    defaultMessage: "Labour",
    description: "Shared label for Labour JobCost component (translated)",
  },
});
