import { defineMessages } from "react-intl";

export const messages = defineMessages({
  highlightCopy: {
    id: "jobCostDiscoveryCard.highlightCopy",
    defaultMessage: "job costing",
    description: "Highlight Copy",
  },
  headerText: {
    id: "jobCostDiscoveryCard.headerText",
    defaultMessage: "See real-time profit calculations with",
    description: "Header Text",
  },
  cardTrialText: {
    id: "jobCostDiscoveryCard.cardTrialText",
    defaultMessage: "Try it for free with a 14-day Grow plan trial.",
    description: "Card Trial Text",
  },
  cardText: {
    id: "jobCostDiscoveryCard.cardText",
    defaultMessage: "Be confident that every job is profitable.",
    description: "Card Text",
  },
  learnMoreLabel: {
    id: "jobCostDiscoveryCard.learnMoreLabel",
    defaultMessage: "Learn More",
    description: "Learn More Label",
  },
  jobCostFeatureUrl: {
    id: "jobCostDiscoveryCard.jobCostingFeatureUrl",
    defaultMessage: "/job_costing_feature",
    description: "Job Costing Feature Url",
  },
  tryFeatureLabel: {
    id: "jobCostDiscoveryCard.tryFeatureLabel",
    defaultMessage: "Try This Feature",
    description: "Try Feature Label",
  },
  defaultError: {
    id: "jobCostDiscoveryCard.defaultError",
    defaultMessage: "Grow trial could not be started. Please try again.",
    description: "Default Error",
  },
  dismissAriaLabel: {
    id: "jobCostDiscoveryCard.JobCostDiscoveryCard.dismissAriaLabel",
    defaultMessage: "Dismiss",
    description: "Dismiss button Aria Label",
  },
});
