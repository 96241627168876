import { gql, useQuery } from "@apollo/client";

export const UPCOMING_PAYOUTS_QUERY = gql`
  query UpcomingPayouts {
    payoutRecords(filter: { status: IN_TRANSIT }) {
      nodes {
        arrivalDate
        id
        netAmount
      }
    }
    jobberPaymentsPayouts {
      instantPayoutAmount
      processingPayoutAmount
      lastPayoutAmount
      instantPayoutRequestedLast24Hours
      hasValidPayoutDebitCard
    }
    moneyManagementAccount {
      availableBalance
      isPayoutAccount
    }
  }
`;

export interface UpcomingPayoutsType {
  payoutRecords: {
    nodes: {
      arrivalDate: string;
      id: string;
      netAmount: number;
    }[];
  };
  jobberPaymentsPayouts: {
    instantPayoutAmount?: string;
    instantPayoutRequestedLast24Hours?: string;
    processingPayoutAmount?: string;
    lastPayoutAmount?: string;
    hasValidPayoutDebitCard?: boolean;
  };
  moneyManagementAccount?: {
    availableBalance: number;
    isPayoutAccount: boolean;
  };
}

interface UpcomingPayoutsData {
  lastPayoutAmount?: number;
  availableBalance?: number;
  showJobberMoneyPayouts: boolean;
  expectedPayouts: { arrivalDate: string; id: string; netAmount: number }[];
  processingPayout: number;
  instantPayout: string;
  instantPayoutRequestedLast24Hours: string;
  hasValidPayoutDebitCard: boolean;
}

const defaultData: UpcomingPayoutsData = {
  lastPayoutAmount: undefined,
  availableBalance: undefined,
  showJobberMoneyPayouts: false,
  expectedPayouts: [],
  processingPayout: 0,
  instantPayout: "0",
  instantPayoutRequestedLast24Hours: "0",
  hasValidPayoutDebitCard: false,
};

export function useUpcomingPayouts(): {
  loading: boolean;
  data: UpcomingPayoutsData;
} {
  const { data, loading } = useQuery<UpcomingPayoutsType>(
    UPCOMING_PAYOUTS_QUERY,
    { fetchPolicy: "cache-and-network" },
  );

  if (!data) {
    return { data: defaultData, loading };
  }

  const mappedData = {
    lastPayoutAmount: data.jobberPaymentsPayouts.lastPayoutAmount
      ? Number(data.jobberPaymentsPayouts.lastPayoutAmount)
      : undefined,
    availableBalance: data.moneyManagementAccount?.availableBalance,
    showJobberMoneyPayouts:
      data.moneyManagementAccount?.isPayoutAccount ?? false,
    expectedPayouts: data.payoutRecords.nodes,
    processingPayout: Number(
      data.jobberPaymentsPayouts.processingPayoutAmount ?? "0",
    ),
    instantPayout: data.jobberPaymentsPayouts.instantPayoutAmount ?? "0",
    instantPayoutRequestedLast24Hours:
      data.jobberPaymentsPayouts.instantPayoutRequestedLast24Hours ?? "0",
    hasValidPayoutDebitCard:
      data.jobberPaymentsPayouts.hasValidPayoutDebitCard || false,
  };

  return { data: mappedData, loading };
}
