import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import type {
  CancellationFormAnswer,
  CancellationFormStepProps,
} from "./CancellationForm.d";
import styles from "./CancellationFormModal.module.css";
import {
  type CancellationFormQuestion,
  FINAL_QUESTION_IDS,
} from "./CancellationFormQuestion.d";
import { Question } from "./CancellationFormQuestion";
import { WHAT_WILL_YOU_USE_NEXT_QUESTION_ID } from "./CancellationFormModal";

export function ProcessCancellationStep({
  questions,
  answers,
  onPrevious,
  addAnswer,
  onFinalizeCancellation,
  askAboutNextSolution,
}: CancellationFormStepProps & {
  askAboutNextSolution: boolean;
  onFinalizeCancellation(
    setButtonLoadingState: (loadingState: boolean) => void,
  ): Promise<void>;
}) {
  const [buttonLoadingState, setButtonLoadingState] = useState(false);

  const questionsToAsk = askAboutNextSolution
    ? FINAL_QUESTION_IDS
    : FINAL_QUESTION_IDS.filter(
        id => id !== WHAT_WILL_YOU_USE_NEXT_QUESTION_ID,
      );

  async function handleCancel() {
    await onFinalizeCancellation(setButtonLoadingState);
  }

  return (
    <>
      <Content>
        Is there anything else you&apos;d like to tell us to help improve our
        product?
      </Content>
      {questionsToAsk.map((id: string) => {
        const question = questions.find(
          (formQuestion: CancellationFormQuestion) => formQuestion.id === id,
        );
        const existingValue =
          answers.find((answer: CancellationFormAnswer) => answer.id === id)
            ?.value || "";

        return (
          question && (
            <Question
              key={question.id}
              id={question.id}
              type={question.type}
              label={question.label}
              customerText={question.customerText}
              options={question.options}
              value={existingValue}
              nextQuestion={question.nextQuestion}
              addAnswer={addAnswer}
            />
          )
        );
      })}
      <div className={styles.actionButtonsContainer}>
        <Button label="Previous" onClick={onPrevious} variation="subtle" />
        <Button
          variation="destructive"
          label="Cancel Subscription"
          loading={buttonLoadingState}
          onClick={handleCancel}
        />
      </div>
    </>
  );
}
