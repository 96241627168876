import { useMutation, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import type {
  DisputeEditInput,
  DisputeEditMutation,
  DisputeEditMutationVariables,
  DisputeUpdatedSubscription,
  EvidenceFileInput,
  JobberPaymentsDispute,
  MutationErrors,
} from "~/utilities/API/graphql";
import type { SubmitEvidencePayload } from "jobber/features/PaymentDisputes/types";
import { DISPUTE_EDIT_MUTATION, DISPUTE_UPDATED_SUBSCRIPTION } from "./graphql";
import { useUploadEvidenceFiles } from "./useUploadEvidenceFiles";
import { messages } from "./messages";
import { HELP_CENTER_UPLOAD_TROUBLESHOOTING_URL } from "../constants";

export interface UseDisputeUpdateProps {
  disputeId: string;
  updateCompleted: () => void;
}

interface ReturnedDisputeUpdateData {
  updateDispute(evidence: SubmitEvidencePayload): void;
  dispute?: JobberPaymentsDispute;
  loading: boolean;
  error?: string;
}

export function useDisputeUpdate({
  disputeId,
  updateCompleted,
}: UseDisputeUpdateProps): ReturnedDisputeUpdateData {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [dispute, setDispute] = useState<JobberPaymentsDispute>();

  const { uploadEvidenceFiles } = useUploadEvidenceFiles();

  const [updateDisputeResult] = useMutation<
    DisputeEditMutation,
    DisputeEditMutationVariables
  >(DISPUTE_EDIT_MUTATION);

  const { data, error: subscriptionError } =
    useSubscription<DisputeUpdatedSubscription>(DISPUTE_UPDATED_SUBSCRIPTION, {
      variables: {
        disputeId: disputeId,
      },
    });

  useEffect(() => {
    handleSubscriptionData();
  });

  return { updateDispute, loading, dispute, error };

  // eslint-disable-next-line max-statements
  async function updateDispute(evidence: SubmitEvidencePayload) {
    setError("");
    setLoading(true);

    let evidenceInput: EvidenceFileInput[] = [];
    if (evidence.evidenceFiles) {
      try {
        evidenceInput = await uploadEvidenceFiles(evidence.evidenceFiles);
      } catch (e) {
        setError(
          formatMessage(messages.fileUploadErrorMessage, {
            link: HELP_CENTER_UPLOAD_TROUBLESHOOTING_URL,
          }),
        );
        setLoading(false);
        return;
      }
    }

    const input: DisputeEditInput = {
      clientInformation: evidence.clientInformation,
      evidenceReason: evidence.evidenceReason,
      productDescription: evidence.productDescription,
      refundRefusalExplanation: evidence.refundRefusalExplanation,
      serviceDate: evidence.serviceDate,
    };
    if (evidenceInput.length > 0) {
      input.evidenceFilesInput = evidenceInput;
    }

    const payload = {
      variables: {
        id: disputeId,
        input,
      },
    };

    updateDisputeResult(payload)
      .then(result => {
        const errors = result?.data?.disputeEdit
          ?.userErrors as MutationErrors[];
        if (errors.length > 0) {
          setError(errors[0].message);
        }
      })
      .catch(() => {
        setError(`Failed to update the dispute`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSubscriptionData() {
    if (!data) {
      return;
    }

    if (dispute) {
      return;
    }

    setLoading(false);

    if (subscriptionError || data.disputeUpdated.userErrors.length > 0) {
      setError(data.disputeUpdated.userErrors[0].message);
      return;
    }

    if (data) {
      setDispute(data.disputeUpdated.dispute as JobberPaymentsDispute);
      updateCompleted();
    }
  }
}
