import type {
  CancellationFormAction,
  CancellationFormAnswer,
  CancellationFormState,
} from "./CancellationForm.d";

export function CancellationFormReducer(
  previousState: CancellationFormState,
  action: CancellationFormAction,
): CancellationFormState {
  switch (action.type) {
    case "add":
      return [...previousState, ...[action.answer]];
    case "update":
      return previousState.map((answer: CancellationFormAnswer) => {
        if (answer.id === action.answer.id) {
          return action.answer;
        } else {
          return answer;
        }
      });
    case "replace":
      return [...previousState.slice(0, action.index), ...[action.answer]];
    case "clear":
      return [];
    default:
      throw new Error("Invalid action type");
  }
}
