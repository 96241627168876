import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import {
  type InvoiceSummaryFilterInput,
  type InvoiceSummarySortInput,
  InvoiceSummarySortKey,
  SortDirectionEnum,
} from "~/utilities/API/graphqlFranchiseManagement";
import type { InvoiceSummarySortState } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport/types";

export function useFormatSortAndFilter() {
  return { formatSort, formatFilter };
}

function formatSort(
  sort: [InvoiceSummarySortState] | [],
): InvoiceSummarySortInput {
  return {
    key: sort[0]?.id || InvoiceSummarySortKey.FRANCHISE_NAME,
    direction: sort[0]?.desc
      ? SortDirectionEnum.DESCENDING
      : SortDirectionEnum.ASCENDING,
  };
}

function formatFilter(filter?: FilterType): InvoiceSummaryFilterInput {
  return {
    startDate:
      filter?.value.startDate.toISOString() ||
      defaultDateRangeOption.start.toISOString(),
    endDate:
      filter?.value.endDate.toISOString() ||
      defaultDateRangeOption.end.toISOString(),
  };
}
