import React from "react";
import { Button } from "@jobber/components/Button";
import type { BillingStepProps } from "./BillingStep.d";

export function BillingStep({ onClick }: BillingStepProps) {
  /* Props */
  return (
    <div className="u-textRight">
      <Button label="Submit" onClick={onClick} />
    </div>
  );
}
