import React from "react";
import { SchedulingDateInputs } from "./SchedulingDateInputs";
import { useScheduleCardChangeHandlers, useScheduleCardState } from "../hooks";

interface SchedulingDateControls {
  heading?: React.ReactNode;
  showPlaceHolders: boolean;
}

export function SchedulingDateControls({
  heading,
  showPlaceHolders,
}: SchedulingDateControls) {
  const { stateMatches, schedulingState } = useScheduleCardState();
  const { handleEndDateChange, handleStartDateChange } =
    useScheduleCardChangeHandlers();

  return (
    <>
      {heading}
      <SchedulingDateInputs
        showPlaceholders={showPlaceHolders}
        startDate={schedulingState.startDate}
        startDateDisabled={stateMatches("dates.disabled")}
        onStartDateChange={handleStartDateChange}
        endDate={schedulingState.endDate}
        endDateDisabled={stateMatches("dates.disabled")}
        onEndDateChange={handleEndDateChange}
      />
    </>
  );
}
