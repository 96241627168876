import React from "react";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { DismissMandatoryCardCTA } from "./DismissMandatoryCardCTA";
import styles from "./MandatoryCardFeature.module.css";

export interface MandatoryCardFeatureProps {
  mandatoryCardEnabled?: boolean;
  onSwitch: (isCardMandatory: boolean) => void;
  showDismissButton?: boolean;
}

export const MandatoryCardFeature: React.FC<MandatoryCardFeatureProps> = ({
  mandatoryCardEnabled,
  onSwitch,
  showDismissButton,
}) => {
  return (
    <>
      {showDismissButton && (
        <div
          data-testid="mandatory-card-feature-test-id"
          className={styles.mandatoryCardFeatureContainer}
        >
          <Card>
            <FeatureSwitch
              title={"Mandatory card on file for quote deposits"}
              description={
                "By making card saving mandatory, you save time on billing and unlock the ability to enable Automatic Payments."
              }
              hasSaveIndicator={false}
              enabled={mandatoryCardEnabled}
              onSwitch={onSwitch}
            ></FeatureSwitch>
          </Card>
          <div className={styles.featureSwitchExtraInfo}>
            <Text>
              You can always disable this in Settings.&nbsp;
              <a
                href="https://help.getjobber.com/hc/en-us/articles/115009590727-Jobber-Payments-Management-and-Settings"
                target="_blank"
                rel="noreferrer"
              >
                Learn more in Help Center
              </a>
            </Text>
          </div>
          <Card>
            <Content>
              <DismissMandatoryCardCTA showDismissButton={showDismissButton} />
            </Content>
          </Card>
        </div>
      )}
    </>
  );
};
