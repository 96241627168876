import { gql } from "@apollo/client";

export const DATA_SHARING_CONSENT_UPSERT = gql`
  mutation JobberPaymentsDataSharingConsentUpsert(
    $input: JobberPaymentsDataSharingConsentActions!
  ) {
    jobberPaymentsDataSharingConsentUpsert(input: { action: $input }) {
      userErrors {
        message
      }
    }
  }
`;
