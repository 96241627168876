import React from "react";
import type { IconNames } from "@jobber/components/Icon";
import { Icon } from "@jobber/components/Icon";
import { Disclosure } from "@jobber/components/Disclosure";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { useIntl } from "react-intl";
import styles from "./OAuthAuthorizationPage.module.css";
import { messages } from "./messages";

export interface OAuthAuthorizationScopesProps {
  scopes: string[];
}

const ReadCompanyInformation = ["read_custom_field_configurations"];
const WriteCompanyInformation = [
  "write_tax_rates",
  "write_custom_field_configurations",
];

const ReadTeamMemberInformation = ["read_users", "read_time_sheets"];
const WriteTeamMemberInformation = ["write_users", "write_time_sheets"];
const TeamMemberInformation = [
  ...ReadTeamMemberInformation,
  ...WriteTeamMemberInformation,
];
const ReadJobberWorkflow = [
  "read_clients",
  "read_requests",
  "read_quotes",
  "read_jobs",
  "read_scheduled_items",
];
const WriteJobberWorkflow = [
  "write_clients",
  "write_requests",
  "write_quotes",
  "write_jobs",
  "write_scheduled_items",
];
const JobberWorkflow = [...ReadJobberWorkflow, ...WriteJobberWorkflow];
const ReadfinancialInformation = [
  "read_invoices",
  "read_jobber_payments",
  "read_expenses",
];
const WritefinancialInformation = [
  "write_invoices",
  "write_jobber_payments",
  "write_expenses",
];
const FinancialInformation = [
  ...ReadfinancialInformation,
  ...WritefinancialInformation,
];

export function OAuthAuthorizationScopes({
  scopes,
}: OAuthAuthorizationScopesProps) {
  return (
    <>
      <ScopesCategory
        iconName={"company"}
        title={"Company information and admin settings"}
      >
        <Text variation="subdued">
          View your company information
          {ReadCompanyInformation.some(item => scopes.includes(item))
            ? `, tax rates, and `
            : ` and tax rates`}
          {
            <ScopesToSentence
              scopes={scopes}
              categoryScopes={ReadCompanyInformation}
            />
          }
        </Text>
        {WriteCompanyInformation.some(item => scopes.includes(item)) && (
          <Text variation="subdued">
            Edit your{" "}
            {
              <ScopesToSentence
                scopes={scopes}
                categoryScopes={WriteCompanyInformation}
              />
            }
          </Text>
        )}
      </ScopesCategory>

      {TeamMemberInformation.some(item => scopes.includes(item)) && (
        <ScopesCategory iconName={"user"} title={"Team member information"}>
          <Text variation="subdued">
            View your team member information, such as{" "}
            {ReadTeamMemberInformation.filter(scope => scopes.includes(scope))
              .length > 1 ? (
              <ScopesToSentence
                scopes={scopes}
                categoryScopes={ReadTeamMemberInformation}
              />
            ) : (
              <>contact information, addresses, and labour rates</>
            )}
          </Text>
          {WriteTeamMemberInformation.some(item => scopes.includes(item)) && (
            <Text variation="subdued">
              Edit your team member information, such as{" "}
              {WriteTeamMemberInformation.filter(scope =>
                scopes.includes(scope),
              ).length > 1 ? (
                <ScopesToSentence
                  scopes={scopes}
                  categoryScopes={WriteTeamMemberInformation}
                />
              ) : (
                <>contact information, addresses, and labour rates</>
              )}
            </Text>
          )}
        </ScopesCategory>
      )}

      {JobberWorkflow.some(item => scopes.includes(item)) && (
        <ScopesCategory iconName={"work"} title={"Jobber workflow information"}>
          <Text variation="subdued">
            View your{" "}
            {
              <ScopesToSentence
                scopes={scopes}
                categoryScopes={ReadJobberWorkflow}
              />
            }
          </Text>
          {WriteJobberWorkflow.some(item => scopes.includes(item)) && (
            <Text variation="subdued">
              Edit your{" "}
              {
                <ScopesToSentence
                  scopes={scopes}
                  categoryScopes={WriteJobberWorkflow}
                />
              }
            </Text>
          )}
        </ScopesCategory>
      )}

      {FinancialInformation.some(item => scopes.includes(item)) && (
        <ScopesCategory iconName={"wallet"} title={"Financial information"}>
          <Text variation="subdued">
            View your{" "}
            {ReadfinancialInformation.filter(scope => scopes.includes(scope))
              .length > 1 ? (
              <ScopesToSentence
                scopes={scopes}
                categoryScopes={ReadfinancialInformation}
              />
            ) : (
              <>invoices and payments</>
            )}
          </Text>
          {WritefinancialInformation.some(item => scopes.includes(item)) && (
            <Text variation="subdued">
              Edit your{" "}
              {WritefinancialInformation.filter(scope => scopes.includes(scope))
                .length > 1 ? (
                <ScopesToSentence
                  scopes={scopes}
                  categoryScopes={WritefinancialInformation}
                />
              ) : (
                <>invoices and payments</>
              )}
            </Text>
          )}
        </ScopesCategory>
      )}
    </>
  );
}

function underscoreToCamel(s: string) {
  const newS = s.replace(/(_\w)/g, m => m[1].toUpperCase());
  return newS;
}

function requireOxfordComma(text: string) {
  return text.includes(",");
}

function ScopesToSentence({
  scopes,
  categoryScopes,
}: {
  scopes: string[];
  categoryScopes: string[];
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      {categoryScopes
        .filter(scope => scopes.includes(scope))
        .reduce((prev, curr, index, array) => {
          curr = formatMessage(
            messages[underscoreToCamel(curr) as keyof typeof messages],
          );
          if (index === 0) {
            // First item, just return it
            return [curr];
          } else if (index === array.length - 1) {
            // Last item, prepend with "and"
            if (requireOxfordComma(prev.join(" "))) {
              return [...prev, `, and ${curr}`];
            } else {
              return [...prev, ` and ${curr}`];
            }
          } else {
            // Middle items, append with a comma
            return [...prev, `, ${curr}`];
          }
        }, [])
        .map((text, index) => (
          <React.Fragment key={index}>{text}</React.Fragment>
        ))}
    </>
  );
}

function ScopesCategory({
  iconName,
  title,
  children,
}: {
  iconName: IconNames;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <div className={styles.scopeCategory}>
        <div className={styles.scopeCategoryIcon}>
          <Icon name={iconName} color={"text"} />
        </div>
        <div className={styles.scopeCategoryContent}>
          <Disclosure title={title} defaultOpen={false}>
            <Content spacing="small">{children}</Content>
          </Disclosure>
        </div>
      </div>
      <Divider direction={"horizontal"} />
    </>
  );
}
