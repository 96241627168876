import { generatePath, useHistory } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { CAMPAIGNS_CONTENT_EDIT_PATH } from "jobber/campaigns/constants";
import { useSendTestCampaign } from "jobber/campaigns/views/CampaignsContentPage/hooks/useSendTestCampaign";
import { useSendTestCampaignForDemo } from "jobber/campaigns/views/CampaignsContentPage/hooks/useSendTestCampaignForDemo";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";

export interface UseSendTestCampaignActionsProps {
  userEmail: string;
  saveCampaign: (skipRedirect?: boolean) => Promise<string>;
  loading: boolean;
  setIsErrorState: (isError: boolean) => void;
  setActionLoading: (isLoading: boolean) => void;
  handleError: () => void;
}
export function useSendTestCampaignActions({
  userEmail,
  saveCampaign,
  loading,
  setIsErrorState,
  setActionLoading,
  handleError,
}: UseSendTestCampaignActionsProps) {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { campaignSendTestEmail } = useSendTestCampaign();
  const { campaignSendTestEmailForDemo } = useSendTestCampaignForDemo();

  // eslint-disable-next-line max-statements
  async function onSendTestEmailForDemo(email: string) {
    if (loading) {
      setIsErrorState(true);
      return;
    }

    let currentCampaignId = "";
    try {
      setActionLoading(true);
      currentCampaignId = await saveCampaign(true);
      if (currentCampaignId) {
        const errors = await campaignSendTestEmailForDemo({
          input: { campaignId: currentCampaignId, email },
        });
        if (errors.userErrors.length === 0 && !errors.mutationErrors) {
          showToast({
            message: formatMessage(messages.sendTestEmailSuccess, {
              userEmail: email,
            }),
            variation: "success",
          });
          const contentPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
            campaignId: currentCampaignId,
          });
          history.replace(contentPath);
        } else {
          handleError();
        }
      }
      setActionLoading(false);
    } catch {
      setActionLoading(false);
      handleError();
    }
  }

  // eslint-disable-next-line max-statements
  async function onSendTestEmail() {
    if (loading) {
      setIsErrorState(true);
      return;
    }

    let currentCampaignId = "";
    try {
      setActionLoading(true);
      currentCampaignId = await saveCampaign(true);
      if (currentCampaignId) {
        const errors = await campaignSendTestEmail({
          input: { campaignId: currentCampaignId },
        });

        if (errors.userErrors.length === 0 && !errors.mutationErrors) {
          showToast({
            message: formatMessage(messages.sendTestEmailSuccess, {
              userEmail: userEmail,
            }),
            variation: "success",
          });
          const contentPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
            campaignId: currentCampaignId,
          });
          history.replace(contentPath);
        } else {
          handleError();
        }
      }
      setActionLoading(false);
    } catch {
      setActionLoading(false);
      handleError();
    }
  }

  return {
    onSendTestEmail,
    onSendTestEmailForDemo,
  };
}
