import { gql } from "@apollo/client";

export const stringWithDefaultFieldsFragment = gql`
  fragment stringWithDefaultFields on StringWithDefault {
    current
    default
  }
`;

export const messageTemplateEmailFragment = gql`
  fragment messageTemplateEmail on MessageTemplateEmail {
    id
    deliveryMethod
    name
    subject {
      ...stringWithDefaultFields
    }
    message {
      ...stringWithDefaultFields
    }
    variables {
      nodes {
        label
        variables {
          nodes {
            label
            name
            sample
          }
        }
      }
    }
  }
  ${stringWithDefaultFieldsFragment}
`;

export const INCENTIVE_SETTINGS_QUERY = gql`
  query ReferralSettings {
    marketingClientReferralsSettings {
      id
      type
      value
      incentivesEnabled
    }
    clientNotification(id: REFERRAL_INCENTIVE_EARNED) {
      id
      templates {
        nodes {
          ... on MessageTemplateEmail {
            ...messageTemplateEmail
          }
        }
      }
    }
  }
  ${messageTemplateEmailFragment}
`;

export const INCENTIVE_SETTINGS_MUTATION = gql`
  mutation ReferralSettingsEdit(
    $input: MarketingClientReferralsSettingsEditInput!
  ) {
    marketingClientReferralsSettingsEdit(input: $input) {
      marketingClientReferralsSettings {
        id
        type
        value
        incentivesEnabled
      }
      userErrors {
        message
        path
      }
    }
  }
`;
