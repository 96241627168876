import React from "react";
import { Text } from "@jobber/components/Text";
import type { MessageDataTextMessageFragment } from "~/utilities/API/graphql";

interface SystemMessageContentProps {
  readonly message: MessageDataTextMessageFragment;
}

export function SystemMessageContent({ message }: SystemMessageContentProps) {
  return (
    <Text size={"small"} variation={"subdued"} align={"center"}>
      {message.content}
    </Text>
  );
}
