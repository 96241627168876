import React, { useState } from "react";
import classnames from "classnames";
import { Button } from "@jobber/components/Button";
import { Grid } from "@jobber/components/Grid";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import {
  APP_DOWNLOAD_GUIDE,
  APP_STORE_BADGE_LINK,
  GOOGLE_PLAY_BADGE_LINK,
  JOBBER_APP_STORE_LINK,
  JOBBER_GOOGLE_PLAY_LINK,
  MOBILE_SCREEN_WIDTH,
  SMALL_SCREEN_WIDTH,
} from "jobber/dashboard/components/SetupGuide/constants";
import { messages as SetupGuideMessages } from "jobber/dashboard/components/SetupGuide/messages";
import { trackInteractedWithExperiment } from "~/utilities/analytics/trackInteractedWithExperiment";
import type {
  SendAppDownloadSmsInviteMutation,
  SendAppDownloadSmsInvitePayload,
} from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./DownloadAppPanel.module.css";
import { SEND_APP_DOWNLOAD_SMS_MUTATION } from "../../SetupGuide.graphql";

export function DownloadAppPanel() {
  const { content, img } = APP_DOWNLOAD_GUIDE;

  return (
    <div className={styles.panelContainer}>
      <Grid alignItems="stretch" gap={false}>
        <Grid.Cell size={{ xs: 12, md: 6 }}>
          <PanelImage {...img} />
        </Grid.Cell>
        <Grid.Cell size={{ xs: 12, md: 6 }}>
          <PanelContent {...content} />
        </Grid.Cell>
      </Grid>
    </div>
  );
}

interface PanelImageProps {
  src: string;
  altText: string;
}
function PanelImage({ altText, src }: PanelImageProps): JSX.Element {
  return (
    <div className={styles.leftContainer}>
      <Content spacing={"base"}>
        <MainImage altText={altText} src={src} />
        <AdditionalImage />
      </Content>
    </div>
  );
}

function MainImage({ altText, src }: PanelImageProps): JSX.Element {
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;

  if (isMobileScreen) {
    return <></>;
  }

  return (
    <div className={styles.imageContainer}>
      <img alt={altText} src={src} />
    </div>
  );
}

// TODO: JOB-72146 Improve this to be more flexible for the SetupGuidePanel and not specific to Google Play and App Store display
function AdditionalImage(): JSX.Element {
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;
  const additionalImageContainerClassName = classnames({
    [styles.additionalImageContainer]: true,
    [styles.appBadge]: true,
    [styles.displayBadgeVertical]: isMobileScreen,
  });
  const appStoreBadgeClassName = classnames({
    [styles.appStoreBadge]: !isMobileScreen,
    [styles.appStoreBadgeVertical]: isMobileScreen,
  });
  const googlePlayBadgeClassName = classnames({
    [styles.googlePlayBadge]: !isMobileScreen,
    [styles.googlePlayBadgeVertical]: isMobileScreen,
  });

  return (
    <div className={additionalImageContainerClassName}>
      <a
        data-testid={SetupGuideMessages.appDownloadGuideAppStore.defaultMessage}
        href={JOBBER_APP_STORE_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Download on the App Store"
          className={appStoreBadgeClassName}
          src={APP_STORE_BADGE_LINK}
        />
      </a>
      <a
        data-testid={
          SetupGuideMessages.appDownloadGuideGooglePlay.defaultMessage
        }
        href={JOBBER_GOOGLE_PLAY_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Get it on Google Play"
          className={googlePlayBadgeClassName}
          src={GOOGLE_PLAY_BADGE_LINK}
        />
      </a>
    </div>
  );
}

interface PanelContentProps {
  heading: string;
  subheading?: string;
  buttonLabel: string;
}

function PanelContent({
  heading,
  subheading,
  buttonLabel,
}: PanelContentProps): JSX.Element {
  const isSmallScreen = window.innerWidth <= SMALL_SCREEN_WIDTH;
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;
  const rightContainerClassName = classnames({
    [styles.rightContainer]: !isMobileScreen,
    [styles.rightContainerSmallScreen]: isMobileScreen,
  });

  const [input, setInput] = useState<string>("");

  const [sendSMS, { loading }] = useMutation<SendAppDownloadSmsInviteMutation>(
    SEND_APP_DOWNLOAD_SMS_MUTATION,
    { variables: { input } },
  );

  const handleInputChange = (value: string) => {
    setInput(value);
  };

  const handleSendSMSSuccess = (resp: SendAppDownloadSmsInvitePayload) => {
    if (resp.success) {
      showToast({
        message: messages.smsSuccessToast.defaultMessage,
      });
    } else {
      const message =
        resp.userErrors[0].message || messages.smsFailToast.defaultMessage;
      showToast({
        message: message,
        variation: "error",
      });
    }
  };

  const handleClick = () => {
    trackInteractedWithExperiment({
      experiment: "Setup Guide",
      interaction: "Sent download link to self",
      source: "content button",
    });

    sendSMS()
      .then(resp => {
        const payload = resp.data?.sendAppDownloadSmsInvite;
        if (!payload) {
          showToast({
            message: messages.smsFailToast.defaultMessage,
            variation: "error",
          });
          return;
        }
        handleSendSMSSuccess(payload);
      })
      .catch(() => {
        showToast({
          message: messages.smsFailToast.defaultMessage,
          variation: "error",
        });
      });
  };

  return (
    <div className={rightContainerClassName}>
      <Content spacing={"base"}>
        <Heading level={3}>{heading}</Heading>
        <Text>
          <span className={styles.description}>{subheading}</span>
        </Text>

        <InputText
          placeholder={messages.phoneNumberPlaceholder.defaultMessage}
          onChange={handleInputChange}
        />
        <Button
          label={buttonLabel}
          onClick={() => handleClick()}
          fullWidth={isSmallScreen}
          disabled={loading}
        />
      </Content>
    </div>
  );
}
