import * as React from "react";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";

type SelectedKeys = keyof Pick<
  BillingAddress,
  "street1" | "street2" | "city" | "province" | "pc"
>;

type AddressKeyMap<ValueType> = { [key in SelectedKeys]: ValueType };

const defaultKeysToLabel: AddressKeyMap<string> = {
  street1: "Address 1",
  street2: "Address 2",
  city: "City",
  province: "Province",
  pc: "Postal code",
};

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Administrative_levels_in_addresses
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html
const keysToAutoComplete: AddressKeyMap<string> = {
  street1: "address-line1",
  street2: "address-line2",
  city: "address-level2",
  province: "address-level1",
  pc: "postal-code",
};

interface NodeAndValues {
  node: React.ReactNode;
  value: string;
}

export type KeysToLabelOverride = Partial<AddressKeyMap<string>>;

interface UseAddressComponentsParams {
  address: BillingAddress;
  keysToLabelOverride?: KeysToLabelOverride;
  className?: string;
  disabled?: boolean;

  onChange?(key: SelectedKeys, value: string): void;
}

// Use named exports instead of `export default`. https://jobber.atlassian.net/wiki/x/cQCKIQ
// If you fix this export, remove it from from: `.eslint/defaultExportExceptionList.js`
export default function useAddressComponents({
  address,
  onChange,
  className,
  keysToLabelOverride,
  disabled,
}: UseAddressComponentsParams) {
  const keysToLabel = {
    ...defaultKeysToLabel,
    ...keysToLabelOverride,
  };

  // `{}` fails to conform to AddressKeyMap<NodeAndValues>, but it's being filled in below
  const nodeAndValues = {} as AddressKeyMap<NodeAndValues>;

  /*
   hooks must be called in stable order, i.e. if we call
   ```js
   const [city, setCity] = React.useState(initialCity);
   const [province, setProvince] = React.useState(initialProvince);
   ```
   in one render, we cannot call them out of order in the next, e.g
   WRONG:
   ```js
   const [province, setProvince] = React.useState(initialProvince);
   const [city, setCity] = React.useState(initialCity);
   ```

   Tho `Object.keys(…)` is stable based on insertion order,
   this function does not require the keys in `initialAddress` to be stable,
   We'll run it once and cache it forever with `useMemo`
   The `[]` dependency list indicates "never update this memo"
  */
  const keysInStableOrder = React.useMemo(() => Object.keys(keysToLabel), []);

  keysInStableOrder.forEach((key: SelectedKeys) => {
    const initialValue = address[key] || "";
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const changeHandler = React.useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = ev.target.value;
        setValue(newValue);
        if (onChange) {
          onChange(key, newValue);
        }
      },
      [setValue, onChange],
    );
    const label = keysToLabel[key];

    const node = label ? (
      <placeholder-field label={label} class={className}>
        {/* eslint-disable-next-line jsx-a11y/autocomplete-valid -- Grandfathered error: Please fix if touching this code. */}
        <input
          onChange={changeHandler}
          value={value}
          name={key}
          autoComplete={`billing ${keysToAutoComplete[key]}`}
          disabled={disabled}
          data-testid={key}
        />
      </placeholder-field>
    ) : undefined;
    nodeAndValues[key] = { node, value };
  });

  return nodeAndValues;
}
