import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pastClientsDescription: {
    id: "campaigns.useClientSegmentSummary.pastClientsDescription",
    defaultMessage:
      "Clients with last job completed more than {interval} {unit}(s) ago, with no upcoming jobs scheduled{additionalCriteriaCount, plural, =0 {} other { + {additionalCriteriaCount} additional criteria}}",
    description: "Description for the past clients segment card",
  },
  allClientsDescription: {
    id: "campaigns.useClientSegmentSummary.allClientsDescription",
    defaultMessage:
      "All clients that you haven't archived{additionalCriteriaCount, plural, =0 {} other { + {additionalCriteriaCount} additional criteria}}",
    description: "Description for the all clients segment card",
  },
  upcomingClientsDescription: {
    id: "campaigns.useClientSegmentSummary.upcomingClientsDescription",
    defaultMessage:
      "Clients with at least one visit within the next {interval} {unit}(s) {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the upcoming visit clients segment card",
  },
});
