import type { IntlShape } from "react-intl";
import { type ClientSegment, Segment } from "~/utilities/API/graphql";
import { messages } from "./messages";

export function getPastClientSummary(
  pastClient: ClientSegment,
  formatMessage: IntlShape["formatMessage"],
): string {
  const [_interval] = pastClient.criteria.nodes.filter(
    e => e.name === "interval",
  );
  const [_unit] = pastClient.criteria.nodes.filter(e => e.name === "unit");

  const unit = _unit.value?.toLocaleLowerCase().replace(/s$/, "") || "";
  const interval = _interval.value || 0;

  const additionalCriteriaCount =
    pastClient.additionalCriteria?.totalCount || 0;

  return formatMessage(messages.pastClientsDescription, {
    unit,
    interval,
    additionalCriteriaCount,
  });
}

export function getAllClientSummary(
  allClient: ClientSegment,
  formatMessage: IntlShape["formatMessage"],
): string {
  const additionalCriteriaCount = allClient.additionalCriteria?.totalCount || 0;

  return formatMessage(messages.allClientsDescription, {
    additionalCriteriaCount,
  });
}

export function getUpcomingClientSummary(
  upcomingClient: ClientSegment,
  formatMessage: IntlShape["formatMessage"],
): string {
  const [_interval] = upcomingClient.criteria.nodes.filter(
    e => e.name === "interval",
  );
  const [_unit] = upcomingClient.criteria.nodes.filter(e => e.name === "unit");

  const unit = _unit.value?.toLocaleLowerCase().replace(/s$/, "") || "";
  const interval = _interval.value || 0;

  const additionalCriteriaCount =
    upcomingClient.additionalCriteria?.totalCount || 0;

  return formatMessage(messages.upcomingClientsDescription, {
    unit,
    interval,
    additionalCriteriaCount,
  });
}

export const getClientSegmentSummary = (
  clientSegment: ClientSegment,
  formatMessage: IntlShape["formatMessage"],
) => {
  if (clientSegment.type == Segment.PAST_CLIENTS) {
    return getPastClientSummary(clientSegment, formatMessage);
  }
  if (clientSegment.type == Segment.ALL_CLIENTS) {
    return getAllClientSummary(clientSegment, formatMessage);
  }
  if (clientSegment.type == Segment.UPCOMING_CLIENTS) {
    return getUpcomingClientSummary(clientSegment, formatMessage);
  }
};
