import React, { useMemo } from "react";
import { Option, Select } from "@jobber/components/Select";
import { useScheduleCardChangeHandlers, useScheduleCardState } from "../hooks";
import { useAssignTeam } from "../../AssignTeam";

const ALL_USERS_SELECTED_OPTION = {
  id: "ALL_USERS",
  label: "Any team member",
  key: "ALL_USERS",
};

export const TeamMemberSelection = () => {
  const { schedulingState } = useScheduleCardState();
  const { handleSchedulingInputTeamMemberChange } =
    useScheduleCardChangeHandlers();
  const { assignableUsers } = useAssignTeam();

  const onSelectTeamMember = (teamMemberIds: { id: string }[]) => {
    const selectedTeamMemberIds = teamMemberIds.map(({ id }) => id.toString());

    return handleSchedulingInputTeamMemberChange(
      selectedTeamMemberIds.filter(id => id !== ALL_USERS_SELECTED_OPTION.id),
    );
  };

  const selectedTeamMember = useMemo(() => {
    const selectedTeamMemberIds =
      schedulingState.schedulingInputTeamMemberIds.map(selectedId => {
        const user = assignableUsers.find(({ id }) => id === selectedId);
        return { id: selectedId, label: user?.name.full ?? "" };
      });

    return selectedTeamMemberIds.length
      ? selectedTeamMemberIds
      : [ALL_USERS_SELECTED_OPTION];
  }, [schedulingState.schedulingInputTeamMemberIds, assignableUsers]);

  return (
    <Select
      value={selectedTeamMember[0].id}
      onChange={val => val && onSelectTeamMember([{ id: val.toString() }])}
      placeholder="Suggested assignee"
    >
      <Option value={ALL_USERS_SELECTED_OPTION.id}>
        {ALL_USERS_SELECTED_OPTION.label}
      </Option>
      {assignableUsers.map(user => (
        <Option value={user.id} key={user.id}>
          {user.name.full}
        </Option>
      ))}
    </Select>
  );
};
