import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CAMPAIGN_STATUS_UPDATE = gql`
  mutation campaignStatusUpdate(
    $campaignId: EncodedId!
    $input: CampaignStatusUpdateInput!
  ) {
    campaignStatusUpdate(campaignId: $campaignId, input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
