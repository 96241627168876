import lodash from "lodash";
import type { QuoteLineItem } from "~/jobber/quotes/types";
import type { WorkItem } from "jobber/workItems/types";

/**
 * Builds a new work item from
 * @param lineItem the base information for the new work item
 * @returns a new unsaved (no id) work item based on the fields set in the provided lineItem
 */
export function quoteLineItemToWorkItem(lineItem: QuoteLineItem): WorkItem {
  return {
    id: undefined,
    name: lineItem.name,
    description: lineItem.description || "",
    defaultUnitCost: lineItem.unit_cost,
    category: mapCategory(lineItem.category),
    internalUnitCost: lineItem.internal_unit_cost || 0,
    markup: lineItem.markup || 0,
    taxable: !!lineItem.taxable,
    fileAttachment: lineItem.file_attachments[0]
      ? {
          fileName: lineItem.file_attachments[0].file_name,
          fileSize: lineItem.file_attachments[0].file_size,
          thumbnailUrl: lineItem.file_attachments[0].thumbnail,
          contentType: lineItem.file_attachments[0].content_type,
          s3Key: lineItem.file_attachments[0].s3_key,
        }
      : undefined,
  };
}

// Map to WorkItem category enum to make typescript happy
export function mapCategory(category: string): WorkItem["category"] {
  switch (category.toLowerCase()) {
    case "product":
      return "PRODUCT";
    case "service":
    default:
      return "SERVICE";
  }
}

export function categoryToStartCase(category: WorkItem["category"]) {
  return lodash.startCase(category.toLowerCase());
}
