import { useEffect, useMemo, useReducer, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import type {
  AutomationRule,
  AutomationRuleEditMutation,
  AutomationRuleEditMutationVariables,
  CampaignCreateTemplateAttributes,
  CreateAutomatedCampaignMutation,
  CreateAutomatedCampaignMutationVariables,
  Template,
} from "~/utilities/API/graphql";
import { AUTOMATION_RULE_EDIT_MUTATION } from "~/jobber/automations/graphql/AutomationRuleEdit.graphql";
import {
  AUTOMATION_RULE_NAME,
  CAMPAIGNS_CONTENT_EDIT_PATH,
} from "jobber/campaigns/constants";
import { useCommsCampaignDefaultTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignDefaultTemplateQuery/useCommsCampaignDefaultTemplateQuery";
import { updatedCampaignAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import {
  parseConditions,
  parseIncomingConditions,
} from "jobber/campaigns/utils/automationRuleUtils";
import { messages } from "./messages";
import { CREATE_AUTOMATED_CAMPAIGN } from "./createAutomatedCampaign.graphql";
import { reducer as ruleBuilderReducer } from "../../components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";
import type { AllConditionTypes } from "../../components/SetAutomationRules/ruleBuilder/types";
import { CreateRuleConditionTypes } from "../../components/SetAutomationRules/ruleBuilder/types";

// eslint-disable-next-line max-statements
export function useAutomationControls(
  onNextNav: () => void,
  savedRule?: AutomationRule,
  templateType?: Template,
  isAutomated?: boolean,
  defaultAutomationRule?: AutomationRule,
) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  // (in months)
  const [trigger, setTrigger] = useState(
    defaultAutomationRule?.trigger.arguments.time,
  );

  const [conditions, dispatch] = useReducer(ruleBuilderReducer, [
    ...(defaultAutomationRule?.condition.conditions ?? []),
  ]);

  const [errors, setErrors] = useState<string[] | null>(null);

  useEffect(() => {
    if (savedRule) {
      setTrigger(savedRule.trigger.arguments.time);
      dispatch({
        type: "loadConditions",
        payload: parseIncomingConditions(savedRule, defaultAutomationRule),
      });
    }
  }, [defaultAutomationRule, savedRule]);

  // query
  const { data: templateData } = useCommsCampaignDefaultTemplateQuery({
    skip: !!savedRule,
    defaultTemplateType: templateType as Template,
  });

  const defaultTemplate = useMemo(
    () => ({
      body: templateData?.commsCampaignDefaultTemplate?.body || "",
      subject: templateData?.commsCampaignDefaultTemplate?.subject || "",
      header: templateData?.commsCampaignDefaultTemplate?.header || "",
      type: templateType,
      ctaVisible:
        templateData?.commsCampaignDefaultTemplate?.ctaVisible || false,
      showLogo: templateData?.commsCampaignDefaultTemplate?.showLogo || true,
    }),
    [templateData, templateType],
  ) as CampaignCreateTemplateAttributes;

  // mutations
  const [
    createAutomatedCampaignMutation,
    { loading: loadingCreateAutomation },
  ] = useMutation<
    CreateAutomatedCampaignMutation,
    CreateAutomatedCampaignMutationVariables
  >(CREATE_AUTOMATED_CAMPAIGN);

  const [editRuleMutation, { loading: loadingEditRule }] = useMutation<
    AutomationRuleEditMutation,
    AutomationRuleEditMutationVariables
  >(AUTOMATION_RULE_EDIT_MUTATION);

  const validateInputs = () => {
    const inputErrors: string[] = [];
    conditions.forEach(condition => {
      if (condition === undefined || !condition.arguments.type) return;

      switch (condition.arguments.type) {
        case CreateRuleConditionTypes.ClientTags:
          if (condition.arguments.fields.tag === "") {
            inputErrors.push(formatMessage(messages.tagErrorMessage));
          }
          break;
        case CreateRuleConditionTypes.LineItems:
          if (condition.arguments.fields.item === "") {
            inputErrors.push(formatMessage(messages.lineItemErrorMessage));
          }
          break;
      }
    });
    if (inputErrors.length > 0) {
      setErrors(inputErrors);
      return false;
    }
    return true;
  };

  const onNext = async () => {
    setErrors(null);
    if (!validateInputs()) return;
    if (savedRule) {
      await editRule();
    } else {
      await createCampaign();
    }
  };

  const createCampaign = async () => {
    await createAutomatedCampaignMutation({
      variables: {
        input: {
          rule: {
            trigger: {
              ...defaultAutomationRule?.trigger,
              arguments: {
                ...defaultAutomationRule?.trigger.arguments,
                time: trigger,
              },
            },
            condition: {
              ...defaultAutomationRule?.condition,
              conditions: parseConditions(conditions as AllConditionTypes[]),
            },
            action: defaultAutomationRule?.action,
            name: defaultAutomationRule?.name,
          },
          template: defaultTemplate,
        },
      },
      onCompleted: data => {
        const userErrors = data.createAutomatedCampaign.userErrors ?? [];
        if (userErrors.length > 0) {
          // there was a user error
          setErrors([userErrors[0].message]);
        } else {
          history.push({
            pathname: generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
              campaignId: data.createAutomatedCampaign.campaign?.id || "",
            }),
          });
        }
        updatedCampaignAmplitudeEvent({
          interaction: "Conditions saved",
          /* eslint-disable @typescript-eslint/naming-convention */
          is_automated: isAutomated,
          template_type: templateType,
          /* eslint-enable @typescript-eslint/naming-convention */
        });
      },
      onError: () => {
        // there was a server error
        setErrors([formatMessage(messages.createErrorMessage)]);
      },
    });
  };

  const editRule = async () => {
    await editRuleMutation({
      variables: {
        ruleId: savedRule?.id ?? "", // this should never be undefined
        trigger: {
          task: defaultAutomationRule?.trigger.task,
          arguments: {
            ...defaultAutomationRule?.trigger.arguments,
            time: trigger,
            /* eslint-disable @typescript-eslint/naming-convention */
            model_type: defaultAutomationRule?.trigger.arguments.model_type,
            model_id: savedRule?.trigger.arguments.model_id,
            /* eslint-enable @typescript-eslint/naming-convention */
          },
        },
        condition: {
          task: "condition",
          conditions: parseConditions(conditions as AllConditionTypes[]),
        },
        action: defaultAutomationRule?.action,
        name:
          AUTOMATION_RULE_NAME.find(
            automation => automation.id === templateType,
          )?.name || "",
      },
      onCompleted: data => {
        const userErrors = data?.automationRuleEdit.userErrors ?? [];
        if (userErrors.length > 0) {
          // there was a user error
          setErrors([userErrors[0].message]);
        } else {
          onNextNav();
        }
        updatedCampaignAmplitudeEvent({
          interaction: "Conditions saved",
          /* eslint-disable @typescript-eslint/naming-convention */
          is_automated: isAutomated,
          template_type: templateType,
          /* eslint-enable @typescript-eslint/naming-convention */
        });
      },
      onError: () => {
        // there was a server error
        setErrors([formatMessage(messages.editErrorMessage)]);
      },
    });
  };

  return {
    conditions: conditions as AllConditionTypes[],
    dispatch,
    trigger,
    setTrigger: setTrigger as (val: number) => void,
    onNext,
    loading: loadingCreateAutomation || loadingEditRule,
    errors,
    clearError: () => setErrors(null),
  };
}
