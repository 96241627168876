/* eslint-disable no-restricted-imports */
import React, { type Dispatch } from "react";
import { ComboSignaturePad } from "components/SignaturePad/ComboSignaturePad";
import type { PaymentAction } from "../paymentReducer";

export function SignaturePadContainer(props: {
  required?: boolean;
  paymentDispatcher: Dispatch<PaymentAction>;
}) {
  const { paymentDispatcher, required } = props;
  if (!required) return <></>;

  return (
    <>
      <h5>Approval signature</h5>
      <div className="u-marginBottomSmaller">
        <ComboSignaturePad
          onSignatureDataUrlChange={onSignatureDataUrlChange}
        />
      </div>
      <p className="paragraph u-textSmaller u-lineHeightBase">
        <em>
          By writing your signature, you accept this quote, associated costs,
          and any terms and conditions that may apply.
        </em>
      </p>
    </>
  );

  function onSignatureDataUrlChange(signature: string | undefined) {
    paymentDispatcher({ type: "updatingSignature", signature });
  }
}
