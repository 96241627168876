import React, { useMemo } from "react";
import { InputDate } from "@jobber/components/InputDate";
import { InputGroup } from "@jobber/components/InputGroup";
import { useBool } from "@jobber/hooks";
import { Button } from "@jobber/components/Button";
import classNames from "classnames";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { SchedulingAssistantModal } from "jobber/workOrders/components/SchedulingAssistant/SchedulingAssistantModal";
import styles from "./styles.module.css";

interface DateInputProps {
  startDate?: Date;
  startDateDisabled: boolean;
  onStartDateChange: (updatedStartDate: Date | undefined) => void;
  endDate?: Date;
  endDateDisabled: boolean;
  onEndDateChange: (updatedEndDate: Date | undefined) => void;
  showPlaceholders: boolean;
}

export function SchedulingDateInputs({
  startDateDisabled,
  startDate,
  onStartDateChange,
  endDateDisabled,
  onEndDateChange,
  endDate,
  showPlaceholders,
}: DateInputProps) {
  const endDatePlaceholder = useMemo(() => {
    if (showPlaceholders) {
      return "End date";
    }

    return endDate ? undefined : "Optional";
  }, [showPlaceholders, endDate]);

  const [
    showSchedulingAssistant,
    onShowSchedulingAssistant,
    onHideSchedulingAssistant,
  ] = useBool(false);

  const isSchedulingAssistantEnabled = useFeatureFlag(
    SplitNames.SchedulingRecommendations,
  );

  return (
    <div
      data-testid="scheduling-date-inputs"
      className={classNames(
        styles.datesContainer,
        isSchedulingAssistantEnabled ? styles.schedulingAssistant : "",
      )}
    >
      <InputGroup flowDirection="horizontal">
        {isSchedulingAssistantEnabled ? (
          <Button
            label="Find a time"
            type="primary"
            onClick={onShowSchedulingAssistant}
          />
        ) : (
          <></>
        )}
        <div data-testid="startDate">
          <InputDate
            placeholder={showPlaceholders ? "Start date" : undefined}
            onChange={onStartDateChange}
            value={startDateDisabled ? undefined : startDate}
            disabled={startDateDisabled}
          />
        </div>
        <div data-testid="endDate">
          <InputDate
            onChange={onEndDateChange}
            placeholder={endDatePlaceholder}
            value={endDateDisabled ? undefined : endDate}
            disabled={endDateDisabled}
          />
        </div>
      </InputGroup>
      {isSchedulingAssistantEnabled && (
        <SchedulingAssistantModal
          open={showSchedulingAssistant}
          onClose={onHideSchedulingAssistant}
        />
      )}
    </div>
  );
}
