import { defineMessages } from "react-intl";

const welcomeCardIdPrefix = "homePage.welcomeCard.";
const workflowCardIdPrefix = "homePage.workflowCard.";

export const messages = defineMessages({
  welcomeToJobberHeading: {
    id: `${welcomeCardIdPrefix}welcomeToJobberHeading`,
    defaultMessage: "Welcome to Jobber",
    description:
      "Heading for the welcome to jobber section of the welcome card",
  },
  dismissButtonAriaLabel: {
    id: `${welcomeCardIdPrefix}dismissButtonAriaLabel`,
    defaultMessage: "Dismiss",
    description: "Aria label for the dismiss button for the welcome card",
  },
  quotesCTAAriaLabel: {
    id: `${workflowCardIdPrefix}quotesCTAAriaLabel`,
    defaultMessage: "Create a new quote",
    description: "Aria label for the quotes CTA for the workflow card",
  },
  quotesEmptyStateMessage: {
    id: `${workflowCardIdPrefix}quotesEmptyStateMessage`,
    defaultMessage:
      "Send your clients professional quotes they can approve online",
    description:
      "Message for the quotes empty state message for the workflow card",
  },
  quotesEmptyStateButtonText: {
    id: `${workflowCardIdPrefix}quotesEmptyStateButtonText`,
    defaultMessage: "Create a Quote",
    description:
      "Button text for the quotes empty state button for the workflow card",
  },
  quotesTrendlineImgAltText: {
    id: `${workflowCardIdPrefix}quotesTrendlineImgAltText`,
    defaultMessage: "Demonstrative trendline for quotes workflow card",
    description:
      "Alt text for the quotes trendline image for the workflow card",
  },
  invoicesCTAAriaLabel: {
    id: `${workflowCardIdPrefix}invoicesCTAAriaLabel`,
    defaultMessage: "Create a new invoice",
    description: "Aria label for the invoices CTA button for the workflow card",
  },
  invoicesEmptyStateMessage: {
    id: `${workflowCardIdPrefix}invoicesEmptyStateMessage`,
    defaultMessage:
      "Send your clients professional invoices they can pay online",
    description:
      "Message for the invoices empty state message for the workflow card",
  },
  invoicesEmptyStateButtonText: {
    id: `${workflowCardIdPrefix}invoicesEmptyStateButtonText`,
    defaultMessage: "Create an Invoice",
    description:
      "Button text for the invoices empty state button for the workflow card",
  },
  invoicesTrendlineImgAltText: {
    id: `${workflowCardIdPrefix}invoicesTrendlineImgAltText`,
    defaultMessage: "Demonstrative trendline for invoices workflow card",
    description:
      "Alt text for the invoices trendline image for the workflow card",
  },
  jobsCTAAriaLabel: {
    id: `${workflowCardIdPrefix}jobsCTAAriaLabel`,
    defaultMessage: "Create a new job",
    description: "Aria label for the jobs CTA button for the workflow card",
  },
  jobsEmptyStateMessage: {
    id: `${workflowCardIdPrefix}jobsEmptyStateMessage`,
    defaultMessage:
      "Schedule jobs with all the details you need to get them done",
    description:
      "Message for the jobs empty state message for the workflow card",
  },
  jobsEmptyStateButtonText: {
    id: `${workflowCardIdPrefix}jobsEmptyStateButtonText`,
    defaultMessage: "Schedule a Job",
    description:
      "Button text for the jobs empty state button for the workflow card",
  },
  jobsTrendlineImgAltText: {
    id: `${workflowCardIdPrefix}jobsTrendlineImgAltText`,
    defaultMessage: "Demonstrative trendline for jobs workflow card",
    description: "Alt text for the jobs trendline image for the workflow card",
  },
  requestsCTAAriaLabel: {
    id: `${workflowCardIdPrefix}requestsCTAAriaLabel`,
    defaultMessage: "Create a new request",
    description: "Aria label for the requests CTA button for the workflow card",
  },
  requestsEmptyStateMessage: {
    id: `${workflowCardIdPrefix}requestsEmptyStateMessage`,
    defaultMessage: "Record new service requests from your clients",
    description:
      "Message for the requests empty state message for the workflow card",
  },
  requestsEmptyStateButtonText: {
    id: `${workflowCardIdPrefix}requestsEmptyStateButtonText`,
    defaultMessage: "Create a Request",
    description:
      "Button text for the requests empty state button for the workflow card",
  },
  requestsTrendlineImgAltText: {
    id: `${workflowCardIdPrefix}requestsTrendlineImgAltText`,
    defaultMessage: "Demonstrative trendline for requests workflow card",
    description:
      "Alt text for the requests trendline image for the workflow card",
  },
  viewButtonAriaLabel: {
    id: `${workflowCardIdPrefix}viewButtonAriaLabel`,
    defaultMessage: "View {statusLabel} {title}",
    description: "Aria label for the view button for the workflow card",
  },
  welcomeVideoIndustryText: {
    id: `${welcomeCardIdPrefix}welcomeVideoIndustryText`,
    defaultMessage:
      "See how Jobber helps {industryName} businesses like yours run smoothly.",
    description:
      "Text for the industry-specific welcome video for the welcome card",
  },
  welcomeVideoDefaultText: {
    id: `${welcomeCardIdPrefix}welcomeVideoDefaultText`,
    defaultMessage: "Graham's here to show you how Jobber works.",
    description: "Text for the default welcome video for the welcome card",
  },
});
