import { gql } from "@apollo/client";

export const AVAILABILITY_SUBSCRIPTION = gql`
  subscription RecommendNonRepeatingVisitScheduleSubscription(
    $recommendationRequestId: ID!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date
    $location: LocationInput
    $duration: Seconds = 1800
  ) {
    recommendNonRepeatingVisitSchedule(
      input: {
        recommendationRequestId: $recommendationRequestId
        startDate: $startDate
        endDate: $endDate
        location: $location
        duration: $duration
      }
    ) {
      userErrors {
        message
      }
      schedulingRecommendations {
        recommendedStartAt
        recommendedEndAt
        driveTimeTo
        driveTimeFrom
        recommendedAssignee {
          id
        }
      }
      recommendationRequestId
    }
  }
`;
