import bind from "bind-decorator";
import React, { Component, type ReactNode } from "react";

export interface CountryPickerProps {
  ariaLabel?: string;
  selectClassName?: string;
  name?: string;
  priorityCountries: string[];
  allCountries: string[];
  value?: string;
  onChange?(country?: string): void;
}

export class CountryPicker extends Component<CountryPickerProps> {
  constructor(props: CountryPickerProps) {
    super(props);
  }

  public render(): ReactNode {
    const ariaLabel = this.props.ariaLabel || "Property";
    //Note: `country` as a css attribute may be deprecated within Jobber.
    const selectClassNames = ["country"];
    if (this.props.selectClassName && this.props.selectClassName !== "") {
      selectClassNames.push(this.props.selectClassName);
    }

    return (
      <div className="select large u-marginBottomNone fieldGroup-field">
        <select
          aria-label={ariaLabel}
          className={selectClassNames.join(" ")}
          name={this.props.name}
          value={this.props.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        >
          {this.renderPriorityCountryOptions()}
          <option value="nil" disabled={true} aria-selected="false">
            -------------
          </option>
          {this.renderAllCountryOptions()}
        </select>
      </div>
    );
  }

  @bind
  private handleBlur(event: React.FocusEvent<HTMLSelectElement>) {
    const currentValueOfSelect = event.target.value;
    const currentValueInState = this.props.value;
    if (currentValueInState !== currentValueOfSelect) {
      this.fireOnChange(currentValueOfSelect);
    }
  }

  @bind
  private handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.fireOnChange(event.target.value);
  }

  @bind
  private renderPriorityCountryOptions(): ReactNode[] {
    return this.renderUniqueCountryOptions("p", this.props.priorityCountries);
  }

  @bind
  private renderAllCountryOptions(): ReactNode[] {
    return this.renderUniqueCountryOptions("all", this.props.allCountries);
  }

  @bind
  private renderUniqueCountryOptions(
    prependKey: string, // Prepend for unique React item rendering key
    countries: string[], // The list of countries to render
  ): ReactNode[] {
    const options: ReactNode[] = [];

    countries.forEach((country: string) => {
      const isSelected = country === this.props.value;
      options.push(
        <option
          key={this.uniqueOptionKeyFor(prependKey, country)}
          value={country}
          aria-selected={isSelected}
        >
          {country}
        </option>,
      );
    });

    return options;
  }

  @bind
  private uniqueOptionKeyFor(prependKey: string, country: string) {
    const safeCountry = country.toLocaleLowerCase().replace(" ", "_");

    return `${prependKey}_${safeCountry}`;
  }

  @bind
  private fireOnChange(newValue: string) {
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }
}
