export enum ExpiryStatus {
  DEFAULT = "default",
  EXPIRED = "expired",
  EXPIRING_SOON = "expiring_soon",
}

export enum StoredPaymentMethodType {
  BANK_ACCOUNT = "bank_account",
  CREDIT_CARD = "credit_card",
}
