/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { Menu } from "@jobber/components/Menu";
import { Button } from "@jobber/components/Button";
import { NoMatch, join } from "jobber/settings/users/utilities/routing";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import { SchedulerPage } from "./SchedulerPage";
import { InvoiceSchedulerPage } from "./InvoiceSchedulerPage";
import { IndexPage } from "./IndexPage";
import { ScheduleRecommendationsPage } from "./ScheduleRecommendationsPage";
import { DayViewPage } from "./DayViewPage";
import { ScheduleAssistantModalPage } from "./ScheduleAssistantModalPage";

export interface AppRouterProps {
  readonly routing: {
    base: string;
  };
}

const WorkOrdersRouteInternal = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <div style={{ boxSizing: "border-box", padding: 24 }}>
        <Menu
          activator={<Button label="Choose sandbox" />}
          items={[
            {
              header: "Choose Page...",
              actions: [
                {
                  label: (<Link to="">Jobs Index</Link>) as any,
                  icon: "list",
                },
                {
                  label: (<Link to="scheduler">Visit Scheduler</Link>) as any,
                  icon: "schedule",
                },
                {
                  label: (
                    <Link to="invoice_scheduler">Invoice Scheduler</Link>
                  ) as any,
                  icon: "invoice",
                },
                {
                  label: (
                    <Link to="schedule_recommendations">
                      Recommend Non-Repeating Visit Subscription
                    </Link>
                  ) as any,
                  icon: "loadingCheck",
                },
                {
                  label: (
                    <Link to="schedule_availability">
                      Schedule Availability Calendar
                    </Link> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ) as any,
                  icon: "calendar",
                },
                {
                  label: (
                    <Link to="schedule_assistant_modal">
                      Schedule Assistant Modal
                    </Link> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ) as any,
                  icon: "person",
                },
              ],
            },
          ]}
        />
      </div>
      <Switch>
        <Route exact path={join(path)}>
          <ScheduleAssistantModalPage />
        </Route>
        <Route exact path={join(path, "schedule_availability")}>
          <DayViewPage />
        </Route>
        <Route exact path={join(path, "schedule_assistant_modal")}>
          <ScheduleAssistantModalPage />
        </Route>
        <Route exact path={join(path, "jobs_list_page")}>
          <IndexPage />
        </Route>
        <Route exact path={join(path, "invoice_scheduler")}>
          <InvoiceSchedulerPage />
        </Route>
        <Route exact path={join(path, "scheduler")}>
          <SchedulerPage />
        </Route>
        <Route exact path={join(path, "schedule_recommendations")}>
          <ScheduleRecommendationsPage />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};

export const WorkOrdersRoute = withRailsPropsAsContexts()(
  withEntryPointProviders<AppRouterProps>(WorkOrdersRouteInternal),
);
