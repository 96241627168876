const MIN_PASSWORD_PLACEHOLDER = "New password";
const CONFIRM_PASSWORD_PLACEHOLDER = "Confirm new password";
const PLEASE_WAIT_LABEL = "Please wait";

const PASSWORD_REQUIRED_WARNING = "Enter your new password";
const MIN_PASSWORD_LENGTH_WARNING =
  "Your password must be at least {n} characters";
const CONFIRM_PASSWORD_WARNING = "Re-enter your password";
const PASSWORD_CONFIRMATION_NOT_MATCHED_WARNING = "Passwords don't match";

export {
  MIN_PASSWORD_PLACEHOLDER,
  PASSWORD_REQUIRED_WARNING,
  MIN_PASSWORD_LENGTH_WARNING,
  CONFIRM_PASSWORD_PLACEHOLDER,
  CONFIRM_PASSWORD_WARNING,
  PASSWORD_CONFIRMATION_NOT_MATCHED_WARNING,
  PLEASE_WAIT_LABEL,
};
