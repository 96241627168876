import React, { useState } from "react";
import { FormatFile } from "@jobber/components/FormatFile";
import type { FileUpload } from "@jobber/components/InputFile";
import { LightBox } from "@jobber/components/LightBox";
import type { File } from "~/utilities/API/graphql";
import styles from "./Content.module.css";

interface FileAttachmentProps {
  readonly files: File[];
}

export function FileAttachment({ files }: FileAttachmentProps) {
  const [imageIndex, setImageIndex] = useState<number | undefined>();

  const images = files.filter(isImage).map(file => {
    return { url: file.url || "", title: file.fileName };
  });

  function closeLightBox() {
    setImageIndex(undefined);
  }

  function openFile(file: File) {
    if (isImage(file)) {
      setImageIndex(images.findIndex(img => img.url === file.url));
    } else {
      window.open(file.url, "_blank");
    }
  }

  function handleClick(file: File) {
    return () => openFile(file);
  }

  function handleKeyPress(file: File) {
    return (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === " " || event.key === "Enter") {
        openFile(file);
      }
    };
  }

  return (
    <>
      {files.map((object: File, index: number) => {
        const file: FileUpload = {
          name: object.fileName || "attachment",
          type: object.contentType || "",
          size: object.fileSize,
          key: "",
          progress: 1,
          uploadUrl: "",
          src: async function (): Promise<string> {
            return Promise.resolve(object.url || "");
          },
        };
        return (
          <div
            role={"button"}
            className={styles.fileParent}
            key={index}
            onClick={handleClick(object)}
            onKeyPress={handleKeyPress(object)}
            tabIndex={0}
          >
            <FormatFile file={file} />
          </div>
        );
      })}
      <LightBox
        open={imageIndex !== undefined}
        images={images}
        imageIndex={imageIndex}
        onRequestClose={closeLightBox}
      />
    </>
  );
}

function isImage(file: File): boolean {
  return !!file.contentType?.includes("image");
}
