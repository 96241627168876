import { useIntl } from "react-intl";
import React from "react";
import { Text } from "@jobber/components/Text";
import styles from "jobber/campaigns/components/EmailPreview/EmailPreview.module.css";
import type { ReferralSettingsQuery } from "~/utilities/API/graphql";
import { IncentiveType } from "~/utilities/API/graphql";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { messages } from "./messages";

interface ReferralSectionProps {
  link?: string;
  referralSettings?: ReferralSettingsQuery;
  loadingReferralSettings: boolean;
}

export function ReferralSection({
  link,
  referralSettings,
  loadingReferralSettings,
}: ReferralSectionProps) {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();
  let referralLinkTitle;

  if (loadingReferralSettings || referralSettings === undefined) return <></>;

  const incentiveSettings = referralSettings.marketingClientReferralsSettings;

  if (incentiveSettings?.incentivesEnabled) {
    const incentiveValue = incentiveSettings.value || 0;
    const incentiveAmount =
      incentiveSettings.type === IncentiveType.MONEY
        ? currencyFormatter(incentiveValue)
        : `${incentiveValue}%`;

    referralLinkTitle = formatMessage(messages.referralLinkWithIncentiveTitle, {
      incentiveAmount,
    });
  } else {
    referralLinkTitle = formatMessage(messages.referralLinkNoIncentiveTitle);
  }

  return (
    <div className={styles.referralLink}>
      <div className={styles.referralLinkTitle}>
        <Text>
          <strong>{referralLinkTitle}</strong>
        </Text>
        <Text>
          <strong>{formatMessage(messages.referralLinkShareMessage)}</strong>
        </Text>
      </div>
      <Text>
        <a href={link}>{link}</a>
      </Text>
    </div>
  );
}
