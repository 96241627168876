import React, { type MutableRefObject, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { APIProvider } from "~/utilities/API/APIProvider";
import { CongratsHandsImg } from "./images";
import styles from "./CoreToConnectTrialInTrialModal.module.css";

export function CoreToConnectTrialInTrialModal() {
  const [showModal, setShowModal] = useState(true);
  const ctaName = "core_to_connect_trial_in_trial_modal_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          title="Your Connect trial has started"
          open={showModal}
          dismissible
          onRequestClose={handleModalDismissal}
          primaryAction={{
            label: "Explore Connect",
            onClick: handleModalButtonClick,
          }}
        >
          <Content>
            <div className={styles.imageContainer}>
              <img
                src={CongratsHandsImg.src}
                alt={CongratsHandsImg.alt}
                className={styles.image}
              />
            </div>
            <Text>
              You now have access to Connect for 14 days. After the trial ends,
              you will automatically be converted back to Core. None of your
              existing work will be lost.
            </Text>
            <Text variation="subdued">
              Restart the Jobber app to access the trial on your phone.
            </Text>
          </Content>
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleModalButtonClick() {
    convertCTA(ctaRef)();
    setShowModal(false);
  }

  function handleModalDismissal() {
    dismissCTA(ctaRef)();
    setShowModal(false);
  }
}
