import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  ExpenseCreateInput,
  ExpenseCreateMutation,
} from "~/utilities/API/graphql";
import { Intercom } from "utilities/chat";
import {
  EXPENSE_CREATE,
  JOB_COST_EXPENSE_TOTALS_QUERY,
  JobCostExpense,
} from "./graphql";
import { messages } from "./messages";

export interface UseExpenseMutationData {
  jobId: string;
}

export function useExpenseMutation(
  data: UseExpenseMutationData,
  canViewJobCosts: boolean,
  canViewExpenses: boolean,
  canViewTimeSheetEntries: boolean,
  closeModal: () => void,
): {
  handleSubmit(input: ExpenseCreateInput): void;
  loading: boolean;
} {
  const [create, { loading }] = useMutation<ExpenseCreateMutation>(
    EXPENSE_CREATE,
    {
      refetchQueries: [
        {
          query: JOB_COST_EXPENSE_TOTALS_QUERY,
          variables: {
            jobId: data.jobId,
            canViewJobCosts: canViewJobCosts,
          },
        },
      ],
      update(cache, result) {
        if (result.data?.expenseCreate?.expense) {
          const newExpense = result.data.expenseCreate.expense;
          cache.modify({
            id: `JobViewExpenses:${data.jobId}`,
            fields: {
              items(existingExpenseRefs = []) {
                const newExpenseRef = cache.writeFragment({
                  data: newExpense,
                  fragment: JobCostExpense,
                });
                return {
                  ...existingExpenseRefs,
                  nodes: [newExpenseRef, ...existingExpenseRefs.nodes],
                  totalCount: existingExpenseRefs.totalCount + 1,
                };
              },
            },
          });
        }
      },
    },
  );

  async function handleSubmit(input: ExpenseCreateInput) {
    try {
      const result = await create({
        variables: {
          input: {
            linkedJobId: data.jobId,
            ...input,
          },
        },
      });

      const errors = result.data?.expenseCreate.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.expenseSaved,
          variation: "success",
        });
        Intercom.EXECUTE("trackEvent", "user_expensewithinjob_entry");
        closeModal();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleSubmit,
    loading,
  };
}
