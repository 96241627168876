import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Link } from "@jobber/components/Link";
import { Divider } from "@jobber/components/Divider";
import JobberLogo from "@images/jobber-logo-white.svg";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./IntegrationPageFooter.module.css";
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from "../../ANALYTICS_EVENTS";

interface MarketplaceFooterProps {
  title?: string;
}

export function IntegrationPageFooter({ title }: MarketplaceFooterProps) {
  const attributionValue = (title || "app_marketplace").replace(/\s+/g, "-");
  const urlParams = `signup_attribution_name=app_marketplace&signup_attribution_value=${attributionValue}`;

  const socialLinks = [
    { platform: "facebook", url: "https://www.facebook.com/GetJobber" },
    { platform: "twitter", url: "https://twitter.com/getjobber" },
    { platform: "instagram", url: "https://www.instagram.com/jobber/" },
    { platform: "youtube", url: "https://www.youtube.com/user/GetJobber" },
    {
      platform: "linkedIn",
      url: "https://ca.linkedin.com/company/jobber-software",
    },
  ];

  function handleStartTrialClick() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedFreeTrialCTA, {
      source: title
        ? ANALYTICS_PROPERTIES.appListingPageSource
        : ANALYTICS_PROPERTIES.marketplaceHomePageSource,
      title,
    });

    window.location.href = `/signup?${urlParams}`;
  }

  return (
    <div className={styles.integrationPageFooterContainer}>
      <div className={styles.integrationPageFooterTop}>
        <div className={styles.marketPlaceFooterPic} />

        <div className={styles.integrationPageFooterDescription}>
          <Heading level={1}>
            Power your business with endless possibilities
          </Heading>
          <Text>
            Grow your revenue an average of 37% in your first year using Jobber.
            Free up your evenings so you can be with friends and family, and get
            back to dreaming about where you’ll take your business next.
          </Text>
          <Button label={"Start Free Trial"} onClick={handleStartTrialClick} />
        </div>
      </div>

      <div className={styles.integrationPageFooterPaperTier} />

      <div className={styles.integrationPageFooterLinksContainer}>
        <div className={styles.integrationPageFooterMiddleContainer}>
          <div className={styles.integrationPageFooterLogoContainer}>
            <img src={JobberLogo} alt="Jobber Logo" />

            <div className={styles.integrationPageFooterContacts}>
              <div className={styles.integrationPageFooterLinkBlock}>
                <Link external url="/">
                  Visit Jobber.com
                </Link>
                <Link
                  external
                  url="https://help.getjobber.com/hc/en-us/articles/360062128653-App-Marketplace"
                >
                  About App Marketplace
                </Link>
              </div>

              <div className={styles.integrationPageFooterLinkBlock}>
                <Link external url="https://getjobber.com/about/careers/">
                  Careers
                </Link>
                <Link external url="https://developer.getjobber.com/">
                  Developer Center
                </Link>
              </div>

              <div className={styles.integrationPageFooterLinkBlock}>
                <a href="tel:+18887211115">1-888-721-1115</a>
                <a href="mailto:hello@getjobber.com">hello@getjobber.com</a>
              </div>
            </div>
          </div>

          <div className={styles.integrationPageFooterSocials}>
            {socialLinks.map(link => (
              <Link key={link.platform} url={link.url} external>
                <Icon
                  name={link.platform as IconNames}
                  color="interactiveSubtle"
                />
              </Link>
            ))}
          </div>
        </div>

        <Divider />

        <div className={styles.integrationPageFooterBottomContainer}>
          <div className={styles.integrationPageFooterLinksBottom}>
            <Text>© Jobber 2024</Text>

            <Divider direction="vertical" />
          </div>

          <div className={styles.integrationPageFooterLinksBottom}>
            <Link external url="https://getjobber.com/privacy-policy/">
              Privacy
            </Link>

            <Divider direction="vertical" />
          </div>

          <div className={styles.integrationPageFooterLinksBottom}>
            <Link external url="https://getjobber.com/accessibility-policy/">
              Accessibility
            </Link>

            <Divider direction="vertical" />
          </div>

          <div className={styles.integrationPageFooterLinksBottom}>
            <Link external url="https://getjobber.com/terms-of-service/">
              Terms of Service
            </Link>

            <Divider direction="vertical" />
          </div>

          <div className={styles.integrationPageFooterLinksBottom}>
            <Link external url="https://getjobber.com/sitemap/">
              Sitemap
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
