import { screen } from "@testing-library/react";
import type { SelectableCard } from "jobber/campaigns/components/SelectableCards";
import { messages } from "jobber/campaigns/components/SelectableCards/SelectableCard/messages";

export function getSelectableCardTitle(card: SelectableCard) {
  return screen.getByText(card.heading);
}

export function getSelectableCardDescription(card: SelectableCard) {
  return screen.getByText(card.description);
}

export function getSelectableCardValue(card: SelectableCard) {
  return screen.getByDisplayValue(card.value);
}

export function getRecommendedLabel() {
  return screen.getByText(messages.recommended.defaultMessage);
}

export function getComingSoonLabel() {
  return screen.getByText(messages.comingSoon.defaultMessage);
}

export function getReferralLabel() {
  return screen.getByText(messages.referral.defaultMessage);
}

export function queryComingSoonLabel() {
  return screen.queryByText(messages.comingSoon.defaultMessage);
}

export function getAdditionalButton(card: SelectableCard) {
  return screen.getByText(card.additionalButtonText || "");
}
