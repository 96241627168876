import { Heading } from "@jobber/components/Heading";
import React from "react";

export interface ClientBalanceDetail {
  name: string;
  value: string;
}
interface ClientBalanceDisplayProps {
  clientDetails?: ClientBalanceDetail[];
}

export function ClientBalanceDisplay(props: ClientBalanceDisplayProps) {
  const { clientDetails } = props;
  if (!clientDetails || !clientDetails.length) return <></>;

  return (
    <>
      <Heading level={4}>Client details</Heading>
      <ul className="list list--dividers">
        {clientDetails.map(balance => (
          <li key={balance.name} className="list-item">
            <div className="row row--tightColumns align-middle">
              <div className="columns">
                <span className="list-label">{balance.name}</span>
              </div>
              <div className="columns u-textRight">
                <span className="list-text">{balance.value}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
