/* eslint-disable import/no-internal-modules */
import { format, isSameDay, parse } from "date-fns";
import { makeFullCalendarBusinessHoursForDay } from "../../utils/makeFullCalendarBusinessHoursForDay";
import { now, today } from "../../utils/today";
import { yearMonthDayToDate } from "../../yearMonthDay";
import type { SchedulingAssistantContext } from "../schedulingAssistant.machine";

export function scrollToTime(context: SchedulingAssistantContext) {
  let date: Date;
  const selectedDate = yearMonthDayToDate(context.selectedDate);

  // Scroll to selected time slot
  if (context.selectedTime?.start) {
    date = new Date(context.selectedTime.start.getTime());
  }

  // Scroll to current time
  else if (isSameDay(selectedDate, today())) {
    date = now();
  }

  // Scroll to start of business hours
  else {
    const { startTime } = makeFullCalendarBusinessHoursForDay(
      context.businessHours,
      selectedDate.getDay(),
    );

    date = parse(startTime.slice(0, 5), "HH:mm", today());
  }

  // Deduct an hour to account for some extra padding
  date.setHours(date.getHours() - 1);

  document
    .querySelector(`[data-time="${format(date, "HH")}:00:00"]`)
    ?.scrollIntoView();
}
