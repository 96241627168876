import type { Query, TimeDimensionGranularity } from "@cubejs-client/core";

interface WorkflowChartQuery {
  primary: Query;
  secondary: Query;
}

export const queries: { [key: string]: WorkflowChartQuery } = {
  requests: {
    primary: {
      measures: ["WorkRequests.count"],
      timeDimensions: [
        {
          dimension: "WorkRequests.createdAt",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      filters: [],
    },
    secondary: {
      measures: ["WorkRequests.count"],
      timeDimensions: [
        {
          dimension: "WorkRequests.updatedAt",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      segments: ["WorkRequests.converted"],
      filters: [],
    },
  },
  invoices: {
    primary: {
      measures: ["Invoices.count", "Invoices.total"],
      timeDimensions: [
        {
          dimension: "Invoices.issuedDate",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 30 days",
        },
      ],
      filters: [],
    },
    secondary: {
      measures: ["Invoices.count", "Invoices.total"],
      timeDimensions: [
        {
          dimension: "Invoices.receivedDate",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 30 days",
        },
      ],
      filters: [],
    },
  },
  quotes: {
    primary: {
      measures: ["Quotes.cost", "Quotes.count"],
      timeDimensions: [
        {
          dimension: "Quotes.statusDate",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      segments: ["Quotes.sent"],
      filters: [],
    },
    secondary: {
      measures: ["Quotes.cost", "Quotes.count"],
      timeDimensions: [
        {
          dimension: "Quotes.statusDate",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      segments: ["Quotes.converted"],
      filters: [],
    },
  },
  jobs: {
    primary: {
      measures: ["WorkOrders.total", "WorkOrders.count"],
      timeDimensions: [
        {
          dimension: "WorkOrders.startAt",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      segments: [],
      filters: [],
    },
    secondary: {
      measures: ["WorkOrders.total", "WorkOrders.count"],
      timeDimensions: [
        {
          dimension: "WorkOrders.completedAt",
          granularity: "day" as TimeDimensionGranularity,
          dateRange: "Last 7 days",
        },
      ],
      segments: [],
      filters: [],
    },
  },
};
