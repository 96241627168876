import React from "react";
import { Markdown } from "@jobber/components/Markdown";

interface BeforeStartingProps {
  content: string;
}

export function BeforeStarting({ content }: BeforeStartingProps) {
  return <Markdown content={content} externalLink={true} />;
}
