import type { InstantBookingSelectedService } from "~/utilities/API/graphql";
import type {
  CategoryWithServices,
  CombinedOffering,
  Offering,
} from "jobber/google_lsa/MerchantConfiguration";

export function convertServices(
  allServices: Offering[],
  selectedServices: InstantBookingSelectedService[],
) {
  // We need to merge that with the full list of services so we can get the
  // display names and generate the full list of available services later.
  //
  // selectedServices + allServices = CombinedOffering
  const zipperedOfferings = zipOfferingsAndSelectedServices(
    allServices,
    selectedServices,
  );

  return organizeServices(zipperedOfferings);
}

function findSelectedService(
  selectedServices: InstantBookingSelectedService[],
  serviceTypeId: number,
) {
  return selectedServices.find(
    service => service.serviceTypeId === serviceTypeId,
  );
}

function zipOfferingsAndSelectedServices(
  offerings: Offering[],
  selectedServices: InstantBookingSelectedService[],
): CombinedOffering[] {
  return offerings.map(offering => {
    const selectedService = findSelectedService(selectedServices, offering.id);
    return {
      serviceTypeId: offering.id,
      categoryName: offering.categoryName,
      categoryDisplayName: offering.categoryDisplayName,
      serviceName: offering.serviceName,
      serviceDisplayName: offering.serviceDisplayName,
      id: selectedService ? selectedService.id : undefined,
      price: selectedService ? selectedService.price : undefined,
      enabled: selectedService ? true : false,
    };
  });
}

function organizeServices(offerings: CombinedOffering[]) {
  const chunkedServices: CategoryWithServices[] = [];
  offerings.forEach(offering => {
    const newService = {
      serviceTypeId: offering.serviceTypeId,
      serviceName: offering.serviceName,
      serviceDisplayName: offering.serviceDisplayName,
      id: offering.id,
      price: offering.price,
      enabled: offering.id ? true : false,
    };

    if (
      chunkedServices.length > 0 &&
      chunkedServices[chunkedServices.length - 1].categoryName ===
        offering.categoryName
    ) {
      chunkedServices[chunkedServices.length - 1].services.push(newService);
    } else {
      chunkedServices.push({
        categoryName: offering.categoryName,
        categoryDisplayName: offering.categoryDisplayName,
        services: [newService],
      });
    }
  });

  return chunkedServices;
}
