import React, { useEffect, useState } from "react";
import { useSetupGuideStatus } from "jobber/dashboard/components/SetupGuide/hooks/useSetupGuideStatus";
import {
  convertDataToSteps,
  getPendingIndex,
} from "jobber/dashboard/components/SetupGuide/utils";
import { useSetupGuideActions } from "jobber/dashboard/components/SetupGuide/hooks/useSetupGuideActions";
import { useSetupGuideEdit } from "jobber/dashboard/components/SetupGuide/hooks/useSetupGuideEdit";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ConnectToGrowOnboardingPopUpModal } from "jobber/connectToGrowTrial/components/ConnectToGrowOnboardingPopUpModal";
import { SetupGuide } from "./SetupGuide";
import { SetupGuideMini } from "./components/SetupGuideMini";
import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "./constants";
import { DonePanel } from "./components/DonePanel";

interface SetupGuideLoaderProps {
  userName: string;
  drawerOpen?: boolean | undefined;
  optingBackIn?: boolean;
}

export function SetupGuideLoader({
  userName,
  drawerOpen,
  optingBackIn,
}: SetupGuideLoaderProps) {
  const {
      handleSetupGuideOptBackIn,
      handleSetupGuideOptOut,
      handleSetupGuideComplete,
    } = useSetupGuideActions(),
    { handleSetupGuideEdit } = useSetupGuideEdit(),
    { data } = useSetupGuideStatus(),
    setupGuide = data?.setupGuideStatus.setupGuide,
    [optOutOpen, setOptOutOpen] = useState(false),
    [currentStepIndex, setCurrentStepIndex] = useState(-1),
    [firstLoad, setFirstLoad] = useState(setupGuide?.firstLoad || true),
    [hideSetupGuideState, setHideSetupGuideState] = useState(
      setupGuide?.optOut || setupGuide?.completed || false,
    );

  useEffect(() => {
    if (optingBackIn) {
      handleSetupGuideOptBackIn(() => {
        Amplitude.TRACK_EVENT("CTA Clicked", {
          name: "explore_grow_features",
          source: "connect_to_grow_feature_trial_in_trial_banner",
        });
        setHideSetupGuideState(false);
      });
    }
  }, []);

  if (!setupGuide) {
    return <></>;
  }

  const steps = convertDataToSteps(
    setupGuide.steps.nodes,
    {
      userName,
    },
    firstLoad,
    setCurrentStepIndex,
    handleSetupGuideEdit,
    setFirstLoad,
  );
  const pendingIndex = getPendingIndex(
    setupGuide.steps.nodes,
    setupGuide.firstLoad,
  );
  const isDone = setupGuide.progress === 100;

  if (pendingIndex != currentStepIndex && currentStepIndex === -1) {
    setCurrentStepIndex(pendingIndex);
  }

  return (
    <>
      {!hideSetupGuideState && (
        <>
          <ConnectToGrowOnboardingPopUpModal
            showModal={optOutOpen}
            confirmationCallback={() => handleSetupGuideOptOut(onOptOutSuccess)}
            dismissalCallback={() => setOptOutOpen(false)}
          />
          {isDone ? (
            <DonePanel
              onClose={() =>
                handleSetupGuideComplete(() => {
                  Amplitude.TRACK_EVENT("CTA Dismissed", {
                    name: "closed_completed_checklist",
                    source: "connect_to_grow_trial_onboarding_checklist",
                  });
                  setHideSetupGuideState(true);
                })
              }
            />
          ) : window.innerWidth <= SMALL_SCREEN_WIDTH ||
            (window.innerWidth <= LARGE_SCREEN_WIDTH && drawerOpen) ? (
            <SetupGuideMini
              steps={steps}
              currentStepIndex={currentStepIndex}
              onDismiss={() => {
                setOptOutOpen(true);
              }}
              setCurrentStepIndex={setCurrentStepIndex}
            />
          ) : (
            <SetupGuide
              steps={steps}
              currentStepIndex={currentStepIndex}
              hideSetupGuide={setupGuide.optOut}
              onDismiss={() => {
                setOptOutOpen(true);
              }}
              setCurrentStepIndex={setCurrentStepIndex}
            />
          )}
        </>
      )}
    </>
  );

  function onOptOutSuccess() {
    setHideSetupGuideState(true);
    setOptOutOpen(false);
  }
}
