export const messages = {
  fileTooLarge: {
    message:
      "The file you uploaded was too large. Please upload a png or jpg under 16mb.",
    description: "error message for file too large",
  },
  fileInvalidType: {
    message:
      "The file type you uploaded could not be processed. Please upload a png or jpg under 16mb.",
    description: "error message for invalid file type",
  },
};
