import React from "react";
import { useIntl } from "react-intl";
import { Content, Heading } from "@jobber/components";
import { UpcomingPayout } from "../UpcomingPayout";
import { messages } from "../../messages";

interface UpcomingPayoutsModalProps {
  expectedPayouts: {
    arrivalDate: string;
    netAmount: number;
    id: string;
  }[];
}

export const UpcomingPayoutsModalContent = ({
  expectedPayouts,
}: UpcomingPayoutsModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Content spacing="larger">
      <Heading level={2}>
        {formatMessage(messages.upcomingPayoutsModalHeading)}
      </Heading>
      <Content>
        {expectedPayouts.map(expectedPayout => (
          <UpcomingPayout
            expectedPayout={expectedPayout}
            key={expectedPayout.arrivalDate}
          />
        ))}
      </Content>
    </Content>
  );
};
