import currency from "currency.js";
import { currencyWithUnit } from "utilities/currencyWithUnit";

export interface DepositResult {
  id: string;
  cardLast4: string;
  amount: string;
  instantAvailableBalance: string;
}

interface WithdrawFundsDialogDisplayState {
  formattedPayoutLimit: string;
  formattedPayoutFeeTotal: string;
  formattedPayoutFeePercent: string;
  formattedWithdrawalAmount: string;
  requestedAmount: string;

  inputErrorMessage: string | undefined;

  isLoading: boolean;
  withdrawRequestErrorMessage: string | undefined;

  depositResult?: DepositResult;
}

interface WithdrawFundsDialogInternalState {
  payoutLimitMin: currency;
  payoutLimitMax: currency;
  currencyUnit: string;
  feeMultiplier: string;
}

export type WithdrawFundsDialogState = WithdrawFundsDialogDisplayState &
  WithdrawFundsDialogInternalState;

interface InitAction {
  type: "init";
  payload: {
    instantAvailableAmount: string;
    currencyUnit: string;
    feeMultiplier: string;
  };
}

interface RequestAmountChangeAction {
  type: "requestAmountChange";
  payload: { requestingAmount: string };
}

interface FormatAmountAction {
  type: "formatAmount";
  payload?: never;
}

interface WithdrawFundsAction {
  type: "withdrawFunds";
  payload?: never;
}

interface OnWithdrawFundsSuccessAction {
  type: "onWithdrawFundsSuccess";
  payload: {
    id: string;
    amount: string;
    last4: string;
    instantAvailableBalance: string;
  };
}

interface OnWithdrawFundsErrorAction {
  type: "onWithdrawFundsError";
  payload: {
    errorMessage: string;
  };
}

export type WithdrawFundsDialogAction =
  | InitAction
  | RequestAmountChangeAction
  | FormatAmountAction
  | WithdrawFundsAction
  | OnWithdrawFundsSuccessAction
  | OnWithdrawFundsErrorAction;

export function initWithdrawFundsDialogReducer(
  params: InitAction["payload"],
): WithdrawFundsDialogState {
  const unusedState = {} as WithdrawFundsDialogState;
  return withdrawFundsDialogReducer(unusedState, {
    type: "init",
    payload: params,
  });
}

export function withdrawFundsDialogReducer(
  prevState: WithdrawFundsDialogState,
  action: WithdrawFundsDialogAction,
): WithdrawFundsDialogState {
  switch (action.type) {
    case "init": {
      const { currencyUnit, instantAvailableAmount } = action.payload;

      const payoutLimitMin = currencyWithUnit("50", currencyUnit);
      const payoutLimitMax = currencyWithUnit("9999", currencyUnit);

      const instantAvailable = currencyWithUnit(
        instantAvailableAmount,
        currencyUnit,
      );
      const amount =
        payoutLimitMax.subtract(instantAvailable).intValue < 0
          ? payoutLimitMax
          : instantAvailable;

      const feeMultiplier = action.payload.feeMultiplier;
      const formattedPayoutFeePercent = formatFeePercentDisplay(feeMultiplier);
      const [payoutFeeTotal, withdrawingAmount] = calculateAmountAndFee(
        amount,
        feeMultiplier,
      );

      const inputErrorMessage = amountErrorMessage(
        amount,
        payoutLimitMin,
        payoutLimitMax,
      );

      return {
        formattedPayoutLimit: amount.format(),
        formattedPayoutFeeTotal: payoutFeeTotal.format(),
        formattedPayoutFeePercent,
        requestedAmount: amount.format(),
        formattedWithdrawalAmount: withdrawingAmount.format(),
        isLoading: false,
        inputErrorMessage: inputErrorMessage,
        withdrawRequestErrorMessage: undefined,

        payoutLimitMin: payoutLimitMin,
        payoutLimitMax: amount,
        currencyUnit,
        feeMultiplier,
      };
    }

    case "requestAmountChange":
      return requestAmountChangeReducer(prevState, action);

    case "formatAmount":
      return formatAmountReducer(prevState);

    case "withdrawFunds":
      return withdrawFundsReducer(prevState);

    case "onWithdrawFundsSuccess":
      return onWithdrawFundsSuccessReducer(prevState, action);

    case "onWithdrawFundsError":
      return onWithdrawFundsErrorReducer(prevState, action);

    default:
      return prevState;
  }
}

function requestAmountChangeReducer(
  prevState: WithdrawFundsDialogState,
  action: RequestAmountChangeAction,
): WithdrawFundsDialogState {
  const { payoutLimitMin, payoutLimitMax, currencyUnit, feeMultiplier } =
    prevState;

  const amount = currencyWithUnit(
    action.payload.requestingAmount,
    currencyUnit,
  );
  const [payoutFeeTotal, withdrawingAmount] = calculateAmountAndFee(
    amount,
    feeMultiplier,
  );

  const inputErrorMessage = amountErrorMessage(
    amount,
    payoutLimitMin,
    payoutLimitMax,
  );

  return {
    ...prevState,
    formattedPayoutFeeTotal: payoutFeeTotal.format(),
    requestedAmount: action.payload.requestingAmount,
    formattedWithdrawalAmount: withdrawingAmount.format(),
    inputErrorMessage,
  };
}

function formatAmountReducer(
  prevState: WithdrawFundsDialogState,
): WithdrawFundsDialogState {
  return {
    ...prevState,
    requestedAmount: formatInputField(prevState.requestedAmount),
  };
}

function withdrawFundsReducer(
  prevState: WithdrawFundsDialogState,
  // if needed: // action: WithdrawFundsAction,
): WithdrawFundsDialogState {
  const { inputErrorMessage } = prevState;

  if (inputErrorMessage) {
    throw new Error(inputErrorMessage);
  }

  return {
    ...prevState,
    isLoading: true,
    withdrawRequestErrorMessage: undefined,
  };
}

function onWithdrawFundsSuccessReducer(
  prevState: WithdrawFundsDialogState,
  action: OnWithdrawFundsSuccessAction,
): WithdrawFundsDialogState {
  return {
    ...prevState,
    isLoading: false,
    depositResult: {
      id: action.payload.id,
      amount: action.payload.amount,
      cardLast4: action.payload.last4,
      instantAvailableBalance: action.payload.instantAvailableBalance,
    },
  };
}

function onWithdrawFundsErrorReducer(
  prevState: WithdrawFundsDialogState,
  action: OnWithdrawFundsErrorAction,
): WithdrawFundsDialogState {
  return {
    ...prevState,
    isLoading: false,
    withdrawRequestErrorMessage: action.payload.errorMessage,
  };
}

function calculateAmountAndFee(amount: currency, feeMultiplier: string) {
  const payoutFee = amount.multiply(feeMultiplier);
  const withdrawingAmount = amount.subtract(payoutFee);

  return [payoutFee, withdrawingAmount] as const;
}

function amountErrorMessage(
  amount: currency,
  payoutLimitMin: currency,
  payoutLimitMax: currency,
) {
  if (payoutLimitMax.subtract(amount).intValue < 0) {
    return `You can not withdraw more than ${payoutLimitMax.format()}`;
  } else if (amount.subtract(payoutLimitMin).intValue < 0) {
    return `You can not withdraw less than ${payoutLimitMin.format()}`;
  } else {
    return undefined;
  }
}

function formatInputField(amount: string): string {
  return currency(amount, {
    symbol: "",
    separator: "",
  }).format();
}

export function formatFeePercentDisplay(feeMultiplier: string) {
  return (+feeMultiplier).toLocaleString("en", {
    style: "percent",
  });
}
