import React from "react";
import { Countdown } from "@jobber/components/Countdown";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { computeGranularity } from "./computeGranularity";

interface BannerCountdownProps {
  readonly expiryDate: string;
  showUnits?: boolean;
  onComplete(): void;
}

export function InternalGlobalBannerCountdown({
  expiryDate,
  showUnits = true,
  onComplete,
}: BannerCountdownProps) {
  const granularity = computeGranularity(expiryDate);
  return (
    <Text>
      <Emphasis variation="bold">
        <Countdown
          granularity={granularity}
          showUnits={showUnits}
          date={expiryDate}
          onComplete={onComplete}
        />
      </Emphasis>
    </Text>
  );
}
