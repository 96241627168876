import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";

interface AboutReportEntryProps {
  entryName: string;
  explanation: string;
}

export function AboutReportEntry({
  entryName,
  explanation,
}: AboutReportEntryProps) {
  return (
    <Text variation="subdued">
      <Emphasis variation="bold">{`${entryName} `} </Emphasis>
      {explanation}
    </Text>
  );
}
