import * as React from "react";
import classNames from "classnames";
import ApplePayCard from "@images/ApplePayCard.svg";
import GooglePayCard from "@images/GooglePayCard.svg";
import styles from "./CardBrand.module.css";
import type { CardBrandProps } from "./types";

// key = brand from: https://stripe.com/docs/stripe-js/reference#element-on
export const cardBrandsToImagePathMap = {
  amex: `svg/cc-american-express.svg`,
  discover: "svg/cc-discover.svg",
  jcb: "svg/cc-jcb.svg",
  mastercard: "svg/cc-mastercard.svg",
  intl: "svg/cc-international.svg",
  visa: "svg/cc-visa.svg",
  diners: "svg/cc-diners-club.svg",
  ["diners club"]: "svg/cc-diners-club.svg",
  ["american express"]: "svg/cc-american-express.svg",
  unknown: "",
};

const importedBrandsMap = {
  applePay: ApplePayCard,
  googlePay: GooglePayCard,
};

// unionpay, or unknown
const defaultImagePath = (imgManifest: { [key: string]: string }) =>
  imgManifest["svg/card.svg"];

interface ResolveConfig {
  brand: string | undefined;
  imgManifest: { [key: string]: string };
}

const resolveSrcFromBrand = ({ brand, imgManifest }: ResolveConfig) => {
  const map = cardBrandsToImagePathMap as { [key: string]: string };
  const importedMap = importedBrandsMap as { [key: string]: string };
  const knownImageKey = brand && map[brand];
  if (brand && importedMap[brand]) {
    return importedMap[brand];
  }

  if (knownImageKey) {
    return imgManifest[knownImageKey];
  }

  return defaultImagePath(imgManifest);
};

export function CardBrand(props: CardBrandProps) {
  const {
    brand,
    imgManifest = window.SG1_IMAGE_MANIFEST,
    size = "base",
  } = props;
  const src = React.useMemo(
    () => resolveSrcFromBrand({ brand, imgManifest }),
    [brand, imgManifest],
  );

  const cardBrandClassNames = classNames({
    [styles.small]: size === "small",
    [styles.smaller]: size === "smaller",
  });

  return (
    <img
      alt={brand || "credit card"}
      src={src}
      className={cardBrandClassNames}
    />
  );
}
