import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Text } from "@jobber/components/Text";
import {
  type CustomFieldConfigurationNodeCustomFieldConfigurationAreaFragment as CustomFieldAreaFragment,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
  type CustomFieldConfigurationNodeCustomFieldConfigurationDropdownFragment as CustomFieldDropdownFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationNumericFragment as CustomFieldNumericFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationTextFragment as CustomFieldTextFragment,
} from "~/utilities/API/graphql";
import styles from "./CustomFieldItemContent.module.css";

export interface CustomFieldItemContentProps {
  customField: CustomFieldConfigurationNodeFragment;
  onEditCustomField?: (
    customField: CustomFieldConfigurationNodeFragment,
  ) => void;
  moreActions?: React.ReactNode;
}

export function CustomFieldItemContent({
  customField,
  onEditCustomField,
  moreActions,
}: CustomFieldItemContentProps) {
  const readonly =
    customField.transferedFrom ||
    customField.app ||
    customField.valueType === CustomFieldConfigurationValueType.LINK ||
    customField.archived;
  return (
    <div className={styles.customFieldItemContentArea}>
      <div className={styles.customFieldItemName}>
        {readonly ? (
          customField.name
        ) : (
          <button
            className={styles.customFieldItemEditButton}
            onClick={
              onEditCustomField
                ? () => onEditCustomField(customField)
                : undefined
            }
          >
            {customField.name}
          </button>
        )}
      </div>
      <div className={styles.customFieldItemDescriptionAndMoreActions}>
        <div className={styles.customFieldItemDescription}>
          <CustomFieldDescription customField={customField} />
        </div>
        {moreActions && (
          <div
            className={
              customField.archived
                ? styles.archivedCustomFieldActionsButton
                : styles.customFieldMoreActionsButton
            }
          >
            {moreActions}
          </div>
        )}
      </div>
    </div>
  );
}

function CustomFieldDescription({
  customField,
}: Pick<CustomFieldItemContentProps, "customField">) {
  return (
    <Text>
      {(customField.transferable || customField.transferedFrom) && (
        <InlineLabel> Transferable </InlineLabel>
      )}{" "}
      {getDescription(customField)}
    </Text>
  );
}

function getDescription(customField: CustomFieldConfigurationNodeFragment) {
  switch (customField.valueType) {
    case CustomFieldConfigurationValueType.LINK:
      return `Stores a link`;
    case CustomFieldConfigurationValueType.TRUE_FALSE:
      return `Stores a true or false value`;
    case CustomFieldConfigurationValueType.TEXT:
      return getTextDescription(customField as CustomFieldTextFragment);
    case CustomFieldConfigurationValueType.AREA:
      return getAreaDescription(customField as CustomFieldAreaFragment);
    case CustomFieldConfigurationValueType.DROPDOWN:
      return getDropdownDescription(customField as CustomFieldDropdownFragment);
    case CustomFieldConfigurationValueType.NUMERIC:
      return getNumericDescription(customField as CustomFieldNumericFragment);
    default:
      return <></>;
  }
}

function getDropdownDescription(customField: CustomFieldDropdownFragment) {
  const options = (
    customField as CustomFieldDropdownFragment
  ).dropdownOptions.map(option => {
    if (option.trim() === "") {
      return "*Blank*";
    }
    return option;
  });
  return `Can hold one of the following: ${options.join(", ")}`;
}

function getAreaDescription(customField: CustomFieldAreaFragment) {
  return `Stores an area in square ${customField.unit}s`;
}

function getTextDescription(customField: CustomFieldTextFragment) {
  const description = (customField as CustomFieldTextFragment).textDefaultValue
    ? ` (default: "${
        (customField as CustomFieldTextFragment).textDefaultValue
      }")`
    : "";
  return `Stores a text value${description}`;
}

function getNumericDescription(customField: CustomFieldNumericFragment) {
  return `Stores the number of ${
    (customField as CustomFieldNumericFragment).unit
  }s`;
}
