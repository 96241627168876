import React from "react";
import { useIntl } from "react-intl";
import { Spinner } from "@jobber/components/Spinner";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import { PaymentMethodListItem } from "jobber/payments_sca/components/PaymentMethodListItem/PaymentMethodListItem";
import { PaymentMethodsEmptyState } from "components/PaymentMethodsEmptyState/PaymentMethodsEmptyState";
import type { StoredPaymentMethodInterface } from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards";
import styles from "./ClientPaymentMethods.module.css";
import { messages } from "./messages";

interface ClientPaymentMethodsListProps {
  isLoading: boolean;
  error: Error | undefined;
  paymentMethods: StoredPaymentMethodInterface[];
  requestCardOnFileEmailUrl: string;
  requestCardOnFileSmsUrl: string;
  hasAutoPayJob: boolean;

  onDeletePaymentMethod(
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined>;
  onSetDefaultPaymentMethod(
    paymentMethod: StoredPaymentMethodInterface,
  ): Promise<StoredPaymentMethodInterface | undefined>;
}

export function ClientPaymentMethodsList({
  error,
  isLoading,
  paymentMethods,
  requestCardOnFileEmailUrl,
  requestCardOnFileSmsUrl,
  onDeletePaymentMethod,
  onSetDefaultPaymentMethod,
  hasAutoPayJob,
}: ClientPaymentMethodsListProps) {
  const { formatMessage } = useIntl();

  if (isLoading || !onDeletePaymentMethod || !onSetDefaultPaymentMethod) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  if (error || !onDeletePaymentMethod || !onSetDefaultPaymentMethod) {
    showToast({
      message: formatMessage(messages.toastErrorMessage),
      variation: "error",
    });

    return (
      <div className={styles.errorContainer}>
        <Text>{formatMessage(messages.errorMessage)}</Text>
      </div>
    );
  }

  const shouldShowDeleteDisclaimer = (defaultCard: boolean) => {
    return hasAutoPayJob && (paymentMethods.length === 1 || defaultCard);
  };

  return (
    <>
      {paymentMethods.length === 0 ? (
        <PaymentMethodsEmptyState
          requestCardOnFileEmailUrl={requestCardOnFileEmailUrl}
          requestCardOnFileSmsUrl={requestCardOnFileSmsUrl}
        />
      ) : (
        paymentMethods.map(paymentMethod => (
          <PaymentMethodListItem
            key={paymentMethod.uuid}
            disableButtons={false}
            onDeleteConfirm={onDeletePaymentMethod}
            paymentMethod={paymentMethod}
            showTrashButton={true}
            onSetAsDefault={onSetDefaultPaymentMethod}
            useCompactLayout={true}
            shouldShowDeleteDisclaimer={shouldShowDeleteDisclaimer(
              paymentMethod.isDefault || false,
            )}
          />
        ))
      )}
    </>
  );
}
