import React from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import styles from "./UpgradePrompt.module.css";

interface UpgradePromptProps {
  title: string;
  content: JSX.Element;
  ctaText: string;
  handleCTAClick(): void;
}

export function UpgradePrompt({
  title,
  content,
  ctaText,
  handleCTAClick,
}: UpgradePromptProps) {
  return (
    <Card>
      <div className={styles.upgradePromptWrapper}>
        <div
          className={`columns small-12 medium-4 u-textCenter ${styles.upgradePromptTitle}`}
        >
          <Heading level={3}>{title}</Heading>
        </div>
        <div
          className={`columns small-12 medium-8 ${styles.upgradePromptDescription}`}
        >
          {content}
        </div>
        <div
          className={`columns small-12 medium-4 u-textCenter ${styles.upgradePromptButton}`}
        >
          <Button
            label={ctaText}
            variation="learning"
            type="secondary"
            onClick={handleCTAClick}
          />
        </div>
      </div>
    </Card>
  );
}
