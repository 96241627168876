import React, { createRef, useEffect, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { showToast } from "@jobber/components/Toast";
import { useQuery } from "@apollo/client";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Content } from "@jobber/components/Content";
import { OTPInput, type OTPInputRef } from "jobber/OTP/components/OTPInput";
import { VerifyOTPPhoneInput } from "jobber/settings/users/components/VerifyOTPPhone/components/VerifyOTPPhoneInput";
import type { GetUserTwoFactorDetailsQuery } from "~/utilities/API/graphql";
import { OTPText } from "jobber/OTP/components/OTPText";
import { GET_USER_TWO_FACTOR_DETAILS_QUERY } from "./SetupOrConfirmTwoFactor.graphql";

enum Progress {
  NOOP = 0,
  QUERY_USER = 1,
  ENTER_PHONE = 2,
  ENTER_CODE = 3,
}

export interface SetupOrConfirmTwoFactorProps {
  isOpen: boolean;
  requireReAuth?: boolean;
  isForCommsVerification?: boolean;
  onSuccess(): void;
  onClose(): void;
}

// eslint-disable-next-line max-statements
export function SetupOrConfirmTwoFactor({
  onSuccess,
  requireReAuth = true,
  isOpen,
  isForCommsVerification = false,
  onClose,
}: SetupOrConfirmTwoFactorProps) {
  const inputRef = createRef<OTPInputRef>();
  const [phone, setPhone] = useState("");
  const [channel, setChannel] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [requiresSetup, setRequiresSetup] = useState(true);
  const [progress, setProgress] = useState(Progress.NOOP);

  // loading/disabled state of modal buttons
  const [disabled, setDisabled] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    // Manually set progress when modal is first opened
    if (isOpen) {
      setProgress(requiresSetup ? Progress.QUERY_USER : Progress.ENTER_CODE);
    } else {
      setProgress(Progress.NOOP);
    }
  }, [isOpen]);

  useEffect(() => {
    if (authenticated && !requireReAuth) {
      onAccessGranted();
    }
  }, [authenticated]);

  return (
    <Modal
      title="Two-step verification"
      open={isOpen}
      size="small"
      primaryAction={{
        label: restricted
          ? "OK"
          : progress === Progress.ENTER_PHONE
            ? "Submit"
            : "Enter code",
        disabled: disabled,
        loading: modalLoading,
        onClick: restricted ? onClose : handleSubmit,
      }}
      tertiaryAction={
        restricted
          ? undefined
          : {
              label:
                progress === Progress.ENTER_PHONE ? "Cancel" : "Resend Code",
              variation: "subtle",
              type: "primary",
              onClick:
                progress === Progress.ENTER_PHONE ? onClose : handleResend,
            }
      }
      onRequestClose={onClose}
    >
      {progress === Progress.QUERY_USER && (
        <GetUserVerificationDetails
          setPhone={setPhone}
          setRequiresSetup={setRequiresSetup}
          setChannel={setChannel}
          setAuthenticated={setAuthenticated}
          setRestricted={setRestricted}
          onSuccess={manuallySetProgress}
        />
      )}
      {progress === Progress.ENTER_PHONE && restricted && !modalLoading && (
        <Content>
          <Text variation="error">
            This action cannot be performed while in safe-mode.
          </Text>
        </Content>
      )}
      {progress === Progress.ENTER_PHONE && !restricted && (
        <VerifyOTPPhoneInput
          phone={phone}
          ref={inputRef}
          setDisabled={setDisabled}
          setParentPhone={setPhone}
          onSuccess={() => {
            setProgress(progress + 1);
          }}
        >
          <Text>
            {isForCommsVerification
              ? "Sending emails and SMS to your customers through Jobber requires enabling two-step verification using a trusted mobile phone number."
              : "Jobber payments requires enabling two-step verification using a trusted mobile phone number."}
            Two-step verification is{" "}
            <Emphasis variation="bold">
              designed to prevent anyone else from making important changes to
              your account,
            </Emphasis>{" "}
            even if they know your password.{" "}
            <a
              href={
                "https://help.getjobber.com/hc/en-us/articles/1500001226241"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </Text>{" "}
        </VerifyOTPPhoneInput>
      )}
      {progress === Progress.ENTER_CODE && (
        <OTPInput
          ref={inputRef}
          onSuccess={onAccessGranted}
          setSubmitting={setModalLoading}
          setDisabled={setDisabled}
        >
          <OTPText
            channel={channel}
            phoneLastDigits={phone.substr(phone.length - 4)}
          />
        </OTPInput>
      )}
    </Modal>
  );

  function manuallySetProgress() {
    setProgress(requiresSetup ? Progress.ENTER_PHONE : Progress.ENTER_CODE);
    setModalLoading(false);
  }

  function onAccessGranted() {
    if (requiresSetup) {
      // show toast if first time setup
      showToast({
        message: "Two-step verification is on.",
      });
      setRequiresSetup(false);
    }
    setModalLoading(false);

    // parent callback
    onSuccess();
  }

  function handleSubmit() {
    if (inputRef.current) inputRef.current.submit();
  }

  function handleResend() {
    if (inputRef?.current?.sendCode) {
      inputRef.current.sendCode();
    }
  }
}

interface GetUserVerificationDetailsProps {
  setPhone(value: string): void;
  setAuthenticated(value: boolean): void;
  setRestricted(value: boolean): void;
  setRequiresSetup(value: boolean): void;
  setChannel(value: string): void;
  onSuccess(): void;
}

// eslint-disable-next-line max-statements
function GetUserVerificationDetails({
  setPhone,
  setRequiresSetup,
  setChannel,
  setAuthenticated,
  setRestricted,
  onSuccess,
}: GetUserVerificationDetailsProps) {
  const { loading, error, data } = useQuery<GetUserTwoFactorDetailsQuery>(
    GET_USER_TWO_FACTOR_DETAILS_QUERY,
    {
      fetchPolicy: "network-only",
    },
  );

  const user = data?.account?.currentUser;
  const restricted = data?.account?.restricted || false;
  if (loading && !user) {
    return <div className="spinner spinner--inline" data-testid="spinner" />;
  }

  if (error || !user) {
    return <Text>Content could not be displayed</Text>;
  }

  setPhone(user.twoFactorDetails.temporaryPhone || user.contactInfo.phone);
  setRequiresSetup(!user.contactInfo.verifiedPhone);
  setChannel(user.contactInfo.verificationChannel);
  setRestricted(restricted);
  setAuthenticated(user.twoFactorAuthorized);

  setTimeout(() => {
    onSuccess();
  }, 1);

  return <></>;
}
