import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadImageModalTitle: {
    id: "campaigns.campaignContentPage.richTextContentEditor.uploadImageModal.title",
    defaultMessage: "Upload image",
    description: "Title of the upload images modal",
  },
  errorForEmptyBody: {
    id: "campaigns.error.emptyBody",
    defaultMessage: "Enter a body message",
    description: "Empty Body input error message",
  },
});
