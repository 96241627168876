import {
  AiAssistantActionIntent,
  type AiAssistantSubscriptionSubscription,
} from "~/utilities/API/graphql";

export function handleBackgroundActions(
  requestId: AiAssistantSubscriptionSubscription["aiAssistantMessages"]["requestId"],
  content: AiAssistantSubscriptionSubscription["aiAssistantMessages"]["content"],
) {
  if (content?.__typename !== "AiAssistantPromptResponse") {
    return;
  }

  for (const action of content.actions) {
    switch (action.intent) {
      case AiAssistantActionIntent.LIVE_HELP_DIALOG:
      case AiAssistantActionIntent.SHOW_SUPPORT_PILLS:
      case AiAssistantActionIntent.TRANSFER_TO_SUPPORT:
        // A request for live help is handled in the conversation list item.
        continue;
      default:
        // eslint-disable-next-line no-console
        console.error("Unknown chat action intent type", action.intent);
    }
  }
}
