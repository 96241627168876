//  Temporary token utilities until we improve theme token support soon.
import type { WebTokens } from "@jobber/design";

// Get Atlantis colors for dark/light mode from the document
/**
 * @param themeTokens Tokens from useAtlantisTheme
 */
export function getStripeFieldColorsFromTheme(themeTokens: WebTokens) {
  // Get colors from the theme and convert to HEX
  // Atlantis doesn't expose --field--value-color
  // so we use color-text--secondary which is what it maps to
  const fieldValueColor = mapThemeToHex(themeTokens["color-heading"]);
  const fieldPlaceholderColor = mapThemeToHex(
    themeTokens["color-text--secondary"],
  );
  const criticalColor = mapThemeToHex(themeTokens["color-critical"]);

  return {
    fieldValueColor,
    fieldPlaceholderColor,
    criticalColor,
  };
}

interface HslToHexMap {
  [key: string]: string;
}

function mapThemeToHex(themeColor: string): string | undefined {
  // Manually converted values
  const HslToHexMap: HslToHexMap = {
    "hsl(6, 64%, 51%)": "#d24232", // critical
    "hsl(210, 17%, 98%)": "#f9fafb", // field value dark
    "hsl(197, 90%, 12%)": "#032b3a", // field value light
    "hsl(196, 12%, 70%)": "#a9b7bc", // placeholder dark
    "hsl(197, 15%, 45%)": "#627a84", // placeholder light
  };

  return HslToHexMap[themeColor] || undefined;
}
