import React, { useContext } from "react";
import { Spinner } from "@jobber/components/Spinner";
import { Banner } from "@jobber/components/Banner";
import { Conversation } from "jobber/chat/components/Conversation";
import { useLookupConversationId } from "jobber/chat/components/SmsComposerPage/hooks/useLookupConversationId";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import type { Recipient } from "~/utilities/API/graphql";
import { getNameOrContactDetailTitle } from "jobber/chat/utilities/formatConversationTitle";
import type { SelectedRecipientType } from "jobber/chat/types";
import styles from "./SMSComposeMessageRenderer.module.css";

interface SMSComposeMessageRendererProps {
  /**
   * normalized phone number
   */
  readonly phoneNumber: string;
  afterSend?(): void;
}

export function SMSComposeMessageRenderer({
  phoneNumber,
  afterSend,
}: SMSComposeMessageRendererProps) {
  const { conversationId, loading, error } =
    useLookupConversationId(phoneNumber);
  const [, dispatch] = useContext(MessageCenterContext);

  function switchActiveViewToConversation(recipient: SelectedRecipientType) {
    if (dispatch && conversationId && recipient) {
      dispatch({
        type: "SHOW_CHAT",
        title: getNameOrContactDetailTitle(
          recipient.name,
          recipient.contactInfo,
        ),
        recipient: recipient,
        conversationId: conversationId,
      });
    }
  }

  function afterSendSwitchView(recipient: Recipient | undefined) {
    if (afterSend) {
      afterSend();
    }
    switchActiveViewToConversation({
      name: recipient?.name,
      contactInfo: recipient?.phoneNumber.friendly || "",
      id: recipient?.id,
    });
  }

  if (loading) {
    return (
      <div className={"row collapse align-center u-paddingTopLarger"}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorBanner}>
        <Banner type="error">
          {"This number cannot receive text messages."}
        </Banner>
      </div>
    );
  }

  if (conversationId) {
    return (
      <Conversation
        conversationId={conversationId}
        afterSend={afterSendSwitchView}
      />
    );
  }
  return <></>;
}
