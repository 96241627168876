import type { MockedResponse } from "@apollo/client/testing";
import { GET_DISPUTE_QUERY } from "jobber/features/PaymentDisputes/views/DisputeDetailsPage/DisputeDetailsPage.graphql";
import {
  type DisputeEditInput,
  DisputeEvidenceReason,
  EvidenceFile,
  type JobberPaymentsDispute,
} from "~/utilities/API/graphql";
import {
  DISPUTE_CLOSED_SUBSCRIPTION,
  DISPUTE_CLOSE_MUTATION,
  DISPUTE_EDIT_MUTATION,
  DISPUTE_UPDATED_SUBSCRIPTION,
} from "jobber/features/PaymentDisputes/hooks/graphql";
import { STRIPE_PROVIDER_CONTEXT } from "jobber/features/PaymentDisputes/hooks/useStripePublishableKey";
import { STRIPE_FILE_LINK_QUERY } from "jobber/features/PaymentDisputes//hooks/useStripeFileLink";

const baseDispute = {
  id: "MQ==",
  evidenceDueBy: "2020-12-22",
  disputedAt: "2020-12-21",
  amount: 234,
  reason: "CREDIT_NOT_PROCESSED",
  category: "UNKNOWN",
  status: "UNKNOWN",
  paymentRecord: {
    adjustmentType: "INVOICE",
    amount: 234,
    entryDate: "2020-10-23",
    id: "MQ==",
    client: {
      id: "MQ==",
      firstName: "john",
      lastName: "doe",
    },
    quote: {
      id: "MQ==",
      quoteNumber: "3942934",
    },
    invoice: {
      id: "MQ==",
      invoiceNumber: "35324434",
    },
  },
  issuerEvidences: {
    nodes: [
      {
        fileEvidence: ["file_1NVcuYKXLQIAg16L8NL0JBqB"],
        textEvidence: "test text evidence.",
        evidenceType: "CHARGEBACK",
      },
    ],
    pageInfo: {
      endCursor: "MQ",
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "MQ",
    },
    totalCount: 1,
  },
} as JobberPaymentsDispute;

export const mockEvidence = (): DisputeEditInput => ({
  clientInformation: {
    customerName: "John Doe",
    customerEmailAddress: "john.doe@getjobber.com",
    billingAddress: "123 Main St",
  },
  refundRefusalExplanation: "I already refunded the customer",
  evidenceReason: DisputeEvidenceReason.NON_REFUNDABLE,
  productDescription: "Test product",
  serviceDate: "2023-06-29T00:00:00.000Z",
  evidenceFilesInput: [
    {
      category: EvidenceFile.CUSTOMER_COMMUNICATION,
      name: "sample_0.png",
      stripeFileId: "sid_123",
    },
    {
      category: EvidenceFile.CUSTOMER_SIGNATURE,
      name: "sample_1.png",
      stripeFileId: "sid_123",
    },
  ],
});

export const mockInquiryNeedsResponseDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_NEEDS_RESPONSE",
} as JobberPaymentsDispute;

export const mockInquiryNeedsResponseTextEvidenceDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_NEEDS_RESPONSE",
  issuerEvidences: {
    nodes: [
      {
        textEvidence: "test text evidence",
      },
    ],
  },
} as JobberPaymentsDispute;

export const mockInquiryNeedsResponseFileEvidenceDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_NEEDS_RESPONSE",
  issuerEvidences: {
    nodes: [
      {
        fileEvidence: ["file_123"],
        textEvidence: "test text evidence",
      },
    ],
  },
} as JobberPaymentsDispute;

export const mockInquiryUnderReviewDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_UNDER_REVIEW",
  responseSubmittedAt: "2023-08-25",
} as JobberPaymentsDispute;

export const mockInquiryLostDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_CLOSED",
} as JobberPaymentsDispute;

export const mockInquiryLostTextEvidenceDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_CLOSED",
  issuerEvidences: {
    nodes: [
      {
        textEvidence: "test text evidence",
      },
    ],
  },
} as JobberPaymentsDispute;

export const mockInquiryLostFileEvidenceDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_CLOSED",
  issuerEvidences: {
    nodes: [
      {
        fileEvidence: ["file_123"],
        textEvidence: "test text evidence",
      },
    ],
  },
} as JobberPaymentsDispute;

export const mockInquiryRefundedDispute = {
  ...baseDispute,
  category: "INQUIRY",
  status: "WARNING_CLOSED",
  refundedAt: "2023-07-07",
} as JobberPaymentsDispute;

export const mockChargebackNeedsResponseDispute = {
  ...baseDispute,
  category: "CHARGEBACK",
  status: "NEEDS_RESPONSE",
} as JobberPaymentsDispute;

export const mockChargebackUnderReviewDispute = {
  ...baseDispute,
  category: "CHARGEBACK",
  status: "UNDER_REVIEW",
  responseSubmittedAt: "2023-08-25",
} as JobberPaymentsDispute;

export const mockChargebackWonDispute = {
  ...baseDispute,
  category: "CHARGEBACK",
  status: "WON",
  resolutionReceivedAt: "2023-07-07",
} as JobberPaymentsDispute;

export const mockChargebackLostDispute = {
  ...baseDispute,
  category: "CHARGEBACK",
  status: "LOST",
} as JobberPaymentsDispute;

export const mockSubmittedEvidenceDispute = {
  ...baseDispute,
  category: "CHARGEBACK",
  status: "UNDER_REVIEW",
  responseSubmittedAt: "2023-08-25",
  submittedEvidence: {
    serviceOccurrence: "2023-07-21",
    customerName: "Customer Name",
    billingAddress: "260 Queen St W 3rd Floor, Toronto, ON M5V 1Z8",
    productDescription: "Product Description",
    customerEmailAddress: "test@example.com",
    refundRefusalExplanation: "Additional Details",
    supportingEvidence: {
      nodes: [
        {
          category: "customer_communication",
          fileId: "file_121",
        },
        {
          category: "customer_signature",
          fileId: "file_122",
        },
        {
          category: "receipt",
          fileId: "file_123",
        },
        {
          category: "refund_policy",
          fileId: "file_124",
        },
        {
          category: "service_documentation",
          fileId: null,
        },
        {
          category: "uncategorized_file",
          fileId: null,
        },
      ],
    },
  },
} as JobberPaymentsDispute;

export const mockSubmittedEvidenceExpiredFilesDispute = {
  ...mockSubmittedEvidenceDispute,
  responseSubmittedAt: "2023-08-25",
} as JobberPaymentsDispute;

export const mockStripeSubmittedEvidenceDispute = {
  ...mockSubmittedEvidenceDispute,
  responseSubmittedAt: undefined,
} as JobberPaymentsDispute;

export const mockOldSubmittedEvidenceDispute = {
  ...mockSubmittedEvidenceDispute,
  responseSubmittedAt: "2023-08-20",
} as JobberPaymentsDispute;

export const mockSubmittedEvidenceNoSupportingEvidenceDispute = {
  ...mockSubmittedEvidenceDispute,
  submittedEvidence: {
    ...mockSubmittedEvidenceDispute.submittedEvidence,
    supportingEvidence: {
      nodes: [
        {
          category: "customer_communication",
        },
        {
          category: "customer_signature",
        },
        {
          category: "receipt",
        },
        {
          category: "refund_policy",
        },
        {
          category: "service_documentation",
        },
        {
          category: "uncategorized_file",
        },
      ],
    },
  },
} as JobberPaymentsDispute;

export const mockSubmittedEvidenceNoClientInformationDispute = {
  ...mockSubmittedEvidenceDispute,
  submittedEvidence: {
    serviceOccurrence: "2023-07-21",
    productDescription: "Product Description",
    refundRefusalExplanation: "Additional Details",
    supportingEvidence: {
      nodes: [
        {
          category: "customer_communication",
          fileId: "file_121",
        },
        {
          category: "customer_signature",
          fileId: "file_122",
        },
        {
          category: "receipt",
          fileId: "file_123",
        },
        {
          category: "refund_policy",
          fileId: "file_124",
        },
        {
          category: "service_documentation",
          fileId: null,
        },
        {
          category: "uncategorized_file",
          fileId: null,
        },
      ],
    },
  },
} as JobberPaymentsDispute;

export const disputeQueryMock = (
  dispute: JobberPaymentsDispute,
): MockedResponse => {
  return {
    request: {
      query: GET_DISPUTE_QUERY,
      variables: { id: "MQ==" },
    },
    result: {
      data: {
        jobberPaymentsDispute: dispute,
      },
    },
  };
};

export const disputeEditMutationMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_EDIT_MUTATION,
      variables: {
        id: baseDispute.id,
        input: mockEvidence(),
      },
    },
    newData: jest.fn(() => ({
      data: {
        disputeEdit: {
          status: "pending",
          userErrors: [],
        },
      },
    })),
    delay: 500,
  };
};

export const disputeEditMutationFailMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_EDIT_MUTATION,
      variables: {
        id: baseDispute.id,
        input: mockEvidence(),
      },
    },
    result: {
      data: {
        disputeEdit: {
          status: "failed",
          userErrors: [
            { message: "Mutation fail: Failed to update the dispute" },
          ],
        },
      },
    },
  };
};

export const disputeEditSubscriptionMock = (
  dispute: JobberPaymentsDispute,
): MockedResponse => {
  return {
    request: {
      query: DISPUTE_UPDATED_SUBSCRIPTION,
      variables: { disputeId: baseDispute.id },
    },
    result: {
      data: {
        disputeUpdated: {
          userErrors: [],
          dispute: dispute,
        },
      },
    },
    delay: 200,
  };
};

export const disputeEditSubscriptionFailMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_UPDATED_SUBSCRIPTION,
      variables: { disputeId: baseDispute.id },
    },
    result: {
      data: {
        disputeUpdated: {
          userErrors: [
            { message: "Subscription error: Failed to update dispute" },
          ],
        },
      },
    },
    delay: 200,
  };
};

export const disputeCloseMutationMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_CLOSE_MUTATION,
      variables: {
        id: baseDispute.id,
      },
    },
    result: {
      data: {
        disputeClose: {
          status: "pending",
          userErrors: [],
        },
      },
    },
  };
};

export const disputeCloseMutationFailMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_CLOSE_MUTATION,
      variables: {
        id: baseDispute.id,
      },
    },
    result: {
      data: {
        disputeClose: {
          status: "pending",
          userErrors: [{ message: "Failed to close the dispute" }],
        },
      },
    },
  };
};

export const disputeCloseSubscriptionMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_CLOSED_SUBSCRIPTION,
      variables: { disputeId: baseDispute.id },
    },
    result: {
      data: {
        disputeClosed: {
          userErrors: [],
          dispute: mockChargebackLostDispute,
        },
      },
    },
    delay: 200,
  };
};

export const disputeCloseSubscriptionFailMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_CLOSED_SUBSCRIPTION,
      variables: { disputeId: baseDispute.id },
    },
    result: {
      data: {
        disputeClosed: {
          userErrors: [{ message: "Failed to close the dispute" }],
        },
      },
    },
    delay: 200,
  };
};

export const disputeCloseMutationErrorMock = (): MockedResponse => {
  return {
    request: {
      query: DISPUTE_CLOSE_MUTATION,
      variables: {
        id: baseDispute.id,
      },
    },
    error: new Error("Failed to close the dispute"),
  };
};

export const stripeProviderContextMock = (): MockedResponse => {
  return {
    request: {
      query: STRIPE_PROVIDER_CONTEXT,
    },
    result: {
      data: {
        stripeProviderContext: {
          publishableKey: "test_stripe_pk",
          stripeAccountId: "test_stripe_account_id",
        },
      },
    },
  };
};

export const stripeFileLinkMock = (): MockedResponse => {
  return {
    request: {
      query: STRIPE_FILE_LINK_QUERY,
      variables: {
        stripeFileId: "file_123",
      },
    },
    result: {
      data: {
        stripeFileLink: {
          expiresAt: "2025-07-19T11:59:37-07:00",
          publicUrl: "https://example.com",
        },
      },
    },
  };
};

export const stripeFileLinkErrorMock = (): MockedResponse => {
  return {
    request: {
      query: STRIPE_FILE_LINK_QUERY,
      variables: {
        stripeFileId: "file_123",
      },
    },
    error: new Error("Failed to retrieve file"),
  };
};
