import { Amplitude } from "~/utilities/analytics/Amplitude";

export function trackCofCheckboxClicked(isChecked: boolean, source: string) {
  Amplitude.TRACK_EVENT("Clicked Toggle", {
    name: "cof_smart_defaults_checkbox",
    source: source,
    type: isChecked ? "off" : "on",
  });
}

export function trackAutopayToggleClicked(newRecord: boolean, value: boolean) {
  Amplitude.TRACK_EVENT("Clicked Toggle", {
    name: "autopay_smart_defaults_toggle",
    source: newRecord ? "new" : "edit",
    type: value ? "on" : "off",
  });
}
