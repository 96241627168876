/* eslint-disable no-restricted-imports */
import React from "react";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import type { CollectPaymentDialogProps } from "./CollectPaymentDialogProps.d";
import { HiddenField } from "../HiddenField";

export function HiddenFields(props: CollectPaymentDialogProps) {
  const { attachedToId, attachedToType, clientId, paymentOrigin } = props;
  const [authenticityToken] = useAuth();
  const attachedToTypeTitleCase = attachedToType.replace(
    attachedToType[0],
    attachedToType[0].toUpperCase(),
  );
  const adjustmentTypeTitleCase =
    attachedToType === "invoice" ? "Payment" : "Deposit";

  return (
    <>
      <HiddenField name="client_id" value={clientId} />
      {attachedToType === "quote" && (
        <>
          <HiddenField
            name="balance_adjustment[attached_to_id]"
            value={attachedToId}
          />
          <HiddenField
            name="balance_adjustment[attached_to_type]"
            value={attachedToTypeTitleCase}
          />
        </>
      )}
      <HiddenField name={`${attachedToType}_id`} value={attachedToId} />
      <HiddenField
        name="balance_adjustment[allocation_target_id]"
        value={attachedToId}
      />
      <HiddenField name="authenticity_token" value={authenticityToken} />
      <HiddenField
        name="balance_adjustment[payment_origin]"
        value={paymentOrigin}
      />
      <HiddenField
        name="balance_adjustment[adjustment_type]"
        value={adjustmentTypeTitleCase}
      />
      <HiddenField name="utf8" value="&#x2713;" />
    </>
  );
}
