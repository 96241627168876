import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const CAMPAIGN_CREATE_PAST_CLIENTS = gql`
  mutation campaignCreatePastClients(
    $input: PastClientsCampaignInput = {
      criteria: { interval: 12, unit: MONTHS }
    }
  ) {
    campaignCreatePastClients(input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
