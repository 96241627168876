import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { StarGroup } from "./StarGroup";
import type { StarGroupProps } from "./types";

export function StarGroupLoader(props: StarGroupProps) {
  return (
    <IntlProvider>
      <StarGroup {...props} />
    </IntlProvider>
  );
}
