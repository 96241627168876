import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { HiddenRecurlyFormInputs } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/HiddenRecurlyFormInputs";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";
import styles from "./BillingAddressDisplay.module.css";
import { messages } from "./messages";

interface BillingAddressDisplayProps {
  billingAddress: BillingAddressType;
}

export function BillingAddressDisplay(props: BillingAddressDisplayProps) {
  const { formatMessage } = useIntl();

  const { billingAddress } = props;
  const { email } = billingAddress;

  return (
    <Content>
      <Grid>
        <Grid.Cell size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Label text={formatMessage(messages.emailLabel)} />
          <Label text={formatMessage(messages.billingAddressLabel)} />
        </Grid.Cell>
        <Grid.Cell size={{ xs: 6, sm: 6, md: 9, lg: 9, xl: 9 }}>
          <div className={styles.addressRow}>
            <Text>{email}</Text>
          </div>
          <Address {...billingAddress} />
        </Grid.Cell>
      </Grid>
      <HiddenRecurlyFormInputs {...billingAddress} />
    </Content>
  );
}

function Label({ text }: { text: string }) {
  return (
    <div className={styles.addressRow}>
      <Text variation="subdued">{text}</Text>
    </div>
  );
}

function Address({
  address1,
  address2,
  city,
  state,
  zip,
  country,
}: Partial<BillingAddressType>) {
  return (
    <div className={styles.addressRow}>
      <Text>{address1}</Text>
      {address2 && <Text>{address2}</Text>}
      <Text>
        {[city, state, zip].filter(component => !!component).join(", ")}
      </Text>
      <Text>{country}</Text>
    </div>
  );
}
