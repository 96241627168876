import React from "react";
import type { EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import momentPlugin from "@fullcalendar/moment";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGrid from "@fullcalendar/scrollgrid";
import interactionPlugin, {
  type DateClickArg,
  type EventDragStopArg,
  type EventResizeDoneArg,
} from "@fullcalendar/interaction";
import classNames from "./DayView.module.css";
import { Event } from "./Event";

export const DEFAULT_EVENT_BACKGROUND_COLOUR = "#F4F4F4";
export const DEFAULT_EVENT_TEXT_COLOUR = "#657884";

const PLUGINS = [
  momentPlugin,
  resourceTimeGridPlugin,
  scrollGrid,
  interactionPlugin,
];

export interface DayViewProps {
  calendarRef: React.MutableRefObject<FullCalendar | null>;
  events: EventInput[];
  employees: {
    id: string;
    title: string;
  }[];
  selectedDate: Date;
  onSelectSlot: (arg: DateClickArg) => void;
  onDragSelection: (arg: EventDragStopArg["event"]) => void;
  onResizeSelection: (arg: EventResizeDoneArg["event"]) => void;
}

export function DayView({
  calendarRef,
  employees,
  events,
  onSelectSlot,
  onDragSelection,
  onResizeSelection,
  selectedDate,
}: DayViewProps) {
  return (
    <div className={classNames.container} data-testid="day-view-calendar">
      <FullCalendar
        initialDate={selectedDate}
        snapDuration={"00:15:00"}
        slotDuration={"00:30:00"}
        ref={calendarRef}
        schedulerLicenseKey="0361426819-fcs-1699556422"
        plugins={PLUGINS}
        initialView="resourceTimeGridDay"
        resources={employees}
        eventBorderColor="#E1E1E1"
        titleFormat="ddd MMM D, YYYY"
        events={events}
        eventBackgroundColor={DEFAULT_EVENT_BACKGROUND_COLOUR}
        eventTextColor={DEFAULT_EVENT_TEXT_COLOUR}
        allDayText="Any time"
        dayMinWidth={128}
        nowIndicator={true}
        eventContent={renderEventContent}
        headerToolbar={false}
        scrollTimeReset={true}
        selectable={false}
        height={"auto"}
        dateClick={onSelectSlot}
        eventDrop={e => {
          onDragSelection(e.event);
        }}
        eventResize={e => {
          onResizeSelection(e.event);
        }}
        eventResizableFromStart={true}
        eventStartEditable={true}
      />
    </div>
  );
}

export function renderEventContent({ event }: EventInput) {
  const eventId = event.id;
  const isAnytime = event.allDay;
  const isShortAppointment = !!event.extendedProps.isShortAppointment;
  const isAvailabilitySlot = !!event.extendedProps.isAvailabilitySlot;
  const title = event.title;
  const description = event.extendedProps.primaryDescription;

  return (
    <Event
      type={
        isAnytime
          ? "anytime"
          : isAvailabilitySlot
            ? "availabilitySlot"
            : isShortAppointment
              ? "short"
              : "appointment"
      }
      eventId={eventId}
      event={event}
      title={title}
      description={description}
    />
  );
}
