import React from "react";
import { Text } from "@jobber/components/Text";
import { Content, InputValidation, Option, Select } from "@jobber/components";
import classNames from "classnames";
import { BaseReadOnlyCustomField } from "./BaseReadCustomField";
import styles from "./CustomFieldTypes.module.css";
import type { SelectValue } from "../types";

interface CustomFieldEditProps {
  value: SelectValue;
  editing: boolean;
  onChange(newValue: SelectValue): void;
}

interface CustomFieldSelectProps {
  isInAppGroup?: boolean;
  label: string;
  stringValue: string;
  editProps?: CustomFieldEditProps;
  error?: string;
}

export function CustomFieldSelect({
  isInAppGroup = false,
  label,
  stringValue,
  editProps,
  error,
}: CustomFieldSelectProps) {
  if (editProps?.editing) {
    return (
      <div className={classNames([styles.container])} title="container">
        <div
          className={classNames({
            [styles.label]: true,
            [styles.labelEdit]: true,
            [styles.inAppGroup]: isInAppGroup,
          })}
          title="label"
        >
          <Text>{label}</Text>
        </div>
        <div
          className={classNames([styles.value, styles.valueEdit])}
          title="value"
        >
          <Content spacing={"minuscule"}>
            <Select
              size="small"
              value={editProps.value.selection}
              onChange={onSelectChange}
              invalid={!!error}
            >
              {editProps.value.options.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
            {error && <InputValidation message={error} />}
          </Content>
        </div>
      </div>
    );
  } else {
    return BaseReadOnlyCustomField(isInAppGroup, label, stringValue);
  }

  function onSelectChange(newSelection: string) {
    editProps?.onChange({
      selection: newSelection,
      options: editProps.value.options,
    });
  }
}
