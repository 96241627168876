import React from "react";
import styles from "./ElongatedCircle.module.css";

export function ElongatedCircle({ children }: { children: React.ReactNode }) {
  return (
    <span className={styles.ElongatedCircleWrapper}>
      <svg
        viewBox="0 0 160 58"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.circle}
      >
        <path
          d="M136.781 12.9726C100.47 4.71676 71.2038 -0.329993 38.8053 8.69841C22.6335 14.145 12.8557 17.1831 9.44635 29.774C4.50754 48.0131 45.0626 53.6213 52.1103 53.7827C69.3392 54.1773 91.1832 56.2069 107.535 52.1278C117.906 49.5407 155.251 43.4048 155.536 28.494C155.757 20.0116 142.71 11.5554 137.798 9.62368C119.382 2.38008 94.4077 0.166351 76.5102 0.998074C58.9872 1.81239 40.0215 2.52293 24.46 10.9653C17.8759 14.5374 10.4721 20.2049 7.88638 29.228C6.1465 35.2994 9.1245 41.1456 13.8392 44.4223C19.5241 48.3734 25.8387 49.8067 31.8405 51.2578C57.3106 57.4158 82.7339 58.6892 107.066 54.2403C116.277 52.5562 162.607 47.5981 158.242 26.8417C156.519 18.6464 147.407 15.9076 142.431 14.4329C132.515 11.4938 122.517 10.0923 112.616 8.7915C101.571 7.34038 90.5346 6.05931 79.5979 5.95011C70.0679 5.85495 31.1538 1.65829 1.49394 25.6053"
          stroke="#84EA00"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {children}
    </span>
  );
}
