import { useCallback, useState } from "react";
import type { GeoPoint } from "~/utilities/API/graphql";

interface AddressLocation {
  address?: string;
  location?: Pick<GeoPoint, "latitude" | "longitude">;
}

export function useLinkedAddressLocation({
  address: initialAddress,
  location: initialLocation,
}: AddressLocation) {
  const getInitialAddress = () => {
    if (initialAddress) {
      return initialAddress;
    } else {
      const latitude = initialLocation?.latitude || 37.09024;
      const longitude = initialLocation?.longitude || -95.712891;
      return getAddressFromLocation(latitude, longitude);
    }
  };
  const [address, setAddress] = useState(getInitialAddress);
  const [location, setLocation] = useState(initialLocation);

  const setAddressLocation = useCallback((addressLocation: AddressLocation) => {
    setAddress(addressLocation.address ?? "");

    if (addressLocation.location) {
      setLocation(addressLocation.location);
    }
  }, []);

  return {
    address,
    location,
    setAddressLocation,
  };
}

function getAddressFromLocation(latitude: number, longitude: number): string {
  return `${convertCoordinate(latitude, false)} ${convertCoordinate(
    longitude,
    true,
  )}`;
}

function convertCoordinate(coord: number, isLongitude: boolean): string {
  const coordinate = Math.abs(coord);
  const degrees = Math.floor(coordinate);
  const minutes = Math.floor((coordinate - degrees) * 60);
  const seconds = ((coordinate - degrees - minutes / 60) * 3600).toFixed(1);

  const direction = isLongitude
    ? coord < 0
      ? "W"
      : "E"
    : coord < 0
      ? "S"
      : "N";

  return `${degrees}°${paddedNumber(minutes, 2)}'${seconds}"${direction}`;
}

function paddedNumber(num: number, length: number): string {
  return num.toString().padStart(length, "0");
}
