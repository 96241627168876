import { Page } from "@jobber/components/Page";
import React, { useEffect, useState } from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Markdown } from "@jobber/components/Markdown";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { messages } from "jobber/settings/locationTimersSettings/messages";
import { LocationTimersMode } from "~/utilities/API/graphql";
import styles from "./LocationTimersSettings.module.css";
import { useLocationTimersFeatureSwitchMutation } from "./useLocationTimersFeatureSwitchMutation";
import type { LocationTimerSettingsPageProps } from "./LocationTimersSettingsPage.loader";
import { useLocationTimersSettingsEditMutation } from "./useLocationTimersSettingsEditMutation";

export function LocationTimersSettingsPage({
  isAvailable,
  isEnabled: initialIsEnabled,
  mode: initialMode,
  enableOnLoad,
}: LocationTimerSettingsPageProps): JSX.Element {
  const [isEnabled, setFeatureEnabled] = useState(initialIsEnabled);
  const [mode, setMode] = useState<LocationTimersMode>(initialMode);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { switchMutation } = useLocationTimersFeatureSwitchMutation();
  const { editMutation } = useLocationTimersSettingsEditMutation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTimeout(() => {
      enableOnLoad && setFeatureEnabled(true);
    }, 1000);
  }, [enableOnLoad]);

  async function handleSwitch(enabled: boolean): Promise<void> {
    if (enabled) {
      setFeatureEnabled(enabled);
      switchMutation({
        variables: {
          enabled: true,
        },
      }).catch(() => {
        showErrorToast();
        setFeatureEnabled(false);
      });
    } else {
      setConfirmationModalOpen(true);
    }
  }

  async function handleConfirm(): Promise<void> {
    setFeatureEnabled(false);
    switchMutation({
      variables: {
        enabled: false,
      },
    }).catch(() => {
      showErrorToast();
      setFeatureEnabled(true);
    });
  }

  async function handleModeChange(value: LocationTimersMode): Promise<void> {
    setMode(value);
    editMutation({
      variables: {
        input: {
          mode: value,
        },
      },
    })
      .then(() => {
        showToast({
          message: formatMessage(messages.modeUpdateToastMessage),
          variation: "success",
        });
      })
      .catch(() => {
        showErrorToast();
        setMode(
          value === LocationTimersMode.AUTOMATED
            ? LocationTimersMode.REMINDER
            : LocationTimersMode.AUTOMATED,
        );
      });
  }

  function showErrorToast(): void {
    showToast({
      message: formatMessage(messages.updateErrorMessage),
      variation: "error",
    });
  }

  return (
    <Page title={formatMessage(messages.locationServicesHeader)} width="fill">
      <Card header={formatMessage(messages.locationTimersCardTitle)}>
        <Content>
          <FeatureSwitch
            description={formatMessage(messages.locationTimersCardBody1)}
            hasSaveIndicator={true}
            disabled={!isAvailable}
            enabled={isEnabled}
            onSwitch={handleSwitch}
          ></FeatureSwitch>
          <div className={styles.radioContainer}>
            <RadioGroup
              onChange={handleModeChange}
              value={mode}
              ariaLabel="Mode"
            >
              <RadioOption
                value="AUTOMATED"
                label={formatMessage(messages.locationTimersAutoModeLabel)}
                disabled={!isEnabled}
              />
              <RadioOption
                value="REMINDER"
                label={formatMessage(messages.locationTimersReminderModeLabel)}
                disabled={!isEnabled}
              />
            </RadioGroup>
          </div>
          <div className={styles.subduedText}>
            <Markdown
              content={formatMessage(messages.locationTimersCardOptionalText)}
              externalLink={true}
            />
          </div>
        </Content>
      </Card>
      <ConfirmationModal
        title={formatMessage(messages.locationTimersConfirmationModalTitle)}
        message={formatMessage(messages.locationTimersConfirmationModalBody)}
        open={confirmationModalOpen}
        confirmLabel={formatMessage(
          messages.locationTimersConfirmationModalConfirmationButton,
        )}
        onConfirm={handleConfirm}
        onCancel={() => setConfirmationModalOpen(false)}
        onRequestClose={() => setConfirmationModalOpen(false)}
        variation={"destructive"}
      />
    </Page>
  );
}
