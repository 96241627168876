import React, { type ComponentType, type SyntheticEvent } from "react";
import CreditCardListItem from "jobber/payments_sca/components/CreditCardListItem";
import type { CreditCardListItemProps } from "jobber/payments_sca/components/CreditCardListItem";
import {
  RadioListItem as DefaultRadioListItem,
  type RadioListItemProps,
} from "jobber/payments_sca/components/RadioListItem";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";

export interface VaultedPaymentMethodSelectorProps {
  paymentMethods: CreditCard[];
  selectedPaymentMethodId?: string;

  PaymentMethodListItem?: ComponentType<CreditCardListItemProps>;
  RadioListItem?: ComponentType<RadioListItemProps>;

  onSelectVaultedPaymentMethod?(vaultedPaymentMethodId: string): void;

  onShowCreditCardForm?(): void;
}

const radioName = "VaultedPaymentMethodSelector";
const useNewCreditCardId = "use-new-credit-card";

export function VaultedPaymentMethodSelector(
  props: VaultedPaymentMethodSelectorProps,
) {
  const {
    paymentMethods,
    onShowCreditCardForm,
    selectedPaymentMethodId,
    onSelectVaultedPaymentMethod,
    PaymentMethodListItem = CreditCardListItem,
    RadioListItem = DefaultRadioListItem,
  } = props;

  const onChange = (e: SyntheticEvent) => {
    const id = (e.target as HTMLElement).id;
    const isUsingNewCreditCard = id === useNewCreditCardId;
    if (isUsingNewCreditCard) {
      if (onShowCreditCardForm) {
        onShowCreditCardForm();
      }
    } else {
      if (onSelectVaultedPaymentMethod) {
        onSelectVaultedPaymentMethod(id);
      }
    }
  };

  return (
    <ul className="list list--dividers">
      {paymentMethods.map(card => {
        const checked = selectedPaymentMethodId === card.id;

        return (
          <RadioListItem
            key={card.id}
            id={card.id}
            value={card.id}
            name={radioName}
            checked={checked}
            onChange={onChange}
          >
            <PaymentMethodListItem {...card} />
          </RadioListItem>
        );
      })}
      <RadioListItem
        id={useNewCreditCardId}
        value={useNewCreditCardId}
        name={radioName}
        checked={!selectedPaymentMethodId}
        onChange={onChange}
      >
        Use another payment method
      </RadioListItem>
    </ul>
  );
}
