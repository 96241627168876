import { Icon } from "@jobber/components/Icon";
import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { messages as SettingsPageMessages } from "jobber/settings/selfServeBookings/views/SettingsPage/messages";
import styles from "./styles.module.css";

export function Breadcrumb() {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.breadcrumbs}>
      <Link to="../">
        <Icon name="arrowLeft" color="interactive" />
        {formatMessage(SettingsPageMessages.header)}
      </Link>
    </div>
  );
}
