import { gql } from "@apollo/client";

export const ENROL_DOWNGRADE_SAVE_PROMO = gql`
  mutation EnrolSelfServeDowngradeSavePromo {
    enrolSelfServeDowngradeSavePromo {
      promotionEnrolment {
        id
        promotion {
          name
        }
      }
      userErrors {
        message
      }
    }
  }
`;
