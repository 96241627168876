import { defineMessages } from "react-intl";

export const messages = defineMessages({
  lineItemsTotalCost: {
    id: "workOrders.jobCost.lineItemsTable.lineItemsTotalCost",
    defaultMessage: "Total Cost",
    description: "The total cost of all line items",
  },
  lineItemsTotalPrice: {
    id: "workOrders.jobCost.lineItemsTable.lineItemsTotalPrice",
    defaultMessage: "Total Price",
    description: "The total price of all line items",
  },
});
