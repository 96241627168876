import { defineMessages } from "react-intl";

export const messages = defineMessages({
  lineItemPriceTitle: {
    id: "workOrders.jobCost.tooltipComponent.LineItemPriceTitle",
    defaultMessage: "Line item price",
    description: "Line item price title in TooltipComponent",
  },
  visitsTitle: {
    id: "workOrders.jobCost.tooltipComponent.Visits",
    defaultMessage: "Visits",
    description:
      "Title in recurring job tooltipComponent for visit-based billing strategy",
  },
  invoiceReminders: {
    id: "workOrders.jobCost.tooltipComponent.InvoiceReminders",
    defaultMessage: "Invoice reminders",
    description:
      "Title in recurring job tooltipComponent for invoice reminder-based billing strategy",
  },
  revenueTitle: {
    id: "workOrders.jobCost.tooltipComponent.RevenueTitle",
    defaultMessage: "Revenue",
    description: "Revenue title in TooltipComponent",
  },
  variesKeyword: {
    id: "workOrders.jobCost.tooltipComponent.Varies",
    defaultMessage: "varies",
    description: "Varies keyword in TooltipComponent",
  },
});
