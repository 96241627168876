import React from "react";
import { Button, Icon, Tooltip } from "@jobber/components";
import type { FieldType } from "./RuleExpression";
import { RuleExpression } from "./RuleExpression";
import styles from "./RuleConditionBlock.module.css";
import type { RuleDropdownProps } from "./RuleDropdown";
import type { RuleConditionText } from "./types";

interface RuleConditionBlockProps {
  rowKey: number;
  lastBlock: boolean;
  text: RuleConditionText;
  removeRuleCondition?: (rowKey: number) => void;
  fields: {
    [key: string]: {
      fieldType: FieldType;
      argument: string | number | boolean;
      options?: RuleDropdownProps["options"];
      readOnly?: boolean;
      setArgument?: (val: string | number | boolean) => void;
    };
  };
  hasSubmitted?: boolean;
  isNonDeletable?: boolean;
}

export function RuleConditionBlock({
  rowKey,
  lastBlock,
  text,
  fields,
  removeRuleCondition,
  hasSubmitted,
  isNonDeletable,
}: RuleConditionBlockProps) {
  const conditionText = lastBlock ? `${text.text} then` : text.text;
  return (
    <div className={styles.inputContainer}>
      {!lastBlock && <div className={styles.bar} />}
      {lastBlock && (
        <div className={styles.arrowContainer}>
          <Icon name="longArrowDown" color="heading" />
        </div>
      )}
      <div className={styles.conditionContainer}>
        <div className={styles.conditionInternalContainer}>
          <div className={styles.conditionRuleContainer}>
            <RuleExpression
              text={conditionText}
              fields={fields}
              hasSubmitted={hasSubmitted}
            />
            {text.tooltip && (
              <div className={styles.toolTipContainer}>
                <Tooltip message={text.tooltip} preferredPlacement={"bottom"}>
                  <Icon name={"help"} size={"small"} />
                </Tooltip>
              </div>
            )}
          </div>
          {!isNonDeletable && (
            <div>
              <Button
                variation="destructive"
                type="tertiary"
                icon="trash"
                ariaLabel="remove condition rule"
                onClick={() => {
                  removeRuleCondition?.(rowKey);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
