import React from "react";
import { Text } from "@jobber/components/Text";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Page } from "@jobber/components/Page";
import {
  type Control,
  Controller,
  type UseFormClearErrors,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { InputValidation } from "@jobber/components/InputValidation";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import styles from "./CardHolderWatchListReviewUploadForm.module.css";
import { messages } from "./messages";
import { InputImageWithDisplay } from "./components/InputImageWithDisplay/InputImageWithDisplay";
import type { CardHolderWatchListReviewUploadFormState } from "../../types";

const FRONT_SIDE_NAME = "frontSide";
const BACK_SIDE_NAME = "backSide";

interface CardHolderWatchListReviewUploadFormProps {
  cardHolderName?: string;
  onFormSubmit: () => Promise<void>;
  loading: boolean;
  control: Control<CardHolderWatchListReviewUploadFormState>;
  clearErrors: UseFormClearErrors<CardHolderWatchListReviewUploadFormState>;
}
export function CardHolderWatchListReviewUploadForm({
  cardHolderName,
  onFormSubmit,
  loading,
  control,
  clearErrors,
}: CardHolderWatchListReviewUploadFormProps) {
  const { formatMessage } = useIntl();
  const [ref, { width }] = useResizeObserver<HTMLDivElement>();
  const isSmallBreakpoint = !width || width < Breakpoints.small;

  const introText =
    formatMessage(messages.uploadDocumentsSubHeading1) +
    `**${cardHolderName}**` +
    formatMessage(messages.uploadDocumentsSubHeading2);

  return (
    <div ref={ref} className={styles.pageContainer}>
      <Page
        title={formatMessage(messages.uploadDocumentsHeading)}
        intro={introText}
        width="narrow"
      >
        <Card header={formatMessage(messages.uploadFrontDocumentHeader)}>
          <Content>
            <Text>{formatMessage(messages.uploadFrontDocumentLabel)}</Text>
            <Controller
              name={FRONT_SIDE_NAME}
              control={control}
              rules={{
                required: formatMessage(messages.uploadFrontDocumentRequired),
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputImageWithDisplay
                    value={field.value}
                    onChange={(file?: File) => {
                      clearErrors(FRONT_SIDE_NAME);
                      field.onChange(file);
                    }}
                    setError={(message: string) => {
                      control.setError(FRONT_SIDE_NAME, {
                        type: "custom",
                        message,
                      });
                    }}
                    testId="frontSideInput"
                  />

                  {fieldState.error?.message && (
                    <InputValidation message={fieldState.error?.message} />
                  )}
                </>
              )}
            />
          </Content>
        </Card>
        <Card header={formatMessage(messages.uploadBackDocumentHeader)}>
          <Content>
            <Text>{formatMessage(messages.uploadBackDocumentLabel)}</Text>
            <Controller
              name={BACK_SIDE_NAME}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <InputImageWithDisplay
                    value={field.value}
                    onChange={(file?: File) => {
                      clearErrors(BACK_SIDE_NAME);
                      field.onChange(file);
                    }}
                    setError={(message: string) => {
                      control.setError(BACK_SIDE_NAME, {
                        type: "custom",
                        message,
                      });
                    }}
                    testId="backSideInput"
                  />

                  {fieldState.error?.message && (
                    <InputValidation message={fieldState.error?.message} />
                  )}
                </>
              )}
            />
          </Content>
        </Card>

        <div className={styles.sectionContainer}>
          <Button
            variation="work"
            type="primary"
            size={isSmallBreakpoint ? "base" : "large"}
            label={formatMessage(messages.submitButtonLabel)}
            fullWidth={false}
            loading={loading}
            onClick={onFormSubmit}
          />
        </div>
      </Page>
    </div>
  );
}
