import { useMemo } from "react";
import { useCommsCampaignDefaultTemplateQuery } from "jobber/campaigns/hooks";
import { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import {
  useUpdateAllClientSegment,
  useUpdatePastClientSegment,
  useUpdateUpcomingClientSegment,
} from "./hooks";
import type { UseHandleUpdatedClientSegmentArgs } from "./types";

export function useHandleUpdatedClientSegment({
  clientSegment,
  segmentType,
}: UseHandleUpdatedClientSegmentArgs) {
  const {
    campaignContent: { templateType },
  } = useCampaignWizardContext();

  const { data, loading: loadingDefaultTemplate } =
    useCommsCampaignDefaultTemplateQuery({
      skip: !!clientSegment?.id,
      defaultTemplateType: templateType,
    });

  const defaultTemplate = useMemo(
    () => ({
      body: data?.commsCampaignDefaultTemplate?.body || "",
      subject: data?.commsCampaignDefaultTemplate?.subject || "",
      header: data?.commsCampaignDefaultTemplate?.header || "",
      type: templateType,
      ctaVisible: data?.commsCampaignDefaultTemplate?.ctaVisible || false,
      showLogo: data?.commsCampaignDefaultTemplate?.showLogo || true,
    }),
    [data, templateType],
  );

  const updateAllClientsSegment = useUpdateAllClientSegment({
    clientSegment,
    defaultTemplate,
  });

  const updatePastClientSegment = useUpdatePastClientSegment({
    clientSegment,
    defaultTemplate,
  });

  const updateUpcomingClientSegment = useUpdateUpcomingClientSegment({
    clientSegment,
    defaultTemplate,
  });
  const updateClientSegment = useMemo(() => {
    switch (segmentType) {
      case Segment.ALL_CLIENTS:
        return updateAllClientsSegment;
      case Segment.PAST_CLIENTS:
        return updatePastClientSegment;
      case Segment.UPCOMING_CLIENTS:
        return updateUpcomingClientSegment;
    }
  }, [
    segmentType,
    updateAllClientsSegment,
    updatePastClientSegment,
    updateUpcomingClientSegment,
  ]);

  return {
    updateClientSegment,
    loadingDefaultTemplate,
  };
}
