import type {
  BankAccountPaymentMethod,
  CreditCardPaymentMethod,
  PaymentMethodInterface,
} from "~/utilities/API/graphql";
import type { cardBrandsToImagePathMap } from "components/CardBrand/CardBrand";

export enum ExpiryStatus {
  DEFAULT = "default",
  EXPIRED = "expired",
  EXPIRING_SOON = "expiring_soon",
}
export interface ServerCreditCard {
  uuid: string;
  last4: string;
  brand: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  default_method: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expiry_year: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expiry_month: number;
  expired: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expiring_soon: boolean;
  fingerprint: string;
}

export interface CreditCard {
  id: string;
  brand?: keyof typeof cardBrandsToImagePathMap;
  last4?: string;
  isDefault?: boolean;
  expiryMonth?: number;
  expiryYear?: number;
  expiryStatus?: ExpiryStatus;
  fingerprint?: string;
}

export async function loadPaymentMethods(url: string): Promise<CreditCard[]> {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Please try again");
  }

  const rawServerResponse = (await response.json()) as ServerCreditCard[];

  return rawServerResponse.map(mapServerCreditCard);
}

export function mapServerCreditCard(card: ServerCreditCard): CreditCard {
  return {
    id: card.uuid,
    brand: mapBrand(card.brand),
    last4: card.last4,
    isDefault: card.default_method,
    expiryMonth: card.expiry_month,
    expiryYear: card.expiry_year,
    fingerprint: card.fingerprint,
    expiryStatus: mapExpiry(card),
  };
}

function mapBrand(paymentMethodBrand: string): CreditCard["brand"] {
  switch (paymentMethodBrand) {
    case "American Express":
      return "amex";
    case "Discover":
      return "discover";
    case "JCB":
      return "jcb";
    case "MasterCard":
      return "mastercard";
    case "Visa":
      return "visa";
    case "Unknown":
      return "unknown";
    default:
      // `paymentMethodBrand` can be both `American Express` and `amex`
      // needs to support both variants
      return paymentMethodBrand as CreditCard["brand"];
  }
}

function mapExpiry(card: ServerCreditCard): ExpiryStatus {
  const { expired, expiring_soon: expiringSoon } = card;
  if (expired) {
    return ExpiryStatus.EXPIRED;
  }
  if (expiringSoon) {
    return ExpiryStatus.EXPIRING_SOON;
  }

  return ExpiryStatus.DEFAULT;
}

interface DeletePaymentMethodParams {
  id: string;
  paymentMethodPath: string;
  staticServerFields: { [key: string]: unknown };
}

interface ErrorCardResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_message: string;
  error: boolean;
}

export async function deletePaymentMethod(params: DeletePaymentMethodParams) {
  const { id, paymentMethodPath, staticServerFields } = params;

  const url = paymentMethodPath.replace(":id", id);
  const response = await fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(staticServerFields),
  });

  if (!response.ok) {
    const data = (await response.json()) as ErrorCardResponse;
    throw new Error(`${data.error_message}`);
  }

  return undefined;
}

interface SetDefaultPaymentMethodParams {
  id: string;
  paymentMethodPath: string;
  staticServerFields: { [key: string]: unknown };
}

export async function setDefaultPaymentMethod(
  params: SetDefaultPaymentMethodParams,
) {
  const { id, paymentMethodPath, staticServerFields } = params;

  const url = paymentMethodPath.replace(":id", id);
  const response = await fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      default_method: true,
      ...staticServerFields,
    }),
  });

  if (!response.ok) throw new Error(`${response.status}`);

  return undefined;
}

export function isBankAccountPaymentMethod(
  paymentMethod: PaymentMethodInterface,
): paymentMethod is BankAccountPaymentMethod {
  return (
    (paymentMethod as BankAccountPaymentMethod)?.__typename ===
      "BankAccountPaymentMethod" ||
    ((paymentMethod as BankAccountPaymentMethod)?.bankName !== undefined &&
      (paymentMethod as BankAccountPaymentMethod)?.bankName !== null)
  );
}

export function isCreditCardPaymentMethod(
  paymentMethod: PaymentMethodInterface,
): paymentMethod is CreditCardPaymentMethod {
  return (
    (paymentMethod as CreditCardPaymentMethod)?.__typename ===
      "CreditCardPaymentMethod" ||
    !!(paymentMethod as CreditCardPaymentMethod)?.brand ||
    !!(paymentMethod as CreditCardPaymentMethod)?.expiryMonth ||
    !!(paymentMethod as CreditCardPaymentMethod)?.expiryYear
  );
}
