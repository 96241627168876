import type { ApolloClient } from "@apollo/client";
import {
  PLAID_LINK_ACCOUNT_TOKEN_QUERY,
  PLAID_LINK_PAYMENT_TOKEN_QUERY,
} from "./PlaidLinkTokenQuery";

interface PlaidLinkAccountTokenType {
  plaidLinkAccountToken: {
    linkToken: string;
  };
}

interface PlaidLinkPaymentTokenType {
  plaidLinkPaymentToken: {
    linkToken: string;
  };
}

export interface FetchPlaidLinkTokenParams {
  apolloClient: ApolloClient<object>;
  onSuccess(token: string): void;
  onError?(errorMessage: string): void;
}

export async function fetchPlaidLinkAccountToken({
  apolloClient,
  onSuccess,
  onError,
}: FetchPlaidLinkTokenParams) {
  let linkToken: string;

  try {
    const { data } = await apolloClient.query<PlaidLinkAccountTokenType>({
      query: PLAID_LINK_ACCOUNT_TOKEN_QUERY,
      variables: { platform: "WEB" },
      fetchPolicy: "no-cache",
    });

    linkToken = data.plaidLinkAccountToken?.linkToken;

    if (!linkToken) {
      throw new Error("Plaid Link Token empty");
    } else {
      localStorage.setItem("plaidLinkToken", linkToken);
      onSuccess(linkToken);
    }
  } catch (err) {
    if (!onError) {
      return;
    }
    onError("Could not establish a secure connection. Please try again.");
  }
}

export async function fetchPlaidLinkPaymentToken({
  apolloClient,
  onSuccess,
  onError,
}: FetchPlaidLinkTokenParams) {
  let linkToken: string;

  try {
    const { data } = await apolloClient.query<PlaidLinkPaymentTokenType>({
      query: PLAID_LINK_PAYMENT_TOKEN_QUERY,
      fetchPolicy: "no-cache",
    });

    linkToken = data.plaidLinkPaymentToken?.linkToken;

    if (!linkToken) {
      throw new Error("Plaid Link Token empty");
    } else {
      localStorage.setItem("plaidLinkToken", linkToken);
      onSuccess(linkToken);
    }
  } catch (err) {
    if (!onError) {
      return;
    }
    onError("Could not establish a secure connection. Please try again.");
  }
}
