import { DisputeEvidenceReason } from "~/utilities/API/graphql";

export const CounterDisputeReasonText = {
  [DisputeEvidenceReason.WITHDREW]: "Client withdrew the dispute",
  [DisputeEvidenceReason.REFUNDED]: "Client was refunded",
  [DisputeEvidenceReason.NON_REFUNDABLE]: "Transaction was non-refundable",
  [DisputeEvidenceReason.LATE_REQUEST]:
    "Refund or cancellation request was made after the date allowed by your terms",
  [DisputeEvidenceReason.SATISFIED]:
    "Client received the product or service as advertised",
  [DisputeEvidenceReason.CREDIT]: "Client received a credit or voucher",
  [DisputeEvidenceReason.GOVT_DELAY]:
    "Service delayed due to a government order or other reason",
  [DisputeEvidenceReason.UNIQUE_PURCHASE]:
    "Purchase is unique and not a duplicate",
  [DisputeEvidenceReason.OTHER]: "Other",
};

export const COUNTER_DISPUTE_REASON_HEADING =
  "Why should you win this dispute?";
export const PRODUCT_OR_SERVICE_DETAILS_HEADING = "Product or service details";
export const ADDITIONAL_DETAILS_HEADING = "Additional details";
export const SUPPORTING_EVIDENCE_HEADING = "Supporting evidence";
export const CLIENT_INFORMATION_HEADING = "Client information";
export const UPLOAD_SUPPORTING_EVIDENCE_HEADING = "Upload supporting evidence";

export const SupportingEvidenceFileCategories: Record<string, string> = {
  ["receipt"]: "Receipt",
  ["refund_policy"]: "Refund policy",
  ["customer_signature"]: "Client signature",
  ["uncategorized_file"]: "Other",
  ["service_documentation"]: "Proof of service",
  ["customer_communication"]: "Client communication",
};
