import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Card } from "@jobber/components/Card";
import classNames from "classnames";
import { WorkItemModal } from "jobber/workItems/components/WorkItemModal";
import type { ProductOrServiceFragment } from "~/utilities/API/graphql";
import { useAccountConfigurationContext } from "jobber/settings/selfServeBookings/components/";
import type { WorkItem } from "jobber/workItems/types";
import { SELF_SERVE_BOOKINGS_SERVICES_QUERY } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/hooks/graphql";
import { PRODUCT_OR_SERVICE_LIST } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/ProductAndServiceSearchModal/hooks/graphql";
// eslint-disable-next-line import/no-internal-modules
import { SELF_SERVE_SETTINGS_QUERY } from "legacy/jobber/settings/selfServeBookings/views/SettingsPage/hooks/graphql";
import {
  ListLoadingWrapper,
  SearchList,
  SearchOrCreateHeader,
} from "./components";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { useProductAndServiceSearch } from "./hooks";

interface ProductAndServiceSearchModalProps {
  open: boolean;
  closeSearchModal(): void;
}

export function ProductAndServiceSearchModal({
  open,
  closeSearchModal,
}: ProductAndServiceSearchModalProps) {
  const accountContext = useAccountConfigurationContext();
  const {
    productsAndServices,
    loading,
    searching,
    loadingNextPage,
    error,
    search,
    nextPage,
  } = useProductAndServiceSearch(
    accountContext.features.advancedQuotingEnabled,
    accountContext.features.quoteMarginsEnabled,
  );

  const [workItemModalOpen, setWorkItemModalOpen] = useState(false);

  const [selectedWorkItem, setSelectedWorkItem] =
    useState(defaultNewWorkItem());

  const saveLabel = selectedWorkItem.id
    ? messages.updateAndAdd.title
    : messages.createAndAdd.title;

  const modalTitle = selectedWorkItem.id
    ? `${messages.add.title} ${selectedWorkItem.name}`
    : messages.addNewProductService.title;

  return (
    <>
      <Modal
        open={open}
        title={messages.selectProductOrService.title}
        onRequestClose={closeSearchModal}
      >
        <div className={classNames(styles.largePadding)}>
          <Card
            header={
              <SearchOrCreateHeader
                onSearchInput={search}
                searching={searching}
                onClickCreate={changeModals}
              />
            }
          >
            <div className={styles.modalHeight}>
              <ListLoadingWrapper isLoading={loading} error={error}>
                <SearchList
                  isLoadingMore={loadingNextPage}
                  loadMore={nextPage}
                  currencySymbol={accountContext.settings.currencySymbol}
                  productsAndServices={productsAndServices}
                  selectItem={handleItemClick}
                />
              </ListLoadingWrapper>
            </div>
          </Card>
        </div>
      </Modal>
      <WorkItemModal
        modalOpen={workItemModalOpen}
        closeModal={closeWorkItemModal}
        workItem={selectedWorkItem}
        currencySymbol={accountContext.settings.currencySymbol}
        onSelfServeBookingsPage={true}
        modalTitle={modalTitle}
        saveButton={{ label: saveLabel }}
        deleteButton={{ hidden: true }}
        quoteMarginsEnabled={accountContext.features.quoteMarginsEnabled}
        selfServeBookingsEnabled={
          accountContext.features.selfServeBookingsEnabled
        }
        advancedQuotingEnabled={accountContext.features.advancedQuotingEnabled}
        refetchQueries={[
          SELF_SERVE_BOOKINGS_SERVICES_QUERY,
          PRODUCT_OR_SERVICE_LIST,
          SELF_SERVE_SETTINGS_QUERY,
        ]}
      />
    </>
  );

  function changeModals() {
    closeSearchModal();
    setWorkItemModalOpen(true);
  }

  function closeWorkItemModal() {
    setWorkItemModalOpen(false);
    setSelectedWorkItem(defaultNewWorkItem());
  }

  function handleItemClick(product: ProductOrServiceFragment) {
    return () => {
      const workItem = {
        ...product,
        onlineBookingsEnabled: true,
      };
      setSelectedWorkItem(workItem);
      changeModals();
    };
  }
}

function defaultNewWorkItem(): WorkItem {
  return {
    category: "SERVICE",
    name: "",
    defaultUnitCost: 0,
    onlineBookingsEnabled: true,
    taxable: true,
  };
}
