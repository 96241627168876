import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { Spinner } from "@jobber/components/Spinner";

const VISIT_DURATION_OPTIONS = [
  { value: `${15 * 60}`, label: "15 min" },
  { value: `${30 * 60}`, label: "30 min" },
  { value: `${45 * 60}`, label: "45 min" },
  { value: `${1 * 60 * 60}`, label: "1 hr 0 min" },
  { value: `${3 * 30 * 60}`, label: "1 hr 30 min" },
  { value: `${2 * 60 * 60}`, label: "2 hr" },
  { value: `${3 * 60 * 60}`, label: "3 hr" },
  { value: `${4 * 60 * 60}`, label: "4 hr" },
  { value: `${5 * 60 * 60}`, label: "5 hr" },
  { value: `${6 * 60 * 60}`, label: "6 hr" },
  { value: `${7 * 60 * 60}`, label: "7 hr" },
  { value: `${8 * 60 * 60}`, label: "8 hr" },
];

const DRIVE_TIME_LIMIT_OPTIONS = [
  { value: `${5 * 60}`, label: "5 min" },
  { value: `${10 * 60}`, label: "10 min" },
  { value: `${15 * 60}`, label: "15 min" },
  { value: `${30 * 60}`, label: "30 min" },
  { value: `${45 * 60}`, label: "45 min" },
  { value: `${60 * 60}`, label: "1 hr 0 min" },
  { value: `${Infinity}`, label: "No limit" },
];

type Seconds = number;

export enum SelectionType {
  duration = "Duration",
  driveTime = "Drive time limit",
}

export const SelectDuration = ({
  duration,
  selectionType,
  loading,
  onChange,
}: {
  duration: Seconds;
  selectionType: SelectionType;
  loading?: boolean;
  onChange: (seconds: Seconds) => void;
}) => {
  return (
    <Select
      placeholder={selectionType}
      value={`${duration}`}
      onChange={value => onChange(+value)}
      // @ts-expect-error
      suffix={loading ? { label: <LoadingSuffix /> } : undefined}
    >
      {getSelectDurationOptions(selectionType, duration)}
    </Select>
  );
};

function getSelectDurationOptions(
  selectionType: SelectionType,
  duration: Seconds,
) {
  if (selectionType === SelectionType.driveTime) {
    return getDriveTimeLimitOptions();
  }

  return getVisitDurationOptions(duration);
}

function LoadingSuffix() {
  return (
    <div
      style={{
        marginTop: -12,
        marginLeft: -21,
      }}
    >
      <Spinner size="small" />
    </div>
  );
}

function getAtypicalDurationValues(duration: Seconds) {
  const hours = Math.floor(duration / (60 * 60));
  const minutes = (duration % (60 * 60)) / 60;

  let durationHasNoOption = true;

  VISIT_DURATION_OPTIONS.forEach(option => {
    if (option.value === `${hours * 60 * 60 + minutes * 60}`) {
      durationHasNoOption = false;
    }
  });

  return {
    durationHasNoOption: durationHasNoOption,
    hours: hours,
    minutes: minutes,
  };
}

function getDynamicOptionIndex(
  options: { label: string; value: string }[],
  hours: number,
  minutes: number,
) {
  if (hours === 1 && minutes < 30) {
    return 4;
  }

  if (hours === 1 && minutes > 30) {
    return 5;
  }

  const hourMatchIndex = options.findIndex(
    option => option.value === `${hours * 60 * 60}`,
  );

  if (hourMatchIndex === -1) {
    return options.length;
  }

  return hourMatchIndex + 1;
}

function getVisitDurationOptions(duration: Seconds) {
  const { durationHasNoOption, hours, minutes } =
    getAtypicalDurationValues(duration);

  const visitDurationOptionsCopy = [...VISIT_DURATION_OPTIONS];

  if (durationHasNoOption) {
    const dynamicOptionIndex = getDynamicOptionIndex(
      visitDurationOptionsCopy,
      hours,
      minutes,
    );

    visitDurationOptionsCopy.splice(dynamicOptionIndex, 0, {
      value: `${duration}`,
      label: `${hours > 0 ? `${hours} hr ` : ""}${
        minutes === 0 ? "" : `${minutes} min`
      }`,
    });
  }

  return (
    <>
      {visitDurationOptionsCopy.map((option, index) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </>
  );
}

function getDriveTimeLimitOptions() {
  return (
    <>
      {DRIVE_TIME_LIMIT_OPTIONS.map((option, index) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </>
  );
}
