/* eslint-disable import/no-internal-modules */
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import React, {
  type MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Button } from "@jobber/components";
import { InlinePrompt } from "components/InlinePrompt";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import {
  JPayAutoenableTurnOffReasonModal,
  ctaName as jPayAutoenableTurnOffReasonModalCtaName,
} from "jobber/jPayAutoenable/components/JPayAutoenableTurnOffReasonModal";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  AchProcessingRate,
  ProcessingRates,
} from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { BankDetails, type BankDetailsProps } from "./BankDetails";
import { InstantPayoutDetails } from "./InstantPayoutDetails";
import { NotificationSettings } from "./Settings/NotificationSettingsProvider";
import { PaymentOptionsCard } from "./Settings/PaymentOptionCard";
import styles from "./JobberPaymentsSettings.module.css";
import { remoteBooleanReducer } from "./remoteBooleanReducer";
import { CardReaders, type CardReadersProps } from "./CardReaders";
import { ClientHubSettings } from "./Settings/ClientHubSettingsProvider";
import { PaymentsCard, type PaymentsCardProps } from "./PaymentsCard";
import { FinancialInsightsSection } from "./FinancialInsightsSection/FinancialInsightsSection";

export interface JobberPaymentsSettingsProps {
  bankDetailsProps: BankDetailsProps;
  processingRates: ProcessingRates;
  instantPayoutDebitCardInfo?: CreditCard;
  cardReaderProps: CardReadersProps;
  hasLoan: boolean;
  newSignUpSettingsEnabled: boolean;
  payoutsErrorMessage?: string;
  achProcessingRate?: AchProcessingRate;
  paymentCard: PaymentsCardProps;
  shouldShowJobberPaymentCard: boolean;
  shouldShowDisputesRow: boolean;
}

export const JobberPaymentsSettings = withSplitClient(
  JobberPaymentsSettingsInternal,
);

// eslint-disable-next-line max-statements
function JobberPaymentsSettingsInternal(props: JobberPaymentsSettingsProps) {
  const {
    bankDetailsProps,
    hasLoan,
    newSignUpSettingsEnabled,
    paymentCard,
    shouldShowJobberPaymentCard,
    shouldShowDisputesRow,
  } = props;

  const jPayAutoenableReminderCtaRef =
    useRef() as MutableRefObject<CallToActionRef>;

  const [t] = useTranslation();
  const {
    enabled: globalEnabled,
    setEnabled: setGlobalEnabled,
    permissions,
    tipsEnabled,
  } = useJobberPayments();

  const [showJPayTurnOffReasonModal, setShowJPayTurnOffReasonModal] =
    useState(false);

  const {
    jpayToggleCurrentState,
    onDisableJobberPaymentsClick,
    onEnableJobberPaymentsClick,
  } = useJobberPaymentsToggle({
    globalEnabled,
    setGlobalEnabled,
    onToggleOff: () => setShowJPayTurnOffReasonModal(true),
  });

  const showInsights = useFeatureFlag(SplitNames.JobberPaymentsInsights);

  return (
    <div className={styles.pageWrapper}>
      <Page title={t("jobberPaymentsTitle")}>
        {!jpayToggleCurrentState && !newSignUpSettingsEnabled && (
          <Banner
            {...(permissions.canEnablePayments && {
              primaryAction: {
                label: "Turn On",
                onClick: onEnableJobberPaymentsClick,
              },
            })}
            type="notice"
          >
            {permissions.canEnablePayments
              ? "Jobber Payments is currently turned off, turn it on to collect card payments"
              : "Jobber Payments is currently turned off, you'll need your account owner to turn it on to collect payments"}
          </Banner>
        )}

        <div
          className={
            showInsights ? styles.containerWithInsights : styles.container
          }
        >
          {showInsights && (
            <FinancialInsightsSection
              hasLoan={hasLoan}
              instantPayoutDebitCardInfo={props.instantPayoutDebitCardInfo}
              instantPayoutFeeMultiplier={
                paymentCard.instantPayoutFeeMultiplier
              }
            />
          )}

          <JPayDetailsSection
            bankDetailsProps={bankDetailsProps}
            instantPayoutDebitCardInfo={props.instantPayoutDebitCardInfo}
            paymentCard={paymentCard}
            shouldShowDisputesRow={shouldShowDisputesRow}
            shouldShowJobberPaymentCard={shouldShowJobberPaymentCard}
            canViewInstantPayouts={permissions.canViewInstantPayouts}
            hasLoan={hasLoan}
            showReportButtons={!showInsights}
          />

          <SettingsCardsSection
            tipsEnabled={tipsEnabled}
            cardReaderProps={props.cardReaderProps}
          />

          {jpayToggleCurrentState && permissions.canDisablePayments && (
            <section>
              <Content spacing="small">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- Grandfathered error: Please fix if touching this code. */}
                  <a href="javascript:;" onClick={onDisableJobberPaymentsClick}>
                    <Emphasis variation="bold">
                      DISABLE JOBBER PAYMENTS
                    </Emphasis>
                  </a>
                </div>
                <Text>
                  Clients will not be able to pay any outstanding or new quotes
                  and invoices online.
                </Text>
              </Content>
            </section>
          )}
        </div>
        {showJPayTurnOffReasonModal && (
          <APIProvider>
            <CallToAction
              ref={jPayAutoenableReminderCtaRef}
              ctaName={jPayAutoenableTurnOffReasonModalCtaName}
            >
              <JPayAutoenableTurnOffReasonModal
                dismissCTA={dismissCTA(jPayAutoenableReminderCtaRef)}
                convertCTA={convertCTA(jPayAutoenableReminderCtaRef)}
              />
            </CallToAction>
          </APIProvider>
        )}
      </Page>
    </div>
  );
}

interface SettingsCardsSectionProps
  extends Pick<JobberPaymentsSettingsProps, "cardReaderProps"> {
  tipsEnabled: boolean;
}
function SettingsCardsSection({
  tipsEnabled,
  cardReaderProps,
}: SettingsCardsSectionProps) {
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [toggleAchFlag, setToggleAchFlag] = useState(false);
  const [updateDefaultPreference, setUpdateDefaultPreference] = useState({
    preference: "",
    achEnabled: false,
  });
  const tipsCTAText = tipsEnabled
    ? "Your clients can now include a tip when paying invoices through client hub! To disable this, change your settings below."
    : "Want to receive tips from clients when they’re paying invoices through client hub? Change your settings below to enable tip collection.";

  return (
    <Content spacing="large">
      <APIProvider>
        <CallToAction
          ctaName={"jobber_payments_settings_tips_helper"}
          ref={ctaRef}
        >
          <InlinePrompt
            title={tipsCTAText}
            handleDismiss={dismissCTA(ctaRef)}
          />
        </CallToAction>
      </APIProvider>

      <PaymentOptionsCard
        toggleAchSwitchFlag={toggleAchFlag}
        setUpdateDefaultPreference={setUpdateDefaultPreference}
      />

      <ClientHubSettings
        dismissCta={dismissCTA(ctaRef)}
        setToggleAchFlag={setToggleAchFlag}
        updateDefaultPreference={updateDefaultPreference}
      />

      <NotificationSettings />

      <CardReaders {...cardReaderProps} />
    </Content>
  );
}

interface JPayDetailsSectionProps
  extends Pick<
    JobberPaymentsSettingsProps,
    | "bankDetailsProps"
    | "instantPayoutDebitCardInfo"
    | "paymentCard"
    | "shouldShowDisputesRow"
    | "shouldShowJobberPaymentCard"
    | "hasLoan"
  > {
  canViewInstantPayouts: boolean;
  showReportButtons: boolean;
}
function JPayDetailsSection(props: JPayDetailsSectionProps) {
  const [instantPayoutDebitCardInfo, setInstantPayoutDebitCardInfo] = useState(
    props.instantPayoutDebitCardInfo,
  );

  const mergedPaymentCardProps = {
    showHeader: false,
    ...props.paymentCard,
  };

  const showJobberPaymentCard =
    props.shouldShowJobberPaymentCard ||
    (!props.shouldShowJobberPaymentCard && props.shouldShowDisputesRow);

  const [
    jobberPaymentsPayoutsPath,
    jobberPaymentsTransactionsPath,
    stripeCapitalReportLinkPath,
  ] = useUrls(
    "jobberPaymentsPayoutsPath",
    "jobberPaymentsTransactionsPath",
    "stripeCapitalReportLinkPath",
  );

  return (
    <section className={styles.bankDetails}>
      <BankDetails {...props.bankDetailsProps} />

      <Content>
        {props.canViewInstantPayouts && (
          <>
            <hr />
            <InstantPayoutDetails
              debitCard={instantPayoutDebitCardInfo}
              onDebitCardChange={setInstantPayoutDebitCardInfo}
              isJobberMoneyPayout={
                props.paymentCard.jobberMoney.payoutIsJobberMoneyAccount
              }
            />
          </>
        )}
        <hr />
        {showJobberPaymentCard && (
          <PaymentsCard
            {...mergedPaymentCardProps}
            shouldShowJobberPaymentCard={props.shouldShowJobberPaymentCard}
            showPayoutDetails={props.showReportButtons}
          />
        )}
        {props.showReportButtons && (
          <Content spacing="small">
            <Button
              label="View bank payouts"
              type="primary"
              url={jobberPaymentsPayoutsPath}
              fullWidth={true}
            />
            <Button
              label="View transactions"
              type="secondary"
              url={jobberPaymentsTransactionsPath}
              fullWidth={true}
            />
            {props.hasLoan && (
              <Button
                label="View loan report"
                type="secondary"
                url={stripeCapitalReportLinkPath}
                fullWidth={true}
                external={true}
              />
            )}
          </Content>
        )}
      </Content>
    </section>
  );
}

interface useJobberPaymentsToggleProps {
  globalEnabled: ReturnType<typeof useJobberPayments>["enabled"];
  setGlobalEnabled: ReturnType<typeof useJobberPayments>["setEnabled"];
  onToggleOff: () => void;
}
function useJobberPaymentsToggle({
  globalEnabled,
  setGlobalEnabled,
  onToggleOff,
}: useJobberPaymentsToggleProps) {
  const [authenticityToken] = useAuth();

  const [state, dispatch] = useReducer(remoteBooleanReducer, {
    type: "idle",
    targetEnable: undefined,
    currentState: globalEnabled,
  });

  useEffect(() => {
    if (state.type !== "fetching") return;

    const { targetEnable } = state;
    let mounted = true;

    async function disableJPay() {
      try {
        const response = await fetch(
          "/jobber_payments/set_managed_account_state",
          {
            method: "POST",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              enable: targetEnable,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              authenticity_token: authenticityToken,
            }),
          },
        );

        if (!mounted) return;
        if (!response.ok) {
          dispatch({ type: "reset" });
          return;
        }

        dispatch({ type: "success", updatedEnable: targetEnable });
      } catch (error) {
        if (!mounted) return;
        dispatch({ type: "reset" });
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    disableJPay();

    return () => void (mounted = false);
  }, [authenticityToken, state]);

  useEffect(() => {
    if (state.type !== "success") return;

    setGlobalEnabled(state.currentState);
  }, [setGlobalEnabled, state]);

  function onEnableJobberPaymentsClick() {
    if (state.type === "fetching") return;

    dispatch({ type: "start", targetEnable: true });
  }

  function onDisableJobberPaymentsClick() {
    if (state.type === "fetching") return;

    dispatch({ type: "start", targetEnable: false });
    onToggleOff();
  }

  return {
    jpayToggleCurrentState: state.currentState,
    onEnableJobberPaymentsClick,
    onDisableJobberPaymentsClick,
  };
}
