import { type Dispatch, useEffect } from "react";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type {
  WithdrawFundsDialogAction,
  WithdrawFundsDialogState,
} from "./withdrawFundsDialogReducer";

export function useWithdrawFundsRequests(
  state: WithdrawFundsDialogState,
  dispatch: Dispatch<WithdrawFundsDialogAction>,
) {
  const [authenticityToken] = useAuth();
  const [path] = useUrls("jobberPaymentsCreateInstantPayoutsPath");
  const { requestedAmount, isLoading } = state;

  useEffect(() => {
    if (!isLoading) return;

    let isAborted = false;

    async function makeRequest() {
      const response = await fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          amount: requestedAmount,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          authenticity_token: authenticityToken,
        }),
      });

      if (isAborted) return;

      if (response.ok) {
        const json = (await response.json()) as {
          id: string;
          amount: string;
          last4: string;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          instant_available_balance: string;
        };
        if (isAborted) return;

        dispatch({
          type: "onWithdrawFundsSuccess",
          payload: {
            ...json,
            instantAvailableBalance: json.instant_available_balance,
          },
        });
      } else {
        const error = (await response.json()) as { message: string };
        if (isAborted) return;

        dispatch({
          type: "onWithdrawFundsError",
          payload: { errorMessage: error.message },
        });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    makeRequest();

    return () => void (isAborted = true);
  }, [isLoading, requestedAmount]);
}
