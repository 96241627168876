export const messages = {
  phoneNumberPlaceholder: {
    id: "phoneNumberPlaceholder",
    defaultMessage: "Phone number",
    description: "Placeholder for the phone number input",
  },
  smsSuccessToast: {
    id: "smsSuccessToast",
    defaultMessage: "Mobile app invite sent successfully.",
    description: "Toast message when the invite was sent successfully",
  },
  smsFailToast: {
    id: "smsFailToast",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Default toast message when the invite failed to send",
  },
};
