import { defineMessages } from "react-intl";

/* Follow Product Vocabulary and Voice & Tone Guidelines:
https://atlantis.getjobber.com/content/product-vocabulary
https://atlantis.getjobber.com/content/voice-and-tone */
export const messages = defineMessages({
  arrayCellSeparator: {
    id: "reporting.report.arrayCellSeparator",
    defaultMessage: ", ",
    description: "Separator for array cells",
  },
});
