import React from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { type IntlFormatters, useIntl } from "react-intl";
import { LiteToCoreTrialBeginModal } from "jobber/liteToCoreTrial/LiteToCoreTrialBeginModal";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./CoreTrialWrapper.module.css";
import { CORE_TRIAL_ELIGIBILITY } from "./CoreTrialEligibility.graphql";

export interface FeatureTrialEligibility {
  accountFeatureTrialEligibility: {
    eligibleForLiteToCoreTrial: boolean | null;
  };
}

interface CoreTrialWrapperProps {
  renderCoreFeatureLandingPage: (
    trialAvailable: boolean,
    showStartTrialModal: () => void,
    formatMessage: IntlFormatters["formatMessage"],
  ) => React.ReactNode;
  source: string;
}

function CoreTrialWrapperInternal({
  renderCoreFeatureLandingPage,
  source,
}: CoreTrialWrapperProps) {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = React.useState(false);
  const { data, loading } = useQuery<FeatureTrialEligibility>(
    CORE_TRIAL_ELIGIBILITY,
  );
  return (
    <>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        <>
          {renderCoreFeatureLandingPage(
            !!data?.accountFeatureTrialEligibility.eligibleForLiteToCoreTrial,
            () => setShowModal(true),
            formatMessage,
          )}
          <LiteToCoreTrialBeginModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            source={source}
          />
        </>
      )}
    </>
  );
}

export function CoreTrialWrapper({
  renderCoreFeatureLandingPage,
  source,
}: CoreTrialWrapperProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <CoreTrialWrapperInternal
          renderCoreFeatureLandingPage={renderCoreFeatureLandingPage}
          source={source}
        />
      </IntlProvider>
    </APIProvider>
  );
}
