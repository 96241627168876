import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import { AddDebitCardDialog } from "jobber/payments_sca/components/AddDebitCardDialog/AddDebitCardDialog";
import useDialogOpen from "jobber/payments_sca/hooks/useDialogOpen/useDialogOpen";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import {
  type ServerCreditCard,
  mapServerCreditCard,
} from "jobber/payments_sca/utils/mapServerCreditCard";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import {
  type DepositResult,
  WithdrawFundsDialog,
  formatFeePercentDisplay,
} from "jobber/managed_accounts/WithdrawFundsDialog";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import styles from "./PaymentsCard.module.css";
import { DepositResultBanner } from "./DepositResultBanner";
import { GetItNowButton } from "./GetItNowButton";

export interface InstantPayoutRowProps {
  instantAvailableAmount: string;
  instantPayoutFeeMultiplier: string;
  currencyUnit: string;

  debitCard: ServerCreditCard | undefined;
  debitCardIsValid: boolean;

  onPayoutSucceed(deposited: DepositResult): void;
}

export const InstantPayoutRow =
  withStripeElementProvider<InstantPayoutRowProps>(InstantPayoutRowWithStripe);

export function InstantPayoutRowWithStripe(props: InstantPayoutRowProps) {
  const {
    instantAvailableAmount,
    instantPayoutFeeMultiplier,
    currencyUnit,
    onPayoutSucceed,
  } = props;

  const [isWithdrawDialogOpen, openWithdrawDialog, closeWithdrawDialog] =
    useDialogOpen();

  const [isAddDebitDialogOpen, openAddDebitDialog, closeAddDebitDialog] =
    useDialogOpen();

  const [debitCard, setDebitCard] = useState(
    props.debitCard && mapServerCreditCard(props.debitCard),
  );
  const [depositResult, setDepositResult] = useState<DepositResult>();
  const getItNowButtonOnClick = debitCard
    ? onWithdrawFundsButtonClick
    : openAddDebitDialog;

  const withdrawalAllowed =
    props.debitCardIsValid && +instantAvailableAmount >= 50.0;

  useEffect(() => {
    const isInstantPayoutModalOpen = localStorage.getItem(
      "instant_payout_modal_open",
    );

    if (isInstantPayoutModalOpen) {
      getItNowButtonOnClick();
      localStorage.removeItem("instant_payout_modal_open");
    }
  }, [getItNowButtonOnClick]);

  return (
    <>
      <hr />
      <Content spacing="small">
        <h5 className={styles.headerText}>Available for instant payout</h5>
        <div className={styles.container}>
          <div className={`${styles.content} ${styles.centerItems}`}>
            <Heading level={2}>
              {currencyWithUnit(instantAvailableAmount, currencyUnit).format()}
            </Heading>
            <span className="u-marginLeftSmallest">
              <tooltip-icon>
                <p>
                  Receive money in seconds, 24/7 for an additional{" "}
                  {formatFeePercentDisplay(instantPayoutFeeMultiplier)} fee by
                  using an instant payout.
                </p>
                These are the funds that haven&apos;t been paid out already and
                are available for an instant payout.
              </tooltip-icon>
            </span>
          </div>
          <div className={styles.action}>
            {debitCard && (
              <WithdrawFundsDialog
                isOpen={isWithdrawDialogOpen}
                onRequestClose={closeWithdrawDialog}
                onDepositSuccess={onDepositSuccess}
                instantAvailableAmount={instantAvailableAmount}
                feeMultiplier={instantPayoutFeeMultiplier}
                currencyUnit={currencyUnit}
                debitCard={debitCard}
              />
            )}
            <GetItNowButton
              getItNowButtonOnClick={getItNowButtonOnClick}
              debitCardLinked={!!debitCard}
              withdrawalAllowed={withdrawalAllowed}
              debitCardIsValid={props.debitCardIsValid}
            />
            <AddDebitCardDialog
              saveButtonLabel="Save and continue"
              isDialogOpen={isAddDebitDialogOpen}
              onRequestCloseDialog={closeAddDebitDialog}
              onDebitCardAdded={onDebitCardAdded}
            />
          </div>
        </div>
        {depositResult && (
          <DepositResultBanner
            depositResult={depositResult}
            currencyUnit={currencyUnit}
          />
        )}
      </Content>
    </>
  );

  function onDebitCardAdded(card: CreditCard) {
    setDebitCard(card);
  }

  function onWithdrawFundsButtonClick() {
    setDepositResult(undefined);
    openWithdrawDialog();
  }

  function onDepositSuccess(deposit: DepositResult) {
    setDepositResult(deposit);
    onPayoutSucceed(deposit);
  }
}
