import React, { useRef, useState } from "react";
import { Button } from "@jobber/components/Button";
import {
  DataList,
  type DataListItemType,
  type DataListSorting,
} from "@jobber/components/DataList";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@jobber/components/Grid";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Tooltip } from "@jobber/components/Tooltip";
import { Text } from "@jobber/components/Text";
import { useHistory } from "react-router-dom";
import { messages } from "jobber/campaigns/views/CampaignsLandingPage/messages";
import { SORT_KEYS } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignsQuery";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import type { SingleCampaign } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignDataListItems";
import {
  ALLOWED_STATUSES,
  LIST_ACTIONS,
  useAutomatedCampaignRowActions,
} from "jobber/campaigns/views/CampaignsLandingPage/hooks/useAutomatedCampaignRowActions/useAutomatedCampaignRowActions";
import styles from "./AutomatedCampaignList.module.css";

interface AutomatedCampaignListProps {
  readonly campaigns: SingleCampaign[];
  readonly loadingInitial?: boolean;
  readonly loadingMore?: boolean;
  readonly totalCount?: number;
  readonly loadMore?: () => void;
  setVariablesForSorting: (
    sortKey: string,
    sortDirection: "asc" | "desc" | undefined,
  ) => void;
  setSelectedCampaignId: (id: string) => void;
}

export function AutomatedCampaignList({
  campaigns,
  loadingInitial,
  loadingMore,
  totalCount = 0,
  loadMore,
  setVariablesForSorting,
  setSelectedCampaignId,
}: AutomatedCampaignListProps) {
  const { formatMessage } = useIntl();
  const [sortingState, setSortingState] = useState<DataListSorting | undefined>(
    { order: "desc", key: "lastUpdated" },
  );
  const { largeAndUp } = useBreakpoints();
  const { push } = useHistory();
  const { newCampaignNavigationURL } = useCampaignUpsellSplit();
  const key = useRef(0);
  const { rowAction, canPerformAction } = useAutomatedCampaignRowActions();

  const grid = (
    item: ReactElement,
    tooltip: string,
    condition: boolean,
    doubleWidth = true,
  ) => {
    return (
      <Grid.Cell size={{ xs: doubleWidth ? 2 : 1 }}>
        <div className={styles.justifyContentEnd}>
          {item}
          {condition && buildTooltip(tooltip)}
        </div>
      </Grid.Cell>
    );
  };

  return (
    <DataList
      data={campaigns}
      headers={{
        label: formatMessage(messages.campaign),
        emailsDelivered: formatMessage(messages.emailsDelivered),
        openRate: formatMessage(messages.openRate),
        clickRate: formatMessage(messages.clickRate),
        revenue: formatMessage(messages.revenue),
        status: formatMessage(messages.status),
        updatedAt: formatMessage(messages.lastUpdated),
      }}
      onLoadMore={loadMore}
      loadingState={getLoadingState({ loadingInitial, loadingMore })}
      headerVisibility={{ xs: false, md: true }}
      title={formatMessage(messages.automatedCampaignListTitle)}
      totalCount={totalCount}
      sorting={{
        state: sortingState,
        onSort: sorting => {
          setVariablesForSorting(SORT_KEYS.UPDATED_AT, sorting?.order);
          setSortingState(sorting);
        },
        sortable: [
          {
            key: "updatedAt",
            sortType: "toggle",
            options: [
              {
                id: "updatedAt",
                label: "Last Updated (Newest)",
                order: "desc",
              },
              {
                id: "updatedAt",
                label: "Last Updated (Oldest)",
                order: "asc",
              },
            ],
          },
        ],
      }}
    >
      <DataList.ItemActions<SingleCampaign>
        onClick={(item: SingleCampaign) =>
          rowAction(item, LIST_ACTIONS.ROW_CLICK)
        }
      >
        <DataList.ItemAction
          icon="edit"
          label={formatMessage(messages.edit)}
          visible={(item: SingleCampaign) =>
            canPerformAction(item, ALLOWED_STATUSES.EDIT)
          }
          onClick={(item: SingleCampaign) => rowAction(item, LIST_ACTIONS.EDIT)}
        />
        <DataList.ItemAction
          icon="edit"
          label={formatMessage(messages.pauseAndEdit)}
          visible={(item: SingleCampaign) =>
            canPerformAction(item, ALLOWED_STATUSES.PAUSE_AND_EDIT)
          }
          onClick={(item: SingleCampaign) =>
            rowAction(item, LIST_ACTIONS.PAUSE_AND_EDIT)
          }
        />
        <DataList.ItemAction
          icon="redo"
          label={formatMessage(messages.reactivate)}
          visible={(item: SingleCampaign) =>
            canPerformAction(item, ALLOWED_STATUSES.REACTIVATE)
          }
          onClick={(item: SingleCampaign) =>
            rowAction(item, LIST_ACTIONS.REACTIVATE)
          }
        />
        <DataList.ItemAction
          icon="minus2"
          label={formatMessage(messages.deactivate)}
          visible={(item: SingleCampaign) =>
            canPerformAction(item, ALLOWED_STATUSES.DEACTIVATE)
          }
          onClick={(item: SingleCampaign) =>
            rowAction(item, LIST_ACTIONS.DEACTIVATE)
          }
        />
        <DataList.ItemAction
          destructive
          label={formatMessage(messages.delete)}
          visible={(item: SingleCampaign) =>
            canPerformAction(item, ALLOWED_STATUSES.DELETE)
          }
          onClick={(item: SingleCampaign) => setSelectedCampaignId(item.id)}
        />
      </DataList.ItemActions>
      <DataList.Layout size="md">
        {(item: DataListItemType<typeof campaigns>) => {
          key.current += 1;
          const isHeaderAndLarge = !item.id && !loadingInitial && largeAndUp;
          return (
            <Grid alignItems={"center"} key={key.current}>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.preventWrap}>{item.label}</div>
              </Grid.Cell>
              {grid(
                item.emailsDelivered,
                formatMessage(messages.emailsDeliveredTooltip),
                isHeaderAndLarge,
              )}
              {grid(
                item.openRate,
                formatMessage(messages.openRateTooltip),
                isHeaderAndLarge,
              )}
              {grid(
                item.clickRate,
                formatMessage(messages.clickRateTooltip),
                isHeaderAndLarge,
              )}
              {grid(
                item.revenue,
                formatMessage(messages.revenueTooltip),
                isHeaderAndLarge,
                false,
              )}
              <Grid.Cell size={{ xs: 1 }}>{item.status}</Grid.Cell>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.updatedAtWrapper}>
                  {item.updatedAt} {item.actions}
                </div>
              </Grid.Cell>
            </Grid>
          );
        }}
      </DataList.Layout>
      <DataList.Layout size="xs">
        {(item: DataListItemType<typeof campaigns>) => (
          <div className={styles.smallListItemWrapper}>
            <div className={styles.smallListItemLabel}>
              <div className={styles.preventWrap}>{item.label}</div>
              {item.status}
            </div>
            <Content spacing={"small"}>
              <Text>
                {item.openRate} {item.clickRate} {item.revenue}
              </Text>
              <div className={styles.updatedAtWrapper}>
                <Text>{item.updatedAt}</Text>
                {item.actions}
              </div>
            </Content>
          </div>
        )}
      </DataList.Layout>
      <DataList.EmptyState
        type="empty"
        message={formatMessage(messages.noCampaignsMessage)}
        action={
          <Button
            label={formatMessage(messages.newCampaignLabel)}
            onClick={() => push(newCampaignNavigationURL())}
          />
        }
      />
      <DataList.EmptyState
        type="filtered"
        message={formatMessage(messages.noFilteredCampaignsMessage)}
        action={<Button label={formatMessage(messages.clearFiltersMessage)} />}
      />
    </DataList>
  );
}

function getLoadingState({
  loadingMore,
  loadingInitial,
}: {
  loadingMore?: boolean;
  loadingInitial?: boolean;
}) {
  if (loadingInitial) {
    return "initial";
  }
  if (loadingMore) {
    return "loadingMore";
  }
  return undefined;
}

function buildTooltip(message: string) {
  return (
    <div className={styles.marginY}>
      <Tooltip message={message}>
        <Icon name={"help"} size={"small"} />
      </Tooltip>
    </div>
  );
}
