import { gql } from "@apollo/client";

export const TRACK_FEATURE_UPGRADE = gql`
  mutation trackFeatureUpgrade($feature: UpgradeFeature!) {
    trackFeatureUpgrade(input: $feature) {
      trackingAdded
      userErrors {
        message
        path
      }
    }
  }
`;
