import { type ApolloError, useLazyQuery } from "@apollo/client";
import type {
  QuoteSummaryFilterInput,
  QuoteSummaryTotalsQuery,
  QuoteSummaryTotalsQueryVariables,
} from "~/utilities/API/graphqlFranchiseManagement";
import { QUOTE_SUMMARY_TOTALS_QUERY } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport/QuoteSummaryReport.graphql";

interface QuoteSummaryTotalsReportDataProps {
  data?: QuoteSummaryTotalsQuery;
  loading: boolean;
  error?: ApolloError;
  fetchQuoteSummaryTotals(filter: QuoteSummaryFilterInput): void;
}

export function useQuoteSummaryTotalsData(): QuoteSummaryTotalsReportDataProps {
  const [fetchQuoteSummaryTotals, { data, loading, error }] = useLazyQuery<
    QuoteSummaryTotalsQuery,
    QuoteSummaryTotalsQueryVariables
  >(QUOTE_SUMMARY_TOTALS_QUERY);

  const _fetchQuoteSummaryTotals = (filter: QuoteSummaryFilterInput) =>
    fetchQuoteSummaryTotals({
      variables: {
        filter,
      },
    });

  return {
    fetchQuoteSummaryTotals: _fetchQuoteSummaryTotals,
    data,
    loading,
    error,
  };
}
