import type {
  ProductOrServiceCustomFieldCustomFieldLinkFragment,
  ProductOrServiceCustomFieldFragment,
} from "~/utilities/API/graphql";

export function isCustomFieldLink(
  customField: ProductOrServiceCustomFieldFragment,
): customField is ProductOrServiceCustomFieldCustomFieldLinkFragment {
  return (
    (customField as ProductOrServiceCustomFieldCustomFieldLinkFragment)
      .valueLink !== undefined
  );
}
