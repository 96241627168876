import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "connectToGrowTrialPreTrialModal.modalTitle",
    defaultMessage: "Try out the Grow plan for free",
    description: "Modal Title",
  },
  modalHeader: {
    id: "connectToGrowTrialPreTrialModal.modalHeader",
    defaultMessage:
      "See what the Grow plan can do for your business with a 14-day trial.",
    description: "Modal Header",
  },
  ctaLabel: {
    id: "connectToGrowTrialPreTrialModal.ctaLabel",
    defaultMessage: "Try Grow Features",
    description: "CTA Label",
  },
  modalDefaultError: {
    id: "connectToGrowTrialPreTrialModal.modalDefaultError",
    defaultMessage: "Grow trial could not be started. Please try again.",
    description: "Modal Default Error",
  },
});
