import type { MutableRefObject } from "react";
import React from "react";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import type { CallToActionRef } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { messages } from "./messages";
import styles from "./GoogleReviewMilestoneCelebration.module.css";
import type { MilestoneCTA } from "./utils";
import { getMilestoneValues } from "./utils";

const SMALL_SCREEN_WIDTH = 768;
const MOBILE_SCREEN_WIDTH = 490;

export function GoogleReviewMilestoneCelebration({
  milestone,
  ctaRef,
}: {
  milestone: MilestoneCTA;
  ctaRef: MutableRefObject<CallToActionRef>;
}) {
  const isSmallScreen = window.innerWidth <= SMALL_SCREEN_WIDTH;
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;
  const { formatMessage } = useIntl();

  const { mobileImage, tabletImage, desktopImage, amount, subHeading } =
    getMilestoneValues(milestone);

  function getScreenSizeImage() {
    if (isMobileScreen) {
      return mobileImage;
    } else if (isSmallScreen) {
      return tabletImage;
    } else {
      return desktopImage;
    }
  }

  const handleReviewsButtonClick = () => {
    ctaRef.current?.convert();
    window.location.href = "/reviews";
  };

  const handleCloseButtonClick = () => {
    ctaRef.current?.dismiss();
  };

  return (
    <Card>
      <div className={styles.donePanelContainer}>
        <img
          className={styles.image}
          src={getScreenSizeImage()}
          alt={formatMessage(messages.imageAltText, {
            milestone: amount,
          })}
        />

        <div className={styles.content}>
          <div className={styles.heading}>
            <Heading level={3}>
              {formatMessage(messages.heading, {
                milestone: amount,
              })}
            </Heading>
          </div>
          <div className={styles.subHeading}>
            <Text>{formatMessage(subHeading)}</Text>
          </div>
          <Button
            label={formatMessage(messages.button)}
            onClick={handleReviewsButtonClick}
          />
        </div>
        <CloseButton onClick={handleCloseButtonClick} />
      </div>
    </Card>
  );
}

function CloseButton({ onClick }: { onClick?: () => void }) {
  return (
    <div className={styles.close}>
      <Button
        icon="cross"
        ariaLabel="Dismiss"
        variation="subtle"
        type="tertiary"
        onClick={onClick}
      />
    </div>
  );
}
