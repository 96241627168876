import { ProcessingRatesBadgesInternal } from "./ProcessingRateBadgesInternal";
import type {
  AchProcessingRate,
  FeeType,
  ProcessingRate,
  ProcessingRates,
  RateGroup,
} from "./types";

export function ProcessingRateBadges(props: {
  processingRates: ProcessingRates;
  hideRateLabel?: boolean;
  achProcessingRate?: AchProcessingRate;
  imgManifest?: { [key: string]: string };
}) {
  const { processingRates, achProcessingRate } = props;
  const rateGroups = transformServerProcessingRates(processingRates);
  return (
    <ProcessingRatesBadgesInternal
      {...props}
      rateGroups={rateGroups}
      achProcessingRate={achProcessingRate}
    />
  );
}

const FEE_TYPE_TO_CARD_BRAND: Record<FeeType, string> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  credit_card: "",
  credit_card_visa: "visa",
  credit_card_mastercard: "mastercard",
  credit_card_americanexpress: "amex",
  credit_card_international: "intl",
  /* eslint-enable @typescript-eslint/naming-convention */
};

function transformServerProcessingRates(
  serverRates: ProcessingRates,
): RateGroup[] {
  const DEFAULT_FEE_TYPE_KEY = "credit_card";

  const { groupedByRate, rateKeyToRate } = getUniqueFeeTypes(serverRates);
  const alwaysShownCardBrands = new Set(["visa", "mastercard", "amex"]);
  const rateGroups: RateGroup[] = [];

  groupedByRate.forEach((feeTypes, rateKey) => {
    const rate: RateGroup = {
      cardBrands: [],
      processingRate: rateKeyToRate.get(rateKey) as ProcessingRate,
    };

    for (const feeType of feeTypes) {
      if (feeType === DEFAULT_FEE_TYPE_KEY) return; // will do default last, after specific fee types take from `alwaysShownCardBrands` first

      const cardBrand = takeFeeType(feeType);
      rate.cardBrands.push(cardBrand);
    }

    if (rate.cardBrands.length) {
      rateGroups.push(rate);
    }
  });

  if (serverRates[DEFAULT_FEE_TYPE_KEY] && alwaysShownCardBrands.size) {
    rateGroups.unshift({
      cardBrands: setToArray(alwaysShownCardBrands),
      processingRate: serverRates[DEFAULT_FEE_TYPE_KEY] as ProcessingRate,
    });
  }

  return rateGroups;

  function takeFeeType(feeType: FeeType) {
    const cardBrand = FEE_TYPE_TO_CARD_BRAND[feeType];
    alwaysShownCardBrands.delete(cardBrand);

    return cardBrand;
  }
}

// becausse ts(2569) doesn't let us use [...set]
function setToArray<T>(set: Set<T>) {
  const array: T[] = [];
  set.forEach(item => array.push(item));
  return array;
}

function getUniqueFeeTypes(serverRates: ProcessingRates) {
  const groupedByRate = new Map<string, FeeType[]>();
  const rateKeyToRate = new Map<string, ProcessingRate>();

  Object.entries(serverRates).forEach(
    ([feeType, rate]: [FeeType, ProcessingRate]) => {
      const rateKey = `${rate.percent};${rate.cents_per_charge}`;
      rateKeyToRate.set(rateKey, rate);

      if (!groupedByRate.has(rateKey)) groupedByRate.set(rateKey, []);
      const feeTypes = groupedByRate.get(rateKey) as FeeType[];
      feeTypes.push(feeType);
    },
  );

  return { groupedByRate, rateKeyToRate };
}
