import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "sendTestEmailModal.title",
    defaultMessage: "Send a test email",
    description: "Send a test email modal title",
  },
  sendEmailButton: {
    id: "sendTestEmailModal.button.sendEmailButton",
    defaultMessage: "Send Test Email",
    description: "Send Test Email button",
  },
  cancelButton: {
    id: "sendTestEmailModal.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  textLine1: {
    id: "sendTestEmailModal.text.textline1",
    defaultMessage: "Send a test email to",
    description: "text line one",
  },
  textLine2: {
    id: "sendTestEmailModal.text.textline2",
    defaultMessage:
      "In the test email, we will fill in any variables with sample data from one of your clients.",
    description: "text line two",
  },
  errorMessage: {
    id: "sendTestEmailModal.error.message",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error banner message",
  },
});
