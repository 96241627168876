import { defineMessages } from "react-intl";

const prefix = "setupWizard.themePage.";

export const messages = defineMessages({
  themePageDefaultHeading: {
    id: `${prefix}themePageDefaultHeading`,
    defaultMessage: "Personalize your look",
    description: "Default page header for the theme page",
  },
  themePageCopy: {
    id: `${prefix}themePageCopy`,
    defaultMessage:
      "Pick the theme—light or dark—that feels right for you. You can always change it later in your settings.",
    description: "Default page copy for the theme page",
  },
  lightButtonText: {
    id: `${prefix}lightButtonText`,
    defaultMessage: "Light Theme",
    description: "Text for the light theme option",
  },
  darkButtonText: {
    id: `${prefix}darkButtonText`,
    defaultMessage: "Dark Theme",
    description: "Text for the dark theme option",
  },
  lightButtonAltText: {
    id: `${prefix}lightButtonAltText`,
    defaultMessage: "light mode",
    description: "Alt text for the light theme option",
  },
  darkButtonAltText: {
    id: `${prefix}darkButtonAltText`,
    defaultMessage: "dark mode",
    description: "Alt text for the dark theme option",
  },
  lightImageAltText: {
    id: `${prefix}lightImageAltText`,
    defaultMessage: "light theme preview",
    description: "Alt text for the light theme image",
  },
  darkImageAltText: {
    id: `${prefix}darkImageAltText`,
    defaultMessage: "dark theme preview",
    description: "Alt text for the dark theme image",
  },
});
