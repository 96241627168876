import React from "react";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Tooltip } from "@jobber/components/Tooltip";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { InvoiceCreationPreference } from "jobber/workOrders/components/InvoiceScheduler/types";
import {
  StoredCardListItem,
  type StoredPaymentMethodInterface,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/StoredCardListItem";
import { SetUpJobberPaymentsCTA } from "jobber/SetUpJobberPaymentsCTA/SetUpJobberPaymentsCTA";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./InvoiceScheduleOptions.module.css";

export interface InvoiceScheduleOptionsProps {
  jobberPaymentsEnabled: boolean;
  creditCardInfo?: StoredPaymentMethodInterface;
  automaticPaymentsEnabled: boolean;
  invoiceCreationPreference: InvoiceCreationPreference;
  onChange(nextState: InvoiceCreationPreference): void;
}

const AutoCreateInvoiceRadioContent = ({
  automaticPaymentsEnabled,
}: {
  automaticPaymentsEnabled: boolean;
}) => {
  const InnerText = () => (
    <Text>Automatically create invoices and charge client</Text>
  );

  if (!automaticPaymentsEnabled) {
    return (
      <Tooltip message="Upgrade your plan to use automatic payments">
        <InnerText />
      </Tooltip>
    );
  } else {
    return <InnerText />;
  }
};

const CreditCardSection = ({
  creditCardInfo,
}: {
  creditCardInfo?: StoredPaymentMethodInterface;
}) => {
  return (
    <div className={styles.creditCardContainer}>
      {creditCardInfo ? (
        <StoredCardListItem
          uuid={creditCardInfo.uuid}
          last4={creditCardInfo.last4}
          expiryMonth={creditCardInfo.expiryMonth}
          expiryYear={creditCardInfo.expiryYear}
          brand={creditCardInfo.brand}
          isDefault={creditCardInfo.isDefault}
          expiryStatus={creditCardInfo.expiryStatus}
          type={creditCardInfo.type}
        />
      ) : (
        <Tooltip message="Your client can add their card after they pay their first deposit or invoice">
          <Text size="small">
            <Emphasis variation="italic">
              {"This client hasn't put their card on file yet"}
            </Emphasis>
          </Text>
        </Tooltip>
      )}
    </div>
  );
};

export const InvoiceScheduleOptions = ({
  creditCardInfo,
  automaticPaymentsEnabled,
  jobberPaymentsEnabled,
  invoiceCreationPreference,
  onChange,
}: InvoiceScheduleOptionsProps) => {
  const onChangeInvoiceScheduleOption = (value: string) => onChange(+value);

  const enableAutoInvoicing =
    automaticPaymentsEnabled &&
    jobberPaymentsEnabled &&
    creditCardInfo !== undefined;

  // TO DO: Disable the radio option when creditCardInfo is undefined
  return (
    <div className={styles.container}>
      <RadioGroup
        onChange={onChangeInvoiceScheduleOption}
        value={`${invoiceCreationPreference}`}
        ariaLabel={"Invoice Schedule Options"}
      >
        <RadioOption
          value={`${InvoiceCreationPreference.RemindMe}`}
          label="Remind me to create invoices"
        />
        <RadioOption
          disabled={!enableAutoInvoicing}
          value={`${InvoiceCreationPreference.AutomaticallyCreateAndCharge}`}
        >
          <AutoCreateInvoiceRadioContent
            automaticPaymentsEnabled={automaticPaymentsEnabled}
          />
        </RadioOption>
      </RadioGroup>

      {!jobberPaymentsEnabled ? (
        <APIProvider>
          <SetUpJobberPaymentsCTA />
        </APIProvider>
      ) : (
        <CreditCardSection creditCardInfo={creditCardInfo} />
      )}
    </div>
  );
};
