import React, { useEffect } from "react";
import { FlashBanner } from "jobber/managed_accounts/PaymentsCard/FlashBanner";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import type { SuccessBannerProps } from "./SuccessBanner.d";

export function SuccessBanner(props: SuccessBannerProps) {
  const { amount, reloadAfterSuccess, closeDialog } = props;
  const { currencySymbol } = useAccount();
  const [paymentState] = usePaymentReducer();

  useEffect(() => {
    if (paymentState.status !== "completed" || !paymentState.response.success) {
      return;
    }

    if (closeDialog) {
      closeDialog();
    }
    if (reloadAfterSuccess && !paymentState.response.didIntercept) {
      window.location.reload();
    }
  }, [paymentState.status]);

  const amountWithCurrency = currencyWithUnit(amount, currencySymbol).format();
  let defaultBannerMessage = `Collected ${amountWithCurrency}`;

  if (paymentState.attachedToType === "quote") {
    defaultBannerMessage = `Quote approved. The ${amountWithCurrency} deposit has been collected.`;
  }

  return (
    <>
      {paymentState.status === "completed" && paymentState.response.success && (
        <FlashBanner type="success">
          {paymentState.response.bannerMessage || defaultBannerMessage}
        </FlashBanner>
      )}
    </>
  );
}
