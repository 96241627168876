import React from "react";
import { Divider } from "@jobber/components/Divider";
import { AboutReportEntry } from "~/jobber/features/Reporting/components/AboutReportEntry/AboutReportEntry";
import {
  PAYMENTS_EXPLANATION,
  PAYMENTS_TITLE,
  ROYALTY_AMOUNTS_EXPLANATION,
  ROYALTY_AMOUNTS_TITLE,
} from "./constants";

export function AboutRoyaltyReport() {
  return (
    <>
      <AboutReportEntry
        entryName={PAYMENTS_TITLE}
        explanation={PAYMENTS_EXPLANATION}
      />
      <Divider />
      <AboutReportEntry
        entryName={ROYALTY_AMOUNTS_TITLE}
        explanation={ROYALTY_AMOUNTS_EXPLANATION}
      />
    </>
  );
}
