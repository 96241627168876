import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";

export function emptyToUndefined(str: string | undefined): string | undefined {
  return (str && str.trim()) || undefined;
}

export function mapJobberToStripeAddress(address: BillingAddress) {
  return {
    city: emptyToUndefined(address.city),
    country: emptyToUndefined(address.country_code),
    line1: emptyToUndefined(address.street1),
    line2: emptyToUndefined(address.street2),
    postal_code: emptyToUndefined(address.pc),
    state: emptyToUndefined(address.province),
  };
}
