import React, { useEffect, useState } from "react";
import { Header, Table } from "components/Table";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type {
  JobCostTimeSheetEntryFragment,
  JobDetailsFragment,
  User,
} from "~/utilities/API/graphql";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { getCurrentEndAt, processTimeSheetEntries } from "./utils";
import {
  LabourTableBody,
  LabourTableEmptyState,
  LabourTableFooter,
  LabourTableHeaderColumns,
  LabourTableTitle,
} from "./components";
import styles from "./LabourTable.module.css";
import { LoadPastEntriesButton } from "../LoadPastEntriesButton";

interface LabourTableProps {
  timeSheetEntries?: JobCostTimeSheetEntryFragment[];
  timeSheetEntriesTotalCost: number;
  timeSheetEntriesTotalDuration: number;
  job: JobDetailsFragment;
  hasMoreTimesheets: boolean;
  fetchMoreTimesheets?: () => Promise<void>;
  user?: User;
}

export function LabourTable({
  timeSheetEntries,
  timeSheetEntriesTotalCost,
  timeSheetEntriesTotalDuration,
  job,
  user,
  hasMoreTimesheets,
  fetchMoreTimesheets,
}: LabourTableProps) {
  const { timezone } = useAccount();
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const ticking = timeSheetEntries?.filter(entry => entry.ticking)?.length;
    if (!ticking || ticking <= 0) return;

    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 2000);

    return () => clearInterval(interval);
  }, [timeSheetEntries]);

  if (!timeSheetEntries || timeSheetEntries.length <= 0) {
    return <LabourTableEmptyState job={job} user={user} />;
  }

  const timeSheetData = processTimeSheetEntries(timeSheetEntries, timezone);

  const tickingTimesheetHours = timeSheetData
    .filter(entry => entry.ticking)
    .reduce(
      (acc, ts) =>
        acc + getCurrentEndAt(ts.date.startAt, currentDate, timezone),
      0,
    );

  return (
    <div className={styles.labourTable}>
      <Table>
        <LabourTableColumnGroup />
        <Header>
          <LabourTableTitle job={job} user={user} />
          <LabourTableHeaderColumns />
        </Header>
        <LabourTableBody
          timeSheetData={timeSheetData}
          job={job}
          currentDate={currentDate}
        >
          <LoadPastEntriesButton
            showButton={hasMoreTimesheets}
            onClick={() => fetchMoreTimesheets?.()}
          />
        </LabourTableBody>
        <LabourTableFooter
          hours={timeSheetEntriesTotalDuration}
          cost={timeSheetEntriesTotalCost}
          tickingTimesheetsHours={tickingTimesheetHours}
        />
      </Table>
    </div>
  );
}

function LabourTableColumnGroup() {
  const { can } = useAuthorization();

  const canViewJobCosts = can("view", "JobCosts");

  if (canViewJobCosts) {
    return (
      <colgroup>
        <col style={{ width: `20%` }} />
        <col style={{ width: `38%` }} />
        <col style={{ width: `15%` }} />
        <col style={{ width: `15%` }} />
        <col style={{ width: `12%` }} />
      </colgroup>
    );
  }

  return (
    <colgroup>
      <col style={{ width: `20%` }} />
      <col style={{ width: `51%` }} />
      <col style={{ width: `17%` }} />
      <col style={{ width: `12%` }} />
    </colgroup>
  );
}
