import React, { createRef, useState } from "react";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { Modal } from "@jobber/components/Modal";
import type { VerifyOTPPhoneRailsProps } from "jobber/settings/users/components/VerifyOTPPhone/VerifyOTPPhoneRails";
import {
  VerifyOTPPhoneInput,
  type VerifyOTPPhoneInputRef,
} from "jobber/settings/users/components/VerifyOTPPhone/components/VerifyOTPPhoneInput";

export interface VerifyOTPPhoneModalProps extends VerifyOTPPhoneRailsProps {
  readonly open: boolean;
  onRequestSuccess(): void;
  onRequestClose(): void;
}

export function VerifyOTPPhoneModal({
  open,
  phone,
  onRequestSuccess,
  onRequestClose,
}: VerifyOTPPhoneModalProps) {
  const inputRef = createRef<VerifyOTPPhoneInputRef>();
  const [disabled, setDisabled] = useState(false);

  return (
    <Modal
      title="Add two-step verification"
      open={open}
      primaryAction={{
        label: "Send Code",
        onClick: handleSubmit,
        disabled: disabled,
      }}
      tertiaryAction={{
        label: "Cancel",
        variation: "subtle",
        type: "primary",
        onClick: onRequestClose,
      }}
      onRequestClose={onRequestClose}
    >
      <VerifyOTPPhoneInput
        phone={phone}
        ref={inputRef}
        setDisabled={setDisabled}
        onSuccess={onRequestSuccess}
      >
        <Text>
          Jobber payments requires enabling two-step verification using a
          trusted mobile phone number. Two-step verification is{" "}
          <Emphasis variation="bold">
            designed to prevent anyone else from making important changes to
            your account,
          </Emphasis>{" "}
          even if they know your password.{" "}
          <a
            href={"https://help.getjobber.com/hc/en-us/articles/1500001226241"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </Text>{" "}
      </VerifyOTPPhoneInput>
    </Modal>
  );

  function handleSubmit() {
    setDisabled(true);
    if (inputRef.current) inputRef.current.submit();
  }
}
