import React from "react";
import type { PointTooltipProps } from "@nivo/line";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components";
import type {
  Iso8601DateRange,
  LineChartTooltipNode,
  TooltipData,
} from "jobber/dataVisualizations/types";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { ChartTooltip } from "jobber/dataVisualizations/ChartTooltip";
import {
  formatResponsiveChartTooltipDate,
  getAdditionalDateInformationForTooltipLabel,
} from "jobber/dataVisualizations/utils/tooltipUtils";
import styles from "./LineChartTooltip.module.css";

export interface LineChartTooltipProps {
  chartDataAggregationInterval: string;
  dateRange: Iso8601DateRange;
  pointTooltipData: PointTooltipProps;
  toolTipHeading: string;
  totalsData: LineChartTooltipNode[];
  ySuffix?: string;
}

export function LineChartTooltip({
  chartDataAggregationInterval,
  dateRange,
  pointTooltipData,
  toolTipHeading,
  totalsData,
  ySuffix,
}: LineChartTooltipProps) {
  const { id, data: pointData } = pointTooltipData.point;
  const { calendarFirstDay } = useAccount();
  const weekStartsOnMonday = calendarFirstDay === 1;

  const tooltipData = {
    id,
    indexValue: pointData.xFormatted,
    value: pointData.y as number,
    data: pointData,
  };

  // Line charts must follow the PointTooltipProps interface, which does not allow for additional arbitrary attributes
  // (such as date in a format other than what the user will see on an axis).
  // Instead, we pass the needed date data for tooltips.
  // e.g. for a daily tooltip, all we have is the day of the month, so we need the month and the year.
  const fullDateForLabel = getAdditionalDateInformationForTooltipLabel({
    tooltipData,
    totalsData,
  });

  return (
    <ChartTooltip
      label={formatResponsiveChartTooltipDate({
        chartDataAggregationInterval,
        dateRange,
        fullDate: fullDateForLabel,
        tooltipData: pointTooltipData.point,
        weekStartsOnMonday,
      })}
      content={
        <TooltipComponent
          tooltipData={tooltipData}
          toolTipHeading={toolTipHeading}
          ySuffix={ySuffix}
        />
      }
      boldContent
    />
  );
}

function TooltipComponent({
  tooltipData,
  toolTipHeading,
  ySuffix,
}: {
  tooltipData: TooltipData;
  toolTipHeading: string;
  ySuffix?: string;
}): JSX.Element {
  const mainValue = tooltipData.value;

  return (
    <Text>
      <Emphasis variation={"bold"}>
        {toolTipHeading}
        <span className={styles.lineChartTooltipLabel}>
          {mainValue}
          {ySuffix}
        </span>
      </Emphasis>
    </Text>
  );
}
