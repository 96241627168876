import React from "react";
import { Heading } from "@jobber/components/Heading";
import type { Result, Section } from "./HelpDrawer.d";
import { sanitizeHTML } from "./sanitizeHTML";
import { trackAmplitudeEvent } from "./amplitudeEvent";
import styles from "./ArticleList.module.css";

interface ArticleListProps {
  results: Result[];
  searchTerm: string;
  section: Section;
}

export function ArticleList({
  results,
  searchTerm,
  section,
}: ArticleListProps) {
  const recommendedArticle = searchTerm === "";

  const handleOnClickArticle = (title: string) => () => {
    trackAmplitudeEvent({
      eventName: "Clicked on Help Article in Help Drawer",
      eventProperties: {
        title,
        recommended: recommendedArticle,
      },
      searchTerm,
      section,
    });
  };

  return (
    <ul>
      {results.map((result, index) => {
        const lastCharacterIsPeriod = result.snippet.slice(-1) === ".";
        const ellipses = lastCharacterIsPeriod ? ".." : "...";

        return (
          <li key={index}>
            <a
              href={result.html_url}
              onClick={handleOnClickArticle(result.title)}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.articleListCta}
            >
              <Heading level={4}>{result.title}</Heading>
              <div
                className={styles.articleSnippet}
                // Don't know why this was originally disabled but moving it here so
                // it is not disabled across all these files.
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  __html: sanitizeHTML(result.snippet + ellipses),
                }}
              />
            </a>
          </li>
        );
      })}
    </ul>
  );
}
