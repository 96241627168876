import { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { formatISO, parseISO } from "date-fns";
import type { Iso8601DateTimeRangeInput } from "~/utilities/API/graphql";
import { CampaignEmailFilterFields, Template } from "~/utilities/API/graphql";
import { messages } from "jobber/campaigns/views/CampaignReportingDetailsPage/messages";
import { interactedWithCampaignSummaryAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import type { InputDateRangeRef } from "~/shared/InputDateRange/InputDateRange";
import { type DateRange, DateRanges } from "~/shared/InputDateRange/types";
import type { DateRangeOptions, SortAndFilterState } from "../types";

const JOB_FILTER_INDEX = 0;

export interface EmailFilterOption {
  id: CampaignEmailFilterFields;
  label: string;
}

export interface FilterActions {
  clearFilter(): void;
  setFilterType(newFilter: CampaignEmailFilterFields): void;
}

const DEFAULT_DATE_RANGE_PRESET = DateRanges.all;
const DEFAULT_DATE_RANGE_FILTER = {
  after: undefined,
  before: undefined,
};

export function useCampaignsReportFilters(templateType?: Template) {
  const { formatMessage } = useIntl();

  const [filter, setFilter] = useState<EmailFilterOption | undefined>(
    undefined,
  );

  const inputDateRangeRef = useRef<InputDateRangeRef | undefined>();
  const [dateRangeFilter, setDateRangeFilter] = useState<
    Iso8601DateTimeRangeInput | undefined
  >(undefined);
  const [presetFilter, setPresetFilter] = useState<DateRangeOptions>(
    DEFAULT_DATE_RANGE_PRESET,
  );

  const filterOptions = useMemo(
    () =>
      [
        {
          id: CampaignEmailFilterFields.JOBS,
          label: formatMessage(messages.jobsFilter),
        },
        {
          id: CampaignEmailFilterFields.OPENED,
          label: formatMessage(messages.openedFilter),
        },
        {
          id: CampaignEmailFilterFields.CLICKED,
          label: formatMessage(messages.clickedFilter),
        },
        {
          id: CampaignEmailFilterFields.UNSUBSCRIBED,
          label: formatMessage(messages.unsubscribedFilter),
        },
      ].filter((_option, index) => {
        // filter out job filter option for referral campaigns
        return templateType !== Template.REFERRAL || index !== JOB_FILTER_INDEX;
      }),
    [formatMessage, templateType],
  );

  const clearFilter = () => {
    setFilter(undefined);
    inputDateRangeRef.current?.updatePresetRange({
      preset: DEFAULT_DATE_RANGE_PRESET,
      range: DEFAULT_DATE_RANGE_FILTER,
    });
  };

  const setFilterType = (newFilter: CampaignEmailFilterFields) => {
    interactedWithCampaignSummaryAmplitudeEvent({
      interaction: "Filter Data List",
    });

    // toggle if clicked again
    if (filter && filter.id === newFilter) {
      clearFilter();
    } else {
      const selectedFilterOption = filterOptions.filter(
        option => option.id === newFilter,
      );
      setFilter(selectedFilterOption[0]);
    }
  };

  const setDateRange = ({ after, before }: DateRange) => {
    if (!after || !before) {
      setDateRangeFilter(DEFAULT_DATE_RANGE_FILTER);
      return;
    }
    setDateRangeFilter({
      after: formatISO(after), // Use formatISO to format Date object to ISO string
      before: formatISO(before), // Use formatISO to format Date object to ISO string
    });
  };

  return {
    filter,
    filterOptions,
    actions: {
      setFilterType,
      clearFilter,
    },
    dateRangeFilter: {
      setters: {
        setDateRange,
        setPresetFilter,
      },
      values: {
        dateRangeFilter: isoRangeToDateRange(dateRangeFilter),
        presetFilter,
      },
      refs: {
        inputDateRangeRef,
      },
    } as SortAndFilterState,
  };
}

function isoRangeToDateRange(dateRange: Iso8601DateTimeRangeInput | undefined) {
  return {
    after: dateRange?.after ? parseISO(dateRange.after) : undefined, // Use parseISO to parse ISO string to Date object
    before: dateRange?.before ? parseISO(dateRange.before) : undefined, // Use parseISO to parse ISO string to Date object
  };
}
