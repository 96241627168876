import { gql } from "@apollo/client";

export const GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION = gql`
  mutation googleBusinessAccountDisconnect {
    googleBusinessAccountDisconnect {
      googleBusinessAccount {
        id
        isConnected
      }
      userErrors {
        message
        path
      }
    }
  }
`;
