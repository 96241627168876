import {
  ACTIVATE_GROW_TRIAL,
  GROW_TRIAL_ELIGIBILITY,
} from "./ConnectToGrowTrial.graphql";

export const growTrialEligibilityQueryMock = (eligible: boolean) => {
  return {
    request: {
      query: GROW_TRIAL_ELIGIBILITY,
    },
    result: {
      data: {
        accountFeatureTrialEligibility: {
          eligibleForConnectToGrowTrial: eligible,
          __typename: "AccountFeatureTrialEligibility",
        },
      },
    },
  };
};

export const growTrialActivationMutationRequestMock = (
  includeVars = false,
  deeplinked = false,
) => {
  const additionalVars = {
    variables: {
      input: {
        deeplinked,
      },
    },
  };

  return {
    request: {
      query: ACTIVATE_GROW_TRIAL,
      ...(includeVars && additionalVars),
    },
  };
};

const mutationSuccessResponseMock = {
  membership: {
    id: 1,
    experimentId: 1,
    variationId: 1,
    abTestableId: 1,
    abTestableType: "Account",
  },
  userErrors: [],
};

const mutationFailureResponseMock = {
  membership: null,
  userErrors: [
    {
      message: new Error("Grow trial could not be started. Please try again."),
    },
  ],
};

export const growTrialActivationMutationMock = (
  success: boolean,
  includeRequestVars = false,
  deeplinked = false,
) => {
  const mutationRequest = growTrialActivationMutationRequestMock(
    includeRequestVars,
    deeplinked,
  );

  return {
    ...mutationRequest,
    result: {
      data: {
        activateConnectToGrowTrial: success
          ? mutationSuccessResponseMock
          : mutationFailureResponseMock,
      },
    },
  };
};
