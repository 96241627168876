/* We are depending on CallToAction class being declared in the global scope and windows.  */
declare const CallToAction: {
  postSeen(actionName: string): void;
  postConverted(actionName: string): void;
};

export function CTAMarkSeen(ctaName: string) {
  CallToAction.postSeen(ctaName);
}

export function CTAMarkConverted(ctaName: string) {
  CallToAction.postConverted(ctaName);
}
