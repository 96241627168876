import { Content } from "@jobber/components/Content";
import React, { useReducer, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/components/TimesheetModal/messages";
import {
  useActiveUsersQuery,
  useSaveNewLabourRateMutation,
  useTimesheetDeleteMutation,
  useTimesheetEditMutation,
} from "jobber/workOrders/components/JobCost/hooks";
import { getUTCFromAccountZonedTime } from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import { TimesheetModalContent } from "./TimesheetModalContent";
import styles from "./CreateTimesheetModal.module.css";
import {
  TimesheetModalReducer,
  type TimesheetModalState,
} from "./TimesheetModalReducer";

interface EditTimesheetModalProps {
  jobId: string;
  timeSheetEntryId: string;
  closeModal(): void;
  initialState: TimesheetModalState;
}

export function EditTimesheetModal({
  jobId,
  timeSheetEntryId,
  closeModal,
  initialState,
}: EditTimesheetModalProps) {
  const { formatMessage } = useIntl();
  const account = useAccount();
  const { canViewJobCosts, canEditTimeEntries, canEditLabourRates } =
    useEditTimesheetAuthorizations();

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const [state, dispatch] = useReducer(TimesheetModalReducer, initialState);

  const { users } = useActiveUsersQuery(canViewJobCosts);

  const { handleSubmit } = useTimesheetEditMutation(
    jobId,
    timeSheetEntryId,
    canViewJobCosts,
    closeModal,
  );

  const { handleSaveNewLabourRate } =
    useSaveNewLabourRateMutation(canViewJobCosts);

  const { deleteTimeSheetEntry } = useTimesheetDeleteMutation(
    jobId,
    timeSheetEntryId,
    canViewJobCosts,
    closeModal,
  );

  return (
    <Content>
      <Form onSubmit={handleFormSubmit}>
        <Content>
          <TimesheetModalContent
            canViewJobCosts={canViewJobCosts}
            canEditTime={canEditTimeEntries}
            currencySymbol={account.currencySymbol}
            state={state}
            dispatch={dispatch}
            users={users}
            canEditLabourRates={canEditLabourRates}
          />
          <div className={styles.buttonGroup}>
            {canEditTimeEntries && (
              <div className={styles.leftButtons}>
                <Button
                  variation={"destructive"}
                  type={"secondary"}
                  label={formatMessage(jobCostMessages.delete)}
                  onClick={() => setConfirmDeleteModalOpen(true)}
                />
              </div>
            )}
            <div className={styles.rightButtons}>
              <Button
                label={formatMessage(jobCostMessages.cancel)}
                variation={"subtle"}
                onClick={closeModal}
              />
              <Button
                label={formatMessage(messages.saveTimesheetEntryButtonLabel)}
                submit
              />
            </div>
          </div>
        </Content>
      </Form>
      <ConfirmationModal
        title={formatMessage(
          messages.deleteTimesheetEntryConfirmationModalTitle,
        )}
        message={formatMessage(
          messages.deleteTimesheetEntryConfirmationModalMessage,
        )}
        confirmLabel={formatMessage(jobCostMessages.delete)}
        variation="destructive"
        open={confirmDeleteModalOpen}
        onConfirm={deleteTimeSheetEntry}
        onRequestClose={() => setConfirmDeleteModalOpen(false)}
      />
    </Content>
  );

  function handleFormSubmit() {
    const input = {
      startAt: getUTCFromAccountZonedTime(state.startAt, account.timezone),
      duration: state.duration,
      note: state.note,
      labourRate: state.labourRate,
      userId: state.assignedTo,
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit(input);

    if (
      state.shouldUpdateDefaultLabourRate &&
      state.assignedTo &&
      state.labourRate
    ) {
      handleSaveNewLabourRate({
        userId: state.assignedTo,
        labourRate: state.labourRate,
      });
    }
  }
}

function useEditTimesheetAuthorizations() {
  const { can } = useAuthorization();

  const canViewJobCosts = can("view", "JobCosts");
  const canEditTimeEntries = can("edit", "Timesheets");
  const canEditLabourRates = can("edit", "LabourRates");
  return {
    canViewJobCosts,
    canEditTimeEntries,
    canEditLabourRates,
  };
}
