import React, { useEffect } from "react";
import countryCodeToLabelOverrides from "jobber/payments_sca/components/BillingAddress/BillingAddressFields/countryCodeToLabelOverrides";
import useAddressComponents from "jobber/payments_sca/components/BillingAddress/BillingAddressFields/useAddressComponents";
import { CountrySelect } from "jobber/payments_sca/components/BillingAddress/CountrySelect";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useCountryCodesToNameMap } from "~/utilities/contexts/internal/CountryCodesToNameMapContext";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";

export function JobberPaymentsBillingAddressEdit() {
  const [state, dispatch] = usePaymentReducer();
  const isPaymentInProgress = state.status === "inProgress";
  const { countryCode } = useAccount();
  const address: BillingAddress = state.billingDetails?.address || {};
  const labelOverrides =
    countryCodeToLabelOverrides[address.country_code as string];
  const { street1, street2, city, province, pc } = useAddressComponents({
    address,
    onChange,
    keysToLabelOverride: labelOverrides,
    className: "fieldGroup-field placeholderField",
    disabled: isPaymentInProgress,
  });
  const countryCodesToNameMap = useCountryCodesToNameMap();

  useEffect(() => {
    if (!state.billingDetails?.address?.country_code) {
      dispatch({
        type: "updatingJobberPaymentsBillingAddress",
        billingAddressPartialUpdate: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          country_code: countryCode,
        },
      });
    }
  }, []);

  return (
    <>
      <div
        className="columns u-paddingRightNone u-paddingLeftNone fieldGroup"
        data-testid="billingAddressEdit"
      >
        <div className="row row--fullWidth collapse">
          {street1.node && <div className="columns">{street1.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {street2.node && <div className="columns">{street2.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {city.node && <div className="columns">{city.node}</div>}
          {province.node && <div className="columns">{province.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {pc.node && <div className="columns">{pc.node}</div>}
          <div className="columns">
            <CountrySelect
              className="u-marginNone"
              countryCodesToNameMap={countryCodesToNameMap}
              countryCode={state.billingDetails?.address?.country_code || ""}
              onCountryCodeSelect={onCountryCodeChange}
              disabled={isPaymentInProgress}
            />
          </div>
        </div>
      </div>
    </>
  );

  function onChange(key: keyof BillingAddress, value: string) {
    if (address[key] === value) {
      return;
    }
    dispatch({
      type: "updatingJobberPaymentsBillingAddress",
      billingAddressPartialUpdate: {
        [key]: value,
      },
    });
  }

  function onCountryCodeChange(newCountryCode: string) {
    onChange("country_code", newCountryCode);
  }
}
