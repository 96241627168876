import { strFormatDate } from "@jobber/components/FormatDate";
import { USE_24_HOUR_TIME } from "jobber/campaigns/constants";

export interface useFormatDateTimeProps {
  scheduledAt: string | undefined;
}

interface useFormatDateTime {
  formattedDate: string;
  formattedTime: string;
}
export function useFormatDateTime({
  scheduledAt,
}: useFormatDateTimeProps): useFormatDateTime {
  if (!scheduledAt) {
    return { formattedDate: "", formattedTime: "" };
  }

  const scheduledAtDateTime = new Date(scheduledAt);

  const formattedDate = strFormatDate(scheduledAtDateTime);

  const formattedTime = scheduledAtDateTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hourCycle: USE_24_HOUR_TIME ? "h23" : "h12",
  });

  return { formattedDate, formattedTime };
}
