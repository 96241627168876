import type { StateFrom } from "xstate";
import type { ScheduleCardMachine } from "../scheduleCard.machine";
import { useScheduleCardMachine } from "../ScheduleCardMachineContext";
import type { OnChange, SchedulingState } from "../types";

interface UseSchedulingStateMachine {
  schedulingState: SchedulingState;
  onChange: (type: OnChange) => void;
  stateMatches: StateFrom<ScheduleCardMachine>["matches"];
}

export const useScheduleCardState = (): UseSchedulingStateMachine => {
  const [current, send] = useScheduleCardMachine();

  const onChange = (param: OnChange) => {
    switch (param.action) {
      case "changeStartDate":
        return send({
          type: "CHANGE_START_DATE",
          newStartDate: param.startDate,
        });
      case "changeEndDate":
        return send({
          type: "CHANGE_END_DATE",
          newEndDate: param.endDate,
        });
      case "changeStartTime":
        return send({
          type: "CHANGE_START_TIME",
          updatedStartTime: param.startTime,
        });
      case "changeEndTime":
        return send({
          type: "CHANGE_END_TIME",
          updatedEndTime: param.endTime,
        });
      case "disableTimes":
        return send({
          type: "DISABLE_TIMES",
        });
      case "enableTimes":
        return send({
          type: "ENABLE_TIMES",
        });
      case "scheduleLater":
        return send({
          type: "SCHEDULE_LATER",
        });
      case "scheduled":
        return send({
          type: "SCHEDULED",
        });
      case "addUnscheduledVisit":
        return send({
          type: "ADD_UNSCHEDULED_VISIT",
        });
      case "noUnscheduledVisit":
        return send({
          type: "NO_UNSCHEDULED_VISIT",
        });
      case "selectRepeatOption":
        return send({
          type: "SELECT_REPEAT_OPTION",
          selectedRepeatOption: param.repeatOption,
        });
      case "selectVisitDuration":
        return send({
          type: "SELECT_VISIT_DURATION",
          updatedDuration: param.updatedDuration,
        });
      case "changeSchedulingInputTeamMembers":
        return send({
          type: "CHANGE_SCHEDULING_INPUT_TEAM_MEMBERS",
          teamMemberIds: param.teamMemberIds,
        });
    }
  };
  return {
    schedulingState: current.context,
    onChange,
    stateMatches: current.matches,
  };
};
