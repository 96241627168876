import ConnectSrc from "@images/connect.png";
import MessagingSrc from "@images/messaging.png";
import RequestsSrc from "@images/requests.png";
import ConnectedSrc from "@images/connected.png";
import { messages } from "./messages";
import type { ImageType } from "../../types";

const ConnectImage: ImageType = {
  src: ConnectSrc as string,
  alt: messages.connectImage,
};

const MessagingImage: ImageType = {
  src: MessagingSrc as string,
  alt: messages.messagingImage,
};

const RequestsImage: ImageType = {
  src: RequestsSrc as string,
  alt: messages.requestsImage,
};

const ConnectedImage: ImageType = {
  src: ConnectedSrc as string,
  alt: messages.requestsImage,
};

export { ConnectImage, ConnectedImage, MessagingImage, RequestsImage };
