import { gql } from "@apollo/client";

export const PAYMENT_DISPUTES_QUERY = gql`
  query PaymentDisputesQuery(
    $key: JobberPaymentsDisputesSortKey!
    $direction: SortDirectionEnum!
    $first: Int
    $after: String
    $before: String
  ) {
    jobberPaymentsDisputes(
      sort: { key: $key, direction: $direction }
      after: $after
      before: $before
      first: $first
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          amount
          category
          disputedAt
          evidenceDueBy
          id
          paymentRecord {
            invoice {
              invoiceNumber
            }
            client {
              name
            }
            quote {
              quoteNumber
            }
          }
          reason
          status
        }
        cursor
      }
    }
  }
`;
