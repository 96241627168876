import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { FormatDate } from "@jobber/components/FormatDate";
import { Modal } from "@jobber/components/Modal";
import { useIntl } from "react-intl";
import type {
  AccountingCode,
  File,
  JobDetailsFragment,
  User,
} from "~/utilities/API/graphql";
import type { ExpensesDataEntry } from "jobber/workOrders/components/JobCost/components/ExpensesTable/types";
import { Body, Cell, Row } from "components/Table";
import { CostingGallery } from "jobber/workOrders/components/JobCost/components/CostingGallery";
import { useViewport } from "jobber/hooks/useViewport";
import { formatCurrency } from "utilities/formatCurrency";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { EditExpenseModal } from "./ExpenseModal";
import styles from "./ExpensesTable.module.css";
import { messages } from "./messages";

const RESPONSIVE_SCREEN_WIDTH = 1024;

interface ExpensesTableBodyProps {
  expensesData: ExpensesDataEntry[];
  job: JobDetailsFragment;
  currentUser: User;
  children?: JSX.Element;
}

export function ExpensesTableBody({
  expensesData,
  job,
  currentUser,
  children,
}: ExpensesTableBodyProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<
    ExpensesDataEntry | undefined
  >(undefined);
  const { innerWidth } = useViewport();
  const { currencySymbol } = useAccount();
  const { formatMessage } = useIntl();

  function editExpense(expense: ExpensesDataEntry) {
    setSelectedExpense(expense);
    setModalOpen(true);
  }

  return (
    <Body>
      <>
        {expensesData.map((expenseRow, index) =>
          formatExpenseRow(
            expenseRow,
            index,
            editExpense,
            innerWidth,
            currencySymbol,
          ),
        )}
        {children}
        <Modal
          open={modalOpen}
          onRequestClose={closeModal}
          title={formatMessage(messages.expenseModalEdit)}
        >
          <EditExpenseModal
            jobId={job.id}
            expenseEntryId={selectedExpense?.id || ""}
            initialState={{
              itemName: selectedExpense?.item || "",
              accountingCode: selectedExpense?.accountingCode?.id || "",
              reimburseTo: selectedExpense?.reimbursableTo?.id || "",
              description: selectedExpense?.description || "",
              expenseDate: selectedExpense
                ? new Date(selectedExpense.date)
                : new Date(),
              total: selectedExpense?.amount,
            }}
            initialReceipt={selectedExpense?.receipt}
            closeModal={closeModal}
            currentUser={currentUser}
          />
        </Modal>
      </>
    </Body>
  );

  function closeModal() {
    setModalOpen(false);
  }
}

function formatExpenseRow(
  expenseRow: ExpensesDataEntry,
  index: number,
  editExpense: (expense: ExpensesDataEntry) => void,
  innerWidth: number,
  currencySymbol: string,
) {
  const { item, accountingCode, description, receipt, date, amount } =
    expenseRow;

  const cells = [
    <Cell key={"item"} variation="bold">
      <Text>
        <strong>{item}</strong>
      </Text>
    </Cell>,
    ...generateResponsiveDescriptionCells(
      innerWidth,
      accountingCode,
      description,
      receipt,
    ),
    ...generateReponsiveDateAndAmountCells(
      innerWidth,
      date,
      amount,
      currencySymbol,
    ),
  ];

  return (
    <Row key={index} onClick={() => editExpense(expenseRow)}>
      {...cells}
    </Row>
  );
}

function generateResponsiveDescriptionCells(
  innerWidth: number,
  accountingCode?: AccountingCode,
  description?: string,
  receipt?: File,
) {
  const descriptionBlock = (
    <div className={styles.descriptionBlock}>
      <Text>
        <strong>{accountingCode?.code}</strong>
      </Text>
      <Text>{description}</Text>
    </div>
  );

  const costGallery = receipt?.url && (
    <CostingGallery
      name={receipt.fileName || ""}
      type={receipt.contentType || ""}
      size={receipt.fileSize}
      src={receipt.url}
      progress={1}
    />
  );

  return innerWidth < RESPONSIVE_SCREEN_WIDTH
    ? [
        <Cell key="descriptionGroup">
          <div className={styles.descriptionGroup}>
            {descriptionBlock}
            {costGallery}
          </div>
        </Cell>,
      ]
    : [
        <Cell key={"description"}>{descriptionBlock}</Cell>,
        <Cell key={"image"}>{costGallery}</Cell>,
      ];
}

function generateReponsiveDateAndAmountCells(
  innerWidth: number,
  date: string,
  amount: number,
  currencySymbol: string,
) {
  const dateBlock = (
    <div key={"date"}>
      <Text>
        <FormatDate date={new Date(date)} />
      </Text>
    </div>
  );

  const amountBlock = (
    <span key={"amount"}>{formatCurrency(amount, currencySymbol)}</span>
  );

  return innerWidth < RESPONSIVE_SCREEN_WIDTH
    ? [
        <Cell key={"dateAmount"}>
          <div
            data-testid={"dateAmountGroup"}
            className={styles.dateAmountGroup}
          >
            {dateBlock}
            {amountBlock}
          </div>
        </Cell>,
      ]
    : [
        <Cell key={"date"}>{dateBlock}</Cell>,
        <Cell key={"amount"} className={styles.alignRight}>
          {amountBlock}
        </Cell>,
      ];
}
