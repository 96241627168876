import { gql } from "@apollo/client";
import type { UploadParams } from "@jobber/components/InputFile";
import { APIClient } from "~/utilities/API/APIClient";
import type {
  CreateDirectUploadMutation,
  DirectUploadCompleteMutation,
  DirectUploadDestinations,
  GetInternalUploadAuthorizationQuery,
  MutationErrors,
  UploadDestinationsEnum,
} from "~/utilities/API/graphql";
import { calculateChecksum } from "./fileChecksum";

const uploadAuthorizationData = gql`
  fragment uploadAuthorizationData on UploadAuthorizationInterface {
    url
    fields {
      key
      acl
      policy
      xAmzSignature
      xAmzDate
      xAmzAlgorithm
      xAmzCredential
      contentType
    }
  }
`;

const GET_INTERNAL_UPLOAD_AUTHORIZATION_QUERY = gql`
  query GetInternalUploadAuthorization(
    $filename: String!
    $destination: UploadDestinationsEnum!
    $contentType: String!
  ) {
    uploadAuthorization(
      filename: $filename
      destination: $destination
      contentType: $contentType
    ) {
      ...uploadAuthorizationData
    }
  }

  ${uploadAuthorizationData}
`;

const CREATE_DIRECT_UPLOAD_MUTATION = gql`
  mutation CreateDirectUpload(
    $filename: String!
    $destination: DirectUploadDestinations!
    $contentType: String!
    $fileSize: Int!
    $checksum: String!
  ) {
    directUploadCreate(
      input: {
        filename: $filename
        fileSize: $fileSize
        checksum: $checksum
        contentType: $contentType
      }
      destination: $destination
    ) {
      directUpload {
        id
        url
        parameters {
          value
          name
        }
      }
      userErrors {
        path
        message
      }
    }
  }
`;

export const DIRECT_UPLOAD_COMPLETE_MUTATION = gql`
  mutation DirectUploadComplete($id: EncodedId!) {
    directUploadComplete(directUploadId: $id) {
      userErrors {
        path
        message
      }
      directUpload {
        parameters {
          value
          name
        }
        url
        id
      }
    }
  }
`;

/**
 * Get authorization params for an upload from a private (i.e. requires login) source.
 * Upload destinations are defined on the backend.
 *
 * Some currying shennanigans happening here since we need to capture different
 * variables at different times. `destination` is defined by the caller of the
 * function, `file` is set by the InputFile component that consumes the
 * second function.
 */
export function fetchInternalUploadParams(destination: UploadDestinationsEnum) {
  return async function captureFilename(file: File): Promise<UploadParams> {
    const checksum = await calculateChecksum(file);
    const {
      data: {
        uploadAuthorization: { url, fields },
      },
    } = await APIClient.query<GetInternalUploadAuthorizationQuery>({
      query: GET_INTERNAL_UPLOAD_AUTHORIZATION_QUERY,
      variables: {
        filename: file.name,
        destination,
        contentType: file.type,
        fileSize: file.size,
        checksum: checksum,
      },
    });

    return {
      url,
      key: fields.key,
      fields: {
        key: fields.key,
        acl: fields.acl,
        policy: fields.policy,
        /* eslint-disable @typescript-eslint/naming-convention */
        "x-amz-credential": fields.xAmzCredential,
        "x-amz-algorithm": fields.xAmzAlgorithm,
        "x-amz-date": fields.xAmzDate,
        "x-amz-signature": fields.xAmzSignature,
        /* eslint-enable @typescript-eslint/naming-convention */
        "Content-Type": fields.contentType,
      },
    };
  };
}

export function fetchActiveStorageUploadParams(
  destination: DirectUploadDestinations,
) {
  return async function captureFile(file: File): Promise<UploadParams> {
    const checksum = await calculateChecksum(file);

    const result = await APIClient.mutate<CreateDirectUploadMutation>({
      mutation: CREATE_DIRECT_UPLOAD_MUTATION,
      variables: {
        checksum,
        contentType: file.type,
        destination,
        filename: file.name,
        fileSize: file.size,
      },
    });

    if (!result.data) {
      throw new Error("Failed to get data");
    }

    return {
      url: result.data.directUploadCreate.directUpload?.url || "",
      key: result.data.directUploadCreate.directUpload?.id,
      httpMethod: "PUT",
      fields: result.data.directUploadCreate.directUpload?.parameters.reduce(
        (previousFields, currentField) => {
          return {
            ...previousFields,
            [currentField.name]: currentField.value,
          };
        },
        {},
      ),
    };
  };
}

export async function completeActiveStorageFileDirectUpload({
  key,
}: {
  key: string;
}) {
  try {
    const result = await APIClient.mutate<DirectUploadCompleteMutation>({
      mutation: DIRECT_UPLOAD_COMPLETE_MUTATION,
      variables: {
        id: key,
      },
    });

    const userErrors = result.data?.directUploadComplete
      .userErrors as MutationErrors[];

    if (userErrors.length > 0) {
      throw new Error("Unable to complete upload");
    } else {
      return result.data || undefined;
    }
  } catch (error) {
    throw new Error("Unable to complete upload");
    return;
  }
}
