import { useQuery } from "@apollo/client";
import type { QueryPlansAvailableArgs } from "~/utilities/API/graphql";
import { PlanTier } from "~/utilities/API/graphql";
import {
  LANDING_PAGE_PLAN_QUERY,
  type LandingPagePlanCardQueryPlansAvailableType,
} from "./useLandingPagePlan.graphql";

export function useLandingPagePlan(tier: string) {
  const planTier = PlanTier[tier.toUpperCase() as keyof typeof PlanTier];
  const { data, loading } = useQuery<
    LandingPagePlanCardQueryPlansAvailableType,
    QueryPlansAvailableArgs
  >(LANDING_PAGE_PLAN_QUERY, {
    variables: {
      filter: {
        planTier,
      },
    },
  });

  // default to the plan with the same team size billing option as the current subscription
  const landingPagePlan =
    data?.plansAvailable?.find(
      plan =>
        plan.belugaPhaseOneSingleUserPlan ===
        data?.accountPlanInfo.belugaPhaseOneSingleUserPlan,
    ) || data?.plansAvailable?.[0];

  return { landingPagePlan, loading };
}
