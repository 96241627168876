import React from "react";
import { Option, Select } from "@jobber/components/Select";
import type { FilterDropdownProps } from "jobber/workOrders/types";
import styles from "./TypeFilter.module.css";

const options = ["All", "One-off jobs", "Recurring jobs"];

export const TypeFilter = ({ value, onChangeFilters }: FilterDropdownProps) => {
  const onChange = (type: string) => onChangeFilters({ filterByType: type });

  return (
    <div className={styles.dropdown}>
      <Select
        name="type_filter"
        placeholder="Type"
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};
