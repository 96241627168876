import { useIntl } from "react-intl";
import { useMemo } from "react";
import { DataTable } from "@jobber/components/DataTable";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import type { ReportColumn } from "~/jobber/features/Reporting/components/Report/types";
import { FilterAutocomplete } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/FilterAutocomplete/FilterAutocomplete";
import { isInDesktopView } from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import { useViewport } from "jobber/hooks/useViewport";
import styles from "./ReportTable.module.css";
import { messages } from "./messages";
import type { ReportTableProps } from "./types";

export function ReportTable<T extends object>({
  data,
  loading,
  asyncLoading,
  columns,
  pagination,
  sorting,
  searchFilter,
  stickyHeader = false,
  pinFirstColumn = false,
}: ReportTableProps<T>) {
  const { innerWidth } = useViewport();

  const EMPTY_TABLE_HEIGHT = 600;

  const columnsWithAsyncLoadedColumns = useMemo(
    () =>
      columns.map((column: ReportColumn<T>) => {
        if (column.async && asyncLoading) {
          return {
            ...column,
            cell: () => <Glimmer />,
            ...(column.footer && {
              footer: () => <Glimmer />,
            }),
          };
        }
        return column;
      }),
    [columns, asyncLoading],
  );

  const tableData = data || [];
  const tableLoading = loading || data === undefined;
  const tableIsEmpty = !tableLoading && tableData.length === 0;

  return (
    <div className={styles.tableWrap}>
      {searchFilter && isInDesktopView(innerWidth) && (
        <div className={styles.tableAccessory}>
          <div className={styles.searchContainer}>
            <FilterAutocomplete {...searchFilter} />
          </div>
        </div>
      )}
      <DataTable
        pagination={pagination}
        sorting={sorting}
        data={tableData}
        columns={columnsWithAsyncLoadedColumns}
        height={tableIsEmpty ? EMPTY_TABLE_HEIGHT : undefined}
        emptyState={<DataTableEmptyState />}
        stickyHeader={stickyHeader}
        pinFirstColumn={isInDesktopView(innerWidth) ? pinFirstColumn : false}
        loading={loading || data === undefined}
      />
    </div>
  );
}

function DataTableEmptyState(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.emptyMessage}>
      <Heading level={5}>{formatMessage(messages.tableNoItemsFound)}</Heading>
      <Text>{formatMessage(messages.updateSearchOrFiltersMessage)}</Text>
    </div>
  );
}
