import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  ExpenseEditInput,
  ExpenseEditMutation,
  ExpenseEditMutationVariables,
} from "~/utilities/API/graphql";
import { EXPENSE_EDIT, JOB_COST_EXPENSE_TOTALS_QUERY } from "./graphql";
import { messages } from "./messages";

export function useExpenseEditMutation(
  jobId: string,
  expenseEntryId: string,
  canViewJobCosts: boolean,
  canViewExpenses: boolean,
  onSuccess: () => void,
): {
  handleSubmit: (input: ExpenseEditInput) => Promise<void>;
  loading: boolean;
} {
  const [editExpense, { loading }] = useMutation<
    ExpenseEditMutation,
    ExpenseEditMutationVariables
  >(EXPENSE_EDIT);

  async function handleSubmit(input: ExpenseEditInput) {
    try {
      const result = await editExpense({
        variables: {
          expenseId: expenseEntryId,
          input: {
            ...input,
            accountingCodeId:
              input.accountingCodeId || (null as unknown as undefined),
            reimbursableToId:
              input.reimbursableToId || (null as unknown as undefined),
          },
        },
        refetchQueries: [
          {
            query: JOB_COST_EXPENSE_TOTALS_QUERY,
            variables: {
              jobId: jobId,
              canViewJobCosts: canViewJobCosts,
            },
          },
        ],
      });

      const errors = result.data?.expenseEdit.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        showToast({
          message: messages.expenseSaved,
          variation: "success",
        });
        onSuccess();
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleSubmit,
    loading,
  };
}
