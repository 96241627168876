import React from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import {
  buildOwnershipsString,
  sortGlobalOwnershipDesc,
} from "jobber/settings/users/utilities/globalOwnershipHelpers";
import type {
  ReplyToOwner,
  WorkObjectGlobalOwnershipFragment,
} from "~/utilities/API/graphql";
import {
  buildReplyToMultipleOwnerships,
  buildReplyToOwnersStringWhenSameOwnership,
} from "jobber/features/WorkObjectOwnerships/views/WorkObjectGlobalOwnershipsCard/components/OwnershipRulesText/utils/ownershipRulesUtils";

interface OwnershipRulesTextProps {
  ownerships: WorkObjectGlobalOwnershipFragment[];
}

interface MultipleReplyToRenderStructure {
  workObjectTypes: string[];
  owners: string[];
}

export function OwnershipRulesText({ ownerships }: OwnershipRulesTextProps) {
  const ownershipsSortedDesc = sortGlobalOwnershipDesc(
    ownerships,
  ) as WorkObjectGlobalOwnershipFragment[];

  const replyToOwnerWorkObjects = ownershipsSortedDesc.map(
    (ownership: WorkObjectGlobalOwnershipFragment) => {
      return {
        workObjectTypes: [ownership.workObjectType],
        owners: ownership.replyToOwners.nodes.map((owner: ReplyToOwner) => {
          return owner.name;
        }),
      };
    },
  ) as MultipleReplyToRenderStructure[];

  const ownershipsGroupedByWorkObjectType = replyToOwnerWorkObjects.reduce(
    (
      acc: MultipleReplyToRenderStructure[],
      curr: MultipleReplyToRenderStructure,
    ) => {
      const index = acc.findIndex(
        (ownership: MultipleReplyToRenderStructure) =>
          ownership.owners.join(" ") === curr.owners.join(" "),
      );
      if (index === -1) {
        // acc doesn't have owners yet - add to array
        acc.push(curr);
      } else {
        // acc array already has owner - add work object type to the types array
        acc[index].workObjectTypes = [
          ...acc[index].workObjectTypes,
          curr.workObjectTypes[0],
        ];
      }
      return acc;
    },
    [],
  );

  const ownershipMessages = buildWorkObjectOwnershipMessage(
    ownershipsGroupedByWorkObjectType,
  );

  function buildWorkObjectOwnershipMessage(
    ownersGroupedByWoType: MultipleReplyToRenderStructure[],
  ) {
    const result: JSX.Element[] = [];

    if (ownersGroupedByWoType.length === 1) {
      const text = buildReplyToOwnersStringWhenSameOwnership(
        ownersGroupedByWoType[0].owners,
      );
      result.push(
        <Text>
          {`All email replies and notifications are sent to `}
          <Emphasis variation="bold">{text}</Emphasis>
        </Text>,
      );

      return result;
    } else {
      ownersGroupedByWoType.forEach(ownership => {
        const text = buildReplyToMultipleOwnerships(ownership.owners);
        result.push(
          <Text>
            <Emphasis variation="bold">{text}</Emphasis>
            {` for ${buildOwnershipsString(
              ownership.workObjectTypes,
            ).toLowerCase()}`}
          </Text>,
        );
      });

      return result;
    }
  }

  return (
    <ul className="list list--bulleted u-paddingLeft u-marginBottomNone">
      {ownershipMessages.map((setting: JSX.Element, index: number) => (
        <li key={index} className="list-item" data-testid="global-ownership">
          {setting}
        </li>
      ))}
    </ul>
  );
}
