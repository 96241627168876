import React from "react";
import { WorkflowButton } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/WorkflowButton";
import { BubbleWrapper } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/BubbleWrapper";
import { FileAttachment } from "jobber/features/MessageCenter/views/Conversation/components/MessageBubble/components/FileAttachment";
import type {
  File,
  MessageDataEmailMessageFragment,
} from "~/utilities/API/graphql";
import styles from "./Content.module.css";

interface EmailContentProps {
  readonly message: MessageDataEmailMessageFragment;
}

export function EmailContent({ message }: EmailContentProps) {
  return (
    <BubbleWrapper direction={message.direction} status={message.status}>
      <div className={styles.flexColumn}>
        <h5 className={styles.marginBase}>{message.subject}</h5>

        {message.content}

        <FileAttachment files={message.files.nodes as File[]} />
        {message?.linkableWorkObject && <WorkflowButton message={message} />}
      </div>
    </BubbleWrapper>
  );
}
