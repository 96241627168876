import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const MARK_DEMO_CAMPAIGN_SENT = gql`
  mutation markDemoCampaignSent(
    $campaignId: EncodedId!
    $input: MarkDemoCampaignSentInput
  ) {
    markDemoCampaignSent(campaignId: $campaignId, input: $input) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
