import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { ClientPaymentMethods } from "./ClientPaymentMethods";

export interface ClientPaymentMethodsLoaderProps {
  clientId: string;
  canAddPaymentMethod: boolean;
  countryCodesToNameMap: Record<string, string>;
  requestCardOnFileEmailUrl: string;
  requestCardOnFileSmsUrl: string;
  hasAutoPayJob: boolean;
}

export function ClientPaymentMethodsLoader(
  props: ClientPaymentMethodsLoaderProps,
) {
  const { hasAutoPayJob } = props;

  return (
    <IntlProvider>
      <APIProvider>
        <ClientPaymentMethods
          clientId={props.clientId}
          canAddPaymentMethod={props.canAddPaymentMethod}
          countryCodesToNameMap={props.countryCodesToNameMap}
          requestCardOnFileEmailUrl={props.requestCardOnFileEmailUrl}
          requestCardOnFileSmsUrl={props.requestCardOnFileSmsUrl}
          hasAutoPayJob={hasAutoPayJob}
        />
      </APIProvider>
    </IntlProvider>
  );
}
