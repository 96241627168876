export interface CostItem {
  defaultUnitCost: number;
  internalUnitCost?: number;
  markup?: number;
}

export interface UpdateCostAction {
  prop: keyof CostItem;
  value: number;
}

export function calculateCostFields(
  prevState: CostItem,
  action: UpdateCostAction,
): CostItem {
  const property = action.prop;

  let costPermutation: CostItem;
  switch (property) {
    case "defaultUnitCost":
      costPermutation = onUnitCostChanged(prevState, action.value);
      break;
    case "markup":
      costPermutation = onMarkupChanged(prevState, action.value);
      break;
    case "internalUnitCost":
      costPermutation = onInternalUnitCostChanged(prevState, action.value);
      break;
    default:
      return { ...prevState };
  }
  return {
    internalUnitCost: costPermutation.internalUnitCost,
    markup: costPermutation.markup,
    defaultUnitCost: costPermutation.defaultUnitCost,
  };

  function onUnitCostChanged(
    previous: CostItem,
    defaultUnitCost: number,
  ): CostItem {
    const internalUnitCost = previous.internalUnitCost;
    const markup = calculateMarkup(defaultUnitCost, internalUnitCost || 0);

    return { internalUnitCost, markup, defaultUnitCost };
  }

  function onMarkupChanged(previous: CostItem, newMarkup: number): CostItem {
    const markup = newMarkup;

    const internalUnitCost = previous.internalUnitCost;
    let defaultUnitCost = previous.defaultUnitCost;

    if (internalUnitCost !== 0) {
      defaultUnitCost = calculateUnitCost(internalUnitCost || 0, markup);
    }

    return { internalUnitCost, markup, defaultUnitCost };
  }

  function onInternalUnitCostChanged(
    prev: CostItem,
    internalUnitCost: number,
  ): CostItem {
    const defaultUnitCost = prev.defaultUnitCost;
    const markup = calculateMarkup(defaultUnitCost, internalUnitCost);

    return { internalUnitCost, markup, defaultUnitCost };
  }
}

export function calculateMarkup(
  defaultUnitCost?: number,
  internalUnitCost?: number,
) {
  if (!internalUnitCost || defaultUnitCost === undefined) {
    return 0;
  }
  return ((defaultUnitCost - internalUnitCost) / internalUnitCost) * 100;
}

export function calculateUnitCost(internalUnitCost: number, markup: number) {
  return internalUnitCost + (internalUnitCost * markup) / 100;
}
