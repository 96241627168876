import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type {
  ExpenseDeleteMutation,
  ExpenseDeleteMutationVariables,
} from "~/utilities/API/graphql";
import { EXPENSE_DELETE, JOB_COST_EXPENSE_TOTALS_QUERY } from "./graphql";
import { messages } from "./messages";

export function useExpenseDeleteMutation(
  jobId: string,
  expenseId: string,
  canViewJobCosts: boolean,
  closeModal: () => void,
): {
  handleDeleteExpense(): Promise<void>;
  loading: boolean;
} {
  const [deleteExpense, { loading }] = useMutation<
    ExpenseDeleteMutation,
    ExpenseDeleteMutationVariables
  >(EXPENSE_DELETE, {
    refetchQueries: [
      {
        query: JOB_COST_EXPENSE_TOTALS_QUERY,
        variables: {
          jobId: jobId,
          canViewJobCosts: canViewJobCosts,
        },
      },
    ],
    update(cache, result) {
      if (result?.data?.expenseDelete.deletedExpense) {
        cache.evict({
          id: cache.identify(result.data.expenseDelete.deletedExpense),
        });

        cache.gc();
      }
    },
  });

  async function handleDeleteExpense() {
    try {
      const result = await deleteExpense({
        variables: {
          expenseId,
        },
      });

      const errors = result.data?.expenseDelete.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: messages.errorMessage,
          variation: "error",
        });
      } else {
        closeModal();
        showToast({
          message: messages.expenseDeleted,
          variation: "success",
        });
      }
    } catch (e) {
      showToast({
        message: messages.errorMessage,
        variation: "error",
      });
    }
  }

  return {
    handleDeleteExpense,
    loading,
  };
}
