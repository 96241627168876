import userEvent from "@testing-library/user-event";
import { screen, waitFor } from "@testing-library/react";
import type { MessageDescriptor } from "react-intl";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages as billingAddressMessages } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/messages";
import { messages as billingAddressPreviewMessages } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressPreview/messages";
import { messages as billingAddressEditMessages } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressEdit/messages";
import { messages as billingAddressDisplayMessages } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressDisplay/messages";
import { messages as paymentDetailsMessages } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/messages";
import { messages as paymentDetailsDisplayMessages } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsDisplay/messages";
import { messages as paymentDetailsEditMessage } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsEdit/messages";
import { messages as editBillingInfoMessages } from "jobber/billing/components/EditBillingInfo/messages";

const { formatMessage } = createIntl();

function getInput(messageDescriptor: MessageDescriptor) {
  return screen.getByLabelText(
    formatMessage(messageDescriptor),
  ) as HTMLInputElement;
}

function getButton(messageDescriptor: MessageDescriptor) {
  return screen.getByText(formatMessage(messageDescriptor))
    .parentElement as HTMLButtonElement;
}

// Payment Details
export function paymentDetailsCardTitle() {
  return screen.getByText(formatMessage(paymentDetailsMessages.cardTitle));
}

export function editPaymentButton() {
  return getButton(paymentDetailsMessages.editPaymentButton);
}

export function editPaymentDetailsButton() {
  return screen.getByLabelText("Edit payment details");
}

export function firstNameInput() {
  return getInput(paymentDetailsEditMessage.firstNamePlaceholder);
}

export function firstNameValidationError() {
  return screen.getByText("First name is required");
}

export function lastNameInput() {
  return getInput(paymentDetailsEditMessage.lastNamePlaceholder);
}

export function lastNameValidationError() {
  return screen.getByText("Last name is required");
}

export function firstNameRecurlyInput() {
  return screen.getByTestId("first_name_recurly") as HTMLInputElement;
}

export function lastNameRecurlyInput() {
  return screen.getByTestId("last_name_recurly") as HTMLInputElement;
}

export function cardNumberInput() {
  return screen.getByText("Mock CardNumberElement");
}

export function cardExpirationDisplayLabel() {
  return screen.getByText(
    formatMessage(paymentDetailsDisplayMessages.expirationLabel),
  );
}

export function defaultPaymentDetailsError() {
  return screen.getByText(
    formatMessage(editBillingInfoMessages.recurlyDefaultError),
  );
}

export function threeDSecureChallenge() {
  return screen.getByText(
    formatMessage(editBillingInfoMessages.additionalVerificationRequired),
  );
}

export function threeDSecureError() {
  return screen.getByText(
    formatMessage(editBillingInfoMessages.recurly3dsAuthError),
  );
}

// Billing Address
export function billingAddressCardTitle() {
  return screen.getByText(formatMessage(billingAddressMessages.cardTitle));
}

export function editBillingButton() {
  return getButton(billingAddressPreviewMessages.editBillingButton);
}

export function editBillingAddressButton() {
  return screen.getByLabelText("Edit billing address");
}

export function streetAddressInput() {
  return getInput(billingAddressEditMessages.streetAddressPlaceholder);
}

export function countryInput() {
  return getInput(billingAddressEditMessages.countryPlaceholder);
}

export function stateInput() {
  return getInput(billingAddressEditMessages.statePlaceholder);
}

export function provinceInput() {
  return getInput(billingAddressEditMessages.provincePlaceholder);
}

export function zipCodeInput() {
  return getInput(billingAddressEditMessages.zipCodePlaceholder);
}

export function postalCodeInput() {
  return getInput(billingAddressEditMessages.postalCodePlaceholder);
}

export function billingEmailDisplayLabel() {
  return screen.getByText(
    formatMessage(billingAddressDisplayMessages.emailLabel),
  );
}

export function billingEmailInput() {
  return getInput(billingAddressEditMessages.emailAddressPlaceholder);
}

export function billingEmailValidationError() {
  return screen.getByText("Email address is required");
}

// Interactions
export function iTriggerCardValidation() {
  // Recurly will trigger an onChange event when they initialize the credit card fields
  // Simulating that here
  const cardNumberElement = screen.getByTestId(
    "cardNumber",
  ) as HTMLInputElement;
  userEvent.clear(cardNumberElement);
}

export function iClickAndLeaveFirstName() {
  firstNameInput().focus();
  firstNameInput().blur();
}

export function iClickAndLeaveLastName() {
  lastNameInput().focus();
  lastNameInput().blur();
}

export function iClickAndLeaveBillingEmail() {
  billingEmailInput().focus();
  billingEmailInput().blur();
}

export function iEnterFirstName(value: string) {
  userEvent.type(firstNameInput(), value);
}

export function iEnterLastName(value: string) {
  userEvent.type(lastNameInput(), value);
}

export function iClearStreetAddress() {
  userEvent.clear(streetAddressInput());
}

export function iEnterStreetAddress(value: string) {
  userEvent.type(streetAddressInput(), value);
}

export function iEnterBillingEmailAddress(value: string) {
  userEvent.type(billingEmailInput(), value);
}

export function iSelectCountry(value: string) {
  userEvent.selectOptions(countryInput(), value);
}

export function iSelectProvince(value: string) {
  userEvent.selectOptions(provinceInput(), value);
}

export function iSelectState(value: string) {
  userEvent.selectOptions(stateInput(), value);
}

export function iClickEditPayment() {
  userEvent.click(editPaymentButton());
}

export function iClickEditBilling() {
  userEvent.click(editBillingButton());
}

export function iClickEditPaymentDetails() {
  userEvent.click(editPaymentDetailsButton());
}

export function iClickEditBillingAddress() {
  userEvent.click(editBillingAddressButton());
}

export function iModifyBillingAddress() {
  iClickEditBillingAddress();
  userEvent.click(screen.getByText("Street address"));
  userEvent.keyboard("123 Main St");
}

export async function waitForDisplayToLoad() {
  await waitFor(() => {
    expect(paymentDetailsCardTitle()).toBeDefined();
    expect(billingAddressCardTitle()).toBeDefined();
  });
}
