export const messages = {
  searchProductOrService: {
    title: "Search Products / Service...",
    description: "Watermark for product or service search input",
  },
  createNew: {
    title: "Create New",
    description: "Create new button label for opening create product modal",
  },
  or: {
    title: "or",
    description: "Word in between search input option and new button option",
  },
};
