import { gql } from "@apollo/client";

// ID is intentionally excluded from the custom field values since they will be null
// and it messes with apollo cache
export const CUSTOM_FIELD_TRANSFERABLE_FRAGMENT = gql`
  fragment CustomFieldTransferableDefaults on CustomFieldUnion {
    ... on CustomFieldText {
      label
      valueText
      customFieldConfiguration {
        id
      }
    }
    ... on CustomFieldDropdown {
      label
      valueDropdown
      dropdownOptions
      customFieldConfiguration {
        id
      }
    }
    ... on CustomFieldNumeric {
      label
      valueNumeric
      unit
      customFieldConfiguration {
        id
      }
    }
    ... on CustomFieldTrueFalse {
      label
      valueTrueFalse
      customFieldConfiguration {
        id
      }
    }
    ... on CustomFieldArea {
      label
      valueArea {
        length
        width
      }
      unit
      customFieldConfiguration {
        id
      }
    }
    ... on CustomFieldLink {
      label
      valueLink {
        text
        url
      }
      customFieldConfiguration {
        id
      }
    }
  }
`;

export const QUOTE_CUSTOM_FIELD_DEFAULTS_QUERY = gql`
  query quoteDefaultCustomFieldValues(
    $clientId: EncodedId
    $propertyId: EncodedId
  ) {
    defaultCustomFieldValues: quoteDefaultCustomFieldValues(
      clientId: $clientId
      propertyId: $propertyId
    ) {
      nodes {
        ...CustomFieldTransferableDefaults
      }
    }
  }
  ${CUSTOM_FIELD_TRANSFERABLE_FRAGMENT}
`;

export const JOB_CUSTOM_FIELD_DEFAULTS_QUERY = gql`
  query jobDefaultCustomFieldValues(
    $clientId: EncodedId
    $quoteId: EncodedId
    $propertyId: EncodedId
  ) {
    defaultCustomFieldValues: jobDefaultCustomFieldValues(
      clientId: $clientId
      quoteId: $quoteId
      propertyId: $propertyId
    ) {
      nodes {
        ...CustomFieldTransferableDefaults
      }
    }
  }
  ${CUSTOM_FIELD_TRANSFERABLE_FRAGMENT}
`;
