import { Button } from "@jobber/components/Button";
import React, { type MutableRefObject, useRef, useState } from "react";
import type { AppAlert } from "./AppAlerts.types";
import styles from "./AppAlertsButton.module.css";
import { AppAlertsList } from "./components/appAlertsList";
import { AppAlertsNotificationBadge } from "./components/appAlertsNotificationBadge";
import { SG1PopDown } from "./components/sg1Popdown";

interface AppAlertsButtonProps {
  appAlerts: AppAlert[];
}

export function AppAlertsButton({ appAlerts }: AppAlertsButtonProps) {
  const totalAlertCount = appAlerts.reduce(
    (accumulator, appAlert) => accumulator + appAlert.count,
    0,
  );
  const [showPopDown, setShowPopdown] = useState(false);
  const buttonContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  if (totalAlertCount === 0) {
    return <></>;
  }
  return (
    <div>
      <div className={styles.appAlertsButtonContainer}>
        <div ref={buttonContainerRef}>
          <Button
            type="tertiary"
            variation="subtle"
            icon="syncAlert"
            ariaLabel={"Open App Alerts"}
            onClick={() => {
              setShowPopdown(!showPopDown);
            }}
          />
          <AppAlertsNotificationBadge count={totalAlertCount} />
        </div>
      </div>
      <SG1PopDown
        title={"App Alerts"}
        closePopDown={() => {
          setShowPopdown(false);
        }}
        attachTo={buttonContainerRef}
        open={showPopDown}
      >
        <AppAlertsList appAlerts={appAlerts} />
      </SG1PopDown>
    </div>
  );
}
