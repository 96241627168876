import React, { type ReactElement } from "react";
import { Row } from ".";

interface HeaderColumnsProps {
  testId?: string;
  children: ReactElement | ReactElement[];
}

export function HeaderColumns({ testId, children }: HeaderColumnsProps) {
  return <Row testId={testId}>{children}</Row>;
}
