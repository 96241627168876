import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deleteCampaignModalTitle: {
    id: "campaigns.deleteCampaignModal.title",
    defaultMessage: "Delete campaign?",
    description: "Delete campaign modal title",
  },
  deleteCampaignModalMessage: {
    id: "campaigns.deleteCampaignModal.message",
    defaultMessage:
      "Deleting this campaign will permanently remove it from Jobber.",
    description: "Delete campaign modal message",
  },
  deleteCampaignModalButtonText: {
    id: "campaigns.deleteCampaignModal.buttonText",
    defaultMessage: "Delete",
    description: "Delete campaign modal button text",
  },
  deleteCampaignToast: {
    id: "campaigns.deleteCampaignModal.successToast",
    defaultMessage: "Deleted campaign",
    description: "Delete campaign modal button text",
  },
});
