import React from "react";
import { Icon } from "@jobber/components/Icon";

interface VehicleTrackerIconProps {
  customColor?: string;
}

export function VehicleTrackerIcon({ customColor }: VehicleTrackerIconProps) {
  return (
    <>
      <Icon name="truck" customColor={customColor} />
    </>
  );
}
