import { useEffect } from "react";
import type { CommsCampaignTemplate } from "jobber/campaigns/views/CampaignsContentPage/hooks";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";

export function useCommsContentState(template: CommsCampaignTemplate) {
  const {
    campaignContent: {
      isDirty,
      setIsDirty,
      setBody,
      setHeader,
      setSubject,
      setShowLogo,
      setCtaButtonEnabled,
      setLinkTo,
      setLinkValue,
      setButtonText,
      setButtonColor,
      setTemplateType,
      setHeaderImage,
      setReferralLink,
      setVariables,
    },
  } = useCampaignWizardContext();

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (template !== undefined && !isDirty) {
      setIsDirty(false);
      setSubject(template.subject, false);
      setHeader(template.header, false);
      setBody(template.body, false);
      setShowLogo(template.showLogo, false);
      setCtaButtonEnabled(template.ctaButtonEnabled, false);
      setLinkTo(template.linkTo, false);
      setLinkValue(template.linkValue, false);
      setButtonText(template.buttonText, false);
      template.buttonColor && setButtonColor(template.buttonColor, false);
      setTemplateType(template.type);
      setHeaderImage(template.headerImage, false);
      setReferralLink(template.referralLink);
      setVariables(template.variables);
    }
  }, [
    template,
    setSubject,
    setHeader,
    setBody,
    setShowLogo,
    setIsDirty,
    isDirty,
    setCtaButtonEnabled,
    setLinkTo,
    setLinkValue,
    setButtonText,
    setButtonColor,
    setTemplateType,
    setHeaderImage,
    setReferralLink,
    setVariables,
  ]);
}
