import React, { useState } from "react";
import {
  type EmailPickerProps,
  EmailRecipientPicker,
} from "jobber/recipientPicker/components/EmailRecipientPicker";
import {
  EmailMessage,
  type EmailMessageProps,
} from "jobber/email/EmailMessage";

export interface EmailControlProps {
  emailPickerProps: EmailPickerProps;

  emailMessageProps: Pick<
    EmailMessageProps,
    "sendSurvey" | "showSurvey" | "sendMeCopy"
  > & {
    allowMagicField?: boolean;
  };

  /** Initial value of the email subject provided by the webserver */
  defaultEmailSubject?: string;

  /** Initial value of the email message provided by the webserver */
  defaultEmailMessage?: string;
}

/* The complete email control container */
export function EmailControl({
  emailPickerProps,
  emailMessageProps,
  defaultEmailSubject,
  defaultEmailMessage,
}: EmailControlProps) {
  const [emailSubject, setEmailSubject] = useState(defaultEmailSubject || "");
  const [emailMessage, setEmailMessage] = useState(defaultEmailMessage || "");
  const [replyToEmail, setReplyToEmail] = useState("");

  const messageProps = {
    ...emailMessageProps,
    emailSubject,
    emailMessage,
    setEmailSubject,
    setEmailMessage,
  };
  const pickerProps = {
    ...emailPickerProps,
    onReplyToChange: onReplyToChange,
  };

  function onReplyToChange(newValue: string, defaultEmail: string) {
    setEmailMessage(
      emailMessage.replace(replyToEmail || defaultEmail, newValue),
    );
    setReplyToEmail(newValue);
  }

  return (
    <>
      <EmailRecipientPicker {...pickerProps} />
      <EmailMessage {...messageProps} />
    </>
  );
}
