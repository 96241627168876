import React, { useEffect } from "react";
import { Content } from "@jobber/components/Content";
import { IntegrationPageHeader } from "jobber/marketplace/views/IntegrationPage/layout/IntegrationPageHeader";
import { IntegrationPageSecondaryContent } from "jobber/marketplace/views/IntegrationPage/layout/IntegrationPageSecondaryContent";
import { IntegrationPageMainContent } from "jobber/marketplace/views/IntegrationPage/layout/IntregrationPageMainContent";
import { IntegrationStateProvider } from "jobber/marketplace/views/IntegrationPage/hooks/useIntegrationState";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "~/utilities/analytics/ANALYTICS_EVENTS";
import { ANALYTICS_PROPERTIES } from "jobber/marketplace/ANALYTICS_EVENTS";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./IntegrationPage.module.css";
import type { IntegrationPageProps } from "./IntegrationPageProps";

export function IntegrationPage(props: IntegrationPageProps) {
  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedPage, {
      appId: props.id,
      name: props.title,
      source: ANALYTICS_PROPERTIES.appDetailsSource,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      public_marketplace: props.publicFacing,
    });
  }, []);

  return (
    <IntlProvider>
      <div className={styles.integrationPage}>
        <APIProvider>
          <IntegrationStateProvider defaultState={props}>
            <Content>
              <IntegrationPageHeader />
              <div className={styles.body}>
                <div className={styles.twoThirdsGrid}>
                  <div>
                    <IntegrationPageMainContent />
                  </div>
                  <div>
                    <IntegrationPageSecondaryContent />
                  </div>
                </div>
              </div>
            </Content>
          </IntegrationStateProvider>
        </APIProvider>
      </div>
    </IntlProvider>
  );
}
