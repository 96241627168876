import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import {
  ReviewSummaryCard,
  type ReviewSummaryCardProps,
} from "./ReviewSummaryCard";

export function ReviewSummaryCardLoader(props: ReviewSummaryCardProps) {
  return (
    <IntlProvider>
      <ReviewSummaryCard {...props} />
    </IntlProvider>
  );
}
