import { gql } from "@apollo/client";

export const WORK_OBJECT_GLOBAL_OWNERSHIP_MUTATION = gql`
  mutation globalOwnershipEdit(
    $ownerships: WorkObjectGlobalOwnershipsEditInput!
  ) {
    workObjectGlobalOwnershipsEdit(
      workObjectGlobalOwnershipAttributes: $ownerships
    ) {
      workObjectGlobalOwnerships {
        id
      }
    }
  }
`;
