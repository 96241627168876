import React from "react";
import {
  type BaseFileInterface,
  SmsMediaContentTypeEnum,
} from "~/utilities/API/graphql";
import { AttachmentLink } from "jobber/chat/components/ChatDrawer/Chat/ChatBubble/AttachmentLink";

interface SmsFileAttachmentProps {
  readonly files: BaseFileInterface[];
}

export function SmsFileAttachment({ files }: SmsFileAttachmentProps) {
  return (
    <>
      {files.map((object: BaseFileInterface, itemKey: number) => {
        const label = getAttachmentLabel(object.contentType || "");

        return (
          <AttachmentLink
            key={`${label}_${itemKey}`}
            label={`View ${label}`}
            contentLocation={object.url || ""}
            contentType={label}
          />
        );
      })}
    </>
  );

  function getAttachmentLabel(contentType: string) {
    const contentPrefix = contentType.split("/")[0].toUpperCase();

    return contentPrefix !== SmsMediaContentTypeEnum.IMAGE &&
      contentPrefix !== SmsMediaContentTypeEnum.VIDEO
      ? "ATTACHMENT"
      : contentPrefix;
  }
}
