import { v4 as uuidV4 } from "uuid";
import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import type {
  AiAssistantTextReviseContentType,
  AiAssistantTextReviseIntent,
  AiAssistantTextReviseMutation,
  AiAssistantTextReviseMutationVariables,
  AiAssistantTextReviseOperation,
} from "~/utilities/API/graphql";
import { AI_ASSISTANT_TEXT_REWRITE } from "./AiAssistantTextRewrite.graphql";
import type { AdditionalInstructions } from "../types";

export function useTextRewrite(
  contentType: AiAssistantTextReviseContentType,
  intent: AiAssistantTextReviseIntent | undefined,
  additionalInstructions: AdditionalInstructions,
) {
  const [conversationId] = useState(uuidV4());
  const [rewriteText] = useMutation<
    AiAssistantTextReviseMutation,
    AiAssistantTextReviseMutationVariables
  >(AI_ASSISTANT_TEXT_REWRITE);

  return useCallback(
    (submission: string, operation: AiAssistantTextReviseOperation) => {
      return rewriteText({
        variables: {
          conversationId,
          input: {
            contentType,
            intent,
            operation,
            submission,
            additionalInstructions: additionalInstructions
              ? JSON.stringify(additionalInstructions)
              : undefined,
          },
        },
      });
    },
    [contentType, intent, additionalInstructions, conversationId, rewriteText],
  );
}
