import { useCallback, useRef } from "react";
import type { AddressValues } from "./types";

type GoogleApi = typeof google;

interface Parameters {
  google: GoogleApi;
  autocompleteService?: google.maps.places.AutocompleteService;
  placesService?: google.maps.places.PlacesService;
  locationBias?: google.maps.places.LocationBias;
}

export function useAddressAutocompleteResults({
  google,
  locationBias = "IP_BIAS",
  autocompleteService,
  placesService,
}: Parameters) {
  const autocomplete = useRef<google.maps.places.AutocompleteService>(
    autocompleteService || new google.maps.places.AutocompleteService(),
  );

  const places = useRef<google.maps.places.PlacesService>(
    placesService ||
      new google.maps.places.PlacesService(document.createElement("div")),
  );

  const sessionTokenRef = useRef<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);

  const predictions = useCallback(
    async (input: string) => {
      if (input === "") {
        return [];
      }

      sessionTokenRef.current ??=
        new google.maps.places.AutocompleteSessionToken();

      const { predictions: results } =
        await autocomplete.current.getPlacePredictions({
          input,
          locationBias,
          sessionToken: sessionTokenRef.current,
        });

      return results.map(result => ({
        label: result.description,
        value: result.place_id,
      }));
    },
    [google.maps.places.AutocompleteSessionToken, locationBias],
  );

  const addressFromPlace = useCallback(
    (placeId: string, fields = ["address_components"]) =>
      new Promise<Partial<AddressValues>>((resolve, reject) => {
        places.current.getDetails(
          {
            placeId,
            fields,
            sessionToken: sessionTokenRef.current,
          },
          (result, status) => {
            if (status == "OK" && result) {
              sessionTokenRef.current = undefined;
              resolve(mapPlaceToAddress(result));
            } else {
              reject(status);
            }
          },
        );
      }),
    [],
  );

  return { predictions, addressFromPlace };
}

function mapPlaceToAddress(
  place: google.maps.places.PlaceResult,
): AddressValues {
  const addressComponents = new Map();
  place.address_components?.forEach(component =>
    component.types.forEach(type =>
      addressComponents.set(type, component.long_name),
    ),
  );

  const location = place.geometry?.location
    ? {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }
    : undefined;

  return {
    location,
    formatted: place.formatted_address ?? "",
    street1: formatStreet1(addressComponents) ?? "",
    street2: formatStreet2(addressComponents) ?? "",
    city: formatCity(addressComponents) ?? "",
    stateOrProvince: formatStateOrProvince(addressComponents) ?? "",
    zipOrPostalCode: formatZipOrPostalCode(addressComponents) ?? "",
    country: formatCountry(addressComponents) ?? "",
  };

  function formatStreet1(address: Map<string, string>) {
    return [address.get("street_number"), address.get("route")]
      .filter(component => component ?? false)
      .join(" ");
  }

  function formatStreet2(address: Map<string, string>) {
    if (
      !address.has("street_number") &&
      !address.has("route") &&
      address.has("neighborhood")
    ) {
      return address.get("neighborhood");
    } else {
      return address.get("subpremise") || address.get("sublocality");
    }
  }

  function formatCity(address: Map<string, string>) {
    switch (address.get("country")) {
      case "United Kingdom":
        return address.get("postal_town") || address.get("locality");
      default:
        return address.get("locality");
    }
  }

  function formatStateOrProvince(address: Map<string, string>) {
    switch (address.get("country")) {
      case "United Kingdom":
        return "";
      default:
        return address.get("administrative_area_level_1");
    }
  }

  function formatZipOrPostalCode(address: Map<string, string>) {
    return address.get("postal_code");
  }

  function formatCountry(address: Map<string, string>) {
    return address.get("country");
  }
}
