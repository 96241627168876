import React from "react";
import type { IconNames } from "@jobber/design";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import styles from "./styles.module.css";

interface LoadingAndErrorRecommendationButtonProps {
  heading: string;
  icon: IconNames;
  loading: boolean;
  errorMessage?: RecommendationButtonErrorMessage;
}
export interface RecommendationButtonErrorMessage {
  message: string;
  style: "error" | "subdued";
  icon?: IconNames;
}

export function LoadingAndErrorRecommendationButton({
  heading,
  icon,
  loading,
  errorMessage,
}: LoadingAndErrorRecommendationButtonProps) {
  return (
    <label
      className={styles.recommendationButtonContainer}
      aria-label={errorMessage ? "Error" : "loading"}
    >
      <input
        type="radio"
        name="recommendation"
        disabled
        className={styles.recommendationInput}
      />
      <div className={styles.cardContent}>
        <div className={styles.title}>
          <Heading level={4}>{heading}</Heading>
          <Icon name={icon} color="lightBlue" size="base" />
        </div>
        <div className={styles.recommendationDetails}>
          <ErrorOrLoading loading={loading} errorMessage={errorMessage} />
        </div>
      </div>
    </label>
  );
}

interface ErrorOrLoadingProps {
  loading: boolean;
  errorMessage?: RecommendationButtonErrorMessage;
}

function ErrorOrLoading({ loading, errorMessage }: ErrorOrLoadingProps) {
  if (loading) {
    return <Glimmer size={"auto"} reverseTheme={false} />;
  }

  return (
    <div className={styles.errorMessageContainer}>
      {errorMessage?.icon && (
        <Icon name={errorMessage.icon} color="destructive" size="small" />
      )}
      <Text variation={errorMessage?.style}>{errorMessage?.message}</Text>
    </div>
  );
}
