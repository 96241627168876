import { defineMessages } from "react-intl";

export const messages = defineMessages({
  overdueItems: {
    id: "dashboard.components.scheduledItemsOverdueInsight.overdueItems",
    defaultMessage: "Overdue items",
    description: "The overdue items insights card",
  },
  overdueItemsDescription: {
    id: "dashboard.components.projectedRevenueInsights.overdueItemsDescription  ",
    defaultMessage:
      "You have {overdueItemsTotal} overdue {overdueItemsTotal, plural, one {item} other {items}} in the last year.",
    description: "The description of overdue items on the insights card",
  },
  viewCalendar: {
    id: "dashboard.components.projectedRevenueInsights.viewCalendar",
    defaultMessage: "View Calendar",
    description: "The action button to view the calendar",
  },
});
