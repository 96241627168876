import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SplitNames, useSplit } from "utilities/split";

export const useSchedulingRecommendationsTracking = () => {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.SchedulingRecommendations],
  });

  const isInRecommendationsBeta = getTreatmentValue(
    SplitNames.SchedulingRecommendations,
  );

  const sendClosestSchedulingSelectedEvent = () => {
    if (isInRecommendationsBeta) {
      Amplitude.TRACK_EVENT("Clicked Button", {
        name: `scheduling_recommendations_closest`,
      });
    }
  };
  const sendSoonestSchedulingSelectedEvent = () => {
    if (isInRecommendationsBeta) {
      Amplitude.TRACK_EVENT("Clicked Button", {
        name: `scheduling_recommendations_soonest`,
      });
    }
  };
  const sendVisitDurationChangedEvent = (value: number) => {
    Amplitude.TRACK_EVENT("Selected Dropdown Value", {
      name: `visit_duration`,
      value,
    });
  };

  return {
    sendClosestSchedulingSelectedEvent,
    sendSoonestSchedulingSelectedEvent,
    sendVisitDurationChangedEvent,
  };
};
