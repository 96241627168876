import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { PurchaseFormContextProvider } from "jobber/billing/hooks/PurchaseFormContext";
import { useStoredUpdateResult } from "jobber/billing/hooks/useStoredUpdateResult";
import {
  CANCEL_INTERACTION,
  DISMISS_INTERACTION,
  SAVE_PAYMENT_INTERACTION,
  type TrackingDetails,
  trackFailedSubmitRecurlyForm,
  trackInteractedWithButton,
  trackViewedRecurlyForm,
} from "jobber/billing/utils/trackInteractions";
import type { MutationErrors } from "~/utilities/API/graphql";
import {
  EditBillingInfo,
  type EditBillingInfoRef,
} from "jobber/billing/components/EditBillingInfo";
import type {
  FieldErrorState,
  FormErrorState,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { messages } from "./messages";

interface EditBillingInfoModalProps {
  recurlyPublicKey: string;
  tier: string;
}

export const UPDATE_PAYMENT_DETAILS = "Update payment details";

// eslint-disable-next-line max-statements
export function EditBillingInfoModal(props: EditBillingInfoModalProps) {
  const { recurlyPublicKey, tier } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrorState>({});
  const { setStoredUpdateResult } = useStoredUpdateResult();
  const ref = useRef() as MutableRefObject<EditBillingInfoRef>;

  const { formatMessage } = useIntl();

  const trackingDetails: TrackingDetails = {
    name: UPDATE_PAYMENT_DETAILS,
    plan: tier,
  };

  useEffect(() => {
    setSubmissionErrors([]);
    setValidationErrors({});
    setIsSubmitting(false);
  }, [modalOpen]);

  const hasValidationError = Object.values(validationErrors).some(
    error => !!error,
  );

  return (
    <PurchaseFormContextProvider submitting={isSubmitting}>
      <>
        <button
          className="dropdown-item"
          onClick={toggleModal}
          type="button"
          aria-label={formatMessage(messages.paymentDetailsButton)}
        >
          {formatMessage(messages.paymentDetailsButton)}
        </button>
        <Modal
          title={formatMessage(messages.modalTitle)}
          open={modalOpen}
          onRequestClose={handleDismiss}
          primaryAction={
            showActions
              ? {
                  label: formatMessage(messages.saveInformationLabel),
                  onClick: submitForm,
                  loading: isSubmitting,
                  disabled: !saveEnabled || hasValidationError,
                }
              : undefined
          }
          secondaryAction={
            showActions
              ? {
                  label: formatMessage(messages.cancelLabel),
                  disabled: isSubmitting,
                  onClick: handleCancel,
                }
              : undefined
          }
        >
          <Content>
            <FormErrors errors={submissionErrors} />
            <EditBillingInfo
              recurlyPublicKey={recurlyPublicKey}
              ref={ref}
              trackingDetails={trackingDetails}
              setSaveEnabled={setSaveEnabled}
              setShowParentActions={setShowActions}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitError={onSubmitError}
              onValidationError={onValidationError}
            />
          </Content>
        </Modal>
      </>
    </PurchaseFormContextProvider>
  );

  async function submitForm() {
    setSubmissionErrors([]);
    setIsSubmitting(true);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: SAVE_PAYMENT_INTERACTION,
    });

    ref.current && (await ref.current.submit());
  }

  function onSubmitSuccess() {
    setStoredUpdateResult(formatMessage(messages.successfulUpdate));
    return window.location.reload();
  }

  function onSubmitError(errors: MutationErrors[]) {
    setIsSubmitting(false);
    setSubmissionErrors(errors.map(error => error.message));
    trackFailedSubmitRecurlyForm(trackingDetails);
  }

  function onValidationError(newErrorState: FieldErrorState) {
    const fieldName = newErrorState.field;

    setValidationErrors({
      ...validationErrors,
      [fieldName]: newErrorState.message,
    });
  }

  function handleDismiss() {
    setModalOpen(!modalOpen);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: DISMISS_INTERACTION,
    });
  }

  function handleCancel() {
    setModalOpen(!modalOpen);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: CANCEL_INTERACTION,
    });
  }

  function toggleModal() {
    setModalOpen(!modalOpen);
    trackViewedRecurlyForm(trackingDetails);
  }
}

function FormErrors({ errors }: { errors: string[] }) {
  if (!errors.length) {
    return <></>;
  }
  return (
    <Banner dismissible={false} type={"error"}>
      {errors.map((errorText: string, index: number) => {
        return (
          <div key={index}>
            <Text>{errorText}</Text>
            {index + 1 < errors.length ? <br></br> : <></>}
          </div>
        );
      })}
    </Banner>
  );
}
