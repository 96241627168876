import { useQuery } from "@apollo/client";
import { GET_ACH_LIMITS_CHANGE_REQUEST } from "./getAchLimitsChangeRequest.graphql";

export const useAchLimitsChangeRequest = () => {
  const { data, loading, error } = useQuery(GET_ACH_LIMITS_CHANGE_REQUEST);

  return {
    data: data?.jobberPaymentsSettings?.achLimitsChangeRequest,
    loading,
    error,
  };
};
