import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import React, { createContext } from "react";
import { DedicatedPhoneNumberSettingWrapper } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSettingWrapper";
import { UkKycComplianceRegistration } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration";
import type {
  TwilioRegistrationStatus,
  UkKycRegistrationRequirementTypes,
} from "~/utilities/API/graphql";
import {
  AccountContext,
  type AccountContextType,
} from "~/utilities/contexts/internal/AccountContext";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  UrlsContext,
  type UrlsContextType,
} from "~/utilities/contexts/internal/UrlsContext";

interface TextMessagingSettingsPageProps {
  selectedNumber: string;
  isSelectedNumberActive: boolean;
  registrationStatus: TwilioRegistrationStatus;
  companySettingsPath: string;
  requiresRegistration: boolean;
  kycRegistrationRequirement: UkKycRegistrationRequirementTypes;
  urlsContext: UrlsContextType;
  accountContext: AccountContextType;
}
export const LinkContext = createContext("");
export function TextMessagingSettingsPage({
  selectedNumber,
  isSelectedNumberActive,
  registrationStatus,
  companySettingsPath,
  requiresRegistration,
  kycRegistrationRequirement,
  urlsContext,
  accountContext,
}: TextMessagingSettingsPageProps) {
  return (
    <UrlsContext.Provider value={urlsContext}>
      <Page title="Two-way text messaging">
        <Content>
          <Content spacing="large">
            <AccountContext.Provider value={accountContext}>
              <APIProvider>
                <UkKycComplianceRegistration />
              </APIProvider>
            </AccountContext.Provider>
            <LinkContext.Provider value={companySettingsPath}>
              <DedicatedPhoneNumberSettingWrapper
                selectedNumber={selectedNumber}
                registrationStatus={registrationStatus}
                isSelectedNumberActive={isSelectedNumberActive}
                requiresRegistration={requiresRegistration}
                kycRegistrationRequirement={kycRegistrationRequirement}
              />
            </LinkContext.Provider>
          </Content>
        </Content>
      </Page>
    </UrlsContext.Provider>
  );
}
