/**
 * Warning: This component is still in the works and undergoing heavy development.
 * Feel free to reach out to Atlantis if you're keen on giving it a go!
 */
import type { InputTextRef } from "@jobber/components/InputText";
import { InputText } from "@jobber/components/InputText";
import type { ReactElement, Ref, RefObject } from "react";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { AnimatedPresence } from "@jobber/components/AnimatedPresence";
import { Button, type ButtonProps } from "@jobber/components/Button";
import { Tooltip } from "@jobber/components/Tooltip";
import type { PromptTypeProps } from "components/TextRewrite";
import { TextRewrite } from "components/TextRewrite";
import { APIProvider } from "~/utilities/API/APIProvider";
import { AiAssistantTextReviseContentType } from "~/utilities/API/graphql";
import { DatadogNoRedactWrapper } from "~/utilities/errors/Datadog/DatadogNoRedactWrapper";
import styles from "./MagicInputText.module.css";

interface PlainTemplatePrompt {
  variableSet: string;
}

type MagicInputTextInternalProps = {
  plainTemplate?: PlainTemplatePrompt;
  primaryButton?: ReactElement<ButtonProps>;
  renderEmptyStateButton?: ({
    setError,
    setText,
    setLoading,
  }: {
    setError: (error: string | undefined) => void;
    setText: (text: string) => void;
    setLoading: (loading: boolean) => void;
  }) => ReactElement;
} & Parameters<typeof InputText>[0];

// eslint-disable-next-line max-statements
function MagicInputTextInternal(
  { onChange, ...props }: MagicInputTextInternalProps,
  ref: RefObject<InputTextRef>,
) {
  const internalRef = useRef<InputTextRef | null>(null);
  const inputRef = ref || internalRef;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const value = props.value || props.defaultValue || "";
  const [prevValue, setPrevValue] = useState(value);

  const handleChange = useCallback(
    (newValue: string) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  const handleLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
    if (loading) {
      setErrorMessage("");
    }
  }, []);

  return (
    <APIProvider>
      <div className={styles.container}>
        {errorMessage != "" && (
          <div className={styles.errorBanner}>
            <Banner
              type="error"
              dismissible
              onDismiss={() => setErrorMessage("")}
            >
              {errorMessage}
            </Banner>
          </div>
        )}

        <InputText
          {...props}
          ref={inputRef}
          readonly={props.readonly ?? isLoading}
          value={value}
          onChange={handleChange}
          onFocus={() => {
            props.onFocus?.();
          }}
          toolbar={buildToolbar(props.primaryButton)}
        />
      </div>
    </APIProvider>
  );

  function buildToolbar(primaryButton?: ReactElement<ButtonProps>) {
    const hasValue = value != null && `${value}` !== "";
    const showToolbar =
      (hasValue && !props.readonly && !props.disabled) ||
      props.renderEmptyStateButton !== undefined;
    const showUndoButton = props.renderEmptyStateButton
      ? hasValue
      : Boolean(prevValue) && prevValue !== value;
    const handleText = (text: string) => {
      handleChange(text);
      setPrevValue(value);
      inputRef.current?.focus();
    };
    const rewriteButton = (
      <TextRewrite
        trackingSource="magic_fields"
        setError={err => err && setErrorMessage(err.message)}
        loading={isLoading}
        setLoading={handleLoading}
        text={`${value}`}
        setText={handleText}
        decodeHTMLEntities={true}
        {...getPromptProps(props)}
      />
    );
    const secondaryButton = hasValue
      ? rewriteButton
      : props.renderEmptyStateButton?.({
          setError: (err: string | undefined) => err && setErrorMessage(err),
          setText: handleText,
          setLoading: handleLoading,
        });

    return (
      <>
        {showToolbar && (
          <div className={styles.rewriteBar}>
            {secondaryButton}
            <AnimatedPresence transition="fromBottom">
              {showUndoButton && (
                <DatadogNoRedactWrapper actionName="Undo" revealOnReplay={true}>
                  <Tooltip message="Undo">
                    <Button
                      icon="redo"
                      type="secondary"
                      variation="subtle"
                      ariaLabel="Undo"
                      size="small"
                      onClick={() => {
                        handleChange(prevValue as string);
                        setPrevValue("");
                        inputRef.current?.focus();
                      }}
                    />
                  </Tooltip>
                </DatadogNoRedactWrapper>
              )}
            </AnimatedPresence>
            {primaryButton}
          </div>
        )}
      </>
    );
  }
}

function getPromptProps(props: MagicInputTextInternalProps): PromptTypeProps {
  if (props.plainTemplate) {
    return {
      contentType: AiAssistantTextReviseContentType.PLAIN_TEMPLATE,
      additionalInstructions: {
        variableSet: props.plainTemplate.variableSet,
      },
    };
  }

  return {
    contentType: AiAssistantTextReviseContentType.PLAIN_TEXT,
  };
}

const MagicInputTextInternalWrapped = forwardRef(MagicInputTextInternal);

type MagicInputTextProps = MagicInputTextInternalProps & {
  disableMagicRewrite?: boolean;
};

function MagicInputText(props: MagicInputTextProps, ref: Ref<InputTextRef>) {
  const { primaryButton, disableMagicRewrite, plainTemplate, ...inputProps } =
    props;

  if (!disableMagicRewrite) {
    return (
      <MagicInputTextInternalWrapped
        {...inputProps}
        plainTemplate={plainTemplate}
        primaryButton={primaryButton}
        ref={ref}
      />
    );
  }

  return <InputText {...inputProps} ref={ref} />;
}

const MagicInputTextWrapped = forwardRef(MagicInputText);
export { MagicInputTextWrapped as MagicInputText };
