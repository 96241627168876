import React, { useContext, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import type {
  ClientPhonesQuery,
  ClientPhonesQueryVariables,
} from "~/utilities/API/graphql";
import { SearchAndSelect } from "jobber/chat/components/MessageComposer/components/SearchAndSelect";
import { CLIENT_PHONES_QUERY } from "jobber/chat/components/SmsComposerPage/SmsComposerPage.graphql";
import { REASSIGN_CONVERSATION_MUTATION } from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";
import type { SelectedRecipientType } from "jobber/chat/types";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { ReassignConversationModal } from "jobber/chat/components/ChatDrawer/ListHeader/ReassignConversationModal/ReassignConversationModal";

export function ReassignConversationPage() {
  const [state, dispatch] = useContext(MessageCenterContext);
  const [newRecipient, setNewRecipient] = useState<SelectedRecipientType>();
  const [reassignConversationToClient] = useMutation(
    REASSIGN_CONVERSATION_MUTATION,
  );
  const [showReassignModal, setShowReassignModal] = useState(false);

  const fetchRecipients = useLazyQuery<
    ClientPhonesQuery,
    ClientPhonesQueryVariables
  >(CLIENT_PHONES_QUERY);

  async function handleSelectRecipient(recipient: SelectedRecipientType) {
    setShowReassignModal(true);
    setNewRecipient(recipient);
  }

  async function handleReassignConversation() {
    if (newRecipient) {
      await reassignConversationToClient({
        variables: {
          id: state.selectedConversationId,
          clientId: newRecipient.id,
        },
      });

      if (dispatch) {
        dispatch({
          type: "SHOW_CHAT",
          title: newRecipient.name ?? "",
          conversationId: state.selectedConversationId ?? "",
          recipient: newRecipient,
        });
      }
    }
  }

  return (
    <>
      <SearchAndSelect
        fetchRecipients={fetchRecipients}
        selectRecipient={handleSelectRecipient}
        canCreateConversation={() => false}
        searchTermOverride={state?.selectedRecipient?.contactInfo}
      />
      <ReassignConversationModal
        isOpen={showReassignModal}
        reassignConversation={handleReassignConversation}
        cancel={() => setShowReassignModal(false)}
      />
    </>
  );
}
