import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientHasNoJobs: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.clientHasNoJobs",
    defaultMessage: "and only if they have no upcoming visits",
    description: "Client has no jobs",
  },
  clientHasNotWorkedWithYou: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.clientHasNotWorkedWithYou",
    defaultMessage: "and only if they have not worked with you",
    description: "Client has not worked with you",
  },
  clientTagsInclude: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.clientTagsInclude",
    defaultMessage: "client tags include",
    description: "Client tags include",
  },
  clientTagsDoNotInclude: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.clientTagsDoesNotInclude",
    defaultMessage: "client tags do not include",
    description: "Client tags does not include",
  },
  lineItemsInclude: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.lineItemsInclude",
    defaultMessage: "line items include",
    description: "Line items include",
  },
  lineItemsDoNotInclude: {
    id: "campaigns.campaignReviewPage.useTranslateAutomationRulesConditionToText.lineItemsDoesNotInclude",
    defaultMessage: "line items do not include",
    description: "Line items does not include",
  },
});
