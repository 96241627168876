import { gql } from "@apollo/client";
import { CAMPAIGN } from "jobber/campaigns/graphql";

export const MARK_DEMO_CAMPAIGN_AUTOMATION_ACTIVE = gql`
  mutation markDemoCampaignAutomationActive($campaignId: EncodedId!) {
    markDemoCampaignAutomationActive(campaignId: $campaignId) {
      campaign {
        ...campaign
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CAMPAIGN}
`;
