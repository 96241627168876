export const messages = {
  validationRequired: {
    message: "Required field",
    description: "Validation message for required fields",
  },

  validationRequiredAddress: {
    message: "Enter an address",
    description: "Validation message for street1 field",
  },

  validationRequiredCity: {
    message: "Enter a city",
    description: "Validation message for city field",
  },

  validationRequiredCountry: {
    message: "Enter a country",
    description: "Validation message for city field",
  },

  validationRequiredGeneric: {
    message: "Enter a ",
    description: "Beginning of validation message for fields",
  },

  street1Label: {
    message: "Address",
    description: "Label for Address Line 1 field",
  },
  street2Label: {
    message: "Unit, apartment, suite, etc (optional)",
    description: "Label for Address Line 2 field",
  },
  city: {
    message: "City",
    description: "Label for City field",
  },
  country: {
    message: "Country",
    description: "Label for Country field",
  },

  // Province
  provinceLabelDefault: {
    message: "Province",
    description:
      "Label for the Province field in countries not otherwise specified",
  },
  provinceLabelProvince: {
    message: "Province",
    description:
      "Label for the Province field in countries that use Province e.g. CA",
  },
  provinceLabelState: {
    message: "State",
    description:
      "Label for the Province field in countries that use State, e.g. US, AU",
  },
  provinceLabelCounty: {
    message: "County",
    description:
      "Label for the Province field in countries that use County, e.g. GB, IE",
  },
  provinceLabelRegion: {
    message: "Region",
    description:
      "Label for the Province field in countries that use Region, e.g. NZ",
  },

  // Postal Code
  postalCodeLabelDefault: {
    message: "Postal Code",
    description:
      "Label for the Postal Code field in countries not otherwise specified",
  },
  postalCodeLabelPostalCode: {
    message: "Postal Code",
    description:
      "Label for the Postal Code field in countries that use Postal Code, e.g. CA",
  },
  postalCodeLabelZipCode: {
    message: "ZIP Code",
    description:
      "Label for the Postal Code field in countries that use ZIP Code, e.g. US",
  },
  postalCodeLabelPostCode: {
    message: "Post Code",
    description:
      "Label for the Postal Code field in countries that use Post Code, e.g. AU, GB, NZ",
  },
  postalCodeLabelEirCode: {
    message: "Eircode",
    description:
      "Label for the Postal Code field in countries that use Eircode, e.g. IE",
  },

  // Postal Code Validations
  postalCodeInvalidCA: {
    message: "Please enter a valid postal code",
    description: "Validation message for Canadian Postal Codes",
  },
  postalCodeInvalidUS: {
    message: "Please enter a valid ZIP code",
    description: "Validation message for USA ZIP Codes",
  },
};
