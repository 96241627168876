import React from "react";
import { Banner } from "@jobber/components/Banner";
import { Text } from "@jobber/components/Text";
import type { CompanyDetails } from "./IntegrationEnabledCard";

export function CompanyDetailsBanner({ name, address }: CompanyDetails) {
  const isCompanyDetailsComplete = name && address;

  if (!isCompanyDetailsComplete) {
    return (
      <Banner
        type="error"
        dismissible={false}
        primaryAction={{
          label: "Edit Company Settings",
          onClick: () => {
            window.location.href = "/accounts/edit";
          },
        }}
      >
        <Text>
          The following information is missing from your company settings in
          Jobber:
          {!name && (
            <>
              <br />
              &bull; Company Name
            </>
          )}
          {!address && (
            <>
              <br />
              &bull; Address
            </>
          )}
          <br />
          This information must match what you have in your Local Services Ads
          profile.
        </Text>
      </Banner>
    );
  } else {
    return <></>;
  }
}
