import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages as companyPageMessages } from "jobber/setupWizard/components/CompanyPage/components/messages";
import type { SetupWizardEditInput } from "~/utilities/API/graphql";
import { Industry } from "~/utilities/API/graphql";
import type { SetupWizardContextType } from "jobber/setupWizard/context/createSetupWizardContext";
import {
  companyAgeArray,
  companyRevenueArray,
  companySizeArray,
  topPriorityArray,
} from "./components/constants";
import type {
  IndustryCategory,
  QueryResultData,
  SetupWizardData,
  SetupWizardExperiments,
  SetupWizardStepProps,
} from "./types";
import type { SetupWizardLoaderProps } from "./SetupWizard.loader";
import type { SetupWizardRouteProps } from "./SetupWizardRoute";

export const mockIndustryMap: IndustryCategory[] = [
  {
    category: "Hi Tech",
    industries: [
      { displayName: "Computers & IT", industryEnum: Industry.COMPUTERS_IT },
    ],
  },
  {
    category: "Other",
    industries: [
      {
        displayName: "Appliance Repair",
        industryEnum: Industry.APPLIANCE_REPAIR,
      },
      {
        displayName: "Flooring Service",
        industryEnum: Industry.FLOORING_SERVICE,
      },
    ],
  },
];

export const mockPromotionData = {
  promoTrackingTag: "test_promo_tracking_tag",
  showPromo: true,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  promoDetails: {
    welcomeText: "Welcome text",
    title: "Title",
    information: "Info",
    subtext: "Subtext",
    buttonText: "Button text",
  },
};

export const mockEmptyPromotionData = {
  promoTrackingTag: "",
  showPromo: false,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  promoDetails: {
    welcomeText: "",
    title: "",
    information: "",
    subtext: "",
    buttonText: "",
  },
};

export const mockExperiments: SetupWizardExperiments = {
  happyPathP1V2: false,
  signupFlowThemeVariation: false,
};

export const mockSetupWizardStepProps: SetupWizardStepProps = {
  navigation: {
    isFinalStep: false,
    nextCtaCopy: "Dummy CTA copy",
    onNext: jest.fn(),
  },
  industryMap: mockIndustryMap,
  onMobileWeb: false,
  promoDetails: mockEmptyPromotionData.promoDetails,
  promoTrackingTag: mockEmptyPromotionData.promoTrackingTag,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockSetupWizardData: SetupWizardData = {
  accountOwnerName: "Test Account Owner",
  companyName: "Test Company Name",
  companySize: companySizeArray[0].value,
  heardAboutUs: "Test heard about us value",
  industry: Industry.COMPUTERS_IT,
  phoneNumber: "(555) 555-5555",
  questionsAndAnswers: [
    {
      question: "business_revenue",
      answer: companyRevenueArray[0].value,
    },
    {
      question: "business_goals",
      answer: topPriorityArray[0].value,
    },
    {
      question: "years_in_business",
      answer: companyAgeArray[0].value,
    },
  ],
  smsOptIn: false,
};

export const companyNameCommunity = "Test Account Owner's Company";

export const mockEmptyQueryResult: QueryResultData = {
  account: {
    name: "",
    phone: "",
    industry: "",
    setupWizard: {
      companySize: "",
      heardAboutUs: "",
      questionsAndAnswers: [],
      smsOptIn: false,
    },
    accountOwner: { fullName: "", email: { raw: "" } },
  },
};

export const mockQueryResult: QueryResultData = {
  account: {
    name: "Carpet Cleaning Inc.",
    phone: "(555) 555-3333",
    industry: Industry.CARPET_CLEANING,
    setupWizard: {
      companySize: companySizeArray[1].value,
      heardAboutUs: "Heard about you from a friend",
      questionsAndAnswers: [
        {
          question: "business_revenue",
          answer: companyRevenueArray[1].value,
        },
        {
          question: "business_goals",
          answer: topPriorityArray[1].value,
        },
        {
          question: "years_in_business",
          answer: companyAgeArray[1].value,
        },
      ],
      smsOptIn: false,
    },
    accountOwner: {
      fullName: "John Doe",
      email: { raw: "john.doe@testemail.com" },
    },
  },
};

export const mockMutationInput: SetupWizardEditInput = {
  completed: false,
};

export const mockSetupWizardContext: SetupWizardContextType = {
  error: "",
  saving: false,
  wizardData: {},
  updateWizardData: jest.fn(),
  topPriorityOptions: [],
  experiments: {} as SetupWizardExperiments,
  updateExperiments: jest.fn(),
  accountCreatedAt: "",
  isCompanyNameManual: false,
  setIsCompanyNameManual: jest.fn(),
  companyPhotos: [],
  setCompanyPhotos: jest.fn(),
  companyReviews: [],
  setCompanyReviews: jest.fn(),
};

export const mockSetupWizardRouteProps: SetupWizardRouteProps = {
  industryMap: mockIndustryMap,
  promotionData: mockEmptyPromotionData,
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockSetupWizardLoaderProps: SetupWizardLoaderProps = {
  industryMap: mockIndustryMap,
  promotionData: mockEmptyPromotionData,
  experiments: mockExperiments,
  accountCreatedAt: "",
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockDatadogBoundaryProvider = {
  useDatadogLogger: jest.fn(() => ({
    logError: jest.fn(),
    logInfo: jest.fn(),
  })),
};

export const mockSelectIndustry = async () => {
  const { formatMessage } = createIntl();

  const input = screen.getByLabelText(
    formatMessage(companyPageMessages.industryPlaceholder),
  );

  userEvent.click(input);
  userEvent.type(input, mockIndustryMap[0].industries[0].displayName);

  const option = await screen.findByText(
    mockIndustryMap[0].industries[0].displayName,
  );

  userEvent.click(option);
};
