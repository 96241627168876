import React from "react";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { messages } from "jobber/setupWizard/messages";
import SmallMaskingTape1 from "@images/signup/small-masking-tape-1.svg";
import SmallMaskingTape2 from "@images/signup/small-masking-tape-2.svg";
import SmallMaskingTape3 from "@images/signup/small-masking-tape-3.svg";
import styles from "./MaskingTapeStats.module.css";

export interface StatLines {
  copy: { id: string; defaultMessage: string; description?: string }; // moce gthis to a format type
  color?: "highlight";
  maskingTape: "one" | "two" | "three";
}

interface MaskingTapeStatsProps {
  stat: StatLines[];
}

export function MaskingTapeStats({ stat }: MaskingTapeStatsProps) {
  const { formatMessage } = useIntl();
  const maskingTapeStyles = (line: StatLines) => {
    let colorLabel;
    switch (line.color) {
      case "highlight":
        colorLabel = styles.highlightHeading;
        break;
      default:
        colorLabel = styles.whiteHeading;
    }

    let maskingTapeSrc;
    switch (line.maskingTape) {
      case "one":
        maskingTapeSrc = SmallMaskingTape1;
        break;
      case "two":
        maskingTapeSrc = SmallMaskingTape2;
        break;
      case "three":
        maskingTapeSrc = SmallMaskingTape3;
        break;
    }

    return (
      <div className={[styles.smallMaskingTape, colorLabel].join(" ")}>
        <div className={styles.maskingTapeContainer}>
          <img
            src={maskingTapeSrc}
            alt={formatMessage(messages.maskingTapeAltText)}
            className={styles.maskingTapeSvg}
          />
        </div>
        <Heading level={1}>{formatMessage(line.copy)}</Heading>
      </div>
    );
  };

  return (
    <div className={styles.statsWrapper}>
      <Content spacing="small">
        {stat.map((line, index) => (
          <div key={index}>{maskingTapeStyles(line)}</div>
        ))}
      </Content>
    </div>
  );
}
