import { gql } from "@apollo/client";
import { CompanyScheduleFragment } from "~/jobber/settings/companySchedule/companyScheduleFragment";

export const UPDATE_SCHEDULE_MUTATION = gql`
  mutation UpdateCompanySchedule(
    $attributes: CompanyScheduleUpdateAttributes!
  ) {
    updateCompanySchedule(attributes: $attributes) {
      companySchedule {
        ...companyScheduleWeekFields
      }
      errors
    }
  }
  ${CompanyScheduleFragment}
`;

export const COMPANY_SCHEDULE_QUERY = gql`
  query CompanySchedulePageData {
    companySchedule {
      days {
        friday {
          enabled
          times
        }
        monday {
          enabled
          times
        }
        saturday {
          enabled
          times
        }
        sunday {
          enabled
          times
        }
        thursday {
          enabled
          times
        }
        tuesday {
          enabled
          times
        }
        wednesday {
          enabled
          times
        }
      }
      enabled
    }
  }
`;
