import { useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import React from "react";
import { Text } from "@jobber/components/Text";
import type {
  GetDisputeQueryQuery,
  JobberPaymentsDispute,
} from "~/utilities/API/graphql";
import { GET_DISPUTE_QUERY } from "./DisputeDetailsPage.graphql";
import { DisputeDetailsPage } from "./DisputeDetailsPage";

export interface DisputeDetailsProps {
  id: string;
}

export function DisputeDetailsPageLoader({ id }: DisputeDetailsProps) {
  const { loading, error, data } = useQuery<GetDisputeQueryQuery>(
    GET_DISPUTE_QUERY,
    {
      variables: {
        id,
      },
    },
  );
  const dispute: JobberPaymentsDispute | undefined =
    data?.jobberPaymentsDispute as JobberPaymentsDispute;

  if (loading && !dispute) {
    return <Spinner />;
  }

  if (error) {
    return <Text>Something went wrong, please try again later.</Text>;
  }

  if (!dispute) {
    return <Text>Dispute not found.</Text>;
  }

  return <DisputeDetailsPage dispute={dispute} />;
}
