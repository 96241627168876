import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import type { IntegrationPageProps } from "jobber/marketplace/views/IntegrationPage/IntegrationPageProps";

const IntegrationStateContext = createContext<IntegrationPageProps | undefined>(
  undefined,
);

interface IntegrationStateProviderProps {
  defaultState: IntegrationPageProps;
}

export function IntegrationStateProvider({
  children,
  defaultState,
}: PropsWithChildren<IntegrationStateProviderProps>) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState(defaultState);
  }, [defaultState]);

  return (
    <IntegrationStateContext.Provider value={{ ...state }}>
      {children}
    </IntegrationStateContext.Provider>
  );
}

export function useIntegrationStateContext() {
  const context = useContext(IntegrationStateContext);
  if (context === undefined) {
    throw new Error(
      "useIntegrationStateContext must be used within the IntegrationStateContext provider",
    );
  }
  return context;
}
