interface Edge {
  __typename?: unknown;
  node: {
    id: unknown;
    __typename?: unknown;
  };
}

export function uniqueEdges(edges: Edge[]): Edge[] {
  const result = new Map<string, Edge>();
  edges.forEach(edge => {
    result.set(
      `${edge.__typename}-${edge.node.__typename}-${edge.node.id}`,
      edge,
    );
  });
  return Array.from(result.values());
}
