import React from "react";
import { Tooltip } from "@jobber/components/Tooltip";
import { Icon } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Cell, HeaderColumns } from "components/Table";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { messages } from "jobber/workOrders/components/JobCost/components/LineItemsTable/messages";
import styles from "./LineItemsTableHeaderColumns.module.css";

interface ColumnType {
  name: string;
  requiresPricingPermission: boolean;
  requiresJobCostFeature: boolean;
  align?: "right" | "center";
  tooltip?: string;
}

function generateLineItemTableHeaders(
  canViewPricing: boolean,
  shouldShowJobCosting: boolean,
  formatMessage: (descriptor: MessageDescriptor) => string,
): ColumnType[] {
  const lineItemsTableHeaders: ColumnType[] = [
    {
      name: formatMessage(messages.lineItemProductService),
      requiresPricingPermission: false,
      requiresJobCostFeature: false,
    },
    {
      name: formatMessage(messages.lineItemQuantity),
      requiresPricingPermission: false,
      requiresJobCostFeature: false,
      align: canViewPricing ? "center" : "right",
    },
    {
      name: formatMessage(messages.lineItemCost),
      requiresPricingPermission: true,
      requiresJobCostFeature: true,
      tooltip: "Amount spent on line item",
      align: "right",
    },
    {
      name: formatMessage(messages.lineItemPrice),
      requiresPricingPermission: true,
      requiresJobCostFeature: false,
      tooltip: formatMessage(messages.lineItemPriceTooltip),
      align: "right",
    },
    {
      name: formatMessage(messages.lineItemTotal),
      requiresPricingPermission: true,
      requiresJobCostFeature: false,
      align: "right",
    },
  ];

  const permissionFilteredLineItemsTableHeaders = lineItemsTableHeaders
    .filter(
      ({ requiresPricingPermission }) =>
        !requiresPricingPermission || canViewPricing,
    )
    .filter(
      ({ requiresJobCostFeature }) =>
        !requiresJobCostFeature || shouldShowJobCosting,
    );

  return permissionFilteredLineItemsTableHeaders;
}

interface LineItemsTableHeaderColumnsProps {
  canViewPricing: boolean;
}

export function LineItemsTableHeaderColumns({
  canViewPricing,
}: LineItemsTableHeaderColumnsProps) {
  const { features } = useAccount();
  const jobCostingEnabled = features.jobCosting.enabled;

  const { formatMessage } = useIntl();

  const applicableLineItemHeaders = generateLineItemTableHeaders(
    canViewPricing,
    jobCostingEnabled && features?.quoteMargins.enabled,
    formatMessage,
  );

  return (
    <HeaderColumns testId={"lineItemsTableHeaderColumns"}>
      {applicableLineItemHeaders.map(formatHeaderColumn)}
    </HeaderColumns>
  );
}

function formatHeaderColumn(column: ColumnType, index: number) {
  return (
    <Cell key={index} size="small" align={column.align}>
      <div className={styles.cellHeaderContainer}>
        <div className={column.tooltip ? styles.headerWithTooltip : undefined}>
          <Heading level={6}>{column.name}</Heading>
        </div>
        {column.tooltip && (
          <Tooltip message={column.tooltip}>
            <Icon name="help" size="small" />
          </Tooltip>
        )}
      </div>
    </Cell>
  );
}
