import { Button } from "@jobber/components/Button";
import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./messages";

export interface AddNewLineItemButtonProps {
  onPress: () => void;
}

export function AddNewLineItemButton({ onPress }: AddNewLineItemButtonProps) {
  const { formatMessage } = useIntl();

  return (
    <Button
      label={formatMessage(messages.newLineItemButton)}
      ariaLabel={formatMessage(messages.newLineItemButton)}
      type="secondary"
      size="small"
      onClick={onPress}
    />
  );
}
