import {
  Button,
  Content,
  Form,
  Grid,
  RadioGroup,
  RadioOption,
} from "@jobber/components";
import React, { useContext, useState } from "react";
import { type Theme, updateTheme } from "@jobber/components";
import { Context } from "jobber/setupWizard/context/SetupWizardContext";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import themeDark from "@images/signup/theme-dark.svg";
import themeLight from "@images/signup/theme-light.svg";
import { generateLastSubmittedStepQandA } from "jobber/setupWizard/utils";
import { createIntl } from "utilities/testHelpers/createIntl";
import { COOKIE_NAME } from "~/jobber/settings/darkModeToggle/constants";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "../../SetupWizardForms.module.css";
import { messages } from "../messages";
import { determineThemeToDisplay } from "../utils";

export function ThemePageForm({
  navigation,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { wizardData, updateWizardData, saving } = useContext(Context);
  const [selectedTheme, setSelectedTheme] = useState<Theme>(() => {
    return determineThemeToDisplay(wizardData);
  });
  const { formatMessage } = createIntl();

  const handleChange = (newTheme: Theme) => {
    updateTheme(newTheme);
    setSelectedTheme(newTheme);
    updateWizardData(
      {
        questionsAndAnswers: [
          {
            question: "theme",
            answer: newTheme,
          },
        ],
      },
      false,
      undefined,
      true,
    );
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.interactedWithThemeStep.eventName, {
      experiment: ANALYTICS_EVENTS.interactedWithThemeStep.experiment,
      interaction: `toggled to ${newTheme}`,
    });
  };

  const handleSubmit = async () => {
    document.cookie = `${COOKIE_NAME}=${selectedTheme}; path=/; SameSite=Lax; max-age=604800;`;

    const dataToSend = {
      questionsAndAnswers: [
        {
          question: "theme",
          answer: selectedTheme,
        },
        generateLastSubmittedStepQandA(Routes.theme),
      ],
    };
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.interactedWithThemeStep.eventName, {
      experiment: ANALYTICS_EVENTS.interactedWithThemeStep.experiment,
      interaction: `submitted ${selectedTheme}`,
    });
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  const renderOption = (theme: "light" | "dark") => {
    const accessibilityLabel =
      theme === "light"
        ? formatMessage(messages.lightButtonText)
        : formatMessage(messages.darkButtonText);
    const imgSrc = theme === "light" ? themeLight : themeDark;
    return (
      <div>
        <button
          type="button"
          className={styles.themeButton}
          onClick={() => handleChange(theme)}
          aria-label={accessibilityLabel}
        >
          <img
            className={styles.themeButtonImage}
            src={imgSrc}
            alt={accessibilityLabel}
          />
        </button>
        <div className={styles.radioContainer}>
          <RadioGroup
            ariaLabel={theme}
            onChange={handleChange}
            value={selectedTheme}
          >
            <RadioOption value={theme} label={accessibilityLabel} />
          </RadioGroup>
        </div>
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <Grid alignItems={"center"}>
          <Grid.Cell size={{ xs: 6 }}>{renderOption("light")}</Grid.Cell>
          <Grid.Cell size={{ xs: 6 }}>{renderOption("dark")}</Grid.Cell>
        </Grid>
        <div className={styles.button}>
          <Button
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            icon="arrowRight"
            iconOnRight={true}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
