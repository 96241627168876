import React from "react";
import styles from "./MessageCenterNotificationBadge.module.css";

export interface MessageCenterNotificationBadgeProps {
  unreadCount: number;
  isNotificationAvailable?: boolean;
}

export function MessageCenterNotificationBadge({
  unreadCount,
  isNotificationAvailable,
}: MessageCenterNotificationBadgeProps) {
  const unreadLabel = unreadCount > 99 ? "99+" : unreadCount.toString();

  if (isNotificationAvailable) {
    return (
      <div
        data-testid="notification-badge"
        className={styles.notificationBadge}
      />
    );
  }

  if (unreadCount) {
    return (
      <div
        data-testid="unread-badge"
        className={styles.unreadBadge}
        style={{ width: calculateWidth(unreadCount) }}
      >
        <div className="notifications-number">{unreadLabel}</div>
      </div>
    );
  }

  return <></>;
}

function calculateWidth(unreadCount: number) {
  if (unreadCount < 10) {
    return "0.9rem";
  }
  if (unreadCount < 100) {
    return "1.3rem";
  }
  return "1.5rem";
}
