export function disableCreate() {
  const button = document.querySelector(".js-createPlace");
  if (button) {
    button.classList.add("disabled");
  }
}

export function enableCreate() {
  const button = document.querySelector(".js-createPlace");
  if (button) {
    button.classList.remove("disabled");
  }
}
