import { Content } from "@jobber/components/Content";
import React, { useReducer } from "react";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import { addHours } from "date-fns";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import {
  useActiveUsersQuery,
  useCreateTimesheetMutation,
  useSaveNewLabourRateMutation,
} from "jobber/workOrders/components/JobCost/hooks";
import type { User } from "~/utilities/API/graphql";
import {
  createDateWithoutSeconds,
  getUTCFromAccountZonedTime,
} from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/components/TimesheetModal/messages";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import { TimesheetModalContent } from "./TimesheetModalContent";
import styles from "./CreateTimesheetModal.module.css";
import {
  TimesheetModalReducer,
  initialCreateTimesheetModalState,
} from "./TimesheetModalReducer";

interface CreateTimesheetModalProps {
  jobId: string;
  user?: User;
  closeModal(): void;
}

export function CreateTimesheetModal({
  jobId,
  user,
  closeModal,
}: CreateTimesheetModalProps) {
  const account = useAccount();
  const { formatMessage } = useIntl();
  const { can } = useAuthorization();

  const canViewJobCosts = can("view", "JobCosts");
  const canEditLabourRates = can("edit", "LabourRates");

  const { users } = useActiveUsersQuery(canViewJobCosts);

  const [state, dispatch] = useReducer(TimesheetModalReducer, {
    ...initialCreateTimesheetModalState,
    startAt: createDateWithoutSeconds(),
    endAt: addHours(createDateWithoutSeconds(), 1),
    labourRate: user?.labourRate || 0,
    assignedTo: user?.id,
  });

  const { handleSubmit } = useCreateTimesheetMutation(
    { jobId },
    canViewJobCosts,
    closeModal,
  );

  const { handleSaveNewLabourRate } =
    useSaveNewLabourRateMutation(canViewJobCosts);

  return (
    <Content>
      <Form onSubmit={handleFormSubmit}>
        <Content>
          <TimesheetModalContent
            canViewJobCosts={canViewJobCosts}
            currencySymbol={account.currencySymbol}
            state={state}
            dispatch={dispatch}
            canEditTime={true}
            users={users}
            canEditLabourRates={canEditLabourRates}
          />
          <div className={styles.buttonGroup}>
            <div className={styles.rightButtons}>
              <Button
                label={formatMessage(jobCostMessages.cancel)}
                variation={"subtle"}
                onClick={closeModal}
              />
              <Button
                label={formatMessage(messages.saveTimesheetEntryButtonLabel)}
                submit
              />
            </div>
          </div>
        </Content>
      </Form>
    </Content>
  );

  function handleFormSubmit() {
    const input = {
      startAt: getUTCFromAccountZonedTime(state.startAt, account.timezone),
      duration: state.duration,
      note: state.note,
      labourRate: canViewJobCosts ? state.labourRate : undefined,
      userId: state.assignedTo,
    };

    handleSubmit(input);

    if (
      state.shouldUpdateDefaultLabourRate &&
      state.assignedTo &&
      state.labourRate
    ) {
      handleSaveNewLabourRate({
        userId: state.assignedTo,
        labourRate: state.labourRate,
      });
    }
  }
}
