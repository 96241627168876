import { useLayoutEffect } from "react";

export function useSetPageTitle(title: string) {
  useLayoutEffect(() => {
    // Update the document's title.
    if (title.length > 0) {
      document.title = `${title} - Jobber`;
    } else {
      document.title = "Jobber";
    }

    // Support legacy use of page title.
    Array.from(document.body.querySelectorAll(".js-pageTitle")).forEach(
      element => {
        if (element instanceof HTMLElement) {
          element.innerText = title;
        }
      },
    );
  }, []);
}
