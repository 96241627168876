import React from "react";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";

interface JobberPaymentsBillingAddressDisplayProps {
  onChangeButtonClick?(): void;
}

export function JobberPaymentsBillingAddressDisplay(
  props: JobberPaymentsBillingAddressDisplayProps,
) {
  const { onChangeButtonClick } = props;
  const [state] = usePaymentReducer();
  const address: BillingAddress = state.billingDetails?.address || {};
  const { city, pc, province, street1, street2 } = address;

  const postalCodeLine = resolvePostalCodeLine({ city, pc, province });
  const isEmpty = [street1, street2, city, postalCodeLine].every(str => !str);

  return (
    <div>
      <div className="u-colorGreyBlue">
        {isEmpty ? (
          <em>No address provided</em>
        ) : (
          <>
            <div>{street1}</div>
            <div>{street2}</div>
            <div>{postalCodeLine}</div>
          </>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code. */}
      <span
        aria-label="Edit Button"
        className="textAction"
        role="button"
        onClick={onChangeButtonClick}
      >
        Change
      </span>
    </div>
  );
}

function resolvePostalCodeLine(
  address: Pick<BillingAddress, "city" | "province" | "pc">,
) {
  const { province = "", city = "", pc = "" } = address;
  let building = "";
  if (city && province) {
    building += `${city}, ${province}`;
  } else {
    building += city;
    building += province;
  }

  if (pc) {
    building = [building, pc].join(" ");
  }

  return building;
}
