export const messages = {
  legacyMigration: {
    modalTitle: "Before you go",
    modalFirstHeader:
      "Why don't you want to take advantage of our new QuickBooks Online integration?",
    point1: "I'm interested but will connect it later",
    point2: "The old integration works fine for me",
    point3: "The new integration doesn't look right for me",
    modalSecondHeader: "Please tell us more (optional)",
    submitButton: "Submit",
    cancelButton: "Cancel",
    inputTextPlaceholder: "Why did you choose that answer?",
  },
};
