import React from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import classNames from "classnames";
import { Icon } from "@jobber/components/Icon";
import styles from "./AppFeatureRow.module.css";
import type { AppFeatureRowsProps } from "./AppFeatureRow.types";
import { AppFeatureRow } from "./AppFeatureRow";

export function AppFeatureRows({ apps }: AppFeatureRowsProps) {
  return (
    <>
      {apps.length >= 1 && (
        <>
          <li className={styles.featureRow}>
            <div className={styles.row}>
              <div
                className={classNames([
                  styles.column,
                  styles.shrink,
                  styles.iconMargin,
                ])}
              >
                <Icon name="cross" color="red" />
              </div>
              <div
                className={classNames([styles.column, styles.textContainer])}
              >
                <Text>
                  <Emphasis variation="bold">Connected apps:</Emphasis>
                </Text>
              </div>
            </div>
          </li>
          {apps.map(app => (
            <AppFeatureRow
              name={app.name}
              logoIconUrl={app.logoIconUrl}
              key={app.name}
            />
          ))}
        </>
      )}
    </>
  );
}
