import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Button, Flex, Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { strFormatDate } from "@jobber/components/FormatDate";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { messages } from "./messages";
import { EventTypes, trackEventWithAmplitude } from "../../utils";

export interface DepositedPayoutsProps {
  hasLoan: boolean;
  monthAmount?: number;
  monthEnd: Date;
  monthStart: Date;
  weekAmount?: number;
  weekEnd: Date;
  weekStart: Date;
}

export function DepositedPayouts({
  hasLoan,
  monthAmount,
  monthEnd,
  monthStart,
  weekAmount,
  weekEnd,
  weekStart,
}: DepositedPayoutsProps) {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();

  const [
    jobberPaymentsPayoutsPath,
    jobberPaymentsTransactionsPath,
    stripeCapitalReportLinkPath,
  ] = useUrls(
    "jobberPaymentsPayoutsPath",
    "jobberPaymentsTransactionsPath",
    "stripeCapitalReportLinkPath",
  );

  const formattedData = {
    monthRange: `(${strFormatDate(monthStart, false)} - ${strFormatDate(
      monthEnd,
      false,
    )})`,
    weekRange: `(${strFormatDate(weekStart, false)} - ${strFormatDate(weekEnd, false)})`,
    monthAmount: currencyFormatter(monthAmount, false),
    weekAmount: currencyFormatter(weekAmount, false),
  };

  return (
    <>
      <Content spacing="smaller">
        <Flex
          align="start"
          direction="row"
          template={["shrink", "grow"]}
          gap="small"
        >
          <Text size="small">
            {formatMessage(messages.depositedPayoutsThisWeekTitle)}
          </Text>
          <Text size="small" variation="subdued">
            {formattedData.weekRange}
          </Text>
        </Flex>
        {weekAmount != null ? (
          <Heading level={1}>{formattedData.weekAmount}</Heading>
        ) : (
          <Heading level={2}>-</Heading>
        )}
      </Content>

      <Content>
        <Content spacing="smaller">
          <Flex
            align="start"
            direction="row"
            template={["shrink", "grow"]}
            gap="small"
          >
            <Text size="small">
              {formatMessage(messages.depositedPayoutsThisMonthTitle)}
            </Text>
            <Text size="small" variation="subdued">
              {formattedData.monthRange}
            </Text>
          </Flex>
          {monthAmount != null ? (
            <Heading level={2}>{formattedData.monthAmount}</Heading>
          ) : (
            <Heading level={2}>-</Heading>
          )}
        </Content>
      </Content>

      <Content spacing="small">
        <Button
          label={formatMessage(messages.viewBankPayoutsLabel)}
          type="primary"
          onClick={() => {
            trackEventWithAmplitude({
              eventType: EventTypes.Link,
              eventSource: formatMessage(messages.viewBankPayoutsLabel),
            });
          }}
          url={jobberPaymentsPayoutsPath}
          fullWidth={true}
        />
        <Button
          label={formatMessage(messages.viewTransactionsLabel)}
          type="secondary"
          onClick={() => {
            trackEventWithAmplitude({
              eventType: EventTypes.Link,
              eventSource: formatMessage(messages.viewTransactionsLabel),
            });
          }}
          url={jobberPaymentsTransactionsPath}
          fullWidth={true}
        />
        {hasLoan && (
          <Button
            label={formatMessage(messages.viewLoanReportLabel)}
            type="secondary"
            onClick={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Link,
                eventSource: formatMessage(messages.viewLoanReportLabel),
              });
            }}
            url={stripeCapitalReportLinkPath}
            fullWidth={true}
            external={true}
          />
        )}
      </Content>
    </>
  );
}
