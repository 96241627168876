import { defineMessages } from "react-intl";

export const messages = defineMessages({
  locationServicesHeader: {
    id: "locationTimers.settings.locationServicesHeader",
    defaultMessage: "Location Services",
    description: "Large title that is displayed at the top of the page",
  },
  locationTimersCardTitle: {
    id: "locationTimers.settings.locationTimersCardTitle",
    defaultMessage: "Location timers",
    description: "Title for the location timers card",
  },
  locationTimersCardBody1: {
    id: "locationTimers.settings.locationTimersCardBody1",
    defaultMessage:
      "Team members can consent to receiving notifications based on their location to help with daily time tracking. Location timers can be turned on in the mobile app through Preferences in Settings.",
    description: "Body text for the location timers card body",
  },
  locationTimersCardOptionalText: {
    id: "locationTimers.settings.locationTimersCardOptionalText",
    defaultMessage:
      "Location timers use geofencing and is optional. By using this feature, you are responsible for ensuring compliance with local laws and regulations. Learn more about location timers in [our Help Center](https://help.getjobber.com/hc/en-us/articles/14125014745239-Location-Timers-in-the-Jobber-App).",
    description:
      "Descriptive text about the use of the location timers feature",
  },
  locationTimersConfirmationModalTitle: {
    id: "locationTimers.settings.locationTimersConfirmationModalTitle",
    defaultMessage: "Turn off location timers?",
    description:
      "Title for the modal dialogue that asks the user to confirm they want to turn off the location-timers feature",
  },
  locationTimersConfirmationModalBody: {
    id: "locationTimers.settings.locationTimersConfirmationModalBody",
    defaultMessage:
      "Turning off location timers prevents this feature from being available to team members",
    description:
      "Body text for the modal dialogue that asks the user to confirm they want to turn off the location-timers feature",
  },
  locationTimersConfirmationModalConfirmationButton: {
    id: "locationTimers.settings.locationTimersConfirmationModalConfirmationButton",
    defaultMessage: "Turn off",
    description:
      "Label for the 'confirm' button of the modal that asks the user to confirm they want to turn off the location-timers feature",
  },
  locationTimersReminderModeLabel: {
    id: "locationTimers.settings.locationTimersReminderModeLabel",
    defaultMessage:
      "Receive reminders to start or stop timers when near a visit",
    description: "Label for the nudge mode toggle",
  },
  locationTimersAutoModeLabel: {
    id: "locationTimers.settings.locationTimersAutoModeLabel",
    defaultMessage: "Track time automatically when near a visit",
    description: "Label for the automated mode toggle",
  },
  modeUpdateToastMessage: {
    id: "locationTimers.settings.modeUpdateToastMessage",
    defaultMessage: "Changes Saved",
    description: "Message displayed in a toast when the mode is updated",
  },
  updateErrorMessage: {
    id: "locationTimers.settings.updateErrorMessage",
    defaultMessage: "Something went wrong, please try again later",
    description: "Message displayed in a toast when saving fails",
  },
});
