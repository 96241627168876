import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "selfServeBookings.emptyOfferings.heading",
    defaultMessage: "You haven't added a bookable service",
    description: "Heading when no services have been added.",
  },
  subHeading: {
    id: "selfServeBookings.emptyOfferings.subHeading",
    defaultMessage:
      "At least 1 service is required for clients to make bookings.",
    description: "Subheading when no services have been added.",
  },
  subHeadingLink: {
    id: "selfServeBookings.emptyOfferings.subHeadingLink",
    defaultMessage: "Add a service",
    description:
      "sub heading link to add a service when no services have been added.",
  },
});
