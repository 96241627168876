export interface GenericObject {
  [key: string]: string;
}

const UNUSED_TEMPLATABLE_VARIABLE_REGEX =
  /{{((?!}}).)*|()}} *{{((?!}}).)*|()}} */g;

function replaceTemplateWithSampleValue(
  template: string,
  sampleValue: string,
  templatedVariableRegex: RegExp,
) {
  let valueToReplace = sampleValue;

  // Check for a phone number
  if (sampleValue.match(/^\d{10}$|^\d{11}$/)) {
    if (sampleValue.match(/^\d{10}$/)) {
      valueToReplace = `${sampleValue.substring(0, 3)}-${sampleValue.substring(
        3,
        6,
      )}-${sampleValue.substring(6, 10)}`;
    } else if (sampleValue.match(/^\d{11}$/)) {
      valueToReplace = `${sampleValue.substring(0, 1)}-${sampleValue.substring(
        1,
        4,
      )}-${sampleValue.substring(4, 7)}-${sampleValue.substring(7, 11)}`;
    }
  }

  return template.replace(templatedVariableRegex, valueToReplace);
}

export function useReplaceTemplatedValuesWithSamples(
  template: string,
  values: GenericObject,
) {
  let templateWithReplacedValues = template;

  if (template) {
    Object.keys(values).forEach(valueKey => {
      const regex = new RegExp(`{{[ ]*${valueKey}[ ]*}}`, "g");

      if (template.match(regex)) {
        const sampleValue = values[valueKey] as string;

        templateWithReplacedValues = replaceTemplateWithSampleValue(
          templateWithReplacedValues,
          sampleValue,
          regex,
        );
      }
    });

    templateWithReplacedValues = templateWithReplacedValues.replace(
      UNUSED_TEMPLATABLE_VARIABLE_REGEX,
      "",
    );
  }

  return templateWithReplacedValues;
}
