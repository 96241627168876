import { gql } from "@apollo/client";

export const GET_USER_TWO_FACTOR_DETAILS_QUERY = gql`
  query GetUserTwoFactorDetails {
    account {
      id
      restricted
      currentUser {
        id
        contactInfo {
          phone
          verifiedPhone
          verificationChannel
        }
        twoFactorDetails {
          temporaryPhone
        }
        twoFactorAuthorized
      }
    }
  }
`;
