import React from "react";
import { useIntl } from "react-intl";
import { Button, Heading, Markdown } from "@jobber/components";
import { useOnMount } from "@jobber/hooks/useOnMount";
import { IntlProvider } from "@translations/IntlProvider";
import { Banner } from "~/jobber/CTABanner/Banner";
import { BannerSlot } from "~/jobber/CTABanner/BannerSlot";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./AutomaticPaymentsExpansionCard.module.css";

export function AutomaticPaymentsExpansionCard(): JSX.Element {
  return (
    <IntlProvider>
      <AutomaticPaymentsExpansionCardInternal />
    </IntlProvider>
  );
}

function AutomaticPaymentsExpansionCardInternal() {
  const { formatMessage: t } = useIntl();

  useOnMount(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "automatic_payments_expansion_card",
      source: "recurring_job_form",
    });
  });

  return (
    <div className={styles.automaticPaymentsExpansionCardContainer}>
      <Banner rowBreakpoint="any">
        <BannerSlot size="fill" padded>
          <Heading level={4}>{t(messages.heading)}</Heading>
          <div className={styles.cardDescription}>
            <Markdown
              content={t(messages.description)}
              externalLink
              onLinkClick={() =>
                Amplitude.TRACK_EVENT("CTA Clicked", {
                  name: "help_center_link",
                  source: "automatic_payments_expansion_card",
                })
              }
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="secondary"
              size="small"
              label={t(messages.primaryCTA)}
              onClick={() => handlePrimaryButtonClick()}
            />
          </div>
        </BannerSlot>
      </Banner>
    </div>
  );

  function handlePrimaryButtonClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "upgrade_now_button",
      source: "automatic_payments_expansion_card",
    });

    window.open("/accounts/billing_info/pricing");
  }
}
