import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { LineItemsType } from "./types";
import { useWorkObject } from "./useWorkObject";

export interface LineItemPermissions {
  readonly canViewPricing: boolean;
  readonly showCosting: boolean;
  readonly canViewLineItemCost: boolean;
  readonly canEditLineItems: boolean;
}

export function useLineItemPermissions() {
  const { workObjectType } = useWorkObject();

  const { can } = useAuthorization();
  const { features } = useAccount();

  const canViewPricing = () => {
    switch (workObjectType) {
      case LineItemsType.Job:
      case LineItemsType.Quote:
        return can("view", "Pricing");
      default:
        return false;
    }
  };

  const showCosting = () => {
    switch (workObjectType) {
      case LineItemsType.Job:
        return features.jobCosting.enabled && features.quoteMargins.enabled;
      case LineItemsType.Quote:
        return features.quoteMargins.enabled;
      default:
        return false;
    }
  };

  const canViewLineItemCost = () => {
    switch (workObjectType) {
      case LineItemsType.Job:
        return can("view", "JobCosts");
      case LineItemsType.Quote:
        return features.quoteMargins.enabled; //there is no user level permission for quotes
      default:
        return false;
    }
  };

  const canEditLineItems = () => {
    switch (workObjectType) {
      case LineItemsType.Job:
        return can("edit", "Jobs");
      case LineItemsType.Quote:
        return can("edit", "Quotes");
      default:
        return false;
    }
  };

  return {
    canViewPricing: canViewPricing(),
    showCosting: showCosting(),
    canViewLineItemCost: canViewLineItemCost(),
    canEditLineItems: canEditLineItems(),
  } as LineItemPermissions;
}
