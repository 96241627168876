import { useMutation } from "@apollo/client";
import type { OnlineBookingServiceSortOrderEditMutation } from "~/utilities/API/graphql";
import { SELF_SERVE_BOOKINGS_SERVICE_SORT_ORDER_MUTATION } from "./graphql";

export function useServiceSortOrderMutation() {
  const [updateServicesSortOrder, { loading, error }] =
    useMutation<OnlineBookingServiceSortOrderEditMutation>(
      SELF_SERVE_BOOKINGS_SERVICE_SORT_ORDER_MUTATION,
    );

  return {
    updateServicesSortOrder,
    loading,
    error,
  };
}
