import { InputNumber } from "@jobber/components/InputNumber";
import { Option, Select } from "@jobber/components/Select";
import pluralize from "pluralize";
import React, { useState } from "react";
import { NotificationReminderUnit } from "~/utilities/API/graphql";
import type { DurationPickerProps } from "jobber/settings/notifications/notificationInterfaces";

export function DurationPicker({
  offset,
  before,
  allowHours = true,
  allowUnitChange = true,
  onDurationChange,
  onUnitChange,
  onValidation,
}: DurationPickerProps) {
  const SAME_DAY = "SAME_DAY";

  /**
   * Might not be needed to be guarded once we add a default in
   * client_notifications.rb
   */
  const duration = offset ? offset.value : 0;
  const unit = offset ? offset.unit : NotificationReminderUnit.DAY;

  const isSameDay = duration === 0 && unit === NotificationReminderUnit.DAY;
  const getDropdownUnit = isSameDay ? SAME_DAY : unit;
  const [dropdownUnit, setDropdownUnit] = useState(getDropdownUnit);
  const [showValue, setShowValue] = useState(!isSameDay);

  const handleUnitChange = (newValue: string) => {
    const valueMap: { [key: string]: string } = {
      HOUR: NotificationReminderUnit.HOUR,
      DAY: NotificationReminderUnit.DAY,
      SAME_DAY: NotificationReminderUnit.DAY,
    };

    if (onUnitChange) {
      setDropdownUnit(newValue);
      setShowValue(newValue !== SAME_DAY);

      onUnitChange(valueMap[newValue]);
    }

    if (onDurationChange) {
      const newDuration = newValue === SAME_DAY ? 0 : duration;
      onDurationChange(newDuration);
    }
  };

  const beforeOrAfter = before ? "before" : "after";
  const minLength = 0;
  const maxNum = dropdownUnit === NotificationReminderUnit.HOUR ? 24 : 35;

  return (
    <>
      {showValue && (
        <InputNumber
          value={duration}
          onChange={onDurationChange}
          size="small"
          inline={true}
          min={minLength}
          max={maxNum}
          maxLength={2}
          onValidation={onValidation}
        />
      )}

      {allowUnitChange && (
        <>
          <span className="u-marginLeftSmallest" />
          <Select
            value={dropdownUnit}
            onChange={handleUnitChange}
            size="small"
            inline={true}
          >
            {allowHours && (
              <Option value={NotificationReminderUnit.HOUR}>
                {pluralize("hour", duration)} {beforeOrAfter}
              </Option>
            )}
            <Option value={NotificationReminderUnit.DAY}>
              {pluralize("day", duration)} {beforeOrAfter}
            </Option>
            <Option value={SAME_DAY}>the same day as</Option>
          </Select>
        </>
      )}

      {!allowUnitChange &&
        ` ${pluralize(unit.toLowerCase(), duration)} ${beforeOrAfter}`}
    </>
  );
}
