import { snakeCase } from "lodash";
import { useStateWithLocalStorage } from "legacy/utilities/localStorage";
import type { ReportColumn } from "~/jobber/features/Reporting/components/Report/types";
import type {
  ReportColumnSelectProps,
  VisibilityConfigType,
} from "~/jobber/features/Reporting/components/Report/components/ReportColumnSelect/types";
import { useFormatColumns } from "./useFormatColumns";

interface useColumnSelectionArgs<T> {
  columns: ReportColumn<T>[];
  key?: string;
  updateVisibleColumns: (
    initialColumns: ReportColumn<T>[],
    selectedColumns: VisibilityConfigType[],
  ) => void;
}

interface useColumnSelectionReturn {
  columnSelectProps?: ReportColumnSelectProps;
}

export function useColumnSelection<T>({
  columns,
  key,
  updateVisibleColumns,
}: useColumnSelectionArgs<T>): useColumnSelectionReturn {
  const { formatColumnsForColumnSelection } = useFormatColumns();
  const selectedColumnsDefaultVisibility: VisibilityConfigType[] =
    formatColumnsForColumnSelection<T>(columns);
  const storageKey = `${snakeCase(key)}_report_column_visibility_config`;
  const [localStorageSelectedColumns, setLocalStorageSelectedColumns] =
    useStateWithLocalStorage(
      storageKey,
      JSON.stringify(selectedColumnsDefaultVisibility),
      () => JSON.stringify(selectedColumnsDefaultVisibility),
    );

  const storedSelectedColumns: VisibilityConfigType[] = JSON.parse(
    localStorageSelectedColumns,
  );

  updateVisibleColumns(columns, storedSelectedColumns);

  const columnSelectProps = {
    columnVisibilityConfig: formatColumnsForColumnSelection(columns),
    onSave: (config: VisibilityConfigType[]) => {
      updateVisibleColumns(columns, config);
      setLocalStorageSelectedColumns(JSON.stringify(config));
    },
  };

  return { columnSelectProps };
}
