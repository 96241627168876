import React from "react";
import {
  type SchedulingAvailabilityResponse,
  useScheduleCardState,
} from "./hooks";
import { SCHEDULE_DATA_FOR_LOGS } from "./constants";
import { formatScheduleData } from "./FormatScheduleDataForLogs";
import { combineDateTime } from "./utils";
import { useAssignTeam } from "../AssignTeam";

interface ScheduleDataForLogsType {
  isInRecommendationsBeta: boolean;
  railsFormKey: string;
  recommendationsResponse: SchedulingAvailabilityResponse | undefined;
}

export const ScheduleDataForLogs = ({
  isInRecommendationsBeta,
  railsFormKey,
  recommendationsResponse,
}: ScheduleDataForLogsType) => {
  /**
   * first build the startDateTime and endDateTime which can be used to work for everything else
   * If multiDay or ScheduleLater, don't calculate deltas
   * if scheduleLater - no assignees, no job duration, no deltas
   * if > 1 assignee, no deltas, need job duration
   * if 1 assignee, nearestToUserSelected - map thru the schedulingRecommendations, for the selected assignee,
   *      find the nearest slot and return the calculated diff and index of the entry
   */
  const { stateMatches, schedulingState } = useScheduleCardState();
  const { selectedAssignees } = useAssignTeam();

  if (
    !isInRecommendationsBeta ||
    recommendationsResponse?.errors.length ||
    recommendationsResponse?.loading ||
    !recommendationsResponse
  ) {
    return <></>;
  }

  const scheduleLater = stateMatches("scheduling.scheduleLater");
  const isMultiDay = stateMatches("dates.enabled.multiDay");
  const visitDuration = schedulingState.visitDuration;
  const startDateTime = combineDateTime(
    schedulingState.startDate,
    schedulingState.startTime,
  );
  const endDateTime = combineDateTime(
    schedulingState.endDate
      ? schedulingState.endDate
      : schedulingState.startDate,
    schedulingState.endTime,
  );
  const assignedTeamMembers = selectedAssignees
    ? Array.from(selectedAssignees)
    : [];

  const scheduleData = formatScheduleData({
    recommendationsResponse,
    startDateTime,
    endDateTime,
    isMultiDay,
    scheduleLater,
    selectedAssignees: assignedTeamMembers,
    visitDuration,
  });

  return scheduleData ? (
    <input
      data-testid={SCHEDULE_DATA_FOR_LOGS}
      type="hidden"
      id={`${railsFormKey}_schedule_data_for_logs`}
      name={`${railsFormKey}[${SCHEDULE_DATA_FOR_LOGS}]`}
      value={JSON.stringify(scheduleData)}
    />
  ) : (
    <></>
  );
};
