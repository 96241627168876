import React, { type ProviderProps } from "react";
import type { LineItemsType } from "./types";

export interface WorkObjectContextType {
  workObjectType: LineItemsType | undefined;
}

export const WorkObjectContext = React.createContext<WorkObjectContextType>({
  workObjectType: undefined,
});
WorkObjectContext.displayName = "WorkObjectContext";

export interface WorkObjectContextProviderProps {
  workObjectType: LineItemsType | undefined;
}

export function WorkObjectContextProvider(
  props: ProviderProps<WorkObjectContextProviderProps>,
) {
  const { value, children } = props;

  return (
    <WorkObjectContext.Provider value={value}>
      {children}
    </WorkObjectContext.Provider>
  );
}
