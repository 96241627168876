import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { ExportJobCostCsvMutation } from "~/utilities/API/graphql";
import { EXPORT_JOB_COST_CSV } from "./graphql";

interface UseExportJobCostMutationData {
  jobId: string;
}

export function useExportJobCostMutation({
  jobId,
}: UseExportJobCostMutationData): {
  handleSubmit: () => Promise<void>;
  loading: boolean;
} {
  const [jobExportCsv, { loading }] =
    useMutation<ExportJobCostCsvMutation>(EXPORT_JOB_COST_CSV);

  async function handleSubmit() {
    try {
      const result = await jobExportCsv({
        variables: {
          jobId,
        },
      });

      const errors = result.data?.jobExportCsv.userErrors;

      if (errors && errors.length > 0) {
        showToast({
          message: "Something went wrong. Please try again.",
          variation: "error",
        });
      } else {
        showToast({
          message: "Email sent",
          variation: "success",
        });
      }
    } catch (e) {
      showToast({
        message: "Something went wrong. Please try again.",
        variation: "error",
      });
    }
  }

  return {
    handleSubmit,
    loading,
  };
}
