import { defineMessages } from "react-intl";

export const messages = defineMessages({
  jobCostingTitle: {
    id: "expansion.growFeatureCarousel.jobCostingTitle",
    defaultMessage: "Job costing",
    description: "Job costing title",
  },
  jobCostingDescription: {
    id: "expansion.growFeatureCarousel.jobCostingDescription",
    defaultMessage:
      "Be confident that every job is profitable. See profitability for every job based on the line items, labor, and expenses you've tracked.",
    description: "Job costing description",
  },
  locationTimersTitle: {
    id: "expansion.growFeatureCarousel.locationTimersTitle",
    defaultMessage: "Location timers",
    description: "Location timers title",
  },
  locationTimersDescription: {
    id: "expansion.growFeatureCarousel.locationTimersDescription",
    defaultMessage:
      "Maintain accurate and up-to-date timesheets by tracking your team's visit time automatically when near a client's property.",
    description: "Location timers description",
  },
  oliTitle: {
    id: "expansion.growFeatureCarousel.oliTitle",
    defaultMessage: "Optional line items",
    description: "Optional line items title",
  },
  oliDescription: {
    id: "expansion.growFeatureCarousel.oliDescription",
    defaultMessage:
      "Sell more by offering premium packages or add-ons in your quotes. Clients can select extras and approve the entire quote all in one go.",
    description: "Optional line items description",
  },
  automatedQuoteFollowUpsTitle: {
    id: "expansion.growFeatureCarousel.automatedQuoteFollowUpsTitle",
    defaultMessage: "Automated quote follow-ups",
    description: "Automated quote follow-ups title",
  },
  automatedQuoteFollowUpsDescription: {
    id: "expansion.growFeatureCarousel.automatedQuoteFollowUpsDescription",
    defaultMessage:
      "Clients automatically receive emails and/or texts reminding them to review and approve their quote so you can win more deals, faster.",
    description: "Automated quote follow-ups description",
  },
  automatedMessagingTitle: {
    id: "expansion.connectFeatureCarousel.automatedMessagingTitle",
    defaultMessage: "Automated emails and text messaging",
    description: "Automated messaging title",
  },
  automatedMessagingDescription: {
    id: "expansion.connectFeatureCarousel.automatedMessagingDescription",
    defaultMessage:
      "Jobber sends updates automatically to inform customers while you focus on the work.",
    description: "Automated messaging description",
  },
  autopayTitle: {
    id: "expansion.connectFeatureCarousel.autopayTitle",
    defaultMessage: "Automatic payments",
    description: "Autopay title",
  },
  autopayDescription: {
    id: "expansion.connectFeatureCarousel.autopayDescription",
    defaultMessage: "Charge cards automatically when cards are saved on file.",
    description: "Autopay description",
  },
  timeTrackingTitle: {
    id: "expansion.connectFeatureCarousel.timeTrackingTitle",
    defaultMessage: "Time and expense tracking",
    description: "Time tracking title",
  },
  timeTrackingDescription: {
    id: "expansion.connectFeatureCarousel.timeTrackingDescription",
    defaultMessage: "Keep track of time and money spent on the job.",
    description: "Time tracking description",
  },
  customFieldTitle: {
    id: "expansion.connectFeatureCarousel.customFieldTitle",
    defaultMessage: "Custom fields",
    description: "Custom fields title",
  },
  customFieldDescription: {
    id: "expansion.connectFeatureCarousel.customFieldDescription",
    defaultMessage:
      "Track additional information that's important for your business.",
    description: "Custom fields description",
  },
});
