import {
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from "@floating-ui/react";
import { useState } from "react";
import { useAtlantisTheme } from "@jobber/components";

export function usePositioning() {
  const { tokens } = useAtlantisTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(tokens["space-small"]), shift()],
  });
  const focus = useFocus(context);
  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    focus,
    click,
    dismiss,
  ]);

  return {
    isOpen,
    setIsOpen,
    refs,
    floatingStyles: { ...floatingStyles, zIndex: 10 },
    getReferenceProps,
    getFloatingProps,
  };
}
