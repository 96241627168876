import React, { useState } from "react";
import { Button } from "@jobber/components/Button";

interface BaseRequestCardOnFileButton {
  label: string;
}
interface OnlyRequestCardOnFileEmailUrl extends BaseRequestCardOnFileButton {
  requestCardOnFileEmailUrl: string;
  requestCardOnFileSmsUrl?: never;
}
interface OnlyRequestCardOnFileSmsUrl extends BaseRequestCardOnFileButton {
  requestCardOnFileEmailUrl?: never;
  requestCardOnFileSmsUrl: string;
}

type RequestCardOnFileButtonProps =
  | OnlyRequestCardOnFileEmailUrl
  | OnlyRequestCardOnFileSmsUrl;

export function RequestCardOnFileButton({
  label,
  requestCardOnFileEmailUrl,
  requestCardOnFileSmsUrl,
}: RequestCardOnFileButtonProps) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      label={label}
      type="secondary"
      size={"small"}
      loading={loading}
      onClick={handleClick}
    />
  );

  function handleClick() {
    setLoading(true);
    const url = requestCardOnFileSmsUrl || requestCardOnFileEmailUrl;
    if (url) {
      void $.getScript(url, () => setLoading(false));
    }
  }
}
