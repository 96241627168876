import { useMutation } from "@apollo/client";
import type {
  OnlineBookingScheduleSettingsEditInput,
  OnlineBookingScheduleSettingsEditV2Mutation,
} from "~/utilities/API/graphql";
import { EDIT_SELF_SERVE_SCHEDULE_MUTATION } from "./graphql";

export function useUpdateSchedule() {
  const [callMutation, { loading, error }] =
    useMutation<OnlineBookingScheduleSettingsEditV2Mutation>(
      EDIT_SELF_SERVE_SCHEDULE_MUTATION,
    );

  async function editSelfServeScheduleSettings(
    newSchedule: OnlineBookingScheduleSettingsEditInput,
  ) {
    return callMutation({
      variables: {
        input: newSchedule,
      },
    });
  }

  return {
    editSelfServeScheduleSettings,
    loading,
    error,
  };
}
