import React from "react";
import { Card } from "@jobber/components/Card";
import { createPortal } from "react-dom";
import styles from "./ContentOverlay.module.css";

export interface ContentOverlayProps {
  showOverlay: boolean;
  children: React.ReactNode;
  handleClose(): void;
}

export function ContentOverlay({
  handleClose,
  children,
  showOverlay,
}: ContentOverlayProps) {
  if (!showOverlay) return null;

  const contentOverlay = (
    <>
      <div
        className={styles.overlay}
        onClick={handleClose}
        role="presentation"
      />
      <div className={styles.contentOverlayContainer}>
        <Card>{children}</Card>
      </div>
    </>
  );

  return createPortal(contentOverlay, document.body);
}
