import { strFormatDate } from "@jobber/components/FormatDate";
import { FormatTime } from "@jobber/components/FormatTime";
import { Text } from "@jobber/components/Text";
import React from "react";
import type { IntlShape } from "react-intl";
import {
  type CampaignListFragment,
  CampaignStatus,
} from "~/utilities/API/graphql";
import { USE_24_HOUR_TIME } from "jobber/campaigns/constants";
import { messages } from "../../messages";

export function campaignStatistics({
  status,
  value,
  extraText,
  emptyText,
  mediumAndUp,
  countText,
}: {
  status: CampaignStatus;
  value?: string;
  extraText?: string | boolean;
  emptyText?: string;
  mediumAndUp?: boolean;
  countText?: string;
}) {
  const Wrapper = mediumAndUp ? Text : React.Fragment;
  if (status === CampaignStatus.DRAFT || value === undefined) {
    return <Wrapper>{emptyText}</Wrapper>;
  }
  return (
    <Wrapper>
      <div className={"flex-grow-1"}>
        {value}
        {extraText}
        <div>{countText}</div>
      </div>
    </Wrapper>
  );
}

export function updatedAtMessage(
  edge: CampaignListFragment,
  mediumAndUp: boolean,
  formatMessage: IntlShape["formatMessage"],
) {
  if (mediumAndUp) {
    return (
      <>
        {edge.status === CampaignStatus.SENT
          ? formatMessage(messages.listItemSent, {
              date: strFormatDate(new Date(edge.updatedAt)),
              time: FormatTime({
                time: new Date(edge.updatedAt),
                use24HourClock: USE_24_HOUR_TIME,
              }),
            })
          : formatMessage(messages.listItemEdit, {
              date: strFormatDate(new Date(edge.updatedAt)),
              time: FormatTime({
                time: new Date(edge.updatedAt),
                use24HourClock: USE_24_HOUR_TIME,
              }),
            })}
      </>
    );
  }
  return (
    <>
      {edge.status === CampaignStatus.SENT
        ? formatMessage(messages.mobileSent, {
            date: strFormatDate(new Date(edge.updatedAt)),
          })
        : formatMessage(messages.mobileEdit, {
            date: strFormatDate(new Date(edge.updatedAt)),
            time: FormatTime({
              time: new Date(edge.updatedAt),
              use24HourClock: USE_24_HOUR_TIME,
            }),
          })}
    </>
  );
}
