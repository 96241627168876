import { defineMessages } from "react-intl";

export const messages = defineMessages({
  achAccountTypePersonal: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.personal",
    defaultMessage: "Personal",
    description: "Personal message for ACH account type",
  },
  achAccountTypeBusiness: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.business",
    defaultMessage: "Business",
    description: "Business message for ACH account type",
  },
  achAuthorizationDisclaimer: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.authorizationDisclaimer",
    defaultMessage:
      "I have signed authorization from my customer to use their payment details. I accept full responsibility for the accuracy of the information entered.",
    description: "Authorization disclaimer for ACH bank account",
  },
  validationAccountNumberRequired: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationAccountNumberRequired",
    defaultMessage: "Account number is required",
    description: "Validation message for account number required",
  },
  validationConfirmAccountNumberRequired: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationConfirmAccountNumberRequired",
    defaultMessage: "Confirm account number is required",
    description: "Validation message for confirm account number required",
  },
  accountNumbersDoNotMatch: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationAccountNumbersDoNotMatch",
    defaultMessage: "Confirm account number does not match account number",
    description:
      "Validation message for confirm account and account numbers not matching",
  },
  validationPatternNumbersOnly: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationPatternNumbersOnly",
    defaultMessage: "Only numbers are allowed",
    description: "Validation message for account number - numerical only",
  },
  validationAccountNumberMinLength: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationAccountNumberMinLength",
    defaultMessage: "Account number should be at least 5 characters long",
    description: "Validation message for account number - minimum length",
  },
  validationAccountNumberMaxLength: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationAccountNumberMaxLength",
    defaultMessage: "Account number should not exceed 17 characters",
    description: "Validation message for account number - maximum length",
  },
  validationRoutingNumberRequired: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationRoutingNumberRequired",
    defaultMessage: "Routing number is required",
    description: "Validation message for routing number required",
  },
  validationRoutingNumberPattern: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationRoutingNumberPattern",
    defaultMessage: "Enter a valid routing number.",
    description: "Validation message for routing number pattern",
  },
  validationNameMaximumLength: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.validationNameMaximumLength",
    defaultMessage: "Name should not exceed 70 characters",
    description: "Validation message for name - maximum length",
  },
  placeholderAccountNumber: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.placeholderAccountNumber",
    defaultMessage: "Account number",
    description: "Placeholder for account number input",
  },
  placeholderConfirmAccountNumber: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.placeholderConfirmAccountNumber",
    defaultMessage: "Confirm account number",
    description: "Placeholder for confirm account number input",
  },
  placeholderAccountOwnerName: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.placeholderAccountOwnerName",
    defaultMessage: "Account holder's name",
    description: "Placeholder for account holder's name input",
  },
  placeholderAccountType: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.placeholderAccountType",
    defaultMessage: "Account type",
    description: "Placeholder for account type input",
  },
  placeholderRoutingNumber: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.placeholderRoutingNumber",
    defaultMessage: "Routing number",
    description: "Placeholder for routing number input",
  },
  routingNumberMaxLengthError: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.routingNumberMaxLengthError",
    defaultMessage: "Routing number should not exceed 9 characters",
    description: "Routing number should not exceed 9 characters error message",
  },
  accountOwnerNameRequiredError: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountOwnerNameRequiredError",
    defaultMessage: "Account holder's name is required",
    description: "Error message for account holder's name input",
  },
  accountOwnerNameMinLengthError: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountOwnerNameMinLengthError",
    defaultMessage: "Name should be at least 2 characters long",
    description: "Error message for account holder's name input",
  },
  accountOwnerNameMaxLengthError: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountOwnerNameMaxLengthError",
    defaultMessage: "Name should not exceed 50 characters",
    description: "Error message for account holder's name input",
  },
  accountHelpText: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountHelpText",
    defaultMessage:
      "Routing number and account number can be found on a direct deposit form or check from your bank.",
    description: "Help text for banking numbers",
  },
  accountOwnerNameHelpText: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountOwnerNameHelpText",
    defaultMessage:
      "Make sure you enter the account holder’s name / business name exactly as it appears on your bank account.",
    description: "Help text for account owner name",
  },
  altTextBankingNumbers: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.altTextBankingNumbers",
    defaultMessage: "Banking numbers help",
    description: "Alt text for banking numbers image",
  },
  routingNumberAriaLabel: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.routingNumberAriaLabel",
    defaultMessage: "Show more info on banking routing number locations",
    description: "Aria label for help icon",
  },
  accountNumberAriaLabel: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountNumberAriaLabel",
    defaultMessage: "Show more info on banking account number locations",
    description: "Aria label for account number help icon",
  },
  accountOwnerNameAriaLabel: {
    id: "jobberPaymentsACH.manualEntryBankAccountFields.accountOwnerNameAriaLabel",
    defaultMessage: "Show more info on account owner name",
    description: "Aria label for account owner name help icon",
  },
});
