import React from "react";
import type { AiAssistantSimplePlot } from "~/utilities/API/graphql";
import { BarChart } from "jobber/dataVisualizations/BarChart";
import { CopilotChartTooltip } from "./CopilotChartTooltip";
import { computeAbbreviatedTimePeriod } from "../../utils";

interface CopilotBarChartProps {
  parameters: AiAssistantSimplePlot;
}

// The threshold above which categories for the x-axis will be alternatively hidden
const TRUNCATE_X_AXIS_THRESHOLD = 6;

export function CopilotBarChart({ parameters }: CopilotBarChartProps) {
  const { dataPoints, xAxisLabel, xCategories, yAxisLabel } = parameters;

  const data = xCategories.map((xAxisTick, index) => ({
    xAxisTick: computeAbbreviatedTimePeriod(xAxisTick),
    [yAxisLabel]: dataPoints[index] ?? 0,
  }));

  const axisBottomFormatter = (value: string) => {
    if (xCategories.length <= TRUNCATE_X_AXIS_THRESHOLD) {
      return value;
    }

    const index = xCategories.findIndex(
      x => computeAbbreviatedTimePeriod(x) === value,
    );
    if (index % 2 === 0) {
      return computeAbbreviatedTimePeriod(value);
    }

    return "";
  };

  return (
    <BarChart
      axisBottomFormatter={axisBottomFormatter}
      colors={["#998ccc"]}
      data={data}
      keys={[yAxisLabel]}
      shouldShowTickValues
      xLegend={xAxisLabel}
      xTickValues={xCategories.map(computeAbbreviatedTimePeriod)}
      yLegend={yAxisLabel}
      tooltip={tooltipProps => (
        <CopilotChartTooltip
          xValue={tooltipProps.indexValue}
          yValue={tooltipProps.value}
          xAxisLabel={xAxisLabel}
          yAxisLabel={yAxisLabel}
        />
      )}
      yTickValues={6}
    />
  );
}
