import { defineMessages } from "react-intl";

export const messages = defineMessages({
  projectedRevenue: {
    id: "dashboard.components.projectedRevenueInsights.projectedRevenue",
    defaultMessage: "Projected revenue",
    description: "The projected revenue insights card",
  },
  projectedRevenueDescription: {
    id: "dashboard.components.projectedRevenueInsights.projectedRevenueDescription",
    defaultMessage:
      "You have {projectedRevenue} of projected revenue in the next 7 days.",
    description: "The description of projected revenue on the insights card",
  },
  viewScheduled: {
    id: "dashboard.components.projectedRevenueInsights.viewScheduled",
    defaultMessage: "View Schedule",
    description: "The action button to view the schedule",
  },
});
