/* eslint-disable max-statements */
import { useQuery } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import React, { useState } from "react";
import { Divider } from "@jobber/components/Divider";
import {
  JobberPaymentsSettingsName,
  type NotificationSettingsQuery,
} from "~/utilities/API/graphql";
import { NOTIFICATION_SETTING_QUERY } from "./NotificationSettingQuery";
import { SettingsSwitch } from "./SettingsSwitch";
import styles from "./NotificationSettingsWrapper.module.css";

const genericError = new Error("Could not update settings");

export function NotificationSettingsWrapper() {
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const { loading, error, data } = useQuery<NotificationSettingsQuery>(
    NOTIFICATION_SETTING_QUERY,
  );

  if (loading) {
    return <div className="spinner" />;
  }

  if (error) {
    return <>{error.message}</>;
  }

  if (!data) {
    return <>Cannot retrieve Jobber Payments settings</>;
  }

  return (
    <>
      {mutationErrorMessage && (
        <Banner type="error" onDismiss={onErrorBannerDismiss}>
          {mutationErrorMessage}
        </Banner>
      )}
      <Card title="Notifications">
        <Content>
          <div className={styles.switch}>
            <SettingsSwitch
              settingsName={JobberPaymentsSettingsName.PAYMENT_NOTIFICATION}
              title="Get notified of payments by email"
              description=""
              defaultEnabled={data?.paymentNotification.enabled ?? false}
              onError={handleMutationError}
            />
          </div>
          <Divider />
          <div className={styles.switch}>
            <SettingsSwitch
              settingsName={JobberPaymentsSettingsName.AUTO_SEND_EMAIL_RECEIPT}
              title="Send email receipts to clients"
              description=""
              defaultEnabled={data?.autoSendEmailReceipt.enabled ?? false}
              onError={handleMutationError}
            />
          </div>
        </Content>
      </Card>
    </>
  );

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }
}
