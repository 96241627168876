import { gql } from "@apollo/client";

const JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CREATE = gql`
  mutation jobberPaymentsLimitsChangeRequestCreate(
    $input: JobberPaymentsLimitsChangeRequestCreateInput!
  ) {
    jobberPaymentsLimitsChangeRequestCreate(input: $input) {
      jobberPaymentsLimitsChangeRequest {
        limitsType
        status
        requestedAt
        id
      }
      userErrors {
        path
        message
      }
    }
  }
`;

export { JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CREATE };
