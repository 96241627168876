import { useMutation } from "@apollo/client";
import type {
  SendRequestCardOnFileMessageMutation,
  SendRequestCardOnFileMessageMutationVariables,
} from "~/utilities/API/graphql";
import { SEND_REQUEST_CARD_ON_FILE_MESSAGE } from "./useSendRequestCardOnFileMessage.graphql";

export function useSendRequestCardOnFileMessage() {
  const [requestCardOnFileMessage, { loading }] =
    useMutation<SendRequestCardOnFileMessageMutation>(
      SEND_REQUEST_CARD_ON_FILE_MESSAGE,
    );

  return { sendRequestCardOnFileMessage, loading };

  async function sendRequestCardOnFileMessage(
    payload: SendRequestCardOnFileMessageMutationVariables,
  ) {
    const data = {
      variables: {
        recipients: payload.recipients,
        clientId: btoa(payload.clientId.toString()),
      },
    };
    return requestCardOnFileMessage(data);
  }
}
