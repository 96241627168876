import React, { useEffect, useState } from "react";
import { Banner, type BannerType } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { pickBy } from "lodash";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { GlobalBannerMessage } from "jobber/features/GlobalBanner/components/GlobalBannerMessage";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  type AnalyticsTracking,
  ButtonComponentVariation,
  type GlobalMessage,
  type GlobalMessageAction,
} from "~/utilities/API/graphql";
import { GlobalBannerMessagesModal } from "jobber/features/GlobalBanner/components/GlobalBannerMessagesModal";
import {
  GlobalBannerModal,
  bannersWithModals,
} from "jobber/features/GlobalBanner/components/GlobalBannerModal/GlobalBannerModal";
import { InternalGlobalBannerCountdown } from "jobber/features/GlobalBanner/components/InternalGlobalBannerCountdown/InternalGlobalBannerCountdown";
import { csrfToken } from "utilities/csrfToken";
import styles from "./InternalGlobalBanner.module.css";
import { bannersWithCTADismissal, bannersWithPOSTRequest } from "./constants";

interface InternalGlobalBannerProps {
  readonly messages: GlobalMessage[];
  expiryDate?: Date;
  onRequestDismiss(id: string): Promise<void>;
}

interface GlobalBannerAction
  extends Pick<GlobalMessageAction, "label" | "url" | "buttonVariation"> {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

// eslint-disable-next-line max-statements
export function InternalGlobalBanner({
  messages,
  onRequestDismiss,
}: InternalGlobalBannerProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const bannerMessage = messages[0];
  const [ref, { width }] = useResizeObserver<HTMLDivElement>();
  const showUnits = (width || Breakpoints.small) > Breakpoints.base;
  const bannerAction: GlobalBannerAction | undefined = bannerMessage.action && {
    ...bannerMessage.action,
    onClick: generateOnClickCallback(bannerMessage, toggleActionModal),
  };

  useEffect(() => {
    handleActionViewed(bannerMessage);
  }, [bannerMessage]);

  const shouldReplaceIcon = bannerMessage.type === "SUCCESS";

  return (
    <>
      <div className={styles.banner} ref={ref}>
        <Banner
          key={bannerMessage.id}
          type={bannerMessage.type.toLowerCase() as BannerType}
          dismissible={bannerMessage.dismissable}
          onDismiss={handleDismiss}
          icon={shouldReplaceIcon ? "gift" : undefined}
        >
          <div className={styles.container}>
            {messages.length > 1 && (
              <span className={styles.showAllButton}>
                <Button
                  label={`1 of ${messages.length}`}
                  variation="learning"
                  type="secondary"
                  size="small"
                  onClick={toggleBannerShowMore}
                />
              </span>
            )}
            <div className={styles.message}>
              <GlobalBannerMessage
                message={bannerMessage.message}
                postMessageLink={bannerMessage.postMessageLink}
              />
              <div className={styles.countdownAndAction}>
                {bannerMessage.expiryDate && (
                  <InternalGlobalBannerCountdown
                    onComplete={handleDismiss}
                    expiryDate={bannerMessage.expiryDate}
                    showUnits={showUnits}
                  />
                )}
                {bannerAction && (
                  <span>
                    <Button
                      {...bannerAction}
                      variation={getButtonVariation()}
                      type="tertiary"
                      size="small"
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Banner>
      </div>

      {shouldOpenModal(bannerMessage.id) && actionModalOpen && (
        <GlobalBannerModal
          globalMessageId={bannerMessage.id}
          onClose={toggleActionModal}
        />
      )}

      <GlobalBannerMessagesModal
        open={modalOpen}
        messages={messages}
        onRequestClose={toggleBannerShowMore}
        handleActionViewed={handleActionViewed}
        actionCallback={generateOnClickCallback}
      />
    </>
  );

  async function makePOSTRequest(actionUrl: string) {
    if (actionUrl === undefined) return;

    const headers = new Headers([
      ["X-CSRF-Token", csrfToken],
      ["Content-type", "application/json"],
    ]);

    const request = new Request(actionUrl, {
      method: "POST",
      credentials: "include",
      headers,
    });

    const response = await fetch(request);

    if (response.redirected) {
      window.location.href = response.url;
    }
  }

  function getButtonVariation() {
    const variation = bannerAction?.buttonVariation;

    switch (variation) {
      case ButtonComponentVariation.WORK:
        return "work";
      case ButtonComponentVariation.DESTRUCTIVE:
        return "destructive";
      case ButtonComponentVariation.LEARNING:
        return "learning";
      case ButtonComponentVariation.SUBTLE:
        return "subtle";
      default:
        return undefined;
    }
  }

  function toggleActionModal() {
    setActionModalOpen(!actionModalOpen);
  }

  function generateOnClickCallback(
    clickedBannerMessage: GlobalMessage,
    toggleModalCallback: () => void,
  ) {
    return async function (event: React.MouseEvent<HTMLButtonElement>) {
      if (clickedBannerMessage?.action?.tracking) {
        const { tracking } = clickedBannerMessage.action;
        Amplitude.TRACK_EVENT(tracking.eventName, amplitudeProps(tracking));
      }

      if (
        bannersWithPOSTRequest.includes(clickedBannerMessage.id) &&
        clickedBannerMessage.action?.url
      ) {
        event?.preventDefault();
        await makePOSTRequest(clickedBannerMessage.action.url);
      }

      if (shouldOpenModal(clickedBannerMessage.id)) {
        toggleModalCallback();
      }

      if (shouldDismissOnCTA(clickedBannerMessage.id)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleDismiss().catch();
      }
    };
  }

  function amplitudeProps(tracking: AnalyticsTracking) {
    return pickBy(tracking?.properties, (val, key) => {
      return !(val === undefined || val === null || key === "__typename");
    });
  }

  function handleActionViewed(message: GlobalMessage) {
    if (message?.viewTracking) {
      const { viewTracking } = message;
      Amplitude.TRACK_EVENT(
        viewTracking.eventName,
        amplitudeProps(viewTracking),
      );
    }
  }

  async function handleDismiss() {
    if (bannerMessage?.dismissTracking) {
      const { dismissTracking } = bannerMessage;
      Amplitude.TRACK_EVENT(
        dismissTracking.eventName,
        amplitudeProps(dismissTracking),
      );
    }
    await onRequestDismiss(bannerMessage.id);
  }

  function toggleBannerShowMore() {
    setModalOpen(!modalOpen);
  }

  function shouldOpenModal(messageId: string): boolean {
    return bannersWithModals.includes(messageId);
  }

  function shouldDismissOnCTA(messageId: string): boolean {
    return bannersWithCTADismissal.includes(messageId);
  }
}
