import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Intercom } from "utilities/chat";
import { JobProfitBarGraph } from "jobber/workOrders/components/JobCost/components/JobCostHeader/components/JobProfitGraphs/JobProfitBarGraph";
import { JobProfitPieGraph } from "jobber/workOrders/components/JobCost/components/JobCostHeader/components/JobProfitGraphs/JobProfitPieGraph";
import { useProfitBarQuery } from "jobber/workOrders/components/JobCost/components/JobCostHeader/useProfitBarQuery";
import type { JobCostRecord } from "jobber/workOrders/components/JobCost/components/JobCostHeader/JobCostHeader";
import {
  getLocalStorageJSON,
  writeDataToLocalStorage,
} from "utilities/localStorage";
import { messages as jobCostHeaderMessages } from "jobber/workOrders/components/JobCost/components/JobCostHeader/messages";
import styles from "./OneOffJobProfitHeader.module.css";
import { messages } from "./messages";
import { ProfitMarginBreakdown } from "../ProfitMarginBreakdown";
import { ComputeJobProfitabilityButton } from "../ComputeJobProfitabilityButton";

interface OneOffJobProfitHeaderProps {
  jobCosting: JobCostRecord;
  jobId: string;
}

export function OneOffJobProfitHeader({
  jobCosting,
  jobId,
}: OneOffJobProfitHeaderProps) {
  const { profitMargin, profitMarginBreakdown, hasJobCostingData } =
    useProfitBarQuery(jobCosting);

  const { formatMessage } = useIntl();

  const localStorageValue = getLocalStorageJSON("jobProfitBarOpen");
  const [isOpen, setIsOpen] = useState(localStorageValue ?? true);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    writeDataToLocalStorage([{ key: "jobProfitBarOpen", value: !isOpen }]);
  };

  useEffect(() => {
    Intercom.EXECUTE("trackEvent", "job_costing_viewed");
  }, []);

  return (
    <div data-testid="jobCostHeaderContainer">
      <div className={styles.jobCostToggleButton}>
        <Button
          label={
            isOpen
              ? formatMessage(jobCostHeaderMessages.hideProfitabilityLabel)
              : formatMessage(jobCostHeaderMessages.showProfitabilityLabel)
          }
          type="tertiary"
          variation="subtle"
          size="small"
          iconOnRight
          icon={isOpen ? "arrowUp" : "arrowDown"}
          onClick={handleToggle}
        />
      </div>
      {isOpen && (
        <div className={styles.jobHeaderContainer}>
          {!hasJobCostingData && (
            <ComputeJobProfitabilityButton jobId={jobId} />
          )}
          <div className={styles.profitAndGraph}>
            <div className={styles.jobCostHeader} data-testid="jobCostHeader">
              <div
                className={classNames([
                  styles.profitMarginContainer,
                  !hasJobCostingData && styles.blurredText,
                ])}
                aria-hidden={!hasJobCostingData}
              >
                <h2
                  data-testid="jobCostHeaderProfitMargin"
                  className={classNames([
                    styles.profitMarginText,
                    typeof profitMargin === "number" &&
                      profitMargin < 0 &&
                      styles.netLossAmount,
                  ])}
                >
                  {profitMargin}%
                </h2>
                <Text size="small" variation="subdued">
                  {formatMessage(messages.profitMargin)}
                </Text>
              </div>
              {profitMarginBreakdown.map((breakdown, index) => (
                <ProfitMarginBreakdown {...breakdown} key={index} />
              ))}
            </div>
            <JobProfitPieGraph jobCosting={jobCosting} />
          </div>
          <JobProfitBarGraph jobCosting={jobCosting} />
        </div>
      )}
    </div>
  );
}
