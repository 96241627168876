import type { MessageDescriptor } from "react-intl";
import type { GoogleProfileSubscription } from "~/utilities/API/graphql";

export enum Routes {
  connect = "/connect",
  profile = "/profile",
  connected = "/connected",
  personalize = "/personalize",
  completeSetup = "/complete_setup",
  buildingDashboard = "/building_dashboard",
}

export interface SetupGuideStepProps {
  data?: GoogleProfileSubscription | undefined;
  loading?: boolean;
  errors?: boolean;
  setErrMsg: React.Dispatch<React.SetStateAction<MessageDescriptor>>;
  navigation: {
    onNext: () => void;
    goBack: () => void;
  };
  companyName?: string;
  isConnected?: boolean;
}

export interface SetupGuideStepTestProps {
  data?: GoogleProfileSubscription | undefined;
  loading?: boolean;
  errors?: boolean;
  setErrMsg?: React.Dispatch<React.SetStateAction<MessageDescriptor>>;
  navigation?: {
    onNext: () => void;
    goBack: () => void;
  };
  companyName?: string;
  isConnected?: boolean;
}

export interface SetupGuideStep {
  route: Routes;
  showBackButton: boolean;
  includeInProgress: boolean;
  component: React.ElementType<SetupGuideStepProps>;
}

export interface SetupGuideProps {
  companyName: string;
  isConnected: boolean;
  readonly routing: {
    base: string;
  };
}

export interface SetupGuideLoaderProps {
  accountSetup: boolean;
  companyName: string;
  hasFeature: boolean;
  isConnected: boolean;
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  readonly routing: {
    base: string;
  };
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  inTrial: boolean;
}
