import { gql } from "@apollo/client";
import {
  reviewsNotificationScheduleFragment,
  stringWithDefaultFieldsFragment,
  variableFieldsFragment,
  variableSectionFragment,
} from "./fragments";

const reviewData = gql`
  fragment reviewData on Review {
    id
    reviewId
    reviewer {
      displayName
      isAnonymous
    }
    reviewReply {
      comment
      updatedAt
    }
    comment
    rating
    updatedAt
  }
`;

export const REVIEWS_COMMS_SUMMARY_QUERY = gql`
  query ReviewsCommsSummary {
    account {
      industry
    }
    reviewsCommsSummary {
      totalSmsSent
    }
  }
`;

export const REVIEWS_QUERY = gql`
  query Reviews(
    $filter: ReviewFilterAttributes
    $canViewCommsSettings: Boolean = false
  ) {
    reviews {
      averageRating
      totalReviewCount
      reviews(first: 50, filter: $filter) {
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...reviewData
          }
        }
      }
    }
    communicationSettings @include(if: $canViewCommsSettings) {
      phoneNumber
    }
  }

  ${reviewData}
`;

export const REVIEWS_SUBSCRIPTION = gql`
  subscription MarketingReviews {
    reviews {
      averageRating
      totalReviewCount
      reviews {
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...reviewData
          }
        }
      }
      errors {
        path
        message
      }
    }
  }

  ${reviewData}
`;

export const REVIEW_REPLY_EDIT_MUTATION = gql`
  mutation ReviewReplyEdit($input: ReviewReplyEditInput!) {
    reviewReplyEdit(input: $input) {
      reviewReply {
        comment
        updatedAt
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation updateReviewsNotification(
    $id: ClientNotificationId!
    $data: NotificationUpdateAttributes!
  ) {
    updateNotification(id: $id, attributes: $data) {
      clientNotification {
        id
        schedules {
          edges {
            node {
              ...reviewsNotificationSchedule
            }
          }
        }
        templates {
          nodes {
            ...reviewsMessageTemplates
          }
        }
      }
    }
  }
  ${reviewsNotificationScheduleFragment}

  fragment reviewsMessageTemplates on MessageTemplate {
    ... on MessageTemplateSMS {
      id
      name
      deliveryMethod
      message {
        ...stringWithDefaultFields
      }
      variables {
        nodes {
          ...variableSection
        }
      }
      footerText
    }
    ... on MessageTemplateEmail {
      id
      name
      deliveryMethod
      subject {
        ...stringWithDefaultFields
      }
      message {
        ...stringWithDefaultFields
      }
      variables {
        nodes {
          ...variableSection
        }
      }
    }
  }

  ${stringWithDefaultFieldsFragment}
  ${variableFieldsFragment}
  ${variableSectionFragment}
`;
