import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configurationDescription: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.configurationDescription",
    defaultMessage:
      "Team members' set hours are used for their availability. Adjust their bookable days and hours in {url}.",
    description: "Description for the bookable team members configuration",
  },
  configurationLabel: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.configurationLabel",
    defaultMessage: "Bookable team members",
    description: "Label for the configuration option",
  },
  errorMessage: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Message displayed when an error occurs",
  },
  manageTeam: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.manageTeam",
    defaultMessage: "Manage Team",
    description: "Link text to manage team members",
  },
  noTeamMembers: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.noTeamMembers",
    defaultMessage:
      "At least 1 team member must be selected to accept bookings",
    description: "Appears when no team members are selected",
  },
  settingSaved: {
    id: "selfServeBookings.BookableTeamMembersConfiguration.settingSaved",
    defaultMessage: "Updated bookable team members",
    description: "Toast message displayed when the setting is saved",
  },
});
