/* eslint-disable import/no-internal-modules */
import React from "react";
import { Card } from "@jobber/components/Card";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Disclosure } from "@jobber/components/Disclosure";
import type { Configuration } from "jobber/settings/selfServeBookings/types";
import { messages } from "./messages";
import { GoogleAnalyticsConfiguration } from "../GoogleAnalyticConfiguration/GoogleAnalyticsConfiguration";
import { TermsAndConditionsConfiguration } from "../TermsAndConditionsConfiguration/TermsAndConditionsConfiguration";
import { ConfirmationMessageConfiguration } from "../ConfirmationMessageConfiguration/ConfirmationMessageConfiguration";
import { ServiceAreaConfiguration } from "../ServiceAreaConfiguration/ServiceAreaConfiguration";
import { EarliestAvailabilityConfiguration } from "../EarliestAvailabilityConfiguration/EarliestAvailabilityConfiguration";
import { BookingIntervalConfiguration } from "../BookingIntervalConfiguration/BookingIntervalConfiguration";
import { EfficientSchedulingConfiguration } from "../EfficientSchedulingConfiguration/EfficientSchedulingConfiguration";
import { BookableTeamMembersConfiguration } from "../BookableTeamMembersConfiguration/BookableTeamMembersConfiguration";

interface AvailabilityCardProps {
  configuration: Configuration;
}

export function AvailabilityCard({ configuration }: AvailabilityCardProps) {
  const { formatMessage } = useIntl();
  const leadRoutingEnabled = configuration.leadRoutingEnabled;

  return (
    <Card header={formatMessage(messages.availabilityCardHeader)}>
      <Content>
        <EfficientSchedulingConfiguration
          useBufferTime={configuration.useBufferTime}
          bufferDurationMinutes={configuration.bufferDurationMinutes}
          showDriveTime={configuration.driveTimeFeatureEnabled}
          useDriveTime={configuration.useDriveTime}
          driveTimeMinutes={configuration.maxDriveTimeMinutes}
        />
        {!leadRoutingEnabled && (
          <ServiceAreaConfiguration
            serviceAreasEnabled={configuration.serviceAreasEnabled}
            serviceAreas={configuration.serviceAreas}
          />
        )}
        <BookableTeamMembersConfiguration
          bookableUsers={configuration.bookableUsers.nodes}
          users={configuration.users}
        />
        <Disclosure title={formatMessage(messages.advancedSettingsDisclosure)}>
          <Content>
            <EarliestAvailabilityConfiguration
              earliestAvailabilityMinutes={
                configuration.earliestAvailabilityMinutes
              }
            />
            <BookingIntervalConfiguration
              intervalDuration={configuration.intervalDurationMinutes}
            />
            <TermsAndConditionsConfiguration
              url={configuration.spTermsAndConditionsUrl}
            />
            <ConfirmationMessageConfiguration
              message={configuration.confirmationMessage}
            />
            <GoogleAnalyticsConfiguration
              gaTag={configuration.googleMeasurementId}
            />
          </Content>
        </Disclosure>
      </Content>
    </Card>
  );
}
