import { gql } from "@apollo/client";

const ProductServiceFragment = gql`
  fragment ProductOrService on ProductOrService {
    id
    name
    description
    defaultUnitCost
    category
    internalUnitCost @include(if: $quoteMarginsEnabled)
    markup @include(if: $quoteMarginsEnabled)
    taxable
    visible
    durationMinutes
    onlineBookingsEnabled
    fileAttachment @include(if: $advancedQuotingEnabled) {
      id
      fileName
      fileSize
      contentType
      s3Key
      thumbnailUrl
    }
    quantityRange {
      quantityEnabled
      maxQuantity
      minQuantity
    }
  }
`;

export const PRODUCT_OR_SERVICE_LIST = gql`
  query ProductOrServiceList(
    $cursor: String
    $advancedQuotingEnabled: Boolean!
    $quoteMarginsEnabled: Boolean!
  ) {
    products(first: 25, after: $cursor) {
      edges {
        node {
          ...ProductOrService
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
  ${ProductServiceFragment}
`;

export const PRODUCT_OR_SERVICE_SEARCH = gql`
  query ProductOrServiceSearch(
    $searchTerm: String!
    $advancedQuotingEnabled: Boolean!
    $quoteMarginsEnabled: Boolean!
  ) {
    productsSearch(searchTerm: $searchTerm) {
      nodes {
        ...ProductOrService
      }
    }
  }
  ${ProductServiceFragment}
`;
